import { PersonInsurance } from 'src/app/shared/models/person-insurance';
import { PersonInsuranceAdapter } from '@secca/shared/models/person-insurance';
import { CaseIncident, CaseIncidentAdapter } from './../../shared/models/caseIncident';
import { PlannedTravel, PlannedTravelAdapter } from './../../shared/models/planned-travel';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpdateTimeLineService {

  constructor(
    private personInsuranceAdapter: PersonInsuranceAdapter,
    private caseIncidentAdapter: CaseIncidentAdapter,
    private plannedTravelAdapter: PlannedTravelAdapter
  ) {  }
  updateInsurance = new BehaviorSubject(null);
  updateTravel = new BehaviorSubject(null);
  updateIncidente = new BehaviorSubject(null);

  changedInsurance(insurance: PersonInsurance) {
    this.updateInsurance.next(this.personInsuranceAdapter.adapt(insurance));
  }
  changedTravel(planTravel: PlannedTravel) {
    this.updateTravel.next(this.plannedTravelAdapter.adapt(planTravel));
  }
  changedIncident(incident: CaseIncident) {
    this.updateIncidente.next(this.caseIncidentAdapter.adapt(incident));
  }
}
