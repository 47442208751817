import { Injectable } from '@angular/core';
import { Adapter } from './../interfaces/adapter';

export class CaseInsurance {
  personPhoto: string;
  personShortuct: string;
  personTitle: string;
  validInsurance: string;
  insurancePeriod: string;
  personGroupsInclude: string;
  geographicalArea: string;
  durationOfTravel: string;
  purposeOfTravel: string;
  incidentCovered: string;
  cu: string;
  companyName: string;
  companyInfo: string;
  public constructor(init?: Partial<CaseInsurance>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class CaseInsuranceAdapter implements Adapter<CaseInsurance> {

  adapt(item: any): CaseInsurance {

    return new CaseInsurance({
      personPhoto: item.personPhoto,
      personShortuct: item.personShortuct,
      personTitle: item.personTitle,
      validInsurance: item.validInsurance,
      insurancePeriod: item.insurancePeriod,
      personGroupsInclude: item.personGroupsInclude,
      geographicalArea: item.geographicalArea,
      durationOfTravel: item.durationOfTravel,
      purposeOfTravel: item.purposeOfTravel,
      incidentCovered: item.incidentCovered,
      cu: item.cu,
      companyName: item.companyName,
      companyInfo: item.companyInfo
    });

  }
}
