import { Moment } from "moment-timezone";

export class SearchCase {
  customer: number = 0;
  policyRefNo: string;
  customerRefNo: string;
  customerProduct: number;
  nationalIdCountryCode: string;
  nationalId: string;
  dateOfBirth: Moment;
  firstName: string;
  surname: string;
  email: string;
  phoneNumberCountryCode: string;
  phoneNumberPrefix: string;
  phoneNumber: string;
  homeCountryCode: string;
  companyName: string;
  stakeholderSearch: string;
  caseNumber: string;
  casePhoneNumber: string;
  caseCreationDateFrom: Moment;
  caseType: string;
  caseCreationDateTo: Moment;
  casePhoneNumberIncludedInSearch: boolean;
  incidentCause: string;
  incidentLocation: string;
  incidentDate: Moment;
  memberProfile: number = null;
  channel: string;

  public constructor(init?: Partial<SearchCase>) {
    if (!!init) {
      Object.assign(this, init);
    }
  }
}
