import {ValidationTypeEnum} from './../../models/enums';
import {InputValidationService} from '../../../core/services/input-validation.service';
import {CountryDetails} from '../../models/country';
import {AutoUnsubscribe} from 'src/app/shared/decorators/auto-unsubscribe';
import {DictionaryService} from '../../../core/services/dictionary.service';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {Observable, of, Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {AutoCompleteTypeEnum} from './auto-complete-type-enum';
import {NgSelectComponent} from '@ng-select/ng-select';

@Component({
  selector: 'app-drop-down-countries-auto-complete',
  templateUrl: './drop-down-countries-auto-complete.component.html',
  styleUrls: ['./drop-down-countries-auto-complete.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@AutoUnsubscribe
export class DropDownCountriesAutoComplete implements OnInit {
  @ViewChild('select') select: NgSelectComponent;
  @Input() showPhonePrefix: boolean;
  @Input() autoCompleteTypeEnum: AutoCompleteTypeEnum;
  @Input() name: string;
  @Input() errorText: string;
  @Input() set selectedItemId(value: CountryDetails) {
    this.currentSelectedItemId = value ? value.code : null;
    this.currentSelectedItem = value;
    if (this.isRemovableCountry) {
      this.items = of(this.currentSelectedItemId ? [value] : []);
    }
  }
  get selectedItemId(): CountryDetails {
    return this.currentSelectedItem;
  }
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() showCode: boolean;
  @Input() withInput: boolean;
  @Input() value: string;
  @Input() showEye: boolean;
  @Input() blockEmail: boolean;
  @Input() validate: boolean;
  @Input() validationType: ValidationTypeEnum;
  @Input() validationTypeToInput: ValidationTypeEnum;
  @Input() recommended: boolean;
  @Input() countryName: string;
  @Input() isValid: boolean;
  @Input() placeholder = '';
  @Input() highlightBorderOnInputNonEmpty = false;
  @Input() set removableCountry(value: boolean) {
    this.isRemovableCountry = value;
    if (this.isRemovableCountry) {
      this.items = of(this.currentSelectedItemId ? [this.currentSelectedItem] : []);
    }
  }
  @Output() valueChange = new EventEmitter();
  @Output() selectedItemIdChange = new EventEmitter<CountryDetails>();
  @Output() selectedItemRemoved = new EventEmitter();
  @Output() saveModel = new EventEmitter();
  @Output() showNationalId = new EventEmitter();
  @Output() inputFocusOutEmit = new EventEmitter();
  subscription: Subscription;
  items: Observable<CountryDetails[]>;
  currentSelectedItem: CountryDetails;
  currentSelectedItemId: string;
  oldSelectedItemId: any;
  input = new Subject<string | null>();
  $inputSubscr: Subscription;
  isRemovableCountry = false;

  constructor(
    private dictionaryService: DictionaryService,
    private inputValidationService: InputValidationService
  ) {
    this.$inputSubscr = this.input.subscribe(newTerm => {
      this.search(newTerm);
    });
  }

  public setFocus() {
    this.select.focus();
  }

  ngOnInit() { }

  onValueChange(value: string) {
    this.valueChange.emit(value);
  }

  onValueSaveModel(value: string) {
    this.saveModel.emit(value);
  }

  showNationalIdClick() {
    this.showNationalId.emit();
  }

  get validationClass() {
    switch (this.validationTypeToInput) {
      case ValidationTypeEnum.nationalID:
        if (this.value && this.value.length > 0 && !this.isValid) {
          return 'invalid-nationalId-input';
        } else {
          return '';
        }
      default:
        return 'input';
    }
  }

  get errorTextClass() {
    if (!!this.errorText) {
      return 'show-error-text';
    } else {
      return 'no-error';
    }
  }

  isValidated(val: any) {
    return this.inputValidationService.isValidated(val, this.validationType);
  }

  inputFucusOut() {
    this.inputFocusOutEmit.emit();
  }

  onChange(event) {
    this.selectedItemIdChange.emit(event);
  }

  onItemClick(item: CountryDetails) {
    if (item.code === this.currentSelectedItemId && this.isRemovableCountry) {
      this.clearSearchList();
      this.selectedItemRemoved.emit();
    }
  }

  onSaveModel() {
    if (this.oldSelectedItemId !== this.selectedItemId) {
      this.saveModel.emit();
    }
  }

  onSaveState() {
    this.oldSelectedItemId = this.selectedItemId;
  }

  private clearSearchList() {
    this.items = of([]);
  }

  private search(term: string | null) {
    if (term) {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      switch (this.autoCompleteTypeEnum) {
        case AutoCompleteTypeEnum.Countries:
          this.subscription = this.dictionaryService
            .searchCountries(term)
            .pipe(debounceTime(100))
            .pipe(result => (this.items = result))
            .subscribe();
          break;
        default:
          console.error('Action is not defined');
          break;
      }
    }
  }
}
