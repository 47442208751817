import { Oxygen, OxygenAdapter } from './oxygen';
import { Escort, EscortAdapter } from './escort';
import { Medication, MedicationAdapter } from './medication';
import { Equipment, EquipmentAdapter } from './equipment';
import { EquipmentItemEnum, EscortEnum, MedicationItemEnum, RepatriationMediumEnum, WheelchairNeedEnum } from '../enums';

import { Injectable } from '@angular/core';
import { Adapter } from '../../interfaces/adapter';

export class PatientNeeds {
  wheelchairNeed: WheelchairNeedEnum;
  medicalCareInTransit: boolean;
  medicalCareInTransitDescription: string;
  equipmentTransportMediumType: RepatriationMediumEnum;
  admissionUponArrival: boolean;
  ward: string;
  admissionUponArrivalDescription: string;
  equipment: Equipment[];
  equipmentNeeded: boolean;
  medication: Medication[];
  medicationNeeded: boolean;
  escort: Escort[];
  escortNeeded: boolean;
  oxygen: Oxygen[];
  inFlightOxygenNeeded: boolean;
  onGroundOxygenNeeded: boolean;

  public constructor(init?: Partial<PatientNeeds>) {
    Object.assign(this, init);
  }

  getOxygen(medium: RepatriationMediumEnum): Oxygen {
    let value = null;
    this.oxygen.forEach(item => item.type === medium ? value = item : {});
    return value;
  }

  setOxygen(oxygen: Oxygen) {
    this.removeOxygen(oxygen.type);
    this.oxygen.push(oxygen);
  }

  removeOxygen(medium: RepatriationMediumEnum) {
    this.oxygen = this.oxygen.filter(item => item.type !== medium);
  }

  getMedicalEscort(type: EscortEnum): Escort {
    let value = null;
    this.escort.forEach(item => item.type === type ? value = item : {});
    return value;
  }

  setMedicalEscort(value: Escort) {
    this.removeMedicalEscort(value.type);
    this.escort.push(value);
  }

  removeMedicalEscort(type: EscortEnum) {
    this.escort = this.escort.filter(item => item.type !== type);
  }

  getEquipment(type: EquipmentItemEnum): Equipment {
    let value = null;
    this.equipment.forEach(item => item.item === type ? value = item : {});
    return value;
  }

  setEquipment(equipment: Equipment) {
    this.removeEquipment(equipment.item);
    this.equipment.push(equipment);
  }

  removeEquipment(type: EquipmentItemEnum) {
    this.equipment = this.equipment.filter(item => item.item !== type);
  }

  getMedication(type: MedicationItemEnum): Medication {
    let value = null;
    this.medication.forEach(item => item.item === type ? value = item : {});
    return value;
  }

  setMedication(medication: Medication) {
    this.removeMedication(medication.item);
    this.medication.push(medication);
  }

  removeMedication(type: MedicationItemEnum) {
    this.medication = this.medication.filter(item => item.item !== type);
  }
}

@Injectable({
  providedIn: 'root'
})
export class PatientNeedsAdapter implements Adapter<PatientNeeds> {
  adapt(item: any): PatientNeeds {
    return new PatientNeeds({
      wheelchairNeed: item.wheelchairNeed,
      equipmentTransportMediumType: item.equipmentTransportMediumType,
      ward: item.ward,
      admissionUponArrivalDescription: item.admissionUponArrivalDescription,
      equipment: !!item.equipment ? this.adaptEquipmentList(item.equipment) : new Array<Equipment>(),
      equipmentNeeded: item.equipmentNeeded,
      medication: item.medication ? this.adaptMedicationList(item.medication) : new Array<Medication>(),
      medicationNeeded: item.medicationNeeded,
      escort: item.escort ? this.adaptEscortList(item.escort) : new Array<Escort>(),
      escortNeeded: item.escortNeeded,
      oxygen: item.oxygen ? this.adaptOxygenList(item.oxygen) : new Array<Oxygen>(),
      inFlightOxygenNeeded: item.inFlightOxygenNeeded,
      onGroundOxygenNeeded: item.onGroundOxygenNeeded,
      medicalCareInTransit: item.medicalCareInTransit,
      admissionUponArrival: item.admissionUponArrival,
      medicalCareInTransitDescription: item.medicalCareInTransitDescription
    });
  }

  private adaptOxygenList(items: any[]): Oxygen[] {
    const oxygen = new Array<Oxygen>();
    items.forEach(item => oxygen.push(new OxygenAdapter().adapt(item)));
    return oxygen;
  }

  private adaptEscortList(items: any[]): Escort[] {
    const escort = new Array<Escort>();
    items.forEach(item => escort.push(new EscortAdapter().adapt(item)));
    return escort;
  }

  private adaptEquipmentList(items: any[]): Equipment[] {
    const equipment = new Array<Equipment>();
    items.forEach(item => equipment.push(new EquipmentAdapter().adapt(item)));
    return equipment;
  }

  private adaptMedicationList(items: any[]): Medication[] {
    const medication = new Array<Medication>();
    items.forEach(item => medication.push(new MedicationAdapter().adapt(item)));
    return medication;
  }

}
