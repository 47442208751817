import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ServiceTypeBase } from './../../models/serviceTypeBase';
import { ServiceTypeComponent } from './../../models/interfaces';
import { AmbulanceFlightServiceOrderExtension } from '@secca/shared/models/service-order/ambulance-flight-service-order-extension';
import { TranslateService } from '@ngx-translate/core';
import { SubStakeholdersConfig } from '../../models/subStakeholdersConfig';
import { SubTravellersResourceConfig } from '../../models/subTravellersResourceConfig';
import { SubTravellers } from '../../models/subTravellers';
import { Location } from '@secca/shared/models/location';
import { ServiceTypeEnum, StakeholderTypeEnum, StatusTypes } from '@secca/shared/models/enums';
import { cloneDeep } from 'lodash-es';
import { SubStakeholders } from '../../models/subStakeholders';
import { MedifStatusTypeEnum, SelectedTab } from '@secca/case/components/case-plans/case-plans/add-service-order/models/enums';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { SubStakeholder } from '../../models/subStakeholder';
import * as _ from 'lodash';
import { CoordinationTransportServiceOrderExtension } from '@secca/shared/models/service-order/coordination-transport-service-order-extension';
import { CoordinationCaseDetail } from '@secca/shared/models/coordination-case-details';
import { CoordinationCaseService } from '@secca/core/services/coordination-case.service';
import { ServiceOrder } from '@secca/shared/models/service-order/service-order';

@Component({
  selector: 'app-ambulance-flight',
  templateUrl: './ambulance-flight.component.html',
  styleUrls: ['./ambulance-flight.component.scss']
})
export class AmbulanceFlightComponent extends ServiceTypeBase implements OnInit, OnChanges, ServiceTypeComponent {
  @Input() caseId: string;
  @Input()
  public get ambulanceFlight(): AmbulanceFlightServiceOrderExtension {
    return this._ambulanceFlightServiceOrderExtension;
  }
  public set ambulanceFlight(ambulanceFlight: AmbulanceFlightServiceOrderExtension) {
    if (ambulanceFlight != null) {
      this._ambulanceFlightServiceOrderExtension = ambulanceFlight;
    }
  }

  @Input() status: StatusTypes;
  @Input() stakeholders: CaseStakeholder[];

  @Input() serviceOrderId: number;
  @Input() iccServiceOrder: ServiceOrder;

  stakeholdersConfig: SubStakeholdersConfig;
  groundAmbulanceToFlightResourceConfig: SubTravellersResourceConfig;
  flightResourceConfig: SubTravellersResourceConfig;
  groundAmbulanceFromFlightResourceConfig: SubTravellersResourceConfig;

  private _ambulanceFlightServiceOrderExtension: AmbulanceFlightServiceOrderExtension;

  coordinationServiceOrderExtensions: CoordinationTransportServiceOrderExtension[];
  linkedEndUserCaseDetail: CoordinationCaseDetail;
  linkedEndUser: CaseStakeholder;
  linkedEndUserSubStakeholdersConfig: SubStakeholdersConfig;

  constructor(
    private translate: TranslateService,
    private coordinationService: CoordinationCaseService
  ) {
    super();
    this.initResources();
  }

  ngOnInit() {
    this.stakeholdersConfig = new SubStakeholdersConfig();
    this.stakeholdersConfig.hideClass = true;
    this.stakeholdersConfig.hideInclDateOfBirth = true;
    this.stakeholdersConfig.hideInclPassportDetails = true;
    this.stakeholdersConfig.requireSingleStakeholder = false;
    this.stakeholdersConfig.onlyPersonTypeStakeholders = true;
    this.stakeholdersConfig.mandatoryAndSetStakeholderType = StakeholderTypeEnum.endUser;

    this.linkedEndUserSubStakeholdersConfig = new SubStakeholdersConfig();
    this.linkedEndUserSubStakeholdersConfig.requireAtLeastOneStakeholder = true;
    this.linkedEndUserSubStakeholdersConfig.hideClass = true;
    this.linkedEndUserSubStakeholdersConfig.hideInclPassportDetails = true;
    this.linkedEndUserSubStakeholdersConfig.hideInclDateOfBirth = true;
    this.linkedEndUserSubStakeholdersConfig.mandatoryAndSetStakeholderType = StakeholderTypeEnum.endUser;
    this.linkedEndUserSubStakeholdersConfig.hideAddButton = true;
    this.coordinationService.getCoordinationCaseNumberChanges(this.caseId).subscribe(result => {
      this.serviceOrderTypeState.lockedByCoordinationTransportCaseNumber = result;
    });
    this.coordinationService.getCoordinationCaseServiceOrdersFromLinkedCase(+this.caseId).subscribe(serviceOrders => {
      this.coordinationServiceOrderExtensions = serviceOrders.filter(serviceOrder => serviceOrder.type === ServiceTypeEnum.COORDINATION_TRANSPORT && serviceOrder.status !== StatusTypes.CANCELLED).map(
        service => {
          let extension = service.extension as CoordinationTransportServiceOrderExtension;
          extension.serviceOrderId = service.id;
          return extension;
        }
      )
      if (this.coordinationServiceOrderExtensions.length > 0) {
        this.serviceOrderTypeState.isLockedByCoordinationTransport = this.coordinationService.serviceOrderUsedInCoordinationTransportCase(this.serviceOrderId, serviceOrders);
        if(this.serviceOrderTypeState.isLockedByCoordinationTransport && this.serviceOrderTypeState.lockedByCoordinationTransportCaseNumber === undefined) {
          this.coordinationService.getCoordinationCasesForLinkedCase(this.caseId).subscribe(
          result => {
            this.serviceOrderTypeState.lockedByCoordinationTransportCaseNumber = result.length > 0 ? result[0].caseNumber : '';
            this.coordinationService.coordinationCaseNumberChanged(this.caseId, this.serviceOrderTypeState.lockedByCoordinationTransportCaseNumber);
          });
        }
        this.coordinationService.getLinkedCaseDetails(this.caseId).subscribe(linkedCaseDetail => {
          this.linkedEndUserCaseDetail = linkedCaseDetail;
          this.activeTab = this.serviceOrderTypeState.showConfirmationTab ? this.linkedEndUserCaseDetail.caseId : SelectedTab.ExpectationTab;
          this.linkedEndUser = linkedCaseDetail.stakeholders.find(value => {
            return value.hasRole(StakeholderTypeEnum.endUser);
          });
        });
      }
    });

  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.status) {
      if ((changes.status.previousValue === StatusTypes.EXPECTED || changes.status.previousValue === StatusTypes.NOT_COVERED) && changes.status.currentValue === StatusTypes.COMMITTED) {
          setTimeout(() => {
          this.copyForConfirmation();
        },0);
      }
    }
    if (changes.iccServiceOrder  && changes.iccServiceOrder.currentValue && changes.iccServiceOrder.currentValue.status == StatusTypes.COMMITTED) {      
      setTimeout( () => { this.activeTab = this.iccServiceOrder.id; }, 300 );
    }
  }

  private copyForConfirmation() {
    this.ambulanceFlight.confirmationGroundTransportToFlightNotIncluded = this.ambulanceFlight.groundTransportToFlightNotIncluded;
    this.ambulanceFlight.subConfirmationGroundTransportToFlight = cloneDeep(this.ambulanceFlight.subGroundTransportToFlight);
    this.ambulanceFlight.confirmationGroundTransportToFlight = this.ambulanceFlight.subConfirmationGroundTransportToFlight.flightLegs;
    this.ambulanceFlight.subConfirmationGroundTransportToFlight.flightLegs[0].id = null;
    this.ambulanceFlight.subConfirmationGroundTransportToFlightStakeholders = new SubStakeholders([]);
    this.ambulanceFlight.subConfirmationGroundTransportToFlightStakeholders.stakeholders =
      this.ambulanceFlight.subGroundTransportToFlightStakeholders.stakeholders.map(stakeholder => this.cloneStakeholder(stakeholder));
    this.ambulanceFlight.confirmationGroundTransportToFlightStakeholders = this.ambulanceFlight.subConfirmationGroundTransportToFlightStakeholders.stakeholders;

    this.ambulanceFlight.subConfirmationFlight = cloneDeep(this.ambulanceFlight.subFlight);
    this.ambulanceFlight.confirmationFlight = this.ambulanceFlight.subConfirmationFlight.flightLegs;
    this.ambulanceFlight.subConfirmationFlight.flightLegs[0].id = null;
    this.ambulanceFlight.subConfirmationFlightStakeholders = new SubStakeholders([]);
    this.ambulanceFlight.subConfirmationFlightStakeholders.stakeholders =
      this.ambulanceFlight.subFlightStakeholders.stakeholders.map(stakeholder => this.cloneStakeholder(stakeholder));
    this.ambulanceFlight.confirmationFlightStakeholders = this.ambulanceFlight.subConfirmationFlightStakeholders.stakeholders;

    this.ambulanceFlight.confirmationGroundTransportFromFlightNotIncluded = this.ambulanceFlight.groundTransportFromFlightNotIncluded;
    this.ambulanceFlight.subConfirmationGroundTransportFromFlight = cloneDeep(this.ambulanceFlight.subGroundTransportFromFlight);
    this.ambulanceFlight.confirmationGroundTransportFromFlight = this.ambulanceFlight.subConfirmationGroundTransportFromFlight.flightLegs;
    this.ambulanceFlight.subConfirmationGroundTransportFromFlight.flightLegs[0].id = null;
    this.ambulanceFlight.subConfirmationGroundTransportFromFlightStakeholders = new SubStakeholders([]);
    this.ambulanceFlight.subConfirmationGroundTransportFromFlightStakeholders.stakeholders =
      this.ambulanceFlight.subGroundTransportFromFlightStakeholders.stakeholders.map(stakeholder => this.cloneStakeholder(stakeholder));
    this.ambulanceFlight.confirmationGroundTransportFromFlightStakeholders = this.ambulanceFlight.subConfirmationGroundTransportFromFlightStakeholders.stakeholders;
  }

  isValid(): boolean {
    let result: boolean;
    if (!this.serviceOrderTypeState.showConfirmationTab) {
      result = this.ambulanceFlight.isValidExpectation();
    }
    if (this.serviceOrderTypeState.showConfirmationTab) {
      result = this.ambulanceFlight.isValid() && this.serviceOrderTypeState.isSupplierChosen;
    }
    return result;
  }

  getModel() {
    return this.ambulanceFlight;
  }

  medifStatusTypeChanged(medifStatusType: MedifStatusTypeEnum) {
    this.ambulanceFlight.medifStatusType = medifStatusType;
  }

  toFlightNotIncluded(value) {
    this.ambulanceFlight.groundTransportToFlightNotIncluded = value;
    if (this.ambulanceFlight.groundTransportToFlightNotIncluded) {
      this.resetTravellers(this.ambulanceFlight.subGroundTransportToFlight);
    }
  }

  fromFlightNotIncluded(value) {
    this.ambulanceFlight.groundTransportFromFlightNotIncluded = value;
    if (this.ambulanceFlight.groundTransportFromFlightNotIncluded) {
      this.resetTravellers(this.ambulanceFlight.subGroundTransportFromFlight);
    }
  }

  initialize() {
  }

  private resetTravellers(travellers: SubTravellers) {
    const flightLeg = travellers.flightLegs[0];
    flightLeg.departureDate = null;
    const departureLocation: Location = new Location();
    departureLocation.id = flightLeg.departureFrom.id
    flightLeg.departureFrom = departureLocation;
    const arrivalLocation: Location = new Location();
    arrivalLocation.id = flightLeg.departureFrom.id
    flightLeg.arrivalAt = arrivalLocation;
    flightLeg.arrivalDate = null;
  }

  enableSendRequest() {
    return !!this.serviceOrderTypeState.isLeftSideValid &&
           this.ambulanceFlight.isValidExpectation() &&
           !this.serviceOrderTypeState.sendRequestRequested;
  }

  enableSendIntinerary() {
    return !!this.serviceOrderTypeState.isLeftSideValid &&
           !!this.serviceOrderTypeState.isSupplierChosen &&
          this.ambulanceFlight.isValid() &&
          this.ambulanceFlight.subConfirmationFlight.isValid();
  }

  showMissingSupplierPopOver(): boolean {
    return this.serviceOrderTypeState.showConfirmationTab && !this.serviceOrderTypeState.isSupplierChosen;
  }

  isCommitted(): boolean {
    return this.status === StatusTypes.COMMITTED;
  }

  isCommittedAndSaved(): boolean {
    return this.isCommitted() && this.serviceOrderTypeState.isConfirmationTabSaved;
  }

  get ServiceTypeEnum() {
    return ServiceTypeEnum;
  }

  private initResources() {
    this.groundAmbulanceToFlightResourceConfig = new SubTravellersResourceConfig();
    this.groundAmbulanceToFlightResourceConfig.title = 'ambulance-flight-ground-ambulance-to-flight-title';
    this.groundAmbulanceToFlightResourceConfig.travelInfoResourceConfig.startDateLabel = 'ambulance-flight-ground-ambulance-to-flight-start-date';
    this.groundAmbulanceToFlightResourceConfig.travelInfoResourceConfig.startDateLocationLabel = 'ambulance-flight-ground-ambulance-to-flight-start-location';

    this.flightResourceConfig = new SubTravellersResourceConfig();
    this.flightResourceConfig.title = 'ambulance-flight-flight-title';
    this.flightResourceConfig.travelInfoResourceConfig.agencyRefLabel ='sub-travel-info-flight-reg-no';

    this.groundAmbulanceFromFlightResourceConfig = new SubTravellersResourceConfig();
    this.groundAmbulanceFromFlightResourceConfig.title = 'ambulance-flight-ground-ambulance-from-flight-title';
    this.groundAmbulanceFromFlightResourceConfig.travelInfoResourceConfig.startDateLabel = 'ambulance-flight-ground-ambulance-from-flight-start-date';
    this.groundAmbulanceFromFlightResourceConfig.travelInfoResourceConfig.startDateLocationLabel = 'ambulance-flight-ground-ambulance-from-flight-start-location';
  }

  private cloneStakeholder(stakeholder: SubStakeholder): SubStakeholder {
    stakeholder = _.clone(stakeholder);
    stakeholder.id = null;
    return stakeholder;
  }

  sendRequest() {
    this.serviceOrderTypeState.sendRequestRequested = true;
    this.serviceOrderTypeState.sendConfirmationEvent.emit();
  }
}
