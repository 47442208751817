import {Component, Input, OnInit} from '@angular/core';
import {ServiceOrderTypeState} from '@secca/case/components/case-plans/case-plans/add-service-order/models/serviceOrderTypeState';
import {ServiceOrderExtension} from '@secca/shared/models/service-order/service-order-extension';
import {OutputManagementService} from '@secca/case/components/case-output-management/services/output-management.service';
import {MessageChannelType} from '@secca/shared/models/enums';

@Component({
  selector: 'app-gop-send-request',
  templateUrl: './gop-send-request.component.html',
  styleUrls: ['./gop-send-request.component.scss']
})
export class GopSendRequestComponent implements OnInit {
  @Input() caseId: any;
  @Input() serviceOrderTypeState: ServiceOrderTypeState;
  @Input() serviceOrderExtension: ServiceOrderExtension;

  constructor(private outputManagementService: OutputManagementService) { }

  ngOnInit() {
  }

  sendRequest() {
    this.serviceOrderTypeState.sendConfirmationEvent.emit();
  }

  get disableGopButton(): boolean {
    return !this.serviceOrderTypeState.isLeftSideValid || !this.serviceOrderTypeState.isSupplierChosen ||
      !this.serviceOrderExtension.isValid();
  }

}
