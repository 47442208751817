import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfileChangeService {
  private profiles = {};

  observe(caseId: string) {
    this.init(caseId);
    return this.profiles[caseId].asObservable();
  }

  publish(caseId: string, profile: number) {
    this.init(caseId);
    this.profiles[caseId].next(profile);
  }

  init(caseId: string) {
    if (this.profiles[caseId] == null) {
      this.profiles[caseId] = new Subject();
    }
  }
}
