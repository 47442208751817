<div class="d-flex">
  <div class="common-drop-down-arrow common-drop-down drop-down-with-customer-profile">
    <span class="name">{{name}} </span>
    <ng-select [clearable]="false" [items]="items" (open)="onOpen()" (blur)="onSaveModelDropDown()" (change)="onSelectedItemIdChange()"
       [(ngModel)]="selectedItemId" class="custom customer-profile" bindLabel="profileName" bindValue="profileId"
              [disabled]="!!disabled"
              [ngClass]="validationClass"
              [class.customer-profile-highlight-border]="highlightBorderOnInputNonEmpty && !!selectedItemId && !disabled" #ngselectcomp>
      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <div *ngIf="item.commonCustomerName">
          <img [src]="getProfileImagesUrl(item.brandId)" class="imageClass float-start" />
          <div class="profileName" >{{ item.profileName }}</div>
          <div class="customerBrand">{{ item.commonCustomerName }} / {{ item.brandName }} |
            {{ getTeamName(item.adtCode) }}</div>
        </div>
        <div *ngIf="item.noInsuranceWithThisCustomerGroup">
          <div class="blank"> {{item.profileName}} </div>
        </div>
        <div *ngIf="!item.commonCustomerName && !item.noInsuranceWithThisCustomerGroup">
          <div class="blank" *ngIf="useAsSearchParameter" > All </div>
          <div class="blank" *ngIf="!useAsSearchParameter && !blankTitle" > Blank </div>
          <div class="blank" *ngIf="!useAsSearchParameter && blankTitle" > {{blankTitle}} </div>
        </div>
      </ng-template>
    </ng-select>
  </div>
</div>
