import { Injectable } from '@angular/core';
import { Adapter } from './../interfaces/adapter';
export class Coverage {
  id: string;
  seccaCaseId: string;
  typeId: string;
  reserve: number;
  planned: number;
  limit: number;
  limitCurrency: string;
  limitId: string;
  typeDescription: string;
  nonAmountLimitsExist: boolean;

  public constructor(init?: Partial<Coverage>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class CoverageAdapter implements Adapter<Coverage> {
  adapt(item: any): Coverage {
    return new Coverage({
      id: item.id,
      seccaCaseId: item.seccaCaseId,
      typeId: item.typeId != null ? item.typeId.toString() : null,
      reserve: item.reserve,
      planned: item.planned,
      limit: item.limit,
      limitCurrency: item.limitCurrency,
      limitId: item.limitId,
      typeDescription: item.typeDescription,
      nonAmountLimitsExist: item.nonAmountLimitsExist
    });
  }
}
