<div class="d-flex">
    <div class="icc-stakeholder">
      <div class="common-drop-down-arrow common-drop-down">
        <ng-select
            [(ngModel)]="subStakeholder.stakeholderId"
            class="custom"
            [items]="stakeholdersDropdown"
            bindValue="id"
            bindLabel="name"
            (change)="setSelectedStakeholder($event)"
            [clearable]="false">

          <ng-template ng-option-tmp let-item="item" let-index="index">
            <div>
              <img [src]="item.iconPath" class="drop-down-icon" /> {{item.name}}
            </div>
          </ng-template>
          <ng-template ng-label-tmp let-item="item">
            <div class="service-order-stakeholder-picker">
                <img [src]="item.iconPath" class="drop-down-icon" *ngIf="item?.iconPath"/>
                <span class="p-2 service-order-stakeholder-picker-label">{{item.name}}</span>
                <span *ngIf="!getNameInPassport(item.id)" class="no-nip ms-auto p-2">{{"sub-stakeholder-no-nip" | translate}}</span>
            </div>
          </ng-template>
        </ng-select>
      </div>
    </div>
  </div>