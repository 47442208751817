import { StakeholderClassTypeEnum } from './../../models/enums';
import { StakeholderService } from 'src/app/core/services/stakeholder.service';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CaseService } from '@secca/core/services/case.service';
import { TranslateService } from '@ngx-translate/core';
import { SubStakeholder } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholder';
import { SubStakeholders } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholders';
import { ServiceTypeEnum, StakeholderTypeEnum } from '@secca/shared/models/enums';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import { DropdownWitIconDictionary } from '@secca/shared/models/dropdownWithIconDictionary';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { SubStakeholdersConfig } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholdersConfig';
import * as _ from 'lodash';
import { ServiceOrderType } from '@secca/shared/models/service-order-type';

@Component({
  selector: 'app-sub-stakeholders',
  templateUrl: './sub-stakeholders.component.html',
  styleUrls: ['./sub-stakeholders.component.scss'],
})
@AutoUnsubscribe
export class SubStakeholdersComponent implements OnInit, OnChanges {
  @Input() caseId: string;
  @Input() stakeholders: CaseStakeholder[];
  @Input() subStakeholders: SubStakeholders;
  @Input() stakeholderType: StakeholderTypeEnum;
  @Input() subStakeholdersConfig: SubStakeholdersConfig;
  @Input() disabled: boolean;
  @Input() preselectEndUser = false;
  @Input() medicalStaffOnly = false;
  @Input() showInvalidBackground: boolean;
  @Input() hideNip: boolean;
  @Input() serviceOrderType: ServiceOrderType;
  @Output() endUserHasDateOfBirthEmit: EventEmitter<boolean> = new EventEmitter();

  stakeholderTypeEndUser: CaseStakeholder;
  stakeholdersDropdown: DropdownWitIconDictionary[];
  classDropdown: DropdownDictionary[];
  classDropdownSeeMedifOnly: DropdownDictionary[];
  inclDOBDropdown: DropdownDictionary[];
  inclPassportDropdown: DropdownDictionary[];
  caseStakeholders: CaseStakeholder[];

  constructor(private caseService: CaseService, private stakeholderService: StakeholderService, private translate: TranslateService) {}

  ngOnInit() {
    this.initClassDropdown();
    this.initInclDOBDropdown();
    this.initInclPassportDropdown();
    this.initClassDropdownSeeMedifOnly();
  }

  ngOnChanges(changes: SimpleChanges): void {
   if (changes.stakeholders?.currentValue) {
      setTimeout(() => {
        this.initStakeholdersDropdown();
      }, 0);
    }
  }

  initStakeholdersDropdown() {
    if ( this.stakeholders ) {
      let tempStakeholders = _.clone(this.stakeholders);
      this.stakeholderTypeEndUser = tempStakeholders?.find(stakeholder => stakeholder.hasRole(StakeholderTypeEnum.endUser));
      this.endUserHasDateOfBirthEmit.emit(!!this.stakeholderTypeEndUser.person.dateOfBirth);
      if (this.subStakeholdersConfig.onlyPersonTypeStakeholders) {
        tempStakeholders = tempStakeholders.filter(a => a.person);
      } else if (this.subStakeholdersConfig.availableStakeholderTypes) {
        tempStakeholders = tempStakeholders.filter(stakeholder =>
          this.subStakeholdersConfig.availableStakeholderTypes.includes(stakeholder.stakeholderType as StakeholderTypeEnum)
        );
      }
      if (this.preselectEndUser) {
        if (this.subStakeholders.stakeholders.length === 0) {
          this.subStakeholders.stakeholders.push(new SubStakeholder({ stakeholderId: this.stakeholderTypeEndUser.id }));
        } else if (!this.subStakeholders.stakeholders[0].stakeholderId) {
          this.subStakeholders.stakeholders[0].stakeholderId = this.stakeholderTypeEndUser.id;
        }
      }
      if (this.subStakeholdersConfig.reporterAndPolicyHolderMustBePersons) {
        tempStakeholders = tempStakeholders.filter(
          a =>
            (!a.hasRole(StakeholderTypeEnum.reporter) && !a.hasRole(StakeholderTypeEnum.policyHolder)) ||
            (a.hasRole(StakeholderTypeEnum.reporter) && !a.company) ||
            (a.hasRole(StakeholderTypeEnum.policyHolder) && !a.company)
        );
      }
      if(this.medicalStaffOnly){
        const medicalStakeholderTypes: StakeholderTypeEnum[] =  [
                  StakeholderTypeEnum.hospital,
                  StakeholderTypeEnum.dentist,
                  StakeholderTypeEnum.crisisPsychologist,
                  StakeholderTypeEnum.doctorOutpatientClinic,
                ];
        tempStakeholders = tempStakeholders.filter(a => medicalStakeholderTypes.includes(a.stakeholderType) );
      }

      this.caseStakeholders = tempStakeholders;
      this.stakeholdersDropdown = [];
      tempStakeholders.forEach(stakeholder => {
        this.stakeholdersDropdown.push({
          id: stakeholder.id,
          name: stakeholder.isCompany ? stakeholder.company.name : `${stakeholder.person.firstName} ${stakeholder.person.surname}`,
          iconPath: this.stakeholderService.getStakeholderIconFullPath(stakeholder.stakeholderType),
          hoverText: null
        });
      });
    }
  }

  initClassDropdown() {
    this.classDropdown = [];
    const classLabelsVisibleKeys = Array.from(this.subStakeholdersConfig.classLabelsVisible.keys());
    classLabelsVisibleKeys.forEach(labelKey => {
      if (this.subStakeholdersConfig.classLabelsVisible.get(labelKey)) {
        this.classDropdown.push({
          id: labelKey,
          name: this.translate.instant(`sub-stakeholder-class-type-${labelKey}`),
        });
      }
    });
  }

  initClassDropdownSeeMedifOnly() {
    this.classDropdownSeeMedifOnly = [
      {
        id: StakeholderClassTypeEnum.SEE_MEDIF,
        name: this.translate.instant(`sub-stakeholder-class-type-${StakeholderClassTypeEnum.SEE_MEDIF}`),
      },
    ];
  }

  initInclDOBDropdown() {
    this.inclDOBDropdown = [
      {
        id: 'YES',
        name: this.translate.instant('sub-stakeholder-yes'),
      },
      {
        id: 'NO',
        name: this.translate.instant('sub-stakeholder-no'),
      },
    ];
  }

  initInclPassportDropdown() {
    this.inclPassportDropdown = [
      {
        id: 'YES',
        name: this.translate.instant('sub-stakeholder-yes'),
      },
      {
        id: 'NO',
        name: this.translate.instant('sub-stakeholder-no'),
      },
    ];
  }

  setDefaultDOBDropdown(serviceTypeEnum: string): boolean {
    const acceptedServerOrdertypeCodes: ServiceTypeEnum[] =
      [ServiceTypeEnum.REGULAR_FLIGHT_MEDICAL, ServiceTypeEnum.GROUND_AMBULANCE, ServiceTypeEnum.REGULAR_FLIGHT_NON_MEDICAL];
    return acceptedServerOrdertypeCodes.includes(serviceTypeEnum as ServiceTypeEnum);
  }

  addStakeholder() {
    if (this.serviceOrderType && this.setDefaultDOBDropdown(this.serviceOrderType.serviceTypeEnumValue)){
      this.subStakeholders.stakeholders.push(new SubStakeholder({includeDateOfBirth: 'YES'}));
    } else {
      this.subStakeholders.stakeholders.push(new SubStakeholder());
    }
  }

  deleteStakeholder(stakeholder) {
    if (this.subStakeholders.stakeholders.length === 1 && this.subStakeholdersConfig.requireAtLeastOneStakeholder) {
      return;
    }
    const index = this.subStakeholders.stakeholders.findIndex(d => d === stakeholder);
    this.subStakeholders.stakeholders.splice(index, 1);
  }
}
