import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';

export class SelectionAwareStakeholder {
  stakeholder: CaseStakeholder;
  isSelected: boolean;

  public constructor(stakeholder: CaseStakeholder, isSelected: boolean) {
    this.stakeholder = stakeholder;
    this.isSelected = isSelected;
  }
}
