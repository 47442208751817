import { LimitTypeEnum } from './enums';
import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';

export class Limit {
  id: number;
  coverageID: number;
  limitDescription: string;
  limitType: LimitTypeEnum;
  limitUnit: string;
  limitValue: string;
  limitMaxValue: string;

  public constructor(init?: Partial<Limit>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class LimitAdapter implements Adapter<Limit> {
  adapt(item: any): Limit {
    return new Limit({
      id: item.id,
      coverageID: item.coverageID,
      limitDescription: item.limitDescription,
      limitType: item.limitType,
      limitUnit: item.limitUnit,
      limitValue: item.limitValue,
      limitMaxValue: item.limitMaxValue
    });
  }
}
