import { SavingTypedInField } from '@secca/shared/models/enums';
import { SupplierInvoiceItem } from '@secca/shared/models/supplierInvoiceItem';
import { SupplierInvoiceSaving } from '@secca/shared/models/supplierInvoiceSaving';
import { DineroObject } from 'dinero.js';

export class SavingsCalculator {
  splitSavings(supplierInvoiceItem: SupplierInvoiceItem): SupplierInvoiceSaving[][] {
    const splitSavings = [];
    const grossSavings = supplierInvoiceItem.savings.filter(saving => saving.gross).sort((a, b) => b.lineNumber - a.lineNumber);
    const netSavings = supplierInvoiceItem.savings.filter(saving => !saving.gross).sort((a, b) => a.lineNumber - b.lineNumber);
    splitSavings.push(grossSavings);
    splitSavings.push(netSavings);
    return splitSavings;
  }

  recalcSavingAmounts(localCurrency: DineroObject, supplierInvoiceItem: SupplierInvoiceItem): SupplierInvoiceSaving[][] {
    const currencyPrecision = Math.pow(10, !!localCurrency.precision ? localCurrency.precision : 2);
    const splitSavings = this.splitSavings(supplierInvoiceItem);
    const grossSavings = splitSavings[0];
    const netSavings = splitSavings[1];

    if (supplierInvoiceItem.calculatedFromGross && grossSavings[0].totalAmount) {
      this.calculateFromGross(supplierInvoiceItem, grossSavings, currencyPrecision);
    } else {
      this.calculateGrossSavings(supplierInvoiceItem, grossSavings, currencyPrecision);
    }
    let netAmount = supplierInvoiceItem.paidAmount;
    for (const saving of netSavings) {
      if (saving.typedInField === SavingTypedInField.AMOUNT || !saving.percentage) {
        saving.percentage = Math.round((saving.amount * 10000) / netAmount) / 100;
      } else {
        saving.amount = Math.round(saving.percentage * netAmount) / 100;
      }
      netAmount -= saving.amount;
      saving.totalAmount = netAmount;
    }
    return splitSavings;
  }

  private calculateFromGross(supplierInvoiceItem: SupplierInvoiceItem, grossSavings: SupplierInvoiceSaving[], currencyPrecision: number) {
    let paidAmount = grossSavings[0].totalAmount;
    for (let i = 0; i < grossSavings.length; i++) {
      if (grossSavings[i].typedInField === SavingTypedInField.AMOUNT) {
        grossSavings[i].percentage = Math.round((grossSavings[i].amount * 10000) / paidAmount) / 100;
      } else {
        grossSavings[i].amount = Math.round(grossSavings[i].percentage * paidAmount) / 100;
      }
      if (i > 0) {
        grossSavings[i].totalAmount = paidAmount;
      }
      paidAmount -= grossSavings[i].amount;
    }
    supplierInvoiceItem.paidAmount = paidAmount;
  }

  private calculateGrossSavings(
    supplierInvoiceItem: SupplierInvoiceItem,
    grossSavings: SupplierInvoiceSaving[],
    currencyPrecision: number
  ) {
    let grossAmount = supplierInvoiceItem.paidAmount;
    for (const saving of grossSavings.slice().reverse()) {
      if (saving.typedInField === SavingTypedInField.AMOUNT || !saving.percentage) {
        saving.percentage = Math.round((saving.amount * 10000) / (saving.amount + grossAmount)) / 100;
      } else {
        const newGross = Math.round((currencyPrecision * 100 * grossAmount) / (100 - saving.percentage)) / currencyPrecision;
        saving.amount = newGross - grossAmount;
      }
      grossAmount += saving.amount;
      saving.totalAmount = grossAmount;
    }
  }
}
