<div class="history-table-container" [class.zoomed]="settingsService.isZoomed()" *ngIf="filteredEconomyHistoryList">
    <div class="background">
        <div class="sort-container">
            <div class="row">
              <div class="col-9"></div>
              <div class="col-1 sort-by">{{ 'case-summary-sort-by' | translate }}</div>
              <div class="col-2 sort-drop-down-area">
                <app-drop-down
                  class="sort-drop-down"
                  [items]="sortItems"
                  [(selectedItemId)]="sortItem"
                  (selectedItemIdChange)="sortChanged($event)"
                ></app-drop-down>
              </div>
            </div>
          </div>
        <div class="container">
          <div *ngFor="let entry of filteredEconomyHistoryList; let i = index">
            <app-refund-row [entry]="entry" [index]="i" *ngIf="entry.type === EconomyHistoryFilterType.REFUND"></app-refund-row>
            <app-refund-item-row [entry]="entry" [index]="i" *ngIf="entry.type === EconomyHistoryFilterType.REFUND_ITEM"></app-refund-item-row>
            <app-supplier-invoice-row [entry]="entry" [index]="i" *ngIf="entry.type === EconomyHistoryFilterType.SUPPLIER_INVOICE"></app-supplier-invoice-row>
            <app-supplier-invoice-item-row [entry]="entry" [index]="i" *ngIf="entry.type === EconomyHistoryFilterType.SUPPLIER_INVOICE_ITEM"></app-supplier-invoice-item-row>
            <app-customer-invoice-row [entry]="entry" [index]="i" *ngIf="entry.type === EconomyHistoryFilterType.CUSTOMER_INVOICE"></app-customer-invoice-row>
            <app-customer-invoice-item-row [entry]="entry" [index]="i" *ngIf="entry.type === EconomyHistoryFilterType.CUSTOMER_INVOICE_ITEM"></app-customer-invoice-item-row>
            <app-sos-service-row [entry]="entry" [index]="i" *ngIf="entry.type === EconomyHistoryFilterType.SOS_SERVICE"></app-sos-service-row>
            <app-recovery-payment-row [entry]="entry" [index]="i" *ngIf="entry.type === EconomyHistoryFilterType.RECOVERY_PAYMENT"></app-recovery-payment-row>
            <app-recovery-payment-item-row [entry]="entry" [index]="i" *ngIf="entry.type === EconomyHistoryFilterType.RECOVERY_PAYMENT_ITEM"></app-recovery-payment-item-row>
          </div>
        </div>
    </div>
</div>
