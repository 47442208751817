import { DateHelperService } from './../../core/services/date-helper.service';
import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
import { SearchResultParamDto } from './SearchResultParamDto';
import { CaseHistoryDeletedFieldValueDto } from './CaseHistoryDeletedFieldValueDto';
import { CaseHistoryType, CaseObjectType, CaseSubObjectType } from '../enums/case-history-type';
import { CaseHistoryDataFieldChangeDto, CaseHistoryDtoAdapter } from './CaseHistoryDataFieldChangeDto';
import { CommunicationDirection, StakeholderTypeEnum } from './enums';
import { CaseHistoryPropertyChangedData, CaseHistoryPropertyChangedDataAdapter } from '@secca/shared/models/caseHistoryPropertyChangedData';
import {CaseHistoryRuleData, CaseHistoryRuleDataAdapter} from "@secca/shared/models/caseHistoryRuleData";
import moment from 'moment-timezone';
import { DateSerializer } from './date-serializer';

export class CaseHistoryEntry {
  id: number;
  caseId: number;
  caseHistoryType: CaseHistoryType;
  actionTime: moment.Moment;
  userId: string;
  stakeholderType: string;
  searchResultParams: SearchResultParamDto[];
  searchResult: any;
  searchResultDetails: string;
  caseObjectType: CaseObjectType;
  caseSubObjectType: CaseSubObjectType;
  entityId: number;
  deletedEntityId: number;
  deletedFieldValues: CaseHistoryDeletedFieldValueDto[];
  dataFieldChanges: CaseHistoryDataFieldChangeDto[];
  content: string;
  oldStatus: string;
  newStatus: string;
  mediaType: string;
  direction: CommunicationDirection;
  appliedPropertyId: number;
  propertyData: CaseHistoryPropertyChangedData;
  outdated: boolean;
  unreadMessage: boolean;
  caseHistoryRuleData: CaseHistoryRuleData;
  deletedBy: string;
  deletedOn: Date;
  digitalCaseflowLogId: number;
  flowName: string;
  flowResult: boolean;
  uuId: string;
  deleteGdpr: boolean;

  public constructor(init?: Partial<CaseHistoryEntry>) {
    Object.assign(this, init);
  }
}

export class CaseHistoryCommEntry extends CaseHistoryEntry {
  subject: string;
  toStakeholderType: StakeholderTypeEnum;
  internalSummary: string;
  updatedInternalSummary: string;
  attachments: string;
  toStakeholderName: string;
  to: string;
  from: string;
  cc: string;
  emailId: string;
  faxId: string;
  messageId: string;
  stakeholderReceiverId: number;
  unhandled: boolean;
  stakeholderSenderId: number;
  updatedStakeholderSenderId: number;
  uuId: string;

  public constructor(init?: Partial<CaseHistoryCommEntry>) {
    super();
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class CaseHistoryEntryAdapter implements Adapter<CaseHistoryEntry> {
  constructor(private dateHelperService: DateHelperService,
              private caseHistoryDtoAdapter: CaseHistoryDtoAdapter,
              private propertyChangedDataAdapter: CaseHistoryPropertyChangedDataAdapter,
              private caseHistoryRuleDataAdapter: CaseHistoryRuleDataAdapter) {
  }

  adapt(item: any): CaseHistoryEntry {
    if ( item === null ) {
      return null;
    }
    return item.caseSubObjectType === 'EMAIL' || item.caseSubObjectType === 'FAX' || item.caseSubObjectType === 'SMS' || item.caseSubObjectType === 'MMS' ?
      new CaseHistoryCommEntry({
        id: item.id,
        caseId: item.caseId,
        actionTime:  item.actionTime ? DateSerializer.deserialize(item.actionTime) : null,
        caseHistoryType: item.caseHistoryType,
        userId: item.userId,
        stakeholderType: item.stakeholderType === null ? '' : item.stakeholderType,
        searchResultParams: item.searchResultParams,
        searchResult: item.searchResult === null ? '' : item.searchResult,
        searchResultDetails: item.searchResultDetails === null ? '' : item.searchResultDetails,
        caseObjectType: item.caseObjectType,
        caseSubObjectType: item.caseSubObjectType,
        entityId: item.entityId === null ? '' : item.entityId,
        deletedFieldValues: item.deletedFieldValues,
        dataFieldChanges: item.dataFieldChanges,
        deletedEntityId: item.deletedEntityId === null ? '' : item.deletedEntityId,
        mediaType: item.mediaType === null ? '' : item.mediaType,
        direction: item.direction,
        to: item.to === null ? '' : item.to,
        content: item.content === null ? '' : item.content,
        oldStatus: item.oldStatus === null ? '' : item.oldStatus,
        newStatus: item.newStatus === null ? '' : item.newStatus,
        internalSummary: item.internalSummary,
        updatedInternalSummary: item.updatedInternalSummary,
        toStakeholderType: item.toStakeholderType,
        subject: item.subject,
        attachments: item.attachments,
        toStakeholderName: item.toStakeholderName,
        from: item.from,
        cc: item.cc,
        emailId: item.emailId,
        stakeholderReceiverId: item.stakeholderReceiverId,
        faxId: item.faxId,
        messageId: item.messageId,
        outdated: item.outdated,
        unreadMessage: item.unreadMessage,
        unhandled: item.unhandled,
        stakeholderSenderId: item.stakeholderSenderId,
        updatedStakeholderSenderId: item.updatedStakeholderSenderId,
        deletedBy: item.deletedBy,
        deletedOn: item.deletedOn === '' ? null : this.dateHelperService.convertFromUTCDateTimeToLocalDateTime(item.deletedOn),
        uuId: item.uuId,
        deleteGdpr: item.deleteGdpr,
      }) : new CaseHistoryEntry({
        id: item.id,
        caseId: item.caseId,
        actionTime: item.actionTime ? DateSerializer.deserialize(item.actionTime) : null,
        caseHistoryType: item.caseHistoryType,
        userId: item.userId,
        stakeholderType: item.stakeholderType === null ? '' : item.stakeholderType,
        searchResultParams: item.searchResultParams,
        searchResult: item.searchResult === null ? '' : item.searchResult,
        searchResultDetails: item.searchResultDetails === null ? '' : item.searchResultDetails,
        caseObjectType: item.caseObjectType,
        caseSubObjectType: item.caseSubObjectType,
        entityId: item.entityId === null ? '' : item.entityId,
        deletedFieldValues: item.deletedFieldValues,
        dataFieldChanges: this.adaptDataFieldChanges(item.dataFieldChanges),
        deletedEntityId: item.deletedEntityId === null ? '' : item.deletedEntityId,
        mediaType: item.mediaType === null ? '' : item.mediaType,
        direction: item.direction,
        content: item.content === null ? '' : item.content,
        oldStatus: item.oldStatus === null ? '' : item.oldStatus,
        newStatus: item.newStatus === null ? '' : item.newStatus,
        outdated: item.outdated,
        unreadMessage: item.unreadMessage,
        appliedPropertyId: item.appliedPropertyId,
        propertyData: this.adaptPropertyDataChange(item.propertyData),
        caseHistoryRuleData: item.caseHistoryRuleData === undefined ? null : this.adaptCaseHistoryRuleData(item.caseHistoryRuleData),
        digitalCaseflowLogId: item.digitalCaseflowLogId,
        flowResult: item.flowResult,
        flowName: item.flowName,
        deletedBy: item.deletedBy,
        deletedOn: item.deletedOn === '' ? null : this.dateHelperService.convertFromUTCDateTimeToLocalDateTime(item.deletedOn),
        uuId: item.uuId,
      });
  }

  private adaptDataFieldChanges(items: any[]): CaseHistoryDataFieldChangeDto[] {
    return items ? items.map((e, i, a) => a[i] = this.caseHistoryDtoAdapter.adapt(e)) : null;
  }

  private adaptPropertyDataChange(data: any): CaseHistoryPropertyChangedData {
    return data ? this.propertyChangedDataAdapter.adapt(data) : null;
  }

  private adaptCaseHistoryRuleData(item: any): CaseHistoryRuleData {
    return this.caseHistoryRuleDataAdapter.adapt(item);
  }
}
