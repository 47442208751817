<div *ngIf="fileScan?.hit">
  {{'file-scan-hit-1' | translate}}
    <br><br>
  {{'file-scan-hit-2' | translate}}
</div>
<div *ngIf="!fileScan?.scanned">
  {{'file-scan-not-scanned-1' | translate}}
    <br><br>
  {{'file-scan-not-scanned-2' | translate}}
</div>

