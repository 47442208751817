<div class="case-basics-planned-travel-container" *ngIf="plannedTravel">
  <ul ngbNav #nav="ngbNav" class="nav-tabs">
    <li ngbNavItem>
      <a ngbNavLink>Planned travel</a>
      <ng-template ngbNavContent>
        <app-case-basics-planned-travel-details [plannedTravel]="plannedTravel" [plannedTravelSubPlans]="plannedTravelSubPlans" [customerProduct]="customerProduct"></app-case-basics-planned-travel-details>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</div>
