import { ServiceTypeBase } from './../../models/serviceTypeBase';
import { Component, Input, OnInit } from '@angular/core';
import { ServiceTypeComponent } from './../../models/interfaces';
import { LuggageServiceOrderExtension } from '@secca/shared/models/service-order/luggage-service-order-extension';

@Component({
  selector: 'app-luggage',
  templateUrl: './luggage.component.html',
})
export class LuggageComponent extends ServiceTypeBase implements OnInit, ServiceTypeComponent {
  @Input() luggage: LuggageServiceOrderExtension;
  @Input() caseId: string;

  constructor() {
    super();
  }


  ngOnInit() {}

  startDateChange(date) {
    this.luggage.startDate = date;
  }

  isValid(): boolean {
   return this.luggage.isValid();
  }
  getModel() {
    return this.luggage;
  }
}
