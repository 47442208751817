import { Component, Input } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CaseLinkedCasesService } from '@secca/core/services/case-linked-cases.service';
import { HandlingAreaStatusEnum } from '@secca/shared/models/enums';
import { LinkedCaseCreate } from '@secca/shared/models/linkedCaseCreate';
import { LinkedCasesList } from '@secca/shared/models/linkedCasesList';
import { LinkedModalLinkCaseComponent } from './linked-modal-link-case/linked-modal-link-case.component';
import { ModalDialogComponent } from '@secca/shared/components/modal-dialog/modal-dialog.component';
import { ModalDialogConfiguration } from '@secca/shared/models/modal/modal-dialog-configuration';
import { CaseValidationService } from '@secca/core/services/case-validation.service';
import { Router } from '@angular/router';
import { CoordinationCaseService } from '@secca/core/services/coordination-case.service';
import { CoordinationList } from '@secca/shared/models/coordination-link';

@Component({
  selector: 'app-linked',
  templateUrl: './linked.component.html',
  styleUrls: ['./linked.component.scss']
})
export class LinkedComponent {

  @Input()
  set caseId(CaseId: string) {
    if (CaseId !== undefined) {
      this._caseId = CaseId;
      this.reloadData();
    }
  }

  get caseId(): string {
    return this._caseId;
  }
  private _caseId: string;

  @Input() caseNumber: String;
  linkedCasesList: LinkedCasesList[] = [];
  // TODO ICC SCW
  coordinationCasesList: CoordinationList[] = [];
  createLink: LinkedCaseCreate;
  existingLinkedCaseNumbersOnCase: string[] = [];
  isCaseNumberAssigned: boolean = false;
  areLinkedCasesLoaded: boolean = false;
  areCoordinationCasesLoaded: boolean = true;

  constructor(private caseLinkedCasesService: CaseLinkedCasesService,
              private coordinationCaseService: CoordinationCaseService,
              private modalService: NgbModal,
              private caseValidationService: CaseValidationService,
              private router: Router,) { }

  linkModalRef: NgbModalRef;

  reloadData() {
    this.caseValid();
    this.caseLinkedCasesService.getLinkedCasesOnCase(this.caseId).subscribe(
      result => {
        this.linkedCasesList = result;
        this.linkedCasesList.forEach(linkedCase => this.existingLinkedCaseNumbersOnCase.push(linkedCase.caseNumber))
        this.areLinkedCasesLoaded = true;
      }
    );

    this.coordinationCaseService.getCoordinationCasesForLinkedCase(this.caseId).subscribe(
      result => {
        this.coordinationCasesList = result;
        this.coordinationCasesList.forEach(linkedCase => this.existingLinkedCaseNumbersOnCase.push(linkedCase.caseNumber))
        this.areCoordinationCasesLoaded = true;
      }
    );
  }

  redirectToCase(caseId: number) {
    this.router.navigate(['case', caseId]);
  }

  deleteLink(id: number) {
    const showCloseWarningMessageDialog = this.showCloseWarningMessageDialog();
    showCloseWarningMessageDialog.componentInstance.closeModalEvent.subscribe(emittedEvent => {
      if(emittedEvent) {
        this.caseLinkedCasesService.deleteCasesFromLinkedGroup(id, +this.caseId).subscribe(
          result => {
            this.linkedCasesList = result;
            this.caseLinkedCasesService.linkedCasesChanged(this.caseId, (this.linkedCasesList.length - 1));
            this.existingLinkedCaseNumbersOnCase = [];
            this.linkedCasesList.forEach(linkedCase => this.existingLinkedCaseNumbersOnCase.push(linkedCase.caseNumber))
          }
        )
      }
      showCloseWarningMessageDialog.close();
    });

    showCloseWarningMessageDialog.componentInstance.closeModalEvent.subscribe(
      emittedEvent => {
        showCloseWarningMessageDialog.close();
      },
      err => console.log(err)
    );
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  caseValid() {
    this.caseValidationService.isCaseNumberAssigned(this.caseId).subscribe(
      result => {
        this.isCaseNumberAssigned = result;
      }
    );
  }

  get HandlingAreaStatusEnum() {
    return HandlingAreaStatusEnum;
  }

  openModal() {
    this.createLink = new LinkedCaseCreate();
    this.linkModalRef = this.modalService.open(LinkedModalLinkCaseComponent);
    this.linkModalRef.componentInstance.caseNumberOnCase = this.caseNumber;
    this.linkModalRef.componentInstance.existingLinkedCaseNumbersOnCase = this.existingLinkedCaseNumbersOnCase;
    this.linkModalRef.componentInstance.linkCaseEvent.subscribe(
      caseNumberLinkedCase => {
        this.createLink.linkedCaseNumber = caseNumberLinkedCase;
        this.createLink.seccaCaseId = this.caseId;
        this.createLink.groupId = this.linkedCasesList.length != 0 ? this.linkedCasesList[0].groupId : null;

        this.areLinkedCasesLoaded = false;
        this.caseLinkedCasesService.linkCases(this.createLink).subscribe(
          result => {
            this.linkedCasesList = result;
            this.caseLinkedCasesService.linkedCasesChanged(this.caseId, (this.linkedCasesList.length - 1));
            this.existingLinkedCaseNumbersOnCase = [];
            this.linkedCasesList.forEach(linkedCase => this.existingLinkedCaseNumbersOnCase.push(linkedCase.caseNumber));
            this.areLinkedCasesLoaded = true;
            this.closeModal();
          },
          error => {
            this.areLinkedCasesLoaded = true;
            this.closeModal();
            let showWarningMessageDialog;
            if (error.status === 404) {
              showWarningMessageDialog = this.showWarningMessageDialog('case-basics-linked-case-not-exist');
            } else if (error.error.code === 'LINK_TO_COORDINATION_NOT_POSSIBLE') {
              showWarningMessageDialog = this.showWarningMessageDialog('case-basics-linked-case-coordination-case');
            } else if (error.error.code === 'LINK_TO_MAJOR_INCIDENT_NOT_POSSIBLE') {
              showWarningMessageDialog = this.showWarningMessageDialog('case-basics-linked-case-major-incident-case');
            } else if (error.error.code === 'LINKED_CASE_NOT_VALIDATED') {
              showWarningMessageDialog = this.showWarningMessageDialog('case-basics-linked-case-not-validated');
            } else if (error.error.code === 'CASE_IS_MEMBER_OF_OTHER_GROUPS') {
              showWarningMessageDialog = this.showWarningMessageDialog('case-basics-linked-already-link-to-other-case');
            }
            showWarningMessageDialog.componentInstance.closeModalEvent.subscribe(emittedEvent => {
              showWarningMessageDialog.close();
            });
          }
        );
      });
    this.linkModalRef.componentInstance.closeModal.subscribe(
      closeEvent => {
        this.closeModal();
        this.areLinkedCasesLoaded = true;
      },
      error => console.log(error)
    );
  }

  private showCloseWarningMessageDialog() : NgbModalRef {
    const confirmCloseDialog = this.modalService.open(ModalDialogComponent);
    confirmCloseDialog.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'default-modal-header',
      title: '',
      text: 'case-basics-linked-delete-link-to-case',
      footer: '',
      yes: 'default-modal-dialog-yes',
      no: 'default-modal-dialog-no',
      isBody: true,
      isFooter: true,
    });
    return confirmCloseDialog;
  }

  private showWarningMessageDialog(warningText: string): NgbModalRef {
    const confirmCloseDialog = this.modalService.open(ModalDialogComponent);
    confirmCloseDialog.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'default-modal-header',
      title: '',
      text: warningText,
      footer: warningText,
      yes: 'default-modal-dialog-yes',
      no: 'default-modal-dialog-no',
      isBody: true,
      isFooter: false,
    });
    return confirmCloseDialog;
  }
}
