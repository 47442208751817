import { ChannelType } from '@secca/shared/enums/channelType';
import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { PermissionEnum, StakeholderTypeEnum, TaskType } from '@secca/shared/models/enums';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import { PermissionService } from '@secca/core/services/permission.service';
import { IncomingCasesTaskViewModel } from '@secca/board/components/board-task/models/incoming-cases-task-view-model';
import { SimpleCaseStakeholder } from '@secca/shared/models/simpleCaseStakeholder';
import { SimpleCustomerProfile } from '@secca/shared/models/simpleCustomerProfile';
import { CaseLockOverlayAction, LockContextEnum } from '@secca/shared/enums/lock-context.enum';
import { ServiceItemType } from '@secca/shared/models/service-item-type';
import { PurposeOfTravelItemType } from '@secca/shared/models/purpose-of-travel-item-type';
import { CauseItemType } from '@secca/shared/models/cause-item-type';
import { TaskModalModeEnum } from '@secca/case/components/case-task/task-modal-static/task-modal-static-mode.enum';
import { DigitalConsentService } from '@secca/core/services/digital-consent.service';
import { DisplayTypeEnum } from '../task-web-properties/task-web-properties.component';
import * as _ from 'lodash';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { Diagnosis } from '@secca/shared/models/dignosis';

@Component({
  selector: 'app-task-web-details-panel',
  templateUrl: './task-web-details-panel.component.html',
  styleUrls: ['./task-web-details-panel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@AutoUnsubscribe
export class TaskWebDetailsPanelComponent implements OnChanges {
  @Input() taskDataViewModel: IncomingCasesTaskViewModel;
  @Input() serviceItemList: ServiceItemType[];
  @Input() purposeOfTravelItemList: PurposeOfTravelItemType[];
  @Input() causes: Map<string, CauseItemType[]>;
  @Input() diagnoses: Diagnosis[];
  @Input() customerProfiles: SimpleCustomerProfile[];
  @Input() isSupplierPortalChannel: boolean;

  propertiesDisplayTypes = {'incidentDescription': DisplayTypeEnum.FORMATTED_TEXT, 'remarks': DisplayTypeEnum.FORMATTED_TEXT};

  // Ignored properties
  ignoredProperties = ['id', 'properties', 'stakeholderType', 'nationalIdValid', 'isCompany'];
  travelIgnoredProperties = ['luggage'];
  insuranceIgnoredProperties = ['customerProductId', 'customerProductRelationId'];
  ignoredPropertiesByName = {'insurance': this.insuranceIgnoredProperties, 'travel': this.travelIgnoredProperties};

  // Hidden properties
  incidentHiddenProperties = ['streetNumber', 'streetName', 'locality', 'administrativeAreaLevel1', 'administrativeAreaLevel2', 'country', 'postalCode',
                              'latitude', 'longitude', 'countryCodeAlpha2', 'timeZoneId', 'utcOffsetInHours'];

  travelHiddenProperties = ['streetNumber', 'streetName', 'locality', 'administrativeAreaLevel1', 'administrativeAreaLevel2', 'country', 'postalCode',
                            'latitude', 'longitude', 'countryCodeAlpha2', 'timeZoneId', 'utcOffsetInHours'];

  nameToHiddenPropertiesMap = {'incident': this.incidentHiddenProperties, 'travel': this.travelHiddenProperties};
  hiddenPropertiesByName = { ...this.nameToHiddenPropertiesMap };

  endUser: SimpleCaseStakeholder;
  reporter: SimpleCaseStakeholder;
  policyHolder: SimpleCaseStakeholder;
  internalSummary: string;
  reporterIsEndUser = false;
  reporterIsPolicyHolder = false;
  endUserIsPolicyHolder = false;
  endUserIsReporter = false;

  constructor(
    private digitalConsentService: DigitalConsentService,
    public caseLockHelperService: CaseLockHelperService,
    public permissionService: PermissionService,
    public caseStateService: CaseStateService
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if ( this.taskDataViewModel ) {
      this.getRelevantInformation();
    }
  }

  showMore(name: string): void {
    this.hiddenPropertiesByName[name] = null;
  }

  showLess(name: string): void {
    const ignoredProperties = this.nameToHiddenPropertiesMap[name];
    this.hiddenPropertiesByName[name] = ignoredProperties;
  }

  hasHiddenProperties(name: string): boolean {
    return !!this.hiddenPropertiesByName[name];
  }

  hasNonNullHiddenProperty(name: string, ...obj: any): boolean {
    return !!this.hiddenPropertiesByName[name] && !!this.hiddenPropertiesByName[name]?.find(prop => obj && obj.find(o => o && o[prop]));
  }

  getHiddenProperties(name: string): string[] {
    return this.hiddenPropertiesByName[name];
  }

  getIgnoredProperties(name: string): string[] {
    const ignoredProperties = this.ignoredPropertiesByName[name] || [];
    const hiddenProperties = this.hiddenPropertiesByName[name] || [];

    const ignored = [...ignoredProperties, ...hiddenProperties];
    return ignored;
  }

  get TaskType() {
    return TaskType;
  }

  get TaskModalModeEnum() {
    return TaskModalModeEnum;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  get LockContextEnum(): any {
    return LockContextEnum;
  }

  get CaseLockOverlayAction(): any {
    return CaseLockOverlayAction;
  }

  private getRelevantInformation() {
    this.endUser = this.taskDataViewModel?.data?.caseStakeholders?.find(stakeHolder => stakeHolder.stakeholderType === StakeholderTypeEnum.endUser);
    this.reporter = this.taskDataViewModel?.data?.caseStakeholders?.find(stakeHolder => stakeHolder.stakeholderType === StakeholderTypeEnum.reporter);
    this.policyHolder = this.taskDataViewModel?.data?.caseStakeholders?.find(stakeHolder => stakeHolder.stakeholderType === StakeholderTypeEnum.policyHolder);
    this.internalSummary = this.taskDataViewModel?.internalSummary;

    if ( !this.endUser ) {
      if ( this.reporter?.properties?.includes(StakeholderTypeEnum.endUser) ) {
        this.endUser = this.reporter;
        this.reporterIsEndUser = true;
      }
    }

    if ( !this.policyHolder ) {
      if ( this.reporter?.properties?.includes(StakeholderTypeEnum.policyHolder) ) {
        this.policyHolder = this.reporter;
        this.reporterIsPolicyHolder = true;
      }
      else if ( this.endUser?.properties?.includes(StakeholderTypeEnum.policyHolder) ) {
        this.policyHolder = this.endUser;
        this.endUserIsPolicyHolder = true;
      }
    }

    if ( !this.reporter ) {
      if ( this.endUser?.properties?.includes(StakeholderTypeEnum.reporter) ) {
        this.reporter = this.endUser;
        this.endUserIsReporter = true;
      }
    }
  }

}
