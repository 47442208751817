<div class="modal-content">
  <div class="modal-header">
    <div class="modal-header-title">
      <span>{{"case-summary-sms-view-sms" | translate}}</span>
    </div>
    <div class="modal-buttons">
      <div (click)="close()">
        <img src="/assets/icons/Close White.svg" />
      </div>
    </div>
  </div>
  <ul ngbNav #nav="ngbNav" class="nav-tabs" [destroyOnHide]="false">
     <li ngbNavItem>
      <a ngbNavLink>
        <div> {{"task-message-modal-message" | translate}}</div>
      </a>
      <ng-template ngbNavContent>
        <div class="view">
          <div class="view-message-information">
            <div class="view-message-header" *ngIf="entry.direction === CommunicationDirection.IN_BOUND">
              <span class="view-message-header-title">{{"case-summary-sms-from" | translate}}: </span>
              <div class="view-message-header-value">{{entry.from}}</div>
            </div>
            <div class="view-message-header" *ngIf="entry.direction === CommunicationDirection.OUT_BOUND">
              <span class="view-message-header-title">{{"case-summary-sms-to" | translate}}: </span>
              <div class="view-message-header-value">{{entry.to}}</div>
            </div>
            <div class="view-message-header">
              <span class="view-message-header-title">{{"case-summary-sms-date" | translate}}: </span>
              <div class="view-message-header-value">{{entry.actionTime | date:'dd. MMM yyyy, hh:mm'}}</div>
            </div>
          </div>
          <div class="view-content" [innerHTML]="entry.content | safeHtml"></div>
          <div class="view-message-from" *ngIf="stakeholder">
            <div class="view-message-header" *ngIf="entry.direction === CommunicationDirection.IN_BOUND">
              <div class="view-message-header-title">{{"case-summary-sms-message-from" | translate}}</div>
            </div>
            <div class="view-message-header" *ngIf="entry.direction === CommunicationDirection.OUT_BOUND">
              <div class="view-message-header-title">{{"case-summary-sms-message-to" | translate}}</div>
            </div>
            <div>
              <img [src]="stakeholderIcon"><span class="stakeholder-name">{{stakeholderName}}</span>
            </div>
          </div>
        </div>
      </ng-template>
     </li>
     <li ngbNavItem *ngIf="caseEmail?.attachments?.length > 0">
      <a ngbNavLink>
        <div> {{"task-message-modal-attachments" | translate}} ({{caseEmail?.attachments?.length}}) </div>
      </a>
      <ng-template ngbNavContent>
        <div class="attachments-container">
          <div *ngFor="let attachment of caseEmail.attachments">
            <app-task-message-attachment [attachment]="attachment" [caseId]="entry.caseId"></app-task-message-attachment>
          </div>
        </div>
      </ng-template>
     </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>  
</div>
