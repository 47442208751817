import { SupplierInvoiceSaving } from './supplierInvoiceSaving';
import {
  BalanceSheetEntryItem,
  BalanceSheetEntryReduction,
} from '@secca/case/components/case-plans/case-plans/add-service-order/models/interfaces';
import { Adapter } from '../interfaces/adapter';
import { Injectable } from '@angular/core';
import { EconomyHistory } from './refund-history';
import moment from 'moment-timezone';
import { DateSerializer } from './date-serializer';
export class SupplierInvoiceItem implements BalanceSheetEntryItem, EconomyHistory {
  id: number;
  serviceOrderId: string;
  serviceItemCode: string;
  serviceItemName: string;
  isIncludingVAT: boolean;
  isInVATCreditorGroup: boolean;
  quantity: number;
  quantityUnit: string;
  paidAmount: number;
  exchangeRate: number;
  supplierId: number;
  creditorId: string;
  creditorName: string;
  calculatedFromGross: boolean;
  savings: SupplierInvoiceSaving[];
  modifiedBy: string;
  modifiedOn: moment.Moment;
  deletedBy: string;
  deletedOn: moment.Moment;

  public constructor(init?: Partial<SupplierInvoiceItem>) {
    Object.assign(this, init);
  }

  identicalHistory(histObject: EconomyHistory): boolean {
    return false;
  }

  getExchangeRate(): number {
    return this.exchangeRate;
  }

  getId(): number {
    return this.id;
  }

  getIsInVATCreditorGroup(): boolean {
    return this.isInVATCreditorGroup;
  }

  getIsIncludingVAT(): boolean {
    return this.isIncludingVAT;
  }

  getOriginalAmount(): number {
    return this.paidAmount;
  }

  getSavings(): BalanceSheetEntryReduction[] {
    return this.savings;
  }

  getServiceItemCode(): string {
    return this.serviceItemCode;
  }

  getServiceOrderId(): string {
    return this.serviceOrderId;
  }

  getQuantityUnit(): string {
    return this.quantityUnit;
  }

  getQuantity(): number {
    return this.quantity;
  }
}

@Injectable({
  providedIn: 'root',
})
export class SupplierInvoiceItemAdapter implements Adapter<SupplierInvoiceItem> {
  adapt(invoiceItem: any): SupplierInvoiceItem {
    const supplierInvoiceItem = new SupplierInvoiceItem({
      id: invoiceItem.id,
      serviceOrderId: invoiceItem.serviceOrderId,
      serviceItemCode: invoiceItem.serviceItemCode,
      serviceItemName: invoiceItem.serviceItemName,
      quantity: invoiceItem.quantity,
      quantityUnit: invoiceItem.quantityUnit,
      paidAmount: invoiceItem.paidAmount,
      isInVATCreditorGroup: invoiceItem.inCreditorSupplierGroup,
      calculatedFromGross: invoiceItem.calculatedFromGross,
      savings: [],
      modifiedBy: invoiceItem.modifiedBy,
      modifiedOn: invoiceItem.modifiedOn ? DateSerializer.deserialize(invoiceItem.modifiedOn) : null,
      deletedOn: invoiceItem.deletedOn ? DateSerializer.deserialize(invoiceItem.deletedOn) : null,
      deletedBy: invoiceItem.deletedBy,

    });
    if (invoiceItem.savings) {
      for (const saving of invoiceItem.savings) {
        supplierInvoiceItem.savings.push(
          new SupplierInvoiceSaving({
            id: saving.id,
            reductionTypeCode: saving.reductionTypeCode,
            lineNumber: saving.lineNumber,
            amount: saving.amount,
            typedInField: saving.typedInField,
            gross: saving.gross,
          })
        );
      }
    }
    return supplierInvoiceItem;
  }
}