import { Component, Input, OnInit } from '@angular/core';
import { ServiceTypeBase } from '../../models/serviceTypeBase';
import { ServiceTypeComponent } from '../../models/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { MedicalFlightAuthorizationServiceOrderExtension } from '@secca/shared/models/service-order/medical-flight-authorization-service-order-extension';

@Component({
  selector: 'app-medical-flight-authorization',
  templateUrl: './medical-flight-authorization.component.html',
})
export class MedicalFlightAuthorizationComponent extends ServiceTypeBase implements ServiceTypeComponent {
  @Input() medicalFlightAuthorization: MedicalFlightAuthorizationServiceOrderExtension;
  @Input() caseId: string;

  constructor(private translate: TranslateService) {
    super();
  }

  isValid(): boolean {
    return this.medicalFlightAuthorization.isValid();
  }

  getModel() {
    return this.medicalFlightAuthorization;
  }
}
