import { CaseIncident } from '@secca/shared/models/caseIncident';
import { PlannedTravel } from 'src/app/shared/models/planned-travel';
import { PersonInsurance } from '@secca/shared/models/person-insurance';
import { OtherInsurance  } from '@secca/shared/models/otherInsurance';
import { Case } from '@secca/shared/models/case';
import { CaseService } from '@secca/core/services/case.service';
import { Component, Input, OnInit } from '@angular/core';
import { OnRefresh } from 'src/app/shared/interfaces/on-refresh';
import { ActivatedRoute } from '@angular/router';
import { InsuranceService } from '@secca/core/services/insurance.service';
import { Subscription } from 'rxjs';
import { PlannedTravelService } from '@secca/core/services/planned-travel.service';
import { DigitalConsent } from '@secca/shared/models/digital-consent';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import * as _ from 'lodash-es';

@Component({
  selector: 'app-case-basics',
  templateUrl: './case-basics.component.html',
  styleUrls: ['./case-basics.component.scss']
})
@AutoUnsubscribe
export class CaseBasicsComponent implements OnInit, OnRefresh {
  @Input()
  set case(newCase: Case) {
    this._case = newCase;
  }
  get case(): Case {
    return this._case;
  }
  @Input() newCase: boolean;
  private _case: Case;
  @Input() consent: DigitalConsent;
  personInsurance: PersonInsurance;
  plannedTravel: PlannedTravel;
  plannedTravelSubPlans: PlannedTravel[];
  caseIncident: CaseIncident;
  otherInsurance: OtherInsurance;
  $insuranceSubscr: Subscription;
  customerProductId: number;
  @Input()
  numberOfLinkedCases: number;
  @Input() numberOfCoordinationCases: number;
  id: string;

  constructor(private caseService: CaseService,
              private insuranceService: InsuranceService,
              private plannedTravelService: PlannedTravelService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    if (this._case) {
      this.onRefresh();
    }
  }

  public onRefresh() {
    this.$insuranceSubscr = this.insuranceService.getPersonInsurance(this.case.id)
    .subscribe(
      result => {
        this.personInsurance =  _.clone(result);
        this.customerProductId = this.personInsurance.customerProduct;
      }
    );

    this.plannedTravelService.getPlannedTravel(this.case.id)
        .subscribe(result => this.plannedTravel = result);

    this.plannedTravelService.getPlannedTravelSubPlans(this.case.id)
        .subscribe(result => this.plannedTravelSubPlans = result);

    this.caseService.getOtherInsurance(this.case.id)
        .subscribe(result => this.otherInsurance = result);
  }
}
