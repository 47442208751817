import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';

export class Channel {
  id: number;
  channelTypeName: string;
  channelTypeCode: string;
  channelTypeEnumValue: string;
  uiSortIndex: number;
  activateOn: Date;
  deactivateOn: Date;

  public constructor(init?: Partial<Channel>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
export class ChannelAdapter implements Adapter<Channel> {
  adapt(item: any): Channel {
    return new Channel({
      id: item.id,
      channelTypeName: item.channelTypeName,
      channelTypeCode: item.channelTypeCode,
      channelTypeEnumValue: item.channelTypeEnumValue,
      uiSortIndex: item.uiSortIndex,
      activateOn: new Date(item.activateOn),
      deactivateOn: item.deactivateOn ? new Date(item.deactivateOn) : null
    });
  }
}

