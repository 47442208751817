import { Component, OnInit, Input, OnChanges, OnDestroy, EventEmitter, Output } from '@angular/core';
import { InboxTaskViewModel } from '../models/inbox-task-view.model';
import { AbstractGlobalBoardTableShortcutsComponent } from '@secca/shared/components/abstract-global-board-table-shortcuts.component';
import { DataService } from '@secca/core/services/data.service';
import { Router } from '@angular/router';
import { SettingsService } from '@secca/core/services/settings.service';
import { CaseService } from '@secca/core/services/case.service';
import { HandlingAreaStatusEnum, ShortcutEnum, SortOrder, TaskType, TaskTypeEnumIcon } from '@secca/shared/models/enums';
import { Subscription } from 'rxjs';
import { UserDto } from '@secca/shared/models/userDto';
import { TaskService } from '@secca/core/services/task.service';
import { TaskViewModel } from '../models/task-view.model';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import { LockContextEnum } from '@secca/shared/enums/lock-context.enum';
import { MatDialogRef } from '@angular/material/dialog';
import { TaskModalModeEnum } from '@secca/case/components/case-task/task-modal-static/task-modal-static-mode.enum';
import { DialogViewerService } from '@secca/core/services/dialog-viewer.service';
import { TaskRedirectFromGlobalTaskBoard } from '@secca/shared/models/task-redirect-from-global-task-board';
import { TaskHoverModel } from '../board-task-table/task-hover/task-hover-model';
import { TaskStateService } from '../services/task-state.service';
import { DictionaryService } from '@secca/core/services/dictionary.service';
import { TableSort } from '@secca/shared/models/table-sort';
import { TranslateService } from '@ngx-translate/core';
import { BoardNavigationStateService } from '../services/board-navigation-state.service';

@Component({
  selector: 'app-board-task-inbox-table',
  templateUrl: './board-task-inbox-table.component.html',
  styleUrls: ['./board-task-inbox-table.component.scss']
})
export class BoardTaskInboxTableComponent extends AbstractGlobalBoardTableShortcutsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() set taskViewModels(data: InboxTaskViewModel[]) {
    this._taskViewModels = data;
    this.numberOfRows = data?.length;
    setTimeout(() => {
      this.selectedRowIndex = this.boardNavigationStateService.getState('board-case-filter-request-inbox-selected-row-index') ? parseInt(this.boardNavigationStateService.getState('board-case-filter-request-inbox-selected-row-index'), 10) : 0;
      this.inputScrollPosition = this.boardNavigationStateService.getState('board-case-filter-request-inbox-task-scroll-position') ? parseInt(this.boardNavigationStateService.getState('board-case-filter-request-inbox-task-scroll-position'), 10) : 0;

      this.navigateToTable();
    }, 50);
  }
  get taskViewModels(): InboxTaskViewModel[] {
    return this._taskViewModels;
  }

  private _taskViewModels: InboxTaskViewModel[];
  selectedTaskViewModel: TaskViewModel;
  isAllSelected = false;
  inputScrollPosition = 0;
  loggedInUser: UserDto;
  loggedInUserSubscription: Subscription;
  redirectedToUrl = '';
  modalRef: MatDialogRef<any>;
  isCheckAll = false;
  sortedOn: any = { dueDate: true };
  sortOrder: any = {};

  @Output() updateTaskListAfterEdit = new EventEmitter();
  @Output() sortingAction = new EventEmitter<TableSort>();

  constructor(private dataService: DataService,
              private router: Router,
              public settingsService: SettingsService,
              private caseService: CaseService,
              private taskService: TaskService,
              private caseStateService: CaseStateService,
              private dictionaryService: DictionaryService,
              private caseLockHelperService: CaseLockHelperService,
              private dialogViewerService: DialogViewerService,
              private taskStateService: TaskStateService,
              private translateService: TranslateService,
              private boardNavigationStateService: BoardNavigationStateService
    ) {
    super('taskInboxTabel');
    this.loggedInUserSubscription = dictionaryService.getLoggedInUser()
      .subscribe(user => {
          this.loggedInUser = user;
      });
   }

  ngOnInit() {
    this.inputScrollPosition = this.boardNavigationStateService.getState('board-case-filter-request-inbox-task-scroll-position') ? parseInt(this.boardNavigationStateService.getState('board-case-filter-request-inbox-task-scroll-position'), 10) : 0;
    this.selectedRowIndex = this.boardNavigationStateService.getState('board-case-filter-request-inbox-selected-row-index') ? parseInt(this.boardNavigationStateService.getState('board-case-filter-request-inbox-selected-row-index'), 10) : 0;

    this.taskStateService.sendHoveredTask(undefined);
    this.shortcutSubscriptions.push(
      this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateTableRowCtrlEnter }).subscribe(_ => {
        if (typeof this.selectedRowIndex === 'number') {
          const taskViewModel = this.taskViewModels[this.selectedRowIndex];
          this.viewTask(taskViewModel);
        }
      }),
      this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateTableRowAltEnter }).subscribe(_ => {
        if (typeof this.selectedRowIndex === 'number') {
          this.redirectToTaskInCase(this.taskViewModels[this.selectedRowIndex]);
        }
      }),
    );
  }


  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.boardNavigationStateService.saveState('board-case-filter-request-inbox-task-scroll-position', JSON.stringify(this.inputScrollPosition));
    this.boardNavigationStateService.saveState('board-case-filter-request-inbox-selected-row-index', JSON.stringify(this.selectedRowIndex));
  }
  get HandlingAreaStatusEnum() {
    return HandlingAreaStatusEnum;
  }

  getTaskTypeImagePath(taskViewModel: InboxTaskViewModel): string {
    return TaskTypeEnumIcon.getIcon(taskViewModel.taskType, taskViewModel.status);
  }

  onCaseTabelScroll() {
    this.inputScrollPosition = document.getElementById('taskInboxTabel').scrollTop;
  }

  getBrandImagesUrlForProfile(profileId: string) {
    return this.caseService.getBrandImagesUrlForProfile(profileId);
  }

  redirectToTaskInCase(taskViewModel: InboxTaskViewModel) {
    if (taskViewModel.caseNumber) {
      this.taskService.taskRedirectFromGlobalTaskBoard = new TaskRedirectFromGlobalTaskBoard({
        caseId: taskViewModel.caseId,
        taskId: +taskViewModel.id,
        taskStatus: taskViewModel.status,
        directRedirection: true,
      });
      this.router.navigate(['case', taskViewModel.caseId]);
    }
  }


  viewTask(taskViewModel: InboxTaskViewModel) {
    this.redirectedToUrl = '';

    this.caseService.getCase(taskViewModel.caseId.toString()).subscribe(seccaCase => {
      this.caseStateService.sendCase(seccaCase);

      // Update caselocks to determine if loggedin user has already taken the lock
      this.caseLockHelperService.updateCaseLocks(seccaCase.id, seccaCase.caseLocks);

      const isCaseAlreadyLockedByTheLoggedInUser = this.caseLockHelperService.isCaseLockedByTheLoggedInUser(LockContextEnum.CASE_BASIC);
      if (!isCaseAlreadyLockedByTheLoggedInUser && !this.caseLockHelperService.isCaseBasicLockTaken) {
        // If not already locked by the loggedin user or others, we try to take the lock
        this.caseLockHelperService.lockCase(LockContextEnum.CASE_BASIC).subscribe();
      }

      this.openTaskModal(taskViewModel);
    });
  }

  caseDetailsEscort(taskViewModel: TaskViewModel): string {
    const mortalList: boolean[] = [taskViewModel.caseDetailsDOC, taskViewModel.caseDetailsNUR, taskViewModel.caseDetailsNME];
    let returnValue: string;
    const mortalListNumbers = mortalList.filter(m => m === true).length;
    if (mortalListNumbers === 1) {
      if (taskViewModel.caseDetailsDOC) { returnValue = this.translateService.instant('board-task-table-case-details-DOC'); }
      if (taskViewModel.caseDetailsNUR) { returnValue = this.translateService.instant('board-task-table-case-details-NUR'); }
      if (taskViewModel.caseDetailsNME) { returnValue = this.translateService.instant('board-task-table-case-details-NME'); }
    } else {
      returnValue = mortalListNumbers.toString();
    }
    return returnValue;
  }

  sortBy(column: string) {
    this.sortedOn = {};
    this.sortedOn[column] = true;
    if (this.sortOrder[column] == null) {
      this.sortOrder[column] = SortOrder.asc;
    } else {
      if (this.sortOrder[column] === SortOrder.asc) {
        this.sortOrder[column] = SortOrder.desc;
      } else {
        this.sortOrder[column] = SortOrder.asc;
      }
    }
    this.sortingAction.emit(new TableSort({ sortBy: column, sortOrder: this.sortOrder[column] }));
  }

  sortedBy(column: string): string {
    return this.sortedOn[column] ? 'sorted-by' : '';
  }

  private openTaskModal(taskViewModel: InboxTaskViewModel): void {
    this.selectedTaskViewModel = taskViewModel.convertToTaskViewModel();
    this.modalRef = null;
    if (
      taskViewModel.taskType === TaskType.email ||
      taskViewModel.taskType === TaskType.fax ||
      taskViewModel.taskType === TaskType.sms ||
      taskViewModel.taskType === TaskType.mms
    ) {
      this.dialogViewerService.openTaskMessageDialog(this.selectedTaskViewModel, false, null, () => this.taskUpdated());
    } else if (taskViewModel.taskType === TaskType.consent) {
      this.dialogViewerService.openTaskConsentDialog(this.selectedTaskViewModel, false, () => this.taskUpdated());
    } else if (taskViewModel.taskType === TaskType.external) {
      this.dialogViewerService.openTaskExternalDialog(this.selectedTaskViewModel, false, true);
    } else if (taskViewModel.taskType === TaskType.process) {
      this.dialogViewerService.openTaskDialog(this.selectedTaskViewModel, TaskModalModeEnum.partialViewOnly);
    } else {
      this.dialogViewerService.openTaskDialog(this.selectedTaskViewModel, TaskModalModeEnum.assignableOnly, false);
    }
  }

  mouseTitleEnter(event, taskViewModel: TaskViewModel) {
    const spanElement = event.srcElement;
    const tdElement = event.srcElement.parentNode;
    const tableElement = tdElement.parentNode.parentNode;
    const taskHoverModel: TaskHoverModel = new TaskHoverModel();
    taskHoverModel.title = taskViewModel.title;
    taskHoverModel.description = this.removeNewlines(taskViewModel.description);
    taskHoverModel.LeftHoverElemet = tdElement.offsetLeft - (taskHoverModel.width - tdElement.getBoundingClientRect().width);
    taskHoverModel.bottomHoverElement =
      tableElement.scrollTop +
      tableElement.getBoundingClientRect().height -
      (tdElement.offsetTop - tdElement.getBoundingClientRect().height) -
      15;
    taskHoverModel.leftTrianglePosition =
      taskHoverModel.width -
      tdElement.getBoundingClientRect().width +
      (spanElement.getBoundingClientRect().width > tdElement.getBoundingClientRect().width
        ? tdElement.getBoundingClientRect().width
        : spanElement.getBoundingClientRect().width) /
      2 -
      7;
    this.taskStateService.sendHoveredTask(taskHoverModel);
  }

  protected removeNewlines(description: string): string {
    if (description !== undefined && description !== null && description.includes('\n')) {
      return description.replace(/\n/g,'');
    } else {
      return description;
    }
  }

  assigneeShortName(assignInitials: string): string {
    return this.caseService.teamNameShortName(assignInitials);
  }

  taskUpdated() {
    this.updateTaskListAfterEdit.emit();
  }

  redirectToCase(caseId: number) {
    this.router.navigate(['case', caseId]);
  }

  mouseTitleLeave() {
    this.taskStateService.sendHoveredTask(undefined);
  }

  showMedicalServiceOrderType(taskViewModel: TaskViewModel): boolean {
    return  !taskViewModel.caseDetailsAIR &&
            !taskViewModel.caseDetailsSTR &&
            !taskViewModel.caseDetailsEXT &&
            !taskViewModel.caseDetailsSIT &&
            !taskViewModel.caseDetailsREG;
  }

  ngOnChanges() {
    setTimeout(() => this.setScrollPosition(), 500);
  }

  setScrollPosition() {
    if (this.inputScrollPosition !== null && document.getElementById('taskInboxTabel')) {
      document.getElementById('taskInboxTabel').scrollTop = this.inputScrollPosition;
    }
  }
}
