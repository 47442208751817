import { SortOrder } from './enums';

export class TaskCaseViewOptions {
  caseId: number;
  toDoSortOrder: SortOrder;
  toDoSortColumnName: string;
  toDoDateFrom: Date;
  toDoDateTo: Date;
  doneSortOrder: SortOrder;
  doneSortColumnName: string;
  doneDateFrom: Date;
  doneDateTo: Date;
  
  public constructor(init?: Partial<TaskCaseViewOptions>) {
    Object.assign(this, init);
  }
}
