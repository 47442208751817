import { Component, Inject, ViewChild } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { DialogBoundryService } from "@secca/core/services/dialog-boundry.service";
import { DialogHelperUtilService } from "@secca/core/services/dialog-helper-util.service";
import { DialogMinimizableDirective } from "@secca/shared/directives/dialog-minimizable.directive";
import { MinimizableDialogType } from "@secca/shared/enums/minimizable-dialog-type-enum";
import { AdditionalInfo, IAdditionalInfo } from "@secca/shared/interfaces/additional-info";
import { IMinimizable } from "@secca/shared/interfaces/minimizable";
import { CaseSummarySmsDialogData } from "./case-summary-sms-dialog-data.component";
import { CaseSummarySmsModalComponent } from "../case-summary-sms-modal/case-summary-sms-modal.component";
import { IKeyboardEnabled } from "@secca/shared/interfaces/keyboard-enabled";

@Component({
    selector: 'app-case-summary-sms-dialog',
    templateUrl: './case-summary-sms-dialog.component.html',
    styleUrls: ['./case-summary-sms-dialog.component.scss'],
  })
  export class CaseSummarySmsDialogComponent implements IAdditionalInfo, IMinimizable, IKeyboardEnabled {
    @ViewChild(DialogMinimizableDirective) minimizedDirective: DialogMinimizableDirective;
    @ViewChild(CaseSummarySmsModalComponent) wrappedInstance: CaseSummarySmsModalComponent;

    data: CaseSummarySmsDialogData;

    constructor(private dialogRef: MatDialogRef<CaseSummarySmsDialogComponent>,
                @Inject(MAT_DIALOG_DATA) data: CaseSummarySmsDialogData,
                private dialogHelperUtilService: DialogHelperUtilService,
                public dialogBoundryService: DialogBoundryService) {
              
      this.data = data;
    }

    close() {
      this.dialogHelperUtilService.close(this.dialogRef, this.getAdditionalInfo()?.caseId);
    }

    keyboardClose(): void {
      this.wrappedInstance.close();
    }

    minimize() {
      this.minimizedDirective.minimize();
    }

    getMinimizeType(): MinimizableDialogType {
      return this.minimizedDirective.appDialogMinimizable;
    }

    getAdditionalInfo(): AdditionalInfo {

      return {
        caseId: +this.data.entry.caseId,
        hoverTitle: this.data?.caseNumber ? this.data.caseNumber : '',
        hoverText: 'View SMS'
      };
    }

    get MinimizableDialogType() {
      return MinimizableDialogType;
    }
  }
