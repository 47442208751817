<div appDialogSelectable appDialogKeyboardEnabled [appDialogMinimizable]="MinimizableDialogType.MedicalAssessment">
  <mat-dialog-content (cdkDragStarted)="dialogBoundryService.handleDragStart($event)" (cdkDragEnded)="dialogBoundryService.handleDragEnd($event)" cdkDragRootElement=".cdk-overlay-pane" cdkDrag>
    <div appDialogDragArea cdkDragHandle></div>
    <app-medical-assessment appDialogModal [case]="data.case" 
                            [medicalAssessmentType]="data.medicalAssessmentType"
                            [medicalAssessmentId]="data.medicalAssessmentId"
                            [disabledViewOfMedicalAssessment]="data.disabledViewOfMedicalAssessment"
                            (updateDraftButton)="data.updateDraftButtonEvent ? data.updateDraftButtonEvent() : null"
                            (refreshMedicalHistory)="data.refreshMedicalHistoryEvent ? data.refreshMedicalHistoryEvent() : null"
                            (closed)="close()"></app-medical-assessment>
  </mat-dialog-content>
</div>
