import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ 
name: 'translateIn',
pure: true
})
export class TranslateInPipe implements PipeTransform {

  constructor(protected translateService: TranslateService,
              protected translationInPipeService: TranslateInPipeService) {}
  
  transform(key: string, langCode: string)  {
    return this.translationInPipeService.translate(key, langCode);
  };
}

@Injectable({
  providedIn: 'root'
})
export class TranslateInPipeService {
  translations = {};

  constructor(protected translateService: TranslateService,
              protected http: HttpClient) {    
    }

    public async translate(key: string, langCode: string) {
      const translations = await this.getTranslations(langCode);
      return translations[key];
    }
  
    public async getTranslations(langCode: string) {
      return this.translations[langCode] ? this.translations[langCode] : this.loadTranslation(langCode);
    }

    public async loadTranslation(langCode: string) {
      this.translations[langCode] = await this.http.get("/assets/i18n/" + langCode + ".json").toPromise();
      return this.translations[langCode];
    }
};
