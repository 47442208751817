import { MajorIncidentStakeholder, MajorIncidentStakeholderAdapter } from "@secca/case/components/case-plans/case-plans/add-service-order/models/majorIncidentStakeholder";
import { CommitmentType, CommitmentTypeAdapter } from "../commitment-type";
import { NoDurationServiceOrderExtension } from "./no-duration-service-order-extension";
import { ServiceOrderExtension } from "./service-order-extension";
import { MomentHelperService } from '@secca/core/services/moment-helper.service';
import { MajorIncidentStakeholders } from "@secca/case/components/case-plans/case-plans/add-service-order/models/majorIncidentStakeholders";
import { DateSerializer } from "../date-serializer";
import { MajorIncidentCategory } from "../enums";
import { Location } from '@secca/shared/models/location';
import * as moment from 'moment';
export class MajorIncidentServiceOrderExtension extends NoDurationServiceOrderExtension implements ServiceOrderExtension {

    id: number;
    startDate: moment.Moment = MomentHelperService.getSeccaTime().startOf('day').utc();
    commitmentType: CommitmentType;
    displayDurationId: number;
    majorIncidentCategory: MajorIncidentCategory;
    confirmationMajorIncidentCategory: MajorIncidentCategory;
    subExpectedStakeholders: MajorIncidentStakeholders;
    expectedStakeholders: MajorIncidentStakeholder[];
    subConfirmationStakeholders: MajorIncidentStakeholders;
    confirmationStakeholders: MajorIncidentStakeholder[];
    location: Location = new Location();


    public constructor(init?: Partial<MajorIncidentServiceOrderExtension>) {
      super();
      if (init) {
        Object.assign(this, init);
      }
      if (!init) {
        this.init();
      }

      this.expectedStakeholders = this.subExpectedStakeholders.stakeholders;
    }

    init() {
      this.startDate = moment.utc();
      this.subExpectedStakeholders = new MajorIncidentStakeholders();
    }

    isValid(): boolean {
        return true;
      }

    isPreviewGopValid(): boolean {
    return true;
    }

    setCommitmentType(commitmentType: CommitmentType) {
      this.commitmentType = commitmentType;
    }

    getSelectedStakeholderIds(): string[] {
    return null;
    }

    getCommitmentType(): CommitmentType {
      return this.commitmentType;
    }

    getMajorIncidentCategory(): MajorIncidentCategory {
      return this.majorIncidentCategory;
    }

    getStartLocation(): Location {
      return this.location;
    }
}

export class MajorIncidentServiceOrderExtensionAdapter {
  commitmentTypeAdapter: CommitmentTypeAdapter = new CommitmentTypeAdapter();
  adapt(item?: any): MajorIncidentServiceOrderExtension {
    const commitmentTypeAdapter: CommitmentTypeAdapter = new CommitmentTypeAdapter();
    const majorIncidentStakeholderAdapter = new MajorIncidentStakeholderAdapter();

    return new MajorIncidentServiceOrderExtension({
      id: item.id,
      commitmentType: item.commitmentType != null ?  commitmentTypeAdapter.adapt(item.commitmentType) : null,
      startDate: DateSerializer.deserialize(item.startDate),
      subExpectedStakeholders: item.expectedStakeholders ? new MajorIncidentStakeholders(item.expectedStakeholders.map(stakeholder => majorIncidentStakeholderAdapter.adapt(stakeholder))) : new MajorIncidentStakeholders(),
      majorIncidentCategory: item.majorIncidentCategory,
      location: item.location ? item.location : new Location(),
    });
  }
}
