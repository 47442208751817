<div class="questionnaire-panel flex">
  <div class="message-panel p-3">
    <div class="border-bottom row ps-2">
      <img class="" src="assets/icons/task/small/medical-pre-assessment-todo.svg">
      <p class="mt-3 mx-3 pt-1"> {{ 'medical-pre-assessment-questionnaire' | translate}}</p>
    </div>
    <div class="" *ngFor="let questionnaire of taskDataViewModel?.data?.questionnaire; index as i">
        <div class="diagnosis-container">
          <div class="row">
            <app-task-web-properties class="diagnosis-text col-12 row"
                [properties]="questionnaire" [ignoredProperties]="getDiagnosisTextIgnoredProperties(questionnaire)"
                [serviceItemList]="serviceItemList" [purposeOfTravelItemList]="purposeOfTravelItemList"
                [causes]="causes" [diagnoses]="diagnoses" [customerProfiles]="customerProfiles">
            </app-task-web-properties>
          </div>
          <div class="row">
            <app-task-web-properties class="diagnosis-code col-12 row"
                [properties]="questionnaire.diagnosis" [ignoredProperties]="diagnosisCodeIgnoredProperties"
                [serviceItemList]="serviceItemList" [purposeOfTravelItemList]="purposeOfTravelItemList"
                [causes]="causes" [diagnoses]="diagnoses" [customerProfiles]="customerProfiles">
          </app-task-web-properties>
          </div>
        </div>
        <div class="row border-bottom display-block">
          <div *ngFor="let question of questionnaire?.questions; index as i">
            <app-task-web-properties class="question-panel row"
                [properties]="question" [ignoredProperties]="questionnaireIgnoredProperties"
                [serviceItemList]="serviceItemList" [purposeOfTravelItemList]="purposeOfTravelItemList"
                [causes]="causes" [diagnoses]="diagnoses" [customerProfiles]="customerProfiles">
            </app-task-web-properties>
          </div>
        </div>
    </div>
  </div>
</div>
