import {ComponentRef, Directive, ElementRef, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {Overlay, OverlayConfig, OverlayPositionBuilder, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {StakeholderTooltipComponent} from './stakeholder-tooltip.component';

@Directive({selector: '[appStakeholderTooltip]'})
export class StakeholderTooltipDirective implements OnInit, OnDestroy {
  @Input('appStakeholderTooltip') stakeholderId: string;

  private overlayRef: OverlayRef;
  private shown = false;

  constructor(private overlayPositionBuilder: OverlayPositionBuilder,
              private elementRef: ElementRef,
              private overlay: Overlay) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.hide();
    this.overlayRef = null;
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: MouseEvent) {
    if (this.elementRef.nativeElement.contains(event.target)) {
      this.toggle();
    } else {
      this.shown = false;
      this.hide();
    }
  }

  toggle() {
    if (this.shown) {
      this.hide();
    } else {
      this.show();
    }
    this.shown = !this.shown;
  }

  show() {
    this.createOverlay();
    const tooltipPortal = new ComponentPortal(StakeholderTooltipComponent);
    const tooltipRef: ComponentRef<StakeholderTooltipComponent> = this.overlayRef.attach(tooltipPortal);
    tooltipRef.instance.stakeholderId = this.stakeholderId;
  }

  hide() {
    if ( this.overlayRef ) {
      this.overlayRef.detach();
    }
  }


  private createOverlay(): void {
    if ( !this.overlayRef ) {
      const config = new OverlayConfig();
      config.positionStrategy = this.overlayPositionBuilder
        .flexibleConnectedTo(this.elementRef)
        .withPositions([{
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
          offsetY: 14,
          offsetX: -5
        }]);

      this.overlayRef = this.overlay.create(config);
    }
  }
}
