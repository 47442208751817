import { StatusTypes } from "@secca/shared/models/enums";

export class ICCStakeholder {
  id: number;
  caseId: string;
  serviceOrderId: string | number;
  passengerConfirmed: boolean;
  stakeholderId: string;
  serviceOrderStatus: StatusTypes;

  public constructor(init?: Partial<ICCStakeholder>) {
    Object.assign(this, init);
  }

  isFilled() {
    return !!this.serviceOrderId || !!this.stakeholderId
  }
}

export class ICCStakeHolderAdapter {

    adapt(item: any): ICCStakeholder {
      const iccStakeholder = new ICCStakeholder({
        caseId: item.caseId,
        stakeholderId: item.stakeholderId,
        serviceOrderId: item.serviceOrderId,
        passengerConfirmed: item.passengerConfirmed,
        serviceOrderStatus: item.serviceOrderStatus ? item.serviceOrderStatus : null
      });
      return iccStakeholder;
    }
  }
