import { HelperService } from './../../core/services/helper.service';
import { AttachmentDto } from '@secca/shared/models/attachment-dto';
import { Adapter } from './../interfaces/adapter';
import { Injectable } from '@angular/core';
import { AttachmentTypeEnum } from './enums';

export class MessageTemplate {
  id: string;
  languageCode: string;
  templateType: string;
  receiverType: string;
  serviceType: string;
  displayName: string;
  featureType: string;
  tags: string[];
  attachments: AttachmentDto[];
  subject: string;
  documentType: string;

  public constructor(init?: Partial<MessageTemplate>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
export class MessageTemplateAdapter implements Adapter<MessageTemplate> {
  constructor(private helperService: HelperService) {}

  adapt(item: any): MessageTemplate {
    return new MessageTemplate({
      id: item.id,
      languageCode: item.languageCode,
      templateType: item.templateType,
      receiverType: item.receiverType,
      serviceType: item.serviceType,
      featureType: item.featureType,
      displayName: item.displayName,
      subject: item.subject,
      documentType: item.documentType,
      tags: item.tags,
      attachments:
        item.attachments == null
          ? []
          : item.attachments
              .filter(attachment => attachment)
              .map(
                attachment =>
                  new AttachmentDto({
                    documentName: attachment,
                    documentExtension: attachment.split('.').length > 1 ? attachment.split('.')[attachment.split('.').length - 1] : '',
                    documentId: this.helperService.getRandomGuid(),
                    attachmentType: AttachmentTypeEnum.FROM_TEMPLATE,
                  })
              ),
    });
  }
}
