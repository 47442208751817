<div class="d-flex" [class.even]="index % 2">
    <div class="history-table-cell align-top">
        <app-user-picture [imageURL]="entry.picture" [active]="'true'" [imageClass]="'caseSummery'">
        </app-user-picture>
        <span class="headline initials" *ngIf="!entry.previousValue">
            {{entry.initials}} {{'case-history-economy-customer-invoice-item-created' | translate }} {{entry.newValue.id}}
        </span>
        <span class="headline initials" *ngIf="entry.previousValue && !entry.newValue.deletedOn">
            {{entry.initials}} {{ 'case-history-economy-customer-invoice-item-updated' | translate }} {{entry.newValue.id}}
        </span>
        <span class="headline initials" *ngIf="entry.previousValue && entry.newValue.deletedOn">
            {{entry.initials}} {{ 'case-history-economy-customer-invoice-item-deleted' | translate }} {{entry.newValue.id}}
        </span>
        <div class="elementDateTime">{{ entry.newValue.modifiedOn | date: 'd MMM y - HH:mm' }}</div>
    </div>
    <div class="history-table-cell align-top">
        <div class="headline">
            <span *ngIf="entry.previousValue">
                {{ 'case-history-list-original-value' | translate }}
            </span>
        </div>
        <app-previous-value [entry]="entry" [attribute]="'feeType'"></app-previous-value>
        <app-previous-value [entry]="entry" [attribute]="'feeName'"></app-previous-value>
        <app-previous-value [entry]="entry" [attribute]="'appliedDate'" [display]="'date'"></app-previous-value>
        <app-previous-value [entry]="entry" [attribute]="'agreementPrice'" [display]="'currency'"></app-previous-value>
        <app-previous-value [entry]="entry" [attribute]="'invoicePrice'" [display]="'currency'"></app-previous-value>
        <app-previous-value [entry]="entry" [attribute]="'vatPrice'" [display]="'currency'"></app-previous-value>
        <app-previous-value [entry]="entry" [attribute]="'actionComment'"></app-previous-value>
        <app-previous-value [entry]="entry" [attribute]="'status'"></app-previous-value>
        <app-previous-value [entry]="entry" [attribute]="'suggestion'"></app-previous-value>
        <app-previous-value [entry]="entry" [attribute]="'sosServicesCategory'"></app-previous-value>
        <app-previous-value [entry]="entry" [attribute]="'serviceOrderId'"></app-previous-value>
    </div>
    <div class="history-table-cell align-top">
        <div class="headline">
            <span>
                {{ 'case-history-list-new-value' | translate }}
            </span>
        </div>
        <app-new-value [entry]="entry" [attribute]="'feeType'"></app-new-value>
        <app-new-value [entry]="entry" [attribute]="'feeName'"></app-new-value>
        <app-new-value [entry]="entry" [attribute]="'appliedDate'" [display]="'date'"></app-new-value>
        <app-new-value [entry]="entry" [attribute]="'agreementPrice'" [display]="'currency'"></app-new-value>
        <app-new-value [entry]="entry" [attribute]="'invoicePrice'" [display]="'currency'"></app-new-value>
        <app-new-value [entry]="entry" [attribute]="'vatPrice'" [display]="'currency'"></app-new-value>
        <app-new-value [entry]="entry" [attribute]="'actionComment'"></app-new-value>
        <app-new-value [entry]="entry" [attribute]="'status'"></app-new-value>
        <app-new-value [entry]="entry" [attribute]="'suggestion'"></app-new-value>
        <app-new-value [entry]="entry" [attribute]="'sosServicesCategory'"></app-new-value>
        <app-new-value [entry]="entry" [attribute]="'serviceOrderId'"></app-new-value>
    </div>
</div>