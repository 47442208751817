<div
  #serviceContainer
  [ngStyle]="serviceStyle"
  class="service"
  [ngClass]=" {'icc-linked' : !!serviceOrder.isLinkedToIcc }"
  (dblclick)="openServiceForEdit()"
  (click)="showServiceOrderHover($event)"
  (mouseenter)="mouseEnterServiceOrder()"
  (mouseleave)="mouseLeaveServiceOrder()"
  *ngIf="isVisible"
  [ngbPopover]="popContent" triggers="mouseenter:mouseleave" [placement]="serviceOrder.laneNumber === 0 ? 'end' : 'start'" openDelay="1000"
>
  <div class="service-diagonal-background" [ngStyle]="serviceDiagonalBackgroundStyle"></div>
  <div class="service-left-line" [ngStyle]="serviceLeftLineStyle"></div>
  <div class="service-container">
    <div class="service-container-image" [ngStyle]="serviceImageStyle"></div>
    <div class="service-container-date">
      <div [ngSwitch]="serviceOrderGroup">
        <div *ngSwitchCase="1">
          <div *ngIf="serviceOrder.getStartLocation()" class="location">
            {{ serviceOrder.getStartDate() | momentFormat: 'HH:mm':serviceOrder.getStartLocation().timeZoneId }} {{
              serviceOrder.getStartLocation().freeText
            }}
          </div>
          <div>{{ supplierName }}</div>
        </div>
        <div *ngSwitchCase="2">
          <div>{{ serviceOrder.getStartDateLocal() | date: 'HH:mm':'local' }}</div>
          <div *ngIf="serviceOrder.getEndDateLocal()">{{ serviceOrder.getEndDateLocal() | date: 'HH:mm':'local' }}</div>
          <div *ngIf="height >= 70">
            <div>{{ supplierName }}</div>
          </div>
        </div>
        <div *ngSwitchCase="3">
          <div *ngIf="serviceOrder.getStartLocation()" class="location">
            <span>
            {{ serviceOrder.getStartDisplayDate() ? (serviceOrder.getStartDisplayDate() | momentFormat: 'HH:mm':serviceOrder.getStartLocation().timeZoneId) : '-- : --' }}
            </span>
            <span *ngIf="serviceOrder.getStartLocation()?.freeText">
              {{ serviceOrder.getStartLocation().freeText }}
            </span>
          </div>
          <div *ngIf="serviceOrder.getEndLocation()" class="location">
            <span>
              {{ serviceOrder.getEndDisplayDate() ? (serviceOrder.getEndDisplayDate() | momentFormat: 'HH:mm':serviceOrder.getEndLocation().timeZoneId) : '-- : --' }}
            </span>
            <span *ngIf="serviceOrder.getEndLocation()?.freeText">
              {{ serviceOrder.getEndLocation().freeText }}
            </span>
          </div>
          <div *ngIf="height >= 70">
            <div>{{ supplierName }}</div>
          </div>
        </div>
        <div *ngSwitchDefault>
          <div>{{ serviceOrder.getStartDateLocal() | date: 'HH:mm':'local' }}</div>
          <div *ngIf="serviceOrder.getStartLocation()?.country">
            {{ serviceOrder.getStartDate() | momentFormat: 'HH:mm':serviceOrder.getStartLocation().timeZoneId }} ({{
              serviceOrder.getStartLocation().country
            }})
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #popContent>
    <app-new-service-order-hover [caseStakeholder]="caseStakeholder" [serviceOrderHoverModel]="serviceOrderHoverModel"></app-new-service-order-hover>
  </ng-template>
</div>
