import { Component, Input } from '@angular/core';
import { MedicalAssessmentStateService } from '@secca/core/services/medical-assessment-state.service';
import { Subscription } from 'rxjs';
import { MedicalAssessmentState } from '../medical-models';
import { MedicalAssessmentComponent } from '../../medical-assessment/medical-assessment.component';

@Component({
  selector: 'app-medical-assessment-menu',
  templateUrl: './medical-assessment-menu.component.html',
  styleUrls: ['./medical-assessment-menu.component.scss']
})
export class MedicalAssessmentMenuComponent {
  stateSubscription: Subscription;
  @Input() state: MedicalAssessmentState;
  @Input() formValid = true;
  constructor(
    private assessmentSateService: MedicalAssessmentStateService,
    private medicalAssessmentComponent: MedicalAssessmentComponent) { }

  checkMark(valid: boolean) {
    if (valid) {
      return 'assets/icons/Success.svg';
    } else {
      return '/assets/icons/Failure.svg';
    }
  }

  scrollToElement(element) {
    this.medicalAssessmentComponent.scrollToElement(element);
  }
}
