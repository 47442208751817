<div [appDisableInput]="caseStateService.isCaseDisabled()">
  <div class="row">
    <div class="col-6"></div>
    <div class="col-6">
      <div class="row header-amounts">
        <div class="col">
          <div class="row">
            <div class="col text-end amount-name">{{ 'case-economy-purchase-and-sales-total-purchase-amount' | translate }}</div>
          </div>
          <div class="row">
            <div class="col">
              <div class="d-flex">
                <div class="flex-fill text-end amount-value">{{ totalPurchaseAmount | number: '.2' }}</div>
                <div class="amount-currency">{{ caseCurrency }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="row">
            <div class="col text-end amount-name">{{ 'case-economy-purchase-and-sales-total-sales-amount' | translate }}</div>
          </div>
          <div class="row">
            <div class="col">
              <div class="d-flex">
                <div class="flex-fill text-end amount-value">{{ totalSalesAmount | number: '.2' }}</div>
                <div class="amount-currency">{{ caseCurrency }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="row">
            <div class="col text-end amount-name">{{ 'case-economy-purchase-and-sales-total-net-amount' | translate }}</div>
          </div>
          <div class="row">
            <div class="col">
              <div class="d-flex">
                <div class="flex-fill text-end">
                  <div
                    [ngClass]="{
                      'net-amount-positive': totalNetAmount >= 0,
                      'net-amount-negative': totalNetAmount < 0
                    }"
                  >
                    {{ totalNetAmount | number: '.2' }}
                  </div>
                </div>
                <div class="amount-currency">{{ caseCurrency }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="row">
            <div class="col text-end amount-name">{{ 'case-economy-purchase-and-sales-total-net-percent' | translate }}</div>
          </div>
          <div class="row">
            <div class="col">
              <div class="d-flex">
                <div class="flex-fill text-end">
                  <div
                    [ngClass]="{
                      'net-amount-positive': totalNetAmount >= 0,
                      'net-amount-negative': totalNetAmount < 0
                    }"
                  >
                    {{ totalNetPercent | number: '.2' }}
                  </div>
                </div>
                <div class="amount-currency">%</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-12 table-container">
  <table class="primary-table">
    <thead>
      <tr>
        <td class="thead" sortColumn [sortKey]="'serviceItem'" [data]="combinedPurchaseAndSalesItems">
          {{ 'case-economy-purchase-and-sales-service-item' | translate }}
        </td>
        <td class="thead" sortColumn [sortKey]="'supplierName'" [data]="combinedPurchaseAndSalesItems">
          {{ 'case-economy-purchase-and-sales-supplier' | translate }}
        </td>
        <td class="thead" sortColumn [sortKey]="'creditorId'" [data]="combinedPurchaseAndSalesItems">
          {{ 'case-economy-purchase-and-sales-creditor' | translate }}
        </td>
        <td class="thead type-col" sortColumn [sortKey]="'type'" [data]="combinedPurchaseAndSalesItems">
          {{ 'case-economy-purchase-and-sales-type' | translate }}
        </td>
        <td class="thead" sortColumn [sortKey]="'purchaseAmount'" [data]="combinedPurchaseAndSalesItems">
          {{ 'case-economy-purchase-and-sales-purchase-amount' | translate }}
        </td>
        <td class="thead" sortColumn [sortKey]="'salesAmount'" [data]="combinedPurchaseAndSalesItems">
          {{ 'case-economy-purchase-and-sales-sales-amount' | translate }}
        </td>
        <td class="thead net-col" sortColumn [sortKey]="'netAmount'" [data]="combinedPurchaseAndSalesItems">
          {{ 'case-economy-purchase-and-sales-net-amount' | translate }}
        </td>
      </tr>
    </thead>
    <tbody class="theBody">
      <ng-container *ngFor="let purchaseAndSalesItem of combinedPurchaseAndSalesItems; index as i">
        <tr>
          <td [ngClass]="{ even: i % 2 != 0 }">
            {{ purchaseAndSalesItem.serviceItem }}
          </td>
          <td [ngClass]="{ even: i % 2 != 0 }">{{ purchaseAndSalesItem.supplierName }}</td>
          <td [ngClass]="{ even: i % 2 != 0 }">
            {{ purchaseAndSalesItem.creditorId }}
            <div class="creditor-name">{{ purchaseAndSalesItem.creditorName }}</div>
          </td>
          <td class="type-col" [ngClass]="{ even: i % 2 != 0 }">{{ purchaseAndSalesItem.type }}</td>
          <td [ngClass]="{ even: i % 2 != 0 }">
            <div class="d-flex">
              <div class="flex-fill currency">{{ caseCurrency }}</div>
              <div class="amount-positive">
                {{ purchaseAndSalesItem.purchaseAmount | number: '.2-2' }}
              </div>
            </div>
          </td>
          <td [ngClass]="{ even: i % 2 != 0 }">
            <div class="d-flex">
              <div class="flex-fill currency">{{ caseCurrency }}</div>
              <div class="amount-positive">{{ purchaseAndSalesItem.salesAmount | number: '.2-2' }}</div>
            </div>
          </td>
          <td class="net-col" [ngClass]="{ even: i % 2 != 0 }">
            <div class="d-flex">
              <div class="flex-fill currency">{{ caseCurrency }}</div>
              <div class="amount-positive">{{ purchaseAndSalesItem.netAmount | number: '.2-2' }}</div>
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
