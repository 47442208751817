import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DocumentService } from '@secca/core/services/document.service';
import { InsuranceService } from '@secca/core/services/insurance.service';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { PolicyLookupMethodType, PolicyProduct } from '@secca/shared/models/policy-lookup-response';
import { Case } from '@secca/shared/models/case';
import { CustomerProductInfo } from '@secca/shared/models/customer-product-info';
import { ShortcutService } from '@secca/core/services/shortcut.service';
import { ShortcutEnum } from '@secca/shared/models/enums';

@Component({
  selector: 'app-case-terms',
  templateUrl: './case-terms.component.html',
  styleUrls: ['./case-terms.component.scss']
})
@AutoUnsubscribe
export class CaseTermsComponent implements OnInit, OnDestroy {
  @Input()
  set case(value: Case) {
    this._case = value;
  }
  get case() {
    return this._case;
  }
  private _case;
  termsDocumentId: string;
  guidelineUri: string;
  termsName: string;
  productVersion: string;
  private product = new PolicyProduct();
  private insuranceSubscription: Subscription;
  private GUIDELINE_URL_PREFIX = 'ms-word:ofv|u|';

  private shortcutSubscriptions: Subscription[] = [];

  constructor(private documentService: DocumentService,
              private insuranceService: InsuranceService,
              private shortcutService: ShortcutService) {
    // this.shortcutSubscriptions.push(
    //   this.shortcutService.addShortcut({ keys: ShortcutEnum.OpenGuideDocument }).subscribe(a => {
    //     if (this.guidelineUri) {
    //       this.openGuideDocument();
    //     }
    //   }),
    //   this.shortcutService.addShortcut({ keys: ShortcutEnum.OpenTermsDocument }).subscribe(a => {
    //     if (this.termsDocumentId) {
    //       this.openTermsDocument();
    //     }
    //   }),
    // );
  }

  // TODO NPN refactor?
  private subscribePersonInsurance() {
    this.insuranceSubscription = this.insuranceService.getPersonInsurance(this.case.id).subscribe(
      insurance => {
        if (!insurance) {
          this.resetValues();
          return;
        }
        if (insurance.insuranceLookupPolicy && insurance.insuranceLookupPolicy.lookupExecution
          && PolicyLookupMethodType.SECCA_POLICY_LOOKUP === insurance.insuranceLookupPolicy.lookupExecution.type) {
          if (insurance.insuranceLookupPolicy.product != null) {
            this.productChange( insurance.insuranceLookupPolicy.product );
          } else {
            this.resetValues();
          }
        } else {
          if (!insurance.customerProduct) {
            this.resetValues();
          } else {
            if (this?.product?.id !== insurance.customerProduct) {
              this.product.id = insurance.customerProduct;
              this.insuranceService.getProduct(insurance.customerProduct).subscribe(
              product => {
                  this.insuranceService.getCustomerProductInfo(this.case.id).subscribe(
                    info => {
                      this.productChange(product, info);
                    }
                  );
                }
              );
            }
          }
        }
      }
    );
  }

  ngOnInit(): void {
    this.resetValues();
    this.product = new PolicyProduct();
    this.subscribePersonInsurance();
  }

  ngOnDestroy(): void {
    this.shortcutSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  openTermsDocument() {
    this.documentService.getTermsDocument(this.termsDocumentId).subscribe(result => {
      const url = window.URL.createObjectURL(result);
      window.open(url);
    }, error => console.log(error));
  }

  openGuideDocument() {
    const url = this.GUIDELINE_URL_PREFIX +  this.guidelineUri;
    window.open(url);
  }

  private productChange(product: PolicyProduct, productInfo?: CustomerProductInfo) {
    this.product = product;
    this.productVersion = this.product.version;
    this.guidelineUri = this.product.guidelineUri;
    if (!!productInfo) {
      this.termsDocumentId = productInfo.termsDocumentId;
      this.termsName = productInfo.termsName;
    } else {
      this.termsDocumentId = this.product.termsDocumentId;
      this.termsName = this.product.termsName;
    }
  }

  private resetValues() {
    this.product = new PolicyProduct();
    this.termsDocumentId = null;
    this.termsName = null;
    this.productVersion = null;
    this.guidelineUri = null;
  }

}
