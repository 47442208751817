<div class="refund-row" [appDisableInput]="caseLockHelperService.isCaseBasicLockTaken || caseStateService.isCaseClosed">
    <div class="caselock-no-events">
        <div>
            <table class="primary-table">
                <tr class="tr-refund">
                    <td class="td-service-order">
                        <app-drop-down-service-order
                            [recommended]="!item.serviceOrderId"
                            [(selectedItemId)]="item.serviceOrderId"
                            (selectedItemIdChange)="serviceOrderChange()"
                            [shouldDisableSelectionOnInvalidServiceOrders]="false"
                            [disabled]="caseStateService.isCaseClosed"
                            [serviceOrders]="selectableServiceOrders"
                        ></app-drop-down-service-order>
                    </td>
                    <td class="td-item">
                        <app-drop-down
                            [(selectedItemId)]="item.serviceItemCode"
                            [items]="selectableServiceItemList"
                            [disabled]="lockRefundOnApproved"
                            [recommended]="!item.serviceItemCode"
                        ></app-drop-down>
                    </td>
                    <td class="td-quantity">
                        <input
                          class="input-height"
                          [(ngModel)]="item.quantity"
                          currencyMask
                          [options]="quantityMaskOptions"
                          [disabled]="lockRefundOnApproved"
                          (focus)="focusElement($event)"
                          [ngClass]="{ 'recommended-yellow-background': !item.quantity }"
                        />
                      </td>
                    <td class="td-unit">
                        <app-drop-down
                        [items]="quantityUnits"
                        [(selectedItemId)]="item.quantityUnit"
                        [disabled]="lockRefundOnApproved"
                        [translatePipePath]="'quantity-unit-'"
                        [recommended]="!item.quantityUnit"
                      ></app-drop-down>
                    </td>
                    <td class="td-expense-amount"></td>
                    <td class="td-reduction"></td>
                    <td class="td-expense-date" >
                        <app-input-calendar
                            [withImage]="true"
                            [maxDate]="today"
                            [(date)]="item.refundDate"
                            [disabled]="lockRefundOnApproved"
                            (dateChange)="updateExpenseDate($event, item)"
                            [invalid]="!item.refundDate">
                        </app-input-calendar>
                    </td>

                    <td class="td-reduction-currency " >
                        <app-drop-down
                        [items]="baseCurrenciesSupported"
                        [(selectedItemId)]="item.refundCurrency"
                        (selectedItemIdChange)="updateRefundItemCurrency()"
                        [disabled]="lockRefundOnApproved"
                      ></app-drop-down>
                    </td>
                    <td class="td-expense-amount" placement="bottom" >
                        <div class="input-with-currency">
                          <div class="input-with-currency-currency">{{ localCurrency.currency }}</div>
                          <input
                            class="input-height"
                            [(ngModel)]="item.refundAmount"
                            currencyMask
                            [options]="currencyMaskOptions"
                            [disabled]="lockRefundOnApproved"
                            (focus)="focusElement($event)"
                            (blur)="recalcReductionAmounts()"
                            [ngClass]="{ 'recommended-yellow-background': !item.refundAmount }"
                          />
                        </div>
                    </td>
                    <td class="td-refund-amount">
                        <div class="input-with-currency">
                          <div class="input-with-currency-currency">{{ refundCurrency.currency }}</div>
                            <input
                            class="input-height"
                            [ngModel]="item.refundExchangedAmount"
                            [disabled]="true"
                            currencyMask
                            [options]="currencyMaskOptions"
                            (focus)="focusElement($event)"
                            />
                        </div>
                    </td>
                    <td [ngClass]="{'delete-button': showDelete && !lockRefundOnApproved, 'no-delete-button': !showDelete && lockRefundOnApproved }" (click)="deleteItem(item)" [attr.rowspan]="getHeightOfDeletebutton()"></td>
                </tr>
                <tr *ngFor="let reduction of item.reductions; let i = index" class="tr-saving">
                    <td>
                        <div class="delete-reduction-button" *ngIf="!lockRefundOnApproved" (click)="removeReduction(reduction.lineNumber)">
                            <img class="delete-saving-button-icon" src="../../../../../../../assets/icons/garbage.svg" />
                            {{ 'case-economy-refund-delete-reduction' | translate }}
                        </div>
                    </td>

                    <td class="td-item">
                        <app-drop-down
                            [items]="savingTypeList"
                            [(selectedItemId)]="reduction.reductionTypeCode"
                            [disabled]="lockRefundOnApproved"
                            [requiredRedBackground]="true"
                            >
                        </app-drop-down>
                    </td>
                    <td colspan="2"></td>
                    <td> <div class="input-with-currency" placement="bottom">
                        <div class="input-with-currency-currency">{{ localCurrency.currency }}</div>
                        <input
                          class="input-height"
                          [ngClass]="{ 'input-with-currency-required': reduction.amount <= 0 }"
                          [(ngModel)]="reduction.amount"
                          [disabled]="lockRefundOnApproved"
                          currencyMask
                          [options]="currencyMaskOptions"
                          (focus)="focusElement($event)"
                          (blur)="reductionAmountChanged(reduction)"
                        />
                      </div>
                    </td>
                    <td>
                        <div class="input-with-currency">
                        <div class="input-with-currency-currency">%</div>
                        <input
                          class="input-height"
                          [ngClass]="{ 'input-with-currency-required': (reduction.percentage <= 0 || !reduction.percentage) }"
                          [(ngModel)]="reduction.percentage"
                          [disabled]="lockRefundOnApproved"
                          currencyMask
                          [options]="currencyMaskOptions"
                          (focus)="focusElement($event)"
                          (blur)="savingPercentageChanged(reduction)"
                        />
                      </div>
                    </td>
                    <td colspan="3"></td>
                    <td>
                        <div class="output-with-currency" >
                            <div class="output-with-currency-currency">{{ refundCurrency.currency }}</div>
                            {{ reduction.exchangedAmount | number: '.' + refundCurrency.precision }}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" style="text-align: right;">
                        <div class="add-reduction-button" *ngIf="!lockRefundOnApproved" (click)="addReduction(false)">
                            <img src="../../../../../../../assets/icons/add-new.svg" /> {{ 'case-economy-refund-add-reduction' | translate }}
                        </div>
                    </td>
                    <td colspan="2"></td>
                    <td>
                        <div class="output-with-currency">
                            <div class="output-with-currency-currency">{{ localCurrency.currency }}</div>
                            {{ getReductionAmountTotal() | number: '.' + localCurrency.precision }}
                        </div>
                    </td>
                    <td>
                        <div class="output-with-currency">
                            <div class="output-with-currency-currency">{{ '%' }}</div>
                            {{ getReductionPctTotal() | number: '.1-1' }}
                        </div>
                    </td>
                    <td colspan="3"></td>
                    <td>
                        <div class="output-with-currency">
                            <div class="output-with-currency-currency">{{ refundCurrency.currency }}</div>
                            {{ getExchangedNetAmount() | number: '.' + refundCurrency.precision }}
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>
