import { HelperService } from '@secca/core/services/helper.service';
import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import * as _ from "lodash";

@Component({
  selector: 'app-multiple-select-dropdown',
  templateUrl: './multiple-select-dropdown.component.html',
  styleUrls: ['./multiple-select-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MultipleSelectDropdownComponent implements OnInit {
  @Input() name: string;
  @Input() placeholder: string;
  @Input() selectedItems = [];
  @Input() dropdownList = [];
  @Input() notSelectableList = [];
  @Input() recommended: boolean = false;

  @Output() selectedItemsChange = new EventEmitter();

  filteredlist = [];
  dropdownSettings = {};
  uniqueClassName: string;
  focused: boolean;

  constructor(private helperService: HelperService) {}

  ngOnInit() {
    this.filteredlist = _.cloneDeep(this.dropdownList);
    this.notSelectableList.forEach(tag => {
      if(tag.preTagged) {
        this.filteredlist.splice(this.filteredlist.findIndex(e => e.id === tag.name), 1);
      }
    });

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      limitSelection: 10,
      allowSearchFilter: true,
    };
    this.uniqueClassName = this.helperService.getRandomGuid();
  }
  onItemSelect(item: any) {
    this.selectedItemsChange.emit(this.selectedItems);
  }
  onItemDeSelect(item: any) {
    this.selectedItemsChange.emit(this.selectedItems);
  }

  keyDown(event) {
    // ng-multiselect-dropdown doesn't support keyboard operation by default and doesn't have method for openning the list of items programmatically
    if (event.key === 'Enter') {
      if (
        document.getElementsByClassName(this.uniqueClassName).length > 0 &&
        document.getElementsByClassName(this.uniqueClassName)[0].getElementsByClassName('dropdown-list').length > 0 &&
        document.getElementsByClassName(this.uniqueClassName)[0].getElementsByClassName('dropdown-list')[0].getElementsByTagName('input')
          .length > 0
      ) {
        document.getElementsByClassName(this.uniqueClassName)[0].getElementsByClassName('dropdown-list')[0].removeAttribute('hidden');
        document
          .getElementsByClassName(this.uniqueClassName)[0]
          .getElementsByClassName('dropdown-list')[0]
          .getElementsByTagName('input')[0]
          .focus();
      }
    }
  }

  hideDropDown() {
    if (
      document.getElementsByClassName(this.uniqueClassName).length > 0 &&
      document.getElementsByClassName(this.uniqueClassName)[0].getElementsByClassName('dropdown-list').length > 0
    ) {
      document.getElementsByClassName(this.uniqueClassName)[0].getElementsByClassName('dropdown-list')[0].setAttribute('hidden', null);
    }
  }

  setFocus(){
    this.focused = true;
  }

  setBlur(){
    this.focused = false;
  }
}
