import { MedicalPreAssessment, MedicalPreAssessmentQuestion } from '@secca/shared/models/medicalPreAssessment';
import { DateHelperService } from '@secca/core/services/date-helper.service';
import { Icd10Code } from '@secca/shared/models/icd10Code';
import { TranslateService } from '@ngx-translate/core';
import { DropdownDictionary } from './../../../../../shared/models/dropdownDictionary';
import { StakeholderTypeEnum, MedicalAssessmentBasedOnEnum, MedicalPreAssessmentDecisionEnum, MessageFrom, MessageChannelType, AttachmentTypeEnum, MedicalPreAssessmentTypeEnum, MedicalPreAssessmentRelevantEnum, MedicalPreAssessmentStatusEnum } from './../../../../../shared/models/enums';
import { StakeholderService } from './../../../../../core/services/stakeholder.service';
import { CaseStakeholder } from './../../../../../shared/models/caseStakeholder';
import { StakeholderType } from './../../case-stakeholders/stakeholderType';
import { CaseService } from './../../../../../core/services/case.service';
import { DictionaryService } from './../../../../../core/services/dictionary.service';
import { MedicalPreAssessmentDecisionDetail, MedicalPreAssessmentSource, MedicalPreAssessmentAdapter, MedicalPreAssessmentQuestionnaire } from './../../../../../shared/models/medicalPreAssessment';
import { MedicalPreAssessmentState, SelectItem } from './../medical-components/medical-models';
import { Case } from './../../../../../shared/models/case';
import { DialogHelperUtilService } from '@secca/core/services/dialog-helper-util.service';
import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { MedicalPreAssessmentStateService } from '@secca/core/services/medical-pre-assessment-state.service';
import { SourceUser, SourceUserId } from '@secca/case/components/case-medical/medical-assessment/medical-assessment-source/source-user';
import { Observable, ReplaySubject, Subject, Subscription, throwError } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentService } from 'src/app/core/services/document.service';
import * as onChange from 'on-change';
import { MedicalPreAssessmentService } from '@secca/core/services/medical-pre-assessment.service';
import { tap, finalize, switchMap, catchError, take } from 'rxjs/operators';
import { Message } from '../../case-output-management/models/message';
import { AttachmentDto } from '@secca/shared/models/attachment-dto';
import { Person } from '@secca/shared/models/person';
import { ServiceOrderMessageRequest } from '@secca/shared/models/service-order/service-order-message-request';
import { CaseMessageService } from '@secca/core/services/case-message.service';
import { CaseDialogViewerService } from '@secca/case/case-dialog-viewer.service';
import { DialogStateService } from '@secca/core/state-services/dialog-state.service';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { IncidentService } from '@secca/core/services/incident.service';
import { IncidentStateService } from '@secca/core/state-services/incident-state-service';
import _ from 'lodash';
import { MasterListService } from '@secca/core/services/masterlist-service';
import { ProfileService } from '@secca/core/services/profile.service';
import { TranslateInPipeService } from '@secca/shared/pipes/translateIn-pipe';
import { InsuranceService } from '@secca/core/services/insurance.service';
import { PersonInsurance } from '@secca/shared/models/person-insurance';
import { Questionnaire } from '@secca/shared/models/questionnaire';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';

@Component({
  selector: 'app-medical-pre-assessment',
  templateUrl: './medical-pre-assessment.component.html',
  styleUrls: ['./medical-pre-assessment.component.scss'],
  providers: [MedicalPreAssessmentStateService]
})
@AutoUnsubscribe
export class MedicalPreAssessmentComponent implements OnInit, OnDestroy {
  _case: Case;
  @Input() medicalPreAssessmentId: number;
  @Input() disabledViewOfMedicalPreAssessment: boolean;
  @Input() medicalPreAssessmentType: MedicalPreAssessmentTypeEnum;
  @Input() supplier: CaseStakeholder;
  @Input() stakeholders: CaseStakeholder[];
  @Input()
  set case(newCase: Case) {
    if (newCase !== undefined) {
      this._case = newCase;
    }
  }
  get case(): Case {
    return this._case;
  }

  get caseId(): number {
    if (!!this._case) {
      return Number.parseInt(this._case.id);
    }
    return null;
  }

  @Output() updateDraftButton = new EventEmitter();
  @Output() closed = new EventEmitter<number>();
  @Output() excludedValues = new EventEmitter<string[]>();
  questionsInitialised: Subject<MedicalPreAssessmentQuestion[]> = new ReplaySubject();
  state: MedicalPreAssessmentState;
  stateSubscription: Subscription;
  formValid = true;
  basisSources: DropdownDictionary[];
  contactPerson: SourceUser;
  excludedDiagnosis: any[] = [];
  users: SourceUser[] = [];
  userIndex = 0;
  stakeholderTypes: StakeholderType[];
  storingMedicalPreAssessment: boolean = false;
  medicalPreAssessment: MedicalPreAssessment;
  showSpinner = false;
  addMore = false;
  isDraft = false;
  assessmentTypeList: SelectItem<MedicalPreAssessmentTypeEnum>[];
  relevantList: SelectItem<MedicalPreAssessmentRelevantEnum>[];
  questions: MedicalPreAssessmentQuestion[];

  stakeholderSortOnGroupName = new Map<string, number>([
    ['End-User', 1],
    ['Policy Holder', 2],
    ['Reporter', 3],
    ['Person', 4],
    ['Medical', 5],
    ['Accommodation', 6],
    ['Transport', 7],
    ['Medical escort', 8],
    ['Agent', 9],
    ['Other', 10],
  ]);

  private propertyNameMap = new Map<string, string>([
    ['PRIMARY_CONTACT', 'Primary Contact'],
    ['REPORTER', 'Reporter'],
    ['POLICYHOLDER', 'Policy Holder'],
    ['END_USER', 'End User'],
    ['LEGAL_GUARDIAN', 'Legal Guardian'],
    ['CO_TRAVELER', 'Co-Traveller'],
  ]);

  constructor(
    private dialogHelperUtilService: DialogHelperUtilService,
    private medicalPreAssessmentService: MedicalPreAssessmentService,
    private preAssessmentStateService: MedicalPreAssessmentStateService,
    private dictionaryService: DictionaryService,
    private caseService: CaseService,
    private translateService: TranslateService,
    private dateHelperService: DateHelperService,
    private stakeholderService: StakeholderService,
    private modalService: NgbModal,
    private documentService: DocumentService,
    private caseMessageService: CaseMessageService,
    private dialogViewerService: CaseDialogViewerService,
    private dialogStateService: DialogStateService,
    private caseStateService: CaseStateService,
    private incidentService: IncidentService,
    private incidentStateService: IncidentStateService,
    private masterListService: MasterListService,
    private insuranceService: InsuranceService,
    private profileService: ProfileService,
    private translateInPipeService: TranslateInPipeService
  ) { }


  ngOnInit(): void {
    this.stateSubscription = this.preAssessmentStateService.stateSubject().subscribe(state => {
      this.state = new MedicalPreAssessmentState(state);
    });

    this.assessmentTypeList = this.getSingleSelectItemFromEnum(MedicalPreAssessmentTypeEnum, MedicalPreAssessmentTypeEnum.PREASSESSMENT_TRAVEL, 'medical-pre-assessment-');
    this.relevantList = this.getSingleSelectItemFromEnum(MedicalPreAssessmentRelevantEnum, MedicalPreAssessmentRelevantEnum.YES, 'medical-pre-assessment-');

    this.medicalPreAssessmentService.componentText = 'pre-departure';
    this.dictionaryService.getStakeholdersTypes().subscribe(
      stakeholderTypes => {
        this.stakeholderTypes = stakeholderTypes;
      },
      error => console.log(error)
    );

    this.caseService.getCaseStakeholdersOnCase(this.caseId.toString()).subscribe(result => {
      this.stakeholders = result;
      this.filterList();
      this.setDefaultSource();
    });

    this.initDefaultQuestions().then(questions => {
      this.questionsInitialised.next(questions);
    })

    var preAssessmentDraftData = this.medicalPreAssessmentService.getPreAssessmentInDraft(this.caseId);
    //View - > get the medical-pre-assessment
    if (this.medicalPreAssessmentId) {
      this.medicalPreAssessmentService.getMedicalPreAssessment(this.medicalPreAssessmentId).subscribe(
        result => {
          this.medicalPreAssessment = result;
          //this.setRelevantList(result);
          if (this.medicalPreAssessment.questionnaires.length > 0) {
            this.medicalPreAssessment.questionnaires.forEach(qs => {
              this.setRelevantListSingleItem(qs.relevant, qs);
            });
          }
          this.assessmentTypeList = this.getSingleSelectItemFromEnum(MedicalPreAssessmentTypeEnum, result.medicalAssessmentType, 'medical-pre-assessment-');
          if (this.medicalPreAssessment) {
            this.refreshAfterAssessmentIsAdded();
          }
        }
      );
    } else if (preAssessmentDraftData !== null) {
      // draft
      this.medicalPreAssessment = preAssessmentDraftData;
      this.assessmentTypeList = this.getSingleSelectItemFromEnum(MedicalPreAssessmentTypeEnum, preAssessmentDraftData.medicalAssessmentType, 'medical-pre-assessment-');
      this.setRelevantList(preAssessmentDraftData);
      if (this.medicalPreAssessment) {
        this.isDraft = true;
        this.refreshAfterAssessmentIsAdded();
      }
    } else {
      //Pre-filled or new
      this.medicalPreAssessmentService.getNewestMedicalPreAssessment(this.caseId).subscribe(result => {
        if (result !== null) {
          this.medicalPreAssessment = result;
          this.medicalPreAssessment.note.id = null;
          this.medicalPreAssessment.medicalAssessmentType = result.medicalAssessmentType;
          this.assessmentTypeList = this.getSingleSelectItemFromEnum(MedicalPreAssessmentTypeEnum, result.medicalAssessmentType, 'medical-pre-assessment-');
          this.medicalPreAssessment.sourceList?.forEach(source => source.id = null);
          if(this.medicalPreAssessment.questionnaires?.length>0)
          {
            this.medicalPreAssessment.questionnaires.forEach(question=>question.id = null);
            this.excludedDiagnoses();
          }

          this.medicalPreAssessment.questionnaires[0].id = null;
          this.medicalPreAssessment.decision = undefined;
          this.medicalPreAssessment.decisionDetail = new MedicalPreAssessmentDecisionDetail();
          this.medicalPreAssessment.note.evaluation = null;
          this.medicalPreAssessment.note.canFinalizeAssessment = undefined;
          this.medicalPreAssessment.seccaCaseId = this.caseId;
          this.medicalPreAssessment.id = null;
        }
        this.refreshAfterAssessmentIsAdded();
      });
    }
  }

  assignTravel(stateType: MedicalPreAssessmentTypeEnum) {
    this.medicalPreAssessment.medicalAssessmentType = stateType === MedicalPreAssessmentTypeEnum.PREASSESSMENT_CANCELLATION ? stateType : MedicalPreAssessmentTypeEnum.PREASSESSMENT_TRAVEL;
    this.state.typeText = this.medicalPreAssessment.medicalAssessmentType;
    return this.medicalPreAssessment.medicalAssessmentType;
  }

  setRelevantList(result: MedicalPreAssessment) {
    result.questionnaires.forEach((question: MedicalPreAssessmentQuestionnaire)=> {
      const relevant = question.relevant === undefined ? MedicalPreAssessmentRelevantEnum.YES : question.relevant;
      this.relevantList = this.getSingleSelectItemFromEnum(MedicalPreAssessmentRelevantEnum, relevant, 'medical-pre-assessment-');
    })
  }

  setRelevantListSingleItem(relevanttype: MedicalPreAssessmentRelevantEnum, questionaire: MedicalPreAssessmentQuestionnaire) {
    questionaire.relevant = relevanttype;
  }

  close() {
    if (this.closed.observers) {
      this.closed.emit();
    }
    this.dialogHelperUtilService.closeModal();
  }


  updateFormValid(valid: boolean) {
    this.formValid = valid;
  }

  public scrollToElement(selector: string) {
    if (!!document.getElementById(selector)) {
      document.getElementById(selector).scrollIntoView();
    }
  }

  showRelevant(questionnaire: MedicalPreAssessmentQuestionnaire) {
    return questionnaire?.diagnosisReported?.length > 0 &&
            (this.questionnairesWithReportedAndRelevantDiagnosisCount > 1 ||
             this.questionnairesWithNotReportedDiagnosisCount > 0);
  }

  questionnaireDeletable(questionnaire: MedicalPreAssessmentQuestionnaire) {
    return (!questionnaire?.diagnosisReported || questionnaire?.diagnosisReported.length === 0) &&
            (this.questionnairesWithNotReportedDiagnosisCount + this.questionnairesWithReportedAndRelevantDiagnosisCount) > 1;
  }

  sourceDeletable() {
    return this.medicalPreAssessment?.sourceList && this.medicalPreAssessment?.sourceList?.length > 1;
  }

  deleteSource(index) {
    this.medicalPreAssessment?.sourceList?.splice(index, 1);
  }

  deleteQuestionnaire(index) {
    this.medicalPreAssessment?.questionnaires?.splice(index, 1);
  }

  excludedDiagnoses() {
    this.medicalPreAssessment?.questionnaires?.forEach(question=> {
      question.diagnosisPreDeparture.forEach(diagnoses=>{
        this.excludedDiagnosis.push(diagnoses.icd10Code);
       });
    this.medicalPreAssessmentService.excludedValues = [...this.excludedDiagnosis];
  });
  }

  updatedSymptoms() {
    this.medicalPreAssessmentService.excludedValues = [];
    this.excludedDiagnosis = [];
    this.excludedDiagnoses();
  }

  refreshAfterAssessmentIsAdded() {
    if (!this.medicalPreAssessment) {
      this.medicalPreAssessment = new MedicalPreAssessment();
      this.medicalPreAssessment.medicalAssessmentType = MedicalPreAssessmentTypeEnum.PREASSESSMENT_TRAVEL;
      this.medicalPreAssessment.seccaCaseId = this.caseId;
      this.setDefaultSource();
      this.setRelevantList(this.medicalPreAssessment);
      this.excludedDiagnoses();
      this.questionsInitialised.pipe(take(1)).subscribe(questions => {
        this.medicalPreAssessment.questionnaires.forEach(questionnaire => {
          questionnaire.questions = questions.map(q => ({...q}));
        });
      });
    }

    // Register onChange function
    this.medicalPreAssessment = onChange(
      this.medicalPreAssessment,
      (path, value, previousValue) => {
        this.preAssessmentStateService.updatePreAssessment(this.medicalPreAssessment);
      },
      { ignoreKeys: ['forEach', 'every', 'filter'], ignoreSymbols: true }
    );

    // First-time state check
    this.preAssessmentStateService.updatePreAssessment(this.medicalPreAssessment);

    this.basisSources = this.getBasisList();

  };

  updateSourceData(selectedSourceId: string, basis: MedicalPreAssessmentSource) {
    const selectedSource = JSON.parse(selectedSourceId);

    basis.sourceName = selectedSource.sourceName;
    basis.sourceType = selectedSource.sourceType;
  }

  resolveSourceId(source: MedicalPreAssessmentSource) {
    if (!!source && !!source.sourceName && !!source.sourceType) {
      return JSON.stringify(
        new SourceUserId({
          sourceName: source.sourceName,
          sourceType: source.sourceType,
        })
      );
    }
    return null;
  }

  private filterList() {
    this.stakeholders.forEach(stakeholder => {
      const theType = this.stakeholderTypes.find(type => type.stakeholderTypeEnum === stakeholder.stakeholderType);
      stakeholder.sortId = this.stakeholderSortOnGroupName.get(theType.groupName);
      stakeholder.imageName = this.stakeholderService.addIconToCaseStakeholder(theType.stakeholderTypeEnum) + '.svg';
    });
    this.createList();
  }
  private getSingleSelectItemFromEnum(enumItem: any, selectedPath: any, prefix: string) {
    return Object.keys(enumItem).map(key => {
      return new SelectItem({
        label: this.translateService.instant(prefix + key.toString()),
        value: enumItem[key],
        selected: enumItem[selectedPath] === enumItem[key]
      });
    });
  }

  createList() {
    const stakeholderTypeString = Object.values(StakeholderTypeEnum);
    const displayedRoles = [
      StakeholderTypeEnum.endUser,
      StakeholderTypeEnum.policyHolder,
      StakeholderTypeEnum.reporter,
      StakeholderTypeEnum.coTraveller,
      StakeholderTypeEnum.person,
    ];
    this.users = new Array();
    this.stakeholders.forEach(stake => {
      const stakeholder = this.createSourceUserFromStakeholder(stake, stakeholderTypeString, displayedRoles);
      this.userIndex++;
      this.users.push(stakeholder);

      if (stake.isCompany) {
        this.addContactPersonToUserList(stake);
      }
    });
  }

  private async initDefaultQuestions(): Promise<MedicalPreAssessmentQuestion[]> {
    const defaultMarket = 'dk';
    const defaultLanguage = 'da';

    let questionnaireQuestions;
    try {
      const personInsurance: PersonInsurance = await this.insuranceService.getPersonInsurance(this.case.id).pipe(take(1)).toPromise();

      if ( !!personInsurance.customerProfileId ) {
        const market = await this.profileService.getProfileMarket(personInsurance.customerProfileId).toPromise();
        if ( !!market ) {
          questionnaireQuestions = await this.masterListService.getQuestionnaireByFlowAndLanguage(market, defaultLanguage).toPromise();
        }
      }
    }
    catch(err) {
      console.log("Failed to get questionnaires from insurance, market", err);
    }

    try {
      if ( !questionnaireQuestions || questionnaireQuestions.length === 0 ) {
        questionnaireQuestions = await this.masterListService.getQuestionnaireByFlowAndLanguage(defaultMarket, defaultLanguage).toPromise();
      }
    }
    catch(err) {
      console.log("Failed to get questionnaires from defaultMarket, defaultLanguage", err);
    }

    let questions;
    if ( questionnaireQuestions?.length > 0 ) {
      questions = questionnaireQuestions.filter(q => q.active).map(q =>
        new MedicalPreAssessmentQuestion({
          questionKey: q.questionCode,
          questionText: q.questionName
        })
      )
    }
    else {
      // hardcoded questions
      const questionTexts = await Promise.all([this.translateInPipeService.translate('medical-pre-assessment-recent-other-treatments', defaultLanguage),
                                               this.translateInPipeService.translate('medical-pre-assessment-recent-medication-changes', defaultLanguage),
                                               this.translateInPipeService.translate('medical-pre-assessment-recent-treatment-plan-changes', defaultLanguage),
                                               this.translateInPipeService.translate('medical-pre-assessment-awaiting-recent-treatment-results', defaultLanguage)]);

      questions = questionTexts.map(text =>
        new MedicalPreAssessmentQuestion({
          questionText: text
        })
      );
      console.log("Using hardcoded questionnaires!");
    }

    return questions;
  }

  private createSourceUserFromStakeholder(stake: CaseStakeholder, stakeholderTypeString: string[], displayedRoles: StakeholderTypeEnum[]): SourceUser {
    const stakeholder = new SourceUser();
    if (stake.isCompany) {
      stakeholder.companyName = stake.company.name;
    } else {
      stakeholder.companyName = stake.person.firstName;
    }
    stakeholder.isCompany = stake.isCompany;
    if (!stake.isCompany) {
      stakeholder.firstName = stake.person.firstName;
      stakeholder.surname = stake.person.surname;
    }

    stakeholder.stakeholderType = stake.stakeholderType;
    stakeholder.stakeholderId = stake.id;
    stakeholder.contactPersonId = '';
    stakeholder.icon = '/assets/icons/' + stake.imageName;
    stakeholder.sortId = Number(stake.sortId);

    let stakeholderTypeEnum: StakeholderTypeEnum;
    for (let i = 0; i < stakeholderTypeString.length; i++) {
      if (stakeholderTypeString[i] === stake.stakeholderType) {
        stakeholderTypeEnum = StakeholderTypeEnum[Object.keys(StakeholderTypeEnum)[i]];
        break;
      }
    }
    if (displayedRoles.find(r => r === stakeholderTypeEnum)) {
      if (stakeholderTypeEnum === StakeholderTypeEnum.person) {
        stakeholder.roleName = '';
        stake.properties.forEach(p => {
          stakeholder.roleName = this.propertyNameMap.get(p);
        });
      } else {
        const stakeholderType = this.stakeholderTypes.find(s => s.stakeholderTypeEnum === stakeholderTypeEnum);
        if (stakeholderType !== undefined) {
          stakeholder.roleName = stakeholderType.groupName;
        }
      }
    }
    stakeholder.id = this.constructId(stakeholder);

    return stakeholder;
  }

  private constructId(sourceUser: SourceUser): string {
    const id = new SourceUserId();
    if (!sourceUser.isCompany) {
      id.sourceName = sourceUser.firstName + ' ' + sourceUser.surname;
      if (sourceUser.treatingDoctor) {
        id.sourceName += ' (' + sourceUser.companyName + ')';
      }
      if (sourceUser.roleName) {
        id.sourceName += ' (' + sourceUser.roleName + ')';
      }
    } else {
      id.sourceName = sourceUser.companyName;
    }
    id.sourceType = sourceUser.stakeholderType;
    return JSON.stringify(id);
  }

  private setDefaultSource(index: number = 0) {
    if (!!this.medicalPreAssessmentId || this.isDraft) {
      return;
    }

    if (this.medicalPreAssessment?.sourceList?.length > index) {
      if (!this.medicalPreAssessment?.sourceList[index].basedOn) {
        this.medicalPreAssessment.sourceList[index].basedOn = MedicalAssessmentBasedOnEnum.VOICE;
      }

      if (this.users?.length > 0) {
        const endUser = this.case?.simpleCaseStakeholders?.find(c => c.hasRole(StakeholderTypeEnum.endUser));
        if (!!endUser) {
          const source = this.users.find(user => +user.stakeholderId === +endUser.id);
          if (source?.stakeholderId) {
            const newSource = _.clone(this.medicalPreAssessment.sourceList[index]);
            this.updateSourceData(source.id, newSource);

            this.medicalPreAssessment.sourceList[index] = newSource;
          }
        }
      }
    }
  }

  private get questionnairesWithNotReportedDiagnosisCount() {
    return this.medicalPreAssessment?.questionnaires?.filter(q => !q.diagnosisReported || q.diagnosisReported?.length === 0).length;
  }

  private get questionnairesWithReportedAndRelevantDiagnosisCount() {
    return this.medicalPreAssessment?.questionnaires?.filter(q => q.diagnosisReported?.length > 0 && q.relevant === MedicalPreAssessmentRelevantEnum.YES).length;
  }

  get MedicalPreAssessmentRelevantEnum() {
    return MedicalPreAssessmentRelevantEnum;
  }

  addContactPersonToUserList(stake: CaseStakeholder) {
    stake.company.contactPersons.forEach(conta => {
      this.contactPerson = new SourceUser();
      if (stake.isCompany) {
        this.contactPerson.companyName = stake.company.name;
      }
      this.contactPerson.firstName = conta.firstName;
      this.contactPerson.surname = conta.surname;
      this.contactPerson.stakeholderType = stake.stakeholderType;
      this.contactPerson.stakeholderId = stake.id;
      this.contactPerson.contactPersonId = conta.id;
      if (conta.treatingDoctor) {
        this.contactPerson.sortId = Number(4);
        this.contactPerson.treatingDoctor = true;
        this.contactPerson.icon = '/assets/icons/' + stake.imageName;
      } else {
        this.contactPerson.sortId = Number(5);
        this.contactPerson.treatingDoctor = false;
        this.contactPerson.icon = '/assets/icons/Person.svg';
      }
      this.contactPerson.id = this.constructId(this.contactPerson);
      this.users = [...this.users, this.contactPerson];
      this.users.sort((a, b) => (a.sortId >= b.sortId ? 1 : -1));
    });
  }

  private getBasisList() {
    const prefix = 'medical-assessment-based-';
    return Object.keys(MedicalAssessmentBasedOnEnum).map(
      item =>
        new DropdownDictionary(
          MedicalAssessmentBasedOnEnum[item],
          this.translateService.instant(prefix + item.toString().toLowerCase().replace('_', '-'))
        )
    );
  }

  addNewSource() {
    const sources = Array.from(this.medicalPreAssessment.sourceList);
    sources.push(new MedicalPreAssessmentSource());
    this.medicalPreAssessment.sourceList = sources;
  }

  addNewQuestionaire() {
   // this.selectedSymptoms = [];
    const questionnaire = Array.from(this.medicalPreAssessment.questionnaires);
    var quesNew = new MedicalPreAssessmentQuestionnaire({
      diagnosisPreDeparture: undefined,
      diagnosisReported: undefined,
      relevant: MedicalPreAssessmentRelevantEnum.YES,
    })
    this.questionsInitialised.pipe(take(1)).subscribe(questions => {
      quesNew.questions = questions.map(q => ({...q}));
    });
    questionnaire.push(quesNew);
    this.medicalPreAssessment.questionnaires = questionnaire;
    this.setRelevantList(this.medicalPreAssessment);
  }

  saveDraft() {
    // This step is necessary to remove all proxies from the medical assessment form
    const copyMedicalPreAssessment = JSON.parse(JSON.stringify(onChange.target(this.medicalPreAssessment)));
    this.medicalPreAssessmentService.savePreAssessmentDraft(copyMedicalPreAssessment, this.caseId);
    this.updateDraftButton.emit();
    this.close();
  }

  cancel() {
    if (this.storingMedicalPreAssessment) {
      return;
    }
    const copyMedicalAssessment: MedicalPreAssessment = JSON.parse(JSON.stringify(onChange.target(this.medicalPreAssessment)));

    this.storingMedicalPreAssessment = true;
    this.showSpinner = true;
    this.medicalPreAssessmentService.cancelMedicalPreAssessment(copyMedicalAssessment).pipe(
      finalize(() => {
        this.storingMedicalPreAssessment = false;
        this.showSpinner = false;
      })).
      subscribe(result => {
        this.updateMedicalPreAssessmentResult(result);
        this.close();
      });
  }

  publish() {
    if (this.storingMedicalPreAssessment) {
      return;
    }
    this.showSpinner = true;
    this.publishPreAssessment().pipe(finalize(() => this.showSpinner = false)).subscribe(() => {
      this.close();
    });
  }

  previewLetter() {
    if (this.storingMedicalPreAssessment) {
      return;
    }
    this.showSpinner = true;
    const copyMedicalAssessment: MedicalPreAssessment = JSON.parse(JSON.stringify(onChange.target(this.medicalPreAssessment)));

    return this.medicalPreAssessmentService.getMedicalPreAssessmentPreviewLetter(copyMedicalAssessment).pipe(
      finalize(() => this.showSpinner = false),
      tap(data => {
        const file = new Blob([data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        this.dialogViewerService.openPreviewDocumentDialog(this.medicalPreAssessment.id, fileURL, this.caseId.toString(), this.case.caseNumber);
      }))
      .subscribe();
  }

  sendLetter() {
    if (this.storingMedicalPreAssessment) {
      return;
    }

    const receiverId = this.users?.find(u => u.id === this.resolveSourceId(this.medicalPreAssessment.sourceList[0]))?.stakeholderId;
    const ccIds = [...new Set(this.medicalPreAssessment.sourceList.slice(1).map(s => this.users?.find(u => u.id === this.resolveSourceId(s))?.stakeholderId))];

    this.showSpinner = true;
    this.publishPreAssessment().pipe(switchMap(result => {
      return this.medicalPreAssessmentService.storeMedicalPreAssessmentLetter(result.id).pipe(
        finalize(() => this.showSpinner = false),
        tap(documentId => {
          this.openMessageModal(documentId, receiverId, ccIds);
          this.close();
        }))
    }),
      catchError(err => { this.showSpinner = false; return throwError(err) })
    ).subscribe();
  }

  requestInformation() {
    if (this.storingMedicalPreAssessment) {
      return;
    }
    const receiverId = this.users?.find(u => u.id === this.resolveSourceId(this.medicalPreAssessment.sourceList[0]))?.stakeholderId;
    const ccIds = [...new Set(this.medicalPreAssessment.sourceList.slice(1).map(s => this.users?.find(u => u.id === this.resolveSourceId(s))?.stakeholderId))];

    this.showSpinner = true;
    this.publishPreAssessment().pipe(finalize(() => this.showSpinner = false)).subscribe(result => {
      this.openMessageModal(null, receiverId, ccIds);
      this.close();
    });
  }

  findExcludedElement() {

    this.medicalPreAssessment.questionnaires.forEach(quest=> {
      quest.diagnosisPreDeparture.forEach(q=>{
        this.excludedDiagnosis.push(q.icd10Code);
       });
        this.medicalPreAssessmentService.excludedValues = [...this.excludedDiagnosis];
      });
      console.log('this.medicalPreAssessmentService.excludedValues', this.medicalPreAssessmentService.excludedValues)
      return  this.medicalPreAssessmentService.excludedValues;
    }

  isValid(): boolean {
    return true;
  }

  decisionTakenForNo(value: MedicalPreAssessmentDecisionEnum) {
    this.medicalPreAssessment.decision = value;
  }

  setRequiredField(question: MedicalPreAssessmentQuestionnaire) {
    return question.relevant === MedicalPreAssessmentRelevantEnum.YES;
  }

  get isCancelButtonDisabled() {
    return !this.medicalPreAssessment?.note?.evaluation;
  }

  private openMessageModal(documentId, receiverStakeholderId?: string, ccStakeholderIds?: string[]) {
    const message = new Message();
    message.templateType = 'PRE_ASSESSMENT';
    message.caseId = '' + this.caseId;
    message.messageChannelType = MessageChannelType.EMAIL;
    message.messageFrom = MessageFrom.EMAIL_SENDER_PRE_DEPARTURE;
    message.caseNumber = ' ';
    if (documentId) {
      message.preSelectDocumentAttachments = [new AttachmentDto({
        documentId: documentId,
        attachmentType: AttachmentTypeEnum.GENERATED_FROM_TEMPLATE
      })];
    }

    if (!!receiverStakeholderId) {
      message.stakeholderReceiverId = +receiverStakeholderId;
    } else {
      message.receiver = new CaseStakeholder({ person: new Person({ preferredLanguageCode: 'en' }) });
    }

    if (ccStakeholderIds?.length > 0) {
      message.cc = ccStakeholderIds.map(ccId => this.stakeholders.find(s => s.id === ccId));
    }

    const srMessage = new ServiceOrderMessageRequest({
      messageService: this.caseMessageService,
      message,
    });

    this.caseService.getCase('' + this.caseId).subscribe(result => {
      message.caseNumber = `[${result.caseNumber}]`;
      let matDialogRef = this.dialogViewerService.openOutputManagementDialog(
        result,
        MessageChannelType.EMAIL,
        srMessage,
        null,
        () => this.handleMessageSent()
      );

      setTimeout(() => this.dialogStateService.sendToFront(matDialogRef.id), 0);
    });
  }

  private handleMessageSent(): void {
  }


  private publishPreAssessment(): Observable<any> {
    this.storingMedicalPreAssessment = true;

    const copyMedicalAssessment: MedicalPreAssessment = JSON.parse(JSON.stringify(onChange.target(this.medicalPreAssessment)));

    const service: (req) => Observable<MedicalPreAssessment> = !this.medicalPreAssessment.id ?
      req => this.medicalPreAssessmentService.createMedicalPreAssessment(req) :
      req => this.medicalPreAssessmentService.updateMedicalPreAssessment(req);

    copyMedicalAssessment.questionnaires.forEach((question: any) => {
      if (!question.diagnosisPreDeparture.length) {
        question.diagnosisPreDeparture = null;
      }
      question.relevant = !question.relevant ? MedicalPreAssessmentRelevantEnum.YES : question.relevant;
    });

    return service(copyMedicalAssessment).pipe(
      tap(result => {
        this.updateMedicalPreAssessmentResult(result);
      }),
      finalize(() =>
        this.storingMedicalPreAssessment = false))
  }

  private updateMedicalPreAssessmentResult(result: MedicalPreAssessment) {
    this.medicalPreAssessment = result;
    this.caseStateService.isCaseMedicalPreAssessment = true;
    this.medicalPreAssessmentService.removeMedicalPreAssessmentDraft(this.caseId);

    this.caseStateService.sendRefreshCaseInfo();

    this.updateDraftButton.emit();
    this.medicalPreAssessmentService.setMedicalPreAssessmentDecisionChanges(this.caseId, this.medicalPreAssessment.decision);
  }

  ngOnDestroy(): void {
    this.medicalPreAssessmentService.componentText = '';
  }
}

