import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from './settings.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { SalesOrder, SalesOrderAdapter } from '../../shared/models/salesOrder';
import { map, mapTo } from 'rxjs/operators';
import { SalesOrderSosService, SalesOrderSosServiceAdapter } from '@secca/shared/models/sales-order-sos-service';
import { DineroObject } from 'dinero.js';
import { SalesOrderItem, SalesOrderItemAdapter } from '@secca/shared/models/salesOrderItem';
import { CaseLockHttpService } from './case-lock-http.service';
import { FeePickerStatus, FeePickerStatusAdapter } from '@secca/shared/models/fee-picker-status';
import { FeeTypeEnum, SosServiceCategoriesEnum } from '@secca/shared/models/enums';
import * as moment from 'moment-timezone';
import {Debtor, DebtorAdapter} from "@secca/shared/models/debtor";

@Injectable({
  providedIn: 'root',
})
export class SalesOrderService extends BaseService {
  private salesOrders = new BehaviorSubject(undefined);

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private salesOrderAdapter: SalesOrderAdapter,
    private salesOrderItemAdapter: SalesOrderItemAdapter,
    private debtorAdaptor: DebtorAdapter,
    private salesOrderSosServiceAdapter: SalesOrderSosServiceAdapter,
    private feePickerStatusAdapter: FeePickerStatusAdapter,
    private caseLockHttpService: CaseLockHttpService
  ) {
    super(settingsService);
  }

  public sendSalesOrders(salesOrders: SalesOrder[]) {
    this.salesOrders.next(salesOrders);
  }

  validateCustomerInvoice(caseId: string) {
    return this.http.get<string[]>(this.baseURL + 'sales-orders/validate-for-finalization' + '/' + caseId, {
      headers: this.jsonHeaders,
    });
  }

  public finalizeSalesOrder(salesOrder: SalesOrder): Observable<SalesOrder[]> {
    return this.caseLockHttpService
      .put<number>(this.baseURL + 'sales-orders/finalize' + '/' + salesOrder.id, {
        headers: this.jsonHeaders,
      })
      .pipe(map((data: any[]) => data.map(item => this.salesOrderAdapter.adapt(item))));
  }

  public getSalesOrders(): Observable<SalesOrder[]> {
    return this.salesOrders.asObservable();
  }

  public getSalesOrdersByCaseId(caseId: string): Observable<SalesOrder[]> {
    return this.http
      .get(this.baseURL + 'sales-orders' + '/' + 'case' + '/' + caseId, { headers: this.jsonHeaders })
      .pipe(map((data: any[]) => data.map(item => this.salesOrderAdapter.adapt(item))));
  }

  public getPriceAgreementServices(profileId: number, businessArea: string, caseCreationDate: Date): Observable<SalesOrderSosService[]> {
    const utcValidDate = moment.utc(caseCreationDate);
    return this.http
      .get(
        this.baseURL + 'profile' + '/' + profileId + '/' + businessArea + '/price-agreement/sos-service?validDate=' + utcValidDate.format(),
        { headers: this.jsonHeaders }
      )
      .pipe(map((data: any[]) => data.map(item => this.salesOrderSosServiceAdapter.adapt(item))));
  }

  public addSalesOrderItem(caseId: string, selectedSosServiceId: string, actionComment: string): Observable<void> {
    const salesOrderItem = { sosServiceId: selectedSosServiceId, actionComment };
    return this.caseLockHttpService
      .post(this.baseURL + 'sales-orders' + '/' + 'case' + '/' + caseId, salesOrderItem, { headers: this.jsonHeaders })
      .pipe(map((data: any[]) => data.map(item => this.salesOrderAdapter.adapt(item))))
      .pipe(map(result => this.sendSalesOrders(result)));
  }

  public replaceSalesOrderItem(salesOrderItemId: number, selectedSosServiceId: string, actionComment: string): Observable<void> {
    const salesOrderItem = { id: salesOrderItemId, sosServiceId: selectedSosServiceId, actionComment };
    return this.caseLockHttpService
      .put(this.baseURL + 'sales-orders' + '/' + 'sales-order-item' + '/' + 'replace', salesOrderItem, { headers: this.jsonHeaders })
      .pipe(map((data: any[]) => data.map(item => this.salesOrderAdapter.adapt(item))))
      .pipe(map(result => this.sendSalesOrders(result)));
  }

  public salesOrderItemAdjustAmount(salesOrderItemId: number, newAmount: DineroObject, actionComment: string): Observable<void> {
    const salesOrderItem = { id: salesOrderItemId, agreementPrice: newAmount, actionComment };
    return this.caseLockHttpService
      .put(this.baseURL + 'sales-orders' + '/' + 'sales-order-item' + '/' + 'adjust', salesOrderItem, { headers: this.jsonHeaders })
      .pipe(map((data: any[]) => data.map(item => this.salesOrderAdapter.adapt(item))))
      .pipe(map(result => this.sendSalesOrders(result)));
  }

  public deleteSalesOrderItem(caseId: number, salesOrderItem: SalesOrderItem, actionComment: string): Observable<void> {
    const feeType = Object.keys(FeeTypeEnum).find(k => FeeTypeEnum[k] === salesOrderItem.feeType);
    const sosServicesCategory = Object.keys(SosServiceCategoriesEnum).find(
      k => SosServiceCategoriesEnum[k] === salesOrderItem.sosServicesCategory
    );
    const deleteDto = { id: salesOrderItem.id, suggestion: salesOrderItem.suggestion, feeType, sosServicesCategory, actionComment };
    return this.caseLockHttpService
      .put(this.baseURL + 'sales-orders' + '/' + 'sales-order-item' + '/' + 'delete' + '/' + caseId, deleteDto, {
        headers: this.jsonHeaders,
      })
      .pipe(map((data: any[]) => data.map(item => this.salesOrderAdapter.adapt(item))))
      .pipe(map(result => this.sendSalesOrders(result)));
  }

  public deleteSalesOrder(salesOrderId: number, salesOrder: SalesOrder): Observable<void> {
    return this.caseLockHttpService
      .put(`${this.baseURL}sales-orders/delete/${salesOrderId}`, salesOrder, {
        headers: this.jsonHeaders,
      });
  }

  public reinvoiceSalesOrder(salesOrderId: number, debtor: Debtor, salesOrder: SalesOrder): Observable<void> {
    const reinvoiceDto = { debtor: debtor, salesOrder: salesOrder };
    return this.caseLockHttpService
      .put(`${this.baseURL}sales-orders/reinvoice/${salesOrderId}`, reinvoiceDto, {
        headers: this.jsonHeaders,
      });
  }

  public creditSalesOrderItem(caseId: number, salesOrderItem: SalesOrderItem, actionComment: string): Observable<void> {
    const creditDto = { id: salesOrderItem.id, actionComment };
    return this.caseLockHttpService
      .put(this.baseURL + 'sales-orders' + '/' + 'sales-order-item' + '/' + 'credit' + '/' + caseId, creditDto, {
        headers: this.jsonHeaders,
      })
      .pipe(map((data: any[]) => data.map(item => this.salesOrderAdapter.adapt(item))))
      .pipe(map(result => this.sendSalesOrders(result)));
  }

  public selectSuggestedSalesOrderItem(caseId: number, salesOrderItem: SalesOrderItem, actionComment: string): Observable<void> {
    const feeType = Object.keys(FeeTypeEnum).find(k => FeeTypeEnum[k] === salesOrderItem.feeType);
    const sosServicesCategory = Object.keys(SosServiceCategoriesEnum).find(
      k => SosServiceCategoriesEnum[k] === salesOrderItem.sosServicesCategory
    );
    const suggested = {
      id: salesOrderItem.id,
      suggestion: salesOrderItem.suggestion,
      sosServiceId: salesOrderItem.sosServiceId,
      feeType,
      sosServicesCategory,
      actionComment,
    };
    return this.caseLockHttpService
      .put(this.baseURL + 'sales-orders' + '/' + 'suggested-sales-order-item/select' + '/' + caseId, suggested, {
        headers: this.jsonHeaders,
      })
      .pipe(map((data: any[]) => data.map(item => this.salesOrderAdapter.adapt(item))))
      .pipe(map(result => this.sendSalesOrders(result)));
  }

  public getFeePickerStatus(caseId: string): Observable<FeePickerStatus> {
    return this.http
      .get(this.baseURL + 'sales-orders' + '/' + 'get-fee-picker-status' + '/' + caseId, { headers: this.jsonHeaders })
      .pipe(map(value => this.feePickerStatusAdapter.adapt(value)));
  }

  public enableFeePicker(caseId: number, feePickerStatus: FeePickerStatus): Observable<FeePickerStatus> {
    return this.caseLockHttpService
      .put(this.baseURL + 'sales-orders' + '/' + 'enable-fee-picker' + '/' + caseId, feePickerStatus, { headers: this.jsonHeaders })
      .pipe(map(value => this.feePickerStatusAdapter.adapt(value)));
  }

  public executeFeePicker(caseId: string): Observable<void> {
    return this.http
      .put(this.baseURL + 'sales-orders' + '/' + 'execute-fee-picker' + '/' + caseId, { headers: this.jsonHeaders })
      .pipe(mapTo(null));
  }

  public isFeePickerRunning(caseId: string): Observable<boolean> {
    return this.http
      .get(this.baseURL + 'sales-orders' + '/' + 'is-fee-picker-running' + '/' + caseId, { headers: this.jsonHeaders })
      .pipe(map(value => !!value));
  }

  public getAllowedDebtors(salesOrder: SalesOrder): Observable<Debtor[]> {
    return this.http
      .get(this.baseURL + 'sales-orders' + '/' + salesOrder.id + '/' + 'allowed-debtors', { headers: this.jsonHeaders })
      .pipe(map((data: any[]) => data.map(item => this.debtorAdaptor.adapt(item))));
  }

  public saveCustomerInvoiceNote(salesOrder: SalesOrder): Observable<SalesOrder> {
    return this.http
      .put(this.baseURL + 'sales-orders' + '/' + 'customer-invoice-note' + '/' + salesOrder.id, salesOrder, { headers: this.jsonHeaders })
      .pipe(map(item => this.salesOrderAdapter.adapt(item)));
  }

  public getFullHistory(caseId: string): Observable<SalesOrder[]> {
    return this.http
      .get(this.baseURL + 'sales-orders/full-history/' + caseId, { headers: this.jsonHeaders })
      .pipe(map((data: any[]) => data.map(item => this.salesOrderAdapter.adapt(item))));
  }

  public getFullItemHistory(caseId: string): Observable<SalesOrderItem[]> {
    return this.http
      .get(this.baseURL + 'sales-orders/full-item-history/' + caseId, { headers: this.jsonHeaders })
      .pipe(map((data: any[]) => data.map(item => this.salesOrderItemAdapter.adapt(item))));
  }
}
