<div class="d-flex">
  <div class="left-column">
    <div class="spacer"></div>
    <div class="left-row">
      <div class="left-column-container">
        <div class="text">
          {{ 'left-side-status' | translate }}
        </div>

        <div class="left-row d-inline-flex w-100">
          <div class="flex-grow-1 w-auto">
            <label class="custom-radio-button radio-disabled">
              <input type="checkbox"
                [checked]="serviceOrder.status === StatusTypes.EXPECTED"
                [disabled]="expectedDisabled()"
                (click)="changeStatus(StatusTypes.EXPECTED, $event)"
                />
              <span class="checkmark"></span>
              <div class="radio-button-text">{{ 'service-order-expected' | translate }}</div>
            </label>
          </div>
          <div class="flex-grow-1 w-auto">
            <label class="custom-radio-button" [class.radio-disabled]="committedDisabled()">
              <input
                type="checkbox"
                [checked]="serviceOrder.status == StatusTypes.COMMITTED"
                [disabled]="committedDisabled()"
                (click)="changeStatus(StatusTypes.COMMITTED, $event)" />
              <span class="checkmark"></span>
              <div class="radio-button-text">{{ 'service-order-committed' | translate }}</div>
            </label>
          </div>
          <div class="flex-grow-1 w-auto">
            <label class="custom-radio-button" [class.radio-disabled]="cancelledDisabled()">
              <input type="checkbox"
                [checked]="serviceOrder.status === StatusTypes.CANCELLED"
                (click)="changeStatus(StatusTypes.CANCELLED, $event)"
                [disabled]="cancelledDisabled()"
              />
              <span class="checkmark"></span>
              <div class="radio-button-text">{{ 'service-order-cancelled' | translate }}</div>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="horizontal-line"></div>
    <div class="left-row">
      <div class="left-column-container">
        <div class="remarks-container">
          <div class="text">{{ 'service-order-remarks' | translate }}</div>
          <textarea name="textarea" class="remarks-textarea remarks" maxlength="1000" [(ngModel)]="serviceOrder.remarks"
                    (ngModelChange)="dataChanged()"></textarea>
        </div>
      </div>
    </div>
    <div class="horizontal-line"></div>
    <ng-container *ngIf="isNotMedicalEscortServiceOrderType">
      <div class="horizontal-line"></div>
      <div class="stakeholders-container">
        <div class="text">
          {{ 'service-order-stakeholder-using-the-service' | translate }}
        </div>
        <div class="stakeholders">
          <div *ngFor="let stakeholder of selectedStakeholders; index as i">
            <div [ngClass]="{ 'stakeholder': !isOddNumber(i), 'stakeholder-gray': isOddNumber(i) }">
              <app-stakeholder [stakeholder]="stakeholder"></app-stakeholder>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
