import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TaskViewModel } from '@secca/board/components/board-task/models/task-view.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CaseService } from '@secca/core/services/case.service';
import { TaskRedirectFromGlobalTaskBoard } from '@secca/shared/models/task-redirect-from-global-task-board';
import { StakeholderService } from '@secca/core/services/stakeholder.service';
import { Task } from '@secca/shared/models/task';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { TaskStateService } from '@secca/board/components/board-task/services/task-state.service';
import { TaskService } from '@secca/core/services/task.service';
import { DigitalConsentService } from '@secca/core/services/digital-consent.service';
import { DigitalConsent } from '@secca/shared/models/digital-consent';
import { Router } from '@angular/router';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { ModalDialogComponent } from '@secca/shared/components/modal-dialog/modal-dialog.component';
import { ModalDialogConfiguration } from '@secca/shared/models/modal/modal-dialog-configuration';
import { CaseLockOverlayAction, LockContextEnum } from '@secca/shared/enums/lock-context.enum';
import { DialogHelperUtilService } from '@secca/core/services/dialog-helper-util.service';
import { TaskMessageLeftPanelComponent } from '../task-message-left-panel/task-message-left-panel.component';
import { TaskModalModeEnum } from '@secca/case/components/case-task/task-modal-static/task-modal-static-mode.enum';
import { ConsentStatusEnum } from '@secca/shared/models/enums';

@Component({
  selector: 'app-task-consent-modal',
  templateUrl: './task-consent-modal.component.html',
  styleUrls: ['./task-consent-modal.component.scss']
})
@AutoUnsubscribe
export class TaskConsentModalComponent implements OnInit {
  @ViewChild('taskModal') taskModal: TaskMessageLeftPanelComponent;
  private _taskViewModel: TaskViewModel;
  selectedStakeholder: any;
  oldTask: Task;
  consent: DigitalConsent;
  stakeholder: CaseStakeholder;
  changesOnTask: boolean;

  @Input() invokedFromTheCase;
  @Output() taskUpdatedEvent = new EventEmitter<number>();

  get taskViewModel(): TaskViewModel {
    return this._taskViewModel;
  }

  constructor(private modalService: NgbModal,
              private dialogHelperUtilService: DialogHelperUtilService,
              private caseService: CaseService,
              private stakeholderService: StakeholderService,
              private taskStateService: TaskStateService,
              private taskService: TaskService,
              private digitalConsentService: DigitalConsentService,
              private router: Router,
  ) {}

  ngOnInit() {
    this.digitalConsent();
  }

  @Input() set taskViewModel(value: TaskViewModel) {
    // Angular does ngOnCheck in undeterministic way, usually at the beginning of the component initialization and user interaction.
    // In such case input parameter is updated (it may be the same value) and then the setter is called and stakeholders are fetched.
    // To avoid doing it multiple times I added this check.
    if (this._taskViewModel !== null &&
      this._taskViewModel !== undefined
      && value !== undefined
      && value !== null
      && value.id === this._taskViewModel.id) {
      return;
    }
    this._taskViewModel = value;
  }

  @Output() closed = new EventEmitter<number>();

  taskLoadedNotification(loaded: boolean): void {
    this.loadData(this.task);
  }

  private loadData(task: Task): void {
    if (task != null) {
      this.oldTask = new Task();
      Object.assign(this.oldTask, task);
    }
  }

  checkIfThereWereChanges() {
    this.changesOnTask = this.modelChanged();
  }

  private getStakeholder() {
    this.stakeholderService.getStakeholder(this.consent.caseStakeholder).subscribe(stakeholder => {
      this.stakeholder = stakeholder;
    });
  }

  informTaskHasChanged() {
    this.taskStateService.refreshAllTasksAndPendingNumberOfTasks.next();
  }

  saveTaskChanges() {
    if (this.taskChanged) {
      this.taskService.updateTask(this.taskModal.task).subscribe(
        result => {
          this.taskUpdatedEvent.emit(this.task.id);
          Object.assign(this.oldTask, this.task);
          if (this.isOnCasePage) {
            this.informTaskHasChanged();
          }
          this.closeTaskMessageDialog();
        },
        error => console.log(error)
      );
    }
  }

  get task(): Task {
    if (this.taskModal) {
      return this.taskModal.task;
    } else {
      return null;
    }
  }

  get isOnCasePage(): boolean {
    return this.invokedFromTheCase || this.router.url.startsWith('/case');
  }

  goToCase() {
    this.taskService.taskRedirectFromGlobalTaskBoard = new TaskRedirectFromGlobalTaskBoard({
      caseId: this.taskViewModel.caseId,
      taskId: +this.taskViewModel.id,
      taskStatus: this.taskViewModel.status,
      directRedirection: true
    });

    if (this.modelChanged()) {
      const modalRef = this.openLeaveWarningDialog();
      modalRef.componentInstance.closeModalEvent.subscribe(
        closedClickingYes => {
          if (closedClickingYes) {
            this.navigateToCase();
          } 
        }
      );
    }
    else {
      this.navigateToCase();
      this.close();
    }
  }

  closeTaskMessageDialog() {
    if (this.modelChanged()) {
      this.openLeaveWarningDialog();
    } else {
      this.close();
    }
  }

  close() {
    if ( this.closed.observers ) {
      this.closed.emit();
    }
    this.dialogHelperUtilService.closeModal();
  }

  openLeaveWarningDialog(): NgbModalRef {
    const modalRef = this.modalService.open(ModalDialogComponent);
    modalRef.componentInstance.items = [];
    modalRef.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'default-modal-header',
      title: 'case-tasks-leave-edited-task-or-go-back-and-save-pop-up',
      text: null,
      footer: 'case-validation-warning-modal-footer',
      yes: 'default-modal-dialog-yes',
      no: 'default-modal-dialog-no',
      isBody: true,
      isFooter: true,
    });
    modalRef.componentInstance.closeModalEvent.subscribe(
      closedClickingYes => {
        if (closedClickingYes) {
          this.close();
        } 
        modalRef.close();
      },
      error => console.log(error)
    );

    return modalRef;
  }

  get stakeholderIcon() {
    if (this.stakeholder) {
      return this.stakeholderService.getStakeholderIconFullPath(this.stakeholder.stakeholderType);
    }
  }

  private digitalConsent() {
    this.digitalConsentService.getDigitalConsentStatus(this.taskViewModel.caseId).subscribe(digitalConsent => {
      if (digitalConsent !== null) {
        this.consent = digitalConsent;
        this.getStakeholder();
      }
    });
  }

  private navigateToCase() {
    this.router.navigate(['case', this.taskViewModel.caseId]);
  }

  goViewConsent() {
    window.open(this.consent.consentUrlAc);
  }

  goShareConsent() {
    window.open(this.consent.consentUrlDoctor);
  }

  get isFailed(): boolean {
    return this.consent?.consentType === ConsentStatusEnum.AUTO_FAILED;
  }

  get TaskModalModeEnum() {
    return TaskModalModeEnum;
  }

  get LockContextEnum(): any {
    return LockContextEnum;
  }

  get CaseLockOverlayAction(): any {
    return CaseLockOverlayAction;
  }

  get taskChanged(): boolean {
    return (
      this.oldTask.status !== this.taskModal.task.status ||
      this.oldTask.userId !== this.taskModal.task.userId ||
      this.oldTask.adtCode !== this.taskModal.task.adtCode ||
      this.oldTask.title !== this.taskModal.task.title ||
      this.oldTask.dueDate !== this.taskModal.task.dueDate ||
      this.oldTask.priority !== this.taskModal.task.priority
    );
  }

  modelChanged(): boolean {
    if (
      this.oldTask == null ||
      this.taskModal == null ||
      this.taskModal.task == null
    ) {
      return false;
    }
    return this.taskChanged;
  }

  checkIfThereWereChangesOnTask() {
    this.changesOnTask = this.modelChanged();
  }
}
