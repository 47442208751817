import {Component, EventEmitter, Output, TemplateRef, ViewChild} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SalesOrderService } from '@secca/core/services/sales-order.service';
import { SalesOrder } from '@secca/shared/models/salesOrder';
import {Debtor} from "@secca/shared/models/debtor";

@Component({
  selector: 'app-customer-invoice-credit-modal',
  templateUrl: './customer-invoice-credit-modal.component.html',
  styleUrls: ['./customer-invoice-credit-modal.component.scss'],
})
export class CustomerInvoiceCreditModalComponent {
  @ViewChild('content') private template: TemplateRef<any>;
  saveInProgress = false;
  salesOrder: SalesOrder;
  debtors: Debtor[] = [];
  selectedId: string;
  actionComment: string;
  @Output() salesOrderDeleted = new EventEmitter();

  constructor(private salesOrderService: SalesOrderService, private modalService: NgbModal) {}

  show(salesOrder: SalesOrder) {
    this.salesOrderService.getAllowedDebtors(salesOrder).subscribe(debtors => {
      this.debtors = debtors;
    });
    this.salesOrder = salesOrder;
    this.modalService.open(this.template, { size: 'lg' as any });
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  creditSalesOrder() {
    this.saveInProgress = true;
    this.salesOrder.customerInvoiceNote = this.actionComment;
    this.salesOrderService.reinvoiceSalesOrder(this.salesOrder.id, this.debtors.find(d => d.id === this.selectedId), this.salesOrder).subscribe(so => {
      this.salesOrder.credited = true;
      this.closeModal();
    });
  }
}
