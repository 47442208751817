import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
import * as moment from 'moment';

export class ReductionType {
  id: number;
  reductionCode: string;
  reductionName: string;
  description: string;
  uiSortIndex: number;
  activateOn: moment.Moment;
  active: boolean;
  usedInRefund: boolean;
  usedInSupplierInvoice: boolean;
  shareOfCostControl: boolean;
  partnerOnly: boolean;

  public constructor(init?: Partial<ReductionType>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
export class ReductionTypeAdapter implements Adapter<ReductionType> {
  adapt(item: any): ReductionType {
    return new ReductionType({
      id: item.id,
      reductionCode: item.reductionCode,
      reductionName: item.reductionName,
      description: item.description,
      uiSortIndex: item.uiSortIndex,
      activateOn: item.activateOn,
      active: item.active,
      usedInRefund: item.usedInRefund,
      usedInSupplierInvoice: item.usedInSupplierInvoice,
      shareOfCostControl: item.shareOfCostControl,
      partnerOnly: item.partnerOnly,
    });
  }
}
