<ng-template #content let-modal>
  <div class="edit-refund-container">
    <div class="edit-refund-modal-header">
      <div class="edit-refund-modal-header-close float-end clearfix">
        <img (click)="close()" src="/assets/icons/Close.svg" alt="Close" />
      </div>

      <div class="edit-refund-modal-header-title">
        {{ title | translate }}
      </div>
    </div>
    <div [appDisableInput]="caseLockHelperService.isCaseBasicLockTaken || permissionService.isReadOnly">
      <div class="edit-refund-modal-body">
        <app-text-box
          [maxTextLength]="250"
          [name]="'case-economy-edit-refund-comment' | translate"
          [(value)]="actionComment"
        ></app-text-box>
      </div>
      <div class="modal-footer">
        <div class="d-flex justify-content-end">
          <button [disabled]="! (this.actionComment?.length > 0)" (click)="save()">
            {{ 'case-economy-edit-refund-save' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
