<div *ngIf="_executions">
  <app-alert-box *ngIf="succeeded.length > 0 && failed.length == 0"
                 [title]="('policy-lookup-integration-status-successful' | translate)"
                 [type]="'success'">
  <ng-container *ngTemplateOutlet="successful"></ng-container>
  </app-alert-box>
  <app-alert-box *ngIf="failed.length > 0"
                 [title]="('policy-lookup-integration-status-failed' | translate)"
                 [type]="'danger'">
    <div *ngIf="succeeded.length > 0">
      <ng-container *ngTemplateOutlet="successful"></ng-container>
    </div>
    <div>
      <span>{{'policy-lookup-integration-failed-details' | translate}}:</span>
      <ul>
        <li *ngFor="let lookup of failed">
          <ng-container *ngIf="lookup.type">
            {{'policy-lookup-method-type-' + lookup.type | translate }} {{lookup.sourceInfo ? '(' + lookup.sourceInfo + ')' : ''}} &mdash;
            {{ 'policy-lookup-error-' + lookup.error.type | translate }}
            <div><span class="label">{{ 'policy-lookup-error-type' | translate }}</span> {{ lookup.error.type }}</div>
            <div><span class="label">{{ 'policy-lookup-error-message' | translate }}</span> {{ lookup.error.message }}</div>
            <div *ngIf="lookup.error.params">
              <div><span class="label">{{ 'policy-lookup-error-params' | translate }}</span></div>
              <div class="small-text">{{ stringify(lookup.error.params) }}</div>
            </div>
          </ng-container>
        </li>
      </ul>
      <span>{{'policy-lookup-integration-product-details' | translate}}:</span>
      <ul>
        <li *ngFor="let p of failedProducts">
          {{ p }}
        </li>
      </ul>
    </div>
  </app-alert-box>
</div>

<ng-template #successful>
  <span>{{'policy-lookup-integration-successful-details' | translate}}:</span>
  <ul>
    <li *ngFor="let lookup of succeeded">{{'policy-lookup-method-type-' + lookup.type | translate }} {{lookup.sourceInfo ? '(' + lookup.sourceInfo + ')' : ''}}</li>
  </ul>
</ng-template>
