import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfileUpdateInProcessService {
  private profiles = {};

  observe(caseId: string) {
    this.init(caseId);
    return this.profiles[caseId].asObservable();
  }

  publish(caseId: string, inProcess: boolean) {
    this.init(caseId);
    this.profiles[caseId].next(inProcess);
  }

  init(caseId: string) {
    if (this.profiles[caseId] == null) {
      this.profiles[caseId] = new BehaviorSubject(undefined);
    }
  }
}
