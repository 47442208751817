import {Injectable} from '@angular/core';
import { ChannelType } from '../enums/channelType';
import {Adapter} from './../interfaces/adapter';
import { HandlingAreaStatusEnum } from './enums';
import {SimpleCaseStakeholder, SimpleCaseStakeholderAdapter} from './simpleCaseStakeholder';
import {CASE_TYPE_CODES, CaseTypeCodes} from '@secca/shared/models/case-type';

export class SearchCaseResult {
  customer: any;
  customerProduct: any;
  caseId: string;
  caseNumber: string;
  endUserMatch: boolean;
  endUserName: string;
  endUserAge: number;
  endUserId: number;
  endUserSsnValidated: boolean;
  policyHolderMatch: boolean;
  policyHolderName: string;
  policyHolderId: number;
  policyHolderSsnValidated: boolean;
  incidentCountry: string;
  incidentCity: string;
  incidentCause: string;
  incidentDescription: string;
  caseTypeCode: string;
  incidentDate: Date;
  teamStatus: HandlingAreaStatusEnum;
  caseCreationDate: Date;
  endUserSsnValid: boolean;
  policyHolderSsnValid: boolean;
  endUser: SimpleCaseStakeholder;
  policyHolder: SimpleCaseStakeholder;
  channelType: ChannelType;
  taskId: number;
  caseValidated: boolean;
  webTaskCaseValidated: boolean;
  coordinationCaseName: string;

  public constructor(init?: Partial<SearchCaseResult>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})

export class SearhCaseResultAdapter implements Adapter<SearchCaseResult> {
  constructor(private simpleCaseStakeholderAdapter: SimpleCaseStakeholderAdapter) {
  }

  adapt(item: any): SearchCaseResult {
    return new SearchCaseResult({
      customer: item.customer,
      customerProduct: item.customerProduct,
      caseId: item.caseId,
      caseNumber: item.caseNumber,
      endUserMatch: item.endUserMatch,
      endUserName: item.endUserName,
      endUserAge: item.endUserAge,
      endUserId: item.enduserId,
      endUserSsnValidated: item.endUserSsnValidated,
      policyHolderMatch: item.policyHolderMatch,
      policyHolderName: item.policyHolderName,
      policyHolderId: item.policyholderId,
      policyHolderSsnValidated: item.policyHolderSsnValidated,
      caseCreationDate: new Date(item.caseCreationDate),
      incidentCountry: item.incidentCountry,
      incidentCity: item.incidentCity,
      incidentCause: item.incidentCause,
      caseTypeCode: item.caseTypeCode,
      incidentDescription: item.incidentDescription,
      incidentDate: item.incidentDate ? new Date(item.incidentDate) : null,
      teamStatus: item.teamStatus,
      endUser: this.simpleCaseStakeholderAdapter.adapt(item.endUser),
      policyHolder: this.simpleCaseStakeholderAdapter.adapt(item.policyHolder),
      channelType: item.channelType,
      taskId: item.taskId,
      caseValidated: item.caseValidated,
      webTaskCaseValidated: item.webTaskCaseValidated
    });
  }
}
