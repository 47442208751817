import { Injectable } from '@angular/core';
import { DataService } from '@secca/core/services/data.service';
import { SettingsService } from '@secca/core/services/settings.service';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ModalDialogComponent } from '@secca/shared/components/modal-dialog/modal-dialog.component';
import { ModalDialogConfiguration } from '@secca/shared/models/modal/modal-dialog-configuration';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class OpenCaseLimitService implements CanActivate {

  constructor(private dataService: DataService,
              private settingsService: SettingsService,
              private translateService: TranslateService,
              private modalService: NgbModal) {
  }

  public isOpenedCasesLimitReached() {
    return this.dataService.getOpenCasesCount() >= this.settingsService.getOpenCasesMaxCount();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const id = route.paramMap.get('id');
    const isAlreadyOpened = this.dataService.isCaseOpened(+id);

    if (!isAlreadyOpened && this.isOpenedCasesLimitReached()) {
      this.showErrorModal();
      return false;
    }
    return true;
  }

  verifyOpenCasesCount() {
    const openCasesCount = this.dataService.getOpenCasesCount();
    if (openCasesCount >= this.settingsService.getOpenCasesMaxCount()) {
      this.showWarningLimitReachedModal();
    }
    else if (openCasesCount >= this.settingsService.getOpenCasesWarningThreshold()) {
      this.showInfoModal();
    }
  }

  private showErrorModal() {
    const params = {
      limit: this.settingsService.getOpenCasesMaxCount()
    };
    this.showModal(
      'open-cases-limit-modal-error-header',
      this.translateService.instant('open-cases-limit-modal-error-title', params),
      'open-cases-limit-modal-error-text'
    );
  }

  private showWarningLimitReachedModal() {
    const params = {
      limit: this.settingsService.getOpenCasesMaxCount()
    };
    this.showModal(
      'open-cases-limit-modal-warning-limit-reached-header',
      this.translateService.instant('open-cases-limit-modal-error-title', params),
      'open-cases-limit-modal-error-text'
    );
  }

  private showInfoModal() {
    const params = {
      limit: this.settingsService.getOpenCasesMaxCount(),
      current: this.dataService.getOpenCasesCount()
    };
    this.showModal(
      'default-modal-header',
      this.translateService.instant('open-cases-limit-modal-warning-title', params),
      'open-cases-limit-modal-warning-text'
    );
  }

  private showModal(mHeader: string, mTitle: string, mText: string) {
    const caseNumberInfoModalRef = this.modalService.open(ModalDialogComponent, {backdrop: 'static', keyboard: false});
    caseNumberInfoModalRef.componentInstance.configuration = new ModalDialogConfiguration({
      header: mHeader,
      title: mTitle,
      text: mText,
      yes: 'open-cases-limit-modal-dismiss',
      isBody: true,
      isFooter: true,
    });
    caseNumberInfoModalRef.componentInstance.closeModalEvent.subscribe(
      _ => {
        caseNumberInfoModalRef.close();
      }
    );
  }
}
