import { Injectable } from '@angular/core';

export class CustomerProductInfo {
  termsDocumentId: string;
  termsName: string;
  productCoveragePeriodType: string;

  constructor(init?: Partial<CustomerProductInfo> ) {
    Object.assign(this, init);
  }
}

@Injectable({providedIn: 'root'})
export class CustomerProductInfoAdapter {
  public adapt(item: any): CustomerProductInfo {
    return new CustomerProductInfo({
      termsDocumentId: item.termsDocumentId,
      termsName: item.termsName,
      productCoveragePeriodType: item.productCoveragePeriodType
    });
  }
}
