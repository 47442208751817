import {BaseService} from './base.service';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SettingsService} from './settings.service';
import {SearchUserDropDownWrapper} from '../../shared/models/searchUser';
import {
  CustomerProductRelation,
  CustomerProductRelationType,
  ProfileLookupResponse,
  ProfileLookupResponseAdapter
} from '@secca/shared/models/profile-lookup-response';
import {DropdownAdapter, DropdownDictionary} from '@secca/shared/models/dropdownDictionary';

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends BaseService {
  constructor(
    private http: HttpClient,
    private searchUserDropDownWrapper: SearchUserDropDownWrapper,
    private settingsService: SettingsService,
    private profileLookupResponseAdapter: ProfileLookupResponseAdapter,
    private dropdownAdapter: DropdownAdapter
  ) {
    super(settingsService);
  }

  public lookupProfile(caseId: string, customerProductRelationTypes: CustomerProductRelationType[],
                       companyName: string, policyNo: string, market: string,
                       bin: string, bank: string, cardType: string, level: string,
                       incidentDate: string, reportDate: string): Observable<ProfileLookupResponse> {
    let searchParams = 'case-id=' + caseId;
    searchParams += '&customerProductRelationTypes=' + customerProductRelationTypes;
    searchParams += (companyName ? '&company-name=' + companyName : '');
    searchParams += (policyNo ? '&policy-no=' + policyNo : '');
    searchParams += (market ? '&market=' + market : '');
    searchParams += (bin ? '&bin=' + bin : '');
    searchParams += (bank ? '&bank=' + bank : '');
    searchParams += (cardType ? '&product-name=' + cardType : '');
    searchParams += (level ? '&level=' + level : '');
    searchParams += (incidentDate ? '&incident-date=' + incidentDate : '');
    searchParams += (reportDate ? '&report-date=' + reportDate : '');
    return this.http.get<ProfileLookupResponse>(this.baseURL + 'policies/customer-search?' + searchParams)
      .pipe(map(item => this.profileLookupResponseAdapter.adapt(item)));
  }

  public getBanks(bin: string): Observable<DropdownDictionary[]> {
    const searchParams = (bin ? '?bin=' + bin : '');
    return this.http.get<DropdownDictionary[]>(this.baseURL + 'customer-product-relation/banks' + searchParams)
      .pipe(map((value: any[]) => value.map(item => this.dropdownAdapter.adapt(item))));
  }

  public getCardTypes(bin: string, bank: string): Observable<DropdownDictionary[]> {
    let searchParams = 'bank=' + bank;
    searchParams += (bin ? '&bin=' + bin : '');
    return this.http.get<DropdownDictionary[]>(this.baseURL + 'customer-product-relation/card-types?' + searchParams)
      .pipe(map((value: any[]) => value.map(item => this.dropdownAdapter.adapt(item))));
  }

  public getLevels(bin: string, bank: string, cardType): Observable<DropdownDictionary[]> {
    let searchParams = 'bank=' + bank + '&card-type=' + cardType;
    searchParams += (bin ? '&bin=' + bin : '');
    return this.http.get<DropdownDictionary[]>(this.baseURL + 'customer-product-relation/levels?' + searchParams)
      .pipe(map((value: any[]) => value.map(item => this.dropdownAdapter.adapt(item))));
  }

  public getProfileMarket(profileId: number): Observable<string> {
    return this.http.get<any>(this.baseURL + 'profile/customer-profile/' + profileId)
      .pipe(map(item => item.market));
  }
}
