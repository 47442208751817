<hr>
<div class="hover-container-content-section-title">
  {{'medical-assessment-service-order-assignee' | translate }}
</div>
<div class="hover-container-content-bold">
  {{ assignee | translate }}
</div>
<hr>
<div class="hover-container-content-section-title">
  Medical staff
</div>
<div class="hover-container-content-bold">
  {{medicalStaff?.firstName}} {{ medicalStaff?.surname }}
</div>
<hr>
<div class="hover-container-content-section-title">
  Assessment type
</div>
<div class="hover-container-content-bold">
  {{ assessmentType | translate }}
</div>
<hr>
<div class="hover-container-content-section-title">
  Status Type
</div>
<div class="hover-container-content-bold">
  <span >{{ statusType| translate }}</span>
</div>
<hr>
<div class="hover-container-content-section-title">
  Start
</div>
<div class="hover-container-content-bold">
  {{extension.startDate | momentFormat: 'DD MMM YYYY HH:mm' : 'local'}} <span>Denmark</span>
</div>
<div class="hover-container-content-section-title">
  End
</div>
<div class="hover-container-content-bold">
  {{serviceOrder.getEndDate() | date: 'dd MMM yyyy HH:mm'}} <span>Denmark</span>
</div>
<hr>
<div class="hover-container-content-section-title">
  Last edited
</div>
<div class="hover-container-content-bold">
      <span *ngIf="serviceOrder.lastEditedByInitials">
        {{serviceOrder.lastEditedByInitials | uppercase}}
      </span>
  <span *ngIf="serviceOrder.modifiedOn">
        {{serviceOrder.modifiedOn | momentFormat:'DD MMM YYYY HH:mm':'local'}}
      </span>
</div>
