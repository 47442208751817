import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateHelperService {
  public readonly NUMBER_OF_MILLISECONDS_IN_MINUTE: number = 60 * 1000;

  constructor() {}

  public isDateAfterTodayThisYear(date: Date) {
    return (
      new Date(new Date().getFullYear(), date.getMonth(), date.getDate()) >
      new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
    );
  }

  public convertFromUTCDateTimeToLocalDateTime(date: any): Date {
    if (date == null) {
      return null;
    }
    let utcDate = new Date(date);
    return new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * this.NUMBER_OF_MILLISECONDS_IN_MINUTE);
  }

  public getDateFormat(): string {
    return 'YYYY-MM-DD';
  }
}
