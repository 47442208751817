import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CaseCasesComponent} from './components/case-cases/case-cases.component';
import {CaseInfoComponent} from './components/case-info/case-info.component';
import {CaseNewComponent} from './components/case-new/case-new.component';
import {CaseTimelineComponent} from './components/case-timeline/case-timeline.component';
import {CasePageComponent} from './pages/case-page/case-page.component';
import {CaseAboutComponent} from './components/case-about/case-about.component';
import {CaseLocalTimeComponent} from './components/case-local-time/case-local-time.component';
import {CaseScreeningToolComponent} from './components/case-screening-tool/case-screening-tool.component';
import {CaseSmsComponent} from './components/case-sms/case-sms.component';
import {UtilsModule} from 'src/app/utils/utils.module';
import {SharedModule} from '@secca/shared/shared.module';
import {CaseDocumentTableMoveDocumentComponent} from './components/case-documents/case-document-table/case-document-table-move-document/case-document-table-move-document.component';
import {CaseDocumentTableDeleteDocumentComponent} from './components/case-documents/case-document-table/case-document-table-delete-document/case-document-table-delete-document.component';
import {CaseNewDialogComponent} from './components/case-new/case-new-dialog/case-new-dialog.component';
import {CaseConsentComponent} from './components/case-consent/case-consent.component';
import {DropDownConsentComponent} from './components/case-consent/drop-down-consent/drop-down-consent.component';
import {CaseHandlerModalComponent} from './components/case-handlers/case-handler-modal/case-handler-modal.component';
import {CaseHandlerStatusBoxComponent} from './components/case-handlers/case-handler-status-box/case-handler-status-box.component';
import {CaseHandlersComponent} from './components/case-handlers/case-handlers.component';
import {HandlerPictureComponent} from './components/case-handlers/handler-picture/handler-picture.component';
import {CaseTermsComponent} from './components/case-terms/case-terms.component';
import {MessageFormAttachmentsComponent} from './components/case-output-management/components/message-dialog/message-subject/message-form-attachments/message-form-attachments.component';
import {MessageFormCcComponent} from './components/case-output-management/components/message-dialog/message-subject/message-form-cc/message-form-cc.component';
import {MessageSubjectComponent} from './components/case-output-management/components/message-dialog/message-subject/message-subject.component';
import {CaseNotesComponent} from './components/case-notes/case-notes.component';
import {MessageDialogComponent} from './components/case-output-management/components/message-dialog/message-dialog.component';
import {MessageFormComponent} from './components/case-output-management/components/message-dialog/message-form/message-form.component';
import {PinnedButtonsComponent} from './components/case-output-management/components/pinned-buttons/pinned-buttons.component';
import {OutputManagementDialogComponent} from './components/case-output-management/output-management-dialog/output-management-dialog.component';
import {IConfig, NgxMaskModule} from 'ngx-mask';
import {NgxCurrencyModule} from 'ngx-currency';
import {CasePlansModule} from './components/case-plans/case-plans.module';
import {RouterModule, Routes} from '@angular/router';
import {LoggedCallComponent} from './components/case-notes/logged-call/logged-call.component';
import {CaseCoverageInfoComponent} from './components/case-plans/case-coverage-info/case-coverage-info.component';
import {TreatmentIconComponent} from './components/case-stakeholders/case-stakeholders-table/case-stakeholders-info/treatment-icon/treatment-icon.component';
import {BlacklistedIconComponent} from './components/case-stakeholders/case-stakeholders-table/case-stakeholders-info/blacklisted-icon/blacklisted-icon.component';
import {SupplierIconComponent} from './components/case-stakeholders/case-stakeholders-table/case-stakeholders-info/supplier-icon/supplier-icon.component';
import {CaseEconomyModule} from './components/case-economy/case-economy.module';
import {CasePrimaryContactComponent} from './components/case-primary-contact/case-primary-contact.component';
import {CaseMedicalModule} from './components/case-medical/case-medical.module';
import {CaseBasicsModule} from './components/case-basics/case-basics.module';
import {CaseSummaryModule} from './components/case-summery/case-summary.modules';
import {CaseDocumentModule} from './components/case-documents/case-documents.modules';
import {CaseStakeholdersModule} from './components/case-stakeholders/case-stakeholders.module';
import {CaseTaskModule} from './components/case-task/case-task.module';
import {CaseDialogViewerService} from './case-dialog-viewer.service';
import { CaseTypeModalComponent } from './components/case-info/case-type-modal/case-type-modal.component';
import { CaseSensitiveComponent } from './components/case-sensitive/case-sensitive.component';
import { MatDialogModule } from '@angular/material/dialog';
import { BalanceSheetService } from '@secca/core/services/balance-sheet.service';
import { CaseCountryInfoDialogComponent } from './components/case-country-info/case-country-info-dialog/case-country-info-dialog.component';
import { CountryInfoComponent } from './components/case-country-info/case-country-info-dialog/country-info/country-info.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

const routes: Routes = [
  {
    path: '',
    component: CasePageComponent
  }
];

@NgModule({
    declarations: [
        CasePageComponent,
        CaseInfoComponent,
        CaseTypeModalComponent,
        CaseTimelineComponent,
        CaseCasesComponent,
        CaseNewComponent,
        CasePrimaryContactComponent,
        CaseAboutComponent,
        CaseLocalTimeComponent,
        CaseSmsComponent,
        CaseScreeningToolComponent,
        CaseNewDialogComponent,
        CaseConsentComponent,
        DropDownConsentComponent,
        CaseHandlersComponent,
        CaseHandlerStatusBoxComponent,
        CaseHandlerModalComponent,
        HandlerPictureComponent,
        CaseTermsComponent,
        MessageSubjectComponent,
        MessageFormCcComponent,
        MessageFormAttachmentsComponent,
        CaseNotesComponent,
        MessageDialogComponent,
        MessageFormComponent,
        PinnedButtonsComponent,
        OutputManagementDialogComponent,
        LoggedCallComponent,
        CaseCoverageInfoComponent,
        TreatmentIconComponent,
        BlacklistedIconComponent,
        SupplierIconComponent,
        CaseSensitiveComponent,
        CaseCountryInfoDialogComponent,
        CountryInfoComponent
    ],
    providers: [
        CaseDialogViewerService,
        BalanceSheetService
    ],
    imports: [
        CommonModule,
        UtilsModule,
        SharedModule,
        CaseTaskModule,
        CasePlansModule,
        CaseBasicsModule,
        CaseEconomyModule,
        CaseMedicalModule,
        CaseSummaryModule,
        CaseDocumentModule,
        CaseStakeholdersModule,
        NgxCurrencyModule,
        RouterModule.forChild(routes),
        NgxMaskModule.forChild(),
        MatDialogModule
    ],
    exports: [
        CasePageComponent,
        CaseSummaryModule
    ]
})
export class CaseModule { }
