import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TaskModalCommon } from '@secca/shared/components/task-modal-common/task-modal-common.component';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { Coverage } from '@secca/shared/models/coverage';
import { Case } from '@secca/shared/models/case';
import { Task } from '@secca/shared/models/task';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';

@Component({
  selector: 'app-task-web-left-panel',
  templateUrl: './task-web-left-panel.component.html',
  styleUrls: ['./task-web-left-panel.component.scss']
})
@AutoUnsubscribe
export class TaskWebLeftPanelComponent extends TaskModalCommon implements OnInit {
  @Input() taskCase: string;
  @Input() taskId: string;
  @Input() taskStatusDisabled: boolean = false;
  @Input() disabled: boolean = false;

  coverages: Coverage[];
  reserve: number;
  currency: string;
  $subscr2: Subscription;
  $teamsSubscr: Subscription;
  $loggedUserSubscr: Subscription;
  priorityDropdown: DropdownDictionary[] = [];

  _constructor() {}

  ngOnInit() {
    this.initialiseTaskInformation(this.taskId || this.taskViewModel?.id);
    this.initialiseCaseInformation(this.case, this.taskCase || this.taskViewModel?.caseId?.toString());
    this.initialiseTeamHandler();
    this.initialiseCaseNumberValidated(this.case?.id || this.taskCase || this.taskViewModel?.caseId?.toString());
    this.initPriorityDropdown();
  }

  get caseId() {
    return this.case?.id || this.taskCase || this.taskViewModel?.caseId?.toString();
  }

  get isDisabled(): boolean {
    return this.disabled || this.caseStateService.isCaseDisabled();
  }

  get validated(): boolean {
    return (
      this.task.title != null &&
      this.task.title !== '' &&
      this.task.dueDate != null &&
      this.task.status != null &&
      (this.acHandler?.adtCode || this.acHandler?.team?.adtCode) &&
      this.disableSaveButtonWhenNoChanges === false
    );
  }

  private initPriorityDropdown() {
    this.priorityDropdown.push(new DropdownDictionary(1, this.translate.instant('task-priority-type-1')));
    this.priorityDropdown.push(new DropdownDictionary(2, this.translate.instant('task-priority-type-2')));
    this.priorityDropdown.push(new DropdownDictionary(3, this.translate.instant('task-priority-type-3')));
    this.priorityDropdown.push(new DropdownDictionary(4, this.translate.instant('task-priority-type-4')));
    this.priorityDropdown.push(new DropdownDictionary(5, this.translate.instant('task-priority-type-5')));
  }

  private initialiseCaseInformation(seccaCase: Case, caseId: string) {
    if ( caseId ) {
      this.getCaseInformations(caseId);
    }
    else if ( seccaCase ) {
      this.getHeaderInformations(seccaCase);
      this.getCoverages(seccaCase.id);
    }
  }

  private initialiseTaskInformation(taskId: string) {
    if ( taskId ) {
      this.getTaskInformation(+taskId).subscribe();
    }
    else {
      this.task = new Task();
    }
  }

  private getCoverages(caseId: string) {
    this.planService.getCoveragesForCase(caseId).subscribe(
      coverages => {
        this.coverages = coverages;
        this.calculateCaseReserve();
      },
      error => console.log(error)
    );
  }

  private calculateCaseReserve() {
    if (this.coverages != null) {
      this.reserve = this.coverages.map(a => a.reserve).reduce((a, b) => a + b, 0);
    }
  }

  private initialiseCaseNumberValidated(caseId: string) {
    if ( caseId ) {
      this.$subscr2 = this.caseValidationService.isCaseNumberAssigned(caseId).subscribe(validated => {
        this.isCaseNumberValidated = validated;
      });
    }
  }

  private initialiseTeamHandler() {
    this.$teamsSubscr = this.dictionaryService.getAllActiveTeamsList().subscribe(result => {
      if (result != null) {
        this.teams = result.map(a => new DropdownDictionary(a.adtCode, a.teamName));
        if (this.task != null && this.task.adtCode != null) {
          this.initializeTeamAcHandler();
        }
      }
    });
  }

  private getCaseInformations(caseId: string) {
    this.caseService.getCase(caseId).subscribe(
      seccaCase => {
        this.case = seccaCase;
        this.getHeaderInformations(seccaCase);
        this.getCoverages(caseId);
      },
      error => console.log(error)
    );
  }
}
