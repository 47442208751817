import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { PlanService } from './../../../../../core/services/plan.service';
import { Coverage } from './../../../../../shared/models/coverage';
import { Coverage as CaseCoverage } from '@secca/shared/models/onepoint-coverage';
import { CasePlansService } from './../case-plans.service';
import {Component, Input, OnInit} from '@angular/core';
import { AutoUnsubscribe } from 'src/app/shared/decorators/auto-unsubscribe';

@Component({
  selector: 'app-case-coverage',
  templateUrl: './case-coverage.component.html',
  styleUrls: ['./case-coverage.component.scss']
})
@AutoUnsubscribe
export class CaseCoverageComponent implements OnInit {
  @Input() caseId: string;
  coverages: Coverage[];
  coveragesSummarized: Coverage[];
  $coverageSubscr: Subscription;
  $routeSubscr: Subscription;
  billingCurrency: string;
  validCaseCoverages: CaseCoverage[];

  constructor(
    private casePlansService: CasePlansService,
    private planService: PlanService,
    private route: ActivatedRoute
  ) {
    this.$coverageSubscr = this.casePlansService.getCoverages().subscribe(coverages => {
      this.coverages = coverages;
      this.calculateCoveragesSummarized();
    });
  }

  ngOnInit() {
      if (this.coverages == null) {
        this.planService.getCoveragesForCase(this.caseId).subscribe(
          result => this.casePlansService.sendCoverages(result),
          error => console.log(error)
        );
      }
      if (this.validCaseCoverages == null) {
        this.planService.getValidCaseCoverages(Number(this.caseId)).subscribe(caseCoverages => {
            this.validCaseCoverages = caseCoverages;
          },
          error => console.log(error)
        );
      }
      if (this.caseId) {
        this.planService.getBillingCurrencyForCase(+this.caseId).subscribe(result => {
          this.billingCurrency = result.billingCurrency;
        });
      } else {
        this.billingCurrency = '';
      }
  }

  calculateCoveragesSummarized() {
    if (this.coverages == null) {
      return;
    }
    let coverageTypesInCoverage: string[];
    coverageTypesInCoverage = this.coverages.map(a => a.typeId).filter((value, index, self) => self.indexOf(value) === index);
    this.coveragesSummarized = [];
    coverageTypesInCoverage.forEach(typeId => {
      let filteredCoverages = this.coverages.filter(a => a.typeId === typeId);
      if (filteredCoverages.length > 0) {
        const reserve = filteredCoverages
          .map(a => a.reserve)
          .reduce((a, b) => a + b, 0);
        const planned = filteredCoverages
          .map(a => a.planned)
          .reduce((a, b) => a + b, 0);
        const typeDescription = filteredCoverages[filteredCoverages.length - 1].typeDescription;
        const limit = filteredCoverages[filteredCoverages.length - 1].limit;
        const limitCurrency = filteredCoverages[filteredCoverages.length - 1].limitCurrency;
        const limitId = filteredCoverages[filteredCoverages.length - 1].limitId;
        const nonAmountLimitsExist = filteredCoverages[filteredCoverages.length - 1].nonAmountLimitsExist;
        this.coveragesSummarized.push(new Coverage({ nonAmountLimitsExist, limit, limitCurrency, limitId, reserve, planned, typeDescription, typeId }));
      }
    });
  }

  isCoverageValid(coverage: Coverage): boolean {
    if (this.validCaseCoverages) {
      return this.validCaseCoverages.filter(validCaseCoverage => +validCaseCoverage.coverageId === +coverage.typeId).length > 0;
    }
    return true;
  }
}
