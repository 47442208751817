
import { Component, Input, OnInit } from '@angular/core';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { CoordinationCaseDetail } from '@secca/shared/models/coordination-case-details';
import { MedicalAssessment } from '@secca/shared/models/medicalAssessment';
import { MedicalService } from '@secca/core/services/medical.service';
import { SubRepatriationFormMedical } from '../../models/subRepatriationFormMedical';
import { SubRepatriationDetails } from '../../models/subRepatriationDetails';
import { IccRepatriationInfo } from '@secca/shared/models/service-order/icc-repatriation-info';
import { CoordinationTransportServiceOrderExtension } from '@secca/shared/models/service-order/coordination-transport-service-order-extension';

@Component({
  selector: 'app-end-user-repatriation-form',
  templateUrl: './sub-end-user-repatriation-form.component.html',
  styleUrls: ['./sub-end-user-repatriation-form.component.scss'],
})
export class SubEndUserRepatriationFormComponent implements OnInit {
  @Input() caseId: number;
  @Input() coordinationTransport: CoordinationTransportServiceOrderExtension;
  @Input() addRepatriationDisabled = false;
  iccRepatriationInfo: IccRepatriationInfo;
  coordinationMedicalAssessment: MedicalAssessment;
  caseStakeholders: CaseStakeholder[];
  repatriationDetails: SubRepatriationDetails = new SubRepatriationDetails()
  constructor(private medicalService: MedicalService) {}

  ngOnInit() {
    this.loadMedicalAssessment(this.caseId);
    this.iccRepatriationInfo = this.coordinationTransport.iccRepatriationInfos.byCase(""+this.caseId)

  }

  loadMedicalAssessment(caseId: number): void {
    this.medicalService.getNewestMedicalAssessment(caseId).subscribe(assessment => {
      this.coordinationMedicalAssessment = assessment;
    });
  }

}
