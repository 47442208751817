import { AssigneeEnum, StatusTypes } from '@secca/shared/models/enums';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ServiceTypeBase } from '@secca/case/components/case-plans/case-plans/add-service-order/models/serviceTypeBase';
import { ServiceOrder } from '@secca/shared/models/service-order/service-order';
import { MedicalPreAssessmentServiceOrderExtension } from '@secca/shared/models/service-order/medical-pre-assessment-service-order-extension';

@Component({
  selector: 'app-medical-pre-assessment-left-side',
  templateUrl: './medical-pre-assessment-left-side.component.html',
  styleUrls: ['./medical-pre-assessment-left-side.component.scss']
})
export class MedicalPreAssessmentLeftSideComponent extends ServiceTypeBase implements OnInit, OnChanges {
  disableEditingDescription = false;  
  _medicalPreAssessment: MedicalPreAssessmentServiceOrderExtension;

  @Input()
  set medicalPreAssessment(medicalAssessment: MedicalPreAssessmentServiceOrderExtension) {
    this._medicalPreAssessment = medicalAssessment;
    this.isValid();
  }

  get medicalPreAssessment() {
    return this._medicalPreAssessment;
  }

  @Input() caseId: number;
  @Input() serviceOrder: ServiceOrder;
  @Input() assignee: AssigneeEnum;

  isDescriptionValid: boolean;
  isCancelAllowed = true;

  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit() {
  }

  isValid(): boolean {
    this.serviceOrderTypeState.isLeftSideValid = true;
    return true;
  }

  changeStatus(val: StatusTypes, event: any = null) {
    if (val === this.serviceOrder.status && event != null) {
      return false;
    }
    if (val !== this.serviceOrder.status) {
      this.serviceOrder.status = val;
    }
  }

  expectedDisabled() {
    return true;
  }

  cancelledDisabled() {
    return !(this.isCancelAllowed && (this.serviceOrder.status === StatusTypes.COMMITTED));
  }

  committedDisabled() {
    return this.serviceOrder.status !== StatusTypes.CANCELLED;
  }

  dataChanged() {
    this.isValid();
  }

  get StatusTypes() {
    return StatusTypes;
  }

}
