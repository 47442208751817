export class DocumentTemplateFilter {

  documentType: string;
  templateTypes: string [];
  serviceType: string;

  public constructor(documentType: string, templateTypes: string[], serviceType: string) {
    this.documentType = documentType;
    this.templateTypes = templateTypes;
    this.serviceType = serviceType;
  }

}
