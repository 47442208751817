import { Injectable } from "@angular/core";
import { AutoUnsubscribe } from "@secca/shared/decorators/auto-unsubscribe";
import { ShortcutEnum } from "@secca/shared/models/enums";
import { Subscription } from "rxjs";
import { DialogStateAction, DialogStateService, MinimizableNotifData } from "../state-services/dialog-state.service";
import { ShortcutService } from "./shortcut.service";

interface DialogSequenceOrderData {
  dialogId: string;
  minimized: boolean;
}

@Injectable({
    providedIn: 'root'
})
@AutoUnsubscribe
export class DialogSequenceOrderService {
    private originalOrderList: DialogSequenceOrderData[] = [];
    private currentOpenOrderList: string[] = [];

    minimizeSubscr: Subscription;
    sendToFrontSubscr: Subscription;
    shortcutToggleSubscription: Subscription;
    shortcutReverseToggleSubscription: Subscription;

    constructor(private dialogStateService: DialogStateService,
                private shortcutService: ShortcutService) {
      this.sendToFrontSubscr = this.dialogStateService.getSendToFront().subscribe(dialogId => {
        this.handleSentToFront(dialogId);
      });

      this.minimizeSubscr = this.dialogStateService.getMinimizable().subscribe((data: MinimizableNotifData) => {
        if ( data.action === DialogStateAction.MINIMIZE ) {
          this.handleMinimized(data.dialogId);
        }
      });

      this.shortcutToggleSubscription = this.shortcutService.addShortcut({ keys: ShortcutEnum.ModalsToggleOpenDialogs }).subscribe(keyEvnt => {
        this.handleToggle();
      });

      this.shortcutReverseToggleSubscription = this.shortcutService.addShortcut({ keys: ShortcutEnum.ModalsToggleOpenDialogsReverse }).subscribe(keyEvnt => {
        this.handleReverseToggle();
      });  
    }

    public getFirst(): string {
      return this.currentOpenOrderList?.length > 0 ? this.currentOpenOrderList[0] : null;
    }

    public remove(dialogId: string): string {
      this.removeFromOriginalOrder(dialogId);
      this.updateCurrentOrder();
      
      return this.getFirst();
    }

    private handleSentToFront(dialogId: string): void {
      this.addToOriginalOrder(dialogId);
      this.updateCurrentOrder(dialogId);
    }

    private handleMinimized(dialogId: string): void {
      const item = this.originalOrderList.find(data => data.dialogId === dialogId);
      if ( item ) {
        item.minimized = true;
      }
      this.updateCurrentOrder();
      this.sendFirstToFront();
    }

    private handleReverseToggle(): void {      
      if ( this.currentOpenOrderList.length > 0 ) {
        const currentOrder = this.getOpenOrderList();
        const index = currentOrder.indexOf(this.getFirst());
        this.dialogStateService.sendToFront(index > 0 ? currentOrder[index-1] : currentOrder[currentOrder.length-1]);
      }
    }

    private handleToggle(): void {
      if ( this.currentOpenOrderList.length > 0 ) {
        const currentOrder = this.getOpenOrderList();
        const index = currentOrder.indexOf(this.getFirst());
        this.dialogStateService.sendToFront(index < currentOrder.length-1 ? currentOrder[index+1] : currentOrder[0]);
      }
    } 

    private sendFirstToFront(): void {
      const firstDialogId = this.getFirst();
      if ( firstDialogId ) {
        this.dialogStateService.sendToFront(firstDialogId);
      }
    }

    private addToOriginalOrder(dialogId: string): void {
      const item = this.originalOrderList.find(data => data.dialogId === dialogId);
      if ( !item ) {
        this.originalOrderList = [{dialogId, minimized: false}, ...this.originalOrderList];
      }
      else {
        item.minimized = false;
      }
    }

    private removeFromOriginalOrder(dialogId: string): void {
      this.originalOrderList = this.originalOrderList?.filter(data => data.dialogId !== dialogId);
    }

    private updateCurrentOrder(dialogId?: string): void {
      this.currentOpenOrderList = this.getOpenOrderList(dialogId);
    }

    private getOpenOrderList(dialogId?: string): string[] {
      return (dialogId ? [dialogId] : []).concat(this.originalOrderList?.filter(data => data.dialogId !== dialogId && !data.minimized).map(data => data.dialogId));
    }
}
