import {IncomingCasesTaskViewModel} from '@secca/board/components/board-task/models/incoming-cases-task-view-model';

export class IncomingCasesTaskFilterResponse {
  totalNumber: number;
  taskViewModels: IncomingCasesTaskViewModel[];

  public constructor(init?: Partial<IncomingCasesTaskFilterResponse>) {
    Object.assign(this, init);
  }
}
