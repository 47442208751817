import { DineroObject } from 'dinero.js';
import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
import { FeeTypeEnum } from './enums';

export class SalesOrderSosService {
  sosServiceId: string;
  serviceName: string;
  feeType: FeeTypeEnum;
  agreementPrice: DineroObject;
  category: string;
  share: number;
  public constructor(init?: Partial<SalesOrderSosService>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
export class SalesOrderSosServiceAdapter implements Adapter<SalesOrderSosService> {
  adapt(item: any): SalesOrderSosService {
    const salesOrderSosService = new SalesOrderSosService({
      sosServiceId: item.sosServiceId,
      serviceName: item.serviceName,
      feeType: FeeTypeEnum[item.feeType],
      agreementPrice: item.agreementPrice,
      category: item.category,
      share: item.share,
    });
    return salesOrderSosService;
  }
}
