import { ModalErrorDialogGlobalComponent } from '@secca/shared/components/modal-error-dialog-global/modal-error-dialog-global.component';
import { ModalDialogComponent } from './shared/components/modal-dialog/modal-dialog.component';
import { ModalErrorDialogComponent } from './shared/components/modal-error-dialog/modal-error-dialog.component';
import { GoogleLocationService } from './core/services/google-location.service';
import { ServiceOrderService } from './core/services/service-order.service';
import { NoteService } from './core/services/note.service';
import { MenuService } from './core/services/menu.service';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DataService } from './core/services/data.service';
import { GlobalSearchTopFilterComponent } from '@secca/global-search/components/global-search-top-filter/global-search-top-filter.component';
import { GlobalSearchResultsComponent } from '@secca/global-search/components/global-search-results/global-search-results.component';
import { GlobalSearchPageComponent } from '@secca/global-search/pages/global-search-page/global-search-page.component';
import { DictionaryService } from './core/services/dictionary.service';
import { LayoutComponent } from './shared/layout/layout/layout.component';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './shared/app-routing.module';
import { AppComponent } from './app.component';
import { TopMenuComponent } from './shared/components/top-menu/top-menu.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TopTabDirective, TopTabsComponent } from './shared/components/top-tabs/top-tabs.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BoardShareService } from './core/services/board-share.service';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { TimeSpanPipe } from './shared/pipes/TimeSpan.pipe';
import { GlobalSearchCaseRightFilterComponent } from '@secca/global-search/components/global-search-results/global-search-case-right-filter/global-search-case-right-filter.component';
import { GlobalSearchDocumentRightFilterComponent } from '@secca/global-search/components/global-search-results/global-search-document-right-filter/global-search-document-right-filter.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ToastComponent } from './shared/components/toast/toast.component';
import { DecimalPipe, HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { FooterComponent } from './shared/components/footer/footer.component';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { SettingsService } from './core/services/settings.service';
import { SettingsLoaderService } from './core/services/settings.loader.service';
import { initializeApp } from './shared/app-init';
import { OverlayModule } from '@angular/cdk/overlay';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxCurrencyModule } from 'ngx-currency';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { TaskBoardDatesRangeService } from '@secca/case/components/case-task/services/task-board-dates-range.service';
import localeEnglishInDenmark from '@angular/common/locales/en-DK';
import localeEnglishInDenmarkExtra from '@angular/common/locales/extra/en-DK';
import { LocaleService } from './core/services/locale.service';
import { LocationService } from './core/services/location.service';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { NumberFormatPipe } from './shared/pipes/number-format.pipe';
import { MomentHelperService } from './core/services/moment-helper.service';
import * as moment from 'moment';
import { GlobalErrorHandler } from './core/services/error-handler.service';
import { GoogleMapsModule } from '@angular/google-maps';
import { EnvironmentRibbonComponent } from '@secca/shared/components/environment-ribbon/environment-ribbon.component';
import { SignOutComponent } from './shared/components/sign-out/sign-out.component';
import { RouterModule } from '@angular/router';
import { ErrorHttpInterceptorProvider } from '@secca/shared/interceptor/error.http.interceptor';
import { OpenCaseLimitService } from '@secca/core/services/open-case-limit.service';
import { ReloadFooterComponent } from './shared/components/reload-footer/reload-footer.component';
import { DialogsActiveGuardService } from './core/services/dialogs-active-guard.service';
import { CaseMinimizedComponent } from '@secca/case/components/case-minimized/case-minimized.component';
import { DialogViewerService } from './core/services/dialog-viewer.service';
import { DIALOG_VIEWER_TOKEN } from './core/services/token';
import { UtilsModule } from './utils/utils.module';
import { BoardModule } from '@secca/board/board.module';
import { SharedModule } from './shared/shared.module';
import { DocumentService } from './core/services/document.service';
import { DialogBoundryService } from './core/services/dialog-boundry.service';
import { CaseTypesService } from './core/services/case-type.service';
import { PlanService } from './core/services/plan.service';
import { CaseService } from './core/services/case.service';
import { CaseMessageService } from './core/services/case-message.service';
import { CaseCommunicationService } from './core/services/case-communication.service';
import { InsuranceService } from './core/services/insurance.service';

export let AppInjector: Injector;

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

moment.fn.toJSON = function () {
  return this.format('YYYY-MM-DDHH:mm:ss');
};

registerLocaleData(localeEnglishInDenmark, 'en-DK', localeEnglishInDenmarkExtra);

export function appInitializerFactory(translateService: TranslateService): () => Promise<any> {
  return () => {
    translateService.setDefaultLang('en');
    return translateService.use('en').toPromise();
  };
}

@NgModule({
    declarations: [
        AppComponent,
        LayoutComponent,
        TopMenuComponent,
        TopTabsComponent,
        TimeSpanPipe,
        GlobalSearchPageComponent,
        GlobalSearchResultsComponent,
        GlobalSearchDocumentRightFilterComponent,
        GlobalSearchCaseRightFilterComponent,
        GlobalSearchTopFilterComponent,
        ToastComponent,
        FooterComponent,
        ModalDialogComponent,
        ModalErrorDialogComponent,
        ModalErrorDialogGlobalComponent,
        EnvironmentRibbonComponent,
        SignOutComponent,
        TopTabDirective,
        ReloadFooterComponent,
        CaseMinimizedComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgbModule,
        NgSelectModule,
        FormsModule,
        HttpClientModule,
        NoopAnimationsModule,
        ReactiveFormsModule,
        ScrollingModule,
        MatSnackBarModule,
        BrowserAnimationsModule,
        KeycloakAngularModule,
        OverlayModule,
        GoogleMapsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        NgxCurrencyModule,
        NgxWebstorageModule.forRoot(),
        NgxMaskModule.forRoot(options),
        UtilsModule,
        SharedModule,
        BoardModule
    ],
    providers: [
        ErrorHttpInterceptorProvider,
        DictionaryService,
        DataService,
        BoardShareService,
        LocaleService,
        LocationService,
        MomentHelperService,
        MenuService,
        NoteService,
        ServiceOrderService,
        TaskBoardDatesRangeService,
        GoogleLocationService,
        DialogBoundryService,
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        //    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
        { provide: APP_INITIALIZER, useFactory: initializeApp, multi: true, deps: [KeycloakService, SettingsService, SettingsLoaderService] },
        {
            provide: LOCALE_ID,
            useFactory: (localeService: LocaleService) => {
                return localeService.userLocale;
            },
            deps: [LocaleService],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService, Injector],
            multi: true,
        },
        // Solve cyclic dependencies on DialogViewerService
        { provide: DIALOG_VIEWER_TOKEN, useClass: DialogViewerService },
        DecimalPipe,
        OpenCaseLimitService,
        DialogsActiveGuardService,
        NumberFormatPipe,
        DocumentService,
        CaseTypesService,
        PlanService,
        CaseService,
        CaseMessageService,
        CaseCommunicationService,
        InsuranceService
    ],
    bootstrap: [AppComponent],
    exports: [RouterModule]
})
export class AppModule {
  constructor(private injector: Injector) {
    AppInjector = this.injector;
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  const suf = '.json?' + new Date().getTime();
  return new TranslateHttpLoader(http, '/assets/i18n/', encodeURI(suf));
}
