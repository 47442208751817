<div class="d-flex">
  <div [style.width.%]="calculateStakeholderDropdownWidth()">
    <div class="common-drop-down-arrow common-drop-down">
      <ng-select class="custom" [class.required-red-background]="!selectedItemId"
                 [items]="serviceOrderExist(iccStakeholder.serviceOrderId) ? serviceOrderDropDown : fullServiceOrderDropdown"
                 bindValue="id" bindLabel="serviceOrderType"
                 [(ngModel)]="iccStakeholder.serviceOrderId"
                 [clearable]="false"
                 [disabled]="disabled" >
        <ng-template ng-label-tmp let-item="item">
          <div *ngIf="!!item.serviceOrderType">
            <img [src]="item.iconFileName" class="drop-down-icon-icc" />
              {{ 'service-order-' + item.serviceOrderType.replaceAll('_', '-').toLowerCase() | translate }} - {{
              item.serviceOrderId }}
          </div>
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
          <div class="service-order-stakeholder-picker">
            <img [src]="item.iconFileName" class="drop-down-icon" *ngIf="item?.iconFileName"/>
            <span class="p-2 service-order-stakeholder-picker-label">{{ 'service-order-' + item.serviceOrderType.replaceAll('_', '-').toLowerCase() | translate }} - {{
              item.serviceOrderId }}</span>
          </div>
        </ng-template>
      </ng-select>
    </div>
  </div>
</div>
