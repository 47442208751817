import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CaseStakeholder } from "@secca/shared/models/caseStakeholder";
import { SharedModule } from "@secca/shared/shared.module";
import { UtilsModule } from "src/app/utils/utils.module";
import { CaseStakeholdersFilterComponent } from "./case-stakeholders-filter/case-stakeholders-filter.component";
import { CaseStakeholdersInfoComponent } from "./case-stakeholders-table/case-stakeholders-info/case-stakeholders-info.component";
import { CaseStakeholdersTableComponent } from "./case-stakeholders-table/case-stakeholders-table.component";
import { CaseStakeholdersComponent } from "./case-stakeholders.component";
import { SupplierSearchDialogComponent } from './case-stakeholders-table/case-stakeholders-info/supplier-search-dialog/supplier-search-dialog.component';
import { CasePlansModule } from '@secca/case/components/case-plans/case-plans.module';
import { StakeholderSupplierSearchDialogComponent } from './case-stakeholders-table/case-stakeholders-info/stakeholder-supplier-search-dialog/stakeholder-supplier-search-dialog.component';

@NgModule({
  declarations: [
    CaseStakeholdersComponent,
    CaseStakeholdersFilterComponent,
    CaseStakeholdersTableComponent,
    CaseStakeholdersInfoComponent,
    SupplierSearchDialogComponent,
    StakeholderSupplierSearchDialogComponent,

   ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UtilsModule,
    SharedModule,
    CasePlansModule
  ],
  exports: [
    CaseStakeholdersComponent
  ]
})
export class CaseStakeholdersModule { }
