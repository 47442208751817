import { ServiceTypeEnum, StakeholderTypeEnum } from '@secca/shared/models/enums';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SubTravellers } from '../../models/subTravellers';
import { SubTravelInfo } from '../../models/subTravelInfo';
import { SubStakeholders } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholders';
import { SubStakeholdersConfig } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholdersConfig';
import { SubTravellersResourceConfig } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subTravellersResourceConfig';
import * as moment from 'moment';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { ServiceOrderType } from '@secca/shared/models/service-order-type';
import * as lodash from 'lodash-es';
import { ICCFlightLegRelevantForCase } from '../../models/icc-flightLegsRelevantForCase';


@Component({
  selector: 'app-sub-travellers',
  templateUrl: './sub-travellers.component.html',
  styleUrls: ['./sub-travellers.component.scss'],
})
export class SubTravellersComponent {
  @Input() caseId: string;
  @Input() iccCaseId: string;
  @Input() travellers: SubTravellers;
  @Input() stakeholders: CaseStakeholder[];
  @Input() subStakeholders: SubStakeholders;
  @Input() stakeholderType: StakeholderTypeEnum;
  @Input() subStakeholdersConfig: SubStakeholdersConfig;
  @Input() disableStakeholders: boolean;
  @Input() hideAddFlightLeg: boolean;
  @Input() hideCabinClass: boolean;
  @Input() hideFlightInRefs: boolean;
  @Input() hideRemarks: boolean;
  @Input() hideTicketDetails: boolean;
  @Input() departureDateRequired: boolean;
  @Input() arrivalDateWhite: boolean;
  @Input() departureFromRequired: boolean;
  @Input() arrivalDateRequired: boolean;
  @Input() arrivalAtRequired: boolean;
  @Input() cabinClassRequired: boolean;
  @Input() flightNoRequired: boolean;
  @Input() agencyReferenceRequired: boolean;
  @Input() hideAirlineReference: boolean;
  @Input() hideTicketNo: boolean;
  @Input() readOnlyMode: boolean;
  @Input() remarksReadOnlyMode: boolean;
  @Input() resourceConfig: SubTravellersResourceConfig;
  @Input() preselectUser: boolean;
  @Input() serviceOrderType: ServiceOrderType;
  @Input() showRelevantForCase: boolean = false;
  @Input() iccFlightLegRelevantForCaseList: ICCFlightLegRelevantForCase[] ;
  @Input() relevantForCaseReadOnly: boolean;
  @Input() hideTitle = true;

  @Output() endUserHasDateOfBirthEmit: EventEmitter<boolean> = new EventEmitter();
  @Input() set minDate(min: moment.Moment){
    if (min) {
      this.minMoment = min.clone().local(true);
    }
  }

  minMoment: moment.Moment;

  constructor() {
    this.resourceConfig = new SubTravellersResourceConfig();
  }

  get hideDeleteOnFlightLeg() {
    return this.travellers.flightLegs.length === 1;
  }

  get ServiceTypeEnum() {
    return ServiceTypeEnum;
  }

  addFlightLeg() {
    if (this.serviceOrderType && (this.serviceOrderType.serviceTypeEnumValue === ServiceTypeEnum.REGULAR_FLIGHT_NON_MEDICAL || ServiceTypeEnum.REGULAR_FLIGHT_MEDICAL || ServiceTypeEnum.COORDINATION_TRANSPORT)) {
      switch (this.travellers.flightLegs.length) {
        case 1:
          this.travellers.flightLegs.push(new SubTravelInfo({
            cabinClass: lodash.cloneDeep(this.travellers.flightLegs[0].cabinClass),
            agencyReference: lodash.cloneDeep(this.travellers.flightLegs[0].agencyReference),
            departureFrom: lodash.cloneDeep(this.travellers.flightLegs[0].arrivalAt),
          }));
          break;
        case 2:
          this.travellers.flightLegs.push(new SubTravelInfo({
            cabinClass: lodash.cloneDeep(this.travellers.flightLegs[1].cabinClass),
            agencyReference: lodash.cloneDeep(this.travellers.flightLegs[1].agencyReference),
            departureFrom: lodash.cloneDeep(this.travellers.flightLegs[1].arrivalAt),
          }));
          break;
        case 3:
          this.travellers.flightLegs.push(new SubTravelInfo({
            cabinClass: lodash.cloneDeep(this.travellers.flightLegs[2].cabinClass),
            agencyReference: lodash.cloneDeep(this.travellers.flightLegs[2].agencyReference),
            departureFrom: lodash.cloneDeep(this.travellers.flightLegs[2].arrivalAt),
          }));
          break;
        case 4:
          this.travellers.flightLegs.push(new SubTravelInfo({
            cabinClass: lodash.cloneDeep(this.travellers.flightLegs[3].cabinClass),
            agencyReference: lodash.cloneDeep(this.travellers.flightLegs[3].agencyReference),
            departureFrom: lodash.cloneDeep(this.travellers.flightLegs[3].arrivalAt),
          }));
          break;
        case 5:
          this.travellers.flightLegs.push(new SubTravelInfo({
            cabinClass: lodash.cloneDeep(this.travellers.flightLegs[4].cabinClass),
            agencyReference: lodash.cloneDeep(this.travellers.flightLegs[4].agencyReference),
            departureFrom: lodash.cloneDeep(this.travellers.flightLegs[4].arrivalAt),
          }));
          break;
        case 6:
          this.travellers.flightLegs.push(new SubTravelInfo({
            cabinClass: lodash.cloneDeep(this.travellers.flightLegs[5].cabinClass),
            agencyReference: lodash.cloneDeep(this.travellers.flightLegs[5].agencyReference),
            departureFrom: lodash.cloneDeep(this.travellers.flightLegs[5].arrivalAt),
          }));
          break;
        default:
          this.travellers.flightLegs.push(new SubTravelInfo());
      }
    }
    else {
      this.travellers.flightLegs.push(new SubTravelInfo());
    }
  }

  deleteTravelInfo(travelInfo: SubTravelInfo) {
    const index = this.travellers.flightLegs.findIndex(d => d === travelInfo);
    this.travellers.flightLegs.splice(index, 1);
  }

  endUserHasDateOfBirth(event) {
    this.endUserHasDateOfBirthEmit.emit(event);
  }
}
