<div class="row">
  <div class="col-6">
    <app-case-basics-info [newCase]='newCase' [case]="case" [caseType]="case.caseTypeCode" [personInsurance]="personInsurance" [numberOfLinkedCases]="numberOfLinkedCases" [numberOfCoordinationCases]="numberOfCoordinationCases"  #caseBasicsInfo></app-case-basics-info>
  </div>
  <div class="col-6">
    <app-case-basics-insurance [caseId]="case.id" [caseNumber]="case.caseNumber" [personInsurance]="personInsurance" [otherInsurance]="otherInsurance" [consent]="consent"></app-case-basics-insurance>
  </div>
</div>
<div class="row">
  <div class="col-6">
    <app-case-basics-incident [caseId]="case.id"></app-case-basics-incident>
    <div style="height: 320px;"></div>
  </div>
  <div class="col-6">
    <app-case-basics-planned-travel [plannedTravel]="plannedTravel" [plannedTravelSubPlans]="plannedTravelSubPlans" [customerProduct]="customerProductId"></app-case-basics-planned-travel>
  </div>
</div>
