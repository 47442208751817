import { DropdownDictionary, SupplierDropDownAdapter } from './../../models/dropdownDictionary';
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ServiceOrderDropDownItem } from '../../models/service-order-drop-down-item';
import { ServiceTypeEnum, ServiceTypeEnumIcon, StakeholderTypeEnum, StatusTypes } from '../../models/enums';
import { DictionaryService } from 'src/app/core/services/dictionary.service';
import { ServiceOrderService } from 'src/app/core/services/service-order.service';
import { CaseService } from 'src/app/core/services/case.service';
import { ServiceOrder } from '../../models/service-order/service-order';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { Dictionary } from '@secca/shared/models/dictionary';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-drop-down-service-order',
  templateUrl: './drop-down-service-order.component.html',
  styleUrls: ['./drop-down-service-order.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DropDownServiceOrderComponent {
  @Input() recommended: boolean;
  @Input() hightLightInvalid: boolean = false;
  @Input() disabled: boolean;
  @Input() displayOnly: boolean;
  @Input() shouldDisableSelectionOnInvalidServiceOrders: boolean = true;
  @Input() selectedItemId: string;
  @Input() set serviceOrders(value: ServiceOrder[]) {
    this.selectableServiceOrders = value;
    this.initSelectItemList();
  }
  get serviceOrders(): ServiceOrder[] {
    return this.selectableServiceOrders;
  }
  @Output() selectedItemIdChange = new EventEmitter<string>();
  @Output() saveModel = new EventEmitter();
  @Output() open = new EventEmitter();
  @Output() close = new EventEmitter();
  @Output() itemsLoaded = new EventEmitter();

  items: ServiceOrderDropDownItem[];
  private providersNames: DropdownDictionary[];
  private oldSelectedItemId: string;
  private selectableServiceOrders: ServiceOrder[];
  constructor(
    private serviceOrderService: ServiceOrderService,
    private caseService: CaseService,
    private dictionaryService: DictionaryService,
    private supplierAdapter: SupplierDropDownAdapter
  ) {}

  initSelectItemList() {
    this.dictionaryService
      .getStakeholdersTypes()
      .pipe(take(1))
      .subscribe(stakeholderTypes => {
        this.caseService
          .getCaseStakeholderWithCaseStakeholder()
          .pipe(take(1))
          .subscribe(stakeholders => {
            this.mockProvidersNames(stakeholders);
            this.createServiceOrderItems(this.selectableServiceOrders);
          });
      });
  }

  onChange() {
    this.selectedItemIdChange.emit(this.selectedItemId);
  }

  onOpen() {
    this.open.emit();
  }

  onClose() {
    this.close.emit();
  }

  onSaveModel() {
    if (this.oldSelectedItemId !== this.selectedItemId) {
      this.saveModel.emit();
    }
  }

  onSaveState() {
    this.oldSelectedItemId = this.selectedItemId;
  }

  private mockProvidersNames(stakeholders: CaseStakeholder[]) {
    if (stakeholders) {
      this.providersNames = stakeholders
        .filter(
          stakeholder =>
            stakeholder.stakeholderType !== StakeholderTypeEnum.endUser &&
            stakeholder.stakeholderType !== StakeholderTypeEnum.reporter &&
            stakeholder.stakeholderType !== StakeholderTypeEnum.policyHolder
        )
        .map(data => this.supplierAdapter.adapt(data))
        .filter(data => data.name != null);
    }
  }
  private createServiceOrderItems(serviceOrders: ServiceOrder[]) {
    this.items = [];
    if (serviceOrders && this.providersNames) {
      for (const serviceOrder of serviceOrders) {
        const providerNameDict: Dictionary = this.getProviderDictionaryValue(serviceOrder);
        const serviceOrderType = serviceOrder.type;
        const enableStatus = StatusTypes.COMMITTED === serviceOrder.status || StatusTypes.NOT_COVERED === serviceOrder.status || StatusTypes.CANCELLED === serviceOrder.status;
        const isDisabled = !enableStatus ||
            serviceOrder.supplierId === null &&
          this.shouldDisableSelectionOnInvalidServiceOrders;
        if (serviceOrderType) {
          const serviceTypeEnumIcon = ServiceTypeEnumIcon.getIcon(serviceOrderType);
          this.items.push(
            new ServiceOrderDropDownItem({
              id: Number(serviceOrder.id),
              serviceOrderId: serviceOrder.serviceOrderId,
              iconFileName: 'assets/icons/' + serviceTypeEnumIcon,
              serviceOrderType,
              providerName: providerNameDict ? providerNameDict.name : '',
              disabled: isDisabled,
            })
          );
        }
      }
      this.itemsLoaded.emit();
    }
  }

  private getProviderDictionaryValue(serviceOrder): Dictionary {
    if (serviceOrder.type === ServiceTypeEnum.MEDICAL_ASSESSMENT) {
      return null;
    } else {
      const supplierId = serviceOrder.supplierId;
      return this.providersNames.find(p => Number.parseInt(p.id) === supplierId);
    }
  }

  disabledSelected(): boolean {
    if (this.hightLightInvalid && this.items) {
      return this.items.find(item => item.serviceOrderId === this.selectedItemId)?.disabled;
    }
    return false;
  }

  get selectedItem() {
    return this.items.find(item => item.serviceOrderId === this.selectedItemId);
  }
}
