<div class="phone-drop-down">
  <div class="dropdown-cell" [ngStyle]="!withInput && {'width':'100%'}">
    <span class="name">{{name}}</span>
    <ng-select #select (blur)="onSaveModel()" [typeahead]="input" (focus)="onSaveState()"
      [ngClass]="showCode === true ? 'small' : 'custom'"
      [class.required-red-background]="!currentSelectedItemId && required"
      [class.recommended-yellow-background]="!currentSelectedItemId && recommended && !required && !disabled"
      [class.dropdown-cell-highlight-border]="highlightBorderOnInputNonEmpty && (!!currentSelectedItemId || !!prefixValue || !!value) && !disabled"
      [class.right]="!withPrefixInput && !withInput"
      [clearable]="false"
      [(ngModel)]="currentSelectedItemId" placeholder="" (change)="onChange($event)" [items]="items | async" [disabled]="!!disabled"
      bindValue="code">
      <ng-template ng-label-tmp let-item="item" let-index="index">
        <div *ngIf="item.url !== undefined; else generateData">
          <div *ngIf="showCode; else showName">
            <flag-icon *ngIf="item.code" [country]="item.code.toLowerCase()"></flag-icon>
            {{item.code}}
          </div>
          <ng-template #showName>
            <flag-icon *ngIf="item.code" [country]="item.code.toLowerCase()"></flag-icon>
            {{item.countryName}}
          </ng-template>
        </div>
        <ng-template #generateData>
          <flag-icon [country]="item.code.toLowerCase()"></flag-icon>
          <span *ngIf="showCode; else showName">
            {{item.code}}
          </span>
          <ng-template #showName>
            {{countryName}}
          </ng-template>
        </ng-template>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-index="index">
        <div class="row" (click)="onItemClick(item)">
          <div class="col-2">
            <flag-icon [country]="item.code.toLowerCase()" class="country-image"></flag-icon>
          </div>
          <div class="col-10">
            <div class="country-name">{{item.countryName}}</div>
          </div>
        </div>
      </ng-template>
    </ng-select>
  </div>
  <div class="prefix-input-cell prefix-input-container" *ngIf="withPrefixInput">
    <div [className] ="validate && isValidated(value) ? 'invalid-input' : 'input'" >
      <input
        [ngClass]="{'important-yellow-background' : !prefixValue && recommended && !required && !disabled, 'required-red-background' : !prefixValue && required }"
        [class.prefix-input-cell-highlight-border]="highlightBorderOnInputNonEmpty && (!!currentSelectedItemId || !!prefixValue || !!value) && !disabled"
        [class.right]="!withInput"
        maxLength="8"
        type="text"
        (blur)="onSaveModel()"
        (focus)="onSaveState()"
        class="prefix-input-component"
        placeholder="{{placeholder}}"
        [(ngModel)]="prefixValue"
        [disabled]="!!disabled"
        (keyup)="onPrefixChange($event)"
      >
    </div>
  </div>
  <div class="input-cell input-container" *ngIf="withInput">
    <div [className] ="validate && isValidated(value) ? 'invalid-input' : 'input'" >
      <img *ngIf="this.value !== undefined" src="../../../../assets/icons/Phone%20note%20focused.svg" class="image-phone" (click)="onPerformCall()" >
      <img *ngIf="this.value === undefined || this.value === ''" src="../../../../assets/icons/Phone%20note.svg" class="image-phone disabled">
      <input maxLength="12"
        [ngClass]="{'important-yellow-background' : !value && recommended && !required && !disabled, 'required-red-background' : !value && required }"
        [class.input-cell-highlight-border]="highlightBorderOnInputNonEmpty && (!!currentSelectedItemId || !!prefixValue || !!value) && !disabled"
        type="tel"
        pattern="[0-9]*"
        (focus)="onSaveState()"
        class="input-component"
        (blur)="onSaveModel()"
        [(ngModel)]="value"
        [disabled]="!!disabled"
        (keyup)="onInputChange($event)">
    </div>
  </div>
</div>
