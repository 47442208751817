<div class="sub-component-container" *ngIf="subRemarksRulesAndFees">
  <div class="sub-title">{{"sub-remarks-rules-and-fees-title" | translate}}</div>
  <div class="sub-content-container">
    <div class="row">
      <div class="col-6">
        <app-text-box [name]="'sub-remarks-rules-and-fees-remarks' | translate" [maxTextLength]="1000"
                      [(value)]="subRemarksRulesAndFees.remarks"></app-text-box>
      </div>
      <div class="col-6">
        <app-text-box [name]="'sub-remarks-rules-and-fees-rules-and-fees' | translate" [maxTextLength]="1000"
                      [(value)]="subRemarksRulesAndFees.rulesAndFees"></app-text-box>
      </div>
    </div>
  </div>
</div>
