<div class="modal-content">
  <div class="modal-header">
    <div class="modal-header-title">
      {{ 'View digital case flow' }}
    </div>
    <div class="modal-buttons">
      <div (click)="close()">
        <img src="/assets/icons/Close White.svg" />
      </div>
    </div>
  </div>
  <div class="modal-content-body">
    <app-case-summary-digital-caseflow-details class="details" [flowLog]="flowLog"></app-case-summary-digital-caseflow-details>
  </div>
  <div class="modal-footer">
    <span class="grey">{{'digital-caseflow-time' | translate }}</span>
    <span>{{ flowLog.flowInitiationDate | momentFormat: 'DD. MMM YYYY HH:MM': 'local' }}</span>
    <span class="grey">{{ 'digital-caseflow-flow-log-id' | translate }}</span>
    <span>{{ flowLog.id }}</span>
  </div>
</div>
