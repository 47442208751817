import { AfterViewInit, Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { AutoUnsubscribe } from '../decorators/auto-unsubscribe';
import { MatDialog } from '@angular/material/dialog';

@Directive({
  selector: '[appDialogDragArea]'
})
@AutoUnsubscribe
export class DialogDragAreaDirective implements OnInit, AfterViewInit {
  readonly MAX_PARENT_TRAVERSE_CNT = 10;

  dialogId: string;
  containerElement: any;

  constructor(private matDialog: MatDialog,
              private elmRef: ElementRef,
              private renderer: Renderer2) {
  }

  ngOnInit() {
    this.findDialogId();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.initialiseDragArea();
    }, 1000);
  }

  private initialiseDragArea(): void {
    const overlayElement = this.findParentElementByClass(this.elmRef.nativeElement, 'cdk-overlay-pane');
    if ( overlayElement ) {
      this.renderer.setStyle(overlayElement, 'transform', 'translate3d(0px, 0px, 0px)');
    }

    this.renderer.addClass(this.elmRef.nativeElement, this.isMinimizable() ? 'dialog-drag-area' : 'dialog-drag-area-no-minimize');
  }

  private isMinimizable(): boolean {
    const dialogRef = this.matDialog.getDialogById(this.dialogId);
    const component = dialogRef?.componentInstance;
    if ( !component ) {
      return true;
    }
    const isMinimizable = !!component?.getMinimizeType && typeof component.getMinimizeType === 'function';
    const isMinimizableIconHidden = !!component?.minimizedDirective && component.minimizedDirective.isMinimizeIconHidden;

    return isMinimizable && !isMinimizableIconHidden;
  }

  private findDialogId(): void {
    this.containerElement = this.findParentElementByClass(this.elmRef.nativeElement, 'mat-dialog-container');
    if ( this.containerElement ) {
      this.dialogId = this.containerElement.id;
    }
  }

  private findParentElementByClass(element, componentClass) {
    for (let i = 0; i < this.MAX_PARENT_TRAVERSE_CNT; i++) {
        if ( !element ) {
          break;
        }

        if (element?.classList?.contains(componentClass)) {
            return element;
        }

        element = element.parentNode;
    }

    return null;
  }
}
