
import {Injectable} from '@angular/core';
import {BaseService} from '@secca/core/services/base.service';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {SettingsService} from '@secca/core/services/settings.service';
import {CoordinationDetails, CoordinationDetailsAdapter} from '@secca/shared/models/coordination-details';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import { CoordinationCaseDetail, CoordinationCaseDetailAdapter } from '@secca/shared/models/coordination-case-details';
import { MajorIncidentCaseDetail, MajorIncidentCaseDetailAdapter } from '@secca/shared/models/Major-incident-case-details';
import { MajorIncidentDetails, MajorIncidentDetailsAdapter } from '@secca/shared/models/Major-incident-details';



@Injectable({
    providedIn: 'root',
  })
  export class MajorIncidentDetailsService extends BaseService {
    constructor(
      private http: HttpClient,
      private settingsService: SettingsService,
      private majorIncidentCaseDetailAdapter: MajorIncidentCaseDetailAdapter,
      private coordinationDetailsAdapter: CoordinationDetailsAdapter,
      private majorIncidentDetailsAdapter: MajorIncidentDetailsAdapter

    ) {
      super(settingsService);
    }

    private details = new BehaviorSubject<MajorIncidentDetailsWithCaseId>(undefined);

    public getIncidentEventCasesDetails(majorIncidentCaseId: string): Observable<MajorIncidentCaseDetail[]> {
        return this.http
          .get(this.baseURL + 'incidents/incident-event-cases-details/' + majorIncidentCaseId, { headers: this.jsonHeaders })
          .pipe(map((data: MajorIncidentCaseDetail[]) => data.map(item => this.majorIncidentCaseDetailAdapter.adapt(item))));
    }


    public saveMajorIncidentDetails(caseId: string, caseTypeCode: string,  details: MajorIncidentDetails): Observable<MajorIncidentDetails> {
      return this.http.post<CoordinationDetails>(`${this.baseURL}majorincident/${caseId}/${caseTypeCode}`,
        details, { headers: this.jsonHeaders })
          .pipe(tap(result => this.details.next({caseId, details: result})));
    }

    getMajorIncidentDetails(caseId: string) {
      return this.http.get<CoordinationDetails>(`${this.baseURL}majorincident/${caseId}`)
        .pipe(
          map(cd => this.coordinationDetailsAdapter.adapt(cd)),
          tap(details => this.details.next({caseId, details})),
          catchError(err => this.handleError(err))
        );
    }

    public majorIncidentDetailsChanges() {
      return this.details.asObservable();
    }

    handleError(error: HttpErrorResponse) {
      if (error.status === 404) {
        return of(new CoordinationDetails());
      } else {
        throw error;
      }
    }
}


export class MajorIncidentDetailsWithCaseId {
  caseId: string;
  details: MajorIncidentDetails;
}