
export class IccRepatriationInfos{
  repatriationInfos: IccRepatriationInfo[] = [];

  constructor(repatriationInfos?: IccRepatriationInfo[]) {    
    this.repatriationInfos = !!repatriationInfos ? repatriationInfos : [];
  }

  byCase(caseId: string) : IccRepatriationInfo{
    let foundInfo = this.repatriationInfos.find(repatriationInfo => repatriationInfo.caseId == caseId);
    if (!foundInfo) {
      foundInfo = new IccRepatriationInfo({caseId : caseId});
      this.repatriationInfos.push(foundInfo);

    }
    return foundInfo;
  }
}

export class IccRepatriationInfo {
  caseId: string;
  patientHeight: number;
  patientWeight: number;
  remarks: string;
  chosenAssessmentId: number;

  public constructor(init?: Partial<IccRepatriationInfo>) {
    if (init) {
      Object.assign(this, init);
    }
  }
}

export class IccRepatriationInfoAdapter {
  adapt(item?: any): IccRepatriationInfo {
    return new IccRepatriationInfo({
      caseId: item.caseId,
      patientHeight: item.patientHeight,
      patientWeight: item.patientWeight,
      remarks: item.remarks,
      chosenAssessmentId: item.chosenAssessmentId
    })
  }
}
