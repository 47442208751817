import { SubTravelInfo } from './subTravelInfo';
import { SubComponentModel } from '@secca/case/components/case-plans/case-plans/add-service-order/models/interfaces';

export class SubTravellers implements SubComponentModel {
  flightLegs: SubTravelInfo[] = [new SubTravelInfo()];

  constructor(flightLegs = null) {
    if (flightLegs != null) {
      this.flightLegs = flightLegs;
    }
  }

  clearFlightLegs() {
    if (this.flightLegs) {
      this.flightLegs.length = 0;
      this.flightLegs.push(new SubTravelInfo());
    }
  }

  isValid(): boolean {
    for (const flightLeg of this.flightLegs) {
      if (!flightLeg.isValid(true)) {
        return false;
      }
    }
    return true;
  }

  isValidExpectation(): boolean {
    for (const flightLeg of this.flightLegs) {
      if (!flightLeg.isValidExpectation()) {
        return false;
      }
    }
    return true;
  }
}
