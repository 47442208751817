import { MedifStatusTypeEnum } from '@secca/case/components/case-plans/case-plans/add-service-order/models/enums';
import { ServiceOrderExtension } from '@secca/shared/models/service-order/service-order-extension';
import { StatusTypes } from '@secca/shared/models/enums';
import { SubRepatriationDetails } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subRepatriationDetails';
import * as moment from 'moment';
import { SubRepatriationFormAmbulanceFlight } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subRepatriationFormAmbulanceFlight';
import { SubStakeholders } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholders';
import { SubTravellers } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subTravellers';
import { SubRemarksToSupplier } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subRemarksToSupplier';
import { SubRemarksFromSupplier } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subRemarksFromSupplier';
import { SubTravelInfo, SubTravelInfoAdapter } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subTravelInfo';
import { SubStakeholder, SubStakeholderAdapter } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholder';
import { Location } from '@secca/shared/models/location';
import { DaySerializer } from '@secca/shared/models/day-serializer';
import { CommitmentType, CommitmentTypeAdapter } from '../commitment-type';

export class AmbulanceFlightServiceOrderExtension implements ServiceOrderExtension {
  id: number;
  repatriationFormAmbulanceFlight: SubRepatriationFormAmbulanceFlight;
  groundTransportToFlightNotIncluded: boolean;
  groundTransportFromFlightNotIncluded: boolean;
  //medifStatus: SubMedifStatus;
  repatriationDetails: SubRepatriationDetails;
  commitmentType: CommitmentType;
  medifStatusType: MedifStatusTypeEnum;
  subGroundTransportToFlightStakeholders: SubStakeholders;
  groundTransportToFlightStakeholders: SubStakeholder[];
  subGroundTransportToFlight: SubTravellers;
  groundTransportToFlight: SubTravelInfo[];
  subFlight: SubTravellers;
  flight: SubTravelInfo[];
  subFlightStakeholders: SubStakeholders;
  flightStakeholders: SubStakeholder[];
  subConfirmationFlight: SubTravellers;
  confirmationFlight: SubTravelInfo[];
  subConfirmationFlightStakeholders: SubStakeholders;
  confirmationFlightStakeholders: SubStakeholder[];
  subGroundTransportFromFlightStakeholders: SubStakeholders;
  groundTransportFromFlightStakeholders: SubStakeholder[];
  subGroundTransportFromFlight: SubTravellers;
  groundTransportFromFlight: SubTravelInfo[];
  remarksToSupplier: SubRemarksToSupplier;
  confirmationGroundTransportToFlightNotIncluded: boolean;
  subConfirmationGroundTransportToFlightStakeholders: SubStakeholders;
  confirmationGroundTransportToFlightStakeholders: SubStakeholder[];
  subConfirmationGroundTransportToFlight: SubTravellers;
  confirmationGroundTransportToFlight: SubTravelInfo[];
  confirmationGroundTransportFromFlightNotIncluded: boolean;
  subConfirmationGroundTransportFromFlight: SubTravellers;
  confirmationGroundTransportFromFlight: SubTravelInfo[];
  subConfirmationGroundTransportFromFlightStakeholders: SubStakeholders;
  confirmationGroundTransportFromFlightStakeholders: SubStakeholder[];

  remarksFromSupplier: SubRemarksFromSupplier;

  // Only used for display purposes in plans
  displayFlightLegId = 0;
  displayDurationId: number;

  public constructor(init?: Partial<AmbulanceFlightServiceOrderExtension>) {
    Object.assign(this, init);

    if (init) {
      if (!this.confirmationFlight) {
        this.subConfirmationFlight = new SubTravellers();
        this.confirmationFlight = this.subConfirmationFlight.flightLegs;
      }
    } else {
      this.init();
    }
  }

  isValidExpectation(): boolean {
    return this.subFlight.isValidExpectation() && this.isRepatriationValid();
  }

  isValid(): boolean {
    return this.subConfirmationFlight.flightLegs.length > 0 &&
           this.subConfirmationFlight.isValid() &&
           this.isConfirmationGroundAmbulanceFromFlightValid() &&
           this.isConfirmationGroundAmbulanceToFlightValid();
  }

  isPreviewGopValid(): boolean {
    throw new Error('Gop preview not supported for this service order type');
  }

  init() {
    this.repatriationFormAmbulanceFlight = new SubRepatriationFormAmbulanceFlight();
    this.repatriationDetails = new SubRepatriationDetails();
    this.subGroundTransportToFlight = new SubTravellers();
    this.groundTransportToFlight = this.subGroundTransportToFlight.flightLegs;
    this.subGroundTransportToFlightStakeholders = new SubStakeholders();
    this.groundTransportToFlightStakeholders = this.subGroundTransportToFlightStakeholders.stakeholders;
    this.subFlight = new SubTravellers();
    this.flight = this.subFlight.flightLegs;
    this.subFlightStakeholders = new SubStakeholders();
    this.flightStakeholders = this.subFlightStakeholders.stakeholders;
    this.subGroundTransportFromFlightStakeholders = new SubStakeholders();
    this.groundTransportFromFlightStakeholders = this.subGroundTransportFromFlightStakeholders.stakeholders;
    this.subGroundTransportFromFlight = new SubTravellers();
    this.groundTransportFromFlight = this.subGroundTransportFromFlight.flightLegs;
    this.remarksToSupplier = new SubRemarksToSupplier();
    this.remarksFromSupplier = new SubRemarksFromSupplier();

    this.subConfirmationGroundTransportToFlight = new SubTravellers();
    this.confirmationGroundTransportToFlight = this.subConfirmationGroundTransportToFlight.flightLegs;
    this.subConfirmationGroundTransportToFlightStakeholders = new SubStakeholders();
    this.confirmationGroundTransportToFlightStakeholders = this.subConfirmationGroundTransportToFlightStakeholders.stakeholders;

    this.subConfirmationFlightStakeholders = new SubStakeholders();
    this.confirmationFlightStakeholders = this.subConfirmationFlightStakeholders.stakeholders;
    this.subConfirmationFlight = new SubTravellers();
    this.confirmationFlight = this.subConfirmationFlight.flightLegs;

    this.subConfirmationGroundTransportFromFlight = new SubTravellers();
    this.confirmationGroundTransportFromFlight = this.subConfirmationGroundTransportFromFlight.flightLegs;
    this.subConfirmationGroundTransportFromFlightStakeholders = new SubStakeholders();
    this.confirmationGroundTransportFromFlightStakeholders = this.subConfirmationGroundTransportFromFlightStakeholders.stakeholders;
  }

  getStartDate(status: StatusTypes): moment.Moment {
    return this.getFlight(status)[this.displayFlightLegId].departureDate || this.getFlight(StatusTypes.EXPECTED)[this.displayFlightLegId].departureDate;
  }

  getStartDisplayDate(status: StatusTypes): moment.Moment {
    return this.getFlight(status)[this.displayFlightLegId].departureDisplayDate || this.getFlight(StatusTypes.EXPECTED)[this.displayFlightLegId].departureDisplayDate;
  }

  isMultipleDurations(status: StatusTypes): boolean {
    return this.getFlight(status).length > 1;
  }

  getFlight(status: StatusTypes): SubTravelInfo[] {
    if (status === StatusTypes.COMMITTED || status === StatusTypes.CANCELLED) {
      return this.confirmationFlight;
    }
    else {
      return this.flight;
    }
  }

  getStartLocation(status: StatusTypes): Location {
    return this.getFlight(status)[this.displayFlightLegId].departureFrom;
  }

  getEndLocation(status: StatusTypes): Location {
    if (status === StatusTypes.COMMITTED || status === StatusTypes.CANCELLED) {
      return this.getFlight(status)[this.confirmationFlight.length - 1].arrivalAt;
    }
    else {
      return this.getFlight(status)[this.displayFlightLegId].arrivalAt;
    }
  }

  getEndDate(status: StatusTypes): moment.Moment {
    let arrivalDate = null;

    if (status === StatusTypes.COMMITTED || status === StatusTypes.CANCELLED) {
      arrivalDate = this.getFlight(status)[this.confirmationFlight.length - 1].arrivalDate;
    }
    else {
      arrivalDate = this.getFlight(status)[this.flight.length - 1].arrivalDate;
    }

    return arrivalDate ? arrivalDate : this.getStartDate(status);
  }

  getEndDisplayDate(status: StatusTypes): moment.Moment {
    if (status === StatusTypes.COMMITTED || status === StatusTypes.CANCELLED) {
      return this.getFlight(status)[this.confirmationFlight.length - 1].arrivalDisplayDate;
    }
    else {
      return this.getFlight(status)[this.flight.length - 1].arrivalDisplayDate;
    }
  }

  hasMedicalEscort(): boolean {
    return false;
  }

  hasCoTravellers(status: StatusTypes): boolean {
    return this.getCoTravellers(status).length > 1;
  }

  private getCoTravellers(status: StatusTypes): SubStakeholder[] {
    if (status === StatusTypes.COMMITTED || status === StatusTypes.CANCELLED) {
      return this.confirmationFlightStakeholders;
    } else {
      return this.flightStakeholders;
    }
  }

  setCommitmentType(commitmentType: CommitmentType) {
    this.commitmentType = commitmentType;
  }

  getCommitmentType(): CommitmentType {
    return this.commitmentType;
  }

  getSelectedStakeholderIds(status? : StatusTypes): string[] {
    if (status === StatusTypes.COMMITTED || status === StatusTypes.CANCELLED) {
      return this.subConfirmationFlightStakeholders.stakeholders.map(subStakeholder => subStakeholder.stakeholderId);
    } else{
      return this.subFlightStakeholders.stakeholders.map(subStakeholder => subStakeholder.stakeholderId);
    }
  }

  getGroundTransportFromNotIncluded(status? : StatusTypes): boolean {
    if (status === StatusTypes.COMMITTED || status === StatusTypes.CANCELLED) {
      return this.confirmationGroundTransportFromFlightNotIncluded;
    } else {
      return this.groundTransportFromFlightNotIncluded;
    }
  }

  getGroundTransportFromFlight(status? : StatusTypes): SubTravelInfo {
    if (status === StatusTypes.COMMITTED || status === StatusTypes.CANCELLED) {
      return this.confirmationGroundTransportFromFlight[0];
    } else {
      return this.groundTransportFromFlight[0];
    }
  }

  getGroundTransportToNotIncluded(status? : StatusTypes): boolean {
    if (status === StatusTypes.COMMITTED || status === StatusTypes.CANCELLED) {
      return this.confirmationGroundTransportToFlightNotIncluded;
    } else {
      return this.groundTransportToFlightNotIncluded;
    }
  }

  getGroundTransportToFlight(status? : StatusTypes): SubTravelInfo {
    if (status === StatusTypes.COMMITTED || status === StatusTypes.CANCELLED) {
      return this.confirmationGroundTransportToFlight[0];
    } else {
      return this.groundTransportToFlight[0];
    }
  }

  isConfirmationGroundAmbulanceFromFlightValid() {
    return this.confirmationGroundTransportFromFlightNotIncluded ||
           this.isValidConfirmationFromFlight();
  }

  isConfirmationGroundAmbulanceToFlightValid() {
    return this.confirmationGroundTransportToFlightNotIncluded  ||
           this.isValidConfirmationToFlight();
  }

  isRepatriationValid() {
    return this.repatriationFormAmbulanceFlight.isValid();
  }

  private isValidConfirmationToFlight(): boolean {
    for (const flightLeg of this.subConfirmationGroundTransportToFlight.flightLegs) {
      if (!flightLeg.isDepartureFromValid() || !flightLeg.isArrivalAtValid()) {
        return false;
      }
    }
    return true;
  }

  private isValidConfirmationFromFlight(): boolean {
    for (const flightLeg of this.subConfirmationGroundTransportFromFlight.flightLegs) {
      if (!flightLeg.isDepartureDateValid() || !flightLeg.isDepartureFromValid()) {
        return false;
      }
    }
    return true;
  }
}

export class AmbulanceFlightServiceOrderExtensionAdapter {
  adapt(item?: Partial<AmbulanceFlightServiceOrderExtension>): AmbulanceFlightServiceOrderExtension {
    const commitmentTypeAdapter: CommitmentTypeAdapter = new CommitmentTypeAdapter();
    const subStakeholderAdapter = new SubStakeholderAdapter();
    const subTravelInfoAdapter = new SubTravelInfoAdapter();

    const groundTransportToFlightStakeholdersAdapted = item.groundTransportToFlightStakeholders.map(stakeholder => subStakeholderAdapter.adapt(stakeholder));
    const groundTransportToFlightAdapted = item.groundTransportToFlight.map(flightLeg => subTravelInfoAdapter.adapt(flightLeg));
    const flightStakeholdersAdapted = item.flightStakeholders.map(stakeholder => subStakeholderAdapter.adapt(stakeholder));
    const flightAdapted = item.flight.map(flightLeg => subTravelInfoAdapter.adapt(flightLeg));
    const groundTransportFromFlightStakeholdersAdapted = item.groundTransportFromFlightStakeholders.map(stakeholder => subStakeholderAdapter.adapt(stakeholder));
    const groundTransportFromFlightAdapted = item.groundTransportFromFlight.map(flightLeg => subTravelInfoAdapter.adapt(flightLeg));

    const confirmationGroundTransportToFlightStakeholdersAdapted = item.confirmationGroundTransportToFlightStakeholders.map(stakeholder => subStakeholderAdapter.adapt(stakeholder));
    const confirmationGroundTransportToFlightAdapted = item.confirmationGroundTransportToFlight.map(flightLeg => subTravelInfoAdapter.adapt(flightLeg));
    const confirmationFlightStakeholdersAdapted = item.confirmationFlightStakeholders ? item.confirmationFlightStakeholders.map(stakeholder => subStakeholderAdapter.adapt(stakeholder)) : [];

    const confirmationFlightAdapted = item.confirmationFlight?.map(flightLeg => subTravelInfoAdapter.adapt(flightLeg));
    const confirmationGroundTransportFromFlightStakeholdersAdapted = item.confirmationGroundTransportFromFlightStakeholders?.map(stakeholder => subStakeholderAdapter.adapt(stakeholder));
    const confirmationGroundTransportFromFlightAdapted = item.confirmationGroundTransportFromFlight.map(flightLeg => subTravelInfoAdapter.adapt(flightLeg));

    let result =  new AmbulanceFlightServiceOrderExtension({
      id: item.id,
      repatriationFormAmbulanceFlight: item.repatriationFormAmbulanceFlight
        ? new SubRepatriationFormAmbulanceFlight(item.repatriationFormAmbulanceFlight)
        : new SubRepatriationFormAmbulanceFlight(),
      repatriationDetails: item.repatriationDetails ? new SubRepatriationDetails(item.repatriationDetails) : new SubRepatriationDetails(),
      commitmentType:
        item.commitmentType != null ? commitmentTypeAdapter.adapt(item.commitmentType) : null,// TODO Default new SubCommitmentType(CommitmentTypes.BOOKING),
      medifStatusType: item.medifStatusType,
      groundTransportToFlightNotIncluded: item.groundTransportToFlightNotIncluded,
      groundTransportFromFlightNotIncluded: item.groundTransportFromFlightNotIncluded,
      groundTransportToFlightStakeholders: groundTransportToFlightStakeholdersAdapted,
      subGroundTransportToFlightStakeholders: new SubStakeholders(groundTransportToFlightStakeholdersAdapted),
      groundTransportToFlight: groundTransportToFlightAdapted,
      subGroundTransportToFlight: new SubTravellers(groundTransportToFlightAdapted),
      groundTransportFromFlightStakeholders: groundTransportFromFlightStakeholdersAdapted,
      subGroundTransportFromFlightStakeholders: new SubStakeholders(groundTransportFromFlightStakeholdersAdapted),
      groundTransportFromFlight: groundTransportFromFlightAdapted,
      subGroundTransportFromFlight: new SubTravellers(groundTransportFromFlightAdapted),
      flightStakeholders: flightStakeholdersAdapted,
      subFlightStakeholders: new SubStakeholders(flightStakeholdersAdapted),
      flight: flightAdapted,
      subFlight: new SubTravellers(flightAdapted),
      confirmationGroundTransportFromFlightNotIncluded: item.confirmationGroundTransportFromFlightNotIncluded,
      confirmationGroundTransportToFlightNotIncluded: item.confirmationGroundTransportToFlightNotIncluded,
      confirmationGroundTransportToFlightStakeholders: confirmationGroundTransportToFlightStakeholdersAdapted,
      subConfirmationGroundTransportToFlightStakeholders: new SubStakeholders(confirmationGroundTransportToFlightStakeholdersAdapted),
      confirmationGroundTransportToFlight: confirmationGroundTransportToFlightAdapted,
      subConfirmationGroundTransportToFlight: new SubTravellers(confirmationGroundTransportToFlightAdapted),
      confirmationFlightStakeholders: confirmationFlightStakeholdersAdapted ? confirmationFlightStakeholdersAdapted : [],
      subConfirmationFlightStakeholders: new SubStakeholders(confirmationFlightStakeholdersAdapted),
      confirmationFlight: confirmationFlightAdapted,
      subConfirmationFlight: new SubTravellers(confirmationFlightAdapted),
      confirmationGroundTransportFromFlightStakeholders: confirmationGroundTransportFromFlightStakeholdersAdapted,
      subConfirmationGroundTransportFromFlightStakeholders: new SubStakeholders(confirmationGroundTransportFromFlightStakeholdersAdapted),
      confirmationGroundTransportFromFlight: confirmationGroundTransportFromFlightAdapted,
      subConfirmationGroundTransportFromFlight: new SubTravellers(confirmationGroundTransportFromFlightAdapted),
      remarksToSupplier: item.remarksToSupplier ? new SubRemarksToSupplier(item.remarksToSupplier) : new SubRemarksToSupplier(),
      remarksFromSupplier: item.remarksFromSupplier ? new SubRemarksFromSupplier(item.remarksFromSupplier) : new SubRemarksFromSupplier(),
    });
    if (result.repatriationDetails.repatriationPossibleBy != null) {
      result.repatriationDetails.repatriationPossibleBy = DaySerializer.deserialize(
        result.repatriationDetails.repatriationPossibleBy as any
      );
    }
    return result;
  }
}
