
import { UserService } from './../../../../../core/services/user.service';
import { HandlingAreaStatusEnum } from '../../../../../shared/models/enums';
import { Component, Input } from '@angular/core';
import { HandlerPicture } from './handler-picture';

@Component({
  selector: 'app-handler-picture',
  templateUrl: './handler-picture.component.html',
  styleUrls: ['./handler-picture.component.scss']
})
export class HandlerPictureComponent {

  constructor(
    private userService: UserService
  ) { }

  @Input()
  set handlerPicture(newHandlerPicture: HandlerPicture) {
    this.userImage = null;
    this._handlerPicture = newHandlerPicture;
    this.userImage = this.getUserImage(newHandlerPicture);
  }

  get handlerPicture(): HandlerPicture {
    return this._handlerPicture;
  }

  _handlerPicture: HandlerPicture;
  userImage: string;

  getHandlerStatusEnum() {
    return HandlingAreaStatusEnum;
  }

  getUserImage(handlerPicture: HandlerPicture) {
    if (handlerPicture.haveUserImage && handlerPicture.activeUser && handlerPicture.userId) {
      return this.userService.getUserImagesUrl(handlerPicture.userId.toString());
    } else if (!handlerPicture.haveUserImage) {
      return '/assets/icons/NoImageYetNoWhite.png';
    } else {
      return '/assets/icons/DeactivatedUser.svg';
    }
  }

  updateUrl() {
    this.userImage = '/assets/icons/NoImageYetNoWhite.png';
  }

}
