<div class="team-caring-shown d-flex" >
  <div class="headline-for-teams">{{ 'board-team-quick-filters' | translate }}</div>
  <div class="board-menues d-flex">
    <button class="board-menues-item"
            [ngClass]="{ 'board-menues-item-selected': isMenuSelected('ADT04') }"
            (click)="boardTeamClick('ADT04')">
          {{ 'board-team-dk' | translate }}
    </button>
    <button class="board-menues-item"
            [ngClass]="{ 'board-menues-item-selected': isMenuSelected('ADT06') }"
            (click)="boardTeamClick('ADT06')">
            {{ 'board-team-fi' | translate }}
    </button>
    <button class="board-menues-item"
            [ngClass]="{ 'board-menues-item-selected': isMenuSelected('ADT03') }"
            (click)="boardTeamClick('ADT03')">
            {{ 'board-team-no' | translate }}
    </button>
    <button class="board-menues-item"
            [ngClass]="{ 'board-menues-item-selected': isMenuSelected('ADT27') }"
            (click)="boardTeamClick('ADT27')">
            {{ 'board-team-nordic' | translate }}
    </button>
    <button class="board-menues-item"
            [ngClass]="{ 'board-menues-item-selected': isMenuSelected('ADT34') }"
            (click)="boardTeamClick('ADT34')">
            {{ 'board-team-se1' | translate }}
    </button>
    <button class="board-menues-item"
            [ngClass]="{ 'board-menues-item-selected': isMenuSelected('ADT35') }"
            (click)="boardTeamClick('ADT35')">
            {{ 'board-team-se2' | translate }}
    </button>
    <button class="board-menues-item"
            [ngClass]="{ 'board-menues-item-selected': isMenuSelected('ADT11') }"
            (click)="boardTeamClick('ADT11')">
            {{ 'board-team-claims' | translate }}
    </button>
    <button class="board-menues-item"
            [ngClass]="{ 'board-menues-item-selected': isMenuSelected('ADT12') }"
            (click)="boardTeamClick('ADT12')">
            {{ 'board-team-predeparture' | translate }}
    </button>
  </div>
  <div>
    <div class="d-flex flex-row">
      <div class="assignee-dropdown">
        <app-drop-down-team
          name="{{ 'board-team-caring-team' | translate }}"
          [items]="teams"
          [(selectedItemId)]="selectedTeams"
          (selectedItemIdChange)="onSelectedTeamChangedNew($event)"
        ></app-drop-down-team>
      </div>
      <div class="assignee-dropdown">
        <app-drop-down-team-user
          name="{{ 'board-team-caring-assignee' | translate }}"
          [items]="filteredSearchUsers"
          [selectedItemId]="selectedworker"
          [selectedTeams]="selectedTeams"
          (selectedItemIdChange)="onSelectUserClick($event)"
        ></app-drop-down-team-user>
      </div>
      <button class="icon-button">
        <img src="../../../../assets/icons/clear-filter.svg" (click)="clearTeamAndAssegneeFilter()" />
      </button>
      </div>
    </div>
</div>
