import { Injectable } from '@angular/core';
import { BaseService } from '@secca/core/services/base.service';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '@secca/core/services/settings.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DigitalConsent, DigitalConsentAdapter } from '@secca/shared/models/digital-consent';
import { ConsentRequestEnum, ConsentStatusEnum } from '@secca/shared/models/enums';
import { CaseLockHttpService } from './case-lock-http.service';

@Injectable({
  providedIn: 'root',
})
export class DigitalConsentService extends BaseService {
  private acceptedConsentTypes: ConsentStatusEnum[] = [
    ConsentStatusEnum.CONSENT_ACCEPTED,
    ConsentStatusEnum.CONSENT_ACCEPTED_MAN,
    ConsentStatusEnum.CONSENT_NOT_POSSIBLE,
    ConsentStatusEnum.CONSENT_REJECTED,
  ];
  private cache$: Observable<DigitalConsent>;
  private lastCase: number;

  constructor(
    private settingsService: SettingsService,
    private http: HttpClient,
    private digitalConsentAdaptor: DigitalConsentAdapter,
    private caseLockHttpService: CaseLockHttpService
  ) {
    super(settingsService);
  }


  /*
  * Get status of consent.  Cached if consent is given.  Cache is cleared
   * when new case is requested.
  */
  getDigitalConsentStatus(caseId: number): Observable<DigitalConsent> {
    return this.fetchDigitalConsentStatus(caseId);
  }

  private isConsentGiven(digitalConsent: DigitalConsent) {
    if (this.acceptedConsentTypes.includes(digitalConsent.consentType)) {
      return true;
    }
    return false;
  }

  private fetchDigitalConsentStatus(caseId: number): Observable<DigitalConsent> {
    return this.http
      .get<DigitalConsent>(this.baseURL + 'digital-consent/case/' + caseId, { headers: this.jsonHeaders })
      .pipe(map(item => this.digitalConsentAdaptor.adapt(item)));
  }

  requestDigitalConsent(caseId: number, consentRequest: ConsentRequestEnum, text?: string): Observable<DigitalConsent> {
    return this.caseLockHttpService
      .post<DigitalConsent>(this.baseURL + 'digital-consent/case/' + caseId + '/' + consentRequest, text ? text : '{}', {
        headers: this.jsonHeaders,
      })
      .pipe(map(item => this.digitalConsentAdaptor.adapt(item)));
  }
}
