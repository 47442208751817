<div class="modal-dialog-wrapper" appDialogSelectable
     [appDialogMinimizable]='MinimizableDialogType.StakeholderSupplierSearch'>

  <mat-dialog-content (cdkDragStarted)='dialogBoundryService.handleDragStart($event)'
                      (cdkDragEnded)='dialogBoundryService.handleDragEnd($event)'
                      cdkDragRootElement='.cdk-overlay-pane' cdkDrag>
    <div appDialogDragArea cdkDragHandle></div>

    <app-supplier-search-dialog (closeSearchSupplierModal)='close()' [supplierSearchData]='data'>
    </app-supplier-search-dialog>
  </mat-dialog-content>
</div>
