import { MedicalPreAssessmentStatusEnum, ShortcutEnum, TaskType } from '@secca/shared/models/enums';
import { Case } from '@secca/shared/models/case';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Task } from '@secca/shared/models/task';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { Subscription } from 'rxjs';
import { Coverage } from '@secca/shared/models/coverage';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import { TaskModalModeEnum } from './task-modal-static-mode.enum';
import { TaskModalCommon } from '@secca/shared/components/task-modal-common/task-modal-common.component';
import { CaseLockOverlayAction, LockContextEnum } from '@secca/shared/enums/lock-context.enum';
import {MomentHelperService} from "@secca/core/services/moment-helper.service";
import moment from "moment";
import { UserDto } from '@secca/shared/models/userDto';
import { TeamDto } from '@secca/shared/models/teamDto';

@Component({
  selector: 'app-task-modal-static',
  templateUrl: './task-modal-static.component.html',
  styleUrls: ['./task-modal-static.component.scss'],
})
@AutoUnsubscribe
export class TaskModalStaticComponent extends TaskModalCommon implements OnInit, OnDestroy {
  @Input() taskModalModeEnum: TaskModalModeEnum;
  @Input() taskCase: string;
  @Input() taskId: string;
  @Input() taskStatusDisabled = false;

  coverages: Coverage[];
  reserve: number;
  currency: string;
  primaryCaseHandler: string;
  lastCaseActionBy: string;
  lastCaseActionTime: Date;
  $subscr2: Subscription;
  $teamsSubscr: Subscription;
  $loggedUserSubscr: Subscription;
  taskTypeEnum = TaskType;
  priorityDropdown: DropdownDictionary[] = [];

  private shortcutSubscription: Subscription;

  _constructor() {
    this.shortcutSubscription = this.shortcutService.addShortcut({ keys: ShortcutEnum.SaveAction }).subscribe(_ => {
      if (this.validated) {
        this.createOrEditTask();
      }
    });
  }

  ngOnInit() {
    this.initialiseTaskInformation(this.taskId || this.taskViewModel?.id);
    this.initialiseFromMode();
    this.initialiseCaseInformation(this.case, this.taskCase || this.taskViewModel?.caseId?.toString());
    this.initialiseTeamHandler();
    this.initialiseCaseNumberValidated(this.case?.id || this.taskCase || this.taskViewModel?.caseId?.toString());
    this.initPriorityDropdown();
  }

  ngOnDestroy(): void {
    this.shortcutSubscription.unsubscribe();
  }

  initialiseFromMode() {
    switch (this.taskModalModeEnum) {
      case TaskModalModeEnum.viewOnly:
        this.disableSaveButtonWhenNoChanges = false;
        break;

      case TaskModalModeEnum.addNew:
        this.initialiseHandlerInformationFromLoggedinUser();
        break;

      case TaskModalModeEnum.assignableOnly:
        this.disableSaveButtonWhenNoChanges = true;
        break;
    }
  }

  get caseId() {
    return this.case?.id || this.taskCase || this.taskViewModel?.caseId?.toString();
  }

  get isReadOnly(): boolean {
    return this.taskModalModeEnum === TaskModalModeEnum.viewOnly;
  }

  get isAssignableOnly(): boolean {
    return this.taskModalModeEnum === TaskModalModeEnum.assignableOnly;
  }

  get isPartialReadOnly(): boolean {
    return this.taskModalModeEnum === TaskModalModeEnum.partialViewOnly;
  }

  get isSupplierInvoiceTask(): boolean {
    return this.task.taskType === TaskType.suplierInvoice;
  }

  get isRefundTask(): boolean {
    return this.task.taskType === TaskType.refund;
  }

  get isRecoveryTask(): boolean {
    return this.task.taskType === TaskType.recovery;
  }

  get isRecoveryPaymentTask(): boolean {
    return this.task.taskType === TaskType.recoveryPayment;
  }

  get isMedicalPreAssessmentTask(): boolean {
    return this.task.taskType === TaskType.medicalPreAssessment;
  }

  get isMedicalPreAssessmentTaskDone(): boolean {
    return this.medicalPreAssessment?.status === MedicalPreAssessmentStatusEnum.DONE;
  }

  get validated(): boolean {
    return super.isValidated(this.taskModalModeEnum) && !(this.caseStateService.isCaseClosed || this.caseStateService.isInvoiceClosedWithNoInvoicingToCustomer);
  }

  get SubmitButtonTitle() {
    return super.getSubmitButtonTitle(this.taskModalModeEnum);
  }

  get modalTitle() {
    return super.getModalTitle(this.taskModalModeEnum);
  }

  createOrEditTask() {
    if (this.taskModalModeEnum === TaskModalModeEnum.viewOnly) {
      this.close();
      return;
    }
    if (this.taskModalModeEnum === TaskModalModeEnum.edit ||
      this.taskModalModeEnum === TaskModalModeEnum.partialViewOnly ||
      this.taskModalModeEnum === TaskModalModeEnum.assignableOnly) {
      this.updateTask();
    } else {
      this.createTask();
    }
  }

  descriptionChange($event) {
    if (this.task.description !== $event) {
      this.setTaskHasChanged();
    }
    this.disableSaveButtonWhenNoChanges = false;
    this.task.description = $event;
  }

  calculateCaseReserve() {
    if (this.coverages != null) {
      this.reserve = this.coverages.map(a => a.reserve).reduce((a, b) => a + b, 0);
    }
  }

  private initialiseCaseInformation(seccaCase: Case, caseId: string) {
    if (caseId) {
      this.getCaseInformations(caseId);
    } else if (seccaCase) {
      this.getHeaderInformations(seccaCase);
      this.getCoverages(seccaCase.id);

      this.getBillingCurrency(seccaCase.id, billingCurrency => {
        this.currency = billingCurrency.billingCurrency;
      });

      this.getPrimaryCaseHandler(seccaCase.id, (user: UserDto, team: TeamDto) => {
        if (user) {
          this.primaryCaseHandler = user.fullNameAndInitialt;
        } else if (team) {
          this.primaryCaseHandler = team.teamName;
        } else {
          this.primaryCaseHandler = undefined;
        }
      });

      this.getLastActionInCaseSummary(seccaCase.id, caseHistoryEntry => {
        this.lastCaseActionTime = moment(caseHistoryEntry.actionTime).utc(true).toDate();
        this.getUser(caseHistoryEntry.userId, user => {
          if (user != undefined && user.firstName != undefined && user.surname != undefined && user.initials != undefined) {
            this.lastCaseActionBy = user.firstName + ' ' + user.surname + ', ' + user.initials;
          } else {
            this.lastCaseActionBy = undefined;
          }
        });
      });
    }
  }

  private initialiseTaskInformation(taskId: string) {
    if (taskId) {
      this.getTaskInformation(+taskId).subscribe();
    } else {
      this.task = new Task();
      this.task.dueDate = MomentHelperService.getSeccaTime().utc();
    }
  }

  private initPriorityDropdown() {
      this.priorityDropdown.push(new DropdownDictionary(1, this.translate.instant('task-priority-type-1')));
      this.priorityDropdown.push(new DropdownDictionary(2, this.translate.instant('task-priority-type-2')));
      this.priorityDropdown.push(new DropdownDictionary(3, this.translate.instant('task-priority-type-3')));
      this.priorityDropdown.push(new DropdownDictionary(4, this.translate.instant('task-priority-type-4')));
      this.priorityDropdown.push(new DropdownDictionary(5, this.translate.instant('task-priority-type-5')));
  }

  private getCoverages(caseId: string) {
    this.planService.getCoveragesForCase(caseId).subscribe(
      coverages => {
        this.coverages = coverages;
        this.calculateCaseReserve();
      },
      error => console.log(error)
    );
  }

  private initialiseCaseNumberValidated(caseId: string) {
    if (caseId) {
      this.$subscr2 = this.caseValidationService.isCaseNumberAssigned(caseId).subscribe(validated => {
        this.isCaseNumberValidated = validated;
      });
    }
  }

  private initialiseTeamHandler() {
    this.$teamsSubscr = this.dictionaryService.getAllActiveTeamsList().subscribe(result => {
      if (result != null) {
        this.teams = result.map(a => new DropdownDictionary(a.adtCode, a.teamName));
        if (this.task != null && this.task.adtCode != null) {
          this.initializeTeamAcHandler();
        }
      }
    });
  }

  private initialiseHandlerInformationFromLoggedinUser() {
    if (this.$loggedUserSubscr) {
      this.$loggedUserSubscr.unsubscribe();
    }
    this.$loggedUserSubscr = this.dictionaryService.getLoggedInUser().subscribe(user => {
      if (user != null) {
        this.getHandlerInformation(user);
      }
    });
  }

  private getCaseInformations(caseId: string) {
    this.caseService.getCase(caseId).subscribe(
      seccaCase => {
        this.case = seccaCase;
        this.getHeaderInformations(seccaCase);
        this.getCoverages(caseId);
      },
      error => console.log(error)
    );

    this.getBillingCurrency(caseId, billingCurrency => {
      this.currency = billingCurrency.billingCurrency;
    })

    this.getPrimaryCaseHandler(caseId, (user: UserDto, team: TeamDto) => {

      if (user && user.firstName && user.surname && user.initials) {
        this.primaryCaseHandler = user.firstName + ' ' + user.surname + ', ' + user.initials;
      } else if (team) {
        this.primaryCaseHandler = team.teamName;
      } else {
        this.primaryCaseHandler = undefined;
      }
    });

    this.getLastActionInCaseSummary(caseId, caseHistoryEntry => {
      this.lastCaseActionTime = moment(caseHistoryEntry.actionTime).utc(false).toDate();
      this.getUser(caseHistoryEntry.userId, user => {
        if (user != undefined && user.firstName != undefined && user.surname != undefined && user.initials != undefined) {
          this.lastCaseActionBy = user.firstName + ' ' + user.surname + ', ' + user.initials;
        } else {
          this.lastCaseActionBy = undefined;
        }

      });
    });
  }

  get LockContextEnum(): any {
    return LockContextEnum;
  }

  get CaseLockOverlayAction(): any {
    return CaseLockOverlayAction;
  }

  get TaskType() {
    return TaskType;
  }
}
