import {Component, Input} from '@angular/core';
import {PolicyPerson} from '../../../../../../shared/models/policy-lookup-response';

@Component({
  selector: 'app-policy-lookup-person',
  templateUrl: './policy-lookup-person.component.html',
  styleUrls: ['./policy-lookup-person.component.scss']
})
export class PolicyLookupPersonComponent {
  @Input() person: PolicyPerson;
  @Input() title: string;
}
