<div class="assessment-card" id="repatriationGeneral">
  <app-medical-assessment-card-title [valid]="state.repatriationGeneralValid"
    [title]="'medical-regular-assessment-repatriation-card-general-info' | translate">
  </app-medical-assessment-card-title>
  <div class="assessment-card-content">

    <app-multiple-select [name]="'medical-regular-assessment-main-diagnosis' | translate" [maxElements]="1" [singleValue]="true"
      [(selectedItems)]="this.selectedDiagnosis" [disabled]=true>
    </app-multiple-select>

    <div class="small-spacer"></div>

    <app-multiple-select [name]="'medical-regular-assessment-reported-symptoms' | translate" [maxElements]="10" [singleValue]="false"
      [(selectedItems)]="this.selectedSymptoms" [disabled]=true>
    </app-multiple-select>

    <div class="small-spacer"></div>

    <div class="date-size-col">
      <app-input-calendar [name]="'medical-regular-assessment-date-of-diagnosis' | translate" [date]="dateOfDiagnosis" [disabled]="true">
      </app-input-calendar>
    </div>

    <div class="small-spacer"></div>

    <app-medical-assessment-source [disabled]="disabledViewOfMedicalAssessment"
                                  [name]="'repatriation-treating-doctor' | translate"
                                  [required]=true
                                  [selectedItemId]="resolveSourceId()"
                                  (selectedItemIdChange)="updateSourceData($event)"
                                  [users]="this.users"
                                  [disabled]="disabledViewOfMedicalAssessment">
    </app-medical-assessment-source>

    <div class="spacer"></div>

    <app-yes-no-question [header]="'repatriation-surgery' | translate" [disabled]="disabledViewOfMedicalAssessment"
      [(value)]="this.repatriationInstructions.patientState.surgery">
    </app-yes-no-question>

    <div class="small-spacer"></div>

    <div *ngIf="state.surgery">
      <div class="date-size-col">
        <app-input-calendar [withImage]="true" [name]="'repatriation-date-of-surgery' | translate"
          [date]="repatriationInstructions.patientState.surgeryDate" [disabled]="disabledViewOfMedicalAssessment"
          (dateChange)="updateSurgeryDate($event)" [required]="true">
        </app-input-calendar>
      </div>
      <div class="small-spacer"></div>

      <app-text-box [maxTextLength]="250" [name]="'repatriation-decribe-surgery' | translate" [required]=true [numberOfRows]="5"
        [disabled]="disabledViewOfMedicalAssessment" [(value)]="repatriationInstructions.patientState.surgeryDescription">
      </app-text-box>
    </div>

  </div>
</div>

<div class="assessment-card" id="repatriationForm">
  <app-medical-assessment-card-title [valid]="state.repatriationFormValid"
    [title]="'medical-regular-assessment-repatriation-card-form' | translate">
  </app-medical-assessment-card-title>
  <div class="assessment-card-content">

    <div class="date-size-col">
      <app-input-calendar [withImage]="true" [name]="'repatriation-repatriation-possible-by-date' | translate"
        [date]="repatriationInstructions.repatriationForm.repatriationDate" [disabled]="disabledViewOfMedicalAssessment"
        [required]="!repatriationInstructions.repatriationForm.repatriationDate" (dateChange)="updateRepatriationDate($event)">
      </app-input-calendar>
    </div>
    <div class="spacer"></div>
    <div class="date-size-col">
      <app-input-calendar [withImage]="true" [name]="'repatriation-arrival-deadline' | translate"
        [date]="this.repatriationInstructions.repatriationForm.arrivalDeadline" [disabled]="disabledViewOfMedicalAssessment"
        (dateChange)="updateArrivalDeadline($event)">
      </app-input-calendar>
    </div>
    <div *ngIf="state.repatriationArrivalDeadline">
      <div class="small-spacer"></div>

      <app-text-box [maxTextLength]="250" [name]="'repatriation-arrival-deadline-describe' | translate" [required]=true
        [(value)]="this.repatriationInstructions.repatriationForm.arrivalDescription" [disabled]="disabledViewOfMedicalAssessment">
      </app-text-box>
    </div>

    <div class="spacer"></div>
    <app-drop-down [(selectedItemId)]="this.repatriationInstructions.repatriationForm.form" [items]="repatriationFormList"
      [name]="'repatriation-repatriation-form' | translate" [requiredRedBackground]=true [disabled]="disabledViewOfMedicalAssessment">
    </app-drop-down>

    <div *ngIf="state.extraSpace">
      <div class="small-spacer"></div>

      <div class="fixed-text-holder">
        <app-multiple-choice-component [disabled]="disabledViewOfMedicalAssessment" [items]="extraSpaceList" [extraSpace]=true
          (valueChangeEvent)="updateExtraSpace($event)" [title]="'repatriation-extra-space-type' | translate">
        </app-multiple-choice-component>
        <div class="fixed-text">
          {{ 'repatriation-minimum-height' | translate }}
        </div>
      </div>
    </div>

    <div class="small-spacer"></div>

    <app-text-box *ngIf="state.extraSpaceDesc" [maxTextLength]="250" [name]="'repatriation-describe-patient-need' | translate" [numberOfRows]="5"
      [required]=true [(value)]="otherExtraSpaceDescription" (valueChange)="getOtherExtraSpace().setOtherItemDescription($event)"
      [disabled]="disabledViewOfMedicalAssessment">
    </app-text-box>

    <div *ngIf="state.intensiveCarePatient">
      <div class="small-spacer"></div>

      <app-small-yes-no-question [disabled]="disabledViewOfMedicalAssessment" [header]="'repatriation-sea-level' | translate"
        [(value)]="repatriationInstructions.repatriationForm.seaLevelFlight">
      </app-small-yes-no-question>
    </div>

    <div class="small-spacer"></div>

    <div *ngIf="state.intensiveCarePatient || state.stretcher">
      <div class="half-size">
        <div class="input-name">{{ 'repatriation-patient-height' | translate }} </div>
        <input
          id="repatriation-patient-height"
          type="number"
          (blur)="roundOffTo($event, 0)"
          [(ngModel)]="repatriationInstructions.repatriationForm.patientHeight"
          [ngClass]="{'input-component-disabled' : !!disabledViewOfMedicalAssessment, 'input-component-required': !repatriationInstructions.repatriationForm.patientHeight }"
          class="input-component"
          [disabled]="!!disabledViewOfMedicalAssessment">
          <div class="unit-text">cm</div>
      </div>
      <div class="small-spacer"></div>
      <div class="half-size">
        <div class="input-name">{{ 'repatriation-patient-weight' | translate }} </div>
        <input
          id="repatriation-patient-weight"
          type="number"
          (blur)="roundOffTo($event, 0)"
          [(ngModel)]="repatriationInstructions.repatriationForm.patientWeight"
          [ngClass]="{'input-component-disabled' : !!disabledViewOfMedicalAssessment, 'input-component-required': !repatriationInstructions.repatriationForm.patientWeight}"
          class="input-component"
          [disabled]="!!disabledViewOfMedicalAssessment">
          <div class="unit-text">kg</div>
      </div>
      <div class="small-spacer"></div>
      <app-small-yes-no-question [disabled]="disabledViewOfMedicalAssessment" [header]="'repatriation-pcob' | translate"
        [(value)]="repatriationInstructions.repatriationForm.patientCarriableOnBoard">
      </app-small-yes-no-question>
    </div>

    <div class="spacer"></div>

    <app-text-box [maxTextLength]="250" [name]="'repatriation-mobilization' | translate" [required]=false [numberOfRows]="5"
      [disabled]="disabledViewOfMedicalAssessment" [(value)]="repatriationInstructions.repatriationForm.mobilization">
    </app-text-box>

    <div class="small-spacer"></div>

    <app-yes-no-question [header]="'repatriation-repatriation-by-ground' | translate" [disabled]="disabledViewOfMedicalAssessment"
      [(value)]="repatriationInstructions.repatriationForm.groundRepatriation">
    </app-yes-no-question>

    <app-yes-no-question [header]="'repatriation-patient-can-be-co-transported' | translate" [disabled]="disabledViewOfMedicalAssessment"
      [(value)]="repatriationInstructions.repatriationForm.coTransport">
    </app-yes-no-question>

    <div class="small-spacer"></div>

    <app-text-box *ngIf="state.coTransportDesc" [disabled]="disabledViewOfMedicalAssessment" [maxTextLength]="250"
      [name]="'repatriation-describe-why-not' | translate" [required]=true
      [(value)]="repatriationInstructions.repatriationForm.coTransportDescription">
    </app-text-box>

  </div>
</div>
<div class="assessment-card" *ngIf="state.intensiveCarePatient" id="repatriationIntensiveCare">
  <app-medical-assessment-card-title [valid]="state.repatriationIntensiveCareValid"
    [title]="'medical-regular-assessment-repatriation-card-intensive-care-patient' | translate">
  </app-medical-assessment-card-title>
  <div class="assessment-card-content">

    <app-text-box [maxTextLength]="250" [name]="'repatriation-ventilator' | translate" [required]=true
      [placeHolder]="'repatriation-ventilator-helptext' | translate" [numberOfRows]="5"
      [(value)]="repatriationInstructions.patientState.breathingSupportDescription" [disabled]="disabledViewOfMedicalAssessment">
    </app-text-box>

    <div class="spacer"></div>

    <app-text-box [maxTextLength]="250" [name]="'repatriation-medications' | translate" [required]=true [numberOfRows]="5"
      [disabled]="disabledViewOfMedicalAssessment" [(value)]="repatriationInstructions.patientState.medications"
      [placeHolder]="'repatriation-medications-helptext' | translate">
    </app-text-box>

    <div class="spacer"></div>
    <app-drop-down [disabled]="disabledViewOfMedicalAssessment" [(selectedItemId)]="repatriationInstructions.patientState.intensiveCareStep"
      [items]="stepCategoryList" (saveModel)="updateintensiveCareStepText(repatriationInstructions.patientState.intensiveCareStep)" [name]="'repatriation-step-category' | translate" [requiredRedBackground]=true>
    </app-drop-down>
    <div class="small-spacer"></div>
  </div>
</div>

<div class="assessment-card" id="vitalSigns">
  <app-medical-assessment-card-title [title]="'medical-regular-assessment-repatriation-card-vital-signs' | translate">
  </app-medical-assessment-card-title>
  <div class="assessment-card-content">

    <div class="two-col">
      <div class="left-col">
        <app-input [name]="'repatriation-blood-pressure' | translate" [required]=false [disabled]="disabledViewOfMedicalAssessment"
          [(value)]="repatriationInstructions.patientState.bloodPressureSystolic" [inputTypePattern]="InputTypePattern.digitsOnly">
        </app-input>
      </div>
      <div class="left-text"> {{ 'repatriation-sys' | translate }} </div>
      <div class="middle-text"> /</div>
      <div class="right-col">
        <app-input [disabled]="disabledViewOfMedicalAssessment" [required]=false [inputTypePattern]="InputTypePattern.digitsOnly"
          [(value)]="repatriationInstructions.patientState.bloodPressureDiastolic">
        </app-input>
      </div>
      <div class="right-text">{{ 'repatriation-dia' | translate }}</div>
    </div>

    <div class="small-spacer"></div>

    <div class="half-size">
      <app-input [name]="'repatriation-pulse' | translate" [required]=false [disabled]="disabledViewOfMedicalAssessment"
        [(value)]="repatriationInstructions.patientState.hearthRate" [inputTypePattern]="InputTypePattern.digitsOnly" [inputType]="InputType.number" [inputType]="InputType.number">
      </app-input>
      <div class="unit-text">{{'repatriation-beats-per-minute' | translate}}</div>
    </div>

    <div class="small-spacer"></div>

    <div class="half-size">
      <app-input [name]="'repatriation-respiratory-rate' | translate" [required]=false [disabled]="disabledViewOfMedicalAssessment"
        [(value)]="repatriationInstructions.patientState.respiratoryRate" [inputTypePattern]="InputTypePattern.digitsOnly" [inputType]="InputType.number">
      </app-input>
      <div class="unit-text">{{'repatriation-breaths-per-minute' | translate}}</div>
    </div>

    <div class="small-spacer"></div>
    <div class="half-size">
      <app-input [name]="'repatriation-oxygen-saturation' | translate" [required]=false [disabled]="disabledViewOfMedicalAssessment"
        [(value)]="repatriationInstructions.patientState.oxygenSaturation" [inputTypePattern]="InputTypePattern.digitsOnly" [inputType]="InputType.number">
      </app-input>
      <div class="unit-text">%</div>
    </div>

    <div class="small-spacer"></div>

    <div class="half-size">
      <div class="input-name">{{ 'repatriation-temperature' | translate }} </div>
      <input
        id="temperature"
        type="number"
        [(ngModel)]="repatriationInstructions.patientState.temperature"
        (blur)="roundOffTo($event, 1)"
        [ngClass]="{'input-component-disabled' : !!disabledViewOfMedicalAssessment}"
        class="input-component"
        [disabled]="!!disabledViewOfMedicalAssessment">
        <div class="unit-text">&deg;C</div>
    </div>

    <div class="small-spacer"></div>
    <div class="half-size">
      <div class="input-name">{{ 'repatriation-hemoglobin' | translate }} </div>
      <input
        id="hemoglobin"
        type="number"
        [(ngModel)]="repatriationInstructions.patientState.hemoglobin"
        (blur)="roundOffTo($event, 1)"
        [ngClass]="{'input-component-disabled' : !!disabledViewOfMedicalAssessment}"
        class="input-component"
        [disabled]="!!disabledViewOfMedicalAssessment">
        <div class="unit-text">g/dl</div>
    </div>
  </div>
</div>

<div class="assessment-card" id="repatriationWheelchair">
  <app-medical-assessment-card-title [valid]="state.repatriationWheelchairValid"
    [title]="'medical-regular-assessment-repatriation-card-wheelchair' | translate">
  </app-medical-assessment-card-title>
  <div class="assessment-card-content">

    <app-single-choice-component [items]="wheelchairList" [(value)]="repatriationInstructions.patientNeeds.wheelchairNeed"
      [title]="'repatriation-wheelchair' | translate" [disabled]="disabledViewOfMedicalAssessment">
    </app-single-choice-component>

  </div>
</div>

<div class="assessment-card" id="repatriationTransitAdmission">
  <app-medical-assessment-card-title [valid]="state.repatriationTransitAdmissionValid"
    [title]="'medical-regular-assessment-repatriation-card-transit-and-admission' | translate">
  </app-medical-assessment-card-title>
  <div class="assessment-card-content">

    <app-single-choice-component [items]="transitListTo" [(value)]="this.repatriationInstructions.repatriationForm.transportToAirport"
      [title]="'repatriation-departure-airport' | translate" [disabled]="disabledViewOfMedicalAssessment">
    </app-single-choice-component>

    <div class="spacer"></div>

    <app-single-choice-component [items]="transitListFrom" [(value)]="this.repatriationInstructions.repatriationForm.transportFromAirport"
      [title]="'repatriation-arrival-airport' | translate" [disabled]="disabledViewOfMedicalAssessment">
    </app-single-choice-component>

    <div class="spacer"></div>

    <app-yes-no-question [header]="'repatriation-medical-care-in-transit' | translate" [disabled]="disabledViewOfMedicalAssessment"
      [(value)]="repatriationInstructions.patientNeeds.medicalCareInTransit">
    </app-yes-no-question>

    <div *ngIf="state.medicalCareInTransit">
      <div class="small-spacer"></div>

      <app-text-box [maxTextLength]="250" [name]="'repatriation-medical-care-in-transit-describe' | translate" [required]=true
        [(value)]="repatriationInstructions.patientNeeds.medicalCareInTransitDescription" [disabled]="disabledViewOfMedicalAssessment">
      </app-text-box>
    </div>

    <div class="spacer"></div>

    <app-yes-no-question [header]="'repatriation-admission-upon-arrival' | translate" [disabled]="disabledViewOfMedicalAssessment"
      [(value)]="repatriationInstructions.patientNeeds.admissionUponArrival">
    </app-yes-no-question>

    <div *ngIf="state.admissionOnArrival">
      <div class="small-spacer"></div>

      <app-text-box [disabled]="disabledViewOfMedicalAssessment" [maxTextLength]="250" [numberOfRows]="5"
        [name]="'repatriation-admission-upon-arrival-ward' | translate" [required]=true
        [(value)]="repatriationInstructions.patientNeeds.ward">
      </app-text-box>

      <div class="small-spacer"></div>

      <app-text-box [disabled]="disabledViewOfMedicalAssessment" [maxTextLength]="250" [numberOfRows]="5"
        [name]="'repatriation-admission-upon-arrival-describe' | translate" [required]=true
        [(value)]="repatriationInstructions.patientNeeds.admissionUponArrivalDescription">
      </app-text-box>
    </div>
  </div>
</div>

<div class="assessment-card" id="repatriationCast">
  <app-medical-assessment-card-title [valid]="state.repatriationCastValid"
    [title]="'medical-regular-assessment-repatriation-card-cast' | translate"></app-medical-assessment-card-title>
  <div class="assessment-card-content">

    <app-yes-no-question [header]="'repatriation-injury-treated-with-a-cast' | translate" [disabled]="disabledViewOfMedicalAssessment"
      [(value)]="repatriationInstructions.patientState.cast">
    </app-yes-no-question>

    <div *ngIf="state.cast">
      <div class="small-spacer"></div>

      <app-multiple-choice-component [disabled]="disabledViewOfMedicalAssessment" [items]="castList"
        (valueChangeEvent)="updateCastSide($event)" [title]="">
      </app-multiple-choice-component>

      <div class="small-spacer"></div>

      <app-small-yes-no-question [disabled]="disabledViewOfMedicalAssessment" [header]="'repatriation-cut-cast' | translate"
        [(value)]="repatriationInstructions.patientState.castReadyForFlight">
      </app-small-yes-no-question>

      <div class="small-spacer"></div>

      <app-text-box [disabled]="disabledViewOfMedicalAssessment" [maxTextLength]="250" [name]="'repatriation-describe-cast' | translate"
        [required]=true [(value)]="repatriationInstructions.patientState.castDescription">
      </app-text-box>
    </div>
  </div>
</div>

<div class="assessment-card" id="repatriationOxygen">
  <app-medical-assessment-card-title [valid]="state.repatriationOxygenValid"
    [title]="'medical-regular-assessment-repatriation-card-oxygen' | translate">
  </app-medical-assessment-card-title>
  <div class="assessment-card-content">

    <app-yes-no-question [header]="'repatriation-oxygen-in-flight' | translate" [disabled]="disabledViewOfMedicalAssessment"
      [(value)]="this.repatriationInstructions.patientNeeds.inFlightOxygenNeeded" (valueChange)="updateOxygen(oxygenInFlight, $event)">
    </app-yes-no-question>

    <div *ngIf="hasOxygen(RepatriationMediumEnum.IN_FLIGHT)">
      <div class="small-spacer"></div>

      <div class="half-size">
        <app-input [disabled]="disabledViewOfMedicalAssessment" [name]="'repatriation-oxygen-rate' | translate" [required]=true
          (valueChange)="updateOxygen(oxygenInFlight, true)" [(value)]="oxygenInFlight.rate"
          [inputTypePattern]="InputTypePattern.digitsOnly">
        </app-input>
        <div class="unit-text">{{'repatriation-oxygen-liters' | translate}}</div>
      </div>

      <div class="small-spacer"></div>

      <app-single-choice-component [(value)]="oxygenInFlight.availability" (valueChangeEvent)="updateOxygen(oxygenInFlight, true)"
        [disabled]="disabledViewOfMedicalAssessment" [items]="oxygenAvailabilityInFlight"
        [title]="'repatriation-oxygen-availability' | translate">
      </app-single-choice-component>

      <div class="spacer"></div>
    </div>

    <app-yes-no-question [header]="'repatriation-oxygen-on-ground' | translate" [disabled]="disabledViewOfMedicalAssessment"
      [(value)]="this.repatriationInstructions.patientNeeds.onGroundOxygenNeeded" (valueChange)="updateOxygen(oxygenOnGround, $event)">
    </app-yes-no-question>

    <div *ngIf="hasOxygen(RepatriationMediumEnum.ON_GROUND)">
      <div class="small-spacer"></div>
      <div class="half-size">
        <app-input [disabled]="disabledViewOfMedicalAssessment" [name]="'repatriation-oxygen-rate' | translate" [required]=true
          (valueChange)="updateOxygen(oxygenOnGround, true)" [(value)]="oxygenOnGround.rate"
          [inputTypePattern]="InputTypePattern.digitsOnly">
        </app-input>
        <div class="unit-text">{{'repatriation-oxygen-liters' | translate}}</div>
      </div>

      <div class="small-spacer"></div>

      <app-single-choice-component [(value)]="oxygenOnGround.availability" (valueChangeEvent)="updateOxygen(oxygenOnGround, true)"
        [disabled]="disabledViewOfMedicalAssessment" [items]="oxygenAvailabilityOnGround"
        [title]="'repatriation-oxygen-availability' | translate">
      </app-single-choice-component>

    </div>
  </div>
</div>

<div class="assessment-card" id="repatriationEscort">
  <app-medical-assessment-card-title [valid]="state.repatriationEscortValid"
    [title]="'medical-regular-assessment-repatriation-card-escort' | translate">
  </app-medical-assessment-card-title>
  <div class="assessment-card-content">

    <app-yes-no-question [header]="'repatriation-escorted-needed' | translate"
      [disabled]="state.escortDisabled || disabledViewOfMedicalAssessment" [(value)]="repatriationInstructions.patientNeeds.escortNeeded">
    </app-yes-no-question>

    <div *ngIf="state.escort">
      <div class="small-spacer"></div>

      <app-multiple-choice-component [items]="escortList" (valueChangeEvent)="updateEscort($event)" [deepValueChangeListener]="true"
        [title]="'repatriation-escort-type' | translate" [disabled]="disabledViewOfMedicalAssessment">
      </app-multiple-choice-component>

      <div *ngIf="hasEscort(EscortEnum.DOCTOR) && this.doctor">
        <div class="spacer"></div>

        <div class="escort-header">{{ 'repatriation-escort-doctor' | translate }}</div>

        <div class="half-size">
          <app-drop-down [(selectedItemId)]="doctor.specialty" (selectedItemIdChange)="updateEscortObject(this.doctor)" [items]="specializedList" [disabled]="disabledViewOfMedicalAssessment"
            [name]="'repatriation-escort-specialized' | translate" [requiredRedBackground]=true>
          </app-drop-down>
        </div>

        <div class="small-spacer"></div>

        <app-text-box [maxTextLength]="250" [name]="'repatriation-escort-describe-specialization-needed' | translate" [required]=false
          [(value)]="doctor.specializationDescription" (focusout)="updateEscortObject(this.doctor)" [disabled]="disabledViewOfMedicalAssessment">
        </app-text-box>

        <div class="small-spacer"></div>

        <app-text-box [disabled]="disabledViewOfMedicalAssessment" (focusout)="updateEscortObject(this.doctor)" [(value)]="doctor.taskDescription" [maxTextLength]="250"
          [name]="'repatriation-escort-task-describe' | translate" [required]=false>
        </app-text-box>

        <div class="spacer"></div>
      </div>

      <div *ngIf="hasEscort(EscortEnum.NURSE) && !!this.nurse">
        <div class="escort-header">{{ 'repatriation-escort-nurse' | translate }}</div>

        <div class="half-size">
          <app-drop-down [(selectedItemId)]="this.nurse.specialty" (selectedItemIdChange)="updateEscortObject(this.nurse)" [items]="specializedList" [disabled]="disabledViewOfMedicalAssessment"
            [name]="'repatriation-escort-specialized' | translate" [requiredRedBackground]=true>
          </app-drop-down>
        </div>

        <div class="small-spacer"></div>

        <app-text-box [maxTextLength]="250" [name]="'repatriation-escort-describe-specialization-needed' | translate" [required]=false
          [(value)]="nurse.specializationDescription" (focusout)="updateEscortObject(this.nurse)" [disabled]="disabledViewOfMedicalAssessment">
        </app-text-box>

        <div class="small-spacer"></div>

        <app-text-box [disabled]="disabledViewOfMedicalAssessment" [maxTextLength]="250"
          [name]="'repatriation-escort-task-describe' | translate" (focusout)="updateEscortObject(this.nurse)" [required]=false [(value)]="nurse.taskDescription">
        </app-text-box>
      </div>

      <div *ngIf="hasEscort(EscortEnum.NON_MEDICAL_ESCORT) && !!this.nonMedical">
        <div class="spacer"></div>

        <div class="escort-headder">{{ 'repatriation-escort-non-medical-escort' | translate }}</div>

        <app-text-box [disabled]="disabledViewOfMedicalAssessment" [maxTextLength]="250"
          [name]="'repatriation-escort-task-describe' | translate" [required]=true [(value)]="nonMedical.taskDescription">
        </app-text-box>

      </div>
    </div>
  </div>
</div>

<div class="assessment-card" *ngIf="state.equipmentMedication" id="repatriationEquipmentMedication">
  <app-medical-assessment-card-title [valid]="state.repatriationEquipmentMedicationValid"
    [title]="'medical-regular-assessment-repatriation-card-equipment-medication' | translate"></app-medical-assessment-card-title>
  <div class="assessment-card-content">

    <app-yes-no-question [header]="'repatriation-equipment-needed' | translate" [disabled]="disabledViewOfMedicalAssessment"
      [(value)]="repatriationInstructions.patientNeeds.equipmentNeeded">
    </app-yes-no-question>

    <div *ngIf="state.equipment">
      <div class="small-spacer"></div>

      <app-multiple-choice-component [disabled]="disabledViewOfMedicalAssessment" [items]="equipmentList"
        (valueChangeEvent)="updateEquipment($event)" [title]="'repatriation-equipment' | translate">
      </app-multiple-choice-component>

      <div class="small-spacer"></div>

      <app-single-choice-component [items]="defineList" [(value)]="repatriationInstructions.patientNeeds.equipmentTransportMediumType"
        [title]="'repatriation-equipment-define' | translate" [disabled]="disabledViewOfMedicalAssessment">
      </app-single-choice-component>

      <div class="small-spacer"></div>

      <app-text-box *ngIf="state.otherEquipment" [disabled]="disabledViewOfMedicalAssessment" [maxTextLength]="250"
        [name]="'repatriation-describe-equipment' | translate" [required]=true [(value)]="otherEquipmentDescription"
        (valueChange)="getOtherEquipment().setOtherItemDescription($event)">
      </app-text-box>
    </div>

    <div class="spacer"></div>

    <app-yes-no-question [header]="'repatriation-medication-needed' | translate" [disabled]="disabledViewOfMedicalAssessment"
      [(value)]="repatriationInstructions.patientNeeds.medicationNeeded">
    </app-yes-no-question>

    <div *ngIf="state.medication">
      <div class="small-spacer"></div>

      <app-multiple-choice-component [disabled]="disabledViewOfMedicalAssessment" [items]="medicationList"
        (valueChangeEvent)="updateMedication($event)" [title]="'repatriation-medication-kits' | translate">
      </app-multiple-choice-component>

      <div class="small-spacer"></div>

      <app-text-box *ngIf="state.otherMedication" [disabled]="disabledViewOfMedicalAssessment" [maxTextLength]="250"
        [name]="'repatriation-describe-medication' | translate" [required]=true [(value)]="otherMedicationDescription" [numberOfRows]="5"
        (valueChange)="getOtherMedication().setOtherItemDescription($event)">
      </app-text-box>
    </div>
  </div>
</div>

<div class="assessment-card" *ngIf="state.altFromShownIndex != 4" id="repatriationAlternativeForms">
  <app-medical-assessment-card-title [valid]="state.repatriationAlternativeFormsValid"
  [title]="'medical-regular-assessment-repatriation-card-expected-alternative-forms' | translate">
  </app-medical-assessment-card-title>
  <div class="assessment-card-content">
    <div *ngIf="state.altFromShownIndex <= 0">
      <app-yes-no-question [header]="getAlternativeFormQuestionString(repatriationInstructions.alternativeFormStretcher.repatriationForm)"
        [disabled]="disabledViewOfMedicalAssessment" [(value)]="repatriationInstructions.alternativeFormStretcher.formSelected">
      </app-yes-no-question>

      <div class="date-size-col" *ngIf="repatriationInstructions.alternativeFormStretcher.formSelected">
        <div class="small-spacer"></div>
        <app-input-calendar [withImage]="true" [name]="'repatriation-alternative-form-expected-possible-by' | translate"
          [date]="repatriationInstructions.alternativeFormStretcher.date" [disabled]="disabledViewOfMedicalAssessment"
          (dateChange)="updateAlternativeFormStretcherDate($event)" [required]="!repatriationInstructions.alternativeFormStretcher.date">
        </app-input-calendar>
      </div>
      <div class="spacer"></div>
    </div>

    <div *ngIf="state.altFromShownIndex <= 1">
      <app-yes-no-question [header]="getAlternativeFormQuestionString(repatriationInstructions.alternativeFormExtraSpace.repatriationForm)"
       [disabled]="disabledViewOfMedicalAssessment" [(value)]="repatriationInstructions.alternativeFormExtraSpace.formSelected">
      </app-yes-no-question>

      <div class="date-size-col" *ngIf="repatriationInstructions.alternativeFormExtraSpace.formSelected">
        <div class="small-spacer"></div>
        <app-input-calendar [withImage]="true" [name]="'repatriation-alternative-form-expected-possible-by' | translate"
          [date]="repatriationInstructions.alternativeFormExtraSpace.date" [disabled]="disabledViewOfMedicalAssessment"
          (dateChange)="updateAlternativeFormExtraSpaceDate($event)" [required]="!repatriationInstructions.alternativeFormExtraSpace.date">
        </app-input-calendar>
      </div>

      <div *ngIf="repatriationInstructions.alternativeFormExtraSpace.formSelected">
        <div class="small-spacer"></div>
          <div class="fixed-text-holder">
            <app-multiple-choice-component [disabled]="disabledViewOfMedicalAssessment" [items]="altFromExtraSpace" [extraSpace]=true
              (valueChangeEvent)="updateAltExtraSpace($event)" [title]="'repatriation-extra-space-type' | translate">
            </app-multiple-choice-component>
            <div class="fixed-text">
              {{ 'repatriation-minimum-height' | translate }}
            </div>
          </div>
          <div class="small-spacer"></div>

          <app-text-box *ngIf="!!getAltFormOtherExtraSpace()" [disabled]="disabledViewOfMedicalAssessment" [maxTextLength]="250" [numberOfRows]="5"
            [name]="'repatriation-describe-patient-need' | translate" [required]=true [(value)]="otherAlternativeExtraSpaceDescription"
            (valueChange)="getAltFormOtherExtraSpace().setOtherItemDescription($event)">
          </app-text-box>
      </div>

      <div class="spacer"></div>
    </div>

    <div *ngIf="state.altFromShownIndex <= 2">
      <app-yes-no-question [header]="getAlternativeFormQuestionString(repatriationInstructions.alternativeFormEconomyClass.repatriationForm)"
      [disabled]="disabledViewOfMedicalAssessment" [(value)]="repatriationInstructions.alternativeFormEconomyClass.formSelected">
      </app-yes-no-question>

      <div class="date-size-col" *ngIf="repatriationInstructions.alternativeFormEconomyClass.formSelected">
        <div class="small-spacer"></div>
        <app-input-calendar [withImage]="true" [name]="'repatriation-alternative-form-expected-possible-by' | translate"
          [date]="repatriationInstructions.alternativeFormEconomyClass.date" [disabled]="disabledViewOfMedicalAssessment"
          (dateChange)="updateAlternativeFormEconomyClassDate($event)" [required]="!repatriationInstructions.alternativeFormEconomyClass.date">
        </app-input-calendar>
      </div>
      <div class="spacer"></div>
    </div>

    <div *ngIf="state.altFromShownIndex <= 3">
      <app-yes-no-question [header]="getAlternativeFormQuestionString(repatriationInstructions.alternativeFormRegularPassenger.repatriationForm)"
      [disabled]="disabledViewOfMedicalAssessment" [(value)]="repatriationInstructions.alternativeFormRegularPassenger.formSelected">
      </app-yes-no-question>

      <div class="date-size-col" *ngIf="repatriationInstructions.alternativeFormRegularPassenger.formSelected">
        <div class="small-spacer"></div>
        <app-input-calendar [withImage]="true" [name]="'repatriation-alternative-form-expected-possible-by' | translate"
          [date]="repatriationInstructions.alternativeFormRegularPassenger.date" [disabled]="disabledViewOfMedicalAssessment"
          (dateChange)="updateAlternativeFormRegularPassengerDate($event)" [required]="!repatriationInstructions.alternativeFormRegularPassenger.date">
        </app-input-calendar>
      </div>
      <div class="spacer"></div>
    </div>
  </div>
</div>

<div class="assessment-card"id="repatriationMedicalHistory">
  <app-medical-assessment-card-title [valid]="state.repatriationMedicalHistoryValid"
    [title]="'medical-regular-assessment-repatriation-card-medical-history' | translate"></app-medical-assessment-card-title>
  <div class="assessment-card-content">
    <app-text-box [disabled]="disabledViewOfMedicalAssessment" [maxTextLength]="3000" [largeView]=true
      [name]="'repatriation-medical-history-for-external-purposes' | translate" [required]=true [numberOfRows]="10"
      [(value)]="repatriationInstructions.patientState.medicalHistoryDescription">
    </app-text-box>
  </div>
</div>
