import { PermissionEnum, PermissionHideTypeEnum, ShortcutEnum } from '@secca/shared/models/enums';
import { SupplierInvoiceService } from '@secca/core/services/supplier-invoice.service';
import { CaseEconomyTabEnum } from 'src/app/shared/enums/routing/case-economy-tab-enum';
import { ServiceOrderService } from '@secca/core/services/service-order.service';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Case } from 'src/app/shared/models/case';
import { SalesOrderService } from '@secca/core/services/sales-order.service';
import { RefundService } from '@secca/core/services/refund.service';
import { SalesOrder } from '@secca/shared/models/salesOrder';
import { CaseService } from 'src/app/core/services/case.service';
import { CaseEconomyTabService } from 'src/app/core/services/routing/case.economy.tab.service';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { map } from 'rxjs/operators';
import { PermissionService } from '@secca/core/services/permission.service';
import { DataService } from '@secca/core/services/data.service';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { ShortcutService } from '@secca/core/services/shortcut.service';
import { forkJoin, Subscription } from 'rxjs';

@Component({
  selector: 'app-case-economy',
  templateUrl: './case-economy.component.html',
  styleUrls: ['./case-economy.component.scss'],
})
@AutoUnsubscribe
export class CaseEconomyComponent implements OnInit {
  salesOrders: SalesOrder[];
  caseEconomyTabEnum = CaseEconomyTabEnum;

  $supplierInvoiceApprovedSubscr;

  @ViewChild('tabSupplierInvoicesElement') private tabSupplierInvoicesElement: ElementRef;

  @ViewChild('caseEconomyTabs', { static: true }) caseEconomyTabs: NgbNav;
  active = CaseEconomyTabEnum.SERVICE_ORDERS;
  @Input()
  set case(newCase: Case) {
    this.theCase = newCase;
    if (this.theCase) {
      this.onRefresh();
    }
  }
  get case(): Case {
    return this.theCase;
  }
  @Input() newCase: boolean;
  private theCase: Case;

  $shortcutSubscription: Subscription;
  $salesOrdersSubscr: Subscription;

  constructor(
    private caseEconomyTabService: CaseEconomyTabService,
    private salesOrderService: SalesOrderService,
    private serviceOrderService: ServiceOrderService,
    private caseService: CaseService,
    private supplierInvoiceService: SupplierInvoiceService,
    private dataService: DataService,
    private permissionService: PermissionService,
    private refundService: RefundService,
    private shortcutService: ShortcutService,
  ) {
    this.$shortcutSubscription = this.shortcutService.addShortcut({ keys: ShortcutEnum.CaseEconomyRefundsAndSupplierInvoices }).subscribe(a => {
      this.tabSupplierInvoicesElement.nativeElement.click();
    });
  }

  ngOnInit(): void {
    this.$supplierInvoiceApprovedSubscr = this.supplierInvoiceService.getSupplierInvoiceApproved().subscribe(_ => this.fetchSalesOrders());
  }

  private onRefresh() {
    this.salesOrders = [];
    this.fetchSalesOrders();
    if ( this.$salesOrdersSubscr ) {
      this.$salesOrdersSubscr.unsubscribe();
    }
    this.$salesOrdersSubscr = this.salesOrderService.getSalesOrders().subscribe(result => {
      this.salesOrders = result;
    });

    if (this.permissionService.checkUserPermission(PermissionEnum.PLANS_READ)) {
      this.serviceOrderService
        .getServiceOrdersForCase(Number(this.theCase.id))
        .subscribe(result => this.serviceOrderService.sendCaseServiceOrders(result));
    }
    this.caseService
      .getCaseStakeholdersOnCase(this.theCase.id)
      .subscribe(stakeholders => this.caseService.sendCaseStakeholderWithCaseStakeholder(stakeholders));
    this.fetchSupplierInvoices();
    this.fetchRefunds();
    if (this.dataService.navigateSupplierInvoiceId || this.dataService.navigateRefundId) {
      this.active = CaseEconomyTabEnum.SUPPLIER_INVOICES;
    }
    if (this.dataService.navigateSalesOrderId) {
      this.active = CaseEconomyTabEnum.CUSTOMER_INVOICES;
      this.dataService.navigateSalesOrderId = null;
    }
    if (this.dataService.navigateRecoveryId) {
      this.active = CaseEconomyTabEnum.RECOVERY;
    }
    if (this.dataService.navigateRecoveryPaymentId) {
      this.active = CaseEconomyTabEnum.RECOVERY_PAYMENT;
    }
  }

  private fetchSalesOrders() {
    if (this.permissionService.checkUserPermission(PermissionEnum.SALES_ORDER_READ)) {
      this.salesOrderService.getSalesOrdersByCaseId(this.theCase.id).subscribe(caseSalesOrders => {
        this.salesOrderService.sendSalesOrders(caseSalesOrders);
      });
    }
  }
  private fetchSupplierInvoices() {
    if (this.permissionService.checkUserPermission(PermissionEnum.SUPPLIER_INVOICE_READ)) {
      this.supplierInvoiceService.getSupplierInvoicesByCase(Number(this.theCase.id)).subscribe(supplierInvoices => {
        this.supplierInvoiceService.sendCaseSupplierInvoices(supplierInvoices);
        const progressObservables = supplierInvoices.map(supplierInvoice => this.supplierInvoiceService.getSupplierInvoiceHistory(supplierInvoice.id).pipe(map(progress => supplierInvoice.progress = progress)));
        forkJoin(progressObservables).subscribe(() => {});
      });
    }
  }

  private fetchRefunds() {
    if (this.permissionService.checkUserPermission(PermissionEnum.REFUND_READ)) {
      this.refundService.getRefundByCaseId(Number(this.theCase.id)).subscribe(refunds => {
        this.refundService.sendCaseRefunds(refunds);
        const progressObservable = refunds.map(refund => this.refundService.getRefundHistory(refund.id).pipe(map(progress => refund.progress = progress)));
        forkJoin(progressObservable).subscribe(() => {});
      });
    }
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  get PermissionHideTypeEnum() {
    return PermissionHideTypeEnum;
  }

  tabClicked(clickedTab: CaseEconomyTabEnum) {
    switch (clickedTab) {
      case CaseEconomyTabEnum.SERVICE_ORDERS:
      case CaseEconomyTabEnum.SUPPLIER_INVOICES:
        this.fetchSupplierInvoices();
        break;
      case CaseEconomyTabEnum.SOS_SERVICES:
      case CaseEconomyTabEnum.CUSTOMER_INVOICES:
        this.fetchSalesOrders();
        break;
      case CaseEconomyTabEnum.PURCHASE_AND_SALES:
        this.fetchSalesOrders();
        this.fetchSupplierInvoices();
        break;
      default:
    }
  }
}
