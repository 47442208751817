<div class="medical-assessment-menu-container">
  <div class="medical-assessment-menu">
    <div class="medical-assessment-menu-header">{{"medical-regular-assessment-menu-basis-assessment" | translate}}
    </div>
    <div class="medical-assessment-menu-item" (click)="scrollToElement('basisAssessment')"> {{"medical-regular-assessment-basic-for-assessment" | translate}}
      <div class="check-mark" *ngIf="state.regularAssessment">
        <img src="{{checkMark(state.basisValid)}}">
      </div>
    </div>
    <div class="medical-assessment-menu-item" (click)="scrollToElement('assessmentNote')"> {{"medical-regular-assessment-medical-notes" | translate}}
      <div class="check-mark" *ngIf="state.regularAssessment">
        <img src="{{checkMark(state.noteValid)}}">
      </div>
    </div>
    <div  class="medical-assessment-menu-item" *ngIf="state.regularAssessment" (click)="scrollToElement('treatmentPrognosis')">
      {{"medical-regular-assessment-treatment-prognosis" | translate}}
      <div class="check-mark">
        <img src="{{checkMark(state.treatmentPrognosisValid)}}">
      </div>
    </div>
    <div class="medical-assessment-menu-item" *ngIf="state.regularAssessment" (click)="scrollToElement('examinationTreatments')">
      {{"medical-regular-assessment-examination-treatments" | translate}}
    </div>
    <div class="medical-assessment-menu-item" *ngIf="state.regularAssessment" (click)="scrollToElement('medicalEvacuation')">
      {{"medical-regular-assessment-medical-evacuation" | translate}}
      <div class="check-mark">
        <img src="{{checkMark(state.medEvacValid)}}">
      </div>
    </div>
    <div class="medical-assessment-menu-item" *ngIf="state.regularAssessment" (click)="scrollToElement('repatriationStatus')"> {{"medical-regular-assessment-repatriation-status" | translate}}
      <div class="check-mark">
        <img src="{{checkMark(state.repatriationStatusValid)}}">
      </div>
    </div>

    <div *ngIf="state.repatriationPrognosis">
      <div class="medical-assessment-menu-header"> {{"medical-regular-assessment-menu-repatriation-prognosis" | translate}}
      </div>
      <div class="medical-assessment-menu-item" (click)="scrollToElement('repatriationPrognosis')">
        {{"medical-regular-assessment-repatriation-prognosis" | translate}}
        <div class="check-mark">
          <img src="{{checkMark(state.repatriationPrognosisValid)}}">
        </div>
      </div>
    </div>

    <div *ngIf="state.repatriationInstructionsAllowed">
      <div class="medical-assessment-menu-header"> {{"medical-regular-assessment-menu-repatriation-instructions" | translate}}
      </div>
      <div class="medical-assessment-menu-item" (click)="scrollToElement('repatriationGeneral')">{{'medical-regular-assessment-repatriation-menu-general-info' | translate}}
        <div class="check-mark">
          <img src="{{checkMark(state.repatriationGeneralValid)}}">
        </div>
      </div>
      <div class="medical-assessment-menu-item" (click)="scrollToElement('repatriationForm')">
        {{"medical-regular-assessment-repatriation-menu-form" | translate}}
        <div class="check-mark">
          <img src="{{checkMark(state.repatriationFormValid)}}">
        </div>
      </div>
      <div class="medical-assessment-menu-item" *ngIf="state.intensiveCarePatient" (click)="scrollToElement('repatriationIntensiveCare')">
        {{"medical-regular-assessment-repatriation-menu-intensive-care-patient" | translate}}
        <div class="check-mark">
          <img src="{{checkMark(state.repatriationIntensiveCareValid)}}">
        </div>
      </div>
      <div class="medical-assessment-menu-item" (click)="scrollToElement('vitalSigns')">
        {{"medical-regular-assessment-repatriation-menu-vital-signs" | translate}}
      </div>
      <div class="medical-assessment-menu-item" (click)="scrollToElement('repatriationWheelchair')">
        {{"medical-regular-assessment-repatriation-menu-wheelchair" | translate}}
        <div class="check-mark">
          <img src="{{checkMark(state.repatriationWheelchairValid)}}">
        </div>
      </div>
      <div class="medical-assessment-menu-item" (click)="scrollToElement('repatriationTransitAdmission')">
        {{"medical-regular-assessment-repatriation-menu-transit-and-admission" | translate}}
        <div class="check-mark">
          <img src="{{checkMark(state.repatriationTransitAdmissionValid)}}">
        </div>
      </div>
      <div class="medical-assessment-menu-item" (click)="scrollToElement('repatriationCast')">
        {{"medical-regular-assessment-repatriation-menu-cast" | translate}}
        <div class="check-mark">
          <img src="{{checkMark(state.repatriationCastValid)}}">
        </div>
      </div>
      <div class="medical-assessment-menu-item" (click)="scrollToElement('repatriationOxygen')">
        {{"medical-regular-assessment-repatriation-menu-oxygen" | translate}}
        <div class="check-mark">
          <img src="{{checkMark(state.repatriationOxygenValid)}}">
        </div>
      </div>
      <div class="medical-assessment-menu-item" (click)="scrollToElement('repatriationEscort')">
        {{"medical-regular-assessment-repatriation-menu-escort" | translate}}
        <div class="check-mark">
          <img src="{{checkMark(state.repatriationEscortValid)}}">
        </div>
      </div>
      <div class="medical-assessment-menu-item" *ngIf="state.equipmentMedication" (click)="scrollToElement('repatriationEquipmentMedication')">
        {{"medical-regular-assessment-repatriation-menu-equipment-medication" | translate}}
        <div class="check-mark">
          <img src="{{checkMark(state.repatriationEquipmentMedicationValid)}}">
        </div>
      </div>
      <div class="medical-assessment-menu-item" *ngIf="state.altFromShownIndex != 4" (click)="scrollToElement('repatriationAlternativeForms')">
        {{"medical-regular-assessment-repatriation-menu-expected-alternative-forms" | translate}}
        <div class="check-mark">
          <img src="{{checkMark(state.repatriationAlternativeFormsValid && formValid)}}">
        </div>
      </div>
      <div class="medical-assessment-menu-item" (click)="scrollToElement('repatriationMedicalHistory')">
        {{"medical-regular-assessment-repatriation-menu-medical-history" | translate}}
        <div class="check-mark">
          <img src="{{checkMark(state.repatriationMedicalHistoryValid)}}">
        </div>
      </div>
    </div>

    <div class="medical-assessment-menu-header" *ngIf="state.regularAssessment"> {{"medical-regular-assessment-menu-next-assessment" | translate}}
    </div>
    <div (click)="scrollToElement('nextAssessment')" class="medical-assessment-menu-item" *ngIf="state.regularAssessment"> {{"medical-assessment-next-assessment" | translate}}
      <div class="check-mark">
        <img src="{{checkMark(state.nextAssessmentValid)}}">
      </div>
    </div>
  </div>
</div>
