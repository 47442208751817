<div class="case-basics-card case-basics-otherinsurance-details-container" *ngIf="otherInsurance">
  <div [appDisableInput]="caseStateService.isCaseDisabled()">
  <div class="field-row"
       [class.required]="otherInsurance.hasOtherInsurance == null && personInsurance?.customerProfileId == requiredProfileId"
       [class.locked]="otherInsurance.creditCardBank || otherInsurance.insuranceCompany"
  >
    <div class="col-12 radio-title">{{ 'has-other-insurance-title' | translate }}</div>
    <div class="col-2">
    <div class="custom-radio-button" (click)="setOtherInsurance(HasOtherInsurance.YES)">
      <input
        type="checkbox"
        [checked]="otherInsurance.hasOtherInsurance == HasOtherInsurance.YES"
         />
      <span class="checkmark"></span>
      <div class="radio-button-text">{{ 'has-other-insurance-yes' | translate }}</div>
    </div>
    </div>
    <div class="col-2">
      <div class="custom-radio-button" (click)="setOtherInsurance(HasOtherInsurance.NO)">
      <input
        type="checkbox"
        [checked]="otherInsurance.hasOtherInsurance == HasOtherInsurance.NO"
        (click)="saveModel()" />
      <span class="checkmark"></span>
      <div class="radio-button-text">{{ 'has-other-insurance-no' | translate }}</div>
    </div>
    </div>
    <div class="col-5">
      <div class="custom-radio-button" (click)="setOtherInsurance(HasOtherInsurance.NOT_SURE)">
      <input
        type="checkbox"
        [checked]="otherInsurance.hasOtherInsurance == HasOtherInsurance.NOT_SURE"
        (click)="saveModel()" />
      <span class="checkmark"></span>
      <div class="radio-button-text">{{ 'has-other-insurance-not-sure' | translate }}</div>
    </div>
    </div>
    <div class="col-3">
      <div class="custom-radio-button" (click)="setOtherInsurance(HasOtherInsurance.NOT_ASKED)">
      <input
        type="checkbox"
        [checked]="otherInsurance.hasOtherInsurance == HasOtherInsurance.NOT_ASKED"
        (click)="saveModel()" />
      <span class="checkmark"></span>
      <div class="radio-button-text">{{ 'has-other-insurance-not-asked' | translate }}</div>
    </div>
    </div>
  </div>
  <div class="separator"></div>
  <header>
    {{ 'case-basics-otherInsurance-creditCard' | translate }}
  </header>
  <div class="field-row">
    <div class="col-6">
      <app-input [name]="'case-basics-otherInsurance-bank' | translate" (saveModel)="saveModel()"
        [(value)]="otherInsurance.creditCardBank"></app-input>
    </div>
    <div class="col-6">
      <app-input-credit-card [name]="'case-basics-otherInsurance-creditCardNo' | translate" (saveModel)="saveModel()"
        [(value)]="otherInsurance.creditCardNo" [validate]="true"></app-input-credit-card>
    </div>
  </div>
  <div class="field-row">
    <div class="col-6">
      <app-input [name]="'case-basics-otherInsurance-creditcardType' | translate" (saveModel)="saveModel()"
        [(value)]="otherInsurance.creditCardType"></app-input>
    </div>
    <div class="col-6">
      <app-input [name]="'case-basics-otherInsurance-remarks' | translate" (saveModel)="saveModel()"
        [(value)]="otherInsurance.creditCardRemarks"></app-input>
    </div>
  </div>
  <div class="separator"></div>
  <header>
    {{ 'case-basics-otherInsurance-insurance' | translate }}
  </header>
  <div class="field-row">
    <div class="col-6">
      <app-input [name]="'case-basics-otherInsurance-insuranceCompany' | translate" (saveModel)="saveModel()"
        [(value)]="otherInsurance.insuranceCompany">
      </app-input>
    </div>
    <div class="col-6">
      <app-input [name]="'case-basics-otherInsurance-policyNo' | translate" (saveModel)="saveModel()"
        [(value)]="otherInsurance.insurancePolicyNo"></app-input>
    </div>
  </div>
  <div class="field-row">
    <div class="col-6">
      <app-input [name]="'case-basics-otherInsurance-insuranceProduct' | translate" (saveModel)="saveModel()"
        [(value)]="otherInsurance.insuranceProduct">
      </app-input>
    </div>
    <div class="col-6">
      <app-input [name]="'case-basics-otherInsurance-remarks' | translate" (saveModel)="saveModel()"
        [(value)]="otherInsurance.insuranceRemarks"></app-input>
    </div>
  </div>
  </div>
</div>
