import {AttachmentDto} from './../../../../../shared/models/attachment-dto';
import {Message, MessageUtilService} from './message';
import {Adapter} from 'src/app/shared/interfaces/adapter';
import {Injectable} from '@angular/core';
import {ContentType} from './../../../../../shared/models/enums';
import {StakeholderCcDto} from '../components/message-dialog/message-subject/message-form-cc/stakeholder-cc-dto';
import {PostHandlingsDto} from '@secca/shared/models/post-handlings-dto';

export class SendMailRequestDto {
  caseId: string;
  subject: string;
  body: string;
  contentType: ContentType;
  recipientEmails: string[];
  attachments: AttachmentDto[];
  internalSummary: string;
  toStakeholderType: string;
  toStakeholderName: string;
  ccStakeholderIds: string[];
  ccRecipientEmails: string[];
  tags: string[];
  from: string;
  stakeholderReceiverId: string;
  postHandlings: PostHandlingsDto;
  templateId: string;

  public constructor(init?: Partial<SendMailRequestDto>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
export class SendMailRequestDtoAdapter implements Adapter<SendMailRequestDto> {

  constructor(private messageUtilService: MessageUtilService) {
  }

  adapt(message: Message): SendMailRequestDto {
    const cc: StakeholderCcDto[] = this.messageUtilService.getStakeholderCcDtoItems(message);
    let messageSubject: string;
    // if the subject contains the case number, for instance when replying, there is no need to add it one more time
    if (message.subject != null && message.subject.includes(message.caseNumber.replace('[', '').replace(']', ''))) {
      messageSubject = message.subject;
    } else {
      messageSubject = message.caseNumber + (message.subject != null ? message.subject : '');
    }
    return new SendMailRequestDto({
      caseId: message.caseId,
      subject: messageSubject,
      body: message.text,
      contentType: ContentType.html,
      recipientEmails: this.mapToEmailAddresses(message.email),
      attachments: message.attachments,
      internalSummary: message.internalSummary,
      toStakeholderType: message.receiver.stakeholderType,
      toStakeholderName: message.receiver.company
        ? message.receiver.company.name
        : message.receiver.person.firstName + ' ' + message.receiver.person.surname,
      ccStakeholderIds: this.mapCcToIds(cc),
      tags: message.tags,
      ccRecipientEmails: this.mapCcToEmailsAddress(cc),
      stakeholderReceiverId: message.receiver.id,
      from: message.messageFrom,
      postHandlings: message.postHandlings,
      templateId: message.templateId
    });
  }

  private mapToEmailAddresses(receiver: string): string[] {
    return [receiver];
  }

  private mapCcToEmailsAddress(cc: StakeholderCcDto[]): string[] {
    if (cc != null && cc.length > 0) {
      return cc.map(item => item.address);
    }
    return [];
  }

  private mapCcToIds(cc: StakeholderCcDto[]): string[] {
    if (cc != null && cc.length > 0) {
      return cc.map(item => item.id);
    }
    return [];
  }
}
