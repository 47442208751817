import { HandlingArea } from '@secca/shared/models/handlingArea';
import { HandlingAreaAdapter } from './../../shared/models/handlingArea';
import { SettingsService } from './settings.service';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HandlingAreaService extends BaseService {
  constructor(private http: HttpClient, private handlingAreaAdapter: HandlingAreaAdapter, private settingsService: SettingsService) {
    super(settingsService);
  }

  private caseHandlingsAreas = new BehaviorSubject<HandlingArea[]>(undefined);
  public setCaseHandlingsAreas(caseHandlingsAreas: HandlingArea[]) {
    this.caseHandlingsAreas.next(caseHandlingsAreas);
  }
  public getCaseHandlingsAreas(): Observable<HandlingArea[]> {
    return this.caseHandlingsAreas.asObservable();
  }

  public saveHandlingArea(caseId: number, handlingArea: HandlingArea): Observable<HandlingArea[]> {
    return this.http
      .post(this.baseURL + 'handling-area/' + caseId, handlingArea, {
        headers: this.jsonHeaders,
      })
      .pipe(map((data: any[]) => data.map(item => this.handlingAreaAdapter.adapt(item))));
  }

  public getHandlingsAreas(caseId: number, includeCloseValidations=false): Observable<HandlingArea[]> {
    
    let params = includeCloseValidations ? "?includeCloseValidations=true" : "?includeCloseValidations=false";
    return this.http
      .get(this.baseURL + 'handling-area/' + caseId + params, {
        headers: this.jsonHeaders,
      })
      .pipe(map((data: any[]) => data.map(item => this.handlingAreaAdapter.adapt(item))));
  }

  public createHandlingAreaForWebCase(caseId: number): Observable<HandlingArea[]> {
    return this.http
      .post(this.baseURL + `handling-area/web/${caseId}`, {
        headers: this.jsonHeaders,
      })
      .pipe(map((data: any[]) => data.map(item => this.handlingAreaAdapter.adapt(item))));
  }
}
