import {AutoUnsubscribe} from 'src/app/shared/decorators/auto-unsubscribe';
import {FaxDto} from './../../../../../../../shared/models/fax-dto';
import {DropdownDictionary} from './../../../../../../../shared/models/dropdownDictionary';
import {MessageTemplate} from './../../../../../../../shared/models/message-template';
import {FeatureEnum, MessageChannelType, MessageFrom, PhoneNumberType, UserTeam} from 'src/app/shared/models/enums';
import {CaseStakeholder} from '@secca/shared/models/caseStakeholder';
import {OutputManagementService} from './../../../services/output-management.service';
import {CaseService} from './../../../../../../../core/services/case.service';
import {StakeholderService} from '@secca/core/services/stakeholder.service';
import {Message} from './../../../models/message';
import {Data} from 'src/app/core/mocks/data';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {PhoneDto} from 'src/app/shared/models/phone-dto';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ModalDialogComponent} from '@secca/shared/components/modal-dialog/modal-dialog.component';
import {ModalDialogConfiguration} from '@secca/shared/models/modal/modal-dialog-configuration';
import {CaseCommunicationService} from '@secca/core/services/case-communication.service';
import {MessageFromAddress} from '@secca/shared/models/message-from-address';
import {Subscription} from 'rxjs';
import {DictionaryService} from '@secca/core/services/dictionary.service';
import {UserDto} from '@secca/shared/models/userDto';
import {PostHandlingsDto} from "@secca/shared/models/post-handlings-dto";

@AutoUnsubscribe
@Component({
  selector: 'app-message-form',
  templateUrl: './message-form.component.html',
  styleUrls: ['./message-form.component.scss'],
})
export class MessageFormComponent implements OnInit {
  @Input() takeStakeholderFromGopContact: boolean;
  @Input() stakeholders: CaseStakeholder[];
  @Input()
  public set message(message: Message) {
    this._message = message;
    this.addressOverride = message.messageFrom !== undefined;
    this.setTemplateLanguages();
  }
  public get message(): Message {
    return this._message;
  }


  private _message: Message;

  @Input()
  set messageTemplates(inputMessageTemplates: MessageTemplate[]) {
    this._messageTemplates = inputMessageTemplates;
    this.filterOutConsentTemplatesIfNecessary();
    this.convertTemplatesIntoDropdown();

    this.setDigitalConsentTemplate();
  }

  get messageTemplates() {
    return this._messageTemplates;
  }

  private oldSelectedReceiver: CaseStakeholder;

 @Input()
  set hideConsentTemplates(should: boolean) {
    this.$hideConsentTemplates = should;
    this.filterOutConsentTemplatesIfNecessary();
    if (this._messageTemplates != null) {
      this.convertTemplatesIntoDropdown();
    }
  }

  private filterOutConsentTemplatesIfNecessary() {
    if (this.$hideConsentTemplates === true && this._messageTemplates != null ) {
      this._messageTemplates = this._messageTemplates.filter(template => template.templateType !== 'CONSENT' && template.templateType !== 'DIGITAL_CONSENT');
    }
  }

  private convertTemplatesIntoDropdown() {
    this.mappedMessageTemplates = this._messageTemplates.map(item => new DropdownDictionary(item.id, item.displayName));
    this.setMappedMessageTemplatesUnique();
    this.setTemplateLanguages();
  }

  uniqueFilteredMessageTemplates: MessageTemplate[] = [];
  private _messageTemplates: MessageTemplate[];
  mappedMessageTemplates: DropdownDictionary[] = [];
  languages: DropdownDictionary[] = [];
  @Output() messageTemplateHasChanged: EventEmitter<string> = new EventEmitter(undefined);
  @Output() messageLanguageHasChanged: EventEmitter<string> = new EventEmitter(undefined);
  @Output() messageChannelTypeHasChanged: EventEmitter<string> = new EventEmitter(undefined);
  @Output() messageUpdated: EventEmitter<Message> = new EventEmitter<Message>();

  $subscrUpdateStakeholderInformation;
  messageChannelTypes: DropdownDictionary[];
  $hideConsentTemplates = false;
  messageFromAddresses: MessageFromAddress[];
  loggedInUser: UserDto;
  $loggedInUserSubscr: Subscription;
  emailFieldDisabled: boolean;
  addressOverride: boolean;
  emailFeature: string;

  constructor(
    private caseService: CaseService,
    private outputManagementService: OutputManagementService,
    private stakeholderService: StakeholderService,
    private caseCommunicationService: CaseCommunicationService,
    private dictionaryService: DictionaryService,
    private modalService: NgbModal,
  ) {
    this.$hideConsentTemplates = false;

    this.$loggedInUserSubscr = dictionaryService.getLoggedInUser().subscribe(user => {
      this.loggedInUser = user;
    });
  }

  ngOnInit() {
    this.messageChannelTypes = Data.getMessageChannelTypes();
    this.refreshFromAddresses(FeatureEnum.DEFAULT);
  }

  private refreshFromAddresses(feature : string) {

    this.caseCommunicationService.getEmailAddressesByFeature(feature).subscribe(response => {
      this.messageFromAddresses = response;
      this.emailFeature = feature;
      this.emailFieldDisabled = false;

      if(!this.addressOverride){
        if (this.message.messageChannelType !== MessageChannelType.EMAIL){
          this.emailFieldDisabled = true;
        } else if (this.message.messageChannelType === MessageChannelType.EMAIL && feature === FeatureEnum.DEFAULT) {
          this.message.messageFrom = this.loggedInUser?.userTeam?.adtCode === UserTeam.costControl ? MessageFrom.EMAIL_SENDER_ADM : MessageFrom.EMAIL_SENDER_SOS;
        } else if (this.message.messageChannelType === MessageChannelType.EMAIL && (feature == FeatureEnum.RECOVERY_POA_REQUEST || feature == FeatureEnum.RECOVERY_FIRST_REMINDER || feature == FeatureEnum.RECOVERY_SECOND_REMINDER)){
          this.message.messageFrom = MessageFrom.EMAIL_SENDER_ATTORNEY;
          this.emailFieldDisabled = true;
        } else if (this.message.messageChannelType === MessageChannelType.EMAIL && feature == FeatureEnum.RECOVERY_APPLICATION){
          this.message.messageFrom = MessageFrom.EMAIL_SENDER_RECOVERY;
          this.emailFieldDisabled = true;
        }
      }
    });
  }

  private setMappedMessageTemplatesUnique() {
    let uniqueDisplayNames = [...new Set(this.mappedMessageTemplates.map(item => item.name))];
    this.mappedMessageTemplates = this.mappedMessageTemplates.filter(message =>
      this.filterByUniqueDisplayName(message, uniqueDisplayNames)
    );
  }

  private filterByUniqueDisplayName(dropDownDictionary: DropdownDictionary, uniqueDisplayNames: string[]): boolean {
    let index = uniqueDisplayNames.indexOf(dropDownDictionary.name);
    if (index >= 0) {
      uniqueDisplayNames.splice(index, 1);
      return true;
    } else {
      return false;
    }
  }
  setTemplateLanguagesPreferredLanguage(templateId: any = null) {
    this.setTemplateLanguages();
    this.setPreferredLanguage();

    if (templateId != null) {
      let featureType = this.messageTemplates.find(a => a.id === templateId).featureType
      if (!this.message.postHandlings) {
        this.message.postHandlings = new PostHandlingsDto({
          feature: featureType
        });
      } else {
        this.message.postHandlings.feature = featureType;
      }
      if ( featureType != this.emailFeature ) {
        this.refreshFromAddresses(featureType === null ? FeatureEnum.DEFAULT : featureType);
      }
      this.message.templateDisplayName = this.messageTemplates.find(a => a.id === templateId).displayName;
      this.message.tags = this.messageTemplates.find(a => a.id === templateId).tags;
      const localizedTemplateId = this.messageTemplates.find(a => a.displayName === this.message.templateDisplayName && a.languageCode === this.message.language)?.id;
      this.messageTemplateHasChanged.emit(localizedTemplateId);
    }
  }

  setTemplateMessageLanguage() {
    this.messageLanguageHasChanged.emit(this.message.language);
  }

  private setPreferredLanguage() {
    const preferredLanguage = this.languages.find(
      (item, idx) => {
        const stakeholderPreferredLanguage = this.stakeholderService.getPreferredLanguageForStakeholder(this.message.receiver);
        if (stakeholderPreferredLanguage && stakeholderPreferredLanguage.code) {
          return item.id.toString().toUpperCase() === stakeholderPreferredLanguage.code.toUpperCase();
        }
        return false;
      }
    );
    const displayNameFromTemplate = this.messageTemplates.find(a => a.id === this.message.templateId).displayName;
    if (preferredLanguage) {
      const template = this.messageTemplates.find(a => a.displayName === displayNameFromTemplate && a.languageCode === preferredLanguage.name);
      if (template){
        this.message.language = preferredLanguage.name;
        return;
      }
    }
  }

  setTemplateLanguages() {
    if (this._messageTemplates != null && this.message?.templateId) {
      const messageTemplate = this._messageTemplates.find(t => t.id === this.message.templateId);
      if (messageTemplate == null) {
        return;
      }
      const templatesInDifferentLanguage = this._messageTemplates.filter(t => t.displayName === messageTemplate?.displayName);
      this.languages = templatesInDifferentLanguage.map(t => new DropdownDictionary(t.languageCode, t.languageCode));
      if (this.languages.length === 1){
        this.message.language = this.languages[0].name;
      }
      this.languages.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (b.name < a.name) {
          return 1;
        }
        return 0;
      });
    }
  }

  updateStakeholderInformation(stakeholder: CaseStakeholder) {
    if ( stakeholder != null ) {
      this.updateMessageStakeholder(stakeholder);
    }
  }

  updateMessageStakeholderFromDropDown(stakeholder: CaseStakeholder) {
    if (!this.message.afterMaximazingKeepTheLanguageIntact) {
      this.message.language = null;
    }
    this.updateMessageStakeholder(stakeholder);
  }

  private showWarningMessageDialog() : NgbModalRef {
    const confirmCloseDialog = this.modalService.open(ModalDialogComponent);
    confirmCloseDialog.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'default-modal-header',
      title: '',
      text: '',
      footer: 'output-management-warning-footer',
      yes: 'default-modal-dialog-yes',
      no: 'default-modal-dialog-no',
      isBody: false,
      isFooter: true,
    });
    return confirmCloseDialog;
  }

  private handleMessageStakeholderChange(stakeholder: CaseStakeholder) {
    this.oldSelectedReceiver = stakeholder;
    this.formatChannel(this.message, stakeholder);
    this.message.template = null;
    this.message.language = null;
    this.message.templateId = null;
    this.message.text = null;
    this.updateMessage();
  }

  private updateMessageStakeholder(stakeholder: CaseStakeholder) {
    if (this.message?.text?.length > 0) {
      let showWarningMessageDialog = this.showWarningMessageDialog();
        showWarningMessageDialog.componentInstance.closeModalEvent.subscribe(emittedEvent => {
          if (emittedEvent) {
            this.handleMessageStakeholderChange(stakeholder);
          } else {
            this.message.receiver = this.oldSelectedReceiver;
          }
          showWarningMessageDialog.close();
        });
    } else {
      this.handleMessageStakeholderChange(stakeholder);
    }
  }

  updateMessageSmsCountry(country: string) {
    this.message.sms.countryCode = country;
  }

  private updateMessageSms(event) {
    this.message.sms.phonePrefix = event.phonePrefix;
    this.message.sms.phoneNumber = event.phoneNumber;
    this.message.sms.countryCode = event.phoneCountryCode;
  }

  private updateMessageFax(event) {
    this.message.fax.faxPrefix = event.phonePrefix;
    this.message.fax.faxNumber = event.phoneNumber;
    this.message.fax.countryCode = event.phoneCountryCode;
  }

  private updateMessageChannelType(channelType: MessageChannelType) {
    this.messageChannelTypeHasChanged.emit(channelType);
    this.message.messageChannelType = channelType;
    const message = this.createClearedMessageWithInternalSummary(channelType, this.message.internalSummary);
    this.messageUpdated.emit(message);
  }

  private createClearedMessageWithInternalSummary(channelType: MessageChannelType, internalSummary: string): Message {
    const message = new Message();
    message.caseId = this.message.caseId;
    message.internalSummary = internalSummary;
    message.messageChannelType = channelType;
    message.messageFrom = (channelType === MessageChannelType.EMAIL ) && this.messageFromAddresses ? MessageFrom.EMAIL_SENDER_SOS : null;
    message.receiver = this.message.receiver;
    message.sms = new PhoneDto();
    message.fax = new FaxDto();
    message.caseNumber = this.message.caseNumber;
    message.legalGuardianOnly = this.message.legalGuardianOnly;
    message.templateType = this.message.templateType;
    this.formatChannel(message, this.message.receiver);
    return message;
  }

  private updateMessage() {
    this.messageUpdated.emit(this.message);
  }

  private formatChannel(message: Message, stakeholder: CaseStakeholder) {
    if (!stakeholder) {
      message.sms.countryCode = null;
      message.fax.countryCode = null;
      return;
    }
    switch (message.messageChannelType) {
      case MessageChannelType.EMAIL:
        message.email = stakeholder.isCompany ? stakeholder.company.email : stakeholder.person.email;
        break;
      case MessageChannelType.SMS:
        message.sms.phoneNumber = this.stakeholderService.getPhoneNumber(PhoneNumberType.Mobile, stakeholder.phoneNumbers).number;
        message.sms.countryCode = this.stakeholderService.getPhoneNumber(PhoneNumberType.Mobile, stakeholder.phoneNumbers).countryCode;
        message.sms.phonePrefix = this.stakeholderService.getPhoneNumber(PhoneNumberType.Mobile, stakeholder.phoneNumbers).prefix;
        break;
      case MessageChannelType.FAX:
        message.fax.faxNumber = this.stakeholderService.getPhoneNumber(PhoneNumberType.Fax, stakeholder.phoneNumbers).number;
        message.fax.countryCode = this.stakeholderService.getPhoneNumber(PhoneNumberType.Fax, stakeholder.phoneNumbers).countryCode;
        message.fax.faxPrefix = this.stakeholderService.getPhoneNumber(PhoneNumberType.Fax, stakeholder.phoneNumbers).prefix;
        break;
    }
  }

  get validationType() {
    return this.outputManagementService.mapToValidationTypeEnum(this.message.messageChannelType);
  }

  get MessageChannelType() {
    return MessageChannelType;
  }

  private setDigitalConsentTemplate() {
    if (this.message?.templateType === 'CONSENT' && !this.message?.templateDisplayName
      && this.messageTemplates?.[0]?.displayName && this.message.receiver) {

      this.message.templateDisplayName = this.messageTemplates[0].displayName;
      this.message.templateId = this.messageTemplates[0].id;
      this.setTemplateLanguagesPreferredLanguage(this.message.templateId);
    }
  }
}
