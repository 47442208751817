import {SubGopDetails} from './../../../modules/case/components/case-plans/case-plans/add-service-order/models/subGopDetails';
import {SubReference} from './../../../modules/case/components/case-plans/case-plans/add-service-order/models/subReference';
import {ServiceOrderExtension} from './service-order-extension';
import {Duration, DurationAdapter} from '../duration';
import {Location} from '@secca/shared/models/location';
import {BaseDurationServiceOrderExtension} from '@secca/shared/models/service-order/base-duration-service-order-extension';
import { CommitmentType, CommitmentTypeAdapter } from '../commitment-type';

export class TreatmentServiceOrderExtension extends BaseDurationServiceOrderExtension implements ServiceOrderExtension {
  getSelectedStakeholderIds(): string[] {
    return null;
  }

  isPreviewGopValid(): boolean {
    return this.isValid();
  }

  isValid(): boolean {
    let gopValid = true;
    if (
      this.commitmentType && this.commitmentType.gop
      ) {
      gopValid = this.gopDetails.isValid();
    }
    return !!this.commitmentType &&
      gopValid &&
      this.isDurationValid();
  }

  getEndLocation(): Location {
    return this.getStartLocation();
  }

  isDurationValid(): boolean {
    if (!this.durations[0].isValid()) {
      return false;
    }
    if (this.durations.length > 1) {
      for (let index = 1; index < this.durations.length; index++) {
        const duration = this.durations[index];
        if (!duration.isValidExpectation()) {
          return false;
        }
      }
    }
    return true;
  }

  setCommitmentType(commitmentType: CommitmentType) {
    this.commitmentType = commitmentType;
  }

  getCommitmentType(): CommitmentType {
    return this.commitmentType;
  }
}

export class TreatmentServiceOrderExtensionAdapter {
  adapt(item?: Partial<TreatmentServiceOrderExtension>): TreatmentServiceOrderExtension {
    const adapter = new DurationAdapter();
    const commitmentTypeAdapter: CommitmentTypeAdapter = new CommitmentTypeAdapter();
    return new TreatmentServiceOrderExtension({
      id: item.id,
      durations: item.durations !== null ? item.durations.map(duration => adapter.adapt(duration)) : [new Duration()],
      gopDetails: item.gopDetails != null ? new SubGopDetails(item.gopDetails) : new SubGopDetails(undefined),
      reference: item.reference != null ? new SubReference(item.reference) : new SubReference(undefined),
      commitmentType: commitmentTypeAdapter.adapt(item.commitmentType),
    });
  }
}
