import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import {CaseConsent} from '@secca/shared/models/caseConsent';
import {ConsentRequestEnum, ConsentStatusEnum} from '@secca/shared/models/enums';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-drop-down-consent',
  templateUrl: './drop-down-consent.component.html',
  styleUrls: ['./drop-down-consent.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DropDownConsentComponent implements OnInit {
  @ViewChild('concentSelect') private concentSelect: NgSelectComponent;

  // What comes in from users
  @Input() items: CaseConsent[];
  @Input() selectedItemId: CaseConsent;
  @Input() loading: boolean;

  // What goes out of component
  @Output() saveModel = new EventEmitter();
  @Output() selectedItemIdChange = new EventEmitter();
  @Output() open = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  focus() {
    this.concentSelect.open();
  }

  onOpen() {
    this.open.emit();
  }

  onSaveModel() {
    this.saveModel.emit();
  }

  onSaveModelDropDown() {
    this.onSaveModel();
  }

  onSelectedItemIdChange() {
    this.selectedItemIdChange.emit(this.selectedItemId);
  }

  get ConsentRequestEnum() {
    return ConsentRequestEnum;
  }

  getBackgroundClass(consentStatus: ConsentStatusEnum): string {
    switch (consentStatus) {
      case ConsentStatusEnum.CONSENT_REQUEST_NOT_SENT:
      case ConsentStatusEnum.CONSENT_REJECTED:
      case ConsentStatusEnum.CONSENT_NOT_POSSIBLE:
      case ConsentStatusEnum.AUTO_FAILED:
        return 'consent-request-background-red';
      case ConsentStatusEnum.CONSENT_ACCEPTED:
      case ConsentStatusEnum.CONSENT_ACCEPTED_MAN:
        return 'consent-request-background-green';
      case ConsentStatusEnum.REQUESTED:
      case ConsentStatusEnum.ORAL:
        return 'consent-request-background-yellow';
    }
    return;
  }
}
