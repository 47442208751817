import { Component, EventEmitter, OnInit, Output, Input, OnChanges, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ServiceOrderService } from '@secca/core/services/service-order.service';
import { SupplierInvoiceService } from '@secca/core/services/supplier-invoice.service';
import { DropdownWithIconDisableDictionary } from '@secca/shared/models/dropdownWithIconDisableDictionary';
import { TableSort } from '@secca/shared/models/table-sort';
import { RefundStatus, ServiceTypeEnum, ServiceTypeEnumIcon, ShortcutEnum, SortOrder } from '@secca/shared/models/enums';
import { RefundTaskViewModel } from '../models/refund-task-view.model';
import { DataService } from '@secca/core/services/data.service';
import { Router } from '@angular/router';
import { CaseService } from '@secca/core/services/case.service';
import { AbstractGlobalBoardTableShortcutsComponent } from '@secca/shared/components/abstract-global-board-table-shortcuts.component';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { SettingsService } from '@secca/core/services/settings.service';
import { BoardNavigationStateService } from '../services/board-navigation-state.service';

@Component({
  selector: 'app-board-task-refund-table',
  templateUrl: './board-task-refund-table.component.html',
  styleUrls: ['./board-task-refund-table.component.scss'],
})
@AutoUnsubscribe
export class BoardTaskRefundTableComponent extends AbstractGlobalBoardTableShortcutsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() set taskViewModels(data: RefundTaskViewModel[]) {
    this._taskViewModels = data;
    this.numberOfRows = data?.length;
    setTimeout(() => {
      this.selectedRowIndex = this.boardNavigationStateService.getState('board-case-filter-request-refund-selected-row-index') ? parseInt(this.boardNavigationStateService.getState('board-case-filter-request-refund-selected-row-index'), 10) : 0;
      this.inputScrollPosition = this.boardNavigationStateService.getState('board-case-filter-request-refund-task-scroll-position') ? parseInt(this.boardNavigationStateService.getState('board-case-filter-request-refund-task-scroll-position'), 10) : 0;
      this.navigateToTable();
    }, 50);
  }
  get taskViewModels(): RefundTaskViewModel[] {
    return this._taskViewModels;
  }

  @Input() taskSorting: TableSort;
  get sortedOn(): any {
    const sortedOn = {};
    if (this.taskSorting != null) {
      sortedOn[this.taskSorting.sortBy] = true;
    }
    return sortedOn;
  }
  get sortOrder(): any {
    const sortOrder = {};
    if (this.taskSorting != null) {
      sortOrder[this.taskSorting.sortBy] = this.taskSorting.sortOrder;
    }
    return sortOrder;
  }

  private _taskViewModels: RefundTaskViewModel[];
  private serviceOrderTypes: DropdownWithIconDisableDictionary[];
  isAllSelected = false;
  inputScrollPosition = 0;
  @Output() sortingAction = new EventEmitter<TableSort>();
  @Output() refundMovedEvent = new EventEmitter();

  constructor(private serviceOrderService: ServiceOrderService,
              private translateService: TranslateService,
              private supplierInvoiceService: SupplierInvoiceService,
              private dataService: DataService,
              private router: Router,
              private caseService: CaseService,
              public settingsService: SettingsService,
              private boardNavigationStateService: BoardNavigationStateService) {
    super('taskRefundTabel');
    this.shortcutSubscriptions.push(
      this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateTableRowAltEnter }).subscribe(a => {
        if (Number.isFinite(this.selectedRowIndex)) {
          this.redirectToManageRefund(this.taskViewModels[this.selectedRowIndex].caseId, this.taskViewModels[this.selectedRowIndex].refundId);
        }
      }),
    );
  }

  ngOnInit(): void {
    this.serviceOrderService.getServiceOrderTypes(false).subscribe(result => (this.serviceOrderTypes = result));
    this.selectedRowIndex = this.boardNavigationStateService.getState('board-case-filter-request-refund-selected-row-index') ? parseInt(this.boardNavigationStateService.getState('board-case-filter-request-refund-selected-row-index'), 10) : 0;
    this.inputScrollPosition = this.boardNavigationStateService.getState('board-case-filter-request-refund-task-scroll-position') ? parseInt(this.boardNavigationStateService.getState('board-case-filter-request-refund-task-scroll-position'), 10) : 0;
  }

  ngOnChanges() {
    setTimeout(() => this.setScrollPosition(), 500);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.boardNavigationStateService.saveState('board-case-filter-request-refund-task-scroll-position', JSON.stringify(this.inputScrollPosition));
    this.boardNavigationStateService.saveState('board-case-filter-request-refund-selected-row-index', JSON.stringify(this.selectedRowIndex));
  }

  setScrollPosition() {
    if (this.inputScrollPosition !== null && document.getElementById('taskRefundTabel')) {
      document.getElementById('taskRefundTabel').scrollTop = this.inputScrollPosition;
    }
  }

  onCaseTabelScroll() {
    this.inputScrollPosition = document.getElementById('taskRefundTabel').scrollTop;
  }

  redirectToManageRefund(caseId: number, refund: number) {
    this.dataService.navigateRefundId = refund;
    this.router.navigate(['case', caseId]);
  }

  selectAllTasks() {
    this.isAllSelected = !this.isAllSelected;
    this.taskViewModels.forEach(a => (a.isSelected = this.isAllSelected));
  }

  sortedBy(column: string): string {
    return this.sortedOn[column] ? 'sorted-by' : '';
  }

  sortBy(column: string) {
    const sortOrder = {};
    if (this.sortOrder[column] == null) {
      sortOrder[column] = SortOrder.asc;
    } else {
      if (this.sortOrder[column] === SortOrder.asc) {
        sortOrder[column] = SortOrder.desc;
      } else {
        sortOrder[column] = SortOrder.asc;
      }
    }
    this.sortingAction.emit(new TableSort({ sortBy: column, sortOrder: sortOrder[column] }));
  }

  getBrandImagesUrlForProfile(profileId: string) {
    return this.caseService.getBrandImagesUrlForProfile(profileId);
  }

  getServiceOderTypeIconStyle(serviceTypeEnum: string): any {
    if (!!serviceTypeEnum) {
      const sosServiceType: ServiceTypeEnum = ServiceTypeEnum[String(serviceTypeEnum)];
      const imagePath = ServiceTypeEnumIcon.getIcon(sosServiceType);
      return {
        backgroundColor: ServiceTypeEnumIcon.getBackGroundColor(sosServiceType),
        backgroundImage: `url(\'../../../../../../assets/icons/${imagePath}\')`,
      };
    } else {
      return null;
    }
  }

  getServiceOrderTypeName(serviceTypeEnum: string): string {
    if (serviceTypeEnum) {
      return this.translateService.instant('service-order-' + serviceTypeEnum.toLowerCase().replace(/_/g, '-'));
    }
    return null;
  }

  getRefundStatusText(refundStatus: RefundStatus) {
    return this.translateService.instant('case-economy-refund-refund-status-long-' + RefundStatus[refundStatus]);
  }
}
