import { AttachmentDto } from './../../../../../shared/models/attachment-dto';
import { Message, MessageUtilService } from './message';
import { Adapter } from 'src/app/shared/interfaces/adapter';
import { Injectable } from '@angular/core';
import { ContentType } from './../../../../../shared/models/enums';
import { StakeholderCcDto } from '../components/message-dialog/message-subject/message-form-cc/stakeholder-cc-dto';
import { PostHandlingsDto } from '@secca/shared/models/post-handlings-dto';

export class SendFaxRequestDto {
  caseId: string;
  subject: string;
  body: string;
  contentType: ContentType;
  recipientFaxes: string[];
  attachments: AttachmentDto[];
  internalSummary: string;
  toStakeholderType: string;
  toStakeholderName: string;
  ccStakeholderIds: string[];
  ccAddresses: string[];
  from: string;
  stakeholderReceiverId: string;
  postHandlings: PostHandlingsDto;
  templateId: string;

  public constructor(init?: Partial<SendFaxRequestDto>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class SendFaxRequestDtoAdapter implements Adapter<SendFaxRequestDto> {

  constructor(private messageUtilService: MessageUtilService) {
  }

  adapt(message: Message): SendFaxRequestDto {
    const cc: StakeholderCcDto[] = this.messageUtilService.getStakeholderCcDtoItems(message);

    return new SendFaxRequestDto({
      caseId: message.caseId,
      subject: message.caseNumber + (message.subject != null ? message.subject : ''),
      body: message.text,
      contentType: ContentType.html,
      recipientFaxes: this.mapToFaxAddress(message.fax.faxPrefix + message.fax.faxNumber, message.cc),
      attachments: message.attachments,
      internalSummary: message.internalSummary,
      toStakeholderType: message.receiver.stakeholderType,
      toStakeholderName: message.receiver.isCompany
        ? message.receiver.company.name
        : message.receiver.person.firstName + ' ' + message.receiver.person.surname,
      ccStakeholderIds: this.mapCcToIds(cc),
      ccAddresses: this.mapCcToFaxAddress(cc),
      stakeholderReceiverId: message.receiver.id,
      postHandlings: message.postHandlings,
      templateId: message.templateId
    });
  }

  private mapCcToFaxAddress(cc: any[]): string[] {
    if (cc != null && cc.length > 0) {
      return cc.map(item => item.address);
    }
    return [];
  }

  private mapToFaxAddress(receiver: string, cc: any[]): string[] {
    if (cc != null && cc.length > 0) {
      const fax: string[] = cc.map(item => this.formatFaxNumber(item.address));
      fax.push(receiver);
      return fax;
    }
    return [this.formatFaxNumber(receiver)];
  }

  private mapCcToIds(cc: StakeholderCcDto[]): string[] {
    if (cc != null && cc.length > 0) {
      return cc.map(item => item.id);
    }
    return [];
  }

  private formatFaxNumber(faxNumber: string): string {
    return faxNumber.replace(/([\(\)\+])+/g, '');
  }
}
