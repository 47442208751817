<ul ngbNav #nav="ngbNav" class="nav-tabs" [activeId]="!isProcessGopRequest() ? 0 : 5" [destroyOnHide]="false">
   <li [ngbNavItem]="0" *ngIf="!isProcessGopRequest()">
    <a ngbNavLink>
      <div>{{ 'task-web-modal-case-basics' | translate }}</div>
    </a>
    <ng-template ngbNavContent>
      <app-task-web-modal-header
        [appDisableInput]="caseStateService.isCaseDisabled()"
        [taskViewModel]="taskViewModel"
        [taskDataViewModel]="taskDataViewModel"
        [hideSearchCreateButton]="hideSearchCreateButton"
        [causes]="causes"
        [diagnoses]="diagnoses"
        [channels]="channels"
        [createCaseOnly]="createCaseOnly"
        (createCaseButtonPressed)="createCase()"
        (closed)="close()"
      ></app-task-web-modal-header>

      <app-task-web-details-panel
        [taskDataViewModel]="taskDataViewModel"
        [serviceItemList]="serviceItemList"
        [purposeOfTravelItemList]="purposeOfTravelItemList"
        [causes]="causes"
        [diagnoses]="diagnoses"
        [customerProfiles]="customerProfiles"
        [isSupplierPortalChannel]="isSupplierPortalChannel()"
      ></app-task-web-details-panel>
      <div class="message-reported-by" *ngIf="isSupplierPortalChannel() && this.taskDataViewModel?.caseValidated">
        <div class="row align-items-end reported-by-row">
          <div class="col-4">
            <app-drop-down-stakeholder-with-icon
              name="{{ 'task-web-modal-reported-by' | translate }}"
              [items]="companyStakeholders"
              [(selectedItemId)]="assignedReporter"
              [required]="isReportedByRequired()" [requiredRedBackground]="true"
            ></app-drop-down-stakeholder-with-icon>
          </div>
          <div class="col">
            <input type="checkbox" [(ngModel)]="switchReporter" [disabled]="!isSwitchReporterOnCaseAllowed"/>
            <span class="name">{{ 'task-web-modal-move-case-reporter' | translate }}</span>
          </div>
        </div>
      </div>
      <div class="message-internal-summary">
        <label>{{ 'task-web-modal-internal-summary' | translate }}</label>
        <textarea maxlength="1000" [(ngModel)]="internalSummary" [disabled]="isDisabled"></textarea>
      </div>
    </ng-template>
   </li>
   <li [ngbNavItem]="1" *ngIf="taskDataViewModel?.data?.coTravellerStakeholders?.length > 0">
    <a ngbNavLink>
      <div>{{ 'task-web-modal-co-travellers' | translate }}</div>
    </a>
    <ng-template ngbNavContent>
      <app-task-web-modal-header
        [taskViewModel]="taskViewModel"
        [taskDataViewModel]="taskDataViewModel"
        [hideSearchCreateButton]="hideSearchCreateButton"
        [causes]="causes"
        [diagnoses]="diagnoses"
        [channels]="channels"
        [createCaseOnly]="createCaseOnly"
        (createCaseButtonPressed)="createCase()"
        (closed)="close()"
      ></app-task-web-modal-header>

      <app-task-web-co-travellers-panel
        [taskDataViewModel]="taskDataViewModel"
        [serviceItemList]="serviceItemList"
        [purposeOfTravelItemList]="purposeOfTravelItemList"
        [causes]="causes"
        [diagnoses]="diagnoses"
        [customerProfiles]="customerProfiles"
      ></app-task-web-co-travellers-panel>
    </ng-template>
   </li>
   <li [ngbNavItem]="2" *ngIf="taskDataViewModel?.data?.refunds?.length > 0 || taskDataViewModel?.data?.refund?.refundItems?.length > 0">
    <a ngbNavLink>
      <div>{{ 'task-web-modal-refunds' | translate }}</div>
    </a>
    <ng-template ngbNavContent>
      <app-task-web-modal-header
        [taskViewModel]="taskViewModel"
        [taskDataViewModel]="taskDataViewModel"
        [hideSearchCreateButton]="hideSearchCreateButton"
        [causes]="causes"
        [diagnoses]="diagnoses"
        [channels]="channels"
        [createCaseOnly]="createCaseOnly"
        (createCaseButtonPressed)="createCase()"
        (closed)="close()"
      ></app-task-web-modal-header>

      <app-task-web-refunds-panel
        [taskDataViewModel]="taskDataViewModel"
        [serviceItemList]="serviceItemList"
        [purposeOfTravelItemList]="purposeOfTravelItemList"
        [causes]="causes"
        [diagnoses]="diagnoses"
        [customerProfiles]="customerProfiles"
      ></app-task-web-refunds-panel>
    </ng-template>
   </li>
   <li [ngbNavItem]="3" *ngIf="taskDataViewModel?.data?.refund?.bankDetails">
    <a ngbNavLink>
      <div>{{ 'task-web-modal-bank-details' | translate }}</div>
    </a>
    <ng-template ngbNavContent>
      <app-task-web-modal-header
        [taskViewModel]="taskViewModel"
        [taskDataViewModel]="taskDataViewModel"
        [hideSearchCreateButton]="hideSearchCreateButton"
        [causes]="causes"
        [diagnoses]="diagnoses"
        [channels]="channels"
        [createCaseOnly]="createCaseOnly"
        (createCaseButtonPressed)="createCase()"
        (closed)="close()"
      ></app-task-web-modal-header>

      <app-task-web-bank-details-panel
        [taskDataViewModel]="taskDataViewModel"
        [serviceItemList]="serviceItemList"
        [purposeOfTravelItemList]="purposeOfTravelItemList"
        [causes]="causes"
        [diagnoses]="diagnoses"
        [customerProfiles]="customerProfiles"
      ></app-task-web-bank-details-panel>
    </ng-template>
   </li>
   <li [ngbNavItem]="4" *ngIf="taskDataViewModel?.data?.plannedTravel?.luggage">
    <a ngbNavLink>
      <div>{{ 'task-web-modal-luggage' | translate }}</div>
    </a>
    <ng-template ngbNavContent>
      <app-task-web-modal-header
        [taskViewModel]="taskViewModel"
        [taskDataViewModel]="taskDataViewModel"
        [hideSearchCreateButton]="hideSearchCreateButton"
        [causes]="causes"
        [diagnoses]="diagnoses"
        [channels]="channels"
        [createCaseOnly]="createCaseOnly"
        (createCaseButtonPressed)="createCase()"
        (closed)="close()"
      ></app-task-web-modal-header>

      <app-task-web-luggage-panel
        [taskDataViewModel]="taskDataViewModel"
        [serviceItemList]="serviceItemList"
        [purposeOfTravelItemList]="purposeOfTravelItemList"
        [causes]="causes"
        [diagnoses]="diagnoses"
        [customerProfiles]="customerProfiles"
      ></app-task-web-luggage-panel>
    </ng-template>
   </li>
   <li [ngbNavItem]="5" *ngIf="taskDataViewModel?.data?.treatment || isProcessGopRequest()">
    <a ngbNavLink>
      <div>{{ 'task-web-modal-medical-report' | translate }} {{medicalAttachments ? '(' + medicalAttachments.length + ')' : ''}}</div>
    </a>
    <ng-template ngbNavContent>
      <div class="message-attachments-container">

      <app-task-web-medical-report-panel
        [taskDataViewModel]="taskDataViewModel"
        [serviceItemList]="serviceItemList"
        [purposeOfTravelItemList]="purposeOfTravelItemList"
        [causes]="causes"
        [diagnoses]="diagnoses"
        [channels]="channels"
        [customerProfiles]="customerProfiles"
        [companyStakeholders]="companyStakeholders"
      ></app-task-web-medical-report-panel>

        <div *ngFor="let attachment of medicalAttachments">
          <app-task-message-attachment [attachment]="attachment" [caseId]="taskDataViewModel?.caseId"> </app-task-message-attachment>
        </div>
      </div>
    </ng-template>
   </li>

  <li [ngbNavItem]="6" *ngIf="isMpaRequest()">
    <a ngbNavLink>
      <div>{{ 'task-web-modal-questionnaire' | translate }}</div>
    </a>
    <ng-template ngbNavContent>

        <app-task-web-modal-header
          [appDisableInput]="caseStateService.isCaseDisabled()"
          [taskViewModel]="taskViewModel"
          [taskDataViewModel]="taskDataViewModel"
          [hideSearchCreateButton]="hideSearchCreateButton"
          [causes]="causes"
          [diagnoses]="diagnoses"
          [channels]="channels"
          [createCaseOnly]="createCaseOnly"
          (createCaseButtonPressed)="createCase()"
          (closed)="close()"
        ></app-task-web-modal-header>

        <app-task-web-questionnaire
          [taskDataViewModel]="taskDataViewModel"
          [serviceItemList]="serviceItemList"
          [purposeOfTravelItemList]="purposeOfTravelItemList"
          [causes]="causes"
          [diagnoses]="diagnoses"
          [customerProfiles]="customerProfiles"
        ></app-task-web-questionnaire>
    </ng-template>
  </li>

   <li [ngbNavItem]="7">
    <a ngbNavLink>
      <div>{{ 'task-web-modal-attachments' | translate }} ({{ attachments?.length }})</div>
    </a>
    <ng-template ngbNavContent>
      <div class="message-attachments-container">
        <div *ngFor="let attachment of attachments">
          <app-task-message-attachment [attachment]="attachment" [caseId]="taskDataViewModel?.caseId"> </app-task-message-attachment>
        </div>
      </div>
    </ng-template>
   </li>
</ul>
<div [ngbNavOutlet]="nav"></div>
