import { EndUserStateService } from '@secca/core/state-services/enduser-state-service';
import { DropdownDictionary } from 'src/app/shared/models/dropdownDictionary';
import { Data } from 'src/app/core/mocks/data';
import { LocationService } from '@secca/core/services/location.service';
import { GopMethodEnum, PhoneNumberType, ProviderRatingEnum, StakeholderTypeEnum, ValidationTypeEnum } from '@secca/shared/models/enums';
import {
  DropDownCountriesAutoComplete
} from '@secca/shared/components/drop-down-countries-auto-complete/drop-down-countries-auto-complete.component';
import { StakeholderService } from '@secca/core/services/stakeholder.service';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { interval } from 'rxjs';
import { HelperService } from '@secca/core/services/helper.service';
import { AutoCompleteTypeEnum } from 'src/app/shared/components/drop-down-countries-auto-complete/auto-complete-type-enum';
import { CaseStakeholder, CaseStakeholderAdapter } from 'src/app/shared/models/caseStakeholder';
import { CaseService } from 'src/app/core/services/case.service';
import { Country, CountryDetails, CountryDetailsAdapter } from '@secca/shared/models/country';
import { DictionaryService } from '@secca/core/services/dictionary.service';
import { ContactPerson } from 'src/app/shared/models/contactPerson';
import * as moment from 'moment';
import { InputTypePattern, PermissionEnum } from 'src/app/shared/models/enums';
import { CasePrimaryContactService } from '../../../case-primary-contact/case-primary-contact.service';
import { Case } from '@secca/shared/models/case';
import { UserDto } from '@secca/shared/models/userDto';
import { StakeholderType } from '../../stakeholderType';
import { CaseIncident } from '@secca/shared/models/caseIncident';
import { PhoneNumber } from '@secca/shared/models/phoneNumber';
import { Gop } from '@secca/shared/models/gop';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import { NationalIdService } from '@secca/core/services/national-id.service';
import { DropdownAdapter } from '@secca/shared/models/dictionary';
import { TableCaseStakeholder } from '@secca/case/components/case-stakeholders/case-stakeholders-table/tableCaseStakeholder';
import { PermissionService } from '@secca/core/services/permission.service';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import Autocomplete = google.maps.places.Autocomplete;
import { PropertyType, PropertyTypeDb } from '@secca/shared/models/PropertyType';
import { MasterListService } from '@secca/core/services/masterlist-service';
import { switchMap } from 'rxjs/operators';
import { CaseDialogViewerService } from '@secca/case/case-dialog-viewer.service';
import { DialogStateService } from '@secca/core/state-services/dialog-state.service';
import {
  SupplierSearchData
} from '@secca/case/components/case-plans/case-plans/add-service-order/search-service-provider/SupplierDialogData';
import { SettingsService } from '@secca/core/services/settings.service';

@AutoUnsubscribe
@Component({
  selector: 'app-case-stakeholders-info',
  templateUrl: './case-stakeholders-info.component.html',
  styleUrls: ['./case-stakeholders-info.component.scss']
})
export class CaseStakeholdersInfoComponent implements OnInit, AfterViewInit {
  @ViewChild('homeCountry', { static: true }) homeCountry: DropDownCountriesAutoComplete;
  @ViewChild('addressInput') public addressInputRef: ElementRef;
  @Output() reloadData = new EventEmitter<any>();
  @Output() stakeholderNationalIdChange = new EventEmitter<any>();
  @Output() searchSupplierNotifier = new EventEmitter<CaseStakeholder>();
  @Input() caseId: string;
  @Input() case: Case;
  @Input() allStakeholders: TableCaseStakeholder[];
  @Input() disable: boolean;
  agentStakeholders: CaseStakeholder[];
  private loggedInUser: UserDto;
  private stakeholderstypes: StakeholderType[];
  incident: CaseIncident;

  agentForStakeholder: CaseStakeholder;
  stakeholderFax: PhoneNumber;
  stakeholderFixed: PhoneNumber;
  stakeholderMobile: PhoneNumber;
  gopFixed: PhoneNumber;
  gopMobile: PhoneNumber;
  gopFax: PhoneNumber;
  gopMethods: DropdownDictionary[];
  propertyTypesShow: PropertyTypeDb[];

  blockEmail: boolean;
  personEmail: string;

  $subscrLocalStakeholderTime;
  private allPropertyTypes: PropertyTypeDb[];
  protected readonly PhoneNumberType = PhoneNumberType;
  isNewServiceProviderEnabled: boolean = this.settingService.isNewServiceProviderEnabled();

  @Input()
  set caseStakeholder(caseStakeholder: CaseStakeholder) {
    if (caseStakeholder) {
      this._caseStakeholder = caseStakeholder;
      if (this.caseStakeholder.hasRole(StakeholderTypeEnum.policyHolder)) {
        this.caseService.publishPolicyholder(caseStakeholder);
      }
      if (this.caseStakeholder.person) {
        if (this.caseStakeholder.person.nationalIdCountry === null) {
          this.caseStakeholder.person.nationalIdCountry = new Country();
          this.caseStakeholder.person.nationalIdCountry.code = null;
          this.caseStakeholder.person.nationalIdCountry.name = '';
        }

        this.proceedWithNationalCountryCode();

        if (!this.caseStakeholder.person.hasEmail) {
          this.blockEmail = true;
          this.personEmail = '';
        } else {
          this.blockEmail = false;
          this.personEmail = this.caseStakeholder.person.email;
        }
      }

      if (caseStakeholder.company) {
        if (this.caseStakeholder.address.country === null) {
          this.caseStakeholder.address.country = new Country();
          this.caseStakeholder.address.country.code = null;
          this.caseStakeholder.address.country.name = '';
        }
        this.personEmail = this.caseStakeholder.company.email;
      }
      this.stakeholderFixed = this.stakeholderService.getPhoneNumber(PhoneNumberType.Fixed, caseStakeholder.phoneNumbers);
      this.stakeholderMobile = this.stakeholderService.getPhoneNumber(PhoneNumberType.Mobile, caseStakeholder.phoneNumbers);
      this.stakeholderFax = this.stakeholderService.getPhoneNumber(PhoneNumberType.Fax, caseStakeholder.phoneNumbers);

      this.gopFixed = new PhoneNumber();
      this.gopMobile = new PhoneNumber();
      this.gopFax = new PhoneNumber();

      if (caseStakeholder.company != null && caseStakeholder.company.gop) {
        this.gopFixed = this.stakeholderService.getPhoneNumber(PhoneNumberType.Fixed, caseStakeholder.company.gop.phoneNumbers);
        this.gopMobile = this.stakeholderService.getPhoneNumber(PhoneNumberType.Mobile, caseStakeholder.company.gop.phoneNumbers);
        this.gopFax = this.stakeholderService.getPhoneNumber(PhoneNumberType.Fax, caseStakeholder.company.gop.phoneNumbers);
      }
    }
  }

  get caseStakeholder(): CaseStakeholder {
    return this._caseStakeholder;
  }

  private _caseStakeholder: CaseStakeholder;

  genders: DropdownDictionary[];
  preferredChannels: DropdownDictionary[];
  preferredLanguages: DropdownDictionary[];
  contactPersonRoles: DropdownDictionary[] = [];
  emailStatusType: DropdownDictionary[] = [
    { id: true, name: 'Email' },
    { id: false, name: 'No' }
  ];
  countries: Country[];
  nonShowNationalId: string;
  theShownNationalId: string;
  nationalIdCountry: CountryDetails;
  autocompleteAddress: Autocomplete;
  contactPersons: ContactPerson[] = [];
  remarksPlaceholder: string;
  now: number;
  timezone: number;
  passportExpiryMinDate = moment.utc();
  caseStakeholderCountry: CountryDetails;
  caseStakeholderCountryFromGoogle: CountryDetails;
  ssnValidationStatus = 'not-looked-up';

  constructor(
    private caseService: CaseService,
    private changeDetectorRef: ChangeDetectorRef,
    private ngZone: NgZone,
    private helperService: HelperService,
    private stakeholderService: StakeholderService,
    private primaryContactService: CasePrimaryContactService,
    private locationService: LocationService,
    private nationalIdService: NationalIdService,
    private countryAdapter: CountryDetailsAdapter,
    private dropdownAdapter: DropdownAdapter,
    private dictionaryService: DictionaryService,
    private caseStakeholderAdapter: CaseStakeholderAdapter,
    public caseLockHelperService: CaseLockHelperService,
    public permissionService: PermissionService,
    private endUserStateService: EndUserStateService,
    public caseStateService: CaseStateService,
    private masterListService: MasterListService,
    private dialogViewerService: CaseDialogViewerService,
    private dialogStateService: DialogStateService,
    private settingService: SettingsService
  ) {
  }

  get ValidationTypeEnum() {
    return ValidationTypeEnum;
  }

  ngOnInit() {
    this.stakeholderFixed = this.stakeholderService.getPhoneNumber(PhoneNumberType.Fixed, this.caseStakeholder.phoneNumbers);
    this.stakeholderMobile = this.stakeholderService.getPhoneNumber(PhoneNumberType.Mobile, this.caseStakeholder.phoneNumbers);
    this.stakeholderFax = this.stakeholderService.getPhoneNumber(PhoneNumberType.Fax, this.caseStakeholder.phoneNumbers);

    if (this.caseStakeholder.company != null) {
      if (this.caseStakeholder.company.gop === null) {
        this.caseStakeholder.company.gop = new Gop();
      }
      this.gopFixed = this.stakeholderService.getPhoneNumber(PhoneNumberType.Fixed, this.caseStakeholder.company.gop.phoneNumbers);
      this.gopMobile = this.stakeholderService.getPhoneNumber(PhoneNumberType.Mobile, this.caseStakeholder.company.gop.phoneNumbers);
      this.gopFax = this.stakeholderService.getPhoneNumber(PhoneNumberType.Fax, this.caseStakeholder.company.gop.phoneNumbers);
    }

    this.remarksPlaceholder = this.helperService.freeTextFieldPlaceholder;
    this.genders = this.dictionaryService.getGenders();
    this.preferredChannels = this.stakeholderService.getPreferredChannels().map(item => this.dropdownAdapter.adapt(item));
    this.preferredLanguages = this.stakeholderService.getPreferredLanguages().map(item => new DropdownDictionary(item.code, item.language));
    this.loadContactRoles();
    this.showCountry();
    this.setMaskId();
    this.getloggedinUser();
    this.getStakeholderTypes();

    this.agentStakeholders = this.allStakeholders
      .map(sh => sh.caseStakeholder)
      .filter(
        item =>
          item.hasRole(StakeholderTypeEnum.assistanceContact) ||
          item.hasRole(StakeholderTypeEnum.sosServiceOffice) ||
          item.hasRole(StakeholderTypeEnum.billingOfficeAdministration)
      );
    if (this.caseStakeholder.company != null && this.caseStakeholder.company.gop != null) {
      this.agentForStakeholder = this.agentStakeholders.find((item) => +item.id === this.caseStakeholder.company.gop.agentId);
      if (!this.agentForStakeholder) {
        this.caseStakeholder.company.gop.agentId = null;
      }
    }
    this.gopMethods = Data.getGopMethods();
    this.updateSsnValidationStatus();
  }

  private loadContactRoles() {
    this.masterListService.getContactRoles().pipe(
      switchMap((value: PropertyTypeDb[]) => {
        this.allPropertyTypes = value;

        this.caseStakeholder.company?.contactPersons?.forEach(contactPerson => {
            this.propertyTypesShow = contactPerson.propertyTypes.map(item => this.findPropertyTypeForContactPersonRoleKey(item.businessKey));
            contactPerson.propertyTypesSelected = this.propertyTypesShow.map(value => this.dropdownAdapter.adapt(value.contactRoleName));
          }
        );
        // After the first run the second
        return this.masterListService.getContactRolesWithApplication();
      })
    ).subscribe((value: PropertyTypeDb[]) => {
      this.contactPersonRoles = value.map((item: PropertyTypeDb) => this.dropdownAdapter.adapt(item.contactRoleName));
    });
  }

  private updateSsnValidationStatus() {
    if (!this.caseStakeholder.isCompany) {
      if (this.caseStakeholder.person.ssnValidated) {
        this.ssnValidationStatus = 'success';
      } else if (this.caseStakeholder.person.ssnLookupPerformed) {
        this.ssnValidationStatus = 'failure';
      } else {
        this.ssnValidationStatus = 'not-looked-up';
      }
    }
  }

  doSsnLookup() {
    this.ssnValidationStatus = 'searching';
    this.caseService.doSsnLookup(this.caseStakeholder.id).subscribe(
      result => {
        this.caseStakeholder = result;
        this.updateSsnValidationStatus();
        this.caseService.publishStakeholder(result);
      },
      error => console.error(error)
    );
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  private showCountry() {
    if (this.caseStakeholder.address.country) {
      this.caseStakeholderCountry = this.countryAdapter.adaptFromCountry(this.caseStakeholder.address.country);
    } else {
      this.caseStakeholderCountry = new CountryDetails();
    }
  }

  private setMaskId() {
    this.theShownNationalId = this.nonShowNationalId;
  }

  refreshSupplier() {
    this.reloadData.emit();
  }

  private getloggedinUser() {
    this.dictionaryService.getLoggedInUser().subscribe(result => {
      this.loggedInUser = result;
    });
    this.showLocalTimeForStakeholder();
  }

  private showLocalTimeForStakeholder() {
    if (this.caseStakeholder.address.latitude && this.caseStakeholder.address.longitude) {
      this.locationService
        .requestTimeZoneId(this.caseStakeholder.address.latitude, this.caseStakeholder.address.longitude)
        .subscribe((result: any) => {
          const secondsCounter = interval(1000);
          if (this.$subscrLocalStakeholderTime != null) {
            this.$subscrLocalStakeholderTime.unsubscribe();
          }
          this.$subscrLocalStakeholderTime = secondsCounter.subscribe(() => {
            // now in UTC + offset from the location + offset if daylight saving + (-) offset of the local time
            // (only for displaying, this will go to the timezoneOffset)
            this.now =
              new Date().getTime() +
              result.rawOffset * 1000 +
              result.dstOffset * 1000 +
              new Date().getTimezoneOffset() * 60 * 1000;
          });
        });
    }
  }

  private getStakeholderTypes() {
    this.dictionaryService.getStakeholdersTypes().subscribe(result => {
      this.stakeholderstypes = result;
    });
  }

  proceedWithNationalCountryCode() {
    if (this.caseStakeholder.person.nationalIdCountry === null) {
      this.caseStakeholder.person.nationalIdCountry = new Country();
      this.caseStakeholder.person.nationalIdCountry.code = null;
      this.caseStakeholder.person.nationalIdCountry.name = '';

      this.nationalIdCountry = new CountryDetails();
    } else {
      this.nationalIdCountry = this.countryAdapter.adaptFromCountry(this.caseStakeholder.person.nationalIdCountry);
    }
    if (this.caseStakeholder.person.nationalId) {
      this.nonShowNationalId = this.nationalIdService.getMaskedId(
        this.caseStakeholder.person.nationalIdCountry.code,
        this.caseStakeholder.person.nationalId
      );
    }

    if (!this.caseStakeholder.isCompany) {
      if (this.caseStakeholder.person.nationalIdValid) {
        this.nonShowNationalId = this.nationalIdService.getMaskedId(
          this.caseStakeholder.person.nationalIdCountry.code,
          this.caseStakeholder.person.nationalId
        );
      }
    }
  }

  initMapsAPI() {
    this.autocompleteAddress = new google.maps.places.Autocomplete(this.addressInputRef.nativeElement, {});

    this.autocompleteAddress.addListener('place_changed', () => {
      this.onAddressLocationChange();
    });
  }

  onAddressLocationChange() {
    this.caseStakeholder.address.street = '';
    this.caseStakeholder.address.city = '';
    this.caseStakeholder.address.region = '';
    this.caseStakeholder.address.postalCode = '';
    this.caseStakeholder.address.homeCountryCode = '';
    this.homeCountry.countryName = '';

    this.runGoogleApi(this.autocompleteAddress, this.addressInputRef);
  }

  runGoogleApi(ac: Autocomplete, ref: ElementRef) {
    try {
      this.ngZone.run(() => {
        const place: google.maps.places.PlaceResult = ac.getPlace();
        this.getInformationFromGoogle(place);
      });
    } catch (e) {
    }
  }

  getInformationFromGoogle(place: any) {
    if (place === undefined || place.address_components === undefined) {
      return;
    }
    let street = '';
    for (const address of place.address_components) {
      switch (address.types[0]) {
        case 'street_number':
          street += ' ' + address.long_name;
          break;
        case 'route':
          street = address.long_name + street;
          break;
        case 'locality':
          this.caseStakeholder.address.city = address.long_name;
          break;
        case 'country':
          this.caseStakeholder.address.homeCountryCode = address.short_name;
          this.caseStakeholder.address.country = new Country();
          this.caseStakeholder.address.country.name = address.long_name;
          this.caseStakeholder.address.country.code = address.short_name;
          this.homeCountry.countryName = address.long_name;
          break;
        case 'administrative_area_level_1':
          this.caseStakeholder.address.region = address.long_name;
          break;
        case 'postal_code':
          this.caseStakeholder.address.postalCode = address.long_name;
          break;
      }
    }
    this.caseStakeholder.address.street = this.addressInputRef.nativeElement.value;
    try {
      const longitude = (place.geometry.viewport?.Va?.i + place.geometry.viewport?.Va?.j) / 2;
      const latitude = (place.geometry.viewport?.Za?.i + place.geometry.viewport?.Za?.j) / 2;
      this.caseStakeholder.address.longitude = longitude;
      this.caseStakeholder.address.latitude = latitude;
    } catch (e) {
      console.error(e);
    }
    this.showLocalTimeForStakeholder();

    this.caseStakeholderCountryFromGoogle = this.countryAdapter.adaptFromCountry(this.caseStakeholder.address.country);

    if (this.caseStakeholderCountryFromGoogle) {
      this.dictionaryService.getCountryByAlpha2(this.caseStakeholder.address.country.code).subscribe(result => {
          if (result) {
            this.caseStakeholderCountryFromGoogle.phonePrefix = result.phonePrefix;
          }
          this.homeCountryChanged(this.caseStakeholderCountryFromGoogle);

        }, error => console.error(error)
      );
    } else {
      this.homeCountryChanged(this.countryAdapter.adaptFromCountry(this.caseStakeholder.address.country));
    }
    this.showCountry();
  }

  ngAfterViewInit(): void {
    if (this.caseStakeholder.address.country.name && this.homeCountry) {
      this.homeCountry.countryName = this.caseStakeholder.address.country.name;
    } else {
      this.homeCountry.countryName = '';
    }
    this.changeDetectorRef.detectChanges();
    this.initMapsAPI();
  }

  get InputTypePattern() {
    return InputTypePattern;
  }

  viewProviderRating() {
    return this.stakeholderService.displayTreatmentAndProviderRatingIcons(this.caseStakeholder);
  }

  showNationalIdClick() {
    if (this.theShownNationalId === this.nonShowNationalId) {
      this.theShownNationalId = this.caseStakeholder.person.nationalId;
    } else {
      this.theShownNationalId = this.nonShowNationalId;
    }
  }

  getFaxNumber(index: number): PhoneNumber {
    return this.caseStakeholder.company?.contactPersons[index].phoneNumbers.find(pn => pn.type === PhoneNumberType.Fax) || new PhoneNumber();
  }

  getPhoneNumber(index: number): PhoneNumber {
    return this.caseStakeholder.company?.contactPersons[index].phoneNumbers.find(pn => pn.type === PhoneNumberType.Fixed) || new PhoneNumber();
  }

  saveModelContactPersonPhone(phoneNumber: { phoneNumber: string; phonePrefix: string; phoneCountryCode: string; }, index: number, phoneNumberType: PhoneNumberType) {

    const contactPerson = this.caseStakeholder.company.contactPersons[index];
    const existingPhoneNumberIndex = contactPerson.phoneNumbers.findIndex(pn => pn.type === phoneNumberType);
    let newPhoneNumber: PhoneNumber = new PhoneNumber({
      type: phoneNumberType,
      number: phoneNumber.phoneNumber,
      prefix: phoneNumber.phonePrefix,
      countryCode: phoneNumber.phoneCountryCode
    });
    if (existingPhoneNumberIndex > -1) {
      contactPerson.phoneNumbers[existingPhoneNumberIndex] = newPhoneNumber;
    } else {
      contactPerson.phoneNumbers.push(newPhoneNumber);
    }
    this.saveModelContactPerson(contactPerson.id, contactPerson);
  }


  updateContactRoles($event: DropdownDictionary[], id: string, contactPerson: ContactPerson) {
    const propertyTypes: PropertyTypeDb[] = $event.map(item => this.findContactPersonRole(item.name));
    this.propertyTypesShow = propertyTypes as PropertyTypeDb[];
    contactPerson.propertyTypes = propertyTypes.map(item => {
      return { id: item.id, businessKey: item.businessKey, personId: +contactPerson.id };
    });
    this.saveModelContactPerson(id, contactPerson);
  }

  saveEmailContactPerson(id: string, contactPerson: ContactPerson) {
    this.saveModelContactPerson(id, contactPerson);
  }

  saveModelContactPerson(id: string, contactPerson: ContactPerson) {
    if (contactPerson.propertyTypes?.length > 0) {
      contactPerson = this.updateContactPersonWithRole(contactPerson);
    }
    contactPerson.caseId = +this.caseId;
    this.stakeholderService.updateContactPersons(id, contactPerson).subscribe((value: ContactPerson) => {
      contactPerson.propertyTypes = value.propertyTypes;
    }, error => console.error(error));
  }

  private updateContactPersonWithRole(contactPerson: ContactPerson): ContactPerson {
    contactPerson.propertyTypes.some(propertyType => {
      propertyType.businessKey === 'CR005';
    });
    contactPerson.propertyTypes.forEach((propertyType: PropertyType) => {
      propertyType.personId = +contactPerson.id;
    });
    return contactPerson;
  }

  private findContactPersonRole(contactRoleName: string): PropertyTypeDb {
    return this.allPropertyTypes.find(item => item.contactRoleName === contactRoleName);
  }

  private findPropertyTypeForContactPersonRoleKey(contactRoleKey: string): PropertyTypeDb {
    return this.allPropertyTypes?.find(item => item.businessKey === contactRoleKey);
  }

  addContactPerson() {
    this.stakeholderService.addContactPerson(new ContactPerson({ 'caseId': +this.caseId }), this.caseStakeholder.id).subscribe(
      result => {
        this.caseStakeholder.company.contactPersons.push(result);
      }
    );
  }

  deleteContactPerson(contactPersonId) {
    this.stakeholderService.deleteContactPersons(contactPersonId, this.caseId).subscribe(
      result => {
        this.caseStakeholder.company.contactPersons.splice(this.caseStakeholder.company.contactPersons.findIndex(item => item.id === contactPersonId), 1);
      }
    );
  }

  homeCountryChanged(country: CountryDetails) {
    const countryCode = country ? country.code : null;
    this.caseStakeholder.address.homeCountryCode = countryCode;
    this.caseStakeholder.address.country.id = country ? country.id : null;
    this.caseStakeholder.address.country.code = countryCode;
    this.caseStakeholder.address.country.name = country ? country.countryName : null;
    this.stakeholderService.setPhoneCountryCode(PhoneNumberType.Fixed, country, this.caseStakeholder.phoneNumbers);
    this.stakeholderService.setPhoneCountryCode(PhoneNumberType.Mobile, country, this.caseStakeholder.phoneNumbers);
    this.stakeholderService.setPhoneCountryCode(PhoneNumberType.Fax, country, this.caseStakeholder.phoneNumbers);
    this.stakeholderFixed = this.stakeholderService.getPhoneNumber(PhoneNumberType.Fixed, this.caseStakeholder.phoneNumbers);
    this.stakeholderMobile = this.stakeholderService.getPhoneNumber(PhoneNumberType.Mobile, this.caseStakeholder.phoneNumbers);
    this.stakeholderFax = this.stakeholderService.getPhoneNumber(PhoneNumberType.Fax, this.caseStakeholder.phoneNumbers);
    this.caseStakeholderCountry = this.countryAdapter.adaptFromCountry(this.caseStakeholder.address.country);
    this.homeCountry.countryName = country.countryName;
    this.updateStakeholderAsync();
  }

  updateDateOfBirth(dateOfBirth) {
    this.caseStakeholder.person.dateOfBirth = dateOfBirth;
    this.updateDateOfBirthOrAgeOrGenderOrNationalId('dateOfBirth', dateOfBirth, true);
  }

  updateDateOfDeath(dateOfDeath) {
    this.caseStakeholder.person.dateOfDeath = dateOfDeath;
    if (dateOfDeath) {
      this.saveModel('dateOfDeath', dateOfDeath, true);
    } else {
      this.stakeholderService.removeDateOfDeath(this.caseStakeholder.id).subscribe(
        result => this.handleStakeholderUpdated(result, true, 'dateOfDeath')
      );
    }
  }

  updatePassportExpiryDate(passportExpiryDate) {
    this.caseStakeholder.person.passportExpiryDate = passportExpiryDate;
    this.saveModel('passportExpiryDate', passportExpiryDate, true);
  }

  updateAgentAndSave() {
    if (this.agentForStakeholder != null) {
      this.setAgentNameFromStakeholder();
    } else {
      this.caseStakeholder.company.gop.agentName = null;
    }
    this.caseStakeholder.company.gop.agentId =
      this.agentForStakeholder != null && this.agentForStakeholder.id != null && this.agentForStakeholder.id != ''
        ? +this.agentForStakeholder.id
        : null;

    this.saveModel('agent', this.caseStakeholder.company.gop.agentId, true);
  }

  private setAgentNameFromStakeholder() {
    if (this.agentForStakeholder.company != null) {
      this.caseStakeholder.company.gop.agentName = this.agentForStakeholder.company.name;
    } else if (this.caseStakeholder.person != null) {
      this.caseStakeholder.company.gop.agentName =
        this.agentForStakeholder.person.firstName + ' ' + this.agentForStakeholder.person.surname;
    }
  }

  updatePreferredLanguage(preferredLanguageId: string) {
    const preferredLanguage = this.preferredLanguages.filter(p => p.id === preferredLanguageId);
    this.saveModel('preferredLanguage', preferredLanguage, true);
  }

  saveCompanyEmail(): void {
    this.saveModel('email', this.caseStakeholder.company.email, true);
  }

  saveCompanyGopEmail(): void {
    this.saveModel('gopEmail', this.caseStakeholder.company.gop.email);
  }

  savePersonEmail(): void {
    this.caseStakeholder.person.email = this.personEmail;
    if (!this.caseStakeholder.person.hasEmail) {
      this.blockEmail = true;
      this.personEmail = '';
    } else {
      this.blockEmail = false;
      this.personEmail = this.caseStakeholder.person.email;
    }
    this.saveModel('email', this.personEmail);
  }

  saveModel(valueType: string, value: any, refresh: boolean = false) {
    this.updateStakeholder(valueType, refresh);
    if (this.caseStakeholder.primaryContact) {
      this.updatePrimaryContactWidget();
    }
  }

  get showSsnLookup(): boolean {
    return this.caseStakeholder.stakeholderType !== StakeholderTypeEnum.endUser.toString() &&
      this.caseStakeholder.stakeholderType !== StakeholderTypeEnum.policyHolder.toString() &&
      this.caseStakeholder.stakeholderType !== StakeholderTypeEnum.reporter.toString() &&
      !this.caseStakeholder.isCompany;
  }

  public searchSupplier() {
    this.searchSupplierNotifier.next(this.caseStakeholder);
  }

  public nonEmptyProviderTransferredField(value: any, value2?: any): boolean {
    return !!this.caseStakeholder.company.providerId && value?.toString().length > 0 && value2?.toString().length > 0;
  }

  saveFax(event) {
    this.caseStakeholder.phoneNumbers = this.stakeholderService.setPhonenumber(
      PhoneNumberType.Fax,
      event,
      this.caseStakeholder.phoneNumbers
    );
    this.updateStakeholder(
      'fax',
      true
    );
  }
  getStakeholderType(stakeholder: CaseStakeholder): number | null {
    const stakeholderType = this.stakeholderstypes?.find(value => {
      return value.stakeholderTypeEnum === stakeholder.stakeholderType;
    });
    if (stakeholderType) {
      return stakeholderType.providerTypeId;
    } else {
      return null;
    }
  }

  updateStakeholderAsync() {
    this.caseService.updateStakeholderForCase(this.caseStakeholder);
  }

  saveFixedPhone(event) {
    this.caseStakeholder.phoneNumbers = this.stakeholderService.setPhonenumber(
      PhoneNumberType.Fixed,
      event,
      this.caseStakeholder.phoneNumbers
    );
    this.updateStakeholder(
      'fixedPhone',
      true
    );
  }

  saveMobilePhone(event) {
    this.caseStakeholder.phoneNumbers = this.stakeholderService.setPhonenumber(
      PhoneNumberType.Mobile,
      event,
      this.caseStakeholder.phoneNumbers
    );
    this.updateStakeholder(
      'mobilePhone',
      true
    );
  }

  saveGopPhone(event) {
    this.caseStakeholder.company.gop.phoneNumbers = this.stakeholderService.setPhonenumber(
      PhoneNumberType.Fixed,
      event,
      this.caseStakeholder.company.gop.phoneNumbers
    );
    this.updateStakeholder(
      'gopPhone',
      true
    );
  }

  saveGopFax(event) {
    this.caseStakeholder.company.gop.phoneNumbers = this.stakeholderService.setPhonenumber(
      PhoneNumberType.Fax,
      event,
      this.caseStakeholder.company.gop.phoneNumbers
    );
    this.updateStakeholder(
      'gopFax',
      true
    );
  }

  updateStakeholder(valueType: string, refresh: boolean) {
    this.caseService.updateStakeholderForCase(this.caseStakeholder);
    this.handleStakeholderUpdated(this.caseStakeholder, refresh, valueType);
  }

  updateDateOfBirthOrAgeOrGenderOrNationalId(valueType: string, value: any, refresh: boolean) {
    this.caseService.updateDateOfBirthOrAgeOrGenderOrNationalId(this.caseStakeholder).subscribe(
      result => {
        this.caseStateService.updatedStakeholder(this.caseStakeholder);
        this.caseStakeholderAdapter.adaptToExisting(this.caseStakeholder, result);
        if (this.caseStakeholder.hasRole(StakeholderTypeEnum.endUser)) {
          this.endUserStateService.sendEndUser(this.caseStakeholder);
        }
      }
    );
  }

  private handleStakeholderUpdated(result: CaseStakeholder, refresh: boolean, valueType: string) {
    this.caseStakeholder = result;
    if (refresh) {
      this.updatePrimaryContactWidget();
      if (valueType === 'nationalIdCountry') {
        this.stakeholderNationalIdChange.emit(this.caseStakeholder);
      }
    }
    if (this.caseStakeholder.person != null) {
      this.proceedWithNationalCountryCode();
    }
  }

  savePreferredLanguage(language: DropdownDictionary) {
    this.caseStakeholder.person.preferredLanguageCode = language.id;
    this.saveModel('preferredLanguage', this.caseStakeholder.person.preferredLanguageCode, false);
  }

  savePreferredGopLanguage(language: DropdownDictionary) {
    this.caseStakeholder.company.gop.preferredLanguageCode = language.id;
    this.saveModel('preferredLanguage', this.caseStakeholder.company.gop.preferredLanguageCode, false);
  }

  saveGopMethod() {
    if (
      this.caseStakeholder.company.gop.method !== GopMethodEnum.COPY_AGENT_OFFICE &&
      this.caseStakeholder.company.gop.method !== GopMethodEnum.VIA_AGENT_OFFICE
    ) {
      this.agentForStakeholder = null;
      this.updateAgentAndSave();
    } else {
      this.saveModel('gopMethod', this.caseStakeholder.company.gop.method);
    }
  }

  saveNationalId(nationalIdValue: string, country: CountryDetails) {
    nationalIdValue = nationalIdValue?.toUpperCase();
    if (nationalIdValue !== this.nonShowNationalId && nationalIdValue !== this.caseStakeholder.person.nationalId) {
      this.caseStakeholder.person.nationalId = nationalIdValue;
      this.updateDateOfBirthOrAgeOrGenderOrNationalId('nationalIdCountry', this.caseStakeholder.person.nationalIdCountry, true);
    }
    if (country) {
      if (this.caseStakeholder.person.nationalIdCountry.code !== country.code) {
        this.caseStakeholder.person.nationalIdCountry = country.toCountry();
        this.updateDateOfBirthOrAgeOrGenderOrNationalId('nationalIdCountry', this.caseStakeholder.person.nationalIdCountry, true);
      }
    } else if (!this.caseStakeholder.person.nationalIdCountry) {
      this.caseStakeholder.person.nationalIdCountry = new Country();
      this.updateDateOfBirthOrAgeOrGenderOrNationalId('nationalIdCountry', this.caseStakeholder.person.nationalIdCountry, true);
    }
  }

  agentStakeholderRequired() {
    return (
      this.caseStakeholder.company.gop.method === GopMethodEnum.COPY_AGENT_OFFICE ||
      this.caseStakeholder.company.gop.method === GopMethodEnum.VIA_AGENT_OFFICE
    );
  }

  get isCompanyStakeholderMedicalCategory() {
    return this.stakeholderService.isCompanyStakeholderMedicalCategory(this.caseStakeholder.stakeholderType);
  }

  focusInFunction() {
    this.theShownNationalId = this.caseStakeholder.person.nationalId;
  }

  focusOutFunction() {
    if (this.theShownNationalId) {
      let countryDetails: CountryDetails = null;
      if (this.caseStakeholder.person.nationalIdCountry) {
        countryDetails = new CountryDetails({
          id: this.caseStakeholder.person.nationalIdCountry.id,
          code: this.caseStakeholder.person.nationalIdCountry.code,
          countryName: this.caseStakeholder.person.nationalIdCountry.name
        });
      }
      this.saveNationalId(this.theShownNationalId, countryDetails);
    }
    this.proceedWithNationalCountryCode();
    this.theShownNationalId = this.nonShowNationalId;
  }

  updatePrimaryContactWidget() {
    this.primaryContactService.updatePrimary(this.caseStakeholder.stakeholderType, this.caseStakeholder);
  }

  displayMobilePhoneNumber(): boolean {
    return [StakeholderTypeEnum.medicalAdvisor.toString(), StakeholderTypeEnum.crisisPsychologist.toString(),
      StakeholderTypeEnum.escortDoctor.toString(), StakeholderTypeEnum.escortNurse.toString()].includes(
      this.caseStakeholder.stakeholderType
    );
  }

  getAddressClass(stakeholder: CaseStakeholder, disable: boolean): string {
    if (disable) {
      return 'is-disabled';
    } else if (stakeholder.stakeholderType !== 'REPORTER' && !stakeholder.address.street) {
      return 'recommended';
    } else {
      return '';
    }
  }

  get AutoCompleteTypeEnum() {
    return AutoCompleteTypeEnum;
  }

  get ProviderRatingEnum() {
    return ProviderRatingEnum;
  }
  supplierInformation(): void {
    const data: SupplierSearchData = {supplierId: +this.caseStakeholder.company.providerId, caseId: +this.caseId};
    const matDialogRef = this.dialogViewerService.openSupplierSearchDialogFromStakeholder(data);
    if ( matDialogRef ) {
      setTimeout(() => {
        this.dialogStateService.sendToFront(matDialogRef.id);
      }, 0);
    }
  }

}
