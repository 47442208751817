<div class="all-part">
  <ul ngbNav  #nav="ngbNav" class="nav-tabs" [(activeId)]="active" #caseEconomyTabs>
    <!--
    <li id="{{ caseEconomyTabEnum.OVERVIEW }}">
      <a ngbNavLink>Overview </ng-template>
      <ng-template ngbNavContent></ng-template>
    </li>
-->
    <li [ngbNavItem]="caseEconomyTabEnum.SERVICE_ORDERS" id="{{ caseEconomyTabEnum.SERVICE_ORDERS }}" *permission="PermissionEnum.PLANS_READ; hideType: PermissionHideTypeEnum.REMOVE">
      <a ngbNavLink (click)="tabClicked(caseEconomyTabEnum.SERVICE_ORDERS)">Service orders </a>
      <ng-template ngbNavContent>
        <app-case-economy-service-orders [case]="case"></app-case-economy-service-orders>
      </ng-template>
    </li>
    <li
      [ngbNavItem]="caseEconomyTabEnum.SUPPLIER_INVOICES"
      id="{{ caseEconomyTabEnum.SUPPLIER_INVOICES }}"
      *permission="PermissionEnum.TASK_SUPPLIER_INVOICE_READ; hideType: PermissionHideTypeEnum.REMOVE"
    >
      <a ngbNavLink (click)="tabClicked(caseEconomyTabEnum.SUPPLIER_INVOICES)" #tabSupplierInvoicesElement>Ref<span class="shortcut-key">u</span>nds & supplier invoices</a>
      <ng-template ngbNavContent>
        <app-case-economy-supplier-invoices [case]="case"></app-case-economy-supplier-invoices>
      </ng-template>
    </li>
    <li
      [ngbNavItem]="caseEconomyTabEnum.RECOVERY"
      id="{{ caseEconomyTabEnum.RECOVERY }}"
      *permission="PermissionEnum.RECOVERY_READ; hideType: PermissionHideTypeEnum.REMOVE"
    >
      <a ngbNavLink  (click)="tabClicked(caseEconomyTabEnum.RECOVERY)">Recovery </a>
      <ng-template ngbNavContent>
        <app-case-economy-recovery [case]="case"></app-case-economy-recovery>
      </ng-template>
    </li>
    <li
      [ngbNavItem]="caseEconomyTabEnum.RECOVERY_PAYMENT"
      id="{{ caseEconomyTabEnum.RECOVERY_PAYMENT }}"
      *permission="PermissionEnum.RECOVERY_READ; hideType: PermissionHideTypeEnum.REMOVE"
    >
      <a ngbNavLink  (click)="tabClicked(caseEconomyTabEnum.RECOVERY_PAYMENT)">Recovery payment </a>
      <ng-template ngbNavContent>
        <app-case-economy-recovery-payment [case]="case"></app-case-economy-recovery-payment>
      </ng-template>
    </li>
    <li
      [ngbNavItem]="caseEconomyTabEnum.SOS_SERVICES"
      id="{{ caseEconomyTabEnum.SOS_SERVICES }}"
      *permission="PermissionEnum.SALES_ORDER_READ; hideType: PermissionHideTypeEnum.REMOVE"
    >
      <a ngbNavLink (click)="tabClicked(caseEconomyTabEnum.SOS_SERVICES)">SOS services </a>
      <ng-template ngbNavContent>
        <app-case-economy-sos-service [case]="case"></app-case-economy-sos-service>
      </ng-template>
    </li>
    <li
      [ngbNavItem]="caseEconomyTabEnum.CUSTOMER_INVOICES"
      id="{{ caseEconomyTabEnum.CUSTOMER_INVOICES }}"
      *permission="PermissionEnum.SALES_ORDER_READ; hideType: PermissionHideTypeEnum.REMOVE"
    >
      <a ngbNavLink (click)="tabClicked(caseEconomyTabEnum.CUSTOMER_INVOICES)">Customer invoices </a>
      <ng-template ngbNavContent>
        <app-case-economy-customer-invoices [case]="case"></app-case-economy-customer-invoices>
      </ng-template>
    </li>
    <li
      [ngbNavItem]="caseEconomyTabEnum.PURCHASE_AND_SALES"
      id="{{ caseEconomyTabEnum.PURCHASE_AND_SALES }}"
      *permission="PermissionEnum.MEDICAL_ESCORT_SUPPLIER_INVOICE_READ; hideType: PermissionHideTypeEnum.REMOVE"
    >
      <a ngbNavLink (click)="tabClicked(caseEconomyTabEnum.PURCHASE_AND_SALES)">Purchase and sales </a>
      <ng-template ngbNavContent>
        <app-case-economy-purchase-and-sales [case]="case"></app-case-economy-purchase-and-sales>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</div>
