import { Injectable } from "@angular/core";
import { DateHelperService } from "@secca/core/services/date-helper.service";
import { Adapter } from "@secca/shared/interfaces/adapter";
import { AssessmentTypeEnum, HandlingAreaStatusEnum, PatientType } from "@secca/shared/models/enums";
import { IncidentEvent } from "@secca/shared/models/incidentEvent";

export class BoardIncidentEventCase {
    id: number;
    caseNumber: string;
    teamStatus: HandlingAreaStatusEnum;
    incidentId: number;
    endUserFullName: string;
    incidentEvent: IncidentEvent;
    customerProfileId: number;
    caseLockedBy: string;
    customerProfileName: string;
    caseTypeCode: string;
    incidentCause: string;
    createdOn: Date;
    createdOnLocalTime: Date;
    transportAssistanceType: string;
    incidentLocationCountry: string;
    incidentLocationAdmArea: string;
    incidentLocationLocality: string;
    incidentLocationAlpha2: string;
    currentLocationCountry: string;
    currentLocationAdmArea: string;
    currentLocationLocality: string;
    currentLocationAlpha2: string;
    age: number;
    nationalIdCountryName: string;
    homeCountryName: string;
    caseDetailsTBD: boolean;
    caseDetailsURN: boolean;
    caseDetailsCOF: boolean;
    caseDetailsAIR: boolean;
    caseDetailsSTR: boolean;
    caseDetailsEXT: boolean;
    caseDetailsSIT: boolean;
    caseDetailsREG: boolean;
    caseDetailsDOC: boolean;
    caseDetailsNUR: boolean;
    caseDetailsNME: boolean;
    caseDetailsOXY: boolean;
    caseDetailsEQU: boolean;
    caseDetailsDiagnosis: string;
    medicalServiceorderType: AssessmentTypeEnum;
    medicalServiceorderAssignee: string;
    medicalAssessmentPublished: Date;
    inpatientOutpatientOnCase: PatientType;
    inpatientOutpatientActive: boolean;
    relatedToICCCase: boolean;
    relatedToMajorIncidentCase: boolean;
    transportHandlingAreaStatus: HandlingAreaStatusEnum;
    medicalHandlingAreaStatus: HandlingAreaStatusEnum;
    claimsHandlingAreaStatus: HandlingAreaStatusEnum;
    costControlHandlingAreaStatus: HandlingAreaStatusEnum;

    public constructor(init?: Partial<BoardIncidentEventCase>) {
        Object.assign(this, init);
      }
}

@Injectable({
    providedIn: 'root'
  })
  export class BoardIncidentEventCaseAdapter implements Adapter<BoardIncidentEventCase> {
    constructor(private dateHelperService: DateHelperService) {}

    adapt(item: any): BoardIncidentEventCase {
      return new BoardIncidentEventCase({
        id: item.id,
        caseNumber: item.caseNumber,
        teamStatus: item.teamStatus,
        incidentId: item.incidentId,
        endUserFullName: item.endUserFullName,
        incidentEvent: item.incidentEvent,
        customerProfileId: item.customerProfileId,
        caseLockedBy: item.caseLockedBy,
        customerProfileName: item.customerProfileName,
        incidentCause: item.incidentCause,
        transportAssistanceType: item.transportAssistanceType,
        caseTypeCode: item.caseTypeCode,
        createdOn: new Date(item.createdOn),
        createdOnLocalTime: this.dateHelperService.convertFromUTCDateTimeToLocalDateTime(item.createdOn),
        incidentLocationCountry: item.incidentLocationCountry,
        incidentLocationAdmArea: item.incidentLocationAdmArea,
        incidentLocationLocality: item.incidentLocationLocality,
        incidentLocationAlpha2: item.incidentLocationAlpha2,
        currentLocationCountry: item.currentLocationCountry,
        currentLocationAdmArea: item.currentLocationAdmArea,
        currentLocationLocality: item.currentLocationLocality,
        currentLocationAlpha2: item.currentLocationAlpha2,
        age: item.age,
        nationalIdCountryName: item.nationalIdCountryName,
        homeCountryName: item.homeCountryName,
        inpatientOutpatientOnCase: item.inpatientOutpatientOnCase,
        inpatientOutpatientActive: item.inpatientOutpatientActive,
        relatedToICCCase: item.relatedToICCCase ? item.relatedToICCCase : false,
        relatedToMajorIncidentCase: item.relatedToMajorIncidentCase ? item.relatedToMajorIncidentCase : false,
        caseDetailsTBD: item.caseDetailsTBD,
        caseDetailsURN: item.caseDetailsURN,
        caseDetailsCOF: item.caseDetailsCOF,
        caseDetailsAIR: item.caseDetailsAIR,
        caseDetailsSTR: item.caseDetailsSTR,
        caseDetailsEXT: item.caseDetailsEXT,
        caseDetailsSIT: item.caseDetailsSIT,
        caseDetailsREG: item.caseDetailsREG,
        caseDetailsDOC: item.caseDetailsDOC,
        caseDetailsNUR: item.caseDetailsNUR,
        caseDetailsNME: item.caseDetailsNME,
        caseDetailsOXY: item.caseDetailsOXY,
        caseDetailsEQU: item.caseDetailsEQU,
        transportHandlingAreaStatus: item.transportHandlingAreaStatus,
        medicalHandlingAreaStatus: item.medicalHandlingAreaStatus,
        claimsHandlingAreaStatus: item.claimsHandlingAreaStatus,
        costControlHandlingAreaStatus: item.costControlHandlingAreaStatus
      });
    }
  }
