import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DropdownWitIconDictionary } from '@secca/shared/models/dropdownWithIconDictionary';
import { SubStakeholder } from '../../models/subStakeholder';
import { SubStakeholders } from '../../models/subStakeholders';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { MajorIncidentStakeholders } from '../../models/majorIncidentStakeholders';
import { MajorIncidentStakeholder } from '../../models/majorIncidentStakeholder';

@Component({
  selector: 'app-sub-major-incident-stakeholder',
  templateUrl: './sub-major-incident-stakeholder.component.html',
  styleUrls: ['./sub-major-incident-stakeholder.component.scss']
})
export class SubMajorIncidentStakeholderComponent {

  @Input() stakeholdersDropdown: DropdownWitIconDictionary[];
  @Input() subStakeholder: MajorIncidentStakeholder;
  @Input() stakeholders: MajorIncidentStakeholders;
  @Input() disabled: boolean;
  @Input() caseStakeholders: CaseStakeholder[];
  @Input() deletable: boolean;

  @Output() delete: EventEmitter<MajorIncidentStakeholder> = new EventEmitter();
  @Output() update: EventEmitter<MajorIncidentStakeholder> = new EventEmitter();

  setSelectedStakeholder(selectedStakeholder: CaseStakeholder) {
    this.subStakeholder.stakeholderId = selectedStakeholder.id;
    this.update.emit(this.subStakeholder);
  }

  getNameInPassport(stakeholderId: string): boolean {
    const selectedIccStakeholder = this.caseStakeholders.find(a => a.id === stakeholderId);
    return stakeholderId !== '0' ? selectedIccStakeholder.person?.nameInPassport : true;
  }

  deleteStakeholder() {
    if(this.deletable) {
      this.delete.emit(this.subStakeholder);
    }
  }

}
