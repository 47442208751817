
import { MomentHelperService } from '@secca/core/services/moment-helper.service';
import * as moment from 'moment';
import { DateSerializer } from '../date-serializer';
import { ServiceOrderExtension } from './service-order-extension';
import { NoDurationServiceOrderExtension } from './no-duration-service-order-extension';
import { CommitmentType } from '../commitment-type';

export class ReferalToCustomerServiceOrderExtension extends NoDurationServiceOrderExtension implements ServiceOrderExtension {
  displayDurationId: number;
  id: number;
  startDate: moment.Moment = MomentHelperService.getSeccaTime().startOf('day').utc();
  adviceGiven: string;

  public constructor(init?: Partial<ReferalToCustomerServiceOrderExtension>) {
    super();
    Object.assign(this, init);
  }

  isPreviewGopValid(): boolean {
    throw new Error("Gop preview not supported for this service order type");
  }

  isValid(): boolean {
    return this.startDate && this.startDate.isValid() && !!this.adviceGiven;
  }

  init() {}


  setCommitmentType(commitmentType: CommitmentType) {}

  getCommitmentType(): CommitmentType {
    return null;
  }

  getSelectedStakeholderIds(): string[] {
    return null;
  }
}

export class ReferalToCustomerServiceOrderExtensionAdapter {
  adapt(item?: any): ReferalToCustomerServiceOrderExtension {
    return new ReferalToCustomerServiceOrderExtension({
      id: item.id,
      startDate: DateSerializer.deserialize(item.startDate),
      adviceGiven: item.adviceGiven,
    });
  }
}
