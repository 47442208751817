import {TeamDto} from './teamDto';
import {HandlingArea} from '@secca/shared/models/handlingArea';
import {CaseScreening} from './caseScreening';
import {Injectable} from '@angular/core';
import {Adapter} from './../interfaces/adapter';
import {UserAdapter} from './user';
import {PhoneConversation} from './phoneConversation';
import {SimpleCaseStakeholder, SimpleCaseStakeholderAdapter} from './simpleCaseStakeholder';
import {SimpleIncident} from './SimpleIncident';
import {ChannelType} from '../enums/channelType';
import {SensitiveType, MedicalPreAssessmentDecisionEnum} from '../models/enums';
import {CaseLock, CaseLockAdapter} from './case-lock.model';

export class Case {
  team: TeamDto;
  cu: string;
  product: string;
  id: string;
  endUserName: string;
  sex: string;
  age: string;
  incidentId: number;
  incidentCountry: string;
  incidentCity: string;
  cause: string;
  channelType: string;
  countryCode: string;
  incidentTimeZone: string;
  currentTimeZone: string;
  caseCreatedDate: Date;
  validatedOn: Date;
  caseCreatedDateAsISOString: string;
  lastHandled: Date;
  timeSpendInMinutes: number;
  phoneConversation: PhoneConversation[];
  insuranceCheckTime: Date;
  insuranceCheckMethod: string;
  insuranceCheckUser: string;
  privacyNoticeTime: Date;
  privacyNoticeChannel: string;
  privacyNoticeSendTo: string;
  caseClosedTime: Date;
  caseClosedHandler: string;
  caseNumber: string;
  caseStakeholders: SimpleCaseStakeholder[];
  simpleCaseStakeholders: SimpleCaseStakeholder[];
  incident: SimpleIncident;
  enduserId: string;
  policyHolderId: string;
  reporterId: string;
  caseScreening: CaseScreening;
  customerProfileId: number;
  caseLocks: CaseLock[];
  handlingAreas: HandlingArea[];
  lastAcknowledged: Date;
  lastAcknowledgedBy: string;
  caseTypeCode: string;
  caseTypeTranslated: string;
  caseTypeCodeManuallySet: boolean;
  sensitiveType: SensitiveType;
  medicalPreAssessmentDecision: MedicalPreAssessmentDecisionEnum;
  coordinationCaseName: string;

  public constructor(init?: Partial<Case>) {
    Object.assign(this, init);
  }

}

@Injectable({
  providedIn: 'root'
})
export class CaseAdapter implements Adapter<Case> {

  constructor(private userAdapter: UserAdapter,
              private simpleCaseStakeholderAdapter: SimpleCaseStakeholderAdapter,
              private caseLockAdapter: CaseLockAdapter) {

  }

  adapt(item: any): Case {
    return new Case({
      team: item.team,
      cu: item.cu,
      product: item.product,
      id: item.id,
      endUserName: item.endUserName,
      sex: item.sex,
      age: item.age,
      incidentId: item.incidentId,
      incidentCountry: item.incidentCountry,
      incidentCity: item.incidentCity,
      cause: item.cause,
      channelType: item.channelType,
      countryCode: item.countryCode,
      incidentTimeZone: item.incidentTimezone,
      currentTimeZone: item.currentTimezone,
      caseCreatedDate: item.caseCreatedDate == "" ? null : new Date(item.caseCreatedDate),
      caseCreatedDateAsISOString: item.caseCreatedDateAsISOString,
      lastHandled: item.lastHandled == "" ? null : new Date(item.lastHandled),
      timeSpendInMinutes: item.timeSpendInMinutes,
      phoneConversation: item.phoneConversation,
      insuranceCheckTime: item.insuranceCheckTime == "" ? null : new Date(item.insuranceCheckTime),
      insuranceCheckMethod: item.insuranceCheckMethod,
      insuranceCheckUser: item.insuranceCheckUser,
      privacyNoticeTime: item.privacyNoticeTime == "" ? null : new Date(item.privacyNoticeTime),
      privacyNoticeChannel: item.privacyNoticeChannel,
      privacyNoticeSendTo: item.privacyNoticeSendTo,
      caseClosedTime: item.caseClosedTime == "" ? null : new Date(item.caseClosedTime),
      caseClosedHandler: item.caseClosedHandler,
      caseNumber: item.caseNumber,
      caseStakeholders: item.caseStakeholders,
      simpleCaseStakeholders: !!item.stakeholders ? this.adaptSimpleCaseStakeholders(item.stakeholders) : null,
      incident: item.incident,
      enduserId: item.enduserId,
      policyHolderId: item.policyHolderId,
      reporterId: item.reporterId,
      caseScreening: item.caseScreening,
      caseLocks: item.caseLocks.map(caseLock => this.caseLockAdapter.adapt(caseLock)),
      handlingAreas: item.handlingAreas,
      lastAcknowledged: item.lastAcknowledged ? new Date(item.lastAcknowledged) : null,
      lastAcknowledgedBy: item.lastAcknowledgedBy,
      caseTypeCode: item.caseTypeCode,
      caseTypeTranslated: item.caseTypeTranslated,
      caseTypeCodeManuallySet: item.caseTypeCodeManuallySet,
      validatedOn: item.validatedOn ? new Date(item.validatedOn) : null,
      sensitiveType: item.sensitiveType !== null ? item.sensitiveType: null,
      medicalPreAssessmentDecision: item.medicalPreAssessmentDecision !== null ? item.medicalPreAssessmentDecision: null,
    });
  }

  adaptSimpleCaseStakeholders(data: any[]): SimpleCaseStakeholder[] {
    return data.map(item => this.simpleCaseStakeholderAdapter.adapt(item));
  }

}
