import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'objectTypeTranslate' })
export class ObjectTypeTranslatePipePipe implements PipeTransform {

  constructor(private translate: TranslateService) {}

  transform(value: string): string {
    if (!value) { 
      return ''; 
    } 

    return this.translate.instant('case-objects-' + value);
  }
  
}