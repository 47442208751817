import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CaseService } from '@secca/core/services/case.service';
import { DictionaryService } from '@secca/core/services/dictionary.service';
import { SettingsService } from '@secca/core/services/settings.service';
import { AbstractGlobalBoardTableShortcutsComponent } from '@secca/shared/components/abstract-global-board-table-shortcuts.component';
import { TableSort } from '@secca/shared/models/table-sort';
import { BoardNavigationStateService } from '../../board-task/services/board-navigation-state.service';
import { Subscription } from 'rxjs';
import { UserDto } from '@secca/shared/models/userDto';
import { CommitmentTypes, RepatriationFormEnum, ShortcutEnum, SortOrder } from '@secca/shared/models/enums';
import { BoardTransportCase } from '../models/board-transport-case.model';

@Component({
  selector: 'app-board-transport-cases-table',
  templateUrl: './board-transport-cases-table.component.html',
  styleUrls: ['./board-transport-cases-table.component.scss']
})
export class BoardTransportCasesTableComponent extends AbstractGlobalBoardTableShortcutsComponent  implements OnInit, OnChanges, OnDestroy {


  @Input() set boardTransportCase(data: BoardTransportCase[]) {
    this._boardTransportCase = data;
    this.numberOfRows = data?.length;
    setTimeout(() => {
      this.selectedRowIndex = parseInt(this.boardNavigationStateService.getState('board-transport-case-filter-request-cases-selected-row-index'), 10);
      this.scrollPosition = this.boardNavigationStateService.getState('board-transport-case-filter-request-scroll-position') ? parseInt(this.boardNavigationStateService.getState('board-transport-case-filter-request-scroll-position'), 10) : 0;
      this.navigateToTable();
    }, 50);
  }


  get boardTransportCases(): BoardTransportCase[] {
    return this._boardTransportCase;
  }


  @Input() casesSorting: TableSort;
  @Output() sortingAction = new EventEmitter<TableSort>();


  sortedOn: any = { createdOn: true };
  sortOrder: any = { };
  scrollPosition = 0;

  $loggedUserSubscr: Subscription;
  loggedInUser: UserDto;

  private _boardTransportCase: BoardTransportCase[];



  constructor(private router: Router,
    private caseService: CaseService,
    private dictionaryService: DictionaryService,
    private translateService: TranslateService,
    public settingsService: SettingsService,
    private boardNavigationStateService: BoardNavigationStateService

    ) {
        super('caseTransportTabel');
        this.$loggedUserSubscr = dictionaryService.getLoggedInUser()
          .subscribe(user => this.loggedInUser = user
        );
        this.shortcutSubscriptions.push(
        this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateTableRowAltEnter }).subscribe(a => {
        if (typeof this.selectedRowIndex === 'number') {
          this.redirectToCase(this.boardTransportCases[this.selectedRowIndex].id);
        }
      }),
    );
  }


  ngOnInit(): void {
    if (this.casesSorting != null) {
      this.sortedOn = {};
      this.sortedOn[this.casesSorting.sortBy] = true;
      this.sortOrder[this.casesSorting.sortBy] = this.casesSorting.sortOrder;
    }
    const scrollPositionJson = this.boardNavigationStateService.getState('board-transport-case-filter-request-scroll-position');
    if (!!scrollPositionJson) {
      this.scrollPosition = JSON.parse(scrollPositionJson);
    }
  }

  ngOnChanges() {
    setTimeout(() => this.setScrollPosition(), 500);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.boardNavigationStateService.saveState('board-transport-case-filter-request-scroll-position', JSON.stringify(this.scrollPosition));
    this.boardNavigationStateService.saveState('board-transport-case-filter-request-incident-event-cases-selected-row-index', JSON.stringify(this.selectedRowIndex));
  }

  redirectToCase(id: any) {
    this.router.navigate(['case', id]);
  }

  onCaseTabelScroll() {
    this.scrollPosition = document.getElementById('caseTransportTabel').scrollTop;
  }

  setScrollPosition() {
    if (this.scrollPosition !== null && document.getElementById('caseTransportTabel')) {
      document.getElementById('caseTransportTabel').scrollTop = this.scrollPosition;
    }
  }

  getBrandImagesUrlForProfile(profileId: number) {
    return this.caseService.getBrandImagesUrlForProfile(profileId.toString());
  }

  sortedBy(column: string): string {
    return this.sortedOn[column] ? 'sorted-by' : '';
  }

  sortBy(column: string) {
    this.sortedOn = {};
    this.sortedOn[column] = true;
    if (this.sortOrder[column] == null) {
      this.sortOrder[column] = SortOrder.asc;
    } else {
      if (this.sortOrder[column] === SortOrder.asc) {
        this.sortOrder[column] = SortOrder.desc;
      } else {
        this.sortOrder[column] = SortOrder.asc;
      }
    }
    this.sortingAction.emit(new TableSort({ sortBy: column, sortOrder: this.sortOrder[column] }));
  }


  getRepatriationType(repatriationType: RepatriationFormEnum): string {
    return repatriationType ? this.translateService.instant('medical-assessment-repatriation-form-' + repatriationType.toString().toLowerCase().replace(/_/g, '-')) : '-';
  }

  get CommitmentTypes() {
    return CommitmentTypes;
  }

  showMedicalServiceOrderType(boardTransportCase: BoardTransportCase): boolean {
    return  !boardTransportCase.caseDetailsAIR &&
            !boardTransportCase.caseDetailsSTR &&
            !boardTransportCase.caseDetailsEXT &&
            !boardTransportCase.caseDetailsSIT &&
            !boardTransportCase.caseDetailsREG;
  }


  coTransported(value: boolean) {
    if(value === null) { return '-'; }
    else {return value === true ? 'Yes' : 'No'; }
  }


  caseDetailsEscort(boardTransportCase: BoardTransportCase): string {
    const mortalList: boolean[] = [boardTransportCase.caseDetailsDOC, boardTransportCase.caseDetailsNUR, boardTransportCase.caseDetailsNME];
    let returnValue: string;
    const mortalListNumbers = mortalList.filter(m => m === true).length;
    if (mortalListNumbers === 1) {
      if (boardTransportCase.caseDetailsDOC) { returnValue = this.translateService.instant('board-task-table-case-details-DOC'); }
      if (boardTransportCase.caseDetailsNUR) { returnValue = this.translateService.instant('board-task-table-case-details-NUR'); }
      if (boardTransportCase.caseDetailsNME) { returnValue = this.translateService.instant('board-task-table-case-details-NME'); }
    } else {
      returnValue = mortalListNumbers.toString();
    }
    return returnValue;
  }
}
