import { Message, MessageUtilService } from './message';
import { Adapter } from 'src/app/shared/interfaces/adapter';
import { Injectable } from '@angular/core';
import { StakeholderCcDto } from '../components/message-dialog/message-subject/message-form-cc/stakeholder-cc-dto';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import {PostHandlingsDto} from '@secca/shared/models/post-handlings-dto';

export class SendSmsRequestDto {
  caseId: string;
  text: string;
  internalSummary: string;
  receivers: string[];
  stakeholderReceiverId: number;
  stakeholderReceiverIdsAndNumbers: any;
  postHandlings: PostHandlingsDto;
  templateId: string;
  public constructor(init?: Partial<SendSmsRequestDto>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class SendSmsRequestDtoAdapter implements Adapter<SendSmsRequestDto> {

  constructor(private messageUtilService: MessageUtilService) {
  }

  adapt(message: Message): SendSmsRequestDto {
    const cc: StakeholderCcDto[] = this.messageUtilService.getStakeholderCcDtoItems(message);

    return new SendSmsRequestDto({
      caseId: message.caseId,
      text: message.text,
      internalSummary: message.internalSummary,
      receivers: this.mapToMobilePhoneNumbers(message.sms.phonePrefix + message.sms.phoneNumber, cc),
      stakeholderReceiverIdsAndNumbers: this.mapStakeholderIdsAndNumbers(message.receiver, message.sms.phonePrefix + message.sms.phoneNumber, cc),
      stakeholderReceiverId: +message.receiver.id,
      postHandlings: message.postHandlings,
      templateId: message.templateId
    });
  }
  private mapToMobilePhoneNumbers(receiver: string, cc: StakeholderCcDto[]): string[] {
    if (cc != null && cc.length > 0) {
      const mobileNumbers: string[] = cc.map(item => this.formatPhoneNumber(item.address));
      mobileNumbers.push(this.formatPhoneNumber(receiver));
      return mobileNumbers;
    }
    return [this.formatPhoneNumber(receiver)];
  }

  private mapStakeholderIdsAndNumbers(receiver: CaseStakeholder, receiverPhoneNumber: string, cc: StakeholderCcDto[]): any {
    var dict = {};
    dict[receiverPhoneNumber] = receiver.id;
    if (cc != null && cc.length > 0) {
      cc.forEach(item => {
        if(dict[item.address] === undefined){
          dict[item.address] = item.id;
        }
      });
    }
    return dict;
  }

  private formatPhoneNumber(phoneNumber: string): string {
    return phoneNumber.replace(/([\(\)])+/g, '');
  }

}
