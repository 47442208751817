import { RepatriationExtraSpace, RepatriationExtraSpaceAdapter } from './repatriation-extra-space';
import { ExtraSpaceEnum, TransitEnum } from './../enums';

import { Injectable } from '@angular/core';
import { Adapter } from '../../interfaces/adapter';
import * as moment from 'moment';

export class RepatriationForm {
  repatriationDate: moment.Moment;
  arrivalDeadline: moment.Moment;
  arrivalDescription: string;
  form: string;
  mobilization: string;
  seaLevelFlight: boolean;
  patientWeight: number;
  patientHeight: number;
  groundRepatriation: boolean;
  coTransport: boolean;
  coTransportDescription: string;
  transportToAirport: TransitEnum;
  transportFromAirport: TransitEnum;
  patientCarriableOnBoard: boolean;
  extraSpaces: RepatriationExtraSpace[];

  public constructor(init?: Partial<RepatriationForm>) {
    Object.assign(this, init);
  }

  public getExtraSpace(type: ExtraSpaceEnum): any {
    let value = null;
    this.extraSpaces.forEach(item => item.type === type ? value = item : {});
    return value;
  }

  public setExtraSpace(item: RepatriationExtraSpace) {
    this.removeExtraSpace(item.type);
    this.extraSpaces.push(item);
  }

  public removeExtraSpace(type: ExtraSpaceEnum) {
    this.extraSpaces = this.extraSpaces.filter(item => item.type !== type);
  }
}

@Injectable({
  providedIn: 'root'
})
export class RepatriationFormAdapter implements Adapter<RepatriationForm> {
  adapt(item: any): RepatriationForm {
    return new RepatriationForm({
      repatriationDate: item.repatriationDate,
      arrivalDeadline: item.arrivalDeadline,
      arrivalDescription: item.arrivalDescription,
      form: item.form,
      mobilization: item.mobilization,
      seaLevelFlight: item.seaLevelFlight,
      patientWeight: item.patientWeight,
      patientHeight: item.patientHeight,
      groundRepatriation: item.groundRepatriation,
      coTransport: item.coTransport,
      coTransportDescription: item.coTransportDescription,
      transportToAirport: item.transportToAirport,
      transportFromAirport: item.transportFromAirport,
      patientCarriableOnBoard: item.patientCarriableOnBoard,
      extraSpaces: !!item.extraSpaces ? this.adaptExtraSpaces(item.extraSpaces) : null
    });
  }

  private adaptExtraSpaces(data: any[]) {
    const extraSpaces = new Array<RepatriationExtraSpace>();
    data.forEach(item => extraSpaces.push(new RepatriationExtraSpaceAdapter().adapt(item)));
    return extraSpaces;
  }
}
