import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'subObjectTypeTranslate' })
export class SubObjectTypeTranslatePipe implements PipeTransform {

  constructor(private translate: TranslateService) {
  }

  transform(value: string): string {
    if (!value) {
      return '';
    }

    return this.translate.instant('case-sub-objects-' + value);
  }
}
