import {Injectable} from "@angular/core";

@Injectable()
export class LocaleService {
  private static readonly DEFAULT_LOCALE = 'en-DK';
  public static LOCALE = LocaleService.DEFAULT_LOCALE;

  get userLocale(): string {
    let userLocale = navigator.languages ? navigator.languages[0] : navigator.language;
    if (userLocale !== 'en-US') {
      userLocale = LocaleService.DEFAULT_LOCALE;
    }
    LocaleService.LOCALE = userLocale;
    return userLocale;
  }
}
