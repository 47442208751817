<div class="sub-component-container" >
  <div class="sub-title">{{'sub-medif-status-title' | translate}}</div>
  <div class="sub-content-container medif-status-container">
    <div class="row m-0">
      <div class="col-4">
        <div class="medif-status-button status-requested" [class.selected]="medifStatusType === MedifStatusTypeEnum.REQUESTED"
          (click)="setMedifStatus(MedifStatusTypeEnum.REQUESTED)">{{'sub-medif-status-type.REQUESTED' | translate}}</div>
      </div>
      <div class="col-4">
        <div class="medif-status-button status-authorized" [class.selected]="medifStatusType === MedifStatusTypeEnum.AUTHORIZED"
          (click)="setMedifStatus(MedifStatusTypeEnum.AUTHORIZED)">{{'sub-medif-status-type.AUTHORIZED' | translate}}</div>
      </div>
      <div class="col-4">
        <div class="medif-status-button status-rejected" [class.selected]="medifStatusType === MedifStatusTypeEnum.REJECTED"
          (click)="setMedifStatus(MedifStatusTypeEnum.REJECTED)">{{'sub-medif-status-type.REJECTED' | translate}}</div>
      </div>
    </div>
  </div>
</div>
