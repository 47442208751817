import { DocumentTemplateFilter } from './../../sub-components/sub-gop-details/documentTemplateFilter';
import { ServiceTypeComponent } from './../../models/interfaces';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ServiceTypeBase } from '../../models/serviceTypeBase';
import { AccommodationServiceOrderExtension } from '@secca/shared/models/service-order/accommodation-service-order-extension';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import { CommitmentTypes, PlansAccommodationTypeEnum, StakeholderTypeEnum, StatusTypes } from '@secca/shared/models/enums';
import { TranslateService } from '@ngx-translate/core';
import { SubStakeholdersConfig } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholdersConfig';
import { SubStakeholders } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholders';
import { SubStakeholder } from '../../models/subStakeholder';
import * as moment from 'moment-timezone';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';

@Component({
  selector: 'app-accommodation',
  templateUrl: './accommodation.component.html',
})
export class AccommodationComponent extends ServiceTypeBase implements OnInit, OnChanges, ServiceTypeComponent {

  private static subStakeholderConfig = new SubStakeholdersConfig({
    hideClass: true,
    hideInclPassportDetails: true,
    requireAtLeastOneStakeholder: true,
    availableStakeholderTypes : [
      StakeholderTypeEnum.endUser,
      StakeholderTypeEnum.policyHolder,
      StakeholderTypeEnum.reporter,
      StakeholderTypeEnum.escortDoctor,
      StakeholderTypeEnum.escortNurse,
      StakeholderTypeEnum.person,
      StakeholderTypeEnum.nonMedicalEscort
    ]
  });

  private static subConfirmationStakeholderConfig = new SubStakeholdersConfig({
    hideClass: true,
    hideInclPassportDetails: true,
    requireAtLeastOneStakeholder: true,
    hideAddButton: true
  });

  accommodationTemplatesFilter = new DocumentTemplateFilter('DOCUMENT', ['GOP', 'VOB'], 'ACCOMMODATION');

  @Input() gopPreviewModelChanged: boolean;

  @Input()
  accommodation: AccommodationServiceOrderExtension;

  @Input() caseId: string;
  @Input() incidentId: string;
  @Input() supplier: SubStakeholder;
  @Input() stakeholders: CaseStakeholder[];
  @Input() status: StatusTypes;
  subStakeholders: SubStakeholders;
  accommodationTypeDict: DropdownDictionary[];

  defaultStartDate = moment.utc().set({ hour: 9, minute: 0, second: 0, millisecond: 0 });
  defaultEndDate = moment.utc().set({ hour: 15, minute: 0, second: 0, millisecond: 0 });

  constructor(private translateService: TranslateService) {
    super();
  }

  ngOnInit() {
    this.subStakeholders = new SubStakeholders(this.accommodation.stakeholders);
    this.accommodationTypeDict = Object.keys(PlansAccommodationTypeEnum)
      .map(key => {
        return new DropdownDictionary(key, this.translateService.instant('plans-accommodation-type-' + key));
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }

  isValid(): boolean {
    return this.serviceOrderTypeState.showConfirmationTab ?
      this.accommodation.isValid() : this.accommodation.isValidExpectation();
  }

  getModel() {
    return this.accommodation;
  }

  get subStakeholderConfig(): SubStakeholdersConfig {
    return AccommodationComponent.subStakeholderConfig;
  }

  get subConfirmationStakeholderConfig(): SubStakeholdersConfig {
    return AccommodationComponent.subConfirmationStakeholderConfig;
  }
  get CommitmentTypes () {
    return CommitmentTypes;
  }

}
