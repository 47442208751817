import { HandlingAreaStatusEnum, HandlingAreaType } from './enums';
import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';

export class CoordinationList {
    caseNumber: string;
    caseId: number;
    name: string;
    id: string;
    primary: string;
    status: HandlingAreaStatusEnum;

    public constructor(init?: Partial<CoordinationList>) {
      Object.assign(this, init);
    }
}

@Injectable({
    providedIn: 'root'
})

export class CoordinationListAdapter implements Adapter<CoordinationList> {
    adapt(item: any): CoordinationList {
        return new CoordinationList({
            caseNumber: item.caseNumber,
            caseId: item.caseId,
            name: item.name,
            id: item.id,
            primary: item.primary,
            status: item.status,
        });
    }
}