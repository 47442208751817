import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectItem } from '@secca/case/components/case-medical/medical-components/medical-models';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import * as onChange from 'on-change';

@Component({
  selector: 'app-multiple-choice-component',
  templateUrl: 'multiple-choice.component.html',
  styleUrls: ['multiple-choice.component.scss'],
  animations: [
    trigger('toggleChecked', [
      state('checked', style({
        opacity: 1
      })),
      state('unchecked', style({
        opacity: 0
      })),
      transition('unchecked => checked', [
        animate('400ms', keyframes([
          style({ opacity: 0.5, boxShadow: '0 0 0 2px #23AFDC', offset: 0.5 }),
          style({ opacity: 1, boxShadow: '0 0 0 0px #23AFDC', offset: 1 })
        ]))
      ]),
      transition('checked => unchecked', [
        animate('300ms', keyframes([
          style({ opacity: 0.5, transform: 'scale(0)', offset: 0.5 }),
          style({ opacity: 0, offset: 1 })
        ]))
      ]),
    ])
  ]
})

export class MultipleChoiceComponent<T> implements OnInit {

  @Input() items: SelectItem<T>[];
  @Input() title: string;
  @Input() disabled: boolean;
  @Input() important: boolean;
  @Input() extraSpace: boolean;
  @Input() deepValueChangeListener: boolean;
  required: boolean;

  @Output() valueChangeEvent = new EventEmitter<SelectItem<T>>();

  constructor() {
  }

  ngOnInit(): void {
    if (this.deepValueChangeListener) {
      this.items.forEach((item, index) => this.items[index] = onChange(item, ((path, value, previousValue) => {
        this.valueChangeEvent.emit(item);
      })));
    }
    this.required = true;
    if (this.items) {
      this.items.forEach(item => { if (item.selected === true) { this.required = false; } });
    }
  }

  public select(value: SelectItem<T>) {
    if (this.disabled !== true) {
      value.selected = !value.selected;
      this.valueChangeEvent.emit(value);
      this.required = true;
      this.items.forEach(item => { if (item.selected === true) { this.required = false; } });
    }
  }
}
