import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CaseService } from '@secca/core/services/case.service';
import { DictionaryService } from '@secca/core/services/dictionary.service';
import { SettingsService } from '@secca/core/services/settings.service';
import { AbstractGlobalBoardTableShortcutsComponent } from '@secca/shared/components/abstract-global-board-table-shortcuts.component';
import { CommitmentTypes, PatientType, ShortcutEnum, SortOrder, TransportAssistanceType } from '@secca/shared/models/enums';
import { TableSort } from '@secca/shared/models/table-sort';
import { UserDto } from '@secca/shared/models/userDto';
import { BoardIncidentEventCase } from '../models/board-incident-event-case.model';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { BoardNavigationStateService } from '../../board-task/services/board-navigation-state.service';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';

@Component({
  selector: 'app-board-incident-event-cases-table',
  templateUrl: './board-incident-event-cases-table.component.html',
  styleUrls: ['./board-incident-event-cases-table.component.scss']
})
@AutoUnsubscribe
export class BoardIncidentEventCasesTableComponent extends AbstractGlobalBoardTableShortcutsComponent  implements OnInit, OnChanges, OnDestroy {
  @Input() set boardIncidentEventCases(data: BoardIncidentEventCase[]) {
    this._boardIncidentEventCase = data;
    this.numberOfRows = data?.length;
    setTimeout(() => {
      this.selectedRowIndex = parseInt(this.boardNavigationStateService.getState('board-incident-event-case-filter-request-incident-event-cases-selected-row-index'), 10);
      this.scrollPosition = this.boardNavigationStateService.getState('board-incident-event-case-filter-request-scroll-position') ? parseInt(this.boardNavigationStateService.getState('board-incident-event-case-filter-request-scroll-position'), 10) : 0;
      this.navigateToTable();
    }, 50);
  }
  get boardIncidentEventCases(): BoardIncidentEventCase[] {
    return this._boardIncidentEventCase;
  }

  @Input() casesSorting: TableSort;
  @Output() sortingAction = new EventEmitter<TableSort>();

  @Input() caseTypes: DropdownDictionary[];

  sortedOn: any = { createdOn: true };
  sortOrder: any = { };
  scrollPosition = 0;

  $loggedUserSubscr: Subscription;
  loggedInUser: UserDto;

  private _boardIncidentEventCase: BoardIncidentEventCase[];

  constructor(private router: Router,
              private caseService: CaseService,
              private dictionaryService: DictionaryService,
              private translateService: TranslateService,
              public settingsService: SettingsService,
              private boardNavigationStateService: BoardNavigationStateService

              ) {
      super('caseIncidentTabel');
      this.$loggedUserSubscr = dictionaryService.getLoggedInUser()
        .subscribe(user => this.loggedInUser = user
      );
      this.shortcutSubscriptions.push(
        this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateTableRowAltEnter }).subscribe(a => {
          if (typeof this.selectedRowIndex === 'number') {
            this.redirectToCase(this.boardIncidentEventCases[this.selectedRowIndex].id);
          }
        }),
      );
    }

  ngOnInit(): void {
    if (this.casesSorting != null) {
      this.sortedOn = {};
      this.sortedOn[this.casesSorting.sortBy] = true;
      this.sortOrder[this.casesSorting.sortBy] = this.casesSorting.sortOrder;
    }
    const scrollPositionJson = this.boardNavigationStateService.getState('board-incident-event-case-filter-request-scroll-position');
    if (!!scrollPositionJson) {
      this.scrollPosition = JSON.parse(scrollPositionJson);
    }
  }

  ngOnChanges() {
    setTimeout(() => this.setScrollPosition(), 500);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.boardNavigationStateService.saveState('board-incident-event-case-filter-request-scroll-position', JSON.stringify(this.scrollPosition));
    this.boardNavigationStateService.saveState('board-incident-event-case-filter-request-incident-event-cases-selected-row-index', JSON.stringify(this.selectedRowIndex));
  }

  setScrollPosition() {
    if (this.scrollPosition !== null && document.getElementById('caseIncidentTabel')) {
      document.getElementById('caseIncidentTabel').scrollTop = this.scrollPosition;
    }
  }

  sortedBy(column: string): string {
    return this.sortedOn[column] ? 'sorted-by' : '';
  }

  sortBy(column: string) {
    this.sortedOn = {};
    this.sortedOn[column] = true;
    if (this.sortOrder[column] == null) {
      this.sortOrder[column] = SortOrder.asc;
    } else {
      if (this.sortOrder[column] === SortOrder.asc) {
        this.sortOrder[column] = SortOrder.desc;
      } else {
        this.sortOrder[column] = SortOrder.asc;
      }
    }
    this.sortingAction.emit(new TableSort({ sortBy: column, sortOrder: this.sortOrder[column] }));
  }

  onCaseTabelScroll() {
    this.scrollPosition = document.getElementById('caseIncidentTabel').scrollTop;
  }

  getBrandImagesUrlForProfile(profileId: number) {
    return this.caseService.getBrandImagesUrlForProfile(profileId.toString());
  }

  redirectToCase(id: any) {
    this.router.navigate(['case', id]);
  }

  getCaseType(caseType: any): string {
    return this.caseTypes ? this.caseTypes.find(type => type.id === caseType).name : '';
  }


  getTransportType(transportType: TransportAssistanceType): string {
    return transportType ? this.translateService.instant('incident-event-transport-arranged-by-sos-' + transportType.toString().toLowerCase().replace(/_/g, '-')) : '-';
  }

  showMedicalServiceOrderType(boardIncidentEventCase: BoardIncidentEventCase): boolean {
    return  !boardIncidentEventCase.caseDetailsAIR &&
            !boardIncidentEventCase.caseDetailsSTR &&
            !boardIncidentEventCase.caseDetailsEXT &&
            !boardIncidentEventCase.caseDetailsSIT &&
            !boardIncidentEventCase.caseDetailsREG;
  }

  caseDetailsEscort(boardIncidentEventCase: BoardIncidentEventCase): string {
    const mortalList: boolean[] = [boardIncidentEventCase.caseDetailsDOC, boardIncidentEventCase.caseDetailsNUR, boardIncidentEventCase.caseDetailsNME];
    let returnValue: string;
    const mortalListNumbers = mortalList.filter(m => m === true).length;
    if (mortalListNumbers === 1) {
      if (boardIncidentEventCase.caseDetailsDOC) { returnValue = this.translateService.instant('board-task-table-case-details-DOC'); }
      if (boardIncidentEventCase.caseDetailsNUR) { returnValue = this.translateService.instant('board-task-table-case-details-NUR'); }
      if (boardIncidentEventCase.caseDetailsNME) { returnValue = this.translateService.instant('board-task-table-case-details-NME'); }
    } else {
      returnValue = mortalListNumbers.toString();
    }
    return returnValue;
  }

  get CommitmentTypes() {
    return CommitmentTypes;
  }

  get PatientType() {
    return PatientType;
  }
}
