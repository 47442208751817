import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'htmlAttributeModifier'
})
export class HtmlAttributeModifierPipe implements PipeTransform {
  public transform(html: string, tagName: string, attributeName: string, attributeValue: string): string {
    if (html == null || tagName == null || attributeName == null || attributeValue == null) {
      return html;
    }

    const element = this.createElementWithHTML(html);
    const elements = Array.from(element.getElementsByTagName(tagName));
    elements.forEach(element => element.setAttribute(attributeName, attributeValue));
    return element.innerHTML;
  }

  private createElementWithHTML(html: string): Element {
    let element = document.createElement('html');
    element.innerHTML = html;
    return element;
  }
}
