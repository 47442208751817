import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MedicalNote } from '@secca/shared/models/medical-models/medical-note';
import { MedicalNoteTypeEnum } from '@secca/shared/models/enums';
import { MedicalService } from '@secca/core/services/medical.service';
import { CaseStateService } from '@secca/core/state-services/case-state.service';

@Component({
  selector: 'app-medical-note',
  templateUrl: './medical-note.component.html',
  styleUrls: ['./medical-note.component.scss']
})
export class MedicalNoteComponent implements OnInit {

  @Output() updateMedicalHistory = new EventEmitter();

  private _caseId: number;
  @Input()
  set caseId(newCaseId: number) {
    if (newCaseId !== undefined) {
      this._caseId = newCaseId;
    }
  }

  get caseId(): number {
    return this._caseId;
  }

  constructor(private modalService: NgbModal,
              private medicalService: MedicalService,
              private caseStateService: CaseStateService) { }

  noteTextValid: boolean;
  medicalNote: MedicalNote;


  ngOnInit() {
    this.noteTextValid = true;
    this.medicalNote = new MedicalNote();
    this.medicalNote.noteType = MedicalNoteTypeEnum.COMMENT;
    this.medicalNote.seccaCaseId = this.caseId;
  }

  close() {
    this.modalService.dismissAll();
  }

  addComment() {
    this.noteTextValid = true;
    if ( this.isMedicalPreAssessment ) {
      this.medicalNote.noteType = MedicalNoteTypeEnum.PRE_ASSESSMENT_COMMENT;
    }
    this.medicalService.publishMedicalNote(this.medicalNote).subscribe(result => {
      this.updateMedicalHistory.emit();
      this.close();
    }, error => console.log(error));
  }

  textChanged() {
    this.medicalNote.noteText ? (this.medicalNote.noteText.length > 0 ? this.noteTextValid = false : this.noteTextValid = true) : this.noteTextValid = false;
  }

  changeNoteType() {
    this.medicalNote.noteType = this.medicalNote.noteType === MedicalNoteTypeEnum.COMMENT ? MedicalNoteTypeEnum.ADMISSION : MedicalNoteTypeEnum.COMMENT;
  }

  get isMedicalPreAssessment() {
    return this.caseStateService.isCaseMedicalPreAssessment;
  }
}
