<div class="modal-content">
  <ng-container *ngIf="configuration">
    <div class="modal-header">
      <h2>
        {{ configuration.header | translate }}
      </h2>
      <div>
        <div class="modal-close">
          <img class="cross-close" src="/assets/icons/Close White.svg" (click)="close(false)">
        </div>
      </div>
    </div>
    <div class="modal-body pb-0">
      <div class="lane text">
        {{ configuration.contactSupport | translate }}
      </div>
    </div>
    <div class="modal-body pt-1">
      <ul ngbNav #nav="ngbNav" class="nav-tabs" [(activeId)]="active" (navChange)="fetchError($event)">
        <li *ngFor="let error of configuration.errors; let i = index" id="{{ERROR_TYPE.ERROR}}-{{i}}" [ngbNavItem]="ERROR_TYPE.ERROR + '-' + i">

            <a ngbNavLink>{{error.name}}</a>
            <ng-template ngbNavContent>
              <div class="global-error global-error-body">
                {{error.message}}
              </div>
              <div class="global-error global-error-stack">
                {{error.stack}}
              </div>
            </ng-template>
          </li>
          <li *ngFor="let error of configuration.httpErrors; let ii = index;" id="{{ERROR_TYPE.HTTP_ERROR}}-{{ii}}" [ngbNavItem]="ERROR_TYPE.HTTP_ERROR + '-' + ii">
            <a ngbNavLink>{{error.name}}</a>
            <ng-template ngbNavContent>
              <div class="global-error global-error-body">
                {{error.message}} <br>
                {{'global-error-modal-status' | translate }} {{error.statusText}} - {{error.status}}<br>
                Url: {{error.url}}<br>
              </div>
              <div class="global-error global-error-stack">
                {{error.error | json}}
              </div>
            </ng-template>
          </li>
      </ul>
      <div [ngbNavOutlet]="nav"></div>
    </div>

    <div class="modal-footer">
      <div class="lane w-100">
        <div class="float-end">
            <label class="checkbox" for="suppress">
              <span class="label">{{'global-error-modal-suppress-20-mins' | translate }}</span>
              <input id="suppress" type="checkbox" [(ngModel)]="suppressErrors" (change)="saveModel()"/>
            </label>
        </div>
      </div>
    </div>

  </ng-container>
</div>
