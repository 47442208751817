import {Component, Input, OnInit} from '@angular/core';
import {ServiceTypeBase} from '../../models/serviceTypeBase';
import {ServiceTypeComponent} from './../../models/interfaces';
import {ServiceTypeEnum} from '@secca/shared/models/enums';
import {SubStakeholders} from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholders';
import {ServiceOrderTypeState} from '@secca/case/components/case-plans/case-plans/add-service-order/models/serviceOrderTypeState';
import {CaseStakeholder} from '@secca/shared/models/caseStakeholder';
import {OtherRefundRequestServiceOrderExtension} from "@secca/shared/models/service-order/other-refund-request-service-order-extension";
import {CaseStateService} from "@secca/core/state-services/case-state.service";
import * as moment from 'moment-timezone';
import {MomentHelperService} from "@secca/core/services/moment-helper.service";

@Component({
  selector: 'app-other-refund-request',
  templateUrl: './other-refund-request.component.html',
  styleUrls: ['./other-refund-request.component.scss'],
})
export class OtherRefundRequestComponent extends ServiceTypeBase implements OnInit, ServiceTypeComponent {
  @Input() caseId: string;
  @Input() serviceOrderTypeState: ServiceOrderTypeState;
  @Input() public otherRefundRequest: OtherRefundRequestServiceOrderExtension;
  @Input() public stakeholders: CaseStakeholder[];
  @Input() leftSideValid: boolean;

  defaultStartDate;
  defaultEndDate;
  setDefaultDates: boolean = false;
  subStakeholders: SubStakeholders;
  //serviceOrderType = ServiceTypeEnum.OTHER_REFUND_REQUEST;

  constructor(private caseService: CaseStateService) {
    super();

  }

  ngOnInit() {
    this.caseService.getCase().subscribe(seccaCase => {
      if(seccaCase.incident.incidentDate){
        let incidentDate: moment.Moment;
        if(seccaCase.incident.incidentDate instanceof moment){
          incidentDate = seccaCase.incident.incidentDate;
        }else{
          incidentDate = moment(seccaCase.incident.incidentDate,'YYYY-MM-DD');
        }
        this.defaultStartDate =  incidentDate.clone().tz(MomentHelperService.getSeccaTimeZoneId()).startOf('day');
        this.defaultEndDate =  incidentDate.clone().tz(MomentHelperService.getSeccaTimeZoneId()).endOf('day');
        this.setDefaultDates = true;
      }
    });
  }

  isValid(): boolean {
    let result: boolean;
    result = this.otherRefundRequest.isValid()
    return result;
  }

  getModel(): any {
    return this.otherRefundRequest;
  }

  disableSendRequest(): boolean {
    return !(this.isValid() && !!this.leftSideValid && this.serviceOrderTypeState.isSupplierChosen);
  }
}
