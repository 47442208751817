import { CallType } from './enums';
import { Injectable } from '@angular/core';
import { Adapter } from './../interfaces/adapter';

export class PhoneConversation {
  id: string;
  user: string;
  durationInMinutes: number;
  callType: CallType;
  phoneNumber: string;
  when: Date;

  public constructor(init?: Partial<PhoneConversation>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class PhoneConversationAdapter implements Adapter<PhoneConversation> {
  adapt(item: any): PhoneConversation {
    return new PhoneConversation({
      id: item.id,
      user: item.user,
      durationInMinutes: item.durationInMinutes,
      callType: item.callType,
      phoneNumber: item.phoneNumber,
      when: new Date(item.when)
    });
  }
}
