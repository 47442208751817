import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
import { CaseStakeholder, CaseStakeholderAdapter } from '@secca/shared/models/caseStakeholder';
import { ServiceOrder, ServiceOrderAdapter } from '@secca/shared/models/service-order/service-order';

// TODO: Rename to LinkedEndUserCaseDetails
export class CoordinationCaseDetail {
    caseId: number;
    id: string;
    stakeholders: CaseStakeholder[];
    serviceOrders: ServiceOrder[];
    caseNumber: string;
    incidentLocation: string;

    public constructor(init?: Partial<CoordinationCaseDetail>) {
      Object.assign(this, init);
    }
}

@Injectable({
    providedIn: 'root'
})

export class CoordinationCaseDetailAdapter implements Adapter<CoordinationCaseDetail> {

    constructor(
      private caseStakeholderAdapter: CaseStakeholderAdapter,
      private serviceOrderAdapter: ServiceOrderAdapter) {
    }

    adapt(item: any): CoordinationCaseDetail {
        return new CoordinationCaseDetail({
            caseId: item.caseId,
            id: item.id,
            stakeholders: item.stakeholders.map(stakeholder => this.caseStakeholderAdapter.adapt(stakeholder)),
            serviceOrders: item.serviceOrders.map(serviceOrder => this.serviceOrderAdapter.adapt(serviceOrder)),
            caseNumber: item.caseNumber,
            incidentLocation: item.incidentLocation
        });
    }
}
