
<div class="minimized-container">
  <div *ngFor="let index of [0,1,2,3,4,5,6,7]">
      <div *ngIf="minimizedList[index] as minimizedData" [ngbPopover]="getHoverText(minimizedData.additionalInfo)" [popoverTitle]="getTitle(minimizedData.additionalInfo)" popoverClass="minimized-hover-style" openDelay="200" triggers="hover">
        <div class="minimized-elem" [class.minimized-elem-disabled]="navigationActive || loading">
          <div [ngSwitch]="minimizedData.dialogType">
            <div tabindex="0" *ngSwitchCase="MinimizableDialogType.Task" class="box image minimized-icon-size" [ngStyle]="getTaskImageIcon(minimizedData.additionalInfo)" (click)="maximize(minimizedData.dialogId)"></div>
            <div tabindex="0" *ngSwitchCase="MinimizableDialogType.ServicePlan" class="box image minimized-icon-size" [ngStyle]="getServicePlanImageIcon(minimizedData.additionalInfo)" (click)="maximize(minimizedData.dialogId)"></div>
            <div tabindex="0" *ngSwitchCase="MinimizableDialogType.SupplierInvoice" class="box image supplier-invoice-task-icon" (click)="maximize(minimizedData.dialogId)"></div>
            <div tabindex="0" *ngSwitchCase="MinimizableDialogType.Claims" class="box image refund-icon" (click)="maximize(minimizedData.dialogId)"></div>
            <div tabindex="0" *ngSwitchCase="MinimizableDialogType.CaseNew" class="box image case-new-icon" (click)="maximize(minimizedData.dialogId)"></div>
            <div tabindex="0" *ngSwitchCase="MinimizableDialogType.MedicalAssessment" class="box image medical-assessment-icon" (click)="maximize(minimizedData.dialogId)"></div>
            <div tabindex="0" *ngSwitchCase="MinimizableDialogType.MedicalPreAssessment" class="box image medical-pre-assessment-icon" (click)="maximize(minimizedData.dialogId)"></div>
            <div tabindex="0" *ngSwitchCase="MinimizableDialogType.PolicyLookup" class="box image policy-lookup-icon" (click)="maximize(minimizedData.dialogId)"></div>
            <div tabindex="0" *ngSwitchCase="MinimizableDialogType.PolicyView" class="box image policy-view-icon" (click)="maximize(minimizedData.dialogId)"></div>
            <div tabindex="0" *ngSwitchCase="MinimizableDialogType.ProfileLookup" class="box image profile-lookup-icon" (click)="maximize(minimizedData.dialogId)"></div>
            <div tabindex="0" *ngSwitchCase="MinimizableDialogType.CaseDocument" class="box image minimized-icon-size" [ngStyle]="getCaseDocumentImageIcon(minimizedData.additionalInfo)" (click)="maximize(minimizedData.dialogId)"></div>
            <div tabindex="0" *ngSwitchCase="MinimizableDialogType.CaseSummaryEmailFax" class="box image minimized-icon-size" [ngStyle]="getCaseSummaryEmailFaxImageIcon(minimizedData.additionalInfo)" (click)="maximize(minimizedData.dialogId)"></div>
            <div tabindex="0" *ngSwitchCase="MinimizableDialogType.CaseSummarySms" class="box image case-summary-sms-icon" (click)="maximize(minimizedData.dialogId)"></div>
            <div tabindex="0" *ngSwitchCase="MinimizableDialogType.CaseSummaryNote" class="box image case-summary-note-icon" (click)="maximize(minimizedData.dialogId)"></div>
            <div tabindex="0" *ngSwitchCase="MinimizableDialogType.CaseSummaryExternal" class="box image case-summary-external-icon" (click)="maximize(minimizedData.dialogId)"></div>
            <div tabindex="0" *ngSwitchCase="MinimizableDialogType.CaseFax" class="box image case-fax-icon" (click)="maximize(minimizedData.dialogId)"></div>
            <div tabindex="0" *ngSwitchCase="MinimizableDialogType.CaseEmail" class="box image case-email-icon" (click)="maximize(minimizedData.dialogId)"></div>
            <div tabindex="0" *ngSwitchCase="MinimizableDialogType.CaseSms" class="box image case-sms-icon" (click)="maximize(minimizedData.dialogId)"></div>
            <div tabindex="0" *ngSwitchCase="MinimizableDialogType.SearchProvider" class="box image minimized-icon-size" [ngStyle]="getSupplierSearchImageIcon(minimizedData.additionalInfo)" (click)="maximize(minimizedData.dialogId)"></div>
            <div tabindex="0" *ngSwitchCase="MinimizableDialogType.StakeholderSupplierSearch" class="box image minimized-icon-size" [ngStyle]="getSupplierSearchInformationIcon(minimizedData.additionalInfo)" (click)="maximize(minimizedData.dialogId)"></div>
            <div tabindex="0" *ngSwitchCase="MinimizableDialogType.CaseCountryInfo" class="box image country-info-icon" (click)="maximize(minimizedData.dialogId)"></div>
            <div tabindex="0" *ngSwitchDefault class="box image minimized-default-icon minimized-icon-size" (click)="maximize(minimizedData.dialogId)"></div>
          </div>
        </div>
      </div>
      <div *ngIf="!minimizedList[index]">
        <div class="minimized-elem">
          <div class="box"></div>
        </div>
      </div>
  </div>
</div>
