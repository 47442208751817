import { ValidationTypeEnum } from './../../models/enums';
import { InputValidationService } from '../../../core/services/input-validation.service';
import { CountryDetails } from './../../models/country';
import { AutoUnsubscribe } from 'src/app/shared/decorators/auto-unsubscribe';
import { DictionaryService } from '../../../core/services/dictionary.service';
import { Component, ViewEncapsulation, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';
import { OnSaveData } from '../../interfaces/on-save-data';
import { PhoneService } from '@secca/core/services/phone.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProviderHelper } from '@secca/shared/helpers/provider-helper';

@Component({
  selector: 'app-phone-drop-down',
  templateUrl: './phone-drop-down.component.html',
  styleUrls: ['./phone-drop-down.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
@AutoUnsubscribe
export class PhoneDropDownComponent implements OnInit, OnSaveData {
  @ViewChild('select') select: NgSelectComponent;
  @ViewChild('phoneMessageModalContent') phoneMessageModalContent: TemplateRef<any>;
  @Input() required: boolean;
  @Input() recommended: boolean;
  @Input() name: string;
  @Input() set selectedItemId(value: any) {
    this.currentSelectedItemId = value;
    if (!!value && this.isRemovableCountry) {
      this.getCountryForCode(value);
    }
  }
  get selectedItemId() {
    return this.currentSelectedItemId;
  }
  @Input() placeholder: string;
  @Input() countryName: string;
  @Input() disabled: boolean;

  @Input() showCode: boolean;
  @Input() withInput: boolean;
  @Input() withPrefixInput: boolean;
  @Input() prefixValue: string;
  @Input() value: string;
  @Input() showEye: boolean;
  @Input() blockEmail: boolean;
  @Input() validate: boolean;
  @Input() validationType: ValidationTypeEnum;
  @Input() validationTypeToInput: string;
  @Input() isValid: boolean;
  @Input() highlightBorderOnInputNonEmpty = false;
  @Input() set removableCountry(value: boolean) {
    this.isRemovableCountry = value;
    if (!!this.currentSelectedItemId && this.isRemovableCountry) {
      this.getCountryForCode(this.currentSelectedItemId);
    }
  }
  @Output() performCall = new EventEmitter<{ phoneCountryCode: string; phonePrefix: string; phoneNumber: string }>();
  @Output() selectedItemIdChange = new EventEmitter();
  @Output() saveModel = new EventEmitter<{ phoneCountryCode: string; phonePrefix: string; phoneNumber: string }>();
  @Output() valueChange = new EventEmitter();
  @Output() prefixValueChange = new EventEmitter();
  
  isRemovableCountry = false;
  currentSelectedItemId: any;
  currentSelectedCountry: CountryDetails;
  subscription: Subscription;
  subscription2: Subscription;
  items: Observable<CountryDetails[]>;
  oldSelectedItemId: any;
  oldValue: string;
  oldPrefixValue: string;
  input = new Subject<string | null>();
  $inputSubscr: Subscription;

  private providerHelper: ProviderHelper;

  constructor(
    private dictionaryService: DictionaryService,
    private inputValidationService: InputValidationService,
    private phoneService: PhoneService,
    private modalService: NgbModal
  ) {
    this.$inputSubscr = this.input.subscribe(newTerm => {
      this.search(newTerm);
    });
  }

  public setFocus() {
    this.select.focus();
  }

  ngOnInit() {}

  isValidated(val: any) {
    return this.inputValidationService.isValidated(val, this.validationType);
  }

  onChange(e: any) {
    this.prefixValue = e.phonePrefix;
    this.onSaveModel();
  }

  onPrefixChange(e: any) {
    this.prefixValueChange.emit(this.prefixValue);
  }

  onInputChange(e: any) {
    if(this.value) {
      this.value = this.value.replace(/[^\d]+/,'');
    }
    this.valueChange.emit(this.value);
  }

  onSaveModel() {
    if (this.oldValue !== this.value || this.oldSelectedItemId !== this.selectedItemId || this.oldPrefixValue !== this.prefixValue) {
      this.saveModel.emit({ phoneCountryCode: this.selectedItemId, phonePrefix: this.prefixValue?.replace(/ /g, ''), phoneNumber: this.value?.replace(/[^\d]/g, '') });
    }
  }

  onSaveState() {
    this.oldSelectedItemId = this.selectedItemId;
    this.oldValue = this.value;
    this.oldPrefixValue = this.prefixValue;
  }

  onItemClick(item: CountryDetails) {
    if (item.code === this.selectedItemId && this.isRemovableCountry) {
      this.clearSearchList();
      this.currentSelectedItemId = null;
    }
  }

  private clearSearchList() {
    this.items = of([]);
  }

  private search(term: string | null) {
    if (term) {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      this.subscription = this.dictionaryService
        .searchCountries(term)
        .pipe(debounceTime(100))
        .pipe(result => (this.items = result))
        .subscribe();
    }
  }

  private getCountryForCode(code: string) {
    if (this.subscription2) {
      this.subscription2.unsubscribe();
    }
    if (!this.currentSelectedCountry || this.currentSelectedItemId !== this.currentSelectedCountry.code ) {
      this.subscription2 = this.dictionaryService
        .getCountryByAlpha2(code)
        .pipe(tap(result => this.currentSelectedCountry = result))
        .pipe(map(result => [result]))
        .pipe(result => (this.items = result))
        .subscribe();
    } else {
        this.items = of([this.currentSelectedCountry]);
      }
  }

  private openProviderModal(error: any) {
    this.providerHelper = new ProviderHelper(this.modalService);
    this.providerHelper.messageError(error, 'phone-error-modal-header', 'phone-error-title', 'phone-error-message');
  }

  onPerformCall() {
    this.phoneService.performCall(this.prefixValue, this.value).subscribe(
      result => {},
      error => {
        console.log('ERROR:' + JSON.stringify(error));
        this.openProviderModal(error);
      }
    );

    this.performCall.emit({ phoneCountryCode: this.selectedItemId, phonePrefix: this.prefixValue, phoneNumber: this.value });
  }
}
