import {CaseHistoryCommEntry} from '../../../../../../shared/models/caseHistoryEntry';
import {Component, Inject, Input, OnInit} from '@angular/core';
import {CaseLockHelperService} from '@secca/core/services/case-lock-helper.service';
import {PermissionService} from '@secca/core/services/permission.service';
import {ChannelType} from '@secca/shared/enums/channelType';
import {Case} from '@secca/shared/models/case';
import {DialogViewerServiceInterface} from '@secca/core/services/dialog-viewer-service-interface';
import {DIALOG_VIEWER_TOKEN} from '@secca/core/services/token';
import {CaseHistoryDataFieldChangeDto} from '@secca/shared/models/CaseHistoryDataFieldChangeDto';
import {CaseStateService} from '@secca/core/state-services/case-state.service';
import {CaseDialogViewerService} from '@secca/case/case-dialog-viewer.service';
import {CaseHistoryType} from "@secca/shared/enums/case-history-type";
import {CaseStakeholder} from "@secca/shared/models/caseStakeholder";
import {StakeholderService} from "@secca/core/services/stakeholder.service";
import { Channel } from '@secca/shared/models/channel';

@Component({
  selector: 'app-case-summary-external',
  templateUrl: './case-summary-external.component.html',
  styleUrls: ['./case-summary-external.component.scss'],
})
export class CaseSummaryExternalComponent implements OnInit {
  @Input() case: Case;
  @Input() entry: CaseHistoryCommEntry;
  @Input() channelTypes: Channel[];

  @Input()
  set stakeholders(stakeholders: CaseStakeholder[]) {
    if (stakeholders) {
      this._stakeholders = stakeholders;
      this.tryToMatchProvider();
    }
  }

  @Input() showActionButton: boolean;

  showEntryMenu: boolean;
  webChannel: string;
  hasTaskId: boolean;
  deleted: boolean;
  _stakeholders: CaseStakeholder[];
  stakeholder: CaseStakeholder;
  stakeholderName: string;

  constructor(
    public caseLockHelperService: CaseLockHelperService,
    public permissionService: PermissionService,
    public caseStateService: CaseStateService,
    private dialogViewerService: CaseDialogViewerService,
    private stakeholderService: StakeholderService,
  ) {
  }

  ngOnInit() {
    this.hasTaskId = !!this.entry?.dataFieldChanges?.find(field => field.field === 'TaskId')?.newValue;
    this.deleted = !!this.entry?.deletedBy;
  }

  viewMenuClick() {
    this.dialogViewerService.openCaseSummaryExternalDialog(this.entry, this.case.channelType, this.getChannelTypeName(this.case.channelType), this.case.caseNumber);
  }

  get ChannelType() {
    return ChannelType;
  }

  tryToMatchProvider() {
    const providerId = this.getProviderId(this.entry?.dataFieldChanges);
    if (this._stakeholders != null && this.entry != null) {
      this.stakeholder = this._stakeholders.filter(a => a.company && providerId).find(a => a.company.providerId === providerId)
      this.stakeholderName = this.stakeholder?.company.name
    }
  }

  getProviderId(dataFieldChanges: CaseHistoryDataFieldChangeDto[]): String {
    return dataFieldChanges?.find(field => field.field === 'ProviderId')?.newValue;
  }

  getChannelTypeName(channelType: string) {
    return this.channelTypes?.find(c => c.channelTypeEnumValue === channelType)?.channelTypeName;
  }

  isCaseUpdatedWithWeb(dataFieldChanges: CaseHistoryDataFieldChangeDto[]): boolean {
    this.webChannel = dataFieldChanges?.find(field => field.field === 'Channel')?.newValue;
    return !!this.webChannel;
  }
  
  get CaseHistoryType() {
    return CaseHistoryType
  }

  get stakeholderIcon() {
    if (this.stakeholder) {
      return this.stakeholderService.getStakeholderIconFullPath(this.stakeholder.stakeholderType);
    }
  }
}
