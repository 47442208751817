import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DictionaryService} from '@secca/core/services/dictionary.service';
import {TranslateService} from '@ngx-translate/core';
import {CaseTypesService} from '@secca/core/services/case-type.service';
import {DropdownDictionary} from '@secca/shared/models/dropdownDictionary';
import { MasterListService } from '@secca/core/services/masterlist-service';
import * as _ from 'lodash';

@Component({
  selector: 'app-channel-type-modal',
  templateUrl: './channel-type-modal.component.html',
  styleUrls: ['./channel-type-modal.component.scss']
})
export class ChannelTypeModalComponent implements OnInit {

  @ViewChild('content') private template: TemplateRef<any>;

  @Input()
  caseId: string;

  @Input()
  channelType: string;
    
  @Output()
  channelTypeChanged: EventEmitter<string> = new EventEmitter<string>();
  
  selectableChannelTypes: DropdownDictionary[];
  selectedChannelType: string;

  ngOnInit() {

  }

  constructor(
    private caseTypesService: CaseTypesService,
    private modalService: NgbModal,
    private dictionaryService: DictionaryService,
    private translate: TranslateService,
    private masterListService: MasterListService
  ) {}

  save(){
    this.caseTypesService.saveChannelType(this.caseId, this.selectedChannelType).subscribe(result => {
      this.notify();
      this.close();
    });

  }

  open() {
    this.selectedChannelType = this.channelType;
    this.dictionaryService.getActiveChannelTypes().subscribe(
      result => {
        this.selectableChannelTypes = result;
      }  
    );
    this.modalService.open(this.template, { size: 'lg' as any, centered: true, windowClass: 'channel-type-popup'  });
  }

  notify() {
    this.channelTypeChanged.next(this.selectedChannelType);
  }

  close() {
    this.modalService.dismissAll();
  }

  disableSave() {
    return this.channelType === this.selectedChannelType;
  }
}
