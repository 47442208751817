import { SubComponentModel } from '@secca/case/components/case-plans/case-plans/add-service-order/models/interfaces';
import * as moment from 'moment';
import {
  DetailsCommonListTypeEnum, DetailsOxygenSuppliedTypeEnum,
  DetailsTransportTypeEnum,
  DetailsWheelchairTypeEnum
} from '@secca/case/components/case-plans/case-plans/add-service-order/models/enums';

export class SubRepatriationDetails implements SubComponentModel {
  id: number;
  repatriationPossibleBy: moment.Moment;
  repatriationForm: string;
  coTransportAcceptable: DetailsTransportTypeEnum;
  wheelchair: DetailsWheelchairTypeEnum;
  groundAmbulanceToAmbulanceFlight: DetailsCommonListTypeEnum;
  medicalCareInTransit: DetailsCommonListTypeEnum;
  groundAmbulanceFromAmbulanceFlight: DetailsCommonListTypeEnum;
  admissionUponArrival: DetailsCommonListTypeEnum;
  oxygenInFlight: DetailsCommonListTypeEnum;
  oxygenOnGround: DetailsCommonListTypeEnum;
  oxygenSuppliedBySosRequested: DetailsOxygenSuppliedTypeEnum;
  oxygenSuppliedBySosBooked: DetailsOxygenSuppliedTypeEnum;
  sosOxygenOnEscortOutboundFlights: DetailsCommonListTypeEnum;
  sosOxygenOnEscortReturnFlights: DetailsCommonListTypeEnum;
  escort1: DetailsCommonListTypeEnum;
  escort2: DetailsCommonListTypeEnum;
  equipment: DetailsCommonListTypeEnum;
  medication: DetailsCommonListTypeEnum;

  public constructor(init?: Partial<SubRepatriationDetails>) {
    Object.assign(this, init);
  }

  isValid(): boolean {
    return true;
  }

}
