import { Component, OnInit, ViewChild, TemplateRef, Output, EventEmitter } from '@angular/core';
import { DropdownDictionary } from './../../../../../shared/models/dropdownDictionary';
import { DictionaryService } from 'src/app/core/services/dictionary.service';
import {CaseDocumentTagEnum} from '@secca/shared/models/enums';

@Component({
  selector: 'app-case-document-filter',
  templateUrl: './case-document-filter.component.html',
  styleUrls: ['./case-document-filter.component.scss'],
})
export class CaseDocumentFilterComponent implements OnInit {
  @Output() searchFilterTextChangeds = new EventEmitter<string>();
  @Output() filterByTags = new EventEmitter<string[]>();
  @Output() sortedBy = new EventEmitter<string>();

  @ViewChild('content')
  private template: TemplateRef<any>;

  tags: DropdownDictionary[];
  numberOfSelectedTags: number;

  constructor(private dictionaryService: DictionaryService) {}

  ngOnInit() {
    this.initTags();
  }

  private initTags() {
    this.tags = Object.keys(CaseDocumentTagEnum).map(item => new DropdownDictionary(item, CaseDocumentTagEnum[item]));
    this.tags.push(new DropdownDictionary('ALL', 'All tags'));
  }

  selectedItemIdChange(selectedItemId: string[]) {
    this.numberOfSelectedTags = selectedItemId.length;
    this.filterByTags.emit(selectedItemId);
  }

  changeSearchFilterText(value: string) {
    this.searchFilterTextChangeds.emit(value);
  }
}
