import { TaskFilterRequest } from './task-filter-request.model';
import * as moment from 'moment';

export class SupplierInvoiceTaskFilterRequest extends TaskFilterRequest {
  dateRange: moment.Moment[];
  invoiceGroups: string[];
  serviceTypes: string[];
  invoiceStatuses: string[];
  invoiceNumber: string;
  creditorId: string;
  caseNumber: string;

  public constructor(init?: Partial<SupplierInvoiceTaskFilterRequest>) {
    super();
    Object.assign(this, init);
  }
}
