<div class="card">
  <div class="sub-component-placeholder">
    <div class="sub-component-container col-12">
      <div class="sub-title">{{ 'referral-to-customer-advice-given-title' | translate }}</div>
      <app-text-box
        [name]="'referral-to-customer-given-label' | translate"
        [maxTextLength]="1000"
        [(value)]="referalToCustomer.adviceGiven"
        [required]="true"
      >
      </app-text-box>
    </div>
  </div>
  <div class="sub-component-placeholder">
    <div class="col-5">
      <app-input-date-time
        [name]="'referral-to-customer-start-date' | translate"
        (dateChange)="startDateChange($event)"
        [withImage]="true"
        [date]="referalToCustomer.startDate"
        [timeZone]="'secca'"
      >
      </app-input-date-time>
    </div>
  </div>
</div>
