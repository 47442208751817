import { HandlingAreaStatusEnum, PatientType, RepatriationFormEnum, SortOrder, TransportAssistanceType } from '@secca/shared/models/enums';
import * as moment from 'moment';
import { CustomerProfileIdsForSearch } from '../../board-task/models/task-filter-request.model';

export class CaseTransportRequest {
  fromDate: moment.Moment;
  toDate: moment.Moment;
  incidentFromDate: moment.Moment;
  incidentToDate: moment.Moment;
  repatriationForm: RepatriationFormEnum[];
  customerProfileId: number;
  customerProfileIds: CustomerProfileIdsForSearch[];
  currentCountry: String[];
  userIds: number[];
  adtCodes: string[];
  adtCodesForCount: string[];
  includeOtherTeams: boolean;
  sortBy: string;
  pageIndex: number;
  pageSize: number;
  sortOrder: SortOrder;
  selectedTeamNames: string[];
  caseTypeCodes: string[];
  caseDetails: string[];
  public constructor(init?: Partial<CaseTransportRequest>) {
    Object.assign(this, init);
  }
}
