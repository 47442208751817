import { AutoUnsubscribe } from 'src/app/shared/decorators/auto-unsubscribe';
import { CaseSelectedMenuEnum } from '@secca/shared/enums/case-selected-menu-enum.component';
import { Case } from 'src/app/shared/models/case';
import { MenuService } from '@secca/core/services/menu.service';
import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { TaskStateService } from '@secca/board/components/board-task/services/task-state.service';
import { PendingTaskDtoRequest } from '@secca/board/components/board-task/models/pending-task-dto-request';
import { PendingTasksListUpdateService } from '../case-task/services/pending-tasks-list-update.service';
import { HelperService } from '@secca/core/services/helper.service';
import { interval, Subscription } from 'rxjs';
import { PermissionEnum, PermissionHideTypeEnum, ShortcutEnum } from '@secca/shared/models/enums';
import { ShortcutService } from '@secca/core/services/shortcut.service';

@AutoUnsubscribe
@Component({
  selector: 'app-case-cases',
  templateUrl: './case-cases.component.html',
  styleUrls: ['./case-cases.component.scss']
})
export class CaseCasesComponent implements OnInit, OnDestroy {
  @Output() menuClick: EventEmitter<number> = new EventEmitter();

  @Input() set case(_case: Case) {
    if (_case != null) {
      this._case = _case;
      this.updateNumberOfPendingTasks();
      this.updateNumberOfPendingMessageTasks();
    }
  }

  source = interval(HelperService.ONE_MINUTE_IN_MILLIS);

  get case(): Case {
    return this._case;
  }

  numberOfPendingTasks: number;
  numberOfPendingMessageTasks: number;
  $subscr1;
  $subscr2;
  selectedMenuNumber: number;
  menu: Map<string, number>;

  get CaseSelectedMenuEnum() {
    return CaseSelectedMenuEnum;
  }

  private _case: Case;

  private shortcutSubscriptions: Subscription[] = [];

  constructor(
    private menuService: MenuService,
    private shortcutService: ShortcutService,
    private taskStateService: TaskStateService,
    private pendingTasksListUpdateService: PendingTasksListUpdateService
  ) {
    this.$subscr1 = this.pendingTasksListUpdateService.getPendingTasksHasChange().subscribe(result => {
      if (result != null) {
        this.updateNumberOfPendingTasks();
        this.updateNumberOfPendingMessageTasks();
      }
    });
    this.shortcutSubscriptions.push(
      this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateToCaseTask }).subscribe(a => {
        this.caseTaskClick();
      }),
      this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateToCaseBasics }).subscribe(a => {
        this.caseBasicClick();
      }),
      this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateToCaseSummary }).subscribe(a => {
        this.caseActivityLogClick();
      }),
      this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateToCasePlan }).subscribe(a => {
        this.casePlanClick();
      }),
      this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateToCaseMedical }).subscribe(a => {
        this.caseMedicalClick();
      }),
      this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateToCaseEconomy }).subscribe(a => {
        this.caseEconomyClick();
      }),
      this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateToCaseStakeholders }).subscribe(a => {
        this.caseStakeholdersClick();
      }),
      this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateToCaseDocuments }).subscribe(a => {
        this.caseDocumentsClick();
      }),
    );
  }

  ngOnInit() {
    this.$subscr2 = this.source.subscribe(val => {
      this.updateNumberOfPendingTasks();
      this.updateNumberOfPendingMessageTasks();
    });
  }

  ngOnDestroy(): void {
    this.shortcutSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private updateNumberOfPendingMessageTasks() {
    if (this.case != null) {
      this.taskStateService.getPendingMessageTasks(this.createPendingTasksRequest())
        .subscribe(count => {
          this.numberOfPendingMessageTasks = count;
        });
    }
  }

  private updateNumberOfPendingTasks() {
    if (this.case != null) {
      this.taskStateService.getToDoTaskViewModelForCase(this.case.id)
        .subscribe(tasks => {
          this.numberOfPendingTasks = tasks.length;
        });
    }
  }


  private createPendingTasksRequest(): PendingTaskDtoRequest {
    const pendingTaskDtoRequest = new PendingTaskDtoRequest();
    pendingTaskDtoRequest.caseId = +this._case.id;
    const dueDate = new Date();
    dueDate.setHours(dueDate.getHours() + HelperService.NUMBER_OF_HOURS_FOR_PENDING_TASKS);
    pendingTaskDtoRequest.dueDate = dueDate;
    return pendingTaskDtoRequest;
  }

  isMenuSelected(menuNuber: number): boolean {
    // Either initialize case-cases as 1 or get selected menu id from case
    if (this.menuService.getSelectedMenuId(+this.case.id) === undefined) {
      this.selectedMenuNumber = CaseSelectedMenuEnum.CaseBasics;
    } else {
      this.selectedMenuNumber = this.menuService.getSelectedMenuId(+this.case.id);
    }
    return menuNuber === this.selectedMenuNumber;
  }

  caseTaskClick() {
    this.selectedMenuNumber = CaseSelectedMenuEnum.CaseTask;
    this.pendingTasksListUpdateService.setPendingTasksHasChange();
    this.menuClick.emit(this.selectedMenuNumber);
  }

  casePlanClick() {
    this.selectedMenuNumber = CaseSelectedMenuEnum.CasePlan;
    this.menuClick.emit(this.selectedMenuNumber);
  }

  caseMedicalClick() {
    this.selectedMenuNumber = CaseSelectedMenuEnum.CaseMedical;
    this.menuClick.emit(this.selectedMenuNumber);
  }

  caseEconomyClick() {
    this.selectedMenuNumber = CaseSelectedMenuEnum.CaseEconomy;
    this.menuClick.emit(this.selectedMenuNumber);
  }

  caseBasicClick() {
    this.selectedMenuNumber = CaseSelectedMenuEnum.CaseBasics;
    this.menuClick.emit(this.selectedMenuNumber);
  }

  caseActivityLogClick() {
    this.selectedMenuNumber = CaseSelectedMenuEnum.CaseSummary;
    this.menuClick.emit(this.selectedMenuNumber);
  }

  caseStakeholdersClick() {
    this.selectedMenuNumber = CaseSelectedMenuEnum.Stakeholders;
    this.menuClick.emit(this.selectedMenuNumber);
  }

  caseDocumentsClick() {
    this.selectedMenuNumber = CaseSelectedMenuEnum.Documents;
    this.menuClick.emit(this.selectedMenuNumber);
  }

  caseAboutClick() {
    this.selectedMenuNumber = CaseSelectedMenuEnum.About;
    this.menuClick.emit(this.selectedMenuNumber);
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  get PermissionHideTypeEnum() {
    return PermissionHideTypeEnum;
  }
}
