import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SensitiveType } from '@secca/shared/models/enums';
import {ModalDialogComponent} from '@secca/shared/components/modal-dialog/modal-dialog.component';
import {ModalDialogConfiguration} from '@secca/shared/models/modal/modal-dialog-configuration';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-case-sensitive',
  templateUrl: './case-sensitive.component.html',
  styleUrls: ['./case-sensitive.component.scss']
})
export class CaseSensitiveComponent {

  @Input() sensitiveInformation: SensitiveType;
  @Input() caseId: number;
  @Output() sensitiveTypeChanged = new EventEmitter<{ sensitiveType: SensitiveType, cause: any }>();

  constructor(private modalService: NgbModal,
              private translateService: TranslateService) { }

  get SensitiveTypeEnum() {
    return SensitiveType;
  }

  clickSensitive() {
    this.openModal();
  }


  getSentiviveTextForModal() {
    return this.sensitiveInformation === SensitiveType.SENSITIVE ? this.translateService.instant('sensitive-modal-label-text-not-sensitive') : this.translateService.instant('sensitive-modal-label-text-sensitive');

  }

  openModal() {
    const modalRef = this.modalService.open(ModalDialogComponent);
    modalRef.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'sensitive-header-text-consent',
      title: '',
      text: '',
      footer: 'sensitive-modal-label-text-not-available-to-the-customer',
      yes: '',
      no: '',
      isBody: false,
      isFooter: true,
      showTextArea: true,
      showYesNoButton: false,
      redFooterText: true,
      saveandclose: 'save-and-close-oral-consent',
      placeholderText: 'text-inside-no-sensitive-data-text',
      labelTextAboveTextArea: this.getSentiviveTextForModal()
    });

    modalRef.componentInstance.saveandcloseEvent.subscribe(
      emittedEvent => {
        this.closeModal();
          if(!this.sensitiveInformation || this.sensitiveInformation === SensitiveType.NOT_SENSITIVE) {
           this.sensitiveInformation = SensitiveType.SENSITIVE;
          } else {
           this.sensitiveInformation = SensitiveType.NOT_SENSITIVE;
          }
          this.sensitiveTypeChanged.emit({ sensitiveType: this.sensitiveInformation, cause: emittedEvent });
      },
      error => { }
    );

    modalRef.componentInstance.closeModalEvent.subscribe(
      emittedEvent => {
        modalRef.close();
      },
      err => console.log(err)
    );
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
