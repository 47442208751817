<div class="board-content">
  <ul ngbNav #nav="ngbNav" class="nav-tabs">
     <li ngbNavItem>
      <a ngbNavLink>{{ 'board-sales-order-tab-title' | translate }} ({{ filterResponse?.totalNumber }})</a>
      <ng-template ngbNavContent>
        <div class="top-panel">
          <div class="row align-items-end filter-row">
            <div class="col filter-col tab3-calendar">
              <app-input-calender
                [name]="'board-sales-order-creation-date' | translate"
                [withRange]="true"
                [withImage]="true"
                [placeholder]="datePlaceholder"
                [(dateTimeRange)]="filterRequest.salesOrderCreationRange"
                (dateTimeRangeChange)="loadSalesOrderInvoiceJobs()"
              >
              </app-input-calender>
            </div>
            <div class="col filter-col tab3-calendar">
              <app-input-calender
                [name]="'board-sales-order-invoice-date' | translate"
                [withRange]="true"
                [withImage]="true"
                [placeholder]="datePlaceholder"
                [(dateTimeRange)]="filterRequest.invoiceDateRange"
                (dateTimeRangeChange)="loadSalesOrderInvoiceJobs()"
              >
              </app-input-calender>
            </div>
            <div class="col filter-col tab3-calendar">
              <app-input-calender
                [name]="'board-sales-order-invoice-job-date' | translate"
                [withRange]="true"
                [withImage]="true"
                [placeholder]="datePlaceholder"
                [(dateTimeRange)]="filterRequest.invoiceJobDateRange"
                (dateTimeRangeChange)="loadSalesOrderInvoiceJobs()"
              >
              </app-input-calender>
            </div>
            <div class="col filter-col tab3-calendar">
              <app-drop-down
                [name]="'board-sales-order-retry-count' | translate"
                [placeholder]="'board-sales-order-placeholder-all' | translate"
                [items]="allRetryCount"
                [(selectedItemId)]="filterRequest.retryCount"
                [multiple]="true"
                [multiSelectedLabel]="'board-sales-order-filter-some-selected' | translate: { number: filterRequest.retryCount.length }"
                (selectedItemIdChange)="loadSalesOrderInvoiceJobs()"
              ></app-drop-down>
            </div>
          </div>
          <div class="row align-items-end filter-row">
            <div class="col filter-col">
              <app-input
                [withImage]="true"
                [name]="'board-sales-order-sales-order' | translate"
                [placeholder]="'board-sales-order-placeholder-all' | translate"
                (saveModel)="loadSalesOrderWithSalesOrderId()"
                [(value)]="filterRequest.salesOrderId"
                [inputType]="InputType.number"
                [inputTypePattern]="InputTypePattern.digitsOnly"
              ></app-input>
            </div>
            <div class="col filter-col">
              <app-drop-down
                [name]="'board-sales-order-profile' | translate"
                [placeholder]="'board-sales-order-placeholder-all' | translate"
                [items]="allCustomerProfiles"
                [(selectedItemId)]="filterRequest.customerProfile"
                [multiple]="true"
                [multiSelectedLabel]="
                  'board-sales-order-filter-some-selected' | translate: { number: filterRequest.customerProfile.length }
                "
                (selectedItemIdChange)="loadSalesOrderInvoiceJobs()"
              ></app-drop-down>
            </div>
            <div class="col filter-col">
              <app-input
                [withImage]="true"
                [name]="'board-task-invoice-case-number' | translate"
                [placeholder]="'board-sales-order-placeholder-all' | translate"
                (saveModel)="loadSalesOrderInvoiceJobs()"
                [(value)]="filterRequest.caseNumber"
              ></app-input>
            </div>
            <div class="col filter-col">
              <app-drop-down
                [name]="'board-sales-order-debtor' | translate"
                [placeholder]="'board-sales-order-placeholder-all' | translate"
                [items]="allDebtors"
                [(selectedItemId)]="filterRequest.debtor"
                [multiple]="true"
                [multiSelectedLabel]="'board-sales-order-filter-some-selected' | translate: { number: filterRequest.debtor.length }"
                (selectedItemIdChange)="loadSalesOrderInvoiceJobs()"
              ></app-drop-down>
            </div>
            <div class="col filter-col">
              <app-drop-down
                [name]="'board-sales-order-erp-client' | translate"
                [placeholder]="'board-sales-order-placeholder-all' | translate"
                [items]="allErpClients"
                [(selectedItemId)]="filterRequest.erpClient"
                [multiple]="true"
                [multiSelectedLabel]="'board-sales-order-filter-some-selected' | translate: { number: filterRequest.erpClient.length }"
                (selectedItemIdChange)="loadSalesOrderInvoiceJobs()"
              ></app-drop-down>
            </div>
            <div class="col filter-col">
              <app-input
                [withImage]="true"
                [inputType]=InputType.number
                [name]="'board-sales-order-invoice-number' | translate"
                [placeholder]="'board-sales-order-placeholder-all' | translate"
                (saveModel)="loadSalesOrderInvoiceJobs()"
                [(value)]="filterRequest.invoiceNo"
              ></app-input>
            </div>
            <div class="col filter-col">
              <app-drop-down
                [name]="'board-sales-order-status' | translate"
                [placeholder]="'board-sales-order-placeholder-all' | translate"
                [items]="allSalesOrderStatus"
                [(selectedItemId)]="filterRequest.salesOrderStatus"
                [multiple]="true"
                [multiSelectedLabel]="
                  'board-sales-order-filter-some-selected' | translate: { number: filterRequest.salesOrderStatus.length }
                "
                (selectedItemIdChange)="loadSalesOrderInvoiceJobs()"
              ></app-drop-down>
            </div>
            <div class="col filter-col">
              <app-drop-down
                [name]="'board-sales-order-case-financial-status' | translate"
                [placeholder]="'board-sales-order-placeholder-all' | translate"
                [items]="allCaseFinancialStatus"
                [(selectedItemId)]="filterRequest.caseFinancialStatus"
                [multiple]="true"
                [multiSelectedLabel]="
                  'board-sales-order-filter-some-selected' | translate: { number: filterRequest.caseFinancialStatus.length }
                "
                (selectedItemIdChange)="loadSalesOrderInvoiceJobs()"
              ></app-drop-down>
            </div>
            <div class="col filter-col">
              <app-drop-down
                [name]="'board-sales-order-invoice-job-status' | translate"
                [placeholder]="'board-sales-order-placeholder-all' | translate"
                [items]="allInvoiceJobStatus"
                [(selectedItemId)]="filterRequest.invoiceJobStatus"
                [multiple]="true"
                [multiSelectedLabel]="
                  'board-sales-order-filter-some-selected' | translate: { number: filterRequest.invoiceJobStatus.length }
                "
                (selectedItemIdChange)="loadSalesOrderInvoiceJobs()"
              ></app-drop-down>
            </div>
            <div class="col filter-btn-col">
              <div class="d-flex">
                <button class="icon-button icon-button-last">
                  <img src="../../../../assets/icons/clear-filter.svg" (click)="clearFilter()" />
                </button>
                <button class="icon-button" (click)="refresh()">
                  <img src="../../../../assets/icons/refresh.svg" />
                </button>

                  <div class="icon-button icon-button-last" >
                    <div class="context-menu position-relative" [align]="'middle'">
                      <div class="context-menu-dot"></div>
                      <div class="context-menu-dot"></div>
                      <div class="context-menu-dot"></div>


                      <div class="context-menu-content" [align]="'left'">
                        <p (click)="resetRetryCount()">{{ 'case-economy-customer-invoices-reset-retry-count' | translate }}</p>
                        <p (click)="resetAckMessage()">{{ 'case-economy-customer-invoices-reset-ack-msg' | translate }}</p>
                        <p (click)="resetInvoiceDocument()"
                           [ngClass]="{ 'context-menu-content-disabled': !hasOnlyOneSalesOrderSelected() }">{{ 'case-economy-customer-invoices-reset-invoice-document' | translate }}</p>
                        <p (click)="resetPaymentStatus()"
                           [ngClass]="{ 'context-menu-content-disabled': !hasOnlyOneSalesOrderSelected() }">{{ 'case-economy-customer-invoices-reset-payment-status' | translate }}</p>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <app-board-sales-order-table
          [customerInvoiceJobs]="filterResponse?.jobSalesOrders"
          (sortingAction)="sortingAction($event)"
          (navigateToCaseEvent)="navigateToCase($event)"
        ></app-board-sales-order-table>
        <app-pagination
          [currentPageIndex]="filterRequest?.pageIndex"
          [totalNumber]="filterResponse?.totalNumber"
          [pageSize]="filterRequest?.pageSize"
          (pagingChangedEvent)="paginationChanged($event)"
        ></app-pagination>
      </ng-template>
     </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</div>
