<div>
  <editor
    apiKey=""
    [disabled]="disabled"
    [init]="editorConfig"
    [(ngModel)]="text"
    (onKeyUp)="onKeyUp($event)"
    (onFocusOut)="onBlur($event)"
    (onFocusIn)="onFocus($event)"
  >
  </editor>
</div>
