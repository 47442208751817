import { CallType, StakeholderTypeEnum } from './enums';
import { Injectable } from '@angular/core';
import { Adapter } from './../interfaces/adapter';
import { HelperService } from '../../core/services/helper.service';
import { StakeholderType } from '../../modules/case/components/case-stakeholders/stakeholderType';
import * as moment from 'moment';
import { CaseStakeholder, CaseStakeholderAdapter } from './caseStakeholder';

export class Note {
  id: string;
  originalId: number;
  caseId?: number;
  date?: Date;
  time?: string;
  caseHandler?: string;
  description?: string;
  stakeholder?: CaseStakeholder;
  type?: string;
  callFrom?: string;
  callTo?: string;
  callLength?: string;
  phoneStartTime?: moment.Moment;
  caseHandlerPhoto: string;
  callType?: CallType;
  deletedBy: string;
  deletedOn: Date;
  connectionCallId: string;
  copiedFromCaseId: number;

  public constructor(init?: Partial<Note>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
export class NoteAdapter implements Adapter<Note> {
  constructor(private stakeholderadapter: CaseStakeholderAdapter) {}

  adapt(item: any): Note {
    return new Note({
      id: item.id,
      originalId: item.originalId,
      caseId: item.caseId,
      date: item.date === '' ? null : new Date(item.date),
      time: item.time,
      stakeholder: item.stakeholder ? this.stakeholderadapter.adapt(item.stakeholder) : null,
      caseHandler: item.caseHandler,
      description: item.description,
      type: item.type,
      callFrom: item.callFrom,
      callTo: item.callTo,
      callLength: item.callLength,
      caseHandlerPhoto: item.caseHandlerPhoto,
      callType: item.callType,
      deletedBy: item.deletedBy,
      deletedOn: item.deletedOn,
      connectionCallId: item.connectionCallId,
      copiedFromCaseId: item.copiedFromCaseId
    });
  }
}

export class NoteDto {
  id: number;
  entityId: number;
  originalId: number;
  noteType: string;
  connectionCallId: string;
  phoneNumber: string;
  phoneCallDuration: any;
  phoneStartTime: any;
  description: string;
  stakeholderId: string;
  stakeholderType: string;
  stakeholderFirstName: string;
  stakeholderSurname: string;
  stakeholderCompanyName: string;
  callType?: CallType;
  deletedBy: string;
  deletedOn: Date;
  copiedFromCaseId: number;

  public constructor(init?: Partial<NoteDto>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
export class NoteDtoWrapper {
  constructor(private helperService: HelperService) {}

  wrap(item: Note, stakeholderTypes: StakeholderType[]): NoteDto {
    let stakeholderType = item.stakeholder.stakeholderType;

    if (stakeholderType) {
      let type = stakeholderTypes.find(a => a.stakeholderTypeEnum === item.stakeholder.stakeholderType);
      if (type) {
        stakeholderType = type.stakeholderTypeEnum;
      }
    }
    if (item.stakeholder.properties != null) {
      if (item.stakeholder.hasRole(StakeholderTypeEnum.endUser)) {
        stakeholderType = stakeholderTypes.find(a => a.stakeholderTypeEnum === 'END_USER').stakeholderTypeEnum;
      } else if (item.stakeholder.hasRole(StakeholderTypeEnum.policyHolder)) {
        stakeholderType = stakeholderTypes.find(a => a.stakeholderTypeEnum === 'POLICYHOLDER').stakeholderTypeEnum;
      }
    }

    return new NoteDto({
      id: +item.id,
      entityId: item.caseId,
      noteType: item.type,
      originalId: item.originalId,
      description: item.description,
      stakeholderId: item.stakeholder.id,
      stakeholderType,
      stakeholderFirstName: item.stakeholder.person && item.stakeholder.person.firstName ? item.stakeholder.person.firstName : null,
      stakeholderSurname: item.stakeholder.person && item.stakeholder.person.surname ? item.stakeholder.person.surname : null,
      stakeholderCompanyName: item.stakeholder.company && item.stakeholder.company.name ? item.stakeholder.company.name : null,
      callType: item.callType,
      phoneCallDuration: item.callLength,
      phoneStartTime: item.phoneStartTime,
      connectionCallId: item.connectionCallId,
      phoneNumber: item.callType === CallType.ingoing ? item.callFrom : item.callTo,
      copiedFromCaseId: item.copiedFromCaseId
    });
  }
}
