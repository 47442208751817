import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {SettingsService} from './settings.service';
import {DigitalCaseflowLog, DigitalCaseflowLogAdapter} from "@secca/shared/models/digital-caseflow-log";

@Injectable({
  providedIn: 'root',
})
export class DigitalCaseflowService extends BaseService {
  constructor(
    private http: HttpClient,
    private digitalCaseflowFlowLogAdapter: DigitalCaseflowLogAdapter,
    private settingsService: SettingsService
  ) {
    super(settingsService);
  }

  getDigitalCaseflowLog(digitalCaseflowLogId: number): Observable<DigitalCaseflowLog> {
    return this.http
      .get(this.baseURL + 'dcf/log/' + digitalCaseflowLogId)
      .pipe(map(item => this.digitalCaseflowFlowLogAdapter.adapt(item)));
  }

}
