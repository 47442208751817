<div class="common-drop-down-arrow drop-down">
  <span class="name">{{name}}</span>
  <ng-select (blur)="onSaveModelDropDown()" (change)="onSelectedItemIdChange()" [clearable]="false"
             [items]="items" bindValue="id" bindLabel="name" [(ngModel)]="selectedItemId" [disabled]="!!disabled"
             class="custom" [class.required-red-background]="(required && !selectedItemId) || selectedItemId === defaultCoveragesAlwaysPresentEnum().UNKNOWN">
    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
      <div *ngIf="item.id === defaultCoveragesAlwaysPresentEnum().EXTRAORDINARY_COVERAGES" class="dropdown-item-name-red"> {{ item.name | translate }}</div>
      <div *ngIf="item.id !== defaultCoveragesAlwaysPresentEnum().EXTRAORDINARY_COVERAGES"> {{ item.name | translate }} </div>
    </ng-template>
  </ng-select>
</div>
