import { Injectable } from '@angular/core';
import { BaseService } from '@secca/core/services/base.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SettingsService } from '@secca/core/services/settings.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FinancialStatusEnum } from '@secca/shared/models/enums';
import { CaseLockHttpService } from './case-lock-http.service';
import { FinancialStatus, FinancialStatusAdapter } from '@secca/shared/models/financial-status';

@Injectable({
  providedIn: 'root',
})
export class FinancialStatusService extends BaseService {
  constructor(
    private settingsService: SettingsService,
    private http: HttpClient,
    private financialStatusAdapter: FinancialStatusAdapter,
    private caseLockHttpService: CaseLockHttpService
  ) {
    super(settingsService);
  }

  private caseFinancialStatus: BehaviorSubject<FinancialStatus> = new BehaviorSubject(undefined);
  sendCaseFinancialStatus(status: FinancialStatus) {
    this.caseFinancialStatus.next(status);
  }
  getCaseFinancialStatus(): Observable<FinancialStatus> {
    return this.caseFinancialStatus.asObservable();
  }

  getStatus(caseId: string): Observable<FinancialStatus> {
    return this.http
      .get<FinancialStatus>(this.baseURL + 'financial-status/case/' + caseId, { headers: this.jsonHeaders })
      .pipe(
        map(item => this.financialStatusAdapter.adapt(item)),
        catchError(err => this.handleError(err, caseId))
      );
  }

  addOrUpdate(caseId: string, status: FinancialStatus): Observable<FinancialStatus> {
    return this.caseLockHttpService
      .put<FinancialStatus>(this.baseURL + 'financial-status/case/' + caseId, status, { headers: this.jsonHeaders })
      .pipe(map(item => this.financialStatusAdapter.adapt(item)));
  }

  handleError(error: HttpErrorResponse, caseId) {
    if (error.status === 404) {
      console.log('No previous case financial status found for case id ' + caseId + ' Using default status: OPEN');
      return of(new FinancialStatus({ status: FinancialStatusEnum.OPEN }));
    } else {
      throw error;
    }
  }
}
