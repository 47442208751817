import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@secca/shared/shared.module';
import { UtilsModule } from 'src/app/utils/utils.module';
import {
  CaseSummaryViewComponent
} from '../case-output-management/components/message-dialog/case-summary-view/case-summary-view.component';
import { CaseEconomyHistoryListComponent } from './case-economy-history/case-economy-history-list/case-economy-history-list.component';
import { NewValueComponent } from './case-economy-history/new-value/new-value.component';
import { PreviousValueComponent } from './case-economy-history/previous-value/previous-value.component';
import { RefundItemRowComponent } from './case-economy-history/refund-item-row/refund-item-row.component';
import { RefundRowComponent } from './case-economy-history/refund-row/refund-row.component';
import { CustomerInvoiceItemRowComponent } from './case-economy-history/customer-invoice-item-row/customer-invoice-item-row.component';
import { CustomerInvoiceRowComponent } from './case-economy-history/customer-invoice-row/customer-invoice-row.component';
import { SosServiceRowComponent } from './case-economy-history/sos-service-row/sos-service-row.component';
import { CaseHistoryFilterComponent } from './case-history-filter/case-history-filter.component';
import { CaseHistoryListComponent } from './case-history-list/case-history-list.component';
import { CaseSummaryFilterComponent } from './case-summary-filter/case-summary-filter.component';
import {
  CaseSummaryExternalDialogComponent
} from './case-summary-list/case-summary-claims/case-summary-external-dialog/case-summary-external-dialog.component';
import {
  CaseSummaryExternalModalComponent
} from './case-summary-list/case-summary-claims/case-summary-external-modal/case-summary-external-modal.component';
import { CaseSummaryExternalComponent } from './case-summary-list/case-summary-claims/case-summary-external.component';
import { CaseSummaryConsentComponent } from './case-summary-list/case-summary-consent/case-summary-consent.component';
import {
  CaseSummaryEmailFaxDialogComponent
} from './case-summary-list/case-summary-email-fax/case-summary-email-fax-dialog/case-summary-email-fax-dialog.component';
import {
  CaseSummaryEmailFaxModalComponent
} from './case-summary-list/case-summary-email-fax/case-summary-email-fax-modal/case-summary-email-fax-modal.component';
import { CaseSummaryEmailFaxComponent } from './case-summary-list/case-summary-email-fax/case-summary-email-fax.component';
import { CaseSummaryListComponent } from './case-summary-list/case-summary-list.component';
import {
  CaseSummaryNoteDialogComponent
} from './case-summary-list/case-summary-note/case-summary-note-dialog/case-summary-note-dialog.component';
import {
  CaseSummaryNoteModalComponent
} from './case-summary-list/case-summary-note/case-summary-note-modal/case-summary-note-modal.component';
import { CaseSummaryNoteComponent } from './case-summary-list/case-summary-note/case-summary-note.component';
import { CaseSummaryPolicyComponent } from './case-summary-list/case-summary-policy/case-summary-policy.component';
import { CaseSummarySmsDialogComponent } from './case-summary-sms/case-summary-sms-dialog/case-summary-sms-dialog.component';
import { CaseSummarySmsModalComponent } from './case-summary-sms/case-summary-sms-modal/case-summary-sms-modal.component';
import { CaseSummarySmsComponent } from './case-summary-sms/case-summary-sms.component';
import { CaseSummaryComponent } from './case-summary.component';
import { SupplierInvoiceRowComponent } from './case-economy-history/supplier-invoice-row/supplier-invoice-row.component';
import { SupplierInvoiceItemRowComponent } from './case-economy-history/supplier-invoice-item-row/supplier-invoice-item-row.component';
import {
  CaseSummaryDigitalCaseflowComponent
} from '@secca/case/components/case-summery/case-summary-list/case-summary-dcf/case-summary-digital-caseflow.component';
import {
  CaseSummaryDigitalCaseflowModalComponent
} from '@secca/case/components/case-summery/case-summary-list/case-summary-dcf/case-summary-dcf-modal/case-summary-digital-caseflow-modal.component';
import {
  CaseSummaryDigitalCaseflowDialogComponent
} from '@secca/case/components/case-summery/case-summary-list/case-summary-dcf/case-summary-dcf-dialog/case-summary-digital-caseflow-dialog.component';
import {
  CaseSummaryDigitalCaseflowDetailsComponent
} from '@secca/case/components/case-summery/case-summary-list/case-summary-dcf/case-summary-dcf-details/case-summary-digital-caseflow-details.component';
import { ChannelTypeModalComponent } from './case-summary-list/case-summary-reported-by-channel-modal/channel-type-modal.component';
import {
  RecoveryPaymentRowComponent
} from '@secca/case/components/case-summery/case-economy-history/recovery-payment-row/recovery-payment-row.component';
import {
  RecoveryPaymentItemRowComponent
} from '@secca/case/components/case-summery/case-economy-history/recovery-payment-item-row/recovery-payment-item-row.component';

@NgModule({
  declarations: [
    CaseSummaryComponent,
    CaseSummaryEmailFaxModalComponent,
    CaseSummaryEmailFaxDialogComponent,
    CaseSummaryExternalModalComponent,
    CaseSummaryExternalDialogComponent,
    CaseSummarySmsModalComponent,
    CaseSummarySmsDialogComponent,
    CaseSummaryExternalComponent,
    CaseSummaryDigitalCaseflowModalComponent,
    CaseSummaryDigitalCaseflowDialogComponent,
    CaseSummaryDigitalCaseflowComponent,
    CaseSummaryDigitalCaseflowDetailsComponent,
    CaseSummaryConsentComponent,
    CaseSummaryNoteComponent,
    CaseSummarySmsComponent,
    CaseSummaryViewComponent,
    CaseSummaryPolicyComponent,
    CaseSummaryFilterComponent,
    CaseHistoryFilterComponent,
    CaseHistoryListComponent,
    CaseSummaryListComponent,
    CaseHistoryFilterComponent,
    CaseSummaryNoteModalComponent,
    CaseSummaryNoteDialogComponent,
    CaseSummaryEmailFaxComponent,
    CaseEconomyHistoryListComponent,
    PreviousValueComponent,
    NewValueComponent,
    RefundRowComponent,
    RefundItemRowComponent,
    CustomerInvoiceRowComponent,
    CustomerInvoiceItemRowComponent,
    SosServiceRowComponent,
    SupplierInvoiceRowComponent,
    SupplierInvoiceItemRowComponent,
    RecoveryPaymentRowComponent,
    RecoveryPaymentItemRowComponent,
    ChannelTypeModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UtilsModule,
    SharedModule
  ],
  exports: [
    CaseSummaryComponent,
    CaseSummaryViewComponent,
    CustomerInvoiceItemRowComponent
  ]
})
export class CaseSummaryModule {
}
