import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalDialogComponent } from '@secca/shared/components/modal-dialog/modal-dialog.component';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { LockContextEnum } from '@secca/shared/enums/lock-context.enum';
import { CaseLock } from '@secca/shared/models/case-lock.model';
import { CaseNotLockedError } from '@secca/shared/models/case-not-locked.error';
import { ModalDialogConfiguration } from '@secca/shared/models/modal/modal-dialog-configuration';
import { User } from '@secca/shared/models/user';
import { UserDto } from '@secca/shared/models/userDto';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CaseLockService } from './case-lock.service';
import { DictionaryService } from './dictionary.service';

@Injectable({
  providedIn: 'root',
})
@AutoUnsubscribe
export class CaseLockHelperService {
  private loggedInUser: UserDto;
  private caseLocks: CaseLock[];
  private subscription: Subscription;
  private caseId: string;
  private caseNotLockedDialog: NgbModalRef;

  private caseBasicLockTaken: boolean;
  private supplierInvoiceLockTaken: boolean;
  private isCaseLockedModalOpen = false;

  private caseLocksChanged = new Subject<void>();

  constructor(
    private caseLockService: CaseLockService,
    private dictionaryService: DictionaryService,
    private modalService: NgbModal,
    private router: Router
  ) {
    this.subscription = dictionaryService.getLoggedInUser().subscribe(user => {
      this.loggedInUser = user;
      this.updateCaseLockStatus();
    });
  }

  public resetCaseLocks(): void {
    this.updateCaseLocks(null, null);
  }

  public get getCaseId(): string{
    return this.caseId;
  }

  public updateCaseLocks(caseId: string, caseLocks: CaseLock[]): void {
    this.caseId = caseId;
    this.caseLocks = caseLocks;
    this.updateCaseLockStatus();
  }

  public lockCase(lockContext: LockContextEnum, contextId?: number): Observable<CaseLock> {
    return this.caseLockService.tryLockCase(this.caseId, lockContext, contextId).pipe(
      tap(caseLock => {
        this.addReplaceCaseLock(caseLock);
        this.updateCaseLockStatus();
      })
    );
  }

  public releaseCaseLock(lockContext: LockContextEnum, contextId?: number): Observable<CaseLock> {
    return this.caseLockService.tryReleaseCaseLock(this.caseId, lockContext, contextId);
  }

  public releaseLocksForCurrentCase(): void {
    if (this.caseLocks && this.caseLocks.length > 0) {
      this.caseLocks.forEach(caseLock => {
        this.caseLockService.tryReleaseCaseLockByName(caseLock.seccaCaseId, caseLock.lockContext).subscribe();
      });
    }
  }

  public releaseLocks(caseLocks: CaseLock[]): void {
    if (caseLocks && caseLocks.length > 0) {
      caseLocks.forEach(caseLock => {
        this.caseLockService.tryReleaseCaseLockByName(caseLock.seccaCaseId, caseLock.lockContext).subscribe();
      });
    }
  }

  public forceReleaseLock(lockContext: LockContextEnum, contextId?: number): Observable<CaseLock> {
    return this.caseLockService.forceReleaseCaseLock(this.caseId, lockContext, contextId);
  }

  public checkHasCaseLock(lockContext: LockContextEnum, contextId?: number): Observable<any> {
    if (!this.caseId) {
      return of(true);
    }

    return this.caseLockService.hasCaseLock(this.caseId, lockContext, contextId).pipe(
      tap(hasLock => {
        if (!hasLock) {
          this.showNotLockedErrorDialog(lockContext);
          throw new CaseNotLockedError('Case lock not taken!');
        }
      })
    );
  }

  public isLockTaken(lockContext: LockContextEnum, contextId?: number): boolean {
    return this.isCaseLocked(this.caseLocks, lockContext, contextId);
  }

  public lockedBy(lockContext: LockContextEnum, contextId?: number): string {
    const user = this.getLockedByUser(lockContext, contextId);

    return !user ? '' : user.firstName + ' ' + (user.surname ? user.surname : '') + (user.initials ? '(' + user.initials + ')' : '');
  }

  public isSupplierInvoiceLockTaken(contextId: number): boolean {
    return this.isCaseLocked(this.caseLocks, LockContextEnum.SUPPLIER_INVOICE, contextId);
  }

  public get isCaseBasicLockTaken(): boolean {
    return this.caseBasicLockTaken;
  }

  public get caseBasicLockedBy(): string {
    return this.lockedBy(LockContextEnum.CASE_BASIC);
  }

  public getLockedByUser(lockContext: LockContextEnum, contextId?: number): User {
    const caseLock = this.getCaseLock(this.caseLocks, lockContext, contextId);
    return caseLock ? caseLock.user : null;
  }

  public reloadCase(): void {
    this.doReloadCase();
  }

  public isCaseLockedByTheLoggedInUser(lockContext: LockContextEnum, contextId?: number): boolean {
    const caseLock = this.getCaseLock(this.caseLocks, lockContext, contextId);
    return caseLock && this.loggedInUser && this.loggedInUser.id === +caseLock.user.id;
  }

  public getCaseLocksChanged(): Observable<any> {
    return this.caseLocksChanged;
  }

  private addReplaceCaseLock(caseLock: CaseLock): void {
    if (caseLock) {
      const index = this.caseLocks && this.caseLocks.findIndex(cl => cl.lockContext === caseLock.lockContext);
      if (this.caseLocks && index !== -1) {
        this.caseLocks[index] = caseLock;
      } else {
        this.caseLocks = this.caseLocks || [];
        this.caseLocks.push(caseLock);
      }
    }
  }

  private updateCaseLockStatus(): void {
    this.caseBasicLockTaken = this.isCaseLocked(this.caseLocks, LockContextEnum.CASE_BASIC);
    this.supplierInvoiceLockTaken = this.isCaseLocked(this.caseLocks, LockContextEnum.SUPPLIER_INVOICE);

    this.caseLocksChanged.next();
  }

  private isCaseLocked(caseLocks: CaseLock[], lockContext: LockContextEnum, contextId?: number): boolean {
    const caseLock = this.getCaseLock(caseLocks, lockContext, contextId);
    return caseLock && this.loggedInUser && this.loggedInUser.id !== +caseLock.user.id;
  }

  private getCaseLock(caseLocks: CaseLock[], lockContext: LockContextEnum, contextId?: number): CaseLock {
    const lockContextName = lockContext + (contextId ? `-${contextId}` : '');
    return caseLocks && caseLocks.find((c: CaseLock) => lockContextName === c.lockContext);
  }

  private showNotLockedErrorDialog(lockContext: LockContextEnum): void {
    if (this.isCaseLockedModalOpen) {
      return;
    }

    this.caseNotLockedDialog = this.modalService.open(ModalDialogComponent, { windowClass: 'modal-ontop' });
    this.isCaseLockedModalOpen = true;
    this.caseNotLockedDialog.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'default-modal-header',
      title: lockContext === LockContextEnum.SUPPLIER_INVOICE ? 'invoice-not-locked-title' : 'case-not-locked-title',
      text: 'case-not-locked-text',
      yes: 'default-modal-dialog-refresh',
      isBody: true,
      isFooter: true,
    });

    this.caseNotLockedDialog.componentInstance.closeModalEvent.subscribe(
      emittedEvent => {
        if (emittedEvent) {
          this.doReloadCase();
        }
        this.caseNotLockedDialog.close();
      },
      error => console.log(error)
    );

    // Make sure to update state when dialog is closed
    this.caseNotLockedDialog.result.then(
      result => {
        this.isCaseLockedModalOpen = false;
      },
      error => {
        this.isCaseLockedModalOpen = false;
      }
    );
  }

  private doReloadCase(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/case', this.caseId]);
  }
}
