export enum SelectedTab {
  ExpectationTab,
  ConfirmationTab,
  DetailsTab,
}

export enum CoffinUrnHandlingTypeEnum {
  COFFIN_SENT_HOME_BY_REGULAR_FLIGHT = 'COFFIN_SENT_HOME_BY_REGULAR_FLIGHT',
  COFFIN_SENT_HOME_BY_GROUND_TRANSPORT = 'COFFIN_SENT_HOME_BY_GROUND_TRANSPORT',
  URN_SENT_HOME_BY_REGULAR_FLIGHT = 'URN_SENT_HOME_BY_REGULAR_FLIGHT',
  URN_SENT_HOME_BY_GROUND_TRANSPORT = 'URN_SENT_HOME_BY_GROUND_TRANSPORT',
  URN_BROUGHT_HOME_BY_CO_TRAVELLER = 'URN_BROUGHT_HOME_BY_CO_TRAVELLER',
  URN_BROUGHT_HOME_BY_ESCORT = 'URN_BROUGHT_HOME_BY_ESCORT',
  LOCAL_BURIAL = 'LOCAL_BURIAL',
  COFFIN = 'COFFIN',
  URN = 'URN',
}

export enum StakeholderClassTypeEnum {
  ECONOMY = 'ECONOMY',
  ECONOMY_FLEX_PREMIUM_ECONOMY = 'ECONOMY_FLEX_PREMIUM_ECONOMY',
  BUSINESS_CLASS = 'BUSINESS_CLASS',
  FIRST_CLASS = 'FIRST_CLASS',
  SAME_AS_END_USER = 'SAME_AS_END_USER',
  SEE_MEDIF = 'SEE_MEDIF'
}

export enum RegularFlightType {
  REGULAR_PASSENGERS = 'REGULAR_PASSENGERS',
  COFFIN = 'COFFIN',
  URN = 'URN'
}

export enum DetailsCommonListTypeEnum {
  NOT_ORGANIZED = 'NOT_ORGANIZED',
  ORGANIZED = 'ORGANIZED',
  NOT_RESERVED = 'NOT_RESERVED',
  RESERVED = 'RESERVED',
  BOOKED = 'BOOKED',
  AWAITING_CONFIRMATION = 'AWAITING_CONFIRMATION',
  CONFIRMED = 'CONFIRMED',
  SUPPLIED_BY_ESCORT = 'SUPPLIED_BY_ESCORT',
  NOT_AVAILABLE_FROM_SUPPLIER = 'NOT_AVAILABLE_FROM_SUPPLIER',
  NOT_RELEVANT = 'NOT_RELEVANT',
}

export enum DetailsOxygenSuppliedTypeEnum {
  INOGEN_ONE_G3 = 'INOGEN_ONE_G3',
  WENOLL = 'WENOLL',
  NOT_ORGANIZED = 'NOT_ORGANIZED',
  RESERVED = 'RESERVED',
  RESERVED_WITH_OTHER_SUPPLIER = 'RESERVED_WITH_OTHER_SUPPLIER',
  BOOKED = 'BOOKED',
  BOOKED_WITH_OTHER_SUPPLIER = 'BOOKED_WITH_OTHER_SUPPLIER',
  NOT_RELEVANT = 'NOT_RELEVANT',
}

export enum DetailsTransportTypeEnum {
  NOT_CO_TRANSPORT = 'NOT_CO_TRANSPORT',
  CO_TRANSPORT = 'CO_TRANSPORT',
}

export enum DetailsWheelchairTypeEnum {
  WCHR = 'WCHR',
  WCHS = 'WCHS',
  WCHC = 'WCHC',
  NOT_RELEVANT = 'NOT_RELEVANT',
}

export enum MedifStatusTypeEnum {
  REQUESTED = 'REQUESTED',
  AUTHORIZED = 'AUTHORIZED',
  REJECTED = 'REJECTED',
}
