import { ValidationTypeEnum } from './../../models/enums';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { InputValidationService } from './../../../core/services/input-validation.service';


@Component({
  selector: 'app-input-credit-card',
  templateUrl: './input-credit-card.component.html',
  styleUrls: ['./input-credit-card.component.scss']
})
export class InputCreditCardComponent implements OnInit {

  @Input() required: boolean;
  @Input() name: string;
  @Input() value: any;
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() validate: boolean;
  @Input() validationType: ValidationTypeEnum;
  @Input() isValid: boolean;
  @Input() withImage: boolean;
  @Output() valueChange = new EventEmitter();
  @Output() saveModel = new EventEmitter();
  @Output() imageClicked = new EventEmitter();

  oldValue: string;

  constructor(private inputValidationService: InputValidationService) {
    if (this.placeholder === undefined) {
      this.placeholder = '';
    }
  }

  onSaveState() {
    this.oldValue = this.value;
  }

  onSaveModel() {
    if (this.oldValue !== this.value) {
      let newValue: String = this.value;
      if (newValue !== null) {
        for (let i = 6; ((i < newValue.length) && (i <= 11)); i++) {
          this.value = this.setInsertXCharacter(this.value, (i));
        }
      }
      this.valueChange.emit(this.value);
      this.saveModel.emit();
    }
  }

  onKeyEnterSaveModel() {
    let newValue: String = this.value;
    if (newValue !== null) {
      for (let i = 6; ((i < newValue.length) && (i <= 11)); i++) {
        this.value = this.setInsertXCharacter(this.value, (i));
      }
    }
    this.valueChange.emit(this.value);
    this.saveModel.emit();
  }

  setInsertXCharacter(str, index) {
    return str.substr(0, index) + 'X' + str.substr(index + 1);
  }

  isValidated(val: any) {
    return this.isValid === undefined ? this.inputValidationService.isValidated(val, this.validationType) : !this.isValid;
  }

  ngOnInit() {
  }
}
