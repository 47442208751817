import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'src/app/shared/decorators/auto-unsubscribe';
import { CaseValidationService } from 'src/app/core/services/case-validation.service';
import { MessageChannelType, ShortcutEnum, ValidationCauses } from './../../../../../../shared/models/enums';
import { OutputManagementService } from './../../services/output-management.service';
import { Case } from '../../../../../../shared/models/case';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Message } from '../../models/message';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import {ServiceOrderMessageRequest} from '@secca/shared/models/service-order/service-order-message-request';
import {CaseMessageService} from '@secca/core/services/case-message.service';
import {PhoneDto} from '@secca/shared/models/phone-dto';
import {FaxDto} from '@secca/shared/models/fax-dto';
import { PermissionService } from '@secca/core/services/permission.service';
import { DigitalConsent } from '@secca/shared/models/digital-consent';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { CaseDialogViewerService } from '@secca/case/case-dialog-viewer.service';
import { ShortcutService } from '@secca/core/services/shortcut.service';
import { CaseValidation } from '@secca/shared/models/caseValidation';

@Component({
  selector: 'app-pinned-buttons',
  templateUrl: './pinned-buttons.component.html',
  styleUrls: ['./pinned-buttons.component.scss'],
})
@AutoUnsubscribe
export class PinnedButtonsComponent implements OnInit, OnDestroy {
  @Input()
  public get seccaCase(): Case {
    return this._seccaCase;
  }
  public set seccaCase(seccaCase: Case) {
    if (seccaCase != null) {
      this.disablePinnedButtons = seccaCase.caseNumber == null;
      this._seccaCase = seccaCase;
      this.isCaseValid();
    }
  }
  private _seccaCase: Case;

  @Input() caseClosed: false;

  @Input() consent: DigitalConsent;
  messageChannelType: MessageChannelType;
  message: ServiceOrderMessageRequest;

  disablePinnedButtons = true;
  $subscrCaseValid;
  $outputManagementSubscr: Subscription;

  private shortcutSubscriptions: Subscription[] = [];

  constructor(
    private outputManagementService: OutputManagementService,
    private shortcutService: ShortcutService,
    public caseLockHelperService: CaseLockHelperService,
    public permissionService: PermissionService,
    private caseValidationService: CaseValidationService,
    private caseMessageService: CaseMessageService,
    public caseStateService: CaseStateService,
    private dialogViewerService: CaseDialogViewerService
  ) {
    this.shortcutSubscriptions.push(
      this.shortcutService.addShortcut({ keys: ShortcutEnum.OutputManagementEmail }).subscribe(a => {
        if (!this.caseClosed) {
          this.openMessageModal(MessageChannelType.EMAIL);
        }
      }),
      this.shortcutService.addShortcut({ keys: ShortcutEnum.OutputManagementSms }).subscribe(a => {
        if (!this.caseClosed) {
          this.openMessageModal(MessageChannelType.SMS);
        }
      }),
    );
  }

  ngOnInit() {
    this.$outputManagementSubscr = this.outputManagementService.openOutputManagementDialog.subscribe(message => {
      this.openMessageModalWithMessage(message, message.message?.messageChannelType);
    });
  }

  ngOnDestroy(): void {
    this.shortcutSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  isCaseValid() {
    if (this.$subscrCaseValid != null) {
      this.$subscrCaseValid.unsubscribe();
    }
    this.$subscrCaseValid = this.caseValidationService.isCaseValid(this.seccaCase.id).subscribe(result => {
      if (result.caseValidateList.length === 0) {
        this.disablePinnedButtons = false;
        return;
      }
      else if (this.seccaCase?.incident?.incidentFreeText && result?.caseValidateList?.length === 1 && result?.caseBeenValidated) {
        if ( result.caseValidateList[0] === ValidationCauses.INCIDENT_LOCATION ) {
          this.disablePinnedButtons = false;
          return;
        }
      }
      this.disablePinnedButtons = true;
    });
  }

  openMessageModal(messageChannelType: MessageChannelType) {
    if (this.disablePinnedButtons) {
      return;
    }

    const message = new ServiceOrderMessageRequest({messageService: this.caseMessageService,
      message: new Message({messageChannelType: this.messageChannelType,
        sms: new PhoneDto(),
        fax: new FaxDto(),
        caseId: this.seccaCase.id})
    });

    this.openMessageModalWithMessage(message, messageChannelType);
  }

  openMessageModalWithMessage(message, messageChannelType) {
    this.dialogViewerService.openOutputManagementDialog(this.seccaCase, messageChannelType, message, this.consent);
  }

  existsMessage(messageChannelType: MessageChannelType): boolean {
    return false;
  }

  get MessageChannelType() {
    return MessageChannelType;
  }
}
