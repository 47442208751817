<div class="modal-content">
  <div class="modal-header">
    <div class="modal-header-title">
      {{ 'medical-history-report-supplier-portal-title' | translate }}
    </div>
    <div class="modal-buttons">
      <div (click)="closeTaskWebDialog()">
        <img src="/assets/icons/Close White.svg" />
      </div>
    </div>
  </div>
  <div class="modal-content-body">
    <div>
      <app-task-web-medical-report-panel
        [taskDataViewModel]="taskDataViewModel"
        [serviceItemList]="serviceItemList"
        [purposeOfTravelItemList]="purposeOfTravelItemList"
        [causes]="causes"
        [diagnoses]="diagnoses"
        [customerProfiles]="customerProfiles"
      >
      </app-task-web-medical-report-panel>
    </div>
  </div>
</div>
