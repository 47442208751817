import {AfterViewInit, Component, Inject, ViewChild} from '@angular/core';
import {AdditionalInfo, IAdditionalInfo} from '@secca/shared/interfaces/additional-info';
import {IMinimizable} from '@secca/shared/interfaces/minimizable';
import {IKeyboardEnabled} from '@secca/shared/interfaces/keyboard-enabled';
import {DialogMinimizableDirective} from '@secca/shared/directives/dialog-minimizable.directive';
import {
  CaseSummaryNoteLinkedCaseDialogData
} from '@secca/case/components/case-summery/case-summary-list/case-summary-note/case-summary-note-linked-case-dialog/case-summary-note-linked-case-data.component';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {DialogHelperUtilService} from '@secca/core/services/dialog-helper-util.service';
import {DialogBoundryService} from '@secca/core/services/dialog-boundry.service';
import {MinimizableDialogType} from '@secca/shared/enums/minimizable-dialog-type-enum';
import {
  CaseSummaryNoteLinkedCaseModalComponent
} from '@secca/case/components/case-summery/case-summary-list/case-summary-note/case-summary-note-linked-case-modal/case-summary-note-linked-case-model.component';

@Component({
  selector: 'app-case-summary-note-linked-case-dialog',
  templateUrl: './case-summary-note-linked-case-dialog.component.html',
  styleUrls: [],
})
export class CaseSummaryNoteLinkedCaseDialogComponent implements AfterViewInit, IAdditionalInfo, IKeyboardEnabled {
  @ViewChild(CaseSummaryNoteLinkedCaseModalComponent) wrappedInstance: CaseSummaryNoteLinkedCaseModalComponent;
  @ViewChild(DialogMinimizableDirective) minimizedDirective: DialogMinimizableDirective;

  data: CaseSummaryNoteLinkedCaseDialogData;

  constructor(private dialogRef: MatDialogRef<CaseSummaryNoteLinkedCaseModalComponent>,
              @Inject(MAT_DIALOG_DATA) data: CaseSummaryNoteLinkedCaseDialogData,
              private dialogHelperUtilService: DialogHelperUtilService,
              public dialogBoundaryService: DialogBoundryService) {
    this.data = data;
  }

  ngAfterViewInit() {}

  close() {
    this.dialogHelperUtilService.close(this.dialogRef, this.getAdditionalInfo()?.caseId);
  }

  keyboardClose(): void {
    this.wrappedInstance.closeModal();
  }

  minimize() {
    this.minimizedDirective.minimize();
  }

  getMinimizeType(): MinimizableDialogType {
    return this.minimizedDirective.appDialogMinimizable;
  }

  getAdditionalInfo(): AdditionalInfo {

    return {
      caseId: +this.data?.caseId,
      hoverTitle: 'Copy Note',
      hoverText: ''
    };
  }

  get enabledKeys(): string[] {
    return null;
  }

  get MinimizableDialogType() {
    return MinimizableDialogType;
  }
}
