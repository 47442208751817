import { ServiceOrder } from '@secca/shared/models/service-order/service-order';
export class ServiceOrderHoverModel {
  serviceOrder: ServiceOrder;
  topHoverOffset: number;
  serviceWidth: number;
  serviceHeight: number;
  serviceTopPosition: number;
  serviceLeftPosition: number;
  serviceTypeIcon: string;
  providerName: string;
  agentName: string;
  incidentEvent: string;
}
