import {Injectable} from '@angular/core';
import {Adapter} from '../interfaces/adapter';
import { DaySerializer } from './day-serializer';
import * as moment from 'moment';

export class SimpleIncident {

  id: number;
  incidentCity: string;
  incidentCountry: string;
  currentCity: string;
  currentCountry: string;
  incidentDate: moment.Moment;
  incidentCause: string;
  causeLevel1Code: string;
  causeLevel2Code: string;
  causeLevel3Code: string;
  countryCodeAlpha2: string; // incident-location.
  currentCountryCodeAlpha2: string; // current-location.
  incidentFreeText: string;

  public constructor(init?: Partial<SimpleIncident>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class SimpleIncidentAdapter implements Adapter<SimpleIncident> {
  adapt(item: any): SimpleIncident {
    return new SimpleIncident({
      id: item.id,
      incidentCity: item.incidentCity,
      incidentCountry: item.incidentCity,
      currentCity: item.currentCity,
      currentCountry: item.currentCountry,
      incidentDate: item.incidentDate,
      incidentCause: item.incidentCause,
      causeLevel1Code: item.incidentCauseLevel1Code,
      causeLevel2Code: item.incidentCauseLevel2Code,
      causeLevel3Code: item.incidentCauseLevel3Code,
      countryCodeAlpha2: item.countryCodeAlpha2, // incident-location
      currentCountryCodeAlpha2: item.currentCountryCodeAlpha2, // current-location
      incidentFreeText: item.incidentFreeText,
    });
  }

  adaptFromIncident(item: any): SimpleIncident {
    return new SimpleIncident({
      id: item.id,
      incidentCity: item.incidentLocation == null ? '' : item.incidentLocation.locality,
      incidentCountry: item.incidentLocation == null ? '' : item.incidentLocation.country,
      currentCity: item.currentLocation == null ? '' : item.currentLocation.locality,
      currentCountry: item.currentLocation == null ? '' : item.currentLocation.country,
      incidentDate: DaySerializer.deserialize(item.incidentDate),
      incidentCause: item.causeLevel1Text,
      causeLevel1Code: item.causeLevel1Code,
      causeLevel2Code: item.causeLevel2Code,
      causeLevel3Code: item.causeLevel3Code,
      countryCodeAlpha2: item.incidentLocation == null ? '' : item.incidentLocation.countryCodeAlpha2,
      currentCountryCodeAlpha2: item.currentLocation == null ? '' : item.currentLocation.countryCodeAlpha2,
      incidentFreeText: item.incidentLocation == null ? '' : item.incidentLocation.freeText,
    });
  }
}
