import {AutoUnsubscribe} from '@secca/shared/decorators/auto-unsubscribe';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CaseHistoryCommEntry, CaseHistoryEntry} from '@secca/shared/models/caseHistoryEntry';
import {CallType, CommunicationDirection, NoteTypeEnum, PermissionEnum, PermissionHideTypeEnum} from '@secca/shared/models/enums';
import {CaseStakeholder} from '@secca/shared/models/caseStakeholder';
import {CaseObjectType} from '@secca/shared/enums/case-history-type';
import {CaseSummaryService} from '@secca/core/services/caseSummary.service';
import {CaseSummaryHelperService} from '@secca/core/services/case-summary-helper.service';
import {StakeholderHelperService} from '@secca/core/services/stakeholder-helper.service';
import {StakeholderService} from '@secca/core/services/stakeholder.service';
import {CaseLockHelperService} from '@secca/core/services/case-lock-helper.service';
import {Note} from '@secca/shared/models/note';
import {NoteService} from '@secca/core/services/note.service';
import {PhoneService} from '@secca/core/services/phone.service';
import {ProviderHelper} from '@secca/shared/helpers/provider-helper';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Moment} from 'moment';
import {PermissionService} from '@secca/core/services/permission.service';
import {Case} from '@secca/shared/models/case';
import {CaseStateService} from '@secca/core/state-services/case-state.service';
import {CaseDialogViewerService} from '@secca/case/case-dialog-viewer.service';
import {ModalDialogComponent} from '@secca/shared/components/modal-dialog/modal-dialog.component';
import {ModalDialogConfiguration} from '@secca/shared/models/modal/modal-dialog-configuration';
import {DeleteIdWithReason} from '@secca/shared/models/delete-id-with-reason';
import { LockContextEnum } from '@secca/shared/enums/lock-context.enum';

@AutoUnsubscribe
@Component({
  selector: 'app-case-summary-note',
  templateUrl: './case-summary-note.component.html',
  styleUrls: ['./case-summary-note.component.scss'],
})
export class CaseSummaryNoteComponent implements OnInit {
  @Input()
  case: Case;

  @Input() set entry(entry: CaseHistoryCommEntry) {
    if (entry) {
      this._entry = entry;
      this.tryToMatchStakeholder();
    }
  }
  get entry(): CaseHistoryCommEntry {
    return this._entry;
  }
  _entry: CaseHistoryCommEntry;

  get stakeholders(): CaseStakeholder[] {
    return this._stakeholders;
  }
  @Input()
  set stakeholders(stakeholders: CaseStakeholder[]) {
    if (stakeholders) {
      this._stakeholders = stakeholders;
      this.tryToMatchStakeholder();
    }
  }

  @Input() caseSummaryEntities: any;
  @Output() noteIdToDelete = new EventEmitter<DeleteIdWithReason>();

  @Input() numberOfLinkedCases: number;

  get showCopyLink(): boolean {
    return this.numberOfLinkedCases > 0;
  }
  _stakeholders: CaseStakeholder[];

  stakeholder: CaseStakeholder;

  private providerHelper: ProviderHelper;
  wasNoteEdited: boolean;
  $subscrEditNoteUpdated;
  deleteReason: string;
  longStakeholderName: boolean = false;

  constructor(
    private caseSummaryService: CaseSummaryService,
    private caseSummaryHelperService: CaseSummaryHelperService,
    private stakeholderHelperService: StakeholderHelperService,
    private stakeholderService: StakeholderService,
    public caseLockHelperService: CaseLockHelperService,
    public permissionService: PermissionService,
    private noteService: NoteService,
    private phoneService: PhoneService,
    private modalService: NgbModal,
    private dialogViewerService: CaseDialogViewerService,
    public caseStateService: CaseStateService
  ) {}

  ngOnInit(): void {
    this.$subscrEditNoteUpdated = this.noteService.editedNoteUpdated.subscribe(result => {
      if (result.id === this.entry.entityId) {
        if (result.note.deletedBy === null ) {
          this.wasNoteEdited = true;
        }
        this.caseSummaryHelperService.updateNote(this.caseSummaryEntities, result.id, result.note);
      }
    });
  }

  get CallType() {
    return CallType;
  }

  get NoteTypeEnum() {
    return NoteTypeEnum;
  }

  get CaseObjectType() {
    return CaseObjectType;
  }

  showLongStakeholderName() {
    this.longStakeholderName = !this.longStakeholderName;
  }

  getStakeholderIcon(noteId: number) {
    return this.stakeholderService.getStakeholderIconFullPath(
      this.caseSummaryHelperService.getNoteStakeholderType(this.caseSummaryEntities, noteId)
    );
  }

  private isEdited(entry: CaseHistoryEntry): boolean {
    return this.caseSummaryHelperService.isEdited(this.caseSummaryEntities, entry);
  }

  private isCopied(noteId: number): boolean {
    return this.caseSummaryHelperService.isCopied(this.caseSummaryEntities, noteId);
  }

  private edititem(noteId: number) {
    const editedNote = this.entityToNote(this.caseSummaryEntities.NOTE[noteId]);
    this.noteService.send(editedNote);
  }

  viewNoteDocuments(noteId: number) {
    this.dialogViewerService.openCaseSummaryNoteDialog(this.entry, this.getNoteDocuments(noteId), this.case.caseNumber);
  }

  private entityToNote(note: any): Note {
    return this.caseSummaryHelperService.entityToNote(note);
  }

  getNoteType(noteId: number) {
    return this.caseSummaryHelperService.getNoteType(this.caseSummaryEntities, noteId);
  }

  getNoteDocuments(noteId: number) {
    return this.caseSummaryHelperService.getNoteDocuments(this.caseSummaryEntities, noteId);
  }

  private getNoteCalltype(noteId: number) {
    return this.caseSummaryHelperService.getNoteCallType(this.caseSummaryEntities, noteId);
  }

  private getNoteStakeholderName(noteId: number) {
    return this.caseSummaryHelperService.getNoteStakeholderName(this.caseSummaryEntities, noteId);
  }

  isNoteDeleted(noteId: number) {
    return this.getNoteDeletedBy(noteId) !== null;
  }

  private getNoteDescriptionSplited(noteId: number): string[] {
    return this.caseSummaryHelperService.getNoteDescriptionSplited(this.caseSummaryEntities, noteId);
  }

  private getNoteDeletedBy(noteId: number) {
    return this.caseSummaryHelperService.getNoteDeletedBy(this.caseSummaryEntities, noteId);
  }

  private registerDeleteItem(id: number, reason?: string) {
    this.noteIdToDelete.emit({id, reason});
  }

  public getPhoneNumber(noteId: number) {
    return this.caseSummaryHelperService.getPhoneNumber(this.caseSummaryEntities, noteId);
  }

  private getPhoneCallDuration(caseHistory: CaseHistoryEntry, noteId: number) {
    const totalTimeInSec = this.caseSummaryHelperService.getPhoneCallDuration(caseHistory, this.caseSummaryEntities, noteId);

    if (totalTimeInSec == null || Number(totalTimeInSec) <= 0) {
      return null;
    }
    return this.formatTimeDuration(totalTimeInSec);
  }

  getPhoneStartTime(entityId: number): Moment {
    return this.caseSummaryHelperService.getPhoneStartTime(this.caseSummaryEntities, entityId);
  }

  private formatTimeDuration(totalTimeInSecAsString: string): string {
    const totalTimeInSec = Number(totalTimeInSecAsString);
    const hours = Math.floor(totalTimeInSec / 3600);
    const minutes = Math.floor((totalTimeInSec - hours * 3600) / 60);
    const seconds = totalTimeInSec - hours * 3600 - minutes * 60;

    return minutes.toString().padStart(2, '0') + 'm ' + seconds.toString().padStart(2, '0') + 's';
  }

  performCall(entityId: number) {
    const phoneNumber = this.getPhoneNumber(entityId);
    this.phoneService.performCall('', phoneNumber).subscribe(
      result => {},
      error => {
        this.openProviderModal(error);
      }
    );
  }

  private openProviderModal(error: any) {
    this.providerHelper = new ProviderHelper(this.modalService);
    this.providerHelper.messageError(error, 'phone-error-modal-header', 'phone-error-title', 'phone-error-message');
  }

  tryToMatchStakeholder() {
    if (this.stakeholders != null && this.entry != null) {
      if (this.entry.direction === CommunicationDirection.IN_BOUND) {
        this.stakeholder = this.stakeholders.find(a => +a.id === this.entry.stakeholderSenderId);
      }
      if (this.entry.direction === CommunicationDirection.OUT_BOUND) {
        this.stakeholder = this.stakeholders.find(a => +a.id === this.entry.stakeholderReceiverId);
      }
    }
  }

  openCopyNoteDialog(noteId: number) {
    const noteElement = this.caseSummaryEntities.NOTE[noteId];
    this.dialogViewerService.openCaseSummaryNoteLinkedCaseDialog(this.case.id, noteElement);
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  get PermissionHideTypeEnum() {
    return PermissionHideTypeEnum;
  }

  get LockContextEnum() {
    return LockContextEnum;
  }

  openModal() {

    const modalRef = this.modalService.open(ModalDialogComponent);
    modalRef.componentInstance.items = [];
    modalRef.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'default-modal-header',
      title: 'case-summary-modal-gdpr-delete',
      text: '',
      footer: 'case-summary-modal-gdpr-delete-proceed',
      showYesNoButton: false,
      yes: 'Yes',
      no: 'No',
      labelTextAboveTextArea: 'case-summary-modal-gdpr-delete-reason-label',
      placeholderText: 'case-summary-modal-gdpr-delete-reason-placeholder',
      showTextAreaWithYesNo: true,
      showTextArea: true,
      isBody: true,
      isFooter: true,
      modalYesHoverDialog: 'case-summary-modal-gdpr-delete-yes-hover'
    });

    modalRef.componentInstance.saveandcloseEvent.subscribe(
      emittedEvent => {
        this.closeModal();
        if (emittedEvent) {
          this.deleteReason = emittedEvent;
          this.registerDeleteItem(this.entry.entityId, this.deleteReason);
        }
      },
      error => { }
    );

    modalRef.componentInstance.closeModalEvent.subscribe(
      emittedEvent => {
        this.closeModal();
      },
      error => console.log('error', error)
    );
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  setTextVisible() {

  }
}
