
import { PatientNeeds, PatientNeedsAdapter } from './patient-needs';
import { PatientState, PatientStateAdapter } from './patient-state';
import { RepatriationAlternativeForm, RepatriationAlternativeFormAdapter } from './repatriation-alternative-form';
import { RepatriationForm, RepatriationFormAdapter } from './repatriation-form';

import { Injectable } from '@angular/core';
import { Adapter } from '../../interfaces/adapter';

export class RepatriationInstructionsBase {
  repatriationInstructionId: number;
  repatriationForm: RepatriationForm;
  alternativeFormStretcher: RepatriationAlternativeForm;
  alternativeFormExtraSpace: RepatriationAlternativeForm;
  alternativeFormEconomyClass: RepatriationAlternativeForm;
  alternativeFormRegularPassenger: RepatriationAlternativeForm;
  patientState: PatientState;
  patientNeeds: PatientNeeds;
  treatingDoctorName: string;
  treatingDoctorType: string;

  public constructor(init?: Partial<RepatriationInstructionsBase>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
export class RepatriationInstructionsBaseAdapter implements Adapter<RepatriationInstructionsBase> {
  adapt(item: any): RepatriationInstructionsBase {
    return new RepatriationInstructionsBase({
      repatriationForm: !!item.repatriationForm ? new RepatriationFormAdapter().adapt(item.repatriationForm) : null,
      alternativeFormStretcher: !!item.alternativeFormStretcher ?
      new RepatriationAlternativeFormAdapter().adapt(item.alternativeFormStretcher) : null,
      alternativeFormExtraSpace: !!item.alternativeFormExtraSpace ?
      new RepatriationAlternativeFormAdapter().adapt(item.alternativeFormExtraSpace) : null,
      alternativeFormEconomyClass: !!item.alternativeFormEconomyClass ?
      new RepatriationAlternativeFormAdapter().adapt(item.alternativeFormEconomyClass) : null,
      alternativeFormRegularPassenger: !!item.alternativeFormRegularPassenger ?
      new RepatriationAlternativeFormAdapter().adapt(item.alternativeFormRegularPassenger) : null,
      patientState: !!item ? new PatientStateAdapter().adapt(item.patientState) : null,
      patientNeeds: !!item ? new PatientNeedsAdapter().adapt(item.patientNeeds) : null,
      treatingDoctorName: item.treatingDoctorName,
      treatingDoctorType: item.treatingDoctorType,
      repatriationInstructionId: item.id,
    });
  }

  private adaptAlternativeForms(items: any[]): RepatriationAlternativeForm[] {
    const altForms = new Array<RepatriationAlternativeForm>();
    items.forEach(item => altForms.push(new RepatriationAlternativeFormAdapter().adapt(item)));
    return altForms;
  }
}
