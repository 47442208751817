import {Injectable} from '@angular/core';
import {Adapter} from './../interfaces/adapter';
import {ConsentStatusEnum} from '@secca/shared/models/enums';
import * as moment from 'moment';
import { DateSerializer } from '@secca/shared/models/date-serializer';

export class DigitalConsent {
  seccaCaseId: number;
  includeConsent: boolean;
  consentType: ConsentStatusEnum;
  hasLegalGuardian: boolean;
  hasLegalGuardianSMS: boolean;
  hasCustomerProfile: boolean;
  consentUrlAc: string;
  consentUrlDoctor: string;
  caseStakeholder: number;
  consentUrlUser: string;
  consentDate: moment.Moment;

  public constructor(init?: Partial<DigitalConsent>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class DigitalConsentAdapter implements Adapter<DigitalConsent> {
  private consentDate: moment.Moment;

  adapt(item: any): DigitalConsent {
    if (item == null) {
      return new DigitalConsent();
    }

    return new DigitalConsent({
      seccaCaseId: item.seccaCaseId,
      includeConsent: item.includeConsent,
      consentType: item.consentType,
      hasLegalGuardian: item.hasLegalGuardian,
      hasLegalGuardianSMS: item.hasLegalGuardianSMS,
      hasCustomerProfile: item.hasCustomerProfile,
      consentUrlAc: item.consentUrlAc,
      consentUrlDoctor: item.consentUrlDoctor,
      caseStakeholder: item.caseStakeholder,
      consentUrlUser: item.consentUrlUser,
      consentDate: DateSerializer.deserialize(item.consentDate)
    });
  }
}
