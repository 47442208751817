import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "@secca/shared/shared.module";
import { UtilsModule } from "src/app/utils/utils.module";
import { CaseBasicsCoTravellersComponent } from "./case-basics-co-travellers/case-basics-co-travellers.component";
import { CaseBasicsIncidentDetailsComponent } from "./case-basics-incident/case-basics-incident-details/case-basics-incident-details.component";
import { CaseBasicsIncidentComponent } from "./case-basics-incident/case-basics-incident.component";
import { CaseBasicsInfoUserComponent } from "./case-basics-info/case-basics-info-user/case-basics-info-user.component";
import { CaseBasicsInfoComponent } from "./case-basics-info/case-basics-info.component";
import { CaseBasicsInsuranceDatesComponent } from "./case-basics-insurance/case-basics-insurance-dates/case-basics-insurance-dates.component";
import { CaseBasicsInsuranceDetailsComponent } from "./case-basics-insurance/case-basics-insurance-details/case-basics-insurance-details.component";
import { CaseBasicsInsuranceOtherComponent } from "./case-basics-insurance/case-basics-insurance-other/case-basics-insurance-other.component";
import { CaseBasicsInsuranceProductComponent } from "./case-basics-insurance/case-basics-insurance-product/case-basics-insurance-product.component";
import { CaseBasicsInsuranceComponent } from "./case-basics-insurance/case-basics-insurance.component";
import { CoverageViewComponent } from "./case-basics-insurance/coverage-view/coverage-view.component";
import { LimitDescriptionComponent } from "./case-basics-insurance/coverage-view/limit-description/limit-description.component";
import { LimitsViewComponent } from "./case-basics-insurance/coverage-view/limits-view/limits-view.component";
import { PolicyLookupContactComponent } from "./case-basics-insurance/policy-lookup-contact/policy-lookup-contact.component";
import { PolicyLookupDetailsComponent } from "./case-basics-insurance/policy-lookup-details/policy-lookup-details.component";
import { PolicyLookupDialogComponent } from "./case-basics-insurance/policy-lookup-dialog/policy-lookup-dialog.component";
import { PolicyLookupIntegrationStatusComponent } from "./case-basics-insurance/policy-lookup-integration-status/policy-lookup-integration-status.component";
import { PolicyLookupPersonComponent } from "./case-basics-insurance/policy-lookup-person/policy-lookup-person.component";
import { PolicyLookupComponent } from "./case-basics-insurance/policy-lookup/policy-lookup.component";
import { PolicyViewDialogComponent } from "./case-basics-insurance/policy-view-dialog/policy-view-dialog.component";
import { PolicyViewComponent } from "./case-basics-insurance/policy-view/policy-view.component";
import { ProfileLookupDetailsComponent } from "./case-basics-insurance/profile-lookup-details/profile-lookup-details.component";
import { ProfileLookupDialogComponent } from "./case-basics-insurance/profile-lookup-dialog/profile-lookup-dialog.component";
import { ProfileLookupComponent } from "./case-basics-insurance/profile-lookup/profile-lookup.component";
import { CaseBasicsPlannedTravelDetailsComponent } from "./case-basics-planned-travel/case-basics-planned-trave-details/case-basics-planned-travel-details.component";
import { CaseBasicsPlannedTravelComponent } from "./case-basics-planned-travel/case-basics-planned-travel.component";
import { CaseBasicsComponent } from "./case-basics.component";
import { LinkedComponent } from './case-basics-info/linked/linked.component';
import { LinkedModalLinkCaseComponent } from './case-basics-info/linked/linked-modal-link-case/linked-modal-link-case.component';
import { CoordinationCaseComponent } from './case-basics-info/coordination-case/coordination-case.component';
import { LinkedCoordinationCasesComponent } from './case-basics-info/linked-coordination-cases/linked-coordination-cases.component';
import { LinkedCoordinationCaseModalComponent } from './case-basics-info/linked-coordination-cases/linked-coordination-case-modal/linked-coordination-case-modal.component';

@NgModule({
  declarations: [
    CaseBasicsComponent,
    CaseBasicsCoTravellersComponent,
    CaseBasicsIncidentComponent,
    CaseBasicsIncidentDetailsComponent,
    CaseBasicsInfoComponent,
    CaseBasicsInfoUserComponent,
    CaseBasicsInsuranceComponent,
    CaseBasicsInsuranceDatesComponent,
    CaseBasicsInsuranceOtherComponent,
    CaseBasicsInsuranceDetailsComponent,
    CaseBasicsInsuranceProductComponent,
    CaseBasicsPlannedTravelDetailsComponent,
    CaseBasicsPlannedTravelComponent,
    PolicyViewComponent,
    PolicyViewDialogComponent,
    PolicyLookupDetailsComponent,
    PolicyLookupPersonComponent,
    PolicyLookupComponent,
    PolicyLookupDialogComponent,
    ProfileLookupDialogComponent,
    ProfileLookupDetailsComponent,
    PolicyLookupIntegrationStatusComponent,
    ProfileLookupComponent,
    CoverageViewComponent,
    LimitDescriptionComponent,
    PolicyLookupContactComponent,
    LimitsViewComponent,
    LinkedComponent,
    LinkedModalLinkCaseComponent,
    CoordinationCaseComponent,
    LinkedCoordinationCasesComponent,
    LinkedCoordinationCaseModalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UtilsModule,
    SharedModule
  ],
  exports: [
    CaseBasicsComponent
  ]
})
export class CaseBasicsModule { }
