import * as moment from 'moment';
import { SensitiveType } from '@secca/shared/models/enums';
export class TabData {
  caseId: string;
  caseNumber: string;
  caseTypeCode: string;
  logo: string;
  user: string;
  cause: string;
  country: string;
  newlyCreated = false;
  loading = false;
  incidentDate: Date;
  sensitiveType: SensitiveType;
}
