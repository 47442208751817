import { Injectable } from '@angular/core';
import { TeamDto } from '@secca/shared/models/teamDto';
import { UserTeamDto } from '@secca/shared/models/user-team-dto';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BoardShareService {
  private selectedCaseWorker = new Subject<BoardShareCommunicationModel>();
  private changeCaseEventMenu = new Subject<boolean>();

  public sendSelectedCaseWorker(boardShareCommunicationModel: BoardShareCommunicationModel) {
      this.selectedCaseWorker.next(boardShareCommunicationModel);
  }

  public getSelectedCaseWorker(): Observable<BoardShareCommunicationModel> {
    return this.selectedCaseWorker.pipe(debounceTime(500));
  }

  public changedCaseEventMenu(show: boolean) {
    this.changeCaseEventMenu.next(show);
  }

  public getCaseEventMenu(): Observable<boolean> {
    return this.changeCaseEventMenu.asObservable();
  }

  public findSelectedTeamNames(allTeams: TeamDto[], selectedTeams: string[]): string[] {
    if(!allTeams) {
      let JSONTeamNames;
      JSONTeamNames = sessionStorage.getItem('teamNames');
      if (!!JSONTeamNames) {
        allTeams = JSON.parse(JSONTeamNames) as TeamDto[];
      }
    }

    if(!selectedTeams) {
      let JSONTodoTeamAndUser;
      JSONTodoTeamAndUser = sessionStorage.getItem('team');
      let selectedTeamNew = [];

      if (!!JSONTodoTeamAndUser) {
        const parsedBoardCaseFilterRequest = JSON.parse(JSONTodoTeamAndUser) as BoardShareCommunicationModel;
        selectedTeamNew = parsedBoardCaseFilterRequest.adtCodes;
      }
      selectedTeams = selectedTeamNew;
    }
    let selectedTeamNames = [];
    if(allTeams) {
      selectedTeams.forEach(teamName => {
        selectedTeamNames.push(allTeams.find(element => element.adtCode === teamName).teamId);
      })
    }
    return selectedTeamNames;
  }
}

export class BoardShareCommunicationModel {
  userId: number;
  userIds: number[];
  adtCode: string;
  adtCodes: string[];
  teamOnly: boolean;
  userDto: UserTeamDto;
  userDtos: UserTeamDto[];
  selectedTeamsForSearch: string[]
  adtCodesForCount: string[];
  teamNames: string[];
  selectedTeamNames: string[];

  public constructor(init?: Partial<BoardShareCommunicationModel>) {
    Object.assign(this, init);
  }
}
