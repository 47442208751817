import {Injectable} from '@angular/core';
import {BaseService} from '@secca/core/services/base.service';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {SettingsService} from '@secca/core/services/settings.service';
import {CoordinationDetails, CoordinationDetailsAdapter} from '@secca/shared/models/coordination-details';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CoordinationDetailsService extends BaseService {
  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private coordinationDetailsAdapter: CoordinationDetailsAdapter
  ) {
    super(settingsService);
  }

  private details = new BehaviorSubject<CoordinationDetailsWithCaseId>(undefined);



  public saveCoordinationDetails(caseId: string, details: CoordinationDetails): Observable<CoordinationDetails> {
    return this.http.post<CoordinationDetails>(`${this.baseURL}coordination/${caseId}`,
      details, { headers: this.jsonHeaders })
        .pipe(tap(result => this.details.next({caseId, details: result})));
  }

  getCoordinationDetails(caseId: string) {
    return this.http.get<CoordinationDetails>(`${this.baseURL}coordination/${caseId}`)
      .pipe(
        map(cd => this.coordinationDetailsAdapter.adapt(cd)),
        tap(details => this.details.next({caseId, details})),
        catchError(err => this.handleError(err))
      );
  }
  public coordinationDetailsChanges() {
    return this.details.asObservable();
  }

  handleError(error: HttpErrorResponse) {
    if (error.status === 404) {
      return of(new CoordinationDetails());
    } else {
      throw error;
    }
  }

}

export class CoordinationDetailsWithCaseId {
  caseId: string;
  details: CoordinationDetails;
}
