import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BoardShareService } from '@secca/core/services/board-share.service';
import { StakeholderService } from '@secca/core/services/stakeholder.service';
import { OnSaveData } from '@secca/shared/interfaces/on-save-data';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import { StakeholderTypeEnum } from '@secca/shared/models/enums';
import { UserTeamDto, UserTeamDtoAdapter } from '@secca/shared/models/user-team-dto';
import { UserDto } from '@secca/shared/models/userDto';
import { BoardTeamCaringService } from '../board-team-caring.service';

@Component({
  selector: 'app-drop-down-team-user',
  templateUrl: './drop-down-team-user.component.html',
  styleUrls: ['./drop-down-team-user.component.scss']
})
export class DropDownTeamUserComponent implements OnSaveData {
  @Input() name: string;
  @Input() items: UserTeamDto[];
  @Output() saveModel = new EventEmitter();
  @Output() selectedItemIdChange = new EventEmitter<{ selectedItemId: UserTeamDto[], allClicked: boolean, selectedTeamNames: string[] }>();
  @Output() open = new EventEmitter();
  disableUserDropdown: boolean;


  @Input()
  set selectedItemId(newSelectedItemId: UserTeamDto[]) {
    if (newSelectedItemId !== undefined) {
      this._selectedItemId = newSelectedItemId;
      this.userForEventList = newSelectedItemId;
    }
  }

  get selectedItemId(): UserTeamDto[] {
    return this._selectedItemId;
  }
  private _selectedItemId: UserTeamDto[];



  allSelected: boolean = false;
  teamSelected: boolean = false;
  userSelected: boolean = false;
  clickedUser: UserTeamDto;
  userForEventList: UserTeamDto[];
  empty: boolean = false;
  @Input() selectedTeams: string[];
  $subscr4;

  oldSelectedItemId: UserDto;
  constructor(
              private stakeholderService: StakeholderService,
              private boardTeamCaringService: BoardTeamCaringService,
              private userTeamDtoAdapter: UserTeamDtoAdapter,
              private boardShareService: BoardShareService,) {
                this.$subscr4 = this.boardShareService.getCaseEventMenu().subscribe(result => {
                        this.disableUserDropdown = result;
                        this.handleTypeOfList();
                      }
                  );
              }

  onChange(event) {
    this.selectedItemId = event;
    this.userForEventList = event;
    this.selectedItemIdChange.emit({selectedItemId:  this.selectedItemId, allClicked: this.clickedUser?.initials === 'ADT00' ? true : false, selectedTeamNames: this.boardShareService.findSelectedTeamNames(null, this.selectedTeams) });
  }

  onOpen() {
    this.open.emit();
  }

  handleTypeOfList() {
    if(this.disableUserDropdown) {
      setTimeout(() => {
      if(!!this.userForEventList)  {
        this.userForEventList.push(new UserTeamDto({active: true, firstName: 'All', isTeam: true, fullName: 'All', initials: 'ADT00'}) );
        this.selectedItemIdChange.emit({selectedItemId: this.userForEventList, allClicked: true, selectedTeamNames: this.boardShareService.findSelectedTeamNames(null, this.selectedTeams)});
      }
      }, 1000);
    }
  }

  onSaveModel() {
    this.empty = false;
    this.saveModel.emit();
  }

  onSaveState(){
    this.empty = true;
  }

  getSelectedItem(item) {
    this.clickedUser = item;
  }

  close() {
    this.empty = false;
  }

  displayMultiLabel(): string {
    if(!this.empty){
      if (!!this.items && !!this.selectedItemId) {
        if(this.selectedItemId.length === 1) {
          if(this.selectedItemId[0].isTeam) {
            return this.selectedItemId[0].firstName;
          }
          return this.selectedItemId[0].fullNameAndInitialt;
        }
        return this.selectedItemId.length + ' selected';
      }
      return '-';
    }
    return '';
  }

  getStakeholderIconType(stakeholder: CaseStakeholder) {
    let type = stakeholder.stakeholderType;
    if (stakeholder.hasRole(StakeholderTypeEnum.endUser)) {
      type = StakeholderTypeEnum.endUser;
    } else if (stakeholder.hasRole(StakeholderTypeEnum.policyHolder)) {
      type = StakeholderTypeEnum.policyHolder;
    }
    return  '/assets/icons/' + this.stakeholderService.addIconToCaseStakeholder(type) + '.svg';
  }
}
