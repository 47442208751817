<ng-template #content let-modal>
  <div class="return-invoice-container">
    <div class="return-invoice-container-header">
      {{'manage-supplier-invoice-return' | translate}}
    </div>
    <div class="input-title-name">{{'manage-supplier-invoice-return-comments' | translate}}</div>
    <div class="return-invoice-container-comment">
      <textarea class="return-invoice-container-comment-input"
                maxlength="255"
                [(ngModel)]="returnRemark">
      </textarea>
    </div>
    <div class="return-invoice-container-footer">
      <button class="return-invoice-container-footer-button-return" (click)="returnToAccounting()"
              [class.return-invoice-container-footer-button-return-disabled]="!returnRemark"
              [disabled]="!returnRemark">{{'manage-supplier-invoice-return' | translate}}</button>
      <button class="return-invoice-container-footer-button"
              (click)="close()">{{'message-dialog-close-and-discard' | translate}}</button>
    </div>
  </div>
</ng-template>
