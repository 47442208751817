import { Component, OnChanges } from '@angular/core';
import { OWL_DATE_TIME_FORMATS } from '@danielmoncada/angular-datetime-picker';
import { PickerBaseComponent } from '../input-date-time/picker-base.component';
import { DAY_FORMATS } from '../input-date-time/date-formats';


@Component({
  selector: 'app-input-calendar',
  templateUrl: './input-calendar.component.html',
  styleUrls: ['./input-calendar.component.scss'],
  providers: [
    { provide: OWL_DATE_TIME_FORMATS, useValue: DAY_FORMATS },
  ],
})
export class InputCalendarComponent extends PickerBaseComponent implements OnChanges {

  constructor() {
    super();
    this.formats = DAY_FORMATS;
  }
}
