import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MedifStatusTypeEnum } from '@secca/case/components/case-plans/case-plans/add-service-order/models/enums';

@Component({
  selector: 'app-sub-medif-status',
  templateUrl: './sub-medif-status.component.html',
  styleUrls: ['./sub-medif-status.component.scss'],
})
export class SubMedifStatusComponent implements OnInit {
  @Input() medifStatusType: MedifStatusTypeEnum;
  @Output() medifStatusTypeChanged = new EventEmitter<MedifStatusTypeEnum>();

  get MedifStatusTypeEnum() {
    return MedifStatusTypeEnum;
  }

  constructor() {}

  ngOnInit() {}

  setMedifStatus(status: MedifStatusTypeEnum) {
    this.medifStatusType = status;
    if (this.medifStatusTypeChanged != null) {
      this.medifStatusTypeChanged.emit(this.medifStatusType);
    }
  }
}
