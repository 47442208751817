
<div class="modal-dialog-wrapper" appDialogSelectable appDialogKeyboardEnabled [enabledKeys]="enabledKeys">
    <mat-dialog-content (cdkDragStarted)="dialogBoundaryService.handleDragStart($event)" (cdkDragEnded)="dialogBoundaryService.handleDragEnd($event)" cdkDragRootElement=".cdk-overlay-pane" cdkDrag>
        <div appDialogDragArea cdkDragHandle></div>
        <app-task-message-modal-move-task [showServiceOrder]="data.showServiceOrder" 
                                          [isCaseNumberValid]="data.isCaseNumberValid"
                                          [taskCaseId]="data.taskCaseId"
                                          (caseNumberEnteredEvent)="!!data ? data.caseNumberEnteredEvent($event) : null"                                          
                                          (moveConfirmedEvent)="!!data ? data.moveConfirmedEvent($event) : null"
                                          (closed)="close()">
        </app-task-message-modal-move-task>
    </mat-dialog-content>
</div>