import { Injectable } from "@angular/core";
import { BaseService } from "@secca/core/services/base.service";
import { String } from "lodash";

@Injectable({
  providedIn: 'root',
})
export class BoardNavigationStateService {
  state = {}

  saveState(key: string, value: string) {
    this.state[key] = value
  }

  removeState(key: string) {
    this.state[key] = null;
  }

  getState(key) : string {
    return this.state[key]
  }

  public clearAll() {
    this.clearTodoTask();
    this.clearDoneTask();
    this.clearPreDeparture();
    this.clearSupplierInvoice();
    this.clearRefund();
    this.removeCases();
    this.removeIncidents();
    this.removeMedicalCases();
  }


  removeMedicalCases() {
    this.removeState('board-medical-case-filter-request-cases-selected-row-index');
    this.removeState('board-medical-case-filter-request-cases-pagination-index');
    this.removeState('board-medical-case-filter-request-scroll-position');
  }

  removeIncidents() {
    this.removeState('board-incident-event-case-filter-request-incident-event-cases-selected-row-index');
    this.removeState('board-incident-event-case-pagination-index');
    this.removeState('board-incident-event-case-filter-request-scroll-position');
  }

  public removeCases() {
    this.removeState('board-case-filter-request-cases-selected-row-index');
    this.removeState('board-task-cases-pagination-index');
    this.removeState('board-case-filter-request-cases-scroll-position');

  }

  public clearRefund() {
    this.removeState('board-case-filter-request-refund-selected-row-index');
    this.removeState('board-task-refund-pagination-index');
    this.removeState('board-case-filter-request-refund-task-scroll-position');
  }

  public clearSupplierInvoice() {
    this.removeState('board-case-filter-request-supplier-invoice-selected-row-index');
    this.removeState('board-task-supplier-invoice-pagination-index');
    this.removeState('board-case-filter-request-supplier-invoice-task-scroll-position');
  }

  public clearRecoveryPayment() {
    this.removeState('board-case-filter-request-recovery-payment-selected-row-index');
    this.removeState('board-task-recovery-payment-pagination-index');
    this.removeState('board-case-filter-request-recovery-payment-task-scroll-position');
  }


  public clearPreDeparture() {
    this.removeState('board-case-filter-request-pre-departure-selected-row-index');
    this.removeState('board-task-pre-departure-pagination-index');
    this.removeState('board-case-filter-request-pre-departure-task-scroll-position');
  }

  public clearRecovery() {
    this.removeState('board-case-filter-request-recovery-selected-row-index');
    this.removeState('board-task-recovery-pagination-index');
    this.removeState('board-case-filter-request-recovery-task-scroll-position');
  }

  public clearDoneTask() {
    this.removeState('board-case-filter-request-done-task-selected-row-index');
    this.removeState('board-task-done-pagination-index');
    this.removeState('board-case-filter-request-done-task-scroll-position');
  }

  public clearTodoTask() {
    this.removeState('board-case-filter-request-to-do-task-selected-row-index');
    this.removeState('board-task-to-do-pagination-index');
    this.removeState('board-case-filter-request-to-do-task-scroll-position');
  }

  public clearInbox() {
    this.removeState('board-case-filter-request-inbox-selected-row-index');
    this.removeState('board-task-inbox-pagination-index');
    this.removeState('board-case-filter-request-inbox-task-scroll-position');
  }

  public clearWebTasks() {
    this.removeState('board-case-filter-request-web-task-selected-row-index');
    this.removeState('board-task-web-task-pagination-index');
    this.removeState('board-case-filter-request-web-task-scroll-position');
  }


  removeTransport() {
    this.removeState('board-transport-case-filter-request-cases-selected-row-index');
    this.removeState('board-transport-case-filter-request-cases-pagination-index');
    this.removeState('board-transport-case-filter-request-scroll-position');
  }

}
