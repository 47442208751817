<div *ngIf="case" class="case-basics-info-container">
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
    <ng-container *ngIf="case.caseTypeCode !== caseTypeCodes.INTERNAL_COORDINATION && case.caseTypeCode !== caseTypeCodes.MAJOR_INCIDENT">
      <li [ngbNavItem]="1">
        <a ngbNavLink>{{'case-basics-end-user' | translate }}  </a>
        <ng-template ngbNavContent>
          <app-case-basics-info-user
            [type]="'END_USER'"
            [newCase]='newCase'
            [caseId]="case.id"
            [personInsurance]="personInsurance"
            >
          </app-case-basics-info-user>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>{{'case-basics-policy-holder' | translate }}</a>
        <ng-template ngbNavContent>
          <app-case-basics-info-user  [type]="'POLICYHOLDER'" [newCase]='newCase' [caseId]="case.id" [personInsurance]="personInsurance">
          </app-case-basics-info-user>
        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <a ngbNavLink>{{'case-basics-reporter' | translate }}</a>
        <ng-template ngbNavContent>
          <app-case-basics-info-user [type]="'REPORTER'" [newCase]='newCase' [caseId]="case.id" [personInsurance]="personInsurance">
          </app-case-basics-info-user>
        </ng-template>
      </li>

      <li [ngbNavItem]="4">
        <a ngbNavLink>{{'case-basics-linked-cases' | translate }}<div class="tasks-number" *ngIf="numberOfLinkedCases !== 0"> {{ numberOfLinkedCases }} </div></a>
        <ng-template ngbNavContent>
          <app-linked [caseId]="case.id" [caseNumber]="case.caseNumber">
          </app-linked>
        </ng-template>
      </li>
    </ng-container>

    <ng-container *ngIf="isCoordinationCase(case.caseTypeCode)">
      <li [ngbNavItem]="5">
        <a ngbNavLink><span *ngIf="case.caseTypeCode === caseTypeCodes.INTERNAL_COORDINATION">{{'case-basics-coordination-cases' | translate }}</span><span *ngIf="case.caseTypeCode === caseTypeCodes.MAJOR_INCIDENT">{{'case-basics-major-incident-cases' | translate }}</span></a>
        <ng-template ngbNavContent>
          <app-coordination-case [case]="case" [caseTypeCode]="case.caseTypeCode"></app-coordination-case>
        </ng-template>
      </li>
      <li [ngbNavItem]="6" *ngIf="case.caseTypeCode !== caseTypeCodes.MAJOR_INCIDENT">
        <a ngbNavLink>{{'case-basics-linked-coordination-cases' | translate }}<div class="tasks-number" *ngIf="numberOfCoordinationCases !== 0"> {{ numberOfCoordinationCases }} </div></a>
        <ng-template ngbNavContent>
          <app-linked-coordination-cases [caseId]="case.id" [caseNumber]="case.caseNumber" ></app-linked-coordination-cases>
        </ng-template>
      </li>
    </ng-container>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</div>
