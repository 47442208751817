<div class="input">
  <span [ngClass]="isValid ? 'input-name' : 'invalid-input-name'" *ngIf="name && name != ''">{{ name }}</span>
  <span *ngIf="required" class="required-red">*</span>
  <div *ngIf="startingPlaceholder" class="input-starting-placeholder">{{ startingPlaceholder }}</div>
  <div [ngClass]="isValid ? 'd-flex input-box' : 'd-flex invalid-input-box'">
    <div [ngClass]="withRange ? 'input-ranged' : 'input-non-ranged'">
      <img
        *ngIf="withImage"
        src="../../../../assets/icons/Calendar.svg"
        class="input-image-calender"
        [owlDateTimeTrigger]="dt1Trigger"
        (click)="setOpenedFromCalendarIcon()"
      />
      <input
        [owlDateTime]="dt1Trigger"
        [owlDateTimeTrigger]="dt1Trigger"
        (saveModel)="onSaveModel()"
        (blur)="onChange(); onSaveModel()"
        title="{{ this.isValidMessage }}"
        spellcheck="false"
        (focus)="onSaveState()"
        [(ngModel)]="dateTimeRange"
        placeholder="{{ placeholder }}"
        [selectMode]="selectMode"
        (input)="onInputCheckEmpty($event)"
        (keydown.backspace)="onInputCheckEmpty($event)"
        [ngClass]="isValid ? 'input-date' : 'invalid-input-date'"
        [disabled]="!!disabled"
        [min]="minDate"
        [max]="maxDate"
        [class.input-date-required]="requiredRedBackground"
        #dt1
      />
      <owl-date-time
        [disabled]="pickerDisabled"
        [pickerType]="'calendar'"
        #dt1Trigger
        (afterPickerOpen)="afterPickerOpen()"
        [startAt]="startAt"
        (afterPickerClosed)="onChange()"
      ></owl-date-time>
    </div>
    <div *ngIf="withRange" class="input-separator" [class.input-separator-required]="requiredRedBackground">-</div>
    <div *ngIf="withRange" class="input-ranged">
      <img
        *ngIf="withImage"
        src="../../../../assets/icons/Calendar.svg"
        class="input-image-calender"
        [owlDateTimeTrigger]="dt2Trigger"
        (click)="setOpenedFromCalendarIcon()"
      />
      <input
        [owlDateTime]="dt2Trigger"
        [owlDateTimeTrigger]="dt2Trigger"
        (saveModel)="onSaveModel()"
        (blur)="onChange(); onSaveModel()"
        title="{{ this.isValidMessage }}"
        spellcheck="false"
        (focus)="onSaveState()"
        [(ngModel)]="dateTimeRange"
        placeholder="{{ placeholder }}"
        [selectMode]="selectModeTo"
        (input)="onInputCheckEmpty($event)"
        (keydown.backspace)="onInputCheckEmpty($event)"
        [ngClass]="isValid ? 'input-date' : 'invalid-input-date'"
        [disabled]="!!disabled"
        [min]="minDate"
        [max]="maxDate"
        [class.input-date-required]="requiredRedBackground"
        #dt2
      />
      <owl-date-time
        [pickerType]="'calendar'"
        #dt2Trigger
        (afterPickerOpen)="afterPickerOpen()"
        [startAt]="startAt"
        (afterPickerClosed)="onChange()"
      ></owl-date-time>
    </div>
  </div>
</div>
