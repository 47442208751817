import { Injectable } from "@angular/core";
import { Adapter } from "../interfaces/adapter";

export class CommitmentType  {
    name: string;
    businessKey: string;
    gop: boolean;
    sosClaims: boolean;
    hoverText: string;
    refund: boolean;
    supplierInvoice: boolean;
    uiSortIndex: number;


    public constructor(init?: Partial<CommitmentType>) {
        Object.assign(this, init);
    }    
}

@Injectable({
    providedIn: 'root'
  })
  export class CommitmentTypeAdapter implements Adapter<CommitmentType> {
    adapt(item: any): CommitmentType {
      if (item) {
        return new CommitmentType({
          name: item.name,
          businessKey: item.businessKey,
          gop: item.gop,
          sosClaims: item.sosClaims,
          hoverText:item.hoverText,
          refund: !!item.refund,
          supplierInvoice: !!item.supplierInvoice,
          uiSortIndex: item.uiSortIndex ? item.uiSortIndex : 100000
        });
      } else {
        return null;
      }
    }
  }