<div class="modal-content">
    <div class="modal-header">
      <div class="modal-header-title">
        {{ 'recent-cases-recently-viewed-cases' | translate }}
      </div>
      <div class="modal-buttons">
        <div (click)="close()">
          <img src="/assets/icons/Close White.svg" />
        </div>
      </div>
    </div>
    <div class="modal-content-body">
        <div class="case-list">
            <div class="d-flex top">
                <div class="items cu">{{ 'recent-cases-cu' | translate }} </div>
                <div class="items case-number">{{ 'recent-cases-case-number' | translate }}</div>
                <div class="items end-user">{{ 'recent-cases-end-user' | translate }}</div>
                <div class="items incidentDate">{{ 'recent-cases-incident-date' | translate }}</div>
                <div class="items country">{{ 'recent-cases-incident-country' | translate }}</div>
                <div class="items cause"> {{ 'recent-cases-cause' | translate }}  </div>
                <div class="items date">{{ 'recent-cases-viewed' | translate }}</div>
            </div>
            <ng-container *ngFor="let recentCase of recentCases">
                <div class="d-flex lines" (click)="redirectToCase(recentCase.caseId)">
                <div class="items cu">
                    <img class="customer-image" [src]="recentCase.logo" *ngIf="recentCase.logo; else noProfileIcon">
                </div>
                <div class="items case-number">{{ recentCase.caseNumber }}</div>
                <div class="items end-user">{{ recentCase.user }}</div>
                <div class="items incidentDate">{{ recentCase.incidentDate }}</div>
                <div class="items country">{{ recentCase.country }}</div>
                <div class="items cause">{{ recentCase.cause }}</div>
                <div class="items date">{{ getExitDate(recentCase.exitTime) }}</div>
                </div>
            </ng-container>
            <ng-container *ngIf="recentCases.length == 0">
                <div class="items no-cases">&nbsp;</div>
            </ng-container>
          </div>
    </div>
</div>
