import { AssigneeEnum, StatusTypes } from '@secca/shared/models/enums';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MedicalAssessmentServiceOrderExtension } from '@secca/shared/models/service-order/medical-assessment-service-order-extension';
import { ServiceTypeBase } from '@secca/case/components/case-plans/case-plans/add-service-order/models/serviceTypeBase';
import { MedicalService } from '@secca/core/services/medical.service';
import { ServiceOrder } from '@secca/shared/models/service-order/service-order';

@Component({
  selector: 'app-medical-assessment-left-side',
  templateUrl: './medical-assessment-type-left-side.component.html',
  styleUrls: ['./medical-assessment-type-left-side.component.scss']
})
export class MedicalAssessmentTypeLeftSideComponent  extends ServiceTypeBase implements OnInit, OnChanges {
  disableEditingDescription = false;
  @Input()
  set medicalAssessment(medicalAssessment: MedicalAssessmentServiceOrderExtension) {
    this._medicalAssessment = medicalAssessment;
  }

  get medicalAssessment() {
    return this._medicalAssessment;
  }

  _medicalAssessment: MedicalAssessmentServiceOrderExtension;

  @Input() caseId: number;
  @Input() serviceOrder: ServiceOrder;
  @Input() assignee: AssigneeEnum;

  isCancelAllowed = false;

  constructor(private medicalService: MedicalService) {

    super();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.serviceOrder.status !== StatusTypes.CANCELLED) {
      if (this.medicalAssessment.assignee === AssigneeEnum.MEDICAL_ADVICE) {
        this.serviceOrder.status = StatusTypes.EXPECTED;
      }
      else if(this.medicalAssessment.assignee === AssigneeEnum.MEDICAL_STAFF || this.medicalAssessment.assignee === AssigneeEnum.PRE_DEPARTURE_MEDICAL_ADVICE) {
        this.serviceOrder.status = StatusTypes.COMMITTED;
      }
    }
    this.isValid();
  }

  ngOnInit() {
    this.disableEditingDescription = !!this.medicalAssessment.id;
    this.medicalService.getNewestMedicalAssessment(this.caseId).subscribe((medicalAssessment) => {
      if (!medicalAssessment.id) {
        this.isCancelAllowed = true;
      }
    });
  }

  isValid() {
    if (this.isDescriptionValid()) {
      this.serviceOrderTypeState.isLeftSideValid = true;
    }
  }

  isDescriptionValid(): boolean {
    return this.medicalAssessment.validateDescription();
  }

  changeStatus(val: StatusTypes, event: any = null) {
    if (val === this.serviceOrder.status && event != null) {
      return false;
    }
    if (val !== this.serviceOrder.status) {
      this.serviceOrder.status = val;
    }
  }

  expectedDisabled() {
    return true;
  }

  cancelledDisabled() {
    return !(this.isCancelAllowed && (this.serviceOrder.status === StatusTypes.COMMITTED));
  }

  committedDisabled() {
    return this.serviceOrder.status !== StatusTypes.CANCELLED;
  }

  medicalChange() {
    this.isValid();
  }

  get AssigneeEnum(){
    return AssigneeEnum;
  }

  get StatusTypes() {
    return StatusTypes;
  }

}
