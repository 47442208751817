<div [appDisableInput]="caseLockHelperService.isCaseBasicLockTaken || permissionService.isReadOnly" class="caselock-no-events">
  <div class="case-handlers">
    <app-case-handler-status-box
      [handlingArea]="caseHandlerHandlingArea"
      [teamName]="caseTeamName()"
      class="status-box first"
      ngbPopover="{{ mouseOverTeam }}"
      [autoClose]="false"
      triggers="hover"
      #p="ngbPopover"
      openDelay="400"
      [statusName]="caseHandlerStatus"
      placement="top-right"
      appPreventDoubleClick (throttledClick)="openModal(caseHandlerName)"
      >
    </app-case-handler-status-box>
    <div *permission="PermissionEnum.AC_STATUS_UPDATE; hideType: PermissionHideTypeEnum.DISABLE">
      <app-case-handler-status-box
        [handlingArea]="medicalHandlingArea"
        [teamName]="'handler-type-medical' | translate"
        class="status-box"
        ngbPopover="{{ mouseOverMedical }}"
        [autoClose]="false"
        triggers="hover"
        #p="ngbPopover"
        openDelay="400"
        [statusName]="medicalStatus"
        placement="top-right"
        appPreventDoubleClick (throttledClick)="openModal(medicalName)"
      >
      </app-case-handler-status-box>
    </div>
    <app-case-handler-status-box
      [handlingArea]="transportHandlingArea"
      [teamName]="'handler-type-transport' | translate"
      class="status-box"
      ngbPopover="{{ mouseOverTransport }}"
      [autoClose]="false"
      triggers="hover"
      #p="ngbPopover"
      openDelay="400"
      [statusName]="transportStatus"
      placement="top-right"
      appPreventDoubleClick (throttledClick)="openModal(transportName)"
      >
    </app-case-handler-status-box>

    <div [appDisableInput]="permissionService.isReadOnly" class="caselock-no-events">
      <app-case-handler-status-box
        [handlingArea]="costControlHandlingArea"
        [teamName]="'handler-type-cost-control' | translate"
        class="status-box"
        ngbPopover="{{ mouseOverCostControl }}"
        [autoClose]="false"
        triggers="hover"
        #p="ngbPopover"
        openDelay="400"
        [statusName]="costControlStatus"
        placement="top-right"
        appPreventDoubleClick (throttledClick)="openModal(costControlName)"
      >
      </app-case-handler-status-box>
    </div>
    <div [appDisableInput]="caseLockHelperService.isCaseBasicLockTaken || permissionService.isReadOnly" class="caselock-no-events">
      <app-case-handler-status-box
        [handlingArea]="claimsHandlingArea"
        [teamName]="claimsHandlerTeamName()"
        class="status-box"
        ngbPopover="{{ mouseOverClaims }}"
        [autoClose]="false"
        triggers="hover"
        #p="ngbPopover"
        openDelay="400"
        [statusName]="claimsStatus"
        placement="top-right"
        appPreventDoubleClick (throttledClick)="openModal(claimsName)"
      >
      </app-case-handler-status-box>
    </div>
    <div [appDisableInput]="caseLockHelperService.isCaseBasicLockTaken || permissionService.isReadOnly" class="caselock-no-events">
      <app-case-handler-status-box
        [handlingArea]="recoveryHandlingArea"
        [teamName]="'handler-type-recovery' | translate"
        class="status-box last"
        ngbPopover="{{ mouseOverRecovery }}"
        [autoClose]="false"
        triggers="hover"
        #p="ngbPopover"
        openDelay="400"
        [statusName]="recoveryStatus"
        placement="top-right"
        (click)="openModal(recoveryName)"
      >
      </app-case-handler-status-box>
    </div>
  </div>
</div>
<app-case-handler-modal #modal (changeHandlingArea)="handlingAreasChange($event)"></app-case-handler-modal>
