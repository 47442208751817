import { ServiceOrderTypeState } from '@secca/case/components/case-plans/case-plans/add-service-order/models/serviceOrderTypeState';
import { OutputManagementService } from '@secca/case/components/case-output-management/services/output-management.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-send-itinerary',
  templateUrl: './send-itinerary.component.html',
  styleUrls: ['./send-itinerary.component.scss'],
})
export class SendItineraryComponent implements OnInit {
  @Input() caseId: any;
  @Input() serviceOrderTypeState: ServiceOrderTypeState;
  @Input() regularFlightMedicalSaveButtonDisabled: boolean;

  constructor(private outputManagementService: OutputManagementService) {}

  ngOnInit() {
  }

  openMessageModal() {
    this.serviceOrderTypeState.sendConfirmationEvent.emit();
  }

  get itineraryButtonDisabled(): boolean {
    return this.regularFlightMedicalSaveButtonDisabled;
  }
}
