import { EventEmitter } from '@angular/core';
import { SelectedTab } from './enums';

export class ServiceOrderTypeState {
  isConfirmationTabSaved: boolean;
  isLeftSideValid: boolean;
  showConfirmationTab: boolean;
  isSupplierChosen: boolean;
  isSupplierRequired: boolean;
  showDetailsTab: boolean;
  isLockedByCoordinationTransport: boolean;
  lockedByCoordinationTransportCaseNumber: string;
  sendingGopInProgress: boolean;
  endUserHasDateOfBirth: boolean = true;
  selectedTab: SelectedTab = SelectedTab.ExpectationTab;

  sendRequestRequested: boolean = false;
  requestSent: boolean = false;

  statusChangeEvent = new EventEmitter();
  previewGopEvent = new EventEmitter();
  sendGopEvent = new EventEmitter();
  sendConfirmationEvent = new EventEmitter();
  confirmationTabHasBeenClosed = new EventEmitter();
  medicalPreAssessmentGopEvent = new EventEmitter();
  gopHasBeenSent: boolean; // TODO set when gop has been sent
  gopHasBeenPreviewed: boolean;
  gopPreviewWasClicked: boolean = false;
  medicalPreAssessGopPreviewWasClicked: boolean = false;
  agentMissing: boolean = false;
  stakeholderEmailRequiredAndMissing: boolean = false;
  stakeholderEmailInvalid: boolean = false;
  supplierGopEmailRequiredAndMissing: boolean = false;
  supplierGopFaxRequiredAndMissing: boolean = false;
  agentGopEmailRequiredAndMissing: boolean = false;
  agentGopFaxRequiredAndMissing: boolean = false;
  supplierGopMethodIsMissing: boolean = false;
  supplierRequiredAndMissing: boolean = false;
  supplierGopEmailInvalid: boolean = false;
  agentGopEmailInvalid: boolean = false;

  get canConfirmationTabBeClosed(): boolean {
    return !this.gopHasBeenSent && !this.isConfirmationTabSaved;
  }
}
