import { Injectable } from '@angular/core';
import { BaseService } from '@secca/core/services/base.service';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '@secca/core/services/settings.service';
import { PlannedTravel, PlannedTravelAdapter } from '@secca/shared/models/planned-travel';
import { DaySerializer } from '@secca/shared/models/day-serializer';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { QueueService } from '@secca/core/services/queue.service';
import { UpdatePlannedTravelQueueService } from '@secca/core/services/update-planned-travel-queue.service';

@Injectable({
  providedIn: 'root'
})
export class PlannedTravelService extends BaseService {
  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private queueService: QueueService,
    private plannedTravelAdapter: PlannedTravelAdapter,
    private updatePlannedTravelQueueService: UpdatePlannedTravelQueueService,
  ) {
    super(settingsService);
  }

  private plannedTravelSubject: ReplaySubject<PlannedTravel>;
  private caseId: string;

  public updatePlannedTravelWithQueueService(plannedTravel: PlannedTravel) {
    if ( this.isMainTravelPlan(plannedTravel) ) {
      this.initSubject(plannedTravel.seccaCaseId);
      this.plannedTravelSubject.next(plannedTravel);
    }
    const plannedTravelForServiceCall = this.adaptPlannedTravelDatesForServiceCall(this.plannedTravelAdapter.adapt(plannedTravel));
    this.queueService.pushPlannedTravel(plannedTravelForServiceCall);
    return this.updatePlannedTravelQueueService.actionSchedule();
  }

  public getAllPlannedTravels(caseId: string): Observable<PlannedTravel[]> {
    return this.http
      .get(this.baseURL + `travelplans/cases/${caseId}`)
      .pipe(map((data: any[]) => data.map(item => this.plannedTravelAdapter.adapt(item))));
  }

  public getPlannedTravel(caseId: string): Observable<PlannedTravel> {
    return this.http
      .get(this.baseURL + `travelplans/cases/${caseId}/main-plan`)
      .pipe(map(item => this.plannedTravelAdapter.adapt(item)));
  }

  public getPlannedTravelSubPlans(caseId: string): Observable<PlannedTravel[]> {
    return this.http
      .get(this.baseURL + `travelplans/cases/${caseId}/sub-plans`)
      .pipe(map((data: any[]) => data.map(item => this.plannedTravelAdapter.adapt(item))));
  }

  public addPlannedTravel(plannedTravel: PlannedTravel): Observable<PlannedTravel> {
    return this.http
      .post(this.baseURL + `travelplans`, plannedTravel)
      .pipe(map(item => this.plannedTravelAdapter.adapt(item)));
  }

  public deletePlannedTravel(plannedTravelId: number): Observable<PlannedTravel> {
    return this.http
      .delete(this.baseURL + `travelplans/${plannedTravelId}`)
      .pipe(map(item => this.plannedTravelAdapter.adapt(item)));
  }

  public plannedTravelChanges(caseId: string): Observable<PlannedTravel> {
    this.initSubject(caseId);
    return this.plannedTravelSubject;
  }

  isMainTravelPlan(plannedTravel: PlannedTravel): boolean {
    return !plannedTravel.parentId;
  }

  private initSubject(caseId: string) {
    if (this.caseId !== caseId) {
      this.caseId = caseId;
      this.plannedTravelSubject = new ReplaySubject<PlannedTravel>(1);
    }
  }

  adaptPlannedTravelDatesForServiceCall(plannedTravel: PlannedTravel) {
    const plannedTravelRequest = JSON.parse(JSON.stringify(plannedTravel));
    const dates: string[] = [];
    plannedTravel.departureAndArrival.forEach(d => dates.push(DaySerializer.serialize(d)));
    plannedTravelRequest.departureAndArrival = dates;
    const expatriationDates: string[] = [];
    plannedTravel.expatriationDepartureAndArrival.forEach(d => expatriationDates.push(DaySerializer.serialize(d)));
    plannedTravelRequest.expatriationDepartureAndArrival = expatriationDates;
    return plannedTravelRequest;
  }
}
