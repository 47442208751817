import { StakeholderTypeEnum, MessageChannelType, PhoneNumberType  } from './../../../../../../../../shared/models/enums';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { StakeholderService } from 'src/app/core/services/stakeholder.service';
import { StakeholderType } from '@secca/case/components/case-stakeholders/stakeholderType';
import { DictionaryService } from '@secca/core/services/dictionary.service';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import * as _ from 'lodash-es';
@Component({
  selector: 'app-message-form-cc',
  templateUrl: './message-form-cc.component.html',
  styleUrls: ['./message-form-cc.component.scss']
})
@AutoUnsubscribe
export class MessageFormCcComponent implements OnInit, OnChanges {
  readonly MAX_LEN = 145;

  @Input() messageChannelType: MessageChannelType;
  @Input() selectedItems: CaseStakeholder[];
  @Input() showFullName: boolean;
  @Input() stakeholders: CaseStakeholder[];

  @Output() selectedItemsChange = new EventEmitter();

  maxNumberOfCc = 20;
  stakeholdersWithContacts: CaseStakeholder[];
  stakeholderTypes: StakeholderType[];
  $stakeholdersTypesSubscr: Subscription;
  $outputManagementSelectedMessageSubscr: Subscription;

  constructor(private stakeholderService: StakeholderService,
              private dictionaryService: DictionaryService) {}

  ngOnInit() {
    this.$stakeholdersTypesSubscr = this.dictionaryService.getStakeholdersTypes().subscribe(
      stakeholderTypes => {
        this.stakeholderTypes = stakeholderTypes;
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['messageChannelType'] || changes['stakeholders']) {
      this.initialiseStakeholders();
    }
  }

  onSelectedItemsChange() {
    this.selectedItemsChange.emit(this.selectedItems);
  }

  getDocumentName(item) {
    if ( item && item.documentName.length > this.MAX_LEN ) {
      return item.documentName.substring(0, this.MAX_LEN) + '...';
    }

    return item.documentName;
  }

  getStakeholderIconUrl(stakeholderType: string) {
    if (this.stakeholderTypes == null) {
      return '';
    }
    const supplier = this.stakeholderTypes.find(type => type.stakeholderTypeEnum === stakeholderType);
    if (supplier != null) {
      return '/assets/icons/' + this.stakeholderService.addIconToCaseStakeholder(supplier.stakeholderTypeEnum) + '.svg';
    }
    if (stakeholderType === StakeholderTypeEnum.gop) {
      return '/assets/icons/GOP Contact Person.svg';
    }
    return '';
  }

  private initialiseStakeholders(): void {
    this.createStakeholdersWithContacts(this.stakeholders);
    this.addFullNameForSearchPurposes();
  }

  private createStakeholdersWithContacts(stakeholders: CaseStakeholder[]): void {
    this.stakeholdersWithContacts = _.cloneDeep(stakeholders);
    stakeholders
      .filter(stakeholder => stakeholder.isCompany)
      .forEach(company => {
        this.addGopContactAsStakeholder(company);
        this.addContactsAsStakeholders(company);
      });

    this.stakeholdersWithContacts = this.stakeholdersWithContacts.filter(item => this.hasAddress(item));
    this.sortStakeholdersWithContacts();
  }

  private addGopContactAsStakeholder(stakeholder: CaseStakeholder) {
    const gopStakeholder = _.cloneDeep(stakeholder);
    if (stakeholder.company.gop) {
      gopStakeholder.phoneNumbers = [];
      gopStakeholder.company.email = stakeholder.company.gop.email ? stakeholder.company.gop.email : '';
      const gopFaxNumber = stakeholder.company.gop.phoneNumbers.find(a => a.type === PhoneNumberType.Fax);
      if (gopFaxNumber != null) {
        gopStakeholder.phoneNumbers.push(gopFaxNumber);
      }
      const gopMobileNumber = stakeholder.company.gop.phoneNumbers.find(a => a.type === PhoneNumberType.Mobile);
      if (gopMobileNumber != null) {
        gopStakeholder.phoneNumbers.push(gopMobileNumber);
      }
      gopStakeholder.company.contactPersons = [];
      gopStakeholder.company.name = stakeholder.company.name + ' (GOP: ' + stakeholder.company.contactPerson + ')';
      gopStakeholder.stakeholderType = StakeholderTypeEnum.gop;
      this.stakeholdersWithContacts.push(gopStakeholder);
    }
  }

  private addContactsAsStakeholders(stakeholder: CaseStakeholder) {
    stakeholder.company.contactPersons.forEach(contact => {
      const newStakeholder = _.cloneDeep(stakeholder);
      newStakeholder.company.name = stakeholder.company.name + ' (' + contact.firstName + ' ' + contact.surname + ')';
      newStakeholder.company.contactPersons = [];
      newStakeholder.company.email = contact.email;
      this.stakeholdersWithContacts.push(newStakeholder);
    });
  }

  private sortStakeholdersWithContacts() {
    this.stakeholdersWithContacts.sort((a, b) => {
      const aName = a.isCompany ? a.company.name : a.person.firstName + ' ' + a.person.surname;
      const bName = b.isCompany ? b.company.name : b.person.firstName + ' ' + b.person.surname;
      const isTheSameType = (a.isCompany && b.isCompany) || (!a.isCompany && !b.isCompany);
      if (isTheSameType && aName > bName) {
        return 1;
      }
      if (isTheSameType && aName < bName) {
        return -1;
      }
      return 0;
    });
  }

    /* Only allow stakeholders which have mail/fax/phone address*/
    private hasAddress(stakeholder: CaseStakeholder): boolean {
      const thisMail = stakeholder.isCompany ? stakeholder.company.email : stakeholder.person.email;

      let address = '';
      if (this.messageChannelType === MessageChannelType.EMAIL && thisMail) {
        address = thisMail;
      } else if (this.messageChannelType === MessageChannelType.FAX && this.stakeholderService.getPhoneNumber(PhoneNumberType.Fax, stakeholder.phoneNumbers).number) {
        address = this.stakeholderService.getPhoneNumber(PhoneNumberType.Fax, stakeholder.phoneNumbers).prefix + this.stakeholderService.getPhoneNumber(PhoneNumberType.Fax, stakeholder.phoneNumbers).number;
      } else if (this.messageChannelType === MessageChannelType.SMS &&
                 this.stakeholderService.getPhoneNumber(PhoneNumberType.Mobile, stakeholder.phoneNumbers).number) {
        address = this.stakeholderService.getPhoneNumber(PhoneNumberType.Mobile, stakeholder.phoneNumbers).prefix +
                   this.stakeholderService.getPhoneNumber(PhoneNumberType.Mobile, stakeholder.phoneNumbers).number;
      }

      return !!address;
    }

  private addFullNameForSearchPurposes(): void {
    this.stakeholdersWithContacts.forEach((s: any) => {
      s.fullName = s.isCompany ? s.company.name : s.person.firstName + s.person.surname;
    });
  }
}
