import { Component, EventEmitter, OnInit, Output, Input, OnChanges, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ServiceOrderService } from '@secca/core/services/service-order.service';
import { SupplierInvoiceService } from '@secca/core/services/supplier-invoice.service';
import { DropdownWithIconDisableDictionary } from '@secca/shared/models/dropdownWithIconDisableDictionary';
import { TableSort } from '@secca/shared/models/table-sort';
import { RecoveryStatus, RefundStatus, ServiceTypeEnum, ServiceTypeEnumIcon, ShortcutEnum, SortOrder } from '@secca/shared/models/enums';
import { RefundTaskViewModel } from '../models/refund-task-view.model';
import { DataService } from '@secca/core/services/data.service';
import { Router } from '@angular/router';
import { CaseService } from '@secca/core/services/case.service';
import { AbstractGlobalBoardTableShortcutsComponent } from '@secca/shared/components/abstract-global-board-table-shortcuts.component';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { SettingsService } from '@secca/core/services/settings.service';
import { BoardNavigationStateService } from '../services/board-navigation-state.service';
import { RecoveryTaskViewModel } from '../models/recovery-task-view.model';
import { EconomyStatusBarValues } from '@secca/shared/models/economyStatusBarValues';
import { Recovery } from '@secca/shared/models/recovery';
import * as moment from 'moment';

@Component({
  selector: 'app-board-task-recovery-table',
  templateUrl: './board-task-recovery-table.component.html',
  styleUrls: ['./board-task-recovery-table.component.scss'],
})
@AutoUnsubscribe
export class BoardTaskRecoveryTableComponent extends AbstractGlobalBoardTableShortcutsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() set taskViewModels(data: RecoveryTaskViewModel[]) {
    this._taskViewModels = data;
    this.numberOfRows = data?.length;
    setTimeout(() => {
      this.selectedRowIndex = this.boardNavigationStateService.getState('board-case-filter-request-recovery-selected-row-index') ? parseInt(this.boardNavigationStateService.getState('board-case-filter-request-recovery-selected-row-index'), 10) : 0;
      this.inputScrollPosition = this.boardNavigationStateService.getState('board-case-filter-request-recovery-task-scroll-position') ? parseInt(this.boardNavigationStateService.getState('board-case-filter-request-recovery-task-scroll-position'), 10) : 0;
      this.navigateToTable();
    }, 50);
  }
  get taskViewModels(): RecoveryTaskViewModel[] {
    return this._taskViewModels;
  }

  @Input() taskSorting: TableSort;
  get sortedOn(): any {
    const sortedOn = {};
    if (this.taskSorting != null) {
      sortedOn[this.taskSorting.sortBy] = true;
    }
    return sortedOn;
  }
  get sortOrder(): any {
    const sortOrder = {};
    if (this.taskSorting != null) {
      sortOrder[this.taskSorting.sortBy] = this.taskSorting.sortOrder;
    }
    return sortOrder;
  }

  private _taskViewModels: RecoveryTaskViewModel[];
  isAllSelected = false;
  inputScrollPosition = 0;
  @Output() sortingAction = new EventEmitter<TableSort>();
  @Output() refundMovedEvent = new EventEmitter();

  constructor(private serviceOrderService: ServiceOrderService,
              private translateService: TranslateService,
              private dataService: DataService,
              private router: Router,
              private caseService: CaseService,
              public settingsService: SettingsService,
              private boardNavigationStateService: BoardNavigationStateService) {
    super('taskRecoveryTable');
    this.shortcutSubscriptions.push(
      this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateTableRowAltEnter }).subscribe(a => {
        if (Number.isFinite(this.selectedRowIndex)) {
          this.redirectToManageRecovery(this.taskViewModels[this.selectedRowIndex].caseId, this.taskViewModels[this.selectedRowIndex].recoveryId);
        }
      }),
    );
  }

  ngOnInit(): void {
    this.inputScrollPosition = this.boardNavigationStateService.getState('board-case-filter-request-recovery-task-scroll-position') ? parseInt(this.boardNavigationStateService.getState('board-case-filter-request-recovery-task-scroll-position'), 10) : 0;
  }

  ngOnChanges() {
    setTimeout(() => this.setScrollPosition(), 500);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.boardNavigationStateService.saveState('board-case-filter-request-recovery-task-scroll-position', JSON.stringify(this.inputScrollPosition));
    this.boardNavigationStateService.saveState('board-case-filter-request-recovery-selected-row-index', JSON.stringify(this.selectedRowIndex));
  }

  setScrollPosition() {
    if (this.inputScrollPosition !== null && document.getElementById('taskRecoveryTable')) {
      document.getElementById('taskRecoveryTable').scrollTop = this.inputScrollPosition;
    }
  }

  onCaseTabelScroll() {
    this.inputScrollPosition = document.getElementById('taskRecoveryTable').scrollTop;
  }

  redirectToManageRecovery(caseId: number, recovery: number) {
    this.dataService.navigateRecoveryId = recovery;
    this.router.navigate(['case', caseId]);
  }

  selectAllTasks() {
    this.isAllSelected = !this.isAllSelected;
    this.taskViewModels.forEach(a => (a.isSelected = this.isAllSelected));
  }

  sortedBy(column: string): string {
    return this.sortedOn[column] ? 'sorted-by' : '';
  }

  sortBy(column: string) {
    const sortOrder = {};
    if (this.sortOrder[column] == null) {
      sortOrder[column] = SortOrder.asc;
    } else {
      if (this.sortOrder[column] === SortOrder.asc) {
        sortOrder[column] = SortOrder.desc;
      } else {
        sortOrder[column] = SortOrder.asc;
      }
    }
    this.sortingAction.emit(new TableSort({ sortBy: column, sortOrder: sortOrder[column] }));
  }

  getBrandImagesUrlForProfile(profileId: string) {
    return this.caseService.getBrandImagesUrlForProfile(profileId);
  }

  getRecoveryStatusText(recoveryStatus: RecoveryStatus) {
    return this.translateService.instant('case-economy-recovery-recovery-status-' + RecoveryStatus[recoveryStatus]);
  }

  getRemainingDays(task: RecoveryTaskViewModel) {
    return moment(task.applicationDeadline).diff(moment(), 'days');
  }

  isApplicationSent(status: RecoveryStatus) {
    const statusValues = new EconomyStatusBarValues();
    statusValues.statusIndex = -1;
    Recovery.setStatusIndex(statusValues, status);

    return (statusValues.statusIndex >= 4);
  }
}
