import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
import * as moment from 'moment';
import { DaySerializer } from '@secca/shared/models/day-serializer';
import {DateSerializer} from '@secca/shared/models/date-serializer';

export class ProfileLookupResponse {
  lookupTime: moment.Moment;
  matchedBy: string;
  sourceInfo: string;
  results?: RelationSearchResult[];

  public constructor(init?: Partial<ProfileLookupResponse>) {
    Object.assign(this, init);
  }
}

export class RelationSearchResult {
  matches: Matches[];
  customerProductRelation: CustomerProductRelation;

  public constructor(init?: Partial<RelationSearchResult>) {
    Object.assign(this, init);
  }
}

export class Matches {
  parameter: string;
  quality: MatchQuality;

  public constructor(init?: Partial<Matches>) {
    Object.assign(this, init);
  }
}

export enum MatchQuality {
  SUCCESS = 'SUCCESS',
  PARTIAL = 'PARTIAL',
  NONE = 'NONE',
}

export class CustomerProductRelation {
  bin: string;
  bank: string;
  companyPolicyHolder: string;
  customerProductRelationType: CustomerProductRelationType;
  customerRelationLabel: CustomerRelationLabel;
  id: number;
  policyNo: string;
  productId: number;
  productName: string;
  productLevel: string;
  profileId: number;
  profileName: string;
  market: string;
  remarks: string;
  splitDateType: SplitDateType;
  validFrom: moment.Moment;
  validTo: moment.Moment;

  public constructor(init?: Partial<CustomerProductRelation>) {
    Object.assign(this, init);
  }
}

export enum CustomerProductRelationType {
  BUSINESS_TRAVEL = 'BUSINESS_TRAVEL',
  EXPATRIATE_INSURANCE = 'EXPATRIATE_INSURANCE',
  CREDIT_CARD = 'CREDIT_CARD',
  GROUP_INSURANCE = 'GROUP_INSURANCE'
}

export enum SplitDateType {
  INCIDENT_DATE = 'INCIDENT_DATE',
  REPORT_DATE = 'REPORT_DATE',
}

export class CustomerRelationLabel {
  id: number;
  name: string;
  remarks: string;
  status: Status;

  public constructor(init?: Partial<CustomerRelationLabel>) {
    Object.assign(this, init);
  }
}

export enum Status {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
}

@Injectable({
  providedIn: 'root'
})
export class ProfileLookupResponseAdapter implements Adapter<ProfileLookupResponse> {
  constructor() {}

  adapt(item: any): ProfileLookupResponse {
    return new ProfileLookupResponse({
      lookupTime: DateSerializer.deserialize(item.lookupTime),
      matchedBy: item.matchedBy,
      sourceInfo: item.sourceInfo,
      results: this.adaptRelationsSearchResults(item.results)
    });
  }

  adaptRelationsSearchResults(items: any[]): RelationSearchResult[] {
    const relationsSearchResults = [];
    if (items != null) {
      items.forEach(item => {
        return relationsSearchResults.push(
          new RelationSearchResult({
            matches: this.adaptMatches(item.matches),
            customerProductRelation: this.adaptCustomerProductRelation(item.customerProductRelation)
          })
        );
      });
    }
    return relationsSearchResults;
  }

  adaptMatches(items: any[]): Matches[] {
    const matches = [];
    if (items != null) {
      items.forEach(m => {
        matches.push(
          new Matches({
            parameter: m.parameter,
            quality: MatchQuality['' + m.quality]
          })
        );
      });
    }
    return matches;
  }

  adaptCustomerProductRelation(item: any): CustomerProductRelation {
    return new CustomerProductRelation({
      bin: item.bin,
      bank: item.bank,
      companyPolicyHolder: item.companyPolicyHolder,
      customerProductRelationType: item.customerProductRelationType,
      customerRelationLabel: item.customerRelationLabel ? this.adaptCustomerRelationLabel(item.customerRelationLabel) : null,
      id: item.id,
      policyNo: item.policyNo,
      productId: item.productId,
      productName: item.productName,
      productLevel: item.productLevel,
      profileId: item.profileId,
      profileName: item.profileName,
      market: item.market,
      remarks: item.remarks,
      splitDateType: item.splitDateType,
      validFrom: item.validFrom ? DaySerializer.deserialize(item.validFrom) : null,
      validTo: item.validTo ? DaySerializer.deserialize(item.validTo) : null
    });
  }

  adaptCustomerRelationLabel(item: any): CustomerRelationLabel {
    return new CustomerRelationLabel({
      id: item.id,
      name: item.name,
      remarks: item.remarks,
      status: item.status
    });
  }
}
