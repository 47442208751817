import { Component, Input, OnInit } from '@angular/core';
import { MedicalAssessmentAdmission } from '@secca/shared/models/medicalAssessment';
import * as moment from 'moment';
import { RangeSupport } from '@secca/shared/components/input-date-time/range-support';

@Component({
  selector: 'app-total-days',
  templateUrl: './total-days.component.html',
  styleUrls: ['./total-days.component.scss']
})
export class TotalDaysComponent implements OnInit {

  @Input() header: string;
  @Input()
  set medicalAssessmentAdmission(newMedicalAssessmentAdmission: MedicalAssessmentAdmission) {
    if (newMedicalAssessmentAdmission !== undefined) {
      this._medicalAssessmentAdmission = newMedicalAssessmentAdmission;
      this.loadRangeSupport();
    }
  }

  get medicalAssessmentAdmission(): MedicalAssessmentAdmission {
    return this._medicalAssessmentAdmission;
  }

  @Input() disabled: boolean;
  @Input() required: boolean;
  expectedEndPeriod: moment.Moment;
  period: moment.Moment[];
  totalAdmissionDays: number = null;
  rangeSupport: RangeSupport;
  invalidDateRange: boolean;
  _medicalAssessmentAdmission: MedicalAssessmentAdmission;

  constructor() { }

  ngOnInit() {
    this.period = [this.medicalAssessmentAdmission.admissionFrom, this.medicalAssessmentAdmission.admissionTo];
    this.invalidDateRange = false;
  }

  loadRangeSupport() {
    this.rangeSupport = new RangeSupport(
      (this.medicalAssessmentAdmission.admissionFrom !== null && this.medicalAssessmentAdmission.admissionFrom !== undefined) ? moment(this.medicalAssessmentAdmission.admissionFrom.format()) : undefined,
      (this.medicalAssessmentAdmission.admissionTo !== null && this.medicalAssessmentAdmission.admissionTo !== undefined) ? moment(this.medicalAssessmentAdmission.admissionTo.format()) : undefined,
      this,
      this.updateadmissionFrom,
      this.updateadmissionTo,
      this.invalidDate);
  }

  updateadmissionFrom(admissionFrom: moment.Moment, admissionTo: moment.Moment) {
    this.invalidDateRange = false;
    this.medicalAssessmentAdmission.admissionFrom = admissionFrom;
    this.medicalAssessmentAdmission.admissionTo = admissionTo;
  }

  updateadmissionTo(admissionTo: moment.Moment, admissionFrom: moment.Moment) {
    this.invalidDateRange = false;
    this.medicalAssessmentAdmission.admissionTo = admissionTo;
    this.medicalAssessmentAdmission.admissionFrom = admissionFrom;
  }

  invalidDate(admissionTo: moment.Moment, admissionFrom: moment.Moment) {
    this.invalidDateRange = true;
    this.medicalAssessmentAdmission.admissionTo = admissionTo;
    this.medicalAssessmentAdmission.admissionFrom = admissionFrom;
  }

  public saveAdditionallyAdmissionUntil($event) {
    this.medicalAssessmentAdmission.additionallyAdmissionUntil = $event;
  }
}


