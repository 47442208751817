import { Injectable } from '@angular/core';
import { DropdownDictionary } from './dropdownDictionary';
import { HandlingAreaStatusEnum, HandlingAreaType, Team, UserTeam } from './enums';
import { TeamDto, TeamDtoAdapter } from '../../shared/models/teamDto';

export class SearchUser {
  department: string;
  fullUserName: string;
  initials: string;
  picture: string;
  responsibility: HandlingAreaType;
  role: string;
  team: TeamDto;
  active: boolean;
  id: number;
  status: HandlingAreaStatusEnum;
  excludedTeams: UserTeam[];
  includedTeams: Team[];
  public constructor(init?: Partial<SearchUser>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class SearchUserDropDownWrapper {
  wrap(item: any): DropdownDictionary {
    return new DropdownDictionary(item.initials, item.fullUserName);
  }
}

@Injectable({
  providedIn: 'root'
})
export class CaseUserSearchUserWrapper {
  wrap(item: any): SearchUser {
    return new SearchUser({
      department: item.userDepartment,
      fullUserName: item.firstName + ' ' + item.surname,
      initials: item.initials,
      picture: item.picture,
      role: item.userRole,
      team: (item.userTeam) ? new TeamDtoAdapter().adapt(item.userTeam) : null,
      active: item.active,
      status: item.status
    });
  }
}
