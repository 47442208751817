<div *ngIf="case && task" class="modal-container">
  <div class="modal-content">
    <div class="modal-content-body">
      <div *permission="PermissionEnum.TASK_UPDATE; hideType: PermissionHideTypeEnum.DISABLE">
        <div class="modal-body modal-body-supplier-invoice">
          <div class="container p-0">
            <div>
              <div>
                <div class="left-panel-message-case-edit">
                  <div class="modal-body-left-panel">
                    <div [appDisableInput]="caseStateService.isCaseDisabled()">
                      <div class="field-title">{{ 'task-modal-title' | translate }}</div>
                      <div>
                        <input [readonly]="true" type="text" name="textinput" class="task-text"
                          maxlength="1000" [name]="'Title'" [required]="true" (input)="titleUpdated()"
                          [(ngModel)]="task.title" />
                      </div>
                      <div class="row-separator-small"></div>
                      <div class="horizontal-line"></div>
                    </div>
                  </div>
                  <div>
                    <div class="field-title">
                      {{ 'task-modal-description' | translate }}
                    </div>
                    <textarea rows="5" readonly>{{ task.description }}</textarea>
                    <div class="horizontal-line"></div>
                  </div>
                  <div>
                    <div [appDisableInput]="caseStateService.isCaseDisabled()"
                      class="caselock-no-events">
                      <div class="field-title">{{ 'task-modal-due-date' | translate }}</div>
                      <div>
                        <app-input-date-time [disabled]="true" [date]="task.dueDate" [withImage]="true" [ngClass]="validated ? '' : 'required-red'"
                          (dateChange)="dueDateUpdated($event)" [timeZone]="'local'">
                        </app-input-date-time>
                      </div>
                      <div class="row-separator"></div>
                      <app-case-handler-selector [acHandler]="acHandler" [caseId]="caseId" [required]="true"
                                                 (caseHandlerChange)="caseHandlerUpdated($event)">
                      </app-case-handler-selector>
                      <div class="row-separator"></div>
                      <div class="field-title">{{ 'task-modal-status' | translate }}</div>
                      <div>
                        <app-drop-down
                          [dropdownPosition]="'auto'"
                          [disabled]="true" [items]="task.allStatuses" [(selectedItemId)]="task.status"
                          [requiredRedBackground]="true" (selectedItemIdChange)="statusUpdated()"></app-drop-down>
                      </div>
                      <div class="row-separator"></div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="task-id-col">
                    <div class="task-id">
                      <div>{{task.createdOn ? ('Created: ' + (task.createdOn | momentFormat:'DD MMM YYYY HH:mm':'local') + '&nbsp;&nbsp;&nbsp;ID: ' + task.id) : '&nbsp;' }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
