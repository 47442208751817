<div class="sub-component-container m-3">
    <div class="sub-title sub-title-table">
    <div class="d-flex">
        <div class="me-auto p-0 w-50">{{ majorIncidentCaseDetail.caseNumber }} </div>
    </div>
</div>
<div class="m-3 ">
    <div class="pb-3 stakeholder-container" *ngFor="let stakeholder of endUserCaseStakeholders; let i = index">
        <div class="stakeholder  me-1">
            <span class="headline">{{"major-incident-service-order-stakeholder" | translate}}</span>
            <app-sub-major-incident-stakeholder
                [stakeholdersDropdown]="stakeholdersDropdown"
                [caseStakeholders]="majorIncidentCaseDetail.stakeholders"
                (update)="updateStakeHolder($event)"
                [subStakeholder]="stakeholder"
            >
            </app-sub-major-incident-stakeholder>
        </div>
        <div class="age me-1 ms-1">
            <app-input-calendar
                [withImage]="false"
                [name]="'major-incident-service-order-date-of-birth' | translate"
                [required]="false"
                [date]="getDayOfBirth(stakeholder.stakeholderId)"
                [disabled]="true"
            >
            </app-input-calendar>
        </div>
        <div class="location ms-1">
            <app-input
                [name]="'major-incident-service-order-current-location' | translate"
                [disabled]="true"
                [value]="majorIncidentCaseDetail.destination?.freeText"
            ></app-input>
        </div>


        <div class="delete-container w-100 ms-2" *ngIf="true" (click)="deleteStakeholder(stakeholder)" >
            <div class="d-flex justify-content-center align-items-center h-100">
                <img src="../../../../assets/icons/garbage.svg" />
            </div>
        </div>


    </div>
</div>
<div class="ms-3 mb-1">
    <a class="add-stakeholder" (click)="addStakeholder()"
       *ngIf="!disabled">{{"major-incident-service-order-add-stakeholder" | translate}} {{ majorIncidentCaseDetail.caseNumber}}</a>
</div>