import { Component, Input } from '@angular/core';
import { DocumentService } from '@secca/core/services/document.service';

@Component({
  selector: 'app-open-recovery-payment-document',
  templateUrl: './open-recovery-payment-document.component.html',
  styleUrls: ['./open-recovery-payment-document.component.scss']
})
export class OpenRecoveryPaymentDocumentComponent {
  @Input()
  recoveryPaymentDocumentId: string;

  constructor(private documentService: DocumentService) {
  }

  getAndOpenRecoveryPaymentDocument(recoveryPaymentDocumentId: string) {
    this.documentService.getDocument(recoveryPaymentDocumentId).subscribe(result => {
      const url = window.URL.createObjectURL(result);
      window.open(url);
    });
  }


}
