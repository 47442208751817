<ng-template #content let-modal>
  <div class="fee-picker-secondary-container">
    <div class="fee-picker-secondary-modal-header">
      <div class="fee-picker-secondary-modal-header-close float-end clearfix">
        <img (click)="close()" src="/assets/icons/Close.svg" alt="Close"/>
      </div>

      <div class="fee-picker-secondary-modal-header-title">
        {{ 'case-economy-fee-picker-secondary-title' | translate }}
      </div>
    </div>
    <div class="fee-picker-secondary-modal-body">
      <div class="horizontal-spacer"></div>
      <ng-container *ngFor="let category of getAllSosServiceCategories(); index as i">
        <div>
          <input type="checkbox" id="{{ category }}" [disabled]="feePickerCategoryMap.get(category)" [checked]="feePickerCategoryMap.get(category)"
                 (change)="enableCategory(category)"/>
          <label
            for="{{ category }}"
          >{{ 'case-economy-fee-picker-secondary-' + category | translate }}</label
          >
        </div>
      </ng-container>
    </div>
    <div class="modal-footer">
      <div class="d-flex justify-content-end">
        <button [disabled]="saveDisabled()" (click)="save()">
          {{ 'case-economy-sos-services-modify-save' | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>
