<div appDialogSelectable appDialogKeyboardEnabled [appDialogMinimizable]="MinimizableDialogType.SupplierInvoice">
  <mat-dialog-content (cdkDragStarted)="dialogBoundryService.handleDragStart($event)"
                      (cdkDragEnded)="dialogBoundryService.handleDragEnd($event)" cdkDragRootElement=".cdk-overlay-pane" cdkDrag>
    <div appDialogDragArea cdkDragHandle></div>
    <app-manage-supplier-invoice
      [supplierInvoiceId]="data.supplierInvoiceId"
      [customerProfileId]="data.customerProfileId"
      [caseCreatedDate]="data.caseCreatedDate"
      (supplierInvoiceSaved)="data.supplierInvoiceSaved($event)"
      (closed)="close()"
    ></app-manage-supplier-invoice>
  </mat-dialog-content>
</div>
