import {Adapter} from "@secca/shared/interfaces/adapter";
import {Injectable} from "@angular/core";

export class ValidCaseCoverage {
  coverageId: string;
  coverageName: string;
  type: string;
  limits: ValidCaseLimit[];

  public constructor(init?: Partial<ValidCaseCoverage>) {
    Object.assign(this, init);
  }
}

export class ValidCaseLimit {
  description: string;
  limitType: string;
  limitUnit: string;
  limitValue: string;
  limitMaxValue: number;

  public constructor(init?: Partial<ValidCaseLimit>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class ValidCaseCoverageAdapter implements Adapter<ValidCaseCoverage> {
  adapt(item: any): ValidCaseCoverage {
    return new ValidCaseCoverage({
      coverageId: item.coverageId,
      coverageName: item.coverageName,
      type: item.coverageType,
      limits: this.adaptLimits(item.limits),
    });
  }

  adaptLimits(items: any[]): ValidCaseLimit[] {
    return null;
  }
}
