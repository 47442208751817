import { EscortEnum, MedicalEscortSpecialtyEnum } from './../enums';

import { Injectable } from '@angular/core';
import { Adapter } from '../../interfaces/adapter';

export class Escort {
  type: EscortEnum;
  taskDescription: string;
  specializationDescription: string;
  specialty: MedicalEscortSpecialtyEnum;

  public constructor(init?: Partial<Escort>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class EscortAdapter implements Adapter<Escort> {
  adapt(item: any): Escort {
    return new Escort({
      type: item.type,
      taskDescription: item.taskDescription,
      specializationDescription: item.specializationDescription,
      specialty: item.specialty
    });
  }
}
