import { PermissionEnum, SavingTypedInField, StatusTypes, SupplierInvoiceStatus } from "@secca/shared/models/enums";
import { SupplierInvoiceService } from "@secca/core/services/supplier-invoice.service";
import { ServiceOrderService } from "@secca/core/services/service-order.service";
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { SupplierInvoice } from "src/app/shared/models/supplierInvoice";
import { SupplierInvoiceItem } from "src/app/shared/models/supplierInvoiceItem";
import { CaseService } from "src/app/core/services/case.service";
import { CaseStakeholder } from "@secca/shared/models/caseStakeholder";
import { CaseLockHelperService } from "@secca/core/services/case-lock-helper.service";
import { CaseLockOverlayAction, LockContextEnum } from "@secca/shared/enums/lock-context.enum";
import { ReturnInvoiceModalComponent } from "./return-invoice-modal/return-invoice-modal.component";
import { ServiceOrder } from "@secca/shared/models/service-order/service-order";
import { ModalDialogComponent } from "@secca/shared/components/modal-dialog/modal-dialog.component";
import { ModalDialogConfiguration } from "@secca/shared/models/modal/modal-dialog-configuration";
import { TranslateService } from "@ngx-translate/core";
import { PermissionService } from "@secca/core/services/permission.service";
import { SavingsCalculator } from "./savings-calculator";
import { forkJoin, Observable, of } from "rxjs";
import { ServiceItemType } from "@secca/shared/models/service-item-type";
import { CaseStateService } from "@secca/core/state-services/case-state.service";
import { SupplierInvoiceAutoApprovalDto } from "@secca/shared/models/supplierInvoiceAutoApprovalDto";
import { CommentModalComponent } from "@secca/case/components/case-economy/manage-supplier-invoice/comment-modal/comment-modal.component";
import { ErpCreditor } from "@secca/shared/models/erp-creditor";

@Component({
  selector: 'app-manage-supplier-invoice',
  templateUrl: './manage-supplier-invoice.component.html',
  styleUrls: ['./manage-supplier-invoice.component.scss'],
})
export class ManageSupplierInvoiceComponent implements OnInit {
  @ViewChild('returnInvoiceModal') returnModal: ReturnInvoiceModalComponent;
  @ViewChild('commentModal') commentModal: CommentModalComponent;

  @Input() supplierInvoiceId: number;
  @Input()
  set customerProfileId(value: number) {
    if (value) {
      if(this.currentCustomerProfileId == null){
        this.currentCustomerProfileId = value;
        this.validateCustomerProfile();
      }
      this.currentCustomerProfileId = value;
    }
  }

  @Input() caseCreatedDate: Date;

  @Output() supplierInvoiceSaved: EventEmitter<SupplierInvoice> = new EventEmitter();
  @Output() closed = new EventEmitter<number>();

  currentCustomerProfileId: number = null;
  supplierInvoice: SupplierInvoice;
  supplierInvoiceAutoApprovalDto: SupplierInvoiceAutoApprovalDto;
  caseStakeholder: CaseStakeholder[];
  serviceOrders: ServiceOrder[];
  serviceItemList: ServiceItemType[];
  groupMemberValid = true;
  isCaseErpReady = false;
  creditor: ErpCreditor = new ErpCreditor();
  private confirmRejectDialog: NgbModalRef;
  private savingsCalculator: SavingsCalculator;
  private saveInProgress = false;

  constructor(
    private modalService: NgbModal,
    private supplierInvoiceService: SupplierInvoiceService,
    private serviceOrderService: ServiceOrderService,
    private caseService: CaseService,
    public caseLockHelperService: CaseLockHelperService,
    public permissionService: PermissionService,
    private translate: TranslateService,
    public caseStateService: CaseStateService
  ) {

    this.savingsCalculator = new SavingsCalculator();
  }

  ngOnInit() {
    this.supplierInvoice = new SupplierInvoice();
    this.supplierInvoice.items = [];
    this.supplierInvoice.invoiceAmountLocal = { amount: 0, currency: null, precision: 2 };
    this.supplierInvoice.invoiceAmountExchanged = { amount: 0, currency: null, precision: 2 };

    if (this.supplierInvoiceId) {
      this.loadSupplierInvoice();
    }
    this.caseService.getCaseStakeholderWithCaseStakeholder().subscribe(stakeholders => (this.caseStakeholder = stakeholders));
  }

  private loadSupplierInvoice() {
    this.saveInProgress = false;
    this.serviceOrders = [];
    forkJoin({
      rqServiceItemList: this.supplierInvoiceService.getServiceItemList(),
      rqSupplierInvoice: this.supplierInvoiceService.getSupplierInvoice(this.supplierInvoiceId),
      rqSupplierInvoiceAutoApprovalDto: this.supplierInvoiceService.getSupplierInvoiceAutoApprovalDto(this.supplierInvoiceId)
    }).subscribe(({ rqServiceItemList, rqSupplierInvoice, rqSupplierInvoiceAutoApprovalDto }) => {
      this.serviceItemList = rqServiceItemList;
      this.supplierInvoice = rqSupplierInvoice;
      this.supplierInvoiceAutoApprovalDto = rqSupplierInvoiceAutoApprovalDto;
      this.caseService.isCaseErpReady(this.supplierInvoice.caseId+"").subscribe(result => {
        this.isCaseErpReady = result;
      });
      this.loadCaseServiceOrders(this.supplierInvoice.caseId).subscribe(serviceOrders => {
        if (serviceOrders) {
          this.serviceOrders = serviceOrders;
          this.addDefaultSupplierInvoiceItem();
        }
      });
      if (!!this.supplierInvoice.items && this.supplierInvoice.items.length > 0) {
        this.setIncludingVAT(rqSupplierInvoice);
      }
      this.aggregateSavings();

      this.creditor.erpId = this.supplierInvoice.creditorId;
    });
  }

  private validateCustomerProfile() {
    this.caseService.getCustomerProfile(this.currentCustomerProfileId.toString()).subscribe(customerProfile =>{
      if(customerProfile.isGroupProfile){
        this.groupMemberValid = false;
      }
    });
  }

  private loadCaseServiceOrders(caseId: number): Observable<ServiceOrder[]> {
    if (this.permissionService.checkUserPermission(PermissionEnum.PLANS_READ)) {
      return this.serviceOrderService.getServiceOrdersForSupplierInvoice(caseId);
    } else {
      return of([]);
    }
  }

  setIncludingVAT(supplierInvoice: SupplierInvoice) {
    supplierInvoice.items.forEach(invoiceItem => {
      if (invoiceItem.serviceItemCode) {
        invoiceItem.isIncludingVAT = this.serviceItemList.find(s => s.code === invoiceItem.serviceItemCode)?.vat;
      }
    });
  }

  private addDefaultSupplierInvoiceItem() {
    if (
      this.supplierInvoice.invoiceStatus === SupplierInvoiceStatus.invoiceReceived ||
      this.supplierInvoice.invoiceStatus === SupplierInvoiceStatus.ongoingCostControl
    ) {
      if (this.supplierInvoice.items.length === 0) {
        this.addItem();
      }
      if (this.supplierInvoice.items.length > 0 && this.supplierInvoice.items[0].serviceOrderId === undefined) {
        let serviceOrderIx = 0;
        while (serviceOrderIx < this.serviceOrders.length && this.supplierInvoice.items[0].serviceOrderId === undefined) {
          if (this.supplierInvoice.serviceOrderId === this.serviceOrders[serviceOrderIx].serviceOrderId) {
            this.supplierInvoice.items[0].serviceOrderId = this.serviceOrders[serviceOrderIx].serviceOrderId;
          }
          serviceOrderIx++;
        }
      }
    }
  }

  close() {
    if (this.closed.observers) {
      this.closed.emit();
    }
    this.modalService.dismissAll();
  }

  aggregateSavings() {
    for (const item of this.supplierInvoice.items) {
      this.savingsCalculator.recalcSavingAmounts(this.supplierInvoice.invoiceAmountLocal, item);
    }
  }

  getTotalGrossAmount(): number {
    return this.supplierInvoice.invoiceAmountLocal.amount + this.getTotalGrossSaving();
  }

  getTotalGrossSaving(): number {
    let grossSaving = 0;
    this.supplierInvoice.items.forEach(item => {
      this.savingsCalculator.splitSavings(item)[0].forEach(saving => (grossSaving += +saving.amount));
    });

    return grossSaving;
  }

  getTotalGrossSavingPct(): number {
    const totalGrossAmount = this.getTotalGrossAmount();
    if (totalGrossAmount === 0) {
      return null;
    }
    return (this.getTotalGrossSaving() * 100) / totalGrossAmount;
  }

  getTotalReduction(): number {
    let reduction = 0;
    this.supplierInvoice.items.forEach(item => {
      this.savingsCalculator.splitSavings(item)[1].forEach(saving => (reduction += +saving.amount));
    });

    return reduction;
  }

  getTotalReductionPct(): number {
    if (!this.supplierInvoice.invoiceAmountLocal || this.supplierInvoice.invoiceAmountLocal.amount === 0) {
      return null;
    }
    return (this.getTotalReduction() * 100) / this.supplierInvoice.invoiceAmountLocal.amount;
  }

  getTotalGrossAmountExchanged(): number {
    return Math.round(this.getTotalGrossAmount() * 100 * this.supplierInvoice.exchangeRate) / 100;
  }

  getTotalGrossSavingExchanged(): number {
    return Math.round(this.getTotalGrossSaving() * 100 * this.supplierInvoice.exchangeRate) / 100;
  }

  getTotalReductionExchanged(): number {
    return Math.round(this.getTotalReduction() * 100 * this.supplierInvoice.exchangeRate) / 100;
  }

  getTotalPaidLocal(): number {
    return this.supplierInvoice.invoiceAmountLocal.amount - this.getTotalReduction();
  }

  getTotalPaidExchanged(): number {
    return this.supplierInvoice.invoiceAmountExchanged.amount - this.getTotalReductionExchanged();
  }

  getDiffFromTotalAmount(): number {
    let totalTyped = 0.0;
    for (const item of this.supplierInvoice.items) {
      totalTyped += Number(item.paidAmount);
    }
    return this.supplierInvoice.invoiceAmountLocal.amount - totalTyped;
  }

  getTotalAmountTypedExclVAT(): string {
    for (let index = 0; index < this.supplierInvoice.items.length; index++) {
      if (index === 0) {
        this.supplierInvoice.items[0].paidAmount = this.supplierInvoice.invoiceAmountLocal.amount;
      } else {
        if (this.supplierInvoice.items[index].paidAmount != undefined) {
          this.supplierInvoice.items[0].paidAmount -= this.supplierInvoice.items[index].paidAmount;
        }
      }
    }

    let totalTyped = 0.0;
    for (const item of this.supplierInvoice.items) {
      if (
        this.supplierInvoice.creditorSupplierGroup != null &&
        (this.supplierInvoice.creditorSupplierGroup === '1' || this.supplierInvoice.creditorSupplierGroup === '8')
      ) {
        totalTyped += this.removeVAT(item.paidAmount);
      } else {
        totalTyped += Number(item.paidAmount);
      }
    }
    return totalTyped.toFixed(2);
  }

  removeVAT(amount: number): number {
    return (amount / 100) * 80;
  }

  getTotalAmountTypedMatch(): boolean {
    return Math.abs(Math.round(this.getDiffFromTotalAmount() * Math.pow(10, this.supplierInvoice.invoiceAmountLocal.precision))) === 0;
  }

  isValidated(): boolean {
    let success = !this.saveInProgress;
    this.supplierInvoice.items.forEach(item => {
      item.savings.forEach(
        saving =>
          (success =
            success &&
            !!saving.reductionTypeCode &&
            ((saving.typedInField === SavingTypedInField.AMOUNT && saving.amount > 0) ||
              (saving.percentage > 0 && saving.percentage <= 100)))
      );
    });
    return success;
  }

  isNotApprovable(): boolean {
    let allItemsFilled = true;
    this.supplierInvoice.items.forEach(item => {
      allItemsFilled =
        allItemsFilled &&
        item.serviceOrderId !== null &&
        item.serviceItemCode !== null &&
        item.quantity !== null &&
        item.quantity !== 0 &&
        item.quantityUnit !== null;
      if (allItemsFilled) {
        const serviceOrder = this.serviceOrders.find(so => so.serviceOrderId === item.serviceOrderId);
        allItemsFilled =
          allItemsFilled &&
          (serviceOrder?.status === StatusTypes.COMMITTED || serviceOrder?.status === StatusTypes.CANCELLED) &&
          !!serviceOrder?.supplierId;
      }
    });
    return !allItemsFilled || !this.getTotalAmountTypedMatch() || this.isApproved() || this.isReturned() || !this.isValidated();
  }

  addItem() {
    if (this.supplierInvoice.invoiceStatus !== SupplierInvoiceStatus.approved) {
      const supplierInvoiceItem = new SupplierInvoiceItem({
        serviceOrderId: this.supplierInvoice.serviceOrderId,
        serviceItemCode: null,
        quantity: null,
        quantityUnit: null,
        paidAmount: null,
        isIncludingVAT: false,
      });
      if (this.supplierInvoice.creditorSupplierGroup === '1' || this.supplierInvoice.creditorSupplierGroup === '8') {
        supplierInvoiceItem.isInVATCreditorGroup = true;
      }
      supplierInvoiceItem.savings = [];
      supplierInvoiceItem.id = -1 * this.supplierInvoice.items.length;
      this.supplierInvoice.items.push(supplierInvoiceItem);
      const scrollToMeElem = document.getElementById('scrollToMe');
      if (scrollToMeElem) {
        scrollToMeElem.scrollTop = scrollToMeElem.scrollHeight + 72;
      }
    }
  }

  deleteItem(supplierInvoiceItem: SupplierInvoiceItem) {
    const original = this.supplierInvoice.items;
    this.supplierInvoice.items = original.filter(s => s.id !== supplierInvoiceItem.id);
  }

  save() {
    this.saveInProgress = true;
    if (
      this.supplierInvoice.invoiceStatus === SupplierInvoiceStatus.invoiceReceived ||
      this.supplierInvoice.invoiceStatus === SupplierInvoiceStatus.invoiceCorrected
    ) {
      this.supplierInvoice.invoiceStatus = SupplierInvoiceStatus.ongoingCostControl;
    }


    if (this.supplierInvoiceAutoApprovalDto.isCorrect !== null) {
      if (this.supplierInvoiceAutoApprovalDto.isCorrect) {
        this.supplierInvoiceService.markSupplierInvoiceAutoApprovalCorrect(this.supplierInvoiceId).subscribe();
      } else {
        this.supplierInvoiceService.markSupplierInvoiceAutoApprovalIncorrect(this.supplierInvoiceId, this.supplierInvoiceAutoApprovalDto.incorrectComment).subscribe();
      }
    }

    this.supplierInvoiceService.saveSupplierInvoice(this.supplierInvoice).subscribe(result => {
      this.supplierInvoice = result;
      this.supplierInvoiceSaved.emit(this.supplierInvoice);
      this.aggregateSavings();
      this.saveInProgress = false;
      this.close();
    });
  }

  approve() {
    this.supplierInvoice.invoiceStatus = SupplierInvoiceStatus.approved;
    this.supplierInvoiceService.saveSupplierInvoice(this.supplierInvoice).subscribe(result => {
      this.supplierInvoice = result;
      this.supplierInvoiceSaved.emit(this.supplierInvoice);
      this.supplierInvoiceService.sendSupplierInvoiceApproved();
      this.aggregateSavings();
      this.close();
    });
  }

  isApproved(): boolean {
    if (this.supplierInvoice) {
      return (
        this.supplierInvoice.invoiceStatus === SupplierInvoiceStatus.approved ||
        this.supplierInvoice.invoiceStatus === SupplierInvoiceStatus.error ||
        this.supplierInvoice.invoiceStatus === SupplierInvoiceStatus.supplierPaid ||
        this.supplierInvoice.invoiceStatus === SupplierInvoiceStatus.rejected
      );
    } else {
      return false;
    }
  }

  isReturned(): boolean {
    return !!this.supplierInvoice && this.supplierInvoice.invoiceStatus === SupplierInvoiceStatus.awaitingAccounting;
  }

  isLatestRemark(): boolean {
    return this.supplierInvoice.invoiceStatus === SupplierInvoiceStatus.invoiceCorrected;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  markAutoApprovalAsIncorrect() {
    this.commentModal.open(this.supplierInvoiceAutoApprovalDto?.incorrectComment);
  }

  markAutoApprovalAsCorrect() {
    this.supplierInvoiceAutoApprovalDto.isCorrect = true;
    this.supplierInvoiceAutoApprovalDto.incorrectComment = null;
  }

  updateAutoApprovalComment(comment: string) {
    this.supplierInvoiceAutoApprovalDto.incorrectComment = comment;
    this.supplierInvoiceAutoApprovalDto.isCorrect = false;
    this.commentModal.close();
  }

  openReturnToAccounting() {
    this.returnModal.open();
  }

  returnToAccounting(remark: string) {
    this.supplierInvoice.returnRemark = remark;
    this.supplierInvoice.invoiceStatus = SupplierInvoiceStatus.awaitingAccounting;
    this.save();
  }

  get LockContextEnum(): any {
    return LockContextEnum;
  }

  get CaseLockOverlayAction(): any {
    return CaseLockOverlayAction;
  }

  reject() {
    this.confirmRejectDialog = this.modalService.open(ModalDialogComponent);
    this.confirmRejectDialog.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'default-modal-header',
      text: this.translate.instant('supplier-invoice-reject-text'),
      footer: 'unlock-case-footer',
      yes: 'default-modal-dialog-yes',
      no: 'default-modal-dialog-no',
      isBody: true,
      isFooter: true,
      translateTitle: false,
    });
    this.confirmRejectDialog.componentInstance.closeModalEvent.subscribe(emittedEvent => {
      if (emittedEvent) {
        this.rejectSupplierInvoice();
      }
      this.closeRejectDialog();
    });
  }

  private rejectSupplierInvoice() {
    this.supplierInvoice.invoiceStatus = SupplierInvoiceStatus.rejected;
    this.save();
  }

  private closeRejectDialog() {
    this.confirmRejectDialog.close();
  }

  hasAutoApprovalStatus(): boolean {
    return !!this.supplierInvoiceAutoApprovalDto;
  }

  shouldShowAutoApprovalCorrect(): boolean {
    if (this.supplierInvoiceAutoApprovalDto !== null && this.supplierInvoiceAutoApprovalDto?.isCorrect !== null) {
      return this.supplierInvoiceAutoApprovalDto?.isCorrect === true;
    }
    return false;
  }

  shouldShowAutoApprovalIncorrect(): boolean {
    if (this.supplierInvoiceAutoApprovalDto !== null && this.supplierInvoiceAutoApprovalDto?.isCorrect !== null) {
      return this.supplierInvoiceAutoApprovalDto?.isCorrect === false;
    }
    return false;
  }

  isInvoiceStatusReceived(): boolean {
    return this.supplierInvoice?.invoiceStatus === SupplierInvoiceStatus.invoiceReceived;
  }

  hasAutoApprovalStatusAndScore(): boolean {
    return !!this.supplierInvoiceAutoApprovalDto && !!this.supplierInvoiceAutoApprovalDto.approvalScore;
  }

  publishedAutoApprovalExists(): boolean {
    if (this.hasAutoApprovalStatus()) {
      return this.supplierInvoiceAutoApprovalDto.publishDate !== null;
    }
    return false;
  }

  resendApproval() {
    this.supplierInvoiceService.resendSupplierInvoiceAutoApproval(this.supplierInvoiceId).subscribe(value => {
      this.supplierInvoiceAutoApprovalDto = value;
      this.close();
    });
  }

  autoApprovalGroupStyles(): object {
    if (this.supplierInvoice.invoiceStatus !== SupplierInvoiceStatus.error && this.publishedAutoApprovalExists()) {
      return { visibility: 'visible' };
    } else {
      return { visibility: 'hidden' };
    }
  }

  showRetryCount(): boolean {
    return [SupplierInvoiceStatus.approved, SupplierInvoiceStatus.error, SupplierInvoiceStatus.supplierPaid].includes(this.supplierInvoice.invoiceStatus);
  }

  protected readonly SupplierInvoiceStatus = SupplierInvoiceStatus;

  getCreditorInfo() {
    if (!this.creditor?.id) {
      this.supplierInvoiceService.getErpCreditor(this.supplierInvoice.creditorId).subscribe(creditor => this.creditor = creditor);
    }
  }
}
