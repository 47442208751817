import {CallType} from './../../../../../shared/models/enums';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation} from '@angular/core';
import {NgSelectComponent} from '@ng-select/ng-select';
import {PhoneCall} from '@secca/shared/models/phoneCall';

@Component({
  selector: 'app-logged-call',
  templateUrl: './logged-call.component.html',
  styleUrls: ['./logged-call.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoggedCallComponent implements OnInit, OnChanges {
  @ViewChild('loggedCallDropDown', { static: true }) loggedCallDropDown: NgSelectComponent;

  @Input() items: PhoneCall[];
  @Input() callType: CallType;
  @Input() stakeholderType: string;
  @Input() lastPhoneCallConnectionId: string;
  @Input() validate: boolean;
  @Input() disabled: boolean;

  @Output() selectedPhoneConversationChange = new EventEmitter();
  @Output() open = new EventEmitter();
  @Output() callTypeChange = new EventEmitter<CallType>();

  selectedPhoneCall: PhoneCall;

  constructor() {}

  ngOnInit() {
    this.loggedCallDropDown.notFoundText = '';
    this.loggedCallDropDown.searchable = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes.items?.currentValue) {
      this.initLastPhoneCall();
    }
  }

  get CallType() {
    return CallType;
  }

  chosenLoggedCallValueClick() {
    if (this.disabled) {
      return;
    }
    this.loggedCallDropDown.open();
    this.open.emit();
  }

  incomingCallClick() {
    if (this.disabled) {
      return;
    }
    this.selectedPhoneCall = null;
    this.callType = this.stakeholderType !== 'Other' ? CallType.ingoing : CallType.outgoing ;
    this.callTypeChange.emit(this.callType);
    this.loggedCallDropDown.close();
  }

  outgoingCallClick() {
    if (this.disabled) {
      return;
    }
    this.selectedPhoneCall = null;
    this.callType = CallType.outgoing;
    this.callTypeChange.emit(this.callType);
    this.loggedCallDropDown.close();
  }

  regularNumberClick() {
    if (this.disabled) {
      return;
    }
    this.open.emit();
  }

  onChange() {
    this.selectedPhoneConversationChange.emit(this.selectedPhoneCall);
  }

  private initLastPhoneCall() {
    if ( !!this.lastPhoneCallConnectionId ) {
      const phoneCall = this.items?.find(phoneCall => phoneCall.connectionCallId === this.lastPhoneCallConnectionId);
      this.selectedPhoneCall = phoneCall;
      this.onChange();
    }
  }
}
