import { Component, Input } from '@angular/core';
import { OtherInsurance } from 'src/app/shared/models/otherInsurance';
import { HasOtherInsurance } from 'src/app/shared/models/enums';
import { CaseService } from 'src/app/core/services/case.service';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import { PermissionService } from '@secca/core/services/permission.service';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { PersonInsurance } from '@secca/shared/models/person-insurance';

@Component({
  selector: 'app-case-basics-insurance-other',
  templateUrl: './case-basics-insurance-other.component.html',
  styleUrls: ['./case-basics-insurance-other.component.scss']
})
export class CaseBasicsInsuranceOtherComponent {

  @Input() public otherInsurance: OtherInsurance;
  @Input() public personInsurance: PersonInsurance;
  public requiredProfileId: string;

  constructor(private caseService: CaseService,
              public caseLockHelperService: CaseLockHelperService,
              public caseStateService: CaseStateService,
              public permissionService: PermissionService) {
    const requiredProfileCode = 'CP14'; // AKM promises that this, while hideous, is temporary
    this.caseService.getCustomerProfiles().subscribe(result => {
      const requiredProfile = result.find(profile => profile.profileCode === requiredProfileCode);
      if (requiredProfile) {
        this.requiredProfileId = requiredProfile.profileId;
      }
    });
  }

  saveModel() {
    if (this.otherInsurance?.creditCardBank || this.otherInsurance?.insuranceCompany) {
      this.otherInsurance.hasOtherInsurance = HasOtherInsurance.YES;
    }
    if (this.otherInsurance.creditCardNo !== null) {
      for (let i = 6; ((i < this.otherInsurance.creditCardNo.length) && (i <= 11)); i++) {
        this.otherInsurance.creditCardNo = this.setInsertXCharacter(this.otherInsurance.creditCardNo, (i));
      }
    }
    this.caseService.updateOtherInsurance(this.otherInsurance);
  }

  setInsertXCharacter(str, index) {
    return str.substr(0, index) + 'X' + str.substr(index + 1);
  }

  setOtherInsurance(value: HasOtherInsurance) {
    this.otherInsurance.hasOtherInsurance = value;
    this.saveModel();
  }

  get HasOtherInsurance() {
    return HasOtherInsurance;
  }
}
