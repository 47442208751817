import { CaseDocument } from '@secca/shared/models/caseDocument';
import { SupplierInvoiceService } from '@secca/core/services/supplier-invoice.service';
import { MessageChannelType, MessageFrom, PermissionEnum, ShortcutEnum, StakeholderTypeEnum, TaskType } from '@secca/shared/models/enums';
import { PendingTasksListUpdateService } from '@secca/case/components/case-task/services/pending-tasks-list-update.service';
import { CaseCommunicationService } from '@secca/core/services/case-communication.service';
import { CaseEmail } from '@secca/shared/models/case-email';
import { TaskRedirectFromGlobalTaskBoard } from '@secca/shared/models/task-redirect-from-global-task-board';
import { TaskService } from '@secca/core/services/task.service';
import { AutoUnsubscribe } from 'src/app/shared/decorators/auto-unsubscribe';
import { ModalDialogComponent } from 'src/app/shared/components/modal-dialog/modal-dialog.component';
import { ModalDialogConfiguration } from 'src/app/shared/models/modal/modal-dialog-configuration';
import { TaskStateService } from './../../services/task-state.service';
import { Task } from 'src/app/shared/models/task';
import { CaseService } from '@secca/core/services/case.service';
import { Router } from '@angular/router';
import { TaskViewModel } from './../../models/task-view.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {Component, OnInit, Input, ViewChild, TemplateRef, Output, EventEmitter, Inject, HostListener} from '@angular/core';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import { DocumentService } from '@secca/core/services/document.service';
import { AttachmentDto } from '@secca/shared/models/attachment-dto';
import { CaseLockOverlayAction, LockContextEnum } from '@secca/shared/enums/lock-context.enum';
import { PermissionService } from '@secca/core/services/permission.service';
import { DialogHelperUtilService } from '@secca/core/services/dialog-helper-util.service';
import { TaskModalModeEnum } from '@secca/case/components/case-task/task-modal-static/task-modal-static-mode.enum';
import { TaskModalCommon } from '@secca/shared/components/task-modal-common/task-modal-common.component';
import { OutputManagementService } from '@secca/case/components/case-output-management/services/output-management.service';
import { CaseMessageService } from '@secca/core/services/case-message.service';
import { DialogViewerServiceInterface } from '@secca/core/services/dialog-viewer-service-interface';
import { PhoneService } from '@secca/core/services/phone.service';
import { DIALOG_VIEWER_TOKEN } from '@secca/core/services/token';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { DialogStateService } from '@secca/core/state-services/dialog-state.service';
import { Case } from '@secca/shared/models/case';
import { Person } from '@secca/shared/models/person';
import { PhoneDto } from '@secca/shared/models/phone-dto';
import { PostHandlingsDto } from '@secca/shared/models/post-handlings-dto';
import { ServiceOrderMessageRequest } from '@secca/shared/models/service-order/service-order-message-request';
import { Message } from '@secca/case/components/case-output-management/models/message';
import {FaxDto} from '@secca/shared/models/fax-dto';
import { Subscription } from 'rxjs';
import { ShortcutService } from '@secca/core/services/shortcut.service';
import { SearchCase } from '@secca/shared/models/searchCase';
import moment from 'moment-timezone';
import {CaseHistoryCommEntry} from '@secca/shared/models/caseHistoryEntry';
import {CaseHistoryType} from '@secca/shared/enums/case-history-type';

@AutoUnsubscribe
@Component({
  selector: 'app-task-message-modal',
  templateUrl: './task-message-modal.component.html',
  styleUrls: ['./task-message-modal.component.scss'],
})
export class TaskMessageModalComponent implements OnInit {
  @ViewChild('taskModal') taskModal: TaskModalCommon;
  @ViewChild(ModalDialogComponent) modalDialogComponent: ModalDialogComponent;
  @ViewChild('confirmationDialog') confirmationDialog: TemplateRef<any>;

  @Input() invokedFromTheCase: boolean;

  @Input() set taskViewModel(value: TaskViewModel) {
    // Angular does ngOnCheck in undeterministic way, usually at the beginning of the component initialization and user interaction.
    // In such case input parameter is updated (it may be the same value) and then the setter is called and stakeholders are fetched.
    // To avoid doing it multiple times I added this check.
    if (
      this._taskViewModel !== null &&
      this._taskViewModel !== undefined &&
      value !== undefined &&
      value !== null &&
      value.id === this._taskViewModel.id
    ) {
      return;
    }
    this._taskViewModel = value;
    if (value != null && value.taskType === TaskType.suplierInvoice) {
      this.getSupplierInvoiceInfo(value);
    }
    if (value != null && this.stakeholders.length === 0) {
      this.getStakeholders();
    }
  }
  get taskViewModel(): TaskViewModel {
    return this._taskViewModel;
  }
  @Output() supplierInvoiceMovedEvent = new EventEmitter<number>();
  @Output() taskUpdatedEvent = new EventEmitter<number>();
  @Output() closed = new EventEmitter<void>();

  selectedStakeholder: CaseStakeholder;
  oldSelectedStakeholderId: string = '';
  moveModalRef: NgbModalRef;
  isMoveToCaseNumberValid = false;
  private _taskViewModel: TaskViewModel;

  get task(): Task {
    if (this.taskModal) {
      return this.taskModal.task;
    } else {
      return null;
    }
  }


  case: Case;
  _entry: CaseHistoryCommEntry;
  get entry(): CaseHistoryCommEntry {
    return this._entry as CaseHistoryCommEntry;
  }


  get PermissionEnum() {
    return PermissionEnum;
  }

  get isCaseLocked(): boolean {
    return this.caseStateService.isCaseDisabled();
  }

  stakeholders: CaseStakeholder[] = [];
  oldTask: Task;
  caseEmail: CaseEmail;
  oldCaseEmail: CaseEmail;
  isHtmlBody: boolean;
  emailBody: string;
  wereChanges: boolean;
  deleteReason: string;

  supplierInvoiceInfo: AttachmentDto;
  showServiceOrder = false;

  private shortcutSubscriptions: Subscription[] = [];

  constructor(
    private modalService: NgbModal,
    private dialogHelperUtilService: DialogHelperUtilService,
    private router: Router,
    private caseService: CaseService,
    private taskStateService: TaskStateService,
    private taskService: TaskService,
    private caseCommunicationService: CaseCommunicationService,
    private pendingTasksListUpdateService: PendingTasksListUpdateService,
    public caseLockHelperService: CaseLockHelperService,
    public permissionService: PermissionService,
    private documentService: DocumentService,
    private supplierInvoiceService: SupplierInvoiceService,
    @Inject(DIALOG_VIEWER_TOKEN) private dialogViewerService: DialogViewerServiceInterface,
    private caseMessageService: CaseMessageService,
    private outputManagementService: OutputManagementService,
    private dialogStateService: DialogStateService,
    private phoneService: PhoneService,
    public caseStateService: CaseStateService,
    private shortcutService: ShortcutService,
  ) {
    this.shortcutSubscriptions.push(
      this.shortcutService.addShortcut({ keys: ShortcutEnum.BoardNavigateToTheSearchOrCreateCase }).subscribe(a => {
        if (!this.isOnCasePage && this.hasCaseNumber) {
          this.goToCase();
        } else if (!this.isOnCasePage && !this.hasCaseNumber) {
          this.goToSearchCase();
        }
      }),
    );
  }

  taskLoadedNotification(loaded: boolean): void {
    this.loadData(this.task);
  }

  private loadData(task: Task): void {
    this.emailBody = null;
    if (task != null) {
      this.oldTask = new Task();
      Object.assign(this.oldTask, task);
      if (this.getCurrentTaskOriginType() === TaskOriginType.EMAIL) {
        this.loadEmailData(task);
      }
      if (this.getCurrentTaskOriginType() === TaskOriginType.FAX) {
        this.loadFaxData(task);
      }
      if (this.getCurrentTaskOriginType() === TaskOriginType.SMS_MMS) {
        this.loadSmsData(task);
      }
    }
  }

  private setCaseEmail(caseEmail: CaseEmail) {
    this.caseEmail = caseEmail;
    this.oldCaseEmail = new CaseEmail();
    this.isHtmlBody = caseEmail.contentType ? caseEmail.contentType !== 'TEXT' : undefined;
    Object.assign(this.oldCaseEmail, this.caseEmail);
    this.tryToMatchStakeholder();
  }

  private loadEmailData(task: Task) {
    this.caseCommunicationService.getCaseEmail(task.emailId).subscribe(
      result => {
        this.setCaseEmail(result);
      },
      error => console.log(error)
    );

    this.caseCommunicationService.getEmailBody(task.emailId).subscribe(
      result => {
        this.emailBody = result;
        if (this.isHtmlBody === undefined) {
          this.isHtmlBody = this.isHtml(this.emailBody);
        }
      },
      error => console.log(error)
    );
  }

  private isHtml(body: string): boolean {
    let doc = new DOMParser().parseFromString(body, "text/html");
    return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
  }

  private loadFaxData(task: Task) {
    this.caseCommunicationService.getCaseFax(task.faxId).subscribe(
      result => {
        this.setCaseEmail(result);
      },
      error => console.log(error)
    );
    this.caseCommunicationService.getEmailBody(task.faxId).subscribe(
      result => {
        this.emailBody = result;
        if (this.isHtmlBody === undefined) {
          this.isHtmlBody = this.isHtml(this.emailBody);
        }
      },
      error => console.log(error)
    );
  }

  private loadSmsData(task: Task) {
    this.caseCommunicationService.getCaseMessage(task.messageId).subscribe(
      result => {
        this.setCaseEmail(result);
        this.emailBody = task.message;
      },
      error => console.log(error)
    );
  }

  ngOnInit() {}

  closeTaskMessageDialog() {
    if (this.modelChanged()) {
      this.openLeaveWarningDialog();
    } else {
      this.close();
    }
  }

  close() {
    if (this.closed.observers) {
      this.closed.emit();
    }
    this.dialogHelperUtilService.closeModal();
  }

  get TaskModalModeEnum(): any {
    return TaskModalModeEnum;
  }

  get LockContextEnum(): any {
    return LockContextEnum;
  }

  get CaseLockOverlayAction(): any {
    return CaseLockOverlayAction;
  }

  goToCase(): void {
    this.taskService.taskRedirectFromGlobalTaskBoard = new TaskRedirectFromGlobalTaskBoard({
      caseId: this.taskViewModel.caseId,
      taskId: +this.taskViewModel.id,
      taskStatus: this.taskViewModel.status,
      directRedirection: true,
    });
    this.router.navigate(['case', this.taskViewModel.caseId]);
    this.closeTaskMessageDialog();
  }

  goToSearchCase() {
    const searchCase = new SearchCase({
      customer: null,
      stakeholderSearch: StakeholderTypeEnum.endUser,
      caseCreationDateFrom: moment().subtract(6, 'months'),
      channel: this.task?.channelType
    });

    const dialogRef = this.dialogViewerService.openUnrelatedTaskSearchCaseDialog(searchCase, this.taskViewModel, () => this.closed.emit());
    if ( dialogRef ) {
      setTimeout(() => {
        this.dialogStateService.sendToFront(dialogRef.id);
      }, 0);
    }
  }

  private getStakeholders() {
    this.caseService.getCaseStakeholdersOnCase(this.taskViewModel.caseId.toString()).subscribe(result => {
      this.stakeholders = result;
      this.tryToMatchStakeholder();
    });
  }

  private tryToMatchStakeholder() {
    if (this.caseEmail != null && this.stakeholders.length > 0 && this.caseEmail.stakeholderSenderId != null) {
      this.selectedStakeholder = this.stakeholders.find(a => +a.id === this.caseEmail.stakeholderSenderId);
      this.oldSelectedStakeholderId = this.selectedStakeholder && this.selectedStakeholder.id;
    }
  }

  private getSupplierInvoiceInfo(value: TaskViewModel) {
    this.supplierInvoiceInfo = null;
    if (value.invoiceId) {
      this.supplierInvoiceService.getSupplierInvoice(this._taskViewModel.invoiceId).subscribe(supplierInvoice => {
        this.documentService
          .getCaseDocument(supplierInvoice.invoiceDocumentId)
          .subscribe(caseDocument => this.mapCaseDocumentToSupplierInvoiceInfo(caseDocument));
      });
    } else {
      this.documentService.getCaseDocumentList(this.taskViewModel.caseId).subscribe(result => {
        if (result.length > 0) {
          this.mapCaseDocumentToSupplierInvoiceInfo(result[0]);
        }
      });
    }
  }

  private mapCaseDocumentToSupplierInvoiceInfo(caseDocument: CaseDocument) {
    this.supplierInvoiceInfo = new AttachmentDto({
      documentName: caseDocument.documentName,
      documentExtension: caseDocument.documentExtension,
      documentId: caseDocument.documentId,
    });
  }

  modelChanged(): boolean {
    if (this.oldTask == null || this.taskModal == null || this.taskModal.task == null) {
      return false;
    }
    if (this.taskModal.task.taskType != TaskType.suplierInvoice && (this.oldCaseEmail == null || this.caseEmail == null)) {
      return false;
    }
    return this.taskChanged || this.emailChanged;
  }

  isDisabled(): boolean {
    return !this.wereChanges || (!this.taskModal?.task?.adtCode && !this.taskModal?.task?.userId);
  }

  checkIfThereWereChanges() {
    this.wereChanges = this.modelChanged();
  }

  get taskChanged(): boolean {
    return (
      this.oldTask.status !== this.taskModal.task.status ||
      this.oldTask.userId !== this.taskModal.task.userId ||
      this.oldTask.adtCode !== this.taskModal.task.adtCode ||
      this.oldTask.title !== this.taskModal.task.title ||
      this.oldTask.dueDate !== this.taskModal.task.dueDate ||
      this.oldTask.priority !== this.taskModal.task.priority
    );
  }

  get emailChanged(): boolean {
    return (
      this.taskModal.task.taskType != TaskType.suplierInvoice &&
      (this.oldCaseEmail.internalSummary !== this.caseEmail.internalSummary ||
        (this.selectedStakeholder != null && this.selectedStakeholder.id !== this.oldSelectedStakeholderId))
    );
  }

  informTaskHasChanged() {
    this.taskStateService.refreshAllTasksAndPendingNumberOfTasks.next();
  }

  saveTaskChanged() {
    if (this.taskChanged) {
      this.taskService.updateTask(this.taskModal.task).subscribe(
        result => {
          this.taskUpdatedEvent.emit(this.task.id);
          Object.assign(this.oldTask, this.task);
          if (this.isOnCasePage) {
            this.taskStateService.refreshToDoTasksAfterSavingFromEmailView.next();
            this.informTaskHasChanged();
          }
          if (this.emailChanged) {
            this.saveEmailChanged();
          } else {
            this.close();
          }
        },
        error => console.log(error)
      );
    } else if (this.emailChanged) {
      this.saveEmailChanged();
    }
  }

  saveEmailChanged(): void {
    this.caseEmail.emailId = this.task.emailId;
    this.caseEmail.stakeholderSenderId = this.selectedStakeholder && +this.selectedStakeholder.id;

    if (this.getCurrentTaskOriginType() === TaskOriginType.EMAIL) {
      this.caseCommunicationService.updateCaseEmail(this.caseEmail).subscribe(
        result => {
          this.setUpdatedCaseEmail();
          this.close();
        },
        error => console.log(error)
      );
    } else if (this.getCurrentTaskOriginType() === TaskOriginType.FAX) {
      this.caseCommunicationService.updateCaseFax(this.caseEmail).subscribe(
        result => {
          this.setUpdatedCaseEmail();
          this.close();
        },
        error => console.log(error)
      );
    } else if (this.getCurrentTaskOriginType() === TaskOriginType.SMS_MMS) {
      this.caseCommunicationService.updateCaseMessage(this.caseEmail).subscribe(
        result => {
          this.setUpdatedCaseEmail();
          this.close();
        },
        error => console.log(error)
      );
    }
  }

  private setUpdatedCaseEmail() {
    Object.assign(this.oldCaseEmail, this.caseEmail);
    this.oldSelectedStakeholderId = this.selectedStakeholder && this.selectedStakeholder.id;
  }

  get TaskOriginType() {
    return TaskOriginType;
  }

  get isOnCasePage(): boolean {
    return this.invokedFromTheCase || this.router.url.startsWith('/case');
  }

  get hasCaseNumber(): boolean {
    return !!this.taskViewModel.caseNumber;
  }

  getCurrentTaskOriginType(): TaskOriginType {
    if (this.task == null) {
      return null;
    }
    if (this.task.messageId != null) {
      return TaskOriginType.SMS_MMS;
    }
    if (this.task.faxId != null) {
      return TaskOriginType.FAX;
    }
    if (this.task.emailId != null) {
      return TaskOriginType.EMAIL;
    }
    return null;
  }

  getCurrentMessageChannelType(): MessageChannelType {
    if (this.task == null) {
      return null;
    }
    if (this.task.messageId != null) {
      return MessageChannelType.SMS
    }
    if (this.task.faxId != null) {
      return MessageChannelType.FAX;
    }
    if (this.task.emailId != null) {
      return MessageChannelType.EMAIL;
    }
    return null;
  }

  openLeaveWarningDialog() {
    const modalRef = this.modalService.open(ModalDialogComponent);
    modalRef.componentInstance.items = [];
    modalRef.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'default-modal-header',
      title: 'case-tasks-leave-edited-task-or-go-back-and-save-pop-up',
      text: null,
      footer: 'case-validation-warning-modal-footer',
      yes: 'default-modal-dialog-yes',
      no: 'default-modal-dialog-no',
      isBody: true,
      isFooter: true,
    });
    modalRef.componentInstance.closeModalEvent.subscribe(
      closedClickingYes => {
        if (closedClickingYes) {
          this.close();
        }
        modalRef.close();
      },
      error => console.log(error)
    );
  }

  openMoveTaskModal() {
    this.isMoveToCaseNumberValid = true;
    this.showServiceOrder = false;
    this.dialogViewerService.openTaskMessageMoveDialog(this.showServiceOrder, this.isMoveToCaseNumberValid, null, evt => this.moveCaseNumberEntered(evt), evt => this.moveConfirmed(evt));
  }

  moveCaseNumberEntered(value: boolean) {
    this.isMoveToCaseNumberValid = value;
  }

  moveConfirmed(caseOrServiceOrder: string) {
    if (!this.showServiceOrder) {
      this.moveTask(this.task.id, caseOrServiceOrder);
    } else {
      this.moveSupplierInvoice(this.task.id, caseOrServiceOrder);
    }
  }

  private moveTask(taskId: number, caseNumber: string) {
    this.taskService.moveTask(taskId, caseNumber).subscribe(
      taskDto => {
        this.taskStateService.refreshTasks();
        this.modalService.dismissAll();
        this.close();
      },
      error => (this.isMoveToCaseNumberValid = false)
    );
  }

  openMoveSupplierInvoiceModal() {
    this.isMoveToCaseNumberValid = true;
    this.showServiceOrder = true;
    this.dialogViewerService.openTaskMessageMoveDialog(this.showServiceOrder, this.isMoveToCaseNumberValid, null, evt => this.moveCaseNumberEntered(evt), evt => this.moveConfirmed(evt));
  }

  private moveSupplierInvoice(taskId: number, caseNumber: string) {
    this.taskService.moveSupplierInvoice(taskId, caseNumber).subscribe(
      task => {
        this.supplierInvoiceMovedEvent.emit(task.id);
        this.modalService.dismissAll();
        this.dialogHelperUtilService.closeModal();
        this.close();
      },
      error => {
        const validationError: string = error.error?.message;
        try {
          const alreadyReceived = JSON.parse(validationError);
          this.modalService.dismissAll();
          const modalRef = this.modalService.open(ModalDialogComponent);
          modalRef.componentInstance.configuration = new ModalDialogConfiguration({
            header: 'task-message-modal-supplier-invoice-ident-header',
            title: 'task-message-modal-supplier-invoice-ident-title',
            translateText: false,
            text:
              'Service order ' +
              alreadyReceived.serviceOrder +
              ', Invoice no. ' +
              alreadyReceived.invoiceNumber +
              ', Task id ' +
              this.task.id +
              ', Ident ' +
              alreadyReceived.supplierInvoiceIdent,
            footer: '',
            isFooter: true,
            isBody: true,
            yes: 'task-message-modal-supplier-invoice-ident-ok',
          });
          modalRef.componentInstance.closeModalEvent.subscribe(closedClicked => modalRef.close());
        } catch (e) {
          this.isMoveToCaseNumberValid = false;
        }
      }
    );
  }

  get TaskType() {
    return TaskType;
  }



  openMessageModal(sendToIsReporter: boolean = false, doneTask: boolean, deleteTask: boolean) {
    const message = new Message();
    message.caseId = '' + this.taskViewModel.caseId;
    message.messageChannelType = this.getCurrentMessageChannelType();
    message.messageFrom = MessageFrom.EMAIL_SENDER_SOS;
    message.preSelectDocumentAttachments = this.caseEmail.attachments;
    message.replyEmailId = this.caseEmail.messageId ? this.caseEmail.messageId : (this.caseEmail.emailId ? this.caseEmail.emailId : this.caseEmail.faxId);
    message.subject = 'RE: ' + this.caseEmail.subject;
    message.subjectLock = true;
    message.caseNumber = ' ';
    message.fax = new FaxDto();
    message.receiverIsNotChangable = true;
    message.sms = this.getCurrentMessageChannelType() === MessageChannelType.SMS ? new PhoneDto() : null;
    if (sendToIsReporter) {
      if (this.caseEmail.emailId) {
        message.email = this.caseEmail.from;
      } else if (this.caseEmail.messageId) {
        message.sms = this.phoneService.extractPhoneDto(this.caseEmail.from);
      }
    }
    message.receiver = new CaseStakeholder({person: new Person({
        preferredLanguageCode: 'en'}),
        ...this.caseEmail.stakeholderSenderId && { id: '' + this.caseEmail.stakeholderSenderId }});
    message.postHandlings = new PostHandlingsDto({
      messageTaskToRemove: deleteTask ? Number(this.taskViewModel.id) : null,
      taskToDone: doneTask ? Number(this.taskViewModel.id) : null,
      removeSentMail: deleteTask,
      deleteReason: this.deleteReason,
      sendToReporter: sendToIsReporter});

    const srMessage = new ServiceOrderMessageRequest({
      messageService: this.caseMessageService,
      message });

    let matDialogRef = this.dialogViewerService.openOutputManagementDialog(
      new Case({id: ''+this.taskViewModel.caseId,
        caseNumber: this.taskViewModel.caseNumber}),
        message.messageChannelType,
        srMessage,
        null,
        () => this.handleMessageSent(),
        true);

    setTimeout(() => this.dialogStateService.sendToFront(matDialogRef.id), 0);

  }

  openModal() {
    const modalRef = this.modalService.open(ModalDialogComponent);
    modalRef.componentInstance.items = [];
    modalRef.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'default-modal-header',
      title: 'case-summary-modal-gdpr-delete',
      text: '',
      footer: 'case-summary-modal-gdpr-delete-proceed',
      showYesNoButton: false,
      yes: 'Yes',
      no: 'No',
      labelTextAboveTextArea: 'case-summary-modal-gdpr-delete-reason-label',
      placeholderText: 'case-summary-modal-gdpr-delete-reason-placeholder',
      showTextAreaWithYesNo: true,
      showTextArea: true,
      isBody: true,
      isFooter: true,
      modalYesHoverDialog: 'case-summary-modal-gdpr-delete-yes-hover'
    });

    modalRef.componentInstance.saveandcloseEvent.subscribe(
      emittedEvent => {
        this.closeModal();
        if (emittedEvent) {
          this.deleteReason = emittedEvent;
          this.openMessageModal(true, false, true);
        }

      },
      error => { }
    );

    modalRef.componentInstance.closeModalEvent.subscribe(
      emittedEvent => {
        this.closeModal();
      },
      error => console.log('error', error)
    );
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  private handleMessageSent(): void {
    setTimeout(() => {
      this.taskStateService.refreshTasks();
      this.modalService.dismissAll();
      this.close();
    }, 1000);
  }
}

enum TaskOriginType {
  EMAIL,
  FAX,
  SMS_MMS,
}
