<div class="card">
  <div class="sub-component-placeholder">
    <div class="sub-component-container" >
      <div class="sub-title">{{"general-advice-advice-given-title" | translate}}</div>
      <div class="sub-content-container">
        <app-text-box [name]="'general-advice-advice-given-label' | translate"
                      [maxTextLength]="1000"
                      [required]="true"
                      [(value)]="advice.adviceGiven">
        </app-text-box>
      </div>
    </div>
  </div>
  <div class="sub-component-placeholder">
    <div class="col-5 p-0">
      <app-input-date-time
        [name]="'general-advice-start-date' | translate"
        (dateChange)="startDateChange($event)"
        [required]="true"
        [withImage]="true"
        [date]="advice.startDate"
        [timeZone]="'secca'"
      >
      </app-input-date-time>
    </div>
</div>
