import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InputTypePattern } from '@secca/shared/models/enums';

@Component({
  selector: 'app-input-with-value-type',
  templateUrl: './input-with-value-type.component.html',
  styleUrls: ['./input-with-value-type.component.scss']
})
export class InputWithValueTypeComponent implements OnInit {

  @Input() required: boolean;
  @Input() name: string;
  @Input() value: number;
  @Input() placeholder: string;
  @Input() valueType: string;
  @Input() disabled: boolean;
  @Input() mask: string;
  @Output() valueChange = new EventEmitter();
  @Output() saveModel = new EventEmitter();
  

  ngOnInit() {
  }

  onSaveModel() {
    this.valueChange.emit(Number(this.value));
  }

  onChange() {
    this.valueChange.emit(Number(this.value));
  }

  get InputTypePattern() {
    return InputTypePattern;
  }
}
