import { Component, EventEmitter, Output, Input } from '@angular/core';
import { CaseService } from '@secca/core/services/case.service';
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  catchError,
  tap
} from "rxjs/operators";
import { Subject, asapScheduler, scheduled, Observable, Subscription, of } from "rxjs";
import { Case } from '@secca/shared/models/case';
import { CaseTypesService } from '@secca/core/services/case-type.service';
import { ServiceOrderService } from '@secca/core/services/service-order.service';
import { ServiceOrder } from '@secca/shared/models/service-order/service-order';
import { CaseType } from '@secca/shared/models/case-type';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { OnInit } from '@angular/core';
import { ServiceOrderType } from '@secca/shared/models/service-order-type';

@Component({
  selector: 'app-task-message-modal-move-task',
  templateUrl: './task-message-modal-move-task.component.html',
  styleUrls: ['./task-message-modal-move-task.component.scss'],
})
@AutoUnsubscribe
export class TaskMessageModalMoveTaskComponent implements OnInit {
  @Input() showServiceOrder: boolean;
  @Input() taskCaseId: string;
  @Input() 
  set isCaseNumberValid(caseNumberValid: boolean) {
    this._isCaseNumberValid = caseNumberValid;
    if (!caseNumberValid && this.moveInProgress) {
      this.moveInProgress = false;
    }
  }
  get isCaseNumberValid(): boolean {
    return this._isCaseNumberValid;
  };
  @Output() moveConfirmedEvent = new EventEmitter<string>();
  @Output() caseNumberEnteredEvent = new EventEmitter<boolean>();
  @Output() closed = new EventEmitter<void>();

  caseNumber: string;
  moveInProgress = false;
  moveToCase: Case | undefined | null;
  moveToServiceOrder: ServiceOrder | undefined | null;
  taskCaseType: CaseType;
  caseType: CaseType;
  serviceOrderTypes: ServiceOrderType[];

  private _isCaseNumberValid: boolean;
  private caseNumberRegexStrict: RegExp = /^[A-Z]{4}(-\d{3}){3}$/;
  private serviceOrderRegexStrict: RegExp = /^([A-Z]{4}(?:-\d{3}){3})-([A-Z]+)$/;
  private caseNumberServiceOrderRegex: RegExp = /([A-Z]{4}(?:-\d{3}){3})(?:-([A-Z]+)|)/;
  private caseNumberChangeEvent = new Subject();
  private $inputSubscr: Subscription;

  constructor(private caseService: CaseService,
              private caseTypeService: CaseTypesService,
              private serviceOrderService: ServiceOrderService) {

    this.$inputSubscr = scheduled(this.caseNumberChangeEvent, asapScheduler).pipe(
      debounceTime(50),
      distinctUntilChanged(),
      switchMap((caseNumber:string) => {
        caseNumber = caseNumber?.toUpperCase();
        const caseMatch = caseNumber?.match(this.caseNumberServiceOrderRegex);
        if ( !!caseMatch ) {
          const observable = (caseMatch[1] !== this.moveToCase?.caseNumber) ? this.getCase(caseMatch[1]) : of(this.moveToCase);
          return observable.pipe(
            tap(caseDto => {
              if ( !!caseMatch[2]) {
                this.getServiceOrder(caseDto.id, caseMatch[2]);
              }
              else {
                this.moveToServiceOrder = undefined;
              }
            }),
            catchError(() => of(null))
          );
        }
        else {
          return of(undefined);
        }
      })
    ).subscribe((caseDto: any) =>  
        this.moveToCase = caseDto
    );
  }

  ngOnInit() {
    if ( !!this.taskCaseId ) {
      this.caseTypeService.getCaseTypeByCaseId(this.taskCaseId).subscribe(caseType => {
        this.taskCaseType = caseType;
      });
    }

    this.serviceOrderService.getAllServiceOrderTypes().subscribe(result => {
      this.serviceOrderTypes = result;
    })
  }

  setDirty() {
    this.caseNumber = this.caseNumber?.toUpperCase();
  }

  moveTask() {
    this.moveInProgress = true;
    this.moveConfirmedEvent.emit(this.caseNumber);
  }

  setCaseNumberValid(valid: boolean) {
    this.isCaseNumberValid = valid;
  }

  closeTaskMessageDialog() {
    this.moveInProgress = false;
    this.closed.emit();
  }

  caseNumberChange(event: string) {
    this.caseNumberChangeEvent.next(event);
  }

  isValidInput(): boolean {
    return this.regExp.test(this.caseNumber) && this.isCaseNumberValid;
  }

  canMove(): boolean {
    if (this.showServiceOrder) {
      return this.serviceOrderExists() && this.canMoveToCase();
    }
    else {
      return this.caseExists() && this.canMoveToCase();
    }
  }

  caseExists(): boolean {
    return !!this.moveToCase && this.moveToServiceOrder !== null;
  }

  serviceOrderExists(): boolean {
    return !!this.moveToCase && !!this.moveToServiceOrder;
  }

  canMoveToCase(): boolean {
    return !!this.taskCaseType ? !!this.taskCaseType?.allowableCaseTypes?.find(allowed => allowed.code === this.moveToCase?.caseTypeCode) || this.taskCaseType?.code === this.moveToCase?.caseTypeCode : true;
  }

  getServiceOrderTypeName(serviceOrderType: string): string {
    return this.serviceOrderTypes.find(s => s.serviceTypeEnumValue == serviceOrderType)?.serviceTypeName || serviceOrderType;
  }

  private getCase(caseNumber: string): Observable<Case> {
    return this.caseService.getCase(caseNumber);
  }

  private getServiceOrder(caseId: string, serviceOrderId: string): void {
    this.serviceOrderService.getServiceOrderByCaseIdAndServiceOrderid(+caseId, serviceOrderId.toUpperCase()).pipe(
      catchError(() => of(null))
    )
    .subscribe(
      result => this.moveToServiceOrder = result
    )
  }

  private get regExp() {
    if (this.showServiceOrder) {
      return this.serviceOrderRegexStrict;
    } else {
      return this.caseNumberRegexStrict;
    }
  }
}
