import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
import * as moment from 'moment';
import { DateSerializer } from './date-serializer';

export class ReasonForCancellation {
  id: number;
  reasonForCancellationCode: string;
  reasonForCancellationName: string;
  modalType: string;
  uiSortIndex: number;
  activateOn: moment.Moment;
  deactivateOn: moment.Moment;
  active: boolean;

  public constructor(init?: Partial<ReasonForCancellation>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
export class ReasonForCancellationAdapter implements Adapter<ReasonForCancellation> {
  adapt(item: any): ReasonForCancellation {
    return new ReasonForCancellation({
      id: item.id,
      reasonForCancellationCode: item.reasonForCancellationCode,
      reasonForCancellationName: item.reasonForCancellationName,
      modalType: item.modalType,
      uiSortIndex: item.uiSortIndex,
      activateOn: DateSerializer.deserialize(item.activateOn),
      deactivateOn: DateSerializer.deserialize(item.deactivateOn),
      active: item.active
    });
  }
}
