import { Component, Inject, ViewChild } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { DialogBoundryService } from "@secca/core/services/dialog-boundry.service";
import { DialogHelperUtilService } from "@secca/core/services/dialog-helper-util.service";
import { DialogStateService } from "@secca/core/state-services/dialog-state.service";
import { DialogMinimizableDirective } from "@secca/shared/directives/dialog-minimizable.directive";
import { DialogSelectableDirective } from "@secca/shared/directives/dialog-selectable.directive";
import { MinimizableDialogType } from "@secca/shared/enums/minimizable-dialog-type-enum";
import { IAdditionalInfo, TaskAdditionalInfo } from "@secca/shared/interfaces/additional-info";
import { IFocusEnabled } from "@secca/shared/interfaces/focus-enabled";
import { IKeyboardEnabled } from "@secca/shared/interfaces/keyboard-enabled";
import { IMinimizable } from "@secca/shared/interfaces/minimizable";
import { TaskType } from "@secca/shared/models/enums";
import { AddServiceOrderDialogComponent } from "../../case-plans/case-plans/add-service-order/add-service-order-dialog/add-service-order-dialog.component";
import { TaskModalModeEnum } from "../task-modal-static/task-modal-static-mode.enum";
import { TaskModalStaticComponent } from "../task-modal-static/task-modal-static.component";
import { TaskDialogData } from "./task-dialog-data.component";

@Component({
    selector: 'app-task-dialog',
    templateUrl: './task-dialog.component.html',
    styleUrls: ['./task-dialog.component.scss'],
  })
  export class TaskDialogComponent implements IAdditionalInfo, IMinimizable, IKeyboardEnabled, IFocusEnabled {
    @ViewChild(TaskModalStaticComponent) wrappedInstance: TaskModalStaticComponent;
    @ViewChild(DialogMinimizableDirective) minimizedDirective: DialogMinimizableDirective;
    @ViewChild(DialogSelectableDirective) selectableDirective: DialogSelectableDirective;

    data: TaskDialogData

    constructor(private dialogRef: MatDialogRef<TaskDialogComponent>,
                @Inject(MAT_DIALOG_DATA) data: TaskDialogData,
                private dialogHelperUtilService: DialogHelperUtilService,
                private dialogStateService: DialogStateService,
                public dialogBoundryService: DialogBoundryService) {
      this.data = data;
    }

    keyboardClose(): void {
      this.wrappedInstance.close();
    }

    close() {
      this.dialogHelperUtilService.close(this.dialogRef, this.getAdditionalInfo()?.caseId);
    }

    minimize() {
      this.minimizedDirective.minimize();
    }

    getMinimizeType(): MinimizableDialogType {
      return this.minimizedDirective.appDialogMinimizable;
    }

    getAdditionalInfo(): TaskAdditionalInfo {      
      const caseId: number = this.wrappedInstance?.taskViewModel?.caseId;
      const caseNumber: string = this.wrappedInstance?.taskViewModel?.caseNumber;
      const taskTitle: string = this.wrappedInstance?.taskViewModel?.title;
      const taskType = this.wrappedInstance?.taskViewModel?.taskType;
      const taskStatus = this.wrappedInstance?.taskViewModel?.status;

      return {
        caseId,
        hoverTitle: caseNumber || '',
        hoverText: taskTitle || '',
        taskType,
        taskStatus
      };
    }

    getFocusElement(): any {
      if ( this.wrappedInstance?.task?.taskType === TaskType.manual || this.data.taskModalMode === TaskModalModeEnum.addNew ) {
        return this.selectableDirective?.focusElement;
      }
    }

    onOpenServiceEditDialog(serviceOrderId: number) {
      const caseId: number = this.wrappedInstance?.taskViewModel?.caseId;
      const caseNumber: string = this.wrappedInstance?.taskViewModel?.caseNumber;

      const dialogRef: MatDialogRef<any> = this.openServiceOrderDialog(serviceOrderId, ''+caseId, caseNumber, null);
      if ( dialogRef ) {
        setTimeout(() => {
          this.dialogStateService.sendToFront(dialogRef.id);
        }, 0);
      }
    }
  
    openServiceOrderDialog(serviceOrderId: number, caseId: string, caseNumber: string, incidentId: number): MatDialogRef<any> {
      const data = {
        serviceOrderId,
        caseId,
        caseNumber,
        incidentId,
      };
  
      const dialogConfig = {
        id: serviceOrderId ? `ServicePlan-${serviceOrderId}` : undefined,
        width: '1560px',
        data,
      };
  
      return this.dialogHelperUtilService.open(AddServiceOrderDialogComponent, dialogConfig);
    }
  
    get TaskModalModeEnum() {
      return TaskModalModeEnum;
    }    

    get MinimizableDialogType() {
      return MinimizableDialogType;
    }
}
