import { ServiceTypeBase } from '../../models/serviceTypeBase';
import { Component, Input } from '@angular/core';
import { ServiceTypeComponent } from '../../models/interfaces';
import { AdviceCallRegistrationServiceOrderExtension } from '@secca/shared/models/service-order/advice-call-registration-service-order-extension';

@Component({
  selector: 'app-advice-call-registration',
  templateUrl: './advice-call-registration.component.html',
})
export class AdviceCallRegistrationComponent extends ServiceTypeBase implements ServiceTypeComponent {
  @Input() adviceCall: AdviceCallRegistrationServiceOrderExtension;
  @Input() leftSideValid = false;
  @Input() caseId: string;


  constructor() {
    super();
  }

  startDateChange(date): void {
    this.adviceCall.startDate = date;
  }

  isValid(): boolean {
    if (this.adviceCall.isValid()){
      this.leftSideValid = true;
    }
    return this.adviceCall.isValid();
  }

  getModel(): AdviceCallRegistrationServiceOrderExtension {
    return this.adviceCall;
  }

}
