import { Escort, EscortAdapter } from '@secca/shared/models/medical-models/escort';
import { Oxygen, OxygenAdapter } from '@secca/shared/models/medical-models/oxygen';
import {
  CastSideEnum,
  EquipmentItemEnum,
  EscortEnum,
  ExtraSpaceEnum,
  InputType,
  InputTypePattern,
  IntensiveCareStepCategoryEnum,
  MedicalEscortSpecialtyEnum,
  MedicationItemEnum,
  OxygenAvailabilityEnum,
  RepatriationFormEnum,
  RepatriationMediumEnum,
  TransitEnum,
  WheelchairNeedEnum
} from '@secca/shared/models/enums';
import { RepatriationInstructionsBase } from '@secca/shared/models/medical-models/repatriation-instructions-base';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import { Icd10Code } from '@secca/shared/models/icd10Code';
import { SourceUserId, SourceUser } from '../medical-assessment/medical-assessment-source/source-user';
import { TranslateService } from '@ngx-translate/core';
import { MedicalAssessmentStateService } from '@secca/core/services/medical-assessment-state.service';
import { MedicalAssessmentState, SelectItem } from '../medical-components/medical-models';
import { RepatriationExtraSpace } from '@secca/shared/models/medical-models/repatriation-extra-space';
import { Medication } from '@secca/shared/models/medical-models/medication';
import { Equipment } from '@secca/shared/models/medical-models/equipment';
import {
  RepatriationAlternativeForm
} from '@secca/shared/models/medical-models/repatriation-alternative-form';
import { DateHelperService } from '@secca/core/services/date-helper.service';
import * as moment from 'moment';

@Component({
  selector: 'app-repatriation-instructions',
  templateUrl: './repatriation-instructions.component.html',
  styleUrls: ['./repatriation-instructions.component.scss']
})
export class RepatriationInstructionsComponent implements OnInit {

  @Input() repatriationInstructions: RepatriationInstructionsBase;
  @Input() selectedSymptoms: Icd10Code[] = [];
  @Input() selectedDiagnosis: Icd10Code[] = [];
  @Input() caseID: number;
  @Input() dateOfDiagnosis: Date;
  @Input() disabledViewOfMedicalAssessment: boolean;
  @Input() copyOfExistingAssessment: boolean;
  @Input() users: SourceUser;
  @Output() formValidChanged = new EventEmitter();
  stateValues: MedicalAssessmentState;
  oxygenAvailabilityInFlight: SelectItem<OxygenAvailabilityEnum>[];
  oxygenAvailabilityOnGround: SelectItem<OxygenAvailabilityEnum>[];
  transitListFrom: SelectItem<TransitEnum>[];
  transitListTo: SelectItem<TransitEnum>[];
  extraSpaceList: SelectItem<ExtraSpaceEnum>[];
  wheelchairList: SelectItem<WheelchairNeedEnum>[];
  medicationList: SelectItem<MedicationItemEnum>[];
  equipmentList: SelectItem<EquipmentItemEnum>[];
  escortList: SelectItem<Escort>[] = [];
  castList: SelectItem<CastSideEnum>[];
  defineList: SelectItem<RepatriationMediumEnum>[];
  repatriationFormList: DropdownDictionary[];
  stepCategoryList: DropdownDictionary[];
  specializedList: DropdownDictionary[];
  expAltForms: SelectItem<RepatriationAlternativeForm>[];
  altFromExtraSpace: SelectItem<ExtraSpaceEnum>[];
  oxygenInFlight: Oxygen;
  oxygenOnGround: Oxygen;
  doctor: Escort;
  nurse: Escort;
  nonMedical: Escort;
  otherAlternativeExtraSpaceDescription = '';
  otherExtraSpaceDescription = '';
  otherMedicationDescription = '';
  otherEquipmentDescription = '';
  expAlternativeFormsValid: boolean;


  constructor(
    private assessmentSateService: MedicalAssessmentStateService,
    private translateService: TranslateService,
    private dateHelperService: DateHelperService) {
  }

  ngOnInit() {
    this.repatriationFormList = this.getDropdownListFromEnum(RepatriationFormEnum, 'medical-assessment-repatriation-form-')
      .filter(item => item.id !== RepatriationFormEnum.TOO_SOON_TO_ESTIMATE);

    this.stepCategoryList = this.getDropdownListFromEnum(IntensiveCareStepCategoryEnum, 'repatriation-intensive-care-step-category-');
    this.specializedList = this.getDropdownListFromEnum(MedicalEscortSpecialtyEnum, 'repatriation-medical-escort-specialty-');
    this.wheelchairList = this.getSingleSelectItemFromEnum(WheelchairNeedEnum, this.repatriationInstructions.patientNeeds.wheelchairNeed, 'repatriation-wheelchair-need-');
    this.extraSpaceList = this.getMultiSelectItemFromEnum(ExtraSpaceEnum, this.repatriationInstructions.repatriationForm.extraSpaces, 'repatriation-extra-space-type-');
    this.transitListTo = this.getSingleSelectItemFromEnum(TransitEnum, this.repatriationInstructions.repatriationForm.transportToAirport, 'repatriation-transit-');
    this.transitListFrom = this.getSingleSelectItemFromEnum(TransitEnum, this.repatriationInstructions.repatriationForm.transportFromAirport, 'repatriation-transit-');
    this.equipmentList = this.getMultiSelectItemFromEnum(EquipmentItemEnum, this.repatriationInstructions.patientNeeds.equipment, 'repatriation-equipment-');
    this.medicationList = this.getMultiSelectItemFromEnum(MedicationItemEnum, this.repatriationInstructions.patientNeeds.medication, 'repatriation-medication-');
    this.defineList = this.getSingleSelectItemFromEnum(RepatriationMediumEnum, this.repatriationInstructions.patientNeeds.equipmentTransportMediumType, 'repatriation-medium-');
    this.populateCastSideList();
    this.populateOxygen();
    this.populateEscort();
    this.populateAlternativeForms();

    let otherItem = this.repatriationInstructions.repatriationForm.getExtraSpace(ExtraSpaceEnum.OTHER);
    this.otherExtraSpaceDescription = !!otherItem ? otherItem.otherItemDescription : null;
    otherItem = this.repatriationInstructions.patientNeeds.getMedication(MedicationItemEnum.OTHER);
    this.otherMedicationDescription = !!otherItem ? otherItem.otherItemDescription : null;
    otherItem = this.repatriationInstructions.patientNeeds.getEquipment(EquipmentItemEnum.OTHER);
    this.otherEquipmentDescription = !!otherItem ? otherItem.otherItemDescription : null;
    otherItem = this.getAltFormOtherExtraSpace();
    this.otherAlternativeExtraSpaceDescription = !!otherItem ? otherItem.otherItemDescription : null;

    if (this.repatriationInstructions.patientState.surgeryDate) {
      this.repatriationInstructions.patientState.surgeryDate = moment.utc(this.repatriationInstructions.patientState.surgeryDate, this.dateHelperService.getDateFormat());
    }
    if (this.repatriationInstructions.repatriationForm.repatriationDate) {
      this.repatriationInstructions.repatriationForm.repatriationDate = moment.utc(this.repatriationInstructions.repatriationForm.repatriationDate, this.dateHelperService.getDateFormat());
    }
    if (this.repatriationInstructions.repatriationForm.arrivalDeadline) {
      this.repatriationInstructions.repatriationForm.arrivalDeadline = moment.utc(this.repatriationInstructions.repatriationForm.arrivalDeadline, this.dateHelperService.getDateFormat());
    }
  }

  resolveSourceId() {
    if (!!this.repatriationInstructions.treatingDoctorName && !!this.repatriationInstructions.treatingDoctorType) {
      return JSON.stringify(new SourceUserId({
        sourceName: this.repatriationInstructions.treatingDoctorName,
        sourceType: this.repatriationInstructions.treatingDoctorType
      }));
    }
    return null;
  }

  populateOxygen() {
    this.oxygenAvailabilityInFlight = this.getSingleSelectItemFromEnum(OxygenAvailabilityEnum, null,
      'repatriation-oxygen-availability-');
    this.oxygenAvailabilityOnGround = this.getSingleSelectItemFromEnum(OxygenAvailabilityEnum, null,
      'repatriation-oxygen-availability-');

    const inFlight = this.repatriationInstructions.patientNeeds.getOxygen(RepatriationMediumEnum.IN_FLIGHT);
    this.oxygenInFlight = !!inFlight ? new OxygenAdapter().adapt(inFlight) : new Oxygen({
      type: RepatriationMediumEnum.IN_FLIGHT,
      rate: 2
    });
    const onGround = this.repatriationInstructions.patientNeeds.getOxygen(RepatriationMediumEnum.ON_GROUND);
    this.oxygenOnGround = !!onGround ? new OxygenAdapter().adapt(onGround) : new Oxygen({
      type: RepatriationMediumEnum.ON_GROUND,
      rate: 2
    });

    if (!!inFlight && !!inFlight.availability) {
      this.oxygenAvailabilityInFlight.forEach(item => {
        if (item.value === inFlight.availability) {
          item.selected = true;
        }
      });
    }

    if (!!onGround && !!onGround.availability) {
      this.oxygenAvailabilityOnGround.forEach(item => {
        if (item.value === onGround.availability) {
          item.selected = true;
        }
      });
    }
  }

  populateEscort() {
    this.escortList.push(this.createEscortListItem(EscortEnum.DOCTOR));
    this.escortList.push(this.createEscortListItem(EscortEnum.NURSE));
    this.escortList.push(this.createEscortListItem(EscortEnum.NON_MEDICAL_ESCORT));

    this.escortList.forEach(item => {
      switch (item.value.type) {
        case EscortEnum.DOCTOR:
          this.doctor = item.value;
          break;
        case EscortEnum.NURSE:
          this.nurse = item.value;
          break;
        case EscortEnum.NON_MEDICAL_ESCORT:
          this.nonMedical = item.value;
          break;
        default:
          break;
      }
    });
  }

  roundOffTo(evt, numberOfDecimals: number) {
    if(evt.target.valueAsNumber) {
      if(evt.target.id === 'hemoglobin') {
        this.repatriationInstructions.patientState.hemoglobin = evt.target.valueAsNumber.toFixed(numberOfDecimals);
      } else if(evt.target.id === 'temperature') {
        this.repatriationInstructions.patientState.temperature = evt.target.valueAsNumber.toFixed(numberOfDecimals);
      } else if(evt.target.id === 'repatriation-patient-height') {
        this.repatriationInstructions.repatriationForm.patientHeight = evt.target.valueAsNumber.toFixed(numberOfDecimals);
      } else if(evt.target.id === 'repatriation-patient-weight') {
        this.repatriationInstructions.repatriationForm.patientWeight = evt.target.valueAsNumber.toFixed(numberOfDecimals);
      }
    }
  }

  private createEscortListItem(type: EscortEnum): SelectItem<Escort> {
    const escort = this.repatriationInstructions.patientNeeds.getMedicalEscort(type);
    return new SelectItem<Escort>({
      label: this.translateService.instant('repatriation-escort-' + type.toString().toLowerCase().replace(/_/g, '-')),
      value: !!escort ? new EscortAdapter().adapt(escort) : new Escort({ type }),
      selected: !!escort
    });
  }

  updateEscortObject(escort: Escort) {
    this.repatriationInstructions.patientNeeds.setMedicalEscort(escort);
  }

  updateEscort(item: SelectItem<Escort>) {
    if (item.selected) {
      this.repatriationInstructions.patientNeeds.setMedicalEscort(item.value);
    } else {
      this.repatriationInstructions.patientNeeds.removeMedicalEscort(item.value.type);
    }
  }

  populateCastSideList() {
    this.castList = this.getMultiSelectItemFromEnum(CastSideEnum, this.repatriationInstructions.repatriationForm.extraSpaces,
      'repatriation-cast-side-');
    this.castList.forEach(side => {
      if (side.value === CastSideEnum.LEFT) {
        side.selected = this.repatriationInstructions.patientState.castLeft;
      }
      if (side.value === CastSideEnum.RIGHT) {
        side.selected = this.repatriationInstructions.patientState.castRight;
      }
    });
  }

  updateOxygen(value: Oxygen, selected: boolean) {
    if (selected) {
      this.repatriationInstructions.patientNeeds.setOxygen(value);
    } else {
      this.repatriationInstructions.patientNeeds.removeOxygen(value.type);
    }
  }

  updateSourceData(selectedSourceId: string) {
    const selectedSource = JSON.parse(selectedSourceId);
    this.repatriationInstructions.treatingDoctorName = selectedSource.sourceName;
    this.repatriationInstructions.treatingDoctorType = selectedSource.sourceType;
  }

  updateExtraSpace(item: SelectItem<ExtraSpaceEnum>) {
    if (item.selected) {
      const extraSpace = new RepatriationExtraSpace({ type: item.value });
      if (item.value === ExtraSpaceEnum.OTHER) {
        extraSpace.setOtherItemDescription(this.otherExtraSpaceDescription);
      }
      this.repatriationInstructions.repatriationForm.setExtraSpace(extraSpace);
    } else {
      this.repatriationInstructions.repatriationForm.removeExtraSpace(item.value);
    }
  }

  updateEquipment(equipmentItem: SelectItem<EquipmentItemEnum>) {
    if (equipmentItem.selected) {
      const equipment = new Equipment({ item: equipmentItem.value });
      if (equipmentItem.value === EquipmentItemEnum.OTHER) {
        equipment.otherItemDescription = this.otherEquipmentDescription;
      }
      this.repatriationInstructions.patientNeeds.setEquipment(equipment);
    } else {
      this.repatriationInstructions.patientNeeds.removeEquipment(equipmentItem.value);
    }
  }

  updateMedication(medicationItem: SelectItem<MedicationItemEnum>) {
    if (medicationItem.selected) {
      const medication = new Medication({ item: medicationItem.value });
      if (medication.item === MedicationItemEnum.OTHER) {
        medication.otherItemDescription = this.otherMedicationDescription;
      }
      this.repatriationInstructions.patientNeeds.setMedication(medication);
    } else {
      this.repatriationInstructions.patientNeeds.removeMedication(medicationItem.value);
    }
  }

  updateCastSide(item: SelectItem<CastSideEnum>) {
    if (!!item) {
      if (item.value === CastSideEnum.LEFT) {
        this.repatriationInstructions.patientState.castLeft = item.selected;
      }
      if (item.value === CastSideEnum.RIGHT) {
        this.repatriationInstructions.patientState.castRight = item.selected;
      }
    }
  }

  populateAlternativeForms() {
    this.repatriationInstructions.alternativeFormStretcher.repatriationForm = RepatriationFormEnum.STRETCHER;
    this.repatriationInstructions.alternativeFormExtraSpace.repatriationForm = RepatriationFormEnum.EXTRA_SPACE;
    this.repatriationInstructions.alternativeFormEconomyClass.repatriationForm = RepatriationFormEnum.SITTING_SICK_IN_ECONOMY_CLASS_SEAT;
    this.repatriationInstructions.alternativeFormRegularPassenger.repatriationForm = RepatriationFormEnum.REGULAR_PASSENGER;
    const altExtraSpace = this.repatriationInstructions.alternativeFormExtraSpace;
    this.altFromExtraSpace = this.getMultiSelectItemFromEnum
    (ExtraSpaceEnum, !!altExtraSpace.formSelected ? altExtraSpace.extraSpaces : null, 'repatriation-extra-space-type-');
  }

  updateAltExtraSpace(item: SelectItem<ExtraSpaceEnum>) {
    if (item.selected) {
      const extraSpace = new RepatriationExtraSpace({ type: item.value });
      if (item.value === ExtraSpaceEnum.OTHER) {
        extraSpace.setOtherItemDescription(this.otherAlternativeExtraSpaceDescription);
      }
      this.repatriationInstructions.alternativeFormExtraSpace.setExtraSpace(extraSpace);
    } else {
      this.repatriationInstructions.alternativeFormExtraSpace.removeExtraSpace(item.value);
    }
  }

  private getDropdownListFromEnum(enumItem: any, prefix: string) {
    return Object.keys(enumItem)
      .map(key => new DropdownDictionary(enumItem[key],
        this.translateService.instant(prefix + key.toString().toLowerCase().replace(/_/g, '-'))));
  }

  private getMultiSelectItemFromEnum(enumItem: any, selectedPath: any[], prefix: string) {
    if (!!selectedPath) {
      return Object.keys(enumItem).map(key => {
        return new SelectItem({
          label: this.translateService.instant(prefix + key.toString().toLowerCase().replace(/_/g, '-')),
          value: enumItem[key],
          selected: !!selectedPath.find(saved => (saved.type === key || saved.item === key))
        });
      });
    } else {
      return Object.keys(enumItem).map(key => {
        return new SelectItem({
          label: this.translateService.instant(prefix + key.toString().toLowerCase().replace(/_/g, '-')),
          value: enumItem[key],
          selected: undefined
        });
      });
    }
  }

  private getSingleSelectItemFromEnum(enumItem: any, selectedPath: any, prefix: string) {
    return Object.keys(enumItem).map(key => {
      return new SelectItem({
        label: this.translateService.instant(prefix + key.toString().toLowerCase().replace(/_/g, '-')),
        value: enumItem[key],
        selected: enumItem[selectedPath] === enumItem[key]
      });
    });
  }

  private getOtherExtraSpace(): RepatriationExtraSpace {
    return this.repatriationInstructions.repatriationForm.getExtraSpace(ExtraSpaceEnum.OTHER);
  }

  hasOxygen(medium: RepatriationMediumEnum): boolean {
    return !!this.repatriationInstructions.patientNeeds.getOxygen(medium);
  }

  private hasEscort(type: EscortEnum): boolean {
    return !!this.repatriationInstructions.patientNeeds.getMedicalEscort(type);
  }

  private getOtherEquipment(): Equipment {
    return this.repatriationInstructions.patientNeeds.getEquipment(EquipmentItemEnum.OTHER);
  }

  private getOtherMedication(): Medication {
    return this.repatriationInstructions.patientNeeds.getMedication(MedicationItemEnum.OTHER);
  }

  private getAlternativeFormQuestionString(type: RepatriationFormEnum) {
    const formString = this.translateService.instant('medical-assessment-repatriation-form-'
      + type.toString().toLowerCase().replace(/_/g, '-'));
    return this.translateService.instant('repatriation-repatriation-alternative-form-question', { form: formString.toUpperCase() });
  }

  private getAltFormOtherExtraSpace(): RepatriationExtraSpace {
    const extraSpace = this.repatriationInstructions.alternativeFormExtraSpace;
    if (!!extraSpace) {
      return extraSpace.getExtraSpace(ExtraSpaceEnum.OTHER);
    }
    return null;
  }

  repatriationFormChanged() {

  }

  get InputTypePattern() {
    return InputTypePattern;
  }

  get InputType() {
    return InputType;
  }

  get RepatriationMediumEnum() {
    return RepatriationMediumEnum;
  }

  get EscortEnum() {
    return EscortEnum;
  }

  get RepatriationFormEnum() {
    return RepatriationFormEnum;
  }

  @Input()
  set state(state: MedicalAssessmentState) {
    if (state.escortLockedValue != null) {
      this.repatriationInstructions.patientNeeds.escortNeeded = state.escortLockedValue;
    } else if (!!this.stateValues && this.stateValues.escortLockedValue !== null && state.escortLockedValue === null) {
      this.repatriationInstructions.patientNeeds.escortNeeded = null;
    }
    this.stateValues = state;
    this.calculateExpAltFormsValid();
  }

  get state() {
    return this.stateValues;
  }

  calculateExpAltFormsValid() {
    let valid = true;
    if (this.expAltForms != null) {
      for (let i = this.state.altFromShownIndex; i < this.expAltForms.length; i++) {
        if (this.expAltForms[i].selected == null) {
          valid = false;
        }
      }
    }
    this.expAlternativeFormsValid = valid;
    this.formValidChanged.emit(this.expAlternativeFormsValid);
  }

  updateSurgeryDate($event) {
    this.repatriationInstructions.patientState.surgeryDate = $event;
  }

  updateRepatriationDate($event) {
    this.repatriationInstructions.repatriationForm.repatriationDate = $event;
  }

  updateArrivalDeadline($event) {
    this.repatriationInstructions.repatriationForm.arrivalDeadline = $event;
  }

  updateAlternativeFormStretcherDate($event) {
    this.repatriationInstructions.alternativeFormStretcher.date = $event;
  }

  updateAlternativeFormExtraSpaceDate($event) {
    this.repatriationInstructions.alternativeFormExtraSpace.date = $event;
  }

  updateAlternativeFormEconomyClassDate($event) {
    this.repatriationInstructions.alternativeFormEconomyClass.date = $event;
  }

  updateAlternativeFormRegularPassengerDate($event) {
    this.repatriationInstructions.alternativeFormRegularPassenger.date = $event;
  }

  updateintensiveCareStepText(step) {
    this.repatriationInstructions.patientState.intensiveCareStepText = this.translateService.instant('repatriation-intensive-care-step-category-'
      + step.toString().toLowerCase().replace(/_/g, '-'));
  }
}
