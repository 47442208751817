import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
import * as moment from 'moment';
import { DaySerializer } from './day-serializer';
import { QuantityUnit } from './enums';
import { RefundReduction, RefundReductionAdapter } from './refund-reduction';
import {
  BalanceSheetEntryItem,
  BalanceSheetEntryReduction
} from "@secca/case/components/case-plans/case-plans/add-service-order/models/interfaces";
import { DateSerializer } from '@secca/shared/models/date-serializer';
import { EconomyHistory } from './refund-history';


export class RefundItem implements BalanceSheetEntryItem, EconomyHistory {
  id: number;
  serviceOrderId: string;
  serviceItemCode: string;
  serviceItemName: string;
  quantity: number;
  quantityUnit: QuantityUnit;
  refundDate: moment.Moment;
  refundCurrency: string;
  refundAmount: number;
  refundExchangedAmount: number;
  reductions: RefundReduction[];
  modifiedBy: string;
  modifiedOn: moment.Moment;
  modifiedUserName: string;
  deletedBy: string;
  deletedOn: moment.Moment;

  public constructor(init?: Partial<RefundItem>) {
    Object.assign(this, init);
  }

  identicalHistory(refundItem: RefundItem): boolean {
    return this.serviceOrderId == refundItem.serviceOrderId &&
           this.serviceItemCode == refundItem.serviceItemCode &&
           this.serviceItemName == refundItem.serviceItemName &&
           this.quantity == refundItem.quantity &&
           this.refundDate ?  this.refundDate.isSame(refundItem.refundDate) : refundItem.refundDate === null &&
           this.quantityUnit == refundItem.quantityUnit &&
           this.refundAmount == refundItem.refundAmount &&
           this.refundCurrency == refundItem.refundCurrency &&
           this.refundExchangedAmount == refundItem.refundExchangedAmount;
  }

  getExchangeRate(): number {
    return 1;
  }

  getId(): number {
    return this.id;
  }

  getIsInVATCreditorGroup(): boolean {
    return false;
  }

  getIsIncludingVAT(): boolean {
    return false;
  }

  getOriginalAmount(): number {
    return this.refundExchangedAmount;
  }

  getSavings(): BalanceSheetEntryReduction[] {
    return this.reductions;
  }

  getServiceItemCode(): string {
    return this.serviceItemCode;
  }

  getServiceOrderId(): string {
    return this.serviceOrderId;
  }

  getQuantityUnit(): string {
    return this.quantityUnit;
  }

  getQuantity(): number {
    return this.quantity;
  }
}

@Injectable({
    providedIn: 'root'
  })
  export class RefundItemAdapter implements Adapter<RefundItem> {
    adapt(item: any): RefundItem {
      return new RefundItem({
        id: item.id,
        serviceOrderId: item.serviceOrderId,
        serviceItemCode: item.serviceItemCode ? item.serviceItemCode : '',
        serviceItemName: item.serviceItemName,
        quantity: item.quantity,
        quantityUnit: item.quantityUnit,
        refundDate: DaySerializer.deserialize(item.refundDate),
        refundCurrency: item.refundCurrency,
        refundAmount: item.refundAmount,
        refundExchangedAmount: item.refundExchangedAmount,
        reductions: !!item.reductions ? this.adaptReductionItems(item.reductions) : null,
        modifiedBy: item.modifiedBy,
        modifiedOn: item.modifiedOn ? DateSerializer.deserialize(item.modifiedOn) : null,
        modifiedUserName: item.modifiedUserName
      });
    }

    adaptReductionItems(data: any[]) {
        const refundReductionList = new Array<RefundReduction>();
        data.forEach(item => refundReductionList.push(new RefundReductionAdapter().adapt(item)));
        return refundReductionList;
      }
  }

