import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-single-item-display',
  templateUrl: './single-item-display.component.html',
  styleUrls: ['./single-item-display.component.scss']
})
export class SingleItemDisplayComponent {
  @Input() filterList: Set<string>;
  protected readonly Array = Array;

  get additionalCount(): string {
    const count = this.filterList.size - 1;
    return count > 0 ? `+${count}` : '';
  }
}
