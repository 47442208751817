import { DropdownDictionary } from './dropdownDictionary';

export class DropdownWitIconDictionary extends DropdownDictionary {
  iconPath: string;
  hoverText: string;

  public constructor(id?: any, name?: any, iconPath?: string) {
    super(id, name);
    this.iconPath = iconPath;
  }
}
