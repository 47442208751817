import { AssessmentTypeEnum, AssigneeEnum } from './../enums';
import { Adapter } from '../../interfaces/adapter';
import { ServiceOrderExtension } from '@secca/shared/models/service-order/service-order-extension';
import { DateSerializer } from '../date-serializer';
import { Location } from '@secca/shared/models/location';
import * as moment from 'moment';
import { NoDurationServiceOrderExtension } from './no-duration-service-order-extension';
import { CommitmentType, CommitmentTypeAdapter } from '../commitment-type';

export class MedicalAssessmentServiceOrderExtension extends NoDurationServiceOrderExtension implements ServiceOrderExtension {
  id: number;
  startDate: moment.Moment;
  endDate: moment.Moment;
  description: string;
  assignee: AssigneeEnum;
  assignedMedicalStaffId: number;
  assessmentType: AssessmentTypeEnum;
  commitmentType: CommitmentType;
  isDescriptionRequired = true;

  // Only used for display purposes in plans
  displayDurationId: number;

  public constructor(init?: Partial<MedicalAssessmentServiceOrderExtension>) {
    super();
    this.init();
    Object.assign(this, init);
  }

  isValid(): boolean {
    return this.validateDescription() && this.validateAssignee();
  }

  isPreviewGopValid(): boolean {
    throw new Error("Gop preview not supported for this service order type");
  }

  init() {
    this.startDate = moment.utc();
    this.description = '';
    this.assignee = AssigneeEnum.MEDICAL_ADVICE;
    this.assignedMedicalStaffId = null;
    this.assessmentType = AssessmentTypeEnum.NONE;
  }

  getStartDate(): moment.Moment {
    return this.startDate;
  }

  getEndDate(): moment.Moment {
    return this.endDate ? this.endDate : this.startDate;
  }

  isMultipleDurations(): boolean {
    return false;
  }

  getStartLocation(): Location {
    return null;
  }

  getEndLocation(): Location {
    return null;
  }

  getCommitmentType(): CommitmentType {
    return this.commitmentType;
  }

  setCommitmentType(commitmentType: CommitmentType) {
    this.commitmentType = commitmentType;
  }

  validateDescription() {
    return !this.isDescriptionRequired || (this.description && this.description.length > 0);
  }

  validateAssignee(): boolean {
    if (this.assignee == null) {
      return false;
    }
    if (this.assignee === AssigneeEnum.MEDICAL_ADVICE || this.assignee === AssigneeEnum.PRE_DEPARTURE_MEDICAL_ADVICE) {
      return true;
    }
    if (this.assignee === AssigneeEnum.MEDICAL_STAFF) {
      return this.assignedMedicalStaffId !== null && this.commitmentType !== null;
    }
  }

  getSelectedStakeholderIds(): string[] {
    return null;
  }

  hasMedicalEscort(): boolean {
    return false;
  }

  hasCoTravellers(): boolean {
    return false;
  }
}

export class MedicalAssessmentServiceOrderAdapter implements Adapter<MedicalAssessmentServiceOrderExtension> {
  adapt(item: any): MedicalAssessmentServiceOrderExtension {
    const commitmentTypeAdapter: CommitmentTypeAdapter = new CommitmentTypeAdapter();
    return new MedicalAssessmentServiceOrderExtension({
      id: item.id,
      startDate: DateSerializer.deserialize(item.startDate),
      endDate: DateSerializer.deserialize(item.endDate),
      description: item.description,
      assignee: item.assignee,
      assignedMedicalStaffId: item.assignedMedicalStaffId,
      assessmentType: item.assessmentType,
      commitmentType: item.commitmentType !== null ? commitmentTypeAdapter.adapt(item.commitmentType) : null,
    });
  }
}
