import { SearchUserAndTeam } from './../../models/searchUserAndTeam';
import { SearchUser } from '../../models/searchUser';
import { AutoUnsubscribe } from 'src/app/shared/decorators/auto-unsubscribe';
import { DictionaryService } from '@secca/core/services/dictionary.service';
import { Component, ViewEncapsulation, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AutoCompleteTypeEnum } from './auto-complete-type-enum';
import { NgSelectComponent } from '@ng-select/ng-select';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-drop-down-auto-complete',
  templateUrl: './drop-down-auto-complete.component.html',
  styleUrls: ['./drop-down-auto-complete.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@AutoUnsubscribe
export class DropDownAutoCompleteComponent implements OnInit {
  @ViewChild('select') select: NgSelectComponent;
  @Input() caseId: string;

  @Input() set autoCompleteTypeEnum(autoCompleteTypeEnum: AutoCompleteTypeEnum) {
    this._autoCompleteTypeEnum = autoCompleteTypeEnum;
  }
  get autoCompleteTypeEnum() {
    return this._autoCompleteTypeEnum;
  }
  @Input() name: string;
  @Input() selectedItemId = undefined;
  @Input() shortDropDown = false;
  @Input() required = false;
  @Input() appendTo: string = null;
  @Output() selectedItemIdChange = new EventEmitter();
  @Output() saveModel = new EventEmitter();

  _autoCompleteTypeEnum: AutoCompleteTypeEnum;
  subscription: Subscription;
  users: Observable<SearchUser[]>;
  usersAndTeams: Observable<SearchUserAndTeam[]>;
  oldSelectedItemId: string;
  input = new Subject<string | null>();
  teams: any[];
  $inputSubscr: Subscription;

  constructor(private dictionaryService: DictionaryService) {
    this.$inputSubscr = this.input.subscribe(newTerm => {
      this.search(newTerm);
    });
  }

  ngOnInit() {
    this.loadTeams();
  }

  get AutoCompleteTypeEnum() {
    return AutoCompleteTypeEnum;
  }

  public setFocus() {
    this.select.focus();
  }

  onChange() {
    this.selectedItemIdChange.emit(this.selectedItemId);
  }

  onSaveModel() {
    if (this.oldSelectedItemId !== this.selectedItemId) {
      this.saveModel.emit();
    }
  }

  onSaveState() {
    this.oldSelectedItemId = this.selectedItemId;
  }

  get items() {
    if (this.autoCompleteTypeEnum === AutoCompleteTypeEnum.Users) {
      return this.users;
    }
    if (this.autoCompleteTypeEnum === AutoCompleteTypeEnum.UsersAndTeamsExceptFromOtherDepartment) {
      return this.usersAndTeams;
    }
    if (this.autoCompleteTypeEnum === AutoCompleteTypeEnum.UsersAndTeamsExceptFromOtherAndCostControlDepartment) {
      return this.usersAndTeams;
    }
  }

  private search(term: string | null) {
    if (term && term.length > 1) {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      switch (this.autoCompleteTypeEnum) {
        case AutoCompleteTypeEnum.Users:
          this.subscription = this.dictionaryService
            .searchUsers(term)
            .pipe(debounceTime(500))
            .pipe(map(users => users.filter(user => user.active)))
            .pipe(result => (this.users = result))
            .subscribe();
          break;
        case AutoCompleteTypeEnum.UsersAndTeamsExceptFromOtherDepartment:
          this.subscription = this.dictionaryService
            .searchUsersExcludeDepartments(term, ['OTHER'])
            .pipe(debounceTime(500))
            .pipe(map(users => users.filter(user => user.active)))
            .subscribe(result => this.joinUsersAndTeams(result, term, ['OTHER']));
          break;
        case AutoCompleteTypeEnum.UsersAndTeamsExceptFromOtherAndCostControlDepartment:
          const blacklist = ['OTHER', 'COST_CONTROL', 'CLAIMS_SE', 'CLAIMS_DK', 'PRE_DEPARTURE', 'QUALITY', 'MEDICAL_CENTRE', 'MEDICAL_ADVICE', 'MEDICAL_CENTRE_SUPPORT'];
          this.subscription = this.dictionaryService
            .searchUsersExcludeDepartments(term, blacklist)
            .pipe(debounceTime(500))
            .pipe(map(users => users.filter(user => user.active)))
            .subscribe(result => this.joinUsersAndTeams(result, term, blacklist));
          break;
        default:
          console.error('Action is not defined');
          break;
      }
    }
  }

  private joinUsersAndTeams(users: SearchUser[], term: string, excludedTeams: string[]): SearchUserAndTeam[] {
    let result: SearchUserAndTeam[] = [];
    if (term != null) {
      result = users.map(
        a =>
          new SearchUserAndTeam({
            department: a.department,
            initials: a.initials,
            picture: a.picture,
            team: a.team,
            fullUserName: a.fullUserName,
            id: a.id,
            isTeam: false
          })
      );
      this.teams
        .filter(a => a.teamName != null && a.teamName.toLowerCase().indexOf(term.toLowerCase()) >= 0)
        .filter(a => a.teamId != null && excludedTeams.indexOf(a.teamId) == -1)
        .forEach(a => result.push(new SearchUserAndTeam({ isTeam: true, adtCode: a.adtCode, teamName: a.teamName, fullUserName: a.teamName })));
    }
    this.usersAndTeams = of(result);
    return result;
  }

  private loadTeams() {
    if (this.autoCompleteTypeEnum === AutoCompleteTypeEnum.UsersAndTeamsExceptFromOtherDepartment || this.autoCompleteTypeEnum === AutoCompleteTypeEnum.UsersAndTeamsExceptFromOtherAndCostControlDepartment) {
      this.dictionaryService.getAllActiveTeamsExcludingOtherDepartment().subscribe(
        result => {
          this.teams = result;
        },
        error => console.log(error)
      );
    }
  }
}
