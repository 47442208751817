
<div appDialogSelectable appDialogKeyboardEnabled [appDialogMinimizable]="MinimizableDialogType.ServicePlan">
    <mat-dialog-content (cdkDragStarted)="dialogBoundryService.handleDragStart($event)" (cdkDragEnded)="dialogBoundryService.handleDragEnd($event)" cdkDragRootElement=".cdk-overlay-pane" cdkDrag>
        <div appDialogDragArea cdkDragHandle></div>
        <app-add-service-order [serviceOrderId]="data.serviceOrderId"
                               [caseId]="data.caseId"
                               [caseNumber]="data.caseNumber"
                               [incidentId]="data.incidentId"
                               (closeEvent)="close()"></app-add-service-order>
    </mat-dialog-content>
</div>