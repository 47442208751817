import { Injectable } from '@angular/core';
import { Adapter } from './../interfaces/adapter';

export class Timeline {
  caseID: string;
  travelBegin: Date;
  accidentDate: Date;
  travelEnd: Date;
  insuranceEnd: Date;
  coverageDayEnd: Date;

  public constructor(init?: Partial<Timeline>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class TimeLineAdapter implements Adapter<Timeline> {
  adapt(item: any): Timeline {
    return new Timeline({
      caseID: item.caseID,
      travelBegin: new Date(item.travelBegin),
      accidentDate: new Date(item.accidentDate),
      travelEnd: new Date(item.travelEnd),
      insuranceEnd: new Date(item.insuranceEnd),
      coverageDayEnd: item.coverageDayEnd
    });
  }
}
