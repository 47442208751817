<div class="sub-component-container" *ngIf="subAirwayBill">
  <div class="sub-title">{{"sub-airway-bill-title" | translate}}</div>
  <div class="sub-content-container">
    <div class="row">
      <div class="col-6">
        <app-input [name]="'sub-airway-bill-airway-bill-number' | translate" [maxTextLength]="100"
                   [(value)]="subAirwayBill.airwayBillNumber"></app-input>
      </div>
      <div class="col-6">
        <app-input [name]="'sub-airway-bill-remarks' | translate" [maxTextLength]="100"
                   [(value)]="subAirwayBill.remarks"></app-input>
      </div>
    </div>
  </div>
</div>
