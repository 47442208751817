import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Observable, of } from 'rxjs';
import { DialogMinimizedStorageService } from './dialog-minimized-storage.service';
import { IMinimizable } from '@secca/shared/interfaces/minimizable';

@Injectable()
export class DialogsActiveGuardService implements CanActivate {

  constructor(private matDialog: MatDialog,
              private dialogMinimizedStorageService: DialogMinimizedStorageService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if ( this.matDialog.openDialogs.length != 0 ) {

      const notMinimizedDialogs: MatDialogRef<any>[] = this.matDialog.openDialogs.filter(matRef => {
        return !this.dialogMinimizedStorageService.getMinimizedFromDialogId(matRef.id);
      });

      if ( notMinimizedDialogs.length != 0 ) {
        this.minimizeOpenDialogs(notMinimizedDialogs);
      }
    }

    return of(true);
  }

  private minimizeOpenDialogs(notMinimizedDialogs: MatDialogRef<any>[]): void {
    notMinimizedDialogs.forEach(matDialogRef => {
      const minimizableComponent = matDialogRef.componentInstance as IMinimizable;
      if ( minimizableComponent && minimizableComponent.minimize ) {
        minimizableComponent.minimize();
      }
    });
  }
}
