<div class="sub-component-container">
  <div class="sub-content-container d-flex">
    <div class="flight-leg me-auto " [ngClass]="{ 'non-selected-icc-flight-leg': showRelevantForCase && !getRelevantForCase(relevantForCaseId) }">
      <div class="row mb-2" *ngIf="!hideCabinClass || !hideFlightInRefs">
        <ng-container *ngIf="!hideCabinClass">
          <app-drop-down
            class="col-3"
            [items]="cabinClassDropdown"
            [name]="'sub-travel-info-class' | translate"
            [requiredRedBackground]="cabinClassRequired"
            [(selectedItemId)]="travelInfo.cabinClass"
            [disabled]="readOnlyMode"
          ></app-drop-down>
        </ng-container>
        <ng-container *ngIf="!hideFlightInRefs">
          <app-input
            class="col-2"
            [(value)]="travelInfo.flightNo"
            [name]="'sub-travel-info-flight-number' | translate"
            [maxTextLength]="100"
            [required]="flightNoRequired"
            [disabled]="readOnlyMode"
          ></app-input>
          <ng-container *ngIf="!hideTicketDetails">
              <app-input
                class="col-2"
                [(value)]="travelInfo.agencyReference"
                [name]="resourceConfig.agencyRefLabel | translate"
                [maxTextLength]="100"
                [required]="agencyReferenceRequired"
                [disabled]="readOnlyMode"
              ></app-input>
              <app-input
                *ngIf="!hideAirlineReference"
                class="col-2"
                [(value)]="travelInfo.airlineReference"
                [name]="'sub-travel-info-airline-reference-number' | translate"
                [maxTextLength]="100"
                [disabled]="readOnlyMode"
              ></app-input>
              <app-input
                *ngIf="!hideTicketNo"
                class="col-6"
                [(value)]="travelInfo.ticketNumbers"
                [name]="'sub-travel-info-ticket-numbers' | translate"
                [maxTextLength]="100"
                [disabled]="readOnlyMode"
              ></app-input>
          </ng-container>
        </ng-container>
      </div>
      <div class="row mb-2">
        <app-input-date-time
          class="col-3"
          [withImage]="true"
          [name]="resourceConfig.startDateLabel"
          [date]="travelInfo.departureDate"
          [required]="departureDateRequired"
          [recommended]="!departureDateRequired"
          [startAt]="departureDateStartAt"
          [timeZone]="departureTimeZone"
          (dateChange)="departureDateChange($event)"
          (invalidChange)="departureDateChange($event)"
          [disabled]="readOnlyMode"
          [minDate]="minMoment"
          [markWithError]="invalidDateRange"
        ></app-input-date-time>
        <app-input-location
          class="col-9"
          [withImage]="true"
          [(value)]="travelInfo.departureFrom"
          [allowEmpty]="false"
          [validationType]="(departureFromRequired || departureFromRequired === undefined) ? ValidationTypeEnum.required : ValidationTypeEnum.default"
          [name]="resourceConfig.startDateLocationLabel | translate"
          (saveModel)="onDepartureFromUpdate()"
          [disabled]="readOnlyMode"
          placeholder=""
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
          type="text"
        ></app-input-location>
      </div>
      <div class="row mb-2">
        <app-input-date-time
          class="col-3"
          [withImage]="true"
          [name]="resourceConfig.endDateLabel | translate"
          [date]="travelInfo.arrivalDate"
          [required]="arrivalDateRequired || invalidDateRange"
          [recommended]="!arrivalDateRequired && !arrivalDateWhite"
          [startAt]="arrivalDateStartAt"
          [timeZone]="arrivalDateTimeZone"
          (dateChange)="arrivalDateChange($event)"
          (invalidChange)="arrivalDateChange($event)"
          [disabled]="readOnlyMode"
          [markWithError]="invalidDateRange"
        ></app-input-date-time>
        <app-input-location
          class="col-9"
          [withImage]="true"
          [(value)]="travelInfo.arrivalAt"
          [allowEmpty]="false"
          [validationType]="(arrivalAtRequired || arrivalAtRequired === undefined) ? ValidationTypeEnum.required : ValidationTypeEnum.default"
          [name]="resourceConfig.endDateLocationLabel | translate"
          (saveModel)="onArrivalAtUpdate()"
          [disabled]="readOnlyMode"
          placeholder=""
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
          type="text"
        ></app-input-location>
      </div>
      <div *ngIf="invalidDateRange" class="row mb-2">
        <div class="col-12">
          <div class="invalid-date-range">{{'sub-travel-info-invalid-date-range' | translate}}</div>
        </div>
      </div>
      <div class="row" *ngIf="!hideRemarks">
        <app-input [disabled]="remarksReadOnlyMode" class="col-12" [name]="'sub-travel-info-remarks' | translate"
                   [(value)]="travelInfo.remarks"></app-input>
      </div>
    </div>
    <div *ngIf="!hideDeleteButton && !showRelevantForCase" class="delete-container ms-4" (click)="deleteClicked()">
      <div class="d-flex justify-content-center align-items-center h-100">
        <img src="../../../../assets/icons/garbage.svg" />
      </div>
    </div>
    <div *ngIf="showRelevantForCase" class="relevant-for-case-container ms-2 text-center">
      <div class="headline">{{ 'case-basics-coordination-case-relevant-for-case' | translate }}</div>
      <label class="custom-radio-button text-center mt-3 ms-3">
        <div class="single-choice-item-checkbox" (click)="setRelevantForCase(relevantForCaseId, true)" >
          <a class="button" [ngClass]="{'button-required' : getRelevantForCase(relevantForCaseId) == undefined, 'button-on-green' : getRelevantForCase(relevantForCaseId)}"  tabindex="0"></a>
          <a class="button" [ngClass]="{'button-required' : getRelevantForCase(relevantForCaseId) == undefined, 'button-dot-green' : getRelevantForCase(relevantForCaseId)}"></a>
        </div>
        <div class="radio-button-text">{{ 'case-basics-coordination-case-relevant-for-case-yes' | translate }}</div>
      </label>
      <label class="custom-radio-button text-center mt-3 ms-3" >
        <div class="single-choice-item-checkbox" (click)="setRelevantForCase(relevantForCaseId, false)" >
          <a class="button" [ngClass]="{'button-on-red' : getRelevantForCase(relevantForCaseId) == false, 'button-required' : getRelevantForCase(relevantForCaseId) == undefined }"  tabindex="0"></a>
          <a class="button" [ngClass]="{'button-dot-red' : getRelevantForCase(relevantForCaseId) == false, 'button-required' : getRelevantForCase(relevantForCaseId) == undefined }"></a>
        </div>
        <div class="radio-button-text">{{ 'case-basics-coordination-case-relevant-for-case-no' | translate }}</div>
      </label>
    </div>
  </div>
</div>
