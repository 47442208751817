import { SubComponentModel } from './interfaces';

export class SubGopDetails implements SubComponentModel {
  itemsInGop: string;
  itemsNotInGop: string;

  // Required fields
  templateName: string;
  templateLanguage: string;
  templateId: string;

  public constructor(init?: Partial<SubGopDetails>) {
    Object.assign(this, init);
  }

  isValid(): boolean {
    return (
      this.templateName != null &&
      this.templateLanguage != null &&
      this.templateId != null
    );
  }
}
