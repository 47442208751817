<div class="output-management-multi-select-attachments ng-dropdown-panel-wrap">
  
  <ng-select #attachmentSelect [items]="attachments" [multiple]="true" (change)="onSelectedItemIdsChange()"
    [(ngModel)]="selectedItems" [searchFn]="searchFunction" (blur)="attachmentSelect.close()">
    <ng-template ng-option-tmp let-item="item" let-index="index">
      <span>
        {{getDocumentName(item)}} {{getNote(item)}}
      </span>
    </ng-template>
    <ng-template ng-label-tmp let-item="item" let-index="index" let-clear="clear">
      <span>{{getDocumentName(item)}}</span>
      <span *ngIf="item.attachmentType === AttachmentTypeEnum.FROM_USER" class="ng-value-icon right border-0"
        (click)="clear(item)">x</span>
    </ng-template>
  </ng-select>
</div>
