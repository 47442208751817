import {MedicalService} from '@secca/core/services/medical.service';
import {HandlingAreaStatusEnum, HandlingAreaType, PermissionEnum, PermissionHideTypeEnum,} from './../../../../shared/models/enums';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Case} from '@secca/shared/models/case';
import {HandlingArea} from '@secca/shared/models/handlingArea';
import {CaseValidationService} from '@secca/core/services/case-validation.service';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalDialogComponent} from '@secca/shared/components/modal-dialog/modal-dialog.component';
import {ModalDialogConfiguration} from '@secca/shared/models/modal/modal-dialog-configuration';
import {CaseLockHelperService} from '@secca/core/services/case-lock-helper.service';
import {HandlingAreaService} from '@secca/core/services/handling-area.service';
import {Subscription} from 'rxjs';
import {AutoUnsubscribe} from '@secca/shared/decorators/auto-unsubscribe';
import {PermissionService} from '@secca/core/services/permission.service';
import {CaseStateService} from '@secca/core/state-services/case-state.service';
import {CaseHandlerModalComponent} from './case-handler-modal/case-handler-modal.component';
import {CaseValidation} from '@secca/shared/models/caseValidation';

@Component({
  selector: 'app-case-handlers',
  templateUrl: './case-handlers.component.html',
  styleUrls: ['./case-handlers.component.scss'],
})
@AutoUnsubscribe
export class CaseHandlersComponent implements OnInit, OnChanges {
  @Input()
  set case(newCase: Case) {
    this._case = newCase;
    if (newCase.handlingAreas) {
      this.setHandlingAreas(newCase.handlingAreas);
    }
  }

  get case(): Case {
    return this._case;
  }

  @ViewChild('modal') modal: CaseHandlerModalComponent;
  @Input() handlingAreas: HandlingArea[];

  @Output() changeHandlingArea: EventEmitter<HandlingArea[]> = new EventEmitter();
  caseHandlerHandlingArea: HandlingArea;
  transportHandlingArea: HandlingArea;
  medicalHandlingArea: HandlingArea;
  costControlHandlingArea: HandlingArea;
  claimsHandlingArea: HandlingArea;
  recoveryHandlingArea: HandlingArea;

  mouseOverTeam: string;
  mouseOverMedical: string;
  mouseOverTransport: string;
  mouseOverCostControl: string;
  mouseOverClaims: string;
  mouseOverRecovery: string;
  _case: Case;
  showCaseTeam: boolean;
  translatePreFix = 'HANDLING-AREA-';

  claimsName: string;
  costControlName: string;
  transportName: string;
  medicalName: string;
  caseHandlerName: string;
  recoveryName: string;

  claimsStatus: string;
  costControlStatus: string;
  transportStatus: string;
  medicalStatus: string;
  caseHandlerStatus: string;
  recoveryStatus: string;

  caseValid: boolean;

  $coveragesSubscr: Subscription;
  $medicalAssmentSubscr: Subscription;
  $caseNumberShownSubscr: Subscription;

  constructor(
    private caseValidationService: CaseValidationService,
    private translate: TranslateService,
    private modalService: NgbModal,
    public caseLockHelperService: CaseLockHelperService,
    public permissionService: PermissionService,
    private medicalService: MedicalService,
    public caseStateService: CaseStateService,
    private handlingAreaService: HandlingAreaService,
    private ngbModal: NgbModal,
  ) {}

  handlerStatusEnum: HandlingAreaStatusEnum;

  ngOnInit() {
    this.claimsName = this.translate.instant(this.translatePreFix + 'CLAIMS-NAME');
    this.costControlName = this.translate.instant(this.translatePreFix + 'COST-CONTROL-NAME');
    this.transportName = this.translate.instant(this.translatePreFix + 'TRANSPORT-NAME');
    this.medicalName = this.translate.instant(this.translatePreFix + 'MEDICAL-NAME');
    this.caseHandlerName = this.translate.instant(this.translatePreFix + 'CASE-HANDLER-NAME');
    this.recoveryName = this.translate.instant(this.translatePreFix + 'RECOVERY-NAME');
    this.caseValidationService.isCaseValid(this.case.id).subscribe(result => {
      if (result.caseValidateList.length === 0) {
        this.caseValid = true;
        return;
      } else {
        this.caseValid = false;
      }
    });

    this.$caseNumberShownSubscr = this.caseValidationService.getCaseNumberAssignedConfirmation().subscribe(result => {
      if (result) {
        this.showCaseTeam = !!result;
      }
    });
    this.changeStatusOnPublicMedicalAssessment();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.handlingAreas) {
      this.setHandlingAreas(this.handlingAreas);
    }
  }


  private setHandlingAreas(handlingAreas: HandlingArea[]) {
    this.caseHandlerHandlingArea = new HandlingArea({
      handlingAreaType: HandlingAreaType.CASE_HANDLER,
      status: HandlingAreaStatusEnum.NON_ACTIVE,
    });
    this.transportHandlingArea = new HandlingArea({
      handlingAreaType: HandlingAreaType.TRANSPORT,
      status: HandlingAreaStatusEnum.NON_ACTIVE,
    });
    this.medicalHandlingArea = new HandlingArea({ handlingAreaType: HandlingAreaType.MEDICAL, status: HandlingAreaStatusEnum.NON_ACTIVE });
    this.costControlHandlingArea = new HandlingArea({
      handlingAreaType: HandlingAreaType.COST_CONTROL,
      status: HandlingAreaStatusEnum.NON_ACTIVE,
    });
    this.claimsHandlingArea = new HandlingArea({ handlingAreaType: HandlingAreaType.CLAIMS, status: HandlingAreaStatusEnum.NON_ACTIVE });
    this.recoveryHandlingArea = new HandlingArea({ handlingAreaType: HandlingAreaType.RECOVERY, status: HandlingAreaStatusEnum.NON_ACTIVE });

    handlingAreas.forEach(handlingArea => {
      switch (handlingArea.handlingAreaType) {
        case HandlingAreaType.CASE_HANDLER:
          this.caseHandlerHandlingArea = handlingArea;
          this.caseHandlerStatus = this.translate.instant(this.translatePreFix + handlingArea.status);
          break;
        case HandlingAreaType.MEDICAL:
          this.medicalHandlingArea = handlingArea;
          this.medicalStatus = this.translate.instant(this.translatePreFix + handlingArea.status);
          break;
        case HandlingAreaType.CLAIMS:
          this.claimsHandlingArea = handlingArea;
          this.claimsStatus = this.translate.instant(this.translatePreFix + handlingArea.status);
          break;
        case HandlingAreaType.COST_CONTROL:
          this.costControlHandlingArea = handlingArea;
          this.costControlStatus = this.translate.instant(this.translatePreFix + handlingArea.status);
          break;
        case HandlingAreaType.TRANSPORT:
          this.transportHandlingArea = handlingArea;
          this.transportStatus = this.translate.instant(this.translatePreFix + handlingArea.status);
          break;
        case HandlingAreaType.RECOVERY:
          this.recoveryHandlingArea = handlingArea;
          this.recoveryStatus = this.translate.instant(this.translatePreFix + handlingArea.status);
          break;
        default:
      }
    });

    this.mouseOverTeam = this.mouseOverString(this.caseHandlerHandlingArea, this.caseHandlerName);
    this.mouseOverMedical = this.mouseOverString(this.medicalHandlingArea, this.medicalName);
    this.mouseOverTransport = this.mouseOverString(this.transportHandlingArea, this.transportName);
    this.mouseOverClaims = this.mouseOverString(this.claimsHandlingArea, this.claimsName);
    this.mouseOverCostControl = this.mouseOverString(this.costControlHandlingArea, this.costControlName);
    this.mouseOverRecovery = this.mouseOverString(this.recoveryHandlingArea, this.recoveryName);
  }

  mouseOverString(handlingArea: HandlingArea, defaultString: string) {
    if (handlingArea.user && handlingArea.user.firstName && handlingArea.user.surname && handlingArea.user.initials) {
      return handlingArea.user.firstName + ' ' + handlingArea.user.surname + ' (' + handlingArea.user.initials + ')';
    } else if (handlingArea.user && handlingArea.user.firstName && handlingArea.user.surname) {
      return handlingArea.user.firstName + ' ' + handlingArea.user.surname;
    } else if (handlingArea.team && handlingArea.team.teamName) {
      return handlingArea.team.teamName;
    } else {
      return defaultString;
    }
  }

  getHandlerStatusEnum() {
    return HandlingAreaStatusEnum;
  }

  caseTeamName() {
    if (this.case.team && this.showCaseTeam) {
      return this.case.team.teamName;
    } else {
      return '⋅ ⋅ ⋅';
    }
  }

  claimsHandlerTeamName() {
    const claimsHandlerUserDepartmentName =
      !!this.claimsHandlingArea &&
      !!this.claimsHandlingArea.user &&
      !!this.claimsHandlingArea.user.userTeam &&
      !!this.claimsHandlingArea.user.userTeam.teamName;
    const claimsHandlerUserTeamName =
      !!this.claimsHandlingArea && !!this.claimsHandlingArea.team && !!this.claimsHandlingArea.team.teamName;
    if (claimsHandlerUserDepartmentName) {
      return this.claimsHandlingArea.user.userTeam.teamName;
    } else if (claimsHandlerUserTeamName) {
      return this.claimsHandlingArea.team.teamName;
    } else {
      return this.translate.instant(this.translatePreFix + 'CLAIMS-NAME');
    }
  }

  openHandlingAreaModal(handlingAreaName, caseId) {
    this.handlingAreaService.getHandlingsAreas(caseId, true).subscribe( result => {
        this.setHandlingAreas(result);
        let handlingArea = null;
        switch (handlingAreaName) {
          case this.costControlName: {
            handlingArea = this.costControlHandlingArea;
            break;
          }
          case this.recoveryName: {
            handlingArea = this.recoveryHandlingArea;
            break;
          }
          case this.caseHandlerName: {
            handlingArea = this.caseHandlerHandlingArea;
            break;
          }
          case this.claimsName: {
            handlingArea = this.claimsHandlingArea;
            break;
          }
          case this.transportName: {
            handlingArea = this.transportHandlingArea;
            break;
          }
          case this.medicalName: {
            handlingArea = this.medicalHandlingArea;
            break;
          }
        }
        this.modal.open(handlingAreaName, handlingArea, caseId);
      }
    )
  }

  openCaseValidationModal() {
    this.caseValidationService.isCaseValid(this.case.id).subscribe(result => {
      if (result.caseValidateList.length === 0) {
        this.caseValid = true;
        return;
      }
      const modalRef = this.modalService.open(ModalDialogComponent);
      modalRef.componentInstance.items = result.caseValidateList;
      modalRef.componentInstance.configuration = new ModalDialogConfiguration({
        header: 'default-modal-header',
        title: 'case-validation-warning-modal-title-case-insurance',
        text: 'case-validation-warning-modal-text',
        footer: 'case-validation-warning-modal-footer',
        yes: 'default-modal-dialog-yes',
        no: 'default-modal-dialog-no',
        isBody: true,
        isFooter: false,
      });
      this.caseValid = false;
      modalRef.componentInstance.closeModalEvent.subscribe(emittedEvent => {
        this.closeModal();
      });
    });
  }

  openModal(controlAreaName) {
    if (this.caseStateService.isCaseClosed) {
      this.openHandlingAreaModal(controlAreaName, this.case.id);
    } else {
      let caseClosedValidation: CaseValidation;
      this.caseValidationService.isCaseClosedValid(this.case.id).subscribe(result => {
        caseClosedValidation = result;
        caseClosedValidation.caseBeenValidated ? this.openHandlingAreaModal(controlAreaName, this.case.id) : this.openCaseValidationModal();
      })

    }
  }

  changeStatusOnPublicMedicalAssessment() {
    this.$medicalAssmentSubscr = this.medicalService.publishMedicalAssessmentHaveFollowUpPlaned.subscribe(result => {
      this.updateMedicalStatus(result);
    });
  }

  updateMedicalStatus(followupPlaned: boolean) {
    if (!followupPlaned) {
      this.medicalHandlingArea.status = HandlingAreaStatusEnum.CLOSED;
      this.medicalStatus = this.translate.instant(this.translatePreFix + HandlingAreaStatusEnum.CLOSED);
    } else {
      if (this.medicalHandlingArea.status === HandlingAreaStatusEnum.CLOSED) {
        this.medicalHandlingArea.status = HandlingAreaStatusEnum.REOPENED;
        this.medicalStatus = this.translate.instant(this.translatePreFix + HandlingAreaStatusEnum.REOPENED);
      }
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  handlingAreasChange($event) {
    this.changeHandlingArea.emit($event);
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  get PermissionHideTypeEnum() {
    return PermissionHideTypeEnum;
  }
}
