import { Component, Input, ViewEncapsulation } from "@angular/core";
import { RecoveryService } from "@secca/core/services/recovery.service";
import { TaskModalCommonHeaderComponent } from "@secca/shared/components/task-modal-common/task-modal-common-header.component";
import { AutoUnsubscribe } from "@secca/shared/decorators/auto-unsubscribe";
import { Case } from "@secca/shared/models/case";
import { Recovery } from "@secca/shared/models/recovery";
import { Task } from "@secca/shared/models/task";

@Component({
  selector: 'app-task-modal-static-recovery-header',
  templateUrl: './task-modal-static-recovery-header.component.html',
  styleUrls: ['./task-modal-static-recovery-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@AutoUnsubscribe
export class TaskModalStaticRecoveryHeaderComponent extends TaskModalCommonHeaderComponent {
  @Input() case: Case;
  @Input() profileName: string;
  @Input() location: string;
  @Input() incidentCause: string;
  @Input() set task(task: Task) {
    if ( !!task?.recoveryId ) {
      this.loadRecovery(task.recoveryId);
    }
  }

  recovery: Recovery;

  constructor(private recoveryService: RecoveryService) {
    super();
  }

  get nationalHealthAuthority() {
    return '';
  }

  eligibleAmount() {
    return "";
  }

  private loadRecovery(recoveryId: number) {
    this.recoveryService.getRecoveryById(recoveryId).subscribe(result => this.recovery = result);
  }
}