<div class="board-menu-container">
  <div fxLayout="row">
    <div fxFlex="100" fxFlexAlign="start">
      <div class="board-menues">
        <button
          class="board-menues-item"
          [ngClass]="{ 'board-menues-item-selected': isMenuSelected(BoardSelectedMenuEnum.Cases) }"
          (click)="boardCasesClick()"
        >
          {{ 'board-menu-item-cases' | translate }}
        </button>
        <button
          class="board-menues-item"
          [ngClass]="{ 'board-menues-item-selected': isMenuSelected(BoardSelectedMenuEnum.Task) }"
          (click)="boardTaskClick()"
        >
          {{ 'board-menu-item-task' | translate }}
        </button>
        <span *permission="PermissionEnum.SALES_ORDER_READ">
          <button
            class="board-menues-item"
            [ngClass]="{ 'board-menues-item-selected': isMenuSelected(BoardSelectedMenuEnum.SalesOrder) }"
            (click)="boardSalesOrderClick()"
          >
            {{ 'board-menu-item-sales-order' | translate }}
          </button>
        </span>
      </div>
    </div>
    <div fxFlexAlign="end start">
      <app-board-team-caring [isCaseView]="selectedMenuNumber === BoardSelectedMenuEnum.Cases"></app-board-team-caring>
    </div>
  </div>
</div>
