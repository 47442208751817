import { Component, Input, OnInit } from '@angular/core';
import { ServiceTypeBase } from '../../models/serviceTypeBase';
import { ServiceTypeComponent } from '../../models/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { PreDepartureAdviceServiceOrderExtension } from '@secca/shared/models/service-order/medical-pre-departure-advice-service-order-extension';

@Component({
  selector: 'app-pre-departure-advice',
  templateUrl: './pre-departure-advice.component.html',
})
export class PreDepartureAdviceComponent extends ServiceTypeBase implements ServiceTypeComponent, OnInit {
  @Input() preDepartureAdvice: PreDepartureAdviceServiceOrderExtension;
  @Input() caseId: string;

  constructor(private translate: TranslateService) {
    super();
  }

  ngOnInit() {
    this.initialize();
  }

  isValid(): boolean {
    return this.preDepartureAdvice.isValid();
  }

  getModel() {
    return this.preDepartureAdvice;
  }

  initialize() {
  }

}
