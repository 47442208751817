<div class="coordination-card" *ngIf="incident && coordinationDetails" [appDisableInput]="caseStateService.isCaseDisabled()">
  <div class="col-12">
    <app-input [name]="'coordination-details-name' | translate" [validationType]="ValidationTypeEnum.required" [(value)]="coordinationDetails.name"
    (saveModel)="saveCoordinationDetails()"></app-input>
  </div>
  <div class="col-12">
    <app-drop-down [name]="'coordination-details-type' | translate" [requiredRedBackground]="true" [items]="coordinationTypes" [(selectedItemId)]="coordinationDetails.type"
    (saveModel)="saveCoordinationDetails()"></app-drop-down>
  </div>
  <ng-container *ngIf="case.caseTypeCode === caseTypeCodes.INTERNAL_COORDINATION">
    <div class="col-4">
      <app-input-calendar [name]="'coordination-details-expected-date-of-transport' | translate" [withImage]="true" [required]="true" [(date)]="coordinationDetails.expectedDate"
      (dateChange)="saveCoordinationDetails()"></app-input-calendar>
    </div>
    <div class="col-12">
      <app-input-location [name]="'coordination-details-expected-start-location' | translate" autocorrect="off" autocapitalize="off" spellcheck="false" type="text"
                          [allowEmpty]="false" (saveModel)="updateIncidentLocation()" [withImage]="true" [freeText]="incident.incidentLocation.freeText" [value]="incident.incidentLocation"
                          [validationType]="CoordinationTypeEnum.AMBULANCE_FLIGHT_PRE_BOOKED == coordinationDetails.type ? ValidationTypeEnum.required : ValidationTypeEnum.default"></app-input-location>
    </div>
    <div class="col-12">
      <app-input-location [name]="'coordination-details-expected-end-destination' | translate" autocorrect="off" autocapitalize="off" spellcheck="false" type="text"
                          [allowEmpty]="false" (saveModel)="saveCoordinationDetails()" [withImage]="true" [value]="coordinationDetails.destination"
                          [validationType]="CoordinationTypeEnum.AMBULANCE_FLIGHT_PRE_BOOKED == coordinationDetails.type ? ValidationTypeEnum.required : ValidationTypeEnum.default"></app-input-location>
    </div>
  </ng-container>
  <div class="col-12">
    <div class="remarks-container">
      <div class="text">{{ 'coordination-details-remarks' | translate }}</div>
      <textarea
        class="remarks-textarea remarks"
        maxlength="2000"
        [(ngModel)]="coordinationDetails.remarks"
        (blur)="saveCoordinationDetails()">
      </textarea>
    </div>
  </div>
</div>


