<div class="sub-component-container">
  <div class="sub-title" *ngIf="!hideTitle">{{ resourceConfig.title | translate}}
  </div>
  <ng-container *ngIf="subStakeholders && subStakeholdersConfig && serviceOrderType?.serviceTypeEnumValue !== ServiceTypeEnum.COORDINATION_TRANSPORT">
    <div class="sub-title">{{ resourceConfig.title | translate}}
      <ng-content class=""></ng-content>
    </div>
    <div class="sub-content-container">
      <app-sub-stakeholders
        [stakeholderType]="stakeholderType"
        [(caseId)]="caseId"
        [stakeholders]="stakeholders"
        [(subStakeholders)]="subStakeholders"
        [(subStakeholdersConfig)]="subStakeholdersConfig"
        [preselectEndUser]="preselectUser"
        (endUserHasDateOfBirthEmit)="endUserHasDateOfBirth($event)"
        [disabled]="disableStakeholders"
        [serviceOrderType]="serviceOrderType">
      </app-sub-stakeholders>
    </div>
  </ng-container>
  <div class="sub-content-container" *ngIf="travellers">
    <div *ngFor="let flightLeg of travellers.flightLegs; let i = index">
      <app-sub-travel-info
        [(travelInfo)]="travellers.flightLegs[i]"
        [hideDeleteButton]="hideDeleteOnFlightLeg"
        [hideCabinClass]="hideCabinClass"
        [hideFlightInRefs]="hideFlightInRefs"
        [hideTicketDetails]="hideTicketDetails"
        (delete)="deleteTravelInfo($event)"
        [cabinClassRequired]="cabinClassRequired"
        [flightNoRequired]="flightNoRequired"
        [hideRemarks]="hideRemarks"
        [hideTicketNo]="hideTicketNo"
        [hideAirlineReference]="hideAirlineReference"
        [agencyReferenceRequired]="agencyReferenceRequired"
        [departureDateRequired]="departureDateRequired"
        [departureFromRequired]="departureFromRequired"
        [arrivalDateRequired]="arrivalDateRequired"
        [arrivalDateWhite]="arrivalDateWhite"
        [arrivalAtRequired]="arrivalAtRequired"
        [readOnlyMode]="readOnlyMode"
        [remarksReadOnlyMode]="remarksReadOnlyMode"
        [resourceConfig]="resourceConfig.travelInfoResourceConfig"
        [minDate]="minMoment"
        [subStakeholders]="subStakeholders"
        [showRelevantForCase]="showRelevantForCase"
        [(iccFlightLegRelevantForCaseList)]="iccFlightLegRelevantForCaseList"
        [relevantForCaseReadOnly]="relevantForCaseReadOnly"
        [relevantForCaseId]="caseId"
      >
      </app-sub-travel-info>
    </div>
  </div>
  <button *ngIf="!hideAddFlightLeg" class="primary-button ms-3 mb-3" (click)="addFlightLeg()" [disabled]="readOnlyMode">{{'sub-travellers-add-flightleg' | translate}}</button>
</div>
