<div class="container">
  <div class="row">
    <div class="col">
      <app-drop-down
        [items]="financialStatusList"
        [selectedItemId]="status"
        (selectedItemIdChange)="handleStatusChange($event)"
        [translatePipePath] = "'case-economy-financial-status-'"
        *permission="PermissionEnum.SALES_ORDER_UPDATE; hideType: PermissionHideTypeEnum.DISABLE"
      >
      </app-drop-down>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="comment">{{!!financialStatus? financialStatus.comment : ''}}</div>
    </div>
  </div>
</div>

<ng-template #commentModalTemplate let-modal>
  <div class="comment-modal">
    <div class="comment-modal-header">
      <span *ngIf="status == FinancialStatusEnum.CLOSED_NO_INVOICE">{{'case-economy-financial-status-comment-title' | translate}}</span>
      <span *ngIf="status == FinancialStatusEnum.ON_HOLD_AWAITING_INFO">{{'case-economy-financial-status-on-hold-comment-title' | translate}}</span>
    </div>
    <div class="input-title-name">{{'case-economy-financial-status-comment-desc' | translate}}</div>
    <div class="comment-modal-body">
      <textarea class="comment-modal-body-input"
                maxlength="255"
                [(ngModel)]="enteredComment">
      </textarea>
    </div>
    <div class="comment-modal-footer">
      <button class="comment-modal-footer-button-save" (click)="handleCommentAdded()"
              [class.comment-modal-footer-button-save-disabled]="!enteredComment"
              [disabled]="!enteredComment">{{'case-economy-financial-status-save' | translate}}</button>
      <button class="comment-modal-footer-button"
              (click)="handleCommentDiscarded()">{{'message-dialog-close-and-discard' | translate}}</button>
    </div>
  </div>
</ng-template>

