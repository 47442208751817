import { Component, Input } from '@angular/core';
import { BalanceSheetEvent, BalanceSheetProgress } from '@secca/shared/models/balance-sheet-event.model';
import { BalanceSheetEntryEnum, BalanceSheetEventEnum, BalanceSheetStatusEnum } from '@secca/shared/models/enums';

@Component({
  selector: 'app-economy-process-bar',
  templateUrl: './economy-process-bar.component.html',
  styleUrls: ['./economy-process-bar.component.scss'],
})
export class EconomyProcessBarComponent {
  @Input() eventsToDisplay: BalanceSheetEventEnum[];
  @Input() hidden: BalanceSheetEventEnum[] = [];
  @Input() identifier: string;
  @Input() type: BalanceSheetEntryEnum;
  @Input() get progress(): BalanceSheetProgress {
    return this._progress;
  }
  set progress(progress: BalanceSheetProgress) {
    this._progress = progress;
  }

  private _progress: BalanceSheetProgress;

  public getType(): string {
    return this.type;
  }

  public isFirst(event: BalanceSheetEventEnum): boolean {
    const noneHidden = this.getNoneHidden();
    return noneHidden.length > 0 && noneHidden[0] === event;
  }

  private getNoneHidden() {
    return this.eventsToDisplay.filter(e => !this.isHidden(e));
  }

  public isHidden(event: BalanceSheetEventEnum): boolean {
    return this.hidden.includes(event);
  }

  public hideAuditPopover(event: BalanceSheetEventEnum): boolean {
    return !this.identifier || this.getEvent(event)?.modifierList.length === 0;
  }

  private hasReachableStatus(event: BalanceSheetEventEnum) {
    const elementStatus = this.getEvent(event)?.status;
    const reachable = [BalanceSheetStatusEnum.SUCCESS, BalanceSheetStatusEnum.WARNING, BalanceSheetStatusEnum.FAILURE];
    return reachable.includes(elementStatus);
  }

  public isCurrent(event: BalanceSheetEventEnum): boolean {
    return event === this._progress.currentEvent;
  }

  public isCurrentAndSuccess(event: BalanceSheetEventEnum): boolean {
    return event === this._progress.currentEvent && this.getEvent(event)?.status === BalanceSheetStatusEnum.SUCCESS;
  }


  public isLast(event: BalanceSheetEventEnum): boolean {
    const noneHidden = this.getNoneHidden()
    return noneHidden.length === 0 || noneHidden[noneHidden.length - 1] === event;
  }

  public isAggregatedOrAnySubsequent(event: BalanceSheetEventEnum): boolean {
    if (this._progress.aggregated) {
      let currentIndex = null;
      let eventIndex = null;
      Object.keys(BalanceSheetEventEnum).forEach((key, index) => {
        BalanceSheetEventEnum[key] === this._progress.currentEvent && currentIndex === null ? currentIndex = index : null;
        BalanceSheetEventEnum[key] === event && eventIndex === null ? eventIndex = index : null;
      });
      return eventIndex <= currentIndex;
    }
    return this.getEvent(event)?.status === BalanceSheetStatusEnum.WARNING;
  }
  public isAggregatedStatus(event: BalanceSheetEventEnum): boolean {
    if (this._progress.aggregated) {
      return this.hasReachableStatus(event);
    }
    return false;
  }

  public getEvent(element: BalanceSheetEventEnum): BalanceSheetEvent {
    if (!!this._progress.events) {
      return this._progress.events.find(e => e.event === element);
    }
    return null;
  }

  isAnyCancelled(): boolean {
    if (!this._progress.events || this._progress.events.length === 0) {
      return false;
    }
    let cancelled = false;
    Object.keys(BalanceSheetEventEnum).forEach((key) => {
      cancelled = cancelled || this.getEvent(BalanceSheetEventEnum[key])?.status === BalanceSheetStatusEnum.CANCELLED;
    });

    return cancelled;
  }

  isAnyCancelledOrAfterCurrent(event: BalanceSheetEventEnum): boolean {
    if (!this._progress.events || this._progress.events.length === 0) {
      return false;
    }
    let currentIndex = null;
    let eventIndex = null;
    let cancelled = false;
    Object.keys(BalanceSheetEventEnum).forEach((key, index) => {
      BalanceSheetEventEnum[key] === this._progress.currentEvent && currentIndex === null ? currentIndex = index : null;
      BalanceSheetEventEnum[key] === event && eventIndex === null ? eventIndex = index : null;
      cancelled = cancelled || this.getEvent(BalanceSheetEventEnum[key])?.status === BalanceSheetStatusEnum.CANCELLED;
    });

    return cancelled || eventIndex > currentIndex;
  }

  isAfterCurrent(event: BalanceSheetEventEnum): boolean {
    if (!this._progress.events || this._progress.events.length === 0) {
      return false;
    }
    let currentIndex = null;
    let eventIndex = null;
    Object.keys(BalanceSheetEventEnum).forEach((key, index) => {
      BalanceSheetEventEnum[key] === this._progress.currentEvent && currentIndex === null ? currentIndex = index : null;
      BalanceSheetEventEnum[key] === event && eventIndex === null ? eventIndex = index : null;
    });

    return eventIndex > currentIndex
  }

}
