import { ValidationTypeEnum } from '@secca/shared/models/enums';
import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { PersonInsurance } from 'src/app/shared/models/person-insurance';
import { CaseService } from 'src/app/core/services/case.service';
import { DictionaryService } from 'src/app/core/services/dictionary.service';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import { UpdateTimeLineService } from 'src/app/core/services/updateTimeLine.service';
import { InsuranceService } from '@secca/core/services/insurance.service';
import { TranslateService } from '@ngx-translate/core';
import { PolicyInsuranceType, PolicyLookupMethodType } from '@secca/shared/models/policy-lookup-response';
import { ProductPurposesOfTravel } from '@secca/shared/models/productPurposesOfTravel';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import { IncidentService } from '@secca/core/services/incident.service';
import { PolicyLookupService } from '@secca/case/components/case-basics/case-basics-insurance/policy-lookup/policy-lookup-service';
import { PermissionService } from '@secca/core/services/permission.service';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { RangeSupport } from '@secca/shared/components/input-date-time/range-support';
import { catchError, map, tap } from 'rxjs/operators';
import { forkJoin, of, Subscription } from 'rxjs';
import { ProfileChangeService } from '@secca/case/components/case-basics/case-basics-insurance/profile-change-service';
import { CoverageChangeService } from '@secca/case/components/case-basics/case-basics-insurance/coverage-change-service';
import { ProductChangeService } from '@secca/case/components/case-basics/case-basics-insurance/product-change-service';
import { ServiceOrderService } from '@secca/core/services/service-order.service';
import { ModalDialogComponent } from '@secca/shared/components/modal-dialog/modal-dialog.component';
import { ModalDialogConfiguration } from '@secca/shared/models/modal/modal-dialog-configuration';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { CaseBasicsInsuranceProfileComponent } from '../case-basics-insurance-profile/case-basics-insurance-profile.component';
import { DialogHelperUtilService } from '@secca/core/services/dialog-helper-util.service';
import { MinimizableDialogType } from '@secca/shared/enums/minimizable-dialog-type-enum';
import { DialogStateService } from '@secca/core/state-services/dialog-state.service';
import { DigitalConsent } from '@secca/shared/models/digital-consent';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { CaseDialogViewerService } from '@secca/case/case-dialog-viewer.service';
import * as moment from 'moment';
import { ProfileUpdateInProcessService } from '@secca/case/components/case-basics/case-basics-insurance/profile-update-in-process';

@Component({
  selector: 'app-case-basics-insurance-details',
  templateUrl: './case-basics-insurance-details.component.html',
  styleUrls: ['./case-basics-insurance-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
@AutoUnsubscribe
export class CaseBasicsInsuranceDetailsComponent {
  @ViewChild(CaseBasicsInsuranceProfileComponent) caseBasicsInsuranceProfileComponent: CaseBasicsInsuranceProfileComponent;
  insuranceLevels: DropdownDictionary[] = [];
  areas: DropdownDictionary[];
  lookupMethod: DropdownDictionary[];
  purposeOfTravel: ProductPurposesOfTravel;
  extensionPeriodRangeSupport: RangeSupport;
  policyMatchSubscription: Subscription;
  incidentDate: moment.Moment = null;
  oldInsurance = new PersonInsurance();
  numberOfProducts: number = 0;

  _personInsurance: PersonInsurance;
  private policyHolder: CaseStakeholder;

  @Input() consent: DigitalConsent;

  @Input()
  set personInsurance(newPersonInsurance: PersonInsurance) {
    if (JSON.stringify(newPersonInsurance) === JSON.stringify(this._personInsurance)) {
      this.saveSnapshot();
      return;
    }
    const productChanged =
      newPersonInsurance.customerProduct !== this?._personInsurance?.customerProduct &&
      newPersonInsurance.customerProduct != null &&
      (newPersonInsurance.id === this?._personInsurance?.id || this?._personInsurance?.id == null);
    if (productChanged) {
      this.refreshPurposesOfTravel(newPersonInsurance.customerProduct);
      this.refreshLevelsAndAreas(newPersonInsurance.customerProduct);
    }
    this._personInsurance = newPersonInsurance;
    this.initializeRangeSupport();
    this.saveSnapshot();
  }

  get personInsurance(): PersonInsurance {
    return this._personInsurance;
  }

  _caseId: string;

  @Input()
  set caseId(newCaseId: string) {
    this._caseId = newCaseId;
    if (newCaseId != null) {
      this.init();
      this.policyholderSubscription = this.caseService.observePolicyholder().subscribe(caseStakeholder => {
        this.policyHolder = caseStakeholder;
        if (this.policyHolder && this.policyHolder.isCompany) {
          this.creditCardDisabledPopUpText = 'case-basics-insurance-details-policyholder-company-info-popup';
        } else {
          this.creditCardDisabledPopUpText = null;
        }
      });
    }
  }

  get caseId(): string {
    return this._caseId;
  }

  set insurancePolicyLookupMethod(value: PolicyLookupMethodType) {
    if (!this.personInsurance.insuranceLookupPolicy) {
      this.personInsurance.manualLookupMethod = value;
    }
  }

  get insurancePolicyLookupMethod(): PolicyLookupMethodType {
    if (this.personInsurance.insuranceLookupPolicy) {
      return this.personInsurance.insuranceLookupPolicy.lookupExecution.type;
    }
    return this.personInsurance.manualLookupMethod;
  }

  set insurancePolicyLookupTime(value: moment.Moment) {
    if (!this.personInsurance.insuranceLookupPolicy) {
      this.personInsurance.manualLookupTime = value;
    }
  }

  get insurancePolicyLookupTime(): moment.Moment {
    if (this.personInsurance.insuranceLookupPolicy) {
      return this.personInsurance.insuranceLookupPolicy.lookupExecution.lookupTime;
    }
    return this.personInsurance.manualLookupTime;
  }

  private policyholderSubscription: Subscription;
  creditCardDisabledPopUpText: string;

  constructor(
    private dictionaryService: DictionaryService,
    private caseService: CaseService,
    private insuranceService: InsuranceService,
    private updateTimeLineService: UpdateTimeLineService,
    private translate: TranslateService,
    private incidentService: IncidentService,
    public permissionService: PermissionService,
    public caseLockHelperService: CaseLockHelperService,
    private policyLookupService: PolicyLookupService,
    private changeCoverageService: CoverageChangeService,
    private profileChangeService: ProfileChangeService,
    private profileUpdateInProcessService: ProfileUpdateInProcessService,
    private productChangeService: ProductChangeService,
    private serviceOrderService: ServiceOrderService,
    private dialogHelperUtilService: DialogHelperUtilService,
    private dialogStateService: DialogStateService,
    public caseStateService: CaseStateService,
    private modalService: NgbModal,
    private dialogViewerService: CaseDialogViewerService
  ) {
    this.initializeLookupMethods();
  }

  private initializeLookupMethods() {
    this.dictionaryService.getInsuranceLookupMethodList().subscribe(result => {
      this.lookupMethod = result.map(item => new DropdownDictionary(item, this.translate.instant('policy-lookup-method-type-' + item)));
      this.lookupMethod.splice(this.lookupMethod.findIndex(i => i.id === 'NO_INTEGRATION'));
      this.lookupMethod.push(new DropdownDictionary(null, ''));
    });
  }

  private saveSnapshot() {
    this.oldInsurance = JSON.parse(JSON.stringify(this.personInsurance));
  }

  private init() {
    this.policyMatchSubscribe();
  }

  private initializeRangeSupport() {
    this.extensionPeriodRangeSupport = new RangeSupport(
      this.personInsurance.insuranceExtensionFrom,
      this.personInsurance.insuranceExtensionTo,
      this,
      this.updateExtensionFromDate,
      this.updateExtensionToDate,
      null
    );
  }

  shouldLevelCauseUpdate(newList: boolean): boolean {
    if (this._personInsurance == null) {
      return false;
    }
    if (!this.insuranceLevels && this.personInsurance.insuranceLevelId) {
      return true;
    } else if (!this.insuranceLevels && !this.personInsurance.insuranceLevelId) {
      return false;
    } else if (newList && this.insuranceLevels.length === 1 && this.personInsurance.insuranceLevelId !== this.insuranceLevels[0].id) {
      this.personInsurance.insuranceLevelId = this.insuranceLevels[0].id;
      return true;
    }
    return false;
  }

  get ValidationTypeEnum() {
    return ValidationTypeEnum;
  }

  insurancePolicyLookupTimeChange(lookupTime) {
    this.insurancePolicyLookupTime = lookupTime;
    this.updateInsurance();
  }

  onCustomerProfileDropDownOpen() {
    const dialogRef = this.dialogHelperUtilService.getOpenDialogWithCaseIdAndType(this.caseId, MinimizableDialogType.ProfileLookup);
    if ( dialogRef ) {
      this.caseBasicsInsuranceProfileComponent?.close();
      this.dialogStateService.maximize(dialogRef.id);
    }
  }

  updateInsuranceCustomerProfile(customerProfileId: number) {
    if (this.oldInsurance.customerProfileId !== customerProfileId) {
      this._personInsurance.customerProfileId = customerProfileId;
      this.onProfileChange();
      this.profileUpdateInProcessService.publish(this.caseId, true);
      this.insuranceService.updateCustomerProfile(this._personInsurance).subscribe(
        () => {
          this.profileChangeService.publish(this.caseId, customerProfileId);
          this.saveSnapshot();
          this.profileUpdateInProcessService.publish(this.caseId, false);
        }, error => {
          this._personInsurance.customerProfileId = this.oldInsurance.customerProfileId;
          this.profileUpdateInProcessService.publish(this.caseId, false);
        });
    }
  }

  updateInsurance(type = '') {
    if (this.personInsurance.customerProfileId !== this.oldInsurance.customerProfileId) {
      this.onProfileChange();
    }
    this.insuranceService.updatePersonInsuranceWithQueueService(this.caseId, this.personInsurance);
    this.notifySubscribersOfChange(type);
    this.saveSnapshot();
  }

  onProfileChange() {
    this.personInsurance.insuranceLevelId = null;
    this.personInsurance.geographicalCoverageArea = null;
    this.personInsurance.customerProduct = null;
    this.personInsurance.coverageDays = null;
    this.personInsurance.coverageWeeks = null;
    this.personInsurance.coveragePeriodFromLookup = false;
  }

  private notifySubscribersOfChange(type: string) {
    if (type === 'Coverage') {
      this.updateTimeLineService.changedInsurance(this.personInsurance);
    }
    if (type === 'productChange') {
      this.refreshPurposesOfTravel(this.personInsurance.customerProduct);
    }
  }

  viewPolicyLookup(): void {
    this.dialogViewerService.openPolicyViewDialog(this.caseId);
  }

  refreshLevelsAndAreas(productId: number, forceUpdate = false ) {
    if (productId == null) {
      this.updateInsurance('productChange');
      return;
    }
    const productLevelUpdateCmd = this.createProductLevelIdRefreshRequest(productId);
    const geoCoverageAreaUpdateCmd = this.createGeoCoverageAreaRefreshRequest(productId);

    forkJoin([productLevelUpdateCmd, geoCoverageAreaUpdateCmd]).subscribe(results => {
      const updated = results.filter(res => !res.isError).reduce((acc, value) => acc || value.body, false);

      if (forceUpdate || updated) {
        this.saveSnapshot();
        this.insuranceService.updatePersonInsurance(this.caseId, this.personInsurance)
          .subscribe(() => {} );
      }

      results
        .filter(res => res.isError)
        .forEach(res => {
          throw res.body;
        });
    });
  }

  createProductLevelIdRefreshRequest(productId: number) {
    return this.caseService.getProductLevels(productId).pipe(
      tap(levels => {
        this.insuranceLevels = levels;
      }),
      map(() => {
        const performUpdate = this.shouldLevelCauseUpdate(true);
        return {isError: false, body: performUpdate};
      }),
      catchError(error => {
        this.insuranceLevels = null;
        return of({isError: true, body: error});
      })
    );
  }

  createGeoCoverageAreaRefreshRequest(productId: number) {
    return this.caseService.getProductGeoCoverageAreas(productId).pipe(
      tap(areas => {
        this.areas = areas;
      }),
      map(areas => {
        const productChanged = productId === this.oldInsurance.customerProduct;
        const performUpdate = this.coverageAreasCausedChanges(areas, productChanged);
        return {isError: false, body: performUpdate};
      }),
      catchError(error => {
        this.areas = null;
        return of({isError: true, body: error});
      })
    );
  }

  coverageAreasCausedChanges(areas: DropdownDictionary[], sameProduct: boolean): boolean {
    if (this._personInsurance == null) {
      return false;
    }
    if (areas.length === 1 && !sameProduct) {
      this.personInsurance.geographicalCoverageArea = areas[0].id;
      return true;
    } else if (!sameProduct && this.personInsurance.geographicalCoverageArea != null) {
      this.personInsurance.geographicalCoverageArea = null;
      return true;
    }
    return false;
  }

  setCoveragePeriod(product: any) {
    this.changeCoverageService.updateCoverage(product);
  }

  private policyMatchSubscribe() {
    if (this.policyMatchSubscription) {
      this.policyMatchSubscription.unsubscribe();
    }
    this.policyMatchSubscription = this.policyLookupService.getPolicyMatch(this.caseId).subscribe(
      result => {
        if (result != null) {
          this._personInsurance.insuranceLookupMatch = result;
        }
      }
    );
  }

  policyLookupStatus() {
    if (!!this.personInsurance.insuranceLookupPolicy) {
      return 'success';
    } else if (this.personInsurance.insuranceLookupMatch && !this.personInsurance.insuranceLookupPolicy) {
      return 'warn';
    } else if (this.personInsurance.insuranceLookupMatch === false) {
      return 'failure';
    }
    return 'unset';
  }

  productLevelChangeHandler(productId: string) {
    this.personInsurance.insuranceLevelId = productId;
    this.personInsurance.customerProduct = Number(productId);
    this.caseService.getCustomerProduct(Number(productId)).subscribe(result => {
      this.productChangeService.publish(this.caseId, productId);
      this.personInsurance.creditCard = PolicyInsuranceType.CARD === result.insuranceType;
      this.personInsurance.percentagePaidWithCreditCard = result.percentagePaidByCard;
      this.setCoveragePeriod(result);
      const geoCoverageAreaRefreshRequest = this.createGeoCoverageAreaRefreshRequest(this.personInsurance.customerProduct);
      geoCoverageAreaRefreshRequest.subscribe(() =>
        this.insuranceService.updatePersonInsurance(this.caseId, this.personInsurance).subscribe(
          () => {
            this.refreshPurposesOfTravel(this.personInsurance.customerProduct);
          }
        ));
    });
  }
  updateNumberOfProducts(numbers: number){
    this.numberOfProducts = numbers;
  }

  onProductChange(id: number) {
    this._personInsurance.customerProduct = id;
    if (id === this.oldInsurance.customerProduct) {
      return;
    }
    this.personInsurance.coverageDays = null;
    this.personInsurance.coverageWeeks = null;
    this.personInsurance.coveragePeriodFromLookup = false;
    this.personInsurance.geographicalCoverageArea = null;
    this.personInsurance.insuranceLevelId = null;
    this.refreshPurposesOfTravel(id);
    this.refreshLevelsAndAreas(id, true);
    this.insuranceService.customerProductChanged(this.caseId, id);
  }

  refreshPurposesOfTravel(customerProduct: number) {
    if (customerProduct == null) {
      return;
    }
    this.caseService.getProductPurposesOfTravel(customerProduct).subscribe(
      result => {
        this.purposeOfTravel = result;
      },
      ignoreError => {
        this.purposeOfTravel = null;
      }
    );
  }

  isGeographicAreaDisabled(): boolean {
    let isGeographicAreaDisabled = !this.personInsurance.customerProduct;
    isGeographicAreaDisabled =
      isGeographicAreaDisabled ||
      (this.personInsurance.geographicalCoverageArea &&
        this.personInsurance.insuranceLookupPolicy &&
        this.areas &&
        this.areas.length === 1);
    return isGeographicAreaDisabled;
  }

  canSwitchToCreditCard() {
    return this.policyHolder && !this.policyHolder.isCompany;
  }

  isInsuranceLevelDisabled(): boolean {
    return !this.personInsurance.customerProduct
      || !this.insuranceLevels || this.insuranceLevels.length < 2
      || this.personInsurance?.insuranceLookupPolicy?.insuranceLevel != null;
  }

  updateExtensionFromDate(extensionFrom: moment.Moment, extensionTo: moment.Moment) {
    this.personInsurance.insuranceExtensionFrom = extensionFrom;
    this.personInsurance.insuranceExtensionTo = extensionTo;
    this.updateInsurance();
  }

  updateExtensionToDate(extensionTo: moment.Moment, extensionFrom: moment.Moment) {
    this.personInsurance.insuranceExtensionTo = extensionTo;
    this.personInsurance.insuranceExtensionFrom = extensionFrom;
    this.updateInsurance();
  }

  showWarningIfProductOrLevelChange(productId: string) {
    this.serviceOrderService.isCoveragesUsedOnExistingServiceOrders(+this.caseId).subscribe(coveragesExist => {
      if (coveragesExist) {
        const modalRef = this.modalService.open(ModalDialogComponent);
        modalRef.componentInstance.items = [];
        modalRef.componentInstance.configuration = new ModalDialogConfiguration({
          header: 'default-modal-header',
          title: 'changing-service-orders-with-coverages',
          text: null,
          footer: 'case-validation-warning-modal-footer',
          yes: 'default-modal-dialog-yes',
          no: 'default-modal-dialog-no',
          isBody: true,
          isFooter: true,
        });
        modalRef.componentInstance.closeModalEvent.subscribe(
          closedClickingYes => {
            this.modalService.dismissAll();
            if (closedClickingYes) {
              this.productLevelChangeHandler(productId);
            } else {
              this.personInsurance.insuranceLevelId = this.oldInsurance.insuranceLevelId;
            }
          },
          error => console.log(error)
        );
      } else {
        this.productLevelChangeHandler(productId);
      }
    });
  }
}
