import { Injectable } from "@angular/core";
import { Adapter } from "../interfaces/adapter";
import { Dictionary } from "./dictionary";

export class MessageFromAddress extends Dictionary {

  public constructor(id, name) {
    super(id, name);
  }
}


@Injectable({
  providedIn: 'root'
})
export class MessageFromAddressAdapter implements Adapter<MessageFromAddress> {

  constructor() {
  }

  adapt(item: any): MessageFromAddress {
    return new MessageFromAddress(
      item.name,
      item.address
    );
  }
}