import {KeycloakOptions, KeycloakService} from 'keycloak-angular';
import {SettingsService} from '../core/services/settings.service';

export function initializeAppSSO(keycloak: KeycloakService, settingsService: SettingsService): Promise<any> {

  if (settingsService.isSsoDisabled()) {
    console.log('SSO is disabled!');
    return Promise.resolve(null);
  }

  const keycloakOptions: KeycloakOptions = {
    config: {
      url: settingsService.getKeyCloakUrl(),
      realm: 'secca',
      clientId: 'secca-case-management-system-ui',
    },
    initOptions: {
        checkLoginIframe: false,
        onLoad: 'login-required'
    },
    bearerExcludedUrls: ['https://maps.googleapis.com/*']
  };
  return keycloak.init(keycloakOptions);

}

