<div class="multiple-select-container">
  <span class="multiple-select-name">{{name}}</span>
  <div class="selected-item-box" [ngClass]="{'selected-item-box-required' : this.required && this.selectedItems?.length < 1, 'selected-item-box-recommended' : recommended}"

       (click)="toggleShowDropdown($event)">
      <ng-container class="d-flex" *ngFor="let selecteditem of selectedItems">
        <div class="d-flex label-box" [ngClass]="{'label-box-red': alternativeBoxStyle || selecteditem?.redBoxStyle}">
        <div class="label-name">
          {{selecteditem.icd10Code}}-{{selecteditem.shortName}}
        </div>
        <div class="remove-label" *ngIf="!disabled" (click)="removeLabelClick(selecteditem.icd10Code)">
          <img src="../../../../assets/icons/Close White.svg" class="close-img">
        </div>
      </div>
    </ng-container>
    <div class="up-down" *ngIf="!disabled">
      <img src="../../../../assets/icons/ArrowDown.svg" tabindex="0"
           (keypress)="toggleShowDropdown($event)">
    </div>
  </div>
  <div class="dropdown" *ngIf="showDropdown && !disabled">
    <input #tableText class="search-text" [(ngModel)]="filteringText" type="text" (input)="getDropdownValues($event.target.value)"
           placeholder="{{'multiple-select-component-search' | translate}}" (keydown.arrowDown)="arrowDownSearchTextPressed($event)">
    <div class="dropdown-container">
      <div #dropdownCheckbox class="dropdown-value" *ngFor="let dropdownRow of dropdownValueList" tabindex="0"
           (click)="clickSelectItem($event, dropdownRow)" (keypress)="clickSelectItem($event, dropdownRow)"
           (mouseover)="focusCurrent($event)"
           (keydown.arrowDown)="arrowItemPressed($event,false)" (keydown.arrowUp)="arrowItemPressed($event, true)">
        <div class="dropdown-checkbox">
          <img [@toggleChecked]="dropdownRow.selected ? 'checked' : 'unchecked'"
               class="checked" src="../../../../assets/icons/Checked.svg">
        </div>
        <div class="dropdown-label-name">
          {{dropdownRow.shortName}}
        </div>
      </div>
    </div>
  </div>
</div>
