<div class="case-basics-card case-basics-planned-travel-details-container" *ngIf="plannedTravel">
<div [appDisableInput]="caseStateService.isCaseDisabled()">
  <div class="field-row" *ngIf="travelPurposeEnum === getTravelPurposeEnum.EXPATRIATION">
    <div class="planed-travel-arrival-departure-column col-6">
      <div>
        <app-input-calendar
          [withImage]="true"
          [name]="'case-basics-planned-travel-expatriation-period'| translate "
          (dateChange)="rangeExpatriationSupport.updateStartDate($event)"
          [date]="plannedTravel.expatriationDepartureDate"
          [invalid]="rangeExpatriationSupport.isStartDateInValidInRange()"
          [disabled]="caseLockHelperService.isCaseBasicLockTaken || permissionService.isReadOnly"
          ></app-input-calendar>

        </div>
      <div>
        <app-input-calendar
        [withImage]="true"
        [name]="' '"
        (dateChange)="rangeExpatriationSupport.updateEndDate($event)"
        [date]="plannedTravel.expatriationArrivalDate"
        [min]="plannedTravel.expatriationDepartureDate"
        [invalid]="rangeExpatriationSupport.isStartDateInValidInRange()"
        [disabled]="caseLockHelperService.isCaseBasicLockTaken || permissionService.isReadOnly"
        ></app-input-calendar>
      </div>
    </div>
    <div class=" col-6" >
      <div class="yellowBox">
        {{ 'case-basics-planned-travel-expatriate-insurance-product' | translate }}
      </div>
    </div>
  </div>
  <div class="field-row" *ngIf="travelPurposeEnum === getTravelPurposeEnum.EXPATRIATION">
    <div class="col-12">
      <app-input-location [name]="'case-basics-planned-travel-expatriation-destination' | translate"
            placeholder=""
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
            type="text"
            [(value)]="plannedTravel.expatriationLocation"
            [allowEmpty]="true"
            (saveModel)="saveModel(plannedTravel)"
            [withImage]="true"
            [disabled]="caseLockHelperService.isCaseBasicLockTaken || permissionService.isReadOnly"
            [validationType]="ValidationTypeEnum.empty">
      </app-input-location>
    </div>
  </div>
  <div class="field-row h-25" *ngIf="travelPurposeEnum === getTravelPurposeEnum.EXPATRIATION" >
    <div class="col-12">
      <div class="vertical-line" ></div>
    </div>
  </div>
  <div class="field-row">
    <div class="planed-travel-arrival-departure-column col-6">
      <div>
        <app-input-calendar
          [withImage]="true"
          [name]=" 'planned-travel-departure-name'| translate"
          (dateChange)="rangeSupport.updateStartDate($event)"
          [date]="plannedTravel.departureDate"
          [invalid]="rangeSupport.isStartDateInValidInRange()"
          [recommended]="plannedTravel.departureDate === null"
          ></app-input-calendar>
        </div>
      <div>
        <app-input-calendar
        [withImage]="true"
        [name]="getTextForPlannedTravelDepartureName() | translate"
        (dateChange)="rangeSupport.updateEndDate($event)"
        [date]="plannedTravel.arrivalDate"
        [invalid]="rangeSupport.isEndDateInvalidInRange()"
        [recommended]="plannedTravel.arrivalDate === null"
        ></app-input-calendar>
      </div>
    </div>
    <div class="days-and-weeks col-6">
      <div>
        <app-input [name]="'planned-travel-duration-days' | translate" [value]="getDurationDays()" [disabled]="true"
                   [isValid]="isDepartureAndArrivalValid()">
        </app-input>
      </div>
      <div>
        <app-input [name]="'planned-travel-duration-weeks' | translate" [value]="getDurationWeeks()" [disabled]="true"
                   [isValid]="isDepartureAndArrivalValid()">
        </app-input>
      </div>
    </div>
  </div>
  <div class="field-row">
    <div class="col-12">
      <div class="travel-plan-container">
        <div class="travel-plan-location">
          <app-input-location [name]="'planned-travel-destination' | translate" placeholder="" autocorrect="off" autocapitalize="off"
                              spellcheck="false" type="text"
                              [(value)]="plannedTravel.destinationLocation" [allowEmpty]="true" (saveModel)="saveModel(plannedTravel)" [withImage]="true"
                              [validationType]="ValidationTypeEnum.empty" [recommended]="plannedTravel.destinationLocation.freeText === '' || plannedTravel.destinationLocation.freeText === null">
          </app-input-location>
        </div>
        <div *ngIf="hasSubTravelPlans()" class="travel-plan-trashcan main-travel-plan">
          <span (click)="deleteMainTravelDestination(plannedTravel)"><img src="/assets/icons/garbage.svg"></span>
        </div>
      </div>
    </div>
  </div>
  <div *ngFor="let plannedTravelSubPlan of plannedTravelSubPlans; index as i" class="field-row sub-travel-plan">
    <div class="col-12">
      <div class="travel-plan-container">
        <div class="travel-plan-location">
          <app-input-location placeholder="" autocorrect="off" autocapitalize="off"
                              spellcheck="false" type="text"
                              [withLabel]="false"
                              [(value)]="plannedTravelSubPlan.destinationLocation" [allowEmpty]="true" (saveModel)="saveModel(plannedTravelSubPlan)" [withImage]="true"
                              [validationType]="ValidationTypeEnum.empty" [recommended]="plannedTravelSubPlan.destinationLocation.freeText === '' || plannedTravelSubPlan.destinationLocation.freeText === null">
          </app-input-location>
        </div>
        <div class="travel-plan-trashcan sub-travel-plan">
          <span (click)="deleteSubTravelDestination(plannedTravelSubPlan)"><img src="/assets/icons/garbage.svg"></span>
        </div>
      </div>
    </div>
  </div>
  <div class="row add-destination-label">
    <div class="col-12">
      <span (click)="addSubTravelDestination()" [ngClass]="isMaxDestinationsCreated() ? 'label-disabled' : 'label-enabled'"
            [ngbPopover]="'planned-travel-max-destinations-created' | translate" 
            [disablePopover]="!isMaxDestinationsCreated()"
            placement="auto"
            triggers="hover">
            {{'planned-travel-add-destination' | translate}}
      </span>      
    </div>
  </div>
  <div class="field-row">
    <div class="col-12">
      <app-multiple-select-dropdown [name]="'planned-travel-purpose-of-travel-title' | translate" [dropdownList]="purposeOfTravelList"
                                    (selectedItemsChange)="updateTravelPurposes($event) "
                                    [(selectedItems)]="selectedPurposesOfTravel" [recommended]="selectedPurposesOfTravel?.length === 0"></app-multiple-select-dropdown>
    </div>
  </div>
  <div class="field-row">
    <div class="col-6">
      <app-drop-down  [name]="'planned-travel-means-of-transportation' | translate" (saveModel)="saveModel(plannedTravel)"
                     [items]="meansOfTransportationList"
                     [(selectedItemId)]="plannedTravel.meansOfTransportation" [recommended]="plannedTravel.meansOfTransportation === null"></app-drop-down>
    </div>
    <div class="col-6">
      <app-drop-down [name]="'planned-travel-accommodation' | translate" (saveModel)="saveModel(plannedTravel)" [items]="accommodationList"
                     [(selectedItemId)]="plannedTravel.accommodation" [recommended]="plannedTravel.accommodation === null" ></app-drop-down>
    </div>
  </div>
  <div class="field-row">
    <div class="col-6">
      <app-input [name]="'planned-travel-co-travellers' | translate" [value]="plannedTravel.coTravellers"
        (saveModel)="updateCoTravellers($event)" [maxTextLength]="25"  [recommended]="plannedTravel.coTravellers === null || plannedTravel.coTravellers === ''">
      </app-input>
    </div>
    <div class="col-6">
      <app-input [name]="'planned-travel-travel-agency' | translate" [value]="plannedTravel.travelAgency" (saveModel)="updateTravelAgency($event)"
      [recommended]="plannedTravel.travelAgency === null || plannedTravel.travelAgency === ''">
      </app-input>
    </div>
  </div>
</div>
</div>
