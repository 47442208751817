<div class="cases-table">
  <div class="primary-table-container">
    <table class="primary-table fixed-header-table">
      <thead>
        <tr>
          <td (click)="sortBy('teamName')" [ngClass]="sortedBy('teamName')">{{"board-case-table-team" | translate}}</td>
          <td (click)="sortBy('customerProfileName')" [ngClass]="sortedBy('customerProfileName')">{{"board-case-table-customer" | translate}}</td>
          <td (click)="sortBy('createdOn')" [ngClass]="sortedBy('createdOn')">{{"board-case-table-case-creation-date" | translate}}</td>
          <td (click)="sortBy('caseNumber')" [ngClass]="sortedBy('caseNumber')">{{"board-case-table-case-number" | translate}}</td>
          <td (click)="sortBy('endUserFullName')" [ngClass]="sortedBy('endUserFullName')">{{"board-case-table-end-user-name" | translate}}</td>
          <td (click)="sortBy('dateOfBirth')" [ngClass]="sortedBy('dateOfBirth')">{{"board-case-table-age" | translate}}</td>
          <td (click)="sortBy('incidentCoveredState')" [ngClass]="sortedBy('covered')" >{{"board-case-table-covered" | translate}}</td>
          <td (click)="sortBy('incidentCountry')" [ngClass]="sortedBy('incidentCountry')">{{"board-case-table-incident-country" | translate}}</td>
          <td (click)="sortBy('cause')" [ngClass]="sortedBy('cause')">{{"board-case-table-cause" | translate}}</td>
          <td (click)="sortBy('caseHandler')" [ngClass]="sortedBy('caseHandler')">{{"board-case-table-case-handler" | translate}}</td>
          <td (click)="sortBy('reserve')" [ngClass]="sortedBy('reserve')">{{"board-case-table-reserve" | translate}}</td>
          <td (click)="sortBy('teamStatus')" [ngClass]="sortedBy('teamStatus')">{{"board-case-table-team-status" | translate}}</td>
          <td (click)="sortBy('claimsStatus')" [ngClass]="sortedBy('claimsStatus')" >{{"board-case-table-claims-status" | translate}}</td>
        </tr>
      </thead>
      <tbody (scroll)="onCaseTabelScroll()" id="caseTabel" [class.zoomed]="settingsService.isZoomed()">
        <tr *ngFor="let boardCase of boardCases" (click)="redirectToCase(boardCase.id)" [routerLink]="['/case', boardCase.id]">
          <td>{{boardCase.teamName}}</td>
          <td class="customer-image-column">
            <img class="customer-image" *ngIf="boardCase.customerProfileId"
              [src]="getBrandImagesUrlForProfile(boardCase.customerProfileId)"
              [ngbPopover]="boardCase.customerProfileName"
              placement="auto"
              triggers="hover">
          </td>
          <td>{{boardCase.createdOnLocalTime | date :'dd MMM yyyy'}}</td>
          <td><span [ngClass]="{'case-locked': boardCase.caseLockedBy && boardCase.caseLockedBy != loggedInUser.id}">{{boardCase.caseNumber}}</span></td>
          <td class="end-user-column">{{boardCase.endUserFullName}}</td>
          <td class="text-center pe-2">{{boardCase.age}}</td>
          <td class="long-text-field">
            <div
              [ngClass]="{ 'yes-col-off' :  true,
                          'yes-col-on' : boardCase.incidentCoveredSetByCaseHandler && boardCase.incidentCoveredState === YES,
                          'tbd-col-on' : boardCase.incidentCoveredSetByCaseHandler && boardCase.incidentCoveredState === TBD,
                          'no-col-on' : boardCase.incidentCoveredSetByCaseHandler && boardCase.incidentCoveredState === NO}">
              <div *ngIf = '!boardCase.incidentCoveredSetByCaseHandler && boardCase.incidentCoveredState === YES' class="yes-dot"></div>
              <div *ngIf = '!boardCase.incidentCoveredSetByCaseHandler && boardCase.incidentCoveredState === TBD' class="tbd-dot"></div>
              <div *ngIf = '!boardCase.incidentCoveredSetByCaseHandler && boardCase.incidentCoveredState === NO' class="no-dot"></div>
            </div>
          </td>
          <td class="long-text-field" ><flag-icon *ngIf="boardCase?.countryCodeAlpha2" [country]="boardCase.countryCodeAlpha2.toLowerCase()"></flag-icon>&nbsp;<span [ngbPopover]="boardCase.incidentCountry" placement="auto" triggers="hover">{{boardCase.incidentCountry}}</span></td>
          <td class="long-text-field">{{boardCase.incidentCause}}</td>
          <td>{{boardCase.caseHandler || caseHandlerTeamNameShortName(boardCase.caseHandlerTeamName)}}</td>
          <td class="reserve-column">
            <span
              *ngIf="boardCase.caseNumber">{{boardCase.reserve == null ?  0 : boardCase.reserve | numberFormat }}
              {{boardCase.billingCurrency}}</span>
          </td>
          <td>{{ !!boardCase.teamStatus ? ('HANDLING-AREA-' + boardCase.teamStatus | translate) : ''}}</td>
          <td> {{ !!boardCase.claimsStatus ? ('HANDLING-AREA-' + boardCase.claimsStatus | translate) : ''}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
