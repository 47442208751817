import { Injectable } from '@angular/core';
import { Adapter } from './../interfaces/adapter';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';

export class DropdownDictionary {
  id: any;
  name: any;

  public constructor(id?: any, name?: any) {
    this.id = id;
    this.name = name;
  }
}

@Injectable({
  providedIn: 'root',
})
export class DropdownAdapter implements Adapter<DropdownDictionary> {
  adapt(item: string): DropdownDictionary {
    return new DropdownDictionary(item, item);
  }
}

@Injectable({
  providedIn: 'root',
})
export class TeamDropdownAdapter implements Adapter<DropdownDictionary> {
  adapt(item: any): DropdownDictionary {
    return new DropdownDictionary(item.adtCode, item.teamName);
  }
}

@Injectable({
  providedIn: 'root',
})
export class ChannelDropdownAdapter implements Adapter<DropdownDictionary> {
  adapt(item: any): DropdownDictionary {
    return new DropdownDictionary(item.channelTypeEnumValue, item.channelTypeName);
  }
}

@Injectable({
  providedIn: 'root',
})
@Injectable({
  providedIn: 'root',
})
export class SupplierDropDownAdapter implements Adapter<DropdownDictionary> {
  adapt(item: CaseStakeholder): DropdownDictionary {
    let fullName: string;
    if (item.isCompany) {
      if (item.company.name != null && item.company.name !== '') {
        return new DropdownDictionary(item.id.toString(), item.company.name);
      }
    }
    if (!item.isCompany) {
      if (item.person.firstName != null && item.person.firstName !== '') {
        fullName = item.person.firstName;
      }
      if (item.person.surname != null && item.person.surname !== '') {
        fullName = fullName + ' ' + item.person.surname;
      }
    }
    return new DropdownDictionary(item.id.toString(), fullName);
  }

  adaptToIconList(item: CaseStakeholder): DropdownDictionary {
    return new DropdownDictionary(item.stakeholderType, item.imageName);
  }
}
export class DisableableDropdownDictionary extends DropdownDictionary {
  disabled: boolean;
  public constructor(id?: any, name?: any, disabled?: boolean) {
    super(id, name);
    this.disabled = disabled;
  }
}
