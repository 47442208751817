import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
import {DigitalCaseflowActivityLog, DigitalCaseflowActivityLogAdapter} from "@secca/shared/models/digital-caseflow-activity-log";
import * as moment from 'moment';
import {DateSerializer} from "@secca/shared/models/date-serializer";
export class DigitalCaseflowLog {
  flowInitiationDate: moment.Moment;
  activityLogs: DigitalCaseflowActivityLog[];
  sessionId: number;
  id: number;
  flowName: string;
  flowResult: string;
  public constructor(init?: Partial<DigitalCaseflowLog>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class DigitalCaseflowLogAdapter implements Adapter<DigitalCaseflowLog> {
  constructor(private digitalCaseflowActivityLogAdapter: DigitalCaseflowActivityLogAdapter) {}

  adapt(item: any): DigitalCaseflowLog {
    return new DigitalCaseflowLog({
      id : item.id,
      flowInitiationDate: item.flowInitiationDate ? DateSerializer.deserialize(item.flowInitiationDate) : null,
      activityLogs: item.activityLogs.map(log => this.digitalCaseflowActivityLogAdapter.adapt(log)),
      sessionId:item.sessionId,
      flowName: item.flowName,
      flowResult: item.flowResult
    });
  }
}
