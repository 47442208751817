import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ElementRef, ViewChild } from '@angular/core';
import { OWL_DATE_TIME_FORMATS} from '@danielmoncada/angular-datetime-picker';
import * as moment from 'moment';
import { target }  from 'on-change';

export const DATE_FORMATS = {
  fullPickerInput: 'DD MMM YYYY',
  datePickerInput: 'DD MMM YYYY',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
  serverSideDateFormat: 'YYYY-MM-DD',
  serverSideInput: 'YYYY-MM-DD',
};

@Component({
  selector: 'app-input-calender',
  templateUrl: './input-calender.component.html',
  styleUrls: ['./input-calender.component.scss'],
  providers: [
    { provide: OWL_DATE_TIME_FORMATS, useValue: DATE_FORMATS },
  ],
})
export class InputCalenderComponent implements OnInit, OnChanges {
  @ViewChild('dt1', { static: true }) dt1: any;
  @ViewChild('dt2', { static: true }) dt2: any;
  inputComp: ElementRef;

  @Input() required: boolean;
  @Input() requiredRedBackground: boolean;
  @Input() name: string;
  @Input() withImage: boolean;
  @Input() dateTimeRange: any;
  @Input() startingPlaceholder: string;
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() isValid = true;
  @Input() isValidMessage = '';
  @Output() dateTimeRangeChange = new EventEmitter();
  @Output() saveModel = new EventEmitter();

  private localDateMin: Date;
  private localDateMax: Date;
  private openedFromCalendarIcon: boolean;

  @Input()
  set minDate(date: Date) {
    const temp = !!date ? new Date(target(date)) : new Date(date);
    temp.setDate(temp.getDate());
    this.localDateMin = temp;
  }

  get minDate(): Date {
    return this.localDateMin;
  }

  @Input()
  set maxDate(date: Date) {
    const temp = !!date ? new Date(target(date)) : new Date(date);
    temp.setDate(temp.getDate());
    this.localDateMax = temp;
  }

  get maxDate(): Date {
    return this.localDateMax;
  }

  private localWithRange = false;
  private localSelectMode: string;
  private localSelectModeTo: string;

  @Input()
  set withRange(withRange: boolean) {
    this.localWithRange = withRange;
    if (this.localWithRange) {
      this.localSelectMode = 'rangeFrom';
      this.localSelectModeTo = 'rangeTo';
    } else {
      this.localSelectMode = 'single';
    }
  }

  get withRange(): boolean {
    return this.localWithRange;
  }

  @Input()
  set selectMode(selectMode: string) {
    this.localSelectMode = selectMode;
    this.localWithRange = false;
    this.localSelectModeTo = null;
  }

  get selectMode(): string {
    return this.localSelectMode;
  }

  get selectModeTo(): string {
    return this.localSelectModeTo;
  }

  value: Date;
  public startAt = new Date();
  oldValue: Date;
  oldRange: any;
  pickerOpen: boolean;
  @Input() pickerDisabled: boolean;

  constructor() {}

  onChange() {
    this.dateTimeRangeChange.emit(this.dateTimeRange);
    this.pickerOpen = false;
    if (this.openedFromCalendarIcon) {
      this.onSaveModel();
    }
  }

  afterPickerOpen() {
    this.pickerOpen = true;
  }

  onSaveModel() {
    this.openedFromCalendarIcon = false;
    this.convertDateTimeRange();
    if (this.selectMode === 'range' || this.selectMode === 'rangeFrom') {
      if (JSON.stringify(this.dateTimeRange) !== JSON.stringify(this.oldRange)) {
        this.saveModel.emit();
      }
    } else {
      if (this.oldValue !== this.value) {
        this.saveModel.emit();
      }
    }
  }
  setOpenedFromCalendarIcon() {
    this.onSaveState();
    this.openedFromCalendarIcon = true;
  }

  onSaveState() {
    this.convertDateTimeRange();
    if (this.selectMode === 'range' || this.selectMode === 'rangeFrom') {
      if (!this.pickerOpen) {
        this.oldRange = this.dateTimeRange;
        if (this.dateTimeRange != null && !this.pickerDisabled) {
          this.startAt = moment(this.dateTimeRange[0], [DATE_FORMATS.datePickerInput, DATE_FORMATS.serverSideDateFormat]).toDate();
        }
      }
    } else {
      this.oldValue = this.dateTimeRange;
      if (this.dateTimeRange != null) {
        this.startAt = moment(this.dateTimeRange, [DATE_FORMATS.datePickerInput, DATE_FORMATS.serverSideDateFormat]).toDate();
      }
    }
  }

  private convertDateTimeRange() {
    if (this.selectMode === 'range' || this.selectMode === 'rangeFrom') {
      if (this.dateTimeRange == null || this.dateTimeRange === '') {
        this.dateTimeRange = null;
        this.placeholder = '';
        this.value = null;
      } else {
        if (this.dateTimeRange[0] != null) {
          this.dateTimeRange[0] = moment(this.dateTimeRange[0], [DATE_FORMATS.datePickerInput, DATE_FORMATS.serverSideDateFormat]).toDate();
        }
        if (this.dateTimeRange[1] != null) {
          this.dateTimeRange[1] = moment(this.dateTimeRange[1], [DATE_FORMATS.datePickerInput, DATE_FORMATS.serverSideDateFormat]).toDate();
        }
        if (this.selectMode === 'range') {
          this.placeholder = this.dateTimeRange[0] == null ? '' : this.dateTimeRange[0];
          this.placeholder = this.dateTimeRange[1] == null ? this.placeholder : this.placeholder + ' ~ ' + this.dateTimeRange[1];
        }
      }
    } else {
      if (this.dateTimeRange == null || this.dateTimeRange === '') {
        this.dateTimeRange = null;
        this.placeholder = '';
        this.value = null;
      } else if (this.dateTimeRange instanceof Date) {
        this.value = this.dateTimeRange;
        this.dateTimeRange = moment(this.dateTimeRange).format(DATE_FORMATS.datePickerInput);
        this.placeholder = this.dateTimeRange;
      } else if (this.isString(this.dateTimeRange)) {
        this.value = moment(this.dateTimeRange, [DATE_FORMATS.datePickerInput, DATE_FORMATS.serverSideDateFormat]).toDate();
        this.dateTimeRange = moment(this.dateTimeRange, [DATE_FORMATS.datePickerInput, DATE_FORMATS.serverSideDateFormat]).format(
          DATE_FORMATS.datePickerInput
        );
        this.placeholder = this.dateTimeRange;
      } else if (moment.isMoment(this.dateTimeRange)) {
        this.value = this.dateTimeRange.toDate();
        this.dateTimeRange = this.dateTimeRange.format(DATE_FORMATS.datePickerInput);
        this.placeholder = this.dateTimeRange;
      } else if (Array.isArray(this.dateTimeRange)) {
        this.dateTimeRange[0] = moment(this.dateTimeRange[0]).format(DATE_FORMATS.datePickerInput);
        this.dateTimeRange[1] = moment(this.dateTimeRange[1]).format(DATE_FORMATS.datePickerInput);
        this.placeholder = this.dateTimeRange[0] + ' ' + this.dateTimeRange[1];
      }
    }
  }

  ngOnInit() {
    this.onSaveState();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.convertDateTimeRange();
  }

  onInputCheckEmpty($event) {
    console.log($event)
    if (!this.localWithRange) {
      if ((event.target as HTMLInputElement).value == null || (event.target as HTMLInputElement).value === '') {
        this.dateTimeRange = null;
        this.value = null;
        this.placeholder = '';
      }
    }
  }

  setFocus(): void {
    this.inputComp.nativeElement.focus();
  }

  private isString(value) {
    return typeof value === 'string'
  }
}
