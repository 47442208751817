import { SupplierInvoiceSaving } from './../../../../../../shared/models/supplierInvoiceSaving';
import { getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import { ServiceOrderService } from './../../../../../../core/services/service-order.service';
import {
  PermissionEnum,
  QuantityUnit,
  SavingTypedInField,
  ServiceTypeEnumIcon, ServiceTypeItemEnum
} from '@secca/shared/models/enums';
import { DropDownServiceOrderComponent } from '../../../../../../shared/components/drop-down-service-order/drop-down-service-order.component';
import { DropdownDictionary } from '../../../../../../shared/models/dropdownDictionary';
import { SupplierInvoiceItem } from '../../../../../../shared/models/supplierInvoiceItem';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ServiceItemType } from 'src/app/shared/models/service-item-type';
import { InputTypePattern } from 'src/app/shared/models/enums';
import { CasePlansService } from '../../../case-plans/case-plans.service';
import { ServiceOrderHoverModel } from '../../../case-plans/plan/service-order-hover/service-order-hover-model';
import { LocaleService } from 'src/app/core/services/locale.service';
import { IConfig } from 'ngx-mask';
import { DineroObject } from 'dinero.js';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import { ServiceOrder } from '@secca/shared/models/service-order/service-order';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { TranslateService } from '@ngx-translate/core';
import { PermissionService } from '@secca/core/services/permission.service';
import { SavingsCalculator } from '../savings-calculator';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { MasterListService } from '@secca/core/services/masterlist-service';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { CurrencyMaskInputMode } from 'ngx-currency';

@Component({
  selector: 'app-supplier-invoice-item',
  templateUrl: './supplier-invoice-item.component.html',
  styleUrls: ['./supplier-invoice-item.component.scss', '../manage-supplier-invoice.component.scss'],
})
@AutoUnsubscribe
export class SupplierInvoiceItemComponent implements OnInit {
  @ViewChild('serviceOrders') serviceOrdersDropDown: DropDownServiceOrderComponent;
  @ViewChild('hoverAnchor') hoverAnchor: ElementRef;
  @Input() shouldActAsInvoiceRecievedFromSUPO: boolean = false;
  @Input() showDelete: boolean;
  @Input() supplierInvoiceId: number;
  @Input() set supplierInvoiceItem(value: SupplierInvoiceItem) {
    this._supplierInvoiceItem = value;
    this.splitSavings();
  }
  get supplierInvoiceItem(): SupplierInvoiceItem {
    return this._supplierInvoiceItem;
  }
  @Input() set serviceOrders(value: ServiceOrder[]) {
    this._serviceOrders = value;
    this.filterSelectableServiceOrders(this._editDisabled);
  }
  get serviceOrders(): ServiceOrder[] {
    return this._serviceOrders;
  }
  @Input() set localCurrency(value: DineroObject) {
    this._localCurrency = value;
    this.currencyMaskOptions = {
      prefix: '',
      thousands: this.getThousandSeparator(),
      decimal: this.getDecimalMarker(),
      precision: value.precision,
      allowNegative: true,
      inputMode: CurrencyMaskInputMode.NATURAL,
    };
  }
  get localCurrency(): DineroObject {
    return this._localCurrency;
  }
  @Input() set editDisabled(value: boolean) {
    this._editDisabled = value;
    this.filterSelectableServiceOrders(value);
  }
  get editDisabled(): boolean {
    return this._editDisabled;
  }
  @Input() caseCreatedDate: Date;
  @Input() allServiceItems: ServiceItemType[];
  @Input() caseStakeholders: CaseStakeholder[];

  @Output() delete: EventEmitter<SupplierInvoiceItem> = new EventEmitter();

  serviceItemList: ServiceItemType[];
  quantityUnits: DropdownDictionary[];
  reductionTypeList: DropdownDictionary[];
  currencyMaskOptions = {
    prefix: '',
    thousands: '.',
    decimal: ',',
    precision: 2,
    allowNegative: true,
    inputMode: CurrencyMaskInputMode.NATURAL,
  };
  quantityMaskOptions: any;

  grossSavings: SupplierInvoiceSaving[];
  netSavings: SupplierInvoiceSaving[];

  selectableServiceOrders: ServiceOrder[];


  private isMouseOverTheService: boolean = false;
  private _supplierInvoiceItem: SupplierInvoiceItem;
  private _localCurrency: DineroObject;
  private savingsCalculator: SavingsCalculator;
  private _serviceOrders: ServiceOrder[];
  private _editDisabled: boolean;

  $hoverServiceSubscr: Subscription;
  $caseServiceOrdersSubscr: Subscription;
  serviceOrderHoverModel: ServiceOrderHoverModel;
  showServiceOrderInfo: any = {};

  constructor(
    private masterListService: MasterListService,
    private casePlansService: CasePlansService,
    private serviceOrderService: ServiceOrderService,
    private translate: TranslateService,
    public caseLockHelperService: CaseLockHelperService,
    public permissionService: PermissionService,
    public caseStateService: CaseStateService
  ) {
    this.savingsCalculator = new SavingsCalculator();
  }

  get InputTypePattern() {
    return InputTypePattern;
  }

  ngOnInit() {
    this.serviceItemList = [];
    this.quantityUnits = [];
    this.reductionTypeList = [];
    for (const unit in QuantityUnit) {
      if (isNaN(Number(unit))) {
        this.quantityUnits.push(new DropdownDictionary(QuantityUnit[unit], unit));
      }
    }
    this.masterListService.getSupplierInvoiceReductionTypes().subscribe(reductionTypes => {
      this.reductionTypeList = reductionTypes.map(item => new DropdownDictionary(item.reductionCode, item.reductionName));
    });
    this.quantityMaskOptions = {
      prefix: '',
      thousands: this.getThousandSeparator(),
      decimal: this.getDecimalMarker(),
      precision: 2,
      allowNegative: false,
      nullable: false,
      inputMode: CurrencyMaskInputMode.NATURAL,
    };
  }

  focusElement(event: any) {
    event.target.select();
  }

  private filterSelectableServiceOrders(doFilter: boolean) {
    if (doFilter) {
      if (this.allServiceItems) {
        const serviceItem = this.allServiceItems.find(s => s.code === this.supplierInvoiceItem.serviceItemCode);
        this.selectableServiceOrders = this.serviceOrders.filter(
          so => serviceItem && serviceItem.sosServiceTypes.some(type => type === so.type) && so.extension.getCommitmentType().supplierInvoice
        );
      }
    } else {
      this.selectableServiceOrders = this.serviceOrders;
    }
  }

  splitSavings() {
    const splitSavings = this.savingsCalculator.splitSavings(this.supplierInvoiceItem);
    this.grossSavings = splitSavings[0];
    this.netSavings = splitSavings[1];
  }

  getThousandSeparator(): string {
    return getLocaleNumberSymbol(LocaleService.LOCALE, NumberSymbol.CurrencyGroup);
  }

  getDecimalMarker(): IConfig['decimalMarker'] {
    const currencyDecimal = getLocaleNumberSymbol(LocaleService.LOCALE, NumberSymbol.CurrencyDecimal);
    if (currencyDecimal === ',') {
      return ',';
    } else if (currencyDecimal === '.') {
      return '.';
    } else {
      throw new RangeError("NumberSymbol.CurrencyDecimal is neither ',' nor '.'!");
    }
  }

  getSavingsAmountTotal(): number {
    let savingsSum = 0;
    for (const saving of this.supplierInvoiceItem.savings) {
      savingsSum += saving.amount;
    }
    return savingsSum;
  }

  getSavingsPctTotal(): number {
    this.splitSavings();
    let grossAmount = this.supplierInvoiceItem.paidAmount;
    this.grossSavings.forEach(saving => (grossAmount += saving.amount));
    return Math.round((this.getSavingsAmountTotal() * 10000) / grossAmount) / 100;
  }

  getNetAmount(): number {
    let netAmount = this.supplierInvoiceItem.paidAmount;
    if (this.supplierInvoiceItem.savings) {
      for (const s of this.supplierInvoiceItem.savings.filter(saving => !saving.gross)) {
        netAmount -= s.amount;
      }
    }
    return netAmount;
  }

  getAmountExclVAT(): string {
    const paidAmount = this.supplierInvoiceItem.paidAmount;
    let amount: string | number;
    const amountString = this.translate.instant('case-economy-amount-excl-vat');
    if (this.supplierInvoiceItem.isIncludingVAT && this.supplierInvoiceItem.isInVATCreditorGroup) {
      amount = this.removeVAT(paidAmount).toFixed(2);
    } else {
      amount = paidAmount;
    }
    const currency = this.localCurrency.currency.toString();
    return amountString + ' \r\n' + currency + ' ' + amount;
  }

  getSavingsExclVAT(index: number): string {
    if (!!this.supplierInvoiceItem.savings[index]) {
      const savedAmount = this.supplierInvoiceItem.savings[index].amount;
      let amount: string | number;
      const savedAmountString = this.translate.instant('case-economy-saved-amount-excl-vat');
      if (this.supplierInvoiceItem.isIncludingVAT && this.supplierInvoiceItem.isInVATCreditorGroup) {
        amount = this.removeVAT(savedAmount).toFixed(2);
      } else {
        amount = savedAmount;
      }
      const currency = this.localCurrency.currency.toString();
      return savedAmountString + ' \r\n' + currency + ' ' + amount;
    }
    return '';
  }

  removeVAT(amount: number): number {
    return (amount / 100) * 80;
  }

  deleteSpecification() {
    this.delete.emit(this.supplierInvoiceItem);
  }

  serviceOrderChange() {
    if (this.supplierInvoiceItem.serviceOrderId) {
      const serviceOrderItem = this.serviceOrders.find(soi => soi.serviceOrderId === this.supplierInvoiceItem.serviceOrderId);
      if (serviceOrderItem && serviceOrderItem.type) {
        this.serviceItemList = this.allServiceItems.filter(
          s =>
            s.sosServiceTypes.some(type => type === serviceOrderItem.type) &&
            s.serviceItemType !== ServiceTypeItemEnum.RECOVERY &&
            s.activateOn <= this.caseCreatedDate &&
            (!s.deactivateOn || s.deactivateOn > this.caseCreatedDate)
        );
      }
    } else if (this.supplierInvoiceItem.serviceItemCode && this.serviceItemList?.length !== this.allServiceItems?.length) {
      this.filterSelectableServiceOrders(true);
      this.serviceItemList = this.allServiceItems;
    }
  }

  serviceItemChange() {
    this.supplierInvoiceItem.serviceItemName = this.serviceItemList.find(s => s.code === this.supplierInvoiceItem.serviceItemCode).name;
    this.supplierInvoiceItem.isIncludingVAT = this.serviceItemList.find(s => s.code === this.supplierInvoiceItem.serviceItemCode).vat;
    if (!this.supplierInvoiceItem.serviceOrderId) {
      this.filterSelectableServiceOrders(true);
    }
  }

  mouseEnter(event: MouseEvent, i: string) {
    if (this.showServiceOrderInfo[i]) {
      this.showServiceOrderInfo[i] = !this.showServiceOrderInfo[i];
    } else {
      this.showServiceOrderInfo[i] = true;
    }
    this.isMouseOverTheService = true;
    setTimeout(() => {
      if (this.isMouseOverTheService) {
        this.serviceOrderHoverModel = this.initializeHoverModel(event);
        this.casePlansService.sendHoverServiceOrder(this.serviceOrderHoverModel);
      }
    }, 1000);
  }

  private initializeHoverModel(event: MouseEvent) {
    if (!this.supplierInvoiceItem.serviceOrderId) {
      return undefined;
    }
    const serviceOrderItem = this.serviceOrdersDropDown.items.find(soi => soi.serviceOrderId === this.supplierInvoiceItem.serviceOrderId);
    if (!serviceOrderItem) {
      return undefined;
    }
    const serviceOrderHoverModel: ServiceOrderHoverModel = new ServiceOrderHoverModel();
    if (this.$caseServiceOrdersSubscr) {
      this.$caseServiceOrdersSubscr.unsubscribe();
    }
    this.$caseServiceOrdersSubscr = this.serviceOrderService.getCaseServiceOrders().subscribe(result => {
      serviceOrderHoverModel.serviceOrder = result.find(so => so.serviceOrderId === this.supplierInvoiceItem.serviceOrderId);
      serviceOrderHoverModel.serviceOrder.laneNumber = 0;
      if (!serviceOrderHoverModel.serviceOrder.extension.displayDurationId) {
        serviceOrderHoverModel.serviceOrder.extension.displayDurationId = 0;
      }
    });
    const iconPaths = serviceOrderItem.iconFileName.split('/');
    serviceOrderHoverModel.serviceLeftPosition = 180;
    serviceOrderHoverModel.serviceTopPosition = event.pageY - 300;
    serviceOrderHoverModel.serviceWidth = 0;
    serviceOrderHoverModel.serviceTypeIcon = ServiceTypeEnumIcon.getIcon(serviceOrderHoverModel.serviceOrder.type);
    serviceOrderHoverModel.providerName = serviceOrderItem.providerName;
    serviceOrderHoverModel.agentName = this.getAgentName(serviceOrderHoverModel.serviceOrder.supplierId)
    return serviceOrderHoverModel;
  }
  private getAgentName(supplierId: number): string {
    const supplier = this.caseStakeholders.find(s => +s.id === supplierId);

    if (supplier && supplier.company.gop?.agentId) {
      const agent = this.caseStakeholders.find(s => +s.id === supplier.company.gop.agentId);
      return agent?.company?.name || null;
    }
    return null;
  }

  mouseLeave( i: string) {
    this.showServiceOrderInfo[i] = false;
    this.isMouseOverTheService = false;
    this.casePlansService.sendHoverServiceOrder(undefined);
    // what does this line do, except making a lot of unessary subscriptions ?
    // this.$hoverServiceSubscr = this.casePlansService.getHoverServiceOrder().subscribe(hover => (hover = null));
  }

  savingAmountChanged(changedSaving: SupplierInvoiceSaving) {
    changedSaving.typedInField = SavingTypedInField.AMOUNT;
    this.recalcSavingAmounts();
  }

  savingPercentageChanged(changedSaving: SupplierInvoiceSaving) {
    changedSaving.typedInField = SavingTypedInField.PERCENTAGE;
    this.recalcSavingAmounts();
  }

  savingTotalAmountChanged(changedSaving: SupplierInvoiceSaving) {
    this.supplierInvoiceItem.calculatedFromGross = true;
    this.recalcSavingAmounts();
  }

  addSaving(gross: boolean) {
    const newSaving = new SupplierInvoiceSaving();
    let maxLineNumber = 0;
    this.supplierInvoiceItem.savings.forEach(s => (maxLineNumber = s.lineNumber > maxLineNumber ? s.lineNumber : maxLineNumber));
    newSaving.lineNumber = maxLineNumber + 1;
    newSaving.amount = 0;
    newSaving.percentage = 0;
    newSaving.totalAmount = gross ? 0 : null;
    newSaving.typedInField = SavingTypedInField.AMOUNT;
    newSaving.gross = gross;
    this.supplierInvoiceItem.savings.push(newSaving);
    this.recalcSavingAmounts();
  }

  removeSaving(lineNumber: number) {
    if (this.supplierInvoiceItem.calculatedFromGross && lineNumber === this.grossSavings[0].lineNumber) {
      this.supplierInvoiceItem.calculatedFromGross = null;
      this.supplierInvoiceItem.paidAmount = null;
    }
    this.supplierInvoiceItem.savings = this.supplierInvoiceItem.savings.filter(s => s.lineNumber !== lineNumber);
    this.recalcSavingAmounts();
  }

  recalcSavingAmounts() {
    const splitSavings = this.savingsCalculator.recalcSavingAmounts(this.localCurrency, this.supplierInvoiceItem);
    this.grossSavings = splitSavings[0];
    this.netSavings = splitSavings[1];
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  invoiceCurrencyMask(): string {
    return 'separator.' + this.localCurrency.precision;
  }

  grossTotalDisabled(): boolean {
    if (!this.editDisabled) {
      return (
        (this.supplierInvoiceItem.calculatedFromGross === null || !this.supplierInvoiceItem.calculatedFromGross) &&
        this.supplierInvoiceItem.paidAmount !== null
      );
    }
    return this.editDisabled;
  }

  getShowServiceOrderInfo(i: string): boolean {
    if (this.showServiceOrderInfo[i]) {
      return this.showServiceOrderInfo[i];
    } else {
      return false;
    }
  }
}
