<div class="assessment-card" id="repatriationPrognosis">
  <app-medical-assessment-card-title [valid]="state.repatriationPrognosisValid"
    [title]="'medical-regular-assessment-repatriation-prognosis' | translate"></app-medical-assessment-card-title>
  <div class="assessment-card-content">
    <div class="repatriation-form">
      <app-drop-down [(selectedItemId)]="repatriationPrognosis.repatriationForm" [items]="repatriationForms" [requiredRedBackground]=true
        [name]="'medical-regular-assessment-repatriation-prognosis-form' | translate" [disabled]="disabledViewOfMedicalAssessment">
      </app-drop-down>
    </div>
    <ng-container *ngIf="repatriationPrognosis.repatriationForm != repatriationFormEnum.TOO_SOON_TO_ESTIMATE">
      <div class="spacer"></div>
      <div class="date-picker-restrainer">
        <div class="date-size-col">
          <app-input-calendar [withImage]="true" [minDate]="today"
            [name]="'medical-regular-assessment-repatriation-prognosis-date' | translate" [date]="this.repatriationPrognosis.date"
            [disabled]="disabledViewOfMedicalAssessment" (dateChange)="updateDate($event)" [minDate]="today"
            [required]="!repatriationPrognosis.date">
          </app-input-calendar>
        </div>
        <div class="spacer"></div>
        <app-small-yes-no-question [header]="'medical-regular-assessment-repatriation-prognosis-oxygen' | translate"
          [(value)]="repatriationPrognosis.oxygen" [disabled]="disabledViewOfMedicalAssessment"></app-small-yes-no-question>
      </div>
    </ng-container>
  </div>
