import { Adapter } from './../../../../../shared/interfaces/adapter';
import { Injectable } from '@angular/core';
import { AssessmentTypeEnum, HandlingAreaStatusEnum, PatientType } from './../../../../../shared/models/enums';
import { DateHelperService } from '@secca/core/services/date-helper.service';
export class BoardMedicalCase {
  id: number;
  caseNumber: string;
  age: number;
  endUserFullName: string;
  incidentCountry: string;
  countryCodeAlpha2: string;
  teamStatus: HandlingAreaStatusEnum;
  caseHandler: string;
  caseHandlerTeamName: string;
  reserve: number;
  caseLockedBy: string;
  billingCurrency: string;
  customerProfileId: number;
  customerProfileName: string;
  readyFrom: Date;
  arrivalDeadline: Date;
  caseDetailsTBD: boolean;
  caseDetailsURN: boolean;
  caseDetailsCOF: boolean;
  caseDetailsAIR: boolean;
  caseDetailsSTR: boolean;
  caseDetailsEXT: boolean;
  caseDetailsSIT: boolean;
  caseDetailsREG: boolean;
  caseDetailsDOC: boolean;
  caseDetailsNUR: boolean;
  caseDetailsNME: boolean;
  caseDetailsOXY: boolean;
  caseDetailsEQU: boolean;
  caseDetailsDiagnosis: string;
  assignedOn: Date;
  followUpDate: Date;
  medicalServiceorderType: AssessmentTypeEnum;
  medicalServiceorderAssignee: string;
  medicalAssessmentPublished: Date;

  inpatientOutpatientOnCase: PatientType;
  inpatientOutpatientActive: boolean;

  relatedToICCCase: boolean;
  relatedToMajorIncidentCase: boolean;

  public constructor(init?: Partial<BoardMedicalCase>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class BoardMedicalCaseAdapter implements Adapter<BoardMedicalCase> {

  constructor(private dateHelperService: DateHelperService) {
  }

  adapt(item: any): BoardMedicalCase {
    return item ? new BoardMedicalCase({
      id: item.id,
      caseNumber: item.caseNumber,
      endUserFullName: item.endUserFullName,
      age: item.age,
      incidentCountry: item.incidentCountry,
      caseHandler: item.caseHandler,
      caseHandlerTeamName: item.caseHandlerTeamName,
      teamStatus: item.teamStatus,
      countryCodeAlpha2: item.countryCodeAlpha2,
      reserve: item.reserve,
      customerProfileId: item.customerProfileId,
      customerProfileName: item.customerProfileName,
      billingCurrency: item.billingCurrency,
      caseLockedBy: item.caseLockedBy,
      readyFrom: this.dateHelperService.convertFromUTCDateTimeToLocalDateTime(item.readyFrom),
      arrivalDeadline: this.dateHelperService.convertFromUTCDateTimeToLocalDateTime(item.arrivalDeadline),
      caseDetailsTBD: item.caseDetailsTBD,
      caseDetailsURN: item.caseDetailsURN,
      caseDetailsCOF: item.caseDetailsCOF,
      caseDetailsAIR: item.caseDetailsAIR,
      caseDetailsSTR: item.caseDetailsSTR,
      caseDetailsEXT: item.caseDetailsEXT,
      caseDetailsSIT: item.caseDetailsSIT,
      caseDetailsREG: item.caseDetailsREG,
      caseDetailsDOC: item.caseDetailsDOC,
      caseDetailsNUR: item.caseDetailsNUR,
      caseDetailsNME: item.caseDetailsNME,
      caseDetailsOXY: item.caseDetailsOXY,
      caseDetailsEQU: item.caseDetailsEQU,
      caseDetailsDiagnosis: item.caseDetailsDiagnosis,
      assignedOn: this.dateHelperService.convertFromUTCDateTimeToLocalDateTime(item.assignedOn),
      followUpDate: this.dateHelperService.convertFromUTCDateTimeToLocalDateTime(item.followUpDate),
      medicalServiceorderType: item.medicalServiceorderType,
      medicalServiceorderAssignee: item.medicalServiceorderAssignee,
      medicalAssessmentPublished: this.dateHelperService.convertFromUTCDateTimeToLocalDateTime(item.medicalAssessmentPublished),
      inpatientOutpatientOnCase: item.inpatientOutpatientOnCase,
      inpatientOutpatientActive: item.inpatientOutpatientActive,
      relatedToICCCase: item.relatedToICCCase,
      relatedToMajorIncidentCase: item.relatedToMajorIncidentCase
    }) : new BoardMedicalCase();
  }
}
