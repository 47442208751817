import { MedicalPreAssessmentQuestionnaire } from './../../../../../shared/models/medicalPreAssessment';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MedicalService } from '@secca/core/services/medical.service';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import {
  AssessmentTypeEnum,
  MedicalActionTypeEnum,
  MedicalAssessmentBasedOnEnum,
  MedicalExaminationsTypeEnum,
  MedicalHistoryType,
} from '@secca/shared/models/enums';
import {
  MedicalAssessment,
  MedicalAssessmentAction,
  MedicalAssessmentDocument,
  MedicalAssessmentSource,
} from '@secca/shared/models/medicalAssessment';
import { SourceUser, SourceUserId } from '@secca/case/components/case-medical/medical-assessment/medical-assessment-source/source-user';
import { Icd10Code } from '@secca/shared/models/icd10Code';
import { TranslateService } from '@ngx-translate/core';
import * as onChange from 'on-change';
import { MedicalAssessmentState, SelectItem } from '@secca/case/components/case-medical/medical-components/medical-models';
import { MedicalAssessmentStateService } from '@secca/core/services/medical-assessment-state.service';
import { Subscription } from 'rxjs';
import { CaseDocument } from '@secca/shared/models/caseDocument';
import * as moment from 'moment';
import { Case } from '@secca/shared/models/case';
import { DateHelperService } from '@secca/core/services/date-helper.service';
import { StakeholderType } from '@secca/case/components/case-stakeholders/stakeholderType';
import { DictionaryService } from '@secca/core/services/dictionary.service';
import { CaseService } from '@secca/core/services/case.service';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { StakeholderService } from '@secca/core/services/stakeholder.service';
import { StakeholderTypeEnum } from '@secca/shared/models/enums';
import { ViewportScroller } from '@angular/common';
import { DialogHelperUtilService } from '@secca/core/services/dialog-helper-util.service';
import { finalize } from 'rxjs/operators';
import { MedicalReportSupplierPortalDto } from '@secca/shared/models/medicalHistory';
import { MomentHelperService } from '@secca/core/services/moment-helper.service';
import { CaseTypeCodes } from '@secca/shared/models/case-type';

@Component({
  selector: 'app-medical-assessment',
  templateUrl: './medical-assessment.component.html',
  styleUrls: ['./medical-assessment.component.scss'],
})
export class MedicalAssessmentComponent implements OnInit {
  _case: Case;
  @Input() medicalAssessmentId: number;
  @Input() disabledViewOfMedicalAssessment: boolean;
  @Input() medicalAssessmentType: AssessmentTypeEnum;

  state: MedicalAssessmentState;
  medicalActionTypeList: DropdownDictionary[];
  stateSubscription: Subscription;
  basisSources: DropdownDictionary[];
  documents: SelectItem<CaseDocument>[];
  supoMedReps: SelectItem<MedicalReportSupplierPortalDto>[];
  medicalAssessment: MedicalAssessment;
  newMedicalAssessmentAction: MedicalAssessmentAction;
  copyOfExistingAssessment: boolean;
  stakeholderTypes: StakeholderType[];
  stakeholders: CaseStakeholder[] = [];
  contactPerson: SourceUser;
  userIndex = 0;
  storingMedicalAssessment: boolean = false;

  today: moment.Moment = moment.utc();
  now: moment.Moment = moment();
  formValid = true;

  @Output() updateDraftButton = new EventEmitter();
  @Output() closed = new EventEmitter<number>();

  @Input()
  set case(newCase: Case) {
    if (newCase !== undefined) {
      this._case = newCase;
    }
  }

  get case(): Case {
    return this._case;
  }

  get caseId(): number {
    if (!!this._case) {
      return Number.parseInt(this._case.id);
    }
    return null;
  }

  stakeholderSortOnGroupName = new Map<string, number>([
    ['End-User', 1],
    ['Policy Holder', 2],
    ['Reporter', 3],
    ['Person', 4],
    ['Medical', 5],
    ['Accommodation', 6],
    ['Transport', 7],
    ['Medical escort', 8],
    ['Agent', 9],
    ['Other', 10],
  ]);

  private propertyNameMap = new Map<string, string>([
    ['PRIMARY_CONTACT', 'Primary Contact'],
    ['REPORTER', 'Reporter'],
    ['POLICYHOLDER', 'Policy Holder'],
    ['END_USER', 'End User'],
    ['LEGAL_GUARDIAN', 'Legal Guardian'],
    ['CO_TRAVELER', 'Co-Traveller'],
  ]);

  users: SourceUser[] = [];
  stakeholder: SourceUser;

  constructor(
    private medicalService: MedicalService,
    private assessmentSateService: MedicalAssessmentStateService,
    private translateService: TranslateService,
    private dateHelperService: DateHelperService,
    private dictionaryService: DictionaryService,
    private caseService: CaseService,
    private stakeholderService: StakeholderService,
    private viewportScroller: ViewportScroller,
    private dialogHelperUtilService: DialogHelperUtilService,
    private momentHelperService: MomentHelperService
  ) {}

  ngOnInit(): void {
    this.today = moment.utc().startOf('day');
    this.now = moment();
    this.stateSubscription = this.assessmentSateService.stateSubject().subscribe(state => {
      this.state = new MedicalAssessmentState(state);
      if (state.medicalEvacuation) {
        this.medicalAssessment.travelHomeAsPlanned = false;
        this.medicalAssessment.repatriationInstructionsAllowed = true;
      }
    });

    this.dictionaryService.getStakeholdersTypes().subscribe(
      stakeholderTypes => {
        this.stakeholderTypes = stakeholderTypes;
      },
      error => console.log(error)
    );

    this.caseService.getCaseStakeholdersOnCase(this.caseId.toString()).subscribe(result => {
      this.stakeholders = result;
      this.filterList();
    });

    if (this.medicalAssessmentId) {
      this.medicalService.getMedicalAssessment(this.medicalAssessmentId).subscribe(
        result => {
          this.medicalAssessment = result;
          this.refreshAfterAssessmentIsAdded();
        },
        error => console.log(error)
      );
    } else {
      if (this.medicalAssessmentType !== AssessmentTypeEnum.CLAIMS) {
        this.medicalAssessment = this.medicalService.getRegularAssessmentInDraft(this.caseId);
        if (this.medicalAssessment) {
          this.refreshAfterAssessmentIsAdded();
        }
      } else {
        this.medicalAssessment = this.medicalService.getClaimsReviewInDraft(this.caseId);
        if (this.medicalAssessment) {
          this.refreshAfterAssessmentIsAdded();
        }
      }

      if (!this.medicalAssessment) {
        this.medicalService.getNewestMedicalAssessment(this.caseId).subscribe(result => {
          if (result.id !== null) {
            this.medicalAssessment = result;
            this.medicalAssessment.id = null;
            this.medicalAssessment.sourceList = [];
            this.medicalAssessment.sourceList.push(new MedicalAssessmentSource());
           // this.medicalAssessment.Questionnaire = [];
           // this.medicalAssessment.sourceList.push(new MedicalAssessmentSource());
            this.medicalAssessment.note.internalNote = null;
            this.medicalAssessment.note.status = null;
            this.medicalAssessment.note.id = null;
            this.medicalAssessment.documentList = [];
            this.medicalAssessment.seccaCaseId = this.caseId;
            this.medicalAssessment.actionList.forEach(actionlist => (actionlist.id = null));
            this.medicalAssessment.admissionList.forEach(admissionList => (admissionList.id = null));
            this.medicalAssessment.medicalAssessmentType = this.medicalAssessmentType;
            this.medicalAssessment.followupPlanned = null;
            this.medicalAssessment.followupDate = null;
            this.medicalAssessment.followupDescription = '';
            this.copyOfExistingAssessment = true;
          }
          this.refreshAfterAssessmentIsAdded();
        });
      }
    }
  }

  private filterList() {
    this.stakeholders.forEach(stakeholder => {
      const theType = this.stakeholderTypes.find(type => type.stakeholderTypeEnum === stakeholder.stakeholderType);
      stakeholder.sortId = this.stakeholderSortOnGroupName.get(theType.groupName);
      stakeholder.imageName = this.stakeholderService.addIconToCaseStakeholder(theType.stakeholderTypeEnum) + '.svg';
    });
    this.createList();
  }

  createList() {
    const stakeholderTypeString = Object.values(StakeholderTypeEnum);
    const displayedRoles = [
      StakeholderTypeEnum.endUser,
      StakeholderTypeEnum.policyHolder,
      StakeholderTypeEnum.reporter,
      StakeholderTypeEnum.coTraveller,
      StakeholderTypeEnum.person,
    ];
    this.users = new Array();
    this.stakeholders.forEach(stake => {
      // No endUser etc. exists on a coordination case
      if (this.case.caseTypeCode !== CaseTypeCodes.INTERNAL_COORDINATION || ![StakeholderTypeEnum.endUser, StakeholderTypeEnum.policyHolder, StakeholderTypeEnum.reporter].includes(stake.stakeholderType)) {
        this.stakeholder = new SourceUser();
        if (stake.isCompany) {
          this.stakeholder.companyName = stake.company.name;
        } else {
          this.stakeholder.companyName = stake.person.firstName;
        }
        this.stakeholder.isCompany = stake.isCompany;
        if (!stake.isCompany) {
          this.stakeholder.firstName = stake.person.firstName;
          this.stakeholder.surname = stake.person.surname;
        }

        this.stakeholder.stakeholderType = stake.stakeholderType;
        this.stakeholder.stakeholderId = stake.id;
        this.stakeholder.contactPersonId = '';
        this.stakeholder.icon = '/assets/icons/' + stake.imageName;
        this.stakeholder.sortId = Number(stake.sortId);

        let stakeholderTypeEnum: StakeholderTypeEnum;
        for (let i = 0; i < stakeholderTypeString.length; i++) {
          if (stakeholderTypeString[i] === stake.stakeholderType) {
            stakeholderTypeEnum = StakeholderTypeEnum[Object.keys(StakeholderTypeEnum)[i]];
            break;
          }
        }
        if (displayedRoles.find(r => r === stakeholderTypeEnum)) {
          if (stakeholderTypeEnum === StakeholderTypeEnum.person) {
            this.stakeholder.roleName = '';
            stake.properties.forEach(p => {
              this.stakeholder.roleName = this.propertyNameMap.get(p);
            });
          } else {
            const stakeholderType = this.stakeholderTypes.find(s => s.stakeholderTypeEnum === stakeholderTypeEnum);
            if (stakeholderType !== undefined) {
              this.stakeholder.roleName = stakeholderType.groupName;
            }
          }
        }
        this.stakeholder.id = this.constructId(this.stakeholder);

        this.userIndex++;
        this.users.push(this.stakeholder);

        if (stake.isCompany) {
          this.addContactPersonToUserList(stake);
        }
      }
    });
  }

  addContactPersonToUserList(stake: CaseStakeholder) {
    stake.company.contactPersons.forEach(conta => {
      this.contactPerson = new SourceUser();
      if (stake.isCompany) {
        this.contactPerson.companyName = stake.company.name;
      }
      this.contactPerson.firstName = conta.firstName;
      this.contactPerson.surname = conta.surname;
      this.contactPerson.stakeholderType = stake.stakeholderType;
      this.contactPerson.stakeholderId = stake.id;
      this.contactPerson.contactPersonId = conta.id;
      if (conta.treatingDoctor) {
        this.contactPerson.sortId = Number(4);
        this.contactPerson.treatingDoctor = true;
        this.contactPerson.icon = '/assets/icons/' + stake.imageName;
      } else {
        this.contactPerson.sortId = Number(5);
        this.contactPerson.treatingDoctor = false;
        this.contactPerson.icon = '/assets/icons/Person.svg';
      }
      this.contactPerson.id = this.constructId(this.contactPerson);
      this.users = [...this.users, this.contactPerson];
      this.users.sort((a, b) => (a.sortId >= b.sortId ? 1 : -1));
    });
  }
  private constructId(sourceUser: SourceUser): string {
    const id = new SourceUserId();
    if (!sourceUser.isCompany) {
      id.sourceName = sourceUser.firstName + ' ' + sourceUser.surname;
      if (sourceUser.treatingDoctor) {
        id.sourceName += ' (' + sourceUser.companyName + ')';
      }
      if (sourceUser.roleName) {
        id.sourceName += ' (' + sourceUser.roleName + ')';
      }
    } else {
      id.sourceName = sourceUser.companyName;
    }
    id.sourceType = sourceUser.stakeholderType;
    return JSON.stringify(id);
  }

  refreshAfterAssessmentIsAdded() {
    if (this.medicalAssessment) {
      if (this.medicalAssessment.note.date) {
        this.medicalAssessment.note.date = moment.utc(this.medicalAssessment.note.date, this.dateHelperService.getDateFormat());
      }

      if (this.medicalAssessment.medicalEvacuationDate) {
        this.medicalAssessment.medicalEvacuationDate = moment.utc(
          this.medicalAssessment.medicalEvacuationDate,
          this.dateHelperService.getDateFormat()
        );
      }

      if (this.medicalAssessment.followupDate) {
        this.medicalAssessment.followupDate = moment.utc(this.medicalAssessment.followupDate, this.dateHelperService.getDateFormat());
      }
    } else {
      this.medicalAssessment = new MedicalAssessment();
      this.medicalAssessment.seccaCaseId = this.caseId;
      this.medicalAssessment.visits.numberOfVisits = 0;
      this.medicalAssessment.visits.numberOfAdditionalVisits = 0;
      this.medicalAssessment.admissionIsReadmission = false;
      this.medicalAssessment.medicalEvacuation = false;
      this.medicalAssessment.repatriationInstructionsBase.repatriationForm.seaLevelFlight = false;
      this.medicalAssessment.repatriationInstructionsBase.repatriationForm.patientCarriableOnBoard = false;
      this.medicalAssessment.repatriationInstructionsBase.repatriationForm.coTransport = true;
      this.medicalAssessment.medicalAssessmentType = this.medicalAssessmentType;
    }

    // Register onChange function
    this.medicalAssessment = onChange(
      this.medicalAssessment,
      (path, value, previousValue) => {
        this.assessmentSateService.updateAssessment(this.medicalAssessment);
      },
      { ignoreKeys: ['forEach', 'every', 'filter'], ignoreSymbols: true }
    );

    // First-time state check
    this.assessmentSateService.updateAssessment(this.medicalAssessment);

    this.basisSources = this.getBasisList();
    this.medicalService.getMedicalDocuments(this.caseId).subscribe(result => {
      this.documents = result.map(doc => {
        return new SelectItem({
          label:
            (!!doc.documentDate ? moment.utc(doc.documentDate).format('DD MMM YYYY HH:mm') + ' <b>' : '<b>') +
            this.getSenderDisplayString(doc) +
            '</b> ' +
            doc.documentName,
          value: doc,
          selected: !!this.medicalAssessment.documentList.find(saved => saved.documentId === doc.documentId),
        });
      });
    });
    this.medicalActionTypeList = this.getActionsTypeList();
    const timeZone = MomentHelperService.resolveTimeZone('local');
    this.medicalService.getMedicalHistory('' + this.caseId).subscribe(result => {
      this.supoMedReps = result
        .filter(medicalHistory => medicalHistory.medicalHistoryType === MedicalHistoryType.medicalReportSupplierPortal)
        .map(medicalHistory => {
          return new SelectItem({
            label:
              medicalHistory.createdOn.clone().tz(timeZone).format('DD MMM YYYY HH:mm') +
              ' <b>' +
              this.getMedicalReportCaseStakeholderName(medicalHistory.reportSupplierPortal) +
              '</b> Supplier Portal medical report',
            value: medicalHistory.reportSupplierPortal,
            selected: !!this.medicalAssessment.documentList.find(saved => saved.medicalReportSupplierPortalId === medicalHistory.reportSupplierPortal.id),
          });
        });
    });
  }

  private getMedicalReportCaseStakeholderName(item: MedicalReportSupplierPortalDto): string {
    return this.case.caseStakeholders.find(stakeholder => stakeholder.id === item.caseStakeholderId)?.companyName;
  }

  close() {
    if (this.closed.observers) {
      this.closed.emit();
    }
    this.dialogHelperUtilService.closeModal();
  }

  updateSourceData(selectedSourceId: string, basis: MedicalAssessmentSource) {
    const selectedSource = JSON.parse(selectedSourceId);

    basis.sourceName = selectedSource.sourceName;
    basis.sourceType = selectedSource.sourceType;
  }

  resolveSourceId(source: MedicalAssessmentSource) {
    if (!!source && !!source.sourceName && !!source.sourceType) {
      return JSON.stringify(
        new SourceUserId({
          sourceName: source.sourceName,
          sourceType: source.sourceType,
        })
      );
    }
    return null;
  }

  addNewSource() {
    const sources = Array.from(this.medicalAssessment.sourceList);
    sources.push(new MedicalAssessmentSource());
    this.medicalAssessment.sourceList = sources;
  }

  saveDraft() {
    // This step is necessary to remove all proxies from the medical assessment form
    const copyMedicalAssessment = JSON.parse(JSON.stringify(onChange.target(this.medicalAssessment)));
    if (this.medicalAssessment.medicalAssessmentType === AssessmentTypeEnum.CLAIMS) {
      this.medicalService.saveClaimsReviewToDraft(copyMedicalAssessment, this.caseId);
    } else {
      this.medicalService.saveRegularAssessmentDraft(copyMedicalAssessment, this.caseId);
    }

    this.updateDraftButton.emit();
    this.close();
  }

  publishAssessment() {
    if (this.storingMedicalAssessment) {
      return;
    }
    this.storingMedicalAssessment = true;

    // This step is necessary to remove all proxies from the medical assessment form
    const copyMedicalAssessment = JSON.parse(JSON.stringify(onChange.target(this.medicalAssessment)));
    this.medicalService.publishMedicalAssessment(copyMedicalAssessment).subscribe(
      result => {
        if (copyMedicalAssessment.medicalAssessmentType === AssessmentTypeEnum.CLAIMS) {
          this.medicalService.removeClaimsReviewDraft(this.caseId);
        } else {
          this.medicalService.removeRegularAssessmentDraft(this.caseId);
        }

        this.updateDraftButton.emit();
        this.close();
      },
      error => console.log(error)
    );
  }

  private getBasisList() {
    const prefix = 'medical-assessment-based-';
    return Object.keys(MedicalAssessmentBasedOnEnum).map(
      item =>
        new DropdownDictionary(
          MedicalAssessmentBasedOnEnum[item],
          this.translateService.instant(prefix + item.toString().toLowerCase().replace('_', '-'))
        )
    );
  }

  private getActionsTypeList() {
    const prefix = 'medical-assessment-actiontype-';
    return Object.keys(MedicalActionTypeEnum).map(
      item =>
        new DropdownDictionary(
          MedicalActionTypeEnum[item],
          this.translateService.instant(prefix + item.toString().toLowerCase().replace('_', '-'))
        )
    );
  }

  getMedicalExaminationsTypeEnum() {
    return MedicalExaminationsTypeEnum;
  }

  addNewAction(examinationType: MedicalExaminationsTypeEnum) {
    const actions = Array.from(this.medicalAssessment.actionList);
    this.newMedicalAssessmentAction = new MedicalAssessmentAction();
    this.newMedicalAssessmentAction.examinationType = examinationType;
    this.newMedicalAssessmentAction.toBeDeleted = true;
    actions.push(this.newMedicalAssessmentAction);
    this.medicalAssessment.actionList = JSON.parse(JSON.stringify(actions));
  }

  deleteAction(index) {
    this.medicalAssessment.actionList.splice(index, 1);
  }

  actionDeleteButtonActive(type: MedicalExaminationsTypeEnum) {
    return this.medicalAssessment.actionList.filter(action => action.examinationType === type).length > 1 ? true : false;
  }

  updateDocument(item: SelectItem<CaseDocument>) {
    const doc = item.value;
    if (!!item) {
      if (item.selected) {
        if (!this.medicalAssessment.documentList.find(value => value.documentId === doc.documentId)) {
          this.medicalAssessment.documentList.push(new MedicalAssessmentDocument({ documentId: doc.documentId }));
        }
      } else {
        if (!!this.medicalAssessment.documentList.find(saved => saved.documentId === doc.documentId)) {
          this.medicalAssessment.documentList = this.medicalAssessment.documentList.filter(value => value.documentId !== doc.documentId);
        }
      }
    }
  }

  updateSupoMedRep(item: SelectItem<MedicalReportSupplierPortalDto>) {
    const findMedRep = value => value.medicalReportSupplierPortalId === item.value.id;
    if (!!item) {
      if (item.selected) {
        if (!this.medicalAssessment.documentList.find(findMedRep)) {
          this.medicalAssessment.documentList.push(new MedicalAssessmentDocument({medicalReportSupplierPortalId: item.value.id}));
        }
      } else {
        if (this.medicalAssessment.documentList.find(findMedRep)) {
          this.medicalAssessment.documentList = this.medicalAssessment.documentList.filter(value => value.medicalReportSupplierPortalId !== item.value.id);
        }
      }
    }
  }

  findExcludedElement(diagnoses: Icd10Code[], position: number) {
    return diagnoses !== undefined && diagnoses[position] !== undefined ? diagnoses[position].icd10Code : null;
  }

  updateDiagnosisDate($event) {
    this.medicalAssessment.note.date = $event;
  }

  updateMedEvacDate($event) {
    this.medicalAssessment.medicalEvacuationDate = $event;
  }

  updateFollowupDate($event) {
    this.medicalAssessment.followupDate = $event;
  }

  updateFormValid(valid: boolean) {
    this.formValid = valid;
  }

  getAssessmentType() {
    return AssessmentTypeEnum;
  }

  get medicalAssessmentBasedOnEnum() {
    return MedicalAssessmentBasedOnEnum;
  }

  sourceDeletable() {
    return this.medicalAssessment.sourceList && this.medicalAssessment.sourceList.length > 1;
  }

  deleteSource(index) {
    this.medicalAssessment.sourceList.splice(index, 1);
  }

  private getSenderDisplayString(doc: CaseDocument) {
    if (!!doc.stakeholderSenderId && !!this.stakeholders) {
      const sender = this.stakeholders.filter(stakeholder => +stakeholder.id === doc.stakeholderSenderId)[0];
      if (!!sender?.company) {
        return sender.company.name;
      } else if (!!sender?.person) {
        return !!sender.person?.firstName && !!sender.person?.surname ? sender.person.firstName + ' ' + sender.person.surname : doc.sender;
      }
    }
    return doc.sender ? doc.sender : '';
  }

  public scrollToElement(selector: string) {
    if (!!document.getElementById(selector)) {
      document.getElementById(selector).scrollIntoView();
    }
  }
}
