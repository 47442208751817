import {SubReference} from '@secca/case/components/case-plans/case-plans/add-service-order/models/subReference';
import {SubGopDetails} from '@secca/case/components/case-plans/case-plans/add-service-order/models/subGopDetails';
import {Duration} from '@secca/shared/models/duration';
import {Location} from '@secca/shared/models/location';
import {StatusTypes} from '@secca/shared/models/enums';
import * as moment from 'moment-timezone';
import { CommonServiceOrderExtension } from './CommonServiceOrderExtension.component';
import { CommitmentType } from '../commitment-type';

export class BaseDurationServiceOrderExtension extends CommonServiceOrderExtension {
  id: number;
  reference: SubReference;
  gopDetails: SubGopDetails;
  commitmentType: CommitmentType;
  durations: Duration[];

  // Only used for display purposes in plans
  displayDurationId: number;

  public constructor(init?: Partial<BaseDurationServiceOrderExtension>) {
    super();
    Object.assign(this, init);
    if (!init) {
      this.reference = new SubReference();
      this.gopDetails = new SubGopDetails();
      this.durations = [new Duration()];
    }
  }

  isValidExpectation(): boolean {
    return  this.reference.isValid() &&
      this.isDurationValidOnExpectation();
  }

  isValid(): boolean {
    return !!this.commitmentType &&
      this.gopDetails.isValid() &&
      this.isDurationValid();
  }

  init() {
    this.durations = [new Duration()];
    this.reference = new SubReference();
    this.gopDetails = new SubGopDetails();
    this.commitmentType = null;
  }

  getStartDate(): moment.Moment {
    return this.durations[this.displayDurationId].startDate;
  }

  isMultipleDurations(): boolean {
    return this.durations.length > 1;
  }

  getStartLocation(): Location {
    return this.durations[this.displayDurationId].location;
  }

  getEndLocation(): Location {
    return null;
  }

  getEndDate(): moment.Moment {
    return this.durations[this.displayDurationId].endDate;
  }

  hasMedicalEscort(): boolean {
    return false;
  }

  hasCoTravellers(): boolean {
    return false;
  }

  setCommitmentType(commitmentType: CommitmentType) {
    this.commitmentType = commitmentType;
  }

  getCommitmentType(status?: StatusTypes): CommitmentType {
    if (!status) {
      return this.commitmentType;
    }
    return status === StatusTypes.COMMITTED || status === StatusTypes.CANCELLED ? this.commitmentType : null;
  }

  isDurationValidOnExpectation(): boolean {
    for (let index = 0; index < this.durations.length; index++) {
      const duration = this.durations[index];
      if (!duration.isValidExpectation()) {
        return false;
      }
    }
    return true;
  }

  isPreviewGopValid(): boolean {
    return this.isValid();
  }

  isDurationValid(): boolean {
    for (let index = 0; index < this.durations.length; index++) {
      const duration = this.durations[index];
      if (!duration.isValid()) {
        return false;
      }
    }
    return true;
  }

  getSelectedStakeholderIds(): string[] {
    return null;
  }

}
