import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
import { User, UserAdapter } from './user';

export class CaseLock {
    id: string;
    seccaCaseId: string;
    user: User;
    lockContext: string;

    public constructor(init?: Partial<CaseLock>) {
        Object.assign(this, init);
    }
}

@Injectable({
    providedIn: 'root'
  })
  export class CaseLockAdapter implements Adapter<CaseLock> {

    constructor(private userAdapter: UserAdapter) {
    }

    adapt(item: any): CaseLock {
      return new CaseLock({
        id: item.id,
        seccaCaseId: item.seccaCaseId,
        user: this.userAdapter.adapt(item.user),
        lockContext: item.lockContext
      });
    }
  }
