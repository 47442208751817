import {Component, Input, OnInit} from '@angular/core';
import {MedicalAssessment} from '@secca/shared/models/medicalAssessment';
import {SubRepatriationFormAmbulanceFlight} from '@secca/case/components/case-plans/case-plans/add-service-order/models/subRepatriationFormAmbulanceFlight';
import {MedicalService} from '@secca/core/services/medical.service';
import {ExtraSpaceEnum, InputType, InputTypePattern, RepatriationFormEnum} from '@secca/shared/models/enums';
import {TranslateService} from '@ngx-translate/core';
import {DropdownDictionary} from '@secca/shared/models/dropdownDictionary';
import {SelectItem} from '@secca/case/components/case-medical/medical-components/medical-models';
import { SubRepatriationDetails } from '../../models/subRepatriationDetails';
import { ServiceOrderDetailsHelper } from '@secca/case/components/case-plans/plan/service-order/service-order-details-helper';

@Component({
  selector: 'app-sub-repatriation-form-ambulance-flight',
  templateUrl: './sub-repatriation-form-ambulance-flight.component.html',
  styleUrls: ['./sub-repatriation-form-ambulance-flight.component.scss']
})
export class SubRepatriationFormAmbulanceFlightComponent implements OnInit {

  @Input() readOnlyMode: boolean = false;
  @Input() subRepatriationDetails: SubRepatriationDetails;

  @Input()
  public get caseId(): string {
    return this._caseId;
  }
  public set caseId(caseId: string) {
    this._caseId = caseId;
    this.tryToGetRepatriation();
  }

  @Input()
  public get subRepatriationFormAmbulanceFlight(): SubRepatriationFormAmbulanceFlight {
    return this._subRepatriationFormAmbulanceFlight;
  }
  public set subRepatriationFormAmbulanceFlight(subRepatriationFormAmbulanceFlight: SubRepatriationFormAmbulanceFlight) {
    this._subRepatriationFormAmbulanceFlight = subRepatriationFormAmbulanceFlight;
    this.tryToGetRepatriation();
  }


  private _caseId: string;
  private _subRepatriationFormAmbulanceFlight: SubRepatriationFormAmbulanceFlight;

  medicalAssessments: MedicalAssessment[];
  awaitingMedicalAssessment: MedicalAssessment;
  medicalAssessment: MedicalAssessment;
  repatriationForms: DropdownDictionary[];
  extraSpaceList: SelectItem<ExtraSpaceEnum>[];
  digitsTypePattern: InputTypePattern = InputTypePattern.digitsOnly;
  numberInputType: InputType = InputType.number;

  constructor(private medicalService: MedicalService,
              private translateService: TranslateService,
              private serviceOrderDetailsHelper: ServiceOrderDetailsHelper) {
    this.initializeData();
  }

  ngOnInit(): void {
  }

  initializeData(): void {
    this.repatriationForms = this.getDropdownListFromEnum(RepatriationFormEnum, 'medical-assessment-repatriation-form-');
  }

  private getDropdownListFromEnum(enumItem: any, prefix: string): DropdownDictionary[] {
    return Object.keys(enumItem).map(
      key => new DropdownDictionary(enumItem[key], this.translateService.instant(prefix + key.toString().toLowerCase().replace(/_/g, '-')))
    );
  }

  tryToGetRepatriation(): void {
    if (this.caseId != null && this.subRepatriationFormAmbulanceFlight != null && this.awaitingMedicalAssessment == null) {
      this.medicalService.getMedicalAssessments(+this.caseId).subscribe(
        result => {
          this.medicalAssessments = result.sort((a, b) => b.id - a.id);
          for (const medicalAssessment of this.medicalAssessments) {
            if (medicalAssessment.repatriationInstructionsBase != null &&
                medicalAssessment.repatriationInstructionsBase.repatriationForm != null &&
                medicalAssessment.repatriationInstructionsBase.repatriationInstructionId != null) {
              if (this.awaitingMedicalAssessment == null) {
                this.awaitingMedicalAssessment = medicalAssessment;
              }
              if (
                medicalAssessment.repatriationInstructionsBase.repatriationInstructionId ===
                this.subRepatriationFormAmbulanceFlight.repatriationInstructionId
              ) {
                this.setRepatriation(medicalAssessment);
                break;
              }
            }
          }
        },
        error => console.log(error)
      );
    }
  }

  isHeightFromRepatriationInstructions(): boolean {
    if (this.medicalAssessment &&
      this.medicalAssessment.repatriationInstructionsBase &&
      this.medicalAssessment.repatriationInstructionsBase.repatriationForm &&
      this.medicalAssessment.repatriationInstructionsBase.repatriationForm.patientHeight) {
      return true;
    } else {
      return false;
    }
  }

  public get height(): number {
    if (this.isHeightFromRepatriationInstructions()) {
      return this.medicalAssessment.repatriationInstructionsBase.repatriationForm.patientHeight;
    }
    else {
      return this.subRepatriationFormAmbulanceFlight.height;
    }
  }
  public set height(height: number) {
    this.subRepatriationFormAmbulanceFlight.height = height;
  }


  isWeightFromRepatriationInstructions(): boolean {
    if (this.medicalAssessment &&
      this.medicalAssessment.repatriationInstructionsBase &&
      this.medicalAssessment.repatriationInstructionsBase.repatriationForm &&
      this.medicalAssessment.repatriationInstructionsBase.repatriationForm.patientWeight) {
      return true;
    } else {
      return false;
    }
  }

  public get weight(): number {
    if (this.isWeightFromRepatriationInstructions()) {
      return this.medicalAssessment.repatriationInstructionsBase.repatriationForm.patientWeight;
    } else {
      return this.subRepatriationFormAmbulanceFlight.weight;
    }
  }

  public set weight(weight: number) {
    this.subRepatriationFormAmbulanceFlight.weight = weight;
  }

  setLatestRepatriation(): void {
    const currentRepatriationForm = this.serviceOrderDetailsHelper.getRepatriationFormRequested(this.medicalAssessment);
    const awaitingRepatriationForm = this.serviceOrderDetailsHelper.getRepatriationFormRequested(this.awaitingMedicalAssessment);
    if ( currentRepatriationForm !== awaitingRepatriationForm ) {
      this.subRepatriationDetails.repatriationForm = "";
    }

    this.setRepatriation(this.awaitingMedicalAssessment);
    this.subRepatriationFormAmbulanceFlight.luggageDescription = null;
  }

  setRepatriation(medicalAssessment: MedicalAssessment): void {
    this.medicalAssessment = medicalAssessment;
    this.extraSpaceList = this.getMultiSelectItemFromEnum(
      ExtraSpaceEnum,
      this.medicalAssessment.repatriationInstructionsBase.repatriationForm.extraSpaces,
      'repatriation-extra-space-type-'
    );
    this.subRepatriationFormAmbulanceFlight.repatriationInstructionId = medicalAssessment.repatriationInstructionsBase.repatriationInstructionId;
    this.subRepatriationFormAmbulanceFlight.medicalAssessmentId = medicalAssessment.id;
  }

  private getMultiSelectItemFromEnum(enumItem: any, selectedPath: any[], prefix: string): SelectItem<ExtraSpaceEnum>[] {
    return Object.keys(enumItem).map(key => {
      return new SelectItem({
        label: this.translateService.instant(prefix + key.toString().toLowerCase().replace(/_/g, '-')),
        value: enumItem[key],
        selected: !!selectedPath.find(saved => saved.type === key || saved.item === key),
      });
    });
  }

  get isThereRepatriationInstruction(): boolean {
    return (
      this.awaitingMedicalAssessment != null &&
      this.awaitingMedicalAssessment.repatriationInstructionsBase != null &&
      this.awaitingMedicalAssessment.repatriationInstructionsBase.repatriationForm != null
    );
  }

  get RepatriationFormEnum() {
    return RepatriationFormEnum;
  }

  get isOtherSelected(): boolean {
    if (this.extraSpaceList == null) {
      return false;
    }
    return this.extraSpaceList.some(a => a.value === ExtraSpaceEnum.OTHER && a.selected);
  }

  get otherExtraSpaceText(): string {
    if (this.isOtherSelected) {
      return this.medicalAssessment.repatriationInstructionsBase.repatriationForm.extraSpaces.find(a => a.type === ExtraSpaceEnum.OTHER)
        .otherItemDescription;
    } else {
      return null;
    }
  }

}
