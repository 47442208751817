import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
import { PhoneNumber } from './phoneNumber';
import { GopMethodEnum } from './enums';

export class Gop {
    id: number;
    contact: string;
    email: string;
    remarks: string;
    agentName: string;
    agentId: number;
    providerAgentId: number;
    method: GopMethodEnum;
    providerMethod: GopMethodEnum;
    phoneNumbers: PhoneNumber[];
    preferredChannelCode: string;
    preferredLanguageCode: string;

    public constructor(init?: Partial<Gop>) {
        Object.assign(this, init);
        this.phoneNumbers = Array<PhoneNumber>();
    }
}

@Injectable({
    providedIn: 'root'
})

export class GopAdapter implements Adapter<Gop> {
    adapt(item: any): Gop {
        return new Gop({
            id: item.id,
            contact: item.contact,
            email: item.email,
            remarks: item.remarks,
            agentName: item.agentName,
            agentId: item.agentId,
            providerAgentId: item.providerAgentId,
            method: item.method,
            providerMethod: item.providerMethod,
            phoneNumbers: item.phoneNumbers,
            preferredChannelCode: item.preferredChannelCode,
            preferredLanguageCode: item.preferredLanguageCode,
        });
    }
}
