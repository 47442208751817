import { AbstractGlobalBoardTableShortcutsComponent } from "@secca/shared/components/abstract-global-board-table-shortcuts.component";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { OpenInvoicePdfComponent } from "@secca/shared/components/open-invoice-pdf/open-invoice-pdf.component";
import { AutoUnsubscribe } from "@secca/shared/decorators/auto-unsubscribe";
import { TableSort } from "@secca/shared/models/table-sort";
import { RecoveryPaymentTaskViewModel } from "@secca/board/components/board-task/models/recovery-payment-task-view.model";
import { RecoveryPaymentStatus, ShortcutEnum, SortOrder} from "@secca/shared/models/enums";
import { SettingsService } from "@secca/core/services/settings.service";
import { CaseService } from "@secca/core/services/case.service";
import { Router } from "@angular/router";
import { DataService } from "@secca/core/services/data.service";
import { RecoveryPaymentService } from "@secca/core/services/recovery/recovery-payment.service";
import { BoardNavigationStateService } from "@secca/board/components/board-task/services/board-navigation-state.service";
import { LockContextEnum } from "@secca/shared/enums/lock-context.enum";
import { CaseStateService } from "@secca/core/state-services/case-state.service";
import { CaseLockHelperService } from "@secca/core/services/case-lock-helper.service";
import { TaskStateService } from "@secca/board/components/board-task/services/task-state.service";
import { DialogViewerService } from "@secca/core/services/dialog-viewer.service";
import { TaskViewModel } from "@secca/board/components/board-task/models/task-view.model";

@Component({
  selector: 'app-board-task-recovery-payment-table',
  templateUrl: './board-task-recovery-payment-table.component.html',
  styleUrls: ['./board-task-recovery-payment-table.component.scss'],
  providers: [OpenInvoicePdfComponent],
})
@AutoUnsubscribe
export class BoardTaskRecoveryPaymentTableComponent extends AbstractGlobalBoardTableShortcutsComponent implements OnInit, OnDestroy {
  @Input() taskSorting: TableSort;
  @Input() set taskViewModels(data: RecoveryPaymentTaskViewModel[]) {
    this._taskViewModels = data;
    this.numberOfRows = !!data ? data.length : 0;

    setTimeout(() => {
      this.selectedRowIndex = this.boardNavigationStateService.getState('board-case-filter-request-recovery-payment-selected-row-index') ? parseInt(this.boardNavigationStateService.getState('board-case-filter-request-recovery-payment-selected-row-index'), 10) : 0;
      this.inputScrollPosition = this.boardNavigationStateService.getState('board-case-filter-request-recovery-payment-task-scroll-position') ? parseInt(this.boardNavigationStateService.getState('board-case-filter-request-recovery-payment-task-scroll-position'), 10) : 0;
      this.navigateToTable();
    }, 50);
  }
  get taskViewModels(): RecoveryPaymentTaskViewModel[] { return this._taskViewModels; }
  @Output() sortingAction = new EventEmitter<TableSort>();
  @Output() recoveryPaymentMovedEvent = new EventEmitter<unknown>();
  @Output() taskUpdatedEvent = new EventEmitter<unknown>();
  inputScrollPosition: number = 0;
  isAllSelected = false;

  private selectedTaskViewModel: TaskViewModel;

  get sortedOn(): any {
    const sortedOn = {};
    if (this.taskSorting != null) {
      sortedOn[this.taskSorting.sortBy] = true;
    }
    return sortedOn;
  }
  get sortOrder(): any {
    const sortOrder = {};
    if (this.taskSorting != null) {
      sortOrder[this.taskSorting.sortBy] = this.taskSorting.sortOrder;
    }
    return sortOrder;
  }

  private _taskViewModels: RecoveryPaymentTaskViewModel[];


  constructor(
    private caseService: CaseService,
    private caseStateService: CaseStateService,
    private caseLockHelperService: CaseLockHelperService,
    private taskStateService: TaskStateService,
    private dialogViewerService: DialogViewerService,
    private recoveryPaymentService: RecoveryPaymentService,
    private openInvoicePdfComponent: OpenInvoicePdfComponent,
    public settingsService: SettingsService,
    private router: Router,
    private dataService: DataService,
    private boardNavigationStateService: BoardNavigationStateService
  ) {
    super('taskRecoveryPaymentTable');
  }

  ngOnInit(): void {
    this.selectedRowIndex = this.boardNavigationStateService.getState('board-case-filter-request-recovery-payment-selected-row-index') ? parseInt(this.boardNavigationStateService.getState('board-case-filter-request-recovery-payment-selected-row-index'), 10) : 0;
    this.inputScrollPosition = this.boardNavigationStateService.getState('board-case-filter-request-recovery-payment-task-scroll-position') ? parseInt(this.boardNavigationStateService.getState('board-case-filter-request-recovery-payment-task-scroll-position'), 10) : 0;

    this.shortcutSubscriptions.push(
      this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateTableRowCtrlEnter }).subscribe(a => {
        if (Number.isFinite(this.selectedRowIndex)) {
          this.viewTask(this.taskViewModels[this.selectedRowIndex] as any);
        }
      }),
      this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateTableRowAltEnter }).subscribe(a => {
        if (Number.isFinite(this.selectedRowIndex)) {
          this.manageInvoiceAndOpenPDF(this.taskViewModels[this.selectedRowIndex] as any);
        }
      }),
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.boardNavigationStateService.saveState('board-case-filter-request-request-recovery-task-scroll-position', JSON.stringify(this.inputScrollPosition));
    this.boardNavigationStateService.saveState('board-case-filter-request-request-recovery-selected-row-index', JSON.stringify(this.selectedRowIndex));
  }


  selectAllTasks() {
    this.isAllSelected = !this.isAllSelected;
    this.taskViewModels.forEach(a => (a.isSelected = this.isAllSelected));
  }

  sortBy(column: string) {
    const sortOrder = {};
    if (this.sortOrder[column] == null) {
      sortOrder[column] = SortOrder.asc;
    } else {
      if (this.sortOrder[column] === SortOrder.asc) {
        sortOrder[column] = SortOrder.desc;
      } else {
        sortOrder[column] = SortOrder.asc;
      }
    }
    this.sortingAction.emit(new TableSort({ sortBy: column, sortOrder: sortOrder[column] }));
  }

  sortedBy(column: string): string {
    return this.sortedOn[column] ? 'sorted-by' : '';
  }

  onCaseTableScroll() {
    this.inputScrollPosition = document.getElementById('taskRecoveryPaymentTable').scrollTop;
  }

  viewTask(task: RecoveryPaymentTaskViewModel) {
    const doneStatuses = [RecoveryPaymentStatus.REJECTED, RecoveryPaymentStatus.APPROVED, RecoveryPaymentStatus.FINALIZED];
    let recoveryPaymentStatus: RecoveryPaymentStatus|null = null;
    for (const[key, value] of Object.entries(RecoveryPaymentStatus)) {
      if (value === task.recoveryPaymentStatus) { recoveryPaymentStatus = RecoveryPaymentStatus[key]; }
    }
    if (recoveryPaymentStatus && doneStatuses.find(done => done == recoveryPaymentStatus)) {
      return;
    }

    this.caseService.getCase(task.caseId.toString()).subscribe(seccaCase => {
      this.caseStateService.sendCase(seccaCase);

      // Update caselocks to determine if loggedin user has already taken the lock
      this.caseLockHelperService.updateCaseLocks(seccaCase.id, seccaCase.caseLocks);

      const isCaseAlreadyLockedByTheLoggedInUser = this.caseLockHelperService.isCaseLockedByTheLoggedInUser(LockContextEnum.CASE_BASIC);
      if (!isCaseAlreadyLockedByTheLoggedInUser && !this.caseLockHelperService.isCaseBasicLockTaken) {
        // If not already locked by the loggedin user or others, we try to take the lock
        this.caseLockHelperService.lockCase(LockContextEnum.CASE_BASIC).subscribe();
      }
    });

    this.taskStateService.getTaskById(task.id).subscribe(taskViewModel => {
      this.selectedTaskViewModel = taskViewModel.convertToTaskViewModel();
      this.dialogViewerService.openTaskMessageDialog(this.selectedTaskViewModel, false, () => this.recoveryPaymentMoved(), () => this.taskUpdated(), );
    });

  }

  getBrandImagesUrlForProfile(profileId: number) {
    return this.caseService.getBrandImagesUrlForProfile(profileId.toString());
  }

  manageInvoiceAndOpenPDF(recoveryPaymentTaskViewModel: RecoveryPaymentTaskViewModel) {
    if (recoveryPaymentTaskViewModel.recoveryPaymentId) {
      this.redirectToManageRecoveryPayment(recoveryPaymentTaskViewModel.caseId, recoveryPaymentTaskViewModel.recoveryPaymentId);
      this.recoveryPaymentService
        .getRecoveryPayment(recoveryPaymentTaskViewModel.recoveryPaymentId)
        .subscribe(recoveryPayment => this.openInvoicePdfComponent.getAndOpenInvoicePDFNewWindow(recoveryPayment.documentId, null));
    }
  }

  private redirectToManageRecoveryPayment(caseId: number, recoveryPaymentId: number) {
    this.dataService.navigateRecoveryPaymentId = recoveryPaymentId;
    this.router.navigate(['case', caseId]);
  }

  recoveryPaymentMoved() {
    this.recoveryPaymentMovedEvent.emit();
  }

  taskUpdated() {
    this.taskUpdatedEvent.emit();
  }

}
