import {ErrorModalDialogConfiguration} from '@secca/shared/models/errormodal/modal-error-configuration';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbNavChangeEvent} from '@ng-bootstrap/ng-bootstrap';
import {HttpErrorResponse} from '@angular/common/http';
import { ERROR_TYPE } from '@secca/shared/models/enums';
import {GlobalErrorModalSessionObject} from '@secca/shared/models/errormodal/global-error-modal-session-object';


@Component({
  selector: 'app-modal-error-dialog-global',
  templateUrl: './modal-error-dialog-global.component.html',
  styleUrls: ['./modal-error-dialog-global.component.scss']
})
export class ModalErrorDialogGlobalComponent implements OnInit {
  @Input() configuration: ErrorModalDialogConfiguration;
  @Output() closeModalEvent = new EventEmitter<boolean>();
  @Input() suppressErrors: boolean;

  @Output() selectedErrorEvent = new EventEmitter<Error | HttpErrorResponse>();

  showModal = false;
  active: string;
  constructor() {}

  ngOnInit() {
  }

  close(close: boolean) {
    this.closeModalEvent.emit(close);
  }

  saveModel() {
    const globalErrorModalObject = new GlobalErrorModalSessionObject({
      expiresAt: new Date(new Date().getTime() + (20 * 60000)).toJSON(),
      suppressErrors: this.suppressErrors
    });
    sessionStorage.setItem(GlobalErrorModalSessionObject.key, JSON.stringify(globalErrorModalObject));
  }

  fetchError(event: NgbNavChangeEvent) {
    const split = event.nextId.split('-');
    if (split[0] === ERROR_TYPE.ERROR) {
      this.selectedErrorEvent.emit(this.configuration.errors[split[1]]);
    } else if (split[0] === ERROR_TYPE.HTTP_ERROR) {
      this.selectedErrorEvent.emit(this.configuration.httpErrors[split[1]]);
    }
  }

  get ERROR_TYPE() {
    return ERROR_TYPE;
  }
}

