<div class="container" *ngIf="!!progress">
  <div class="containerItem" *ngFor="let event of eventsToDisplay; let i = index">
    <div
      class="line"
      [ngClass]="{
        'first-line': isFirst(event),
        'yellow-line': isAggregatedOrAnySubsequent(event),
        'current': isCurrentAndSuccess(event),
        'last-line': isLast(event),
        'gray-line': isAnyCancelledOrAfterCurrent(event),
        'hidden': isHidden(event)
      }"
    ></div>

    <ng-template #popTitle>
      {{ 'economy-popover-content-poptitle-' + type | translate }}: {{ identifier }}
    </ng-template>
    <ng-template #popContent>
      <div class="popover-content">
        <div *ngFor="let modified of getEvent(event)?.modifierList">
          <div class="popover-content-separator"></div>
          <div class="popover-content-label">{{ 'economy-balance-sheet-entry-status-' + modified.status | translate }}</div>
          <div class="popover-content-text">{{ modified.modifiedOn | momentFormat: 'DD MMM YYYY HH:mm':'local' }}</div>
          <div class="popover-content-text-last">{{ modified.modifiedBy }}</div>
        </div>
      </div>
    </ng-template>
    <div
      class="icon"
      [ngClass]="{
        success: getEvent(event)?.status === 'SUCCESS',
        failure: getEvent(event)?.status === 'FAILURE' || getEvent(event)?.status === 'CANCELLED',
        warn: getEvent(event)?.status === 'WARNING',
        cancelled: isAnyCancelled(),
        'success-yellow': isAggregatedStatus(event),
        'empty-gray-ring': isAfterCurrent(event),
        'hidden': isHidden(event)
      }"
      [ngbPopover]="popContent"
      [popoverTitle]="popTitle"
      [disablePopover]="hideAuditPopover(event)"
      placement="right-top right-bottom"
      triggers="hover"
      openDelay="300"
    ></div>
    <div class="text" *ngIf="isCurrent(event)" [ngClass]="{'first-label': isFirst(event), 'last-label': isLast(event) }">
      {{ 'economy-balance-sheet-entry-status-' + getEvent(event)?.text | translate }}
    </div>
  </div>
</div>
