import {DropdownWitIconDictionary} from "@secca/shared/models/dropdownWithIconDictionary";

export class DropdownWithIconDisableDictionary extends DropdownWitIconDictionary {
  disabled: boolean;
  public constructor(id?: any, name?: any, iconPath?: string, disabled?: boolean, hoverText?: string) {
    super(id, name, iconPath);
    this.disabled = disabled;
    this.hoverText = hoverText;
  }

}
