<ng-container *ngFor="let medicalEscort of medicalEscorts">
  <div class="sub-component-container mb-3">
    <div class="sub-title">
      <div class="d-flex">
        <div class="me-auto p-0 w-100">
          {{'sub-medical-escort-title' | translate}} {{!!medicalEscort.escortIndex ? medicalEscort.escortIndex : ''}}
        </div>
        <div>
          <div class="d-flex justify-content-center align-items-center h-100" (click)="deleteMedicalEscort($event)">
            <img class="delete-button" src="../../../../assets/icons/garbage.svg" />
          </div>
        </div>
      </div>
    </div>
    <div class="sub-content-container">
      <app-sub-stakeholders [caseId]="caseId"
                            [showInvalidBackground]="!medicalEscortIsUnique"
                            [stakeholders]="stakeholders"
                            [(subStakeholders)]="medicalEscort.subStakeholders"
                            [subStakeholdersConfig]="subStakeholdersConfig">
      </app-sub-stakeholders>
    </div>
    <div class="sub-component-horizontal-line"></div>
    <div class="sub-content-container">
      <div class="d-flex mb-1">
        <div class="me-auto w-100">
          <div class="sub-content-title">{{'sub-medical-escort-outbound-trip' | translate}}</div>
        </div>
        <div class="pe-2">
          <label class="text-nowrap force-cursor" for="no-outbound-trip">{{'sub-medical-escort-no-outbound-trip-needed' | translate}}</label>
        </div>
        <div class="pe-3">
          <div class="custom-checkbox">
            <label class="checkbox">
              <input id="no-outbound-trip" type="checkbox" (click)="checkNoOutboundTripNeeded(medicalEscort)"
                [checked]="medicalEscort.outboundTripNotNeeded">
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>
      <app-sub-travellers
        [stakeholders]="stakeholders"
        [(travellers)]="medicalEscort.subOutboundTrips"
        [hideFlightInRefs]="!isConfirmationTab"
        [hideAddFlightLeg]="!isConfirmationTab"
        [departureDateRequired]="true"
        [departureFromRequired]="true"
        [cabinClassRequired]="true"
        [arrivalDateRequired]="isConfirmationTab"
        [arrivalAtRequired]="true"
        [flightNoRequired]="isConfirmationTab"
        [agencyReferenceRequired]="isConfirmationTab"
        [readOnlyMode]="medicalEscort.outboundTripNotNeeded"
        [serviceOrderType]="serviceOrderType"
      >
      </app-sub-travellers>
    </div>
    <div class="sub-component-horizontal-line"></div>
    <div class="sub-content-container">
      <div class="sub-content-title">{{'sub-medical-escort-minimum-rest' | translate}}</div>
      <div class="row" *ngIf="!isConfirmationTab">
        <div class="col-6">
          <div class="common-drop-down-arrow drop-down">
            <app-drop-down
              class="custom"
              [items]="minimumRestTypesDropdown"
              [requiredRedBackground]=true
              [(selectedItemId)]="medicalEscort.minimumRest"
            ></app-drop-down>
          </div>
        </div>
      </div>
      <div class="repatriation-container" *ngIf="isConfirmationTab">
        <div class="d-flex justify-content-center align-items-center h-100">{{medicalEscort.minimumRest}}</div>
      </div>
    </div>
    <ng-container *ngIf="serviceOrderType.serviceTypeEnumValue === ServiceTypeEnum.REGULAR_FLIGHT_MEDICAL">
      <div class="sub-component-horizontal-line"></div>
      <div class="sub-content-container">
        <div class="d-flex mb-1">
          <div class="me-auto w-100">
            <div class="sub-content-title">{{'sub-medical-escort-hotel' | translate}}</div>
          </div>
          <div class="pe-2">
            <label class="text-nowrap force-cursor" for="no-accommodation">{{'duration-accommodation-not-needed' | translate}}</label>
          </div>
          <div class="pe-3">
            <div class="custom-checkbox">
              <label class="checkbox">
                <input id="no-accommodation" type="checkbox" [(ngModel)]="medicalEscort.accommodationNotNeeded" />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
          <app-duration
          [incidentId]="incidentId ? '' + incidentId : null"
          [duration]="medicalEscort.accommodation"
          [hideGOPDates]="true"
          [calculateDefaultDates]="false"
          [notNeeded]="medicalEscort.accommodationNotNeeded"
          [title]="false"
        > </app-duration>
      </div>
    </ng-container>
    <div class="sub-component-horizontal-line"></div>
    <div class="sub-content-container">
      <div class="sub-content-title">{{'sub-medical-escort-repatriation-with-end-user' | translate}}</div>
      <div class="repatriation-container">
        <div class="d-flex justify-content-center align-items-center h-100">{{'sub-medical-escort-repatriation-with-end-user-response' | translate}}</div>
      </div>
    </div>
    <div class="sub-component-horizontal-line"></div>
    <div class="sub-content-container">
      <div class="d-flex mb-1">
        <div class="me-auto w-100">
          <div class="sub-content-title">{{'sub-medical-escort-return-trip' | translate}}</div>
        </div>
        <div class="pe-2">
          <label class="text-nowrap force-cursor" for="no-return-trip">{{'sub-medical-escort-no-return-trip-needed' | translate}}</label>
        </div>
        <div class="pe-3">
          <div class="custom-checkbox">
            <label class="checkbox">
              <input id="no-return-trip" type="checkbox" (click)="checkNoReturnTripNeeded(medicalEscort)"
                [checked]="medicalEscort.returnTripNotNeeded">
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>
      <app-sub-travellers
        [stakeholders]="stakeholders"
        [(travellers)]="medicalEscort.subReturnTrips"
        [hideFlightInRefs]="!isConfirmationTab"
        [hideAddFlightLeg]="!isConfirmationTab"
        [departureDateRequired]="true"
        [departureFromRequired]="true"
        [cabinClassRequired]="true"
        [arrivalDateRequired]="isConfirmationTab"
        [arrivalAtRequired]="true"
        [flightNoRequired]="isConfirmationTab"
        [agencyReferenceRequired]="isConfirmationTab"
        [readOnlyMode]="medicalEscort.returnTripNotNeeded"
        [minDate]="getLastInboundDate(medicalEscort)"
        [serviceOrderType]="serviceOrderType">
      </app-sub-travellers>
    </div>
  </div>
</ng-container>
<button class="primary-button-white" (click)="addMedicalEscort()">{{'sub-medical-escort-add-medical-escort' | translate}}</button>
