import { Component, OnInit, Input, Output, ViewEncapsulation, EventEmitter, OnChanges } from '@angular/core';
import { OWL_DATE_TIME_FORMATS, DateTimeAdapter, OWL_DATE_TIME_LOCALE } from '@danielmoncada/angular-datetime-picker';

import * as moment from 'moment';

export const DATE_FORMATS = {
  fullPickerInput: 'DD MMM YYYY HH:mm:ss',
  datePickerInput: 'DD MMM YYYY HH:mm',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
  serverSideDateFormat: 'YYYY-MM-DD HH:mm:ss',
  serverSideInput: 'YYYY-MM-DD HH:mm:ss',
};

@Component({
  selector: 'app-input-time',
  templateUrl: './input-time.component.html',
  styleUrls: ['./input-time.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: OWL_DATE_TIME_FORMATS, useValue: DATE_FORMATS },
  ],
})
export class InputTimeComponent implements OnInit, OnChanges {
  @Input() name: string;
  @Input() withImage: boolean;
  @Input() dateTimeRange: any;
  @Input() selectMode: string;
  @Input() startingPlaceholder: string;
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() isValid = true;
  @Input() isValidMessage = '';
  @Output() dateTimeRangeChange = new EventEmitter();
  @Output() saveModel = new EventEmitter();
  value: Date;
  public startAt = new Date();
  oldValue: Date;
  oldDateTimeRange: any;
  pickerOpen: boolean;

  constructor() {}

  public setPlaceholderManually(placeholder: string) {
    this.placeholder = placeholder;
  }

  onChange() {
    this.dateTimeRangeChange.emit(this.dateTimeRange);
    this.pickerOpen = false;
  }

  afterPickerOpen() {
    this.pickerOpen = true;
  }

  onSaveModel() {
    this.convertDateTimeRange();
    if (this.selectMode === 'range') {
      if (JSON.stringify(this.dateTimeRange) !== JSON.stringify(this.oldDateTimeRange)) {
        this.saveModel.emit();
      }
    } else {
      if (this.oldValue !== this.value) {
        this.saveModel.emit();
      }
    }
  }

  setOpenedFromCalendarIcon() {
    this.onSaveState();
  }

  onSaveState() {
    this.convertDateTimeRange();
    if (this.selectMode === 'range') {
      if (!this.pickerOpen) {
        this.oldDateTimeRange = this.dateTimeRange;
        if (this.dateTimeRange != null) {
          this.startAt = moment(this.dateTimeRange[0], [DATE_FORMATS.datePickerInput, DATE_FORMATS.serverSideDateFormat]).toDate();
        }
      }
    } else {
      this.oldValue = this.dateTimeRange;
      if (this.dateTimeRange != null) {
        this.startAt = moment(this.dateTimeRange, [DATE_FORMATS.datePickerInput, DATE_FORMATS.serverSideDateFormat]).toDate();
      }
    }
  }

  convertDateTimeRange() {
    if (this.selectMode === 'range') {
      if (this.dateTimeRange == null || this.dateTimeRange === '') {
        this.dateTimeRange = null;
        this.value = null;
      } else {
        if (this.dateTimeRange[0] != null) {
          this.dateTimeRange[0] = moment(this.dateTimeRange[0], [DATE_FORMATS.datePickerInput, DATE_FORMATS.serverSideDateFormat]).toDate();
        }
        if (this.dateTimeRange[1] != null) {
          this.dateTimeRange[1] = moment(this.dateTimeRange[1], [DATE_FORMATS.datePickerInput, DATE_FORMATS.serverSideDateFormat]).toDate();
        }
        this.placeholder = this.dateTimeRange[0] == null ? '' : this.dateTimeRange[0];
        this.placeholder = this.dateTimeRange[1] == null ? this.placeholder : this.placeholder + ' ~ ' + this.dateTimeRange[1];
      }
    } else {
      if (this.dateTimeRange == null || this.dateTimeRange === '') {
        this.dateTimeRange = null;
        this.value = null;
      } else if (this.dateTimeRange instanceof Date) {
        this.value = this.dateTimeRange;
        this.dateTimeRange = moment(this.dateTimeRange).format(DATE_FORMATS.datePickerInput);
        this.placeholder = this.dateTimeRange;
      } else if (this.isString(this.dateTimeRange)) {
        this.value = moment(this.dateTimeRange, [DATE_FORMATS.datePickerInput, DATE_FORMATS.serverSideDateFormat]).toDate();
        this.dateTimeRange = moment(this.dateTimeRange, [DATE_FORMATS.datePickerInput, DATE_FORMATS.serverSideDateFormat]).format(
          DATE_FORMATS.datePickerInput
        );
        this.placeholder = this.dateTimeRange;
      } else if (moment.isMoment(this.dateTimeRange)) {
        this.value = this.dateTimeRange.toDate();
        this.dateTimeRange = this.dateTimeRange.format(DATE_FORMATS.datePickerInput);
        this.placeholder = this.dateTimeRange;
      } else if (Array.isArray(this.dateTimeRange)) {
        this.dateTimeRange[0] = moment(this.dateTimeRange[0]).format(DATE_FORMATS.datePickerInput);
        this.dateTimeRange[1] = moment(this.dateTimeRange[1]).format(DATE_FORMATS.datePickerInput);
        this.placeholder = this.dateTimeRange[0] + ' ' + this.dateTimeRange[1];
      }
    }
  }

  ngOnInit() {
    this.onSaveState();
  }

  ngOnChanges() {
    this.convertDateTimeRange();
  }

  onInputCheckEmpty($event) {
    if ((event.target as HTMLInputElement).value == null || (event.target as HTMLInputElement).value === '') {
      this.dateTimeRange = null;
      this.value = null;
    }
  }

  private isString(value) {
    return typeof value === 'string'
  }
}
