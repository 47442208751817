<div class="plan">
  <div class="plan-header">
    <div class="plan-header-timeline">
      {{ timelineText }}
      <div class="paginate" (click)="loadMoreDaysTop(100)">
        <img class="top" src="/assets/icons/Right Slide Arrow.svg">
      </div>
      <div class="paginate" (click)="loadMoreDaysBottom(100)">
        <img class="bottom" src="/assets/icons/Right Slide Arrow.svg">
      </div>
    </div>
    <div class="plan-header-stakeholder plan-header-first-stakeholder-style">
      {{'plans-end-user' | translate}}
      <div class="enduser"></div>
    </div>
    <div class="plan-header-stakeholder plan-header-second-stakeholder-style">
      <div *ngIf="isMedicalEscort && !isCoTraveller">
        {{'plans-medical-escort' | translate}}
        <div class="medicalescort"></div>
      </div>
      <div *ngIf="!isMedicalEscort && isCoTraveller">
        {{'plans-co-traveller' | translate}}
        <div class="cotraveller"></div>
      </div>
      <div *ngIf="isMedicalEscort && isCoTraveller">
        <div class="plan-header-stakeholder-medicalescort">
          {{'plans-medical-escort' | translate}}
          <div class="medicalescort"></div>
        </div>
        <div class="plan-header-stakeholder-cotraveller">
          {{'plans-co-traveller' | translate}}
          <div class="cotraveller"></div>
        </div>
      </div>
    </div>
  </div>
  <div id="plan-content" class="plan-content" #planContent>
    <div class="plan-content-container">
      <div class="plan-content-container">
      <div *ngFor="let dayInTimeline of daysInTimeline" class="plan-content-container-date"
        [ngStyle]="{'height':getHeightForDate(dayInTimeline)+'px'}">
        <div [ngClass]="{'bold-text': isToday(dayInTimeline) }"> {{ dayInTimeline | date:'dd MMM yyyy' | lowercase }}</div>
      </div>
      <div class="plan-content-container-services">
        <ng-template ngFor let-serviceOrder [ngForOf]="serviceOrdersCalculated">
          <app-service-order #serviceOrderComponents [serviceOrders]="serviceOrdersCalculated" [providersIcons]="providersIcons"
            [providersNames]="providersNames" [serviceOrder]="serviceOrder" [timeline]="timeline" [stakeholders]="stakeholders"
            [daysInTimeline]="daysInTimeline" [planHelper]="planHelper" [numberOfLanes]="numberOfLanes"
            (openServiceEditDialog)="onOpenServiceEditDialog($event)" [caseStakeholder]="stakeholders"
            [incidentEvent]="incidentEvent">
          </app-service-order>
        </ng-template>
      </div>
      <div class="plan-content-container-vertical-line" [ngStyle]="firstVerticalLine"></div>
      <div class="plan-content-container-vertical-line" [ngStyle]="secondVerticalLine"></div>
      <div class="plan-content-container-vertical-line" *ngIf="isMedicalEscort && isCoTraveller" [ngStyle]="thirdVerticalLine"></div>
      <div *ngFor="let dayInTimeline of daysInTimeline.slice(1); let i = index" [ngStyle]="{'top': getTopForHorizontalLine(i) }"
        class="plan-content-container-horizontal-line">
      </div>
      <div class="plan-content-container-current-day" [ngStyle]="{'top':currentDayTop, 'height': getHeightForCurrentDay}">
      </div>
      <div *ngIf="timeline.accidentDate" class="plan-content-container-accident-date" [ngStyle]="{'top':accidentTop}"></div>
      <div *ngIf="timeline.travelEnd" class="plan-content-container-landing-date" [ngStyle]="{'top':landingTop}"></div>
      <div *ngIf="timeline.travelBegin" class="plan-content-container-starting-date" [ngStyle]="{'top':startingTop}"></div>
      <div *ngIf="timeline.accidentDate" class="plan-content-container-before-accident-line"
        [ngStyle]="{'bottom':accidentBeforeLineBottom, 'top':startingTop}"></div>
      <div *ngIf="timeline.accidentDate" class="plan-content-container-after-accident-line"
        [ngStyle]="{'bottom':travelEndBeforeLineBottom, 'top':accidentTop}"></div>
      <div *ngIf="timeline.coverageDayEnd" class="plan-content-container-after-landing-line"
        [ngStyle]="{'bottom':coverageDayEndBeforeLineBottom, 'top':landingTop}"></div>
      <div *ngIf="timeline.travelBegin && timeline.travelEnd && !timeline.accidentDate" class="plan-content-container-after-accident-line"
        [ngStyle]="{'bottom':travelEndBeforeLineBottom, 'top':startingTop}"></div>
      <div *ngIf="timeline.coverageDayEnd" class="plan-content-container-stop"
        [ngStyle]="{'top':coverageDayEndTop, 'background-image':stopIcon}"></div>
    </div>
  </div>
</div>
