<div class="task-table" *ngIf="simpleTaskViewModels; else loadingTemplate">
  <div class="primary-table-container">
    <table class="primary-table fixed-header-table" id="task-table">
      <thead>
        <tr>
          <td class="checkbox-column-header">
            <div [appDisableInput]="caseStateService.isCaseDisabled()">
            <div class="custom-checkbox checkbox-header-container">
              <label class="checkbox">
                <input type="checkbox" (click)="checkAllTasks()" [(ngModel)]="isCheckAll">
                <span class="checkmark"></span>
              </label>
            </div>
            </div>
          </td>
          <td class="column-type-header" sortColumn [sortKey]="'taskType'" [data]="simpleTaskViewModels"
            [toggleSort]="!toggleSortType('taskType')" (click)="sortBy('taskType')" [ngClass]="sortedBy('taskType')">
            {{"board-task-table-type" | translate}}</td>
          <td class="column-due-date-header" sortColumn [sortKey]="'dueDate'" [data]="simpleTaskViewModels"
            [toggleSort]="!toggleSortType('dueDate')" (click)="sortBy('dueDate')" [ngClass]="sortedBy('dueDate')">
            {{"board-task-table-due" | translate}}</td>
          <td class="column-priority-header" sortColumn [sortKey]="'prioritySort'" [data]="simpleTaskViewModels"
            [toggleSort]="!toggleSortType('prioritySort')" (click)="sortBy('prioritySort')" [ngClass]="sortedBy('prioritySort')">
            {{"board-task-table-priority" | translate}}</td>
          <td class="column-title-header" sortColumn [sortKey]="'title'" [data]="simpleTaskViewModels"
            [toggleSort]="toggleSortType('title')" (click)="sortBy('title')" [ngClass]="sortedBy('title')">
            {{"board-task-table-task-title" | translate}}
          </td>
          <td class="column-assign-header" sortColumn [sortKey]="'assignInitials'" [data]="simpleTaskViewModels"
            [toggleSort]="toggleSortType('assignInitials')" (click)="sortBy('assignInitials')" [ngClass]="sortedBy('assignInitials')">
            {{"board-task-table-assign" | translate}}
          </td>
        </tr>
      </thead>
      <tbody id="task-table-tbody">
        <ng-container *ngFor="let simpleTaskViewModel of simpleTaskViewModels; index as i">
          <tr [class.task-check]="simpleTaskViewModel.isCheck" id="{{'task'+simpleTaskViewModel.id}}">
            <td class="checkbox-column" [ngClass]="{'even': i % 2 != 0}">
              <div [appDisableInput]="caseStateService.isCaseDisabled()">
              <div class="custom-checkbox checkbox-content-container">
                <label class="checkbox">
                  <input type="checkbox" [(ngModel)]="simpleTaskViewModel.isCheck">
                  <span class="checkmark"></span>
                </label>
              </div>
              </div>
            </td>
            <td class="type-column task-clickable" [ngClass]="{'even': i % 2 != 0}" (click)="viewDetails(simpleTaskViewModel)">
              <img class="type-image" [src]="getTaskStatusImagePath(simpleTaskViewModel)">
            </td>
            <td class="date-column" [ngClass]="{'even': i % 2 != 0}">
              {{simpleTaskViewModel.dueDate | momentFormat :'DD MMM HH:mm' : 'local' | lowercase}}
            </td>
            <td class="priority-column task-clickable " [ngClass]="{'even': i % 2 != 0}" (click)="viewDetails(simpleTaskViewModel)">
              {{ simpleTaskViewModel.priority }}
            </td>
            <td class="long-text-field-task-title" [ngClass]="{'even': i % 2 != 0}">
              <span>{{simpleTaskViewModel.title}}</span>
            </td>
            <td class="assign-column" [ngClass]="{'even': i % 2 != 0}">{{simpleTaskViewModel.assignInitials}}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
<ng-template #loadingTemplate>
  <div class="refreshing-message">
    {{"board-task-table-task-refreshing" | translate}}
  </div>
</ng-template>
