import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Subscription } from 'rxjs';
import { TaskModalCommon } from '@secca/shared/components/task-modal-common/task-modal-common.component';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { TaskType } from '@secca/shared/models/enums';
import { Task } from '@secca/shared/models/task';
import { Coverage } from '@secca/shared/models/coverage';
import { Case } from '@secca/shared/models/case';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import {SearchUserAndTeam} from "@secca/shared/models/searchUserAndTeam";
import {Moment} from "moment-timezone";

@Component({
  selector: 'app-task-supplier-invoice-left-panel',
  templateUrl: './task-supplier-invoice-left-panel.component.html',
  styleUrls: ['./task-supplier-invoice-left-panel.component.scss']
})
@AutoUnsubscribe
export class TaskSupplierInvoiceLeftPanelComponent extends TaskModalCommon implements OnInit {
  @Input() taskCase: string;
  @Input() taskId: string;

  @Output() stateChanged = new EventEmitter<void>();

  coverages: Coverage[];
  reserve: number;
  currency: string;

  $subscr2: Subscription;
  $teamsSubscr: Subscription;

  _constructor() {}

  ngOnInit() {
    this.initialiseTaskInformation(this.taskId || this.taskViewModel?.id);
    this.initialiseCaseInformation(this.case, this.taskCase || this.taskViewModel?.caseId?.toString());
    this.initialiseTeamHandler();
    this.initialiseCaseNumberValidated(this.caseId);
  }

  get caseId() {
    return this.case?.id || this.taskCase || this.taskViewModel?.caseId?.toString();
  }

  get validated(): boolean {
    return (
      this.task.title &&
      this.task.dueDate != null &&
      this.task.status != null &&
      (this.acHandler?.adtCode || this.acHandler?.team?.adtCode) &&
      this.isCaseNumberValidated
    );
  }

  get SubmitButtonTitle() {
    return 'task-modal-submit-save';
  }

  descriptionChange($event) {
    if (this.task.description !== $event) {
      this.setTaskHasChanged();
    }
    this.disableSaveButtonWhenNoChanges = false;
    this.task.description = $event;
  }

  isDigitalConsent() {
    return this.task.taskType === TaskType.consent;
  }

  viewDetailsConsent() {
    this.dialogViewerService.openTaskConsentDialog(this.taskViewModel, true);
  }

  private initialiseCaseInformation(seccaCase: Case, caseId: string) {
    if ( caseId ) {
      this.getCaseInformations(caseId);
    }
    else if ( seccaCase ) {
      this.getHeaderInformations(seccaCase);
      this.getCoverages(seccaCase.id);
    }
  }

  private initialiseTaskInformation(taskId: string) {
    if ( taskId ) {
      this.getTaskInformation(+taskId).subscribe();
    }
    else {
      this.task = new Task();
    }
  }

  private getCoverages(caseId: string) {
    this.planService.getCoveragesForCase(caseId).subscribe(
      coverages => {
        this.coverages = coverages;
        this.calculateCaseReserve();
      },
      error => console.log(error)
    );
  }

  private calculateCaseReserve() {
    if (this.coverages != null) {
      this.reserve = this.coverages.map(a => a.reserve).reduce((a, b) => a + b, 0);
    }
  }

  private initialiseCaseNumberValidated(caseId: string) {
    if ( caseId ) {
      this.$subscr2 = this.caseValidationService.isCaseNumberAssigned(caseId).subscribe(validated => {
        this.isCaseNumberValidated = validated;
      });
    }
  }

  private initialiseTeamHandler() {
    this.$teamsSubscr = this.dictionaryService.getAllActiveTeamsList().subscribe(result => {
      if (result != null) {
        this.teams = result.map(a => new DropdownDictionary(a.adtCode, a.teamName));
        if (this.task != null && this.task.adtCode != null) {
          this.initializeTeamAcHandler();
        }
      }
    });
  }

  private getCaseInformations(caseId: string) {
    this.caseService.getCase(caseId).subscribe(
      seccaCase => {
        this.case = seccaCase;
        this.getHeaderInformations(seccaCase);
        this.getCoverages(caseId);
      },
      error => console.log(error)
    );
  }

  caseHandlerUpdated(acHandler: SearchUserAndTeam) {
    this.caseHandlerChanged(acHandler);
    this.stateChanged.emit();
  }

  dueDateUpdated(date: Moment) {
    this.dueDateChanged(date);
    this.stateChanged.emit();
  }

  statusUpdated() {
    this.setTaskHasChanged();
    this.stateChanged.emit();
  }

  titleUpdated() {
    this.setTaskHasChanged();
    this.stateChanged.emit();
  }
}
