import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CaseService } from '@secca/core/services/case.service';
import { TaskStateService } from '@secca/board/components/board-task/services/task-state.service';
import { DictionaryService } from '@secca/core/services/dictionary.service';
import { ShortcutEnum, SortOrder, TaskType, TaskTypeEnumIcon } from '@secca/shared/models/enums';
import { TableSort } from '@secca/shared/models/table-sort';
import { UserDto } from '@secca/shared/models/userDto';
import { IncomingCasesTaskViewModel } from '@secca/board/components/board-task/models/incoming-cases-task-view-model';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import { DialogViewerService } from '@secca/core/services/dialog-viewer.service';
import { LockContextEnum } from '@secca/shared/enums/lock-context.enum';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { AbstractGlobalBoardTableShortcutsComponent } from '@secca/shared/components/abstract-global-board-table-shortcuts.component';
import { TaskViewModel } from '@secca/board/components/board-task/models/task-view.model';
import { TaskModalModeEnum } from '@secca/case/components/case-task/task-modal-static/task-modal-static-mode.enum';
import { SettingsService } from '@secca/core/services/settings.service';
import { BoardNavigationStateService } from '../services/board-navigation-state.service';

@Component({
  selector: 'app-board-task-incoming-cases-table',
  templateUrl: './board-task-incoming-cases-table.component.html',
  styleUrls: ['./board-task-incoming-cases-table.component.scss']
})
@AutoUnsubscribe
export class BoardTaskIncomingCasesTableComponent extends AbstractGlobalBoardTableShortcutsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() set taskViewModels(data: IncomingCasesTaskViewModel[]) {
    this._taskViewModels = data;
    this.numberOfRows = data?.length;
    setTimeout(() => {
      this.selectedRowIndex = this.boardNavigationStateService.getState('board-case-filter-request-web-task-selected-row-index') ? parseInt(this.boardNavigationStateService.getState('board-case-filter-request-web-task-selected-row-index'), 10) : 0;
      this.inputScrollPosition = this.boardNavigationStateService.getState('board-case-filter-request-web-task-scroll-position') ? parseInt(this.boardNavigationStateService.getState('board-case-filter-request-web-task-scroll-position'), 10) : 0;
      this.navigateToTable();
    }, 50);
  }
  get taskViewModels(): IncomingCasesTaskViewModel[] {
    return this._taskViewModels;
  }

  @Input() taskSorting: TableSort;
  @Output() sortingAction = new EventEmitter<TableSort>();

  isCheckAll = false;
  sortedOn: any = { receivedDate: true };
  sortOrder: any = {};
  loggedInUser: UserDto;
  loggedInUserSubscription: Subscription;
  inputScrollPosition = 0;

  private _taskViewModels: IncomingCasesTaskViewModel[];

  constructor(private caseService: CaseService,
              private router: Router,
              private taskStateService: TaskStateService,
              private dictionaryService: DictionaryService,
              private dialogViewerService: DialogViewerService,
              private caseLockHelperService: CaseLockHelperService,
              public settingsService: SettingsService,
              private boardNavigationStateService: BoardNavigationStateService
              ) {
    super('taskIncomingCasesTabel');
    this.loggedInUserSubscription = dictionaryService.getLoggedInUser()
      .subscribe(user => {
        this.loggedInUser = user;
      });
    this.shortcutSubscriptions.push(
      this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateTableRowCtrlEnter }).subscribe(a => {
        if (typeof this.selectedRowIndex === 'number') {
          this.viewTask(this.taskViewModels[this.selectedRowIndex] as any);
        }
      }),
      this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateTableRowAltEnter }).subscribe(a => {
        if (typeof this.selectedRowIndex === 'number') {
          if (this.taskViewModels[this.selectedRowIndex].caseValidated) {
            this.redirectToCase(this.taskViewModels[this.selectedRowIndex].caseId);
          }
        }
      }),
    );
  }

  ngOnInit() {
    this.inputScrollPosition = this.boardNavigationStateService.getState('board-case-filter-request-web-task-scroll-position') ? parseInt(this.boardNavigationStateService.getState('board-case-filter-request-web-task-scroll-position'), 10) : 0;
    this.selectedRowIndex = this.boardNavigationStateService.getState('board-case-filter-request-web-task-selected-row-index') ? parseInt(this.boardNavigationStateService.getState('board-case-filter-request-web-task-selected-row-index'), 10) : 0;
  }

  ngOnChanges() {
    setTimeout(() => this.setScrollPosition(), 500);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.boardNavigationStateService.saveState('board-case-filter-request-web-task-scroll-position', JSON.stringify(this.inputScrollPosition));
    this.boardNavigationStateService.saveState('board-case-filter-request-web-task-selected-row-index', JSON.stringify(this.selectedRowIndex));
  }

  setScrollPosition() {
    if (this.inputScrollPosition !== null && document.getElementById('taskIncomingCasesTabel')) {
      document.getElementById('taskIncomingCasesTabel').scrollTop = this.inputScrollPosition;
    }
  }

  onCaseTabelScroll() {
    this.inputScrollPosition = document.getElementById('taskIncomingCasesTabel').scrollTop;
  }

  checkAllTasks() {
    this.isCheckAll = !this.isCheckAll;
    this.taskViewModels.forEach(a => (a.isCheck = this.isCheckAll));
  }

  sortBy(column: string) {
    this.sortedOn = {};
    this.sortedOn[column] = true;
    if (this.sortOrder[column] == null) {
      this.sortOrder[column] = SortOrder.asc;
    } else {
      if (this.sortOrder[column] === SortOrder.asc) {
        this.sortOrder[column] = SortOrder.desc;
      } else {
        this.sortOrder[column] = SortOrder.asc;
      }
    }
    this.sortingAction.emit(new TableSort({ sortBy: column, sortOrder: this.sortOrder[column] }));
  }

  sortedBy(column: string): string {
    return this.sortedOn[column] ? 'sorted-by' : '';
  }

  getTaskTypeImagePath(taskViewModel: IncomingCasesTaskViewModel): string {
    return TaskTypeEnumIcon.getIcon(taskViewModel.taskType, taskViewModel.status);
  }

  getBrandImagesUrlForProfile(profileId: number) {
    return this.caseService.getBrandImagesUrlForProfile(profileId.toString());
  }

  viewTask(taskViewModel: TaskViewModel) {
    this.caseService.getCase(taskViewModel.caseId.toString()).subscribe(seccaCase => {
      this.caseLockHelperService.updateCaseLocks(seccaCase.id, seccaCase.caseLocks);

      const isCaseAlreadyLockedByTheLoggedInUser = this.caseLockHelperService.isCaseLockedByTheLoggedInUser(LockContextEnum.CASE_BASIC);
      if (!isCaseAlreadyLockedByTheLoggedInUser && !this.caseLockHelperService.isCaseBasicLockTaken) {
        // If not already locked by the loggedin user or others, we try to take the lock
        this.caseLockHelperService.lockCase(LockContextEnum.CASE_BASIC).subscribe();
      }

      this.openTaskModal(taskViewModel);
    });
  }

  private openTaskModal(taskViewModel: TaskViewModel) {
    if (taskViewModel.taskType === TaskType.external) {
      this.dialogViewerService.openTaskExternalDialog(taskViewModel, true);
    } else if (taskViewModel.taskType === TaskType.process) {
        this.dialogViewerService.openTaskDialog(taskViewModel, TaskModalModeEnum.partialViewOnly);
    }
  }

  redirectToCase(caseId: number) {
    this.router.navigate(['case', caseId]);
  }
}
