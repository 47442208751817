export enum CaseEconomyTabEnum {
    OVERVIEW,
    SERVICE_ORDERS,
    SUPPLIER_INVOICES,
    RECOVERY,
    RECOVERY_PAYMENT,
    SOS_SERVICES,
    CUSTOMER_INVOICES,
    PURCHASE_AND_SALES
}
