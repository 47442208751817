import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
import { SavingTypedInField } from './enums';
import {BalanceSheetEntryReduction} from "@secca/case/components/case-plans/case-plans/add-service-order/models/interfaces";
import * as moment from 'moment/moment';
import { DateSerializer } from '@secca/shared/models/date-serializer';

export class RefundReduction implements BalanceSheetEntryReduction{
  id: number;
  lineNumber: number;
  reductionTypeCode: string;
  amount: number;
  percentage: number;
  typedInField: SavingTypedInField;
  totalAmount: number;
  exchangedAmount: number;
  modifiedBy: string;
  modifiedOn: moment.Moment;
  modifiedUserName: string;

  public constructor(init?: Partial<RefundReduction>) {
    Object.assign(this, init);
  }

  getAmount(): number {
    return this.exchangedAmount;
  }

  getGross(): boolean {
    return false;
  }

  getId(): number {
    return this.id;
  }
}

@Injectable({
    providedIn: 'root'
  })
  export class RefundReductionAdapter implements Adapter<RefundReduction> {
    adapt(item: any): RefundReduction {
      return new RefundReduction({
        id: item.id,
        lineNumber: item.lineNumber,
        reductionTypeCode: item.reductionTypeCode,
        amount: item.amount,
        percentage: item.percentage,
        typedInField: item.typedInField,
        totalAmount: item.totalAmount,
        exchangedAmount: !!item.exchangedAmount ? item.exchangedAmount : 100,
        modifiedBy: item.modifiedBy,
        modifiedOn: item.modifiedOn ? DateSerializer.deserialize(item.modifiedOn) : null,
        modifiedUserName: item.modifiedUserName
        });
    }
}
