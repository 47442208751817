import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MatLegacyDialogState as MatDialogState } from '@angular/material/legacy-dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogBoundryService } from '@secca/core/services/dialog-boundry.service';
import { DialogMinimizedData, DialogMinimizedStorageService } from '@secca/core/services/dialog-minimized-storage.service';
import { AutoUnsubscribe } from '../decorators/auto-unsubscribe';
import { IKeyboardEnabled } from '../interfaces/keyboard-enabled';
import { IMinimizable } from '../interfaces/minimizable';

  const ESCAPE_KEY      = 'Escape';
  const MINIMIZE_KEY    = 'm';
  const ARROW_UP_KEY    = 'ArrowUp';
  const ARROW_DOWN_KEY  = 'ArrowDown';
  const ARROW_LEFT_KEY  = 'ArrowLeft';
  const ARROW_RIGHT_KEY = 'ArrowRight';
@Directive({
  selector: "[appDialogKeyboardEnabled]",
})
@AutoUnsubscribe
export class DialogKeyboardEnabledDirective implements OnInit {
  readonly MOVE_DIST_X     = 100;
  readonly MOVE_DIST_Y     = 100;

  public static readonly ESCAPE_KEY      = ESCAPE_KEY;
  public static readonly MINIMIZE_KEY    = 'm';
  public static readonly ARROW_UP_KEY    = 'ArrowUp';
  public static readonly ARROW_DOWN_KEY  = 'ArrowDown';
  public static readonly ARROW_LEFT_KEY  = 'ArrowLeft';
  public static readonly ARROW_RIGHT_KEY = 'ArrowRight';

  public static readonly ARROW_KEYS = [ARROW_UP_KEY, ARROW_DOWN_KEY, ARROW_LEFT_KEY, ARROW_RIGHT_KEY];

  dialogId: string;
  dialogRef: MatDialogRef<any>;
  _containerElement: any;

  @Input()
  enabledKeys: string[];

  constructor(private elmRef: ElementRef,
              private matDialog: MatDialog,
              private ngbModal: NgbModal,
              private dialogMinimizedStorageService: DialogMinimizedStorageService,
              private dialogBoundryService: DialogBoundryService) {
  }

  ngOnInit() {
    this.findDialog();
  }

  @HostListener('window:keydown.escape', ['$event'])
  private keyHandleCloseEvent(event: KeyboardEvent) {
    const dialogRef: MatDialogRef<any> = this.matDialog.getDialogById(this.dialogId);
    if ( dialogRef && dialogRef.getState() === MatDialogState.OPEN ) {
      if ( !this.isMinimized() && !this.hasOpenModals() && this.onTop() ) {
        if ( this.isKeyEnabled(ESCAPE_KEY) ) {
          const component: IKeyboardEnabled = dialogRef.componentInstance;
          if ( component?.keyboardClose ) {
            component?.keyboardClose();
          }
        }
        this.stopEventPropagation(event);
      }
    }
  }

  @HostListener('window:keydown.control.m', ['$event'])
  private keyHandleMinimizeEvent(event: KeyboardEvent) {
    const dialogRef: MatDialogRef<any> = this.matDialog.getDialogById(this.dialogId);
    if ( dialogRef && dialogRef.getState() === MatDialogState.OPEN ) {      
      if ( !this.isMinimized() && !this.hasOpenModals() && this.onTop() ) {
        if ( this.isKeyEnabled(MINIMIZE_KEY) ) {
          const component: IMinimizable = dialogRef.componentInstance;
          if ( component?.minimize ) {
            component?.minimize();
          }
        }
        this.stopEventPropagation(event);
      }
    }
  }

  @HostListener('window:keydown.control.arrowUp', ['$event'])
  @HostListener('window:keydown.control.arrowDown', ['$event'])
  @HostListener('window:keydown.control.arrowRight', ['$event'])
  @HostListener('window:keydown.control.arrowLeft', ['$event'])
  private keyHandleMoveEvent(event: KeyboardEvent) {
    const dialogRef: MatDialogRef<any> = this.matDialog.getDialogById(this.dialogId);
    if ( dialogRef && dialogRef.getState() === MatDialogState.OPEN ) {
      if ( !this.isMinimized() && !this.hasOpenModals() && this.onTop() ) {
        if ( this.isOneOfKeysEnabled(DialogKeyboardEnabledDirective.ARROW_KEYS) ) {    
          const pos = this.getPosition();

          if ( event.key === ARROW_UP_KEY ) {
            pos.y -= this.MOVE_DIST_Y;
          }
          else if ( event.key === ARROW_DOWN_KEY ) {
            pos.y += this.MOVE_DIST_Y;
          }
          else if ( event.key === ARROW_RIGHT_KEY ) {
            pos.x += this.MOVE_DIST_X;
          }
          else if ( event.key === ARROW_LEFT_KEY ) {
            pos.x -= this.MOVE_DIST_X;
          }

          if ( !this.dialogBoundryService.isPositionOutsideBoundry(this.elmRef.nativeElement, pos, this.containerElement) ) {
            const dialogPosition = this.dialogBoundryService.getDialogPosition(this.elmRef.nativeElement, pos, this.containerElement);
            this.dialogRef.updatePosition(dialogPosition);
          }
        }
        this.stopEventPropagation(event);
      }
    }
  }

  private onTop(): boolean {
    const overlayElement = this.findParentElementByClass(this.elmRef.nativeElement, 'cdk-global-overlay-wrapper');
    return overlayElement?.classList?.contains('dialog-to-front');
  }

  private isMinimized(): boolean {
    const minimized: DialogMinimizedData = this.dialogMinimizedStorageService.getMinimizedFromDialogId(this.dialogId);
    return !!minimized;
  }

  private hasOpenModals(): boolean {
    return this.ngbModal.hasOpenModals();
  }

  private findDialog(): void {
    const containerElement = this.containerElement;
    if ( containerElement ) {
      this.dialogId = containerElement.id;
      this.dialogRef = this.matDialog.getDialogById(this.dialogId);
    }
  }

  private getPosition(): any {
    return this.getOffset(this.containerElement);
  }

  private getOffset(el): any {
    const rect = el.getBoundingClientRect();
    return {
      x: rect.left + window.scrollX,
      y: rect.top + window.scrollY
    };
  }

  private stopEventPropagation(event: KeyboardEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
  }

  private isKeyEnabled(key: string): boolean {
    return !this.enabledKeys || this.enabledKeys.includes(key);
  }

  private isOneOfKeysEnabled(keys: string[]): boolean {
    return !this.enabledKeys || !!this.enabledKeys.find(key => keys.includes(key));
  }

  private findParentElementByClass(element, componentClass) {
    for (let i = 0; i < 10; i++) {
        if ( !element ) {
          break;
        }

        if (element?.classList?.contains(componentClass)) {
            return element;
        } 

        element = element.parentNode;
    }

    return null;
  }

  private get containerElement(): any {
    if ( !this._containerElement ) {
      this._containerElement = this.findParentElementByClass(this.elmRef.nativeElement, 'mat-dialog-container');
    }
    return this._containerElement;
  }
}
