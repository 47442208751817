import { SettingsService } from './settings.service';
import { HttpHeaders } from '@angular/common/http';

export class BaseService {
  apiURL = 'api/';
  baseURL: string;
  jsonHeaders = new HttpHeaders({ 'Content-Type': 'application/JSON' });
  caseManagementSystemURL: string;

  constructor(private settingService: SettingsService) {
    this.baseURL = this.settingService.getBffEndpoint() + this.apiURL;
    this.caseManagementSystemURL = this.settingService.getCaseManagementSystemURL();
  }
}
