import { ServiceTypeBase } from './../../models/serviceTypeBase';
import { Component, Input } from '@angular/core';
import { ServiceTypeComponent } from './../../models/interfaces';
import { MoneyTransferServiceOrderExtension } from '@secca/shared/models/service-order/money-transfer-service-order-extension';

@Component({
  selector: 'app-money-transfer',
  templateUrl: './money-transfer.component.html',
})
export class MoneyTransferComponent extends ServiceTypeBase implements ServiceTypeComponent {
  @Input() moneyTransfer: MoneyTransferServiceOrderExtension;

  constructor() {
    super();
  }

  startDateChange(date) {
    this.moneyTransfer.startDate = date;
  }

  isValid(): boolean {
   return this.moneyTransfer.isValid();
  }
  getModel() {
    return this.moneyTransfer;
  }
}