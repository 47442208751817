import { ServiceTypeComponent } from './../../models/interfaces';
import { ServiceTypeBase } from './../../models/serviceTypeBase';
import { Component, Input, OnInit } from '@angular/core';
import { AdviceOnInsuranceTermsServiceOrderExtension } from '@secca/shared/models/service-order/advice-on-insurance-terms';

@Component({
  selector: 'app-advise-on-insurance-terms',
  templateUrl: './advise-on-insurance-terms.component.html',
})
export class AdviseOnInsuranceTermsComponent extends ServiceTypeBase implements OnInit, ServiceTypeComponent {
  @Input() advice: AdviceOnInsuranceTermsServiceOrderExtension;

  constructor() {
    super();
  }

  ngOnInit() {}

  startDateChange(date) {
    this.advice.startDate = date;
  }

  isValid(): boolean {
   return this.advice.isValid();
  }
  getModel() {
    return this.advice;
  }
}
