<div class="subject-container" *ngIf="message">
  <div class="content-container" *ngIf="message.messageChannelType === MessageChannelType.FAX || message.messageChannelType === MessageChannelType.EMAIL">
    <div class="container-fluid meta-box">
      <div class="row spacer">
        <div class="col-1">
          <span class="text subject-label"> {{ 'message-form-subject' | translate }} </span>
        </div>
        <div class="col-11 left-space">
          <div class="content-container">
            <div *ngIf="!message.replyEmailId" class="input-case-number" type="text">{{message.caseNumber}}</div>
          <input class="input-subject" [class.input-subject-reply]="message.replyEmailId" type="text" [(ngModel)]="message.subject">
        </div>
        </div>
      </div>
      <div class="row spacer">
        <div class="col-1">
          <span class="text cc-label">{{ 'message-form-cc' | translate }}</span>
        </div>
        <div class="col-11 left-space">
          <app-message-form-cc [stakeholders]="stakeholders" [(selectedItems)]="message.cc"
            [messageChannelType]="message.messageChannelType" (selectedItemsChange)=(updateMessage()) showFullName="true"></app-message-form-cc>
        </div>
      </div>
      <div class="row spacer">
        <div class="col-1">
          <span class="text cc-label">{{ 'message-form-attachments' | translate }}</span>
        </div>
        <div class="col-11 left-space">
            <app-message-form-attachments [attachments]="attachments"
              [(selectedItems)]="message.attachments"
              (selectedItemsChange)=(updateMessage())></app-message-form-attachments>
        </div>
      </div>
      <div class="row-separator"></div>
    </div>
  </div>
  <div class="content-container" *ngIf="message.messageChannelType === MessageChannelType.SMS">
    <div class="container-fluid">
      <div class="row spacer">
        <div class="col-1">
          <span class="text cc-label">{{ 'message-form-cc' | translate }}</span>
        </div>
        <div class="col-11">
          <app-message-form-cc [stakeholders]="stakeholders" [(selectedItems)]="message.cc"
            [messageChannelType]="message.messageChannelType" (selectedItemsChange)=(updateMessage()) showFullName="true"></app-message-form-cc>
        </div>
      </div>
    </div>

  </div>
</div>
