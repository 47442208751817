import { Adapter } from '../interfaces/adapter';

export class ResetFinanceBridgeStatustDto {
    salesOrderId: Array<String>;
    public constructor(init?: Partial<ResetFinanceBridgeStatustDto>) {
    Object.assign(this, init);
  }
}

export class ResetFinanceBridgeStatustDtoAdapter implements Adapter<ResetFinanceBridgeStatustDto> {
 adapt(item: any): ResetFinanceBridgeStatustDto {
     return new ResetFinanceBridgeStatustDto({
       salesOrderId: item.salesOrderId, 
     });
 }   
}