<div class="case-info" *ngIf="caseId">
  <div [class.hidden]="!isCaseValidated(+caseId)">
    <div>{{caseNumber}}</div>
    <div>{{createdLocalDate ? (createdLocalDate | date: 'd MMM y - HH:mm') : ''}}</div>
  </div>
  <div class="d-flex center">
    <button class="case-type" [ngClass]="'casetype-style-' + caseTypeCode" [disabled]="isDisabled" (click)="modal.open()">
      <div [disablePopover]="!isDisabled" triggers="hover" [ngbPopover]="'case-info-case-type-disabled-text' | translate">
        {{ caseTypeTranslated ? caseTypeTranslated : ('CASE_TYPE_AWAITING_CASE_TYPE' | translate)}}
      </div>
    </button>
    <div class="case-type-reset" *ngIf="caseTypeManuallySet" (click)="openConfirmDialog()">
      <img [src]="caseTypeResetImage()" [ngbPopover]="caseTypeResetPopOverText()"          
           placement="right-top"
           triggers="hover">
    </div>
  </div>
</div>
<app-case-type-modal (caseTypeChanged)="caseTypeChanged()" [caseValidated]="isCaseValidated(+caseId)" [caseTypeTranslated]="caseTypeTranslated" [caseId]="caseId" [caseTypeCode]="caseTypeCode" #modal ></app-case-type-modal>
