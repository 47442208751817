<div class="sub-component-container" *ngIf="subLuggageReference">
  <div class="sub-title">{{"sub-luggage-reference-title" | translate}}</div>
  <div class="sub-content-container">
    <div class="row">
      <div class="col-6">
        <app-input [name]="'sub-luggage-reference-pir-number' | translate" [maxTextLength]="100"
                        [(value)]="subLuggageReference.pirNumber"></app-input>
      </div>
      <div class="col-6">
        <app-input [name]="'sub-luggage-reference-police-report-reference' | translate" [maxTextLength]="100"
                        [(value)]="subLuggageReference.policeReportReference"></app-input>
      </div>
    </div>
  </div>
</div>
