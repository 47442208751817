import {Injectable} from '@angular/core';
import { Adapter } from 'src/app/shared/interfaces/adapter';
import { StakeholderTypeEnum } from 'src/app/shared/models/enums';

export class StakeholderType {

  stakeholderTypeEnum: StakeholderTypeEnum;
  stakeholderTypeName: string;
  providerTypeId: number;
  groupName: string;
  iconName: string;
  id: number;

  public constructor(init?: Partial<StakeholderType>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})

export class StakeholderTypeAdapter implements Adapter<StakeholderType> {

  public adapt(item: any): StakeholderType {

    return new StakeholderType({
      stakeholderTypeEnum: item.stakeholderTypeEnum,
      stakeholderTypeName: item.stakeholderTypeName,
      providerTypeId : item.providerTypeId,
      groupName: item.groupName,
      iconName: item.iconName,
      id: item.id
    });
  }
}
