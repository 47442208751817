import { CommitmentTypes, ServiceTypeEnum } from '@secca/shared/models/enums';
import { CountryCode } from '@secca/shared/enums/countryCode';

export class ServiceTypeCommitmentTypeMapping {
  private static MAPPING: { [key in ServiceTypeEnum]: CommitmentTypes[] } = {
    ACCOMMODATION: [
      CommitmentTypes.GUARANTEE_OF_PAYMENT,
      CommitmentTypes.BOOKING,
      CommitmentTypes.PAY_AND_CLAIM_SOS_NO_POLICY_LOOKUP_NO_ADVICE_ON_INSURANCE_TERMS,
      CommitmentTypes.PAY_AND_CLAIM_CUSTOMER_NO_POLICY_LOOKUP_NO_ADVICE_ON_INSURANCE_TERMS,
      CommitmentTypes.PAY_AND_CLAIM_SOS_POLICY_LOOKUP_DONE_ADVICE_ON_INSURANCE_TERMS_GIVEN,
      CommitmentTypes.PAY_AND_CLAIM_CUSTOMER_POLICY_LOOKUP_DONE_ADVICE_ON_INSURANCE_TERMS_GIVEN
    ],
    ADVISE_ON_INSURANCE_TERMS: [],
    AGENT: [],
    AMBULANCE_FLIGHT: [
      CommitmentTypes.BOOKING,
      CommitmentTypes.PAY_AND_CLAIM_SOS_NO_POLICY_LOOKUP_NO_ADVICE_ON_INSURANCE_TERMS,
      CommitmentTypes.PAY_AND_CLAIM_CUSTOMER_NO_POLICY_LOOKUP_NO_ADVICE_ON_INSURANCE_TERMS,
      CommitmentTypes.PAY_AND_CLAIM_SOS_POLICY_LOOKUP_DONE_ADVICE_ON_INSURANCE_TERMS_GIVEN,
      CommitmentTypes.PAY_AND_CLAIM_CUSTOMER_POLICY_LOOKUP_DONE_ADVICE_ON_INSURANCE_TERMS_GIVEN
    ],
    ASSISTANCE_OR_CONTACT: [],
    GENERAL_ADVICE: [],
    GROUND_AMBULANCE: [
      CommitmentTypes.GUARANTEE_OF_PAYMENT,
      CommitmentTypes.PAY_AND_CLAIM_SOS_NO_POLICY_LOOKUP_NO_ADVICE_ON_INSURANCE_TERMS,
      CommitmentTypes.PAY_AND_CLAIM_CUSTOMER_NO_POLICY_LOOKUP_NO_ADVICE_ON_INSURANCE_TERMS,
      CommitmentTypes.PAY_AND_CLAIM_SOS_POLICY_LOOKUP_DONE_ADVICE_ON_INSURANCE_TERMS_GIVEN,
      CommitmentTypes.PAY_AND_CLAIM_CUSTOMER_POLICY_LOOKUP_DONE_ADVICE_ON_INSURANCE_TERMS_GIVEN
    ],
    GROUND_TRANSPORT: [
      CommitmentTypes.BOOKING,
      CommitmentTypes.PAY_AND_CLAIM_SOS_NO_POLICY_LOOKUP_NO_ADVICE_ON_INSURANCE_TERMS,
      CommitmentTypes.PAY_AND_CLAIM_CUSTOMER_NO_POLICY_LOOKUP_NO_ADVICE_ON_INSURANCE_TERMS,
      CommitmentTypes.PAY_AND_CLAIM_SOS_POLICY_LOOKUP_DONE_ADVICE_ON_INSURANCE_TERMS_GIVEN,
      CommitmentTypes.PAY_AND_CLAIM_CUSTOMER_POLICY_LOOKUP_DONE_ADVICE_ON_INSURANCE_TERMS_GIVEN
    ],
    HANDLING_OF_MORTAL_REMAINS: [
      CommitmentTypes.GUARANTEE_OF_PAYMENT,
      CommitmentTypes.PAY_AND_CLAIM_SOS_NO_POLICY_LOOKUP_NO_ADVICE_ON_INSURANCE_TERMS,
      CommitmentTypes.PAY_AND_CLAIM_CUSTOMER_NO_POLICY_LOOKUP_NO_ADVICE_ON_INSURANCE_TERMS,
      CommitmentTypes.PAY_AND_CLAIM_SOS_POLICY_LOOKUP_DONE_ADVICE_ON_INSURANCE_TERMS_GIVEN,
      CommitmentTypes.PAY_AND_CLAIM_CUSTOMER_POLICY_LOOKUP_DONE_ADVICE_ON_INSURANCE_TERMS_GIVEN
    ],
    LUGGAGE: [
      CommitmentTypes.PAY_AND_CLAIM_SOS_NO_POLICY_LOOKUP_NO_ADVICE_ON_INSURANCE_TERMS,
      CommitmentTypes.PAY_AND_CLAIM_CUSTOMER_NO_POLICY_LOOKUP_NO_ADVICE_ON_INSURANCE_TERMS,
      CommitmentTypes.PAY_AND_CLAIM_SOS_POLICY_LOOKUP_DONE_ADVICE_ON_INSURANCE_TERMS_GIVEN,
      CommitmentTypes.PAY_AND_CLAIM_CUSTOMER_POLICY_LOOKUP_DONE_ADVICE_ON_INSURANCE_TERMS_GIVEN
    ],
    MEDICAL_ASSESSMENT: [],
    MEDICAL_PRE_ASSESSMENT: [],
    MEDICAL_ESCORT: [
      CommitmentTypes.CRISIS_NURSE,
      CommitmentTypes.CRISIS_PSYCHOLOGIST,
      CommitmentTypes.ESCORT_DOCTOR,
      CommitmentTypes.ESCORT_NURSE,
      CommitmentTypes.NON_MEDICAL_ESCORT,
    ],
    MONEY_TRANSFER: [],
    OTHER_REFUND_REQUEST: [
      CommitmentTypes.PAY_AND_CLAIM_SOS
    ],
    REFERRAL_TO_CUSTOMER: [],
    REGULAR_FLIGHT_MEDICAL: [
      CommitmentTypes.BOOKING,
      CommitmentTypes.PAY_AND_CLAIM_SOS_NO_POLICY_LOOKUP_NO_ADVICE_ON_INSURANCE_TERMS,
      CommitmentTypes.PAY_AND_CLAIM_CUSTOMER_NO_POLICY_LOOKUP_NO_ADVICE_ON_INSURANCE_TERMS,
      CommitmentTypes.PAY_AND_CLAIM_SOS_POLICY_LOOKUP_DONE_ADVICE_ON_INSURANCE_TERMS_GIVEN,
      CommitmentTypes.PAY_AND_CLAIM_CUSTOMER_POLICY_LOOKUP_DONE_ADVICE_ON_INSURANCE_TERMS_GIVEN
    ],
    REGULAR_FLIGHT_NON_MEDICAL: [
      CommitmentTypes.BOOKING,
      CommitmentTypes.PAY_AND_CLAIM_SOS_NO_POLICY_LOOKUP_NO_ADVICE_ON_INSURANCE_TERMS,
      CommitmentTypes.PAY_AND_CLAIM_CUSTOMER_NO_POLICY_LOOKUP_NO_ADVICE_ON_INSURANCE_TERMS,
      CommitmentTypes.PAY_AND_CLAIM_SOS_POLICY_LOOKUP_DONE_ADVICE_ON_INSURANCE_TERMS_GIVEN,
      CommitmentTypes.PAY_AND_CLAIM_CUSTOMER_POLICY_LOOKUP_DONE_ADVICE_ON_INSURANCE_TERMS_GIVEN
    ],
    TREATMENT: [
      CommitmentTypes.GUARANTEE_OF_PAYMENT,
      CommitmentTypes.EHIC_FULL_COVERAGE,
      CommitmentTypes.EHIC_PARTIAL_GOP,
      CommitmentTypes.VOB_VERIFICATION_OF_BENEFITS,
      CommitmentTypes.PAY_AND_CLAIM_SOS_NO_POLICY_LOOKUP_NO_ADVICE_ON_INSURANCE_TERMS,
      CommitmentTypes.PAY_AND_CLAIM_CUSTOMER_NO_POLICY_LOOKUP_NO_ADVICE_ON_INSURANCE_TERMS,
      CommitmentTypes.PAY_AND_CLAIM_SOS_POLICY_LOOKUP_DONE_ADVICE_ON_INSURANCE_TERMS_GIVEN,
      CommitmentTypes.PAY_AND_CLAIM_CUSTOMER_POLICY_LOOKUP_DONE_ADVICE_ON_INSURANCE_TERMS_GIVEN
    ],
    ADVICE_CALL_REGISTRATION: [
      CommitmentTypes.POLICY_LOOKUP_DONE_ADVICE_ON_INSURANCE_TERMS_GIVEN,
      CommitmentTypes.NO_POLICY_LOOKUP_NO_ADVICE_ON_INSURANCE_TERMS,
      CommitmentTypes.NO_POLICY_LOOKUP_REFERAL_TO_CUSTOMER,
      CommitmentTypes.NOT_TRAVEL_SOS_RELATED
    ],
    INVESTIGATION: [
      CommitmentTypes.POLICY_LOOKUP_DONE_ADVICE_ON_INSURANCE_TERMS_GIVEN,
      CommitmentTypes.NO_POLICY_LOOKUP_NO_ADVICE_ON_INSURANCE_TERMS,
      CommitmentTypes.NO_POLICY_LOOKUP_REFERAL_TO_CUSTOMER,
      CommitmentTypes.NOT_TRAVEL_SOS_RELATED
    ],
    TRANSLATION: [
      CommitmentTypes.POLICY_LOOKUP_DONE_ADVICE_ON_INSURANCE_TERMS_GIVEN,
      CommitmentTypes.NO_POLICY_LOOKUP_NO_ADVICE_ON_INSURANCE_TERMS,
      CommitmentTypes.NO_POLICY_LOOKUP_REFERAL_TO_CUSTOMER,
      CommitmentTypes.NOT_TRAVEL_SOS_RELATED
    ],
    PRE_DEPARTURE_TRAVEL_SERVICE: [],
    COORDINATION_TRANSPORT: [
      CommitmentTypes.BOOKING,
    ],
    MAJOR_INCIDENT: [
      CommitmentTypes.BOOKING,
    ],
    MEDICAL_FLIGHT_AUTHORIZATION: []
  };

  public static getListByServiceType(type: ServiceTypeEnum): CommitmentTypes[] {
    return ServiceTypeCommitmentTypeMapping.MAPPING[type];
  }
}

export class TeamCountryCode {
  private static MAPPING: { [key in string]: CountryCode} = {
    ADT02: CountryCode.SE,
    ADT03: CountryCode.NO,
    ADT04: CountryCode.DK,
    ADT06: CountryCode.FI,
    ADT07: CountryCode.NO,
    ADT08: CountryCode.SE,
    ADT09: CountryCode.SE,
    ADT34: CountryCode.SE,
    ADT35: CountryCode.SE,
  };

  public static getCountryCodeByAdtCode(adtCode: string): CountryCode {
    return TeamCountryCode.MAPPING[adtCode];
  }
}
