import { target } from 'on-change';
import { MedicalPreAssessmentAdapter, MedicalPreAssessmentQuestionnaire } from './../../shared/models/medicalPreAssessment';
import { MedicalPreAssessmentDecisionEnum, MedicalPreAssessmentRelevantEnum, MedicalPreAssessmentTypeEnum } from './../../shared/models/enums';
import { Injectable } from "@angular/core";
import { MedicalPreAssessmentState } from "@secca/case/components/case-medical/medical-components/medical-models";
import { MedicalAssessmentBasedOnEnum } from "@secca/shared/models/enums";
import { MedicalPreAssessment } from "@secca/shared/models/medicalPreAssessment";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";

@Injectable()
export class MedicalPreAssessmentStateService {
    private subject: BehaviorSubject<MedicalPreAssessmentState> = new BehaviorSubject<MedicalPreAssessmentState>(new MedicalPreAssessmentState());
    private preAssessment: MedicalPreAssessment;
    public getLatestQuestionnarire: MedicalPreAssessmentQuestionnaire[];

    public constructor(private medicalPreAssessmentAdapter: MedicalPreAssessmentAdapter) {}

    public updatePreAssessment(values: MedicalPreAssessment) {
        this.subject.next(this.calculatePreAssessmentState(values));
    }

    public stateSubject(): BehaviorSubject<MedicalPreAssessmentState> {
        this.subject.next(new MedicalPreAssessmentState());
        return this.subject;
      }
    
    public calculatePreAssessmentState(values: MedicalPreAssessment): MedicalPreAssessmentState {
        this.preAssessment = this.medicalPreAssessmentAdapter.adapt(target(values));
        const state = new MedicalPreAssessmentState();
        state.preAssessment = true;
        this.assessmentTypeState(state);
        this.basisControl(state);
        this.controlsState(state);
        this.questionnaireState(values,state);
        this.notesControl(state);
        this.decisionControl(state);
        return state;
      }
  assessmentTypeState(state: MedicalPreAssessmentState) {
    state.typeValid = true;
    if (this.preAssessment?.medicalAssessmentType === undefined) {
      state.typeText = MedicalPreAssessmentTypeEnum.PREASSESSMENT_TRAVEL;
      return;
    }
    state.typeText = this.preAssessment?.medicalAssessmentType;
    
  }
    private basisControl(state: MedicalPreAssessmentState) {
        while (this.preAssessment.sourceList.length > 0) {
          const source = this.preAssessment.sourceList.pop();
    
          if (!!source.basedOn && (!!source.sourceName && !!source.sourceType)) {
            if (state.basisValid == null) {
              state.basisValid = true;
            }
          } else {
            state.basisValid = false;
          }

    
          if (!!source.basedOn && source.basedOn === MedicalAssessmentBasedOnEnum.MEDICAL_REPORT) {
            state.basisValid = (state.basisValid || false);
          }
        }
    }

    private controlsState(state: MedicalPreAssessmentState) {
        if (state.preAssessment === false) {
          state.preAssessmentValid = state.preAssessmentValid !== false &&
            state.basisValid !== false && 
            state.noteValid !== false;
        } else {
          state.preAssessmentValid = state.preAssessmentValid !== false &&
            state.basisValid !== false &&
            state.noteValid !== false;
        }
      }

      private questionnaireState(value: MedicalPreAssessment,state: MedicalPreAssessmentState) {
        if (this.preAssessment.questionnaires.length > 0) {
          this.preAssessment.questionnaires.forEach((questionnaire => {
            if ( questionnaire.questions?.filter(q => q.answer === undefined)?.length === 0 &&
                 this.checkNotNullDeep(questionnaire.diagnosisPreDeparture) || questionnaire.relevant === MedicalPreAssessmentRelevantEnum.NO ) {
                  state.questionnaireValid = true;
                }
                else {
                  state.questionnaireValid = false;
                }
          }))
       
      }
      }

      private checkNullAndUndefined(data:any){
        return data ?? undefined;
      }

      private notesControl(state: MedicalPreAssessmentState) {
        //this.preAssessment.note.webDiagnosis has to be added in the checkNotNullDeep once we load the control
        state.noteValid = this.preAssessment.note.canFinalizeAssessment !== undefined && this.preAssessment.note.evaluation !== undefined && this.preAssessment.note.evaluation !== null &&
        this.preAssessment.note.evaluation !=="";
      }

      private decisionControl(state: MedicalPreAssessmentState) {
        if (this.preAssessment.note.canFinalizeAssessment === false || 
          (this.preAssessment.decision == MedicalPreAssessmentDecisionEnum.PARTIALLY_COVERED || this.preAssessment.decision == MedicalPreAssessmentDecisionEnum.FULLY_COVERED) && 
          this.preAssessment.decisionDetail !== null && 
          (this.preAssessment.decisionDetail.evaluationDecision !== undefined && this.preAssessment.decisionDetail.evaluationDecision !=="") && 
          this.preAssessment.decisionDetail.templateId !== undefined && 
          this.preAssessment.decisionDetail.templateLanguage !== undefined ) {
          state.decisionValid = true;
        } else {
          state.decisionValid = false;
        }
      }

      private checkNotNullDeep(data: any[]): boolean {
        let result;
        data.every(item => {
          if (item == null || item === '') {
            result = false;
            return false;
          } else if ((result || result == null)) {
            if (item instanceof Array) {
              result = this.checkNotNullDeep(item);
            } else {
              result = true;
            }
            return true;
          }
        });
        return !!result && result;
      }
}