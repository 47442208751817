import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-alert-box',
  templateUrl: './alert-box.component.html',
  styleUrls: ['alert-box.component.scss']
})
export class AlertBoxComponent {
  @Input() title;
  @Input() type;
  showDetails = false;

  toggleDetails() {
      this.showDetails = !this.showDetails;
  }
}
