import { Component, Input, OnInit } from '@angular/core';
import { ServiceTypeBase } from '../../models/serviceTypeBase';
import { ServiceTypeComponent } from '../../models/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { MedicalPreAssessmentServiceOrderExtension } from '@secca/shared/models/service-order/medical-pre-assessment-service-order-extension';

@Component({
  selector: 'app-medical-pre-assessment',
  templateUrl: './medical-pre-assessment.component.html',
})
export class MedicalPreAssessmentComponent extends ServiceTypeBase implements ServiceTypeComponent, OnInit {
  @Input() medicalPreAssessment: MedicalPreAssessmentServiceOrderExtension;
  @Input() caseId: string;

  constructor(private translate: TranslateService) {
    super();
  }

  ngOnInit() {
    this.initialize();
  }

  isValid(): boolean {
    return this.medicalPreAssessment.isValid();
  }

  getModel() {
    return this.medicalPreAssessment;
  }

  initialize() {
  }

}
