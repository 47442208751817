<div class="sales-order-job-table" *ngIf="customerInvoiceJobs; else loadingTemplate">
  <div class="primary-table-container">
    <table class="primary-table">
      <thead>
        <tr class="fixed-header-table">
          <td (click)="sortBy('salesOrderId')" [ngClass]="sortedBy('salesOrderId')">{{ 'board-sales-order-sales-order' | translate }}</td>
          <td (click)="sortBy('salesOrderCreatedOn')" [ngClass]="sortedBy('salesOrderCreatedOn')">
            {{ 'board-sales-order-sales-order-created-on' | translate }}
          </td>
          <td (click)="sortBy('customerProfileName')" [ngClass]="sortedBy('customerProfileName')">
            {{ 'board-sales-order-customer' | translate }}
          </td>
          <td (click)="sortBy('caseNumber')" [ngClass]="sortedBy('caseNumber')">{{ 'board-sales-order-case-number' | translate }}</td>
          <td (click)="sortBy('debtorNumber')" [ngClass]="sortedBy('debtorNumber')">{{ 'board-sales-order-debtor' | translate }}</td>
          <td (click)="sortBy('erpClient')" [ngClass]="sortedBy('erpClient')">{{ 'board-sales-order-erp-client-column' | translate }}</td>
          <td (click)="sortBy('invoiceNo')" [ngClass]="sortedBy('invoiceNo')">{{ 'board-sales-order-invoice-number' | translate }}</td>
          <td class="amount-column">
            {{ 'board-sales-order-amount-excl-vat' | translate }}
          </td>
          <td class="amount-column">
            {{ 'board-sales-order-amount-incl-vat' | translate }}
          </td>
          <td (click)="sortBy('salesOrderStatus')" [ngClass]="sortedBy('salesOrderStatus')">
            {{ 'board-sales-order-status' | translate }}
          </td>
          <td (click)="sortBy('caseFinancialStatus')" class="case-financial-column" [ngClass]="sortedBy('caseFinancialStatus')">
            {{ 'board-sales-order-case-financial-status' | translate }}
          </td>
          <td (click)="sortBy('jobStatus')" [ngClass]="sortedBy('jobStatus')">{{ 'board-sales-order-invoice-job-status' | translate }}</td>
          <td (click)="sortBy('jobDate')" [ngClass]="sortedBy('jobDate')">{{ 'board-sales-order-last-job-date' | translate }}</td>
          <td (click)="sortBy('retryCount')" [ngClass]="sortedBy('retryCount')">
            {{ 'board-sales-order-retry-count' | translate }}
          </td>
          <td class="checkbox-column-header">
            <div class="custom-checkbox checkbox-header-container">
              <label class="checkbox">
                <input type="checkbox" (click)="checkAllTasks()" [(ngModel)]="isCheckAll">
                <span class="checkmark"></span>
              </label>
            </div>
          </td>
        </tr>
      </thead>
      <tbody (scroll)="onCaseTabelScroll()" id="tabelSalesOrders" [class.zoomed]="settingsService.isZoomed()">
        <ng-container *ngFor="let jobSalesOrder of customerInvoiceJobs; index as i">
          <tr class="fixed-header-table">
            <td [class.note-present-indicator]="!!jobSalesOrder.customerInvoiceNote">
              <div class="flex-fill" [ngbPopover]="jobSalesOrder.customerInvoiceNote" triggers="hover" placement="bottom-left">
                {{ jobSalesOrder.salesOrderId }}
              </div>
            </td>
            <td class="date-column">
              {{ jobSalesOrder.salesOrderCreatedOn | momentFormat: 'DD MMM HH:mm':'local' }}
            </td>
            <td class="customer-column">
              <img
                [src]="getBrandImagesUrlForProfile(jobSalesOrder.customerProfileId)"
                [ngbPopover]="jobSalesOrder.customerProfileName"
                placement="auto"
                triggers="hover"
              />
            </td>
            <td class="case-number-column" (click)="navigateToCase(jobSalesOrder.salesOrderId)">
              <span>{{ jobSalesOrder.caseNumber }}</span>
            </td>
            <td>{{ jobSalesOrder.debtorNumber }}</td>
            <td>{{ jobSalesOrder.erpClient }}</td>
            <td>{{ jobSalesOrder.invoiceNo }}</td>
            <td class="amount-column">
              <span class="currency-value">{{ jobSalesOrder.invoiceCurrency }}</span
              >{{ jobSalesOrder.amountExclVAT | number: '.2' }}
            </td>
            <td class="amount-column">
              <span class="currency-value">{{ jobSalesOrder.invoiceCurrency }}</span
              >{{ jobSalesOrder.amountInclVAT | number: '.2' }}
            </td>
            <td>
            <div class="flex-fill" [ngbPopover]="jobSalesOrder.acknowledgeMessage" triggers="hover" placement="bottom-left">{{ 'sales-order-status-' + jobSalesOrder.salesOrderStatus | translate }}</div>
          </td>
            <td>{{ 'case-economy-financial-status-' + jobSalesOrder.caseFinancialStatus | translate }}</td>
            <td>{{'customer-invoice-sales-order-status-' + jobSalesOrder.jobStatus | translate }}</td>
            <td class="date-column">
              <div class="d-flex" (click)="expandSalesOrder(i, jobSalesOrder)">
                <div class="flex-fill">
                  {{ jobSalesOrder.jobDate | momentFormat: 'DD MMM YYYY HH:mm':'local' }}
                </div>
                <div>
                  <img *ngIf="!expanded[i]" class="expand-icon" src="../../../../../assets/icons/ArrowDown.svg" />
                  <img *ngIf="expanded[i]" class="expand-icon" src="../../../../../assets/icons/ArrowDownBlue.svg" />
                </div>
              </div>
            </td>
            <td>
            <div class="flex-fill">
              {{ jobSalesOrder.retryCount }}
            </div>
          </td>
          <td class="checkbox-column">
            <div class="custom-checkbox checkbox-content-container">
              <label class="checkbox">
                <input type="checkbox" [(ngModel)]="jobSalesOrder.isCheck">
                <span class="checkmark"></span>
              </label>
            </div>
        </tr>
          <tr *ngIf="expanded[i]">
            <td class="expanded-td" colspan="13">
              <div>
                <table>
                  <tr *ngFor="let details of jobSalesOrder.details; index as j" class="fixed-header-table">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="amount-column">
                    <span class="currency-value">{{ details.invoiceCurrency }}</span
                    >{{ details.amountExclVAT | number: '.2' }}
                    </td>
                    <td></td>
                    <td></td>
                    <td>{{ details.caseFinancialStatus == null ? '' : ('case-economy-financial-status-' + details.caseFinancialStatus | translate) }}</td>
                    <td>{{ details.jobStatus == null ? '' : ('customer-invoice-sales-order-status-' + details.jobStatus | translate) }}</td>
                    <td>
                      {{ details.jobDate | momentFormat: 'DD MMM YYYY HH:mm':'local' }}
                    </td>
                  </tr>
                </table>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
<ng-template #loadingTemplate>
  <div class="refreshing-message">
    {{ 'board-task-supplier-invoice-table-refreshing' | translate }}
  </div>
</ng-template>
