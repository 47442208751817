import { ServiceTypeBase } from '../../models/serviceTypeBase';
import { Component, Input } from '@angular/core';
import { ServiceTypeComponent } from '../../models/interfaces';
import { TranslationServiceOrderExtension } from '@secca/shared/models/service-order/translation-service-order-extension';

@Component({
  selector: 'app-translation',
  templateUrl: './translation.component.html',
})
export class TranslationComponent extends ServiceTypeBase implements ServiceTypeComponent {
  @Input() translationCall: TranslationServiceOrderExtension;
  @Input() leftSideValid = false;
  @Input() caseId: string;


  constructor() {
    super();
  }

  startDateChange(date): void {
    this.translationCall.startDate = date;
  }

  isValid(): boolean {
    if (this.translationCall.isValid()){
      this.leftSideValid = true;
    }
    return this.translationCall.isValid();
  }

  getModel(): TranslationServiceOrderExtension {
    return this.translationCall;
  }

}
