import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EconomyProcessBarComponent } from '@secca/shared/components/economy-process-bar/economy-process-bar.component';
import { SharedModule } from '@secca/shared/shared.module';
import { NgxCurrencyModule } from 'ngx-currency';
import { UtilsModule } from 'src/app/utils/utils.module';
import { CasePlansModule } from '../case-plans/case-plans.module';
import { AddRefundComponent } from './case-economy-add-claims-dialog/add-refund/add-refund.component';
import { CaseEconomyClaimsComponent } from './case-economy-add-claims-dialog/case-economy-add-claims-dialog.component';
import { RefundDocumentComponent } from './case-economy-add-claims-dialog/refund-document/refund-document.component';
import { RefundItemComponent } from './case-economy-add-claims-dialog/refund-item/refund-item.component';
import { CaseEconomyCustomerInvoicesComponent } from './case-economy-customer-invoices/case-economy-customer-invoices.component';
import { CustomerInvoiceNoteComponent } from './case-economy-customer-invoices/customer-invoice-note/customer-invoice-note.component';
import { FinancialStatusComponent } from './case-economy-customer-invoices/financial-status/financial-status.component';
import { CaseEconomyPurchaseAndSalesComponent } from './case-economy-purchase-and-sales/case-economy-purchase-and-sales.component';
import { CaseEconomyServiceOrdersComponent } from './case-economy-service-orders/case-economy-service-orders.component';
import { CaseEconomySosServiceComponent } from './case-economy-sos-service/case-economy-sos-service.component';
import { FeePickerSecondaryComponent } from './case-economy-sos-service/fee-picker-secondary/fee-picker-secondary.component';
import { ModifySalesOrderComponent } from './case-economy-sos-service/modify-sales-order/modify-sales-order.component';
import { CaseEconomySupplierInvoicesComponent } from './case-economy-supplier-invoices/case-economy-supplier-invoices.component';
import { CaseEconomyComponent } from './case-economy.component';
import { ManageSupplierInvoiceDialogComponent } from './manage-supplier-invoice/manage-supplier-invoice-dialog/manage-supplier-invoice-dialog.component';
import { ManageSupplierInvoiceComponent } from './manage-supplier-invoice/manage-supplier-invoice.component';
import { ReturnInvoiceModalComponent } from './manage-supplier-invoice/return-invoice-modal/return-invoice-modal.component';
import { SupplierInvoiceItemComponent } from './manage-supplier-invoice/supplier-invoice-item/supplier-invoice-item.component';
import { CustomerInvoiceDeleteModalComponent } from '@secca/case/components/case-economy/case-economy-customer-invoices/customer-invoice-delete-modal/customer-invoice-delete-modal.component';
import {EditRefundComponent} from '@secca/case/components/case-economy/case-economy-supplier-invoices/edit-refund/edit-refund.component';
import { CustomerInvoiceCreditModalComponent } from '@secca/case/components/case-economy/case-economy-customer-invoices/customer-invoice-credit-modal/customer-invoice-credit-modal.component';
import { CaseEconomyRecoveryComponent } from './case-economy-recovery/case-economy-recovery.component';
import { RecoveryItemComponent } from './case-economy-add-recovery-dialog/recovery-item/recovery-item.component';
import { RecoveryDocumentComponent } from './case-economy-add-recovery-dialog/recovery-document/recovery-document.component';
import { AddRecoveryComponent } from './case-economy-add-recovery-dialog/add-recovery/add-recovery.component';
import { CaseEconomyAddRecoveryDialogComponent } from './case-economy-add-recovery-dialog/case-economy-add-recovery-dialog.component';
import { CommentModalComponent } from '@secca/case/components/case-economy/manage-supplier-invoice/comment-modal/comment-modal.component';
import { CaseEconomyRecoveryPaymentComponent } from './case-economy-recovery-payment/case-economy-recovery-payment.component';
import { CaseEconomyRecoveryPaymentDialogComponent } from './case-economy-recovery-payment-dialog/case-economy-recovery-payment-dialog.component';
import { RecoveryPaymentComponent } from './case-economy-recovery-payment-dialog/recovery-payment/recovery-payment.component';
import { RecoveryPaymentItemComponent } from '@secca/case/components/case-economy/case-economy-recovery-payment-dialog/recovery-payment/recovery-payment-item/recovery-payment-item.component';
import {
  RecoveryPaymentPaidBoxComponent
} from '@secca/case/components/case-economy/case-economy-recovery-payment-dialog/recovery-payment/paid-box/recovery-payment-paid-box.component';

@NgModule({
  declarations: [
    CaseEconomyComponent,
    CaseEconomySosServiceComponent,
    CaseEconomyPurchaseAndSalesComponent,
    CaseEconomyCustomerInvoicesComponent,
    CaseEconomySupplierInvoicesComponent,
    CaseEconomyServiceOrdersComponent,
    CaseEconomyClaimsComponent,
    ModifySalesOrderComponent,
    FeePickerSecondaryComponent,
    CustomerInvoiceNoteComponent,
    CustomerInvoiceDeleteModalComponent,
    CustomerInvoiceCreditModalComponent,
    ReturnInvoiceModalComponent,
    AddRefundComponent,
    RefundItemComponent,
    RefundDocumentComponent,
    FinancialStatusComponent,
    ManageSupplierInvoiceComponent,
    ManageSupplierInvoiceDialogComponent,
    SupplierInvoiceItemComponent,
    EconomyProcessBarComponent,
    EditRefundComponent,
    CaseEconomyRecoveryComponent,
    CaseEconomyRecoveryComponent,
    RecoveryItemComponent,
    RecoveryDocumentComponent,
    AddRecoveryComponent,
    CaseEconomyAddRecoveryDialogComponent,
    CommentModalComponent,
    CaseEconomyRecoveryPaymentComponent,
    CaseEconomyRecoveryPaymentDialogComponent,
    RecoveryPaymentComponent,
    RecoveryPaymentItemComponent,
    RecoveryPaymentPaidBoxComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UtilsModule,
    SharedModule,
    NgxCurrencyModule,
    CasePlansModule,
    NgOptimizedImage
  ],
  exports: [
    CaseEconomyComponent
  ]
})
export class CaseEconomyModule { }
