<div [appDisableInput]="caseStateService.isCaseDisabled()" class="caselock-no-events">
  <div class="left-side-container">
    <div class="coverage-container" *ngIf="isNotMajorIncidentServiceOrderType">
      <app-drop-down-service-order-coverage [items]="validCaseCoverages" [name]="'service-order-coverage' | translate"
        [(selectedItemId)]="serviceOrder.coverageTypeId" (selectedItemIdChange)="dataChanged()" [required]="true" [disabled]="gopCreated || serviceOrderTypeState.isLockedByCoordinationTransport">
      </app-drop-down-service-order-coverage>
    </div>
    <div class="horizontal-line"></div>
    <div class="supplier-container">
      <div [ngClass]="serviceOrder.supplierId ? 'col-7' : 'col-7'">
        <app-drop-down [items]="providers" [name]="'supplier' | translate" [(selectedItemId)]="serviceOrder.supplierId"
          [disabled]="supplierDisabled" [requiredRedBackground]="serviceOrderTypeState.isSupplierRequired || (isCommitted && isAmbulanceFlight)"
          (selectedItemIdChange)="supplierChanged($event)" [recommended]="!serviceOrder.supplierId">
        </app-drop-down>
      </div>
      <div *ngIf='isNewServiceProviderEnabled'>
        <button class="search-supplier-button float-end" [disabled]="supplierDisabled" (click)="searchSupplierNew()" [class.button-busy]="showProviderSpinner" [class.search-supplier-button-inactive]="supplierDisabled">
          <span *ngIf="!serviceOrder.supplierId">{{ 'search-supplier-new' | translate }}</span>
          <img src="/assets/icons/waitForSearchresult.gif" class="spinner" [class.hide-spinner]="!showProviderSpinner" />
          <img class="search-image" src="../../../../../../assets/icons/Magnifier-white.svg" *ngIf="serviceOrder.supplierId">
        </button>
      </div>
      <div *ngIf='isNewServiceProviderEnabled' class='d-flex'>
        <div class='info-image-container' *ngIf='serviceOrder.supplierId' [class.col-2]='serviceOrder.supplierId'>
          <img class='info-image' src='../../../../../../assets/icons/Info.svg' alt='provider-info' (click)='viewSupplier()'>
        </div>
      </div>
    </div>
    <div class="supplier-container w-100 text-start mt-0 pt-0 ps-0">
      <div [class.medium-small]="!serviceOrder.supplierId" >
        <button class="search-supplier-button float-end mt-0" [disabled]="supplierDisabled && !allowSearchSupplierIgnoreTransfer" (click)="searchSupplier()" [class.button-busy]="showProviderSpinner" [class.search-supplier-button-inactive]="supplierDisabled">
          <span *ngIf="!serviceOrder.supplierId">{{ 'search-supplier' | translate }}</span>
          <img src="/assets/icons/waitForSearchresult.gif" class="spinner" [class.hide-spinner]="!showProviderSpinner" />
          <img class="search-image" src="../../../../../../assets/icons/Magnifier-white.svg" *ngIf="serviceOrder.supplierId">
        </button>
      </div>
    </div>
    <div class="horizontal-line"></div>
    <div class="amount-cost-container d-flex" *ngIf="isNotMajorIncidentServiceOrderType">
      <div>
        <app-input-valuta [disabled]="expectedCostDisabled" [name]="'service-order-cost' | translate"
          [(currency)]="serviceOrder.expectedCostCurrency" [required]="!expectedCostDisabled && reserveAmountRequired"
          [(value)]="serviceOrder.expectedCostValue" [inputTypePattern]="InputTypePattern.digitsAndComma" [allowZero]="true"
          (currencyChange)="checkIfLeftSideIsValid()" (valueChange)="checkIfLeftSideIsValid()"></app-input-valuta>
      </div>
      <div class="canceled-warning" *ngIf="canceledWarningVisible">
        {{"left-side-canceled-warning" | translate}}
      </div>
      <div class="canceled-warning not-covered-warning" *ngIf="notCoveredWarningVisible">
        {{"left-side-not-covered-warning" | translate}}
      </div>
    </div>
    <div class="amount-cost-container" *ngIf="!isNotMajorIncidentServiceOrderType">
      <div class="text">
        {{ 'service-order-icc-reserve-expected' | translate }}
      </div>
      <div class="enduser-reserve-message">
        {{"service-order-icc-reserve" | translate}}
      </div>
    </div>
    <div class="horizontal-line"></div>
    <div class="status-container">
      <div class="text">
        {{ 'left-side-status' | translate }}
      </div>
      <div class="d-flex" *ngIf="!iccServiceOrder">
        <div class="w-25 me-1">
          <label class="custom-radio-button" [class.radio-disabled]="expectedDisabled()">
            <input type="checkbox" [checked]="serviceOrder.status === StatusTypes.EXPECTED" [disabled]="expectedDisabled()"
              (click)="changeStatus(StatusTypes.EXPECTED, $event)" />
            <span class="checkmark"></span>
            <div class="radio-button-text">{{ 'service-order-expected' | translate }}</div>
          </label>
        </div>
        <div class="w-25 not-covered p-0 me-1" *ngIf="showNotCoveredStatus()">
          <label class="custom-radio-button" [class.radio-disabled]="notCoveredDisabled()">
            <input type="checkbox" [checked]="serviceOrder.status === StatusTypes.NOT_COVERED"
              [disabled]="notCoveredDisabled()"
              (click)="changeStatus(StatusTypes.NOT_COVERED, $event)" />
            <span class="checkmark"></span>
            <div class="radio-button-text">{{ 'service-order-not-covered' | translate }}</div>
          </label>
        </div>
        <div class="w-25 p-0 me-1">
          <label class="custom-radio-button" [class.radio-disabled]="commitedDisabled()">
            <input type="checkbox" [checked]="serviceOrder.status === StatusTypes.COMMITTED" [disabled]="commitedDisabled()"
              (click)="changeStatus(StatusTypes.COMMITTED, $event)" />
            <span class="checkmark"></span>
            <div class="radio-button-text">{{ 'service-order-committed' | translate }}</div>
          </label>
        </div>
        <div class="w-25 ps-0">
          <label class="custom-radio-button" [class.radio-disabled]="canceledDisabled()">
            <input type="checkbox" [checked]="serviceOrder.status === StatusTypes.CANCELLED" [disabled]="canceledDisabled()"
              (click)="changeStatus(StatusTypes.CANCELLED, $event)" />
            <span class="checkmark"></span>
            <div class="radio-button-text">{{ 'service-order-cancelled' | translate }}</div>
          </label>
        </div>
      </div>
      <div class="row" *ngIf="iccServiceOrder" [appDisableInput]="false">
        <div class="col pe-0" [ngbPopover]="'coordination-linked-service-order-status-disabled-text' | translate" container="body" placement="top" triggers="hover">
          <label class="custom-radio-button" [class.radio-disabled]="true">
            <input type="checkbox" [checked]="serviceOrder.status === StatusTypes.EXPECTED" [disabled]="true"
              (click)="changeStatus(StatusTypes.EXPECTED, $event)" />
            <span class="checkmark"></span>
            <div class="radio-button-text">{{ 'service-order-expected' | translate }}</div>
          </label>
        </div>
        <div class="col not-covered p-0" *ngIf="true" [ngbPopover]="'coordination-linked-service-order-status-disabled-text' | translate" container="body" placement="top" triggers="hover">
          <label class="custom-radio-button" [class.radio-disabled]="true">
            <input type="checkbox" [checked]="serviceOrder.status === StatusTypes.NOT_COVERED"
              [disabled]="true"
              (click)="changeStatus(StatusTypes.NOT_COVERED, $event)" />
            <span class="checkmark"></span>
            <div class="radio-button-text">{{ 'service-order-not-covered' | translate }}</div>
          </label>
        </div>
        <div class="col p-0">
          <label class="custom-radio-button" [class.radio-disabled]="true" [ngbPopover]=" iccCommitedHover() | translate" container="body" placement="top" triggers="hover">
            <input type="checkbox" [checked]="serviceOrder.status === StatusTypes.COMMITTED"
            [disabled]="true"
            (click)="changeStatus(StatusTypes.COMMITTED, $event)" />
            <span class="checkmark"></span>
            <div class="radio-button-text">{{ 'service-order-committed' | translate }}</div>
          </label>
        </div>
        <div class="col ps-0">
          <label class="custom-radio-button" [class.radio-disabled]="true" [ngbPopover]=" iccCancelledHover() | translate" container="body" placement="top" triggers="hover">
            <input type="checkbox" [checked]="serviceOrder.status === StatusTypes.CANCELLED"
            [disabled]="true"
              (click)="changeStatus(StatusTypes.CANCELLED, $event)" />
            <span class="checkmark"></span>
            <div class="radio-button-text">{{ 'service-order-cancelled' | translate }}</div>
          </label>
        </div>
      </div>
    </div>
    <div class="horizontal-line"></div>
    <div class="remarks-container">
      <div class="text">{{ 'service-order-remarks' | translate }}</div>
      <textarea name="textarea" [disabled]="serviceOrderTypeState.isLockedByCoordinationTransport" class="remarks-textarea remarks" maxlength="1000" [(ngModel)]="serviceOrder.remarks"
        (ngModelChange)="dataChanged()"></textarea>
    </div>
    <ng-container *ngIf="isNotMedicalEscortServiceOrderType && isNotMajorIncidentServiceOrderType">
      <div class="horizontal-line"></div>
      <div class="stakeholders-container">
        <div class="text">
          {{ 'service-order-stakeholder-using-the-service' | translate }}
        </div>
        <div class="stakeholders">
          <div *ngFor="let stakeholder of selectedStakeholders; index as i">
            <div [ngClass]="{ 'stakeholder': !isOddNumber(i), 'stakeholder-gray': isOddNumber(i) }">
              <app-stakeholder [stakeholder]="stakeholder"></app-stakeholder>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
