import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-country-info',
  templateUrl: './country-info.component.html',
  styleUrls: ['./country-info.component.scss']
})
export class CountryInfoComponent {
  @Input() countryCode: string;
  @Input() country: string;
  @Output() closeCountryInfo = new EventEmitter<string>();

  close() {
    this.closeCountryInfo.emit('close');
  }
}
