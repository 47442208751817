import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import { SimpleCaseStakeholder } from '@secca/shared/models/simpleCaseStakeholder';
import {StakeholderTypeEnum} from '@secca/shared/models/enums';

@Component({
  selector: 'app-case-new-stakeholder-icon',
  templateUrl: './stakeholder-icon.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./stakeholder-icon.scss']
})
export class StakeholderIconComponent implements OnInit {
  @Input() isMatch: boolean;
  @Input() stakeholder: SimpleCaseStakeholder;
  @Input() ssnValidated: boolean;
  @Input() coordinationCase: boolean;
  icon: string;

  constructor() {
  }

  ngOnInit() {
    if (this.stakeholder && !this.coordinationCase) {
      if (this.stakeholder.hasRole(StakeholderTypeEnum.policyHolder)) {
        this.icon = this.ssnValidated ? 'Policy%20Holder-Match.svg' : 'Is%20Policy%20Holder%20OFF.svg';
      } else if (this.stakeholder.hasRole(StakeholderTypeEnum.endUser)) {
        this.icon = this.ssnValidated ? 'End-User-Match.svg' : 'Is%20End-User%20OFF.svg';
      }
    } else {
      this.icon = 'coordination-case.svg';
    }
  }
}
