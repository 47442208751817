export class SourceUser {
  id: string;
  stakeholderId: string;
  contactPersonId: string;
  firstName: string;
  surname: string;
  companyName: string;
  stakeholderType: string;
  treatingDoctor: boolean;
  icon: string;
  isCompany: boolean;
  sortId: number;
  roleName: string;

  public constructor(init?: Partial<SourceUser>) {
    Object.assign(this, init);
  }
}

export class SourceUserId {
  sourceName: string;
  sourceType: string;

  public constructor(init?: Partial<SourceUserId>) {
    Object.assign(this, init);
  }
}
