import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'timeDiff' })
export class TimeDiffPipe implements PipeTransform {
  constructor() {}
  transform(timeZoneId: string, showMinutesOfStraightTz: boolean): any {
    let fixed = 0;
    let timeDiff = 0;
    if (timeZoneId) {
      timeDiff =  moment().diff(moment().tz(timeZoneId, true), 'hours', true);
    }
    if (showMinutesOfStraightTz){
      fixed = 2;
    }
    let displayTimeDiff = timeDiff.toFixed(fixed).replace('.', ',');
    if ('-0' === displayTimeDiff) {
      displayTimeDiff = '0';
    }
    if (timeDiff > 0) {
      return '+' + displayTimeDiff;
    }
    return displayTimeDiff;
  }
}
