<div class="input" [ngClass]="validationClass">
  <span class="input-name">{{name}}</span>
  <img *ngIf="withImage" src="../../../../assets/icons/Magnifier.svg" class="input-image" [ngStyle]="name && {'top': '30px'}" (click)="imageClicked.emit(value)">
  <input [type]="inputType" [autocomplete]="isProd ? 'disabled' : ''" (blur)="onSaveModel()" inputPattern [inputTypePattern]="inputTypePattern" (focus)="onSaveState()"
    class="input-component" [(ngModel)]="value" (keyup)="onChange()" [placeholder]="placeholder"
    [ngClass]="{'input-component-required' : requiredExternalTrigger || isRequired,
                'input-component-recommended': recommended,
                'input-component-highlight-border': highlightBorderOnInputNonEmpty && !!value && !disabled}" [disabled]="!!disabled"
    [maxLength]="maxTextLength" mask="{{maskPattern}}" #input>
  <img *ngIf="showEye" src='assets/icons/eye.svg' (click)="showHideValue()" class="show-hidden-value">
  <div class="hider" *ngIf="hideValue && showEye && value">X</div>
</div>
