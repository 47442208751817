

import { BoardTransportCase } from './board-transport-case.model';
export class CaseTransportFilterResponse {
  totalNumber: number;
  boardTransportCaseDtoList: BoardTransportCase[];

  public constructor(init?: Partial<CaseTransportFilterResponse>) {
    Object.assign(this, init);
  }
}
