import { ServiceTypeBase } from '../../models/serviceTypeBase';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {ServiceTypeComponent, ValidatingFieldsServiceTypeComponent} from '../../models/interfaces';
import { CoordinationTransportServiceOrderExtension } from '@secca/shared/models/service-order/coordination-transport-service-order-extension';
import { StakeholderTypeEnum, StatusTypes, CoordinationTypeEnum, CommitmentTypes } from '@secca/shared/models/enums';
import { SubTravellers } from '../../models/subTravellers';
import { SubTravellersResourceConfig } from '../../models/subTravellersResourceConfig';
import { MomentHelperService } from '@secca/core/services/moment-helper.service';
import { SubStakeholdersConfig } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholdersConfig';
import { ServiceOrder } from '@secca/shared/models/service-order/service-order';
import { CoordinationCaseDetail } from '@secca/shared/models/coordination-case-details';
import { cloneDeep } from 'lodash-es';
import { Subscription } from 'rxjs';
import { ICCStakeholder } from '../../models/icc-stakeholder';
import { ICCStakeholders } from '../../models/icc-stakeholders';
import { ServiceOrderService } from '@secca/core/services/service-order.service';
import { CoordinationCaseService } from '@secca/core/services/coordination-case.service';
import { ICCAmbulanceFlight } from '@secca/case/components/case-plans/case-plans/add-service-order/models/icc-ambulanceFlight';
import { SubStakeholder } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholder';
import { CoordinationDetailsService } from '@secca/core/services/coordination-details.service';
import { TranslateService } from '@ngx-translate/core';
import { CommitmentType } from '@secca/shared/models/commitment-type';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';


@Component({
  selector: 'app-coordination-transport',
  templateUrl: './coordination-transport.component.html',
  styleUrls: ['./coordination-transport.component.scss'],
})
export class CoordinationTransportComponent extends ServiceTypeBase
    implements ServiceTypeComponent, ValidatingFieldsServiceTypeComponent, OnInit, OnChanges  {
  @Input() leftSideValid = false;
  @Input() caseId: string;
  coordinatedCaseServiceOrders: ServiceOrder[];
  coordinatedCaseDetails: CoordinationCaseDetail[];
  committedCoordinatedCaseDetails: CoordinationCaseDetail[];
  expectedCoordinatedDetails : CoordinationCaseDetail[];
  @Input() status: StatusTypes;
  @Input() coordinationTransport: CoordinationTransportServiceOrderExtension;
  @Input() supplierSelected: boolean = false;
  commitmentType: CommitmentType[];
  expectedFlight: SubTravellers;
  confirmationFlight: SubTravellers;
  expectedCoordinationResourceConfig: SubTravellersResourceConfig;
  confirmtCoordinationResourceConfig: SubTravellersResourceConfig;
  coordinationCaseTypeText: string;
  subStakeholdersConfig: SubStakeholdersConfig;
  savedFlightStakeholders: ICCStakeholders;
  private caseDetailtCaseNumbersForValidation: Map<number, string> = new Map<number, string>();
  hoverContent = '';

  constructor(
    private coordinationService: CoordinationCaseService,
    private serviceOrderService: ServiceOrderService,
    private coordinationDetailsService: CoordinationDetailsService,
    private translateService: TranslateService,
  ) {
    super();
    this.initializeData();
    this.initialize();
  }

  ngOnInit() {
    this.initializeSettings();
    this.getServiceOrdersForCoordinationCase(+this.caseId);
    this.getLinkedCasesDetails(this.caseId);

    this.coordinationDetailsService.getCoordinationDetails(this.caseId).subscribe(result => {
      this.coordinationCaseTypeText =  result.type ? this.translateService.instant('coordination-case-type-' + result.type) : "";
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.status) {
      if ((changes.status.previousValue === StatusTypes.EXPECTED || changes.status.previousValue === StatusTypes.NOT_COVERED) && changes.status.currentValue === StatusTypes.COMMITTED) {
        this.copyForConfirmation();
        this.status = StatusTypes.COMMITTED;
      }
    }
  }

  setCaseRemark(event, caseId: number) {
    this.coordinationTransport.icclinkedCasesRemarks.setByCase(caseId, event);
  }

  async getServiceOrdersForCoordinationCase(caseId: number){
    this.serviceOrderService.getServiceOrdersForCoordinationCase(caseId)
      .subscribe(serviceOrders => {
        this.coordinatedCaseServiceOrders = serviceOrders;
        this.savedFlightStakeholders = cloneDeep(this.coordinationTransport.iccConfirmationFlightStakeholders);
       });
  }

  async getLinkedCasesDetails(caseId: string){
    this.coordinationService.getLinkedCasesDetails(caseId)
      .subscribe(linkedCaseDetails => {
        this.coordinatedCaseDetails = linkedCaseDetails;
        this.expectedCoordinatedDetails = linkedCaseDetails;
        this.committedCoordinatedCaseDetails =  cloneDeep(this.coordinatedCaseDetails);
        for (const caseDetail of linkedCaseDetails) {
          this.caseDetailtCaseNumbersForValidation.set(caseDetail.caseId, caseDetail.caseNumber);
        }
        if (this.status == StatusTypes.COMMITTED){
          this.expectedCoordinatedDetails = this.coordinatedCaseDetails.filter((coordinatedCaseDetails) => this.coordinationTransport.iccExpectedFlightStakeholders.isFilledForCase(""+coordinatedCaseDetails.caseId));
          this.committedCoordinatedCaseDetails.forEach(detail => {
            if (this.coordinationTransport.iccConfirmationFlightStakeholders.byCase(detail.caseId.toString()).length == 0) {
              let end_user_id = ((detail.stakeholders.find(stakeholder => stakeholder.hasRole(StakeholderTypeEnum.endUser))) as CaseStakeholder).id;
              let serviceOrderId = detail.serviceOrders.length === 1 ? detail.serviceOrders[0].id : null;
              this.coordinationTransport.iccConfirmationFlightStakeholders.push(new ICCStakeholder({caseId: detail.caseId.toString(),  stakeholderId: end_user_id, serviceOrderId: serviceOrderId, serviceOrderStatus: null}));
            }
          });
        }
        if (this.status == StatusTypes.EXPECTED) {
          this.expectedCoordinatedDetails.forEach(detail => {
            if (this.coordinationTransport.iccExpectedFlightStakeholders.byCase(detail.caseId.toString()).length == 0) {
              let end_user_id = ((detail.stakeholders.find(stakeholder => stakeholder.hasRole(StakeholderTypeEnum.endUser))) as CaseStakeholder).id;
              let serviceOrderId = detail.serviceOrders.filter(so => StatusTypes.EXPECTED == so.status).length === 1 ? detail.serviceOrders.filter(so => StatusTypes.EXPECTED == so.status)[0].id : null;
              this.coordinationTransport.iccExpectedFlightStakeholders.push(new ICCStakeholder({caseId: detail.caseId.toString(), stakeholderId: end_user_id, serviceOrderId: serviceOrderId}));
            }
          })
        };
      });
  }

  copyForConfirmation() {
    this.confirmationFlight = cloneDeep(this.expectedFlight);
    this.confirmationFlight.flightLegs.forEach(flightleg => {
      flightleg.id = null;
    });
    this.coordinationTransport.confirmationFlight = this.confirmationFlight.flightLegs;
    this.coordinationTransport.iccConfirmationFlightStakeholders = new ICCStakeholders();
    this.coordinationTransport.iccConfirmationFlightStakeholders.stakeholders = this.coordinationTransport.iccExpectedFlightStakeholders.stakeholders.map(stakeholder => this.cloneIccStakeholder(stakeholder));
    this.coordinationTransport.confirmationFlightStakeholders = this.coordinationTransport.iccConfirmationFlightStakeholders.stakeholders;
    this.coordinationTransport.iccConfirmationAmbulanceFlights = [];
    this.coordinationTransport.iccExpectedAmbulanceFlights.forEach(iccAmbulanceFlight => {
      const iccConfirmationAmbulanceFlight = new ICCAmbulanceFlight();
      iccConfirmationAmbulanceFlight.linkedCaseId = iccAmbulanceFlight.linkedCaseId;
      iccConfirmationAmbulanceFlight.groundTransportToFlightNotIncluded = iccAmbulanceFlight.groundTransportToFlightNotIncluded;
      iccConfirmationAmbulanceFlight.groundTransportFromFlightNotIncluded = iccAmbulanceFlight.groundTransportFromFlightNotIncluded;
      iccConfirmationAmbulanceFlight.groundTransportToFlight = iccAmbulanceFlight.groundTransportToFlight;
      iccConfirmationAmbulanceFlight.groundTransportFromFlight = iccAmbulanceFlight.groundTransportFromFlight;
      iccConfirmationAmbulanceFlight.groundTransportToFlightStakeholders = iccAmbulanceFlight.groundTransportToFlightStakeholders.map(stakeholder => this.cloneSubStakeholder(stakeholder));
      iccConfirmationAmbulanceFlight.groundTransportFromFlightStakeholders = iccAmbulanceFlight.groundTransportFromFlightStakeholders.map(stakeholder => this.cloneSubStakeholder(stakeholder));
      iccConfirmationAmbulanceFlight.subGroundTransportToFlight = iccAmbulanceFlight.subGroundTransportToFlight;
      iccConfirmationAmbulanceFlight.subGroundTransportFromFlight = iccAmbulanceFlight.subGroundTransportFromFlight;
      iccConfirmationAmbulanceFlight.subGroundTransportToFlightStakeholders = iccAmbulanceFlight.subGroundTransportToFlightStakeholders;
      iccConfirmationAmbulanceFlight.subGroundTransportFromFlightStakeholders = iccAmbulanceFlight.subGroundTransportFromFlightStakeholders;
      this.coordinationTransport.iccConfirmationAmbulanceFlights.push(iccConfirmationAmbulanceFlight);
    });
  }

  private cloneIccStakeholder(stakeholder: ICCStakeholder): ICCStakeholder {
    stakeholder = cloneDeep(stakeholder);
    return stakeholder;
  }

  private cloneSubStakeholder(expectedStakeholder: SubStakeholder): SubStakeholder {
    const committedStakeholder = new SubStakeholder();
    committedStakeholder.stakeholderId = expectedStakeholder.stakeholderId;
    return committedStakeholder;
  }

  startDateChange(date): void {
    this.coordinationTransport.startDate = date;
  }

  isValidForChangingTheStatusToConfirmation(): boolean {
    return this.isValid();
  }

  disableConfirmationButton(): boolean {
     return this.coordinatedCaseDetails !== undefined ? this.isValid() && !this.serviceOrderTypeState.sendRequestRequested && this.supplierSelected : undefined;
  }

  isValid(): boolean {
      return this.coordinationTransport.getMissingFields(this.serviceOrderTypeState.showConfirmationTab, this.caseDetailtCaseNumbersForValidation).length === 0;
  }

  getMissingFields(): string[] {
    return this.coordinationTransport.getMissingFields(this.serviceOrderTypeState.showConfirmationTab, this.caseDetailtCaseNumbersForValidation);
  }

  generateMissingFieldsText() {
    const missingFields = this.getMissingFields();
    let _hoverContent;
    _hoverContent = this.translateService.instant('service-order-missing-fields');
    missingFields?.forEach(mf => {
      if(mf.includes('relevantforcase')) {
        _hoverContent += '<br/>- ' + mf.split(' ')[1] + ' ' + this.translateService.instant('service-order-missing-field-relevantforcase');
      }
      else if(mf.includes('passengerConfirmed')) {
        _hoverContent += '<br/>- ' + mf.split(' ')[1] + ' ' + this.translateService.instant('service-order-missing-field-passengerConfirmed');
        }
      else {
        _hoverContent += '<br/>- ' + this.translateService.instant('service-order-missing-field-' + mf);
      }
    });
    if(this.status === StatusTypes.COMMITTED && !this.supplierSelected) { _hoverContent += '<br/>- ' + this.translateService.instant('left-side-no-supplier-selected-warning') }
    this.hoverContent = _hoverContent;
  }

  sendConfirmation() {
    this.serviceOrderTypeState.sendRequestRequested = true;
    this.serviceOrderTypeState.sendConfirmationEvent.emit();
 }

  getModel(): CoordinationTransportServiceOrderExtension {
    return this.coordinationTransport;
  }

  isCommitted(): boolean {
    return this.status === StatusTypes.COMMITTED;
  }

  initializeSettings() {
    if (this.coordinationTransport.expectedFlight.length !== 0) {
      this.expectedFlight = new SubTravellers();
      this.expectedFlight.flightLegs = this.coordinationTransport.expectedFlight;
    }
    if (this.coordinationTransport.confirmationFlight) {
      this.confirmationFlight = new SubTravellers();
      this.confirmationFlight.flightLegs = this.coordinationTransport.confirmationFlight;
    }

    this.isSupplierRequiredOnConfirmationTab = false;
    this.coordinationTransport.confirmationFlightStakeholders = this.coordinationTransport.iccConfirmationFlightStakeholders?.stakeholders ? this.coordinationTransport.iccConfirmationFlightStakeholders.stakeholders : [];
    this.coordinationTransport.confirmationFlightStakeholders.forEach(stakeholder => {
      if(!stakeholder.serviceOrderStatus) {
        stakeholder.serviceOrderStatus = null;
      }
    })
  }

  initialize() {
    this.subStakeholdersConfig = new SubStakeholdersConfig();
    this.subStakeholdersConfig.requireAtLeastOneStakeholder = true;
    this.subStakeholdersConfig.hideClass = true;
    this.subStakeholdersConfig.hideInclPassportDetails = true;
    this.subStakeholdersConfig.hideInclDateOfBirth = true;
    this.subStakeholdersConfig.mandatoryAndSetStakeholderType = StakeholderTypeEnum.endUser;
  }

  initializeData() {
    this.confirmtCoordinationResourceConfig = new SubTravellersResourceConfig();
    this.expectedCoordinationResourceConfig = new SubTravellersResourceConfig();
    this.expectedFlight = new SubTravellers();
    this.confirmationFlight = new SubTravellers();
    this.expectedCoordinationResourceConfig.title = 'expected-coordination-flight-suggested-route-title';
    this.confirmtCoordinationResourceConfig.title = 'confirmt-coordination-flight-suggested-route-title';
    this.confirmtCoordinationResourceConfig.travelInfoResourceConfig.agencyRefLabel = 'sub-travel-info-flight-reg-no';
  }
}
