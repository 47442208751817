<div *ngIf="case && task" class="modal-container">
  <div class="modal-content">
    <div class="modal-header">
      <div class="modal-header-title">
        {{ modalTitle | translate }}
      </div>
      <div class="modal-buttons">
        <div (click)="close()">
          <img src="/assets/icons/Close White.svg" alt="Close window icon"/>
        </div>
      </div>
    </div>
    <app-case-lock-overlay
      *ngIf="taskModalModeEnum !== TaskModalModeEnum.viewOnly"
      [lockContext]="LockContextEnum.CASE_BASIC"
      [asOverlay]="false"
      [unlockPostAction]="CaseLockOverlayAction.TAKE_LOCK"
    ></app-case-lock-overlay>
    <div class="modal-content-body">
      <div class="top-info">
        <app-task-modal-static-header *ngIf="!isRecoveryTask"
          [case]="case"
          [actualTimeZoneId]="actualTimeZoneId"
          [profileName]="profileName"
          [location]="location"
          [primaryCaseHandler]="primaryCaseHandler"
          [reserve]="reserve"
          [currency]="currency"
          [incidentCause]="incidentCause"
          [lastCaseActionBy]="lastCaseActionBy"
          [lastCaseActionTime]="lastCaseActionTime"
          [isCoordinationCase]="isCoordinationCase()"
        ></app-task-modal-static-header>
        <app-task-modal-static-recovery-header *ngIf="isRecoveryTask"
          [case]="case"
          [profileName]="profileName"
          [location]="location"
          [incidentCause]="incidentCause"
          [task]="task"
        ></app-task-modal-static-recovery-header>
      </div>
      <div *permission="PermissionEnum.TASK_UPDATE; hideType: PermissionHideTypeEnum.DISABLE">
        <div [ngSwitch]="taskModalModeEnum">
          <div
            *ngSwitchCase="
              [TaskModalModeEnum.viewOnly, TaskModalModeEnum.partialViewOnly, TaskModalModeEnum.addNew, TaskModalModeEnum.edit, TaskModalModeEnum.assignableOnly] | switchMultiCase: taskModalModeEnum
            "
          >
            <div class="modal-body">
              <div class="container">
                <div>
                  <div>
                    <div class="d-flex left-panel-message-case-edit ps-2 pe-2">
                      <div class="modal-body-left-panel">
                        <div
                          [appDisableInput]="
                            (caseLockHelperService.isCaseBasicLockTaken && taskModalModeEnum !== TaskModalModeEnum.addNew) ||
                            permissionService.isReadOnly
                          "
                        >
                          <div class="field-title">{{ 'task-modal-title' | translate }}</div>
                          <div>
                            <input
                              [disabled]="isReadOnly || isPartialReadOnly || isAssignableOnly"
                              type="text"
                              name="textinput"
                              class="task-text"
                              maxlength="1000"
                              [name]="'Title'"
                              [required]="true"
                              (input)="setTaskHasChanged()"
                              [(ngModel)]="task.title"
                            />
                          </div>
                          <div class="row-separator-small"></div>
                          <div class="field-title">
                            {{ 'task-modal-description' | translate }}
                          </div>
                          <app-task-editor
                            [text]="task.description"
                            [disabled]="
                              isReadOnly || isPartialReadOnly || isAssignableOnly || (caseLockHelperService.isCaseBasicLockTaken && taskModalModeEnum !== TaskModalModeEnum.addNew)
                            "
                            [isEmbeddedView]="false"
                            (textChanged)="descriptionChange($event)"
                            (focusOut)="descriptionChange($event)"
                          >
                          </app-task-editor>
                        </div>
                      </div>
                      <div class="modal-body-right-panel">
                        <div
                          [appDisableInput]="
                            (caseLockHelperService.isCaseBasicLockTaken && taskModalModeEnum !== TaskModalModeEnum.addNew) ||
                            permissionService.isReadOnly
                          "
                          class="caselock-no-events"
                        >
                          <div class="field-title">{{ 'task-modal-received-date' | translate }}</div>
                          <div>
                            <app-input-date-time
                              [disabled]="true"
                              [date]="task.receivedDate"
                              [withImage]="true"
                              [ngClass]="validated ? '' : 'required-red'"
                              [timeZone]="'local'"
                              [required]="!task.receivedDate"
                            >
                            </app-input-date-time>
                          </div>
                          <div class="row-separator"></div>
                          <div class="field-title">{{ 'task-modal-due-date' | translate }}</div>
                          <div>
                            <app-input-date-time
                              [disabled]="isReadOnly || isSupplierInvoiceTask || isRefundTask || isMedicalPreAssessmentTask
                              || isRecoveryTask || isAssignableOnly || isRecoveryPaymentTask"
                              [date]="task.dueDate"
                              [withImage]="true"
                              [ngClass]="!validated ? '' : 'required-red'"
                              (dateChange)="dueDateChanged($event)"
                              [timeZone]="'local'"
                              [required]="!task.dueDate"
                            >
                            </app-input-date-time>
                          </div>
                          <div class="row-separator"></div>
                          <div class="field-title" *ngIf="!task.originatedFromWebtaskId">{{ 'task-modal-priority' | translate }}</div>
                          <div>
                            <app-drop-down
                            [dropdownPosition]="'auto'"
                            [disabled]="isReadOnly || isPartialReadOnly || isSupplierInvoiceTask || isRefundTask
                            || isRecoveryTask || isAssignableOnly || isRecoveryPaymentTask"
                            [items]="priorityDropdown"
                            [(selectedItemId)]="task.priority"
                            [requiredRedBackground]="true"
                            (selectedItemIdChange)="setTaskHasChanged()"
                          ></app-drop-down>
                          </div>
                          <div class="row-separator"></div>
                          <app-case-handler-selector
                            [acHandler]="acHandler" [caseId]="caseId"
                            [isViewOnly]="taskModalModeEnum === TaskModalModeEnum.viewOnly" [required]="true"
                            (caseHandlerChange)="caseHandlerChanged($event)"
                          ></app-case-handler-selector>
                          <div class="row-separator"></div>
                          <div class="field-title">{{ 'task-modal-status' | translate }}</div>
                          <div>
                            <app-drop-down
                              [dropdownPosition]="auto"
                              [disabled]="isReadOnly || isSupplierInvoiceTask || isRefundTask || isRecoveryTask
                              || (isMedicalPreAssessmentTask && !isMedicalPreAssessmentTaskDone) || isAssignableOnly
                              || isRecoveryPaymentTask"
                              [items]="task.allStatuses"
                              [(selectedItemId)]="task.status"
                              [requiredRedBackground]="true"
                              (selectedItemIdChange)="setTaskHasChanged()"
                            ></app-drop-down>
                          </div>
                          <div class="row-separator"></div>
                          <div class="row-separator"></div>
                        </div>
                        <div *ngIf="isTaskTypeHandelMedicalAssessment">
                          <button
                            (click)="openServiceOrderModal()"
                            class="submit-button primary-button no-margin"
                            [disabled]="serviceOrderHasBeenDeleted"
                          >
                            <div [disablePopover]="!serviceOrderHasBeenDeleted" triggers="hover" [ngbPopover]="'task-modal-service-has-been-deleted' | translate">
                              Open Service
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 task-id-col">
                        <div class="task-id">
                          <div>
                            {{
                              task.createdOn
                                ? 'Created: ' +
                                  (task.createdOn | momentFormat: 'DD MMM YYYY HH:mm':'local') +
                                  '&nbsp;&nbsp;&nbsp;ID: ' +
                                  task.id
                                : '&nbsp;'
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 task-id-col">
                        <div class="task-id" *ngIf="originatedFromWebtask">
                          {{
                          'Based on: ' +
                          (originatedFromWebtask.createdOn | momentFormat: 'DD MMM YYYY HH:mm':'local') +
                          '&nbsp;&nbsp;&nbsp;ID: ' +
                          originatedFromWebtask.id
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-body-bottom-panel">
                <div class="horizontal-line"></div>
                <div>
                  <button
                  class="float-end primary-button button-margin"
                    [disabled]="!validated"
                    (click)="createOrEditTask()"
                    [ngClass]="validated && !saveButtonHasBeenClicked && isTaskChanged ? 'submit-button' : 'submit-button-deactivated'"
                  >
                    <ng-template [ngIf]="disableSaveButtonWhenNoChanges" [ngIfElse]="saveButtonEnabled">
                      {{ SubmitButtonTitle | translate }}
                    </ng-template>
                    <ng-template #saveButtonEnabled>
                      <ng-template [ngIf]="isReadOnly || isSupplierInvoiceTask || isRefundTask" [ngIfElse]="closeButton">
                        {{ 'task-modal-submit-close' | translate }}
                      </ng-template>
                      <ng-template #closeButton>
                        {{ 'task-modal-submit-save' | translate }}
                      </ng-template>
                    </ng-template>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
