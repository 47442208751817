import { Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges, SimpleChanges, Directive } from '@angular/core';
import * as moment from 'moment';
import { MomentHelperService, SECCA_TIMEZONES } from '@secca/core/services/moment-helper.service';

@Directive()
export class PickerBaseComponent implements OnChanges {
  @Input() required: boolean;
  @Input() semiRequired:boolean;
  @Input() invalid: boolean;
  @Input() name: string;
  @Input() withImage: boolean;
  @Input() date: moment.Moment;
  @Input() placeholder: string;
  @Input() recommended: boolean;
  @Input() disabled: boolean;
  @Input() pickerDisabled: boolean;
  @Input() markWithError: boolean;
  @Input() highlightBorderOnInputNonEmpty = false;
  seccaTimeZone: string = 'utc';
  @Input() set minDate(min: moment.Moment){
    if (min) {
      this.minMoment = min.clone().local(true);
    }
  }

  @Input() set maxDate(max: moment.Moment){
    if (max) {
      this.maxMoment = max.clone().local(true);
    }
  }

  @Input() startAt: moment.Moment;
  
  @Input() set timeZone(seccaTimeZone: SECCA_TIMEZONES) {
    this.seccaTimeZone = MomentHelperService.resolveTimeZone(seccaTimeZone);
    if (this.owlMoment) {
      this.dateChange.emit(this.resetToUtc(this.owlMoment));
    }
  }

  @Output() dateChange = new EventEmitter();
  @Output() invalidChange = new EventEmitter<moment.Moment>();

  @ViewChild('dateTime', { static: true }) dateTime;
  @ViewChild('inputfield', { static: true }) inputField: ElementRef;
  @ViewChild('dt1', { static: true }) datePicker;

  public owlMoment;
  public minMoment: moment.Moment;
  public maxMoment: moment.Moment;

  public showRequired = true;
  public showSemiRequired = false;
  public showRecommended = false;
  private currentMoment: moment.Moment;
  protected formats;


  ngOnChanges(changes: SimpleChanges) {
    if (changes.date) {
      if (changes.date.currentValue && !moment.isMoment(changes.date.currentValue)) {
        throw new Error('date is not a moment');
      }
      if (this.date) {
        this.owlMoment = this.date.clone().tz(this.seccaTimeZone);
      } else {
        this.owlMoment = null;
      }
      this.currentMoment = this.owlMoment;
      this.formatToReadable();
    }
    setTimeout(() => {
      this.showRequired = this.required && !this.inputField.nativeElement.value;
      this.showSemiRequired = this.semiRequired && !this.inputField.nativeElement.value;
      this.showRecommended = this.recommended && !this.inputField.nativeElement.value;
    });
  }

  onPickerDateChange(changes) {
    if (this.dateTime.invalid) {
      this.invalidChange.emit(moment.invalid());
    } else {
      if (changes.value ) {
        this.currentMoment = changes.value.clone();
      } else {
        this.currentMoment = null;
      }
      this.dateChange.emit(this.resetToUtc(changes.value));
      this.formatToReadable();
    }
    setTimeout(() => {
      this.showRequired = this.required && !this.inputField.nativeElement.value;
      this.showRecommended = this.recommended && !this.inputField.nativeElement.value;
    });
  }

  onFocus(event) {
    if (this.currentMoment) {
      this.inputField.nativeElement.value = this.currentMoment.format(this.formats.parseInput);
    }
  }

  onBlur() {
    this.formatToReadable();
  }

  private formatToReadable() {
    if (this.currentMoment) {
      this.inputField.nativeElement.value = this.currentMoment.clone().format(this.formats.fullPickerInput);
    } else {
      this.inputField.nativeElement.value = "";
    }
  }

  private resetToUtc(momentWithOffset: moment.Moment) {
    if (!momentWithOffset) {
      return null;
    }
    const offSetMoment = momentWithOffset.clone();
    offSetMoment.tz(this.seccaTimeZone, true);
    return offSetMoment.utc();
  }
}
