import { AttachmentDto, AttachmentAdapter } from './attachment-dto';

import { Injectable } from '@angular/core';
import { Adapter } from './../interfaces/adapter';

export class CaseEmail {
    id: number;
    emailId: string;
    faxId: string;
    messageId: string;
    internalSummary: string;
    from: string;
    subject: string;
    to: string;
    ccRecipientEmails: string[];
    attachments: AttachmentDto[];
    stakeholderReceiverId: number;
    stakeholderSenderId: number;
    receivedDate;
    contentType;
    status: string;

    public constructor(init?: Partial<CaseEmail>) {
        Object.assign(this, init);
    }
}

@Injectable({
    providedIn: 'root'
})
export class CaseEmailAdapter implements Adapter<CaseEmail> {

    constructor(private attachmentAdapter: AttachmentAdapter) {
    }

    adapt(item: any): CaseEmail {
        const attachments: AttachmentDto[] = [];
        item.attachments.forEach(attachment =>
            attachments.push(this.attachmentAdapter.adapt(attachment))
        );
        return new CaseEmail({
            id: item.id,
            internalSummary: item.internalSummary,
            from: item.from,
            subject: item.subject,
            to: item.to,
            attachments: attachments,
            emailId: item.emailId,
            faxId: item.faxId,
            messageId: item.messageId,
            stakeholderReceiverId: item.stakeholderReceiverId,
            stakeholderSenderId: item.stakeholderSenderId,
            receivedDate: item.receivedDate,
            ccRecipientEmails: item.ccRecipientEmails,
            contentType: item.contentType,
            status: item.status,
        });

    }
}
