import { Injectable } from "@angular/core";
import { Adapter } from "../interfaces/adapter";
import { CausesLevel3DD, CausesLevel3DDAdapter } from "./CausesLevel3DD";
import { DropdownDictionary } from "./dropdownDictionary";

export class CAUSES_LEVEL2_CODES {
  static readonly PRE_ASSESSMENT_TRAVEL       = 'CL2039';
  static readonly PRE_ASSESSMENT_CANCELLATION = 'CL2040';  
}

export class CausesLevel2DD extends DropdownDictionary {
    level3Causes: CausesLevel3DD[];

    public constructor(init?: Partial<CausesLevel2DD>) {
        super();
        Object.assign(this, init);
    }    
}

@Injectable({
    providedIn: 'root'
})  
export class CausesLevel2DDAdapter implements Adapter<CausesLevel2DD> {
    constructor(private causesLevel3DDAdapter: CausesLevel3DDAdapter) {
    }

    adapt(item: any): CausesLevel2DD {
        return new CausesLevel2DD({
        id: item.level2Code,
        name: item.level2,
        level3Causes: item.level3Causes ? item.level3Causes.map(causeLevel3 => this.causesLevel3DDAdapter.adapt(causeLevel3)) : []
      });
    }
}

