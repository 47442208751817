<div class="d-flex">
  <div class="common-drop-down drop-down-input"
    [className]="validate && isValidated(selectedItemId) ? 'invalid-drop-down-input' : 'common-drop-down drop-down-input'">
    <span class="name">{{name}}</span>
    <ng-select [clearable]="false" (blur)="onSaveModelDropDown()" (focus)="onSaveState()" [(ngModel)]="selectedItemId"
      class="custom" (change)="onSelectedItemIdChange()" [disabled]="!!disabled">
      <ng-option *ngFor="let item of items" [value]="item.id">{{item.name}}</ng-option>
    </ng-select>
  </div>
  <div class="input-container">
    <app-input [withImage]="false" [(value)]="value" (valueChange)="onValueChange()" (saveModel)="onSaveModelInput()"
      (focus)="onSaveState()" [validate]="validate" [disabled]="blockEmail || !!disabled"
      [validationType]="validationTypeToInput">
    </app-input>
  </div>
</div>
