import { Injectable } from "@angular/core";
import { MatLegacyDialog as MatDialog, MatLegacyDialogState as MatDialogState } from "@angular/material/legacy-dialog";
import { AutoUnsubscribe } from "@secca/shared/decorators/auto-unsubscribe";
import { Subscription } from "rxjs";
import { DialogStateService } from "../state-services/dialog-state.service";

@Injectable({
    providedIn: 'root'
})
@AutoUnsubscribe
export class DialogFocusService{
    sendToFrontSubscr: Subscription;

    constructor(dialogStateService: DialogStateService,
                private matDialog: MatDialog) {
      this.sendToFrontSubscr = dialogStateService.getSendToFront().subscribe(dialogId => {
        this.setFocus(dialogId);
      });
    }

    private setFocus(dialogId: string): void {
      const dialogRef = this.matDialog.getDialogById(dialogId);
      if ( dialogRef && dialogRef.getState() === MatDialogState.OPEN ) {
        const component = dialogRef.componentInstance;
        const focusElement = component.getFocusElement && component.getFocusElement();          
        this.setFocusToElement(dialogRef._containerInstance, focusElement);
      }
    }
    
    private setFocusToElement(component: any, element: any): void {
      if ( element?.disabled ) {
        // element disabled, find next enabled input element, if any
        element = this.getNextElement(component, element);
      }
      if ( element ) {
          // Special handling for tinymce rich text editor focus
          if ( this.isTinymceEditorComponent(element) ) {
            this.setTinymceFocus(element);
          }
          else {
            element.focus();
          }
      }
      else {
        component._recaptureFocus();
      }
    }

    private getNextElement(component: any, element: any): any {
      const elementRef = component?._elementRef?.nativeElement;
      if ( !elementRef ) {
        return null;
      }
      const allInputElements = Array.from(elementRef.querySelectorAll('input,button:not(.tox-tbtn,.tox-statusbar__wordcount),iframe.tox-edit-area__iframe'));
      let found: boolean = false;
      return allInputElements.find((elem:any) => {
        found = found || elem === element;
        return found && elem !== element && (elem.disabled === undefined || elem.disabled === false);
      });      
    }

    private setTinymceFocus(element: any) {
      const body = element.contentDocument?.getElementsByTagName('body');
      if (body !== undefined && body.length > 0) {
        body[0].focus();
      }
    }

    private isTinymceEditorComponent(element: any): boolean {
      return element.classList?.contains('tox-edit-area__iframe');
    }
}
