import { Calculations } from './calculations';
import { DictionaryService } from '@secca/core/services/dictionary.service';
import { DropdownDictionary, SupplierDropDownAdapter } from '@secca/shared/models/dropdownDictionary';
import { ServiceOrder } from '@secca/shared/models/service-order/service-order';
import { Case } from '@secca/shared/models/case';
import { CaseService } from '@secca/core/services/case.service';
import { Component, Input, OnInit } from '@angular/core';
import { SupplierInvoice } from '@secca/shared/models/supplierInvoice';
import { ServiceOrderService } from '@secca/core/services/service-order.service';
import { SupplierInvoiceService } from '@secca/core/services/supplier-invoice.service';
import {
  BalanceSheetEventEnum,
  BalanceSheetStatusEnum,
  CommitmentTypes,
  MedicalEscortExpenseItemEnum,
  PermissionEnum,
  RefundStatus,
  ServiceOrderStatus,
  ServiceTypeEnum,
  ServiceTypeEnumIcon,
  ShortcutEnum,
  StakeholderTypeEnum,
  StatusTypes,
  SupplierInvoiceStatus
} from 'src/app/shared/models/enums';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { SupplierInvoiceItem } from '@secca/shared/models/supplierInvoiceItem';
import { SupplierExpensesAppendix } from '@secca/shared/models/supplierExpensesAppendix';
import * as DineroFactory from 'dinero.js';
import { DineroObject } from 'dinero.js';
import { ServiceOrderEconomyViewModel } from './service-order-economy-view-model';
import { first, map, take } from 'rxjs/operators';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { ServiceOrderHoverModel } from '@secca/case/components/case-plans/plan/service-order-hover/service-order-hover-model';
import { CasePlansService } from '@secca/case/components/case-plans/case-plans.service';
import { RefundService } from '@secca/core/services/refund.service';
import { Refund } from '@secca/shared/models/refund';
import { ServiceItemType } from '@secca/shared/models/service-item-type';
import { ReductionsCalculator } from '../case-economy-add-claims-dialog/refund-item/reduction-calculator';
import { PermissionService } from '@secca/core/services/permission.service';
import { ServiceOrderRefundItemViewModel, ServiceOrderRefundViewModel } from './service-order-refund-view-model';
import * as moment from 'moment';
import { InsuranceService } from '@secca/core/services/insurance.service';
import { CaseDialogViewerService } from '@secca/case/case-dialog-viewer.service';
import { AttachmentDto } from '@secca/shared/models/attachment-dto';
import { DocumentService } from '@secca/core/services/document.service';
import { CaseDocument } from '@secca/shared/models/caseDocument';
import { ShortcutService } from '@secca/core/services/shortcut.service';
import { SettingsService } from '@secca/core/services/settings.service';
import { ServiceOrderType } from '@secca/shared/models/service-order-type';
import { BalanceSheetService } from '@secca/core/services/balance-sheet.service';
import { BalanceSheetEvent, BalanceSheetProgress } from '@secca/shared/models/balance-sheet-event.model';
import { CoordinationCaseService } from '@secca/core/services/coordination-case.service';
import { CoordinationCaseDetail } from '@secca/shared/models/coordination-case-details';
import { CaseIncident } from '@secca/shared/models/caseIncident';
import { IncidentService } from '@secca/core/services/incident.service';


@Component({
  selector: 'app-case-economy-service-orders',
  templateUrl: './case-economy-service-orders.component.html',
  styleUrls: ['./case-economy-service-orders.component.scss'],
})
@AutoUnsubscribe
export class CaseEconomyServiceOrdersComponent implements OnInit {
  readonly ALLOWED_MIME_TYPES = ['application/pdf', 'image/png', 'image/jpg', 'image/jpeg', 'image/heic'];

  @Input()
  set case(newCase: Case) {
    const isOtherCase = this.theCase && this.theCase.id !== newCase.id;
    this.theCase = newCase;
    if (isOtherCase) {
      this.onRefresh();
    }
  }

  get case(): Case {
    return this.theCase;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  get ServiceTypeEnum() {
    return ServiceTypeEnum;
  }

  get BalanceSheetEventEnum() {
    return BalanceSheetEventEnum
  }

  private theCase: Case;
  reductionsCalculator = new ReductionsCalculator();
  totalSavingsAmount: number;
  totalSettledAmount: number;
  customerProfileId: number;
  caseCurrency = 'DKK';
  serviceOrders: ServiceOrderEconomyViewModel[];
  supplierExpensesAppendixes: SupplierExpensesAppendix[];
  expanded: boolean[];
  expandedRefund: boolean[];
  numberOfStatus = 9;
  today: Date;
  refundToday: moment.Moment;
  calculations = new Calculations();
  private providersNames: DropdownDictionary[];
  sortedOn: any = {};
  stakeholderTypesSubscr$: Subscription;
  caseSupplierInvoicesSubscr$: Subscription;
  caseStakeholderWithCaseStakeholderSubscr$: Subscription;
  $personInsuranceSubscr: Subscription;
  isMouseOverTheService: boolean = true;
  caseStakeholder: CaseStakeholder[];
  serviceItemList: ServiceItemType[];
  totaleServiceItemList: ServiceItemType[];
  caseDocuments: CaseDocument[];
  shortcutExpandAllSubscription: Subscription;
  allExpanded: boolean;
  coordinationCaseDetail: CoordinationCaseDetail[]
  coordinatonCaseStakeholders: CaseStakeholder[];
  private serviceOrderTypes: ServiceOrderType[];
  serviceOrderHoverModel: ServiceOrderHoverModel;
  caseIncident: CaseIncident;
  incidentEvent: string;

  constructor(
    private caseService: CaseService,
    private casePlansService: CasePlansService,
    private serviceOrderService: ServiceOrderService,
    private supplierInvoiceService: SupplierInvoiceService,
    private insuranceService: InsuranceService,
    private dictionaryService: DictionaryService,
    private supplierAdapter: SupplierDropDownAdapter,
    private dialogViewerService: CaseDialogViewerService,
    private refundService: RefundService,
    private permissionService: PermissionService,
    private documentService: DocumentService,
    private shortcutService: ShortcutService,
    private balanceSheetService: BalanceSheetService,
    public settingsService: SettingsService,
    private coordinationCaseService: CoordinationCaseService,
    private incidentService: IncidentService,
  ) {
    this.shortcutExpandAllSubscription = this.shortcutService.addShortcut({ keys: ShortcutEnum.EconomyServiceOrdersExpandAll }).subscribe(keyEvnt => {
      if ( this.allExpanded ) {
        this.collapseAll();
      } else {
        this.expandAll();
      }
    });
  }

  ngOnInit() {
    this.onRefresh();
  }

  private onRefresh() {
    this.getCaseDocuments();
    this.getServiceOrderTypes();

    this.$personInsuranceSubscr = this.insuranceService.getPersonInsurance(this.case.id).subscribe(
      result => {
        if (result) {
          this.customerProfileId = result.customerProfileId;
        }
      },
      error => console.log(error)
    );
    this.supplierInvoiceService.getServiceItemList().subscribe(serviceItemList => this.totaleServiceItemList = serviceItemList );

    if (this.theCase) {
      if (this.stakeholderTypesSubscr$) {
        this.stakeholderTypesSubscr$.unsubscribe();
      }
      this.stakeholderTypesSubscr$ = this.dictionaryService.getStakeholdersTypes().subscribe(
        stakeholderTypes => {
          if (this.caseStakeholderWithCaseStakeholderSubscr$) {
            this.caseStakeholderWithCaseStakeholderSubscr$.unsubscribe();
          }
          this.caseStakeholderWithCaseStakeholderSubscr$ = this.caseService.getCaseStakeholderWithCaseStakeholder().subscribe(
            stakeholders => {
              this.caseStakeholder = stakeholders;
              this.mockProvidersNames(stakeholders);
            },
            error => console.log(error)
          );
        },
        error => console.log(error)
      );
      this.serviceOrderService.getServiceOrdersForCase(Number(this.theCase.id)).subscribe(orders => {
        let serviceOrders = orders;
        this.expanded = [];
        this.totalSavingsAmount = null;
        this.totalSettledAmount = null;
        serviceOrders.sort((a, b) => (a.serviceOrderId > b.serviceOrderId ? 1 : -1));

        this.expanded = new Array(serviceOrders.length);

        if (serviceOrders.length > 0) {
          this.caseCurrency = serviceOrders[0].expectedCostCurrency;
        }

        if (this.caseSupplierInvoicesSubscr$) {
          this.caseSupplierInvoicesSubscr$.unsubscribe();
        }

        this.caseSupplierInvoicesSubscr$ = combineLatest([
          this.supplierInvoiceService.getCaseSupplierInvoices(),
          this.refundService.getCaseRefunds()
        ]).subscribe(([supplierInvoices, refunds]) => {
          for (const order of orders) {
            order.supplierInvoices = this.getSupplierInvoicesByServiceOrder(order, supplierInvoices);
          }
          for (const order of orders) {
            order.refunds = this.getRefundItemsByServiceOrder(order, refunds);
          }
          this.serviceOrders = this.initializeServiceOrderEconomyViewModel(serviceOrders);
          this.getSupplierExpensesAppendix();
        });

        if (this.serviceOrders.some(service => service.type === ServiceTypeEnum.COORDINATION_TRANSPORT)) {
          this.coordinationCaseService.getLinkedCasesDetails(this.theCase.id)
            .subscribe(linkedCaseDetails => {
              this.coordinationCaseDetail = linkedCaseDetails;
              this.coordinatonCaseStakeholders = this.updateCoordinatonCaseStakeholders(this.coordinationCaseDetail);
            });
        }
        if (this.serviceOrders.some(service => service.type === ServiceTypeEnum.MAJOR_INCIDENT)) {
          this.incidentService.getCaseIncident(this.case.incidentId).subscribe(result => {
            this.incidentEvent = result.incidentEvent;
          });
        }
      });
    }
  }

  private updateCoordinatonCaseStakeholders(coordinationCaseDetail: CoordinationCaseDetail[]): CaseStakeholder[]  {
    let coordinatonCaseStakeholders: CaseStakeholder[] = [];
    coordinationCaseDetail.forEach(caseDetail => {
      caseDetail.stakeholders.forEach(stakeholder => {
        coordinatonCaseStakeholders.push(stakeholder)
      })
    })
    return coordinatonCaseStakeholders;
  }

  private initializeServiceOrderEconomyViewModel(serviceOrders: ServiceOrder[]): ServiceOrderEconomyViewModel[] {
    let ServiceOrderEconomyViewModels = serviceOrders as ServiceOrderEconomyViewModel[];
    ServiceOrderEconomyViewModels.forEach(a => {
      a.invoiced = this.calculations.calculateServiceOrderInvoiced(a);
      a.saved = this.calculations.calculateServiceOrderSavings(a);
      a.settled = this.calculations.calculateServiceOrderSettled(a);
      a.commitmentTypeView = a.extension.getCommitmentType();
      a.expectedCostValueNumber = Number.parseFloat(a.expectedCostValue) === NaN ? 0 : Number.parseFloat(a.expectedCostValue);
      this.getServiceOrderProgress(a).subscribe(progress => a.progress = progress);
    });
    return ServiceOrderEconomyViewModels;
  }

  private getServiceOrderProgress(serviceOrder: ServiceOrderEconomyViewModel): Observable<BalanceSheetProgress> {
    return this.balanceSheetService.getServiceOrderEvents(this.theCase.id, serviceOrder.serviceOrderId)
      .pipe(map((serviceOrderProgress: BalanceSheetProgress) => {
        let events: BalanceSheetEvent[] = [
          { event: BalanceSheetEventEnum.EXPECTED, status: BalanceSheetStatusEnum.SUCCESS, text: StatusTypes.EXPECTED, modifierList: [] }
        ];
        let currentEvent = BalanceSheetEventEnum.EXPECTED;
        if ([StatusTypes.COMMITTED,StatusTypes.CANCELLED,StatusTypes.NOT_COVERED].includes(serviceOrder.status)) {
          events.push({ event: BalanceSheetEventEnum.COMMITMENT, status: BalanceSheetStatusEnum.SUCCESS, text: serviceOrder.status, modifierList: [] });
          currentEvent = BalanceSheetEventEnum.COMMITMENT;
        }
        events = events.concat(serviceOrderProgress?.events);
        if (serviceOrder.status === StatusTypes.CANCELLED || serviceOrder.status === StatusTypes.NOT_COVERED) {
          events.forEach(e => e.status = BalanceSheetStatusEnum.CANCELLED);
          if (events.length > 0) {
            events[events.length - 1].status = BalanceSheetStatusEnum.CANCELLED;
          }
        }
        if (!!serviceOrderProgress?.currentEvent) {
          currentEvent = serviceOrderProgress.currentEvent;
        }
        let aggregated = serviceOrderProgress?.aggregated;
        return { currentEvent, aggregated, events };
      }));
  }

  private getSupplierExpensesAppendix() {
    const medicalEscortExpenseItemType = Object.keys(MedicalEscortExpenseItemEnum);
    this.supplierInvoiceService
      .getExpenseAppendixByCase(Number(this.theCase.id))
      .pipe(take(1))
      .subscribe(supplierExpensesAppendixes => {
        for (const order of this.serviceOrders) {
          order.supplierExpensesAppendixes = supplierExpensesAppendixes.filter(
            expensesAppendix => expensesAppendix.serviceOrderId === order.serviceOrderId
          );
          if (order.type === ServiceTypeEnum.MEDICAL_ESCORT) {
            let expenseAmount = 0;
            let expenseSaving = 0;
            let expenseSettled = 0;
            let allApproved = true;
            order.supplierInvoices.forEach(si => {
              allApproved =
                allApproved &&
                (si.invoiceStatus === SupplierInvoiceStatus.approved ||
                  si.invoiceStatus === SupplierInvoiceStatus.supplierPaid);
              const expenseInvoiceItems = si.items.filter(item => medicalEscortExpenseItemType.indexOf(item.serviceItemCode) >= 0);
              expenseInvoiceItems.forEach(item => {
                expenseAmount += this.calculations.calculateItemAmount(si, item);
                expenseSaving += this.calculations.calculateItemSavings(si, item);
                expenseSettled += this.calculations.calculateItemSettled(si, item);
              });
              si.items = this.removeExpenseInvoiceItems(si.items, expenseInvoiceItems);
            });
            if (!order.supplierExpensesAppendixes || order.supplierExpensesAppendixes.length === 0) {
              this.createAppendixlessSupplierExpense(order, allApproved, expenseAmount, expenseSaving, expenseSettled);
            }
          }
        }
        this.calculateAggregations();
      });
  }

  private removeExpenseInvoiceItems(
    originalItems: SupplierInvoiceItem[],
    expenseInvoiceItems: SupplierInvoiceItem[]
  ): SupplierInvoiceItem[] {
    const reducedItems: SupplierInvoiceItem[] = [];
    originalItems.forEach(item => {
      if (expenseInvoiceItems.filter(exItem => exItem.id === item.id).length === 0) {
        reducedItems.push(item);
      }
    });
    return reducedItems;
  }

  private createAppendixlessSupplierExpense(
    order: ServiceOrder,
    allApproved: boolean,
    expenseAmount: number,
    expenseSaving: number,
    expenseSettled: number
  ) {
    let amountSavings: DineroObject;
    let amountSettled: DineroObject;
    if (allApproved) {
      amountSavings = { amount: expenseSaving, currency: this.caseCurrency as DineroFactory.Currency, precision: 2 };
      amountSettled = { amount: expenseSettled, currency: this.caseCurrency as DineroFactory.Currency, precision: 2 };
    }
    order.supplierExpensesAppendixes = [];
    order.supplierExpensesAppendixes.push(
      new SupplierExpensesAppendix({
        caseId: order.caseId,
        serviceOrderId: order.serviceOrderId,
        amountInvoice: { amount: expenseAmount, currency: this.caseCurrency as DineroFactory.Currency, precision: 2 },
        amountSavings,
        amountSettled,
      })
    );
  }

  private getSupplierInvoicesByServiceOrder(serviceOrder: ServiceOrder, supplierInvoices: SupplierInvoice[]): SupplierInvoice[] {
    const medicalEscortExpenseItemType = Object.keys(MedicalEscortExpenseItemEnum);
    const serviceOrderInvoices = [];
    if (!!supplierInvoices) {
      for (const invoice of supplierInvoices) {
        let addInvoice = false;
        addInvoice =
          invoice.invoiceStatus === SupplierInvoiceStatus.invoiceReceived && serviceOrder.serviceOrderId === invoice.serviceOrderId;
        addInvoice =
          addInvoice ||
          (invoice.invoiceStatus !== SupplierInvoiceStatus.invoiceReceived &&
            invoice.invoiceStatus !== SupplierInvoiceStatus.rejected &&
            invoice.items.filter(item => item.serviceOrderId === serviceOrder.serviceOrderId).length > 0);

        if (addInvoice) {
          const copyInvoice = new SupplierInvoice(invoice);
          if (serviceOrder.type == ServiceTypeEnum.MEDICAL_ESCORT) {
            copyInvoice.items = invoice.items.filter(item => medicalEscortExpenseItemType.indexOf(item.serviceItemCode) >= 0);
          }
          serviceOrderInvoices.push(copyInvoice);
        }
      }
    }
    return serviceOrderInvoices;
  }

  private getRefundItemsByServiceOrder(serviceOrder: ServiceOrder, refunds: Refund[]): ServiceOrderRefundViewModel[] {
    const medicalEscortExpenseItemType = Object.keys(MedicalEscortExpenseItemEnum);
    const serviceOrderRefundViewModels: ServiceOrderRefundViewModel[] = [];
    if (!!refunds) {
      refunds.forEach(refund => {
        if (![RefundStatus.REJECTED, RefundStatus.CANCELLED, RefundStatus.DELETED].includes(refund.refundStatus)) {
          let addRefund = false;
          let serviceOrderRefundViewModel = new ServiceOrderRefundViewModel();
          serviceOrderRefundViewModel.serviceItemName = [];
          serviceOrderRefundViewModel.reduction = 0;
          serviceOrderRefundViewModel.exchangedNetAmount = 0;
          serviceOrderRefundViewModel.originalAmount = 0;
          serviceOrderRefundViewModel.exchangedAmount = 0;
          serviceOrderRefundViewModel.serviceOrderRefundItemViewModel = [];
          serviceOrderRefundViewModel.refundStatus = undefined;
          const refundItemsNames: String[] = [];
          refund.items.forEach(item => {
            if(item.serviceOrderId === serviceOrder.serviceOrderId) {
              addRefund = true;
              serviceOrderRefundViewModel.originalAmount = serviceOrderRefundViewModel.originalAmount + (item.refundExchangedAmount * refund.getExchangeRate());
              serviceOrderRefundViewModel.exchangedAmount = serviceOrderRefundViewModel.exchangedAmount + item.refundExchangedAmount;
              serviceOrderRefundViewModel.reduction = serviceOrderRefundViewModel.reduction + (this.reductionsCalculator.getReductionExchangedAmountTotal(item) * refund.getExchangeRate());
              serviceOrderRefundViewModel.exchangedNetAmount = serviceOrderRefundViewModel.exchangedNetAmount + (this.reductionsCalculator.getExchangedNetAmount(item) * refund.getExchangeRate());
              const serviceOrderRefundItemViewModel = new ServiceOrderRefundItemViewModel();
              serviceOrderRefundItemViewModel.itemName = this.getServiceItemName(item.getServiceItemCode());
              serviceOrderRefundItemViewModel.amount = item.refundExchangedAmount * refund.getExchangeRate();
              serviceOrderRefundItemViewModel.amountExchanged = item.refundExchangedAmount;
              serviceOrderRefundItemViewModel.exchangeCurrency = refund.refundCurrency;
              serviceOrderRefundItemViewModel.reduction = this.reductionsCalculator.getReductionExchangedAmountTotal(item) * refund.getExchangeRate();
              serviceOrderRefundItemViewModel.settled = this.reductionsCalculator.getExchangedNetAmount(item) * refund.getExchangeRate();
              serviceOrderRefundViewModel.serviceOrderRefundItemViewModel.push(serviceOrderRefundItemViewModel);
              serviceOrderRefundViewModel.progress = refund.getProgress();
            }
          });
          if(addRefund) {
            serviceOrderRefundViewModel.serviceItemName = refundItemsNames.filter(function(elem, index, self) { return index === self.indexOf(elem); })
            serviceOrderRefundViewModel.id = refund.getId();
            serviceOrderRefundViewModel.refundCurrency = refund.refundCurrency;
            serviceOrderRefundViewModel.refundStatus = refund.refundStatus;
            serviceOrderRefundViewModel.dueDate = refund.dueDate;
            serviceOrderRefundViewModel.internalRemark = refund.internalRemark;
            serviceOrderRefundViewModels.push(serviceOrderRefundViewModel);
          }
        }
      });
    }
    return serviceOrderRefundViewModels;
  }

  getServiceItemName(serviceItemCode: string):string {
    if(this.totaleServiceItemList && serviceItemCode) {
      const text: ServiceItemType = this.totaleServiceItemList.find(serviceOrderItem => serviceOrderItem.code === serviceItemCode);
      return text ? text.name : '';
    } else {
      return '';
    }
  }

  private getCaseDocuments() {
    this.documentService.getCaseDocumentList(+this.theCase.id).subscribe(
      result => {
        this.caseDocuments = result;
      },
      error => console.log(error)
    );
  }

  private getServiceOrderTypes() {
    this.serviceOrderService.getServiceOrderTypesPerCase(this.theCase.id).subscribe(
      result => this.serviceOrderTypes = result
    );
  }

  private getServiceOrderStatusType(serviceOrder): StatusTypes {
    return serviceOrder.status;
  }

  removeAmountAndCurrency(serviceOrder: ServiceOrder): boolean {
    return  (this.isClaimCustomer(serviceOrder) ||
            this.getServiceOrderStatusType(serviceOrder) === StatusTypes.NOT_COVERED ||
            serviceOrder.type === ServiceTypeEnum.ADVICE_CALL_REGISTRATION ||
            serviceOrder.type === ServiceTypeEnum.ADVISE_ON_INSURANCE_TERMS ||
            serviceOrder.type === ServiceTypeEnum.GENERAL_ADVICE ||
            serviceOrder.type === ServiceTypeEnum.REFERRAL_TO_CUSTOMER ||
            (serviceOrder.extension.getCommitmentType() && serviceOrder.extension.getCommitmentType().businessKey === CommitmentTypes.EHIC_FULL_COVERAGE ))
  }

  private mockProvidersNames(stakeholders: CaseStakeholder[]) {
    if (stakeholders) {
      this.providersNames = stakeholders
        .filter(
          stakeholder =>
            stakeholder.stakeholderType !== StakeholderTypeEnum.endUser &&
            stakeholder.stakeholderType !== StakeholderTypeEnum.reporter &&
            stakeholder.stakeholderType !== StakeholderTypeEnum.policyHolder
        )
        .map(data => this.supplierAdapter.adapt(data))
        .filter(data => data.name != null);
    }
  }

  private calculateAggregations() {
    this.totalSettledAmount = 0;
    this.totalSavingsAmount = 0;
    for (const serviceOrder of this.serviceOrders) {
      this.totalSettledAmount += this.calculations.calculateServiceOrderSettled(serviceOrder);
      this.totalSavingsAmount += this.calculations.calculateServiceOrderSavings(serviceOrder);
    }
    if (this.totalSettledAmount === 0) {
      this.totalSettledAmount = null;
    }
    if (this.totalSavingsAmount === 0) {
      this.totalSavingsAmount = null;
    }
  }

  getServiceType(serviceOrder: ServiceOrder): string {
    return serviceOrder.type.toString();
  }

  getProviderName(serviceOrder: ServiceOrder): string {
    const supplierId = this.getSupplierId(serviceOrder);
    if (!!this.providersNames && supplierId != null) {
      const providerName = this.providersNames.find(p => p.id === supplierId.toString());
      if (providerName !== undefined) {
        return providerName.name;
      }
    }
    return '';
  }

  private getSupplierId(serviceOrder: ServiceOrder): number {
    return serviceOrder.supplierId;
  }

  getIconFileName(serviceOrder: ServiceOrder): string {
    return 'assets/icons/' + ServiceTypeEnumIcon.getIcon(serviceOrder.type);
  }

  mouseEnter(serviceOrder: ServiceOrder, index: number, event: MouseEvent) {
    this.isMouseOverTheService = true;
    setTimeout(() => {
      if (this.isMouseOverTheService) {
        this.serviceOrderHoverModel = this.initializeHoverModel(serviceOrder, index, event);
        this.casePlansService.sendHoverServiceOrder(this.serviceOrderHoverModel);
      }
    }, 100);
  }

  private initializeHoverModel(serviceOrder: ServiceOrder, index: number, event: MouseEvent) {
    const serviceOrderHoverModel: ServiceOrderHoverModel = new ServiceOrderHoverModel();
    serviceOrderHoverModel.serviceOrder = serviceOrder;
    serviceOrderHoverModel.serviceOrder.laneNumber = 0;
    if (!serviceOrderHoverModel.serviceOrder.extension.displayDurationId) {
      serviceOrderHoverModel.serviceOrder.extension.displayDurationId = 0;
    }
    serviceOrderHoverModel.topHoverOffset = 0;
    serviceOrderHoverModel.serviceLeftPosition = 50;
    serviceOrderHoverModel.serviceTopPosition = event.pageY - 300;
    serviceOrderHoverModel.serviceWidth = 0;
    serviceOrderHoverModel.serviceTypeIcon = ServiceTypeEnumIcon.getIcon(serviceOrderHoverModel.serviceOrder.type);
    serviceOrderHoverModel.providerName = this.getProviderName(serviceOrder);
    serviceOrderHoverModel.incidentEvent = this.incidentEvent;
    let supplierId  = this.getSupplierId(serviceOrder);
    serviceOrderHoverModel.agentName = this.getAgentName(supplierId);
    return serviceOrderHoverModel;
  }

  getAgentName(supplierId: number): string {
    const supplier = this.caseStakeholder.find(s => +s.id === supplierId);

    if (supplier && supplier.company.gop?.agentId) {
      const agent = this.caseStakeholder.find(s => +s.id === supplier.company.gop.agentId);
      return agent?.company?.name || null;
    }
    return null;
  }

  mouseLeave() {
    this.isMouseOverTheService = false;
    this.casePlansService.sendHoverServiceOrder(undefined);
    this.serviceOrderHoverModel = null;
  }

  get getRefundStatus() {
    return RefundStatus;
  }

  get ServiceOrderStatus() {
    return ServiceOrderStatus;
  }

  expandAll() {
    this.allExpanded = true;
    this.serviceOrders?.forEach((s,i) => this.expanded[i] = true);
  }

  collapseAll() {
    this.allExpanded = false;
    this.serviceOrders?.forEach((s,i) => this.expanded[i] = false);
  }

  expand(i: number) {
    this.expanded[i] = this.expanded[i] !== true;
  }

  expandRefund(i: number) {
    this.expandedRefund[i] = this.expandedRefund[i] !== true;
  }

  isExpanded(i: number) {
    return i >= 0 && this.expanded[i] && this.showExpandIcon(this.serviceOrders[i]);
  }

  openManageInvoice(invoiceNumber: number) {
    this.dialogViewerService.openManageSupplierInvoiceDialog(
      invoiceNumber,
      this.customerProfileId,
      this.case.caseNumber,
      this.case.caseCreatedDate,
      supplierInvoice => this.supplierInvoiceSaved(supplierInvoice)
    );
  }

  supplierInvoiceSaved(supplierInvoice: SupplierInvoice) {
    const supplierInvoiceId = supplierInvoice.id;

    this.supplierInvoiceService
      .getCaseSupplierInvoices()
      .pipe(first())
      .subscribe(supplierInvoices => {
        const postsave: SupplierInvoice[] = [];
        for (const invoice of supplierInvoices) {
          if (invoice.id === supplierInvoiceId) {
            postsave.push(supplierInvoice);
            this.supplierInvoiceService
              .getSupplierInvoiceHistory(supplierInvoiceId)
              .pipe(first())
              .subscribe(history => supplierInvoice.progress = history);
          } else {
            postsave.push(invoice);
          }
        }
        this.supplierInvoiceService.sendCaseSupplierInvoices(postsave);
      });
  }

  refundDeleted(this: any, refundId: number) {
    let newRefunds: Refund[] = [];
    this.refundService
        .getCaseRefunds()
        .pipe(first())
        .subscribe(refunds => {
          newRefunds = refunds;
          newRefunds.forEach((refund, index) => {
            if(refund.id === refundId) {
              newRefunds.splice(index, 1);
            }
          });
          newRefunds.sort(function(a,b) { return (a.id - b.id); });
          this.refundService.sendCaseRefunds(newRefunds);
        });
  }

  refundSaved(this: any, id: number, isDeleted: boolean){
    let savedRefund: Refund;
    let oldRefunds: Refund[] = [];
    this.refundService.getRefundById(id).subscribe(
      result => {
        savedRefund = result;
        this.refundService
        .getCaseRefunds()
        .pipe(first())
        .subscribe(refunds => {
          oldRefunds = refunds;
          const postsave: Refund[] = [];
          oldRefunds.forEach(oldRefund => {
            if(oldRefund.id === savedRefund.id) {
              this.refundService
              .getRefundHistory(savedRefund.id)
              .pipe(first())
              .subscribe(history => {
                savedRefund.progress = history
                postsave.push(savedRefund);
              });
            } else {
              postsave.push(oldRefund);
            }
          });
          setTimeout(() => {
            postsave.sort(function(a,b) { return (a.id - b.id); });
            this.refundService.sendCaseRefunds(postsave);
          }, 500);
        });
      });
  }

  get SupplierInvoiceStatus() {
    return SupplierInvoiceStatus;
  }

  getSupplierInvoiceItemsForServiceOrder(serviceOrder: ServiceOrder, supplierInvoice: SupplierInvoice): SupplierInvoiceItem[] {
    return supplierInvoice.items.filter(si => si.serviceOrderId === serviceOrder.serviceOrderId);
  }

  showExpandIcon(serviceOrder: ServiceOrder): boolean {
    return (serviceOrder && (serviceOrder.supplierInvoices.length > 0 || serviceOrder.type === ServiceTypeEnum.MEDICAL_ESCORT || serviceOrder.refunds.length > 0));
  }

  isClaimCustomer(serviceOrder: ServiceOrder): boolean {
    if (!serviceOrder.extension.getCommitmentType()) {
      return false;
    }
    return  !serviceOrder.extension.getCommitmentType().sosClaims && serviceOrder.extension.getCommitmentType().refund;
  }

  isClaimSOS(serviceOrder: ServiceOrder): boolean {
    if (!serviceOrder.extension.getCommitmentType()) {
      return false;
    }
    return  serviceOrder.extension.getCommitmentType().sosClaims && serviceOrder.extension.getCommitmentType().refund;
  }


  isRefundApproved(refunds: ServiceOrderRefundViewModel): boolean {
    return (
      refunds.refundStatus === RefundStatus.APPROVED.toString() ||
      refunds.refundStatus === RefundStatus.PAID.toString()
    );
  }

  sortedBy(column: string): string {
    return this.sortedOn[column] ? 'sorted-by' : '';
  }

  sortBy(column: string) {
    this.sortedOn = {};
    this.sortedOn[column] = true;
  }

  openManageRefund(refundId: number) {
    let readOnly = false;
    if (!this.permissionService.checkUserPermission(PermissionEnum.REFUND_UPDATE)) {
      readOnly = true;
    }
    this.dialogViewerService.openManageClaimsDialog(this.case.id, false, this.customerProfileId.toString(),refundId.toString(), readOnly, this.case.caseCreatedDate, this.refundSaved.bind(this, refundId, false), this.refundDeleted.bind(this, refundId, false));
  }

  openServiceOrder(serviceOrderId: number) {
    const dialogRef = this.dialogViewerService.openServiceOrderDialog(serviceOrderId, this.case.id, this.case.caseNumber, this.case.incidentId);
    dialogRef.afterClosed().subscribe(() => {
      this.onRefresh();
    });
  }

  openDocumentInEmbeddedView(document: CaseDocument) {
    if (this.containsFileScanInformation(document)){
      return;
    }

    const attachment = new AttachmentDto({
      documentId: document.documentId,
      documentName: document.documentName,
      documentExtension: document.documentExtension
    });

    this.dialogViewerService.openCaseDocumentDialog(+this.case.id, this.case.caseNumber, attachment, null, true);
  }

  getServiceOrderExpenseDocuments(serviceOrder): CaseDocument[] {
    const appendixDocuments = serviceOrder.supplierExpensesAppendixes?.
                                           filter(expense => !!expense.documentId).
                                           map(expense => this.caseDocuments?.find(c => c.documentId === expense.documentId)).
                                           filter(expense => expense !== null);

    return appendixDocuments || [];
  }

  containsFileScanInformation(document: CaseDocument) : boolean {
    return !!document?.fileScan && (!document.fileScan?.scanned || document.fileScan?.hit);
  }

  getDocumentName(document: CaseDocument) {
    return document ? `${document.documentName}.${document.documentExtension}` : '';
  }

  getFileScanIcon(document: CaseDocument) {
    const fileScan = document?.fileScan;

    if (!fileScan?.scanned) {
      return '/assets/icons/question-mark-red.svg';
    } else if (fileScan?.hit) {
      return '/assets/icons/Warning-filescan.svg';
    }
  }

  hoverText(serviceOrder: ServiceOrder) {
    let text = null;
    if (serviceOrder.extension.getCommitmentType()){
      text = serviceOrder.extension.getCommitmentType().name;
    }

    return text;
  }

  getEventsToDisplay(): BalanceSheetEventEnum[] {
    return Object.keys(BalanceSheetEventEnum).map(key => BalanceSheetEventEnum[key]);
  }

  getHiddenEvents(serviceOrder: ServiceOrderEconomyViewModel): BalanceSheetEventEnum[] {
    if(!this.serviceOrderTypes) {
      return [];
    }
    const serviceOrderType = this.serviceOrderTypes.find(t => ServiceTypeEnum[t.serviceTypeEnumValue] === serviceOrder.type);
    if (!serviceOrderType?.economyApplicable) {
      return [BalanceSheetEventEnum.INITIAL, BalanceSheetEventEnum.AWAITING, BalanceSheetEventEnum.UPDATED, BalanceSheetEventEnum.ONGOING, BalanceSheetEventEnum.HANDLED, BalanceSheetEventEnum.CREDITOR_PAID, BalanceSheetEventEnum.INVOICING];
    }
    return [];
  }
}
