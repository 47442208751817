<div class="common-drop-down-arrow drop-down">
  <span class="name">{{name}}</span>
  <span *ngIf="required" class="required-red">*</span>
  <ng-select [searchFn]="searchByItemName" [items]="items" (blur)="onSaveModel()" (focus)="onSaveState()" (close)="onClose()"
    [clearable]="false" [(ngModel)]="selectedItemId" [appendTo]="appendTo"
    class="custom" bindValue="id" [disabled]="!!disabled" [closeOnSelect]="closeOnSelect" [placeholder]="placeholder" (change)="onChange()">
      <ng-template ng-option-tmp let-item="item" let-index="index">
       <div> <img [src]="item.iconPath" class="drop-down-icon" *ngIf="item.iconPath"/>
        <span
          [ngbPopover]=" item.hoverText | translate"
          triggers="hover"
          placement="right"
          popoverClass="commit-popover"
          container="body">
          {{ item.name }}
        </span>        
       </div>
      </ng-template>
      <ng-template ng-label-tmp let-item="item">
         <div> <img [src]="item.iconPath" class="drop-down-icon" *ngIf="item.iconPath" />
          <span
            [ngbPopover]="item.hoverText | translate"
            triggers="hover"
            placement="right"
            popoverClass="commit-popover"
            container="body">
            {{ item.name }}
          </span>           
         </div>
      </ng-template>
  </ng-select>
</div>
