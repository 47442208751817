import {ServiceOrder} from './../../../../../shared/models/service-order/service-order';
import {FaxDto} from './../../../../../shared/models/fax-dto';
import {PhoneDto} from './../../../../../shared/models/phone-dto';
import {StakeholderCcDto} from './../components/message-dialog/message-subject/message-form-cc/stakeholder-cc-dto';
import {AttachmentDto} from './../../../../../shared/models/attachment-dto';
import {CaseStakeholder} from 'src/app/shared/models/caseStakeholder';
import {MessageChannelType, MessageFrom, PhoneNumberType} from './../../../../../shared/models/enums';
import {StakeholderService} from '@secca/core/services/stakeholder.service';
import {Injectable} from '@angular/core';
import {PostHandlingsDto} from '@secca/shared/models/post-handlings-dto';

export class Message {
  caseId: string;
  messageChannelType: MessageChannelType;
  template: string;
  templateId: string;
  templateDisplayName: string;
  language: string;
  email: string;
  fax: FaxDto;
  sms: PhoneDto;
  receiver: CaseStakeholder;
  internalSummary: string;
  caseNumber: string;
  refundId: number;
  recoveryId: number;
  subject = '';
  subjectLock: boolean;
  cc: CaseStakeholder[];
  attachments: AttachmentDto[];
  text: any;
  send: boolean;
  replyEmailId: string;
  replyWebRequestData: any;
  stakeholderReceiverId: number;
  channelDisabled: boolean;
  templateType: string;
  legalGuardianOnly: boolean;
  gopContactPersonShouldBeChosen: boolean;
  afterMaximazingKeepTheLanguageIntact: boolean;
  requestConfirmNeededForServiceOrder: ServiceOrder;
  receiverIsNotChangable: boolean;
  messageFrom: MessageFrom;
  feature: string;
  consentRequest: string;
  preSelectDocumentAttachments: AttachmentDto[];
  tags: string[];

  //posthandlings after email is sent. Executed in backend
  postHandlings: PostHandlingsDto;


  public constructor(init?: Partial<Message>) {
    Object.assign(this, init);
  }
}


@Injectable({
  providedIn: 'root'
})
export class MessageUtilService {

  constructor(private stakeholderService: StakeholderService) {
  }

  public getStakeholderCcDtoItems(message: Message): StakeholderCcDto[] {
    return message.cc ? message.cc.map(stakeholder => {
      const thisMail = stakeholder.isCompany ? stakeholder.company.email : stakeholder.person.email;

      let address = '';
      if (message.messageChannelType === MessageChannelType.EMAIL && thisMail) {
        address = thisMail;
      }
      else if (message.messageChannelType === MessageChannelType.FAX && this.stakeholderService.getPhoneNumber(PhoneNumberType.Fax, stakeholder.phoneNumbers).number) {
        address = this.stakeholderService.getPhoneNumber(PhoneNumberType.Fax, stakeholder.phoneNumbers).prefix + this.stakeholderService.getPhoneNumber(PhoneNumberType.Fax, stakeholder.phoneNumbers).number;
      }
      else if (message.messageChannelType === MessageChannelType.SMS &&
               this.stakeholderService.getPhoneNumber(PhoneNumberType.Mobile, stakeholder.phoneNumbers).number) {
        address = this.stakeholderService.getPhoneNumber(PhoneNumberType.Mobile, stakeholder.phoneNumbers).prefix +
                  this.stakeholderService.getPhoneNumber(PhoneNumberType.Mobile, stakeholder.phoneNumbers).number;
      }

      return new StakeholderCcDto({
        id: stakeholder.id,
        name: stakeholder.company ? stakeholder.company.name : stakeholder.person?.firstName + ' ' + stakeholder.person?.surname,
        address: address
      });
    }).filter(ccDto => !!ccDto.address) : [];
  }
}
