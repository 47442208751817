import { DineroObject } from 'dinero.js';
import { FeeTypeEnum } from './enums';
import { Adapter } from '../interfaces/adapter';
import { SalesOrderStatus } from '../enums/sales-order-status';
import { Injectable } from '@angular/core';
import { EconomyHistory } from './refund-history';
import { DateSerializer } from './date-serializer';

export class SalesOrderItem implements EconomyHistory{
  id: number;
  supplierInvoiceItemId: number;
  lineNumber: number;
  quantity: number;
  sosServiceId: string;
  sosServiceName: string;
  feeType: FeeTypeEnum;
  feeName: string;
  appliedDate: Date;
  agreementPrice: DineroObject;
  invoicePrice: DineroObject;
  vatPrice: DineroObject;
  billable: boolean;
  credited: boolean;
  creditNote: boolean;
  invoiced: boolean;
  type: string;
  actionComment: string;
  suggestion: boolean;
  status: SalesOrderStatus;
  sosServicesCategory: string;
  serviceOrderId: string;
  vat: boolean;
  modifiedBy: string;
  modifiedOn: moment.Moment;
  deletedBy: string;
  deletedOn: moment.Moment;
  salesOrderId: number;

  public constructor(init?: Partial<SalesOrderItem>) {
    Object.assign(this, init);
  }

  identicalHistory(histObject: SalesOrderItem): boolean {
    return this.feeType == histObject.feeType &&
        this.appliedDate == histObject.appliedDate &&
        this.agreementPrice == histObject.agreementPrice &&
        this.invoicePrice == histObject.invoicePrice &&
        this.actionComment == histObject.actionComment &&
        this.suggestion == histObject.suggestion &&
        this.sosServicesCategory == histObject.sosServicesCategory &&
        this.serviceOrderId == histObject.serviceOrderId;
  }

}
@Injectable({
  providedIn: 'root',
})
export class SalesOrderItemAdapter implements Adapter<SalesOrderItem> {
  adapt(item: any): SalesOrderItem {
    return new SalesOrderItem({
      id: item.id,
      salesOrderId: item.salesOrderId,
      supplierInvoiceItemId: item.supplierInvoiceItemId,
      lineNumber: item.lineNumber,
      sosServiceId: item.sosServiceId,
      sosServiceName: item.feeName,
      feeType: FeeTypeEnum[item.feeType],
      feeName: item.feeName,
      appliedDate: item.appliedDate,
      agreementPrice: item.agreementPrice,
      invoicePrice: item.invoicePrice,
      vatPrice: item.vatPrice,
      billable: item.billable,
      credited: item.credited,
      creditNote: item.creditNote,
      type: item.type,
      actionComment: item.actionComment,
      suggestion: item.suggestion,
      sosServicesCategory: item.sosServicesCategory,
      serviceOrderId: item.serviceOrderId,
      vat: item.vat,
      quantity: item.quantity,
      modifiedOn: item.modifiedOn ? DateSerializer.deserialize(item.modifiedOn) : null,
      deletedOn: item.deletedOn ? DateSerializer.deserialize(item.deletedOn) : null,
      deletedBy: item.deletedBy,

    });
  }
}
