<div class="cases-container">
    <div class="primary-table-container">
      <table class="primary-table fixed-header-table">
        <thead>
          <tr>
            <td (click)="sortBy('customerProfileName')" [ngClass]="sortedBy('customerProfileName')">{{"board-case-table-customer" | translate}}</td>
            <td (click)="sortBy('caseNumber')" [ngClass]="sortedBy('caseNumber')">{{"board-case-table-case-number" | translate}}</td>
            <td (click)="sortBy('endUserFullName')" [ngClass]="sortedBy('endUserFullName')">{{"board-case-table-end-user-name" | translate}}</td>
            <td (click)="sortBy('dateOfBirth')" [ngClass]="sortedBy('dateOfBirth')">{{"board-case-table-age" | translate}}</td>
            <td (click)="sortBy('incidentCountry')" [ngClass]="sortedBy('incidentCountry')">{{"board-case-table-incident-country" | translate}}</td>
            <td (click)="sortBy('caseDetailsDiagnosis')" [ngClass]="sortedBy('caseDetailsDiagnosis')">{{"board-case-table-main-diagnosis" | translate}}</td>
            <td (click)="sortBy('reserve')" [ngClass]="sortedBy('reserve')">{{"board-case-table-reserve" | translate}}</td>
            <td (click)="sortBy('caseHandler')" [ngClass]="sortedBy('caseHandler')">{{"board-case-table-medical-case-handler" | translate}}</td>
            <td (click)="sortBy('assignedOn')" [ngClass]="sortedBy('assignedOn')">{{"board-case-table-case-assigned-on" | translate}}</td>
            <td (click)="sortBy('medicalAssessmentPublished')" [ngClass]="sortedBy('medicalAssessmentPublished')">{{"board-case-table-case-last-publish" | translate}}</td>
            <td (click)="sortBy('followUpDate')" [ngClass]="sortedBy('followUpDate')" >{{"board-case-table-case-next-assessment" | translate}}</td>
            <td (click)="sortBy('teamStatus')" [ngClass]="sortedBy('teamStatus')">{{"board-case-table-medical-team-status" | translate}}</td>
            <td>{{"board-case-table-medical-case-details" | translate}}</td>
            <td (click)="sortBy('readyFrom')" [ngClass]="sortedBy('readyFrom')" >{{"board-case-table-medical-ready-from" | translate}}</td>
          </tr>
        </thead>
        <tbody (scroll)="onCaseTabelScroll()" id="medicalCaseTabel" [class.zoomed]="settingsService.isZoomed()">
          <tr *ngFor="let boardMedicalCase of boardMedicalCases" (click)="redirectToCase(boardMedicalCase.id)" [routerLink]="['/case',boardMedicalCase.id]">
            <td class="customer-image-column">
              <img class="customer-image" *ngIf="boardMedicalCase.customerProfileId"
                [src]="getBrandImagesUrlForProfile(boardMedicalCase.customerProfileId)"
                [ngbPopover]="boardMedicalCase.customerProfileName"
                placement="auto"
                triggers="hover">
            </td>
            <td>
              <span [ngClass]="{'case-locked': boardMedicalCase.caseLockedBy && boardMedicalCase.caseLockedBy != loggedInUser.id}">{{boardMedicalCase.caseNumber}}</span>
            </td>
            <td>
              {{boardMedicalCase.endUserFullName}}
            </td>
            <td class="text-center pe-2">
              {{boardMedicalCase.age}}
            </td>
            <td class="long-text-field" >
              <span [ngbPopover]="boardMedicalCase.incidentCountry" placement="auto" triggers="hover">{{boardMedicalCase.incidentCountry}}</span>
            </td>
            <td class="long-column">
              {{boardMedicalCase.caseDetailsDiagnosis }}
            </td>
            <td class="reserve-column">
              <span
                *ngIf="boardMedicalCase.caseNumber">{{boardMedicalCase.reserve == null ?  0 : boardMedicalCase.reserve | numberFormat }}
                {{boardMedicalCase.billingCurrency}}</span>
            </td>
            <td>
              {{boardMedicalCase.caseHandler || caseHandlerTeamNameShortName(boardMedicalCase.caseHandlerTeamName)}}
            </td>
            <td class="end-user-column">
              {{boardMedicalCase.assignedOn  | date :'dd MMM HH:mm'}}
            </td>
            <td class="end-user-column">
              {{boardMedicalCase.medicalAssessmentPublished | date :'dd MMM HH:mm'}}
            </td>
            <td class="ps-2">
              {{boardMedicalCase.followUpDate  | date :'dd MMM HH:mm'}}
            </td>
            <td>
              {{ !!boardMedicalCase.teamStatus ? ('HANDLING-AREA-' + boardMedicalCase.teamStatus | translate) : ''}}
            </td>
            <td>
              <div *ngIf="boardMedicalCase.caseDetailsTBD === true" class="case-status case-details-mortal"> {{"board-task-table-case-details-TBD" | translate}} </div>
              <div *ngIf="boardMedicalCase.caseDetailsURN === true" class="case-status case-details-mortal"> {{"board-task-table-case-details-URN" | translate}} </div>
              <div *ngIf="boardMedicalCase.caseDetailsCOF === true" class="case-status case-details-mortal"> {{"board-task-table-case-details-COF" | translate}} </div>
              <div *ngIf="boardMedicalCase.caseDetailsTBD !== true && boardMedicalCase.caseDetailsURN !== true && boardMedicalCase.caseDetailsCOF !== true ">
                <span *ngIf="boardMedicalCase.inpatientOutpatientOnCase !== null && boardMedicalCase.inpatientOutpatientActive !== null && showMedicalServiceOrderType(boardMedicalCase)">
                  <div *ngIf="boardMedicalCase.inpatientOutpatientOnCase === PatientType.INPATIENT" class="case-status"
                    [ngClass]="{ 'case-details-treatment-service-order-inpatient' : boardMedicalCase.inpatientOutpatientActive === true,
                                 'case-details-treatment-service-order-inactive' :  boardMedicalCase.inpatientOutpatientActive === false } ">
                      {{"board-task-table-case-details-INPATIENT" | translate}}
                  </div>
                  <div *ngIf="boardMedicalCase.inpatientOutpatientOnCase === PatientType.OUTPATIENT" class="case-status"
                    [ngClass]="{ 'case-details-treatment-service-order-outpatient' : boardMedicalCase.inpatientOutpatientActive === true,
                                 'case-details-treatment-service-order-inactive' :  boardMedicalCase.inpatientOutpatientActive === false } ">
                      {{ "board-task-table-case-details-OUTPATIENT" | translate }}
                  </div>
                </span>
                <span *ngIf="showMedicalServiceOrderType(boardMedicalCase)">
                  <div *ngIf="boardMedicalCase.medicalServiceorderType === CommitmentTypes.REGULAR_MEDICAL_ASSESSMENT" class="case-status case-details-medical-service-order"> {{"board-task-table-case-details-REGULAR" | translate}} </div>
                  <div *ngIf="boardMedicalCase.medicalServiceorderType === CommitmentTypes.LIGHT_MEDICAL_REVIEW" class="case-status case-details-medical-service-order"> {{"board-task-table-case-details-LIGHT" | translate}} </div>
                  <div *ngIf="boardMedicalCase.medicalServiceorderType === CommitmentTypes.NIGHT_SHIFT_MEDICAL_ASSESSMENT" class="case-status case-details-medical-service-order"> {{"board-task-table-case-details-NIGHT" | translate}} </div>
                  <div *ngIf="boardMedicalCase.medicalServiceorderType === CommitmentTypes.CLAIMS_MEDICAL_REVIEW" class="case-status case-details-claims-service-order"> {{"board-task-table-case-details-CLAIMS" | translate}} </div>
                </span>
                <div *ngIf="boardMedicalCase.caseDetailsAIR === true" class="case-status case-details-repatriation-form"> {{"board-task-table-case-details-AIR" | translate}} </div>
                <div *ngIf="boardMedicalCase.caseDetailsSTR === true" class="case-status case-details-repatriation-form"> {{"board-task-table-case-details-STR" | translate}} </div>
                <div *ngIf="boardMedicalCase.caseDetailsEXT === true" class="case-status case-details-repatriation-form"> {{"board-task-table-case-details-EXT" | translate}} </div>
                <div *ngIf="boardMedicalCase.caseDetailsSIT === true" class="case-status case-details-repatriation-form"> {{"board-task-table-case-details-SIT" | translate}} </div>
                <div *ngIf="boardMedicalCase.caseDetailsREG === true" class="case-status case-details-repatriation-form"> {{"board-task-table-case-details-REG" | translate}} </div>
                <div *ngIf="boardMedicalCase.caseDetailsDOC === true || boardMedicalCase.caseDetailsNUR === true || boardMedicalCase.caseDetailsNME === true" class="case-status case-details-escort"> {{ caseDetailsEscort(boardMedicalCase) }} </div>
                <div *ngIf="boardMedicalCase.caseDetailsOXY === true" class="case-status case-details-oxygen"> {{"board-task-table-case-details-OXY" | translate}} </div>
                <div *ngIf="boardMedicalCase.caseDetailsEQU === true" class="case-status case-details-equipment"> {{"board-task-table-case-details-EQU" | translate}} </div>
              </div>
            </td>
            <td>
              <div *ngIf="boardMedicalCase.caseDetailsTBD === false && boardMedicalCase.caseDetailsURN === false && boardMedicalCase.caseDetailsCOF === false ">
                {{boardMedicalCase.readyFrom | date: 'dd MMM YYYY' }}
                <div *ngIf="boardMedicalCase.arrivalDeadline" [ngbPopover]="'board-task-table-arrival-deadline-popover-text' | translate: {arrivalDeadline: boardMedicalCase.arrivalDeadline | date | lowercase}" placement="auto" triggers="hover" class="aarrival-deadline">
                  {{boardMedicalCase.arrivalDeadline | date: 'dd MMM YYYY' }}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
