import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LockContextEnum } from '@secca/shared/enums/lock-context.enum';
import { CaseNotLockedError } from '@secca/shared/models/case-not-locked.error';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { BaseService } from './base.service';
import { CaseLockHelperService } from './case-lock-helper.service';
import { SettingsService } from './settings.service';

@Injectable({
    providedIn: 'root'
})
export class CaseLockHttpService extends BaseService {
    private static readonly defaultOptions = {
        headers: new HttpHeaders().set('Content-Type', 'application/json')
    };

    private lockContext: LockContextEnum;
    private contextId: number;

    constructor(private http: HttpClient,
                private settingsService: SettingsService,
                private caseLockHelperService: CaseLockHelperService) {
        super(settingsService);
    }

    public get<T>(url: string, options?: any): Observable<any> {
        return this.http.get<T>(`${this.toAbsUrl(url)}`, Object.assign(CaseLockHttpService.defaultOptions, options));
    }

    public post<T = void>(url: string, body: string | object, options?: any): Observable<any> {
        return this.genericUpdate<T>('post', url, body, options);
    }

    public put<T>(url: string, body: string | object, options?: any): Observable<any> {
        return this.genericUpdate<T>('put', url, body, options);
    }

    public patch<T = void>(url: string, body: string | object, options?: any): Observable<any> {
        return this.genericUpdate<T>('patch', url, body, options);
    }

    public delete<T>(url: string, options?: any): Observable<any> {
        const lockContext = this.lockContext || LockContextEnum.CASE_BASIC;
        const contextId = this.contextId;
        this.lockContext = null;
        this.contextId = null;

        return this.caseLockHelperService.checkHasCaseLock(lockContext, contextId).pipe(
            switchMap(() => {
                return this.http.delete<T>(`${this.toAbsUrl(url)}`, options);
            }),
            catchError(err => {
                if ( err instanceof CaseNotLockedError ) {
                    console.log(`Case was not locked when trying to perform delete operation, url: ${url}`);
                }
                return throwError(err);
            })
        );
    }

    public genericUpdate<T>(method: 'post' | 'put' | 'patch', url: string,
                            body: string | object, options?: any): Observable<any> {
        const lockContext = this.lockContext || LockContextEnum.CASE_BASIC;
        const contextId = this.contextId;
        this.lockContext = null;
        this.contextId = null;

        return this.caseLockHelperService.checkHasCaseLock(lockContext, contextId).pipe(
            switchMap(() => {
                    return this.http[method]<T>(`${this.toAbsUrl(url)}`, body,
                                                  Object.assign(CaseLockHttpService.defaultOptions, options));
            }),
            catchError(err => {
                if ( err instanceof CaseNotLockedError ) {
                    console.log(`Case was not locked when trying to perform ${method} operation, url: ${url}`);
                }
                return throwError(err);
            })
        );
    }

    public withLockContext(lockContext: LockContextEnum, contextId?: number): CaseLockHttpService {
        this.lockContext = lockContext;
        this.contextId = contextId;
        return this;
    }

    private toAbsUrl(url: string): string {
        if ( url.startsWith('http') ) {
            return url;
        } else {
            return this.baseURL + url;
        }
    }
}
