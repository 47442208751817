import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
export class DocumentTemplate {
  templateId: string;
  templateName: string;
  templateType: string;
  templateCountry: string;
  templateLanguage: string;

  public constructor(init?: Partial<DocumentTemplate>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class DocumentTemplateAdapter implements Adapter<DocumentTemplate> {

  adapt(item: any): DocumentTemplate {
    return new DocumentTemplate({
      templateId: item.templateId,
      templateName: item.templateName,
      templateType: item.templateType,
      templateCountry: item.templateCountry,
      templateLanguage: item.templateLanguage
    });
  }
}
