import { SupplierInvoiceService } from './../../../../core/services/supplier-invoice.service';
import { TranslateService } from '@ngx-translate/core';
import { TaskViewModel } from './models/task-view.model';
import { NgbModal, NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { SettingsService } from './../../../../core/services/settings.service';
import { TableSort } from './../../../../shared/models/table-sort';
import { BoardShareService } from './../../../../core/services/board-share.service';
import { TaskFilterResponse } from './models/task-filter-response.model';
import { TaskFilterRequest, CustomerProfileIdsForSearch } from './models/task-filter-request.model';
import { BoardTeamCaringService } from './../board-team-caring/board-team-caring.service';
import { TaskStateService } from './services/task-state.service';
import {
  PermissionEnum,
  PermissionHideTypeEnum,
  RefundStatus,
  ServiceTypeEnum,
  SortOrder,
  SupplierInvoiceStatus,
  TaskStatus,
  TaskType,
  ValidationTypeEnum,
  ShortcutEnum,
  MedicalPreAssessmentStatusEnum,
  HandlingAreaStatusEnum,
  RecoveryStatus, RecoveryPaymentStatus
} from "@secca/shared/models/enums";
import {AfterViewInit, Component, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {AutoUnsubscribe} from 'src/app/shared/decorators/auto-unsubscribe';
import {SupplierInvoiceTaskViewModel} from './models/supplier-invoice-task-view.model';
import {SupplierInvoiceTaskFilterRequest} from './models/supplier-invoice-task-filter-request.model';
import {DropdownDictionary} from 'src/app/shared/models/dropdownDictionary';
import {ServiceOrderService} from 'src/app/core/services/service-order.service';
import {IncomingCasesTaskFilterResponse} from '@secca/board/components/board-task/models/incoming-cases-task-filter-response.model';
import {ChannelType} from '@secca/shared/enums/channelType';
import * as moment from 'moment';
import {SupplierInvoiceTaskFilterResponse} from './models/supplier-invoice-task-filter-response.model';
import {RefundTaskFilterRequest} from '@secca/board/components/board-task/models/refund-task-filter-request.model';
import {RefundTaskFilterResponse} from './models/refund-task-filter-response.model';
import { interval, Subject, Subscription, throwError } from 'rxjs';
import {TaskService} from '@secca/core/services/task.service';
import {ScreeningStates} from '@secca/shared/enums/screening-states';
import {SupplierProfileService} from '@secca/core/services/supplier-profile.service';
import {TaskCountFilterRequest} from '@secca/board/components/board-task/models/task-count-filter-request.model';
import {TaskCountResponse} from '@secca/board/components/board-task/models/task-count-filter-response.model';
import {HelperService} from '@secca/core/services/helper.service';
import { ShortcutService } from '@secca/core/services/shortcut.service';
import { SessionStorageDateDeserializer } from '@secca/shared/models/session-storage-date-deserializer';
import { MomentHelperService } from '@secca/core/services/moment-helper.service';
import { MasterListService } from '@secca/core/services/masterlist-service';
import { IncidentEvent } from '@secca/shared/models/incidentEvent';
import { CausesLevel1DD } from '@secca/shared/models/CausesLevel1DD';
import { IncidentService } from '@secca/core/services/incident.service';
import { DropDownComponent } from '@secca/shared/components/drop-down/drop-down.component';
import { PreDepartureTaskFilterRequest } from './models/pre-departure-task-filter-request.model';
import { PreDepartureTaskFilterResponse } from './models/pre-departure-task-filter-responce.model';
import { CausesLevel2DD } from '@secca/shared/models/CausesLevel2DD';
import { CausesLevel3DD } from '@secca/shared/models/CausesLevel3DD';
import { catchError, takeUntil } from 'rxjs/operators';
import { BoardNavigationStateService } from './services/board-navigation-state.service';
import { InboxTaskFilterRequest } from './models/inbox-task-filter-request.model';
import { InboxTaskFilterResponse } from './models/inbox-task-filter-responce.model';
import { RecoveryTaskFilterRequest } from './models/recovery-task-filter-request.model';
import { RecoveryTaskFilterResponse } from './models/recovery-task-filter-response.model';
import { Recovery } from '@secca/shared/models/recovery';
import { RecoveryPaymentTaskFilterRequest } from "@secca/board/components/board-task/models/recovery-payment-task-filter-request.model";
import { RecoveryPaymentTaskFilterResponse } from "@secca/board/components/board-task/models/recovery-payment-task-filter-response.model";


@Component({
  selector: 'app-board-task',
  templateUrl: './board-task.component.html',
  styleUrls: ['./board-task.component.scss'],
})
@AutoUnsubscribe
export class BoardTaskComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('ngbNavInstance') ngbNavInstance: NgbNav;
  @ViewChild('modalContent') modalContent: TemplateRef<any>;
  @ViewChild('todoFromDate') todoFromDate: any;
  @ViewChild('doneFromDate') doneFromDate: any;
  @ViewChild('customerProfileId') profileComponent: any;
  @ViewChild('caseCauseLevel1Component') caseCauseLevel1Component: DropDownComponent;
  @ViewChild('multipleSelectCustomerProfileComponent') multipleSelectCustomerProfileComponent: any;

  @ViewChild('taskCaseCauseLevel1Component') taskCaseCauseLevel1Component: DropDownComponent;
  @ViewChild('taskCaseCauseLevel2Component') taskCaseCauseLevel2Component: DropDownComponent;
  @ViewChild('taskCaseCauseLevel3Component') taskCaseCauseLevel3Component: DropDownComponent;

  @ViewChild('inboxTaskCaseCauseLevel1Component') inboxTaskCaseCauseLevel1Component: DropDownComponent;
  @ViewChild('inboxTaskCaseCauseLevel2Component') inboxTaskCaseCauseLevel2Component: DropDownComponent;
  @ViewChild('inboxTaskCaseCauseLevel3Component') inboxTaskCaseCauseLevel3Component: DropDownComponent;

  supplierInvoiceTaskViewModelsOriginal: SupplierInvoiceTaskViewModel[];
  $subscrboardTeamCaringService;
  $subscrBoardTeam: Subscription;
  $supplierInvoiceTasksSubscr;
  $recoveryPaymentTasksSubscr;
  $subscrRefreshAfterSavingFromEmailView;
  $subscrRefreshIncomingCasesAfterSaving;
  $refreshTasksSubscr;
  isBoardTeamCaringShow: boolean;
  dateTimePlaceholder = 'dd mmm yyyy - hh:mm';
  datePlaceholder = 'dd mm yyyy';
  toDoTaskFilterRequest: TaskFilterRequest;
  doneTaskFilterRequest: TaskFilterRequest;
  toDoTaskFilterResponse: TaskFilterResponse;
  doneTaskFilterResponse: TaskFilterResponse;
  globalNumberToDoTasks: number;
  globalNumberIncomingTasks: number
//  globalNumberSupplierInvoiceTasks: number;
  globalNumberRefundTasks: number;
  globalNumberPreDepartureTasks: number;
  globalNumberInboxTasks: number;
  globalNumberRecoveryTasks: number;
  supplierInvoiceTaskFilterRequest: SupplierInvoiceTaskFilterRequest;
  supplierInvoiceTaskFilterResponse: SupplierInvoiceTaskFilterResponse;
  filterableInvoiceGroups: DropdownDictionary[];
  filterableServiceTypes: DropdownDictionary[];
  filterableInvoiceStatus: DropdownDictionary[];
  filterResetInvoiceStatus: string[];
  filterableRefundStatus: DropdownDictionary[];
  filterablePreAssessmentStatus: DropdownDictionary[];
  filterResetPreAssessmentStatus: string[];
  filterableRecoveryStatus: DropdownDictionary[];
  filterResetRecoveryStatus: string[];
  recoveryPaymentTaskFilterRequest: RecoveryPaymentTaskFilterRequest;
  recoveryPaymentTaskFilterResponse: RecoveryPaymentTaskFilterResponse;
  filterableRecoveryPaymentStatus: DropdownDictionary[];
  snowEventTasks: DropdownDictionary[];
  currentTab: TaskStatus = TaskStatus.toDo;
  active = TabsEnum.TO_DO;

  creditors: DropdownDictionary[];
  incomingCasesTaskFilterRequest: TaskFilterRequest;
  incomingCasesTaskFilterResponse: IncomingCasesTaskFilterResponse;
  taskTypes: DropdownDictionary[];
  taskInboxTypes: DropdownDictionary[];
  latestWeeks: DropdownDictionary[];
  taskChannels: DropdownDictionary[];
  taskIncidentCoveredStatus: DropdownDictionary[];
  incidentEventsDropdown: DropdownDictionary[];
  selectedChannelType: string[] = [];
  $refundTasksSubscr;
  $preDepartureTasksSubscr;
  $recoveryTasksSubscr;
  $inboxTasksSubscr
  refundTaskFilterRequest: RefundTaskFilterRequest;
  refundTaskFilterResponse: RefundTaskFilterResponse;
  filterResetRefundStatus: string[];
  $taskCountSubscr: Subscription;
  incidentEvents: IncidentEvent[];
  preDepartureTaskFilterRequest: PreDepartureTaskFilterRequest;
  preDepartureTaskFilterResponse: PreDepartureTaskFilterResponse;
  searchUpdated: boolean = true;
  status: DropdownDictionary[];
  relatedCase: DropdownDictionary[];
  recoveryTaskFilterRequest: RecoveryTaskFilterRequest;
  recoveryTaskFilterResponse: RecoveryTaskFilterResponse;
  isRecoverySearchFilterUpdated = true;

  inboxTaskFilterRequest: InboxTaskFilterRequest;
  inboxTaskFilterResponse: InboxTaskFilterResponse;

  currentWeek = moment().locale('da').day(1).hour(0).minute(0).second(0);

  taskCausesLevel1: CausesLevel1DD[] = [];
  taskCausesLevel2: CausesLevel2DD[] = [];
  taskCausesLevel3: CausesLevel3DD[] = [];

  inboxTaskCausesLevel1: CausesLevel1DD[] = [];
  inboxTaskCausesLevel2: CausesLevel2DD[] = [];
  inboxTaskCausesLevel3: CausesLevel3DD[] = [];

  claimStatus: string[] = [];
  notRelavantJustSelected = true;
  claimsDropDownStatus: DropdownDictionary[];

  private viewInitializing = true;

  private shortcutSubscriptions: Subscription[] = [];
  private stopCallInterval$ = new Subject<boolean> ();

  constructor(
    private taskStateService: TaskStateService,
    private boardTeamCaringService: BoardTeamCaringService,
    private boardShareService: BoardShareService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private serviceOrderService: ServiceOrderService,
    private supplierInvoiceService: SupplierInvoiceService,
    private settingsService: SettingsService,
    private taskService: TaskService,
    private supplierProfileService: SupplierProfileService,
    private shortcutService: ShortcutService,
    private masterListService: MasterListService,
    private incidentService: IncidentService,
    private boardNavigationStateService: BoardNavigationStateService
  ) {
    this.toDoTaskFilterRequest = new TaskFilterRequest({
      to: MomentHelperService.getSeccaTimeEndOfDay(),
      taskType: '',
      taskTypes: [],
      sortBy: 'dueDate',
      sortOrder: SortOrder.asc,
      pageSize: SettingsService.pageSizeForPagination,
      pageIndex: this.boardNavigationStateService.getState('board-task-to-do-pagination-index') ? parseInt(this.boardNavigationStateService.getState('board-task-to-do-pagination-index'), 10) : 0,
      customerProfileId: null,
      incidentCoveredStates: [],
      eventIds: [],
      customerProfileIds: [],
    });

    this.doneTaskFilterRequest = new TaskFilterRequest({
      taskType: '',
      taskTypes: [],
      sortBy: 'doneDate',
      sortOrder: SortOrder.asc,
      pageSize: SettingsService.pageSizeForPagination,
      pageIndex: this.boardNavigationStateService.getState('board-task-pre-done-pagination-index') ? parseInt(this.boardNavigationStateService.getState('board-task-done-pagination-index'), 10) : 0,
      customerProfileId: null,
      eventIds: [],
      weekAgo: 0,
      customerProfileIds: [],
    });

    this.incomingCasesTaskFilterRequest = new TaskFilterRequest({
      taskType: TaskType.external,
      taskTypes: [TaskType.external, TaskType.process],
      sortBy: 'receivedDate',
      sortOrder: SortOrder.asc,
      pageSize: SettingsService.pageSizeForPagination,
      pageIndex: this.boardNavigationStateService.getState('board-task-web-task-pagination-index') ? parseInt(this.boardNavigationStateService.getState('board-task-web-task-pagination-index'), 10) : 0,
      taskStatus: TaskStatus.toDo,
      customerProfileIds: [],
    });

    this.refundTaskFilterRequest = new RefundTaskFilterRequest({
      taskType: TaskType.refund,
      taskTypes: [],
      sortBy: 'receivedDate',
      sortOrder: SortOrder.asc,
      pageSize: SettingsService.pageSizeForPagination,
      pageIndex: this.boardNavigationStateService.getState('board-task-refund-pagination-index') ? parseInt(this.boardNavigationStateService.getState('board-task-refund-pagination-index'), 10) : 0,
      taskStatus: TaskStatus.toDo,
      causeLevel1Code: [],
      winterSport: null,
      incidentEvents: []
    });

    this.preDepartureTaskFilterRequest = new PreDepartureTaskFilterRequest({
      taskType: TaskType.medicalPreAssessment,
      taskTypes: [],
      sortBy: 'caseCreatedOn',
      sortOrder: SortOrder.asc,
      pageSize: SettingsService.pageSizeForPagination,
      pageIndex: this.boardNavigationStateService.getState('board-task-pre-departure-pagination-index') ? parseInt(this.boardNavigationStateService.getState('board-task-pre-departure-pagination-index'), 10) : 0,
      customerProfileIds: [],
      status: [MedicalPreAssessmentStatusEnum.UPDATED, MedicalPreAssessmentStatusEnum.ONGOING, MedicalPreAssessmentStatusEnum.MEDICAL_EVALUATION]
    });

    this.inboxTaskFilterRequest = new InboxTaskFilterRequest({
      taskTypes: [],
      sortBy: 'caseCreatedOn',
      sortOrder: SortOrder.asc,
      pageSize: SettingsService.pageSizeForPagination,
      pageIndex: this.boardNavigationStateService.getState('board-task-inbox-pagination-index') ? parseInt(this.boardNavigationStateService.getState('board-task-inbox-pagination-index'), 10) : 0,
      customerProfileIds: [],
      statuses: [],
      relatedCase: false,
      noIncidentDate: false
    });

    this.recoveryTaskFilterRequest = new RecoveryTaskFilterRequest({
      taskType: TaskType.medicalPreAssessment,
      taskTypes: [],
      sortBy: 'createdOn',
      sortOrder: SortOrder.asc,
      pageSize: SettingsService.pageSizeForPagination,
      pageIndex: this.boardNavigationStateService.getState('board-task-recovery-pagination-index') ? parseInt(this.boardNavigationStateService.getState('board-task-recovery-pagination-index'), 10) : 0,
      customerProfileIds: [],
      recoveryStatus: [RecoveryStatus.REQUEST_POA, RecoveryStatus.SEND_POA_FIRST_REMINDER, RecoveryStatus.SEND_POA_SECOND_REMINDER, RecoveryStatus.POA_FOLLOW_UP, RecoveryStatus.POA_SIGNED, RecoveryStatus.SEND_APPLICATION]
    });

    this.initSupplierInvoiceTaskFilter();
    this.initRefundTaskFilter();
    this.initToDoTaskFilter();
    this.initIncomingTaskFilter();
    this.initDoneTaskFilter();
    this.initSubscriptions();
    this.initPreDepartureTaskFilter();
    this.initInboxTaskFilter();
    this.initRecoveryTaskFilter();
    this.initRecoveryPaymentTaskFilter()

    this.$refreshTasksSubscr = this.taskStateService.getRefreshTasks().subscribe(res => {
      if (this.currentTab === TaskStatus.toDo) {
        this.refreshTodoTasksTabClick();
      } else if (this.currentTab === TaskStatus.inbox) {
        this.refreshInboxTasksClick();
      } else if (this.currentTab === TaskStatus.done) {
        this.refreshDoneTasksTabClick();
      } else if (this.currentTab === TaskStatus.supplierInvoice) {
        this.refreshSupplierInvoiceTasksTabClick();
      } else if (this.currentTab === TaskStatus.incomingCases) {
        this.refreshIncomingCasesTasksTabClick();
      } else if (this.currentTab === TaskStatus.refund) {
        this.refreshRefundTasksTabClick();
      } else if (this.currentTab === TaskStatus.recovery) {
        this.refreshRecoveryTasksTabClick();
      } else if (this.currentTab === TaskStatus.preDeparture) {
        this.refreshPreDepartureTasksTabClick();
      }
      this.updateTaskCounts();
    });

    this.shortcutSubscriptions.push(
      this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateToTaskToDo }).subscribe(a => {
        this.ngbNavInstance.select(TabsEnum.TO_DO);
        this.refreshToDoTasksClick();
      }),
      this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateToTaskWeb }).subscribe(a => {
        this.ngbNavInstance.select(TabsEnum.INCOMING_CASES);
        this.refreshIncomingCasesTasksClick();
      }),
      this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateToTaskRefund }).subscribe(a => {
        this.ngbNavInstance.select(TabsEnum.REFUND);
        this.refreshRefundTasksTabClick();
      }),
      this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateToTaskSupplierInvoice }).subscribe(a => {
        this.ngbNavInstance.select(TabsEnum.SUPPLIER_INVOICE);
        this.refreshSupplierInvoiceTasksClick();
      }),

      this.shortcutService.addShortcut({ keys: ShortcutEnum.BoardTaskReassignActivate }).subscribe(a => {
        if (this.currentTab === TaskStatus.toDo && PermissionEnum.TASK_UPDATE) {
          this.reassignTasks();
        } else if (this.currentTab === TaskStatus.incomingCases && PermissionEnum.TASK_UPDATE) {
          this.reassignIncomingCasesTasks();
        } else if (this.currentTab === TaskStatus.refund && PermissionEnum.TASK_UPDATE) {
          this.reassignRefundTasks();
        } else if (this.currentTab === TaskStatus.supplierInvoice && PermissionEnum.TASK_UPDATE) {
          this.reassignSupplierInvoiceTasks();
        } else if (this.currentTab === TaskStatus.preDeparture && PermissionEnum.TASK_UPDATE) {
          this.reassignPreDepartureTasks();
        } else if (this.currentTab === TaskStatus.inbox && PermissionEnum.TASK_UPDATE) {
          this.reassignInboxTasks();
        } else if (this.currentTab === TaskStatus.recovery && PermissionEnum.TASK_UPDATE) {
          this.reassignRecoveryTasks();
        }
      }),
    );
  }

  ngOnInit(): void {
    this.initTaskTypesDropdown(false);
    this.initInboxTaskTypesDropdown();
    this.initWeeksDropdown();
    this.initTaskChannelsDropdown();
    this.initIncidentCoveredStatusDropdown();
    this.selectTaskTabFromSessionStorage();
    this.initIncidentEventsDropdown();
    this.initIncidentCauses();
    this.initInboxIncidentCauses();
    this.initSnowEventDropdown();
    this.toDoTaskFilterRequest.pageIndex = this.boardNavigationStateService.getState('board-task-to-do-pagination-index') ? parseInt(this.boardNavigationStateService.getState('board-task-to-do-pagination-index'), 10) : 0;
    this.inboxTaskFilterRequest.pageIndex = this.boardNavigationStateService.getState('board-task-inbox-pagination-index') ? parseInt(this.boardNavigationStateService.getState('board-task-inbox-pagination-index'), 10) : 0;
    this.doneTaskFilterRequest.pageIndex = this.boardNavigationStateService.getState('board-task-done-pagination-index') ? parseInt(this.boardNavigationStateService.getState('board-task-done-pagination-index'), 10) : 0;
    this.incomingCasesTaskFilterRequest.pageIndex = this.boardNavigationStateService.getState('board-task-web-task-pagination-index') ? parseInt(this.boardNavigationStateService.getState('board-task-web-task-pagination-index'), 10) : 0;
    this.refundTaskFilterRequest.pageIndex = this.boardNavigationStateService.getState('board-task-refund-pagination-index') ? parseInt(this.boardNavigationStateService.getState('board-task-refund-pagination-index'), 10) : 0;
    this.preDepartureTaskFilterRequest.pageIndex = this.boardNavigationStateService.getState('board-task-pre-departure-pagination-index') ? parseInt(this.boardNavigationStateService.getState('board-task-pre-departure-pagination-index'), 10) : 0;
    this.supplierInvoiceTaskFilterRequest.pageIndex = this.boardNavigationStateService.getState('board-task-supplier-invoice-pagination-index') ? parseInt(this.boardNavigationStateService.getState('board-task-supplier-invoice-pagination-index'), 10) : 0;
    this.recoveryPaymentTaskFilterRequest.pageIndex = this.boardNavigationStateService.getState('board-task-recovery-payment-pagination-index') ? parseInt(this.boardNavigationStateService.getState('board-task-recovery-payment-pagination-index'), 10) : 0;
    this.recoveryTaskFilterRequest.pageIndex = this.boardNavigationStateService.getState('board-task-recovery-pagination-index') ? parseInt(this.boardNavigationStateService.getState('board-task-recovery-pagination-index'), 10) : 0;

    this.initStatusDropdown();
    this.initClaimsStatusDropdown();
    this.initRelatedStatusDropdown();

    this.$taskCountSubscr = interval(HelperService.HALF_MINUTE_IN_MILLIS).pipe(takeUntil(this.stopCallInterval$)).subscribe(val => {
      this.updateTaskCounts();
    });

  }

  ngAfterViewInit(): void {
    this.loadViewModelWithUser();
  }

  ngOnDestroy(): void {
    sessionStorage.setItem('supplier-invoice-task-filter-request', JSON.stringify(this.supplierInvoiceTaskFilterRequest));
    sessionStorage.setItem('toDo-task-filter-request', JSON.stringify(this.toDoTaskFilterRequest));
    sessionStorage.setItem('incomming-task-filter-request', JSON.stringify(this.incomingCasesTaskFilterRequest));
    sessionStorage.setItem('done-task-filter-request', JSON.stringify(this.doneTaskFilterRequest));
    sessionStorage.setItem('refund-task-filter-request', JSON.stringify(this.refundTaskFilterRequest));
    sessionStorage.setItem('task-tab-selected', JSON.stringify(this.active));
    sessionStorage.setItem('pre-departure-task-filter-request', JSON.stringify(this.preDepartureTaskFilterRequest));
    sessionStorage.setItem('recovery-task-filter-request', JSON.stringify(this.recoveryTaskFilterRequest));
    sessionStorage.setItem('recovery-payment-task-filter-request', JSON.stringify(this.recoveryPaymentTaskFilterRequest));
    this.boardNavigationStateService.saveState('board-task-to-do-pagination-index', JSON.stringify(this.toDoTaskFilterRequest.pageIndex));
    this.boardNavigationStateService.saveState('board-task-done-pagination-index', JSON.stringify(this.doneTaskFilterRequest.pageIndex));
    this.boardNavigationStateService.saveState('board-task-refund-pagination-index', JSON.stringify(this.refundTaskFilterRequest.pageIndex));
    this.boardNavigationStateService.saveState('board-task-inbox-pagination-index', JSON.stringify(this.inboxTaskFilterRequest.pageIndex));
    this.boardNavigationStateService.saveState('board-task-web-task-pagination-index', JSON.stringify(this.incomingCasesTaskFilterRequest.pageIndex));
    this.boardNavigationStateService.saveState('board-task-pre-departure-pagination-index', JSON.stringify(this.preDepartureTaskFilterRequest.pageIndex));
    this.boardNavigationStateService.saveState('board-task-supplier-invoice-pagination-index', JSON.stringify(this.supplierInvoiceTaskFilterRequest.pageIndex));
    this.boardNavigationStateService.saveState('board-task-recovery-payment-pagination-index', JSON.stringify(this.recoveryPaymentTaskFilterRequest.pageIndex));
    this.boardNavigationStateService.saveState('board-task-recovery-pagination-index', JSON.stringify(this.recoveryTaskFilterRequest.pageIndex));

    sessionStorage.setItem('inbox-task-filter-request', JSON.stringify(this.inboxTaskFilterRequest));
    sessionStorage.setItem('board-task-filter-request-claims-status', JSON.stringify(this.claimStatus));

    this.shortcutSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  selectTaskTabFromSessionStorage() {
    const filterJSON = sessionStorage.getItem('task-tab-selected');
    if (!!filterJSON) {
      const taskTabSelected = JSON.parse(filterJSON);
      this.active = taskTabSelected;
      this.onTabChange(this.active);
    }
  }

  private initTaskTypesDropdown(isDone: boolean) {
    this.taskTypes = [
      new DropdownDictionary(TaskType.manual, this.translateService.instant('task-type-manual')),
      new DropdownDictionary(TaskType.automatic, this.translateService.instant('task-type-automatic')),
      new DropdownDictionary(TaskType.medical, this.translateService.instant('task-type-medical')),
    ].concat(isDone ? [
      new DropdownDictionary(TaskType.refund, this.translateService.instant('task-type-refund')),
      new DropdownDictionary(TaskType.suplierInvoice, this.translateService.instant('task-type-supplier-invoice'))
    ] : []);
  }

  private initInboxTaskTypesDropdown() {
    this.taskInboxTypes = [
      new DropdownDictionary(TaskType.sms, this.translateService.instant('task-type-sms')),
      new DropdownDictionary(TaskType.email, this.translateService.instant('task-type-email')),
      new DropdownDictionary(TaskType.fax, this.translateService.instant('task-type-fax')),
      new DropdownDictionary(TaskType.mms, this.translateService.instant('task-type-mms')),
      new DropdownDictionary(TaskType.customerNote, this.translateService.instant('task-type-customer-note')),
      new DropdownDictionary(TaskType.supplierNote, this.translateService.instant('task-type-supplier-note')),
      new DropdownDictionary(TaskType.consent, this.translateService.instant('task-type-consent')),
      new DropdownDictionary(TaskType.processGopRequest, this.translateService.instant('task-type-process-gop-request'))
    ];
  }

  private initRelatedStatusDropdown() {
    this.relatedCase = [
      new DropdownDictionary(null, this.translateService.instant('task-type-all')),
      new DropdownDictionary(true, this.translateService.instant('RELATED-CASE-STARTS-RELATED')),
      new DropdownDictionary(false, this.translateService.instant('RELATED-CASE-STARTS-UNRELATED')),
    ];
  }

  private initStatusDropdown() {
    this.status = [
      new DropdownDictionary(HandlingAreaStatusEnum.OPEN, this.translateService.instant('HANDLING-AREA-OPEN')),
      new DropdownDictionary(HandlingAreaStatusEnum.CLOSED, this.translateService.instant('HANDLING-AREA-CLOSED')),
      new DropdownDictionary(HandlingAreaStatusEnum.REOPENED, this.translateService.instant('HANDLING-AREA-REOPENED')),
    ];
  }

  private initClaimsStatusDropdown() {
    this.claimsDropDownStatus = [
      new DropdownDictionary('NOT_RELEVANT', this.translateService.instant('HANDLING-AREA-NOT-RELEVANT')),
      new DropdownDictionary(HandlingAreaStatusEnum.OPEN, this.translateService.instant('HANDLING-AREA-OPEN')),
      new DropdownDictionary(HandlingAreaStatusEnum.REOPENED, this.translateService.instant('HANDLING-AREA-REOPENED')),
      new DropdownDictionary(HandlingAreaStatusEnum.CLOSED, this.translateService.instant('HANDLING-AREA-CLOSED')),
    ];
  }

  private initWeeksDropdown() {
    const weekStart = this.currentWeek.clone();

    this.latestWeeks = [];

    for (let i = 0; i < 12; i++) {
      const weekNo = weekStart.week();
      this.latestWeeks.push(new DropdownDictionary(i, weekStart.year() + ' - ' + weekStart.week()));
      weekStart.subtract(1, 'w');
    }
  }

  private initSnowEventDropdown() {
    this.snowEventTasks = [
      new DropdownDictionary(null, this.translateService.instant('board-task-winter-sport-all')),
      new DropdownDictionary(1, this.translateService.instant('board-task-winter-sport-yes')),
      new DropdownDictionary(0, this.translateService.instant('board-task-winter-sport-no')),
    ];
  }

  private initTaskChannelsDropdown() {
    this.taskChannels =
    ['CREATE_CASE', 'FIND_MY_DOCTOR', 'CREATE_CASE_EXPAT_BTI', 'FIND_MY_DOCTOR_EXPAT_BTI', 'HELP_ME_CREATE_CASE',
    'HELP_ME_FIND_MY_DOCTOR', 'ONLINE_CLAIMS', 'CLAIMS_BUSINESS', 'SUPPLIER_PORTAL', 'UNKNOWN_SUPPLIER',
    'BATCH_BILLING', 'MPA_TRAVEL', 'ONLINE_CARE','PHONE','CHAT','MAIL','WEB','SMS','FAX']
      .map(val => new DropdownDictionary(val, this.translateService.instant('channel-type-' + val)));
  }

  private initIncidentCoveredStatusDropdown() {
    this.taskIncidentCoveredStatus = [
      new DropdownDictionary(ScreeningStates.YES, this.translateService.instant('board-task-screening-states-yes')),
      new DropdownDictionary(ScreeningStates.NO, this.translateService.instant('board-task-screening-states-no')),
      new DropdownDictionary(
        ScreeningStates.TO_BE_DETERMINED,
        this.translateService.instant('board-task-screening-states-to-be-determined')
      ),
    ];
  }

  private initIncidentEventsDropdown() {
    this.masterListService.getIncidentEvents().subscribe(result => {
    this.incidentEventsDropdown = [];
    this.incidentEvents = result;
    this.incidentEvents.forEach(item => {
      if(!item.hiddenInEventDropDown) {
        this.incidentEventsDropdown.push(new DropdownDictionary(item.businessKey, item.name));
      }
    });
    if((this.incidentEventsDropdown.length !== 0) && (this.incidentEventsDropdown[0].id != "")) {
      this.incidentEventsDropdown.splice(0,0, new DropdownDictionary(null, "All"));
    }
  });
  }

  private initIncidentCauses() {
    this.incidentService.getIncidentCauses().subscribe(result => {
      this.taskCausesLevel1 = result;
        if(this.taskCausesLevel1[0].id != "") {
          this.taskCausesLevel1.splice(0,0, new CausesLevel1DD({name: "All", id: null}));
        }
        this.initCauses();
      });
  }

  private initCauses(): void {
    this.initCausesLevel2();
    this.initCausesLevel3();
  }

  private initCausesLevel2(): void {
    if (this.toDoTaskFilterRequest.taskCauseLevel1Code && this.taskCausesLevel1 && this.toDoTaskFilterRequest.taskCauseLevel1Code != "") {
      const selectedIncidentCauseLevel1 = this.taskCausesLevel1.find(level1 => level1.id === this.toDoTaskFilterRequest.taskCauseLevel1Code);
      this.taskCausesLevel2 = selectedIncidentCauseLevel1 ? selectedIncidentCauseLevel1.level2Causes : [];
      if(this.taskCausesLevel2[0].id != "" && this.taskCausesLevel2[0].id != null) {
        this.taskCausesLevel2.splice(0,0, new CausesLevel2DD({name: "All", id: null}));
      }
    }
  }

  private initCausesLevel3(): void {
    if (this.toDoTaskFilterRequest.taskCauseLevel2Code && this.taskCausesLevel2 && this.toDoTaskFilterRequest.taskCauseLevel2Code != "") {
      const selectedIncidentCauseLevel2 = this.taskCausesLevel2.find(level2 => level2.id === this.toDoTaskFilterRequest.taskCauseLevel2Code);
      this.taskCausesLevel3 = selectedIncidentCauseLevel2 ? selectedIncidentCauseLevel2.level3Causes : [];
      if(this.taskCausesLevel3.length != 0) {
        if(this.taskCausesLevel3[0].id != "" && this.taskCausesLevel3[0].id != null) {
          this.taskCausesLevel3.splice(0,0, new CausesLevel3DD({name: "All", id: null}));
        }
      }
    }
  }

  openCauseLevel1Dropdown() {
    this.caseCauseLevel1Component.setFocus({ selectCause: true });
  }

  openTaskCauseLevel1Dropdown() {
    this.taskCaseCauseLevel1Component.setFocus({ selectCause: true });
  }

  openTaskCauseLevel2Dropdown() {
    this.taskCaseCauseLevel2Component.setFocus({ selectCause: true });
  }

  openTaskCauseLevel3Dropdown() {
    if(!!this.toDoTaskFilterRequest.taskCauseLevel2Code && this.taskCausesLevel3?.length !== 0) {
      this.taskCaseCauseLevel3Component.setFocus({ selectCause: true });
    }
  }

  taskCauseLevel1Changed(): void {
    this.initCausesLevel2();
    this.toDoTaskFilterRequest.taskCauseLevel2Code = undefined;
    this.toDoTaskFilterRequest.taskCauseLevel3Code = undefined;
    this.taskCaseCauseLevel2Component.setFocus({ selectCause: true });
    this.loadTaskViewModels(TaskStatus.toDo, this.toDoTaskFilterRequest, false);
  }

  taskCauseLevel2Changed(): void {
    this.initCausesLevel3();
    this.toDoTaskFilterRequest.taskCauseLevel3Code = undefined;
    if(this.taskCausesLevel3?.length !== 0) {
      this.taskCaseCauseLevel3Component.setFocus({ selectCause: true });
    }
    this.loadTaskViewModels(TaskStatus.toDo, this.toDoTaskFilterRequest, false);
  }

  private initInboxIncidentCauses() {
    this.incidentService.getIncidentCauses().subscribe(result => {
      this.inboxTaskCausesLevel1 = result;
        if(this.inboxTaskCausesLevel1[0].id != "") {
          this.inboxTaskCausesLevel1.splice(0,0, new CausesLevel1DD({name: "All", id: null}));
        }
        this.initInboxCauses();
      });
  }

  private initInboxCauses(): void {
    this.initInboxCausesLevel2();
    this.initInboxCausesLevel3();
  }

  private initInboxCausesLevel2(): void {
    if (this.inboxTaskFilterRequest.taskCauseLevel1Code && this.inboxTaskCausesLevel1 && this.inboxTaskFilterRequest.taskCauseLevel1Code != "") {
      const selectedInboxIncidentCauseLevel1 = this.inboxTaskCausesLevel1.find(level1 => level1.id === this.inboxTaskFilterRequest.taskCauseLevel1Code);
      this.inboxTaskCausesLevel2 = selectedInboxIncidentCauseLevel1 ? selectedInboxIncidentCauseLevel1.level2Causes : [];
      if(this.inboxTaskCausesLevel2[0].id != "" && this.inboxTaskCausesLevel2[0].id != null) {
        this.inboxTaskCausesLevel2.splice(0,0, new CausesLevel2DD({name: "All", id: null}));
      }
    }
  }

  private initInboxCausesLevel3(): void {
    if (this.inboxTaskFilterRequest.taskCauseLevel2Code && this.inboxTaskCausesLevel2 && this.inboxTaskFilterRequest.taskCauseLevel2Code != "") {
      const selectedInboxIncidentCauseLevel2 = this.inboxTaskCausesLevel2.find(level2 => level2.id === this.inboxTaskFilterRequest.taskCauseLevel2Code);
      this.inboxTaskCausesLevel3 = selectedInboxIncidentCauseLevel2 ? selectedInboxIncidentCauseLevel2.level3Causes : [];
      if(this.inboxTaskCausesLevel3.length != 0) {
        if(this.inboxTaskCausesLevel3[0].id != "" && this.inboxTaskCausesLevel3[0].id != null) {
          this.inboxTaskCausesLevel3.splice(0,0, new CausesLevel3DD({name: "All", id: null}));
        }
      }
    }
  }

  openInboxTaskCauseLevel1Dropdown() {
    this.inboxTaskCaseCauseLevel1Component.setFocus({ selectCause: true });
  }

  openInboxTaskCauseLevel2Dropdown() {
    this.inboxTaskCaseCauseLevel2Component.setFocus({ selectCause: true });
  }

  openInboxTaskCauseLevel3Dropdown() {
    this.inboxTaskCaseCauseLevel3Component.setFocus({ selectCause: true });
  }

  inboxTaskCauseLevel1Changed(): void {
    this.initInboxCauses();
    this.inboxTaskFilterRequest.taskCauseLevel2Code = undefined;
    this.inboxTaskFilterRequest.taskCauseLevel3Code = undefined;
    this.inboxTaskCaseCauseLevel2Component.setFocus({ selectCause: true });
    this.loadInboxTasks(false);
  }

  inboxTaskCauseLevel2Changed(): void {
    this.initInboxCausesLevel3();
    this.inboxTaskFilterRequest.taskCauseLevel3Code = undefined;
    if(this.inboxTaskCausesLevel3?.length !== 0) {
      this.inboxTaskCaseCauseLevel3Component.setFocus({ selectCause: true });
    }
    this.loadInboxTasks(false);
  }

  private initSubscriptions() {
    this.$subscrboardTeamCaringService = this.boardTeamCaringService
      .getShowBoardTeamCaring()
      .subscribe(result => (this.isBoardTeamCaringShow = result));

    this.$subscrRefreshAfterSavingFromEmailView = this.taskStateService.refreshToDoTasksAfterSavingFromEmailView.subscribe(() => {
      this.loadTaskViewModels(TaskStatus.toDo, this.toDoTaskFilterRequest, false);
      this.updateTaskCounts();
    });
    this.$subscrRefreshIncomingCasesAfterSaving = this.taskStateService.refreshIncomingCasesAfterSaving.subscribe(() => {
      this.refreshIncomingCasesTasksClick();
      this.updateTaskCounts();
    });
  }

  private updateTaskCounts(): void {
    if (!this.toDoTaskFilterRequest.adtCodesForCount) {
      this.toDoTaskFilterRequest.adtCodesForCount = [this.toDoTaskFilterRequest.adtCode]
    }
    this.taskStateService.getTaskCounts(new TaskCountFilterRequest(      {
        adtCodeList: this.toDoTaskFilterRequest.adtCodesForCount, taskTypeCountList: ['TODO', 'WEB', 'INBOX']
      })).pipe(catchError(err => {
      if (err.status === 401 ) {
        this.stopCallInterval$.next(true);
      }
      return throwError(err);
    })).subscribe((result: TaskCountResponse) => {

      this.globalNumberIncomingTasks = 0;
      this.globalNumberInboxTasks = 0;
      this.globalNumberToDoTasks = result.unhandledTasks;
      result.taskCountTeamDtoList.map(taskCountTeam => taskCountTeam.taskTypeCountList).filter(taskCountTypeList => {
        const teamWebTaskCount = taskCountTypeList.filter(taskCountType => taskCountType.taskCountType === 'WEB').reduce((a, b) => a + b.total, 0);
        this.globalNumberIncomingTasks += teamWebTaskCount;
        const teamToTaskCount = taskCountTypeList.filter(taskCountType => taskCountType.taskCountType === 'TODO').reduce((a, b) => a + b.total, 0);
        this.globalNumberToDoTasks += teamToTaskCount;
        const teamInboxTaskCount = taskCountTypeList.filter(taskCountType => taskCountType.taskCountType === 'INBOX').reduce((a, b) => a + b.total, 0);
        this.globalNumberInboxTasks += teamInboxTaskCount;
      });
    });

    this.taskStateService.getRefundTaskCount(this.refundTaskFilterRequest).pipe(catchError(err => {
      if (err.status === 401 ) {
        this.stopCallInterval$.next(true);
      }
      return throwError(err);
    })).subscribe(result => {
      this.globalNumberRefundTasks = result;
    });

/*
    this.taskStateService.getSupplierInvoiceTaskCount(this.supplierInvoiceTaskFilterRequest).pipe(catchError(err => {
      if (err.status === 401 ) {
        this.stopCallInterval$.next(true);
      }
      return throwError(err);
    })).subscribe(result => {
      this.globalNumberSupplierInvoiceTasks = result;
    });
*/

    this.taskStateService.getPreDepartureTaskCount(this.preDepartureTaskFilterRequest.status[0]).pipe(catchError(err => {
      if (err.status === 401 ) {
        this.stopCallInterval$.next(true);
      }
      return throwError(err);
    })).subscribe(result => {
      this.globalNumberPreDepartureTasks = result;
    });

    this.taskStateService.getRecoveryTaskCount().pipe(catchError(err => {
      if (err.status === 401 ) {
        this.stopCallInterval$.next(true);
      }
      return throwError(() => err);
    })).subscribe(result => {
      this.globalNumberRecoveryTasks = result;
    });
  }

  sortingActionToDo(taskSorting: TableSort) {
    this.toDoTaskFilterRequest.sortBy = taskSorting.sortBy;
    this.toDoTaskFilterRequest.sortOrder = taskSorting.sortOrder;
    this.loadTaskViewModels(TaskStatus.toDo, this.toDoTaskFilterRequest, false);
  }

  sortingActionDone(taskSorting: TableSort) {
    this.doneTaskFilterRequest.sortBy = taskSorting.sortBy;
    this.doneTaskFilterRequest.sortOrder = taskSorting.sortOrder;
    this.loadTaskViewModels(TaskStatus.done, this.doneTaskFilterRequest, false);
  }

  sortingActionSupplierInvoiceCases(taskSorting: TableSort) {
    this.supplierInvoiceTaskFilterRequest.sortBy = taskSorting.sortBy;
    this.supplierInvoiceTaskFilterRequest.sortOrder = taskSorting.sortOrder;
    this.loadSupplierInvoiceTasksOnDemand();
  }

  sortingActionRefund(taskSorting: TableSort) {
    this.refundTaskFilterRequest.sortBy = taskSorting.sortBy;
    this.refundTaskFilterRequest.sortOrder = taskSorting.sortOrder;
    this.loadRefundTasksOnDemand();
  }

  sortingActionRecovery(taskSorting: TableSort) {
    this.recoveryTaskFilterRequest.sortBy = taskSorting.sortBy;
    this.recoveryTaskFilterRequest.sortOrder = taskSorting.sortOrder;
    this.loadRecoveryTasks(false);
  }

  sortingActionRecoveryPayment(taskSorting: TableSort) {
    this.recoveryPaymentTaskFilterRequest.sortBy = taskSorting.sortBy;
    this.recoveryPaymentTaskFilterRequest.sortOrder = taskSorting.sortOrder;
    this.loadRecoveryPaymentTasks(false);
  }

  sortingActionInbox(taskSorting: TableSort) {
    this.inboxTaskFilterRequest.sortBy = taskSorting.sortBy;
    this.inboxTaskFilterRequest.sortOrder = taskSorting.sortOrder;
    this.loadInboxTasks(false);
  }

  sortingActionIncomingCases(taskSorting: TableSort) {
    this.incomingCasesTaskFilterRequest.sortBy = taskSorting.sortBy;
    this.incomingCasesTaskFilterRequest.sortOrder = taskSorting.sortOrder;
    this.loadIncomingCasesTaskViewModels(this.incomingCasesTaskFilterRequest, false);
  }

  toDoPaginationChanged(index: number): void {
    this.toDoTaskFilterRequest.pageIndex = index;
    this.loadTaskViewModels(TaskStatus.toDo, this.toDoTaskFilterRequest, false);
    this.boardNavigationStateService.clearTodoTask();
  }

  donePaginationChanged(index: number): void {
    this.doneTaskFilterRequest.pageIndex = index;
    this.loadTaskViewModels(TaskStatus.done, this.doneTaskFilterRequest, false);
    this.boardNavigationStateService.clearDoneTask();
  }

  supplierInvoicePaginationChanged(index: number): void {
    this.supplierInvoiceTaskFilterRequest.pageIndex = index;
    this.loadSupplierInvoiceTasksOnDemand();
    this.boardNavigationStateService.clearSupplierInvoice();
  }

  recoveryPaymentPaginationChanged(index: number): void {
    this.recoveryPaymentTaskFilterRequest.pageIndex = index;
    this.loadRecoveryPaymentTasks(false);
    this.boardNavigationStateService.clearRecoveryPayment();
  }

  preDepartureTaskPaginationChanged(index: number): void {
    this.preDepartureTaskFilterRequest.pageIndex = index;
    this.loadPreDepartureTasks(false);
    this.boardNavigationStateService.clearPreDeparture();
  }

  incomingCasesPaginationChanged(index: number): void {
    this.incomingCasesTaskFilterRequest.pageIndex = index;
    this.loadIncomingCasesTaskViewModels(this.incomingCasesTaskFilterRequest, false);
    this.boardNavigationStateService.clearWebTasks();
  }

  refundPaginationChanged(index: number): void {
    this.refundTaskFilterRequest.pageIndex = index;
    this.loadRefundTasksOnDemand();
    this.boardNavigationStateService.clearRefund();
  }

  recoveryPaginationChanged(index: number): void {
    this.recoveryTaskFilterRequest.pageIndex = index;
    this.loadRecoveryTasks(false);
    this.boardNavigationStateService.clearRecovery()
  }

  inboxPaginationChanged(index: number): void {
    this.inboxTaskFilterRequest.pageIndex = index;
    this.loadInboxTasks(false);
    this.boardNavigationStateService.clearInbox();
  }

  refundEventDropdownChanged() {
    if(this.refundTaskFilterRequest.incidentEvents.indexOf(null) !== -1) {
      this.refundTaskFilterRequest.incidentEvents = [];
    }
    this.loadRefundTasksOnDemand();
  }

  sortingActionPreDepartureTask(taskSorting: TableSort) {
    this.preDepartureTaskFilterRequest.sortBy = taskSorting.sortBy;
    this.preDepartureTaskFilterRequest.sortOrder = taskSorting.sortOrder;
    this.loadPreDepartureTasks(false);
  }

  private initSupplierInvoiceTaskFilter() {
    this.filterableInvoiceGroups = [];
    this.filterableServiceTypes = [];
    this.filterableInvoiceStatus = [];
    this.filterResetInvoiceStatus = [];

    this.supplierProfileService.getPartnerSupplierProfiles().subscribe(result => {
      const resultList = [];
      result
        .forEach(sp => resultList.push(new DropdownDictionary(sp.supplierProfileCode, sp.supplierProfileName)));

      resultList.push(new DropdownDictionary('NO_PARTNER', 'SUPO no partner'));
      resultList.push(new DropdownDictionary('BATCH_BILLING', 'Batch billing'));
      resultList.push(new DropdownDictionary('OTHER', 'Other'));
      this.filterableInvoiceGroups = resultList;
    });

    this.serviceOrderService.getServiceOrderTypes(false).subscribe(allServiceTypes => {
      this.filterableServiceTypes = allServiceTypes.filter(
        type =>
          type.id !== ServiceTypeEnum.REFERRAL_TO_CUSTOMER &&
          type.id !== ServiceTypeEnum.ADVISE_ON_INSURANCE_TERMS &&
          type.id !== ServiceTypeEnum.GENERAL_ADVICE &&
          type.id !== ServiceTypeEnum.ADVICE_CALL_REGISTRATION
      );
    });
    Object.keys(SupplierInvoiceStatus).forEach(invStatus => {
      this.translateService
        .get('economy-supplier-invoice-status-' + SupplierInvoiceStatus[invStatus])
        .subscribe(result => this.filterableInvoiceStatus.push(new DropdownDictionary(SupplierInvoiceStatus[invStatus], result)));
      const status: SupplierInvoiceStatus = SupplierInvoiceStatus[invStatus];
      if (
        status === SupplierInvoiceStatus.invoiceReceived ||
        status === SupplierInvoiceStatus.ongoingCostControl ||
        status === SupplierInvoiceStatus.invoiceCorrected
      ) {
        this.filterResetInvoiceStatus.push(SupplierInvoiceStatus[invStatus]);
      }
    });

    const filterJSON = sessionStorage.getItem('supplier-invoice-task-filter-request');
    if (!!filterJSON) {
      const parsedSupplierInvoiceTaskFilterRequest = JSON.parse(filterJSON);
      this.supplierInvoiceTaskFilterRequest = new SupplierInvoiceTaskFilterRequest(parsedSupplierInvoiceTaskFilterRequest);
      this.supplierInvoiceTaskFilterRequest.dateRange[0] = null;
      this.supplierInvoiceTaskFilterRequest.from = null;
      this.supplierInvoiceTaskFilterRequest.dateRange[1] = null;
      this.supplierInvoiceTaskFilterRequest.to = null;
    } else {
      this.supplierInvoiceTaskFilterRequest = new SupplierInvoiceTaskFilterRequest({
        pageSize: SettingsService.pageSizeForPagination,
        sortBy: 'remaining',
      });
      this.clearSupplierInvoiceTaskFilter(false);
    }
  }

  private deserializeDate(dateTimeString: string): moment.Moment {
   return SessionStorageDateDeserializer.deserialize(dateTimeString);
  }

  private initDoneTaskFilter() {
    const filterJSON = sessionStorage.getItem('done-task-filter-request');
    if (!!filterJSON) {
      const parsedDoneTaskFilterRequest = JSON.parse(filterJSON);
      this.doneTaskFilterRequest = new TaskFilterRequest(parsedDoneTaskFilterRequest);
      this.doneTaskFilterRequest.to = this.deserializeDate(parsedDoneTaskFilterRequest.to);
      this.doneTaskFilterRequest.from = this.deserializeDate(parsedDoneTaskFilterRequest.from);
      this.doneTaskFilterRequest.taskType = parsedDoneTaskFilterRequest.taskType;
    } else {
      this.doneTaskFilterRequest = new TaskFilterRequest({
        pageSize: SettingsService.pageSizeForPagination,
        sortBy: 'receivedDate',
      });
      this.clearDoneTaskFilter(false);
      sessionStorage.removeItem('done-task-filter-request');
    }
  }

  private initIncomingTaskFilter() {
    const filterJSON = sessionStorage.getItem('incomming-task-filter-request');
    if (!!filterJSON) {
      const parsedIncommingTaskFilterRequest = JSON.parse(filterJSON);
      this.incomingCasesTaskFilterRequest = new TaskFilterRequest(parsedIncommingTaskFilterRequest);
      this.incomingCasesTaskFilterRequest.to = this.deserializeDate(parsedIncommingTaskFilterRequest.to);
      this.incomingCasesTaskFilterRequest.from = this.deserializeDate(parsedIncommingTaskFilterRequest.from);
      this.incomingCasesTaskFilterRequest.channelTypes = parsedIncommingTaskFilterRequest.channelTypes;
      if (!!this.incomingCasesTaskFilterRequest.channelTypes?.length) {
        this.selectedChannelType.push(this.incomingCasesTaskFilterRequest.channelTypes[0]);
      }
    } else {
      this.incomingCasesTaskFilterRequest = new TaskFilterRequest({
        pageSize: SettingsService.pageSizeForPagination,
        sortBy: 'receivedDate',
      });
      this.clearIncomingTaskFilter(false);
      sessionStorage.removeItem('incomming-task-filter-request');
    }
  }

  private initToDoTaskFilter() {
    const filterJSON = sessionStorage.getItem('toDo-task-filter-request');
    if (!!filterJSON) {
      const parsedtoDoTaskFilterRequest = JSON.parse(filterJSON);
      this.toDoTaskFilterRequest = new TaskFilterRequest(parsedtoDoTaskFilterRequest);
      (this.toDoTaskFilterRequest.to = this.deserializeDate(parsedtoDoTaskFilterRequest.to)),
        (this.toDoTaskFilterRequest.from = this.deserializeDate(parsedtoDoTaskFilterRequest.from));
    } else {
      this.toDoTaskFilterRequest = new TaskFilterRequest({
        pageSize: SettingsService.pageSizeForPagination,
        sortBy: 'dueDate',
      });
      this.clearToDoTaskFilter(false);
      sessionStorage.removeItem('toDo-task-filter-request');
    }
  }

  private initPreDepartureTaskFilter() {

    this.filterablePreAssessmentStatus = [];
    this.filterResetPreAssessmentStatus = [];

    Object.keys(MedicalPreAssessmentStatusEnum).forEach(preAssessmentStatus => {
      this.translateService
        .get('board-task-pre-departure-status-' + MedicalPreAssessmentStatusEnum[preAssessmentStatus])
        .subscribe(result => this.filterablePreAssessmentStatus.push(new DropdownDictionary(MedicalPreAssessmentStatusEnum[preAssessmentStatus], result)));
      const status: MedicalPreAssessmentStatusEnum = MedicalPreAssessmentStatusEnum[preAssessmentStatus];

      if (
        preAssessmentStatus === MedicalPreAssessmentStatusEnum.UPDATED ||
        preAssessmentStatus === MedicalPreAssessmentStatusEnum.ONGOING ||
        preAssessmentStatus === MedicalPreAssessmentStatusEnum.MEDICAL_EVALUATION
      ) {
        this.filterResetPreAssessmentStatus.push(MedicalPreAssessmentStatusEnum[preAssessmentStatus]);
      }
    });

    const filterJSON = sessionStorage.getItem('pre-departure-task-filter-request');
    if (!!filterJSON) {
      const parsedPreDepartureTaskFilterRequest = JSON.parse(filterJSON);
      this.preDepartureTaskFilterRequest = new PreDepartureTaskFilterRequest(parsedPreDepartureTaskFilterRequest);
      this.preDepartureTaskFilterRequest.createdOnFrom = this.deserializeDate(parsedPreDepartureTaskFilterRequest.createdOnFrom);
      this.preDepartureTaskFilterRequest.createdOnTo = this.deserializeDate(parsedPreDepartureTaskFilterRequest.createdOnTo);
      this.preDepartureTaskFilterRequest.taskType = parsedPreDepartureTaskFilterRequest.taskType;
      this.preDepartureTaskFilterRequest.status = parsedPreDepartureTaskFilterRequest.status;
    } else {
      this.preDepartureTaskFilterRequest = new PreDepartureTaskFilterRequest({
        pageSize: SettingsService.pageSizeForPagination,
        sortBy: 'caseCreatedOn',
      });

      this.clearPreDepartureTaskFilter(false);
      sessionStorage.removeItem('pre-departure-task-filter-request');
    }
  }

  private initInboxTaskFilter() {
    const filterJSON = sessionStorage.getItem('inbox-task-filter-request');
    const filterJSONClaims = sessionStorage.getItem('board-task-filter-request-claims-status');
    if (!!filterJSON) {
      const parsedInboxTaskFilterRequest = JSON.parse(filterJSON);
      this.inboxTaskFilterRequest = new InboxTaskFilterRequest(parsedInboxTaskFilterRequest);
      this.inboxTaskFilterRequest.incidentDatefrom = this.deserializeDate(parsedInboxTaskFilterRequest.incidentDatefrom);
      this.inboxTaskFilterRequest.incidentDateTo = this.deserializeDate(parsedInboxTaskFilterRequest.incidentDateTo);
      this.inboxTaskFilterRequest.from = this.deserializeDate(parsedInboxTaskFilterRequest.from);
      this.inboxTaskFilterRequest.to = this.deserializeDate(parsedInboxTaskFilterRequest.to);
      this.claimStatus = JSON.parse(filterJSONClaims);

      this.inboxTaskFilterRequest.taskType = parsedInboxTaskFilterRequest.taskType;
    } else {
      this.inboxTaskFilterRequest = new InboxTaskFilterRequest({
        pageSize: SettingsService.pageSizeForPagination,
        sortBy: 'dueDate',
      });

      this.clearInboxTaskFilter(false);
      sessionStorage.removeItem('inbox-task-filter-request');
    }
  }

  private initRefundTaskFilter() {
    this.filterableRefundStatus = [];
    this.filterResetRefundStatus = [];

    this.serviceOrderService.getServiceOrderTypes(false).subscribe(allServiceTypes => {
      this.filterableServiceTypes = allServiceTypes.filter(
        type =>
          type.id !== ServiceTypeEnum.REFERRAL_TO_CUSTOMER &&
          type.id !== ServiceTypeEnum.ADVISE_ON_INSURANCE_TERMS &&
          type.id !== ServiceTypeEnum.GENERAL_ADVICE
      );
    });

    Object.keys(RefundStatus).forEach(refundStatus => {
      this.translateService
        .get('case-economy-refund-refund-status-long-' + RefundStatus[refundStatus])
        .subscribe(result => this.filterableRefundStatus.push(new DropdownDictionary(RefundStatus[refundStatus], result)));
      const status: RefundStatus = RefundStatus[refundStatus];
      if (
        status === RefundStatus.ONGOING_CLAIMS_HANDLING ||
        status === RefundStatus.REFUND_REGISTERED ||
        status === RefundStatus.INFORMATION_RECEIVED ||
        status === RefundStatus.AWAITING_SECOND_APPROVER ||
        status === RefundStatus.AWAITING_SEND_LETTER ||
        status === RefundStatus.ERROR
      ) {
        this.filterResetRefundStatus.push(RefundStatus[refundStatus]);
      }
    });

    const filterJSON = sessionStorage.getItem('refund-task-filter-request');
    if (!!filterJSON) {
      const parsedRefundTaskFilterRequest = JSON.parse(filterJSON);
      this.refundTaskFilterRequest = new RefundTaskFilterRequest(parsedRefundTaskFilterRequest);
      this.refundTaskFilterRequest.from = null;
      this.refundTaskFilterRequest.to = null;
      this.refundTaskFilterRequest.dateRange[0] = null;
      this.refundTaskFilterRequest.dateRange[1] = null;
    } else {
      this.refundTaskFilterRequest = new RefundTaskFilterRequest({
        pageSize: SettingsService.pageSizeForPagination,
        sortBy: 'receivedDate',
      });
      this.clearRefundTaskFilter(false);
      sessionStorage.removeItem('refund-task-filter-request');
    }
  }

  private initRecoveryTaskFilter() {
    this.filterableRecoveryStatus = [];
    this.filterResetRecoveryStatus = [];

    Object.keys(RecoveryStatus).forEach(recoveryStatus => {
      this.translateService
        .get('case-economy-recovery-recovery-status-' + RecoveryStatus[recoveryStatus])
        .subscribe(result => this.filterableRecoveryStatus.push(new DropdownDictionary(RecoveryStatus[recoveryStatus], result)));
      const status: RecoveryStatus = RecoveryStatus[recoveryStatus];
    });

    const filterJSON = sessionStorage.getItem('recovery-task-filter-request');
    if (!!filterJSON) {
      const parsedRecoveryTaskFilterRequest = JSON.parse(filterJSON);
      this.recoveryTaskFilterRequest = new RecoveryTaskFilterRequest(parsedRecoveryTaskFilterRequest);
      this.recoveryTaskFilterRequest.from = this.deserializeDate(parsedRecoveryTaskFilterRequest.from);
      this.recoveryTaskFilterRequest.to = this.deserializeDate(parsedRecoveryTaskFilterRequest.to);
      this.recoveryTaskFilterRequest.dateRange[0] = this.deserializeDate(parsedRecoveryTaskFilterRequest.dateRange[0]);
      this.recoveryTaskFilterRequest.dateRange[1] = this.deserializeDate(parsedRecoveryTaskFilterRequest.dateRange[1]);
      this.recoveryTaskFilterRequest.applicationDeadlineFrom = this.deserializeDate(parsedRecoveryTaskFilterRequest.applicationDeadlineFrom);
      this.recoveryTaskFilterRequest.applicationDeadlineTo = this.deserializeDate(parsedRecoveryTaskFilterRequest.applicationDeadlineTo);
      this.recoveryTaskFilterRequest.applicationDeadlineRange[0] = this.deserializeDate(parsedRecoveryTaskFilterRequest.applicationDeadlineRange[0]);
      this.recoveryTaskFilterRequest.applicationDeadlineRange[1] = this.deserializeDate(parsedRecoveryTaskFilterRequest.applicationDeadlineRange[1]);
    } else {
      this.recoveryTaskFilterRequest = new RecoveryTaskFilterRequest({
        pageSize: SettingsService.pageSizeForPagination,
        sortBy: 'createdOn',
      });
      this.clearRecoveryTaskFilter(false);
      sessionStorage.removeItem('recovery-task-filter-request');
    }
  }

  private initRecoveryPaymentTaskFilter() {
    this.filterableRecoveryPaymentStatus = [];

    Object.keys(RecoveryPaymentStatus).forEach(recoveryPaymentStatus => {
      this.translateService
        .get('case-economy-recovery-payment-status-' + RecoveryPaymentStatus[recoveryPaymentStatus])
        .subscribe(result => this.filterableRecoveryPaymentStatus.push(new DropdownDictionary(RecoveryPaymentStatus[recoveryPaymentStatus], result)));
    });

    const filterJSON = sessionStorage.getItem('recovery-payment-task-filter-request');
    if (!!filterJSON) {
      const parsedRecoveryPaymentTaskFilterRequest = JSON.parse(filterJSON);
      this.recoveryPaymentTaskFilterRequest = new RecoveryPaymentTaskFilterRequest(parsedRecoveryPaymentTaskFilterRequest);
      this.recoveryPaymentTaskFilterRequest.dateRange[0] = this.deserializeDate(parsedRecoveryPaymentTaskFilterRequest.dateRange[0]);
      this.recoveryPaymentTaskFilterRequest.from = this.deserializeDate(parsedRecoveryPaymentTaskFilterRequest.from);
      this.recoveryPaymentTaskFilterRequest.dateRange[1] = this.deserializeDate(parsedRecoveryPaymentTaskFilterRequest.dateRange[1]);
      this.recoveryPaymentTaskFilterRequest.to = this.deserializeDate(parsedRecoveryPaymentTaskFilterRequest.to);
    } else {
      this.recoveryPaymentTaskFilterRequest = new RecoveryPaymentTaskFilterRequest({
        pageSize: SettingsService.pageSizeForPagination,
        sortBy: 'remaining',
      });
      this.clearRecoveryPaymentInvoiceTaskFilter(false);
    }
  }

  loadViewModelWithUser() {
    if (this.$subscrBoardTeam) {
      this.$subscrBoardTeam.unsubscribe();
    }
    this.$subscrBoardTeam = this.boardShareService.getSelectedCaseWorker().subscribe(result => {
      if (result != null) {
        this.toDoTaskFilterRequest.userId =
          this.doneTaskFilterRequest.userId =
            this.incomingCasesTaskFilterRequest.userId =
              this.supplierInvoiceTaskFilterRequest.userId =
                this.refundTaskFilterRequest.userId =
                  this.preDepartureTaskFilterRequest.userId =
                    this.inboxTaskFilterRequest.userId =
                      this.recoveryTaskFilterRequest.userId =
                        this.recoveryPaymentTaskFilterRequest.userId =
                result.userId;
        this.toDoTaskFilterRequest.adtCode =
          this.doneTaskFilterRequest.adtCode =
            this.incomingCasesTaskFilterRequest.adtCode =
              this.supplierInvoiceTaskFilterRequest.adtCode =
                this.refundTaskFilterRequest.adtCode =
                  this.preDepartureTaskFilterRequest.adtCode =
                    this.inboxTaskFilterRequest.adtCode =
                      this.recoveryTaskFilterRequest.adtCode =
                        this.recoveryPaymentTaskFilterRequest.adtCode =
                result.adtCode;

        this.toDoTaskFilterRequest.adtCodes =
          this.doneTaskFilterRequest.adtCodes =
            this.incomingCasesTaskFilterRequest.adtCodes =
              this.supplierInvoiceTaskFilterRequest.adtCodes =
                this.refundTaskFilterRequest.adtCodes =
                  this.preDepartureTaskFilterRequest.adtCodes =
                    this.inboxTaskFilterRequest.adtCodes =
                      this.recoveryTaskFilterRequest.adtCodes =
                        this.recoveryPaymentTaskFilterRequest.adtCodes =
                result.adtCodes;

        this.toDoTaskFilterRequest.userIds =
          this.doneTaskFilterRequest.userIds =
            this.incomingCasesTaskFilterRequest.userIds =
              this.supplierInvoiceTaskFilterRequest.userIds =
                this.refundTaskFilterRequest.userIds =
                  this.preDepartureTaskFilterRequest.userIds =
                    this.inboxTaskFilterRequest.userIds =
                      this.recoveryTaskFilterRequest.userIds =
                        this.recoveryPaymentTaskFilterRequest.userIds =
                result.userIds;

        this.toDoTaskFilterRequest.teamOnly =
          this.doneTaskFilterRequest.teamOnly =
            this.incomingCasesTaskFilterRequest.teamOnly =
              this.supplierInvoiceTaskFilterRequest.teamOnly =
                this.refundTaskFilterRequest.teamOnly =
                  this.preDepartureTaskFilterRequest.teamOnly =
                    this.inboxTaskFilterRequest.teamOnly =
                      this.recoveryTaskFilterRequest.teamOnly =
                        this.recoveryPaymentTaskFilterRequest.teamOnly =
                result.teamOnly;

        this.toDoTaskFilterRequest.adtCodesForCount =
          this.doneTaskFilterRequest.adtCodesForCount =
            this.incomingCasesTaskFilterRequest.adtCodesForCount =
              this.supplierInvoiceTaskFilterRequest.adtCodesForCount =
                this.refundTaskFilterRequest.adtCodesForCount =
                  this.preDepartureTaskFilterRequest.adtCodesForCount =
                    this.inboxTaskFilterRequest.adtCodesForCount =
                      this.recoveryTaskFilterRequest.adtCodesForCount =
                        this.recoveryPaymentTaskFilterRequest.adtCodesForCount =
                result.adtCodesForCount;

        let  paginationIndex = this.boardNavigationStateService.getState("board-task-refund-pagination-index");
        this.refundTaskFilterRequest.pageIndex = paginationIndex ? +paginationIndex: 0;
        paginationIndex = this.boardNavigationStateService.getState("board-task-to-do-pagination-index");
        this.toDoTaskFilterRequest.pageIndex = paginationIndex ? +paginationIndex: 0;
        paginationIndex = this.boardNavigationStateService.getState("board-task-done-pagination-index");
        this.doneTaskFilterRequest.pageIndex = paginationIndex ? +paginationIndex: 0;
        paginationIndex = this.boardNavigationStateService.getState("board-task-web-task-pagination-index");
        this.incomingCasesTaskFilterRequest.pageIndex = paginationIndex ? +paginationIndex: 0;
        paginationIndex = this.boardNavigationStateService.getState("board-task-supplier-invoice-pagination-index");
        this.supplierInvoiceTaskFilterRequest.pageIndex = paginationIndex ? +paginationIndex: 0;

        paginationIndex = this.boardNavigationStateService.getState("board-task-recovery-payment-pagination-index");
        this.supplierInvoiceTaskFilterRequest.pageIndex = paginationIndex ? +paginationIndex: 0;

        paginationIndex = this.boardNavigationStateService.getState("board-task-predeparture-pagination-index");
        this.preDepartureTaskFilterRequest.pageIndex = paginationIndex ? +paginationIndex: 0;


        paginationIndex = this.boardNavigationStateService.getState("board-task-inbox-pagination-index");
        this.inboxTaskFilterRequest.pageIndex = paginationIndex ? +paginationIndex: 0;

        paginationIndex = this.boardNavigationStateService.getState("board-task-recovery-pagination-index");
        this.recoveryTaskFilterRequest.pageIndex = paginationIndex ? +paginationIndex: 0;

        if (this.currentTab === TaskStatus.toDo || this.currentTab === TaskStatus.done) {
          this.loadTaskViewModels(
            this.currentTab,
            this.currentTab === TaskStatus.toDo ? this.toDoTaskFilterRequest : this.doneTaskFilterRequest,
            false
          );
        } else if (this.currentTab === TaskStatus.supplierInvoice) {
          this.refreshSupplierInvoiceTasksClick();
        } else if (this.currentTab === TaskStatus.incomingCases) {
          this.refreshIncomingCasesTasksClick();
        } else if (this.currentTab === TaskStatus.refund) {
          this.refreshRefundTasksTabClick();
        } else if (this.currentTab === TaskStatus.preDeparture) {
          this.refreshPreDepartureTasksTabClick();
        } else if (this.currentTab === TaskStatus.inbox) {
          this.refreshInboxTasksClick();
        } else if (this.currentTab === TaskStatus.recovery) {
          this.refreshRecoveryTasksTabClick();
        } else if (this.currentTab === TaskStatus.recoveryPayment) {
          this.refreshRecoveryPaymentTasksClick();
        }

        this.updateTaskCounts();
      }
    });
  }

  onTabChange(event: number) {

    if (event === TabsEnum.TO_DO) {
      this.currentTab = TaskStatus.toDo;
      this.initTaskTypesDropdown(false);
      this.refreshTodoTasksTabClick();
    }
    if (event === TabsEnum.DONE) {
      this.currentTab = TaskStatus.done;
      this.initTaskTypesDropdown(true);

      this.refreshDoneTasksTabClick();
    }
    if (event === TabsEnum.SUPPLIER_INVOICE) {
      this.currentTab = TaskStatus.supplierInvoice;
      this.loadSupplierInvoiceTasksOnDemand();
    }
    if (event === TabsEnum.INCOMING_CASES) {
      this.currentTab = TaskStatus.incomingCases;
      this.refreshIncomingCasesTasksTabClick();
    }
    if (event === TabsEnum.REFUND) {
      this.currentTab = TaskStatus.refund;
      this.refreshRefundTasksTabClick();
    }
    if (event === TabsEnum.PRE_DEPARTURE) {
      this.currentTab = TaskStatus.preDeparture;
      this.refreshPreDepartureTasksTabClick();
    }
    if (event === TabsEnum.INBOX) {
      this.currentTab = TaskStatus.inbox;
      this.refreshInboxTasksClick();
    }
    if (event === TabsEnum.RECOVERY) {
      this.currentTab = TaskStatus.recovery;
      this.refreshRecoveryTasksTabClick();
    }
    if (event === TabsEnum.RECOVERY_PAYMENT) {
      this.currentTab = TaskStatus.recoveryPayment;
      this.refreshRecoveryPaymentTasksClick();
    }
  }

  get TaskStatus() {
    return TaskStatus;
  }

  get toDoTaskSorting() {
    return new TableSort({sortBy: this.toDoTaskFilterRequest.sortBy, sortOrder: this.toDoTaskFilterRequest.sortOrder});
  }

  get doneTaskSorting() {
    return new TableSort({sortBy: this.doneTaskFilterRequest.sortBy, sortOrder: this.doneTaskFilterRequest.sortOrder});
  }

  get supplierInvoiceTaskSorting() {
    return new TableSort({
      sortBy: this.supplierInvoiceTaskFilterRequest.sortBy,
      sortOrder: this.supplierInvoiceTaskFilterRequest.sortOrder,
    });
  }

  get incomingCasesTaskSorting() {
    return new TableSort({sortBy: this.incomingCasesTaskFilterRequest.sortBy, sortOrder: this.incomingCasesTaskFilterRequest.sortOrder});
  }

  get refundTaskSorting() {
    return new TableSort({sortBy: this.refundTaskFilterRequest.sortBy, sortOrder: this.refundTaskFilterRequest.sortOrder});
  }

  get preDepartureTaskSorting() {
    return new TableSort({sortBy: this.preDepartureTaskFilterRequest.sortBy, sortOrder: this.preDepartureTaskFilterRequest.sortOrder});
  }

  get inboxTaskSorting() {
    return new TableSort({sortBy: this.inboxTaskFilterRequest.sortBy, sortOrder: this.inboxTaskFilterRequest.sortOrder});
  }

  get recoveryTaskSorting() {
    return new TableSort({sortBy: this.recoveryTaskFilterRequest.sortBy, sortOrder: this.recoveryTaskFilterRequest.sortOrder});
  }

  get recoveryPaymentTaskSorting() {
    return new TableSort({sortBy: this.recoveryPaymentTaskFilterRequest.sortBy, sortOrder: this.recoveryPaymentTaskFilterRequest.sortOrder});
  }

  private loadTaskViewModels(taskStatus: TaskStatus, taskFilterRequest: TaskFilterRequest, setPageIndexToZero: boolean) {

    if (setPageIndexToZero) {
      taskFilterRequest.pageIndex = 0;
    }

    if (taskFilterRequest.eventIds.includes(null)) {
      taskFilterRequest.eventIds = [];
    }

    if (taskStatus === TaskStatus.done) {
      if (taskFilterRequest.weekAgo !== undefined) {
        taskFilterRequest.from = this.currentWeek.clone().subtract(taskFilterRequest.weekAgo, 'w');
        taskFilterRequest.to = taskFilterRequest.from.clone().add(1, 'w');
      }
    }
    this.taskStateService.getTaskViewModels(taskStatus, taskFilterRequest).subscribe(result => {
      if (!taskFilterRequest.taskType) {
        taskFilterRequest.taskType = '';
      }
      if (taskStatus === TaskStatus.toDo) {
        this.toDoTaskFilterResponse = result;
        this.currentTab = TaskStatus.toDo;
      }
      if (taskStatus === TaskStatus.done) {
        this.doneTaskFilterResponse = result;
        this.currentTab = TaskStatus.done;
      }
    });
  }

  incomingCasesTaskChannelChanged(channel: ChannelType[]) {
    this.incomingCasesTaskFilterRequest.channelTypes = channel.length !== 0 ? channel : null;
    this.loadIncomingCasesTaskViewModels(this.incomingCasesTaskFilterRequest, true);
  }

  private loadIncomingCasesTaskViewModels(incomingCasesTaskFilterRequest: TaskFilterRequest, setPageIndexToZero: boolean) {
    if (setPageIndexToZero) {
      incomingCasesTaskFilterRequest.pageIndex = 0;
    }
    this.taskStateService.getIncomingCasesTaskViewModels(incomingCasesTaskFilterRequest).subscribe(result => {
      this.incomingCasesTaskFilterResponse = result;
    });
  }

  loadSupplierInvoiceTasksOnDemand() {
    this.supplierInvoiceService.getCreditors().subscribe(result => (this.creditors = result));

    if (this.$supplierInvoiceTasksSubscr) {
      this.$supplierInvoiceTasksSubscr.unsubscribe();
    }
    this.supplierInvoiceTaskFilterRequest.from = !!this.supplierInvoiceTaskFilterRequest.dateRange[0] ? moment.utc(this.supplierInvoiceTaskFilterRequest.dateRange[0]) : null;
    this.supplierInvoiceTaskFilterRequest.to = !!this.supplierInvoiceTaskFilterRequest.dateRange[1] ? moment.utc(this.supplierInvoiceTaskFilterRequest.dateRange[1]) : null;

    this.$supplierInvoiceTasksSubscr = this.taskStateService
      .getSupplierInvoiceTaskViewModelOnDemand(this.supplierInvoiceTaskFilterRequest)
      .subscribe(result => {
        this.searchUpdated = true;
        this.supplierInvoiceTaskFilterResponse = result;
      });
  }

  filteringChanged() {
    this.searchUpdated = false;
  }

  searchByRefundId() {
    if (this.refundTaskFilterRequest.refundID) {
      this.refundTaskFilterRequest.refundStatus = Object.values(RefundStatus);
    }
    this.loadRefundTasksOnDemand();
  }

  private loadRefundTasksOnDemand() {
    if (this.$refundTasksSubscr) {
      this.$refundTasksSubscr.unsubscribe();
    }
    this.refundTaskFilterRequest.from = !!this.refundTaskFilterRequest.dateRange[0] ? moment.utc(this.refundTaskFilterRequest.dateRange[0]) : null;
    this.refundTaskFilterRequest.to = !!this.refundTaskFilterRequest.dateRange[1] ? moment.utc(this.refundTaskFilterRequest.dateRange[1]) : null;
    this.$refundTasksSubscr = this.taskStateService.getRefundTaskViewModelOnDemand(this.refundTaskFilterRequest).subscribe(result => {
      this.refundTaskFilterResponse = result;
    });
  }

  searchByRecoveyId() {
    if (this.recoveryTaskFilterRequest.recoveryID) {
      this.recoveryTaskFilterRequest.recoveryStatus = Object.values(RecoveryStatus);
    }
    this.loadRecoveryTasks(true);
  }

  private loadRecoveryTasks(setPageIndexToZero: boolean) {
    if (this.$recoveryTasksSubscr) {
      this.$recoveryTasksSubscr.unsubscribe();
    }
    if (setPageIndexToZero) {
      this.recoveryTaskFilterRequest.pageIndex = 0;
    }
    this.recoveryTaskFilterRequest.from = !!this.recoveryTaskFilterRequest.dateRange[0] ? moment.utc(this.recoveryTaskFilterRequest.dateRange[0]) : null;
    this.recoveryTaskFilterRequest.to = !!this.recoveryTaskFilterRequest.dateRange[1] ? moment.utc(this.recoveryTaskFilterRequest.dateRange[1]) : null;

    this.recoveryTaskFilterRequest.applicationDeadlineFrom = !!this.recoveryTaskFilterRequest.applicationDeadlineRange[0] ? moment.utc(this.recoveryTaskFilterRequest.applicationDeadlineRange[0]) : null;
    this.recoveryTaskFilterRequest.applicationDeadlineTo = !!this.recoveryTaskFilterRequest.applicationDeadlineRange[1] ? moment.utc(this.recoveryTaskFilterRequest.applicationDeadlineRange[1]) : null;

    this.$recoveryTasksSubscr = this.taskStateService.getRecoveryTaskViewModels(this.recoveryTaskFilterRequest).subscribe(result => {
      this.recoveryTaskFilterResponse = result;
      this.isRecoverySearchFilterUpdated = false;
    });
  }

  private loadRecoveryPaymentTasks(setPageIndexToZero: boolean) {
    if (this.$recoveryTasksSubscr) {
      this.$recoveryTasksSubscr.unsubscribe();
    }
    if(setPageIndexToZero) {
      this.recoveryPaymentTaskFilterRequest.pageIndex = 0;
    }
    this.$recoveryPaymentTasksSubscr = this.taskStateService.getRecoveryPaymentTaskViewModels(this.recoveryPaymentTaskFilterRequest).subscribe(result => {
      this.searchUpdated = true;
      this.recoveryPaymentTaskFilterResponse = result;
    });
  }

  private loadPreDepartureTasks(setPageIndexToZero: boolean) {
    if (this.$preDepartureTasksSubscr) {
      this.$preDepartureTasksSubscr.unsubscribe();
    }
    if (setPageIndexToZero) {
      this.preDepartureTaskFilterRequest.pageIndex = 0;
    }
    this.$preDepartureTasksSubscr = this.taskStateService.getPreDepartureTaskViewModels(this.preDepartureTaskFilterRequest).subscribe(result => {
      this.preDepartureTaskFilterResponse = result;
    });
  }

  private loadInboxTasks(setPageIndexToZero: boolean) {
    if (this.$inboxTasksSubscr) {
      this.$inboxTasksSubscr.unsubscribe();
    }
    if (setPageIndexToZero) {
      this.inboxTaskFilterRequest.pageIndex = 0;
    }
    this.$inboxTasksSubscr = this.taskStateService.getInboxTaskViewModels(this.inboxTaskFilterRequest).subscribe(result => {
      this.inboxTaskFilterResponse = result;
    });
  }

  get numberOfReassignedTasksToDo(): number {
    if (this.toDoTaskFilterResponse?.taskViewModels == null) {
      return 0;
    } else {
      return this.toDoTaskFilterResponse.taskViewModels.filter(a => a.isCheck).length;
    }
  }

  get numberOfReassignedTasksDone(): number {
    if (this.doneTaskFilterResponse?.taskViewModels == null) {
      return 0;
    } else {
      return this.doneTaskFilterResponse.taskViewModels.filter(a => a.isCheck).length;
    }
  }

  get numberOfReassignTasksSupplierInvoice(): number {
    if (this.supplierInvoiceTaskFilterResponse?.taskViewModels == null) {
      return 0;
    } else {
      return this.supplierInvoiceTaskFilterResponse.taskViewModels.filter(a => a.isSelected).length;
    }
  }

  get numberOfReassignedIncomingCasesTasks(): number {
    if (this.incomingCasesTaskFilterResponse?.taskViewModels == null) {
      return 0;
    } else {
      return this.incomingCasesTaskFilterResponse.taskViewModels.filter(a => a.isCheck).length;
    }
  }

  get numberOfReassignTasksRefund(): number {
    if (this.refundTaskFilterResponse?.taskViewModels == null) {
      return 0;
    } else {
      return this.refundTaskFilterResponse.taskViewModels.filter(a => a.isSelected).length;
    }
  }

  get numberOfReassignTasksRecovery(): number {
    if (this.recoveryTaskFilterResponse?.taskViewModels == null) {
      return 0;
    } else {
      return this.recoveryTaskFilterResponse.taskViewModels.filter(a => a.isSelected).length;
    }
  }

  get numberOfReassignTasksRecoveryPayment(): number {
    if (this.recoveryTaskFilterResponse?.taskViewModels == null) {
      return 0;
    } else {
      return this.recoveryTaskFilterResponse.taskViewModels.filter(a => a.isSelected).length;
    }
  }

  get numberOfReassignTasksPreDeparture(): number {
    if (this.preDepartureTaskFilterResponse?.taskViewModels == null) {
      return 0;
    } else {
      return this.preDepartureTaskFilterResponse.taskViewModels.filter(a => a.isSelected).length;
    }
  }

  get numberOfReassignTasksInbox(): number {
    if (this.inboxTaskFilterResponse?.inboxTaskModal == null) {
      return 0;
    } else {
      return this.inboxTaskFilterResponse.inboxTaskModal.filter(a => a.isSelected).length;
    }
  }

  refreshToDoTasksClick() {
    this.toDoTaskFilterResponse = null;
    this.boardNavigationStateService.clearTodoTask()
    this.loadTaskViewModels(TaskStatus.toDo, this.toDoTaskFilterRequest, true);
  }

  refreshDoneTasksClick() {
    this.doneTaskFilterResponse = null;
    this.boardNavigationStateService.clearAll()
    this.loadTaskViewModels(TaskStatus.done, this.doneTaskFilterRequest, true);
  }

  refreshTodoTasksTabClick() {
    this.toDoTaskFilterResponse = null;
    this.loadTaskViewModels(TaskStatus.toDo, this.toDoTaskFilterRequest, false);
  }

  refreshDoneTasksTabClick() {
    this.doneTaskFilterResponse = null;
    this.doneTaskFilterRequest.sortOrder = SortOrder.desc;
    this.loadTaskViewModels(TaskStatus.done, this.doneTaskFilterRequest, false);
  }

  refreshSupplierInvoiceTasksClick() {
    this.taskStateService.sendSupplierInvoiceTaskViewModels(undefined);
    this.loadSupplierInvoiceTasksOnDemand();
  }

  refreshSupplierInvoiceTasksTabClick() {
    this.taskStateService.sendSupplierInvoiceTaskViewModels(undefined);
    this.loadSupplierInvoiceTasksOnDemand();
  }

  refreshIncomingCasesTasksClick() {
    this.incomingCasesTaskFilterResponse = null;
    this.loadIncomingCasesTaskViewModels(this.incomingCasesTaskFilterRequest, true);
  }

  refreshIncomingCasesTasksTabClick() {
    this.incomingCasesTaskFilterResponse = null;
    this.loadIncomingCasesTaskViewModels(this.incomingCasesTaskFilterRequest, false);
  }

  refreshRefundTasksClick() {
    this.boardNavigationStateService.clearRefund()
    this.refundTaskFilterRequest.pageIndex = 0;
    this.taskStateService.sendRefundTaskViewModels(undefined);
    this.loadRefundTasksOnDemand();
  }

  refreshRefundTasksTabClick() {
    this.taskStateService.sendRefundTaskViewModels(undefined);
    this.loadRefundTasksOnDemand();
  }

  refreshRecoveryTasksTabClick() {
    this.taskStateService.sendRefundTaskViewModels(undefined);
    this.loadRecoveryTasks(false);
  }

  refreshRecoveryPaymentTasksClick() {
    this.taskStateService.sendRecoveryPaymentTaskViewModels(undefined);
    this.loadRecoveryPaymentTasks(true);
  }

  refreshPreDepartureTasksTabClick() {
    this.taskStateService.sendRefundTaskViewModels(undefined);
    this.loadPreDepartureTasks(false);
  }

  refreshInboxTasksClick() {
    this.inboxTaskFilterResponse = null;
    this.loadInboxTasks(false);
  }

  updateTaskListAfterEdit() {
    this.postReassign();
  }

  get tasksForReassign(): TaskViewModel[] {
    if (this.currentTab === TaskStatus.supplierInvoice) {
      const reassign: TaskViewModel[] = [];
      this.supplierInvoiceTaskFilterResponse.taskViewModels
        .filter(a => a.isSelected)
        .forEach(a => {
          const taskViewModel = new TaskViewModel({id: a.id.toString(), isCheck: a.isSelected});
          reassign.push(taskViewModel);
        });
      return reassign;
    } else if (this.currentTab === TaskStatus.refund) {
      const reassign: TaskViewModel[] = [];
      this.refundTaskFilterResponse.taskViewModels
        .filter(a => a.isSelected)
        .forEach(a => {
          const taskViewModel = new TaskViewModel({id: a.id.toString(), isCheck: a.isSelected});
          reassign.push(taskViewModel);
        });
      return reassign;
    } else if (this.currentTab === TaskStatus.incomingCases && this.incomingCasesTaskFilterResponse != null) {
      const reassign: TaskViewModel[] = [];
      this.incomingCasesTaskFilterResponse.taskViewModels
        .filter(a => a.isCheck)
        .forEach(task => {
          const taskViewModel = new TaskViewModel({id: task.id.toString(), isCheck: task.isCheck});
          reassign.push(taskViewModel);
        });
      return reassign;


    } else if (this.currentTab === TaskStatus.inbox && this.inboxTaskFilterResponse != null) {
      const reassign: TaskViewModel[] = [];
      this.inboxTaskFilterResponse.inboxTaskModal
        .filter(a => a.isSelected)
        .forEach(task => {
          const taskViewModel = new TaskViewModel({id: task.id.toString(), isCheck: task.isSelected});
          reassign.push(taskViewModel);
        });
      return reassign;
    } else if (this.currentTab === TaskStatus.preDeparture && this.preDepartureTaskFilterResponse != null) {
      const reassign: TaskViewModel[] = [];
      this.preDepartureTaskFilterResponse.taskViewModels
        .filter(a => a.isSelected)
        .forEach(task => {
          const taskViewModel = new TaskViewModel({id: task.id.toString(), isCheck: task.isSelected});
          reassign.push(taskViewModel);
        });
      return reassign;
    } else if (this.currentTab === TaskStatus.recovery && this.recoveryTaskFilterResponse != null) {
      const reassign: TaskViewModel[] = [];
      this.recoveryTaskFilterResponse.taskViewModels
        .filter(a => a.isSelected)
        .forEach(task => {
          const taskViewModel = new TaskViewModel({id: task.id.toString(), isCheck: task.isSelected});
          reassign.push(taskViewModel);
        });
      return reassign;
    } else if (this.currentTab === TaskStatus.toDo && this.toDoTaskFilterResponse != null) {
      return this.toDoTaskFilterResponse.taskViewModels.filter(a => a.isCheck);
    } else {
      return null;
    }
  }

  clearSupplierInvoiceTaskFilter(doLoad: boolean) {
    this.searchUpdated = false;
    this.supplierInvoiceTaskFilterRequest.sortBy = 'dueDate';
    this.supplierInvoiceTaskFilterRequest.sortOrder = SortOrder.asc;
    this.supplierInvoiceTaskFilterRequest.pageIndex = 0;
    this.supplierInvoiceTaskFilterRequest.dateRange = [null, null];
    this.supplierInvoiceTaskFilterRequest.from = null;
    this.supplierInvoiceTaskFilterRequest.to = null;
    this.supplierInvoiceTaskFilterRequest.invoiceGroups = [];
    this.supplierInvoiceTaskFilterRequest.serviceTypes = [];
    this.supplierInvoiceTaskFilterRequest.invoiceStatuses = this.filterResetInvoiceStatus;
    this.supplierInvoiceTaskFilterRequest.caseNumber = null;
    this.supplierInvoiceTaskFilterRequest.creditorId = null;
    this.supplierInvoiceTaskFilterRequest.invoiceNumber = null;
    sessionStorage.removeItem('supplier-invoice-task-filter-request');
    if (doLoad) {
      this.loadViewModelWithUser();
    }
  }

  clearRecoveryPaymentInvoiceTaskFilter(doLoad: boolean): void {
    this.searchUpdated = false;
    this.recoveryPaymentTaskFilterRequest.sortBy = 'dueDate';
    this.recoveryPaymentTaskFilterRequest.sortOrder = SortOrder.asc;
    this.recoveryPaymentTaskFilterRequest.pageIndex = 0;
    this.recoveryPaymentTaskFilterRequest.dateRange = [null, null];
    this.recoveryPaymentTaskFilterRequest.from = null;
    this.recoveryPaymentTaskFilterRequest.to = null;
    this.recoveryPaymentTaskFilterRequest.statuses = [RecoveryPaymentStatus.RECEIVED, RecoveryPaymentStatus.CORRECTED, RecoveryPaymentStatus.ONGOING, RecoveryPaymentStatus.ERROR];
    this.recoveryPaymentTaskFilterRequest.caseNumber = null;
    this.recoveryPaymentTaskFilterRequest.creditorId = null;
    this.recoveryPaymentTaskFilterRequest.invoiceNumber = null;
    sessionStorage.removeItem('recovery-payment-task-filter-request');
    if (doLoad) {
      this.loadViewModelWithUser();
    }
  }

  reassignTasks() {
    this.currentTab = TaskStatus.toDo;
    this.modalService.open(this.modalContent, {size: 'xs' as any, backdrop: 'static'});
  }

  reassignSupplierInvoiceTasks() {
    this.currentTab = TaskStatus.supplierInvoice;
    this.modalService.open(this.modalContent, {size: 'xs' as any, backdrop: 'static'});
  }

  reassignIncomingCasesTasks() {
    this.currentTab = TaskStatus.incomingCases;
    this.modalService.open(this.modalContent, {size: 'xs' as any, backdrop: 'static'});
  }

  reassignRefundTasks() {
    this.currentTab = TaskStatus.refund;
    this.modalService.open(this.modalContent, {size: 'xs' as any, backdrop: 'static'});
  }

  reassignRecoveryTasks() {
    this.currentTab = TaskStatus.recovery;
    this.modalService.open(this.modalContent, {size: 'xs' as any, backdrop: 'static'});
  }

  reassignPreDepartureTasks() {
    this.currentTab = TaskStatus.preDeparture;
    this.modalService.open(this.modalContent, {size: 'xs' as any, backdrop: 'static'});
  }

  reassignInboxTasks() {
    this.currentTab = TaskStatus.inbox;
    this.modalService.open(this.modalContent, {size: 'xs' as any, backdrop: 'static'});
  }

  postReassign() {
    if (this.currentTab === TaskStatus.supplierInvoice) {
      this.loadSupplierInvoiceTasksOnDemand();
    }
    if (this.currentTab === TaskStatus.refund) {
      this.loadRefundTasksOnDemand();
    }
    if (this.currentTab === TaskStatus.incomingCases) {
      this.refreshIncomingCasesTasksClick();
    }
    if (this.currentTab === TaskStatus.done) {
      this.refreshDoneTasksClick();
    }
    if (this.currentTab === TaskStatus.toDo) {
      this.refreshToDoTasksClick();
    }
    if (this.currentTab === TaskStatus.preDeparture) {
      this.refreshPreDepartureTasksTabClick();
    }
    if (this.currentTab === TaskStatus.inbox) {
      this.refreshInboxTasksClick();
    }
    if (this.currentTab === TaskStatus.recovery) {
      this.refreshRecoveryTasksTabClick();
    }

    this.updateTaskCounts();
  }

  get TabsEnum() {
    return TabsEnum;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  get PermissionHideTypeEnum() {
    return PermissionHideTypeEnum;
  }

  updateInsuranceCustomerProfile(customerProfiles: CustomerProfileIdsForSearch[]) {
    this.refundTaskFilterRequest.customerProfileIds = customerProfiles;
    this.loadRefundTasksOnDemand();
  }

  updateToDoTaskCustomerProfile(customerProfiles: CustomerProfileIdsForSearch[]) {
    this.toDoTaskFilterRequest.customerProfileIds = customerProfiles;
    this.loadTaskViewModels(TaskStatus.toDo, this.toDoTaskFilterRequest, true);
  }

  updateDoneTaskCustomerProfile(customerProfiles: CustomerProfileIdsForSearch[]) {
    this.doneTaskFilterRequest.customerProfileIds = customerProfiles;
    this.loadTaskViewModels(TaskStatus.done, this.doneTaskFilterRequest, true);
  }

  updateIncomingCasesTaskCustomerProfile(customerProfiles: CustomerProfileIdsForSearch[]) {
    this.incomingCasesTaskFilterRequest.customerProfileIds = customerProfiles;
    this.loadIncomingCasesTaskViewModels(this.incomingCasesTaskFilterRequest, true);
  }

  updatePreDepartureTaskCustomerProfile(customerProfiles: CustomerProfileIdsForSearch[]) {
    this.preDepartureTaskFilterRequest.customerProfileIds = customerProfiles;
    this.loadPreDepartureTasks(false);
  }

  updateTaskCustomerProfile(customerProfiles: CustomerProfileIdsForSearch[]) {
    this.toDoTaskFilterRequest.customerProfileIds = customerProfiles;
    this.loadTaskViewModels(TaskStatus.toDo, this.toDoTaskFilterRequest, true);
  }

  updateInboxTaskCustomerProfile(customerProfiles: CustomerProfileIdsForSearch[]) {
    this.inboxTaskFilterRequest.customerProfileIds = customerProfiles;
    this.loadInboxTasks(false);
  }

  updateRecoveryTaskCustomerProfile(customerProfiles: CustomerProfileIdsForSearch[]) {
    this.recoveryTaskFilterRequest.customerProfileIds = customerProfiles;
    this.recoverySearchFilterUpdated();
  }

  taskUpdate() {
    this.postReassign();
  }

  recoverySearchFilterUpdated() {
    this.isRecoverySearchFilterUpdated = true;
  }

  clearToDoTaskFilter(doLoad: boolean) {
    this.toDoTaskFilterRequest.to =  MomentHelperService.getSeccaTimeEndOfDay();
    this.toDoTaskFilterRequest.from = null;
    this.toDoTaskFilterRequest.taskType = '';
    this.toDoTaskFilterRequest.taskTypes = [];
    this.toDoTaskFilterRequest.sortOrder = SortOrder.asc;
    this.toDoTaskFilterRequest.pageIndex = 0;
    this.toDoTaskFilterRequest.customerProfileId = null;
    this.toDoTaskFilterRequest.incidentCoveredStates = [];
    this.toDoTaskFilterRequest.taskCauseLevel1Code = null;
    this.toDoTaskFilterRequest.taskCauseLevel2Code = null;
    this.toDoTaskFilterRequest.taskCauseLevel3Code = null;
    this.toDoTaskFilterRequest.eventIds = [],
    this.profileComponent?.resetCustomerProfile();
    this.toDoTaskFilterRequest.customerProfileIds = [];
    this.multipleSelectCustomerProfileComponent?.clearSelectedCustomerProfiles();
    this.boardNavigationStateService.clearTodoTask();
    if (doLoad) {
      this.loadTaskViewModels(TaskStatus.toDo, this.toDoTaskFilterRequest, true);
    }
  }

  clearIncomingTaskFilter(doLoad: boolean) {
    this.incomingCasesTaskFilterRequest.to = MomentHelperService.getSeccaTimeEndOfDay();
    this.incomingCasesTaskFilterRequest.from = null;
    this.incomingCasesTaskFilterRequest.taskType = TaskType.external;
    this.incomingCasesTaskFilterRequest.taskTypes = [TaskType.external, TaskType.process];
    this.incomingCasesTaskFilterRequest.sortBy = 'receivedDate';
    this.incomingCasesTaskFilterRequest.taskStatus = TaskStatus.toDo;
    this.incomingCasesTaskFilterRequest.sortOrder = SortOrder.asc;
    this.incomingCasesTaskFilterRequest.pageIndex = 0;
    this.incomingCasesTaskFilterRequest.channelTypes = null;
    this.incomingCasesTaskFilterRequest.customerProfileId = null;
    this.incomingCasesTaskFilterRequest.customerProfileIds = [];
    this.selectedChannelType = [];
    this.multipleSelectCustomerProfileComponent?.clearSelectedCustomerProfiles();
    if (doLoad) {
      this.loadIncomingCasesTaskViewModels(this.incomingCasesTaskFilterRequest, true);
    }
  }

  clearDoneTaskFilter(doLoad: boolean) {
    this.doneTaskFilterRequest.from = null;
    this.doneTaskFilterRequest.to = null;
    this.doneTaskFilterRequest.taskType = '';
    this.doneTaskFilterRequest.taskTypes = [];
    this.doneTaskFilterRequest.sortBy = 'doneDate';
    this.doneTaskFilterRequest.sortOrder = SortOrder.asc;
    this.doneTaskFilterRequest.pageSize = SettingsService.pageSizeForPagination;
    this.doneTaskFilterRequest.pageIndex = 0;
    this.doneTaskFilterRequest.customerProfileId = null;
    this.doneTaskFilterRequest.eventIds = [],
    this.profileComponent?.resetCustomerProfile();
    this.doneTaskFilterRequest.customerProfileIds = [];
    this.multipleSelectCustomerProfileComponent?.clearSelectedCustomerProfiles();
    this.boardNavigationStateService.clearDoneTask();
    if (doLoad) {
      this.loadTaskViewModels(TaskStatus.done, this.doneTaskFilterRequest, true);
    }
  }

  clearRefundTaskFilter(doLoad: boolean) {
    this.refundTaskFilterRequest.sortBy = 'remaining';
    this.refundTaskFilterRequest.serviceTypes = [];
    this.refundTaskFilterRequest.sortOrder = SortOrder.asc;
    this.refundTaskFilterRequest.pageIndex = 0;
    this.refundTaskFilterRequest.dateRange = [null, null];
    this.refundTaskFilterRequest.from = null;
    this.refundTaskFilterRequest.to = null;
    this.refundTaskFilterRequest.serviceTypes = [];
    this.refundTaskFilterRequest.caseNumber = null;
    this.refundTaskFilterRequest.customerProfileId = null;
    this.refundTaskFilterRequest.refundID = null;
    this.refundTaskFilterRequest.receiver = null;
    this.refundTaskFilterRequest.causeLevel1Code = [];
    this.refundTaskFilterRequest.incidentEvents = [];
    this.refundTaskFilterRequest.winterSport = null;
    this.refundTaskFilterRequest.refundStatus = this.filterResetRefundStatus;
    this.profileComponent?.resetCustomerProfile();
    this.refundTaskFilterRequest.customerProfileIds = [];
    this.multipleSelectCustomerProfileComponent?.clearSelectedCustomerProfiles();
    this.boardNavigationStateService.clearRefund();
    if (doLoad) {
      this.loadRefundTasksOnDemand();
    }
  }

  clearPreDepartureTaskFilter(doLoad: boolean) {
    this.preDepartureTaskFilterRequest.sortBy = 'caseCreatedOn';
    this.preDepartureTaskFilterRequest.sortOrder = SortOrder.asc;
    this.preDepartureTaskFilterRequest.pageIndex = 0;
    this.preDepartureTaskFilterRequest.createdOnFrom = null;
    this.preDepartureTaskFilterRequest.createdOnTo = null;
    this.preDepartureTaskFilterRequest.customerProfileId = null;
    this.preDepartureTaskFilterRequest.customerProfileIds = [];
    this.preDepartureTaskFilterRequest.status = [MedicalPreAssessmentStatusEnum.UPDATED, MedicalPreAssessmentStatusEnum.DRAFT,
      MedicalPreAssessmentStatusEnum.ONGOING, MedicalPreAssessmentStatusEnum.MEDICAL_EVALUATION];
    this.doneTaskFilterRequest.pageSize = SettingsService.pageSizeForPagination;
    this.multipleSelectCustomerProfileComponent?.clearSelectedCustomerProfiles();
    this.boardNavigationStateService.clearPreDeparture();
    if (doLoad) {
      this.loadPreDepartureTasks(false);
    }
  }

  clearRecoveryTaskFilter(doLoad: boolean) {
    this.recoveryTaskFilterRequest.sortBy = 'createdOn';
    this.recoveryTaskFilterRequest.sortOrder = SortOrder.asc;
    this.recoveryTaskFilterRequest.pageIndex = 0;
    this.recoveryTaskFilterRequest.dateRange = [null, null];
    this.recoveryTaskFilterRequest.from = null;
    this.recoveryTaskFilterRequest.to = null;
    this.recoveryTaskFilterRequest.applicationDeadlineRange = [null, null];
    this.recoveryTaskFilterRequest.applicationDeadlineFrom = null;
    this.recoveryTaskFilterRequest.applicationDeadlineTo = null;
    this.recoveryTaskFilterRequest.customerProfileId = null;
    this.recoveryTaskFilterRequest.customerProfileIds = [];
    this.recoveryTaskFilterRequest.recoveryStatus = [RecoveryStatus.REQUEST_POA, RecoveryStatus.SEND_POA_FIRST_REMINDER, RecoveryStatus.SEND_POA_SECOND_REMINDER, RecoveryStatus.POA_FOLLOW_UP, RecoveryStatus.POA_SIGNED, RecoveryStatus.SEND_APPLICATION];
    this.recoveryTaskFilterRequest.recoveryID = null;
    this.doneTaskFilterRequest.pageSize = SettingsService.pageSizeForPagination;
    this.multipleSelectCustomerProfileComponent?.clearSelectedCustomerProfiles();
    this.boardNavigationStateService.clearRecovery();
    this.isRecoverySearchFilterUpdated = true;
    if (doLoad) {
      setTimeout(() => {
        this.loadRecoveryTasks(true);
      },500);
    }
  }

  clearInboxTaskFilter(doLoad: boolean) {
    this.inboxTaskFilterRequest.sortBy = 'dueDate';
    this.inboxTaskFilterRequest.sortOrder = SortOrder.asc;
    this.inboxTaskFilterRequest.pageIndex = 0;
    this.inboxTaskFilterRequest.incidentDatefrom = null;
    this.inboxTaskFilterRequest.incidentDateTo = null;
    this.inboxTaskFilterRequest.from = null;
    this.inboxTaskFilterRequest.to = null;
    this.inboxTaskFilterRequest.customerProfileIds = [];
    this.inboxTaskFilterRequest.statuses = [];
    this.inboxTaskFilterRequest.taskTypes = [];
    this.inboxTaskFilterRequest.eventIds = [];
    this.inboxTaskFilterRequest.claimStatus = [];
    this.inboxTaskFilterRequest.relatedCase = null;
    this.inboxTaskFilterRequest.taskCauseLevel1Code = null;
    this.inboxTaskFilterRequest.taskCauseLevel2Code = null;
    this.inboxTaskFilterRequest.taskCauseLevel3Code = null;
    this.inboxTaskFilterRequest.noIncidentDate = false;
    this.claimStatus = [];

    this.inboxTaskFilterRequest.pageSize = SettingsService.pageSizeForPagination;
    this.multipleSelectCustomerProfileComponent?.clearSelectedCustomerProfiles();

    if (doLoad) {
      this.loadInboxTasks(false);
    }
  }

  get ValidationTypeEnum() {
    return ValidationTypeEnum;
  }

  showRefundListInTestOnly() {
    return !this.settingsService.isProduction();
  }

  filterOnInvoiceId() {
    this.taskStateService.sendSupplierInvoiceTaskViewModels(undefined);
    if (this.supplierInvoiceTaskFilterRequest.invoiceNumber?.length > 0) {
      this.supplierInvoiceTaskFilterRequest.invoiceStatuses = Object.values(SupplierInvoiceStatus);
    }
    this.loadSupplierInvoiceTasksOnDemand();
  }

  getTaskTabCount(tab: TabsEnum): string {
    switch (tab) {
      case (TabsEnum.INCOMING_CASES):
        if (this.currentTab === TaskStatus.incomingCases && this.incomingCasesTaskFilterResponse) {
          return '(' + this.incomingCasesTaskFilterResponse.totalNumber + ' of ' + this.globalNumberIncomingTasks + ')';
        } else {
          return '(' + this.globalNumberIncomingTasks + ')';
        }
      case (TabsEnum.TO_DO):
        if (this.currentTab === TaskStatus.toDo && this.toDoTaskFilterResponse) {
          return '(' + this.toDoTaskFilterResponse.totalNumber + ' of ' + this.globalNumberToDoTasks + ')';
        } else {
          return '(' + this.globalNumberToDoTasks + ')';
        }
      case (TabsEnum.SUPPLIER_INVOICE):
        if (this.currentTab === TaskStatus.supplierInvoice && this.supplierInvoiceTaskFilterResponse) {
          return '(' + this.supplierInvoiceTaskFilterResponse.totalNumber + ')';
        } else {
          return '';
        }
      case (TabsEnum.REFUND):
        if (this.currentTab === TaskStatus.refund && this.refundTaskFilterResponse) {
          return '(' + this.refundTaskFilterResponse.totalNumber + ' of ' + this.globalNumberRefundTasks + ')';
        } else {
          return '(' + this.globalNumberRefundTasks + ')';
        }

      case (TabsEnum.PRE_DEPARTURE):
        if (this.currentTab === TaskStatus.preDeparture && this.preDepartureTaskFilterResponse) {
          return '(' + this.preDepartureTaskFilterResponse.totalNumber + ' of ' + this.globalNumberPreDepartureTasks + ')';
        } else {
          return '(' + this.globalNumberPreDepartureTasks + ')';
        }

      case (TabsEnum.INBOX):
        if (this.currentTab === TaskStatus.inbox && this.inboxTaskFilterResponse) {
          return '(' + this.inboxTaskFilterResponse.totalNumber + ' of ' + this.globalNumberInboxTasks + ')';
        } else {
          return '(' + this.globalNumberInboxTasks + ')';
        }

      case (TabsEnum.RECOVERY):
        if (this.currentTab === TaskStatus.recovery && this.recoveryTaskFilterResponse) {
          return '(' + this.recoveryTaskFilterResponse.totalNumber + ' of ' + this.globalNumberRecoveryTasks + ')';
        } else {
          return '(' + this.globalNumberRecoveryTasks + ')';
        }
      case (TabsEnum.RECOVERY_PAYMENT):
        if (this.currentTab === TaskStatus.recoveryPayment && this.recoveryPaymentTaskFilterResponse) {
          return '(' + this.recoveryPaymentTaskFilterResponse.totalNumber + ')';
        } else {
          return '';
        }
    }
  }

  claimsTypeSelectChange() {
    let copyclaimStatus: string[] = [];
    if (this.claimStatus.includes('NOT_RELEVANT')) {
      if (!this.notRelavantJustSelected) {
        this.claimStatus = [];
        this.claimStatus.push('NOT_RELEVANT');
        this.inboxTaskFilterRequest.claimStatus = [];
        this.notRelavantJustSelected = !this.notRelavantJustSelected;
      } else if (this.notRelavantJustSelected) {
        const index = this.claimStatus.indexOf('NOT_RELEVANT');
        this.claimStatus.splice(index, 1);
        copyclaimStatus = JSON.parse(JSON.stringify(this.claimStatus));
        this.inboxTaskFilterRequest.claimStatus = [];
        this.claimStatus = [];
        this.claimStatus = copyclaimStatus;
        this.claimStatus.forEach(element => {
          this.inboxTaskFilterRequest.claimStatus.push(element);
        });
        this.notRelavantJustSelected = !this.notRelavantJustSelected;
      }
    } else {
      this.inboxTaskFilterRequest.claimStatus = [];
      this.claimStatus.forEach(element => {
        this.inboxTaskFilterRequest.claimStatus.push(element);
      });
    }
    this.loadInboxTasks(false);
  }

  claimsTypeSelectText(): string {
    if (this.claimStatus.length === 1 && this.claimStatus.includes('NOT_RELEVANT')) {
      return  this.translateService.instant('HANDLING-AREA-NOT-RELEVANT');
    } else {
      return this.claimStatus.length + ' ' + this.translateService.instant('board-case-filter-claims-some-selected');
    }
  }
}

enum TabsEnum {
  TO_DO,
  DONE,
  SUPPLIER_INVOICE,
  INCOMING_CASES,
  REFUND,
  PRE_DEPARTURE,
  INBOX,
  RECOVERY,
  RECOVERY_PAYMENT
}
