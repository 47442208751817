import { PhoneNumberType } from '@secca/shared/models/enums';
import { ServiceItemType } from '@secca/shared/models/service-item-type';
import { PurposeOfTravelItemType } from '@secca/shared/models/purpose-of-travel-item-type';
import { CauseItemType } from '@secca/shared/models/cause-item-type';
import { SimpleCustomerProfile } from '@secca/shared/models/simpleCustomerProfile';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { NumberFormatPipe } from '@secca/shared/pipes/number-format.pipe';
import { Diagnosis } from '@secca/shared/models/dignosis';

@Injectable({
  providedIn: 'root',
})
export class TaskWebPropertiesService {
  constructor(private numberFormatPipe: NumberFormatPipe) {}

  isShowProperty(obj: any, key: string, showEmptyProperties: boolean = false): boolean {
    return (
      showEmptyProperties ||
      this.isArrayProperty(obj, key) ||
      this.isTypeProperty(obj, key) ||
      this.isNestedProperty(obj, key) ||
      this.isBooleanProperty(obj, key) ||
      (this.isStringProperty(obj, key) &&
        (!!this.getPropertyValue(obj, key, null, null, null, null, null) || 0 === this.getPropertyValue(obj, key, null, null, null, null, null)))
    );
  }

  getPropertyKeys(obj: any, ignored: string[] = []): string[] {
    return obj ? Object.keys(obj).filter(key => !ignored || !ignored.includes(key)) : [];
  }

  isBooleanProperty(obj: any, key: string): boolean {
    const objVal = obj && obj[key];

    return objVal !== undefined && objVal !== null && typeof objVal === 'boolean';
  }

  isStringProperty(obj: any, key: string): boolean {
    const objVal = obj && obj[key];

    return objVal === undefined || objVal === null || typeof objVal === 'string' || typeof objVal === 'number';
  }

  isArrayProperty(obj: any, key: string): boolean {
    const objVal = obj && obj[key];

    return objVal && objVal['type'] === 'array';
  }

  isTypeProperty(obj: any, key: string): boolean {
    const objVal = obj && obj[key];

    return this.isKnownObjectType(objVal);
  }

  isNestedProperty(obj: any, key: string): boolean {
    return (
      !this.isStringProperty(obj, key) &&
      !this.isBooleanProperty(obj, key) &&
      !this.isArrayProperty(obj, key) &&
      !this.isTypeProperty(obj, key)
    );
  }

  getPropertyValue(
    obj: any,
    key: string,
    serviceItemList: ServiceItemType[],
    purposeOfTravelItemList: PurposeOfTravelItemType[],
    causes: Map<string, CauseItemType[]>,
    diagnoses: Diagnosis[],
    customerProfiles: SimpleCustomerProfile[]
  ): any {
    const objVal = obj && obj[key];
    if (this.isBooleanProperty(obj, key)) {
      return objVal;
    }

    if (this.isStringProperty(obj, key)) {
      return objVal;
    }
    if (!objVal) {
      return '';
    }

    if (this.isKnownObjectType(objVal)) {
      return this.getObjectValueFromType(objVal, serviceItemList, purposeOfTravelItemList, causes, diagnoses, customerProfiles);
    }

    return '';
  }

  getArrayProperties(obj: any, key: string): any {
    const objVal = obj && obj[key];
    if (!objVal) {
      return [];
    }
    const type = objVal['type'];
    switch (type) {
      case 'array':
        const array = [];
        const arr = objVal[key] || objVal['items'];
        if (arr && Array.isArray(arr)) {
          arr.forEach((item, idx) => {
            const nestedObj = {};
            if (item.name) {
              if (item.name === 'DASH') {
                nestedObj['-'] = item;
              }
              else {
                nestedObj[item.name] = item;
              }
            } else if (item.type) {
              nestedObj[item.type] = item;
            } else {
              nestedObj[key + idx] = item;
            }
            array.push(nestedObj);
          });
        }
        return array;
    }

    return [];
  }

  getObjectValueFromType(
    obj: any,
    serviceItemList: ServiceItemType[],
    purposeOfTravelItemList: PurposeOfTravelItemType[],
    causes: Map<string, CauseItemType[]>,
    diagnoses: Diagnosis[],
    customerProfiles: SimpleCustomerProfile[]
  ): string {
    let val = '';
    let field;
    const type = obj['type'];
    switch (type) {
      case 'date':
      case 'localdate':
      case 'datetime':
      case 'localdatetime':
        field = 'localDate';
        break;
  
      case 'amount':
        field = 'amount';
        break;

      case 'CUSTOMER_PROFILE':
        field = 'customerProfileCode';
        break;

      case 'PURPOSE_OF_TRAVEL':
      case 'SERVICE_ITEM':
      case 'CAUSE_LIST':
      case 'DIAGNOSIS':
        field = 'code'
        break;

      case PhoneNumberType.Fax:
      case PhoneNumberType.Fixed:
      case PhoneNumberType.Mobile:
        val = this.getPhoneNumberValue(obj['prefix'], obj['number']);
        break;
    }

    if ( field ) {
      val = this.getValueFromType(obj[field], type, serviceItemList, purposeOfTravelItemList, causes, diagnoses, customerProfiles);
    }

    return val;
  }


  getValueFromType(
    val: any,
    type: string,
    serviceItemList: ServiceItemType[],
    purposeOfTravelItemList: PurposeOfTravelItemType[],
    causes: Map<string, CauseItemType[]>,
    diagnoses: Diagnosis[],
    customerProfiles: SimpleCustomerProfile[]
  ): string {
    switch (type) {
      case 'date':
      case 'localdate':
        val = this.getDateValue(val);
        break;

      case 'datetime':
      case 'localdatetime':
        val = this.getDateTimeValue(val);
        break;

      case 'amount':
        val = this.getAmountValue(val);
        break;

      case 'boolean':
        val = this.getYesNoValue(val);
        break;

      case 'CUSTOMER_PROFILE':
        val = this.getCustomerProfile(val, customerProfiles);
        break;

      case 'PURPOSE_OF_TRAVEL':
        val = this.getPurposeOfTravel(val, purposeOfTravelItemList);
        break;

      case 'SERVICE_ITEM':
        val = this.getServiceItem(val, serviceItemList);
        break;

      case 'CAUSE_LIST':
        val = this.getCause(val, causes?.get('level1'));
        if (!val) {
          val = this.getCause(val, causes?.get('level2'));
        }
        if (!val) {
          val = this.getCause(val, causes?.get('level3'));
        }
        break;

      case 'DIAGNOSIS':
        val = this.getDiagnosis(val, diagnoses);
        break;
    }

    return val;
  }

  isKnownObjectType(obj: any): boolean {
    const type = obj && obj['type'];
    switch (type) {
      case 'CUSTOMER_PROFILE':
      case 'PURPOSE_OF_TRAVEL':
      case 'SERVICE_ITEM':
      case 'CAUSE_LIST':
      case 'DIAGNOSIS':
      case 'date':
      case 'localdate':
      case 'datetime':
      case 'localdatetime':
      case 'amount':
      case PhoneNumberType.Fax:
      case PhoneNumberType.Fixed:
      case PhoneNumberType.Mobile:
        return true;
    }

    return false;
  }

  getNestedProperties(obj: any, key: string): any {
    const nested = obj[key];
    if (Array.isArray(nested)) {
      return nested;
    } else {
      return [nested];
    }
  }

  getDateValue(date: string): string {
    if (date) {
      return moment(date).format('DD-MM-YYYY');
    }
    return '';
  }

  getDateTimeValue(dateTime: string): string {
    if (dateTime) {
      return moment(dateTime).format('DD-MM-YYYY HH:MM');
    }
    return '';
  }

  getAmountValue(amount: string): string {
    if (amount !== '') {
      return this.numberFormatPipe.transform(amount);
    }
    return '';
  }

  getYesNoValue(value: string): string {
    if ( value === '' || value === null || value === undefined ) {
      return '';
    }
    return value ? 'Yes' : 'No';
  }

  getPhoneNumberValue(prefix, number): string {
    if (number) {
      return prefix ? prefix + number : number;
    }
    return '';
  }

  getCustomerProfile(code: string, customerProfiles: SimpleCustomerProfile[]): string {
    const customerProfileItem = customerProfiles?.find(item => item.profileCode === code);

    return customerProfileItem ? customerProfileItem.profileName : '';
  }

  getPurposeOfTravel(code: string, purposeOfTravelItemList: PurposeOfTravelItemType[]): string {
    const purposeOfTravelItem = purposeOfTravelItemList?.find(item => item.code === code);

    return purposeOfTravelItem ? purposeOfTravelItem.name : '';
  }

  getServiceItem(code: string, serviceItemList: ServiceItemType[]): string {
    const serviceItem = serviceItemList?.find(item => item.code === code);

    return serviceItem ? serviceItem.name : '';
  }

  getCause(code: string, causeItemList: CauseItemType[]): string {
    const causeItem = causeItemList?.find(item => item.code === code);

    return causeItem ? causeItem.name : '';
  }

  getDiagnosis(code: string, diagnoses: Diagnosis[]): string {
    const diagnosisItem = diagnoses?.find(item => item.icd10Code === code);

    return diagnosisItem ? diagnosisItem.name : '';
  }
}
