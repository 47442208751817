import { Injectable, Inject } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';

interface Options {
  element: any;
  keys: string;
  preventDefault: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ShortcutService {
  hotkeys = new Map();
  defaults: Partial<Options> = {
    element: this.document,
    preventDefault: true
  };
  shortCutsEnabled = true;

  constructor(private eventManager: EventManager, @Inject(DOCUMENT) private document: Document) {}

  addShortcut(options: Partial<Options>): Observable<KeyboardEvent> {
    const merged = { ...this.defaults, ...options };
    const event = `keydown.${merged.keys}`;

    this.hotkeys.set(merged.keys, merged.keys);

    return new Observable(observer => {
      const handler = e => {
        if ( merged.preventDefault ) {
          e.preventDefault();
        }        
        if ( this.shortCutsEnabled ) {
          observer.next(e);
        }
      };

      const dispose = this.eventManager.addEventListener(merged.element, event, handler);

      return () => {
        dispose();
        this.hotkeys.delete(merged.keys);
      };
    });
  }

  enableShortCuts() {
    this.shortCutsEnabled = true;
  }

  disableShortCuts() {
    this.shortCutsEnabled = false;
  }
}
