
import { MomentHelperService } from '@secca/core/services/moment-helper.service';
import * as moment from 'moment';
import { DateSerializer } from '../date-serializer';
import { Location } from '@secca/shared/models/location';
import { ServiceOrderExtension } from './service-order-extension';
import { NoDurationServiceOrderExtension } from './no-duration-service-order-extension';
import { CommitmentType, CommitmentTypeAdapter } from '../commitment-type';

export class AdviceCallRegistrationServiceOrderExtension extends NoDurationServiceOrderExtension implements ServiceOrderExtension {
  displayDurationId: number;
  id: number;
  startDate: moment.Moment = MomentHelperService.getSeccaTime().startOf('day').utc();
  adviceGiven: string;
  commitmentType: CommitmentType;

  public constructor(init?: Partial<AdviceCallRegistrationServiceOrderExtension>) {
    super();
    if (init) {
      Object.assign(this, init);
    }
  }

  isPreviewGopValid(): boolean {
    throw new Error("Gop preview not supported for this service order type");
  }

  isValid(): boolean {
    return this.startDate && this.startDate.isValid() && !!this.commitmentType;
  }

  init() {}

  getStartDate(): moment.Moment {
    return this.startDate;
  }

  getStartLocation(): Location {
    return null;
  }

  getEndLocation(): Location {
    return null;
  }

  isMultipleDurations(): boolean {
    return false;
  }

  setCommitmentType(commitmentType: CommitmentType) {
    this.commitmentType = commitmentType;
  }

  getCommitmentType(): CommitmentType {
    return this.commitmentType;
  }

  getSelectedStakeholderIds(): string[] {
    return null;
  }
}
export class AdviceCallRegistrationServiceOrderExtensionAdapter {
  commitmentTypeAdapter: CommitmentTypeAdapter = new CommitmentTypeAdapter();
  adapt(item?: any): AdviceCallRegistrationServiceOrderExtension {

    return new AdviceCallRegistrationServiceOrderExtension({
      id: item.id,
      startDate: DateSerializer.deserialize(item.startDate),
      adviceGiven: item.adviceGiven,
      commitmentType: item.commitmentType != null ?  this.commitmentTypeAdapter.adapt(item.commitmentType): null//TODO DEFAULTnew SubCommitmentType(CommitmentTypes.EXPECTED),
    });
  }
}
