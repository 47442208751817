export class ApplicationSettings {
  bffEndpoint: string;
  keyCloakUlr: string;
  googleTimezoneAPIURL: string;
  googleAPIKey: string;
  caseManagementSystemEndpoint: string;
  ssoDisabled: boolean;
  isProduction: boolean;
  isTest: boolean;
  providerEndpoint: string;
  openCasesMaxCount: number;
  openCasesWarningThreshold: number;
  newSupplierEnabled: boolean;
  newCountryInfoEnabled: boolean;
  constructor() {
    this.bffEndpoint = '';
  }
}
