<div class="sub-component-container">
  <div class="sub-title">{{"sub-repatriation-form-medical-title" | translate}}</div>
  <div class="sub-content-container">
    <div class="repatriation-container">
      <div class="repatriation-button-container">
        <div *ngIf="isIntensiveRepatriation" class="fixed-text">
          <span>{{"repatriation-intensive1" | translate}}</span>
          <span style="font-style:italic;">{{"repatriation-intensive2" | translate}}</span>
          <span>{{"repatriation-intensive3" | translate}}</span>
          <span style="font-style:italic;">{{"repatriation-intensive4" | translate}}</span>
        </div>
        <div class="horizontal-line"></div>
        <button *ngIf="!isIntensiveRepatriation" class="primary-button repatriation-button"
                [disabled]="!isThereRepatriationInstruction || addRepatriationDisabled" [class.primary-button-inactive]="!isThereRepatriationInstruction"
                (click)="setLatestRepatriation()">{{"sub-repatriation-form-medical-add-data" | translate}}</button>
      </div>
      <div *ngIf="medicalAssessment?.repatriationInstructionsBase" class="repatriation-instruction-container">
        <div class="vertical-line"></div>
        <div class="row">
          <div class="col-6">
            <div class="repatriation-form-container">
              <app-drop-down
                *ngIf="medicalAssessment.repatriationInstructionsBase.repatriationForm.form !== RepatriationFormEnum.INTENSIVE_CARE_PATIENT"
                [items]="repatriationForms" [name]="'sub-repatriation-form-medical-repatriation-form' | translate"
                [(selectedItemId)]="medicalAssessment.repatriationInstructionsBase.repatriationForm.form">
              </app-drop-down>
            </div>
            <div
              *ngIf="medicalAssessment.repatriationInstructionsBase.repatriationForm.form == RepatriationFormEnum.INTENSIVE_CARE_PATIENT">
              <app-small-yes-no-question [disabled]="true" [header]="'repatriation-sea-level' | translate"
                [value]="medicalAssessment.repatriationInstructionsBase.repatriationForm.seaLevelFlight">
              </app-small-yes-no-question>
            </div>
            <div *ngIf="medicalAssessment.repatriationInstructionsBase.repatriationForm.form == RepatriationFormEnum.STRETCHER ||
              medicalAssessment.repatriationInstructionsBase.repatriationForm.form ==
              RepatriationFormEnum.INTENSIVE_CARE_PATIENT">
              <div class="half-size">
                <app-input
                  [disabled]="true"
                  [name]="'repatriation-patient-height' | translate"
                  [value]="medicalAssessment.repatriationInstructionsBase.repatriationForm.patientHeight">
                </app-input>
                <div class="unit-text">cm</div>
              </div>
              <div class="small-spacer"></div>
              <div class="half-size">
                <app-input [disabled]="true" [name]="'repatriation-patient-weight' | translate"
                  [value]="medicalAssessment.repatriationInstructionsBase.repatriationForm.patientWeight">
                </app-input>
                <div class="unit-text">kg</div>
              </div>
              <div class="small-spacer"></div>
              <app-small-yes-no-question [disabled]="true" [header]="'repatriation-pcob' | translate"
                [value]="medicalAssessment.repatriationInstructionsBase.repatriationForm.patientCarriableOnBoard">
              </app-small-yes-no-question>
            </div>
            <div
              *ngIf="medicalAssessment.repatriationInstructionsBase.repatriationForm.form == RepatriationFormEnum.EXTRA_SPACE">
              <div class="fixed-text-holder">
                <app-multiple-choice-component [items]="extraSpaceList"
                  [title]="'repatriation-extra-space-type' | translate">
                </app-multiple-choice-component>
                <div *ngIf="isOtherSelected">
                  <div class="name">{{"sub-repatriation-form-medical-other-patients-need" | translate}}</div>
                  <div class="other-patient-need">{{otherExtraSpaceText}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <app-yes-no-question [header]="'sub-repatriation-form-medical-ambulance-to-airport' | translate"
              [value]="isTransportToAirportAmbulanceOrLiftvan">
            </app-yes-no-question>
            <div *ngIf="isTransportToAirportAmbulanceOrLiftvan"
              class="stakeholder-chose-section sub-repatriation-form-medical">
              <div class="small-spacer"></div>
              <div class="name">{{"sub-repatriation-form-medical-ambulance-organizer" | translate}} </div>
              <ng-select class="custom"
                [class.required-red-background]="!subRepatriationFormMedical.toAirportOrganizerStakeholderId"
                [items]="stakeholdersDropdown" bindValue="id" bindLabel="name"
                (change)="setSelectedStakeholderToAirport($event)"
                [(ngModel)]="subRepatriationFormMedical.toAirportOrganizerStakeholderId" [clearable]="false">
                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <div>
                    <img [src]="item.iconPath" class="drop-down-icon" /> {{item.name}}
                  </div>
                </ng-template>
                <ng-template ng-label-tmp let-item="item">
                  <div class="w-100">
                    <img [src]="item.iconPath" class="drop-down-icon" /> {{item.name}}
                  </div>
                </ng-template>
              </ng-select>
              <div class="small-spacer"></div>
              <div class="phone-number">
                <div class="name">{{"sub-repatriation-form-medical-ambulance-organizer-phone" | translate}} </div>
                <app-phone-drop-down [disabled]="true" [withPrefixInput]="true" [withInput]="true" [showCode]="true"
                  [required]="true" [selectedItemId]="phoneToAirport.countryCode" [prefixValue]="phoneToAirport.prefix"
                  [value]="phoneToAirport.number">
                </app-phone-drop-down>
              </div>
            </div>
            <div class="spacer"></div>
            <app-yes-no-question [header]="'sub-repatriation-form-medical-ambulance-from-airport' | translate"
              [value]="isTransportFromAirportAmbulanceOrLiftvan">
            </app-yes-no-question>
            <div *ngIf="isTransportFromAirportAmbulanceOrLiftvan"
              class="stakeholder-chose-section sub-repatriation-form-medical">
              <div class="small-spacer"></div>
              <div class="name">{{"sub-repatriation-form-medical-ambulance-organizer" | translate}} </div>
              <ng-select class="custom" [items]="stakeholdersDropdown" bindValue="id" bindLabel="name"
                [class.required-red-background]="!subRepatriationFormMedical.fromAirportOrganizerStakeholderId"
                (change)="setSelectedStakeholderFromAirport($event)"
                [(ngModel)]="subRepatriationFormMedical.fromAirportOrganizerStakeholderId" [clearable]="false">
                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <div>
                    <img [src]="item.iconPath" class="drop-down-icon" /> {{item.name}}
                  </div>
                </ng-template>
                <ng-template ng-label-tmp let-item="item">
                  <div class="w-100">
                    <img [src]="item.iconPath" class="drop-down-icon" /> {{item.name}}
                  </div>
                </ng-template>
              </ng-select>
              <div class="small-spacer"></div>
              <div class="phone-number">
                <div class="name">{{"sub-repatriation-form-medical-ambulance-organizer-phone" | translate}} </div>
                <app-phone-drop-down [disabled]="true" [withPrefixInput]="true" [withInput]="true" [showCode]="true"
                  [required]="true" [selectedItemId]="phoneFromAirport.countryCode"
                  [prefixValue]="phoneFromAirport.prefix" [value]="phoneFromAirport.number">
                </app-phone-drop-down>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
