import { SubLuggageReference } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subLuggageReference';
import { MomentHelperService } from '@secca/core/services/moment-helper.service';
import * as moment from 'moment';
import { DateSerializer } from '../date-serializer';
import { ServiceOrderExtension } from './service-order-extension';
import { NoDurationServiceOrderExtension } from './no-duration-service-order-extension';
import { CommitmentType, CommitmentTypeAdapter } from '../commitment-type';



export class LuggageServiceOrderExtension extends NoDurationServiceOrderExtension implements ServiceOrderExtension {
  displayDurationId: number;
  id: number;
  startDate: moment.Moment =  MomentHelperService.getSeccaTime().startOf('day').utc();
  adviceGiven: string;
  luggageReference: SubLuggageReference = new SubLuggageReference();
  expectedCostValue: string;
  commitmentDate: Date;
  commitmentType: CommitmentType;
  public constructor(init?: Partial<LuggageServiceOrderExtension>) {
    super();
    Object.assign(this, init);
  }

  isValid(): boolean {
    return this.startDate && this.startDate.isValid() && !!this.commitmentType;
  }

  init() {}

  setCommitmentType(commitmentType: CommitmentType) {
    this.commitmentType = commitmentType;
  }

  getCommitmentType(): CommitmentType {
    return this.commitmentType;
  }


  getSelectedStakeholderIds(): string[] {
    return null;
  }

  isPreviewGopValid(): boolean {
    throw new Error("Gop preview not supported for this service order type");
  }
}
export class LuggageServiceOrderExtensionAdapter {
  adapt(item?: any): LuggageServiceOrderExtension {
    const commitmentTypeAdapter: CommitmentTypeAdapter = new CommitmentTypeAdapter();
    return new LuggageServiceOrderExtension({
      id: item.id,
      startDate: DateSerializer.deserialize(item.startDate),
      adviceGiven: item.adviceGiven,
      commitmentType:
        item.commitmentType = commitmentTypeAdapter.adapt(item.commitmentType), // new SubCommitmentType(CommitmentTypes.COMMITTED),
      luggageReference: item.luggageReference ? new SubLuggageReference(item.luggageReference) : null
    });
  }
}
