import {TaskService} from '../../../../../core/services/task.service';
import {AutoUnsubscribe} from 'src/app/shared/decorators/auto-unsubscribe';
import {SortOrder, TaskStatus, TaskType, TaskTypeEnumIcon} from '../../../../../shared/models/enums';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {SimpleTaskViewModel} from '../models/simple-task-view.model';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {CaseStateService} from '@secca/core/state-services/case-state.service';
import {Case} from '@secca/shared/models/case';
import {TaskViewModel} from '@secca/board/components/board-task/models/task-view.model';
import {CaseLockHelperService} from '@secca/core/services/case-lock-helper.service';
import {Subscription} from 'rxjs';
import {PermissionService} from '@secca/core/services/permission.service';
import { TaskModalModeEnum } from '../task-modal-static/task-modal-static-mode.enum';
import * as _ from 'lodash-es';
import { CaseDialogViewerService } from '@secca/case/case-dialog-viewer.service';
import { DialogViewerService } from '@secca/core/services/dialog-viewer.service';

@Component({
  selector: 'app-case-task-table',
  templateUrl: './case-task-table.component.html',
  styleUrls: ['./case-task-table.component.scss'],
})
@AutoUnsubscribe
export class CaseTaskTableComponent implements OnInit, OnDestroy {
  @Input() taskStatus: TaskStatus;

  @Input()
  set simpleTaskViewModels(simpleTaskViewModels: SimpleTaskViewModel[]) {
    this._simpleTaskViewModels = simpleTaskViewModels;
    if (this._simpleTaskViewModels != null && this.taskStatus === TaskStatus.done) {
      this._simpleTaskViewModels.sort((a, b) => a.dueDate.isAfter(b.dueDate) ? -1 : 1);
    }
  }

  get simpleTaskViewModels(): SimpleTaskViewModel[] {
    return this._simpleTaskViewModels;
  }

  _simpleTaskViewModels: SimpleTaskViewModel[];
  isCheckAll = false;
  taskId: number;
  headerHeight = 40;
  $scrollInterval;
  $subscrTaskMessageModalOpen;
  $subscrTaskConsentMessageModalOpen;

  $caseSubscr: Subscription;
  case: Case;
  showAddServiceOrderComponent: boolean;
  serviceOrderId: number;

  constructor(
    private taskService: TaskService,
    public dialog: MatDialog,
    private caseStateService: CaseStateService,
    public caseLockHelperService: CaseLockHelperService,
    public permissionService: PermissionService,
    private caseDialogViewerService: CaseDialogViewerService,
    private dialogViewerService: DialogViewerService
  ) {
    this.$caseSubscr = this.caseStateService.getCase().subscribe((result: Case) => {
      if (result != null) {
        this.case = result;
      }
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.$scrollInterval != null) {
      clearInterval(this.$scrollInterval);
    }
  }

  viewDetails(taskViewModel: TaskViewModel): void {
    if ( this.isMessageType(taskViewModel.taskType) ) {
      this.caseDialogViewerService.openTaskMessageDialog(taskViewModel, true);
    } else if (TaskType.external === taskViewModel.taskType || TaskType.processGopRequest === taskViewModel.taskType) {
      this.caseDialogViewerService.openTaskExternalDialog(taskViewModel, false, true);
    } else if (TaskType.process === taskViewModel.taskType) {
      this.dialogViewerService.openTaskDialog(taskViewModel, TaskModalModeEnum.partialViewOnly);
    } else if (TaskType.consent === taskViewModel.taskType) {
      this.caseDialogViewerService.openTaskConsentDialog(taskViewModel, true);
    } else {
      this.dialogViewerService.openTaskDialog(taskViewModel, TaskModalModeEnum.edit);
    }
  }

  getTaskStatusImagePath(simpleTaskViewModel: SimpleTaskViewModel): string {
    return TaskTypeEnumIcon.getIcon(simpleTaskViewModel.taskType, simpleTaskViewModel.status);
  }

  checkAllTasks() {
    this.isCheckAll = !this.isCheckAll;
    this.simpleTaskViewModels.forEach(a => (a.isCheck = this.isCheckAll));
  }

  sortBy(column: string) {
    if (this.taskStatus === TaskStatus.toDo) {
      if (this.taskService.taskCaseViewOptions.toDoSortColumnName === column) {
        this.flipToDoSortOrder();
      } else {
        this.changeSortColumnForToDoTasks(column);
      }
    } else {
      if (this.taskService.taskCaseViewOptions.doneSortColumnName === column) {
        this.flipDoneSortOrder();
      } else {
        this.changeSortColumnForDoneTasks(column);
      }
    }
  }

  getTaskViewModel(simpleTaskViewModel: SimpleTaskViewModel): TaskViewModel {
    const clone = _.clone(simpleTaskViewModel);

    return clone.convertToTaskViewModel();
  }

  private isMessageType(taskType: TaskType): boolean {
    return taskType === TaskType.sms || taskType === TaskType.mms ||
           taskType === TaskType.fax || taskType === TaskType.email;
  }

  private flipToDoSortOrder() {
    if (this.taskService.taskCaseViewOptions.toDoSortOrder === SortOrder.asc) {
      this.taskService.taskCaseViewOptions.toDoSortOrder = SortOrder.desc;
    } else {
      this.taskService.taskCaseViewOptions.toDoSortOrder = SortOrder.asc;
    }
  }

  private changeSortColumnForToDoTasks(column: string) {
    this.taskService.taskCaseViewOptions.toDoSortColumnName = column;
    this.taskService.taskCaseViewOptions.toDoSortOrder = SortOrder.asc;
  }

  private flipDoneSortOrder() {
    if (this.taskService.taskCaseViewOptions.doneSortOrder === SortOrder.asc) {
      this.taskService.taskCaseViewOptions.doneSortOrder = SortOrder.desc;
    } else {
      this.taskService.taskCaseViewOptions.doneSortOrder = SortOrder.asc;
    }
  }

  private changeSortColumnForDoneTasks(column: string) {
    this.taskService.taskCaseViewOptions.doneSortColumnName = column;
    this.taskService.taskCaseViewOptions.doneSortOrder = SortOrder.asc;
  }

  sortedBy(column: string): string {
    if (this.taskStatus === TaskStatus.toDo) {
      return this.taskService.taskCaseViewOptions.toDoSortColumnName === column ? 'sorted-by' : '';
    } else {
      return this.taskService.taskCaseViewOptions.doneSortColumnName === column ? 'sorted-by' : '';
    }
  }

  get TaskModalModeEnum() {
    return TaskModalModeEnum;
  }

  get TaskType() {
    return TaskType;
  }

  toggleSortType(columnName: string) {
    if (this.taskStatus === TaskStatus.toDo) {
      return (
        this.taskService.taskCaseViewOptions.toDoSortColumnName === columnName &&
        this.taskService.taskCaseViewOptions.toDoSortOrder === SortOrder.asc
      );
    } else {
      return (
        this.taskService.taskCaseViewOptions.doneSortColumnName === columnName &&
        this.taskService.taskCaseViewOptions.doneSortOrder === SortOrder.asc
      );
    }
  }
}
