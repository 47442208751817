import { DateHelperService } from './../../core/services/date-helper.service';
import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
import { DropdownDictionary } from './dropdownDictionary';
import { TaskStatus, TaskType } from './enums';
import * as moment from 'moment';
import { DateSerializer } from './date-serializer';

export class Task {
  id: number;
  caseId: number;
  userId: number;
  adtCode: string;
  title: string;
  description: string;
  dueDate: moment.Moment;
  status: TaskStatus = TaskStatus.toDo;
  allStatuses: DropdownDictionary[] = [
    new DropdownDictionary(TaskStatus.toDo.toString(), 'To do'),
    new DropdownDictionary(TaskStatus.done.toString(), 'Done')
  ];
  taskType: TaskType;
  serviceOrderId: number;
  emailId: string;
  faxId: string;
  messageId: string;
  createdOn: moment.Moment;
  message: string;
  receivedDate: moment.Moment;
  internalSummary: string;
  invoiceId: number;
  priority: number;
  originatedFromWebtaskId: number;
  medicalPreAssessmentId: number;
  digitalCaseflowSessionId: number;
  recoveryId: number;
  digitalCaseflowLogId: number;
  channelType: string;

  public constructor(init?: Partial<Task>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class TaskAdapter implements Adapter<Task> {

  constructor(private dateHelperService: DateHelperService) { }

  adapt(item: any): Task {
    return new Task({
      id: item.id,
      caseId: item.caseId,
      userId: item.userId,
      serviceOrderId: item.serviceOrderId,
      title: item.title,
      description: item.description,
      dueDate: DateSerializer.deserialize(item.dueDate),
      status: item.status,
      adtCode: item.adtCode,
      taskType: item.type,
      emailId: item.caseEmail != null ? item.caseEmail.emailId : null,
      faxId: item.caseFax != null ? item.caseFax.faxId : null,
      messageId: item.caseMessage != null ? item.caseMessage.messageId : null,
      message: item.caseMessage != null ? item.caseMessage.message : null,
      createdOn: DateSerializer.deserialize(item.createdOn),
      receivedDate: DateSerializer.deserialize(item.receivedDate),
      internalSummary: item.internalSummary,
      invoiceId: item.invoiceId,
      priority: item.priority,
      originatedFromWebtaskId: item.originatedFromWebtaskId,
      medicalPreAssessmentId: item.medicalPreAssessmentId,
      recoveryId: item.recoveryId,
      digitalCaseflowLogId: item.digitalCaseflowLogId,
      channelType: item.channelType
    });
  }
}
