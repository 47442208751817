
import { Icd10Code } from '@secca/shared/models/icd10Code';
import { Injectable } from '@angular/core';
import { MedicalAssessmentBasedOnEnum, MedicalPreAssessmentDecisionEnum, MedicalPreAssessmentStatusEnum, MedicalPreAssessmentTypeEnum , MedicalPreAssessmentRelevantEnum} from './enums';


export class MedicalPreAssessment {
  id: number;
  seccaCaseId: number;
  sourceList: MedicalPreAssessmentSource[];
  medicalAssessmentType: MedicalPreAssessmentTypeEnum;
  questionnaires: MedicalPreAssessmentQuestionnaire[];
  note: MedicalPreAssessmentNote;
  decision: MedicalPreAssessmentDecisionEnum; 
  type: MedicalPreAssessmentTypeEnum;
  status: MedicalPreAssessmentStatusEnum;
  decisionDetail: MedicalPreAssessmentDecisionDetail;

  public constructor(init?: Partial<MedicalPreAssessment>) {
    Object.assign(this, init);

    if (!init || !init.sourceList) {
      this.sourceList = [new MedicalPreAssessmentSource()];
    }

    if(!init || !init.questionnaires)
    {
      this.questionnaires = [new MedicalPreAssessmentQuestionnaire()];
    }

    if (!init || !init.note) {
      this.note = new MedicalPreAssessmentNote();
    }
    
    if (!init || !init.decisionDetail) {
      this.decisionDetail = new MedicalPreAssessmentDecisionDetail();
    }
  }
}

export class MedicalPreAssessmentSource {
  id: number;
  basedOn: MedicalAssessmentBasedOnEnum;
  sourceName: string;
  sourceType: string;

  public constructor(init?: Partial<MedicalPreAssessmentSource>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class MedicalPreAssessmentAdapter {
  constructor(private sourceAdapter: MedicalPreAssessmentSourceAdapter, 
              private noteAdapter: MedicalPreAssessmentNoteAdapter,
              private questionaireAdapter: MedicalPreAssessmentQuestionaireAdapter,
              private decisionDetailAdapter: MedicalPreAssessmentDecisionDetailAdapter){}
  adapt(data: any): MedicalPreAssessment {
    if ( data === null ) {
      return null;
    }
    return new MedicalPreAssessment({
      id: data.id,
      seccaCaseId: data.seccaCaseId,
      medicalAssessmentType: data.medicalAssessmentType,
      sourceList: !!data.sourceList ? data.sourceList.map(d => this.sourceAdapter.adapt(d)) : null,
      questionnaires: !!data.questionnaires ? data.questionnaires.map(q => this.questionaireAdapter.adapt(q)): null,
      note: !!data.note ? this.noteAdapter.adapt(data.note) : null,      
      status: data.status,
      decision: data.decision,
      decisionDetail : data.note.canFinalizeAssessment === true ? this.decisionDetailAdapter.adapt(data.decisionDetail) : null,
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class MedicalPreAssessmentSourceAdapter {
  adapt(data: any): MedicalPreAssessmentSource {
    return new MedicalPreAssessmentSource({
      id: data.id,
      basedOn: data.basedOn,
      sourceName: data.sourceName,
      sourceType: data.sourceType,
    });
  }
}


export class MedicalPreAssessmentQuestionnaire {
  id: number;
  diagnosisPreDeparture: Icd10Code[];
  diagnosisReported: Icd10Code[];
  relevant: MedicalPreAssessmentRelevantEnum = MedicalPreAssessmentRelevantEnum.YES;
  questions: MedicalPreAssessmentQuestion[];

  recentOtherTreatments: boolean;
  recentMedicationChanges: boolean;
  recentTreatmentPlanChanges: boolean;
  awaitingRecentTreatmentResults: boolean;

  public constructor(init?: Partial<MedicalPreAssessmentQuestionnaire>) {
    Object.assign(this, init); 
    if (!init || !init.diagnosisPreDeparture) {
      this.diagnosisPreDeparture = new Array<Icd10Code>();
    }
    if (!init || !init.diagnosisReported) {
      this.diagnosisReported = new Array<Icd10Code>();
    }
    if(!init || !init.relevant) {
      this.relevant = MedicalPreAssessmentRelevantEnum.YES;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class MedicalPreAssessmentQuestionaireAdapter {
  constructor(private questionAdapter: MedicalPreAssessmentQuestionAdapter,
              private icd10Adapter: MedicalAssessmentIcd10Adapter,
              private relevantEnumAdapter: MedicalPreAssessmentRelevantEnumAdapter){}
  adapt(data: any): MedicalPreAssessmentQuestionnaire{
    return new MedicalPreAssessmentQuestionnaire({
      id: data.id,
      diagnosisPreDeparture: this.icd10Adapter.adapt(data.diagnosisPreDeparture),
      diagnosisReported: this.icd10Adapter.adapt(data.diagnosisReported),
      relevant: this.relevantEnumAdapter.adapt(data.relevant),
      questions: data.questions?.map(question => this.questionAdapter.adapt(question)),
      recentOtherTreatments: data.recentOtherTreatments,
      recentMedicationChanges: data.recentMedicationChanges,
      recentTreatmentPlanChanges: data.recentTreatmentPlanChanges,
      awaitingRecentTreatmentResults: data.awaitingRecentTreatmentResults
    });
  }
}

export class MedicalPreAssessmentQuestion {
  id: number;
  questionKey: string;
  questionText: string;
  answer: boolean;

  public constructor(init?: Partial<MedicalPreAssessmentQuestion>) {
    Object.assign(this, init); 
  }
}

@Injectable({
  providedIn: 'root'
})
export class MedicalPreAssessmentQuestionAdapter {
  adapt(data: any): MedicalPreAssessmentQuestion{
    return new MedicalPreAssessmentQuestion({
      id: data.id,
      questionKey: data.questionKey,
      questionText: data.questionText,
      answer: data.answer
    });
  }
}

export class MedicalPreAssessmentDecision {
  isCovered: boolean

  public constructor(init?: Partial<MedicalPreAssessmentDecision>) {
    Object.assign(this, init);
    if (!init || !init.isCovered) {
      this.isCovered = false;
    }    
  }
}

export class MedicalPreAssessmentNote {
  id: number;
  evaluation: string;
  internalNote: string;
  canFinalizeAssessment: boolean;

  public constructor(init?: Partial<MedicalPreAssessmentNote>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class MedicalPreAssessmentNoteAdapter {
  adapt(data: any): MedicalPreAssessmentNote {
    return new MedicalPreAssessmentNote({
      id: data.id,
      evaluation: data.evaluation,
      internalNote: data.internalNote,
      canFinalizeAssessment : data.canFinalizeAssessment
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class MedicalAssessmentIcd10Adapter {
  adapt(data: any): Icd10Code[] {
    const adapted = new Array<Icd10Code>();
    if (!!data && data.length > 0) {
      data.forEach(value => adapted.push(value));
    }
    return adapted;
  }
  adaptItems(data: any): Icd10Code {
    const adapted = new Array<Icd10Code>();
    return new  Icd10Code({
      icd10Code: data.icd10Code,
      shortName: data.shortName,
      selected: true
    });
  
  }
}

export class MedicalPreAssessmentDecisionDetail {
  id: string;
  templateName: string;
  templateLanguage: string;
  templateId: string;
  endUserDescription: string;
  evaluationDecision: string;
  public constructor(init?: Partial<MedicalPreAssessmentDecisionDetail>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class MedicalPreAssessmentDecisionDetailAdapter {
  adapt(data: any): MedicalPreAssessmentDecisionDetail {
    return new MedicalPreAssessmentDecisionDetail({
      id: data.id,
      templateName: data.templateName,
      templateLanguage: data.templateLanguage,
      templateId: data.templateId,
      endUserDescription: data.endUserDescription,
      evaluationDecision: data.evaluationDecision
    });
  }

}

@Injectable({
  providedIn: 'root'
})
export class MedicalPreAssessmentRelevantEnumAdapter {
  adapt(data: any): MedicalPreAssessmentRelevantEnum {
    if(!data){
      return MedicalPreAssessmentRelevantEnum.YES;
    }
    return data;
  }

}








