import {ServiceOrderExtension} from './service-order-extension';
import {SubRemarksToSupplier} from '@secca/case/components/case-plans/case-plans/add-service-order/models/subRemarksToSupplier';
import {SubReference} from '@secca/case/components/case-plans/case-plans/add-service-order/models/subReference';
import {SubStakeholder, SubStakeholderAdapter} from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholder';
import {NoDurationServiceOrderExtension} from "@secca/shared/models/service-order/no-duration-service-order-extension";
import {MomentHelperService} from "@secca/core/services/moment-helper.service";
import {DateSerializer} from "@secca/shared/models/date-serializer";
import { CommitmentType, CommitmentTypeAdapter } from '../commitment-type';

export class AssistanceOrContactServiceOrderExtension extends NoDurationServiceOrderExtension implements ServiceOrderExtension {
  id: number;
  commitmentType: CommitmentType;
  reference: SubReference;
  stakeholders: SubStakeholder[];
  remarksToSupplier: SubRemarksToSupplier;
  displayDurationId: number;

  public constructor(init?: Partial<AssistanceOrContactServiceOrderExtension>) {
    super();
    Object.assign(this, init);
    if (!init) {
      this.init();
    }
  }

  init() {
    this.reference = new SubReference();
    this.commitmentType = null //TODO new SubCommitmentType(CommitmentTypes.COMMITTED);
    this.remarksToSupplier = new SubRemarksToSupplier();
    this.startDate = MomentHelperService.getSeccaTime().utc();
    this.stakeholders = [new SubStakeholder()];
  }

  getCommitmentType(): CommitmentType {
    return this.commitmentType;
  }

  getSelectedStakeholderIds(): string[] {
    return null;
  }

  isPreviewGopValid(): boolean {
    return false;
  }

  isValid(): boolean {
    let stakeholdersValid = true;
    this.stakeholders.forEach(stakeholder => {
        if (stakeholdersValid) {
          stakeholdersValid = stakeholder.isValid();
        }
      }
    );
    return stakeholdersValid;
  }

  setCommitmentType(commitmentType: CommitmentType) {
    this.commitmentType = commitmentType;
  }
}

export class AssistanceOrContactServiceOrderExtensionAdapter {
  adapt(item?: any): AssistanceOrContactServiceOrderExtension {
    const subStakeholderAdapter = new SubStakeholderAdapter();
    const commitmentTypeAdapter: CommitmentTypeAdapter = new CommitmentTypeAdapter();
    return new AssistanceOrContactServiceOrderExtension({
      id: item.id,
      commitmentType: item.commitmentType == null ? null/* TODO new SubCommitmentType(CommitmentTypes.COMMITTED)*/ : commitmentTypeAdapter.adapt(item.commitmentType),
      reference: item.reference ? new SubReference(item.reference) : new SubReference(),
      remarksToSupplier: item.remarksToSupplier ? new SubRemarksToSupplier(item.remarksToSupplier) : new SubRemarksToSupplier(),
      startDate: DateSerializer.deserialize(item.startDate),
      stakeholders: item.stakeholders != null ? item.stakeholders.map(data => subStakeholderAdapter.adapt(data)) : [new SubStakeholder()]
    });
  }
}
