<div class="refunds-panel">
  <div class="message-content p-3">
    <div class="row">
      <div class="col-12">
          <div class="row">
            <div class="col-5 no-right-padding">
              <div class="message-content-header">
                <div class="heading"><span><img src="/assets/icons/service-order-type/OtherRefund.svg"></span>
                </div>
                <div class="text">{{'task-web-modal-refunds' | translate}}</div>
              </div>
            </div>
            <div *ngIf="refundItems[0].cancellation" class="col-7 no-left-padding">
              <div class="message-content-header right-header">
                <div class="heading"><span><img src="/assets/icons/Cancellation.svg"></span>
                </div>
                <div class="text">{{'task-web-modal-cancellation' | translate}}</div>
              </div>
            </div>
          </div>
          <div class="row">
              <div class="col-12">
                  <div class="message-content-box">
                      <div class="row">
                        <div class="col-12 left-indent">
                          <div class="row">                          
                            <!-- Service item -->
                            <div class="col-2 message-content-box-col header">
                                {{'task-web-modal-service-item' | translate}}
                            </div>
                            <div class="col-1 message-content-box-col header">
                                {{'task-web-modal-amount' | translate}}
                            </div>
                            <div class="col-1 message-content-box-col header">
                              {{'task-web-modal-currency' | translate}}
                            </div>
                            <div class="col-1 message-content-box-col header" [ngClass]="{'right-border': refundItems[0].cancellation}">
                                {{'task-web-modal-invoice-date' | translate}}
                            </div>

                            <!-- Cancellation -->
                            <div *ngIf="refundItems[0].cancellation" class="col-1 message-content-box-col header">
                                {{'task-web-modal-booking-date' | translate}}
                            </div>
                            <div *ngIf="refundItems[0].cancellation" class="col-1 message-content-box-col header">
                              {{'task-web-modal-trip-cancelled' | translate}}
                            </div>
                            <div *ngIf="refundItems[0].cancellation" class="col-1 message-content-box-col header">
                                {{'task-web-modal-cancellation-date' | translate}}
                            </div>
                            <div *ngIf="refundItems[0].cancellation" class="col-1 message-content-box-col header">
                                {{'task-web-modal-full-payment' | translate}}
                            </div>
                            <div *ngIf="refundItems[0].cancellation" class="col-1 message-content-box-col header">
                                {{'task-web-modal-supplier-refund' | translate}}
                            </div>                    
                            <div *ngIf="refundItems[0].cancellation" class="col-1 message-content-box-col header">
                                {{'task-web-modal-supplier-refund-amount' | translate}}
                            </div>
                            <div *ngIf="refundItems[0].cancellation" class="col-1 message-content-box-col header">
                              {{'task-web-modal-currency' | translate}}
                            </div>
                          </div>
                        </div>
                      </div>                      
                      <div class="row" *ngFor="let refund of refundItems">
                        <div class="col-12 left-indent">
                          <div class="row" [ngClass]="{'content-padding-bottom': refund.remarks}">
                            <!-- Service item -->
                            <div class="col-2 message-content-box-col">
                                <app-task-web-property [properties]="refund" key="serviceItemCode"
                                    [serviceItemList]="serviceItemList"
                                    [purposeOfTravelItemList]="purposeOfTravelItemList" [causes]="causes" [diagnoses]="diagnoses"
                                    [customerProfiles]="customerProfiles">
                                </app-task-web-property>
                            </div>
                            <div class="col-1 message-content-box-col">
                                <app-task-web-property [properties]="refund" key="expenseAmount" type="amount"
                                    [serviceItemList]="serviceItemList"
                                    [purposeOfTravelItemList]="purposeOfTravelItemList" [causes]="causes" [diagnoses]="diagnoses"
                                    [customerProfiles]="customerProfiles">
                                </app-task-web-property>
                            </div>
                            <div class="col-1 message-content-box-col">
                              <app-task-web-property [properties]="refund" key="expenseCurrency"
                                  [serviceItemList]="serviceItemList"
                                  [purposeOfTravelItemList]="purposeOfTravelItemList" [causes]="causes" [diagnoses]="diagnoses"
                                  [customerProfiles]="customerProfiles">
                              </app-task-web-property>
                            </div>
                            <div class="col-1 message-content-box-col" [ngClass]="{'right-border': refundItems[0].cancellation}">
                                <app-task-web-property [properties]="refund" key="expensePaymentDate"
                                    [serviceItemList]="serviceItemList"
                                    [purposeOfTravelItemList]="purposeOfTravelItemList" [causes]="causes" [diagnoses]="diagnoses"
                                    [customerProfiles]="customerProfiles">
                                </app-task-web-property>
                            </div>

                            <!-- Cancellation -->
                            <div *ngIf="refund.cancellation" class="col-1 message-content-box-col">
                              <app-task-web-property [properties]="refund?.cancellation" key="bookingDate"
                                  [serviceItemList]="serviceItemList"
                                  [purposeOfTravelItemList]="purposeOfTravelItemList" [causes]="causes" [diagnoses]="diagnoses"
                                  [customerProfiles]="customerProfiles">
                              </app-task-web-property>
                          </div>
                          <div *ngIf="refund.cancellation" class="col-1 message-content-box-col">
                            <app-task-web-property [properties]="refund?.cancellation" key="cancellation" type="boolean"
                                [serviceItemList]="serviceItemList"
                                [purposeOfTravelItemList]="purposeOfTravelItemList" [causes]="causes" [diagnoses]="diagnoses"
                                [customerProfiles]="customerProfiles">
                            </app-task-web-property>
                          </div>
                          <div *ngIf="refund.cancellation" class="col-1 message-content-box-col">
                              <app-task-web-property [properties]="refund?.cancellation" key="cancellationDate"
                                  [serviceItemList]="serviceItemList"
                                  [purposeOfTravelItemList]="purposeOfTravelItemList" [causes]="causes" [diagnoses]="diagnoses"
                                  [customerProfiles]="customerProfiles">
                              </app-task-web-property>
                          </div>
                          <div *ngIf="refund.cancellation" class="col-1 message-content-box-col">
                            <app-task-web-property [properties]="refund?.cancellation" key="paymentTypeInFull" type="boolean"
                                [serviceItemList]="serviceItemList"
                                [purposeOfTravelItemList]="purposeOfTravelItemList" [causes]="causes" [diagnoses]="diagnoses"
                                [customerProfiles]="customerProfiles">
                            </app-task-web-property>
                          </div>
                          <div *ngIf="refund.cancellation" class="col-1 message-content-box-col">
                            <app-task-web-property [properties]="refund?.cancellation" key="moneyRefunded" type="boolean"
                                [serviceItemList]="serviceItemList"
                                [purposeOfTravelItemList]="purposeOfTravelItemList" [causes]="causes" [diagnoses]="diagnoses"
                                [customerProfiles]="customerProfiles">
                            </app-task-web-property>
                          </div>
                          <div *ngIf="refund.cancellation" class="col-1 message-content-box-col">
                              <app-task-web-property [properties]="refund?.cancellation" key="supplierReimbursedAmount" type="amount"
                                  [serviceItemList]="serviceItemList"
                                  [purposeOfTravelItemList]="purposeOfTravelItemList" [causes]="causes" [diagnoses]="diagnoses"
                                  [customerProfiles]="customerProfiles">
                              </app-task-web-property>
                          </div>
                          <div *ngIf="refund.cancellation" class="col-1 message-content-box-col">
                              <app-task-web-property [properties]="refund?.cancellation" key="supplierReimbursedCurrency"
                                  [serviceItemList]="serviceItemList"
                                  [purposeOfTravelItemList]="purposeOfTravelItemList" [causes]="causes" [diagnoses]="diagnoses"
                                  [customerProfiles]="customerProfiles">
                              </app-task-web-property>
                          </div>
                        </div>
                        <div class="row" *ngIf="refund.remarks">
                          <div class="col-5 message-content-remarks" [ngClass]="{'right-border': refundItems[0].cancellation}">
                            {{'task-web-modal-remark' | translate}}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-5 message-content-remarks" [ngClass]="{'remark-padding-top': refund.remarks,'right-border': refundItems[0].cancellation}" *ngIf="refund.remarks">
                            <app-task-web-property [properties]="refund" key="remarks"
                                [serviceItemList]="serviceItemList"
                                [purposeOfTravelItemList]="purposeOfTravelItemList" [causes]="causes" [diagnoses]="diagnoses"
                                [customerProfiles]="customerProfiles">
                            </app-task-web-property>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>          
          </div>
          <br>
          <div class="property-container">
            <div class="property-name">{{'task-web-properties-refund-currency' | translate}}:</div>
            <div class="property-value-container">
              <app-task-web-property [properties]="taskDataViewModel?.data?.refund" key="currency"
                [serviceItemList]="serviceItemList"
                [purposeOfTravelItemList]="purposeOfTravelItemList" [causes]="causes" [diagnoses]="diagnoses"
                [customerProfiles]="customerProfiles">
              </app-task-web-property>
            </div>
          </div>    

      </div>
    </div>
  
  </div>
</div>