import { Component, Input } from '@angular/core';
import { CauseItemType } from '@secca/shared/models/cause-item-type';
import { SimpleCustomerProfile } from '@secca/shared/models/simpleCustomerProfile';
import { StakeholderTypeEnum } from '@secca/shared/models/enums';
import { PurposeOfTravelItemType } from '@secca/shared/models/purpose-of-travel-item-type';
import { ServiceItemType } from '@secca/shared/models/service-item-type';
import { Diagnosis } from '@secca/shared/models/dignosis';

@Component({
  selector: 'app-task-web-co-traveller',
  templateUrl: './task-web-co-traveller.component.html',
  styleUrls: ['./task-web-co-traveller.component.scss'],
})
export class TaskWebCoTravellerComponent {
  @Input() cotraveller: any;

  @Input() serviceItemList: ServiceItemType[];
  @Input() purposeOfTravelItemList: PurposeOfTravelItemType[];
  @Input() causes: Map<string, CauseItemType[]>;
  @Input() diagnoses: Diagnosis[];
  @Input() customerProfiles: SimpleCustomerProfile[];

  @Input() ignoredProperties = ['id', 'properties', 'stakeholderType', 'nationalIdValid', 'isCompany'];

  constructor() {}

  isEnduser(cotraveller: any): boolean {
    return cotraveller?.properties?.includes(StakeholderTypeEnum.endUser);
  }

  isPolicyHolder(cotraveller: any): boolean {
    return cotraveller?.properties?.includes(StakeholderTypeEnum.policyHolder);
  }

  isReporter(cotraveller: any): boolean {
    return cotraveller?.properties?.includes(StakeholderTypeEnum.reporter);
  }
}
