import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-medical-assessment-card-title',
  templateUrl: './medical-assessment-card-title.component.html',
  styleUrls: ['./medical-assessment-card-title.component.scss']
})
export class MedicalAssessmentCardTitleComponent implements OnInit {

  @Input() title: string;
  @Input() valid: boolean;

  ngOnInit(): void {
  }

  get cardTitle(): string {
    return this.title;
  }
}
