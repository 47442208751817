<div class="card">
  <div class="sub-component-placeholder">
    <div class="sub-component-container" >
      <div class="sub-title">{{"service-order-remarks" | translate}}</div>
        <app-text-box [name]="'translation-remarks' | translate"
                      [maxTextLength]="1000"
                        [required]="false"
                      [(value)]="translationCall.adviceGiven">
        </app-text-box>
    </div>
  </div>
  <div class="sub-component-placeholder">
    <div class="col-5 p-0">
      <app-sub-commitment-type
        [(commitmentType)]="translationCall.commitmentType"
        [serviceOrderType]="serviceOrderType"
        [caseId]="caseId"
        ></app-sub-commitment-type>
      </div>
  </div>
  <div class="sub-component-placeholder">
    <div class="col-5 p-0">
      <app-input-date-time
        [name]="'general-advice-start-date' | translate"
        (dateChange)="startDateChange($event)"
        [required]="true"
        [withImage]="true"
        [date]="translationCall.startDate"
        [timeZone]="'secca'"
      >
      </app-input-date-time>
    </div>
</div>
</div>
