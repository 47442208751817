import {Questions, QuestionsAdapter} from '@secca/shared/models/questions';
import {Injectable} from '@angular/core';
import {Adapter} from '@secca/shared/interfaces/adapter';

export class QuestionnaireDto {
  diagnosisKey: string;
  diagnosis: string;
  questions: Questions[];

  public constructor(init?: Partial<QuestionnaireDto>) {
    Object.assign(this, init);
  }
}


@Injectable({
  providedIn: 'root',
})
export class QuestionnaireDtoAdapter implements Adapter<QuestionnaireDto> {

  constructor(private questionsAdapter: QuestionsAdapter) {
  }

  adapt(item: any): QuestionnaireDto {
    return new QuestionnaireDto({
      diagnosisKey: item.diagnosisKey,
      diagnosis: item.diagnosis,
      questions: item.questions ? item.questions.map(question =>
        this.questionsAdapter.adapt({questionKey: question.questionKey,
                                        question: question.question,
                                        answerKey: question.answerKey,
                                        answer: question.answer
        })) : []
    });
  }
}
