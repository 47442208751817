import { ConsentStatusEnum, FinancialStatusEnum, HandlingAreaStatusEnum, MedicalPreAssessmentDecisionEnum, PermissionEnum, PermissionHideTypeEnum } from '@secca/shared/models/enums';
import { CaseValidationService } from 'src/app/core/services/case-validation.service';
import { TaskService } from '@secca/core/services/task.service';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { CaseSelectedMenuEnum } from '@secca/shared/enums/case-selected-menu-enum.component';
import { MenuService } from '@secca/core/services/menu.service';
import { CaseService } from 'src/app/core/services/case.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Case } from 'src/app/shared/models/case';
import { AutoUnsubscribe } from 'src/app/shared/decorators/auto-unsubscribe';
import { DataService } from 'src/app/core/services/data.service';
import { CaseLocalTimeService } from '../../components/case-local-time/case-local-time.service';
import { CasePrimaryContactService } from '../../components/case-primary-contact/case-primary-contact.service';
import { CaseStakeholderAdapter } from 'src/app/shared/models/caseStakeholder';
import { InsuranceService } from '@secca/core/services/insurance.service';
import { tap } from 'rxjs/operators';
import { LockContextEnum } from '@secca/shared/enums/lock-context.enum';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import { CaseLockService } from '@secca/core/services/case-lock.service';
import { StakeholderService } from '@secca/core/services/stakeholder.service';
import { CaseConsentComponent } from '@secca/case/components/case-consent/case-consent.component';
import { Subscription } from 'rxjs';
import { OpenCaseLimitService } from '@secca/core/services/open-case-limit.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SimpleCaseStakeholder } from '@secca/shared/models/simpleCaseStakeholder';
import { DigitalConsentService } from '@secca/core/services/digital-consent.service';
import { DigitalConsent } from '@secca/shared/models/digital-consent';
import { HandlingArea } from '@secca/shared/models/handlingArea';
import { CasePlansService } from '@secca/case/components/case-plans/case-plans.service';
import { HandlingAreaService } from '@secca/core/services/handling-area.service';
import { Coverage } from '@secca/shared/models/coverage';
import { PlanService } from '@secca/core/services/plan.service';
import {CaseTypesService} from '@secca/core/services/case-type.service';
import {CaseType, CaseTypeCodes} from '@secca/shared/models/case-type';
import {RefundService} from '@secca/core/services/refund.service';
import { FinancialStatusService } from '@secca/core/services/financial-status.service';
import { FinancialStatus } from '@secca/shared/models/financial-status';
import { SensitiveType } from '@secca/shared/models/enums';
import { result } from 'lodash';
import {CaseLinkedCasesService} from "@secca/core/services/case-linked-cases.service";
import {MedicalPreAssessmentService} from "@secca/core/services/medical-pre-assessment.service";
import { IncidentStateService } from '@secca/core/state-services/incident-state-service';
import { CaseIncidentIdWithCaseId } from '@secca/shared/models/caseIncidentWithCaseId';
import { CaseIncident } from '@secca/shared/models/caseIncident';
import { IncidentService } from '@secca/core/services/incident.service';
import { SimpleIncident } from '@secca/shared/models/SimpleIncident';
import { CAUSES_LEVEL1_CODES } from '@secca/shared/models/CausesLevel1DD';
import { CAUSES_LEVEL2_CODES } from '@secca/shared/models/CausesLevel2DD';
import { CoordinationCaseService } from '@secca/core/services/coordination-case.service';
import { count } from 'console';
@Component({
  selector: 'app-case-page',
  templateUrl: './case-page.component.html',
  styleUrls: ['./case-page.component.scss'],
})
@AutoUnsubscribe
export class CasePageComponent implements OnInit {
  selectedMenuNumber: number;
  id: string;
  caseContainer: Case;
  caseType: CaseType;
  newCase: boolean;
  $routeSubscr: any;
  consent: DigitalConsent;
  loaded: boolean;
  caseTypeManuallySet: boolean;
  caseClosed = false;
  financialStatus: FinancialStatus = null;
  $verificationSubscr: Subscription;
  $caseSubscr: Subscription;
  $editServiceSubscr: Subscription;
  $newServiceSubscr: Subscription;
  $coveragesSubscr: Subscription;
  $financialStatusSubscription: Subscription;
  $medicalPreAssessmentSubscription: Subscription;
  caseRefundsSubscr$: Subscription;
  private numberOfLinkedCases: number = -1;
  private numberOfCoordinationCases: number = 0;
  private numberOfCoordinationCasesOnCase: number = 0;
  linkedCasesSubscription: Subscription;
  $coordinatedCasesSubscription: Subscription;
  $caseInfoRefreshSubscription: Subscription;
  $caseTypeRefreshSubscription: Subscription;
  $handlingAreaRefreshSubscription: Subscription;


  PRIMARY_CONTACT_PROPERTY_VALUE = 'PRIMARY_CONTACT';
  @ViewChild('digitalConsent') digitalConsentComponent: CaseConsentComponent;
  private FINAL_CONSENT_STATUSES = [ConsentStatusEnum.CONSENT_ACCEPTED, ConsentStatusEnum.CONSENT_REJECTED,
    ConsentStatusEnum.CONSENT_ACCEPTED_MAN, ConsentStatusEnum.CONSENT_ACCEPTED_WEB];
  handlingAreas: HandlingArea[];
  coverages: Coverage[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private caseService: CaseService,
    private menuService: MenuService,
    private dataService: DataService,
    private localTimeService: CaseLocalTimeService,
    private primaryContactService: CasePrimaryContactService,
    private caseStakeholderAdapter: CaseStakeholderAdapter,
    private caseStateService: CaseStateService,
    private taskService: TaskService,
    private caseValidationService: CaseValidationService,
    private insuranceService: InsuranceService,
    private caseLockService: CaseLockService,
    private caseLockHelperService: CaseLockHelperService,
    private stakeholderService: StakeholderService,
    private openCaseLimitService: OpenCaseLimitService,
    private dialog: MatDialog,
    private planService: PlanService,
    private consentService: DigitalConsentService,
    private casePlanService: CasePlansService,
    private handlingAreaService: HandlingAreaService,
    private caseTypeService: CaseTypesService,
    private refundService: RefundService,
    private financialStatusService: FinancialStatusService,
    private caseLinkedCasesService: CaseLinkedCasesService,
    private coordinationCaseService: CoordinationCaseService,
    private medicalPreAssessmentService: MedicalPreAssessmentService,
    private incidentService: IncidentService,
    private incidentStateService: IncidentStateService
  ) {
    const state = this.router.getCurrentNavigation().extras.state;
    this.newCase = state ? state.newCase : false;
    stakeholderService.initPreferredLanguagesAndChannels();
    this.clearCaches();
  }

  private consentEnabledCase = false;

  get CaseSelectedMenuEnum() {
    return CaseSelectedMenuEnum;
  }

  ngOnInit() {
    this.$routeSubscr = this.route.params.subscribe(params => {
      // at this moment the displayed data is invalid (components may have the data from the old case).
      this.loaded = false;
      this.consentEnabledCase = false;
      this.coverages = [];
      this.dataService.setIsCaseLoading(true);
      this.id = params.id;
      this.consent = null;
      this.newCase = !!history.state.newCase;
      const isAlreadyOpened = this.dataService.isCaseOpened(+this.id);
      if (this.taskService.taskRedirectFromGlobalTaskBoard != null && this.taskService.taskRedirectFromGlobalTaskBoard.directRedirection) {
        this.taskService.taskRedirectFromGlobalTaskBoard.directRedirection = false;
        this.menuService.send(+this.id, CaseSelectedMenuEnum.CaseTask);
      } else if (this.dataService.navigateSupplierInvoiceId || this.dataService.navigateSalesOrderId || this.dataService.navigateRefundId || this.dataService.navigateRecoveryId || this.dataService.navigateRecoveryPaymentId) {
        this.menuService.send(+this.id, CaseSelectedMenuEnum.CaseEconomy);
      } else if (!!this.dataService.navigateMedical) {
        this.menuService.send(+this.id, CaseSelectedMenuEnum.CaseMedical);
      }
      this.dataService.send({ caseId: +this.id, newlyCreated: this.newCase });
      if (!isAlreadyOpened) {
        this.openCaseLimitService.verifyOpenCasesCount();
      }
      this.updateConsent(this.id);
      this.takeLockAndGetCase();

      this.planService.getCoveragesForCase(this.id)
      .subscribe(result => {
        this.coverages = result;
        this.casePlanService.sendCoverages(result), error => console.log(error)
       });

      this.getCaseType();

      if(!this.newCase) {
        this.loadFinancialStatus(this.id);
      }

      this.caseLinkedCasesService.countLinkedCasesByCaseId(this.id).subscribe(
        numberOfLinkedCases => {
          this.numberOfLinkedCases = numberOfLinkedCases === 0 ?  numberOfLinkedCases : (numberOfLinkedCases - 1);
          this.coordinationCaseService.countCoordinationCasesByLinkedCaseId(this.id).subscribe(
            count => {
              this.numberOfCoordinationCasesOnCase = count;
              this.numberOfLinkedCases = this.numberOfLinkedCases + this.numberOfCoordinationCasesOnCase
            }
          );
        }
      );

      this.coordinationCaseService.countLinkedCasesByCoordinationCaseId(this.id).subscribe(
        count => {
          this.numberOfCoordinationCases = count;
        }
      );

      this.linkedCasesSubscription = this.caseLinkedCasesService.getLinkedCasesChanges(this.id).subscribe(
        count => {
          if(!!count) {
            this.numberOfLinkedCases = count !== -1 ? count + this.numberOfCoordinationCasesOnCase : this.numberOfCoordinationCasesOnCase;
          }
        }
      );

      this.$coordinatedCasesSubscription = this.coordinationCaseService.getCoordinationCasesChanges(this.id).subscribe(
        count => {
          this.numberOfCoordinationCases = count;
        }
      );
    });

    this.$caseSubscr = this.caseStateService.getCase().subscribe(evt => {
      if (evt && evt.incident &&
          (evt.incident.causeLevel1Code === CAUSES_LEVEL1_CODES.MEDICAL ||
           (evt.incident.causeLevel1Code === CAUSES_LEVEL1_CODES.PRE_DEPARTURE && evt.incident.causeLevel2Code === CAUSES_LEVEL2_CODES.PRE_ASSESSMENT_TRAVEL) ||
           (evt.incident.causeLevel1Code === CAUSES_LEVEL1_CODES.PRE_DEPARTURE && evt.incident.causeLevel2Code === CAUSES_LEVEL2_CODES.PRE_ASSESSMENT_CANCELLATION))) {
        this.consentEnabledCase = true;
        setTimeout(() => {
          this.updateConsent(this.id);
        }, 1000);
      } else {
        this.consentEnabledCase = false;
      }
    });

    this.$coveragesSubscr = this.casePlanService.getCoverages().subscribe(result => {
      if (result != null) {
        //this.coverages = result;
        this.reloadHandlingAreas();
      }
    });

    this.$editServiceSubscr = this.casePlanService.getEditedServiceOrder().subscribe(result => {
      if (result) {
        this.getCaseType();
      }
    });
    this.$newServiceSubscr = this.casePlanService.getNewServiceOrder().subscribe(result => {
      if (result) {
        this.getCaseType();
      }
    });

    this.caseRefundsSubscr$ = this.refundService.getCaseRefunds().subscribe(refunds => {
      this.getCaseType();
    });

    this.$financialStatusSubscription = this.financialStatusService.getCaseFinancialStatus().subscribe(response => {
      this.setFinancialStatus(response);
    });

    this.$caseInfoRefreshSubscription = this.caseStateService.getRefreshCaseInfo().subscribe(() => {
      this.refreshCaseType();
      this.refreshTopTabsIncidentLocation();
      this.reloadHandlingAreas();
      this.checkCaseNumberAssigned();
    });

    this.$caseTypeRefreshSubscription = this.caseStateService.getRefreshCaseType().subscribe(() => {
      this.refreshCaseType();
    });

    this.$handlingAreaRefreshSubscription = this.caseStateService.getRefreshHandlingArea().subscribe(() => {
      this.reloadHandlingAreas();
    });
  }

  refreshCaseType() {
    this.getCaseType();
  }

  refreshChannelType(channelType: string) {
    if(this.caseContainer) {
      this.caseContainer.channelType = channelType;
    }
  }

  resetCaseType() {
    this.caseTypeService.resetCaseTypeEvaluationByCaseId(this.id).subscribe(result => {
      this.caseTypeManuallySet = false;
      this.caseType = result;
      this.caseStateService.sendCaseType(this.caseType);
    });
  }

  private getCaseType() {
    this.caseTypeService.getCaseTypeByCaseId(this.id).subscribe(result => {
      if (!!this.caseType && this.caseType.code !== result.code) {
        // When caseType changes: Possible change of customer profile on backend. Need to neglect cache and force backend call to fetch correct personInsurance
        this.insuranceService.getPersonInsurance(this.id, true);
      }
      this.caseType = result;
      if(this.caseContainer) {
        this.caseContainer.caseTypeCode = result.code;
      }
      this.caseStateService.sendCaseType(this.caseType);
    });
  }

  consentChanged(newConsent: DigitalConsent) {
    this.consent = newConsent;
    this.caseStateService.updatedConsent(this.consent);
  }

  sensitiveTypeChanged(sensitivChangeInformation: any) {
    this.caseContainer.sensitiveType = sensitivChangeInformation.sensitiveType;
    this.caseService.updateSensitiveType(this.id, sensitivChangeInformation.sensitiveType, sensitivChangeInformation.cause).subscribe(
      result => {
        this.caseStateService.sendCase(result);
      }
    )
  }

  private takeLockAndGetCase() {
    this.caseLockService
      .tryLockCase(this.id, LockContextEnum.CASE_BASIC)
      .pipe(tap(() => this.getCase()))
      .subscribe();
  }

  private updateConsent(caseId) {
    this.consentService.getDigitalConsentStatus(caseId).subscribe(result => {
      this.consent = result;
      this.caseStateService.updatedConsent(this.consent);
    });
  }

  private getCase(): void {
    this.caseService.getCase(this.id).subscribe(
      result => {
        this.caseLockHelperService.updateCaseLocks(this.id, result.caseLocks);
        this.caseContainer = result;
        this.changeHandlingArea(this.caseContainer.handlingAreas);

        this.caseStateService.sendCase(result);
        this.updateConsent(this.id);
        // at this moment the displayed data is valid.
        this.selectedMenuNumber = this.menuService.getSelectedMenuId(+this.caseContainer.id);
        if (this.selectedMenuNumber == null) {
          this.selectedMenuNumber = CaseSelectedMenuEnum.CaseBasics;
        }
        this.checkCaseNumberAssigned();
        this.initLocalTimeWidget();
        this.initPrimaryContactWidget(result);
        this.updatePolicyholder();
        this.updateMedicalPreAssessmentDecision();
        this.insuranceService.getPersonInsurance(this.id);
        this.caseTypeManuallySet = result.caseTypeCodeManuallySet;
        this.dataService.setIsCaseLoading(false);
        this.loaded = true;
      },
      error => {
        console.log(error);
        this.dataService.setIsCaseLoading(false);
        this.loaded = true;
      }
    );
  }

  private checkCaseNumberAssigned() {
    this.caseValidationService.isCaseNumberAssigned(this.id).subscribe(result => {
      if (result) {
        this.caseValidationService.sendVerifyCaseNumberAssigned(this.id);
      } else {
        this.subscribeForCaseNumberAssigned();
      }
    });
  }

  private subscribeForCaseNumberAssigned() {
    if (this.$verificationSubscr) {
      this.$verificationSubscr.unsubscribe();
    }
    this.$verificationSubscr = this.caseValidationService.getCaseNumberAssignedConfirmation().subscribe(result => {
      if (result != null && this.id != null && this.id === result.toString()) {
        this.takeLockAndGetCase();
        // When case is validated ok and case number is assigned, the backend has added the customer profile to the case as stakeholder. Need to update the stakeholder list in case notes
        this.caseService.getStakeholderForCase(this.id, 'CUSTOMER').subscribe(customerStakeholder => {
          if (!!customerStakeholder) {
            this.caseStateService.updatedStakeholder(customerStakeholder);
          }
        });
        this.$verificationSubscr.unsubscribe();
      }
    });
  }

  private clearCaches() {
    this.insuranceService.clearInsuranceCache();
  }

  private refreshTopTabsIncidentLocation(): void {
    this.incidentService.getCaseIncident(this.caseContainer.incident.id).subscribe(incident => {
      this.incidentStateService.sendIncident(incident, this.caseContainer.id);
    });
  }

  updatePolicyholder() {
    this.caseService.getStakeholderForCase(this.id, 'POLICYHOLDER').subscribe(
      result => this.caseService.publishPolicyholder(this.caseStakeholderAdapter.adapt(result)),
      error => console.error(error)
    );
  }

  updateMedicalPreAssessmentDecision() {
    this.medicalPreAssessmentService.getNewestMedicalPreAssessment(+this.id).subscribe(
      result => {
        if(!!result) {
          this.caseContainer.medicalPreAssessmentDecision = result.decision;
        } else {
          this.caseContainer.medicalPreAssessmentDecision = null;
        }
      }
    )

    this.$medicalPreAssessmentSubscription = this.medicalPreAssessmentService.getMedicalPreAssessmentDecisionChanges(+this.id).subscribe(
      result => {
          this.caseContainer.medicalPreAssessmentDecision = result;
      }
    )
  }

  initLocalTimeWidget() {
    this.localTimeService.clearLocalTimeZone();
    this.localTimeService.setCurrentLocation(
      this.caseContainer.currentTimeZone,
      this.caseContainer.incident.currentCity,
      this.caseContainer.incident.currentCountry,
      this.caseContainer.incident.currentCountryCodeAlpha2
    );
    this.localTimeService.setIncidentLocation(
      this.caseContainer.incidentTimeZone,
      this.caseContainer.incident.incidentCity,
      this.caseContainer.incident.incidentCountry,
      this.caseContainer.incident.countryCodeAlpha2
    );
  }

  initPrimaryContactWidget(caseContainer: Case) {
    this.primaryContactService.updatePrimary(null, null);
    const primaryContactStakeholder = this.getPrimaryContact(caseContainer);
    if (primaryContactStakeholder) {
      this.caseService.getCaseStakeholder(this.id, primaryContactStakeholder.id.toString()).subscribe(caseStakeholder => {
        caseStakeholder.primaryContact = true;
        this.primaryContactService.updatePrimary(caseStakeholder.stakeholderType, caseStakeholder);
      });
    }
  }

  getPrimaryContact(caseContainer: Case): SimpleCaseStakeholder {
    return caseContainer.caseStakeholders.find(
      stakeholder => !!stakeholder.properties.find(p => p === this.PRIMARY_CONTACT_PROPERTY_VALUE)
    );
  }

  onMenuClick(menuNumber: number) {
    this.selectedMenuNumber = menuNumber;
    this.menuService.send(+this.caseContainer.id, menuNumber);
    if (this.consent && this.consentEnabledCase && !this.FINAL_CONSENT_STATUSES.includes(this.consent.consentType)) {
      this.updateConsent(this.caseContainer.id);
    }
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  get PermissionHideTypeEnum() {
    return PermissionHideTypeEnum;
  }

  private isCaseClosed(aCase: Case, handlingAreas: HandlingArea[]) : boolean {
    const handlingAreasClosed = this.isHandlingAreasClosed(handlingAreas);
    return handlingAreasClosed;
  }

  private isHandlingAreasClosed(handlingAreas: HandlingArea[]) {
    let allHandlingAreasClosed = true;
    for (let index = 0; index < handlingAreas.length; index++) {
      const handlingArea = handlingAreas[index];
      if (handlingArea.status !== HandlingAreaStatusEnum.CLOSED) {
        allHandlingAreasClosed = false;
        break;
      }
    }
    return allHandlingAreasClosed;
  }

  changeHandlingArea($event) {
    this.handlingAreas = $event;
    this.caseClosed = this.isCaseClosed(this.caseContainer, this.handlingAreas);
    this.caseStateService.isCaseClosed = this.caseClosed;
  }

  private reloadHandlingAreas() {
    this.handlingAreaService.getHandlingsAreas(Number(this.id)).subscribe(result => {
      this.handlingAreas = result;
      //this.handlingAreaService.setCaseHandlingsAreas(result);
    });
  }

  private loadFinancialStatus(caseId: string): void {
    this.financialStatusService.getStatus(caseId).subscribe(response => {
      this.setFinancialStatus(response);
    });
  }

  private setFinancialStatus(financialStatus: FinancialStatus) {
    if ( financialStatus ) {
      this.financialStatus = financialStatus;
      this.caseStateService.isInvoiceClosedWithNoInvoicingToCustomer = FinancialStatusEnum.CLOSED_NO_INVOICE === financialStatus.status;
    }
  }

  get getValidatedOn(): Date {
      if (this.caseContainer.validatedOn) {
        return this.caseContainer.validatedOn;
      } else {
        return this.caseContainer.caseCreatedDate;
      }
  }
}
