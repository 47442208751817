import { Component, Input, OnInit } from '@angular/core';
import { PersonInsurance, PersonInsuranceAdapter } from '@secca/shared/models/person-insurance';
import { CaseHistoryEntry } from '@secca/shared/models/caseHistoryEntry';
import { CaseHistoryType } from '@secca/shared/enums/case-history-type';

@Component({
  selector: 'app-case-summary-policy',
  templateUrl: './case-summary-policy.component.html',
  styleUrls: ['./case-summary-policy.component.scss']
})
export class CaseSummaryPolicyComponent implements OnInit {

  historyEntry: CaseHistoryEntry;
  insurance: PersonInsurance;
  customerProfileName: string;

  constructor(
    private personInsuranceAdapter: PersonInsuranceAdapter
  ) {
  }

  @Input() set entry(entry: CaseHistoryEntry) {
    this.historyEntry = entry;
    if (entry != null) {
      this.insurance = this.personInsuranceAdapter.adapt(this.historyEntry.propertyData.value.insurance);
      this.customerProfileName = this.historyEntry.propertyData.value.customerProfileName;
    }
  }

  ngOnInit(): void {
  }

  get caseHistoryType(): typeof CaseHistoryType {
    return CaseHistoryType;
  }

  private getPolicyNumber(): string {
    return this.insurance.creditCard ? this.insurance.creditCardNo : this.insurance.policyReference;
  }

  private getCustomerProductName(): string {
    return this.insurance.insuranceLookupPolicy?.product?.customerProductName;
  }

  private getInsuranceLevel(): string {
    return this.insurance.insuranceLookupPolicy?.insuranceLevel;
  }

  private getVersion(): string {
    const version = this.insurance.insuranceLookupPolicy?.product?.version;
    return version?.length ? '(' + version + ')' : '';
  }

}
