import {StakeholderService} from 'src/app/core/services/stakeholder.service';
import {InputValidationService} from '../../../core/services/input-validation.service';
import {StakeholderTypeEnum, ValidationTypeEnum} from '../../models/enums';
import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {OnSaveData} from '../../interfaces/on-save-data';
import {NgSwitch} from '@angular/common';
import {CaseStakeholder} from '@secca/shared/models/caseStakeholder';

@Component({
  selector: 'app-drop-down-stakeholder-with-icon',
  templateUrl: './drop-down-stakeholder-with-icon.component.html',
  styleUrls: ['./drop-down-stakeholder-with-icon.component.scss'],
  providers: [NgSwitch],
  encapsulation: ViewEncapsulation.None
})
export class DropDownStakeholderWithIconComponent implements OnInit, OnSaveData {
  @Input() name: string;
  @Input() standardDropdownIcon: boolean;
  @Input() sortingStyle: boolean;
  @Input()
  set items(newItems: CaseStakeholderSearch[]) {
    if (newItems !== undefined) {
      this._items = newItems;
      this.updateDate();
    }
  }
  get items(): CaseStakeholderSearch[] {
    return this._items;
  }
  private _items: CaseStakeholderSearch[];
  @Input() withCountryFlag: boolean;
  @Input() countries: string[];
  @Input() selectedItemId: CaseStakeholderSearch;
  @Input() validate: boolean;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() validationType: ValidationTypeEnum;
  @Input() requiredRedBackground: boolean = false;
  @Output() selectedItemIdChange = new EventEmitter();
  @Output() saveModel = new EventEmitter();
  @Output() open = new EventEmitter();

  oldSelectedItemId: CaseStakeholder;
  constructor(private inputValidationService: InputValidationService, private stakeholderService: StakeholderService) {}

  onChange() {
    this.selectedItemIdChange.emit(this.selectedItemId);
  }

  onOpen() {
    this.open.emit();
  }

  ngOnInit() {}

  onSaveModel() {
    if (this.oldSelectedItemId !== this.selectedItemId) {
      this.saveModel.emit();
    }
  }
  onSaveState() {
    this.oldSelectedItemId = this.selectedItemId;
  }

  updateDate() {
    this.items.forEach(item => {
      if(!item.company) {
        item.nameForSearch = [item.person.firstName, item.person.surname].filter(Boolean).join(' ');
      } else {
        item.nameForSearch = item.company.name || '';
      }
    })
  }

  isValidated(val: any) {
    return this.inputValidationService.isValidated(val, this.validationType);
  }

  getStakeholderIconType(stakeholder: CaseStakeholderSearch) {
    let type = stakeholder.stakeholderType;
    if (stakeholder.hasRole(StakeholderTypeEnum.endUser)) {
      type = StakeholderTypeEnum.endUser;
    } else if (stakeholder.hasRole(StakeholderTypeEnum.policyHolder)) {
      type = StakeholderTypeEnum.policyHolder;
    }
    return  '/assets/icons/' + this.stakeholderService.addIconToCaseStakeholder(type) + '.svg';
  }

  isRequired() {
    return this.required && this.selectedItemId == null;
  }

  compareFunction(a: any, b: any): boolean {
    return a.id === b.id;
  }
}

class CaseStakeholderSearch extends CaseStakeholder {
  nameForSearch: String;
}
