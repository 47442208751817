import {Injectable} from '@angular/core';
import {Adapter} from '@secca/shared/interfaces/adapter';

export class Language {
  code: string;
  language: string;
  public constructor(init?: Partial<Language>) {
    Object.assign(this, init);
  }
}
@Injectable({
  providedIn: 'root'
})
export class LanguageAdapter implements Adapter<Language> {
  adapt(item: any): Language {
    return new Language({
      code: item.code,
      language: item.language
    });
  }
}
