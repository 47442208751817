import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SubStakeholdersConfig } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholdersConfig';
import { SubMedicalEscort } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subMedicalEscort';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import { TranslateService } from '@ngx-translate/core';
import { ServiceTypeEnum, StakeholderTypeEnum } from '@secca/shared/models/enums';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { SubTravellers } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subTravellers';
import { ServiceOrderType } from '@secca/shared/models/service-order-type';
import moment from 'moment';

@Component({
  selector: 'app-sub-medical-escort',
  templateUrl: './sub-medical-escort.component.html',
  styleUrls: ['./sub-medical-escort.component.scss'],
})
export class SubMedicalEscortComponent implements OnInit {
  @Input() caseId: string;
  @Input() incidentId: number;
  @Input() stakeholders: CaseStakeholder[];
  @Input() medicalEscorts: SubMedicalEscort[];
  @Input() isConfirmationTab = false;
  @Input() medicalEscortIsUnique = true;
  @Output() newMedicalEscortAdded = new EventEmitter();
  @Input() travellers: SubTravellers;
  @Input() serviceOrderType: ServiceOrderType;

  subStakeholdersConfig: SubStakeholdersConfig;
  minimumRestTypesDropdown: DropdownDictionary[];

  protected readonly ServiceTypeEnum = ServiceTypeEnum;

  constructor(private translate: TranslateService) {
    this.initialize();
  }

  ngOnInit() {
    this.initMinimumRestTypesDropdown();
  }

  initialize() {
    this.subStakeholdersConfig = new SubStakeholdersConfig();
    this.subStakeholdersConfig.hideClass = true;
    this.subStakeholdersConfig.hideAddButton = true;
    this.subStakeholdersConfig.requireSingleStakeholder = true;
    this.subStakeholdersConfig.reporterAndPolicyHolderMustBePersons = true;
    this.subStakeholdersConfig.availableStakeholderTypes = [
      StakeholderTypeEnum.escortDoctor,
      StakeholderTypeEnum.escortNurse,
      StakeholderTypeEnum.reporter,
      StakeholderTypeEnum.policyHolder,
      StakeholderTypeEnum.person,
      StakeholderTypeEnum.nonMedicalEscort,
    ];
  }

  initMinimumRestTypesDropdown() {
    this.minimumRestTypesDropdown = [
      {
        id: this.translate.instant('sub-medical-escort-minimum-rest-one-day-trip'),
        name: this.translate.instant('sub-medical-escort-minimum-rest-one-day-trip'),
      },
      {
        id: this.translate.instant('sub-medical-escort-minimum-rest-meet-in-transit'),
        name: this.translate.instant('sub-medical-escort-minimum-rest-meet-in-transit'),
      },
    ];
    for (let currentHour = 10; currentHour < 61; currentHour++) {
      const currentHourValue = `${currentHour} ${this.translate.instant('sub-medical-escort-minimum-rest-hours')}`;
      this.minimumRestTypesDropdown.push({
        id: currentHourValue,
        name: currentHourValue,
      });
    }
  }

  checkNoOutboundTripNeeded(medicalEscort: SubMedicalEscort) {
    medicalEscort.outboundTripNotNeeded = !medicalEscort.outboundTripNotNeeded;
  }

  checkNoReturnTripNeeded(medicalEscort: SubMedicalEscort) {
    this.clearDateFromReturnFlight(medicalEscort);
    medicalEscort.returnTripNotNeeded = !medicalEscort.returnTripNotNeeded;
  }
  clearDateFromReturnFlight(medicalEscort: SubMedicalEscort): void {
    medicalEscort.returnTrips.forEach(date => {
      if (date.departureDate?.isValid() === false){
        date.departureDate = null;
      }
      if (date.arrivalDate?.isValid() === false){
        date.arrivalDate = null;
      }
    });
  }

  addMedicalEscort() {
    const newIdx = (!!this.medicalEscorts[this.medicalEscorts.length - 1] ?
      this.medicalEscorts[this.medicalEscorts.length - 1].escortIndex : 0) + 1 ;
    const newEscort = new SubMedicalEscort();
    newEscort.escortIndex = newIdx;
    newEscort.subStakeholders.stakeholders.forEach(holder => {
      holder.includeDateOfBirth = 'YES';
    });
    this.medicalEscorts.push(newEscort);
    if (this.newMedicalEscortAdded != null) {
      this.newMedicalEscortAdded.emit();
    }
  }

  deleteMedicalEscort(medicalEscort: SubMedicalEscort) {
    const index = this.medicalEscorts.findIndex(d => d === medicalEscort);
    this.medicalEscorts.splice(index, 1);
  }

  getLastInboundDate(medicalEscort: SubMedicalEscort): moment.Moment {
    let lastArrivalDate = null;
    medicalEscort.outboundTrips.forEach(trip => {
      const tripDate = !!trip.arrivalDate ? trip.arrivalDate : trip.departureDate;
      if (lastArrivalDate == null || (!!tripDate && tripDate.isSameOrAfter(lastArrivalDate))){
        lastArrivalDate = tripDate;
      }
    });
    return lastArrivalDate;
  }
}
