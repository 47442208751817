<div class="refund-task-table" *ngIf="taskViewModels; else loadingTemplate">
    <div class="primary-table-container">
      <table class="primary-table fixed-header-table">
            <thead>
                <tr>
                    <td class="checkbox-column-header">
                        <div class="custom-checkbox checkbox-header-container">
                            <label class="checkbox">
                                <input type="checkbox" (click)="selectAllTasks()" [(ngModel)]="isAllSelected" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </td>
                    <td (click)="sortBy('customerProfileId')" [ngClass]="sortedBy('customerProfileId')">
                        {{ 'board-task-refund-tabel-customer-profile' | translate }}
                    </td>
                    <td (click)="sortBy('receivedDate')" [ngClass]="sortedBy('receivedDate')">
                        {{ 'board-task-refund-tabel-received-date' | translate }}
                    </td>
                    <td (click)="sortBy('dueDate')" [ngClass]="sortedBy('dueDate')">
                        {{ 'board-task-refund-tabel-due-date' | translate }}
                    </td>
                    <td (click)="sortBy('remaining')" [ngClass]="sortedBy('remaining')">
                        {{ 'board-task-refund-tabel-remaining' | translate }}
                    </td>
                    <td (click)="sortBy('refundDate')" [ngClass]="sortedBy('refundDate')">
                        {{ 'board-task-refund-tabel-refund-date' | translate }}
                    </td>
                    <td (click)="sortBy('receiverName')" [ngClass]="sortedBy('receiverName')">
                        {{ 'board-task-refund-tabel-receiver' | translate }}
                    </td>
                    <td (click)="sortBy('caseNumber')" [ngClass]="sortedBy('caseNumber')">
                        {{ 'board-task-refund-tabel-case-number' | translate }}
                    </td>
                    <td>
                        {{ 'board-task-refund-tabel-cause' | translate }}
                    </td>
                    <td (click)="sortBy('refundId')" [ngClass]="sortedBy('refundId')">
                        {{ 'board-task-refund-tabel-refund-id' | translate }}
                    </td>
                    <td (click)="sortBy('refundAmount')" [ngClass]="sortedBy('refundAmount')">
                        {{ 'board-task-refund-tabel-refund-amount' | translate }}
                    </td>
                    <td (click)="sortBy('refundStatus')" [ngClass]="sortedBy('refundStatus')">
                        {{ 'board-task-refund-tabel-refund-status' | translate }}
                    </td>
                    <td>
                        {{ 'board-task-refund-tabel-refund-second-approver' | translate }}
                    </td>
                    <td (click)="sortBy('assignedTo')" [ngClass]="sortedBy('assignedTo')">
                        {{ 'board-task-refund-tabel-refund-assignee' | translate }}
                    </td>

                    <td (click)="sortBy('winterSport')" [ngClass]="sortedBy('winterSport')">
                        {{ 'board-task-refund-tabel-WS' | translate }}
                    </td>

                    <td (click)="sortBy('incidentEvent')" [ngClass]="sortedBy('incidentEvent')">
                        {{ 'board-task-refund-tabel-event' | translate }}
                    </td>
                </tr>
            </thead>
            <tbody (scroll)="onCaseTabelScroll()" id="taskRefundTabel" [class.zoomed]="settingsService.isZoomed()">
                <tr *ngFor="let task of taskViewModels; let i = index" [class.task-check]="task.isSelected" [class.task-row-red]="!task.refundId">
                    <td class="checkbox-column">
                        <div class="custom-checkbox checkbox-content-container">
                            <label class="checkbox">
                            <input type="checkbox" [(ngModel)]="task.isSelected" />
                            <span class="checkmark"></span>
                            </label>
                        </div>
                    </td>
                    <td class="customer-column">
                        <img *ngIf="task.customerProfileId" [src]="getBrandImagesUrlForProfile(task.customerProfileId)" />
                      </td>
                    <td>{{ task.receivedDate | date: 'dd MMM' | lowercase }}</td>
                    <td>{{ task.dueDate | date: 'dd MMM' | lowercase }}</td>
                    <td>{{ task.remainingDays  }}
                    </td>
                    <td>{{ task.refundDate | date: 'dd MMM' | lowercase }}</td>
                    <td>{{ task.receiverName }}</td>
                    <td class="link-column" (click)="redirectToManageRefund(task.caseId, task.refundId)">{{ task.caseNumber }}</td>
                    <td>{{task.causeLevel1Code}}</td>
                    <td>{{ task.refundId }}</td>
                    <td class="amount-column">
                        <span class="currency-value">{{ task.refundCurrency }}</span
                            >{{ task.refundAmount | number: '.2' }}
                    </td>
                    <td>{{ getRefundStatusText(task.refundStatus) }}</td>
                    <td>
                      <span *ngIf='task.refundAwaiting !== null'>{{ task.refundAwaiting }} </span>
                    </td>
                    <td>{{ task.assignedTo }}</td>



                    <td class="text-center ps-0"><img *ngIf="task.winterSport" src="/assets/icons/snowflake.svg" /></td>
                    <td>{{ task.incidentEventName }}  </td>

                </tr>
            </tbody>
        </table>
    </div>
</div>
<ng-template #loadingTemplate>
    <div class="d-flex justify-content-center pt-3">
      <h4>{{ 'board-task-table-refreshing' | translate }}</h4>
    </div>
</ng-template>
