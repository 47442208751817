import { Case } from 'src/app/shared/models/case';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { DropdownDictionary } from '../../../../../shared/models/dropdownDictionary';
import { DictionaryService } from 'src/app/core/services/dictionary.service';
import { CaseSummaryService } from 'src/app/core/services/caseSummary.service';
import { UserDto } from '../../../../../shared/models/userDto';
import { CaseSummaryFilter } from 'src/app/shared/models/caseSummaryFilter';
import { finalize } from 'rxjs/operators';
import { CaseHistorySearchFilter } from '@secca/shared/models/CaseHistorySearchFilter';
import { PagingInfo } from '@secca/shared/models/paging-info';
import { SettingsService } from '@secca/core/services/settings.service';
import { Subscription } from 'rxjs';
import { CaseService } from '@secca/core/services/case.service';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';

@Component({
  selector: 'app-case-history-filter',
  templateUrl: './case-history-filter.component.html',
  styleUrls: ['./case-history-filter.component.scss']
})
@AutoUnsubscribe
export class CaseHistoryFilterComponent implements OnInit, AfterViewInit {
  @Input()
  private case: Case;

  @Input()
  searchFilter: CaseHistorySearchFilter;

  @Input()
  users: UserDto[] = [];

  @Input()
  objectItems: DropdownDictionary[] = [];

  @Input()
  pagingInfo: PagingInfo;

  @Input()
  queryMode: string;

  subObjectItems: DropdownDictionary[] = [];
  subObjectFocus = false;

  $subscr2: Subscription;

  constructor(private dictionaryService: DictionaryService,
              private caseSummaryService: CaseSummaryService,
              private settingsService: SettingsService,
              private caseService: CaseService) {
    this.caseSummaryService.setCaseHistorySubject(null);
  }

  ngOnInit(): void {
    this.pagingInfo.pageIndex = 0;

    if (this.searchFilter.objectItem) {
      this.fetchSubObjectItems(this.searchFilter.objectItem);
    } else {
      this.performSearch();
    }

    this.$subscr2 = this.caseService.observeCaseNotesChanges().subscribe(caseId => {
      this.performSearch();
    });

  }

  ngAfterViewInit(): void {
  }


  selectedObjectItemChange() {
    this.searchFilter.subObjectItems = [];
    this.subObjectItems = [];
    this.subObjectFocus = true;
    this.pagingInfo.pageIndex = 0;

    this.fetchSubObjectItems(this.searchFilter.objectItem);
  }

  selectedSubObjectClose() {
    this.pagingInfo.pageIndex = 0;
    this.performSearch();
  }

  selectedDateRange() {
    this.pagingInfo.pageIndex = 0;
    this.performSearch();
  }

  selectedCaseHandlerChange() {
    this.pagingInfo.pageIndex = 0;
    this.performSearch();
  }

  typedInText() {
    this.pagingInfo.pageIndex = 0;
    this.performSearch();
  }

  pagingChanged(index: number) {
    this.pagingInfo.pageIndex = index;
    this.performSearch();
  }

  resetFilters() {
    this.searchFilter.dateRange = [];
    this.searchFilter.users = [];
    this.searchFilter.objectItem = null;
    this.searchFilter.subObjectItems = [];
    this.searchFilter.searchText = '';
    this.subObjectItems = [];
    this.pagingInfo.pageIndex = 0;

    this.performSearch();
  }

  performSearch() {
    this.caseSummaryService.getCaseHistory(this.case.id, this.getFilterRequest()).subscribe(
      result => {
        this.caseSummaryService.setCaseHistorySubject(result);
        this.pagingInfo.totalNumber = result.totalNumber;
      },
      error => console.log(error)
    );
  }

  private getFilterRequest(): CaseSummaryFilter {
    const caseSummaryFilter = new CaseSummaryFilter();
    caseSummaryFilter.types = this.getSubObjectItems();
    caseSummaryFilter.dateFrom = this.getFromDate(this.searchFilter.dateRange);
    caseSummaryFilter.dateTo = this.getToDate(this.searchFilter.dateRange);
    caseSummaryFilter.authorsIds = this.searchFilter.users ? this.searchFilter.users.map(user => user.userId) : [];
    caseSummaryFilter.searchText = this.searchFilter.searchText;
    caseSummaryFilter.pageSize = this.pagingInfo.pageSize;
    caseSummaryFilter.pageIndex = this.pagingInfo.pageIndex;
    caseSummaryFilter.sortOrder = this.pagingInfo.sortOrder;
    caseSummaryFilter.queryMode = this.queryMode;

    return caseSummaryFilter;
  }

  private fetchSubObjectItems(objectItem: string): void {
    this.dictionaryService.getDictionaryEntriesAsStringObject(`casehistory/dictionary/casesubobjects/${objectItem}`).pipe(
      finalize(() => this.subObjectFocus = false)
    )
      .subscribe(
        result => {
          this.subObjectItems = result.map(str => new DropdownDictionary(str, str));

          this.performSearch();
        },
        error => console.log(error)
      );
  }

  private getSubObjectItems() {
    if (this.searchFilter.subObjectItems && this.searchFilter.subObjectItems.length > 0) {
      return this.searchFilter.subObjectItems;
    }

    return (this.subObjectItems && this.subObjectItems.length > 0) ? this.subObjectItems.map(dictionary => dictionary.id) : [];
  }

  private getFromDate(dateRange: Date[]): Date {
    return (dateRange && dateRange.length > 0) ? this.setLowerBoundOnDate(dateRange[0]) : null;
  }

  private getToDate(dateRange: Date[]): Date {
    return (dateRange && dateRange.length > 1) ? this.setUpperBoundOnDate(dateRange[1]) : null;
  }

  private setUpperBoundOnDate(date: Date): Date {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);

    return date;
  }

  private setLowerBoundOnDate(date: Date): Date {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);

    return date;
  }

}
