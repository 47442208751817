<tr [ngClass]="{'disabled': disabled}">
  <td>
    <app-drop-down [items]="recoveryOptions"
                   [disabled]="approved || disabled"
                   [(selectedItemId)]="recoveryPaymentItem.recoveryId"/>
  </td>
  <td>
    <app-drop-down [items]="serviceItemOptions"
                   [disabled]="approved || disabled"
                   [(selectedItemId)]="recoveryPaymentItem.serviceItemCode"/>
  </td>
  <td>
    <div class="d-flex">
      <div class="input-with-currency" [ngClass]="{ 'input-with-currency-disabled': approved || firstItem}">
        <div class="input-with-currency-currency">{{ paymentCurrency }}</div>
        <input [(ngModel)]="recoveryPaymentItem.amount" [disabled]="approved || firstItem" currencyMask
               [options]="currencyMaskOptions" (ngModelChange)="amountChanged()" />
      </div>
      <img *ngIf="!firstItem && !approved" [ngStyle]="{margin: '1em 0.5em 0.5em 0.5em'}" ngSrc="../../../../../../../../assets/icons/garbage.svg" alt="delete" height="12" width="11" (click)="removeItem()"/>
    </div>
  </td>
</tr>
