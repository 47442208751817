import {
  AfterViewInit,
  Component,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { CdkScrollable } from '@angular/cdk/overlay';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef  } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {CaseIncident} from 'src/app/shared/models/caseIncident';
import {EndUserStateService} from '@secca/core/state-services/enduser-state-service';
import {IncidentStateService} from '@secca/core/state-services/incident-state-service';
import {SettingsService} from '@secca/core/services/settings.service';
import {WorkInProgressService} from '@secca/core/services/work-in-progress.service';
import {CaseStateService} from '@secca/core/state-services/case-state.service';
import {InsuranceService} from '@secca/core/services/insurance.service';
import {CaseValidationService} from 'src/app/core/services/case-validation.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ModalDialogComponent} from '../modal-dialog/modal-dialog.component';
import {MenuService} from '@secca/core/services/menu.service';
import {DataService} from '@secca/core/services/data.service';
import {CaseService} from '@secca/core/services/case.service';
import * as _ from 'lodash-es';
import {ModalDialogConfiguration} from 'src/app/shared/models/modal/modal-dialog-configuration';
import {MedicalService} from '@secca/core/services/medical.service';
import {CaseLockHelperService} from '@secca/core/services/case-lock-helper.service';
import {CaseStakeholder} from '@secca/shared/models/caseStakeholder';
import {TaskStateService} from '@secca/board/components/board-task/services/task-state.service';
import {
  ConsentStatusEnum,
  HandlingAreaStatusEnum,
  HandlingAreaType,
  SensitiveType,
  TaskType
} from '@secca/shared/models/enums';
import {HandlingAreaService} from '@secca/core/services/handling-area.service';
import {LockContextEnum} from '@secca/shared/enums/lock-context.enum';
import {CaseBoardService} from '@secca/board/components/board-cases/services/case-board.service';
import {PersonInsurance} from '@secca/shared/models/person-insurance';
import {Case} from '@secca/shared/models/case';
import {BoardCase} from '@secca/board/components/board-cases/models/board-case.model';
import {CaseIncidentIdWithCaseId} from '@secca/shared/models/caseIncidentWithCaseId';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {DialogMinimizedData, DialogMinimizedStorageService} from '@secca/core/services/dialog-minimized-storage.service';
import {TopTabsService} from '@secca/core/services/top-tabs.service';
import {TabData} from '@secca/shared/models/tab-data';
import {RecentCases} from '@secca/shared/models/recent-cases';
import { MedicalPreAssessmentService } from '@secca/core/services/medical-pre-assessment.service';
import {CASE_TYPE_CODES, CaseTypeCodes} from '@secca/shared/models/case-type';
import { SimpleTaskViewModel } from '@secca/case/components/case-task/models/simple-task-view.model';
import { AutoUnsubscribe } from 'src/app/shared/decorators/auto-unsubscribe';
import moment from 'moment';
import {CoordinationDetailsService} from '@secca/core/services/coordination-details.service';

@Directive({
  selector: '[topTab]',
})
export class TopTabDirective {
  constructor(private elementRef: ElementRef<HTMLElement>) {}

  scrollIntoView() {
//    this.elementRef.nativeElement.scrollIntoView(true);
  }
}

@AutoUnsubscribe
@Component({
  selector: 'app-top-tabs',
  templateUrl: './top-tabs.component.html',
  styleUrls: ['./top-tabs.component.scss'],
})
export class TopTabsComponent implements OnInit, AfterViewInit {
  currentTab: TabData;
  @HostBinding('class.case-id')
  caseId = '';
  @ViewChild(ModalDialogComponent) modalDialogComponent: ModalDialogComponent;
  @ViewChild(CdkScrollable) scrollPanel: CdkScrollable;
  @ViewChildren(TopTabDirective) scrollPanelTabs: QueryList<TopTabDirective>;
  validatedCases = {};
  consentedCases = {};
  $subscr1: Subscription;
  $subscr2: Subscription;
  $subscr3: Subscription;
  $subscr4: Subscription;
  $subscr5: Subscription;
  $subscr6: Subscription;
  $subscr7: Subscription;
  $tabAddedSubscr: Subscription;
  $insuranceSubscr: Subscription;
  $consentSubscr: Subscription;
  isCaseLoading: boolean;
  recentCases: RecentCases[] = [];
  private Type = TaskType;

  scrollTabCapacity = 5;
  scrollPosLeft = 0;
  scrollPosRight = 300;
  scrollOffset = 300;
  scrollOffsetMargin = 250;

  constructor(
    private dataService: DataService,
    private caseStateService: CaseStateService,
    private caseBoardService: CaseBoardService,
    private incidentStateService: IncidentStateService,
    private endUserStateService: EndUserStateService,
    private caseService: CaseService,
    private menuService: MenuService,
    private caseValidationService: CaseValidationService,
    private modalService: NgbModal,
    private router: Router,
    private insuranceService: InsuranceService,
    private workInProgressService: WorkInProgressService,
    private medicalService: MedicalService,
    private settingsService: SettingsService,
    private caseLockHelperService: CaseLockHelperService,
    private taskStateService: TaskStateService,
    private handlingAreaService: HandlingAreaService,
    private dialogMinimizedStorageService: DialogMinimizedStorageService,
    private matDialog: MatDialog,
    public topTabsService: TopTabsService,
    public medicalPreAssessmentService: MedicalPreAssessmentService,
    private coordinationService: CoordinationDetailsService
  ) {
    this.currentTab = new TabData();
    this.subscribeCaseNumberAssigned();
  }

  ngOnInit() {
    this.$subscr1 = this.dataService.getIsCaseLoading().subscribe(isLoading => {
      this.isCaseLoading = isLoading;
    });

    this.$subscr2 = this.dataService.changeSelectedCase.subscribe(caseData => {
    if (caseData.newlyCreated) {
        const tab = new TabData();
        tab.newlyCreated = true;
        tab.caseId = caseData.caseId.toString();
        this.updateTab(tab);
        this.setCurrent(tab);
        this.scrollToLastTab();
      } else {
        // Create tab without content to keep the correct tab order
        if ( !caseData.setAsCurrent ) {
          const tab = new TabData();
          tab.caseId = caseData.caseId.toString();
          tab.loading = true;
          this.updateTab(tab);
        }

        this.caseBoardService.getBoardCaseById(caseData.caseId.toString()).subscribe(
          (result: BoardCase) => {
            const tab = new TabData();
            tab.caseId = caseData.caseId.toString();
            tab.caseNumber = result.caseNumber;
            tab.country = this.trim(result.incidentCountry);
            tab.cause = this.trim(result.incidentCause);
            tab.user = this.trim(result.endUserFullName);
            tab.incidentDate = result.incidentDate;
            tab.sensitiveType = result.sensitiveType;
            tab.logo = this.getBrandImagesUrlForProfile(result.customerProfileId);
            this.updateTab(tab);
            if ( caseData.setAsCurrent ) {
              this.setCurrent(tab);
              this.scrollToTab(tab);
            }
          }
        );
      }
    });

    this.$subscr3 = this.incidentStateService.getIncident().subscribe((incidentWithCaseId: CaseIncidentIdWithCaseId) => {
      if (incidentWithCaseId) {
        this.updateIncident(incidentWithCaseId.incident, incidentWithCaseId.caseId);
      }
    });
    this.$subscr4 = this.endUserStateService.getEndUser().subscribe((user: CaseStakeholder) => {
      if (user) {
        this.updateEndUser(user);
      }
    });

    this.$subscr5 = this.caseStateService.getCase().subscribe((result: Case) => {
      if (result != null) {
        const foundTab = this.topTabsService.getTabFromCaseId(+result.id);
        if (foundTab != null) {
          this.handleICC(result, foundTab);
          if (result.caseNumber) {
            foundTab.caseNumber = result.caseNumber;
            foundTab.sensitiveType = result.sensitiveType;
            foundTab.caseTypeCode = result.caseTypeCode;
          }
        }
      }
    });
    this.$consentSubscr = this.caseStateService.consent.subscribe(result => {
      if (result.consentType !== ConsentStatusEnum.UNKNOWN && result.consentType !== ConsentStatusEnum.CONSENT_ACCEPTED_WEB
          && result.consentType != null){
        this.consentedCases[result.seccaCaseId] = true;
        this.caseService.getCase('' + result.seccaCaseId).subscribe(caseResult => {
          const foundTab = this.topTabsService.getTabFromCaseId(+caseResult.id);
          if (foundTab != null) {
            foundTab.caseNumber = caseResult.caseNumber;
            this.updateTab(foundTab);
          }
        });
      }
    });
  }

  private handleICC(caseData: Case, tabData: TabData) {
    if (CaseTypeCodes.INTERNAL_COORDINATION !== caseData.caseTypeCode) {
      return;
    }
    this.$subscr5 = this.coordinationService.coordinationDetailsChanges().subscribe(
        cd => {
          if (cd && tabData.caseId === '' + cd.caseId) {
            tabData.user = cd.details?.name;
          }
        }
    );
  }

  get getSensitiveType() {
    return SensitiveType;
  }

  ngAfterViewInit(): void {
    this.$subscr7 = this.scrollPanel.elementScrolled().subscribe(() => {
      this.scrollPosLeft = this.scrollPanel.measureScrollOffset('left');
      this.scrollPosRight = this.scrollPanel.measureScrollOffset('right');
    });
  }

  private setCurrent(tab: TabData): void {
    this.caseId = tab.caseId;
    this.currentTab = tab;
    this.subscribeToInsuranceChanges(tab.caseId);
  }

  private subscribeCaseNumberAssigned() {
    this.$subscr6 = this.caseValidationService.getCaseNumberAssignedConfirmation().subscribe(result => {
      if (result) {
        this.validatedCases[result] = true;
      }
    });
  }

  private closeAndActivate(closingCase: TabData) {
    if (closingCase.caseNumber) {
      this.updateRecentClosedCases(closingCase);
    }
    const closedTabIndex = this.topTabsService.tabs.indexOf(closingCase);
    this.closeTab(closingCase);
    this.medicalService.removeRegularAssessmentDraft(+closingCase.caseId);
    this.medicalService.removeClaimsReviewDraft(+closingCase.caseId);
    this.medicalPreAssessmentService.removeMedicalPreAssessmentDraft(+closingCase.caseId);
    this.dataService.unsetOpenCase(parseInt(closingCase.caseId, 10));
    const nextTabIndex = Math.max(0, closedTabIndex - 1);
    const nextOpenTab: TabData = this.topTabsService.tabs[nextTabIndex];
    if (nextOpenTab) {
      this.router.navigate(['/case', nextOpenTab.caseId]);
    } else {
      this.router.navigate(['board']);
    }
  }

  private updateRecentClosedCases(closingCase: TabData) {
    const oldCasesJSON = sessionStorage.getItem('top-menu-recent-cases');
    if (oldCasesJSON) {
      this.recentCases = JSON.parse(oldCasesJSON);
    }

    const recentCase = new RecentCases();
    recentCase.caseId = +closingCase.caseId;
    recentCase.exitTime = moment();
    if (this.recentCases.length > 0) {
      const index = this.recentCases.findIndex(c => c.caseId === +closingCase.caseId );
      if (index !== -1) {
        this.recentCases.splice(index, 1);
      }
    }
    this.recentCases.push(recentCase);
    if (this.recentCases.length === 16) {
      this.recentCases.splice(0, 1);
    }
    sessionStorage.setItem('top-menu-recent-cases', JSON.stringify(this.recentCases));
  }

  private closeTab(tab: TabData) {
    if (+tab.caseId === +this.caseLockHelperService.getCaseId) {
      this.caseLockHelperService.releaseLocksForCurrentCase();
    } else {
      this.caseService.getCase(tab.caseId).subscribe(seccaCase => {
        this.caseLockHelperService.releaseLocks(seccaCase.caseLocks);
      });
    }
    this.topTabsService.tabs.splice(this.topTabsService.tabs.indexOf(tab), 1);
    this.menuService.send(+tab.caseId, 1);
  }

  private trim(str: string): string {
    return str ? str.trim() : '';
  }

  updateTab(tab: TabData) {
    const index = _.findIndex(this.topTabsService.tabs, { caseId: tab.caseId });
    if (index !== -1) {
      if ( !tab.logo && this.topTabsService.tabs[index].logo ) {
        tab.logo = this.topTabsService.tabs[index].logo;
      }
      this.topTabsService.tabs[index] = tab;
      this.topTabsService.tabs = [].concat(this.topTabsService.tabs); // nudge change detection
    } else {
      this.topTabsService.tabs.push(tab);
    }
  }

  isCaseValidated(caseId: string): boolean {
    return !!this.validatedCases[+caseId];
  }

  isCaseConsented(caseId: string): boolean {
    return !!this.consentedCases[+caseId];
  }

  openModal(tab: TabData, event: Event) {
    event.stopPropagation();
    if ( this.hasMinimizedDialogs(tab.caseId) || this.hasOpenDialogs() ) {
      this.showHasOpenOrMinimizedDialogsInfoModal();
      return;
    }

    if (this.workInProgressService.getWorkInProgress(tab.caseId).length >= 1) {
      this.openWorkInProgressModal(tab);
      return;
    }

    this.caseValidationService.isCaseValid(tab.caseId).subscribe(
      missingCaseFields => {
        if (missingCaseFields.caseValidateList.length === 0) {
          this.taskStateService.getToDoTaskViewModelForCase(tab.caseId).subscribe(tasks => {
            this.handlingAreaService.getHandlingsAreas(Number(tab.caseId)).subscribe(result => {
              const caseHandlerArea = result.find(area => area.handlingAreaType === HandlingAreaType.CASE_HANDLER);
              const caseHandlerStatus = caseHandlerArea == null ? null : caseHandlerArea.status;
              const transportHandlingAreaStatus = result.find(area => area.handlingAreaType === HandlingAreaType.TRANSPORT)?.status;
              if ( this.isMissingRequiredTask(tab, tasks) &&
                ([HandlingAreaStatusEnum.OPEN, HandlingAreaStatusEnum.REOPENED].includes(caseHandlerStatus) ||
                  [HandlingAreaStatusEnum.OPEN, HandlingAreaStatusEnum.REOPENED].includes(transportHandlingAreaStatus))
              ) {
                this.checkIfTheCaseIsLockedByCurrentUser(tab);
              } else if (!!this.medicalService.getRegularAssessmentInDraft(+tab.caseId)) {
                this.openMedicalAssessmentDraftPendingModal(tab);
              } else if (!!this.medicalPreAssessmentService.getPreAssessmentInDraft(+tab.caseId)) {
                this.openMedicalPreAssessmentDraftPendingModal(tab);
              } else {
                this.closeAndActivate(tab);
              }
            });
          },
          error => {
            if ( error.status === 403 ) {
              this.closeAndActivate(tab);
            }
          });
        } else {
          this.openCaseValidationModal(missingCaseFields, tab);
        }
      }
    );
  }

  private isMissingRequiredTask(tab: TabData, tasks: SimpleTaskViewModel[]) {
    const openManualOrProcessTasksCount = tasks.filter(
        task => task.taskType !== null
        && (task.taskType === this.Type.manual || task.taskType === this.Type.process || task.taskType === this.Type.processGopRequest)
    ).length;
    const openMedicalPreAssessmentTasksCount = tasks.filter(
        task => task.taskType !== null && task.taskType === this.Type.medicalPreAssessment
    ).length;

    return (tab.caseTypeCode !== CASE_TYPE_CODES.MEDICAL_PRE_ASSESSMENT && openManualOrProcessTasksCount < 1) ||
           (tab.caseTypeCode === CASE_TYPE_CODES.MEDICAL_PRE_ASSESSMENT && openMedicalPreAssessmentTasksCount < 1);
  }

  private hasMinimizedDialogs(caseId: string): boolean {
     const minimized: DialogMinimizedData[] = this.dialogMinimizedStorageService.getMinimizedList();
     return !!minimized.find(m => +m.caseId === +caseId);
  }

  private hasOpenDialogs() {
    if ( this.matDialog.openDialogs.length !== 0 ) {

      const notMinimizedDialogs: MatDialogRef<any>[] = this.matDialog.openDialogs.filter(matRef => {
        return !this.dialogMinimizedStorageService.getMinimizedFromDialogId(matRef.id);
      });

      return notMinimizedDialogs.length !== 0;
    }
  }

  private checkIfTheCaseIsLockedByCurrentUser(tab: TabData) {
    this.caseService.getCase(tab.caseId).subscribe(seccaCase => {
      this.caseLockHelperService.updateCaseLocks(seccaCase.id, seccaCase.caseLocks);
      if (
        this.caseLockHelperService.isCaseLockedByTheLoggedInUser(LockContextEnum.CASE_BASIC) ||
        this.caseLockHelperService.isCaseLockedByTheLoggedInUser(LockContextEnum.SUPPLIER_INVOICE)
      ) {
        this.openMissingOpenTaskOnCaseModal(tab);
      } else {
        this.closeAndActivate(tab);
      }
      return;
    });
  }

  private showHasOpenOrMinimizedDialogsInfoModal() {
    const modalRef = this.modalService.open(ModalDialogComponent, { backdrop: 'static', windowClass: 'modal-ontop' });
    modalRef.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'default-modal-header',
      title: 'minimized-dialogs-warning-title',
      text: 'minimized-dialogs-warning-text',
      no: 'dismiss-ok-modal',
      isBody: true,
      isFooter: true,
    });
    modalRef.componentInstance.closeModalEvent.subscribe(
      () => {
        modalRef.close();
      }
    );
  }


  private openMedicalPreAssessmentDraftPendingModal(tab: TabData) {
    const modalRef = this.modalService.open(ModalDialogComponent);
    modalRef.componentInstance.medicalPreAssessmentInDraft = (!!this.medicalPreAssessmentService.getPreAssessmentInDraft(+tab.caseId));
    modalRef.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'default-modal-header',
      footer: 'case-validation-warning-modal-footer',
      yes: 'default-modal-dialog-yes',
      no: 'default-modal-dialog-no',
      isBody: true,
      isFooter: true,
    });
    this.close(modalRef, tab);
  }


  private openMedicalAssessmentDraftPendingModal(tab: TabData) {
    const modalRef = this.modalService.open(ModalDialogComponent);
    modalRef.componentInstance.medicalAssessmentInDraft = (!!this.medicalService.getRegularAssessmentInDraft(+tab.caseId));
    modalRef.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'default-modal-header',
      footer: 'case-validation-warning-modal-footer',
      yes: 'default-modal-dialog-yes',
      no: 'default-modal-dialog-no',
      isBody: true,
      isFooter: true,
    });
    this.close(modalRef, tab);
  }

  private openMissingOpenTaskOnCaseModal(tab: TabData) {
    const modalRef = this.modalService.open(ModalDialogComponent);
    modalRef.componentInstance.items = this.workInProgressService.getWorkInProgressMessageList(tab.caseId);
    modalRef.componentInstance.medicalAssessmentInDraft = (!!this.medicalService.getRegularAssessmentInDraft(+tab.caseId));
    modalRef.componentInstance.medicalPreAssessmentInDraft = (!!this.medicalPreAssessmentService.getPreAssessmentInDraft(+tab.caseId));
    modalRef.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'default-modal-header',
      title: 'missing-open-manual-task-modal-title-case',
      text: tab.caseTypeCode === CASE_TYPE_CODES.MEDICAL_PRE_ASSESSMENT ? 'missing-open-pre-assessment-task-modal-text' : 'missing-open-manual-task-modal-text',
      footer: 'missing-open-manual-task-modal-footer',
      yes: 'default-modal-dialog-yes',
      no: 'default-modal-dialog-no',
      isBody: true,
      isFooter: false,
    });
    this.close(modalRef, tab);
  }

  private openWorkInProgressModal(tab: TabData) {
    const modalRef = this.modalService.open(ModalDialogComponent);
    modalRef.componentInstance.items = this.workInProgressService.getWorkInProgressMessageList(tab.caseId);
    modalRef.componentInstance.medicalAssessmentInDraft = (!!this.medicalService.getRegularAssessmentInDraft(+tab.caseId));
    modalRef.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'default-modal-header',
      title: 'work-in-progress-modal-title-case',
      text: 'work-in-progress-modal-text',
      footer: 'work-in-progress-modal-footer',
      yes: 'default-modal-dialog-yes',
      no: 'default-modal-dialog-no',
      isBody: true,
      isFooter: true,
    });
    this.close(modalRef, tab);
  }

  private openCaseValidationModal(result: any, tab: TabData) {
    const modalRef = this.modalService.open(ModalDialogComponent);
    const title = result.caseBeenValidated
        ? 'case-created-validation-warning-modal-title-case'
        : 'case-validation-warning-modal-title-case';
    modalRef.componentInstance.items = result.caseValidateList;
    modalRef.componentInstance.medicalAssessmentInDraft = (!!this.medicalService.getRegularAssessmentInDraft(+tab.caseId));
    modalRef.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'default-modal-header',
      title,
      text: 'case-validation-warning-modal-text',
      footer: 'case-validation-warning-modal-footer',
      yes: 'default-modal-dialog-yes',
      no: 'default-modal-dialog-no',
      isBody: true,
      isFooter: true,
    });
    this.close(modalRef, tab);
  }

  private close(modalRef: NgbModalRef, tab: TabData) {
    modalRef.componentInstance.closeModalEvent.subscribe(
      emittedEvent => {
        this.modalService.dismissAll();
        if (emittedEvent) {
          this.closeAndActivate(tab);
        }
      },
      error => console.log(error)
    );
  }

  getBrandImagesUrlForProfile(profileId: number) {
    return profileId ? this.caseService.getBrandImagesUrlForProfile(profileId.toString()) : null;
  }

  containsNoData(tab: TabData) {
    return !tab.country && !tab.user && !tab.cause;
  }

  updateIncident(incident: CaseIncident, caseId: string) {
    let cause = incident.causeLevel1Text;
    cause = incident.causeLevel2Text ? cause + '/' + incident.causeLevel2Text : cause;
    cause = incident.causeLevel3Text ? cause + '/' + incident.causeLevel3Text : cause;

    const foundTab = this.topTabsService.getTabFromCaseId(+caseId);
    if ( foundTab ) {
      foundTab.cause = cause;
      foundTab.country = incident.incidentLocation.country;
    }
  }

  updateEndUser(user: CaseStakeholder) {
    let endUser = '';
    if (user.company != null) {
      endUser = user.company.name;
    } else if (user.person != null) {
      endUser = [user.person.firstName, user.person.surname].filter(Boolean).join(' ');
    }

    const foundTab = this.topTabsService.getTabFromCaseId(user.caseId);
    if ( foundTab ) {
      foundTab.user = endUser;
    }
  }

  subscribeToInsuranceChanges(caseId: string): void {
    if (this.$insuranceSubscr) {
      this.$insuranceSubscr.unsubscribe();
    }
    this.$insuranceSubscr = this.insuranceService.getPersonInsurance(caseId).subscribe((insurance: PersonInsurance) => {
      if (insurance != null) {
        const foundTab = this.topTabsService.getTabFromCaseId(+insurance.seccaCaseId);
        if ( foundTab ) {
          foundTab.logo = this.getBrandImagesUrlForProfile(insurance.customerProfileId);
        }
      }
    });
  }

  // TODO: Commented this out for local development
  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    if (this.settingsService.isTest() === true || this.settingsService.isProduction() === true) {
      event.returnValue = 'Are you sure?';
      return event;
    }
  }

  @HostListener('window:unload', ['$event'])
  unloadHandler(event) {
    if (this.settingsService.isTest() === true || this.settingsService.isProduction() === true) {
      event.returnValue = 'Are you sure?';
      return event;
    }
  }

  drop(event: CdkDragDrop<TabData[]>) {
    moveItemInArray(this.topTabsService.tabs, event.previousIndex, event.currentIndex);
    this.dataService.changeCaseOrder(event.previousIndex, event.currentIndex);
  }

  scrollToPrevTab() {
    this.scroll(this.scrollOffset * -1);
  }

  scrollToNextTab() {
    this.scroll(this.scrollOffset);
  }

  private scroll(value) {
    this.scrollPanel.scrollTo({ left: this.scrollPanel.measureScrollOffset('left') + value, behavior: 'smooth' });
  }

  tabPanelCapacityExceeded() {
    return this.topTabsService.tabs.length > this.scrollTabCapacity;
  }

  nextTabAvailable() {
    return this.tabPanelCapacityExceeded() && this.scrollPosRight > this.scrollOffsetMargin;
  }

  prevTabAvailable() {
    return this.tabPanelCapacityExceeded() && this.scrollPosLeft > this.scrollOffsetMargin;
  }

  private scrollToLastTab() {
    if (this.$tabAddedSubscr) {
      this.$tabAddedSubscr.unsubscribe();
    }
    this.$tabAddedSubscr = this.scrollPanelTabs.changes.subscribe(() => {
      this.scrollPanelTabs.last.scrollIntoView();
      this.$tabAddedSubscr.unsubscribe();
    });
  }

  private scrollToTab(tab: TabData) {
    const index = _.findIndex(this.topTabsService.tabs, { caseId: tab.caseId });
    if (index !== -1) {
      if (index < this.scrollPanelTabs.length) {
        this.scrollPanelTabs.toArray()[index].scrollIntoView();
      } else {
        this.scrollToLastTab();
      }
    }
  }
}
