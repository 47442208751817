import { Component, OnInit, Input } from '@angular/core';
import { Limit } from 'src/app/shared/models/onepoint-limit';
import { LimitTypeEnum } from 'src/app/shared//models/enums';

@Component({
  selector: 'app-limit-description',
  templateUrl: './limit-description.component.html'
})
export class LimitDescriptionComponent implements OnInit {

  @Input() limits: Limit[];

  constructor() { }

  ngOnInit() { }

  get getLimitTypeEnum() {
    return LimitTypeEnum;
  }
}
