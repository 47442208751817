<div *ngIf="historyEntry">
  <div *ngIf="historyEntry.caseHistoryType === caseHistoryType.POLICY_SELECTED">
    <span class="values-blue-color">{{'case-summary-list-policy-selected' | translate}}</span>
    {{'case-summary-list-policy-policy' | translate}} {{'case-summary-list-policy-from' | translate}}
    <span
      class="values-blue-color">{{customerProfileName}}</span>: {{getPolicyNumber()}}
    <span class="values-blue-color">{{getCustomerProductName()}}</span>
    <span class="values-blue-color"> {{getInsuranceLevel()}}</span>
    <span class="values-blue-color"> {{getVersion()}}</span>
  </div>
  <div *ngIf="historyEntry.caseHistoryType === caseHistoryType.POLICY_REMOVED">
    <span class="values-blue-color">{{'case-summary-list-policy-removed' | translate}}</span>
    {{'case-summary-list-policy-policy' | translate}} {{getPolicyNumber()}}
    {{getCustomerProductName()}} {{getInsuranceLevel()}} {{getVersion()}}
  </div>
</div>
