import {PhoneConversation} from './../../../../shared/models/phoneConversation';
import {CaseService} from './../../../../core/services/case.service';
import {Case} from './../../../../shared/models/case';
import {Component, Input, OnInit} from '@angular/core';
import {CallType} from 'src/app/shared/models/enums';

@Component({
  selector: 'app-case-about',
  templateUrl: './case-about.component.html',
  styleUrls: ['./case-about.component.scss']
})
export class CaseAboutComponent implements OnInit {
  @Input() case: Case;
  phoneConv: number = 0;
  runtime: number;
  incommingCalls: number = 0;
  outgoingCalls: number = 0;
  phonesConversations: PhoneConversation[] = [];

  constructor(private caseService: CaseService) {
  }

  totalSum: number = 0;

  ngOnInit() {
  }

  displayCaseInformation() {
    this.runtime = (new Date().getTime() - this.case.caseCreatedDate.getTime()) / 60000;
    this.case.phoneConversation.forEach(a => {
      this.phoneConv += a.durationInMinutes;
      if (a.callType === CallType.ingoing) {
        this.incommingCalls++;
      } else {
        this.outgoingCalls++;
      }
    });

    const groupedConv = this.grouping(this.case.phoneConversation, 'user');
    for (var i = 0; Object.values(groupedConv)[i] != undefined; i++) {
      const phonesConv = Object.values(groupedConv)[i];
      var sum = 0;
      (phonesConv as any[]).forEach(a => (sum += a.durationInMinutes));
      this.phonesConversations.push(new PhoneConversation({user: Object.keys(groupedConv)[i], durationInMinutes: sum}));
      this.totalSum += sum;
    }
    this.phonesConversations.forEach(element => {
      element.durationInMinutes = Math.floor((100 * element.durationInMinutes) / this.totalSum);
    });
  }

  grouping = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
}
