<div class="case-primary-contact-container" *ngIf="primaryContactExists">
  <div class="primary-contact-box">
    <header class="header-primary-contact">
      Primary contact
    </header>
    <div class="line-primary-contact"></div>
    <div class="primary-contact-name">{{fullName}}</div>
    <div class="primary-contact-icons">
      <div class="role {{roleName}}"></div>
      <div class="type type-enduser" *ngIf="isEndUser"></div>
      <div class="type type-policyholder" *ngIf="isPolicyHolder"></div>
      <div class="type type-reporter" *ngIf="isReporter"></div>
      <div class="type guardian" *ngIf="isGuardian"></div>
      <div class="type co-traveller" *ngIf="isCotraveller"></div>
      <flag-icon *ngIf="countryCode" [country]="findCountryCode()"></flag-icon>
    </div>
    <div class="primary-contact-preferred-channel" *ngIf="contact">
      {{contact}}
    </div>
  </div>
</div>
