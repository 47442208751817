import {SubStakeholdersConfig} from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholdersConfig';
import {StakeholderTypeEnum, TransportTypeEnum} from './../../../../../../../../shared/models/enums';
import {CaseStakeholder} from '@secca/shared/models/caseStakeholder';
import {ServiceTypeBase} from './../../models/serviceTypeBase';
import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ServiceTypeComponent} from './../../models/interfaces';
import {GroundAmbulanceServiceOrderExtension} from '@secca/shared/models/service-order/ground-ambulance-service-order-extension';
import {SubTransportType} from '../../models/subTransportType';
import {SubRemarksFromSupplier} from '../../models/subRemarksFromSupplier';
import {SubRemarksToSupplier} from '../../models/subRemarksToSupplier';
import {SubTravellers} from '../../models/subTravellers';
import {SubStakeholders} from '../../models/subStakeholders';
import {SubStakeholder} from '../../models/subStakeholder';

@Component({
  selector: 'app-ground-ambulance',
  templateUrl: './ground-ambulance.component.html',
})
export class GroundAmbulanceComponent extends ServiceTypeBase implements ServiceTypeComponent, OnChanges {
  @Input()
  public get stakeholders(): CaseStakeholder[] {
    return this._stakeholders;
  }
  public set stakeholders(stakeholders: CaseStakeholder[]) {
    if (stakeholders != null) {
      this._stakeholders = stakeholders;
      this.tryToAddEndUserAsDefaultStakeholderWhenAdding();
    }
  }

  private _stakeholders: CaseStakeholder[];
  @Input() caseId: string;
  @Input()
  set groundAmbulance(groundAmbulance: GroundAmbulanceServiceOrderExtension) {
    if (groundAmbulance != null) {
      this._groundAmbulance = groundAmbulance;
      this.initializeSettings();
      this.tryToAddEndUserAsDefaultStakeholderWhenAdding();
    }
  }

  get groundAmbulance() {
    return this._groundAmbulance;
  }

  _groundAmbulance: GroundAmbulanceServiceOrderExtension;

  subStakeholdersConfig: SubStakeholdersConfig;
  availableTransportTypes: TransportTypeEnum[];

  constructor() {
    super();
    this.initializeData();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }

  tryToAddEndUserAsDefaultStakeholderWhenAdding() {
    if (this.stakeholders != null && this.groundAmbulance != null) {
      if (!this.groundAmbulance.stakeholders.some(a => a.stakeholderId != null)) {
        this.groundAmbulance.stakeholders.unshift(
          new SubStakeholder({
            stakeholderId: this.stakeholders.find(a => a.hasRole(StakeholderTypeEnum.endUser)).id,
            stakeholderType: StakeholderTypeEnum.endUser,
            includeDateOfBirth: 'YES'
          })
        );
      }
    }
  }

  isValid(): boolean {
    let result: boolean;
    if (!this.serviceOrderTypeState.showConfirmationTab) {
      result = this.groundAmbulance.isValidExpectation();
    }
    if (this.serviceOrderTypeState.showConfirmationTab) {
      result = this.groundAmbulance.isValid() && this.serviceOrderTypeState.isSupplierChosen;
    }
    return result;
  }
  getModel() {
    return this.groundAmbulance;
  }

  initializeData() {
    this.groundAmbulance = new GroundAmbulanceServiceOrderExtension();
    this.groundAmbulance.transportType = new SubTransportType(TransportTypeEnum.GROUND_AMBULANCE);
    // TODOthis.groundAmbulance.commitmentType = new SubCommitmentType(CommitmentTypes.BOOKING);
    this.groundAmbulance.remarksFromSupplier = new SubRemarksFromSupplier();
    this.groundAmbulance.remarksToSupplier = new SubRemarksToSupplier();
    this.groundAmbulance.travellers = new SubTravellers();
    this.groundAmbulance.travelInfo = this.groundAmbulance.travellers.flightLegs[0];
    this.groundAmbulance.subStakeholders = new SubStakeholders();
    this.groundAmbulance.subStakeholders.clearStakeholderTable();
    this.groundAmbulance.stakeholders = this.groundAmbulance.subStakeholders.stakeholders;
  }

  initializeSettings() {
    this.subStakeholdersConfig = new SubStakeholdersConfig();
    this.subStakeholdersConfig.mandatoryAndSetStakeholderType = StakeholderTypeEnum.endUser;
    this.subStakeholdersConfig.hideClass = true;
    this.subStakeholdersConfig.hideInclPassportDetails = true;
    this.subStakeholdersConfig.requireAtLeastOneStakeholder = true;
    this.subStakeholdersConfig.availableStakeholderTypes = [
      StakeholderTypeEnum.endUser,
      StakeholderTypeEnum.reporter,
      StakeholderTypeEnum.escortDoctor,
      StakeholderTypeEnum.escortNurse,
      StakeholderTypeEnum.policyHolder,
      StakeholderTypeEnum.coTraveller,
      StakeholderTypeEnum.person,
    ];
    this.subStakeholdersConfig.requireStakeholderType = StakeholderTypeEnum.endUser;
    this.availableTransportTypes = [TransportTypeEnum.GROUND_AMBULANCE, TransportTypeEnum.LIFT_VAN];
    this.isSupplierRequiredOnConfirmationTab = false;
  }

  get StakeholderTypeEnum() {
    return StakeholderTypeEnum;
  }
}
