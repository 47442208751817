<div class="common-drop-down-arrow drop-down-creditors">
  <span class="name">{{name}}</span>
  <span *ngIf="required" class="required-red">*</span>
  <ng-select #selectCreditor (blur)="onSaveModel()" (focus)="onSaveState()" (close)="onClose()" [clearable]="true"
    [(ngModel)]="selectedItemId" [ngClass]="{'noborder' : noBorderAndArrow,'custom' : !noBorderAndArrow}"
    [disabled]="!!disabled" [closeOnSelect]="closeOnSelect" [placeholder]="placeholder" (change)="onChange()"
    [class.required-red-background]="requiredRedBackground &&
    !this.selectedItemId" [searchFn]="searchCreditor" [items]="items" bindValue="id">
    <ng-template ng-label-tmp let-item="item">{{item.id}}</ng-template>
    <ng-template ng-option-tmp let-item="item">
      <div class="creditor-id-option">{{item.id}}</div>
      <div class="creditor-name-option">{{item.name}}</div>
    </ng-template>
  </ng-select>
</div>