
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-global-search-page',
  templateUrl: './global-search-page.component.html',
  styleUrls: ['./global-search-page.component.scss']
})
export class GlobalSearchPageComponent implements OnInit {
  @ViewChild("globalSearchResults")  globalSearchResults;
  constructor() { }

  ngOnInit() {
  }

  onSearchTextChange(searchText: string){
    this.globalSearchResults.onSearchTextChange(searchText);
  }
  onIsSelectedCaseChange(isCaseSelected: boolean){
    this.globalSearchResults.onIsSelectedCaseChange(isCaseSelected);
  }
}
