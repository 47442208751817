import {CallType} from './enums';
import {Injectable} from '@angular/core';
import {Adapter} from './../interfaces/adapter';
import * as moment from 'moment';
import {DateSerializer} from '@secca/shared/models/date-serializer';

export class PhoneCall {
  id: string;
  user: string;
  durationInMinutes: number;
  callType: CallType;
  phoneNumber: string;
  callStartTime: moment.Moment;
  connectionCallId: string;

  public constructor(init?: Partial<PhoneCall>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class PhoneCallAdapter implements Adapter<PhoneCall> {
  adapt(item: any): PhoneCall {
    if (item == null) {
      return new PhoneCall();
    }

    return new PhoneCall({
      id: item.id,
      user: item.user,
      durationInMinutes: item.durationInMinutes,
      callType: item.callType,
      phoneNumber: item.phoneNumber,
      callStartTime: DateSerializer.deserialize(item.callStartTime),
      connectionCallId: item.connectionCallId
    });
  }
}
