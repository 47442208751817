<ul ngbNav #nav="ngbNav"
  class="service-order-type-tabset nav-tabs"
  [(activeId)]="activeTab"
  (navChange)="tabChange($event)"
>
  <li id="{{ SelectedTab.ExpectationTab }}" [ngbNavItem]="SelectedTab.ExpectationTab">
    <a ngbNavLink>{{ 'service-type-expectation' | translate }}</a>
    <ng-template ngbNavContent>
      <div [appDisableInput]="caseLocked" class="caselock-no-events">
      <div class="sub-component-placeholder row">
        <div class="col-3">
          <app-drop-down
            name="{{ 'plans-accommodation-type' | translate }}"
            [items]="accommodationTypeDict"
            [(selectedItemId)]="accommodation.expectedAccommodationType"
          ></app-drop-down>
        </div>
      </div>
      <div class="sub-component-placeholder">
        <div class="sub-component-container">
          <div class="sub-title">{{ 'sub-stakeholders-title' | translate }}</div>
          <div class="sub-content-container">
            <app-sub-stakeholders
              [caseId]="caseId"
              [stakeholders]="stakeholders"
              [subStakeholders]="subStakeholders"
              [subStakeholdersConfig]="subStakeholderConfig"
            ></app-sub-stakeholders>
          </div>
        </div>
      </div>
      <div class="sub-component-placeholder">
        <app-durations
          [incidentId]="incidentId"
          [durations]="accommodation.durations"
          [hideGOPDates]="true"
          [defaultStartDate]="defaultStartDate"
          [defaultEndDate]="defaultEndDate"
        > </app-durations>
      </div>
      </div>
    </ng-template>
  </li>
  <li *ngIf="serviceOrderTypeState.showConfirmationTab" [id]="SelectedTab.ConfirmationTab" [ngbNavItem]="SelectedTab.ConfirmationTab">
    <a ngbNavLink>{{ 'service-type-confirmation' | translate }}
      <div class="close-tab-x" (click)="closeConfirmationTab($event)" *ngIf="!serviceOrderTypeState.isConfirmationTabSaved">
        <img src="/assets/icons/Close Black.svg" />
      </div>
    </a>
    <ng-template ngbNavContent>
      <div [appDisableInput]="caseLocked" class="caselock-no-events">
      <div class="sub-component-placeholder row">
        <div class="col-3">
          <div class="common-drop-down-arrow drop-down">
            <app-drop-down
              name="{{ 'plans-accommodation-type' | translate }}"
              [items]="accommodationTypeDict"
              [(selectedItemId)]="accommodation.committedAccommodationType"
            ></app-drop-down>
          </div>
        </div>
        <div class="col-7">
          <app-sub-commitment-type
            [serviceOrderType]="serviceOrderType"
            [(commitmentType)]="accommodation.commitmentType"
            [caseId]="caseId"
          ></app-sub-commitment-type>
        </div>
        <div class="col-2">
          <div class="d-flex float-end align-items-end h-100">
            <a class="btn primary-button no-hover" href="https://www.egencia.dk/" target="_blank"
              >{{ 'regular-flight-non-medical-egencia-online' | translate }}
              <img class="ps-2" src="/assets/icons/new window white.svg" />
            </a>
          </div>
        </div>
      </div>
      <div class="sub-component-placeholder">
        <app-sub-reference [subReference]="accommodation.reference"></app-sub-reference>
      </div>
      <div class="sub-component-placeholder">
        <div class="sub-component-container">
          <div class="sub-title">{{ 'sub-stakeholders-title' | translate }}</div>
          <div class="sub-content-container">
            <app-sub-stakeholders
              [caseId]="caseId"
              [stakeholders]="stakeholders"
              [subStakeholders]="subStakeholders"
              [disabled]="true"
              [subStakeholdersConfig]="subConfirmationStakeholderConfig"
            >
            </app-sub-stakeholders>
          </div>
        </div>
      </div>
      <div class="sub-component-placeholder">
        <app-durations
          [disableDeletion]="true"
          [incidentId]="incidentId"
          [gopDatesSemiRequired]="true"
          [durations]="accommodation.durations"
          [hideGOPDates]="false"
          [disableStartDate]="true"
          [disableEndDate]="true"
          [disableLocation]="true"
          [hideAdd]="true"
          [altGopPeriodTitle]="'sub-durations-gop-period-title'"
          [defaultStartDate]="defaultStartDate"
          [defaultEndDate]="defaultEndDate"
        ></app-durations>
      </div>
      <div class="sub-component-placeholder" *ngIf="accommodation.commitmentType && accommodation.commitmentType.businessKey === CommitmentTypes.GUARANTEE_OF_PAYMENT">
        <app-sub-gop-details
          [serviceTypeEnum]="ServiceTypeEnum.ACCOMMODATION"
          [templateFilter]="accommodationTemplatesFilter"
          [subGopDetails]="accommodation.gopDetails"
        ></app-sub-gop-details>
      </div>
      <div class="sub-component-placeholder" *ngIf="accommodation.commitmentType && accommodation.commitmentType.businessKey=== CommitmentTypes.GUARANTEE_OF_PAYMENT">
        <app-GOP
          [caseId]="caseId"
          [serviceOrderExtension]="accommodation"
          [supplier]="supplier"
          [stakeholders]="stakeholders"
          [serviceOrderTypeState]="serviceOrderTypeState"
        ></app-GOP>
      </div>
      </div>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="nav"></div>
