import { ServiceOrderExtension } from '@secca/shared/models/service-order/service-order-extension';
import { Duration, DurationAdapter } from '@secca/shared/models/duration';
import { Location } from '@secca/shared/models/location';
import * as moment from 'moment';
import { CommonServiceOrderExtension } from './CommonServiceOrderExtension.component';
import { CommitmentType, CommitmentTypeAdapter } from '../commitment-type';

export class MedicalEscortServiceOrderExtension extends CommonServiceOrderExtension implements ServiceOrderExtension {
  id: number;
  duration: Duration;
  commitmentType: CommitmentType;

  // Only used for display purposes in plans
  displayDurationId: number;

  public constructor(init?: Partial<MedicalEscortServiceOrderExtension>) {
    super();
    Object.assign(this, init);
    if (!init) {
      this.init();
    }
  }

  isPreviewGopValid(): boolean {
    throw new Error("Gop preview not supported for this service order type");
  }

  isValidExpectation(): boolean {
    return !!this.commitmentType && this.duration.isStartEndDatesValid();
  }

  isValid(): boolean {
    return !!this.commitmentType && this.duration.isStartEndDatesValid();
  }

  init() {
    this.duration = new Duration();
  }

  getStartDate(): moment.Moment {
    return this.duration.startDate;
  }

  getStartLocation(): Location {
    return this.duration.location;
  }

  getEndLocation(): Location {
    return this.getStartLocation();
  }

  getEndDate(): moment.Moment {
    return this.duration.endDate;
  }

  isMultipleDurations(): boolean {
    return false;
  }

  hasMedicalEscort(): boolean {
    return false;
  }

  hasCoTravellers(): boolean {
    return false;
  }

  setCommitmentType(commitmentType: CommitmentType) {
    this.commitmentType = commitmentType;
  }

  getCommitmentType(): CommitmentType {
    return this.commitmentType;
  }

  getSelectedStakeholderIds(): string[] {
    return null;
  }
}

export class MedicalEscortServiceOrderExtensionAdapter {
  adapt(item?: Partial<MedicalEscortServiceOrderExtension>): MedicalEscortServiceOrderExtension {
    const durationAdapter  = new DurationAdapter();
    const commitmentTypeAdapter: CommitmentTypeAdapter = new CommitmentTypeAdapter();
    return new MedicalEscortServiceOrderExtension({
      id: item.id,
      duration: item.duration !== null ?  durationAdapter.adapt(item.duration) : null,
      commitmentType: item.commitmentType != null ? commitmentTypeAdapter.adapt(item.commitmentType) : null, //TODO new SubCommitmentType(CommitmentTypes.BOOKING),
    });
  }
}
