import { HandlingAreaType } from '@secca/shared/models/enums';
import { Component, OnInit, Input } from '@angular/core';
import { HandlingAreaStatusEnum } from '@secca/shared/models/enums';
import { HandlingArea } from '@secca/shared/models/handlingArea';
import { TranslateService } from '@ngx-translate/core';
import { HandlerPicture } from '../handler-picture/handler-picture';

@Component({
  selector: 'app-case-handler-status-box',
  templateUrl: './case-handler-status-box.component.html',
  styleUrls: ['./case-handler-status-box.component.scss']
})
export class CaseHandlerStatusBoxComponent implements OnInit {

  constructor(
    private translate: TranslateService
  ) { }

  @Input()
  set handlingArea(newHandlingArea: HandlingArea) {
    this._handlingArea = newHandlingArea;
    this.getHandlerPicture(newHandlingArea);
  }

  get handlingArea(): HandlingArea {
    return this._handlingArea;
  }

  @Input()
  set statusName(newStatusName: string) {
    this._statusName = newStatusName;
  }

  get statusName(): string {
    return this._statusName;
  }
  @Input() teamName: string;

  _handlingArea: HandlingArea;
  _statusName: string;
  handlerPicture: HandlerPicture;
  translatePreFix = 'HANDLING-AREA-';


  ngOnInit() {
  }

  haveUserImage() {
    if (this.handlingArea.user) {
      return !!this.handlingArea.user.picture;
    } else {
      return false;
    }
  }

  getHandlerPicture(handlingArea: HandlingArea) {
    this.handlerPicture = new HandlerPicture();
    this.handlerPicture.activeUser = this.getUserActiveStatus();
    this.handlerPicture.handlerStatus = handlingArea.status;
    this.handlerPicture.haveUserImage = this.haveUserImage();
    this.handlerPicture.showUserImage = !!handlingArea.user;
    this.handlerPicture.userId = this.getUserId();
  }

  getHandlerStatusEnum() {
    return HandlingAreaStatusEnum;
  }

  getHandlerType() {
    return HandlingAreaType;
  }

  getUserId() {
    return this.handlingArea.user ? this.handlingArea.user.id : null;
  }

  getUserActiveStatus() {
    return this.handlingArea.user ? this.handlingArea.user.active : false;
  }

}
