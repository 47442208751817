import { ServiceTypeBase } from './../../models/serviceTypeBase';
import { Component, Input, OnInit } from '@angular/core';
import { ServiceTypeComponent } from './../../models/interfaces';
import { ReferalToCustomerServiceOrderExtension } from '@secca/shared/models/service-order/referal-to-customer-service-order-extension';

@Component({
  selector: 'app-referral-to-customer',
  templateUrl: './referral-to-customer.component.html',
})
export class ReferralToCustomerComponent extends ServiceTypeBase implements OnInit, ServiceTypeComponent {
  @Input() referalToCustomer: ReferalToCustomerServiceOrderExtension;

  constructor() {
    super();
  }


  ngOnInit() {}

  startDateChange(date) {
    this.referalToCustomer.startDate = date;
  }

  isValid(): boolean {
   return this.referalToCustomer.isValid();
  }
  getModel() {
    return this.referalToCustomer;
  }
}
