import { CaseService } from '@secca/core/services/case.service';
import { PermissionEnum, PermissionHideTypeEnum, ShortcutEnum } from '@secca/shared/models/enums';
import { InsuranceService } from '@secca/core/services/insurance.service';
import { Coverage } from '@secca/shared/models/coverage';
import { CasePlansService } from './../case-plans.service';
import { Subscription } from 'rxjs';
import { Case } from '@secca/shared/models/case';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AutoUnsubscribe } from 'src/app/shared/decorators/auto-unsubscribe';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import { ServiceOrderMessageRequest } from '@secca/shared/models/service-order/service-order-message-request';
import { PermissionService } from '@secca/core/services/permission.service';
import { SettingsService } from '@secca/core/services/settings.service';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { CaseDialogViewerService } from '@secca/case/case-dialog-viewer.service';
import { MenuService } from '@secca/core/services/menu.service';
import { ShortcutService } from '@secca/core/services/shortcut.service';
import { CaseSelectedMenuEnum } from '@secca/shared/enums/case-selected-menu-enum.component';
import { RefundService } from '@secca/core/services/refund.service';
import { TranslateService } from '@ngx-translate/core';
import { CoordinationCaseService } from '@secca/core/services/coordination-case.service';
import { ServiceOrder } from '@secca/shared/models/service-order/service-order';

@Component({
  selector: 'app-case-plans',
  templateUrl: './case-plans.component.html',
  styleUrls: ['./case-plans.component.scss'],
})
@AutoUnsubscribe
export class CasePlansComponent implements OnInit, OnDestroy {

  @Input()
  set case(newCase: Case) {
    if (newCase !== undefined) {
      this._case = newCase;
      if (this.case.caseNumber == null) {
        this.caseService.getCase(this.case.id).subscribe(result => {
          this.caseNumber = result.caseNumber;
        });
      } else {
        this.caseNumber = this.case.caseNumber;
      }
    }
  }

  @Input() numberOfLinkedCases =  0;
  showPlans = false;

  get case(): Case {
    return this._case;
  }

  private _case: Case;

  $caseRefundSubscr: Subscription;
  $coverageSubscr: Subscription;
  $sendServiceMsgSubscr: Subscription;
  messageRequest: ServiceOrderMessageRequest;
  coverages: Coverage[];
  date: Date = new Date();
  heightOfTheCoverageRow = 29;
  customerProfileId: number;
  serviceOrderId: number;
  caseNumber = '';
  hasClaimsAgreement = false;
  $personInsuranceSubscr: Subscription;
  iccServiceOrders: ServiceOrder[];

  private selectedMenuId: number;
  private shortcutSubscriptions: Subscription[] = [];

  constructor(
    private casePlansService: CasePlansService,
    private insuranceService: InsuranceService,
    public caseLockHelperService: CaseLockHelperService,
    public permissionService: PermissionService,
    private caseService: CaseService,
    private dialogViewerService: CaseDialogViewerService,
    private settingsService: SettingsService,
    public caseStateService: CaseStateService,
    private menuService: MenuService,
    private shortcutService: ShortcutService,
    private refundService: RefundService,
    private translateService: TranslateService,
    private coordinationService: CoordinationCaseService
  ) {
    this.$coverageSubscr = this.casePlansService.getCoverages().subscribe(coverages => {
      this.coverages = coverages;
    });
  }

  ngOnInit() {
    this.$personInsuranceSubscr = this.insuranceService.getPersonInsurance(this.case.id).subscribe(
      result => {
        if (result) {
          this.customerProfileId = result.customerProfileId;
          if (this.customerProfileId) {
            this.insuranceService.getCustomerProfileInfo(this.case.id).subscribe(info => {
              this.hasClaimsAgreement = info.claimsAgreement;
            });
          }
        }
      }
    );

    this.coordinationService.getCoordinationCaseServiceOrdersFromLinkedCase(+this.case.id).subscribe(serviceOrders => {
      this.iccServiceOrders = serviceOrders;
      this.showPlans = true;
    });

  }

  ngOnDestroy(): void {
    this.shortcutSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  addNewServiceOrder() {
    this.dialogViewerService.openServiceOrderDialog(null, this.case.id, this.caseNumber, this.case.incidentId);
  }

  closeEvent() {
    this.serviceOrderId = null;
  }

  onOpenServiceEditDialog(serviceOrderId: number) {
    this.serviceOrderId = serviceOrderId;
    this.dialogViewerService.openServiceOrderDialog(serviceOrderId, this.case.id, this.caseNumber, this.case.incidentId);
  }

  addNewClaim() {
    this.dialogViewerService.openManageClaimsDialog(this.case.id, false, this.customerProfileId.toString(), null, false, this.case.caseCreatedDate, () => this.refundSaved());
  }

  refundSaved() {
    this.$caseRefundSubscr = this.refundService.getRefundByCaseId(parseInt(this.case.id, 10)).subscribe(refunds => {
      setTimeout(() => {
        this.refundService.sendCaseRefunds(refunds);
      }, 500);
    });
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  get PermissionHideTypeEnum() {
    return PermissionHideTypeEnum;
  }

  isRefundDisabled() {
    return !this.hasClaimsAgreement || this.caseStateService.isCaseClosed || this.caseStateService.isInvoiceClosedWithNoInvoicingToCustomer || this.caseStateService.isSanctionCase;
  }

  isRefundHoverDisabled() {
    return (this.hasClaimsAgreement && !this.caseStateService.isSanctionCase) || this.caseStateService.isCaseClosed || this.caseStateService.isInvoiceClosedWithNoInvoicingToCustomer;
  }

  refundPopOverText() {
    if ( this.caseStateService.isSanctionCase ) {
      return this.translateService.instant('add-not-possible-on-sanction-case');
    }
    else {
      return this.translateService.instant('add-no-claims-agreement');
    }
  }

  isAddSoDisabled() {
    return !this.customerProfileId || this.caseStateService.isCaseClosed || this.caseStateService.isInvoiceClosedWithNoInvoicingToCustomer;
  }
}
