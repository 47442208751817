<div class="modal-content" *ngIf="selectedServiceOrder">
  <div [appDisableInput]="caseStateService.isCaseDisabled()">
    <div class="modal-header">
      <div *ngIf="!editModal; else editModalTemplate" class="modal-header-title">
        {{ 'service-order-add-service' | translate }}: {{ caseNumber + '-' + displayServiceOrderId }} {{ 'service-order-copy-id-hint' | translate}}
      </div>
      <ng-template #editModalTemplate>
        <div class="modal-header-title">
          {{ 'service-order-edit-service' | translate }}: {{ caseNumber + '-' + displayServiceOrderId }} {{ 'service-order-copy-id-hint' | translate}}
        </div>
      </ng-template>
      <div class="modal-buttons">
        <div (click)="close()">
          <img src="/assets/icons/Close White.svg" />
        </div>
      </div>
    </div>
  </div>
  <div class="service-order-lock" *ngIf="serviceOrderTypeState.isLockedByCoordinationTransport">
    <div class="overlay-text">
      {{ 'service-order-linked-to-coordination-case' | translate }} {{ serviceOrderTypeState.lockedByCoordinationTransportCaseNumber }}
    </div>
  </div>
  <div class="modal-content-body">
  <div class="modal-body">
    <div class="add-service-order">
      <div class="d-flex">
        <div class="left-side">
          <div *ngIf="selectedServiceOrder" class="left-side-container">
            <div [appDisableInput]="caseStateService.isCaseDisabled()">
              <div class="service-order-type-picker">
                <app-drop-down-with-icon
                  [items]="types"
                  [name]="'service-order-service-type' | translate"
                  [disabled]="editModal"
                  (selectedItemIdChange)="setSelectedType($event)"
                  [(selectedItemId)]="selectedServiceOrder.type"
                >
                </app-drop-down-with-icon>
              </div>
              <div class="spacer"></div>
              <ng-container *ngIf="selectedServiceOrder.type" [ngSwitch]="selectedServiceOrder.type">
                <div class="left-column-base" *ngSwitchDefault>
                  <app-left-side
                    *ngIf="stakeholders"
                    [selectedServiceOrderInitialStatus]="selectedServiceOrderInitialStatus"
                    [serviceTypeComponent]="selectedServiceTypeComponent"
                    [(serviceOrderTypeState)]="serviceOrderTypeState"
                    [(serviceOrder)]="selectedServiceOrder"
                    [serviceOrderType]="selectedServiceOrderType"
                    [caseId]="caseId"
                    [incidentId]="incidentId"
                    [type]="selectedServiceOrder.type"
                    [stakeholders]="stakeholders"
                    (supplierChange)="supplierChange($event)"
                    (supplierAddedEvent)="supplierAdded()"
                    [returnedProviders]="returnedProviders"
                    [showProviderSpinner]="showProviderSpinner"
                    [iccServiceOrder]="iccServiceOrder"
                  ></app-left-side>
                </div>
                <div class="left-column-base" *ngSwitchCase="serviceTypeEnum.MEDICAL_ASSESSMENT">
                  <app-medical-assessment-left-side
                    [caseId]="caseId"
                    [(serviceOrder)]="selectedServiceOrder"
                    [(serviceOrderTypeState)]="serviceOrderTypeState"
                    [(medicalAssessment)]="selectedServiceOrder.extension"
                    [assignee]="selectedServiceOrder.extension.assignee"
                    [serviceOrderType]="selectedServiceOrderType"
                  ></app-medical-assessment-left-side>
                </div>
                <div class="left-column-base" *ngSwitchCase="serviceTypeEnum.MEDICAL_PRE_ASSESSMENT">
                  <app-medical-pre-assessment-left-side
                    [caseId]="caseId"
                    [(serviceOrder)]="selectedServiceOrder"
                    [(serviceOrderTypeState)]="serviceOrderTypeState"
                    [(medicalPreAssessment)]="selectedServiceOrder.extension"
                    [assignee]="selectedServiceOrder.extension.assignee"
                    [serviceOrderType]="selectedServiceOrderType"
                  ></app-medical-pre-assessment-left-side>
                </div>
                <div class="left-column-base" *ngSwitchCase="serviceTypeEnum.INVESTIGATION">
                  <app-investigation-left-side
                    [caseId]="caseId"
                    [(serviceOrder)]="selectedServiceOrder"
                    [(serviceOrderTypeState)]="serviceOrderTypeState"
                    [(investigation)]="selectedServiceOrder.extension"
                    [serviceOrderType]="selectedServiceOrderType"
                    [stakeholders]="stakeholders"
                  ></app-investigation-left-side>
                </div>
                <div class="left-column-base" *ngSwitchCase="serviceTypeEnum.TRANSLATION">
                  <app-translation-left-side
                    [caseId]="caseId"
                    [(serviceOrder)]="selectedServiceOrder"
                    [(serviceOrderTypeState)]="serviceOrderTypeState"
                    [(translation)]="selectedServiceOrder.extension"
                    [serviceOrderType]="selectedServiceOrderType"
                    [stakeholders]="stakeholders"
                  ></app-translation-left-side>
                </div>
                <div class="left-column-base" *ngSwitchCase="serviceTypeEnum.COORDINATION_TRANSPORT">
                  <app-coordination-transport-left-side
                    [caseId]="caseId"
                    [incidentId]="incidentId"
                    [serviceTypeComponent]="selectedServiceTypeComponent"
                    [(serviceOrderTypeState)]="serviceOrderTypeState"
                    [(serviceOrder)]="selectedServiceOrder"
                    [(coordinationTransport)]="selectedServiceOrder.extension"
                    [serviceOrderType]="selectedServiceOrderType"
                    [type]="selectedServiceOrder.type"
                    [stakeholders]="stakeholders"
                    (supplierChange)="supplierChange($event)"
                    (supplierAddedEvent)="supplierAdded()"
                    [returnedProviders]="returnedProviders"
                    [showProviderSpinner]="showProviderSpinner"
                  ></app-coordination-transport-left-side>
                </div>
                <div class="left-column-base" *ngSwitchCase="serviceTypeEnum.MEDICAL_FLIGHT_AUTHORIZATION">
                  <app-medical-flight-authorization-left-side
                    [caseId]="caseId"
                    [(serviceOrder)]="selectedServiceOrder"
                    [(serviceOrderTypeState)]="serviceOrderTypeState"
                    [(medicalFlightAuthorization)]="selectedServiceOrder.extension"
                    [assignee]="selectedServiceOrder.extension.assignee"
                    [serviceOrderType]="selectedServiceOrderType"
                  ></app-medical-flight-authorization-left-side>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="service-type-container">
          <ng-container [ngSwitch]="selectedServiceOrder.type" *ngIf="selectedServiceOrder && selectedServiceOrder.type">
            <div
              [appDisableInput]="
                caseStateService.isCaseDisabled() || selectedServiceOrder.lockedForEdit
              "
            >
              <app-treatment
                #ServiceTypeComponent
                *ngSwitchCase="serviceTypeEnum.TREATMENT"
                [(serviceOrderTypeState)]="serviceOrderTypeState"
                [(treatment)]="selectedServiceOrder.extension"
                [caseId]="caseId"
                [gopPreviewModelChanged]="hasGopPreviewModelChanged"
                [incidentId]="incidentId"
                [supplier]="supplier"
                [serviceOrderType]="selectedServiceOrderType"
                [stakeholders]="returnedProviders"
                [status]="selectedServiceOrder.status"
                [caseLocked]="caseStateService.isCaseDisabled() || selectedServiceOrder.lockedForEdit"
              ></app-treatment>
              <app-accommodation
                #ServiceTypeComponent
                *ngSwitchCase="serviceTypeEnum.ACCOMMODATION"
                [gopPreviewModelChanged]="hasGopPreviewModelChanged"
                [serviceOrderTypeState]="serviceOrderTypeState"
                [accommodation]="selectedServiceOrder.extension"
                [stakeholders]="returnedProviders"
                [caseId]="caseId"
                [incidentId]="incidentId"
                [supplier]="supplier"
                [serviceOrderType]="selectedServiceOrderType"
                [status]="selectedServiceOrder.status"
                [caseLocked]="caseStateService.isCaseDisabled() || selectedServiceOrder.lockedForEdit"
              >
              </app-accommodation>
              <app-regular-flight-non-medical
                #ServiceTypeComponent
                *ngSwitchCase="serviceTypeEnum.REGULAR_FLIGHT_NON_MEDICAL"
                [(serviceOrderTypeState)]="serviceOrderTypeState"
                [caseId]="caseId"
                [stakeholders]="returnedProviders"
                [(regularFlightNonMedical)]="selectedServiceOrder.extension"
                [leftSideValid]="serviceOrderTypeState.isLeftSideValid"
                [serviceOrderType]="selectedServiceOrderType"
                [status]="selectedServiceOrder.status"
                [caseLocked]="caseStateService.isCaseDisabled() || selectedServiceOrder.lockedForEdit"
                [serviceOrderId]="selectedServiceOrder.id"
                [iccServiceOrder]="iccServiceOrder"
              >
              </app-regular-flight-non-medical>
              <app-regular-flight-medical
                #ServiceTypeComponent
                *ngSwitchCase="serviceTypeEnum.REGULAR_FLIGHT_MEDICAL"
                [regularFlightMedical]="selectedServiceOrder.extension"
                [serviceOrderTypeState]="serviceOrderTypeState"
                [caseId]="caseId"
                [incidentId]="incidentId"
                [accommodation]="accommodation"
                [stakeholders]="returnedProviders"
                [gopPreviewModelChanged]="hasGopPreviewModelChanged"
                [status]="selectedServiceOrderInitialStatus"
                [supplier]="supplier"
                [status]="selectedServiceOrder.status"
                [serviceOrderType]="selectedServiceOrderType"
                [caseLocked]="caseStateService.isCaseDisabled() || selectedServiceOrder.lockedForEdit"
                [serviceOrderId]="selectedServiceOrder.id"
                [iccServiceOrder]="iccServiceOrder"
              >
              </app-regular-flight-medical>
              <app-assistance-or-contact
                #ServiceTypeComponent
                *ngSwitchCase="serviceTypeEnum.ASSISTANCE_OR_CONTACT"
                [(serviceOrderTypeState)]="serviceOrderTypeState"
                [caseId]="caseId"
                [stakeholders]="returnedProviders"
                [(assistanceOrContact)]="selectedServiceOrder.extension"
                [leftSideValid]="serviceOrderTypeState.isLeftSideValid"
                [status]="selectedServiceOrder.status"
                [serviceOrderType]="selectedServiceOrderType"
                [caseLocked]="caseStateService.isCaseDisabled() || selectedServiceOrder.lockedForEdit"
              ></app-assistance-or-contact>
                <app-ambulance-flight
                  #ServiceTypeComponent
                  *ngSwitchCase="serviceTypeEnum.AMBULANCE_FLIGHT"
                  [(serviceOrderTypeState)]="serviceOrderTypeState"
                  [caseId]="caseId"
                  [stakeholders]="returnedProviders"
                  [(ambulanceFlight)]="selectedServiceOrder.extension"
                  [status]="selectedServiceOrder.status"
                  [serviceOrderType]="selectedServiceOrderType"
                  [caseLocked]="caseStateService.isCaseDisabled() || selectedServiceOrder.lockedForEdit"
                  [serviceOrderId]="selectedServiceOrder.id"
                  [iccServiceOrder]="iccServiceOrder"
                >
                </app-ambulance-flight>
                <app-medical-escort
                  #ServiceTypeComponent
                  *ngSwitchCase="serviceTypeEnum.MEDICAL_ESCORT"
                  [(serviceOrderTypeState)]="serviceOrderTypeState"
                  [(medicalEscort)]="selectedServiceOrder.extension"
                  [status]="selectedServiceOrder.status"
                  [serviceOrderType]="selectedServiceOrderType"
                  [caseLocked]="caseStateService.isCaseDisabled() || selectedServiceOrder.lockedForEdit"
                  [caseId]="caseId"
                >
                </app-medical-escort>
                <app-handling-of-mortal-remains
                  #ServiceTypeComponent
                  *ngSwitchCase="serviceTypeEnum.HANDLING_OF_MORTAL_REMAINS"
                  [incidentId]="incidentId"
                  [(serviceOrderTypeState)]="serviceOrderTypeState"
                  [caseId]="caseId"
                  [(handlingOfMortalRemains)]="selectedServiceOrder.extension"
                  [status]="selectedServiceOrder.status"
                  [serviceOrderType]="selectedServiceOrderType"
                  [stakeholders]="returnedProviders"
                  [caseLocked]="caseStateService.isCaseDisabled() || selectedServiceOrder.lockedForEdit"
                >
                </app-handling-of-mortal-remains>
                <app-ground-transport
                  #ServiceTypeComponent
                  *ngSwitchCase="serviceTypeEnum.GROUND_TRANSPORT"
                  [stakeholders]="returnedProviders"
                  [(serviceOrderTypeState)]="serviceOrderTypeState"
                  [(groundTransport)]="selectedServiceOrder.extension"
                  [caseId]="caseId"
                  [status]="selectedServiceOrder.status"
                  [serviceOrderType]="selectedServiceOrderType"
                  [caseLocked]="caseStateService.isCaseDisabled() || selectedServiceOrder.lockedForEdit"
                >
                </app-ground-transport>
                <app-ground-ambulance
                  #ServiceTypeComponent
                  *ngSwitchCase="serviceTypeEnum.GROUND_AMBULANCE"
                  [stakeholders]="returnedProviders"
                  [(serviceOrderTypeState)]="serviceOrderTypeState"
                  [(groundAmbulance)]="selectedServiceOrder.extension"
                  [caseId]="caseId"
                  [status]="selectedServiceOrder.status"
                  [serviceOrderType]="selectedServiceOrderType"
                  [caseLocked]="caseStateService.isCaseDisabled() || selectedServiceOrder.lockedForEdit"
                >
                </app-ground-ambulance>
                <app-luggage
                  #ServiceTypeComponent
                  *ngSwitchCase="serviceTypeEnum.LUGGAGE"
                  [(serviceOrderTypeState)]="serviceOrderTypeState"
                  [(luggage)]="selectedServiceOrder.extension"
                  [status]="selectedServiceOrder.status"
                  [serviceOrderType]="selectedServiceOrderType"
                  [caseLocked]="caseStateService.isCaseDisabled() || selectedServiceOrder.lockedForEdit"
                  [caseId]="caseId"
                >
                </app-luggage>
                <app-referral-to-customer
                  #ServiceTypeComponent
                  *ngSwitchCase="serviceTypeEnum.REFERRAL_TO_CUSTOMER"
                  [(serviceOrderTypeState)]="serviceOrderTypeState"
                  [(referalToCustomer)]="selectedServiceOrder.extension"
                  [status]="selectedServiceOrder.status"
                  [serviceOrderType]="selectedServiceOrderType"
                  [caseLocked]="caseStateService.isCaseDisabled() || selectedServiceOrder.lockedForEdit"
                  [caseId]="caseId"
                >
                </app-referral-to-customer>
                <app-advise-on-insurance-terms
                  #ServiceTypeComponent
                  *ngSwitchCase="serviceTypeEnum.ADVISE_ON_INSURANCE_TERMS"
                  [(serviceOrderTypeState)]="serviceOrderTypeState"
                  [advice]="selectedServiceOrder.extension"
                  [status]="selectedServiceOrder.status"
                  [serviceOrderType]="selectedServiceOrderType"
                  [caseLocked]="caseStateService.isCaseDisabled() || selectedServiceOrder.lockedForEdit"
                  [caseId]="caseId"
                >
                </app-advise-on-insurance-terms>
                <app-advice-call-registration
                  #ServiceTypeComponent
                  *ngSwitchCase="serviceTypeEnum.ADVICE_CALL_REGISTRATION"
                  [(serviceOrderTypeState)]="serviceOrderTypeState"
                  [(adviceCall)]="selectedServiceOrder.extension"
                  [serviceOrderType]="selectedServiceOrderType"
                  [leftSideValid]="serviceOrderTypeState.isLeftSideValid"
                  [caseLocked]="caseStateService.isCaseDisabled() || selectedServiceOrder.lockedForEdit"
                  [caseId]="caseId"
                >
                </app-advice-call-registration>
              <app-investigation
                #ServiceTypeComponent
                *ngSwitchCase="serviceTypeEnum.INVESTIGATION"
                [(serviceOrderTypeState)]="serviceOrderTypeState"
                [(investigationCall)]="selectedServiceOrder.extension"
                [serviceOrderType]="selectedServiceOrderType"
                [leftSideValid]="serviceOrderTypeState.isLeftSideValid"
                [caseLocked]="caseStateService.isCaseDisabled() || selectedServiceOrder.lockedForEdit"
                [caseId]="caseId"
              >
              </app-investigation>
              <app-translation
                #ServiceTypeComponent
                *ngSwitchCase="serviceTypeEnum.TRANSLATION"
                [(serviceOrderTypeState)]="serviceOrderTypeState"
                [(translationCall)]="selectedServiceOrder.extension"
                [serviceOrderType]="selectedServiceOrderType"
                [leftSideValid]="serviceOrderTypeState.isLeftSideValid"
                [caseLocked]="caseStateService.isCaseDisabled() || selectedServiceOrder.lockedForEdit"
                [caseId]="caseId"
              >
              </app-translation>
                <app-general-advice
                  #ServiceTypeComponent
                  *ngSwitchCase="serviceTypeEnum.GENERAL_ADVICE"
                  [(serviceOrderTypeState)]="serviceOrderTypeState"
                  [(generalAdvice)]="selectedServiceOrder.extension"
                  [leftSideValid]="serviceOrderTypeState.isLeftSideValid"
                  [serviceOrderType]="selectedServiceOrderType"
                  [caseLocked]="caseStateService.isCaseDisabled() || selectedServiceOrder.lockedForEdit"
                  [caseId]="caseId"
                >
                </app-general-advice>
                <app-money-transfer
                  #ServiceTypeComponent
                  *ngSwitchCase="serviceTypeEnum.MONEY_TRANSFER"
                  [(serviceOrderTypeState)]="serviceOrderTypeState"
                  [(moneyTransfer)]="selectedServiceOrder.extension"
                  [serviceOrderType]="selectedServiceOrderType"
                  [caseLocked]="caseStateService.isCaseDisabled() || selectedServiceOrder.lockedForEdit"
                  [caseId]="caseId"
                >
                </app-money-transfer>
                <app-medical-assessment-type
                  #ServiceTypeComponent
                  *ngSwitchCase="serviceTypeEnum.MEDICAL_ASSESSMENT"
                  [(serviceOrderTypeState)]="serviceOrderTypeState"
                  [caseId]="caseId"
                  [caseType]="caseType"
                  [(medicalAssessment)]="selectedServiceOrder.extension"
                  [serviceOrderType]="selectedServiceOrderType"
                  [serviceOrder]="selectedServiceOrder"
                  [caseLocked]="caseStateService.isCaseDisabled() || selectedServiceOrder.lockedForEdit"
                >
                </app-medical-assessment-type>
                <app-medical-pre-assessment
                  #ServiceTypeComponent
                  *ngSwitchCase="serviceTypeEnum.MEDICAL_PRE_ASSESSMENT"
                  [(serviceOrderTypeState)]="serviceOrderTypeState"
                  [(medicalPreAssessment)]="selectedServiceOrder.extension"
                  [serviceOrderType]="selectedServiceOrderType"
                  [status]="selectedServiceOrder.status"
                  [caseLocked]="caseStateService.isCaseDisabled() || selectedServiceOrder.lockedForEdit"
                  [caseId]="caseId"
                ></app-medical-pre-assessment>
                <app-other-refund-request
                  #ServiceTypeComponent
                  *ngSwitchCase="serviceTypeEnum.OTHER_REFUND_REQUEST"
                  [(serviceOrderTypeState)]="serviceOrderTypeState"
                  [caseId]="caseId"
                  [(otherRefundRequest)]="selectedServiceOrder.extension"
                  [serviceOrderType]="selectedServiceOrderType"
                  [caseLocked]="caseStateService.isCaseDisabled() || selectedServiceOrder.lockedForEdit"
                >
                </app-other-refund-request>
                <app-pre-departure-advice
                  #ServiceTypeComponent
                  *ngSwitchCase="serviceTypeEnum.PRE_DEPARTURE_TRAVEL_SERVICE"
                  [(serviceOrderTypeState)]="serviceOrderTypeState"
                  [(preDepartureAdvice)]="selectedServiceOrder.extension"
                  [serviceOrderType]="selectedServiceOrderType"
                  [status]="selectedServiceOrder.status"
                  [caseLocked]="caseStateService.isCaseDisabled() || selectedServiceOrder.lockedForEdit"
                  [caseId]="caseId"
                ></app-pre-departure-advice>
                <app-coordination-transport
                  #ServiceTypeComponent
                  *ngSwitchCase="serviceTypeEnum.COORDINATION_TRANSPORT"
                  [(serviceOrderTypeState)]="serviceOrderTypeState"
                  [(coordinationTransport)]="selectedServiceOrder.extension"
                  [status]="selectedServiceOrder.status"
                  [serviceOrderType]="selectedServiceOrderType"
                  [leftSideValid]="serviceOrderTypeState.isLeftSideValid"
                  [caseId]="caseId"
                  [caseLocked]="caseStateService.isCaseDisabled() || selectedServiceOrder.lockedForEdit"
                  [supplierSelected]="!!this.supplier"
                >
                </app-coordination-transport>
                <app-medical-flight-authorization
                  #ServiceTypeComponent
                  *ngSwitchCase="serviceTypeEnum.MEDICAL_FLIGHT_AUTHORIZATION"
                  [(serviceOrderTypeState)]="serviceOrderTypeState"
                  [(medicalFlightAuthorization)]="selectedServiceOrder.extension"
                  [serviceOrderType]="selectedServiceOrderType"
                  [status]="selectedServiceOrder.status"
                  [caseLocked]="caseStateService.isCaseDisabled() || selectedServiceOrder.lockedForEdit"
                  [caseId]="caseId"
                ></app-medical-flight-authorization>
                <app-major-incident
                  #ServiceTypeComponent
                  *ngSwitchCase="serviceTypeEnum.MAJOR_INCIDENT"
                  [caseId]="caseId"
                  [(serviceOrderTypeState)]="serviceOrderTypeState"
                  [(majorIncidentExtension)]="selectedServiceOrder.extension"
                  [leftSideValid]="serviceOrderTypeState.isLeftSideValid"
                  [serviceOrderType]="selectedServiceOrderType"
                  [status]="selectedServiceOrder.status"
                  [incidentId]="incidentId"
                  >
                </app-major-incident>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
  </div>
  <div class="modal-footer">
    <div class="d-flex">
      <div class="bottom-column">
        <div class="committed"></div>
        <div class="save-delete pe-4">
          <div
            [appDisableInput]="caseStateService.isCaseDisabled()"
            class="caselock-no-events">
            <span *permission="PermissionEnum.PLANS_UPDATE">
              <ng-template #popContent><span [innerHTML]="hoverContent | safeHtml"></span></ng-template>
              <button class="save-button" (click)="saveServiceOrder(false)" [disabled]="disableSaveButton()" (mouseenter)="generateMissingFieldsText()">
                <div [disablePopover]="setTimerUsed === true ? !showMissingFieldPopOver() : !showMissingFieldPopOverFirstTime()" triggers="hover" [ngbPopover]="popContent">
                  Sa<span class="shortcut-key">v</span>e and close
                </div>
              </button>
            </span>
            <span *permission="PermissionEnum.PLANS_DELETE">
              <div class="delete-button" *ngIf="showDeleteButton && !serviceOrderTypeState.isLockedByCoordinationTransport" (click)="markServiceOrderAsDeleted()">
                <img src="/assets/icons/garbageRed.svg" />
                <span>{{ 'service-order-delete' | translate }}</span>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</div>
