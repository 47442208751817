<div class="row justify-content-end header-amounts">
  <div class="col-2">
    <div class="row">
      <div class="col text-end amount-name">{{ 'economy-service-orders-list-total-reduction' | translate }}</div>
    </div>
    <div class="row">
      <div class="col">
        <div class="d-flex">
          <div class="flex-fill text-end amount-value">{{ totalSavingsAmount | number: '.2-2' }}</div>
          <div class="amount-currency">{{ caseCurrency }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-2">
    <div class="row">
      <div class="col text-end amount-name">{{ 'economy-service-orders-list-total-settled-amount' | translate }}</div>
    </div>
    <div class="row">
      <div class="col">
        <div class="d-flex">
          <div class="flex-fill text-end amount-value">{{ totalSettledAmount | number: '.2-2' }}</div>
          <div class="amount-currency">{{ caseCurrency }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-12 table-container" [class.zoomed]="settingsService.isZoomed()">
  <table class="primary-table">
    <thead>
      <tr>
        <td
          [ngClass]="sortedBy('type')"
          class="thead service-order-type"
          sortColumn
          (click)="sortBy('type')"
          [sortKey]="'type'"
          [data]="serviceOrders"
        >
          <div class="expand-collapse-link" style="float: left;" (click)="$event.stopPropagation()">
            <span (click)="expandAll();$event.stopPropagation()">
              <img src="/assets/icons/plus_square_icon.svg" width='16' height='16' [title]="'expand-all' | translate"/>
            </span> /
            <span (click)="collapseAll();$event.stopPropagation()">
              <img src="/assets/icons/minus_square_icon.svg" width='16' height='16' [title]="'collapse-all' | translate"/>
            </span>
          </div>
          <div style="float: left;">
            {{ 'economy-service-orders-table-head-service' | translate }}
          </div>
        </td>
        <td
          [ngClass]="sortedBy('serviceId')"
          class="thead service-order-id"
          (click)="sortBy('serviceId')"
          sortColumn
          [sortKey]="'serviceOrderId'"
          [data]="serviceOrders"
        >
          {{ 'economy-service-orders-table-head-id' | translate }}
        </td>
        <td
          [ngClass]="sortedBy('commitmentTypeView')"
          class="thead commitment-type"
          (click)="sortBy('commitmentTypeView')"
          sortColumn
          [sortKey]="'commitmentTypeView'"
          [data]="serviceOrders"
        >
          {{ 'economy-service-orders-table-head-refund' | translate }}
        </td>
        <td
          [ngClass]="sortedBy('statusIndex')"
          class="thead process-status"
          (click)="sortBy('statusIndex')"
          sortColumn
          [sortKey]="'statusIndex'"
          [data]="serviceOrders"
        >
          {{ 'economy-service-orders-table-head-progress' | translate }}
        </td>
        <td
          [ngClass]="sortedBy('expectedCostValueNumber')"
          class="thead"
          sortColumn
          (click)="sortBy('expectedCostValueNumber')"
          c
          [sortKey]="'expectedCostValueNumber'"
          [data]="serviceOrders"
        >
          {{ 'economy-service-orders-table-head-reserve' | translate }}
        </td>
        <td
          [ngClass]="sortedBy('invoiced')"
          class="thead"
          sortColumn
          (click)="sortBy('invoiced')"
          c
          [sortKey]="'invoiced'"
          [data]="serviceOrders"
        >
          {{ 'economy-service-orders-table-head-original' | translate }}
        </td>
        <td [ngClass]="sortedBy('saved')" class="thead" sortColumn (click)="sortBy('saved')" c [sortKey]="'saved'" [data]="serviceOrders">
          {{ 'economy-service-orders-table-head-reduction' | translate }}
        </td>
        <td
          [ngClass]="sortedBy('settled')"
          class="thead"
          sortColumn
          (click)="sortBy('settled')"
          c
          [sortKey]="'settled'"
          [data]="serviceOrders"
        >
          {{ 'economy-service-orders-table-head-settled' | translate }}
        </td>
      </tr>
    </thead>
    <tbody class="theBody">
      <ng-container *ngFor="let serviceOrder of serviceOrders; index as i">
        <tr class="odd" [ngClass]="{'expanded-border-style-bottom': isExpanded(i), 'expanded-border-style-top': isExpanded(i-1)}" >
          <td class="service-order-type">
            <div class="float-start" (mouseenter)="mouseEnter(serviceOrder, i, $event)" (mouseleave)="mouseLeave()" [ngbPopover]="popContent" triggers="mouseenter:mouseleave" placement="right">
              <div class="d-flex">
                <img [src]="getIconFileName(serviceOrder)" class="service-order-icon" (click)="openServiceOrder(serviceOrder.id)" />
                <div class="service-column-label">{{ 'case-sub-objects-' + getServiceType(serviceOrder) | translate }}</div>
              </div>
              <div class="service-order-provider">{{ getProviderName(serviceOrder) }}</div>
            </div>
            <div class="float-end pt-2 pe-1" (click)="expand(i)">
              <img *ngIf="showExpandIcon(serviceOrder)" class="icon" src="/assets/icons/ArrowDown.svg" [ngClass]="{ 'icon-rotated': expanded[i] }" />
            </div>
          </td>
          <td class="service-order-id" (mouseenter)="mouseEnter(serviceOrder, i, $event)" (mouseleave)="mouseLeave()">
            <div class="float-start">
              <div class="d-flex">
                <div class="service-order-id">{{ serviceOrder.serviceOrderId }}</div>
              </div>
            </div>
          </td>
          <td class="commitment-type" [ngClass]="{ even: i % 2 != 0 }">
            <div class="commitment-type-div" [ngbPopover]="hoverText(serviceOrder)" triggers="hover" placement="auto" openDelay="400">
                <img *ngIf="isClaimSOS(serviceOrder)" [src]="'assets/icons/service-order-type/OtherRefund.svg'"/>
                <img *ngIf="isClaimCustomer(serviceOrder)" [src]="'assets/icons/service-order-type/OtherRefundGrey.svg'"/>
            </div>
          </td>
          <td class="process-status pt-2">
            <app-economy-process-bar
              [eventsToDisplay]="getEventsToDisplay()"
              [hidden]="getHiddenEvents(serviceOrder)"
              [progress]="serviceOrder.progress"
            ></app-economy-process-bar>
          </td>
          <td>
            <div class="d-flex"  *ngIf="!removeAmountAndCurrency(serviceOrder)">
              <div class="flex-fill currency">{{ caseCurrency }}</div>
              <div class="amount">{{ serviceOrder.expectedCostValue | number: '.2-2' }}</div>
            </div>
          </td>
          <td>
            <div class="d-flex" *ngIf="!removeAmountAndCurrency(serviceOrder)">
              <div class="flex-fill currency-case">{{ caseCurrency }}</div>

              <div *ngIf="serviceOrder.supplierInvoices.length !== 0" class="amount-case">{{ calculations.calculateServiceOrderInvoiced(serviceOrder) | number: '.2-2' }}</div>
              <div *ngIf="serviceOrder.refunds.length !== 0" class="amount-case">{{ reductionsCalculator.calculateServiceOrderRefundsOriginalAmount(serviceOrder) | number: '.2-2' }}</div>
            </div>

            <div class="d-flex" *ngIf="!removeAmountAndCurrency(serviceOrder)">
              <div *ngIf="serviceOrder.supplierInvoices.length !== 0" class="flex-fill currency-refund">{{ serviceOrder.supplierInvoices[0].invoiceAmountLocal.currency }}</div>
              <div *ngIf="serviceOrder.refunds.length !== 0" class="flex-fill currency-refund">{{ serviceOrder.refunds[0].refundCurrency }}</div>
              <div *ngIf="serviceOrder.supplierInvoices.length !== 0" class="amount-refund">{{ calculations.calculateServiceOrderInvoiced(serviceOrder, false) | number: '.2-2' }}</div>
              <div *ngIf="serviceOrder.refunds.length !== 0" class="amount-refund">{{ reductionsCalculator.calculateServiceOrderRefundsExchangedAmount(serviceOrder) | number: '.2-2' }}</div>
            </div>
          </td>
          <td>
            <div
              class="d-flex"
              *ngIf="!removeAmountAndCurrency(serviceOrder) &&
                serviceOrder.invoicesStatus !== ServiceOrderStatus.invoiceReceived &&
                serviceOrder.invoicesStatus !== ServiceOrderStatus.ongoingCostControl
              "
            >
              <div class="flex-fill currency">{{ caseCurrency }}</div>
              <div *ngIf="serviceOrder.supplierInvoices.length !== 0" class="amount">{{ calculations.calculateServiceOrderSavings(serviceOrder) | number: '.2-2' }}</div>
              <div *ngIf="serviceOrder.refunds.length !== 0" class="amount">{{ reductionsCalculator.calculateServiceOrderRefundsReductionAmount(serviceOrder) | number: '.2-2' }}</div>
            </div>
          </td>
          <td>
            <div
              class="d-flex"
              *ngIf="!removeAmountAndCurrency(serviceOrder) &&
                serviceOrder.invoicesStatus !== ServiceOrderStatus.invoiceReceived &&
                serviceOrder.invoicesStatus !== ServiceOrderStatus.ongoingCostControl
              "
            >
              <div class="flex-fill currency">{{ caseCurrency }}</div>
              <div *ngIf="serviceOrder.supplierInvoices.length !== 0" class="amount">{{ calculations.calculateServiceOrderSettled(serviceOrder) | number: '.2-2' }}</div>
              <div *ngIf="serviceOrder.refunds.length !== 0" class="amount">{{ reductionsCalculator.calculateServiceOrderRefundsNetAmount(serviceOrder) | number: '.2-2' }}</div>
            </div>
          </td>
        </tr>
        <ng-container *ngFor="let supplierInvoice of serviceOrder.supplierInvoices">
          <tr *ngIf="expanded[i]" class="even">
            <td colspan="3" class="service-item">
              <div class="float-start">
                <div class="d-flex">
                  <div><span class="label-name">{{'economy-service-orders-table-invoice' | translate}}:</span>{{ supplierInvoice.invoiceNumber }}</div>
                  <div *permission="PermissionEnum.MEDICAL_ESCORT_SUPPLIER_INVOICE_READ">
                    <app-open-invoice-pdf
                      *ngIf="supplierInvoice.medicalEscort"
                      [invoiceDocumentId]="supplierInvoice.invoiceDocumentId"
                    ></app-open-invoice-pdf>
                  </div>
                  <app-open-invoice-pdf
                    *ngIf="!supplierInvoice.medicalEscort"
                    [invoiceDocumentId]="supplierInvoice.invoiceDocumentId"
                  ></app-open-invoice-pdf>
                </div>
                <div *permission="PermissionEnum.MEDICAL_ESCORT_SUPPLIER_INVOICE_READ">
                  <div *ngIf="supplierInvoice.medicalEscort" class="manage" (click)="openManageInvoice(supplierInvoice.id)">Manage</div>
                </div>
                <div *ngIf="!supplierInvoice.medicalEscort" class="manage" (click)="openManageInvoice(supplierInvoice.id)">Manage</div>
                <div *ngIf="supplierInvoice.internalRemark">
                  <span class="label-name">{{ 'economy-service-orders-table-internal-remark' | translate }}:</span>{{supplierInvoice.internalRemark}}
                </div>
              </div>
            </td>
            <td class="commitment-type">
              <div class="commitment-type-div">
              </div>
            </td>
            <td class="process-status pt-2">
              <app-economy-process-bar
                [eventsToDisplay]="getEventsToDisplay()"
                [hidden]="[BalanceSheetEventEnum.EXPECTED,BalanceSheetEventEnum.COMMITMENT]"
                [identifier]="supplierInvoice.invoiceNumber"
                [type]="supplierInvoice.getType()"
                [progress]="supplierInvoice.progress"
              ></app-economy-process-bar>
            </td>


            <td colspan="4" class="p-0 border-0">
              <table class="innerItems">
                <tr *ngFor="let item of getSupplierInvoiceItemsForServiceOrder(serviceOrder, supplierInvoice); count as c" [style.height.%]="c ? 100/c : 100">
                  <td class="invoice-item-service-name">
                    {{ item.serviceItemName }}
                  </td>
                  <td class="invoice-item-currency">
                    <div class="d-flex" *ngIf="!!item.paidAmount">
                      <div class="flex-fill currency-case">{{ caseCurrency }}</div>
                      <div class="amount-case">{{ calculations.calculateItemAmount(supplierInvoice, item) | number: '.2-2' }}</div>
                    </div>
                    <div class="d-flex" *ngIf="!!item.paidAmount">
                      <div class="flex-fill currency-refund">{{ supplierInvoice.invoiceAmountLocal.currency }}</div>
                      <div class="amount-refund">{{ calculations.calculateItemAmount(supplierInvoice, item, false) | number: '.2-2' }}</div>
                    </div>
                  </td>
                  <td class="invoice-item-currency">
                    <div
                      class="d-flex"
                      *ngIf="
                        !!item.paidAmount &&
                        supplierInvoice.invoiceStatus !== SupplierInvoiceStatus.invoiceReceived &&
                        supplierInvoice.invoiceStatus !== SupplierInvoiceStatus.ongoingCostControl &&
                        supplierInvoice.invoiceStatus !== SupplierInvoiceStatus.invoiceCorrected &&
                        supplierInvoice.invoiceStatus !== SupplierInvoiceStatus.awaitingAccounting
                      "
                    >
                      <div class="flex-fill currency">{{ caseCurrency }}</div>
                      <div class="amount">{{ calculations.calculateItemSavings(supplierInvoice, item) | number: '.2-2' }}</div>
                    </div>
                  </td>
                  <td class="invoice-item-currency">
                    <div
                      class="d-flex"
                      *ngIf="
                        !!item.paidAmount &&
                        supplierInvoice.invoiceStatus !== SupplierInvoiceStatus.invoiceReceived &&
                        supplierInvoice.invoiceStatus !== SupplierInvoiceStatus.ongoingCostControl &&
                        supplierInvoice.invoiceStatus !== SupplierInvoiceStatus.invoiceCorrected &&
                        supplierInvoice.invoiceStatus !== SupplierInvoiceStatus.awaitingAccounting
                      "
                    >
                      <div class="flex-fill currency">{{ caseCurrency }}</div>
                      <div class="amount">{{ calculations.calculateItemSettled(supplierInvoice, item) | number: '.2-2' }}</div>
                    </div>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="serviceOrder.supplierExpensesAppendixes?.length > 0">
          <tr *ngIf="expanded[i]" class="even">
            <td colspan="4" class="invoice-expenses">
              <div class="fxflex" fxLayout="row">
                <div class="label-name">{{ 'economy-service-orders-supplier-expenses' | translate }}:</div>
                <div class="fxflex" fxLayout="column">
                  <div class="float-start" *ngFor="let expensesDocument of getServiceOrderExpenseDocuments(serviceOrder)">
                    <div>
                      <span [ngClass]="{'expense-document': !containsFileScanInformation(expensesDocument)}" (click)="openDocumentInEmbeddedView(expensesDocument)">{{ getDocumentName(expensesDocument) }}</span>
                      <span *ngIf="containsFileScanInformation(expensesDocument)" container="body" [ngbPopover]="hoverFileScanTemplate" placement="bottom-left" triggers="hover">
                        <span>
                          <img class="virus-information-icon" [src]="getFileScanIcon(expensesDocument)"/>
                        </span>
                        <ng-template #hoverFileScanTemplate>
                          <app-hover-file-scan [fileScan]="expensesDocument?.fileScan"></app-hover-file-scan>
                        </ng-template>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td></td>
            <td>
              <div class="d-flex" *ngIf="serviceOrder.supplierExpensesAppendixes[0]?.amountInvoice">
                <div class="flex-fill currency">{{ caseCurrency }}</div>
                <div class="amount">{{ serviceOrder.supplierExpensesAppendixes[0]?.amountInvoice.amount | number: '.2-2' }}</div>
              </div>
            </td>
            <td>
              <div class="d-flex" *ngIf="serviceOrder.supplierExpensesAppendixes[0]?.amountSavings">
                <div class="flex-fill currency">{{ caseCurrency }}</div>
                <div class="amount">{{ serviceOrder.supplierExpensesAppendixes[0]?.amountSavings.amount | number: '.2-2' }}</div>
              </div>
            </td>
            <td>
              <div class="d-flex" *ngIf="serviceOrder.supplierExpensesAppendixes[0]?.amountSettled">
                <div class="flex-fill currency">{{ caseCurrency }}</div>
                <div class="amount">{{ serviceOrder.supplierExpensesAppendixes[0]?.amountSettled.amount | number: '.2-2' }}</div>
              </div>
            </td>
          </tr>
        </ng-container>

        <ng-container *ngFor="let refund of serviceOrder.refunds">
          <tr *ngIf="expanded[i]" class="even">
            <td colspan="2" class="service-item">
              <span class="label-name">{{ 'economy-service-orders-table-refund' | translate }}:</span>{{ refund.id}}<br/>
              <div class="manage" (click)="openManageRefund(refund.id)">
                {{ 'economy-service-orders-table-manage' | translate }}
              </div>
              <div *ngIf="refund.internalRemark">
                <span class="label-name">{{ 'economy-service-orders-table-internal-remark' | translate }}:</span>{{refund.internalRemark}}
              </div>
            </td>
            <td class="commitment-type"></td>
            <td class="process-status" >
              <app-economy-process-bar
                [eventsToDisplay]="getEventsToDisplay()"
                [hidden]="[BalanceSheetEventEnum.EXPECTED,BalanceSheetEventEnum.COMMITMENT]"
                [identifier]="refund.id.toString()"
                [type]="refund.getType()"
                [progress]="refund.progress"
              ></app-economy-process-bar>
            </td>

            <td colspan="4" class="p-0 border-0">
                <table class="innerItems">
                  <tr *ngFor="let serviceOrderRefundItemViewModel of refund.serviceOrderRefundItemViewModel" style="height:100%">
                    <td>
                      <div class="text">
                        {{ serviceOrderRefundItemViewModel.itemName }}
                      </div>
                    </td>
                    <td>
                      <div *ngIf="isRefundApproved(refund)" class="d-flex">
                        <div class="flex-fill currency-case">{{ caseCurrency }}</div>
                        <div class="amount-case">{{ serviceOrderRefundItemViewModel.amount | number: '.2-2' }}</div>
                      </div>

                      <div *ngIf="isRefundApproved(refund)" class="d-flex">
                        <div class="flex-fill currency-refund">{{ serviceOrderRefundItemViewModel.exchangeCurrency }}</div>
                        <div class="amount-refund">{{ serviceOrderRefundItemViewModel.amountExchanged | number: '.2-2' }}</div>
                      </div>
                    </td>
                    <td>
                      <div *ngIf="isRefundApproved(refund)" class="d-flex">
                        <div class="flex-fill currency">{{ caseCurrency }}</div>
                        <div class="amount">{{ serviceOrderRefundItemViewModel.reduction | number: '.2-2' }}</div>
                      </div>
                    </td>
                    <td>
                      <div *ngIf="isRefundApproved(refund)" class="d-flex">
                        <div class="flex-fill currency">{{ caseCurrency }}</div>
                        <div class="amount">{{ serviceOrderRefundItemViewModel.settled | number: '.2-2' }}</div>
                      </div>
                    </td>
                  </tr>
                </table>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    <div class="last-entry-blank">

    </div>
    </tbody>
  </table>
  <ng-template #popContent>
    <app-new-service-order-hover [caseStakeholder]="caseStakeholder" [serviceOrderHoverModel]="serviceOrderHoverModel"></app-new-service-order-hover>
  </ng-template>
</div>
