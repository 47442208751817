<div class="case-basics-card case-basic-info-lniked-cases" style="min-height: 442px;" [appDisableInput]="caseStateService.isCaseDisabled()">
    <div class="row">
        <div class="col button-box">
            <button [disabled]="!isCaseNumberAssigned || !areLinkedCasesLoaded" (click)="openModal()" [class.primary-button-inactive]="!isCaseNumberAssigned || !areLinkedCasesLoaded"  class="primary-button m-2">{{ 'case-basics-linked-cases-add-link-to-case' | translate }}</button>
        </div>
        <div class="col">
            <div class="headline-container">
                <div class="d-flex headline">
                    <div class="casenumber casenumber-headline">{{ 'case-basics-linked-cases-case-number' | translate }}</div>
                    <div class="name" >{{ 'case-basics-linked-cases-name' | translate }}</div>
                    <div class="age" >{{ 'case-basics-linked-cases-age' | translate }}</div>
                    <div class="primary">{{ 'case-basics-linked-cases-primary-case-handler' | translate }}</div>
                    <div class="status">{{ 'case-basics-linked-cases-status' | translate }}</div>
                    <div class="delete"></div>
                </div>
            </div>
            <div class="list-container">
                <div *ngIf="!areLinkedCasesLoaded">
                    <img src="/assets/icons/waitForSearchresult.gif" class="spinner"  />
                </div>
                <div *ngIf="areLinkedCasesLoaded">
                 <ng-container *ngFor="let case of coordinationList; index as i">
                    <div class="d-flex line"  >
                        <div class="casenumber" (click)="redirectToCase(case.caseId)">
                            {{ case.caseNumber }}
                        </div>
                        <div class="name red-color">
                            {{ case.name }}
                        </div>
                        <div class="age">
                            -
                        </div>
                        <div class="primary">
                            {{ case.primary }}
                        </div>
                        <div class="status">
                            <img src="/assets/icons/folder-outline.svg" *ngIf="case.status === HandlingAreaStatusEnum.CLOSED"  />
                            <img src="/assets/icons/folder-open-outline.svg" *ngIf="case.status === HandlingAreaStatusEnum.REOPENED"  />
                            <img src="/assets/icons/folder-more-open.svg" *ngIf="case.status === HandlingAreaStatusEnum.OPEN"  />
                        </div>
                        <div class="delete">
                            <img src="/assets/icons/garbage.svg"  *ngIf="!caseStateService.isCaseDisabled()" height="16px" (click)="deleteLink(case.caseId)" />
                        </div>
                    </div>
                </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>