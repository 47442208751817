import {Component, EventEmitter, Output, TemplateRef, ViewChild} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SalesOrderService } from '@secca/core/services/sales-order.service';
import { SalesOrder } from '@secca/shared/models/salesOrder';

@Component({
  selector: 'app-customer-invoice-delete-modal',
  templateUrl: './customer-invoice-delete-modal.component.html',
  styleUrls: ['./customer-invoice-delete-modal.component.scss'],
})
export class CustomerInvoiceDeleteModalComponent {
  @ViewChild('content') private template: TemplateRef<any>;
  saveInProgress = false;
  salesOrder: SalesOrder;
  actionComment: string;
  @Output() salesOrderDeleted = new EventEmitter();

  constructor(private salesOrderService: SalesOrderService, private modalService: NgbModal) {}

  show(salesOrder: SalesOrder) {
    this.salesOrder = salesOrder;
    this.modalService.open(this.template, { size: 'lg' as any });
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  deleteSalesOrder() {
    this.saveSalesOrder();
  }

  private saveSalesOrder() {
    this.saveInProgress = true;
    this.salesOrder.customerInvoiceNote = this.actionComment;
    this.salesOrderService.deleteSalesOrder(this.salesOrder.id, this.salesOrder).subscribe(so => {
      this.salesOrderService.getSalesOrdersByCaseId(this.salesOrder.seccaCaseId).subscribe(caseSalesOrders => {
        this.salesOrderService.sendSalesOrders(caseSalesOrders);
      });
      this.closeModal();
    });
  }
}
