<ng-container *ngIf="coordinationMedicalAssessment">
    <app-sub-repatriation-form-icc
      [caseId]="caseId"
      [stakeholders]="companyStakeholders"
      [subRepatriationDetails]="repatriationDetails"
      [iccRepatriationInfo]="iccRepatriationInfo"
      [addRepatriationDisabled] = addRepatriationDisabled
      [readOnlyMode]="addRepatriationDisabled"
    >
  </app-sub-repatriation-form-icc>
</ng-container>
