import { Component, Input, ViewEncapsulation } from "@angular/core";
import { TaskModalCommonHeaderComponent } from "@secca/shared/components/task-modal-common/task-modal-common-header.component";
import { AutoUnsubscribe } from "@secca/shared/decorators/auto-unsubscribe";
import { Case } from "@secca/shared/models/case";

@Component({
  selector: 'app-task-modal-static-header',
  templateUrl: './task-modal-static-header.component.html',
  styleUrls: ['./task-modal-static-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@AutoUnsubscribe
export class TaskModalStaticHeaderComponent extends TaskModalCommonHeaderComponent {
  @Input() case: Case;
  @Input() actualTimeZoneId: string;
  @Input() profileName: string;
  @Input() location: string;
  @Input() primaryCaseHandler: string;
  @Input() reserve: number;
  @Input() currency: string;
  @Input() incidentCause: string;
  @Input() lastCaseActionBy: string;
  @Input() lastCaseActionTime: Date;
  @Input() isCoordinationCase: boolean;

  constructor() {
    super();
  }
}