import * as moment from 'moment';
import { Moment } from 'moment-timezone';

export class DateHelper {
  public static numberOfHoursBetweenDates(startDate: Date, endDate: Date): number {
    const startMoment = moment(startDate);
    const endMoment = moment(endDate);
    return Math.floor(moment.duration(endMoment.diff(startMoment)).asHours());
  }

  public static findDateIndexInDates(dates: Date[], searchDate: Date): number {
    const date = dates.find(
      a =>
        a != null &&
        searchDate != null &&
        a.getDate() === searchDate.getDate() &&
        a.getFullYear() === searchDate.getFullYear() &&
        a.getMonth() === searchDate.getMonth()
    );
    return dates.indexOf(date);
  }

  public static datesInTheSameDay(firstDate: Date, secondDate: Date): boolean {
    if (firstDate == null || secondDate == null) {
      return false;
    }
    return (
      firstDate.getFullYear() === secondDate.getFullYear() &&
      firstDate.getMonth() === secondDate.getMonth() &&
      firstDate.getDate() === secondDate.getDate()
    );
  }

  public static datesInTheSameDayHourMinute(firstDate: Date, secondDate: Date): boolean {
    if (firstDate == null || secondDate == null) {
      return false;
    }
    return (
      firstDate.getFullYear() === secondDate.getFullYear() &&
      firstDate.getMonth() === secondDate.getMonth() &&
      firstDate.getDate() === secondDate.getDate() &&
      firstDate.getHours() === secondDate.getHours() &&
      firstDate.getMinutes() === secondDate.getMinutes()
    );
  }

  public static getDaysRangeBothDatesIncl(dt1: Date, dt2: Date) {
    if (dt1 && dt2) {
      return (
        Math.floor(
          (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
            (1000 * 60 * 60 * 24)
        ) + 1
      );
    } else {
      return null;
    }
  }

  public static setTimeAs23Hour59Minutes(dateMoment: Moment) {
    return dateMoment.clone().hours(23).minutes(59);
  }
}
