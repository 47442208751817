import { MajorIncidentStakeholder } from "./majorIncidentStakeholder";

export class MajorIncidentStakeholders {
    stakeholders: MajorIncidentStakeholder[] = [];


    constructor(stakeholders?: MajorIncidentStakeholder[]) {
        this.stakeholders = !!stakeholders ? stakeholders : [];
    }


    push(stakeHolder: MajorIncidentStakeholder) {
        this.stakeholders.push(stakeHolder);
    }

    delete(stakeHolder: MajorIncidentStakeholder) {
        const index = this.stakeholders.findIndex(d => d === stakeHolder);
        this.stakeholders.splice(index, 1);
    }

    byCase(caseId: string) {
        return this.stakeholders.filter(stakeholder => stakeholder.caseId == caseId);
    }

    allreadyChosen(caseId: string,stakeholderId: string ) :boolean {
        const stakeholdersByCase = this.byCase(caseId);
        return stakeholdersByCase.slice(0, stakeholdersByCase.length-1)
        .some(a => a.stakeholderId === stakeholderId)
    }
}
