import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { CaseIncident, CaseIncidentAdapter, IncidentDtoWrapper } from '../../shared/models/caseIncident';
import { SettingsService } from './settings.service';
import { Location, LocationAdapter } from '../../shared/models/location';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CaseLockHttpService } from './case-lock-http.service';
import { QueueService } from './queue.service';
import { UpdateIncidentQueueService } from './update-incident-queue.service';
import * as moment from 'moment';
import { CausesLevel1DD, CausesLevel1DDAdapter } from '@secca/shared/models/CausesLevel1DD';

@Injectable({
  providedIn: 'root',
})
export class IncidentService extends BaseService {
  constructor(
    private http: HttpClient,
    private incidentAdapter: CaseIncidentAdapter,
    private incidentDtoWrapper: IncidentDtoWrapper,
    private incidentLocationAdapter: LocationAdapter,
    private settingsService: SettingsService,
    private caseLockHttpService: CaseLockHttpService,
    private updateIncidentQueueService: UpdateIncidentQueueService,
    private queueService: QueueService,
    private causesLevel1DDAdapter: CausesLevel1DDAdapter
  ) {
    super(settingsService);
  }

  private incidentDateChanges = {};

  public getIncidentDateChanges(caseId: string): Observable<moment.Moment> {
    this.initIncidentDateChangeSubject(caseId);
    return this.incidentDateChanges[caseId].asObservable();
  }

  public incidentDateChanged(caseId: string, incidentDate: moment.Moment) {
    this.initIncidentDateChangeSubject(caseId);
    this.incidentDateChanges[caseId].next(incidentDate);
  }

  private initIncidentDateChangeSubject(caseId: string) {
    if (this.incidentDateChanges[caseId] == null) {
      this.incidentDateChanges[caseId] = new Subject();
    }
  }

  public getIncidentCauses(): Observable<CausesLevel1DD[]> {
    return this.http
      .get<CausesLevel1DD[]>(this.baseURL + 'incidents/causes/level1/all')
      .pipe(map(items => items.map(item => this.causesLevel1DDAdapter.adapt(item))));
  }

  public getIncidentCausesForCase(caseId: string): Observable<CausesLevel1DD[]> {
    return this.http
      .get<CausesLevel1DD[]>(this.baseURL + 'incidents/causes/level1/all/' + caseId)
      .pipe(map(items => items.map(item => this.causesLevel1DDAdapter.adapt(item))));
  }

  public getCaseIncident(id: number): Observable<CaseIncident> {
    try {
      return this.http.get(this.baseURL + 'incidents/' + id).pipe(map(item => this.incidentAdapter.adapt(item)));
    } catch (e) {
      console.log(e);
    }
  }

  public getIncidentEventLockedByServiceOrder(caseId: string, caseTypeCode: string): Observable<any> {
    return this.http.get(this.baseURL + 'incidents/incident-event-locked-by-service-order/' + caseId + '/' + caseTypeCode);
  }

  public updateIncidentLocation(id: number, location: Location) {
    return this.http.put<any>(this.baseURL + 'incidents/' + id + '/incidentLocation', location, { headers: this.jsonHeaders });
  }

  public updateCurrentLocation(id: number, location: Location) {
    return this.http.put<any>(this.baseURL + 'incidents/' + id + '/currentLocation', location, { headers: this.jsonHeaders });
  }

  public updateIncident(incident: CaseIncident, caseId: number) {
    return this.caseLockHttpService.post<any>(this.baseURL + 'incidents/' + caseId, this.incidentDtoWrapper.wrap(incident), {
      headers: this.jsonHeaders,
    });
  }

  public updateeIncidentWithQueueService(caseIncident: CaseIncident, caseId: number) {
    this.queueService.pushIncident(caseIncident);
    return this.updateIncidentQueueService.actionSchedule(caseId);
  }
}
