import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { SendFaxRequestDto, SendFaxRequestDtoAdapter } from './../models/send-fax-request-dto';
import { SendSmsRequestDto, SendSmsRequestDtoAdapter } from '../models/send-sms-request-dto';
import { SendMailRequestDto, SendMailRequestDtoAdapter } from './../models/send-mail-request-dto';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { BaseService } from './../../../../../core/services/base.service';
import { SettingsService } from './../../../../../core/services/settings.service';
import { HttpClient } from '@angular/common/http';
import { MessageChannelType, ValidationTypeEnum } from './../../../../../shared/models/enums';
import { Message } from './../models/message';
import { Injectable, EventEmitter, Directive } from '@angular/core';
import { CaseLockHttpService } from '@secca/core/services/case-lock-http.service';
import {ServiceOrderMessageRequest} from '@secca/shared/models/service-order/service-order-message-request';

@Directive()
@Injectable({
  providedIn: 'root',
})
export class OutputManagementService extends BaseService {
  private messagesDictionary = {};
  openOutputManagementDialog = new Subject<ServiceOrderMessageRequest>();
  selectedMessage = new EventEmitter();

  readonly MAX_MAIL_MESSAGE_SIZE = 20000000; // 20 Mb

  constructor(
    private http: HttpClient,
    private sendMailRequestDtoAdapter: SendMailRequestDtoAdapter,
    private sendFaxRequestDtoAdapter: SendFaxRequestDtoAdapter,
    private sendSmsRequestDtoAdapter: SendSmsRequestDtoAdapter,
    private settingsService: SettingsService,
    private caseLockHttpService: CaseLockHttpService,
  ) {
    super(settingsService);
  }

  public emitMessage(message: Message) {
    this.selectedMessage.emit(message);
  }

  public openMessageWindow(caseId: string, message: ServiceOrderMessageRequest) {
    this.openOutputManagementDialog.next(message);
  }

  public sendMessage(message: Message) {

    switch (message.messageChannelType) {
      case MessageChannelType.EMAIL:
        const sendMailrequestDto = this.sendMailRequestDtoAdapter.adapt(message);
        if (JSON.stringify(sendMailrequestDto).length > this.MAX_MAIL_MESSAGE_SIZE) {
          return throwError({status: 413});
        }
        return this.sendMail(sendMailrequestDto);
      case MessageChannelType.FAX:
        const sendFaxRequestDto = this.sendFaxRequestDtoAdapter.adapt(message);
        return this.sendFax(sendFaxRequestDto);
      case MessageChannelType.SMS:
        const sendSmsRequestDto = this.sendSmsRequestDtoAdapter.adapt(message);
        return this.sendSms(sendSmsRequestDto);
    }
  }

  private sendSms(sendSmsRequestDto: SendSmsRequestDto): Observable<any> {
    return this.caseLockHttpService.post<any>(this.baseURL + 'sms/send', sendSmsRequestDto, {
      headers: this.jsonHeaders,
    });
  }

  private sendMail(sendMailRequestDto: SendMailRequestDto): Observable<any> {
    return this.caseLockHttpService.post<any>(this.baseURL + 'mails', sendMailRequestDto, {
      headers: this.jsonHeaders,
    });
  }

  private sendFax(sendFaxRequestDto: SendFaxRequestDto): Observable<any> {
    return this.caseLockHttpService.post<any>(this.baseURL + 'fax', sendFaxRequestDto, {
      headers: this.jsonHeaders,
    });
  }

  public mapToValidationTypeEnum(messageChannelType: MessageChannelType): ValidationTypeEnum {
    switch (messageChannelType) {
      case MessageChannelType.EMAIL:
        return ValidationTypeEnum.emailAndValid;
      case MessageChannelType.FAX:
        return ValidationTypeEnum.numbers;
      case MessageChannelType.SMS:
        return ValidationTypeEnum.numbers;
    }
  }
}
