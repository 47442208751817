import { SubTravellers } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subTravellers';
import { SubStakeholders } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholders';
import {
  SubStakeholder,
  SubStakeholderAdapter
} from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholder';
import { SubTravelInfo, SubTravelInfoAdapter } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subTravelInfo';

export class ICCAmbulanceFlight {
  linkedCaseId: number;
  groundTransportToFlightNotIncluded: boolean;
  groundTransportFromFlightNotIncluded: boolean;
  groundTransportToFlight: SubTravelInfo;
  groundTransportFromFlight: SubTravelInfo;
  subGroundTransportToFlight: SubTravellers;
  subGroundTransportFromFlight: SubTravellers;
  subGroundTransportToFlightStakeholders: SubStakeholders;
  subGroundTransportFromFlightStakeholders: SubStakeholders;
  groundTransportToFlightStakeholders: SubStakeholder[];
  groundTransportFromFlightStakeholders: SubStakeholder[];

  public constructor(init?: Partial<ICCAmbulanceFlight>) {
    Object.assign(this, init);
  }
}

export class ICCAmbulanceFlightAdapter {
  adapt(item: any): ICCAmbulanceFlight {
    const subStakeholderAdapter = new SubStakeholderAdapter();
    const subTravelInfoAdapter = new SubTravelInfoAdapter();
    const groundTransportToFlightAdapted = subTravelInfoAdapter.adapt(item.groundTransportToFlight);
    const groundTransportFromFlightAdapted = subTravelInfoAdapter.adapt(item.groundTransportFromFlight);
    const groundTransportToFlightStakeholdersAdapted = item.groundTransportToFlightStakeholders.map(stakeholder => subStakeholderAdapter.adapt(stakeholder));
    const groundTransportFromFlightStakeholdersAdapted = item.groundTransportFromFlightStakeholders.map(stakeholder => subStakeholderAdapter.adapt(stakeholder));

    return new ICCAmbulanceFlight({
      linkedCaseId: item.linkedCaseId,
      groundTransportToFlightNotIncluded: item.groundTransportToFlightNotIncluded,
      groundTransportFromFlightNotIncluded: item.groundTransportFromFlightNotIncluded,
      groundTransportToFlight: groundTransportToFlightAdapted,
      groundTransportFromFlight: groundTransportFromFlightAdapted,
      subGroundTransportToFlight: new SubTravellers([groundTransportToFlightAdapted]),
      subGroundTransportFromFlight: new SubTravellers([groundTransportFromFlightAdapted]),
      subGroundTransportToFlightStakeholders: new SubStakeholders(groundTransportToFlightStakeholdersAdapted),
      subGroundTransportFromFlightStakeholders: new SubStakeholders(groundTransportFromFlightStakeholdersAdapted),
      groundTransportToFlightStakeholders: groundTransportToFlightStakeholdersAdapted,
      groundTransportFromFlightStakeholders: groundTransportFromFlightStakeholdersAdapted,
    });
  }
}
