import { Component, Input, OnInit } from '@angular/core';
import { SubRemarksToSupplier } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subRemarksToSupplier';

@Component({
  selector: 'app-sub-remarks-to-supplier',
  templateUrl: './sub-remarks-to-supplier.component.html',
  styleUrls: ['./sub-remarks-to-supplier.component.scss']
})
export class SubRemarksToSupplierComponent implements OnInit {
  @Input() subRemarksToSupplier: SubRemarksToSupplier;
  @Input() readOnlyMode: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
