

export class ModalDialogConfiguration {
  header: string;
  title: string;
  text: string;
  footer: string;
  isFooter: boolean;
  isBody = true;
  yes: string;
  no: string;
  saveandclose: string;
  showTextArea = false;
  showTextAreaWithYesNo = false;
  modalYesHoverDialog: string;
  fieldText: string;
  showYesNoButton = true;
  placeholderText: string;
  labelTextAboveTextArea: string;
  translateTitle = true;
  translateText = true;
  redFooterText = false;

  public constructor(init?: Partial<ModalDialogConfiguration>) {
    Object.assign(this, init);
  }

}


