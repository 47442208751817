
export class DateFormatter {

  static formatToNumberOfDays(numberOfDays: number) {
    if (isNaN(numberOfDays)) {
      return '0 day(s)';
    }
    return String(numberOfDays) + ' day(s)';
  }
}
