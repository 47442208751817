
export class WorkInProgress {
  type: string;
  caseId: string;

  public constructor(init?: Partial<WorkInProgress>) {
    Object.assign(this, init);
  }

}
