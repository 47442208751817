import { DropdownDictionary } from 'src/app/shared/models/dropdownDictionary';
import { TeamDto } from 'src/app/shared/models/teamDto';
import { UserDto } from '@secca/shared/models/userDto';
import { Adapter } from 'src/app/shared/interfaces/adapter';
import { Injectable } from '@angular/core';
import { UserDepartmentAdapter } from './userDepartment';
import { UserTeamAdapter } from './userTeam';

export class UserTeamDto extends UserDto {
  isTeam: boolean = false;
  adtCode: string;
  adtCodes: string[];
  fullNameAndInitialt: string;
  disabled: boolean;

  public constructor(init?: Partial<UserTeamDto>) {
    super();
    Object.assign(this, init);
  }
}
@Injectable({
  providedIn: 'root',
})
export class UserTeamDtoAdapter implements Adapter<UserTeamDto> {
  adapt(item: TeamDto): UserTeamDto {
    return new UserTeamDto({
      active: true,
      firstName: item.teamName,
      fullName: item.teamName,
      id: item.id,
      isTeam: true,
      adtCode: item.adtCode,
    });
  }

  adaptUser(item: UserDto): UserTeamDto {
    return new UserTeamDto({
      active: true,
      firstName: item.firstName,
      fullName: item.firstName + ' ' + item.surname + ', ' + item.initials,
      fullNameAndInitialt: item.firstName + ' ' + item.surname + ', ' + item.initials,
      initials: item.initials,
      picture: item.picture,
      surname: item.surname,
      userDepartment: !!item.userDepartment ? new UserDepartmentAdapter().adapt(item.userDepartment) : null,
      id: item.id,
      userTeam: !!item.userTeam ? new UserTeamAdapter().adapt(item.userTeam) : null,
    });
  }

  adaptFromDropdownDictionary(item: DropdownDictionary): UserTeamDto {
    return new UserTeamDto({
      active: true,
      firstName: item.name,
      fullNameAndInitialt: item.name,
      id: item.id,
      fullName: item.name,
      initials: item.id,
      userTeam: {
        teamName: item.name,
        teamNameAlt: null,
        id: item.id,
        adtCode: item.id,
        caseHandler: null,
        defaultChannel: ''
      },
      isTeam: true,
    });
  }
}
