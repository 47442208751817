import { PreDepartureTaskViewModel } from "./pre-departure-task-view.model";

export class PreDepartureTaskFilterResponse {
  totalNumber: number;
  taskViewModels: PreDepartureTaskViewModel[];

  public constructor(init?: Partial<PreDepartureTaskFilterResponse>) {
    Object.assign(this, init);
  }
}
