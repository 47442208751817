import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { SettingsService } from './settings.service';
import { BaseService } from './base.service';
import { CaseSummary } from '../../shared/models/caseSummary';
import { CaseSummaryAdapter } from '@secca/shared/models/caseSummary';
import { CaseSummaryFilter } from '../../shared/models/caseSummaryFilter';
import { UserDto, UserDtoAdapter } from '../../shared/models/userDto';
import { CaseHistoryEntry, CaseHistoryEntryAdapter } from '@secca/shared/models/caseHistoryEntry';

@Injectable({
  providedIn: 'root',
})
export class CaseSummaryService extends BaseService {
  map: any = '';
  private caseSummary = new BehaviorSubject<CaseSummary>(undefined);
  private caseHistory = new BehaviorSubject<CaseSummary>(undefined);

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private userDtoAdapter: UserDtoAdapter,
    private caseHistoryDtoAdapter: CaseHistoryEntryAdapter,
    private caseSummaryAdapter: CaseSummaryAdapter
  ) {
    super(settingsService);
  }

  public setCaseSummarySubject(caseSummary: CaseSummary) {
    this.caseSummary.next(caseSummary);
  }

  public getCaseSummarySubject(): Observable<CaseSummary> {
    return this.caseSummary.asObservable();
  }

  public setCaseHistorySubject(caseSummary: CaseSummary) {
    this.caseHistory.next(caseSummary);
  }

  public getCaseHistorySubject(): Observable<CaseSummary> {
    return this.caseHistory.asObservable();
  }

  public getCaseHistory(caseId: string, filter: CaseSummaryFilter): Observable<CaseSummary> {
    return this.http
      .get<CaseSummary>(this.baseURL + `casehistory/history-decorated/` + caseId, {
        params: filter.generateHttpParamsForHistory(),
        headers: this.jsonHeaders,
      })
      .pipe(map(item => this.caseSummaryAdapter.adapt(item)));
    }

  public getCaseSummary(caseId: string, filter: CaseSummaryFilter): Observable<CaseSummary> {
    return this.http
      .get<CaseSummary>(this.baseURL + `casehistory/summary-decorated/` + caseId, {
        params: filter.generateHttpParamsForSummary(),
        headers: this.jsonHeaders,
      })
      .pipe(map(item => this.caseSummaryAdapter.adapt(item)));
    }

  public getCaseSummaryAuthors(caseId: string): Observable<UserDto[]> {
    return this.http
      .get<UserDto[]>(this.baseURL + `bff/casehistory/dictionary/authors/` + caseId, { headers: this.jsonHeaders })
      .pipe(map((data: any[]) => data.map(item => this.userDtoAdapter.adapt(item))));
  }

  public getLastActionInCaseSummary(caseId: string): Observable<CaseHistoryEntry> {
    return this.http
      .get<CaseHistoryEntry>(this.baseURL + 'casehistory/summary/last-action/' + caseId)
      .pipe(map(item => this.caseHistoryDtoAdapter.adapt(item)));
  }
}
