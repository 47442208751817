<div class="common-drop-down-arrow drop-down">
  <span class="name">{{ name }}</span>
  <ng-select
    [disabled]="disabled"
    [clearable]="false"
    [(ngModel)]="selectedItemId"
    class="custom"
    (change)="onSelectedItemIdChange()"
    [items]="users"
    bindValue="id"
    [ngClass]="{ 'required-red-background': isRequired && requiredOverrule }"
  >
    <ng-template ng-option-tmp let-item="item" let-index="index">
      <img [src]="item.icon" />
      <span *ngIf="!item.isCompany">
        {{ item.firstName }} {{ item.surname }}
        <span *ngIf="item.treatingDoctor"> ({{ item.companyName }}) </span>
        <span *ngIf="item.roleName"> ({{ item.roleName }})</span>
      </span>
      <span *ngIf="!!item.isCompany">{{ item.companyName }}</span>
    </ng-template>

    <ng-template ng-label-tmp let-item="item">
      <img [src]="item.icon" />
      <span *ngIf="!item.isCompany">
        {{ item.firstName }} {{ item.surname }}
        <span *ngIf="item.treatingDoctor"> ({{ item.companyName }}) </span>
        <span *ngIf="item.roleName"> ({{ item.roleName }})</span>
      </span>
      <span *ngIf="!!item.isCompany">{{ item.companyName }}</span>
    </ng-template>
  </ng-select>
</div>
