<div class="cardsContainer">
  <div *ngIf="endUser">
    <div class="cardHeader"><img src="/assets/icons/End-User.svg" /> {{ 'case-basics-info-end-user' | translate }}</div>
    <div class="cardBody">
      <h1>{{ endUser.person.firstName }} {{ endUser.person.surname }}</h1>
      <h2> {{ endUser.person.age }}<span *ngIf="endUser.person.gender !== null">, {{ getGenderTypes(endUser.person.gender) }}</span></h2>
      <div class="infoLines">
        <div class="float-start">{{ 'case-basics-info-national-id' | translate }}</div>
        <div class="float-end"> <img *ngIf="showEye" src='./../../../../../../../assets/icons/eye.svg' (click)=showNationalIdClick()>
          {{ theShownNationalId }}</div>
      </div>
      <div class="infoLines">
        <div class="float-start">{{ 'case-basics-info-date-of-birth' | translate }}</div>
        <div class="float-end">{{ endUser.person.dateOfBirth | date:'dd MMM yyyy' }}</div>
      </div>
      <div class="infoLines">
        <div class="float-start">{{ 'case-basics-info-home-country' | translate }}</div>
        <div class="float-end">{{ endUser.address.country.name }}</div>
      </div>
    </div>
  </div>

  <div *ngIf="caseIncident">
    <div class="cardHeader"><img src="/assets/icons/incident.png" /> {{ 'case-basics-incident' | translate }}</div>
    <div class="cardBody">
      <h1>{{ incidentLevel }}</h1>
      <h2> {{ caseIncident.description }}</h2>
      <div class="infoLines">
        <div class="float-start">{{ 'case-basics-incident-incident-date' | translate }}</div>
        <div class="float-end">{{ caseIncident.incidentDate | date:'dd MMM yyyy' }}</div>
      </div>
      <div class="infoLines">
        <div class="float-start">{{ 'case-basics-incident-first-medical-visit' | translate }}</div>
        <div class="float-end">{{ caseIncident.firstMedicalVisit | date:'dd MMM yyyy' }}</div>
      </div>
      <div class="infoLines">
        <div class="float-start">{{ 'case-basics-incident-incident-location' | translate }}</div>
        <div class="float-end break-word maxwidth text-end">{{ caseIncident.incidentLocation.freeText }}</div>
      </div>

      <div class="infoLines">
        <div class="float-start">{{ 'case-basics-incident-current-location' | translate }}</div>
        <div class="float-end break-word maxwidth text-end">{{ caseIncident.currentLocation.freeText }}</div>
      </div>
    </div>
  </div>
  <div *ngIf="plannedTravel">
    <div class="cardHeader"><img src="/assets/icons/travel.png" /> {{ 'case-basics-planned-travel' | translate }}</div>
    <div class="cardBody">
      <h1>{{ plannedTravel.destinationLocation.freeText }}</h1>
      <h2 *ngIf="calculatedRemainingDays !== ''">{{ calculatedRemainingDays }} days left</h2>
      <div class="infoLines">
        <div class="float-start">{{ 'case-basics-planned-travel-dateOfDeparture' | translate }} </div>
        <div class="float-end ">{{ plannedTravel.departureDate | date:'dd MMM yyyy' }}</div>
      </div>
      <div class="infoLines">
        <div class="float-start">{{ 'case-basics-planned-travel-dateOfArrivalInHomeCountry' | translate }}</div>
        <div class="float-end">{{ plannedTravel.arrivalDate | date:'dd MMM yyyy' }}</div>
      </div>
      <div class="infoLines">
        <div class="float-start">{{ 'case-basics-planned-travel-meansOfTransportation' | translate }}</div>
        <div class="float-end"><ng-container *ngIf="plannedTravel.meansOfTransportation">{{ 'planned-travel-means-of-transportation-' + plannedTravel.meansOfTransportation.toLowerCase().replace('_','-') | translate   }}</ng-container></div>
      </div>
      <div class="infoLines">
        <div class="float-start">{{ 'case-basics-planned-travel-numberOfCoTravelers' | translate }}</div>
        <div class="float-end">{{ plannedTravel.coTravellers }}</div>
      </div>
      <div class="infoLines" >
        <div class="float-start">{{ 'case-basics-planned-travel-accommodation' | translate }}</div>
        <div class="float-end"> <ng-container *ngIf="plannedTravel.accommodation"> {{ 'planned-travel-accommodation-' + plannedTravel.accommodation.toLowerCase().replace('_','-') | translate   }} </ng-container> </div>
      </div>
    </div>
  </div>
</div>
