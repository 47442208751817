<div appDialogSelectable appDialogKeyboardEnabled [appDialogMinimizable]="MinimizableDialogType.MedicalPreAssessment">
    <mat-dialog-content (cdkDragStarted)="dialogBoundryService.handleDragStart($event)" (cdkDragEnded)="dialogBoundryService.handleDragEnd($event)" cdkDragRootElement=".cdk-overlay-pane" cdkDrag>
      <div appDialogDragArea cdkDragHandle></div>
      <app-medical-pre-assessment appDialogModal
                              [case]="data.case"
                              [medicalPreAssessmentId]="data.medicalPreAssessmentId"
                              [disabledViewOfMedicalPreAssessment]="data.disabledViewOfMedicalAssessment"
                              (updateDraftButton)="data.updateDraftButtonEvent ? data.updateDraftButtonEvent() : null"
                              (closed)="close()"></app-medical-pre-assessment>
    </mat-dialog-content>
  </div>
