<div class="card">

  <div class="sub-component-placeholder">
    <app-sub-commitment-type [(commitmentType)]="otherRefundRequest.commitmentType"
                             [readOnlyMode]="true"
                             [serviceOrderType]="serviceOrderType"
                             [status]="status"
                             [caseId]="caseId">
    </app-sub-commitment-type>
  </div>

  <div class="sub-component-placeholder">
    <app-duration [(duration)]="otherRefundRequest.duration"
                  [hideGOPDates]="true"
                  [hideRoomAndRemark]="true"
                  [deleteable]="false"
                  [hideLocation]="true"
                  [seccaTimeZone]="'secca'"
                  [calculateDefaultDates]="setDefaultDates"
                  [defaultStartDate]="defaultStartDate"
                  [defaultEndDate]="defaultEndDate">
    </app-duration>
  </div>

  <div class="sub-component-placeholder bottom">
    <app-sub-remarks-refund [readOnlyMode]="false"
                            [subRemarksRefund]="otherRefundRequest.remarksRefund"></app-sub-remarks-refund>
  </div>
</div>
