import {Component, Inject, ViewChild} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {DialogBoundryService} from '@secca/core/services/dialog-boundry.service';
import {DialogMinimizableDirective} from '@secca/shared/directives/dialog-minimizable.directive';
import {MinimizableDialogType} from '@secca/shared/enums/minimizable-dialog-type-enum';
import {IAdditionalInfo, TaskAdditionalInfo} from '@secca/shared/interfaces/additional-info';
import {IMinimizable} from '@secca/shared/interfaces/minimizable';
import {TaskWebDialogData} from '@secca/board/components/board-task/board-task-incoming-cases-table/task-web-dialog/task-web-dialog-data.component';
import {TaskWebModalComponent} from '@secca/board/components/board-task/board-task-incoming-cases-table/task-web-modal/task-web-modal.component';
import { DialogHelperUtilService } from '@secca/core/services/dialog-helper-util.service';
import { IKeyboardEnabled } from '@secca/shared/interfaces/keyboard-enabled';
import { ShortcutService } from '@secca/core/services/shortcut.service';
import { DialogKeyboardEnabledDirective } from '@secca/shared/directives/dialog-keyboard-enabled.directive';

@Component({
  selector: 'app-task-web-dialog',
  templateUrl: './task-web-dialog.component.html',
  styleUrls: ['./task-web-dialog.component.scss'],
})
export class TaskWebDialogComponent implements IAdditionalInfo, IMinimizable, IKeyboardEnabled {
  @ViewChild(TaskWebModalComponent) wrappedInstance: TaskWebModalComponent;
  @ViewChild(DialogMinimizableDirective) minimizedDirective: DialogMinimizableDirective;

  data: TaskWebDialogData;

  constructor(private dialogRef: MatDialogRef<TaskWebDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data: TaskWebDialogData,
              private shortcutService: ShortcutService,
              private dialogHelperUtilService: DialogHelperUtilService,
              public dialogBoundryService: DialogBoundryService) {

    this.data = data;

    if ( this.data.disableShortcuts ) {
      this.shortcutService.disableShortCuts();
    }
  }

  close() {
    if ( this.data.disableShortcuts ) {
      this.shortcutService.enableShortCuts();
    }

    this.dialogHelperUtilService.close(this.dialogRef, this.getAdditionalInfo()?.caseId);
  }

  keyboardClose(): void {
    this.wrappedInstance.close();
  }

  minimize() {
    this.minimizedDirective.minimize();
  }

  getMinimizeType(): MinimizableDialogType {
    return this.minimizedDirective.appDialogMinimizable;
  }

  getAdditionalInfo(): TaskAdditionalInfo {
    const taskTitle: string = this.wrappedInstance?.taskViewModel?.title;
    const taskType = this.wrappedInstance?.taskViewModel?.taskType;
    const taskStatus = this.wrappedInstance?.taskViewModel?.status;
    const caseId = this.wrappedInstance?.taskViewModel?.caseId;
    const caseNumber = this.wrappedInstance?.taskViewModel?.caseNumber;

    return {
      caseId,
      hoverTitle: caseNumber || '',
      hoverText: taskTitle || '',
      taskType,
      taskStatus
    };
  }

  get enabledKeys(): string[] {
    return this.data.disableShortcuts ? [DialogKeyboardEnabledDirective.ESCAPE_KEY] : null;
  }

  get MinimizableDialogType() {
    return MinimizableDialogType;
  }

  get hideSearchCreateButton(): boolean {
    return this.wrappedInstance?.hideSearchCreateButton || this.wrappedInstance?.taskDataViewModel?.customerProfileLegacy
  }
}
