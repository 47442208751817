<div class="common-drop-down-arrow global-board-dropdown-height">
    <span class="name">
      {{name}}
    </span>
<ng-select
  #selectCause
  dropdownPosition="auto"
  (blur)="onSaveModel()"
  (focus)="onSaveState()"
  [clearable]="false"
  [(ngModel)]="selectedItemId"
  [ngClass]="'custom'"
  [multiple]="true"
  (change)="onChange()"
  (focus)="onOpen()"
  (close)="close()"
>
<ng-option *ngFor="let item of items" [value]="item.id" [disabled]="item.disabled">
  {{ item.name }}
</ng-option>
<ng-template ng-multi-label-tmp>
  <div class="multi-selected-label">
     {{ displayMultiLabel() }}
    </div>
</ng-template>
<ng-template ng-label-tmp let-item="item" >
</ng-template>
</ng-select>
</div>
