import { Component, ElementRef, Renderer2, ViewEncapsulation } from "@angular/core";
import { Event, NavigationEnd, NavigationError, NavigationStart, Router } from "@angular/router";
import { CaseLockHelperService } from "@secca/core/services/case-lock-helper.service";
import { CaseService } from "@secca/core/services/case.service";
import { DataService } from "@secca/core/services/data.service";
import { DialogMinimizedData, DialogMinimizedStorageService } from "@secca/core/services/dialog-minimized-storage.service";
import { ShortcutService } from "@secca/core/services/shortcut.service";
import { TopTabsService } from "@secca/core/services/top-tabs.service";
import { CaseStateService } from "@secca/core/state-services/case-state.service";
import { DialogStateService } from "@secca/core/state-services/dialog-state.service";
import { AutoUnsubscribe } from "@secca/shared/decorators/auto-unsubscribe";
import { CaseHistoryType } from "@secca/shared/enums/case-history-type";
import { LockContextEnum } from "@secca/shared/enums/lock-context.enum";
import { MinimizableDialogType } from "@secca/shared/enums/minimizable-dialog-type-enum";
import {
  AdditionalInfo,
  CaseDocumentAdditionalInfo,
  CaseSummaryEmailFaxAdditionalInfo,
  ServicePlanAdditionalInfo,
  SupplierAdditionalInfo,
  TaskAdditionalInfo
} from '@secca/shared/interfaces/additional-info';
import { ServiceTypeEnumIcon, ShortcutEnum, TaskTypeEnumIconSmall } from "@secca/shared/models/enums";
import { Subscription } from "rxjs";
import { add } from 'lodash';

@Component({
  selector: 'app-minimized',
  templateUrl: './case-minimized.component.html',
  styleUrls: ['./case-minimized.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@AutoUnsubscribe
export class CaseMinimizedComponent {
  private caseId: string;
  private caseStateSubscr: Subscription;
  private shortcutSubscription1: Subscription;
  private shortcutSubscription2: Subscription;

  navigationActive: boolean;
  loading: boolean;

  constructor(public dialogMinimizedStorageService: DialogMinimizedStorageService,
              private dialogStateService: DialogStateService,
              private caseStateService: CaseStateService,
              private dataService: DataService,
              private topTabsService: TopTabsService,
              private caseService: CaseService,
              private caseLockHelperService: CaseLockHelperService,
              private shortcutService: ShortcutService,
              private router: Router,
              private elmRef: ElementRef,
              private renderer2: Renderer2) {
    this.caseStateSubscr = caseStateService.getCase().subscribe( seccaCase => {
      if ( seccaCase ) {
        this.caseId = seccaCase.id;
      }
    });

    this.dataService.getIsCaseLoading().subscribe(loading => {
      this.loading = loading;
    })

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
          this.navigationActive = true;
      }

      if (event instanceof NavigationEnd) {
          this.navigationActive = false;
      }

      if (event instanceof NavigationError) {
          this.navigationActive = false;
      }
    });

    this.shortcutSubscription1 = this.shortcutService.addShortcut({ keys: ShortcutEnum.ModalsMinimizedContainerFocus }).subscribe(keyEvnt => {
      const firstMinimizedElement = this.elmRef.nativeElement.querySelector('.minimized-elem > div > div:first-child');
      if ( firstMinimizedElement ) {
        firstMinimizedElement.focus();
      }
    });

    this.shortcutSubscription2 = this.shortcutService.addShortcut({ keys: 'enter', preventDefault: false }).subscribe(keyEvnt => {
      const activeDocument = document.activeElement;
      if ( activeDocument?.classList?.contains('box') ) {
        (activeDocument as any).click();
      }
    });
  }

  maximize(dialogId: string): void {
    if ( this.navigationActive ) {
      return;
    }

    const dialogMinimizedData:DialogMinimizedData = this.dialogMinimizedStorageService.getMinimizedFromDialogId(dialogId);
    const hasOpenCaseTab = this.topTabsService.tabWithCaseIdExists(+dialogMinimizedData.caseId);

    if ( hasOpenCaseTab ) {
      if ( dialogMinimizedData.caseId && (+this.caseId !== +dialogMinimizedData.caseId || this.boardActive )) {
        this.router.navigate(['case', dialogMinimizedData.caseId]).then(success => {
          if ( success ) {
            this.dialogStateService.maximize(dialogId);
          }
        });
      }
      else {
        this.dialogStateService.maximize(dialogId);
      }
    }
    else if ( dialogMinimizedData.isGlobalBoardDialog && dialogMinimizedData.caseId ) {
      this.router.navigate(['board']).then(success => {
        if ( success ) {
          this.takeCaseLock(`${dialogMinimizedData.caseId}`);
          this.dialogStateService.maximize(dialogId);
        }
      });
    }
    else {
      this.dialogStateService.maximize(dialogId);
    }
  }

  get minimizedList(): DialogMinimizedData[] {
    return this.dialogMinimizedStorageService.getMinimizedList();
  }

  getTaskImageIcon(additionalInfo: TaskAdditionalInfo): any {
    const taskType = additionalInfo?.taskType;
    const taskStatus = additionalInfo?.taskStatus;
    const taskImageIcon = taskType ? TaskTypeEnumIconSmall.getSmallIcon(taskType, taskStatus) : '/assets/icons/question-mark.svg';

    return { backgroundImage: `url('${taskImageIcon}')` };
  }

  getServicePlanImageIcon(additionalInfo: ServicePlanAdditionalInfo): any {
    const serviceOrderType = additionalInfo?.servicePlanType;
    const serviceImageIcon = serviceOrderType ? ServiceTypeEnumIcon.getIcon(serviceOrderType) : 'question-mark.svg';
    return { backgroundImage: `url('/assets/icons/${serviceImageIcon}')` };
  }

  getCaseDocumentImageIcon(additionalInfo: CaseDocumentAdditionalInfo): any {
    const documentIcon = additionalInfo.isOutgoing ? 'DocOutgoing-Small.svg' : 'DocIncoming-Small.svg';
    return { backgroundImage: `url('/assets/icons/${documentIcon}')` };
  }

  getCaseSummaryEmailFaxImageIcon(additionalInfo: CaseSummaryEmailFaxAdditionalInfo): any {
    switch ( additionalInfo.caseHistoryType ) {
      case CaseHistoryType.EMAIL:
      case CaseHistoryType.EMAIL_RECEIVE:
      case CaseHistoryType.EMAIL_UPDATE:
        return { backgroundImage: `url('/assets/icons/output-management/Email White.svg')` };

      default:
        return { backgroundImage: `url('/assets/icons/output-management/Fax White.svg')` };
    }
  }

  getSupplierSearchImageIcon(additionalInfo: SupplierAdditionalInfo): any {
    return {backgroundImage: `url('/assets/icons/SearchLargeWhite.svg')`}
  }

  getSupplierSearchInformationIcon(additionalInfo: SupplierAdditionalInfo): any {
    return {backgroundImage: `url('/assets/icons/Info.svg')`}
  }

  getTitle(additionalInfo: AdditionalInfo): string {
    return additionalInfo.hoverTitle;
  }

  getHoverText(additionalInfo: AdditionalInfo): string {
    return additionalInfo.hoverText;
  }

  get MinimizableDialogType() {
    return MinimizableDialogType;
  }

  get boardActive(): boolean {
    return this.router.url === '/board';
  }

  private takeCaseLock(caseId: string): void {

    this.caseService.getCase(caseId).subscribe(seccaCase => {
      this.caseStateService.sendCase(seccaCase);

      // Update caselocks to determine if loggedin user has already taken the lock
      this.caseLockHelperService.updateCaseLocks(seccaCase.id, seccaCase.caseLocks);

      const isCaseAlreadyLockedByTheLoggedInUser = this.caseLockHelperService.isCaseLockedByTheLoggedInUser(LockContextEnum.CASE_BASIC);
      if (!isCaseAlreadyLockedByTheLoggedInUser && !this.caseLockHelperService.isCaseBasicLockTaken) {
        // If not already locked by the loggedin user or others, we try to take the lock
        this.caseLockHelperService.lockCase(LockContextEnum.CASE_BASIC).subscribe();
      }
    });
  }
}
