
<div class="hover-container-content-section-title">
    Supplier
  </div>
  <div class="hover-container-content-bold">
    {{providerName}}
  </div>
  <div class="hover-container-content-section-title"  *ngIf="agentName">
    Via
  </div>
  <div class="hover-container-content-bold" *ngIf="agentName">
    {{agentName}}
  </div>

  <hr>
<div class="hover-container-content-section-title">
  Start
</div>
<div class="hover-container-content-bold">
  <span>{{ serviceOrder.getStartDisplayDate() ? (serviceOrder.getStartDisplayDate() | momentFormat: 'DD MMM YYYY HH:mm':getStartTimeZone()) : '-- : --' }}</span>
</div>
<hr>
<div class="hover-container-content-section-title">
    Category
</div>
<div class="hover-container-content-bold">
    {{ getCategory() }}
</div>
<hr>

<div class="hover-container-content-section-title">
    Event
</div>
<div class="hover-container-content-bold">
    {{ incidentEventName }}
</div>

<hr>
<div class="hover-container-content-section-title">
  Remarks
</div>
<div class="hover-container-content-bold">
  {{serviceOrder.remarks}}
</div>

<hr>
<div class="hover-container-content-section-title">
  Commitment Type
</div>
<div class="hover-container-content-bold">
  <span *ngIf="commitmentType">{{ commitmentType.name }}</span>
</div>

<hr>
<div class="hover-container-content-section-title">
    Status Type
</div>
<div class="hover-container-content-bold">
  <span >{{ statusType| translate }}</span>
</div>

<hr>
<div class="hover-container-content-section-title">
    Last edited
</div>
<div class="hover-container-content-bold">
    <span *ngIf="serviceOrder.lastEditedByInitials">
        {{serviceOrder.lastEditedByInitials | uppercase}}
    </span>
<span *ngIf="serviceOrder.modifiedOn">
        {{serviceOrder.modifiedOn | momentFormat:'DD MMM YYYY HH:mm':'local'}}
    </span>
</div>
