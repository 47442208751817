<div class="input-location-container">
    <div [ngClass]="validationClass">
      <span *ngIf="withLabel" class="input-name">{{name}}</span>
      <div class="input-container">
        <img src="../../../../../../assets/icons/Magnifier.svg" class="input-image" alt="">
        <input placeholder="" autocorrect="off" autocapitalize="off" spellcheck="false" type="text" #searchElement
              class="input-component"
              [formControl]="searchLocation" (blur)="onBlur()" [(ngModel)]="value.freeText" [class.recommended]="recommended" [attr.disabled]="disabled ? disabled : null">
      </div>
    </div>
</div>
