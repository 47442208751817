 <div class="logged-call-container common-drop-down-arrow">
   <span class="name" [ngClass]="validate && (callType === undefined || callType == null) && 'validation-error'">Logged call</span>
   <div class="result-select-container" (click)="chosenLoggedCallValueClick()">
     <div *ngIf="(selectedPhoneCall === undefined || selectedPhoneCall == null) && !lastPhoneCallConnectionId">
       <div *ngIf="callType === CallType.ingoing">
         <img src="../../../../../../assets/icons/Ingoing.svg">&nbsp;&nbsp;Manual phone note
       </div>
       <div *ngIf="callType === CallType.outgoing">
         <img src="../../../../../../assets/icons/Outgoing.svg">&nbsp;&nbsp;Manual phone note
       </div>
     </div>
   </div>

   <ng-select #loggedCallDropDown [clearable]="false" class="logged-call-drop-down" [items]="items" (click)="regularNumberClick()"
     [ngClass]="validate && (callType === undefined || callType == null) && 'validation-error'" [(ngModel)]="selectedPhoneCall"
              (focus)="this.open.emit()" (change)="onChange()" [disabled]="disabled">

     <ng-template ng-option-tmp ng-label-tmp let-item="item">
       <div class="row phone-call">
         <div class="col-2">
           <div *ngIf="item.callType === CallType.ingoing">
             <img src="../../../../../../assets/icons/Ingoing.svg">
           </div>
           <div *ngIf="item.callType === CallType.outgoing">
             <img src="../../../../../../assets/icons/Outgoing.svg">
           </div>
         </div>
         <div class="col-7 cell">{{item.phoneNumber}}</div>
         <div class="col-3 cell">{{item.callStartTime | momentFormat: "HH:mm" : 'local'}}</div>
       </div>
     </ng-template>

     <ng-template ng-notfound-tmp>
       <div class="ng-option disabled" style="display:none">
         No data found for
       </div>
     </ng-template>

     <ng-template ng-footer-tmp>
       <div class="row create-manually-footer">
         <div class="col-8 create-manually-label ">
           Create manually
         </div>
         <div class="col-2 call-cell" (click)="incomingCallClick()">
           <img src="../../../../../../assets/icons/Ingoing.svg">
         </div>
         <div class="col-2 call-cell" (click)="outgoingCallClick()">
           <img src="../../../../../../assets/icons/Outgoing.svg">
         </div>
       </div>
     </ng-template>
   </ng-select>
   </div>
