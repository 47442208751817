import { Injectable } from "@angular/core";
import { DateHelperService } from "@secca/core/services/date-helper.service";
import { Adapter } from "@secca/shared/interfaces/adapter";
import { AssessmentTypeEnum, RepatriationFormEnum } from "@secca/shared/models/enums";



export class BoardTransportCase {
    id: number;
    caseNumber: string;
    endUserFullName: string;
    customerProfileId: number;
    caseLockedBy: string;
    customerProfileName: string;
    caseTypeCode: string;
    currentLocationCountry: string;
    currentLocationAdmArea: string;
    currentLocationLocality: string;
    currentLocationAlpha2: string;
    homeCity: string;
    readyFrom: Date;
    arrivalDeadline: Date;
    caseDetailsAIR: boolean;
    caseDetailsSTR: boolean;
    caseDetailsEXT: boolean;
    caseDetailsSIT: boolean;
    caseDetailsREG: boolean;
    caseDetailsDOC: boolean;
    caseDetailsNUR: boolean;
    caseDetailsNME: boolean;
    caseDetailsOXY: boolean;
    caseDetailsEQU: boolean;
    firstRepatriationDate: Date;
    firstMedicalAssessmentCreated: Date;
    firstTransportServiceOrderFlightLegDate: Date
    expectedRepatriationForm: RepatriationFormEnum;
    coTransported: boolean;
    relatedICCCaseNumber: string;
    medicalAssessmentPublished: Date;
    relatedToICCCase: boolean;
    relatedToMajorIncidentCase: boolean
    medicalServiceorderType: AssessmentTypeEnum;

    public constructor(init?: Partial<BoardTransportCase>) {
        Object.assign(this, init);
      }
}


@Injectable({
    providedIn: 'root'
  })
  export class BoardTransportCaseAdapter implements Adapter<BoardTransportCase> {
    constructor(private dateHelperService: DateHelperService) {}

    adapt(item: any): BoardTransportCase {
      return new BoardTransportCase({
        id: item.id,
        caseNumber: item.caseNumber,
        endUserFullName: item.endUserFullName,
        customerProfileId: item.customerProfileId,
        caseLockedBy: item.caseLockedBy,
        customerProfileName: item.customerProfileName,
        caseTypeCode: item.caseTypeCode,
        currentLocationCountry: item.currentLocationCountry,
        currentLocationAdmArea: item.currentLocationAdmArea,
        currentLocationLocality: item.currentLocationLocality,
        currentLocationAlpha2: item.currentLocationAlpha2,
        homeCity: item.homeCity,
        relatedToICCCase: item.relatedToICCCase ? item.relatedToICCCase : false,
        relatedToMajorIncidentCase: item.relatedToMajorIncidentCase ? item.relatedToMajorIncidentCase : false,
        caseDetailsAIR: item.caseDetailsAIR,
        caseDetailsSTR: item.caseDetailsSTR,
        caseDetailsEXT: item.caseDetailsEXT,
        caseDetailsSIT: item.caseDetailsSIT,
        caseDetailsREG: item.caseDetailsREG,
        caseDetailsDOC: item.caseDetailsDOC,
        caseDetailsNUR: item.caseDetailsNUR,
        caseDetailsNME: item.caseDetailsNME,
        caseDetailsOXY: item.caseDetailsOXY,
        caseDetailsEQU: item.caseDetailsEQU,
        readyFrom: this.dateHelperService.convertFromUTCDateTimeToLocalDateTime(item.readyFrom),
        arrivalDeadline: this.dateHelperService.convertFromUTCDateTimeToLocalDateTime(item.arrivalDeadline),
        firstRepatriationDate: this.dateHelperService.convertFromUTCDateTimeToLocalDateTime(item.firstRepatriationDate),
        medicalAssessmentPublished: this.dateHelperService.convertFromUTCDateTimeToLocalDateTime(item.medicalAssessmentPublished),
        firstTransportServiceOrderFlightLegDate: this.dateHelperService.convertFromUTCDateTimeToLocalDateTime(item.firstTransportServiceOrderFlightLegDate),
        expectedRepatriationForm: item.expectedRepatriationForm,
        coTransported: item.coTransported,
        relatedICCCaseNumber: item.relatedICCCaseNumber,
        medicalServiceorderType: item.medicalServiceorderType,
      });
    }
  }