import { ProfileCountryList, ProfileCountryListAdapter } from '@secca/shared/models/ProfileCountryList';
import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from './settings.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UpdateScreeningToolService extends BaseService {

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private profileCountryListAdapter: ProfileCountryListAdapter,
  ) {
    super(settingsService);
  }

  public getCountryList(id: string): Observable<ProfileCountryList> {
    return this.http
      .get(this.baseURL + 'profile/country-list/' + id)
      .pipe(map( item => this.profileCountryListAdapter.adapt(item)));
  }
}
