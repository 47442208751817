import { Injectable } from '@angular/core';
import { Adapter } from './../interfaces/adapter';

export class User {
  id: string;
  caseId: string;
  nationalIdCountryCode: string;
  nationalIdNumber: string;
  dateOfBirth: Date;
  age: number;
  sex: string;
  firstName: string;
  nip: boolean;
  surname: string;
  homeCountryCode: string;
  mobilePhoneCountryCode: string;
  mobilePhone: string;
  email: string;
  fixedPhoneCountryCode: string;
  fixedPhone: string;
  remarks: string;
  reporter: boolean;
  policyHolder: boolean;
  primaryContact: boolean;
  guardian: boolean;
  concent: boolean;
  endUser: boolean;
  coTraveller: boolean;
  company: boolean;
  address: string;
  postalCode: string;
  city: string;
  type: string;
  roles: string[];
  periodFrom: Date;
  periodTo: Date;
  preferredChannel: string;
  preferredLanguage: string;
  initials: string;

  public constructor(init?: Partial<User>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class UserAdapter implements Adapter<User> {


  adapt(item: any): User {
    return new User({
      id: item.id,
      caseId: item.caseId,
      nationalIdNumber: item.nationalIdNumber,
      dateOfBirth: new Date(item.dateOfBirth),
      age: item.age,
      sex: item.sex,
      firstName: item.firstName,
      nip: item.nip,
      surname: item.surname,
      homeCountryCode: item.homeCountryCode,
      mobilePhoneCountryCode: item.mobilePhoneCountryCode,
      mobilePhone: item.mobilePhone,
      email: item.email,
      fixedPhoneCountryCode: item.fixedPhoneCountryCode,
      fixedPhone: item.fixedPhone,
      remarks: item.remarks,
      reporter: item.reporter,
      policyHolder: item.policyHolder,
      primaryContact: item.primaryContact,
      guardian: item.guardian,
      concent: item.concent,
      endUser: item.endUser,
      coTraveller: item.coTraveller,
      company: item.company,
      address: item.address,
      postalCode: item.postalCode,
      city: item.city,
      type: item.type,
      roles: item.roles,
      periodFrom: new Date(item.periodFrom),
      periodTo: new Date(item.periodTo),
      preferredChannel: item.preferredChannel,
      preferredLanguage: item.preferredLanguage,
      nationalIdCountryCode: item.nationalIdCountryCode,
      initials: item.initials
    });
  }
}
