<div class="common-drop-down-arrow drop-down">
  <span class="name">{{ name }}</span>
  <span *ngIf="required" class="required-red">*</span>
  <ng-select
    #selectCause
    [dropdownPosition]="dropdownPosition"
    (blur)="onSaveModel(); selectCause.close()"
    (focus)="onSaveState()"
    (close)="onClose()"
    [clearable]="false"
    [(ngModel)]="selectedItemId"
    [ngClass]="{ noborder: noBorderAndArrow, custom: !noBorderAndArrow, 'recommended-yellow-background': recommended }"
    [class.common-drop-down-arrow-highlight-border]="highlightBorderOnInputNonEmpty && !!selectedItemId && !disabled"
    [disabled]="!!disabled"
    [multiple]="multiple"
    [closeOnSelect]="closeOnSelect"
    [placeholder]="placeholder"
    [appendTo]="appendTo"
    *ngIf="translatePipePath !== undefined"
    (change)="onChange()"
  >
    <ng-option *ngFor="let item of items" [value]="item.id" [disabled]="item.disabled">
      {{ translatePipePath + item.name | translate }}</ng-option>
    <ng-template ng-multi-label-tmp *ngIf="multiSelectedLabel !== undefined">
      <div class="multi-selected-label">{{ translatePipePath + multiSelectedLabel | translate: { number: selectedItemId.length } }}</div>
    </ng-template>
    <ng-template ng-label-tmp let-item="item" *ngIf="displayMissingLabel()">
      {{ missingItemLabel }}
    </ng-template>
  </ng-select>

  <ng-select
    #selectCause2
    (blur)="onSaveModel(); selectCause2.close()"
    (focus)="onSaveState()"
    (close)="onClose()"
    [clearable]="false"
    [dropdownPosition]="dropdownPosition"
    [(ngModel)]="selectedItemId"
    [ngClass]="{ noborder: noBorderAndArrow, custom: !noBorderAndArrow }"
    [class.common-drop-down-arrow-highlight-border]="highlightBorderOnInputNonEmpty && !!selectedItemId && !disabled"
    [disabled]="!!disabled"
    [multiple]="multiple"
    [closeOnSelect]="closeOnSelect"
    [placeholder]="placeholder"
    [items]="items"
    [appendTo]="appendTo"
    bindLabel="name"
    bindValue="id"
    *ngIf="translatePipePath === undefined"
    (change)="onChange()"
    [class.required-red-background]="requiredRedBackground && !this.selectedItemId"
    [class.required-red-label]="requiredRedLabel"
    [class.recommended-yellow-background]="recommended"
  >
    <ng-template ng-option-tmp let-item="item">
      {{ item.name }}
    </ng-template>
    <ng-template ng-label-tmp let-item="item" *ngIf="!displayMissingLabel()">
      {{ item.name }}
    </ng-template>
    <ng-template ng-multi-label-tmp *ngIf="multiSelectedLabel !== undefined">
      <div class="multi-selected-label">{{ multiSelectedLabel }}</div>
    </ng-template>
    <ng-template ng-label-tmp let-item="item" *ngIf="displayMissingLabel()">
      {{ missingItemLabel }}
    </ng-template>
  </ng-select>
</div>
