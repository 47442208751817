<div class="common-drop-down-arrow drop-down">
  <ng-select *ngIf="!displayOnly"
    class="custom" [ngClass]="{ 'recommended-yellow-background': recommended, 'required-red-background': disabledSelected() }"
    [(ngModel)]="selectedItemId" (change)="onChange()" (blur)="onSaveModel()" (focus)="onSaveState()"
    (close)="onClose()" [items]="items" bindValue="serviceOrderId" bindLabel="serviceOrderType" [clearable]="false"
    [disabled]="!!disabled">
    <ng-template ng-label-tmp let-item="item">
      <div *ngIf="!!item.serviceOrderType">
        <img [src]="item.iconFileName" class="service-order-icon" />
        <div class="service-order-type">
          {{ 'service-order-' + item.serviceOrderType.replaceAll('_', '-').toLowerCase() | translate }} - {{
          item.serviceOrderId }}
        </div>
        <div class="service-order-provider">{{ item.providerName }}</div>
      </div>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
      <img [src]="item.iconFileName" class="service-order-icon" />
      <div class="service-order-type">
        {{ 'service-order-' + item.serviceOrderType.replaceAll('_', '-').toLowerCase() | translate }} - {{
        item.serviceOrderId }}
      </div>
      <div class="service-order-provider">{{ item.providerName }}</div>
    </ng-template>
  </ng-select>
  <div *ngIf="displayOnly && selectedItem">    
    <img [src]="selectedItem?.iconFileName" class="service-order-icon" />
    <div class="service-order-type">
      {{ 'service-order-' + selectedItem?.serviceOrderType.replaceAll('_', '-').toLowerCase() | translate }} - {{selectedItem?.serviceOrderId }}
    </div>
    <div class="service-order-provider">{{ selectedItem?.providerName }}</div>
  </div>
</div>