import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DialogHelperUtilService } from '@secca/core/services/dialog-helper-util.service';
import { DialogBoundryService } from '@secca/core/services/dialog-boundry.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { RecentCasesModalComponent } from '../recent-cases-modal/recent-cases-modal.component';
import { IKeyboardEnabled } from '@secca/shared/interfaces/keyboard-enabled';


@Component({
  selector: 'app-recent-cases-dialog',
  templateUrl: './recent-cases-dialog.component.html',
  styleUrls: ['./recent-cases-dialog.component.scss']
})
export class RecentCasesDialogComponent implements OnInit, IKeyboardEnabled {
  @ViewChild(RecentCasesModalComponent) wrappedInstance: RecentCasesModalComponent;

  constructor(private dialogRef: MatDialogRef<RecentCasesModalComponent>,
              private dialogHelperUtilService: DialogHelperUtilService,
              public dialogBoundryService: DialogBoundryService) { }

  ngOnInit(): void {
  }

  close() {
    this.dialogHelperUtilService.close(this.dialogRef, null);
  }

  keyboardClose(): void {
    this.wrappedInstance.close();
  }

}
