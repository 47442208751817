import {HttpClient} from "@angular/common/http";
import {BaseService} from "./base.service";
import {SettingsService} from "./settings.service";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LocationService extends BaseService {
  constructor(
    private httpClient: HttpClient,
    private settingsService: SettingsService,
  ) {
    super(settingsService);
  }

  public requestTimeZoneId(latitude: number, longitude: number): Observable<any> {
    return this.httpClient.get<any>(this.baseURL
      + 'timezone/timezoneid'
      + `?latitude=${latitude}&longitude=${longitude}`,
      { headers: this.jsonHeaders});

  }
}
