import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {DigitalCaseflowLog} from "@secca/shared/models/digital-caseflow-log";

@Component({
  selector: 'app-case-summary-digital-caseflow-modal',
  templateUrl: './case-summary-digital-caseflow-modal.component.html',
  styleUrls: ['./case-summary-digital-caseflow-modal.component.scss'],
})
export class CaseSummaryDigitalCaseflowModalComponent implements OnInit {
  @Input()
  flowLog: DigitalCaseflowLog;

  @Output()
  closed: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit() {
  }

  close() {
    this.closed.emit();
  }
}
