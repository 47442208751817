<div class="topSpacer" *ngIf="!loadedFromOutputManagement"></div>
<div class="sort-container" *ngIf="!loadedFromOutputManagement">
  <div class="row">
    <div class="col-9"></div>
    <div class="col-1 sort-by">{{ "case-summary-sort-by" | translate}} </div>
    <div class="col-2 sort-drop-down-area">
      <app-drop-down [items]="sortItems" [(selectedItemId)]="sortItem" (selectedItemIdChange)="sortChanged($event)"></app-drop-down>
    </div>
  </div>
</div>
<div class="activity-log-table-container" [class.zoomed]="settingsService.isZoomed()" *ngIf="caseSummaryList">
  <div class="background">
    <table class="activity-log-table">
      <thead>
      </thead>
      <tbody class="table-body">
        <div class="activity-row">
          <tr class="table-row" *ngFor="let entry of caseSummaryList; index as i">
            <div *ngIf="showDocumentMoveIfMovedToCase(entry)">
              <td class="position-relative datetime">
                <div class="datetime-entry">{{ entry.actionTime | momentFormat: 'DD MMM YYYY - HH:mm': 'local' }}</div>
                <div class="float-end imageTopMagin"
                  *ngIf="!(entry.caseHistoryType === CaseHistoryType.SMS && entry.direction === CommunicationDirection.IN_BOUND)">
                  <app-user-picture [imageURL]="getUserImage(entry.userId)" [active]="'true'" [imageClass]="'caseSummery'">
                  </app-user-picture>
                </div>
                <div class="float-end pt-1 pe-1 text-uppercase"
                  *ngIf="!(entry.caseHistoryType === CaseHistoryType.SMS && entry.direction === CommunicationDirection.IN_BOUND)">
                  {{ getUserInitials(entry.userId) }}</div>
                <div
                  *ngIf="entry.caseHistoryType === CaseHistoryType.SMS && entry.direction === CommunicationDirection.IN_BOUND && smsCreatedByMap[entry.id] != null">
                  {{smsCreatedByMap[entry.id].type}}
                  <img [src]="smsCreatedByMap[entry.id].icon" class="stakeholder-image" alt="Stakeholder image">
                </div>
              </td>
              <td class="position-relative timeline">
                <div [ngClass]="insertLineBetweenCaseSummaryItems(i)"></div>
                <div [ngClass]="{'red-circle': entry.unreadMessage || entry.unhandled, 'circle': !entry.unreadMessage}"></div>
              </td>
              <td class="position-relative content">
                <div class="casesummeryItem" *ngIf="showNoteEntity(entry.caseHistoryType) === false && entry.caseHistoryType !== CaseHistoryType.EMAIL &&
                  entry.caseHistoryType !== CaseHistoryType.FAX && entry.caseHistoryType !== CaseHistoryType.EMAIL_RECEIVE && entry.caseHistoryType !== CaseHistoryType.EMAIL_UPDATE &&
                  entry.caseHistoryType !== CaseHistoryType.FAX_RECEIVE && entry.caseHistoryType !== CaseHistoryType.FAX_UPDATE &&
                  entry.caseHistoryType !== CaseHistoryType.SMS && entry.caseHistoryType !== CaseHistoryType.CONSENT &&
                  entry.caseHistoryType !== CaseHistoryType.MEDICAL_ASSESSMENT_CREATE && entry.caseHistoryType !== CaseHistoryType.MEDICAL_PRE_ASSESSMENT_CREATE &&
                  entry.caseHistoryType !== CaseHistoryType.POLICY_REMOVED && entry.caseHistoryType !== CaseHistoryType.DIGITAL_CASEFLOW && entry.caseHistoryType !== CaseHistoryType.POLICY_SELECTED &&
                  entry.caseHistoryType !== CaseHistoryType.PROCESS_GOP_REQUEST_CREATE &&
                  entry.caseSubObjectType !== 'EXTERNAL' && entry.caseHistoryType !== CaseHistoryType.GDPR_ITEM_DELETION">
                  <span *ngIf="entry.caseHistoryType === CaseHistoryType.CASE_LINK">
                    <span *ngIf="entry.dataFieldChanges[3].newValue === 'FALSE'">
                      {{ 'case-summary-list-case-linked-to' | translate  }} <span class="values-blue-color">{{ entry.dataFieldChanges[1].newValue }}</span>
                    </span>
                    <span *ngIf="entry.dataFieldChanges[3].newValue === 'TRUE'">
                      {{ 'case-summary-list-case-no-longer-linked-to' | translate  }} <span class="values-blue-color">{{ entry.dataFieldChanges[0].newValue }}</span>
                    </span>
                  </span>
                  <span *ngIf="entry.caseHistoryType === CaseHistoryType.CASE_CREATE">
                    Case reported by <span
                      class="values-blue-color">{{ getChannelTypeName(case.channelType) }}</span>
                    <div class="channel-type-edit">
                      <div class="action-button-menu-button" (click)="showEntryMenu=!showEntryMenu" (clickOutside)="showEntryMenu=false"
                        *ngIf="showActionButton">
                        <div class="action-button-menu-button-dot"></div>
                        <div class="action-button-menu-button-dot"></div>
                        <div class="action-button-menu-button-dot"></div>
                      </div>
                      <div class="action-button-menu" *ngIf="showEntryMenu">
                        <div class="action-button-menu-position" (click)="modal.open()">{{"case-summary-edited-reported-by" | translate}}</div>
                      </div>
                    </div>
                  </span>
                  <span *ngIf="entry.caseHistoryType === CaseHistoryType.SENSITIVE">
                    <div> {{ 'summary-' + entry.dataFieldChanges[0].newValue | translate }}  <span class="values-blue-color">{{ 'sensitive-summary-SENSITIVE' | translate }}</span></div>
                    <div> {{ 'sensitive-history-reason' | translate }}: {{ entry.dataFieldChanges[1].newValue | translate }}</div>
                  </span>
                  <span *ngIf="entry.caseHistoryType === CaseHistoryType.CASE_NUMBER_CREATE">
                    Created case number <span class="values-blue-color">{{ entry.dataFieldChanges[0].newValue }}</span>

                  </span>
                  <span *ngIf="entry.caseHistoryType === CaseHistoryType.POLICY_LOOKUP">
                    Insurance lookup performed in <span
                      class="values-blue-color">{{ entry.searchResultParams[0].searchResultParamValue }}</span>
                  </span>
                  <span *ngIf="entry.caseHistoryType === CaseHistoryType.SMS"> {{'case-summary-list-sms-sent-to' | translate }}
                    {{entry.to}}</span>
                  <span
                    *ngIf="entry.caseHistoryType === CaseHistoryType.SMS_UPDATE">{{ 'case-summary-list-sms-recieved-status-to' |translate }}
                    {{entry.to}} : <span class="values-blue-color">{{entry.newStatus}}</span> </span>
                  <span *ngIf="entry.caseHistoryType === CaseHistoryType.END_USER_SATISFACTION_SURVEY">Case chosen for alarmcenter
                    <span class="values-blue-color">{{"End-user satisfaction"}}</span> survey</span>
                  <span *ngIf="entry.caseHistoryType === CaseHistoryType.END_USER_SATISFACTION_SURVEY_DUPLICATE">Alarmcenter
                    <span class="values-blue-color">{{"End-user satisfaction"}}</span> survey has been sent from another case</span>
                  <span *ngIf="entry.caseHistoryType === CaseHistoryType.CLAIM_END_USER_SATISFACTION_SURVEY">Case chosen for claims
                    <span class="values-blue-color">{{"End-user satisfaction"}}</span> survey</span>
                  <span *ngIf="entry.caseHistoryType === CaseHistoryType.CLAIM_END_USER_SATISFACTION_SURVEY_DUPLICATE">Claims
                    <span class="values-blue-color">{{"End-user satisfaction"}}</span> survey has been sent from another case</span>
                  <span *ngIf="entry.caseHistoryType === CaseHistoryType.MPA_END_USER_SATISFACTION_SURVEY">Case chosen for Medical pre-assessment
                    <span class="values-blue-color">{{"End-user satisfaction"}}</span> survey</span>
                  <span *ngIf="entry.caseHistoryType === CaseHistoryType.MPA_END_USER_SATISFACTION_SURVEY_DUPLICATE">Medical pre-assessment
                    <span class="values-blue-color">{{"End-user satisfaction"}}</span> survey has been sent from another case</span>
                  <span *ngIf="entry.caseHistoryType === CaseHistoryType.REGRES">Case chosen for
                    <span class="values-blue-color">{{"Recovery claims handling - EU-healthcare security"}}</span></span>
                  <span *ngIf="entry.caseHistoryType === CaseHistoryType.STATUS_CHANGE">
                    <span class="values-blue-color">{{ entry.dataFieldChanges[0].field}}</span> set from <span
                      class="values-blue-color">{{ entry.dataFieldChanges[0].oldValue }}</span> to <span
                      class="values-blue-color">{{ entry.dataFieldChanges[0].newValue }}</span>
                  </span>
                  <span *ngIf="entry.caseHistoryType === CaseHistoryType.DOCUMENT_MOVE"> Attachment:
                    <span class="values-blue-color">{{ entry.dataFieldChanges[0].oldValue}}</span> has been transferred from
                    <span class="values-blue-color">{{ entry.dataFieldChanges[1].oldValue }}</span>.
                  </span>
                  <span *ngIf="entry.caseHistoryType === CaseHistoryType.SCREENING_TOOL_CHANGE">
                    {{ "case-summary-list-updated-screening-tool" | translate}}
                    <span *ngFor="let value of entry.dataFieldChanges">
                      <p>
                        <span class="values-blue-color">
                          {{ value.field | translate }}
                        </span>:
                        <span class="values-blue-color">
                          {{ screeningStateDisplayValueAdapter.getPropertyFromValue(value.newValue) | translate }}
                        </span>
                      </p>
                    </span>
                  </span>
                  <span *ngIf="entry.caseHistoryType === CaseHistoryType.CUSTOMER_PRODUCT_ADDED">
                    {{'case-summary-list-customer-product-added' | translate}} <span class="values-blue-color">{{ entry.dataFieldChanges[0].newValue }}</span>
                  </span>
                  <span *ngIf="entry.caseHistoryType === CaseHistoryType.CUSTOMER_PRODUCT_UPDATED">
                    {{'case-summary-list-customer-product-updated' | translate}}  <span class="values-blue-color">{{ entry.dataFieldChanges[0].newValue }}</span>
                  </span>
                  <span *ngIf="entry.caseHistoryType === CaseHistoryType.COORDINATION_CASE_LINK">
                    <ng-container *ngIf="entry.propertyData.value.coordinationCase === 'TRUE'">
                        <span *ngIf="entry.propertyData.value.linkedCaseNumber">
                          {{'case-summary-list-case-linked-to' | translate}}<span class="values-blue-color"> {{ entry.propertyData.value.linkedCaseNumber }}</span>
                        </span>
                        <span *ngIf="entry.propertyData.value.deletedLinkTo">
                          {{'case-summary-list-case-no-longer-linked-to' | translate}}<span class="values-blue-color"> {{ entry.propertyData.value.deletedLinkTo }}</span>
                        </span>
                    </ng-container>
                    <ng-container *ngIf="entry.propertyData.value.coordinationCase === 'FALSE'">
                      <span *ngIf="entry.propertyData.value.linkedCaseNumber">
                        {{'case-summary-list-case-linked-to-coordination-case' | translate}}<span class="values-blue-color"> {{ entry.propertyData.value.linkedCaseNumber }}</span>
                      </span>
                      <span *ngIf="entry.propertyData.value.deletedLinkTo">
                        {{'case-summary-list-case-no-longer-linked-to-coordination-case' | translate}}<span class="values-blue-color"> {{ entry.propertyData.value.deletedLinkTo }}</span>
                      </span>
                    </ng-container>
                  </span>
                </div>
                <div class="case-summary-entry" *ngIf="entry.caseHistoryType === CaseHistoryType.EMAIL || entry.caseHistoryType === CaseHistoryType.FAX ||
                  entry.caseHistoryType === CaseHistoryType.EMAIL_RECEIVE || entry.caseHistoryType === CaseHistoryType.EMAIL_UPDATE ||
                  entry.caseHistoryType === CaseHistoryType.FAX_RECEIVE || entry.caseHistoryType === CaseHistoryType.FAX_UPDATE">
                  <app-case-summary-email-fax [case]="case" [entry]="entry" [stakeholders]="stakeholders" [showActionButton]="true">
                  </app-case-summary-email-fax>
                </div>
                <div class="case-summary-entry" *ngIf="entry.caseHistoryType === CaseHistoryType.SMS">
                  <app-case-summary-sms [case]="case" [entry]="entry" [stakeholders]="stakeholders" [showActionButton]="true"></app-case-summary-sms>
                </div>
                <div class="case-summary-note" *ngIf="showNoteEntity(entry.caseHistoryType) === true">
                  <app-case-summary-note [case]="case" [entry]="entry" [stakeholders]="stakeholders" [caseSummaryEntities]="caseSummaryEntities"
                    (noteIdToDelete)="deleteNote($event)"
                  [numberOfLinkedCases]="numberOfLinkedCases" ></app-case-summary-note>
                </div>
                <div class="casesummery-consent-item" *ngIf="entry.caseHistoryType === CaseHistoryType.CONSENT">
                  <app-case-summary-consent [entry]="entry" [stakeholders]="stakeholders"></app-case-summary-consent>
                </div>
                <div class="casesummeryItem"
                  *ngIf="entry.caseHistoryType === CaseHistoryType.POLICY_SELECTED || entry.caseHistoryType === CaseHistoryType.POLICY_REMOVED">
                  <app-case-summary-policy [entry]="entry"></app-case-summary-policy>
                </div>
                <div class="case-summary-entry" *ngIf="entry.caseHistoryType === CaseHistoryType.DIGITAL_CASEFLOW">
                  <app-case-summary-digital-caseflow [entry]="entry"></app-case-summary-digital-caseflow>
                </div>
                <div class="case-summary-entry" *ngIf="(entry.caseHistoryType === CaseHistoryType.CASE_CREATE || entry.caseHistoryType === CaseHistoryType.PROCESS_GOP_REQUEST_CREATE)
                  && entry.caseSubObjectType === 'EXTERNAL'">
                  <app-case-summary-external [case]="case" [entry]="entry" [showActionButton]="true" [stakeholders]="stakeholders" [channelTypes]="channelTypes">
                  </app-case-summary-external>
                </div>
                <span *ngIf="entry.caseHistoryType === CaseHistoryType.MEDICAL_ASSESSMENT_CREATE ">
                  <div class="card activity-card note">
                    <div class="card-header activity-card-header">
                      <div class="float-start text">
                        <img class="icon-phone" src="/assets/icons/service-order-type/Medical Assessment.svg">
                        <ng-container
                          *ngIf="getField(entry.dataFieldChanges, 'MedicalType')?.newValue === 'MEDICAL_REPORT_SUPPLIER_PORTAL'">
                          #{{getField(entry.dataFieldChanges, 'MedicalNumber').newValue}} {{ 'medical-report-was-added' | translate }}
                        </ng-container>
                        <ng-container
                          *ngIf="getField(entry.dataFieldChanges, 'MedicalType')?.newValue === MedicalNoteTypeEnum.ADMISSION || getField(entry.dataFieldChanges, 'MedicalType').newValue === MedicalNoteTypeEnum.COMMENT">
                          #{{getField(entry.dataFieldChanges, 'MedicalNumber').newValue}} {{ 'medical-comment-note-was-added' | translate }}
                        </ng-container>
                        <ng-container
                          *ngIf="isAnyMedicalAssesment(entry)">
                          #{{medicalAssessmentNo(entry)}}
                          <span class="values-blue-color"
                            *ngIf="isMedicalAssessment(entry,AssessmentTypeEnum.LIGHT)">{{ 'medical-new-regular-assessment-button-light' | translate }}</span>
                            <span class="values-blue-color"
                            *ngIf="isMedicalAssessment(entry, AssessmentTypeEnum.CLAIMS)">{{ 'medical-new-regular-assessment-button-claims' | translate }}</span>
                          <span class="values-blue-color"
                            *ngIf="isMedicalAssessment(entry,AssessmentTypeEnum.REGULAR)">{{ 'medical-new-regular-assessment-button-regular' | translate }}
                            <span *ngIf="getField(entry.dataFieldChanges, 'Repatriation') !== 'null'">
                              <span *ngIf="getField(entry.dataFieldChanges, 'Repatriation') === 'true'">
                                {{ 'case-history-list-with-repatriation-instructions' | translate }} </span>
                              <span *ngIf="getField(entry.dataFieldChanges, 'Repatriation') === 'false'">
                                {{ 'case-history-list-added-with-repatriation-prognosis' | translate }} </span>
                            </span>
                          </span>
                          was published
                        </ng-container>
                      </div>
                      <div *permission="PermissionEnum.MEDICAL_READ">
                        <div class="float-end"
                          *ngIf="isAnyMedicalAssesment(entry) && showMedicalItemIfNotDeleted(entry)">
                          <div class="dropdown">
                            <span class="dot"></span>
                            <span class="dot"></span>
                            <span class="dot"></span>
                            <div class="dropdown-content">
                              <p (click)='openAssessment(entry.entityId);'>View</p>
                            </div>
                          </div>
                        </div>
                        <div class="float-end deleted" *ngIf="!showMedicalItemIfNotDeleted(entry)">
                          {{ "medical-history-deleted-due-to-GDPR" | translate }}
                        </div>
                      </div>
                    </div>
                  </div>
                </span>
                <div *ngIf="entry.caseHistoryType === CaseHistoryType.GDPR_ITEM_DELETION">
                    <div class="card activity-card note" *ngIf="entry.caseObjectType !== 'MEDICAL_ASSESSMENT'">
                      <div class="card-header activity-card-header">
                        <div class="float-start text">
                          <span>
                            <img src="/assets/icons/Case note.svg" />
                          </span>
                        </div>
                        <div class="float-end">
                          <div *ngIf="entry.deletedOn" class="float-end">
                            <span class="redText">Deleted &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                          </div>
                          <div class="dropdown float-end" *ngIf='!entry.deletedOn'>
                            <span class="dot"></span>
                            <span class="dot"></span>
                            <span class="dot"></span>

                            <div class="dropdown-content">
                              <p (click)="openModalEdit(entry)">{{"Edit" | translate}}</p>
                              <div *permission="PermissionEnum.COMMUNICATION_GDPR_DELETE">
                                <p class="redText" (click)="deleteGdprNote(entry)">Delete</p>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="!entry.deletedOn && deleteReasonEdited(entry)" class="float-end">
                            <span>Edited &nbsp;</span>
                          </div>
                        </div>

                      </div>
                      <div class="body activity-card-header" *ngIf='!entry.deletedOn'>
                        <span class="float-left text">{{ 'reason' | translate }}: </span>
                        <span class="">{{ deleteReasonValue(entry) | translate }}</span>

                      </div>
                    </div>
                </div>
                <span *ngIf="entry.caseHistoryType === CaseHistoryType.MEDICAL_PRE_ASSESSMENT_CREATE">
                  <div class="card activity-card note">
                    <div class="card-header activity-card-header">
                      <div class="float-start text">
                        <img class="icon-phone" src="/assets/icons/service-order-type/Medical Pre Assessment.svg">
                        <ng-container
                          *ngIf="getField(entry.dataFieldChanges, 'MedicalType')?.newValue === MedicalNoteTypeEnum.PRE_ASSESSMENT_COMMENT">
                          #{{getField(entry.dataFieldChanges, 'MedicalNumber').newValue}} <span class="values-blue-color">{{ 'case-summary-list-medical-pre-assessment' | translate }}</span> {{ 'comment-note-was-added' | translate }}
                        </ng-container>
                        <ng-container *ngIf="isAnyMedicalPreAssesment(entry)">
                          #{{medicalPreAssessmentNo(entry)}}
                          <span class="values-blue-color">{{ 'case-summary-list-medical-pre-assessment' | translate }}</span>
                          was published - <span class="medical-pre-assessment-decision">{{'medical-pre-assessment-decison-' + getMedicalPreAssessmentDecision(entry) | translate}}</span>
                        </ng-container>
                      </div>
                      <div *permission="PermissionEnum.MEDICAL_READ">
                        <div class="float-end"
                          *ngIf="isAnyMedicalPreAssesment(entry) && showMedicalPreAssessmentItemIfNotDeleted(entry)">
                          <div class="dropdown">
                            <span class="dot"></span>
                            <span class="dot"></span>
                            <span class="dot"></span>
                            <div class="dropdown-content">
                              <p (click)='openPreAssessment(entry.entityId);'>View</p>
                            </div>
                          </div>
                        </div>
                        <div class="float-end deleted" *ngIf="!showMedicalPreAssessmentItemIfNotDeleted(entry)">
                          {{ "medical-history-deleted-due-to-GDPR" | translate }}
                        </div>
                      </div>
                    </div>
                  </div>
                </span>
              </td>
            </div>
          </tr>

          <div class="modal modal-window" tabindex="-1" role="dialog" [ngStyle]="showYesNo && {'display':'block'}">
            <div class="modal-dialog-case-summary" role="document">
              <div class="modal-content">
                <div class="modal-body modal-body-case-summary">
                  <p>Delete note?</p>
                </div>
                <div class="modal-footer-case-summary">
                  <button class="primary-button float-start" (click)="deleteItem();hideMe()">Yes</button>
                  <button class="primary-button-inactive float-end" (click)="hideMe()" data-dismiss="modal">No</button>
                </div>
              </div>
            </div>
          </div>
          <div class="new-casesummary-overlay" [ngStyle]="showYesNo && {'display':'block'}">
          </div>
        </div>
      </tbody>
    </table>
  </div>
</div>
<app-channel-type-modal (channelTypeChanged)="channelTypeUpdated($event)" [caseId]="case.id" [channelType]="case.channelType" #modal ></app-channel-type-modal>
