<div class="sub-component-container" *ngIf="subCoffinUrn">
  <div class="sub-title">{{"coffin-urn-title" | translate}}</div>
  <div class="sub-content-container">
    <div class="row">
      <div class="col-6">
        <app-drop-down [requiredRedBackground]="!subCoffinUrn.handling && required" [items]="types"
          [name]="'coffin-urn-handling' | translate" [(selectedItemId)]="subCoffinUrn.handling"
                       (selectedItemIdChange)="setSelectedHandlingType($event)" [disabled]="handlingDisabled || readOnlyMode"></app-drop-down>
      </div>
      <div class="col-6">
        <app-input [(value)]="subCoffinUrn.passportLocation" [name]="'coffin-urn-passport-location' | translate"
                   [maxTextLength]="100" [disabled]="readOnlyMode"></app-input>
      </div>
    </div>
  </div>
</div>
