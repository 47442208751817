
import { MomentHelperService } from '@secca/core/services/moment-helper.service';
import * as moment from 'moment';
import { DateSerializer } from '../date-serializer';
import { ServiceOrderExtension } from './service-order-extension';
import { NoDurationServiceOrderExtension } from './no-duration-service-order-extension';
import { CommitmentType, CommitmentTypeAdapter } from '../commitment-type';

export class MoneyTransferServiceOrderExtension extends NoDurationServiceOrderExtension implements ServiceOrderExtension {
  displayDurationId: number;
  id: number;
  startDate: moment.Moment = MomentHelperService.getSeccaTime().startOf('day').utc();
  commitmentType: CommitmentType;

  public constructor(init?: Partial<MoneyTransferServiceOrderExtension>) {
    super();
    Object.assign(this, init);
  }

  isValid(): boolean {
    return this.startDate && this.startDate.isValid();
  }

  init() {}

  isPreviewGopValid(): boolean {
    throw new Error("Gop preview not supported for this service order type");
  }

  setCommitmentType(commitmentType: CommitmentType) {
    this.commitmentType = commitmentType
  }

  getCommitmentType(): CommitmentType {
    return this.commitmentType;
  }

  getSelectedStakeholderIds(): string[] {
    return null;
  }
}
export class MoneyTransferServiceOrderExtensionAdapter {

  adapt(item?: any): MoneyTransferServiceOrderExtension {
    const commitmentTypeAdapter: CommitmentTypeAdapter = new CommitmentTypeAdapter();

    return new MoneyTransferServiceOrderExtension({
      id: item.id,
      startDate: DateSerializer.deserialize(item.startDate),
      commitmentType: item.commitmentType != null ? commitmentTypeAdapter.adapt(item.commitmentType) : null
    });
  }
}
