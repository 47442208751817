import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
import { DineroObject } from 'dinero.js';
import { SupplierInvoiceItem, SupplierInvoiceItemAdapter } from './supplierInvoiceItem';
import { BalanceSheetEntryEnum, SupplierInvoiceStatus } from './enums';
import { BalanceSheetProgress } from "./balance-sheet-event.model";
import { BalanceSheetEntry, BalanceSheetEntryItem } from '@secca/case/components/case-plans/case-plans/add-service-order/models/interfaces';
import { EconomyHistory } from './refund-history';
import { DateSerializer } from './date-serializer';

export class SupplierInvoice implements BalanceSheetEntry, EconomyHistory {
  id: number;
  caseId: number;
  invoiceDocumentId: string;
  supplierId: number;
  description: string;
  invoiceNumber: string;
  receivedDate: Date;
  invoiceDate: Date;
  dueDate: Date;
  exchangeRate: number;
  invoiceAmountLocal: DineroObject;
  invoiceAmountExchanged: DineroObject;
  internalRemark: string;
  reductionRemark: string;
  returnRemark: string;
  invoiceStatus: SupplierInvoiceStatus;
  creditorId: string;
  creditorName: string;
  serviceOrderId: string;
  items: SupplierInvoiceItem[];
  saved: number;
  settled: number;
  creditorSupplierGroup: string;
  medicalEscort: boolean;
  submittedByInSupo: string;
  invoiceGroup: string;
  progress: BalanceSheetProgress;
  modifiedBy: string;
  modifiedOn: moment.Moment;
  deletedBy: string;
  deletedOn: moment.Moment;
  supplierInvoiceError: string;
  supplierInvoiceRetryCount: number;

  identicalHistory(histObject: EconomyHistory): boolean {
    return false;
  }

  public constructor(init?: Partial<SupplierInvoice>) {
    Object.assign(this, init);
  }

  getInvoiceDate(): Date {
    return this.invoiceDate;
  }

  getId(): number {
    return this.id;
  }

  getType(): BalanceSheetEntryEnum {
    return BalanceSheetEntryEnum.SUPPLIER_INVOICE;
  }

  getInvoiceAmount(): DineroObject {
    return this.invoiceAmountLocal;
  }

  getInvoiceAmountExchanged(): DineroObject {
    return this.invoiceAmountExchanged;
  }

  getCreditorId(): string {
    return this.creditorId;
  }

  getCreditorName(): string {
    return this.creditorName;
  }

  getDueDate(): Date {
    return this.dueDate;
  }

  getExchangeRate(): number {
    return this.exchangeRate;
  }

  getInvoiceDocumentId(): string {
    return this.invoiceDocumentId;
  }

  getInvoiceNumber(): string {
    return this.invoiceNumber;
  }

  getStatus(): string {
    return this.invoiceStatus;
  }

  getItems(): BalanceSheetEntryItem[] {
    return this.items;
  }

  getReceivedDate(): Date {
    return this.receivedDate;
  }

  getSettledAmount(): number {
    return this.settled;
  }

  getMedicalEscort(): boolean {
    return this.medicalEscort;
  }

  getReductionAmount(): number {
    return this.saved;
  }

  setReductionAmount(amount: number) {
    this.saved = amount;
  }

  setSettledAmount(amount: number) {
    this.settled = amount;
  }

  getStakeholderId(): number {
    return 0;
  }

  getProgress(): BalanceSheetProgress {
    return this.progress;
  }

  isCancelled(): boolean {
    return false;
  }

  isObjection(): boolean {
    return false;
  }

  getRedStatusText(): string {
    if (this.getStatus() === SupplierInvoiceStatus.rejected.toString()){
      return SupplierInvoiceStatus.rejected;
    }
    return SupplierInvoiceStatus.supplierPaid;
  }

  isDeleted(): boolean {
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class SupplierInvoiceAdapter implements Adapter<SupplierInvoice> {
  adapt(item: any): SupplierInvoice {
    let invoiceAmountLocal = item.invoiceAmountLocal;
    if (item.invoiceAmountLocal == null) {
      invoiceAmountLocal = { amount: 0, currency: 'DKK', precision: 2 };
    }

    let invoiceAmountExchanged = item.invoiceAmountExchanged;
    if (item.invoiceAmountExchanged == null) {
      invoiceAmountExchanged = { amount: 0, currency: 'DKK', precision: 2 };
    }

    let invoiceStatusString = '';
    for (const enumStatus in SupplierInvoiceStatus) {
      if (SupplierInvoiceStatus[enumStatus] === item.invoiceStatus) {
        invoiceStatusString = enumStatus;
        break;
      }
    }
    const invoiceStatus: SupplierInvoiceStatus = SupplierInvoiceStatus[invoiceStatusString];

    const supplierInvoice = new SupplierInvoice({
      id: item.id,
      caseId: item.caseId,
      invoiceDocumentId: item.invoiceDocumentId,
      supplierId: item.supplierId,
      description: item.description,
      invoiceNumber: item.invoiceNumber,
      receivedDate: item.receivedDate === null ? null : new Date(item.receivedDate),
      invoiceDate: item.invoiceDate === null ? null : new Date(item.invoiceDate),
      dueDate: item.dueDate === null ? null : new Date(item.dueDate),
      invoiceAmountLocal,
      invoiceAmountExchanged,
      exchangeRate: item.exchangeRate,
      internalRemark: item.internalRemark,
      reductionRemark: item.reductionRemark,
      returnRemark: item.returnRemark,
      creditorId: item.creditorId,
      creditorName: item.creditorName,
      creditorSupplierGroup: item.creditorSupplierGroup,
      invoiceStatus,
      invoiceGroup: item.invoiceGroup,
      serviceOrderId: item.serviceOrderId,
      medicalEscort: item.medicalEscort,
      submittedByInSupo: item.submittedByInSupo,
      items: [],
      saved: null,
      settled: null,
      modifiedBy: item.modifiedBy,
      modifiedOn: item.modifiedOn ? DateSerializer.deserialize(item.modifiedOn) : null,
      deletedOn: item.deletedOn ? DateSerializer.deserialize(item.deletedOn) : null,
      deletedBy: item.deletedBy,
      supplierInvoiceError: item.supplierInvoiceError,
      supplierInvoiceRetryCount: item.supplierInvoiceRetryCount,
    });
    if (item.items) {
      for (const invoiceItem of item.items) {
        supplierInvoice.items.push(this.adaptSupplierInvoiceItem(invoiceItem));
      }
    }

    return supplierInvoice;
  }

  private adaptSupplierInvoiceItem(invoiceItem: any): SupplierInvoiceItem {
    const supplierInvoiceItemAdapter = new SupplierInvoiceItemAdapter()
    const supplierInvoiceItem = supplierInvoiceItemAdapter.adapt(invoiceItem);
    return supplierInvoiceItem;
  }
}
