<div class="supplier-invoice-task-table" *ngIf="taskViewModels; else loadingTemplate">
  <div class="primary-table-container">
    <table class="primary-table fixed-header-table">
      <thead>
        <tr>
          <td class="checkbox-column-header">
            <div class="custom-checkbox checkbox-header-container">
              <label class="checkbox">
                <input type="checkbox" (click)="selectAllTasks()" [(ngModel)]="isAllSelected" />
                <span class="checkmark"></span>
              </label>
            </div>
          </td>
          <td (click)="sortBy('taskType')" [ngClass]="sortedBy('taskType')">{{ 'board-task-supplier-invoice-table-type' | translate }}</td>
          <td (click)="sortBy('customerProfileId')" [ngClass]="sortedBy('customerProfileId')">{{ 'board-task-supplier-invoice-table-cu' | translate }}</td>
          <td (click)="sortBy('invoiceDate')" [ngClass]="sortedBy('invoiceDate')">{{ 'board-task-supplier-invoice-table-invoice-date' | translate }}</td>
          <td (click)="sortBy('receivedDate')" [ngClass]="sortedBy('receivedDate')">{{ 'board-task-supplier-invoice-table-received-date' | translate }}</td>
          <td (click)="sortBy('dueDate')" [ngClass]="sortedBy('dueDate')">{{ 'board-task-supplier-invoice-table-due-date' | translate }}</td>
          <td (click)="sortBy('remaining')" [ngClass]="sortedBy('remaining')">{{ 'board-task-supplier-invoice-table-remaining' | translate }}</td>
          <td (click)="sortBy('invoiceGroup')" [ngClass]="sortedBy('invoiceGroup')">{{ 'board-task-supplier-invoice-table-invoice-group' | translate }}</td>
          <td (click)="sortBy('serviceOrderId')" [ngClass]="sortedBy('serviceOrderId')">{{ 'board-task-supplier-invoice-table-service-order-id' | translate }}</td>
          <td (click)="sortBy('serviceOrderType')" [ngClass]="sortedBy('serviceOrderType')">{{ 'board-task-supplier-invoice-table-ser' | translate }}</td>
          <td (click)="sortBy('creditorId')" [ngClass]="sortedBy('creditorId')">{{ 'board-task-supplier-invoice-table-creditor' | translate }}</td>
          <td (click)="sortBy('invoiceNumber')" [ngClass]="sortedBy('invoiceNumber')">{{ 'board-task-supplier-invoice-table-invoice-id' | translate }}</td>
          <td (click)="sortBy('invoiceAmount')" [ngClass]="sortedBy('invoiceAmount')">{{ 'board-task-supplier-invoice-table-invoice-amount' | translate }}</td>
          <td (click)="sortBy('invoiceStatus')" [ngClass]="sortedBy('invoiceStatus')">{{ 'board-task-supplier-invoice-table-invoice-status' | translate }}</td>
          <td (click)="sortBy('assignedTo')" [ngClass]="sortedBy('assignedTo')">{{ 'board-task-supplier-invoice-table-assign' | translate }}</td>
        </tr>
      </thead>
      <tbody (scroll)="onCaseTabelScroll()" id="taskSupplierInvoiceTabel" [class.zoomed]="settingsService.isZoomed()">
        <tr *ngFor="let task of taskViewModels; let i = index" [class.task-check]="task.isSelected" [class.task-row-red]="!task.invoiceId">
          <td class="checkbox-column">
            <div class="custom-checkbox checkbox-content-container">
              <label class="checkbox">
                <input type="checkbox" [(ngModel)]="task.isSelected" />
                <span class="checkmark"></span>
              </label>
            </div>
          </td>
          <td class="type-column"><img [src]="getTaskTypeImagePath(task)" (click)="viewTask(task)" /></td>
          <td class="customer-column">
            <img *ngIf="task.customerProfileId" [src]="getBrandImagesUrlForProfile(task.customerProfileId)" />
          </td>
          <td>{{ task.invoiceDate | date: 'dd MMM' | lowercase }}</td>
          <td>{{ task.receivedDate | momentFormat: 'DD MMM':'local' | lowercase }}</td>
          <td>{{ task.dueDate | momentFormat: 'DD MMM':'local' | lowercase }}</td>
          <td>{{ task.remaining }}</td>
          <td>{{ task.invoiceGroup }}</td>
          <td class="link-column" (click)="manageInvoiceAndOpenPDF(task)">
            <div *ngIf="task.serviceOrderId !== '-'">{{ task.serviceOrderId }}</div>
          </td>
          <td class="service-order-type-icon-column">
            <div *ngIf="task.serviceOrderId && task.serviceOrderId !== '-'"
              [ngStyle]="getServiceOderTypeIconStyle(task)" ngbPopover="{{ getServiceOrderTypeName(task) }}"
              triggers="hover"></div>
          </td>
          <td class="creditor-column">
            {{ task.creditorId }}&nbsp;
            <span class="creditor-column-label">{{ task.creditorName }}</span>
          </td>
          <td>{{ task.invoiceNumber }}</td>
          <td class="amount-column">
            <span class="currency-value">{{ task.invoiceAmountCurrency }}</span>{{ task.invoiceAmount | number: '.2' }}
          </td>
          <td>
            <div *ngIf="task.invoiceId">{{ 'economy-supplier-invoice-status-' + task.invoiceStatus | translate }}</div>
          </td>
          <td>{{ task.assignedTo }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<ng-template #loadingTemplate>
  <div class="d-flex justify-content-center pt-3">
    <h4>{{ 'board-task-table-refreshing' | translate }}</h4>
  </div>
</ng-template>
