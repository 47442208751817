<div class="left-side-container">
  <div class="creation-date-container">
    <div class="start-date">
          <app-input-date-time
            [name]="'medical-flight-authorization-service-order-creation-date' | translate"
            [(date)]="medicalFlightAuthorization.startDate"
            [withImage]="true"
            [disabled]="true"
            [timeZone]="'local'">
          </app-input-date-time>
    </div>
  </div>
  <div class="vert-spacer"></div>
  <div class="horizontal-line"></div>
  <div class="status-container">
    <div class="text">
      {{ 'left-side-status' | translate }}
    </div>
    <div class="row">
      <div class="col pe-0">
        <label class="custom-radio-button" [class.radio-disabled]="expectedDisabled()">
          <input type="checkbox" [checked]="serviceOrder.status === StatusTypes.EXPECTED" [disabled]="expectedDisabled()"
            (click)="changeStatus(StatusTypes.EXPECTED, $event)" />
          <span class="checkmark"></span>
          <div class="radio-button-text">{{ 'service-order-expected' | translate }}</div>
        </label>
      </div>
      <div class="col p-0">
        <label class="custom-radio-button" [class.radio-disabled]="committedDisabled()">
          <input type="checkbox" [checked]="serviceOrder.status === StatusTypes.COMMITTED" [disabled]="committedDisabled()"
            (click)="changeStatus(StatusTypes.COMMITTED, $event)" />
          <span class="checkmark"></span>
          <div class="radio-button-text">{{ 'service-order-committed' | translate }}</div>
        </label>
      </div>
      <div class="col ps-0">
        <label class="custom-radio-button" [class.radio-disabled]="cancelledDisabled()">
          <input type="checkbox" [checked]="serviceOrder.status === StatusTypes.CANCELLED" [disabled]="cancelledDisabled()"
            (click)="changeStatus(StatusTypes.CANCELLED, $event)" />
          <span class="checkmark"></span>
          <div class="radio-button-text">{{ 'service-order-cancelled' | translate }}</div>
        </label>
      </div>
    </div>
  </div>
  <div class="horizontal-line"></div>
  <div class="remarks-container">
    <div class="text">{{ 'service-order-remarks' | translate }}</div>
    <textarea name="textarea" class="remarks-textarea remarks" maxlength="1000" [(ngModel)]="serviceOrder.remarks"
      (ngModelChange)="dataChanged()"></textarea>
  </div>
</div>
