
import { Location } from '@secca/shared/models/location';
import * as moment from 'moment-timezone';
import { MomentHelperService } from '@secca/core/services/moment-helper.service';
import { CommitmentType } from '../commitment-type';

export class NoDurationServiceOrderExtension {

  startDate: moment.Moment;
  
  getStartDate(): moment.Moment {
    return this.startDate;
  }

  getStartDisplayDate(): moment.Moment {
    return this.getStartDate();
  }

  isMultipleDurations(): boolean {
    return false;
  }

  getStartLocation(): Location {
    return null;
  }

  getEndLocation(): Location {
    return null;
  }

  getEndDate(): moment.Moment {
    return MomentHelperService.endOfCaseWorkerDay(this.startDate);
  }

  getEndDisplayDate(): moment.Moment {
    return this.getEndDate();
  }

  hasMedicalEscort(): boolean {
    return false;
  }

  hasCoTravellers(): boolean {
    return false;
  }

}
