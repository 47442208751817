import { InputTypePattern } from 'src/app/shared/models/enums';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { formatCurrency, getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import { IConfig } from 'ngx-mask';
import { LocaleService } from '../../../core/services/locale.service';

@Component({
  selector: 'app-input-valuta',
  templateUrl: './input-valuta.component.html',
  styleUrls: ['./input-valuta.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InputValutaComponent implements OnInit {
  @Output() valueChange = new EventEmitter();
  @Output() currencyChange = new EventEmitter();
  @Input() placeholder: string;
  @Input() name: string;
  @Input() value: string;
  @Input() currency: string;
  @Input() required: boolean;
  @Input() maxTextLength: number = 100000;
  @Input() disabled: boolean;
  @Input() inputTypePattern: InputTypePattern = InputTypePattern.all;
  @Input() allowZero = false;

  constructor() {
    if (this.placeholder === undefined) {
      this.placeholder = '';
    }
  }

  ngOnInit() {
    this.formatCurrency();
  }

  focusElement(event: any) {
    event.target.select();
  }

  onChangeValue() {
    this.valueChange.emit(this.value);
  }

  get isValueAccepted() {
    //the value is formatted as currency 3232.333,43 or 32,3232.33 depends on locale settings
    if (this.value == null || this.value.length === 0){
      return false;
    }
    let reg = this.allowZero ? new RegExp('^[0-9]+$') : new RegExp('^[1-9]+$');
    return reg.test(this.value[0]);
  }

  formatCurrency() {
    if (this.value != null && this.value !== '') this.value = formatCurrency(parseFloat(this.value), LocaleService.LOCALE, '');
  }

  getThousandSeparator(): string {
    return getLocaleNumberSymbol(LocaleService.LOCALE, NumberSymbol.CurrencyGroup);
  }

  getDecimalMarker(): IConfig['decimalMarker'] {
    const currencyDecimal = getLocaleNumberSymbol(LocaleService.LOCALE, NumberSymbol.CurrencyDecimal);
    if (currencyDecimal === ',') {
      return ',';
    } else if (currencyDecimal === '.') {
      return '.';
    } else {
      throw new RangeError('NumberSymbol.CurrencyDecimal is neither \',\' nor \'.\'!');
    }
  }
}
