import { CaseHistoryCommEntry, CaseHistoryEntry } from '../../../../../shared/models/caseHistoryEntry';
import { CaseSummaryService } from '../../../../../core/services/caseSummary.service';
import { UserDto } from '../../../../../shared/models/userDto';
import { CaseSummary } from '../../../../../shared/models/caseSummary';
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Case } from '../../../../../shared/models/case';
import { DropdownDictionary } from '../../../../../shared/models/dropdownDictionary';
import { CaseSummaryFilter } from '../../../../../shared/models/caseSummaryFilter';
import { CaseHistoryType, CaseObjectType, CaseSubObjectType } from '../../../../../shared/enums/case-history-type';
import {
  AssessmentTypeEnum,
  CommunicationDirection,
  IODirection,
  MedicalNoteTypeEnum,
  TravelPurposeEnum
} from '../../../../../shared/models/enums';
import { CaseSummarySortOrderType } from '../../../../../shared/enums/caseSummarySortOrderType';
import { ScreeningStateDisplayValueAdapter } from '../../../../../shared/enums/screening-states';
import { PersonInsuranceAdapter } from '@secca/shared/models/person-insurance';
import { MatchQuality, PossibleMatches } from '@secca/shared/models/policy-lookup-response';
import { TranslateService } from '@ngx-translate/core';
import { CaseHistoryPropertyChangedData } from '@secca/shared/models/caseHistoryPropertyChangedData';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { Subscription } from 'rxjs';
import { ChannelType } from '@secca/shared/enums/channelType';
import { SettingsService } from '@secca/core/services/settings.service';
import { CaseHistoryDataFieldChangeDto } from '@secca/shared/models/CaseHistoryDataFieldChangeDto';
import { CaseHistoryDeletedFieldValueDto } from '@secca/shared/models/CaseHistoryDeletedFieldValueDto';
import { OutputManagementService } from '@secca/case/components/case-output-management/services/output-management.service';
import { MasterListService } from '@secca/core/services/masterlist-service';
import { Channel } from '@secca/shared/models/channel';

@Component({
  selector: 'app-case-history-list',
  templateUrl: './case-history-list.component.html',
  styleUrls: ['./case-history-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
@AutoUnsubscribe
export class CaseHistoryListComponent {
  @Input()
  case: Case;

  @Output()
  sortChangeEvent: EventEmitter<string> = new EventEmitter();

  sortItems: DropdownDictionary[] = [
    { id: 'ASC', name: CaseSummarySortOrderType.ASC },
    { id: 'DESC', name: CaseSummarySortOrderType.DESC },
  ];
  sortItem = 'DESC';
  caseHistoryEntries: CaseHistoryEntry[] | CaseHistoryCommEntry [];
  caseHistoryUsers: UserDto[];
  channelTypes: Channel[];
  caseHistory: CaseSummary;
  caseSummaryFilter: CaseSummaryFilter;
  previousCaseSummaryFilter: CaseSummaryFilter = null;
  caseHistoryEntities: any;
  matchQuality: typeof MatchQuality = MatchQuality;
  $subscr1: Subscription;

  webChannel: string;

  constructor(
    private caseSummaryService: CaseSummaryService,
    private masterListService: MasterListService,
    private personInsuranceAdapter: PersonInsuranceAdapter,
    private translateService: TranslateService,
    // screeningStateDisplayValueAdapter in used in the HTML and must not be deleted
    public screeningStateDisplayValueAdapter: ScreeningStateDisplayValueAdapter,
    public settingsService: SettingsService,

  ) {
    this.$subscr1 = this.caseSummaryService.getCaseHistorySubject().subscribe(result => {
      if (result) {
        this.caseHistory = result;
        this.caseHistoryEntries = this.caseHistory.caseHistoryList;
        this.caseHistoryUsers = this.caseHistory.users;
      } else {
        this.caseHistoryEntries = null;
      }
    });

    this.masterListService.getAllChannelTypes().subscribe(channelTypes => {
      this.channelTypes = channelTypes;
    })

  }

  get CaseHistoryType() {
    return CaseHistoryType;
  }

  get CaseSubObjectType() {
    return CaseSubObjectType;
  }

  get MedicalNoteTypeEnum() {
    return MedicalNoteTypeEnum;
  }

  get ChannelType() {
    return ChannelType;
  }

  get AssessmentTypeEnum() {
    return AssessmentTypeEnum;
  }

  get IODirection() {
    return IODirection;
  }

  get CommunicationDirection() {
    return CommunicationDirection;
  }

  get CaseObjectType() {
    return CaseObjectType;
  }

  showSingleTableDataViews(caseSummaryType: CaseHistoryType) {
    return (
      caseSummaryType === CaseHistoryType.CASE_CREATE ||
      caseSummaryType === CaseHistoryType.MEDICAL_ASSESSMENT_CREATE ||
      caseSummaryType === CaseHistoryType.MEDICAL_ASSESSMENT_MOVE ||
      caseSummaryType === CaseHistoryType.EXTERNAL_AUTO_CREATE_CASE
    );
  }

  showDataFieldWithOldValues(caseSummaryType: CaseHistoryType) {
    return (
      caseSummaryType === CaseHistoryType.PHONE_NOTE_EDIT ||
      caseSummaryType === CaseHistoryType.CASE_NOTE_EDIT ||
      caseSummaryType === CaseHistoryType.STATUS_CHANGE ||
      caseSummaryType === CaseHistoryType.DATA_FIELD_UPDATE ||
      caseSummaryType === CaseHistoryType.SMS_UPDATE ||
      caseSummaryType === CaseHistoryType.SERVICE_ORDER_EDIT ||
      caseSummaryType === CaseHistoryType.TASK_UPDATE ||
      caseSummaryType === CaseHistoryType.TASK_ADD ||
      caseSummaryType === CaseHistoryType.SCREENING_TOOL_CHANGE ||
      caseSummaryType === CaseHistoryType.DOCUMENT_MOVE ||
      caseSummaryType === CaseHistoryType.FAX_UPDATE ||
      caseSummaryType === CaseHistoryType.EMAIL_UPDATE ||
      caseSummaryType === CaseHistoryType.CONSENT ||
      caseSummaryType === CaseHistoryType.CUSTOMER_PRODUCT_UPDATED ||
      caseSummaryType === CaseHistoryType.STAKEHOLDER_MERGE ||
      caseSummaryType === CaseHistoryType.HANDLER_CHANGE ||
      caseSummaryType === CaseHistoryType.SENSITIVE ||
      caseSummaryType === CaseHistoryType.GDPR_ITEM_EDIT
    );
  }

  showDataFieldWithNewValues(caseSummaryType: CaseHistoryType) {
    return (
      caseSummaryType === CaseHistoryType.PHONE_NOTE_CREATE ||
      caseSummaryType === CaseHistoryType.CASE_NOTE_CREATE ||
      caseSummaryType === CaseHistoryType.PHONE_NOTE_EDIT ||
      caseSummaryType === CaseHistoryType.CASE_NOTE_EDIT ||
      caseSummaryType === CaseHistoryType.PHONE_NOTE_COPY ||
      caseSummaryType === CaseHistoryType.CASE_NOTE_COPY ||
      caseSummaryType === CaseHistoryType.STATUS_CHANGE ||
      caseSummaryType === CaseHistoryType.DATA_FIELD_INPUT ||
      caseSummaryType === CaseHistoryType.DATA_FIELD_UPDATE ||
      caseSummaryType === CaseHistoryType.SMS ||
      caseSummaryType === CaseHistoryType.SMS_UPDATE ||
      caseSummaryType === CaseHistoryType.SERVICE_ORDER_CREATE ||
      caseSummaryType === CaseHistoryType.SERVICE_ORDER_EDIT ||
      caseSummaryType === CaseHistoryType.TASK_CREATE ||
      caseSummaryType === CaseHistoryType.TASK_UPDATE ||
      caseSummaryType === CaseHistoryType.TASK_ADD ||
      caseSummaryType === CaseHistoryType.CASE_NUMBER_CREATE ||
      caseSummaryType === CaseHistoryType.SCREENING_TOOL_CHANGE ||
      caseSummaryType === CaseHistoryType.DOCUMENT_CREATE ||
      caseSummaryType === CaseHistoryType.DOCUMENT_MOVE ||
      caseSummaryType === CaseHistoryType.EMAIL ||
      caseSummaryType === CaseHistoryType.FAX ||
      caseSummaryType === CaseHistoryType.EMAIL_RECEIVE ||
      caseSummaryType === CaseHistoryType.FAX_RECEIVE ||
      caseSummaryType === CaseHistoryType.CUSTOMER_NOTE_CREATE ||
      caseSummaryType === CaseHistoryType.CONSENT ||
      caseSummaryType === CaseHistoryType.CUSTOMER_PRODUCT_ADDED ||
      caseSummaryType === CaseHistoryType.CUSTOMER_PRODUCT_UPDATED ||
      caseSummaryType === CaseHistoryType.HANDLER_CHANGE ||
      caseSummaryType === CaseHistoryType.SUPPLIER_NOTE_CREATE ||
      caseSummaryType === CaseHistoryType.END_USER_SATISFACTION_SURVEY ||
      caseSummaryType === CaseHistoryType.END_USER_SATISFACTION_SURVEY_DUPLICATE ||
      caseSummaryType === CaseHistoryType.CLAIM_END_USER_SATISFACTION_SURVEY_DUPLICATE ||
      caseSummaryType === CaseHistoryType.CLAIM_END_USER_SATISFACTION_SURVEY ||
      caseSummaryType === CaseHistoryType.MPA_END_USER_SATISFACTION_SURVEY_DUPLICATE ||
      caseSummaryType === CaseHistoryType.MPA_END_USER_SATISFACTION_SURVEY ||
      caseSummaryType === CaseHistoryType.SLA_EVENT ||
      caseSummaryType === CaseHistoryType.REGRES ||
      caseSummaryType === CaseHistoryType.CASE_EVENT ||
      caseSummaryType === CaseHistoryType.SENSITIVE ||
      caseSummaryType === CaseHistoryType.GDPR_ITEM_EDIT
    );
  }

  showSearchResult(caseSummaryType: CaseHistoryType) {
    return (
      caseSummaryType === CaseHistoryType.NATIONAL_ID_CHECK ||
      caseSummaryType === CaseHistoryType.POLICY_LOOKUP ||
      caseSummaryType === CaseHistoryType.CUSTOMER_LOOKUP ||
      caseSummaryType === CaseHistoryType.SUPPLIER_LOOKUP ||
      caseSummaryType === CaseHistoryType.END_USER_LOOKUP ||
      caseSummaryType === CaseHistoryType.MEDICAL_PRE_ASSESSMENT_LOOKUP ||
      caseSummaryType === CaseHistoryType.MEDICAL_PRE_ASSESSMENT_LOOKUP_SECCA
    );
  }

  showItemDeletion(caseSummaryType: CaseHistoryType) {
    return (
      caseSummaryType === CaseHistoryType.ITEM_DELETION ||
      caseSummaryType === CaseHistoryType.GDPR_ITEM_DELETION
    );
  }

  showPurposeOfTravelText(value: string): string {
    const newValue = value.replace('[', '').replace(']', '').split(',');
    let finalTextForHistory = '';
    newValue.forEach((value, index) => {
        value.trim() !== '' ? finalTextForHistory = finalTextForHistory + TravelPurposeEnum[value.trim()] : '';
        if (index !== newValue.length - 1){
          finalTextForHistory = finalTextForHistory + ', ';
        }
      })
    return finalTextForHistory;
  }

  showCaseSubObject(caseObjectType: CaseObjectType, caseSubObjectType: CaseSubObjectType) {
    if (caseObjectType != null && caseSubObjectType != null) {
      return caseObjectType.toString() !== caseSubObjectType.toString();
    }
    return false;
  }

  getUserImage(userId: string) {
    if (this.caseHistoryUsers.length !== 0 && userId !== null) {
      const user = this.caseHistoryUsers.find(x => x.userId === userId);
      return user != null ? user.picture : '';
    } else {
      return '';
    }
  }

  getUserInitials(userId: string) {
    if (this.caseHistoryUsers.length !== 0 && userId !== null) {
      const user = this.caseHistoryUsers.find(x => x.userId === userId);
      return user != null ? user.initials : '';
    } else {
      return '';
    }
  }

  sortChanged(value: string): void {
    this.sortItem = value;
    this.sortChangeEvent.emit(value);
  }

  newConsentType(chy: CaseHistoryEntry): string {
    const datafieldConsent = chy.dataFieldChanges.filter(x => x.field === 'ConsentType');
    return datafieldConsent && datafieldConsent.length > 0 ? datafieldConsent[0].newValue : '';
  }

  getAutoCreationResult(data: CaseHistoryPropertyChangedData): string {
    return data.value.result;
  }

  getCustomerProfileName(data: CaseHistoryPropertyChangedData): string {
    return data.value.customerProfileName;
  }

  getInsurance(data: CaseHistoryPropertyChangedData) {
    return this.personInsuranceAdapter.adapt(data.value.insurance);
  }

  getPolicyMatchCriteria(data: CaseHistoryPropertyChangedData): PossibleMatches[] {
    return this.getInsurance(data).insuranceLookupPolicy.possibleMatches;
  }

  getCustomerProfileMatchCriteria(data: CaseHistoryPropertyChangedData): string[] {
    return Object.keys(data.value).filter(value => value !== 'customerProfileName');
  }

  getPolicyNumber(data: CaseHistoryPropertyChangedData): string {
    const insurance = this.getInsurance(data);
    return insurance.creditCard ? insurance.creditCardNo : insurance.policyReference;
  }

  getCustomerProductName(data: CaseHistoryPropertyChangedData): string {
    return this.getInsurance(data).insuranceLookupPolicy.product.customerProductName;
  }

  getInsuranceLevel(data: CaseHistoryPropertyChangedData): string {
    const insurance = this.getInsurance(data);
    return insurance.insuranceLookupPolicy.insuranceLevel;
  }

  getInsuranceCoverageDays(data: CaseHistoryPropertyChangedData): number {
    const insurance = this.getInsurance(data);
    return insurance.insuranceLookupPolicy.product.coveragePeriodDays;
  }

  getInsuranceCoverageWeeks(data: CaseHistoryPropertyChangedData): number {
    const insurance = this.getInsurance(data);
    return insurance.insuranceLookupPolicy.product.coveragePeriodWeeks;
  }

  getInsuranceCoverageArea(data: CaseHistoryPropertyChangedData): string {
    const insurance = this.getInsurance(data);
    return insurance.insuranceLookupPolicy.product.geographicAreas.toString();
  }

  getSosId(data: CaseHistoryPropertyChangedData): string {
    return this.getInsurance(data).insuranceLookupPolicy.product.sosProductId;
  }

  getVersion(data: CaseHistoryPropertyChangedData): string {
    return this.getInsurance(data).insuranceLookupPolicy.product.version;
  }

  getLookupExecutionDetails(data: CaseHistoryPropertyChangedData): string {
    const insurance = this.getInsurance(data);
    const date = insurance.insuranceLookupPolicy.lookupExecution.lookupTime.format('DD MMM YYYY HH:mm');
    const type = this.translateService.instant('policy-lookup-method-type-' + insurance.insuranceLookupPolicy.lookupExecution.type);
    const name = insurance.insuranceLookupPolicy.lookupExecution.sourceInfo
      ? '/' + insurance.insuranceLookupPolicy.lookupExecution.sourceInfo
      : '';
    return `${date} (${type}${name})`;
  }

  getOld(data: CaseHistoryEntry, property: string): string {
    return data.dataFieldChanges.find(change => change.field === property).oldValue;
  }
  getNew(data: CaseHistoryEntry, property: string): string {
    return data.dataFieldChanges.find(change => change.field === property).newValue;
  }

  isCaseUpdatedWithWeb(data: CaseHistoryEntry): boolean {
    this.webChannel = data.dataFieldChanges?.find(field => field.field === 'Channel')?.newValue;
    return !!this.webChannel;
  }

  getStakeholderById(stakeholderId: number): string {
    if (stakeholderId) {
      const simpleCaseStakeholder = this.case.caseStakeholders.find(stakeholder => stakeholder.id === stakeholderId);
      if (simpleCaseStakeholder?.isCompany) {
        return simpleCaseStakeholder.companyName;
      } else if (simpleCaseStakeholder) {
        return simpleCaseStakeholder.firstName + ' ' + (simpleCaseStakeholder.surname ? simpleCaseStakeholder.surname : '');
      }
    }
    return '';
  }

  getField(dataFieldChanges: CaseHistoryDataFieldChangeDto[], fieldType: string): CaseHistoryDataFieldChangeDto {
    return dataFieldChanges.find(field => field.field === fieldType);
  }

  getDeletedField(dataFieldChanges: CaseHistoryDeletedFieldValueDto[], fieldType: string): CaseHistoryDeletedFieldValueDto {
    return dataFieldChanges.find(field => field.deletedField === fieldType);
  }

  getDeletedFieldValueTranslated(dataFieldChanges: CaseHistoryDeletedFieldValueDto[], fieldType: string): string {
    const field = dataFieldChanges.find(field => field.deletedField === fieldType);
    if (!!field && !!field.deletedValue) {
      return this.translateService.instant('case-history-' + field.deletedValue);
    }

    return '';
  }

  getChannelTypeName(channelTypeEnumValue: string) {
    return this.channelTypes?.find(c => c.channelTypeEnumValue === channelTypeEnumValue)?.channelTypeName;
  }

}
