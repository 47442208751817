import { CdkDragEnd, CdkDragRelease, CdkDragStart } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import { LegacyDialogPosition as DialogPosition, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { SettingsService } from '@secca/core/services/settings.service';

@Injectable({
  providedIn: 'root',
})
export class DialogBoundryService {
  readonly MAX_PARENT_TRAVERSE_CNT = 10;

  readonly DIALOG_MIN_VISIBLE_TOP_HEIGHT           = 0;
  readonly DIALOG_MIN_VISIBLE_BOTTOM_HEIGHT        = 60;
  readonly DIALOG_MIN_VISIBLE_BOTTOM_HEIGHT_ZOOMED = 200;
  readonly DIALOG_MIN_VISIBLE_RIGHT_WIDTH          = 50;
  readonly DIALOG_MIN_VISIBLE_LEFT_WIDTH           = 170;

  private beforeDragPosition: any;

  constructor(private matDialog: MatDialog, private settingsService: SettingsService) { }

  // @deprecated This method should not be used, instead use handleDragStart, handleDragEnd
  public handleDragReleased(e: CdkDragRelease): void {
  }

  public handleDragStart(e: CdkDragStart): void {
    const containerElement = this.findParentElementByClass(e.source.element.nativeElement, 'mat-dialog-container');
    if ( containerElement ) {
      this.beforeDragPosition = this.getOffset(containerElement);
    }
  }

  public handleDragEnd(e: CdkDragEnd): void {
    const containerElement = this.findParentElementByClass(e.source.element.nativeElement, 'mat-dialog-container');
    if ( containerElement ) {
      const dialogId = containerElement.id;
      const dialogRef: MatDialogRef<any> = this.matDialog.getDialogById(dialogId);

      const dragPosition = {
        x: this.beforeDragPosition.x + e.distance.x,
        y: this.beforeDragPosition.y + e.distance.y
      };

      const dialogPosition = this.getDialogPosition(e.source.element.nativeElement, dragPosition);

      dialogRef.updatePosition(dialogPosition);
      e.source.reset();

      this.beforeDragPosition = null;
    }
  }

  public isPositionOutsideBoundry(element, position, containerElement?, dragAreaElement?): boolean {
    if ( !containerElement ) {
      containerElement = this.findParentElementByClass(element, 'mat-dialog-container');
    }

    if ( !dragAreaElement ) {
      dragAreaElement = this.findDragAreaElement(element);
    }
    if ( containerElement ) {
      if ( position.x + containerElement.offsetWidth < this.DIALOG_MIN_VISIBLE_LEFT_WIDTH ) {
        return true;
      }
      else if ( position.x > window.innerWidth - 20 ) {
        return true;
      }
      else if ( position.y + (dragAreaElement ? dragAreaElement.offsetHeight - 10 : 0) < 0 ) {
        return true;
      }
      else if ( position.y > window.innerHeight - 10 ) {
        return true;
      }
    }
  }

  public getDialogPosition(element, dragPosition, containerElement?, dragAreaElement?): any {
    if ( !containerElement ) {
      containerElement = this.findParentElementByClass(element, 'mat-dialog-container');
    }

    if ( !dragAreaElement ) {
      dragAreaElement = this.findDragAreaElement(element);
    }    const dialogPosition: DialogPosition = {};

    if ( containerElement ) {

      if ( dragPosition.x + containerElement.offsetWidth < this.DIALOG_MIN_VISIBLE_LEFT_WIDTH ) {
        dialogPosition.left = (-containerElement.offsetWidth + this.DIALOG_MIN_VISIBLE_LEFT_WIDTH) + 'px';
      }
      else if ( dragPosition.x > window.innerWidth - 20 ) {
        dialogPosition.right = (-containerElement.offsetWidth + this.DIALOG_MIN_VISIBLE_RIGHT_WIDTH) + 'px';
      }
      else {
        if ( dragPosition.x > window.innerWidth - containerElement.offsetWidth ) {
          dialogPosition.right = (window.innerWidth - (dragPosition.x + containerElement.offsetWidth)) + 'px';
        }
        else {
          dialogPosition.left = dragPosition.x + 'px';
        }
      }

      if ( dragPosition.y + (dragAreaElement ? dragAreaElement.offsetHeight - 10 : 0) < 0 ) {
        dialogPosition.top = this.DIALOG_MIN_VISIBLE_TOP_HEIGHT + 'px';
      }
      else if ( (dragPosition.y + (this.settingsService.isZoomed() ? this.DIALOG_MIN_VISIBLE_BOTTOM_HEIGHT_ZOOMED : 0)) > window.innerHeight - 10 ) {
        dialogPosition.bottom = (-containerElement.offsetHeight + (this.settingsService.isZoomed() ? this.DIALOG_MIN_VISIBLE_BOTTOM_HEIGHT_ZOOMED : this.DIALOG_MIN_VISIBLE_BOTTOM_HEIGHT)) + 'px';
      }
      else {
        dialogPosition.top = dragPosition.y + 'px';
      }
    }

    return dialogPosition;
  }

  private findDragAreaElement(element) {
    return element.querySelector('.dialog-drag-area');
  }

  private findParentElementByClass(element, componentClass) {
    for (let i = 0; i < this.MAX_PARENT_TRAVERSE_CNT; i++) {
        if ( !element ) {
          break;
        }

        if (element?.classList?.contains(componentClass)) {
            return element;
        }

        element = element.parentNode;
    }

    return null;
  }

  private getOffset(el): any {
    const rect = el.getBoundingClientRect();
    return {
      x: rect.left + window.scrollX,
      y: rect.top + window.scrollY
    };
  }
}
