<div class="input">
  <span [ngClass]="dateTime.invalid ? 'invalid-input-name' : 'input-name'" *ngIf="name && name != ''">{{ name | translate }}</span>
  <div [ngClass]="dateTime.invalid ? 'd-flex invalid-input-box' : 'd-flex input-box'">
    <div class="input-non-ranged">
      <img
        *ngIf="withImage"
        src="../../../../assets/icons/Calendar.svg"
        class="input-image-calender"
        [owlDateTimeTrigger]="dt1"
      />
      <input
        [owlDateTime]="dt1"
        spellcheck="false"
        [ngModel]="owlMoment"
        placeholder="{{ placeholder }}"
        [selectMode]="'single'"
        [ngClass]="[markWithError ? 'error-input-date' : (dateTime.invalid || invalid ? 'invalid-input-date' : 'input-date')]"
        [class.required]="showRequired && !disabled"
        [class.recommended]="showRecommended && !disabled"
        [class.input-non-ranged-highlight-border]="highlightBorderOnInputNonEmpty && !!owlMoment && !!dateTime && !dateTime.invalid && !invalid && !disabled"
        [disabled]="disabled"
        [min]="minMoment"
        [max]="maxMoment"
        (dateTimeChange)="onPickerDateChange($event)"
        (focus)="onFocus($event)"
        (blur)="onBlur()"
        #inputfield
        #dateTime="ngModel"
      />
      <owl-date-time
        [disabled]="disabled"
        [pickerType]="'both'"
        #dt1
        [firstDayOfWeek]="1"
        [startAt]="owlMoment"
      ></owl-date-time>
    </div>
  </div>
</div>
