  <div class="limitsContainer">
    <div class="itemContainer">
      <div class="bigCol top">Description</div>
      <div class="bigCol top">Type</div>
      <div class="smallCol top">Value</div>
      <div class="bigCol top">Unit</div>
    </div>
    <div class="itemContainer" *ngFor="let limit of limits">
      <div class="bigCol">{{ limit.limitDescription }}</div>
      <div class="bigCol"> {{ "coverages-" + limit.limitType | translate  }}</div>
      <div class="smallCol"> {{ limit.limitMaxValue }} </div>
      <div class="bigCol">
        <span *ngIf="limit.limitType === getLimitTypeEnum.AMOUNT">
          {{ limit.limitUnit }}
        </span>
        <span *ngIf="limit.limitType != getLimitTypeEnum.AMOUNT">
          {{ "coverages-" + limit.limitUnit | translate  }}
          <span *ngIf="limit.limitType === getLimitTypeEnum.DURATION">(From {{ "coverages-" + limit.limitValue | translate  }})</span>
        </span>
      </div>
    </div>
    <div class="closeButton">
      <img (click)="close();" src="../../../../assets/icons/Close.svg" height="15px" />
    </div>
  </div>
