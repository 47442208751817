<div class="input-valuta">
  <span class="input-valuta-name">{{name}}</span>
</div>
<div>
  <span class="input-valuta-value">{{currency}}</span>
  <input class="input-valuta-component" inputPattern [inputTypePattern]="inputTypePattern" (focusin)="focusElement($event)" 
    [maxLength]="maxTextLength" [class.required-red-background]="required && !isValueAccepted" [(ngModel)]="value"
    (ngModelChange)="onChangeValue()"
    [placeholder]="placeholder" [disabled]="disabled"
    mask="separator.2" [thousandSeparator]="getThousandSeparator()" [decimalMarker]="getDecimalMarker()" />
</div>
