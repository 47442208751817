<div *ngIf="entry" class="output-management-entry action-button">
  <div class="output-management-entry-header">
    <div *ngIf="entry.caseHistoryType === CaseHistoryType.PROCESS_GOP_REQUEST_CREATE">
      <span>
        <img src="/assets/icons/task/process-case-summary.svg">
      </span>
      <span *ngIf="stakeholder" class="output-management-entry-header-direction"><img class="stakeholder-icon" [src]="stakeholderIcon"></span>
      <span *ngIf="stakeholder"> {{stakeholderName}}</span>
    </div>
    <div *ngIf="entry.caseHistoryType === CaseHistoryType.CASE_CREATE">
      <div *ngIf="isCaseUpdatedWithWeb(entry.dataFieldChanges); else newCaseReported">
      <span>Case updated with <span
        class="values-blue-color">{{ getChannelTypeName(webChannel) }}</span> task</span>
      </div>
      <ng-template #newCaseReported>
      <span>Case reported by <span
        class="values-blue-color">{{ getChannelTypeName(case.channelType) }}</span></span>
      </ng-template>
    </div>
  </div>
  <div *ngIf="entry.caseHistoryType === CaseHistoryType.PROCESS_GOP_REQUEST_CREATE">
    <div class="output-management-entry-line"></div>
    <div class="output-management-entry-value">{{"case-summary-gop-request-from-supplier" | translate }}</div>
  </div>
  <div>
    <div class="action-button-menu-button" (click)="showEntryMenu=!showEntryMenu" (clickOutside)="showEntryMenu=false"
         *ngIf="showActionButton && hasTaskId && !deleted">
      <div class="action-button-menu-button-dot"></div>
      <div class="action-button-menu-button-dot"></div>
      <div class="action-button-menu-button-dot"></div>
    </div>
    <div class="action-button-menu" *ngIf="showEntryMenu">
      <div class="action-button-menu-position" (click)="viewMenuClick()">{{"case-summary-external-view" | translate}}</div>
    </div>
    <div class="entry-deleted" *ngIf="deleted">
      <span class="redText">Deleted</span>
    </div>

  </div>
</div>
