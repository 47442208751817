import * as moment from 'moment';
/*
 Deserialzer exclusive to retrieve moments serialized in session storage.
 Session storage differs from backened in that it cannot serialize in specific format. 
*/
export class SessionStorageDateDeserializer {
  private static ISO_LOCAL_DATE_TIME = 'YYYY-MM-DDTHH:mm:ss.SSS';

  static deserialize(dayStr: string): moment.Moment {
    if (dayStr) {
      return moment.utc(dayStr, this.ISO_LOCAL_DATE_TIME);
    }
    return null;
  }
}
