<div class="GOP-container">
  <ng-template #popContent><span [innerHTML]="sendPopUpText | safeHtml"></span></ng-template>
  <button [class.primary-button-inactive]="disableSendButton" class="primary-button send-button" [disabled]="disableSendButton" (click)="openMessageModal()">
    <div [disablePopover]="disablePopover" triggers="hover" [ngbPopover]="popContent">
      {{ sendText | translate }}
    </div>
  </button>

  <button [class.primary-button-inactive]="!isValid || serviceOrderTypeState.gopPreviewWasClicked" class="primary-button create-button" [disabled]="!isValid || serviceOrderTypeState.gopPreviewWasClicked"
    (click)="preview()">
    <div [disablePopover]="disablePreviewPopover" triggers="hover" [ngbPopover]="'service-order-missing-date-of-birth' | translate">
      {{ previewText | translate}}
    </div>
  </button>
</div>
