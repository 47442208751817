import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CaseCommunicationService } from "@secca/core/services/case-communication.service";
import { CaseHistoryType } from "@secca/shared/enums/case-history-type";
import { CaseEmail } from "@secca/shared/models/case-email";
import { CaseHistoryCommEntry } from "@secca/shared/models/caseHistoryEntry";

@Component({
  selector: 'app-case-summary-email-fax-modal',
  templateUrl: './case-summary-email-fax-modal.component.html',
  styleUrls: ['./case-summary-email-fax-modal.component.scss'],
})
export class CaseSummaryEmailFaxModalComponent implements OnInit {
  @Input()
  entry: CaseHistoryCommEntry;

  @Output()
  closed: EventEmitter<void> = new EventEmitter();

  caseEmail: CaseEmail;
  internalSummaryBeforeChange: string;

  constructor(private caseCommunicationService: CaseCommunicationService) {}

  ngOnInit() {
    this.internalSummaryBeforeChange = this.entry.updatedInternalSummary;

    this.loadData();
  }

  saveCaseSummary() {
    this.caseEmail.internalSummary = this.entry.updatedInternalSummary;
    if (this.isEmail()) {
      this.caseEmail.emailId = this.entry.emailId;
      this.caseCommunicationService.updateCaseEmail(this.caseEmail).subscribe(
        result => {
          this.internalSummaryBeforeChange = result.internalSummary;
          this.close();
        }
      );
    } else {
      this.caseEmail.faxId = this.entry.faxId;
      this.caseCommunicationService.updateCaseFax(this.caseEmail).subscribe(
        result => {
          this.internalSummaryBeforeChange = result.internalSummary;
          this.close();
        }
      );
    }
  }

  close(): void {
    this.closed.emit();
  }

  isEmail(): boolean {
    return (
      this.entry.caseHistoryType === CaseHistoryType.EMAIL ||
      this.entry.caseHistoryType === CaseHistoryType.EMAIL_RECEIVE ||
      this.entry.caseHistoryType === CaseHistoryType.EMAIL_UPDATE
    );
  }

  private loadData() {
    this.caseCommunicationService.getEmailBody(this.isEmail() ? this.entry.emailId : this.entry.faxId).subscribe(
      result => {
        this.entry.content = result;
      },
      error => console.log(error)
    );
    this.isEmail() ? this.getCaseEmail() : this.getCaseFax();
  }

  private getCaseEmail() {
    this.caseCommunicationService.getCaseEmail(this.entry.emailId).subscribe(
      result => {
        this.caseEmail = result;
      },
      error => console.log(error)
    );
  }

  private getCaseFax() {
    this.caseCommunicationService.getCaseFax(this.entry.faxId).subscribe(
      result => {
        this.caseEmail = result;
      },
      error => console.log(error)
    );
  }

  get mailDate() {
    if (this.caseEmail?.receivedDate) {
      return this.caseEmail.receivedDate;
    } else {
      return this.entry?.actionTime;
    }
  }

  get internalSummaryHasBeenUpdated() {
    if (this.entry == null) {
      return false;
    }
    return this.entry.updatedInternalSummary !== this.internalSummaryBeforeChange;
  }
}
