<div class="attachment">
  <div class="row attachment-info">
    <div class="col-4" *ngIf="attachment?.documentFilename">
      <app-input [name]="'task-message-attachment-file-name' | translate" [value]="attachment.documentFilename" [disabled]="true">
      </app-input>
    </div>
    <div class="col-4" *ngIf="caseDocument">
      <div [appDisableInput]="caseStateService.isCaseDisabled() || disabled">
        <app-multiple-select-dropdown [name]="'task-message-attachment-tags' | translate" [dropdownList]="documentsTag"
                                      [(selectedItems)]="caseDocument.documentTags" (selectedItemsChange)="updateDocument()">
        </app-multiple-select-dropdown>
      </div>
    </div>
    <div class="col-4" *ngIf="caseDocument">
      <div [appDisableInput]="caseStateService.isCaseDisabled()">
        <app-input [name]="'task-message-attachment-comments' | translate" [withImage]="false" [(value)]="caseDocument.comments"
                  (saveModel)="updateDocument()"></app-input>
      </div>
    </div>
  </div>
  <div class="attachment-filescan">
    <div *ngIf="!showPreview" class="attachment-filescan">
      <div class="attachment-filescan-container">
        <div class="filescan-modal">
          <div class="filescan-header">
            <div class="text-center">Attention!</div>
          </div>
          <div class="filescan-body">
            <div *ngIf="!attachment.fileScan?.scanned">
              {{'file-scan-not-scanned-1' | translate}}<br>
              {{'file-scan-not-scanned-2' | translate}}
            </div>
            <div *ngIf="attachment.fileScan?.hit">
              {{'file-scan-hit-1' | translate}}<br>
              {{'file-scan-hit-2' | translate}}
            </div>
            <div class="virus-information-icon">
              <div [class]="attachment.fileScan?.hit ? 'virus-icon' : 'warning-icon'"></div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div *ngIf="showPreview" class="attachment-preview-container">
      <div *ngIf="loading && !askForPDFPassword" class="loading-in-progress">
        {{"task-message-attachment-loading" | translate}}
      </div>
      <img *ngIf="currentFileType === FileType.IMAGE" class="image-preview" [src]="sanitizedDocumentUrl">
      <div class="pdf-preview" *ngIf="currentFileType === FileType.PDF">
        <pdf-viewer logLevel="0" *ngIf="!askForPDFPassword && !loading" (error)="onPDFError($event)" [src]="arrayBufferFromBlob" [render-text]="true" style="width: 100%; height: 454px">
        </pdf-viewer>
        <div *ngIf="askForPDFPassword" class="ask-for-password container">
          <div class="ask-for-password-text">{{"task-message-attachment-password-protected" | translate}}</div>
          <div class="row">
            <div class="col-4">
              <app-input [name]="'task-message-attachment-password' | translate" [(value)]="pdfPassword" [inputType]="InputType.password">
              </app-input>
            </div>
            <div class="col-8 button-column">
              <button class="primary-button"
                      (click)="pdfPasswordProvided()">{{"task-message-attachment-ok" | translate}}</button>
            </div>
          </div>
          <div *ngIf="incorrectPDFPassword" class="incorrect-password">
            {{"task-message-attachment-incorrect-password" | translate }}
          </div>
        </div>
      </div>
      <div *ngIf="currentFileType === FileType.VIDEO">
        <video class="video-preview" controls *ngIf="sanitizedDocumentUrl">
          <source [src]="sanitizedDocumentUrl">
        </video>
      </div>
      <div *ngIf="currentFileType === FileType.HEIC">
        <div>
          <img class="image-preview" [src]="sanitizedHeicUrl">
        </div>
      </div>
      <div *ngIf="currentFileType === FileType.WORD">
        <div class="word-preview">
          <pdf-viewer logLevel="0" [src]="arrayBufferFromBlob" (after-load-complete)="pdfFromWordLoaded()" [render-text]="true" style="width: 100%; height: 454px">
          </pdf-viewer>
        </div>
      </div>
      <div *ngIf="currentFileType === FileType.HTML">
        <div class="html-preview" [innerHTML]="htmlDocument | safeHtml"></div>
      </div>
      <div *ngIf="currentFileType === FileType.UNKNOWN">
        <div class="unknown-preview">
          Download: <a (click)="downloadFile($event)" [href]="documentUrl">{{attachment?.documentFilename}}</a>
        </div>
      </div>
    </div>
  </div>
</div>
