import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {CaseConsent} from '@secca/shared/models/caseConsent';
import {DigitalConsentService} from '@secca/core/services/digital-consent.service';
import {ActivatedRoute} from '@angular/router';
import {AutoUnsubscribe} from '@secca/shared/decorators/auto-unsubscribe';
import {CaseStateService} from '@secca/core/state-services/case-state.service';
import {DigitalConsent} from '@secca/shared/models/digital-consent';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ConsentRequestEnum, ConsentStatusEnum, MessageChannelType, ShortcutEnum} from '@secca/shared/models/enums';
import {ModalDialogComponent} from '@secca/shared/components/modal-dialog/modal-dialog.component';
import {ModalDialogConfiguration} from '@secca/shared/models/modal/modal-dialog-configuration';
import {ProviderHelper} from '@secca/shared/helpers/provider-helper';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CaseLockHelperService} from '@secca/core/services/case-lock-helper.service';
import {OutputManagementService} from '@secca/case/components/case-output-management/services/output-management.service';
import {Message} from '@secca/case/components/case-output-management/models/message';
import {EndUserStateService} from '@secca/core/state-services/enduser-state-service';
import {ServiceOrderMessageRequest} from '@secca/shared/models/service-order/service-order-message-request';
import {CaseMessageService} from '@secca/core/services/case-message.service';
import {PermissionService} from '@secca/core/services/permission.service';
import {CaseStakeholder} from '@secca/shared/models/caseStakeholder';
import {FaxDto} from '@secca/shared/models/fax-dto';
import {PhoneDto} from '@secca/shared/models/phone-dto';
import {CaseService} from '@secca/core/services/case.service';
import {DropDownConsentComponent} from '@secca/case/components/case-consent/drop-down-consent/drop-down-consent.component';
import {ShortcutService} from '@secca/core/services/shortcut.service';

@Component({
  selector: 'app-case-consent',
  templateUrl: './case-consent.component.html',
  styleUrls: ['./case-consent.component.scss']
})
@AutoUnsubscribe
export class CaseConsentComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('dropDownConsentInstance') private dropDownConsentInstance: DropDownConsentComponent;

  consentData: CaseConsent[] = [];
  selectedConsent: CaseConsent;

  consentRequestLoading = false;

  @Input() digitalConsent: DigitalConsent;
  @Input() caseId: number;
  @Output() consentChanged: EventEmitter<DigitalConsent> = new EventEmitter();

  selectedCaseConsentData: DigitalConsent;
  legalGuardianId: string;
  preferredChannel: MessageChannelType;
  caseUpdatedSubscription$: Subscription;
  private providerHelper: ProviderHelper;

  $selectedMessageSubscr: Subscription;
  $stakeholderSubscr: Subscription;
  $legalGuardianSubscr: Subscription;
  $documentTagSubscr: Subscription;

  private shortcutSubscription: Subscription;

  constructor(private digitalConsentService: DigitalConsentService,
              private route: ActivatedRoute,
              private caseStateService: CaseStateService,
              private translate: TranslateService,
              private modalService: NgbModal,
              public caseLockHelperService: CaseLockHelperService,
              public permissionService: PermissionService,
              private outputManagementService: OutputManagementService,
              private endUserStateService: EndUserStateService,
              private caseMessageService: CaseMessageService,
              private caseService: CaseService,
              private shortcutService: ShortcutService) {
    this.shortcutSubscription = this.shortcutService.addShortcut({ keys: ShortcutEnum.CaseConsent }).subscribe(a => {
      if (this.selectedCaseConsentData?.includeConsent && !caseStateService.isCaseDisabled()) {
        this.dropDownConsentInstance.focus();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
   this.selectedCaseConsentData = this.digitalConsent;
   this.populateConsentDropdown();
  }

  ngOnInit() {
    this.caseUpdatedSubscription$ = this.caseStateService.getCase().subscribe(updatedCase => {
      this.loadLegalGuardianInfo();
    });

    this.$documentTagSubscr = this.caseStateService.documentTags.subscribe(e => {
      this.getDigitalConsentStatus();
    });

    this.$legalGuardianSubscr = this.caseStateService.legalGuardian.subscribe(e => {
      this.loadLegalGuardianInfo();
    });

    this.$stakeholderSubscr = this.caseStateService.stakeholder.subscribe((stakeholder: CaseStakeholder) => {
      if (stakeholder.properties.indexOf('LEGAL_GUARDIAN') > -1) {
        this.updateLegalGuardian(stakeholder);
      }
    });

    this.$selectedMessageSubscr = this.outputManagementService.selectedMessage.subscribe(message => {
      if (message.send === true && message.templateType === 'CONSENT' && message.consentRequest) {
        this.digitalConsentService.requestDigitalConsent(this.caseId, message.consentRequest).subscribe(result => {
          this.selectedCaseConsentData = result;
          this.consentChanged.emit(result);
          this.populateConsentDropdown();
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.shortcutSubscription.unsubscribe();
  }

  loadLegalGuardianInfo(){
    this.caseService.getCaseStakeholdersOnCase(this.caseId.toString()).subscribe(result => {
      result.forEach(stakeholder => {
        if (stakeholder.properties.indexOf('LEGAL_GUARDIAN') > -1) {
          this.updateLegalGuardian(stakeholder);
        }
      });
    });
  }

  updateLegalGuardian(stakeholder: CaseStakeholder){
    this.legalGuardianId = stakeholder.id;
    let preferredChannelCode;
    if (stakeholder.isCompany) {
        preferredChannelCode = stakeholder.company?.gop?.preferredChannelCode;
    } else {
      preferredChannelCode = stakeholder.person.preferredChannelCode;
    }
    this.preferredChannel = this.getMessageType(preferredChannelCode);
  }

  getDigitalConsentStatus() {
    this.digitalConsentService.getDigitalConsentStatus(this.caseId).subscribe(result => {
      this.selectedCaseConsentData = result;
      this.populateConsentDropdown();
      this.consentChanged.emit(result);
    });
  }

  private shouldIncludeAutoRequest(): boolean {
    if (this.selectedCaseConsentData) {
      if (!this.selectedCaseConsentData.consentType
        || this.selectedCaseConsentData.consentType === ConsentStatusEnum.UNKNOWN) {
        return true;
      }
    }
    return false;
  }

  private shouldIncludeManualRequest(): boolean {
    if (this.selectedCaseConsentData) {
      if (!this.selectedCaseConsentData.consentType
        || this.selectedCaseConsentData.consentType === ConsentStatusEnum.UNKNOWN
        || this.selectedCaseConsentData.consentType === ConsentStatusEnum.CONSENT_ACCEPTED_WEB
        || this.selectedCaseConsentData.consentType === ConsentStatusEnum.AUTO_FAILED) {
        return true;
      }
    }
    return false;
  }

  private shouldIncludeResendManually(): boolean {
    if (this.selectedCaseConsentData && this.selectedCaseConsentData.consentType) {
      if (this.selectedCaseConsentData.consentType !== null &&
        this.selectedCaseConsentData.consentType !== ConsentStatusEnum.UNKNOWN &&
        this.selectedCaseConsentData.consentType !== ConsentStatusEnum.CONSENT_ACCEPTED &&
        this.selectedCaseConsentData.consentType !== ConsentStatusEnum.CONSENT_ACCEPTED_MAN &&
        this.selectedCaseConsentData.consentType !== ConsentStatusEnum.CONSENT_ACCEPTED_WEB &&
        this.selectedCaseConsentData.consentType !== ConsentStatusEnum.AUTO_FAILED
      ) {
        return true;
      }
    }
    return false;
  }

  private shouldIncludeConsentAccepted(): boolean {
    if (this.selectedCaseConsentData && this.selectedCaseConsentData.consentType) {
      if (this.selectedCaseConsentData.consentType !== null &&
        this.selectedCaseConsentData.consentType !== ConsentStatusEnum.UNKNOWN &&
        this.selectedCaseConsentData.consentType !== ConsentStatusEnum.CONSENT_ACCEPTED &&
        this.selectedCaseConsentData.consentType !== ConsentStatusEnum.CONSENT_ACCEPTED_MAN &&
        this.selectedCaseConsentData.consentType !== ConsentStatusEnum.CONSENT_ACCEPTED_WEB) {
        return true;
      }
    }
    return false;
  }

  private shouldIncludeConsentNotPossible(): boolean {
    if (this.selectedCaseConsentData) {
      if (!this.selectedCaseConsentData.consentType
        || (this.selectedCaseConsentData.consentType !== ConsentStatusEnum.CONSENT_ACCEPTED &&
          this.selectedCaseConsentData.consentType !== ConsentStatusEnum.CONSENT_NOT_POSSIBLE)) {
        return true;
      }
    }
    return false;
  }

  private shouldIncludeConsentRejected(): boolean {
    if (this.selectedCaseConsentData && this.selectedCaseConsentData.consentType) {
      if (this.selectedCaseConsentData.consentType !== null &&
        this.selectedCaseConsentData.consentType !== ConsentStatusEnum.UNKNOWN &&
        this.selectedCaseConsentData.consentType !== ConsentStatusEnum.CONSENT_REJECTED) {
        return true;
      }
    }
    return false;
  }

  private shouldIncludeConsentTemporary(): boolean {
    if (this.selectedCaseConsentData) {
      if (!this.selectedCaseConsentData.consentType ||
        (this.selectedCaseConsentData.consentType !== ConsentStatusEnum.ORAL &&
          this.selectedCaseConsentData.consentType !== ConsentStatusEnum.CONSENT_ACCEPTED)) {
        return true;
      }
    }
    return false;
  }

  private setSelectedConsentItem() {
    if (!this.selectedCaseConsentData || !this.selectedCaseConsentData.consentType) {
      this.selectedConsent = new CaseConsent(this.translate.instant('case-basics-digital-consent-request-not-sent'),
        ConsentStatusEnum.CONSENT_REQUEST_NOT_SENT,
        null, true);
    } else {
      switch (this.selectedCaseConsentData.consentType) {
        case ConsentStatusEnum.CONSENT_NOT_POSSIBLE:
          this.selectedConsent = new CaseConsent(this.translate.instant('case-basics-digital-consent-not-possible'),
            ConsentStatusEnum.CONSENT_NOT_POSSIBLE,
            null, true);
          break;
        case ConsentStatusEnum.UNKNOWN:
          this.selectedConsent = new CaseConsent(this.translate.instant('case-basics-digital-consent-request-not-sent'),
            ConsentStatusEnum.CONSENT_REQUEST_NOT_SENT,
            null, true);
          break;
        case ConsentStatusEnum.REQUESTED:
        case ConsentStatusEnum.REQUESTED_MANUALLY_AGAIN:
        case ConsentStatusEnum.REQUESTED_MANUALLY:
          this.selectedConsent = new CaseConsent(this.translate.instant('case-basics-digital-consent-requested'),
            ConsentStatusEnum.REQUESTED,
            null, true);
          break;
        case ConsentStatusEnum.ORAL:
          this.selectedConsent = new CaseConsent(this.translate.instant('case-basics-digital-consent-temporary-oral-consent-given'),
            ConsentStatusEnum.ORAL,
            null, true);
          break;
        case ConsentStatusEnum.CONSENT_REJECTED:
          this.selectedConsent = new CaseConsent(this.translate.instant('case-basics-digital-consent-rejected'),
            ConsentStatusEnum.CONSENT_REJECTED,
            null, true);
          break;
        case ConsentStatusEnum.CONSENT_ACCEPTED:
        case ConsentStatusEnum.CONSENT_ACCEPTED_WEB:
          this.selectedConsent = new CaseConsent(this.translate.instant('case-basics-digital-consent-accepted'),
            ConsentStatusEnum.CONSENT_ACCEPTED,
            null, true);
          break;
        case ConsentStatusEnum.CONSENT_ACCEPTED_MAN:
          this.selectedConsent = new CaseConsent(this.translate.instant('case-basics-digital-consent-accepted'),
            ConsentStatusEnum.CONSENT_ACCEPTED_MAN,
            null, true);
          break;
        case ConsentStatusEnum.AUTO_FAILED:
          this.selectedConsent = new CaseConsent(this.translate.instant('case-basics-digital-consent-failed'),
            ConsentStatusEnum.AUTO_FAILED,
            null, true);
          break;
        default:
          this.selectedConsent = new CaseConsent(this.translate.instant('case-basics-digital-consent-request-not-sent'),
            ConsentStatusEnum.CONSENT_REQUEST_NOT_SENT,
            null, true);
      }
    }
  }


  private populateConsentDropdown() {
    this.consentData = [];

    if (this.shouldIncludeAutoRequest()) {
      this.consentData.push(new CaseConsent(this.translate.instant('case-basics-digital-consent-request-automatic'),
        null,
        ConsentRequestEnum.AUTO, false, this.selectedCaseConsentData.hasLegalGuardian,
        this.selectedCaseConsentData.hasLegalGuardianSMS, this.selectedCaseConsentData.hasLegalGuardian,
        this.selectedCaseConsentData.hasCustomerProfile));
    }
    if (this.shouldIncludeManualRequest()) {
      this.consentData.push(new CaseConsent(this.translate.instant('case-basics-digital-consent-request-manually'),
        null,
        ConsentRequestEnum.MANUALLY, null, null, true, this.selectedCaseConsentData.hasLegalGuardian, this.selectedCaseConsentData.hasCustomerProfile));
    }
    if (this.shouldIncludeResendManually()) {
      this.consentData.push(new CaseConsent(this.translate.instant('case-basics-digital-consent-resend-request-manually'),
        null,
        ConsentRequestEnum.RESEND_MANUALLY, null, null, null, this.selectedCaseConsentData.hasLegalGuardian, this.selectedCaseConsentData.hasCustomerProfile));
    }
    if (this.shouldIncludeConsentAccepted()) {
      this.consentData.push(new CaseConsent(this.translate.instant('case-basics-digital-consent-accepted'),
        ConsentStatusEnum.CONSENT_ACCEPTED_MAN,
        ConsentRequestEnum.CONSENT_ACCEPTED_MAN, null, null, null, this.selectedCaseConsentData.hasLegalGuardian));
    }
    if (this.shouldIncludeConsentRejected()) {
      this.consentData.push(new CaseConsent(this.translate.instant('case-basics-digital-consent-rejected'),
        ConsentStatusEnum.CONSENT_REJECTED,
        ConsentRequestEnum.CONSENT_REJECTED, null, null, null, this.selectedCaseConsentData.hasLegalGuardian));
    }
    if (this.shouldIncludeConsentTemporary()) {
      this.consentData.push(new CaseConsent(this.translate.instant('case-basics-digital-consent-temporary-oral-consent-given'),
        ConsentStatusEnum.ORAL,
        ConsentRequestEnum.ORAL, null, null, true, this.selectedCaseConsentData.hasLegalGuardian, this.selectedCaseConsentData.hasCustomerProfile));
    }
    if (this.shouldIncludeConsentNotPossible()) {
      this.consentData.push(new CaseConsent(this.translate.instant('case-basics-digital-consent-not-possible'),
        ConsentStatusEnum.CONSENT_NOT_POSSIBLE,
        ConsentRequestEnum.CONSENT_NOT_POSSIBLE, null, null, null, this.selectedCaseConsentData.hasLegalGuardian));
    }
    this.setSelectedConsentItem();
  }

  private getMessageType(preferredChannel?: String): MessageChannelType {
    if (!preferredChannel) {
      return MessageChannelType.EMAIL;
    }
    switch (preferredChannel) {
      case "Fax":
        return MessageChannelType.FAX;
      case "Email":
        return MessageChannelType.EMAIL;
      case "Fixed phone":
      case "Mobile phone":
      case "SMS":
        return MessageChannelType.SMS;
      default:
        return MessageChannelType.EMAIL;
    }
  }

  onSelectItem(caseConsent: CaseConsent) {
    if (caseConsent.consentRequest === ConsentRequestEnum.ORAL ||
      caseConsent.consentRequest === ConsentRequestEnum.CONSENT_NOT_POSSIBLE ||
      caseConsent.consentRequest === ConsentRequestEnum.CONSENT_REJECTED) {
      this.openModal();
    } else if (caseConsent.consentRequest === ConsentRequestEnum.MANUALLY ||
      caseConsent.consentRequest === ConsentRequestEnum.RESEND_MANUALLY) {
      this.outputManagementService.openMessageWindow(this.caseId.toString(), this.createOutputManagementMessage(caseConsent.consentRequest));
      this.setSelectedConsentItem();
    } else if (caseConsent.consentRequest){
      this.consentRequestLoading = true;
      this.digitalConsentService.requestDigitalConsent(this.caseId, caseConsent.consentRequest).subscribe(result => {
          this.selectedCaseConsentData = result;
          this.consentChanged.emit(result);
          this.populateConsentDropdown();
        },
        error => {
          this.openProviderModal(error);
          this.consentRequestLoading = false;
          this.setSelectedConsentItem();
        }, () => this.consentRequestLoading = false);
    }
  }

  createOutputManagementMessage(consentRequest: ConsentRequestEnum.MANUALLY | ConsentRequestEnum.RESEND_MANUALLY): ServiceOrderMessageRequest {
    const msg = new Message({
      messageChannelType: this.getMessageType(this.preferredChannel),
      channelDisabled: false,
      stakeholderReceiverId: +this.legalGuardianId,
      templateType: 'CONSENT',
      legalGuardianOnly: true,
      caseId: this.caseId.toString(),
      fax: new FaxDto(),
      sms: new PhoneDto(),
      consentRequest: consentRequest
    });


    return new ServiceOrderMessageRequest({ message: msg, messageService: this.caseMessageService, isConsent: true });
  }

  onOpen() {
    this.getDigitalConsentStatus();
    this.populateConsentDropdown();
  }

  openModal() {
    const consentRequest = this.selectedConsent.consentRequest;
    const modalRef = this.modalService.open(ModalDialogComponent);
    modalRef.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'header-text-consent',
      title: '',
      text: '',
      footer: '',
      yes: '',
      no: '',
      isBody: false,
      isFooter: true,
      showTextArea: true,
      showYesNoButton: false,
      saveandclose: 'save-and-close-oral-consent',
      placeholderText: 'text-inside-no-sensitive-data-text',
      labelTextAboveTextArea: 'label-text-oral-consent'
    });

    modalRef.componentInstance.saveandcloseEvent.subscribe(
      emittedEvent => {
        this.closeModal();
        if (consentRequest) {
          this.digitalConsentService.requestDigitalConsent(this.caseId, consentRequest, emittedEvent).subscribe(result => {
            this.selectedCaseConsentData = result;
            this.consentChanged.emit(result);
            this.populateConsentDropdown();
          }, error => {
            this.openProviderModal(error);
          });
        }
      },
      error => {
        this.openProviderModal(error);
      }
    );

    modalRef.componentInstance.closeModalEvent.subscribe(
      emittedEvent => {
        modalRef.close();
      },
      err => console.log(err)
    );
  }

  closeModal() {
    this.modalService.dismissAll();
  }


  private openProviderModal(error: any) {
    this.providerHelper = new ProviderHelper(this.modalService);
    this.providerHelper.messageError(
      error,
      'consent-error-modal-header', 'consent-error-title', 'consent-error-message');
  }

}

