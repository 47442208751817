<div class="modal-content">
  <div class="modal-header">
    <div class="modal-header-title">
      {{ 'case-document-table-delete-document' | translate }}
    </div>
    <div class="modal-buttons">
      <div (click)="close(false)">
        <img src="/assets/icons/Close White.svg" />
      </div>
    </div>
  </div>
  <div class="body-1 modal-body">
    <div class="modal-text-title">
      {{ 'case-document-delete-document-title-delete' | translate}}
      <span class="document-name">{{documentName}}</span>
    </div>
    <div class="modal-text">
      {{ 'case-document-delete-document-text' | translate}}
    </div>
    <div class="modal-buttons">
      <button class="no" (click)="close(false)">
        {{ "default-modal-dialog-no" | translate}}
      </button>
      <button class="yes" (click)="close(true)">
        {{ "default-modal-dialog-yes" | translate}}
      </button>
    </div>
  </div>
</div>
