<div class="sub-component-container" *ngIf="subGopDetails">
  <div class="sub-title">{{"sub-gop-details-title" | translate}}</div>
  <div class="sub-content-container">
    <div class="row">
      <div class="col-6">
        <span class="sub-name">{{ 'sub-gop-details-items-in-gop' | translate}}</span>
        <textarea class="gop-text" maxlength="1000" [(ngModel)]="subGopDetails.itemsInGop" [placeholder]="itemsInGopPlaceholder"></textarea>
      </div>
      <div class="col-6">
        <span class="sub-name">{{ 'sub-gop-details-items-not-in-gop' | translate}}</span>
        <textarea class="gop-text" maxlength="1000" [(ngModel)]="subGopDetails.itemsNotInGop"
          placeholder='{{itemsNotInGopPlaceholder}}'></textarea>
      </div>
    </div>
    <div class="row">
      <div class="col-8">
        <span class="sub-name">{{'sub-gop-details-gop-template' | translate}}</span>
        <div class="common-drop-down-arrow common-drop-down">
          <ng-select [class.required-red-background]="!subGopDetails.templateName" [clearable]="false" [items]="templateNames" [(ngModel)]="subGopDetails.templateName" class="custom"
            (change)="selectTemplateLanguages($event)">
            <ng-template ng-label-tmp let-item="item">
              <div>
                {{ item }}
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="col-4">
        <span class="sub-name">{{'sub-gop-details-gop-template-language' | translate}}</span>
        <div class="common-drop-down-arrow common-drop-down">
          <ng-select [class.required-red-background]="!subGopDetails.templateName" [clearable]="false" [items]="templateLanguages" [(ngModel)]="subGopDetails.templateLanguage" class="custom"
          (change)="selectTemplateId($event)">
            <ng-template ng-label-tmp let-item="item">
              <div>
                {{ item }}
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>
  </div>
</div>
