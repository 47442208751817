import { Adapter } from '../interfaces/adapter';
import { Injectable } from '@angular/core';

export class TeamDto {
    id: number;
    adtCode: string;
    teamId: string;
    departmentId: string;
    teamName: string;
    teamNameAlt: string;
    mindKeyIds: string;
    ciscoTeamId: string;
    caseHandler: boolean;

    public constructor(init?: Partial<TeamDto>) {
        Object.assign(this, init);
    }
}
@Injectable({
    providedIn: 'root'
})
export class TeamDtoAdapter implements Adapter<TeamDto> {
    public adapt(item: any): TeamDto {
        return new TeamDto({
            id: item.id,
            adtCode: item.adtCode,
            teamId: item.teamId,
            departmentId: item.departmentId,
            teamName: item.teamName,
            teamNameAlt: item.teamNameAlt,
            mindKeyIds: item.mindKeyIds,
            ciscoTeamId: item.ciscoTeamId,
            caseHandler: item.caseHandler
        });
    }
}
