<div class="filter-container">
  <div class="d-flex">
    <div>
      <div class="drop-down-container-left">
        <span class="input-name">{{ 'case-summary-filter-objects' | translate }}</span>
        <app-drop-down [translatePipePath] ="'case-objects-'" [items]="objectItems" [(selectedItemId)]="searchFilter.objectItem"
                       (selectedItemIdChange)="selectedObjectItemChange()"
                       placeholder="{{'case-objects-all' | translate}}">
        </app-drop-down>
      </div>
    </div>
    <div>
      <div class="drop-down-container-left">
        <span class="input-name">{{ 'case-summary-filter-subobjects' | translate }}</span>
        <div [appInputFocus]="subObjectFocus">
          <app-drop-down [translatePipePath] ="'case-sub-objects-'" [items]="subObjectItems" [(selectedItemId)]="searchFilter.subObjectItems"
                        [closeOnSelect]="false" [multiSelectedLabel]="'some-selected'"
                        [multiple]="true" placeholder="{{'case-objects-all' | translate}}" (close)="selectedSubObjectClose()">
          </app-drop-down>
        </div>
      </div>
    </div>
    <div>
      <div class="input-calender">
        <span class="input-name">{{ 'case-summary-filter-date' | translate }}</span>
        <app-input-calender [withImage]="true" [selectMode]="'range'" (saveModel)="selectedDateRange()" [(dateTimeRange)]="searchFilter.dateRange"></app-input-calender>
      </div>
    </div>
    <div>
      <div class="drop-down-container-right">
        <span class="input-name">{{ 'case-summary-filter-user' | translate }}</span>
        <app-drop-down-users-static [items]="users" [(selectedUserDtos)]="searchFilter.users" (close)="selectedCaseHandlerChange()"
                                    [multiple]="true" [closeOnSelect]="true" placeholder="{{'case-summary-users-all' | translate}}"
                                    [multiSelectedLabel]="'case-summary-users-some-selected'" ></app-drop-down-users-static>
      </div>
    </div>
    <div class="search-div">
      <div class="row column-flexible">
        <div class="search-container">
          <span class="input-name">{{ 'case-summary-filter-search' | translate }}</span>
          <app-input [withImage]="true" [placeholder]="'Search'" (saveModel)="typedInText()" [(value)]="searchFilter.searchText">
          </app-input>
        </div>
      </div>
    </div>
    <div class="reset-div">
      <button class="reset-button" (click)="resetFilters()">{{'case-summary-filter-reset' | translate}}</button>
    </div>
  </div>
</div>
