import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DialogHelperUtilService } from '@secca/core/services/dialog-helper-util.service';
import { DialogBoundryService } from '@secca/core/services/dialog-boundry.service';
import { KeyboardShortcutsModalComponent } from '@secca/shared/components/keyboard-shortcuts-modal/keyboard-shortcuts-modal.component';
import { IKeyboardEnabled } from '@secca/shared/interfaces/keyboard-enabled';

@Component({
  selector: 'app-keyboard-shortcuts-dialog',
  templateUrl: './keyboard-shortcuts-dialog.component.html',
  styleUrls: ['./keyboard-shortcuts-dialog.component.scss']
})
export class KeyboardShortcutsDialogComponent implements OnInit, IKeyboardEnabled {
  @ViewChild(KeyboardShortcutsModalComponent) wrappedInstance: KeyboardShortcutsModalComponent;

  constructor(private dialogRef: MatDialogRef<KeyboardShortcutsDialogComponent>,
              private dialogHelperUtilService: DialogHelperUtilService,
              public dialogBoundryService: DialogBoundryService) { }

  ngOnInit(): void {
  }

  close() {
    this.dialogHelperUtilService.close(this.dialogRef, null);
  }

  keyboardClose(): void {
    this.wrappedInstance.close();
  }
}
