import { ServiceTypeBase } from '../../models/serviceTypeBase';
import { Component, Input } from '@angular/core';
import { ServiceTypeComponent } from '../../models/interfaces';
import { InvestigationServiceOrderExtension } from '@secca/shared/models/service-order/investigation-service-order-extension';

@Component({
  selector: 'app-investigation',
  templateUrl: './investigation.component.html',
})
export class InvestigationComponent extends ServiceTypeBase implements ServiceTypeComponent {
  @Input() investigationCall: InvestigationServiceOrderExtension;
  @Input() leftSideValid = false;
  @Input() hideSupplier = false;
  @Input() caseId: string;


  constructor() {
    super();
  }

  startDateChange(date): void {
    this.investigationCall.startDate = date;
  }

  isValid(): boolean {
    if (this.investigationCall.isValid()){
      this.leftSideValid = true;
    }
    return this.investigationCall.isValid();
  }

  getModel(): InvestigationServiceOrderExtension {
    return this.investigationCall;
  }

}
