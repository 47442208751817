<div class="modal-content">
  <div class="modal-header">
    <div class="modal-header-title">
      {{ 'case-document-move-document-title' | translate }}
    </div>
    <div class="modal-buttons">
      <div (click)="close()">
        <img src="/assets/icons/Close White.svg" />
      </div>
    </div>
  </div>
  <div class="body-1 modal-body">
    <div class="modal-text document-header">
      {{"case-document-move-document-title-move" | translate }}
      <span class="document-name">{{documentName}}</span> 
    </div>
    <div class="modal-text">
      {{ 'case-document-move-document-text' | translate}}
    </div>
    <input type="text" class="input-component" [(ngModel)]="caseNumber" (keyup)="setDirty()"
      [class.invalid-case-number]="!isCaseNumberValid">
    <button class="modal-button" [class.modal-button-busy]="moveInProgress || !isValidCaseNumber()" (click)="moveCaseDocument()" [disabled]="moveInProgress || !isValidCaseNumber()">
      {{ 'case-document-table-move-document-move' | translate }}
      <img src="/assets/icons/waitForSearchresult.gif" class="spinner" [class.hide-spinner]="!moveInProgress" />
    </button>
  </div>
</div>
