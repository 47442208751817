import { RefundStatus, SavingTypedInField } from '@secca/shared/models/enums';
import { RefundReduction } from '@secca/shared/models/refund-reduction';
import { RefundItem } from '@secca/shared/models/refund-Item';
import { DineroObject } from 'dinero.js';
import { Refund } from '@secca/shared/models/refund';
import { ServiceOrder } from '@secca/shared/models/service-order/service-order';
import { ServiceOrderRefundViewModel } from '../../case-economy-service-orders/service-order-refund-view-model';

export class ReductionsCalculator {


  isRefundApproved(refunds: ServiceOrderRefundViewModel): boolean {
    return (
      refunds.refundStatus === RefundStatus.APPROVED.toString() ||
      refunds.refundStatus === RefundStatus.PAID.toString()
    );
  }

    calculateServiceOrderRefundsOriginalAmount(serviceOrder: ServiceOrder): number {
      let totalServiceOrderAmount = 0;
      for (const refund of serviceOrder.refunds) {
        if(this.isRefundApproved(refund)) {
          totalServiceOrderAmount = totalServiceOrderAmount + refund.originalAmount
        }
      }
      return totalServiceOrderAmount;
    }

    calculateServiceOrderRefundsExchangedAmount(serviceOrder: ServiceOrder): number {
      let totalServiceOrderExchangedAmount = 0;
      for (const refund of serviceOrder.refunds) {
        if(this.isRefundApproved(refund)) {
          totalServiceOrderExchangedAmount = totalServiceOrderExchangedAmount + refund.exchangedAmount;
        }
      }
      return totalServiceOrderExchangedAmount;
    }

    calculateServiceOrderRefundsReductionAmount(serviceOrder: ServiceOrder): number {
      let totalServiceOrderAmount = 0;
      for (const refund of serviceOrder.refunds) {
        if(this.isRefundApproved(refund)) {
          totalServiceOrderAmount = totalServiceOrderAmount + refund.reduction;
        }
      }
      return totalServiceOrderAmount;
    }

    calculateServiceOrderRefundsNetAmount(serviceOrder: ServiceOrder): number {
      let totalServiceOrderAmount = 0;
      for (const refund of serviceOrder.refunds) {
        if(this.isRefundApproved(refund)) {
          totalServiceOrderAmount = totalServiceOrderAmount + refund.exchangedNetAmount;
        }
      }
      return totalServiceOrderAmount;
    }

    addAmountsIfRefundIsApproved(refund: ServiceOrderRefundViewModel): boolean {
      return refund.refundStatus === RefundStatus.APPROVED || refund.refundStatus === RefundStatus.PAID;

    }

    recalcReductionAmounts(localCurrency: DineroObject, refundItem: RefundItem): RefundReduction[] {
        const currencyPrecision = Math.pow(10, !!localCurrency.precision ? localCurrency.precision : 2);
        let expenseAmount = refundItem.refundAmount;
        if(expenseAmount !== 0) {
          for (const reduction of refundItem.reductions.slice()) {
              if (reduction.typedInField === SavingTypedInField.AMOUNT || !reduction.percentage) {
                  reduction.percentage = !!reduction.amount ? Math.round((reduction.amount * 10000) / expenseAmount) / 100 : 0;
                } else {
                  const newGross = isFinite(reduction.percentage) ? Math.round((currencyPrecision * expenseAmount) * (reduction.percentage / 100)) / currencyPrecision : 0;
                  reduction.amount = newGross;
                }
                expenseAmount -= reduction.amount;
                reduction.totalAmount = expenseAmount;
          }
        }
        return refundItem.reductions;
    }

    calculateTotalRefundCurrency(refund: Refund): number {
      let totalAmount: number = 0;
      refund.items.forEach(item =>
        {
          totalAmount = totalAmount + this.getExchangedNetAmount(item);
        }
      )
      return totalAmount;
    }

    getExchangedNetAmount(item: RefundItem): number {
      let amount = item.refundExchangedAmount;
      item.reductions.forEach(reductions => (amount -= reductions.exchangedAmount));
      return amount;
    }

    getReductionAmountTotal(item: RefundItem): number {
      let reductionSum = 0;
      for (const reduction of item.reductions) {
        reductionSum += reduction.amount;
      }
      return reductionSum;
    }
    exchangedAmount
    getReductionExchangedAmountTotal(item: RefundItem): number {
      let reductionSum = 0;
      for (const reduction of item.reductions) {
        reductionSum += reduction.exchangedAmount;
      }
      return reductionSum;
    }

    getReductionPctTotal(item: RefundItem): number {
      let amount = item.refundAmount;
      return Math.round((this.getReductionAmountTotal(item) * 10000) / amount) / 100;
    }

    getTotalReductionAmounts(refund: Refund) : number {
      let totalAmount: number = 0;
      refund.items.forEach(item => {
        totalAmount = totalAmount + this.getReductionAmountTotal(item);
      });
      return totalAmount;
    }
}
