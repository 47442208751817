import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { KeycloakService } from 'keycloak-angular';

@Injectable()
export class ErrorHttpInterceptor implements HttpInterceptor {

  constructor(private keycloakService: KeycloakService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError(err => {
      if ([401].indexOf(err.status) !== -1) { // <-- bypass the 403 for now either until all user roles are in sync or until keycloak returns logout flag!
        // Auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        this.keycloakService.logout('https://www.sos.eu/da/');
        return;
      }
      // Re-throw all other errors up to the calling service
      return throwError(err);
    }));
  }
}

export const ErrorHttpInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ErrorHttpInterceptor,
  multi: true
};
