<div class="luggage-panel">
  <div class="message-content p-3">
    <div class="row">
      <div class="col-8">    
        <div class="row">
          <div class="col-5">
            <div *ngIf="taskDataViewModel?.data?.plannedTravel?.luggage">
              <div class="message-content-header">
                <div class="heading">                
                  <span><img src="/assets/icons/service-order-type/Luggage.svg"></span>
                  <div class="text">
                    <span>{{'task-web-modal-luggage' | translate}}</span>
                  </div>
                </div>
              </div>
              <div class="message-content-box">
                <app-task-web-properties [properties]="taskDataViewModel?.data?.plannedTravel?.luggage" [ignoredProperties]="ignoredProperties"
                  [serviceItemList]="serviceItemList" [purposeOfTravelItemList]="purposeOfTravelItemList"
                  [causes]="causes" [diagnoses]="diagnoses" [customerProfiles]="customerProfiles"></app-task-web-properties>
              </div>
              <br>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>