<div class="inbox-task-table" *ngIf="taskViewModels; else loadingTemplate">
    <div class="primary-table-container">
      <table class="primary-table fixed-header-table">
            <thead>
                <tr>
                    <td class="checkbox-column-header">
                        <div class="custom-checkbox checkbox-header-container">
                            <label class="checkbox">
                                <input type="checkbox" (click)="selectAllTasks()" [(ngModel)]="isAllSelected" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </td>
                    <td (click)="sortBy('taskType')" [ngClass]="sortedBy('taskType')">
                        {{"board-task-table-type" | translate}}
                    </td>
                    <td (click)="sortBy('dueDate')" [ngClass]="sortedBy('dueDate')">
                        {{"board-task-table-due" | translate}}
                    </td>
                    <td (click)="sortBy('prioritySort')" [ngClass]="sortedBy('prioritySort')">
                        {{"board-task-table-priority" | translate}}
                    </td>
                    <td (click)="sortBy('customerProfileId')" [ngClass]="sortedBy('customerProfileId')">
                        {{ 'board-task-pre-departure-customer-profile' | translate }}
                    </td>
                    <td>
                        {{"board-task-table-task-from" | translate}}
                    </td>
                    <td (click)="sortBy('title')" [ngClass]="sortedBy('title')">
                        {{"board-task-table-task-title" | translate}}
                    </td>
                    <td (click)="sortBy('causeLevel1Code')" [ngClass]="sortedBy('causeLevel1Code')">
                        {{"board-task-table-cause" | translate}}
                    </td>
                    <td (click)="sortBy('caseNumber')" [ngClass]="sortedBy('caseNumber')">
                        {{"board-task-table-case-id" | translate}}
                    </td>
                    <td (click)="sortBy('endUserFullName')" [ngClass]="sortedBy('endUserFullName')">
                        {{"board-task-table-end-user" | translate}}
                    </td>
                    <td (click)="sortBy('location')" [ngClass]="sortedBy('location')">
                        {{"board-task-table-location" | translate}}
                    </td>
                    <td (click)="sortBy('reserve')" [ngClass]="sortedBy('reserve')">
                        {{"board-task-table-reserve" | translate}}
                    </td>
                    <td (click)="sortBy('assignInitials')" [ngClass]="sortedBy('assignInitials')">
                        {{"board-task-table-assign" | translate}}
                    </td>
                    <td (click)="sortBy('caseHandler')" [ngClass]="sortedBy('caseHandler')">
                        {{"board-task-table-primary" | translate}}
                    </td>
                    <td>
                        {{"board-task-table-case-details" | translate}}
                    </td>
                    <td (click)="sortBy('readyFrom')" [ngClass]="sortedBy('readyFrom')">
                        {{"board-task-table-ready-from" | translate}}
                    </td>
                </tr>
            </thead>
            <tbody (scroll)="onCaseTabelScroll()" id="taskInboxTabel" [class.zoomed]="settingsService.isZoomed()">
                <app-task-hover></app-task-hover>
                <tr *ngFor="let task of taskViewModels; let i = index" [class.task-check]="task.isSelected" [class.task-without-case-row]="!task.caseNumber" [class.closed-case]="task.teamStatus === HandlingAreaStatusEnum.CLOSED">
                    <td class="checkbox-column">
                        <div class="custom-checkbox checkbox-content-container">
                            <label class="checkbox">
                            <input type="checkbox" [(ngModel)]="task.isSelected" />
                            <span class="checkmark"></span>
                            </label>
                        </div>
                    </td>
                    <td class="type-column">
                        <img [src]="getTaskTypeImagePath(task)" (click)="viewTask(task)">
                    </td>
                    <td>
                        {{ task.dueDate | momentFormat :'DD MMM HH:mm' : 'local' }}
                    </td>
                    <td class="text-center">
                        {{ task.taskPriority }}
                    </td>
                    <td class="customer-column">
                        <img *ngIf="task.customerProfileId" [src]="getBrandImagesUrlForProfile(task.customerProfileId)" />
                    </td>
                    <td>
                        {{ task.fromText }}
                    </td>
                    <td class="long-text-field-task-title">
                        <span   (click)="redirectToTaskInCase(task)"
                                (mouseenter)="mouseTitleEnter($event, task)"
                                (mouseleave)="mouseTitleLeave()">
                                {{task.title}}
                        </span>
                    </td>
                    <td class="long-text-field">
                        <div class="d-flex justify-content-between">
                          <div [class]="task.incidentEvent != null && task.incidentEvent != 0 ? 'cause-short' : 'cause' "> {{task.cause}}</div>
                          <div>
                            <div
                              *ngIf="task.incidentEvent != null && task.incidentEvent != 0"
                              class="case-status case-details-repatriation-form case-details-event"
                              [ngbPopover]="task.incidentEventName"
                              placement="auto"
                              triggers="hover">
                                  {{ 'task-board-incident-event' | translate }}
                            </div>
                          </div>
                        </div>
                    </td>
                    <td class="case-number-column">
                        <div
                            [ngClass]="{'case-locked': task.caseLockedBy && task.caseLockedBy != loggedInUser.id}"
                            (click)="redirectToCase(task.caseId)"
                            [ngbPopover]="task.incidentCause"
                              placement="auto"
                              triggers="hover"
                            >{{task.caseNumber}}
                        </div>
                    </td>
                    <td>
                        {{task.endUserFullName}}
                    </td>
                    <td>
                        {{task.location}} ({{task.timeZoneId | timeDiff : true}})
                    </td>
                    <td class="reserve-column">
                        <span *ngIf="task.caseNumber">{{task.reserve == null ?  0 : task.reserve | numberFormat }}
                        {{task.currency}}</span>
                    </td>
                    <td class="assing-column">{{ assigneeShortName(task.assignInitials) }}</td>
                    <td class="long-text-field">{{ assigneeShortName(task.caseHandler) }}</td>
                    <td>
                        <div *ngIf="task.caseDetailsTBD === true" class="case-status case-details-mortal"> {{"board-task-table-case-details-TBD" | translate}} </div>
                        <div *ngIf="task.caseDetailsURN === true" class="case-status case-details-mortal"> {{"board-task-table-case-details-URN" | translate}} </div>
                        <div *ngIf="task.caseDetailsCOF === true" class="case-status case-details-mortal"> {{"board-task-table-case-details-COF" | translate}} </div>
                        <div *ngIf="task.caseDetailsTBD !== true && task.caseDetailsURN !== true && task.caseDetailsCOF !== true ">
                            <span *ngIf="showMedicalServiceOrderType(task)">
                            <div *ngIf="task.medicalServiceorderType === 'COMT011'" class="case-status case-details-medical-service-order"> {{"board-task-table-case-details-REGULAR" | translate}} </div>
                            <div *ngIf="task.medicalServiceorderType === 'COMT010'" class="case-status case-details-medical-service-order"> {{"board-task-table-case-details-LIGHT" | translate}} </div>
                            <div *ngIf="task.medicalServiceorderType === 'COMT012'" class="case-status case-details-medical-service-order"> {{"board-task-table-case-details-NIGHT" | translate}} </div>
                            <div *ngIf="task.medicalServiceorderType === 'COMT028'" class="case-status case-details-claims-service-order"> {{"board-task-table-case-details-CLAIMS" | translate}} </div>
                            </span>
                            <div *ngIf="task.caseDetailsAIR === true" class="case-status case-details-repatriation-form"> {{"board-task-table-case-details-AIR" | translate}} </div>
                            <div *ngIf="task.caseDetailsSTR === true" class="case-status case-details-repatriation-form"> {{"board-task-table-case-details-STR" | translate}} </div>
                            <div *ngIf="task.caseDetailsEXT === true" class="case-status case-details-repatriation-form"> {{"board-task-table-case-details-EXT" | translate}} </div>
                            <div *ngIf="task.caseDetailsSIT === true" class="case-status case-details-repatriation-form"> {{"board-task-table-case-details-SIT" | translate}} </div>
                            <div *ngIf="task.caseDetailsREG === true" class="case-status case-details-repatriation-form"> {{"board-task-table-case-details-REG" | translate}} </div>
                            <div *ngIf="task.caseDetailsDOC === true || task.caseDetailsNUR === true || task.caseDetailsNME === true" class="case-status case-details-escort"> {{ caseDetailsEscort(task) }} </div>
                            <div *ngIf="task.caseDetailsOXY === true" class="case-status case-details-oxygen"> {{"board-task-table-case-details-OXY" | translate}} </div>
                            <div *ngIf="task.caseDetailsEQU === true" class="case-status case-details-equipment"> {{"board-task-table-case-details-EQU" | translate}} </div>
                        </div>
                    </td>
                    <td>
                        <div *ngIf="task.caseDetailsTBD === false && task.caseDetailsURN === false && task.caseDetailsCOF === false ">
                          {{task.readyFrom | date: 'dd MMM YYYY' }}
                          <div *ngIf="task.arrivalDeadline" [ngbPopover]="'board-task-table-arrival-deadline-popover-text' | translate: {arrivalDeadline: task.arrivalDeadline | date | lowercase}" placement="auto" triggers="hover" class="aarrival-deadline">
                            {{task.arrivalDeadline | date: 'dd MMM YYYY' }}
                          </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<ng-template #loadingTemplate>
    <div class="d-flex justify-content-center pt-3">
      <h4>{{ 'board-task-table-refreshing' | translate }}</h4>
    </div>
</ng-template>
