
  <div class="left-side-container" [appDisableInput]="caseStateService.isCaseDisabled()" >
    <div class="supplier-container row">
      <div [class.col-7]="!serviceOrder.supplierId" [class.col-10]="serviceOrder.supplierId">
        <app-drop-down [items]="providers" [name]="'supplier' | translate" [(selectedItemId)]="serviceOrder.supplierId"
                       [disabled]="supplierDisabled" [requiredRedBackground]="serviceOrderTypeState.isSupplierRequired || (isCommitted)"
                       (selectedItemIdChange)="supplierChanged($event)">
        </app-drop-down>
      </div>
      <div [class.col-5]="!serviceOrder.supplierId" [class.col-2]="serviceOrder.supplierId">
        <button class="search-supplier-button float-end" [disabled]="supplierDisabled" (click)="searchSupplier()" [class.button-busy]="showProviderSpinner">
          <span *ngIf="!serviceOrder.supplierId">{{ 'search-supplier' | translate }}</span>
          <img src="/assets/icons/waitForSearchresult.gif" class="spinner" [class.hide-spinner]="!showProviderSpinner" />
          <img class="search-image" src="../../../../../../assets/icons/Magnifier-white.svg" *ngIf="serviceOrder.supplierId">
        </button>
      </div>
    </div>
    <div class="horizontal-line"></div>
    <div class="amount-cost-container">
      <div class="text">
        {{ 'service-order-icc-reserve-expected' | translate }}
      </div>
      <div class="enduser-reserve-message">
        {{"service-order-icc-reserve" | translate}}
      </div>
    </div>
    <div class="horizontal-line"></div>
    <div class="status-container">
      <div class="text">
        {{ 'left-side-status' | translate }}
      </div>
      <div class="row">
        <div class="col pe-0">
          <label class="custom-radio-button" [class.radio-disabled]="expectedDisabled()">
            <input type="checkbox" [checked]="serviceOrder.status === StatusTypes.EXPECTED" [disabled]="expectedDisabled()"
                   (click)="changeStatus(StatusTypes.EXPECTED, $event)" />
            <span class="checkmark"></span>
            <div class="radio-button-text">{{ 'service-order-expected' | translate }}</div>
          </label>
        </div>
        <div class="col p-0">
          <label class="custom-radio-button" [class.radio-disabled]="commitedDisabled()">
            <input type="checkbox" [checked]="serviceOrder.status === StatusTypes.COMMITTED" [disabled]="commitedDisabled()"
                   (click)="changeStatus(StatusTypes.COMMITTED, $event)" />
            <span class="checkmark"></span>
            <div class="radio-button-text">{{ 'service-order-committed' | translate }}</div>
          </label>
        </div>
        <div class="col ps-0">
          <label class="custom-radio-button" [class.radio-disabled]="canceledDisabled()">
            <input type="checkbox" [checked]="serviceOrder.status === StatusTypes.CANCELLED" [disabled]="canceledDisabled()"
                   (click)="changeStatus(StatusTypes.CANCELLED, $event)" />
            <span class="checkmark"></span>
            <div class="radio-button-text">{{ 'service-order-cancelled' | translate }}</div>
          </label>
        </div>
      </div>
    </div>
    <div class="horizontal-line"></div>
    <div class="remarks-container">
      <div class="text">{{ 'service-order-remarks' | translate }}</div>
      <textarea name="textarea" class="remarks-textarea remarks" maxlength="1000" [(ngModel)]="serviceOrder.remarks"
                (ngModelChange)="dataChanged()"></textarea>
    </div>
  </div>
