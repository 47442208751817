<div class="digital-caseflow-details">
  <div>
    <span class="dcfDetailText grey">{{ 'digital-caseflow-flow' | translate }}</span>
    <span class="dcfDetailText"> {{ flowLog.flowName }}</span>
  </div>
  <div>
    <span class="dcfDetailText grey">{{ 'digital-caseflow-activity-log-status' | translate }}</span>
    <span class="dcfDetailText red" *ngIf="flowLog.flowResult == 'FAILED'"> {{ 'status-failed-capital' | translate }}</span>
    <span class="dcfDetailText teal" *ngIf="flowLog.flowResult != 'FAILED'"> {{ 'status-succeeded-capital' | translate }}</span>
  </div>

  <tr class="activityDetailsTable" *ngFor="let activityLog of flowLog.activityLogs">
    <td class="activityDetailsRow">
      <div>
        <span class="dcfDetailText grey">{{ 'digital-caseflow-activity-log-action' | translate }}</span>
        <span class="dcfDetailText"> {{ actionTranslationPrefix+activityLog.activityType | translate }}</span>
      </div>
      <div>
        <span class="dcfDetailText grey">{{ 'digital-caseflow-activity-log-status' | translate }}</span>
        <span class="dcfDetailText red" *ngIf="activityLog.result == 'FAILED'"> {{ getTranslatedResult(activityLog) }}</span>
        <span class="dcfDetailText orange" *ngIf="activityLog.result == 'FAILED_NOT_MANDATORY'"> {{ getTranslatedResult(activityLog) }}</span>
        <span class="dcfDetailText teal" *ngIf="activityLog.result == 'SUCCESS'"> {{ getTranslatedResult(activityLog) }}</span>
      </div>
      <div *ngIf="activityLog.activityErrorLogs.length !== 0">
        <span class="dcfDetailText grey">{{ 'digital-caseflow-result' | translate }}</span>
        <span class="dcfDetailText"> {{ getTranslatedErrorCodes(activityLog) }}</span>
      </div>
    </td>
  </tr>
</div>
