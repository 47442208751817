<div class="service-order-row supplier-invoice-container">
  <div
    [appDisableInput]="caseLockHelperService.isSupplierInvoiceLockTaken(supplierInvoiceId) || permissionService.isReadOnly"
    class="caselock-no-events">
    <div>
      <table class="primary-table">
        <tr class="tr-saving" *ngFor="let saving of grossSavings; let i = index">
          <td>
            <div *permission="PermissionEnum.SUPPLIER_INVOICE_UPDATE">
              <div class="delete-saving-button" *ngIf="!editDisabled" (click)="removeSaving(saving.lineNumber)">
                <img class="delete-saving-button-icon" src="../../../../../../../assets/icons/garbage.svg" />
                {{ 'economy-supplier-invoice-remove-saving' | translate }}
              </div>
            </div>
          </td>
          <td>
            <app-drop-down [items]="reductionTypeList" [(selectedItemId)]="saving.reductionTypeCode"
              [disabled]="editDisabled" [requiredRedBackground]="true">
            </app-drop-down>
          </td>
          <td></td>
          <td></td>
          <td>
            <div class="input-with-currency" [ngbTooltip]="getSavingsExclVAT(i)" placement="bottom">
              <div class="input-with-currency-currency">{{ localCurrency.currency }}</div>
              <input [ngClass]="{ 'input-with-currency-required': saving.amount <= 0 }" [(ngModel)]="saving.amount"
                [disabled]="editDisabled" currencyMask [options]="currencyMaskOptions" (focus)="focusElement($event)"
                (ngModelChange)="savingAmountChanged(saving)" />
            </div>
          </td>
          <td>
            <div class="input-with-currency">
              <div class="input-with-currency-currency">%</div>
              <input [ngClass]="{ 'input-with-currency-required': saving.percentage <= 0 }" [(ngModel)]="saving.percentage"
                [disabled]="editDisabled" currencyMask [options]="currencyMaskOptions" (focus)="focusElement($event)"
                (ngModelChange)="savingPercentageChanged(saving)" />
            </div>
          </td>

          <td>
            <div class="input-with-currency">
              <div class="input-with-currency-currency">{{ localCurrency.currency }}</div>
              <input [(ngModel)]="saving.totalAmount" [disabled]="grossTotalDisabled() || i > 0" currencyMask
                [options]="currencyMaskOptions" (focus)="focusElement($event)" (ngModelChange)="savingTotalAmountChanged()" />
            </div>
          </td>
        </tr>
        <tr class="tr-service">
          <td class="td-service-order">
            <div (mouseenter)="mouseEnter($event, supplierInvoiceItem.serviceOrderId)" (mouseleave)="mouseLeave(supplierInvoiceItem.serviceOrderId)"  >
              <app-drop-down-service-order #serviceOrders [(selectedItemId)]="supplierInvoiceItem.serviceOrderId"
                (selectedItemIdChange)="serviceOrderChange()" (itemsLoaded)="serviceOrderChange()"
                [serviceOrders]="selectableServiceOrders" [disabled]="caseStateService.isCaseClosed"
                [hightLightInvalid]="true">
              </app-drop-down-service-order>
            </div>
            <div class="service-order-hover" *ngIf="getShowServiceOrderInfo(supplierInvoiceItem.serviceOrderId)" >
              <app-new-service-order-hover [caseStakeholder]="caseStakeholders" [serviceOrderHoverModel]="serviceOrderHoverModel"></app-new-service-order-hover>
            </div>
          </td>
          <td class="td-item">
            <app-drop-down [items]="serviceItemList" [recommended]="supplierInvoiceItem.serviceItemCode === null"
              [(selectedItemId)]="supplierInvoiceItem.serviceItemCode" (selectedItemIdChange)="serviceItemChange()"
              [disabled]="editDisabled"></app-drop-down>
          </td>
          <td class="td-quantity">
            <input [(ngModel)]="supplierInvoiceItem.quantity"
              [ngClass]="{ 'validation-error': supplierInvoiceItem.quantity === null || supplierInvoiceItem.quantity === 0 }" currencyMask
              [options]="quantityMaskOptions" [disabled]="editDisabled" (focus)="focusElement($event)" />
          </td>
          <td class="td-unit">
            <app-drop-down [items]="quantityUnits" [recommended]="supplierInvoiceItem.quantityUnit === null"
              [(selectedItemId)]="supplierInvoiceItem.quantityUnit" [disabled]="editDisabled"
              [translatePipePath]="'quantity-unit-'"></app-drop-down>
          </td>
          <td class="td-savings-amount"></td>
          <td class="td-savings-pct"></td>
          <td class="td-amount" [ngbTooltip]="getAmountExclVAT()" placement="bottom">
            <div *ngIf="shouldActAsInvoiceRecievedFromSUPO; else amountWithInput">
              <div class="output-with-currency">
                <div class="output-with-currency-currency">{{ localCurrency.currency }}</div>
                <div [ngClass]="supplierInvoiceItem.paidAmount < 0 ? 'amount-negative' : ''">{{ supplierInvoiceItem.paidAmount | number: '.' + localCurrency.precision }}</div>
              </div>
            </div>
          </td>
          <ng-template #amountWithInput>
            <div class="input-with-currency">
              <div class="input-with-currency-currency">{{ localCurrency.currency }}</div>
              <input [(ngModel)]="supplierInvoiceItem.paidAmount"
                     [disabled]="editDisabled || supplierInvoiceItem.calculatedFromGross" currencyMask
                     [options]="currencyMaskOptions" (focus)="focusElement($event)" (ngModelChange)="recalcSavingAmounts()"
                     [ngClass]="{ 'validation-attention': supplierInvoiceItem.paidAmount === null }" />
            </div>
          </ng-template>
        </tr>
        <tr class="tr-saving" *ngFor="let saving of netSavings; let i = index">
          <td>
            <div *permission="PermissionEnum.SUPPLIER_INVOICE_UPDATE">
              <div class="delete-saving-button" *ngIf="!editDisabled" (click)="removeSaving(saving.lineNumber)">
                <img class="delete-saving-button-icon" src="../../../../../../../assets/icons/garbage.svg" />
                {{ 'economy-supplier-invoice-remove-saving' | translate }}
              </div>
            </div>
          </td>
          <td>
            <app-drop-down [items]="reductionTypeList" [(selectedItemId)]="saving.reductionTypeCode"
              [disabled]="editDisabled" [requiredRedBackground]="true">
            </app-drop-down>
          </td>
          <td></td>
          <td></td>
          <td>
            <div class="input-with-currency" [ngbTooltip]="getSavingsExclVAT(i)" placement="bottom">
              <div class="input-with-currency-currency">{{ localCurrency.currency }}</div>
              <input [ngClass]="{ 'input-with-currency-required': saving.amount <= 0 }" [(ngModel)]="saving.amount"
                [disabled]="editDisabled" currencyMask [options]="currencyMaskOptions" (focus)="focusElement($event)"
                (ngModelChange)="savingAmountChanged(saving)" />
            </div>
          </td>

          <td>
            <div class="input-with-currency">
              <div class="input-with-currency-currency">%</div>
              <input [ngClass]="{ 'input-with-currency-required': saving.percentage <= 0 }" [(ngModel)]="saving.percentage"
                [disabled]="editDisabled" currencyMask [options]="currencyMaskOptions" (focus)="focusElement($event)"
                (ngModelChange)="savingPercentageChanged(saving)" />
            </div>
          </td>

          <td>
            <div class="output-with-currency" *ngIf="!saving.gross">
              <div class="output-with-currency-currency">{{ localCurrency.currency }}</div>
              {{ saving.totalAmount | number: '.' + localCurrency.precision }}
            </div>
          </td>
        </tr>
        <tr class="tr-saving">
          <td class="td-add-saving-left"></td>
          <td class="td-add-saving">
            <div *permission="PermissionEnum.SUPPLIER_INVOICE_UPDATE">
              <div class="add-saving-container">
                <div class="add-saving-button" *ngIf="!editDisabled" (click)="addSaving(false)">
                  <img src="../../../../../../../assets/icons/add-new.svg" /> {{ 'economy-supplier-invoice-add-saving' |
                  translate }}
                </div>
                <div class="add-saving-button" *ngIf="!editDisabled" (click)="addSaving(true)">
                  <img src="../../../../../../../assets/icons/add-new.svg" /> {{
                  'economy-supplier-invoice-add-gross-saving' | translate }}
                </div>
              </div>
            </div>
          </td>

          <td class="td-add-saving"></td>
          <td class="td-add-saving-right"></td>
          <td>
            <div class="output-with-currency">
              <div class="output-with-currency-currency">{{ localCurrency.currency }}</div>
              {{ getSavingsAmountTotal() | number: '.' + localCurrency.precision }}
            </div>
          </td>
          <td>
            <div class="output-with-currency">
              <div class="output-with-currency-currency">%</div>
              {{ getSavingsPctTotal() | number: '.1-1' }}
            </div>
          </td>
          <td>
            <div class="output-with-currency">
              <div class="output-with-currency-currency">{{ localCurrency.currency }}</div>
              <div [ngClass]="shouldActAsInvoiceRecievedFromSUPO && getNetAmount() < 0 ? 'amount-negative' : ''">{{ getNetAmount() | number: '.' + localCurrency.precision }}</div>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="delete-button" *ngIf="showDelete && !editDisabled" (click)="deleteSpecification()"></div>
  </div>
</div>
