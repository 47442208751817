import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Debtor, DebtorAdapter } from '@secca/shared/models/debtor';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root',
})
export class CommonCustomersService extends BaseService {
  constructor(private http: HttpClient, private settingsService: SettingsService, private debtorAdapter: DebtorAdapter) {
    super(settingsService);
  }

  public getAllDebtors(): Observable<Debtor[]> {
    return this.http
      .get<DropdownDictionary[]>(this.baseURL + `common-customers/debtors`, {
        headers: this.jsonHeaders,
      })
      .pipe(map((data: any[]) => data.map(item => this.debtorAdapter.adapt(item))));
  }
}
