import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { PersonInsurance } from '@secca/shared/models/person-insurance';
import { Dictionary } from '@secca/shared/models/dictionary';
import { ProfileChangeService } from '@secca/case/components/case-basics/case-basics-insurance/profile-change-service';
import { CaseService } from '@secca/core/services/case.service';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { IncidentService } from '@secca/core/services/incident.service';
import { InsuranceService } from '@secca/core/services/insurance.service';
import { CoverageChangeService } from '@secca/case/components/case-basics/case-basics-insurance/coverage-change-service';
import { ProductChangeService } from '@secca/case/components/case-basics/case-basics-insurance/product-change-service';
import { ServiceOrderService } from '@secca/core/services/service-order.service';
import { ModalDialogComponent } from '@secca/shared/components/modal-dialog/modal-dialog.component';
import { ModalDialogConfiguration } from '@secca/shared/models/modal/modal-dialog-configuration';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FinancialStatusService } from '@secca/core/services/financial-status.service';
import { PlanService } from '@secca/core/services/plan.service';
import { TranslateService } from '@ngx-translate/core';

@AutoUnsubscribe
@Component({
  selector: 'app-case-basics-insurance-product',
  templateUrl: './case-basics-insurance-product.component.html',
})
export class CaseBasicsInsuranceProductComponent implements OnInit {
  @Input() set personInsurance(value: PersonInsurance) {
    if (value && value.customerProduct !== this._personInsurance?.customerProduct) {
      this._personInsurance = value;
      this.getCustomerProductName();
      this.oldInsurance = JSON.parse(JSON.stringify(this._personInsurance));
      this.loadCustomerProducts();
      if (value.noInsuranceWithThisCustomerGroup) {
        this.placeholder = this.translate.instant('case-basics-insurance-coverage-view-no-insurance');
      } else {
        this.placeholder = this.translate.instant('case-basics-insurance-customerProduct-placeholder');
      }
    }
  }
  get personInsurance() {
    return this._personInsurance;
  }

  @Input() set caseId(value: string) {
    if (value && value !== this._caseId) {
      this._caseId = value;
    }
  }

  get caseId(): string {
    return this._caseId;
  }

  @Input() set insuranceLevels(value: Dictionary[]) {
    if (value && JSON.stringify(value.map(l => l.id).sort()) !== JSON.stringify(this._insuranceLevels.map(l => l.id).sort())) {
      this._insuranceLevels = value;
      this.identifyProduct();
    }
  }

  @Input() recommended: boolean;

  _insuranceLevels: Dictionary[] = [];
  @Output() productChange: EventEmitter<number> = new EventEmitter();
  @Output() numberOfProducts: EventEmitter<number> = new EventEmitter();

  _caseId: string;
  incidentDate: moment.Moment;
  incidentDateSubscription: Subscription;
  oldInsurance = new PersonInsurance();
  _personInsurance: PersonInsurance;
  customerProductListFull: Dictionary[];
  customerProductList: Dictionary[];
  customerProductName: string;
  placeholder = '';
  private $profileChangeSubscr: Subscription;
  private $productChangeSubscr: Subscription;

  constructor(private profileChangeService: ProfileChangeService,
              private incidentService: IncidentService,
              private caseService: CaseService,
              private insuranceService: InsuranceService,
              private changeCoverageService: CoverageChangeService,
              private productChangeService: ProductChangeService,
              private serviceOrderService: ServiceOrderService,
              private financialStatusService: FinancialStatusService,
              private planService: PlanService,
              private translate: TranslateService,
              private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.$profileChangeSubscr = this.profileChangeService.observe(this.caseId).subscribe(result => {
      if (result && result !== this.oldInsurance?.customerProfileId) {
        this.oldInsurance = JSON.parse(JSON.stringify(this._personInsurance));
        this._personInsurance.customerProfileId = result;
        this.loadCustomerProducts();
      }
    });
    this.$productChangeSubscr = this.productChangeService.observe(this.caseId).subscribe(result => {
      if (!result || result === this._personInsurance?.customerProduct) {
        return;
      }
      if (this.customerProductListFull && this.customerProductListFull.find(p => Number(p.id) === this._personInsurance.customerProduct)) {
        this.recalculateProductList();
      } else {
        this.loadCustomerProducts();
      }
    });
    this.incidentDateSubscription = this.incidentService.getIncidentDateChanges(this.caseId).subscribe(
      result => {
        if (result && result !== this.incidentDate) {
          this.incidentDate = result;
          this.oldInsurance = JSON.parse(JSON.stringify(this._personInsurance));
          this.loadCustomerProducts();
        }
      }
    );
  }

  loadCustomerProducts() {
    if (!this._personInsurance.customerProfileId || this._personInsurance.noInsuranceWithThisCustomerGroup) {
      this.customerProductList = [];
    } else {
      this.caseService.getProfileProducts(this._personInsurance.customerProfileId, this.incidentDate).subscribe(result => {
        this.customerProductListFull = result;
        this.numberOfProducts.emit(this.customerProductListFull.length);
        this.recalculateProductList();
        if (this._personInsurance.customerProduct) {
          this.identifyProduct();
        }
      });
    }
  }

  private getCustomerProductName() {
    if (this?._personInsurance?.insuranceLookupPolicy?.product) {
      this.customerProductName = this._personInsurance.insuranceLookupPolicy.product.customerProductName;
    } else if (!!this._personInsurance.customerProduct) {
      this.caseService.getCustomerProduct(this._personInsurance.customerProduct).subscribe(result => {
        this.customerProductName = result.name;
      });
    }
  }

  private recalculateProductList() {
    // several products can have the same name, only show one per name
    const list = this.customerProductListFull.filter((product, i, array) => array.findIndex(p => p.name === product.name) === i);
    // make sure the chosen id is in the list
    if (this._personInsurance.customerProduct) {
      const chosenProduct = this.customerProductListFull.find(p => Number(p.id) === this._personInsurance.customerProduct);
      if (!!chosenProduct) {
        const idx = list.findIndex(p => p.name === chosenProduct.name);
        list[idx] = new DropdownDictionary(this._personInsurance.customerProduct, chosenProduct.name);
      }
    }
    list.push(new DropdownDictionary(null, 'Blank'));
    this.customerProductList = list;
  }

  identifyProduct() {
    if (this.customerProductListFull == null || this._personInsurance?.insuranceLookupPolicy) {
      return;
    }
    if (this._personInsurance.insuranceLevelId && !this._insuranceLevels?.length) {
      return;
    }
    if (!this._personInsurance.customerProduct && this.oldInsurance.customerProduct) {
      this.onProductChange();
      this.oldInsurance = JSON.parse(JSON.stringify(this._personInsurance));
      this.productChange.emit(null);
      return;
    }
    const chosenProduct = this.customerProductListFull.find(p => Number(p.id) === this._personInsurance.customerProduct);
    if (!chosenProduct) {
      return;
    }
    let level = null;
    if (this._personInsurance.insuranceLevelId
      && Number(this._personInsurance.insuranceLevelId) === this._personInsurance.customerProduct) {
      level = this._insuranceLevels.find(l => l.id === this._personInsurance.insuranceLevelId)?.name;
    }
    this.insuranceService.getProductVersion(
      this._personInsurance.customerProfileId,
      chosenProduct.name,
      level,
      this.incidentDate
    ).subscribe(
      result => {
        const isChanged = this.isChanged();
        if (this.oldInsurance.customerProduct !== result.id) {
          this.onProductChange();
        }
        this._personInsurance.customerProduct = result.id;
        this.recalculateProductList();
        if (isChanged) {
          this.oldInsurance = JSON.parse(JSON.stringify(this._personInsurance));
          this.productChange.emit(result.id);
          this.changeCoverageService.updateCoverage(result);
        }
      },
      ignoreError => {
        this._personInsurance.customerProduct = null;
      }
    );
  }

  isChanged() {
    return JSON.stringify(this._personInsurance) !== JSON.stringify(this.oldInsurance);
  }

  onProductChange() {
    this._personInsurance.geographicalCoverageArea = null;
    this._personInsurance.insuranceLevelId = null;
  }

  checkIfProductChangeIsPossible() {
    if (this.personInsurance.customerProduct != null) {
      this.insuranceService.checkDebtorChange(this.caseId, this.personInsurance.customerProduct, this.personInsurance.customerProfileId).subscribe(debtorChange => {
        if (!debtorChange) {
          this.checkCoveragesUsedOnExistingServiceOrders(this.caseId, this.personInsurance.customerProduct);
        } else {
          this.insuranceService.checkDebtorChangeFinancialStatus(this.caseId).subscribe(financialStatusOpen => {
            if (financialStatusOpen) {
              this.planService.existReserveOnCase(this.caseId).subscribe(reserveExist => {
                if (!reserveExist) {
                  this.checkCoveragesUsedOnExistingServiceOrders(this.caseId, this.personInsurance.customerProduct);
                } else {
                  this.insuranceService.checkCurrencyChange(this.caseId, this.personInsurance.customerProduct, this.personInsurance.customerProfileId).subscribe(currencyChange => {
                    if (!currencyChange) {
                      this.checkCoveragesUsedOnExistingServiceOrders(this.caseId, this.personInsurance.customerProduct);
                    } else {
                      this.showWarningIfProductChangeNotPossible('check-debtors-same-currency-title', 'check-debtors-same-currency-text');
                    }
                  });
                }
              });
            } else {
              this.showWarningIfProductChangeNotPossible('check-debtors-financial-status-not-open-title', 'check-debtors-financial-status-not-open-text');
            }
          });
        }
      });
    } else {
      this.checkCoveragesUsedOnExistingServiceOrders(this.caseId, null);
    }
  }

  private checkCoveragesUsedOnExistingServiceOrders(caseId: string, productId: number) {
    this.serviceOrderService.isCoveragesUsedOnExistingServiceOrders(+caseId).subscribe(coveragesExist => {
      if (!coveragesExist) {
        this.productChange.emit(productId);
      } else {
        const modalRef = this.modalService.open(ModalDialogComponent);
        modalRef.componentInstance.items = [];
        modalRef.componentInstance.configuration = new ModalDialogConfiguration({
          header: 'default-modal-header',
          title: 'changing-service-orders-with-coverages',
          text: null,
          footer: 'case-validation-warning-modal-footer',
          yes: 'default-modal-dialog-yes',
          no: 'default-modal-dialog-no',
          isBody: true,
          isFooter: true,
        });
        modalRef.componentInstance.closeModalEvent.subscribe(
          closedClickingYes => {
            this.modalService.dismissAll();
            if (closedClickingYes) {
              this.productChange.emit(productId);
            } else {
              this.personInsurance.customerProduct = this.oldInsurance.customerProduct;
            }
          },
          error => console.log(error)
        );
      }
    });
  }

  private showWarningIfProductChangeNotPossible(title: string, text: string) {
    const modalRef = this.modalService.open(ModalDialogComponent);
    modalRef.componentInstance.items = [];
    modalRef.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'default-modal-header',
      title: title,
      text: text,
      no: 'dismiss-ok-modal',
      isBody: true,
      isFooter: true,
    });
    modalRef.componentInstance.closeModalEvent.subscribe(
      ok => {
        this.modalService.dismissAll();
        this.personInsurance.customerProduct = this.oldInsurance.customerProduct;
      },
      error => console.log(error)
    );
  }
}
