import { MedicalPreAssessmentServiceOrderExtension } from '@secca/shared/models/service-order/medical-pre-assessment-service-order-extension';
import { Component, OnInit, SimpleChanges, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { ServiceOrderTypeState } from '@secca/case/components/case-plans/case-plans/add-service-order/models/serviceOrderTypeState';
import { ServiceOrderExtension } from '@secca/shared/models/service-order/service-order-extension';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { MedicalPreAssessment } from '@secca/shared/models/medicalPreAssessment';
import { MedicalPreAssessmentState, SelectItem } from '../../medical-components/medical-models';
import { MedicalPreAssessmentDecisionEnum } from '@secca/shared/models/enums';
import { TranslateService } from '@ngx-translate/core';
import { DocumentService } from '@secca/core/services/document.service';
import { DocumentTemplateFilter } from '@secca/case/components/case-plans/case-plans/add-service-order/sub-components/sub-gop-details/documentTemplateFilter';
import { DocumentTemplate } from '@secca/shared/models/document-template';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { StakeholderService } from '@secca/core/services/stakeholder.service';
import { DropdownDictionary } from '../../../../../../shared/models/dropdownDictionary';

@Component({
  selector: 'app-medical-pre-assessment-decision',
  templateUrl: './medical-pre-assessment-decision.component.html',
  styleUrls: ['./medical-pre-assessment-decision.component.scss']
})
@AutoUnsubscribe
export class MedicalPreAssessmentDecisionComponent implements OnInit{
  @Input() caseId: number;
  @Input() serviceOrderExtension: ServiceOrderExtension;
  @Input() medicalPreAssessment: MedicalPreAssessment;
  @Input() disabled: boolean;
  @Input() state: MedicalPreAssessmentState;
  @Input() stakeholders: CaseStakeholder;

  @Output() preview = new EventEmitter();
  @Output() send = new EventEmitter();
  @Output() publish = new EventEmitter();
  @Output() requestInformation = new EventEmitter();

  previewText: string;
  sendText: string;
  sendPopUpText: string;
  templateName: string;
  templateId: string;
  templateLanguage: string;
  templateNames = [];
  templateLanguages = [];
  fullTemplateList: DocumentTemplate[];
  templateFilter = new DocumentTemplateFilter('DOCUMENT', ['PRE_ASSESSMENT'], null);
  templatesWithLanguages: { [templateName: string]: string[] } = {};
  decisionList: SelectItem<MedicalPreAssessmentDecisionEnum>[];
  sendButtonDisabled = true;
  decisionButtonDisabled = true;
  decisionRequestInfoButtonDisabled = true;
  languages: DropdownDictionary[];

  constructor(private translateService: TranslateService,
              private documentService: DocumentService,
              private stakeholderService: StakeholderService) { }

  ngOnInit() {
    this.decisionList = this.getSingleSelectItemFromEnum(MedicalPreAssessmentDecisionEnum, this.medicalPreAssessment?.decision, 'medical-pre-assessment-');

    this.documentService.postTemplatesByType(this.templateFilter).subscribe(decisionTemplates => {
      this.fullTemplateList = decisionTemplates;
      this.mapToTemplateNameWithLanguagesList(decisionTemplates);
    });
    this.decisionButtonDisabled = this.getStateValue(this.state,this.disabled);
    this.decisionRequestInfoButtonDisabled = this.getRequestInfoStateValue(this.state, this.disabled);

  }

  ngOnChanges(changes: SimpleChanges): void {
   this.decisionButtonDisabled = this.getStateValue(this.state,this.disabled);
   this.decisionRequestInfoButtonDisabled = this.getRequestInfoStateValue(this.state, this.disabled);
  }

  previewLetter() {
    this.preview.emit();
    this.sendButtonDisabled = false;
  }

  sendLetter() {
    this.send.emit();
  }

  sendRequestInformation() {
    this.medicalPreAssessment.decision = MedicalPreAssessmentDecisionEnum.REQUEST_INFORMATION;
    this.requestInformation.emit();
  }

  publishDecision(value: MedicalPreAssessmentDecisionEnum) {
    this.medicalPreAssessment.decision = value;
    this.publish.emit();
  }

  selectTemplateLanguages(selectedTemplateName: string) {
    this.medicalPreAssessment.decisionDetail.templateLanguage = null;
    this.templateLanguages = this.templatesWithLanguages[selectedTemplateName];
    this.templateId = this.getTemplateId();
    this.setPreferredLanguage();
    this.medicalPreAssessment.decisionDetail.templateId = this.templateId;

  }

  setPreferredLanguage() {
    const preferredLanguage = this.templateLanguages.find(
      (item, idx) => {
        const stakeholderPreferredLanguage = this.stakeholderService.getPreferredLanguageForStakeholder(this.stakeholders[0]);
        if (stakeholderPreferredLanguage && stakeholderPreferredLanguage.code) {
          return item === stakeholderPreferredLanguage.code.toUpperCase();
        }
      }
    );
    if (preferredLanguage) {
      this.medicalPreAssessment.decisionDetail.templateLanguage = preferredLanguage;
      this.selectTemplateId(this.medicalPreAssessment.decisionDetail.templateLanguage);
      return;
    } else {
       if (this.templateLanguages.length === 1) {
        this.medicalPreAssessment.decisionDetail.templateLanguage = this.templateLanguages[0];
        this.selectTemplateId(this.medicalPreAssessment.decisionDetail.templateLanguage);
       }
    }

  }

  selectTemplateId(selectedTemplateLanguage: string) {
    this.templateId = this.getTemplateId();
    this.medicalPreAssessment.decisionDetail.templateId = this.templateId;
  }

  getTemplateId() {
    const preAssesmentTemplate = this.fullTemplateList.find(
      preAssesmentTemplateResult =>
      preAssesmentTemplateResult.templateLanguage === this.medicalPreAssessment.decisionDetail.templateLanguage &&
      preAssesmentTemplateResult.templateName === this.medicalPreAssessment.decisionDetail.templateName
    );
    if (preAssesmentTemplate) {
      return preAssesmentTemplate.templateId;
    }
    return '';
  }

  mapToTemplateNameWithLanguagesList(preAssessmentTemplates: DocumentTemplate[]) {
    const templateNames = new Set();

    preAssessmentTemplates.forEach(template => {
      if (this.templatesWithLanguages[template.templateName] == null) {
        this.templatesWithLanguages[template.templateName] = [];
      }

      this.templatesWithLanguages[template.templateName].push(template.templateLanguage);
      templateNames.add(template.templateName);
    });

    this.templateNames = Array.from(templateNames);
  }

  get isDisabled() {
    return this.disabled;
  }

  get isSendButtonDisabled() {
    return this.sendButtonDisabled;
  }

  get disablePreviewPopover(): boolean {
    return false;
  }

  get disablePopover(): boolean {
    return false;
  }

  get MedicalPreAssessmentDecisionEnum() {
    return MedicalPreAssessmentDecisionEnum;
  }

  private getSingleSelectItemFromEnum(enumItem: any, selectedPath: any, prefix: string) {
    return Object.keys(enumItem).map(key => {
      return new SelectItem({
        label: this.translateService.instant(prefix + key.toString().toLowerCase().replace(/_/g, '-')),
        value: enumItem[key],
        selected: enumItem[selectedPath] === enumItem[key]
      });
    }).filter(x=>x.value === MedicalPreAssessmentDecisionEnum.PARTIALLY_COVERED || x.value === MedicalPreAssessmentDecisionEnum.FULLY_COVERED);
  }

  private getStateValue(state: MedicalPreAssessmentState, preAssessmentStatevalue: boolean)
  {
    if(preAssessmentStatevalue === true) {
      return true;
    }

    if(state.basisValid === true && state.decisionValid === true &&
       state.noteValid === true && state.questionnaireValid === true) {
      return false;
    }

    return true;
  }

  private getRequestInfoStateValue(state: MedicalPreAssessmentState, preAssessmentStatevalue: boolean)
  {
    if(preAssessmentStatevalue === true) {
      return true;
    }

    if(state.basisValid ) {
      return false;
    }

    return true;
  }
}
