import {CaseHistoryCommEntry, CaseHistoryEntry} from '@secca/shared/models/caseHistoryEntry';
import {
  AssessmentTypeEnum,
  CommunicationDirection,
  MedicalHistoryType,
  MedicalNoteTypeEnum,
  PermissionEnum
} from './../../../../../shared/models/enums';
import {CaseStakeholder} from './../../../../../shared/models/caseStakeholder';
import {CaseHistoryType, CaseSummaryWarning} from '@secca/shared/enums/case-history-type';
import {CaseSummaryHelperService} from '@secca/core/services/case-summary-helper.service';
import {CallType} from 'src/app/shared/models/enums';
import {CaseSummaryService} from '@secca/core/services/caseSummary.service';
import {UserDto} from '@secca/shared/models/userDto';
import {CaseSummary} from '@secca/shared/models/caseSummary';
import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {DictionaryService} from '@secca/core/services/dictionary.service';
import {Case} from '@secca/shared/models/case';
import {DropdownDictionary} from '@secca/shared/models/dropdownDictionary';
import {CaseSummaryFilter} from '@secca/shared/models/caseSummaryFilter';
import {CaseSummarySortOrderType} from '@secca/shared/enums/caseSummarySortOrderType';
import {AutoUnsubscribe} from '@secca/shared/decorators/auto-unsubscribe';
import {ScreeningStateDisplayValueAdapter} from '@secca/shared/enums/screening-states';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CaseLockHelperService} from '@secca/core/services/case-lock-helper.service';
import {Subscription} from 'rxjs';
import {ChannelType} from '@secca/shared/enums/channelType';
import {NoteService} from '@secca/core/services/note.service';
import {Note} from '@secca/shared/models/note';
import {DigitalConsent} from '@secca/shared/models/digital-consent';
import {CaseDialogViewerService} from '@secca/case/case-dialog-viewer.service';
import { SettingsService } from '@secca/core/services/settings.service';
import {CaseHistoryDataFieldChangeDto} from "@secca/shared/models/CaseHistoryDataFieldChangeDto";
import { MasterListService } from '@secca/core/services/masterlist-service';
import { Channel } from '@secca/shared/models/channel';
import {CaseHistoryDeletedFieldValueDto} from '@secca/shared/models/CaseHistoryDeletedFieldValueDto';
import {ModalDialogComponent} from '@secca/shared/components/modal-dialog/modal-dialog.component';
import {ModalDialogConfiguration} from '@secca/shared/models/modal/modal-dialog-configuration';
import {CaseCommunicationService} from '@secca/core/services/case-communication.service';
import {CaseService} from '@secca/core/services/case.service';
import {DeleteIdWithReason} from '@secca/shared/models/delete-id-with-reason';


@Component({
  selector: 'app-case-summary-list',
  templateUrl: './case-summary-list.component.html',
  styleUrls: ['./case-summary-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
@AutoUnsubscribe
export class CaseSummaryListComponent {

  @Input()
  stakeholders: CaseStakeholder[];

  @Input()
  loadedFromOutputManagement: boolean;

  @Input()
  case: Case;

  @Input() consent: DigitalConsent;

  @Input() numberOfLinkedCases: number;

  @Output()
  sortChangeEvent: EventEmitter<string> =  new EventEmitter();

  @Output()
  channelTypeChanged: EventEmitter<string> =  new EventEmitter();

  $subscr1: Subscription;

  sortItems: DropdownDictionary[] = [
    { id: 'ASC', name: CaseSummarySortOrderType.ASC },
    { id: 'DESC', name: CaseSummarySortOrderType.DESC },
  ];
  sortItem = 'DESC';
  caseSummaryList: CaseHistoryEntry[];
  caseSummaryUser: UserDto[];
  caseSummary: CaseSummary;
  caseSummaryFilter: CaseSummaryFilter;
  previousCaseSummaryFilter: CaseSummaryFilter = null;
  caseSummaryEntities: any;
  showYesNo: boolean;
  noteIdToDelete: number;
  medicalAssessmentId: number;
  channelTypes: Channel[];
  showEntryMenu = false;
  showActionButton = true;
  public smsCreatedByMap: HashTable = {};
  deleteReason: string;
  deleteItem: () => void;

  constructor(
    private caseSummaryService: CaseSummaryService,
    private caseSummaryHelperService: CaseSummaryHelperService,
    private dictionaryService: DictionaryService,
    private modalService: NgbModal,
    // screeningStateDisplayValueAdapter in used in the HTML and must not be deleted
    public screeningStateDisplayValueAdapter: ScreeningStateDisplayValueAdapter,
    public caseLockHelperService: CaseLockHelperService,
    private noteService: NoteService,
    private masterListService: MasterListService,
    private dialogViewerService: CaseDialogViewerService,
    public settingsService: SettingsService,
    public caseCommunicationService: CaseCommunicationService,
    public caseService: CaseService
  ) {
    this.$subscr1 = this.caseSummaryService.getCaseSummarySubject().subscribe(result => {
      if (result && this.case) {
        this.caseSummaryList = result.caseHistoryList;
        this.caseSummaryUser = result.users;
        this.caseSummaryEntities = result.entities;
      } else {
        this.caseSummaryList = null;
      }
    });

    this.masterListService.getAllChannelTypes().subscribe(channelTypes => {
      this.channelTypes = channelTypes;
    });
  }

  get CaseHistoryType() {
    return CaseHistoryType;
  }

  get MedicalNoteTypeEnum() {
    return MedicalNoteTypeEnum;
  }

  get AssessmentTypeEnum() {
    return AssessmentTypeEnum;
  }


  get MedicalHistoryType() {
    return MedicalHistoryType;
  }

  get CallType() {
    return CallType;
  }

  get ChannelType() {
    return ChannelType;
  }

  get errorMessage() {
    return CaseSummaryWarning.warning;
  }

  get CommunicationDirection() {
    return CommunicationDirection;
  }

  deleteReasonEdited(entry: CaseHistoryEntry): boolean {
    const edits = this.caseSummaryHelperService.getDeleteEdits(this.caseSummaryEntities, entry.id);
    return edits.length > 0;
  }

  deleteReasonValue(entry: CaseHistoryEntry): string {
      const edits = this.caseSummaryHelperService.getDeleteEdits(this.caseSummaryEntities, entry.id);
      if (edits.length > 0) {
        return edits[0].dataFieldChanges.filter(f => f.field === 'reason')[0].newValue;
      }
      return entry.deletedFieldValues.filter(f => f.deletedField === 'reason')[0].deletedValue;
  }

  openModal() {

    const modalRef = this.modalService.open(ModalDialogComponent);
    modalRef.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'default-modal-header',
      title: 'case-summary-modal-gdpr-delete',
      text: '',
      fieldText: '',
      footer: 'case-summary-modal-gdpr-delete-proceed',
      showYesNoButton: false,
      yes: 'Yes',
      no: 'No',
      labelTextAboveTextArea: 'case-summary-modal-gdpr-delete-reason-label',
      placeholderText: 'case-summary-modal-gdpr-delete-reason-placeholder',
      showTextAreaWithYesNo: true,
      showTextArea: true,
      isBody: true,
      isFooter: true,
      modalYesHoverDialog: 'case-summary-modal-gdpr-delete-yes-hover'
    });

    modalRef.componentInstance.saveandcloseEvent.subscribe(
      emittedEvent => {
        this.closeModal();
        if (emittedEvent) {
          this.deleteReason = emittedEvent;
        }
      },
      error => { }
    );

    modalRef.componentInstance.closeModalEvent.subscribe(
      emittedEvent => {
        this.closeModal();
      },
      error => console.log('error', error)
    );
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  openModalEdit(entry: CaseHistoryEntry) {
    const modalRef = this.modalService.open(ModalDialogComponent);
    modalRef.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'default-modal-header',
      title: 'case-summary-modal-gdpr-edit',
      text: '',
      fieldText: '',
      footer: 'case-summary-modal-gdpr-edit-proceed',
      showYesNoButton: false,
      yes: 'Yes',
      no: 'No',
      placeholderText: 'case-summary-modal-gdpr-edit-reason-placeholder',
      showTextAreaWithYesNo: true,
      showTextArea: true,
      isBody: true,
      isFooter: true,
      modalYesHoverDialog: 'case-summary-modal-gdpr-edit-yes-hover'
    });
    modalRef.componentInstance.fieldText = this.deleteReasonValue(entry);

    modalRef.componentInstance.saveandcloseEvent.subscribe(
      emittedEvent => {
        this.saveDeleteReason(entry, emittedEvent);
        this.closeModal();
        this.caseService.publishCaseNotesChanges(+ this.case.id);
      },
      error => { }
    );

    modalRef.componentInstance.closeModalEvent.subscribe(
      emittedEvent => {
        this.closeModal();
      },
      error => console.log('error', error)
    );
  }

  saveDeleteReason(entry: CaseHistoryEntry, reason: string): void {
    this.caseCommunicationService.editCaseHistoryWithDeleteReason(entry.caseId, entry.id, reason)
      .subscribe(s => {

      });
  }
  insertLineBetweenCaseSummaryItems(index: number) {
    return this.caseSummaryHelperService.insertLineBetweenCaseSummaryItems(this.caseSummaryList, index);
  }

  showNoteEntity(caseSummaryType: CaseHistoryType) {
    return this.caseSummaryHelperService.showNoteEntity(caseSummaryType);
  }

  getUserImage(userId: string) {
    return this.caseSummaryHelperService.getUserImage(this.caseSummaryUser, userId);
  }

  getUserInitials(userId: string) {
    return this.caseSummaryHelperService.getUserInitials(this.caseSummaryUser, userId);
  }

  showMedicalItemIfNotDeleted(entry: CaseHistoryEntry): boolean {
    if (entry.caseHistoryType === CaseHistoryType.MEDICAL_ASSESSMENT_CREATE) {
      if (this.getField(entry.dataFieldChanges, 'MedicalType').newValue === MedicalNoteTypeEnum.ADMISSION ||
          this.getField(entry.dataFieldChanges, 'MedicalType').newValue === MedicalNoteTypeEnum.COMMENT) {
        return this.caseSummaryHelperService.getMedicalNoteDeleted(this.caseSummaryEntities, entry.entityId) === null;
      } else if (this.getField(entry.dataFieldChanges, 'MedicalType').newValue === 'MEDICAL_REPORT_SUPPLIER_PORTAL') {
        return this.caseSummaryHelperService.getMedicalreportDeleted(this.caseSummaryEntities, entry.entityId) === null;
      } else {
        return this.caseSummaryHelperService.getMedicalAssessmentDeleted(this.caseSummaryEntities, entry.entityId) === null;
      }
    } else {
      return true;
    }
  }

  showMedicalPreAssessmentItemIfNotDeleted(entry: CaseHistoryEntry): boolean {
    if (entry.caseHistoryType === CaseHistoryType.MEDICAL_PRE_ASSESSMENT_CREATE) {
      if (this.getField(entry.dataFieldChanges, 'MedicalType')?.newValue === MedicalNoteTypeEnum.PRE_ASSESSMENT_COMMENT) {
        return this.caseSummaryHelperService.getMedicalNoteDeleted(this.caseSummaryEntities, entry.entityId) === null;
      }
      else {
        return this.caseSummaryHelperService.getMedicalPreAssessmentDeleted(this.caseSummaryEntities, entry.entityId) === null;
      }
    } else {
      return true;
    }
  }

  isMedicalAssessment(entry: CaseHistoryEntry, assessmentType: AssessmentTypeEnum) {
    return this.getField(entry.dataFieldChanges, 'MedicalType')?.newValue === assessmentType;
  }

  isAnyMedicalAssesment(entry) {
    return this.isMedicalAssessment(entry, AssessmentTypeEnum.REGULAR) || this.isMedicalAssessment(entry, AssessmentTypeEnum.CLAIMS) || this.isMedicalAssessment(entry, AssessmentTypeEnum.LIGHT);
   }

  medicalAssessmentNo(entry: CaseHistoryEntry) {
    return this.getField(entry.dataFieldChanges, 'MedicalNumber').newValue;
  }

  isAnyMedicalPreAssesment(entry) {
    return this.isMedicalAssessment(entry, AssessmentTypeEnum.PREASSESSMENT) ||
           this.isMedicalAssessment(entry, AssessmentTypeEnum.PREASSESSMENT_TRAVEL) ||
           this.isMedicalAssessment(entry, AssessmentTypeEnum.PREASSESSMENT_CANCELLATION);
   }

  medicalPreAssessmentNo(entry: CaseHistoryEntry) {
    return this.getField(entry.dataFieldChanges, 'MedicalNumber')?.newValue;
  }

  showDocumentMoveIfMovedToCase(entry: CaseHistoryEntry): boolean {
    if (entry.caseHistoryType === CaseHistoryType.DOCUMENT_MOVE) {
      return entry.dataFieldChanges && entry.dataFieldChanges.length > 1 ? entry.dataFieldChanges[1].newValue === this.case.caseNumber : false;
    } else {
      return true;
    }
  }

  sortChanged(value: string): void {
    this.sortItem = value;
    this.sortChangeEvent.emit(value);
  }

  hideMe() {
    this.showYesNo = !this.showYesNo;
  }

  deleteNote(noteIdToDelete: DeleteIdWithReason) {
    if (noteIdToDelete.reason === null) {
      this.deleteItem = () => {
        this.dictionaryService.deleteNote(noteIdToDelete.id).subscribe(deleteNote => {
          this.noteService.editedNoteUpdated.next({id: +(deleteNote as Note).id, note: (deleteNote as Note)});
        });
      };
      this.showYesNo = !this.showYesNo;
    } else {
      this.dictionaryService.deleteSupoCupoNote(noteIdToDelete.id, noteIdToDelete.reason).subscribe(deleteNote => {
        this.caseService.publishCaseNotesChanges(+ this.case.id);
      });
  }
}

  deleteGdprNote(entry: CaseHistoryEntry) {
    this.deleteItem = () => {
      this.caseCommunicationService.deleteCaseHistoryDelete(entry.caseId, entry.id)
        .subscribe(s => {
          this.caseService.publishCaseNotesChanges(+ this.case.id);
        });
    };
    this.showYesNo = !this.showYesNo;
  }

  openAssessment(id, index) {
    this.medicalAssessmentId = id;
    this.dialogViewerService.openMedicalAssessmentDialog(this.case, null, null, null, this.medicalAssessmentId, true);
  }

  openPreAssessment(medicalPreAssessmentId, index) {
    this.dialogViewerService.openPreMedicalAssessmentDialog(this.case, null, null, null, medicalPreAssessmentId, true);
  }

  channelTypeUpdated(channelType: string) {
    this.channelTypeChanged.emit(channelType);
  }

  getMedicalPreAssessmentDecision(entry: CaseHistoryEntry): string {
    return this.caseSummaryHelperService.getMedicalPreAssessmentDecision(this.caseSummaryEntities, entry.entityId);
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  getField(dataFieldChanges: CaseHistoryDataFieldChangeDto[], fieldType: string): CaseHistoryDataFieldChangeDto {
    return dataFieldChanges?.find(field => field.field === fieldType);
  }

  getReasonField(deletedFieldValues: CaseHistoryDeletedFieldValueDto[], deletedFieldType: string): CaseHistoryDeletedFieldValueDto {
    return deletedFieldValues?.find(deletedField => deletedField.deletedField === deletedFieldType);
  }

  getChannelTypeName(channelType: string) {
    return this.channelTypes?.find(c => c.channelTypeEnumValue === channelType)?.channelTypeName;
  }
}
class SmsReceiverStakeholderInfo {
  type: string;
  icon: string;
  unhandled: boolean;
}

interface HashTable {
  [key: string]: SmsReceiverStakeholderInfo;
}
