import { Pipe, PipeTransform } from '@angular/core';
import { Moment } from 'moment-timezone';

@Pipe({
  name: 'TimezoneOffset',
})
export class TimezoneOffset implements PipeTransform {
  transform(moment: Moment, timeZoneId: string): string {
    if (!timeZoneId) {
      timeZoneId = 'Europe/Copenhagen';
    }
    return moment.clone().tz(timeZoneId).format('H:mm');
  }
}
