import { Component, OnInit, ViewChild } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { ModalDialogComponent } from '@secca/shared/components/modal-dialog/modal-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDialogConfiguration } from '@secca/shared/models/modal/modal-dialog-configuration';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';

@Component({
  selector: 'app-sign-out',
  templateUrl: './sign-out.component.html',
  styleUrls: ['./sign-out.component.scss']
})
@AutoUnsubscribe
export class SignOutComponent implements OnInit {
  @ViewChild(ModalDialogComponent) modalDialogComponent: ModalDialogComponent;

  constructor(private keycloakService: KeycloakService, private modalService: NgbModal) { }

  ngOnInit(): void { }

  signOut(): void {
    const modalRef = this.modalService.open(ModalDialogComponent);
    modalRef.componentInstance.items = [];
    modalRef.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'default-modal-header',
      title: 'sign-out-warning-modal-title',
      text: null,
      footer: 'sign-out-warning-modal-footer',
      yes: 'default-modal-dialog-yes',
      no: 'default-modal-dialog-no',
      isBody: true,
      isFooter: true,
    });
    modalRef.componentInstance.closeModalEvent.subscribe(
      closedClickingYes => {
        if (closedClickingYes) {
          sessionStorage.removeItem('team');
          sessionStorage.removeItem('top-menu-recent-cases');
          this.keycloakService.logout('https://www.sos.eu/da/');
          this.modalService.dismissAll();
        } else {
          modalRef.close();
        }
      },
      error => console.log(error)
    );
  }
}
