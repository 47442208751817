<div [appDisableInput]="caseStateService.isCaseDisabled()">
  <div class="case-plans-container">
    <div class="case-top">
      <div class="row add-service">
        <div class="col-3 d-flex flex-row">
          <div *permission="PermissionEnum.PLANS_CREATE">
            <button class="add-service-button mt-3 ms-4 mb-3" (click)="addNewServiceOrder()" [disabled]="isAddSoDisabled()"><!--span class="shortcut-key">A</span-->Add service
            </button>
          </div>
          <div *permission="PermissionEnum.REFUND_CREATE; hideType: PermissionHideTypeEnum.REMOVE">
            <button
              class="add-service-button mt-3 ms-1 mb-3"
              (click)="addNewClaim()"
              [class.primary-button-inactive]="isRefundDisabled()"
              [disabled]="isRefundDisabled()">
              <div [disablePopover]="isRefundHoverDisabled()" triggers="hover" placement="end"
                   [ngbPopover]="refundPopOverText()">Add re<!--span class="shortcut-key">f</span-->fund</div>
            </button>
          </div>
        </div>
        <div class="col-9 case-coverage">
          <app-case-coverage [caseId]="case.id"></app-case-coverage>
        </div>
      </div>
  </div>
  <div>
    <app-plan *ngIf="showPlans"
      [caseId]="case.id"
      [incidentId]="case.incident.id"
      [iccServiceOrders]= "iccServiceOrders"
      (openServiceEditDialog)="onOpenServiceEditDialog($event)">
    </app-plan>
  </div>
</div>
</div>
