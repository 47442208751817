import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';

export class CaseHistoryRuleData {
  actionName: string;
  customerProfileName: string;
  error: string;
  eventName: string;
  name: string;
  ruleName: string;
  taskId: number;

  public constructor(init?: Partial<CaseHistoryRuleData>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})

export class CaseHistoryRuleDataAdapter implements Adapter<CaseHistoryRuleData> {
  adapt(item: any): CaseHistoryRuleData {
    return new CaseHistoryRuleData({
      actionName: item.actionName,
    customerProfileName: item.customerProfileName,
    error: item.error,
    eventName: item.eventName,
    name: item.name,
    ruleName: item.ruleName,
    taskId: item.taskId,
    });
  }
}
