<div class="d-flex">
  <div class="w-25">
    <div class="text-label">{{ 'task-modal-case-id' | translate }}</div>
    <div class="text-content">{{ case.caseNumber }}</div>
  </div>
  <div class="w-25" *ngIf='!isCoordinationCase'>
    <div class="text-label">{{ 'task-modal-end-user' | translate }}</div>
    <div class="text-content" *ngIf="case.caseNumber">{{ trimText(case.endUserName, 40) }}</div>
  </div>
  <div class="w-25" *ngIf='isCoordinationCase'>
    <div class="text-label">{{ 'task-modal-coordination-name' | translate }}</div>
    <div class="text-content" *ngIf="case.caseNumber">{{ trimText(case.coordinationCaseName, 40) }}</div>
  </div>
  <div class="w-25">
    <div class="text-label">{{ 'task-modal-time-difference' | translate }}</div>
    <div class="text-content">{{ actualTimeZoneId | timeDiff: true }}</div>
  </div>
</div>
<div class="row-separator"></div>
<div class="d-flex">
  <div class="w-25">
    <div class="text-label">{{ 'task-modal-customer' | translate }}</div>
    <div class="text-content">{{ profileName }}</div>
  </div>
  <div class="w-25">
    <div class="text-label">{{ 'task-modal-location' | translate }}</div>
    <div class="text-content">{{ trimText(location, 40) }}</div>
  </div>
  <div class="w-25">
    <div class="text-label">{{ 'task-modal-primary-case-handler' | translate }}</div>
    <div class="text-content">{{ trimText(primaryCaseHandler, 40) }}</div>
  </div>
</div>
<div class="row-separator"></div>
<div class="d-flex">
  <div class="w-25">
    <div class="text-label">{{ 'task-modal-reserve' | translate }}</div>
    <div class="text-content">{{ reserve | numberFormat }} {{ currency }}</div>
  </div>
  <div class="w-25">
    <div class="text-label">{{ 'task-modal-cause' | translate }}</div>
    <div class="text-content">{{ trimText(incidentCause, 40) }}</div>
  </div>
  <div class="w-25">
    <div class="text-label">{{ 'task-modal-last-case-action-by' | translate }}</div>
    <div *ngIf="lastCaseActionBy != undefined">
      <div class="text-content">{{ trimText(lastCaseActionBy, 40) }}</div>
      <div class="text-content">on {{lastCaseActionTime | date:'dd MMM yyyy HH:mm':'local'}}</div>
    </div>
  </div>
</div>
