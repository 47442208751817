
export enum ColorGroup {
  red,
  blue,
  green,
  purple,
  gray,
  lightGray,
  yellow,
  darkBlue,
  darkGreen,
  brown
}

export class ColorGroupHtml {
  static getBackgroundColor(color: ColorGroup): string {
    switch (color) {
      case ColorGroup.red:
        return '#FCD8D8';
      case ColorGroup.blue:
        return '#C0EDFC';
      case ColorGroup.green:
        return '#9EFFE7';
      case ColorGroup.purple:
        return '#C688D8';
      case ColorGroup.gray:
        return '#CCCCCC';
      case ColorGroup.lightGray:
        return '#D3D3D3';
      case ColorGroup.yellow:
        return '#ffefcb';
      case ColorGroup.darkBlue:
        return '#3b74db';
      case ColorGroup.darkGreen:
        return '#08d899';
      case ColorGroup.brown:
        return '#e29a71';
      default:
        return '#CCCCCC';
    }
  }

  static getBorderColor(color: ColorGroup): string {
    switch (color) {
      case ColorGroup.red:
        return '#F50A37';
      case ColorGroup.blue:
        return '#23AFDC';
      case ColorGroup.green:
        return '#34D1AA';
      case ColorGroup.purple:
        return '#905C9F';
      case ColorGroup.gray:
        return '#979DB1';
      case ColorGroup.lightGray:
        return '#979DB1';
      case ColorGroup.yellow:
        return '#ffd36f';
      case ColorGroup.darkBlue:
        return '#00318d';
      case ColorGroup.darkGreen:
         return '#059b6e';
      case ColorGroup.brown:
        return '#b97a57';
      default:
        return '#979DB1';
    }
  }

  static getDiagonalColor(color: ColorGroup): string {
    switch (color) {
      case ColorGroup.red:
        return '#F2E3E3';
      case ColorGroup.blue:
        return '#D0E5EB';
      case ColorGroup.green:
        return '#B1ECDD';
      case ColorGroup.purple:
        return '#BE97C8';
      case ColorGroup.gray:
        return '#B5B5B5';
      case ColorGroup.lightGray:
        return '#CCCCCC';
      case ColorGroup.yellow:
        return '#f1ede6';
      case ColorGroup.darkBlue:
        return '#00318d';
      case ColorGroup.darkGreen:
        return '#79c9b0';
      case ColorGroup.brown:
        return '#e6cfc4';
      default:
        return '#000';
    }
  }
}
