import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CoordinationCaseDetail } from '@secca/shared/models/coordination-case-details';
import { CoordinationTransportServiceOrderExtension } from '@secca/shared/models/service-order/coordination-transport-service-order-extension';
import { ICCAmbulanceFlight } from '@secca/case/components/case-plans/case-plans/add-service-order/models/icc-ambulanceFlight';
import { SubStakeholdersConfig } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholdersConfig';
import { SubTravellersResourceConfig } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subTravellersResourceConfig';
import { ServiceOrderType } from '@secca/shared/models/service-order-type';
import { StatusTypes } from '@secca/shared/models/enums';
import { SubTravellers } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subTravellers';
import { Location } from '@secca/shared/models/location';
import { SubStakeholders } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholders';
import { CaseStateService } from '@secca/core/state-services/case-state.service';

@Component({
  selector: 'app-end-user-linked-case-component',
  templateUrl: './end-user-linked-case.component.html',
  styleUrls: ['./end-user-linked-case.component.scss']

})
export class EndUserLinkedCaseComponent implements OnInit {
  @Input() endUserDisplayReadOnly: boolean;
  @Input() caseId: string;
  @Input() endUserCaseDetail: CoordinationCaseDetail;
  @Input() coordinationTransportServiceOrderExtension: CoordinationTransportServiceOrderExtension;
  @Input() subStakeholdersConfig: SubStakeholdersConfig;
  @Input() serviceOrderType: ServiceOrderType;
  @Input() status: StatusTypes;
  @Output() updateEnduserCaseRemark = new EventEmitter<string>();

  groundAmbulanceToCoordinationTransportResourceConfig: SubTravellersResourceConfig;
  groundAmbulanceFromCoordinationTransportResourceConfig: SubTravellersResourceConfig;
  expectedCoordinationResourceConfig: SubTravellersResourceConfig;
  confirmedCoordinationResourceConfig: SubTravellersResourceConfig;
  expectedFlight: SubTravellers;
  confirmationFlight: SubTravellers;
  enduserCaseRemark: string;

  constructor(public caseStateService: CaseStateService) {
    this.expectedCoordinationResourceConfig = new SubTravellersResourceConfig();
    this.confirmedCoordinationResourceConfig = new SubTravellersResourceConfig();
    this.expectedCoordinationResourceConfig.title = 'expected-coordination-flight-suggested-route-title';
    this.confirmedCoordinationResourceConfig.title = 'confirmt-coordination-flight-suggested-route-title';
    this.confirmedCoordinationResourceConfig.travelInfoResourceConfig.agencyRefLabel = 'sub-travel-info-flight-reg-no';

    this.groundAmbulanceToCoordinationTransportResourceConfig = new SubTravellersResourceConfig();
    this.groundAmbulanceFromCoordinationTransportResourceConfig = new SubTravellersResourceConfig();
    this.groundAmbulanceToCoordinationTransportResourceConfig.title = 'ambulance-flight-ground-ambulance-to-coordination-transport-title';
    this.groundAmbulanceToCoordinationTransportResourceConfig.travelInfoResourceConfig.startDateLabel = 'ambulance-flight-ground-ambulance-to-flight-start-date';
    this.groundAmbulanceToCoordinationTransportResourceConfig.travelInfoResourceConfig.startDateLocationLabel = 'ambulance-flight-ground-ambulance-to-flight-start-location';
    this.groundAmbulanceFromCoordinationTransportResourceConfig.title = 'ambulance-flight-ground-ambulance-from-coordination-transport-title';
    this.groundAmbulanceFromCoordinationTransportResourceConfig.travelInfoResourceConfig.startDateLabel = 'ambulance-flight-ground-ambulance-from-flight-start-date';
    this.groundAmbulanceFromCoordinationTransportResourceConfig.travelInfoResourceConfig.startDateLocationLabel = 'ambulance-flight-ground-ambulance-from-flight-start-location';
  }

  ngOnInit(): void {
    this.expectedFlight = new SubTravellers();
    this.confirmationFlight = new SubTravellers();
    if (this.coordinationTransportServiceOrderExtension.expectedFlight.length !== 0) {
      this.expectedFlight = new SubTravellers();
      this.expectedFlight.flightLegs = this.coordinationTransportServiceOrderExtension.expectedFlight;
    }
    if (this.coordinationTransportServiceOrderExtension.confirmationFlight) {
      this.confirmationFlight = new SubTravellers();
      this.confirmationFlight.flightLegs = this.coordinationTransportServiceOrderExtension.confirmationFlight;
    }

    let iccAmbulanceFlights: ICCAmbulanceFlight[];
    if (this.isCommitted()) {
      iccAmbulanceFlights = this.coordinationTransportServiceOrderExtension.iccConfirmationAmbulanceFlights;
    } else {
      iccAmbulanceFlights = this.coordinationTransportServiceOrderExtension.iccExpectedAmbulanceFlights;
    }
    if (!iccAmbulanceFlights?.some(ambulanceFlight => ambulanceFlight.linkedCaseId === this.endUserCaseDetail.caseId)) {
      const iccAmbulanceFlight = new ICCAmbulanceFlight();
      iccAmbulanceFlight.linkedCaseId = this.endUserCaseDetail.caseId;
      iccAmbulanceFlight.groundTransportToFlightNotIncluded = false;
      iccAmbulanceFlight.groundTransportFromFlightNotIncluded = false;
      iccAmbulanceFlight.subGroundTransportToFlight = new SubTravellers();
      iccAmbulanceFlight.subGroundTransportFromFlight = new SubTravellers();
      iccAmbulanceFlight.groundTransportToFlight = iccAmbulanceFlight.subGroundTransportToFlight.flightLegs[0];
      iccAmbulanceFlight.groundTransportFromFlight = iccAmbulanceFlight.subGroundTransportFromFlight.flightLegs[0];
      iccAmbulanceFlight.subGroundTransportToFlightStakeholders = new SubStakeholders();
      iccAmbulanceFlight.subGroundTransportFromFlightStakeholders = new SubStakeholders();
      iccAmbulanceFlight.groundTransportToFlightStakeholders = iccAmbulanceFlight.subGroundTransportToFlightStakeholders.stakeholders;
      iccAmbulanceFlight.groundTransportFromFlightStakeholders = iccAmbulanceFlight.subGroundTransportFromFlightStakeholders.stakeholders;
      iccAmbulanceFlights.push(iccAmbulanceFlight);
    }
    this.enduserCaseRemark = this.coordinationTransportServiceOrderExtension.icclinkedCasesRemarks.getByCase(this.endUserCaseDetail.caseId);
  }

  setEnduserCaseRemark(value) {
    this.updateEnduserCaseRemark.emit(value);
  }

  changeStatusOnServiceOrders(caseId: string) {
    this.coordinationTransportServiceOrderExtension.iccConfirmationFlightStakeholders.changeStatusOnServiceOrders(caseId);
    this.endUserCaseDetail.serviceOrders.forEach(serviceOrder => {
      if(serviceOrder.caseId.toString() === caseId.toString()) {
        serviceOrder.status = serviceOrder.status === StatusTypes.COMMITTED || serviceOrder.status === null ? StatusTypes.CANCELLED : StatusTypes.COMMITTED;
      }
    })
  }

  getAmbulanceFlight(caseId: number) {
    let iccAmbulanceFlights: ICCAmbulanceFlight[];
    if (this.isCommitted() ||  this.status === StatusTypes.CANCELLED) {
      iccAmbulanceFlights = this.coordinationTransportServiceOrderExtension.iccConfirmationAmbulanceFlights;
    } else {
      iccAmbulanceFlights = this.coordinationTransportServiceOrderExtension.iccExpectedAmbulanceFlights;
    }
    return  iccAmbulanceFlights.find(ambulanceFlight => ambulanceFlight.linkedCaseId === caseId);
  }

  toFlightNotIncluded(value, caseId: number) {
    const iccAmbulanceFlight = this.getAmbulanceFlight(caseId);
    iccAmbulanceFlight.groundTransportToFlightNotIncluded = value;
    if (iccAmbulanceFlight.groundTransportToFlightNotIncluded) {
      this.resetTravellers(iccAmbulanceFlight.subGroundTransportToFlight);
    }
  }

  fromFlightNotIncluded(value, caseId: number) {
    const iccAmbulanceFlight = this.getAmbulanceFlight(caseId);
    iccAmbulanceFlight.groundTransportFromFlightNotIncluded = value;
    if (iccAmbulanceFlight.groundTransportFromFlightNotIncluded) {
      this.resetTravellers(iccAmbulanceFlight.subGroundTransportFromFlight);
    }
  }

  isCommitted(): boolean {
    return this.status === StatusTypes.COMMITTED;
  }

  private resetTravellers(travellers: SubTravellers) {
    const flightLeg = travellers.flightLegs[0];
    flightLeg.departureDate = null;
    const departureLocation: Location = new Location();
    departureLocation.id = flightLeg.departureFrom.id;
    flightLeg.departureFrom = departureLocation;
    const arrivalLocation: Location = new Location();
    arrivalLocation.id = flightLeg.departureFrom.id;
    flightLeg.arrivalAt = arrivalLocation;
    flightLeg.arrivalDate = null;
  }
}
