import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MedicalAssessmentServiceOrderExtension} from '@secca/shared/models/service-order/medical-assessment-service-order-extension';
import {ServiceOrder} from '@secca/shared/models/service-order/service-order';
import {ServiceOrderHoverExtensionComponent} from '@secca/case/components/case-plans/plan/service-order-hover/service-order-hover-extension-component';
import {CaseStakeholder} from '@secca/shared/models/caseStakeholder';
import { AssigneeEnum, StatusTypes } from '@secca/shared/models/enums';
import { CaseService } from '@secca/core/services/case.service';
import { User } from '@secca/shared/models/user';

@Component({
  selector: 'app-medical-assessment-service-order-hover',
  templateUrl: './medical-assessment-service-order-hover.component.html',
  styleUrls: ['./medical-assessment-service-order-hover.component.scss']
})
export class MedicalAssessmentServiceOrderHoverComponent implements ServiceOrderHoverExtensionComponent, OnChanges, OnInit {
  @Input() serviceOrder: ServiceOrder;
  @Input() caseStakeholders: CaseStakeholder[];

  assessmentType: string;
  medicalStaff: User;
  private medicalTeam: User[];

  constructor(
    private caseService: CaseService
  ){}

  ngOnInit() {
    this.caseService.getUsersByTeam('ADT15').subscribe(users => {
      this.medicalTeam = users;
      if (this.extension.assignedMedicalStaffId){
        this.medicalStaff = this.medicalTeam.find(user =>
          user.id.toString() === this.extension.assignedMedicalStaffId.toString()
        );
      }
    });
  }

  ngOnChanges(change: SimpleChanges) {
    this.assessmentType =  this.extension.getCommitmentType() ? this.extension.getCommitmentType().name : ""
  }

  get extension(): MedicalAssessmentServiceOrderExtension {
    return this.serviceOrder.extension as MedicalAssessmentServiceOrderExtension;
  }
  
  get statusType(): string {
    switch (this.serviceOrder.status) {
      case StatusTypes.CANCELLED:
        return 'service-order-cancelled';
      case StatusTypes.COMMITTED:
        return 'service-order-committed';
      case StatusTypes.EXPECTED:
        return 'service-order-expected';
      case StatusTypes.NOT_COVERED:
        return 'service-order-not-covered';
      case StatusTypes.PARTIALLY_COMMITTED:
        return ''
    }
  }

  get assignee(): string {
    switch(this.extension.assignee) {
      case AssigneeEnum.MEDICAL_ADVICE:
        return 'medical-assessment-service-order-assignee-send-to-medical-advise';
      case AssigneeEnum.PRE_DEPARTURE_MEDICAL_ADVICE:
        return 'medical-assessment-service-order-assignee-send-to-pre-departure-medical-advice';
      case AssigneeEnum.MEDICAL_STAFF:
        return 'medical-assessment-service-order-assignee-send-to-medical-staff';
      default:
        return '';
    }
  }

  onRefresh() {
  }
}
