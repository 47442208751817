import { Component, Input, OnInit } from '@angular/core';
import {SubRemarksRefund} from "@secca/case/components/case-plans/case-plans/add-service-order/models/subRemarksRefund";

@Component({
  selector: 'app-sub-remarks-refund',
  templateUrl: './sub-remarks-refund.component.html',
  styleUrls: ['./sub-remarks-refund.component.scss']
})
export class SubRemarksRefundComponent implements OnInit {
  @Input() subRemarksRefund: SubRemarksRefund;
  @Input() readOnlyMode: boolean = false;
  constructor() { }

  ngOnInit() {
  }

}
