import { StatusTypes } from '@secca/shared/models/enums';
import { DateHelper } from '../../../../../../shared/helpers/date-helper';
import { ServiceOrderHeightEnum } from './../ServiceOrderHeightEnum';
import { PlanDisplayHelper } from '../plan-display-helper';
import { ServiceOrder } from '@secca/shared/models/service-order/service-order';
import * as moment from 'moment';
import { ServiceTypeEnumIcon, ServiceTypeIconBackgroundColor } from 'src/app/shared/models/enums';
import { ColorGroup } from './color-group-enum';
import _, { over } from 'lodash';

export class ServiceOrderHelper {
  private currentServiceOrder: ServiceOrder;
  private remainingServiceOrders: ServiceOrder[];​

  constructor(currentServiceOrder: ServiceOrder, allServiceOrders: ServiceOrder[]) {
    this.currentServiceOrder = currentServiceOrder;
    this.remainingServiceOrders = allServiceOrders.filter(
      a =>
        (a.serviceId == null || a.serviceId !== this.currentServiceOrder.serviceId) && a.laneNumber === this.currentServiceOrder.laneNumber
    );
  }

  public get hoursLast(): number {
    let startMoment = moment(this.currentServiceOrder.getStartDateLocal());
    let endMoment = moment(this.currentServiceOrder.getEndDateLocal());
    return Math.floor(moment.duration(endMoment.diff(startMoment)).asHours());
  }​

  public get hoursInLastDay(): number {
    let startDateLocal = new Date(this.currentServiceOrder.getEndDateLocal().getTime());
    startDateLocal.setHours(0, 0, 0, 0);
    let startMoment = moment(startDateLocal);
    let endMoment = moment(this.currentServiceOrder.getEndDateLocal());
    return Math.ceil(moment.duration(endMoment.diff(startMoment)).asHours());
  }
​
  public get daysLast(): number {
    let startDateLocal = new Date(this.currentServiceOrder.getStartDateLocal().getTime());
    startDateLocal.setHours(0, 0, 0, 0);
    let startMoment = moment(startDateLocal);
    let endMoment = moment(this.currentServiceOrder.getEndDateLocal());
    return Math.floor(moment.duration(endMoment.diff(startMoment)).asDays());
  }
​
  public get serviceOrderHeightEnum(): ServiceOrderHeightEnum {
    if (!DateHelper.datesInTheSameDay(this.currentServiceOrder.getStartDateLocal(), this.currentServiceOrder.getEndDateLocal())) {
      if (this.hoursLast < 12) {
        return ServiceOrderHeightEnum.MultipleDaysLessThan12h;
      } else {
        return ServiceOrderHeightEnum.MultipleDaysMoreThan12h;
      }
    }
    if (this.hoursLast < 12) {
      return ServiceOrderHeightEnum.HalfCellHeight;
    }
    if (this.hoursLast <= 24) {
      return ServiceOrderHeightEnum.FullCellHeight;
    }
    return ServiceOrderHeightEnum.MultipleDaysMoreThan12h;
  }

  public isThereAnotherServiceOrderInTheDateOnTheSamePosition(date: Date): boolean {
    return (
      this.remainingServiceOrders.filter(
        a =>
          (DateHelper.datesInTheSameDay(a.getStartDateLocal(), date) || DateHelper.datesInTheSameDay(a.getEndDateLocal(), date)) &&
          a.overlappingPosition === this.currentServiceOrder.overlappingPosition
      ).length > 0
    );
  }

  public numberOfOverlappingServiceOrders(): number {
    if ( this.currentServiceOrder.overlappingGroupId ) {
      const group = this.remainingServiceOrders.find(s => s.serviceId !== this.currentServiceOrder.serviceId && s.overlappingGroupId === this.currentServiceOrder.overlappingGroupId && s.numberOfOverlappingServiceOrders !== null );
      if ( group ) {
        return group.numberOfOverlappingServiceOrders;
      }
    }

    const overlappingGroup = this.findOverlappingServiceGroup(this.currentServiceOrder, this.remainingServiceOrders);
    this.currentServiceOrder.overlappingGroupId = this.currentServiceOrder.serviceId;

    let overlappingCnt = 0;
    if ( overlappingGroup.length > 0 ) {
      overlappingGroup.push(this.currentServiceOrder);

      overlappingGroup.forEach(overlappingService => {
        let overlappingServices = this.findOverlappingServices(overlappingService, overlappingGroup);

        const overlapping = this.findOverlappingCount(overlappingServices, 0, 1);
        if ( overlapping > overlappingCnt ) {
          overlappingCnt = overlapping;
        }
      });

      overlappingCnt++;
    }

    return overlappingCnt;
  }
  ​
  private findOverlappingCount(serviceOrders: ServiceOrder[], overlappingCnt: number, level: number): number {
    if ( level <= overlappingCnt ) {
      return overlappingCnt;
    }

    for ( let overlappingService of serviceOrders ) {
      let overlappingServices = this.findOverlappingServices(overlappingService, serviceOrders.filter(s => s.serviceId !== overlappingService.serviceId));
      if ( overlappingServices.length > 0 ) {
          return this.findOverlappingCount(overlappingServices, overlappingCnt+1, level+1);
      }
      else {
        return overlappingCnt;
      }
    };

    return overlappingCnt;
  }

  private findOverlappingServiceGroup(serviceOrder: ServiceOrder, serviceOrders: ServiceOrder[]): ServiceOrder[] {
    this.findOverlappingGroup(serviceOrder, serviceOrders, serviceOrder.serviceId, 1);
    return this.findOverlappingByGroupId(serviceOrder.serviceId, serviceOrders);
  }

  private findOverlappingGroup(serviceOrder: ServiceOrder, serviceOrders: ServiceOrder[], serviceId: string, level: number): number {
    if ( level > 4 ) {
      return;
    }

    let overlappingServicesOrders = this.findOverlappingServices(serviceOrder, serviceOrders.filter(s => s.serviceId !== serviceOrder.serviceId));
    if ( overlappingServicesOrders.length > 0 ) {
      for ( let overlappingService of overlappingServicesOrders ) {
        overlappingService.overlappingGroupId = serviceId;
        this.findOverlappingGroup(overlappingService, serviceOrders, serviceId, level+1);
      }
    }
  }

  private findOverlappingByGroupId(overlappingGroupId: string, serviceOrders: ServiceOrder[]): ServiceOrder[] {
    return serviceOrders.filter(
      a =>
        a.overlappingGroupId != null &&
        a.overlappingGroupId === overlappingGroupId
    );
  }

  public calculateOverlappingPosition() {
    let overlappedServices = this.findOverlappingServices(this.currentServiceOrder, this.remainingServiceOrders);
    let returnedIndex = null;

    // If a candidate position exists, try to accomodate it
    if ( this.currentServiceOrder.candidateOverlappingPosition != null ) {
      const candidatePosition = this.currentServiceOrder.candidateOverlappingPosition > this.currentServiceOrder.numberOfOverlappingServiceOrders ? this.currentServiceOrder.numberOfOverlappingServiceOrders : this.currentServiceOrder.candidateOverlappingPosition;
      if (overlappedServices.filter(a => a.overlappingPosition === candidatePosition).length === 0) {
        returnedIndex = candidatePosition;
      }
    }

    // otherwise just set it normally
    if ( returnedIndex === null ) {
      returnedIndex = overlappedServices.length;
      for (let index = 0; index < overlappedServices.length; index++) {
        if (overlappedServices.filter(a => a.overlappingPosition === index).length === 0) {
          returnedIndex = index;
          break;
        }
      }
    }

    // Special ambulanceflight handling, try to align ambulanceTo, flight, ambulanceFrom in sequence
    if ( returnedIndex !== null && this.currentServiceOrder.parentServiceId && this.currentServiceOrder.overlappingPosition === null &&
         this.currentServiceOrder.candidateOverlappingPosition === null && this.currentServiceOrder.laneNumber === 0) {
      this.handleAmbulanceflightPositioning(returnedIndex);
    }

    this.currentServiceOrder.overlappingPosition = returnedIndex;
  }

  public colorGroupForType(): ColorGroup {
    let color: ServiceTypeIconBackgroundColor = ServiceTypeEnumIcon.getBackGroundColorEnum(this.currentServiceOrder.type);
    if (this.currentServiceOrder.status === StatusTypes.CANCELLED || this.currentServiceOrder.status === StatusTypes.NOT_COVERED) {
      color = ServiceTypeIconBackgroundColor.lightGray;
    }
    switch (color) {
      case ServiceTypeIconBackgroundColor.red:
        return ColorGroup.red;
      case ServiceTypeIconBackgroundColor.blue:
        return ColorGroup.blue;
      case ServiceTypeIconBackgroundColor.green:
        return ColorGroup.green;
      case ServiceTypeIconBackgroundColor.purple:
        return ColorGroup.purple;
      case ServiceTypeIconBackgroundColor.gray:
        return ColorGroup.gray;
      case ServiceTypeIconBackgroundColor.lightGray:
        return ColorGroup.lightGray;
      case ServiceTypeIconBackgroundColor.yellow:
        return ColorGroup.yellow;
      case ServiceTypeIconBackgroundColor.darkBlue:
        return ColorGroup.darkBlue;
      case ServiceTypeIconBackgroundColor.darkGreen:
        return ColorGroup.darkGreen;
      case ServiceTypeIconBackgroundColor.brown:
        return ColorGroup.brown;
      default:
        return ColorGroup.gray;
    }
  }

  private handleAmbulanceflightPositioning(overlappingPosition: number) {
    // find and set candidate positions
    const ambulanceFlight = this.remainingServiceOrders.find(s => s.serviceId === this.currentServiceOrder.parentServiceId);
    const groundTransportFromFlight = this.remainingServiceOrders.find(s => s.parentServiceId === this.currentServiceOrder.parentServiceId && s.serviceId !== this.currentServiceOrder.serviceId);

    if ( ambulanceFlight ) {
      ambulanceFlight.candidateOverlappingPosition = overlappingPosition;
    }

    if ( groundTransportFromFlight ) {
      groundTransportFromFlight.candidateOverlappingPosition = overlappingPosition;
    }
  }

  private findOverlappingServices(serviceOrder: ServiceOrder, serviceOrders: ServiceOrder[]): ServiceOrder[] {
    return serviceOrders.filter( a => {
      return a.getEndDateLocal() >= serviceOrder.getStartDateLocal() && a.getStartDateLocal() <= serviceOrder.getEndDateLocal() && a.serviceId != serviceOrder.serviceId;
    });
  }
}
