import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'src/app/shared/decorators/auto-unsubscribe';
import { CaseCommunicationService } from './../../../../../../core/services/case-communication.service';
import { TranslateService } from '@ngx-translate/core';
import { AttachmentDto } from './../../../../../../shared/models/attachment-dto';
import { MessageTemplate } from './../../../../../../shared/models/message-template';
import { WorkInProgress } from './../../../../../../shared/models/work-in-progress';
import { WorkInProgressEnum, WorkInProgressService } from './../../../../../../core/services/work-in-progress.service';
import { ModalDialogComponent } from './../../../../../../shared/components/modal-dialog/modal-dialog.component';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { CaseService } from 'src/app/core/services/case.service';
import { AttachmentTypeEnum, MessageChannelType, ServiceTypeEnum, StakeholderTypeEnum } from './../../../../../../shared/models/enums';
import { OutputManagementService } from './../../services/output-management.service';
import { Case } from './../../../../../../shared/models/case';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDialogConfiguration } from 'src/app/shared/models/modal/modal-dialog-configuration';
import { DocumentService } from 'src/app/core/services/document.service';
import { ProviderHelper } from '@secca/shared/helpers/provider-helper';
import { MessageService } from '@secca/core/services/message.service';
import { ServiceOrderMessageRequest } from '@secca/shared/models/service-order/service-order-message-request';
import { DigitalConsent } from '@secca/shared/models/digital-consent';
import { Message } from '../../models/message';
import * as moment from 'moment';
import { MessageFormComponent } from './message-form/message-form.component';
import { ServiceOrderService } from '@secca/core/services/service-order.service';
import { InputValidationService } from '@secca/core/services/input-validation.service';
import { CaseTypeCodes } from '@secca/shared/models/case-type';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss'],
})
@AutoUnsubscribe
export class MessageDialogComponent implements OnInit {
  @ViewChild(MessageFormComponent) formComponent;

  @Input() case: Case;
  @Input() messageChannelType: MessageChannelType;
  @Input()
  public get messageRequest(): ServiceOrderMessageRequest {
    return this._messageRequest;
  }
  public set messageRequest(messageRequest: ServiceOrderMessageRequest) {
    this._messageRequest = messageRequest;
    if (this.messageRequest?.message) {
      this.messageService = this.messageRequest.messageService;
      this.getMessageTemplates();
      this.filterMessageTemplates();
    }
  }
  @Input() consent: DigitalConsent;
  @Output() messageSentEvent = new EventEmitter<void>();
  @Output() closed = new EventEmitter<number>();

  private _messageRequest: ServiceOrderMessageRequest;
  @ViewChild(ModalDialogComponent, { static: false }) modalDialogComponent: ModalDialogComponent;
  confirmCloseDialog: NgbModalRef;
  stakeholders: CaseStakeholder[] = [];
  attachments: AttachmentDto[] = [];
  attachmentsFromTemplate: AttachmentDto[] = [];
  preSelectDocumentIds: AttachmentDto[];
  messageTemplates: MessageTemplate[] = [];
  filteredMessageTemplates: MessageTemplate[] = [];
  smsPlaceholder: string = '';
  $sendSubscr;
  messageService: MessageService;
  $sendMessageSubscr: Subscription;
  $messageSubscr: Subscription;
  $closeSubscr: Subscription;
  takeStakeholderFromGopContact: boolean;

  constructor(
    private modalService: NgbModal,
    private outputManagementService: OutputManagementService,
    private caseService: CaseService,
    private documentService: DocumentService,
    private workInProgressService: WorkInProgressService,
    private translate: TranslateService,
    private caseCommunicationService: CaseCommunicationService,
    private serviceOrderService: ServiceOrderService,
    private inputValidationService: InputValidationService,
    private changeDetector: ChangeDetectorRef,
  ) {}


  ngOnInit() {
    this.messageService = this.messageRequest.messageService;
    this.messageRequest.message.messageChannelType = this.messageChannelType;
    this.preSelectDocumentIds = this.messageRequest.message.preSelectDocumentAttachments;
    this.initializePlaceholderTextForSMS(this.messageRequest.message.messageChannelType);
    this.loadStakeholders(true);
    this.getDocuments();
    this.messageRequest.message.caseNumber = this.formatSubject();
    this.workInProgressService.addWorkInProgress(new WorkInProgress({ type: WorkInProgressEnum.MESSAGE, caseId: this.case.id }));
  }

  initializePlaceholderTextForSMS(messageChannelType: MessageChannelType) {
    if (messageChannelType === MessageChannelType.SMS) {
      this.smsPlaceholder = this.translate.instant('message-dialog-sms-placeholder');
    }
  }

  messageUpdatedEvent(message: Message): void {
    this.outputManagementService.emitMessage(message);

    this.messageRequest.message = message;
    this.getMessageTemplates();
    this.filterMessageTemplates();
    if (this.messageRequest.message.send) {
      this.send();
    }
    this.changeDetector.detectChanges();
  }

  get MessageChannelType() {
    return MessageChannelType;
  }

  get messageSubjectClass() {
    switch (this.messageRequest.message.messageChannelType) {
      case MessageChannelType.SMS:
        return 'message-subject-sms';
      case MessageChannelType.FAX:
        return 'message-subject-fax-mail';
      case MessageChannelType.EMAIL:
        return 'message-subject-fax-mail';
    }
    return '';
  }

  private send() {
    this.messageRequest.message.caseId = this.case.id;
    if (this.$sendMessageSubscr != null) {
      this.$sendMessageSubscr.unsubscribe();
    }
    this.$sendMessageSubscr = this.outputManagementService.sendMessage(this.messageRequest.message).subscribe(
      result => {
        this.messageRequest.message.send = false;
        this.messageSentEvent.emit();
        this.closeConfirmAndMessageDialog();
      },
      error => {
        const address = new ProviderHelper(this.modalService);
        let titleText, textBody;
        if (error.status == '413') {
          titleText = 'message-not-sent-payload-too-large-title';
          textBody = 'message-not-sent-payload-too-large-text';
        } else {
          titleText = 'message-not-sent-title';
          textBody = 'message-not-sent-text';
        }
        address.messageError(error,  'message_not_sent', titleText, textBody);
        this.messageRequest.message.send = false;
      }
    );
  }


  loadStakeholders(init=false) {
    this.caseService.getCaseStakeholdersOnCase(this.case.id).subscribe(result => {
      this.stakeholders = result;
      if (this.case.caseTypeCode === CaseTypeCodes.INTERNAL_COORDINATION) {
        // No endUser etc. exists on a coordination case
        this.stakeholders = this.stakeholders.filter(value => value.stakeholderType !== StakeholderTypeEnum.endUser && value.stakeholderType !== StakeholderTypeEnum.policyHolder && value.stakeholderType !== StakeholderTypeEnum.reporter);
      }
      if (this.messageRequest.message.legalGuardianOnly) {
        this.stakeholders = [];
        this.stakeholders.push(result.find(a => a.properties.indexOf("LEGAL_GUARDIAN") > -1));
        if (this.messageRequest.message.receiver?.id !== this.stakeholders[0]?.id) {
          this.messageRequest.message.receiver = this.stakeholders[0];
          this.formComponent.updateStakeholderInformation(this.messageRequest.message.receiver);
        }
      }
      else if (init && this.messageRequest.message.stakeholderReceiverId) {
        let caseStakeholder = this.stakeholders.find(
          a => a.id.toString() === this.messageRequest.message.stakeholderReceiverId.toString()
        );
        this.takeStakeholderFromGopContact = this.messageRequest.message.gopContactPersonShouldBeChosen;
        if (this.messageRequest.message.receiver?.id !== caseStakeholder?.id) {
          this.messageRequest.message.receiver = caseStakeholder;
          this.formComponent.updateStakeholderInformation(this.messageRequest.message.receiver);
        }
      }
    });
  }

  private formatSubject() {
    if (this.messageRequest.message.caseNumber) {
      return this.messageRequest.message.caseNumber;
    }
    return this.case.caseNumber == null ? '' : '[' + this.case.caseNumber + ']';
  }

  private getDocuments() {
    this.documentService.getAttachmentDtoList(this.case.id).subscribe(
      result => {
        this.attachments = result;
        this.updateAttachmentsListWithAttachmentsFromTemplate(undefined);
      },
      error => console.log(error)
    );
  }

  private getMessageTemplates() {
    if (this.messageService) {
      if (this.$messageSubscr != null) {
        this.$messageSubscr.unsubscribe();
      }
      this.$messageSubscr = this.messageService.getMessageTemplates(this.messageRequest).subscribe(messageTemplates => {
        this.messageTemplates = messageTemplates;
        this.filteredMessageTemplates = messageTemplates;
        // Filter messages if window has been minimized and it is opened again
        this.filterMessageTemplates();
      });
    }
  }

  private filterMessageTemplates() {
    if (this.messageRequest.message.receiver === undefined) {
      return;
    }
    this.filteredMessageTemplates = this.messageTemplates
      .filter(value => this.filterByReceiverType(value))
      .filter(value => this.filterByCategoryType(value))
      .filter(message => this.filterByTemplateType(message))
      .sort((a, b) => a.displayName.localeCompare(b.displayName));
  }

  private filterByTemplateType(messageTemplate: MessageTemplate): boolean {
    if (!this.messageRequest.message.templateType) {
      return true;
    }
    return this.messageRequest.message.templateType === messageTemplate.templateType;
  }

  private filterByReceiverType(messageTemplate: MessageTemplate): boolean {
    return this.messageRequest.message.receiver.company
      ? messageTemplate.receiverType !== 'PERSON'
      : messageTemplate.receiverType === 'PERSON';
  }

  private filterByCategoryType(messageTemplate: MessageTemplate): boolean {
    if (this.messageRequest.message.messageChannelType === MessageChannelType.SMS) {
      return messageTemplate.documentType === MessageChannelType.SMS;
    } else {
      return messageTemplate.documentType !== MessageChannelType.SMS;
    }
  }

  messageChannelTypeHasChanged(messageChannelType: MessageChannelType) {
    this.initializePlaceholderTextForSMS(messageChannelType);
  }

  setTemplateRichTextAndSubjectFields(templateId: any) {
    if (templateId) {
      this.setTemplateForRichTextField(templateId);
      this.setSubjectField(templateId);
    } else {
      this.messageRequest.message.text = '';
      this.messageRequest.message.subject = '';
      this.messageRequest.message.attachments = [];
    }
  }

  setSubjectField(templateId: any) {
    const messageTemplate = this.filteredMessageTemplates.find(template => template.id === templateId);
    if (messageTemplate !== undefined && !this.messageRequest.message.subjectLock) {
      this.messageRequest.message.subject = messageTemplate.subject;
    }
  }

  messageLanguageHasChanged(languageCode: any) {
    const messageTemplate = this.filteredMessageTemplates.find(
      a => a.displayName === this.messageRequest.message.templateDisplayName && a.languageCode === languageCode
    );
    if (messageTemplate != null) {
      this.setTemplateRichTextAndSubjectFields(messageTemplate.id);
    }
  }

  setTemplateForRichTextField(templateId: any) {
    if (!this.messageRequest.message.templateId) {
      return;
    }
    let messageTemplate = this.filteredMessageTemplates.find(
      template => template.id === templateId && template.languageCode === this.messageRequest.message.language
    );
    if (messageTemplate === undefined) {
      messageTemplate = this.filteredMessageTemplates.find(template => template.id === templateId && template.languageCode === 'EN');
    }


    this.updateAttachmentsListWithAttachmentsFromTemplate(messageTemplate);
    if (messageTemplate !== undefined) {
      this.attachmentsFromTemplate = messageTemplate.attachments;
      if (this.messageRequest.message.messageChannelType === MessageChannelType.SMS) {
        this.messageService.getSmsBody(this.messageRequest, messageTemplate.id).subscribe(
          result => (this.messageRequest.message.text = result.htmlBody),
          error => console.log(error)
        );
        this.smsPlaceholder = '';
      } else {
        this.messageService.getEmailBody(this.messageRequest, templateId).subscribe(
          result => {
            this.addReplyMessage(result.htmlBody);
          },
          error => console.log(error)
        );
      }
    }
  }

  updateAttachmentsListWithAttachmentsFromTemplate(messageTemplate: MessageTemplate) {
    if (this.messageRequest.message.attachments == null) {
      this.messageRequest.message.attachments = [];
    }

    this.messageRequest.message.attachments = this.messageRequest.message.attachments.filter(
      attachment => attachment.attachmentType === AttachmentTypeEnum.FROM_USER
    );

    if (messageTemplate) {
      this.messageRequest.message.attachments = this.messageRequest.message.attachments.concat(messageTemplate.attachments);
    }

    if (this.preSelectDocumentIds) {
      this.preSelectDocumentIds.
        filter(preSelectedDocument => !this.messageRequest.message.attachments.find(att => att.documentId === preSelectedDocument.documentId)).
        forEach(preSelectedDocument => {
          const attachmentDto = this.attachments.find(att => att.documentId === preSelectedDocument.documentId);
          if (attachmentDto) {
            this.messageRequest.message.attachments = this.messageRequest.message.attachments.concat(attachmentDto);
          }
      })
    }
  }

  addReplyMessage(templateContent: string) {
    if (this.messageRequest.message.replyEmailId != null) {
      this.caseCommunicationService.getEmailBody(this.messageRequest.message.replyEmailId).subscribe(
        emailBody => {
          this.caseCommunicationService.getCaseEmail(this.messageRequest.message.replyEmailId).subscribe( email =>
          {
            this.messageRequest.message.text = templateContent + '<br>';
            this.messageRequest.message.text =  this.messageRequest.message.text + '<hr>' + '<br>';
            if (email.from) {
              this.messageRequest.message.text =  this.messageRequest.message.text + '<b>From: </b> ' + email.from  + '<br>';
            }
            if (email.receivedDate) {
              this.messageRequest.message.text = this.messageRequest.message.text + '<b>Sent: </b> ' + moment(email.receivedDate).format('YYYY-MM-DD HH:mm') + '<br>';
            }
            if (email.to) {
              this.messageRequest.message.text = this.messageRequest.message.text + '<b>To: </b> ' + email.to  + '<br>';
            }
            if (email.subject){
              this.messageRequest.message.text = this.messageRequest.message.text + '<b>Subject: </b> ' + email.subject  + '<br>';
            }
            this.messageRequest.message.text =  this.messageRequest.message.text + emailBody;
          }, error => console.log(error));
        },
        error => console.log(error)
      );
    } else if (this.messageRequest.message.replyWebRequestData) {
      this.messageRequest.message.text = templateContent + '<br>';
      this.messageRequest.message.text = this.messageRequest.message.text + '<pre>' +
        JSON.stringify(this.messageRequest.message.replyWebRequestData, null, 2) + '</pre>';
    } else {
      this.messageRequest.message.text = templateContent;
    }
  }

  sendClicked(): void {
    const missingFields = this.getMissingFields();
    if (missingFields.length > 0) {
      this.confirmCloseDialog = this.modalService.open(ModalDialogComponent);
      this.confirmCloseDialog.componentInstance.items = missingFields;
      this.confirmCloseDialog.componentInstance.configuration = new ModalDialogConfiguration({
        header: 'default-modal-header',
        title: 'output-management-warning-send-message-title',
        text: 'output-management-warning-send-message-text',
        footer: 'output-management-warning-send-message-footer',
        yes: 'default-modal-dialog-yes',
        no: 'default-modal-dialog-no',
        isBody: true,
        isFooter: true,
      });
      this.confirmCloseDialog.componentInstance.closeModalEvent.subscribe(
        emittedEvent => {
          if (emittedEvent) {
            this.sendMessageToMessageDialog();
          }
          this.closeConfirmDialog();
        },
        error => console.log(error)
      );
    } else {
      this.sendMessageToMessageDialog();
    }
  }

  private getMissingFields(): string[] {
    const missingFields = [];
    if (this.messageRequest.message.messageChannelType !== MessageChannelType.SMS && (this.messageRequest.message.subject == null || this.messageRequest.message.subject === '')) {
      missingFields.push('Subject');
    }
    if (this.messageRequest.message.text == null || this.messageRequest.message.text === '') {
      missingFields.push('Text');
    }
    if (this.messageRequest.message.messageChannelType !== MessageChannelType.SMS && (this.messageRequest.message.internalSummary == null || this.messageRequest.message.internalSummary === '')) {
      missingFields.push('Internal summary');
    }
    return missingFields;
  }

  private sendMessageToMessageDialog() {
    this.messageRequest.message.send = true;
    this.updateMessage();
    this.updateServiceOrderRequestConfirmIfAny();
  }

  private updateServiceOrderRequestConfirmIfAny() {
    if (this.messageRequest.message && this.messageRequest.message.requestConfirmNeededForServiceOrder && this.messageRequest.message.requestConfirmNeededForServiceOrder.id) {
      this.serviceOrderService.getServiceOrder(this.messageRequest.message.requestConfirmNeededForServiceOrder.id).subscribe(result => {
        result.requestSent = true;
        this.serviceOrderService.editServiceOrder(result).subscribe(() => {});
      });
    }
  }

  closeMessageDialog() {
    this.confirmCloseDialog = this.modalService.open(ModalDialogComponent);
    this.confirmCloseDialog.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'default-modal-header',
      title: '',
      text: '',
      footer: 'output-management-warning-footer',
      yes: 'default-modal-dialog-yes',
      no: 'default-modal-dialog-no',
      isBody: false,
      isFooter: true,
    });
    this.$closeSubscr = this.confirmCloseDialog.componentInstance.closeModalEvent.subscribe(
      emittedEvent => {
        if (emittedEvent) {
          this.closeConfirmAndMessageDialog();
        }
        this.closeConfirmDialog();
      },
      error => console.log(error)
    );
  }

  private closeConfirmAndMessageDialog() {
    this.workInProgressService.clearWorkInProgress(this.case.id);
    this.close();
  }

  private closeConfirmDialog() {
    this.confirmCloseDialog.close();
  }

  private close() {
    if ( this.closed.observers ) {
      this.closed.emit();
    }
    this.modalService.dismissAll();
  }

  private updateMessage() {
    this.messageUpdatedEvent(this.messageRequest.message);
    this.outputManagementService.emitMessage(this.messageRequest.message);
  }
  get isSendSmsValid(): boolean {
    return (
      this.messageRequest.message.receiver != null &&
      this.messageRequest.message.messageChannelType != null &&
      this.messageRequest.message.sms.phoneNumber &&
      this.messageRequest.message.sms.phonePrefix != null &&
      !this.inputValidationService.isValidated(
        this.messageRequest.message.sms.phoneNumber,
        this.outputManagementService.mapToValidationTypeEnum(this.messageRequest.message.messageChannelType)
      ) &&
      this.messageRequest.message.language != null &&
      !this.messageRequest.message.send
    );
  }

  get isSendMessageValid(): boolean {
    if (this.messageRequest.message.messageChannelType === MessageChannelType.EMAIL) {
      return this.isSendEmailValid();
    } else if (this.messageRequest.message.messageChannelType === MessageChannelType.FAX) {
      return this.isSendFaxValid();
    }
    return false;
  }

  isSendEmailValid(): boolean {
    return (
      this.messageRequest.message.messageFrom != null &&
      this.messageRequest.message.receiver != null &&
      this.messageRequest.message.messageChannelType != null &&
      this.messageRequest.message.email != null &&
      !this.inputValidationService.isValidated(
        this.messageRequest.message.email,
        this.outputManagementService.mapToValidationTypeEnum(this.messageRequest.message.messageChannelType)
      ) &&
      this.messageRequest.message.language != null &&
      this.validateText()
    );
  }

  isSendFaxValid(): boolean {
    return (
      this.messageRequest.message.receiver != null &&
      this.messageRequest.message.messageChannelType != null &&
      this.messageRequest.message.fax.faxNumber != null &&
      this.messageRequest.message.fax.faxPrefix != null &&
      !this.inputValidationService.isValidated(
        this.messageRequest.message.fax.faxNumber,
        this.outputManagementService.mapToValidationTypeEnum(this.messageRequest.message.messageChannelType)
      ) &&
      this.messageRequest.message.language != null &&
      this.validateText()
    );
  }

  private validateText(): boolean {
    if (this.messageRequest.message.messageChannelType === MessageChannelType.SMS) {
      return !!this.messageRequest.message.text;
    }
    return true;
  }

}
