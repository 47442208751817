<div class="modal-dialog-wrapper" appDialogSelectable appDialogKeyboardEnabled
     [appDialogMinimizable]='MinimizableDialogType.SearchProvider'>

  <mat-dialog-content (cdkDragStarted)='dialogBoundryService.handleDragStart($event)'
                      (cdkDragEnded)='dialogBoundryService.handleDragEnd($event)'
                      cdkDragRootElement='.cdk-overlay-pane' cdkDrag>
    <div appDialogDragArea cdkDragHandle></div>

    <app-search-service-provider-modal (closeSupplierSearch)='close()'
                                       [supplierDialogData]='data'>

    </app-search-service-provider-modal>

  </mat-dialog-content>
</div>
