import { SubComponentModel } from '@secca/case/components/case-plans/case-plans/add-service-order/models/interfaces';

export class SubStakeholder implements SubComponentModel {
  id: number;
  stakeholderId: string;
  cabinClass?: string;
  includeDateOfBirth?: string;
  includePassportDetails?: string;
  stakeholderType: string;

  public constructor(init?: Partial<SubStakeholder>) {
    Object.assign(this, init);
  }

  isValid(): boolean {
    return this.stakeholderId != null && this.cabinClass != null && this.includeDateOfBirth != null && this.includePassportDetails != null;
  }
}

export class SubStakeholderAdapter {
  adapt(item: any): SubStakeholder {
    return new SubStakeholder({
      id: item.id,
      stakeholderId: item.stakeholderId,
      cabinClass: item.cabinClass,
      includeDateOfBirth: item.includeDateOfBirth,
      includePassportDetails: item.includePassportDetails ? item.includePassportDetails : 'MISSING',
    });
  }
}
