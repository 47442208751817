import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';

export class TableCaseStakeholder {
  caseStakeholder: CaseStakeholder;
  tableRole: string;
  sortId: number;

  public constructor(init?: Partial<TableCaseStakeholder>) {
    Object.assign(this, init);
  }

  public sameRoleAndType(): boolean {
    return this.tableRole === this.caseStakeholder.stakeholderType;
  }

  public similarTo(type: string): boolean {
    return this.caseStakeholder.similarStakeholders != null
      && this.caseStakeholder.similarStakeholders.indexOf(type) > -1;
  }
}
