import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';

@Component({
  selector: 'app-drop-down-team',
  templateUrl: './drop-down-team.component.html',
  styleUrls: ['./drop-down-team.component.scss']
})
export class DropDownTeamComponent {


  @Input() name: string;
  @Input() items: DropdownDictionary[];
  @Input() selectedItemId: string[];
  @Output() selectedItemIdChange = new EventEmitter();
  @Output() saveModel = new EventEmitter();
  @Output() open = new EventEmitter();
  empty: boolean = false;

  constructor() { }

  onChange() {
    this.selectedItemIdChange.emit(this.selectedItemId);
  }

  onOpen() {
    this.open.emit();
  }

  onSaveModel() {
    this.empty = false;
    this.saveModel.emit();
  }

  onSaveState(){
    this.empty = true;
  }

  close() {
    this.empty = false;
  }

  displayMultiLabel(): string {
    if(!this.empty){
      if (!!this.items && !!this.selectedItemId) {
        if(this.selectedItemId.length === 1 && this.items.length !== 0) {
          const test = this.items.filter(e => e.id === this.selectedItemId[0]);
          return test.length !== 0 ? test[0].name : '-';
        }
        return this.selectedItemId.length + ' selected';
      }
      return '-';
    }
    return '';
  }
}
