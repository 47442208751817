import { Injectable } from '@angular/core';

export enum ScreeningStates {
  NO = 'NO',
  TO_BE_DETERMINED = 'TO_BE_DETERMINED',
  YES = 'YES',
  FULLY_COVERED = 'FULLY_COVERED',
  PARTIALLY_COVERED = 'PARTIALLY_COVERED',
  TBD = 'TBD',
  REQUEST_INFORMATION = 'REQUEST_INFORMATION',
  CASE_HANDLING = 'CASE_HANDLING',
  DOCTOR = 'DOCTOR',
  NURSE = 'NURSE',
  SYSTEM_VALUE_TBD = 'SYSTEM_VALUE_TBD'
}

@Injectable({
  providedIn: 'root'
})
export class ScreeningStateDisplayValueAdapter {
  public getPropertyFromValue(value: ScreeningStates): string {
    switch (value) {
      case ScreeningStates.YES: {
        return 'screening-tool-YES';
      }
      case ScreeningStates.NO: {
        return 'screening-tool-NO';
      }
      case ScreeningStates.TO_BE_DETERMINED: {
        return 'screening-tool-TBD';
      }
      case ScreeningStates.FULLY_COVERED: {
        return 'screening-tool-FULLY_COVERED';
      }
      case ScreeningStates.PARTIALLY_COVERED: {
        return 'screening-tool-PARTIALLY_COVERED';
      }
      case ScreeningStates.TBD: {
        return 'screening-tool-TBD';
      }
      case ScreeningStates.REQUEST_INFORMATION: {
        return 'screening-tool-REQUEST_INFORMATION';
      }
      case ScreeningStates.CASE_HANDLING: {
        return 'screening-tool-CASE_HANDLING';
      }
      case ScreeningStates.DOCTOR: {
        return 'screening-tool-DOCTOR';
      }
      case ScreeningStates.NURSE: {
        return 'screening-tool-NURSE';
      }
      case ScreeningStates.SYSTEM_VALUE_TBD: {
        return 'screening-tool-SYSTEM_VALUE_TBD';
      }
      default: {
        return 'NO_VALUE';
      }
    }
  }
}
