import { SubComponentModel } from '@secca/case/components/case-plans/case-plans/add-service-order/models/interfaces';

export class SubRepatriationFormMedical implements SubComponentModel {
  id: number;
  repatriationInstructionId: number;
  medicalAssessmentId: number;
  toAirportOrganizerStakeholderId: number;
  fromAirportOrganizerStakeholderId: number;

  isToAirportOrganizerVisible: boolean = false;
  isPhoneNumberToAirportOrganizer: boolean = false;

  isFromAirportOrganizerVisible: boolean = false;
  isPhoneNumberFromAirportOrganizer: boolean = false;

  static jsonIgnoreFields = [
    'isToAirportOrganizerVisible',
    'isPhoneNumberToAirportOrganizer',
    'isFromAirportOrganizerVisible',
    'isPhoneNumberFromAirportOrganizer',
    'extendedHeight',
    'cellPosOffset',
    'cellHeight',
    'laneNumber',
    'hidden',
    'isDuplicated',
    'coffinUrn',
    'deletedOn'
  ];

  public constructor(init?: Partial<SubRepatriationFormMedical>) {
    Object.assign(this, init);
  }

  isValid(): boolean {

    let correctToAirportOrganizer = 
      !this.isToAirportOrganizerVisible ||
      (this.isToAirportOrganizerVisible && this.toAirportOrganizerStakeholderId && this.isPhoneNumberToAirportOrganizer);

    let correctFromAirportOrganizer = 
      !this.isFromAirportOrganizerVisible ||
      (this.isFromAirportOrganizerVisible && this.fromAirportOrganizerStakeholderId && this.isPhoneNumberFromAirportOrganizer);

    return correctToAirportOrganizer && correctFromAirportOrganizer;
  }

  isRepatriationInstructionPresent(): boolean {
    return this.repatriationInstructionId != null && this.medicalAssessmentId != null;
  }
}
