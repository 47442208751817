<div class="bank-details-panel">
  <div class="message-content p-3">
    <div class="row">
      <div class="col-4">
        <div>
          <div class="message-content-header">
            <div class="heading"><span><img src="/assets/icons/service-order-type/OtherRefund.svg"></span></div>
            <div class="text">{{'task-web-modal-bank-details' | translate}}</div>
          </div>
          <div class="message-content-box">
            <app-task-web-properties [properties]="taskDataViewModel?.data?.refund?.bankDetails"
              [ignoredProperties]="ignoredProperties" [serviceItemList]="serviceItemList"
              [purposeOfTravelItemList]="purposeOfTravelItemList" [causes]="causes" [diagnoses]="diagnoses"
              [customerProfiles]="customerProfiles"></app-task-web-properties>
          </div>
        </div>    
      </div>
      <div class="col-4">
        <div>
          <div class="message-content-header">
            <div class="heading">
              <span *ngIf="isEndUser"><img src="/assets/icons/End-User.svg"></span>
              <span *ngIf="isReporter"><img src="/assets/icons/Reporter.svg"></span>
              <span *ngIf="isPolicyHolder"><img src="/assets/icons/Policy Holder.svg"></span>
              <span *ngIf="!isEndUser && !isReporter && !isPolicyHolder"><img src="/assets/icons/Person.svg"></span>
              <div class="text">
                <span *ngIf="isEndUser">{{'task-web-modal-end-user' | translate}}</span>
                <span *ngIf="isPolicyHolder"><span *ngIf="isEndUser"> / </span>{{'task-web-modal-policy-holder' | translate}}</span>
                <span *ngIf="isReporter"><span *ngIf="isEndUser || isPolicyHolder"> / </span>{{'task-web-modal-reporter' | translate}}</span>
                <span *ngIf="isEndUser || isPolicyHolder || isReporter"> - </span>{{'task-web-modal-receiver-of-refund' | translate}}
              </div>
            </div>
        </div>
          <div class="message-content-box">
            <app-task-web-properties [properties]="taskDataViewModel?.data?.refund?.receiverOfRefund"
              [ignoredProperties]="ignoredProperties" [serviceItemList]="serviceItemList"
              [purposeOfTravelItemList]="purposeOfTravelItemList" [causes]="causes" [diagnoses]="diagnoses"
              [customerProfiles]="customerProfiles"></app-task-web-properties>
          </div>
        </div>    
      </div>
    </div>
  </div>
</div>