import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Response } from '@secca/shared/models/SupplierSearchResponse';
import { ProviderService } from '@secca/core/services/providerService';
import { SearchKeyword } from '@secca/case/components/case-plans/case-plans/add-service-order/search-service-provider/model/searchKeyword';
import {
  SupplierSearchHelper
} from '@secca/case/components/case-plans/case-plans/add-service-order/search-service-provider/search-service-provider-modal/supplier-search-helper.service';

@Component({
  selector: 'app-suggester',
  templateUrl: './suggester.component.html',
  styleUrls: ['./suggester.component.scss']
})
export class SuggesterComponent implements OnChanges {

  private debounceTimeout: any;

  constructor(
    private supplierService: ProviderService,
    private supplierSearchHelper: SupplierSearchHelper
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items.currentValue?.length < changes.items.previousValue?.length) {
      const keywordDeleted: SearchKeyword[] = this.supplierSearchHelper.differencesBetweenTwoArrays(changes.items.previousValue, changes.items.currentValue);
      if (keywordDeleted) {
        keywordDeleted.forEach((keyword: SearchKeyword) => {
          const index = this.chips.findIndex(chip => chip.category === keyword.category && chip.text === keyword.text && chip.userEntered === keyword.userEntered);
          if (index >= 0) {
            this.chips.splice(index, 1);
          }
        })
      }
    }
  }

  chips: SearchKeyword[] = [];
  inputValue: string = '';
  showList: boolean = false;
  listItems: SearchKeyword[] = [];
  filteredList: SearchKeyword[] = [...this.listItems];
  focusedItemIndex: number = -1;
  @ViewChild('chipInput') chipInput: ElementRef<HTMLInputElement> | undefined;

  @Input()
  set items(items: SearchKeyword[]) {
    this.listItems = items;
    this.filteredList = [...this.listItems];
  }

  @Output()
  chipListChanged = new EventEmitter<SearchKeyword[]>();

  addChip(): void {
    const value = this.inputValue.trim();
    if (value) {
      let keyword: SearchKeyword = this.filteredList.find(item => {
        item.text === value && item.category;
      });
      if (!keyword) {
        keyword = { text: value, userEntered: true, category: 'User Entered' } as SearchKeyword;
      }
      this.chips.push(keyword);
      this.chipListChanged.emit(this.chips);
      this.inputValue = '';
    }
    this.showList = false;
  }

  selectItem(item: SearchKeyword): void {
    if (!this.chips.find(chip => {
      chip.text === item.text && chip.category === item.category;
    })) {
      this.chips.push({ text: item.text, userEntered: false, category: item.category } as SearchKeyword);
      this.chipListChanged.emit(this.chips);
    }
    this.inputValue = '';
    this.showList = false;
  }

  removeChip(chip: SearchKeyword): void {
    const index = this.chips.indexOf(chip);
    if (index >= 0) {
      this.chips.splice(index, 1);
      this.chipListChanged.emit(this.chips);
    }
  }

  filterList(): void {
    this.filteredList = [];
    clearTimeout(this.debounceTimeout);
    this.debounceTimeout = setTimeout(() => {
      const keyword = this.inputValue.trim();
      if (keyword) {
        this.supplierService.suggesterKeywordSelecterOnSupplierServices(keyword).subscribe((data: Response) => {
          data?.suggestionList?.filter(itemval => itemval.category !== 'serviceTypes').forEach(item => {
            this.filteredList.push({ text: item.term, userEntered: undefined, category: item.category } as SearchKeyword);
          });
          this.showList = true;
        });
      } else {
        this.showList = false;
      }
    }, 500);
  }

  hideList(): void {
    setTimeout(() => this.showList = false, 200);
  }

  focusInput(): void {
    this.chipInput?.nativeElement.focus();
  }

  clearChips(): void {
    this.chips = [];
    this.chipListChanged.emit([]);
  }

  handleKeydown(event: KeyboardEvent) {
    if (event.key === 'ArrowDown') {
      this.focusedItemIndex = Math.min(this.focusedItemIndex + 1, this.filteredList.length - 1);
      event.preventDefault(); // Prevent scrolling the page
    } else if (event.key === 'ArrowUp') {
      this.focusedItemIndex = Math.max(this.focusedItemIndex - 1, 0);
      event.preventDefault(); // Prevent scrolling the page
    } else if (event.key === 'Enter' && this.focusedItemIndex >= 0) {
      this.selectItem(this.filteredList[this.focusedItemIndex]);
      this.focusedItemIndex = -1; // Reset or handle as needed
      event.preventDefault(); // Prevent form submission or other default actions
    } else if (event.key === 'Backspace') {
      if (!this.inputValue) {
        this.removeChip(this.chips[this.chips.length - 1]);
      }
    }
  }

}


