import { AutoUnsubscribe } from 'src/app/shared/decorators/auto-unsubscribe';
import { CaseManagementSystemService } from './../../../core/services/case-management-system.service';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
@AutoUnsubscribe
export class FooterComponent implements OnInit {
  connectedServiceInfo: [any];
  appVersion: string;
  shown: boolean;

  constructor(private caseManagementSystemService: CaseManagementSystemService) {}

  ngOnInit() {
    this.appVersion = environment.appVersion;
    this.caseManagementSystemService.getConnectedServicesInfo().subscribe(
      result => {
        this.connectedServiceInfo = result;
      },
      error => console.log(error)
    );
  }

  get footerStyle(): any {
    if (!this.shown) {
      return { left: '49%', right: 'inherit', height: '20px', top: 'calc(100vh - 20px)' };
    } else {
      return { left: '49%', right: 'inherit', height: '20px', top: 'calc(100vh - 63px)' };
    }
    return null;
  }

  toggle(): void {
    this.shown = !this.shown;
  }
}
