import { QuantityUnit } from "@secca/shared/models/enums";
import {
  BalanceSheetEntryItem,
  BalanceSheetEntryReduction
} from "@secca/case/components/case-plans/case-plans/add-service-order/models/interfaces";
import { EconomyHistory } from "@secca/shared/models/refund-history";
import moment from "moment";
import { Injectable } from '@angular/core';
import { Adapter } from '@secca/shared/interfaces/adapter';
import { DateSerializer } from '@secca/shared/models/date-serializer';

export class RecoveryPaymentItem implements BalanceSheetEntryItem, EconomyHistory {
  id: number;
  recoveryId: string;
  quantity: number;
  quantityUnit: QuantityUnit;
  amount: number
  revision: number
  serviceItemCode: string;
  modifiedBy: string;
  modifiedOn: moment.Moment;
  deletedBy: string;
  deletedOn: moment.Moment;

  public constructor(init?: Partial<RecoveryPaymentItem>) {
    Object.assign(this, init);
  }

  getExchangeRate(): number {
    return 0;
  }

  getId(): number {
    return this.id;
  }

  getIsInVATCreditorGroup(): boolean {
    return false;
  }

  getIsIncludingVAT(): boolean {
    return false;
  }

  getOriginalAmount(): number {
    return 0;
  }

  getQuantity(): number {
    return this.quantity;
  }

  getQuantityUnit(): string {
    return this.quantityUnit;
  }

  getSavings(): BalanceSheetEntryReduction[] {
    return [];
  }

  getServiceItemCode(): string {
    return this.serviceItemCode;
  }

  getServiceOrderId(): string {
    return null;
  }

  identicalHistory(histObject: EconomyHistory): boolean {
    return false;
  }
}

@Injectable({
  providedIn: 'root'
})
export class RecoveryPaymentItemAdapter implements Adapter<RecoveryPaymentItem> {
  adapt(item: any): RecoveryPaymentItem {
    return new RecoveryPaymentItem({
      id: item.id,
      quantity: item.quantity,
      quantityUnit: item.quantityUnit,
      amount: item.amount,
      recoveryId: item.recoveryId,
      serviceItemCode: item.serviceItemCode,
      revision: item.revision,
      modifiedBy: item.modifiedBy,
      modifiedOn: item.modifiedOn ? DateSerializer.deserialize(item.modifiedOn) : null,
      deletedBy: item.deletedBy,
      deletedOn: item.deletedOn ? DateSerializer.deserialize(item.deletedOn) : null,
    });
  }
}
