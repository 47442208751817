import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';
import { ServiceOrder, ServiceOrderAdapter } from '@secca/shared/models/service-order/service-order';
import { Refund, RefundAdapter } from '@secca/shared/models/refund';
import { BalanceSheetProgress, BalanceSheetAdapter } from '@secca/shared/models/balance-sheet-event.model';
import { CaseLockHttpService } from '@secca/core/services/case-lock-http.service';
import { RefundItem, RefundItemAdapter } from '@secca/shared/models/refund-Item';
import { SettingsService } from '@secca/core/services/settings.service';

@Injectable({
  providedIn: 'root',
})
export class RefundService extends BaseService {
  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private refundAdapter: RefundAdapter,
    private refundItemAdapter: RefundItemAdapter,
    private serviceOrderAdapter: ServiceOrderAdapter,
    private caseLockHttpService: CaseLockHttpService,
    private balanceSheetAdapter: BalanceSheetAdapter,
  ) {
    super(settingsService);
  }

  private caseRefunds: BehaviorSubject<Refund[]> = new BehaviorSubject(null);

  public getServiceOrdersForRefunds(caseId: number): Observable<ServiceOrder[]> {
    return this.http.get<ServiceOrder[]>(this.baseURL + 'service-orders/by-case-for-refunds/' + caseId)
    .pipe(map((data: any[]) => data.map(item => this.serviceOrderAdapter.adapt(item))));
  }

  public createRefund(refund: Refund): Observable<Refund> {
    return this.http
      .post<Refund>(this.baseURL + 'refunds', refund, {
        headers: this.jsonHeaders,
      })
      .pipe(map(returnRefund => this.refundAdapter.adapt(returnRefund)));
  }

  public updateRefund(refund: Refund): Observable<Refund> {
    return this.caseLockHttpService
      .put<Refund>(this.baseURL + 'refunds/' + refund.id, refund, {
        headers: this.jsonHeaders,
      })
      .pipe(map(returnRefund => this.refundAdapter.adapt(returnRefund)));
  }

  public getRefundById(refundId: number): Observable<Refund> {
    return this.http.get<Refund>(this.baseURL + 'refunds/' + refundId).pipe(map(item => this.refundAdapter.adapt(item)));
  }

  public cancelARefund(refund: Refund): Observable<any> {
    return this.http.put<Refund>(this.baseURL + 'refunds/cancel', refund, {
        headers: this.jsonHeaders,
      });
  }

  public getRefundByCaseId(refundId: number): Observable<Refund[]> {
    return this.http
      .get<Refund[]>(this.baseURL + 'refunds/case/' + refundId, { headers: this.jsonHeaders })
      .pipe(map((data: any[]) => data.map(item => this.refundAdapter.adapt(item))));
  }

  public adjustExchangedBalances(refund: Refund): Observable<Refund> {
    return this.http
      .put<Refund>(this.baseURL + 'refunds/adjust-exchanged-balances', refund, {
        headers: this.jsonHeaders,
      })
      .pipe(map(item => this.refundAdapter.adapt(item)));
  }

  public preview(refundId: number): Observable<any> {
    return this.http
      .post(this.baseURL + 'documents/refund/' + refundId, '', {
        responseType: 'arraybuffer',
      })
      .pipe(
        map((res: any) => {
          return new Blob([res], {
            type: 'application/pdf',
          });
        })
      );
  }

  public store(refundId: number): Observable<any> {
    return this.http.post(this.baseURL + 'documents/refund/store/' + refundId, '', { headers: this.jsonHeaders, responseType: 'text' });
  }

  public delete(refundId: number): Observable<any> {
    return this.http.delete<any>(this.baseURL + 'refunds/' + refundId);
  }

  public deleteRefundAndSetTaskToDone(refundId: number): Observable<any> {
    return this.http.delete<any>(this.baseURL + 'refunds/deleteRefundAndSetTaskToDone/' + refundId);
  }

  public deleteErrorRefund(refundId: number, comment: string): Observable<any> {
    return this.http.put<any>(this.baseURL + 'refunds/error/' + refundId, comment);
  }

  public sendCaseRefunds(refunds: Refund[]) {
    this.caseRefunds.next(refunds);
  }

  public getCaseRefunds(): Observable<Refund[]> {
    return this.caseRefunds.asObservable();
  }

  public getRefundHistory(id: number): Observable<BalanceSheetProgress> {
    return this.http
      .get(this.baseURL + 'refunds/history/' + id, { headers: this.jsonHeaders })
      .pipe(map((data: any) => this.balanceSheetAdapter.adapt(data)));
  }

  public getFullRefundHistory(id: string): Observable<Refund[]> {
    return this.http
      .get(this.baseURL + 'refunds/full-history/' + id, { headers: this.jsonHeaders })
      .pipe(map((data: any[]) => data.map(item => this.refundAdapter.adapt(item))));
  }

  public getFullRefundItemHistory(id: string): Observable<RefundItem[]> {
    return this.http
      .get(this.baseURL + 'refunds/full-item-history/' + id, { headers: this.jsonHeaders })
      .pipe(map((data: any[]) => data.map(item => this.refundItemAdapter.adapt(item))));
  }

}
