import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';

export class CaseHistoryPropertyChangedData {
  value: any;

  public constructor(init?: Partial<CaseHistoryPropertyChangedData>) {
    Object.assign(this, init);
  }
}


@Injectable({
  providedIn: 'root'
})

export class CaseHistoryPropertyChangedDataAdapter implements Adapter<CaseHistoryPropertyChangedData> {
  adapt(item: any): CaseHistoryPropertyChangedData {
    return new CaseHistoryPropertyChangedData({
      value: JSON.parse(item.value)
    });
  }
}
