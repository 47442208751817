<div class="modal-content">
  <div class="modal-header">
    <div class="modal-header-title">
      {{ 'Keyboard Shortcuts' | translate }}
    </div>
    <div class="modal-buttons">
      <div (click)="close()">
        <img src="/assets/icons/Close White.svg" />
      </div>
    </div>
  </div>
  <div class="modal-content-body">
    <div class="row">
      <div class="col-6 text-center">
        <h5>Global board shortcuts</h5>
      </div>
      <div class="col-6 text-center">
        <h5>Case shortcuts</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <h5>General</h5>
        <div class="row">
          <div class="col-6"><div class="mt-2">Global board</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Ctrl</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">G</div></div>
        </div>
        <div class="row">
          <div class="col-6"><div class="mt-2">Search case</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Ctrl</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">S</div></div>
        </div>
        <div class="row">
          <div class="col-6"><div class="mt-2">Search case from web task</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Ctrl</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">Shift</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">S</div></div>
        </div>
        <div class="row">
          <div class="col-6"><div class="mt-2">Keyboard shortcuts overview</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Ctrl</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">K</div></div>
        </div>
        <div class="row">
          <div class="col-6"><div class="mt-2">My recently viewed cases</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Ctrl</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">Q</div></div>
        </div>
        <div class="row">
          <div class="col-6"><div class="mt-2">First case/task/sales order (jump to)</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Home</div></div>
        </div>
        <div class="row">
          <div class="col-6"><div class="mt-2">Move up/down</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Up/down arrow</div></div>
        </div>

        <h5 class="pt-3">Cases</h5>
        <div class="row">
          <div class="col-6"><div class="mt-2">Cases - Go to</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Ctrl</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">1</div></div>
        </div>
        <div class="row">
          <div class="col-6"><div class="mt-2">Open case</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Alt</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">Enter</div></div>
        </div>
      </div>
      <div class="col-3" style="border-right: 2px solid #CCCCCC">
        <h5>Task</h5>
        <div class="row">
          <div class="col-6"><div class="mt-2">Task - Go to</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Ctrl</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">2</div></div>
        </div>
        <div class="row">
          <div class="col-6"><div class="mt-2">To do</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Ctrl</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">O</div></div>
        </div>
        <div class="row">
          <div class="col-6"><div class="mt-2">Web</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Ctrl</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">B</div></div>
        </div>
        <div class="row">
          <div class="col-6"><div class="mt-2">Refund</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Ctrl</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">E</div></div>
        </div>
        <div class="row">
          <div class="col-6"><div class="mt-2">Supplier Invoice</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Ctrl</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">U</div></div>
        </div>
        <div class="row">
          <div class="col-6"><div class="mt-2">Open task on global board</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Ctrl</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">Enter</div></div>
        </div>
        <div class="row">
          <div class="col-6"><div class="mt-2">Open case</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Alt</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">Enter</div></div>
        </div>
        <div class="row">
          <div class="col-6"><div class="mt-2">Select task to reassign</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Ctrl</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">Space</div></div>
        </div>
        <div class="row">
          <div class="col-6"><div class="mt-2">Reassign</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Ctrl</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">R</div></div>
        </div>

        <h5 class="pt-3">Sales order</h5>
        <div class="row">
          <div class="col-6"><div class="mt-2">Sales order - Go to</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Ctrl</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">3</div></div>
        </div>
        <div class="row">
          <div class="col-6"><div class="mt-2">Open case</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Alt</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">Enter</div></div>
        </div>
      </div>
      <div class="col-3" style="padding-left: 40px !important;">
        <h5>Menu</h5>
        <div class="row">
          <div class="col-6"><div class="mt-2">Tasks</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Alt</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">T</div></div>
        </div>
        <div class="row">
          <div class="col-6"><div class="mt-2">Case Basics</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Alt</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">B</div></div>
        </div>
        <div class="row">
          <div class="col-6"><div class="mt-2">Case Summary</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Alt</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">C</div></div>
        </div>
        <div class="row">
          <div class="col-6"><div class="mt-2">Plans</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Alt</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">P</div></div>
        </div>
        <div class="row">
          <div class="col-6"><div class="mt-2">Medical</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Alt</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">M</div></div>
        </div>
        <div class="row">
          <div class="col-6"><div class="mt-2">Economy</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Alt</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">E</div></div>
        </div>
        <div class="row">
          <div class="col-6"><div class="mt-2">Stakeholders</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Alt</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">S</div></div>
        </div>
        <div class="row">
          <div class="col-6"><div class="mt-2">Documents</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Alt</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">D</div></div>
        </div>

        <h5 class="pt-3">New</h5>
<!--        <div class="row">-->
<!--          <div class="col-6"><div class="mt-2">Add new task, plan, stakeholder (jump to relevant menu first)</div></div>-->
<!--          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Alt</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">A</div></div>-->
<!--        </div>-->
<!--        <div class="row">-->
<!--          <div class="col-6"><div class="mt-2">Add new refund</div></div>-->
<!--          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Alt</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">F</div></div>-->
<!--        </div>-->
        <div class="row">
          <div class="col-6"><div class="mt-2">Open new email</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Alt</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">J</div></div>
        </div>
        <div class="row">
          <div class="col-6"><div class="mt-2">Open new sms</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Alt</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">K</div></div>
        </div>
        <div class="row">
          <div class="col-6"><div class="mt-2">Save / Save & close</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Alt</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">V</div></div>
        </div>
        <div class="row">
          <div class="col-6"><div class="mt-2">Copy service order ID</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Alt</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">I</div></div>
        </div>
        <div class="row">
          <div class="col-6"><div class="mt-2">Refund & supplier invoices tab (jump to)</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Alt</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">U</div></div>
        </div>

        <h5 class="pt-3">Economy</h5>
        <div class="row">
          <div class="col-6"><div class="mt-2">Expand / collapse</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Alt</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">Q</div></div>
        </div>
      </div>
      <div class="col-3">
        <h5>Basic functionalities</h5>
        <div class="row">
          <div class="col-6"><div class="mt-2">National ID lookup</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Alt</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">L</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">Enter</div></div>
        </div>
        <div class="row">
          <div class="col-6"><div class="mt-2">Policy/Profile lookup</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Alt</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">O</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">Enter</div></div>
        </div>
        <div class="row">
          <div class="col-6"><div class="mt-2">Consent request menu</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Alt</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">R</div></div>
        </div>
        <div class="row">
          <div class="col-6"><div class="mt-2">Phone note (jump to)</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Alt</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">N</div></div>
        </div>
        <div class="row">
          <div class="col-6"><div class="mt-2">Winter sports</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Alt</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">W</div></div>
        </div>
<!--        <div class="row">-->
<!--          <div class="col-6"><div class="mt-2">Open guide</div></div>-->
<!--          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Alt</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">G</div></div>-->
<!--        </div>-->
<!--        <div class="row">-->
<!--          <div class="col-6"><div class="mt-2">Open terms</div></div>-->
<!--          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Alt</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">H</div></div>-->
<!--        </div>-->

        <h5 class="pt-3">Manage window</h5>
        <div class="row">
          <div class="col-6"><div class="mt-2">Close top window</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Esc</div></div>
        </div>
        <div class="row">
          <div class="col-6"><div class="mt-2">Minimize top window</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Ctrl</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">M</div></div>
        </div>
        <div class="row">
          <div class="col-6"><div class="mt-2">Move top window</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Ctrl</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">Arrow&nbsp;key</div></div>
        </div>
        <div class="row">
          <div class="col-6"><div class="mt-2">Toggle between open windows</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Ctrl</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">I</div></div>
        </div>
        <div class="row">
          <div class="col-6"><div class="mt-2">Goto minimized windows tray</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Ctrl</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">J</div></div>
        </div>
        <div class="row">
          <div class="col-6"><div class="mt-2">Move right in minimized windows tray</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Tab</div></div>
        </div>
        <div class="row">
          <div class="col-6"><div class="mt-2">Move left in minimized windows tray</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Shift</div><div class="mt-2 px-2">+</div><div class="shortcut-item-key">Tab</div></div>
        </div>
        <div class="row">
          <div class="col-6"><div class="mt-2">Open minimized window from tray</div></div>
          <div class="col-6 d-flex flex-row"><div class="shortcut-item-key">Enter</div></div>
        </div>
      </div>
    </div>
  </div>
</div>
