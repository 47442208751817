
import {
  SubTravelInfo,
  SubTravelInfoAdapter,
} from '../../../modules/case/components/case-plans/case-plans/add-service-order/models/subTravelInfo';
import { SubTravellers } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subTravellers';
import { SubStakeholders } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholders';
import { SubStakeholder } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholder';
import { SubRemarksToSupplier } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subRemarksToSupplier';
import { SubRemarksFromSupplier } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subRemarksFromSupplier';
import { TransportTypeEnum } from '../enums';
import { SubTransportType } from '../../../modules/case/components/case-plans/case-plans/add-service-order/models/subTransportType';
import { ServiceOrderExtension } from './service-order-extension';
import { Location } from '@secca/shared/models/location';
import * as moment from 'moment-timezone';
import { CommonServiceOrderExtension } from './CommonServiceOrderExtension.component';
import { CommitmentType, CommitmentTypeAdapter } from '../commitment-type';

export class GroundAmbulanceServiceOrderExtension extends CommonServiceOrderExtension implements ServiceOrderExtension {
  id: number;
  transportType: SubTransportType;
  commitmentType: CommitmentType;
  displayDurationId: number;
  remarksFromSupplier: SubRemarksFromSupplier;
  remarksToSupplier: SubRemarksToSupplier;
  travellers: SubTravellers;
  stakeholders: SubStakeholder[];
  subStakeholders: SubStakeholders;
  travelInfo: SubTravelInfo;

  public constructor(init?: Partial<GroundAmbulanceServiceOrderExtension>) {
    super();
    Object.assign(this, init);
    if (!init) {
      this.init();
    }
  }

  isValidExpectation(): boolean {
    return (
      this.travellers.flightLegs[0].departureDate != null &&
      !!this.travellers.flightLegs[0].arrivalAt.freeText &&
      !!this.travellers.flightLegs[0].departureFrom.freeText &&
      this.travellers.flightLegs[0].arrivalDate != null
    );
  }

  isValid(): boolean {
    return (
      !!this.commitmentType &&
      !!this.travellers.flightLegs[0].departureFrom.freeText &&
      this.travellers.flightLegs[0].departureDate != null &&
      this.travellers.flightLegs[0].arrivalDate != null &&
      !!this.travellers.flightLegs[0].arrivalAt.freeText
    );
  }

  init() {
    this.transportType = new SubTransportType(TransportTypeEnum.GROUND_AMBULANCE);
    this.remarksFromSupplier = new SubRemarksFromSupplier();
    this.remarksToSupplier = new SubRemarksToSupplier();
    this.travellers = new SubTravellers();
    this.travelInfo = this.travellers.flightLegs[0];
    this.subStakeholders = new SubStakeholders();
    this.subStakeholders.clearStakeholderTable();
    this.stakeholders = this.subStakeholders.stakeholders;
  }

  isPreviewGopValid(): boolean {
    throw new Error("Gop preview not supported for this service order type");
  }

  getStartDate(): moment.Moment {
    return this.travelInfo.departureDate;
  }

  getStartLocation(): Location {
    return this.travelInfo.departureFrom;
  }

  getEndLocation(): Location {
    return this.travelInfo.arrivalAt;
  }

  getEndDate(): moment.Moment {
    return this.travelInfo.arrivalDate;
  }

  isMultipleDurations(): boolean {
    return this.travellers.flightLegs.length > 1;
  }

  hasMedicalEscort(): boolean {
    return false;
  }

  hasCoTravellers(): boolean {
    return false;
  }

  setCommitmentType(commitmentType: CommitmentType) {
    this.commitmentType = commitmentType;
  }

  getCommitmentType(): CommitmentType {
    return this.commitmentType;
  }

  getSelectedStakeholderIds(): string[] {
    return this.subStakeholders.stakeholders.length === 0
      ? null
      : this.subStakeholders.stakeholders.map(subStakeholder => subStakeholder.stakeholderId);
  }
}

export class GroundAmbulanceServiceOrderExtensionAdapter {
  adapt(item?: Partial<GroundAmbulanceServiceOrderExtension>): GroundAmbulanceServiceOrderExtension {
    const travelInfoAdapter = new SubTravelInfoAdapter();
    const travelInfoAdapted = travelInfoAdapter.adapt(item.travelInfo);
    const travelInfoAddaptedArray: SubTravelInfo[] = [];
    travelInfoAddaptedArray.push(travelInfoAdapted);
    const commitmentTypeAdapter: CommitmentTypeAdapter = new CommitmentTypeAdapter();
    return new GroundAmbulanceServiceOrderExtension({
      id: item.id,
      transportType: item.transportType,
      commitmentType:
        item.commitmentType != null ? commitmentTypeAdapter.adapt(item.commitmentType) : null,//TODO new SubCommitmentType(CommitmentTypes.BOOKING),
      remarksFromSupplier: item.remarksFromSupplier == null ? new SubRemarksFromSupplier() : item.remarksFromSupplier,
      remarksToSupplier: item.remarksToSupplier == null ? new SubRemarksToSupplier() : item.remarksToSupplier,
      travelInfo: travelInfoAdapted,
      travellers: new SubTravellers(travelInfoAddaptedArray),
      stakeholders: item.stakeholders,
      subStakeholders: new SubStakeholders(item.stakeholders),
    });
  }
}
