import { Component, Input, OnInit } from '@angular/core';
import { SubRemarksRulesAndFees } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subRemarksRulesAndFees';

@Component({
  selector: 'app-sub-remarks-rules-and-fees',
  templateUrl: './sub-remarks-rules-and-fees.component.html',
  styleUrls: ['./sub-remarks-rules-and-fees.component.scss']
})
export class SubRemarksRulesAndFeesComponent implements OnInit {
  @Input() subRemarksRulesAndFees: SubRemarksRulesAndFees;

  constructor() { }

  ngOnInit() {
  }

}
