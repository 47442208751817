
<div class="d-flex">
  <div class="w-25">
    <div class="text-label">{{ 'task-modal-case-id' | translate }}</div>
    <div class="text-content">{{ case.caseNumber }}</div>
  </div>
  <div class="w-25">
    <div class="text-label">{{ 'task-modal-end-user' | translate }}</div>
    <div class="text-content" *ngIf="case.caseNumber">{{ trimText(case.endUserName, 40) }}</div>
  </div>
  <div class="w-25">
    <div class="text-label">{{ 'task-modal-applied-amount' | translate }}</div>
    <div class="text-content">{{recovery?.recoveryCurrency}} {{ recovery?.totalAppliedAmount | numberFormat }}</div>
  </div>
  <div class="w-25">
    <div class="text-label">{{ 'task-modal-application-deadline' | translate }}</div>
    <div class="text-content">{{ recovery?.applicationDeadline  | date:'dd MMM yyyy':'local'}}</div>
  </div>
</div>
<div class="row-separator"></div>
<div class="d-flex">
  <div class="w-25">
    <div class="text-label">{{ 'task-modal-customer' | translate }}</div>
    <div class="text-content">{{ profileName }}</div>
  </div>
  <div class="w-25">
    <div class="text-label">{{ 'task-modal-location' | translate }}</div>
    <div class="text-content">{{ trimText(location, 40) }}</div>
  </div>
  <div class="w-25">
    <div class="text-label">{{ 'task-modal-recovered-amount' | translate }}</div>
    <div class="text-content">{{recovery?.recoveryCurrency}} {{ recovery?.totalRecoveredAmount | numberFormat }}</div>
  </div>
  <div class="w-25">
    &nbsp;
  </div>
</div>
<div class="row-separator"></div>
<div class="d-flex">
  <div class="w-25">
    &nbsp;
  </div>
  <div class="w-25">
    <div class="text-label">{{ 'task-modal-cause' | translate }}</div>
    <div class="text-content">{{ trimText(incidentCause, 40) }}</div>
  </div>
  <div class="w-25">
    &nbsp;
  </div>
  <div class="w-25">
    &nbsp;
  </div>
</div>
