import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ErpCreditor } from '@secca/shared/models/erp-creditor';
import { RecoveryPaymentService } from '@secca/core/services/recovery/recovery-payment.service';
import { RecoveryPaymentItem } from '@secca/shared/models/recoveryPaymentItem';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import { RecoveryPayment } from '@secca/shared/models/recoveryPayment';
import { PermissionEnum, QuantityUnit, RecoveryPaymentStatus } from '@secca/shared/models/enums';
import { PermissionService } from '@secca/core/services/permission.service';

@Component({
  selector: 'app-recovery-payment',
  templateUrl: './recovery-payment.component.html',
  styleUrls: ['./recovery-payment.component.scss']
})
export class RecoveryPaymentComponent implements OnInit {
  @Output()
  closeRecoveryPayment = new EventEmitter<string>();
  @Input()
  recoveryPayment: RecoveryPayment;
  @Input()
  serviceItemOptions: DropdownDictionary[];
  @Input()
  recoveryOptions: DropdownDictionary[];

  creditor = new ErpCreditor();

  constructor(private recoveryPaymentService: RecoveryPaymentService, private permissionService: PermissionService) {
  }

  ngOnInit(): void {
    this.recoveryPaymentService.getErpCreditor(this.recoveryPayment.getCreditorId()).subscribe(creditor => {
      this.creditor = creditor;
    });

    if (this.recoveryPayment.items.length === 0) {
      this.recoveryPayment.items.push(this.createRecoveryPaymentItem(true));
    }
  }

  close() {
    this.closeRecoveryPayment.emit('close');
  }

  itemRemoved(recoveryPaymentItem: RecoveryPaymentItem) {
    this.recoveryPayment.items = this.recoveryPayment.items.filter(item => item !== recoveryPaymentItem);
    this.itemChanged();
  }

  itemChanged() {
    let firstItem = this.recoveryPayment.items[0];
    let diffFromTotal = this.recoveryPayment.amountLocal.amount - this.recoveryPayment.items.reduce((acc, item) => acc + item.amount, 0);
    firstItem.amount = firstItem.amount + diffFromTotal;
  }

  isFirstItem(recoveryPaymentItem: RecoveryPaymentItem): boolean {
    return this.recoveryPayment.items.indexOf(recoveryPaymentItem) === 0;
  }

  isApproved(): boolean {
    return this.recoveryPayment.status === RecoveryPaymentStatus.APPROVED ;
  }

  hasAccess(): boolean {
    return this.permissionService.checkUserPermission(PermissionEnum.RECOVERY_UPDATE)
  }

  rejectRecoveryPayment() {

  }

  returnRecoveryPaymentToAccounting() {

  }

  isInvalid(): boolean {
    let validationResult = false;
    this.recoveryPayment.items.forEach(item => {
      if (item.amount == null || item.recoveryId == null || item.serviceItemCode == null ||
        item.amount === undefined || item.recoveryId === undefined || item.serviceItemCode === undefined) {
        validationResult = true;
      }
    });
    return validationResult;
  }


  saveRecoveryPayment() {
    if (this.recoveryPayment.status === RecoveryPaymentStatus.RECEIVED) {
      this.recoveryPayment.status = RecoveryPaymentStatus.ONGOING;
    }
    this.recoveryPaymentService.updateRecoveryPayment(this.recoveryPayment).subscribe(payment => {
      this.recoveryPayment = payment;
      this.close();
    });
  }

  approveRecoveryPayment() {
    this.recoveryPayment.status = RecoveryPaymentStatus.APPROVED;
    this.recoveryPaymentService.updateRecoveryPayment(this.recoveryPayment).subscribe(payment => {
      this.recoveryPayment = payment;
      this.close();
    });
  }

  addItem() {
    this.recoveryPayment.items.push(this.createRecoveryPaymentItem(false));
  }

  createRecoveryPaymentItem(first: Boolean): RecoveryPaymentItem {
    let amount = this.recoveryPayment.amountLocal.amount;
    let recoveryId = this.recoveryPayment.recoveryId;
    if (!first) {
      recoveryId = null;

      amount = this.recoveryPayment.amountLocal.amount - this.recoveryPayment.items.reduce((acc, item) => acc + item.amount, 0);
    }

    let newItem = new RecoveryPaymentItem();
    newItem.recoveryId = recoveryId;
    newItem.amount = amount;
    newItem.quantity = 1;
    newItem.quantityUnit = QuantityUnit.pcs;
    return newItem;
  }
}
