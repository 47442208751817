<div *ngIf="cotraveller">
  <div class="message-content-header">
    <div class="heading">                
      <span *ngIf="isEnduser(cotraveller)"><img src="/assets/icons/End-User.svg"></span>
      <span *ngIf="isReporter(cotraveller)"><img src="/assets/icons/Reporter.svg"></span>
      <span *ngIf="isPolicyHolder(cotraveller)"><img src="/assets/icons/Policy Holder.svg"></span>
      <span *ngIf="!isEnduser(cotraveller) && !isReporter(cotraveller) && !isPolicyHolder(cotraveller)"><img src="/assets/icons/Person.svg"></span>
      <div class="text">
        <span *ngIf="isEnduser(cotraveller)">{{'task-web-modal-end-user' | translate}}</span>
        <span *ngIf="isReporter(cotraveller)"><span *ngIf="isEnduser(cotraveller)"> / </span>{{'task-web-modal-reporter' | translate}}</span>
        <span *ngIf="isPolicyHolder(cotraveller)"><span *ngIf="isEnduser(cotraveller) || isReporter(cotraveller)"> / </span>{{'task-web-modal-policy-holder' | translate}}</span>
        <span *ngIf="!isEnduser(cotraveller) && !isReporter(cotraveller) && !isPolicyHolder(cotraveller)">
          <span *ngIf="isEnduser(cotraveller) || isReporter(cotraveller) || isPolicyHolder(cotraveller)"> / </span>{{'task-web-modal-co-traveller' | translate}}
        </span>
      </div>
    </div>
  </div>
  <div class="message-content-box">
    <app-task-web-properties [properties]="cotraveller" [ignoredProperties]="ignoredProperties"
      [serviceItemList]="serviceItemList" [purposeOfTravelItemList]="purposeOfTravelItemList"
      [causes]="causes" [diagnoses]="diagnoses" [customerProfiles]="customerProfiles"></app-task-web-properties>
  </div>
  <br>
</div>
