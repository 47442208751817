import { Component, Inject, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppInjector } from '../../app.module';
import { ShortcutService } from '@secca/core/services/shortcut.service';
import { ShortcutEnum } from '@secca/shared/models/enums';

@Component({ template: '' })
export abstract class AbstractGlobalBoardTableShortcutsComponent implements OnDestroy {

  protected numberOfRows: number;
  protected selectedRowIndex: number;
  protected selectedElement: HTMLElement;

  protected shortcutService: ShortcutService;
  protected shortcutSubscriptions: Subscription[] = [];

  private rowIndexOffset: number;

  protected constructor(@Inject(String) private id: string) {
    this.shortcutService = AppInjector.get(ShortcutService);

    this.shortcutSubscriptions.push(
      this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateToTable }).subscribe(a => {
        this.navigateToTable();
      }),
      this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateTableRowUp }).subscribe(a => {
        // this.onNavigateTableRowUp();
        setTimeout(() => this.onNavigateTableRowUp(), 50);
      }),
      this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateTableRowDown }).subscribe(a => {
        // this.onNavigateTableRowDown();
        setTimeout(() => this.onNavigateTableRowDown(), 50);
      }),
      this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateTableRowCtrlEnter }).subscribe(a => {
        (document.getElementById(this.id)?.children[this.selectedRowIndex + this.rowIndexOffset] as HTMLElement)?.click();
      }),
      this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateTableRowSpace }).subscribe(a => {
        const firstColumn = ((document.getElementById(this.id)?.children[this.selectedRowIndex + this.rowIndexOffset] as HTMLElement)?.children[0] as HTMLElement);
        const checkboxParent = firstColumn.getElementsByClassName('checkbox');
        if (checkboxParent && checkboxParent.item(0).hasChildNodes()) {
          (checkboxParent.item(0).children[0] as HTMLElement).click();
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.shortcutSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  protected navigateToTable() {
//    console.log('----%%%> id', this.id);
    // Skip for team and assignee!
    if (document.activeElement.nodeName === 'INPUT') {
      const universe = document.querySelectorAll('input');
      const list = Array.prototype.filter.call(universe, item => item.tabIndex >= '0');
      const index = list.indexOf(document.activeElement);

      if (index === 0) {
        // Focus element assignee and skip navigate to table
        (list[index + 1] || list[0]).focus();
        return;
      } else {
        // Unfocus element assignee and continue with navigate to table
        (document.activeElement as HTMLElement).blur();
      }
    }

    // Reset
//    if (this.selectedRowIndex && this.rowIndexOffset) {
    if (typeof this.selectedRowIndex === 'number' && typeof this.rowIndexOffset === 'number') {
      (document.getElementById(this.id)?.children[this.selectedRowIndex + this.rowIndexOffset] as HTMLElement)?.classList.remove('selected-row');
    }
    // Prepare
    this.rowIndexOffset = 0;
    if (document.getElementById(this.id)?.firstChild && document.getElementById(this.id)?.firstChild.nodeName !== 'TR') {
      this.rowIndexOffset = 1;
    }
    // Navigate
    const caseTableFirstRowElement = document.getElementById(this.id)?.firstChild ? (document.getElementById(this.id)?.children[this.rowIndexOffset] as HTMLElement) : null;
    if (!!this.selectedRowIndex && document.getElementById(this.id)) {
      (document.getElementById(this.id)?.children[this.selectedRowIndex + this.rowIndexOffset] as HTMLElement)?.classList.add('selected-row');

      const clientHeight = document.getElementById(this.id).clientHeight;
      const tableBottom = document.getElementById(this.id).getBoundingClientRect().bottom;
      const elementBottom = document.getElementById(this.id)?.children[this.selectedRowIndex + this.rowIndexOffset].getBoundingClientRect().bottom;
      if (this.selectedRowIndex * 40 > clientHeight && elementBottom > tableBottom) {
//        document.getElementById(this.id)?.children[this.selectedRowIndex + this.rowIndexOffset].scrollIntoView(false);
      }
    } else if (caseTableFirstRowElement) {
      this.selectedRowIndex = 0;
      caseTableFirstRowElement.classList.add('selected-row');
      caseTableFirstRowElement.focus();
    }
  }

  protected onAltEnter() {
  }

  protected onCtrlEnter() {
  }

  private onNavigateTableRowUp() {
//    console.log('----###> id', this.id);
    if (document.activeElement.nodeName !== 'INPUT' && this.selectedRowIndex > 0 && (document.getElementById(this.id)?.children[this.selectedRowIndex + this.rowIndexOffset] as HTMLElement)?.classList) {
      (document.getElementById(this.id)?.children[this.selectedRowIndex + this.rowIndexOffset] as HTMLElement).classList.remove('selected-row');
      this.selectedRowIndex--;
      (document.getElementById(this.id).children[this.selectedRowIndex + this.rowIndexOffset] as HTMLElement).classList.add('selected-row');

      const tableTop = document.getElementById(this.id).getBoundingClientRect().top;
      const elementTop = document.getElementById(this.id).children[this.selectedRowIndex + this.rowIndexOffset].getBoundingClientRect().top;
      if (elementTop < tableTop) {
        document.getElementById(this.id).children[this.selectedRowIndex + this.rowIndexOffset].scrollIntoView(true);
      }
//      console.log('tableTop', tableTop);
//      console.log('elementBoundingClientRect', document.getElementById(this.id).children[this.selectedRowIndex + this.rowIndexOffset].getBoundingClientRect());
//      console.log('elementClientHeight', document.getElementById(this.id).children[this.selectedRowIndex + this.rowIndexOffset].clientHeight);
    }
  }

  private onNavigateTableRowDown() {
//    console.log('----&&&> id', this.id);
    if (document.activeElement.nodeName !== 'INPUT' && this.selectedRowIndex < this.numberOfRows - 1 && (document.getElementById(this.id)?.children[this.selectedRowIndex + this.rowIndexOffset] as HTMLElement)?.classList) {
      (document.getElementById(this.id).children[this.selectedRowIndex + this.rowIndexOffset] as HTMLElement).classList.remove('selected-row');
      this.selectedRowIndex++;
      (document.getElementById(this.id).children[this.selectedRowIndex + this.rowIndexOffset] as HTMLElement).classList.add('selected-row');

      const clientHeight = document.getElementById(this.id).clientHeight;
      // console.log('selectedRowIndex:currentRowHeight:clientHeight', this.selectedRowIndex, this.selectedRowIndex * 40, clientHeight);
      const tableBottom = document.getElementById(this.id).getBoundingClientRect().bottom;
      const elementBottom = document.getElementById(this.id).children[this.selectedRowIndex + this.rowIndexOffset].getBoundingClientRect().bottom;
      // console.log('tableBottom:elementBottom', tableBottom, elementBottom, elementBottom > tableBottom);
      if (this.selectedRowIndex * 40 > clientHeight && elementBottom > tableBottom) {
        // console.log('set-->scrollIntoView:false');

        // document.getElementById(this.id).scrollTop = (this.selectedRowIndex * 40) + 40;
        document.getElementById(this.id).children[this.selectedRowIndex + this.rowIndexOffset].scrollIntoView(false);
      }
    }
  }
}
