import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { TaskWebModalMainPanelComponent } from "@secca/board/components/board-task/board-task-incoming-cases-table/task-web-modal/task-web-main-panel/task-web-modal-main-panel.component";
import { IncomingCasesTaskViewModel } from "@secca/board/components/board-task/models/incoming-cases-task-view-model";
import { CaseLockHelperService } from "@secca/core/services/case-lock-helper.service";
import { CaseService } from "@secca/core/services/case.service";
import { MasterListService } from "@secca/core/services/masterlist-service";
import { PermissionService } from "@secca/core/services/permission.service";
import { TaskService } from "@secca/core/services/task.service";
import { ChannelType } from "@secca/shared/enums/channelType";
import { CaseHistoryCommEntry } from "@secca/shared/models/caseHistoryEntry";
import { CauseItemType } from "@secca/shared/models/cause-item-type";
import { Channel } from "@secca/shared/models/channel";
import { CustomerProfile } from "@secca/shared/models/customerProfile";
import { Diagnosis } from "@secca/shared/models/dignosis";
import { PurposeOfTravelItemType } from "@secca/shared/models/purpose-of-travel-item-type";
import { ServiceItemType } from "@secca/shared/models/service-item-type";

@Component({
  selector: 'app-case-summary-external-modal',
  templateUrl: './case-summary-external-modal.component.html',
  styleUrls: ['./case-summary-external-modal.component.scss'],
})
export class CaseSummaryExternalModalComponent implements OnInit {
  @ViewChild(TaskWebModalMainPanelComponent) taskModalMainPanelComponent;

  @Input() entry: CaseHistoryCommEntry;
  @Input() channelType: ChannelType;

  @Input() set caseId(value: number) {
    this.caseService.getCustomerProfilesByCase(value+"").subscribe(result => this.customerProfiles = result);
  }

  @Output()
  closed: EventEmitter<void> = new EventEmitter();

  serviceItemList: ServiceItemType[];
  purposeOfTravelItemList: PurposeOfTravelItemType[];
  causes: Map<string, CauseItemType[]> = new Map<string, CauseItemType[]>();
  diagnoses: Diagnosis[];
  customerProfiles: CustomerProfile[];
  channels: Channel[];

  taskDataViewModel: IncomingCasesTaskViewModel;

  constructor(private taskService: TaskService,
              private caseService: CaseService,
              private masterListService: MasterListService,
              public permissionService: PermissionService,
              public caseLockHelperService: CaseLockHelperService) {
    this.masterListService.getServiceItemList().subscribe(result => this.serviceItemList = result);
    this.masterListService.getPurposeOfTravelList().subscribe(result => this.purposeOfTravelItemList = result);
    this.masterListService.getAllLevel1Causes().subscribe(result => this.causes.set('level1', result));
    this.masterListService.getAllLevel2Causes().subscribe(result => this.causes.set('level2', result));
    this.masterListService.getAllLevel3Causes().subscribe(result => this.causes.set('level3', result));
    this.masterListService.getAllDiagnoses().subscribe(result => this.diagnoses = result);
    this.masterListService.getAllChannelTypes().subscribe(result => this.channels = result);
  }

  ngOnInit() {
    this.getTaskWithData();
  }

  save() {
    if (this.internalSummaryHasBeenUpdated) {
      this.taskService.getTask(this.taskDataViewModel.id).subscribe(task => {
        task.internalSummary = this.taskModalMainPanelComponent.internalSummary;
        this.taskService.updateTask(task).subscribe(
          () => this.close()
        );
      });
    }
  }

  close(): void {
    this.closed.emit();
  }

  getChannelTypeName(channelTypeEnumValue: string) {
    return this.channels?.find(c => c.channelTypeEnumValue === channelTypeEnumValue)?.channelTypeName;
  }

  get internalSummaryHasBeenUpdated() {
    return this.taskModalMainPanelComponent?.internalSummary !== this.taskDataViewModel?.internalSummary;
  }

  get ChannelType() {
    return ChannelType;
  }

  private getTaskWithData(): void {
    const taskId = this.entry?.dataFieldChanges?.find(field => field.field === 'TaskId')?.newValue;

    if ( taskId ) {
      this.taskService.getWebTaskById(+taskId).subscribe(
        taskModel => {
          this.taskDataViewModel = taskModel;
          this.taskModalMainPanelComponent.internalSummary = this.taskDataViewModel.internalSummary;
        }
      );
    }
  }
}
