import { Case } from 'src/app/shared/models/case';
import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { DropdownDictionary } from '../../../../../shared/models/dropdownDictionary';
import { CaseSummaryService } from 'src/app/core/services/caseSummary.service';
import { UserDto } from '../../../../../shared/models/userDto';
import { CaseSummaryFilter } from 'src/app/shared/models/caseSummaryFilter';
import { CaseSummarySearchFilter } from '@secca/shared/models/CaseSummarySearchFilter';
import { PagingInfo } from '@secca/shared/models/paging-info';
import { Subscription } from 'rxjs';
import { SettingsService } from '@secca/core/services/settings.service';
import { CaseService } from '@secca/core/services/case.service';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';

@Component({
  selector: 'app-case-summary-filter',
  templateUrl: './case-summary-filter.component.html',
  styleUrls: ['./case-summary-filter.component.scss']
})
@AutoUnsubscribe
export class CaseSummaryFilterComponent implements OnInit, AfterViewInit {
  @Input()
  private case: Case;

  @Input()
  searchFilter: CaseSummarySearchFilter;

  @Input()
  users: UserDto[] = [];

  @Input()
  typeItems: DropdownDictionary[] = [];

  @Input()
  pagingInfo: PagingInfo;

  $subscr2: Subscription;

  constructor(private caseSummaryService: CaseSummaryService,
              private settingsService: SettingsService, private caseService: CaseService) {
    this.caseSummaryService.setCaseSummarySubject(null);
  }

  ngOnInit() {
    this.pagingInfo.pageIndex = 0;
    this.performSearch();
    this.$subscr2 = this.caseService.observeCaseNotesChanges().subscribe(caseId => {
        this.performSearch();
    });

  }

  ngAfterViewInit(): void {
  }

  selectedTypeClose() {
    this.pagingInfo.pageIndex = 0;
    this.performSearch();
  }

  selectedDateRange() {
    this.pagingInfo.pageIndex = 0;
    this.performSearch();
  }

  selectedCaseHandlerChange() {
    this.pagingInfo.pageIndex = 0;
    this.performSearch();
  }

  typedInText() {
    this.pagingInfo.pageIndex = 0;
    this.performSearch();
  }

  pagingChanged(index: number) {
    this.pagingInfo.pageIndex = index;
    this.performSearch();
  }

  resetFilters() {
    this.searchFilter.typeItems = [];
    this.searchFilter.dateRange = [];
    this.searchFilter.users = [];
    this.searchFilter.searchText = '';

    this.performSearch();
  }

  performSearch() {
    this.caseSummaryService.getCaseSummary(this.case.id, this.getFilterRequest()).subscribe(
      result => {
        this.caseSummaryService.setCaseSummarySubject(result);
        this.pagingInfo.totalNumber = result.totalNumber;
      },
      error => console.log(error)
    );
  }

  private getFilterRequest(): CaseSummaryFilter {
    const caseSummaryFilter = new CaseSummaryFilter();
    caseSummaryFilter.types = this.searchFilter.typeItems;
    caseSummaryFilter.dateFrom = this.getFromDate(this.searchFilter.dateRange);
    caseSummaryFilter.dateTo = this.getToDate(this.searchFilter.dateRange);
    caseSummaryFilter.authorsIds = this.searchFilter.users ? this.searchFilter.users.map(user => user.userId) : [];
    caseSummaryFilter.searchText = this.searchFilter.searchText;
    caseSummaryFilter.pageSize = this.pagingInfo.pageSize;
    caseSummaryFilter.pageIndex = this.pagingInfo.pageIndex;
    caseSummaryFilter.sortOrder = this.pagingInfo.sortOrder;

    return caseSummaryFilter;
  }

  private getFromDate(dateRange: Date[]): Date {
    return (dateRange && dateRange.length > 0) ? this.setLowerBoundOnDate(dateRange[0]) : null;
  }

  private getToDate(dateRange: Date[]): Date {
    return (dateRange && dateRange.length > 1) ? this.setUpperBoundOnDate(dateRange[1]) : null;
  }

  private setUpperBoundOnDate(date: Date): Date {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);

    return date;
  }

  private setLowerBoundOnDate(date: Date): Date {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);

    return date;
  }
}
