import { BalanceSheetProgress } from '@secca/shared/models/balance-sheet-event.model';
import { BalanceSheetEntryEnum, RefundStatus } from '@secca/shared/models/enums';
import * as moment from 'moment-timezone';

export class ServiceOrderRefundViewModel  {
    id: number;
    originalAmount: number;
    exchangedAmount: number;
    reduction: number;
    exchangedNetAmount: number;
    serviceItemCode: string;
    refundCurrency: string;
    serviceItemName: String[];
    refundStatus: RefundStatus;
    dueDate: moment.Moment;
    serviceOrderRefundItemViewModel: ServiceOrderRefundItemViewModel[];
    progress: BalanceSheetProgress;
    internalRemark: string;

    getType(): BalanceSheetEntryEnum {
        return BalanceSheetEntryEnum.REFUND;
      }
}


export class ServiceOrderRefundItemViewModel {
  itemName: string;
  amount: number;
  amountExchanged: number;
  exchangeCurrency: string;
  reduction: number;
  settled: number;
}


