import {HandlingArea, HandlingAreaAdapter} from '@secca/shared/models/handlingArea';
import {HandlingAreaStatusEnum, HandlingAreaType} from '@secca/shared/models/enums';
import {UserService} from '@secca/core/services/user.service';
import {Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AutoCompleteTypeEnum} from '@secca/shared/components/drop-down-auto-complete/auto-complete-type-enum';
import {DictionaryService} from '@secca/core/services/dictionary.service';
import {Subscription} from 'rxjs';
import {DropdownDictionary} from '@secca/shared/models/dropdownDictionary';
import {SearchUserAndTeam} from '@secca/shared/models/searchUserAndTeam';
import {TeamDto} from '@secca/shared/models/teamDto';
import {UserDto} from '@secca/shared/models/userDto';
import {HandlingAreaService} from '@secca/core/services/handling-area.service';
import {TranslateService} from '@ngx-translate/core';
import {CaseValidationService} from '@secca/core/services/case-validation.service';

@Component({
  selector: 'app-case-handler-modal',
  templateUrl: './case-handler-modal.component.html',
  styleUrls: ['./case-handler-modal.component.scss'],
})
export class CaseHandlerModalComponent implements OnInit {
  @ViewChild('content') private template: TemplateRef<any>;

  @Output() changeHandlingArea: EventEmitter<HandlingArea[]> = new EventEmitter();

  caseId: number;
  handlingAreaType: string;

  handlingArea: HandlingArea;
  subscribe: Subscription;
  subscribe2: Subscription;
  isChangeHandlerVisible: boolean;
  caseValid: false;
  userImage: string;
  status: DropdownDictionary[];
  theStatus: HandlingAreaStatusEnum;
  handlerShortcutName: string;
  handlerTeamName: string;
  userIdOnOpen: number;
  adtCodeOnOpen: string;
  statusOnOpen: HandlingAreaStatusEnum;
  saveable = false;
  translatePreFix = 'HANDLING-AREA-';
  closable = false;
  validationTexts: String[] = [];
  caseValidationTexts: String[] = [];

  ngOnInit() {
  }

  constructor(
    private modalService: NgbModal,
    private dictionaryService: DictionaryService,
    private userService: UserService,
    private handlingAreaAdapter: HandlingAreaAdapter,
    private handlingAreaService: HandlingAreaService,
    private translate: TranslateService,
    private caseValidationService: CaseValidationService
  ) {
  }

  updateUrl() {
    this.userImage = 'assets/icons/NoImageYet.svg';
  }

  get AutoCompleteTypeEnum() {
    return AutoCompleteTypeEnum;
  }

  caseHandlerChanged(userOrTeam: SearchUserAndTeam) {
    if (userOrTeam.isTeam) {
      this.handlingArea.team = new TeamDto();
      this.handlingArea.team.adtCode = userOrTeam.adtCode;
      this.handlerShortcutName = userOrTeam.teamName;
      this.handlerTeamName = null;
      this.handlingArea.user = null;
      this.userImage = null;
      this.validChange();
    } else {
      this.handlingArea.user = new UserDto();
      const id: number = userOrTeam.id;
      this.subscribe = this.dictionaryService.searchUsersExact(id).subscribe(result => {
        if (result.length === 1) {
          this.handlingArea.user.picture = result[0].picture;
          this.handlingArea.user.active = result[0].active;
          this.handlerTeamName = result[0].team.teamName;
          this.handlingArea.user.id = result[0].id;
          this.handlerShortcutName = result[0].fullUserName;
          this.handlingArea.team = null;
          this.userImage = this.getUserImage();
        } else {
          this.handlingArea.user = null;
        }
        this.validChange();
      });
    }
    this.isChangeHandlerVisible = false;
  }

  makeChangeHandlerVisible(e: Event) {
    e.stopPropagation();
    this.isChangeHandlerVisible = true;
  }

  makeCaseHandlerVisible(e: Event) {
    this.isChangeHandlerVisible = false;
    if (!this.handlingArea.user && !this.handlingArea.team) {
      this.isChangeHandlerVisible = true;
    }
  }

  getUserImage() {
    const activeUser = !!this.handlingArea && !!this.handlingArea.user && this.handlingArea.user.active;
    if (activeUser && !!this.handlingArea.user.picture) {
      return this.userService.getUserImagesUrl(this.handlingArea.user.id.toString());
    } else if (activeUser && !this.handlingArea.user.picture) {
      return '/assets/icons/NoImageYet.svg';
    } else {
      return '/assets/icons/DeactivatedUser.svg';
    }
  }

  getHandlerTeamName() {
    if (this.handlingArea && this.handlingArea.user && this.handlingArea.user.userTeam) {
      return this.handlingArea.user.userTeam.teamName;
    } else {
      return null;
    }
  }

  getHandlerShortcutName() {
    if (
      this.handlingArea &&
      this.handlingArea.user &&
      this.handlingArea.user.firstName &&
      this.handlingArea.user.surname &&
      this.handlingArea.user.initials
    ) {
      return this.handlingArea.user.firstName + ' ' + this.handlingArea.user.surname + ' (' + this.handlingArea.user.initials + ')';
    } else if (
      this.handlingArea &&
      this.handlingArea.user &&
      this.handlingArea.user.firstName &&
      this.handlingArea.user.surname &&
      !this.handlingArea.user.initials
    ) {
      return this.handlingArea.user.firstName + ' ' + this.handlingArea.user.surname;
    } else if (this.handlingArea && this.handlingArea.team) {
      return this.handlingArea.team.teamName;
    } else {
      return '⋅ ⋅ ⋅';
    }
  }

  handlerIsAnUser() {
    return !!this.handlingArea.user;
  }

  getHandlerStatus() {
    return HandlingAreaStatusEnum;
  }

  changeStatus(currentHandlingAreaStatus: HandlingAreaStatusEnum) {
    switch (currentHandlingAreaStatus) {
      case HandlingAreaStatusEnum.OPEN: {
        this.status = [
          {id: HandlingAreaStatusEnum.OPEN, name: this.translate.instant(this.translatePreFix + HandlingAreaStatusEnum.OPEN)},
          {id: HandlingAreaStatusEnum.CLOSED, name: this.translate.instant(this.translatePreFix + HandlingAreaStatusEnum.CLOSED)},
        ];
        break;
      }
      case HandlingAreaStatusEnum.CLOSED: {
        this.status = [
          {id: HandlingAreaStatusEnum.CLOSED, name: this.translate.instant(this.translatePreFix + HandlingAreaStatusEnum.CLOSED)},
          {id: HandlingAreaStatusEnum.REOPENED, name: this.translate.instant(this.translatePreFix + HandlingAreaStatusEnum.REOPENED)},
        ];
        break;
      }
      case HandlingAreaStatusEnum.REOPENED: {
        this.status = [
          {id: HandlingAreaStatusEnum.REOPENED, name: this.translate.instant(this.translatePreFix + HandlingAreaStatusEnum.REOPENED)},
          {id: HandlingAreaStatusEnum.CLOSED, name: this.translate.instant(this.translatePreFix + HandlingAreaStatusEnum.CLOSED)},
        ];
        break;
      }
    }
  }

  setNewStatus() {
    this.validChange();
  }

  open(handlingAreaType: string, handlingArea: HandlingArea, caseId: number) {
    this.modalService.open(this.template, {size: 'xs' as any, windowClass: 'case-handler-popup'});
    this.handlingArea = new HandlingArea();
    this.caseValidationTexts = [];
    this.validationTexts = [];

    this.userIdOnOpen = !!handlingArea.user ? handlingArea.user.id : null;
    this.adtCodeOnOpen = !!handlingArea.team ? handlingArea.team.adtCode : null;
    this.statusOnOpen = handlingArea.status;
    this.handlingAreaType = handlingAreaType;
    this.handlingArea = this.handlingAreaAdapter.adapt(handlingArea);

    this.userImage = this.getUserImage();
    this.isChangeHandlerVisible = !this.handlingArea.user && !this.handlingArea.team;
    this.caseId = caseId;
    this.changeStatus(this.handlingArea.status);
    this.theStatus = this.handlingArea.status;
    this.setNewStatus();
    if (this.handlingArea.closeValidations) {
      this.validationTexts = this.handlingArea.closeValidations.slice();
    }

    if (this.validationTexts.includes('CASE_NOT_VALIDATED')) {
      this.validationTexts = this.validationTexts.filter(function(value, index, arr) {
        return value !== 'CASE_NOT_VALIDATED';
      });

      this.caseValidationService.isCaseClosedValid('' + this.caseId).subscribe(result => {
        this.caseValidationTexts = result.caseValidateList;

        if (this.validationTexts.includes('INCIDENT_CAUSE_LEVEL_2')) {
          this.validationTexts = this.validationTexts.filter(function(value, index, arr) {
            return value !== 'INCIDENT_CAUSE_LEVEL_2';
          });
          this.caseValidationTexts.push('INCIDENT_CAUSE_LEVEL_2');
        }

      });
    } else if (this.validationTexts.includes('INCIDENT_CAUSE_LEVEL_2')) {
      this.caseValidationTexts = ['INCIDENT_CAUSE_LEVEL_2'];
      this.validationTexts = this.validationTexts.filter(function(value, index, arr) {
        return value !== 'INCIDENT_CAUSE_LEVEL_2';
      });
    } else {
      this.caseValidationTexts = [];
    }

    this.handlerShortcutName = this.getHandlerShortcutName();
    this.handlerTeamName = this.getHandlerTeamName();
  }

  validChange() {
    const userIdOnNow = !!this.handlingArea.user ? this.handlingArea.user.id : null;
    const adtCodeOnNow = !!this.handlingArea.team ? this.handlingArea.team.adtCode : null;
    const userChange = userIdOnNow !== this.userIdOnOpen;
    const teamChange = adtCodeOnNow !== this.adtCodeOnOpen;
    const statusChange = this.statusOnOpen !== this.theStatus;

    this.closable = this.theStatus !== HandlingAreaStatusEnum.CLOSED || !this.handlingArea.closeValidations || this.handlingArea.closeValidations.length === 0;
    this.saveable = (userChange || teamChange || statusChange) && this.closable;

  }

  close() {
    this.modalService.dismissAll();
  }

  getAutoCompleteTypeEnum() {
    let autoCompleteType;
    switch (this.handlingArea.handlingAreaType) {
      case HandlingAreaType.CASE_HANDLER :
        autoCompleteType = AutoCompleteTypeEnum.UsersAndTeamsExceptFromOtherDepartment;
        break;
      case HandlingAreaType.MEDICAL :
        autoCompleteType = AutoCompleteTypeEnum.UsersAndTeamsExceptFromOtherDepartment;
        break;
      case HandlingAreaType.CLAIMS :
        autoCompleteType = AutoCompleteTypeEnum.UsersAndTeamsExceptFromOtherDepartment;
        break;
      case HandlingAreaType.TRANSPORT :
        autoCompleteType = AutoCompleteTypeEnum.UsersAndTeamsExceptFromOtherAndCostControlDepartment;
        break;
      case HandlingAreaType.COST_CONTROL :
        autoCompleteType = AutoCompleteTypeEnum.UsersAndTeamsExceptFromOtherDepartment;
        break;
      default:
        autoCompleteType = AutoCompleteTypeEnum.UsersAndTeamsExceptFromOtherDepartment;
        break;
    }
    return autoCompleteType;
  }

  save() {
    if (this.theStatus === HandlingAreaStatusEnum.NON_ACTIVE) {
      this.theStatus = HandlingAreaStatusEnum.OPEN;
    }

    this.handlingArea.status = this.theStatus;
    this.handlingAreaService.saveHandlingArea(this.caseId, this.handlingArea).subscribe(result => {
      this.changeHandlingArea.emit(result);
      this.close();
    });
  }
}
