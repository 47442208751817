import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
import { PreferredChannel } from './enums';
import { PhoneNumber } from './phoneNumber';
import { PhoneNumberType } from './enums';
import { PropertyType } from '@secca/shared/models/PropertyType';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';

export class ContactPerson {
  id: string;
  firstName: string;
  surname: string;
  phoneNumbers: PhoneNumber[];
  preferredChannelCode: PreferredChannel;
  remarks: string;
  email: string;
  preferredLanguageCode: string;
  treatingDoctor: boolean;
  caseId: number;
  propertyTypes: PropertyType[];
  propertyTypesSelected: DropdownDictionary[];
  contactRoleName: string;

  public constructor(init?: Partial<ContactPerson>) {
    Object.assign(this, init);
    if (!init || !init.phoneNumbers) {
      this.phoneNumbers = new Array<PhoneNumber>();
      this.phoneNumbers.push(new PhoneNumber());
      this.phoneNumbers[0].type = PhoneNumberType.Fixed;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class ContactPersonAdapter implements Adapter<ContactPerson> {
  adapt(item: any): ContactPerson {
    return new ContactPerson({
      id: item.id,
      firstName: item.firstName,
      surname: item.surname,
      phoneNumbers: item.phoneNumbers,
      preferredChannelCode: item.preferredChannelCode,
      remarks: item.remarks,
      email: item.email,
      preferredLanguageCode: item.preferredLanguageCode,
      treatingDoctor: item.treatingDoctor,
      contactRoleName: item.contactRoleName,
      propertyTypes: item.propertyTypes
    });
  }
}
