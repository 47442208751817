import { getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocaleService } from '@secca/core/services/locale.service';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import { QuantityUnit, SavingTypedInField, ServiceTypeEnum, ServiceTypeItemEnum } from '@secca/shared/models/enums';
import { Refund } from '@secca/shared/models/refund';
import { RefundItem } from '@secca/shared/models/refund-Item';
import { RefundReduction } from '@secca/shared/models/refund-reduction';
import { ServiceItemType } from '@secca/shared/models/service-item-type';
import { ServiceOrder } from '@secca/shared/models/service-order/service-order';
import { Currency, DineroObject } from 'dinero.js';
import * as moment from 'moment-timezone';
import { IConfig } from 'ngx-mask';
import { ReductionsCalculator } from './reduction-calculator';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { CurrencyMaskInputMode } from 'ngx-currency';

@Component({
  selector: 'app-refund-item',
  templateUrl: './refund-item.component.html',
  styleUrls: ['./refund-item.component.scss']
})
export class RefundItemComponent implements OnInit {
  @Output() deleteRefundItem: EventEmitter<RefundItem> = new EventEmitter();
  @Output() updateRefundAmounts: EventEmitter<any> = new EventEmitter();
  @Input() showDelete: boolean;
  @Input() lockRefundOnApproved: boolean;
  @Input() refundCurrency: DineroObject;
  @Input() baseCurrenciesSupported: DropdownDictionary[];
  @Input() caseCreatedDate: Date;

  @Input() set serviceOrders(value: ServiceOrder[]) {
    this.allServiceOrderList = value;
    this.loadServiceOrderAndServiceItemLists();
  }


  get serviceOrders(): ServiceOrder[] {
    return this.allServiceOrderList;
  }

  @Input() set savingTypes(value: DropdownDictionary[]) {
    this.savingTypeList = value;
  }

  get savingTypes(): DropdownDictionary[] {
    return this.savingTypeList;
  }

  @Input() set serviceItems(value: ServiceItemType[]) {
    this.allServiceItemList = value;
    this.loadServiceOrderAndServiceItemLists();
  }

  get serviceItems(): ServiceItemType[] {
    return this.allServiceItemList;
  }

  @Input() set item(value: RefundItem) {
    this._item = value;
    this.loadServiceOrderAndServiceItemLists();
  }

  get item(): RefundItem {
    return this._item;
  }

  @Input()
  set caseId(value: String) {
    if (value) {
      this._caseId = value;
    }
  }

  get caseId(): String {
    return this._caseId;
  }

  private _caseId: String;
  serviceOrderId: string;
  selectableServiceOrders: ServiceOrder[];
  selectableServiceItemList: ServiceItemType[];
  allServiceItemList: ServiceItemType[];
  allServiceOrderList: ServiceOrder[];
  savingTypeList: DropdownDictionary[];
  private reductionsCalculator: ReductionsCalculator;
  refund: Refund;
  private _item: RefundItem;

  currencyMaskOptions = {
    prefix: '',
    thousands: '.',
    decimal: ',',
    precision: 2,
    allowNegative: true,
    inputMode: CurrencyMaskInputMode.NATURAL
  };
  quantityMaskOptions: any;
  quantityUnits: DropdownDictionary[];
  localCurrency: DineroObject;
  today: moment.Moment = moment.utc();

  constructor(
    public caseLockHelperService: CaseLockHelperService,
    public caseStateService: CaseStateService
  ) {
    this.reductionsCalculator = new ReductionsCalculator();
  }

  ngOnInit() {
    this.refund = new Refund();
    this.refund.items = [];
    this.quantityUnits = [];

    if (!this.localCurrency) {
      this.localCurrency = { amount: 0, currency: <Currency>this.item.refundCurrency, precision: 2 };
    }

    for (const unit in QuantityUnit) {
      if (isNaN(Number(unit))) {
        this.quantityUnits.push(new DropdownDictionary(QuantityUnit[unit], unit));
      }
    }
    this.quantityMaskOptions = {
      prefix: '',
      thousands: this.getThousandSeparator(),
      decimal: this.getDecimalMarker(),
      precision: 2,
      allowNegative: false,
      nullable: false,
      inputMode: CurrencyMaskInputMode.NATURAL
    };
  }

  // skal flyttes til service da den bliver brugt flere steder under invoices
  getThousandSeparator(): string {
    return getLocaleNumberSymbol(LocaleService.LOCALE, NumberSymbol.CurrencyGroup);
  }

  // skal flyttes til service da den bliver brugt flere steder under invoices
  getDecimalMarker(): IConfig['decimalMarker'] {
    const currencyDecimal = getLocaleNumberSymbol(LocaleService.LOCALE, NumberSymbol.CurrencyDecimal);
    if (currencyDecimal === ',') {
      return ',';
    } else if (currencyDecimal === '.') {
      return '.';
    } else {
      throw new RangeError('NumberSymbol.CurrencyDecimal is neither \',\' nor \'.\'!');
    }
  }

  focusElement(event: any) {
    event.target.select();
  }

  recalcReductionAmounts() {
    this.item.reductions = this.reductionsCalculator.recalcReductionAmounts(this.localCurrency, this.item);
    this.updateRefundAmounts.emit();
  }

  loadServiceOrderAndServiceItemLists() {
    if (this.allServiceOrderList == null || this.allServiceItemList == null || this._item == null) {
      return;
    }
    this.selectableServiceItemList = [];
    this.selectableServiceOrders = [];
    if (this._item.serviceOrderId !== null) {
      const serviceOrderItem = this.allServiceOrderList.find(soi => soi.serviceOrderId === this._item.serviceOrderId);
      if (this.allServiceItemList.find(s => s.sosServiceTypes.some(type => type === serviceOrderItem.type))) {
        this.selectableServiceItemList = this.allServiceItemList.filter(s => s.serviceItemType !== ServiceTypeItemEnum.RECOVERY &&
          s.sosServiceTypes.some(type => type === serviceOrderItem.type));
        this.selectableServiceOrders = this.allServiceOrderList;
      } else {
        const serviceItem = this.allServiceItemList.find(s => s.code === this._item.serviceItemCode);
        this.selectableServiceOrders = this.allServiceOrderList.filter(
          so => serviceItem && serviceItem.sosServiceTypes.some(type => type === so.type)
        );
      }
    } else {
      this.selectableServiceOrders = this.allServiceOrderList;
    }
  }

  serviceOrderChange() {
    if (this.item.serviceOrderId) {
      this.selectableServiceItemList = [];
      if (this.selectableServiceOrders) {
        const serviceOrderItem = this.selectableServiceOrders.find(soi => soi.serviceOrderId === this.item.serviceOrderId);
        if (serviceOrderItem && serviceOrderItem.type && this.allServiceItemList) {
          this.selectableServiceItemList = this.allServiceItemList.filter(s => s.serviceItemType !== ServiceTypeItemEnum.RECOVERY &&
            s.sosServiceTypes.some(type => type === serviceOrderItem.type) &&
            s.activateOn <= this.caseCreatedDate &&
            (!s.deactivateOn || s.deactivateOn > this.caseCreatedDate));
        }
      }

      if (this.item.serviceItemCode && this.selectableServiceItemList.find(s => s.sosServiceTypes.some(type => type === this.item.serviceItemCode))) {
        this.item.serviceItemCode = null;
      }
    }
  }

  addReduction(gross: boolean) {
    const newReductionItem = new RefundReduction();
    let maxLineNumber = 0;
    this.item.reductions.forEach(s => (maxLineNumber = s.lineNumber > maxLineNumber ? s.lineNumber : maxLineNumber));
    newReductionItem.lineNumber = maxLineNumber + 1;
    newReductionItem.amount = null;
    newReductionItem.percentage = null;
    newReductionItem.totalAmount = null;
    newReductionItem.exchangedAmount = 0;
    newReductionItem.typedInField = null;
    newReductionItem.reductionTypeCode = null;
    this.item.reductions.push(newReductionItem);
    this.recalcReductionAmounts();
  }

  deleteItem(item: RefundItem) {
    this.deleteRefundItem.emit(item);
  }

  removeReduction(lineNumber: number) {
    this.item.reductions = this.item.reductions.filter(s => s.lineNumber !== lineNumber);
    this.recalcReductionAmounts();
  }

  reductionAmountChanged(refundReduction: RefundReduction) {
    refundReduction.typedInField = SavingTypedInField.AMOUNT;
    this.recalcReductionAmounts();
    this.updateRefundItemCurrency();
  }

  savingPercentageChanged(refundReduction: RefundReduction) {
    refundReduction.typedInField = SavingTypedInField.PERCENTAGE;
    this.recalcReductionAmounts();
    this.updateRefundItemCurrency();
  }

  getHeightOfDeletebutton() {
    return this.item.reductions.length + 2;
  }

  updateExpenseDate(event: moment.Moment, refundItem: RefundItem) {
    this.recalcReductionAmounts();
  }

  getReductionAmountTotal(): number {
    return this.reductionsCalculator.getReductionAmountTotal(this.item);
  }

  getReductionPctTotal(): number {
    return this.reductionsCalculator.getReductionPctTotal(this.item);
  }

  getExchangedNetAmount(): number {
    return this.reductionsCalculator.getExchangedNetAmount(this.item);
  }

  updateRefundItemCurrency() {
    this.localCurrency.currency = <Currency>this.item.refundCurrency;
    this.updateRefundAmounts.emit();
  }


}
