import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MomentHelperService, SECCA_TIMEZONES, UTCOffset } from '@secca/core/services/moment-helper.service';
import { ValidationTypeEnum } from '@secca/shared/models/enums';
import { SubTravelInfo } from '../../models/subTravelInfo';
import { SubTravelInfoResourceConfig } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subTravelInfoResourceConfig';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import { StakeholderClassTypeEnum } from '@secca/case/components/case-plans/case-plans/add-service-order/models/enums';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { SubStakeholders } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholders';
import { ICCFlightLegRelevantForCase } from '../../models/icc-flightLegsRelevantForCase';
import { cloneDeep } from 'lodash-es';
import { CaseStateService } from '@secca/core/state-services/case-state.service';

@Component({
  selector: 'app-sub-travel-info',
  templateUrl: './sub-travel-info.component.html',
  styleUrls: ['./sub-travel-info.component.scss']
})
export class SubTravelInfoComponent implements OnInit {
  @Input() disableDepartureLocation: boolean;
  @Input() travelInfo: SubTravelInfo;
  @Input() hideDeleteButton: boolean;
  @Input() hideCabinClass: boolean;
  @Input() hideFlightInRefs: boolean;
  @Input() hideRemarks = true ;
  @Input() departureDateRequired: boolean;
  @Input() departureFromRequired: boolean;
  @Input() arrivalDateRequired: boolean;
  @Input() arrivalAtRequired: boolean;
  @Input() cabinClassRequired: boolean;
  @Input() flightNoRequired: boolean;
  @Input() agencyReferenceRequired: boolean;
  @Input() hideTicketDetails: boolean;
  @Input() hideAirlineReference: boolean;
  @Input() hideTicketNo: boolean;
  @Input() readOnlyMode: boolean;
  @Input() remarksReadOnlyMode: boolean;
  @Input() arrivalDateWhite: boolean = false;
  @Input() resourceConfig: SubTravelInfoResourceConfig = new SubTravelInfoResourceConfig();
  @Input() subStakeholders: SubStakeholders;
  @Input() iccFlightLegRelevantForCaseList: ICCFlightLegRelevantForCase[] = [];
  @Input() relevantForCaseReadOnly: boolean;

  @Input() set minDate(min: moment.Moment){
    if (min) {
      this.minMoment = min.clone().local(true);
    }
  }

  @Output() delete: EventEmitter<SubTravelInfo> = new EventEmitter();
  @Input() showRelevantForCase: boolean;
  @Input() relevantForCaseId: string;

  cabinClassDropdown: DropdownDictionary[];s
  departureDateStartAt: moment.Moment;
  arrivalDateStartAt: moment.Moment;
  utcOffset = 0;
  departureTimeZone: SECCA_TIMEZONES;
  arrivalDateTimeZone: SECCA_TIMEZONES;
  minMoment: moment.Moment;
  invalidDateRange = false;
  existingDepartureLocation: Location;
  existingArrivalLocation: Location;

  get departureDateInvalid() {
    return !this.travelInfo.departureDate;
  }

  get arrivalDateInvalid() {
    return !this.travelInfo.arrivalDate;
  }

  get ValidationTypeEnum() {
    return ValidationTypeEnum;
  }

  constructor(private translate: TranslateService, public caseStateService: CaseStateService) { }

  ngOnInit() {
    this.calcDepartureTimeZone();
    this.calcArrivalTimeZone();
    this.initCabinClassDropdown();
    this.checkDateRange();
    this.setCabinClass();
    this.existingDepartureLocation = cloneDeep(this.travelInfo.departureFrom);
    this.existingArrivalLocation = cloneDeep(this.travelInfo.arrivalAt);
  }

  departureDateChange(date) {
    this.travelInfo.departureDate = date;
    this.checkDateRange();
  }

  arrivalDateChange(date) {
    this.travelInfo.arrivalDate = date;
    this.checkDateRange();
  }

  onDepartureFromUpdate() {
    this.calcDepartureTimeZone();
    if ((Object.entries(this.travelInfo.departureFrom).toString() !== Object.entries(this.existingDepartureLocation).toString()) && this.travelInfo.relevantForCaseIds.length !== 0) {
      this.existingDepartureLocation = cloneDeep(this.travelInfo.departureFrom);
      // Resetting travelInfo, Forcing new entity to be created on backend. Prevents duplicate key exception
      this.travelInfo.id = null;
      this.travelInfo.relevantForCaseIds = [];
    }
  }

  onArrivalAtUpdate() {
    this.calcArrivalTimeZone();
    if ((Object.entries(this.travelInfo.arrivalAt).toString() !== Object.entries(this.existingArrivalLocation).toString()) && this.travelInfo.relevantForCaseIds.length !== 0) {
      this.existingArrivalLocation = cloneDeep(this.travelInfo.arrivalAt);
      // Resetting travelInfo, Forcing new entity to be created on backend. Prevents duplicate key exception
      this.travelInfo.id = null;
      this.travelInfo.relevantForCaseIds = [];
    }
  }

  deleteClicked() {
    this.delete.emit(this.travelInfo);
  }

  getRelevantForCase(caseId: string): boolean {
    var relevant = this.travelInfo.relevantForCaseIds?.find(leg => leg.linkedCaseId === caseId);
    return relevant ? this.travelInfo.relevantForCaseIds.find(leg => leg.linkedCaseId === caseId).relevantForCase : undefined;
  }

  setRelevantForCase(caseId: string, relevant: boolean, flightLegId: string) {
    if (!this.relevantForCaseReadOnly && !this.caseStateService.isCaseDisabled()) {
      var _relevant =  this.travelInfo.relevantForCaseIds.find(flightLeg => flightLeg.linkedCaseId === caseId);
      if(_relevant) {
        _relevant.relevantForCase = relevant;
      } else {
        this.travelInfo.relevantForCaseIds.push({linkedCaseId: this.relevantForCaseId, relevantForCase: relevant, flightLegId})
      }
    }
  }

  private calcDepartureTimeZone() {
      this.departureTimeZone =  MomentHelperService.resolveTimeZone(this.travelInfo.departureFrom.timeZoneId);
  }

  private calcArrivalTimeZone() {
    this.arrivalDateTimeZone =  MomentHelperService.resolveTimeZone(this.travelInfo.arrivalAt.timeZoneId);
  }

  private checkDateRange(): void {
    if ( !this.travelInfo.departureDate || !this.travelInfo.arrivalDate ) {
      this.invalidDateRange = false;
      return;
    }
    const fromUtc: moment.Moment = this.travelInfo.departureDate.clone().tz(MomentHelperService.resolveTimeZone('utc'));
    const toUtc: moment.Moment = this.travelInfo.arrivalDate.clone().tz(MomentHelperService.resolveTimeZone('utc'));

    this.invalidDateRange = fromUtc.isAfter(toUtc);
  }

  initCabinClassDropdown() {
    this.cabinClassDropdown = [
      {
        id: StakeholderClassTypeEnum.ECONOMY,
        name: this.translate.instant(`sub-stakeholder-class-type-${StakeholderClassTypeEnum.ECONOMY}`),
      },
      {
        id: StakeholderClassTypeEnum.ECONOMY_FLEX_PREMIUM_ECONOMY,
        name: this.translate.instant(`sub-stakeholder-class-type-${StakeholderClassTypeEnum.ECONOMY_FLEX_PREMIUM_ECONOMY}`),
      },
      {
        id: StakeholderClassTypeEnum.BUSINESS_CLASS,
        name: this.translate.instant(`sub-stakeholder-class-type-${StakeholderClassTypeEnum.BUSINESS_CLASS}`),
      },
      {
        id: StakeholderClassTypeEnum.FIRST_CLASS,
        name: this.translate.instant(`sub-stakeholder-class-type-${StakeholderClassTypeEnum.FIRST_CLASS}`),
      }
    ];
  }
  setCabinClass(): void {
   this.travelInfo.cabinClass = StakeholderClassTypeEnum.ECONOMY;
  }
}
