
import { ServiceTypeEnum } from "@secca/shared/models/enums";
import { AmbulanceFlightServiceOrderExtension } from "@secca/shared/models/service-order/ambulance-flight-service-order-extension";
import { ServiceOrder } from "@secca/shared/models/service-order/service-order";
import { cloneDeep } from 'lodash-es';
import { HelperService } from "./helper.service";

export class AmbulanceFlightCloner {
  private extension: AmbulanceFlightServiceOrderExtension;
  constructor(private ambulanceFlight: ServiceOrder, private helperService: HelperService) {
    this.extension = ambulanceFlight.extension as AmbulanceFlightServiceOrderExtension;
  }

  public cloneForGroundTransport(): ServiceOrder[]{
    const clonedAmbulanceFlight = [];

    const clonedTransportTo = this.cloneGroundTransportTo();
    if (clonedTransportTo) {
      clonedAmbulanceFlight.push(clonedTransportTo);
    }
    const clonedTransportFrom = this.cloneGroundTransportFrom();
    if (clonedTransportFrom) {
      clonedAmbulanceFlight.push(clonedTransportFrom);
    }

    if ( this.ambulanceFlight.serviceId == null ) {
      this.ambulanceFlight.serviceId = this.helperService.getRandomGuid();
    }

    if (clonedTransportTo) {
      clonedTransportTo.parentServiceId = this.ambulanceFlight.serviceId;
      clonedTransportTo.serviceId = this.helperService.getRandomGuid();
    }

    if (clonedTransportFrom) {
      clonedTransportFrom.parentServiceId = this.ambulanceFlight.serviceId;
      clonedTransportFrom.serviceId = this.helperService.getRandomGuid();
    }


    this.updateDisplayDates();

    return clonedAmbulanceFlight;
  }

  cloneGroundTransportTo(): ServiceOrder {
    let clonedAmbFlight;
    if (!this.extension.getGroundTransportToNotIncluded(this.ambulanceFlight.status)) {
      clonedAmbFlight = cloneDeep(this.ambulanceFlight);
      clonedAmbFlight.type = ServiceTypeEnum.GROUND_AMBULANCE;

      const flightLeg = 0;

      const expectedStartDate = clonedAmbFlight.extension.flight[flightLeg].departureDate;
      const confirmStartDate = clonedAmbFlight.extension.confirmationFlight[flightLeg].departureDate;

      clonedAmbFlight.extension.flight = clonedAmbFlight.extension.groundTransportToFlight;
      clonedAmbFlight.extension.confirmationFlight = clonedAmbFlight.extension.confirmationGroundTransportToFlight;

      clonedAmbFlight.extension.flightStakeholders = clonedAmbFlight.extension.groundTransportToFlightStakeholders;
      clonedAmbFlight.extension.confirmationFlightStakeholders = clonedAmbFlight.extension.confirmationGroundTransportToFlightStakeholders;

      clonedAmbFlight.extension.flight[flightLeg].departureDisplayDate = clonedAmbFlight.extension.flight[flightLeg].departureDate;
      clonedAmbFlight.extension.confirmationFlight[flightLeg].departureDisplayDate = clonedAmbFlight.extension.confirmationFlight[flightLeg].departureDate;

      clonedAmbFlight.extension.flight[flightLeg].arrivalDisplayDate = clonedAmbFlight.extension.flight[flightLeg].arrivalDate;
      clonedAmbFlight.extension.confirmationFlight[flightLeg].arrivalDisplayDate = clonedAmbFlight.extension.confirmationFlight[flightLeg].arrivalDate;

      if ( expectedStartDate && !clonedAmbFlight.extension.flight[flightLeg].departureDate ) {
        clonedAmbFlight.extension.flight[flightLeg].departureDate = expectedStartDate.subtract(60, 'seconds');
      }

      if ( confirmStartDate && !clonedAmbFlight.extension.confirmationFlight[flightLeg].departureDate ) {
        clonedAmbFlight.extension.confirmationFlight[flightLeg].departureDate = confirmStartDate.subtract(60, 'seconds');
      }
    }

    return clonedAmbFlight;
  }

  cloneGroundTransportFrom(): ServiceOrder{
    let clonedAmbFlight;
    if (!this.extension.getGroundTransportFromNotIncluded(this.ambulanceFlight.status)) {
      clonedAmbFlight = cloneDeep(this.ambulanceFlight);
      clonedAmbFlight.type = ServiceTypeEnum.GROUND_AMBULANCE;

      const flightLeg = 0;

      const expectedStartDate = clonedAmbFlight.extension.flight[flightLeg].arrivalDate || clonedAmbFlight.extension.flight[flightLeg].departureDate;
      const confirmStartDate = clonedAmbFlight.extension.confirmationFlight[flightLeg].arrivalDate || clonedAmbFlight.extension.confirmationFlight[flightLeg].departureDate;

      clonedAmbFlight.extension.flight = clonedAmbFlight.extension.groundTransportFromFlight;
      clonedAmbFlight.extension.confirmationFlight = clonedAmbFlight.extension.confirmationGroundTransportFromFlight;

      clonedAmbFlight.extension.flightStakeholders = clonedAmbFlight.extension.groundTransportFromFlightStakeholders;
      clonedAmbFlight.extension.confirmationFlightStakeholders = clonedAmbFlight.extension.confirmationGroundTransportFromFlightStakeholders;

      clonedAmbFlight.extension.flight[flightLeg].departureDisplayDate = clonedAmbFlight.extension.flight[flightLeg].departureDate;
      clonedAmbFlight.extension.confirmationFlight[flightLeg].departureDisplayDate = clonedAmbFlight.extension.confirmationFlight[flightLeg].departureDate;

      clonedAmbFlight.extension.flight[flightLeg].arrivalDisplayDate = clonedAmbFlight.extension.flight[flightLeg].arrivalDate;
      clonedAmbFlight.extension.confirmationFlight[flightLeg].arrivalDisplayDate = clonedAmbFlight.extension.confirmationFlight[flightLeg].arrivalDate;

      if ( expectedStartDate && !clonedAmbFlight.extension.flight[flightLeg].departureDate ) {
        clonedAmbFlight.extension.flight[flightLeg].departureDate = expectedStartDate.add(60, 'seconds');
      }

      if ( confirmStartDate && !clonedAmbFlight.extension.confirmationFlight[flightLeg].departureDate ) {
        clonedAmbFlight.extension.confirmationFlight[flightLeg].departureDate = confirmStartDate.add(60, 'seconds');
      }
    }

    return clonedAmbFlight;
  }

  private updateDisplayDates(): void {
    this.extension.flight?.forEach(flight => {
      flight.departureDisplayDate = flight.departureDate;
      flight.arrivalDisplayDate = flight.arrivalDate;
    });

    this.extension.confirmationFlight?.forEach(flight => {
      flight.departureDisplayDate = flight.departureDate;
      flight.arrivalDisplayDate = flight.arrivalDate;
    });
  }

}
