import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { CaseLockHttpService } from './case-lock-http.service';
import { IncidentDtoWrapper } from '../../shared/models/caseIncident';
import { QueueService } from './queue.service';
import { SettingsService } from './settings.service';
import { CaseValidationService } from '@secca/core/services/case-validation.service';
import { GlobalErrorHandler } from './error-handler.service';


@Injectable({
  providedIn: 'root'
})
export class UpdateIncidentQueueService extends BaseService {

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private caseLockHttpService: CaseLockHttpService,
    private queueService: QueueService,
    private incidentDtoWrapper: IncidentDtoWrapper,
    private caseValidationService: CaseValidationService,
    private globalErrorHandler: GlobalErrorHandler
  ) {
    super(settingsService);
  }

  actionSchedule(caseId) {
    if (!this.queueService.anyIncidentQueuedOperationsRunning()) {
      return this.runActions(caseId);
    }
  }

  runActions(caseId) {
    this.queueService.incidentQueuedOperationsRunning(true);

    const nextIncident = this.queueService.popIncident();
    if (nextIncident == null) {
      return this.queueService.incidentQueuedOperationsRunning(false);
    }

    return this.caseLockHttpService.post<any>(this.baseURL + 'incidents/' + caseId ,
    this.incidentDtoWrapper.wrap(nextIncident), {headers: this.jsonHeaders})
      .subscribe(() => {
        this.caseValidationService.sendVerifyCaseNumberAssigned(caseId);
        this.runActions(caseId);
      }, err => {
        this.globalErrorHandler.handleError(err);
        this.runActions(caseId);
      });
  }
}
