import { Injectable } from '@angular/core';
import { Adapter } from './../interfaces/adapter';
import { DropdownDictionary } from './dropdownDictionary';

export class Debtor extends DropdownDictionary {
  erpClient = { id: null, name: null };
  public constructor(init?: Partial<Debtor>) {
    super(init.id, init.name);
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
export class DebtorAdapter implements Adapter<Debtor> {
  adapt(item: any): Debtor {
    const debtor = new Debtor({
      id: item.number,
      name: item.number + ' ' + item.name,
      erpClient: item.erpClient,
    });
    return debtor;
  }
}
