import { TaskFilterRequest } from './task-filter-request.model';
import * as moment from 'moment';

export class RefundTaskFilterRequest extends TaskFilterRequest {
    dateRange: moment.Moment[];
    receiver: number;
    caseNumber: string;
    serviceTypes: number[];
    refundID: number;
    refundStatus: string[];
    causeLevel1Code: String[];
    incidentEvents: String[];
    winterSport: boolean;


    public constructor(init?: Partial<RefundTaskFilterRequest>) {
        super();
        Object.assign(this, init);
      }

}