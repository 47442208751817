import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';

export class CASE_TYPE_CODES {
  static readonly MEDICAL_PRE_ASSESSMENT = 'CT06';
  static readonly ASSISTANCE_ONLY = 'CT01';
  static readonly ASSISTANCE_AND_CLAIMS = 'CT02';
  static readonly SANCTION_CASE = 'CT12';
  static readonly SANCTION_CASE_FULL = 'CT13';
  static readonly AWAITING_CASE_TYPE = 'CT99';
  static readonly INTERNAL_COORDINATION = 'CT11';
  static readonly MAJOR_INCIDENT = 'CT15';
}

export enum CaseTypeCodes {
  AWAITING_CASE_TYPE = 'CT99',
  ASSISTANCE_ONLY = 'CT01',
  ASSISTANCE_AND_CLAIMS = 'CT02',
  CLAIMS = 'CT03',
  BATCH_BILLING = 'CT04',
  COST_CONTROL_PER_REQUEST = 'CT05',
  MEDICAL_PRE_ASSESSMENT = 'CT06',
  CRISIS_RISK_AND_SECURITY = 'CT07',
  INVESTIGATION = 'CT08',
  TRANSLATION = 'CT09',
  INTERNAL_COORDINATION = 'CT11',
  MAJOR_INCIDENT = 'CT15',
}

export function isCoordinationCase(caseType: CASE_TYPE_CODES): boolean {
  return (caseType === CASE_TYPE_CODES.INTERNAL_COORDINATION || caseType === CASE_TYPE_CODES.MAJOR_INCIDENT);
}

export class CaseType {
  id: number;
  name: string;
  code: string;
  caseTypeResetDisabled: boolean;
  uiSortIndex: number;
  allowableCaseTypes: CaseType[];
  activateOn: Date;
  deactivateOn: Date;

  public constructor(init?: Partial<CaseType>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
export class CaseTypeAdapter implements Adapter<CaseType> {
  adapt(item: any): CaseType {
    if (!item) {
      return new CaseType();
    }
    const deactivateOn = item.deactivateOn ? new Date(item.deactivateOn) : null;
    const allowableCaseTypes = [];
    if (item.allowableCaseTypes) {
      item.allowableCaseTypes.forEach(type=>{
        allowableCaseTypes.push((new CaseTypeAdapter).adapt(type));
      });
    }

    let caseTypeName = item.caseTypeName;
    if (caseTypeName === undefined) {
      caseTypeName = item.caseTypeTranslated;
    }

    return new CaseType({
      id: item.id,
      name: caseTypeName,
      code: item.caseTypeCode,
      caseTypeResetDisabled: item.caseTypeResetDisabled,
      uiSortIndex: item.uiSortIndex,
      allowableCaseTypes: allowableCaseTypes,
      activateOn: new Date(item.activateOn),
      deactivateOn,
    });
  }
}
