import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { PhoneNumberType, ShortcutEnum, StakeholderTypeEnum } from '@secca/shared/models/enums';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import { PermissionService } from '@secca/core/services/permission.service';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { IncomingCasesTaskViewModel } from '@secca/board/components/board-task/models/incoming-cases-task-view-model';
import { SearchCase } from '@secca/shared/models/searchCase';
import { PhoneNumber } from '@secca/shared/models/phoneNumber';
import { DialogViewerServiceInterface } from '@secca/core/services/dialog-viewer-service-interface';
import { DialogStateService } from '@secca/core/state-services/dialog-state.service';
import { DIALOG_VIEWER_TOKEN } from '@secca/core/services/token';
import { TaskWebPropertiesService } from '@secca/core/services/task-web-properties.service';
import { TaskViewModel } from '../../../models/task-view.model';
import { CauseItemType } from '@secca/shared/models/cause-item-type';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { ShortcutService } from '@secca/core/services/shortcut.service';
import { ChannelType } from '@secca/shared/enums/channelType';
import * as moment from 'moment';
import { Diagnosis } from '@secca/shared/models/dignosis';
import { Channel } from '@secca/shared/models/channel';

@Component({
  selector: 'app-task-web-modal-header',
  templateUrl: './task-web-modal-header.component.html',
  styleUrls: ['./task-web-modal-header.component.scss']
})
@AutoUnsubscribe
export class TaskWebModalHeaderComponent implements OnChanges {
  @Input() taskViewModel: TaskViewModel;
  @Input() taskDataViewModel: IncomingCasesTaskViewModel;
  @Input() hideSearchCreateButton: boolean = false;
  @Input() causes: Map<string, CauseItemType[]>;
  @Input() diagnoses: Diagnosis[];
  @Input() channels: Channel[];
  @Input() createCaseOnly = false;

  @Output() closed = new EventEmitter<number>();
  @Output() createCaseButtonPressed = new EventEmitter<void>();

  endUser: any;
  cause: string;
  showSpinner = false;
  isCaseBasicLockTaken: boolean;

  private shortcutSubscriptions: Subscription[] = [];

  constructor(
    private dialogStateService: DialogStateService,
    private shortcutService: ShortcutService,
    public taskWebPropertiesService: TaskWebPropertiesService,
    public caseLockHelperService: CaseLockHelperService,
    public permissionService: PermissionService,
    public caseStateService: CaseStateService,
    @Inject(DIALOG_VIEWER_TOKEN) private dialogViewerService: DialogViewerServiceInterface
  ) {
    this.isCaseBasicLockTaken = this.caseLockHelperService.isCaseBasicLockTaken;
    if(!this.isCaseBasicLockTaken) {
      this.shortcutSubscriptions.push(
        this.shortcutService.addShortcut({ keys: ShortcutEnum.BoardNavigateToTheSearchOrCreateCase }).subscribe(a => {
          this.goToSearchCase();
        }),
      );
    }
  }

  ngOnChanges(): void {
    this.getRelevantInformation();
  }

  goToSearchCase() {
    let searchCase: SearchCase;

    switch (this.channelType) {
      case ChannelType.SUPPLIER_PORTAL:
      case ChannelType.UNKNOWN_SUPPLIER:
      case ChannelType.BATCH_BILLING:
          searchCase = new SearchCase({
          customer: null,
          nationalId: this.endUser.nationalId,
          nationalIdCountryCode: this.endUser.nationalIdCountry,
          stakeholderSearch: StakeholderTypeEnum.endUser,
          channel: this.taskViewModel?.channelType
        });
        break;
      default:
        searchCase = new SearchCase({
          firstName: this.endUser.firstName,
          surname: this.endUser.surname,
          nationalId: this.endUser.nationalId,
          nationalIdCountryCode: this.endUser.nationalIdCountry,
          customer: this.taskViewModel.customerProfileId,
          stakeholderSearch: StakeholderTypeEnum.endUser,
          dateOfBirth: !!this.endUser.dateOfBirth ? moment.utc(this.endUser.dateOfBirth.localDate, 'YYYY-MM-DD') : null,
          channel: this.taskViewModel?.channelType
        });
        break;
    }

    const dialogRef = this.dialogViewerService.openClaimsSearchCaseDialog(searchCase, this.taskViewModel, () => this.closed.emit());
    if ( dialogRef ) {
      setTimeout(() => {
        this.dialogStateService.sendToFront(dialogRef.id);
      }, 0);
    }
  }

  createCase(): void {
    this.createCaseButtonPressed.emit();
  }

  getChannelTypeName(channelTypeEnumValue: string) {
    return this.channels?.find(c => c.channelTypeEnumValue === channelTypeEnumValue)?.channelTypeName;
  }

  private getRelevantInformation() {
    this.endUser = this.taskDataViewModel?.data?.caseStakeholders?.find(stakeHolder => stakeHolder.stakeholderType === StakeholderTypeEnum.endUser);
    if ( !this.endUser ) {
      const reporter = this.taskDataViewModel?.data?.caseStakeholders?.find(stakeHolder => stakeHolder.stakeholderType === StakeholderTypeEnum.reporter);
      if ( reporter && reporter.properties.includes('END_USER') ) {
        this.endUser = reporter;
      }
    }

    if ( this.taskDataViewModel?.data?.incident?.incidentCauseCode ) {
      this.cause = this.taskWebPropertiesService.getPropertyValue(this.taskDataViewModel?.data?.incident, 'incidentCauseCode', null, null, this.causes, null, null);
    }
    else if ( this.taskDataViewModel?.data?.incident?.incidentCauseLvl1Code ) {
      const causeLevel1 = this.taskWebPropertiesService.getPropertyValue(this.taskDataViewModel?.data?.incident, 'incidentCauseLvl1Code', null, null, this.causes, null, null);
      const causeLevel2 = this.taskWebPropertiesService.getPropertyValue(this.taskDataViewModel?.data?.incident, 'incidentCauseLvl2Code', null, null, this.causes, null, null);

      this.cause = [causeLevel1, causeLevel2].filter(cause => !!cause).join(' / ');
    }
  }

  get hasValidCustomerTeamRelation(): boolean {
    if (this.taskDataViewModel?.customerProfileId) {
      return !!this.taskDataViewModel.customerProfileTeamName;
    }
    return true;
  }

  get hasValidCustomerContractRelation(): boolean {
    if (this.taskDataViewModel?.customerProfileId) {
      return !!this.taskDataViewModel.customerProfileInfoId;
    }
    return true;
  }

  get channelType(): ChannelType {
    return ChannelType[this.taskViewModel?.channelType];
  }

  private getEndUserPhoneNumbers(): PhoneNumber[] {
    const stakeholder: any = this.endUser;
    if ( stakeholder?.phoneNumbers?.phoneNumbers ) {
      return stakeholder.phoneNumbers.phoneNumbers;
    }
    else if ( stakeholder?.phoneNumbers ) {
      return stakeholder.phoneNumbers;
    }
    else {
      return [];
    }
  }
}
