import {Injectable} from '@angular/core';
import {Adapter} from '../interfaces/adapter';

export class SupplierInvoiceAutoApprovalDto {
  id: number;
  publishDate: Date;
  acknowledgeDate: Date;
  approvalScore: number;
  autoApprovalStatus: string;
  scoreMap: string;
  isCorrect: boolean;
  incorrectComment: string;

  public constructor(init?: Partial<SupplierInvoiceAutoApprovalDto>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
export class SupplierInvoiceAutoApprovalDtoAdapter implements Adapter<SupplierInvoiceAutoApprovalDto> {
  adapt(item: any): SupplierInvoiceAutoApprovalDto {
    const supplierInvoiceAutoApprovalDto = new SupplierInvoiceAutoApprovalDto({
      id: item.id,
      publishDate: item.publishDate === null ? null : new Date(item.publishDate),
      acknowledgeDate: item.acknowledgeDate === null ? null : new Date(item.acknowledgeDate),
      approvalScore: item.approvalScore,
      autoApprovalStatus: item.autoApprovalStatus,
      scoreMap: item.scoreMap,
      isCorrect: item.isCorrect,
      incorrectComment: item.incorrectComment
    });

    return supplierInvoiceAutoApprovalDto;
  }
}
