import {HandlingAreaStatusEnum, ValidationTypeEnum} from '@secca/shared/models/enums';
import {AutoCompleteTypeEnum} from 'src/app/shared/components/drop-down-countries-auto-complete/auto-complete-type-enum';


import {CaseService} from 'src/app/core/services/case.service';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {PermissionEnum} from 'src/app/shared/models/enums';
import {AutoUnsubscribe} from '@secca/shared/decorators/auto-unsubscribe';
import {DialogHelperUtilService} from '@secca/core/services/dialog-helper-util.service';
import {CaseLinkedCasesService} from '@secca/core/services/case-linked-cases.service';
import {LinkedCasesList} from '@secca/shared/models/linkedCasesList';
import {combineLatest, Subscription} from 'rxjs';

@Component({
  selector: 'app-case-summary-note-linked-case-modal',
  templateUrl: './case-summary-note-linked-case-modal.component.html',
  styleUrls: ['./case-summary-note-linked-case-modal.component.scss'],
})
@AutoUnsubscribe
export class CaseSummaryNoteLinkedCaseModalComponent implements OnInit {

  @Input() private caseId: string;
  @Input() private note: any;

  showSpinner = false;

  linkedCases: LinkedCasesList[];
  copiedCases: number[];

  anySelected = false;

  @Output()
  closed: EventEmitter<void> = new EventEmitter();

  $subscription: Subscription;

  isCheckAll = false;

  get HandlingAreaStatusEnum() {
    return HandlingAreaStatusEnum;
  }
  get AutoCompleteTypeEnum() {
    return AutoCompleteTypeEnum;
  }

  constructor(
    private modalService: NgbModal,
    private dialogHelperUtilService: DialogHelperUtilService,
    private caseLinkedService: CaseLinkedCasesService,
    private caseService: CaseService
    ) {
  }

  ngOnInit() {
    this.showSpinner = true;
    this.$subscription = combineLatest([
      this.caseLinkedService.getLinkedCasesOnCase(this.caseId),
      this.caseService.getCopiedCasesByNoteId(this.note.id)
    ]).subscribe(res => {
      this.linkedCases = res[0] as [LinkedCasesList];
      this.copiedCases = res[1];
      this.showSpinner = false;
    });
  }

  closeModal(): void {
    this.closed.emit();
    this.dialogHelperUtilService.closeModal();
  }

  alreadyCopiedToCase(caseId: number): boolean {
    return this.copiedCases.includes(caseId);
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  get ValidationTypeEnum() {
    return ValidationTypeEnum;
  }

  selectAll() {
    this.isCheckAll = !this.isCheckAll;
    this.linkedCases.filter(lc => !this.alreadyCopiedToCase(lc.caseId) && lc.caseId !== +this.caseId).
      forEach(lc => Object.assign(lc, {isSelected: this.isCheckAll}));
    this.anySelected = this.isCheckAll;
  }

  checkCase(linkedCase: LinkedCasesList, event: any) {
    Object.assign(linkedCase, {isSelected: event.target.checked});
    this.anySelected = this.linkedCases.filter((lc: any) => lc.isSelected).length !== 0;
    this.isCheckAll = this.linkedCases.filter((lc: any) => !lc.isSelected && !this.alreadyCopiedToCase(lc.caseId)).length === 1;
  }

  copyNotes() {
    this.showSpinner = true;
    this.caseService.copyNoteToCases(this.note, this.linkedCases.filter((lc: any) => lc.isSelected).
    map((lc: LinkedCasesList) => lc.caseId)).
    subscribe(res => {
      this.showSpinner = false;
      this.closeModal();
    });
  }

  isCaseSelected(linkedCase: any) {
    return linkedCase.isSelected;
  }
}

