import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AbstractGlobalBoardTableShortcutsComponent } from '@secca/shared/components/abstract-global-board-table-shortcuts.component';
import { CaseService } from 'src/app/core/services/case.service';
import { TableSort } from '@secca/shared/models/table-sort';
import { ShortcutEnum, SortOrder } from '@secca/shared/models/enums';
import { BoardCase } from '../models/board-case.model';
import { UserDto } from '@secca/shared/models/userDto';
import { DictionaryService } from '@secca/core/services/dictionary.service';
import { ScreeningStates } from '@secca/shared/enums/screening-states';
import { SettingsService } from '@secca/core/services/settings.service';
import { AutoUnsubscribe } from 'src/app/shared/decorators/auto-unsubscribe';
import { BoardNavigationStateService } from '../../board-task/services/board-navigation-state.service';

@Component({
  selector: 'app-board-cases-table',
  templateUrl: './board-cases-table.component.html',
  styleUrls: ['./board-cases-table.component.scss'],
})
@AutoUnsubscribe
export class BoardCasesTableComponent extends AbstractGlobalBoardTableShortcutsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() set boardCases(data: BoardCase[]) {
    this._boardCases = data;
    this.numberOfRows = data?.length;
    setTimeout(() => {
      this.selectedRowIndex = parseInt(this.boardNavigationStateService.getState('board-case-filter-request-cases-selected-row-index'), 10);
      this.scrollPosition = this.boardNavigationStateService.getState('board-case-filter-request-cases-scroll-position') ? parseInt(this.boardNavigationStateService.getState('board-case-filter-request-cases-scroll-position'), 10) : 0;
      this.navigateToTable();
    }, 50);
  }
  get boardCases(): BoardCase[] {
    return this._boardCases;
  }

  @Input() caseSelect: string;
  @Input() casesSorting: TableSort;
  @Output() sortingAction = new EventEmitter<TableSort>();

  filteringText: string;
  caseStatus: string;
  selectedTeam: string;
  $loggedUserSubscr: Subscription;
  sortedOn: any = { createdOn: true };
  sortOrder: any = { };
  loggedInUser: UserDto;
  NO = ScreeningStates.NO;
  YES = ScreeningStates.YES;
  TBD = ScreeningStates.TO_BE_DETERMINED;
  scrollPosition = 0;

  private _boardCases: BoardCase[];

  constructor(private router: Router,
              private caseService: CaseService,
              private dictionaryService: DictionaryService,
              public settingsService: SettingsService,
              private boardNavigationStateService: BoardNavigationStateService) {
    super('caseTabel');
    this.$loggedUserSubscr = dictionaryService.getLoggedInUser()
      .subscribe(user => this.loggedInUser = user
    );
    this.shortcutSubscriptions.push(
      this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateTableRowAltEnter }).subscribe(a => {
        if (typeof this.selectedRowIndex === 'number') {
          this.redirectToCase(this.boardCases[this.selectedRowIndex].id);
        }
      }),
    );
  }

  ngOnInit() {
    if (this.casesSorting != null) {
      this.sortedOn = {};
      this.sortedOn[this.casesSorting.sortBy] = true;
      this.sortOrder[this.casesSorting.sortBy] = this.casesSorting.sortOrder;
    }
    const scrollPositionJson = this.boardNavigationStateService.getState('board-case-filter-request-cases-scroll-position');
    if (!!scrollPositionJson) {
      this.scrollPosition = JSON.parse(scrollPositionJson);
    }
  }

  ngOnChanges() {
    setTimeout(() => this.setScrollPosition(), 500);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.boardNavigationStateService.saveState('board-case-filter-request-cases-scroll-position', JSON.stringify(this.scrollPosition));
    this.boardNavigationStateService.saveState('board-case-filter-request-cases-selected-row-index', JSON.stringify(this.selectedRowIndex));
  }

  setScrollPosition() {
    if (this.scrollPosition !== null && document.getElementById('caseTabel')) {
      document.getElementById('caseTabel').scrollTop = this.scrollPosition;
    }
  }

  onCaseTabelScroll() {
    this.scrollPosition = document.getElementById('caseTabel').scrollTop;
  }

  sortedBy(column: string): string {
    return this.sortedOn[column] ? 'sorted-by' : '';
  }

  caseHandlerTeamNameShortName(assignInitials: string): string {
    return this.caseService.teamNameShortName(assignInitials);
  }

  sortBy(column: string) {
    this.sortedOn = {};
    this.sortedOn[column] = true;
    if (this.sortOrder[column] == null) {
      this.sortOrder[column] = SortOrder.asc;
    } else {
      if (this.sortOrder[column] === SortOrder.asc) {
        this.sortOrder[column] = SortOrder.desc;
      } else {
        this.sortOrder[column] = SortOrder.asc;
      }
    }
    this.sortingAction.emit(new TableSort({ sortBy: column, sortOrder: this.sortOrder[column] }));
  }

  changeSearchFilterText(value: string) {
    this.filteringText = value.toLowerCase();
  }

  redirectToCase(id: any) {
    // Navigate to case and transfer case id
    this.router.navigate(['case', id]);
  }

  getBrandImagesUrlForProfile(profileId: number) {
    return this.caseService.getBrandImagesUrlForProfile(profileId.toString());
  }
}
