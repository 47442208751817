import { DropdownWitIconDictionary } from '../../models/dropdownWithIconDictionary';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-drop-down-with-icon',
  templateUrl: './drop-down-with-icon.component.html',
  styles: ['.commit-popover { width: 900px }']
})
export class DropDownWithIconComponent implements OnInit {
  @Input() required: boolean;
  @Input() requiredRedBackground: boolean;
  @Input() name: string;
  @Input() items: DropdownWitIconDictionary[];
  @Input() selectedItemId = '';
  @Input() disabled: boolean;
  @Input() closeOnSelect = true;
  @Input() placeholder = '';
  @Input() appendTo;
  @Output() selectedItemIdChange = new EventEmitter();
  @Output() saveModel = new EventEmitter();
  @Output() open = new EventEmitter();
  @Output() close = new EventEmitter();

  oldSelectedItemId: string;

  constructor() {}

  onChange() {
    this.selectedItemIdChange.emit(this.selectedItemId);
  }

  onOpen() {
    this.open.emit();
  }

  onClose() {
    this.close.emit();
  }

  ngOnInit() {}

  onSaveModel() {
    if (this.oldSelectedItemId !== this.selectedItemId) {
      this.saveModel.emit();
    }
  }

  onSaveState() {
    this.oldSelectedItemId = this.selectedItemId;
  }

  searchByItemName(term: string, item: DropdownWitIconDictionary) {
    term = term.toLocaleLowerCase();
    return item.name.toLocaleLowerCase().indexOf(term) > -1;
  }
}
