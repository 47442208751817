<div class="cases-container">
    <div class="primary-table-container">
      <table class="primary-table fixed-header-table">
        <thead>
          <tr>
            <td (click)="sortBy('customerProfileName')" [ngClass]="sortedBy('customerProfileName')">{{"board-case-table-customer" | translate}}</td>
            <td (click)="sortBy('createdOn')" [ngClass]="sortedBy('createdOn')">{{"board-case-table-case-creation-date" | translate}}</td>
            <td (click)="sortBy('endUserFullName')" [ngClass]="sortedBy('endUserFullName')">{{"board-case-table-end-user-name" | translate}}</td>
            <td (click)="sortBy('cause')" [ngClass]="sortedBy('cause')">{{"board-case-table-cause" | translate}}</td>
            <td (click)="sortBy('incidentLocationLocality')" [ngClass]="sortedBy('incidentLocationLocality')">{{"board-case-table-incident-city" | translate}}</td>
            <td (click)="sortBy('incidentLocationAlpha2')" [ngClass]="sortedBy('incidentLocationAlpha2')">{{"board-case-table-incident-country" | translate}}</td>
            <td (click)="sortBy('currentLocationLocality')" [ngClass]="sortedBy('currentLocationLocality')">{{"board-case-table-current-location-city" | translate}}</td>
            <td (click)="sortBy('currentLocationCountry')" [ngClass]="sortedBy('currentLocationAlpha2')">{{"board-case-table-current-location-country" | translate}}</td>
            <td>Case details</td>
            <td (click)="sortBy('transportAssistanceType')" [ngClass]="sortedBy('transportAssistanceType')">Transport by SOS</td>
            <td (click)="sortBy('caseTypeCode')" [ngClass]="sortedBy('caseTypeCode')">Case type</td>
            <td (click)="sortBy('teamStatus')" [ngClass]="sortedBy('teamStatus')">{{"board-case-table-team-status" | translate}}</td>
          </tr>
        </thead>
        <tbody (scroll)="onCaseTabelScroll()" id="caseIncidentTabel" [class.zoomed]="settingsService.isZoomed()">
          <tr *ngFor="let boardIncidentEventCase of boardIncidentEventCases"  (click)="redirectToCase(boardIncidentEventCase.id)" [routerLink]="['/case',boardIncidentEventCase.id]">
              <td class="customer-image-column">
                <img class="customer-image" *ngIf="boardIncidentEventCase.customerProfileId"
                  [src]="getBrandImagesUrlForProfile(boardIncidentEventCase.customerProfileId)"
                  [ngbPopover]="boardIncidentEventCase.customerProfileName"
                  placement="auto"
                  triggers="hover">
              </td>
              <td>
                {{boardIncidentEventCase.createdOnLocalTime | date :'dd MMM yyyy'}}
              </td>
              <td>
                {{boardIncidentEventCase.endUserFullName}}
              </td>
              <td class="long-text-field" >
                {{boardIncidentEventCase.incidentCause}}
              </td>
              <td class="long-text-field" >
                {{boardIncidentEventCase.incidentLocationLocality}}
              </td>
              <td class="long-text-field" >
                {{boardIncidentEventCase.incidentLocationCountry}}
              </td>
              <td class="long-text-field" >
                {{boardIncidentEventCase.currentLocationAdmArea}}
              </td>
              <td class="long-text-field" >
                {{boardIncidentEventCase.currentLocationCountry}}
              </td>
              <td>
                <div *ngIf="boardIncidentEventCase.relatedToMajorIncidentCase === true" class="case-status case-details-major-incident"></div>
                <div *ngIf="boardIncidentEventCase.relatedToICCCase === true" class="case-status case-details-internal-coordination"></div>
                <div *ngIf="boardIncidentEventCase.caseDetailsTBD === true" class="case-status case-details-mortal"> {{"board-task-table-case-details-TBD" | translate}} </div>
                <div *ngIf="boardIncidentEventCase.caseDetailsURN === true" class="case-status case-details-mortal"> {{"board-task-table-case-details-URN" | translate}} </div>
                <div *ngIf="boardIncidentEventCase.caseDetailsCOF === true" class="case-status case-details-mortal"> {{"board-task-table-case-details-COF" | translate}} </div>
                <div *ngIf="boardIncidentEventCase.caseDetailsTBD !== true && boardIncidentEventCase.caseDetailsURN !== true && boardIncidentEventCase.caseDetailsCOF !== true ">
                  <span *ngIf="boardIncidentEventCase.inpatientOutpatientOnCase !== null && boardIncidentEventCase.inpatientOutpatientActive !== null && showMedicalServiceOrderType(boardIncidentEventCase)">
                    <div *ngIf="boardIncidentEventCase.inpatientOutpatientOnCase === PatientType.INPATIENT" class="case-status"
                      [ngClass]="{ 'case-details-treatment-service-order-inpatient' : boardIncidentEventCase.inpatientOutpatientActive === true,
                                  'case-details-treatment-service-order-inactive' :  boardIncidentEventCase.inpatientOutpatientActive === false } ">
                        {{"board-task-table-case-details-INPATIENT" | translate}}
                    </div>
                    <div *ngIf="boardIncidentEventCase.inpatientOutpatientOnCase === PatientType.OUTPATIENT" class="case-status"
                      [ngClass]="{ 'case-details-treatment-service-order-outpatient' : boardIncidentEventCase.inpatientOutpatientActive === true,
                                  'case-details-treatment-service-order-inactive' :  boardIncidentEventCase.inpatientOutpatientActive === false } ">
                        {{ "board-task-table-case-details-OUTPATIENT" | translate }}
                    </div>
                  </span>
                  <span *ngIf="showMedicalServiceOrderType(boardIncidentEventCase)">
                    <div *ngIf="boardIncidentEventCase.medicalServiceorderType === CommitmentTypes.REGULAR_MEDICAL_ASSESSMENT" class="case-status case-details-medical-service-order"> {{"board-task-table-case-details-REGULAR" | translate}} </div>
                    <div *ngIf="boardIncidentEventCase.medicalServiceorderType === CommitmentTypes.LIGHT_MEDICAL_REVIEW" class="case-status case-details-medical-service-order"> {{"board-task-table-case-details-LIGHT" | translate}} </div>
                    <div *ngIf="boardIncidentEventCase.medicalServiceorderType === CommitmentTypes.NIGHT_SHIFT_MEDICAL_ASSESSMENT" class="case-status case-details-medical-service-order"> {{"board-task-table-case-details-NIGHT" | translate}} </div>
                    <div *ngIf="boardIncidentEventCase.medicalServiceorderType === CommitmentTypes.CLAIMS_MEDICAL_REVIEW" class="case-status case-details-claims-service-order"> {{"board-task-table-case-details-CLAIMS" | translate}} </div>
                  </span>
                  <div *ngIf="boardIncidentEventCase.caseDetailsAIR === true" class="case-status case-details-repatriation-form"> {{"board-task-table-case-details-AIR" | translate}} </div>
                  <div *ngIf="boardIncidentEventCase.caseDetailsSTR === true" class="case-status case-details-repatriation-form"> {{"board-task-table-case-details-STR" | translate}} </div>
                  <div *ngIf="boardIncidentEventCase.caseDetailsEXT === true" class="case-status case-details-repatriation-form"> {{"board-task-table-case-details-EXT" | translate}} </div>
                  <div *ngIf="boardIncidentEventCase.caseDetailsSIT === true" class="case-status case-details-repatriation-form"> {{"board-task-table-case-details-SIT" | translate}} </div>
                  <div *ngIf="boardIncidentEventCase.caseDetailsREG === true" class="case-status case-details-repatriation-form"> {{"board-task-table-case-details-REG" | translate}} </div>
                  <div *ngIf="boardIncidentEventCase.caseDetailsDOC === true || boardIncidentEventCase.caseDetailsNUR === true || boardIncidentEventCase.caseDetailsNME === true" class="case-status case-details-escort"> {{ caseDetailsEscort(boardIncidentEventCase) }} </div>
                  <div *ngIf="boardIncidentEventCase.caseDetailsOXY === true" class="case-status case-details-oxygen"> {{"board-task-table-case-details-OXY" | translate}} </div>
                  <div *ngIf="boardIncidentEventCase.caseDetailsEQU === true" class="case-status case-details-equipment"> {{"board-task-table-case-details-EQU" | translate}} </div>
                </div>
              </td>
              <td class="text-center">
                {{getTransportType(boardIncidentEventCase.transportAssistanceType)}}
              </td>
              <td class="case-info">
                <div class="case-type" [ngClass]="'casetype-style-' + boardIncidentEventCase.caseTypeCode">{{ getCaseType(boardIncidentEventCase.caseTypeCode) }}</div>
              </td>
              <td class="long-text-field" >
                {{ !!boardIncidentEventCase.teamStatus ? ('HANDLING-AREA-' + boardIncidentEventCase.teamStatus | translate) : ''}}
              </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
