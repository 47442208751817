import { ServiceTypeEnum } from './enums';

export class ServiceOrderDropDownItem {
  id: number;
  serviceOrderId: string;
  iconFileName: string;
  serviceOrderType: ServiceTypeEnum;
  disabled: boolean;
  providerName: string;

  public constructor(init?: Partial<ServiceOrderDropDownItem>) {
    Object.assign(this, init);
  }
}
