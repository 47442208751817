import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IncomingCasesTaskViewModel } from '@secca/board/components/board-task/models/incoming-cases-task-view-model';
import { DialogHelperUtilService } from '@secca/core/services/dialog-helper-util.service';
import { CauseItemType } from '@secca/shared/models/cause-item-type';
import { CustomerProfile } from '@secca/shared/models/customerProfile';
import { Diagnosis } from '@secca/shared/models/dignosis';
import { PurposeOfTravelItemType } from '@secca/shared/models/purpose-of-travel-item-type';
import { ServiceItemType } from '@secca/shared/models/service-item-type';

@Component({
  selector: 'app-medical-report-supplier-modal',
  templateUrl: './medical-report-supplier-modal.component.html',
  styleUrls: ['./medical-report-supplier-modal.component.scss'],
})
export class MedicalReportSupplierModalComponent {
  taskDataViewModel: IncomingCasesTaskViewModel;
  serviceItemList: ServiceItemType[] = [];
  purposeOfTravelItemList: PurposeOfTravelItemType[] = [];
  causes: Map<string, CauseItemType[]> = new Map();
  diagnoses: Diagnosis[];
  customerProfiles: CustomerProfile[] = [];

  @Output() closed = new EventEmitter<number>();

  constructor(private dialogHelperUtilService: DialogHelperUtilService) {}

  closeTaskWebDialog() {
    if (this.closed.observers) {
      this.closed.emit();
    }
    this.dialogHelperUtilService.closeModal();
  }
}
