import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
import {
  DigitalCaseflowActivityErrorLog,
  DigitalCaseflowActivityErrorLogAdapter
} from "@secca/shared/models/digital-caseflow-activity-error-log";
export class DigitalCaseflowActivityLog {
  activityType: string;
  result: string;
  activityErrorLogs: DigitalCaseflowActivityErrorLog[];

  public constructor(init?: Partial<DigitalCaseflowActivityLog>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class DigitalCaseflowActivityLogAdapter implements Adapter<DigitalCaseflowActivityLog> {

  constructor(private digitalCaseflowActivityErrorLogAdapter: DigitalCaseflowActivityErrorLogAdapter) {}

  adapt(item: any): DigitalCaseflowActivityLog {
    return new DigitalCaseflowActivityLog({
      activityType: item.activityType,
      result: item.result,
      activityErrorLogs: item.activityErrorLogs.map(error => this.digitalCaseflowActivityErrorLogAdapter.adapt(error))
    });
  }
}
