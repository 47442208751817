import { DateHelperService } from "@secca/core/services/date-helper.service";
import { ConsentStatusEnum, HandlingAreaStatusEnum, TaskStatus, TaskType } from "@secca/shared/models/enums";
import { Adapter } from '@secca/shared/interfaces/adapter';
import { Injectable } from "@angular/core";
import { DateSerializer } from '@secca/shared/models/date-serializer';
import { TaskViewModel } from "./task-view.model";
export class InboxTaskViewModel {
    id: number;
    isCheck: boolean;
    caseId: number;
    status: TaskStatus;
    taskType: TaskType;
    teamStatus: HandlingAreaStatusEnum;
    dueDate: moment.Moment;
    isSelected: boolean;
    taskPriority: number;
    customerProfileId: number;
    assignInitials: string;
    caseHandler: string;
    title: string;
    description: string;
    cause: string;
    causeLevel1Code: string;
    causeLevel2Code: string;
    causeLevel3Code: string;
    incidentCause: string;
    incidentDate: moment.Moment;
    incidentEvent: string
    incidentEventName: string
    caseNumber: string;
    endUserFullName: string;
    location: string;
    timeZoneId: string;
    timeOffsetInHours: number;
    reserve: string;
    currency: string;
    caseDetailsTBD: boolean;
    caseDetailsURN: boolean;
    caseDetailsCOF: boolean;
    caseDetailsAIR: boolean;
    caseDetailsSTR: boolean;
    caseDetailsEXT: boolean;
    caseDetailsSIT: boolean;
    caseDetailsREG: boolean;
    caseDetailsDOC: boolean;
    caseDetailsNUR: boolean;
    caseDetailsNME: boolean;
    caseDetailsOXY: boolean;
    caseDetailsEQU: boolean;
    medicalServiceorderType: string;
    readyFrom: moment.Moment;
    arrivalDeadline: moment.Moment;
    consentType: ConsentStatusEnum;
    fromText: string;
    caseLockedBy: string;

    public constructor(init?: Partial<InboxTaskViewModel>) {
        Object.assign(this, init);
    }

    convertToTaskViewModel(): TaskViewModel {
      return new TaskViewModel({
        id: this.id.toString(),
        caseId: +this.caseId,
        caseNumber: this.caseNumber,
        dueDate: this.dueDate,
        title: this.title,
        status: this.status,
        taskType: this.taskType,
      });
    }
}

@Injectable({
    providedIn: 'root'
  })
  export class InboxTaskViewModelAdapter implements Adapter<InboxTaskViewModel> {
    constructor(private dateHelperService: DateHelperService) {}

    adapt(item: any): InboxTaskViewModel {
      return new InboxTaskViewModel({
        id: item.id,
        isCheck: item.isCheck,
        caseId: item.caseId,
        taskType: item.taskType,
        teamStatus: item.teamStatus,
        status: item.status,
        dueDate: item.dueDate ? DateSerializer.deserialize(item.dueDate) : null,
        taskPriority: item.taskPriority,
        customerProfileId: item.customerProfileId,
        assignInitials: item.assignInitials,
        caseHandler: item.caseHandler,
        title: item.title,
        description: item.description,
        cause: item.cause,
        currency: item.currency,
        causeLevel1Code: item.causeLevel1Code,
        causeLevel2Code: item.causeLevel2Code,
        causeLevel3Code: item.causeLevel3Code,
        incidentCause: item.incidentCause,
        incidentDate: item.incidentDate ? DateSerializer.deserialize(item.incidentDate) : null,
        incidentEvent: item.incidentEvent,
        incidentEventName: item.incidentEventName,
        caseNumber: item.caseNumber,
        endUserFullName: item.endUserFullName,
        location: item.location,
        timeZoneId: item.timeZoneId,
        timeOffsetInHours: item.timeOffsetInHours,
        reserve: item.reserve,
        caseDetailsTBD: item.caseDetailsTBD,
        caseDetailsURN: item.caseDetailsURN,
        caseDetailsCOF: item.caseDetailsCOF,
        caseDetailsAIR: item.caseDetailsAIR,
        caseDetailsSTR: item.caseDetailsSTR,
        caseDetailsEXT: item.caseDetailsEXT,
        caseDetailsSIT: item.caseDetailsSIT,
        caseDetailsREG: item.caseDetailsREG,
        caseDetailsDOC: item.caseDetailsDOC,
        caseDetailsNUR: item.caseDetailsNUR,
        caseDetailsNME: item.caseDetailsNME,
        caseDetailsOXY: item.caseDetailsOXY,
        caseDetailsEQU: item.caseDetailsEQU,
        medicalServiceorderType: item.medicalServiceorderType,
        readyFrom: item.readyFrom,
        arrivalDeadline: item.arrivalDeadline,
        consentType: item.consentType,
        fromText: item.fromText,
        caseLockedBy: item.caseLockedBy,
      });
    }
  }