import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { SettingsService } from './settings.service';
import {HttpClient} from "@angular/common/http";
import {UserDto, UserDtoAdapter} from "@secca/shared/models/userDto";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {
    constructor(
      private httpClient: HttpClient,
      private settingsService: SettingsService,
      private userDtoAdapter: UserDtoAdapter
    ) {
      super(settingsService);
    }
    public getUserImagesUrl(userId: string): string {
      return this.baseURL + 'users/' + userId + '/user-images';
    }

  public getUserByUserId(userId: string): Observable<UserDto> {
    return this.httpClient.get<UserDto>(this.baseURL + `users/by-user-id/` + userId, { headers: this.jsonHeaders })
      .pipe(map((data: UserDto) => this.userDtoAdapter.adapt(data)));
  }

  public getUserFromLastActionInCaseSummary(caseId: string): Observable<UserDto> {
    return this.httpClient.get<UserDto>(this.baseURL + `users/user-from-last-action-in-case-summary/` + caseId, { headers: this.jsonHeaders })
      .pipe(map((data: UserDto) => this.userDtoAdapter.adapt(data)));
  }
}
