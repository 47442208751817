<div class="medical-pre-decision-container" id="decision">
  <div class="assessment-card-content" *ngIf="medicalPreAssessment?.note?.canFinalizeAssessment===true">
    <div class="field-row">
      <div class="col-4">
        <app-single-choice-component [alternativePadding]="true" [items]="decisionList" [(value)]="medicalPreAssessment.decision" [disabled]="isDisabled">
        </app-single-choice-component>
      </div>
    </div>
    <app-text-box [disabled]="isDisabled" *ngIf="medicalPreAssessment.decision === MedicalPreAssessmentDecisionEnum.PARTIALLY_COVERED" [maxTextLength]="2000"
      [largeView]=true [numberOfRows]="3" [name]="'medical-pre-assessment-description-to-end-user' | translate"
      [(value)]="medicalPreAssessment.decisionDetail.endUserDescription" [required]=false [placeHolder]="">
    </app-text-box>
    <app-text-box [disabled]="isDisabled" [maxTextLength]="2000" [largeView]=true [numberOfRows]="3"
      [name]="'medical-pre-assessment-evaluation-decision' | translate" [(value)]="medicalPreAssessment.decisionDetail.evaluationDecision"
      [required]=true [placeHolder]="'medical-pre-assessment-evaluation-decision-placeholder' | translate">
    </app-text-box>
    <div class="small-spacer"></div>
    <div class="row mb-3">
      <div class="col-8">
        <div class="common-drop-down-arrow common-drop-down">
          <ng-select [class.required-red-background]="!medicalPreAssessment.decisionDetail.templateName" [clearable]="false"
            [items]="templateNames" [(ngModel)]="medicalPreAssessment.decisionDetail.templateName" class="custom"
            (change)="selectTemplateLanguages($event)">
            <ng-template ng-label-tmp let-item="item">
              <div>
                {{ item }}
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="col-4">
        <div class="common-drop-down-arrow common-drop-down">
          <ng-select [class.required-red-background]="!medicalPreAssessment.decisionDetail.templateLanguage" [clearable]="false"
            [items]="templateLanguages" [(ngModel)]="medicalPreAssessment.decisionDetail.templateLanguage" class="custom"
            (change)="selectTemplateId($event)">
            <ng-template ng-label-tmp let-item="item">
              <div>
                {{ item }}
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>
    <div class="small-spacer"></div>
    <div class="button-container">
      <button class="preview-button primary-button" [disabled]="decisionButtonDisabled" (click)="previewLetter()">
        {{ 'medical-pre-assessment-preview-letter' | translate}}</button>
      <button [class.primary-button-inactive]="isSendButtonDisabled" class="primary-button" [disabled]="isSendButtonDisabled"
        (click)="sendLetter()">
        <div>
          {{ 'medical-pre-assessment-send-letter' | translate }}
        </div>
      </button>
    </div>
  </div>
  <div class="assessment-card-content" *ngIf="medicalPreAssessment?.note?.canFinalizeAssessment===false">
    <div class="small-spacer"></div>
    <div class="row no-gutters">
      <div class="nodecisionContainer">
        <button class="decision-button primary-button" [disabled]="decisionButtonDisabled"
          (click)="publishDecision(MedicalPreAssessmentDecisionEnum.CASE_HANDLING)">{{"medical-pre-assessment-casehandling" | translate}}</button>
          <button class="decision-button primary-button" [disabled]="decisionRequestInfoButtonDisabled"
          (click)="sendRequestInformation()">{{"medical-pre-assessment-request-information" | translate}}</button>
        <button class="decision-button primary-button" [disabled]="decisionButtonDisabled"
          (click)="publishDecision(MedicalPreAssessmentDecisionEnum.DOCTOR)">{{"medical-pre-assessment-doctor" | translate}}</button>
        <button class="decision-button primary-button" [disabled]="decisionButtonDisabled"
          (click)="publishDecision(MedicalPreAssessmentDecisionEnum.NURSE)">{{"medical-pre-assessment-nurse" | translate}}</button>
      </div>
    </div>
    <div class="small-spacer"></div>
  </div>
</div>
