<div class="sub-component-container" >
  <div class="sub-content-container">
    <div *ngFor="let duration of durations; let i = index">
      <app-duration
      [deleteable]="deleteable(i)"
      [duration]="durations[i]"
      [gopSemiRequired]="gopSemiRequired(i)"
      (delete)="deleteDuration($event)"
      [defaultStartDate]="defaultStartDate"
      [defaultEndDate]="defaultEndDate"
      [hideGOPDates]="hideGOPDates"
      [disableStartDate]="disableStartDate"
      [disableEndDate]="disableEndDate"
      [disableLocation]="disableLocation"
      [altGopPeriodTitle]="altGopPeriodTitle"
      [serviceOrderType]="serviceOrderType"
      ></app-duration>
    </div>
  </div>
  <button *ngIf="!hideAdd"class="primary-button ms-3 mb-2" (click)="addMore()">Add more</button>
</div>
