import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
import { PhoneNumberType } from './enums';

export class PhoneNumber {
    id: number;
    number: string;
    prefix: string;
    type: PhoneNumberType;
    countryCode: string;

    public constructor(init?: Partial<PhoneNumber>) {
        Object.assign(this, init);
    }
}

@Injectable({
    providedIn: 'root'
})
export class PhoneNumberAdapter implements Adapter<PhoneNumber> {
    adapt(item: any): PhoneNumber {
        return new PhoneNumber({
            id: item.id,
            number: item.number,
            prefix: item.prefix,
            type: item.type,
            countryCode: item.countryCode
        });
    }
}
