<div [appDisableInput]="caseLockHelperService.isCaseBasicLockTaken || permissionService.isReadOnly">
  <div class="row header-amounts">
    <div class="col-2">
      <div class="d-flex flex-row justify-content-between fee-picker">
        <div [class.fee-picker-disabled]="reenableFeePickerDisabled">{{ 'case-economy-sos-services-primary-fee-picker' | translate }}</div>
        <input
          type="checkbox"
          [(ngModel)]="!feePickerStatus.primaryDisabled"
          (change)="enableFeePicker()"
          [disabled]="reenableFeePickerDisabled()"
        />
      </div>
      <div class="d-flex flex-row justify-content-between fee-picker">
        <div class="fee-picker-disabled">{{ 'case-economy-sos-services-secondary-fee-picker' | translate }}</div>
        <input
          type="checkbox"
          [(ngModel)]="feePickerSecondaryEnabled"
          [indeterminate]="isFeePickerSecondaryActivePart()"
          *ngIf="!feePickerSecondaryEnabled"
          (change)="openFeePickerSecondary()"
        /><input type="checkbox" *ngIf="feePickerSecondaryEnabled" checked disabled />
      </div>
    </div>
    <div class="col-4 d-flex">
      <div *permission="PermissionEnum.SALES_ORDER_UPDATE">
        <button
          class="primary-button"
          [class.button-busy]="disableRunFeepicker"
          (click)="executeFeePicker()"
          [disabled]="disableRunFeepicker"
        >
          {{ 'case-economy-sos-services-run-fee-picker' | translate }}
          <img src="../../../../../../assets/icons/waitForSearchresult.gif" class="spinner" [class.hide-spinner]="!disableRunFeepicker" />
        </button>
      </div>
      <div *permission="PermissionEnum.SALES_ORDER_CREATE">
        <button class="primary-button" (click)="addFee()">
          {{ 'case-economy-sos-services-add-fee' | translate }}
        </button>
      </div>
    </div>
    <div class="col-2">
      <div class="row">
        <div class="col text-end amount-name">{{ 'case-economy-sos-services-not-invoiced-amount' | translate }}</div>
      </div>
      <div class="row">
        <div class="col">
          <div class="d-flex">
            <div class="flex-fill text-end amount-value">{{ notInvoicedAmount | number: '.2' }}</div>
            <div class="amount-currency">{{ caseCurrency }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-2">
      <div class="row">
        <div class="col text-end amount-name">{{ 'case-economy-sos-services-sent-to-finance' | translate }}</div>
      </div>
      <div class="row">
        <div class="col">
          <div class="d-flex">
            <div class="flex-fill text-end amount-value">{{ sentToERPAmount | number: '.2' }}</div>
            <div class="amount-currency">{{ caseCurrency }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-2">
      <div class="row">
        <div class="col text-end amount-name">{{ 'case-economy-sos-services-invoiced-amount' | translate }}</div>
      </div>
      <div class="row">
        <div class="col">
          <div class="d-flex">
            <div class="flex-fill text-end amount-value">{{ invoicedAmount | number: '.2' }}</div>
            <div class="amount-currency">{{ caseCurrency }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-12 table-container" [class.zoomed]="settingsService.isZoomed()">
  <table class="primary-table">
    <thead>
      <tr>
        <td class="thead" sortColumn [sortKey]="'sosServiceName'" [data]="salesOrderItems">
          {{ 'case-economy-sos-services-sosServiceName' | translate }}
        </td>
        <td class="thead" sortColumn [sortKey]="'feeType'" [data]="salesOrderItems">
          {{ 'case-economy-sos-services-feeType' | translate }}
        </td>
        <td class="thead" sortColumn [sortKey]="'appliedDate'" [data]="salesOrderItems">
          {{ 'case-economy-sos-services-appliedDate' | translate }}
        </td>
        <td class="thead" sortColumn [sortKey]="'agreementPrice'" [data]="salesOrderItems">
          {{ 'case-economy-sos-services-priceAgreement' | translate }}
        </td>
        <td class="thead" sortColumn [sortKey]="'invoicePrice'" [data]="salesOrderItems">
          {{ 'case-economy-sos-services-invoicePrice' | translate }}
        </td>
        <td class="thead vat-column" sortColumn [sortKey]="'vat'" [data]="salesOrderItems">
          {{ 'case-economy-sos-services-vat' | translate }}
        </td>
        <td class="thead" sortColumn [sortKey]="'invoiced'" [data]="salesOrderItems">
          {{ 'case-economy-sos-services-status' | translate }}
        </td>
      </tr>
    </thead>
    <tbody class="theBody">
      <ng-container *ngFor="let salesOrderItem of salesOrderItems; index as i">
        <tr [ngClass]="{ suggestion: salesOrderItem.suggestion }">
          <td [ngClass]="{ 'billable-indicator': salesOrderItem.billable, even: i % 2 != 0 }">
            <div class="flex-fill" [ngbPopover]="salesOrderItem.actionComment" triggers="hover" placement="bottom-left">
              {{ salesOrderItem.sosServiceName }}
            </div>
          </td>
          <td [ngClass]="{ even: i % 2 != 0 }">{{ salesOrderItem.feeType }}</td>
          <td [ngClass]="{ even: i % 2 != 0 }">{{ salesOrderItem.appliedDate | date: 'dd LLL yyyy, HH:mm' }}</td>
          <td [ngClass]="{ even: i % 2 != 0 }">
            <ng-template #popContent><b>SOS Service ID: </b>{{salesOrderItem.sosServiceId}}<br/><b>Quantity: </b>{{salesOrderItem.quantity}}<br/><b>Unit price: </b>{{(salesOrderItem.agreementPrice.amount/salesOrderItem.quantity) | number: getCurrencyDecimals(salesOrderItem.agreementPrice)}} {{salesOrderItem.agreementPrice.currency}} </ng-template>
            <div class="d-flex" [disablePopover]="salesOrderItem.quantity == null"
                 triggers="hover"
                 placement="right"
                 [ngbPopover]="popContent">
              <div class="flex-fill currency">{{ salesOrderItem.agreementPrice?.currency }}</div>
              <div [ngClass]="{
                  'amount-positive': salesOrderItem.agreementPrice?.amount >= 0,
                  'amount-negative': salesOrderItem.agreementPrice?.amount < 0
                }" *ngIf="salesOrderItem.agreementPrice"
              >
                {{ salesOrderItem.agreementPrice.amount | number: getCurrencyDecimals(salesOrderItem.agreementPrice) }}
              </div>
            </div>
          </td>
          <td [ngClass]="{ even: i % 2 != 0 }">
            <div class="d-flex">
              <div class="flex-fill currency">{{ salesOrderItem.invoicePrice.currency }}</div>
              <div
                [ngClass]="{
                  'amount-positive': salesOrderItem.invoicePrice.amount >= 0,
                  'amount-negative': salesOrderItem.invoicePrice.amount < 0
                }"
              >{{ salesOrderItem.invoicePrice.amount | number: '.2-2' }}</div>
            </div>
          </td>
          <td [ngClass]="{ even: i % 2 != 0 }" class="vat-column">
            <input type="checkbox" disabled checked *ngIf="salesOrderItem.vat" />
          </td>
          <td [ngClass]="{ even: i % 2 != 0 }">
            <div [appDisableInput]="caseLockHelperService.isCaseBasicLockTaken || permissionService.isReadOnly">
              <div class="d-flex">
                <div class="flex-fill" *ngIf="!salesOrderItem.suggestion">
                  {{ 'sales-order-status-' + salesOrderItem.status | translate }}
                </div>
                <div class="flex-fill" *ngIf="salesOrderItem.suggestion">{{ 'case-economy-sos-services-suggestion' | translate }}</div>
                <div class="context-menu" *ngIf="userUpdateDeletePermitted()">
                  <div class="context-menu-dot"></div>
                  <div class="context-menu-dot"></div>
                  <div class="context-menu-dot"></div>
                  <div class="context-menu-hook">
                    <div class="context-menu-content">
                      <p
                        *ngIf="salesOrderItemUpdatePermitted(salesOrderItem)"
                        (click)="replaceFee(salesOrderItem)"
                        [ngClass]="{ 'context-menu-content-disabled': salesOrderItem.agreementPrice?.amount < 0 || salesOrderItem.credited }"
                      >
                        {{ 'case-economy-sos-services-replace-fee' | translate }}
                      </p>
                      <p
                        *ngIf="salesOrderItemUpdatePermitted(salesOrderItem)"
                        (click)="adjustAmount(salesOrderItem)"
                        [ngClass]="{ 'context-menu-content-disabled': salesOrderItem.creditNote || salesOrderItem.credited || !selectCreditAllowed(salesOrderItem)}"
                      >
                        {{ 'case-economy-sos-services-adjust-amount' | translate }}
                      </p>
                      <p *ngIf="selectSuggestionPermitted(salesOrderItem)" (click)="selectSuggestion(salesOrderItem)">
                        {{ 'case-economy-sos-services-select-suggestion' | translate }}
                      </p>
                      <p
                        *permission="PermissionEnum.SALES_ORDER_DELETE"
                        (click)="deleteSalesOrderItem(salesOrderItem)"
                        [ngClass]="{ 'context-menu-content-disabled': salesOrderItem.invoiced || salesOrderItem.credited }"
                      >
                        {{ 'case-economy-sos-services-delete-fee' | translate }}
                      </p>
                      <p
                        *permission="PermissionEnum.SALES_ORDER_UPDATE"
                        [hidden]="salesOrderItem.creditNote"
                        (click)="creditSalesOrderItem(salesOrderItem)"
                        [ngClass]="{ 'context-menu-content-disabled': salesOrderItem.agreementPrice?.amount < 0 || salesOrderItem.credited || selectCreditAllowed(salesOrderItem)}"
                      >
                        {{ 'case-economy-sos-services-credit-fee' | translate }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
<app-modify-sales-order #modifySalesOrder (salesOrderModified)="refreshFeePickerStatus()"></app-modify-sales-order>
<app-fee-picker-secondary #feePickerSecondary (feePickerSecondaryUpdated)="feePickerSecondaryUpdated($event)"></app-fee-picker-secondary>
