import {Component, EventEmitter, Input, Output} from '@angular/core';
import {RecoveryPaymentItem} from "@secca/shared/models/recoveryPaymentItem";
import {DropdownDictionary} from "@secca/shared/models/dropdownDictionary";
import {CurrencyMaskInputMode} from "ngx-currency";

@Component({
  selector: 'app-recovery-payment-item',
  templateUrl: './recovery-payment-item.component.html',
  styleUrls: ['./recovery-payment-item.component.scss']
})
export class RecoveryPaymentItemComponent {
  @Output()
  removePaymentItem = new EventEmitter<RecoveryPaymentItem>();
  @Output()
  changePaymentItem = new EventEmitter<RecoveryPaymentItem>();
  @Input()
  recoveryPaymentItem: RecoveryPaymentItem;
  @Input()
  serviceItemOptions: DropdownDictionary[];
  @Input()
  recoveryOptions: DropdownDictionary[];
  @Input()
  firstItem: boolean;
  @Input()
  approved: boolean;
  @Input()
  paymentCurrency: string;
  @Input()
  disabled: boolean = false;

  currencyMaskOptions = {
    prefix: '',
    thousands: '.',
    decimal: ',',
    precision: 2,
    allowNegative: true,
    inputMode: CurrencyMaskInputMode.NATURAL,
  };

  removeItem() {
    this.removePaymentItem.emit(this.recoveryPaymentItem);
  }
  amountChanged() {
    this.changePaymentItem.emit(this.recoveryPaymentItem);
  }
}
