import { Component, Input, OnInit } from '@angular/core';
import { SubRemarksFromSupplier } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subRemarksFromSupplier';

@Component({
  selector: 'app-sub-remarks-from-supplier',
  templateUrl: './sub-remarks-from-supplier.component.html',
  styleUrls: ['./sub-remarks-from-supplier.component.scss']
})
export class SubRemarksFromSupplierComponent implements OnInit {
  @Input() subRemarksFromSupplier: SubRemarksFromSupplier;

  constructor() { }

  ngOnInit() {
  }

}
