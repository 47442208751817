import {ValidationTypeEnum} from '../../models/enums';
import {Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, Renderer2, SimpleChanges, ViewChild, OnChanges} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {AutoUnsubscribe} from 'src/app/shared/decorators/auto-unsubscribe';
import {Location} from '../../models/location';
import {LocationService} from '../../../core/services/location.service';
import Autocomplete = google.maps.places.Autocomplete;
import AutocompleteOptions = google.maps.places.AutocompleteOptions;
import {EMPTY, Observable} from 'rxjs';

@Component({
  selector: 'app-input-location',
  templateUrl: './input-location.component.html',
  styleUrls: ['./input-location.component.scss'],
})
@AutoUnsubscribe
export class InputLocationComponent implements OnInit, OnChanges {
  public searchLocation: UntypedFormControl;
  autocompleteLocation: Autocomplete;
  isValid = false;
  locationSetByGoogle: boolean;
  @Input() id: number;
  @Input() name: string;
  @Input() withImage: boolean;
  @Input() withLabel = true;
  @Input() freeText: string;
  @Input()
  public get value(): Location {
    return this._value;
  }
  public set value(value: Location) {
    this._value = value;
    this.validate();
  }

  private _value: Location = new Location();
  @Input() allowEmpty = false;
  @Input() validationType: ValidationTypeEnum;
  @Input() disabled: boolean;
  @Input() autocompleteOptions: AutocompleteOptions;
  @Input() recommended: boolean;
  @Output() saveModel = new EventEmitter();
  @ViewChild('searchElement', { static: true })
  public searchElementLocationRef: ElementRef;

  private currentFreetext: string;

  constructor(private locationService: LocationService, private ngZone: NgZone, private renderer: Renderer2) {}

  public ngOnInit() {
    if (this.validate()) {
      this.locationSetByGoogle = true;
    }
    this.initMapsAPI();
    this.currentFreetext = this.value.freeText;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isValid = false;
     setTimeout(() => {
                this.validate();
              }, 500);
  }

  initMapsAPI() {
    this.searchLocation = new UntypedFormControl();
    this.autocompleteLocation = new Autocomplete(this.searchElementLocationRef.nativeElement,
      this.autocompleteOptions ? this.autocompleteOptions : {
      fields: ['address_components', 'geometry', 'utc_offset_minutes']});
    this.autocompleteLocation.addListener('place_changed', () => {
      this.runGoogleApi(this.autocompleteLocation, this.searchElementLocationRef);
    });
    this.markIfLocationIsValid();
  }

  runGoogleApi(ac: Autocomplete, ref: ElementRef) {
    try {
      this.ngZone.run(() => {
        const place: google.maps.places.PlaceResult = ac.getPlace();
          this.getInformationFromGoogle(place).subscribe(httpResponse => {
            this.value.timeZoneId = httpResponse.timeZoneId;
          },() => {},() => {
              this.value.freeText = ref.nativeElement.value;
              this.currentFreetext = this.value.freeText;
              this.markIfLocationIsValid();
              this.onBlur();
            });
      });
    } catch (e) {
      console.log('input-location.component.ts runGoogleApi():' + e);
    }
  }

  private markIfLocationIsValid() {
    if (!this.locationSetByGoogle) {
      this.isValid = false;
      return false;
    }
    this.validate();
  }

  private validate() {
    if (this.allowEmpty && this.value.freeText === '') {
      this.isValid = true;
      return this.isValid;
    }
    if (this.value.country == null) {
      this.isValid = false;
      return this.isValid;
    }
    if (this.value.administrativeAreaLevel1 !== undefined && this.value.administrativeAreaLevel1 !== '') {
      this.isValid = true;
    }
    if (this.value.locality !== undefined && this.value.locality !== '') {
      this.isValid = true;
    }
    return this.isValid;
  }

  onBlur() {
    if (this.searchElementLocationRef.nativeElement.value === '') {
      this.clearLocation();
    } else if (this.searchElementLocationRef.nativeElement.value !== this.currentFreetext) {
      this.clearLocation(false);
    } else {
      this.currentFreetext = this.value.freeText;
    }
    this.saveModel.emit();
  }

  get validationTypeEnum() {
    return ValidationTypeEnum;
  }

  getInformationFromGoogle(place: any): Observable<any> {
    if (place == null) {
      this.clearLocation();
      return EMPTY;
    }

    if (!place.address_components && this.currentFreetext === this.value.freeText && this.isValid) {
      return EMPTY;
    }

    if (!place.geometry) {
      this.clearLocation(false);
      return EMPTY;
    }

    if (place === undefined || place.address_components === undefined) {
      this.clearLocation();
      return EMPTY;
    }

    this.clearLocation();
    this.locationSetByGoogle = true;
    for (const address of place.address_components) {
      switch (address.types[0]) {
        case 'street_number':
          this.value.streetNumber = address.long_name;
          break;
        case 'route':
          this.value.streetName = address.long_name;
          break;
        case 'locality':
          this.value.locality = address.long_name;
          break;
        case 'administrative_area_level_1':
          this.value.administrativeAreaLevel1 = address.long_name;
          break;
        case 'administrative_area_level_2':
          this.value.administrativeAreaLevel2 = address.long_name;
          break;
        case 'country':
          this.value.country = address.long_name;
          this.value.countryCodeAlpha2 = address.short_name;
          break;
        case 'postal_code':
          this.value.postalCode = address.long_name;
          break;
      }
    }

    this.value.latitude = place.geometry.location.lat();
    this.value.longitude = place.geometry.location.lng();
    this.value.utcOffsetInHours = place.utc_offset_minutes ? place.utc_offset_minutes / 60 : 0;
    return this.locationService.requestTimeZoneId(this.value.latitude, this.value.longitude);
  }

  clearLocation(clearFreeText?: boolean) {
    this.locationSetByGoogle = false;
    this.value.streetNumber = '';
    this.value.streetName = '';
    this.value.locality = '';
    this.value.administrativeAreaLevel1 = '';
    this.value.administrativeAreaLevel2 = '';
    this.value.country = '';
    this.value.postalCode = '';
    this.value.latitude = 0;
    this.value.longitude = 0;
    this.value.timeZoneId = '';
    this.value.utcOffsetInHours = null;
    this.isValid = false;
    this.value.countryCodeAlpha2 = '';
    if (clearFreeText) {
      this.currentFreetext = '';
    }
  }

  get validationClass() {
    if (!this.disabled) {
      switch (this.validationType) {
        case ValidationTypeEnum.required:
          if (this.isValid ) {
            return 'input-component-location';
          } else {
            return this.value.freeText == null || this.value.freeText === '' ? 'required-input-location' : 'invalid-input-location';
          }
        case ValidationTypeEnum.default:
          return this.isValid ? 'input-component-location' : 'invalid-input-location';

        default:
          return 'input-component-location';
      }
    }
    if (this.disabled) {
      this.renderer.setAttribute(this.searchElementLocationRef.nativeElement, 'disabled', 'true');
    } else {
      this.renderer.removeAttribute(this.searchElementLocationRef.nativeElement, 'disabled');
    }
  }
}
