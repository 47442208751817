<div class="case-basics-insurance-container position-relative" *ngIf="personInsurance">
  <div [appDisableInput]="caseStateService.isCaseDisabled()">
    <span *ngIf='!isInternalCoordinationCase'>
      <span *permission="PermissionEnum.CASE_BASIC_UPDATE">
        <button class="insurance-button primary-button" [ngClass]="personInsurance.insuranceLookupMatch != null ? 'btn-lookup-selected' : (policyLookupEnabled ? '' : 'profile-lookup-selected')" [disabled]="!lookupEnabled"
                (click)="policyLookupEnabled ? policyLookupOpen(): profileLookupOpen()" #insuranceLookupButton>
          <ng-container *ngIf="!lookupEnabled">L<span class="shortcut-key">o</span>okup</ng-container>
          <ng-container *ngIf="lookupEnabled && policyLookupEnabled">Policy l<span class="shortcut-key">o</span>okup</ng-container>
          <ng-container *ngIf="lookupEnabled && !policyLookupEnabled">Profile l<span class="shortcut-key">o</span>okup</ng-container>
        </button>
      </span>
    </span>
  </div>
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
    <li [ngbNavItem]="1">
      <a ngbNavLink>{{ 'case-basics-insurance' | translate }}</a>
      <ng-template ngbNavContent>
        <app-case-basics-insurance-details [personInsurance]="personInsurance" [caseId]="caseId" [consent]="consent"></app-case-basics-insurance-details>
      </ng-template>
    </li>
    <ng-container *ngIf="!isInternalCoordinationCase">
      <li [ngbNavItem]="2">
        <a ngbNavLink>{{ 'coverages-limits-title' | translate }}</a>
        <ng-template ngbNavContent>
          <app-coverage-view [personInsurance]="personInsurance" [coveragesBasic]="coveragesBasic"
                             [coveragesSupplement]="coveragesSupplement" [fixedHeight]="true"></app-coverage-view>
        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <a ngbNavLink>{{ 'case-basics-otherInsurance' | translate }}</a>
        <ng-template ngbNavContent>
          <app-case-basics-insurance-other [otherInsurance]="otherInsurance" [personInsurance]="personInsurance"></app-case-basics-insurance-other>
        </ng-template>
      </li>
    </ng-container>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</div>
