<div class="d-flex">
  <div [style.width.%]="calculateStakeholderDropdownWidth()">
    <div class="common-drop-down-arrow common-drop-down">
      <ng-select class="custom" [class.required-red-background]="showInvalidBackground || ((subStakeholdersConfig.requireSingleStakeholder ||
        subStakeholdersConfig.requireAtLeastOneStakeholder) && isFirstItem && !subStakeholder.stakeholderId)"
        [items]="stakeholdersDropdown" bindValue="id" bindLabel="name" [(ngModel)]="subStakeholder.stakeholderId"
        (change)="setSelectedStakeholder($event)" [disabled]="isMandatoryAndSetStakeholderType || disabled" [clearable]="false">
        <ng-template ng-option-tmp let-item="item" let-index="index">
          <div>
            <img [src]="item.iconPath" class="drop-down-icon" /> {{item.name}}
          </div>
        </ng-template>
        <ng-template ng-label-tmp let-item="item">
          <div class="service-order-stakeholder-picker">
            <img [src]="item.iconPath" class="drop-down-icon" *ngIf="item?.iconPath"/>
            <span class="p-2 service-order-stakeholder-picker-label">{{item.name}}</span>
            <span class="no-nip ms-auto p-2"  *ngIf="!hideNip && (!nameInPassport || subStakeholdersConfig.showNoNipAlways)">{{"sub-stakeholder-no-nip" | translate}}</span>
          </div>
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="col-class w-100 ms-2" *ngIf="!subStakeholdersConfig.hideClass">
    <app-drop-down *ngIf="!showSeeMedifOnlyForEndUser()"
      [requiredRedBackground]="(subStakeholdersConfig.requireSingleStakeholder || subStakeholdersConfig.requireAtLeastOneStakeholder) && isFirstItem"
      [items]="classDropdown" [(selectedItemId)]="subStakeholder.cabinClass" [disabled]="disabled"></app-drop-down>
    <app-drop-down *ngIf="showSeeMedifOnlyForEndUser()"
      [items]="classDropdownSeeMedifOnly" [(selectedItemId)]="subStakeholder.cabinClass" [disabled]="true"></app-drop-down>
  </div>
  <div class="col-yes-no-missing w-100 ms-2" *ngIf="!subStakeholdersConfig.hideInclDateOfBirth">
    <app-drop-down
      [requiredRedBackground]="(subStakeholdersConfig.requireSingleStakeholder || subStakeholdersConfig.requireAtLeastOneStakeholder) && isFirstItem"
      [items]="inclDOBDropdown" [(selectedItemId)]="subStakeholder.includeDateOfBirth" [disabled]="disabled || (subStakeholdersConfig.lockFirstInclDateOfBirth && isFirstItem )"></app-drop-down>
  </div>
  <div class="col-yes-no-missing w-100 ms-2" *ngIf="!subStakeholdersConfig.hideInclPassportDetails">
    <app-drop-down
      [requiredRedBackground]="(subStakeholdersConfig.requireSingleStakeholder || subStakeholdersConfig.requireAtLeastOneStakeholder) && isFirstItem"
      [items]="inclPassportDropdown" [(selectedItemId)]="subStakeholder.includePassportDetails" [requiredRedLabel]="missingPassportDetails"
      [disabled]="missingPassportDetails || disabled" [missingItemLabel]="'sub-stakeholder-missing' | translate"></app-drop-down>
  </div>
  <div class="delete-container w-100 ms-2"
    *ngIf="!subStakeholdersConfig.requireSingleStakeholder && !isMandatoryAndSetStakeholderType && !disabled && !subStakeholdersConfig.hideTrashIcon">
    <div class="d-flex justify-content-center align-items-center h-100" (click)="deleteStakeholder()">
      <img src="../../../../assets/icons/garbage.svg" />
    </div>
  </div>
</div>
