<div class="modal-container">
  <div class="modal-content">
    <div class="modal-header">
      <div class="modal-header-title">
        {{"task-reassign-title" | translate}}
      </div>
      <div class="modal-close">
        <img src="/assets/icons/Close White.svg" (click)="close()">
      </div>
    </div>
    <div class="modal-body">
      <div class="assignee-panel">
        <div class="number-of-assign" *ngIf="tasksForReassignTaskViewModel">
          {{"task-reassign-label" | translate: {numberOfTasks: tasksForReassignTaskViewModel?.length } }}</div>
        <div class="number-of-assign" *ngIf="tasksForReassignSimpleTaskViewModel">
          {{"task-reassign-label" | translate: {numberOfTasks: tasksForReassignSimpleTaskViewModel?.length } }}
        </div>
        <div class="new-assignee-label">
          {{  "task-reassign-new-assignee" | translate}}
        </div>
        <div *ngIf="acHandler != undefined && !isCaseHandlerVisible" (click)="makeCaseHandlerVisible($event)"
          class="change-ac-handler">
          <div *ngIf="acHandler.isTeam" class="container-fluid">
            <div class="row">
              <div class="col fixed-width-50">
                <app-team-picture>
                </app-team-picture>
              </div>
              <div class="col column-content">
                <div class="team-name">{{acHandler.teamName}}</div>
              </div>
            </div>
          </div>
          <div *ngIf="!acHandler.isTeam" class="container-fluid">
            <div class="row" (click)="makeCaseHandlerVisible($event)">
              <div class='col fixed-width-50'>
                <app-user-picture [imageURL]="acHandler.picture" [active]="acHandler.active"></app-user-picture>
              </div>
              <div class="col column-content">
                <div>{{acHandler.fullUserName}} ({{acHandler.initials}})</div>
                <div class="person-team">{{acHandler.team.teamName | titlecase}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="handlers-dropdown-list" [ngStyle]="(acHandler == undefined || isCaseHandlerVisible)  && {'display':'block'}"
          (clickOutside)="makeCaseHandlerInVisible($event)">
          <app-drop-down-auto-complete #caseHandlerDropDown
            [caseId]="caseId"
            [autoCompleteTypeEnum]="AutoCompleteTypeEnum.UsersAndTeamsExceptFromOtherDepartment"
            (selectedItemIdChange)="caseHandlerChanged($event)">
          </app-drop-down-auto-complete>
        </div>
      </div>
      <button class="reassign-button" (click)="reassignClick()"
        [class.reassign-button-deactivated]="!acHandler">Reassign</button>
    </div>

  </div>
</div>
