import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

export enum DialogStateAction {
  MAXIMIZE = 'maximize',
  MINIMIZE = 'minimize'
}

@Injectable({
    providedIn: 'root'
})  
export class DialogStateService {
    private sendToFrontNotification = new Subject<any>();
    private minimizableNotification = new Subject<any>();

    public sendToFront(dialogId: any) {
        this.sendToFrontNotification.next(dialogId);
    }

    public getSendToFront(): Observable<any> {
        return this.sendToFrontNotification;
    }

    public maximize(dialogId: string) {
        this.minimizableNotification.next({action:DialogStateAction.MAXIMIZE, dialogId});
    }
     
    public minimize(dialogId: string) {
      this.minimizableNotification.next({action:DialogStateAction.MINIMIZE, dialogId});
    }

    public getMinimizable(): Observable<any> {
      return this.minimizableNotification;
    }
}

export class MinimizableNotifData {
  action: DialogStateAction;
  dialogId: string;
}
