import { Injectable } from '@angular/core';
import { Adapter } from './../interfaces/adapter';
import { Person, PersonAdapter, UpdateDateOfBirthOrAgeOrGenderOrNationalId } from './person';
import { Company } from './company';
import { Address } from './address';
import { PhoneNumber } from './phoneNumber';
import { Country } from './country';
import { StakeholderTypeEnum } from '@secca/shared/models/enums';

export class CaseStakeholder {
  id: string;
  caseId: number;
  stakeholderType: StakeholderTypeEnum;
  stakeholderTypeId: number;
  stakeholderId: number;
  person: Person;
  company: Company;
  address: Address;
  isCompany: boolean;
  phoneNumbers: PhoneNumber[];
  email: string;
  primaryContact: boolean;
  legalGuardian: boolean;
  primaryContactChangeable: boolean;
  legalGuardianChangeable: boolean;
  coTraveller: boolean;
  enduserIsPolicyholder: boolean;
  enduserIsReporter: boolean;
  reporter: boolean;
  policyholderIsReporter: boolean;
  similarStakeholders: string[];
  properties: string[];
  stakeholderTypeShowName: string;
  imageName: string;
  sortId: number;
  deactivated: boolean;
  disabled: boolean;
  deleted: boolean;
  timezone: number;
  homeCountryCode: string;
  homeCountryName: string;
  nationalCountryCode: string;
  suppliersReference: string;
  providerReference: string;

  public constructor(init?: Partial<CaseStakeholder>) {
    Object.assign(this, init);
    if (!init || !init.address) {
      this.address = new Address();
      this.address.street = '';
    }
    if (!this.address.country) {
      this.address.country = new Country();
    }
  }

  hasRole(roleName: string): boolean {
    return this.stakeholderType === roleName || (this.properties != null && this.properties.indexOf(roleName) > -1);
  }
}

@Injectable({
  providedIn: 'root',
})
export class CaseStakeholderAdapter implements Adapter<CaseStakeholder> {
  adapt(item: any): CaseStakeholder {
    const caseStakeholder = new CaseStakeholder({
      id: item.id,
      caseId: item.caseId,
      stakeholderType: item.stakeholderType,
      stakeholderTypeId: item.stakeholderTypeId,
      company: item.company != null ? item.company : null,
      person: item.person != null ? new PersonAdapter().adapt(item.person) : null,
      address: item.address,
      phoneNumbers: item.phoneNumbers,
      isCompany: item.company != null,
      email: item.company != null ? item.company?.email : item.person?.email,
      stakeholderId: item.stakeholderId,
      primaryContact: item.primaryContact,
      legalGuardian: item.legalGuardian,
      primaryContactChangeable: item.primaryContactChangeable,
      legalGuardianChangeable: item.legalGuardianChangeable,
      coTraveller: item.coTraveller,
      enduserIsPolicyholder: item.enduserIsPolicyholder,
      enduserIsReporter: item.enduserIsReporter,
      reporter: item.reporter,
      deactivated: item.deactivated,
      disabled: item.disabled,
      deleted: item.deleted,
      policyholderIsReporter: item.policyholderIsReporter,
      similarStakeholders: item.similarStakeholders,
      properties: item.properties == null ? null : item.properties,
      suppliersReference: item.suppliersReference,
      providerReference: item.providerReference
    });
    if (caseStakeholder.company != null && caseStakeholder.company.attention == null) {
      caseStakeholder.company.attention = false;
    }
    return caseStakeholder;
  }

  adaptToExisting(caseStakeholder: CaseStakeholder, update: UpdateDateOfBirthOrAgeOrGenderOrNationalId, updateNationalId: boolean = true) {
    caseStakeholder.person.dateOfBirth = update.dateOfBirth;
    caseStakeholder.person.dateOfBirthCalculated = update.dateOfBirthCalculated;
    caseStakeholder.person.age = update.age;
    if(updateNationalId) { caseStakeholder.person.nationalId = update.nationalId; };
    caseStakeholder.person.nationalIdCountry = update.nationalIdCountry;
    caseStakeholder.person.nationalIdValid = update.nationalIdValid;
    caseStakeholder.person.gender = update.gender;
    caseStakeholder.person.genderCalculated = update.genderCalculated;
    caseStakeholder.person.ssnValidated = update.ssnValidated;
    caseStakeholder.person.ssnLookupPerformed = update.ssnLookupPerformed;
    caseStakeholder.person.ssnLookupPossible = update.ssnLookupPossible;
    caseStakeholder.legalGuardian = update.legalGuardian;
    caseStakeholder.legalGuardianChangeable = update.legalGuardianChangeable;
  }
}
