<div class="handler" [ngClass]="{
  'handler-closed':handlingArea.status === getHandlerStatusEnum().CLOSED,
  'handler-nonActive':handlingArea.status === getHandlerStatusEnum().NON_ACTIVE,
  'handler-medical':handlingArea.handlingAreaType === getHandlerType().MEDICAL,
  'handler-medical-closed':handlingArea.status === getHandlerStatusEnum().CLOSED && handlingArea.handlingAreaType === getHandlerType().MEDICAL,
  'handler-medical-nonActive':handlingArea.status === getHandlerStatusEnum().NON_ACTIVE && handlingArea.handlingAreaType === getHandlerType().MEDICAL}">
  <div class="handler-image">
    <app-handler-picture
      [handlerPicture]=handlerPicture>
    </app-handler-picture>
  </div>
  <div class="team-text"
    [ngClass]="{'team-text-closed':handlingArea.status === getHandlerStatusEnum().CLOSED,
    'team-text-nonActive':handlingArea.status === getHandlerStatusEnum().NON_ACTIVE}">
    {{teamName}}
  </div>
  <div class="status-text" *ngIf="handlingArea.status !== getHandlerStatusEnum().NON_ACTIVE"
    [ngClass]="{'status-text-closed':handlingArea.status === getHandlerStatusEnum().CLOSED}" >
    {{statusName}}
  </div>
</div>