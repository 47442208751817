
import { RepatriationInstructionsBase } from './../../shared/models/medical-models/repatriation-instructions-base';
import { Injectable } from '@angular/core';
import { MedicalAssessment, MedicalAssessmentAdapter } from '@secca/shared/models/medicalAssessment';
import { BehaviorSubject } from 'rxjs';
import {
  EquipmentItemEnum,
  EscortEnum,
  ExtraSpaceEnum,
  MedicalAssessmentBasedOnEnum,
  MedicationItemEnum,
  RepatriationMediumEnum,
  WheelchairNeedEnum,
  AssessmentTypeEnum,
  RepatriationFormEnum
} from '@secca/shared/models/enums';
import { MedicalAssessmentState } from '@secca/case/components/case-medical/medical-components/medical-models';
import { RepatriationAlternativeForm } from '@secca/shared/models/medical-models/repatriation-alternative-form';
import { target } from 'on-change';
import { DateHelperService } from '@secca/core/services/date-helper.service';

@Injectable({
  providedIn: 'root'
})
export class MedicalAssessmentStateService {

  private subject: BehaviorSubject<MedicalAssessmentState> = new BehaviorSubject<MedicalAssessmentState>(new MedicalAssessmentState());
  private assessment: MedicalAssessment;

  public constructor(private medicalAssessmentAdapter: MedicalAssessmentAdapter,
    private dateHelperService: DateHelperService) {
  }

  public updateAssessment(values: MedicalAssessment) {
    this.subject.next(this.calculateAssessmentState(values));
  }

  public stateSubject(): BehaviorSubject<MedicalAssessmentState> {
    this.subject.next(new MedicalAssessmentState());
    return this.subject;
  }

  public calculateAssessmentState(values: MedicalAssessment): MedicalAssessmentState {
    this.assessment = this.medicalAssessmentAdapter.adapt(target(values));
    const state = new MedicalAssessmentState();
    this.assessment.medicalAssessmentType === AssessmentTypeEnum.REGULAR ? state.regularAssessment = true : state.regularAssessment = false;
    this.basisControl(state);
    this.notesControl(state);

    if (state.regularAssessment === true) {
      state.treatmentPrognosisValid = this.treatmentPrognosisControl();
      this.medEvacControl(state);

      this.repatriationControl(state);
      this.repatriationGeneralControl(state);
      this.repatriationFormControl(state);
      state.repatriationPrognosisValid =  this.assessment.repatriationPrognosis.repatriationForm !== RepatriationFormEnum.TOO_SOON_TO_ESTIMATE ? this.checkNotNullDeep([this.assessment.repatriationPrognosis.date,
      this.assessment.repatriationPrognosis.repatriationForm, this.assessment.repatriationPrognosis.oxygen]) : true;

      const patientState = this.assessment.repatriationInstructionsBase.patientState;
      state.repatriationIntensiveCareValid = this.checkNotNullDeep([
        patientState.breathingSupportDescription,
        patientState.medications,
        patientState.intensiveCareStep]);

      this.wheelChairControl(state);
      this.transitAdmissionControl(state);

      state.repatriationCastValid = patientState.cast != null && (patientState.cast ?
        this.checkIfAtLeastOneNotNull([patientState.castLeft, patientState.castRight]) &&
        this.checkNotNullDeep([patientState.castReadyForFlight, patientState.castDescription])
        : true);
      state.cast = Boolean(patientState.cast);

      this.oxygenControl(state);
      this.escortControl(state);
      this.equipmentMedicationControl(state);
      this.altFormsControl(state);

      state.repatriationMedicalHistoryValid = this.notNullOrBlank(patientState.medicalHistoryDescription);

      this.nextAssessmentControl(state);
    }
    this.controlsState(state);

    return state;
  }

  private basisControl(state: MedicalAssessmentState) {
    while (this.assessment.sourceList.length > 0) {
      const source = this.assessment.sourceList.pop();
      if (!!source.basedOn && (!!source.sourceName && !!source.sourceType)) {
        if (state.basisValid == null) {
          state.basisValid = true;
        }
      } else {
        state.basisValid = false;
      }

      if (!!source.basedOn && source.basedOn === MedicalAssessmentBasedOnEnum.MEDICAL_REPORT) {
        state.basisDocuments = true;
        state.basisValid = (state.basisValid || false) && this.assessment.documentList.length > 0;
      }
    }
  }

  private notesControl(state: MedicalAssessmentState) {
    state.noteValid = this.checkNotNullDeep([this.assessment.note.diagnosis, this.assessment.note.date, this.assessment.note.status]);
  }

  private treatmentPrognosisControl(): boolean {
    let result = false;
    if (this.assessment.visits != null) {
      result = this.assessment.visits.getTotal() >= 0;
    }
    if (!result && this.assessment.admissionList[0] != null) {
      result = this.assessment.admissionList[0].getTotalAdmissionDays() > 0;
    }
    if (!result && this.assessment.admissionList[1] != null) {
      result = this.assessment.admissionList[1].getTotalAdmissionDays() > 0;
    }
    return result;
  }

  private medEvacControl(state: MedicalAssessmentState) {
    if (!!this.assessment.medicalEvacuation) {
      state.medicalEvacuation = true;
      state.repatriationInstructionsAllowed = true;
      state.medEvacValid = !!this.assessment.medicalEvacuationDate;
    } else if (this.assessment.medicalEvacuation != null) {
      state.medEvacValid = true;
    } else {
      state.medEvacValid = false;
    }
  }

  private repatriationControl(state: MedicalAssessmentState) {
    let valid = this.assessment.travelHomeAsPlanned != null;

    state.notTravelHomeAsPlanned = this.assessment.travelHomeAsPlanned === false;

    if (this.assessment.travelHomeAsPlanned === false) {
      valid = valid && this.assessment.repatriationInstructionsAllowed != null;
      if (this.assessment.repatriationInstructionsAllowed) {
        state.repatriationInstructionsAllowed = true;
      } else if (this.assessment.repatriationInstructionsAllowed === false) {
        state.repatriationPrognosis = true;
      }
    }

    state.repatriationStatusValid = valid;
  }

  private repatriationGeneralControl(state: MedicalAssessmentState) {
    const repBase = this.assessment.repatriationInstructionsBase;
    state.repatriationGeneralValid = this.checkNotNullDeep([repBase.treatingDoctorName,
    repBase.treatingDoctorType]) && repBase.patientState.surgery ?
      this.checkNotNullDeep([repBase.patientState.surgeryDate, repBase.patientState.surgeryDescription]) :
      repBase.patientState.surgery != null;

    state.surgery = repBase.patientState.surgery;
  }

  private repatriationFormControl(state: MedicalAssessmentState) {
    const repForm = this.assessment.repatriationInstructionsBase.repatriationForm;

    let valid = this.checkNotNullDeep([repForm.repatriationDate, repForm.form,
    repForm.groundRepatriation, repForm.coTransport]);

    if (repForm.arrivalDeadline) {
      state.repatriationArrivalDeadline = true;
      valid = valid && (!!repForm.arrivalDescription && repForm.arrivalDescription !== '');
    }

    if (repForm.form) {
      switch (repForm.form) {
        case 'EXTRA_SPACE':
          state.extraSpace = true;
          let extraSpaceValid = !!repForm.extraSpaces && repForm.extraSpaces.length > 0;
          repForm.extraSpaces.forEach(item => {
            if (item.type === 'OTHER') {
              state.extraSpaceDesc = true;
              extraSpaceValid = extraSpaceValid && !!item.otherItemDescription;
            }
          });
          valid = valid && extraSpaceValid;
          state.altFromShownIndex = 2;
          break;
        case 'STRETCHER':
          state.stretcher = true;
          state.altFromShownIndex = 1;
          break;
        case 'INTENSIVE_CARE_PATIENT':
          state.intensiveCarePatient = true;
          valid = valid && repForm.seaLevelFlight != null;
          state.altFromShownIndex = 0;
          break;
        case 'SITTING_SICK_IN_ECONOMY_CLASS_SEAT':
          state.altFromShownIndex = 3;
          break;
        case 'REGULAR_PASSENGER':
          state.altFromShownIndex = 4;
          break;
        default:
          state.altFromShownIndex = 4;
          break;
      }
      if (repForm.form === 'INTENSIVE_CARE_PATIENT' || repForm.form === 'STRETCHER') {
        valid = valid && this.checkNotNullDeep([repForm.patientHeight, repForm.patientWeight, repForm.patientCarriableOnBoard]);

      }
    }

    if (repForm.coTransport === false) {
      valid = valid && (repForm.coTransportDescription != null && repForm.coTransportDescription !== '');
      state.coTransportDesc = true;
    }

    state.repatriationFormValid = valid;
  }

  private wheelChairControl(state: MedicalAssessmentState) {
    const patientNeeds = this.assessment.repatriationInstructionsBase.patientNeeds;
    state.repatriationWheelchairValid = !!patientNeeds.wheelchairNeed;
    state.carryToChair = !!patientNeeds.wheelchairNeed && patientNeeds.wheelchairNeed === WheelchairNeedEnum.CARRY_TO_CHAIR;
  }

  private transitAdmissionControl(state: MedicalAssessmentState) {
    const repatriationForm = this.assessment.repatriationInstructionsBase.repatriationForm;
    const patientNeeds = this.assessment.repatriationInstructionsBase.patientNeeds;
    let valid = this.checkNotNullDeep([
      repatriationForm.transportToAirport, repatriationForm.transportFromAirport,
      patientNeeds.medicalCareInTransit, patientNeeds.admissionUponArrival
    ]);

    if (patientNeeds.medicalCareInTransit) {
      state.medicalCareInTransit = true;
      valid = valid && (patientNeeds.medicalCareInTransitDescription != null && patientNeeds.medicalCareInTransitDescription !== '');
    }

    if (patientNeeds.admissionUponArrival) {
      state.admissionOnArrival = true;
      valid = valid && this.checkNotNullDeep([
        patientNeeds.ward, patientNeeds.admissionUponArrivalDescription
      ]);
    }
    state.repatriationTransitAdmissionValid = valid;
  }

  private oxygenControl(state: MedicalAssessmentState) {
    const patientNeeds = this.assessment.repatriationInstructionsBase.patientNeeds;
    let valid = this.checkNotNullDeep([patientNeeds.inFlightOxygenNeeded, patientNeeds.onGroundOxygenNeeded]);

    if (patientNeeds.inFlightOxygenNeeded) {
      const oxygen = patientNeeds.getOxygen(RepatriationMediumEnum.IN_FLIGHT);
      valid = valid && !!oxygen && this.checkNotNullDeep([oxygen.rate, oxygen.availability]);
    }

    if (patientNeeds.onGroundOxygenNeeded) {
      const oxygen = patientNeeds.getOxygen(RepatriationMediumEnum.ON_GROUND);
      valid = valid && !!oxygen && this.checkNotNullDeep([oxygen.rate, oxygen.availability]);
    }

    state.repatriationOxygenValid = valid;
  }

  private escortControl(state: MedicalAssessmentState) {
    const patientNeeds = this.assessment.repatriationInstructionsBase.patientNeeds;
    if (state.intensiveCarePatient) {
      state.escort = false;
      state.escortLockedValue = false;
      state.escortDisabled = true;
    } else if (patientNeeds.oxygen.length > 0 || state.carryToChair || state.stretcher) {
      state.escort = true;
      state.escortLockedValue = true;
      state.escortDisabled = true;
    } else {
      state.escortLockedValue = null;
      state.escortDisabled = false;
    }

    let valid = patientNeeds.escortNeeded != null;
    if (patientNeeds.escortNeeded) {
      state.escort = true;
      valid = valid && patientNeeds.escort.length > 0;
      let escort = patientNeeds.getMedicalEscort(EscortEnum.NON_MEDICAL_ESCORT);
      if (escort != null) {
        valid = valid && this.notNullOrBlank(escort.taskDescription);
      }

      escort = patientNeeds.getMedicalEscort(EscortEnum.DOCTOR);
      if (escort != null) {
        valid = valid && !!escort.specialty;
        state.equipmentMedication = true;
      }

      escort = patientNeeds.getMedicalEscort(EscortEnum.NURSE);
      if (escort != null) {
        valid = valid && !!escort.specialty;
        state.equipmentMedication = true;
      }
    }

    state.repatriationEscortValid = valid;
  }

  private equipmentMedicationControl(state: MedicalAssessmentState) {
    const patientNeeds = this.assessment.repatriationInstructionsBase.patientNeeds;
    let valid = this.checkNotNullDeep([patientNeeds.equipmentNeeded, patientNeeds.medicationNeeded]);

    if (patientNeeds.equipmentNeeded) {
      valid = valid && this.checkNotNullDeep([patientNeeds.equipment, patientNeeds.equipmentTransportMediumType]);

      state.equipment = true;
      const otherEquipment = patientNeeds.getEquipment(EquipmentItemEnum.OTHER);
      if (otherEquipment != null) {
        state.otherEquipment = true;
        valid = valid && this.notNullOrBlank(otherEquipment.otherItemDescription);
      }
    }


    if (patientNeeds.medicationNeeded) {
      valid = valid && this.checkNotNullDeep(patientNeeds.medication);
      state.medication = true;
      const otherMedication = patientNeeds.getMedication(MedicationItemEnum.OTHER);
      if (otherMedication != null) {
        state.otherMedication = true;
        valid = valid && this.notNullOrBlank(otherMedication.otherItemDescription);
      }
    }
    state.repatriationEquipmentMedicationValid = valid;
  }

  private altFormsControl(state: MedicalAssessmentState) {
    const repBase = this.assessment.repatriationInstructionsBase;
    const altFormsNeedsCheck = !this.assessment.repatriationInstructionsAllowed && state.altFromShownIndex === 4;
    state.repatriationAlternativeFormsValid = altFormsNeedsCheck ? true : this.validateAlternativeForms(repBase, state);
  }

  private validateAlternativeForms(repBase: RepatriationInstructionsBase, state: MedicalAssessmentState): boolean {
    const theIndex = state.altFromShownIndex;
    const altStretcherValid = this.simpleAlternativeCheck(repBase.alternativeFormStretcher, theIndex <= 0);
    let altExtraSpaceValid = this.simpleAlternativeCheck(repBase.alternativeFormExtraSpace, theIndex <= 1);
    const altEconomyClassValid = this.simpleAlternativeCheck(repBase.alternativeFormEconomyClass, theIndex <= 2);
    const altRegularPassengerValid = this.simpleAlternativeCheck(repBase.alternativeFormRegularPassenger, theIndex <= 3);

    if (theIndex <= 1 && repBase.alternativeFormExtraSpace.formSelected) {
      altExtraSpaceValid = altExtraSpaceValid && repBase.alternativeFormExtraSpace.extraSpaces.length > 0;
      repBase.alternativeFormExtraSpace.extraSpaces.forEach(space => {
        if (space.type === ExtraSpaceEnum.OTHER) {
          state.altExtraSpaceDesc = true;
          altExtraSpaceValid = altExtraSpaceValid && this.notNullOrBlank(space.otherItemDescription);
        }
      });
    }
    return altStretcherValid &&
    altExtraSpaceValid &&
    altEconomyClassValid &&
    altRegularPassengerValid;
  }

  private simpleAlternativeCheck(altForm: RepatriationAlternativeForm, doCheck: boolean) {
    if (!doCheck) {
      return true;
    }
    let altValid = altForm.formSelected === false;
    if (altForm.formSelected === true) {
      altValid = !!altForm.date;
    }
    return altValid;
  }

  private nextAssessmentControl(state: MedicalAssessmentState) {
    let valid = this.assessment.followupPlanned != null;
    if (this.assessment.followupPlanned) {
      state.followUp = true;
      valid = valid && !!this.assessment.followupDate;
    }

    state.nextAssessmentValid = valid;
  }

  private controlsState(state: MedicalAssessmentState) {
    if (state.regularAssessment === false) {
      state.assessmentValid = state.assessmentValid !== false &&
        state.basisValid !== false &&
        state.noteValid !== false;
    } else {
      state.assessmentValid = state.assessmentValid !== false &&
        state.basisValid !== false &&
        state.noteValid !== false &&
        state.treatmentPrognosisValid !== false &&
        state.medEvacValid !== false &&
        state.repatriationStatusValid !== false &&
        state.nextAssessmentValid !== false &&
        (state.repatriationPrognosis ? state.repatriationPrognosisValid !== false : true) &&
        (state.repatriationInstructionsAllowed ? (
          state.repatriationGeneralValid !== false &&
          state.repatriationFormValid !== false &&
          (state.intensiveCarePatient ? state.repatriationIntensiveCareValid !== false : true) &&
          state.repatriationWheelchairValid !== false &&
          state.repatriationTransitAdmissionValid !== false &&
          state.repatriationCastValid !== false &&
          state.repatriationOxygenValid !== false &&
          state.repatriationEscortValid !== false &&
          (state.equipmentMedication ? state.repatriationEquipmentMedicationValid !== false : true) &&
          state.repatriationAlternativeFormsValid !== false &&
          state.repatriationMedicalHistoryValid !== false) : true);
    }
  }

  private checkNotNullDeep(data: any[]): boolean {
    let result;
    data.every(item => {
      if (item == null || item === '') {
        result = false;
        return false;
      } else if ((result || result == null)) {
        if (item instanceof Array) {
          result = this.checkNotNullDeep(item);
        } else {
          result = true;
        }
        return true;
      }
    });
    return !!result && result;
  }

  private checkIfAtLeastOneNotNull(data: any[]): boolean {
    let result = false;
    data.every(item => {
      if (item != null && item !== '') {
        if (item instanceof Array) {
          result = this.checkIfAtLeastOneNotNull(item);
        } else {
          result = true;
        }
        return false;
      } else {
        result = false;
        return true;
      }
    });
    return result;
  }

  private notNullOrBlank(value: string): boolean {
    return value !== undefined && value !== null && value.length > 0;
  }
}
