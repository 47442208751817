import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable, catchError, of } from 'rxjs';
import { SettingsService } from './settings.service';
import { HttpClient } from '@angular/common/http';
import { RegresConfiguration } from '@secca/shared/models/regres-configuration';

@Injectable({
  providedIn: 'root'
})
export class RegresService extends BaseService {
  constructor(
    private http: HttpClient,
    private settingsService: SettingsService) {
    super(settingsService);
  }

  public getCustomerProfileRegresConfiguration(customerProfileId: number): Observable<RegresConfiguration> {
    return this.http.get<RegresConfiguration>(this.baseURL + 'regres/configuration/' + customerProfileId).pipe(
      catchError(error => {
        return of(null);
      }
    ));
  }
}
