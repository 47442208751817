import { ModalDialogConfiguration } from '../../models/modal/modal-dialog-configuration';
import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
// import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.scss']
})
export class ModalDialogComponent {
  @Input() items: string[];
  configuration: ModalDialogConfiguration;
  medicalAssessmentInDraft: boolean;
  medicalPreAssessmentInDraft: boolean;
  @Output() closeModalEvent = new EventEmitter<boolean>();
  @Output() saveandcloseEvent = new EventEmitter<string>();

  showModal = false;
  @Input() fieldText: string;

  public close(close: boolean) {
    // lig html logic ind her i stedet for i htmlen
    if (this.configuration.showTextArea && this.configuration.showTextAreaWithYesNo === true) {
      if (close === true){
        this.saveAndCloseUserInput(this.fieldText);
        this.closeModalEvent.emit(close);
      }
    }
    this.closeModalEvent.emit(close);
  }

  public saveAndCloseUserInput(text: string) {
    if (this.fieldText) {
      this.saveandcloseEvent.emit(text);
    }
  }

}
