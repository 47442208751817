import { StakeholderTypeEnum } from '@secca/shared/models/enums';

export const StakeholderSortOnType = new Map<StakeholderTypeEnum, number>([
  [StakeholderTypeEnum.endUser, 1],
  [StakeholderTypeEnum.policyHolder, 2],
  [StakeholderTypeEnum.reporter, 3],
  [StakeholderTypeEnum.person, 4],
  [StakeholderTypeEnum.hospital, 5],
  [StakeholderTypeEnum.doctorOutpatientClinic, 6],
  [StakeholderTypeEnum.dentist, 7],
  [StakeholderTypeEnum.patientsDoctorHospital, 8],
  [StakeholderTypeEnum.medicalAdvisor, 9],
  [StakeholderTypeEnum.sosServiceOffice, 10],
  [StakeholderTypeEnum.assistanceContact, 11],
  [StakeholderTypeEnum.travelAgency, 12],
  [StakeholderTypeEnum.taxi, 13],
  [StakeholderTypeEnum.hotel, 14],
  [StakeholderTypeEnum.ambulanceCompany, 15],
  [StakeholderTypeEnum.escortDoctor, 16],
  [StakeholderTypeEnum.escortNurse, 17],
  [StakeholderTypeEnum.nonMedicalEscort, 18],
  [StakeholderTypeEnum.airAmbulanceCompany, 19],
  [StakeholderTypeEnum.embassyConsulate, 20],
  [StakeholderTypeEnum.funeralCompany, 21],
  [StakeholderTypeEnum.shippingAndCargoCompany, 22],
  [StakeholderTypeEnum.crisisPsychologist, 23],
  [StakeholderTypeEnum.charterFights, 24],
  [StakeholderTypeEnum.schedulesFights, 25],
  [StakeholderTypeEnum.airline, 26],
  [StakeholderTypeEnum.airport, 27],
  [StakeholderTypeEnum.churchesAndPriests, 28],
  [StakeholderTypeEnum.pharmacy, 29],
  [StakeholderTypeEnum.lawyers, 30],
  [StakeholderTypeEnum.policeAndRescueSearch, 31],
  [StakeholderTypeEnum.billingOfficeAdministration, 32],
  [StakeholderTypeEnum.customer, 33],
  [StakeholderTypeEnum.company, 34],
]);
