import { ServiceOrderHeightEnum } from './ServiceOrderHeightEnum';
import { ServiceOrder } from '@secca/shared/models/service-order/service-order';
import * as moment from 'moment';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import _, { keyBy } from 'lodash';

export class PlanDisplayHelper {
  static readonly END_USER_LANE       = 0;
  static readonly CO_TRAVELLER_LANE   = 1;
  static readonly MEDICAL_ESCORT_LANE = 2;
  static readonly NON_VISIBLE_LANE    = 3;

  public static defaultCellHeight: number = 74;
  private serviceOrders: ServiceOrder[];
  public extendedDatesOffset = {};
  public cellHeightForDateMap = {};

  constructor(serviceOrders: ServiceOrder[]) {
    this.serviceOrders = serviceOrders;
  }

  public static sizeThatServiceOrderNeedsForDate(serviceOrder: ServiceOrder, date: Date): ServiceOrderHeightEnum {
    if (serviceOrder == null) {
      return ServiceOrderHeightEnum.Zero;
    }
    let endOfDayDate = moment(date).add(1, 'days').toDate();
    if (DateHelper.datesInTheSameDay(serviceOrder.getStartDateLocal(), date)) {
      return DateHelper.numberOfHoursBetweenDates(
        serviceOrder.getStartDateLocal(),
        serviceOrder.getEndDateLocal() > endOfDayDate ? endOfDayDate : serviceOrder.getEndDateLocal()
      ) < 12
        ? ServiceOrderHeightEnum.HalfCellHeight
        : ServiceOrderHeightEnum.FullCellHeight;
    } else if (DateHelper.datesInTheSameDay(serviceOrder.getEndDateLocal(), date)) {
      return DateHelper.numberOfHoursBetweenDates(date, serviceOrder.getEndDateLocal()) < 12
        ? ServiceOrderHeightEnum.HalfCellHeight
        : ServiceOrderHeightEnum.FullCellHeight;
    }
    return ServiceOrderHeightEnum.Zero;
  }

  public topOffsetFromPreviousExtendedDates(currentDate: Date): number {
    let result = 0;
    Object.keys(this.extendedDatesOffset).forEach(key => {
      let date = new Date(+key);
      if (date < currentDate && !DateHelper.datesInTheSameDay(date, currentDate)) {
        result += this.extendedDatesOffset[key];
      }
    });
    return result;
  }

  public bottomOffsetFromNextExtendedDates(currentDate: Date): number {
    let result = 0;
    Object.keys(this.extendedDatesOffset).forEach(key => {
      let date = new Date(+key);
      if (date >= currentDate && !DateHelper.datesInTheSameDay(date, currentDate)) {
        result += this.extendedDatesOffset[key];
      }
    });
    return result;
  }

  public calculateDateHeightAdjustForLongServices(serviceOrder: ServiceOrder): number {
    let result = 0;
    //if there are bigger cells (without the last) then we must add
    let dateIterator = new Date(serviceOrder.getStartDateLocal());
    //if the service has no overlap then we need to add one day
    if (serviceOrder.overlappingGroupId == null) {
      dateIterator = moment(dateIterator).add(1, 'days').toDate();
    }
    for (; dateIterator < serviceOrder.getEndDateLocal(); dateIterator.setDate(dateIterator.getDate() + 1)) {
      if (dateIterator.getDate() === serviceOrder.getEndDateLocal().getDate()) {
        break;
      }
      let maxHeight = 0;
      const date = new Date(dateIterator.getFullYear(), dateIterator.getMonth(), dateIterator.getDate());
    
      const entry = date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate();
      maxHeight = this.cellHeightForDateMap[entry];

      result += maxHeight - PlanDisplayHelper.defaultCellHeight;
    }
    return result;
  }

  //gets the maximum height
  public getMaximumHeightForDateCell(date: Date): number {
    let result: number = 0;
    for (let lane = 0; lane < 3; lane++) {
      let newResult = this.getHeightForDateCell(date, lane);
      if (newResult > result) {
        result = newResult;
      }
    }
    if (result > PlanDisplayHelper.defaultCellHeight) {
      this.extendedDatesOffset[new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime()] =
        result - PlanDisplayHelper.defaultCellHeight;
    }
    
    this.cellHeightForDateMap[date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate()] = result;

    return result;
  }

  // calculate the height for each date and each lane
  private getHeightForDateCell(date: Date, laneNumber: number): number {
    const defaultCellHeight = PlanDisplayHelper.defaultCellHeight / 2;

    const servicesInTheSameDay = this.getServicesInSameDay(date, laneNumber);
    const maxPos = Math.max(...servicesInTheSameDay.map(s => s.overlappingTempPosition));

    let lastCellServiceOrders = [];
    servicesInTheSameDay.forEach(serviceOrder => {

      if ( serviceOrder.coTravellersEndUserServiceId ) {
        this.setCotravellerCellPos(serviceOrder);
        return;
      }
      else if ( DateHelper.datesInTheSameDay(serviceOrder.getStartDateLocal(), date ) ) {
        serviceOrder.cellPosOffset = 0;
        if ( serviceOrder.cellHeight === 0 ) {
          serviceOrder.cellHeight = ServiceOrderHeightEnum.HalfCellHeight;
        }
      }

      // Adjust cell position to previous service orders 
      this.setServiceOrderCellPosition(serviceOrder, lastCellServiceOrders, maxPos, date);

      // Adjust cell height to previous service orders 
      lastCellServiceOrders[serviceOrder.overlappingTempPosition] = serviceOrder;

      this.setServiceOrderCellHeight(serviceOrder, lastCellServiceOrders, maxPos, date);
    });

    // Calculate the height of the last cells for each position, for cells that should fill the whole cell height
    let maxCellHeight = 0;
    for (let overlappingPos=0; overlappingPos <= maxPos; ++overlappingPos ) {
      const lastCellServiceOrderOnPos = lastCellServiceOrders[overlappingPos];
      if ( lastCellServiceOrderOnPos && !lastCellServiceOrderOnPos.coTravellersEndUserServiceId ) {
        const height = DateHelper.datesInTheSameDay(lastCellServiceOrderOnPos.getStartDateLocal(), date) ? lastCellServiceOrderOnPos.cellPosOffset + lastCellServiceOrderOnPos.cellHeight : lastCellServiceOrderOnPos.cellHeight;
        if ( height > maxCellHeight ) {
          maxCellHeight = height;
        }
      }
    }

    return maxCellHeight > 1 ? maxCellHeight * defaultCellHeight : PlanDisplayHelper.defaultCellHeight;
  }

  private getCellHeight(serviceOrder: ServiceOrder, date: Date) {
    return ServiceOrderHeightEnum.HalfCellHeight;
  }

  private getServicesInSameDay(date: Date, laneNumber: number) {
    let servicesInTheSameDay;
    if ( laneNumber === PlanDisplayHelper.END_USER_LANE ) {
      // If end-user lane then also include serviceOrders from other lanes that needs placement, ie. medical-escort outbound/return trips, that has its own date&time, need to be placed correct in relation to the end-user lane service-orders
      servicesInTheSameDay = this.serviceOrders
        .filter(a => a.laneNumber === laneNumber || a.useEndUserLaneForPlacementCalculation)
    }    
    else {
      // For other lanes, do not include serviceOrders that has already been processed
      servicesInTheSameDay = this.serviceOrders
        .filter(a => a.laneNumber === laneNumber && !a.useEndUserLaneForPlacementCalculation)
    }
    servicesInTheSameDay = servicesInTheSameDay.filter(a => DateHelper.datesInTheSameDay(a.getStartDateLocal(), date) || DateHelper.datesInTheSameDay(a.getEndDateLocal(), date));

    // Make for temp overlappingPosition for handling of the medical-escort outbound/return trips
    let laneOffset = this.getOffsetForLanePlacementServiceOrders(servicesInTheSameDay);

    let maxPosWithoutEndUserPlacement = Math.max(...servicesInTheSameDay.map(s => s.useEndUserLaneForPlacementCalculation ? 0 : s.overlappingPosition));
    servicesInTheSameDay.forEach(s => {
      s.overlappingTempPosition = s.useEndUserLaneForPlacementCalculation ? maxPosWithoutEndUserPlacement + laneOffset[this.offsetKey(s)] + 1 : s.overlappingPosition;
    });

    return servicesInTheSameDay;
  }

  private setServiceOrderCellPosition(serviceOrder: ServiceOrder, lastCellServiceOrders: ServiceOrder[], maxPos: number, date: Date) {
    if ( !lastCellServiceOrders || lastCellServiceOrders.length === 0 ) {
      return;
    }

    // Adjust cellPosOffset to previous service orders 
    maxPos = maxPos > lastCellServiceOrders.length ? lastCellServiceOrders.length : maxPos; 
    for (let overlappingPos=0; overlappingPos <= maxPos; ++overlappingPos ) {
      if ( overlappingPos >= lastCellServiceOrders.length ) {
        return;
      }

      const lastCellServiceOrderOnPos = lastCellServiceOrders[overlappingPos];
      if ( lastCellServiceOrderOnPos ) {
        const lastCellServiceOrderOnPosCellOffset =  DateHelper.datesInTheSameDay(lastCellServiceOrderOnPos.getStartDateLocal(), date ) ? lastCellServiceOrderOnPos.cellPosOffset : 0;
    
        // Make a cell starting after another ends, positioned after the other cell's end
        if ( serviceOrder.getStartDateLocal() > lastCellServiceOrderOnPos.getEndDateLocal() &&
             DateHelper.datesInTheSameDay(lastCellServiceOrderOnPos.getEndDateLocal(), date) ) {
          const cellPosOffset = lastCellServiceOrderOnPosCellOffset + lastCellServiceOrderOnPos.cellHeight;
          if ( cellPosOffset > serviceOrder.cellPosOffset ) {
            serviceOrder.cellPosOffset = cellPosOffset;
          }
        }
        // Make a cell starting after another starts, positioned after the other cell's start
        else if ( serviceOrder.getStartDateLocal() > lastCellServiceOrderOnPos.getStartDateLocal() &&
                  DateHelper.datesInTheSameDay(lastCellServiceOrderOnPos.getStartDateLocal(), date) &&
                  overlappingPos !== serviceOrder.overlappingTempPosition ) {
          const cellPosOffset = lastCellServiceOrderOnPosCellOffset + this.getCellHeight(lastCellServiceOrderOnPos, date);
          if ( cellPosOffset > serviceOrder.cellPosOffset ) {
            serviceOrder.cellPosOffset = cellPosOffset;
          }
        }
        // Make a cell starting same time as another starts, positioned on the other cell's start
        else if ( serviceOrder.getStartDateLocal().getTime() === lastCellServiceOrderOnPos.getStartDateLocal().getTime() &&
                  DateHelper.datesInTheSameDay(lastCellServiceOrderOnPos.getStartDateLocal(), date) &&
                  overlappingPos !== serviceOrder.overlappingTempPosition ) {
          const cellPosOffset = lastCellServiceOrderOnPosCellOffset;
          if ( cellPosOffset > serviceOrder.cellPosOffset ) {
            serviceOrder.cellPosOffset = cellPosOffset;
          }
        }
      }
    }    
  }

  private setServiceOrderCellHeight(serviceOrder: ServiceOrder, lastCellServiceOrders: ServiceOrder[], maxPos: number, date: Date): void {
    if ( !lastCellServiceOrders || lastCellServiceOrders.length === 0 ) {
      return;
    }
    
    // Calculate the height of the last service orders for each position
    for (let overlappingPos=0; overlappingPos <= maxPos; ++overlappingPos ) {
      if ( overlappingPos >= lastCellServiceOrders.length ) {
        return;
      }

      const lastCellServiceOrderOnPos = lastCellServiceOrders[overlappingPos];
      if ( lastCellServiceOrderOnPos &&
           overlappingPos !== serviceOrder.overlappingTempPosition &&
           serviceOrder.serviceId !== lastCellServiceOrderOnPos.serviceId &&
           DateHelper.datesInTheSameDay(lastCellServiceOrderOnPos.getEndDateLocal(), date)) {

        const serviceOrderCellOffset =  DateHelper.datesInTheSameDay(serviceOrder.getStartDateLocal(), date ) ? serviceOrder.cellPosOffset : 0;
        const serviceOrderEndPos = serviceOrderCellOffset + serviceOrder.cellHeight;

        const lastCellServiceOrderOnPosCellOffset =  DateHelper.datesInTheSameDay(lastCellServiceOrderOnPos.getStartDateLocal(), date ) ? lastCellServiceOrderOnPos.cellPosOffset : 0;
        const lastCellServiceOrderOnPosEndPos = lastCellServiceOrderOnPosCellOffset + lastCellServiceOrderOnPos.cellHeight;

        // Make a cell ending after another starts, larger than the other
        if ( lastCellServiceOrderOnPos.getEndDateLocal() >= serviceOrder.getStartDateLocal() && lastCellServiceOrderOnPosEndPos <= serviceOrderCellOffset) {
          const cellHeight = serviceOrderCellOffset - lastCellServiceOrderOnPosCellOffset + this.getCellHeight(serviceOrder, date);
          if ( cellHeight > lastCellServiceOrderOnPos.cellHeight ) {
            lastCellServiceOrderOnPos.cellHeight = cellHeight;

            this.setServiceOrderCellHeight(lastCellServiceOrderOnPos, lastCellServiceOrders, maxPos, date);
          }
        }

        // Make a cell ending after another ends, larger than the other
        if ( lastCellServiceOrderOnPos.getEndDateLocal() > serviceOrder.getEndDateLocal() && lastCellServiceOrderOnPosEndPos <= serviceOrderEndPos ) {
          const cellHeight = serviceOrderEndPos - lastCellServiceOrderOnPosCellOffset + this.getCellHeight(serviceOrder, date); 
          if ( cellHeight > lastCellServiceOrderOnPos.cellHeight ) {
            lastCellServiceOrderOnPos.cellHeight = cellHeight;

            this.setServiceOrderCellHeight(lastCellServiceOrderOnPos, lastCellServiceOrders, maxPos, date);
          }
        }
        
        // Make a cell ending after another ends, larger than the other
        if ( serviceOrder.getEndDateLocal() > lastCellServiceOrderOnPos.getEndDateLocal() && serviceOrderEndPos <= lastCellServiceOrderOnPosEndPos &&
             DateHelper.datesInTheSameDay(serviceOrder.getEndDateLocal(), date) ) {
          const cellHeight = lastCellServiceOrderOnPosEndPos - serviceOrderCellOffset + this.getCellHeight(lastCellServiceOrderOnPos, date);
          if ( cellHeight > serviceOrder.cellHeight ) {
            serviceOrder.cellHeight = cellHeight;

            this.setServiceOrderCellHeight(serviceOrder, lastCellServiceOrders, maxPos, date);
          }
        }
      }
    }
  }
  
  private setCotravellerCellPos(coTravellerServiceOrder: ServiceOrder): void {
    let endUser = this.serviceOrders.find(s => s.serviceId === coTravellerServiceOrder.coTravellersEndUserServiceId);
    if (endUser) {
      coTravellerServiceOrder.cellPosOffset = endUser.cellPosOffset;
      coTravellerServiceOrder.cellHeight = endUser.cellHeight;
    }    
  }

  private getOffsetForLanePlacementServiceOrders(servicesInTheSameDay: ServiceOrder[]) {
    let index = 0;
    let offset = [];
    servicesInTheSameDay.filter(s => s.useEndUserLaneForPlacementCalculation).forEach(s =>
      offset[s.laneNumber+':'+s.overlappingPosition] = offset[this.offsetKey(s)] || index++
    );

    return offset;
  }

  private offsetKey(s: ServiceOrder): string {
    return s.laneNumber+':'+s.overlappingPosition;
  }

  public getHeightForCurrentDay(): number {
    let today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    return (
      PlanDisplayHelper.defaultCellHeight +
      (this.extendedDatesOffset[today.getTime()] === undefined ? 0 : this.extendedDatesOffset[today.getTime()])
    );
  }  
}
