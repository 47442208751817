import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { FinancialStatusEnum, HandlingAreaStatusEnum, PermissionEnum, PermissionHideTypeEnum } from '@secca/shared/models/enums';
import { DisableableDropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FinancialStatusService } from '@secca/core/services/financial-status.service';
import { FinancialStatus } from '@secca/shared/models/financial-status';
import { take } from 'rxjs/operators';
import { HandlingAreaService } from '@secca/core/services/handling-area.service';

@Component({
  selector: 'app-case-economy-customer-invoices-financial-status',
  templateUrl: './financial-status.component.html',
  styleUrls: ['./financial-status.component.scss'],
})
export class FinancialStatusComponent {
  _caseId: string;
  financialStatusList = this.getFinancialStatusList();
  commentModalRef: NgbModalRef;
  @ViewChild('commentModalTemplate') private commentModalTemplate: TemplateRef<any>;
  enteredComment: string;
  status: FinancialStatusEnum;
  financialStatus: FinancialStatus = null;

  constructor(
    private financialStatusService: FinancialStatusService,
    private handlingAreaService: HandlingAreaService,
    private modalService: NgbModal
  ) {}

  @Input()
  set caseId(id: string) {
    this.setStatus(null);
    this._caseId = id;
    if (this._caseId) {
      this.financialStatusService
        .getStatus(this._caseId)
        .pipe(take(1))
        .subscribe(result => this.financialStatusService.sendCaseFinancialStatus(result));
      this.financialStatusService.getCaseFinancialStatus().subscribe(result => this.setStatus(result));
      this.handlingAreaService.getCaseHandlingsAreas().subscribe(result => {
        if (!!result) {
          const notAllClosed = result.filter(h => h.status !== HandlingAreaStatusEnum.CLOSED);
          if (notAllClosed && notAllClosed.length > 0) {
            this.financialStatusList.find(item => item.id === FinancialStatusEnum.INVOICED_AND_CLOSED).disabled = true;
            this.financialStatusList.find(item => item.id === FinancialStatusEnum.CLOSED_NO_INVOICE).disabled = true;
          } else {
            this.financialStatusList.forEach(item => (item.disabled = false));
          }
        }
      });
    }
  }

  private setStatus(fs: FinancialStatus) {
    this.financialStatus = fs;
    this.updateComponents(fs);
  }

  private updateComponents(fs: FinancialStatus) {
    if (!fs) {
      this.enteredComment = null;
      this.status = null;
    } else {
      this.enteredComment = fs.comment;
      this.status = fs.status;
    }
  }

  getFinancialStatusList(): DisableableDropdownDictionary[] {
    return Object.keys(FinancialStatusEnum).map(key => new DisableableDropdownDictionary(key, key, false));
  }

  handleStatusChange(selectedStatus) {
    if ((this.status == FinancialStatusEnum.CLOSED_NO_INVOICE && selectedStatus == FinancialStatusEnum.ON_HOLD_AWAITING_INFO) ||
        (this.status == FinancialStatusEnum.ON_HOLD_AWAITING_INFO && selectedStatus == FinancialStatusEnum.CLOSED_NO_INVOICE)) {
         this.enteredComment = "";
       } 
    this.status = selectedStatus;
    if (selectedStatus === FinancialStatusEnum.CLOSED_NO_INVOICE || selectedStatus === FinancialStatusEnum.ON_HOLD_AWAITING_INFO) {

      this.commentModalRef = this.modalService.open(this.commentModalTemplate, { backdrop: 'static', keyboard: false });
    } else {
      this.status = selectedStatus;
      this.updateStatus(selectedStatus, null);
    }
  }

  handleCommentAdded() {
    this.commentModalRef.close();
    this.updateStatus(this.status, this.enteredComment);
  }

  handleCommentDiscarded() {
    this.updateComponents(this.financialStatus);
    this.commentModalRef.close();
  }

  updateStatus(status: FinancialStatusEnum, comment: string) {
    this.financialStatusService.addOrUpdate(this._caseId, new FinancialStatus({ status, comment })).subscribe(
      result => this.emitNewStatus(result),
      error => {
        this.emitNewStatus(this.financialStatus);
        throw error;
      }
    );
  }

  private emitNewStatus(fs: FinancialStatus): void {
    this.financialStatusService.sendCaseFinancialStatus(fs);
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  get PermissionHideTypeEnum() {
    return PermissionHideTypeEnum;
  }
  
  get FinancialStatusEnum () {
    return FinancialStatusEnum; 
  }
}
