<div class="container">
  <div class="containerItem" *ngFor="let item of [].constructor(maxStatus); let i = index">
    <div
      class="line"
      [ngClass]="{
        firstline: i === prefixWithHidden,
        current: i + 1 === statusIndex && i + 1 !== getNumberOfStatus() && !aggregatedStatus,
        yellowline: i + 1 === statusIndex && i + 1 !== getNumberOfStatus() && aggregatedStatus,
        yellowline: i + 1 <= statusIndex && i + 1 !== getNumberOfStatus() && aggregatedStatus,
        lastline: i + 1 >= getNumberOfStatus(),
        grayline: i + 1 > statusIndex || cancelled,
        'hidden': i + 1 > getNumberOfStatus() || i < prefixWithHidden
      }"
    ></div>

    <ng-template #popTitle>
      {{ 'economy-popover-content-poptitle-' + histotyType | translate }}: {{ invoiceNumber }}
    </ng-template>
    <ng-template #popContent>
      <div class="popover-content">
        <div class="popover-content-label">{{ 'case-economy-sos-services-status' | translate }}</div>
        <div *ngIf="histotyType === BalanceSheetEntryEnum.REFUND" class="popover-content-text-last">{{ 'case-economy-refund-refund-status-' + popOverGetStatus(i + 1) | translate }}</div>
        <div *ngIf="histotyType === BalanceSheetEntryEnum.SUPPLIER_INVOICE" class="popover-content-text-last">{{ 'economy-supplier-invoice-status-' + popOverGetStatus(i + 1) | translate }}</div>
        <div *ngFor="let modified of popOverGetModifiedInfo(i + 1)">
          <div class="popover-content-separator"></div>
          <div class="popover-content-label">Changed</div>
          <div class="popover-content-text">{{ modified.modifiedOn | momentFormat: 'DD MMM YYYY HH:mm':'local' }}</div>
          <div class="popover-content-label">Changed by</div>
          <div class="popover-content-text-last">{{ modified.modifiedBy }}</div>
        </div>
      </div>
    </ng-template>

    <div
      class="icon"
      [ngClass]="{
        success: i + 1 <= statusIndex && !aggregatedStatus,
        cancelled: cancelled || notCovert,
        'success-yellow': i + 1 <= statusIndex && aggregatedStatus,
        'empty-gray-ring': i + 1 > statusIndex,
        failure: i + 1 === redIconIndex,
        warn: i + 1 === warnIconIndex,
        'hidden': i + 1 > getNumberOfStatus() || i < prefixWithHidden
      }"
      [ngbPopover]="popContent"
      [popoverTitle]="popTitle"
      [disablePopover]="popOverDisabled(i + 1)"
      placement="right-top right-bottom"
      triggers="hover"
      openDelay="300"
    ></div>
    <div class="text" [ngClass]="{firstlabel: i === 0, lastlabel: i + 1 === maxStatus }" *ngIf="i + 1 === statusIndex && i + 1 !== redIconIndex && i + 1 !== warnIconIndex">{{ (!cancelled && !notCovert) || i >= 2 ? statusText : getNotCovertAndCancelledTest() }}</div>
    <div class="text" *ngIf="i + 1 === redIconIndex">{{ redIconText }}</div>
    <div class="text" *ngIf="i + 1 === warnIconIndex">{{ redIconText }}</div>
  </div>
</div>
