import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {DatesRange} from '../models/DatesRange';

@Injectable({
  providedIn: 'root'
})
export class TaskBoardDatesRangeService {

  private dateSource = new BehaviorSubject<DatesRange>(undefined);

  public setDatesRange(date: DatesRange) {
    this.dateSource.next(date);
  }

  public getDate(): Observable<DatesRange> {
    return this.dateSource.asObservable();
  }

}
