import { DateSerializer } from '@secca/shared/models/date-serializer';
import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
import * as moment from 'moment';
import { Coverage, CoverageAdapter } from '@secca/shared/models/onepoint-coverage';
import { DaySerializer } from '@secca/shared/models/day-serializer';

export class PolicyLookupResponse {
  profileId: string;
  profileContactInfo: string;
  results?: PolicyLookup[];
  error?: { type?: string; message?: string };
  executions: LookupExecution[];

  public constructor(init?: Partial<PolicyLookupResponse>) {
    Object.assign(this, init);
  }
}

export class PolicyLookup {
  product: PolicyProduct;
  policyNumber?: string;
  insurancePeriodStart?: moment.Moment;
  insurancePeriodEnd?: moment.Moment;
  extensions?: PolicyExtension[];
  insuranceLevel?: string;
  insuranceType?: PolicyInsuranceType;
  percentagePaidWithCard?: number;
  customerId?: string;
  sosId?: string;
  supplements?: PolicySupplement[];
  exclusions?: string[];
  excess?: PolicyExcess[];
  policyHolder: PolicyPerson;
  coInsured?: string;
  coveredPersons?: PolicyPerson[];
  specialRemarks?: string[];
  possibleMatches: PossibleMatches[];
  matchQuality: 'SUCCESS' | 'PARTIAL' | 'NONE';
  lookupExecution: LookupExecution;
  customerContactInfo: string;
  geographicalCoverageArea: string;

  public constructor(init?: Partial<PolicyLookup>) {
    Object.assign(this, init);
  }
}

export class PolicyProduct {
  id: number;
  productId: number;
  profileId: number;
  customerProductName: string;
  customerProductId: string;
  sosProductId: string;
  coveragePeriodWeeks?: number;
  coveragePeriodDays?: number;
  coveragePeriodUnit: string;
  coveragePeriodValue: number;
  coveragePeriodFromLookup: boolean;
  geographicAreas: GeographicAreas[];
  primaryPurposeOfTravel: string;
  secondaryPurposesOfTravel: string[];
  coverages: Coverage[];
  version: string;
  termsName: string;
  termsDocumentId: string;
  termsSelectedBy: string;
  guidelineName: string;
  guidelineUri: string;

  public constructor(init?: Partial<PolicyProduct>) {
    Object.assign(this, init);
  }
}

export class GeographicAreas {
  id: number;
  name: string;

  public constructor(init?: Partial<GeographicAreas>) {
    Object.assign(this, init);
  }
}

export class PolicyExtension {
  extensionPeriodStart?: moment.Moment;
  extensionPeriodEnd?: moment.Moment;
  extensionPeriodInDays?: number;
  extensionPeriodInWeeks?: number;

  public constructor(init?: Partial<PolicyExtension>) {
    Object.assign(this, init);
  }
}

export class PolicySupplement {
  supplementDescription: string;
  supplementPeriodStart?: moment.Moment;
  supplementPeriodEnd?: moment.Moment;
  supplementInsuranceSum: number;
  supplementInsuranceCurrency?: string;

  public constructor(init?: Partial<PolicySupplement>) {
    Object.assign(this, init);
  }
}

export class PolicyExcess {
  coverageId: string;
  excessAmount: number;

  public constructor(init?: Partial<PolicyExcess>) {
    Object.assign(this, init);
  }
}

export class PolicyPerson {
  firstName?: string;
  lastName?: string;
  addressCareOf?: string;
  streetName?: string;
  streetNumber?: string;
  streetNumberLetter?: string;
  apartmentFloor?: string;
  apartmentSide?: string;
  apartmentDoor?: string;
  postalCode?: string;
  city?: string;
  residentCountry?: string;
  citizenshipCountry?: string;
  ssn?: string;
  email?: string;
  phoneNumbers?: { type: string; number: number }[];
  personType?: string;
  gender?: string;

  public constructor(init?: Partial<PolicyPerson>) {
    Object.assign(this, init);
  }
}

export class PossibleMatches {
  parameter: string;
  quality: MatchQuality;

  public constructor(init?: Partial<PossibleMatches>) {
    Object.assign(this, init);
  }
}

export enum MatchQuality {
  SUCCESS = 'SUCCESS',
  PARTIAL = 'PARTIAL',
  NONE = 'NONE',
}

export class PolicyCoverage {
  coverageName: string;
  customerCoverageId: string;
  limits?: Limit[];

  public constructor(init?: Partial<PolicyCoverage>) {
    Object.assign(this, init);
  }
}

export class Limit {
  limitId?: string;
  limitType: LimitType;
  limitCurrency?: string;
  limitValue: number;

  public constructor(init?: Partial<Limit>) {
    Object.assign(this, init);
  }
}

export enum LimitType {
  REASONABLE,
  AMOUNT,
  NUMBER_OF_ADULTS,
  NUMBER_OF_CHILDREN,
  NUMBER_OF_CHILDREN_AND_ADULTS,
  DURATION_FROM_INCIDENT_IN_HOURS,
  DURATION_FROM_INCIDENT_IN_DAYS,
  DURATION_FROM_INCIDENT_IN_MONTHS,
  NUMBER_OF_TREATMENTS,
  AGE_MINIMUM,
  AGE_MAXIMUM,
}

export enum PolicyLookupMethodType {
  SECCA_POLICY_LOOKUP = 'SECCA_POLICY_LOOKUP',
}

export enum PolicyInsuranceType {
  REGULAR = 'REGULAR',
  CARD = 'CARD',
}

export enum LookupExecutionErrorType {
  UNEXPECTED_ERROR = 'UNEXPECTED_ERROR',
  SERVICE_CALL = 'SERVICE_CALL',
  LOOKUP_ERROR = 'LOOKUP_ERROR'
}

export class LookupExecution {
  lookupTime: moment.Moment;
  type: PolicyLookupMethodType;
  sourceInfo: string;
  matchedBy: string;
  error: LookupExecutionError;

  public constructor(init?: Partial<LookupExecution>) {
    Object.assign(this, init);
  }
}

export class LookupExecutionError {
  type: LookupExecutionErrorType;
  message: string;
  params: any;
  products: LookupExecutionProduct[];

  public constructor(init?: Partial<LookupExecutionError>) {
    Object.assign(this, init);
  }
}

export class LookupExecutionProduct {
  productId: number;
  productName: string;
  profileId: string;

  public constructor(init?: Partial<LookupExecutionProduct>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class PolicyLookupResponseAdapter implements Adapter<PolicyLookupResponse> {
  constructor(private coverageAdapter: CoverageAdapter) {}

  adapt(item: any): PolicyLookupResponse {
    return new PolicyLookupResponse({
      profileId: item.profileId,
      results: this.adaptPolicyLookups(item.policies),
      error: item.error,
      profileContactInfo: item.profileContactInfo,
      executions: this.adaptLookupExecutions(item.executions)
    });
  }

  adaptPolicyLookups(items: any[]): PolicyLookup[] {
    const policies = [];
    if (items != null) {
      items.forEach(item => {
        const policy: any = item.policy;
        return policies.push(
          new PolicyLookup({
            product: this.adaptProduct(policy.product),
            policyNumber: policy.policyNumber,
            insurancePeriodStart: DaySerializer.deserialize(policy.insurancePeriodStart),
            insurancePeriodEnd: DaySerializer.deserialize(policy.insurancePeriodEnd),
            insuranceType: (PolicyInsuranceType as any)[policy.insuranceType],
            percentagePaidWithCard: policy.percentagePaidWithCard,
            customerId: policy.customerId,
            sosId: policy.sosId,
            extensions: this.adaptExtensions(policy.extensions),
            insuranceLevel: policy.insuranceLevel,
            supplements: this.adaptSupplements(policy.coverages),
            exclusions: policy.exclusions,
            excess: this.adaptExcesses(policy.excess),
            policyHolder: this.adaptPerson(policy.policyHolder),
            coInsured: policy.coInsured,
            coveredPersons: this.adaptPersons(policy.coveredPersons),
            specialRemarks: policy.specialRemarks,
            matchQuality: item.matchQuality,
            possibleMatches: this.adaptMatches(item.possibleMatches),
            lookupExecution: this.adaptLookupExecution(policy.lookupExecution),
            customerContactInfo: policy.customerContactInfo,
            geographicalCoverageArea: policy.geographicalCoverageArea
          })
        );
      });
    }
    return policies;
  }

  adaptProduct(item: any): PolicyProduct {
    return new PolicyProduct({
      id: item.id,
      customerProductName: item.customerProductName,
      customerProductId: item.customerProductId,
      productId: item.productId,
      profileId: item.profileId,
      sosProductId: item.sosProductId,
      coveragePeriodWeeks: item.coveragePeriodWeeks,
      coveragePeriodDays: item.coveragePeriodDays,
      coveragePeriodValue: item.coveragePeriodValue,
      coveragePeriodUnit: item.coveragePeriodUnit,
      coveragePeriodFromLookup: item.coveragePeriodFromLookup,
      geographicAreas: this.adaptGeographicAreas(item.geographicAreas),
      primaryPurposeOfTravel: item.primaryPurposeOfTravel,
      secondaryPurposesOfTravel: item.secondaryPurposeOfTravel,
      coverages: item.coverages == null ? null : item.coverages.map(c => this.coverageAdapter.adapt(c)),
      version: item.version,
      termsName: item.termsName,
      termsDocumentId: item.termsDocumentId,
      termsSelectedBy: item.termsSelectedBy,
      guidelineName: item.guidelineName,
      guidelineUri: item.guidelineUri
    });
  }

  adaptGeographicAreas(items: any[]): GeographicAreas[] {
    const area = [];
    if (items != null) {
      items.forEach(e => {
        area.push(
          new GeographicAreas({
            id: e.id,
            name: e.name
          })
        );
      });
    }
    return area;
  }

  adaptExtensions(items: any[]): PolicyExtension[] {
    const extensions = [];
    if (items != null) {
      items.forEach(e => {
        extensions.push(
          new PolicyExtension({
            extensionPeriodStart: DaySerializer.deserialize(e.extensionPeriodStart),
            extensionPeriodEnd: DaySerializer.deserialize(e.extensionPeriodEnd),
            extensionPeriodInDays: e.extensionPeriodInDays,
            extensionPeriodInWeeks: e.extensionPeriodInWeeks
          })
        );
      });
    }
    return extensions;
  }

  adaptSupplements(items: any[]): PolicySupplement[] {
    let supplements: PolicySupplement[];
    if (items != null) {
      supplements = [];
      items.forEach(s => {
        supplements.push(
          new PolicySupplement({
            supplementDescription: s.supplementDescription,
            supplementPeriodStart: DaySerializer.deserialize(s.supplementPeriodStart),
            supplementPeriodEnd: DaySerializer.deserialize(s.supplementPeriodEnd),
            supplementInsuranceSum: s.supplementInsuranceSum,
            supplementInsuranceCurrency: s.supplementInsuranceCurrency
          })
        );
      });
    }
    return supplements;
  }

  adaptExcesses(items: any[]): PolicyExcess[] {
    const excesses = [];
    if (items != null) {
      items.forEach(e => {
        excesses.push(
          new PolicyExcess({
            coverageId: e.coverageId,
            excessAmount: e.excessAmount
          })
        );
      });
    }
    return excesses;
  }

  adaptPerson(item: any): PolicyPerson {
    if (item == null) {
      return null;
    }
    return new PolicyPerson({
      firstName: item.firstName,
      lastName: item.lastName,
      addressCareOf: item.addressCareOf,
      streetName: item.streetName,
      streetNumber: item.streetNumber,
      streetNumberLetter: item.streetNumberLetter,
      apartmentFloor: item.apartmentFloor,
      apartmentSide: item.apartmentSide,
      apartmentDoor: item.apartmentDoor,
      postalCode: item.postalCode,
      city: item.city,
      residentCountry: item.residentCountry,
      citizenshipCountry: item.citizenshipCountry,
      ssn: item.ssn,
      email: item.email,
      phoneNumbers: this.adaptPhoneNumbers(item.phoneNumbers),
      personType: item.personType,
      gender: item.gender,
    });
  }

  adaptMatches(items: any[]): PossibleMatches[] {
    const matches = [];
    if (items != null) {
      items.forEach(m => {
        matches.push(
          new PossibleMatches({
            parameter: m.parameter,
            quality: MatchQuality['' + m.quality]
          })
        );
      });
    }
    return matches;
  }

  adaptLookupExecutionProducts(items: any[]): LookupExecutionProduct[] {

    const products = [];
    if (items) {
      items.forEach(item => {
        products.push(new LookupExecutionProduct({
          productId: item.productId,
          productName: item.productName,
          profileId: item.profileId
        }));
      });
    }
    return products;
  }

  adaptLookupExecutionError(item: any): LookupExecutionError {
    if (item == null) {
      return null;
    }
    return new LookupExecutionError({
      type: item.type,
      message: item.message,
      params: item.params,
      products: this.adaptLookupExecutionProducts(item.products)
    });
  }

  adaptLookupExecutions(items: any[]): LookupExecution[] {
    const executions = [];
    if (items) {
      items.forEach(item => {
        executions.push(this.adaptLookupExecution(item));
      });
    }
    return executions;
  }

  adaptLookupExecution(item: any): LookupExecution {
    return new LookupExecution({
      lookupTime: DateSerializer.deserialize(item.lookupTime),
      type: item.type,
      sourceInfo: item.sourceInfo,
      matchedBy: item.matchedBy,
      error: this.adaptLookupExecutionError(item.error)
    });
  }

  adaptPersons(items: any[]): PolicyPerson[] {
    return items ? items.map(item => this.adaptPerson(item)) : null;
  }

  adaptCoverages(items: any[]): PolicyCoverage[] {
    const coverages = [];
    if (items != null) {
      items.forEach(c => {
        coverages.push(
          new PolicyCoverage({
            coverageName: c.coverageName,
            customerCoverageId: c.customerCoverageId,
            limits: this.adaptLimits(c.limits)
          })
        );
      });
    }
    return coverages;
  }

  adaptPhoneNumbers(items: any[]): { type: string; number: number }[] {
    const numbers = [];
    if (items) {
      items.forEach(n => {
        numbers.push({ type: n.type, number: n.number });
      });
    }
    return numbers;
  }

  adaptLimits(items: any[]): Limit[] {
    const limits = [];
    if (items != null) {
      items.forEach(l => {
        limits.push(
          new Limit({
            limitId: l.limitId,
            limitType: LimitType['' + l.limitType],
            limitCurrency: l.limitCurrency,
            limitValue: l.limitValue
          })
        );
      });
    }
    return limits;
  }
}

