import {Injectable} from '@angular/core';
import {Adapter} from '@secca/shared/interfaces/adapter';

export class RecoveryError {
  id: string;
  code: string;
  cause: string;
  text: string;
  actionText: string;
  action: string;

  public constructor(init?: Partial<RecoveryError>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class RecoveryErrorAdapter implements Adapter<RecoveryError> {
  adapt(item: any): RecoveryError {
    return new RecoveryError({
      id: item.id,
      code: item.code,
      cause: item.cause,
      text: item.text,
      actionText: item.actionText,
      action: item.action
    });
  }
}
