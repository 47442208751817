import { CaseIncident } from 'src/app/shared/models/caseIncident';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { CaseIncidentIdWithCaseId } from '@secca/shared/models/caseIncidentWithCaseId';

@Injectable({
  providedIn: 'root'
})
export class IncidentStateService {
  private incident = new BehaviorSubject<CaseIncidentIdWithCaseId>(undefined);
  constructor() {}

  public sendIncident(newIndicent: CaseIncident, caseId: string) {
    this.incident.next({caseId, incident: newIndicent});
  }

  public getIncident(): Observable<CaseIncidentIdWithCaseId> {
    return this.incident.asObservable();
  }
}
