import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-linked-coordination-case-modal',
  templateUrl: './linked-coordination-case-modal.component.html',
  styleUrls: ['./linked-coordination-case-modal.component.scss']
})
export class LinkedCoordinationCaseModalComponent {
  @Output() linkCaseEvent = new EventEmitter<string>();
  @Output() closeModal = new EventEmitter<string>();
  @Input() caseNumberOnCase: string;
  @Input() existingLinkedCaseNumbersOnCase: string[] = [];

  caseNumber = '';
  isCaseNumberValid = true;
  moveInProgress = false;
  private caseNumberRegex: RegExp = /[A-Z]{4}(-\d{3}){3}/;

  setDirty() {
    this.isCaseNumberValid = true;
    this.caseNumber = this.caseNumber?.toUpperCase();
  }

  linkCase() {
    this.linkCaseEvent.emit(this.caseNumber);
  }

  close() {
    this.closeModal.emit();
  }

  isValidCaseNumber(): boolean {
    return this.caseNumberRegex.test(this.caseNumber) &&
      this.isCaseNumberValid &&
      (!this.existingLinkedCaseNumbersOnCase.includes(this.caseNumber.trim())) &&
      (this.caseNumber.trim() !== this.caseNumberOnCase) &&
      this.caseNumber.length <= 16;
  }
}
