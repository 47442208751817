import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EndUserStateService {
  private user = new BehaviorSubject<CaseStakeholder>(undefined);

  constructor() {}

  public sendEndUser(newUser: CaseStakeholder) {
    this.user.next(newUser);
  }

  public getEndUser(): Observable<CaseStakeholder> {
    return this.user.asObservable();
  }

}
