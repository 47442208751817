<ul ngbNav #nav="ngbNav" class="service-order-type-tabset" [(activeId)]="activeTab" (navChange)="tabChange($event)" class="nav-tabs">
  <li id="{{SelectedTab.ExpectationTab}}" [ngbNavItem]="SelectedTab.ExpectationTab" >
    <a ngbNavLink>{{"service-type-expectation" | translate}}</a>
    <ng-template ngbNavContent>
      <div [appDisableInput]="caseLocked || serviceOrderTypeState.isLockedByCoordinationTransport" class="caselock-no-events">
        <div class="sub-component-placeholder">
          <app-sub-commitment-type [readOnlyMode]="isCommitted()"
            [serviceOrderType]="serviceOrderType"
            [(commitmentType)]="ambulanceFlight.commitmentType"
                                  [caseId]="caseId"
          ></app-sub-commitment-type>
        </div>
        <div class="sub-component-placeholder">
          <app-sub-repatriation-form-ambulance-flight
            [caseId]="caseId"
            [subRepatriationFormAmbulanceFlight]="ambulanceFlight.repatriationFormAmbulanceFlight"
            [subRepatriationDetails]="ambulanceFlight.repatriationDetails"
            [readOnlyMode]="isCommitted()"
          ></app-sub-repatriation-form-ambulance-flight>
        </div>
        <div class="sub-component-placeholder">
          <app-sub-travellers
            [caseId]="caseId"
            [(travellers)]="ambulanceFlight.subGroundTransportToFlight"
            [stakeholders]="stakeholders"
            [(subStakeholders)]="ambulanceFlight.subGroundTransportToFlightStakeholders"
            [subStakeholdersConfig]="stakeholdersConfig"
            [resourceConfig]="groundAmbulanceToFlightResourceConfig"
            [hideCabinClass]="true"
            [hideRemarks]="false"
            [hideFlightInRefs]="true"
            [hideAddFlightLeg]="true"
            [departureDateRequired]="false"
            [departureFromRequired]="false"
            [arrivalDateRequired]="false"
            [arrivalAtRequired]="false"
            [readOnlyMode]="ambulanceFlight.groundTransportToFlightNotIncluded || isCommitted()"
            [remarksReadOnlyMode]="ambulanceFlight.groundTransportToFlightNotIncluded || isCommitted()"
            [disableStakeholders]="isCommitted()"
            [preselectUser]="true"
          >
          <span class="float-end not-organized">{{ "ambulance-flight-ground-ambulance-not-organized" | translate}}
            <input [disabled]="isCommitted()" type="checkbox" #checkbox1 (click)="toFlightNotIncluded(checkbox1.checked)" [(ngModel)]="ambulanceFlight.groundTransportToFlightNotIncluded" /></span>
          </app-sub-travellers>
        </div>
        <div class="sub-component-placeholder">
          <app-sub-travellers
            [caseId]="caseId"
            [(travellers)]="ambulanceFlight.subFlight"
            [stakeholders]="stakeholders"
            [(subStakeholders)]="ambulanceFlight.subFlightStakeholders"
            [subStakeholdersConfig]="stakeholdersConfig"
            [resourceConfig]="flightResourceConfig"
            [hideCabinClass]="true"
            [hideFlightInRefs]="true"
            [hideAddFlightLeg]="true"
            [departureDateRequired]="true"
            [departureFromRequired]="true"
            [arrivalDateRequired]="false"
            [arrivalAtRequired]="true"
            [preselectUser]="true"
            [readOnlyMode]="isCommitted()"
            [remarksReadOnlyMode]="isCommitted()"
            [disableStakeholders]="isCommitted()"
          >
          </app-sub-travellers>
        </div>
        <div class="sub-component-placeholder">
          <app-sub-travellers
            [caseId]="caseId"
            [(travellers)]="ambulanceFlight.subGroundTransportFromFlight"
            [stakeholders]="stakeholders"
            [(subStakeholders)]="ambulanceFlight.subGroundTransportFromFlightStakeholders"
            [subStakeholdersConfig]="stakeholdersConfig"
            [resourceConfig]="groundAmbulanceFromFlightResourceConfig"
            [hideCabinClass]="true"
            [hideFlightInRefs]="true"
            [hideAddFlightLeg]="true"
            [departureDateRequired]="false"
            [departureFromRequired]="false"
            [arrivalDateRequired]="false"
            [arrivalAtRequired]="false"
            [readOnlyMode]="ambulanceFlight.groundTransportFromFlightNotIncluded || isCommitted()"
            [remarksReadOnlyMode]="ambulanceFlight.groundTransportFromFlightNotIncluded || isCommitted()"
            [disableStakeholders]="isCommitted()"
            [preselectUser]="true"
          >
          <span class="float-end not-organized">{{ "ambulance-flight-ground-ambulance-not-organized" | translate}}
            <input [disabled]="isCommitted()" type="checkbox" #checkbox2 [(ngModel)]="ambulanceFlight.groundTransportFromFlightNotIncluded" (click)="fromFlightNotIncluded(checkbox2.checked)"/></span>
          </app-sub-travellers>
        </div>
        <div class="sub-component-placeholder">
          <app-sub-remarks-to-supplier [readOnlyMode]="isCommitted()" [(subRemarksToSupplier)]="ambulanceFlight.remarksToSupplier"></app-sub-remarks-to-supplier>
        </div>
        <div class="sub-component-placeholder row">
          <div class="col-12">
            <button class="primary-button float-end" (click)="sendRequest()" [disabled]="!enableSendRequest() || isCommitted()">{{'ambulance-flight-send-request-or-confirmation' | translate}}</button>
          </div>
        </div>
      </div>
    </ng-template>
  </li>
  <ng-container *ngIf="iccServiceOrder">
    <li *ngFor='let coordinationTransportServiceOrderExtension of coordinationServiceOrderExtensions' id="coordinationTransportServiceOrderExtension.serviceOrderId" [ngbNavItem]="coordinationTransportServiceOrderExtension.serviceOrderId" >
      <a style="font-size: 12px; line-height: 12px; padding-top: 3px;" ngbNavLink>{{linkedEndUserCaseDetail?.caseNumber}}<br/>{{ linkedEndUser?.person.firstName }} {{ linkedEndUser?.person.surname }}</a>
      <ng-template ngbNavContent>
        <div [appDisableInput]="caseLocked || serviceOrderTypeState.isLockedByCoordinationTransport" >
          <app-end-user-linked-case-component
            *ngIf="linkedEndUserCaseDetail"
            [endUserDisplayReadOnly]='caseLocked || serviceOrderTypeState.isLockedByCoordinationTransport'
            [caseId]='caseId'
            [endUserCaseDetail]='linkedEndUserCaseDetail'
            [coordinationTransportServiceOrderExtension]='coordinationTransportServiceOrderExtension'
            [subStakeholdersConfig]="linkedEndUserSubStakeholdersConfig"
            [serviceOrderType]='serviceOrderType'
            [status]='status'
          >
          </app-end-user-linked-case-component>
        </div>

      </ng-template>
    </li>
  </ng-container>
  <li [id]="SelectedTab.ConfirmationTab" *ngIf="serviceOrderTypeState.showConfirmationTab && !iccServiceOrder" [ngbNavItem]="SelectedTab.ConfirmationTab">
    <a ngbNavLink>{{"service-type-confirmation" | translate}}<div class="close-tab-x" (click)="closeConfirmationTab($event)"
        *ngIf="!serviceOrderTypeState.isConfirmationTabSaved"><img src="/assets/icons/Close Black.svg"></div>
    </a>
        <ng-template ngbNavContent>
        <div [appDisableInput]="caseLocked || serviceOrderTypeState.isLockedByCoordinationTransport" class="caselock-no-events">
          <div class="sub-component-placeholder">
            <app-sub-travellers
              [caseId]="caseId"
              [(travellers)]="ambulanceFlight.subConfirmationGroundTransportToFlight"
              [stakeholders]="stakeholders"
              [(subStakeholders)]="ambulanceFlight.subConfirmationGroundTransportToFlightStakeholders"
              [subStakeholdersConfig]="stakeholdersConfig"
              [resourceConfig]="groundAmbulanceToFlightResourceConfig"
              [hideCabinClass]="true"
              [hideRemarks]="false"
              [hideFlightInRefs]="true"
              [hideAddFlightLeg]="true"
              [departureDateRequired]="false"
              [departureFromRequired]="true"
              [arrivalDateRequired]="false"
              [arrivalAtRequired]="true"
              [readOnlyMode]="ambulanceFlight.confirmationGroundTransportToFlightNotIncluded"
              [remarksReadOnlyMode]="ambulanceFlight.confirmationGroundTransportToFlightNotIncluded"
              [disableStakeholders]="ambulanceFlight.confirmationGroundTransportToFlightNotIncluded"
              [preselectUser]="false"
            >
            <span class="float-end not-organized">{{ "ambulance-flight-ground-ambulance-not-organized" | translate}}
              <input type="checkbox" #checkbox (click)="toFlightNotIncluded(checkbox.checked)" [(ngModel)]="ambulanceFlight.confirmationGroundTransportToFlightNotIncluded" />
            </span>
            </app-sub-travellers>
          </div>
          <div class="sub-component-placeholder">
            <app-sub-travellers
              [caseId]="caseId"
              [(travellers)]="ambulanceFlight.subConfirmationFlight"
              [stakeholders]="stakeholders"
              [(subStakeholders)]="ambulanceFlight.subConfirmationFlightStakeholders"
              [subStakeholdersConfig]="stakeholdersConfig"
              [resourceConfig]="flightResourceConfig"
              [hideCabinClass]="true"
              [hideFlightInRefs]="false"
              [flightNoRequired]="true"
              [hideAddFlightLeg]="false"
              [agencyReferenceRequired]="true"
              [hideTicketNo]="true"
              [hideAirlineReference]="true"
              [departureDateRequired]="true"
              [departureFromRequired]="true"
              [arrivalDateRequired]="true"
              [arrivalAtRequired]="true"
              [preselectUser]="true"
            >
            </app-sub-travellers>
          </div>
          <div class="sub-component-placeholder">
            <app-sub-travellers
              [caseId]="caseId"
              [(travellers)]="ambulanceFlight.subConfirmationGroundTransportFromFlight"
              [stakeholders]="stakeholders"
              [(subStakeholders)]="ambulanceFlight.subConfirmationGroundTransportFromFlightStakeholders"
              [subStakeholdersConfig]="stakeholdersConfig"
              [resourceConfig]="groundAmbulanceFromFlightResourceConfig"
              [hideCabinClass]="true"
              [hideFlightInRefs]="true"
              [hideAddFlightLeg]="true"
              [departureDateRequired]="true"
              [departureFromRequired]="true"
              [arrivalDateRequired]="false"
              [arrivalAtRequired]="false"
              [readOnlyMode]="ambulanceFlight.confirmationGroundTransportFromFlightNotIncluded"
              [remarksReadOnlyMode]="ambulanceFlight.confirmationGroundTransportFromFlightNotIncluded"
              [disableStakeholders]="ambulanceFlight.confirmationGroundTransportFromFlightNotIncluded"
              [preselectUser]="false"
            >
            <span class="float-end not-organized">{{ "ambulance-flight-ground-ambulance-not-organized" | translate}}
              <input type="checkbox" #checkbox3 [(ngModel)]="ambulanceFlight.confirmationGroundTransportFromFlightNotIncluded" (click)="fromFlightNotIncluded(checkbox3.checked)"/>
            </span>
            </app-sub-travellers>
          </div>
          <div class="sub-component-placeholder">
            <app-sub-remarks-from-supplier [(subRemarksFromSupplier)]="ambulanceFlight.remarksFromSupplier"></app-sub-remarks-from-supplier>
          </div>
          <div class="sub-component-placeholder row">
            <ng-template #popContent><span [innerHTML]="'left-side-no-supplier-selected-warning' | translate | safeHtml"></span></ng-template>

            <div class="col-12">
              <button class="primary-button float-end" (click)="sendRequest()" [disabled]="!enableSendIntinerary()">
                <div [disablePopover]="!showMissingSupplierPopOver()" triggers="hover" [ngbPopover]="popContent">
                  {{'ambulance-flight-send-intinerary' | translate}}
                </div>
              </button>
            </div>
          </div>
        </div>
      </ng-template>
  </li>
  <li *ngIf="ambulanceFlight?.repatriationFormAmbulanceFlight?.medicalAssessmentId" [ngbNavItem]="SelectedTab.DetailsTab">
    <a ngbNavLink>{{ 'service-type-details' | translate }}</a>
    <ng-template ngbNavContent>
      <div [appDisableInput]="caseLocked || serviceOrderTypeState.isLockedByCoordinationTransport" class="caselock-no-events">
      <div class="sub-component-placeholder">
        <app-sub-repatriation-details
          [medicalAssessmentId]="ambulanceFlight?.repatriationFormAmbulanceFlight?.medicalAssessmentId"
          [caseId]="caseId"
          [(subRepatriationDetails)]="ambulanceFlight.repatriationDetails"
          [serviceOrderType]="ServiceTypeEnum.AMBULANCE_FLIGHT"
          [hideFormRegularFlightEntries]="true"
        ></app-sub-repatriation-details>
      </div>
      </div>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="nav"></div>
