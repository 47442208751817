export class ICCFlightLegRelevantForCase{
    id?: number;
    linkedCaseId: string;
    flightLegId: string
    relevantForCase: boolean;

    public constructor(init?: Partial<ICCFlightLegRelevantForCase>) {
      Object.assign(this, init);
    }
  }
  export class ICCFlightLegRelevantForCaseAdapter {
      adapt(item: any): ICCFlightLegRelevantForCase {
        return  new ICCFlightLegRelevantForCase({
          id: item.id,
          linkedCaseId: item.linkedCaseId,
          flightLegId: item.serviceflightLegIdOrderId,
          relevantForCase: item.relevantForCase
        });
      }
    }