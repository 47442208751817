import { DocumentTemplateFilter } from './../../sub-components/sub-gop-details/documentTemplateFilter';
import { TreatmentServiceOrderExtension } from '@secca/shared/models/service-order/treatment-service-order-extension';
import { Component, Input, OnChanges } from '@angular/core';
import { ServiceTypeBase } from '../../models/serviceTypeBase';
import { ServiceTypeComponent } from '../../models/interfaces';
import * as moment from 'moment';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { ServiceOrderType } from '@secca/shared/models/service-order-type';
import { StatusTypes } from '@secca/shared/models/enums';

@Component({
  selector: 'app-treatment',
  templateUrl: './treatment.component.html',
})
export class TreatmentComponent extends ServiceTypeBase implements OnChanges, ServiceTypeComponent {

  _treatment: TreatmentServiceOrderExtension;


  @Input() gopPreviewModelChanged: boolean;

  @Input()
  set treatment(treatment: TreatmentServiceOrderExtension) {
    this._treatment = treatment;
  }

  get treatment() {
    return this._treatment;
  }

  @Input() incidentId: string;
  @Input() caseId: string;
  @Input() supplier: CaseStakeholder;
  @Input() stakeholders: CaseStakeholder[];
  @Input() serviceOrderType: ServiceOrderType;
  @Input() status: StatusTypes;

  defaultStartDate = moment.utc().set({ hour: 9, minute: 0, second: 0, millisecond: 0 });
  defaultEndDate = moment.utc().set({ hour: 15, minute: 0, second: 0, millisecond: 0 });
  treatmentTemplatesFilter = new DocumentTemplateFilter('DOCUMENT', ['GOP', 'VOB'], 'TREATMENT');

  constructor() {
    super();
  }

  ngOnInit() {
  }

  isGOPVisible() {
    return this.treatment.commitmentType && this.treatment.commitmentType.gop;
  }

  isValid(): boolean {
    let result: boolean;
    if (!this.serviceOrderTypeState.showConfirmationTab) {
      result = this.treatment.isValidExpectation();
    }
    if (this.serviceOrderTypeState.showConfirmationTab) {
      result = this.treatment.isValid();
    }
    return result;
  }

  getModel(): any {
    return this.treatment;
  }

}
