import { Injectable, Output, EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { moveItemInArray } from '@angular/cdk/drag-drop';

@Injectable()
export class DataService {
  @Output() changeSelectedCase: EventEmitter<ChangeSelectedCaseData> = new EventEmitter();
  private caseData: ChangeSelectedCaseData;
  private isCaseLoading = new Subject<boolean>();
  private openCasesCount = 0;

  private refundId: number = null;
  private supplierInvoiceId: number = null;
  private recoveryPaymentId: number = null;
  private salesOrderId: number = null;
  private medical: boolean = null;
  private recoveryId: number = null;

  send(caseData: ChangeSelectedCaseData) {
    this.caseData = caseData;
    this.caseData.setAsCurrent = true;
    this.changeSelectedCase.emit(caseData);
    this.setOpenCase(caseData);
  }

  public get selectedCaseId(): number {
    return this.caseData ? this.caseData.caseId : null;
  }

  public getIsCaseLoading(): Observable<boolean> {
    return this.isCaseLoading.asObservable();
  }

  public setIsCaseLoading(isCaseLoading: boolean) {
    this.isCaseLoading.next(isCaseLoading);
  }

  public getOpenCasesCount() {
    return this.openCasesCount;
  }

  public isCaseOpened(caseId: number) {
    return this.fetchOpenCases().has(caseId);
  }

  private setOpenCase(caseData: ChangeSelectedCaseData): void {
    const openCases = this.fetchOpenCases();
    if (!openCases.has(caseData.caseId)) {
      openCases.add(caseData.caseId);
    }
    this.openCasesCount = openCases.size;
    this.updateOpenCases(openCases);
  }

  public unsetOpenCase(caseId: number): void {
    const openCases = this.fetchOpenCases();
    openCases.delete(caseId);
    this.openCasesCount = openCases.size;
    this.updateOpenCases(openCases);
  }

  public fetchOpenCases(): Set<number> {
    const openCasesStringified = sessionStorage.getItem('open-cases');
    return openCasesStringified ? new Set<number>(openCasesStringified.split(';').map(caseId => parseInt(caseId, 10))) : new Set<number>();
  }

  public changeCaseOrder(from: number, to: number) {
    const openCasesStringified = sessionStorage.getItem('open-cases');
    const cases = openCasesStringified.split(';').map(caseId => parseInt(caseId, 10));
    moveItemInArray(cases, from, to);
    sessionStorage.setItem('open-cases', cases.join(';'));
  }

  private updateOpenCases(openCases: Set<number>): void {
    sessionStorage.setItem('open-cases', Array.from(openCases).join(';'));
  }

  public get navigateSupplierInvoiceId(): number {
    return this.supplierInvoiceId;
  }
  public set navigateSupplierInvoiceId(supplierInvoiceId: number) {
    this.supplierInvoiceId = supplierInvoiceId;
    this.salesOrderId = null;
    this.refundId = null;
    this.recoveryId = null;
    this.medical = false;
    this.recoveryPaymentId = null;
  }

  public get navigateSalesOrderId(): number {
    return this.salesOrderId;
  }
  public set navigateSalesOrderId(salesOrderId: number) {
    this.salesOrderId = salesOrderId;
    this.supplierInvoiceId = null;
    this.refundId = null;
    this.recoveryId = null;
    this.medical = false;
    this.recoveryPaymentId = null;
  }

  public get navigateRefundId(): number {
    return this.refundId;
  }

  public set navigateRefundId(refundId: number) {
    this.refundId = refundId;
    this.supplierInvoiceId = null;
    this.salesOrderId = null;
    this.recoveryId = null;
    this.medical = false;
    this.recoveryPaymentId = null;
  }

  public get navigateMedical(): boolean {
    return this.medical;
  }
  public set navigateMedical(medical: boolean) {
    this.medical = medical;
    this.supplierInvoiceId = null;
    this.salesOrderId = null;
    this.refundId = null;
    this.recoveryId = null;
    this.recoveryPaymentId = null;
  }

  public get navigateRecoveryId(): number {
    return this.recoveryId;
  }
  public set navigateRecoveryId(recoveryId: number) {
    this.recoveryId = recoveryId;
    this.refundId = null;
    this.supplierInvoiceId = null;
    this.salesOrderId = null;
    this.medical = false;
    this.recoveryPaymentId = null;
  }

  public get navigateRecoveryPaymentId(): number {
    return this.recoveryPaymentId;
  }
  public set navigateRecoveryPaymentId(recoveryPaymentId: number) {
    this.recoveryId = null;
    this.refundId = null;
    this.supplierInvoiceId = null;
    this.salesOrderId = null;
    this.medical = false;
    this.recoveryPaymentId = recoveryPaymentId;
  }

}

export class ChangeSelectedCaseData {
  caseId: number;
  newlyCreated: boolean;
  setAsCurrent?: boolean;
}
