import { Component, Inject, ViewChild } from '@angular/core';
import {IAdditionalInfo, SupplierStakeholderAdditionalInfo } from '@secca/shared/interfaces/additional-info';
import { IMinimizable } from '@secca/shared/interfaces/minimizable';
import { IKeyboardEnabled } from '@secca/shared/interfaces/keyboard-enabled';
import { IFocusEnabled } from '@secca/shared/interfaces/focus-enabled';
import { DialogMinimizableDirective } from '@secca/shared/directives/dialog-minimizable.directive';
import { DialogSelectableDirective } from '@secca/shared/directives/dialog-selectable.directive';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { DialogHelperUtilService } from '@secca/core/services/dialog-helper-util.service';
import { DialogBoundryService } from '@secca/core/services/dialog-boundry.service';
import { MinimizableDialogType } from '@secca/shared/enums/minimizable-dialog-type-enum';
import {
  SupplierSearchDialogComponent
} from '@secca/case/components/case-stakeholders/case-stakeholders-table/case-stakeholders-info/supplier-search-dialog/supplier-search-dialog.component';
import {
  SupplierSearchData
} from '@secca/case/components/case-plans/case-plans/add-service-order/search-service-provider/SupplierDialogData';

@Component({
  selector: 'app-stakeholder-supplier-search-dialog',
  templateUrl: './stakeholder-supplier-search-dialog.component.html',
  styleUrls: ['./stakeholder-supplier-search-dialog.component.scss']
})
export class StakeholderSupplierSearchDialogComponent implements IAdditionalInfo, IMinimizable, IKeyboardEnabled, IFocusEnabled {
  @ViewChild(DialogMinimizableDirective) minimizedDirective: DialogMinimizableDirective;
  @ViewChild(SupplierSearchDialogComponent) wrappedInstance: SupplierSearchDialogComponent;
  @ViewChild(DialogSelectableDirective) selectableDirective: DialogSelectableDirective;

  constructor(private dialogRef: MatDialogRef<SupplierSearchDialogComponent>,
              public dialogBoundryService: DialogBoundryService,
              private dialogHelperUtilService: DialogHelperUtilService,
              @Inject(MAT_DIALOG_DATA) data: SupplierSearchData) {
    this.data = data;
  }

  data: SupplierSearchData;
  getAdditionalInfo(): SupplierStakeholderAdditionalInfo {
    const caseId = this.wrappedInstance?.supplierSearchData?.caseId;
    const supplierId = this.wrappedInstance?.supplierSearchData.supplierId;
    return {
      caseId,
      supplierId
    };
  }

  getFocusElement() {
    return this.selectableDirective?.focusElement;
  }

  keyboardClose(): void {
    this.wrappedInstance.close();
  }

  minimize(): void {
    this.minimizedDirective.minimize();
  }

  getMinimizeType(): MinimizableDialogType {
    return this.minimizedDirective.appDialogMinimizable;
  }

  close() {
    this.dialogHelperUtilService.close(this.dialogRef, this.getProperties().caseId, true);
  }

  getProperties(): SupplierStakeholderAdditionalInfo {
    return this.getAdditionalInfo();
  }

  get MinimizableDialogType() {
    return MinimizableDialogType;
  }
}
