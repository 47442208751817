import { SupplierInvoiceItem } from './supplierInvoiceItem';

export class PurchaseAndSalesItem {
  id: number;
  serviceItem: string;
  supplierName: string;
  creditorId: string;
  creditorName: string;
  type: string;
  purchaseAmount: number;
  salesAmount: number;
  netAmount: number;
  supplierInvoiceItem: SupplierInvoiceItem;
}
