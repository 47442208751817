<div class="match-overview">
  <div class="right-box" *ngIf="relationSearchResult">
    <div class="match" *ngFor="let match of relationSearchResult.matches">
      <div class="float-end clearfix" [ngClass]="match.quality === MatchQuality.SUCCESS ? 'success' : 'failure'"></div>
      <div class="match-parameter">{{ match.parameter }}</div>
    </div>
  </div>
  <div class="right-box" *ngIf="relationSearchResult">
    <div class="match match-bold">{{ 'profile-lookup-SECCA_PROFILE_LOOKUP' | translate }}</div>
    <div *ngIf="sourceInfo" class="match">{{ sourceInfo }}</div>
    <div *ngIf="matchedBy" class="match">(Matched by: {{ matchedBy }})</div>
    <div class="match">{{ lookupTime | date: "dd MMM yyyy HH:mm" }}</div>
  </div>
</div>

<div class="row">
  <div class="result-field-list col-5" *ngIf="relationSearchResult">
    <div class="result-fields" *ngIf="relationSearchResult.customerProductRelation.customerProductRelationType">
      <div class="title">{{'profile-lookup-details-type' | translate}}</div>
      <div class="value"><span class="text">{{ 'profile-lookup-details-type-' + relationSearchResult.customerProductRelation.customerProductRelationType | translate }}</span></div>
    </div>
    <div class="result-fields" *ngIf="relationSearchResult.customerProductRelation.bank">
      <div class="title">{{'profile-lookup-details-bank' | translate}}</div>
      <div class="value"><span class="text">{{ relationSearchResult.customerProductRelation.bank }}</span></div>
    </div>
    <div class="result-fields" *ngIf="relationSearchResult.customerProductRelation.bin">
      <div class="title">{{'profile-lookup-details-bin' | translate}}</div>
      <div class="value"><span class="text">{{ relationSearchResult.customerProductRelation.bin }}</span></div>
    </div>
    <div class="result-fields" *ngIf="relationSearchResult.customerProductRelation.productName">
      <div class="title">{{'profile-lookup-details-product-name' | translate}}</div>
      <div class="value"><span class="text">{{ relationSearchResult.customerProductRelation.productName }}</span></div>
    </div>
    <div class="result-fields">
      <div class="title" *ngIf="isCreditCard()">{{'profile-lookup-details-card-level' | translate}}</div>
      <div class="title" *ngIf="!isCreditCard()">{{'profile-lookup-details-insurance-level' | translate}}</div>
      <div class="value"><span class="text">{{ relationSearchResult.customerProductRelation.productLevel }}</span></div>
    </div>
    <div class="result-fields" *ngIf="relationSearchResult.customerProductRelation.profileName">
      <div class="title">{{'profile-lookup-details-profile-name' | translate}}</div>
      <div class="value"><span class="text">{{ relationSearchResult.customerProductRelation.profileName }}</span></div>
    </div>
    <div class="result-fields" *ngIf="relationSearchResult.customerProductRelation.validFrom">
      <div class="title">{{'profile-lookup-details-valid-from-to' | translate}}</div>
      <div class="value">
        <span class="text">{{ relationSearchResult.customerProductRelation.validFrom | date:'dd MMM yyyy' }} -
          <span *ngIf="relationSearchResult.customerProductRelation.validTo"> {{relationSearchResult.customerProductRelation.validTo | date:'dd MMM yyyy'}}</span>
          <span *ngIf="!relationSearchResult.customerProductRelation.validTo"> {{'profile-lookup-ongoing' | translate }}</span>
        </span>
      </div>
    </div>
    <div class="result-fields" *ngIf="relationSearchResult.customerProductRelation.splitDateType">
      <div class="title">{{'profile-lookup-details-split-date-type' | translate}}</div>
      <div class="value"><span class="text">{{ 'profile-lookup-details-split-date-type-' + relationSearchResult.customerProductRelation.splitDateType | translate }}</span></div>
    </div>
  </div>
  <div class="result-field-list col-5" *ngIf="relationSearchResult">
    <div class="result-fields" *ngIf="relationSearchResult.customerProductRelation.companyPolicyHolder">
      <div class="title">{{'profile-lookup-details-company-policy-holder' | translate}}</div>
      <div class="value"><span class="text">{{ relationSearchResult.customerProductRelation.companyPolicyHolder }}</span></div>
    </div>
    <div class="result-fields">
      <div class="title">{{'profile-lookup-details-policy-no' | translate}}</div>
      <div class="value"><span class="text">{{ relationSearchResult.customerProductRelation.policyNo }}</span></div>
    </div>
    <div class="result-fields" *ngIf="relationSearchResult.customerProductRelation.remarks">
      <div><img class="warning" src="/assets/icons/Warning.svg" alt=""></div>
      <div class="title">{{'profile-lookup-details-remarks' | translate}}</div>
      <div class="value"><span class="text">{{ relationSearchResult.customerProductRelation.remarks }}</span></div>
    </div>
  </div>
</div>
