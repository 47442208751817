<div class="sub-component-placeholder" [appDisableInput]="caseStateService.isCaseDisabled()">
  <div class="sub-component-container" >
    <div class="sub-title ">
      <div class="d-flex">
        <div class="me-auto p-0 w-50"><span>{{endUserCaseDetail.caseNumber}} - {{ endUserCaseDetail.stakeholders[0].person.firstName }} {{ endUserCaseDetail.stakeholders[0].person.surname }}</span></div>
      </div>
    </div>
    <div class="sub-content-container">
      <div class="sub-component-container" >
        <div class="sub-title ">
          <div class="d-flex">
            <div class="me-auto p-0 w-50">{{"sub-stakeholders-serviceorders-title" | translate}}</div>
            <a class="test-end change-status" *ngIf="isCommitted() && !endUserDisplayReadOnly" (click)="changeStatusOnServiceOrders(endUserCaseDetail.caseId)">
              {{"coordination-linked-service-order-change-service-order-status" | translate}}
            </a>
          </div>
        </div>
        <div class="sub-component-placeholder">
          <app-sub-icc-stakeholders [caseId]='endUserCaseDetail.caseId.toString()'
                                    [stakeholders]="endUserCaseDetail.stakeholders"
                                    [flightStakeholders]='isCommitted() ? coordinationTransportServiceOrderExtension.iccConfirmationFlightStakeholders : coordinationTransportServiceOrderExtension.iccExpectedFlightStakeholders'
                                    [preselectEndUser]="false"
                                    [serviceOrderType]="serviceOrderType"
                                    [serviceOrdersList]='endUserCaseDetail.serviceOrders'
                                    [iccServiceOrderStatus]="status"
                                    [disabled]="true"
                                    [showDeleteButton]="false"
          >
          </app-sub-icc-stakeholders>
        </div>
      </div>
    </div>
    <div class="sub-content-container">
      <app-end-user-repatriation-form
        [caseId]='endUserCaseDetail.caseId'
        [coordinationTransport]='coordinationTransportServiceOrderExtension'
        [addRepatriationDisabled] = endUserDisplayReadOnly
      >
      </app-end-user-repatriation-form>
    </div>
    <div class="sub-content-container">
      <app-sub-travellers
        [caseId]="caseId"
        [travellers]="getAmbulanceFlight(endUserCaseDetail.caseId).subGroundTransportToFlight"
        [stakeholders]="endUserCaseDetail.stakeholders"
        [subStakeholders]="getAmbulanceFlight(endUserCaseDetail.caseId).subGroundTransportToFlightStakeholders"
        [subStakeholdersConfig]="subStakeholdersConfig"
        [resourceConfig]="groundAmbulanceToCoordinationTransportResourceConfig"
        [hideCabinClass]="true"
        [hideRemarks]="false"
        [hideFlightInRefs]="true"
        [hideAddFlightLeg]="true"
        [departureDateRequired]="false"
        [departureFromRequired]="false"
        [arrivalDateRequired]="false"
        [arrivalAtRequired]="false"
        [readOnlyMode]="getAmbulanceFlight(endUserCaseDetail.caseId).groundTransportToFlightNotIncluded || endUserDisplayReadOnly"
        [remarksReadOnlyMode]="getAmbulanceFlight(endUserCaseDetail.caseId).groundTransportToFlightNotIncluded || endUserDisplayReadOnly"
        [disableStakeholders]="endUserDisplayReadOnly ? endUserDisplayReadOnly : false"
        [preselectUser]="true"
      >
          <span class='float-end not-organized'>
            {{ "ambulance-flight-ground-ambulance-coordination-transport-not-organized" | translate}}
            <input type='checkbox' #checkbox1 (click)='toFlightNotIncluded(checkbox1.checked, endUserCaseDetail.caseId)' [(ngModel)]='getAmbulanceFlight(endUserCaseDetail.caseId).groundTransportToFlightNotIncluded' [disabled]='endUserDisplayReadOnly'/>
          </span>
      </app-sub-travellers>
    </div>
    <div class="sub-content-container">
      <app-sub-travellers
        [travellers]="!isCommitted() ? expectedFlight : confirmationFlight"
        [subStakeholdersConfig]="subStakeholdersConfig"
        [hideCabinClass]="true"
        [hideFlightInRefs]="true"
        [departureDateRequired]="false"
        [departureFromRequired]="false"
        [arrivalDateRequired]="false"
        [arrivalAtRequired]="false"
        [arrivalDateWhite]="true"
        [hideAddFlightLeg]="true"
        [hideRemarks]="false"
        [remarksReadOnlyMode]="true"
        [serviceOrderType]="serviceOrderType"
        [readOnlyMode]="true"
        [resourceConfig]="!isCommitted() ? expectedCoordinationResourceConfig : confirmedCoordinationResourceConfig"
        [showRelevantForCase]="true"
        [caseId]="endUserCaseDetail.caseId"
        [relevantForCaseReadOnly]="endUserDisplayReadOnly"
        [hideTitle] = "false"
      >
      </app-sub-travellers>
    </div>
    <div class="sub-content-container">
      <app-sub-travellers
        [caseId]="caseId"
        [travellers]="getAmbulanceFlight(endUserCaseDetail.caseId).subGroundTransportFromFlight"
        [stakeholders]="endUserCaseDetail.stakeholders"
        [subStakeholders]="getAmbulanceFlight(endUserCaseDetail.caseId).subGroundTransportFromFlightStakeholders"
        [subStakeholdersConfig]="subStakeholdersConfig"
        [resourceConfig]="groundAmbulanceFromCoordinationTransportResourceConfig"
        [hideCabinClass]="true"
        [hideRemarks]="false"
        [hideFlightInRefs]="true"
        [hideAddFlightLeg]="true"
        [departureDateRequired]="false"
        [departureFromRequired]="false"
        [arrivalDateRequired]="false"
        [arrivalAtRequired]="false"
        [readOnlyMode]="getAmbulanceFlight(endUserCaseDetail.caseId).groundTransportFromFlightNotIncluded || endUserDisplayReadOnly"
        [remarksReadOnlyMode]="getAmbulanceFlight(endUserCaseDetail.caseId).groundTransportFromFlightNotIncluded || endUserDisplayReadOnly"
        [disableStakeholders]="endUserDisplayReadOnly ? endUserDisplayReadOnly : false"
        [preselectUser]="true"
      >
          <span class='float-end not-organized'>
            {{ "ambulance-flight-ground-ambulance-coordination-transport-not-organized" | translate}}
            <input type='checkbox' #checkbox2 (click)='fromFlightNotIncluded(checkbox2.checked, endUserCaseDetail.caseId)' [(ngModel)]='getAmbulanceFlight(endUserCaseDetail.caseId).groundTransportFromFlightNotIncluded' [disabled]='endUserDisplayReadOnly'/>
          </span>
      </app-sub-travellers>
    </div>
  </div>
  <div class="sub-content-container">
    <div class="sub-component-container" >
      <div class="sub-content-container">
          <app-text-box
              [name]="'sub-remarks-icc-enduser-case' | translate"
              [maxTextLength]="1000"
              [value]="enduserCaseRemark"
              (valueChange)="setEnduserCaseRemark($event)"
              [disabled]="endUserDisplayReadOnly"
          ></app-text-box>
      </div>
    </div>
  </div>
</div>
