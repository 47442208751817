import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { DocumentService } from '@secca/core/services/document.service';
import { DocumentTemplate } from '@secca/shared/models/document-template';
import {
  DocumentTemplateFilter
} from '@secca/case/components/case-plans/case-plans/add-service-order/sub-components/sub-gop-details/documentTemplateFilter';
import { RefundService } from '@secca/core/services/refund.service';
import { Message } from '@secca/case/components/case-output-management/models/message';
import { AttachmentTypeEnum, MessageChannelType, MessageFrom, RefundStatus } from '@secca/shared/models/enums';
import { OutputManagementService } from '@secca/case/components/case-output-management/services/output-management.service';
import { CaseMessageService } from '@secca/core/services/case-message.service';
import { ServiceOrderMessageRequest } from '@secca/shared/models/service-order/service-order-message-request';
import { CaseService } from '@secca/core/services/case.service';
import { Refund } from '@secca/shared/models/refund';
import { RefundState } from '../add-refund/add-refund.component';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { CaseDialogViewerService } from '@secca/case/case-dialog-viewer.service';
import { AttachmentDto } from '@secca/shared/models/attachment-dto';
import { Case } from '@secca/shared/models/case';

@Component({
  selector: 'app-refund-document',
  templateUrl: './refund-document.component.html',
  styleUrls: ['./refund-document.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RefundDocumentComponent implements OnInit {

  @Input() set refund(value: Refund) {
    this._refund = value;
    this.checkTemplateReference();
  }
  get refund() {
    return this._refund;
  }

  @Input() refundState: RefundState;

  @Input() disabled: boolean;
  @Input() currentCase : Case;

  private _refund: Refund;
  @Output() refundChange = new EventEmitter<Refund>();
  @Output() closeRefundModal = new EventEmitter<void>();
  templateNames = [];
  templateLanguages = [];
  hasBeenPreviewed = false;
  disableSendButton = true;
  errorText = '';
  disablePopover = false;
  private fullTemplateList;
  private templatesWithLanguages = {};

  constructor(private documentService: DocumentService,
              private outputManagementService: OutputManagementService,
              private dialogViewerService: CaseDialogViewerService,
              private caseMessageService: CaseMessageService,
              private caseService: CaseService,
              private refundService: RefundService,
              public caseStateService: CaseStateService,
              public caseLockHelperService: CaseLockHelperService) { }

  ngOnInit(): void {
    const filter = new DocumentTemplateFilter('DOCUMENT', ['CLAIM_LETTER'], null);
    this.documentService.postTemplatesByType(filter).subscribe(refundTemplates => {
      this.fullTemplateList = refundTemplates;
      this.mapToTemplateNameWithLanguagesList(refundTemplates);
      this.checkTemplateReference();
    });
  }

  checkTemplateReference() {
    if (this._refund == null || this.fullTemplateList == null) {
      return;
    }
    if (!this.fullTemplateList.map(template => template.templateId).includes(this._refund?.templateId)) {
      this.refund.templateId = '';
      this.refund.templateName = '';
      this.refund.templateLanguage = '';
    } else {
      this.selectTemplateLanguages(this.refund.templateName);
    }
  }

  selectTemplateLanguages(selectedTemplateName: string) {
    this.templateLanguages = this.templatesWithLanguages[selectedTemplateName];
    this.refund.templateId = this.getTemplateId();
  }

  selectTemplateId() {
    this.refund.templateId = this.getTemplateId();
  }

  private getTemplateId() {
    const template = this.fullTemplateList.find(
      templateResult =>
        templateResult.templateLanguage === this.refund.templateLanguage &&
        templateResult.templateName === this.refund.templateName
    );
    if (template) {
      return template.templateId;
    }
    return '';
  }

  private mapToTemplateNameWithLanguagesList(refundTemplates: DocumentTemplate[]) {
    const templateNames = new Set();
    refundTemplates.forEach(template => {
      if (this.templatesWithLanguages[template.templateName] == null) {
        this.templatesWithLanguages[template.templateName] = [];
      }
      this.templatesWithLanguages[template.templateName].push(template.templateLanguage);
      templateNames.add(template.templateName);
    });
    this.templateNames = Array.from(templateNames);
  }

  preview() {
    if (this.refund.refundStatus !== RefundStatus.AWAITING_SECOND_APPROVER && this.refund.refundStatus !== RefundStatus.AWAITING_SEND_LETTER &&
      this.refund.refundStatus !== RefundStatus.APPROVED && this.refund.refundStatus !== RefundStatus.REJECTED &&
      this.refund.refundStatus !== RefundStatus.PAID &&
      this.refund.refundStatus !== RefundStatus.CANCELLED) {
      this.refund.refundStatus = RefundStatus.ONGOING_CLAIMS_HANDLING;
    }
    if (this.refund.id == null) {
      this.refundService.createRefund(this.refund).subscribe(refund => {
        this.refund = refund;
        this.refundChange.emit(this.refund);
        this.previewAfterSave();
      });
    } else {
      this.refundService.updateRefund(this.refund).subscribe(refund => {
        this.refund = refund;
        this.refundChange.emit(this.refund);
        this.previewAfterSave();
      });
    }
  }

  previewAfterSave() {
    this.refundService.preview(this.refund.id).subscribe(data => {
      this.hasBeenPreviewed = true;
      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      this.dialogViewerService.openPreviewDocumentDialog(this.refund.id, fileURL, this.refund.caseId.toString(), this.currentCase.caseNumber);
      this.disableSendButton = false;
    });
  }

  send() {
    this.refundService.store(this.refund.id).subscribe(
      documentId => {
        const message = new Message();
        message.templateType = 'CLAIM_LETTER';
        message.caseId = '' + this.refund.caseId;
        message.stakeholderReceiverId = this.refund.stakeHolderId;
        message.messageChannelType = MessageChannelType.EMAIL;
        message.messageFrom = MessageFrom.EMAIL_SENDER_CLAIMS;
        message.refundId = this.refund.id;
        if (documentId) {
          message.preSelectDocumentAttachments = [new AttachmentDto({documentId: documentId,
            attachmentType: AttachmentTypeEnum.GENERATED_FROM_TEMPLATE}) ];
        }

        const srMessage = new ServiceOrderMessageRequest({
          messageService: this.caseMessageService,
          message });
        if (this.refund.refundStatus === RefundStatus.AWAITING_SEND_LETTER) {
          // Refund is only approved when user select "Send letter"
          this.refund.refundStatus = RefundStatus.APPROVED;
          this.refundService.updateRefund(this.refund).subscribe(refund => {
            this.refund = refund;
            this.refundChange.emit(this.refund);
            this.sendDialog(message, srMessage);
          });
        } else {
          this.sendDialog(message, srMessage);
        }
      }
    );
  }

  private sendDialog(message: Message, srMessage: ServiceOrderMessageRequest) {
    this.caseService.getCase('' + this.refund.caseId).subscribe(
      result => {
        message.caseNumber = '[' + result.caseNumber + '#' + this.refund.id + ']';
        this.dialogViewerService.openOutputManagementDialog(result, message.messageChannelType, srMessage, null);
        this.closeRefundModal.emit();
      }
    );
  }
}
