<ul ngbNav #nav="ngbNav" class="nav-tabs">
  <li ngbNavItem>
    <a ngbNavLink>{{ 'case-basics-incident-incident-tab' | translate }}</a>
    <ng-template ngbNavContent>
      <app-case-basics-incident-details (eventName)="updateEventName($event)" [type]="'BASIC'" [caseId]="caseId"></app-case-basics-incident-details>
    </ng-template>
  </li>


  <li ngbNavItem>
    <a ngbNavLink><div class="float-start">{{ 'case-basics-incident-event-tab' | translate }}</div><div *ngIf="eventName !== ''" class="float-end event-folder-title">{{ eventName }}</div> </a>
    <ng-template ngbNavContent>
      <app-case-basics-incident-details (eventName)="updateEventName($event)" [type]="'EVENTS'" [caseId]="caseId"></app-case-basics-incident-details>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="nav"></div>
