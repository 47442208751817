import { TaskStatus } from './enums';

export class TaskRedirectFromGlobalTaskBoard {
  caseId: number;
  taskId: number;
  taskStatus: TaskStatus;
  directRedirection: boolean;

  public constructor(init?: Partial<TaskRedirectFromGlobalTaskBoard>) {
    Object.assign(this, init);
  }
}
