<div class="d-flex">
  <div class="left-column">
    <div class="left-row">
      <div class="left-column-container">
        <div class="start-date">
          <app-input-date-time
            [name]="'medical-assessment-service-order-start-date' | translate"
            [(date)]="medicalAssessment.startDate"
            [withImage]="true"
            [disabled]="true"
            [timeZone]="'local'"
          >
          </app-input-date-time>
        </div>
      </div>
    </div>
    <div class="horizontal-line"></div>
    <div class="left-row">
      <div class="left-column-container">
        <div class="description-for-medical-staff">
          <div class="text">{{ 'medical-assessment-service-order-description' | translate }}</div>
          <textarea
            name="textarea"
            class="description"
            maxlength="1000"
            [(ngModel)]="medicalAssessment.description"
            [disabled]="disableEditingDescription"
            (ngModelChange)="medicalChange()"
            [class]="isDescriptionValid() ? 'description' : 'description-invalid'"
          ></textarea>
        </div>
      </div>
    </div>
    <div class="horizontal-line"></div>
    <div class="spacer"></div>
    <div class="left-row">
      <div class="left-column-container">
        <div class="text">
          {{ 'left-side-status' | translate }}
        </div>

        <div class="d-flex d-inline-flex w-100">
          <div class="flex-grow-1">
            <label class="custom-radio-button radio-disabled">
              <input type="checkbox"
                [checked]="serviceOrder.status === StatusTypes.EXPECTED"
                [disabled]="expectedDisabled()"
                (click)="changeStatus(StatusTypes.EXPECTED, $event)"
                />
              <span class="checkmark"></span>
              <div class="radio-button-text">{{ 'service-order-expected' | translate }}</div>
            </label>
          </div>
          <div class="flex-grow-1">
            <label class="custom-radio-button" [class.radio-disabled]="committedDisabled()">
              <input
                type="checkbox"
                [checked]="serviceOrder.status == StatusTypes.COMMITTED"
                [disabled]="committedDisabled()"
                (click)="changeStatus(StatusTypes.COMMITTED, $event)" />
              <span class="checkmark"></span>
              <div class="radio-button-text">{{ 'service-order-committed' | translate }}</div>
            </label>
          </div>
          <div class="flex-grow-1">
            <label class="custom-radio-button" [class.radio-disabled]="cancelledDisabled()">
              <input type="checkbox"
                [checked]="serviceOrder.status === StatusTypes.CANCELLED"
                (click)="changeStatus(StatusTypes.CANCELLED, $event)"
                [disabled]="cancelledDisabled()"
              />
              <span class="checkmark"></span>
              <div class="radio-button-text">{{ 'service-order-cancelled' | translate }}</div>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
