import { Component, OnInit, ViewChild, OnDestroy, Inject, LOCALE_ID } from '@angular/core';
import { SettingsService } from './../../../../core/services/settings.service';
import { TableSort } from './../../../../shared/models/table-sort';
import { CaseDetails, HandlingAreaStatusEnum, PatientType, RepatriationFormEnum, ServiceTypeEnum, ShortcutEnum, SortOrder, TransportAssistanceType, ValidationTypeEnum } from './../../../../shared/models/enums';
import { CaseFilterResponse } from './models/case-filter-response.model';
import { CaseFilterRequest } from './models/case-filter-request.model';
import { BoardCase } from './models/board-case.model';
import { BoardTeamCaringService } from '../board-team-caring/board-team-caring.service';
import { BoardShareService } from '../../../../core/services/board-share.service';
import { Case } from '../../../../shared/models/case';
import { CaseBoardService } from './services/case-board.service';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import { TranslateService } from '@ngx-translate/core';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { ScreeningStates } from '@secca/shared/enums/screening-states';
import { CauseDropdownElement } from '@secca/shared/components/drop-down-with-all-causes/causeDropdownElement';
import * as moment from 'moment';
import { ShortcutService } from '@secca/core/services/shortcut.service';
import { interval, Subject, Subscription, throwError } from 'rxjs';
import { CaseMedicalFilterRequest } from './models/case-medical-filter-request.model';
import { CaseMedicalFilterResponse } from './models/case-medical-filter-responce.model';
import { BoardMedicalCase } from './models/board-medical-case.model';
import { HelperService } from '@secca/core/services/helper.service';
import { MasterListService } from '@secca/core/services/masterlist-service';
import { DictionaryService } from '@secca/core/services/dictionary.service';
import { CountryDetails } from '@secca/shared/models/country';
import { CaseIncidentEventsRequest } from './models/case-incident-event-filter-request.model';
import { IncidentEvent } from '@secca/shared/models/incidentEvent';
import { CaseIncidentEventFilterResponse } from './models/case-incident-event-filter-responce.model';
import { BoardIncidentEventCase } from './models/board-incident-event-case.model';
import { IncidentService } from '@secca/core/services/incident.service';
import { CausesLevel1DD } from '@secca/shared/models/CausesLevel1DD';
import { CausesLevel2DD } from '@secca/shared/models/CausesLevel2DD';
import { CausesLevel3DD } from '@secca/shared/models/CausesLevel3DD';
import { DropDownComponent } from '@secca/shared/components/drop-down/drop-down.component';
import { saveAs } from 'file-saver-es';
import { formatDate, formatNumber } from '@angular/common';
import { catchError, takeUntil } from 'rxjs/operators';
import { BoardNavigationStateService } from '../board-task/services/board-navigation-state.service';
import { CaseTypesService } from '@secca/core/services/case-type.service';
import { CaseTransportRequest } from './models/case-transport-filter-request.model';
import { CaseTransportFilterResponse } from './models/case-transport-filter-responce.model';
import { BoardTransportCase } from './models/board-transport-case.model';

@Component({
  selector: 'app-board-cases',
  templateUrl: './board-cases.component.html',
  styleUrls: ['./board-cases.component.scss'],
})
@AutoUnsubscribe
export class BoardCasesComponent implements OnInit, OnDestroy {
  @ViewChild('BoardCasesTableComponent') BoardCasesTableComponent;
  @ViewChild('customerProfileId') profileComponent: any;
  @ViewChild('customerProfileIdMedical') profileComponentMedical: any;
  @ViewChild('customerProfileIdIncidentEvent') customerProfileIdIncidentEvent: any;

  @ViewChild('caseCauseLevel1Component') caseCauseLevel1Component: DropDownComponent;
  @ViewChild('caseCauseLevel2Component') caseCauseLevel2Component: DropDownComponent;
  @ViewChild('caseCauseLevel3Component') caseCauseLevel3Component: DropDownComponent;
  @ViewChild('multipleSelectCustomerProfileComponent') multipleSelectCustomerProfileComponent: any;
  @ViewChild('multipleSelectCustomerProfileMedicalComponent') multipleSelectCustomerProfileMedicalComponent: any;
  @ViewChild('multipleSelectCustomerProfileEventsComponent') multipleSelectCustomerProfileEventsComponent: any;
  @ViewChild('multipleSelectCustomerProfileTransportComponent') multipleSelectCustomerProfileTransportComponent: any;


  exportRunning = false;
  exportTransportRunning = false;
  cases: Case[];
  numberOfOpenCase: number;
  isBoardTeamCaringShow: boolean;
  boardCases: BoardCase[];
  boardMedicalCase: BoardMedicalCase[];
  boardIncidentEventCase: BoardIncidentEventCase[];
  boardCasesRefreshing = true;
  caseFilterRequest: CaseFilterRequest;
  caseMedicalFilterRequest: CaseMedicalFilterRequest;
  caseIncidentEventsRequest: CaseIncidentEventsRequest;
  caseTransportRequest: CaseTransportRequest;
  caseTransportFilterResponse: CaseTransportFilterResponse;
  boardTransportCase: BoardTransportCase[];
  eventCases: BoardIncidentEventCase[];
  causeDropdownElement: CauseDropdownElement[] = [];
  caseFilterResponse: CaseFilterResponse;
  caseMedicalFilterResponse: CaseMedicalFilterResponse;
  caseIncidentEventFilterResponse: CaseIncidentEventFilterResponse;
  $subscrCasesSelectedCaseWorker: Subscription;
  $subscrCasesPagination;
  $subscrCasesSorting;
  status: DropdownDictionary[];
  assessmentTypes: DropdownDictionary[];
  claimsDropDownStatus: DropdownDictionary[];
  caseIncidentCoveredStatus: DropdownDictionary[];
  caseDetails: DropdownDictionary[] = [];
  patientType: DropdownDictionary[];
  transportArranged: DropdownDictionary[];
  repatriationForm: DropdownDictionary[];
  caseTypes: DropdownDictionary[];
  countryListDropdown: DropdownDictionary[] = [];
  incidentEventsDropdown: DropdownDictionary[];
  countryList: CountryDetails[];
  dateTimePlaceholder = 'dd mmm yyyy - hh:mm';
  dateTimePlaceholderIncidentEvent = 'dd mmm yyyy';
  claimStatus: string[] = [];
  notRelavantJustSelected = true;
  scrollPosition = 0;

  exportConfig = [
    { key: 'customerProfileName', value: 'Cu.'},
    { key: 'createdOn', value: 'Case creation', formatter: (val: any) => this.dateTimeFormatter(val) },
    { key: 'nationalIdCountryName', value: 'Nationality' },
    { key: 'caseNumber', value: 'Case number' },
    { key: 'endUserFullName', value: 'End-user' },
    { key: 'age', value: 'Age' },
    { key: 'homeCountryName', value: 'Home country' },
    { key: 'incidentEvent', value: 'Event', formatter: (val: any) => this.eventFormatter(val) },
    { key: 'incidentCause', value: 'Cause' },
    { key: 'incidentLocationLocality', value: 'Incident city' },
    { key: 'incidentLocationAdmArea', value: 'Inc. region/state' },
    { key: 'incidentLocationCountry', value: 'Incident country' },
    { key: 'currentLocationLocality', value: 'Current city' },
    { key: 'currentLocationAdmArea', value: 'Cur. region/state' },
    { key: 'currentLocationCountry', value: 'Current country' },
    { key: 'teamStatus', value: 'AC status' },
    { key: 'transportHandlingAreaStatus', value: 'Transport status' },
    { key: 'medicalHandlingAreaStatus', value: 'Medical status' },
    { key: 'claimsHandlingAreaStatus', value: 'Claims status' },
    { key: 'costControlHandlingAreaStatus', value: 'Cost control status' }
  ];

  exportTransportConfig = [
    { key: 'customerProfileName', value: 'Cu.'},
    { key: 'caseNumber', value: 'Case number' },
    { key: 'endUserFullName', value: 'End-user' },
    { key: 'currentLocationLocality', value: 'Current city' },
    { key: 'currentLocationCountry', value: 'Current country' },
    { key: 'homeCity', value: 'Home country' },
    { key: 'firstRepatriationDate', value: 'Transport order', formatter: (val: any) => this.dateTimeFormatter(val) },
    { key: 'expectedRepatriationForm', value: 'Repatriation Form (exp.)'},
    { key: 'firstTransportServiceOrderFlightLegDate', value: 'Repatriation date', formatter: (val: any) => this.dateTimeFormatter(val) },
    { key: 'readyFrom', value: 'Ready from', formatter: (val: any) => this.dateTimeFormatter(val) },
    { key: 'arrivalDeadline', value: 'Arrival deadline', formatter: (val: any) => this.dateTimeFormatter(val) },
    { key: 'coTransported', value: 'Co-transport', formatter: (val: any) => this.coTransportFormatter(val)      },
    { key: 'relatedICCCaseNumber', value: 'ICC case number' },
  ];


  private shortcutSubscriptions: Subscription[] = [];
  medicalStatus: string[] = [];
  currentTab: TabsEnum = TabsEnum.GENERAL;
  $caseCountSubscr: Subscription;
  globalNumberGeneralCases: number;
  globalNumberMedicalCases: number;
  globalNumberIncidentEventCases: number;
  globalNumberTransportCases: number;
  public minMoment: moment.Moment;
  public maxMoment: moment.Moment;
  public defaultFrom: moment.Moment;
  public defaultTo: moment.Moment;
  public currentDate: moment.Moment;
  active = TabsEnum.GENERAL;
  incidentEvents: IncidentEvent[];
  incidentCausesLevel1: CausesLevel1DD[] = [];
  incidentCausesLevel2: CausesLevel2DD[] = [];
  incidentCausesLevel3: CausesLevel3DD[] = [];
  private stopCallInterval$ = new Subject<boolean> ();



  constructor(
    private boardShareService: BoardShareService,
    private boardTeamCaringService: BoardTeamCaringService,
    private caseBoardService: CaseBoardService,
    private translateService: TranslateService,
    private dictionaryService: DictionaryService,
    private masterListService: MasterListService,
    private shortcutService: ShortcutService,
    private incidentService: IncidentService,
    private boardNavigationStateService: BoardNavigationStateService,
    private caseTypesService: CaseTypesService

  ) {
    this.currentDate  = moment();
    this.defaultFrom = moment(this.currentDate).subtract(1, 'd').startOf('day');
    this.defaultTo = moment(this.currentDate).endOf('day');
    this.caseMedicalFilterRequest = new CaseMedicalFilterRequest({
      sortBy: 'assignedOn',
      sortOrder: SortOrder.desc,
      pageSize: SettingsService.pageSizeForPagination,
      pageIndex: +this.boardNavigationStateService.getState('board-medical-case-filter-request-cases-pagination-index'),
      statuses: [],
      medicalStatus: [],
      customerProfileIds: [],
      assessmentType: [],
      customerProfileId: null,
      fromDate: this.defaultFrom,
      toDate: this.defaultTo,
    });

    this.caseFilterRequest = new CaseFilterRequest({
      sortBy: 'createdOn',
      sortOrder: SortOrder.desc,
      pageSize: SettingsService.pageSizeForPagination,
      pageIndex: +this.boardNavigationStateService.getState('board-task-cases-pagination-index'),
      statuses: [],
      claimStatus: [],
      incidentCoveredStates: [],
      customerProfileId: null,
      customerProfileIds: [],
    });

    this.caseIncidentEventsRequest = new CaseIncidentEventsRequest({
      sortBy: 'createdOn',
      sortOrder: SortOrder.desc,
      pageSize: SettingsService.pageSizeForPagination,
      pageIndex: +this.boardNavigationStateService.getState('board-incident-event-case-pagination-index'),
      eventKeys: [],
      statuses: [],
      customerProfileId: null,
      incidentCountry: [],
      causeLevel1Code: null,
      causeLevel2Code: null,
      causeLevel3Code: null,
      noIncidentDate: false,
      selectedTeamNames: [],
      customerProfileIds: [],
      inOutPatient: [],
      caseTypeCodes: [],
      transportAssistanceType: []
    });


    this.caseTransportRequest = new CaseTransportRequest({
      sortBy: 'defaultSort',
      sortOrder: SortOrder.asc,
      pageSize: SettingsService.pageSizeForPagination,
      pageIndex: +this.boardNavigationStateService.getState('board-medical-case-filter-request-cases-pagination-index'),
      customerProfileIds: [],
      customerProfileId: null,
      repatriationForm: [],
      currentCountry: [],
      caseDetails: [],
      adtCodes: [],
      caseTypeCodes: [],
    });



    this.shortcutSubscriptions.push(
      );
   }
  @Inject(LOCALE_ID) private locale: string = 'en-DK';

  initGeneralCases() {
    const filterJSON = sessionStorage.getItem('board-case-filter-request');
    const filterJSONClaims = sessionStorage.getItem('board-case-filter-request-claims-status');
    if (!!filterJSON && !!filterJSONClaims) {
      const parsedBoardCaseFilterRequest = JSON.parse(filterJSON);
      this.caseFilterRequest = new CaseFilterRequest(parsedBoardCaseFilterRequest);
      this.caseFilterRequest.fromDate = this.deserializeDate(parsedBoardCaseFilterRequest.fromDate);
      this.caseFilterRequest.toDate = this.deserializeDate(parsedBoardCaseFilterRequest.toDate);
      this.claimStatus = JSON.parse(filterJSONClaims);
      this.updateCasesSelectedCaseWorker();
    } else {
      this.caseFilterRequest = new CaseFilterRequest({
        sortBy: 'createdOn',
        sortOrder: SortOrder.desc,
        pageSize: SettingsService.pageSizeForPagination,
        pageIndex: 0,
        statuses: [],
        claimStatus: [],
        incidentCoveredStates: [],
        customerProfileId: null,
      });
      this.updateCasesSelectedCaseWorker();
      this.clearBoardCaseFilter();
    }
    if (this.currentTab === TabsEnum.GENERAL) {
      this.updateCasesSelectedCaseWorker();
    }
   }

   initMedicalCases() {
    const filterMadicalJSON = sessionStorage.getItem('board-case-medical-filter-request');
    if (!!filterMadicalJSON) {

      const parsedBoardMedicalCaseFilterRequest = JSON.parse(filterMadicalJSON);
      this.caseMedicalFilterRequest = new CaseMedicalFilterRequest(parsedBoardMedicalCaseFilterRequest);
      this.caseMedicalFilterRequest.fromDate = this.deserializeDate(parsedBoardMedicalCaseFilterRequest.fromDate);
      this.caseMedicalFilterRequest.toDate = this.deserializeDate(parsedBoardMedicalCaseFilterRequest.toDate);
    }
    if (this.currentTab === TabsEnum.MEDICAL) {
      this.updateCasesSelectedCaseWorker();
    }
  }

  initIncidentEventCases() {
      const filterIncidentEventJSON = sessionStorage.getItem('board-case-Incident-event-filter-request');
      if (!!filterIncidentEventJSON) {
        const parsedBoardMedicalCaseFilterRequest = JSON.parse(filterIncidentEventJSON);
        this.caseIncidentEventsRequest = new CaseIncidentEventsRequest(parsedBoardMedicalCaseFilterRequest);
        this.caseIncidentEventsRequest.fromDate = this.deserializeDate(parsedBoardMedicalCaseFilterRequest.fromDate);
        this.caseIncidentEventsRequest.toDate = this.deserializeDate(parsedBoardMedicalCaseFilterRequest.toDate);
        this.caseIncidentEventsRequest.incidentFromDate = this.deserializeDate(parsedBoardMedicalCaseFilterRequest.incidentFromDate);
        this.caseIncidentEventsRequest.incidentToDate = this.deserializeDate(parsedBoardMedicalCaseFilterRequest.incidentToDate);
      }
      if(this.currentTab === TabsEnum.EVENTS) {
        this.updateCasesSelectedCaseWorker();
      }
   }

   initTransportCases() {
    const filterTransportJSON = sessionStorage.getItem('board-case-transport-filter-request');
    if (!!filterTransportJSON) {
      const parsedBoardTransportCaseFilterRequest = JSON.parse(filterTransportJSON);
      this.caseTransportRequest = new CaseTransportRequest(parsedBoardTransportCaseFilterRequest);
      this.caseTransportRequest.fromDate = this.deserializeDate(parsedBoardTransportCaseFilterRequest.fromDate);
      this.caseTransportRequest.toDate = this.deserializeDate(parsedBoardTransportCaseFilterRequest.toDate);
      this.caseTransportRequest.incidentFromDate = this.deserializeDate(parsedBoardTransportCaseFilterRequest.incidentFromDate);
      this.caseTransportRequest.incidentToDate = this.deserializeDate(parsedBoardTransportCaseFilterRequest.incidentToDate);
    }
    if(this.currentTab === TabsEnum.TRANSPORT) {
      this.updateCasesSelectedCaseWorker();
    }
 }


  ngOnInit() {
    this.initGeneralCases();
    this.initMedicalCases();
    this.initIncidentEventCases();
    this.initTransportCases();
    this.maxMoment = moment(this.currentDate).endOf('day');
    this.minMoment = moment(this.currentDate).subtract(1, 'M').startOf('month');
    this.initStatusDropdown();
    this.initClaimsStatusDropdown();
    this.initAssessmentTypeDropdown();
    this.initIncidentCoveredStatusDropdown();
    this.selectCaseTabFromSessionStorage();
    this.initCountryListDropdown();
    this.initIncidentEventsDropdown();
    this.initIncidentCauses();
    this.initPatientTypeDropdown();
    this.initTransportArrangedDropdown();
    this.initCaseTypeDropdown();
    this.initRepatriationFormDropdown();
    this.initCaseDetailsDropdown();
    this.initMultiCaseDetailsDropdown();
    this.caseFilterRequest.pageIndex = this.boardNavigationStateService.getState('board-task-cases-pagination-index') ? parseInt(this.boardNavigationStateService.getState('board-task-cases-pagination-index'), 10) : 0;
    this.caseIncidentEventsRequest.pageIndex = this.boardNavigationStateService.getState('board-incident-event-case-pagination-index') ? parseInt(this.boardNavigationStateService.getState('board-incident-event-case-pagination-index'), 10) : 0;
    this.caseMedicalFilterRequest.pageIndex = this.boardNavigationStateService.getState('board-medical-case-filter-request-cases-pagination-index') ? parseInt(this.boardNavigationStateService.getState('board-medical-case-filter-request-cases-pagination-index'), 10) : 0;
    this.caseTransportRequest.pageIndex = this.boardNavigationStateService.getState('board-transport-case-filter-request-cases-pagination-index') ? parseInt(this.boardNavigationStateService.getState('board-transport-case-filter-request-cases-pagination-index'), 10) : 0;

    this.updateCaseCounts();
    this.$caseCountSubscr = interval(HelperService.HALF_MINUTE_IN_MILLIS).pipe(takeUntil(this.stopCallInterval$)).subscribe(val => {
      this.updateCaseCounts();
    });
  }

  updateCaseCounts(): void {
    if (!this.caseMedicalFilterRequest.adtCodesForCount) {
      this.caseMedicalFilterRequest.adtCodesForCount = this.caseMedicalFilterRequest.adtCodes;
    }
    this.caseBoardService.getBoardMedicalCasesCount(this.caseMedicalFilterRequest).pipe(catchError(err => {
      if (err.status === 401 ) {
        this.stopCallInterval$.next(true);
      }
      return throwError(err);
    })).subscribe(
      result => {
        this.globalNumberMedicalCases = result;
      }
    );
    this.caseBoardService.getBoardIncidentEventCasesCount().pipe(catchError(err => {
      if (err.status === 401) {
        this.stopCallInterval$.next(true);
      }
      return throwError(err);
    })).subscribe(
      result => {
        this.globalNumberIncidentEventCases = result;
      }
    );

    this.caseBoardService.getBoardTransportCasesCount().pipe(catchError(err => {
      if (err.status === 401) {
        this.stopCallInterval$.next(true);
      }
      return throwError(err);
    })).subscribe(
      result => {
        this.globalNumberTransportCases = result;
      }
    );
  }

  getCaseCount(tab: TabsEnum): string {
    switch (tab) {
      case (TabsEnum.MEDICAL):
        if (this.caseMedicalFilterResponse) {
          return '(' + this.caseMedicalFilterResponse.totalNumber + ' of ' + this.globalNumberMedicalCases + ')';
        } else {
          return '(' + this.globalNumberMedicalCases + ')';
        }

      case (TabsEnum.EVENTS):
        if (this.caseIncidentEventFilterResponse) {
          return '(' + this.caseIncidentEventFilterResponse.totalNumber  + ' of ' + this.globalNumberIncidentEventCases + ')';
        } else {
          return '(' + this.globalNumberIncidentEventCases + ')';
        }


      case (TabsEnum.TRANSPORT):
        if (this.caseTransportFilterResponse) {
          return '(' + this.caseTransportFilterResponse.totalNumber  + ' of ' + this.globalNumberTransportCases + ')';
        } else {
          return '(' + this.globalNumberTransportCases + ')';
        }
      }
  }

  private deserializeDate(dateTimeString: string): moment.Moment {
    if (!!dateTimeString) {
      if (dateTimeString.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?(Z|[-+]\d{2}:\d{2})?/)) {
        return moment(dateTimeString);
      } else {
        return moment(dateTimeString.substr(0, 10));
      }
    } else {
      return null;
    }
  }

  private initAssessmentTypeDropdown() {
    this.masterListService.getCommitmentBusinessKeyValuesByServiceTypeEnum(ServiceTypeEnum.MEDICAL_ASSESSMENT).subscribe(result => {
      this.assessmentTypes = result;
    });
  }

  private initClaimsStatusDropdown() {
    this.claimsDropDownStatus = [
      new DropdownDictionary('NOT_RELEVANT', this.translateService.instant('HANDLING-AREA-NOT-RELEVANT')),
      new DropdownDictionary(HandlingAreaStatusEnum.OPEN, this.translateService.instant('HANDLING-AREA-OPEN')),
      new DropdownDictionary(HandlingAreaStatusEnum.REOPENED, this.translateService.instant('HANDLING-AREA-REOPENED')),
      new DropdownDictionary(HandlingAreaStatusEnum.CLOSED, this.translateService.instant('HANDLING-AREA-CLOSED')),
    ];
  }


  private initStatusDropdown() {
    this.status = [
      new DropdownDictionary(HandlingAreaStatusEnum.OPEN, this.translateService.instant('HANDLING-AREA-OPEN')),
      new DropdownDictionary(HandlingAreaStatusEnum.CLOSED, this.translateService.instant('HANDLING-AREA-CLOSED')),
      new DropdownDictionary(HandlingAreaStatusEnum.REOPENED, this.translateService.instant('HANDLING-AREA-REOPENED')),
    ];
  }

  private initIncidentCoveredStatusDropdown() {
    this.caseIncidentCoveredStatus = [
      new DropdownDictionary(ScreeningStates.YES, this.translateService.instant('board-task-screening-states-yes')),
      new DropdownDictionary(ScreeningStates.NO, this.translateService.instant('board-task-screening-states-no')),
      new DropdownDictionary(ScreeningStates.TO_BE_DETERMINED, this.translateService.instant('board-task-screening-states-to-be-determined')),
    ];
  }

  private initMultiCaseDetailsDropdown() {
    this.caseDetails = [
      new DropdownDictionary(CaseDetails.REM, CaseDetails.REM + " - " +  this.translateService.instant('board-task-table-case-details-text-' + CaseDetails.REM)),
      new DropdownDictionary(CaseDetails.NIM, CaseDetails.NIM + " - " +  this.translateService.instant('board-task-table-case-details-text-' + CaseDetails.NIM)),
      new DropdownDictionary(CaseDetails.LIM, CaseDetails.LIM + " - " +  this.translateService.instant('board-task-table-case-details-text-' + CaseDetails.LIM)),
      new DropdownDictionary(CaseDetails.CLM, CaseDetails.CLM + " - " +  this.translateService.instant('board-task-table-case-details-text-' + CaseDetails.CLM)),
      new DropdownDictionary(CaseDetails.AIR, CaseDetails.AIR + " - " +  this.translateService.instant('board-task-table-case-details-text-' + CaseDetails.AIR)),
      new DropdownDictionary(CaseDetails.STR, CaseDetails.STR + " - " +  this.translateService.instant('board-task-table-case-details-text-' + CaseDetails.STR)),
      new DropdownDictionary(CaseDetails.EXT, CaseDetails.EXT + " - " +  this.translateService.instant('board-task-table-case-details-text-' + CaseDetails.EXT)),
      new DropdownDictionary(CaseDetails.SIT, CaseDetails.SIT + " - " +  this.translateService.instant('board-task-table-case-details-text-' + CaseDetails.SIT)),
      new DropdownDictionary(CaseDetails.REG, CaseDetails.REG + " - " +  this.translateService.instant('board-task-table-case-details-text-' + CaseDetails.REG)),
      new DropdownDictionary(CaseDetails.DOC, CaseDetails.DOC + " - " +  this.translateService.instant('board-task-table-case-details-text-' + CaseDetails.DOC)),
      new DropdownDictionary(CaseDetails.NUR, CaseDetails.NUR + " - " +  this.translateService.instant('board-task-table-case-details-text-' + CaseDetails.NUR)),
      new DropdownDictionary(CaseDetails.NME, CaseDetails.NME + " - " +  this.translateService.instant('board-task-table-case-details-text-' + CaseDetails.NME)),
      new DropdownDictionary(CaseDetails.OXY, CaseDetails.OXY + " - " +  this.translateService.instant('board-task-table-case-details-text-' + CaseDetails.OXY)),
      new DropdownDictionary(CaseDetails.EQU, CaseDetails.EQU + " - " +  this.translateService.instant('board-task-table-case-details-text-' + CaseDetails.EQU)),
    ];
  }

  private initPatientTypeDropdown() {
    this.patientType = Object.keys(PatientType).map(
      key => new DropdownDictionary(PatientType[key], this.translateService.instant('board-task-table-case-details-dropdown-' + key.toString()))
    );
  }

  private initTransportArrangedDropdown() {
    this.transportArranged = Object.keys(TransportAssistanceType).map(
      key => new DropdownDictionary(TransportAssistanceType[key], this.translateService.instant('incident-event-transport-arranged-by-sos-' + key.toLowerCase().replace(/_/g, '-')))
    );
  }

  private initRepatriationFormDropdown() {
    this.repatriationForm = Object.keys(RepatriationFormEnum).map(
      key => new DropdownDictionary(RepatriationFormEnum[key], this.translateService.instant('medical-assessment-repatriaion-form-' + key.toLowerCase().replace(/_/g, '-')))
    );
  }

  private initCaseDetailsDropdown() {
    this.caseDetails = [
      new DropdownDictionary(ScreeningStates.YES, this.translateService.instant('board-task-screening-states-yes')),
      new DropdownDictionary(ScreeningStates.NO, this.translateService.instant('board-task-screening-states-no')),
      new DropdownDictionary(ScreeningStates.TO_BE_DETERMINED, this.translateService.instant('board-task-screening-states-to-be-determined')),
    ];
  }

  private initCaseTypeDropdown() {
    this.caseTypesService.getCaseTypesAll().subscribe(
      result =>
        this.caseTypes = result
    );
  }

  private initIncidentCauses() {
  this.incidentService.getIncidentCauses().subscribe(result => {
    this.incidentCausesLevel1 = result;
    if(this.incidentCausesLevel1[0].id != "") {
      this.incidentCausesLevel1.splice(0,0, new CausesLevel1DD({name: "All", id: null}));
    }
    this.initCauses();
    });
  }

  openCauseLevel1Dropdown() {
    this.caseCauseLevel1Component.setFocus({ selectCause: true });
  }

  openCauseLevel2Dropdown() {
    this.caseCauseLevel2Component.setFocus({ selectCause: true });
  }

  openCauseLevel3Dropdown() {
    if(!!this.caseIncidentEventsRequest.causeLevel2Code && this.incidentCausesLevel3?.length !== 0) {
      this.caseCauseLevel3Component.setFocus({ selectCause: true });
    }
  }

  selectedEventChanged(event) {
    this.caseIncidentEventsRequest.eventKeys = event.indexOf(null) > -1 ? [] : event;
    this.loadIncidentEventCaseViewModels(this.caseIncidentEventsRequest, true);
  }

  selectedCountryChanged(event) {
    this.caseIncidentEventsRequest.incidentCountry = event.indexOf(null) > -1 ? [] : event;
    this.loadIncidentEventCaseViewModels(this.caseIncidentEventsRequest, true);
  }

  selectedCurrentCountryChanged(event) {
    this.caseTransportRequest.currentCountry = event.indexOf(null) > -1 ? [] : event;
    this.loadTransportCaseViewModels(this.caseTransportRequest, true);
  }

  selectedRepatriationFormChanged(event) {
    this.caseTransportRequest.repatriationForm = event.indexOf(null) > -1 ? [] : event;
    this.loadTransportCaseViewModels(this.caseTransportRequest, true);
  }

  incidentCauseLevel1Changed(): void {
    this.initCausesLevel2();
    this.caseIncidentEventsRequest.causeLevel2Code = undefined;
    this.caseIncidentEventsRequest.causeLevel3Code = undefined;
    this.caseCauseLevel2Component.setFocus({ selectCause: true });
    this.loadIncidentEventCaseViewModels(this.caseIncidentEventsRequest, true);
  }

  incidentCauseLevel2Changed(): void {
    this.initCausesLevel3();
    this.caseIncidentEventsRequest.causeLevel3Code = undefined;
    if(this.incidentCausesLevel3?.length !== 0) {
      this.caseCauseLevel3Component.setFocus({ selectCause: true });
    }
    this.loadIncidentEventCaseViewModels(this.caseIncidentEventsRequest, true);
  }

  private initCauses(): void {
    this.initCausesLevel2();
    this.initCausesLevel3();
  }

  private initCausesLevel2(): void {
    if (this.caseIncidentEventsRequest.causeLevel1Code && this.incidentCausesLevel1 && this.caseIncidentEventsRequest.causeLevel1Code != "") {
      const selectedIncidentCauseLevel1 = this.incidentCausesLevel1.find(level1 => level1.id === this.caseIncidentEventsRequest.causeLevel1Code);
      this.incidentCausesLevel2 = selectedIncidentCauseLevel1 ? selectedIncidentCauseLevel1.level2Causes : [];
      if(this.incidentCausesLevel2[0].id != "" && this.incidentCausesLevel2[0].id != null) {
        this.incidentCausesLevel2.splice(0,0, new CausesLevel2DD({name: "All", id: null}));
      }
    }
  }

  private initCausesLevel3(): void {
    if (this.caseIncidentEventsRequest.causeLevel2Code && this.incidentCausesLevel2 && this.caseIncidentEventsRequest.causeLevel2Code != "") {
      const selectedIncidentCauseLevel2 = this.incidentCausesLevel2.find(level2 => level2.id === this.caseIncidentEventsRequest.causeLevel2Code);
      this.incidentCausesLevel3 = selectedIncidentCauseLevel2 ? selectedIncidentCauseLevel2.level3Causes : [];
      if(this.incidentCausesLevel3.length != 0) {
        if(this.incidentCausesLevel3[0].id != "" && this.incidentCausesLevel3[0].id != null) {
          this.incidentCausesLevel3.splice(0,0, new CausesLevel3DD({name: "All", id: null}));
        }
      }
    }
  }

  private initCountryListDropdown() {
    this.dictionaryService.getAllCountries().subscribe(
      result => {
        this.countryList = result;
        this.countryList.forEach(country => {
          this.countryListDropdown.push(new DropdownDictionary(country.code, country.countryName ))
        })
        this.countryListDropdown.splice(0,0, new DropdownDictionary(null, 'All'));
      }
    );
  }

  private initIncidentEventsDropdown() {
    this.masterListService.getIncidentEvents().subscribe(result => {
    this.incidentEventsDropdown = [];
    this.incidentEvents = result;
    this.incidentEvents.sort((a, b) => {
      if (a.dateOfEvent === null || b.dateOfEvent === null) {
        return -2;
      }
      if (a.dateOfEvent > b.dateOfEvent) {
        return -1;
      }
      if (a.dateOfEvent < b.dateOfEvent) {
        return 1;
      }
    })
    this.incidentEvents.forEach(item => {
      if(!item.hiddenInEventDropDown) {
        this.incidentEventsDropdown.push(new DropdownDictionary(item.businessKey, item.name));
      }
    });
    this.incidentEventsDropdown.splice(0,0, new DropdownDictionary(null, 'All'));
  });
  }

  claimsTypeSelectChange() {
      let copyclaimStatus: string[] = [];
      if (this.claimStatus.includes('NOT_RELEVANT')) {
        if (!this.notRelavantJustSelected) {
          this.claimStatus = [];
          this.claimStatus.push('NOT_RELEVANT');
          this.caseFilterRequest.claimStatus = [];
          this.notRelavantJustSelected = !this.notRelavantJustSelected;
        } else if (this.notRelavantJustSelected) {
          const index = this.claimStatus.indexOf('NOT_RELEVANT');
          this.claimStatus.splice(index, 1);
          copyclaimStatus = JSON.parse(JSON.stringify(this.claimStatus));
          this.caseFilterRequest.claimStatus = [];
          this.claimStatus = [];
          this.claimStatus = copyclaimStatus;
          this.claimStatus.forEach(element => {
            this.caseFilterRequest.claimStatus.push(element);
          });
          this.notRelavantJustSelected = !this.notRelavantJustSelected;
        }
      } else {
        this.caseFilterRequest.claimStatus = [];
        this.claimStatus.forEach(element => {
          this.caseFilterRequest.claimStatus.push(element);
        });
      }
      this.loadCaseViewModels(this.caseFilterRequest, false);
  }

  ngOnDestroy(): void {
    this.boardNavigationStateService.saveState('board-task-cases-pagination-index', JSON.stringify(this.caseFilterRequest.pageIndex));

    sessionStorage.setItem('board-case-filter-request', JSON.stringify(this.caseFilterRequest));
    sessionStorage.setItem('board-case-medical-filter-request', JSON.stringify(this.caseMedicalFilterRequest));
    sessionStorage.setItem('board-case-Incident-event-filter-request', JSON.stringify(this.caseIncidentEventsRequest));
    sessionStorage.setItem('board-case-transport-filter-request', JSON.stringify(this.caseTransportRequest));
    sessionStorage.setItem('board-case-filter-request-claims-status', JSON.stringify(this.claimStatus));
    sessionStorage.setItem('case-tab-selected', JSON.stringify(this.currentTab));
  }

  selectCaseTabFromSessionStorage() {
    const filterJSON = sessionStorage.getItem('case-tab-selected');
    if (!!filterJSON) {
      const caseTabSelected = JSON.parse(filterJSON);
      this.active = caseTabSelected;
      this.onTabChange(this.active);
    }
  }


  claimsTypeSelectText(): string {
    if (this.claimStatus.length === 1 && this.claimStatus.includes('NOT_RELEVANT')) {
      return  this.translateService.instant('HANDLING-AREA-NOT-RELEVANT');
    } else {
      return this.claimStatus.length + ' ' + this.translateService.instant('board-case-filter-claims-some-selected');
    }
  }

  updateCaseViewModelsCustomerProfile(id: number) {
    this.caseFilterRequest.customerProfileId = id;
    this.loadCaseViewModels(this.caseFilterRequest, false);
  }

  updateCaseMedicalViewModelsCustomerProfile(id: number) {
    this.caseMedicalFilterRequest.customerProfileId = id;
    this.loadMedicalCaseViewModels(this.caseMedicalFilterRequest, false);
  }

  updateCaseIncidentEventViewModelsCustomerProfile(id: number) {
    this.caseIncidentEventsRequest.customerProfileId = id;
    this.loadIncidentEventCaseViewModels(this.caseIncidentEventsRequest, false);
  }

  get ValidationTypeEnum() {
    return ValidationTypeEnum;
  }

  selectedCausesChanged(event) {
    this.causeDropdownElement = event;
  }

  clearBoardCaseFilter() {
    this.scrollPosition = 0;
    this.claimStatus = [];
    this.claimStatus.push('NOT_RELEVANT');
    this.caseFilterRequest.fromDate = moment().subtract(7, 'd').startOf('day');
    this.caseFilterRequest.toDate = null;
    this.caseFilterRequest.sortBy = 'createdOn';
    this.caseFilterRequest.sortOrder = SortOrder.desc;
    this.caseFilterRequest.pageSize = SettingsService.pageSizeForPagination;
    this.caseFilterRequest.pageIndex = 0;
    this.caseFilterRequest.statuses = [];
    this.caseFilterRequest.claimStatus = [];
    this.caseFilterRequest.incidentCoveredStates = [];
    this.caseFilterRequest.customerProfileId = null;
    this.caseFilterRequest.customerProfileIds = [];
    this.profileComponent?.resetCustomerProfile();
    this.refreshBoardCases();
    this.multipleSelectCustomerProfileComponent?.clearSelectedCustomerProfiles();
  }

  clearBoardMedicalCaseFilter() {
    this.currentDate  = moment();
    this.defaultFrom = moment(this.currentDate).subtract(1, 'd').startOf('day');
    this.defaultTo = moment(this.currentDate).endOf('day');
    this.scrollPosition = 0;
    this.caseMedicalFilterRequest.fromDate = this.defaultFrom;
    this.caseMedicalFilterRequest.toDate = this.defaultTo;
    this.caseMedicalFilterRequest.sortBy = 'assignedOn';
    this.caseMedicalFilterRequest.sortOrder = SortOrder.desc;
    this.caseMedicalFilterRequest.pageSize = SettingsService.pageSizeForPagination;
    this.caseMedicalFilterRequest.pageIndex = 0;
    this.caseMedicalFilterRequest.statuses = [];
    this.caseMedicalFilterRequest.medicalStatus = [];
    this.caseMedicalFilterRequest.assessmentType = [];
    this.caseMedicalFilterRequest.customerProfileId = null;
    this.caseMedicalFilterRequest.customerProfileIds = [];
    this.multipleSelectCustomerProfileMedicalComponent?.clearSelectedCustomerProfiles();
    this.refreshBoardCases();
  }


  clearBoardIncidentEventCaseFilter() {
    this.currentDate  = moment();
    this.defaultFrom = moment(this.currentDate).subtract(1, 'd').startOf('day');
    this.defaultTo = moment(this.currentDate).endOf('day');
    this.scrollPosition = 0;
    this.caseIncidentEventsRequest.fromDate = null;
    this.caseIncidentEventsRequest.toDate = null;
    this.caseIncidentEventsRequest.sortBy = 'createdOn';
    this.caseIncidentEventsRequest.sortOrder = SortOrder.desc;
    this.caseIncidentEventsRequest.pageSize = SettingsService.pageSizeForPagination;
    this.caseIncidentEventsRequest.pageIndex = 0;
    this.caseIncidentEventsRequest.statuses = [];
    this.caseIncidentEventsRequest.eventKeys = [];
    this.caseIncidentEventsRequest.incidentCountry = [];
    this.caseIncidentEventsRequest.incidentFromDate =null;
    this.caseIncidentEventsRequest.incidentToDate = null;
    this.caseIncidentEventsRequest.customerProfileId = null;
    this.caseIncidentEventsRequest.customerProfileIds = [];
    this.caseIncidentEventsRequest.causeLevel1Code = null;
    this.caseIncidentEventsRequest.causeLevel2Code = null;
    this.caseIncidentEventsRequest.causeLevel3Code = null;
    this.caseIncidentEventsRequest.noIncidentDate = false,
    this.caseIncidentEventsRequest.transportAssistanceType = [],
    this.caseIncidentEventsRequest.caseTypeCodes = [],
    this.caseIncidentEventsRequest.inOutPatient = []
    this.multipleSelectCustomerProfileEventsComponent?.clearSelectedCustomerProfiles();
    this.refreshBoardCases();
  }


  clearBoardTransportCaseFilter() {
    this.caseTransportRequest.fromDate = null;
    this.caseTransportRequest.toDate = null;
    this.caseTransportRequest.incidentFromDate =null;
    this.caseTransportRequest.incidentToDate = null;
    this.caseTransportRequest.customerProfileIds = [];
    this.caseTransportRequest.caseTypeCodes = [],
    this.caseTransportRequest.repatriationForm = [],
    this.caseTransportRequest.currentCountry = [],
    this.caseTransportRequest.caseDetails = [],
    this.caseTransportRequest.sortBy = 'defaultSort';
    this.caseTransportRequest.sortOrder = SortOrder.asc;
    this.caseTransportRequest.pageSize = SettingsService.pageSizeForPagination;
    this.caseTransportRequest.pageIndex = 0;
    this.multipleSelectCustomerProfileTransportComponent?.clearSelectedCustomerProfiles();
    this.refreshBoardCases();
  }

  updateCasesSelectedCaseWorker() {
    if (this.$subscrCasesSelectedCaseWorker) {
      this.$subscrCasesSelectedCaseWorker.unsubscribe();
    }
    this.$subscrCasesSelectedCaseWorker = this.boardShareService.getSelectedCaseWorker().subscribe(result => {
      if (result != null) {
        const userId = result.userId && result.userId.toString();
        const userIds = result.userIds;
        const userIdsForMedical = result.userIds;
        const userIdsForIncidentEvents = result.userIds;
        const userIdsForTransport = result.userIds;
        const adtCode = result.adtCode && result.adtCode.toString();
        const adtCodes = result.adtCodes;
        const adtCodesForCount = result.adtCodesForCount;
        const selectedTeamNames = result.selectedTeamNames;
        this.caseFilterRequest.userId = userId;
        this.caseFilterRequest.adtCode = adtCode;
        this.caseFilterRequest.adtCodes = this.caseMedicalFilterRequest.adtCodes = this.caseIncidentEventsRequest.adtCodes = this.caseTransportRequest.adtCodes = adtCodes;
        this.caseFilterRequest.userIds = userIds;
        this.caseMedicalFilterRequest.userIds = userIdsForMedical;
        this.caseIncidentEventsRequest.userIds = userIdsForIncidentEvents;
        this.caseTransportRequest.userIds = userIdsForTransport;
        this.caseIncidentEventsRequest.selectedTeamNames = selectedTeamNames;
        this.caseFilterRequest.adtCodesForCount = this.caseMedicalFilterRequest.adtCodesForCount =  this.caseIncidentEventsRequest.adtCodesForCount = this.caseTransportRequest.adtCodesForCount = adtCodesForCount;
        this.caseFilterRequest.includeOtherTeams = this.caseMedicalFilterRequest.includeOtherTeams = this.caseIncidentEventsRequest.includeOtherTeams = this.caseTransportRequest.includeOtherTeams =  !result.teamOnly;
        this.caseFilterRequest.pageIndex = this.boardNavigationStateService.getState('board-task-cases-pagination-index') ? parseInt(this.boardNavigationStateService.getState('board-task-cases-pagination-index'), 10) : 0;
        this.refreshBoardCases();
      }
    });
  }

  selectedCustomerProfilesChange(customerProfiles) {
    this.caseFilterRequest.customerProfileIds = customerProfiles;
    this.loadCaseViewModels(this.caseFilterRequest, false);
  }

  selectedMedicalCustomerProfilesChange(customerProfiles) {
    this.caseMedicalFilterRequest.customerProfileIds = customerProfiles;
    this.loadMedicalCaseViewModels(this.caseMedicalFilterRequest, false);
  }

  selectedEventsCustomerProfilesChange(customerProfiles) {
    this.caseIncidentEventsRequest.customerProfileIds = customerProfiles;
    this.loadIncidentEventCaseViewModels(this.caseIncidentEventsRequest, false);
  }

  selectedTranspoprtCustomerProfilesChange(customerProfiles) {
    this.caseTransportRequest.customerProfileIds = customerProfiles;
    this.loadTransportCaseViewModels(this.caseTransportRequest, false);
  }

  get casesSorting() {
    return new TableSort({ sortBy: this.caseFilterRequest.sortBy, sortOrder: this.caseFilterRequest.sortOrder });
  }

  get medicalCasesSorting() {
    return new TableSort({ sortBy: this.caseMedicalFilterRequest.sortBy, sortOrder: this.caseMedicalFilterRequest.sortOrder });
  }

  get incidentEventCasesSorting() {
    return new TableSort({ sortBy: this.caseIncidentEventsRequest.sortBy, sortOrder: this.caseIncidentEventsRequest.sortOrder });
  }

  get transportCasesSorting() {
    return new TableSort({ sortBy: this.caseTransportRequest.sortBy, sortOrder: this.caseTransportRequest.sortOrder });
  }

  sortingAction(taskSorting: TableSort) {
    this.caseFilterRequest.sortBy = taskSorting.sortBy;
    this.caseFilterRequest.sortOrder = taskSorting.sortOrder;
    this.loadCaseViewModels(this.caseFilterRequest, false);
  }

  sortingActionMedical(taskSorting: TableSort) {
    this.caseMedicalFilterRequest.sortBy = taskSorting.sortBy;
    this.caseMedicalFilterRequest.sortOrder = taskSorting.sortOrder;
    this.loadMedicalCaseViewModels(this.caseMedicalFilterRequest, false);
  }

  sortingActionIncidentEvent(taskSorting: TableSort) {
    this.caseIncidentEventsRequest.sortBy = taskSorting.sortBy;
    this.caseIncidentEventsRequest.sortOrder = taskSorting.sortOrder;
    this.loadIncidentEventCaseViewModels(this.caseIncidentEventsRequest, false);
  }

  sortingActionTransport(taskSorting: TableSort) {
    this.caseTransportRequest.sortBy = taskSorting.sortBy;
    this.caseTransportRequest.sortOrder = taskSorting.sortOrder;
    this.loadTransportCaseViewModels(this.caseTransportRequest, false);
  }

  paginationChanged(index: number): void {
    this.scrollPosition = 0;
    if(this.currentTab === TabsEnum.GENERAL) {
      this.caseFilterRequest.pageIndex = index;
      this.boardNavigationStateService.removeCases();
    }
    if(this.currentTab === TabsEnum.MEDICAL) {
      this.caseMedicalFilterRequest.pageIndex = index;
      this.boardNavigationStateService.removeMedicalCases();
    }
    if(this.currentTab === TabsEnum.EVENTS) {
      this.caseIncidentEventsRequest.pageIndex = index;
      this.boardNavigationStateService.removeIncidents()
    }
    this.refreshBoardCases();

  }

  private loadCaseViewModels(caseFilterRequest: CaseFilterRequest, setPageIndexToZero: boolean) {
    if (setPageIndexToZero) {
      this.scrollPosition = 0;
      caseFilterRequest.pageIndex = 0;
    }
    this.refreshBoardCases();
  }

  private loadMedicalCaseViewModels(caseMedicalFilterRequest: CaseMedicalFilterRequest, setPageIndexToZero: boolean) {
    if (setPageIndexToZero) {
      this.scrollPosition = 0;
      caseMedicalFilterRequest.pageIndex = 0;
    }
    this.refreshBoardCases();
  }

  private loadIncidentEventCaseViewModels(caseIncidentEventsRequest: CaseIncidentEventsRequest, setPageIndexToZero: boolean) {
    if (setPageIndexToZero) {
      this.scrollPosition = 0;
      caseIncidentEventsRequest.pageIndex = 0;
    }
    this.refreshBoardCases();
  }

  private loadTransportCaseViewModels(caseTransportRequest: CaseTransportRequest, setPageIndexToZero: boolean) {
    if (setPageIndexToZero) {
      this.scrollPosition = 0;
      caseTransportRequest.pageIndex = 0;
    }
    this.refreshBoardCases();
  }

  private refreshBoardCases() {
    this.boardCasesRefreshing = true;
    if (this.currentTab === TabsEnum.GENERAL) {
      if (this.caseFilterRequest.userIds?.length === 0) {
        this.caseFilterRequest.userIds.push(-1);
      }
      this.caseBoardService.getBoardCases(this.caseFilterRequest).subscribe(
        result => {
          this.caseFilterResponse = result;
          this.boardCases = this.caseFilterResponse.boardCaseDtoList;
          this.boardCasesRefreshing = false;
        },
        error => console.log(error)
      );
    }
    if (this.currentTab === TabsEnum.MEDICAL) {
      this.caseBoardService.getBoardMedicalCases(this.caseMedicalFilterRequest).subscribe(
        result => {
          this.caseMedicalFilterResponse = result;
          this.boardMedicalCase = this.caseMedicalFilterResponse.boardMedicalCaseDtoList;
          this.boardCasesRefreshing = false;
        },
        error => console.log(error)
      );
    }
    if (this.currentTab === TabsEnum.EVENTS) {
      this.caseBoardService.getBoardIncidentEventCases(this.caseIncidentEventsRequest).subscribe(
        result => {
          this.caseIncidentEventFilterResponse = result;
          this.boardIncidentEventCase = this.caseIncidentEventFilterResponse.incidentEventCaseBoardViewModelDto;
          this.boardCasesRefreshing = false;
        },
        error => console.log(error)
      );
    }
    if (this.currentTab === TabsEnum.TRANSPORT) {
      this.caseBoardService.getBoardTransportCases(this.caseTransportRequest).subscribe(
        result => {
          this.caseTransportFilterResponse = result;
          this.boardTransportCase = this.caseTransportFilterResponse.boardTransportCaseDtoList;
          this.boardCasesRefreshing = false;
        },
        error => console.log(error)
      );
    }
  }

  numberOfOpenCases(openCaseNumber: number) {
    this.numberOfOpenCase = openCaseNumber;
  }

  get TabsEnum() {
    return TabsEnum;
  }

  private exportCases(): void {
    const replacer = (key: string, value: string) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = this.exportConfig.map(item => this.translate(item.value));
    const csv = (this.boardIncidentEventCase)
      .map(row =>
        this.exportConfig.map(item => JSON.stringify(item.formatter ? item.formatter(row[item.key]) : row[item.key], replacer)).join(';')
      );

    csv.unshift(header.join(';'));
    var csvArray = csv.join('\r\n');
    var BOM = "\uFEFF";
    csvArray = BOM + csvArray;
    const blob = new Blob([csvArray], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, 'all-events.csv');

    this.exportRunning = false;
  }

  private exportTransportCases(): void {
    const replacer = (key: string, value: string) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = this.exportTransportConfig.map(item => this.translate(item.value));
    const csv = (this.boardTransportCase)
      .map(row =>
        this.exportTransportConfig.map(item => JSON.stringify(item.formatter ? item.formatter(row[item.key]) : row[item.key], replacer)).join(';')
      );

    csv.unshift(header.join(';'));
    var csvArray = csv.join('\r\n');
    var BOM = "\uFEFF";
    csvArray = BOM + csvArray;
    const blob = new Blob([csvArray], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, 'all-transport-cases.csv');

    this.exportTransportRunning = false;
  }

  private coTransportFormatter(value: boolean): string | null {
    if(value === null) { return '-'; }
    else {return value === true ? 'Yes' : 'No'; }
  }

  onTabChange(event: TabsEnum) {
    if (event === TabsEnum.GENERAL) {
      this.currentTab = event;
      this.refreshBoardCases();
      this.boardShareService.changedCaseEventMenu(false);
    }

    if (event === TabsEnum.MEDICAL) {
      this.currentTab = event;
      this.refreshBoardCases();
      this.boardShareService.changedCaseEventMenu(false);
    }

    if (event === TabsEnum.EVENTS) {
      this.currentTab = event;
      this.refreshBoardCases();
      this.boardShareService.changedCaseEventMenu(true);
    }

    if (event === TabsEnum.TRANSPORT) {
      this.currentTab = event;
      this.refreshBoardCases();
      this.boardShareService.changedCaseEventMenu(false);
    }
  }

  private dateTimeFormatter(date: Date): string | null {
    return date ? formatDate(date, 'dd MMM yyyy', this.locale, 'GMT+1') : '';
  }

  private translate(str: string): string {
    return this.translateService.instant(str);
  }
  onExportButtonClicked(): void {
    if (this.exportRunning) {
      return;
    }

    if(this.boardIncidentEventCase?.length){
      this.exportRunning = true;
      this.exportCases();
    }
  }

  onExportTransportButtonClicked(): void {
    if (this.exportTransportRunning) {
      return;
    }

    if(this.boardTransportCase?.length){
      this.exportTransportRunning = true;
      this.exportTransportCases();
    }
  }

  private eventFormatter(incidentEvent: IncidentEvent): string | null {
    return incidentEvent?.name ? incidentEvent.name : null;
  }
}

enum TabsEnum {
  GENERAL,
  MEDICAL,
  EVENTS,
  TRANSPORT,
  COST_CONTROL,
  CLAIMS,
}
