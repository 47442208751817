import { OutputManagementService } from './../../../services/output-management.service';
import { Message } from './../../../models/message';
import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import '@tinymce/tinymce-angular';
declare var tinymce: any;

@Component({
  selector: 'app-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RichTextEditorComponent implements OnInit {
  @Input() caseId: string;
  @Input() showEditor: boolean;
  @Input() message: Message;
  @Output() messageUpdated: EventEmitter<Message> = new EventEmitter<Message>();

  datamodel: any;

  constructor(private outputManagementService: OutputManagementService) {}

  get tinyMCEConfiguration() {
    return {
      base_url: '/tinymce',
      suffix: '.min',
      height: 390,
      menubar: false,
      paste_data_images: true,
      fontsize_formats: '8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt 24pt 28pt 32pt 36pt',
      content_style: 'body { font-family: calibri; font-size: 11pt;  }',
      font_formats:
        'Calibri=calibri; Arial=arial,helvetica,sans-serif;' +
        'Times New Roman=times new roman,times;' +
        'Helvetica=helvetica;' +
        'Courier New=courier_newregular,courier;' +
        'Verdana=verdana,geneva;',
      plugins: [
        'print preview searchreplace autolink ' +
          'directionality visualblocks visualchars fullscreen image link ' +
          'media template codesample table charmap hr pagebreak nonbreaking ' +
          'anchor toc insertdatetime advlist lists wordcount ' +
          'imagetools textpattern noneditable help ' +
          'charmap quickbars emoticons '
      ],
      toolbar:
        'bold italic underline strikethrough | fontselect fontsizeselect formatselect | \
        alignleft aligncenter alignright alignjustify | \
        bullist numlist checklist  | outdent indent | forecolor backcolor formatpainter'
    };
  }

  ngOnInit() {}

  onFocus(e: any) {
    // Force a focus-in event, fix for missing focus-in event
    const event = new FocusEvent('focusin', {
      view: window,
      bubbles: true,
      cancelable: true
    });
    const editor = e?.editor?.iframeElement;
    if ( editor ) {
      editor.dispatchEvent(event);
    }
  }

  onBlur() {
    this.messageUpdated.emit(this.message);
  }

  get editorClass() {
    return this.showEditor === true ? 'show-editor' : 'hide-editor';
  }
}
