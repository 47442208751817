<div class="modal-content">
  <div class="modal-header">
    <div class="modal-header-title">
      {{ 'case-country-info-modal-title' | translate }} - {{ country }}
    </div>
    <div class="modal-buttons">
      <div (click)="close()">
        <img src="/assets/icons/Close White.svg" alt="X" />
      </div>
    </div>
  </div>

  <div class="modal-content-body">

    <div class="modal-body">

      <h2>This test modal is <b>not yet</b> (ready and) available in Production</h2>

      <p>{{ countryCode }} - {{ country }}!</p>

      <p><label><input type="radio" name="kortlang" id="enkort" checked="checked" /> En kort</label> - <label><input type="radio" name="kortlang" id="enlang" /> En lang (med scroll)</label></p>

      <p>Some placeholder content...</p>

      <p>A paragraph!</p>

      <p>A paragraph!</p>

      <p>A paragraph!</p>

      <p>A paragraph!</p>

      <p>A paragraph!</p>

      <p>A paragraph!</p>

      <p>A paragraph!</p>

      <p>A paragraph!</p>

      <p>A paragraph!</p>

      <p>A paragraph!</p>

      <p>A paragraph!</p>

      <p>A paragraph!</p>

      <div class="enlang">

        <p>A paragraph!</p>

        <p>A paragraph!</p>

        <p>A paragraph!</p>

        <p>A paragraph!</p>

        <p>A paragraph!</p>

        <p>A paragraph!</p>

        <p>A paragraph!</p>

        <p>A paragraph!</p>

        <p>A paragraph!</p>

        <p>A paragraph!</p>

        <p>A paragraph!</p>

        <p>A paragraph!</p>

        <p>A paragraph!</p>

        <p>A paragraph!</p>

        <p>A paragraph!</p>

        <p>A paragraph!</p>

        <p>A paragraph!</p>

        <p>A paragraph!</p>

        <p>A paragraph!</p>

        <p>A paragraph!</p>

        <p>A paragraph!</p>

        <p>A paragraph!</p>

        <p>A paragraph!</p>

      </div>


    </div>
  </div>

</div>
