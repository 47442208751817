<div class="task-message-modal-move-dialog-container">
  <div class="modal-header">
    <div class="modal-header-title" *ngIf="!showServiceOrder">
      {{ 'case-document-move-document-title' | translate }}
    </div>
    <div class="modal-header-title" *ngIf="showServiceOrder">
      {{ 'service-order-document-move-document-title' | translate }}
    </div>
    <div class="modal-buttons">
      <div (click)="closeTaskMessageDialog()">
        <img src="/assets/icons/Close White.svg" />
      </div>
    </div>
  </div>
  <div class="modal-content-body">
    <div class="input-text-header">
      <div class="modal-text" *ngIf="!showServiceOrder">
        {{ 'case-document-move-document-text' | translate }}
      </div>
      <div class="modal-text" *ngIf="showServiceOrder">
        {{ 'service-order-document-move-document-text' | translate }}
      </div>
      <div>            
        <div *ngIf="showServiceOrder">
          <div *ngIf="!!moveToServiceOrder" class="lookup-success"><span class="lookup-text">{{'task-message-modal-move-serviceorder-found' | translate}}</span></div>
          <div *ngIf="moveToServiceOrder === null" class="lookup-no-success"><span class="lookup-text">{{'task-message-modal-move-serviceorder-not-found' | translate}}</span></div>
        </div>
        <div *ngIf="!showServiceOrder">
          <div *ngIf="!!moveToCase" class="lookup-success"><span class="lookup-text">{{'task-message-modal-move-case-found' | translate}}</span></div>
          <div *ngIf="moveToCase === null" class="lookup-no-success"><span class="lookup-text">{{'task-message-modal-move-case-not-found' | translate}}</span></div>
        </div>
      </div>
    </div>
    <input
      type="text"
      class="input-component"
      [(ngModel)]="caseNumber"
      (ngModelChange)="caseNumberChange($event)"
      (keyup)="setDirty()"
      [class.invalid-case-number]="!isCaseNumberValid"
    />
    <div class="lookup-case-info">            
      <div *ngIf="!!moveToCase && (!showServiceOrder || (showServiceOrder && !!moveToServiceOrder))">
        <div class="lookup-info">
          <div class="lookup-label">{{'task-message-modal-move-casetype' | translate}}</div>{{moveToCase?.caseTypeTranslated}}
        </div>
        <div class="lookup-info" *ngIf="!!moveToServiceOrder">
          <div class="lookup-label">{{'task-message-modal-move-serviceorder-type' | translate}}</div>{{getServiceOrderTypeName(moveToServiceOrder?.type)}}
        </div>
        <div class="lookup-info">
          <div class="lookup-label">{{(showServiceOrder ? 'task-message-modal-move-can-move-to-serviceorder' : 'task-message-modal-move-can-move-to-case') | translate}}</div>          
          {{(canMove() && isValidInput() ? 'task-message-modal-move-can-move-task-yes' : 'task-message-modal-move-can-move-task-no') | translate}}
        </div>
      </div>
    </div>
    <div class="button-container-row">
      <div>
        <button class="modal-button" [class.modal-button-busy]="moveInProgress || !isValidInput() || !canMove()" (click)="moveTask()" [disabled]="moveInProgress || !isValidInput() || !canMove()">
          {{ 'case-document-table-move-document-move' | translate }}
          <img src="/assets/icons/waitForSearchresult.gif" class="spinner" [class.hide-spinner]="!moveInProgress" />
        </button>
      </div>
    </div>
  </div>
</div>

