import {HttpParams} from '@angular/common/http';

export class CaseSummaryFilter {
  caseId: string;
  queryMode: string;
  types: string[];
  dateFrom: Date;
  dateTo: Date;
  searchText: string;
  authorsIds: string[];
  pageSize: number;
  pageIndex: number;
  sortOrder: string;

  generateHttpParamsForHistory(): HttpParams {
    return this.generateHttpParams(false);
  }

  generateHttpParamsForSummary(): HttpParams {
    return this.generateHttpParams(true);
  }

  private generateHttpParams(isSummary: boolean): HttpParams {
    let params: HttpParams = new HttpParams();
    if (this.types !== undefined && this.types.length > 0) {
      if (isSummary) {
        params = params.append('types', this.types.join(','));
      } else {
        params = params.append('subObjects', this.types.join(','));
      }
    }
    if (this.queryMode !== undefined && this.queryMode != null) {
      params = params.append('queryMode', this.queryMode);
    }
    if (this.dateFrom !== undefined && this.dateFrom != null) {
      params = params.append('caseHistoryFrom', this.dateFrom.toISOString());
    }
    if (this.dateTo !== undefined && this.dateTo != null) {
      params = params.append('caseHistoryTo', this.dateTo.toISOString());
    }
    if (this.authorsIds !== undefined && this.authorsIds.length > 0) {
      params = params.append('authorsIds', this.authorsIds.join(','));
    }
    if (this.searchText !== undefined && this.searchText.trim() !== '') {
      params = params.append('searchText', this.searchText.trim());
    }
    if (this.pageIndex !== undefined) {
      params = params.append('pageIndex', '' + this.pageIndex);
    }
    if (this.pageSize !== undefined) {
      params = params.append('pageSize', '' + this.pageSize);
    }
    if (this.sortOrder !== undefined) {
      params = params.append('sortOrder', this.sortOrder);
    }
    return params;
  }

  clone(): CaseSummaryFilter {
    return Object.assign({}, this) as CaseSummaryFilter;
  }

  equals(comp: CaseSummaryFilter): boolean {
    return comp !== null &&
      this.types === comp.types &&
      this.authorsIds === comp.authorsIds &&
      this.searchText === comp.searchText &&
      this.dateFrom === comp.dateFrom &&
      this.dateTo === comp.dateTo;
  }
}
