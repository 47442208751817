import { DocumentTemplateFilter } from './../../modules/case/components/case-plans/case-plans/add-service-order/sub-components/sub-gop-details/documentTemplateFilter';
import { DocumentTemplateAdapter } from './../../shared/models/document-template';
import { AttachmentAdapter } from './../../shared/models/attachment-dto';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DocumentTemplate } from '../../shared/models/document-template';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { SettingsService } from './settings.service';
import { DocumentDraftAdapter, DocumentDraft } from 'src/app/shared/models/document-draft';
import { CaseDocument, CaseDocumentAdapter } from 'src/app/shared/models/caseDocument';

@Injectable({
  providedIn: 'root',
})
export class DocumentService extends BaseService {
  constructor(
    private http: HttpClient,
    private documentDraftAdapter: DocumentDraftAdapter,
    private caseDocumentAdapter: CaseDocumentAdapter,
    private attachmentDtoAdapter: AttachmentAdapter,
    private documentTemplateAdapter: DocumentTemplateAdapter,
    private settingsService: SettingsService
  ) {
    super(settingsService);
  }

  getGOPTemplateListMetadata(): Observable<DocumentTemplate[]> {
    return this.http.get<any>(this.baseURL + 'documents/templates', {
      headers: this.jsonHeaders,
    });
  }

  createDraft(templateId: string, seccaCaseId: number, serviceOrderId: number): Observable<DocumentDraft> {
    const data = { templateId, seccaCaseId, serviceOrderId };
    return this.http
      .post<string>(this.baseURL + 'documents/drafts/create', data, {
        headers: this.jsonHeaders,
      })
      .pipe(map(item => this.documentDraftAdapter.adapt(item)));
  }

  createDocumentFromDraft(draftId: string, serviceOrderId: number): Observable<string> {
    const data = { draftId, serviceOrderId };
    return this.http.post<string>(this.baseURL + 'documents/create/pdf', data, {
      headers: this.jsonHeaders,
    });
  }

  public postTemplatesByType(filter: DocumentTemplateFilter) {
    return this.http
      .post(this.baseURL + 'documents/templates/by-filter/', filter)
      .pipe(map((data: any[]) => data.map(item => this.documentTemplateAdapter.adapt(item))));
  }

  public getDocumentList(): Observable<CaseDocument[]> {
    return this.http
      .get(this.baseURL + 'document-service/')
      .pipe(map((data: any[]) => data.map(item => this.caseDocumentAdapter.adapt(item))));
  }

  public getCaseDocumentList(caseId: number): Observable<CaseDocument[]> {
    return this.http
      .get(this.baseURL + 'documents/case/' + caseId)
      .pipe(map((data: any[]) => data.map(item => this.caseDocumentAdapter.adapt(item))));
  }

  public getCaseDocument(documentId: string): Observable<CaseDocument> {
    return this.http.get(this.baseURL + 'documents/' + documentId).pipe(map(item => this.caseDocumentAdapter.adapt(item)));
  }

  public updateCaseDocument(caseDocument: CaseDocument): Observable<CaseDocument> {
    return this.http.post<any>(this.baseURL + 'documents/case', caseDocument, { headers: this.jsonHeaders }).pipe(map(item => this.caseDocumentAdapter.adapt(item)));
  }

  public deleteCaseDocument(documentId: string, caseId: number): Observable<CaseDocument> {
    return this.http.delete<any>(this.baseURL + 'documents/delete/' + documentId, { headers: this.jsonHeaders });
  }

  public moveCaseDocument(documentId: string, caseNumber): Observable<CaseDocument> {
    return this.http.post<any>(this.baseURL + 'documents/move' + '/' + documentId + '/' + caseNumber, { headers: this.jsonHeaders });
  }

  public getDocument(documentId: string): Observable<any> {
    return this.getDocumentUsingPath(documentId, 'document-service/data/');
  }

  public getTermsDocument(documentId: string): Observable<any> {
    return this.getDocumentUsingPath(documentId, 'document-service/product/terms/');
  }

  private getDocumentUsingPath(documentId: string, path: string): Observable<any> {
    return this.http.get(this.baseURL + path + documentId, { responseType: 'arraybuffer' }).pipe(
      map((res: any) => {
        return new Blob([res], {
          type: 'application/pdf',
        });
      })
    );
  }

  public getAttachmentDtoList(caseId: string) {
    return this.http
      .get(this.baseURL + `documents/case/${caseId}/document-names`)
      .pipe(map((data: any[]) => data.map(item => this.attachmentDtoAdapter.adapt(item))));
  }

  public getDocumentUrl(documentId: string): string {
    return this.baseURL + 'document-service/data/' + documentId;
  }

  public getTransformWordToPdf(documentId: string): Observable<any> {
    return this.http.get(this.baseURL + 'documents/getPdf/' + documentId, { responseType: 'arraybuffer' }).pipe(
      map((res: any) => {
        return new Blob([res], {
          type: 'application/pdf',
        });
      })
    );
  }

  public removePasswordFromPDF(documentId: string, password: string, caseId: number): Observable<string> {
    return this.http.get<string>(this.baseURL + 'documents/removePasswordFromPDF/' + documentId + '/' + password + '/' + caseId);
  }

  public getUserAllowedDocumentTags():  Observable<string[]> {
    return this.http.get<string[]>(this.baseURL + 'documents/userAllowedDocumentTags', { headers: this.jsonHeaders });

  }

  public geDocumentsByDocumentTag(caseId: number, documentTag: string):  Observable<CaseDocument[]> {
    return this.http.get<CaseDocument[]>(this.baseURL + `documents/${caseId}/${documentTag}`, { headers: this.jsonHeaders })
                    .pipe(map(items => items.map(item => this.caseDocumentAdapter.adapt(item))));

  }
}
