<div class="case-basics-card case-basics-insurance-details-container" *ngIf="personInsurance">
  <div [appDisableInput]="caseStateService.isCaseDisabled()">
    <div class="container">
        <div class="position-relative">
          <div class="credit-card-switch-button">
            <app-toggle-switch
              [(value)]="personInsurance.creditCard"
              (saveModel)="updateInsurance('creditCardToggle')"
              [name]="'Credit card'"
              [disabled]="!canSwitchToCreditCard()"
              [ngbPopover]="creditCardDisabledPopUpText | translate"
              triggers="hover"
              placement="left-top"
            >
            </app-toggle-switch>
          </div>
      </div>
    </div>
    <div class="d-flex position-relative">
      <div class="w-100">
        <div class="policy-lookup-status clearfix" [ngClass]="policyLookupStatus()"></div>
      </div>
    </div>

    <div class="field-row">
      <div class="col-6">
        <app-case-basics-insurance-profile
          (open)="onCustomerProfileDropDownOpen()"
          (profileUpdate)="updateInsuranceCustomerProfile($event)"
          [caseId]="caseId"
          [customerProfileId]="_personInsurance.customerProfileId"
          [personInsurance]="_personInsurance"
          [consent]="consent"
        ></app-case-basics-insurance-profile>
      </div>
      <div class="col-6">
        <app-input
          *ngIf="!personInsurance.creditCard"
          [name]="'case-basics-insurance-policyNo' | translate"
          (saveModel)="updateInsurance()"
          [(value)]="personInsurance.policyReference"
          [validate]="true"
          [recommended]="personInsurance.policyReference === '' || personInsurance.policyReference === null"
        ></app-input>
        <app-input-credit-card
          *ngIf="personInsurance.creditCard"
          [name]="'case-basics-insurance-creditCardNo' | translate"
          (saveModel)="updateInsurance()"
          [(value)]="personInsurance.creditCardNo"
          [validate]="true"
        ></app-input-credit-card>
      </div>
    </div>
    <div class="field-row">
      <div class="col-12">
        <app-case-basics-insurance-product
          [personInsurance]="_personInsurance"
          [caseId]="caseId"
          [insuranceLevels]="insuranceLevels"
          [recommended]="(this._personInsurance.customerProduct === '' || this._personInsurance.customerProduct === null) && numberOfProducts !== 0"
          (productChange)="onProductChange($event)"
          (numberOfProducts)="updateNumberOfProducts($event)">
        </app-case-basics-insurance-product>
      </div>
    </div>
    <div class="row">
      <div class="col-6 policy-ref-no-column">
        <app-drop-down
          [items]="insuranceLevels"
          [disabled]="isInsuranceLevelDisabled()"
          (selectedItemIdChange)="showWarningIfProductOrLevelChange($event)"
          [name]="(personInsurance.creditCard ? 'case-basics-insurance-cardLevel' : 'case-basics-insurance-insuranceLevel') | translate"
          [(selectedItemId)]="personInsurance.insuranceLevelId"
          [recommended]="personInsurance.insuranceLevelId === '' || personInsurance.insuranceLevelId === null"
        ></app-drop-down>
      </div>
      <div class="col-6">
        <app-input
          [name]="'case-basics-insurance-customerReferenceNo' | translate"
          [(value)]="personInsurance.customerReference"
          (saveModel)="updateInsurance()"
        ></app-input>
      </div>
    </div>

    <app-case-basics-insurance-dates class="field-row" [personInsurance]="_personInsurance"
                                     (updateInsurance)="updateInsurance($event)"></app-case-basics-insurance-dates>
    <div class="field-row">
      <div class="date-range-container col-6 d-flex pt-2">
        <div class="date-box">
          <app-input-calendar
            [withImage]="true"
            [name]="'case-basics-insurance-extensionPeriod'| translate "
            (dateChange)="extensionPeriodRangeSupport.updateStartDate($event)"
            [date]="personInsurance.insuranceExtensionFrom"
            [invalid]="extensionPeriodRangeSupport.isStartDateInValidInRange()"
          ></app-input-calendar>
        </div>
        <div class="date-spacer">-</div>
        <div class="date-box-no-name">
          <app-input-calendar
            [withImage]="true"
            [name]=" "
            (dateChange)="extensionPeriodRangeSupport.updateEndDate($event)"
            [date]="personInsurance.insuranceExtensionTo"
            [invalid]="extensionPeriodRangeSupport.isEndDateInvalidInRange()"
          ></app-input-calendar>
        </div>
      </div>
      <div class="col-6 pt-2">
        <app-drop-down
          [items]="areas"
          [name]="'case-basics-insurance-geographicalCoverageArea' | translate"
          (saveModel)="updateInsurance('geographicalCoverageArea')"
          [(selectedItemId)]="personInsurance.geographicalCoverageArea"
          [disabled]="isGeographicAreaDisabled()"
          [recommended]="personInsurance.geographicalCoverageArea === '' || personInsurance.geographicalCoverageArea === null"
        ></app-drop-down>
      </div>
    </div>
  </div>
    <div class="d-flex pt-2">
      <div style="width: 263px; padding-right: 10px;" [appDisableInput]="caseStateService.isCaseDisabled()">
        <app-drop-down
          [items]="lookupMethod"
          [name]="'case-basics-insurance-insuranceLookupMethod' | translate"
          (saveModel)="updateInsurance()"
          [(selectedItemId)]="insurancePolicyLookupMethod"
          [disabled]="!!personInsurance.insuranceLookupPolicy"
          [recommended]="!insurancePolicyLookupMethod"
        ></app-drop-down>
      </div>
      <div style="padding-left: 10px; width: 210px;" [appDisableInput]="caseStateService.isCaseDisabled()">
        <app-input-date-time
          [name]="'case-basics-insurance-insuranceLookupDateAndTime' | translate"
          [disabled]="!!personInsurance.insuranceLookupPolicy"
          [date]="insurancePolicyLookupTime"
          [withImage]="true"
          [timeZone]="'local'"
          (dateChange)="insurancePolicyLookupTimeChange($event)"
          [recommended]="insurancePolicyLookupTime === null "
        >
        </app-input-date-time>
      </div>
      <div  style="width: 40px;">
        <button
          [ngClass]="!!personInsurance.insuranceLookupPolicy ? 'view-button' : 'view-button view-button-disabled'"
          [disabled]="!!personInsurance.insuranceLookupPolicy ? false : true"
          (click)="viewPolicyLookup()"
        >
          {{ 'case-basics-insurance-insuranceView' | translate }}
        </button>
      </div>
    </div>
  <div [appDisableInput]="caseLockHelperService.isCaseBasicLockTaken || permissionService.isReadOnly">
    <div class="field-row pt-2">
      <div *ngIf="personInsurance.creditCard" class="col-1">
        <img
          class="credit-card-icon"
          [src]="personInsurance.percentagePaidWithCreditCard ? '/assets/icons/CreditCardPercentageOn.svg' : '/assets/icons/CreditCardPercentageOff.svg'"
          ngbPopover="{{ 'case-basics-insurance-creditCard-popover' | translate }}"
          [autoClose]="false"
          triggers="hover"
          #popover="ngbPopover"
          popoverClass="custom-popover"
          openDelay="400"
        />
      </div>
      <div [className]="personInsurance.creditCard === true ? 'col-11' : 'col-12'">
        <app-input
          [name]="'case-basics-insurance-remarks' | translate"
          (saveModel)="updateInsurance()"
          [(value)]="personInsurance.remarks"
          [disabled]="caseStateService.isCaseDisabled()"
        ></app-input>
      </div>
    </div>
  </div>
</div>
