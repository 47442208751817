import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject, EMPTY } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Task } from './../../../../../shared/models/task';
import { SupplierInvoiceTaskFilterRequest } from './../models/supplier-invoice-task-filter-request.model';
import { TaskFilterResponse } from './../models/task-filter-response.model';
import { TaskFilterRequest } from './../models/task-filter-request.model';
import { TaskViewModelAdapter } from '../models/task-view.model';
import { TaskStatus, MedicalPreAssessmentStatusEnum } from '@secca/shared/models/enums';
import { SettingsService } from '../../../../../core/services/settings.service';
import { BaseService } from '../../../../../core/services/base.service';
import { TaskHoverModel } from 'src/app/modules/board/components/board-task/board-task-table/task-hover/task-hover-model';
import {
  SupplierInvoiceTaskViewModel,
  SupplierInvoiceTaskViewModelAdapter,
} from 'src/app/modules/board/components/board-task/models/supplier-invoice-task-view.model';
import { SimpleTaskViewModel, SimpleTaskViewModelAdapter } from '../../../../case/components/case-task/models/simple-task-view.model';
import { PendingTaskDtoRequest } from '../models/pending-task-dto-request';
import { IncomingCasesTaskFilterResponse } from '@secca/board/components/board-task/models/incoming-cases-task-filter-response.model';
import { SupplierInvoiceTaskFilterResponse } from '@secca/board/components/board-task/models/supplier-invoice-task-filter-response.model';
import { IncomingCasesTaskViewModelAdapter } from '@secca/board/components/board-task/models/incoming-cases-task-view-model';
import { RefundTaskFilterRequest } from '../models/refund-task-filter-request.model';
import { RefundTaskFilterResponse } from '../models/refund-task-filter-response.model';
import { RefundTaskViewModel, RefundTaskViewModelAdapter } from '../models/refund-task-view.model';
import {TaskCountResponse, TaskCountResponseAdapter} from '@secca/board/components/board-task/models/task-count-filter-response.model';
import {TaskCountFilterRequest} from '@secca/board/components/board-task/models/task-count-filter-request.model';
import { PreDepartureTaskFilterRequest } from '../models/pre-departure-task-filter-request.model';
import { PreDepartureTaskFilterResponse } from '../models/pre-departure-task-filter-responce.model';
import { PreDepartureTaskViewModelAdapter } from '../models/pre-departure-task-view.model';
import { InboxTaskFilterResponse } from '../models/inbox-task-filter-responce.model';
import { InboxTaskViewModelAdapter } from '../models/inbox-task-view.model';
import { InboxTaskFilterRequest } from '../models/inbox-task-filter-request.model';
import { RecoveryTaskFilterResponse } from '../models/recovery-task-filter-response.model';
import { RecoveryTaskFilterRequest } from '../models/recovery-task-filter-request.model';
import { RecoveryTaskViewModel, RecoveryTaskViewModelAdapter } from '../models/recovery-task-view.model';
import { RecoveryPaymentTaskFilterRequest } from '@secca/board/components/board-task/models/recovery-payment-task-filter-request.model';
import { RecoveryPaymentTaskFilterResponse } from '@secca/board/components/board-task/models/recovery-payment-task-filter-response.model';
import { RecoveryPaymentTaskViewModel, RecoveryPaymentTaskViewModelAdapter } from '@secca/board/components/board-task/models/recovery-payment-task-view.model';

@Injectable({
  providedIn: 'root',
})
export class TaskStateService extends BaseService {
  private supplierInvoiceTaskViewModel = new BehaviorSubject<SupplierInvoiceTaskFilterResponse>(undefined);
  private supplierInvoiceTaskViewModelIsLoadingNow: boolean;
  private supplierInvoiceTaskViewModelLoadOnDemand: boolean;
  private hoveredTask = new BehaviorSubject(undefined);
  public modalTaskLoaded = new BehaviorSubject<Task>(undefined);
  public refreshToDoTasksAfterSavingFromEmailView = new Subject<void>();
  public refreshIncomingCasesAfterSaving = new Subject<void>();

  public refreshAllTasksAndPendingNumberOfTasks = new Subject<void>();
  private refresh = new Subject<void>();

  private refundTaskViewModel = new BehaviorSubject<RefundTaskFilterResponse>(undefined);
  private refundTaskViewModelIsLoadingNow: boolean;
  private refundTaskViewModelLoadOnDemand: boolean;

  private recoveryPaymentTaskViewModel = new BehaviorSubject<SupplierInvoiceTaskFilterResponse>(undefined);

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private taskViewModelAdapter: TaskViewModelAdapter,
    private incomingCasesTaskModelAdapter: IncomingCasesTaskViewModelAdapter,
    private simpleTaskViewModelAdapter: SimpleTaskViewModelAdapter,
    private supplierInvoiceTaskViewModelAdapter: SupplierInvoiceTaskViewModelAdapter,
    private refundTaskViewModelAdapter: RefundTaskViewModelAdapter,
    private taskCountResponseAdapter: TaskCountResponseAdapter,
    private preDepartureTaskViewModelAdapter: PreDepartureTaskViewModelAdapter,
    private inboxTaskViewModelAdapter: InboxTaskViewModelAdapter,
    private recoveryTaskViewModelAdapter: RecoveryTaskViewModelAdapter,
    private recoveryPaymentTaskViewModelAdapter: RecoveryPaymentTaskViewModelAdapter
  ) {
    super(settingsService);
  }

  public refreshTasks() {
    this.refresh.next();
  }

  public getRefreshTasks() {
    return this.refresh.asObservable();
  }

  public sendHoveredTask(taskHoverModel: TaskHoverModel) {
    this.hoveredTask.next(taskHoverModel);
  }

  public getHoveredTask(): Observable<TaskHoverModel> {
    return this.hoveredTask.asObservable();
  }

  public getTaskViewModels(taskStatus: TaskStatus, taskFilterRequest: TaskFilterRequest): Observable<TaskFilterResponse> {
    if (taskFilterRequest.taskType === '') {
      delete taskFilterRequest.taskType;
    }
    return this.http
      .post<TaskFilterResponse>(this.baseURL + 'task/by-status/' + taskStatus, taskFilterRequest, {
        headers: this.jsonHeaders,
      })
      .pipe(
        map(
          (data: TaskFilterResponse) =>
            new TaskFilterResponse({
              taskViewModels: data.taskViewModels.map(taskViewModel => this.taskViewModelAdapter.adapt(taskViewModel)),
              totalNumber: data.totalNumber,
            })
        )
      );
  }

  public getToDoTaskViewModelForCase(caseId: string): Observable<SimpleTaskViewModel[]> {
    return this.getTaskViewModelsByCaseIdAndTaskStatus(caseId, TaskStatus.toDo);
  }

  public getDoneTaskViewModelForCase(caseId: string): Observable<SimpleTaskViewModel[]> {
    return this.getTaskViewModelsByCaseIdAndTaskStatus(caseId, TaskStatus.done);
  }

  private getTaskViewModelsByCaseIdAndTaskStatus(caseId: string, taskStatus: TaskStatus): Observable<SimpleTaskViewModel[]> {
    return this.http
      .get<SimpleTaskViewModel[]>(this.baseURL + 'task/by-case-id-and-status/' + caseId + '/' + taskStatus)
      .pipe(map((data: any[]) => data.map(item => this.simpleTaskViewModelAdapter.adapt(item))));
  }

  private sortTaskViewModels(simpleTasks: SimpleTaskViewModel[]): SimpleTaskViewModel[] {
    return simpleTasks.sort((a, b) => (a.dueDate.valueOf() >= b.dueDate.valueOf() ? 1 : -1));
  }

  public getPendingTasks(pendingTaskDtoRequest: PendingTaskDtoRequest): Observable<number> {
    return this.http.post<number>(this.baseURL + 'task/pending-tasks-count-before-specific-date-for-case', pendingTaskDtoRequest, {
      headers: this.jsonHeaders,
    });
  }

  public getPendingMessageTasks(pendingTaskDtoRequest: PendingTaskDtoRequest): Observable<number> {
    return this.http.post<number>(this.baseURL + 'task/pending-message-tasks-count-before-specific-date-for-case', pendingTaskDtoRequest, {
      headers: this.jsonHeaders,
    });
  }

  public sendSupplierInvoiceTaskViewModels(taskViewModels: SupplierInvoiceTaskFilterResponse) {
    this.supplierInvoiceTaskViewModel.next(taskViewModels);
  }

  public getSupplierInvoiceTaskViewModel(
    supplierInvoiceTaskFilterRequest: SupplierInvoiceTaskFilterRequest
  ): Observable<SupplierInvoiceTaskFilterResponse> {
    if (
      (this.supplierInvoiceTaskViewModel.value == null && !this.supplierInvoiceTaskViewModelIsLoadingNow) ||
      this.supplierInvoiceTaskViewModelLoadOnDemand
    ) {
      this.supplierInvoiceTaskViewModelLoadOnDemand = false;
      this.supplierInvoiceTaskViewModelIsLoadingNow = true;
      this.getSupplierInvoiceTasks(supplierInvoiceTaskFilterRequest).subscribe(
        result => {
          this.supplierInvoiceTaskViewModel.next(result);
          this.supplierInvoiceTaskViewModelIsLoadingNow = false;
          return this.supplierInvoiceTaskViewModel.asObservable();
        },
        error => console.log(error)
      );
    }
    return this.supplierInvoiceTaskViewModel.asObservable();
  }

  public getSupplierInvoiceTaskViewModelOnDemand(
    supplierInvoiceTaskFilterRequest: SupplierInvoiceTaskFilterRequest
  ): Observable<SupplierInvoiceTaskFilterResponse> {
    this.supplierInvoiceTaskViewModelLoadOnDemand = true;
    return this.getSupplierInvoiceTaskViewModel(supplierInvoiceTaskFilterRequest);
  }

  public getSupplierInvoiceTasks(
    supplierInvoiceTaskFilterRequest: SupplierInvoiceTaskFilterRequest
  ): Observable<SupplierInvoiceTaskFilterResponse> {
    return this.http
      .post<SupplierInvoiceTaskViewModel[]>(this.baseURL + 'economy-task/supplier-invoice-tasks', supplierInvoiceTaskFilterRequest, {
        headers: this.jsonHeaders,
      })
      .pipe(
        map(
          (data: any) =>
            new SupplierInvoiceTaskFilterResponse({
              taskViewModels: data.taskViewModels.map(supplierInvoiceTaskViewModel =>
                this.supplierInvoiceTaskViewModelAdapter.adapt(supplierInvoiceTaskViewModel)
              ),
              totalNumber: data.totalNumber,
            })
        )
      );
  }
/*
  public getSupplierInvoiceTaskCount(supplierInvoiceTaskFilterRequest: SupplierInvoiceTaskFilterRequest): Observable<number> {
    return this.http.post<number>(this.baseURL + 'economy-task/supplier-invoice-task-count', supplierInvoiceTaskFilterRequest, {
      headers: this.jsonHeaders,
    });
  }
*/

  public getPreDepartureTaskCount(status: String): Observable<number> {
    return this.http.get<number>(this.baseURL + 'task/pre-departure-task-count/' + MedicalPreAssessmentStatusEnum.ONGOING );
  }

  public sendRefundTaskViewModels(taskViewModels: RefundTaskFilterResponse) {
    this.refundTaskViewModel.next(taskViewModels);
  }

  public getRefundTaskViewModel(refundTaskFilterRequest: RefundTaskFilterRequest): Observable<RefundTaskFilterResponse> {
    if ((this.refundTaskViewModel.value == null && !this.refundTaskViewModelIsLoadingNow) || this.refundTaskViewModelLoadOnDemand) {
      this.refundTaskViewModelLoadOnDemand = false;
      this.refundTaskViewModelIsLoadingNow = true;
      this.getRefundTasks(refundTaskFilterRequest).subscribe(
        result => {
          this.refundTaskViewModel.next(result);
          return this.refundTaskViewModel.asObservable();
        },
        error => console.log(error)
      );
    }
    return this.refundTaskViewModel.asObservable();
  }

  public getRefundTaskViewModelOnDemand(refundTaskFilterRequest: RefundTaskFilterRequest): Observable<RefundTaskFilterResponse> {
    this.refundTaskViewModelLoadOnDemand = true;
    return this.getRefundTaskViewModel(refundTaskFilterRequest);
  }

  public getRefundTasks(refundTaskFilterRequest: RefundTaskFilterRequest): Observable<RefundTaskFilterResponse> {
    return this.http
      .post<RefundTaskViewModel[]>(this.baseURL + 'economy-task/refund-tasks', refundTaskFilterRequest, {
        headers: this.jsonHeaders,
      })
      .pipe(
        map(
          (data: any) =>
            new RefundTaskFilterResponse({
              taskViewModels: data.taskViewModels.map(refundTaskViewModel => this.refundTaskViewModelAdapter.adapt(refundTaskViewModel)),
              totalNumber: data.totalNumber,
            })
        )
      );
  }

  public getRefundTaskCount(refundTaskFilterRequest: RefundTaskFilterRequest): Observable<number> {
    return this.http.post<number>(this.baseURL + 'economy-task/refund-task-count', refundTaskFilterRequest, {
      headers: this.jsonHeaders,
    });
  }

  public getTaskCounts(taskCountFilterRequest: TaskCountFilterRequest): Observable<TaskCountResponse> {
    return this.http
      .post<TaskCountResponse>(this.baseURL + 'task/count-filter', taskCountFilterRequest, {
      headers: this.jsonHeaders,
    })
    .pipe(catchError((err) => {
        //ignore
        return EMPTY;
    }))
    .pipe(
      map((data: TaskCountResponse) =>  new TaskCountResponse(this.taskCountResponseAdapter.adapt(data))));
  }

  public getIncomingCasesTaskViewModels(taskFilterRequest: TaskFilterRequest): Observable<IncomingCasesTaskFilterResponse> {
    return this.http
      .post<IncomingCasesTaskFilterResponse>(this.baseURL + 'task/web-application-tasks', taskFilterRequest, {
        headers: this.jsonHeaders,
      })
      .pipe(
        map(
          (data: IncomingCasesTaskFilterResponse) =>
            new IncomingCasesTaskFilterResponse({
              taskViewModels: data.taskViewModels.map(incomingCasesTaskViewModel =>
                this.incomingCasesTaskModelAdapter.adapt(incomingCasesTaskViewModel)
              ),
              totalNumber: data.totalNumber,
            })
        )
      );
  }

  public getTaskById(taskId: number): Observable<SimpleTaskViewModel> {
    return this.http
      .get<SimpleTaskViewModel>(this.baseURL + 'task/' + taskId)
      .pipe(map(item => this.simpleTaskViewModelAdapter.adapt(item)));
  }

  public getPreDepartureTaskViewModelOnDemand(refundTaskFilterRequest: PreDepartureTaskFilterRequest): Observable<PreDepartureTaskFilterResponse> {
    this.refundTaskViewModelLoadOnDemand = true;
    return this.getPreDepartureTaskViewModels(refundTaskFilterRequest);
  }

  public getPreDepartureTaskViewModels(preDepartureTaskFilterRequest: PreDepartureTaskFilterRequest): Observable<PreDepartureTaskFilterResponse> {
    return this.http
      .post<PreDepartureTaskFilterResponse>(this.baseURL + 'task/pre-departure-tasks', preDepartureTaskFilterRequest, {
        headers: this.jsonHeaders,
      })
      .pipe(
        map(
          (data: PreDepartureTaskFilterResponse) =>
            new PreDepartureTaskFilterResponse({
              taskViewModels: data.taskViewModels.map(taskViewModel => this.preDepartureTaskViewModelAdapter.adapt(taskViewModel)),
              totalNumber: data.totalNumber,
            })
        )
      );
  }

  public getInboxTaskViewModels(inboxTaskFilterRequest: InboxTaskFilterRequest): Observable<InboxTaskFilterResponse> {
    return this.http
      .post<InboxTaskFilterResponse>(this.baseURL + 'task/inbox-tasks', inboxTaskFilterRequest, {
        headers: this.jsonHeaders,
      })
      .pipe(
        map(
          (data: InboxTaskFilterResponse) =>
            new InboxTaskFilterResponse({
              inboxTaskModal: data.inboxTaskModal.map(taskViewModel => this.inboxTaskViewModelAdapter.adapt(taskViewModel)),
              totalNumber: data.totalNumber,
            })
        )
      );
  }

  public getRecoveryTaskViewModels(recoveryTaskFilterRequest: RecoveryTaskFilterRequest): Observable<RecoveryTaskFilterResponse> {
    return this.getRecoveryTasks(recoveryTaskFilterRequest);
  }

  public getRecoveryTasks(recoveryTaskFilterRequest: RecoveryTaskFilterRequest): Observable<RecoveryTaskFilterResponse> {
    return this.http
      .post<RecoveryTaskViewModel[]>(this.baseURL + 'economy-task/recovery-tasks', recoveryTaskFilterRequest, {
        headers: this.jsonHeaders,
      })
      .pipe(
        map(
          (data: any) =>
            new RecoveryTaskFilterResponse({
              taskViewModels: data.taskViewModels.map(value => this.recoveryTaskViewModelAdapter.adapt(value)),
              totalNumber: data.totalNumber,
            })
        ),
      );
  }

  public getRecoveryTaskCount(recoveryTaskFilterRequest?: RecoveryTaskFilterRequest): Observable<number> {
    return this.http.post<number>(this.baseURL + 'economy-task/recovery-task-count', !!recoveryTaskFilterRequest ? recoveryTaskFilterRequest : new RecoveryTaskFilterRequest(), {
      headers: this.jsonHeaders,
    });
  }

  public sendRecoveryPaymentTaskViewModels(taskViewModels: SupplierInvoiceTaskFilterResponse) {
    this.recoveryPaymentTaskViewModel.next(taskViewModels);
  }

  getRecoveryPaymentTaskViewModels(recoveryPaymentTaskFilterRequest: RecoveryPaymentTaskFilterRequest): Observable<RecoveryPaymentTaskFilterResponse> {
    return this.http
      .post<RecoveryPaymentTaskViewModel[]>(this.baseURL + 'economy-task/recovery-payment-tasks', recoveryPaymentTaskFilterRequest, {
        headers: this.jsonHeaders,
      })
      .pipe(
        map(
          (data: any) =>
            new RecoveryPaymentTaskFilterResponse({
              taskViewModels: data.taskViewModels.map(recoveryPaymentTaskViewModel => this.recoveryPaymentTaskViewModelAdapter.adapt(recoveryPaymentTaskViewModel)),
              totalNumber: data.totalNumber,
            })
        )
      );
  }
}


