<div class="medical-pre-assessment-menu-container">
    <div class="medical-pre-assessment-menu">
      <div class="medical-pre-assessment-menu-header">{{"medical-pre-assessment-menu" | translate}}
      </div>
      <div class="medical-pre-assessment-menu-item" (click)="scrollToElement('assessmentType')"> {{"medical-pre-assessment-menu-assessment-type" | translate}} ({{state.typeText | translate | titlecase}}) 
        <div class="check-mark" *ngIf="state?.preAssessment">
          <img src="{{checkMark(state.typeValid)}}">
        </div>
      </div>
      <div class="medical-pre-assessment-menu-item" (click)="scrollToElement('basisAssessment')"> {{"medical-pre-assessment-basic-for-assessment" | translate}}
        <div class="check-mark" *ngIf="state?.preAssessment">
          <img src="{{checkMark(state.basisValid)}}">
        </div>
      </div>
      <div *ngFor="let question of questionaire; let i = index" >
        <div  class="medical-pre-assessment-menu-item" (click)="scrollToElement('medicalQuestionaire', i)"> {{"medical-pre-assessment-medical-quistionaire" | translate}}
          <div class="check-mark">
            <img src="{{checkMark(isQuestionnaireValid(question))}}">
          </div> 
          </div>
          <div class="medical-pre-assessment-menu-item sub-menu-item"  *ngFor="let diagnosis of question.diagnosisPreDeparture" (click)="scrollToElement('medicalQuestionaire', i)">{{diagnosis.shortName}}
            <div class="check-mark" style="margin-right: -7.5%;" *ngIf="state?.preAssessment && question.relevant === MedicalPreAssessmentRelevantEnum.YES">
              <img src="{{checkSubMenu(question)}}">
            </div>  
        </div>
      </div>
      <div class="medical-pre-assessment-menu-item" (click)="scrollToElement('assessmentNote')"> {{"medical-pre-assessment-medical-notes-menu" | translate}}
        <div class="check-mark" *ngIf="state?.preAssessment">
          <img src="{{checkMark(state.noteValid)}}">
        </div>
      </div>
    </div>
    <div class="medical-pre-assessment-menu-header" *ngIf="state?.preAssessment"> {{"medical-pre-assessment-decision" | translate}}
    </div>
    <div (click)="scrollToElement('decision')" class="medical-pre-assessment-menu-item" *ngIf="state?.preAssessment"> {{"medical-pre-assessment-decision" | translate}}
      <div class="check-mark">
        <img src="{{checkMark(state.decisionValid)}}">
      </div>
    </div>
</div>