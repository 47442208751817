import { Injectable } from '@angular/core';

export class CustomerProfileInfo {
  customerProfileId: number;
  billingCurrency: string;
  claimsAgreement: boolean;
  preAssessmentAgreement: boolean;
  medicalFlightAuthorizationAgreement: boolean;
  medicalFlightAuthorizationDebtorNumber: string;
  preDepartureTravelServiceAgreement: boolean;
  preDepartureTravelServiceDebtorNumber: number;
  debtorNumber: string;
  customerTeam: string;

  constructor(item?: Partial<CustomerProfileInfo>) {
    Object.assign(this, item);
  }
}

@Injectable({
  providedIn: 'root'
})
export class CustomerProfileInfoAdapter {
  public adapt(data: any) {
    return new CustomerProfileInfo({
      billingCurrency: data.billingCurrency,
      claimsAgreement: data.claimsAgreement,
      preAssessmentAgreement: data.preAssessmentAgreement,
      medicalFlightAuthorizationAgreement: data.medicalFlightAuthorizationAgreement,
      medicalFlightAuthorizationDebtorNumber: data.medicalFlightAuthorizationDebtorNumber,
      preDepartureTravelServiceAgreement: data.preDepartureTravelServiceAgreement,
      preDepartureTravelServiceDebtorNumber: data.preDepartureTravelServiceDebtorNumber,
      customerProfileId: data.customerProfileId,
      customerTeam: data.customerTeam,
      debtorNumber: data.debtorNumber
    });
  }
}
