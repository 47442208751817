import { ValidationTypeEnum } from './../../models/enums';
import { DropdownDictionary } from '../../models/dropdownDictionary';
import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { OnSaveData } from '../../interfaces/on-save-data';
import { InputValidationService } from '../../../core/services/input-validation.service';

@Component({
  selector: 'app-drop-down-input',
  templateUrl: './drop-down-input.component.html',
  styleUrls: ['./drop-down-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DropDownInputComponent implements OnInit, OnSaveData {
  @Input() name: string;
  @Input() items: DropdownDictionary[];
  @Input() value: string;
  @Input() selectedItemId: any;
  @Input() validate: boolean;
  @Input() validationType: ValidationTypeEnum;
  @Input() validationTypeToInput: string;
  @Input() disabled: boolean;
  @Input() blockEmail: boolean;
  @Output() valueChange = new EventEmitter();
  @Output() selectedItemIdChange = new EventEmitter();
  @Output() saveModel = new EventEmitter();
  @Output() showNationalId = new EventEmitter();

  oldSelectedItemId: string;
  oldValue: string;

  constructor(private inputValidationService: InputValidationService) {}

  ngOnInit() {}

  onSelectedItemIdChange() {
    this.selectedItemIdChange.emit(this.selectedItemId);
  }

  onValueChange() {
    this.valueChange.emit(this.value);
  }

  onSaveModelDropDown() {

    if (this.oldSelectedItemId !== this.selectedItemId) {
      this.onSaveModel();
    }
  }

  onSaveModelInput() {
    if (this.oldValue !== this.value) {
      this.onSaveModel();
    }
  }

  onSaveModel() {
    this.saveModel.emit(this.value);
  }

  onSaveState() {}

  onSaveStateDropDown() {
    this.oldSelectedItemId = this.selectedItemId;
  }
  onSaveStateInput() {
    this.oldValue = this.value;
  }

  showNationalIdClick(){
    this.showNationalId.emit();
  }

  isValidated(val: any){
    return this.inputValidationService.isValidated(val, this.validationType);
  }
}
