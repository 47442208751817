import { Component, OnInit } from '@angular/core';
import { CasePrimaryContactService } from './case-primary-contact.service';

@Component({
  selector: 'app-case-primary-contact',
  templateUrl: './case-primary-contact.component.html',
  styleUrls: ['./case-primary-contact.component.scss'],
})
export class CasePrimaryContactComponent implements OnInit {
  primaryContactExists: boolean;
  fullName: string;
  contact: string;
  countryCode: string;
  roleName: string;
  isEndUser: boolean;
  isGuardian: boolean;
  isCotraveller: boolean;
  isPolicyHolder: boolean;
  isReporter: boolean;
  subscription;
  langCountryMap = new Map([
    [ 'en', 'gb' ],
    [ 'da', 'dk' ],
    [ 'sv', 'se' ],
    [ 'et', 'ee' ],
    [ 'el', 'gr' ]
  ]);


  constructor(private casePrimaryContactService: CasePrimaryContactService) {}

  ngOnInit() {
    this.clearPrimaryContact();
    this.subscription = this.casePrimaryContactService.change.subscribe(now => {
      if (this.casePrimaryContactService.isPrimaryContact) {
        this.primaryContactExists = true;
        this.fullName = this.casePrimaryContactService.fullName;
        this.roleName = this.casePrimaryContactService.roleName;
        this.contact = this.casePrimaryContactService.contact;
        this.countryCode = this.casePrimaryContactService.countryCode;
        this.isEndUser = this.casePrimaryContactService.isEndUser;
        this.isGuardian = this.casePrimaryContactService.isGuardian;
        this.isCotraveller = this.casePrimaryContactService.isCotraveller;
        this.isPolicyHolder = this.casePrimaryContactService.isPolicyHolder;
        this.isReporter = this.casePrimaryContactService.isReporter;
      } else {
        this.clearPrimaryContact();
      }
    });
  }

  clearPrimaryContact() {
    this.primaryContactExists = false;
    this.fullName = '';
    this.contact = '';
    this.countryCode = '';
    this.roleName = '';
    this.isGuardian = false;
    this.isCotraveller = false;
    this.isEndUser = false;
    this.isPolicyHolder = false;
    this.isReporter = false;
  }

  findCountryCode(){
    const preferredLang = this.countryCode.toLowerCase();
    return this.langCountryMap.has(preferredLang) ? this.langCountryMap.get(preferredLang) : preferredLang;
  }
}
