import { Component, Inject, ViewChild } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { DialogBoundryService } from "@secca/core/services/dialog-boundry.service";
import { DialogHelperUtilService } from "@secca/core/services/dialog-helper-util.service";
import { DialogMinimizableDirective } from "@secca/shared/directives/dialog-minimizable.directive";
import { ChannelType } from "@secca/shared/enums/channelType";
import { MinimizableDialogType } from "@secca/shared/enums/minimizable-dialog-type-enum";
import { AdditionalInfo, IAdditionalInfo } from "@secca/shared/interfaces/additional-info";
import { IMinimizable } from "@secca/shared/interfaces/minimizable";
import { CaseSummaryExternalDialogData } from "./case-summary-external-dialog-data.component";
import { CaseSummaryExternalModalComponent } from "../case-summary-external-modal/case-summary-external-modal.component";
import { IKeyboardEnabled } from "@secca/shared/interfaces/keyboard-enabled";

@Component({
    selector: 'app-case-summary-external-dialog',
    templateUrl: './case-summary-external-dialog.component.html',
    styleUrls: ['./case-summary-external-dialog.component.scss'],
  })
  export class CaseSummaryExternalDialogComponent implements IAdditionalInfo, IMinimizable, IKeyboardEnabled {
    @ViewChild(DialogMinimizableDirective) minimizedDirective: DialogMinimizableDirective;
    @ViewChild(CaseSummaryExternalModalComponent) wrappedInstance: CaseSummaryExternalModalComponent;

    data: CaseSummaryExternalDialogData;

    constructor(private dialogRef: MatDialogRef<CaseSummaryExternalDialogComponent>,
                @Inject(MAT_DIALOG_DATA) data: CaseSummaryExternalDialogData,
                private dialogHelperUtilService: DialogHelperUtilService,
                public dialogBoundryService: DialogBoundryService) {
              
      this.data = data;
    }

    close() {
      this.dialogHelperUtilService.close(this.dialogRef, this.getAdditionalInfo()?.caseId);
    }

    keyboardClose(): void {
      this.wrappedInstance.close();
    }

    minimize() {
      this.minimizedDirective.minimize();
    }

    getMinimizeType(): MinimizableDialogType {
      return this.minimizedDirective.appDialogMinimizable;
    }

    getAdditionalInfo(): AdditionalInfo {
      return {
        caseId: +this.data.entry.caseId,
        hoverTitle: this.data.caseNumber ? this.data.caseNumber : '',
        hoverText: `View ${this.data.channelTypeName ? this.data.channelTypeName : 'web task'}`
      };
    }

    get MinimizableDialogType() {
      return MinimizableDialogType;
    }
}
