import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CaseCoverageDropDown} from "@secca/shared/models/case-coverage-drop-down";
import {DefaultCoveragesAlwaysPresentEnum} from "@secca/shared/models/enums";

@Component({
  selector: 'app-drop-down-service-order-coverage',
  templateUrl: './drop-down-service-order-coverage.component.html',
  styleUrls: ['./drop-down-service-order-coverage.component.scss']
})

export class DropDownServiceOrderCoverageComponent implements OnInit {

  @Input()
  set caseId(newCaseId: number) {
    if (newCaseId !== undefined) {
      this._caseId = newCaseId;
    }
  }

  get caseId(): number {
    return this._caseId;
  }

  @Input() items: CaseCoverageDropDown[];
  @Input() name: string;
  @Input() required: boolean;
  @Input() selectedItemId: number;
  @Input() disabled: boolean;
  @Output() selectedItemIdChange = new EventEmitter();
  @Output() saveModel = new EventEmitter();
  @Output() close = new EventEmitter();

  _caseId: number;
  oldSelectedItemId: number;

  constructor() {
  }

  ngOnInit() {
  }

  onSaveModel() {
    this.saveModel.emit();
  }

  onSaveModelDropDown() {
    if (this.oldSelectedItemId !== this.selectedItemId) {
      this.onSaveModel();
    }
  }

  onSelectedItemIdChange() {
    this.selectedItemIdChange.emit(this.selectedItemId);
  }

  public defaultCoveragesAlwaysPresentEnum() {
    return DefaultCoveragesAlwaysPresentEnum;
  }
}
