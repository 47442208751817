import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CommitmentType, CommitmentTypeAdapter } from "@secca/shared/models/commitment-type";
import { CommitmentTypes, ServiceTypeEnum } from "@secca/shared/models/enums";
import { ServiceTypeCommitmentTypeMapping } from "@secca/shared/models/mappings";
import { map } from "rxjs/operators";
import { BaseService } from "./base.service";
import { SettingsService } from "./settings.service";

@Injectable({
    providedIn: 'root',
  })
  export class CommitmentTypeService extends BaseService {
  
    constructor(private http: HttpClient, private settingsService: SettingsService, private commitmentTypeAdapter :CommitmentTypeAdapter) {
        super(settingsService);
      }

    getCommitmentTypesForCase(caseId: number) {
        return this.http
        .get<CommitmentType[]>(this.baseURL + `commitment-types/active` + '/' + caseId , {
          headers: this.jsonHeaders,
        });
    }
   
}