import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@secca/shared/shared.module';
import { UtilsModule } from 'src/app/utils/utils.module';
import { BoardCasesComponent } from './components/board-cases/board-cases.component';
import { BoardPageComponent } from './pages/board-page/board-page.component';
import { BoardTeamCaringComponent } from './components/board-team-caring/board-team-caring.component';
import { BoardMenuComponent } from './components/board-menu/board-menu.component';
import { BoardCasesTableComponent } from './components/board-cases/board-cases-table/board-cases-table.component';
import { BoardTaskComponent } from './components/board-task/board-task.component';
import { BoardTaskSupplierInvoiceTableComponent } from './components/board-task/board-task-supplier-invoice-table/board-task-supplier-invoice-table.component';
import { BoardTaskTableComponent } from './components/board-task/board-task-table/board-task-table.component';
import { BoardTaskIncomingCasesTableComponent } from './components/board-task/board-task-incoming-cases-table/board-task-incoming-cases-table.component';
import { BoardSalesOrderComponent } from './components/board-sales-order/board-sales-order.component';
import { BoardSalesOrderTableComponent } from './components/board-sales-order/board-sales-order-table/board-sales-order-table.component';
import { BoardTaskRefundTableComponent } from './components/board-task/board-task-refund-table/board-task-refund-table.component';
import { TaskConsentDialogComponent } from './components/board-task/board-task-table/task-consent-dialog/task-consent-dialog.component';
import { TaskWebLeftPanelComponent } from './components/board-task/board-task-incoming-cases-table/task-web-modal/task-web-left-panel/task-web-left-panel.component';
import { TaskSupplierInvoiceLeftPanelComponent } from './components/board-task/board-task-table/task-supplier-invoice-left-panel/task-supplier-invoice-left-panel.component';
import { TaskWebDialogComponent } from './components/board-task/board-task-incoming-cases-table/task-web-dialog/task-web-dialog.component';
import { TaskWebModalComponent } from './components/board-task/board-task-incoming-cases-table/task-web-modal/task-web-modal.component';
import { TaskMessageDialogComponent } from './components/board-task/board-task-table/task-message-dialog/task-message-dialog.component';
import { TaskMessageModalComponent } from './components/board-task/board-task-table/task-message-modal/task-message-modal.component';
import { CaseModule } from '@secca/case/case.module';
import { DropDownTeamUserComponent } from './components/board-team-caring/drop-down-team-user/drop-down-team-user.component';
import { DropDownTeamComponent } from './components/board-team-caring/drop-down-team/drop-down-team.component';
import { BoardMedicalCasesTableComponent } from './components/board-cases/board-medical-cases-table/board-medical-cases-table.component';
import { BoardIncidentEventCasesTableComponent } from './components/board-cases/board-incident-event-cases-table/board-incident-event-cases-table.component';
import { BoardTaskPreDepartureTabelComponent } from './components/board-task/board-task-pre-departure-tabel/board-task-pre-departure-tabel.component';
import { BoardTaskInboxTableComponent } from './components/board-task/board-task-inbox-table/board-task-inbox-table.component';
import { TaskWebDigitalCaseflowDetailsComponent} from "@secca/board/components/board-task/board-task-incoming-cases-table/task-web-modal/task-web-left-panel/dcf-log/task-web-digital-caseflow-details.component";
import { BoardTaskRecoveryTableComponent } from './components/board-task/board-task-recovery-table/board-task-recovery-table.component';
import {
  BoardTaskRecoveryPaymentTableComponent
} from "@secca/board/components/board-task/board-task-recovery-payment-table/board-task-recovery-payment-table.component";
import { BoardTransportCasesTableComponent } from './components/board-cases/board-transport-cases-table/board-transport-cases-table.component';



@NgModule({
  declarations: [
    BoardPageComponent,
    BoardCasesComponent,
    BoardTeamCaringComponent,
    BoardMenuComponent,
    BoardCasesTableComponent,
    BoardTaskComponent,
    BoardTaskSupplierInvoiceTableComponent,
    BoardTaskRecoveryPaymentTableComponent,
    BoardTaskTableComponent,
    BoardTaskIncomingCasesTableComponent,
    BoardSalesOrderComponent,
    BoardSalesOrderTableComponent,
    BoardTaskRefundTableComponent,
    TaskConsentDialogComponent,
    TaskSupplierInvoiceLeftPanelComponent,
    TaskWebLeftPanelComponent,
    TaskWebDigitalCaseflowDetailsComponent,
    TaskMessageDialogComponent,
    TaskMessageModalComponent,
    TaskWebDialogComponent,
    TaskWebModalComponent,
    DropDownTeamUserComponent,
    DropDownTeamComponent,
    BoardMedicalCasesTableComponent,
    BoardIncidentEventCasesTableComponent,
    BoardTaskPreDepartureTabelComponent,
    BoardTaskInboxTableComponent,
    BoardTaskRecoveryTableComponent,
    BoardTransportCasesTableComponent
  ],
  imports: [
    CommonModule,
    UtilsModule,
    SharedModule,
    CaseModule
  ],
  exports: [BoardPageComponent]
})
export class BoardModule { }
