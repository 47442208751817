<div class="d-flex no-gutters no-padding infoCards">
  <div class="me-2" style="width: 33%">
    <app-case-medical-infocards [endUser]="endUser" [plannedTravel]="plannedTravel" [caseIncident]="caseIncident" [caseID]="case.id">
    </app-case-medical-infocards>
  </div>
  <div class="ms-3" style="width: 66%">
    <div class="topButtons">
     <span *permission="PermissionEnum.MEDICAL_PRE_ASSESSMENT_CREATE; hideType: PermissionHideTypeEnum.REMOVE">
        <button class="add-assessment-button"
          [class.add-assessment-draft]="medicalPreAssessmentInDraft"
          [class.disabled]="disabledPreassessmentButton()"
          [disabled]="disabledPreassessmentButton()"
          (click)="openCreatePreMedicalAssessment()">
          <ng-template #popContent><span [innerHTML]="preassessmentButtonHoverText | safeHtml"></span></ng-template>
          <div [disablePopover]="preAssessmentButtonPopOverDisabled()" container="body" triggers="hover" [ngbPopover]="popContent" popoverClass="case-medical-pre-assessment-button-popClass">
            {{ 'case-medical-pre-assessment-button-title' | translate }}
            <span *ngIf="medicalPreAssessmentInDraft">(1)</span>
          </div>
        </button>
      </span>
      <span *permission="PermissionEnum.MEDICAL_CREATE" class="ms-2">
        <button class="add-assessment-button"
          [class.add-assessment-draft]="medicalAssessmentInDraft"
          [class.disabled]="disabledMedicalButton()"
          [disabled]="disabledMedicalButton()"
          (click)="openCreateRegularMedicalAssessment()">{{ addCreateAssessmentButtonText() }}
          <span *ngIf="medicalAssessmentInDraft">(1)</span>
        </button>
      </span>
      <span *permission="PermissionEnum.MEDICAL_CREATE" class="ms-2">
        <button class="add-assessment-button" [class.add-assessment-draft]="claimsReviewInDraft" [disabled]="disabledClaimsButton()"
          (click)="openCreateClaimMedicalReview()">
            {{ "add-claims-medical-review" | translate }}
            <span *ngIf="claimsReviewInDraft">(1)</span>
        </button>
      </span>
      <div *permission="PermissionEnum.CASE_BASIC_CREATE">
        <div
          class="add-comment"
          [class.disabled]="disabledCommentButton()"
          [disabled]="disabledCommentButton()"
          (click)="addComment()">
          {{ "medical-comment" | translate }} <img src="../../../../assets/icons/MedicalNote.png" height="16px" />
        </div>
      </div>
    </div>
    <div>
      <app-case-medical-history [case]="case" [medicalHistory]="medicalHistory"></app-case-medical-history>
    </div>
  </div>
</div>
<ng-template #medicalNoteModalContent let-modal>
  <app-medical-note (updateMedicalHistory)="refreshMedicalHistory()" [caseId]="case.id"></app-medical-note>
</ng-template>
