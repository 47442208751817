import { ServiceOrderExtension } from '@secca/shared/models/service-order/service-order-extension';
import { Location } from '@secca/shared/models/location';
import { CommitmentType, CommitmentTypeAdapter } from '../commitment-type';
import { NoDurationServiceOrderExtension } from './no-duration-service-order-extension';
import { DateSerializer } from '../date-serializer';
import { Adapter } from '@secca/shared/interfaces/adapter';
import * as moment from 'moment';

export class PreDepartureAdviceServiceOrderExtension extends NoDurationServiceOrderExtension implements ServiceOrderExtension {
  id: number;
  startDate: moment.Moment;
  endDate: moment.Moment;
  adviceGiven: string;
  commitmentType: CommitmentType;

  // Only used for display purposes in plans
  displayDurationId: number;

  public constructor(init?: Partial<PreDepartureAdviceServiceOrderExtension>) {
    super();
    this.init();
    Object.assign(this, init);
  }

  isValid(): boolean {
    return this.validateCommitmentType();
  }

  isPreviewGopValid(): boolean {
    throw new Error("Gop preview not supported for this service order type");
  }

  init() {
    this.startDate = moment.utc();
  }

  getStartDate(): moment.Moment {
    return this.startDate;
  }

  getEndDate(): moment.Moment {
    return this.endDate ? this.endDate : this.startDate;
  }

  isMultipleDurations(): boolean {
    return false;
  }

  getStartLocation(): Location {
    return null;
  }

  getEndLocation(): Location {
    return null;
  }

  getCommitmentType(): CommitmentType {
    return this.commitmentType;
  }

  setCommitmentType(commitmentType: CommitmentType) {
    this.commitmentType = commitmentType;
  }

  validateCommitmentType(): boolean {
    return !!this.commitmentType;
  }

  getSelectedStakeholderIds(): string[] {
    return null;
  }

  hasMedicalEscort(): boolean {
    return false;
  }

  hasCoTravellers(): boolean {
    return false;
  }
}

export class PreDepartureAdviceServiceOrderAdapter implements Adapter<PreDepartureAdviceServiceOrderExtension> {
  adapt(item: any): PreDepartureAdviceServiceOrderExtension {
    const commitmentTypeAdapter: CommitmentTypeAdapter = new CommitmentTypeAdapter();
    return new PreDepartureAdviceServiceOrderExtension({
      id: item.id,
      startDate: DateSerializer.deserialize(item.startDate),
      endDate: DateSerializer.deserialize(item.endDate),
      adviceGiven: item.adviceGiven,
      commitmentType: item.commitmentType !== null ? commitmentTypeAdapter.adapt(item.commitmentType) : null,
    });
  }
}
