import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PolicyLookupService {
  private matches: BehaviorSubject<boolean>[] = [];

  public getPolicyMatch(caseId: string): Observable<boolean> {
    if (this.matches[caseId] == null) {
      this.matches[caseId] = new BehaviorSubject<boolean>(null);
    }
    return this.matches[caseId];
  }

  public sendPolicyMatch(caseId: string, match: boolean) {
    if (match != null) {
      if (this.matches[caseId] == null) {
        this.matches[caseId] = new BehaviorSubject<boolean>(match);
      }
      this.matches[caseId].next(match);
    }
  }
}
