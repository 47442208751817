<div class="common-drop-down drop-down-users-static" [ngClass]="{'drop-down-sorting': sortingStyle}">
  <span class="name">{{name | translate}}</span>
  <span *ngIf="required" class="required-red">*</span>
  <ng-select (blur)="onSaveModel()" (focus)="onSaveState()" (close)="onClose()" [clearable]="false" [(ngModel)]="selectedUserDtos"
    [ngClass]="'custom'" [disabled]="!!disabled" [multiple]="multiple" [closeOnSelect]="closeOnSelect" [placeholder]="placeholder"
    (change)="onChange()" [items]="items" (open)="onOpen()">
    <ng-template ng-option-tmp let-item="item">
      <div class="row">
        <div class="col-2">
          <app-user-picture [imageURL]="item.picture" [active]="item.active"></app-user-picture>
        </div>
        <div class="col-10">
          <div class="person-name-shorcut">{{item.fullName}} ({{item.initials}})</div>
        </div>
      </div>
    </ng-template>
    <ng-template ng-multi-label-tmp *ngIf="multiSelectedLabel !== undefined">
      <div class="multi-selected-label"> {{ multiSelectedLabel | translate:{'number': selectedUserDtos.length} }}</div>
    </ng-template>
  </ng-select>
</div>
