import { map, tap } from 'rxjs/operators';
import { SettingsService } from './settings.service';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { CaseValidation, CaseClosedValidationAdapter } from '@secca/shared/models/caseValidation';

@Injectable({
  providedIn: 'root'
})
export class CaseValidationService extends BaseService {

  private caseNumberAssignedAndLocked = new BehaviorSubject<string>(undefined);
  private assignedNumberCaseMap = {};

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private caseClosedValidationAdapter: CaseClosedValidationAdapter
  ) {
    super(settingsService);
  }

  isCaseValid(caseId: string): Observable<CaseValidation> {
    return this.http.get(this.baseURL + 'cases/case-validate/' + caseId, {
      headers: this.jsonHeaders
    }).pipe(map(item => this.caseClosedValidationAdapter.adapt(item)));
  }

  isCaseClosedValid(caseId: string): Observable<CaseValidation> {
    return this.http.get<string[]>(this.baseURL + 'cases/case-closed-validate/' + caseId, {
      headers: this.jsonHeaders
    }).pipe(map(item => this.caseClosedValidationAdapter.adapt(item)));
  }

  isCaseNumberAssigned(caseId: string): Observable<boolean> {
    if (caseId in this.assignedNumberCaseMap && this.assignedNumberCaseMap[caseId]) {
      return of(this.assignedNumberCaseMap[caseId]);
    } else {
      return this.http.get<boolean>(this.baseURL + 'cases/' + caseId + '/has-assigned-number')
        .pipe(
          tap(result => this.assignedNumberCaseMap[caseId] = result)
        );
    }
  }

  public getCaseNumberAssignedConfirmation(): Observable<string> {
    return this.caseNumberAssignedAndLocked.asObservable();
  }

  sendVerifyCaseNumberAssigned(caseId: string) {
    this.isCaseNumberAssigned(caseId).subscribe(
      result => {
        if (result) {
          if (this.caseNumberAssignedAndLocked.getValue() !== caseId) {
            this.caseNumberAssignedAndLocked.next(caseId);
          }
        }
      }
    );
  }
}

