import { Component, Input } from '@angular/core';

@Component({
  selector: 'recovery-payment-paid-box',
  templateUrl: './recovery-payment-paid-box.component.html',
  styleUrls: ['./recovery-payment-paid-box.component.scss']
})
export class RecoveryPaymentPaidBoxComponent {

  @Input()
  invoicedAmount: number;
  @Input()
  currency: string;
}
