<ul ngbNav #nav="ngbNav" class="service-order-type-tabset" [(activeId)]="activeTab" (navChange)="tabChange($event)" class="nav-tabs">
  <li id="{{SelectedTab.ExpectationTab}}" [ngbNavItem]="SelectedTab.ExpectationTab">
    <a ngbNavLink>{{"service-type-expectation" | translate}}</a>
    <ng-template ngbNavContent>
      <div [appDisableInput]="caseLocked" class="caselock-no-events">
      <div class="sub-component-placeholder">
        <app-sub-reference [(subReference)]="treatment.reference"></app-sub-reference>
      </div>
      <div class="sub-component-placeholder">
        <app-durations
          [incidentId]="incidentId"
          [(durations)]="treatment.durations"
          [hideGOPDates]="true"
          [defaultStartDate]="defaultStartDate"
          [defaultEndDate]="defaultEndDate"
          [serviceOrderType]="serviceOrderType"></app-durations>
      </div>
      </div>
    </ng-template>
  </li>
  <li [id]="SelectedTab.ConfirmationTab" *ngIf="serviceOrderTypeState.showConfirmationTab" [ngbNavItem]="SelectedTab.ConfirmationTab">
    <a ngbNavLink>{{"service-type-confirmation" | translate}}<div class="close-tab-x" (click)="closeConfirmationTab($event)"
        *ngIf="!serviceOrderTypeState.isConfirmationTabSaved"><img src="/assets/icons/Close Black.svg"></div>
    </a>
    <ng-template ngbNavContent>
      <div [appDisableInput]="caseLocked" class="caselock-no-events">
      <div class="sub-component-placeholder">
        <app-sub-commitment-type
            [(commitmentType)]="treatment.commitmentType"
            [serviceOrderType]="serviceOrderType"
            [readOnlyMode]="serviceOrderTypeState.isConfirmationTabSaved"
            [status]="status"
            [caseId]="caseId"
            >
        </app-sub-commitment-type>
      </div>
      <div class="sub-component-horizontal-line"></div>
      <div class="sub-component-placeholder">
        <app-sub-reference [(subReference)]="treatment.reference"></app-sub-reference>
      </div>
      <div class="sub-component-placeholder">
        <app-durations
        [incidentId]="incidentId"
        [(durations)]="treatment.durations"
        [hideAdd]="true"
        [gopDatesSemiRequired]="true"
        [disableStartDate]="true"
        [disableEndDate]="true"
        [disableLocation]="true"
        [disableDeletion]="true"
        [defaultStartDate]="defaultStartDate"
        [defaultEndDate]="defaultEndDate">
      </app-durations>
      </div>
      <div class="sub-component-placeholder" *ngIf="isGOPVisible()">
        <app-sub-gop-details
          [serviceTypeEnum]="ServiceTypeEnum.TREATMENT"
          [templateFilter]="treatmentTemplatesFilter"
          [(subGopDetails)]="treatment.gopDetails"
        ></app-sub-gop-details>
      </div>
      <div class="sub-component-placeholder" *ngIf="isGOPVisible()">
        <app-GOP
          [caseId]="caseId"
          [serviceOrderExtension]="treatment"
          [serviceOrderTypeState]="serviceOrderTypeState"
          [stakeholders]="stakeholders"
          [supplier]="supplier"
        ></app-GOP>
      </div>
      </div>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="nav"></div>

