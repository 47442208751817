import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MinimizableDialogType } from '@secca/shared/enums/minimizable-dialog-type-enum';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MedicalReportSupplierDialogData } from './medical-report-supplier-dialog-data.component';
import { DialogHelperUtilService } from '@secca/core/services/dialog-helper-util.service';
import { DialogBoundryService } from '@secca/core/services/dialog-boundry.service';
import { DialogMinimizableDirective } from '@secca/shared/directives/dialog-minimizable.directive';
import { MedicalReportSupplierModalComponent } from '../medical-report-supplier-modal/medical-report-supplier-modal.component';
import { TaskService } from '@secca/core/services/task.service';
import { IAdditionalInfo } from '@secca/shared/interfaces/additional-info';
import { IMinimizable } from '@secca/shared/interfaces/minimizable';
import { IKeyboardEnabled } from '@secca/shared/interfaces/keyboard-enabled';

@Component({
  selector: 'app-medical-report-supplier-dialog',
  templateUrl: './medical-report-supplier-dialog.component.html',
  styleUrls: ['./medical-report-supplier-dialog.component.scss'],
})
export class MedicalReportSupplierDialogComponent implements OnInit, IAdditionalInfo, IMinimizable, IKeyboardEnabled {
  @ViewChild(DialogMinimizableDirective) minimizedDirective: DialogMinimizableDirective;
  @ViewChild(MedicalReportSupplierModalComponent) wrappedInstance: MedicalReportSupplierModalComponent;

  data: MedicalReportSupplierDialogData;
  private caseId: number;

  constructor(
    private dialogRef: MatDialogRef<MedicalReportSupplierDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: MedicalReportSupplierDialogData,
    private dialogHelperUtilService: DialogHelperUtilService,
    private taskService: TaskService,
    public dialogBoundryService: DialogBoundryService
  ) {
    this.data = data;
  }

  close() {
    this.dialogHelperUtilService.close(this.dialogRef, this.caseId);
  }

  keyboardClose(): void {
    this.wrappedInstance.closeTaskWebDialog();
  }

  minimize() {
    this.minimizedDirective.minimize();
  }

  getMinimizeType(): MinimizableDialogType {
    return this.minimizedDirective.appDialogMinimizable;
  }

  ngOnInit(): void {
    this.taskService.getWebTaskById(this.data.taskId).subscribe(result => (this.wrappedInstance.taskDataViewModel = result));
  }

  get MinimizableDialogType() {
    return MinimizableDialogType;
  }

  getAdditionalInfo() {
    const caseId = this.wrappedInstance?.taskDataViewModel?.caseId;
    const caseNumber = this.wrappedInstance?.taskDataViewModel?.caseNumber;
    const taskType = this.wrappedInstance?.taskDataViewModel?.taskType;
    const taskStatus = this.wrappedInstance?.taskDataViewModel?.status;

    return {
      caseId,
      hoverTitle: caseNumber || '',
      hoverText: 'Supplier portal medical report',
      taskType,
      taskStatus,
    };
  }
}
