<ng-template #content let-modal>
  <div class="modify-sales-order-container">
    <div class="modify-sales-order-modal-header">
      <div class="modify-sales-order-modal-header-close float-end clearfix">
        <img (click)="close()" src="../../../../../../assets/icons/Close.svg" alt="Close" />
      </div>

      <div class="modify-sales-order-modal-header-title">
        {{ getTitle() | translate }}
      </div>
    </div>
    <div [appDisableInput]="caseLockHelperService.isCaseBasicLockTaken || permissionService.isReadOnly">
      <div class="modify-sales-order-modal-body">
        <div *ngIf="action == ModifyActionEnum.REPLACE" class="modify-sales-order-specifics">
          {{ 'case-economy-sos-services-modify-warning' | translate }}
        </div>
        <div *ngIf="action == ModifyActionEnum.ADD" class="modify-sales-order-fee-type">
          <div class="input">
            <div class="input-name">{{ 'case-economy-sos-services-selection-fee-type' | translate }}</div>
            <div class="custom-control custom-radio">
              <input
                type="radio"
                class="custom-control-input"
                name="feeType"
                value="{{ FeeTypeEnum.PRIMARY }}"
                id="fee-type-primary"
                (change)="changeFeeType($event)"
                [(ngModel)]="feeTypeSelection"
                [disabled]="primaryFeePresentOnCase"
              />
              <label for="fee-type-primary" class="custom-control-label radio-label">{{
                'case-economy-sos-services-selection-primary' | translate
              }}</label>
            </div>
            <div class="custom-control custom-radio">
              <input
                type="radio"
                class="custom-control-input"
                name="feeType"
                value="{{ FeeTypeEnum.SECONDARY }}"
                id="fee-type-secondary"
                (change)="changeFeeType($event)"
                [(ngModel)]="feeTypeSelection"
              />
              <label for="fee-type-secondary" class="custom-control-label radio-label">{{
                'case-economy-sos-services-selection-secondary' | translate
              }}</label>
            </div>
          </div>
        </div>
        <div *ngIf="action === ModifyActionEnum.ADD || action == ModifyActionEnum.REPLACE" class="modify-sales-order-specifics">
          <app-drop-down
            [(selectedItemId)]="selectedSosServiceId"
            [items]="filteredServices"
            [name]="getServiceSelectionName() | translate"
          ></app-drop-down>
        </div>
        <div *ngIf="action === ModifyActionEnum.ADJUST" class="modify-sales-order-specifics">
          <div class="modify-sales-order-specifics-amount input">
            <div class="input-name">{{ 'case-economy-sos-services-adjust-amount' | translate }}</div>
            <div class="input-with-currency">
              <div class="input-with-currency-currency">{{ localCurrency.currency }}</div>
              <input
                [(ngModel)]="newAmount"
                [mask]="invoiceCurrencyMask()"
                [allowNegativeNumbers]="true"
                [thousandSeparator]="getThousandSeparator()"
                [decimalMarker]="getDecimalMarker()"
              />
            </div>
          </div>
        </div>
        <app-text-box
          [maxTextLength]="250"
          [name]="'case-economy-sos-services-modify-comment' | translate"
          [(value)]="actionComment"
        ></app-text-box>
      </div>
      <div class="modal-footer">
        <div class="d-flex justify-content-end">
          <button [disabled]="saveDisabled()" (click)="save()">
            {{ 'case-economy-sos-services-modify-save' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
