import { Injectable } from '@angular/core';
import { Adapter } from '@secca/shared/interfaces/adapter';
import { isTryStatement } from 'typescript';
import { CommitmentType, CommitmentTypeAdapter } from './commitment-type';



export class ServiceOrderType {
  id: string;
  serviceTypeCode: string;
  serviceTypeEnumValue: string;
  serviceTypeName: string;
  description: string;
  uiSortIndex: number;
  activateOn: Date;
  deactivateOn: Date;
  active: boolean;
  allowed: boolean;
  defaultCommitmentType: CommitmentType;
  commitmentTypes: CommitmentType[];
  economyApplicable: boolean;

  public constructor(init?: Partial<ServiceOrderType>){
    Object.assign(this, init);
  }

}

@Injectable({
  providedIn: 'root'
})
export class ServiceOrderTypeAdapter implements Adapter<ServiceOrderType> {

  constructor() {  }

  adapt(item: any): ServiceOrderType {
    const commitmentTypeAdapter: CommitmentTypeAdapter = new CommitmentTypeAdapter();
    const commitmentTypes: CommitmentType[] = [];
    if (item.commitmentTypes) {
      item.commitmentTypes.forEach(type=>{
        commitmentTypes.push(commitmentTypeAdapter.adapt(type));
      });
    }
    return new ServiceOrderType({
      id: item.id,
      serviceTypeCode: item.serviceTypeCode,
      serviceTypeName: item.serviceTypeName,
      allowed: item.allowed,
      defaultCommitmentType:  commitmentTypeAdapter.adapt(item.defaultCommitmentType),
      serviceTypeEnumValue: item.serviceTypeEnumValue,
      commitmentTypes: commitmentTypes.sort((a,b) =>  (a.uiSortIndex - b.uiSortIndex)),
      economyApplicable: item.economyApplicable
    });
  }
}
