import { ServiceOrder } from '@secca/shared/models/service-order/service-order';
import { ServiceTypeEnum, StatusTypes } from '@secca/shared/models/enums';
import {
  RegularFlightMedicalServiceOrderExtension
} from '@secca/shared/models/service-order/regular-flight-medical-service-order-extension';
import { SubMedicalEscort } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subMedicalEscort';
import { PlanDisplayHelper } from '@secca/case/components/case-plans/plan/plan-display-helper';
import { cloneDeep } from 'lodash';

export class ServiceOrderCloneHelper {
  public static cloneMedicalEscortAccommodation(serviceOrder: ServiceOrder, medicalEscort: SubMedicalEscort): ServiceOrder {
    if (!medicalEscort.accommodationNotNeeded && medicalEscort.accommodation) {
      const accommodation = cloneDeep(serviceOrder);
      accommodation.type = ServiceTypeEnum.ACCOMMODATION;
      const travelInfo = serviceOrder.status === StatusTypes.COMMITTED
        ? (accommodation.extension as RegularFlightMedicalServiceOrderExtension).confirmationTravelInfo[0]
        : (accommodation.extension as RegularFlightMedicalServiceOrderExtension).expectedTravelInfo;
      travelInfo.departureDate = medicalEscort.accommodation?.startDate;
      travelInfo.arrivalDate = medicalEscort.accommodation?.endDate;
      travelInfo.departureFrom = medicalEscort.accommodation?.location;
      travelInfo.arrivalAt = medicalEscort.accommodation?.location;
      accommodation.laneNumber = PlanDisplayHelper.MEDICAL_ESCORT_LANE;
      accommodation.serviceId = crypto.randomUUID();
      accommodation.useEndUserLaneForPlacementCalculation = true;
      return accommodation;
    }
    return null;
  }
}
