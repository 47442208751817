import {ConsentRequestEnum, ConsentStatusEnum} from '@secca/shared/models/enums';

export class CaseConsent {
  name: string;
  consentStatus: ConsentStatusEnum;
  consentRequest: ConsentRequestEnum;
  hideInDropDownList: boolean;
  showLegalGuardian: boolean;
  showSMS: boolean;
  disabled: boolean;
  legalGuardianSet: boolean;

  constructor(name: string, consentStatus: ConsentStatusEnum, consentRequest: ConsentRequestEnum,
              hideInDropDownList?: boolean, showLegalGuardian?: boolean, showSms?: boolean, legalGuardianSet?: boolean,
              customerProfileSet?: boolean) {
    this.name = name;
    this.consentStatus = consentStatus;
    this.consentRequest = consentRequest;
    this.hideInDropDownList = hideInDropDownList;
    this.showLegalGuardian = showLegalGuardian;
    this.showSMS = showSms;
    this.legalGuardianSet = legalGuardianSet;
    this.disabled = (this.consentRequest === ConsentRequestEnum.AUTO && (!legalGuardianSet || !showSms || !customerProfileSet))
                 || (this.consentRequest === ConsentRequestEnum.CONSENT_ACCEPTED && !legalGuardianSet)
                 || (this.consentRequest === ConsentRequestEnum.MANUALLY && (!legalGuardianSet || !showSms || !customerProfileSet))
                 || (this.consentRequest === ConsentRequestEnum.ORAL && (!legalGuardianSet || !showSms || !customerProfileSet))
                 || (this.consentRequest === ConsentRequestEnum.RESEND_MANUALLY && !legalGuardianSet);
  }
}
