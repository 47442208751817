<div class="common-drop-down-arrow drop-down">
  <span class="name">{{ name }}</span>
  <ng-select
    [disabled]="!!disabled"
    [clearable]="false"
    [(ngModel)]="selectedItemId"
    class="custom"
    (change)="onSelectedItemIdChange()"
    [items]="users"
    bindValue="id"
    [ngClass]="{ 'recommended-yellow-background': isRecommended && (selectedItemId === 0 || !selectedItemId) }"
  >
    <ng-template ng-option-tmp let-item="item" let-index="index">
      <img [src]="getStakeholderIcon(item.stakeholderType) " />
        {{getStakeholderText(item)}}
    </ng-template>

    <ng-template ng-label-tmp let-item="item" >
      <span *ngIf="selectedItemId === 0 && showOptionalText">{{ 'case-stakeholder-optional-placeholder' | translate }} </span>
      <img *ngIf="+selectedItemId !== 0" [src]="getStakeholderIcon(item.stakeholderType)" />
      {{getStakeholderText(item)}}
    </ng-template>
  </ng-select>
</div>
