<div class="table-container" *ngIf="filteredStakeholders">
  <table class="primary-table">
    <thead>
      <tr>
        <td class="typeHead" sortColumn [sortKey]="'sortId'" [data]="filteredStakeholders">
          {{ 'case-stakeholders-table-type' | translate }}
        </td>
        <td class="typeHead" sortColumn>{{ 'case-stakeholders-table-attributes' | translate }}</td>
        <td class="typeHead" sortColumn [sortKey]="'firstName'" [data]="filteredStakeholders">
          {{ 'case-stakeholders-table-name' | translate }}
        </td>
        <td class="typeHead" sortColumn [sortKey]="'remarks'" [data]="filteredStakeholders">
          {{ 'case-stakeholders-table-remarks' | translate }}
        </td>
      </tr>
    </thead>
    <tbody class="theBody" [class.zoomed]="settingsService.isZoomed()">
      <ng-container *ngFor="let stakeholder of filteredStakeholders; index as i">
        <div id="newCaseStakeholder" *ngIf="newCaseStakeholderId === stakeholder.caseStakeholder.id"></div>
        <tr class="spacer">
          <td colspan="4"></td>
        </tr>
        <tr
          (click)="rowClick(i)"
          class="selectable-row"
          *ngIf="!stakeholder.caseStakeholder.deleted"
          [ngClass]="{ 'selectable-row-selected': expanded[i] }"
          tabindex="0"
          (keyup.enter)="rowClick(i)"
        >
          <td [ngClass]="{ even: i % 2 == 1 }" [class.disabled-stakeholder]="stakeholder.caseStakeholder.disabled">
            <div class="stakeholder-type-text">
              <img alt="stakeholder type" class="type-icon" src="/assets/icons/{{ getIconName(stakeholder.tableRole) }}" />
              {{ getStakeholderName(stakeholder.tableRole) }}
              <img alt="expand" *ngIf="!expanded[i] && stakeholder.tableRole !== 'ICC'" class="expand-icon" src="/assets/icons/ArrowDown.svg" />
              <img alt="contract" *ngIf="expanded[i] && stakeholder.tableRole !== 'ICC'" class="expand-icon" src="/assets/icons/ArrowDownBlue.svg" />
            </div>
          </td>
          <td *ngIf="stakeholder.tableRole === 'ICC' || stakeholder.caseStakeholder.disabled" [ngClass]="{ even: i % 2 == 1 }" [class.disabled-stakeholder]="stakeholder.caseStakeholder.disabled"></td>
          <td *ngIf="stakeholder.tableRole !== 'ICC' && !stakeholder.caseStakeholder.disabled" [ngClass]="{ even: i % 2 == 1 }" [class.disabled-stakeholder]="stakeholder.caseStakeholder.disabled">
            <div class="roles-Icons" [ngClass]="{'working': working}" (click)="$event.stopPropagation()">
              <a class="smallSpacer"></a>
              <img alt="is end user"
                *ngIf="stakeholder.caseStakeholder.hasRole('END_USER') && (stakeholder.tableRole === 'END_USER' || !stakeholder.sameRoleAndType())"
                src="/assets/icons/Is End-User ON.svg"
                ngbPopover="End-User"
                triggers="hover"
                openDelay="400"
              />
              <img alt="is end user"
                class="switch-icon"
                *ngIf="stakeholder.caseStakeholder.hasRole('END_USER') && stakeholder.tableRole !== 'END_USER' && stakeholder.sameRoleAndType()"
                src="/assets/icons/Is End-User ON.svg"
                ngbPopover="End-User"
                triggers="hover"
                openDelay="400"
                onmouseover="this.src='/assets/icons/Is End-User OFF.svg'" onmouseout="this.src='/assets/icons/Is End-User ON.svg'"
                (click)="unmerge(stakeholder, 'END_USER')"
              />
              <img alt="is not end user"
                class="no-switch-icon"
                *ngIf="!stakeholder.caseStakeholder.hasRole('END_USER') && (!stakeholder.similarTo('END_USER') || !stakeholder.sameRoleAndType() || stakeholder.caseStakeholder.isCompany)"
                src="/assets/icons/Is End-User OFF.svg"
                ngbPopover="End-User"
                triggers="hover"
                openDelay="400"
              />
              <img alt="is not end user"
                class="switch-icon"
                *ngIf="!stakeholder.caseStakeholder.hasRole('END_USER') && stakeholder.similarTo('END_USER') && stakeholder.sameRoleAndType() && !stakeholder.caseStakeholder.isCompany"
                src="/assets/icons/Is End-User OFF.svg"
                ngbPopover="End-User"
                triggers="hover"
                openDelay="400"
                onmouseover="this.src='/assets/icons/Is End-User ON.svg'" onmouseout="this.src='/assets/icons/Is End-User OFF.svg'"
                (click)="merge(stakeholder, 'END_USER')"
              />
              <a class="smallSpacer"></a>

              <img alt="is policyholder"
                *ngIf="stakeholder.caseStakeholder.hasRole('POLICYHOLDER') && (stakeholder.tableRole === 'POLICYHOLDER' || !stakeholder.sameRoleAndType())"
                src="/assets/icons/Is PolicyHolder ON.svg"
                ngbPopover="PolicyHolder"
                triggers="hover"
                openDelay="400"
              />
              <img alt="is policyholder"
                class="switch-icon"
                *ngIf="stakeholder.caseStakeholder.hasRole('POLICYHOLDER') && stakeholder.tableRole !== 'POLICYHOLDER' && stakeholder.sameRoleAndType()"
                src="/assets/icons/Is PolicyHolder ON.svg"
                ngbPopover="PolicyHolder"
                triggers="hover"
                openDelay="400"
                onmouseover="this.src='/assets/icons/Is PolicyHolder OFF.svg'" onmouseout="this.src='/assets/icons/Is PolicyHolder ON.svg'"
                (click)="unmerge(stakeholder, 'POLICYHOLDER')"
              />
              <img alt="is not policyholder"
                class="no-switch-icon"
                *ngIf="!stakeholder.caseStakeholder.hasRole('POLICYHOLDER') && (!stakeholder.similarTo('POLICYHOLDER') || !stakeholder.sameRoleAndType())"
                src="/assets/icons/Is PolicyHolder OFF.svg"
                ngbPopover="PolicyHolder"
                triggers="hover"
                openDelay="400"
              />
              <img alt="is not policyholder"
                class="switch-icon"
                *ngIf="!stakeholder.caseStakeholder.hasRole('POLICYHOLDER') && stakeholder.similarTo('POLICYHOLDER') && stakeholder.sameRoleAndType()"
                src="/assets/icons/Is PolicyHolder OFF.svg"
                ngbPopover="PolicyHolder"
                triggers="hover"
                openDelay="400"
                onmouseover="this.src='/assets/icons/Is PolicyHolder ON.svg'" onmouseout="this.src='/assets/icons/Is PolicyHolder OFF.svg'"
                (click)="merge(stakeholder, 'POLICYHOLDER')"
              />
              <a class="smallSpacer"></a>

              <img alt="is reporter"
                *ngIf="stakeholder.caseStakeholder.hasRole('REPORTER') && (stakeholder.tableRole === 'REPORTER' || !stakeholder.sameRoleAndType())"
                src="/assets/icons/Is Report ON.svg"
                ngbPopover="Reporter"
                triggers="hover"
                openDelay="400"
              />
              <img alt="is reporter"
                class="switch-icon"
                *ngIf="stakeholder.caseStakeholder.hasRole('REPORTER') && stakeholder.tableRole !== 'REPORTER' && stakeholder.sameRoleAndType()"
                src="/assets/icons/Is Report ON.svg"
                ngbPopover="Reporter"
                triggers="hover"
                openDelay="400"
                (click)="unmerge(stakeholder, 'REPORTER')"
              />
              <img alt="is not reporter"
                class="no-switch-icon"
                *ngIf="!stakeholder.caseStakeholder.hasRole('REPORTER') && (!stakeholder.similarTo('REPORTER') || !stakeholder.sameRoleAndType())"
                src="/assets/icons/Is Report OFF.svg"
                ngbPopover="Reporter"
                triggers="hover"
                openDelay="400"
              />
              <img alt="is not reporter"
                class="switch-icon"
                *ngIf="!stakeholder.caseStakeholder.hasRole('REPORTER') && stakeholder.similarTo('REPORTER') && stakeholder.sameRoleAndType()"
                src="/assets/icons/Is Report OFF.svg"
                ngbPopover="Reporter"
                triggers="hover"
                openDelay="400"
                onmouseover="this.src='/assets/icons/Is Report ON.svg'" onmouseout="this.src='/assets/icons/Is Report OFF.svg'"
                (click)="merge(stakeholder, 'REPORTER')"
              />
              <a class="smallSpacer"></a>

              <img alt="is primary contact"
                *ngIf="stakeholder.caseStakeholder.primaryContact && (!stakeholder.caseStakeholder.primaryContactChangeable || !stakeholder.sameRoleAndType())"
                src="/assets/icons/Primary Contact ON.svg"
                ngbPopover="Primary contact"
                triggers="hover"
                openDelay="400"
              />
              <img alt="is primary contact"
                class="switch-icon"
                *ngIf="stakeholder.caseStakeholder.primaryContact && stakeholder.caseStakeholder.primaryContactChangeable && stakeholder.sameRoleAndType()"
                src="/assets/icons/Primary Contact ON.svg"
                ngbPopover="Primary contact"
                triggers="hover"
                openDelay="400"
                (click)="togglePrimaryContact(stakeholder)"
              />
              <img alt="is not primary contact"
                class="no-switch-icon"
                *ngIf="!stakeholder.caseStakeholder.primaryContact && (!stakeholder.caseStakeholder.primaryContactChangeable || !stakeholder.sameRoleAndType())"
                src="/assets/icons/Primary Contact OFF.svg"
                ngbPopover="Primary contact"
                triggers="hover"
                openDelay="400"
              />
              <img alt="is not primary contact"
                class="switch-icon"
                *ngIf="!stakeholder.caseStakeholder.primaryContact && stakeholder.caseStakeholder.primaryContactChangeable && stakeholder.sameRoleAndType()"
                src="/assets/icons/Primary Contact OFF.svg"
                ngbPopover="Primary contact"
                triggers="hover"
                openDelay="400"
                onmouseover="this.src='/assets/icons/Primary Contact ON.svg'" onmouseout="this.src='/assets/icons/Primary Contact OFF.svg'"
                (click)="togglePrimaryContact(stakeholder)"
              />
              <a class="smallSpacer"></a>

              <img alt="is legal guardian"
                *ngIf="stakeholder.caseStakeholder.legalGuardian && (!stakeholder.caseStakeholder.legalGuardianChangeable || !stakeholder.sameRoleAndType())"
                src="/assets/icons/Group 2063.svg"
                ngbPopover="Legal guardian"
                triggers="hover"
                openDelay="400"
              />
              <img alt="is legal guardian"
                class="switch-icon"
                *ngIf="stakeholder.caseStakeholder.legalGuardian && stakeholder.caseStakeholder.legalGuardianChangeable && stakeholder.sameRoleAndType()"
                src="/assets/icons/Group 2063.svg"
                ngbPopover="Legal guardian"
                triggers="hover"
                openDelay="400"
                (click)="toggleLegalGuardian(stakeholder)"
              />
              <img alt="is not legal guardian"
                class="no-switch-icon"
                *ngIf="!stakeholder.caseStakeholder.legalGuardian && (!stakeholder.caseStakeholder.legalGuardianChangeable || !stakeholder.sameRoleAndType())"
                src="/assets/icons/Group 2062.svg"
                ngbPopover="Legal guardian"
                triggers="hover"
                openDelay="400"
              />
              <img alt="is not legal guardian"
                class="switch-icon"
                *ngIf="!stakeholder.caseStakeholder.legalGuardian && stakeholder.caseStakeholder.legalGuardianChangeable && stakeholder.sameRoleAndType()"
                src="/assets/icons/Group 2062.svg"
                ngbPopover="Legal guardian"
                triggers="hover"
                openDelay="400"
                onmouseover="this.src='/assets/icons/Group 2063.svg'" onmouseout="this.src='/assets/icons/Group 2062.svg'"
                (click)="toggleLegalGuardian(stakeholder)"
              />

              <a class="smallSpacer" *ngIf="!stakeholder.caseStakeholder.isCompany"></a>
              <img alt="has protected address"
                *ngIf="!stakeholder.caseStakeholder.isCompany && stakeholder.caseStakeholder.address.protectedAddress"
                src="/assets/icons/lock.svg"
                ngbPopover="{{'case-basics-info-protected' | translate}}"
                triggers="hover"
                openDelay="400"
              />
              <img alt="does not have protected address"
                class="no-switch-icon"
                *ngIf="!stakeholder.caseStakeholder.isCompany && !stakeholder.caseStakeholder.address.protectedAddress"
                src="/assets/icons/lock-off.svg"
                ngbPopover="{{'case-basics-info-not-protected' | translate}}"
                triggers="hover"
                openDelay="400"
              />

              <a class="smallSpacer"></a>

              <ng-container *ngIf="!stakeholder.caseStakeholder.hasRole('END_USER') && (stakeholder.caseStakeholder.company == null || stakeholder.caseStakeholder.hasRole('POLICYHOLDER') || stakeholder.caseStakeholder.hasRole('REPORTER'))">
                <img alt="is co-traveler"
                  class="switch-icon"
                  *ngIf="stakeholder.caseStakeholder.coTraveller"
                  src="/assets/icons/Co-traveller ON.svg"
                  ngbPopover="Co-traveller"
                  triggers="hover"
                  openDelay="400"
                  (click)="toggleCoTraveller(stakeholder)"
                />
                <img alt="is not co-traveler"
                  class="switch-icon"
                  *ngIf="!stakeholder.caseStakeholder.coTraveller"
                  src="/assets/icons/Co-traveller OFF.svg"
                  ngbPopover="Co-traveller"
                  triggers="hover"
                  openDelay="400"
                  onmouseover="this.src='/assets/icons/Co-traveller ON.svg'" onmouseout="this.src='/assets/icons/Co-traveller OFF.svg'"
                  (click)="toggleCoTraveller(stakeholder)"
                />
                <a class="smallSpacer"></a>
              </ng-container>

              <span *ngIf="stakeholder.caseStakeholder.company && viewTreatmentIcons(stakeholder.caseStakeholder)">
                <img alt="private hospital"
                  [src]="getHospitalIconPrivate(stakeholder.caseStakeholder.company.privateCompany)"
                  [ngbPopover]="iconTextPopOverPrivate"
                  [autoClose]="false"
                  triggers="hover"
                  #p="ngbPopover"
                  openDelay="400"
                  (click)="clickPrivateCompany(stakeholder.caseStakeholder)"
                />
                <ng-template #iconTextPopOverPrivate>
                  {{ 'supplier-icon-private-hospital' | translate }}
                </ng-template>
                <label class="icon-treatment">
                  <img alt="public hospital"
                    [src]="getHospitalIconPublic(stakeholder.caseStakeholder.company.publicCompany)"
                    [ngbPopover]="clickPublicCompanyPublic"
                    triggers="hover"
                    openDelay="400"
                    (click)="clickPublicCompany(stakeholder.caseStakeholder)"
                  />
                </label>
                <ng-template #clickPublicCompanyPublic>
                  {{ 'supplier-icon-public-hospital' | translate }}
                </ng-template>
                <a class="smallSpacer"></a>
                <img alt="attention"
                  *ngIf="stakeholder.caseStakeholder.company.attention"
                  src="/assets/icons/provider/hosp_blacklist_true_24x24.svg"
                  ngbPopover="Company is blacklisted"
                  triggers="hover"
                  openDelay="400"
                />
                <img alt="not attention"
                  *ngIf="!stakeholder.caseStakeholder.company.attention"
                  src="/assets/icons/provider/hosp_blacklist_false_24x24.svg"
                  ngbPopover="Company is not blacklisted"
                  triggers="hover"
                  openDelay="400"
                />
              </span>
            </div>
          </td>
          <td *ngIf="!stakeholder.caseStakeholder.company" [ngClass]="{ even: i % 2 == 1 }" [class.disabled-stakeholder]="stakeholder.caseStakeholder.disabled">
            {{ stakeholder.caseStakeholder.person.firstName }} {{ stakeholder.caseStakeholder.person.surname }}
          </td>
          <td *ngIf="stakeholder.caseStakeholder.company" [ngClass]="{ even: i % 2 == 1 }" [class.disabled-stakeholder]="stakeholder.caseStakeholder.disabled">{{ stakeholder.caseStakeholder.company.name }}</td>
          <td class="stakeholder-remarks" [ngClass]="{ even: i % 2 == 1 }">
            <div [appDisableInput]="caseStateService.isCaseDisabled()" class="caselock-no-events">
            <div class="stakeholder-dropdown" (mouseenter)="checkIfStakeholderDeletable(caseId, stakeholder.caseStakeholder.id, i)">
              <button class="dropbtn">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </button>
              <div class="stakeholder-dropdown-content">
                <div
                  (click)="deleteStakeholder(i, $event)"
                  [ngClass]="{ 'stakeholder-dropdown-content-deactivated': isStakeholderDeletable[i] }"
                >
                  {{'case-stakeholders-table-delete-stakeholder' | translate}}
                </div>
                <div
                  *ngIf="stakeholder.caseStakeholder.disabled && canChangeActiveStateOnStakeholder(i)"
                  (click)="changeActiveStateOnStakeholder(i, $event, false)"
                >
                  {{'case-stakeholders-table-enable-stakeholder' | translate}}
                </div>
                <div
                  *ngIf="!stakeholder.caseStakeholder.disabled && canChangeActiveStateOnStakeholder(i)"
                  (click)="changeActiveStateOnStakeholder(i, $event, true)"
                >
                  {{'case-stakeholders-table-disable-stakeholder' | translate}}
                </div>
              </div>
            </div>
            </div>
            <span *ngIf="stakeholder.caseStakeholder.isCompany && !stakeholder.caseStakeholder.disabled">
              <span *ngIf="!stakeholder.caseStakeholder.company.remarks">{{ stakeholder.caseStakeholder.company.networkRemarks }}</span>
              <span *ngIf="stakeholder.caseStakeholder.company.remarks">{{ stakeholder.caseStakeholder.company.remarks }}
                <span *ngIf="stakeholder.caseStakeholder.company.networkRemarks">
                  <img src="/assets/icons/Warning.svg" [popoverTitle]="'case-stakeholders-network-remarks' | translate" [ngbPopover]="popContent" triggers="hover" popoverClass="stakeholder-popover-style" alt="warning" />
                  <ng-template #popContent><span [innerHTML]="stakeholder.caseStakeholder.company.networkRemarks | safeHtml"></span></ng-template>
                </span>
              </span>
            </span>
            <span *ngIf="!stakeholder.caseStakeholder.isCompany && !stakeholder.caseStakeholder.disabled">{{ stakeholder.caseStakeholder.person.remarks }}</span>
            <span class="disabled-label" *ngIf="stakeholder.caseStakeholder.disabled">{{'case-stakeholders-disabled' | translate}}</span>
          </td>
        </tr>
        <tr *ngIf="expanded[i] && !stakeholder.caseStakeholder.deleted && 'ICC' !== stakeholder.tableRole" class="expanded-row">
          <td colspan="5">
            <div class="expanded-container">
              <app-case-stakeholders-info
                [(caseStakeholder)]="stakeholder.caseStakeholder"
                [disable]="!stakeholder.sameRoleAndType() || stakeholder.caseStakeholder.disabled"
                (searchSupplierNotifier)="searchSupplier($event)"
                (reloadData)="reloadData()"
                (stakeholderNationalIdChange)="stakeholderNationalIdChange($event)"
                [caseId]="caseId"
                [case]="case"
                [allStakeholders]="tableCaseStakeholders"
              >
              </app-case-stakeholders-info>
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
