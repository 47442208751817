
import {
  Component, EventEmitter,
  Input, Output,
  ViewEncapsulation
} from '@angular/core';
import { ServiceOrder } from '@secca/shared/models/service-order/service-order';
import { ServiceOrderDropDownItem } from '@secca/shared/models/service-order-drop-down-item';
import { ICCStakeholder } from '@secca/case/components/case-plans/case-plans/add-service-order/models/icc-stakeholder';

@Component({
  selector: 'app-sub-service-order',
  templateUrl: './sub-service-order.component.html',
  styleUrls: ['./sub-service-order.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SubServiceOrderComponent {
  @Input() shouldDisableSelectionOnInvalidServiceOrders: boolean = false;
  @Input() selectedItemId: string;
  @Input() iccStakeholder: ICCStakeholder;
  @Input() serviceOrderDropDown: ServiceOrderDropDownItem[];
  @Input() fullServiceOrderDropdown: ServiceOrderDropDownItem[];
  @Input() disabled: boolean;

  @Input() set serviceOrders(value: ServiceOrder[]) {
    this.selectableServiceOrders = value;
  }
  get serviceOrders(): ServiceOrder[] {
    return this.selectableServiceOrders;
  }
  @Output() selectedItemIdChange = new EventEmitter<string>();
  @Output() itemsLoaded = new EventEmitter();

  items: ServiceOrderDropDownItem[];
  selectableServiceOrders: ServiceOrder[];
  calculateStakeholderDropdownWidth() {
      return 100;
  }

  serviceOrderExist(serviceOrderId: number | string): boolean {
    return serviceOrderId ? this.serviceOrderDropDown.findIndex(serviceOrder => {serviceOrder.id.toString() === serviceOrderId.toString()}) !== -1 : true;
  }
}
