import { Injectable } from '@angular/core';
import { Adapter } from '../../interfaces/adapter';
import { CustomerProfile } from '../../models/customerProfile';

export class CustomerProfileDropdown extends CustomerProfile {
  customerProfileSelected: boolean;

  public constructor(init?: Partial<CustomerProfileDropdown>) {
    super();
    Object.assign(this, init);
  }

}

@Injectable({
  providedIn: 'root',
})

export class CustomerProfileDropdownAdapter implements Adapter<CustomerProfileDropdown> {
  adapt(item: any): CustomerProfileDropdown {
    const customerProfileDropdown = new CustomerProfileDropdown({
      commonCustomerName: item.commonCustomerName,
      brandName: item.brandName,
      brandId: item.brandId,
      profileName: item.profileName,
      profileId: item.profileId,
      profileCode: item.profileCode,
      teamName: item.teamName,
      adtCode: item.adtCode,
      groupProfile: item.groupProfile,
      isGroupProfile: item.isGroupProfile,
      customerProfileSelected: item.customerProfileSelected === undefined ? false: true
    });
    return customerProfileDropdown;
  }
}
