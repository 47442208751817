import { Component, Inject, ViewChild } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { DialogBoundryService } from "@secca/core/services/dialog-boundry.service";
import { DialogHelperUtilService } from "@secca/core/services/dialog-helper-util.service";
import { ShortcutService } from "@secca/core/services/shortcut.service";
import { DialogKeyboardEnabledDirective } from "@secca/shared/directives/dialog-keyboard-enabled.directive";
import { DialogMinimizableDirective } from "@secca/shared/directives/dialog-minimizable.directive";
import { DialogSelectableDirective } from "@secca/shared/directives/dialog-selectable.directive";
import { MinimizableDialogType } from "@secca/shared/enums/minimizable-dialog-type-enum";
import { AdditionalInfo, IAdditionalInfo } from "@secca/shared/interfaces/additional-info";
import { IKeyboardEnabled } from "@secca/shared/interfaces/keyboard-enabled";
import { IMinimizable } from "@secca/shared/interfaces/minimizable";
import { MessageChannelType } from "@secca/shared/models/enums";
import { Subscription } from "rxjs";
import { MessageDialogComponent } from "../components/message-dialog/message-dialog.component";
import { OutputManagementDialogData } from "./output-management-dialog-data.component";

@Component({
    selector: 'app-output-management-dialog',
    templateUrl: './output-management-dialog.component.html',
    styleUrls: ['./output-management-dialog.component.scss'],
  })
  export class OutputManagementDialogComponent implements IAdditionalInfo, IMinimizable, IKeyboardEnabled {
    @ViewChild(MessageDialogComponent) wrappedInstance: MessageDialogComponent;
    @ViewChild(DialogMinimizableDirective) minimizedDirective: DialogMinimizableDirective;
    @ViewChild(DialogSelectableDirective) selectableDirective: DialogSelectableDirective;
    dialogStateChangeSubscr: Subscription;

    data: OutputManagementDialogData;

    constructor(private dialogRef: MatDialogRef<OutputManagementDialogComponent>,
                @Inject(MAT_DIALOG_DATA) data: OutputManagementDialogData,
                private shortcutService: ShortcutService,
                private dialogHelperUtilService: DialogHelperUtilService,
                public dialogBoundryService: DialogBoundryService) {
              
      this.data = data;

      if ( this.data.disableShortcuts ) {
        this.shortcutService.disableShortCuts();
      }
    }

    ngAfterViewInit() {
      this.dialogStateChangeSubscr = this.selectableDirective.dialogStateChange.subscribe(() => {
          this.wrappedInstance.loadStakeholders();
      });
    }

    close(): void {
      if ( this.data.disableShortcuts ) {
        this.shortcutService.enableShortCuts();
      }
      this.dialogHelperUtilService.close(this.dialogRef, this.getAdditionalInfo()?.caseId);
    }

    keyboardClose(): void {
      this.wrappedInstance.closeMessageDialog();
    }

    minimize(): void {
      this.minimizedDirective.minimize();
    }

    getMinimizeType(): MinimizableDialogType {
      return this.minimizedDirective.appDialogMinimizable;
    }
    
    getAdditionalInfo(): AdditionalInfo {
      const caseId: number = +this.wrappedInstance?.case?.id;
      const caseNumber: string = this.wrappedInstance?.case?.caseNumber;
      const channelType = this.wrappedInstance?.messageRequest?.message?.messageChannelType || this.data.messageChannelType;

      return {
        caseId,
        hoverTitle: caseNumber || '',
        hoverText: `Send ${channelType}`
      };

    }

    getMinimizableDialogType(): MinimizableDialogType {
      const channelType = this.wrappedInstance?.messageRequest?.message?.messageChannelType || this.data.messageChannelType;

      switch(channelType) {
        case MessageChannelType.FAX:
          return MinimizableDialogType.CaseFax;

        case MessageChannelType.SMS:
          return MinimizableDialogType.CaseSms;

        case MessageChannelType.EMAIL:
          return MinimizableDialogType.CaseEmail;
      }
    }

    get enabledKeys(): string[] {
      return this.data.disableShortcuts ? [DialogKeyboardEnabledDirective.ESCAPE_KEY] : null; 
    }
  
    get MinimizableDialogType() {
      return MinimizableDialogType;
    }
}
