import { ServiceOrderService } from 'src/app/core/services/service-order.service';
import { TranslateService } from '@ngx-translate/core';
import { TransportTypeEnum } from './../../../../../../../../shared/models/enums';
import { DropdownDictionary } from './../../../../../../../../shared/models/dropdownDictionary';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SubTransportType } from '../../models/subTransportType';

@Component({
  selector: 'app-sub-transport-type',
  templateUrl: './sub-transport-type.component.html',
  styleUrls: ['./sub-transport-type.component.scss'],
})
export class SubTransportTypeComponent {
  @Input() hideInnerBorder: boolean;
  @Input() subTranportType: SubTransportType;
  @Input() readonly: boolean;
  @Input() hideGetButton: boolean;
  @Input() set availableTransportTypes(availableTransportTypes: TransportTypeEnum[]) {
    if (availableTransportTypes != null) {
      this.transportTypes = [];
      availableTransportTypes.forEach(a => {
        this.transportTypes.push(new DropdownDictionary(a, this.translateService.instant('transport-type-' + TransportTypeEnum[a])));
      });
    }
  }
  @Output() transportTypeChanged: EventEmitter<any> = new EventEmitter();

  get availableTransportTypes(): TransportTypeEnum[] {
    return null;
  }
  transportTypes: DropdownDictionary[];

  constructor(private translateService: TranslateService, private serviceOrderService: ServiceOrderService) {
    this.initializeData();
  }

  initializeData() {
    this.transportTypes = [
      new DropdownDictionary(
        TransportTypeEnum.TAXI,
        this.translateService.instant('transport-type-' + TransportTypeEnum[TransportTypeEnum.TAXI])
      ),
      new DropdownDictionary(
        TransportTypeEnum.RENTAL_CAR.toString(),
        this.translateService.instant('transport-type-' + TransportTypeEnum[TransportTypeEnum.RENTAL_CAR])
      ),
      new DropdownDictionary(
        TransportTypeEnum.FERRY.toString(),
        this.translateService.instant('transport-type-' + TransportTypeEnum[TransportTypeEnum.FERRY])
      ),
      new DropdownDictionary(
        TransportTypeEnum.TRAIN.toString(),
        this.translateService.instant('transport-type-' + TransportTypeEnum[TransportTypeEnum.TRAIN])
      ),
      new DropdownDictionary(
        TransportTypeEnum.FUNERAL_CAR.toString(),
        this.translateService.instant('transport-type-' + TransportTypeEnum[TransportTypeEnum.FUNERAL_CAR])
      ),
      new DropdownDictionary(
        TransportTypeEnum.HELICOPTER.toString(),
        this.translateService.instant('transport-type-' + TransportTypeEnum[TransportTypeEnum.HELICOPTER])
      ),
      new DropdownDictionary(
        TransportTypeEnum.OTHER_RESCUE_TRANSPORT.toString(),
        this.translateService.instant('transport-type-' + TransportTypeEnum[TransportTypeEnum.OTHER_RESCUE_TRANSPORT])
      )      
    ];
  }

  get TransportTypeEnum() {
    return TransportTypeEnum;
  }

  getClick() {
    window.open(this.serviceOrderService.getETicketUrl(), '_blank');
  }

  selectedTransportTypeChange(event: any) {
    if (this.transportTypeChanged != null) {
      this.transportTypeChanged.emit(event);
    }
  }
}
