<div class="modal-content">
  <div class="modal-header">
    <div class="modal-header-title">
      {{ "medical-pre-assessment" | translate }}
    </div>
    <div class="modal-buttons">
      <div (click)="close()">
        <img src="/assets/icons/Close White.svg" />
      </div>
    </div>
  </div>
  <div class="modal-content-body">
    <div class="modal-body">
      <div class="d-flex no-gutters p-3 assessment">
        <div class="w-25">
          <app-medical-pre-assessment-menu [state]="state" [questionaire]="medicalPreAssessment?.questionnaires" [formValid]="formValid">
          </app-medical-pre-assessment-menu>
        </div>

        <div class="w-75 card-holder">
          <div *ngIf="medicalPreAssessment && state" class="middle-box">
            <div class="assessment-card" id="assessmentType">
              <app-medical-assessment-card-title [title]="'medical-regular-assessment-type' | translate" [valid]="state.typeValid">
              </app-medical-assessment-card-title>
              <div class="assessment-card-content">
                <div class="row ms-1" style="margin-top: -19px;">
                  <app-single-choice-component [items]="assessmentTypeList" [(value)]="medicalPreAssessment.medicalAssessmentType"
                    [disabled]="disabledViewOfMedicalPreAssessment"></app-single-choice-component>
                </div>
              </div>
              <div class="assessment-card" id="basisAssessment">
                <app-medical-assessment-card-title [title]="'medical-pre-assessment-basic-for-assessment' | translate"
                  [valid]="state.basisValid">
                </app-medical-assessment-card-title>
                <div class="assessment-card-content">

                  <!-- <div *ngFor="let source of medicalPreAssessment.sourceList; let i = index">
                    <div class="deleteIcon" *ngIf="sourceDeletable() && !disabledViewOfMedicalPreAssessment" (click)="deleteSource(i)">
                      <span *ngIf="!disabledViewOfMedicalPreAssessment">Delete <img src="../../../../assets/icons/garbage.svg"></span>
                    </div>
                    <app-drop-down [disabled]="disabledViewOfMedicalPreAssessment" class="col-6 mb" [(selectedItemId)]="source.basedOn"
                      [items]="basisSources" [requiredRedBackground]=true [name]="'medical-regular-assessment-based-on' | translate">
                    </app-drop-down>
                    <app-medical-assessment-source [disabled]="disabledViewOfMedicalPreAssessment" class="col-6 mb"
                      [name]="'medical-regular-assessment-source' | translate" [selectedItemId]="resolveSourceId(source)" [required]=true
                      [users]="this.users" (selectedItemIdChange)="updateSourceData($event, source)">
                    </app-medical-assessment-source>
                    <div class="source-spacer"></div>
                  </div> -->

                  <div *ngFor="let source of medicalPreAssessment.sourceList; let i = index" >
                    <div class="d-flex w-100 pt-2">
                      <div class="w-50 pe-2">
                        <app-drop-down [disabled]="disabledViewOfMedicalAssessment"  [(selectedItemId)]="source.basedOn"
                        [items]="basisSources" [requiredRedBackground]=true
                        [name]="'medical-regular-assessment-based-on' | translate">
                        </app-drop-down>
                      </div>
                      <div class="w-50 ps-2">
                        <div class="deleteIcon" *ngIf="sourceDeletable() && !disabledViewOfMedicalAssessment" (click)="deleteSource(i)">
                          <span *ngIf="!disabledViewOfMedicalAssessment">Delete <img src="../../../../assets/icons/garbage.svg"></span>
                        </div>
                        <app-medical-assessment-source [disabled]="disabledViewOfMedicalAssessment"
                          [name]="'medical-regular-assessment-source' | translate"
                          [selectedItemId]="resolveSourceId(source)"
                          [required]=true
                          [users]="this.users"
                          (selectedItemIdChange)="updateSourceData($event, source)">
                        </app-medical-assessment-source>
                      </div>
                    </div>
                    <div class="source-spacer"></div>
                  </div>




                  <button *ngIf="disabledViewOfMedicalPreAssessment !== true" class="primary-button-white mb-3"
                    (click)="addNewSource()">{{'medical-regular-assessment-examination-treatments-add-more' | translate }}</button>

                  <div class="small-spacer" *ngIf="state.basisDocuments"></div>

                  <app-multiple-choice-component [important]="true" [disabled]="disabledViewOfMedicalPreAssessment"
                    *ngIf="state.basisDocuments" [items]="documents"
                    [title]="'medical-regular-assessment-based-on-medical-report' | translate " (valueChangeEvent)="updateDocument($event)">
                  </app-multiple-choice-component>
                  <div class="source-spacer" *ngIf="state.basisDocuments && documents?.length > 0 && supoMedReps?.length > 0"></div>
                  <div class="small-spacer" *ngIf="state.basisDocuments && documents?.length > 0 && supoMedReps?.length > 0"></div>
                  <app-multiple-choice-component [important]="true" [disabled]="disabledViewOfMedicalPreAssessment"
                    *ngIf="state.basisDocuments" [items]="supoMedReps" (valueChangeEvent)="updateSupoMedRep($event)">
                  </app-multiple-choice-component>
                </div>
              </div>
              <div class="assessment-card" id="medicalQuestionaire">
                <app-medical-assessment-card-title [title]="'medical-pre-assessment-questionaire' | translate"
                  [valid]="state.questionnaireValid">
                </app-medical-assessment-card-title>
                <div class="assessment-card-content">
                  <div *ngFor="let questionnaire of medicalPreAssessment.questionnaires; let i = index" class="mb-3">
                    <div class="row me-4 mb-4 float-end" id="medicalQuestionaire{{i}}"
                      [ngClass]="{'disabledRelevant': disabledViewOfMedicalPreAssessment}">

                      <div *ngIf="showRelevant(questionnaire)" class="d-flex position-relative">
                        <span class="pe-2">{{ 'medical-pre-assessment-RELEVANT' | translate }}</span>

                        <div class="col-2" style="padding-right: 45px !important;">
                          <div class="custom-radio-button" (click)="setRelevantListSingleItem(MedicalPreAssessmentRelevantEnum.YES,questionnaire)">
                            <input type="checkbox" [disabled]="disabledViewOfMedicalPreAssessment"
                              [checked]="questionnaire.relevant == MedicalPreAssessmentRelevantEnum.YES" />
                            <span class="checkmark"></span>
                            <div class="radio-button-text">{{ 'medical-pre-assessment-YES' | translate }}</div>
                          </div>
                        </div>
                        <div class="col-2" style="padding-right: 45px !important;">
                          <div class="custom-radio-button" (click)="setRelevantListSingleItem(MedicalPreAssessmentRelevantEnum.NO,questionnaire)">
                            <input type="checkbox" [checked]="questionnaire.relevant == MedicalPreAssessmentRelevantEnum.NO" />
                            <span class="checkmark"></span>
                            <div class="radio-button-text">{{ 'medical-pre-assessment-NO' | translate }}</div>
                          </div>
                        </div>

                      </div>

                      <div style="margin-left: 10px;" *ngIf="questionnaireDeletable(questionnaire) && !disabledViewOfMedicalPreAssessment"
                        (click)="deleteQuestionnaire(i)">
                        <span *ngIf="!disabledViewOfMedicalPreAssessment">{{ 'medical-pre-assessment-DELETE' | translate }} <img src="../../../../assets/icons/garbage.svg"></span>
                      </div>
                    </div>

                    <div class="d-flex w-100">
                      <div class="diagnosis-symptoms-picker w-50 me-4">
                        <app-multiple-select [alternativeBoxStyle]=true
                          [ngClass]="{'label-box-gray': questionnaire.relevant == MedicalPreAssessmentRelevantEnum.NO}"
                          [disabled]="disabledViewOfMedicalPreAssessment" [maxElements]="10"
                          [name]="'medical-pre-assessment-diagnosis-pre-departure-staff' | translate" [singleValue]="false"
                          [required]="setRequiredField(questionnaire)"
                          [(selectedItems)]="questionnaire.diagnosisPreDeparture"
                          (selectedItemsChange)="updatedSymptoms()">
                        </app-multiple-select>
                      </div>

                      <div class="diagnosis-symptoms-picker w-50">
                        <app-multiple-select [disabled]="true" [name]="'medical-pre-assessment-diagnosis-reporter' | translate"
                          [maxElements]="10" [singleValue]="false" [required]=false [(selectedItems)]="questionnaire.diagnosisReported">
                        </app-multiple-select>
                      </div>
                    </div>

                    <div class="small-spacer"></div>
                    <div *ngIf="questionnaire.relevant !== MedicalPreAssessmentRelevantEnum.NO">

                      <div *ngFor="let question of questionnaire.questions; let i = index">
                        <app-yes-no-question style="height: 65px;" [lines]="3" [disabled]="disabledViewOfMedicalPreAssessment"
                        [(value)]="question.answer"
                        [header]="question.questionText"></app-yes-no-question>
                      </div>
                    </div>
                    <div class="source-spacer"></div>
                  </div>
                  <button *ngIf="disabledViewOfMedicalPreAssessment !== true" class="primary-button-white mb-3"
                    (click)="addNewQuestionaire()">{{'medical-regular-assessment-examination-treatments-add-more' | translate }}</button>
                </div>

              </div>
              <div class="assessment-card" id="assessmentNote">
                <app-medical-assessment-card-title [title]="'medical-pre-assessment-medical-notes' | translate" [valid]="state.noteValid">
                </app-medical-assessment-card-title>
                <div class="assessment-card-content">
                  <app-text-box [disabled]="disabledViewOfMedicalPreAssessment" [maxTextLength]="3000" [largeView]=true [numberOfRows]="10"
                    [name]="'medical-regular-assessment-evaluation' | translate" [(value)]="medicalPreAssessment.note.evaluation"
                    [required]=true [placeHolder]="'medical-regular-assessment-medical-status-placeHolder' | translate">
                  </app-text-box>

                  <div class="spacer"></div>

                  <app-text-box [disabled]="disabledViewOfMedicalPreAssessment" [maxTextLength]="3000" [alternativeColor]="'red'"
                    [mediumView]=true [name]="'medical-regular-assessment-internal-note' | translate"
                    [(value)]="medicalPreAssessment.note.internalNote" [numberOfRows]="5"
                    [placeHolder]="'medical-regular-assessment-medical-notes-placeHolder' | translate"></app-text-box>

                  <div class="small-spacer"></div>
                  <app-yes-no-question [disabled]="disabledViewOfMedicalPreAssessment"
                    [(value)]="medicalPreAssessment.note.canFinalizeAssessment"
                    [header]="'medical-pre-assessment-can-finalize-assessment' | translate"></app-yes-no-question>
                  <div class="small-spacer"></div>
                </div>
              </div>
              <div class="assessment-card" *ngIf="medicalPreAssessment?.note?.canFinalizeAssessment!==undefined" id="decisionid">
                <app-medical-assessment-card-title [title]="'medical-pre-assessment-decision' | translate" [valid]="state.decisionValid">
                </app-medical-assessment-card-title>
                <app-medical-pre-assessment-decision [caseId]="caseId" [serviceOrderExtension]="medicalpreassessment"
                  [serviceOrderTypeState]="serviceOrderTypeState" [stakeholders]="stakeholders" [supplier]="supplier"
                  [medicalPreAssessment]="medicalPreAssessment" [state]="state" [disabled]="disabledViewOfMedicalPreAssessment"
                  (preview)="previewLetter()" (send)="sendLetter()" (publish)="publish()" (requestInformation)="requestInformation()">
                </app-medical-pre-assessment-decision>
                <div class="small-spacer"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col pt-2 ps-3 pe-3 bottom button-row">
          <button class="button" *ngIf="disabledViewOfMedicalPreAssessment !== true"
            (click)="saveDraft()">{{"medical-assessment-save-and-close" | translate}}</button>
          <span class="button-spacing"></span>
          <ng-template #popContent><span [innerHTML]="'medical-pre-assessment-evaluation-required' | translate | safeHtml"></span></ng-template>
          <button class="cancel-button" *ngIf="disabledViewOfMedicalPreAssessment !== true" [disabled]="isCancelButtonDisabled" [class.disabled]="isCancelButtonDisabled"
            (click)="cancel()">
            <div [disablePopover]="!isCancelButtonDisabled" triggers="hover" [ngbPopover]="popContent" container="body">
              {{"medical-assessment-cancel" | translate}}
            </div>
          </button>
        </div>
      </div>
      <div *ngIf="showSpinner" class="overlay d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
