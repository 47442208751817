import { MedifStatusTypeEnum, SelectedTab } from '@secca/case/components/case-plans/case-plans/add-service-order/models/enums';
import { SubMedicalEscort } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subMedicalEscort';
import { SubStakeholdersConfig } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholdersConfig';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ServiceTypeBase } from './../../models/serviceTypeBase';
import { ServiceTypeComponent } from './../../models/interfaces';
import { ServiceTypeEnum, StakeholderTypeEnum, StatusTypes } from '@secca/shared/models/enums';
import { RegularFlightMedicalServiceOrderExtension } from '@secca/shared/models/service-order/regular-flight-medical-service-order-extension';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import * as lodash from 'lodash-es';
import { SubStakeholders } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholders';
import { SubTravellers } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subTravellers';
import { SubTravelInfo } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subTravelInfo';
import { Subscription } from 'rxjs';
import { CoordinationTransportServiceOrderExtension } from '@secca/shared/models/service-order/coordination-transport-service-order-extension';
import { CoordinationCaseDetail } from '@secca/shared/models/coordination-case-details';
import { CoordinationCaseService } from '@secca/core/services/coordination-case.service';
import { ServiceOrder } from '@secca/shared/models/service-order/service-order';
import { Duration } from '@secca/shared/models/duration';

@Component({
  selector: 'app-regular-flight-medical',
  templateUrl: './regular-flight-medical.component.html',
})
export class RegularFlightMedicalComponent extends ServiceTypeBase implements OnInit, OnChanges, ServiceTypeComponent {
  @Input()
  public get stakeholders(): CaseStakeholder[] {
    return this._stakeholders;
  }

  public set stakeholders(stakeholders: CaseStakeholder[]) {
    this._stakeholders = stakeholders;
    if (stakeholders != null) {
      this.companyStakeholders = stakeholders.filter(a => a.company && a.stakeholderType !== StakeholderTypeEnum.medicalAdvisor);
    }
  }

  private _stakeholders: CaseStakeholder[];

  @Input() gopPreviewModelChanged: boolean;
  @Input() caseId: string;
  @Input() incidentId: number;
  @Input() status: StatusTypes;
  @Input() supplier: CaseStakeholder;
  @Input() serviceOrderId: number;

  @Input()
  public get regularFlightMedical(): RegularFlightMedicalServiceOrderExtension {
    return this._regularFlightMedicalServiceOrderExtension;
  }

  public set regularFlightMedical(regularFlightMedical: RegularFlightMedicalServiceOrderExtension) {
    if (regularFlightMedical != null) {
      this._regularFlightMedicalServiceOrderExtension = regularFlightMedical;
    }
  }

  @Input() iccServiceOrder: ServiceOrder;

  subStakeholdersConfigTreatingDoctor: SubStakeholdersConfig;
  subStakeholdersConfigTravellers: SubStakeholdersConfig;
  subStakeholdersConfigMedicalEscort: SubStakeholdersConfig;
  intensivePatient = false;
  private _regularFlightMedicalServiceOrderExtension: RegularFlightMedicalServiceOrderExtension;
  companyStakeholders: CaseStakeholder[];
  $statusChangeSubscr: Subscription;

  coordinationServiceOrderExtensions: CoordinationTransportServiceOrderExtension[];
  linkedEndUserCaseDetail: CoordinationCaseDetail;
  linkedEndUser: CaseStakeholder;
  linkedEndUserSubStakeholdersConfig: SubStakeholdersConfig;

  constructor(
    private coordinationService: CoordinationCaseService
  ) {
    super();
    this.initialize();
  }

  ngOnInit() {
    this.$statusChangeSubscr = this.serviceOrderTypeState.statusChangeEvent.subscribe(status => {
      if (status === StatusTypes.COMMITTED) {
        this.status = status;
        this.regularFlightMedical.confirmationSubStakeholders =
          lodash.cloneDeep(this.regularFlightMedical.expectedSubStakeholders);
        this.regularFlightMedical.confirmationSubStakeholders.stakeholders
          .forEach(stakeholder => stakeholder.id = null);
        this.regularFlightMedical.confirmationStakeholders =
          this.regularFlightMedical.confirmationSubStakeholders.stakeholders;
        this.regularFlightMedical.confirmationTravelInfo[0] = lodash.cloneDeep(this.regularFlightMedical.expectedTravelInfo);
        this.regularFlightMedical.confirmationTravelInfo[0].id = null;

        this.regularFlightMedical.confirmationMedicalEscorts =
          lodash.cloneDeep(this.regularFlightMedical.expectedMedicalEscorts);
        this.regularFlightMedical.confirmationMedicalEscorts.forEach(escort => {
          escort.id = null;
          escort.subStakeholders.stakeholders.forEach(stakeholder => stakeholder.id = null);
          this.clearEscortTripIds(escort.outboundTrips);
          this.clearEscortTripIds(escort.returnTrips);
          this.clearEscortAccommodationIds(escort.accommodation);
        });
        this.serviceOrderTypeState.statusChangeEvent.emit(null);
      } else if (status === StatusTypes.EXPECTED) {
        this.status = status;
        this.regularFlightMedical.confirmationSubStakeholders = new SubStakeholders();
        this.regularFlightMedical.confirmationStakeholders =
          this.regularFlightMedical.confirmationSubStakeholders.stakeholders;
        this.regularFlightMedical.confirmationSubTravellers = new SubTravellers();
        this.serviceOrderTypeState.statusChangeEvent.emit(null);
        this.regularFlightMedical.confirmationMedicalEscorts = [new SubMedicalEscort()];
      }
    });
    this.linkedEndUserSubStakeholdersConfig = new SubStakeholdersConfig();
    this.linkedEndUserSubStakeholdersConfig.requireAtLeastOneStakeholder = true;
    this.linkedEndUserSubStakeholdersConfig.hideClass = true;
    this.linkedEndUserSubStakeholdersConfig.hideInclPassportDetails = true;
    this.linkedEndUserSubStakeholdersConfig.hideInclDateOfBirth = true;
    this.linkedEndUserSubStakeholdersConfig.mandatoryAndSetStakeholderType = StakeholderTypeEnum.endUser;
    this.linkedEndUserSubStakeholdersConfig.hideAddButton = true;
    this.coordinationService.getCoordinationCaseNumberChanges(this.caseId).subscribe(result => {
      this.serviceOrderTypeState.lockedByCoordinationTransportCaseNumber = result;
    });
    this.coordinationService.getCoordinationCaseServiceOrdersFromLinkedCase(+this.caseId).subscribe(serviceOrders => {
      this.coordinationServiceOrderExtensions = serviceOrders.filter(serviceOrder => serviceOrder.type === ServiceTypeEnum.COORDINATION_TRANSPORT && serviceOrder.status !== StatusTypes.CANCELLED).map(
        service => {
          let extension = service.extension as CoordinationTransportServiceOrderExtension;
          extension.serviceOrderId = service.id;
          return extension;
        }
      )
      if (this.coordinationServiceOrderExtensions.length > 0) {
        this.serviceOrderTypeState.isLockedByCoordinationTransport = this.coordinationService.serviceOrderUsedInCoordinationTransportCase(this.serviceOrderId, serviceOrders);
        if(this.serviceOrderTypeState.isLockedByCoordinationTransport && this.serviceOrderTypeState.lockedByCoordinationTransportCaseNumber === undefined) {
          this.coordinationService.getCoordinationCasesForLinkedCase(this.caseId).subscribe(
          result => {
            this.serviceOrderTypeState.lockedByCoordinationTransportCaseNumber = result.length > 0 ? result[0].caseNumber : '';
            this.coordinationService.coordinationCaseNumberChanged(this.caseId, this.serviceOrderTypeState.lockedByCoordinationTransportCaseNumber);
          });
        }
        this.coordinationService.getLinkedCaseDetails(this.caseId).subscribe(linkedCaseDetail => {
          this.linkedEndUserCaseDetail = linkedCaseDetail;
          this.activeTab = this.serviceOrderTypeState.showConfirmationTab ? this.linkedEndUserCaseDetail.caseId : SelectedTab.ExpectationTab;
          this.linkedEndUser = linkedCaseDetail.stakeholders.find(value => {
            return value.hasRole(StakeholderTypeEnum.endUser);
          });
        });
      }
    });

    if (this.iccServiceOrder && this.iccServiceOrder.status == StatusTypes.COMMITTED) {
      this.activeTab = this.caseId;
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.iccServiceOrder  && changes.iccServiceOrder.currentValue && changes.iccServiceOrder.currentValue.status == StatusTypes.COMMITTED) {      
      setTimeout( () => { this.activeTab = this.iccServiceOrder.id; }, 300 );
    }
  }

  treatingDoctorSelected(): boolean {
    return Object.keys(this.regularFlightMedical.treatingDoctorStakeholders.stakeholders[0]).length === 0;
  }

  isValid(): boolean {
    let result: boolean;
    if (!this.serviceOrderTypeState.showConfirmationTab) {
      result = this.regularFlightMedical.isValidExpectation() &&
        this.isDepartureDateSetCorrectly() &&
        !this.intensivePatient;
    } else {
      result =
        this.regularFlightMedical.isValid() &&
        this.serviceOrderTypeState.isSupplierChosen &&
        this.isDepartureDateSetCorrectly();
    }
    return result;
  }

  isIntensivePatient(isIntensiv: boolean){
    this.intensivePatient = isIntensiv;
  }

  updateEndUserHasDateOfBirth(event) {
    this.serviceOrderTypeState.endUserHasDateOfBirth = event;
  }

  isDepartureDateSetCorrectly(): boolean {
    const expectedTravelInfo = this.getModel().expectedTravelInfo;
    if (!expectedTravelInfo) {
      return false;
    }
    return expectedTravelInfo.isDepartureDateValid();
  }

  isValidForChangingTheStatusToConfirmation(): boolean {
    return this.regularFlightMedical.isPreviewGopValid();
  }

  getModel(): RegularFlightMedicalServiceOrderExtension {
    return this.regularFlightMedical;
  }

  get ServiceTypeEnum() {
    return ServiceTypeEnum;
  }

  medifStatusTypeChanged(medifStatusType: MedifStatusTypeEnum) {
    this.regularFlightMedical.medifStatusType = medifStatusType;
  }

  newMedicalEscortAddedFromExpectation() {
    const confirmationMedicalEscort = new SubMedicalEscort();
    const newlyAddedMedicalEscort = this.regularFlightMedical.expectedMedicalEscorts.slice(-1).pop();
    confirmationMedicalEscort.subStakeholders = newlyAddedMedicalEscort.subStakeholders;
    confirmationMedicalEscort.minimumRest = newlyAddedMedicalEscort.minimumRest;
    this.regularFlightMedical.confirmationMedicalEscorts.push(confirmationMedicalEscort);
  }

  initialize() {
    this.subStakeholdersConfigTreatingDoctor = new SubStakeholdersConfig();
    this.subStakeholdersConfigTreatingDoctor.hideClass = true;
    this.subStakeholdersConfigTreatingDoctor.onlyPersonTypeStakeholders = false;
    this.subStakeholdersConfigTreatingDoctor.hideInclDateOfBirth = true;
    this.subStakeholdersConfigTreatingDoctor.hideInclPassportDetails = true;
    this.subStakeholdersConfigTreatingDoctor.hideAddButton = true;
    this.subStakeholdersConfigTreatingDoctor.hideTrashIcon = true;

    this.subStakeholdersConfigTravellers = new SubStakeholdersConfig();
    this.subStakeholdersConfigTravellers.onlyPersonTypeStakeholders = false;
    this.subStakeholdersConfigTravellers.mandatoryAndSetStakeholderType = StakeholderTypeEnum.endUser;
    this.subStakeholdersConfigTravellers.requireAtLeastOneStakeholder = true;
    this.subStakeholdersConfigTravellers.lockFirstInclDateOfBirth = true;
    this.subStakeholdersConfigTravellers.endUserAlwaysSeeMedifClass = true;
    this.subStakeholdersConfigTravellers.availableStakeholderTypes = [
      StakeholderTypeEnum.endUser,
      StakeholderTypeEnum.reporter,
      StakeholderTypeEnum.policyHolder,
      StakeholderTypeEnum.coTraveller,
      StakeholderTypeEnum.person
    ];
  }

  private clearEscortTripIds(trips: SubTravelInfo[]) {
    trips.forEach(trip => {
      trip.id = null;
    });
  }

  private clearEscortAccommodationIds(accommodation: Duration) {
    accommodation.id = null;
    if (accommodation.location) {
      accommodation.location.id = null;
    }
  }
}
