import { Adapter } from './../../../../../shared/interfaces/adapter';
import { Injectable } from '@angular/core';
import { HandlingAreaStatusEnum, SensitiveType } from './../../../../../shared/models/enums';
import { DateHelperService } from '@secca/core/services/date-helper.service';
import { ScreeningStates } from '@secca/shared/enums/screening-states';

export class BoardCase {
  id: number;
  caseNumber: string;
  teamName: string;
  teamNameAlt: string;
  endUserFullName: string;
  age: number;
  incidentCountry: string;
  incidentCity: string;
  incidentCause: string;
  incidentDate: Date;
  caseHandler: string;
  caseHandlerTeamName: string;
  createdOn: Date;
  createdOnLocalTime: Date;
  teamStatus: HandlingAreaStatusEnum;
  countryCodeAlpha2: string;
  customerProfileId: number;
  caseLockedBy: string;
  customerProfileName: string;
  reserve: number;
  billingCurrency: string;
  incidentCoveredState: ScreeningStates;
  incidentCoveredSetByCaseHandler: boolean;
  claimsStatus: HandlingAreaStatusEnum;
  sensitiveType: SensitiveType;

  public constructor(init?: Partial<BoardCase>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class BoardCaseAdapter implements Adapter<BoardCase> {

  constructor(private dateHelperService: DateHelperService) {
  }

  adapt(item: any): BoardCase {
    return item ? new BoardCase({
      id: item.id,
      caseNumber: item.caseNumber,
      teamName: item.teamName,
      teamNameAlt: item.teamNameAlt,
      endUserFullName: item.endUserFullName,
      age: item.age,
      incidentCountry: item.incidentCountry,
      incidentCity: item.incidentCity,
      incidentCause: item.incidentCause,
      caseHandler: item.caseHandler,
      caseHandlerTeamName: item.caseHandlerTeamName,
      createdOn: new Date(item.createdOn),
      createdOnLocalTime: this.dateHelperService.convertFromUTCDateTimeToLocalDateTime(item.createdOn),
      incidentDate: this.dateHelperService.convertFromUTCDateTimeToLocalDateTime(item.incidentDate),
      teamStatus: item.teamStatus,
      customerProfileId: +item.customerProfileId,
      caseLockedBy: item.caseLockedBy,
      customerProfileName: item.customerProfileName,
      reserve: item.reserve,
      billingCurrency: item.billingCurrency,
      incidentCoveredState: item.incidentCoveredState,
      incidentCoveredSetByCaseHandler: item.incidentCoveredSetByCaseHandler,
      claimsStatus: item.claimsStatus,
      sensitiveType: item.sensitiveType
    }) : new BoardCase();
  }
}
