import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CaseService } from 'src/app/core/services/case.service';
import { TableSort } from '@secca/shared/models/table-sort';
import { CommitmentTypes, PatientType, ShortcutEnum, SortOrder } from '@secca/shared/models/enums';
import { UserDto } from '@secca/shared/models/userDto';
import { DictionaryService } from '@secca/core/services/dictionary.service';
import { ScreeningStates } from '@secca/shared/enums/screening-states';
import { BoardMedicalCase } from '../models/board-medical-case.model';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '@secca/core/services/settings.service';
import { AbstractGlobalBoardTableShortcutsComponent } from '@secca/shared/components/abstract-global-board-table-shortcuts.component';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { BoardNavigationStateService } from '../../board-task/services/board-navigation-state.service';

@Component({
  selector: 'app-board-medical-cases-table',
  templateUrl: './board-medical-cases-table.component.html',
  styleUrls: ['./board-medical-cases-table.component.scss']
})
@AutoUnsubscribe
export class BoardMedicalCasesTableComponent extends AbstractGlobalBoardTableShortcutsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() set boardMedicalCases(data: BoardMedicalCase[]) {
    this._boardMedicalCases = data;
    this.numberOfRows = data?.length;
    setTimeout(() => {
      this.selectedRowIndex = parseInt(this.boardNavigationStateService.getState('board-medical-case-filter-request-cases-selected-row-index'), 10);
      this.scrollPosition = this.boardNavigationStateService.getState('board-medical-case-filter-request-scroll-position') ? parseInt(this.boardNavigationStateService.getState('board-medical-case-filter-request-scroll-position'), 10) : 0;
      this.navigateToTable();
  }, 50);
  }
  get boardMedicalCases(): BoardMedicalCase[] {
    return this._boardMedicalCases;
  }

  @Input() caseSelect: string;
  @Input() casesSorting: TableSort;
  @Output() sortingAction = new EventEmitter<TableSort>();

  filteringText: string;
  caseStatus: string;
  selectedTeam: string;
  $loggedUserSubscr: Subscription;
  sortedOn: any = { createdOn: true };
  sortOrder: any = { };
  loggedInUser: UserDto;
  NO = ScreeningStates.NO;
  YES = ScreeningStates.YES;
  TBD = ScreeningStates.TO_BE_DETERMINED;
  scrollPosition = 0;

  private _boardMedicalCases: BoardMedicalCase[];

  constructor(private router: Router,
              private caseService: CaseService,
              private dictionaryService: DictionaryService,
              private translateService: TranslateService,
              public settingsService: SettingsService,
              private boardNavigationStateService: BoardNavigationStateService
              ) {
    super('medicalCaseTabel');
    this.$loggedUserSubscr = dictionaryService.getLoggedInUser()
      .subscribe(user => this.loggedInUser = user
    );

    this.shortcutSubscriptions.push(
      this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateTableRowAltEnter }).subscribe(a => {
        if (typeof this.selectedRowIndex === 'number') {
          this.redirectToCase(this.boardMedicalCases[this.selectedRowIndex].id);
        }
      }),
    );
  }

  ngOnInit(): void {
    if (this.casesSorting != null) {
      this.sortedOn = {};
      this.sortedOn[this.casesSorting.sortBy] = true;
      this.sortOrder[this.casesSorting.sortBy] = this.casesSorting.sortOrder;
    }
    const scrollPositionJson = this.boardNavigationStateService.getState('board-medical-case-filter-request-scroll-position');
    if (!!scrollPositionJson) {
      this.scrollPosition = JSON.parse(scrollPositionJson)
    }
  }

  ngOnChanges() {
    setTimeout(() => this.setScrollPosition(), 500);
  }

  ngOnDestroy(): void {
    this.boardNavigationStateService.saveState('board-medical-case-filter-request-scroll-position', JSON.stringify(this.scrollPosition));
    this.boardNavigationStateService.saveState('board-medical-case-filter-request-cases-selected-row-index', JSON.stringify(this.selectedRowIndex));
  }

  setScrollPosition() {
    if (this.scrollPosition !== null && document.getElementById('medicalCaseTabel')) {
      document.getElementById('medicalCaseTabel').scrollTop = this.scrollPosition;
    }
  }

  sortedBy(column: string): string {
    return this.sortedOn[column] ? 'sorted-by' : '';
  }

  get CommitmentTypes() {
    return CommitmentTypes;
  }

  get PatientType() {
    return PatientType;
  }

  sortBy(column: string) {
    this.sortedOn = {};
    this.sortedOn[column] = true;
    if (this.sortOrder[column] == null) {
      this.sortOrder[column] = SortOrder.asc;
    } else {
      if (this.sortOrder[column] === SortOrder.asc) {
        this.sortOrder[column] = SortOrder.desc;
      } else {
        this.sortOrder[column] = SortOrder.asc;
      }
    }
    this.sortingAction.emit(new TableSort({ sortBy: column, sortOrder: this.sortOrder[column] }));
  }

  onCaseTabelScroll() {
    this.scrollPosition = document.getElementById('medicalCaseTabel').scrollTop;
  }

  getBrandImagesUrlForProfile(profileId: number) {
    return this.caseService.getBrandImagesUrlForProfile(profileId.toString());
  }

  caseHandlerTeamNameShortName(assignInitials: string): string {
    return this.caseService.teamNameShortName(assignInitials);
  }

  showMedicalServiceOrderType(boardMedicalCase: BoardMedicalCase): boolean {
    return  !boardMedicalCase.caseDetailsAIR &&
            !boardMedicalCase.caseDetailsSTR &&
            !boardMedicalCase.caseDetailsEXT &&
            !boardMedicalCase.caseDetailsSIT &&
            !boardMedicalCase.caseDetailsREG;
  }

  caseDetailsEscort(taskViewModel: BoardMedicalCase): string {
    const mortalList: boolean[] = [taskViewModel.caseDetailsDOC, taskViewModel.caseDetailsNUR, taskViewModel.caseDetailsNME];
    let returnValue: string;
    const mortalListNumbers = mortalList.filter(m => m === true).length;
    if (mortalListNumbers === 1) {
      if (taskViewModel.caseDetailsDOC) { returnValue = this.translateService.instant('board-task-table-case-details-DOC'); }
      if (taskViewModel.caseDetailsNUR) { returnValue = this.translateService.instant('board-task-table-case-details-NUR'); }
      if (taskViewModel.caseDetailsNME) { returnValue = this.translateService.instant('board-task-table-case-details-NME'); }
    } else {
      returnValue = mortalListNumbers.toString();
    }
    return returnValue;
  }

  redirectToCase(id: any) {
    this.router.navigate(['case', id]);
  }
}
