<div class="modal-dialog-wrapper" appDialogSelectable appDialogKeyboardEnabled [enabledKeys]="enabledKeys"
     [appDialogMinimizable]="MinimizableDialogType.Task"
     [isMinimizeIconHidden]="data.isMinimizeIconHidden">
  <mat-dialog-content (cdkDragStarted)="dialogBoundryService.handleDragStart($event)"
                      (cdkDragEnded)="dialogBoundryService.handleDragEnd($event)"
                      cdkDragRootElement=".cdk-overlay-pane" cdkDrag>
    <div appDialogDragArea cdkDragHandle></div>
    <app-task-web-modal [taskViewModel]="data.taskViewModel"
                        [taskStatusDisabled]="data.taskStatusDisabled"
                        [createCaseOnly]="data.createCaseOnly"
                        [hideSearchCreateButton]="hideSearchCreateButton"
                        (navigatedToCaseEvent)="data.navigatedToCaseEvent ? data.navigatedToCaseEvent($event) : null"
                        (closed)="close()"></app-task-web-modal>
  </mat-dialog-content>
</div>
