import { DropdownDictionary } from './dropdownDictionary';

export class DropdownWithHoverDictionary  {
  hoverText: string;
  value: any;
  id: any;

  public constructor(value: any, hoverText?: string) {
    this.hoverText = hoverText;
    this.value = value;
    this.id = value.businessKey;
  }

  text() {
    return this.value.name;
  }

  
}
