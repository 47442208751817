import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BoardCase, BoardCaseAdapter } from './../models/board-case.model';
import { BoardMedicalCase, BoardMedicalCaseAdapter } from './../models/board-medical-case.model';
import { CaseFilterResponse } from '../models/case-filter-response.model';
import { CaseFilterRequest } from '../models/case-filter-request.model';
import { SettingsService } from '../../../../../core/services/settings.service';
import { BaseService } from '../../../../../core/services/base.service';
import * as moment from 'moment';
import { CaseMedicalFilterRequest } from '../models/case-medical-filter-request.model';
import { CaseMedicalFilterResponse } from '../models/case-medical-filter-responce.model';
import { CaseIncidentEventsRequest } from '../models/case-incident-event-filter-request.model';
import { BoardIncidentEventCase, BoardIncidentEventCaseAdapter } from '../models/board-incident-event-case.model';
import { CaseIncidentEventFilterResponse } from '../models/case-incident-event-filter-responce.model';
import { CaseTransportRequest } from '../models/case-transport-filter-request.model';
import { CaseTransportFilterResponse } from '../models/case-transport-filter-responce.model';
import { BoardTransportCaseAdapter } from '../models/board-transport-case.model';

@Injectable({
  providedIn: 'root'
})
export class CaseBoardService extends BaseService {
  constructor(private http: HttpClient,
              private settingsService: SettingsService,
              private boardCaseAdapter: BoardCaseAdapter,
              private boardMedicalCaseAdapter: BoardMedicalCaseAdapter,
              private boardIncidentEventCaseAdapter: BoardIncidentEventCaseAdapter,
              private boardTransportCaseAdapter: BoardTransportCaseAdapter) {
    super(settingsService);
  }

  public getBoardCases(caseFilterRequest: CaseFilterRequest): Observable<CaseFilterResponse> {
    if (caseFilterRequest.toDate) {
      caseFilterRequest.toDate.toJSON = function(): string {
        return moment(this).toDate().toJSON();
      };
    }
    if (caseFilterRequest.fromDate) {
      caseFilterRequest.fromDate.toJSON = function(): string {
        return moment(this).toDate().toJSON();
      };
    }
    return this.http
      .post<CaseFilterResponse>(this.baseURL + 'cases/request', caseFilterRequest, {
        headers: this.jsonHeaders
      })
      .pipe(
        map(
          (data: CaseFilterResponse) =>
            new CaseFilterResponse({
              boardCaseDtoList: data.boardCaseDtoList.map(boardCaseDto => this.boardCaseAdapter.adapt(boardCaseDto)),
              totalNumber: data.totalNumber
            })
        )
      );
  }


  public getBoardMedicalCases(caseMedicalFilterRequest: CaseMedicalFilterRequest): Observable<CaseMedicalFilterResponse> {
    const removeIndex = caseMedicalFilterRequest.adtCodes?.indexOf('', 0);
    if (removeIndex !== -1) {
      caseMedicalFilterRequest.adtCodes[removeIndex] = 'ADT00';
    }

    if (caseMedicalFilterRequest.toDate) {
      caseMedicalFilterRequest.toDate.toJSON = function(): string {
        return moment(this).toDate().toJSON();
      };
    }
    if (caseMedicalFilterRequest.fromDate) {
      caseMedicalFilterRequest.fromDate.toJSON = function(): string {
        return moment(this).toDate().toJSON();
      };
    }
    return this.http
      .post<CaseMedicalFilterResponse>(this.baseURL + 'cases/request-medical', caseMedicalFilterRequest, {
        headers: this.jsonHeaders
      })
      .pipe(
        map(
          (data: CaseMedicalFilterResponse) =>
            new CaseMedicalFilterResponse({
              boardMedicalCaseDtoList: data.boardMedicalCaseDtoList.map(boardMedicalCaseDto => this.boardMedicalCaseAdapter.adapt(boardMedicalCaseDto)),
              totalNumber: data.totalNumber
            })
        )
      );
  }

  public getBoardMedicalCasesCount(caseMedicalFilterRequest: CaseMedicalFilterRequest): Observable<number> {
    if (caseMedicalFilterRequest.toDate) {
      caseMedicalFilterRequest.toDate.toJSON = function(): string {
        return moment(this).toDate().toJSON();
      };
    }
    if (caseMedicalFilterRequest.fromDate) {
      caseMedicalFilterRequest.fromDate.toJSON = function(): string {
        return moment(this).toDate().toJSON();
      };
    }
    return this.http
      .post<number>(this.baseURL + 'cases/request-medical-count', caseMedicalFilterRequest, {
        headers: this.jsonHeaders
      });
  }

  public getBoardIncidentEventCases(caseIncidentEventFilterRequest: CaseIncidentEventsRequest): Observable<CaseIncidentEventFilterResponse> {
    let removeIndex = caseIncidentEventFilterRequest.adtCodes.indexOf("", 0);
    if(removeIndex != -1) {
      caseIncidentEventFilterRequest.adtCodes[removeIndex] = "ADT00";
    }

    if (caseIncidentEventFilterRequest.toDate) {
      caseIncidentEventFilterRequest.toDate.toJSON = function(): string {
        return moment(this).toDate().toJSON();
      };
    }
    if (caseIncidentEventFilterRequest.fromDate) {
      caseIncidentEventFilterRequest.fromDate.toJSON = function(): string {
        return moment(this).toDate().toJSON();
      };
    }

    if (caseIncidentEventFilterRequest.incidentToDate) {
      caseIncidentEventFilterRequest.incidentToDate.toJSON = function(): string {
        return moment(this).toDate().toJSON();
      };
    }
    if (caseIncidentEventFilterRequest.incidentFromDate) {
      caseIncidentEventFilterRequest.incidentFromDate.toJSON = function(): string {
        return moment(this).toDate().toJSON();
      };
    }
    return this.http
      .post<CaseIncidentEventFilterResponse>(this.baseURL + 'cases/request-incident-event-cases', caseIncidentEventFilterRequest, {
        headers: this.jsonHeaders
      })
      .pipe(
        map(
          (data: CaseIncidentEventFilterResponse) =>
            new CaseIncidentEventFilterResponse({
              incidentEventCaseBoardViewModelDto: data.incidentEventCaseBoardViewModelDto.map(boardIncidentEventCaseDto => this.boardIncidentEventCaseAdapter.adapt(boardIncidentEventCaseDto)),
              totalNumber: data.totalNumber
            })
        )
      );
  }

  public getBoardIncidentEventCasesCount(): Observable<number> {
    return this.http
      .post<number>(this.baseURL + 'cases/request-incident event-count', {
        headers: this.jsonHeaders
      });
  }

public getBoardTransportCases(caseTransportFilterRequest: CaseTransportRequest): Observable<CaseTransportFilterResponse> {
  let removeIndex = caseTransportFilterRequest.adtCodes.indexOf("", 0);
  if(removeIndex != -1) {
    caseTransportFilterRequest.adtCodes[removeIndex] = "ADT00";
  }

  if (caseTransportFilterRequest.toDate) {
    caseTransportFilterRequest.toDate.toJSON = function(): string {
      return moment(this).toDate().toJSON();
    };
  }
  if (caseTransportFilterRequest.fromDate) {
    caseTransportFilterRequest.fromDate.toJSON = function(): string {
      return moment(this).toDate().toJSON();
    };
  }

  if (caseTransportFilterRequest.incidentToDate) {
    caseTransportFilterRequest.incidentToDate.toJSON = function(): string {
      return moment(this).toDate().toJSON();
    };
  }
  if (caseTransportFilterRequest.incidentFromDate) {
    caseTransportFilterRequest.incidentFromDate.toJSON = function(): string {
      return moment(this).toDate().toJSON();
    };
  }
  return this.http
    .post<CaseTransportFilterResponse>(this.baseURL + 'cases/request-transport-cases', caseTransportFilterRequest, {
      headers: this.jsonHeaders
    })
    .pipe(
      map(
        (data: CaseTransportFilterResponse) =>
          new CaseTransportFilterResponse({
            boardTransportCaseDtoList: data.boardTransportCaseDtoList.map(boardTransportCaseDto => this.boardTransportCaseAdapter.adapt(boardTransportCaseDto)),
            totalNumber: data.totalNumber
          })
      )
    );
}

public getBoardTransportCasesCount(): Observable<number> {
  return this.http
    .post<number>(this.baseURL + 'cases/request-transport-count', {
      headers: this.jsonHeaders
    });
}

  public getBoardCaseById(caseId: string): Observable<BoardCase> {
    const request = {ids: [caseId], onlyValidated: false};

    return this.http
      .post<BoardCase[]>(this.baseURL + 'cases/getByIds', request, {
        headers: this.jsonHeaders
      })
      .pipe(
        map(
          (data: BoardCase[]) => this.boardCaseAdapter.adapt(data[0]),
        )
      );
  }


  public getBoardCaseByIds(caseIds: number[]): Observable<BoardCase[]> {
    const request = {ids: caseIds, onlyValidated: false};

    return this.http
      .post<BoardCase[]>(this.baseURL + 'cases/getByIds', request, {
        headers: this.jsonHeaders
      })
      .pipe(
        map(
          (data: BoardCase[]) => data.map(item => this.boardCaseAdapter.adapt(item)),
        )
      );
  }
}
