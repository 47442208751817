import { SalesOrderService } from "./../../../../../core/services/sales-order.service";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { SalesOrderStatus } from "../../../../../shared/enums/sales-order-status";
import { SalesOrder, SalesOrderAdapter } from "../../../../../shared/models/salesOrder";
import { CaseLockHelperService } from "@secca/core/services/case-lock-helper.service";
import { Case } from "@secca/shared/models/case";
import { PermissionService } from "@secca/core/services/permission.service";
import {
  HandlingAreaStatusEnum,
  HandlingAreaType,
  PermissionEnum,
  SalesOrderItemTypeEnum,
  ServiceTypeEnum
} from "@secca/shared/models/enums";
import { SupplierInvoiceService } from "@secca/core/services/supplier-invoice.service";
import { SupplierInvoice } from "@secca/shared/models/supplierInvoice";
import { ServiceOrderService } from "@secca/core/services/service-order.service";
import { ServiceOrder } from "@secca/shared/models/service-order/service-order";
import { SalesOrderItem } from "@secca/shared/models/salesOrderItem";
import { SupplierInvoiceItem } from "@secca/shared/models/supplierInvoiceItem";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { CustomerInvoiceNoteComponent } from "./customer-invoice-note/customer-invoice-note.component";
import { HandlingAreaService } from "@secca/core/services/handling-area.service";
import { ModalDialogComponent } from "@secca/shared/components/modal-dialog/modal-dialog.component";
import { ModalDialogConfiguration } from "@secca/shared/models/modal/modal-dialog-configuration";
import { HandlingArea } from "@secca/shared/models/handlingArea";
import { TranslateService } from "@ngx-translate/core";
import { FinancialStatusService } from "@secca/core/services/financial-status.service";
import { map, skip, take } from "rxjs/operators";
import { combineLatest, Subscription } from "rxjs";
import { AutoUnsubscribe } from "@secca/shared/decorators/auto-unsubscribe";
import { ServiceItemType } from "@secca/shared/models/service-item-type";
import { CaseStateService } from "@secca/core/state-services/case-state.service";
import {
  CustomerInvoiceDeleteModalComponent
} from "@secca/case/components/case-economy/case-economy-customer-invoices/customer-invoice-delete-modal/customer-invoice-delete-modal.component";
import { SettingsService } from "@secca/core/services/settings.service";
import { FinanceBridgeStatusService } from "@secca/core/services/finance-bridge-status.service";
import { ResetFinanceBridgeStatustDto } from "@secca/shared/models/ResetFinanceBridgeStatustDto";
import {
  CustomerInvoiceCreditModalComponent
} from "@secca/case/components/case-economy/case-economy-customer-invoices/customer-invoice-credit-modal/customer-invoice-credit-modal.component";

@Component({
  selector: 'app-case-economy-customer-invoices',
  templateUrl: './case-economy-customer-invoices.component.html',
  styleUrls: ['./case-economy-customer-invoices.component.scss'],
})
@AutoUnsubscribe
export class CaseEconomyCustomerInvoicesComponent implements OnInit {
  notInvoicedAmount: number;
  sentToERPAmount: number;
  invoicedAmountExclVat: number;
  invoicedAmountInclVat: number;
  customerInvoiceExists = false;
  caseCurrency = 'DKK';
  expanded: boolean[];
  salesOrders: SalesOrder[] = [];
  missingFields: string[];
  readyToInvoice: boolean;
  salesOrderNotInvoiced: boolean;
  emptySalesOrder: boolean;
  theCase: Case;
  $subscription: Subscription;
  private serviceItemList: ServiceItemType[];
  private $salesOrderSubscription: Subscription;
  supplierInvoices: SupplierInvoice[];
  serviceOrders: ServiceOrder[];

  @ViewChild('editNoteModal') editNoteModal: CustomerInvoiceNoteComponent;
  @ViewChild('deleteModal') deleteModal: CustomerInvoiceDeleteModalComponent;
  @ViewChild('creditModal') creditModal: CustomerInvoiceCreditModalComponent;

  @Input()
  set case(newCase: Case) {
    this.theCase = newCase;
  }

  get case(): Case {
    return this.theCase;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  constructor(
    private salesOrderService: SalesOrderService,
    private salesOrderAdapter: SalesOrderAdapter,
    public caseLockHelperService: CaseLockHelperService,
    public permissionService: PermissionService,
    private supplierInvoiceService: SupplierInvoiceService,
    private serviceOrderService: ServiceOrderService,
    private financialStatusService: FinancialStatusService,
    private handlingAreaService: HandlingAreaService,
    private translateService: TranslateService,
    private modalService: NgbModal,
    public caseStateService: CaseStateService,
    public settingsService: SettingsService,
    public financeBridgeStatusService: FinanceBridgeStatusService
  ) {
    this.missingFields = [];
  }

  get SalesOrderStatus() {
    return SalesOrderStatus;
  }

  ngOnInit() {
    this.serviceItemList = [];
    this.readyToInvoice = false;
    this.$subscription = combineLatest([
      this.salesOrderService.getSalesOrders(),
      this.serviceOrderService.getCaseServiceOrders(),
      this.supplierInvoiceService.getCaseSupplierInvoices(),
    ])
    .pipe(skip(1))
    .subscribe(results => {
      const rqSalesOrders = results[0];
      this.serviceOrders = results[1];
      this.supplierInvoices = results[2];
      this.salesOrders = [];
      if (!!rqSalesOrders) {
        rqSalesOrders.forEach(so => this.salesOrders.push(this.salesOrderAdapter.adapt(so)));
        this.removeSuggestedSalesOrders();
        this.removeSuggestedSalesOrderItems();
        this.isAnySalesOrderNotInvoiced();
        this.isAnySalesOrderEmpty();
        this.validateSalesOrders();
        this.sortAndCalculateAggregations();
        if (this.serviceItemList.length === 0) {
          this.supplierInvoiceService.getServiceItemList().subscribe(result => {
            this.serviceItemList = result;
            this.salesOrders.forEach(so => this.sortSalesOrderItems(so, this.supplierInvoices, this.serviceOrders ));
          });
        } else {
          this.salesOrders.forEach(so => this.sortSalesOrderItems(so, this.supplierInvoices, this.serviceOrders ));
        }
      }
    });
  }

  refresh() {
    this.$salesOrderSubscription = this.salesOrderService.getSalesOrdersByCaseId(this.case.id).subscribe(result => {
      const rqSalesOrders = result;
      this.salesOrders = [];
      if (!!rqSalesOrders) {
        rqSalesOrders.forEach(so => this.salesOrders.push(this.salesOrderAdapter.adapt(so)));
        this.removeSuggestedSalesOrders();
        this.removeSuggestedSalesOrderItems();
        this.isAnySalesOrderNotInvoiced();
        this.isAnySalesOrderEmpty();
        this.validateSalesOrders();
        this.sortAndCalculateAggregations();
        if (this.serviceItemList.length === 0) {
          this.supplierInvoiceService.getServiceItemList().subscribe(result => {
            this.serviceItemList = result;
            this.salesOrders.forEach(so => this.sortSalesOrderItems(so, this.supplierInvoices, this.serviceOrders));
          });
        } else {
          this.salesOrders.forEach(so => this.sortSalesOrderItems(so, this.supplierInvoices, this.serviceOrders));
        }
      }
    });
  }

  expandSalesOrder(i: number) {
    this.expanded[i] = this.expanded[i] !== true;
  }

  collapseAll() {
    this.expanded.forEach((expanded, i) => {
      this.expanded[i] = false;
    });
  }

  invoice() {
    if (this.readyToInvoice && !this.emptySalesOrder) {
      this.handlingAreaService.getHandlingsAreas(Number(this.case.id))
        .pipe(map(handlingAreas => handlingAreas?.filter(h => h.handlingAreaType !== HandlingAreaType.RECOVERY)))
        .subscribe(result => {
        if (!!result) {
          const notAllClosed = result.filter(h => h.status !== HandlingAreaStatusEnum.CLOSED);
          if (notAllClosed && notAllClosed.length > 0) {
            const showWarningMessageDialog = this.showWarningMessageDialog(notAllClosed[0]);
            showWarningMessageDialog.componentInstance.closeModalEvent.subscribe(emittedEvent => {
              if (emittedEvent) {
                this.invoicePostValidationAndApproval();
              }
              showWarningMessageDialog.close();
            });
          } else {
            this.invoicePostValidationAndApproval();
          }
        }
      });
    }
  }

  private showWarningMessageDialog(handlingArea: HandlingArea): NgbModalRef {
    const handlingRole = 'HANDLING-AREA-' + handlingArea.handlingAreaType.replace('_', '-') + '-NAME';
    const confirmCloseDialog = this.modalService.open(ModalDialogComponent);
    confirmCloseDialog.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'default-modal-header',
      title: '',
      text: this.translateService.instant('case-economy-customer-invoice-warning-body', {
        caseHandlingRole: this.translateService.instant(handlingRole),
        caseHandlingStatus: handlingArea.status,
      }),
      footer: 'case-economy-customer-invoice-warning-footer',
      yes: 'default-modal-dialog-yes',
      no: 'default-modal-dialog-no',
      isBody: true,
      isFooter: true,
      translateText: false,
    });
    return confirmCloseDialog;
  }

  private invoicePostValidationAndApproval() {
    const newSalesOrders: SalesOrder[] = [];
    this.salesOrders.forEach(salesOrder => {
      if (salesOrder.status === SalesOrderStatus.NOT_INVOICED) {
        this.salesOrderService.finalizeSalesOrder(salesOrder).subscribe(finalizedSalesOrder => {
          finalizedSalesOrder.forEach(so => newSalesOrders.push(so));
          this.salesOrderService.sendSalesOrders(newSalesOrders);
          this.financialStatusService
            .getStatus(this.case.id)
            .pipe(take(1))
            .subscribe(result => this.financialStatusService.sendCaseFinancialStatus(result));
        });
      } else {
        newSalesOrders.push(salesOrder);
      }
    });
    this.readyToInvoice = false;
    this.setNoSalesOrdersMissingFields();
    this.salesOrders = newSalesOrders;
  }

  validateSalesOrders() {
    if (this.salesOrders != null && this.salesOrders.length > 0) {
      const caseId = this.salesOrders[0].seccaCaseId;
      this.validateSalesOrder(caseId);
    }
  }

  private isAnySalesOrderNotInvoiced() {
    this.salesOrderNotInvoiced = false;
    if (!!this.salesOrders) {
      this.salesOrders.forEach(salesOrder => {
        if (salesOrder.status === SalesOrderStatus.NOT_INVOICED) {
          this.salesOrderNotInvoiced = true;
        }
      });
    }
    if (this.salesOrderNotInvoiced === false) {
      this.setNoSalesOrdersMissingFields();
    }
  }

  private isAnySalesOrderEmpty() {
    this.emptySalesOrder = false;
    if (!!this.salesOrders) {
      this.salesOrders.forEach(salesOrder => {
        if (salesOrder.status === SalesOrderStatus.NOT_INVOICED && !!salesOrder.salesOrderItems && salesOrder.salesOrderItems.length < 1) {
          this.emptySalesOrder = true;
        }
      });
    }
  }

  private validateSalesOrder(caseId: string) {
    this.salesOrderService.validateCustomerInvoice(caseId).subscribe(result => {
      if (result.length === 0 && this.salesOrderNotInvoiced) {
        this.readyToInvoice = true;
      } else if (this.salesOrderNotInvoiced) {
        this.readyToInvoice = false;
        this.missingFields = result;
      } else if (this.emptySalesOrder) {
        this.readyToInvoice = false;
      } else {
        this.setNoSalesOrdersMissingFields();
      }
    });
  }

  setNoSalesOrdersMissingFields() {
    this.missingFields = [];
    this.missingFields.push('No sales orders ready to be finalized');
  }

  removeSuggestedSalesOrders() {
    this.salesOrders = this.salesOrders.filter(so => so.id != null)
  }

  removeSuggestedSalesOrderItems() {
    this.salesOrders.forEach(so => {
      const noSuggestions = so.salesOrderItems.filter(soi => !soi.suggestion);
      so.salesOrderItems = noSuggestions;
    });
  }

  private sortAndCalculateAggregations() {
    this.expanded = new Array(this.salesOrders.length);
    // Default sort order is invoice date ascending, then Sales Order with status 'Sent to ERP' and then 'Not invoiced'
    this.salesOrders.sort((s1, s2) => {
      if (s1.invoiceDate && s2.invoiceDate) {
        return s1.invoiceDate > s2.invoiceDate ? 1 : -1;
      } else if (s1.invoiceDate != null && s2.invoiceDate == null) {
        return -1;
      } else if (s1.invoiceDate == null && s2.invoiceDate != null) {
        return 1;
      } else if (s1.status === SalesOrderStatus.SENT_TO_ERP && s2.status === SalesOrderStatus.NOT_INVOICED) {
        return 1;
      } else {
        return -1;
      }
    });
    this.invoicedAmountExclVat = 0;
    this.invoicedAmountInclVat = 0;
    this.customerInvoiceExists = false;
    this.sentToERPAmount = 0;
    this.notInvoicedAmount = 0;
    for (const salesOrder of this.salesOrders) {
      this.caseCurrency = salesOrder.invoicePrice.currency;
      if (salesOrder.status === SalesOrderStatus.INVOICED || salesOrder.status === SalesOrderStatus.PAID) {
        this.invoicedAmountExclVat += salesOrder.invoicePrice.amount;
        this.invoicedAmountInclVat += salesOrder.customerInvoiceInclVat?.amount;
        this.customerInvoiceExists = true;
      } else if (salesOrder.status === SalesOrderStatus.SENT_TO_ERP) {
        this.sentToERPAmount += salesOrder.invoicePrice.amount;
      } else {
        this.notInvoicedAmount += salesOrder.invoicePrice.amount;
      }
    }
    this.isAnySalesOrderNotInvoiced();
    this.isAnySalesOrderEmpty();
  }

  private sortSalesOrderItems(salesOrder: SalesOrder, supplierInvoices: SupplierInvoice[], serviceOrders: ServiceOrder[]) {
    const serviceTypeOrder = Object.values(ServiceTypeEnum);
    salesOrder.salesOrderItems.sort((si1, si2) => {
      if (si1.lineNumber !== null && si2.lineNumber !== null) {
        return si1.lineNumber > si2.lineNumber ? 1 : -1;
      } else if (si1.type === SalesOrderItemTypeEnum.SUPPLIER_INVOICE && si2.type === SalesOrderItemTypeEnum.FEE) {
        return -1;
      } else if (si1.type === si2.type) {
        if (si1.type === SalesOrderItemTypeEnum.SUPPLIER_INVOICE) {
          const s1supplierInvoiceItem = this.getSupplierInvoiceItemForSalesOrderItem(si1, supplierInvoices);
          const s2supplierInvoiceItem = this.getSupplierInvoiceItemForSalesOrderItem(si2, supplierInvoices);
          if (!s1supplierInvoiceItem || !s2supplierInvoiceItem) {
            return 1;
          }
          const s1ServiceType = this.getServiceTypeForSupplierInvoiceItem(s1supplierInvoiceItem, serviceOrders);
          const s2ServiceType = this.getServiceTypeForSupplierInvoiceItem(s2supplierInvoiceItem, serviceOrders);
          if (s1ServiceType === s2ServiceType) {
            const s1serviceItemSort = this.serviceItemList.find(si => si.code === s1supplierInvoiceItem.serviceItemCode);
            const s2serviceItemSort = this.serviceItemList.find(si => si.code === s2supplierInvoiceItem.serviceItemCode);
            return s1serviceItemSort.uiSortIndex - s2serviceItemSort.uiSortIndex;
          } else {
            return serviceTypeOrder.indexOf(s1ServiceType) - serviceTypeOrder.indexOf(s2ServiceType);
          }
        } else if (si1.type === SalesOrderItemTypeEnum.FEE) {
          if (si1.feeType !== si2.feeType) {
            return si1.feeType < si2.feeType ? 1 : -1;
          } else if (si1.sosServicesCategory !== si2.sosServicesCategory) {
            return si1.sosServicesCategory > si2.sosServicesCategory ? 1 : -1;
          } else {
            return si1.sosServiceId > si2.sosServiceId ? 1 : -1;
          }
        }
      } else {
        return 1;
      }
    });
  }

  private getSupplierInvoiceItemForSalesOrderItem(
    salesOrderItem: SalesOrderItem,
    supplierInvoices: SupplierInvoice[]
  ): SupplierInvoiceItem {
    if (!supplierInvoices) {
      return null;
    }
    let supplierInvoiceItem: SupplierInvoiceItem = null;
    let i = 0;
    while (supplierInvoiceItem === null && i < supplierInvoices.length) {
      supplierInvoiceItem = supplierInvoices[i].items.find(item => item.id == salesOrderItem.supplierInvoiceItemId);
      i++;
    }
    return supplierInvoiceItem;
  }

  private getServiceTypeForSupplierInvoiceItem(supplierInvoiceItem: SupplierInvoiceItem, serviceOrders: ServiceOrder[]): ServiceTypeEnum {
    if (supplierInvoiceItem && !!serviceOrders) {
      return serviceOrders.find(serviceOrder => serviceOrder.serviceOrderId === supplierInvoiceItem.serviceOrderId)?.type;
    }
    return null;
  }

  getPriceVatIncluded(salesOrderItem: SalesOrderItem): number {
    if (salesOrderItem.vatPrice) {
      return salesOrderItem.invoicePrice.amount + salesOrderItem.vatPrice.amount;
    }
    return salesOrderItem.invoicePrice.amount;
  }

  editNote(salesOrder: SalesOrder) {
    this.editNoteModal.show(salesOrder);
  }

  deleteSalesOrder(salesOrder: SalesOrder) {
    if (salesOrder.salesOrderItems.length > 0) {
      return;
    }
    this.deleteModal.show(salesOrder);
  }

  resetRetryCount(salesOrder: SalesOrder): void {
    let selectedSalesOrders = new Array;
    if (salesOrder !== undefined) {
      selectedSalesOrders.push(salesOrder.id);
      if (selectedSalesOrders !== undefined && selectedSalesOrders.length > 0) {
        this.financeBridgeStatusService.postResetRetryCount(new ResetFinanceBridgeStatustDto({
          salesOrderId: selectedSalesOrders,
        })).subscribe(result => {
          this.refresh();
        });
      }
    }
  }

  resetAckMessage(salesOrder: SalesOrder): void {
    console.log('resetAckMessage');
    let selectedSalesOrders = new Array;
    if (salesOrder !== undefined) {
      selectedSalesOrders.push(salesOrder.id);
      if (selectedSalesOrders !== undefined && selectedSalesOrders.length > 0) {
        this.financeBridgeStatusService.postResetAckMessage(new ResetFinanceBridgeStatustDto({
          salesOrderId: selectedSalesOrders,
        })).subscribe(result => {
          this.refresh();
        });
      }
    }
  }

  creditDebtor(salesOrder: SalesOrder): void {
    if(salesOrder.invoiceDate == null || salesOrder.credited || salesOrder.creditNote){
      return;
    }
    this.creditModal.show(salesOrder);
  }

}
