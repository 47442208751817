import { Injectable } from '@angular/core';
import { Adapter } from '../../../../../shared/interfaces/adapter';
import * as moment from 'moment';
import { DateSerializer } from '@secca/shared/models/date-serializer';

export class RecoveryTaskViewModel {
    id: number;
    caseId: number;
    isSelected: boolean;
    createdOn: moment.Moment;
    updatedOn: moment.Moment;
    dueDate: moment.Moment;
    caseNumber: string;
    recoveryId: number;
    eligableAmount: string;
    appliedAmount: string;
    recoveredAmount: string;
    recoveryStatus: string;
    applicationDeadline: moment.Moment;
    recoveryCurrency: string;
    customerProfileId: string;
    assignedTo: string;

    public constructor(init?: Partial<RecoveryTaskViewModel>) {
        Object.assign(this, init);
    }
}

@Injectable({
    providedIn: 'root',
  })
  export class RecoveryTaskViewModelAdapter implements Adapter<RecoveryTaskViewModel> {
    adapt(item: any): RecoveryTaskViewModel {
        return new RecoveryTaskViewModel({
            id: item.id,
            caseId: item.caseId,
            createdOn: item.createdOn === '' ? null : DateSerializer.deserialize(item.createdOn),
            updatedOn: item.updatedOn === '' ? null : DateSerializer.deserialize(item.updatedOn),
            dueDate: item.dueDate === '' ? null : DateSerializer.deserialize(item.dueDate),
            caseNumber: item.caseNumber,
            recoveryId: item.recoveryId,
            eligableAmount: item.eligableAmount,
            appliedAmount: item.appliedAmount,
            recoveredAmount: item.recoveredAmount,
            recoveryStatus: item.recoveryStatus,
            applicationDeadline: item.applicationDeadline === '' ? null : DateSerializer.deserialize(item.applicationDeadline),          
            recoveryCurrency: item.recoveryCurrency,
            customerProfileId: item.customerProfileId,
            assignedTo: item.assignedTo
        });
    }
}



