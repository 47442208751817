import { Injectable } from '@angular/core';
import { Adapter } from '../../../../../shared/interfaces/adapter';
import * as moment from 'moment';
import { DateSerializer } from '@secca/shared/models/date-serializer';
import { HandlingAreaStatusEnum, MedicalPreAssessmentStatusEnum } from '@secca/shared/models/enums';

export class PreDepartureTaskViewModel {
    id: number;
    caseId: number;
    status: MedicalPreAssessmentStatusEnum;
    type: string;
    title: string;
    isSelected: boolean;
    caseCreatedOn: moment.Moment;
    departureDate: moment.Moment;
    customerProfileName: string;
    customerProfileId: number;
    endUserFullName: string;
    caseLockedBy: number;
    caseNumber: string;
    location: string;
    assignInitials: string;
    medicalPreAssessmentDecision: string;
    subjectText: string;
    teamStatus: HandlingAreaStatusEnum;

    public constructor(init?: Partial<PreDepartureTaskViewModel>) {
        Object.assign(this, init);
    }
}

@Injectable({
    providedIn: 'root',
  })
  export class PreDepartureTaskViewModelAdapter implements Adapter<PreDepartureTaskViewModel> {
    adapt(item: any): PreDepartureTaskViewModel {
        return new PreDepartureTaskViewModel({
            id: item.id,
            caseId: item.caseId,
            type: item.type,
            title: item.title,
            caseCreatedOn: item.receivedDate === '' ? null : DateSerializer.deserialize(item.caseCreatedOn),
            departureDate: item.departureDate === '' ? null : DateSerializer.deserialize(item.departureDate),
            endUserFullName: item.endUserFullName,
            caseNumber: item.caseNumber,
            caseLockedBy: item.caseLockedBy,
            location: item.location,
            customerProfileName: item.customerProfileName,
            customerProfileId: item.customerProfileId,
            status: item.status,
            assignInitials: item.assignInitials,
            medicalPreAssessmentDecision: item.medicalPreAssessmentDecision,
            subjectText: item.subjectText,
            teamStatus: item.teamStatus,
        });
    }
}



