import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import { CaseDocumentTag } from '@secca/shared/models/case-document-tag';
import {CaseDocumentTagEnum, DocumentGroupChannel} from '@secca/shared/models/enums';
import { CaseDocument } from '@secca/shared/models/caseDocument';
import * as moment from 'moment-timezone';
import {FileScan, FileScanAdapter} from '@secca/shared/models/fileScan';

export class CaseDocumentTableRow {
  id: number;
  documentName: string;
  documentExtension: string;
  documentId: string;
  documentType: string;
  documentDate: moment.Moment;
  isOutgoing: boolean;
  sender: string;
  recipient: string;
  documentTags: DropdownDictionary[];
  documentWithPreTags: DropdownDictionary[];
  caseDocumentTags: CaseDocumentTag[];
  comments: string;
  status: string;
  unhandled: boolean;
  deleted: boolean;
  stakeholderIcon: string;
  stakeholderFullName: string;
  deletedBy: string;
  stakeholderSenderId: number;
  stakeholderReceiverId: number;
  documentGroupChannel: DocumentGroupChannel;
  fileScan: FileScan;

  constructor(private document: CaseDocument) {
    this.id = document.id;
    this.documentName = document.documentName;
    this.documentExtension = document.documentExtension;
    this.documentId = document.documentId;
    this.documentType = document.documentType;
    this.documentDate = document.documentDate;
    this.isOutgoing = document.isOutgoing;
    this.sender = document.sender;
    this.recipient = document.recipient;
    this.documentTags = document.caseDocumentTags ? document.caseDocumentTags.map(tag => this.createDropdownDictionary(tag)) : [];
    this.caseDocumentTags = document.caseDocumentTags ? document.caseDocumentTags : [];
    this.comments = document.comments;
    this.status = document.status;
    this.unhandled = document.unhandled;
    this.deleted = document.deleted;
    this.deletedBy = document.deletedBy;
    this.stakeholderSenderId = document.stakeholderSenderId;
    this.stakeholderReceiverId = document.stakeholderReceiverId;
    this.documentGroupChannel = document.documentGroupChannel;
    this.fileScan = document.fileScan;
  }

  getCaseDocument(): CaseDocument {
    this.document.caseDocumentTags = this.getCaseDocumentTags();
    this.document.comments = this.comments;
    return this.document;
  }

  private getCaseDocumentTags(): CaseDocumentTag[] {
    return (this.document.caseDocumentTags = this.documentTags.map(tag => this.createCaseDocumentTag(tag)));
  }

  private createDropdownDictionary(tag: CaseDocumentTag): DropdownDictionary {
    return new DropdownDictionary(tag.name, CaseDocumentTagEnum[tag.name]);
  }

  private createCaseDocumentTag(tag: DropdownDictionary): CaseDocumentTag {
    return new CaseDocumentTag({ name: tag.id });
  }
}
