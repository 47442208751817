<div class="case-basics-card case-basics-incident-container position-relative" *ngIf="incident">

  <button
    (click)="winterSport()"
    class="winter-sport"
    [ngClass]="{'winter-sport-active': incident.winterSport}" triggers="hover" popoverClass="custom-popover" container="body" [ngbPopover]="popOverText()">
    <div class="d-flex">
      <div
        placement="top"
        >
        <span class="shortcut-key" >W</span>inter sports
      </div>
      <div>
        <img  class="snow-flake"  [src]="incident.winterSport ? '/assets/icons/snowflake.svg' : '/assets/icons/snowflake-gray.svg' " />
      </div>
    </div>
  </button>

  <div [appDisableInput]="caseStateService.isCaseDisabled()">
  <div *ngIf="type === 'BASIC'">
    <div class="field-row">
      <div class="col-12">
        <div class="incident-cause">
          <span class="input-name">Cause</span>
          <div class="incident-container">
            <div class="incident-dropdown">
              <app-drop-down
                #caseCauseLevel1Component
                [items]="incidentCausesLevel1"
                [(selectedItemId)]="incident.causeLevel1Code"
                [noBorderAndArrow]="true"
                (focusin)="openCauseLevel1Dropdown()"
                (selectedItemIdChange)="incidentCauseLevel1Changed()"
                (saveModel)="updateIncident(screeningUpdateType.NONE)"
                [requiredRedBackground]="true">
              </app-drop-down>
            </div>
            <div class="incident-dropdown-separator">/</div>
            <div class="incident-dropdown">
              <app-drop-down
                #caseCauseLevel2Component
                [items]="incidentCausesLevel2"
                [(selectedItemId)]="incident.causeLevel2Code"
                (focusin)="openCauseLevel2Dropdown()"
                [disabled]="!incident.causeLevel1Code"
                (selectedItemIdChange)="incidentCauseLevel2Changed()"
                [noBorderAndArrow]="true"
                (saveModel)="updateIncident(screeningUpdateType.NONE)"
                [recommended]="!incident.causeLevel2Code"
              >
              </app-drop-down>
            </div>
            <div class="incident-dropdown-separator">/</div>
            <div class="incident-dropdown">
              <app-drop-down
                #caseCauseLevel3Component
                [items]="incidentCausesLevel3"
                [(selectedItemId)]="incident.causeLevel3Code"
                (focusin)="openCauseLevel3Dropdown()"
                [disabled]="incidentCausesLevel3?.length === 0"
                [noBorderAndArrow]="true"
                (saveModel)="updateIncident(screeningUpdateType.NONE)"
                [recommended]="!!incident.causeLevel2Code && incidentCausesLevel3?.length !== 0 && (incident.causeLevel3Code === '' || incident.causeLevel3Code === null || incident.causeLevel3Code === undefined)"
              >
              </app-drop-down>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="field-row">
      <div class="col-6 incident-date">
        <app-input-calendar
          [withImage]="true"
          [name]="'Incident date'"
          [date]="incident.incidentDate"
          (dateChange)="updateIncidentDate($event)"
          [maxDate]="tomorrow"
          [recommended]="!incident.incidentDate"
          >
        </app-input-calendar>
      </div>
      <div class="col-6 incident-date">
          <app-input-calendar
          [withImage]="true"
          [name]="'First medical visit'"
          [date]="incident.firstMedicalVisit"
          (dateChange)="updateFirstMedicalVisitDate($event)">
        </app-input-calendar>
      </div>
    </div>
    <div class="field-row">
      <div class="col-12 incident-location">
        <app-input-location [name]="'Incident location'"  placeholder="" autocorrect="off" autocapitalize="off" spellcheck="false" type="text"
          [allowEmpty]="false" (saveModel)="updateLocation(locationTypes.INCIDENT)" [freeText]="incident.incidentLocation.freeText"  [withImage]="true" [value]="incident.incidentLocation"
          [validationType]="ValidationTypeEnum.required"></app-input-location>
      </div>
    </div>

    <div class="field-row">
      <div class="col-12 incident-location">
        <app-input-location [name]="'Current location'" placeholder="" autocorrect="off" autocapitalize="off" spellcheck="false" type="text"
          [allowEmpty]="true" (saveModel)="updateLocation(locationTypes.CURRENT)" [withImage]="true" [value]="incident.currentLocation"
          [validationType]="ValidationTypeEnum.default" [recommended]="incident.currentLocation.freeText === '' || incident.currentLocation.freeText === null"></app-input-location>
      </div>
    </div>
    <div class="row">
      <div class="col-12 incident-description">
        <app-text-box [maxTextLength]="250" [name]="'Description of incident'" [(value)]="incident.description" (focusout)="updateIncident()"
          [placeHolder]="freeTextFieldPlaceholder" [recommended]="incident.description === '' ||  incident.description === null"></app-text-box>
      </div>
    </div>
      <div class="row">
      <div class="col-12 incident-main-diagnosis">
        <app-multiple-select
          [name]="diagnosisLabel | translate"
          [maxElements]="1" [singleValue]="true" [alternativeBoxStyle]="medicalDiagnosis || medicalPreAssessmentDiagnosis"
          [(selectedItems)]="selectedDiagnosis" (selectedItemsChange)="updateDiagnosis()" [disabled]="medicalDiagnosis || medicalPreAssessmentDiagnosis" [recommended]="incident.causeLevel1Code === 'CL100' && selectedDiagnosis.length === 0" >
        </app-multiple-select>
      </div>
    </div>
    <div class="row">
      <div class="col-12 incident-report-symptoms">
        <app-multiple-select
          [name]="symptomsLabel | translate"
          [maxElements]="5" [singleValue]="false" [alternativeBoxStyle]="medicalSymptoms"
          [(selectedItems)]="selectedSymptoms" [excludedElements]="findExcludedElement(selectedDiagnosis,0)"
          (selectedItemsChange)="updateSymptoms()" [disabled]="medicalSymptoms || medicalPreAssessmentSymptoms">
        </app-multiple-select>
      </div>
    </div>
  </div>
  </div>
  <div *ngIf="type === 'EVENTS'">
    <div class="field-row">
      <div class="col-11 dropdown-padding" *ngIf="!this.hideIncidentDropdown">
          <app-drop-down
              [name]="'incident-event' | translate"
              (saveModel)="update()"
              (selectedItemIdChange)="updateSelectedIncidentEvent($event)"
              [items]="eventsDropdown"
              [selectedItemId]="incident.incidentEvent"
              [disabled]="!selectedIncidentDropdownActive || incidentEventLockedByServiceOrder"
              [recommended]="false">
          </app-drop-down>
      </div>
      <div class="col-1" *ngIf="!this.hideIncidentDropdown">
        <img class="event-delete" (click)="deleteIncidentEvent()" *ngIf="selectedIncidentDropdownActive && !incidentEventLockedByServiceOrder"  src="/assets/icons/garbageRed.svg" />
        <img class="event-delete-disabled" disabled src="/assets/icons/garbage.svg" *ngIf="!selectedIncidentDropdownActive || incidentEventLockedByServiceOrder" />
      </div>
      <div class="col-12" *ngIf="this.hideIncidentDropdown">
        <app-input
            [name]="'incident-event' | translate"
            [value]="selectedIncidentDropdownName"
            [disabled]="true"      >
          </app-input>
      </div>
    </div>
    <div *ngIf="selectedIncidentEvent">
      <div class="field-row">
        <div class="col-3">
          <app-input-calendar
            [withImage]="true"
            [name]="'Date of event'"
            [date]="selectedIncidentEvent.dateOfEvent"
            [disabled]="true"
            >
          </app-input-calendar>
        </div>
        <div class="col-9 incident-date">
          <app-input
            [name]="'Category'"
            [value]="getEnumValue(selectedIncidentEvent.category)"
            [disabled]="true"      >
          </app-input>
        </div>
      </div>
      <div class="field-row">
        <div class="col-12 incident-date">
          <app-text-box
            [maxTextLength]="250"
            [name]="'Description'"
            [value]="selectedIncidentEvent.description"
            [disabled]="true">
          </app-text-box>
        </div>
      </div>
      <div class="w-50">
        <app-drop-down
            [name]="'incident-event-transport-arranged-by-sos' | translate"
            (saveModel)="update()"
            [items]="transportArrangedBySosList"
            [(selectedItemId)]="incident.transportAssistance"
            [disabled]="incident.incidentEvent === null || incident.incidentEvent === '0'"
            [requiredRedBackground]="!incident.transportAssistance"
            [recommended]="false">
        </app-drop-down>
      </div>
    </div>
  </div>
</div>