<ul ngbNav #nav="ngbNav" class="service-order-type-tabset" [(activeId)]="activeTab" (navChange)="tabChange($event)" class="nav-tabs">
  <li id="{{SelectedTab.ExpectationTab}}" [ngbNavItem]="SelectedTab.ExpectationTab" >
    <a ngbNavLink><div class="tap">{{"service-type-expectation" | translate}}</div></a>
    <ng-template ngbNavContent>
      <div [appDisableInput]="caseLocked || isCommitted()" class="caselock-no-events">
        <div class="sub-component-placeholder row">
          <div class="col-6">
              <div class="sub-content-container">
                <app-input
                  [name]="'sub-transport-type-name' | translate"
                  [value]="coordinationCaseTypeText"
                  [disabled]='true'>
                </app-input>
              </div>
          </div>
          <div class="col-6">
            <app-sub-commitment-type
              [serviceOrderType]="serviceOrderType"
              [(commitmentType)]="coordinationTransport.commitmentType"
              [readOnlyMode]="true">
            </app-sub-commitment-type>
          </div>
        </div>
        <div class="sub-component-placeholder">
            <div *ngFor='let coordinationDetail of expectedCoordinatedDetails'>
              <app-end-user-case
                [flightStakeholders]='coordinationTransport.iccExpectedFlightStakeholders'
                [showInvalidBackground]='showInvalidBackground'
                [serviceOrderType]='serviceOrderType'
                [subStakeholdersConfig]='subStakeholdersConfig'
                [caseId]='coordinationDetail.caseId.toString()'
                [coordinatedCaseDetail]='coordinationDetail'
                [iccServiceOrderStatus]="status"
                [showPassengerConfirmed]="false">
              </app-end-user-case>
            </div>
        </div>
        <div class="sub-component-placeholder">
          <app-sub-travellers
              [travellers]="expectedFlight"
              [subStakeholdersConfig]="subStakeholdersConfig"
              [hideCabinClass]="true"
              [hideFlightInRefs]="true"
              [departureDateRequired]="true"
              [departureFromRequired]="true"
              [arrivalDateRequired]="false"
              [arrivalAtRequired]="true"
              [arrivalDateWhite]="true"
              [hideAddFlightLeg]="false"
              [serviceOrderType]="serviceOrderType"
              [readOnlyMode]="isCommitted()"
              [resourceConfig]="expectedCoordinationResourceConfig"
            >
            </app-sub-travellers>
        </div>
        <div class="sub-component-placeholder">
          <app-sub-remarks-to-supplier [readOnlyMode]="isCommitted()" [(subRemarksToSupplier)]="coordinationTransport.remarksToSupplier"></app-sub-remarks-to-supplier>
        </div>
        <div class="sub-content-container">
          <div class="row">
            <div class="col-12">
              <ng-template #popContent><span [innerHTML]="hoverContent | safeHtml"></span></ng-template>
              <button class="primary-button float-end me-4 mb-2" [class.primary-button-inactive]="!disableConfirmationButton()" (click)="sendConfirmation()" (mouseenter)="generateMissingFieldsText()" [disabled]="!disableConfirmationButton()">
                <div [disablePopover]="isValid()" triggers="hover" [ngbPopover]="popContent">
                  {{ 'coordination-flight-send-request-or-confirmation' | translate }}
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </li>
  <li [id]="SelectedTab.ConfirmationTab" *ngIf="serviceOrderTypeState.showConfirmationTab" [ngbNavItem]="SelectedTab.ConfirmationTab">
    <a ngbNavLink><div class="tap">{{"service-type-confirmation" | translate}}<div class="close-tab-x" (click)="closeConfirmationTab($event)"
                                                                  *ngIf="!serviceOrderTypeState.isConfirmationTabSaved"><img src="/assets/icons/Close Black.svg"></div></div>
    </a>
    <ng-template ngbNavContent>
      <div [appDisableInput]="caseLocked" class="caselock-no-events">
        <div class="sub-component-placeholder row">
          <div class="col-6">
            <div class="sub-content-container">
              <app-input
                [name]="'sub-transport-type-name' | translate"
                [value]="coordinationCaseTypeText"
                [disabled]='true'>
              </app-input>
            </div>
          </div>
          <div class="col-6">
            <app-sub-commitment-type
              [serviceOrderType]="serviceOrderType"
              [(commitmentType)]="coordinationTransport.commitmentType"
              [readOnlyMode]="true">
            </app-sub-commitment-type>
          </div>
        </div>
        <div class="sub-component-placeholder row">
          <ng-template #popContent><span [innerHTML]="'left-side-no-supplier-selected-warning' | translate | safeHtml"></span></ng-template>
        </div>
        <div class="sub-component-placeholder">
          <div *ngFor='let coordinationDetail of committedCoordinatedCaseDetails'>
            <ng-container *ngIf="coordinationTransport.iccConfirmationFlightStakeholders" >
              <app-end-user-case
                [flightStakeholders]='coordinationTransport.iccConfirmationFlightStakeholders'
                [savedFlightStakeholders]="savedFlightStakeholders"
                [showInvalidBackground]='showInvalidBackground'
                [serviceOrderType]='serviceOrderType'
                [subStakeholdersConfig]='subStakeholdersConfig'
                [caseId]='coordinationDetail.caseId.toString()'
                [coordinatedCaseDetail]='coordinationDetail'
                [iccServiceOrderStatus]="status"
                [showPassengerConfirmed]="true">
              </app-end-user-case>
            </ng-container>
          </div>
        </div>

        <div class="sub-component-placeholder">
          <app-sub-travellers
              [travellers]="confirmationFlight"
              [subStakeholdersConfig]="subStakeholdersConfig"
              [hideCabinClass]="true"
              [hideFlightInRefs]="false"
              [agencyReferenceRequired]="false"
              [departureDateRequired]="true"
              [departureFromRequired]="true"
              [arrivalDateRequired]="true"
              [arrivalAtRequired]="true"
              [arrivalDateWhite]="true"
              [hideAirlineReference]="true"
              [hideTicketNo]="true"
              [hideAddFlightLeg]="false"
              [serviceOrderType]="serviceOrderType"
              [resourceConfig]="confirmtCoordinationResourceConfig"
              [agencyReferenceRequired]="true"
              [flightNoRequired]="true"
            >
            </app-sub-travellers>
        </div>
        <div class="sub-component-placeholder">
          <app-sub-remarks-from-supplier [(subRemarksFromSupplier)]="coordinationTransport.remarksFromSupplier"></app-sub-remarks-from-supplier>
        </div>
        <div class="sub-content-container">
          <div class="row">
            <div class="col-12" *ngIf="coordinatedCaseDetails">
              <ng-template #popContentUpdate><span [innerHTML]="hoverContent | safeHtml"></span></ng-template>
              <button class="primary-button float-end me-4 mb-2"
                      [class.primary-button-inactive]="!disableConfirmationButton()"
                      (click)="sendConfirmation()"
                      (mouseenter)="generateMissingFieldsText()"
                      [disabled]="!disableConfirmationButton()">
                      <div [disablePopover]="isValid()" triggers="hover" [ngbPopover]="popContentUpdate">
                        {{ 'coordination-flight-send-updated-request-or-confirmation' | translate }}
                      </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </li>
  <li *ngFor='let coordinationDetail of coordinatedCaseDetails' id="coordinationDetail.caseNumber" [ngbNavItem]="coordinationDetail.caseNumber" >
      <a style="font-size: 12px; line-height: 12px; padding-top: 3px;" ngbNavLink>
        <div class="tap">
          {{coordinationDetail.caseNumber}}
        </div>
        <div class="tap">
          {{ coordinationDetail.stakeholders[0].person.firstName }} {{ coordinationDetail.stakeholders[0].person.surname }}
        </div>
      </a>
    <ng-template ngbNavContent>
      <app-end-user-linked-case-component
        [readonly]='false'
        [caseId]='caseId'
        [endUserCaseDetail]='coordinationDetail'
        [coordinationTransportServiceOrderExtension]='coordinationTransport'
        [subStakeholdersConfig]="subStakeholdersConfig"
        [serviceOrderType]='serviceOrderType'
        [status]='status'
        (updateEnduserCaseRemark)="setCaseRemark($event, coordinationDetail.caseId)"
      >
      </app-end-user-linked-case-component>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="nav"></div>
