import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
import { ServiceTypeEnum, ServiceTypeItemEnum } from './enums';

export class ServiceItemType {
  id: number;
  name: string;
  code: string;
  vat: boolean;
  recovery: boolean;
  sosServiceTypes: ServiceTypeEnum[];
  uiSortIndex: number;
  activateOn: Date;
  deactivateOn: Date;
  serviceItemType: string;

  public constructor(init?: Partial<ServiceItemType>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
export class ServiceItemTypeAdapter implements Adapter<ServiceItemType> {
  adapt(item: any): ServiceItemType {
    const sosServiceTypes: ServiceTypeEnum[] = [];
    item.serviceTypes.forEach(type=>{
      sosServiceTypes.push(ServiceTypeEnum[String(type.serviceTypeEnumValue)])
    });
    const deactivateOn = item.deactivateOn ? new Date(item.deactivateOn) : null;
    return new ServiceItemType({
      id: item.serviceItemCode,
      name: item.serviceItemName,
      code: item.serviceItemCode,
      vat: item.vat,
      recovery: item.recovery,
      sosServiceTypes,
      uiSortIndex: item.uiSortIndex,
      activateOn: new Date(item.activateOn),
      deactivateOn,
      serviceItemType: item.serviceItemType as keyof typeof ServiceTypeItemEnum,
    });
  }
}
