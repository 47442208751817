import { Injectable } from '@angular/core';
import { Adapter } from '../../interfaces/adapter';
import { EquipmentItemEnum } from '../enums';

export class Equipment {
  otherItemDescription: string;
  item: EquipmentItemEnum;

  public constructor(init?: Partial<Equipment>) {
    Object.assign(this, init);
  }

  setOtherItemDescription(value: string) {
    this.otherItemDescription = value;
  }
}

@Injectable({
  providedIn: 'root'
})
export class EquipmentAdapter implements Adapter<Equipment> {
  adapt(item: any): Equipment {
    return new Equipment({
      otherItemDescription: item.otherItemDescription,
      item: item.item
    });
  }
}
