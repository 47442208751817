import { StakeholderService } from 'src/app/core/services/stakeholder.service';
import {PermissionEnum, PermissionHideTypeEnum, SensitiveType, StakeholderTypeEnum} from './../../../../../../shared/models/enums';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { CaseHistoryCommEntry, CaseHistoryEntry } from './../../../../../../shared/models/caseHistoryEntry';
import { CaseCommunicationService } from '../../../../../../core/services/case-communication.service';
import { OutputManagementService } from '../../../case-output-management/services/output-management.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StakeholderHelperService } from '../../../../../../core/services/stakeholder-helper.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommunicationDirection, MessageChannelType } from 'src/app/shared/models/enums';
import { Message } from '../../../case-output-management/models/message';
import { CaseHistoryType } from '@secca/shared/enums/case-history-type';
import { FaxDto } from '@secca/shared/models/fax-dto';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import { ServiceOrderMessageRequest } from '@secca/shared/models/service-order/service-order-message-request';
import { CaseMessageService } from '@secca/core/services/case-message.service';
import { PermissionService } from '@secca/core/services/permission.service';
import { Case } from '@secca/shared/models/case';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { CaseDialogViewerService } from '@secca/case/case-dialog-viewer.service';
import { Subscription } from 'rxjs';
import { CaseSummaryService } from '@secca/core/services/caseSummary.service';
import { CaseSummary } from '@secca/shared/models/caseSummary';
import { CaseService } from '@secca/core/services/case.service';
import {ModalDialogComponent} from '@secca/shared/components/modal-dialog/modal-dialog.component';
import {ModalErrorDialogComponent} from '@secca/shared/components/modal-error-dialog/modal-error-dialog.component';
import {ModalDialogConfiguration} from '@secca/shared/models/modal/modal-dialog-configuration';
import { LockContextEnum } from '@secca/shared/enums/lock-context.enum';

@Component({
  selector: 'app-case-summary-email-fax',
  templateUrl: './case-summary-email-fax.component.html',
  styleUrls: ['./case-summary-email-fax.component.scss'],
})
export class CaseSummaryEmailFaxComponent {
  @Input()
  case: Case;

  @Input() set entry(entry: CaseHistoryCommEntry) {
    if (entry) {
      this._entry = entry;
      this.tryToMatchStakeholder();
    }
  }
  get entry(): CaseHistoryCommEntry {
    return this._entry as CaseHistoryCommEntry;
  }
  _entry: CaseHistoryCommEntry;
  @Input() showActionButton: boolean;

  @Input()
  set stakeholders(stakeholders: CaseStakeholder[]) {
    if (stakeholders) {
      this._stakeholders = stakeholders;
      this.tryToMatchStakeholder();
    }
  }
  get stakeholders(): CaseStakeholder[] {
    return this._stakeholders;
  }
  _stakeholders: CaseStakeholder[];
  showEntryMenu: boolean;
  modalReference: any;
  stakeholder: CaseStakeholder;
  deleteReason: string;

  constructor(
    private stakeholderHelperService: StakeholderHelperService,
    private modalService: NgbModal,
    private outputManagementService: OutputManagementService,
    private caseCommunicationService: CaseCommunicationService,
    private stakeholderService: StakeholderService,
    public caseLockHelperService: CaseLockHelperService,
    public permissionService: PermissionService,
    private caseMessageService: CaseMessageService,
    public caseStateService: CaseStateService,
    private dialogViewerService: CaseDialogViewerService,
    private caseService: CaseService
  ) {
  }

  get deleted(): boolean {
    return !!this.entry?.deletedBy;
  }

  get CommunicationDirection() {
    return CommunicationDirection;
  }

  get getStakeholderType() {
    return this.stakeholderHelperService.getStakeholderNameForType(this.entry.toStakeholderType);
  }

  tryToMatchStakeholder() {
    if (this.stakeholders != null && this.entry != null) {
      if (this.entry.direction === CommunicationDirection.IN_BOUND) {
        if (this.entry.updatedStakeholderSenderId) {
          this.stakeholder = this.stakeholders.find(a => +a.id === this.entry.updatedStakeholderSenderId);
        } else {
          this.stakeholder = this.stakeholders.find(a => +a.id === this.entry.stakeholderSenderId);
        }
      }
      if (this.entry.direction === CommunicationDirection.OUT_BOUND) {
        this.stakeholder = this.stakeholders.find(a => +a.id === this.entry.stakeholderReceiverId);
      }
    }
  }

  viewMenuClick() {

      this.dialogViewerService.openCaseSummaryEmailFaxDialog(this.entry, this.case.caseNumber);

  }

  deleteMenuClick() {
    if (this.isEmail()) {
      this.caseCommunicationService.deleteCaseEmailWithDeleteReason(this.entry.entityId, this.deleteReason)
        .subscribe(s => {
          this.caseService.publishCaseNotesChanges(+ this.case.id);
        });
    } else {
      this.caseCommunicationService.deleteCaseFaxWithDeleteReason(this.entry.entityId, this.deleteReason)
        .subscribe(s => {
          this.caseService.publishCaseNotesChanges(+this.case.id);
        });
    }
  }

  openModal() {

    const modalRef = this.modalService.open(ModalDialogComponent);
    modalRef.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'default-modal-header',
      title: 'case-summary-modal-gdpr-delete',
      text: '',
      footer: 'case-summary-modal-gdpr-delete-proceed',
      showYesNoButton: false,
      yes: 'Yes',
      no: 'No',
      labelTextAboveTextArea: 'case-summary-modal-gdpr-delete-reason-label',
      placeholderText: 'case-summary-modal-gdpr-delete-reason-placeholder',
      showTextAreaWithYesNo: true,
      showTextArea: true,
      isBody: true,
      isFooter: true,
      modalYesHoverDialog: 'case-summary-modal-gdpr-delete-yes-hover'
    });

    modalRef.componentInstance.saveandcloseEvent.subscribe(
      emittedEvent => {
        this.closeModal();
        if (emittedEvent) {
          this.deleteReason = emittedEvent;
          this.deleteMenuClick();
        }
      },
      error => { }
    );

    modalRef.componentInstance.closeModalEvent.subscribe(
      emittedEvent => {
        this.closeModal();
      },
      error => console.log('error', error)
    );
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  get CaseHistoryType() {
    return CaseHistoryType;
  }

  isEmail(): boolean {
    return (
      this.entry.caseHistoryType === CaseHistoryType.EMAIL ||
      this.entry.caseHistoryType === CaseHistoryType.EMAIL_RECEIVE ||
      this.entry.caseHistoryType === CaseHistoryType.EMAIL_UPDATE
    );
  }

  replyMenuClick() {
    let stakeholderId;
    if (this.entry.direction === CommunicationDirection.IN_BOUND) {
      stakeholderId = this.stakeholder == null ? null : +this.stakeholder.id;
    } else {
      stakeholderId = this.entry.stakeholderReceiverId;
    }

    const msg = new Message({
      caseId: this.entry.caseId.toString(),
      channelDisabled: true,
      fax: new FaxDto({}),
      messageChannelType: this.isEmail() ? MessageChannelType.EMAIL : MessageChannelType.FAX,
      replyEmailId: this.isEmail() ? this.entry.emailId : this.entry.faxId,
      stakeholderReceiverId: stakeholderId,
      subject: 'RE:' + this.entry.subject,
      subjectLock: true
    });

    this.outputManagementService.openMessageWindow(
      this.entry.caseId.toString(),
      new ServiceOrderMessageRequest({ message: msg, messageService: this.caseMessageService })
    );
  }

  get stakeholderType() {
    if (this.stakeholder) {
      return this.stakeholderHelperService.getStakeholderNameForType(this.stakeholder.stakeholderType as StakeholderTypeEnum);
    }
  }

  get stakeholderIcon() {
    if (this.stakeholder) {
      return this.stakeholderService.getStakeholderIconFullPath(this.stakeholder.stakeholderType);
    }
  }

  get stakeholderName() {
    if (this.stakeholder) {
      if (this.stakeholder.company) {
        return this.stakeholder.company.name;
      } else {
        return this.stakeholder.person.firstName + ' ' + this.stakeholder.person.surname;
      }
    }
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  get PermissionHideTypeEnum() {
    return PermissionHideTypeEnum;
  }

  get LockContextEnum() {
    return LockContextEnum;
  }
}
