<div [class.sub-component-container]="!hideInnerBorder">
  <div class="sub-content-container">
    <div class="row">
      <div class="col-5">
        <app-drop-down [disabled]="readonly" [name]="'sub-transport-type-name' | translate" [items]="transportTypes"
          (selectedItemIdChange)="selectedTransportTypeChange($event)" [(selectedItemId)]="subTranportType.transportType"></app-drop-down>
      </div>
      <div class="col-4" *ngIf="!hideGetButton">
        <button class="primary-button get-button" (click)="getClick()" *ngIf="subTranportType.transportType === TransportTypeEnum.TAXI">
          <span> {{"sub-transport-type-get-e" | translate}}</span>
          <img src="/assets/icons/new window white.svg">
        </button>
      </div>
    </div>
  </div>
</div>
