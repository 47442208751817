import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import { MedicalAssessmentAction } from '@secca/shared/models/medicalAssessment';
import { MedicalExaminationsTypeEnum } from '@secca/shared/models/enums';
import { DateHelperService } from '@secca/core/services/date-helper.service';
import * as moment from 'moment';
import * as onChange from 'on-change';


@Component({
  selector: 'app-action-card',
  templateUrl: './action-card.component.html',
  styleUrls: ['./action-card.component.scss']
})
export class ActionCardComponent implements OnInit  {

  @Input() actionTypes: DropdownDictionary;
  @Input() medicalAssessmentAction: MedicalAssessmentAction;  
  @Input() index: number;
  @Input() disabled: boolean;
  @Output() actionDelete = new EventEmitter();
  @Input() actionDeleteble: boolean;
  
  constructor(private dateHelperService: DateHelperService) { }

  ngOnInit() {
    const copyMedicalAssessmentAction = JSON.parse(JSON.stringify(onChange.target(this.medicalAssessmentAction)));
    if (this.medicalAssessmentAction.date) {
      this.medicalAssessmentAction.date = moment.utc(copyMedicalAssessmentAction.date, this.dateHelperService.getDateFormat());
    }
  }

  setDate($event) {
    this.medicalAssessmentAction.date = $event;
  }

  deleteAction() {
    this.actionDelete.emit(this.index);
  }

  getMedicalExaminationsTypeEnum() {
    return MedicalExaminationsTypeEnum;
  }
}
