import {Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {RefundService} from '@secca/core/services/refund.service';
import {DropdownDictionary} from '@secca/shared/models/dropdownDictionary';
import {
  BalanceSheetEventEnum, BalanceSheetStatusEnum,
  InputType,
  InputTypePattern,
  MessageChannelType,
  MessageFrom,
  QuantityUnit,
  RefundAccountType,
  RefundStatus,
  StakeholderTypeEnum,
  ValidationTypeEnum
} from '@secca/shared/models/enums';
import {Refund, SelectItem} from '@secca/shared/models/refund';
import {RefundItem} from '@secca/shared/models/refund-Item';
import {ServiceOrder} from '@secca/shared/models/service-order/service-order';
import {PermissionService} from '@secca/core/services/permission.service';
import {ReductionsCalculator} from '../refund-item/reduction-calculator';
import {Currency, DineroObject} from 'dinero.js';
import {TranslateService} from '@ngx-translate/core';
import {CaseService} from '@secca/core/services/case.service';
import {SimpleCaseStakeholder, SimpleCaseStakeholderAdapter} from '@secca/shared/models/simpleCaseStakeholder';
import {CaseStakeholder} from '@secca/shared/models/caseStakeholder';
import {StakeholderService} from '@secca/core/services/stakeholder.service';
import {CountryDetails, CountryDetailsAdapter} from '@secca/shared/models/country';
import {AutoCompleteTypeEnum} from '@secca/shared/components/drop-down-countries-auto-complete/auto-complete-type-enum';
import {NationalIdService} from '@secca/core/services/national-id.service';
import {
  DropDownCountriesAutoComplete
} from '@secca/shared/components/drop-down-countries-auto-complete/drop-down-countries-auto-complete.component';
import {DictionaryService} from '@secca/core/services/dictionary.service';
import * as moment from 'moment-timezone';
import * as _ from 'lodash';
import {Message} from '@secca/case/components/case-output-management/models/message';
import {ServiceOrderMessageRequest} from '@secca/shared/models/service-order/service-order-message-request';
import {CaseMessageService} from '@secca/core/services/case-message.service';
import {OutputManagementService} from '@secca/case/components/case-output-management/services/output-management.service';
import {ModalDialogComponent} from '@secca/shared/components/modal-dialog/modal-dialog.component';
import {ModalDialogConfiguration} from '@secca/shared/models/modal/modal-dialog-configuration';
import {DialogViewerServiceInterface} from "@secca/core/services/dialog-viewer-service-interface";
import {DIALOG_VIEWER_TOKEN} from "@secca/core/services/token";
import {CaseValidationService} from "@secca/core/services/case-validation.service";
import {CaseLockHelperService} from '@secca/core/services/case-lock-helper.service';
import {CaseLockOverlayAction, LockContextEnum} from '@secca/shared/enums/lock-context.enum';
import {finalize} from "rxjs/operators";
import {CaseStateService} from '@secca/core/state-services/case-state.service';
import {MasterListService} from "@secca/core/services/masterlist-service";
import {SupplierInvoiceService} from "@secca/core/services/supplier-invoice.service";
import {ChannelType} from "@secca/shared/enums/channelType";
import {Case} from "@secca/shared/models/case";
import {FinancialStatusService} from "@secca/core/services/financial-status.service";
import { ServiceItemType } from '@secca/shared/models/service-item-type';
import { MomentHelperService } from '@secca/core/services/moment-helper.service';


@Component({
  selector: 'app-add-refund',
  templateUrl: './add-refund.component.html',
  styleUrls: ['./add-refund.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddRefundComponent implements OnInit {
  @Input() objection: boolean;
  @Input() readOnly: boolean;
  @Input() caseCreatedDate: Date;
  @Input()
  set refundId(value: string) {
    if(value) {
      this._refundId = value;
      this.getRefund();
    } else {
      this.createRefund();
    }
  }

  get refundId(): string {
    return this._refundId;
  }
  private _refundId: string;


  @Input() customerProfileId: string;
  @Input()
  set caseId(value: string) {
    if(value) {
      this._caseId = value;
        this.updateServiceOrdersList();
        this.updateSimpleStakeholdersOnCase();
    }
  }

  get caseId(): string {
    return this._caseId;
  }
  private _caseId: string;

  @Output() closed = new EventEmitter<number>();
  @Output() update = new EventEmitter<number>();
  @Output() refundDeleted = new EventEmitter<number>();


  @ViewChild('bankHomeCountry') bankHomeCountry: DropDownCountriesAutoComplete;

  serviceItemList: any[];
  refund: Refund;
  oldRefund: Refund;
  refundForApprove: Refund;
  refundAccountType: SelectItem<RefundAccountType>[];
  quantityUnits: DropdownDictionary[];
  refundStatusUnits: DropdownDictionary[];
  baseCurrenciesSupported: DropdownDictionary[];
  exchangeCurrenciesSupported: DropdownDictionary[];
  selectableServiceOrders: ServiceOrder[];
  private reductionsCalculator: ReductionsCalculator;
  localCurrency: DineroObject;
  lockRefundCurrency: boolean = true;
  stakeholderList: SimpleCaseStakeholder[];
  stakeholderForNemIdList: SimpleCaseStakeholder[];
  selectedRefundStakeholder: CaseStakeholder;
  nationalIdCountry: CountryDetails;
  currentCase : Case;
  theShownNationalId: string;
  nationalIdValid: boolean = false;
  storingRefund: boolean = false;
  claimsOnlineCase: boolean = false;
  recieverOfRefund: string;
  nonShowNationalId: string;
  caseStakeholderCountry: CountryDetails;
  bankCountry: CountryDetails;
  hideBankInfo: boolean = true;
  isCaseErpReady = false;
  groupMemberValid = true;
  today: moment.Moment = moment.utc();
  tomorrow: moment.Moment = moment.utc().add(1, 'd');
  dueDate: moment.Moment = moment.utc();
  calculateTotalRefundCurrency: number;
  refundState = new RefundState();
  ssnValidationStatus = 'not-looked-up';
  waitBeforeInitTextbox: boolean = false;
  refundToday: Date;
  noSpecialCharacters: RegExp = /[`~!¤¨´'@#§£€½$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi;
  savingTypeList: DropdownDictionary[];
  refundTypes: DropdownDictionary[];
  private maxModifiedUser: string;
  private maxModifiedOn: string;

  constructor(private modalService: NgbModal,
              private refundService: RefundService,
              public permissionService: PermissionService,
              private masterListService: MasterListService,
              private translateService: TranslateService,
              private supplierInvoiceService: SupplierInvoiceService,
              private caseService: CaseService,
              private simpleStakeholderAdapter: SimpleCaseStakeholderAdapter,
              private stakeholderService: StakeholderService,
              private countryAdapter: CountryDetailsAdapter,
              private nationalIdService: NationalIdService,
              private dictionaryService: DictionaryService,
              private caseMessageService: CaseMessageService,
              private outputManagementService: OutputManagementService,
              private caseValidationService: CaseValidationService,
              public caseLockHelperService: CaseLockHelperService,
              public caseStateService: CaseStateService,
              public financialStatusService: FinancialStatusService,
              @Inject(DIALOG_VIEWER_TOKEN) private dialogViewerService: DialogViewerServiceInterface) {
    this.reductionsCalculator = new ReductionsCalculator();
    this.getCurrencyList();
  }

  ngOnInit(): void {
    this.serviceItemList = [];
    this.quantityUnits = [];
    this.refundStatusUnits = []
    this.theShownNationalId = '';
    this.nonShowNationalId = '';
    this.nationalIdCountry = null;

    this.caseService.getCase('' + this._caseId).subscribe(
      result => {
        this.currentCase = result;
        if(result.channelType === ChannelType.CLAIMS_BUSINESS || result.channelType === ChannelType.ONLINE_CLAIMS){
          this.claimsOnlineCase = true;
        }
      }
    );

    if(this.customerProfileId){
      this.validateCustomerProfile();
    }

    this.caseService.isCaseErpReady(this._caseId).subscribe(result => {
      this.isCaseErpReady = result;
    });

    this.refundService.getServiceOrdersForRefunds(+this.caseId).subscribe(orders => {
      this.selectableServiceOrders = orders;
    });

    this.supplierInvoiceService.getServiceItemList().subscribe(serviceItemList => {
      this.serviceItemList = serviceItemList;
    });

    this.masterListService.getRefundReductionTypes().subscribe(result => {
      this.savingTypeList = [];
      result.forEach(item => {
        this.savingTypeList.push(new DropdownDictionary(String(item.reductionCode), item.reductionName));
      });
    });

    this.masterListService.getRefundTypes().subscribe(result => {
      this.refundTypes = result;
    });

    for (const unit in QuantityUnit) {
      if (isNaN(Number(unit))) {
        this.quantityUnits.push(new DropdownDictionary(QuantityUnit[unit], unit));
      }
    }

    for (const unit in RefundStatus) {
      if (isNaN(Number(unit))) {
        this.refundStatusUnits.push(new DropdownDictionary(RefundStatus[unit],  this.translateService.instant('case-economy-refund-refund-status-' +  unit)));
      }
    }
  }

  private validateCustomerProfile() {
    this.caseService.getCustomerProfile(this.customerProfileId.toString()).subscribe(customerProfile =>{
      if(customerProfile.isGroupProfile){
        this.groupMemberValid = false;
      }
    });
  }

  updateServiceOrdersList() {
    this.refundService.getServiceOrdersForRefunds(+this.caseId).subscribe(orders => {
      this.selectableServiceOrders = orders;
    });
  }

  updateAccountTypeDisplay() {
    this.refundAccountType = this.getSingleSelectItemFromEnum(RefundAccountType, this.refund.accountType, 'case-economy-refund-');
  }

  getCurrencyList() {
    this.dictionaryService.getExchangeRefundCurrenciesSupported().subscribe(currencies1 => {
      this.exchangeCurrenciesSupported = [];
      currencies1.forEach(element => {
        this.exchangeCurrenciesSupported.push(new DropdownDictionary(element, element));
      });
    });
    this.dictionaryService.getBaseCurrenciesSupported().subscribe(currencies2 => {
      this.baseCurrenciesSupported = [];
      currencies2.forEach(element => {
        this.baseCurrenciesSupported.push(new DropdownDictionary(element, element));
      });
    });
  }

  private getSingleSelectItemFromEnum(enumItem: any, selectedPath: any, prefix: string) {
    return Object.keys(enumItem).map(key => {
      return new SelectItem({
        label: this.translateService.instant(prefix + key.toString().toLowerCase().replace(/_/g, '-')),
        value: enumItem[key],
        selected: enumItem[selectedPath] === enumItem[key]
      });
    });
  }

  getRefund(){
    this.refund = new Refund();
    this.refundService.getRefundById(+this.refundId).subscribe(
      result => {
        this.refund = result;
        this.refund.customerProfileId = + this.customerProfileId;
        this.recalcReductionAmounts();
        this.getStakeholder(this.refund.stakeHolderId);
        this.updateAccountTypeDisplay();
        this.getCalculateTotalRefundCurrency();
        this.addLocalCurrency();
        this.getRefundState();
        if(this.refund.accountType === RefundAccountType.FOREIGN){
          this.exchangeCurrenciesSupported = this.exchangeCurrenciesSupported.filter(s => s.name !== 'DKK');
          this.lockRefundCurrency = false;
        }
        this.refundService.getRefundHistory(this.refund.id).subscribe(progress => this.refund.progress = progress);
        this.oldRefund = _.cloneDeep(this.refund);
        setTimeout(() => {
            this.waitBeforeInitTextbox = true;
        }, 500);
      }
    );
    this.createRefund();
  }

  createRefund() {
    this.refund = new Refund();
    this.refund.caseId = + this._caseId;
    this.refund.customerProfileId = + this.customerProfileId;
    this.refund.refundStatus = RefundStatus.REFUND_REGISTERED;
    this.refund.refundCurrency = 'DKK';
    this.refund.internalRemark = '';
    this.refund.refundDescription = null;
    this.refund.stakeHolderId = null;
    this.refund.id = null;
    this.refund.templateName = null;
    this.refund.accountNo = null;
    this.refund.accountType = null;
    this.refund.bankCountry = null;
    if(this.today.weekday() === 5){
      this.refund.refundDate = moment.utc().add(3, 'days');
    } else if(this.today.weekday() === 6){
      this.refund.refundDate = moment.utc().add(2, 'days');
    } else {
      this.refund.refundDate = moment.utc().add(1, 'days');
    }
    this.refund.iban = null;
    this.refund.items = [];
    this.refund.accountType = RefundAccountType.NEMKONTO;
    this.refund.objection = this.objection;
    this.addItem();
    this.updateAccountTypeDisplay();
    this.addLocalCurrency();
    this.addProgressDraft();
    this.oldRefund = _.cloneDeep(this.refund);
    setTimeout(() => {
      this.waitBeforeInitTextbox = true;
  }, 500);
  }

  addProgressDraft() {
    this.refund.progress = {currentEvent: BalanceSheetEventEnum.INITIAL, aggregated: false, events: [{event: BalanceSheetEventEnum.INITIAL, status: BalanceSheetStatusEnum.SUCCESS, text: RefundStatus.REFUND_REGISTERED, modifierList: []}]};

  }

  addLocalCurrency() {
    this.localCurrency = {amount: 0, currency: <Currency>this.refund.refundCurrency, precision: 2};
  }

  recalcReductionAmounts() {
    this.refund.items.forEach(item => {
      const reductions = this.reductionsCalculator.recalcReductionAmounts(this.localCurrency, item);
      item.reductions = reductions;
    })
  }

  deleteRefundItem(refundItem: RefundItem) {
    const original = this.refund.items;
    this.refund.items = original.filter(s => s.id !== refundItem.id);
  }

  close() {
    if ( this.closed.observers ) {
      this.closed.emit();
    }
    this.modalService.dismissAll();
  }

  addItem() {
    const refundItem = new RefundItem({
      serviceOrderId: null,
      serviceItemCode: null,
      quantity: null,
      quantityUnit: null,
      refundAmount: null,
      refundDate: null,
      refundCurrency: 'DKK',
      reductions: [],
    });
    refundItem.id = -1 * this.refund.items.length;
    this.refund.items.push(refundItem);
  }

  updateSimpleStakeholdersOnCase() {
    this.caseService.getSimpleStakeholdersOnCase(this.caseId).subscribe(
      simpleStakeholders => {
        this.stakeholderList = [];
        this.stakeholderForNemIdList = [];
        simpleStakeholders.forEach((item: SimpleCaseStakeholder) => {
          if (
                (item.hasRole(StakeholderTypeEnum.person) ||
                  item.hasRole(StakeholderTypeEnum.endUser) ||
                  item.hasRole(StakeholderTypeEnum.policyHolder)  ||
                  item.hasRole(StakeholderTypeEnum.reporter) && !item.isCompany) ||
                  item.hasRole(StakeholderTypeEnum.company)
                 )
            {
              this.stakeholderList.push(item);
              if (item.age >= 18 && item.nationalIdCountry === 'DK'){
                this.stakeholderForNemIdList.push(item);
              }
            }
        });
      }
    );
  }

  getStakeholder(stakeholderId: number) {
    if (stakeholderId !== null) {
      this.stakeholderService.getStakeholder(stakeholderId).subscribe(result => {
        this.selectedRefundStakeholder = result;
        this.nationalIdCountry = this.selectedRefundStakeholder.isCompany ? null : this.countryAdapter.adaptFromCountry(this.selectedRefundStakeholder.person.nationalIdCountry);
        this.theShownNationalId = this.selectedRefundStakeholder.person?.nationalId;
        this.caseStakeholderCountry = this.countryAdapter.adaptFromCountry(this.selectedRefundStakeholder.address.country);
        this.nationalIdValid = this.selectedRefundStakeholder.person?.nationalIdValid;
        this.recieverOfRefund = this.selectedRefundStakeholder.isCompany ? this.selectedRefundStakeholder.company.name :
          this.selectedRefundStakeholder.person.firstName + ' ' + this.selectedRefundStakeholder.person.surname;
        this.updateSsnValidationStatus();

        if (this.refund.bankCountry) {
          this.dictionaryService.getCountryByAlpha2(this.refund.bankCountry).subscribe(result => {
            if (result) {
              this.bankCountry = result;
            }
          });
        } else {
          this.bankCountry = new CountryDetails();
        }

        if (!!this.theShownNationalId) {
          this.nonShowNationalId = this.nationalIdService.getMaskedId(
            this.selectedRefundStakeholder.person.nationalIdCountry.code,
            this.selectedRefundStakeholder.person.nationalId
          );
          this.theShownNationalId = this.nonShowNationalId;
        }
      });
    }
  }

  bankCountryChanged(countryDetails: CountryDetails) {
    this.refund.bankCountry = countryDetails.code;
  }

  showNationalIdClick() {
    if (this.theShownNationalId === this.nonShowNationalId) {
      this.theShownNationalId = this.selectedRefundStakeholder.person.nationalId;
    } else if (!this.selectedRefundStakeholder.person.nationalId) {
      this.theShownNationalId = '';
    } else {
      this.theShownNationalId = this.nonShowNationalId;
    }
  }

  changeAccountType(event) {
    this.exchangeCurrenciesSupported = this.exchangeCurrenciesSupported.filter(s => s.name !== 'DKK');
    if (event.value === RefundAccountType.NEMKONTO || event.value === RefundAccountType.OTHER) {
      this.exchangeCurrenciesSupported.push(new DropdownDictionary('DKK', 'DKK'));
      this.refund.refundCurrency = 'DKK';
      this.lockRefundCurrency = true;
      this.updateRefundAmounts();
      if(this.refund.stakeHolderId){
        const stakeholder = this.stakeholderList.find(stakeholder => stakeholder.id === this.refund.stakeHolderId);
        if((this.refund.accountType === RefundAccountType.NEMKONTO)) {
          if(stakeholder.age < 18 || stakeholder.nationalIdCountry !== 'DK'){
            this.refund.stakeHolderId = null;
            this.selectedRefundStakeholder = null;
          }
        } else if (this.refund.accountType === RefundAccountType.OTHER) {
          if(stakeholder.age < 18) {
            this.refund.stakeHolderId = null;
            this.selectedRefundStakeholder = null;
          }
        }
      }


    }
    else {
      this.refund.refundCurrency = '';
      this.lockRefundCurrency = false;
    }
  }

  get RefundAccountType() {
    return RefundAccountType;
  }

  get AutoCompleteTypeEnum() {
    return AutoCompleteTypeEnum;
  }

  get InputType() {
    return InputType;
  }

  get InputTypePattern() {
    return InputTypePattern;
  }

  datesAreEmpty(): boolean {
    return ( !this.refund.receivedDate );
  }

  exists(object: any) : boolean {
    if (typeof object !== "undefined") {
      if (object) {
        return true;
      }
    }

    return false;
  }

  disabledRequestInformation() {
    return this.datesAreEmpty() || !this.requestInformationActiveStatus() || !this.checkForInvallidReductions();
  }

  requestInformationActiveStatus() {
    return this.refund.refundStatus === RefundStatus.REFUND_REGISTERED ||
           this.refund.refundStatus === RefundStatus.ONGOING_CLAIMS_HANDLING ||
           this.refund.refundStatus === RefundStatus.PENDING_INFORMATION ||
           this.refund.refundStatus === RefundStatus.INFORMATION_RECEIVED ||
           this.refund.refundStatus === RefundStatus.AWAITING_SECOND_APPROVER
  }

  showDeleteButton() {
    return (this.refund.refundStatus === RefundStatus.REFUND_REGISTERED || this.refund.refundStatus === RefundStatus.ONGOING_CLAIMS_HANDLING) && !!this.refund.id;
  }

  openCancelWarningDialog() {
    const modalRef = this.modalService.open(ModalDialogComponent);
    modalRef.componentInstance.items = [];
    modalRef.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'default-modal-header',
      title: 'cancel-refund-acknowledgement-dialog-text',
      text: null,
      footer: 'case-validation-warning-modal-footer',
      yes: 'default-modal-dialog-yes',
      no: 'default-modal-dialog-no',
      isBody: true,
      isFooter: true,
    });
    modalRef.componentInstance.closeModalEvent.subscribe(
      closedClickingYes => {
        if (closedClickingYes) {
          this.refundService.cancelARefund(this.refund).subscribe(data => {
            this.close();
          });
        }
        modalRef.close();
      },
      error => console.log(error)
    );
  }

  showCancelButton() {
    return this.refund.refundStatus === RefundStatus.APPROVED || this.refund.refundStatus === RefundStatus.PAID;
  }

  disableCancelButton() {
    return this.refund.refundStatus !== RefundStatus.APPROVED || !this.approvedToday();
  }

  approvedToday() {
    const appliedApprovalDate = this.refund.secondApprovedDate ? this.refund.secondApprovedDate : this.refund.approvedDate;
    return this.refund.approvedDate && !moment.utc().startOf('day').isAfter(appliedApprovalDate);
  }

  isCancelled() {
    return this.refund.refundStatus === RefundStatus.CANCELLED;
  }

  isInErrorStatus() {
    return this.refund.refundStatus === RefundStatus.ERROR;
  }

  disableSavedButton() {
    switch (this.refund.refundStatus) {
      case RefundStatus.APPROVED:
      case RefundStatus.REJECTED:
        return !(!this.datesAreEmpty() && this.refundChanged());
      break;

      default:
        return !(!this.datesAreEmpty() && this.refundChanged() && this.checkForInvallidReductions());
      break;
    }
  }

  deleteRefund() {
    const showCloseWarningMessageDialog = this.showCloseWarningMessageDialog();
      showCloseWarningMessageDialog.componentInstance.closeModalEvent.subscribe(emittedEvent => {
        if(emittedEvent) {
          this.refundService.deleteRefundAndSetTaskToDone(this.refund.id).subscribe(refund =>{
            if ( this.refundDeleted.observers ) {
              this.refundDeleted.emit();
            }
            this.modalService.dismissAll();
           });
        }
      showCloseWarningMessageDialog.close();
    });
  }

  refundChanged(): boolean {
    return JSON.stringify(this.oldRefund) !== JSON.stringify(this.refund);
  }

  validateForOngoingStatius() : boolean {
    let returnValue = false;
    this.refund.items.forEach(item => {
        if(item.serviceOrderId && item.serviceItemCode) {
          returnValue = true;
        }
    })
    return returnValue;
  }

  difference(object, base): Refund {
    function changes(object, base) {
        return _.transform(object, function(result, value, key) {
            if (!_.isEqual(value, base[key])) {
                 result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
            }
        });
    }
    return changes(object, base) as Refund;
  }

  onlyInternlRemarkHasChanged(): boolean {
    var list = this.difference(this.oldRefund, this.refund);
    var returnValue = false;
    if(Object.keys(list).length === 1) {
      if(Object.keys(list)[0] === 'internalRemark') {
        returnValue = true
      }
    }
    return returnValue;
  }

  checkForInvallidReductions() : boolean {
    let returnValue = true;
    this.refund.items.forEach(item => {
      item.reductions.forEach(reduction => {
        if(!reduction.reductionTypeCode || !reduction.amount || !reduction.percentage) {
          returnValue = false;
        }
      }
      )
    })
    return returnValue;
  }

  getRefundState() : RefundState {
    this.refundState.refundContextValid =
      this.exists(this.refund.receivedDate) &&
      this.exists(this.refund.refundCurrency) &&
      this.exists(this.refund.stakeHolderId) &&
      this.exists(this.refund.refundDescription) &&
      this.exists(this.refund.templateId) &&
      this.exists(this.refund.templateLanguage) &&
      this.exists(this.refund.refundType);

    for (let item of this.refund.items) {
        this.refundState.refundItemsValid =
          this.exists(item.serviceOrderId) &&
          this.exists(item.serviceItemCode) &&
          this.exists(item.quantity) &&
          this.exists(item.quantityUnit) &&
          this.exists(item.refundDate) &&
          this.exists(item.refundCurrency) &&
          this.exists(item.refundAmount);

        if (this.refundState.refundItemsValid === false) {
          break;
        }

        for (let reduction of item.reductions) {
            this.refundState.refundItemsValid =
              this.exists(reduction.amount) &&
              this.exists(reduction.percentage) &&
              this.exists(reduction.reductionTypeCode);

            if (this.refundState.refundItemsValid === false) {
              break;
            }
        }

        if (this.refundState.refundItemsValid === false) {
          break;
        }
    }

    switch (this.refund.accountType) {
      case RefundAccountType.NEMKONTO:
        this.refundState.refundReceiverValid = (this.nationalIdCountry === null || this.theShownNationalId === '') ? false : true;
        break;
      case RefundAccountType.OTHER:
        this.refundState.refundReceiverValid = (this.selectedRefundStakeholder?.address.protectedAddress && this.refund.otherAccountRegNo && this.refund.accountNo) ? true : (
          this.refundState.refundReceiverValid = (
            !this.refund.otherAccountRegNo ||
            (!this.refund.accountNo || this.refund.accountNo === '0000000000')) ||
          !this.selectedRefundStakeholder?.address.street ||
          !this.selectedRefundStakeholder?.address.postalCode ||
          !this.selectedRefundStakeholder?.address.city ||
          !this.selectedRefundStakeholder?.address.country.code
            ? false : true);
        break;
      case RefundAccountType.FOREIGN:

        this.refundState.refundReceiverValid = (this.selectedRefundStakeholder?.address.protectedAddress && this.refund.bankName && this.refund.bankCountry && this.refund.foreignAccountNo && this.refund.swiftBIC) ? true : (
          this.refundState.refundReceiverValid =
            (
              !this.selectedRefundStakeholder?.address.street ||
              !this.selectedRefundStakeholder?.address.postalCode ||
              !this.selectedRefundStakeholder?.address.city ||
              !this.selectedRefundStakeholder?.address.country.code ||
              !this.refund.bankName ||
              !this.refund.bankCountry ||
              !this.refund.foreignAccountNo ||
              !this.refund.swiftBIC
            ) ? false : true);
        break;
    }
    this.refundState.refundValid = this.refundState.refundContextValid && this.refundState.refundItemsValid && this.refundState.refundReceiverValid;
    this.refundState.previewLetter = this.refundState.refundContextValid && this.refundState.refundItemsValid && this.refundState.refundReceiverValid;
    this.refundState.validForApproveAndReject = this.refundState.documentHasBeenPreviewed && (this.refund.refundStatus !== RefundStatus.AWAITING_SEND_LETTER && this.refund.refundStatus !== RefundStatus.APPROVED && this.refund.refundStatus !== RefundStatus.REJECTED) && this.refundState.refundValid && this.refund.refundDate > this.today && !this.refundChanged();
    this.refundState.validForSendLetter = this.refundState.refundValid && this.refundState.documentHasBeenPreviewed && (this.refund.refundStatus === RefundStatus.AWAITING_SEND_LETTER || this.refund.refundStatus === RefundStatus.APPROVED || this.refund.refundStatus === RefundStatus.REJECTED)
    return this.refundState;
  }

  changeRefundStatusAndSave(close: boolean = true) {
    if((this.refund.refundStatus === RefundStatus.REFUND_REGISTERED) && this.validateForOngoingStatius()) {
      this.refund.refundStatus = RefundStatus.ONGOING_CLAIMS_HANDLING;
    }

    if(this.refund.refundStatus === RefundStatus.PENDING_INFORMATION || this.refund.refundStatus === RefundStatus.INFORMATION_RECEIVED) {
      if(!this.onlyInternlRemarkHasChanged()) {
        this.refund.refundStatus = RefundStatus.ONGOING_CLAIMS_HANDLING;
      }
    }
    this.saveAndClose(close);
  }

  lockRefundOnApproved(): boolean {
    return [RefundStatus.AWAITING_SEND_LETTER, RefundStatus.APPROVED, RefundStatus.REJECTED].includes(this.refund.refundStatus);
  }

  saveAndClose(close: boolean = true){
    if(this.storingRefund){
      return;
    }
    this.storingRefund = true;
    if (this.refund.id === null) {
      this.refundService.createRefund(this.refund).pipe(
        finalize(() => this.storingRefund = false)
      ).subscribe(refund => {
        if (close) {
          this.close();
        } else {
          this.refund = refund;
          this.recalcReductionAmounts();
        }
      });
    } else {
      this.refundService.updateRefund(this.refund).pipe(
        finalize(() => this.storingRefund = false)
      ).subscribe(refund => {
        this.storingRefund = false;
        this.refund = refund;
        if(close){
          this.close();
        } else {
          this.update.emit();
          this.recalcReductionAmounts();
        }
      });
    }
  }

  requestInformation() {
    if (this.storingRefund) {
      return;
    }

    const message = new Message();
    message.templateType = 'CLAIM_LETTER';
    message.caseId = '' + this.refund.caseId;
    message.stakeholderReceiverId = this.refund.stakeHolderId;
    message.messageChannelType = MessageChannelType.EMAIL;
    message.messageFrom = MessageFrom.EMAIL_SENDER_CLAIMS;
    this.refund.refundStatus = RefundStatus.PENDING_INFORMATION;
    if(this.refund.id === null){
      this.refundService.createRefund(this.refund).pipe(
        finalize(() => this.storingRefund = false)
      ).subscribe(refund => {
        this.refund = refund;
        this.openOutputManagement(message);
        this.close();
      });
    }else{
      this.openOutputManagement(message);
      this.saveAndClose(true);
    }
  }

  openOutputManagement(message: Message){
    message.refundId = this.refund.id;
    const srMessage = new ServiceOrderMessageRequest({
      messageService: this.caseMessageService,
      message });
        message.caseNumber = '[' + this.currentCase.caseNumber + '#' + this.refund.id + ']';
        this.dialogViewerService.openOutputManagementDialog(this.currentCase, message.messageChannelType, srMessage, null);
        this.saveAndClose();

  }

  setServiceItemName(refundItem: RefundItem): void {
    const serviceItem: ServiceItemType = this.serviceItemList.find(si => si.code === refundItem.serviceItemCode);
    if (serviceItem) {
      refundItem.serviceItemName = serviceItem.name;
    }
  }

  approve() {
// Refund is approved by user, but next status is AWAITING_SEND_LETTER. Only when send letter has occurred, will the status change to APPROVE
    if (this.storingRefund) {
      return;
    }
    this.storingRefund = true;
    this.refundForApprove = _.cloneDeep(this.refund);
    this.refundForApprove.refundStatus = RefundStatus.AWAITING_SEND_LETTER;
    this.refundForApprove.items.forEach(refundItem => {
      this.setServiceItemName(refundItem);
    });
    this.refundService.updateRefund(this.refundForApprove).pipe(
      finalize(() => this.storingRefund = false)
    ).subscribe(refund => {
      this.refund = refund;
      this.update.emit();
      this.recalcReductionAmounts();
      this.oldRefund = _.cloneDeep(refund);
      if (this.refund.refundStatus !== RefundStatus.AWAITING_SEND_LETTER) {
        const showWarningMessageDialog = this.showWarningMessageDialog();
        showWarningMessageDialog.componentInstance.closeModalEvent.subscribe(emittedEvent => {
          showWarningMessageDialog.close();
          this.close();
        });
      }
    });
  }

  rejectAll() {
    this.refund.refundStatus = RefundStatus.REJECTED;
    this.changeRefundStatusAndSave(false);
  }

  padLeadingZeros(num, size) {
    var s = num+"";
    s = s.replace(/^0+/, '');
    while (s.length < size) s = "0" + s;
    this.refund.accountNo = s;
  }

  toUpperCase(value) {
    if(value) {
      this.refund.swiftBIC = value.toUpperCase().replace(this.noSpecialCharacters, '');
    }else{
      this.refund.swiftBIC = "";
    }
  }

  saveIBANInfo(value) {
    if(value){
      this.refund.foreignAccountNo = value.replace(this.noSpecialCharacters, '');
    }
  }

  updateRefundAmounts() {
    this.localCurrency.currency = <Currency>this.refund.refundCurrency;
    let update = true;
    this.refund.items.forEach(item => {
      if(item.refundAmount === null || item.refundDate === null) {
        update = false
      } else {
        item.reductions.forEach(reduction => {
          if(reduction.amount === null ) {
            update = false;
          }
        })
      }
    })
    if(this.refund.refundCurrency === ''){
      update = false;
    }
    if(update) {
      this.refundService.adjustExchangedBalances(this.refund).subscribe(
        result => {
          this.refund = result;
          this.getCalculateTotalRefundCurrency();
          this.recalcReductionAmounts();
        }
      );
    }
  }
  getCalculateTotalRefundCurrency() {
    this.calculateTotalRefundCurrency =  this.reductionsCalculator.calculateTotalRefundCurrency(this.refund);
  }

  hideBankInfoClick() {
    this.hideBankInfo = !this.hideBankInfo;
  }

  documentHasBeenPreviewed(refund: Refund) {
    refund.items.forEach(item => {
      const reductions = this.reductionsCalculator.recalcReductionAmounts(this.localCurrency, item);
      item.reductions = reductions;
    })
    this.oldRefund = _.cloneDeep(refund);
    this.refundState.documentHasBeenPreviewed = true;
    this.recalcReductionAmounts();
  }

  get ValidationTypeEnum() {
    return ValidationTypeEnum;
  }

  get LockContextEnum(): any {
    return LockContextEnum;
  }

  get CaseLockOverlayAction(): any {
    return CaseLockOverlayAction;
  }

  get getRefundStatus(): typeof RefundStatus {
    return RefundStatus;
  }

  updateSsnValidationStatus() {
    if (this.selectedRefundStakeholder.person?.ssnValidated) {
      this.ssnValidationStatus = 'success';
    } else if (this.selectedRefundStakeholder.person?.ssnLookupPerformed) {
      this.ssnValidationStatus = 'failure';
    } else {
      this.ssnValidationStatus = 'not-looked-up';
    }
  }

  private showWarningMessageDialog() : NgbModalRef {
    const confirmCloseDialog = this.modalService.open(ModalDialogComponent);
    const permissionDeniedText = this.translateService.instant('case-economy-refund-warning-modal-footer', {
      permissionNeeded: this.refund.approverPermissionNecessary
    });
    confirmCloseDialog.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'default-modal-header',
      title: '',
      text: permissionDeniedText,
      yes: 'default-modal-dialog-yes',
      no: 'default-modal-dialog-no',
      isBody: true,
      isFooter: false,
    });
    return confirmCloseDialog;
  }


  private showCloseWarningMessageDialog() : NgbModalRef {
    const confirmCloseDialog = this.modalService.open(ModalDialogComponent);
    confirmCloseDialog.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'default-modal-header',
      title: 'case-economy-refund-delete-warning-modal-title',
      text: '',
      footer: 'case-economy-refund-delete-warning-modal-footer',
      yes: 'default-modal-dialog-yes',
      no: 'default-modal-dialog-no',
      isBody: false,
      isFooter: true,
    });
    return confirmCloseDialog;
  }

  onKeyUp(editor) {
    if (this.refund.refundDescription === '') {
      editor.editor.iframeElement.contentDocument.getElementsByTagName('body')[0].style = 'background-color: #fff9e7;';
    } else {
      editor.editor.iframeElement.contentDocument.getElementsByTagName('body')[0].style = 'background-color: #fff';
    }
  }

  onFocus(e: any) {
    // Force a focus-in event, fix for missing focus-in event
    const event = new FocusEvent('focusin', {
      view: window,
      bubbles: true,
      cancelable: true
    });
    const editor = e?.editor?.iframeElement;
    if ( editor ) {
      editor.dispatchEvent(event);
    }
  }

  get tinyMCEConfiguration() {
    let style = '';
    if (this.refund.refundDescription === '' || this.refund.refundDescription === null) {
      style = 'body { font-family: calibri; font-size: 11pt; background-color: #fff9e7;  }'
    } else {
      style = 'body { font-family: calibri; font-size: 11pt; }'
    }
    return {
      base_url: '/tinymce',
      suffix: '.min',
      menubar: false,
      height: '100%',
      body_class: "mceBlackBody",
      paste_data_images: true,
      fontsize_formats: '8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt 24pt 28pt 32pt 36pt',
      content_style: style,

      font_formats:
        'Calibri=calibri; Arial=arial,helvetica,sans-serif;' +
        'Times New Roman=times new roman,times;' +
        'Helvetica=helvetica;' +
        'Courier New=courier_newregular,courier;' +
        'Verdana=verdana,geneva;',
      plugins: [
        'print preview searchreplace autolink ' +
          'directionality visualblocks visualchars fullscreen image link ' +
          'media template codesample table charmap hr pagebreak nonbreaking ' +
          'anchor toc insertdatetime advlist lists wordcount ' +
          'imagetools textpattern noneditable help ' +
          'charmap quickbars emoticons '
      ],
      toolbar:
        'bold italic underline strikethrough | fontselect fontsizeselect formatselect | \
        alignleft aligncenter alignright alignjustify | \
        bullist numlist checklist  | outdent indent | forecolor backcolor formatpainter'
      };
    }

  showContactMessage() {
    return this.refund.errors && this.refund.errors.find(err => err.action === 'CONTACT_IMPLEMENTATION_AND_DEVELOPMENT');
  }

  getSecondApprover() {
    if (this.refund.approverAwaiting != null) {
      return this.refund.approverPermissionNecessary;
    } else if (this.refund.secondApprover != null) {
      return this.refund.secondApproverName;
    } else if (this.refund.firstApprover != null) {
      return this.translateService.instant('case-economy-refund-second-approver-not-relevant');
    } else {
      return '';
    }
  }

  isModifiedAfterApproved(): boolean {
    let maxModifiedOn = null;
    const refundItemsWithValidModifiedOn = this.refund.items.filter(item => !!item.modifiedOn);
    if (refundItemsWithValidModifiedOn.length > 0) {
      // Find the maximum modifiedOn
      maxModifiedOn = refundItemsWithValidModifiedOn.reduce((item1, item2) => (item1.modifiedOn.isAfter(item2.modifiedOn)) ? item1 : item2);
      const refundReductionsWithValidModifiedOn = _.flatten(refundItemsWithValidModifiedOn.map(item => item.reductions)).filter(reduction => !!reduction.id);
      let maxReductionModifiedOn = null;
      if (refundReductionsWithValidModifiedOn.length > 0) {
        maxReductionModifiedOn = refundReductionsWithValidModifiedOn.reduce((reduction1, reduction2) => (reduction1.modifiedOn.isAfter(reduction2.modifiedOn)) ? reduction1 : reduction2);
      }
      if (maxReductionModifiedOn && maxReductionModifiedOn.modifiedOn && maxReductionModifiedOn.modifiedOn.isAfter(maxModifiedOn.modifiedOn)) {
        maxModifiedOn = maxReductionModifiedOn;
      }
    }
    if (!maxModifiedOn || this.refund.modifiedOn.isAfter(maxModifiedOn.modifiedOn)) {
      maxModifiedOn = this.refund;
    }
    const isModified = !!maxModifiedOn.modifiedOn && !this.refund.secondApprovedDate && this.refund.approverAwaiting && maxModifiedOn.modifiedOn.isAfter(this.refund.approvedDate);
    if (isModified) {
      this.maxModifiedUser = maxModifiedOn.modifiedUserName;
      this.maxModifiedOn = maxModifiedOn.modifiedOn.clone().tz(MomentHelperService.resolveTimeZone('local')).format('DD MMM YYYY HH:mm');
    } else {
      this.maxModifiedUser = '';
      this.maxModifiedOn = '';
    }
    return isModified;
  }

  getEventsToDisplay() {
    return [BalanceSheetEventEnum.INITIAL, BalanceSheetEventEnum.AWAITING, BalanceSheetEventEnum.UPDATED, BalanceSheetEventEnum.ONGOING, BalanceSheetEventEnum.HANDLED, BalanceSheetEventEnum.CREDITOR_PAID, BalanceSheetEventEnum.INVOICING];
  }
}
export class RefundState {
  refundValid: boolean = false;
  refundContextValid: boolean = false;
  refundItemsValid: boolean = false;
  refundReceiverValid: boolean = false;
  previewLetter:boolean = false;
  documentHasBeenPreviewed: boolean = false;
  validForApproveAndReject: boolean = false;
  validForSendLetter: boolean = false;
  validToSaveAfterApproved: boolean = false;
}
