import { Injector } from '@angular/core';
import { SettingsService } from '@secca/core/services/settings.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalErrorDialogComponent } from '../components/modal-error-dialog/modal-error-dialog.component';
import { ErrorModalDialogConfiguration } from '../models/errormodal/modal-error-configuration';

export class ProviderHelper {

  private settingsService: SettingsService;

  constructor(private modalService: NgbModal) {
    const injector = Injector.create({ providers: [{ provide: SettingsService, deps: [] }] });
    this.settingsService = injector.get(SettingsService);
  }

  public messageError(error: any, headerText: string, titleText: string, textBody: string) {
    const modalRef = this.modalService.open(ModalErrorDialogComponent, {
      centered: true,
      windowClass: !this.settingsService.isZoomed() ? '' : 'top-most-window-class-zoomed',
    });

    let errorCode;
    let id;
    if (error?.error?.message) {
      const splitted = error.error.message.split(' - ', 2);
      errorCode = splitted[0];
      if (splitted.length > 1) {
        id = splitted[1];
      }
    } else {
      errorCode = error?.status;
    }

    modalRef.componentInstance.configuration = new ErrorModalDialogConfiguration({
      header: headerText,
      title: titleText,
      text: textBody,
      footer: '',
      isFooter: false,
      isBody: true,
      ok: 'Ok',
      errorCode: errorCode,
      id: id,
      url: error.url,
      dateAndTime: new Date(),
    });
    modalRef.componentInstance.closeModalEvent.subscribe(
      emittedEvent => {
        modalRef.close();
      },
      err => console.log(err)
    );
  }
}
