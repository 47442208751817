import { SupplierInvoice } from '../../../../../shared/models/supplierInvoice';
import { ServiceOrder } from '@secca/shared/models/service-order/service-order';
import { SupplierInvoiceItem } from '../../../../../shared/models/supplierInvoiceItem';
import { RefundStatus, SupplierInvoiceStatus } from '../../../../../shared/models/enums';
import { ReductionsCalculator } from '../case-economy-add-claims-dialog/refund-item/reduction-calculator';

export class Calculations {
  reductionsCalculator = new ReductionsCalculator();
  calculateServiceOrderInvoiced(serviceOrder: ServiceOrder, applyExchangeRate: boolean = true): number {
    let invoiced = 0;

    for (const supplierInvoice of serviceOrder.supplierInvoices) {
      let serviceOrderShare = 0;
      supplierInvoice.items
        .filter(item => item.serviceOrderId === serviceOrder.serviceOrderId)
        .forEach(item => (serviceOrderShare += item.paidAmount));
      invoiced += applyExchangeRate ? serviceOrderShare * supplierInvoice.exchangeRate : serviceOrderShare;
    }
    for (const expenses of serviceOrder.supplierExpensesAppendixes) {
      if (expenses.amountInvoice) {
        invoiced += expenses.amountInvoice.amount;
      }
    }
    return invoiced;
  }

  calculateServiceOrderSavings(serviceOrder: ServiceOrder): number {
    let serviceOrderSavings = 0;
    for (const supplierInvoice of serviceOrder.supplierInvoices) {
      serviceOrderSavings += this.calculateInvoiceSavings(serviceOrder, supplierInvoice);
    }
    for (const expenses of serviceOrder.supplierExpensesAppendixes) {
      if (expenses.amountSavings) {
        serviceOrderSavings += expenses.amountSavings.amount;
      }
    }
    serviceOrder.refunds.forEach(refund => {
      if(refund.refundStatus === RefundStatus.APPROVED ||
        refund.refundStatus === RefundStatus.PAID) {
        serviceOrderSavings += refund.reduction;
      }
    });

    return serviceOrderSavings;
  }

  calculateInvoiceSavings(serviceOrder: ServiceOrder, supplierInvoice: SupplierInvoice): number {
    let invoiceSavings = 0;
    for (const item of supplierInvoice.items) {
      if (item.serviceOrderId === serviceOrder.serviceOrderId) {
        invoiceSavings += this.calculateItemSavings(supplierInvoice, item);
      }
    }
    return invoiceSavings;
  }

  calculateItemAmount(supplierInvoice: SupplierInvoice, item: SupplierInvoiceItem, applyExchangerate: boolean = true) {
    return applyExchangerate ? supplierInvoice.exchangeRate * item.paidAmount : item.paidAmount;
  }

  calculateItemSavings(supplierInvoice: SupplierInvoice, item: SupplierInvoiceItem): number {
    let itemSavings = 0;
    if (
      supplierInvoice.invoiceStatus === SupplierInvoiceStatus.invoiceReceived ||
      supplierInvoice.invoiceStatus === SupplierInvoiceStatus.ongoingCostControl ||
      supplierInvoice.invoiceStatus === SupplierInvoiceStatus.invoiceCorrected ||
      supplierInvoice.invoiceStatus === SupplierInvoiceStatus.awaitingAccounting
    ) {
      return 0;
    }
    for (const saving of item.savings) {
      if (saving.amount) {
        itemSavings += saving.amount;
      }
    }
    return itemSavings * supplierInvoice.exchangeRate;
  }

  calculateServiceOrderSettled(serviceOrder: ServiceOrder): number {
    let serviceOrderSettled = 0;
    serviceOrder.supplierInvoices.forEach(supplierInvoice => {
      serviceOrderSettled += this.calculateInvoiceSettled(serviceOrder, supplierInvoice);
    });

    serviceOrder.refunds.forEach(refund => {
      if(refund.refundStatus === RefundStatus.APPROVED ||
        refund.refundStatus === RefundStatus.PAID) {
        serviceOrderSettled += refund.exchangedNetAmount;
      }
    });

    for (const expenses of serviceOrder.supplierExpensesAppendixes) {
      if (expenses.amountSettled) {
        serviceOrderSettled += expenses.amountSettled.amount;
      }
    }
    return serviceOrderSettled;
  }

  calculateInvoiceSettled(serviceOrder: ServiceOrder, supplierInvoice: SupplierInvoice): number {
    let settled = 0;
    for (const item of supplierInvoice.items) {
      if (item.serviceOrderId === serviceOrder.serviceOrderId) {
        settled += this.calculateItemSettled(supplierInvoice, item);
      }
    }
    return settled;
  }

  calculateItemSettled(supplierInvoice: SupplierInvoice, item: SupplierInvoiceItem): number {
    if (
      item.paidAmount &&
      (supplierInvoice.invoiceStatus === SupplierInvoiceStatus.approved ||
        supplierInvoice.invoiceStatus === SupplierInvoiceStatus.supplierPaid)
    ) {
      let totalItemSavings = 0;
      item.savings
        .filter(savings => !savings.gross)
        .forEach(savings => {
          if (savings.amount) {
            totalItemSavings += savings.amount;
          }
        });
      return (item.paidAmount - totalItemSavings) * supplierInvoice.exchangeRate;
    }
    return 0;
  }
}
