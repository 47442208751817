import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SubCoffinUrn } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subCoffinUrn';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import { CoffinUrnHandlingTypeEnum } from '@secca/case/components/case-plans/case-plans/add-service-order/models/enums';
import { ServiceTypeEnum } from '@secca/shared/models/enums';

@Component({
  selector: 'app-sub-coffin-urn',
  templateUrl: './sub-coffin-urn.component.html',
  styleUrls: ['./sub-coffin-urn.component.scss'],
})
export class SubCoffinUrnComponent {
  @Input() required = true;
  @Input() subCoffinUrn: SubCoffinUrn;
  @Input() readOnlyMode: boolean;
  @Input() handlingDisabled: boolean;

  @Input() public set onlyCoffinAndUrn(onlyCoffinAndUrn: boolean) {
    this._onlyCoffinAndUrn = onlyCoffinAndUrn;
    if (onlyCoffinAndUrn) {
      this.types = [
        {
          id: CoffinUrnHandlingTypeEnum.COFFIN,
          name: this.translate.instant('coffin-urn-handling-type.COFFIN'),
        },
        {
          id: CoffinUrnHandlingTypeEnum.URN,
          name: this.translate.instant('coffin-urn-handling-type.URN'),
        },
      ];
    }
  }
  @Input() set availableCoffinUrn(availableCoffinUrn: CoffinUrnHandlingTypeEnum[]) {
    if (availableCoffinUrn != null) {
      this.types = [];
      availableCoffinUrn.forEach(a => {
        this.types.push(new DropdownDictionary(a, this.translate.instant('coffin-urn-handling-type.' + CoffinUrnHandlingTypeEnum[a])));
      });
    }
  }

  public get onlyCoffinAndUrn(): boolean {
    return this._onlyCoffinAndUrn;
  }

  @Input() set serviceOrderType(serviceOrderType: ServiceTypeEnum) {
    switch (serviceOrderType) {
      case ServiceTypeEnum.REGULAR_FLIGHT_NON_MEDICAL:
        this.types = [
          {
            id: CoffinUrnHandlingTypeEnum.COFFIN_SENT_HOME_BY_REGULAR_FLIGHT,
            name: this.translate.instant('coffin-urn-handling-type.COFFIN_SENT_HOME_BY_REGULAR_FLIGHT'),
          },
          {
            id: CoffinUrnHandlingTypeEnum.URN_SENT_HOME_BY_REGULAR_FLIGHT,
            name: this.translate.instant('coffin-urn-handling-type.URN_SENT_HOME_BY_REGULAR_FLIGHT'),
          },
        ];
        break;
      default:
        this.setAllCoffinUrnHandlingTypes();
        break;
    }
  }

  private _onlyCoffinAndUrn: boolean;
  types: DropdownDictionary[];

  constructor(private translate: TranslateService) {}

  setSelectedHandlingType(handlingType: CoffinUrnHandlingTypeEnum) {
    this.subCoffinUrn.handling = handlingType;
  }

  setAllCoffinUrnHandlingTypes() {
    this.types = [
      {
        id: CoffinUrnHandlingTypeEnum.COFFIN_SENT_HOME_BY_REGULAR_FLIGHT,
        name: this.translate.instant('coffin-urn-handling-type.COFFIN_SENT_HOME_BY_REGULAR_FLIGHT'),
      },
      {
        id: CoffinUrnHandlingTypeEnum.COFFIN_SENT_HOME_BY_GROUND_TRANSPORT,
        name: this.translate.instant('coffin-urn-handling-type.COFFIN_SENT_HOME_BY_GROUND_TRANSPORT'),
      },
      {
        id: CoffinUrnHandlingTypeEnum.URN_SENT_HOME_BY_REGULAR_FLIGHT,
        name: this.translate.instant('coffin-urn-handling-type.URN_SENT_HOME_BY_REGULAR_FLIGHT'),
      },
      {
        id: CoffinUrnHandlingTypeEnum.URN_SENT_HOME_BY_GROUND_TRANSPORT,
        name: this.translate.instant('coffin-urn-handling-type.URN_SENT_HOME_BY_GROUND_TRANSPORT'),
      },
      {
        id: CoffinUrnHandlingTypeEnum.URN_BROUGHT_HOME_BY_CO_TRAVELLER,
        name: this.translate.instant('coffin-urn-handling-type.URN_BROUGHT_HOME_BY_CO_TRAVELLER'),
      },
      {
        id: CoffinUrnHandlingTypeEnum.URN_BROUGHT_HOME_BY_ESCORT,
        name: this.translate.instant('coffin-urn-handling-type.URN_BROUGHT_HOME_BY_ESCORT'),
      },
      {
        id: CoffinUrnHandlingTypeEnum.LOCAL_BURIAL,
        name: this.translate.instant('coffin-urn-handling-type.LOCAL_BURIAL'),
      },
    ];
  }
}
