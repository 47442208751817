import {Component, Input, OnInit} from '@angular/core';
import {CaseHistoryEntry} from '@secca/shared/models/caseHistoryEntry';
import {ConsentStatusEnum} from '@secca/shared/models/enums';
import {CaseStakeholder} from '@secca/shared/models/caseStakeholder';
import {StakeholderService} from '@secca/core/services/stakeholder.service';
import {TranslateService} from '@ngx-translate/core';
import {AutoUnsubscribe} from '@secca/shared/decorators/auto-unsubscribe';

@AutoUnsubscribe
@Component({
  selector: 'app-case-summary-consent',
  templateUrl: './case-summary-consent.component.html',
  styleUrls: ['./case-summary-consent.component.scss']
})
export class CaseSummaryConsentComponent implements OnInit {
  private caseHistoryEntry: CaseHistoryEntry;
  private stakeholderslist: CaseStakeholder[];
  private legalGuardianlocal: CaseStakeholder;

  private consentUrl: string;
  private doctorUrl: string;

  constructor(private stakeholderService: StakeholderService,
              private translateService: TranslateService) {
  }

  ngOnInit() {
  }

  @Input()
  set stakeholders(stakeholders: CaseStakeholder[]) {
    if (stakeholders) {
      this.stakeholderslist = stakeholders;
      this.findLegalGuardianOnCase();
    }
  }

  @Input() set entry(entry: CaseHistoryEntry) {
    if (entry && entry.dataFieldChanges.length > 0) {
      this.caseHistoryEntry = entry;
    }
  }

  get entry(): CaseHistoryEntry {
    return this.caseHistoryEntry;
  }

  get stakeholderIcon() {
    const stakeholder = this.caseHistoryEntry.dataFieldChanges.filter(x => x.field === 'StakeholderId');
    if (this.stakeholderslist && stakeholder.length > 0) {
      this.legalGuardianlocal = this.stakeholderslist.find(sth => sth.id.toString() === stakeholder[0].newValue);
      return this.stakeholderService.getStakeholderIconFullPath(this.legalGuardianlocal.stakeholderType);
    }
  }

  get newConsentType(): string {
    const datafieldConsent = this.caseHistoryEntry.dataFieldChanges.filter(x => x.field === 'ConsentType');
    if (datafieldConsent.length > 0) {
      return datafieldConsent[0].newValue;
    }
  }

  get reasonText(): string {
    const datafieldReason = this.caseHistoryEntry.dataFieldChanges.filter(x => x.field === 'Reason');
    if (datafieldReason.length > 0) {
      return datafieldReason[0].newValue;
    }
  }

   get channelType(): string {
     const datafield = this.caseHistoryEntry.dataFieldChanges.filter(x => x.field === 'Channel');
     if (datafield.length > 0) {
       return datafield[0].newValue;
     }
   }

  get stakeholderName(): string {
    const stakeholder = this.caseHistoryEntry.dataFieldChanges.filter(x => x.field === 'StakeholderId');
    if (this.stakeholderslist && stakeholder.length > 0) {
      this.legalGuardianlocal = this.stakeholderslist.find(sth => sth.id.toString() === stakeholder[0].newValue);
      let stakeholderName = "";
      if(this.legalGuardianlocal.isCompany){
        stakeholderName = this.legalGuardianlocal.company.name;
      }else{
        stakeholderName = this.legalGuardianlocal.person.firstName + ' ' + this.legalGuardianlocal.person.surname;
      }
      return stakeholderName;
    }
  }

  get urlIcon() {
    return '../../../../assets/icons/consent/New Window Black.svg';
  }

  public openACUrl() {
    if (this.consentUrl) {
      window.open(this.consentUrl, '_blank');
    }
  }

  public openDoctorUrl() {
    if (this.doctorUrl) {
      window.open(this.doctorUrl, '_blank');
    }
  }

  getExplainText(consentStatus: string): string {
    switch (consentStatus) {
      case ConsentStatusEnum.CONSENT_ACCEPTED_MAN.toString():
        return this.translateService.instant('CONSENT_RECEIVED_MAN_EXPLAIN');
      case ConsentStatusEnum.CONSENT_REJECTED.toString():
        return this.translateService.instant('CONSENT_REJECTED_EXPLAIN');
      case ConsentStatusEnum.ORAL.toString():
        return this.translateService.instant('CONSENT_ORAL_EXPLAIN');
      case ConsentStatusEnum.CONSENT_NOT_POSSIBLE.toString():
        return this.translateService.instant('CONSENT_NOT_POSSIBLE_EXPLAIN');
      case ConsentStatusEnum.RESEND_MANUALLY.toString():
        return this.translateService.instant('RESEND_MANUALLY');
      case ConsentStatusEnum.REQUESTED_MANUALLY.toString():
        return this.translateService.instant('CONSENT_REQUESTED_MANUALLY_EXPLAIN');
        case ConsentStatusEnum.REQUESTED_MANUALLY_AGAIN.toString():
        return this.translateService.instant('CONSENT_REQUESTED_MANUALLY_EXPLAIN');
      case ConsentStatusEnum.REQUESTED.toString():
        return this.translateService.instant('CONSENT_REQUESTED_DIGITALLY_EXPLAIN');
      case ConsentStatusEnum.CONSENT_ACCEPTED.toString():
        return this.translateService.instant('CONSENT_ACCEPTED');
      case ConsentStatusEnum.CONSENT_ACCEPTED_WEB.toString():
        return this.translateService.instant('CONSENT_RECEIVED_WEB');
    }
    return '';
  }

  getBackgroundClass(consentStatus: string): string {

    switch (consentStatus) {
      case ConsentStatusEnum.CONSENT_REQUEST_NOT_SENT.toString():
      case ConsentStatusEnum.CONSENT_REJECTED.toString():
      case ConsentStatusEnum.CONSENT_NOT_POSSIBLE.toString():
      case ConsentStatusEnum.AUTO_FAILED.toString():
      case ConsentStatusEnum.UNKNOWN.toString():
        return 'consent-request-background-red';
      case ConsentStatusEnum.CONSENT_ACCEPTED_MAN.toString():
      case ConsentStatusEnum.CONSENT_ACCEPTED.toString():
      case ConsentStatusEnum.CONSENT_ACCEPTED_WEB.toString():
        return 'consent-request-background-green';
      case ConsentStatusEnum.REQUESTED.toString():
      case ConsentStatusEnum.REQUESTED_MANUALLY.toString():
      case ConsentStatusEnum.REQUESTED_MANUALLY_AGAIN.toString():
      case ConsentStatusEnum.ORAL.toString():
      case ConsentStatusEnum.RESEND_MANUALLY.toString():
      case ConsentStatusEnum.MANUALLY.toString():
        return 'consent-request-background-yellow';
    }
    return;
  }

  private findLegalGuardianOnCase() {
    if (this.stakeholderslist != null && this.entry != null) {
      this.legalGuardianlocal = this.stakeholderslist.find(sth => this.findStakholderPropertyLegalGuardian(sth.properties));
    }
  }

  private findStakholderPropertyLegalGuardian(sth: string[]) {
    const legal = sth.find(prop => prop.toString() === 'LEGAL_GUARDIAN');
    return (!!legal);
  }

  get showViewUrls() {
    this.consentUrl = this.caseHistoryEntry.dataFieldChanges.find(x => x.field === 'acUrl')?.newValue;
    this.doctorUrl = this.caseHistoryEntry.dataFieldChanges.find(x => x.field === 'doctorUrl')?.newValue;
    return this.consentUrl || this.doctorUrl;
  }

  get showReasonText() {
    const re = /{}/gi;
    return this.reasonText && this.reasonText.search(re) === -1;
  }

  get isConsentWeb(): boolean {
    return this.newConsentType === ConsentStatusEnum.CONSENT_ACCEPTED_WEB.toString();
  }
}
