<div class="common-drop-down drop-down-auto-complete">
  <span class="name">{{name}}</span>
  <ng-select #select (blur)="onSaveModel()" [typeahead]="input" (focus)="onSaveState()" [class.required]="required && !selectedItemId" class="custom" [clearable]="false"
    [(ngModel)]="selectedItemId" placeholder="Type at least 2 characters" (change)="onChange()" [items]="items | async" [appendTo]="appendTo" [selectOnTab]="true">
    <ng-template ng-option-tmp let-item="item" let-index="index">
      <div class="row" *ngIf="autoCompleteTypeEnum === AutoCompleteTypeEnum.Users">
        <div class="col-2">
          <app-user-picture [imageURL]="item.picture" [active]="item.active"></app-user-picture>
        </div>
        <div class="col-10">
          <div class="person-name-shorcut">{{item.fullUserName}} ({{item.initials}})</div>
          <div class="person-role-title">{{item.team.teamName | titlecase}}</div>
        </div>
      </div>
      <div class="container-fluid" *ngIf="autoCompleteTypeEnum === AutoCompleteTypeEnum.UsersAndTeamsExceptFromOtherDepartment || autoCompleteTypeEnum === AutoCompleteTypeEnum.UsersAndTeamsExceptFromOtherAndCostControlDepartment">
        <div class="row">
          <ng-template [ngIf]="!item.isTeam">
            <div class="col fixed-width-40">
              <app-user-picture [imageURL]="item.picture" [active]="item.active"></app-user-picture>
            </div>
            <div class="col person-column-content-wrap">
              <div class="person-name-shorcut">{{item.fullUserName}} ({{item.initials}})</div>
              <div class="person-role-title">{{item.team.teamName | titlecase}}</div>
            </div>
          </ng-template>
          <ng-template [ngIf]="item.isTeam">
            <div class="col fixed-width-40">
              <app-team-picture></app-team-picture>
            </div>
            <div class="col column-content">
              <div class="team-name">{{item.teamName}}</div>
            </div>
          </ng-template>
        </div>
      </div>
    </ng-template>
  </ng-select>
</div>
