import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
import { Location } from './location';
import * as moment from 'moment';
import { DaySerializer } from './day-serializer';
import { Icd10Code, Icd10CodeAdapter } from '@secca/shared/models/icd10Code';
import { TransportAssistanceType } from './enums';

export class CaseIncident {
  id: number;
  causeLevel1Code: string;
  causeLevel2Code: string;
  causeLevel3Code: string;
  causeLevel1Text: string;
  causeLevel2Text: string;
  causeLevel3Text: string;
  incidentDate: moment.Moment;
  firstMedicalVisit: moment.Moment;
  incidentLocation: Location;
  currentLocation: Location;
  description: string;
  diagnosis: Icd10Code;
  symptoms: Icd10Code[];
  medicalDiagnosis: Icd10Code;
  medicalSymptoms: Icd10Code[];
  medicalPreAssessmentDiagnosis: Icd10Code;
  medicalPreAssessmentSymptoms: Icd10Code[];
  medicalPreAssessmentSymptomsReported: Icd10Code[];
  incidentEvent: string;
  winterSport: boolean;
  transportAssistance: TransportAssistanceType

  public constructor(init?: Partial<CaseIncident>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class CaseIncidentAdapter implements Adapter<CaseIncident> {
  adapt(item: any): CaseIncident {
    return new CaseIncident({
      id: item.id,
      causeLevel1Code: item.causeLevel1Code,
      causeLevel2Code: item.causeLevel2Code,
      causeLevel3Code: item.causeLevel3Code,
      causeLevel1Text: item.causeLevel1Text,
      causeLevel2Text: item.causeLevel2Text,
      causeLevel3Text: item.causeLevel3Text,
      incidentDate: DaySerializer.deserialize(item.incidentDate),
      firstMedicalVisit: DaySerializer.deserialize(item.firstMedicalVisit),
      incidentLocation: item.incidentLocation,
      currentLocation: item.currentLocation,
      description: item.description,
      incidentEvent: item.incidentEvent,
      winterSport: item.winterSport,
      transportAssistance: item.transportAssistance,
      diagnosis: !!item.diagnosis ?
        new Icd10CodeAdapter().adapt(item.diagnosis, true) : null,
      symptoms: !!item.symptoms ?
        new Icd10CodeAdapter().adaptItems(item.symptoms, true) : [],
      medicalDiagnosis: !!item.medicalDiagnosis ?
        new Icd10CodeAdapter().adapt(item.medicalDiagnosis, true) : null,
      medicalSymptoms: !!item.medicalSymptoms ?
        new Icd10CodeAdapter().adaptItems(item.medicalSymptoms, true) : [],
        medicalPreAssessmentDiagnosis: !!item.medicalPreAssessmentDiagnosis ?
        new Icd10CodeAdapter().adapt(item.medicalPreAssessmentDiagnosis, true) : null,
        medicalPreAssessmentSymptoms: !!item.medicalPreAssessmentSymptoms ?
        new Icd10CodeAdapter().adaptItems(item.medicalPreAssessmentSymptoms, true) : [],
        medicalPreAssessmentSymptomsReported: !!item.medicalPreAssessmentSymptomsReported ?
        new Icd10CodeAdapter().adaptItems(item.medicalPreAssessmentSymptomsReported, true) : [],
    });
  }
}

export class IncidentDto {
  id: number;
  businessArea: string;
  causeLevel1Code: string;
  causeLevel2Code: string;
  causeLevel3Code: string;
  incidentDate: moment.Moment;
  firstMedicalVisit: moment.Moment;
  currentLocation: Location;
  incidentLocation: Location;
  description: string;
  diagnosis: Icd10Code;
  symptoms: Icd10Code[];
  medicalDiagnosis: Icd10Code;
  medicalSymptoms: Icd10Code[];
  medicalPreAssessmentDiagnosis: Icd10Code;
  medicalPreAssessmentSymptoms: Icd10Code[];
  incidentEvent: string;
  winterSport: boolean;
  transportAssistance: TransportAssistanceType

  public constructor(init?: Partial<IncidentDto>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class IncidentDtoWrapper {
  wrap(item: CaseIncident): IncidentDto {
    return new IncidentDto({
      id: +item.id,
      causeLevel1Code: item.causeLevel1Code,
      causeLevel2Code: item.causeLevel2Code,
      causeLevel3Code: item.causeLevel3Code,
      firstMedicalVisit: item.firstMedicalVisit,
      incidentDate: item.incidentDate,
      currentLocation: item.currentLocation,
      incidentLocation: item.incidentLocation,
      description: item.description,
      incidentEvent: item.incidentEvent,
      winterSport: item.winterSport,
      diagnosis: !!item.diagnosis ?
        new Icd10CodeAdapter().adapt(item.diagnosis, null) : null,
      symptoms: !!item.symptoms ?
        new Icd10CodeAdapter().adaptItems(item.symptoms, null) : null,
      medicalDiagnosis: !!item.medicalDiagnosis ?
        new Icd10CodeAdapter().adapt(item.medicalDiagnosis, null) : null,
      medicalSymptoms: !!item.medicalSymptoms ?
        new Icd10CodeAdapter().adaptItems(item.medicalSymptoms, null) : null,
      medicalPreAssessmentDiagnosis: !!item.medicalPreAssessmentDiagnosis ?
        new Icd10CodeAdapter().adapt(item.medicalPreAssessmentDiagnosis, null) : null,
      medicalPreAssessmentSymptoms: !!item.medicalPreAssessmentSymptoms ?
        new Icd10CodeAdapter().adaptItems(item.medicalPreAssessmentSymptoms, null) : null,
      transportAssistance: item.transportAssistance,
    });
  }
}
