import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { ServiceOrderTypeState } from './serviceOrderTypeState';
import { Input, Directive, SimpleChanges, OnChanges } from '@angular/core';
import { SelectedTab } from './enums';
import { ServiceTypeEnum, StatusTypes } from '@secca/shared/models/enums';
import { ServiceOrderType } from '@secca/shared/models/service-order-type';

@Directive()
export class ServiceTypeBase implements OnChanges {
  @Input() serviceOrderTypeState: ServiceOrderTypeState;
  @Input() status: StatusTypes;
  @Input() serviceOrderType: ServiceOrderType;
  @Input() caseLocked: boolean;

  isSupplierRequiredOnConfirmationTab: boolean;

  activeTab: any = SelectedTab.ExpectationTab ;


  ngOnChanges(changes: SimpleChanges) {
    if (changes.status) {
     this.activeTab = this.serviceOrderTypeState.showConfirmationTab ? SelectedTab.ConfirmationTab : SelectedTab.ExpectationTab;
    }
  }

  closeConfirmationTab(e) {
    e.preventDefault();
    if (!this.serviceOrderTypeState.isConfirmationTabSaved && this.serviceOrderTypeState.canConfirmationTabBeClosed) {
      this.serviceOrderTypeState.confirmationTabHasBeenClosed.emit();
      this.serviceOrderTypeState.selectedTab = SelectedTab.ExpectationTab;
    }
  }

  get SelectedTab() {
    return SelectedTab;
  }

  tabChange($event: NgbNavChangeEvent) {
    this.serviceOrderTypeState.selectedTab = +$event.nextId as SelectedTab;
    if (this.serviceOrderTypeState.selectedTab === SelectedTab.ConfirmationTab) {
      if (this.isSupplierRequiredOnConfirmationTab) {
        this.serviceOrderTypeState.isSupplierRequired = true;
      }
    } else {
      if (this.isSupplierRequiredOnConfirmationTab) {
        this.serviceOrderTypeState.isSupplierRequired = false;
      }
    }
  }

  get ServiceTypeEnum() {
    return ServiceTypeEnum;
  }
}
