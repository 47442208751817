import {
  Directive,
  ElementRef,
  Inject,
  Input,
  OnInit,
  Optional,
  Renderer2,
} from '@angular/core';
import { NgModel } from '@angular/forms';
import { DOCUMENT } from '@angular/common';

type Placement = 'top-right' | 'bottom-right';

/**
 * Add a counter to an input or textarea field. Th counter is placed outside
 * of the input field, controlled by the placement value.
 * The max number of input allowed characters is taken from the maxlength
 * attribute.
 * For the positioning of the counter to work, the inpout field must be placed
 * inside a <div> element with style "position: relative".
 *
 * Eg:
 *
 * <div class="position-relative">
 *   <textarea appCharCounter='top-right' maxlength="2000" [(ngModel)]=".." ...></textarea>
 * </div>
 */
@Directive({
   selector: '[appCharCount][ngModel]',
})
export class CharCountDirective implements OnInit {
  private window: Window;
  @Input()
  appCharCount: Placement;

  constructor(@Inject(DOCUMENT) private document: Document,
              private _el: ElementRef,
              private _renderer: Renderer2,
              @Optional() private ngModel: NgModel) {
    this.window = this.document.defaultView;
  }

  /**
   * Implements the OnInit Angular2 lifecycle hook.
   */
  ngOnInit(): any {
    const limit = this._el.nativeElement.getAttribute('maxlength');
    if (!limit) {
      throw new Error('appCharCount: maxlength must be specified on the input element');
    }
    const parent = this._renderer.parentNode(this._el.nativeElement);
    if (window.getComputedStyle(parent).position !== 'relative') {
      throw new Error('appCharCount: the parent element must have style.position = "relative');
    }
    const spanElement = this._renderer.createElement('span');
    this._renderer.removeClass(spanElement, 'char-count-warning');
    this._renderer.setStyle(spanElement, 'position', 'absolute');
    switch (this.appCharCount || 'top-right') {
      case 'top-right':
        this._renderer.setStyle(spanElement, 'top', '-1.2rem');
        this._renderer.setStyle(spanElement, 'right', '0.8em');
        break;
      case 'bottom-right':
        this._renderer.setStyle(spanElement, 'bottom', '-1rem');
        this._renderer.setStyle(spanElement, 'right', '0.8em');
        break;
    }

    // insert the span after the input/textarea element
    const sibling = this._renderer.nextSibling(this._el.nativeElement);
    if (sibling === null) {
      this._renderer.appendChild(parent, spanElement);
    } else {
      this._renderer.insertBefore(parent, spanElement, sibling);
    }
    this.ngModel.valueChanges.subscribe(value => {
      const safeValue = value || '';
      spanElement.innerText = safeValue.length + '/' + limit;
      if (limit - safeValue.length <= 50) {
        this._renderer.addClass(spanElement, 'char-count-warning');
      } else {
        this._renderer.removeClass(spanElement, 'char-count-warning');
      }
    });
  }
}
