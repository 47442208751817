<ul ngbNav #nav="ngbNav" class="service-order-type-tabset"
  [(activeId)]="activeTab" class="nav-tabs">
  <li id="{{SelectedTab.ExpectationTab}}" [ngbNavItem]="SelectedTab.ExpectationTab">
    <a ngbNavLink>{{"service-type-expectation" | translate}}</a>
    <ng-template ngbNavContent>
      <div [appDisableInput]="caseLocked || serviceOrderTypeState.isLockedByCoordinationTransport" class="caselock-no-events">
      <div class="sub-component-placeholder">
        <div class="sub-component-container">
          <div class="sub-title">{{"regular-flight-medical-treating-doctor" | translate}}</div>
          <div class="sub-content-container">
            <app-sub-stakeholders [stakeholders]="stakeholders"
                                  [subStakeholders]="regularFlightMedical.treatingDoctorStakeholders"
                                  [caseId]="caseId"
                                  [medicalStaffOnly]="true"
                                  [subStakeholdersConfig]="subStakeholdersConfigTreatingDoctor"
                                  [showInvalidBackground]="treatingDoctorSelected()"
                                  [hideNip]="true">
            </app-sub-stakeholders>
          </div>
        </div>
      </div>
      <div class="sub-component-placeholder">
        <app-sub-repatriation-form-medical
          [caseId]="caseId"
          [stakeholders]="companyStakeholders"
          [subRepatriationFormMedical]="regularFlightMedical.repatriationFormMedical"
          [subRepatriationDetails]="regularFlightMedical.repatriationDetails"
          (intensivePatient)="isIntensivePatient($event)">
        </app-sub-repatriation-form-medical>
      </div>
      <div class="sub-component-placeholder">
        <app-sub-travellers
          [preselectUser]="true"
          [hideCabinClass]="true"
          [hideFlightInRefs]="true"
          [hideAddFlightLeg]="true"
          [caseId]="caseId"
          [(travellers)]="regularFlightMedical.expectedSubTravellers"
          [stakeholders]="stakeholders"
          [(subStakeholders)]="regularFlightMedical.expectedSubStakeholders"
          [subStakeholdersConfig]="subStakeholdersConfigTravellers"
          [departureDateRequired]="true"
          [arrivalAtRequired]="true"
          [departureFromRequired]="true"
          (endUserHasDateOfBirthEmit)="updateEndUserHasDateOfBirth($event)"
          [serviceOrderType]="serviceOrderType"
          >
        </app-sub-travellers>
      </div>
      <div class="sub-component-placeholder">
        <app-sub-medical-escort (newMedicalEscortAdded)="newMedicalEscortAddedFromExpectation()" [caseId]="caseId"
          [medicalEscortIsUnique]="regularFlightMedical.isExpectationMedicalEscortStakeholderUnique()"
          [(medicalEscorts)]="regularFlightMedical.expectedMedicalEscorts" [incidentId]="incidentId"
          [stakeholders]="stakeholders"
                                [travellers]="regularFlightMedical.expectedSubTravellers"
                                [serviceOrderType]="serviceOrderType">
        </app-sub-medical-escort>
      </div>
      <div class="sub-component-placeholder">
        <app-sub-remarks-to-supplier [(subRemarksToSupplier)]="regularFlightMedical.remarksToSupplier"></app-sub-remarks-to-supplier>
      </div>
      <div class="sub-component-placeholder">
        <app-GOP [caseId]="caseId"
          [serviceOrderExtension]="regularFlightMedical"
          [serviceOrderTypeState]="serviceOrderTypeState"
          [supplier]="supplier"
          [stakeholders]="stakeholders"
        ></app-GOP>
      </div>
      </div>
    </ng-template>
  </li>
  <ng-container *ngIf="iccServiceOrder">
    <li *ngFor='let coordinationTransportServiceOrderExtension of coordinationServiceOrderExtensions' id="coordinationTransportServiceOrderExtension.serviceOrderId" [ngbNavItem]="coordinationTransportServiceOrderExtension.serviceOrderId" >
      <a style="font-size: 12px; line-height: 12px; padding-top: 3px;" ngbNavLink>{{linkedEndUserCaseDetail?.caseNumber}}<br/>{{ linkedEndUser?.person.firstName }} {{ linkedEndUser?.person.surname }}</a>
      <ng-template ngbNavContent>
        <app-end-user-linked-case-component
        *ngIf="linkedEndUserCaseDetail"
          [endUserDisplayReadOnly]='true'
          [caseId]='caseId'
          [endUserCaseDetail]='linkedEndUserCaseDetail'
          [coordinationTransportServiceOrderExtension]='coordinationTransportServiceOrderExtension'
          [subStakeholdersConfig]="linkedEndUserSubStakeholdersConfig"
          [serviceOrderType]='serviceOrderType'
          [status]='status'
        >
        </app-end-user-linked-case-component>
      </ng-template>
    </li>
  </ng-container>
  <li [id]="SelectedTab.ConfirmationTab" *ngIf="serviceOrderTypeState.showConfirmationTab && !iccServiceOrder" [ngbNavItem]="SelectedTab.ConfirmationTab">
    <ng-template ngbNavContent>
      <div [appDisableInput]="caseLocked || serviceOrderTypeState.isLockedByCoordinationTransport" class="caselock-no-events">
      <div class="sub-component-placeholder">
        <app-sub-commitment-type [serviceOrderType]="serviceOrderType"
          [(commitmentType)]="regularFlightMedical.commitmentType"  [status]="status" [caseId]="caseId">
        </app-sub-commitment-type>
      </div>
      <div class="sub-component-placeholder">
        <app-sub-travellers [hideCabinClass]="true"
                            [caseId]="caseId"
                            [travellers]="regularFlightMedical.confirmationSubTravellers"
                            [stakeholders]="stakeholders"
                            [subStakeholders]="regularFlightMedical.confirmationSubStakeholders"
                            [subStakeholdersConfig]="subStakeholdersConfigTravellers"
                            [arrivalDateRequired]="true" [departureDateRequired]="true"
                            [arrivalAtRequired]="true" [departureFromRequired]="true"
                            [flightNoRequired]="true" [agencyReferenceRequired]="true"
                            [serviceOrderType]="serviceOrderType">
        </app-sub-travellers>
      </div>
      <div class="sub-component-placeholder">
        <app-sub-medical-escort [caseId]="caseId" [isConfirmationTab]="true"
          [medicalEscortIsUnique]="regularFlightMedical.isConfirmationMedicalEscortStakeholderUnique()"
          [(medicalEscorts)]="regularFlightMedical.confirmationMedicalEscorts"
          [stakeholders]="stakeholders"
                                [travellers]="regularFlightMedical.expectedSubTravellers"
                                [serviceOrderType]="serviceOrderType">
        </app-sub-medical-escort>
      </div>
      <div class="sub-component-placeholder">
        <app-sub-remarks-rules-and-fees [subRemarksRulesAndFees]="regularFlightMedical.remarksRulesAndFees">
        </app-sub-remarks-rules-and-fees>
      </div>
      <div class="sub-component-placeholder">
        <app-send-itinerary [regularFlightMedicalSaveButtonDisabled]="!isValid()" [caseId]="caseId"
          [serviceOrderTypeState]="serviceOrderTypeState"></app-send-itinerary>
      </div>
      </div>
    </ng-template>
    <a ngbNavLink>{{"service-type-confirmation" | translate}}<div class="close-tab-x" (click)="closeConfirmationTab($event)"
        *ngIf="!serviceOrderTypeState.isConfirmationTabSaved"><img src="/assets/icons/Close Black.svg"></div>
    </a>
  </li>
  <li *ngIf="regularFlightMedical?.repatriationFormMedical?.repatriationInstructionId" [ngbNavItem]="SelectedTab.DetailsTab">
      <a ngbNavLink>{{"service-type-details" | translate}}
    </a>
    <ng-template ngbNavContent>
      <div [appDisableInput]="caseLocked || serviceOrderTypeState.isLockedByCoordinationTransport" class="caselock-no-events">
      <div class="sub-component-placeholder">
        <app-sub-medif-status (medifStatusTypeChanged)="medifStatusTypeChanged($event)"
          [(medifStatusType)]="regularFlightMedical.medifStatusType">
        </app-sub-medif-status>
      </div>
      <div class="sub-component-placeholder">
        <app-sub-repatriation-details [medicalAssessmentId]="regularFlightMedical?.repatriationFormMedical?.medicalAssessmentId"
                                      [subRepatriationDetails]="regularFlightMedical.repatriationDetails"
                                      [caseId]="caseId"
                                      [hideFormIntensiveCarePatient]="true"
                                      [serviceOrderType]="ServiceTypeEnum.REGULAR_FLIGHT_MEDICAL">
        </app-sub-repatriation-details>
      </div>
      </div>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="nav"></div>
