<div class="common-drop-down-arrow global-board-dropdown-height">
  <span class="name">{{name}}</span>
  <ng-select
    (blur)="onSaveModel()"
    (focus)="onSaveState()"
    [clearable]="false"
    [ngModel]="selectedItemId"
    [ngClass]="'custom'"
    [multiple]="true"
    (change)="onChange($event)"
    (focus)="onOpen()"
    (close)="close()"
    [items]="items"
    [disabled]="disableUserDropdown"
    [clearable]="false"
    bindLabel="fullNameAndInitialt"
    dropdownPosition="auto"
    >
    <ng-template ng-multi-label-tmp >
      <div class="multi-selected-label">
        {{ displayMultiLabel() }}
      </div>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" >
      <div class="imageClass float-start me-1" (click)="getSelectedItem(item)" >
        <app-team-picture *ngIf="!!item.isTeam"></app-team-picture>
        <app-user-picture *ngIf="!item.isTeam" [imageURL]="item.picture" [active]="item.active"></app-user-picture>
      </div>
      <div [class.pt-2]="item.isTeam" (click)="getSelectedItem(item)" >
        {{ item.fullName }} <span *ngIf="item.initials !== '' && !item.isTeam"> ({{ item.initials }})</span>
      </div>
      <div *ngIf="item.userTeam && !item.isTeam" class="small-font" (click)="getSelectedItem(item)">{{ item.userTeam?.teamName }} </div>
    </ng-template>
  </ng-select>
</div>
