import { BoardIncidentEventCase } from './board-incident-event-case.model';
export class CaseIncidentEventFilterResponse {
  totalNumber: number;
  incidentEventCaseBoardViewModelDto: BoardIncidentEventCase[];

  public constructor(init?: Partial<CaseIncidentEventFilterResponse>) {
    Object.assign(this, init);
  }
}

