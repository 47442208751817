
import { Injectable } from '@angular/core';
import { CaseEconomyTabEnum } from 'src/app/shared/enums/routing/case-economy-tab-enum';

@Injectable({
  providedIn: 'root'
})
export class CaseEconomyTabService {
  private selectedCaseEconomyTab = new Map<number, CaseEconomyTabEnum>();

  set(caseId: number, tab: CaseEconomyTabEnum) {
    this.selectedCaseEconomyTab.set(caseId, tab);
  }

  getSelectedTab(caseId: number): CaseEconomyTabEnum {
    return this.selectedCaseEconomyTab.get(caseId);
  }
}
