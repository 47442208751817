import { AfterViewInit, Component, Inject, ViewChild } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { DialogBoundryService } from "@secca/core/services/dialog-boundry.service";
import { DialogHelperUtilService } from "@secca/core/services/dialog-helper-util.service";
import { ShortcutService } from "@secca/core/services/shortcut.service";
import { DialogStateAction, MinimizableNotifData } from "@secca/core/state-services/dialog-state.service";
import { DialogKeyboardEnabledDirective } from "@secca/shared/directives/dialog-keyboard-enabled.directive";
import { DialogMinimizableDirective } from "@secca/shared/directives/dialog-minimizable.directive";
import { MinimizableDialogType } from "@secca/shared/enums/minimizable-dialog-type-enum";
import { AdditionalInfo, IAdditionalInfo } from "@secca/shared/interfaces/additional-info";
import { IKeyboardEnabled } from "@secca/shared/interfaces/keyboard-enabled";
import { IMinimizable } from "@secca/shared/interfaces/minimizable";
import { Subscription } from "rxjs";
import { CaseNewComponent } from "../case-new.component";
import { CaseNewDialogData } from "./case-new-dialog-data.component";

@Component({
    selector: 'app-case-new-dialog',
    templateUrl: './case-new-dialog.component.html',
    styleUrls: ['./case-new-dialog.component.scss'],
  })
  export class CaseNewDialogComponent implements AfterViewInit, IAdditionalInfo, IMinimizable, IKeyboardEnabled {
    @ViewChild(CaseNewComponent) wrappedInstance: CaseNewComponent;
    @ViewChild(DialogMinimizableDirective) minimizedDirective: DialogMinimizableDirective;

    data: CaseNewDialogData;
    dialogStateChangeSubscr: Subscription;

    constructor(private dialogRef: MatDialogRef<CaseNewDialogComponent>,
                @Inject(MAT_DIALOG_DATA) data: CaseNewDialogData,
                private dialogHelperUtilService: DialogHelperUtilService,
                private shortcutService: ShortcutService,
                public dialogBoundryService: DialogBoundryService) {
              
      this.data = data;

      if ( this.data.disableShortcuts ) {
        this.shortcutService.disableShortCuts();
      }
    }

    ngAfterViewInit() {
      this.dialogStateChangeSubscr = this.minimizedDirective.dialogStateChange.subscribe((data: MinimizableNotifData) => {
        if ( data.action === DialogStateAction.MAXIMIZE ) {
          this.wrappedInstance.initializePhoneCall();
        }
      });
    }

    close() {
      if ( this.data.disableShortcuts ) {
        this.shortcutService.enableShortCuts();
      }
      this.dialogHelperUtilService.close(this.dialogRef, this.getAdditionalInfo()?.caseId);
    }
    
    keyboardClose(): void {
      this.wrappedInstance.closeModal();
    }

    minimize() {
      this.minimizedDirective.minimize();
    }

    getMinimizeType(): MinimizableDialogType {
      return this.minimizedDirective.appDialogMinimizable;
    }

    getAdditionalInfo(): AdditionalInfo {

      return {
        caseId: null,
        hoverTitle: 'New Case',
        hoverText: ''
      };
    }

    get enabledKeys(): string[] {
     return this.data.disableShortcuts ? [DialogKeyboardEnabledDirective.ESCAPE_KEY] : null; 
    }

    get DialogKeyboardEnableableDirective() {
      return DialogKeyboardEnabledDirective;
    }

    get MinimizableDialogType() {
      return MinimizableDialogType;
    }
}
