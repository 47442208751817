import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { Moment } from 'moment-timezone';
export type UTCOffset = number | 'local' | 'utc' | 'secca';
export type SECCA_TIMEZONES = string | 'local' | 'utc' | 'secca';

@Injectable({
  providedIn: 'root',
})
export class MomentHelperService {

  public static resolveTimeZone(timeZone: SECCA_TIMEZONES): string {
    if (!timeZone || timeZone === 'utc') {
      return "UTC";
    }
    if (timeZone === 'local') {
      return moment.tz.guess(); 
    }
    if (timeZone === 'secca') {
     return this.getSeccaTimeZoneId(); 
    }
    return timeZone;
  }

  public static getDaysRangeBothIncl(dt1: moment.Moment, dt2: moment.Moment) {
    if (dt1 && dt2) {
      return dt2.diff(dt1, 'days') + 1;
    } else {
      return null;
    }
  }

  public static getSeccaTime() {
    return moment().tz("Europe/Copenhagen")
  }

  public static getSeccaTimeEndOfDay() {
    return moment.utc().tz("Europe/Copenhagen").endOf('day').utc()
  }

  public static getSeccaTimeZoneId() {
    return "Europe/Copenhagen";
  }

  /*
   * Returns a moment representing the end of day for the caseworker in UTC.
   */
  public static endOfCaseWorkerDay(dateMoment: Moment) {
    if (dateMoment == null) { return null; }
    return dateMoment.clone().local().endOf('day').utc();
  }

  public static startOfCaseWorkerDay(dateMoment: Moment) {
    if (dateMoment == null) { return null; }
    return dateMoment.clone().local().startOf('day').utc();
  }

  /*
   * Sets the timezone of the moment and returns the time in UTC.
   * Example 23:59 UTC in "Europe/Copenhagen" timezone becomes 21:59 UTC
   * because 23:59 in Copenhagen is 21 in UTC (summertime calculation)
   */
  public static momentAsUtc(aMoment: Moment, timeZoneId: string) {
    aMoment.tz(timeZoneId, true).utc();
  }
}
