
import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnChanges {
  @Input() totalNumber: number;
  @Input() pageSize: number;
  @Input() currentPageIndex: number;
  @Output() pagingChangedEvent: EventEmitter<number> = new EventEmitter()
  totalPages : number;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    this.totalPages = Math.ceil(this.totalNumber/ this.pageSize); 
  }

  showPageClick(newPageIndex: number) {
    this.currentPageIndex = newPageIndex;
    this.pagingChangedEvent.emit(this.currentPageIndex);
  }

  showNext(): boolean {
    if (this.totalNumber == null || this.pageSize == null || this.currentPageIndex == null) {
      return false;
    }
    return (this.currentPageIndex + 1) * this.pageSize < this.totalNumber;
  }

  showPrevious(): boolean {
    return this.currentPageIndex > 0;
  }
}
