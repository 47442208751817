import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DictionaryService} from '@secca/core/services/dictionary.service';
import {TranslateService} from '@ngx-translate/core';
import {CaseTypesService} from '@secca/core/services/case-type.service';
import {DropdownDictionary} from '@secca/shared/models/dropdownDictionary';
import { DropdownWithIconDisableDictionary } from '@secca/shared/models/dropdownWithIconDisableDictionary';
import { CASE_TYPE_CODES} from '@secca/shared/models/case-type';

import { CaseType } from '@secca/shared/models/case-type';
import * as _ from 'lodash';
@Component({
  selector: 'app-case-type-modal',
  templateUrl: './case-type-modal.component.html',
  styleUrls: ['./case-type-modal.component.scss']
})
export class CaseTypeModalComponent implements OnInit {

  @ViewChild('content') private template: TemplateRef<any>;

  @Input()
  caseId: string;

  @Input()
  caseTypeTranslated: string;

  @Input()
  caseTypeCode: string;
  
  @Input()
  caseValidated: boolean;
  
  availableCaseTypes: DropdownDictionary[];
  theCaseTypeCode: string;

  @Output()
  caseTypeChanged: EventEmitter<void> = new EventEmitter<void>();
  allCaseTypes: CaseType[];

  ngOnInit() {

  }

  constructor(
    private modalService: NgbModal,
    private dictionaryService: DictionaryService,
    private translate: TranslateService,
    private caseTypesService: CaseTypesService
  ) {}

  save(){
    this.caseTypesService.saveCaseType(this.caseId, this.theCaseTypeCode).subscribe(result => {
      this.caseTypeCode = this.theCaseTypeCode;
      this.caseTypeTranslated = this.availableCaseTypes.find(caseTypeDD => caseTypeDD.id === this.theCaseTypeCode)?.name;
      this.notify();
      this.close();
    });

  }

  open() {
    this.theCaseTypeCode = this.caseTypeCode;
    this.caseTypesService.getCaseTypesForCase(this.caseId).subscribe(
      result => {
        this.allCaseTypes = result;
        let currentCaseType = this.allCaseTypes.find(ct => ct.code == this.caseTypeCode);
        this.availableCaseTypes = this.allCaseTypes.map((caseType) => {
          let disabled = false;
          if (this.caseValidated) {
            disabled = this.shouldBeDisabled(currentCaseType, caseType);
          }
          let disableHoverText = null;
          if (disabled) {
            disableHoverText = "case-type-case-type-disabled-text";
          }
          
          return new DropdownWithIconDisableDictionary(caseType.code, 
                                                caseType.name, 
                                                null, 
                                                disabled,
                                                disableHoverText);

        });
      }  
    );
    this.modalService.open(this.template, { size: 'lg' as any,  windowClass: 'case-type-popup'  });
  }

  notify() {
    this.caseTypeChanged.next();
  }

  close() {
    this.modalService.dismissAll();
  }

  disableSave() {
    return this.caseTypeCode === this.theCaseTypeCode;
  }

  private shouldBeDisabled(currentCaseType: CaseType, caseType: CaseType ) {

    if (caseType.code === CASE_TYPE_CODES.AWAITING_CASE_TYPE  ) {
      return true;
    }

    if (caseType.code === CASE_TYPE_CODES.MEDICAL_PRE_ASSESSMENT  ) {
      return true;
    }
    return !currentCaseType.allowableCaseTypes.find( ct => ct.code == caseType.code)

  }
}
