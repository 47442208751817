import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CaseService } from '@secca/core/services/case.service';
import { CaseSummaryService } from '@secca/core/services/caseSummary.service';
import { DictionaryService } from '@secca/core/services/dictionary.service';
import { Case } from '@secca/shared/models/case';
import { CaseHistorySearchFilter } from '@secca/shared/models/CaseHistorySearchFilter';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { CaseSummarySearchFilter } from '@secca/shared/models/CaseSummarySearchFilter';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import { EconomyHistoryFilterType, SortOrder } from '@secca/shared/models/enums';
import { PagingInfo } from '@secca/shared/models/paging-info';
import { UserDto } from '@secca/shared/models/userDto';
import { CaseHistoryFilterComponent } from './case-history-filter/case-history-filter.component';
import { CaseSummaryFilterComponent } from './case-summary-filter/case-summary-filter.component';
import { DigitalConsent } from '@secca/shared/models/digital-consent';

@Component({
  selector: 'app-case-summary',
  templateUrl: './case-summary.component.html',
  styleUrls: ['./case-summary.component.scss']
})
export class CaseSummaryComponent implements OnInit {
  @ViewChild(CaseHistoryFilterComponent)
  private caseHistoryFilterComponent: CaseHistoryFilterComponent;

  @ViewChild(CaseSummaryFilterComponent)
  private caseSummaryFilterComponent: CaseSummaryFilterComponent;

  @Input()
  private case: Case;

  @Input()
  private consent: DigitalConsent;

  @Input() private numberOfLinkedCases: number;

  @Output()
  channelTypeChanged: EventEmitter<string> = new EventEmitter();

  typeItems: DropdownDictionary[] = [];
  historyObjectItems: DropdownDictionary[] = [];
  systemEventObjectItems: DropdownDictionary[] = [];
  economyObjectItems: DropdownDictionary[] = [];
  users: UserDto[] = [];
  stakeholders: CaseStakeholder[];

  historySearchFilter: CaseHistorySearchFilter = new CaseHistorySearchFilter();
  systemEventSearchFilter: CaseHistorySearchFilter = new CaseHistorySearchFilter();
  summarySearchFilter: CaseSummarySearchFilter = new CaseSummarySearchFilter();
  economySearchFilter: CaseSummarySearchFilter = new CaseSummarySearchFilter();

  historyPagingInfo: PagingInfo = {
    pageSize: 50,
    pageIndex: 0,
    sortOrder: SortOrder.desc
  };

  summaryPagingInfo: PagingInfo = {
    pageSize: 50,
    pageIndex: 0,
    sortOrder: SortOrder.desc
  };

  constructor(private dictionaryService: DictionaryService,
              private caseSummaryService: CaseSummaryService,
              private caseService: CaseService) {
  }

  ngOnInit(): void {
    this.fetchAuthors();
    this.fetchTypeItems();
    this.fetchObjectItems();
    this.fetchStakeholders();

    this.economyObjectItems = [
      EconomyHistoryFilterType.CUSTOMER_INVOICE,
      EconomyHistoryFilterType.RECOVERY_PAYMENT,
      EconomyHistoryFilterType.REFUND,
      EconomyHistoryFilterType.SOS_SERVICE,
      EconomyHistoryFilterType.SUPPLIER_INVOICE
    ]
      .sort()
      .map(str => new DropdownDictionary(str, str.toString()));
  }

  sortHistoryChanged(value: string): void {
    this.historyPagingInfo.sortOrder = value;
    this.historyPagingInfo.pageIndex = 0;
    this.caseHistoryFilterComponent.performSearch();
  }

  sortEconomyChanged(value: string): void {
    this.historyPagingInfo.sortOrder = value;
    this.historyPagingInfo.pageIndex = 0;
    this.caseHistoryFilterComponent.performSearch();
  }

  sortSummaryChanged(value: string): void {
    this.summaryPagingInfo.sortOrder = value;
    this.summaryPagingInfo.pageIndex = 0;
    this.caseSummaryFilterComponent.performSearch();
  }

  summaryPaginationChanged(index: number): void {
    this.caseSummaryFilterComponent.pagingChanged(index);
  }

  historyPaginationChanged(index: number): void {
    this.caseHistoryFilterComponent.pagingChanged(index);
  }

  channelTypeUpdated(channelType: string) {
    this.channelTypeChanged.emit(channelType);
  }

  private fetchAuthors(): void {
    this.caseSummaryService.getCaseSummaryAuthors(this.case.id).subscribe(
      result => {
        this.users = result;
      },
      error => console.log(error)
    );
  }

  private fetchTypeItems(): void {
    this.dictionaryService.getDictionaryEntriesAsStringObject('casehistory/dictionary/casehistorytypesforsummary').subscribe(
      result => {
        this.typeItems = result.map(str => new DropdownDictionary(str, str));
      },
      error => console.log(error)
    );
  }

  private fetchObjectItems(): void {
    this.dictionaryService.getDictionaryEntriesAsStringObject('casehistory/dictionary/caseobjects/FULL_DETAILS').subscribe(
      result => {
        this.historyObjectItems = result.map(str => new DropdownDictionary(str, str));
      }
    );
    this.dictionaryService.getDictionaryEntriesAsStringObject('casehistory/dictionary/caseobjects/SYSTEM_EVENTS').subscribe(
      result => {
        this.systemEventObjectItems = result.map(str => new DropdownDictionary(str, str));
      }
    );
  }

  private fetchStakeholders() {
    this.caseService.getAllStakeholdersOnCase(this.case.id).subscribe(
      result => {
        this.stakeholders = result;
      },
      error => console.log(error));
  }

}
