<ng-template #content let-modal>
  <div
    class="modal-container"
    [ngClass]="{
      'modal-container-transport': handlingAreaType === 'Transport',
      'modal-container-cost-control': handlingAreaType === 'Cost Control',
      'modal-container-recovery': handlingAreaType === 'Recovery',
      'modal-container-claims': handlingAreaType === 'Claims'
    }"
  >
    <div class="modal-header">
      <div class="lookup-title">
        {{ handlingAreaType }}
      </div>
      <div class="lookup-close float-end clearfix">
        <img (click)="close()" src="../../../../../../../assets/icons/Close.svg" alt="Close" />
      </div>
    </div>

    <div class="modal-body">
      <div
        class="col-12 handler-label"
        (click)="makeChangeHandlerVisible($event)"
        [ngStyle]="!isChangeHandlerVisible && { cursor: 'pointer' }"
      >
        {{ 'HANDLING-AREAS-HANDLER' | translate }}
      </div>

      <div class="row">
        <div
          class="col-2"
          *ngIf="!isChangeHandlerVisible && !handlerIsAnUser()"
          [ngStyle]="!isChangeHandlerVisible && { cursor: 'pointer' }"
          (click)="makeChangeHandlerVisible($event)"
        >
          <div class="team-column" (click)="makeChangeHandlerVisible($event)">
            <app-team-picture> </app-team-picture>
          </div>
        </div>

        <div
          class="col-2"
          *ngIf="!isChangeHandlerVisible && handlerIsAnUser()"
          [ngStyle]="!isChangeHandlerVisible && { cursor: 'pointer' }"
          (click)="makeChangeHandlerVisible($event)"
        >
          <div class="photo-column" (click)="makeChangeHandlerVisible($event)">
            <img [src]="this.userImage" class="personPhoto" (error)="updateUrl($event)" />
          </div>
        </div>

        <div
          class="col-10"
          *ngIf="!isChangeHandlerVisible"
          (click)="makeChangeHandlerVisible($event)"
          [ngStyle]="!isChangeHandlerVisible && { cursor: 'pointer' }"
        >
          <div class="person-shortcut" [ngClass]="{ 'modal-team-shortcut': !!handlingArea.team }">{{ handlerShortcutName }}</div>
          <div class="person-title">{{ handlerTeamName }}</div>
        </div>
      </div>
      <div
        class="col-12 handler-input"
        [style.display]="isChangeHandlerVisible ? 'block' : 'none'"
        (clickOutside)="makeCaseHandlerVisible($event)"
        (focusout)="makeCaseHandlerVisible($event)"
        (keyup.enter)="makeCaseHandlerVisible($event)"
      >
        <app-drop-down-auto-complete
          [caseId]="caseId"
          [autoCompleteTypeEnum]="getAutoCompleteTypeEnum()"
          (selectedItemIdChange)="caseHandlerChanged($event)"
          [appendTo]="'body'"
        >
        </app-drop-down-auto-complete>
      </div>
      <div class="col-12 status-container">
        <div class="status" *ngIf="handlingArea.status != getHandlerStatus().NON_ACTIVE">
          <app-drop-down
            [name]="'Status'"
            [items]="status"
            [(selectedItemId)]="theStatus"
            (selectedItemIdChange)="setNewStatus()"
          ></app-drop-down>
        </div>
        <div class="nonStatus" *ngIf="handlingArea.status == getHandlerStatus().NON_ACTIVE"></div>
      </div>
      <div class="col save" [ngbPopover]="savePopover" triggers="hover" placement="top" container="body" [disablePopover]="closable">
        <button class="save-button" (click)="save()" [disabled]="!saveable">
          {{ 'service-order-save' | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #savePopover>
  <div *ngIf="caseValidationTexts.length > 0">
    {{ 'handling-area-validation-intro' | translate }}
    <ul>
      <li *ngFor="let caseValidation of  caseValidationTexts">
        {{ caseValidation | translate }}
      </li>
    </ul>
  </div>

  <div *ngFor="let closeValidation of validationTexts">{{ closeValidation | translate }}</div>
</ng-template>
