import { MedicalPreAssessmentAdapter } from '../../shared/models/medicalPreAssessment';
import { MedicalPreAssessment } from '@secca/shared/models/medicalPreAssessment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseService } from './base.service';
import { SettingsService } from './settings.service';
import { map } from 'rxjs/operators';
import { MedicalPreAssessmentDecisionEnum } from '@secca/shared/models/enums';

@Injectable({
  providedIn: 'root',
})
export class MedicalPreAssessmentService extends BaseService {

  private medicalPreAssessmentDraftPrefix = 'medical-pre-assessment-';
  private medicalPreAssessmentChanges = {};
  excludedValues: string[] = [];
  componentText = '';

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private medicalPreAssessmentAdapter: MedicalPreAssessmentAdapter
  ) {
    super(settingsService);
  }

  public getMedicalPreAssessment(id: number): Observable<MedicalPreAssessment> {
    return this.http.get(this.baseURL + 'medical-pre-assessment/' + id).pipe(map(item => this.medicalPreAssessmentAdapter.adapt(item)));
  }

  public getNewestMedicalPreAssessment(caseId: number): Observable<MedicalPreAssessment> {
    return this.http
      .get(this.baseURL + 'medical-pre-assessment/case/newest/' + caseId)
      .pipe(map(item => this.medicalPreAssessmentAdapter.adapt(item)));
  }

  public getMedicalPreAssessments(caseId: number): Observable<MedicalPreAssessment[]> {
    return this.http
      .get(this.baseURL + 'medical-pre-assessment/case/' + caseId)
      .pipe(map((data: any[]) => data.map(item => this.medicalPreAssessmentAdapter.adapt(item))));
  }

  public getMedicalPreAssessmentExists(caseId: number): Observable<boolean> {
    return this.http
      .get(this.baseURL + 'medical-pre-assessment/exists/case/' + caseId)
      .pipe(map(item => !!item));
  }

  public createMedicalPreAssessment(medicalPreAssessment: MedicalPreAssessment): Observable<MedicalPreAssessment> {
    return this.http.post<MedicalPreAssessment>(this.baseURL + 'medical-pre-assessment', medicalPreAssessment, {
      headers: this.jsonHeaders,
    }).pipe(map(result => this.medicalPreAssessmentAdapter.adapt(result)));
  }

  public updateMedicalPreAssessment(medicalPreAssessment: MedicalPreAssessment): Observable<MedicalPreAssessment> {
    return this.http.put<any>(this.baseURL + 'medical-pre-assessment/' + medicalPreAssessment.id, medicalPreAssessment, {
      headers: this.jsonHeaders,
    }).pipe(map(result => this.medicalPreAssessmentAdapter.adapt(result)));
  }

  public cancelMedicalPreAssessment(medicalPreAssessment: MedicalPreAssessment): Observable<MedicalPreAssessment> {
    return this.http.post<any>(this.baseURL + 'medical-pre-assessment/cancel/case/' + medicalPreAssessment.seccaCaseId, medicalPreAssessment, {
      headers: this.jsonHeaders,
    }).pipe(map(result => this.medicalPreAssessmentAdapter.adapt(result)));
  }

  public savePreAssessmentDraft(medicalPreAssessment: MedicalPreAssessment, caseId: number){
    sessionStorage.setItem(this.medicalPreAssessmentDraftPrefix + caseId, JSON.stringify(medicalPreAssessment));
  }

  public removeMedicalPreAssessmentDraft(caseId: number) {
    sessionStorage.removeItem(this.medicalPreAssessmentDraftPrefix + caseId);
  }

  public getPreAssessmentInDraft(caseId: number): MedicalPreAssessment {
    const draft = sessionStorage.getItem(this.medicalPreAssessmentDraftPrefix + caseId);
    if (!!draft) {
      return this.medicalPreAssessmentAdapter.adapt(JSON.parse(draft));
    }
    return null;
  }

  public getMedicalPreAssessmentDecisionChanges(caseId: number): Observable<MedicalPreAssessmentDecisionEnum> {
    this.initMedicalPreAssessmentDecisionChangesBehaviorSubject(caseId);
    return this.medicalPreAssessmentChanges[caseId].asObservable();
  }

  public setMedicalPreAssessmentDecisionChanges(caseId: number, medicalPreAssessmentDecision: MedicalPreAssessmentDecisionEnum) {
    this.initMedicalPreAssessmentDecisionChangesBehaviorSubject(caseId);
    if(medicalPreAssessmentDecision) {
      this.medicalPreAssessmentChanges[caseId].next(medicalPreAssessmentDecision);
    } else {
      this.medicalPreAssessmentChanges[caseId].next(null);
    }
  }

  private initMedicalPreAssessmentDecisionChangesBehaviorSubject(caseId: number) {
    if (this.medicalPreAssessmentChanges[caseId] == null) {
      this.medicalPreAssessmentChanges[caseId] = new BehaviorSubject(undefined);
    }
  }

  public storeMedicalPreAssessmentLetter(medicalAssessmentId: number): Observable<any> {
    return this.http.post(this.baseURL + `documents/medical-pre-assessment/store/${medicalAssessmentId}`, '',
      { headers: this.jsonHeaders, responseType: 'text' } );
  }

  public getMedicalPreAssessmentPreviewLetter(medicalPreAssessment: MedicalPreAssessment): Observable<any> {
    return this.http
      .post(this.baseURL + `documents/medical-pre-assessment/preview`, medicalPreAssessment, {
        responseType: 'arraybuffer',
      })
      .pipe(
        map((res: any) => {
          return new Blob([res], {
            type: 'application/pdf',
          });
        })
      );
  }

  public getMedicalPreAssessmentPreviewLetterById(medicalAssessmentId: number): Observable<any> {
    return this.http
      .post(this.baseURL + `documents/medical-pre-assessment/preview/${medicalAssessmentId}`, '', {
        responseType: 'arraybuffer',
      })
      .pipe(
        map((res: any) => {
          return new Blob([res], {
            type: 'application/pdf',
          });
        })
      );
  }
}
