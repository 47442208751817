import {StakeholderService} from 'src/app/core/services/stakeholder.service';
import {Component, Input} from '@angular/core';
import {ServiceOrder} from '@secca/shared/models/service-order/service-order';
import {ServiceOrderHoverExtensionComponent} from '@secca/case/components/case-plans/plan/service-order-hover/service-order-hover-extension-component';
import {CaseStakeholder} from '@secca/shared/models/caseStakeholder';
import {StakeholderTypeEnum, StatusTypes} from '@secca/shared/models/enums';
import {ServiceOrderExtension} from '@secca/shared/models/service-order/service-order-extension';
import { CommitmentType } from '@secca/shared/models/commitment-type';
import { Status } from '@secca/shared/models/profile-lookup-response';

@Component({
  selector: 'app-base-service-order-hover',
  templateUrl: './base-service-order-hover.component.html',
  styleUrls: ['./base-service-order-hover.component.scss'],
})
export class BaseServiceOrderHoverComponent implements ServiceOrderHoverExtensionComponent {
  @Input() serviceOrder: ServiceOrder;
  @Input() caseStakeholders: CaseStakeholder[];
  @Input() providerName: string;
  @Input() agentName: string;

  get extension(): ServiceOrderExtension {
    return this.serviceOrder.extension as ServiceOrderExtension;
  }

  stakeholders: { firstName: string; surname: string; icon: string; gender: string; age: string }[];

  constructor(private stakeholderService: StakeholderService) {
  }

  onRefresh() {

    if (this.extension.getSelectedStakeholderIds()) {
      this.stakeholders = [];
      this.serviceOrder.getSelectedStakeholderIds().forEach(stakeholderId => {
        const caseStakeholder = this.caseStakeholders.find(a => a.id === stakeholderId.toString());
        if (caseStakeholder != null) {
          this.stakeholders.push(this.createLocalStakeholder(caseStakeholder));
        }
      });
    }
  }

  get commitmentType(): CommitmentType {
    return this.serviceOrder.extension.getCommitmentType();
  }

  get statusType(): String {
    switch (this.serviceOrder.status) {
      case StatusTypes.CANCELLED:
        return 'service-order-cancelled';
      case StatusTypes.COMMITTED:
        return 'service-order-committed';
      case StatusTypes.EXPECTED:
        return 'service-order-expected';
      case StatusTypes.NOT_COVERED:
        return 'service-order-not-covered';
      case StatusTypes.PARTIALLY_COMMITTED:
        return ''
    }
  }

  getStartCountry(): string {
    return this.serviceOrder.getStartLocation() ? this.serviceOrder.getStartLocation().country : 'Denmark';
  }

  getStartTimeZone(): string {
    return this.serviceOrder.getStartLocation() ? this.serviceOrder.getStartLocation().timeZoneId : 'local';
  }

  private createLocalStakeholder(caseStakeholder: CaseStakeholder): any {
    let type = caseStakeholder.stakeholderType;
    if (caseStakeholder.coTraveller) {
      type = StakeholderTypeEnum.coTraveller;
    }
    return {
      firstName: caseStakeholder.person.firstName != null ? caseStakeholder.person.firstName : '',
      surname: caseStakeholder.person.surname != null ? caseStakeholder.person.surname : '',
      icon: this.stakeholderService.getStakeholderIconFullPath(type),
      gender: caseStakeholder.person.gender,
      age: caseStakeholder.person.age,
    };
  }
}
