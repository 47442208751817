import { AttachmentTypeEnum } from './../../../../../../../../shared/models/enums';
import { AttachmentDto } from './../../../../../../../../shared/models/attachment-dto';
import { CaseDocument } from './../../../../../../../../shared/models/caseDocument';
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-message-form-attachments',
  templateUrl: './message-form-attachments.component.html',
  styleUrls: ['./message-form-attachments.component.scss'],
})
export class MessageFormAttachmentsComponent implements OnInit {
  readonly MAX_LEN = 145;

  @Input() attachments: AttachmentDto[];
  @Input() selectedItems: AttachmentDto[];
  @Output() selectedItemsChange = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  searchFunction(term: string, item: CaseDocument) {
    term = term.toLocaleLowerCase();
    return item.documentName.toLocaleLowerCase().indexOf(term) > -1 || item.documentType.toLocaleLowerCase().indexOf(term) > -1;
  }

  public onSelectedItemIdsChange() {
    this.selectedItemsChange.emit(this.selectedItems);
  }

  get AttachmentTypeEnum(){
    return AttachmentTypeEnum;
  }

  getDocumentName(item) {
    if ( item && item?.documentName?.length > this.MAX_LEN ) {
      return item.documentName.substring(0, this.MAX_LEN) + '...';
    }

    return item.documentName;
  }

  getNote(item) {
    return item?.documentNote ? `[${item.documentNote}]` : '';
  }
}
