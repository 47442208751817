import { CoffinUrnHandlingTypeEnum } from '@secca/case/components/case-plans/case-plans/add-service-order/models/enums';
import { SubComponentModel } from '@secca/case/components/case-plans/case-plans/add-service-order/models/interfaces';

export class SubCoffinUrn implements SubComponentModel {
  id: number;
  handling: CoffinUrnHandlingTypeEnum;
  passportLocation: string;

  public constructor(init?: Partial<SubCoffinUrn>) {
    Object.assign(this, init);
  }

  isValid(): boolean {
    return this.handling != null;
  }

}
