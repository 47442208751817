import { Case } from 'src/app/shared/models/case';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { EventEmitter } from '@angular/core';
import {CaseStakeholder} from "@secca/shared/models/caseStakeholder";
import {DigitalConsent} from "@secca/shared/models/digital-consent";
import { CaseLockHelperService } from '../services/case-lock-helper.service';
import { PermissionService } from '../services/permission.service';
import { SensitiveType } from '@secca/shared/models/enums';
import { CASE_TYPE_CODES, CaseType } from '@secca/shared/models/case-type';

@Injectable({
  providedIn: 'root',
})
export class CaseStateService {
  private case = new BehaviorSubject(undefined);
  private caseTypeSubject: ReplaySubject<CaseType> = new ReplaySubject(1);
  private serviceOrderChangeSubject: ReplaySubject<void> = new ReplaySubject(1);
  private refreshCaseInfoSubject: Subject<void> = new Subject();
  private providersUpdatedSubject: Subject<void> = new Subject();
  private refreshCaseType: Subject<void> = new Subject();
  private refreshHandlingAreaSubject: Subject<void> = new Subject();

  isCaseClosed = false;
  isInvoiceClosedWithNoInvoicingToCustomer = false;
  isCaseMedicalPreAssessment = false;
  isSanctionCase = false;

  legalGuardian: EventEmitter<boolean> = new EventEmitter();
  ssnLookupPossible: EventEmitter<{ caseStakeholderId: number; ssnLookupPossible: boolean }> = new EventEmitter();
  stakeholder: EventEmitter<CaseStakeholder> = new EventEmitter();
  consent: EventEmitter<DigitalConsent> = new EventEmitter();
  documentTags: EventEmitter<boolean> = new EventEmitter();
  sensitive: EventEmitter<SensitiveType> = new EventEmitter();

  constructor(
    private caseLockHelperService: CaseLockHelperService,
    private permissionService: PermissionService
  ) {}

  isCaseDisabled() {
    return this.caseLockHelperService.isCaseBasicLockTaken || this.permissionService.isReadOnly || this.isCaseClosed || this.isInvoiceClosedWithNoInvoicingToCustomer;
  }

  public updatedDocumentTags() {
    this.documentTags.emit(true);
  }

  public sendCase(newCase: Case) {
    this.case.next(newCase);
  }

  public getCase(): Observable<Case> {
    return this.case.asObservable();
  }

  public sendCaseType(caseType: CaseType): void {
    this.isSanctionCase = [CASE_TYPE_CODES.SANCTION_CASE, CASE_TYPE_CODES.SANCTION_CASE_FULL].includes(caseType.code);
    this.caseTypeSubject.next(caseType);
  }

  public getCaseType(): Observable<CaseType> {
    return this.caseTypeSubject;
  }

  public sendServiceOrderChange(): void {
    this.serviceOrderChangeSubject.next();
  }

  public getServiceOrderChange(): Observable<void> {
    return this.serviceOrderChangeSubject;
  }

  public sendLegalGuardian() {
    this.legalGuardian.emit();
  }

  public updatedStakeholder(stakeholder: CaseStakeholder){
    this.stakeholder.emit(stakeholder);
  }

  public updatedConsent(consent: DigitalConsent){
    this.consent.emit(consent);
  }

  public updatedSensitive(sensitiveType: SensitiveType){
    this.sensitive.emit(sensitiveType);
  }

  public sendSsnLookupPossible(caseStakeholderId: number, ssnLookupPossible: boolean) {
    this.ssnLookupPossible.emit({ caseStakeholderId, ssnLookupPossible });
  }

  public getRefreshCaseInfo(): Observable<void> {
    return this.refreshCaseInfoSubject;
  }

  public sendRefreshCaseInfo() {
    this.refreshCaseInfoSubject.next();
  }

  public getProvidersUpdated(): Observable<void> {
    return this.providersUpdatedSubject;
  }

  public sendProvidersUpdated(): void {
    this.providersUpdatedSubject.next();
  }
  
  public getRefreshCaseType(): Observable<void> {
    return this.refreshCaseType;
  }

  public sendRefreshCaseType() {
    this.refreshCaseType.next();
  }

  public getRefreshHandlingArea(): Observable<void> {
    return this.refreshHandlingAreaSubject;
  }

  public sendRefreshHandlingArea() {
    this.refreshHandlingAreaSubject.next();
  }

}
