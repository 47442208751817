<div class="card">
  <div class="sub-component-placeholder">
    <div class="sub-component-container">
      <div class="row">
        <div class="col">
          <div class="sub-component-placeholder">
            <app-text-box [name]="'pre-departure-advice-given-label' | translate"
                        [numberOfRows]="5"
                        [maxTextLength]="1000"
                        [required]="false"
                        [(value)]="preDepartureAdvice.adviceGiven">
            </app-text-box>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="sub-component-placeholder">
            <app-sub-commitment-type [(commitmentType)]="preDepartureAdvice.commitmentType" [serviceOrderType]="serviceOrderType" [status]="status" [caseId]="caseId">
            </app-sub-commitment-type>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
