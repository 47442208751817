import { MedicalPreAssessmentQuestionnaire } from './../../../../../shared/models/medicalPreAssessment';
export class MedicalAction {
  id: number;
  assessmentId;
  questionid: number;
  action: string;
  date: Date;
  description: string;
  toBeDeleted: boolean;

  public constructor(init?: Partial<MedicalAction>) {
    Object.assign(this, init);
  }
}

export class SelectItem<T> {
  label: string;
  value: T;
  selected: boolean;

  public constructor(init?: Partial<SelectItem<T>>) {
    Object.assign(this, init);
  }
}

export class MedicalAssessmentState {

  // States
  assessmentValid: boolean;
  basisValid: boolean;
  noteValid: boolean;
  treatmentPrognosisValid: boolean;
  medEvacValid: boolean;
  repatriationStatusValid: boolean;
  nextAssessmentValid: boolean;

  repatriationPrognosisValid: boolean;
  repatriationGeneralValid: boolean;
  repatriationFormValid: boolean;
  repatriationIntensiveCareValid: boolean;
  repatriationWheelchairValid: boolean;
  repatriationTransitAdmissionValid: boolean;
  repatriationCastValid: boolean;
  repatriationOxygenValid: boolean;
  repatriationEscortValid: boolean;
  repatriationEquipmentMedicationValid: boolean;
  repatriationAlternativeFormsValid: boolean;
  repatriationMedicalHistoryValid: boolean;

  // Triggers
  basisDocuments: boolean;
  medicalEvacuation: boolean;
  notTravelHomeAsPlanned: boolean;
  repatriationPrognosis: boolean;
  repatriationInstructionsAllowed: boolean;

  surgery: boolean;

  repatriationArrivalDeadline: boolean;
  extraSpaceDesc: boolean;
  intensiveCarePatient: boolean;
  stretcher: boolean;
  extraSpace: boolean;
  coTransportDesc: boolean;

  carryToChair: boolean;

  medicalCareInTransit: boolean;
  admissionOnArrival: boolean;

  cast: boolean;

  escort: boolean;

  equipmentMedication: boolean;
  equipment: boolean;
  otherEquipment: boolean;

  medication: boolean;
  otherMedication: boolean;

  altExtraSpace: boolean;
  altExtraSpaceDesc: boolean;

  followUp: boolean;

  // Controls
  escortDisabled: boolean;

  // Locked values
  escortLockedValue: boolean;

  altFromShownIndex = 4;

  regularAssessment: boolean;

  public constructor(init?: Partial<MedicalAssessmentState>) {
    Object.assign(this, init);
  }
}
export class MedicalPreAssessmentState {

  
  // States
  typeValid: boolean;
  typeText: string;
  preAssessmentValid: boolean;
  basisValid: boolean;
  noteValid: boolean;
  decisionValid: boolean;

  preAssessment: boolean;

  questionnaireValid: boolean;

  public constructor(init?: Partial<MedicalPreAssessmentState>) {
    Object.assign(this, init);
  }

}

