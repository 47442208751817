import { Component, Input, OnInit } from '@angular/core';
import { StakeholderService } from '@secca/core/services/stakeholder.service';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { StakeholderTypeEnum } from '@secca/shared/models/enums';

@Component({
  selector: 'app-stakeholder',
  templateUrl: './stakeholder.component.html'
})
export class StakeholderComponent implements OnInit {
  @Input() stakeholder;

  constructor(private stakeholderService: StakeholderService) {}

  ngOnInit() {}

  getIconUrl(stakeholder: CaseStakeholder): string {
    // Find type:
    let type = stakeholder.stakeholderType;
    if (stakeholder.coTraveller) {
      type = StakeholderTypeEnum.coTraveller;
    }
    return this.stakeholderService.getStakeholderIconFullPath(type as StakeholderTypeEnum);
  }
}
