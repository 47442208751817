import { Component, Inject, ViewChild } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { DialogBoundryService } from "@secca/core/services/dialog-boundry.service";
import { DialogHelperUtilService } from "@secca/core/services/dialog-helper-util.service";
import { DialogMinimizableDirective } from "@secca/shared/directives/dialog-minimizable.directive";
import { MinimizableDialogType } from "@secca/shared/enums/minimizable-dialog-type-enum";
import { AdditionalInfo, IAdditionalInfo } from "@secca/shared/interfaces/additional-info";
import { IMinimizable } from "@secca/shared/interfaces/minimizable";
import { PolicyViewDialogData } from "./policy-view-dialog-data.component";
import { PolicyViewComponent } from "@secca/case/components/case-basics/case-basics-insurance/policy-view/policy-view.component";
import { IKeyboardEnabled } from "@secca/shared/interfaces/keyboard-enabled";

@Component({
    selector: 'app-policy-view-dialog',
    templateUrl: './policy-view-dialog.component.html',
    styleUrls: ['./policy-view-dialog.component.scss'],
  })
  export class PolicyViewDialogComponent implements IAdditionalInfo, IMinimizable, IKeyboardEnabled {
    @ViewChild(PolicyViewComponent) wrappedInstance: PolicyViewComponent;
    @ViewChild(DialogMinimizableDirective) minimizedDirective: DialogMinimizableDirective;

    data: PolicyViewDialogData;

    constructor(private dialogRef: MatDialogRef<PolicyViewDialogComponent>,
                @Inject(MAT_DIALOG_DATA) data: PolicyViewDialogData,
                private dialogHelperUtilService: DialogHelperUtilService,
                public dialogBoundryService: DialogBoundryService) {

      this.data = data;
    }

    close() {
      this.dialogHelperUtilService.close(this.dialogRef, this.getAdditionalInfo()?.caseId);
    }

    keyboardClose(): void {
      this.wrappedInstance.close();
    }
    
    minimize() {
      this.minimizedDirective.minimize();
    }

    getMinimizeType(): MinimizableDialogType {
      return this.minimizedDirective.appDialogMinimizable;
    }

    getAdditionalInfo(): AdditionalInfo {

      return {
        caseId: +this.data.caseId,
        hoverTitle: 'View policy',
        hoverText: ''
      };
    }

    get MinimizableDialogType() {
      return MinimizableDialogType;
    }
}
