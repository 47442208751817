import { ValidationTypeEnum } from './../../shared/models/enums';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InputValidationService {
  constructor() {}

  private emailRegexp = new RegExp(
    // tslint:disable-next-line:max-line-length
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  isValidated(val: any, validationType: ValidationTypeEnum) {
    let isVal = false;
    switch (validationType) {
      case ValidationTypeEnum.email:
      case ValidationTypeEnum.emailAndValid: {
        isVal = !this.emailRegexp.test(val);
        break;
      }

      case ValidationTypeEnum.emailOrEmpty:
      case ValidationTypeEnum.emailAndValidOrEmpty: {
        isVal = !this.emailRegexp.test(val) && val !== null && val !== '';
        break;
      }

      case ValidationTypeEnum.numbers: {
        let regexp = RegExp('^[0-9]*$');
        isVal = !regexp.test(val);
        break;
      }

      case ValidationTypeEnum.empty: {
        isVal = val == null || val === '' || val.$ngOptionValue === null;
        break;
      }

      case ValidationTypeEnum.required: {
        isVal = val == null || val === '' || val.$ngOptionValue === null;
        break;
      }

      case ValidationTypeEnum.nationalID: {
        // validate NationalID trough API...
        isVal = true;
        break;
      }

      case ValidationTypeEnum.insuranceLevel: {
        isVal = val == null || val === '' || val.$ngOptionValue === null || val === 1;
        break;
      }

      case ValidationTypeEnum.default: {
        if (!val) {
          isVal = true;
        }
        break;
      }
    }
    return isVal;
  }
}
