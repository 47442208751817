import {Injectable} from '@angular/core';
import {Adapter} from './../interfaces/adapter';
import { FinancialStatusEnum } from '@secca/shared/models/enums';

export class FinancialStatus {
  status: FinancialStatusEnum;
  comment: string;

  public constructor(init?: Partial<FinancialStatus>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class FinancialStatusAdapter implements Adapter<FinancialStatus> {
  adapt(item: any): FinancialStatus {
    if (item == null) {
      return new FinancialStatus();
    }

    return new FinancialStatus({
      status: item.status,
      comment: item.comment
    });
  }
}
