import { TreatmentServiceOrderExtension } from './../../../../../../../../shared/models/service-order/treatment-service-order-extension';
import { TranslateService } from '@ngx-translate/core';
import { RegularFlightMedicalServiceOrderExtension } from '@secca/shared/models/service-order/regular-flight-medical-service-order-extension';
import { ServiceOrderExtension } from './../../../../../../../../shared/models/service-order/service-order-extension';
import { ServiceOrderTypeState } from './../../models/serviceOrderTypeState';
import { OutputManagementService } from './../../../../../case-output-management/services/output-management.service';
import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';

@Component({
  selector: 'app-GOP',
  templateUrl: './GOP.component.html',
  styleUrls: ['./GOP.component.scss'],
})
export class GOPComponent implements OnInit, OnChanges {
  _serviceOrderTypeState: ServiceOrderTypeState;

  @Input()
  set serviceOrderTypeState(serviceOrderTypeState: ServiceOrderTypeState) {
    this._serviceOrderTypeState = serviceOrderTypeState;
    this.setButtonText();
  }

  get serviceOrderTypeState(): ServiceOrderTypeState {
    return this._serviceOrderTypeState;
  }

  @Input() caseId: any;
  @Input() serviceOrderExtension: ServiceOrderExtension;

  @Input() supplier: CaseStakeholder;
  @Input() stakeholders: CaseStakeholder[];

  previewText = '';
  sendText = '';
  sendPopUpText = '';

  constructor(private outputManagementService: OutputManagementService, private translateService: TranslateService) {}

  ngOnInit() {
    this.setButtonText();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.supplier) {
      this.setButtonText();
    }
  }

  setButtonText() {
    if (this.serviceOrderExtension instanceof RegularFlightMedicalServiceOrderExtension) {
      this.setMedifButtonText();
    } else {
      this.setGopButtonText();
    }
  }

  private setMedifButtonText() {
    this.previewText = 'MEDIF-preview';
    this.sendText = 'MEDIF-send';
    this.sendPopUpText = '';

    if (this.serviceOrderTypeState?.supplierRequiredAndMissing) {
      this.sendPopUpText = this.translateService.instant('service-order-missing-supplier');
      return;
    }    

    if (this.serviceOrderTypeState?.stakeholderEmailInvalid) {      
      this.sendPopUpText += ( this.sendPopUpText.length != 0 ? '<br>' : '') + this.translateService.instant('service-order-invalid-stakeholder-email', {
        stakeholder: this.supplier?.company?.name || ''
      });
    }    

    const missingStakeholderFields = [];
    if (this.serviceOrderTypeState?.stakeholderEmailRequiredAndMissing) {
      missingStakeholderFields.push( this.translateService.instant('service-order-missing-stakeholder-email-field') );
    }

    if ( missingStakeholderFields.length > 0 ) {
      this.sendPopUpText += ( this.sendPopUpText.length != 0 ? '<br>' : '') + this.translateService.instant('service-order-missing-stakeholder-information', {
        stakeholder: this.supplier?.company?.name || '',
        fields: missingStakeholderFields.map(field => `<br>- ${field}`).join('')
      });
    }

    if ( this.sendPopUpText.length != 0 ) {
      this.sendPopUpText += '<br>' + this.translateService.instant('service-order-missing-stakeholder-fields-post-text');
    }
  }

  private setGopButtonText() {
    this.previewText = 'GOP-preview';
    this.sendText = 'GOP-send';
    this.sendPopUpText = '';

    if (this.serviceOrderTypeState?.supplierRequiredAndMissing) {
      this.sendPopUpText = this.translateService.instant('service-order-missing-supplier');
      return;
    }    

    if (this.serviceOrderTypeState?.supplierGopEmailInvalid) {      
      this.sendPopUpText += ( this.sendPopUpText.length != 0 ? '<br>' : '') + this.translateService.instant('service-order-invalid-supplier-gop-email', {
        supplier: this.supplier?.company?.name || ''
      });
    }

    if (this.serviceOrderTypeState?.agentGopEmailInvalid) {
      this.sendPopUpText += ( this.sendPopUpText.length != 0 ? '<br>' : '') + this.translateService.instant('service-order-invalid-agent-gop-email', {
        agent: this.findAgent()?.company?.name || ''
      });
    }

    const missingSupplierFields = [];
    const missingAgentFields = [];
    if (this.serviceOrderTypeState?.supplierGopEmailRequiredAndMissing) {
      missingSupplierFields.push( this.translateService.instant('service-order-missing-gop-email-field') );
    }
    
    if (this.serviceOrderTypeState?.supplierGopFaxRequiredAndMissing) {
      missingSupplierFields.push( this.translateService.instant('service-order-missing-gop-fax-field') );
    }
    
    if (this.serviceOrderTypeState?.supplierGopMethodIsMissing) {
      missingSupplierFields.push( this.translateService.instant('service-order-missing-gop-method-field') );
    }
    
    if (this.serviceOrderTypeState?.agentMissing) {
      missingSupplierFields.push( this.translateService.instant('service-order-missing-gop-agent-field') );
    }

    if (this.serviceOrderTypeState?.agentGopEmailRequiredAndMissing) {
      missingAgentFields.push( this.translateService.instant('service-order-missing-gop-email-field') );
    }
    
    if (this.serviceOrderTypeState?.agentGopFaxRequiredAndMissing) {
      missingAgentFields.push( this.translateService.instant('service-order-missing-gop-fax-field') );
    }

    if ( missingSupplierFields.length > 0 ) {
      this.sendPopUpText += ( this.sendPopUpText.length != 0 ? '<br>' : '') + this.translateService.instant('service-order-missing-gop-supplier-information', {
        supplier: this.supplier?.company?.name || '',
        fields: missingSupplierFields.map(field => `<br>- ${field}`).join('')
      });
    }

    if ( missingAgentFields.length > 0 ) {
      this.sendPopUpText += ( this.sendPopUpText.length != 0 ? '<br>' : '') + this.translateService.instant('service-order-missing-gop-agent-information', {
        agent: this.findAgent()?.company?.name || '',
        fields: missingAgentFields.map(field => `<br>- ${field}`).join('')
      });
    }

    if ( this.sendPopUpText.length != 0 ) {
      this.sendPopUpText += '<br>' + this.translateService.instant('service-order-missing-gop-fields-post-text');
    }  
  }


  openMessageModal() {
    if (this.serviceOrderTypeState.sendingGopInProgress) {
      return;
    }
    this.serviceOrderTypeState.sendingGopInProgress = true;
    this.serviceOrderTypeState.sendGopEvent.emit();
  }

  preview() {
    this.serviceOrderTypeState.previewGopEvent.emit();
    this.serviceOrderTypeState.gopPreviewWasClicked = true;
  }

  get isValid() {
    let commitmentTypesAreValidForGopPreview = true;
    if (this.serviceOrderExtension instanceof TreatmentServiceOrderExtension) {
      let treatmentServiceOrderExtension = this.serviceOrderExtension as TreatmentServiceOrderExtension;
      if (!treatmentServiceOrderExtension.commitmentType.gop) {
        commitmentTypesAreValidForGopPreview = false;
      }
    }
    return (
      this.serviceOrderTypeState.isLeftSideValid &&
      this.serviceOrderExtension.isPreviewGopValid() &&
      this.serviceOrderTypeState.isSupplierChosen &&
      commitmentTypesAreValidForGopPreview &&
      this.serviceOrderTypeState.endUserHasDateOfBirth
    );
  }

  get disableSendButton(): boolean {   
    const detailsRequired = this.serviceOrderTypeState.stakeholderEmailRequiredAndMissing ||
                            this.serviceOrderTypeState.supplierGopEmailRequiredAndMissing ||
                            this.serviceOrderTypeState.supplierGopFaxRequiredAndMissing ||
                            this.serviceOrderTypeState.agentGopEmailRequiredAndMissing ||
                            this.serviceOrderTypeState.agentGopFaxRequiredAndMissing ||
                            this.serviceOrderTypeState.supplierRequiredAndMissing ||
                            this.serviceOrderTypeState.supplierGopMethodIsMissing ||
                            this.serviceOrderTypeState.agentMissing ||
                            this.serviceOrderTypeState.stakeholderEmailInvalid ||
                            this.serviceOrderTypeState.supplierGopEmailInvalid ||
                            this.serviceOrderTypeState.agentGopEmailInvalid ||
                            !this.serviceOrderTypeState.endUserHasDateOfBirth;

    return (
      this.serviceOrderTypeState.sendingGopInProgress ||
      !!detailsRequired ||
      !this.serviceOrderTypeState.isLeftSideValid ||
      !this.serviceOrderExtension.isPreviewGopValid() ||
      !this.serviceOrderTypeState.gopHasBeenPreviewed ||
      !this.serviceOrderTypeState.isSupplierChosen
    );
  }

  get disablePreviewPopover(): boolean {
    return this.serviceOrderTypeState.endUserHasDateOfBirth;
  }

  get disablePopover(): boolean {
      return !this.serviceOrderTypeState.stakeholderEmailRequiredAndMissing &&
             !this.serviceOrderTypeState.supplierGopEmailRequiredAndMissing &&
             !this.serviceOrderTypeState.supplierGopFaxRequiredAndMissing &&
             !this.serviceOrderTypeState.agentGopEmailRequiredAndMissing &&
             !this.serviceOrderTypeState.agentGopFaxRequiredAndMissing &&
             !this.serviceOrderTypeState.supplierRequiredAndMissing &&
             !this.serviceOrderTypeState.supplierGopMethodIsMissing &&
             !this.serviceOrderTypeState.stakeholderEmailInvalid &&
             !this.serviceOrderTypeState.supplierGopEmailInvalid &&
             !this.serviceOrderTypeState.agentGopEmailInvalid &&
             !this.serviceOrderTypeState.agentMissing;
  }

  private findAgent(): CaseStakeholder {
    let agent;
    if (this.supplier && this.supplier.company && this.supplier.company.gop) {
      agent = this.stakeholders?.find(s => +s.id == +this.supplier.company.gop.agentId);
    }

    return agent;
  }
}
