import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-case-document-table-move-document',
  templateUrl: './case-document-table-move-document.component.html',
  styleUrls: ['./case-document-table-move-document.component.scss']
})
export class CaseDocumentTableMoveDocumentComponent implements OnInit {

  @Output() moveCaseDocumentEvent = new EventEmitter<string>();
  @Output() closeModal = new EventEmitter<string>();
  @Input() documentName: string;

  caseNumber: string;
  isCaseNumberValid = true;
  moveInProgress = false;
  private caseNumberRegex: RegExp = /[A-Z]{4}(-\d{3}){3}/;

  constructor() { }

  ngOnInit() {
  }

  setDirty() {
    this.isCaseNumberValid = true;
    this.caseNumber = this.caseNumber?.toUpperCase();
  }

  moveCaseDocument() {
    this.moveInProgress = true;
    this.moveCaseDocumentEvent.emit(this.caseNumber);
  }

  close() {
    this.closeModal.emit();
  }

  isValidCaseNumber(): boolean {
    return this.caseNumberRegex.test(this.caseNumber) && this.isCaseNumberValid;
  }
}
