<ng-container *ngIf="personInsurance">
  <div class="date-range-container col-6 d-flex mt-2">
  <div class="date-box">
    <app-input-calendar
      [withImage]="true"
      [name]="'case-basics-insurance-insurance-period'| translate "
      (dateChange)="insurancePeriodRangeSupport.updateStartDate($event)"
      [date]="personInsurance.insurancePeriodFrom"
      [invalid]="insurancePeriodRangeSupport.isStartDateInValidInRange()"
      [recommended]="personInsurance.insurancePeriodFrom === null"
    ></app-input-calendar>
  </div>
  <div class="date-spacer">-</div>
  <div class="date-box-no-name">
    <app-input-calendar
      [withImage]="true"
      [name]=" "
      (dateChange)="insurancePeriodRangeSupport.updateEndDate($event)"
      [date]="personInsurance.insurancePeriodTo"
      [invalid]="insurancePeriodRangeSupport.isEndDateInvalidInRange()"
      [recommended]="personInsurance.insurancePeriodTo === null"
    ></app-input-calendar>
  </div>
</div>
<div class="days-and-weeks col-6  mt-2">
  <div>
    <app-input
      [name]="'case-basics-insurance-coverageDays' | translate"
      (saveModel)="calculateWeeks()"
      [(value)]="personInsurance.coverageDays"
      [disabled]="isDaysDisabled() || !!personInsurance.insuranceLookupPolicy || coveragePeriodType === 'ANNUAL'"
    >
    </app-input>
  </div>
  <div>
    <app-input
      [name]="'case-basics-insurance-coverageWeeks' | translate"
      (saveModel)="calculateDays()"
      [(value)]="personInsurance.coverageWeeks"
      [disabled]="isWeeksDisabled() || !!personInsurance.insuranceLookupPolicy || coveragePeriodType === 'ANNUAL'"
    >
    </app-input>
  </div>
</div>
</ng-container>
