<div class="row" *ngIf="recovery" [appDisableInput]="caseLockHelperService.isCaseBasicLockTaken">
  <div class="col-9">
    <div class="common-drop-down-arrow common-drop-down">
      <span class="name">{{'recovery-template' | translate}}</span>
      <ng-select [class.recommended-yellow-background]="!recovery.templateName"
                 dropdownPosition="top"
                 [clearable]="false"
                 [items]="templateNames"
                 [(ngModel)]="recovery.templateName" class="custom"
                 [disabled]="disabled"
                 (change)="selectTemplateLanguages($event)">
        <ng-template ng-label-tmp let-item="item">
          <div>
            {{ item }}
          </div>
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="col-3">
    <div class="common-drop-down-arrow common-drop-down">
      <span class="name">{{'recovery-template-language' | translate}}</span>
      <ng-select [class.recommended-yellow-background]="!recovery.templateLanguage"
                 dropdownPosition="top"
                 [clearable]="false"
                 [items]="templateLanguages"
                 [(ngModel)]="recovery.templateLanguage"
                 class="custom"
                 [disabled]="disabled"
                 (change)="selectTemplateId()">
        <ng-template ng-label-tmp let-item="item">
          <div>
            {{ item }}
          </div>
        </ng-template>
      </ng-select>
    </div>
  </div>
</div>
