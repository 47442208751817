<div class="row mb-0 total-card">
  <div class="small-header col-12">{{ header }}</div>
  <div class="col-4 nopadding left">
    <app-input-with-value-type [disabled]="disabled" (valueChange)="setVisits($event)" [value]="medicalAssessmentVisits.numberOfVisits"
                               [required]="required"
                               [name]="'medical-regular-assessment-visits-treatment' | translate" [valueType]="'Visits'">
    </app-input-with-value-type>
  </div>
  <div class="col-4 nopadding">
    <app-input-with-value-type [disabled]="disabled" (valueChange)="setAditionalVisits($event)"
                               [value]="medicalAssessmentVisits.numberOfAdditionalVisits"
                               [name]="'medical-regular-assessment-additional-vists-expected' | translate" [valueType]="'Visits'"
                               [required]="required"></app-input-with-value-type>
  </div>
  <div class="col-4 nopadding right">
    <app-input-with-value-type [name]="'medical-regular-assessment-total' | translate" [value]="medicalAssessmentVisits.getTotal()"
                               [valueType]="'medical-regular-assessment-visits' | translate" [disabled]="true" [required]="required"></app-input-with-value-type>
  </div>

  <div class="small-spacer"></div>

  <div class="col-12">
    <app-input [disabled]="!!disabled" [name]="'medical-regular-assessment-remarks' | translate"
               [(value)]="medicalAssessmentVisits.remarks"></app-input>
  </div>
</div>
