import {Injectable} from '@angular/core';
import {Adapter} from '../interfaces/adapter';

export class SimpleCustomerProfile {
  profileId: string = null;
  profileCode: string = null;
  profileName: string = null;

  public constructor(init?: Partial<SimpleCustomerProfile>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class SimpleCustomerProfilesAdapter implements Adapter<SimpleCustomerProfile> {
  adapt(item: any): SimpleCustomerProfile {
    return new SimpleCustomerProfile({
      profileId: item.profileId,
      profileCode: item.profileCode,
      profileName: item.profileName,
    });
  }
}
