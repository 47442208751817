import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-previous-value',
  templateUrl: './previous-value.component.html',
  styleUrls: ['./previous-value.component.scss']
})
export class PreviousValueComponent implements OnInit {

  @Input() entry;
  @Input() attribute;
  @Input() display = 'string';

  constructor() {
  }

  ngOnInit(): void {
  }
}
