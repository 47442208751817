<div *ngIf="entry" class="output-management-entry action-button">
  <div class="output-management-entry-header">
    <span>
      <img *ngIf="isEmail()" src="/assets/icons/output-management/email-outline.svg">
      <img *ngIf="!isEmail()" src="/assets/icons/output-management/fax-outline.svg">
    </span>
    <span *ngIf="entry.direction == CommunicationDirection.OUT_BOUND" class="output-management-entry-header-direction">
      <img src="/assets/icons/Outgoing.svg">
    </span>
    <span *ngIf="entry.direction == CommunicationDirection.IN_BOUND" class="output-management-entry-header-direction">
      <img src="/assets/icons/Ingoing.svg">
    </span>
    <span *ngIf="stakeholder"><img class="stakeholder-icon" [src]="stakeholderIcon"></span>
    <span *ngIf="stakeholder"> {{stakeholderName}}</span>
    <span *ngIf="stakeholder && stakeholder.disabled" class="stakeholder-disabled">{{"case-stakeholders-disabled" | translate}}</span>
    <span *ngIf="!stakeholder && entry.direction == CommunicationDirection.IN_BOUND"> {{entry.from}}</span>
    <span *ngIf="!stakeholder && entry.direction == CommunicationDirection.OUT_BOUND"> {{entry.to}}</span>

  </div>
  <div *ngIf="! deleted">
    <span *ngIf='this._entry.caseHistoryType === CaseHistoryType.EMAIL_UPDATE || this._entry.caseHistoryType === CaseHistoryType.EMAIL'> Status: {{this._entry.newStatus}}</span>
  </div>
    <div class="action-button-menu-button " (click)="showEntryMenu=!showEntryMenu" (clickOutside)="showEntryMenu=false"
      *ngIf="showActionButton && !deleted">
      <div class="action-button-menu-button-dot"></div>
      <div class="action-button-menu-button-dot"></div>
      <div class="action-button-menu-button-dot"></div>
    </div>
    <div class="action-button-menu" *ngIf="showEntryMenu">
      <div class="action-button-menu-position" (click)="viewMenuClick()">{{"case-summary-email-view" | translate}}</div>
      <div *ngIf="!caseStateService.isCaseClosed && !caseStateService.isReadOnly" class="action-button-menu-position" (click)="replyMenuClick()">{{"case-summary-email-reply" | translate}}</div>
      <div *ngIf="!caseStateService.isCaseClosed && !caseLockHelperService.isLockTaken(LockContextEnum.CASE_BASIC) && entry.direction !== CommunicationDirection.IN_BOUND">
        <div *permission='PermissionEnum.COMMUNICATION_GDPR_DELETE; hideType:PermissionHideTypeEnum.REMOVE' class="action-button-menu-position redText" (click)="openModal()">{{"case-summary-email-delete" | translate}}</div>
      </div>
    </div>

    <div class="entry-deleted" *ngIf="deleted">
      <span class="redText" *ngIf='entry.deleteGdpr'>{{"case-summary-email-deleted-gdpr" | translate}}</span>
      <span class="redText" *ngIf='!entry.deleteGdpr'>{{"case-summary-email-forwarded-other-application" | translate}}</span>
    </div>
  <div class="entry-deleted" *ngIf="deleted && entry.caseHistoryType === 'ITEM_DELETION'">
      <span class="redText">Deleted</span>
    </div>
    <div *ngIf="! deleted">
      <div class="output-management-entry-line"></div>
      <div class="output-management-entry-title">{{"case-summary-email-subject" | translate}} </div>
      <div class="output-management-entry-value">{{entry.subject}} </div>
      <div class="output-management-entry-title">{{"case-summary-email-internal-summary" | translate}} </div>
      <div class="output-management-entry-value">{{entry.updatedInternalSummary}} </div>
      <div class="output-management-entry-title">{{"case-summary-email-attachments" | translate}} </div>
      <div class="output-management-entry-value">{{entry.attachments}} </div>
    </div>
</div>
