import { Directive, EventEmitter, Injectable, Output } from '@angular/core';

@Directive({selector: 'coverage-change-service'})
@Injectable({
  providedIn: 'root',
})
export class CoverageChangeService {
  @Output() change: EventEmitter<any> = new EventEmitter();

  public updateCoverage(product: any) {
    this.change.emit(product);
  }
}
