import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
import { PolicyLookup, PolicyLookupResponseAdapter, PolicyLookupMethodType } from './policy-lookup-response';
import * as moment from 'moment';
import { DateSerializer } from './date-serializer';
import { DaySerializer } from './day-serializer';

export class PersonInsurance {
  id: string;
  seccaCaseId: string;
  customerProfileId: number;
  noInsuranceWithThisCustomerGroup: boolean;
  customerProduct: number;
  policyReference: string;
  insurancePeriodFrom: moment.Moment;
  insurancePeriodTo: moment.Moment;
  coverageDays: string;
  coverageWeeks: string;
  coveragePeriodFromLookup: boolean;
  coverageWeeksCalculated: boolean;
  customerReference: string;
  geographicalCoverageArea: number;
  insuranceExtensionFrom: moment.Moment;
  insuranceExtensionTo: moment.Moment;
  insuranceLookupMatch: boolean;
  remarks: string;
  creditCard: boolean;
  creditCardNo: string;
  percentagePaidWithCreditCard: boolean;
  insuranceLevelId: string;
  insuranceLookupPolicy: PolicyLookup;
  manualLookupMethod: PolicyLookupMethodType;
  manualLookupTime: moment.Moment;
  public constructor(init?: Partial<PersonInsurance>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
export class PersonInsuranceAdapter implements Adapter<PersonInsurance> {
  constructor(private policyAdapter: PolicyLookupResponseAdapter) {}

  adapt(item: any): PersonInsurance {
    const insurancePeriodFrom = DaySerializer.deserialize(item.insurancePeriodFrom);
    const insurancePeriodTo = DaySerializer.deserialize(item.insurancePeriodTo);

    const insuranceExtensionFrom = DaySerializer.deserialize(item.insuranceExtensionFrom);
    const insuranceExtensionTo = DaySerializer.deserialize(item.insuranceExtensionTo);
    return new PersonInsurance({
      id: item.id,
      seccaCaseId: item.seccaCaseId,
      customerProfileId: item.customerProfileId,
      noInsuranceWithThisCustomerGroup: item.noInsuranceWithThisCustomerGroup,
      customerProduct: item.customerProduct,
      policyReference: item.policyReference,
      insurancePeriodFrom,
      insurancePeriodTo,
      coverageDays: item.coverageDays,
      coverageWeeks: item.coverageWeeks,
      coveragePeriodFromLookup: item.coveragePeriodFromLookup,
      coverageWeeksCalculated: item.coverageWeeksCalculated,
      customerReference: item.customerReference,
      geographicalCoverageArea: item.geographicalCoverageArea == null ? null : item.geographicalCoverageArea,
      insuranceExtensionFrom,
      insuranceExtensionTo,
      insuranceLookupMatch: item.insuranceLookupMatch,
      remarks: item.remarks,
      creditCard: item.creditCard,
      creditCardNo: item.creditCardNo,
      percentagePaidWithCreditCard: item.percentagePaidWithCreditCard === null ? false : item.percentagePaidWithCreditCard,
      insuranceLevelId: item.insuranceLevelId == null ? null : item.insuranceLevelId,
      insuranceLookupPolicy: item.insuranceLookupPolicy == null ? null : this.adaptPolicyLookup(item.insuranceLookupPolicy),
      manualLookupMethod: item.manualLookupMethod,
      manualLookupTime: DateSerializer.deserialize(item.manualLookupTime),
    });
  }

  adaptPolicyLookup(policy): PolicyLookup {
    const insurancePeriodStart = DaySerializer.deserialize(policy.insurancePeriodStart);
    const insurancePeriodEnd = DaySerializer.deserialize(policy.insurancePeriodEnd);
    return new PolicyLookup({
      product: this.policyAdapter.adaptProduct(policy.product),
      policyNumber: policy.policyNumber,
      insurancePeriodStart,
      insurancePeriodEnd,
      extensions: this.policyAdapter.adaptExtensions(policy.extensions),
      insuranceLevel: policy.insuranceLevel,
      supplements: this.policyAdapter.adaptSupplements(policy.coverages),
      exclusions: policy.exclusions,
      excess: this.policyAdapter.adaptExcesses(policy.excess),
      policyHolder: this.policyAdapter.adaptPerson(policy.policyHolder),
      coInsured: policy.coInsured,
      coveredPersons: this.policyAdapter.adaptPersons(policy.coveredPersons),
      specialRemarks: policy.specialRemarks,
      matchQuality: policy.matchQuality,
      possibleMatches: this.policyAdapter.adaptMatches(policy.possibleMatches),
      lookupExecution: this.policyAdapter.adaptLookupExecution(policy.lookupExecution),
      customerContactInfo : policy.customerContactInfo
    });
  }
}
