import { ChannelType } from '@secca/shared/enums/channelType';
import { SortOrder, TaskStatus } from './../../../../../shared/models/enums';
import * as moment from 'moment';
import { ScreeningStates } from '@secca/shared/enums/screening-states';

export class TaskFilterRequest {
  to: moment.Moment;
  from: moment.Moment;
  taskType: string;
  taskTypes: string[];
  sortBy: string;
  userId: number;
  userIds: number[];
  adtCode: string;
  adtCodes: string[];
  eventIds: number[];
  pageIndex: number;
  pageSize: number;
  sortOrder: SortOrder;
  teamOnly: boolean;
  channelTypes: ChannelType[];
  taskStatus: TaskStatus;
  customerProfileId: number;
  customerProfileIds: CustomerProfileIdsForSearch[];
  incidentCoveredStates: ScreeningStates[];
  adtCodesForCount: string[];
  weekAgo = 0;
  taskCauseLevel1Code: string;
  taskCauseLevel2Code: string;
  taskCauseLevel3Code: string;


  set toDate(to: moment.Moment) {
    this.to = to;
  }

  get toDate(): moment.Moment {
    return this.to;
  }

  set fromDate(from: moment.Moment) {
    this.from = from;
  }

  get fromDate(): moment.Moment {
    return this.from;
  }

  public constructor(init?: Partial<TaskFilterRequest>) {
    Object.assign(this, init);
  }
}

export class CustomerProfileIdsForSearch {
  customerProfileId: number;
  isGroupProfile: boolean;
}
