import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CaseLocalTimeService {
  incidentLocationZone: ZoneLocation;
  currentLocationZone: ZoneLocation;
  localTimeZone: BehaviorSubject<LocalTimeZone> = new BehaviorSubject(undefined);
  private seccaTimezoneOffset = moment().utcOffset();

  setIncidentLocation(timezoneId: string, city: string, country: string, countryCodeAlpha2: string) {
    if (country) {
      this.incidentLocationZone = new ZoneLocation(city, country, timezoneId, countryCodeAlpha2);
    } else {
      this.incidentLocationZone = undefined;
    }
    this.update();
  }

  setCurrentLocation(timezoneId: string, city: string, country: string, countryCodeAlpha2: string) {
    if (country) {
      this.currentLocationZone = new ZoneLocation(city, country, timezoneId, countryCodeAlpha2);
    } else {
      this.currentLocationZone = undefined;
    }
    this.update();
  }

  getLocalTimeZone(): Observable<LocalTimeZone> {
    return this.localTimeZone.asObservable();
  }

  clearLocalTimeZone() {
    this.incidentLocationZone = undefined;
    this.currentLocationZone = undefined;
    this.localTimeZone.next(undefined);
  }

  private update() {
    if (this.currentLocationZone) {
      this.updateLocalTime(this.currentLocationZone);
    } else if (this.incidentLocationZone) {
      this.updateLocalTime(this.incidentLocationZone);
    } else {
      this.localTimeZone.next(undefined);
    }
  }

  private updateLocalTime(location: ZoneLocation) {
    this.localTimeZone.next(new LocalTimeZone(location.city, location.country, location.timezoneId, location.countryCodeAlpha2));
  }
}

class ZoneLocation {
  constructor(city: string, country: string, timezoneId: string, countryCodeAlpha2: string) {
    this.city = city;
    this.country = country;
    this.timezoneId = timezoneId;
    this.countryCodeAlpha2 = countryCodeAlpha2;
  }

  city: string;
  country: string;
  timezoneId: string;
  countryCodeAlpha2: string;
}

export class LocalTimeZone {
  constructor(city: string, country: string, timeZoneId: string, countryCodeAlpha2: string) {
    this.city = city;
    this.country = country;
    this.timeZoneId = timeZoneId;
    this.countryCodeAlpha2 = countryCodeAlpha2;
  }

  city: string;
  country: string;
  timeZoneId: string;
  countryCodeAlpha2: string;
}
