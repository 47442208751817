<div [ngClass]="{'checkbox-section-company-height': !!caseStakeholder.company}">
    <div class="checkbox-section" [ngClass]="{'pt-0': !!caseStakeholder.company}">
      <div class="d-flex justify-content-start" >
        <label class="iconBox" *ngIf="type === 'POLICYHOLDER'">
          <img [class]="mouseoverEndUserIconOnPolicyHolder ? 'icon-img-hover': 'icon-img'" (click)="toggleStakeholderMerge('END_USER', true)"
               *ngIf="!caseStakeholder.hasRole('END_USER') && allowEdit() && similarTo('END_USER') && !caseStakeholder.isCompany"
               [src]="mouseoverEndUserIconOnPolicyHolder ? '/assets/icons/Is End-User ON.svg': '/assets/icons/Is End-User OFF.svg'"
               (mouseover)="mouseoverEndUserIconOnPolicyHolder = true" (mouseout)="mouseoverEndUserIconOnPolicyHolder = false"
               ngbPopover="End-User" [autoClose]="false" triggers="hover" openDelay="400" placement="top">
          <img class="icon-img" (click)="toggleStakeholderMerge('END_USER', false)"
               *ngIf="caseStakeholder.hasRole('END_USER') && allowEdit()" src="/assets/icons/Is End-User ON.svg"
               ngbPopover="End-User" [autoClose]="false" triggers="hover" openDelay="400" placement="top">

          <img class="icon-img-disabled" *ngIf="!caseStakeholder.hasRole('END_USER') && (!allowEdit() || !similarTo('END_USER') || caseStakeholder.isCompany)"
               src="/assets/icons/Is End-User OFF.svg" ngbPopover="End-User" [autoClose]="false" triggers="hover" openDelay="400" placement="top">

          <img class="icon-img-same" *ngIf="caseStakeholder.hasRole('END_USER') && !allowEdit()"
               src="/assets/icons/Is End-User ON.svg" ngbPopover="End-User" [autoClose]="false" triggers="hover" openDelay="400" placement="top">
        </label>

        <label class="iconBox" *ngIf="type === 'REPORTER'">
          <img [class]="mouseoverEndUserIconOnReporter ? 'icon-img-hover': 'icon-img'" (click)="toggleStakeholderMerge('END_USER', true)"
               *ngIf="!caseStakeholder.hasRole('END_USER') && allowEdit() && similarTo('END_USER') && !caseStakeholder.isCompany"
               [src]="mouseoverEndUserIconOnReporter ? './assets/icons/Is End-User ON.svg': './assets/icons/Is End-User OFF.svg'"
               (mouseover)="mouseoverEndUserIconOnReporter = true" (mouseout)="mouseoverEndUserIconOnReporter = false"
               ngbPopover="End-User" [autoClose]="false" triggers="hover" openDelay="400" placement="top">

          <img class="icon-img" (click)="toggleStakeholderMerge('END_USER', false)"
               *ngIf="caseStakeholder.hasRole('END_USER') && allowEdit()" src="/assets/icons/Is End-User ON.svg"
               ngbPopover="END_USER" [autoClose]="false" triggers="hover" openDelay="400" placement="top">

          <img class="icon-img-disabled" *ngIf="!caseStakeholder.hasRole('END_USER') && (!allowEdit() || !similarTo('END_USER') || caseStakeholder.isCompany)"
            src="/assets/icons/Is End-User OFF.svg" ngbPopover="End-User" [autoClose]="false" triggers="hover" openDelay="400" placement="top">

          <img class="icon-img-same" *ngIf="caseStakeholder.hasRole('END_USER') && !allowEdit()"
               src="/assets/icons/Is End-User ON.svg" ngbPopover="End-User" [autoClose]="false" triggers="hover" openDelay="400" placement="top">
        </label>

        <label class="iconBox" *ngIf="type === 'END_USER'">
            <img class="icon-img-same" src="/assets/icons/Is End-User ON.svg"
            ngbPopover="End-User" [autoClose]="false" triggers="hover" openDelay="400" placement="top">
        </label>

        <label class="iconBox" *ngIf="type === 'END_USER'">
          <img [class]="mouseoverPolicyHolderIconOnEndUser ? 'icon-img-hover': 'icon-img'" (click)="toggleStakeholderMerge('POLICYHOLDER', !caseStakeholder.enduserIsPolicyholder)"
               *ngIf="!caseStakeholder.hasRole('POLICYHOLDER') && allowEdit() && similarTo('POLICYHOLDER')"
               [src]="mouseoverPolicyHolderIconOnEndUser ? './assets/icons/Is PolicyHolder ON.svg': './assets/icons/Is PolicyHolder OFF.svg'"
               (mouseover)="mouseoverPolicyHolderIconOnEndUser = true" (mouseout)="mouseoverPolicyHolderIconOnEndUser = false"
               ngbPopover="Policy Holder" [autoClose]="false" triggers="hover" openDelay="400" placement="top">

          <img class="icon-img" (click)="toggleStakeholderMerge('POLICYHOLDER', !caseStakeholder.enduserIsPolicyholder)"
               *ngIf="caseStakeholder.hasRole('POLICYHOLDER') && allowEdit()" src="/assets/icons/Is PolicyHolder ON.svg"
               ngbPopover="Policy Holder" [autoClose]="false" triggers="hover" openDelay="400" placement="top">
          <img class="icon-img-disabled" *ngIf="!caseStakeholder.hasRole('POLICYHOLDER') && (!allowEdit() || !similarTo('POLICYHOLDER'))"
            src="/assets/icons/Is PolicyHolder OFF.svg" ngbPopover="Policy Holder" [autoClose]="false" triggers="hover" openDelay="400" placement="top">
          <img class="icon-img-same" *ngIf="caseStakeholder.hasRole('POLICYHOLDER') && !allowEdit()"
               src="/assets/icons/Is PolicyHolder ON.svg" ngbPopover="Policy Holder" [autoClose]="false" triggers="hover" openDelay="400" placement="top">
        </label>

        <label class="iconBox" *ngIf="type === 'REPORTER'">
          <img [class]="mouseoverPolicyHolderIconOnReporter ? 'icon-img-hover': 'icon-img'" (click)="toggleStakeholderMerge('POLICYHOLDER', !caseStakeholder.policyholderIsReporter)"
               *ngIf="!caseStakeholder.hasRole('POLICYHOLDER') && !(creditCardOrCustomerProductSelected && caseStakeholder.isCompany) && allowEdit() && similarTo('POLICYHOLDER')"
               [src]="mouseoverPolicyHolderIconOnReporter ? './assets/icons/Is PolicyHolder ON.svg': './assets/icons/Is PolicyHolder OFF.svg'"
               (mouseover)="mouseoverPolicyHolderIconOnReporter = true" (mouseout)="mouseoverPolicyHolderIconOnReporter = false"
               ngbPopover="Policy Holder" [autoClose]="false" triggers="hover" openDelay="400" placement="top">

          <img class="icon-img" (click)="toggleStakeholderMerge('POLICYHOLDER', !caseStakeholder.policyholderIsReporter)"
               *ngIf="caseStakeholder.hasRole('POLICYHOLDER') && allowEdit()" src="/assets/icons/Is PolicyHolder ON.svg"
               ngbPopover="Policy Holder" [autoClose]="false" triggers="hover" openDelay="400">

          <img class="icon-img-disabled"
               *ngIf="(!caseStakeholder.hasRole('POLICYHOLDER') && (!allowEdit() || !similarTo('POLICYHOLDER'))) ||
                      (caseStakeholder.isCompany && creditCardOrCustomerProductSelected && allowEdit())"
              src="/assets/icons/Is PolicyHolder OFF.svg"
              ngbPopover="Policy Holder" [autoClose]="false" triggers="hover" openDelay="400" placement="top">
          <img class="icon-img-same" *ngIf="caseStakeholder.hasRole('POLICYHOLDER') && !allowEdit()"
               src="/assets/icons/Is PolicyHolder ON.svg" ngbPopover="Policy Holder" [autoClose]="false" triggers="hover" openDelay="400"  placement="top">
        </label>

        <label class="iconBox" *ngIf="type === 'POLICYHOLDER'">
          <img class="icon-img-same" src="/assets/icons/Is PolicyHolder ON.svg"
            ngbPopover="Policy Holder" [autoClose]="false" triggers="hover" openDelay="400" placement="top">
        </label>

        <label class="iconBox" *ngIf="type === 'END_USER'">
          <img [class]="mouseoverReporterIconOnEndUser ? 'icon-img-hover': 'icon-img'" (click)="toggleStakeholderMerge('REPORTER', !caseStakeholder.enduserIsReporter)"
               *ngIf="!caseStakeholder.hasRole('REPORTER') && allowEdit() && similarTo('REPORTER')"
               [src]="mouseoverReporterIconOnEndUser ? './assets/icons/Is Report ON.svg': './assets/icons/Is Report OFF.svg'"
               (mouseover)="mouseoverReporterIconOnEndUser = true" (mouseout)="mouseoverReporterIconOnEndUser = false"
               ngbPopover="Reporter" [autoClose]="false" triggers="hover" openDelay="400" placement="top">

          <img class="icon-img" (click)="toggleStakeholderMerge('REPORTER', !caseStakeholder.enduserIsReporter)"
               *ngIf="caseStakeholder.hasRole('REPORTER') && allowEdit()" src="/assets/icons/Is Report ON.svg"
               ngbPopover="Reporter" [autoClose]="false" triggers="hover" openDelay="400" placement="top">

          <img class="icon-img-disabled" *ngIf="!caseStakeholder.hasRole('REPORTER') && (!allowEdit() || !similarTo('REPORTER'))"
                 src="/assets/icons/Is Report OFF.svg"
                 ngbPopover="Reporter" [autoClose]="false" triggers="hover" openDelay="400" placement="top">
          <img class="icon-img-same" *ngIf=
            "caseStakeholder.hasRole('REPORTER') && !allowEdit()"
               src="/assets/icons/Is Report ON.svg" ngbPopover="Reporter" [autoClose]="false" triggers="hover" openDelay="400" placement="top">
        </label>

        <label class="iconBox" *ngIf="type === 'POLICYHOLDER'">
          <img [class]="mouseoverReporterIconOnPolicyHolder ? 'icon-img-hover': 'icon-img'" (click)="toggleStakeholderMerge('REPORTER', !caseStakeholder.enduserIsReporter)"
               *ngIf="!caseStakeholder.hasRole('REPORTER') && allowEdit() && similarTo('REPORTER')"
               [src]="mouseoverReporterIconOnPolicyHolder ? './assets/icons/Is Report ON.svg': './assets/icons/Is Report OFF.svg'"
               (mouseover)="mouseoverReporterIconOnPolicyHolder = true" (mouseout)="mouseoverReporterIconOnPolicyHolder = false"
               ngbPopover="Reporter" [autoClose]="false" triggers="hover" openDelay="400" placement="top">

          <img class="icon-img" (click)="toggleStakeholderMerge('REPORTER', !caseStakeholder.policyholderIsReporter)"
               *ngIf="caseStakeholder.hasRole('REPORTER') && allowEdit()" src="/assets/icons/Is Report ON.svg"
               ngbPopover="Reporter" [autoClose]="false" triggers="hover" openDelay="400" placement="top">

          <img class="icon-img-disabled" *ngIf="!caseStakeholder.hasRole('REPORTER') && (!allowEdit() || !similarTo('REPORTER'))"
               src="/assets/icons/Is Report OFF.svg"
               ngbPopover="Reporter" [autoClose]="false" triggers="hover" openDelay="400" placement="top">
          <img class="icon-img-same" *ngIf="caseStakeholder.hasRole('REPORTER') && !allowEdit()"
               src="/assets/icons/Is Report ON.svg" ngbPopover="Reporter" [autoClose]="false" triggers="hover" openDelay="400" placement="top">
        </label>

        <label class="iconBox" *ngIf="type === 'REPORTER'">
            <img class="icon-img-same" src="/assets/icons/Is Report ON.svg"
            ngbPopover="Reporter" [autoClose]="false" triggers="hover" openDelay="400" placement="top">
        </label>

        <label class="iconBox">
          <img [class]="mouseoverPrimaryContactChangeIcon ? 'icon-img-hover': 'icon-img'"
            *ngIf="!caseStakeholder.primaryContact && caseStakeholder.primaryContactChangeable && allowEdit()"
            [src]="mouseoverPrimaryContactChangeIcon ? './assets/icons/Primary Contact ON.svg': './assets/icons/Primary Contact OFF.svg'"
            (mouseover)="mouseoverPrimaryContactChangeIcon = true" (mouseout)="mouseoverPrimaryContactChangeIcon = false"
            ngbPopover="Primary Contact" [autoClose]="false" triggers="hover" #p="ngbPopover" (click)="onPrimaryContactClick(p)" openDelay="400" placement="top">

          <img class="icon-img" (click)="updatePrimaryContact()"
            *ngIf="caseStakeholder.primaryContact && caseStakeholder.primaryContactChangeable && allowEdit()" src="/assets/icons/Primary Contact ON.svg"
            ngbPopover="Primary Contact" [autoClose]="false" triggers="hover" #p="ngbPopover" (click)="p.open()" openDelay="400" placement="top">

          <img class="icon-img-same" *ngIf="caseStakeholder.primaryContact && caseStakeholder.primaryContactChangeable && !allowEdit()"
            src="/assets/icons/Primary Contact ON.svg"
            ngbPopover="Primary Contact" [autoClose]="false" triggers="hover" #p="ngbPopover" (click)="p.open()" openDelay="400" placement="top">

          <img class="icon-img-disabled" *ngIf="(!caseStakeholder.primaryContactChangeable || (!caseStakeholder.primaryContact && caseStakeholder.primaryContactChangeable && !allowEdit()))" src="/assets/icons/Primary Contact OFF.svg"
          ngbPopover="Primary Contact" [autoClose]="false" triggers="hover" #p="ngbPopover" (click)="p.open()" openDelay="400" placement="top">
        </label>

        <label class="iconBox">
          <img [class]="mouseoverLegalGuardianIcon ? 'icon-img-hover': 'icon-img'" (click)="updateLegalGuardian()"
            *ngIf="!caseStakeholder.legalGuardian && caseStakeholder.legalGuardianChangeable && allowEdit() && caseStakeholder.person"
            [src]="mouseoverLegalGuardianIcon ? '/assets/icons/Group 2063.svg': '/assets/icons/Group 2062.svg'"
            (mouseover)="mouseoverLegalGuardianIcon = true" (mouseout)="mouseoverLegalGuardianIcon = false"
            ngbPopover="Legal Guardian" [autoClose]="false" triggers="hover" #p="ngbPopover" (click)="p.open()" openDelay="400" placement="top">

          <img class="icon-img" (click)="updateLegalGuardian()"
            *ngIf="caseStakeholder.legalGuardian && caseStakeholder.legalGuardianChangeable && allowEdit()" src="/assets/icons/Group 2063.svg"
            ngbPopover="Legal Guardian" [autoClose]="false" triggers="hover" #p="ngbPopover" (click)="p.open()" openDelay="400" placement="top">

          <img class="icon-img-same"
          *ngIf="caseStakeholder.legalGuardian && caseStakeholder.legalGuardianChangeable && !allowEdit()" src="/assets/icons/Group 2063.svg"
          ngbPopover="Legal Guardian" [autoClose]="false" triggers="hover" #p="ngbPopover" (click)="p.open()" openDelay="400" placement="top">

          <img class="icon-img-disabled" *ngIf="(!caseStakeholder.legalGuardianChangeable || (!caseStakeholder.legalGuardian && caseStakeholder.legalGuardianChangeable && !allowEdit()) || caseStakeholder.company)"
          src="/assets/icons/Group 2062.svg" ngbPopover="Legal Guardian" [autoClose]="false" triggers="hover" #p="ngbPopover" (click)="p.open()" openDelay="400" placement="top">
        </label>

        <label class="iconBox" *ngIf="!caseStakeholder.isCompany">
          <img class="icon-img" *ngIf="caseStakeholder.address.protectedAddress" src="/assets/icons/lock.svg"
               ngbPopover="{{'case-basics-info-protected' | translate}}"
               [autoClose]="false" triggers="hover" openDelay="400">
          <img class="icon-img-disabled" *ngIf="!caseStakeholder.address.protectedAddress" src="/assets/icons/lock-off.svg"
               ngbPopover="{{'case-basics-info-not-protected' | translate}}"
               [autoClose]="false" triggers="hover" openDelay="400" placement="top">
        </label>

        <label class="iconBox" *ngIf="caseStakeholder.isCompany && type !== 'END_USER'">
          <img class="icon-img-disabled" src="/assets/icons/Co-traveller OFF.svg"
          ngbPopover="Co-Traveller" [autoClose]="false" triggers="hover" #p="ngbPopover" (click)="p.open()" openDelay="400" placement="top">
        </label>

        <label class="iconBox" *ngIf="!caseStakeholder.isCompany && type !== 'END_USER' && !caseStakeholder.hasRole('END_USER')">
            <img [class]="mouseoverCotravelerIcon ? 'icon-img-hover': 'icon-img'" (click)="updateCotraveler()"
              *ngIf="!caseStakeholder.coTraveller && allowEdit()"
              [src]="mouseoverCotravelerIcon ? './assets/icons/Co-traveller ON.svg': './assets/icons/Co-traveller OFF.svg'"
              (mouseover)="mouseoverCotravelerIcon = true" (mouseout)="mouseoverCotravelerIcon = false"
              ngbPopover="Co-Traveller" [autoClose]="false" triggers="hover" openDelay="400" placement="top">

            <img class="icon-img" (click)="updateCotraveler()"
              *ngIf="caseStakeholder.coTraveller && allowEdit()" src="/assets/icons/Co-traveller ON.svg"
              ngbPopover="Co-Traveller" [autoClose]="false" triggers="hover" openDelay="400" placement="top">

            <img class="icon-img-same"
            *ngIf="caseStakeholder.coTraveller && !allowEdit()" src="/assets/icons/Co-traveller ON.svg"
            ngbPopover="Co-Traveller" [autoClose]="false" triggers="hover" openDelay="400" placement="top">

            <img class="icon-img-disabled"
            *ngIf="!caseStakeholder.coTraveller && !allowEdit()" src="/assets/icons/Co-traveller OFF.svg"
            ngbPopover="Co-Traveller" [autoClose]="false" triggers="hover" openDelay="400" placement="top">
        </label>
      </div>
    </div>
</div>
