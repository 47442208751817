import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';
import { SettingsService } from './settings.service';
import { ServiceItemType, ServiceItemTypeAdapter } from '@secca/shared/models/service-item-type';
import { ServiceOrder, ServiceOrderAdapter } from '@secca/shared/models/service-order/service-order';
import { CaseLockHttpService } from '@secca/core/services/case-lock-http.service';
import { Recovery, RecoveryAdapter } from '@secca/shared/models/recovery';
import { RecoveryItem, RecoveryItemAdapter } from '@secca/shared/models/recovery-Item';

@Injectable({
  providedIn: 'root',
})
export class RecoveryService extends BaseService {
  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private serviceItemTypeAdapter: ServiceItemTypeAdapter,
    private recoveryAdapter: RecoveryAdapter,
    private recoveryItemAdapter: RecoveryItemAdapter,
    private serviceOrderAdapter: ServiceOrderAdapter,
    private caseLockHttpService: CaseLockHttpService
  ) {
    super(settingsService);
  }

  private caseRecoveryList: BehaviorSubject<Recovery[]> = new BehaviorSubject(null);

  public getServiceItemList(): Observable<ServiceItemType[]> {
    return this.http
      .get<ServiceItemType[]>(this.baseURL + 'masterlist/service-item-list')
      .pipe(map((data: any[]) => data.map(item => this.serviceItemTypeAdapter.adapt(item))));
  }

  public getServiceOrdersForRecovery(caseId: number): Observable<ServiceOrder[]> {
    return this.http.get<ServiceOrder[]>(this.baseURL + 'service-orders/by-case-for-recovery/' + caseId)
      .pipe(map((data: any[]) => data.map(item => this.serviceOrderAdapter.adapt(item))));
  }

  public createRecovery(recovery: Recovery): Observable<Recovery> {
    return this.http
      .post<Recovery>(this.baseURL + 'recovery', recovery, {
        headers: this.jsonHeaders,
      })
      .pipe(map(returnRecovery => this.recoveryAdapter.adapt(returnRecovery)));
  }

  public updateRecovery(recovery: Recovery): Observable<Recovery> {
    return this.caseLockHttpService
      .put<Recovery>(this.baseURL + 'recovery/' + recovery.id, recovery, {
        headers: this.jsonHeaders,
      })
      .pipe(map(returnRecovery => this.recoveryAdapter.adapt(returnRecovery)));
  }

  public getRecoveryById(recoveryId: number): Observable<Recovery> {
    return this.http.get<Recovery>(this.baseURL + 'recovery/' + recoveryId).pipe(map(item => this.recoveryAdapter.adapt(item)));
  }

  public cancelRecovery(recovery: Recovery): Observable<any> {
    return this.http.put<Recovery>(this.baseURL + 'recovery/cancel', recovery, {
        headers: this.jsonHeaders,
      });
  }

  public getRecoveryByCaseId(recoveryId: number): Observable<Recovery[]> {
    return this.http
      .get<Recovery[]>(this.baseURL + 'recovery/case/' + recoveryId, { headers: this.jsonHeaders })
      .pipe(map((data: any[]) => data.map(item => this.recoveryAdapter.adapt(item))));
  }

  public preview(recoveryId: number): Observable<any> {
    return this.http
      .post(this.baseURL + 'documents/recovery/' + recoveryId, '', {
        responseType: 'arraybuffer',
      })
      .pipe(
        map((res: any) => {
          return new Blob([res], {
            type: 'application/pdf',
          });
        })
      );
  }

  public store(recoveryId: number): Observable<any> {
    return this.http.post(this.baseURL + 'documents/recovery/store/' + recoveryId, '', { headers: this.jsonHeaders, responseType: 'text' });
  }

  public delete(recoveryId: number): Observable<any> {
    return this.http.delete<any>(this.baseURL + 'recovery/' + recoveryId);
  }

  public deleteRecoveryAndSetTaskToDone(recoveryId: number): Observable<any> {
    return this.http.delete<any>(this.baseURL + 'recovery/deleteRecoveryAndSetTaskToDone/' + recoveryId);
  }

  public deleteErrorRecovery(recoveryId: number, comment: string): Observable<any> {
    return this.http.put<any>(this.baseURL + 'recovery/error/' + recoveryId, comment);
  }

  public sendCaseRecoveryList(recovery: Recovery[]) {
    this.caseRecoveryList.next(recovery);
  }

  public getCaseRecoveryList(): Observable<Recovery[]> {
    return this.caseRecoveryList.asObservable();
  }

  public getFullRecoveryHistory(id: string): Observable<Recovery[]> {
    return this.http
      .get(this.baseURL + 'recovery/full-history/' + id, { headers: this.jsonHeaders })
      .pipe(map((data: any[]) => data.map(item => this.recoveryAdapter.adapt(item))));
  }

  public getFullRecoveryItemHistory(id: string): Observable<RecoveryItem[]> {
    return this.http
      .get(this.baseURL + 'recovery/full-item-history/' + id, { headers: this.jsonHeaders })
      .pipe(map((data: any[]) => data.map(item => this.recoveryItemAdapter.adapt(item))));
  }

  paymentInAs400(id: number) {
    return this.http
      .post(this.baseURL + 'recovery/payment-in-as400/' + id, { headers: this.jsonHeaders });
  }
}
