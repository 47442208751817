import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';

export class PurposeOfTravelItemType {
  id: number;  
  name: string;
  code: string;
  vat: boolean;
  uiSortIndex: number;
  activateOn: Date;
  deactivateOn: Date;

  public constructor(init?: Partial<PurposeOfTravelItemType>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
export class PurposeOfTravelItemTypeAdapter implements Adapter<PurposeOfTravelItemType> {
  adapt(item: any): PurposeOfTravelItemType {
    const deactivateOn = item.deactivateOn ? new Date(item.deactivateOn) : null;
    return new PurposeOfTravelItemType({
      id: item.id,
      name: item.purposeOfTravelName,
      code: item.purposeOfTravelCode,
      vat: item.vat,
      uiSortIndex: item.uiSortIndex,
      activateOn: new Date(item.activateOn),
      deactivateOn,
    });
  }
}
