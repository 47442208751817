import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
import * as moment from 'moment';
import { DaySerializer } from './day-serializer';
import { EventCategory } from './enums';


export class IncidentEvent {
  id: number;
  name: string;
  description: String;
  businessKey: String;
  uiSortIndex: number;
  active: boolean;
  category: EventCategory;
  dateOfEvent: moment.Moment;
  hiddenInEventDropDown: boolean

  public constructor(init?: Partial<IncidentEvent>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
export class IncidentEventAdapter implements Adapter<IncidentEvent> {
  adapt(item: any): IncidentEvent {
    return new IncidentEvent({
      id: item.id,
      name: item.name,
      description: item.description,
      businessKey: item.businessKey,
      uiSortIndex: item.uiSortIndex,
      active: item.active,
      category: item.category,
      dateOfEvent: DaySerializer.deserialize(item.dateOfEvent),
      hiddenInEventDropDown: item.hiddenInEventDropDown == null ? false : item.hiddenInEventDropDown,
    });
  }
}