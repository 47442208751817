<div class="card h-100">
  <div class="sub-component-placeholder" *ngIf="medicalAssessment">
    <div class="right-column">
      <div class="right-row">
        <div class="right-column-container assignee">
          <div class="text">
            {{'medical-assessment-service-order-assignee' | translate}}
          </div>
          <div *ngIf="isPreDeparture" class="right-column-container-radio-button">
            <label class="radio-button">
              <input type="checkbox" [checked]="medicalAssessment.assignee === assignee.PRE_DEPARTURE_MEDICAL_ADVICE"
                [disabled]="medicalAssessment.assignee === assignee.PRE_DEPARTURE_MEDICAL_ADVICE || medicalAssessment.assignedMedicalStaffId !== null || isPreDeparture" (change)="changeAssignee(assignee.PRE_DEPARTURE_MEDICAL_ADVICE)">
              <span class="checkmark"></span>
              <div class="text"  [ngClass]="{ 'text-disabled': editServiceOrder || medicalAssessment.assignedMedicalStaffId !== null }"> {{'medical-assessment-service-order-assignee-send-to-pre-departure-medical-advice' | translate}}</div>
            </label>
          </div>
          <div class="right-column-container-radio-button">
            <label class="radio-button">
              <input type="checkbox" [checked]="medicalAssessment.assignee === assignee.MEDICAL_ADVICE"
                [disabled]="medicalAssessment.assignee === assignee.MEDICAL_ADVICE || medicalAssessment.assignedMedicalStaffId !== null || isPreDeparture" (change)="changeAssignee(assignee.MEDICAL_ADVICE)">
              <span class="checkmark"></span>
              <div class="text"  [ngClass]="{ 'text-disabled': editServiceOrder || medicalAssessment.assignedMedicalStaffId !== null || isPreDeparture}"> {{'medical-assessment-service-order-assignee-send-to-medical-advise' | translate}}</div>
            </label>
          </div>
          <div class="right-column-container-radio-button">
            <label class="radio-button">
              <input type="checkbox" [checked]="medicalAssessment.assignee === assignee.MEDICAL_STAFF"
                [disabled]="medicalAssessment.assignee === assignee.MEDICAL_STAFF || medicalAssessment.assignedMedicalStaffId !== null || isPreDeparture" (change)="changeAssignee(assignee.MEDICAL_STAFF)">
              <span class="checkmark"></span>
              <div class="text"  [ngClass]="{ 'text-disabled': editServiceOrder || isPreDeparture}"> {{'medical-assessment-service-order-assignee-send-to-medical-staff' | translate}}</div>
            </label>
          </div>
        </div>
      </div>
      <div class="right-row" *ngIf="medicalAssessment.assignee === assignee.MEDICAL_STAFF">
        <div class="right-column-container">
          <app-drop-down [items]="medicalStaff" [name]="'medical-assessment-service-order-medical-staff' | translate"
            [(selectedItemId)]="medicalAssessment.assignedMedicalStaffId" (selectedItemIdChange)="medicalChange()"
            [disabled]="medicalAssessment.assignee !== assignee.MEDICAL_STAFF" [requiredRedBackground]="true">
          </app-drop-down>
        </div>
      </div>
      <div class="right-row" *ngIf="medicalAssessment.assignee === assignee.MEDICAL_STAFF">
        <div class="right-column-container assessment">
          <div class="text">
            {{'medical-assessment-service-order-assessment-type' | translate}}
          </div>
          <div *ngFor="let commitmentType of selectableCommitmentTypes" class="right-column-container-radio-button">
            <label class="radio-button">
              <input type="radio" [checked]="medicalAssessment.commitmentType && medicalAssessment.commitmentType.businessKey === commitmentType.businessKey"
                [disabled]="commitmentTypeDisabled(commitmentType)"
                (change)="changeAssessmentType(commitmentType)"
                name="medicalAssessment">
              <span class="checkmark"></span>
              <div class="text" [ngClass]="{ 'text-disabled': commitmentTypeDisabled(commitmentType)}" ngbPopover="{{'service-order-refund-claims-is-not-activated' | translate}}" triggers="hover" [disablePopover]="commitmentType.businessKey !== CommitmentTypes.CLAIMS_MEDICAL_REVIEW ||   claimsHandler || editServiceOrder">
                {{ commitmentType.name }}</div>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
