import { StakeholderTypeEnum } from '@secca/shared/models/enums';
import { StakeholderService } from 'src/app/core/services/stakeholder.service';
import { ServiceOrderHoverExtensionComponent } from '@secca/case/components/case-plans/plan/service-order-hover/service-order-hover-extension-component';
import {ServiceTypeEnum} from '@secca/shared/models/enums';
import {Component, Input, OnChanges} from '@angular/core';
import {ServiceOrderHoverModel} from '../service-order-hover/service-order-hover-model';
import {ColorGroupHtml} from '../service-order/color-group-enum';
import {ServiceOrderHelper} from '../service-order/service-order-helper';
import {CaseStakeholder} from '@secca/shared/models/caseStakeholder';

@Component({
  selector: 'app-new-service-order-hover',
  templateUrl: './new-service-order-hover.component.html',
  styleUrls: ['./new-service-order-hover.component.scss']
})
export class NewServiceOrderHoverComponent implements OnChanges{
  @Input() caseStakeholder: CaseStakeholder[];
  @Input() serviceOrderHoverModel: ServiceOrderHoverModel;

  private serviceBorderColor;
  private serviceBackgroundColor;

  private hoverContentComponent: ServiceOrderHoverExtensionComponent;

  constructor(
    private stakeholderService: StakeholderService
  ) {
  }

  ngOnChanges(): void {
      this.onRefresh();
  }

  get serviceOrderTypeName() {
    if (this.serviceOrderHoverModel == null) {
      return '';
    }
    const serviceType = this.serviceOrderHoverModel.serviceOrder.type.replace(/_/g, ' ');
    if (serviceType != null) {
      return serviceType.toString();
    } else {
      return '';
    }
  }

  get ServiceTypeEnum() {
    return ServiceTypeEnum;
  }

  get logoStyle(): any {
    if (this.serviceOrderHoverModel == null) {
      return null;
    }
    return {
      borderLeftColor: this.serviceBorderColor,
      backgroundColor: this.serviceBackgroundColor
    };
  }

  get imageStyle(): any {
    if (this.serviceOrderHoverModel == null) {
      return null;
    }
    return {
      backgroundColor: this.serviceBorderColor,
      backgroundImage: "url('/assets/icons/" + this.serviceOrderHoverModel.serviceTypeIcon + "')"
    };
  }


  private onRefresh() {
    if (this.serviceOrderHoverModel && this.serviceOrderHoverModel.serviceOrder) {
      const serviceOrderHelper = new ServiceOrderHelper(this.serviceOrderHoverModel.serviceOrder, []);
      const colorGroupForType = serviceOrderHelper.colorGroupForType();
      this.serviceBackgroundColor = ColorGroupHtml.getBackgroundColor(colorGroupForType);
      this.serviceBorderColor = ColorGroupHtml.getBorderColor(colorGroupForType);
    }
  }

  private createLocalStakeholder(caseStakeholder: CaseStakeholder): any {
    let type = caseStakeholder.stakeholderType;
    if (caseStakeholder.coTraveller) {
      type = StakeholderTypeEnum.coTraveller;
    }
    return {
      firstName: caseStakeholder.person.firstName != null ? caseStakeholder.person.firstName : '',
      surname: caseStakeholder.person.surname != null ? caseStakeholder.person.surname : '',
      icon: '/assets/icons/' + this.stakeholderService.addIconToCaseStakeholder(type as StakeholderTypeEnum) + '.svg',
      gender: caseStakeholder.person.gender,
      age: caseStakeholder.person.age
    };
  }
}