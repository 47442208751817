import {PolicyLookup} from '@secca/shared/models/policy-lookup-response';

export class PolicyLookupSelect {
  insuranceId: string;
  policy: PolicyLookup;

  constructor(insuranceId: string, policy: PolicyLookup) {
    this.insuranceId = insuranceId;
    this.policy = policy;
  }
}
