import { CaseDocumentTagEnum } from './enums';
import { Injectable } from '@angular/core';
import { Adapter } from '@secca/shared/interfaces/adapter';

export class CaseDocumentTag {
  name: CaseDocumentTagEnum;
  preTagged: boolean;
  public constructor(init?: Partial<CaseDocumentTag>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class CaseDocumentTagAdapter implements Adapter<CaseDocumentTag> {
  adapt(item: any): CaseDocumentTag {
    return new CaseDocumentTag({
      name: item.name,
      preTagged: item.preTagged !== null ? item.preTagged :  false
    });
  }
}

