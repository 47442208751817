import { DropdownDictionary, DropdownAdapter } from '@secca/shared/models/dropdownDictionary';
import { ServiceItemType, ServiceItemTypeAdapter } from '@secca/shared/models/service-item-type';
import { SettingsService } from './settings.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { BaseService } from './base.service';
import { SupplierInvoice, SupplierInvoiceAdapter } from 'src/app/shared/models/supplierInvoice';
import { SupplierExpensesAppendix, SupplierExpensesAppendixAdapter } from 'src/app/shared/models/supplierExpensesAppendix';
import { map } from 'rxjs/operators';
import { CaseLockHttpService } from './case-lock-http.service';
import { LockContextEnum } from '@secca/shared/enums/lock-context.enum';
import {SupplierInvoiceAutoApprovalDto, SupplierInvoiceAutoApprovalDtoAdapter} from '@secca/shared/models/supplierInvoiceAutoApprovalDto';

import { SupplierInvoiceItem, SupplierInvoiceItemAdapter } from '@secca/shared/models/supplierInvoiceItem';
import {
  BalanceSheetProgress,
  BalanceSheetAdapter
} from '@secca/shared/models/balance-sheet-event.model';
import { ErpCreditor } from "@secca/shared/models/erp-creditor";

@Injectable({
  providedIn: 'root',
})
export class SupplierInvoiceService extends BaseService {
  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private supplierInvoiceAdapter: SupplierInvoiceAdapter,
    private supplierInvoiceAutoApprovalDtoAdapter: SupplierInvoiceAutoApprovalDtoAdapter,
    private supplierExpenseAppendixAdapter: SupplierExpensesAppendixAdapter,
    private balanceSheetAdapter: BalanceSheetAdapter,
    private supplierInvoiceItemAdapter: SupplierInvoiceItemAdapter,
    private dropdownAdapter: DropdownAdapter,
    private serviceItemTypeAdapter: ServiceItemTypeAdapter,
    private caseLockHttpService: CaseLockHttpService
  ) {
    super(settingsService);
  }

  private caseSupplierInvoices: BehaviorSubject<SupplierInvoice[]> = new BehaviorSubject(null);
  private supplierInvoiceApproved = new Subject();

  public getServiceItemList(): Observable<ServiceItemType[]> {
    return this.http
      .get<ServiceItemType[]>(this.baseURL + 'masterlist/service-item-list')
      .pipe(map((data: any[]) => data.map(item => this.serviceItemTypeAdapter.adapt(item))));
  }

  public sendCaseSupplierInvoices(caseSupplierInvoices: SupplierInvoice[]) {
    this.caseSupplierInvoices.next(caseSupplierInvoices);
  }

  public getCaseSupplierInvoices(): Observable<SupplierInvoice[]> {
    return this.caseSupplierInvoices.asObservable();
  }

  public sendSupplierInvoiceApproved() {
    this.supplierInvoiceApproved.next(true);
  }

  public getSupplierInvoiceApproved() {
    return this.supplierInvoiceApproved.asObservable();
  }

  public getSupplierInvoice(id: number): Observable<SupplierInvoice> {
    return this.http
      .get(this.baseURL + 'supplier-invoices/' + id, { headers: this.jsonHeaders })
      .pipe(map(item => this.supplierInvoiceAdapter.adapt(item)));
  }

  public getSupplierInvoiceAutoApprovalDto(id: number): Observable<SupplierInvoiceAutoApprovalDto> {
    return this.http
      .get(this.baseURL + 'supplier-invoices/' + id + '/auto-approval-status', { headers: this.jsonHeaders })
      .pipe(map(item => this.supplierInvoiceAutoApprovalDtoAdapter.adapt(item)));
  }

  public resendSupplierInvoiceAutoApproval(id: number): Observable<SupplierInvoiceAutoApprovalDto> {
    return this.http
      .put(this.baseURL + 'supplier-invoices/' + id + '/auto-approval-status/reset', { headers: this.jsonHeaders })
      .pipe(map(item => this.supplierInvoiceAutoApprovalDtoAdapter.adapt(item)));
  }

  public markSupplierInvoiceAutoApprovalCorrect(supplierInvoiceId: number): Observable<Object> {
    return this.http.put(this.baseURL + 'supplier-invoices/' + supplierInvoiceId + '/auto-approval/correct', { headers: this.jsonHeaders });
  }

  public markSupplierInvoiceAutoApprovalIncorrect(supplierInvoiceId: number, comment: string): Observable<Object> {
    return this.http.put(this.baseURL + 'supplier-invoices/' + supplierInvoiceId + '/auto-approval/incorrect', comment, { headers: this.jsonHeaders });
  }

  public getSupplierInvoicesByCase(caseId: number): Observable<SupplierInvoice[]> {
    return this.http
      .get(this.baseURL + 'supplier-invoices/case/' + caseId, { headers: this.jsonHeaders })
      .pipe(map((data: any[]) => data.map(item => this.supplierInvoiceAdapter.adapt(item))));
  }

  public saveSupplierInvoice(supplierInvoice: SupplierInvoice): Observable<any> {
    return this.caseLockHttpService
      .withLockContext(LockContextEnum.SUPPLIER_INVOICE, supplierInvoice.id)
      .put(this.baseURL + 'supplier-invoices/' + supplierInvoice.id, supplierInvoice, { headers: this.jsonHeaders })
      .pipe(map(item => this.supplierInvoiceAdapter.adapt(item)));
  }

  public getCreditors(): Observable<DropdownDictionary[]> {
    return this.http.get<DropdownDictionary[]>(this.baseURL + 'supplier-invoices/creditors');
  }

  public getExpenseAppendixByCase(caseId: number): Observable<SupplierExpensesAppendix[]> {
    return this.http
      .get<SupplierExpensesAppendix[]>(this.baseURL + 'supplier-invoices/expenses-appendix/' + caseId, { headers: this.jsonHeaders })
      .pipe(map((data: any[]) => data.map(item => this.supplierExpenseAppendixAdapter.adapt(item))));
  }

  public getSupplierInvoiceHistory(id: number): Observable<BalanceSheetProgress> {
    return this.http
      .get(this.baseURL + 'supplier-invoices/history/' + id, { headers: this.jsonHeaders })
      .pipe(map((data: any) => this.balanceSheetAdapter.adapt(data)));
  }

  public getSupplierInvoiceFullHistory(caseId: string): Observable<SupplierInvoice[]> {
    return this.http
      .get(this.baseURL + 'supplier-invoices/full-history/' + caseId, { headers: this.jsonHeaders })
      .pipe(map((data: any[]) => data.map(item => this.supplierInvoiceAdapter.adapt(item))));
  }

  public getSupplierInvoiceFullItemHistory(caseId: string): Observable<SupplierInvoiceItem[]> {
    return this.http
      .get(this.baseURL + 'supplier-invoices/full-item-history/' + caseId, { headers: this.jsonHeaders })
      .pipe(map((data: any[]) => data.map(item => this.supplierInvoiceItemAdapter.adapt(item))));
  }

  resetFinanceBridgeStatus(id: number): Observable<void> {
    return this.http
      .post<void>(this.baseURL + 'supplier-invoices/' + id + '/reset-finance-bridge-status', { headers: this.jsonHeaders });
  }

  public getErpCreditor(erpId: string): Observable<ErpCreditor> {
    return this.http
      .get<ErpCreditor>(this.baseURL + 'erp-data/creditor/' + erpId, { headers: this.jsonHeaders });
  }
}
