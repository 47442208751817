<p *ngIf="stakeholder && ssnValidated; else blank" style="display: inline;">
  <img [appStakeholderTooltip]="stakeholder.id"
       src="../../../../../assets/stakeholder-icons/{{icon}}" alt="">
</p>

<ng-template #blank>
  <p style="display: inline;">
    <img src="../../../../../assets/stakeholder-icons/{{icon}}" alt="">
  </p>
</ng-template>
