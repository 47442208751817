import { TaskViewModel } from './../../../../board/components/board-task/models/task-view.model';
import { DateHelperService } from './../../../../../core/services/date-helper.service';
import { TaskStatus, TaskType } from '../../../../../shared/models/enums';
import { Adapter } from '../../../../../shared/interfaces/adapter';
import { Injectable } from '@angular/core';
import { DateSerializer } from '@secca/shared/models/date-serializer';
import * as moment from 'moment';
import { ChannelType } from '@secca/shared/enums/channelType';

export class SimpleTaskViewModel {
  id: string;
  caseId: string;
  caseNumber: string;
  dueDate: moment.Moment;
  title: string;
  description: string;
  assignInitials: string;
  status: TaskStatus;
  isCheck: boolean;
  isExtended: boolean;
  taskType: TaskType;
  channelType: ChannelType;
  invoiceId: number;
  priority: number;
  prioritySort: number;

  public constructor(init?: Partial<SimpleTaskViewModel>) {
    Object.assign(this, init);
  }

  convertToTaskViewModel(): TaskViewModel {
    return new TaskViewModel({
      id: this.id,
      caseId: +this.caseId,
      caseNumber: this.caseNumber,
      dueDate: this.dueDate,
      title: this.title,
      description: this.description,
      status: this.status,
      taskType: this.taskType,
      channelType: this.channelType,
      invoiceId: this.invoiceId,
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class SimpleTaskViewModelAdapter implements Adapter<SimpleTaskViewModel> {
  constructor(private dateHelperService: DateHelperService) {}

  adapt(item: any): SimpleTaskViewModel {
    return new SimpleTaskViewModel({
      id: item.id,
      caseId: item.caseId,
      caseNumber: item.caseNumber,
      dueDate: DateSerializer.deserialize(item.dueDate),
      title: item.title,
      description: item.description,
      assignInitials: item.assignInitials,
      status: item.status,
      isCheck: item.isCheck,
      isExtended: false,
      taskType: item.type,
      channelType: item.channelType,
      invoiceId: item.invoiceId,
      priority: item.priority !== null ? item.priority : '',
      prioritySort: item.prioritySort
    });
  }
}
