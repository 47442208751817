import { Component, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { CauseItemType } from '@secca/shared/models/cause-item-type';
import { PurposeOfTravelItemType } from '@secca/shared/models/purpose-of-travel-item-type';
import { ServiceItemType } from '@secca/shared/models/service-item-type';
import { SimpleCustomerProfile } from '@secca/shared/models/simpleCustomerProfile';
import { IncomingCasesTaskViewModel } from '../../../models/incoming-cases-task-view-model';
import { StakeholderTypeEnum } from '@secca/shared/models/enums';
import { Diagnosis } from '@secca/shared/models/dignosis';

@Component({
  selector: 'app-task-web-bank-details-panel',
  templateUrl: './task-web-bank-details-panel.component.html',
  styleUrls: ['./task-web-bank-details-panel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TaskWebBankDetailsPanelComponent implements OnChanges {
  // Ignored properties
  ignoredProperties = ['id', 'properties', 'stakeholderType', 'nationalIdValid', 'isCompany'];

  @Input() taskDataViewModel: IncomingCasesTaskViewModel;
  @Input() serviceItemList: ServiceItemType[];
  @Input() purposeOfTravelItemList: PurposeOfTravelItemType[];
  @Input() causes: Map<string, CauseItemType[]> = new Map<string, CauseItemType[]>();
  @Input() diagnoses: Diagnosis[];
  @Input() customerProfiles: SimpleCustomerProfile[];

  isEndUser = false;
  isPolicyHolder = false;
  isReporter = false;

  constructor() {}

  ngOnChanges(): void {
    if ( this.taskDataViewModel ) {
      this.getRelevantInformation();
    }
  }

  private getRelevantInformation() {
    this.isEndUser = (this.taskDataViewModel?.data?.refund?.receiverOfRefund?.stakeholderType === StakeholderTypeEnum.endUser) ||
      this.taskDataViewModel?.data?.refund?.receiverOfRefund?.properties?.includes(StakeholderTypeEnum.endUser);

    this.isReporter = (this.taskDataViewModel?.data?.refund?.receiverOfRefund?.stakeholderType === StakeholderTypeEnum.reporter) ||
      this.taskDataViewModel?.data?.refund?.receiverOfRefund?.properties?.includes(StakeholderTypeEnum.reporter);

    this.isPolicyHolder = (this.taskDataViewModel?.data?.refund?.receiverOfRefund?.stakeholderType === StakeholderTypeEnum.policyHolder) ||
      this.taskDataViewModel?.data?.refund?.receiverOfRefund?.properties?.includes(StakeholderTypeEnum.policyHolder);
  }
}
