import { Component, OnInit, Input } from '@angular/core';
import { SubReference } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subReference';

@Component({
  selector: 'app-sub-reference',
  templateUrl: './sub-reference.component.html',
  styleUrls: ['./sub-reference.component.scss'],
})
export class SubReferenceComponent implements OnInit {
  @Input() subReference: SubReference;
  @Input() readOnlyMode: boolean;

  constructor() {}

  ngOnInit() {}
}
