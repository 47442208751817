<div class="sub-component-container" *ngIf="subRemarksRefund">
  <div class="sub-title">{{'sub-remarks-to-supplier-title' | translate}}</div>
  <div class="sub-content-container">
    <div class="row">
      <div class="col-12">
        <app-text-box [disabled]="readOnlyMode" [name]="'sub-remarks-refund' | translate" [maxTextLength]="1000"
                      [(value)]="subRemarksRefund.remarks"
                      [largeView]="true"
                      [required]="true"></app-text-box>
      </div>
    </div>
  </div>
</div>
