<div class='d-flex justify-content-between modal-header background-colour'>
  <div class='modal-header-title'>
    {{ 'service-order-view-supplier-title' | translate }}
  </div>
  <div class='modal-buttons'>
    <div (click)='close()'>
      <img src='/assets/icons/Close White.svg' alt='Close window' />
    </div>
  </div>
</div>

<div class='scroll'>
  <div class='pb-1 px-2 pt-2 background-colour'>
    <div class=''>
      <div *ngIf='foundAnySuppliers; else noDataOrErrorTemplate'>
        <div *ngFor='let supplier of suppliers'>
          <app-supplier-info [supplier]='supplier' callFromStakeholder='true' class='body'></app-supplier-info>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingTemp>
  <div class='d-flex justify-content-center vh-100'>
    <div class='spinner-border' role='status'></div>
  </div>
</ng-template>
<ng-template #noDataFoundtemp>
  <div class='justify-content-center'>
    <div class='m-5 h4 '> No data found</div>
    <div class='m-5 h5 text-info'>{{ message }}</div>
  </div>
</ng-template>

<ng-template #errorTemp>
  <div class='justify-content-center'>
    <div class='m-5 h4'> Error on connection</div>
    <div class='m-5 h5 text-info'> {{ message }}</div>
  </div>
</ng-template>

<ng-template #noDataOrErrorTemplate>
  <ng-container *ngIf='isErrors'>
    <ng-container *ngTemplateOutlet='errorTemp'></ng-container>
  </ng-container>
  <ng-container *ngIf='noDataFound'>
    <ng-container *ngTemplateOutlet='noDataFoundtemp'></ng-container>
  </ng-container>
  <ng-container *ngIf='isLoading'>
    <ng-container *ngTemplateOutlet=loadingTemp></ng-container>
  </ng-container>
</ng-template>
