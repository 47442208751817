import { EventEmitter, Injectable, Output, Directive } from '@angular/core';
import { CaseStakeholder } from 'src/app/shared/models/caseStakeholder';
import { CaseService } from 'src/app/core/services/case.service';
import { PhoneNumberType } from '@secca/shared/models/enums';
import { StakeholderService } from 'src/app/core/services/stakeholder.service';

@Directive()
@Injectable({
  providedIn: 'root',
})
export class CasePrimaryContactService {
  isPrimaryContact: boolean;
  fullName: string;
  contact: string;
  prefix: string;
  countryCode: string;
  roleName: string;
  isReporter: boolean;
  isPolicyHolder: boolean;
  isEndUser: boolean;
  isGuardian: boolean;
  isCotraveller: boolean;
  currentPrimaryContactStakeholderType: string = null;

  @Output() change: EventEmitter<any> = new EventEmitter();

  constructor(private caseService: CaseService, private stakeholderService: StakeholderService) {}

  private processStakeholderUpdated(role: string, caseStakeholder: CaseStakeholder) {
    if (caseStakeholder == null) {
      this.clearPrimaryContactData();
      this.change.emit();
      return;
    }

    if (this.isPrimaryContact) {
      if (this.currentPrimaryContactStakeholderType === role) {
        if (caseStakeholder.primaryContact) {
          this.updatePrimaryContact(role, caseStakeholder);
        } else {
          this.clearPrimaryContactData();
        }
        this.change.emit();
      } else {
        return;
      }
    } else {
      if (caseStakeholder.primaryContact) {
        this.isPrimaryContact = true;
        this.updatePrimaryContact(role, caseStakeholder);
        this.change.emit();
      }
    }
  }

  private updatePrimaryContact(role: string, caseStakeholder: CaseStakeholder) {
    this.currentPrimaryContactStakeholderType = role;

    if (caseStakeholder.person) {
      this.setPropertiesFromPerson(caseStakeholder);
    }
    if (caseStakeholder.company) {
      this.setPropertiesFromCompany(caseStakeholder);
    }

    if (this.contact) {
      this.contact = (this.prefix ? this.prefix + ' ' : '') + this.contact;
    }

    this.isGuardian = caseStakeholder.legalGuardian;
    this.isCotraveller = caseStakeholder.coTraveller;
    if (role === 'END_USER') {
      this.roleName = 'enduser';
      this.isPolicyHolder = caseStakeholder.enduserIsPolicyholder;
      this.isReporter = caseStakeholder.enduserIsReporter;
      this.isEndUser = false;
    } else if (role === 'POLICYHOLDER') {
      this.roleName = 'policyholder';
      this.isReporter = caseStakeholder.policyholderIsReporter;
      this.isEndUser = caseStakeholder.enduserIsPolicyholder;
      this.isPolicyHolder = false;
    } else if (role === 'REPORTER') {
      this.roleName = 'reporter';
      this.isPolicyHolder = caseStakeholder.policyholderIsReporter;
      this.isEndUser = caseStakeholder.enduserIsReporter;
      this.isReporter = false;
    }
  }

  private setPropertiesFromPerson(caseStakeholder: CaseStakeholder) {
    this.fullName = [caseStakeholder.person.firstName, caseStakeholder.person.surname].filter(Boolean).join(' ') || '';
    this.setPrefixAndContactFromPreferredChannel(caseStakeholder.person.preferredChannelCode, caseStakeholder);
    this.countryCode = this.getCountryCodeOrReturnDefault(caseStakeholder.person.preferredLanguageCode);
  }

  private setPropertiesFromCompany(caseStakeholder: CaseStakeholder) {
    this.fullName = caseStakeholder.company.name;
    this.setPrefixAndContactFromPreferredChannel(caseStakeholder.company?.gop?.preferredChannelCode, caseStakeholder);
    this.countryCode = this.getCountryCodeOrReturnDefault(caseStakeholder.company?.gop?.preferredLanguageCode);
  }

  private setPrefixAndContactFromPreferredChannel(preferredChannel: string, caseStakeholder: CaseStakeholder) {
    if (preferredChannel === 'Fixed phone') {
      this.prefix = this.stakeholderService.getPhoneNumber(PhoneNumberType.Fixed, caseStakeholder.phoneNumbers).prefix;
      this.contact = this.stakeholderService.getPhoneNumber(PhoneNumberType.Fixed, caseStakeholder.phoneNumbers).number;
    } else if (preferredChannel === 'Mobile phone' || preferredChannel === 'SMS') {
      this.prefix = this.stakeholderService.getPhoneNumber(PhoneNumberType.Mobile, caseStakeholder.phoneNumbers).prefix;
      this.contact = this.stakeholderService.getPhoneNumber(PhoneNumberType.Mobile, caseStakeholder.phoneNumbers).number;
    } else if (preferredChannel === 'Fax') {
      this.prefix = this.stakeholderService.getPhoneNumber(PhoneNumberType.Fax, caseStakeholder.phoneNumbers).prefix;
      this.contact = this.stakeholderService.getPhoneNumber(PhoneNumberType.Fax, caseStakeholder.phoneNumbers).number;
    } else if (preferredChannel === 'Email') {
      this.prefix = '';
      this.contact = caseStakeholder?.person?.email || caseStakeholder?.company?.email;
    } else {
      this.prefix = '';
      this.contact = null;
    }
  }

  clearPrimaryContactData() {
    this.isPrimaryContact = false;
    this.fullName = null;
    this.contact = null;
    this.prefix = null;
    this.countryCode = null;
    this.roleName = null;
    this.isReporter = false;
    this.isPolicyHolder = false;
    this.isEndUser = false;
    this.isGuardian = false;
    this.isCotraveller = false;
    this.currentPrimaryContactStakeholderType = null;
  }

  updatePrimary(role: string, caseStakeholder: CaseStakeholder) {
    this.processStakeholderUpdated(role, caseStakeholder);
  }

  getCountryCodeOrReturnDefault(preferredLanguageCode: string): string {
    const countryCode = preferredLanguageCode;
    if (countryCode === 'EN') {
      return 'GB';
    }
    return countryCode;
  }
}
