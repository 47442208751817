<div [appDisableInput]="caseStateService.isCaseDisabled()">
  <div class="pinned-buttons-container">
    <div class="action-button fax-button" (click)="openMessageModal(MessageChannelType.FAX)"
      [class.fax-button-marked]="existsMessage(MessageChannelType.FAX)"
      [class.fax-button-disabled]="disablePinnedButtons"></div>
    <div class="action-button email-button" (click)="openMessageModal(MessageChannelType.EMAIL)"
      [class.email-button-marked]="existsMessage(MessageChannelType.EMAIL)"
      [class.email-button-disabled]="disablePinnedButtons">
    </div>
    <div class="action-button sms-button" (click)="openMessageModal(MessageChannelType.SMS)"
      [class.sms-button-marked]="existsMessage(MessageChannelType.SMS)"
      [class.sms-button-disabled]="disablePinnedButtons">
      </div>
  </div>
</div>
