import { Component, OnInit } from '@angular/core';
import { BoardSelectedMenuEnum } from './../../../../shared/enums/board-selected-menu-enum';
import { MenuService } from './../../../../core/services/menu.service';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { BoardShareService } from '@secca/core/services/board-share.service';

@Component({
  selector: 'app-board-page',
  templateUrl: './board-page.component.html',
  styleUrls: ['./board-page.component.scss']
})
export class BoardPageComponent implements OnInit {

  selectedMenuNumber: BoardSelectedMenuEnum;
  get BoardSelectedMenuEnum() {
    return BoardSelectedMenuEnum;
  }

  constructor(private menuService: MenuService,
              private caseLockHelperService: CaseLockHelperService,
              private caseStateService: CaseStateService,
              private boardShareService: BoardShareService,) { }

  ngOnInit() {
    this.caseLockHelperService.resetCaseLocks();
    this.caseStateService.isCaseClosed = false;
    this.caseStateService.isInvoiceClosedWithNoInvoicingToCustomer = false;
    if (this.menuService.getSelectedBoardMenuId() === undefined) {
      this.selectedMenuNumber = BoardSelectedMenuEnum.Task;
    } else {
      this.selectedMenuNumber = this.menuService.getSelectedBoardMenuId();
    }
  }

  onMenuClick(event: BoardSelectedMenuEnum) {
    if(event !== 1) {
      this.boardShareService.changedCaseEventMenu(false);
    }
    this.selectedMenuNumber = event;
  }
}
