<div class="alert-box-container" (click)="toggleDetails()">
  <ngb-alert [dismissible]="false" [type]="type">
    <div>
      <div class="alert-title">
        <span><strong>{{title}}</strong></span>
        <img src="../../../../assets/icons/ArrowDown.svg">
      </div>
      <div  *ngIf="showDetails" class="alert-details">
      <ng-content></ng-content>
      </div>
    </div>
  </ngb-alert>
</div>
