import { Component, Input, OnInit } from '@angular/core';
import { SubAirwayBill } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subAirwayBill';

@Component({
  selector: 'app-sub-airway-bill',
  templateUrl: './sub-airway-bill.component.html',
  styleUrls: ['./sub-airway-bill.component.scss']
})
export class SubAirwayBillComponent implements OnInit {
  @Input() subAirwayBill: SubAirwayBill;

  constructor() { }

  ngOnInit() {
  }

}
