import { Component, Inject, ViewChild } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { DialogBoundryService } from "@secca/core/services/dialog-boundry.service";
import { DialogHelperUtilService } from "@secca/core/services/dialog-helper-util.service";
import { DialogMinimizableDirective } from "@secca/shared/directives/dialog-minimizable.directive";
import { MinimizableDialogType } from "@secca/shared/enums/minimizable-dialog-type-enum";
import { IAdditionalInfo, TaskAdditionalInfo } from "@secca/shared/interfaces/additional-info";
import { IKeyboardEnabled } from "@secca/shared/interfaces/keyboard-enabled";
import { IMinimizable } from "@secca/shared/interfaces/minimizable";
import { TaskConsentModalComponent } from "../task-consent-modal/task-consent-modal.component";
import { TaskConsentDialogData } from "./task-consent-dialog-data.component";

@Component({
    selector: 'app-task-consent-dialog',
    templateUrl: './task-consent-dialog.component.html',
    styleUrls: ['./task-consent-dialog.component.scss'],
  })
  export class TaskConsentDialogComponent implements IAdditionalInfo, IMinimizable, IKeyboardEnabled {
    @ViewChild(TaskConsentModalComponent) wrappedInstance: TaskConsentModalComponent;
    @ViewChild(DialogMinimizableDirective) minimizedDirective: DialogMinimizableDirective;

    data: TaskConsentDialogData;

    constructor(private dialogRef: MatDialogRef<TaskConsentDialogComponent>,
                @Inject(MAT_DIALOG_DATA) data: TaskConsentDialogData,
                private dialogHelperUtilService: DialogHelperUtilService,
                public dialogBoundryService: DialogBoundryService) {
              
      this.data = data;
    }

    close() {
      this.dialogHelperUtilService.close(this.dialogRef, this.getAdditionalInfo()?.caseId);
    }

    keyboardClose(): void {
      this.wrappedInstance.close();
    }

    minimize() {
      this.minimizedDirective.minimize();
    }

    getMinimizeType(): MinimizableDialogType {
      return this.minimizedDirective.appDialogMinimizable;
    }

    getAdditionalInfo(): TaskAdditionalInfo {
      const caseId: number = this.wrappedInstance?.taskViewModel?.caseId;
      const caseNumber: string = this.wrappedInstance?.taskViewModel?.caseNumber;
      const taskTitle: string = this.wrappedInstance?.taskViewModel?.title;
      const taskType = this.wrappedInstance?.taskViewModel?.taskType;
      const taskStatus = this.wrappedInstance?.taskViewModel?.status;

      return {
        caseId,
        hoverTitle: caseNumber || '',
        hoverText: taskTitle || '',
        taskType,
        taskStatus
      };
    }

    get MinimizableDialogType() {
      return MinimizableDialogType;
    }

    taskUpdated(): void {
      if(this.data.taskUpdated !== undefined){
        this.data.taskUpdated();
      }
    }
}
