import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AttachmentDto } from "@secca/shared/models/attachment-dto";
import { CaseDocument } from "@secca/shared/models/caseDocument";
import { CaseHistoryCommEntry } from "@secca/shared/models/caseHistoryEntry";

@Component({
  selector: 'app-case-summary-note-modal',
  templateUrl: './case-summary-note-modal.component.html',
  styleUrls: ['./case-summary-note-modal.component.scss'],
})
export class CaseSummaryNoteModalComponent implements OnInit {
  @Input()
  entry: CaseHistoryCommEntry;

  @Input()
  documents: CaseDocument[]

  @Output()
  closed: EventEmitter<void> = new EventEmitter();

  attachments: AttachmentDto[] = [];

  constructor() {}

  ngOnInit() {
    this.initialise();
  }

  close(): void {
    this.closed.emit();
  }

  private initialise(): void {
    this.attachments = this.documents.map(document => {
      return new AttachmentDto({
        documentId: document.documentId,
        documentName: this.extractDocumentName(document.documentName),
        documentExtension: document.documentExtension ? document.documentExtension : this.extractDocumentExtension(document.documentName)
      });
    })
  }

  private extractDocumentExtension(documentName: string): string {
    const lastIndexOfDot = documentName.lastIndexOf('.');
    return lastIndexOfDot >= 0 ? documentName.substring(lastIndexOfDot + 1) : '';
  }

  private extractDocumentName(documentName: string): string {
    const lastIndexOfDot = documentName.lastIndexOf('.');
    return lastIndexOfDot >= 0 ? documentName.substring(0, lastIndexOfDot) : documentName;
  }
}