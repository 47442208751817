<div class="properties">
  <div *ngFor="let key of taskWebPropertiesService.getPropertyKeys(properties, ignoredProperties)">
    <div *ngIf="taskWebPropertiesService.isShowProperty(properties, key)">
      <div class="property-container">
        <div class="property-name">{{'task-web-properties-' + key | translate}}:</div>
        <div class="property-value-container"
          *ngIf="taskWebPropertiesService.isStringProperty(properties, key) || taskWebPropertiesService.isTypeProperty(properties, key)">
          <div [ngSwitch]="getPropertyDisplayType(key)">
            <span *ngSwitchCase="DisplayTypeEnum.FORMATTED_TEXT">
              <pre
                class="property-value">{{taskWebPropertiesService.getPropertyValue(properties, key, serviceItemList, purposeOfTravelItemList, causes, diagnoses, customerProfiles)}}</pre>
            </span>
            <span *ngSwitchDefault class="property-value">
              {{taskWebPropertiesService.getPropertyValue(properties, key, serviceItemList, purposeOfTravelItemList, causes, diagnoses, customerProfiles)}}
            </span>
          </div>
        </div>
        <div class="property-value-container" *ngIf="taskWebPropertiesService.isBooleanProperty(properties, key)">
          <span
            *ngIf="taskWebPropertiesService.getPropertyValue(properties, key, serviceItemList, purposeOfTravelItemList, causes, diagnoses, customerProfiles)">Yes</span>
          <span
            *ngIf="!taskWebPropertiesService.getPropertyValue(properties, key, serviceItemList, purposeOfTravelItemList, causes, diagnoses, customerProfiles)">No</span>
        </div>
      </div>
      <div *ngIf="taskWebPropertiesService.isArrayProperty(properties, key)">
        <div *ngFor="let nested of taskWebPropertiesService.getArrayProperties(properties, key)">
          <div class="nested" *ngFor="let key of taskWebPropertiesService.getPropertyKeys(nested)">
            <div class="property-container" *ngIf="taskWebPropertiesService.isShowProperty(nested, key)">
              <div *ngIf="key !== '-'" class="property-name">{{'task-web-properties-' + key | translate}}:</div>
              <div *ngIf="key === '-'" class="property-name">-</div>
              <div class="property-value-container">
                <div [ngSwitch]="getPropertyDisplayType(key)">
                  <span *ngSwitchCase="DisplayTypeEnum.FORMATTED_TEXT">
                    <pre
                      class="property-value">{{taskWebPropertiesService.getPropertyValue(nested, key, serviceItemList, purposeOfTravelItemList, causes, diagnoses, customerProfiles)}}</pre>
                  </span>
                  <span *ngSwitchDefault class="property-value">
                    {{taskWebPropertiesService.getPropertyValue(nested, key, serviceItemList, purposeOfTravelItemList, causes, diagnoses, customerProfiles)}}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="showNestedProperties && taskWebPropertiesService.isNestedProperty(properties, key)">
        <div class="nested" *ngFor="let property of taskWebPropertiesService.getNestedProperties(properties, key)">
          <app-task-web-properties [properties]="property" [ignoredProperties]="ignoredProperties" [showEmptyProperties]="showEmptyProperties" 
                                   [serviceItemList]="serviceItemList" [purposeOfTravelItemList]="purposeOfTravelItemList" [causes]="causes" [diagnoses]="diagnoses"
                                   [customerProfiles]="customerProfiles"></app-task-web-properties>
        </div>
      </div>
    </div>
  </div>
</div>
