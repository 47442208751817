<div class="form-container" *ngIf="message">
  <div class="container-fluid">
    <div class="d-flex">
      <div class="ps-2 pe-2 col-width">
        <app-drop-down [name]="'message-form-from' | translate" [items]="messageFromAddresses" [disabled]="emailFieldDisabled"
        [(selectedItemId)]="message.messageFrom"></app-drop-down>
      </div>
      <div class="ps-2 pe-2 col-width">
        <app-message-form-receiver [takeStakeholderFromGopContact]="takeStakeholderFromGopContact"
        [message]="message"
          [name]="'message-form-receiver' | translate"
          [stakeholders]="stakeholders" [showFullName]="true"
          [(selectedItemId)]="message.receiver" (selectedItemIdChange)=(updateMessageStakeholderFromDropDown($event))>
          </app-message-form-receiver>
      </div>
      <div class="ps-2 pe-2 col-width">
        <app-phone-drop-down *ngIf="message.messageChannelType === MessageChannelType.SMS" [name]="'message-form-send-to' | translate" [withPrefixInput]="true" [withInput]="true" [showCode]="true"
          [(selectedItemId)]="message.sms.countryCode"  (selectedItemIdChange)="updateMessageSmsCountry($event)"
          [(prefixValue)]="message.sms.phonePrefix" [(value)]="message.sms.phoneNumber"
          (saveModel)="updateMessageSms($event)">
        </app-phone-drop-down>
        <app-phone-drop-down *ngIf="message.messageChannelType === MessageChannelType.FAX" [name]="'message-form-send-to' | translate" [withPrefixInput]="true" [withInput]="true" [showCode]="true"
          [(selectedItemId)]="message.fax.countryCode"
          [(prefixValue)]="message.fax.faxPrefix" [(value)]="message.fax.faxNumber"
          (saveModel)="updateMessageFax($event)">
        </app-phone-drop-down>
        <app-input *ngIf="message.messageChannelType === MessageChannelType.EMAIL"
        [name]="'message-form-send-to' | translate" [(value)]="message.email" (selectedItemIdChange)="updateMessage()"
        [validationType]="validationType"
        [validate]="true"
        [(isValid)]="isMessageValid">
        </app-input>
      </div>
    </div>
    <div class="row-separator"></div>
    <div class="d-flex">
      <div class="ps-2 pe-2 fixed-width-200">
        <app-drop-down [name]="'message-form-channel' | translate" [items]="messageChannelTypes" [disabled]="message.channelDisabled"
          [(selectedItemId)]="message.messageChannelType" (selectedItemIdChange)="updateMessageChannelType($event)"></app-drop-down>
      </div>
      <div class="ps-2 pe-2 w-100">
        <app-drop-down [name]="'message-form-template' | translate" [items]="mappedMessageTemplates" [(selectedItemId)]="message.templateId"
          [disabled]="!message.receiver" (selectedItemIdChange)="setTemplateLanguagesPreferredLanguage($event)">
        </app-drop-down>
      </div>
      <div class="ps-2 pe-2 fixed-width-200">
        <app-drop-down [name]="'message-form-language' | translate" [items]="languages" [(selectedItemId)]="message.language"
          [disabled]="!message.templateId" (selectedItemIdChange)="setTemplateMessageLanguage()">
        </app-drop-down>
      </div>
    </div>
  </div>
</div>
