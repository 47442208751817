import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-policy-lookup-contact',
  templateUrl: './policy-lookup-contact.component.html',
  styleUrls: ['./policy-lookup-contact.component.scss']
})
export class PolicyLookupContactComponent {
  @Input() contactDetails: string;
}
