import { Injectable } from '@angular/core';
import { Adapter } from '@secca/shared/interfaces/adapter';
import { CustomerInvoiceJobStatusEnum, FinancialStatusEnum, SalesOrderStatusEnum } from '@secca/shared/models/enums';
import * as moment from 'moment';

export class CustomerInvoiceJobSalesOrder {
  caseId: number;
  salesOrderId: number;
  salesOrderCreatedOn: moment.Moment;
  customerProfileId: number;
  customerProfileName: string;
  caseNumber: string;
  debtorNumber: string;
  erpClient: string;
  invoiceNo: string;
  amountExclVAT: number;
  amountInclVAT: number;
  invoiceCurrency: string;
  salesOrderStatus: SalesOrderStatusEnum;
  caseFinancialStatus: FinancialStatusEnum;
  jobStatus: CustomerInvoiceJobStatusEnum;
  jobDate: moment.Moment;
  customerInvoiceNote: string;
  details: CustomerInvoiceJobSalesOrder[] = null;
  acknowledgeMessage: string;
  retryCount: number;
  isCheck: boolean;
  public constructor(init?: Partial<CustomerInvoiceJobSalesOrder>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
export class CustomerInvoiceJobSalesOrderAdapter implements Adapter<CustomerInvoiceJobSalesOrder> {
  adapt(item: any): CustomerInvoiceJobSalesOrder {
    return new CustomerInvoiceJobSalesOrder({
      caseId: item.caseId,
      salesOrderId: item.salesOrderId,
      salesOrderCreatedOn: moment.utc(item.salesOrderCreatedOn),
      customerProfileId: item.customerProfileId,
      customerProfileName: item.customerProfileName,
      caseNumber: item.caseNumber,
      debtorNumber: item.debtorNumber,
      erpClient: item.erpClient,
      invoiceNo: item.invoiceNo,
      amountExclVAT: item.amountExclVAT,
      amountInclVAT: item.amountInclVAT,
      invoiceCurrency: item.invoiceCurrency,
      salesOrderStatus: SalesOrderStatusEnum[item.salesOrderStatus],
      caseFinancialStatus: FinancialStatusEnum[item.caseFinancialStatus],
      jobStatus: CustomerInvoiceJobStatusEnum[item.jobStatus],
      jobDate: moment.utc(item.jobDate),
      customerInvoiceNote: item.customerInvoiceNote,
      acknowledgeMessage: item.acknowledgeMessage,
      retryCount: item.retryCount,
    });
  }
}
