

<div class="hover-container-content-stakeholder-section" *ngFor=" let stakeholder of stakeholders">
  <div class="stakeholder-icon">
    <img class="icon" [src]="stakeholder.icon">
  </div>
  <div class="stakeholder-name">
    {{ stakeholder.firstName }} {{ stakeholder.surname }}
  </div>
  <div *ngIf="stakeholder.gender || stakeholder.age" class="stakeholder-gender-age">
    ({{ stakeholder.gender }} {{ stakeholder.age }})
  </div>
</div>
<hr>
<div class="hover-container-content-section-title">
  Supplier
</div>
<div class="hover-container-content-bold">
  {{providerName}}
</div>
<div class="hover-container-content-section-title"  *ngIf="agentName">
  Via
</div>
<div class="hover-container-content-bold" *ngIf="agentName">
  {{agentName}}
</div>

<hr>
<div class="hover-container-content-section-title">
  Start
</div>
<div class="hover-container-content-bold" *ngIf="!serviceOrder.extension.isMultipleDurations() && this.serviceOrder.getStartLocation()?.country">
  <span>{{ serviceOrder.getStartDisplayDate() ? (serviceOrder.getStartDisplayDate() | momentFormat: 'DD MMM YYYY HH:mm':getStartTimeZone()) : '-- : --' }}</span>
  <span>&nbsp;({{getStartCountry()}})</span>
</div>
<div class="hover-container-content-bold" *ngIf="serviceOrder.extension.isMultipleDurations()">
  Multiple durations
</div>
<div class="hover-container-content-italic" *ngIf="!serviceOrder.extension.isMultipleDurations()">
  <span>{{ serviceOrder.getStartDisplayDate() ? (serviceOrder.getStartDisplayDate() | momentFormat: 'DD MMM YYYY HH:mm':'local') : '-- : --' }}<span>&nbsp;Denmark</span></span>
</div>
<div class="hover-container-content-section-title">
  End
  </div>
  <div class="hover-container-content-bold" *ngIf="!serviceOrder.extension.isMultipleDurations() && this.serviceOrder.getEndLocation()?.country">
    <span>{{ serviceOrder.getEndDisplayDate() ? (serviceOrder.getEndDisplayDate() | momentFormat: 'DD MMM YYYY HH:mm':serviceOrder.getEndLocation()?.timeZoneId) : '-- : --' }}</span>
    <span>&nbsp;({{serviceOrder.getEndLocation()?.country}})</span>
  </div>
  <div class="hover-container-content-bold" *ngIf="serviceOrder.extension.isMultipleDurations()">
    Multiple durations
  </div>
  <div class="hover-container-content-italic" *ngIf="!serviceOrder.extension.isMultipleDurations()">
    <span>{{ serviceOrder.getEndDisplayDate() ? (serviceOrder.getEndDisplayDate() | momentFormat: 'DD MMM YYYY HH:mm':'local') : '-- : --' }}<span>&nbsp;Denmark</span></span>
  </div>
  <div *ngIf="serviceOrder.hasCoTravellers()">
    + Co-traveller(s)
  </div>
  <div *ngIf="serviceOrder.extension.hasMedicalEscort()">
    + Medical escorts(s)
  </div>
<hr>
<div class="hover-container-content-section-title">
  Expected cost
</div>
<div class="hover-container-content-bold">
  {{serviceOrder.expectedCostValue | number:'2.'}}
  {{serviceOrder.expectedCostCurrency}}
</div>
<hr>
<div class="hover-container-content-section-title">
  Remarks
</div>
<div class="hover-container-content-bold">
  {{serviceOrder.remarks}}
</div>
<hr>
<div class="hover-container-content-section-title">
  Commitment Type
</div>
<div class="hover-container-content-bold">
  <span *ngIf="commitmentType">{{ commitmentType.name }}</span>
</div>
<hr>
<div class="hover-container-content-section-title">
  Status Type
</div>
<div class="hover-container-content-bold">
  <span >{{ statusType| translate }}</span>
</div>
<hr>
<div class="hover-container-content-section-title">
  Last edited
</div>
<div class="hover-container-content-bold">
      <span *ngIf="serviceOrder.lastEditedByInitials">
        {{serviceOrder.lastEditedByInitials | uppercase}}
      </span>
  <span *ngIf="serviceOrder.modifiedOn">
        {{serviceOrder.modifiedOn | momentFormat:'DD MMM YYYY HH:mm':'local'}}
      </span>
</div>
