<div class="modal-content">
  <div class="modal-header">
    <div class="modal-header-title">
      <span>{{"case-summary-note-attachments" | translate}}</span>
    </div>
    <div class="modal-buttons">
      <div (click)="close()">
        <img src="/assets/icons/Close White.svg" />
      </div>
    </div>
  </div>
  <ul ngbNav #nav="ngbNav" class="nav-tabs" [destroyOnHide]="false">
     <li ngbNavItem>
      <a ngbNavLink>
        <div> {{"case-summary-note-documents" | translate}} ({{documents?.length}}) </div>
      </a>
      <ng-template ngbNavContent>
        <div class="attachments-container">
          <div *ngFor="let attachment of attachments">
            <app-task-message-attachment [attachment]="attachment" [caseId]="entry.caseId"></app-task-message-attachment>
          </div>
        </div>
      </ng-template>
     </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</div>
