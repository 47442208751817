import { Component, OnInit, Input } from '@angular/core';
import { PlannedTravel } from 'src/app/shared/models/planned-travel';

@Component({
  selector: 'app-case-basics-planned-travel',
  templateUrl: './case-basics-planned-travel.component.html',
  styleUrls: ['./case-basics-planned-travel.component.scss']
})
export class CaseBasicsPlannedTravelComponent implements OnInit {

  @Input() plannedTravel: PlannedTravel;

  @Input() plannedTravelSubPlans: PlannedTravel[];

  @Input() customerProduct: number;

  constructor() { }

  ngOnInit() {
  }

}
