import { Inject, Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ExtraSpaceEnum, IntensiveCarePatientEnum, RepatriationFormEnum } from "@secca/shared/models/enums";
import { MedicalAssessment } from "@secca/shared/models/medicalAssessment";

@Injectable({
  providedIn: 'root',
})
export class ServiceOrderDetailsHelper {

  constructor(private translate: TranslateService) {
  }

  public getRepatriationFormRequested(medicalAssessment: MedicalAssessment): string {
    const repatriationFormRequested = [];
    if (medicalAssessment) {
      const form = medicalAssessment.repatriationInstructionsBase.repatriationForm.form;
      if (RepatriationFormEnum[form] === RepatriationFormEnum.EXTRA_SPACE) {
        const extraSpaces = medicalAssessment.repatriationInstructionsBase.repatriationForm.extraSpaces.map(v => v.type);
        const sortedExtraSpaces = Object.values(ExtraSpaceEnum).filter(v => extraSpaces.includes(v));
        for (const extraSpace of sortedExtraSpaces) {
          repatriationFormRequested.push(this.getExtraSpaceEntry(extraSpace));
        }
      } else if (RepatriationFormEnum[form] === RepatriationFormEnum.INTENSIVE_CARE_PATIENT) {
        repatriationFormRequested.push(this.getIntensiveCarePatientName(
          medicalAssessment.repatriationInstructionsBase.repatriationForm.seaLevelFlight)
        );
      } else {
        repatriationFormRequested.push(this.getRepatriationFormName(form));
      }
    }
    
    return repatriationFormRequested?.join(' ');
  }

  public getExtraSpaceEntry(type): string {
    return this.getRepatriationFormName(RepatriationFormEnum.EXTRA_SPACE.toString()) + ' / ' +
    this.translate.instant(`repatriation-extra-space-type-${this.convertToI18nKey(type)}`);
  }

  public getIntensiveCarePatientName(value: boolean, shortName?: boolean): string {
    const seaLevel = value ? IntensiveCarePatientEnum.SEA_LEVEL : IntensiveCarePatientEnum.NOT_SEA_LEVEL;
    const name = shortName ? RepatriationFormEnum.INTENSIVE_CARE_PATIENT + '-short-name' : RepatriationFormEnum.INTENSIVE_CARE_PATIENT;
    return this.getRepatriationFormName(name) + ' / ' +
      this.translate.instant(`repatriation-intensive-care-patient-type-${this.convertToI18nKey(seaLevel)}`);
  }

  public getRepatriationFormName(value): string {
    return this.translate.instant(`medical-assessment-repatriation-form-${this.convertToI18nKey(value)}`);
  }

  public convertToI18nKey(key: string): string {
    return key?.toString().toLowerCase().replace(/_/g, '-');
  }
}