import { Duration } from './../duration';
import { ServiceOrderExtension } from '@secca/shared/models/service-order/service-order-extension';
import { BaseDurationServiceOrderExtension } from '@secca/shared/models/service-order/base-duration-service-order-extension';
import { DurationAdapter } from '@secca/shared/models/duration';
import { SubGopDetails } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subGopDetails';
import { SubReference } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subReference';
import { Location } from '@secca/shared/models/location';
import { PlansAccommodationTypeEnum } from '@secca/shared/models/enums';
import {
  SubStakeholder,
  SubStakeholderAdapter
} from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholder';
import { SubStakeholders } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholders';
import { CommitmentTypeAdapter } from '../commitment-type';

export class AccommodationServiceOrderExtension extends BaseDurationServiceOrderExtension implements ServiceOrderExtension {
  expectedAccommodationType = PlansAccommodationTypeEnum.HOTEL;
  committedAccommodationType = PlansAccommodationTypeEnum.HOTEL;
  stakeholders: SubStakeholder[];
  subStakeholders: SubStakeholders;

  constructor(init?: Partial<AccommodationServiceOrderExtension>) {
    super(init);
    Object.assign(this, init);    
    this.stakeholders = !!this.stakeholders ? this.stakeholders : [new SubStakeholder()];
  }

  getSelectedStakeholderIds(): string[] {
    return this.stakeholders.length === 0
      ? null
      : this.stakeholders.map(subStakeholder => subStakeholder.stakeholderId);
  }

  isPreviewGopValid(): boolean {
    return this.isValid();
  }

  isValidExpectation(): boolean {
    return super.isValidExpectation() &&
      this.stakeholders[0].isValid();
  }

  getEndLocation(): Location {
    return this.getStartLocation();
  }


  isValid(): boolean {
    let gopValid = true;
    if (this.commitmentType && this.commitmentType.gop) {
      gopValid = this.gopDetails.isValid();
    }

    let stakeholdersValid = true;
    this.stakeholders.forEach(stakeholder => {
       if (stakeholdersValid) {
         stakeholdersValid = stakeholder.isValid();
       }
      }
    );

    return !!this.commitmentType &&
      gopValid &&
      this.isDurationValid() &&
      stakeholdersValid;
  }

  isDurationValid(): boolean {
    if (!this.durations[0].isValid()) {
      return false;
    }
    if (this.durations.length > 1) {
      for (let index = 1; index < this.durations.length; index++) {
        const duration = this.durations[index];
        if (!duration.isValidExpectation()) {
          return false;
        }
      }
    }
    return true;
  }
}

export class AccommodationServiceOrderExtensionAdapter {
  adapt(item?: Partial<AccommodationServiceOrderExtension>): AccommodationServiceOrderExtension {
    const commitmentTypeAdapter: CommitmentTypeAdapter = new CommitmentTypeAdapter();
    const durationAdapter = new DurationAdapter();
    const subStakeholderAdapter = new SubStakeholderAdapter();
    const stakeHolders = item.stakeholders != null ? item.stakeholders.map(data => subStakeholderAdapter.adapt(data)) : [new SubStakeholder()];
    return new AccommodationServiceOrderExtension({
      id: item.id,
      durations: item.durations !== null ? item.durations.map(duration => durationAdapter.adapt(duration)) : [new Duration()],
      gopDetails: item.gopDetails != null ? new SubGopDetails(item.gopDetails) : new SubGopDetails(undefined),
      reference: item.reference != null ? new SubReference(item.reference) : new SubReference(undefined),
      commitmentType: item.commitmentType != null ? commitmentTypeAdapter.adapt(item.commitmentType) : null,
      expectedAccommodationType: item.expectedAccommodationType != null ? PlansAccommodationTypeEnum[item.expectedAccommodationType] : null,
      committedAccommodationType: item.committedAccommodationType != null ? PlansAccommodationTypeEnum[item.committedAccommodationType] : null,
      stakeholders: stakeHolders,
      subStakeholders: new SubStakeholders(stakeHolders)
    });
  }
}
