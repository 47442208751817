import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import { SupplierProfile, SupplierProfileAdapter } from '@secca/shared/models/supplier-profile';
import { EMPTY, empty, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { HelperService } from './helper.service';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root',
})
export class SupplierProfileService extends BaseService {
  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private helperService: HelperService,
    private supplierProfileAdapter: SupplierProfileAdapter
  ) {
    super(settingsService);
  }

  public getPartnerSupplierProfiles(): Observable<SupplierProfile[]> {
    return this.http
      .get(this.baseURL + 'supplier-profile/all-partners')
      .pipe(map((data: any[]) => data.map(item => this.supplierProfileAdapter.adapt(item))));
  }
}
