import {Injectable} from '@angular/core';
import {Adapter} from '@secca/shared/interfaces/adapter';
import {QuestionnaireDto} from '@secca/shared/models/questionnaire-dto';

export class Questions {
  questionKey: string;
  question: string;
  answerKey: string;
  answer: string;

  public constructor(init?: Partial<Questions>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
export class QuestionsAdapter implements Adapter<Questions> {
  adapt(item: any): Questions {
    return new Questions({
      questionKey: item.questionKey,
      question: item.question,
      answerKey: item.answerKey,
      answer: item.answer
    });
  }
}
