import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
import { CaseStakeholder, CaseStakeholderAdapter } from '@secca/shared/models/caseStakeholder';
import { Location } from './location';


export class MajorIncidentCaseDetail {
    caseId: number;
    id: string;
    stakeholders: CaseStakeholder[];
    caseNumber: string;
    destination: Location = new Location();

    public constructor(init?: Partial<MajorIncidentCaseDetail>) {
        Object.assign(this, init);
      }
}

@Injectable({
    providedIn: 'root'
})


export class MajorIncidentCaseDetailAdapter implements Adapter<MajorIncidentCaseDetail> {

    constructor(
      private caseStakeholderAdapter: CaseStakeholderAdapter)  {
    }

    adapt(item: any): MajorIncidentCaseDetail {
        return new MajorIncidentCaseDetail({
            caseId: item.caseId,
            id: item.id,
            stakeholders: item.stakeholders.map(stakeholder => this.caseStakeholderAdapter.adapt(stakeholder)),
            caseNumber: item.caseNumber,
            destination: item.destination || new Location(),
        });
    }
}
