<div class="d-flex" *ngIf="refund" [appDisableInput]="caseLockHelperService.isCaseBasicLockTaken">
  <div class="w-75 pe-4">
    <div class="common-drop-down-arrow common-drop-down">
      <span class="name">{{'refund-template' | translate}}</span>
      <ng-select [class.recommended-yellow-background]="!refund.templateName" dropdownPosition="top" [clearable]="false" [items]="templateNames"
                 [(ngModel)]="refund.templateName" class="custom"
                 [disabled]="disabled"
                 (change)="selectTemplateLanguages($event)">
        <ng-template ng-label-tmp let-item="item">
          <div>
            {{ item }}
          </div>
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="w-25">
    <div class="common-drop-down-arrow common-drop-down">
      <span class="name">{{'refund-template-language' | translate}}</span>
      <ng-select [class.recommended-yellow-background]="!refund.templateLanguage" dropdownPosition="top" [clearable]="false" [items]="templateLanguages"
                 [(ngModel)]="refund.templateLanguage" class="custom" [disabled]="disabled"
                 (change)="selectTemplateId()">
        <ng-template ng-label-tmp let-item="item">
          <div>
            {{ item }}
          </div>
        </ng-template>
      </ng-select>
    </div>
  </div>
</div>
<div class="d-flex">
  <div class="me-4 mt-2 ms-1 buttons w-100">
    <div class="float-start pe-3 button-area-small">
      <button [class.primary-button-inactive]="!refundState.previewLetter || caseLockHelperService.isCaseBasicLockTaken || caseStateService.isCaseClosed || disabled" class="primary-button create-button w-100 me-1" [disabled]="!refundState.previewLetter || disabled || caseLockHelperService.isCaseBasicLockTaken || caseStateService.isCaseClosed"
              (click)="preview()">{{ 'refund-preview-letter' | translate }}</button>
    </div>
    <div class="float-start pe-1 button-area-small" >&nbsp;</div>
    <div class="float-start pe-1 button-area-small" >&nbsp;</div>
    <div class="float-start pe-1 button-area-small me-1">
      <button [class.primary-button-inactive]="!this.refundState.validForSendLetter || caseLockHelperService.isCaseBasicLockTaken" class="primary-button send-button  w-100 me-1" [disabled]="!this.refundState.validForSendLetter || disabled || caseLockHelperService.isCaseBasicLockTaken"
              (click)="send()">
        <div [disablePopover]="disablePopover" triggers="hover" [ngbPopover]="errorText | translate">
          {{ 'refund-send-letter' | translate}}
        </div>
      </button>
    </div>
  </div>
</div>
