import { Injectable } from '@angular/core';
import { Adapter } from './../interfaces/adapter';

export class Country {
  id: number;
  code: string;
  name: string;

  public constructor(init?: Partial<Country>) {
    Object.assign(this, init);
  }
}

export class CountryDetails {
  id: number;
  code: string;
  countryName: string;
  phonePrefix: string;

  public constructor(init?: Partial<CountryDetails>) {
    Object.assign(this, init);
  }

  toCountry(): Country {
    const country = new Country();
    country.id = this.id;
    country.code = this.code;
    country.name = this.countryName;
    return country;
  }
}

@Injectable({
  providedIn: 'root'
})
export class CountryDetailsAdapter implements Adapter<CountryDetails> {
  adapt(item: any): CountryDetails {
    return new CountryDetails({
      id: item.id,
      code: item.codeAlpha2,
      countryName: item.name,
      phonePrefix: item.phonePrefix
    });
  }
  adaptFromCountry(country: Country) {
    return new CountryDetails({
      id: country.id,
      code: country.code,
      countryName: country.name,
      phonePrefix: ''
    });
  }
}


