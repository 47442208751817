import { ServiceTypeEnum } from '@secca/shared/models/enums';
import { DocumentTemplateFilter } from './documentTemplateFilter';
import { DocumentTemplate } from 'src/app/shared/models/document-template';
import { DocumentService } from 'src/app/core/services/document.service';
import { TranslateService } from '@ngx-translate/core';
import { SubGopDetails } from '../../models/subGopDetails';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-sub-gop-details',
  templateUrl: './sub-gop-details.component.html',
  styleUrls: ['./sub-gop-details.component.scss'],
})
export class SubGopDetailsComponent implements OnInit {
  @Input() serviceTypeEnum: ServiceTypeEnum;
  @Input() subGopDetails: SubGopDetails;
  @Input() templateFilter: DocumentTemplateFilter;

  fullTemplateList: DocumentTemplate[];
  itemsInGopPlaceholder: string;
  itemsNotInGopPlaceholder: string;
  templateNames = [];
  templateLanguages = [];
  selectedTemplateName: string;
  selectedTemplateLanguage: string;
  templatesWithLanguages: { [templateName: string]: string[] } = {};

  constructor(private translateService: TranslateService, private documentService: DocumentService) {}

  ngOnInit() {
    this.initialize();
  }

  initialize() {
    this.documentService.postTemplatesByType(this.templateFilter).subscribe(gopTemplates => {
      this.fullTemplateList = gopTemplates;
      this.mapToTemplateNameWithLanguagesList(gopTemplates);
      // Make a method that checks the list if there is only 1 set it
      this.setFirstTemplateName(gopTemplates);
    });
    if (this.serviceTypeEnum === ServiceTypeEnum.ACCOMMODATION) {
      this.itemsInGopPlaceholder = this.translateService.instant('sub-gop-details-items-in-gop-placeholder-accommodation');
      this.itemsNotInGopPlaceholder = this.translateService.instant('sub-gop-details-items-not-in-gop-placeholder-accommodation');
    }
    if (this.serviceTypeEnum === ServiceTypeEnum.TREATMENT) {
      this.itemsInGopPlaceholder = this.translateService.instant('sub-gop-details-items-in-gop-placeholder-treatment');
      this.itemsNotInGopPlaceholder = this.translateService.instant('sub-gop-details-items-not-in-gop-placeholder-treatment');
    }

  }

  setFirstTemplateName(gopTemplates: DocumentTemplate[]): void {
    if (gopTemplates.length === 1){
      this.subGopDetails.templateName = gopTemplates[0].templateName;
      this.subGopDetails.templateLanguage = gopTemplates[0].templateLanguage;
      this.subGopDetails.templateId = this.getTemplateId();
    }
  }

  selectTemplateLanguages(selectedTemplateName: string) {
    if (selectedTemplateName){
      this.templateLanguages = this.templatesWithLanguages[selectedTemplateName];
    }
    this.subGopDetails.templateId = this.getTemplateId();
    if (this.templateLanguages.length === 1){
      this.subGopDetails.templateLanguage = this.templateLanguages[0];
      this.selectTemplateId(this.templateLanguages[0]);
      this.subGopDetails.templateId = this.getTemplateId();
    }
    //Sets the name to GOP first time. Jira Issue: SEC-15072
    this.setGopTemplate(selectedTemplateName);
  }

  setGopTemplate(selectedTemplateName: string){
    if (!this.subGopDetails.templateName){
      this.subGopDetails.templateName = selectedTemplateName;
      if (this.subGopDetails.templateName === 'GOP'){
        this.setENTemplateLanguage();
      }
    }
  }

  selectTemplateId(selectedTemplateLanguage: string) {
    this.subGopDetails.templateId = this.getTemplateId();

  }
  //Sets the language to EN first time. Jira Issue: SEC-15072
  setENTemplateLanguage(): void {
    if (!this.subGopDetails.templateLanguage){
      this.subGopDetails.templateLanguage = 'EN';
      this.subGopDetails.templateId = this.getTemplateId();
    }
  }

  getTemplateId() {
    const gopTemplate = this.fullTemplateList.find(
      gopTemplateResult =>
        gopTemplateResult.templateLanguage === this.subGopDetails.templateLanguage &&
        gopTemplateResult.templateName === this.subGopDetails.templateName
    );
    if (gopTemplate) {
      return gopTemplate.templateId;
    }
    return '';
  }

  mapToTemplateNameWithLanguagesList(gopTemplates: DocumentTemplate[]) {
    const templateNames = new Set();
    gopTemplates.forEach(gopTemplate => {
      if (this.templatesWithLanguages[gopTemplate.templateName] == null) {
        this.templatesWithLanguages[gopTemplate.templateName] = [];
      }
      this.templatesWithLanguages[gopTemplate.templateName].push(gopTemplate.templateLanguage);
      templateNames.add(gopTemplate.templateName);
    });
    this.sortDocuments(Array.from(templateNames));
    this.selectTemplateLanguages(this.templateNames.find(val => val === 'GOP'));
  }

  sortDocuments(templateNames: any[]) {
    const sortedTemplateNames = new Set();
    let gopTemplateNames: sortDokument[] = [];
    let vobTemplateNames: sortDokument[] = [];
    let otherTemplateNames: sortDokument[] = [];
    templateNames.forEach(template => {
      let name = template.split(" ", 2);
      if(name[0] === 'GOP') {
        gopTemplateNames.push(new sortDokument({ name1: name[0], name2: name[1], fullName: template } ))
      } else if (name[0] === 'VOB') {
        vobTemplateNames.push(new sortDokument({ name1: name[0], name2: name[1], fullName: template } ))
      } else {
        otherTemplateNames.push(new sortDokument({ name1: name[0], name2: name[1], fullName: template } ))
      }
    });
    gopTemplateNames.sort((a, b) => (a.name2 < b.name2 ? -1 : 1 )).forEach(template => sortedTemplateNames.add(template.fullName));
    vobTemplateNames.sort((a, b) => (a.name2 < b.name2 ? -1 : 1 )).forEach(template => sortedTemplateNames.add(template.fullName));
    otherTemplateNames.sort((a, b) => (a.name1 < b.name1 ? -1 : 1 )).forEach(template => sortedTemplateNames.add(template.fullName));
    this.templateNames = Array.from(sortedTemplateNames);
  }
}
export class sortDokument {
  name1: string;
  name2: string;
  fullName: string;

  public constructor(init?: Partial<sortDokument>) {
    Object.assign(this, init);
  }
}
