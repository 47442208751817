import { Component, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { BoardSelectedMenuEnum } from '@secca/shared/enums/board-selected-menu-enum';
import { MenuService } from '@secca/core/services/menu.service';
import { PermissionEnum, ShortcutEnum } from '@secca/shared/models/enums';
import { ShortcutService } from '@secca/core/services/shortcut.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-board-menu',
  templateUrl: './board-menu.component.html',
  styleUrls: ['./board-menu.component.scss'],
})
export class BoardMenuComponent implements OnInit, OnDestroy {
  @Output() menuClick: EventEmitter<BoardSelectedMenuEnum> = new EventEmitter();

  selectedMenuNumber: BoardSelectedMenuEnum;

  get BoardSelectedMenuEnum() {
    return BoardSelectedMenuEnum;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  private shortcutSubscriptions: Subscription[] = [];

  constructor(private menuService: MenuService,
              private shortcutService: ShortcutService) {

    this.shortcutSubscriptions.push(
      this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateToBoardCases }).subscribe(a => {
        this.boardCasesClick();
      }),
      this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateToBoardTask }).subscribe(a => {
        this.boardTaskClick();
      }),
      this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateToBoardSalesOrder }).subscribe(a => {
        this.boardSalesOrderClick();
      }),
    );
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.shortcutSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  isMenuSelected(menuNuber: BoardSelectedMenuEnum): boolean {
    if (this.menuService.getSelectedBoardMenuId() === undefined) {
      this.selectedMenuNumber = BoardSelectedMenuEnum.Task;
    } else {
      this.selectedMenuNumber = this.menuService.getSelectedBoardMenuId();
    }
    return menuNuber === this.selectedMenuNumber;
  }

  boardTaskClick() {
    this.selectedMenuNumber = BoardSelectedMenuEnum.Task;
    this.menuService.sendBoardMenuItem(this.selectedMenuNumber);
    this.menuClick.emit(this.selectedMenuNumber);
  }

  boardCasesClick() {
    this.selectedMenuNumber = BoardSelectedMenuEnum.Cases;
    this.menuService.sendBoardMenuItem(this.selectedMenuNumber);
    this.menuClick.emit(this.selectedMenuNumber);
  }

  boardSalesOrderClick() {
    this.selectedMenuNumber = BoardSelectedMenuEnum.SalesOrder;
    this.menuService.sendBoardMenuItem(this.selectedMenuNumber);
    this.menuClick.emit(this.selectedMenuNumber);
  }
}
