<span *ngIf="limits.length > 0">
  {{ limits[0].limitMaxValue }}
  <span *ngIf="(limits[0].limitType === getLimitTypeEnum.AMOUNT)">
    {{ limits[0].limitUnit }}
  </span>
  <span *ngIf="(limits[0].limitType === getLimitTypeEnum.NUMBER_OF_PERSONS) || (limits[0].limitType === getLimitTypeEnum.QUANTITY) ">
    {{ "coverages-" + limits[0].limitUnit | translate  }}
  </span>
  <span *ngIf="limits[0].limitType === getLimitTypeEnum.DURATION">
    {{ "coverages-" + limits[0].limitUnit | translate  }} from
    {{ "coverages-" + limits[0].limitValue | translate  }}
  </span>
</span>
