import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CaseCommunicationService } from "@secca/core/services/case-communication.service";
import { StakeholderService } from "@secca/core/services/stakeholder.service";
import { CaseEmail } from "@secca/shared/models/case-email";
import { CaseHistoryCommEntry } from "@secca/shared/models/caseHistoryEntry";
import { CaseStakeholder } from "@secca/shared/models/caseStakeholder";
import { CommunicationDirection } from "@secca/shared/models/enums";

@Component({
  selector: 'app-case-summary-sms-modal',
  templateUrl: './case-summary-sms-modal.component.html',
  styleUrls: ['./case-summary-sms-modal.component.scss'],
})
export class CaseSummarySmsModalComponent implements OnInit {
  @Input()
  entry: CaseHistoryCommEntry;

  @Input()
  stakeholder: CaseStakeholder;

  @Output()
  closed: EventEmitter<void> = new EventEmitter();

  caseEmail: CaseEmail;

  constructor(private caseCommunicationService: CaseCommunicationService,
              private stakeholderService: StakeholderService) {}

  ngOnInit() {
    if (this.entry.direction === CommunicationDirection.IN_BOUND) {
      this.getCaseMessage();
    }
  }

  close() {
    this.closed.emit();
  }

  get stakeholderName() {
    if (this.stakeholder) {
      if (this.stakeholder.isCompany) {
        return this.stakeholder.company.name;
      } else {
        return this.stakeholder.person.firstName + ' ' + this.stakeholder.person.surname;
      }
    }
  }

  get stakeholderIcon() {
    if (this.stakeholder) {
      return this.stakeholderService.getStakeholderIconFullPath(this.stakeholder.stakeholderType);
    }
  }

  get CommunicationDirection() {
    return CommunicationDirection;
  }

  private getCaseMessage() {
    this.caseCommunicationService.getCaseMessage(this.entry.messageId).subscribe(
      result => {
        this.caseEmail = result;
      },
      error => console.log(error)
    );
  }
}