<div class="incoming-cases-task-table" *ngIf="taskViewModels; else loadingTemplate">
  <div class="primary-table-container">
    <table class="primary-table fixed-header-table">
      <thead>
        <tr>
          <td class="checkbox-column-header">
            <div class="custom-checkbox checkbox-header-container center">
              <label class="checkbox">
                <input type="checkbox" (click)="checkAllTasks()" [(ngModel)]="isCheckAll">
                <span class="checkmark"></span>
              </label>
            </div>
          </td>
          <td (click)="sortBy('type')" [ngClass]="sortedBy('type')"> {{'board-task-table-type' | translate}}</td>
          <td (click)="sortBy('channelType')" [ngClass]="sortedBy('channel')">{{'board-task-table-channel' | translate}}</td>
          <td (click)="sortBy('receivedDate')" [ngClass]="sortedBy('receivedDate')">{{'board-task-table-received' | translate}}</td>
          <td (click)="sortBy('departureDate')" [ngClass]="sortedBy('departureDate')">{{'board-task-table-departure-date' | translate}}</td>
          <td (click)="sortBy('customerProfileName')" [ngClass]="sortedBy('customerProfileName')">{{'board-task-table-customer' | translate}}</td>
          <td (click)="sortBy('caseNumber')" [ngClass]="sortedBy('caseNumber')">{{"board-task-table-case-id" | translate}}</td>
          <td (click)="sortBy('reporter')" [ngClass]="sortedBy('reporter')">{{'board-task-table-reporter' | translate}}</td>
          <td (click)="sortBy('location')" [ngClass]="sortedBy('location')">{{'board-task-table-location' | translate}}</td>
          <td (click)="sortBy('timeOffsetInHours')" [ngClass]="sortedBy('timeOffsetInHours')"> {{'board-task-table-time' | translate}}
          <td (click)="sortBy('incidentDate')" [ngClass]="sortedBy('incidentDate')">{{'board-task-table-task-incident-date' | translate}}
          <td (click)="sortBy('cause')" [ngClass]="sortedBy('cause')">{{'board-task-table-cause' | translate}}</td>
          <td (click)="sortBy('assignInitials')" [ngClass]="sortedBy('assignInitials')"> {{'board-task-table-assign' | translate}}</td>
        </tr>
      </thead>
      <tbody (scroll)="onCaseTabelScroll()" id="taskIncomingCasesTabel" [class.zoomed]="settingsService.isZoomed()">
        <app-task-hover></app-task-hover>
        <tr *ngFor="let taskViewModel of taskViewModels" [class.task-check]="taskViewModel.isCheck">
          <td class="checkbox-column pt-1">
            <div class="custom-checkbox checkbox-content-container center">
              <label class="checkbox">
                <input type="checkbox" [(ngModel)]="taskViewModel.isCheck">
                <span class="checkmark" style="margin:auto"></span>
              </label>
            </div>
          </td>
          <td class="type-column">
            <div>
              <img [src]="getTaskTypeImagePath(taskViewModel)" (click)="viewTask(taskViewModel)">
            </div>
          </td>
          <td>{{'channel-type-'+taskViewModel.channelType | translate}}</td>
          <td class="date-column">{{taskViewModel.receivedDate | momentFormat :'DD MMM HH:mm' : 'local' }}</td>
          <td>{{taskViewModel.departureDate | momentFormat :'DD MMM YYYY' : 'local' }}</td>
          <td class="customer-column">
            <img *ngIf="taskViewModel.customerProfileId" [src]="getBrandImagesUrlForProfile(taskViewModel.customerProfileId)">
            <img *ngIf="!taskViewModel.customerProfileId" src="assets/icons/1x1.png">
          </td>
          <td class="case-number-column">
            <span *ngIf="!!taskViewModel.caseValidated" [ngClass]="{'case-locked': taskViewModel.caseLockedBy && taskViewModel.caseLockedBy != loggedInUser.id}"
                  (click)="redirectToCase(taskViewModel.caseId)">{{taskViewModel.caseValidated ? taskViewModel.caseNumber : ''}}</span>
          </td>
          <td>{{taskViewModel.reporterFullName}}</td>
          <td class="long-text-field">{{taskViewModel.location}}</td>
          <td class="time-column"> {{taskViewModel.timeZoneId | timeDiff : true}}
          <td class="date-column">{{taskViewModel.incidentDate | momentFormat :'DD MMM YYYY' : 'local'}}</td>
          <td class="long-text-field">{{taskViewModel.cause}}</td>
          <td class="assign-column">{{taskViewModel.assignInitials}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<ng-template #loadingTemplate>
  <div class="d-flex justify-content-center">
    <h4>{{"board-task-table-refreshing" | translate}}</h4>
  </div>
</ng-template>
