import { HelperService } from '@secca/core/services/helper.service';
import { StakeholderType } from 'src/app/modules/case/components/case-stakeholders/stakeholderType';
import { DictionaryService } from 'src/app/core/services/dictionary.service';
import { DropdownDictionary } from 'src/app/shared/models/dropdownDictionary';
import { SupplierDropDownAdapter } from '@secca/shared/models/dropdownDictionary';
import { CasePlansService } from '../case-plans.service';
import { ServiceOrderHelper } from './service-order/service-order-helper';
import { ServiceTypeEnum, StakeholderTypeEnum } from 'src/app/shared/models/enums';
import { ServiceOrderService } from '@secca/core/services/service-order.service';
import { CaseService } from '@secca/core/services/case.service';
import { PersonInsurance } from 'src/app/shared/models/person-insurance';
import { PlannedTravel } from 'src/app/shared/models/planned-travel';
import { CaseIncident } from '@secca/shared/models/caseIncident';
import { IncidentService } from 'src/app/core/services/incident.service';
import { ServiceOrder } from '@secca/shared/models/service-order/service-order';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Timeline } from 'src/app/shared/models/timeline';
import moment from 'moment';
import { ServiceOrderComponent } from './service-order/service-order.component';
import { PlanDisplayHelper } from './plan-display-helper';
import { AutoUnsubscribe } from 'src/app/shared/decorators/auto-unsubscribe';
import { InsuranceService } from '@secca/core/services/insurance.service';
import { MomentHelperService } from '@secca/core/services/moment-helper.service';
import { PlannedTravelService } from '@secca/core/services/planned-travel.service';
import { Subscription } from 'rxjs';
import _ from 'lodash';
import { CoordinationCaseDetail } from '@secca/shared/models/coordination-case-details';
import { CoordinationCaseService } from '@secca/core/services/coordination-case.service';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss'],
})
@AutoUnsubscribe
export class PlanComponent implements OnInit, AfterViewInit {
  @ViewChildren('serviceOrderComponents') serviceOrderComponents: QueryList<ServiceOrderComponent>;
  @Output() openServiceEditDialog: EventEmitter<any> = new EventEmitter();
  @ViewChild('planContent', { static: true }) planContent: ElementRef;
  @Input() incidentId: number;
  @Input() iccServiceOrders: ServiceOrder[];
  private _caseId: number;

  timeline: Timeline;
  stakeholders: CaseStakeholder[] = [];
  serviceOrders: ServiceOrder[];
  daysInTimeline: Date[];
  firstVerticalLine: any;
  secondVerticalLine: any;
  thirdVerticalLine: any;
  caseIncident: CaseIncident;
  plannedTravel: PlannedTravel;
  personInsurance: PersonInsurance;
  coverageDays: string;
  timelineText = 'Load dates';
  shiftTravelBegin: boolean;
  shiftTravelEnd: boolean;
  shiftCoverageDayUp: boolean;
  shiftCoverageDayDown: boolean;
  currentDayTop: string;
  planHelper: PlanDisplayHelper;
  today = new Date();
  halfOfTheDateColumnWidth = '75px';
  numberOfLanes = 0;
  $serviceOrdersSubscrAll: Subscription;
  $serviceOrderSubscrNew: Subscription;
  $serviceOrderSubscrEdited: Subscription;
  $serviceOrderSubscrDeleted: Subscription;
  $subscrGetPersonInsurance: Subscription;
  theComponentHasBeenInitialized = false;
  theServiceHasBeenShown = false;
  isMedicalEscort: boolean;
  isCoTraveller: boolean;
  medicalEscortIds: string[];
  coTravellerIds: string[];
  endUserIds: string[];
  serviceOrdersCalculated: ServiceOrder[] = [];
  providersNames: DropdownDictionary[];
  providersIcons: DropdownDictionary[];
  $stakeholderTypesSubscr: Subscription;
  coordinationCaseDetail: CoordinationCaseDetail[]
  coordinatonCaseStakeholders: CaseStakeholder[];
  incidentEvent: string;


  constructor(
    private casePlansService: CasePlansService,
    private serviceOrderService: ServiceOrderService,
    private incidentService: IncidentService,
    private caseService: CaseService,
    private insuranceService: InsuranceService,
    private supplierAdapter: SupplierDropDownAdapter,
    private dictionaryService: DictionaryService,
    private helperService: HelperService,
    private plannedTravelService: PlannedTravelService,
    private coordinationCaseService: CoordinationCaseService
  ) {
    this.$serviceOrdersSubscrAll = this.casePlansService.getServiceOrders().subscribe(
      result => {
        if (result != null) {
          this.serviceOrders = result;
          if (this.serviceOrders != null && this.theComponentHasBeenInitialized) {
            this.refreshPlan();
          }
        }
      }
    );
    this.$serviceOrderSubscrNew = this.casePlansService.getNewServiceOrder().subscribe(
      result => {
        if (result != null) {
          if (this.serviceOrders == null) {
            this.serviceOrders = [];
          }
          const clonedServiceOrder = this.serviceOrderService.cloneDurationServiceOrders([result], this.stakeholders);
          this.serviceOrders.push(...clonedServiceOrder);
          this.serviceOrders.push(...this.serviceOrderService.cloneRegularFlightServiceOrders([result], this.stakeholders));
          const clonedAmbulanceFlightOrders = this.serviceOrderService.cloneAmbulanceFlightServiceOrders([result], this.stakeholders);
          this.serviceOrders.push(...clonedAmbulanceFlightOrders);
          const clonedStakeholderServiceOrders = this.serviceOrderService.cloneOtherServiceOrdersWithStakeholders([result], this.stakeholders);
          this.serviceOrders.push(...clonedStakeholderServiceOrders);
          if(this.serviceOrders.some(service => service.type === ServiceTypeEnum.COORDINATION_TRANSPORT)) {
            this.cloneCoordinationTransportServiceOrderRefresh([result]);
          } else {
            this.refreshPlan();
          }
        }
      }
    );
    this.$serviceOrderSubscrEdited = this.casePlansService.getEditedServiceOrder().subscribe(
      result => {
        if (result != null) {
          const clonedServiceOrder = this.serviceOrderService.cloneDurationServiceOrders([result], this.stakeholders);
          this.serviceOrders = this.serviceOrders.filter(serviceOrder => serviceOrder.id !== result.id);
          this.serviceOrders.push(...clonedServiceOrder);
          this.serviceOrders.push(...this.serviceOrderService.cloneRegularFlightServiceOrders([result], this.stakeholders));
          const clonedAmbulanceFlightOrders = this.serviceOrderService.cloneAmbulanceFlightServiceOrders([result], this.stakeholders);
          this.serviceOrders.push(...clonedAmbulanceFlightOrders);
          const clonedStakeholderServiceOrders = this.serviceOrderService.cloneOtherServiceOrdersWithStakeholders([result], this.stakeholders);
          this.serviceOrders.push(...clonedStakeholderServiceOrders);
          if(this.serviceOrders.some(service => service.type === ServiceTypeEnum.COORDINATION_TRANSPORT)) {
            this.cloneCoordinationTransportServiceOrderRefresh([result]);
          } else {
            this.refreshPlan();
          }
        }
      }
    );
    this.$serviceOrderSubscrDeleted = this.casePlansService.getDeletedServiceOrder().subscribe(
      result => {
        if (result != null) {
          this.serviceOrders = this.serviceOrders.filter(serviceOrder => serviceOrder.id !== result.id);
          this.refreshPlan();
        }
      }
    );
  }

  @Input()
  set caseId(newCaseId: number) {
    if (newCaseId !== undefined) {
      this._caseId = newCaseId;
      this.serviceOrdersCalculated = [];
      this.caseService.getCaseStakeholdersOnCase(this.caseId.toString()).subscribe(
        stakeholders => {
          this.caseService.sendCaseStakeholderWithCaseStakeholder(stakeholders);
          this.stakeholders = stakeholders;
          this.mockProvidersNames(stakeholders);
          if ( this.$stakeholderTypesSubscr ) {
            this.$stakeholderTypesSubscr.unsubscribe();
          }
          this.$stakeholderTypesSubscr = this.dictionaryService.getStakeholdersTypes().subscribe(
            stakeholderTypes => {
              this.mockProvidersIcons(stakeholderTypes, stakeholders);
              this.serviceOrderService.getServiceOrdersForCase(newCaseId).subscribe(
                result => {
                  result = this.serviceOrderService.cloneDurationServiceOrders(result, stakeholders);
                  result.push(...this.serviceOrderService.cloneRegularFlightServiceOrders(result, stakeholders, this.iccServiceOrders));
                  const clonedAmbulanceFlightOrders = this.serviceOrderService.cloneAmbulanceFlightServiceOrders(result, stakeholders, this.iccServiceOrders);
                  result.push(...clonedAmbulanceFlightOrders);
                  const clonedStakeholderServiceOrders = this.serviceOrderService.cloneOtherServiceOrdersWithStakeholders(result, stakeholders);
                  result.push(...clonedStakeholderServiceOrders);
                  if(result.some(service => service.type === ServiceTypeEnum.COORDINATION_TRANSPORT)) {
                    this.cloneCoordinationTransportServiceOrder(result);
                  } else {
                    this.serviceOrders = result;
                    this.casePlansService.sendServiceOrders(this.serviceOrders);
                  }
                }
              );
            }
          );
        }
      );
    }
  }

  get caseId(): number {
    return this._caseId;
  }

  ngOnInit() {
    this.generateTimeline();
    this.calculateVerticalLinePositions();
    if (!this.theServiceHasBeenShown) {
      this.refreshPlan();
    }
    this.theComponentHasBeenInitialized = true;
  }

  ngAfterViewInit() {
    const scrollToValue = (
      this.findDateIndexInTimeLine(new Date()) * PlanDisplayHelper.defaultCellHeight +
      this.topOffset(new Date())
    ).toString();
    this.planContent.nativeElement?.scrollTo(0, +scrollToValue - 250);
  }

  cloneCoordinationTransportServiceOrderRefresh(serviceOrders: ServiceOrder[]) {
    if(this.caseId) {
      this.coordinationCaseService.getLinkedCasesDetails(this.caseId.toString()).subscribe(linkedCaseDetails => {
        this.coordinationCaseDetail = linkedCaseDetails;
        this.coordinatonCaseStakeholders = this.updateCoordinatonCaseStakeholders(this.coordinationCaseDetail);
        const clonedCoordinationTransportServiceOrders = this.serviceOrderService.cloneCoordinationCaseServiceOrders(serviceOrders, this.coordinatonCaseStakeholders);
        this.serviceOrders.push(...clonedCoordinationTransportServiceOrders);
        this.refreshPlan();
      });
    }
  }

  cloneCoordinationTransportServiceOrder(result: ServiceOrder[]) {
    this.coordinationCaseService.getLinkedCasesDetails(this.caseId.toString()).subscribe(linkedCaseDetails => {
      this.coordinationCaseDetail = linkedCaseDetails;
      this.coordinatonCaseStakeholders = this.updateCoordinatonCaseStakeholders(this.coordinationCaseDetail);
      const clonedCoordinationTransportServiceOrders = this.serviceOrderService.cloneCoordinationCaseServiceOrders(result, this.coordinatonCaseStakeholders);
      result.push(...clonedCoordinationTransportServiceOrders);
      this.serviceOrders = result;
      this.casePlansService.sendServiceOrders(this.serviceOrders);
    });
  }

  mockProvidersNames(stakeholders: CaseStakeholder[]) {
    this.providersNames = stakeholders
      .filter(
        stakeholder =>
          stakeholder.stakeholderType !== StakeholderTypeEnum.endUser &&
          stakeholder.stakeholderType !== StakeholderTypeEnum.reporter &&
          stakeholder.stakeholderType !== StakeholderTypeEnum.policyHolder
      )
      .map(data => this.supplierAdapter.adapt(data))
      .filter(data => data.name != null);
  }

  mockProvidersIcons(stakeholdersTypes: StakeholderType[], stakeholders: CaseStakeholder[]) {
    this.providersIcons = [];
    stakeholders.forEach(stakeholder => {
      if (stakeholdersTypes) {
        const stakeholderType = stakeholdersTypes.find(a => a.stakeholderTypeEnum === stakeholder.stakeholderType);
        if (stakeholderType != null) {
          this.providersIcons.push(new DropdownDictionary(stakeholder.id, stakeholderType.iconName));
        }
      }
    });
  }

  onOpenServiceEditDialog(serviceId: any) {
    this.openServiceEditDialog.emit(serviceId);
  }

  isToday(d: Date): boolean {
    return moment(d).isSame(moment(this.today), 'day');
  }

  private checkStakeholderTypes() {
    this.isMedicalEscort = false;
    this.isCoTraveller = false;
    this.serviceOrders.forEach(serviceOrder => {
      if (serviceOrder.laneNumber === PlanDisplayHelper.CO_TRAVELLER_LANE) {
        this.isCoTraveller = true;
      }
      if (serviceOrder.laneNumber === PlanDisplayHelper.MEDICAL_ESCORT_LANE) {
        this.isMedicalEscort = true;
      }
    });
  }

  calculateVerticalLinePositions() {
    this.firstVerticalLine = {
      left: '150px', // the width of the first column with dates
    };
    this.secondVerticalLine = {
      left: 'calc(100% / 2 + ' + this.halfOfTheDateColumnWidth + ' )', // the second half of the plan view + offset of the date column
    };
    this.thirdVerticalLine = {
      // We plus set 149 because we want the round down number when we divide
      left: 'calc(3*(100% - 149px)/4 + 150px)',
    };
  }

  getTopForHorizontalLine(index: number): string {
    let topOffset = 0;
    if (this.planHelper != null && index < this.daysInTimeline.length) {
      topOffset = this.topOffset(this.daysInTimeline[index + 1]);
    }
    return ((index + 1) * PlanDisplayHelper.defaultCellHeight + topOffset).toString() + 'px';
  }

  get getHeightForCurrentDay(): string {
    if (this.planHelper != null) {
      return this.planHelper.getHeightForCurrentDay() + 'px';
    } else {
      return null;
    }
  }

  private calcCurrentDayTop(): string {
    return (this.findDateIndexInTimeLine(new Date()) * PlanDisplayHelper.defaultCellHeight + this.topOffset(new Date())).toString() + 'px';
  }

  private findDateIndexInTimeLine(searchDate: Date): number {
    if (searchDate == null) {
      return;
    }
    const date = this.daysInTimeline.find(
      a => a.getDate() === searchDate.getDate() && a.getFullYear() === searchDate.getFullYear() && a.getMonth() === searchDate.getMonth()
    );
    return this.daysInTimeline.indexOf(date);
  }

  private topOffset(date: Date): number {
    let topOffset = 0;
    if (this.planHelper != null) {
      topOffset = this.planHelper.topOffsetFromPreviousExtendedDates(date);
    }
    return topOffset;
  }

  private bottomOffset(date: Date): number {
    let bottomOffset = 0;
    if (this.planHelper != null) {
      bottomOffset = this.planHelper.bottomOffsetFromNextExtendedDates(date);
    }
    return bottomOffset;
  }

  get accidentTop(): string {
    const shift = 12;
    const date = this.checkIfExceedsBoundary(this.timeline.accidentDate);
    return (
      (
        this.findDateIndexInTimeLine(date) * PlanDisplayHelper.defaultCellHeight +
        PlanDisplayHelper.defaultCellHeight / 2 -
        shift +
        this.topOffset(date)
      ).toString() + 'px'
    );
  }

  get accidentBeforeLineBottom(): string {
    const date = this.checkIfExceedsBoundary(this.timeline.accidentDate);

    return (
      (
        (this.daysInTimeline.length - 1 - this.findDateIndexInTimeLine(date)) * PlanDisplayHelper.defaultCellHeight +
        PlanDisplayHelper.defaultCellHeight / 2 -
        12 -
        this.bottomOffset(date)
      ).toString() + 'px'
    );
  }

  get landingTop(): string {
    let shift = 12;
    if (this.shiftTravelEnd) {
      shift = -12;
    }
    const date = this.checkIfExceedsBoundary(this.timeline.travelEnd);
    return (
      (
        this.findDateIndexInTimeLine(date) * PlanDisplayHelper.defaultCellHeight +
        PlanDisplayHelper.defaultCellHeight / 2 -
        shift +
        this.topOffset(date)
      ).toString() + 'px'
    );
  }

  get travelEndBeforeLineBottom(): string {
    const date = this.checkIfExceedsBoundary(this.timeline.travelEnd);
    return (
      (
        (this.daysInTimeline.length - 1 - this.findDateIndexInTimeLine(date)) * PlanDisplayHelper.defaultCellHeight +
        PlanDisplayHelper.defaultCellHeight / 2 +
        (12 + this.bottomOffset(date))
      ).toString() + 'px'
    );
  }

  get coverageDayEndTop(): string {
    let shift = 11;
    if (this.shiftCoverageDayDown) {
      shift = -12;
    }
    if (this.shiftCoverageDayUp) {
      shift = 36;
    }
    const date = this.checkIfExceedsBoundary(this.timeline.coverageDayEnd);
    return (
      (
        this.findDateIndexInTimeLine(date) * PlanDisplayHelper.defaultCellHeight +
        PlanDisplayHelper.defaultCellHeight / 2 -
        shift +
        this.topOffset(date)
      ).toString() + 'px'
    );
  }

  get stopIcon(): string {
    if (!this.personInsurance.coverageDays) {
      return;
    }
    const daysSinceTravelStartToToday = MomentHelperService.getDaysRangeBothIncl(this.plannedTravel.departureDate, moment.utc());
    const daysTillCoverageEnds = Number(this.personInsurance.coverageDays) - daysSinceTravelStartToToday;
    if (daysTillCoverageEnds < 2) {
      return `url('/assets/icons/StopRed.svg')`;
    } else if (daysTillCoverageEnds === 2 || daysTillCoverageEnds === 3) {
      return `url('/assets/icons/StopYellow.svg')`;
    } else {
      return `url('/assets/icons/stop.svg')`;
    }
  }

  get startingTop(): string {
    let shift = 12;
    if (this.shiftTravelBegin) {
      shift = 36;
    }
    const date = this.checkIfExceedsBoundary(this.timeline.travelBegin);
    return (
      (
        this.findDateIndexInTimeLine(date) * PlanDisplayHelper.defaultCellHeight +
        PlanDisplayHelper.defaultCellHeight / 2 -
        shift +
        this.topOffset(date)
      ).toString() + 'px'
    );
  }

  get coverageDayEndBeforeLineBottom(): string {
    const date = this.checkIfExceedsBoundary(this.timeline.coverageDayEnd);
    return (
      (
        (this.daysInTimeline.length - 1 - this.findDateIndexInTimeLine(date)) * PlanDisplayHelper.defaultCellHeight +
        this.topOffset(date) +
        PlanDisplayHelper.defaultCellHeight / 2 -
        12 +
        this.topOffset(date)
      ).toString() + 'px'
    );
  }

  private checkIfExceedsBoundary(date: Date): Date {
    if (moment(date).isSameOrAfter(this.daysInTimeline[this.daysInTimeline.length - 1])) {
      date = this.daysInTimeline[this.daysInTimeline.length - 1];
    }
    return date;
  }

  private checkIfAccidentDateValid(date: Date) {
    if (moment(date).isAfter(this.timeline.travelEnd)) {
      this.timeline.accidentDate = null;
    }
  }

  updateIncident() {
    this.incidentService.getCaseIncident(this.incidentId).subscribe(result => {
      this.caseIncident = result;
      this.incidentEvent = this.caseIncident.incidentEvent;
      this.timeline.accidentDate = this.caseIncident.incidentDate ? this.caseIncident.incidentDate.toDate() : null;
    });
  }

  updateCoordinatonCaseStakeholders(coordinationCaseDetail: CoordinationCaseDetail[]): CaseStakeholder[]  {
    let coordinationStakeholder: CaseStakeholder[] = [];
    coordinationCaseDetail.forEach(caseDetail => {
      caseDetail.stakeholders.forEach(stakeholder => {
        coordinationStakeholder.push(stakeholder)
      })
    })
    return coordinationStakeholder;
  }

  updateInsurance() {
    this.$subscrGetPersonInsurance = this.insuranceService.getPersonInsurance(this.caseId.toString()).subscribe(result => {
      this.personInsurance = result;
      this.timeline.insuranceEnd = !!this.personInsurance.insurancePeriodTo ? this.personInsurance.insurancePeriodTo.toDate() : null;
      this.coverageDays = this.personInsurance.coverageDays;
      this.timeline.coverageDayEnd = moment(this.timeline.travelBegin)
        .add(this.helperService.getNumberFromString(this.coverageDays) - 1, 'days')
        .toDate();
      this.checkOverlappingDays();
      this.checkIfAccidentDateValid(this.timeline.accidentDate);
    });
  }

  updatePlannedTravel() {
    this.plannedTravelService.getPlannedTravel(this.caseId.toString()).subscribe(result => {
      this.plannedTravel = result;
      this.timeline.travelBegin = this.plannedTravel.departureDate ? this.plannedTravel.departureDate.toDate() : null;
      this.timeline.travelEnd = this.plannedTravel.arrivalDate ? this.plannedTravel.arrivalDate.toDate() : null;
      this.updateInsurance();
    });
  }

  loadMoreDaysTop(days: number) {
    this.timelineText = 'Loading...';
    const startDate = moment(this.daysInTimeline[0]).subtract(days, 'days').toDate();
    const endDate = moment(this.daysInTimeline[0]).subtract(1, 'days').toDate();
    const daysListTop = [];
    for (let date = startDate; date <= endDate; date = moment(date).add(1, 'days').toDate()) {
      daysListTop.push(date);
    }
    this.daysInTimeline = daysListTop.concat(this.daysInTimeline);
    this.timelineText = 'Load dates';
    this.refreshServiceOrders();
    this.refreshPlan();
  }

  loadMoreDaysBottom(days: number) {
    this.timelineText = 'Loading...';
    const startDate = moment(this.daysInTimeline[this.daysInTimeline.length - 1])
      .add(1, 'days')
      .toDate();
    const endDate = moment(startDate)
      .add(days - 1, 'days')
      .toDate();
    for (let date = startDate; date <= endDate; date = moment(date).add(1, 'days').toDate()) {
      this.daysInTimeline.push(date);
    }
    this.timelineText = 'Load dates';
    this.refreshServiceOrders();
    this.refreshPlan();
  }

  generateTimelineDays() {
    this.daysInTimeline = [];
    const startDate = moment(this.today).subtract(2, 'days').toDate();
    const endDate = moment(this.today).add(8, 'days').toDate();

    for (let date = startDate; date <= endDate; date = moment(date).add(1, 'days').toDate()) {
      this.daysInTimeline.push(date);
    }
  }

  checkOverlappingDays() {
    if (moment(this.timeline.accidentDate).isSame(this.timeline.travelBegin)) {
      this.shiftTravelBegin = true;
    }
    if (moment(this.timeline.accidentDate).isSame(this.timeline.travelEnd)) {
      this.shiftTravelEnd = true;
    }
    if (moment(this.timeline.coverageDayEnd).isSame(this.timeline.travelEnd)) {
      this.shiftTravelEnd = true;
      if (moment(this.timeline.coverageDayEnd).isSame(this.timeline.accidentDate)) {
        this.shiftCoverageDayUp = true;
      }
    }
    if (moment(this.timeline.coverageDayEnd).isSame(this.timeline.travelBegin)) {
      this.shiftTravelBegin = true;
      if (moment(this.timeline.coverageDayEnd).isSame(this.timeline.accidentDate)) {
        this.shiftCoverageDayDown = true;
      }
    }
  }

  generateTimeline() {
    this.serviceOrders = [];
    this.daysInTimeline = [];
    this.timeline = new Timeline();
    this.updateIncident();
    this.updatePlannedTravel();
    this.generateTimelineDays();
    this.loadMoreDaysTop(60);
    this.loadMoreDaysBottom(60);
  }

  refreshServiceOrders() {
    if (this.serviceOrderComponents != null) {
      this.serviceOrderComponents.forEach(serviceOrderComponent => {
        serviceOrderComponent.draw();
      });
    }
  }

  getHeightForDate(date: Date): number {
    if ( this.planHelper == null ) {
      return PlanDisplayHelper.defaultCellHeight;
    }
    else {
      const entry = date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate();
      return this.planHelper.cellHeightForDateMap[entry] || PlanDisplayHelper.defaultCellHeight;
    }
  }

  getHeightForDateCell(date: Date): number {
    if (this.planHelper == null) {
      return PlanDisplayHelper.defaultCellHeight;
    }
    return this.planHelper.getMaximumHeightForDateCell(date);
  }

  refreshPlan() {
    if (this.serviceOrders == null || this.daysInTimeline == null || this.stakeholders.length === 0) {
      return;
    }
    this.serviceOrders = this.serviceOrders.filter(a => a.isDuplicated !== true);
    //this.serviceOrders = this.serviceOrders.filter(so => !this.coordinationCaseService.linkedToIcc(so, this.iccServiceOrders));
    this.theServiceHasBeenShown = true;
    // Reset lane presentation values
    this.serviceOrders.forEach(a => {
      a.laneNumber = a.laneNumber ? a.laneNumber : PlanDisplayHelper.END_USER_LANE;
      a.overlappingPosition = null;
      a.candidateOverlappingPosition = null;
      a.numberOfOverlappingServiceOrders = null;
      a.overlappingGroupId = null;
      a.extendedHeight = 0;
      a.cellPosOffset = 0;
      a.cellHeight = 0;
      a.hidden = false;
      if (a.serviceId == null) {
        a.serviceId = this.helperService.getRandomGuid();
      }
    });
    this.checkStakeholderTypes();
    this.numberOfLanes = 1 + (this.isCoTraveller ? 1 : 0) + (this.isMedicalEscort ? 1 : 0);
    this.serviceOrders.sort((a, b) => a.getStartDateLocal()?.getTime() - b.getStartDateLocal()?.getTime());
    // Hide the service orders outside of our timeline
    for (const serviceOrder of this.serviceOrders) {
      if (
        serviceOrder.getStartDateLocal() < this.daysInTimeline[0] ||
        serviceOrder.getEndDateLocal() > this.daysInTimeline[this.daysInTimeline.length - 1]
      ) {
        serviceOrder.hidden = true;
      }
    }
    this.serviceOrders
      .filter(a => a.hidden === false)
      .forEach(serviceOrder => {
        // calculate the overlap position
        const serviceHelper = new ServiceOrderHelper(
          serviceOrder,
          this.serviceOrders.filter(a => a.hidden === false)
        );
        serviceOrder.numberOfOverlappingServiceOrders = serviceHelper.numberOfOverlappingServiceOrders();
        serviceHelper.calculateOverlappingPosition();
    });
    this.planHelper = new PlanDisplayHelper(this.serviceOrders.filter(a => a.hidden === false));
    this.daysInTimeline.forEach(date => {
      this.planHelper.getMaximumHeightForDateCell(date);
    });
    this.serviceOrders
      .filter(a => a.hidden === false)
      .forEach(serviceOrder => {
        serviceOrder.extendedHeight = this.planHelper.calculateDateHeightAdjustForLongServices(serviceOrder);
      });
    this.serviceOrdersCalculated = [];
    this.serviceOrders.forEach(serviceOrder => {
      this.serviceOrdersCalculated.push(_.clone(serviceOrder));
    });
    this.currentDayTop = this.calcCurrentDayTop();
    this.addExtraDayToPlansView();
  }

  addExtraDayToPlansView() {
    if(this.serviceOrdersCalculated.length != 0) {
      const sortServiceOrdersCalculated = _.clone(this.serviceOrdersCalculated);
      sortServiceOrdersCalculated.sort((a, b) => a.getEndDateLocal()?.getTime() - b.getEndDateLocal()?.getTime());
      const lastDateOfTimeLine = moment(this.daysInTimeline[this.daysInTimeline.length - 1]);
      const lastDateOfServiceOrder = moment(sortServiceOrdersCalculated[sortServiceOrdersCalculated.length - 1].getEndDisplayDate());
      if(lastDateOfServiceOrder.isAfter(lastDateOfTimeLine)) {
        this.loadMoreDaysBottom(lastDateOfServiceOrder.diff(lastDateOfTimeLine, 'days') + 1);
      }
    }
  }
}
