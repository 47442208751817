import { SubComponentModel } from '@secca/case/components/case-plans/case-plans/add-service-order/models/interfaces';

export class SubRepatriationFormAmbulanceFlight implements SubComponentModel {
  repatriationInstructionId: number;
  medicalAssessmentId: number;
  luggageDescription: string;
  height: number;
  weight: number;

  public constructor(init?: Partial<SubRepatriationFormAmbulanceFlight>) {
    Object.assign(this, init);
  }

  isValid(): boolean {
    if (this.repatriationInstructionId){
      return true;
    }
    return false;

  }

}
