import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {CoordinationTypeEnum, ValidationTypeEnum} from '@secca/shared/models/enums';
import {CaseIncident} from '@secca/shared/models/caseIncident';
import {IncidentService} from '@secca/core/services/incident.service';
import {Case} from '@secca/shared/models/case';
import {IncidentStateService} from '@secca/core/state-services/incident-state-service';
import {CaseValidationService} from '@secca/core/services/case-validation.service';
import {DictionaryService} from '@secca/core/services/dictionary.service';
import {CoordinationDetails} from '@secca/shared/models/coordination-details';
import {CoordinationDetailsService} from '@secca/core/services/coordination-details.service';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { DisableableDropdownDictionary, DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import { CaseTypeCodes } from '@secca/shared/models/case-type';
import { MajorIncidentDetailsService } from '@secca/core/services/major-incident-details.service';
import { MajorIncidentDetails } from '@secca/shared/models/Major-incident-details';

@Component({
  selector: 'app-coordination-case',
  templateUrl: './coordination-case.component.html',
  styleUrls: ['./coordination-case.component.scss']
})
export class CoordinationCaseComponent implements OnInit {
  @Input()
  set case(newCase: Case) {
    if (newCase) {
      this._case = newCase;
      this.updateTypeList();
    }
  }
  get case(): Case {
    return this._case;
  }

  @Input() caseTypeCode: string;
  private coordinationTypes: DisableableDropdownDictionary[] = [];
  private _case: Case;

  constructor(private coordinationDetailsService: CoordinationDetailsService,
              private majorIncidentDetailsService: MajorIncidentDetailsService,
              private incidentService: IncidentService,
              private incidentStateService: IncidentStateService,
              private caseValidationService: CaseValidationService,
              public caseStateService: CaseStateService,
              private dictionaryService: DictionaryService) {
  }

  readonly ValidationTypeEnum = ValidationTypeEnum;
  readonly CoordinationTypeEnum = CoordinationTypeEnum;

  incident: CaseIncident;
  coordinationDetails: CoordinationDetails;
  majorIncidentDetails: MajorIncidentDetails;

  ngOnInit(): void {
    this.incidentService.getCaseIncident(this.case.incidentId).subscribe(result => {
      this.incident = result;
    });


    if(this.caseTypeCode === CaseTypeCodes.INTERNAL_COORDINATION) {
      this.coordinationDetailsService.getCoordinationDetails(this.case.id).subscribe(
        result =>  {
          this.coordinationDetails = result || new CoordinationDetails();
        }
      ,);
    }


    if(this.caseTypeCode === CaseTypeCodes.MAJOR_INCIDENT) {
      this.majorIncidentDetailsService.getMajorIncidentDetails(this.case.id).subscribe(
        result =>  {
          this.coordinationDetails = result || new CoordinationDetails();
        }
      ,);
    }


    this.incidentStateService.getIncident().subscribe(
        result => {
          if (result && result.caseId === this.case.id) { this.incident = result.incident; }
        }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(!changes.caseTypeCode?.firstChange) {
      if(changes.caseTypeCode) {
        changes.caseTypeCode.currentValue === CaseTypeCodes.MAJOR_INCIDENT ? this.coordinationDetails.type = CoordinationTypeEnum.MAJOR_INCIDENT : this.coordinationDetails.type = null;
        this.saveCoordinationDetails();
      }
    }
    this.updateTypeList();
  }

  updateTypeList() {
    this.coordinationTypes = [];
    this.dictionaryService.getCoordinationTypeDictionary().forEach(type => {
       this.coordinationTypes.push(new DisableableDropdownDictionary(type.id, type.name, this.typeActive(type)))
      }
    );
  }

  typeActive(type: DropdownDictionary): boolean {
    if(type.id === CoordinationTypeEnum.MAJOR_INCIDENT) {
      return this.case.caseTypeCode !== CaseTypeCodes.MAJOR_INCIDENT ? true : false;
    } else {
      return this.case.caseTypeCode !== CaseTypeCodes.INTERNAL_COORDINATION ? true : false;
    }
  }

  updateIncidentLocation() {
    this.incidentService.updateIncidentLocation(this.incident.id, this.incident.incidentLocation).subscribe(() => {
      this.incidentStateService.sendIncident(this.incident, this.case.id);
      this.caseValidationService.sendVerifyCaseNumberAssigned(this.case.id);
    });
  }

  saveCoordinationDetails() {
    if(this.case.caseTypeCode === CaseTypeCodes.INTERNAL_COORDINATION) {
      this.coordinationDetailsService.saveCoordinationDetails(this.case.id, this.coordinationDetails).subscribe(
        () => {}
      );
    } else if(this.case.caseTypeCode === CaseTypeCodes.MAJOR_INCIDENT) {
      this.majorIncidentDetailsService.saveMajorIncidentDetails(this.case.id, this.case.caseTypeCode, this.coordinationDetails).subscribe(
        () => {}
      );
    }
  }

  get caseTypeCodes(){
    return CaseTypeCodes;
  }
}
