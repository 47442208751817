import { ApplicationRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DictionaryService } from '@secca/core/services/dictionary.service';
import { AutoCompleteTypeEnum } from '@secca/shared/components/drop-down-auto-complete/auto-complete-type-enum';
import { DropDownAutoCompleteComponent } from '@secca/shared/components/drop-down-auto-complete/drop-down-auto-complete.component';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { SearchUserAndTeam } from '@secca/shared/models/searchUserAndTeam';

@Component({
  selector: 'app-case-handler-selector',
  templateUrl: './case-handler-selector.component.html',
  styleUrls: ['./case-handler-selector.component.scss'],
})
@AutoUnsubscribe
export class CaseHandlerSelectorComponent implements OnInit {
  @ViewChild('caseHandlerDropDown') caseHandlerDropDown: DropDownAutoCompleteComponent;

  @Input() acHandler: SearchUserAndTeam;
  @Input() isViewOnly: boolean;
  @Input() required: boolean;
  @Input() caseId: string;
  @Output() caseHandlerChange = new EventEmitter<SearchUserAndTeam>();

  public isCaseHandlerVisible: boolean;

  constructor(private dictionaryService: DictionaryService,
              private applicationRef: ApplicationRef) {
  }

  ngOnInit(): void {
  }

  makeCaseHandlerInVisible(e: Event): void {
    e.stopPropagation();
    this.isCaseHandlerVisible = false;
  }

  makeCaseHandlerVisible(e: Event): void {
    if ( !this.isViewOnly ) {
      e.stopPropagation();
      this.isCaseHandlerVisible = true;
      this.applicationRef.tick();
      this.caseHandlerDropDown.setFocus();
    }
  }

  onCaseHandlerChanged(userOrTeam: SearchUserAndTeam): void {
    if (userOrTeam.isTeam) {
      this.acHandler = userOrTeam;
      this.caseHandlerChange.next(this.acHandler);
    } else {
      const id: number = userOrTeam.id;
      this.isCaseHandlerVisible = false;
      this.dictionaryService.searchUsersExact(id).subscribe(
        result => {
          if (result.length !== 1) {
            console.log('There is no user with id ' + id);
          } else {
            this.acHandler = new SearchUserAndTeam();
            this.acHandler.initializeFromSearchUser(result[0]);
            this.caseHandlerChange.next(this.acHandler);
          }
        },
        error => console.log(error)
      );
    }
  }

  get acHandlerSelected(): boolean {
    return !!this.acHandler?.adtCode || !!this.acHandler?.id;
  }

  get AutoCompleteTypeEnum() {
    return AutoCompleteTypeEnum;
  }
}
