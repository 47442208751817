import { RepatriationExtraSpace, RepatriationExtraSpaceAdapter } from './repatriation-extra-space';
import { ExtraSpaceEnum, RepatriationFormEnum } from '../enums';

import { Injectable } from '@angular/core';
import { Adapter } from '../../interfaces/adapter';
import * as moment from 'moment';
import { DateSerializer } from '../date-serializer';
import * as onChange from 'on-change';

export class RepatriationAlternativeForm {
  repatriationForm: RepatriationFormEnum;
  date: moment.Moment;
  extraSpaces: RepatriationExtraSpace[];
  formSelected: boolean;

  public constructor(init?: Partial<RepatriationAlternativeForm>) {
    Object.assign(this, init);
    this.extraSpaces = !!init && !!init.extraSpaces ? init.extraSpaces : new Array<RepatriationExtraSpace>();
  }

  public getExtraSpace(type: ExtraSpaceEnum): RepatriationExtraSpace {
    let value = null;
    this.extraSpaces.forEach(item => item.type === type ? value = item : {});
    return value;
  }

  public setExtraSpace(item: RepatriationExtraSpace) {
    this.removeExtraSpace(item.type);
    this.extraSpaces.push(item);
  }

  public removeExtraSpace(type: ExtraSpaceEnum) {
    this.extraSpaces = this.extraSpaces.filter(item => item.type !== type);
  }
}

@Injectable({
  providedIn: 'root'
})
export class RepatriationAlternativeFormAdapter implements Adapter<RepatriationAlternativeForm> {
  adapt(item: any): RepatriationAlternativeForm {
    const targetItem = JSON.parse(JSON.stringify(onChange.target(item)));
    return new RepatriationAlternativeForm({
      repatriationForm: targetItem.repatriationForm,
      date: !!targetItem.date ? DateSerializer.deserialize(targetItem.date) : null,
      extraSpaces: !!targetItem.extraSpaces ? this.adaptExtraSpaces(targetItem.extraSpaces) : new Array<RepatriationExtraSpace>(),
      formSelected: targetItem.formSelected
    });
  }

  private adaptExtraSpaces(items: any[]) {
    const extraSpaces = new Array<RepatriationExtraSpace>();
    items.forEach(item => extraSpaces.push(new RepatriationExtraSpaceAdapter().adapt(item)));
    return extraSpaces;
  }
}
