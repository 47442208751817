<div class="menu-container">
  <div class="menu">
    <div class="item item-rounded-left" (click)="boardOnClick()" [ngClass]="{'item-selected': boardActive}">
      <img class="image" [ngClass]="{'image-selected': boardActive, 'image-hidden': !boardActive}"
        src="../../../../assets/icons/Board Active.svg">
      <img class="image" [ngClass]="{'image-selected': boardActive, 'image-hidden': boardActive}"
        src="../../../../assets/icons/Board Inactive.svg">
    </div>
    <div class="item" (click)="newCaseOnClick()">
      <img class="image" src="../../../../assets/icons/Create New.svg">
    </div>
    <div ngbDropdown class="item">
      <div>
        <img class="image" src="../../../../assets/icons/Clock-Secca-top.svg" width="36" height="36" (click)="openRecentCases()">
      </div>
    </div>
    <div ngbDropdown class="item item-rounded">
      <div ngbDropdownToggle>
        <img class="image" src="../../../../assets/icons/Questionmark-white.svg" width="36" height="36">
        <div ngbDropdownMenu>
<!--          <button ngbDropdownItem>FAQ</button>-->
          <button ngbDropdownItem (click)="openKeyboardShortcuts()"><span class="shortcut-key">K</span>eyboard Shortcuts</button>
        </div>
      </div>
    </div>
  </div>
  <app-minimized></app-minimized>
</div>
