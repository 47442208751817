<div class="sub-component-container" *ngIf="subRepatriationDetails">
  <div class="sub-title">{{'sub-repatriation-details-title' | translate}}</div>
  <div class="sub-content-container p-0">
    <div class="row m-0 header-container">
      <div class="col-4 header">{{'sub-repatriation-details-header-item' | translate}}</div>
      <div class="col-4 header">{{'sub-repatriation-details-header-requested' | translate}}</div>
      <div class="col-4 header">{{'sub-repatriation-details-header-booked' | translate}}</div>
    </div>
    <div class="row">
      <div class="col-4">{{'sub-repatriation-details-repatriation-possible-by' | translate}}</div>
      <div class="col-4">
        <ng-container *ngIf="medicalAssessment?.repatriationInstructionsBase?.repatriationForm?.repatriationDate">
          {{medicalAssessment?.repatriationInstructionsBase?.repatriationForm?.repatriationDate | date: 'dd MMM yyyy'}}
        </ng-container>
      </div>
      <div class="col-4">
        <app-input-calendar
          [withImage]="true"
          [date]="subRepatriationDetails.repatriationPossibleBy" 
          (dateChange)="updateRepatriationPossibleBy($event)">
        </app-input-calendar>
      </div>
    </div>
    <div class="row">
      <div class="col-4">{{'sub-repatriation-details-repatriation-form' | translate}}</div>
      <div class="col-4">
        {{repatriationFormRequested}}<br>
      </div>
      <div class="col-4">
        <app-drop-down [items]="repatriationFormTypesDropdown"
                       [(selectedItemId)]="subRepatriationDetails.repatriationForm"
                       [disabled]="false"
                       [appendTo]="'body'"></app-drop-down>
      </div>
    </div>
    <div class="row">
      <div class="col-4">{{'sub-repatriation-details-co-transport-acceptable' | translate}}</div>
      <div class="col-4">
        {{medicalAssessment?.repatriationInstructionsBase?.repatriationForm?.coTransport ? ('sub-repatriation-details-yes' | translate) : ('sub-repatriation-details-no' | translate)}}
      </div>
      <div class="col-4">
        <app-drop-down [items]="coTransportAcceptableTypesDropdown"
                       [(selectedItemId)]="subRepatriationDetails.coTransportAcceptable"
                       [disabled]="false"
                       [appendTo]="'body'"></app-drop-down>
      </div>
    </div>

    <div [ngSwitch]="serviceOrderType">
      <span *ngSwitchCase="ServiceTypeEnum.REGULAR_FLIGHT_MEDICAL">
        <div class="row">
          <div class="col-4">{{'sub-repatriation-details-wheelchair' | translate}}</div>
          <div class="col-4">{{wheelchairRequested}}</div>
          <div class="col-4">
            <app-drop-down [items]="wheelchairTypesDropdown"
                           [(selectedItemId)]="subRepatriationDetails.wheelchair"
                           [disabled]="false"
                           [appendTo]="'body'"></app-drop-down>
          </div>
        </div>    
        <div class="row">
          <div class="col-4">{{'sub-repatriation-details-medical-care-in-transit' | translate}}</div>
          <div class="col-4">
            {{medicalAssessment?.repatriationInstructionsBase?.patientNeeds?.medicalCareInTransit ? ('sub-repatriation-details-yes' | translate) : ('sub-repatriation-details-no' | translate)}}
          </div>
          <div class="col-4">
            <app-drop-down [items]="medicalCareInTransitTypesDropdown"
                           [(selectedItemId)]="subRepatriationDetails.medicalCareInTransit"
                           [disabled]="false"
                           [appendTo]="'body'"></app-drop-down>
          </div>
        </div>
        <div class="row">
          <div class="col-4">{{'sub-repatriation-details-admission-upon-arrival' | translate}}</div>
          <div class="col-4">
            <ng-container *ngIf="medicalAssessment?.repatriationInstructionsBase?.patientNeeds">
              {{medicalAssessment?.repatriationInstructionsBase?.patientNeeds?.admissionUponArrival ? ('sub-repatriation-details-yes' | translate) : ('sub-repatriation-details-no' | translate)}}
            </ng-container>
          </div>
          <div class="col-4">
            <app-drop-down [items]="admissionUponArrivalTypesDropdown"
                           [(selectedItemId)]="subRepatriationDetails.admissionUponArrival"
                           [disabled]="false"
                           [appendTo]="'body'"></app-drop-down>
          </div>
        </div>
        <div class="row">
          <div class="col-4">{{'sub-repatriation-details-oxygen-in-flight' | translate}}</div>
          <div class="col-4">
            <ng-container *ngIf="medicalAssessment?.repatriationInstructionsBase?.patientNeeds">
              {{medicalAssessment?.repatriationInstructionsBase?.patientNeeds?.inFlightOxygenNeeded ? 
                ('sub-repatriation-details-yes' | translate)  + ', ' + getOxygenNeededInFlight(medicalAssessment) : 
                ('sub-repatriation-details-no' | translate)
              }}
            </ng-container>
          </div>
          <div class="col-4">
            <app-drop-down [items]="oxygenInFlightTypesDropdown"
                           [(selectedItemId)]="subRepatriationDetails.oxygenInFlight"
                           [disabled]="false"
                           [appendTo]="'body'"></app-drop-down>
          </div>
        </div>
        <div class="row">
          <div class="col-4">{{'sub-repatriation-details-oxygen-on-ground' | translate}}</div>
          <div class="col-4">
            <ng-container *ngIf="medicalAssessment?.repatriationInstructionsBase?.patientNeeds">
              {{medicalAssessment?.repatriationInstructionsBase?.patientNeeds?.onGroundOxygenNeeded ? 
                ('sub-repatriation-details-yes' | translate) + ', ' + getOxygenNeededOnGround(medicalAssessment) : 
                ('sub-repatriation-details-no' | translate)
              }}
            </ng-container>
          </div>
          <div class="col-4">
            <app-drop-down [items]="oxygenOnGroundTypesDropdown"
                           [(selectedItemId)]="subRepatriationDetails.oxygenOnGround"
                           [disabled]="false"
                           [appendTo]="'body'"></app-drop-down>
          </div>
        </div>
        <div class="row">
          <div class="col-4">{{'sub-repatriation-details-oxygen-supplied-by-sos' | translate}}</div>
          <div class="col-4 input-special">
            <app-drop-down [items]="oxygenSuppliedBySosRequestedTypesDropdown"
                           [(selectedItemId)]="subRepatriationDetails.oxygenSuppliedBySosRequested"
                           [disabled]="false"
                           [appendTo]="'body'"></app-drop-down>
          </div>
          <div class="col-4">
            <app-drop-down [items]="oxygenSuppliedBySosBookedTypesDropdown"
                           [(selectedItemId)]="subRepatriationDetails.oxygenSuppliedBySosBooked"
                           [disabled]="false"
                           [appendTo]="'body'"
                           (selectedItemIdChange)="oxygenSuppliedBySosChanged($event)"></app-drop-down>
          </div>
        </div>
        <div class="row">
          <div class="col-4">{{'sub-repatriation-details-sos-oxygen-on-escort-outbound-flights' | translate}}</div>
          <div class="col-4">{{'sub-repatriation-details-sos-oxygen-supplied-by-sos' | translate}}</div>
          <div class="col-4">
            <app-drop-down [items]="sosOxygenOnEscortOutboundFlightsTypesDropdown"
                           [(selectedItemId)]="subRepatriationDetails.sosOxygenOnEscortOutboundFlights"
                           [disabled]="subRepatriationDetails.oxygenSuppliedBySosBooked === DetailsCommonListTypeEnum.NOT_RELEVANT"
                           [appendTo]="'body'"></app-drop-down>
          </div>
        </div>
        <div class="row">
          <div class="col-4">{{'sub-repatriation-details-sos-oxygen-on-escort-return-flights' | translate}}</div>
          <div class="col-4">{{'sub-repatriation-details-sos-oxygen-supplied-by-sos' | translate}}</div>
          <div class="col-4">
            <app-drop-down [items]="sosOxygenOnEscortReturnFlightsTypesDropdown"
                           [(selectedItemId)]="subRepatriationDetails.sosOxygenOnEscortReturnFlights"
                           [disabled]="subRepatriationDetails.oxygenSuppliedBySosBooked === DetailsCommonListTypeEnum.NOT_RELEVANT"
                           [appendTo]="'body'"></app-drop-down>
          </div>
        </div>
        <div class="row">
          <div class="col-4">{{'sub-repatriation-details-escort-1' | translate}}</div>
          <div class="col-4">{{getEscortRequested(0)}}</div>
          <div class="col-4">
            <app-drop-down [items]="escort1TypesDropdown"
                           [(selectedItemId)]="subRepatriationDetails.escort1"
                           [disabled]="false"
                           [appendTo]="'body'"></app-drop-down>
          </div>
        </div>
        <div class="row">
          <div class="col-4">{{'sub-repatriation-details-escort-2' | translate}}</div>
          <div class="col-4">{{getEscortRequested(1)}}</div>
          <div class="col-4">
            <app-drop-down [items]="escort2TypesDropdown"
                           [(selectedItemId)]="subRepatriationDetails.escort2"
                           [disabled]="false"
                           [appendTo]="'body'"></app-drop-down>
          </div>
        </div>
        <div class="row">
          <div class="col-4">{{'sub-repatriation-details-equipment' | translate}}</div>
          <div class="col-4">{{equipmentRequested}}</div>
          <div class="col-4">
            <app-drop-down [items]="equipmentTypesDropdown"
                           [(selectedItemId)]="subRepatriationDetails.equipment"
                           [disabled]="false"
                           [appendTo]="'body'"></app-drop-down>
          </div>
        </div>
        <div class="row">
          <div class="col-4">{{'sub-repatriation-details-medication' | translate}}</div>
          <div class="col-4">{{medicationRequested}}</div>
          <div class="col-4">
            <app-drop-down [items]="medicationTypesDropdown"
                           [(selectedItemId)]="subRepatriationDetails.medication"
                           [disabled]="false"
                           [appendTo]="'body'"></app-drop-down>
          </div>
        </div>
      </span>

      <span *ngSwitchCase="ServiceTypeEnum.AMBULANCE_FLIGHT">
        <div class="row">
          <div class="col-4">{{'sub-repatriation-details-ground-ambulance-to-ambulance-flight' | translate}}</div>
          <div class="col-4">{{'sub-repatriation-details-status' | translate}}</div>
          <div class="col-4">
            <app-drop-down [items]="groundAmbulanceToAmbulanceFlightTypesDropdown"
                           [(selectedItemId)]="subRepatriationDetails.groundAmbulanceToAmbulanceFlight"
                           [disabled]="false"></app-drop-down>
          </div>
        </div>
        <div class="row">
          <div class="col-4">{{'sub-repatriation-details-ground-ambulance-from-ambulance-flight' | translate}}</div>
          <div class="col-4">{{'sub-repatriation-details-status' | translate}}</div>
          <div class="col-4">
            <app-drop-down [items]="groundAmbulanceFromAmbulanceFlightTypesDropdown"
                           [(selectedItemId)]="subRepatriationDetails.groundAmbulanceFromAmbulanceFlight"
                           [disabled]="false"></app-drop-down>
          </div>
        </div>
        <div class="row">
          <div class="col-4">{{'sub-repatriation-details-admission-upon-arrival' | translate}}</div>
          <div class="col-4">
            <ng-container *ngIf="medicalAssessment?.repatriationInstructionsBase?.patientNeeds">
              {{medicalAssessment?.repatriationInstructionsBase?.patientNeeds?.admissionUponArrival ? ('sub-repatriation-details-yes' | translate) : ('sub-repatriation-details-no' | translate)}}
            </ng-container>
          </div>
          <div class="col-4">
            <app-drop-down [items]="admissionUponArrivalTypesDropdown"
                           [(selectedItemId)]="subRepatriationDetails.admissionUponArrival"
                           [disabled]="false"
                           [appendTo]="'body'"></app-drop-down>
          </div>
        </div>            
      </span>
    </div>
</div>
