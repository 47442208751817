import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogHelperUtilService } from '@secca/core/services/dialog-helper-util.service';
import * as moment from 'moment';
import { RecentCases, RecentCasesAdapter } from '@secca/shared/models/recent-cases';
import { Router } from '@angular/router';
import { CaseBoardService } from '@secca/board/components/board-cases/services/case-board.service';
import { BoardCase } from '@secca/board/components/board-cases/models/board-case.model';
import { CaseService } from '@secca/core/services/case.service';


@Component({
  selector: 'app-recent-cases-modal',
  templateUrl: './recent-cases-modal.component.html',
  styleUrls: ['./recent-cases-modal.component.scss']
})
export class RecentCasesModalComponent implements OnInit {
  recentCasesFromJSON: RecentCases[] = [];
  recentCases: RecentCases[] = [];
  boardCase: BoardCase[] = [];
  @Output() closed = new EventEmitter<number>();
  caseNumbers: number[] = [];

  constructor(private router: Router,
              private modalService: NgbModal,
              private dialogHelperUtilService: DialogHelperUtilService,
              private caseBoardService: CaseBoardService,
              private caseService: CaseService,    ) { }

  ngOnInit(): void {
    const oldCasesJSON = sessionStorage.getItem('top-menu-recent-cases');
    if(oldCasesJSON) {
      this.recentCasesFromJSON = JSON.parse(oldCasesJSON);
      this.recentCasesFromJSON.forEach(c => {
        this.caseNumbers.push(c.caseId);
      })

      this.caseBoardService.getBoardCaseByIds(this.caseNumbers).subscribe(
        result => {
            result.forEach(c => {
              this.recentCases.push(
                new RecentCases(
                   {
                    caseId: c.id,
                    user: c.endUserFullName,
                    cause: c.incidentCause,
                    country: c.incidentCountry,
                    caseNumber: c.caseNumber,
                    logo: c.customerProfileId ? this.caseService.getBrandImagesUrlForProfile(c.customerProfileId.toString()) : null,
                    incidentDate: c.incidentDate ? this.getIncidentDate(c.incidentDate) : null,
                    exitTime: this.deserializeDate(this.recentCasesFromJSON.find(oldC => c.id === +oldC.caseId).exitTime.toString())

                   }
                )
              );
            })
            this.recentCases.sort((a, b) => moment(a.exitTime).isBefore(moment(b.exitTime)) ? 1 : -1,)
          }
      );
    }
  }

  close() {
    if (this.closed.observers) {
      this.closed.emit();
    }
    if (this.modalService.hasOpenModals()) {
      this.modalService.dismissAll();
    }
    this.dialogHelperUtilService.closeModal();
  }

  redirectToCase(caseId: string) {
    this.router.navigate(['case', caseId]);
    this.close();
  }

  getIncidentDate(date: Date): string {
    return moment(date).format('D MMM YYYY');;
  }

  getExitDate(date: Date): string {
    return moment(date).format('dddd, D MMM YYYY HH:mm');;
  }

  private deserializeDate(dateTimeString: string): moment.Moment {
    dateTimeString = dateTimeString.substring(0, 10) + " " + dateTimeString.substring(10, dateTimeString.length);
    if (!!dateTimeString) {
      if (dateTimeString.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?(Z|[-+]\d{2}:\d{2})?/)) {
        return moment(dateTimeString);
      } else {
        return moment(dateTimeString.substr(0, 19));
      }
    } else {
      return null;
    }
  }

}
