import { ShortcutService } from '@secca/core/services/shortcut.service';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShortcutEnum } from '@secca/shared/models/enums';
import { DialogViewerServiceInterface } from '@secca/core/services/dialog-viewer-service-interface';
import { DIALOG_VIEWER_TOKEN } from '@secca/core/services/token';
@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent implements OnInit {

  get boardActive(): boolean {
    return this.router.url === '/board';
  }

  constructor(private router: Router,
              private shortcutService: ShortcutService,
              @Inject(DIALOG_VIEWER_TOKEN) private dialogViewerService: DialogViewerServiceInterface) {
    this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateToTheBoard }).subscribe(a => {
      this.boardOnClick();
    });
    this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateToTheNewCase }).subscribe(a => {
      this.newCaseOnClick();
    });
    this.shortcutService.addShortcut({ keys: ShortcutEnum.KeyboardShortcuts }).subscribe(a => {
      this.openKeyboardShortcuts();
    });
    this.shortcutService.addShortcut({ keys: ShortcutEnum.ModalOpenRecentCases }).subscribe(a => {
      this.openRecentCases();
    });
  }

  ngOnInit() {}

  boardOnClick() {
    this.router.navigate(['board']);
  }

  newCaseOnClick() {
    this.dialogViewerService.openSearchCaseDialog();
  }

  openKeyboardShortcuts() {
    this.dialogViewerService.openKeyboardShortcutsDialogComponent();
  }

  openRecentCases() {
    this.dialogViewerService.openRecentCasesDialogComponent();
  }
}
