<div class="modal-content">
  <div class="modal-header">
    <div class="modal-header-title">
      <span>{{'case-summary-note-linked-case-copy-note' | translate }}</span>
    </div>
    <div class="modal-buttons">
      <div (click)="closeModal()">
        <img src="/assets/icons/Close White.svg" />
      </div>
    </div>
  </div>
  <div class="body-1 modal-body">

    <div class="case-basics-card case-basic-info-linked-cases" style="min-height: 442px;" >
      <div class="row">

        <div class="col">
          <div class="headline-container">
            <div class="d-flex headline">
              <div class="case-checkbox">
                <div class="custom-checkbox">
                    <label class="checkbox">
                      <input type="checkbox" (click)="selectAll()" [(ngModel)]="isCheckAll">
                      <span class="checkmark"></span>
                    </label>
                </div>
              </div>
              <div class="casenumber casenumber-headline">{{ 'case-basics-linked-cases-case-number' | translate }}</div>
              <div class="name" >{{ 'case-basics-linked-cases-name' | translate }}</div>
              <div class="age" >{{ 'case-basics-linked-cases-age' | translate }}</div>
              <div class="primary">{{ 'case-basics-linked-cases-primary-case-handler' | translate }}</div>
              <div class="status">{{ 'case-basics-linked-cases-status' | translate }}</div>
            </div>
          </div>
          <div class="list-container">
            <div *ngIf="linkedCases">
              <ng-container *ngFor="let linkedCase of linkedCases; index as i">
                <div class="d-flex line {{alreadyCopiedToCase(linkedCase.caseId) ? 'grayed-out':''}}" *ngIf="linkedCase.caseId != caseId" [appDisableInput]="alreadyCopiedToCase(linkedCase.caseId)">
                  <div class="case-checkbox">
                    <div class="custom-checkbox" >
                      <label class="checkbox">
                        <input type="checkbox" (click)="$event.stopPropagation()" (change)="checkCase(linkedCase, $event)" [ngModel]="isCaseSelected(linkedCase)">
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div class="casenumber">
                    {{ linkedCase.caseNumber }}
                  </div>
                  <div class="name">
                    {{ linkedCase.name }}
                  </div>
                  <div class="age">
                    {{ linkedCase.age }}
                  </div>
                  <div class="primary">
                    {{ linkedCase.primary }}
                  </div>
                  <div class="status">
                    <img src="/assets/icons/folder-outline.svg" *ngIf="linkedCase.status === HandlingAreaStatusEnum.CLOSED"  />
                    <img src="/assets/icons/folder-open-outline.svg" *ngIf="linkedCase.status === HandlingAreaStatusEnum.REOPENED"  />
                    <img src="/assets/icons/folder-more-open.svg" *ngIf="linkedCase.status === HandlingAreaStatusEnum.OPEN"  />
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="col button-box">
          <button [disabled]="!anySelected"
                  (click)="copyNotes()"
                  [class.primary-button-inactive]="!anySelected"
                  class="primary-button m-2">{{ 'case-summary-note-linked-case-copy-note-button' | translate }}</button>
        </div>
      </div>
    </div>


  </div>
<div *ngIf="showSpinner" class="overlay d-flex justify-content-center">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
</div>
