import { TranslateService } from '@ngx-translate/core';
import { UserTeamDtoAdapter } from '../../../../shared/models/user-team-dto';
import { TeamDto } from './../../../../shared/models/teamDto';
import { BoardTeamCaringService } from './board-team-caring.service';
import { ViewEncapsulation, HostListener, Input, OnChanges } from '@angular/core';
import { DropdownDictionary } from 'src/app/shared/models/dropdownDictionary';
import { BoardShareService, BoardShareCommunicationModel } from './../../../../core/services/board-share.service';
import { DictionaryService } from './../../../../core/services/dictionary.service';
import { Component } from '@angular/core';
import { UserTeam } from 'src/app/shared/models/enums';
import { AutoUnsubscribe } from 'src/app/shared/decorators/auto-unsubscribe';
import { UserTeamDto } from '@secca/shared/models/user-team-dto';
import { UserDto } from '@secca/shared/models/userDto';
import { Subscription } from 'rxjs';
import { BoardNavigationStateService } from '../board-task/services/board-navigation-state.service';

@AutoUnsubscribe
@Component({
  selector: 'app-board-team-caring',
  templateUrl: './board-team-caring.component.html',
  styleUrls: ['./board-team-caring.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BoardTeamCaringComponent implements OnChanges {
  selectedworker: UserTeamDto[];
  highLightedId: any;
  $subscr2;
  $subscr3;
  $subscr4;
  focusOnUserIndex: number;

  @Input()
  isCaseView: boolean;

  constructor(
    private boardShareService: BoardShareService,
    private dictionaryService: DictionaryService,
    private boardTeamCaringService: BoardTeamCaringService,
    private userTeamDtoAdapter: UserTeamDtoAdapter,
    private translateService: TranslateService,
    private boardNavigationStateService: BoardNavigationStateService
  ) {
    this.$subscr2 = this.boardTeamCaringService.getShowBoardTeamCaring().subscribe(result => (this.hiddenContent = result));
    this.$subscr3 = this.dictionaryService.getAllActiveTeamsList().subscribe(result => {
      if (result != null) {
        this.allTeams = result;
        sessionStorage.setItem('teamNames', JSON.stringify(this.allTeams));
      }
    });

    this.$subscr4 = this.boardShareService.getCaseEventMenu().subscribe(result =>( this.disableUserDropdown = result));
  }

  searchUsers: UserTeamDto[];
  filteredSearchUsers: UserTeamDto[];
  sortedSearchUsers: UserTeamDto[];
  newFilteredSearchUsers: UserDto[];
  loggedInUser: UserTeamDto;
  selectedTeam: string;
  selectedTeams: string[] = [];
  selectedTeamsForSearch: string[] = [];
  selectedTemsOnly: boolean = true;
  teams: DropdownDictionary[] = [];
  users: DropdownDictionary[] = [];
  userIds: number[] = [-1];
  selectedUser: UserTeamDto;
  sessionTeamUserStorageInfo: BoardShareCommunicationModel;
  currentTeam = '';
  showResults = true;
  hiddenContent = true;
  allUserUser: UserTeamDto;
  allTeams: TeamDto[];
  sessionUsrAndTeamStorageAvailable: boolean = false;
  $loggedUserSubscr: Subscription;
  waitingForMoreButtonClicks: boolean = false;
  debounceSelectedTeamChanged: any;
  disableUserDropdown: boolean = false;
  selectedTeamNames: string[] = [];


  ngOnChanges() {
    if (this.$loggedUserSubscr) {
      this.$loggedUserSubscr.unsubscribe();
    }
    this.$loggedUserSubscr = this.dictionaryService.getLoggedInUser().subscribe(
      result => {
        if (result != null) {
          this.loggedInUser = this.userTeamDtoAdapter.adaptUser(result);
          this.currentTeam = this.loggedInUser.userTeam ? this.loggedInUser.userTeam.adtCode : UserTeam.all;
          this.selectedTeamsForSearch = [ '' ];
          this.checkIfNotCaseHandlerTeam();
          this.getSelectedTabTeamAndUser();
          if (!this.sessionUsrAndTeamStorageAvailable && this.currentTeam !== UserTeam.all) {
            this.selectedTeam = this.currentTeam;
            this.selectedTeams = [this.currentTeam];
            this.dictionaryService.searchUsersForTeams([this.loggedInUser.userTeam.adtCode]).subscribe(
              resultUsers => {
                this.selectedworker = [this.loggedInUser];
                this.sessionTeamUserStorageInfo = new BoardShareCommunicationModel();
                this.sessionTeamUserStorageInfo.adtCode = this.selectedTeam ? this.selectedTeam : null;
                this.sessionTeamUserStorageInfo.adtCodes = this.selectedTeams ? this.selectedTeams : null;
                let ids = [];
                this.selectedworker.forEach(e => {
                  ids.push(e.id);
                })
                this.sessionTeamUserStorageInfo.userIds = ids ? ids : null;
                this.sessionTeamUserStorageInfo.userDtos = this.selectedworker ? this.selectedworker : null;
                this.sessionTeamUserStorageInfo.teamOnly = false ;
                sessionStorage.setItem('team', JSON.stringify(this.sessionTeamUserStorageInfo));
                this.setUserTeamList(resultUsers as any);
                this.boardShareService.sendSelectedCaseWorker(new BoardShareCommunicationModel({adtCode: null, adtCodes: [''], userId: this.loggedInUser.id, userIds: ids, teamOnly: this.selectedTemsOnly, adtCodesForCount: this.selectedTeams, selectedTeamNames: this.selectedTeamNames }));
              },
              error => console.log(error)
            );
          } else if (this.currentTeam === UserTeam.all) {
            this.selectedTeam = this.currentTeam;
            this.selectedworker = [this.loggedInUser];
            this.getUsers(this.currentTeam, [this.currentTeam], false);
          } else {
            this.selectedTeam = this.currentTeam;
            if( this.selectedTeamsForSearch.length === 1 &&
                this.selectedTeamsForSearch[0] === '' &&
                this.userIds.length === 1 &&
                this.userIds[0] === -1 )
                {
                  this.selectedTeamsForSearch = this.selectedTeams;
                }

            this.dictionaryService.getAllActiveTeamsListDD().subscribe(result => {
              this.getUsers(this.currentTeam, this.selectedTeams, false);
            }, null);

            let ids = [-1];
            if(this.selectedworker) {
              this.selectedworker.forEach(e => {
                if(e.adtCode !== 'ADT00' && e.initials !== 'ADT00') {
                  if(e.isTeam) {
                    this.selectedTeamsForSearch.push(e.id.toString());
                  } else{
                    ids.push(e.id);
                  }
                }
              })
            }
            this.boardShareService.sendSelectedCaseWorker(new BoardShareCommunicationModel({adtCode: null, adtCodes: this.selectedTeamsForSearch, userIds: ids, teamOnly: this.selectedTemsOnly, adtCodesForCount: this.selectedTeams, selectedTeamNames: this.selectedTeamNames }));
          }
        }
      }
    );

    this.dictionaryService.getAllActiveTeamsListDD().subscribe(result => this.adjustTeams(result));
  }

  checkIfLogedInTeamIsPartOfTeamList(teamList: string[]) {
    var logedInUserTeamInList: Boolean = false;
    if(this.selectedTeams.length == 1) {
      this.teams.forEach(a => {
        if(a.id.toLowerCase() === this.selectedTeams[0].toLowerCase()) {
          logedInUserTeamInList = true;
        }
      });
      if(!logedInUserTeamInList) {
        this.selectedTeams = [''];
      }
    }
  }

  private adjustTeams(result: DropdownDictionary[]) {
    const theAllUsersTeam = result.find(a => a.id.toString().toLowerCase() === UserTeam.all.toString().toLowerCase());
    this.teams = result;
    this.teams.splice(  this.teams.findIndex(c => { return c.id === 'ADT01'  } ), 1) ;
    if (this.searchUsers != null) {
      this.sortList();
    }
  }

  addTeamsToTheUserList(addAll: boolean = false): any {
    const specialSortTeams = this.teams
    .filter(a => (this.selectedTeams.includes(a.id) || addAll || a.id === 'ADT00' ) && a.id.toString().toLowerCase() !== UserTeam.all.toString().toLowerCase())
    .map(a => this.userTeamDtoAdapter.adaptFromDropdownDictionary(a));
    return specialSortTeams.reverse();
  }

  hide() {
    this.hiddenContent = !this.hiddenContent;
    this.boardTeamCaringService.changedShowBoardTeamCaring(this.hiddenContent);
  }

  boardTeamClick(team: string) {
    const test = this.teams;
    this.teams = null;
    this.teams = test;

    if(!this.selectedTeams.includes(team)) {
      this.selectedTeams.push(team);
    } else {
      this.selectedTeams.splice(this.selectedTeams.indexOf(team), 1);
    }
    this.selectedTeams = JSON.parse(JSON.stringify(this.selectedTeams));
    clearTimeout(this.debounceSelectedTeamChanged);
    this.debounceSelectedTeamChanged = setTimeout(() => {
      this.onSelectedTeamChangedNew(this.selectedTeams);
    }, 800);
    this.clearSavedBoardNavigation();
  }

  isMenuSelected(team: string): boolean {
    return this.selectedTeams.includes(team);
  }

  checkIfNotCaseHandlerTeam() {
    if ( this.loggedInUser.userTeam && !this.loggedInUser.userTeam.caseHandler && this.isCaseView ) {
      this.currentTeam = UserTeam.all;
    }
  }

  onSelectedTeamChangedNew(teams: string[]) {
    this.userIds = [-1];
    this.waitingForMoreButtonClicks = false
    this.selectedTemsOnly = false;
    if(teams.length === 0) {
      this.clearTeamAndAssegneeFilter()
    } else {
      this.highLightedId = null;
      this.selectedworker = null;
      this.setTeamAndUserOnTabSessionStorage();
      this.getUsers(null, this.selectedTeams, true);
    }
  }

  setTeamAndUserOnTabSessionStorage() {
    let ids = [-1];
    this.selectedTeamsForSearch = [''];
    if(this.selectedworker) {
      this.selectedworker.forEach(e => {
        if(e.adtCode !== 'ADT00' && e.initials !== 'ADT00') {
          if(e.isTeam) {
            this.selectedTeamsForSearch.push(e.id.toString());
          } else{
            ids.push(e.id);
          }
        }
      })
    }
    this.selectedTeamNames = this.boardShareService.findSelectedTeamNames(null, this.selectedTeams);
    this.sessionTeamUserStorageInfo = new BoardShareCommunicationModel();
    this.sessionTeamUserStorageInfo.adtCode = this.selectedTeam ? this.selectedTeam : null;
    this.sessionTeamUserStorageInfo.adtCodes = this.selectedTeams ? this.selectedTeams : null;
    this.sessionTeamUserStorageInfo.selectedTeamsForSearch = this.selectedTeamsForSearch ? this.selectedTeamsForSearch : null;
    this.sessionTeamUserStorageInfo.userIds = ids ? ids : null;
    this.sessionTeamUserStorageInfo.userDtos = this.selectedworker ? this.selectedworker : null;
    this.sessionTeamUserStorageInfo.adtCodesForCount = this.selectedTeams ? this.selectedTeams : null;
    this.sessionTeamUserStorageInfo.teamOnly = this.selectedTemsOnly;
    this.sessionTeamUserStorageInfo.selectedTeamNames = this.selectedTeamNames;
    sessionStorage.setItem('team', JSON.stringify(this.sessionTeamUserStorageInfo));
  }

  getSelectedTabTeamAndUser() {
    let JSONTodoTeamAndUser;
    JSONTodoTeamAndUser = sessionStorage.getItem('team');

    if (!!JSONTodoTeamAndUser) {
      this.sessionUsrAndTeamStorageAvailable = true;
      const parsedBoardCaseFilterRequest = JSON.parse(JSONTodoTeamAndUser) as BoardShareCommunicationModel;
      this.selectedworker = parsedBoardCaseFilterRequest.userDtos;
      this.selectedTeam = parsedBoardCaseFilterRequest.adtCode;
      this.selectedTeams = parsedBoardCaseFilterRequest.adtCodes;
      this.selectedTeamsForSearch = parsedBoardCaseFilterRequest.selectedTeamsForSearch;
      this.userIds = parsedBoardCaseFilterRequest.userIds;
      this.selectedTemsOnly = parsedBoardCaseFilterRequest.teamOnly;
      this.selectedTeamNames = parsedBoardCaseFilterRequest.selectedTeamNames;
      this.currentTeam = this.selectedTeam;
      this.onSelectedTeamChangedBySessionStorage(parsedBoardCaseFilterRequest.adtCode, parsedBoardCaseFilterRequest.adtCodes);
    }
  }

  setUserTeamList(users: UserTeamDto[], addAllTeams: boolean = false) {
    let specialSortTeams = [];
    this.searchUsers = users;
    this.setUserDropdownList();
    if (this.teams != null) {
      specialSortTeams = this.addTeamsToTheUserList(addAllTeams);
    }

    this.searchUsers.sort((a, b) => (a.firstName + '' + a.surname > b.firstName + '' + b.surname ? 1 : -1));
    this.searchUsers.sort((a, b) => (!a.isTeam ? 1 : -1));
    this.searchUsers.sort((a, b) => (a.firstName !== 'All' ? 1 : -1));

    specialSortTeams.forEach(e =>
      this.searchUsers.unshift(this.userTeamDtoAdapter.adaptFromDropdownDictionary(new DropdownDictionary(e.id, e.firstName)))
    );
    this.searchUsers.unshift(this.userTeamDtoAdapter.adaptFromDropdownDictionary(new DropdownDictionary('ADT00', 'All')));

    this.sortList();
    if(this.selectedworker === null || this.disableUserDropdown) {
      this.selectedworker = [this.filteredSearchUsers[0]];
    }
  }

  setUserDropdownList() {
    this.users = []
    this.searchUsers.forEach(user => {
      this.users.push(new DropdownDictionary(user.id.toString(), user.fullName  + user.initials + ' (' + user.userTeam?.teamName + ')'))
    })
  }

  getUsers(team: string, teams: string[], eventFromUser: boolean = true) {
    this.filteredSearchUsers = null;
    this.currentTeam = team;
    this.dictionaryService.searchUsersForTeams(teams).subscribe(
      resultUsers => {
        this.setUserTeamList(resultUsers as any);
      }
    );
    if (eventFromUser) {
      this.boardShareService.sendSelectedCaseWorker(new BoardShareCommunicationModel({ adtCode: null,   adtCodes: teams, userIds: this.userIds, teamOnly: this.selectedTemsOnly, selectedTeamsForSearch:  this.selectedTeamsForSearch,  adtCodesForCount: this.selectedTeams, selectedTeamNames: this.selectedTeamNames }));
    }
  }

  clearTeamAndAssegneeFilter() {
    this.selectedTemsOnly = true;
    this.currentTeam = this.loggedInUser.userTeam ? this.loggedInUser.userTeam.adtCode : UserTeam.all;
    this.selectedTeams = [this.currentTeam];
    this.onSelectedTeamChangedNew(this.selectedTeams);
    this.getUsers(null, this.selectedTeams, false);
    this.selectedworker = [this.loggedInUser];
    this.onSelectUserClick({selectedItemId: this.selectedworker, allClicked: false });
    this.clearSavedBoardNavigation();
  }

  onSelectedTeamChangedBySessionStorage(team: string, teams: string[]) {
    this.setTeamAndUserOnTabSessionStorage();
  }

  onSelectUserClick(info: any) {
    let clickedworker: UserTeamDto[] = info.selectedItemId;
    let allClicked: boolean = info.allClicked;
    let selectedTeamNames: string[] = info.selectedTeamNames;
    this.selectedTeamNames = selectedTeamNames;
    this.selectedTemsOnly = true;
    let teamOnly: boolean = true;

    if(clickedworker.length === 1) {
      if(clickedworker[0].initials === 'ADT00') {
        this.selectedworker = null;
        this.setTeamAndUserOnTabSessionStorage();
        this.boardShareService.sendSelectedCaseWorker(
          new BoardShareCommunicationModel({ adtCodes:  this.selectedTeams, userIds: [-1], teamOnly: false,  adtCodesForCount: this.selectedTeams, selectedTeamNames: this.selectedTeamNames  })
        );
        this.getUsers(null, this.selectedTeams, false);
        return;
      }
      this.selectedworker = clickedworker;
    } else if (clickedworker.length === 0) {
        this.selectedworker = !this.disableUserDropdown ? [this.filteredSearchUsers[0]] : null;
        this.setTeamAndUserOnTabSessionStorage();
        this.boardShareService.sendSelectedCaseWorker(
          new BoardShareCommunicationModel({ adtCodes:  this.selectedTeams, userIds: [-1], teamOnly: false, adtCodesForCount: this.selectedTeams, selectedTeamNames: this.selectedTeamNames  })
        );
        return;

    } else {
      let newClickedworker: UserTeamDto[] = [];
      if(allClicked) {
        clickedworker.forEach((worker, index) => {
          if(worker.initials === 'ADT00') {
            newClickedworker = [ worker ];
            teamOnly = false;
            this.selectedTemsOnly = false;
          }
        })
      } else {
        clickedworker.forEach((worker, index) => {
          if(worker.initials !== 'ADT00') {
            newClickedworker.push(worker);
          }
        })
      }
      this.selectedworker = newClickedworker;
    }

    let ids = [-1];
    if (!allClicked && clickedworker.length !== 0) {
      this.selectedTeamsForSearch = [''];
      this.selectedworker.forEach(e => {
        if(e.adtCode !== 'ADT00') {
          if(e.isTeam) {
            this.selectedTeamsForSearch.push(e.id.toString());
          } else{
            ids.push(e.id);
          }
        }
      })
    }
    this.setTeamAndUserOnTabSessionStorage();
    if(allClicked) {
      this.boardShareService.sendSelectedCaseWorker(new BoardShareCommunicationModel({ adtCode: null, adtCodes: this.selectedTeams, userIds: ids, teamOnly: teamOnly, adtCodesForCount: this.selectedTeams, selectedTeamNames: this.selectedTeamNames  }));
    } else {
      this.boardShareService.sendSelectedCaseWorker(new BoardShareCommunicationModel({ adtCode: null, adtCodes: this.selectedTeamsForSearch, userIds: ids, teamOnly: teamOnly, adtCodesForCount: this.selectedTeams, selectedTeamNames: this.selectedTeamNames  }));
    }
    if (this.selectedTeam.toLowerCase() === UserTeam.all.toLowerCase()) {
      this.filteredSearchUsers = clickedworker;
      return;
    }
    this.sortList();
  }

  private sortList() {
    if(!!this.searchUsers) {
      this.filteredSearchUsers = this.searchUsers.filter(type => type.active);
      this.filteredSearchUsers = this.filteredSearchUsers.concat(this.searchUsers.filter(type => !type.active));
      const userStartIndex = this.filteredSearchUsers.findIndex(element => element.isTeam !== true);
      this.selectedworker?.forEach(selectedUser => {
        this.filteredSearchUsers.forEach((user, index) => {
          if((user.id === selectedUser.id) && selectedUser.adtCode !== 'ADT00' && !selectedUser.isTeam) {
            this.filteredSearchUsers.splice(index, 1)
            this.filteredSearchUsers.splice(userStartIndex, 0, selectedUser)
          }
        })
      })

      if (this.selectedworker != null) {
        this.sortedSearchUsers = this.filteredSearchUsers;
      }
      this.focusOnUserIndex = 0;
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  public beforeunloadHandler($event) {
    localStorage.removeItem('team');
  }

  get UserTeam() {
    return UserTeam;
  }

  private clearSavedBoardNavigation() {
    this.boardNavigationStateService.clearAll();
  }
}

