export enum ChannelType {
    PHONE = 'PHONE',
    CHAT = 'CHAT',
    MAIL = 'MAIL',
    WEB = 'WEB',
    SMS = 'SMS',
    FAX = 'FAX',
    CREATE_CASE = 'CREATE CASE',
    FIND_MY_DOCTOR = 'FIND MYDOCTOR',
    ONLINE_CLAIMS = 'CLAIMS ONLINE',
    CLAIMS_BUSINESS = 'CLAIMS BUSINESS',
    SUPPLIER_PORTAL = 'SUPPLIER PORTAL',
    UNKNOWN_SUPPLIER = 'UNKNOWN SUPPLIER',
    HELP_ME_CREATE_CASE = 'HELP ME CREATE CASE',
    HELP_ME_FIND_MY_DOCTOR = 'HELP ME FIND MY DOCTOR',
    BATCH_BILLING = 'BATCH BILLING',
    CREATE_CASE_EXPAT_BTI = 'CREATE CASE EXPAT BTI',
    FIND_MY_DOCTOR_EXPAT_BTI = 'FIND MY DOCTOR EXPAT BTI',
    MPA_TRAVEL = 'MPA_TRAVEL',
    MPA_CANCELLATION = 'MPA_CANCELLATION'
}
