import { Injectable } from "@angular/core";
import { Adapter } from "../interfaces/adapter";
import { DropdownDictionary } from "./dropdownDictionary";

export class CausesLevel3DD extends DropdownDictionary {

    public constructor(init?: Partial<CausesLevel3DD>) {
        super();
        Object.assign(this, init);
    }    
}

@Injectable({
    providedIn: 'root'
})  
export class CausesLevel3DDAdapter implements Adapter<CausesLevel3DD> {
    adapt(item: any): CausesLevel3DD {
        return new CausesLevel3DD({
        id: item.level3Code,
        name: item.level3,
      });
    }
}
