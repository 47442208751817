import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import '@tinymce/tinymce-angular';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-task-editor',
  templateUrl: './task-editor.component.html',
  styleUrls: ['./task-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TaskEditorComponent implements OnInit {
  public editorConfig: object;
  @Input() text: string;
  @Input() disabled: boolean;
  @Input() isEmbeddedView: boolean;
  @Output() focusOut = new EventEmitter();
  @Output() textChanged = new EventEmitter();

  constructor(public sanitizer: DomSanitizer) {}

  ngOnInit() {
    const editorWidth = this.isEmbeddedView ? 480 : 578;
    this.editorConfig = {
      base_url: '/tinymce',
      suffix: '.min',
      height: 420,
      width: editorWidth,
      inline_styles: true,
      menubar: false,
      paste_data_images: true,
      extended_valid_elements : "a[rel|rev|charset|hreflang|tabindex|accesskey|type|name|href|target|title|class|onfocus|onblur|onclick|style]",
      fontsize_formats: '8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt 24pt 28pt 32pt 36pt',
      content_style: 'body { font-family: calibri; font-size: 11pt;  }',
      font_formats: 'Calibri=calibri',
      plugins: [
        'print preview searchreplace autolink ' +
          'directionality visualblocks visualchars fullscreen image link ' +
          'media template codesample table charmap hr pagebreak nonbreaking ' +
          'anchor toc insertdatetime advlist lists wordcount ' +
          'imagetools textpattern noneditable help ' +
          'charmap quickbars emoticons ',
      ],
      toolbar:
        'bold italic underline strikethrough | \
        alignleft aligncenter alignright alignjustify | \
        bullist numlist checklist  | outdent indent ',
    };
  }

  onFocus(e: any) {
    // Force a focus-in event, fix for missing focus-in event 
    const event = new FocusEvent('focusin', {
      view: window,
      bubbles: true,
      cancelable: true
    });          
    const editor = e?.editor?.iframeElement;
    if ( editor ) {      
      editor.dispatchEvent(event);
    }
  }

  onBlur(event) {
    this.validateText(event.editor);
    this.focusOut.emit(this.text);
  }

  onKeyUp(event) {
    this.validateText(event.editor);
    this.textChanged.emit(this.text);
  }
  
  private validateText(editor) {
    if (this.text == null || this.text.length === 0) {
      this.markTextAsInvalid(editor);
      this.textChanged.emit('');
    } else {
      this.markTextAsValid(editor);
    }
  }

  private markTextAsInvalid(editor) {
    editor.iframeElement.contentDocument.getElementsByTagName('body')[0].style = 'background-color: #FDD1DA';
  }

  private markTextAsValid(editor) {
    editor.iframeElement.contentDocument.getElementsByTagName('body')[0].style = 'background-color: white';
  }
}
