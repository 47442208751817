<div class="card">
  <div class="sub-component-placeholder row">
    <div class="col-6" style="padding: 0 !important">
      <div class="sub-component-placeholder">
        <app-sub-commitment-type [(commitmentType)]="medicalEscort.commitmentType" [serviceOrderType]="serviceOrderType" [status]="status" [caseId]="caseId">
        </app-sub-commitment-type>
      </div>
    </div>
  </div>
  <div class="sub-component-placeholder">
    <app-duration
      [(duration)]="medicalEscort.duration"
      [deleteable]="false"
      [hideGOPDates]="true"
      [hideDaysHours]="false"
      [hideLocation]="true"
      [hideRoomAndRemark]="true"
      [seccaTimeZone]="'secca'"
    ></app-duration>
  </div>
</div>
