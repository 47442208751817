<div class="co-travellers-panel">
  <div class="message-content p-3">
    <div class="row" *ngFor="let cotraveller of taskDataViewModel?.data?.coTravellerStakeholders; index as i">
      <div class="col-12">    
        <div class="row">
          <div class="col-4">
            <app-task-web-co-traveller [cotraveller]="getCotravellerFirstCol(i)" [ignoredProperties]="ignoredProperties"
                                      [serviceItemList]="serviceItemList" [purposeOfTravelItemList]="purposeOfTravelItemList"
                                      [causes]="causes" [diagnoses]="diagnoses" [customerProfiles]="customerProfiles"></app-task-web-co-traveller>
          </div>
          <div class="col-4">
            <app-task-web-co-traveller [cotraveller]="getCotravellerSecondCol(i)" [ignoredProperties]="ignoredProperties"
            [serviceItemList]="serviceItemList" [purposeOfTravelItemList]="purposeOfTravelItemList"
            [causes]="causes" [diagnoses]="diagnoses" [customerProfiles]="customerProfiles"></app-task-web-co-traveller>
          </div>
          <div class="col-4">
            <app-task-web-co-traveller [cotraveller]="getCotravellerThirdCol(i)" [ignoredProperties]="ignoredProperties"
                                      [serviceItemList]="serviceItemList" [purposeOfTravelItemList]="purposeOfTravelItemList"
                                      [causes]="causes" [diagnoses]="diagnoses" [customerProfiles]="customerProfiles"></app-task-web-co-traveller>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>