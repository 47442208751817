import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { OnSaveData } from '../../interfaces/on-save-data';
import { NgSelectComponent } from '@ng-select/ng-select';
import { DropdownWithHoverDictionary } from '@secca/shared/models/dropdownWithHoverDictionary';

@Component({
  selector: 'app-drop-down-with-hover',
  templateUrl: './drop-down-with-hover.component.html',
  styles: ['.commit-popover { width: 900px }']
})
export class DropDownWithHoverComponent implements OnInit, OnSaveData {
  @Input() dropdownPosition: string = 'auto';
  @Input() required: boolean;
  @Input() recommended: boolean;
  @Input() requiredRedBackground: boolean;
  @Input() requiredRedLabel: boolean;
  @Input() name: string;
  @Input() items: DropdownWithHoverDictionary[];
  @Input() selectedItem = null;
  @Input() disabled: boolean;
  @Input() noBorderAndArrow: boolean;
  @Input() translatePipePath: string;
  @Input() multiple = false;
  @Input() multiSelectedLabel: string;
  @Input() closeOnSelect = true;
  @Input() placeholder = '';
  @Input() missingItemLabel: string;
  @Output() selectedItemChange = new EventEmitter();
  @Output() saveModel = new EventEmitter();
  @Output() open = new EventEmitter();
  @Output() close = new EventEmitter();
  @Output() focus = new EventEmitter();
  oldSelectedItem: any;
  @ViewChild('selectCause') selectCause: NgSelectComponent;

  constructor() {}

  onChange() {
    this.selectedItemChange.emit(this.selectedItem);
  }

  onOpen() {
    this.open.emit();
  }

  onClose() {
    this.close.emit();
  }

  ngOnInit() {}

  onSaveModel() {
    if (this.oldSelectedItem !== this.selectedItem) {
      this.saveModel.emit();
    }
  }

  onSaveState() {
    this.oldSelectedItem = this.selectedItem;
  }

  setFocus({ selectCause = false }: { selectCause?: boolean } = {}) {
    this.disabled = false;

    if ( this.selectCause ) {
      this.selectCause.focus();
      if (selectCause) {
        this.selectCause.open();
      }
    }
  }

  displayMissingLabel(): boolean {
    if (!!this.items) {
      return !this.items.find(item => item.value === this.selectedItem);
    }
    return true;
  }

  trackBy(a) {
    return a.businessKey;
  }
}
