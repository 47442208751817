import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
import { ContactPerson } from './contactPerson';
import { Gop, GopAdapter } from './gop';
import { ProviderRating } from './../enums/provider';

export class Company {
    name: string;
    email: string;
    remarks: string;
    networkRemarks: string;
    attention: boolean;
    quality: string;
    publicCompany: boolean;
    privateCompany: boolean;
    providerTypeId: number;
    contactPerson: string;
    contactPersons: ContactPerson[];
    gop: Gop;
    providerId: string;
    employee: number;

    public constructor(init?: Partial<Company>) {
        Object.assign(this, init);

        if (!init || !init.contactPersons) {
            this.contactPersons = new Array<ContactPerson>();
        }

        if (!init || init.attention === undefined) {
            this.attention = false;
        }
    }
}


@Injectable({
    providedIn: 'root'
})

export class CompanyAdapter implements Adapter<Company> {
    adapt(item: any): Company {
        return new Company({
            name: item.name,
            email: item.email,
            remarks: item.remarks,
            networkRemarks: item.networkRemarks,
            attention: (item.attention == null),
            quality: (item.quality == null || item.quality === '') ? ProviderRating.NONE : item.quality,
            publicCompany: item.publicCompany,
            privateCompany: item.privateCompany,
            providerTypeId: item.providerTypeId,
            contactPersons: !!item.contactPersons ? item.contactPersons : null,
            gop: !!item.gop ? new GopAdapter().adapt(item.gop) : null,
            providerId: item.providerId,
            employee: item.employee
        });
    }
}
