import { OxygenAvailabilityEnum, RepatriationMediumEnum } from './../enums';

import { Injectable } from '@angular/core';
import { Adapter } from '../../interfaces/adapter';

export class Oxygen {
    type: RepatriationMediumEnum;
    rate: number;
    availability: OxygenAvailabilityEnum;
    public constructor(init?: Partial<Oxygen>) {
        Object.assign(this, init);
    }
}

@Injectable({
    providedIn: 'root'
})
export class OxygenAdapter implements Adapter<Oxygen> {
    adapt(item: any): Oxygen {
        return new Oxygen({
            type: item.type,
            rate: item.rate,
            availability: item.availability
        });
    }
}
