import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { CaseLockHttpService } from './case-lock-http.service';
import { QueueService } from './queue.service';
import { SettingsService } from './settings.service';
import { CaseValidationService } from '@secca/core/services/case-validation.service';
import { GlobalErrorHandler } from './error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class UpdateStakeholderQueueService extends BaseService {
  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private caseLockHttpService: CaseLockHttpService,
    private queueService: QueueService,
    private caseValidationService: CaseValidationService,
    private caseStateService: CaseStateService,
    private globalErrorHandler: GlobalErrorHandler
  ) {
    super(settingsService);
  }

  actionSchedule() {
    if (!this.queueService.anyStakeholderQueuedOperationsRunning()) {
      return this.runActions();
    }
  }

  runActions() {
    this.queueService.stakeholderQueuedOperationsRunning(true);

    const nextStakeholder = this.queueService.popStakeholder();
    if (nextStakeholder == null) {
      return this.queueService.stakeholderQueuedOperationsRunning(false);
    }
    return this.caseLockHttpService
      .put<CaseStakeholder>(this.baseURL + 'stakeholders/' + nextStakeholder.id, nextStakeholder, { headers: this.jsonHeaders })
      .subscribe(result => {
        if (nextStakeholder.person && result.person) {
          this.caseStateService.sendSsnLookupPossible(+nextStakeholder.id, result.person.ssnLookupPossible);
        }
        this.caseStateService.updatedStakeholder(nextStakeholder);
        this.caseValidationService.sendVerifyCaseNumberAssigned(nextStakeholder.caseId.toString());
        this.runActions();
      }, err => {
        this.globalErrorHandler.handleError(err);
        this.runActions();
      });
  }
}
