import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TaskChangeStatusService {
  private dateSource = new Subject<void>();

  constructor() {}

  public setTaskChangeStatus() {
    this.dateSource.next();
  }

  public getTaskChangeStatus() {
    return this.dateSource.asObservable();
  }
}
