import { SupplierInvoiceTaskViewModel } from '@secca/board/components/board-task/models/supplier-invoice-task-view.model';

export class SupplierInvoiceTaskFilterResponse {
  totalNumber: number;
  taskViewModels: SupplierInvoiceTaskViewModel[];

  public constructor(init?: Partial<SupplierInvoiceTaskFilterResponse>) {
    Object.assign(this, init);
  }
}
