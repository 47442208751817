import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { CaseLockHelperService } from "@secca/core/services/case-lock-helper.service";
import { DialogBoundryService } from "@secca/core/services/dialog-boundry.service";
import { DialogMinimizableDirective } from "@secca/shared/directives/dialog-minimizable.directive";
import { DialogSelectableDirective } from "@secca/shared/directives/dialog-selectable.directive";
import { LockContextEnum } from "@secca/shared/enums/lock-context.enum";
import { MinimizableDialogType } from "@secca/shared/enums/minimizable-dialog-type-enum";
import { AdditionalInfo, IAdditionalInfo } from "@secca/shared/interfaces/additional-info";
import { IFocusEnabled } from "@secca/shared/interfaces/focus-enabled";
import { IKeyboardEnabled } from "@secca/shared/interfaces/keyboard-enabled";
import { IMinimizable } from "@secca/shared/interfaces/minimizable";
import { ManageSupplierInvoiceComponent } from "../manage-supplier-invoice.component";
import { ManageSupplierInvoiceDialogData } from "./manage-supplier-invoice-dialog-data.component";

@Component({
    selector: 'app-manage-supplier-invoice-dialog',
    templateUrl: './manage-supplier-invoice-dialog.component.html',
    styleUrls: ['./manage-supplier-invoice-dialog.component.scss'],
  })
  export class ManageSupplierInvoiceDialogComponent implements OnInit, IAdditionalInfo, IMinimizable, IKeyboardEnabled, IFocusEnabled {
    @ViewChild(ManageSupplierInvoiceComponent) wrappedInstance: ManageSupplierInvoiceComponent;
    @ViewChild(DialogMinimizableDirective) minimizedDirective: DialogMinimizableDirective;
    @ViewChild(DialogSelectableDirective) selectableDirective: DialogSelectableDirective;

    data: ManageSupplierInvoiceDialogData;

    constructor(private dialogRef: MatDialogRef<ManageSupplierInvoiceDialogComponent>,
                @Inject(MAT_DIALOG_DATA) data: ManageSupplierInvoiceDialogData,
                private caseLockHelperService: CaseLockHelperService,
                public dialogBoundryService: DialogBoundryService) {
      this.data = data;
    }
    ngOnInit(): void {  
      this.takeLock();
    }

    close(): void {
      this.releaseLock();
      this.dialogRef.close();
    }

    keyboardClose(): void {
      this.wrappedInstance.close();
    }
    
    minimize(): void {
      this.minimizedDirective.minimize();
    }
      
    getMinimizeType(): MinimizableDialogType {
      return this.minimizedDirective.appDialogMinimizable;
    }

    getAdditionalInfo(): AdditionalInfo {
      const caseId: number = this.wrappedInstance?.supplierInvoice?.caseId;
      const caseNumber: string =  this.data?.caseNumber;
      const invoiceNumber: string = this.wrappedInstance?.supplierInvoice?.invoiceNumber;

      return {
        caseId,
        hoverTitle: caseNumber || '',
        hoverText: invoiceNumber || ''
      };
    }

    getFocusElement(): any {
      return this.selectableDirective?.focusElement;
    }
  
    get MinimizableDialogType() {
      return MinimizableDialogType;
    }

    private takeLock(): void {
      this.caseLockHelperService.lockCase(LockContextEnum.SUPPLIER_INVOICE, this.data?.supplierInvoiceId).subscribe();
    }

    private releaseLock(): void {
      this.caseLockHelperService.releaseCaseLock(LockContextEnum.SUPPLIER_INVOICE, this.data?.supplierInvoiceId).subscribe();
    }
}
