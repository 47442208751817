import { Directive, ElementRef, HostListener, OnInit, Renderer2, } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MatLegacyDialogState as MatDialogState } from '@angular/material/legacy-dialog';
import { DialogStateService } from '@secca/core/state-services/dialog-state.service';
import { Subject, Subscription } from 'rxjs';
import { AutoUnsubscribe } from '../decorators/auto-unsubscribe';


@Directive({
  selector: "[appDialogSelectable]",
})
@AutoUnsubscribe
export class DialogSelectableDirective implements OnInit {
  dialogId: string;
  subscr: Subscription;
  focusElement: any;
  allreadyInFocus = false;
  dialogStateChange = new Subject<void>();

  constructor(private elmRef: ElementRef, 
              private renderer: Renderer2,
              private dialogStateService: DialogStateService,
              private matDialog: MatDialog) {        
    this.subscr = dialogStateService.getSendToFront().subscribe( dialogId => {
      if ( dialogId !== this.dialogId ) {
        this.sendToBack();
      }
      else {
        this.sendToFont();
      }
    });            
  }

  ngOnInit() {
    this.findDialogId();
    this.sendToFont();
  }
  
  @HostListener('click', ['$event'])
  click(e: Event) {
    if ( !this.isInFront() ) {
      const dialogRef: MatDialogRef<any> = this.matDialog.getDialogById(this.dialogId);
      if ( dialogRef && dialogRef.getState() === MatDialogState.OPEN ) {
        this.dialogStateService.sendToFront(this.dialogId);
      }
    }
  }

  @HostListener("focusin", ['$event'])
  onFocus(e: Event) { 
    if ( e.target ) {
      const dialogRef = this.matDialog.getDialogById(this.dialogId);
      if ( dialogRef && dialogRef.getState() === MatDialogState.OPEN ) {
        this.focusElement = e.target;
        if (!this.allreadyInFocus) {
          this.dialogStateChange.next();
        }
        this.allreadyInFocus = true;
      }
    }
  } 

  @HostListener("focusout", ['$event'])
  onFocusOut(e: any) { 
    this.allreadyInFocus = false;
  }

  private isInFront() {
    const overlayElement = this.findParentElementByClass(this.elmRef.nativeElement, 'cdk-global-overlay-wrapper');
    return !!overlayElement?.classList?.contains('dialog-to-front');
  }

  private sendToFont() {
    const overlayElement = this.findParentElementByClass(this.elmRef.nativeElement, 'cdk-global-overlay-wrapper');
    if ( overlayElement ) {
      this.renderer.addClass(overlayElement, 'dialog-to-front');
    }
  }

  private sendToBack() {
    const overlayElement = this.findParentElementByClass(this.elmRef.nativeElement, 'cdk-global-overlay-wrapper');
    if ( overlayElement ) {
      this.renderer.removeClass(overlayElement, 'dialog-to-front');
    }
  }

  private findDialogId(): void {
    const containerElement = this.findParentElementByClass(this.elmRef.nativeElement, 'mat-dialog-container');
    if ( containerElement ) {
      this.dialogId = containerElement.id;
    }
  }

  private findParentElementByClass(element, componentClass) {
    for (let i = 0; i < 10; i++) {
        if ( !element ) {
          break;
        }

        if (element?.classList?.contains(componentClass)) {
            return element;
        } 

        element = element.parentNode;
    }

    return null;
  }
}
