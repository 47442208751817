
import { MomentHelperService } from '@secca/core/services/moment-helper.service';
import * as moment from 'moment';
import { DateSerializer } from '../date-serializer';
import { ServiceOrderExtension } from './service-order-extension';
import { NoDurationServiceOrderExtension } from './no-duration-service-order-extension';
import { CommitmentType } from '../commitment-type';

export class AdviceOnInsuranceTermsServiceOrderExtension extends NoDurationServiceOrderExtension implements ServiceOrderExtension {
  displayDurationId: number;
  id: number;
  startDate: moment.Moment = MomentHelperService.getSeccaTime().startOf('day').utc();
  adviceGiven: string;

  public constructor(init?: Partial<AdviceOnInsuranceTermsServiceOrderExtension>) {
    super();
    if (init) {
      Object.assign(this, init);
    }
  }

  isValid(): boolean {
    return this.startDate && this.startDate.isValid() && !!this.adviceGiven;
  }

  init() {}

  isPreviewGopValid(): boolean {
    throw new Error("Gop preview not supported for this service order type");
  }


  setCommitmentType(commitmentType: CommitmentType) {
  }

  getCommitmentType(): CommitmentType {
    return null;
  }

  getSelectedStakeholderIds(): string[] {
    return null;
  }
}
export class AdviceOnInsuranceTermsServiceOrderExtensionAdapter {
  adapt(item?: any): AdviceOnInsuranceTermsServiceOrderExtension {
    return new AdviceOnInsuranceTermsServiceOrderExtension({
      id: item.id,
      startDate: DateSerializer.deserialize(item.startDate),
      adviceGiven: item.adviceGiven
    });
  }
}
