import { MedicalPreAssessmentService } from './../../../../core/services/medical-pre-assessment.service';
import { MedicalPreAssessment } from '@secca/shared/models/medicalPreAssessment';
import { Component, OnInit, Input, ViewChild, TemplateRef, Inject, OnDestroy } from '@angular/core';
import { Case } from 'src/app/shared/models/case';
import { PlannedTravel } from 'src/app/shared/models/planned-travel';
import { PersonInsurance } from './../../../../shared/models/person-insurance';
import { CaseIncident } from './../../../../shared/models/caseIncident';
import { IncidentService } from '../../../../core/services/incident.service';
import { CaseService } from './../../../../core/services/case.service';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MedicalService } from '../../../../core/services/medical.service';
import { MedicalHistory } from '../../../../shared/models/medicalHistory';
import { ServiceOrderService } from '@secca/core/services/service-order.service';
import { ServiceOrder } from '@secca/shared/models/service-order/service-order';
import { ServiceTypeEnum, AssessmentTypeEnum, AssigneeEnum, PermissionEnum, ShortcutEnum, CommitmentTypes, PermissionHideTypeEnum, StakeholderTypeEnum } from '@secca/shared/models/enums';
import { MedicalAssessmentServiceOrderExtension } from '@secca/shared/models/service-order/medical-assessment-service-order-extension';
import { TranslateService } from '@ngx-translate/core';
import { PlannedTravelService } from '@secca/core/services/planned-travel.service';
import { DialogViewerServiceInterface } from '@secca/core/services/dialog-viewer-service-interface';
import { DIALOG_VIEWER_TOKEN } from '@secca/core/services/token';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { CaseDialogViewerService } from '@secca/case/case-dialog-viewer.service';
import { MenuService } from '@secca/core/services/menu.service';
import { ShortcutService } from '@secca/core/services/shortcut.service';
import {InsuranceService} from '@secca/core/services/insurance.service';
import { DataService } from '@secca/core/services/data.service';
import { CustomerProfileInfo } from '@secca/shared/models/customerProfileInfo';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { PermissionService } from '@secca/core/services/permission.service';
import { CASE_TYPE_CODES, CaseType } from '@secca/shared/models/case-type';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-case-medical',
  templateUrl: './case-medical.component.html',
  styleUrls: ['./case-medical.component.scss']
})
@AutoUnsubscribe
export class CaseMedicalComponent implements OnInit, OnDestroy {
  @ViewChild('medicalNoteModalContent') noteContent: TemplateRef<any>;

  @Input()
  set case(newCase: Case) {
    this.theCase = newCase;
    if (this.theCase) {
      this.onRefresh();
    }
  }

  get case(): Case {
    return this.theCase;
  }

  personInsurance: PersonInsurance;
  plannedTravel: PlannedTravel;
  caseIncident: CaseIncident;
  endUser: CaseStakeholder;
  medicalHistory: MedicalHistory[];
  serviceOrderListForSort: ServiceOrder[] = [];
  serviceOrderList: ServiceOrder[] = [];
  serviceOrderClaimsList: ServiceOrder[] = [];
  createAssessmentButtonText: string;
  medicalAssessmentInDraft: boolean;
  medicalPreAssessmentInDraft: boolean;
  claimsReviewInDraft: boolean;
  medicalAssessmentType: AssessmentTypeEnum;
  customerProfileInfo: CustomerProfileInfo;
  caseType: CaseType;

  private theCase: Case;

  //private shortcutSubscription: Subscription;
  private $insuranceSubscr: Subscription;

  constructor(private incidentService: IncidentService,
              private caseService: CaseService,
              private modalService: NgbModal,
              private medicalService: MedicalService,
              private serviceOrderService: ServiceOrderService,
              private translateService: TranslateService,
              private plannedTravelService: PlannedTravelService,
              public caseStateService: CaseStateService,
              private dialogViewerService: CaseDialogViewerService,
              private menuService: MenuService,
              private shortcutService: ShortcutService,
              private insuranceService: InsuranceService,
              private medicalPreAssessmentService: MedicalPreAssessmentService,
              private dataService: DataService,
              private permissionService: PermissionService
  ) {
    // this.shortcutSubscription = this.shortcutService.addShortcut({ keys: ShortcutEnum.AddMedicalNote }).subscribe(a => {
    //   this.selectedMenuId = this.menuService.getSelectedMenuId(parseInt(this.case.id, 10));
    //   if (this.selectedMenuId === CaseSelectedMenuEnum.CaseMedical) {
    //     if (!this.caseStateService.isCaseDisabled()) {
    //       this.addComment();
    //     }
    //   }
    // });
  }

  ngOnInit() {
    this.dataService.navigateMedical = false;

    if ( !!this.case?.id ) {
      this.$insuranceSubscr = this.insuranceService.getPersonInsurance(this.case.id).subscribe(insurance => {
        if ( insurance?.customerProfileId ) {
          this.getCustomerProfileInfo();
        }
      })
    }

    this.caseStateService.getCaseType().subscribe(caseType => {
      this.caseType = caseType;
    })
  }

  ngOnDestroy(): void {
//    this.shortcutSubscription.unsubscribe();
  }

  public onRefresh() {
    const caseId = +this.theCase.id;

    //if (!this.case.simpleCaseStakeholders) {
      this.caseService.getSimpleStakeholdersOnCase(caseId.toString()).subscribe(
        result => ( this.case.simpleCaseStakeholders = result )
      );
    //}

    this.caseService.getStakeholderForCase(this.theCase.id, 'END_USER').subscribe(
      result => (this.endUser = result), error => console.log(error));

    this.plannedTravelService.getPlannedTravel(this.theCase.id).subscribe(
      result => (this.plannedTravel = result), error => console.log(error)
    );

    this.incidentService.getCaseIncident(this.theCase.incident.id).subscribe(
      result => {
        this.caseIncident = result;
      }, error => console.log(error)
    );

    this.serviceOrderService.getServiceOrdersForCaseByType(+this.theCase.id, ServiceTypeEnum.MEDICAL_ASSESSMENT).subscribe(
      result => {
        this.serviceOrderListForSort = result;
        this.serviceOrderListForSort.forEach((serviceorder, index) => {
          if((serviceorder.extension as MedicalAssessmentServiceOrderExtension).getCommitmentType() && (serviceorder.extension as MedicalAssessmentServiceOrderExtension).getCommitmentType().businessKey === CommitmentTypes.CLAIMS_MEDICAL_REVIEW) {
            this.serviceOrderClaimsList.push(serviceorder);
          } else  {
            this.serviceOrderList.push(serviceorder);
          }
        })
        this.serviceOrderList.sort((a, b) => a.modifiedOn.isBefore(b.modifiedOn) ? 1 : -1);

        this.addAssessmentTypeForNewAssessment();
      }, error => console.log(error)
    );

    this.medicalAssessmentInDraft = !!this.medicalService.getRegularAssessmentInDraft(caseId);
    this.medicalPreAssessmentInDraft = !!this.medicalPreAssessmentService.getPreAssessmentInDraft(caseId);
    this.claimsReviewInDraft = !!this.medicalService.getClaimsReviewInDraft(caseId);
    this.refreshMedicalHistory();
  }

  refreshMedicalHistory() {
    this.medicalAssessmentInDraft = !!this.medicalService.getRegularAssessmentInDraft(+this.theCase.id);
    this.claimsReviewInDraft = !!this.medicalService.getClaimsReviewInDraft(+this.theCase.id);
    this.medicalPreAssessmentInDraft = !!this.medicalPreAssessmentService.getPreAssessmentInDraft(+this.theCase.id);
    this.medicalService.getMedicalHistory(this.theCase.id).subscribe(
      result => {
        this.medicalHistory = result;
      }, error => console.log(error)
    );
  }

  addAssessmentTypeForNewAssessment() {
    if (this.serviceOrderList.length !== 0 && (this.serviceOrderList[0].extension as MedicalAssessmentServiceOrderExtension).commitmentType) {
      switch ((this.serviceOrderList[0].extension as MedicalAssessmentServiceOrderExtension).commitmentType.businessKey) {
        case CommitmentTypes.REGULAR_MEDICAL_ASSESSMENT:
        case CommitmentTypes.NIGHT_SHIFT_MEDICAL_ASSESSMENT:
          this.medicalAssessmentType = AssessmentTypeEnum.REGULAR;
          break;
        case CommitmentTypes.LIGHT_MEDICAL_REVIEW:
          this.medicalAssessmentType = AssessmentTypeEnum.LIGHT;
          break;
      }
    } else {
      this.medicalAssessmentType = AssessmentTypeEnum.REGULAR;
    }
  }

  addCreateAssessmentButtonText() {
    this.medicalAssessmentType === AssessmentTypeEnum.LIGHT ?
      this.createAssessmentButtonText = 'light' : this.createAssessmentButtonText = 'regular';
    return this.translateService.instant('medical-new-regular-assessment-button-' + this.createAssessmentButtonText);
  }

  get AssessmentTypeEnum() {
    return AssessmentTypeEnum;
  }

  openCreateRegularMedicalAssessment() {
    this.dialogViewerService
        .openMedicalAssessmentDialog(this.case, this.medicalAssessmentType,
                                     () => this.refreshMedicalHistory(),
                                     () => this.refreshMedicalHistory());
  }


  openCreatePreMedicalAssessment() {
    this.dialogViewerService
        .openPreMedicalAssessmentDialog(this.case, this.medicalAssessmentType,
                                     () => this.refreshMedicalHistory(),
                                     () => this.refreshMedicalHistory());
  }

  openCreateClaimMedicalReview() {
    this.dialogViewerService
        .openMedicalAssessmentDialog(this.case, AssessmentTypeEnum.CLAIMS,
                                     () => this.refreshMedicalHistory(),
                                     () => this.refreshMedicalHistory());
  }

  disabledMedicalButton(): boolean {
    if (this.caseStateService.isCaseClosed || this.caseStateService.isInvoiceClosedWithNoInvoicingToCustomer) {
      return true;
    }

    if (this.serviceOrderList.length !== 0) {
      return !(((this.serviceOrderList[0].extension as MedicalAssessmentServiceOrderExtension).assignedMedicalStaffId !== null) &&
        ((this.serviceOrderList[0].extension as MedicalAssessmentServiceOrderExtension).assignee === AssigneeEnum.MEDICAL_STAFF));
    }
    return true;
  }

  disabledPreassessmentButton(): boolean {
    if ( this.caseType?.code !== CASE_TYPE_CODES.AWAITING_CASE_TYPE &&
         this.caseType?.code !== CASE_TYPE_CODES.MEDICAL_PRE_ASSESSMENT ) {
      return true;
    }

    if ( this.caseStateService.isCaseClosed ) {
      return true;
    }

    if(this.caseStateService.isCaseDisabled()) {
      return true;
    }

    if ( !this.customerProfileInfo?.preAssessmentAgreement ) {
      return true;
    }

    if ( !this.isStakeholderMandatoryFieldsFilled(StakeholderTypeEnum.endUser) ||
         !this.isStakeholderMandatoryFieldsFilled(StakeholderTypeEnum.policyHolder) ||
         !this.isStakeholderMandatoryFieldsFilled(StakeholderTypeEnum.reporter)) {
      return true;
    }

    return false;
  }

  preAssessmentButtonPopOverDisabled(): boolean {
    if ( this.caseType?.code !== CASE_TYPE_CODES.AWAITING_CASE_TYPE &&
         this.caseType?.code !== CASE_TYPE_CODES.MEDICAL_PRE_ASSESSMENT ) {
      return true;
    }

    return !this.disabledPreassessmentButton();
  }

  disabledCommentButton(): boolean {
    return this.caseStateService.isCaseDisabled() || this.case?.caseTypeCode === CASE_TYPE_CODES.AWAITING_CASE_TYPE;
  }

  get preassessmentButtonHoverText() {
    const missing = [];

    if ( this.caseStateService.isCaseClosed ) {
      return this.translateService.instant('case-medical-preassessment-case-closed');
    }

    if ( !this.customerProfileInfo?.preAssessmentAgreement ) {
      return this.translateService.instant('case-medical-preassessment-no-priceagreement');
    }

    if ( !this.permissionService.checkUserPermission(PermissionEnum.MEDICAL_PRE_ASSESSMENT_CREATE) ) {
      return this.translateService.instant('case-medical-preassessment-no-permission');
    }

    if ( !this.isStakeholderMandatoryFieldsFilled(StakeholderTypeEnum.endUser) ) {
      missing.push(this.translateService.instant('case-medical-preassessment-missing-enduser-mandatory-fields'));
    }

    if ( !this.isStakeholderMandatoryFieldsFilled(StakeholderTypeEnum.policyHolder) ) {
      missing.push(this.translateService.instant('case-medical-preassessment-missing-policyholder-mandatory-fields'));
    }

    if ( !this.isStakeholderMandatoryFieldsFilled(StakeholderTypeEnum.reporter) ) {
      missing.push(this.translateService.instant('case-medical-preassessment-missing-reporter-mandatory-fields'));
    }

    return missing.join("<br>");
  }

  disabledClaimsButton(): boolean {
    return this.serviceOrderClaimsList.length === 0;
  }

  addComment() {
    if ( !this.disabledCommentButton() ){
      this.modalService.open(this.noteContent, { windowClass: 'medical-note-modal-window', backdrop: 'static', keyboard: false });
    }
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  get PermissionHideTypeEnum() {
    return PermissionHideTypeEnum;
  }

  private isStakeholderMandatoryFieldsFilled(stakeholderType: StakeholderTypeEnum): boolean {
    const stakeholder = this.case.simpleCaseStakeholders?.find(item => item.hasRole(stakeholderType));
    return stakeholder?.isCompany ? !!stakeholder?.companyName : !!stakeholder?.firstName && !!stakeholder?.surname;
  }

  private getCustomerProfileInfo() {
    this.insuranceService.getCustomerProfileInfo(this.case.id).subscribe(result =>
      this.customerProfileInfo = result
    );

  }
}
