import { HandlingAreaStatusEnum, SortOrder } from '../../../../../shared/models/enums';
import * as moment from 'moment';
import { ScreeningStates } from '@secca/shared/enums/screening-states';
import { CustomerProfileIdsForSearch } from '../../board-task/models/task-filter-request.model';

export class CaseFilterRequest {
  toDate: moment.Moment;
  fromDate: moment.Moment;
  status: string;
  statuses: HandlingAreaStatusEnum[];
  sortBy: string;
  userId: string;
  userIds: number[];
  adtCode: string;
  adtCodes: string[];
  pageIndex: number;
  pageSize: number;
  sortOrder: SortOrder;
  includeOtherTeams: boolean;
  claimStatus: string[];
  incidentCoveredStates: ScreeningStates[];
  customerProfileId: number;
  adtCodesForCount: string[];
  customerProfileIds: CustomerProfileIdsForSearch[];

  public constructor(init?: Partial<CaseFilterRequest>) {
    Object.assign(this, init);
  }

}
