<div *ngIf="entry" class="output-management-entry action-button">
  <div class="output-management-entry-header">
    <span>
      <img src="/assets/icons/output-management/SMS Black.svg">
    </span>
    <span *ngIf="entry.direction == CommunicationDirection.OUT_BOUND" class="output-management-entry-header-direction">
      <img src="/assets/icons/Outgoing.svg">
    </span>
    <span *ngIf="entry.direction == CommunicationDirection.IN_BOUND" class="output-management-entry-header-direction">
      <img src="/assets/icons/Ingoing.svg">
    </span>
    <span>
      <span *ngIf="stakeholder"><img class="stakeholder-icon" [src]="stakeholderIcon"></span>
      <span *ngIf="stakeholder">&nbsp;<span class="stakeholder-name">{{stakeholderName}}</span></span>
      <span *ngIf="stakeholder && stakeholder.disabled" class="stakeholder-disabled">{{"case-stakeholders-disabled" | translate}}</span>
      <span *ngIf="!stakeholder && entry.direction === CommunicationDirection.IN_BOUND">{{entry.from}}</span>
      <span *ngIf="!stakeholder && entry.direction === CommunicationDirection.OUT_BOUND">{{entry.to}}</span>
    </span>
  </div>
  <div>
    <div class="action-button-menu-button" *ngIf="showActionButton && !deleted" (click)="showEntryMenu=!showEntryMenu"
      (clickOutside)="showEntryMenu=false">
      <div class="action-button-menu-button-dot"></div>
      <div class="action-button-menu-button-dot"></div>
      <div class="action-button-menu-button-dot"></div>
    </div>
    <div class="action-button-menu" *ngIf="showEntryMenu">
      <div class="action-button-menu-position" (click)="viewMenuClick()">
        {{"case-summary-sms-view" | translate}}</div>
      <div class="action-button-menu-position" (click)="replyMenuClick()">{{"case-summary-sms-reply" | translate}}</div>
      <div *ngIf="!caseStateService.isCaseClosed && !caseLockHelperService.isLockTaken(LockContextEnum.CASE_BASIC) && entry.direction === CommunicationDirection.OUT_BOUND">
        <div *permission='PermissionEnum.COMMUNICATION_GDPR_DELETE; hideType:PermissionHideTypeEnum.REMOVE' class="action-button-menu-position redText" (click)="openModal()">{{"case-summary-sms-delete" | translate}}</div>
      </div>
    </div>
    <div class="entry-deleted" *ngIf="deleted">
      <span class="redText" *ngIf='entry.deleteGdpr'>{{"case-summary-sms-deleted-gdpr" | translate}}</span>
      <span class="redText" *ngIf='!entry.deleteGdpr'>{{"case-summary-sms-forwarded-other-application" | translate}}</span>
    </div>
  </div>
  <div *ngIf="!deleted">
    <div class="output-management-entry-line"></div>
    <div class="output-management-entry-title">{{"case-summary-sms-message" | translate}} </div>
    <div class="output-management-entry-value">{{deleted ? '...' : entry.content}} </div>
    <div *ngIf="entry.attachments">
      <div class="output-management-entry-title">{{"case-summary-sms-attachments" | translate}} </div>
      <div class="output-management-entry-value">{{deleted ? '...' : entry.attachments}} </div>
    </div>
  </div>
</div>
