import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { DropdownDictionary } from "@secca/shared/models/dropdownDictionary";
import { CaseStateService } from "@secca/core/state-services/case-state.service";
import { CaseDialogViewerService } from "@secca/case/case-dialog-viewer.service";
import { ServiceOrder } from "@secca/shared/models/service-order/service-order";
import { Subscription, timer } from "rxjs";
import { first } from "rxjs/operators";
import {
  BalanceSheetEntryEnum,
  BalanceSheetEventEnum,
  PermissionEnum,
  PermissionHideTypeEnum,
  RefundStatus,
  ShortcutEnum,
  SupplierInvoiceStatus
} from "src/app/shared/models/enums";
import { SupplierInvoiceService } from "@secca/core/services/supplier-invoice.service";
import { SortDirective } from "@secca/shared/directives/sort.directive";
import { SupplierInvoice } from "@secca/shared/models/supplierInvoice";
import { CaseService } from "@secca/core/services/case.service";
import { HelperService } from "@secca/core/services/helper.service";
import { Case } from "@secca/shared/models/case";
import { DataService } from "@secca/core/services/data.service";
import { InsuranceService } from "@secca/core/services/insurance.service";
import { PermissionService } from "@secca/core/services/permission.service";
import { RefundService } from "@secca/core/services/refund.service";
import { ServiceOrderService } from "@secca/core/services/service-order.service";
import { SettingsService } from "@secca/core/services/settings.service";
import { StakeholderService } from "@secca/core/services/stakeholder.service";
import { AutoUnsubscribe } from "@secca/shared/decorators/auto-unsubscribe";
import { CaseStakeholder } from "@secca/shared/models/caseStakeholder";
import { ServiceItemType } from "@secca/shared/models/service-item-type";
import { BalanceSheetEntry, BalanceSheetEntryItem } from "../../case-plans/case-plans/add-service-order/models/interfaces";
import { StakeholderType } from "../../case-stakeholders/stakeholderType";
import { Refund } from "@secca/shared/models/refund";
import { MenuService } from "@secca/core/services/menu.service";
import { ShortcutService } from "@secca/core/services/shortcut.service";
import { CaseSelectedMenuEnum } from "@secca/shared/enums/case-selected-menu-enum.component";
import { EditRefundComponent } from "@secca/case/components/case-economy/case-economy-supplier-invoices/edit-refund/edit-refund.component";
import { TranslateService } from "@ngx-translate/core";

@AutoUnsubscribe
@Component({
  selector: 'app-case-economy-supplier-invoices',
  templateUrl: './case-economy-supplier-invoices.component.html',
  styleUrls: ['./case-economy-supplier-invoices.component.scss'],
})
export class CaseEconomySupplierInvoicesComponent implements OnInit, OnDestroy {
  @ViewChild('editRefund') editRefund: EditRefundComponent;
  @ViewChild(SortDirective) sortDirective;

  notInvoicedAmount: number;
  invoicedAmount: number;
  settledAmount: number;
  savingsAmount: number;
  customerProfileId: number;
  caseCurrency = 'DKK';
  balanceSheetEntries: BalanceSheetEntry[];
  filteredBalanceSheetEntries: BalanceSheetEntry[] = [];
  caseServiceOrders: ServiceOrder[];
  expanded: boolean[];
  today: Date;
  sortKey: string;
  filterableBalanceSheetType: DropdownDictionary[];
  filteredType: number;
  stakeholderMap: Map<Number, CaseStakeholder> = new Map<number, CaseStakeholder>();
  $caseServiceOrdersSubscr: Subscription;
  $permissionsReadyNotificationSubscr: Subscription;
  $caseSupplierInvoicesSubscr: Subscription;
  $caseStakeholderSubscr: Subscription;
  $caseRefundSubscr: Subscription;
  stakeholderTypes: StakeholderType[];
  private serviceItemList: ServiceItemType[];
  shortcutExpandAllSubscription: Subscription;
  allExpanded: boolean;
  showEntryMenu: any = {};

  get BalanceSheetEntryEnum() {
    return BalanceSheetEntryEnum;
  }

  @Input()
  set case(newCase: Case) {
    if(newCase) {
      this.theCase = newCase;
      this.onRefresh();
    }
  }
  get case(): Case {
    return this.theCase;
  }
  private theCase: Case;
  private permissionsReady = false;
  private hasClaimsAgreement = false;

  private selectedMenuId: number;
  private shortcutSubscription: Subscription;

  constructor(
    private caseService: CaseService,
    private supplierInvoiceService: SupplierInvoiceService,
    private serviceOrderService: ServiceOrderService,
    private dataService: DataService,
    private helperService: HelperService,
    private permissionService: PermissionService,
    private refundService: RefundService,
    private dialogViewerService: CaseDialogViewerService,
    public settingsService: SettingsService,
    private stakeholderService: StakeholderService,
    private insuranceService: InsuranceService,
    private caseStateService: CaseStateService,
    private menuService: MenuService,
    private shortcutService: ShortcutService,
    private translateService: TranslateService
  ) {
    this.shortcutSubscription = this.shortcutService.addShortcut({ keys: ShortcutEnum.AddEconomyRefund }).subscribe(a => {
      this.selectedMenuId = this.menuService.getSelectedMenuId(parseInt(this.case.id, 10));
      if (this.selectedMenuId === CaseSelectedMenuEnum.CaseEconomy) {
        if (!this.disableAddRefund()) {
          this.addNewClaim();
        }
      }
    });

    this.shortcutExpandAllSubscription = this.shortcutService.addShortcut({ keys: ShortcutEnum.EconomyServiceOrdersExpandAll }).subscribe(keyEvnt => {
      if ( this.allExpanded ) {
        this.collapseAll();
      } else {
        this.expandAll();
      }
    });
  }

  ngOnInit() {
    this.filterableBalanceSheetType = [];
    this.filterableBalanceSheetType.push(new DropdownDictionary(1, 'All'));
    this.filterableBalanceSheetType.push(new DropdownDictionary(2, 'Supplier invoice'));
    this.filterableBalanceSheetType.push(new DropdownDictionary(3, 'Refund'));

    this.$permissionsReadyNotificationSubscr = this.permissionService.permissionsReadyNotification.subscribe(
      result => (this.permissionsReady = result)
    );
    this.serviceItemList = [];
    this.supplierInvoiceService.getServiceItemList().subscribe(result => (this.serviceItemList = result));
    this.insuranceService.getPersonInsurance(this.case.id).subscribe(
      result => {
        if (result) {
          if (!!result.customerProfileId) {
            this.customerProfileId = result.customerProfileId;
            this.insuranceService.getCustomerProfileInfo(this.case.id).subscribe(info => {
              this.hasClaimsAgreement = info.claimsAgreement;
            });
          }
        }
      },
      error => console.log(error)
    );
  }

  ngOnDestroy(): void {
    this.shortcutSubscription.unsubscribe();
  }

  onRefresh() {
    if (this.theCase) {
      this.expanded = [];
      if (this.permissionService.checkUserPermission(PermissionEnum.PLANS_READ)) {
        if (this.$caseServiceOrdersSubscr) {
          this.$caseServiceOrdersSubscr.unsubscribe();
        }
        this.$caseServiceOrdersSubscr = this.serviceOrderService
          .getCaseServiceOrders()
          .subscribe(serviceOrders => (this.caseServiceOrders = serviceOrders));
      }
      if (this.permissionService.checkUserPermission(PermissionEnum.SUPPLIER_INVOICE_READ)) {
        this.reset();
        if (this.$caseSupplierInvoicesSubscr) {
          this.$caseSupplierInvoicesSubscr.unsubscribe();
        }
        this.$caseSupplierInvoicesSubscr = this.supplierInvoiceService.getCaseSupplierInvoices().subscribe(invoices => {
          if (this.$caseRefundSubscr) {
            this.$caseRefundSubscr.unsubscribe();
          }
          this.$caseRefundSubscr = this.refundService.getRefundByCaseId(parseInt(this.theCase.id)).subscribe(refunds => {
            this.reset();
            let stakeholderIds = [];
            for (const refund of refunds) {
              if (refund.stakeHolderId !== null && !stakeholderIds.includes(refund.stakeHolderId)) {
                stakeholderIds.push(refund.stakeHolderId);
              }
              this.refundService
                .getRefundHistory(refund.id)
                .pipe(first())
                .subscribe(history => refund.progress = history);
              this.filteredBalanceSheetEntries.push(refund);
              this.balanceSheetEntries.push(refund);
            }

            setTimeout(() => {
              this.filteredBalanceSheetEntries.sort(function (a, b) {
                return a.getId() - b.getId();
              });
              this.refundService.sendCaseRefunds(refunds);
            }, 500);

            for (const stakeholderId of stakeholderIds) {
              this.$caseStakeholderSubscr = this.stakeholderService.getStakeholder(stakeholderId).subscribe(stakeholder => {
                this.stakeholderMap.set(stakeholderId, stakeholder);
              });
            }
            if (invoices) {
              for (const invoice of invoices) {
                this.filteredBalanceSheetEntries.push(invoice);
                this.balanceSheetEntries.push(invoice);
              }
            }

            this.filteredBalanceSheetEntries.sort((a, b) => (a.getInvoiceDate() > b.getInvoiceDate() ? -1 : 1));
            if (this.expanded.length !== this.filteredBalanceSheetEntries.length) {
              this.expanded = new Array(this.filteredBalanceSheetEntries.length);
            }
            this.calculateAggregations();

            if (this.dataService.navigateSupplierInvoiceId) {
              const taskSupplierInvoiceId = this.dataService.navigateSupplierInvoiceId;
              this.dataService.navigateSupplierInvoiceId = null;
              // When navigating from supplier invoice task board, this component is not always fully initialised, supplierInvoiceModal is null.
              // Delay opening modal lets the initialisation complete
              timer(300).subscribe(t => this.openManageInvoice(taskSupplierInvoiceId));
            } else if (this.dataService.navigateRefundId) {
              const taskRefundId = this.dataService.navigateRefundId;
              const refundClicked = this.balanceSheetEntries.filter(entry => entry.getId() === taskRefundId && entry.getType() === BalanceSheetEntryEnum.REFUND)[0];
              this.dataService.navigateRefundId = null;
              timer(300).subscribe(t => this.openManageRefund(refundClicked));
            }
          });
        });
      }
    }
  }

  updateFilter() {
    this.filteredBalanceSheetEntries = [];
    switch (this.filteredType) {
      case 1:
        for (const entry of this.balanceSheetEntries) {
          this.filteredBalanceSheetEntries.push(entry);
        }
        break;
      case 2:
        for (const entry of this.balanceSheetEntries) {
          if (entry.getType() === BalanceSheetEntryEnum.SUPPLIER_INVOICE) {
            this.filteredBalanceSheetEntries.push(entry);
          }
        }
        break;
      case 3:
        for (const entry of this.balanceSheetEntries) {
          if (entry.getType() === BalanceSheetEntryEnum.REFUND) {
            this.filteredBalanceSheetEntries.push(entry);
          }
        }
        break;
    }
    this.calculateAggregations();
  }

  private reset() {
    this.balanceSheetEntries = [];
    this.filteredBalanceSheetEntries = [];
    this.notInvoicedAmount = 0;
    this.invoicedAmount = 0;
    this.settledAmount = null;
    this.savingsAmount = null;
  }

  private calculateAggregations() {
    this.settledAmount = 0;
    this.savingsAmount = 0;

    for (const entry of this.filteredBalanceSheetEntries) {
      this.caseCurrency = entry.getInvoiceAmountExchanged()?.currency;
      this.invoicedAmount += entry.getInvoiceAmountExchanged()?.amount;
      this.settledAmount += this.calculateInvoiceSettled(entry);
      this.savingsAmount += this.calculateTotalInvoiceSavings(entry);
    }
    if (this.settledAmount === 0) {
      this.settledAmount = null;
    }
    if (this.savingsAmount === 0) {
      this.savingsAmount = null;
    }
  }

  isRefundCancelled(refund: Refund){
    return refund.refundStatus === RefundStatus.CANCELLED.toString();
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  get PermissionHideTypeEnum() {
    return PermissionHideTypeEnum;
  }

  calculateTotalInvoiceSavings(entry: BalanceSheetEntry): number {
    let invoiceSavings = 0;
    if (this.isBalanceSheetApproved(entry)) {
      for (const item of entry.getItems()) {
        invoiceSavings += this.calculateItemNetSavings(entry, item);
        invoiceSavings += this.calculateItemGrossSavings(entry, item);
      }
    }
    entry.setReductionAmount(invoiceSavings);
    return invoiceSavings;
  }

  calculateItemGrossSavings(entry: BalanceSheetEntry, item: BalanceSheetEntryItem): number {
    let itemSavings = 0;
    if (
      entry.getStatus() === SupplierInvoiceStatus.invoiceReceived.toString() ||
      entry.getStatus() === SupplierInvoiceStatus.ongoingCostControl.toString()
    ) {
      return 0;
    }
    for (const saving of item.getSavings()) {
      if (saving.getGross()) {
        itemSavings += Number(saving.getAmount());
      }
    }
    return itemSavings * entry.getExchangeRate();
  }

  calculateItemNetSavings(entry: BalanceSheetEntry, item: BalanceSheetEntryItem): number {
    let itemSavings = 0;
    if (
      entry.getStatus() === SupplierInvoiceStatus.invoiceReceived.toString() ||
      entry.getStatus() === SupplierInvoiceStatus.ongoingCostControl.toString()
    ) {
      return 0;
    }
    for (const saving of item.getSavings()) {
      if (!saving.getGross()) {
        itemSavings += Number(saving.getAmount());
      }
    }
    return itemSavings * entry.getExchangeRate();
  }

  calculateItemTotalSavings(entry: BalanceSheetEntry, item: BalanceSheetEntryItem): number {
    return this.calculateItemNetSavings(entry, item) + this.calculateItemGrossSavings(entry, item);
  }

  calculateInvoiceSettled(entry: BalanceSheetEntry): number {
    let settled = 0;
    if (this.isBalanceSheetApproved(entry)) {
      for (const item of entry.getItems()) {
        if (item.getIsIncludingVAT() && item.getIsInVATCreditorGroup()) {
          const itemSettled = this.calculateItemSettled(entry, item);
          settled += this.removeVAT(itemSettled);
        } else {
          settled += this.calculateItemSettled(entry, item);
        }
      }
      entry.setSettledAmount(settled);
    }
    return settled;
  }

  calculateItemSettled(entry: BalanceSheetEntry, item: BalanceSheetEntryItem): number {
    if (!this.isBalanceSheetApproved(entry)) {
      return 0;
    }
    return item.getOriginalAmount() * entry.getExchangeRate() - this.calculateItemNetSavings(entry, item);
  }

  removeVAT(amount: number): number {
    return (amount / 100) * 80;
  }

  get SupplierInvoiceStatus() {
    return SupplierInvoiceStatus;
  }

  get RefundStatus() {
    return RefundStatus;
  }

  expandAll() {
    this.allExpanded = true;
    this.filteredBalanceSheetEntries?.forEach((s,i) => this.expanded[i] = true);
  }

  collapseAll() {
    this.allExpanded = false;
    this.filteredBalanceSheetEntries?.forEach((s,i) => this.expanded[i] = false);
  }

  expand(i: number) {
    this.expanded[i] = this.expanded[i] !== true;
  }

  openManageInvoice(supplierInvoiceId: number) {
    this.dialogViewerService.openManageSupplierInvoiceDialog(
      supplierInvoiceId,
      this.customerProfileId,
      this.case.caseNumber,
      this.case.caseCreatedDate,
      supplierInvoice => this.supplierInvoiceSaved(supplierInvoice)
    );
  }

  openManageRefund(refund: BalanceSheetEntry) {
    let readOnly = false;
    if (!this.permissionService.checkUserPermission(PermissionEnum.REFUND_UPDATE)
    || RefundStatus.DELETED === refund.getStatus()) {
      readOnly = true;
    }

    this.dialogViewerService.openManageClaimsDialog(
      this.case.id,
      refund.isObjection(),
      this.customerProfileId.toString(),
      refund.getId().toString(),
      readOnly,
      this.case.caseCreatedDate,
      () => this.refundSaved(),
      () => this.refundDeleted()
    );
  }

  refundSaved() {
    this.onRefresh();
  }

  refundDeleted() {
    this.onRefresh();
  }

  supplierInvoiceSaved(supplierInvoice: SupplierInvoice) {
    const supplierInvoiceId = supplierInvoice.id;

    const postsave: SupplierInvoice[] = [];
    for (const invoice of this.balanceSheetEntries) {
      if (invoice.getId() === supplierInvoiceId) {
        postsave.push(supplierInvoice);
        this.supplierInvoiceService
          .getSupplierInvoiceHistory(supplierInvoiceId)
          .pipe(first())
          .subscribe(history => supplierInvoice.progress = history);
      } else {
        if (invoice instanceof SupplierInvoice) {
          postsave.push(invoice);
        }
      }
    }

    this.supplierInvoiceService.sendCaseSupplierInvoices(postsave);
  }

  getServiceItemName(serviceItemCode: string): string {
    const serviceItem = this.serviceItemList.find(si => si.code === serviceItemCode);
    if (serviceItem) {
      return serviceItem.name;
    }
    return '';
  }

  setSortKey(key: string) {
    this.sortKey = key;
  }

  isBalanceSheetApproved(balanceSheetEntry: BalanceSheetEntry): boolean {
    return (
      balanceSheetEntry.getStatus() === SupplierInvoiceStatus.approved.toString() ||
      balanceSheetEntry.getStatus() === SupplierInvoiceStatus.supplierPaid.toString() ||
      balanceSheetEntry.getStatus() === RefundStatus.APPROVED.toString() ||
      balanceSheetEntry.getStatus() === RefundStatus.PAID.toString()
    );
  }

  medicalEscortAuthorized(balanceSheetEntry: BalanceSheetEntry): boolean {
    if (!balanceSheetEntry.getMedicalEscort()) {
      return true;
    }
    if (
      this.permissionsReady &&
      balanceSheetEntry.getMedicalEscort() &&
      this.permissionService.checkUserPermission(PermissionEnum.MEDICAL_ESCORT_SUPPLIER_INVOICE_READ)
    ) {
      return true;
    }
    return false;
  }

  isSupplierInvoice(balanceSheetEntry: BalanceSheetEntry): boolean {
    return balanceSheetEntry.getType() === BalanceSheetEntryEnum.SUPPLIER_INVOICE;
  }

  getStakeholderIconUrl(stakeholderId: number) {
    let stakeholder = this.stakeholderMap.get(stakeholderId);
    if (stakeholder) {
      return '/assets/icons/' + this.stakeholderService.addIconToCaseStakeholder(stakeholder.stakeholderType) + '.svg';
    }
    return '';
  }

  getStakeholderName(stakeholderId: number) {
    let stakeholder = this.stakeholderMap.get(stakeholderId);
    if (stakeholder && stakeholder.person) {
      return stakeholder.person.firstName + ' ' + stakeholder.person.surname;
    }
    return '';
  }

  addNewClaim() {
    this.openCreateNewRefundDialog(false);
  }

  addNewObjection() {
    this.openCreateNewRefundDialog(true);
  }

  openCreateNewRefundDialog(objection: boolean){
    this.dialogViewerService.openManageClaimsDialog(
      this.case.id,
      objection,
      this.customerProfileId.toString(),
      null,
      false,
      this.case.caseCreatedDate,
      this.refundSaved.bind(this),
      this.refundDeleted.bind(this)
    );
  }

  disableAddRefund() {
    return !this.hasClaimsAgreement || this.caseStateService.isCaseClosed || this.caseStateService.isInvoiceClosedWithNoInvoicingToCustomer || this.caseStateService.isSanctionCase;
  }

  disableAddRefundHover() {
    return (this.hasClaimsAgreement && !this.caseStateService.isSanctionCase) || this.caseStateService.isCaseClosed || this.caseStateService.isInvoiceClosedWithNoInvoicingToCustomer;
  }

  isObjectionEnabled(): boolean {
    return this.hasClaimsAgreement && this.balanceSheetEntries.filter(entry => entry.getType() === BalanceSheetEntryEnum.REFUND && (entry.getStatus() === RefundStatus.REJECTED.toString() || entry.getStatus() === RefundStatus.APPROVED.toString() || entry.getStatus() === RefundStatus.PAID.toString())).length > 0;
  }

  disableAddObjection() {
    return !this.isObjectionEnabled() || this.caseStateService.isCaseClosed || this.caseStateService.isSanctionCase;
  }

  disableAddObjectionHover() {
    return (this.isObjectionEnabled() && !this.caseStateService.isSanctionCase) || this.caseStateService.isCaseClosed;
  }

  isAddSoDisabled() {
    return !this.hasClaimsAgreement || this.caseStateService.isCaseClosed || this.caseStateService.isInvoiceClosedWithNoInvoicingToCustomer;
  }

  refundPopOverText() {
    if ( this.caseStateService.isSanctionCase ) {
      return this.translateService.instant('add-not-possible-on-sanction-case');
    }
    else {
      return this.translateService.instant('add-no-claims-agreement');
    }
  }

  objectionPopOverText() {
    if ( this.caseStateService.isSanctionCase ) {
      return this.translateService.instant('add-not-possible-on-sanction-case');
    }
    else {
      return this.translateService.instant('add-no-refund-or-claims-agreement');
    }
  }

  deleteRefund(refund: BalanceSheetEntry) {
    // we need to check here as well, since the grey <p> can still be clicked
    if (RefundStatus.ERROR === RefundStatus[refund.getStatus()]) {
      this.editRefund.show(this.case.id, refund.getId(), 'case-economy-edit-refund-delete');
    }
  }

  getEventsToDisplay(): BalanceSheetEventEnum[] {
    return Object.keys(BalanceSheetEventEnum)
      .filter(key => key !== BalanceSheetEventEnum.EXPECTED && key !== BalanceSheetEventEnum.COMMITMENT)
      .map(key => BalanceSheetEventEnum[key]);
  }

  supplierInvoiceResetRetry(entry: BalanceSheetEntry) {
    this.supplierInvoiceService.resetFinanceBridgeStatus(entry.getId()).subscribe(() => {
      this.supplierInvoiceSaved(new SupplierInvoice({ id: entry.getId() }));
    });
  }

  setShowEntryMenu(i: number) {
    if (this.showEntryMenu[i]) {
      this.showEntryMenu[i] = !this.showEntryMenu[i];
    } else {
      this.showEntryMenu[i] = true;
    }
  }

  getShowEntryMenu(i: number): boolean {
    if (this.showEntryMenu[i]) {
      return this.showEntryMenu[i];
    } else {
      return false;
    }
  }

  clearShowEntryMenu(i: number) {
    this.showEntryMenu[i] = false;
  }
}
