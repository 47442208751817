import { Component, Inject, ViewChild } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { DialogBoundryService } from "@secca/core/services/dialog-boundry.service";
import { DialogHelperUtilService } from "@secca/core/services/dialog-helper-util.service";
import { DialogMinimizableDirective } from "@secca/shared/directives/dialog-minimizable.directive";
import { MinimizableDialogType } from "@secca/shared/enums/minimizable-dialog-type-enum";
import {AdditionalInfo, IAdditionalInfo} from "@secca/shared/interfaces/additional-info";
import { IMinimizable } from "@secca/shared/interfaces/minimizable";
import { CaseSummaryDigitalCaseflowDialogData } from "./case-summary-digital-caseflow-dialog-data.component";
import { IKeyboardEnabled } from "@secca/shared/interfaces/keyboard-enabled";
import {
  CaseSummaryDigitalCaseflowModalComponent
} from "@secca/case/components/case-summery/case-summary-list/case-summary-dcf/case-summary-dcf-modal/case-summary-digital-caseflow-modal.component";

@Component({
    selector: 'app-case-summary-digital-caseflow-dialog',
    templateUrl: './case-summary-digital-caseflow-dialog.component.html',
    styleUrls: ['./case-summary-digital-caseflow-dialog.component.scss'],
  })
  export class CaseSummaryDigitalCaseflowDialogComponent implements IAdditionalInfo, IMinimizable, IKeyboardEnabled {
    @ViewChild(DialogMinimizableDirective) minimizedDirective: DialogMinimizableDirective;
    @ViewChild(CaseSummaryDigitalCaseflowModalComponent) wrappedInstance: CaseSummaryDigitalCaseflowModalComponent;

    data: CaseSummaryDigitalCaseflowDialogData;

    constructor(private dialogRef: MatDialogRef<CaseSummaryDigitalCaseflowDialogComponent>,
                @Inject(MAT_DIALOG_DATA) data: CaseSummaryDigitalCaseflowDialogData,
                private dialogHelperUtilService: DialogHelperUtilService,
                public dialogBoundryService: DialogBoundryService) {
      this.data = data;
    }

    close() {
      this.dialogHelperUtilService.close(this.dialogRef, this.data.caseId);
    }

    keyboardClose(): void {
      this.wrappedInstance.close();
    }

    minimize() {
      this.minimizedDirective.minimize();
    }

    getMinimizeType(): MinimizableDialogType {
      return this.minimizedDirective.appDialogMinimizable;
    }
    get MinimizableDialogType() {
      return MinimizableDialogType;
    }

  getAdditionalInfo(): AdditionalInfo {
    return {
      caseId: +this.data.caseId,
      hoverTitle: 'View digital case flow log',
      hoverText: '' + this.data.log.flowInitiationDate
    };
  }



}
