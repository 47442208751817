<div class="common-drop-down drop-down-auto-complete">
  <div class="dropdown-cell" [ngStyle]="!withInput && {'width':'100%'}">
    <span class="name">{{name}}</span>
    <span *ngIf="required" class="required-red">*</span>
    <ng-select
        #select
        (blur)="onSaveModel()"
        [typeahead]="input"
        (focus)="onSaveState()"
        [ngClass]="{ 'small' : showCode === true , 'custom' : showCode !== true, 'recommended-yellow-background': recommended && !currentSelectedItemId && !disabled }"
        [class.dropdown-cell-highlight-border]="highlightBorderOnInputNonEmpty && (!!currentSelectedItemId || !!value) && !disabled"
        [class.right]="!withInput"
        [clearable]="false"
        [(ngModel)]="currentSelectedItemId"
        placeholder=""
        (change)="onChange($event)"
        [items]="items | async"
        [disabled]="!!disabled"
      bindValue="code">
      <ng-template ng-label-tmp let-item="item" let-index="index">
        <div *ngIf="item.url !== undefined; else generateData">
          <div *ngIf="showCode; else showName">
            <flag-icon *ngIf="item.code" [country]="item.code.toLowerCase()"></flag-icon>
            {{item.code}}
          </div>
          <ng-template #showName>
            <flag-icon *ngIf="item.code" [country]="item.code.toLowerCase()"></flag-icon>
            <span *ngIf="showPhonePrefix"> {{item.phonePrefix}}</span>
            {{item.countryName}}
          </ng-template>
        </div>
        <ng-template #generateData>
          <flag-icon *ngIf="item.code" [country]="item.code.toLowerCase()"></flag-icon>
          <span *ngIf="showCode; else showName">
            {{item.code}}
          </span>
          <ng-template #showName>
            {{countryName}}
          </ng-template>
        </ng-template>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-index="index">
        <div class="row" (click)="onItemClick(item)">
          <div class="col-2">
            <flag-icon *ngIf="item.code" [country]="item.code.toLowerCase()" class="country-image"></flag-icon>
          </div>
          <div class="col-10">
            <div class="country-name">{{item.countryName}}</div>
          </div>
        </div>
      </ng-template>
    </ng-select>
  </div>
  <div class="input-margin-top" [ngClass]="errorTextClass">
    <span *ngIf="!!errorText" class="show-error-text">{{errorText}}</span>
    <div class="input-cell input-container input-width" *ngIf="withInput" [ngClass]="validationClass">
      <app-input [withImage]="false" [(value)]="value" (valueChange)="onValueChange($event)" (focusout)="inputFucusOut()" (saveModel)="onValueSaveModel($event)" [recommended]="recommended && !value"
                [class.input-cell-highlight-border]="highlightBorderOnInputNonEmpty && (!!currentSelectedItemId || !!value) && !disabled"
                [validate]="validate"
                [disabled]="blockEmail || !!disabled"
                [validationType]="validationTypeToInput"
                [isValid]="isValid"
                [placeholder]="placeholder"
                [highlightBorderOnInputNonEmpty]="false">
      </app-input>
      <img *ngIf="showEye" src='./../../../../../../../assets/icons/eye.svg' (click)=showNationalIdClick() class="showNationalId">
    </div>
  </div>
</div>
