import { Component, Input } from '@angular/core';
import { CauseItemType } from '@secca/shared/models/cause-item-type';
import { ServiceItemType } from '@secca/shared/models/service-item-type';
import { SimpleCustomerProfile } from '@secca/shared/models/simpleCustomerProfile';
import { PurposeOfTravelItemType } from '@secca/shared/models/purpose-of-travel-item-type';
import { IncomingCasesTaskViewModel } from '../../../models/incoming-cases-task-view-model';
import { TaskType } from '@secca/shared/models/enums';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { ServiceOrder } from '@secca/shared/models/service-order/service-order';
import { CaseService } from '@secca/core/services/case.service';
import { Diagnosis } from '@secca/shared/models/dignosis';

@Component({
  selector: 'app-task-web-medical-report-panel',
  templateUrl: './task-web-medical-report-panel.component.html',
  styleUrls: ['./task-web-medical-report-panel.component.scss']
})
export class TaskWebMedicalReportPanelComponent {
  @Input() set taskDataViewModel(taskDataViewModel: IncomingCasesTaskViewModel) {
    this._taskDataViewModel = taskDataViewModel;
    if (this.isProcessGopRequest()) {
      this.setServiceOrderId();
    }
  }
  get taskDataViewModel(): IncomingCasesTaskViewModel {
    return this._taskDataViewModel;
  }

  @Input() set companyStakeholders(companyStakeholders: CaseStakeholder[]) {
    this._companyStakeholders = companyStakeholders;
    if (this.isProcessGopRequest()) {
      this.setSupplierName();
    }
  }
  get companyStakeholders(): CaseStakeholder[] {
    return this._companyStakeholders;
  }

  @Input() serviceItemList: ServiceItemType[];
  @Input() purposeOfTravelItemList: PurposeOfTravelItemType[];
  @Input() causes: Map<string, CauseItemType[]>;
  @Input() diagnoses: Diagnosis[];
  @Input() customerProfiles: SimpleCustomerProfile[];

  private serviceOrders: ServiceOrder[];

  private _supplierName: string;
  private _serviceOrderId: string;
  private _taskDataViewModel: IncomingCasesTaskViewModel;
  private _companyStakeholders: CaseStakeholder[];

  constructor(private caseService: CaseService) { }

  isProcessGopRequest(): boolean {
    if (this.taskDataViewModel) {
      return this.taskDataViewModel.taskType === TaskType.processGopRequest;
    }
    return false;
  }

  get supplierName(): string {
    return this._supplierName;
  }

  get serviceOrderId(): string {
    return this._serviceOrderId;
  }

  private setSupplierName() {
    this._supplierName = this.companyStakeholders?.find(stakeholder => stakeholder?.company.providerId === this.taskDataViewModel?.data?.providerId)?.company.name;
  }

  private setServiceOrderId() {
    this.caseService.getServiceOrdersForCase('' + this.taskDataViewModel?.caseId).subscribe(serviceOrders => {
      this.serviceOrders = serviceOrders;
      this._serviceOrderId = this.serviceOrders?.find(serviceOrder => serviceOrder.id === this.taskDataViewModel?.data?.serviceOrderId)?.serviceOrderId;
    });
  }
}
