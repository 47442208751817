import { Component, Input, OnInit } from '@angular/core';
import { SubRepatriationDetails } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subRepatriationDetails';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import { TranslateService } from '@ngx-translate/core';
import {
  DetailsCommonListTypeEnum,
  DetailsOxygenSuppliedTypeEnum,
  DetailsTransportTypeEnum,
  DetailsWheelchairTypeEnum
} from '@secca/case/components/case-plans/case-plans/add-service-order/models/enums';
import { MedicalService } from '@secca/core/services/medical.service';
import { MedicalAssessment } from '@secca/shared/models/medicalAssessment';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import * as moment from 'moment';
import { ExtraSpaceEnum, IntensiveCarePatientEnum, RepatriationFormEnum, RepatriationMediumEnum, ServiceTypeEnum } from '@secca/shared/models/enums';
import { ServiceOrderDetailsHelper } from '@secca/case/components/case-plans/plan/service-order/service-order-details-helper';
import { Oxygen } from '@secca/shared/models/medical-models/oxygen';

@Component({
  selector: 'app-sub-repatriation-details',
  templateUrl: './sub-repatriation-details.component.html',
  styleUrls: ['./sub-repatriation-details.component.scss'],
})
@AutoUnsubscribe
export class SubRepatriationDetailsComponent implements OnInit {
  @Input() caseId: string;
  @Input() subRepatriationDetails: SubRepatriationDetails;
  @Input() hideFormIntensiveCarePatient = false;
  @Input() hideFormRegularFlightEntries = false;
  @Input() serviceOrderType: ServiceTypeEnum;
  @Input() medicalAssessmentId;

  medicalAssessment: MedicalAssessment;

  repatriationFormTypesDropdown: DropdownDictionary[] = [];
  coTransportAcceptableTypesDropdown: DropdownDictionary[] = [];
  wheelchairTypesDropdown: DropdownDictionary[] = [];
  groundAmbulanceToAmbulanceFlightTypesDropdown: DropdownDictionary[] = [];
  medicalCareInTransitTypesDropdown: DropdownDictionary[] = [];
  groundAmbulanceFromAmbulanceFlightTypesDropdown: DropdownDictionary[] = [];
  admissionUponArrivalTypesDropdown: DropdownDictionary[] = [];
  oxygenInFlightTypesDropdown: DropdownDictionary[] = [];
  oxygenOnGroundTypesDropdown: DropdownDictionary[] = [];
  oxygenSuppliedBySosRequestedTypesDropdown: DropdownDictionary[] = [];
  oxygenSuppliedBySosBookedTypesDropdown: DropdownDictionary[] = [];
  sosOxygenOnEscortOutboundFlightsTypesDropdown: DropdownDictionary[] = [];
  sosOxygenOnEscortReturnFlightsTypesDropdown: DropdownDictionary[] = [];
  escort1TypesDropdown: DropdownDictionary[] = [];
  escort2TypesDropdown: DropdownDictionary[] = [];
  equipmentTypesDropdown: DropdownDictionary[] = [];
  medicationTypesDropdown: DropdownDictionary[] = [];
  repatriationFormRequested: string = '';
  private readonly detailsCommonList1Types = [
    DetailsCommonListTypeEnum.NOT_ORGANIZED,
    DetailsCommonListTypeEnum.AWAITING_CONFIRMATION,
    DetailsCommonListTypeEnum.CONFIRMED,
    DetailsCommonListTypeEnum.NOT_AVAILABLE_FROM_SUPPLIER,
    DetailsCommonListTypeEnum.NOT_RELEVANT,
  ];

  private readonly detailsCommonList2Types = [
    DetailsCommonListTypeEnum.NOT_ORGANIZED,
    DetailsCommonListTypeEnum.ORGANIZED,
    DetailsCommonListTypeEnum.NOT_RELEVANT,
  ];

  private readonly detailsCommonList4Types = [
    DetailsCommonListTypeEnum.NOT_RESERVED,
    DetailsCommonListTypeEnum.RESERVED,
    DetailsCommonListTypeEnum.BOOKED,
    DetailsCommonListTypeEnum.SUPPLIED_BY_ESCORT,
    DetailsCommonListTypeEnum.NOT_RELEVANT,
  ];

  constructor(private medicalService: MedicalService,
              private translate: TranslateService,
              private serviceOrderDetailsHelper: ServiceOrderDetailsHelper) {}

  ngOnInit() {
    this.medicalService
      .getMedicalAssessment(this.medicalAssessmentId)
      .subscribe(medicalAssessment => {
        this.medicalAssessment = medicalAssessment;
        this.repatriationFormRequested = this.serviceOrderDetailsHelper.getRepatriationFormRequested(this.medicalAssessment)
        this.composeRepatriationFormTypeDropdown(this.hideFormIntensiveCarePatient, this.hideFormRegularFlightEntries);
      });

    this.composeDetailsTransportTypeDropdown();
    this.composeDetailsWheelchairTypeDropdown();

    this.composeDetailsCommonListTypeDropdown(this.groundAmbulanceToAmbulanceFlightTypesDropdown, this.detailsCommonList1Types);
    this.composeDetailsCommonListTypeDropdown(this.medicalCareInTransitTypesDropdown, this.detailsCommonList2Types);
    this.composeDetailsCommonListTypeDropdown(this.groundAmbulanceFromAmbulanceFlightTypesDropdown, this.detailsCommonList1Types);
    this.composeDetailsCommonListTypeDropdown(this.admissionUponArrivalTypesDropdown, this.detailsCommonList2Types);
    this.composeDetailsCommonListTypeDropdown(this.oxygenInFlightTypesDropdown, this.detailsCommonList1Types);
    this.composeDetailsCommonListTypeDropdown(this.oxygenOnGroundTypesDropdown, this.detailsCommonList2Types);

    this.composeDetailsOxygenSuppliedTypeDropdown();

    this.composeDetailsCommonListTypeDropdown(this.sosOxygenOnEscortOutboundFlightsTypesDropdown, this.detailsCommonList1Types, [
      DetailsCommonListTypeEnum.NOT_ORGANIZED,
      DetailsCommonListTypeEnum.NOT_AVAILABLE_FROM_SUPPLIER,
    ]);
    this.composeDetailsCommonListTypeDropdown(this.sosOxygenOnEscortReturnFlightsTypesDropdown, this.detailsCommonList1Types, [
      DetailsCommonListTypeEnum.NOT_ORGANIZED,
      DetailsCommonListTypeEnum.NOT_AVAILABLE_FROM_SUPPLIER,
    ]);
    this.composeDetailsCommonListTypeDropdown(this.escort1TypesDropdown, this.detailsCommonList4Types, [
      DetailsCommonListTypeEnum.SUPPLIED_BY_ESCORT,
    ]);
    this.composeDetailsCommonListTypeDropdown(this.escort2TypesDropdown, this.detailsCommonList4Types, [
      DetailsCommonListTypeEnum.SUPPLIED_BY_ESCORT,
    ]);
    this.composeDetailsCommonListTypeDropdown(this.equipmentTypesDropdown, this.detailsCommonList4Types, [
      DetailsCommonListTypeEnum.SUPPLIED_BY_ESCORT,
    ]);
    this.composeDetailsCommonListTypeDropdown(this.medicationTypesDropdown, this.detailsCommonList4Types);
  }

  updateRepatriationPossibleBy(repatriationPossibleBy: moment.Moment) {
    this.subRepatriationDetails.repatriationPossibleBy = repatriationPossibleBy;
  }

  get wheelchairRequested(): string {
    let wheelchairRequested = '';
    if (this.medicalAssessment && this.medicalAssessment.repatriationInstructionsBase.patientNeeds.wheelchairNeed) {
      wheelchairRequested = this.translate.instant(
        `repatriation-wheelchair-need-${this.serviceOrderDetailsHelper.convertToI18nKey(
          this.medicalAssessment.repatriationInstructionsBase.patientNeeds.wheelchairNeed
        )}`
      );
    }
    return wheelchairRequested;
  }

  getEscortRequested(index: number): string {
    let escortRequested = this.translate.instant('sub-repatriation-details-no');
    if (
      this.medicalAssessment &&
      this.medicalAssessment.repatriationInstructionsBase.patientNeeds.escort &&
      this.medicalAssessment.repatriationInstructionsBase.patientNeeds.escort[index]
    ) {
      const escortType = this.translate.instant(
        `medical-escort-type.${this.medicalAssessment.repatriationInstructionsBase.patientNeeds.escort[index].type}`
      );
      escortRequested = `${this.translate.instant('sub-repatriation-details-yes')}, ${escortType}`;
    }
    return escortRequested;
  }

  get ServiceTypeEnum() {
    return ServiceTypeEnum;
  }

  get DetailsCommonListTypeEnum() {
    return DetailsCommonListTypeEnum;
  }

  get equipmentRequested(): string {
    let equipmentRequested = this.translate.instant('sub-repatriation-details-no');
    if (this.medicalAssessment && this.medicalAssessment.repatriationInstructionsBase.patientNeeds.equipmentNeeded) {
      equipmentRequested = this.translate.instant('sub-repatriation-details-yes');
      for (const equipment of this.medicalAssessment.repatriationInstructionsBase.patientNeeds.equipment) {
        const equipmentItemType = this.translate.instant(`repatriation-equipment-${this.serviceOrderDetailsHelper.convertToI18nKey(equipment.item)}`);
        equipmentRequested += `, ${equipmentItemType}`;
      }
    }
    return equipmentRequested;
  }

  get medicationRequested(): string {
    let medicationRequested = this.translate.instant('sub-repatriation-details-no');
    if (this.medicalAssessment && this.medicalAssessment.repatriationInstructionsBase.patientNeeds.medicationNeeded) {
      medicationRequested = this.translate.instant('sub-repatriation-details-yes');
      for (const medication of this.medicalAssessment.repatriationInstructionsBase.patientNeeds.medication) {
        const medicationItemType = this.translate.instant(`repatriation-medication-${this.serviceOrderDetailsHelper.convertToI18nKey(medication.item)}`);
        medicationRequested += `, ${medicationItemType}`;
      }
    }
    return medicationRequested;
  }

  getOxygenNeededInFlight(medicalAssessment: MedicalAssessment): string {
    const oxygen = medicalAssessment?.repatriationInstructionsBase?.patientNeeds?.oxygen?.find(o => o.type === RepatriationMediumEnum.IN_FLIGHT || o.type === RepatriationMediumEnum.IN_FLIGHT_AND_ON_GROUND);
    return oxygen ? this.getOxygenNeeded(oxygen) : '';
  }

  getOxygenNeededOnGround(medicalAssessment: MedicalAssessment): string {
    const oxygen = medicalAssessment?.repatriationInstructionsBase?.patientNeeds?.oxygen?.find(o => o.type === RepatriationMediumEnum.ON_GROUND || o.type === RepatriationMediumEnum.IN_FLIGHT_AND_ON_GROUND);
    return oxygen ? this.getOxygenNeeded(oxygen) : '';
  }

  oxygenSuppliedBySosChanged(value: string): void {
    if ( value === DetailsCommonListTypeEnum.NOT_RELEVANT ) {
      this.subRepatriationDetails.sosOxygenOnEscortOutboundFlights = DetailsCommonListTypeEnum.NOT_RELEVANT;
      this.subRepatriationDetails.sosOxygenOnEscortReturnFlights = DetailsCommonListTypeEnum.NOT_RELEVANT;
    }
    else {
      this.subRepatriationDetails.sosOxygenOnEscortOutboundFlights = null;
      this.subRepatriationDetails.sosOxygenOnEscortReturnFlights = null;
    }
  }

  private getOxygenNeeded(oxygen: Oxygen): string {
    const oxygenAvailability = this.translate.instant('repatriation-oxygen-availability-' + this.serviceOrderDetailsHelper.convertToI18nKey(oxygen.availability));
    const oxygenRate = oxygen.rate + ' ' + this.translate.instant('sub-repatriation-details-oxygen-rate-unit');

    return `${oxygenRate}, ${oxygenAvailability}`;
  }

  private composeRepatriationFormTypeDropdown(hideFormIntensiveCarePatient: boolean, hideFormRegularFlightEntries: boolean) {
    this.repatriationFormTypesDropdown = [];

    if ( !hideFormRegularFlightEntries ) {
      this.repatriationFormTypesDropdown.push(...[
        {
          id: RepatriationFormEnum.SITTING_SICK_IN_ECONOMY_CLASS_SEAT,
          name: this.serviceOrderDetailsHelper.getRepatriationFormName(RepatriationFormEnum.SITTING_SICK_IN_ECONOMY_CLASS_SEAT.toString())
        },
        {
          id: ExtraSpaceEnum.SEAT_IN_FRONT,
          name: this.serviceOrderDetailsHelper.getExtraSpaceEntry(ExtraSpaceEnum.SEAT_IN_FRONT)
        },
        {
          id: ExtraSpaceEnum.SIDE_BY_SIDE_TWO,
          name: this.serviceOrderDetailsHelper.getExtraSpaceEntry(ExtraSpaceEnum.SIDE_BY_SIDE_TWO)
        },
        {
          id: ExtraSpaceEnum.SIDE_BY_SIDE_TREE,
          name: this.serviceOrderDetailsHelper.getExtraSpaceEntry(ExtraSpaceEnum.SIDE_BY_SIDE_TREE)
        },
        {
          id: ExtraSpaceEnum.RECLINING_SEAT,
          name: this.serviceOrderDetailsHelper.getExtraSpaceEntry(ExtraSpaceEnum.RECLINING_SEAT)
        },
        {
          id: ExtraSpaceEnum.OTHER,
          name: this.serviceOrderDetailsHelper.getExtraSpaceEntry(ExtraSpaceEnum.OTHER)
        },
        {
          id: RepatriationFormEnum.STRETCHER,
          name: this.serviceOrderDetailsHelper.getRepatriationFormName(RepatriationFormEnum.STRETCHER.toString())
        }
      ]);
    }

    if (!hideFormIntensiveCarePatient) {
      this.repatriationFormTypesDropdown.push(
        {
          id: IntensiveCarePatientEnum.NOT_SEA_LEVEL,
          name: this.serviceOrderDetailsHelper.getIntensiveCarePatientName(false, true)
        }
      );
      this.repatriationFormTypesDropdown.push(
        {
          id: IntensiveCarePatientEnum.SEA_LEVEL,
          name: this.serviceOrderDetailsHelper.getIntensiveCarePatientName(true, true)
        }
      );
    }
  }

  private composeDetailsTransportTypeDropdown() {
    this.coTransportAcceptableTypesDropdown = [
      {
        id: DetailsTransportTypeEnum.NOT_CO_TRANSPORT,
        name: this.translate.instant(`sub-repatriation-details-transport-type.${DetailsTransportTypeEnum.NOT_CO_TRANSPORT}`),
      },
      {
        id: DetailsTransportTypeEnum.CO_TRANSPORT,
        name: this.translate.instant(`sub-repatriation-details-transport-type.${DetailsTransportTypeEnum.CO_TRANSPORT}`),
      },
    ];
  }

  private composeDetailsWheelchairTypeDropdown() {
    this.wheelchairTypesDropdown = [
      {
        id: DetailsWheelchairTypeEnum.WCHR,
        name: this.translate.instant(`sub-repatriation-details-wheelchair-type.${DetailsWheelchairTypeEnum.WCHR}`),
      },
      {
        id: DetailsWheelchairTypeEnum.WCHS,
        name: this.translate.instant(`sub-repatriation-details-wheelchair-type.${DetailsWheelchairTypeEnum.WCHS}`),
      },
      {
        id: DetailsWheelchairTypeEnum.WCHC,
        name: this.translate.instant(`sub-repatriation-details-wheelchair-type.${DetailsWheelchairTypeEnum.WCHC}`),
      },
      {
        id: DetailsWheelchairTypeEnum.NOT_RELEVANT,
        name: this.translate.instant(`sub-repatriation-details-wheelchair-type.${DetailsWheelchairTypeEnum.NOT_RELEVANT}`),
      },
    ];
  }

  private composeDetailsOxygenSuppliedTypeDropdown() {
    this.oxygenSuppliedBySosRequestedTypesDropdown = [
      {
        id: DetailsOxygenSuppliedTypeEnum.INOGEN_ONE_G3,
        name: this.translate.instant(`sub-repatriation-details-oxygen-supplied-type.${DetailsOxygenSuppliedTypeEnum.INOGEN_ONE_G3}`),
      },
      {
        id: DetailsOxygenSuppliedTypeEnum.WENOLL,
        name: this.translate.instant(`sub-repatriation-details-oxygen-supplied-type.${DetailsOxygenSuppliedTypeEnum.WENOLL}`),
      },
    ];
    this.oxygenSuppliedBySosBookedTypesDropdown = [
      {
        id: DetailsOxygenSuppliedTypeEnum.NOT_ORGANIZED,
        name: this.translate.instant(`sub-repatriation-details-oxygen-supplied-type.${DetailsOxygenSuppliedTypeEnum.NOT_ORGANIZED}`),
      },
      {
        id: DetailsOxygenSuppliedTypeEnum.RESERVED,
        name: this.translate.instant(`sub-repatriation-details-oxygen-supplied-type.${DetailsOxygenSuppliedTypeEnum.RESERVED}`),
      },
      {
        id: DetailsOxygenSuppliedTypeEnum.RESERVED_WITH_OTHER_SUPPLIER,
        name: this.translate.instant(
          `sub-repatriation-details-oxygen-supplied-type.${DetailsOxygenSuppliedTypeEnum.RESERVED_WITH_OTHER_SUPPLIER}`
        ),
      },
      {
        id: DetailsOxygenSuppliedTypeEnum.BOOKED,
        name: this.translate.instant(`sub-repatriation-details-oxygen-supplied-type.${DetailsOxygenSuppliedTypeEnum.BOOKED}`),
      },
      {
        id: DetailsOxygenSuppliedTypeEnum.BOOKED_WITH_OTHER_SUPPLIER,
        name: this.translate.instant(
          `sub-repatriation-details-oxygen-supplied-type.${DetailsOxygenSuppliedTypeEnum.BOOKED_WITH_OTHER_SUPPLIER}`
        ),
      },
      {
        id: DetailsOxygenSuppliedTypeEnum.NOT_RELEVANT,
        name: this.translate.instant(`sub-repatriation-details-oxygen-supplied-type.${DetailsOxygenSuppliedTypeEnum.NOT_RELEVANT}`),
      },
    ];
  }

  private composeDetailsCommonListTypeDropdown(
    dropdown: DropdownDictionary[],
    types: DetailsCommonListTypeEnum[],
    excludeTypes: DetailsCommonListTypeEnum[] = []
  ) {
    for (const item of types.filter(type => (excludeTypes ? !excludeTypes.includes(type) : type))) {
      dropdown.push({
        id: item,
        name: this.translate.instant(`sub-repatriation-details-common-list-type.${item}`),
      });
    }
  }
}
