<div class="case-tabs">
  <div class="scroll-button left" *ngIf="tabPanelCapacityExceeded()" (click)="scrollToPrevTab()" [class.disabled]="!prevTabAvailable()">
    <img src="/assets/icons/ChevronLeftBlack.svg" alt="Navigate left icon">
  </div>
  <div
    class="case-tab-container"
    cdkDropList
    cdkDropListOrientation="horizontal"
    cdkDropListLockAxis="x"
    cdkScrollable
    (cdkDropListDropped)="drop($event)"
  >
    <div
      *ngFor="let tab of topTabsService.tabs"
      class="case-tab"
      cdkDrag
      topTab
      [routerLinkActiveOptions]="{exact: false}"
      [routerLinkActive]="['case-tab-selected']"
      [routerLink]="['/case', tab.caseId]"
      [ngStyle]="{ 'pointer-events': isCaseLoading ? 'none' : null }"
      [class.disabled]="isCaseLoading"
    >
      <div class="case-row">
        <div *ngIf="!tab.loading" class="image-column">
          <img class="customer-image" alt="Customer icon" [src]="tab.logo" *ngIf="tab.logo; else noProfileIcon">
        </div>
        <div class="text-column">
          <div class="case-description" *ngIf="!containsNoData(tab) || tab.loading; else newCaseDescription">
            <div class="case-text">
              <div class="case-number" *ngIf="!((isCaseValidated(tab.caseId) || isCaseConsented(tab.caseId)) && tab.caseNumber)">&nbsp;
                <div class="sensitive" *ngIf="tab.sensitiveType === getSensitiveType.SENSITIVE">SENSITIVE</div>
              </div>
              <div class="case-number" *ngIf="(isCaseValidated(tab.caseId) || isCaseConsented(tab.caseId)) && tab.caseNumber">{{ tab.caseNumber }}&nbsp;
                <div class="sensitive" *ngIf="tab.sensitiveType === getSensitiveType.SENSITIVE">SENSITIVE</div>
              </div>
              <div class="case-username">{{ tab.user }}</div>
              <div class="case-incident">{{ tab.cause }}</div>
              <div class="case-incident">{{ tab.country }}</div>
            </div>
          </div>

          <img
            class="button-close"
            [routerLinkActive]="['case-tab-selected']"
            src="/assets/icons/Close tab inactive.svg"
            (click)="openModal(tab, $event)"
            alt="close icon"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="scroll-button right" *ngIf="tabPanelCapacityExceeded()" (click)="scrollToNextTab()" [class.disabled]="!nextTabAvailable()">
    <img src="/assets/icons/ChevronRightBlack.svg" alt="Navigate right icon">
  </div>
</div>

<ng-template #noProfileIcon>
  <img class="customer-image no-profile-image" alt="No customer icon" src="/assets/icons/No%20customer%20profile%20icon.svg" />
</ng-template>

<ng-template #newCaseDescription>
  <div class="row case-description">
    <div class="col-12 case-text">
      <div class="new-case">New case</div>
    </div>
  </div>
</ng-template>
