<div class="topSpacer"></div>
<div class="sort-container">
  <div class="row">
    <div class="col-9"></div>
    <div class="col-1 sort-by">{{ 'case-summary-sort-by' | translate }}</div>
    <div class="col-2 sort-drop-down-area">
      <app-drop-down
        class="sort-drop-down"
        [items]="sortItems"
        [(selectedItemId)]="sortItem"
        (selectedItemIdChange)="sortChanged($event)"
      ></app-drop-down>
    </div>
  </div>
</div>
<div class="history-table-container" [class.zoomed]="settingsService.isZoomed()" *ngIf="caseHistoryEntries">
  <div class="background">
    <table class="history-table">
      <tbody class="table-body">
        <tr class="historyTabelRow" *ngFor="let entry of caseHistoryEntries" style="margin-top: 100px">
          <td class="historyTabelCell align-top" *ngIf="showSingleTableDataViews(entry.caseHistoryType) === true" colspan="3">
            <app-user-picture [imageURL]="getUserImage(entry.userId)" [active]="'true'" [imageClass]="'caseSummery'"> </app-user-picture>
            <span class="headline initials">
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.CASE_CREATE">
                <span *ngIf="isCaseUpdatedWithWeb(entry); else newCaseReported">
                  <span class="text-uppercase">{{ getUserInitials(entry.userId) }}</span> Case updated with
                  {{ webChannel | translate | lowercase }} task
                </span>
                <ng-template #newCaseReported>
                  <span class="text-uppercase">{{ getUserInitials(entry.userId) }}</span> Case reported by
                  {{ getChannelTypeName(case.channelType) }}
                </ng-template>
              </span>
                <span *ngIf="entry.caseHistoryType === CaseHistoryType.MEDICAL_ASSESSMENT_CREATE || entry.caseHistoryType === CaseHistoryType.MEDICAL_ASSESSMENT_MOVE">
                <ng-container
                  *ngIf="
                    getField(entry.dataFieldChanges, 'MedicalType').newValue === MedicalNoteTypeEnum.ADMISSION ||
                    getField(entry.dataFieldChanges, 'MedicalType').newValue === MedicalNoteTypeEnum.COMMENT
                  "
                >
                  {{ getUserInitials(entry.userId) }} {{ 'case-history-list-added' | translate }} #{{
                    getField(entry.dataFieldChanges, 'MedicalNumber').newValue
                  }}
                  {{ 'medical-comment-note' | translate }}
                </ng-container>
                <ng-container
                  *ngIf="
                    getField(entry.dataFieldChanges, 'MedicalType').newValue === AssessmentTypeEnum.LIGHT ||
                    getField(entry.dataFieldChanges, 'MedicalType').newValue === AssessmentTypeEnum.REGULAR ||
                    getField(entry.dataFieldChanges, 'MedicalType').newValue === AssessmentTypeEnum.CLAIMS
                  "
                >
                  {{ getUserInitials(entry.userId) }} {{ 'case-history-list-added' | translate }} #{{
                  getField(entry.dataFieldChanges, 'MedicalNumber').newValue
                  }}
                  <span *ngIf="getField(entry.dataFieldChanges, 'MedicalType').newValue === AssessmentTypeEnum.LIGHT">{{
                    'medical-new-regular-assessment-button-light' | translate | uppercase
                  }}</span>
                  <span *ngIf="getField(entry.dataFieldChanges, 'MedicalType').newValue === AssessmentTypeEnum.CLAIMS">{{
                    'medical-new-regular-assessment-button-claims' | translate | uppercase
                  }}</span>
                  <span *ngIf="getField(entry.dataFieldChanges, 'MedicalType').newValue === AssessmentTypeEnum.REGULAR"
                    >{{ 'medical-new-regular-assessment-button-regular' | translate | uppercase }}
                    <span *ngIf="getField(entry.dataFieldChanges, 'Repatriation').newValue !== 'null'">
                      <span *ngIf="getField(entry.dataFieldChanges, 'Repatriation').newValue === 'true'">
                        {{ 'case-history-list-with-repatriation-instructions' | translate }}
                      </span>
                      <span *ngIf="getField(entry.dataFieldChanges, 'Repatriation').newValue === 'false'">
                        {{ 'case-history-list-added-with-repatriation-prognosis' | translate }}
                      </span>
                    </span>
                  </span>
                </ng-container>
                <ng-container *ngIf="getField(entry.dataFieldChanges, 'MedicalType').newValue === 'MEDICAL_REPORT_SUPPLIER_PORTAL'">

                  {{ getUserInitials(entry.userId) }} {{ (entry.caseHistoryType === CaseHistoryType.MEDICAL_ASSESSMENT_MOVE ? 'case-history-list-moved' : 'case-history-list-added') | translate }} #{{
                  getField(entry.dataFieldChanges, 'MedicalNumber').newValue
                  }}
                  {{ 'supplier-portal-medical-report' | translate | uppercase }}
                </ng-container>
                <ng-container *ngIf="getField(entry.dataFieldChanges, 'Case Number')?.oldValue">
                  (
                  Moved from
                  {{getField(entry.dataFieldChanges, 'Case Number').oldValue}} to {{getField(entry.dataFieldChanges, 'Case Number').newValue}}
                  )
                </ng-container>
              </span>
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.EXTERNAL_AUTO_CREATE_CASE">
                <span>
                  <span class="text-uppercase">{{ getUserInitials(entry.userId) }}</span>
                  {{ getAutoCreationResult(entry.propertyData) }}
                </span>
              </span>
            </span>
            <div class="elementDateTime">{{ entry.actionTime | date: 'd MMM y - HH:mm' }}</div>
          </td>
          <td class="historyTabelCell align-top" *ngIf="showSingleTableDataViews(entry.caseHistoryType) === false">
            <app-user-picture [imageURL]="getUserImage(entry.userId)" [active]="'true'" [imageClass]="'caseSummery'"> </app-user-picture>
            <span class="headline initials">
              <span *ngIf="entry.caseHistoryType !== CaseHistoryType.SMS_UPDATE" class="text-uppercase">{{
                getUserInitials(entry.userId)
              }}</span>
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.CASE_LINK && entry.dataFieldChanges[3].newValue === 'FALSE'">
                created a case link
              </span>
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.CASE_LINK && entry.dataFieldChanges[3].newValue === 'TRUE'">
                deleted a case link
              </span>
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.PHONE_NOTE_CREATE">
                created a {{ entry.caseObjectType | objectTypeTranslate }}
                <span *ngIf="entry.caseSubObjectType"> of type {{ entry.caseSubObjectType | subObjectTypeTranslate }}</span></span
              >
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.PHONE_NOTE_COPY">
                copied a {{ entry.caseObjectType | objectTypeTranslate }}
                <span *ngIf="entry.caseSubObjectType"> of type {{ entry.caseSubObjectType | subObjectTypeTranslate }}</span></span
              >
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.CASE_NUMBER_CREATE"> created case number </span>
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.PHONE_NOTE_EDIT">
                edited a {{ entry.caseObjectType | objectTypeTranslate
                }}<span *ngIf="entry.caseSubObjectType"> of type {{ entry.caseSubObjectType | subObjectTypeTranslate }}</span></span
              >
              <span
                *ngIf="entry.caseHistoryType === CaseHistoryType.EMAIL_RECEIVE || entry.caseHistoryType === CaseHistoryType.FAX_RECEIVE"
              >
                received a new {{ entry.caseSubObjectType | subObjectTypeTranslate }}.</span
              >
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.EMAIL_UPDATE || entry.caseHistoryType === CaseHistoryType.FAX_UPDATE">
                updated an {{ entry.caseSubObjectType | subObjectTypeTranslate }}: Id: {{entry.entityId}}.</span
              >
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.EMAIL || entry.caseHistoryType === CaseHistoryType.FAX">
                sent a new {{ entry.caseSubObjectType | subObjectTypeTranslate }}: Id: {{entry.entityId}}.</span
              >
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.CASE_NOTE_CREATE">
                created a {{ entry.caseObjectType | objectTypeTranslate
                }}<span *ngIf="entry.caseSubObjectType"> of type {{ entry.caseSubObjectType | subObjectTypeTranslate }}</span></span
              >
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.CASE_NOTE_COPY">
                copied a {{ entry.caseObjectType | objectTypeTranslate
                }}<span *ngIf="entry.caseSubObjectType"> of type {{ entry.caseSubObjectType | subObjectTypeTranslate }}</span></span
              >
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.END_USER_SATISFACTION_SURVEY"> Case chosen for {{'case-summary-types-END_USER_SATISFACTION_SURVEY' | translate}} survey</span>
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.END_USER_SATISFACTION_SURVEY_DUPLICATE"> End-user satisfaction survey sent from another case</span>
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.CLAIM_END_USER_SATISFACTION_SURVEY"> Case chosen for {{'case-summary-types-END_USER_SATISFACTION_SURVEY' | translate}} survey</span>
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.CLAIM_END_USER_SATISFACTION_SURVEY_DUPLICATE"> End-user satisfaction survey sent from another case</span>
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.MPA_END_USER_SATISFACTION_SURVEY"> Case chosen for {{'case-summary-types-END_USER_SATISFACTION_SURVEY' | translate}} survey</span>
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.MPA_END_USER_SATISFACTION_SURVEY_DUPLICATE"> End-user satisfaction survey sent from another case</span>
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.REGRES">{{'case-summary-types-REGRES' | translate}}</span>
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.SMS && entry.direction === CommunicationDirection.OUT_BOUND">
                {{ 'case-history-list-sent-an' | translate }} {{ entry.caseSubObjectType | subObjectTypeTranslate }}
                {{ 'case-history-list-to' | translate }} {{ entry.to }}</span
              >
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.SMS && entry.direction === CommunicationDirection.IN_BOUND">
                {{ 'case-history-list-received-an' | translate }} {{ entry.caseSubObjectType | subObjectTypeTranslate }}
                {{ 'case-history-list-received-from' | translate }} {{ entry.from }}</span
              >
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.SMS_UPDATE"
                >{{ 'case-history-list-sms-recieved-status-to' | translate }} {{ entry.to }}</span
              >
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.SLA_EVENT"
              >{{ 'case-history-list-sla-event' | translate }}</span
              >
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.CASE_NOTE_EDIT">
                edited a {{ entry.caseObjectType | objectTypeTranslate
                }}<span *ngIf="entry.caseSubObjectType"> of type {{ entry.caseSubObjectType | subObjectTypeTranslate }}</span></span
              >
              <span
                *ngIf="
                  entry.caseHistoryType === CaseHistoryType.CUSTOMER_NOTE_CREATE ||
                  entry.caseHistoryType === CaseHistoryType.SUPPLIER_NOTE_CREATE
                "
              >
                created a {{ entry.caseObjectType | objectTypeTranslate
                }}<span *ngIf="entry.caseSubObjectType"> of type {{ entry.caseSubObjectType | subObjectTypeTranslate }}</span></span
              >
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.NATIONAL_ID_CHECK">
                performed SSN lookup in {{ entry.stakeholderType }}</span
              >
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.CASE_EVENT"
              >{{ 'case-history-list-case-event-'+entry.caseSubObjectType | translate }}</span
              >
              <span
                *ngIf="
                  entry.caseHistoryType === CaseHistoryType.DATA_FIELD_INPUT ||
                  entry.caseHistoryType === CaseHistoryType.CUSTOMER_PRODUCT_ADDED
                "
              >
                added a new input to {{ entry.caseObjectType | objectTypeTranslate
                }}<span *ngIf="showCaseSubObject(entry.caseObjectType, entry.caseSubObjectType)">
                  of type
                  {{ entry.caseSubObjectType | subObjectTypeTranslate }}
                  <span *ngIf="entry.caseSubObjectType === CaseSubObjectType.contact_person"> with id: {{ entry.entityId }}</span>
                </span>
              </span>
              <span
                *ngIf="
                  entry.caseHistoryType === CaseHistoryType.DATA_FIELD_UPDATE ||
                  entry.caseHistoryType === CaseHistoryType.DOCUMENT_MOVE ||
                  entry.caseHistoryType === CaseHistoryType.CUSTOMER_PRODUCT_UPDATED ||
                  entry.caseHistoryType === CaseHistoryType.STAKEHOLDER_MERGE ||
                  entry.caseHistoryType === CaseHistoryType.STAKEHOLDER_UNMERGE ||
                  entry.caseHistoryType === CaseHistoryType.SENSITIVE ||
                  entry.caseHistoryType === CaseHistoryType.GDPR_ITEM_EDIT
                "
              >
                updated an input to {{ entry.caseObjectType | objectTypeTranslate }}
                <span *ngIf="showCaseSubObject(entry.caseObjectType, entry.caseSubObjectType)">
                  of type
                  {{ entry.caseSubObjectType | subObjectTypeTranslate }}
                  <span *ngIf="entry.caseSubObjectType === CaseSubObjectType.contact_person"> with id: {{ entry.entityId }}</span>
                </span>
              </span>
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.ITEM_DELETION || entry.caseHistoryType === CaseHistoryType.GDPR_ITEM_DELETION">
                <span *ngIf="entry.caseObjectType === 'MEDICAL_ASSESSMENT' || entry.caseObjectType === 'MEDICAL_PRE_ASSESSMENT'">
                  <ng-container
                  *ngIf="
                    getDeletedField(entry.deletedFieldValues, 'MedicalType').deletedValue === MedicalNoteTypeEnum.ADMISSION ||
                    getDeletedField(entry.deletedFieldValues, 'MedicalType').deletedValue === MedicalNoteTypeEnum.COMMENT
                  ">
                  {{ "case-summary-deleted-medical-note-1" | translate: {notetype: getDeletedField(entry.deletedFieldValues, 'MedicalType').deletedValue | lowercase, medicalNumber: getDeletedField(entry.deletedFieldValues, 'MedicalNumber').deletedValue  }  }}
                  </ng-container>
                  <ng-container
                  *ngIf="
                    getDeletedField(entry.deletedFieldValues, 'MedicalType').deletedValue === MedicalNoteTypeEnum.PRE_ASSESSMENT_COMMENT
                  ">
                  {{ "case-summary-deleted-medical-note-1" | translate: {notetype: getDeletedFieldValueTranslated(entry.deletedFieldValues, 'MedicalType'), medicalNumber: getDeletedField(entry.deletedFieldValues, 'MedicalNumber').deletedValue  }  }}
                  </ng-container>
                  <ng-container
                    *ngIf="
                      getDeletedField(entry.deletedFieldValues, 'MedicalType').deletedValue === AssessmentTypeEnum.LIGHT ||
                      getDeletedField(entry.deletedFieldValues, 'MedicalType').deletedValue === AssessmentTypeEnum.REGULAR ||
                      getDeletedField(entry.deletedFieldValues, 'MedicalType').deletedValue === AssessmentTypeEnum.CLAIMS ||
                      getDeletedField(entry.deletedFieldValues, 'MedicalType').deletedValue === AssessmentTypeEnum.PREASSESSMENT ||
                      getDeletedField(entry.deletedFieldValues, 'MedicalType').deletedValue === AssessmentTypeEnum.PREASSESSMENT_TRAVEL ||
                      getDeletedField(entry.deletedFieldValues, 'MedicalType').deletedValue === AssessmentTypeEnum.PREASSESSMENT_CANCELLATION
                    ">
                    deleted #{{ getDeletedField(entry.deletedFieldValues, 'MedicalNumber').deletedValue }}
                    <span *ngIf="getDeletedField(entry.deletedFieldValues, 'MedicalType').deletedValue === AssessmentTypeEnum.LIGHT">{{
                      'medical-new-regular-assessment-button-light' | translate | uppercase
                    }}</span>
                    <span *ngIf="getDeletedField(entry.deletedFieldValues, 'MedicalType').deletedValue === AssessmentTypeEnum.CLAIMS">{{
                      'medical-new-regular-assessment-button-claims' | translate | uppercase
                    }}</span>
                    <span *ngIf="getDeletedField(entry.deletedFieldValues, 'MedicalType').deletedValue === AssessmentTypeEnum.REGULAR"
                      >{{ 'medical-new-regular-assessment-button-regular' | translate | uppercase }}
                      <span *ngIf="getDeletedField(entry.deletedFieldValues, 'Repatriation').deletedValue !== 'null'">
                        <span *ngIf="getDeletedField(entry.deletedFieldValues, 'Repatriation').deletedValue === 'true'">
                          {{ 'case-history-list-with-repatriation-instructions' | translate }}
                        </span>
                        <span *ngIf="getDeletedField(entry.deletedFieldValues, 'Repatriation').deletedValue === 'false'">
                          {{ 'case-history-list-added-with-repatriation-prognosis' | translate }}
                        </span>
                      </span>
                    </span>
                    <span *ngIf="[AssessmentTypeEnum.PREASSESSMENT,AssessmentTypeEnum.PREASSESSMENT_TRAVEL,AssessmentTypeEnum.PREASSESSMENT_CANCELLATION].includes(getDeletedField(entry.deletedFieldValues, 'MedicalType').deletedValue)">{{
                      'medical-new-regular-assessment-button-pre-assessment' | translate | uppercase }}
                    </span>
                  </ng-container>
                  <ng-container *ngIf="getDeletedField(entry.deletedFieldValues, 'MedicalType').deletedValue === 'MEDICAL_REPORT_SUPPLIER_PORTAL'">
                    deleted #{{ getDeletedField(entry.deletedFieldValues, 'MedicalNumber').deletedValue }} {{ 'supplier-portal-medical-report' | translate | uppercase }}
                  </ng-container>
                </span>
                <span *ngIf="entry.caseObjectType !== 'MEDICAL_ASSESSMENT' && entry.caseObjectType !== 'MEDICAL_PRE_ASSESSMENT'">
                  deleted a {{ entry.caseObjectType | objectTypeTranslate }}
                  <span *ngIf="entry.caseSubObjectType">
                    of type {{ entry.caseSubObjectType | subObjectTypeTranslate }}
                    <span *ngIf="entry.caseSubObjectType === CaseSubObjectType.contact_person"> with id: {{ entry.deletedEntityId }}</span>
                  </span>
                </span>
              </span>
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.SERVICE_ORDER_CREATE">
                added a {{ entry.caseObjectType | objectTypeTranslate }} of type
                {{ entry.caseSubObjectType | subObjectTypeTranslate }}</span
              >
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.SERVICE_ORDER_EDIT">
                edited a {{ entry.caseObjectType | objectTypeTranslate }} of type
                {{ entry.caseSubObjectType | subObjectTypeTranslate }}</span
              >
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.TASK_CREATE">
                created a {{ entry.caseObjectType | objectTypeTranslate }} with ID {{ entry.entityId }} of type
                {{ entry.caseSubObjectType | subObjectTypeTranslate }}</span
              >
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.RULE_ENGINE"> SYS activated a business rule </span>
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.TASK_UPDATE">
                updated a {{ entry.caseObjectType | objectTypeTranslate }} with ID {{ entry.entityId }} of type
                {{ entry.caseSubObjectType | subObjectTypeTranslate }}</span
              >
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.TASK_ADD">
                added a {{ entry.caseObjectType | objectTypeTranslate }} with ID {{ entry.entityId }} of type
                {{ entry.caseSubObjectType | subObjectTypeTranslate }}</span
              >
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.POLICY_LOOKUP">
                performed {{ entry.caseObjectType | objectTypeTranslate }} lookup in
                {{ entry.caseSubObjectType | subObjectTypeTranslate }}</span
              >
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.POLICY_SELECTED">
                {{ 'case-history-list-policy-selected' | translate }}
                {{ getCustomerProfileName(entry.propertyData).toUpperCase() }}</span
              >
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.CUSTOMER_LOOKUP">
                {{ 'case-history-list-profile-lookup' | translate }}</span
              >
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.MEDICAL_PRE_ASSESSMENT_LOOKUP">
                {{ 'case-history-list-medical-pre-assessment-lookup-as400' | translate }}</span
              >
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.MEDICAL_PRE_ASSESSMENT_LOOKUP_SECCA">
                {{ 'case-history-list-medical-pre-assessment-lookup-secca' | translate }}</span
              >
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.DIGITAL_CASEFLOW">
                {{ 'case-history-list-digital-caseflow' | translate }}
              </span>
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.CUSTOMER_SELECTED">
                {{ 'case-history-list-profile-selected' | translate }}</span
              >
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.POLICY_REMOVED">
                {{ 'case-history-list-policy-removed' | translate }}</span
              >
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.SUPPLIER_LOOKUP">
                {{ 'case-history-list-supplier-lookup' | translate }}</span
              >
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.END_USER_LOOKUP">
                {{ 'case-history-list-end-user-lookup' | translate }}</span
              >
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.SCREENING_TOOL_CHANGE">
                {{ 'case-history-list-updated' | translate }} {{ 'case-history-list-screening-tool' | translate }}
              </span>
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.DOCUMENT_CREATE">
                {{ 'case-summary-list-document_created' | translate }} {{ entry.caseSubObjectType | subObjectTypeTranslate }}
              </span>
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.CONSENT">
                updated consent to {{ newConsentType(entry) | translate }}
              </span>
              <span
                *ngIf="entry.caseHistoryType === CaseHistoryType.HANDLER_CHANGE || entry.caseHistoryType === CaseHistoryType.STATUS_CHANGE"
              >
                {{ 'case-history-list-updated-the' | translate }} {{ entry.caseSubObjectType | subObjectTypeTranslate }}
              </span>
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.MEDICAL_PRE_ASSESSMENT_CREATE">
                <ng-container
                  *ngIf="getField(entry.dataFieldChanges, 'MedicalType')?.newValue === MedicalNoteTypeEnum.PRE_ASSESSMENT_COMMENT"
                >
                  {{ getUserInitials(entry.userId) }} {{ 'case-history-list-added' | translate }} #{{
                    getField(entry.dataFieldChanges, 'MedicalNumber').newValue
                  }}
                  {{ 'medical-pre-assessment-comment-note' | translate }}
                </ng-container>
                <ng-container
                  *ngIf="[AssessmentTypeEnum.PREASSESSMENT, AssessmentTypeEnum.PREASSESSMENT_TRAVEL,AssessmentTypeEnum.PREASSESSMENT_CANCELLATION].includes(getField(entry.dataFieldChanges, 'MedicalType')?.newValue)"
                >
                  {{ getUserInitials(entry.userId) }} {{ 'case-history-list-added' | translate }} #{{
                    getField(entry.dataFieldChanges, 'MedicalNumber')?.newValue
                  }}
                  {{ 'case-history-list-medical-pre-assessment' | translate }}
                </ng-container>
              </span>
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.COORDINATION_CASE_LINK">
                <span *ngIf="entry.propertyData.value.linkedCaseNumber">
                  {{'case-summary-list-case-created-linked-to-coordination-case' | translate}}
                </span>
                <span *ngIf="entry.propertyData.value.deletedLinkTo">
                  {{'case-summary-list-case-deleted-linked-to-coordination-case' | translate}}
                </span>
              </span>
            </span>
            <div class="elementDateTime">{{ entry.actionTime | date: 'd MMM y - HH:mm' }}</div>
          </td>
          <td class="historyTabelCell align-top" *ngIf="showSingleTableDataViews(entry.caseHistoryType) === false">
            <div class="headline">
              <span *ngIf="(showDataFieldWithOldValues(entry.caseHistoryType) === true || showItemDeletion(entry.caseHistoryType) === true) && entry.caseObjectType !== 'MEDICAL_ASSESSMENT'">
                {{ 'case-history-list-original-value' | translate }}
              </span>
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.NATIONAL_ID_CHECK">
                {{ 'case-history-list-input' | translate }}
              </span>
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.CASE_LINK">
                {{ 'case-history-list-original-value' | translate }}
              </span>
              <span *ngIf="entry.caseHistoryType === CaseHistoryType.COORDINATION_CASE_LINK">
                {{ 'case-history-list-original-value' | translate }}
              </span>
              <span *ngIf="showSearchResult(entry.caseHistoryType) === true">
                {{ 'case-history-list-search-criteria' | translate }}
              </span>

            </div>
            <div *ngIf="showDataFieldWithOldValues(entry.caseHistoryType) === true" class="icon-phone">
              <div *ngFor="let dataFieldChangeDto of entry.dataFieldChanges">
                <div class="entry-text-container">
                  <span class="historyTabelCell-label" *ngIf="entry.caseSubObjectType === 'SENSITIVE' && dataFieldChangeDto.oldValue !== null" > {{ dataFieldChangeDto.field | translate }}:</span>
                  <span class="historyTabelCell-label" *ngIf="entry.caseSubObjectType !== 'SENSITIVE'" > {{ dataFieldChangeDto.field | translate }}:</span>
                  <span
                    *ngIf="entry.caseHistoryType !== CaseHistoryType.SCREENING_TOOL_CHANGE && entry.caseObjectType !== 'PLANNED_TRAVEL'"
                    class="entry-text-container historyTabelCell-value"                  >
                    {{ dataFieldChangeDto.oldValue | translate }}
                  </span>
                  <span *ngIf="entry.caseHistoryType === CaseHistoryType.SCREENING_TOOL_CHANGE" class="historyTabelCell-value">
                    <span *ngIf="dataFieldChangeDto.field === 'screening-tool-case-address-state'">
                      {{ dataFieldChangeDto.oldValue }}
                    </span>
                    <span *ngIf="dataFieldChangeDto.field !== 'screening-tool-case-address-state'">
                    {{ screeningStateDisplayValueAdapter.getPropertyFromValue(dataFieldChangeDto.oldValue) | translate }}
                    </span>
                  </span>
                  <span *ngIf="entry.caseObjectType === 'PLANNED_TRAVEL'" class="historyTabelCell-value">
                    <span *ngIf="entry.dataFieldChanges[0].field === 'Purpose of travel'">
                      {{ showPurposeOfTravelText(dataFieldChangeDto.oldValue) }}
                    </span>
                    <span *ngIf="entry.dataFieldChanges[0].field !== 'Purpose of travel'">
                      {{ dataFieldChangeDto.oldValue | translate }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div *ngIf="entry.caseHistoryType === CaseHistoryType.CASE_LINK">
              <span class="historyTabelCell-label">{{ entry.dataFieldChanges[0].field | translate }} </span>
              <span class="historyTabelCell-value">{{ entry.dataFieldChanges[0].newValue }}</span>
            </div>
            <div *ngIf="entry.caseHistoryType === CaseHistoryType.COORDINATION_CASE_LINK">
              <span *ngIf="entry.propertyData.value.linkedCaseNumber">
                <span class="historyTabelCell-label">{{ 'case-summary-list-case-existing-coordination-case-links' | translate }} </span>
                <span class="historyTabelCell-value">{{ entry.propertyData.value.oldCaseList }} </span>
              </span>

              <span *ngIf="entry.propertyData.value.deletedLinkTo">
                <span class="historyTabelCell-label">{{ 'case-summary-list-case-coordination-case-link-deleted' | translate }} </span>
                <span class="historyTabelCell-value">{{ entry.propertyData.value.deletedLinkTo }} </span>
              </span>
            </div>


            <div *ngIf="entry.caseHistoryType === CaseHistoryType.FAX_UPDATE || entry.caseHistoryType === CaseHistoryType.EMAIL_UPDATE">
              <div>
                <div *ngIf="entry.updatedInternalSummary">
                  <span class="historyTabelCell-label">{{ 'case-history-list-message-internal-summary' | translate }}:</span>
                  <span class="historyTabelCell-value"> {{ entry.internalSummary }}</span>
                </div>
                <div *ngIf="entry.newStatus">
                  <span class="historyTabelCell-label">{{ 'case-history-list-message-status' | translate }}:</span>
                  <span class="historyTabelCell-value"> {{ entry.oldStatus }}</span>
                </div>
                <div *ngIf="entry.updatedStakeholderSenderId">
                  <span class="historyTabelCell-label">{{ 'case-history-list-message-from' | translate }}:</span>
                  <span class="historyTabelCell-value"> {{getStakeholderById(entry.stakeholderSenderId)}}</span>
                </div>
              </div>
            </div>
            <div *ngIf="entry.caseHistoryType === CaseHistoryType.SMS_UPDATE">
              {{ 'case-history-list-sms' | translate }} {{ entry.oldStatus }}
            </div>
            <div *ngIf="showSearchResult(entry.caseHistoryType) === true" class="icon-phone">
              <div *ngFor="let paramDto of entry.searchResultParams">
                <span *ngIf="paramDto.ioDirection === IODirection.input">
                  <span class="historyTabelCell-label">{{ paramDto.searchResultParam }}:</span>
                  <span class="historyTabelCell-value"> {{ paramDto.searchResultParamValue }}</span>
                </span>
              </div>
            </div>
            <div *ngIf="showItemDeletion(entry.caseHistoryType) === true" class="icon-phone">
              <span *ngIf="entry.caseObjectType === 'MEDICAL_ASSESSMENT'">
                <ng-container
                  *ngIf="
                    entry.deletedFieldValues[0].deletedValue === MedicalNoteTypeEnum.ADMISSION ||
                    entry.deletedFieldValues[0].deletedValue === MedicalNoteTypeEnum.COMMENT ||
                    entry.deletedFieldValues[0].deletedValue === 'MEDICAL_REPORT_SUPPLIER_PORTAL'
                  ">
                    Cause: {{ entry.deletedFieldValues[1].deletedValue }}
                </ng-container>
                <ng-container
                *ngIf="
                  entry.deletedFieldValues[0].deletedValue === AssessmentTypeEnum.LIGHT ||
                  entry.deletedFieldValues[0].deletedValue === AssessmentTypeEnum.REGULAR ||
                  entry.deletedFieldValues[0].deletedValue === AssessmentTypeEnum.CLAIMS
                ">
                Cause: {{ entry.deletedFieldValues[2].deletedValue }}
                </ng-container>
              </span>
              <span *ngIf="entry.caseObjectType !== 'MEDICAL_ASSESSMENT'">

                <div *ngFor="let fieldDto of entry.deletedFieldValues">
                  <span class="historyTabelCell-label">{{ fieldDto.deletedField | translate }}:</span>
                  <span class="historyTabelCell-value"> {{ fieldDto.deletedValue | translate }}</span>
                </div>
              </span>
            </div>
            <div *ngIf="entry.caseHistoryType === CaseHistoryType.POLICY_SELECTED">
              <div class="headline">
                <span>
                  {{ 'case-history-list-policy-match-on' | translate }}
                </span>
              </div>
              <div *ngFor="let match of getPolicyMatchCriteria(entry.propertyData)">
                <span class="historyTabelCell-label">{{ 'policy-lookup-' + match.parameter | translate }}</span
                >:
                <span class="historyTabelCell-value">
                  {{
                    match.quality === matchQuality.SUCCESS
                      ? ('case-history-list-policy-match-true' | translate)
                      : ('case-history-list-policy-match-false' | translate)
                  }}</span
                >
              </div>
            </div>
            <div *ngIf="entry.caseHistoryType === CaseHistoryType.CUSTOMER_SELECTED">
              <div class="headline">
                <span>
                  {{ 'case-history-list-profile-match-on' | translate }}
                </span>
              </div>
              <div *ngFor="let key of getCustomerProfileMatchCriteria(entry.propertyData)">
                <span class="historyTabelCell-label">{{ key }}</span
                >:
                <span class="historyTabelCell-value"> {{ entry.propertyData.value[key] }}</span>
              </div>
            </div>
            <div *ngIf="entry.caseHistoryType === CaseHistoryType.POLICY_REMOVED">
              <div class="headline">
                <span>
                  {{ 'case-history-list-original-value' | translate }}
                </span>
              </div>
              <div>
                <span class="historyTabelCell-label">{{ 'case-history-list-policy-customer-profile' | translate }}:</span>
                <span class="historyTabelCell-value"> {{ getCustomerProfileName(entry.propertyData) }}</span>
              </div>
              <div>
                <span class="historyTabelCell-label">{{ 'case-history-list-policy-number' | translate }}:</span>
                <span class="historyTabelCell-value"> {{ getPolicyNumber(entry.propertyData) }}</span>
              </div>
              <div>
                <span class="historyTabelCell-label">{{ 'case-history-list-policy-customer-product' | translate }}:</span>
                <span class="historyTabelCell-value"> {{ getCustomerProductName(entry.propertyData) }}</span>
              </div>
              <div>
                <span class="historyTabelCell-label">{{ 'case-history-list-policy-level' | translate }}:</span>
                <span class="historyTabelCell-value"> {{ getInsuranceLevel(entry.propertyData) }}</span>
              </div>
              <div>
                <span class="historyTabelCell-label">{{ 'case-history-list-policy-coverage-days' | translate }}:</span>
                <span class="historyTabelCell-value"> {{ getInsuranceCoverageDays(entry.propertyData) }}</span>
              </div>
              <div>
                <span class="historyTabelCell-label">{{ 'case-history-list-policy-coverage-weeks' | translate }}:</span>
                <span class="historyTabelCell-value"> {{ getInsuranceCoverageWeeks(entry.propertyData) }}</span>
              </div>
              <div>
                <span class="historyTabelCell-label">{{ 'case-history-list-policy-geo-area' | translate }}:</span>
                <span class="historyTabelCell-value"> {{ getInsuranceCoverageArea(entry.propertyData) }}</span>
              </div>
              <div>
                <span class="historyTabelCell-label">{{ 'case-history-list-policy-version' | translate }}:</span>
                <span class="historyTabelCell-value"> {{ getVersion(entry.propertyData) }}</span>
              </div>
            </div>
            <div *ngIf="entry.caseHistoryType === CaseHistoryType.MEDICAL_PRE_ASSESSMENT_CREATE">
              <div class="headline">
                <span>
                  {{ 'case-history-list-original-value' | translate }}
                </span>
              </div>
              <div *ngFor="let dataFieldChangeDto of entry.dataFieldChanges">
                <span class="historyTabelCell-label" *ngIf="dataFieldChangeDto?.oldValue" > {{ 'case-history-list-medical-pre-assessment-' + dataFieldChangeDto.field | translate }}:&nbsp;</span>
                <span class="historyTabelCell-label">{{ dataFieldChangeDto?.oldValue | translate }}</span>
              </div>
            </div>
          </td>
          <td class="historyTabelCell align-top" *ngIf="showSingleTableDataViews(entry.caseHistoryType) === false">
            <div class="headline">
              <span
                *ngIf="
                  showDataFieldWithNewValues(entry.caseHistoryType) === true ||
                  entry.caseHistoryType === CaseHistoryType.FAX_UPDATE ||
                  entry.caseHistoryType === CaseHistoryType.EMAIL_UPDATE ||
                  entry.caseHistoryType === CaseHistoryType.STAKEHOLDER_MERGE ||
                  entry.caseHistoryType === CaseHistoryType.STAKEHOLDER_UNMERGE
                "
              >
                {{ 'case-history-list-new-value' | translate }}
              </span>
              <span *ngIf="showSearchResult(entry.caseHistoryType) === true">
                <span> {{ 'case-history-list-result' | translate }}:</span>
                <span class="historyTabelCell-value">{{ entry.searchResult }}</span>
              </span>
            </div>
            <div *ngIf="entry.caseHistoryType === CaseHistoryType.CASE_LINK">
              <div class="headline">{{ 'case-history-list-new-value' | translate }}</div>
              <div>
                <span class="historyTabelCell-label">{{ entry.dataFieldChanges[1].field | translate }}</span>
                <span class="historyTabelCell-value"> {{ entry.dataFieldChanges[1].newValue }}</span>
              </div>
              <div *ngIf="entry.dataFieldChanges[2].newValue != ''">
                <span class="historyTabelCell-label">{{ entry.dataFieldChanges[2].field | translate }}</span>
                <span class="historyTabelCell-value"> {{ entry.dataFieldChanges[2].newValue }}</span>
              </div>
            </div>
            <div *ngIf="entry.caseHistoryType === CaseHistoryType.DIGITAL_CASEFLOW">
              <div class="headline">{{ 'case-history-list-new-value' | translate }}</div>
              <div>
                <span class="historyTabelCell-label">{{ 'digital-caseflow-flow-log-id' | translate }}</span>
                <span class="historyTabelCell-value"> {{ entry.digitalCaseflowLogId }}</span>
              </div>
              <div>
                <span class="historyTabelCell-label">{{ 'digital-caseflow-name' | translate }}</span>
                <span class="historyTabelCell-value"> {{ entry.flowName }}</span>
              </div>
              <div>
                <span class="historyTabelCell-label">{{ 'digital-caseflow-result' | translate }}</span>
                <span class="historyTabelCell-value"> {{ (entry.flowResult ? 'status-succeeded' : 'status-failed') | translate }}</span>
              </div>
            </div>
            <div *ngIf="entry.caseHistoryType === CaseHistoryType.COORDINATION_CASE_LINK">
              <div class="headline">{{ 'case-history-list-new-value' | translate }}</div>
              <div>
                <span *ngIf="entry.propertyData.value.linkedCaseNumber">
                  <span class="historyTabelCell-label">{{ 'case-summary-list-case-coordination-case-link-created' | translate }} </span>
                  <span class="historyTabelCell-value">{{ entry.propertyData.value.linkedCaseNumber }} </span>
                </span>
                <span *ngIf="entry.propertyData.value.deletedLinkTo">
                  <span class="historyTabelCell-label">{{ 'case-summary-list-case-remaining-coordination-case-links' | translate }} </span>
                  <span class="historyTabelCell-value">{{ entry.propertyData.value.newCaseList }} </span>
                </span>
              </div>
            </div>


            <div *ngIf="entry.caseHistoryType === CaseHistoryType.SMS">
              <span *ngIf="entry.direction === CommunicationDirection.OUT_BOUND">
                <span class="historyTabelCell-label">{{ 'case-history-list-number' | translate }}:</span>
                <span class="historyTabelCell-value"> {{ entry.to }}</span>
                <div>
                  <span class="historyTabelCell-label">{{ 'case-history-list-calltype' | translate }}:</span>
                  <span class="historyTabelCell-value"> {{ 'case-history-list-outgoing' | translate }}</span>
                </div>
              </span>
              <span *ngIf="entry.direction === CommunicationDirection.IN_BOUND">
                <span class="historyTabelCell-label">{{ 'case-history-list-number' | translate }}:</span>
                <span class="historyTabelCell-value"> {{ entry.from }}</span>
                <div>
                  <span class="historyTabelCell-label">{{ 'case-history-list-calltype' | translate }}:</span>
                  <span class="historyTabelCell-value"> {{ 'case-history-list-incoming' | translate }}</span>
                </div>
              </span>
              <div>
                <span class="historyTabelCell-label">{{ 'case-history-list-communicationtype' | translate }}:</span>
                <span class="historyTabelCell-value"> {{ entry.caseSubObjectType | subObjectTypeTranslate }}</span>
              </div>
              <div>
                <span class="historyTabelCell-label">{{ 'case-history-list-id' | translate }}:</span>
                <span class="historyTabelCell-value"> {{ entry.id }}</span>
              </div>
              <div>
                <span class="historyTabelCell-label">{{ 'case-history-list-text' | translate }}:</span>
                <span class="historyTabelCell-value"> {{ entry.content }}</span>
              </div>
              <div>
                <span class="historyTabelCell-label">{{ 'case-history-list-internal-summary' | translate }}:</span>
                <span class="historyTabelCell-value"> {{ entry.internalSummary }}</span>
              </div>
            </div>
            <div *ngIf="entry.caseHistoryType === CaseHistoryType.SMS_UPDATE">
              <span class="historyTabelCell-label">{{ 'case-history-list-sms' | translate }}</span> {{ entry.newStatus }}
            </div>
            <div
              *ngIf="
                entry.caseHistoryType === CaseHistoryType.FAX ||
                entry.caseHistoryType === CaseHistoryType.FAX_RECEIVE ||
                entry.caseHistoryType === CaseHistoryType.EMAIL ||
                entry.caseHistoryType === CaseHistoryType.EMAIL_RECEIVE
              "
            >
              <span class="historyTabelCell-label">{{ 'case-history-list-message-subject' | translate }}:</span>
              <span class="historyTabelCell-value"> {{ entry.subject }}</span>
              <div *ngIf="entry.attachments !== null && entry.attachments !== ''">
                <span class="historyTabelCell-label">{{ 'case-history-list-message-attachments' | translate }}:</span>
                <span class="historyTabelCell-value"> {{ entry.attachments }}</span>
              </div>
              <div>
                <span class="historyTabelCell-label">{{ 'case-history-list-message-to' | translate }}:</span>
                <span class="historyTabelCell-value"> {{ entry.to }}</span>
              </div>
              <div>
                <span class="historyTabelCell-label">{{ 'case-history-list-message-from' | translate }}:</span>
                <span class="historyTabelCell-value"> {{ entry.from }}</span>
              </div>
              <div>
                <span class="historyTabelCell-label">{{ 'case-history-list-id' | translate }}:</span>
                <span class="historyTabelCell-value"> {{ entry.id }}</span>
              </div>
              <div *ngIf="entry.updatedInternalSummary !== null && entry.updatedInternalSummary !== ''">
                <span class="historyTabelCell-label">{{ 'case-history-list-message-internal-summary' | translate }}:</span>
                <span class="historyTabelCell-value"> {{ entry.updatedInternalSummary }}</span>
              </div>
              <div *ngIf="entry.cc !== null && entry.cc !== ''">
                <span class="historyTabelCell-label">{{ 'case-history-list-message-cc' | translate }}:</span>
                <span class="historyTabelCell-value"> {{ entry.cc }}</span>
              </div>
            </div>
            <div *ngIf="entry.caseHistoryType === CaseHistoryType.FAX_UPDATE || entry.caseHistoryType === CaseHistoryType.EMAIL_UPDATE">
              <div>
                <div *ngIf="entry.updatedInternalSummary">
                  <span class="historyTabelCell-label">{{ 'case-history-list-message-internal-summary' | translate }}:</span>
                  <span class="historyTabelCell-value"> {{ entry.updatedInternalSummary }}</span>
                </div>
                <div *ngIf="entry.newStatus">
                  <span class="historyTabelCell-label">{{ 'case-history-list-message-status' | translate }}:</span>
                  <span class="historyTabelCell-value"> {{ entry.newStatus }}</span>
                </div>
                <div *ngIf="entry.updatedStakeholderSenderId">
                  <span class="historyTabelCell-label">{{ 'case-history-list-message-from' | translate }}:</span>
                  <span class="historyTabelCell-value"> {{getStakeholderById(entry.updatedStakeholderSenderId)}}</span>
                </div>
              </div>
            </div>
            <div *ngIf="showDataFieldWithNewValues(entry.caseHistoryType) === true" class="icon-phone">
              <div *ngFor="let dataFieldChangeDto of entry.dataFieldChanges">
                <span class="historyTabelCell-label"> {{ dataFieldChangeDto.field | translate }}:</span>
                <span class="entry-text-container" *ngIf="entry.caseHistoryType !== CaseHistoryType.SCREENING_TOOL_CHANGE && entry.caseObjectType !== 'PLANNED_TRAVEL'">
                  <span class="historyTabelCell-value"> {{ dataFieldChangeDto.newValue | translate }}</span>
                </span>
                <span class="entry-text-container" *ngIf="entry.caseHistoryType === CaseHistoryType.SCREENING_TOOL_CHANGE">
                  <span class="historyTabelCell-value">
                    <span *ngIf="dataFieldChangeDto.field === 'screening-tool-case-address-state'">
                      {{ dataFieldChangeDto.newValue }}
                    </span>
                    <span *ngIf="dataFieldChangeDto.field !== 'screening-tool-case-address-state'">
                      {{ this.screeningStateDisplayValueAdapter.getPropertyFromValue(dataFieldChangeDto.newValue) | translate }}
                    </span>
                  </span>
                </span>
                <span *ngIf="entry.caseObjectType === 'PLANNED_TRAVEL'" class="historyTabelCell-value">
                  <span *ngIf="entry.dataFieldChanges[0].field === 'Purpose of travel'">
                    {{ showPurposeOfTravelText(dataFieldChangeDto.newValue) }}
                  </span>

                  <span *ngIf="entry.dataFieldChanges[0].field !== 'Purpose of travel'">
                    {{ dataFieldChangeDto.newValue | translate }}
                  </span>
                </span>
              </div>
            </div>
            <div *ngIf="entry.caseHistoryType === 'RULE_ENGINE'" class="icon-phone">
              <div>
                <div>
                  <span class="headline">{{ 'case-history-rule-customer-profile' | translate }}</span
                  ><span class="entry-text-container historyTabelCell-value">{{ entry.caseHistoryRuleData.customerProfileName }}</span>
                </div>
                <div>
                  <span class="headline">{{ 'case-history-rule-mapping-name' | translate }}</span
                  ><span class="entry-text-container historyTabelCell-value">{{ entry.caseHistoryRuleData.name }}</span>
                </div>
                <div>
                  <span class="headline">{{ 'case-history-rule-event' | translate }}</span
                  ><span class="entry-text-container historyTabelCell-value">{{ entry.caseHistoryRuleData.eventName }}</span>
                </div>
                <div>
                  <span class="headline">{{ 'case-history-rule-rule' | translate }}</span
                  ><span class="entry-text-container historyTabelCell-value">{{ entry.caseHistoryRuleData.ruleName }}</span>
                </div>
                <div>
                  <span class="headline">{{ 'case-history-rule-action' | translate }}</span
                  ><span class="entry-text-container historyTabelCell-value">{{ entry.caseHistoryRuleData.actionName }}</span>
                </div>
                <div>
                  <span class="headline">{{ 'case-history-rule-task' | translate }}</span
                  ><span class="entry-text-container historyTabelCell-value">{{ entry.caseHistoryRuleData.taskId }}</span>
                </div>
                <div>
                  <span class="headline">{{ 'case-history-rule-error' | translate }}</span
                  ><span class="entry-text-container historyTabelCell-value">{{ entry.caseHistoryRuleData.error }}</span>
                </div>
              </div>
            </div>
            <div *ngIf="showSearchResult(entry.caseHistoryType) === true" class="icon-phone">
              <div *ngIf="!!entry.searchResultDetails">
                <span class="historyTabelCell-label">Status:</span>
                <span class="historyTabelCell-value"> {{ entry.searchResultDetails }}</span>
              </div>
              <div *ngFor="let paramDto of entry.searchResultParams">
                <span *ngIf="paramDto.ioDirection === IODirection.output">
                  <span class="historyTabelCell-label">{{ paramDto.searchResultParam }}:</span>
                  <span class="historyTabelCell-value"> {{ paramDto.searchResultParamValue }}</span>
                </span>
              </div>
            </div>
            <div *ngIf="showItemDeletion(entry.caseHistoryType) === true" class="icon-phone">
              <span class="redText">Deleted</span>
            </div>
            <div *ngIf="entry.caseHistoryType === CaseHistoryType.POLICY_SELECTED">
              <div class="headline">
                <span>
                  {{ 'case-history-list-new-value' | translate }}
                </span>
              </div>
              <div>
                <span class="historyTabelCell-label">{{ 'case-history-list-policy-customer-profile' | translate }}:</span>
                <span class="historyTabelCell-value"> {{ getCustomerProfileName(entry.propertyData) }}</span>
              </div>
              <div>
                <span class="historyTabelCell-label">{{ 'case-history-list-policy-number' | translate }}:</span>
                <span class="historyTabelCell-value"> {{ getPolicyNumber(entry.propertyData) }}</span>
              </div>
              <div>
                <span class="historyTabelCell-label">{{ 'case-history-list-policy-customer-product' | translate }}:</span>
                <span class="historyTabelCell-value"> {{ getCustomerProductName(entry.propertyData) }}</span>
              </div>
              <div>
                <span class="historyTabelCell-label">{{ 'case-history-list-policy-sosid' | translate }}:</span>
                <span class="historyTabelCell-value"> {{ getSosId(entry.propertyData) }}</span>
              </div>
              <div>
                <span class="historyTabelCell-label">{{ 'case-history-list-policy-version' | translate }}:</span>
                <span class="historyTabelCell-value"> {{ getVersion(entry.propertyData) }}</span>
              </div>
              <div>
                <span class="historyTabelCell-label">{{ 'case-history-list-policy-level' | translate }}:</span>
                <span class="historyTabelCell-value"> {{ getInsuranceLevel(entry.propertyData) }}</span>
              </div>
              <div>
                <span class="historyTabelCell-label">{{ 'case-history-list-policy-coverage-days' | translate }}:</span>
                <span class="historyTabelCell-value"> {{ getInsuranceCoverageDays(entry.propertyData) }}</span>
              </div>
              <div>
                <span class="historyTabelCell-label">{{ 'case-history-list-policy-coverage-weeks' | translate }}:</span>
                <span class="historyTabelCell-value"> {{ getInsuranceCoverageWeeks(entry.propertyData) }}</span>
              </div>
              <div>
                <span class="historyTabelCell-label">{{ 'case-history-list-policy-geo-area' | translate }}:</span>
                <span class="historyTabelCell-value"> {{ getInsuranceCoverageArea(entry.propertyData) }}</span>
              </div>
              <div>
                <span class="historyTabelCell-label">{{ 'case-history-list-policy-execution' | translate }}:</span>
                <span class="historyTabelCell-value"> {{ getLookupExecutionDetails(entry.propertyData) }}</span>
              </div>
            </div>
            <div *ngIf="entry.caseHistoryType === CaseHistoryType.CUSTOMER_SELECTED">
              <div class="headline">
                <span>
                  {{ 'case-history-list-new-value' | translate }}
                </span>
              </div>
              <div>
                <span class="historyTabelCell-label">{{ 'case-history-list-profile-customer-profile' | translate }}:</span>
                <span class="historyTabelCell-value"> {{ getCustomerProfileName(entry.propertyData) }}</span>
              </div>
            </div>
            <div *ngIf="entry.caseHistoryType === CaseHistoryType.STAKEHOLDER_MERGE">
              <div>
                {{ getNew(entry, 'stakeholderType') }}{{ 'case-history-merged-text' | translate }}{{ getOld(entry, 'stakeholderType') }}
              </div>
            </div>
            <div *ngIf="entry.caseHistoryType === CaseHistoryType.STAKEHOLDER_UNMERGE">
              <div>
                {{ getOld(entry, 'stakeholderType') }}{{ 'case-history-unmerged-text' | translate }}{{ getNew(entry, 'stakeholderType') }}
              </div>
            </div>
            <div *ngIf="entry.caseSubObjectType === CaseSubObjectType.customer_reference">
              <span class="historyTabelCell-label">    {{ 'case-summary-customer-reference-added-by' | translate }}:</span> {{ entry.userId }}
            </div>
            <div *ngIf="entry.caseHistoryType === CaseHistoryType.MEDICAL_PRE_ASSESSMENT_CREATE">
              <div class="headline">
                <span>
                  {{ 'case-history-list-new-value' | translate }}
                </span>
              </div>
              <div *ngFor="let dataFieldChangeDto of entry.dataFieldChanges">
                <span class="historyTabelCell-label" *ngIf="dataFieldChangeDto?.newValue" > {{ 'case-history-list-medical-pre-assessment-' + dataFieldChangeDto.field | translate }}:&nbsp;</span>
                <span class="historyTabelCell-label">{{ dataFieldChangeDto?.newValue | translate }}</span>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
