import { Component, Inject, ViewChild } from '@angular/core';
import { MinimizableDialogType } from '@secca/shared/enums/minimizable-dialog-type-enum';
import { DialogBoundryService } from "@secca/core/services/dialog-boundry.service";
import { DialogMinimizableDirective } from "@secca/shared/directives/dialog-minimizable.directive";
import { IMinimizable } from "@secca/shared/interfaces/minimizable";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { ManageClaimsDialogData } from './manage-claims-dialog-data.component';
import { IAdditionalInfo, refundAdditionalInfo} from "@secca/shared/interfaces/additional-info";
import { AddRefundComponent } from './add-refund/add-refund.component';
import { DialogHelperUtilService } from '@secca/core/services/dialog-helper-util.service';
import { IKeyboardEnabled } from '@secca/shared/interfaces/keyboard-enabled';
import { DialogSelectableDirective } from '@secca/shared/directives/dialog-selectable.directive';
import { IFocusEnabled } from '@secca/shared/interfaces/focus-enabled';

@Component({
  selector: 'app-case-economy-add-claims-dialog',
  templateUrl: './case-economy-add-claims-dialog.component.html',
  styleUrls: ['./case-economy-add-claims-dialog.component.scss']
})
export class CaseEconomyClaimsComponent implements IAdditionalInfo, IMinimizable, IKeyboardEnabled, IFocusEnabled {
  @ViewChild(AddRefundComponent) wrappedInstance: AddRefundComponent;
  @ViewChild(DialogMinimizableDirective) minimizedDirective: DialogMinimizableDirective;
  @ViewChild(DialogSelectableDirective) selectableDirective: DialogSelectableDirective;

  data: ManageClaimsDialogData;

  constructor(private dialogRef: MatDialogRef<CaseEconomyClaimsComponent>,
              @Inject(MAT_DIALOG_DATA) data: ManageClaimsDialogData,
              private dialogHelperUtilService: DialogHelperUtilService,
              public dialogBoundryService: DialogBoundryService)
  {
    this.data = data;
  }

  close(): void {
    this.dialogHelperUtilService.close(this.dialogRef, this.getAdditionalInfo()?.caseId);
    if(this.data.refundSaved !== undefined){
      this.data.refundSaved();
    }
  }

  keyboardClose(): void {
    this.wrappedInstance.close();
  }

  update(): void {
    if(this.data.refundSaved !== undefined){
      this.data.refundSaved();
    }
  }

  refundDeleted(): void {
    this.dialogHelperUtilService.close(this.dialogRef, this.getAdditionalInfo()?.caseId);
    if(this.data.refundDeleted !== undefined){
      this.data.refundDeleted();
    }
  }

  getAdditionalInfo(): refundAdditionalInfo {
    const caseId: number = this.wrappedInstance?.refund?.caseId;
    const refundId: number = this.wrappedInstance?.refund.id;
    return {
      caseId,
      refundId
    };
  }

  minimize(): void {
    this.minimizedDirective.minimize();
  }

  getMinimizeType(): MinimizableDialogType {
    return this.minimizedDirective.appDialogMinimizable;
  }

  getFocusElement(): any {
    return this.selectableDirective?.focusElement;
  }

  get MinimizableDialogType() {
    return MinimizableDialogType;
  }
}
