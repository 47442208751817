import {Adapter} from "@secca/shared/interfaces/adapter";
import {Injectable} from "@angular/core";

export class BillingCurrency {
  caseId: number;
  billingCurrency: string;

  public constructor(init?: Partial<BillingCurrency>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})

export class BillingCurrencyAdapter implements Adapter<BillingCurrency> {
  adapt(item: any): BillingCurrency {
    return new BillingCurrency({
      caseId: item.caseId,
      billingCurrency: item.billingCurrency,
    });
  }
}
