<div class="table-drop-down-container">

  <div class="table-container">
    <table>
      <thead>
        <tr>
          <td colspan="3" sortColumn [sortKey]="sortItem" [data]="filteredRows"></td>
        </tr>
      </thead>
      <tbody class="theBody" [class.zoomed]="settingsService.isZoomed()">
        <ng-container *ngFor="let row of filteredRows; index as i">
          <tr class="spacer">
            <td colspan="3"></td>
          </tr>
          <tr *ngIf="!isCaseDocumentRowDeleted(row)">
            <td (click)="openDocumentInEmbeddedView(row)" class="td1"
                [ngClass]="{'even' : i % 2 == 1, 'not-allowed': containsFileScanInformation(row) }">
              <div *ngIf="row.isOutgoing" class="img">
                <img src="../../../../../../assets/icons/DocOutgoing.svg"/>
              </div>
              <div *ngIf="!row.isOutgoing" class="img">
                <img src="../../../../../../assets/icons/DocIncoming.svg"/>
              </div>
            </td>
            <td (click)="downloadDocument(row)" class="td2"
                [ngClass]="{'even' : i % 2 == 1, 'not-allowed': containsFileScanInformation(row)}">
              <div class="d-flex justify-content-between">
                <div class="docInfoText">
                  <div class="d-flex">
                    <div class="text-name">{{(row.documentName.length > 36) ? (row.documentName | slice:0:36) + '..' : (row.documentName) }}
                    </div>
                    <div class="text-type"> ({{row.documentExtension.toUpperCase()}})</div>
                  </div>
                  <div class="text-rest">{{row.documentDate | momentFormat: 'D MMM YYYY HH:mm': 'local'}}</div>
                  <div class="d-flex text-rest">
                      <div class="document-group-channel" *ngIf="containsDocumentGroupChannel(row)">
                        <img [src]="getDocumentGroupChannelIcon(row.documentGroupChannel)" class="documentGroupChannel-icon"/>
                      </div>
                      <div *ngIf="!containsMatchedStakeholder(row)">
                        <div *ngIf="!row.isOutgoing">{{row.sender}} </div>
                        <div *ngIf="row.isOutgoing">{{row.recipient}} </div>
                      </div>
                      <div *ngIf="containsMatchedStakeholder(row)"><img class="stakeholder-icon" *ngIf="row.stakeholderIcon"
                                                                        [src]="row.stakeholderIcon">
                        {{row.stakeholderFullName}}
                      </div>
                  </div>
                </div>
                <div>
                    <div *ngIf="containsFileScanInformation(row)" [ngbPopover]="hoverFileScanTemplate" placement="bottom-left" triggers="hover">
                      <span>
                        <img class="virus-information-icon" [src]="getFileScanIcon(row.fileScan)"/>
                      </span>
                      <ng-template #hoverFileScanTemplate>
                        <app-hover-file-scan [fileScan]="row.fileScan"></app-hover-file-scan>
                      </ng-template>
                    </div>
                </div>
              </div>
            </td>
            <td class="td3" [ngClass]="{'even' : i % 2 == 1 }">
              <div>
                <div [appDisableInput]="caseStateService.isCaseDisabled()">
                  <div class="d-flex">
                    <div class="float-start  fixedDocumentTag">
                      <div class="fixedTagsHeader">Fixed tags</div>
                      <div class="fixedTagContainer">
                        <ng-container *ngFor="let tag of row.documentWithPreTags; index as i">
                              <span class="fixedTag" *ngIf="tag?.name">
                                {{ tag.name }}
                              </span>
                        </ng-container>
                      </div>
                    </div>
                    <div class="float-start documentTag">
                      <app-multiple-select-dropdown [name]="'Tags'" [placeholder]="'Tag the document'" [dropdownList]="documentsTagsList"
                                                    [(selectedItems)]="row.documentTags" [notSelectableList]="row.caseDocumentTags"
                                                    (selectedItemsChange)="updateDocument(row)">
                      </app-multiple-select-dropdown>
                    </div>
                    <div class="float-start documentComments">
                      <app-input [name]="'Note'" [placeholder]="''" [withImage]="false" [(value)]="row.comments"
                                 (focusout)="updateDocument(row)"></app-input>
                    </div>
                    <div class="float-start buttonAndRedDot">
                      <div class="float-start">
                        <div class="action-button action-button-column">
                          <div class="action-button-menu-button position-relative" (click)="setShowEntryMenu(i)"
                               (clickOutside)="clearShowEntryMenu(i)">
                            <div class="action-button-menu-button-dot"></div>
                            <div class="action-button-menu-button-dot"></div>
                            <div class="action-button-menu-button-dot"></div>
                          </div>
                          <div class="action-button-menu" *ngIf="getShowEntryMenu(i)">
                            <div class="action-button-menu-position">
                              <div *permission="PermissionEnum.DOCUMENT_UPDATE" (click)="openMoveCaseDocumentModal(row)">
                                {{ 'case-document-table-move-document' | translate}}
                              </div>
                            </div>
                            <div class="action-button-menu-position">
                              <div *permission="PermissionEnum.DOCUMENT_DELETE" (click)="openDeleteCaseDocumentModal(row)">
                                {{ 'case-document-table-delete-document' | translate}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="float-end">
                        <div class="red-dot" *ngIf="row.unhandled"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td [ngClass]="{'even' : i % 2 == 1 }">

            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>


