<div class="row mb-3 totalCard">
  <div class="smallHeader col-12">{{ header }}</div>
  <div class="col-4 nopadding left">
    {{ 'medical-regular-assessment-admission-period'| translate }}
    <div class="d-flex justify-content-between">
      <div>
        <app-input-calendar [withImage]="true" [name]="''" [required]="required" (dateChange)="rangeSupport.updateStartDate($event)"
          [date]="medicalAssessmentAdmission.admissionFrom" [disabled]="disabled" [invalid]="rangeSupport.isStartDateInValidInRange()"></app-input-calendar>
      </div>
      <div class="date-spacer">-</div>
      <div>
        <app-input-calendar [withImage]="true" [name]="''" (dateChange)="rangeSupport.updateEndDate($event)" [required]="required"
          [date]="medicalAssessmentAdmission.admissionTo" [disabled]="disabled" [invalid]="rangeSupport.isEndDateInvalidInRange()"></app-input-calendar>
      </div>
    </div>
  </div>
  <div class="col-4 nopadding">
    <app-input-calendar [withImage]="true" [name]="'medical-regular-assessment-additionally-admission-until' | translate"
      [minDate]="medicalAssessmentAdmission.admissionTo" [date]="this.medicalAssessmentAdmission.additionallyAdmissionUntil"
      [disabled]="disabled" (dateChange)="saveAdditionallyAdmissionUntil($event)">
    </app-input-calendar>
  </div>
  <div class="col-4 nopadding right">
    <app-input-with-value-type [name]="'medical-regular-assessment-total' | translate"
      [value]="medicalAssessmentAdmission.getTotalAdmissionDays(this.invalidDateRange)"
      [valueType]="'medical-regular-assessment-days' | translate" [disabled]="true" [required]="required"></app-input-with-value-type>
  </div>
</div>
