import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from './settings.service';
import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
var google: any;

@Injectable({
  providedIn: 'root'
})
export class GoogleLocationService extends BaseService {
  constructor(private settingsService: SettingsService, private http: HttpClient) {
    super(settingsService);
  }

  public getTimezoneFromGeoCoordinates(lat: number, lng: number, timestamp: number): Observable<any> {
    return this.http.get(
      'https://maps.googleapis.com/maps/api/timezone/json?location=' +
        lat +
        ',' +
        lng +
        '&timestamp=' +
        timestamp +
        '&key=' +
        this.settingsService.getGoogleAPIKey() +
        '&callback=Function.prototype'
    );
  }

  public getCurrentLocation(latitude: number, langitude: number) {
    const geocoder = new google.maps.Geocoder();
    const latlng = { lat: latitude, lng: langitude };
    return geocoder.geocode({ location: latlng }, results => {
      if (results[0]) {
        return results[0];
      } else {
        console.log('No results found');
      }
    });
  }
}
