import { Component, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { CaseLockOverlayAction, LockContextEnum } from '@secca/shared/enums/lock-context.enum';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import { ModalDialogComponent } from '../modal-dialog/modal-dialog.component';
import { ModalDialogConfiguration } from '@secca/shared/models/modal/modal-dialog-configuration';
import { CaseStateService } from '../../../core/state-services/case-state.service';
import { tap } from 'rxjs/operators';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { Subscription } from 'rxjs';
import { PermissionService } from '@secca/core/services/permission.service';
import { FinancialStatus } from '@secca/shared/models/financial-status';
import { FinancialStatusEnum } from '@secca/shared/models/enums';

@Component({
  selector: 'app-case-lock-overlay',
  styleUrls: ['./case-lock-overlay.component.scss'],
  templateUrl: './case-lock-overlay.component.html',
  encapsulation: ViewEncapsulation.None
})
@AutoUnsubscribe
export class CaseLockOverlayComponent implements OnInit, OnChanges {
  private confirmUnlockDialog: NgbModalRef;

  @Input()
  lockContext: LockContextEnum = LockContextEnum.CASE_BASIC;

  @Input()
  contextId: number;

  @Input()
  asOverlay = true;

  @Input()
  unlockPostAction: CaseLockOverlayAction = CaseLockOverlayAction.RELOAD_CASE;

  @Input()
  caseClosed: boolean
  
  @Input() financialStatus: FinancialStatus;

  isLockTaken: boolean;
  lockedBy: string;
  hover: boolean;

  $caseSubscr: Subscription;
  $caselockChangedSubscr: Subscription;

  constructor(private modalService: NgbModal,
              private translate: TranslateService,
              private caseStateService: CaseStateService,
              public caseLockHelperService: CaseLockHelperService,
              public permissionService: PermissionService) {
    this.$caseSubscr = this.caseStateService.getCase()
        .subscribe(() => this.initialise());

    this.$caselockChangedSubscr = this.caseLockHelperService.getCaseLocksChanged().subscribe(() => this.initialise());
  }


  ngOnInit(): void {
    this.initialise();
  }

  ngOnChanges(): void {
    this.initialise();
  }

  unlockCaseButtonClick(): void {
    this.showConfirmUnlockDialog();
  }

  get financialStatusClosedNoInvoice(): boolean {
    return FinancialStatusEnum.CLOSED_NO_INVOICE === this.financialStatus?.status;
  }

  get LockContextEnum(): any {
    return LockContextEnum;
  }

  private initialise(): void {
    this.isLockTaken = this.caseLockHelperService.isLockTaken(this.lockContext, this.contextId);
    this.lockedBy = this.caseLockHelperService.lockedBy(this.lockContext, this.contextId);
  }

  private showConfirmUnlockDialog(): void {
    this.confirmUnlockDialog = this.modalService.open(ModalDialogComponent);
    this.confirmUnlockDialog.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'default-modal-header',
      title: this.getUnlockTitle(),
      text: this.getUnlockText(),
      footer: 'unlock-case-footer',
      yes: 'default-modal-dialog-yes',
      no: 'default-modal-dialog-no',
      isBody: true,
      isFooter: true,
      translateTitle: false
    });
    this.confirmUnlockDialog.componentInstance.closeModalEvent.subscribe(
      emittedEvent => {
        if (emittedEvent) {
          this.unlockCase();
        }
        this.closeConfirmDialog();
      },
      (error) => console.log(error)
    );
  }

  private getUnlockTitle(): string {
    let title = '';
    switch (this.lockContext) {
      case LockContextEnum.CASE_BASIC:
        title = this.translate.instant('unlock-case-basic-title');
        break;
      case LockContextEnum.SUPPLIER_INVOICE:
        title = this.translate.instant('unlock-supplier-invoice-title');
        break;
    }

    return title + this.lockedBy;
  }

  private getUnlockText(): string {
    switch (this.lockContext) {
      case LockContextEnum.CASE_BASIC:
        return 'unlock-case-text';
      case LockContextEnum.SUPPLIER_INVOICE:
        return 'unlock-supplier-invoice-text';
    }
  }

  private unlockCase(): void {
    this.caseLockHelperService.forceReleaseLock(this.lockContext, this.contextId).pipe(
      tap(() => this.handleUnlockedCase())
    ).subscribe();
  }

  private closeConfirmDialog(): void {
    this.confirmUnlockDialog.close();
  }

  private handleUnlockedCase(): void {
    if ( this.unlockPostAction === CaseLockOverlayAction.TAKE_LOCK ) {
      this.caseLockHelperService.lockCase(this.lockContext, this.contextId).subscribe(
        () => this.initialise()
      );
    } 
    
    if ( this.unlockPostAction === CaseLockOverlayAction.RELOAD_CASE ) {
      this.caseLockHelperService.reloadCase();
    }
  }
}
