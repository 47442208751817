import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SourceUser } from './source-user';

@Component({
  selector: 'app-medical-assessment-source',
  templateUrl: './medical-assessment-source.component.html',
  styleUrls: ['./medical-assessment-source.component.scss'],
})
export class MedicalAssessmentSourceComponent implements OnInit {
  @Input() name: string;
  @Input() selectedItemId: any;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() requiredOverrule: boolean = true;
  isRequired: boolean;

  @Output() selectedItemIdChange = new EventEmitter();

  @Input()
  set users(theUsers: SourceUser[]) {
    if (theUsers !== undefined) {
      this._users = theUsers;
      this.filterUsers();
    }
  }
  get users(): SourceUser[] {
    return this._users;
  }
  _users: SourceUser[];

  constructor( ) {}

  ngOnInit() {
    this.isRequired = this.required && !this.selectedItemId;
  }

  filterUsers() {
    this.users.forEach( (user, index) => {
      if((!user.isCompany && (!user.firstName && !user.surname)) || (!!user.isCompany && user.companyName === '') )  {
        this.users.splice(index,1);
      }
    });
  }

  onSelectedItemIdChange() {
    this.selectedItemIdChange.emit(this.selectedItemId);
    this.isRequired = this.required && !this.selectedItemId;
  }
}
