<div class="modal-content">
    <div class="modal-header">
      <div class="modal-header-title">
        {{ 'case-basics-linked-add-link-to-case-title' | translate }}
      </div>
      <div class="modal-buttons">
        <div (click)="close()">
          <img src="/assets/icons/Close White.svg" />
        </div>
      </div>
    </div>
    <div class="body-1 modal-body">
      <div class="modal-text">
        {{ 'case-basics-linked-add-link-to-case-case-number' | translate}}
      </div>
      <input type="text" class="input-component" [(ngModel)]="caseNumber" (keyup)="setDirty()"
        [class.invalid-case-number]="!isCaseNumberValid">
      <button class="modal-button" [class.modal-button-busy]="moveInProgress || !isValidCaseNumber()" (click)="linkCase()" [disabled]="moveInProgress || !isValidCaseNumber()">
        {{ 'case-basics-linked-add-link-to-case-save' | translate }}
        <img src="/assets/icons/waitForSearchresult.gif" class="spinner" [class.hide-spinner]="!moveInProgress" />
      </button>
    </div>
  </div>
