import {
  ServiceTypeEnum,
  StakeholderTypeEnum,
  StatusTypes
} from '@secca/shared/models/enums';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ServiceOrder } from '@secca/shared/models/service-order/service-order';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { SelectionAwareStakeholder } from '@secca/case/components/case-plans/case-plans/add-service-order/models/SelectionAwareStakeholder';
import {
  CoordinationTransportServiceOrderExtension
} from '@secca/shared/models/service-order/coordination-transport-service-order-extension';
import { DropdownDictionary, SupplierDropDownAdapter } from '@secca/shared/models/dropdownDictionary';
import { ProviderService } from '@secca/core/services/providerService';
import { ProviderSearch } from '@secca/shared/models/ProviderSearch';
import { ServiceOrderTypeState } from '@secca/case/components/case-plans/case-plans/add-service-order/models/serviceOrderTypeState';
import { CaseIncident } from '@secca/shared/models/caseIncident';
import { ProviderResponse } from '@secca/shared/models/ProviderResponse';
import { UserDto } from '@secca/shared/models/userDto';
import { TranslateService } from '@ngx-translate/core';
import { interval, Subscription } from 'rxjs';
import { switchMap, takeWhile, tap } from 'rxjs/operators';
import { ProviderHelper } from '@secca/shared/helpers/provider-helper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProviderModifiedResponseDto } from '@secca/shared/models/provider-modified-status';
import { CaseService } from '@secca/core/services/case.service';
import { DictionaryService } from '@secca/core/services/dictionary.service';
import { IncidentService } from '@secca/core/services/incident.service';
import {
  RegularFlightMedicalComponent
} from '@secca/case/components/case-plans/case-plans/add-service-order/service-type-components/regular-flight-medical/regular-flight-medical.component';
import { ServiceTypeComponent } from '@secca/case/components/case-plans/case-plans/add-service-order/models/interfaces';
import { ServiceOrderType } from '@secca/shared/models/service-order-type';
import { ServiceOrderExtension } from '@secca/shared/models/service-order/service-order-extension';
import { FormControl } from '@angular/forms';
import { PlanService } from '@secca/core/services/plan.service';
import { StakeholderHelperService } from '@secca/core/services/stakeholder-helper.service';
import { StakeholderService } from '@secca/core/services/stakeholder.service';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import { PermissionService } from '@secca/core/services/permission.service';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { Coverage } from '@secca/shared/models/onepoint-coverage';
import { AutoCompleteTypeEnum } from 'src/app/shared/components/drop-down-countries-auto-complete/auto-complete-type-enum';
import { InputTypePattern } from 'src/app/shared/models/enums';
import { CoordinationTransportComponent } from '../coordination-transport/coordination-transport.component';


@Component({
  selector: 'app-coordination-transport-left-side',
  templateUrl: './coordination-transport-type-left-side.component.html',
  styleUrls: ['./coordination-transport-type-left-side.component.scss']
})
export class CoordinationTransportTypeLeftSideComponent implements OnInit, OnDestroy {
  @Input() selectedServiceOrderInitialStatus: StatusTypes;
  @Input() serviceTypeComponent: ServiceTypeComponent;





  @Input() set serviceOrderTypeState(serviceOrderTypeState: ServiceOrderTypeState) {
    this._serviceOrderTypeState = serviceOrderTypeState;
    if (this.$tabStatusSubscription != null) {
      this.$tabStatusSubscription.unsubscribe();
    }
    this.$tabStatusSubscription = serviceOrderTypeState.confirmationTabHasBeenClosed.subscribe(() => {
      this.changeStatus(StatusTypes.EXPECTED);
    });
  }
  get serviceOrderTypeState() {
    return this._serviceOrderTypeState;
  }

  @Input()
  set serviceOrder(serviceOrder: ServiceOrder) {
    this._serviceOrder = serviceOrder;
    this.changeStatus(this._serviceOrder.status);
  }
  get serviceOrder() {
    return this._serviceOrder;
  }
  _serviceOrder: ServiceOrder;

  @Input() showProviderSpinner: boolean = false;

  @Input()
  set coordinationTransport(coordinationTransport: CoordinationTransportServiceOrderExtension) {
    this._coordinationTransportation = coordinationTransport;
    this.isValid();
  }
  get coordinationTransport() {
    return this._coordinationTransportation;
  }
  _coordinationTransportation: CoordinationTransportServiceOrderExtension;

  @Input() set returnedProviders(returnedProviders: CaseStakeholder[]) {
    this._returnedProviders = returnedProviders;
    if (this.caseId != null) {
      this.mockProviders(returnedProviders);
    }
  }

  @Input() incidentId: number;
  @Input() serviceOrderType: ServiceOrderType;
  @Input() lastGopDocumentId: string;

  private expectedNotPossible: boolean = false;

  @Output() extensionChange: EventEmitter<ServiceOrderExtension> = new EventEmitter();
  @Output() supplierChange: EventEmitter<number> = new EventEmitter();
  @Output() supplierAddedEvent: EventEmitter<number> = new EventEmitter();
  @Input()
  set caseId(newCaseId: number) {
    if (newCaseId !== undefined) {
      this._caseId = newCaseId;
      if (this.returnedProviders != null) {
        this.mockProviders(this.returnedProviders);
      }
    }
  }

  get caseId(): number {
    return this._caseId;
  }

  @Input()
  set type(newTypeId: number) {
    if (newTypeId !== undefined) {
      this._typeId = newTypeId;
    }
  }

  get type(): number {
    return this._typeId;
  }


  get supplierDisabled(): boolean {
    return (
      this.serviceOrder.status === StatusTypes.CANCELLED ||
      this.isSupplierDisabled ||
      this.showProviderSpinner ||
      (!this.serviceOrderTypeState.canConfirmationTabBeClosed && this.serviceOrder.status !== StatusTypes.EXPECTED && this.hasSupplier)
    );
  }

  private caseStakeholders: CaseStakeholder[];

  @Input() set stakeholders(stakeholders: CaseStakeholder[]) {
    this.caseStakeholders = stakeholders;
    if (stakeholders) {
      this.selectionAwareStakeholders = stakeholders.map(stakeholder => new SelectionAwareStakeholder(stakeholder, false));
      this.selectStakeholders();
    }
  }

  private _serviceOrderTypeState: ServiceOrderTypeState;
  private incident = new CaseIncident();
  private providerResponse: ProviderResponse;
  private _caseId: number;
  private _returnedProviders: CaseStakeholder[];
  private caseNumber: string;
  private providerURL: string;
  private loggedInUser: UserDto;
  private _typeId: number;
  public searchStartLocationFormControl: FormControl;
  public searchEndLocationFormControl: FormControl;
  providers: DropdownDictionary[];
  private address: ProviderHelper;
  private isSupplierDisabled;
  private pollingActive: boolean;
  private pollingActiveCnt: number;
  private pollingCheckTime: Date;
  private pollingInterval = 1500;
  private hasSupplier: boolean;
  private $stakeholderPollSubscr: Subscription;
  private oldReturnedProviders;

  selectionAwareStakeholders: SelectionAwareStakeholder[];
  $tabStatusSubscription;

  get returnedProviders(): CaseStakeholder[] {
    return this._returnedProviders;
  }

  get isRightSideValid(): boolean {
    if (this.serviceTypeComponent == null) {
      return false;
    } else {
      if (CoordinationTransportComponent.prototype.isPrototypeOf(this.serviceTypeComponent)) {
        return (this.serviceTypeComponent as CoordinationTransportComponent).isValidForChangingTheStatusToConfirmation();
      }
      return this.serviceTypeComponent.isValid();
    }
  }

  constructor(
    private planService: PlanService,
    private caseService: CaseService,
    private incidentService: IncidentService,
    private supplierAdapter: SupplierDropDownAdapter,
    private providerService: ProviderService,
    private dictionaryService: DictionaryService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    public caseLockHelperService: CaseLockHelperService,
    public permissionService: PermissionService,
    public caseStateService: CaseStateService
  ) {
   }

  ngOnInit() {
    this.type = 1;
    this.pollingActive = false;
    this.pollingActiveCnt = 0;
    this.setSeccaCaseNumber();
    this.searchStartLocationFormControl = new FormControl();
    this.searchEndLocationFormControl = new FormControl();
    this.providerURL = this.providerService.getProviderURL();
    this.getLoggedInUser();
    this.selectStakeholders();
    this.getIncident();
    this.checkIfLeftSideIsValid();
  }

  ngOnDestroy(): void {
    this.pollingActive = false;
  }


  get isNotMedicalEscortServiceOrderType(): boolean {
    return this.serviceOrder.type !== ServiceTypeEnum.MEDICAL_ESCORT && this.serviceOrder.type !== ServiceTypeEnum.OTHER_REFUND_REQUEST;
  }

  get selectedStakeholders(): CaseStakeholder[] {
    if (this.caseStakeholders == null) {
      return null;
    } else {
      const selectedStakeholders = this.serviceOrder.getSelectedStakeholderIds();
      if (selectedStakeholders) {
        return this.caseStakeholders.filter(stakeholder =>
          selectedStakeholders.includes(stakeholder.id)
        );
      }
      return this.caseStakeholders.filter(stakeholder => stakeholder.hasRole(StakeholderTypeEnum.endUser));
    }
  }

  private getIncident() {
    this.incidentService.getCaseIncident(this.incidentId).subscribe(incident => {
      this.incident = incident;
    });
  }

  private selectStakeholders() {
    if (this.selectionAwareStakeholders !== null && this.selectionAwareStakeholders !== undefined) {
      this.selectionAwareStakeholders.forEach(s => {
        let stakeholderWasAppearingOnServiceOrder = false;
        s.isSelected = stakeholderWasAppearingOnServiceOrder;
      });
      this.serviceOrder.caseStakeholderIds = this.selectionAwareStakeholders.map(a => +a.stakeholder.id);
    }
  }

  private getLoggedInUser() {
    this.dictionaryService.getLoggedInUser().subscribe(result => {
      this.loggedInUser = result;
    });
  }

  private setSeccaCaseNumber() {
    this.caseService.getCase(this._caseId.toString()).subscribe(result => {
      this.caseNumber = result.caseNumber;
    });
  }

  get InputTypePattern() {
    return InputTypePattern;
  }
  //TODO: ICC
  mockProviders(result: any[]) {
    if (result != null) {
      this.providers = result
        .filter(
          stakeholder =>
            stakeholder.stakeholderType !== StakeholderTypeEnum.endUser &&
            stakeholder.stakeholderType !== StakeholderTypeEnum.reporter &&
            stakeholder.stakeholderType !== StakeholderTypeEnum.policyHolder &&
            stakeholder.stakeholderType !== StakeholderTypeEnum.person
        )
        .map(data => this.supplierAdapter.adapt(data))
        .map(data => new DropdownDictionary(+data.id, data.name))
        .filter(data => data.name != null)
        .sort((a, b) =>
          a.name === 'Egencia' ||
          a.name === 'Albin International Repatriation Limited' ||
          a.name === 'GET-E International Airport Transfers'
            ? -1 : 0); // Egencia, Albin International Repatriation Limited, GET-E International Airport Transfers top of list
    }
  }
  get AutoCompleteTypeEnum() {
    return AutoCompleteTypeEnum;
  }

  isOddNumber(index: number) {
    if (index % 2 === 0) {
      return true;
    }
    return false;
  }
  supplierChanged(supplierId: number) {
    this.checkIfLeftSideIsValid();
    this.supplierChange.emit(supplierId);
  }

  dataChanged() {
    this.checkIfLeftSideIsValid();
  }

  checkIfLeftSideIsValid() {
    this.serviceOrderTypeState.isLeftSideValid = this.isCommitted ? !!this.serviceOrder.supplierId : true;
  }

  searchSupplier() {
    this.providerService.createProviderSearch(this.createJSONProviderSearchParameter()).subscribe(
      result => {
        if (result !== null) {
          this.providerResponse = result;
          this.pollUntilStakeholderUpdated();
          window.open(this.providerURL + this.providerResponse.uniqueId);
        }
      },
      error => {
        this.openProviderModal(error);
      }
    );
  }

  private openProviderModal(error: any) {
    this.address = new ProviderHelper(this.modalService);
    this.address.messageError(error, 'provider-error-modal-header', 'provider-error-title', 'provider-error-message');
  }

  private pollUntilStakeholderUpdated() {
    if (this.pollingActive) {
      return;
    }
    this.pollingActive = true;
    this.pollingCheckTime = null;
    this.oldReturnedProviders = this.returnedProviders;

    this.$stakeholderPollSubscr = interval(this.pollingInterval)
      .pipe(
        switchMap(() => this.providerService.providerModified(this.caseId, this.pollingCheckTime, null)),
        tap(result => this.handlePollResult(result)),
        takeWhile(() => this.pollingActive),
      )
      .subscribe(
        () => {},
        error => {
          console.log(error);
          this.pollingActive = false;
        }
      );
  }

  private handlePollResult(response: ProviderModifiedResponseDto): void {
    if (response.modifiedStakeholders.length !== 0) {
      this.loadStakeholders();
      this.pollingActive = false;
    }

    this.pollingCheckTime = response.lastCheckTime;
  }


  private loadStakeholders(): void {
    this.caseService.getCaseStakeholdersOnCase(this.caseId.toString()).subscribe(result => {
      this.returnedProviders = result;

      // Find new stakeholders
      if (this._returnedProviders && this._returnedProviders.length > 0) {
        const newProviders = this._returnedProviders.filter(
          theNew => this.oldReturnedProviders && !this.oldReturnedProviders.find(theOld => +theOld.id === +theNew.id)
        );

        if (newProviders.length === 1) {
          // Only one provider, select it
          this.serviceOrder.supplierId = +newProviders[0].id;
          this.supplierAddedEvent.emit(this.serviceOrder.supplierId);
        } else if (newProviders.length > 1) {
          // More providers, remove any contacts
          const newProvidersNoContacts = newProviders.filter(
            provider => StakeholderTypeEnum.assistanceContact !== provider.stakeholderType &&
              StakeholderTypeEnum.sosServiceOffice !== provider.stakeholderType
          );
          if (newProvidersNoContacts.length === 1) {
            // Only one provider, select it
            this.serviceOrder.supplierId = +newProvidersNoContacts[0].id;
            this.supplierAddedEvent.emit(this.serviceOrder.supplierId);
          }
        }
      }
    });
  }

  private createJSONProviderSearchParameter() {
    const isEndUserCurrentLocation = this.incident.currentLocation.country !== null;

    const searchParameter = new ProviderSearch({
      caseNumber: null !== this.caseNumber ? this.caseNumber : this.translateService.instant('provider-call-without-case-number'),
      caseId: this.caseId.toString(),
      stakeholderId: '',
      webUser: this.loggedInUser.initials.toUpperCase(),
      clientLocation: {
        address: '',
        postalCode: isEndUserCurrentLocation ? this.incident.currentLocation.postalCode : this.incident.incidentLocation.postalCode,
        cityName: isEndUserCurrentLocation ? this.incident.currentLocation.locality : this.incident.incidentLocation.locality,
        country: {
          name: isEndUserCurrentLocation ? this.incident.currentLocation.country : this.incident.incidentLocation.country,
        },
      },
      searchParameters: {
        providerType: '',
        name: '',
        service: '',
        contactDetail: '',
        city: '',
        countryCode: '',
      },
      integrationInformation: {
        showIntegration: true,
        WebfileWaitForBrowser: false,
        showSearch: true,
        textHeader: '',
        textBody: this.translateService.instant('provider-please-choose-the-required-provider'),
      },
    });
    return searchParameter;
  }

  changeStatus(val: StatusTypes, event: any = null) {
    // TODO HUSK validering...
    if (val === this.serviceOrder.status && event != null) {
      return false;
    }

    if (this.serviceOrderTypeState.isConfirmationTabSaved && val === StatusTypes.EXPECTED) {
      return false;
    }

    if (val !== this.serviceOrder.status) {
      this.serviceOrder.status = val;
      this.serviceOrderTypeState.statusChangeEvent.emit(val);
    }

    if (val === StatusTypes.EXPECTED) {
      this.serviceOrderTypeState.showConfirmationTab = false;
    }

    if (val === StatusTypes.COMMITTED || val === StatusTypes.CANCELLED) {
      this.serviceOrderTypeState.showConfirmationTab = true;
    }
    this.checkIfLeftSideIsValid();
  }

  expectedDisabled(): boolean {
    return this.expectedNotPossible || (this.selectedServiceOrderInitialStatus !== StatusTypes.EXPECTED && this.selectedServiceOrderInitialStatus !== StatusTypes.NOT_COVERED);
  }

  notCoveredDisabled(): boolean {
    return this.serviceOrder.status !== StatusTypes.EXPECTED &&  this.serviceOrder.status !== StatusTypes.NOT_COVERED;
  }

  commitedDisabled(): boolean {
    return  (!this.serviceOrderTypeState.isLeftSideValid || !this.coordinationTransport.isValidExpectation());
  }

  canceledDisabled(): boolean {
    return (
      !this.serviceOrderTypeState.isLeftSideValid ||
      !this.coordinationTransport.isValid()
    );
  }

  get isCommitted(): boolean {
    return this.serviceOrder.status === StatusTypes.COMMITTED;
  }

  get StatusTypes() {
    return StatusTypes;
  }

  isValid() {
    this.serviceOrderTypeState.isLeftSideValid = this.isCommitted ? !!this.serviceOrder.supplierId : true;
  }

}
