import { Component, ViewChild, TemplateRef, EventEmitter, Output, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NumberSymbol, getLocaleNumberSymbol } from '@angular/common';
import { SalesOrderItem } from '@secca/shared/models/salesOrderItem';
import { LocaleService } from '@secca/core/services/locale.service';
import { IConfig } from 'ngx-mask';
import {Currency, DineroObject} from 'dinero.js';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import { InsuranceService } from '@secca/core/services/insurance.service';
import { SalesOrderService } from '@secca/core/services/sales-order.service';
import { SalesOrderSosService } from '@secca/shared/models/sales-order-sos-service';
import { ServiceOrderService } from '@secca/core/services/service-order.service';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import { PermissionService } from '@secca/core/services/permission.service';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { FeeTypeEnum, SosServiceCategoriesEnum } from '@secca/shared/models/enums';
import { CaseStateService } from '@secca/core/state-services/case-state.service';

export enum ModifyActionEnum {
  ADD,
  REPLACE,
  ADJUST,
  DELETE,
  SELECT_SUGGESTION,
  CREDIT,
}

@Component({
  selector: 'app-modify-sales-order',
  templateUrl: './modify-sales-order.component.html',
  styleUrls: ['./modify-sales-order.component.scss'],
})
@AutoUnsubscribe
export class ModifySalesOrderComponent {
  @ViewChild('content') private template: TemplateRef<any>;
  @Output() salesOrderModified = new EventEmitter();
  caseId: string;
  salesOrderItem: SalesOrderItem;
  action: ModifyActionEnum;
  feeTypeSelection: FeeTypeEnum;
  actionComment: string = null;
  newAmount: number;
  selectedSosServiceId: string = null;
  filteredServices: DropdownDictionary[];
  salesOrderSosServices: SalesOrderSosService[];
  localCurrency: DineroObject = { amount: 0, currency: 'DKK', precision: 2 };
  isSaveHidden = false;
  primaryFeePresentOnCase = false;
  $personInsuranceSubscr: Subscription;
  $caseServiceOrdersSubscr: Subscription;
  $caseSubscr: Subscription;

  get ModifyActionEnum() {
    return ModifyActionEnum;
  }
  get FeeTypeEnum() {
    return FeeTypeEnum;
  }

  constructor(
    private modalService: NgbModal,
    private insuranceService: InsuranceService,
    private serviceOrderService: ServiceOrderService,
    private salesOrderService: SalesOrderService,
    public caseLockHelperService: CaseLockHelperService,
    public permissionService: PermissionService,
    public caseStateService: CaseStateService
  ) {}

  close() {
    this.modalService.dismissAll();
  }

  show(caseId: string, salesOrderItem: SalesOrderItem, action: ModifyActionEnum, primaryFeePresentOnCase: boolean, lineCurrency:Currency) {
    this.caseId = caseId;
    this.salesOrderItem = salesOrderItem;
    this.action = action;
    this.newAmount = null;
    this.selectedSosServiceId = null;
    this.actionComment = null;
    this.primaryFeePresentOnCase = primaryFeePresentOnCase;
    this.modalService.open(this.template, { size: 'lg' as any });
    this.feeTypeSelection = salesOrderItem?.feeType;
    if(lineCurrency){
      this.localCurrency = { amount: 0, currency: lineCurrency, precision: 2 };
    }
    if (action == ModifyActionEnum.ADD) {
      this.feeTypeSelection = primaryFeePresentOnCase ? FeeTypeEnum.SECONDARY : null;
    }
    if (caseId) {
      if (this.$personInsuranceSubscr) {
        this.$personInsuranceSubscr.unsubscribe();
      }
      this.$personInsuranceSubscr = this.insuranceService.getPersonInsurance(this.caseId).subscribe(personInsurance => {
        if (this.$caseServiceOrdersSubscr) {
          this.$caseServiceOrdersSubscr.unsubscribe();
        }
        this.$caseServiceOrdersSubscr = this.serviceOrderService.getCaseServiceOrders().subscribe(serviceOrders => {
          if (serviceOrders === undefined) {
            this.serviceOrderService.getServiceOrdersForCase(Number(this.caseId)).subscribe(newlyFetched => {
              this.serviceOrderService.sendCaseServiceOrders(newlyFetched);
            });
          } else {
            if (personInsurance.customerProfileId != null) {
              const businessArea = 'TRAVELCARE'; // Should come from service order
              if (this.$caseSubscr) {
                this.$caseSubscr.unsubscribe();
              }
              this.$caseSubscr = this.caseStateService.getCase().subscribe(theCase => {
                this.salesOrderService
                  .getPriceAgreementServices(personInsurance.customerProfileId, businessArea, theCase.caseCreatedDate)
                  .subscribe(result => {
                    this.salesOrderSosServices = result;
                    this.setFilteredServices();
                  });
              });
            }
          }
        });
      });
      this.setFilteredServices();
    }
  }

  changeFeeType(event) {
    this.setFilteredServices();
  }

  setFilteredServices() {
    this.filteredServices = [];

    if (this.salesOrderSosServices) {
      this.salesOrderSosServices
        .filter(
          service =>
            service.feeType === this.feeTypeSelection
        )
        .forEach(sosService => {
          this.filteredServices.push(
            new DropdownDictionary(
              sosService.sosServiceId,
              this.createServiceName(sosService)
            )
          );
        });
    }
  }

  private createServiceName(sosService: SalesOrderSosService) {
    if (sosService.share != null) {
      return sosService.serviceName + ' (' + sosService.share + '%)';
    }
    return sosService.serviceName + ' (' + sosService.agreementPrice?.currency + ' ' + sosService.agreementPrice?.amount + ')';
  }

  getTitle(): string {
    switch (this.action) {
      case ModifyActionEnum.ADD:
        return 'case-economy-sos-services-add-fee';
      case ModifyActionEnum.REPLACE:
        return 'case-economy-sos-services-replace-fee';
      case ModifyActionEnum.ADJUST:
        return 'case-economy-sos-services-adjust-amount';
      case ModifyActionEnum.DELETE:
        return 'case-economy-sos-services-delete-fee';
      case ModifyActionEnum.SELECT_SUGGESTION:
        return 'case-economy-sos-services-select-suggestion-title';
      case ModifyActionEnum.CREDIT:
        return 'case-economy-sos-services-credit-fee';
      default:
        return 'undefined';
    }
  }

  getThousandSeparator(): string {
    return getLocaleNumberSymbol(LocaleService.LOCALE, NumberSymbol.CurrencyGroup);
  }

  getDecimalMarker(): IConfig['decimalMarker'] {
    const currencyDecimal = getLocaleNumberSymbol(LocaleService.LOCALE, NumberSymbol.CurrencyDecimal);
    if (currencyDecimal === ',') {
      return ',';
    } else if (currencyDecimal === '.') {
      return '.';
    } else {
      throw new RangeError('NumberSymbol.CurrencyDecimal is neither "," nor "."!');
    }
  }

  invoiceCurrencyMask(): string {
    return 'separator.' + this.localCurrency.precision;
  }

  getServiceSelectionName(): string {
    if (this.action === ModifyActionEnum.ADD) {
      return 'case-economy-sos-services-selection-add-fee';
    } else {
      return 'case-economy-sos-services-selection-replace';
    }
  }

  saveDisabled(): boolean {
    let isSaveAllowed = this.actionComment !== null && this.actionComment.length > 0;
    switch (this.action) {
      case ModifyActionEnum.ADD:
      case ModifyActionEnum.REPLACE:
        isSaveAllowed = isSaveAllowed && this.selectedSosServiceId != null;
        break;
      case ModifyActionEnum.ADJUST:
        isSaveAllowed = isSaveAllowed && this.newAmount != null;
        break;
      default:
    }

    return !isSaveAllowed;
  }

  save() {
    switch (this.action) {
      case ModifyActionEnum.ADD:
        this.salesOrderService
          .addSalesOrderItem(this.caseId, this.selectedSosServiceId, this.actionComment)
          .subscribe(t => this.closeAndEmitEvent());
        break;
      case ModifyActionEnum.REPLACE:
        this.salesOrderService
          .replaceSalesOrderItem(this.salesOrderItem.id, this.selectedSosServiceId, this.actionComment)
          .subscribe(t => this.closeAndEmitEvent());
        break;
      case ModifyActionEnum.ADJUST:
        const amount: DineroObject = {
          amount: this.newAmount,
          currency: this.localCurrency.currency,
          precision: this.localCurrency.precision,
        };
        this.salesOrderService
          .salesOrderItemAdjustAmount(this.salesOrderItem.id, amount, this.actionComment)
          .subscribe(t => this.closeAndEmitEvent());
        break;
      case ModifyActionEnum.DELETE:
        this.salesOrderService
          .deleteSalesOrderItem(Number(this.caseId), this.salesOrderItem, this.actionComment)
          .subscribe(t => this.closeAndEmitEvent());
        break;
      case ModifyActionEnum.SELECT_SUGGESTION:
        this.salesOrderService
          .selectSuggestedSalesOrderItem(Number(this.caseId), this.salesOrderItem, this.actionComment)
          .subscribe(t => this.closeAndEmitEvent());
        break;
      case ModifyActionEnum.CREDIT:
        this.salesOrderService
          .creditSalesOrderItem(Number(this.caseId), this.salesOrderItem, this.actionComment)
          .subscribe(t => this.closeAndEmitEvent());
        break;
      default:
    }
  }

  private closeAndEmitEvent() {
    this.salesOrderModified.emit();
    this.close();
  }
}
