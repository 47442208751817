import { SubComponentModel } from './interfaces';
import { TransportTypeEnum } from './../../../../../../../shared/models/enums';

export class SubTransportType implements SubComponentModel {
  transportType: TransportTypeEnum;

  public constructor(transportType: TransportTypeEnum) {
    this.transportType = transportType;
  }

  isValid(): boolean {
    return true;
  }
}
