import {Injectable} from '@angular/core';
import {Adapter} from '../interfaces/adapter';
import * as moment from 'moment';
import {DaySerializer} from './day-serializer';
import {
  BalanceSheetEntryEnum,
  RefundAccountType,
  RefundStatus
} from './enums';
import {RefundItem, RefundItemAdapter} from './refund-Item';
import {BalanceSheetEntry, BalanceSheetEntryItem} from '@secca/case/components/case-plans/case-plans/add-service-order/models/interfaces';
import * as DineroFactory from 'dinero.js';
import {DineroObject} from 'dinero.js';
import { BalanceSheetProgress } from '@secca/shared/models/balance-sheet-event.model';
import { DateSerializer } from '@secca/shared/models/date-serializer';
import {RefundError, RefundErrorAdapter} from '@secca/shared/models/refund-error';

import { EconomyHistory } from './refund-history';

export class Refund implements BalanceSheetEntry, EconomyHistory {
  id: number;
  caseId: number;
  customerProfileId: number;
  refundStatus: RefundStatus;
  receivedDate: moment.Moment;
  approvedDate: moment.Moment;
  refundDate: moment.Moment;
  dueDate: moment.Moment;
  refundCurrency: string;
  internalRemark: string;
  refundDescription: string;
  stakeHolderId: number;
  templateName: string;
  templateLanguage: string;
  templateId: string;
  accountType: RefundAccountType;
  refundAmountExchanged: DineroObject;
  refundCaseAmountExchanged: DineroObject;
  caseExchangeRate: number;
  caseCurrency: number;
  otherAccountRegNo: string;
  bankName: string;
  bankCountry: string;
  bankCurrency: string;
  iban: string;
  swiftBIC: string;
  accountNo: string;
  saved: number;
  settled: number;
  foreignAccountNo: string;
  objection: boolean;
  items: RefundItem[];
  progress: BalanceSheetProgress;
  refundType: string;
  firstApprover: number;
  secondApprover: number;
  approverAwaiting: string;
  approverPermissionNecessary: string;
  firstApproverName: string;
  secondApproverName: string;
  modifiedBy: string;
  modifiedOn: moment.Moment;
  modifiedUserName: string;
  secondApprovedDate: moment.Moment;
  errors: RefundError[];

  deletedBy: string;
  deletedOn: moment.Moment;

  public constructor(init?: Partial<Refund>) {
    Object.assign(this, init);
  }

  identicalHistory(refund: Refund): boolean {
    return this.internalRemark == refund.internalRemark &&
            this.refundType == refund.refundType &&
            this.refundStatus == refund.refundStatus &&
            this.refundDate ? this.refundDate.isSame(refund.refundDate) :  refund.refundDate === null &&
            this.refundCurrency == refund.refundCurrency &&
            this.receivedDate ? this.receivedDate.isSame(refund.receivedDate) : refund.receivedDate === null &&
            this.stakeHolderId == refund.stakeHolderId &&
            this.approvedDate ? this.approvedDate.isSame(refund.approvedDate) : refund.approvedDate === null &&
            this.objection == refund.objection &&
            !this.dueDate ? !refund.dueDate : this.dueDate.isSame(refund.dueDate);
  }

  getRedStatusText(): string {
    if ([RefundStatus.CANCELLED, RefundStatus.REJECTED, RefundStatus.AWAITING_SEND_LETTER,
      RefundStatus.AWAITING_SECOND_APPROVER, RefundStatus.ERROR, RefundStatus.DELETED].includes(this.refundStatus)) {
      return this.refundStatus;
    }
    return RefundStatus.PAID;
  }

  isCancelled(): boolean {
       return this.refundStatus === RefundStatus.CANCELLED.toString();
  }

  isObjection(): boolean {
    return this.objection;
  }

  getInvoiceNumber(): string {
    return this.id.toString();
  }

  getInvoiceDocumentId(): string {
    return 'not used';
  }

  getItems(): BalanceSheetEntryItem[] {
    return this.items;
  }

  getCreditorId(): string {
    return 'not used';
  }

  getCreditorName(): string {
   return 'not used';
  }

  getReceivedDate(): Date {
    return this.receivedDate?.toDate();
  }

  getInvoiceDate(): Date {
    return this.refundDate?.toDate();
  }

  getDueDate(): Date {
    return this.dueDate?.toDate();
  }

  getStatus(): string {
    return this.refundStatus?.toString();
  }

  getReductionAmount(): number {
    return this.saved;
  }

  getSettledAmount(): number {
    return this.settled;
  }

  getExchangeRate(): number {
    return this.caseExchangeRate;
  }

  getId(): number {
    return this.id;
  }

  getType(): BalanceSheetEntryEnum {
    return BalanceSheetEntryEnum.REFUND;
  }

  getInvoiceAmount(): DineroFactory.DineroObject {
    return this.refundAmountExchanged;
  }

  getInvoiceAmountExchanged(): DineroObject {
    return this.refundCaseAmountExchanged;
  }

  getMedicalEscort(): boolean {
    return false;
  }

  setReductionAmount(amount: number) {
    this.saved = amount;
  }

  setSettledAmount(amount: number) {
    this.settled = amount;
  }

  getStakeholderId(): number {
    return this.stakeHolderId;
  }

  getProgress(): BalanceSheetProgress {
    return this.progress;
  }

  isDeleted(): boolean {
    return RefundStatus.DELETED.toString() === this.getStatus();
  }
}

export class SelectItem<T> {
  label: string;
  value: T;
  selected: boolean;

  public constructor(init?: Partial<SelectItem<T>>) {
    Object.assign(this, init);
  }
}

@Injectable({
    providedIn: 'root'
  })
  export class RefundAdapter implements Adapter<Refund> {


    adapt(item: any): Refund {
      const refundErrorAdapter = new RefundErrorAdapter();
      const refund = new Refund({
        id: item.id,
        caseId: item.caseId,
        customerProfileId: item.customerProfileId,
        refundStatus: item.refundStatus,
        receivedDate: DaySerializer.deserialize(item.receivedDate),
        refundDate: DaySerializer.deserialize(item.refundDate),
        dueDate: DaySerializer.deserialize(item.dueDate),
        approvedDate: DaySerializer.deserialize(item.approvedDate),
        modifiedBy: item.modifiedBy,
        caseCurrency: item.caseCurrency,
        caseExchangeRate: item.caseExchangeRate,
        refundCurrency: item.refundCurrency,
        internalRemark: item.internalRemark,
        refundDescription: item.refundDescription,
        stakeHolderId: item.stakeHolderId,
        templateName: item.templateName,
        templateLanguage: item.templateLanguage,
        templateId: item.templateId,
        accountType: item.accountType,
        otherAccountRegNo: item.otherAccountRegNo,
        bankCountry: item.bankCountry,
        bankCurrency: item.bankCurrency,
        bankName: item.bankName,
        iban: item.iban,
        swiftBIC: item.swiftBIC,
        accountNo: item.accountNo,
        foreignAccountNo: item.foreignAccountNo,
        objection: item.objection,
        items: !!item.items ? this.adaptRefundItem(item.items) : null,
        refundType: item.refundType,
        firstApprover: item.firstApprover,
        secondApprover: item.secondApprover,
        approverAwaiting: item.approverAwaiting,
        approverPermissionNecessary: item.approverPermissionNecessary,
        firstApproverName: item.firstApproverName,
        secondApproverName: item.secondApproverName,
        modifiedOn: item.modifiedOn ? DateSerializer.deserialize(item.modifiedOn) : null,
        modifiedUserName: item.modifiedUserName,
        secondApprovedDate: item.secondApprovedDate ? DateSerializer.deserialize(item.secondApprovedDate) : null,
        deletedOn: item.deletedOn ? DateSerializer.deserialize(item.deletedOn) : null,
        deletedBy: item.deletedBy,
        errors: item.refundErrors == null ? [] : item.refundErrors.map(refundErrorAdapter.adapt),
      });
      let total = 0;
      refund.items.forEach(refundItem => total += refundItem.refundExchangedAmount);
      refund.refundAmountExchanged = { amount: total, currency: refund.refundCurrency as DineroFactory.Currency, precision: 2 };
      refund.refundCaseAmountExchanged = {
        amount: total * item.caseExchangeRate,
        currency: item.caseCurrency as DineroFactory.Currency, precision: 2
      };
      return refund;
    }
    adaptRefundItem(data: any[]) {
      const refundItemList = new Array<RefundItem>();
      data.forEach(item => refundItemList.push(new RefundItemAdapter().adapt(item)));
      return refundItemList;
    }
  }
