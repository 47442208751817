<div [ngClass] = "{'modal-content': !configuration.showTextArea, 'modal-content-input-field': configuration.showTextArea }" >
  <div class="modal-header">
    <h2>
      {{ configuration.header | translate }}
    </h2>
    <div class="modal-close">
      <img class="cross-close" src="/assets/icons/Close White.svg" (click)="close(false)">
    </div>
  </div>
  <div class="body-1 modal-body" *ngIf="configuration.isBody">
    <div class="lane text">
      <span *ngIf="configuration.translateTitle">{{ configuration.title | translate }}</span>
      <span *ngIf="!configuration.translateTitle">{{configuration.title}}</span>
    </div>
    <div class="lane text">
      <span *ngIf="configuration.translateText">{{ configuration.text | translate }}</span>
      <span *ngIf="!configuration.translateText">{{configuration.text}}</span>
    </div>
    <div class="lane">
      <ul>
        <li *ngFor="let item of items">
          <div>{{ item | translate}}</div>
        </li>
      </ul>
    </div>
    <div class="lane" *ngIf="medicalAssessmentInDraft" >
      {{ 'lost-medical-assessment-if-the-case-is-closed' | translate }}
    </div>
    <div class="lane" *ngIf="medicalPreAssessmentInDraft" >
      {{ 'lost-medical-pre-assessment-if-the-case-is-closed' | translate }}
    </div>
  </div>

  <div class="text-field" *ngIf=configuration.showTextArea>
    <label for="textArea">{{configuration.labelTextAboveTextArea | translate}}</label>
    <div class="position-relative">
      <textarea #textFieldArea="ngModel" type="text" id="textArea" class="input-field" [ngClass]="{'background-if-empty': !fieldText && textFieldArea.dirty }"
                [(ngModel)]="fieldText"
                appCharCount="top-right" maxlength="8000"
                placeholder="{{configuration.placeholderText | translate}}"></textarea>
    </div>
  </div>

  <div class="modal-footer" *ngIf="configuration.isFooter">
    <div class="lane w-100">
      <div class="text-footer float-start" [ngClass]="{'redText': configuration.redFooterText}">
        {{ configuration.footer | translate }}
      </div>
      <div class="float-end" *ngIf="configuration.showYesNoButton">
        <button class="no" (click)="close(false)" *ngIf="configuration.no">
          {{ configuration.no | translate }}
        </button>
        <button class="yes" (click)="close(true)" *ngIf="configuration.yes">
          {{ configuration.yes | translate }}
        </button>
      </div>
      <div *ngIf=configuration.showTextArea>
        <div *ngIf="configuration.showTextAreaWithYesNo; else showSubmitButton">
          <div class="float-end">
            <button class="no" (click)="close(false)">
              {{ configuration.no | translate }}
            </button>
            <button class="yes" ngbPopover="{{ fieldText ? null : configuration.modalYesHoverDialog | translate }}" placement='top' container="body"
                    triggers="mouseenter:mouseleave" (click)="saveAndCloseUserInput(fieldText)">
              {{ configuration.yes | translate }}
            </button>
          </div>
        </div>
        <ng-template #showSubmitButton>
          <div class="float-end">
            <button [ngClass]="{'submit-button' : fieldText, 'submit-button-deactivated': !fieldText}"
                  (click)="saveAndCloseUserInput(fieldText)" >
              {{ configuration.saveandclose | translate }}
            </button>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
