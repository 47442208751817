
import { MedifStatusTypeEnum } from '@secca/case/components/case-plans/case-plans/add-service-order/models/enums';
import { DaySerializer } from '@secca/shared/models/day-serializer';
import { SubRepatriationDetails } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subRepatriationDetails';
import { SubStakeholderAdapter } from './../../../modules/case/components/case-plans/case-plans/add-service-order/models/subStakeholder';
import { SubTravelInfo, SubTravelInfoAdapter } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subTravelInfo';
import { SubRemarksRulesAndFees } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subRemarksRulesAndFees';
import { SubTravellers } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subTravellers';
import { SubStakeholders } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholders';
import { SubStakeholder } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholder';
import { SubRepatriationFormMedical } from './../../../modules/case/components/case-plans/case-plans/add-service-order/models/subRepatriationFormMedical';
import { ServiceOrderExtension } from '@secca/shared/models/service-order/service-order-extension';
import { StatusTypes } from '@secca/shared/models/enums';
import { Location } from '@secca/shared/models/location';
import {
  MedicalEscortAdapter,
  SubMedicalEscort
} from '@secca/case/components/case-plans/case-plans/add-service-order/models/subMedicalEscort';
import { SubRemarksToSupplier } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subRemarksToSupplier';
import * as moment from 'moment-timezone';
import { MomentHelperService } from '@secca/core/services/moment-helper.service';
import { CommonServiceOrderExtension } from './CommonServiceOrderExtension.component';
import { CommitmentType, CommitmentTypeAdapter } from '../commitment-type';

export class RegularFlightMedicalServiceOrderExtension extends CommonServiceOrderExtension implements ServiceOrderExtension {
  id: number;
  treatingDoctor: SubStakeholder;
  treatingDoctorStakeholders: SubStakeholders;
  expectedStakeholders: SubStakeholder[];
  expectedSubStakeholders: SubStakeholders;
  confirmationStakeholders: SubStakeholder[];
  confirmationSubStakeholders: SubStakeholders;
  expectedSubTravellers: SubTravellers;
  confirmationSubTravellers: SubTravellers;
  remarksToSupplier: SubRemarksToSupplier;
  expectedMedicalEscorts: SubMedicalEscort[] = [];
  confirmationMedicalEscorts: SubMedicalEscort[] = [];
  repatriationDetails: SubRepatriationDetails;

  commitmentType: CommitmentType;
  remarksRulesAndFees: SubRemarksRulesAndFees;
  expectedTravelInfo: SubTravelInfo;
  confirmationTravelInfo: SubTravelInfo[];
  medifStatusType: MedifStatusTypeEnum;

  repatriationFormMedical: SubRepatriationFormMedical;
  // Only used for display purposes in plans
  displayFlightLegId = 0;
  displayDurationId: number;

  displayedStartLocation: Location;
  displayedEndLocation: Location;
  selectedStakeholderIds = [];

  public constructor(init?: Partial<RegularFlightMedicalServiceOrderExtension>) {
    super();
    Object.assign(this, init);
    if (!init) {
      this.init();
    }
  }

  isExpectationMedicalEscortStakeholderUnique(): boolean {
    let expectedMedicalEscortStakeholders: number[] = [];
    this.expectedMedicalEscorts.forEach(a => expectedMedicalEscortStakeholders.push(+a.subStakeholders.stakeholders[0].stakeholderId));
    return new Set(expectedMedicalEscortStakeholders).size === expectedMedicalEscortStakeholders.length;
  }

  isConfirmationMedicalEscortStakeholderUnique(): boolean {
    let confirmationMedicalEscortStakeholders: number[] = [];
    this.confirmationMedicalEscorts.forEach(a =>
      confirmationMedicalEscortStakeholders.push(+a.subStakeholders.stakeholders[0].stakeholderId)
    );
    return new Set(confirmationMedicalEscortStakeholders).size === confirmationMedicalEscortStakeholders.length;
  }

  hasMedicalEscort(status?: StatusTypes): boolean {
    return this.getMedicalTransports(status).length > 0;
  }

  hasCoTravellers(status: StatusTypes): boolean {
    return this.getCoTravellers(status).length > 1;
  }

  private getCoTravellers(status: StatusTypes): SubStakeholder[] {
    if (status === StatusTypes.COMMITTED || status == StatusTypes.CANCELLED){
      return this.confirmationStakeholders;
    } else {
      return this.expectedStakeholders;
    }
  }

  private getMedicalTransports(status?: StatusTypes): SubMedicalEscort[] {
    if (status === StatusTypes.COMMITTED || status == StatusTypes.CANCELLED){
      return this.confirmationMedicalEscorts;
    } else {
      return this.expectedMedicalEscorts;
    }
  }

  isPreviewGopValid(): boolean {
    return (
      this.isValidExpectation() &&
      this.repatriationFormMedical.isRepatriationInstructionPresent() &&
      this.repatriationFormMedical.isValid() &&
      this.isStakeholderAndMinimumRestFromExpectedMedicalEscortValid()
    );
  }

  isStakeholderAndMinimumRestFromExpectedMedicalEscortValid() {
    let isStakeholderAndMinimumRestFromExpectedMedicalEscortValid = true;
    this.expectedMedicalEscorts.forEach(medicalEscort => {
      if (
        medicalEscort.stakeholder.stakeholderId == null ||
        medicalEscort.stakeholder.includeDateOfBirth == null ||
        medicalEscort.stakeholder.includePassportDetails == null
      ) {
        isStakeholderAndMinimumRestFromExpectedMedicalEscortValid = false;
      }
      medicalEscort.subStakeholders.stakeholders.forEach(stakeholder => {
        if (
          stakeholder.stakeholderId == null ||
          stakeholder.includeDateOfBirth == null ||
          stakeholder.includePassportDetails == null
        ) {
          isStakeholderAndMinimumRestFromExpectedMedicalEscortValid = false;
        }
      });
      if (medicalEscort.minimumRest == null) {
        isStakeholderAndMinimumRestFromExpectedMedicalEscortValid = false;
      }
    });
    return isStakeholderAndMinimumRestFromExpectedMedicalEscortValid;
  }

  isTravellersCorrect() {
    let isTravellersCorrect = true;
    this.expectedStakeholders.forEach(traveller => {
      if (
        traveller.stakeholderId == null ||
        traveller.includeDateOfBirth == null ||
        traveller.includePassportDetails == null ||
        traveller.includePassportDetails === 'MISSING'
      ) {
        isTravellersCorrect = false;
      }
    });
    return isTravellersCorrect;
  }

  isValidExpectation(): boolean {
    let isExpectedMedicalEscortCorrect = true;
    this.expectedMedicalEscorts.forEach(medicalEscort => {
      if (!medicalEscort.outboundTripNotNeeded) {
        medicalEscort.outboundTrips.forEach(outboundTrip => {
          if (outboundTrip.departureDate == null || !outboundTrip.arrivalAt.freeText || !outboundTrip.departureFrom.freeText) {
            isExpectedMedicalEscortCorrect = false;
          }
        });
      }
      if (!medicalEscort.returnTripNotNeeded) {
        medicalEscort.returnTrips.forEach(returnTrip => {
          if (returnTrip.departureDate == null || !returnTrip.arrivalAt.freeText || !returnTrip.departureFrom.freeText) {
            isExpectedMedicalEscortCorrect = false;
          }
        });
      }
    });
    let isEndUserTavellerCorrect = true;
    if (
      this.expectedTravelInfo.departureDate == null ||
      this.expectedTravelInfo.departureFrom.freeText === '' ||
      this.expectedTravelInfo.arrivalAt.freeText === '' ||
      this.expectedTravelInfo.departureFrom.freeText == null ||
      this.expectedTravelInfo.arrivalAt.freeText == null
    ) {
      isEndUserTavellerCorrect = false;
    }

    return (
      isExpectedMedicalEscortCorrect &&
      this.repatriationFormMedical.isValid() &&
      isEndUserTavellerCorrect &&
      this.isExpectationMedicalEscortStakeholderUnique()
    );
  }

  isValid(): boolean {
    let isConfirmationMedicalEscortCorrect = true;

    this.confirmationMedicalEscorts.forEach(medicalEscort => {
      if (medicalEscort.stakeholder.stakeholderId == null) {
        isConfirmationMedicalEscortCorrect = false;
      }
      if (!medicalEscort.outboundTripNotNeeded) {
        medicalEscort.outboundTrips.forEach(outboundTrip => {
          if (
            outboundTrip.agencyReference == null ||
            outboundTrip.agencyReference === '' ||
            outboundTrip.flightNo == null ||
            outboundTrip.flightNo === '' ||
            outboundTrip.departureDate == null ||
            outboundTrip.arrivalAt.freeText == null ||
            outboundTrip.arrivalAt.freeText === '' ||
            outboundTrip.departureFrom.freeText === '' ||
            outboundTrip.departureFrom.freeText === null ||
            outboundTrip.arrivalDate == null
          ) {
            isConfirmationMedicalEscortCorrect = false;
          }
        });
      }
      if (!medicalEscort.returnTripNotNeeded) {
        medicalEscort.returnTrips.forEach(returnTrip => {
          if (
            returnTrip.agencyReference == null ||
            returnTrip.agencyReference === '' ||
            returnTrip.flightNo == null ||
            returnTrip.flightNo === '' ||
            returnTrip.departureDate == null ||
            returnTrip.arrivalAt.freeText === '' ||
            returnTrip.arrivalAt.freeText == null ||
            returnTrip.departureFrom.freeText === '' ||
            returnTrip.departureFrom.freeText == null ||
            returnTrip.arrivalDate == null
          ) {
            isConfirmationMedicalEscortCorrect = false;
          }
        });
      }
    });
    let isConfirmationTravelInfoCorrect = true;
    this.confirmationTravelInfo.forEach(confirmationTravelInfo => {
      if (!confirmationTravelInfo.isValid(false)) {
        isConfirmationTravelInfoCorrect = false;
      }
    });

    return (
      isConfirmationTravelInfoCorrect &&
      isConfirmationMedicalEscortCorrect &&
      this.isExpectationMedicalEscortStakeholderUnique() &&
      this.isConfirmationMedicalEscortStakeholderUnique()
    );
  }

  init() {
    this.remarksToSupplier = new SubRemarksToSupplier();
    this.expectedSubTravellers = new SubTravellers();
    this.remarksRulesAndFees = new SubRemarksRulesAndFees();
    this.expectedSubStakeholders = new SubStakeholders();
    this.expectedStakeholders = this.expectedSubStakeholders.stakeholders;
    this.expectedStakeholders[0].includeDateOfBirth = 'YES';
    this.confirmationSubStakeholders = new SubStakeholders();
    this.confirmationStakeholders = this.confirmationSubStakeholders.stakeholders;
    this.treatingDoctorStakeholders = new SubStakeholders();
    this.treatingDoctor = this.treatingDoctorStakeholders.stakeholders[0];
    this.expectedTravelInfo = this.expectedSubTravellers.flightLegs[0];
    this.repatriationFormMedical = new SubRepatriationFormMedical();
    this.confirmationSubTravellers = new SubTravellers();
    this.confirmationTravelInfo = this.confirmationSubTravellers.flightLegs;
    this.repatriationDetails = new SubRepatriationDetails();
  }

  getStartDate(status: StatusTypes): moment.Moment {
    return this.getStartTravelInfo(status).departureDate || this.getStartTravelInfo(StatusTypes.EXPECTED).departureDate;
  }

  getStartLocation(status: StatusTypes): Location {
    return this.getStartTravelInfo(status).departureFrom;
  }

  getEndLocation(status: StatusTypes): Location {
    return this.getEndTravelInfo(status).arrivalAt;
  }

  getEndDate(status: StatusTypes): moment.Moment {
    return this.getEndTravelInfo(status).arrivalDate ? this.getEndTravelInfo(status).arrivalDate :  MomentHelperService.endOfCaseWorkerDay(this.getStartDate(status));
  }

  private getStartTravelInfo(status: StatusTypes) {
    if (status === StatusTypes.COMMITTED || status == StatusTypes.CANCELLED){
      return this.confirmationTravelInfo[0];
    } else {
      return this.expectedTravelInfo;
    }
  }

  private getEndTravelInfo(status: StatusTypes) {
    if (status === StatusTypes.COMMITTED || status == StatusTypes.CANCELLED){
      return this.confirmationTravelInfo[this.confirmationTravelInfo.length - 1];
    } else {
      return this.expectedTravelInfo;
    }
  }

  isMultipleDurations(): boolean {
    return this.expectedSubTravellers.flightLegs.length > 1;
  }

  setCommitmentType(commitmentType: CommitmentType) {
    this.commitmentType = commitmentType;
  }

  getCommitmentType(status? : StatusTypes): CommitmentType {
    if (!status) {
      return this.commitmentType;
    }
    return status == StatusTypes.COMMITTED || status == StatusTypes.CANCELLED ? this.commitmentType : null;
  }

  getSelectedStakeholderIds(status?: StatusTypes): string[] {
    let result: string[];
    if (status == StatusTypes.COMMITTED || status == StatusTypes.CANCELLED) {
      result = this.confirmationSubStakeholders.stakeholders.map(subStakeholder => subStakeholder.stakeholderId);
    } else {
      result = this.expectedSubStakeholders.stakeholders.map(subStakeholder => subStakeholder.stakeholderId);
    }

    this.expectedMedicalEscorts.forEach(medicalEscort => {
      if (medicalEscort.stakeholder && medicalEscort.stakeholder.stakeholderId) {
        result.push(medicalEscort.stakeholder.stakeholderId);
      }
    });
    this.confirmationMedicalEscorts.forEach(confirmationMedicalEscort => {
      if (confirmationMedicalEscort.stakeholder && confirmationMedicalEscort.stakeholder.stakeholderId) {
        result.push(confirmationMedicalEscort.stakeholder.stakeholderId);
      }
    });

    return [...new Set(result)];
  }

  setSelectedStakeholderIds(selectedStakeholderIds: string[]) {
    this.selectedStakeholderIds = selectedStakeholderIds;
  }
}

export class RegularFlightMedicalServiceOrderExtensionAdapter {
  adapt(item?: Partial<RegularFlightMedicalServiceOrderExtension>): RegularFlightMedicalServiceOrderExtension {
    const medicalEscortAdapter = new MedicalEscortAdapter();
    const travelInfoAdapter = new SubTravelInfoAdapter();
    const subStakeholderAdapter = new SubStakeholderAdapter();
    const commitmentTypeAdapter: CommitmentTypeAdapter = new CommitmentTypeAdapter();
    const result = new RegularFlightMedicalServiceOrderExtension({
      id: item.id,
      expectedMedicalEscorts: item.expectedMedicalEscorts.map(medicalEscort => medicalEscortAdapter.adapt(medicalEscort)),
      confirmationMedicalEscorts: item.confirmationMedicalEscorts.map(medicalEscort => medicalEscortAdapter.adapt(medicalEscort)),
      remarksToSupplier: item.remarksToSupplier ? new SubRemarksToSupplier(item.remarksToSupplier) : new SubRemarksToSupplier(),
      commitmentType:
        item.commitmentType != null ? commitmentTypeAdapter.adapt(item.commitmentType) : null, //new SubCommitmentType(CommitmentTypes.BOOKING),
      treatingDoctor: item.treatingDoctor == null ? new SubStakeholder() : subStakeholderAdapter.adapt(item.treatingDoctor),
      remarksRulesAndFees: item.remarksRulesAndFees == null ? new SubRemarksRulesAndFees() : item.remarksRulesAndFees,
      expectedTravelInfo: item.expectedTravelInfo == null ? new SubTravelInfo() : travelInfoAdapter.adapt(item.expectedTravelInfo),
      confirmationTravelInfo: item.confirmationTravelInfo == null ? [] : item.confirmationTravelInfo.map(travelInfoAdapter.adapt),
      expectedStakeholders: item.expectedStakeholders == null ? [] : item.expectedStakeholders.map(subStakeholderAdapter.adapt),
      confirmationStakeholders: item.confirmationStakeholders == null ? [] : item.confirmationStakeholders.map(subStakeholderAdapter.adapt),
      repatriationDetails: item.repatriationDetails == null ? new SubRepatriationDetails() : item.repatriationDetails,
      medifStatusType: item.medifStatusType,
    });
    if (result.repatriationDetails.repatriationPossibleBy != null) {
      result.repatriationDetails.repatriationPossibleBy = DaySerializer.deserialize(
        result.repatriationDetails.repatriationPossibleBy as any
      );
    }

    result.repatriationFormMedical = new SubRepatriationFormMedical();
    result.repatriationFormMedical.id = item.repatriationFormMedical.id;
    result.repatriationFormMedical.fromAirportOrganizerStakeholderId = item.repatriationFormMedical.fromAirportOrganizerStakeholderId;
    result.repatriationFormMedical.medicalAssessmentId = item.repatriationFormMedical.medicalAssessmentId;
    result.repatriationFormMedical.repatriationInstructionId = item.repatriationFormMedical.repatriationInstructionId;
    result.repatriationFormMedical.toAirportOrganizerStakeholderId = item.repatriationFormMedical.toAirportOrganizerStakeholderId;
    result.expectedSubStakeholders = new SubStakeholders();
    result.expectedSubStakeholders.stakeholders = result.expectedStakeholders;
    result.confirmationSubStakeholders = new SubStakeholders();
    result.confirmationSubStakeholders.stakeholders = result.confirmationStakeholders;
    result.expectedSubTravellers = new SubTravellers();
    result.expectedSubTravellers.flightLegs[0] = result.expectedTravelInfo;
    result.treatingDoctorStakeholders = new SubStakeholders();
    result.treatingDoctorStakeholders.stakeholders[0] = result.treatingDoctor;
    result.confirmationSubTravellers = new SubTravellers();
    result.confirmationSubTravellers.flightLegs = result.confirmationTravelInfo;

    result.expectedMedicalEscorts.sort(this.compareEscortIdx());
    result.confirmationMedicalEscorts.sort(this.compareEscortIdx());
    return result;
  }

  private compareEscortIdx(){
    return (a, b) => {
      let comparison = 0;
      if (a.escortIndex < b.escortIndex) {
        comparison = -1;
      } else if (a.escortIndex > b.escortIndex) {
        comparison = 1;
      }
      return comparison;
    };
  }
}
