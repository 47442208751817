import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as onChange from 'on-change';

@Component({
  selector: 'app-yes-no-question',
  templateUrl: './yes-no-question.component.html',
  styleUrls: ['./yes-no-question.component.scss']
})
export class YesNoQuestionComponent<T> implements OnInit {
  @Input() header: string;
  @Input() disabled: boolean;
  @Input() nooflines: number;
  @Output() valueChange = new EventEmitter();
  @Output() dataChange = new EventEmitter();
  payload: T;
  questionValue: boolean;

  constructor() { }

  ngOnInit() {
    if (!!this.payload) {
      this.payload = onChange(this.payload, path => {
        this.dataChange.emit(this.payload);
      });
    }
  }

  answerYes() {
    if (this.value === true) {
      this.value = undefined;
    } else {
      this.value = true;
    }
  }

  answerNo() {
    if (this.value === false) {
      this.value = undefined;
    } else {
      this.value = false;
    }
  }

  @Input()
  get value() {
    return this.questionValue;
  }

  set value(question) {
    this.questionValue = question;
    this.valueChange.emit(this.questionValue);
  }

  @Input()
  get lines() {
    return this.nooflines;
  }

  set lines(lines) {
    this.nooflines = lines;
  }

  @Input()
  set data(data: T) {
    this.payload = data;
    this.dataChange.emit(this.payload);
  }

  get data(): T {
    return this.payload;
  }
}
