import {AfterViewInit, Component, Inject, ViewChild} from '@angular/core';
import {IKeyboardEnabled} from '@secca/shared/interfaces/keyboard-enabled';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import {DialogHelperUtilService} from '@secca/core/services/dialog-helper-util.service';
import {DialogBoundryService} from '@secca/core/services/dialog-boundry.service';
import {
  CaseSummaryNoteLinkedCaseModalComponent
} from '@secca/case/components/case-summery/case-summary-list/case-summary-note/case-summary-note-linked-case-modal/case-summary-note-linked-case-model.component';
import { TaskMessageModalMoveTaskComponent } from '../task-message-modal/task-message-modal-move-task/task-message-modal-move-task.component';
import { TaskMessageMoveDialogData } from './task-message-move-dialog-data.component';
import { DialogKeyboardEnabledDirective } from '@secca/shared/directives/dialog-keyboard-enabled.directive';
import { ShortcutService } from '@secca/core/services/shortcut.service';

@Component({
  selector: 'app-task-message-move-dialog',
  templateUrl: './task-message-move-dialog.component.html',
  styleUrls: [],
})
export class TaskMessageMoveDialogComponent implements IKeyboardEnabled {
  @ViewChild(CaseSummaryNoteLinkedCaseModalComponent) wrappedInstance: TaskMessageModalMoveTaskComponent;

  data: TaskMessageMoveDialogData;

  constructor(private dialogRef: MatDialogRef<CaseSummaryNoteLinkedCaseModalComponent>,
              @Inject(MAT_DIALOG_DATA) data: TaskMessageMoveDialogData,
              private shortcutService: ShortcutService,
              private dialogHelperUtilService: DialogHelperUtilService,
              public dialogBoundaryService: DialogBoundryService) {
    this.data = data;

    if ( this.data.disableShortcuts ) {
      this.shortcutService.disableShortCuts();
    }
  }

  close() {
    if ( this.data.disableShortcuts ) {
      this.shortcutService.enableShortCuts();
    }

    this.dialogHelperUtilService.closeModal();
  }

  keyboardClose(): void {
    this.dialogHelperUtilService.closeModal();
  }

  get enabledKeys(): string[] {
    return this.data.disableShortcuts ? [DialogKeyboardEnabledDirective.ESCAPE_KEY] : null;
  }
}
