export class JsonPatch {
  op: string;
  path: string;
  value: string;
  from: string;

  constructor(op: string, path: string, value: string, from: string) {
    this.op = op;
    this.path = path;
    this.value = value;
    this.from = from;
  }

  static remove(path: string) {
    return new JsonPatch('remove', path, null, null);
  }

  static replace(path: string, value) {
    return new JsonPatch('replace', path, value, null);
  }

  static add(path: string, value) {
    return new JsonPatch('add', path, value, null);
  }

  static move(from: string, path: string) {
    return new JsonPatch('move', path, null, from);
  }

  static copy(from: string, path: string) {
    return new JsonPatch('copy', path, null, from);
  }

  static test(path: string, value) {
    return new JsonPatch('test', path, value, null);
  }
}
