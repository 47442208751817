<div class="multiple-select-customer-product-container" >
    <span class="multiple-select-name">{{name}}</span>
    <div class="selected-item-box" [ngClass]="{'selected-item-box-required' : this.required && this.selectedItems.length < 1, 'selected-item-box-recommended' : recommended}" (click)="toggleShowDropdown($event)">
        <ng-container class="row" *ngFor="let selecteditem of selectedItems; let i = index">
          <div class="image-box" *ngIf="i < maxElements">
            <img [src]="getProfileImagesUrl(selecteditem.brandId)" class="imageClassSelected float-start" />
          </div>
        </ng-container>
        <div class="row label-box" *ngIf="this.selectedItems.length >= maxElements+1">
          <img src="../../../../assets/icons/plus_black.svg" tabindex="0" class="black-plus float-start" />
        </div>
      <ng-container class="row" *ngIf="this.selectedItems.length === 0">
        <div class="row label-box">
          {{ 'board-task-placeholder-all' | translate }}
        </div>
      </ng-container>
      <div class="up-down" *ngIf="!disabled">
        <img src="../../../../assets/icons/ArrowDown.svg" tabindex="0" (keypress)="toggleShowDropdown($event)">
      </div>
    </div>
    <div class="dropdown" *ngIf="showDropdown && !disabled" style="max-width: 400px;">
      <input #tableText class="search-text" [(ngModel)]="filteringText" type="text" (input)="searchDropdownValues($event.target.value)"
             placeholder="{{'multiple-select-component-search' | translate}}" (keydown.arrowDown)="arrowDownSearchTextPressed($event)">
      <button class="deselect-all" (click)="clearSelectedCustomerProfiles()">
        {{ 'board-task-table-customer-profile-multible-deselect-all' | translate }}
      </button>
      <div class="dropdown-container" style="width: 400px;">
        <div #dropdownCheckbox class="dropdown-value" *ngFor="let dropdownRow of dropdownValueList" tabindex="0"
             (click)="clickSelectItem($event, dropdownRow)" (keypress)="clickSelectItem($event, dropdownRow)"
             (mouseover)="focusCurrent($event)"
             (keydown.arrowDown)="arrowItemPressed($event,false)" (keydown.arrowUp)="arrowItemPressed($event, true)">

            <div class="dropdown-checkbox" >
              <img [@toggleChecked]="dropdownRow.customerProfileSelected ? 'checked' : 'unchecked'"   class="checked" src="../../../../assets/icons/Checked.svg">
            </div>

            <div style="min-width: 350px;">
              <img [src]="getProfileImagesUrl(dropdownRow.brandId)" class="imageClass float-start" />
              <div class="profileName" [ngOptionHighlight]="search">
                {{ dropdownRow.profileName }}
              </div>
              <div class="customerBrand">
                {{ dropdownRow.commonCustomerName }} / {{ dropdownRow.brandName }} | {{ getTeamName(dropdownRow.adtCode) }}
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>


