import { HandlingAreaStatusEnum } from '@secca/shared/models/enums';
import { TaskFilterRequest } from './task-filter-request.model';
import * as moment from 'moment';


export class InboxTaskFilterRequest extends TaskFilterRequest {
  incidentDatefrom: moment.Moment;
  incidentDateTo: moment.Moment;
  statuses: HandlingAreaStatusEnum[];
  claimStatus: string[];
  relatedCase: boolean;
  noIncidentDate: boolean;

  public constructor(init?: Partial<InboxTaskFilterRequest>) {
    super();
    Object.assign(this, init);
  }
}