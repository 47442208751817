import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';

export class DocumentDraft {
  id: string;
  uri: string;

  public constructor(init?: Partial<DocumentDraft>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class DocumentDraftAdapter implements Adapter<DocumentDraft> {
  adapt(item: any): DocumentDraft {
    return new DocumentDraft({
      id: item.id,
      uri: item.uri
    });
  }
}
