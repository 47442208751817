import {BaseService} from './base.service';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { map} from 'rxjs/operators';
import {SettingsService} from './settings.service';
import {SearchUserDropDownWrapper} from '../../shared/models/searchUser';
import {
  PolicyLookupResponse,
  PolicyLookupResponseAdapter,
} from '../../shared/models/policy-lookup-response';

@Injectable({
  providedIn: 'root'
})
export class PolicyService extends BaseService {
  constructor(
    private http: HttpClient,
    private searchUserDropDownWrapper: SearchUserDropDownWrapper,
    private settingsService: SettingsService,
    private policyLookupResponseAdapter: PolicyLookupResponseAdapter,
  ) {
    super(settingsService);
  }

  public lookupPolicy(caseId: string, isCard: boolean, value: string): Observable<PolicyLookupResponse> {
    return this.http.post(this.baseURL + 'policies/search', {caseId: Number(caseId), card: isCard,  value}, {headers: this.jsonHeaders})
      .pipe(map(item => this.policyLookupResponseAdapter.adapt(item)));
  }
}
