import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-user-picture',
  templateUrl: './user-picture.component.html',
  styleUrls: ['./user-picture.component.scss']
})
export class UserPictureComponent implements OnInit {
  @Input() imageURL: string;
  @Input() active: boolean;
  @Input() imageClass = 'personPhoto';
  userImageURL: string;

  constructor() { }

  ngOnInit() { }

  ngOnChanges() {
    this.userImageURL = '';
    this.changeUserImageUrl();
  }

  changeUserImageUrl() {
    if (this.imageURL === '' || this.imageURL === null) {
      this.userImageURL = 'assets/icons/NoImageYet.svg';
    } else if (this.imageURL === 'system') {
      this.userImageURL = 'assets/images/sos-user.jpeg';
    } else {
      this.userImageURL = 'data:image/jpeg;base64,' + this.imageURL;
    }
    if (this.active === false) {
      this.userImageURL = 'assets/icons/DeactivatedUser.svg';
    }
  }
}
