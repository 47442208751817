import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CauseItemType } from '@secca/shared/models/cause-item-type';
import { PurposeOfTravelItemType } from '@secca/shared/models/purpose-of-travel-item-type';
import { ServiceItemType } from '@secca/shared/models/service-item-type';
import { SimpleCustomerProfile } from '@secca/shared/models/simpleCustomerProfile';
import { IncomingCasesTaskViewModel } from '../../../models/incoming-cases-task-view-model';
import { Diagnosis } from '@secca/shared/models/dignosis';
@Component({
  selector: 'app-task-web-refunds-panel',
  templateUrl: './task-web-refunds-panel.component.html',
  styleUrls: ['./task-web-refunds-panel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TaskWebRefundsPanelComponent {
  @Input() taskDataViewModel: IncomingCasesTaskViewModel;
  @Input() serviceItemList: ServiceItemType[];
  @Input() purposeOfTravelItemList: PurposeOfTravelItemType[];
  @Input() causes: Map<string, CauseItemType[]> = new Map<string, CauseItemType[]>();
  @Input() diagnoses: Diagnosis[];
  @Input() customerProfiles: SimpleCustomerProfile[];

  constructor() {}

  get refundItems(): any[] {
    if ( this.taskDataViewModel?.data?.refunds ) {
      return this.taskDataViewModel?.data?.refunds;
    }

    if ( this.taskDataViewModel?.data?.refund?.refundItems ) {
      return this.taskDataViewModel?.data?.refund?.refundItems;
    }

    return [];
  }
}
