import { RepatriationExtraSpace } from './medical-models/repatriation-extra-space';
import { Oxygen } from './medical-models/oxygen';
import { Medication } from './medical-models/medication';
import { RepatriationAlternativeForm } from './medical-models/repatriation-alternative-form';
import { RepatriationInstructionsBase, RepatriationInstructionsBaseAdapter } from './medical-models/repatriation-instructions-base';
import {
  AssessmentTypeEnum,
  MedicalActionTypeEnum,
  MedicalAdmissionTypeEnum,
  MedicalAssessmentBasedOnEnum,
  MedicalExaminationsTypeEnum
} from '@secca/shared/models/enums';
import { Injectable } from '@angular/core';
import { Icd10Code } from '@secca/shared/models/icd10Code';
import {
  MedicalAssessmentRepatriationPrognosis,
  MedicalAssessmentRepatriationPrognosisAdapter
} from './medical-models/repatriation-prognosis';
import { RepatriationForm } from './medical-models/repatriation-form';
import { PatientNeeds } from './medical-models/patient-needs';
import { PatientState } from './medical-models/patient-state';
import { Equipment } from './medical-models/equipment';
import { Escort } from './medical-models/escort';
import * as moment from 'moment';
import { DateSerializer } from './date-serializer';

export class MedicalAssessment {
  id: number;
  seccaCaseId: number;
  sourceList: MedicalAssessmentSource[];
  actionList: MedicalAssessmentAction[];
  documentList: MedicalAssessmentDocument[];
  note: MedicalAssessmentNote;
  visits: MedicalAssessmentVisits;
  admissionList: MedicalAssessmentAdmission[];
  admissionIsReadmission: boolean;
  medicalEvacuation: boolean;
  medicalEvacuationDate: moment.Moment;
  travelHomeAsPlanned: boolean;
  repatriationInstructionsAllowed: boolean;
  followupPlanned: boolean;
  followupDate: moment.Moment;
  followupDescription: string;
  repatriationPrognosis: MedicalAssessmentRepatriationPrognosis;
  repatriationInstructionsBase: RepatriationInstructionsBase;
  medicalAssessmentType: AssessmentTypeEnum;


  public constructor(init?: Partial<MedicalAssessment>) {
    Object.assign(this, init);
    if (!init || !init.sourceList) {
      this.sourceList = new Array<MedicalAssessmentSource>();
      this.sourceList.push(new MedicalAssessmentSource());
    }
    if (!init || !init.documentList) {
      this.documentList = new Array<MedicalAssessmentDocument>();
    }
    if (!init || !init.note) {
      this.note = new MedicalAssessmentNote();
    }
    if (!init || !init.visits) {
      this.visits = new MedicalAssessmentVisits();
    }
    if (!init || !init.repatriationPrognosis) {
      this.repatriationPrognosis = new MedicalAssessmentRepatriationPrognosis();
    }
    if (!init || !init.admissionList) {
      this.admissionList = new Array<MedicalAssessmentAdmission>();
      this.admissionList.push(new MedicalAssessmentAdmission());
      this.admissionList.push(new MedicalAssessmentAdmission());
      this.admissionList[0].admissionType = MedicalAdmissionTypeEnum.REGULAR;
      this.admissionList[1].admissionType = MedicalAdmissionTypeEnum.INTENSIVE;
    }
    if (!init || !init.actionList) {
      this.actionList = new Array<MedicalAssessmentAction>();
      this.actionList.push(new MedicalAssessmentAction());
      this.actionList.push(new MedicalAssessmentAction());
      this.actionList[0].examinationType = MedicalExaminationsTypeEnum.ACUTELY;
      this.actionList[1].examinationType = MedicalExaminationsTypeEnum.NOT_ACUTELY;
    }
    if (!init || !init.repatriationInstructionsBase) {
      this.repatriationInstructionsBase = new RepatriationInstructionsBase();
      this.repatriationInstructionsBase.repatriationForm = new RepatriationForm();
      this.repatriationInstructionsBase.repatriationForm.extraSpaces = new Array<RepatriationExtraSpace>();
      this.repatriationInstructionsBase.alternativeFormStretcher = new RepatriationAlternativeForm();
      this.repatriationInstructionsBase.alternativeFormExtraSpace = new RepatriationAlternativeForm();
      this.repatriationInstructionsBase.alternativeFormEconomyClass = new RepatriationAlternativeForm();
      this.repatriationInstructionsBase.alternativeFormRegularPassenger = new RepatriationAlternativeForm();
      this.repatriationInstructionsBase.patientState = new PatientState();
      this.repatriationInstructionsBase.patientNeeds = new PatientNeeds();
      this.repatriationInstructionsBase.patientNeeds.equipment = new Array<Equipment>();
      this.repatriationInstructionsBase.patientNeeds.escort = new Array<Escort>();
      this.repatriationInstructionsBase.patientNeeds.medication = new Array<Medication>();
      this.repatriationInstructionsBase.patientNeeds.oxygen = new Array<Oxygen>();
    }
  }
}

export class MedicalAssessmentSource {
  id: number;
  basedOn: MedicalAssessmentBasedOnEnum;
  sourceName: string;
  sourceType: string;

  public constructor(init?: Partial<MedicalAssessmentSource>) {
    Object.assign(this, init);
  }
}

export class MedicalAssessmentNote {
  id: number;
  diagnosis: Icd10Code[];
  symptoms: Icd10Code[];
  date: moment.Moment;
  status: string;
  internalNote: string;

  public constructor(init?: Partial<MedicalAssessmentNote>) {
    Object.assign(this, init);
    if (!init || !init.diagnosis) {
      this.diagnosis = new Array<Icd10Code>();
    }
    if (!init || !init.symptoms) {
      this.symptoms = new Array<Icd10Code>();
    }
  }
}

export class MedicalAssessmentAction {
  id: number;
  actionType: MedicalActionTypeEnum;
  date: moment.Moment;
  description: string;
  examinationType: MedicalExaminationsTypeEnum;
  toBeDeleted: boolean;

  public constructor(init?: Partial<MedicalAssessmentAction>) {
    Object.assign(this, init);
  }
}

export class MedicalAssessmentVisits {
  id: number;
  numberOfVisits: number;
  numberOfAdditionalVisits: number;
  remarks: string;

  public constructor(init?: Partial<MedicalAssessmentVisits>) {
    Object.assign(this, init);
  }

  public getTotal(): number {
    let totalVisits = 0;
    if (this.numberOfVisits || this.numberOfAdditionalVisits) {
      totalVisits = this.numberOfVisits + this.numberOfAdditionalVisits;
    }
    return !!totalVisits ? totalVisits : 0;
  }
}

export class MedicalAssessmentAdmission {

  public constructor(init?: Partial<MedicalAssessmentAdmission>) {
    Object.assign(this, init);
  }
  id: number;
  admissionFrom: moment.Moment;
  admissionTo: moment.Moment;
  additionallyAdmissionUntil: moment.Moment;
  admissionType: MedicalAdmissionTypeEnum;

  public getTotalAdmissionDays(dateOutOfRange: boolean = false): number {
    let totalAdmissionDays = 0;
    if (this.admissionFrom !== undefined && this.admissionFrom !== null && this.admissionFrom.isValid() && !dateOutOfRange) {
      if (this.additionallyAdmissionUntil !== undefined && this.additionallyAdmissionUntil !== null && this.additionallyAdmissionUntil.isValid()) {
        totalAdmissionDays = this.numberOfDaysBetweenTwoDays(this.admissionFrom, this.additionallyAdmissionUntil);
      } else {
        if (this.admissionTo !== undefined && this.admissionTo !== null && this.admissionTo.isValid()) {
          totalAdmissionDays = this.numberOfDaysBetweenTwoDays(this.admissionFrom, this.admissionTo);
        }
      }
    }
    return totalAdmissionDays;
  }

  private numberOfDaysBetweenTwoDays(dateFrom: moment.Moment, dateTo: moment.Moment) {
    const from = new Date(dateFrom.format('YYYY-MM-DDTHH:mm:ssZ'));
    const to = new Date(dateTo.format('YYYY-MM-DDTHH:mm:ssZ'));
    if (from.getTime() < to.getTime()) {
      return Math.ceil(Math.abs(from.getTime() - to.getTime()) / (1000 * 3600 * 24));
    } else if (from.getTime() === to.getTime()) {
      return 1;
    } else {
      return 0;
    }
  }
}

export class MedicalAssessmentDocument {
  documentId: string;
  medicalReportSupplierPortalId: number;

  public constructor(init?: Partial<MedicalAssessmentDocument>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class MedicalAssessmentAdapter {
  adapt(data: any): MedicalAssessment {
    return new MedicalAssessment({
      id: data.id,
      seccaCaseId: data.seccaCaseId,
      documentList: !!data.documentList ? this.adaptDocumentList(data.documentList) : null,
      note: !!data.note ? new MedicalAssessmentNoteAdapter().adapt(data.note) : null,
      admissionList: !!data.admissionList ? this.adaptAdmissionList(data.admissionList) : null,
      admissionIsReadmission: data.admissionIsReadmission,
      sourceList: !!data.sourceList ? this.adaptSourceList(data.sourceList) : null,
      visits: !!data.visits ? new MedicalAssessmentVisitAdapter().adapt(data.visits) : null,
      actionList: !!data.actionList ? this.adaptActionList(data.actionList) : null,
      repatriationPrognosis: !!data.repatriationPrognosis ?
        new MedicalAssessmentRepatriationPrognosisAdapter().adapt(data.repatriationPrognosis) : null,
      medicalEvacuation: data.medicalEvacuation,
      medicalEvacuationDate: data.medicalEvacuationDate,
      travelHomeAsPlanned: data.travelHomeAsPlanned,
      repatriationInstructionsAllowed: data.repatriationInstructionsAllowed,
      followupPlanned: data.followupPlanned,
      followupDate: DateSerializer.deserialize(data.followupDate),
      repatriationInstructionsBase: !!data.repatriationInstructionsBase ?
        new RepatriationInstructionsBaseAdapter().adapt(data.repatriationInstructionsBase) : null,
      followupDescription: data.followupDescription,
      medicalAssessmentType: data.medicalAssessmentType
    });
  }

  adaptSourceList(data: any[]) {
    const sourceList = new Array<MedicalAssessmentSource>();
    data.forEach(item => sourceList.push(new MedicalAssessmentSourceAdapter().adapt(item)));

    return sourceList;
  }

  adaptDocumentList(data: any[]) {
    const sourceList = new Array<MedicalAssessmentDocument>();
    data.forEach(item => sourceList.push(new MedicalAssessmentDocumentAdapter().adapt(item)));
    return sourceList;
  }

  adaptAdmissionList(data: any[]) {
    const admissionList = new Array<MedicalAssessmentAdmission>();
    data.forEach(item => admissionList.push(new MedicalAssessmentAdmissionAdapter().adapt(item)));
    return admissionList;
  }

  adaptActionList(data: any[]) {
    const actionList = new Array<MedicalAssessmentAction>();
    data.forEach(item => actionList.push(new MedicalAssessmentActionAdapter().adapt(item)));
    return actionList;
  }
}

@Injectable({
  providedIn: 'root'
})
export class MedicalAssessmentNoteAdapter {
  adapt(data: any): MedicalAssessmentNote {
    return new MedicalAssessmentNote({
      id: data.id,
      diagnosis: new MedicalAssessmentIcd10Adapter().adapt(data.diagnosis),
      symptoms: new MedicalAssessmentIcd10Adapter().adapt(data.symptoms),
      date: DateSerializer.deserialize(data.date),
      status: data.status,
      internalNote: data.internalNote
    });
  }
}
@Injectable({
  providedIn: 'root'
})
export class MedicalAssessmentIcd10Adapter {
  adapt(data: any): Icd10Code[] {
    const adapted = new Array<Icd10Code>();
    if (!!data && data.length > 0) {
      data.forEach(value => adapted.push(value));
    }
    return adapted;
  }
}

@Injectable({
  providedIn: 'root'
})
export class MedicalAssessmentSourceAdapter {
  adapt(data: any): MedicalAssessmentSource {
    return new MedicalAssessmentSource({
      id: data.id,
      basedOn: data.basedOn,
      sourceName: data.sourceName,
      sourceType: data.sourceType
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class MedicalAssessmentActionAdapter {
  adapt(data: any): MedicalAssessmentAction {
    return new MedicalAssessmentAction({
      id: data.id,
      actionType: data.actionType,
      date: data.date,
      description: data.description,
      examinationType: data.examinationType
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class MedicalAssessmentVisitAdapter {
  adapt(data: any): MedicalAssessmentVisits {
    return new MedicalAssessmentVisits({
      id: data.id,
      numberOfAdditionalVisits: data.numberOfAdditionalVisits,
      numberOfVisits: data.numberOfVisits,
      remarks: data.remarks
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class MedicalAssessmentAdmissionAdapter {
  adapt(data: any): MedicalAssessmentAdmission {
    return new MedicalAssessmentAdmission({
      id: data.id,
      admissionFrom: !!data.admissionFrom ? DateSerializer.deserialize(data.admissionFrom) : null,
      admissionTo: !!data.admissionTo ? DateSerializer.deserialize(data.admissionTo) : null,
      additionallyAdmissionUntil: !!data.additionallyAdmissionUntil ? DateSerializer.deserialize(data.additionallyAdmissionUntil) : null,
      admissionType: data.admissionType
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class MedicalAssessmentDocumentAdapter {
  adapt(data: any): MedicalAssessmentDocument {
    return new MedicalAssessmentDocument({
      documentId: data.documentId,
      medicalReportSupplierPortalId: data.medicalReportSupplierPortalId
    });
  }
}
