import { MedicalPreAssessmentQuestionnaire } from './../../../../../../shared/models/medicalPreAssessment';
import { MedicalPreAssessmentStateService } from '@secca/core/services/medical-pre-assessment-state.service';
import { MedicalPreAssessmentState } from './../medical-models';
import { Subscription } from 'rxjs';
import { MedicalPreAssessmentComponent } from './../../medical-pre-assessment/medical-pre-assessment.component';
import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { MedicalPreAssessmentRelevantEnum } from '@secca/shared/models/enums';

@Component({
  selector: 'app-medical-pre-assessment-menu',
  templateUrl: './medical-pre-assessment-menu.component.html',
  styleUrls: ['./medical-pre-assessment-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class MedicalPreAssessmentMenuComponent implements OnInit {
  stateSubscription: Subscription;
  @Input() state: MedicalPreAssessmentState;
  @Input() questionaire: MedicalPreAssessmentQuestionnaire[];
  @Input() formValid = true;

  constructor(
    private preAssessmentSateService: MedicalPreAssessmentStateService,
    private medicalPreAssessmentComponent: MedicalPreAssessmentComponent ) { }

  ngOnInit(): void {
  }

  checkMark(valid: boolean) {
    if (valid) {
      return 'assets/icons/Success.svg';
    } else {
      return '/assets/icons/Failure.svg';
    }
  }

  isQuestionnaireValid(questionnaire: MedicalPreAssessmentQuestionnaire) {
    return questionnaire.diagnosisPreDeparture?.length > 0 &&
           !questionnaire.questions?.find(q => q.answer === undefined);
  }

  checkSubMenu(questionnaire: MedicalPreAssessmentQuestionnaire) {
    if ( questionnaire.questions?.filter(q => q.answer === undefined)?.length === 0 &&
         questionnaire.diagnosisPreDeparture.length ) {
          return 'assets/icons/Success.svg';
    }
    return '/assets/icons/Failure.svg';
  }

  private checkNullAndUndefined(data:any){
    return data ?? undefined;
  }

  scrollToElement(element, i?: number) {
    const e = i? element + i : element;
    this.medicalPreAssessmentComponent.scrollToElement(e);
  }

  get MedicalPreAssessmentRelevantEnum() {
    return MedicalPreAssessmentRelevantEnum;
  }
  

}
