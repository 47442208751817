import {Component, EventEmitter, Input, Output, TemplateRef, ViewChild} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CaseService } from '@secca/core/services/case.service';
import { PersonInsurance } from '@secca/shared/models/person-insurance';
import { InsuranceService } from '@secca/core/services/insurance.service';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';

@Component({
  selector: 'app-policy-view',
  templateUrl: './policy-view.component.html',
  styleUrls: ['./policy-view.component.scss']
})
@AutoUnsubscribe
export class PolicyViewComponent {
  @ViewChild('content') private template: TemplateRef<any>;
  @Input() set caseId(value: number) {
    this._caseId = value;
    if (value != null) {
      this.subscribeToPersonInsurance();
    }
  }

  @Output()
  closed: EventEmitter<void> = new EventEmitter();

  get caseId(): number {
    return this._caseId;
  }
  _caseId: number;
  policyHolder = new CaseStakeholder();
  nameLine = '';
  addressLine = '';
  insurance = new PersonInsurance();
  $personInsuranceSubscr: Subscription;

  constructor(private modalService: NgbModal, private caseService: CaseService,
    private insuranceService: InsuranceService) {
    this.caseService.observePolicyholder().subscribe(
      policyHolder => {
        if (policyHolder) {
          this.policyHolder = policyHolder;
          this.updatePolicyHolderData();
        }
      }
    );
  }

  private subscribeToPersonInsurance() {
    if ( this.$personInsuranceSubscr ) {
      this.$personInsuranceSubscr.unsubscribe();
    }
    this.$personInsuranceSubscr = this.insuranceService.getPersonInsurance(this.caseId.toString()).subscribe(
      insurance => {
        if (insurance) {
          this.insurance = insurance;
        }
      }
    );
  }

  updatePolicyHolderData() {
    if (this.policyHolder) {
      let nameLine = '';
      if (this.policyHolder.isCompany) {
        nameLine = this.policyHolder.company.name;
      } else {
        nameLine = [this.policyHolder.person.firstName, this.policyHolder.person.surname].join(' ');
        if (this.policyHolder.person.age || this.policyHolder.person.gender) {
          nameLine += ' ('
            + [this.policyHolder.person.gender, this.policyHolder.person.age].filter(Boolean).join(' ')
            + ')';
        }
      }

      this.nameLine = nameLine;
      const postalCodeCity = [this.policyHolder.address.postalCode, this.policyHolder.address.city].filter(Boolean).join(' ');
      this.addressLine = [this.policyHolder.address.street,
        postalCodeCity, this.policyHolder.address.homeCountry].filter(Boolean).join(', ');
    }
  }

  open() {
    this.modalService.open(this.template, { size: 'xl' as any });
  }

  close() {
    this.closed.emit()
    this.modalService.dismissAll();
  }
}
