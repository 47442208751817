<div appDialogSelectable appDialogKeyboardEnabled [appDialogMinimizable]='MinimizableDialogType.CaseCountryInfo'>

  <mat-dialog-content (cdkDragStarted)='dialogBoundryService.handleDragStart($event)'
                      (cdkDragEnded)='dialogBoundryService.handleDragEnd($event)'
                      cdkDragRootElement='.cdk-overlay-pane' cdkDrag>
    <div appDialogDragArea cdkDragHandle></div>

    <app-country-info (closeCountryInfo)='close()' [countryCode]='countryCode' [country]='country'>
    </app-country-info>

  </mat-dialog-content>
</div>
