import { Message } from '@secca/case/components/case-output-management/models/message';
import { DictionaryService } from 'src/app/core/services/dictionary.service';
import { StakeholderType } from 'src/app/modules/case/components/case-stakeholders/stakeholderType';
import { StakeholderHelperService } from './../../../../../../../../core/services/stakeholder-helper.service';
import { StakeholderService } from '@secca/core/services/stakeholder.service';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { PhoneNumberType, StakeholderTypeEnum } from '@secca/shared/models/enums';

@Component({
  selector: 'app-message-form-receiver',
  templateUrl: './message-form-receiver.component.html',
  styleUrls: ['./message-form-receiver.component.scss'],
})
export class MessageFormReceiverComponent implements OnInit {
  @Input() caseId: string;
  @Input() message: Message;
  @Input() name: string;
  @Input()
  public get selectedItemId(): CaseStakeholder {
    return this._selectedItemId;
  }
  public set selectedItemId(selectedItemId: CaseStakeholder) {
    this._selectedItemId = selectedItemId;
    this.tryToMatchGopContactStakeholder();
  }
  @Output() selectedItemIdChange = new EventEmitter();
  @Input() showFullName: boolean;
  @Input() takeStakeholderFromGopContact: boolean;

  private _selectedItemId: CaseStakeholder;
  stakeholderTypes: StakeholderType[];
  takeStakeholderFromGopContactHasBeenMatchedAlready: boolean;

  constructor(
    private stakeholderHelperService: StakeholderHelperService,
    private dictionaryService: DictionaryService,
    private stakeholderService: StakeholderService
  ) {}

  _originalStakeholders: CaseStakeholder[];
  _stakeholdersWithContacts: CaseStakeholder[];

  @Input() set stakeholders(stakeholders: CaseStakeholder[]) {
    this._originalStakeholders = stakeholders;
    this.createStakeholdersWithContacts(stakeholders);
    this.addFullNameForSearchPurposes();
    this.tryToMatchGopContactStakeholder();
  }

  get stakeholders(): CaseStakeholder[] {
    return this._stakeholdersWithContacts;
  }

  ngOnInit() {
    this.dictionaryService.getStakeholdersTypes().subscribe(
      stakeholderTypes => {
        this.stakeholderTypes = stakeholderTypes;
      }
    );
  }

  openStakeholderDropdown() {
    if (this.message != null) {
      this.message.afterMaximazingKeepTheLanguageIntact = false;
    }
  }

  tryToMatchGopContactStakeholder(): void {
    if (this.selectedItemId != null && this.stakeholders != null) {
      if (this.takeStakeholderFromGopContact && !this.takeStakeholderFromGopContactHasBeenMatchedAlready) {
        this.takeStakeholderFromGopContactHasBeenMatchedAlready = true;
        let foundGopStakeholder = this.stakeholders.find(
          a => a.id.toString() === this.selectedItemId.id.toString() && a.hasRole(StakeholderTypeEnum.gop)
        );

        if (foundGopStakeholder) {
          this._selectedItemId = foundGopStakeholder;
          this.onSelectedItemIdChange();
        }
      }
    }
  }

  createStakeholdersWithContacts(stakeholders: CaseStakeholder[]) {
    this._stakeholdersWithContacts = cloneDeep(stakeholders);
    this._stakeholdersWithContacts
      .filter(stakeholder => stakeholder.isCompany)
      .forEach(company => {
        this.addGopContactAsStakeholder(company);
        this.addContactsAsStakeholders(company);
      });
    this.sortStakeholdersWithContacts();
  }

  addGopContactAsStakeholder(stakeholder: CaseStakeholder) {
    const gopStakeholder = cloneDeep(stakeholder);
    if (stakeholder.company.gop) {
      gopStakeholder.phoneNumbers = [];
      gopStakeholder.company.email = stakeholder.company.gop.email ? stakeholder.company.gop.email : '';
      const gopFaxNumber = stakeholder.company.gop.phoneNumbers.find(a => a.type === PhoneNumberType.Fax);
      if (gopFaxNumber != null) {
        gopStakeholder.phoneNumbers.push(gopFaxNumber);
      }
      const gopMobileNumber = stakeholder.company.gop.phoneNumbers.find(a => a.type === PhoneNumberType.Mobile);
      if (gopMobileNumber != null) {
        gopStakeholder.phoneNumbers.push(gopMobileNumber);
      }
      gopStakeholder.company.contactPersons = [];
      gopStakeholder.company.name = stakeholder.company.name + ' (GOP: ' + stakeholder.company.contactPerson + ')';
      gopStakeholder.stakeholderType = StakeholderTypeEnum.gop;
      this._stakeholdersWithContacts.push(gopStakeholder);
    }
  }

  addContactsAsStakeholders(stakeholder: CaseStakeholder) {
    stakeholder.company.contactPersons.forEach(contact => {
      const newStakeholder = cloneDeep(stakeholder);
      newStakeholder.company.name = stakeholder.company.name + ' (' + contact.firstName + ' ' + contact.surname + ')';
      newStakeholder.company.contactPersons = [];
      newStakeholder.company.email = contact.email;
      this._stakeholdersWithContacts.push(newStakeholder);
    });
  }

  sortStakeholdersWithContacts() {
    this._stakeholdersWithContacts.sort((a, b) => {
      const aName = a.isCompany ? a.company.name : a.person.firstName + ' ' + a.person.surname;
      const bName = b.isCompany ? b.company.name : b.person.firstName + ' ' + b.person.surname;
      const isTheSameType = (a.isCompany && b.isCompany) || (!a.isCompany && !b.isCompany);
      if (isTheSameType && aName > bName) {
        return 1;
      }
      if (isTheSameType && aName < bName) {
        return -1;
      }
      return 0;
    });
  }

  addFullNameForSearchPurposes(): void {
    this._stakeholdersWithContacts.forEach((s: any) => {
      s.fullName = s.isCompany ? s.company.name : s.person.firstName + s.person.surname;
    });
  }

  onSelectedItemIdChange() {
    this.selectedItemIdChange.emit(this.selectedItemId);
  }

  getStakeholderIconUrl(stakeholderType: string) {
    if (this.stakeholderTypes == null) {
      return '';
    }
    const supplier = this.stakeholderTypes.find(type => type.stakeholderTypeEnum === stakeholderType);
    if (supplier != null) {
      return '/assets/icons/' + this.stakeholderService.addIconToCaseStakeholder(supplier.stakeholderTypeEnum) + '.svg';
    }
    if (stakeholderType === StakeholderTypeEnum.gop) {
      return '/assets/icons/GOP Contact Person.svg';
    }
    return '';
  }
}
