import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import { PermissionService } from '@secca/core/services/permission.service';
import { SalesOrderService } from '@secca/core/services/sales-order.service';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { SosServiceCategoriesEnum } from '@secca/shared/models/enums';
import { FeePickerStatus } from '@secca/shared/models/fee-picker-status';

@Component({
  selector: 'app-fee-picker-secondary',
  templateUrl: './fee-picker-secondary.component.html',
  styleUrls: ['./fee-picker-secondary.component.scss'],
})
export class FeePickerSecondaryComponent implements OnInit {
  @ViewChild('content') private template: TemplateRef<any>;
  @Output() feePickerSecondaryUpdated: EventEmitter<FeePickerStatus> = new EventEmitter();

  private feePickerStatus: FeePickerStatus;
  private feePickerCategoryMap: Map<string, boolean>;
  private isModified = false;

  constructor(
    private modalService: NgbModal,
    private caseLockHelperService: CaseLockHelperService,
    private permissionService: PermissionService,
    private salesOrderService: SalesOrderService,
    public caseStateService: CaseStateService
  ) {}

  ngOnInit(): void {}

  show(showFeePickerStatus: FeePickerStatus) {
    this.feePickerStatus = showFeePickerStatus;

    this.feePickerCategoryMap = new Map();
    Object.keys(SosServiceCategoriesEnum).forEach(category => this.feePickerCategoryMap.set(category, true));
    this.feePickerStatus.secondaryDisabledCategories.forEach(category => this.feePickerCategoryMap.set(category, false));

    this.isModified = false;

    this.modalService.open(this.template, { size: 'lg' as any });
  }

  close() {
    this.modalService.dismissAll();
  }

  getAllSosServiceCategories(): string[] {
    const serviceCategories = Object.keys(SosServiceCategoriesEnum);
    return serviceCategories.sort((c1, c2) => (c1 > c2 ? 1 : -1));
  }

  enableCategory(category: string) {
    this.feePickerCategoryMap.set(category, true);
    this.isModified = true;
  }

  saveDisabled(): boolean {
    return !this.isModified;
  }

  save() {
    this.isModified = false;
    this.feePickerStatus.secondaryDisabledCategories = [];
    this.feePickerCategoryMap.forEach((enabled, category) => {
      if (!enabled) {
        this.feePickerStatus.secondaryDisabledCategories.push(SosServiceCategoriesEnum[category]);
      }
    });
    this.salesOrderService.enableFeePicker(this.feePickerStatus.caseId, this.feePickerStatus).subscribe(result => {
      this.feePickerSecondaryUpdated.emit(result);
      this.close();
    });
  }
}
