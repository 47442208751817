import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CaseType, CaseTypeAdapter} from '@secca/shared/models/case-type';
import {Observable, Subject, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {BaseService} from './base.service';
import {SettingsService} from './settings.service';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';

@Injectable({
  providedIn: 'root'
})
export class CaseTypesService extends BaseService {

  constructor(
    private httpClient: HttpClient,
    private settingsService: SettingsService,
    private caseTypeAdapter: CaseTypeAdapter
  ) {
    super(settingsService);
  }

  getCaseTypesForCase(caseId: string): Observable<CaseType[]>  {
    return this.httpClient.get(this.baseURL + 'cases/case-types/all/' + caseId)
    .pipe(map((data: any[]) => data.map(item => this.caseTypeAdapter.adapt(item))));
  }

  getCaseTypeByCaseId(caseId: string): Observable<CaseType> {
    return this.httpClient.get(this.baseURL + 'cases/case-types/' + caseId)
      .pipe(map(item => this.caseTypeAdapter.adapt(item)));
  }

  resetCaseTypeEvaluationByCaseId(caseId: string) {
    return this.httpClient.put(this.baseURL + 'cases/case-types/' + caseId + '/reset', {}).pipe(map(item => this.caseTypeAdapter.adapt(item)));

  }

  saveCaseType(caseId: string, caseTypeCode: string){
    return this.httpClient.put(this.baseURL + 'cases/case-types/' + caseId + '/' + caseTypeCode, {} );
  }

  saveChannelType(caseId: string, channelTypeCode: string) {
    return this.httpClient.put(this.baseURL + 'cases/channel-types/' + caseId + '/' + channelTypeCode, {} );
  }

  getCaseTypesAll(): Observable<DropdownDictionary[]>{
    return this.httpClient.get<DropdownDictionary[]>(this.baseURL + 'cases/case-types/all')
    .pipe(map((data: any[]) => data.map(item => new DropdownDictionary(item.caseTypeCode, item.caseTypeName)))
        , catchError(err => {
          return throwError(err);
        }));
  }
}
