import { Component, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { DropdownDictionary } from 'src/app/shared/models/dropdownDictionary';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-drop-down-creditors',
  templateUrl: './drop-down-creditors.component.html',
  styleUrls: ['./drop-down-creditors.component.scss']
})
export class DropDownCreditorsComponent {
  @Input() required: boolean;
  @Input() requiredRedBackground: boolean;
  @Input() name: string;
  @Input() items: DropdownDictionary[];
  @Input() selectedItemId = '';
  @Input() disabled: boolean;
  @Input() noBorderAndArrow: boolean;
  @Input() closeOnSelect = true;
  @Input() placeholder = '';
  @Output() selectedItemIdChange = new EventEmitter();
  @Output() saveModel = new EventEmitter();
  @Output() open = new EventEmitter();
  @Output() close = new EventEmitter();
  @Output() focus = new EventEmitter();
  oldSelectedItemId: string;
  @ViewChild('selectCreditor') selectCreditor: NgSelectComponent;

  constructor() {}

  onChange() {
    this.selectedItemIdChange.emit(this.selectedItemId);
  }

  onOpen() {
    this.open.emit();
  }

  onClose() {
    this.close.emit();
  }

  onSaveModel() {
    if (this.oldSelectedItemId !== this.selectedItemId) {
      this.saveModel.emit();
    }
  }

  onSaveState() {
    this.oldSelectedItemId = this.selectedItemId;
  }

  setFocus() {
    this.disabled = false;
    this.selectCreditor.focus();
  }

  searchCreditor(term: string, item: DropdownDictionary) {
    term = term.toLowerCase();
    return item.name.toLowerCase().indexOf(term) > -1 || item.id.toLowerCase().indexOf(term) > -1;
  }

}
