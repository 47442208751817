<div class="sub-component-container">
  <div class="sub-title" *ngIf="title">
    {{ 'duration-title' | translate }} <span *ngIf="duration.submittedInSupo" class="duration-title-red">({{'duration-title-submitted-in-supo' | translate }})</span>
    <div *ngIf="deleteable" class="deleteIcon float-end" (click)="deleteClicked()">
      <img src="../../../../assets/icons/garbage.svg" />
    </div>
  </div>
  <div class="sub-content-container duration">
    <div class="row">
      <app-input-date-time
        class="col-3"
        [name]="'sub-duration-start-date' | translate"
        [date]="duration.startDate"
        [invalid]="startDateInvalid"
        [withImage]="true"
        [required]="!duration.startDate"
        [startAt]="startDateStartAt"
        [timeZone]="timeZone"
        (dateChange)="startDateChange($event)"
        (invalidChange)="startDateChange($event)"
        [disabled]="disableStartDate || notNeeded"
        [pickerDisabled]="disableStartDate"
      ></app-input-date-time>
      <app-input-date-time
        class="col-3"
        [name]="'sub-duration-end-date' | translate"
        [date]="duration.endDate"
        [withImage]="true"
        [required]="true"
        [startAt]="endDateStartAt"
        [timeZone]="timeZone"
        [minDate]="duration.startDate"
        (dateChange)="endDateChange($event)"
        (invalidChange)="endDateChange($event)"
        [disabled]="disableEndDate || notNeeded"
        [pickerDisabled]="disableEndDate"
      ></app-input-date-time>

      <app-input-calendar
        *ngIf="!hideGOPDates"
        class="gop-start col-2"
        [name]="gopPeriodTitle | translate"
        [required]="true"
        [semiRequired]="gopSemiRequired"
        [date]="duration.gopPeriodStartDate"
        [minDate]="minGopDate"
        [startAt]="minGopDate"
        [maxDate]="maxGopDate"
        [withImage]="true"
        (dateChange)="gopStartChange($event)"
        (invalidChange)="gopEndChange($event)"
        [disabled]="notNeeded"
      ></app-input-calendar>
      <div *ngIf="!hideGOPDates" class="input-separator"><span>-</span></div>
      <app-input-calendar
        *ngIf="!hideGOPDates"
        class="gop-end col-2"
        [name]="'   '"
        [required]="true"
        [semiRequired]="gopSemiRequired"
        [date]="duration.gopPeriodEndDate"
        [minDate]="minGopDate"
        [startAt]="minGopDate"
        [maxDate]="maxGopDate"
        [withImage]="true"
        (dateChange)="gopEndChange($event)"
        (invalidChange)="gopEndChange($event)"
        [disabled]="notNeeded"
      ></app-input-calendar>

      <app-input *ngIf="!hideDaysHours"
                 class="col-2"
                 [name]="'sub-duration-days-and-hours' | translate"
                 [value]="calculateDaysHours()"
                 [disabled]="true"
      ></app-input>
    </div>
    <div class="row">
      <app-input-location *ngIf="!hideLocation"
        class="col-12"
        [withImage]="true"
        [value]="duration.location"
        [allowEmpty]="true"
        [validationType]="ValidationTypeEnum.required"
        [name]="'sub-duration-location' | translate"
        (saveModel)="onLocationUpdate()"
        placeholder=""
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false"
        type="text"
        [disabled]="disableLocation || notNeeded"
      ></app-input-location>
    </div>
    <div class="row" *ngIf="!hideRoomAndRemark">
      <app-input
        class="col-4"
        [(value)]="duration.roomNumber"
        [name]="'sub-duration-room-number' | translate"
        [maxTextLength]="100"
        [disabled]="notNeeded"
      ></app-input>
      <app-input
        class="col-8"
        [(value)]="duration.internalRemarks"
        [name]="'sub-duration-internal-remark' | translate"
        [maxTextLength]="100"
        [disabled]="notNeeded"
      ></app-input>
    </div>
  </div>
</div>
