import { SavingTypedInField } from './enums';
import { BalanceSheetEntryReduction } from '@secca/case/components/case-plans/case-plans/add-service-order/models/interfaces';

export class SupplierInvoiceSaving implements BalanceSheetEntryReduction {
  id: number;
  reductionTypeCode: string;
  lineNumber: number;
  amount: number;
  percentage: number;
  totalAmount: number;
  typedInField: SavingTypedInField;
  gross: boolean;

  public constructor(init?: Partial<SupplierInvoiceSaving>) {
    Object.assign(this, init);
  }

  getAmount(): number {
    return this.amount;
  }

  getGross(): boolean {
    return this.gross;
  }

  getId(): number {
    return this.id;
  }
}
