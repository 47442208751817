import { Component, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { Limit } from 'src/app/shared/models/onepoint-limit';
import { LimitTypeEnum } from 'src/app/shared//models/enums';

@Component({
  selector: 'app-limits-view',
  templateUrl: './limits-view.component.html',
  styleUrls: ['./limits-view.component.scss']
})
export class LimitsViewComponent {

  @Input() limits: Limit[];
  @Output() closeLimits = new EventEmitter();

  get getLimitTypeEnum() {
    return LimitTypeEnum;
  }
  close() {
    this.closeLimits.emit();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.close();
  }
}
