<ng-container *ngIf="serviceOrderHoverModel" [ngSwitch]="serviceOrderHoverModel.serviceOrder.type">
    <div class="hover-container-content">
      <div class="hover-container-content-logo" [ngStyle]="logoStyle">
        <div class="hover-container-content-logo-image" [ngStyle]="imageStyle"></div>
        <div class="hover-container-content-logo-type-name">{{serviceOrderTypeName | titlecase}} <span>({{serviceOrderHoverModel.serviceOrder.serviceOrderId}})</span></div>
      </div>

      <app-medical-assessment-service-order-hover *ngSwitchCase="ServiceTypeEnum.MEDICAL_ASSESSMENT"
                                                  [serviceOrder]="serviceOrderHoverModel.serviceOrder"
                                                  [caseStakeholders]="caseStakeholder">
      </app-medical-assessment-service-order-hover>
      <app-major-incident-service-order-hover
        *ngSwitchCase="ServiceTypeEnum.MAJOR_INCIDENT"
        [serviceOrder]="serviceOrderHoverModel.serviceOrder"
        [providerName]="serviceOrderHoverModel.providerName"
        [agentName]="serviceOrderHoverModel.agentName"
        [incidentEvent]="serviceOrderHoverModel.incidentEvent"
        >
      </app-major-incident-service-order-hover>

      <app-base-service-order-hover *ngSwitchDefault
        [serviceOrder]="serviceOrderHoverModel.serviceOrder"
        [caseStakeholders]="caseStakeholder"
        [providerName]="serviceOrderHoverModel.providerName"
        [agentName]="serviceOrderHoverModel.agentName">
      </app-base-service-order-hover>
    </div>
  </ng-container>