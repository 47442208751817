import { Injectable } from '@angular/core';
import { Adapter } from '@secca/shared/interfaces/adapter';
import { TaskType, TaskStatus, ConsentStatusEnum, AssessmentTypeEnum, HandlingAreaStatusEnum, PatientType } from '@secca/shared/models/enums';
import { DateSerializer } from '@secca/shared/models/date-serializer';
import { DateHelperService } from '@secca/core/services/date-helper.service';
import * as moment from 'moment';
import { ScreeningStates } from '@secca/shared/enums/screening-states';

export class TaskViewModel {
  id: string;
  isCheck: boolean;
  taskType: TaskType;
  status: TaskStatus;
  channelType: string;
  dueDate: moment.Moment;
  doneDate: moment.Moment;
  receivedDate: moment.Moment;
  caseNumber: string;
  endUserFullName: string;
  reporterFullName: string;
  location: string;
  timeZoneId: string;
  timeOffsetInHours: number;
  title: string;
  description: string;
  internalSummary: string;
  reserve: string;
  currency: string;
  cause: string;
  incidentCause: string;
  caseHandler: string;
  assignInitials: string;
  caseId: number;
  customerProfileId: number;
  caseLockedBy: string;
  invoiceId: number;
  consentType: ConsentStatusEnum;
  incidentCoveredState: String;
  incidentCoveredSetByCaseHandler: ScreeningStates;
  daysToArrivalDate: String;
  customerProfileName: String;
  priority: number;
  prioritySort: number;
  arrivalDeadline: moment.Moment;
  readyFrom: moment.Moment;
  caseDetailsTBD: boolean;
  caseDetailsURN: boolean;
  caseDetailsCOF: boolean;
  caseDetailsAIR: boolean;
  caseDetailsSTR: boolean;
  caseDetailsEXT: boolean;
  caseDetailsSIT: boolean;
  caseDetailsREG: boolean;
  caseDetailsDOC: boolean;
  caseDetailsNUR: boolean;
  caseDetailsNME: boolean;
  caseDetailsOXY: boolean;
  caseDetailsEQU: boolean;
  medicalServiceorderType: String;
  winterSport: boolean;
  incidentEvent: String;
  incidentEventName: String;
  caseTypeCode: String;
  teamStatus: HandlingAreaStatusEnum;
  inpatientOutpatientOnCase: PatientType;
  inpatientOutpatientActive: boolean;


  public constructor(init?: Partial<TaskViewModel>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class TaskViewModelAdapter implements Adapter<TaskViewModel> {
  constructor(private dateHelperService: DateHelperService) {}

  adapt(item: any): TaskViewModel {
    return new TaskViewModel({
      id: item.id,
      isCheck: item.isCheck,
      taskType: item.type,
      channelType: item.channelType,
      dueDate: item.dueDate ? DateSerializer.deserialize(item.dueDate) : null,
      doneDate: item.doneDate ? DateSerializer.deserialize(item.doneDate) : null,
      receivedDate: item.receivedDate === '' ? null : DateSerializer.deserialize(item.receivedDate),
      caseNumber: item.caseNumber,
      endUserFullName: item.endUserFullName,
      reporterFullName: item.reporterFullName,
      location: item.location,
      timeZoneId: item.timeZoneId,
      timeOffsetInHours: item.timeOffsetInHours,
      title: item.title,
      description: item.description,
      internalSummary: item.internalSummary,
      reserve: item.reserve,
      currency: item.currency,
      cause: item.cause,
      incidentCause: item.incidentCause,
      caseHandler: item.caseHandler,
      assignInitials: item.assignInitials,
      status: item.status,
      caseId: item.caseId,
      customerProfileId: item.customerProfileId == null ? null : +item.customerProfileId,
      caseLockedBy: item.caseLockedBy,
      invoiceId: item.invoiceId,
      consentType: item.consentType,
      incidentCoveredState: item.incidentCoveredState,
      incidentCoveredSetByCaseHandler: item.incidentCoveredSetByCaseHandler,
      daysToArrivalDate: item.daysToArrivalDate !== null ? item.daysToArrivalDate : '?',
      customerProfileName: item.customerProfileName,
      priority: item.priority,
      prioritySort: item.prioritySort,
      arrivalDeadline: item.arrivalDeadline,
      readyFrom: item.readyFrom,
      caseDetailsTBD: item.caseDetailsTBD,
      caseDetailsURN: item.caseDetailsURN,
      caseDetailsCOF: item.caseDetailsCOF,
      caseDetailsAIR: item.caseDetailsAIR,
      caseDetailsSTR: item.caseDetailsSTR,
      caseDetailsEXT: item.caseDetailsEXT,
      caseDetailsSIT: item.caseDetailsSIT,
      caseDetailsREG: item.caseDetailsREG,
      caseDetailsDOC: item.caseDetailsDOC,
      caseDetailsNUR: item.caseDetailsNUR,
      caseDetailsNME: item.caseDetailsNME,
      caseDetailsOXY: item.caseDetailsOXY,
      caseDetailsEQU: item.caseDetailsEQU,
      medicalServiceorderType: item.medicalServiceorderType,
      winterSport: item.winterSport,
      incidentEvent: item.incidentEvent,
      incidentEventName: item.incidentEventName,
      caseTypeCode: item.caseTypeCode,
      teamStatus: item.teamStatus,
      inpatientOutpatientOnCase: item.inpatientOutpatientOnCase,
      inpatientOutpatientActive: item.inpatientOutpatientActive
    });
  }
}
