import { Component } from '@angular/core';
import { AutoUnsubscribe } from 'src/app/shared/decorators/auto-unsubscribe';
import { TaskStateService } from '../../services/task-state.service';
import { TaskHoverModel } from './task-hover-model';

@Component({
  selector: 'app-task-hover',
  templateUrl: './task-hover.component.html',
  styleUrls: ['./task-hover.component.scss']
})
@AutoUnsubscribe
export class TaskHoverComponent {
  taskHoverModel: TaskHoverModel;
  $subscr;
  taskHoverContainerStyle: any;
  taskHoverContrainerArrowButton: any;

  constructor(private taskStateService: TaskStateService) {
    this.$subscr = this.taskStateService.getHoveredTask().subscribe(result => {
      this.taskHoverModel = result;
      if (this.taskHoverModel) {
        this.taskHoverContainerStyle = {
          left: this.taskHoverModel.LeftHoverElemet + 'px',
          bottom: this.taskHoverModel.bottomHoverElement + 'px',
          width: this.taskHoverModel.width + 'px'
        };
        this.taskHoverContrainerArrowButton = {
          left: this.taskHoverModel.leftTrianglePosition + 'px'
        };
      }
    });
  }

  get htmlFormattedDescription(): string {
    return this.taskHoverModel.description?.replace(/(\r\n|\n|\r)/gm, '<br />');
  }

}
