import { StatusTypes } from "@secca/shared/models/enums";

export class MajorIncidentStakeholder {
  id: number;
  caseId: string;
  serviceOrderId: string | number;
  stakeholderId: string;

  public constructor(init?: Partial<MajorIncidentStakeholder>) {
    Object.assign(this, init);
  }

  isFilled() {
    return !!this.serviceOrderId || !!this.stakeholderId
  }
}

export class MajorIncidentStakeholderAdapter {
    adapt(item: any): MajorIncidentStakeholder {
      const majorIncidentStakeholder = new MajorIncidentStakeholder({
        caseId: item.caseId,
        stakeholderId: item.stakeholderId,
        serviceOrderId: item.serviceOrderId ? item.serviceOrderId : null,
      });
      return majorIncidentStakeholder;
    }
  }
