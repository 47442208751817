<div [class]="fixedHeight ? 'coverage-container fixed-height' : 'coverage-container'">
  <div class="errorMassage" *ngIf="coveragesBasic.length === 0 && coveragesSupplement.length === 0 && personInsurance.customerProduct !== null">
    No coverages on selected product.
  </div>
  <div class="errorMassage" *ngIf="personInsurance != null && isGroupProfile()">
    <app-drop-down-customer-profile
      [name]="'case-basics-insurance-customer-profile-group-member' | translate"
      [validationType]="validationTypeEnum"
      [(selectedItemId)]="selectedMemberProfile"
      (selectedItemIdChange)="checkIfMemberProfileChangeIsPossible()"
      [items]="memberProfiles"
      [disabled]="!financialStatusOpen || (!!personInsurance.insuranceLookupPolicy && this.selectedMemberProfile !== '')"
      [blankTitle]="'case-basics-insurance-coverage-view-awaiting-confirmation' | translate">
    </app-drop-down-customer-profile>
  </div>
  <div *ngIf="personInsurance != null && isGroupProfile()" class="separator"></div>
  <div class="errorMassage" *ngIf="personInsurance == null || personInsurance.customerProduct === null">
    No customer product selected.
  </div>
  <div class="table-container">
    <table *ngIf="coveragesBasic.length !== 0 || coveragesSupplement.length !== 0">
      <tbody>
        <tr>
          <td class="id-col"></td>
          <td class="name-col"></td>
          <td class="limit-col"></td>
          <td class="headline-col">{{ 'coverages-NO' | translate }}</td>
          <td class="headline-col">{{ 'coverages-TBD' | translate }}</td>
          <td class="headline-col">{{ 'coverages-YES' | translate }}</td>
        </tr>
        <tr>
          <td class="id-col">{{ 'coverages-id' | translate }}</td>
          <td class="name-col">{{ 'coverages-name' | translate }}</td>
          <td class="limit-col">{{ 'coverages-limit' | translate }}</td>
          <td class="included-col" colspan="3">{{ 'coverages-included' | translate }}</td>
        </tr>
        <tr *ngFor="let coverage of coveragesBasic; let i = index;">
          <td class="coverageLimit-col center-text">{{coverage.customerCoverageId}}</td>
          <td class="coverageLimit-col">{{ coverage.coverageName }}{{coverage.coverageNameAlias ? ' (' + coverage.coverageNameAlias + ')' : ''}}</td>
          <td class="coverageLimit-col" >
            <app-limit-description [limits]="coverage.limits"> </app-limit-description>
            <app-limits-view *ngIf="coverage.limits.length > 1 && getSelectedBasic() === i" [limits]="coverage.limits" (closeLimits)="close()">
            </app-limits-view>
            <img *ngIf="coverage.limits.length > 1" class="warning"
              src="/assets/icons/Warning.svg" (click)="setSelectedBasic(i)" />
          </td>
          <td class="icon-col"></td>
          <td class="icon-col"></td>
          <td class="icon-col"><img src="/assets/icons/Success.svg" alt="{{ 'coverages-YES' | translate }}"></td>
        </tr>
        <tr *ngIf="coveragesSupplement != null && coveragesSupplement.length !== 0">
          <td class="id-col"></td>
          <td class="name-col">{{ 'supplements-name' | translate }}</td>
          <td class="limit-col"></td>
          <td class="included-col" colspan="3"></td>
        </tr>
        <tr *ngFor="let coverage of coveragesSupplement; let i = index;">
          <td class="coverageLimit-col center-text">{{coverage.customerCoverageId}}</td>
          <td class="coverageLimit-col">{{ coverage.coverageName }}{{coverage.coverageNameAlias ? ' (' + coverage.coverageNameAlias + ')' : ''}}</td>
          <td class="coverageLimit-col">
            <app-limit-description [limits]="coverage.limits"> </app-limit-description>
            <app-limits-view *ngIf="coverage.limits.length > 1 && getSelectedSupplement() === i" [limits]="coverage.limits" (closeLimits)="close()">
            </app-limits-view>
            <img *ngIf="coverage.limits.length > 1" class="warning"
              src="/assets/icons/Warning.svg" (click)="setSelectedSupplement(i)" />
          </td>
          <td class="icon-col"></td>
          <td class="icon-col"><img *ngIf="'TBD' === coverage.included" src="/assets/icons/Questionmark_yellow.svg" alt="{{ 'coverages-TBD' | translate }}"></td>
          <td class="icon-col"><img *ngIf="'YES' === coverage.included" src="/assets/icons/Success.svg" alt="{{ 'coverages-YES' | translate }}"></td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="displayGrayBackground" (click)="close()" class="grayBackground"></div>
  </div>
</div>
