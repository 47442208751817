<div *ngIf="stakeholders">
  <ul ngbNav #nav="ngbNav" class="service-order-type-tabset nav-tabs" [(activeId)]="activeTab"
    (navChange)="tabChange($event)">
    <li [id]="SelectedTab.ExpectationTab" [ngbNavItem]="SelectedTab.ExpectationTab">
      <a ngbNavLink>{{ 'service-type-expectation' | translate }}</a>
      <ng-template ngbNavContent>
        <div [appDisableInput]="caseLocked" class="caselock-no-events">
        <div class="sub-component-placeholder row">
          <div class="col-5">
            <app-sub-transport-type [(subTranportType)]="groundTransport.transportType" [hideInnerBorder]="true"
              (transportTypeChanged)="transportTypeChanged($event)">
            </app-sub-transport-type>
          </div>
          <div class="col-7 commitment-container">
            <app-sub-commitment-type [serviceOrderType]="serviceOrderType"
              [(commitmentType)]="groundTransport.commitmentType" [status]="status" [caseId]="caseId"
              ></app-sub-commitment-type>
          </div>
        </div>
        <div class="sub-component-placeholder">
          <app-sub-travellers
            [hideCabinClass]="true"
            [hideFlightInRefs]="true"
            [hideAddFlightLeg]="true"
            [caseId]="caseId"
            [stakeholderType]="StakeholderTypeEnum.person"
            [(travellers)]="groundTransport.travellers"
            [stakeholders]="stakeholders"
            [(subStakeholders)]="groundTransport.subStakeholders"
            [subStakeholdersConfig]="subStakeholdersConfig"
            [arrivalDateRequired]="true"
            [departureDateRequired]="true"
            [arrivalAtRequired]="true"
            [departureFromRequired]="true">
          </app-sub-travellers>
        </div>
        <div class="sub-component-placeholder"
          *ngIf="groundTransport.transportType.transportType === TransportTypeEnum.FUNERAL_CAR">
          <app-sub-coffin-urn [(subCoffinUrn)]="groundTransport.coffinUrn" [onlyCoffinAndUrn]="true">
          </app-sub-coffin-urn>
        </div>
        <div class="sub-component-placeholder">
          <app-sub-remarks-to-supplier [(subRemarksToSupplier)]="groundTransport.remarksToSupplier">
          </app-sub-remarks-to-supplier>
        </div>
        <div>
          <app-gop-send-request [serviceOrderExtension]="groundTransport"
            [serviceOrderTypeState]="serviceOrderTypeState">
          </app-gop-send-request>
        </div>
        </div>
      </ng-template>
    </li>
    <li *ngIf="serviceOrderTypeState.showConfirmationTab" [id]="SelectedTab.ConfirmationTab"
      [ngbNavItem]="SelectedTab.ConfirmationTab">
      <a ngbNavLink>{{ 'service-type-confirmation' | translate }}
        <div class="close-tab-x" (click)="closeConfirmationTab($event)"
          *ngIf="!serviceOrderTypeState.isConfirmationTabSaved">
          <img src="/assets/icons/Close Black.svg" />
        </div>
      </a>
      <ng-template ngbNavContent>
        <div [appDisableInput]="caseLocked" class="caselock-no-events">
        <div class="sub-component-placeholder">
          <app-sub-transport-type (transportTypeChanged)="transportTypeChanged()" [readonly]="true"
            [hideGetButton]="true" [(subTranportType)]="groundTransport.transportType">
          </app-sub-transport-type>
        </div>
        <div class="sub-component-placeholder">
          <app-sub-reference [(subReference)]="groundTransport.reference"></app-sub-reference>
        </div>
        <div class="sub-component-placeholder">
          <app-sub-travellers
            [hideCabinClass]="true"
            [hideFlightInRefs]="true"
            [hideAddFlightLeg]="true"
            [caseId]="caseId"
            [stakeholderType]="StakeholderTypeEnum.person"
            [(travellers)]="groundTransport.travellers"
            [stakeholders]="stakeholders"
            [(subStakeholders)]="groundTransport.subStakeholders"
            [subStakeholdersConfig]="subStakeholdersConfig"
            [arrivalDateRequired]="true"
            [departureDateRequired]="true"
            [arrivalAtRequired]="true"
            [departureFromRequired]="true">
          </app-sub-travellers>
        </div>
        <div class="sub-component-placeholder"
          *ngIf="groundTransport.transportType.transportType === TransportTypeEnum.FUNERAL_CAR">
          <app-sub-coffin-urn [(subCoffinUrn)]="groundTransport.coffinUrn" [onlyCoffinAndUrn]="true"
            [readOnlyMode]="true">
          </app-sub-coffin-urn>
        </div>
        <div class="sub-component-placeholder"
          *ngIf="groundTransport.transportType.transportType === TransportTypeEnum.FUNERAL_CAR">
          <app-sub-airway-bill [subAirwayBill]="groundTransport.airwayBill"></app-sub-airway-bill>
        </div>
        <div class="sub-component-placeholder">
          <app-sub-remarks-from-supplier [subRemarksFromSupplier]="groundTransport.remarksFromSupplier">
          </app-sub-remarks-from-supplier>
        </div>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</div>
