<div class="notes-container">
  <ul ngbNav #nav="ngbNav" class="nav-tabs">
    <li ngbNavItem>
      <a ngbNavLink>
        <div>{{ 'case-summary-summary' | translate }}</div>
      </a>
      <ng-template ngbNavContent>
        <app-case-summary-filter [searchFilter]="summarySearchFilter" [case]="case" [users]="users" [typeItems]="typeItems"
                                 [pagingInfo]="summaryPagingInfo" ></app-case-summary-filter>
        <app-case-summary-list [case]="case" [stakeholders]="stakeholders" [consent]="consent" (sortChangeEvent)="sortSummaryChanged($event)"
        [numberOfLinkedCases]="numberOfLinkedCases" (channelTypeChanged)="channelTypeUpdated($event)"></app-case-summary-list>
        <app-pagination [currentPageIndex]="summaryPagingInfo.pageIndex" [totalNumber]="summaryPagingInfo.totalNumber"
                        [pageSize]="summaryPagingInfo.pageSize"
                        (pagingChangedEvent)="summaryPaginationChanged($event)"></app-pagination>
      </ng-template>
    </li>
    <li ngbNavItem>
      <a ngbNavLink>
        <div>{{ 'case-summary-full-details' | translate }}</div>
      </a>
      <ng-template ngbNavContent>
        <app-case-history-filter [searchFilter]="historySearchFilter" [case]="case" [queryMode]="'FULL_DETAILS'" [users]="users" [objectItems]="historyObjectItems"
                                 [pagingInfo]="historyPagingInfo" ></app-case-history-filter>
        <app-case-history-list [case]="case" (sortChangeEvent)="sortHistoryChanged($event)"></app-case-history-list>
        <app-pagination [currentPageIndex]="historyPagingInfo.pageIndex" [totalNumber]="historyPagingInfo.totalNumber"
                        [pageSize]="historyPagingInfo.pageSize"
                        (pagingChangedEvent)="historyPaginationChanged($event)"></app-pagination>
      </ng-template>
    </li>
    <li ngbNavItem>
      <a ngbNavLink>
        <div>{{ 'case-summary-economy' | translate }}</div>
      </a>
      <ng-template ngbNavContent>
        <app-case-history-filter [searchFilter]="economySearchFilter" [case]="case" [queryMode]="'ECONOMY'" [users]="users" [objectItems]="economyObjectItems"
                                 [pagingInfo]="historyPagingInfo" ></app-case-history-filter>
       <app-case-economy-history-list [case]="case" [dateRange]="economySearchFilter.dateRange" [idSearch]="economySearchFilter.searchText" [users]="users" [objectItem]="economySearchFilter.objectItem"  [subObjectItems]="economySearchFilter.subObjectItems" [userCriteria]="economySearchFilter.users"></app-case-economy-history-list>
        <app-pagination [currentPageIndex]="historyPagingInfo.pageIndex" [totalNumber]="historyPagingInfo.totalNumber"
                        [pageSize]="historyPagingInfo.pageSize"
                        (pagingChangedEvent)="historyPaginationChanged($event)"></app-pagination>
      </ng-template>
    </li>
    <li ngbNavItem>
      <a ngbNavLink>
        <div>{{ 'case-summary-system-events' | translate }}</div>
      </a>
      <ng-template ngbNavContent>
        <app-case-history-filter [searchFilter]="systemEventSearchFilter" [case]="case" [queryMode]="'SYSTEM_EVENTS'" [users]="users" [objectItems]="systemEventObjectItems"
                                 [pagingInfo]="historyPagingInfo" ></app-case-history-filter>
        <app-case-history-list [case]="case"  (sortChangeEvent)="sortHistoryChanged($event)"></app-case-history-list>
        <app-pagination [currentPageIndex]="historyPagingInfo.pageIndex" [totalNumber]="historyPagingInfo.totalNumber"
                        [pageSize]="historyPagingInfo.pageSize"
                        (pagingChangedEvent)="historyPaginationChanged($event)"></app-pagination>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</div>
