import {Injectable} from '@angular/core';
import {Adapter} from '../../../../../shared/interfaces/adapter';

export class MessageBody {
  htmlBody: string;

  public constructor(init?: Partial<MessageBody>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class MessageBodyAdapter implements Adapter<MessageBody> {
  adapt(item: any): MessageBody {
    return new MessageBody({
      htmlBody: item.htmlBody
    });
  }
}
