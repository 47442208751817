import { SortOrder } from './enums';

export class TableSort {
  sortBy: string;
  sortOrder: SortOrder;

  public constructor(init?: Partial<TableSort>) {
    Object.assign(this, init);
  }
}
