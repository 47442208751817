<div class="drop-menu">
  <div class="common-drop-down-arrow drop-down">
    <ng-select
      #dropmenu
      [items]="items"
      [class]="'action-menu'"
      [searchable]="false"
      [closeOnSelect]="true"
      [dropdownPosition]="dropdownPosition"
      [appendTo]="appendTo"
      (change)="onChange($event)"
      [(ngModel)]="selectedItem"
    >
      <ng-template ng-option-tmp let-item="item" let-index="index">
        <ng-container [ngTemplateOutlet]="item"></ng-container>
      </ng-template>
    </ng-select>
    <div *ngIf="!!label" class="btn-group" [ngClass]="{'dropup': dropdownPosition}">
      <button (click)="dropmenu.open()" type="button" class="primary-button dropdown-toggle" [ngClass]="{'primary-button-inactive': disabled}" [disabled]="disabled">
        {{ label }}
      </button>
    </div>
  </div>
</div>