<div class="modal-content">
  <div class="modal-header">
    <div class="modal-header-title">
      View {{ getChannelTypeName(channelType) }}
    </div>
    <div class="modal-buttons">
      <div (click)="close()">
        <img src="/assets/icons/Close White.svg" />
      </div>
    </div>
  </div>
  <div class="modal-content-body">
    <div class="message-details">
    <app-task-web-modal-main-panel [taskDataViewModel]="taskDataViewModel"
                                   [serviceItemList]="serviceItemList" [purposeOfTravelItemList]="purposeOfTravelItemList" 
                                   [causes]="causes" [diagnoses]="diagnoses" [customerProfiles]="customerProfiles"
                                   [channels]="channels"
                                   [hideSearchCreateButton]="true" (closed)="close()"></app-task-web-modal-main-panel>
    </div>
    <div class="horizontal-line"></div>
    <div class="row">
      <div class="col-12">
        <div [appDisableInput]="caseLockHelperService.isCaseBasicLockTaken || permissionService.isReadOnly">
          <button class="primary-button save-button" [disabled]="!internalSummaryHasBeenUpdated" (click)="save()"
            [class.primary-button-inactive]="!internalSummaryHasBeenUpdated">
            {{ 'task-web-modal-internal-summary-save' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
