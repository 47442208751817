import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { Subject } from 'rxjs';
import { Note } from 'src/app/shared/models/note';

@Directive()
@Injectable()
export class NoteService {
  @Output() editedNote: EventEmitter<Note> = new EventEmitter();

  editedNoteUpdated: Subject<{ id: number; note: Note }> = new Subject<{ id: number; note: Note }>();

  send(note: Note) {
    this.editedNote.emit(note);
  }

  editedNoteUpdatedSend({ id: number, note: Note }) {
    this.editedNoteUpdated.next({ id: number, note: Note });
  }
}
