import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PendingTasksListUpdateService {

  private pendingTasksListHasChanged = new BehaviorSubject<boolean>(undefined);

  public setPendingTasksHasChange() {
    this.pendingTasksListHasChanged.next(true);
  }

  public getPendingTasksHasChange(): Observable<boolean> {
    return this.pendingTasksListHasChanged.asObservable();
  }

}
