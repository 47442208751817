import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BalanceSheetEntryEnum } from '@secca/shared/models/enums';


@Component({
  selector: 'app-economy-status-bar',
  templateUrl: './economy-status-bar.component.html',
  styleUrls: ['./economy-status-bar.component.scss'],
})
export class EconomyStatusBarComponent implements OnInit {
  @Input() numberOfStatus = 2;
  @Input() prefixWithHidden = 0;
  @Input() cancelled: boolean = false;
  @Input() notCovert: boolean = false;
  @Input() statusText: string;
  @Input() histotyType: BalanceSheetEntryEnum;
  @Input() statusIndex = 0;
  @Input() redIconIndex: number;
  @Input() warnIconIndex: number;
  @Input() redIconText: string;
  @Input() aggregatedStatus: boolean;
  @Input() invoiceNumber: string;
  @Input() greenIndexHistoryMap: Map<number, any>;
  @Input() redIndexHistoryMap: Map<number, any>;
  @Input() maxStatus: number = 9;



  popOverDisabled(index: number): boolean {
    return !this.invoiceNumber || !(this.popOverGetModifiedInfo(index)?.length > 0);
  }

  get BalanceSheetEntryEnum() {
    return BalanceSheetEntryEnum
  }

  popOverGetStatus(index: number): string {
    if (index === this.redIconIndex) {
      return this.redIndexHistoryMap.get(index)?.status;
    } else {
      return this.greenIndexHistoryMap.get(index)?.status;
    }
  }

  popOverGetModifiedInfo(index: number): any[] {
    if (index === this.redIconIndex) {
      return this.redIndexHistoryMap.get(index)?.modifyHistory;
    } else {
      return this.greenIndexHistoryMap.get(index)?.modifyHistory;
    }
  }

  getNumberOfStatus(): number{
    return this.numberOfStatus+this.prefixWithHidden;
  }

  getNotCovertAndCancelledTest() {
    if(this.notCovert) {
      return this.translateService.instant('economy-service-orders-status-NOTCOVERT');
    } else if(this.cancelled) {
      return this.translateService.instant('economy-service-orders-status-CANCELLED');
    } else {
      return '';
    }
  }

  constructor(private translateService: TranslateService,) {}

  ngOnInit() {}
}
