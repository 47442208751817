import { Injectable } from '@angular/core';
import { Adapter } from '../../../../../shared/interfaces/adapter';
import { DateFormatter } from '../../../../../shared/helpers/date-formatter';
import { ServiceTypeEnum } from '@secca/shared/models/enums';
import * as moment from 'moment';
import { DateSerializer } from '@secca/shared/models/date-serializer';

export class SupplierInvoiceTaskViewModel {
  id: number;
  caseId: number;
  isSelected: boolean;
  type: string;
  customerProfileId: number;
  receivedDate: moment.Moment;
  invoiceDate: moment.Moment;
  dueDate: moment.Moment;
  invoiceGroup: string;
  serviceOrderId: string;
  serviceOrderType: ServiceTypeEnum;
  creditorId: string;
  creditorName: string;
  invoiceId: number;
  invoiceNumber: string;
  invoiceAmount: string;
  invoiceAmountCurrency: string;
  invoiceStatus: string;
  assignedTo: string;

  public constructor(init?: Partial<SupplierInvoiceTaskViewModel>) {
    Object.assign(this, init);
  }

  get remaining() {
    const remainingDays = this.dueDate.diff(moment().startOf('day'), 'days');
    return DateFormatter.formatToNumberOfDays(remainingDays);
  }
}

@Injectable({
  providedIn: 'root',
})
export class SupplierInvoiceTaskViewModelAdapter implements Adapter<SupplierInvoiceTaskViewModel> {
  adapt(item: any): SupplierInvoiceTaskViewModel {
    const serviceOrderType: ServiceTypeEnum = ServiceTypeEnum[String(item.serviceOrderType)];
    return new SupplierInvoiceTaskViewModel({
      id: item.id,
      caseId: item.caseId,
      type: item.type,
      customerProfileId: item.customerProfileId == null ? null : +item.customerProfileId,
      receivedDate: item.receivedDate === '' ? null : DateSerializer.deserialize(item.receivedDate),
      invoiceDate: item.invoiceDate === '' ? null : DateSerializer.deserialize(item.invoiceDate),
      dueDate: item.dueDate === '' ? null : DateSerializer.deserialize(item.dueDate),
      invoiceGroup: item.invoiceGroup,
      serviceOrderId: item.serviceOrderId,
      serviceOrderType,
      creditorId: item.creditorId,
      creditorName: item.creditorName,
      invoiceId: item.invoiceId,
      invoiceNumber: item.invoiceNumber,
      invoiceAmount: item.invoiceAmount,
      invoiceAmountCurrency: item.invoiceCurrency,
      invoiceStatus: item.invoiceStatus,
      assignedTo: item.assignedTo,
    });
  }
}
