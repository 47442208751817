export class ProviderSearch {
  caseNumber: string;
  caseId: string;
  stakeholderId: string;
  webUser: string;
  environment?: string;
  clientLocation: Clientlocation;
  searchParameters?: SearchParameters;
  integrationInformation: IntegrationInformation;

  public constructor(init?: Partial<ProviderSearch>) {
    Object.assign(this, init);
  }
}

class Clientlocation {
  address?: string;
  postalCode?: string;
  cityName: string;
  country: Country;
}

class SearchParameters {
  providerType?: string;
  name: string;
  service: string;
  contactDetail: string;
  city: string;
  countryCode: string;
}

class IntegrationInformation {
  showIntegration: boolean;
  WebfileWaitForBrowser: boolean;
  showSearch: boolean;
  textHeader: string;
  textBody: string;
}

class Country {
  name: string;
}


