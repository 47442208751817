import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CaseService } from 'src/app/core/services/case.service';
import { CaseStakeholder } from '../../models/caseStakeholder';
import { CasePrimaryContactService } from 'src/app/modules/case/components/case-primary-contact/case-primary-contact.service';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { EndUserStateService } from '@secca/core/state-services/enduser-state-service';
import { Subscription } from 'rxjs';
import { InsuranceService } from '@secca/core/services/insurance.service';

@Component({
  selector: 'app-icon-chekbox-row',
  templateUrl: './icon-chekbox-row.component.html',
  styleUrls: ['./icon-chekbox-row.component.scss'],
})
export class IconChekboxRowComponent implements OnInit {
  mouseoverEndUserIconOnPolicyHolder = false;
  mouseoverEndUserIconOnReporter = false;
  mouseoverPolicyHolderIconOnEndUser = false;
  mouseoverPolicyHolderIconOnReporter = false;
  mouseoverReporterIconOnPolicyHolder = false;
  mouseoverReporterIconOnEndUser = false;
  mouseoverLegalGuardianIcon = false;
  mouseoverCotravelerIcon = false;
  mouseoverPrimaryContactChangeIcon = false;
  private _caseStakeholder: CaseStakeholder;
  private _caseId: string;
  private working = false;
  @Output() refresh: EventEmitter<any> = new EventEmitter();

  @Input()
  set caseId(newCaseId: string) {
    if (newCaseId !== undefined) {
      this._caseId = newCaseId;
      if (this.insuranceSubscription) {
        this.insuranceSubscription.unsubscribe();
      }
      this.insuranceSubscription = this.insuranceService.getPersonInsurance(this.caseId).subscribe(personInsurance => {
        this.creditCardOrCustomerProductSelected = personInsurance.creditCard || Boolean(personInsurance.customerProduct);
      });
    }
  }

  get caseId(): string {
    return this._caseId;
  }

  @Input()
  set caseStakeholder(newCaseStakeholder: CaseStakeholder) {
    if (newCaseStakeholder !== undefined) {
      this._caseStakeholder = newCaseStakeholder;
    }
  }

  get caseStakeholder(): CaseStakeholder {
    return this._caseStakeholder;
  }

  @Input() type: string;

  private insuranceSubscription: Subscription;
  creditCardOrCustomerProductSelected: boolean;

  constructor(
    private caseService: CaseService,
    private primaryContactService: CasePrimaryContactService,
    private caseStateService: CaseStateService,
    private endUserStateService: EndUserStateService,
    private insuranceService: InsuranceService
  ) {}

  ngOnInit() {}

  onPrimaryContactClick(pop: NgbPopover) {
    this.updatePrimaryContact();
    pop.open();
  }

  updatePrimaryContact() {
    this.caseStakeholder.primaryContact = !this.caseStakeholder.primaryContact;
    this.caseService.updatePrimaryContact(this.caseId, this.caseStakeholder.id).subscribe(
      result => {
        this.caseStakeholder = result;
        this.refresh.emit();
        this.updatePrimaryContactWidget();
      },
      error => console.log(error)
    );
  }

  updateLegalGuardian() {
    this.caseService.updateLegalGuardian(this.caseId, this.caseStakeholder.id).subscribe(
      result => {
        this.caseStakeholder = result;
        this.caseStateService.sendLegalGuardian();
        this.refresh.emit();
        this.updatePrimaryContactWidget();
      },
      error => console.log(error)
    );
  }

  updateCotraveler() {
    this.caseService.updateCoTraveler(this.caseId, this.caseStakeholder.id).subscribe(
      result => {
        this.caseStakeholder = result;
        this.refresh.emit();
        this.updatePrimaryContactWidget();
      },
      error => console.log(error)
    );
  }

  toggleStakeholderMerge(mergeWithType: string, merge: boolean) {
    this.working = true;
    if (merge) {
      this.caseService.mergeStakeholders(this.caseId, this.type, mergeWithType).subscribe(
        result => {
          this._caseStakeholder = result;
          if (mergeWithType === 'POLICYHOLDER') {
            this.caseService.publishPolicyholder(this.caseStakeholder);
          }
          if (mergeWithType === 'END_USER') {
            this.updateLegalGuardian();
            this.endUserStateService.sendEndUser(result);
          }
          this.caseStateService.updatedStakeholder(result);
          this.refresh.emit();
          this.working = false;
        },
        error => {
          this.working = false;
          throw error;
        });
    } else {
      this.caseService.unmergeStakeholders(this.caseId, this.type, mergeWithType).subscribe(
        result => {
          this.refresh.emit();
          this.caseService
            .getStakeholderForCase(this.caseId, mergeWithType)
            .subscribe(policyholder => {
              if (mergeWithType === 'POLICYHOLDER') {
                this.caseService.publishPolicyholder(policyholder);
              }
              this.caseStateService.updatedStakeholder(policyholder);
              this.working = false;
            });
        },
        error => {
          this.working = false;
          throw error;
        }
      );
    }
    this.updatePrimaryContactWidget();
  }

  allowEdit() {
    return this.type.toUpperCase() === this.caseStakeholder.stakeholderType && !this.working;
  }

  updatePrimaryContactWidget() {
    this.primaryContactService.updatePrimary(this.type, this.caseStakeholder);
  }

  similarTo(stakeholderType: string): boolean {
    if (this.caseStakeholder.similarStakeholders == null) {
      return false;
    } else {
      return this.caseStakeholder.similarStakeholders.includes(stakeholderType);
    }
  }
}
