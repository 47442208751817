import { CaseStakeholder } from './../../shared/models/caseStakeholder';
import { TaskViewModel } from '@secca/board/components/board-task/models/task-view.model';
import { map } from 'rxjs/operators';
import { TaskAdapter } from './../../shared/models/task';
import { Observable, EMPTY } from 'rxjs';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { Task } from 'src/app/shared/models/task';
import { TaskCaseViewOptions } from 'src/app/shared/models/task-case-view-options';
import { CaseLockHttpService } from './case-lock-http.service';
import {
  IncomingCasesTaskViewModel,
  IncomingCasesTaskViewModelAdapter,
} from '@secca/board/components/board-task/models/incoming-cases-task-view-model';
import { TaskRedirectFromGlobalTaskBoard } from '@secca/shared/models/task-redirect-from-global-task-board';
import { omit } from 'lodash-es';
import { TaskStatus, TaskType } from '@secca/shared/models/enums';
import { CustomerProfile } from '@secca/shared/models/customerProfile';

@Injectable({
  providedIn: 'root',
})
export class TaskService extends BaseService {
  public taskRedirectFromGlobalTaskBoard: TaskRedirectFromGlobalTaskBoard;
  public taskCaseViewOptions: TaskCaseViewOptions;

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private taskAdapter: TaskAdapter,
    private incomingWebTaskAdapter: IncomingCasesTaskViewModelAdapter,
    private caseLockHttpService: CaseLockHttpService
  ) {
    super(settingsService);
  }

  public createTask(task: Task) {
    return this.http.post<any[]>(this.baseURL + 'task/create', task, {
      headers: this.jsonHeaders,
    });
  }

  public updateTask(task: Task): Observable<Task> {
    task = new Task(omit(task, ['createdOn']));

    return this.caseLockHttpService
      .put<Task>(this.baseURL + 'task/update', task, {
        headers: this.jsonHeaders,
      })
      .pipe(map(item => this.taskAdapter.adapt(item)));
  }

  public getTask(taskId: number): Observable<Task> {
    return this.http.get(this.baseURL + 'task/' + taskId).pipe(map(item => this.taskAdapter.adapt(item)));
  }

  public getWebTaskById(taskId: number): Observable<IncomingCasesTaskViewModel> {
    return this.http
      .get<IncomingCasesTaskViewModel>(this.baseURL + 'task/web-application-tasks/' + taskId)
      .pipe(map(item => this.incomingWebTaskAdapter.adapt(item)));
  }

  public getWebTaskByCaseId(caseId: number): Observable<IncomingCasesTaskViewModel[]> {
    return this.http
      .get<IncomingCasesTaskViewModel[]>(this.baseURL + `task/web-application-tasks/${caseId}/by-case-id`)
      .pipe(map((data: IncomingCasesTaskViewModel[]) => data.map(item => this.incomingWebTaskAdapter.adapt(item))));
  }

  public reassignTasksToTeam(taskIds: string[], adtCode: string) {
    return this.http.post(this.baseURL + 'task/reassign/' + taskIds + '/team/' + adtCode, { headers: this.jsonHeaders });
  }
  public reassignTasksToUser(taskIds: string[], userId: number) {
    return this.http.post(this.baseURL + 'task/reassign/' + taskIds + '/user/' + userId, { headers: this.jsonHeaders });
  }
  public moveTask(taskId: number, caseNumber): Observable<Task> {
    return this.caseLockHttpService.post<any>(this.baseURL + 'task/move' + '/' + taskId + '/' + caseNumber, { headers: this.jsonHeaders });
  }

  public moveSupplierInvoice(taskId: number, caseNumber: string): Observable<Task> {
    return this.http
      .post(this.baseURL + 'task/move-supplier-invoice' + '/' + taskId + '/' + caseNumber, { headers: this.jsonHeaders })
      .pipe(map(item => this.taskAdapter.adapt(item)));
  }

  public getTaskCountByType(taskType: TaskType, taskStatus: TaskStatus): Observable<number> {
    return this.http.get<any>(this.baseURL + 'task/count/' + taskType + '/' + taskStatus);
  }

  public createMedicalReportFromTask(
    taskViewmodel: IncomingCasesTaskViewModel,
    assignedReporter: CaseStakeholder,
    switchReporterOneCase: boolean
  ): Observable<Task> {
    const createMedicalReportFromTaskRequest = { taskId: taskViewmodel.id, assignedReporterId: assignedReporter.id, switchReporterOneCase };
    return this.http.post<Task>(this.baseURL + 'task/create-medical-report', createMedicalReportFromTaskRequest, {
      headers: this.jsonHeaders,
    });
  }

  public getCustomerProfiles(applicationType: string): Observable<CustomerProfile[]> {
    return this.http
      .get<any>(this.baseURL + 'application/application-type/' + applicationType)
      .pipe(
        map((data: any[]) =>
          data.map(
            item =>
              new CustomerProfile({
                commonCustomerName: item.commonCustomerName,
                brandName: item.brandName,
                brandId: item.brandId,
                profileId: item.profileId,
                profileCode: item.code,
                profileName: item.profileName,
              })
          )
        )
      );
  }
}
