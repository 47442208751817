import { Component, OnInit } from '@angular/core';
import { SettingsService } from '@secca/core/services/settings.service';
import * as moment from 'moment';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  isProduction: boolean;
  isTest: boolean;
  constructor(private settingsService: SettingsService) {}

  ngOnInit() {
    this.isProduction = this.settingsService.isProduction();
    this.isTest = this.settingsService.isTest();
  }

  showSnow(): boolean {
    return [0, 11].includes(moment().month()) && !this.isProduction;
  }
}
