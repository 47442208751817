<div class="supplier-invoice-container">
  <div class="modal-header">
    <div class="modal-header-title">
      {{ 'manage-supplier-invoice-title' | translate }}
    </div>
    <div class="modal-buttons">
      <div (click)="close()">
        <img src="/assets/icons/Close White.svg" alt="Close Manage Invoice"/>
      </div>
    </div>
  </div>
  <div class="supplier-invoice-modal-shadow">
    <app-case-lock-overlay [lockContext]="LockContextEnum.SUPPLIER_INVOICE" [contextId]="supplierInvoiceId"
      [asOverlay]="false" [unlockPostAction]="CaseLockOverlayAction.TAKE_LOCK"
      [caseClosed]="caseStateService.isCaseClosed"></app-case-lock-overlay>
    <div class="supplier-invoice-modal-header">
      <div
        [appDisableInput]="caseLockHelperService.isSupplierInvoiceLockTaken(supplierInvoiceId) || permissionService.isReadOnly"
        class="caselock-no-events">

        <div class="container-fluid">
          <div class="d-flex align-items-center justify-content-between">
            <div class="col-auto ps-3 pe-3">
              <div class="status-error" *ngIf="supplierInvoice.invoiceStatus === SupplierInvoiceStatus.error && !!supplierInvoice.supplierInvoiceError">
                {{ supplierInvoice.supplierInvoiceError}}
              </div>
              <div [ngStyle]="autoApprovalGroupStyles()" *ngIf="supplierInvoice.invoiceStatus !== SupplierInvoiceStatus.error">
                <div class="group-box-title">Auto Approval</div>
                <div class="container group-box-content">
                  <div class="row align-items-baseline no-gutters">

                    <div class="col-auto ps-3 pe-3">
                      <button class="reject-btn" placement="bottom" (click)="resendApproval()"
                              [disabled]="!supplierInvoiceAutoApprovalDto?.publishDate"
                              [ngClass]="{ 'reject-btn-disabled': !supplierInvoiceAutoApprovalDto?.publishDate }">
                        {{ supplierInvoiceAutoApprovalDto?.publishDate ? ('manage-supplier-invoice-auto-approval-resend' | translate) : ('manage-supplier-invoice-auto-approval-resend-requested' | translate) }}
                      </button>
                    </div>

                    <div class="col-auto ps-3 pe-3">
                    <span *permission="PermissionEnum.SUPPLIER_INVOICE_UPDATE">
                      <button class="reject-btn" placement="bottom" (click)="markAutoApprovalAsCorrect()"
                              [disabled]="!publishedAutoApprovalExists()"
                              [ngClass]="{ 'reject-btn-disabled': !publishedAutoApprovalExists() }">
                        {{ ('manage-supplier-invoice-auto-approval-correct' | translate) }}
                      </button>
                    </span>
                    </div>

                    <div class="col-auto ps-3 pe-3">
                    <span *permission="PermissionEnum.SUPPLIER_INVOICE_UPDATE">
                      <button class="reject-btn" placement="bottom" (click)="markAutoApprovalAsIncorrect()"
                              [disabled]="!publishedAutoApprovalExists()"
                              [ngClass]="{ 'reject-btn-disabled': !publishedAutoApprovalExists() }">
                        {{ ('manage-supplier-invoice-auto-approval-incorrect' | translate) }}
                      </button>
                    </span>
                    </div>

                    <div class="col-auto supplier-info ps-3 pe-3" *ngIf="supplierInvoice">
                      <span class="font-bold">{{ 'manage-supplier-invoice-approval-score' | translate }}:</span>
                      <ng-template #popContent><span [innerText]="supplierInvoiceAutoApprovalDto?.scoreMap"></span></ng-template>
                      <span *ngIf="hasAutoApprovalStatusAndScore()"
                            placement="bottom" container="body" triggers="hover"
                            [ngbPopover]="popContent"
                            popoverClass="supplier-invoice-score-map-details">
                      {{ supplierInvoiceAutoApprovalDto?.approvalScore }}
                    </span>
                    </div>

                    <div class="col-auto ps-3 pe-3 align-self-center" *ngIf="shouldShowAutoApprovalCorrect()">
                      <img src="/assets/icons/Success.svg" alt="Auto approval is correct" class="icon" />
                    </div>

                    <ng-template #popContent><span [innerText]="supplierInvoiceAutoApprovalDto?.incorrectComment"></span></ng-template>
                    <div class="col-auto ps-3 pe-3 align-self-center" *ngIf="shouldShowAutoApprovalIncorrect()"
                         placement="bottom" container="body" triggers="hover" [ngbPopover]="popContent"
                         popoverClass="supplier-invoice-score-map-details">
                      <img src="/assets/icons/Failure.svg" alt="Auto approval is correct" class="icon" />
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div *ngIf="supplierInvoice" class="supplier-info">
              <div class="col-auto">

                <div class="container-fluid">
                  <div class="row justify-content-end">

                    <div class="col-auto ps-3 pe-3">
                      <div *ngIf="showRetryCount()" class="supplier-info-item">
                        <div>{{ 'manage-supplier-invoice-retry-count' | translate }}</div>
                        <span>{{ supplierInvoice.supplierInvoiceRetryCount }}</span>
                      </div>
                    </div>

                    <div class="col-auto ps-3 pe-3">
                      <div *ngIf="supplierInvoice.submittedByInSupo" class="supplier-info-item">
                        <div>{{ 'manage-supplier-invoice-supo-submitter' | translate }}</div>
                        <span>{{ supplierInvoice.submittedByInSupo }}</span>
                      </div>
                    </div>

                    <div class="col-auto ps-3 pe-3">
                      <div *ngIf="supplierInvoice.invoiceNumber" class="supplier-info-item">
                        <div>{{ 'manage-supplier-invoice-invoice-number' | translate }}</div>
                        <span>{{ supplierInvoice.invoiceNumber }}</span>
                      </div>
                    </div>

                    <div class="col-auto ps-3 pe-3">
                      <div *ngIf="supplierInvoice.creditorId" class="supplier-info-item">
                        <div>{{ 'manage-supplier-invoice-creditor-id' | translate }}</div>
                        <span>{{ supplierInvoice.creditorId }}</span>
                      </div>
                    </div>

                    <div class="col-auto ps-3 pe-3">
                      <div *ngIf="supplierInvoice.creditorName" class="supplier-info-item">
                        <ng-template #creditorInfo>
                          <div class="row"><div class="col-3"><b>{{ 'economy-creditor-popover-creditor-id' | translate }}:</b></div><div class="col">{{creditor.erpId}}</div></div>
                          <div class="row"><div class="col-3"><b>{{ 'economy-creditor-popover-name' | translate }}:</b></div><div class="col">{{creditor.name}}</div></div>
                          <div class="row"><div class="col-3"><b>{{ 'economy-creditor-popover-alias' | translate }}:</b></div><div class="col">{{creditor.alias}}</div></div>
                          <div class="row"><div class="col-3"><b>{{ 'economy-creditor-popover-iban' | translate }}:</b></div><div class="col">{{creditor.iban}}</div></div>
                          <div class="row"><div class="col-3"><b>{{ 'economy-creditor-popover-swift' | translate }}:</b></div><div class="col">{{creditor.swift}}</div></div>
                          <div class="row"><div class="col-3"><b>{{ 'economy-creditor-popover-account' | translate }}:</b></div><div class="col">{{creditor.bankAccount}}</div></div>
                          <div class="row"><div class="col-3"><b>{{ 'economy-creditor-popover-currency' | translate }}:</b></div><div class="col">{{creditor.currency}}</div></div>
                          <div class="row"><div class="col-3"><b>{{ 'economy-creditor-popover-country' | translate }}:</b></div><div class="col">{{creditor.countryCode}}</div></div>
                          <div class="row"><div class="col-3"><b>{{ 'economy-creditor-popover-terms' | translate }}:</b></div><div class="col">{{creditor.paymentTermsDesc}}</div></div>
                          <div class="row"><div class="col-3"><b>{{ 'economy-creditor-popover-active' | translate }}:</b></div><div class="col">{{creditor.erpActive}}</div></div>
                          <div class="row"><div class="col-3"><b>{{ 'economy-creditor-popover-vat-number' | translate }}:</b></div><div class="col">{{creditor.vatRegNo}}</div></div>
                          <div class="row"><div class="col-3"><b>{{ 'economy-creditor-popover-segment' | translate }}:</b></div><div class="col">{{creditor.segmentDesc}}</div></div>
                          <div class="row"><div class="col-3"><b>{{ 'economy-creditor-popover-emails' | translate }}:</b></div><div class="col">{{creditor.emails}}</div></div>
                        </ng-template>
                        <div>{{ 'manage-supplier-invoice-creditor-name' | translate }}</div>
                        <span>{{ supplierInvoice.creditorName }}</span>
                        <img src="/assets/icons/Info.svg" class="icon icon-padded"
                             container="body" [ngbPopover]="creditorInfo" popoverClass="economy-creditor-info-popover" (click)="getCreditorInfo()"/>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="supplier-invoice-modal-body">
      <table class="primary-table" aria-label="Supplier invoices">
        <thead>
          <tr>
            <td class="td-service">Service</td>
            <td class="td-item">
              <div
                [appDisableInput]="caseLockHelperService.isSupplierInvoiceLockTaken(supplierInvoiceId) || permissionService.isReadOnly"
                class="caselock-no-events">
                Item
                <span *permission="PermissionEnum.SUPPLIER_INVOICE_UPDATE">
                  <div class="add-item-button" *ngIf="!isApproved() && !isReturned()" (click)="addItem()">
                    {{ 'economy-supplier-invoice-add-item' | translate }} <img
                      src="/assets/icons/add-new.svg" alt="Add supplier invoice item" />
                  </div>
                </span>
              </div>
            </td>
            <td class="td-quantity">Quantity</td>
            <td class="td-unit">Unit</td>
            <td class="td-savings-amount">Savings amount incl. VAT</td>
            <td class="td-savings-pct">Savings %</td>
            <td>Amount incl. VAT</td>
          </tr>
        </thead>
      </table>
      <div class="supplier-invoice-details" id="scrollToMe">
        <app-supplier-invoice-item *ngFor="let supplierInvoiceItem of supplierInvoice.items; let i = index"
          [showDelete]="i > 0" [supplierInvoiceId]="supplierInvoiceId"
          [supplierInvoiceItem]="supplierInvoiceItem"
          [serviceOrders]="serviceOrders" [localCurrency]="supplierInvoice.invoiceAmountLocal"
          [editDisabled]="isApproved() || isReturned()" [caseCreatedDate]="caseCreatedDate"
          [allServiceItems]="serviceItemList" (delete)="deleteItem($event)" [caseStakeholders]="caseStakeholder"
          [shouldActAsInvoiceRecievedFromSUPO]="i === 0">
        </app-supplier-invoice-item>
        <div class="bottom-margin"></div>
      </div>
      <div class="row supplier-invoice-footer align-items-end">
        <div class="col col-3 align-self-end">
          <div class="supplier-invoice-footer-box">
            <div class="container-fluid box-table">
              <div class="row">
                <div class="col first-col-fixed">{{ 'manage-supplier-invoice-invoice-date' | translate }}</div>
                <div class="col second-col-fixed">{{ supplierInvoice.invoiceDate | date: 'dd MMM yyyy' }}</div>
                <div class="col"></div>
              </div>
              <div class="row">
                <div class="col first-col-fixed">{{ 'manage-supplier-invoice-due-date' | translate }}</div>
                <div class="col second-col-fixed">{{ supplierInvoice.dueDate | date: 'dd MMM yyyy' }}</div>
                <div class="col"></div>
              </div>
            </div>
          </div>
          <div class="supplier-invoice-footer-filler"></div>
          <div class="supplier-invoice-footer-box">
            <div class="container-fluid box-table">
              <div class="row">
                <div class="col first-col-fixed">{{ 'manage-supplier-invoice-gross-amount' | translate }}</div>
                <div class="col second-col-fixed">
                  {{ getTotalGrossAmount() | number: '.' +
                  supplierInvoice.invoiceAmountLocal.precision }}
                </div>
                <div class="col supplier-invoice-footer-currency">{{ supplierInvoice.invoiceAmountLocal.currency }}
                </div>
              </div>
              <div class="row">
                <div class="col first-col-fixed">{{ 'manage-supplier-invoice-gross-saving' | translate }}</div>
                <div class="col second-col-fixed">
                  <span class="supplier-invoice-footer-percentage">{{getTotalGrossSavingPct() | number: '.1-1'}}%</span>
                  {{ getTotalGrossSaving() | number: '.' + supplierInvoice.invoiceAmountLocal.precision }}
                </div>
                <div class="col supplier-invoice-footer-currency">{{ supplierInvoice.invoiceAmountLocal.currency }}
                </div>
              </div>
              <div class="row">
                <div class="col first-col-fixed">{{ 'manage-supplier-invoice-invoiced' | translate }}</div>
                <div class="col second-col-fixed">
                  {{ supplierInvoice.invoiceAmountLocal.amount | number: '.' +
                  supplierInvoice.invoiceAmountLocal.precision }}
                </div>
                <div class="col supplier-invoice-footer-currency">{{ supplierInvoice.invoiceAmountLocal.currency }}
                </div>
              </div>
              <div class="row">
                <div class="col first-col-fixed">{{ 'manage-supplier-invoice-reduction' | translate }}</div>
                <div class="col second-col-fixed">
                  <span class="supplier-invoice-footer-percentage">{{getTotalReductionPct() | number: '.1-1'}}%</span>
                  {{ getTotalReduction() | number: '.' + supplierInvoice.invoiceAmountLocal.precision }}
                </div>
                <div class="col supplier-invoice-footer-currency">{{ supplierInvoice.invoiceAmountLocal.currency }}
                </div>
              </div>
              <div class="row">
                <div class="col sum-line"></div>
              </div>
              <div class="row">
                <div class="col first-col-fixed-sum">{{ 'manage-supplier-invoice-total-paid' | translate }}</div>
                <div class="col second-col-fixed-sum emphasized-amount">
                  {{ getTotalPaidLocal() | number: '.' + supplierInvoice.invoiceAmountLocal.precision }}
                </div>
                <div class="col supplier-invoice-footer-currency">{{ supplierInvoice.invoiceAmountLocal.currency }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col col-3 align-self-end">
          <span *ngIf="isLatestRemark()">
            <div class="supplier-invoice-footer-label">{{ 'manage-supplier-invoice-latest-remark' | translate }}</div>
            <textarea rows="3" cols="44" maxlength="1000" [(ngModel)]="this.supplierInvoice.returnRemark"
              readonly></textarea>
          </span>
          <div class="supplier-invoice-footer-box">
            <div class="container-fluid box-table">
              <div class="row">
                <div class="col first-col-fixed">{{ 'manage-supplier-invoice-gross-amount' | translate }}</div>
                <div class="col second-col-fixed">
                  {{ getTotalGrossAmountExchanged() | number: '.' + supplierInvoice.invoiceAmountExchanged.precision }}
                </div>
                <div class="col supplier-invoice-footer-currency">{{ supplierInvoice.invoiceAmountExchanged.currency }}
                </div>
              </div>
              <div class="row">
                <div class="col first-col-fixed">{{ 'manage-supplier-invoice-gross-saving' | translate }}</div>
                <div class="col second-col-fixed">
                  <span class="supplier-invoice-footer-percentage">{{getTotalGrossSavingPct() | number: '.1-1'}}%</span>
                  {{ getTotalGrossSavingExchanged() | number: '.' + supplierInvoice.invoiceAmountExchanged.precision }}
                </div>
                <div class="col supplier-invoice-footer-currency">{{ supplierInvoice.invoiceAmountExchanged.currency }}
                </div>
              </div>
              <div class="row">
                <div class="col first-col-fixed">{{ 'manage-supplier-invoice-invoiced' | translate }}</div>
                <div class="col second-col-fixed">
                  {{ supplierInvoice.invoiceAmountExchanged.amount | number: '.' +
                  supplierInvoice.invoiceAmountExchanged.precision }}
                </div>
                <div class="col supplier-invoice-footer-currency">{{ supplierInvoice.invoiceAmountExchanged.currency }}
                </div>
              </div>
              <div class="row">
                <div class="col first-col-fixed">{{ 'manage-supplier-invoice-reduction' | translate }}</div>
                <div class="col second-col-fixed">
                  <span class="supplier-invoice-footer-percentage">{{getTotalReductionPct() | number: '.1-1'}}%</span>
                  {{ getTotalReductionExchanged() | number: '.' + supplierInvoice.invoiceAmountExchanged.precision }}
                </div>
                <div class="col supplier-invoice-footer-currency">{{ supplierInvoice.invoiceAmountExchanged.currency }}
                </div>
              </div>
              <div class="row">
                <div class="col sum-line"></div>
              </div>
              <div class="row">
                <div class="col first-col-fixed">{{ 'manage-supplier-invoice-total-paid' | translate }}</div>
                <div class="col second-col-fixed emphasized-amount">
                  {{ getTotalPaidExchanged() | number: '.' + supplierInvoice.invoiceAmountExchanged.precision }}
                </div>
                <div class="col supplier-invoice-footer-currency">{{ supplierInvoice.invoiceAmountExchanged.currency }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col col-4 align-items-end">
          <div
            [appDisableInput]="caseLockHelperService.isSupplierInvoiceLockTaken(supplierInvoiceId) || permissionService.isReadOnly">
            <div class="supplier-invoice-footer-label">{{ 'manage-supplier-invoice-int-remark' | translate }}</div>
            <textarea rows="3" cols="60" maxlength="1000" [(ngModel)]="supplierInvoice.internalRemark"></textarea>
            <div class="supplier-invoice-footer-label">{{ 'manage-supplier-invoice-reduction-remark' | translate }}
            </div>
            <textarea rows="3" cols="60" maxlength="1000" [(ngModel)]="supplierInvoice.reductionRemark"
              [disabled]="isApproved() || isReturned()"></textarea>
          </div>
        </div>
        <div class="col align-self-end text-end">
          <div class="supplier-invoice-footer-label">{{ 'manage-supplier-invoice-total-excl-vat' | translate }}</div>
          <div class="supplier-invoice-footer-label">
            {{ getTotalAmountTypedExclVAT() | number: '.' + supplierInvoice.invoiceAmountLocal.precision }}
            <span class="supplier-invoice-footer-currency">{{ supplierInvoice.invoiceAmountLocal.currency }}</span>
          </div>
          <div class="supplier-invoice-footer-label">{{ 'manage-supplier-invoice-total-typed' | translate }}</div>
          <div
            [ngClass]="{ 'typed-amount-same': getTotalAmountTypedMatch(), 'typed-amount-differ': !getTotalAmountTypedMatch() }">
            {{ getDiffFromTotalAmount() | number: '.' + supplierInvoice.invoiceAmountLocal.precision }}
            <span class="supplier-invoice-footer-currency">{{ supplierInvoice.invoiceAmountLocal.currency }}</span>
          </div>
          <div class="supplier-invoice-footer-label">{{ 'manage-supplier-invoice-total-from-invoice' | translate }}
          </div>
          <div class="emphasized-amount">
            {{ supplierInvoice.invoiceAmountLocal.amount | number: '.' + supplierInvoice.invoiceAmountLocal.precision }}
            <span class="supplier-invoice-footer-currency">{{ supplierInvoice.invoiceAmountLocal.currency }}</span>
          </div>
        </div>
      </div>

      <div class="row supplier-invoice-footer-buttons justify-content-end">
        <div
          [appDisableInput]="caseLockHelperService.isSupplierInvoiceLockTaken(supplierInvoiceId) || permissionService.isReadOnly"
          class="caselock-no-events">
          <div *permission="PermissionEnum.SUPPLIER_INVOICE_UPDATE">
            <div class="text-end">
              <span *permission="PermissionEnum.SUPPLIER_INVOICE_UPDATE">
                <button class="default-button-white"
                        [ngClass]="{ 'default-button-white-disabled': isApproved() || isReturned() }" [disabled]="isApproved() || isReturned()"
                        (click)="reject()">
                  {{ 'manage-supplier-invoice-reject' | translate }}
                </button>
              </span>

              <span *permission="PermissionEnum.SUPPLIER_INVOICE_UPDATE">
                <span *ngIf="!isReturned()">
                  <button class="default-button-white"
                          [ngClass]="{ 'default-button-white-disabled': isApproved() || isReturned() }"
                          [disabled]="isApproved() || isReturned()" (click)="openReturnToAccounting()">
                    {{ 'manage-supplier-invoice-return' | translate }}
                  </button>
                </span>
              </span>

              <span *ngIf="isReturned()">
                <button class="default-button-white-red" [ngbTooltip]="supplierInvoice.returnRemark" [closeDelay]="50000" placement="top">
                  {{ 'manage-supplier-invoice-returned' | translate }}
                </button>
              </span>

              <button class="default-button-white" (click)="save()" [disabled]="!isValidated()"
                      [ngClass]="{ 'default-button-white-disabled': !isValidated() || caseLockHelperService.isSupplierInvoiceLockTaken(supplierInvoiceId) }">
                {{ 'manage-supplier-invoice-save' | translate }}
              </button>

              <button class="default-button" (click)="approve()"
                      [ngClass]="{ 'default-button-disabled': isNotApprovable() || !isCaseErpReady ||!groupMemberValid || caseLockHelperService.isSupplierInvoiceLockTaken(supplierInvoiceId) }"
                      [disabled]="isNotApprovable() || !groupMemberValid || !isCaseErpReady">
                <div [disablePopover]="groupMemberValid && isCaseErpReady" triggers="hover" placement="left-bottom" [ngbPopover]="(!isCaseErpReady ? 'case-economy-refund-invoice-approve-disabled' : 'case-economy-missing-group-member') | translate">
                  {{ 'manage-supplier-invoice-approve' | translate }}
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
<app-return-invoice-modal #returnInvoiceModal (returnEvent)="returnToAccounting($event)"></app-return-invoice-modal>
<app-comment-modal #commentModal (commentEvent)="updateAutoApprovalComment($event)"></app-comment-modal>
