import { CaseValidationService } from 'src/app/core/services/case-validation.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { DateHelperService } from '@secca/core/services/date-helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDialogComponent } from '@secca/shared/components/modal-dialog/modal-dialog.component';
import { ModalDialogConfiguration } from '@secca/shared/models/modal/modal-dialog-configuration';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { MedicalPreAssessmentService } from '@secca/core/services/medical-pre-assessment.service';
import { TranslateService } from '@ngx-translate/core';
import { CASE_TYPE_CODES } from '@secca/shared/models/case-type';
import { ServiceTypeEnum, StatusTypes } from '@secca/shared/models/enums';
import { ServiceOrderService } from '@secca/core/services/service-order.service';
import { CasePlansService } from '@secca/case/components/case-plans/case-plans.service';
import { Subscription } from 'rxjs';

@AutoUnsubscribe
@Component({
  selector: 'app-case-info',
  templateUrl: './case-info.component.html',
  styleUrls: ['./case-info.component.scss'],
})
export class CaseInfoComponent implements OnInit {
  showCaseNumber = false;
  validatedCases = {};
  createdLocalDate: Date;

  $subscr1;

  @Input()
  caseId: string;

  @Input()
  caseNumber: string;

  @Input()
  caseTypeCode: string;

  @Input()
  caseTypeTranslated: string;

  @Input()
  caseTypeManuallySet: boolean;

  @Input()
  caseTypeResetDisabled: boolean;

  @Input()
  set caseCreateDate(cdate: Date) {
    this.createdLocalDate =
      cdate ? this.dateHelperService.convertFromUTCDateTimeToLocalDateTime(cdate) : null;
  }

  @Output()
  setCaseTypeChanged: EventEmitter<void> = new EventEmitter();

  @Output()
  caseTypeReset: EventEmitter<void> = new EventEmitter();

  private committedICCServiceTypeExists: boolean;
  $editServiceSubscr: Subscription;
  $newServiceSubscr: Subscription;
  $deletedServiceSubscr: Subscription;

  constructor(
    private caseValidationService: CaseValidationService,
    private dateHelperService: DateHelperService,
    private modalService: NgbModal,
    public caseStateService: CaseStateService,
    public medicalPreAssessmentService: MedicalPreAssessmentService,
    private translateService: TranslateService,
    private casePlanService: CasePlansService,
    private serviceOrderService: ServiceOrderService
  ) {}

  ngOnInit() {
    this.$subscr1 = this.caseValidationService.getCaseNumberAssignedConfirmation().subscribe(result => {
      if (result) {
        this.validatedCases[result] = true;
      }
    });

    this.caseStateService.isCaseMedicalPreAssessment = false;
    this.medicalPreAssessmentService.getMedicalPreAssessmentExists(+this.caseId).subscribe(result => {
      this.caseStateService.isCaseMedicalPreAssessment = result;
    });

    this.$editServiceSubscr = this.casePlanService.getEditedServiceOrder().subscribe(result => {
      if (result) {
        this.checkICCServiceOrders();
      }
    });
    this.$newServiceSubscr = this.casePlanService.getNewServiceOrder().subscribe(result => {
      if (result) {
        this.checkICCServiceOrders();
      }
    });
    this.$deletedServiceSubscr = this.casePlanService.getDeletedServiceOrder().subscribe(result => {
      if (result) {
        this.checkICCServiceOrders();
      }
    });
  }

  private checkICCServiceOrders() {
    if (this.caseTypeCode === CASE_TYPE_CODES.INTERNAL_COORDINATION) {
      // Check if service order of type ICC exists and is committed.
      this.serviceOrderService.getServiceOrdersForCaseByType(+this.caseId, ServiceTypeEnum.COORDINATION_TRANSPORT).subscribe(
        serviceOrders => this.committedICCServiceTypeExists = serviceOrders.filter(serviceOrder => serviceOrder.status === StatusTypes.COMMITTED).length > 0
      );
    }
  }

  isCaseValidated(caseId: number): boolean {
    return !!this.validatedCases[caseId];
  }

  openConfirmDialog() {
    if ( this.caseTypeResetDisabled ) {
      return;
    }
    const modalRef = this.modalService.open(ModalDialogComponent);
    modalRef.componentInstance.items = [];
    modalRef.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'default-modal-header',
      title: 'case-type-enable-evaluation-pop-up',
      text: null,
      footer: null,
      yes: 'default-modal-dialog-yes',
      no: 'default-modal-dialog-no',
      isBody: true,
      isFooter: true,
    });
    modalRef.componentInstance.closeModalEvent.subscribe(
      closedClickingYes => {
        if (closedClickingYes) {
          this.caseTypeManuallySet = false;
          this.caseTypeReset.next();
        }
        modalRef.close();
      },
      error => console.log(error)
    );
  }

  openModal() {
    const modalRef = this.modalService.open(ModalDialogComponent, { size: 'lg'});

    modalRef.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'default-modal-header',
      title: 'case-validation-warning-modal-title-case-insurance',
      text: 'case-validation-warning-modal-text',
      footer: 'case-validation-warning-modal-footer',
      yes: 'default-modal-dialog-yes',
      no: 'default-modal-dialog-no',
      isBody: true,
      isFooter: false,
    });
    modalRef.componentInstance.closeModalEvent.subscribe(emittedEvent => {
      this.closeModal();
    });
  }

  caseTypeResetPopOverText() {
    return this.caseTypeResetDisabled ? this.translateService.instant('CASE_TYPE_RESET_DISABLED') : this.translateService.instant('CASE_TYPE_RESET_DESCRIPTION');
  }

  caseTypeResetImage() {
    return this.caseTypeResetDisabled ? '/assets/icons/ArrowRepeatAllOff inactive.svg' : '/assets/icons/ArrowRepeatAllOff.svg';
  }

  confirmed() {
    this.modalService.dismissAll();
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  caseTypeChanged() {
    this.caseTypeManuallySet = true;
    this.setCaseTypeChanged.next();
  }

  get isDisabled() {
    return this.caseStateService.isCaseClosed || this.caseStateService.isCaseMedicalPreAssessment || this.committedICCServiceTypeExists;
  }
}
