import {StatusTypes} from '@secca/shared/models/enums';
import {SubReference} from '@secca/case/components/case-plans/case-plans/add-service-order/models/subReference';
import {Duration, DurationAdapter} from "@secca/shared/models/duration";
import {ServiceOrderExtension} from "@secca/shared/models/service-order/service-order-extension";
import * as moment from 'moment-timezone';
import {Location} from "@secca/shared/models/location";
import {SubRemarksRefund} from "@secca/case/components/case-plans/case-plans/add-service-order/models/subRemarksRefund";
import { CommonServiceOrderExtension } from './CommonServiceOrderExtension.component';
import { CommitmentType, CommitmentTypeAdapter } from '../commitment-type';

export class OtherRefundRequestServiceOrderExtension extends CommonServiceOrderExtension implements ServiceOrderExtension {
  remarksRefund: SubRemarksRefund;
  reference: SubReference;
  commitmentType: CommitmentType;
  duration: Duration;
  displayDurationId: number;

  isValid() {
    return this.duration.isStartEndDatesValid() && this.remarksRefund.remarks && this.remarksRefund.remarks.length > 0;
  }

  constructor(init?: Partial<OtherRefundRequestServiceOrderExtension>) {
    super();
    Object.assign(this, init);
    if (!init) {
      this.reference = new SubReference();
      this.duration = new Duration();
      this.remarksRefund = new SubRemarksRefund();
    }
  }

  isValidExpectation(): boolean {
    return false;
  }

  isMultipleDurations(): boolean {
    return false;
  }

  getCommitmentType(): CommitmentType {
    return this.commitmentType;
  }

  getEndDate(): moment.Moment {
    return this.duration.endDate;
  }

  getSelectedStakeholderIds(status?: StatusTypes): string[] {
    return [];
  }

  getStartDate(): moment.Moment {
    return this.duration.startDate;
  }

  hasCoTravellers(status?: StatusTypes): boolean {
    return false;
  }

  hasMedicalEscort(status?: StatusTypes): boolean {
    return false;
  }

  isPreviewGopValid(): boolean {
    return false;
  }

  setCommitmentType(commitmentType: CommitmentType) {
    this.commitmentType = commitmentType;
  }

  getStartLocation(): Location {
    return null;
  }

  getEndLocation(): Location {
    return null;
  }

  init(defaultBillingCurrency) {
  }
}

export class OtherRefundRequestServiceOrderExtensionAdapter {
  commitmentTypeAdapter: CommitmentTypeAdapter = new CommitmentTypeAdapter();
  adapt(item?: any): OtherRefundRequestServiceOrderExtension {
    const durationAdapter = new DurationAdapter();
    return new OtherRefundRequestServiceOrderExtension({
      duration: item.duration == null ? new Duration() : durationAdapter.adapt(item.duration),
      commitmentType: item.commitmentType == null ?  /*CommitmentTypes.COMMITTED*/ null : this.commitmentTypeAdapter.adapt(item.commitmentType), // TODO DEFAULT
      reference: item.reference ? new SubReference(item.reference) : new SubReference(),
      remarksRefund: item.remarksRefund ? new SubRemarksRefund(item.remarksRefund) : new SubRemarksRefund(),
    });
  }
}
