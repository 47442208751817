import { Injectable } from '@angular/core';
import { DropdownDictionary } from './dropdownDictionary';
import { PhoneNumber, PhoneNumberAdapter } from './phoneNumber';
import { StakeholderTypeEnum } from '@secca/shared/models/enums';

export class SimpleCaseStakeholder {
  id: number;
  stakeholderId: number;
  stakeholderType: StakeholderTypeEnum;
  firstName: string;
  surname: string;
  street: string;
  city: string;
  postalCode: string;
  homeCountry: string;
  companyName: string;
  age: number;
  gender: string;
  cotraveller: boolean;
  nationalIdValid: boolean;
  dateOfBirth: Date;
  email: string;
  nationalIdCountry: string;
  nationalId: string;
  phoneNumbers: PhoneNumber[];
  properties: string[];
  isCompany: boolean;

  public constructor(init?: Partial<SimpleCaseStakeholder>) {
    Object.assign(this, init);
  }

  hasRole(roleName: string): boolean {
    return this.stakeholderType === roleName || (this.properties != null && this.properties.indexOf(roleName) > -1);
  }
}


@Injectable({
  providedIn: 'root'
})
export class SimpleCaseStakeholderDropDownWrapper {
  wrap(item: any): DropdownDictionary {
    return new DropdownDictionary(this.formatDropDownId(item),
      {
        value: this.formatItemName(item), type: item.stakeholderType, id: item.id
      });
  }

  formatDropDownId(item: any) {
    let returnvalue = item.stakeholderType + '#';
    if (item.person) {
      returnvalue = returnvalue + (item.person.firstName || '') + '#' + (item.person.surname || '') + '#';
    }
    if (item.company) {
      returnvalue = returnvalue + '# # ' + (item.company.name || '');
    }
    return returnvalue;
  }

  formatStakeholderType(item: any) {
    return item.replace(/(\B)[^ ]*/g, match => (match.toLowerCase()));
  }

  formatItemName(item: any) {
    if (item.company) {
      return item.company.name;
    } else {
      return (item.person.firstName || '')
        + (item.person.surname ? ' ' : '')
        + (item.person.surname || '');
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class SimpleCaseStakeholderAdapter {
  constructor(private phoneNumberAdapter: PhoneNumberAdapter) {}

  public adapt(data: any): SimpleCaseStakeholder {
    return new SimpleCaseStakeholder({
      id: data.id,
      firstName: data.firstName,
      surname: data.surname,
      street: data.street,
      city: data.city,
      postalCode: data.postalCode,
      homeCountry: data.homeCountry,
      age: data.age,
      companyName: data.companyName,
      cotraveller: data.cotraveller,
      gender: data.gender,
      stakeholderId: data.stakeholderId,
      stakeholderType: data.stakeholderType,
      nationalIdValid: data.nationalIdValid,
      dateOfBirth: data.dateOfBirth,
      email: data.email,
      nationalIdCountry: data.nationalIdCountry,
      nationalId: data.nationalId,
      phoneNumbers: data.phoneNumbers ? data.phoneNumbers.map(p => this.phoneNumberAdapter.adapt(p)) : [],
      properties:  data.properties,
      isCompany: data.isCompany
    });
  }
}
