import { Component, Inject, ViewChild } from '@angular/core';
import { MinimizableDialogType } from '@secca/shared/enums/minimizable-dialog-type-enum';
import { DialogBoundryService } from "@secca/core/services/dialog-boundry.service";
import { DialogMinimizableDirective } from "@secca/shared/directives/dialog-minimizable.directive";
import { IMinimizable } from "@secca/shared/interfaces/minimizable";
import { IAdditionalInfo, RecoveryAdditionalInfo, refundAdditionalInfo} from "@secca/shared/interfaces/additional-info";
import { DialogHelperUtilService } from '@secca/core/services/dialog-helper-util.service';
import { IKeyboardEnabled } from '@secca/shared/interfaces/keyboard-enabled';
import { DialogSelectableDirective } from '@secca/shared/directives/dialog-selectable.directive';
import { IFocusEnabled } from '@secca/shared/interfaces/focus-enabled';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { AddRecoveryComponent } from './add-recovery/add-recovery.component';
import { CaseEconomyRecoveryDialogData } from './case-economy-add-recovery-dialog-data.component';

@Component({
  selector: 'app-case-economy-add-recovery-dialog',
  templateUrl: './case-economy-add-recovery-dialog.component.html',
  styleUrls: ['./case-economy-add-recovery-dialog.component.scss']
})
export class CaseEconomyAddRecoveryDialogComponent implements IAdditionalInfo, IMinimizable, IKeyboardEnabled, IFocusEnabled {
  @ViewChild(AddRecoveryComponent) wrappedInstance: AddRecoveryComponent;
  @ViewChild(DialogMinimizableDirective) minimizedDirective: DialogMinimizableDirective;
  @ViewChild(DialogSelectableDirective) selectableDirective: DialogSelectableDirective;

  data: CaseEconomyRecoveryDialogData;

  constructor(private dialogRef: MatDialogRef<CaseEconomyAddRecoveryDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data: CaseEconomyRecoveryDialogData,
              private dialogHelperUtilService: DialogHelperUtilService,
              public dialogBoundryService: DialogBoundryService)
  {
    this.data = data;
  }

  close(): void {
    this.dialogHelperUtilService.close(this.dialogRef, this.getAdditionalInfo()?.caseId);
    if(this.data.recoverySaved !== undefined){
      this.data.recoverySaved();
    }
  }

  keyboardClose(): void {
    this.wrappedInstance.close();
  }

  update(): void {
    if(this.data.recoverySaved !== undefined){
      this.data.recoverySaved();
    }
  }

  recoveryDeleted(): void {
    if(this.data.recoveryDeleted !== undefined){
      this.data.recoveryDeleted();
    }
  }

  getAdditionalInfo(): RecoveryAdditionalInfo {
    const caseId: number = this.wrappedInstance?.recovery?.caseId;
    const recoveryId: number = this.wrappedInstance?.recovery?.id;
    return {
      caseId,
      recoveryId
    };
  }

  minimize(): void {
    this.minimizedDirective.minimize();
  }

  getMinimizeType(): MinimizableDialogType {
    return this.minimizedDirective.appDialogMinimizable;
  }

  getFocusElement(): any {
    return this.selectableDirective?.focusElement;
  }

  get MinimizableDialogType() {
    return MinimizableDialogType;
  }
}
