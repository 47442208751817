export enum CountryCode {
    DK = 'dk',
    SE = 'se',
    NO = 'no',
    FI = 'fi',
    EN = 'en',
    DE = 'de'

}

export enum LocalCode {
    DK = 'da',
    SE = 'sv',
    NO = 'no',
    FI = 'fi',
    EN = 'en',
    DE = 'de'
}
