import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
import * as moment from 'moment';

export class Diagnosis {
  id: number;
  businessKey: string;
  name: string;
  icd10Code: string;
  icd10Name: string;
  uiSortIndex: number;
  activateOn: moment.Moment;
  active: boolean;

  public constructor(init?: Partial<Diagnosis>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
export class DiagnosisAdapter implements Adapter<Diagnosis> {
  adapt(item: any): Diagnosis {
    return new Diagnosis({
      id: item.id,
      businessKey: item.businessKey,
      name: item.name,
      icd10Code: item.icd10Code,
      icd10Name: item.icd10Name,
      uiSortIndex: item.uiSortIndex,
      activateOn: item.activateOn,
      active: item.active
    });
  }
}
