import { Component, OnInit, ViewChild, TemplateRef, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import { DictionaryService } from 'src/app/core/services/dictionary.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StakeholderType } from '../stakeholderType';
import { ShortcutEnum, StakeholderTypeEnum } from 'src/app/shared/models/enums';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import { PermissionService } from '@secca/core/services/permission.service';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { MenuService } from '@secca/core/services/menu.service';
import { CaseSelectedMenuEnum } from '@secca/shared/enums/case-selected-menu-enum.component';
import { ShortcutService } from '@secca/core/services/shortcut.service';
import { Subscription } from 'rxjs';
import { SettingsService } from '@secca/core/services/settings.service';

@Component({
  selector: 'app-case-stakeholders-filter',
  templateUrl: './case-stakeholders-filter.component.html',
  styleUrls: ['./case-stakeholders-filter.component.scss']
})
export class CaseStakeholdersFilterComponent implements OnInit, OnDestroy {
  @Input() caseId: string;

  @Output() searchFilterTextChanged = new EventEmitter<string>();
  @Output() addNewType = new EventEmitter<string>();
  @Output() filterByType = new EventEmitter<StakeholderType[]>();

  @ViewChild('content')
  private   template: TemplateRef<any>;

  get StakeholderTypeEnum() {
    return StakeholderTypeEnum;
  }
  types: StakeholderType[];
  wholeTypes: StakeholderType[];
  fliterGroupTypeList: StakeholderType[];
  filteredTypes: StakeholderType[];
  group: DropdownDictionary[] = [
    { id: '' , name: 'Show all Types'},
    { id: 'End User', name: 'End-User' },
    { id: 'Policyholder', name: 'Policy Holder' },
    { id: 'Reporter', name: 'Reporter' },
    { id: 'Person', name: 'Person' },
    { id: 'Medical', name: 'Medical' },
    { id: 'Accommodation', name: 'Accommodation' },
    { id: 'Transport', name: 'Transport' },
    { id: 'Medical escort', name: 'Medical escort' },
    { id: 'Agent', name: 'Agent' },
    { id: 'Other', name: 'Other' }
  ];
  typeSearchValue = '';

  private selectedMenuId: number;
  private shortcutSubscription: Subscription;

  constructor(private modalService: NgbModal,
              private dictionaryService: DictionaryService,
              public caseLockHelperService: CaseLockHelperService,
              public permissionService: PermissionService,
              public caseStateService: CaseStateService,
              public settingsService: SettingsService,
              private menuService: MenuService,
              private shortcutService: ShortcutService) {
    // this.shortcutSubscription = this.shortcutService.addShortcut({ keys: ShortcutEnum.AddStakeholder }).subscribe(a => {
    //   this.selectedMenuId = this.menuService.getSelectedMenuId(parseInt(this.caseId, 10));
    //   if (this.selectedMenuId === CaseSelectedMenuEnum.Stakeholders) {
    //     this.open();
    //   }
    // });
  }

  ngOnInit() {
    this.dictionaryService.getStakeholdersTypes().subscribe({
      next: (result: StakeholderType[]) => {
        this.wholeTypes = result;
        const excludeTypes = [
          StakeholderTypeEnum.endUser,
          StakeholderTypeEnum.policyHolder,
          StakeholderTypeEnum.reporter,
          StakeholderTypeEnum.person,
          StakeholderTypeEnum.company,
          StakeholderTypeEnum.customer
        ];
        this.types = result.filter(type => !excludeTypes.includes(type.stakeholderTypeEnum));
        this.filterList();
      },
      error: (error: any) => console.log(error)
    });
  }

  ngOnDestroy(): void {
//    this.shortcutSubscription.unsubscribe();
  }

  open() {
    if (!this.settingsService.isZoomed()) {
      this.modalService.open(this.template,  { size: 'lg', centered: true });
    } else {
      this.modalService.open(this.template,  { size: 'lg', windowClass: 'stakeholder-search-type-popup-zoomed', centered: true });
    }
  }

  clickOnType(event: Event, type: string) {
    event.preventDefault();
    event.stopPropagation();
    this.modalService.dismissAll();
    this.addNewType.emit(type);
  }

  addNew(type: string) {
    this.addNewType.emit(type);
  }

  private filterList(filter: string = '') {
    this.filteredTypes =
    this.types.filter(type => type.stakeholderTypeEnum.toLowerCase().includes(filter));
  }

  updateTypesFilter() {
    this.filterList(this.typeSearchValue.toLowerCase());
  }

  selectedItemIdChange(selectedItemId: string) {
    if (selectedItemId === '') {
      this.fliterGroupTypeList = null;
    } else {
      this.fliterGroupTypeList = this.wholeTypes.filter(type => type.groupName.includes(selectedItemId));
    }
    this.filterByType.emit(this.fliterGroupTypeList);
  }

}
