import { InboxTaskViewModel } from "./inbox-task-view.model";

export class InboxTaskFilterResponse {
  totalNumber: number;
  inboxTaskModal: InboxTaskViewModel[];

  public constructor(init?: Partial<InboxTaskFilterResponse>) {
    Object.assign(this, init);
  }
}
