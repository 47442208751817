import { ResetFinanceBridgeStatustDto } from '@secca/shared/models/ResetFinanceBridgeStatustDto';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from './settings.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})

export class FinanceBridgeStatusService extends BaseService {
    constructor(
        private http: HttpClient,
        private settingsService: SettingsService,
    ) {
        super(settingsService);
    }

    public postResetRetryCount(resetFinanceBridgeStatus: ResetFinanceBridgeStatustDto) {
        return this.http.post<any>(this.baseURL + 'finance-bridge-status/resetRetryCount', resetFinanceBridgeStatus, {
            headers: this.jsonHeaders
          }).pipe(map((res: any) => {
              return res;
          }));
    }

    public postResetAckMessage(resetFinanceBridgeStatus: ResetFinanceBridgeStatustDto) {
        return this.http.post<any>(this.baseURL + 'finance-bridge-status/resetAckMessage', resetFinanceBridgeStatus, {
            headers: this.jsonHeaders
          }).pipe(map((res: any) => {
              return res;
          }));
    }

    public postResetInvoiceDocument(resetFinanceBridgeStatus: ResetFinanceBridgeStatustDto) {
        return this.http.post<any>(this.baseURL + 'finance-bridge-status/resetInvoiceDocument', resetFinanceBridgeStatus, {
            headers: this.jsonHeaders
          }).pipe(map((res: any) => {
              return res;
          }));
    }

    public postResetPaymentStatus(resetFinanceBridgeStatus: ResetFinanceBridgeStatustDto) {
        return this.http.post<any>(this.baseURL + 'finance-bridge-status/resetPaymentStatus', resetFinanceBridgeStatus, {
            headers: this.jsonHeaders
          }).pipe(map((res: any) => {
              return res;
          }));
    }

}
