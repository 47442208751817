
import { Component, Input, OnInit  } from '@angular/core';
import { SubStakeholders } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholders';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { ServiceOrder } from '@secca/shared/models/service-order/service-order';
import { ICCStakeholder } from '@secca/case/components/case-plans/case-plans/add-service-order/models/icc-stakeholder';
import { CoordinationCaseDetail } from '@secca/shared/models/coordination-case-details';
import { SubStakeholdersConfig } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholdersConfig';
import { ServiceOrderType } from '@secca/shared/models/service-order-type';
import { MedicalAssessment } from '@secca/shared/models/medicalAssessment';
import { MedicalService } from '@secca/core/services/medical.service';
import { StatusTypes } from '@secca/shared/models/enums';
import { ICCStakeholders } from '../../models/icc-stakeholders';
import { ServiceOrderDetailsHelper } from '@secca/case/components/case-plans/plan/service-order/service-order-details-helper';

@Component({
  selector: 'app-end-user-case',
  templateUrl: './sub-end-user-case.component.html',
  styleUrls: ['./sub-end-user-case.component.scss'],
})
@AutoUnsubscribe
export class SubEndUserCaseComponent implements OnInit {
  @Input() caseId: string;
  @Input() stakeholders: CaseStakeholder[];
  @Input() subStakeholders: SubStakeholders;
  @Input() caseServiceOrders: ServiceOrder[];
  @Input() coordinatedCaseDetail: CoordinationCaseDetail;
  @Input() subStakeholdersConfig: SubStakeholdersConfig;
  @Input() serviceOrderType: ServiceOrderType;
  @Input() showInvalidBackground: boolean;
  @Input() flightStakeholders: ICCStakeholders;
  @Input() iccServiceOrderStatus: StatusTypes;
  @Input() showPassengerConfirmed: boolean;
  @Input() savedFlightStakeholders: ICCStakeholders;

  coordinaitonMedicalAssessment: MedicalAssessment;

  caseStakeholders: CaseStakeholder[];

  constructor(private medicalService: MedicalService,
              private serviceOrderDetailsHelper: ServiceOrderDetailsHelper) {}

  ngOnInit() {
    this.loadMedicalAssessment(this.coordinatedCaseDetail.caseId);
  }

  loadMedicalAssessment(caseId: number): void {
    this.medicalService.getNewestMedicalAssessment(caseId).subscribe(assessment => {
      this.coordinaitonMedicalAssessment = assessment;
    });
  }

  public getRepatriationFormName(value): string {
    return value ? this.serviceOrderDetailsHelper.getRepatriationFormName(value) : '';
  }
}
