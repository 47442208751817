import { Note, NoteAdapter } from '@secca/shared/models/note';
import { Injectable } from '@angular/core';
import { Adapter } from '@secca/shared/interfaces/adapter';

export class NoteView {
  note?: Note;
  isFocused: boolean;
  shouldBeValidated?: boolean;
  hasBeenSubmitted?: boolean;
  isEdit?: boolean;

  public constructor(init?: Partial<NoteView>) {
    this.note = new Note();
    this.isFocused = false;
    this.note.description = '';
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class NoteViewAdapter implements Adapter<NoteView> {
  constructor(private noteAdapter: NoteAdapter) {}

  adapt(item: any): NoteView {
    return new NoteView({
      note: item.note ? this.noteAdapter.adapt(item.note) : null,
      isFocused: item.isFocused,
      shouldBeValidated: item.shouldBeValidated,
      hasBeenSubmitted: item.hasBeenSubmitted,
      isEdit: item.isEdit
    });
  }
}
