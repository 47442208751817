<div class="text-box">
  <span class="text-box-name" [style.color]="alternativeColor !== '' ? alternativeColor : ''">{{name}}</span>
  <textarea
    [disabled]="!!disabled"
    [maxLength]="maxTextLength"
    contenteditable="true"
    class="text-box-component"
    [ngClass]="{'text-box-component-required' : isRequired, 'text-box-component-medium' : mediumView, 'text-box-component-large' : largeView, 'text-box-component-recommended' : recommended  }"
    [style.border-color]="alternativeColor !== '' ? alternativeColor : ''"
    [(ngModel)]="value"
    (keyup)="onChange()"
    [placeholder]="placeHolder"
    [style.height.px]="height"
    >
  </textarea >
</div>

