import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "@secca/shared/shared.module";
import { UtilsModule } from "src/app/utils/utils.module";
import { CaseDocumentDialogComponent } from "./case-document-dialog/case-document-dialog.component";
import { CaseDocumentFilterComponent } from "./case-document-filter/case-document-filter.component";
import { CaseDocumentTableDeleteDocumentComponent } from "./case-document-table/case-document-table-delete-document/case-document-table-delete-document.component";
import { HoverFileScanComponent } from "./case-document-table/case-document-table-hover-filescan/hover-file-scan.component";
import { CaseDocumentTableMoveDocumentComponent } from "./case-document-table/case-document-table-move-document/case-document-table-move-document.component";
import { CaseDocumentTableComponent } from "./case-document-table/case-document-table.component";
import { CaseDocumentsComponent } from "./case-documents.component";

@NgModule({
  declarations: [
    CaseDocumentsComponent,
    CaseDocumentFilterComponent,
    CaseDocumentTableComponent,
    CaseDocumentDialogComponent,
    CaseDocumentTableMoveDocumentComponent,
    CaseDocumentTableDeleteDocumentComponent
   ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UtilsModule,
    SharedModule,
  ],
  exports: [
    CaseDocumentsComponent
  ]
})
export class CaseDocumentModule { }