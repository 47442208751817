import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';

export class CauseItemType {
  id: number;
  name: string;
  code: string;
  vat: boolean;
  uiSortIndex: number;
  activateOn: Date;
  deactivateOn: Date;

  public constructor(init?: Partial<CauseItemType>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class CauseLevel1ItemTypeAdapter implements Adapter<CauseItemType> {
  adapt(item: any): CauseItemType {
    const deactivateOn = item.deactivateOn ? new Date(item.deactivateOn) : null;
    return new CauseItemType({
      id: item.id,
      name: item.level1,
      code: item.level1Code,
      vat: item.vat,
      uiSortIndex: item.uiSortIndex,
      activateOn: new Date(item.activateOn),
      deactivateOn,
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class CauseLevel2ItemTypeAdapter implements Adapter<CauseItemType> {
  adapt(item: any): CauseItemType {
    const deactivateOn = item.deactivateOn ? new Date(item.deactivateOn) : null;
    return new CauseItemType({
      id: item.id,
      name: item.level2,
      code: item.level2Code,
      vat: item.vat,
      uiSortIndex: item.uiSortIndex,
      activateOn: new Date(item.activateOn),
      deactivateOn,
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class CauseLevel3ItemTypeAdapter implements Adapter<CauseItemType> {
  adapt(item: any): CauseItemType {
    const deactivateOn = item.deactivateOn ? new Date(item.deactivateOn) : null;
    return new CauseItemType({
      id: item.id,
      name: item.level3,
      code: item.level3Code,
      vat: item.vat,
      uiSortIndex: item.uiSortIndex,
      activateOn: new Date(item.activateOn),
      deactivateOn,
    });
  }
}
