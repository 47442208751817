import { TaskViewModel } from './task-view.model';

export class TaskFilterResponse {
  totalNumber: number;
  taskViewModels: TaskViewModel[];

  public constructor(init?: Partial<TaskFilterResponse>) {
    Object.assign(this, init);
  }
}
