<div class="entry-text-container">
    <div *ngIf="showAll || entry.previousValue === null  || (entry.previousValue !== null && entry.previousValue[attribute] !== entry.newValue[attribute])">
        <span class="historyTabelCell-label" >{{ 'case-economy-history-' + entry.type + '-' + attribute | translate }} : </span>

        <span
        class="entry-text-container historyTabelCell-value"
        >
        <span *ngIf="display =='string'">{{  entry.newValue[attribute] }}</span>
        <span *ngIf="display =='date'">{{  entry.newValue[attribute] | date  :'dd MMM YYYY HH:mm'}}</span>
        <span *ngIf="display =='currency' && entry.newValue[attribute]">{{entry.newValue[attribute].amount | numberFormat}} {{entry.newValue[attribute]?.currency}} </span>
        <span *ngIf="display =='number'">{{entry.newValue[attribute] | numberFormat}} </span>
        </span>
    </div>
</div>
