<app-drop-down *ngIf="_personInsurance"
  [items]="customerProductList"
  [missingItemLabel]="customerProductName"
  [name]="'case-basics-insurance-customerProduct' | translate"
  [(selectedItemId)]="_personInsurance.customerProduct"
  (selectedItemIdChange)="checkIfProductChangeIsPossible()"
  [disabled]="(!!_personInsurance.insuranceLookupPolicy && !!_personInsurance.customerProduct) || !_personInsurance.customerProfileId"
  [placeholder]="placeholder"
  [recommended]="recommended"
></app-drop-down>
