<ng-template #content let-modal>
  <div class="modal-container channel-type-modal" >

    <div class="modal-header">
      <div class="lookup-title">
        {{'case-summary-reported-by-channel' | translate}}
      </div>
      <div class="lookup-close float-end clearfix"><img (click)="close()" src="../../../../../../../assets/icons/Close.svg" alt="Close"></div>

    </div>

    <div class="modal-body">
      <div class="row">
        <div class="col-12 status-container">
          <div class="status">
            <app-drop-down-with-icon
              [appendTo]="'body'"
              [items]="selectableChannelTypes"
              [(selectedItemId)]="selectedChannelType"
            ></app-drop-down-with-icon>
          </div>
        </div>
        <div class="col save">
          <button
            class="save-button"
            (click)="save()"
            [disabled]="disableSave()"
            >
            {{ 'case-summary-channel-type-save' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
