<div class="case-container">
  <div class="case">
    <div class="case-item" [ngClass]="{'case-item-selected': isMenuSelected(CaseSelectedMenuEnum.CaseTask)}" (click)="caseTaskClick()"><span class="shortcut-key">T</span>ask
      <div *ngIf="numberOfPendingTasks" class="tasks-number">{{numberOfPendingTasks}}</div>
    </div>
    <div class="case-item" [ngClass]="{'case-item-selected': isMenuSelected(CaseSelectedMenuEnum.CaseBasics)}" (click)="caseBasicClick()">
      Case <span class="shortcut-key">B</span>asics
    </div>
    <div class="case-item" [ngClass]="{'case-item-selected': isMenuSelected(CaseSelectedMenuEnum.CaseSummary)}"
      (click)="caseActivityLogClick()"><span class="shortcut-key">C</span>ase Summary
      <div *ngIf="numberOfPendingMessageTasks" class="tasks-number">{{numberOfPendingMessageTasks}}</div>
    </div>
    <div class="case-item"
      [ngClass]="{'case-item-selected': isMenuSelected(CaseSelectedMenuEnum.CasePlan)}" (click)="casePlanClick()"><span class="shortcut-key">P</span>lans
    </div>
    <div *permission="PermissionEnum.MEDICAL_READ;hideType: PermissionHideTypeEnum.REMOVE">
      <div class="case-item"
      [ngClass]="{'case-item-selected': isMenuSelected(CaseSelectedMenuEnum.CaseMedical)}" (click)="caseMedicalClick()"><span class="shortcut-key">M</span>edical
      </div>
    </div>
    <div class="case-item" [ngClass]="{'case-item-selected': isMenuSelected(CaseSelectedMenuEnum.CaseEconomy)}"
      (click)="caseEconomyClick()"><span class="shortcut-key">E</span>conomy
    </div>
    <div class="case-item" [ngClass]="{'case-item-selected': isMenuSelected(CaseSelectedMenuEnum.Stakeholders)}"
      (click)="caseStakeholdersClick()">
      <span class="shortcut-key">S</span>takeholders</div>
    <div class="case-item" [ngClass]="{'case-item-selected': isMenuSelected(CaseSelectedMenuEnum.Documents)}"
      (click)="caseDocumentsClick()"><span class="shortcut-key">D</span>ocuments
    </div>
    <div class="case-item" [ngClass]="{'case-item-selected': isMenuSelected(CaseSelectedMenuEnum.About)}" (click)="caseAboutClick()">About
    </div>
  </div>
</div>
