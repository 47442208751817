<div class="message-content p-3" [class.message-content-supplier-portal]="isSupplierPortalChannel && this.taskDataViewModel?.caseValidated">
  <div class="row">
    <div class="col-8">
      <div class="row">
        <div class="col-5">
          <div>
            <div class="message-content-header">
              <div class="heading"><span><img src="/assets/icons/insurance.svg"></span></div>
              <div class="text">{{'task-web-modal-insurance' | translate}}</div>
            </div>
            <div class="message-content-box">
              <app-task-web-properties [properties]="taskDataViewModel?.data?.insurance"
                [ignoredProperties]="getIgnoredProperties('insurance')" [serviceItemList]="serviceItemList"
                [purposeOfTravelItemList]="purposeOfTravelItemList" [causes]="causes" [diagnoses]="diagnoses"
                [customerProfiles]="customerProfiles"></app-task-web-properties>
            </div>
          </div>
        </div>
        <div class="col-1">
          &nbsp;
        </div>
        <div class="col-5">
          <div>
            <div class="message-content-header">
              <div class="heading"><span><img src="/assets/icons/insurance.svg"></span></div>
              <div class="text">{{'task-web-modal-other-insurances' | translate}}</div>
            </div>
            <div class="message-content-box">
              <app-task-web-properties [properties]="taskDataViewModel?.data?.otherInsurance" [ignoredProperties]="[]"
                [serviceItemList]="serviceItemList" [purposeOfTravelItemList]="purposeOfTravelItemList"
                [causes]="causes" [diagnoses]="diagnoses" [customerProfiles]="customerProfiles"></app-task-web-properties>
            </div>
          </div>
        </div>
      </div>
      <br>
    </div>
    <div class="col-4">
      <div class="d-flex">
        <div class="ms-auto align-self-end">
          <div [appDisableInput]="caseStateService.isCaseDisabled()"
            class="caselock-no-events" *ngIf="!isSupplierPortalChannel">
            <button class="primary-button consent-button position-relative" [disabled]="true">
              {{ 'task-web-modal-consent-accepted' | translate }}
              <img src="/assets/icons/Checked-Green.svg">
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br>
  <div class="row">
    <div class="col-8">
      <div class="row">
        <div class="col-5">

          <div *ngIf="endUser">
            <div class="message-content-header">
              <div class="heading">
                <span><img src="/assets/icons/End-User.svg"></span>
                <span *ngIf="reporterIsEndUser || endUserIsReporter"><img src="/assets/icons/Reporter.svg"></span>
                <span *ngIf="(reporterIsEndUser && reporterIsPolicyHolder) || endUserIsPolicyHolder"><img
                    src="/assets/icons/Policy Holder.svg"></span>
                <div class="text">
                  <span>{{'task-web-modal-end-user' | translate}}</span>
                  <span *ngIf="(reporterIsEndUser && reporterIsPolicyHolder) || endUserIsPolicyHolder"> / {{'task-web-modal-policy-holder' |
                    translate}}</span>
                  <span *ngIf="reporterIsEndUser || endUserIsReporter"> / {{'task-web-modal-reporter' | translate}}</span>
                </div>
              </div>
            </div>
            <div class="message-content-box">
              <app-task-web-properties [properties]="endUser" [ignoredProperties]="ignoredProperties"
                [serviceItemList]="serviceItemList" [purposeOfTravelItemList]="purposeOfTravelItemList"
                [causes]="causes" [diagnoses]="diagnoses" [customerProfiles]="customerProfiles"></app-task-web-properties>
            </div>
            <br>
          </div>

          <div *ngIf="policyHolder && !(reporterIsEndUser && reporterIsPolicyHolder) && !endUserIsPolicyHolder">
            <div class="message-content-header">
              <div class="heading">
                <span><img src="/assets/icons/Policy Holder.svg"></span>
                <span *ngIf="reporterIsPolicyHolder"><img src="/assets/icons/Reporter.svg"></span>
                <div class="text">
                  <span>{{'task-web-modal-policy-holder' | translate}}</span>
                  <span *ngIf="reporterIsPolicyHolder"> / {{'task-web-modal-reporter' | translate}}</span>
                </div>
              </div>
            </div>
            <div class="message-content-box">
              <app-task-web-properties [properties]="policyHolder" [ignoredProperties]="ignoredProperties"
                [serviceItemList]="serviceItemList" [purposeOfTravelItemList]="purposeOfTravelItemList"
                [causes]="causes" [diagnoses]="diagnoses" [customerProfiles]="customerProfiles"></app-task-web-properties>
            </div>
            <br>
          </div>

          <div *ngIf="reporter && !reporterIsEndUser && !reporterIsPolicyHolder && !endUserIsReporter">
            <div class="message-content-header">
              <div class="heading">
                <span><img src="/assets/icons/Reporter.svg"></span>
                <div class="text">
                  <span>{{'task-web-modal-reporter' | translate}}</span>
                </div>
              </div>
            </div>
            <div class="message-content-box">
              <app-task-web-properties [properties]="reporter" [ignoredProperties]="ignoredProperties"
                [serviceItemList]="serviceItemList" [purposeOfTravelItemList]="purposeOfTravelItemList"
                [causes]="causes" [diagnoses]="diagnoses" [customerProfiles]="customerProfiles"></app-task-web-properties>
            </div>
            <br>
          </div>

        </div>

        <div class="col-1">
          &nbsp;
        </div>

        <div class="col-5">
          <div class="message-content-header">
            <div class="heading"><span><img src="/assets/icons/incident.png"></span></div>
            <div class="text">{{'task-web-modal-incident' | translate}}</div>
            <div class="action-panel">
              <div class="text action"
                *ngIf="hasHiddenProperties('incident') && hasNonNullHiddenProperty('incident', taskDataViewModel?.data?.incident?.incidentLocation, taskDataViewModel?.data?.incident?.currentLocation)"
                (click)="showMore('incident')">{{'task-web-modal-show-more' | translate}}</div>
              <div class="text action" *ngIf="!hasHiddenProperties('incident')" (click)="showLess('incident')">
                {{'task-web-modal-show-less' | translate}}</div>
            </div>
          </div>
          <div class="message-content-box">
            <app-task-web-properties [properties]="taskDataViewModel?.data?.incident"
              [ignoredProperties]="getIgnoredProperties('incident')" [propertiesDisplayTypes]="propertiesDisplayTypes"
              [serviceItemList]="serviceItemList" [purposeOfTravelItemList]="purposeOfTravelItemList"
              [causes]="causes" [diagnoses]="diagnoses" [customerProfiles]="customerProfiles"></app-task-web-properties>
          </div>
        </div>
      </div>
    </div>
    <div class="col-1">
      &nbsp;
    </div>
    <div class="col-3">
      <div>
        <div class="message-content-header">
          <div class="heading"><span><img src="/assets/icons/travel.png"></span></div>
          <div class="text">{{'task-web-modal-planned-travel' | translate}}</div>
          <div class="action-panel">
            <div class="text action"
              *ngIf="hasHiddenProperties('travel') && hasNonNullHiddenProperty('travel', taskDataViewModel?.data?.plannedTravel)"
              (click)="showMore('travel')">{{'task-web-modal-show-more' | translate}}</div>
            <div class="text action" *ngIf="!hasHiddenProperties('travel')" (click)="showLess('travel')">
              {{'task-web-modal-show-less' | translate}}</div>
          </div>
        </div>
        <div class="message-content-box-planned-travel">
          <app-task-web-properties [properties]="taskDataViewModel?.data?.plannedTravel"
            [ignoredProperties]="getIgnoredProperties('travel')" [serviceItemList]="serviceItemList"
            [purposeOfTravelItemList]="purposeOfTravelItemList" [causes]="causes" [diagnoses]="diagnoses"
            [customerProfiles]="customerProfiles"></app-task-web-properties>
        </div>
        <div class="message-content-box-planned-sub-travels" *ngFor="let subTravel of taskDataViewModel?.data?.plannedSubTravels; index as i">
          <app-task-web-properties [properties]="subTravel?.destinationLocation"
            [ignoredProperties]="getIgnoredProperties('travel')"
            [serviceItemList]="serviceItemList"
            [purposeOfTravelItemList]="purposeOfTravelItemList" [causes]="causes"
            [customerProfiles]="customerProfiles"></app-task-web-properties>
        </div>
      </div>
    </div>
  </div>
  <br>
  <br>
</div>
