import { Component, Input, OnInit } from '@angular/core';
import { SubLuggageReference } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subLuggageReference';

@Component({
  selector: 'app-sub-luggage-reference',
  templateUrl: './sub-luggage-reference.component.html',
  styleUrls: ['./sub-luggage-reference.component.scss']
})
export class SubLuggageReferenceComponent implements OnInit {
  @Input() subLuggageReference: SubLuggageReference;

  constructor() { }

  ngOnInit() {
  }

}
