<div class="case-note-container" fxFlex="grow">
  <div>
    <div (clickOutside)="onClickedOutside()" class="plus-container" (click)="addNewNote()">
      <img src="../../../../../assets/icons/New note.svg">
      <div class="new-note-container" [ngStyle]="showNewNoteDialog && {'display':'block'}">
        <div (click)="addPhoneNote()">Phone note</div>
        <div (click)="addCaseNote()">Case note</div>
      </div>
    </div>
    <ul ngbNav  #nav="ngbNav" [(activeId)]="active" (navChange)="beforeChange($event)" class="nav-tabs">
      <ng-template ngFor let-noteView [ngForOf]="noteViews" let-i="index">
        <li  [ngbNavItem]="noteView.note.id" id='{{noteView.note.id}}'>
          <a ngbNavLink>
            <div class="phone-container">
              <div class="image-container">
                <img src="../../../../../../assets/icons/Phone note focused.svg" [ngClass]="i == 0 && 'first-image'"
                  *ngIf="noteView.isFocused && noteView.note.type == noteTypeEnum.phone">
                <img src="../../../../../../assets/icons/Phone note.svg" [ngClass]="i == 0 && 'first-image'"
                  *ngIf="!noteView.isFocused && noteView.note.type == noteTypeEnum.phone">
                <img src="../../../../../../assets/icons/Case note focused.svg"
                  *ngIf="noteView.isFocused && noteView.note.type == noteTypeEnum.case">
                <img src="../../../../../../assets/icons/Case note.svg"
                  *ngIf="!noteView.isFocused && noteView.note.type == noteTypeEnum.case">
                <img src="../../../../../../assets/icons/Close enabled.svg" class="close-image" (click)="closeTab(noteView,$event)"
                  *ngIf="i > 0 && canClose(noteView)">
                <img src="../../../../../../assets/icons/Close disabled.svg" class="close-image"
                  *ngIf="i > 0 && !canClose(noteView)">
              </div>
            </div>
          </a>
          <ng-template ngbNavContent>
            <div class="position-relative">
            <textarea name="textarea" class="phone-message" appCharCount="bottom-right"
              [class.phone-message-zoomed]="settingsService.isZoomed()"
              [ngClass]="noteView.shouldBeValidated && (noteView.note.description == '' || noteView.note.description == undefined) && 'validation-error'"
              [placeholder]="freeTextFieldPlaceholder" maxlength="8000" [(ngModel)]="noteView.note.description"
              (keyup)="textChanged(noteView, $event)" #caseNotesElement></textarea>
            </div>
            <div *ngIf="noteView.note.type == noteTypeEnum.phone">
              <div class="d-flex drop-downs-row">
                <div class="pe-2 w-60">
                  <app-logged-call [(callType)]="noteView.note.callType" [validate]="noteView.shouldBeValidated"
                  (open)="getLoggedCall()" [items]="loggedCall" [stakeholderType]="stakeholderType"
                  [lastPhoneCallConnectionId]="noteView.note?.connectionCallId"
                  [disabled]="noteView.isEdit"
                  (selectedPhoneConversationChange)="onSelectedPhoneConversationChange(noteView, $event)"
                  (callTypeChange)="onCallTypeChange(noteView, $event)">
                  </app-logged-call>
                </div>
                <div class="ps-2 w-40">
                  <app-drop-down-stakeholder-with-icon [name]="'case-notes-talked-to' | translate" [validate]="noteView.shouldBeValidated" [items]="noteView?.note?.callType === CallType.outgoing ? this.talkedToItemsOutgoing : this.talkedToItems"
                     [(selectedItemId)]="noteView.note.stakeholder" (selectedItemIdChange)="caseStakeholderChanged(noteView)" [required]="false">
                  </app-drop-down-stakeholder-with-icon>
                </div>
              </div>
            </div>
            <button
              class="submit-button"
              [disabled]="isDisabled(noteView)"
              (click)="submit(noteView)"
              [ngClass]="noteView.hasBeenSubmitted && 'submit-button'">{{"Submit"}}</button>
            <div [ngClass]="noteView.note.type == noteTypeEnum.case && 'case-note-space'"></div>
          </ng-template>
        </li>
      </ng-template>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
  </div>
</div>
