import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { Adapter } from '../interfaces/adapter';
import { BalanceSheetEventEnum, BalanceSheetStatusEnum } from '@secca/shared/models/enums';

export interface Modifier {
  status: string
  modifiedBy: string;
  modifiedOn: moment.Moment;
}

export interface BalanceSheetProgress {
  currentEvent: BalanceSheetEventEnum;
  aggregated: boolean;
  events: BalanceSheetEvent[];
}

export interface BalanceSheetEvent {
  event: BalanceSheetEventEnum;
  status: BalanceSheetStatusEnum;
  text: string;
  modifierList: Modifier[];
}

@Injectable({
  providedIn: 'root',
})
export class BalanceSheetAdapter implements Adapter<BalanceSheetProgress> {
  adapt(item: any): BalanceSheetProgress {
    const events: BalanceSheetEvent[] = [];
    if (!!item.events) {
      for(const event of item.events) {
        const modifierList: Modifier[] = [];
        for (const modifier of event.modifierList) {
          modifierList.push({ status: modifier.status,  modifiedBy: modifier.modifiedBy, modifiedOn: moment(modifier.modifiedOn) });
        }
        events.push({ event: event.event, status: event.status, text: event.text, modifierList });
      }
    }
    return { currentEvent: item.currentEvent, aggregated: item.aggregated, events};
  }
}
