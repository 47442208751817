import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { NgxFlagIconCssModule } from 'ngx-flag-icon-css';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    MatDialogModule,
    NgxFlagIconCssModule
  ],
  exports: [
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    MatDialogModule,
    NgSelectModule,
    NgxFlagIconCssModule
  ]
})
export class UtilsModule { }


