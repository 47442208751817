import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ServiceTypeBase } from '../../models/serviceTypeBase';
import { ServiceTypeComponent } from './../../models/interfaces';
import { SubStakeholdersConfig } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholdersConfig';
import { SubCoffinUrn } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subCoffinUrn';
import { SubFuneralHomes } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subFuneralHomes';
import { RegularFlightNonMedicalServiceOrderExtension } from '@secca/shared/models/service-order/regular-flight-non-medical-service-order-extension';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import { TranslateService } from '@ngx-translate/core';
import { ServiceTypeEnum, StakeholderTypeEnum, StatusTypes } from '@secca/shared/models/enums';
import { SubReference } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subReference';
import { SubStakeholder } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholder';
import { SubStakeholders } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholders';
import { CoffinUrnHandlingTypeEnum, RegularFlightType, SelectedTab } from '@secca/case/components/case-plans/case-plans/add-service-order/models/enums';
import { ServiceOrderTypeState } from '@secca/case/components/case-plans/case-plans/add-service-order/models/serviceOrderTypeState';
import { SubTravellers } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subTravellers';
import * as lodash from 'lodash-es';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { Subscription } from 'rxjs';
import { CoordinationCaseService } from '@secca/core/services/coordination-case.service';
import { CoordinationTransportServiceOrderExtension } from '@secca/shared/models/service-order/coordination-transport-service-order-extension';
import { CoordinationCaseDetail } from '@secca/shared/models/coordination-case-details';
import { ServiceOrder } from '@secca/shared/models/service-order/service-order';

@Component({
  selector: 'app-regular-flight-non-medical',
  templateUrl: './regular-flight-non-medical.component.html',
  styleUrls: ['./regular-flight-non-medical.component.scss'],
})
export class RegularFlightNonMedicalComponent extends ServiceTypeBase implements OnInit, OnChanges, ServiceTypeComponent {
  @Input() caseId: string;
  @Input() serviceOrderTypeState: ServiceOrderTypeState;
  @Input() status: StatusTypes;
  //serviceOrderType = ServiceTypeEnum.REGULAR_FLIGHT_NON_MEDICAL;

  typesDropdown: DropdownDictionary[];
  regularFlightType = RegularFlightType;

  type = RegularFlightType.REGULAR_PASSENGERS;
  subStakeholdersConfig: SubStakeholdersConfig;
  linkedEndUserSubStakeholdersConfig: SubStakeholdersConfig;
  $statusChangeSubscr: Subscription;

  @Input() public regularFlightNonMedical: RegularFlightNonMedicalServiceOrderExtension;
  @Input() public stakeholders: CaseStakeholder[];

  @Input() leftSideValid: boolean;
  @Input() serviceOrderId: number;
  @Input() iccServiceOrder: ServiceOrder;

  coordinationServiceOrderExtensions: CoordinationTransportServiceOrderExtension[];
  linkedEndUserCaseDetail: CoordinationCaseDetail;
  linkedEndUser: CaseStakeholder;

  constructor(
    private translate: TranslateService,
    private coordinationService: CoordinationCaseService
  ) {
    super();
    this.initialize();
  }

  ngOnInit() {
    this.setNewDefaultDOBDropdown();
    this.initTypeDropdown();
    this.$statusChangeSubscr = this.serviceOrderTypeState.statusChangeEvent.subscribe(status => {
      if (status === StatusTypes.COMMITTED) {
        this.regularFlightNonMedical.confirmationSubStakeholders =
          lodash.cloneDeep(this.regularFlightNonMedical.expectedSubStakeholders);
        this.regularFlightNonMedical.confirmationSubStakeholders.stakeholders
          .forEach(stakeholder => stakeholder.id = null);
        this.regularFlightNonMedical.confirmationStakeholders =
          this.regularFlightNonMedical.confirmationSubStakeholders.stakeholders;
        this.regularFlightNonMedical.confirmationTravelInfo[0] = lodash.cloneDeep(this.regularFlightNonMedical.expectedTravelInfo);
        this.regularFlightNonMedical.confirmationTravelInfo[0].id = null;
        this.serviceOrderTypeState.statusChangeEvent.emit(null);
      } else if (status === StatusTypes.EXPECTED) {
        this.regularFlightNonMedical.confirmationSubStakeholders = new SubStakeholders();
        this.regularFlightNonMedical.confirmationStakeholders =
          this.regularFlightNonMedical.confirmationSubStakeholders.stakeholders;
        this.regularFlightNonMedical.confirmationSubTravellers = new SubTravellers();
        this.serviceOrderTypeState.statusChangeEvent.emit(null);
      }
    });
    this.linkedEndUserSubStakeholdersConfig = new SubStakeholdersConfig();
    this.linkedEndUserSubStakeholdersConfig.requireAtLeastOneStakeholder = true;
    this.linkedEndUserSubStakeholdersConfig.hideClass = true;
    this.linkedEndUserSubStakeholdersConfig.hideInclPassportDetails = true;
    this.linkedEndUserSubStakeholdersConfig.hideInclDateOfBirth = true;
    this.linkedEndUserSubStakeholdersConfig.mandatoryAndSetStakeholderType = StakeholderTypeEnum.endUser;
    this.linkedEndUserSubStakeholdersConfig.hideAddButton = true;

    this.setStakeholdersConfigByType();
    this.coordinationService.getCoordinationCaseServiceOrdersFromLinkedCase(+this.caseId).subscribe(serviceOrders => {
      this.coordinationServiceOrderExtensions = serviceOrders.filter(serviceOrder => serviceOrder.type === ServiceTypeEnum.COORDINATION_TRANSPORT && serviceOrder.status !== StatusTypes.CANCELLED).map(
        service => {
          let extension = service.extension as CoordinationTransportServiceOrderExtension;
          extension.serviceOrderId = service.id;
          return extension;
        }
      )
      if (this.coordinationServiceOrderExtensions.length > 0) {
        this.serviceOrderTypeState.isLockedByCoordinationTransport = this.coordinationService.serviceOrderUsedInCoordinationTransportCase(this.serviceOrderId, serviceOrders);
        if(this.serviceOrderTypeState.isLockedByCoordinationTransport && this.serviceOrderTypeState.lockedByCoordinationTransportCaseNumber === undefined) {
          this.coordinationService.getCoordinationCasesForLinkedCase(this.caseId).subscribe(
          result => {
            this.serviceOrderTypeState.lockedByCoordinationTransportCaseNumber = result.length > 0 ? result[0].caseNumber : '';
            this.coordinationService.coordinationCaseNumberChanged(this.caseId, this.serviceOrderTypeState.lockedByCoordinationTransportCaseNumber);
          });
        }
        this.coordinationService.getLinkedCaseDetails(this.caseId).subscribe(linkedCaseDetail => {
          this.linkedEndUserCaseDetail = linkedCaseDetail;
          this.activeTab = this.serviceOrderTypeState.showConfirmationTab ? this.linkedEndUserCaseDetail.caseId : SelectedTab.ExpectationTab;
          this.linkedEndUser = linkedCaseDetail.stakeholders.find(value => {
            return value.hasRole(StakeholderTypeEnum.endUser);
          });
        });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (this.iccServiceOrder && changes.status && changes['status'].previousValue == StatusTypes.COMMITTED && changes['status'].currentValue == StatusTypes.CANCELLED){
      this.activeTab = SelectedTab.ExpectationTab;
    }
    if (changes.iccServiceOrder  && changes.iccServiceOrder.currentValue && changes.iccServiceOrder.currentValue.status == StatusTypes.COMMITTED) {
      setTimeout( () => { this.activeTab = this.iccServiceOrder.id; }, 300 );
    }

  }

  initTypeDropdown() {
    this.typesDropdown = [
      {
        id: RegularFlightType.REGULAR_PASSENGERS,
        name: this.translate.instant(`regular-flight-non-medical-type.${RegularFlightType.REGULAR_PASSENGERS}`),
      },
      {
        id: RegularFlightType.COFFIN,
        name: this.translate.instant(`regular-flight-non-medical-type.${RegularFlightType.COFFIN}`),
      },
      {
        id: RegularFlightType.URN,
        name: this.translate.instant(`regular-flight-non-medical-type.${RegularFlightType.URN}`),
      },
    ];
  }

  setNewDefaultDOBDropdown(): void {
    if(this.regularFlightNonMedical.expectedSubStakeholders.stakeholders[0] && !this.regularFlightNonMedical.expectedSubStakeholders.stakeholders[0].id){
      this.regularFlightNonMedical.expectedSubStakeholders.stakeholders = [
        new SubStakeholder({
          includeDateOfBirth: 'YES'
        })
      ];
    }
    this.regularFlightNonMedical.expectedStakeholders = this.regularFlightNonMedical.expectedSubStakeholders.stakeholders;
  }

  isValid(): boolean {
    let result: boolean;
    if (!this.serviceOrderTypeState.showConfirmationTab) {
      result = this.regularFlightNonMedical.isValidExpectation();
    }
    if (this.serviceOrderTypeState.showConfirmationTab) {
      result = this.regularFlightNonMedical.isValid();
    }
    return result;
  }

  getModel(): any {
    return this.regularFlightNonMedical;
  }

  isCommitted(): boolean {
    return this.status === StatusTypes.COMMITTED;
  }

  disableSendRequest(): boolean {
    return !(this.isValid() && !!this.leftSideValid && this.serviceOrderTypeState.isSupplierChosen);
  }

  initialize() {
    this.subStakeholdersConfig = new SubStakeholdersConfig();
    this.subStakeholdersConfig.requireAtLeastOneStakeholder = true;
  }

  selectType(typeDropdownItem: DropdownDictionary) {
    this.setStakeholdersConfigByType();
    if (typeDropdownItem.id != null && typeDropdownItem.id !== RegularFlightType.REGULAR_PASSENGERS) {
      this.tryToAddEndUserAsDefaultStakeholderWhenAdding();
    }
    if (typeDropdownItem.id != null && typeDropdownItem.id === RegularFlightType.COFFIN) {
      this.regularFlightNonMedical.coffinUrn.handling = CoffinUrnHandlingTypeEnum.COFFIN_SENT_HOME_BY_REGULAR_FLIGHT;
    }
    if (typeDropdownItem.id != null && typeDropdownItem.id === RegularFlightType.URN) {
      this.regularFlightNonMedical.coffinUrn.handling = CoffinUrnHandlingTypeEnum.URN_SENT_HOME_BY_REGULAR_FLIGHT;
    }
  }

  tryToAddEndUserAsDefaultStakeholderWhenAdding() {
    if (this.stakeholders != null && this.regularFlightNonMedical != null) {
      this.regularFlightNonMedical.expectedSubStakeholders.stakeholders = [
        new SubStakeholder({
          stakeholderId: this.stakeholders.find(a => a.hasRole(StakeholderTypeEnum.endUser)).id,
          stakeholderType: StakeholderTypeEnum.endUser,
        })
      ];
      this.regularFlightNonMedical.expectedStakeholders = this.regularFlightNonMedical.expectedSubStakeholders.stakeholders;
    }
  }

  setStakeholdersConfigByType() {
    if (this.regularFlightNonMedical.type === RegularFlightType.REGULAR_PASSENGERS) {
      this.regularFlightNonMedical.reference = new SubReference();
      this.regularFlightNonMedical.coffinUrn = new SubCoffinUrn();
      this.regularFlightNonMedical.funeralHomes = new SubFuneralHomes();

      this.subStakeholdersConfig.hideClass = false;
      this.subStakeholdersConfig.hideInclDateOfBirth = false;
      this.subStakeholdersConfig.hideInclPassportDetails = false;
      this.subStakeholdersConfig.requireSingleStakeholder = false;
      this.subStakeholdersConfig.onlyPersonTypeStakeholders = false;
    }
    else {
      this.subStakeholdersConfig.hideClass = true;
      this.subStakeholdersConfig.hideInclDateOfBirth = true;
      this.subStakeholdersConfig.hideInclPassportDetails = true;
      this.subStakeholdersConfig.requireSingleStakeholder = true;
      this.subStakeholdersConfig.onlyPersonTypeStakeholders = true;
      this.subStakeholdersConfig.hideTrashIcon = true;
      this.subStakeholdersConfig.hideAddButton = true;
    }

    this.subStakeholdersConfig.availableStakeholderTypes = [
      StakeholderTypeEnum.endUser,
      StakeholderTypeEnum.policyHolder,
      StakeholderTypeEnum.reporter,
      StakeholderTypeEnum.escortDoctor,
      StakeholderTypeEnum.escortNurse,
      StakeholderTypeEnum.person,
      StakeholderTypeEnum.nonMedicalEscort
    ];
  }

  sendConfirmation() {
     this.serviceOrderTypeState.sendConfirmationEvent.emit();
  }
}
