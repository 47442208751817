import { Injectable } from '@angular/core';
import { DineroObject } from 'dinero.js';
import { Adapter } from '../interfaces/adapter';

export class SupplierExpensesAppendix {
  id: number;
  caseId: number;
  serviceOrderId: string;
  documentId: string;
  amountInvoice: DineroObject;
  amountSavings: DineroObject;
  amountSettled: DineroObject;

  public constructor(init?: Partial<SupplierExpensesAppendix>) {
    Object.assign(this, init);
  }
}
@Injectable({
  providedIn: 'root',
})
export class SupplierExpensesAppendixAdapter implements Adapter<SupplierExpensesAppendix> {
  adapt(item: any): SupplierExpensesAppendix {
    const supplierExpenseAppendix = new SupplierExpensesAppendix({
      id: item.id,
      caseId: item.caseId,
      serviceOrderId: item.serviceOrderId,
      documentId: item.documentId,
      amountInvoice: item.amountInvoice,
      amountSavings: item.amountSavings,
      amountSettled: item.amountSettled,
    });
    return supplierExpenseAppendix;
  }
}
