import { TeamDto } from './teamDto';
import { UserDto } from './userDto';
import { HandlingAreaStatusEnum, HandlingAreaType } from './enums';
import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
export class HandlingArea {
    status: HandlingAreaStatusEnum;
    handlingAreaType: HandlingAreaType;
    team: TeamDto;
    user: UserDto;
    id: number;
    closeValidations: String[];

    public constructor(init?: Partial<HandlingArea>) {
      Object.assign(this, init);
    }
}

@Injectable({
    providedIn: 'root'
})

export class HandlingAreaAdapter implements Adapter<HandlingArea> {
    adapt(item: any): HandlingArea {
        return new HandlingArea({
            handlingAreaType: item.handlingAreaType,
            team: item.team,
            status: item.status,
            user: item.user,
            id: item.id,
            closeValidations: item.closeValidations
        });
    }
}