<div class="document-filter-search-box">
  <div class="row">
    <div class="col-3">
      <div class="tags-filter">
        <app-drop-down  [closeOnSelect]="false" [multiSelectedLabel]="'case-document-filter-some-selected' | translate:{'number': numberOfSelectedTags}" [items]="tags" [multiple]="true" [selectedItemId]="''" 
        (selectedItemIdChange)="selectedItemIdChange($event)"></app-drop-down>
      </div>
    </div>
    <div class="col-6"></div>
    <div class="col-3">
      <div class="search-container">
        <app-input [withImage]="true" [placeholder]="'Search'" (valueChange)="changeSearchFilterText($event)"></app-input>
      </div>
    </div>
  </div>
</div>