<div class="d-flex" [class.even]="index % 2">
    <div class="history-table-cell align-top">
            <app-user-picture [imageURL]="entry.picture" [active]="'true'" [imageClass]="'caseSummery'">
            </app-user-picture>
            <span class="headline initials" *ngIf="!entry.previousValue">
                {{entry.initials}} {{'case-history-economy-supplier-invoice-item-created' | translate }} {{entry.newValue.id}}
            </span>
            <span class="headline initials" *ngIf="entry.previousValue  && !entry.newValue.deletedOn">
                {{entry.initials}} {{'case-history-economy-supplier-invoice-item-updated' | translate }} {{entry.newValue.id}}
            </span>
            <span class="headline initials" *ngIf="entry.previousValue && entry.newValue.deletedOn">
                {{entry.initials}} {{'case-history-economy-supplier-invoice-item-deleted' | translate }} {{entry.newValue.id}}
            </span>
            <div class="elementDateTime">{{ entry.newValue.modifiedOn | date: 'd MMM y - HH:mm' }}</div>
        </div>
        <div class="history-table-cell align-top">
            <div class="headline">
                <span *ngIf="entry.previousValue">
                    {{ 'case-history-list-original-value' | translate }}
                </span>
            </div>
            <app-previous-value [entry]="entry" [attribute]="'supplierInvoiceId'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'serviceOrderId'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'serviceItemCode'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'serviceItemName'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'quantity'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'quantityUnit'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'paidAmount'"></app-previous-value>
        </div>
        <div class="history-table-cell align-top" *ngIf="!entry.newValue.deletedOn">
            <div class="headline">
                <span>
                    {{ 'case-history-list-new-value' | translate }}
                </span>
            </div>
            <app-new-value [entry]="entry" [attribute]="'supplierInvoiceId'"></app-new-value>
            <app-new-value [entry]="entry" [attribute]="'serviceOrderId'"></app-new-value>
            <app-new-value [entry]="entry" [attribute]="'serviceItemCode'"></app-new-value>
            <app-new-value [entry]="entry" [attribute]="'serviceItemName'"></app-new-value>
            <app-new-value [entry]="entry" [attribute]="'quantity'"></app-new-value>
            <app-new-value [entry]="entry" [attribute]="'quantityUnit'"></app-new-value>
            <app-new-value [entry]="entry" [attribute]="'paidAmount'"></app-new-value>
        </div>
        <div class="history-table-cell align-top" *ngIf="entry.newValue.deletedOn">
            <div class="headline">
                <span class="red-text">
                    {{ 'case-history-economy-deleted' | translate }}
                </span>
            </div>
        </div>

    </div>
