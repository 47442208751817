<div class="case-task-container">
  <ul ngbNav (navChange)="onTabChange($event)" [(activeId)]="activeTab" #nav="ngbNav" class="nav-tabs">
    <li id="{{TabsEnum.TO_DO}}" [ngbNavItem]="TabsEnum.TO_DO">
      <a ngbNavLink>
        <div class="tasks-content-tab-header"> {{"case-tasks-board-open-tasks" | translate}}
          ({{toDoSimpleTaskViewModelsForCase?.length}})</div>
        </a>
      <ng-template ngbNavContent>
        <div class="top-tasks-label">
          <div class="float-start">
            <app-input-time class="task-start-date" [selectMode]="'single'" [dateTimeRange]="datesRange.fromDate" [withImage]="true"
              (dateTimeRangeChange)="onStartDateAndTimeChange($event, TaskStatus.toDo)" [placeholder]="dateTimePlaceholder">
            </app-input-time>
            <app-input-time class="task-end-date" [selectMode]="'single'" [withImage]="true" [dateTimeRange]="datesRange.toDate"
              (dateTimeRangeChange)="onEndDateAndTimeChange($event, TaskStatus.toDo)" [placeholder]="dateTimePlaceholder"></app-input-time>
          </div>
          <div class="float-end">
            <span *permission="PermissionEnum.TASK_CREATE"
              (mouseover)="getCaseValidationText()"
              placement="bottom"
              [ngbPopover]="iconTextPopOver"
              [autoClose]="false"
              triggers="hover"
              [disablePopover]="disableNewTaskHover()"
            >
              <button
                (click)="openCreateTask()"
                [ngClass]="{'add-task-button-disabled': !enableTaskButton(), 'add-task-button': enableTaskButton()}"
                [disabled]="!enableTaskButton()"
              ><!--span class="shortcut-key">A</span-->Add task</button>
            </span>
            <ng-template #iconTextPopOver>
              {{"case-tasks-case-validation" | translate}}
              <ul>
                <li *ngFor="let item of caseValidation?.caseValidateList">
                  <div>{{ item }}</div>
                </li>
              </ul>
            </ng-template>
            <span [appDisableInput]="caseStateService.isCaseDisabled()">
            <span *permission="PermissionEnum.TASK_UPDATE">
              <button class="task-reassign-button" (click)="reassignTasks()"
                [class.task-reassign-button-zero-check]="numberOfReassignedTasksToDo===0">{{"board-task-reassign" | translate}}
                ({{numberOfReassignedTasksToDo}})</button>
            </span>
            </span>
            <button class="task-refresh-button" (click)="refreshTasksClick(TaskStatus.toDo)">{{"board-task-refresh" | translate}} <img
                src="../../../../assets/icons/refresh.svg"></button>
          </div>
        </div>
        <app-case-task-table [taskStatus]="TaskStatus.toDo" [simpleTaskViewModels]="filteredToDoSimpleTaskViewModelsForCase">
        </app-case-task-table>
      </ng-template>
    </li>
    <li id="{{TabsEnum.DONE}}" [ngbNavItem]="TabsEnum.DONE">
      <a ngbNavLink>
        <div class="tasks-content-tab-header">{{"case-tasks-board-done-tasks" | translate}}</div>
      </a>
      <ng-template ngbNavContent>
        <div class="top-tasks-label">
          <div class="float-start">
            <app-input-time class="task-start-date" [selectMode]="'single'" [withImage]="true" [dateTimeRange]="datesRange.fromDate"
              (dateTimeRangeChange)="onStartDateAndTimeChange($event, TaskStatus.done)" [placeholder]="dateTimePlaceholder">
            </app-input-time>
            <app-input-time class="task-end-date" [selectMode]="'single'" [withImage]="true" [dateTimeRange]="datesRange.toDate"
              (dateTimeRangeChange)="onEndDateAndTimeChange($event, TaskStatus.done)" [placeholder]="dateTimePlaceholder"></app-input-time>
          </div>
          <div class="float-end">
            <button class="task-refresh-button" (click)="refreshTasksClick(TaskStatus.done)">{{"board-task-refresh" | translate}} <img
                src="../../../../assets/icons/refresh.svg"></button>
          </div>
        </div>
        <app-case-task-table [taskStatus]="TaskStatus.done" [simpleTaskViewModels]="filteredSimpleDoneTaskViewModelsForCase">
        </app-case-task-table>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</div>
<ng-template #taskReassignModalContent let-modal>
  <app-task-reassign [tasksForReassignSimpleTaskViewModel]="tasksToReassign" [caseId]="caseId"></app-task-reassign>
</ng-template>
