import { Injectable } from '@angular/core';
import { ManageSupplierInvoiceDialogData } from '@secca/case/components/case-economy/manage-supplier-invoice/manage-supplier-invoice-dialog/manage-supplier-invoice-dialog-data.component';
import { ManageSupplierInvoiceDialogComponent } from '@secca/case/components/case-economy/manage-supplier-invoice/manage-supplier-invoice-dialog/manage-supplier-invoice-dialog.component';
import { OutputManagementDialogData } from '@secca/case/components/case-output-management/output-management-dialog/output-management-dialog-data.component';
import { OutputManagementDialogComponent } from '@secca/case/components/case-output-management/output-management-dialog/output-management-dialog.component';
import { AddServiceOrderDialogData } from '@secca/case/components/case-plans/case-plans/add-service-order/add-service-order-dialog/add-service-order-dialog-data.component';
import { AddServiceOrderDialogComponent } from '@secca/case/components/case-plans/case-plans/add-service-order/add-service-order-dialog/add-service-order-dialog.component';
import { Case } from '@secca/shared/models/case';
import { AssessmentTypeEnum, MessageChannelType } from '@secca/shared/models/enums';
import { ServiceOrderMessageRequest } from '@secca/shared/models/service-order/service-order-message-request';
import { DialogHelperUtilService } from '@secca/core/services/dialog-helper-util.service';
import { CaseEconomyClaimsComponent } from '@secca/case/components/case-economy/case-economy-add-claims-dialog/case-economy-add-claims-dialog.component';
import { ManageClaimsDialogData } from '@secca/case/components/case-economy/case-economy-add-claims-dialog/manage-claims-dialog-data.component';
import { SearchCase } from '@secca/shared/models/searchCase';
import { CaseNewDialogData } from '@secca/case/components/case-new/case-new-dialog/case-new-dialog-data.component';
import { CaseNewDialogComponent } from '@secca/case/components/case-new/case-new-dialog/case-new-dialog.component';
import { MedicalAssessmentDialogComponent } from '@secca/case/components/case-medical/medical-assessment/medical-assessment-dialog/medical-assessment-dialog.component';
import { MedicalPreAssessmentDialogComponent } from '@secca/case/components/case-medical/medical-pre-assessment/medical-pre-assessment-dialog/medical-pre-assessment-dialog.component';
import { MedicalAssessmentDialogData } from '@secca/case/components/case-medical/medical-assessment/medical-assessment-dialog/medical-assessment-data.component';
import { ProfileLookupDialogData } from '@secca/case/components/case-basics/case-basics-insurance/profile-lookup-dialog/profile-lookup-dialog-data.component';
import { ProfileLookupDialogComponent } from '@secca/case/components/case-basics/case-basics-insurance/profile-lookup-dialog/profile-lookup-dialog.component';
import { PolicyLookupDialogData } from '@secca/case/components/case-basics/case-basics-insurance/policy-lookup-dialog/policy-lookup-dialog-data.component';
import { PolicyLookupDialogComponent } from '@secca/case/components/case-basics/case-basics-insurance/policy-lookup-dialog/policy-lookup-dialog.component';
import { DigitalConsent } from '@secca/shared/models/digital-consent';
import { CaseDocumentDialogComponent } from '@secca/case/components/case-documents/case-document-dialog/case-document-dialog.component';
import { CaseDocumentDialogData } from '@secca/case/components/case-documents/case-document-dialog/case-document-dialog-data.component';
import { CaseDocumentTableRow } from '@secca/case/components/case-documents/case-document-table/case-document-table-row';
import { AttachmentDto } from '@secca/shared/models/attachment-dto';
import { CaseSummaryEmailFaxDialogComponent } from '@secca/case/components/case-summery/case-summary-list/case-summary-email-fax/case-summary-email-fax-dialog/case-summary-email-fax-dialog.component';
import { CaseHistoryCommEntry } from '@secca/shared/models/caseHistoryEntry';
import { CaseSummaryEmailFaxDialogData } from '@secca/case/components/case-summery/case-summary-list/case-summary-email-fax/case-summary-email-fax-dialog/case-summary-email-fax-dialog-data.component';
import { CaseSummaryExternalDialogData } from '@secca/case/components/case-summery/case-summary-list/case-summary-claims/case-summary-external-dialog/case-summary-external-dialog-data.component';
import { CaseSummaryExternalDialogComponent } from '@secca/case/components/case-summery/case-summary-list/case-summary-claims/case-summary-external-dialog/case-summary-external-dialog.component';
import { CaseSummarySmsDialogComponent } from '@secca/case/components/case-summery/case-summary-sms/case-summary-sms-dialog/case-summary-sms-dialog.component';
import { CaseSummarySmsDialogData } from '@secca/case/components/case-summery/case-summary-sms/case-summary-sms-dialog/case-summary-sms-dialog-data.component';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { TaskViewModel } from '@secca/board/components/board-task/models/task-view.model';
import { TaskWebDialogData } from '@secca/board/components/board-task/board-task-incoming-cases-table/task-web-dialog/task-web-dialog-data.component';
import { TaskWebDialogComponent } from '@secca/board/components/board-task/board-task-incoming-cases-table/task-web-dialog/task-web-dialog.component';
import { TaskConsentDialogData } from '@secca/board/components/board-task/board-task-table/task-consent-dialog/task-consent-dialog-data.component';
import { TaskConsentDialogComponent } from '@secca/board/components/board-task/board-task-table/task-consent-dialog/task-consent-dialog.component';
import { TaskMessageDialogComponent } from '@secca/board/components/board-task/board-task-table/task-message-dialog/task-message-dialog.component';
import { TaskMessageDialogData } from '@secca/board/components/board-task/board-task-table/task-message-dialog/task-message-dialog-data.component';
import { CaseSummaryNoteDialogComponent } from './components/case-summery/case-summary-list/case-summary-note/case-summary-note-dialog/case-summary-note-dialog.component';
import { CaseDocument } from '@secca/shared/models/caseDocument';
import { CaseSummaryNoteDialogData } from './components/case-summery/case-summary-list/case-summary-note/case-summary-note-dialog/case-summary-note-dialog-data.component';
import { PolicyViewDialogComponent } from './components/case-basics/case-basics-insurance/policy-view-dialog/policy-view-dialog.component';
import { MedicalReportSupplierPortalDto } from '@secca/shared/models/medicalHistory';
import { MedicalReportSupplierDialogData } from './components/case-medical/medical-report-supplier-dialog/medical-report-supplier-dialog-data.component';
import { MedicalReportSupplierDialogComponent } from './components/case-medical/medical-report-supplier-dialog/medical-report-supplier-dialog.component';
import { MinimizableDialogType } from '@secca/shared/enums/minimizable-dialog-type-enum';
import { DialogStateService } from '@secca/core/state-services/dialog-state.service';
import {
  PreviewDocumentDialogData
} from '@secca/case/../../shared/components/preview-document-dialog/preview-document-dialog-data';
import {
  PreviewDocumentDialogComponent
} from '@secca/case/../../shared/components/preview-document-dialog/preview-document-dialog.component';
import {
  CaseSummaryNoteLinkedCaseDialogData
} from "@secca/case/components/case-summery/case-summary-list/case-summary-note/case-summary-note-linked-case-dialog/case-summary-note-linked-case-data.component";
import {
  CaseSummaryNoteLinkedCaseDialogComponent
} from "@secca/case/components/case-summery/case-summary-list/case-summary-note/case-summary-note-linked-case-dialog/case-summary-note-linked-case-dialog.component";
import { MedicalPreAssessmentDialogData } from './components/case-medical/medical-pre-assessment/medical-pre-assessment-dialog/medical-pre-assessment-data.component';
import { CaseEconomyAddRecoveryDialogComponent } from './components/case-economy/case-economy-add-recovery-dialog/case-economy-add-recovery-dialog.component';
import { CaseEconomyRecoveryDialogData } from './components/case-economy/case-economy-add-recovery-dialog/case-economy-add-recovery-dialog-data.component';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import {
  SearchServiceProviderComponent
} from '@secca/case/components/case-plans/case-plans/add-service-order/search-service-provider/search-service-provider.component';
import {
  SupplierDialogData, SupplierSearchData
} from '@secca/case/components/case-plans/case-plans/add-service-order/search-service-provider/SupplierDialogData';
import {DigitalCaseflowLog} from "@secca/shared/models/digital-caseflow-log";
import {
  CaseSummaryDigitalCaseflowDialogComponent
} from "@secca/case/components/case-summery/case-summary-list/case-summary-dcf/case-summary-dcf-dialog/case-summary-digital-caseflow-dialog.component";
import {
  CaseSummaryDigitalCaseflowDialogData
} from "@secca/case/components/case-summery/case-summary-list/case-summary-dcf/case-summary-dcf-dialog/case-summary-digital-caseflow-dialog-data.component";
import {
  StakeholderSupplierSearchDialogComponent
} from '@secca/case/components/case-stakeholders/case-stakeholders-table/case-stakeholders-info/stakeholder-supplier-search-dialog/stakeholder-supplier-search-dialog.component';
import { RecoveryPayment } from '@secca/shared/models/recoveryPayment';
import {
  CaseEconomyRecoveryPaymentDialogComponent
} from '@secca/case/components/case-economy/case-economy-recovery-payment-dialog/case-economy-recovery-payment-dialog.component';
import {
  CaseCountryInfoDialogComponent
} from '@secca/case/components/case-country-info/case-country-info-dialog/case-country-info-dialog.component';
import {
  CaseCountryInfoDialogInitData
} from '@secca/case/components/case-country-info/case-country-info-dialog/case-country-info-dialog-init-data';
import {
  CaseEconomyRecoveryPaymentDialogData
} from "@secca/case/components/case-economy/case-economy-recovery-payment-dialog/case-economy-recovery-payment-dialog-data.component";

@Injectable({
  providedIn: 'root',
})
export class CaseDialogViewerService {
  constructor(private dialogHelperUtilService: DialogHelperUtilService,
    private dialogStateService: DialogStateService) { }

  openManageSupplierInvoiceDialog(
    supplierInvoiceId: number,
    customerProfileId: number,
    caseNumber: string,
    caseCreatedDate: Date,
    supplierInvoiceSaved?: any
  ): MatDialogRef<any> {
    const data: ManageSupplierInvoiceDialogData = {
      supplierInvoiceId,
      customerProfileId,
      caseNumber,
      caseCreatedDate,
      supplierInvoiceSaved,
    };

    const dialogConfig = {
      id: supplierInvoiceId ? `SupplierInvoice-${supplierInvoiceId}` : undefined,
      width: '1438px',
      data,
    };

    return this.dialogHelperUtilService.open(ManageSupplierInvoiceDialogComponent, dialogConfig);
  }

  openServiceOrderDialog(serviceOrderId: number, caseId: string, caseNumber: string, incidentId: number): MatDialogRef<any> {
    const data: AddServiceOrderDialogData = {
      serviceOrderId,
      caseId,
      caseNumber,
      incidentId,
    };

    const dialogConfig = {
      id: serviceOrderId ? `ServicePlan-${serviceOrderId}` : undefined,
      width: '1470px',
      data,
    };

    // Speciel service order handling, because a service order dialog can be opened without an serviceOrderId, but can be created(and not closed) if a preview is triggered
    if (serviceOrderId) {
      const dialogRef: MatDialogRef<any> = this.dialogHelperUtilService.getOpenDialogByAdditionaInfoAndType(
        (additionalInfo) => {
          return +additionalInfo.serviceOrderId === +serviceOrderId;
        },
        MinimizableDialogType.ServicePlan
      );
      if (dialogRef) {
        this.dialogStateService.maximize(dialogRef.id);
        return dialogRef;
      }
    }

    return this.dialogHelperUtilService.open(AddServiceOrderDialogComponent, dialogConfig);
  }

  openPreviewDocumentDialog(id: number, arrayBufferFromBlob: any, caseId: string, caseNumber: string): MatDialogRef<any> {
    const data: PreviewDocumentDialogData = {
      id,
      arrayBufferFromBlob,
      disableShortcuts: false,
      caseId,
      caseNumber
    };

    const dialogConfig = {
      id: id ? `Document-${id}-Preview` : undefined,
      width: '1150px',
      data,
    };

    return this.dialogHelperUtilService.open(PreviewDocumentDialogComponent, dialogConfig);
  }

  openOutputManagementDialog(seccaCase: Case, messageChannelType: MessageChannelType,
    messageRequest: ServiceOrderMessageRequest,
    consent: DigitalConsent, messageSentEvent?: any, hasBackDrop: boolean = false): MatDialogRef<any> {
    const data: OutputManagementDialogData = {
      seccaCase,
      messageChannelType,
      messageRequest,
      consent,
      messageSentEvent,
      isMinimizeIconHidden: hasBackDrop,
      disableShortcuts: hasBackDrop,
    };

    const dialogConfig = {
      width: '1150px',
      hasBackdrop: hasBackDrop,
      data
    };

    return this.dialogHelperUtilService.open(OutputManagementDialogComponent, dialogConfig);
  }

  openManageClaimsDialog(caseId: string, objection: boolean, customerProfileId: string, refundId: string, readOnly: boolean, caseCreatedDate: Date, refundSaved?: any, refundDeleted?: any): MatDialogRef<any> {
    const data: ManageClaimsDialogData = {
      caseId,
      objection,
      customerProfileId,
      refundId,
      readOnly,
      caseCreatedDate,
      refundSaved,
      refundDeleted
    }

    const dialogConfig = {
      id: refundId ? `ManageClaims-${refundId}` : undefined,
      width: '1600px',
      data
    };

    return this.dialogHelperUtilService.open(CaseEconomyClaimsComponent, dialogConfig);
  }

  openManageRecoveryDialog(caseId: string, customerProfileId: string, recoveryId: string, readOnly: boolean, caseCreatedDate: Date, recoverySaved?: any, recoveryDeleted?: any): MatDialogRef<any> {
    const data: CaseEconomyRecoveryDialogData = {
      caseId,
      customerProfileId,
      recoveryId,
      readOnly,
      caseCreatedDate,
      recoverySaved,
      recoveryDeleted
    }

    const dialogConfig = {
      id: recoveryId ? `ManageRecovery-${recoveryId}` : undefined,
      width: '1600px',
      data
    };

    return this.dialogHelperUtilService.open(CaseEconomyAddRecoveryDialogComponent, dialogConfig);
  }

  openSearchCaseDialog(searchCase?: SearchCase): MatDialogRef<any> {
    const data: CaseNewDialogData = {
      searchCase,
    };

    const dialogConfig = {
      id: 'SearchCase',
      width: '1810px',
      data,
    };

    return this.dialogHelperUtilService.open(CaseNewDialogComponent, dialogConfig);
  }

  openMedicalAssessmentDialog(
    seccaCase: Case,
    medicalAssessmentType: AssessmentTypeEnum,
    updateDraftButtonEvent?: any,
    refreshMedicalHistoryEvent?: any,
    medicalAssessmentId?: number,
    disabledViewOfMedicalAssessment?: boolean
  ): MatDialogRef<any> {
    const data: MedicalAssessmentDialogData = {
      case: seccaCase,
      medicalAssessmentId,
      disabledViewOfMedicalAssessment,
      medicalAssessmentType,
      updateDraftButtonEvent,
      refreshMedicalHistoryEvent,
    };

      const dialogConfig = {
        id: seccaCase && seccaCase.id ? `MedicalAssessment-${seccaCase.id}-${medicalAssessmentId}` : undefined,
        width: '1150px',
        data,
      };

      return this.dialogHelperUtilService.open(MedicalAssessmentDialogComponent, dialogConfig);
    }


  openPreMedicalAssessmentDialog(
    seccaCase: Case,
    medicalAssessmentType: AssessmentTypeEnum,
    updateDraftButtonEvent?: any,
    refreshMedicalHistoryEvent?: any,
    medicalPreAssessmentId?: number,
    disabledViewOfMedicalAssessment?: boolean
  ): MatDialogRef<any> {
    const data: MedicalPreAssessmentDialogData = {
      case: seccaCase,
      medicalPreAssessmentId,
      disabledViewOfMedicalAssessment,
      medicalAssessmentType,
      updateDraftButtonEvent,
      refreshMedicalHistoryEvent,
    };

      const dialogConfig = {
        id: seccaCase && seccaCase.id ? `MedicalPreAssessment-${seccaCase.id}-${medicalPreAssessmentId}` : undefined,
        width: '1150px',
        data,
      };

      return this.dialogHelperUtilService.open(MedicalPreAssessmentDialogComponent, dialogConfig);
    }

    openProfileLookupDialog(caseId: string, caseNumber ?: string): MatDialogRef < any > {
      const data: ProfileLookupDialogData = {
        caseId,
        caseNumber,
      };

      const dialogConfig = {
        id: caseId ? `ProfileLookup-${caseId}` : undefined,
        width: '1600px',
        data,
      };

      return this.dialogHelperUtilService.open(ProfileLookupDialogComponent, dialogConfig, true);
    }

    openPolicyLookupDialog(caseId: string, caseNumber ?: string): MatDialogRef < any > {
      const data: PolicyLookupDialogData = {
        caseId,
        caseNumber
      };

      const dialogConfig = {
        id: caseId ? `PolicyLookup-${caseId}` : undefined,
        width: '1600px',
        data
      };

      return this.dialogHelperUtilService.open(PolicyLookupDialogComponent, dialogConfig);
    }

    openCaseSummaryEmailFaxDialog(entry: CaseHistoryCommEntry, caseNumber ?: string) : MatDialogRef < any > {
      const data: CaseSummaryEmailFaxDialogData = {
        entry,
        caseNumber
      };

      const attachmentId = entry?.emailId || entry?.faxId;

      const dialogConfig = {
        id: entry?.caseId && attachmentId ? `CaseSummaryEmailFax-${entry.caseId}-${attachmentId}` : undefined,
        width: '1500px',
        data
      };

      return this.dialogHelperUtilService.open(CaseSummaryEmailFaxDialogComponent, dialogConfig);
    }

    openCaseDocumentDialog(caseId: number, caseNumber: string, attachment: AttachmentDto, selectedRow: CaseDocumentTableRow, disabled ?: boolean) : MatDialogRef < any > {
      const data: CaseDocumentDialogData = {
        caseId,
        caseNumber,
        attachment,
        selectedRow,
        disabled
      };

      const dialogConfig = {
        id: caseId && attachment.documentId ? `CaseDocument-${caseId}-${attachment.documentId}` : undefined,
        width: '1150px',
        data
      };

      return this.dialogHelperUtilService.open(CaseDocumentDialogComponent, dialogConfig);
    }

    openCaseSummaryExternalDialog(entry: CaseHistoryCommEntry, channelType: string, channelTypeName: string, caseNumber ?: string) : MatDialogRef < any > {
      const data: CaseSummaryExternalDialogData = {
        entry,
        channelType,
        channelTypeName,
        caseNumber
      }

    const taskId = entry?.dataFieldChanges?.find(field => field.field === 'TaskId')?.newValue;

      const dialogConfig = {
        id: entry?.caseId && taskId ? `CaseSummaryExternal-${entry.caseId}-${taskId}` : undefined,
        width: '1500px',
        data
      };

      return this.dialogHelperUtilService.open(CaseSummaryExternalDialogComponent, dialogConfig);
    }
  openCaseSummaryDigitalCaseflowDialog(log: DigitalCaseflowLog, caseId: number) : MatDialogRef < any > {
    const data: CaseSummaryDigitalCaseflowDialogData = {
      log,
      caseId
    }
    const dialogConfig = {
      id: log.flowInitiationDate + '',
      width: '1500px',
      data
    };

    return this.dialogHelperUtilService.open(CaseSummaryDigitalCaseflowDialogComponent, dialogConfig);
  }
    openCaseSummarySmsDialog(entry: CaseHistoryCommEntry, stakeholder ?: CaseStakeholder, caseNumber ?: string) : MatDialogRef < any > {
      const data: CaseSummarySmsDialogData = {
        entry,
        stakeholder,
        caseNumber
      }

    const dialogConfig = {
        id: entry?.caseId && entry?.messageId ? `CaseSummarySms-${entry.caseId}-${entry.messageId}` : undefined,
        width: '1150px',
        data
      };

      return this.dialogHelperUtilService.open(CaseSummarySmsDialogComponent, dialogConfig);
    }

    openCaseSummaryNoteDialog(entry: CaseHistoryCommEntry, documents: CaseDocument[], caseNumber ?: string) : MatDialogRef < any > {
      const data: CaseSummaryNoteDialogData = {
        entry,
        documents,
        caseNumber
      };

      const documentId = documents?.length > 0 ? documents[0].documentId : null;

      const dialogConfig = {
        id: entry?.caseId && documentId ? `CaseSummaryNote-${entry.caseId}-${documentId}` : undefined,
        width: '1150px',
        data
      };

      return this.dialogHelperUtilService.open(CaseSummaryNoteDialogComponent, dialogConfig);
    }

    openCaseSummaryNoteLinkedCaseDialog(cId: string, noteElement: string): MatDialogRef < any > {
      const data: CaseSummaryNoteLinkedCaseDialogData = { isMinimizeIconHidden: true, caseId: cId, note: noteElement };
      const dialogConfig = {
        id: undefined,
        width: '600px',
        autoFocus: false,
        data
      };

      return this.dialogHelperUtilService.openModal(CaseSummaryNoteLinkedCaseDialogComponent, dialogConfig);
    }

    openTaskExternalDialog(taskViewModel: TaskViewModel, taskStatusDisabled ?: boolean, hideSearchCreateButton ?: boolean): MatDialogRef < any > {
      const data: TaskWebDialogData = {
        taskViewModel,
        taskStatusDisabled,
        hideSearchCreateButton,
      };

      const dialogConfig = {
        id: taskViewModel?.id ? `Task-${taskViewModel.id}` : undefined,
        width: '1558px',
        data,
      };

      return this.dialogHelperUtilService.open(TaskWebDialogComponent, dialogConfig);
    }

    openTaskConsentDialog(taskViewModel ?: TaskViewModel, invokedFromTheCase ?: boolean): MatDialogRef < any > {
      const data: TaskConsentDialogData = {
        taskViewModel,
        invokedFromTheCase,
      };

      const dialogConfig = {
        id: taskViewModel?.id ? `Task-${taskViewModel.id}` : undefined,
        width: '1558px',
        data,
      };

      return this.dialogHelperUtilService.open(TaskConsentDialogComponent, dialogConfig);
    }

    openTaskMessageDialog(taskViewModel: TaskViewModel, invokedFromTheCase ?: boolean, supplierInvoiceMoved ?: any): MatDialogRef < any > {
      const data: TaskMessageDialogData = {
        taskViewModel,
        invokedFromTheCase,
        supplierInvoiceMoved,
      };

      const dialogConfig = {
        id: taskViewModel && taskViewModel.id ? `Task-${taskViewModel.id}` : undefined,
        width: '1558px',
        data,
      };

      return this.dialogHelperUtilService.open(TaskMessageDialogComponent, dialogConfig);
    }

    openPolicyViewDialog(caseId: string, caseNumber ?: string): MatDialogRef < any > {
      const data: PolicyLookupDialogData = {
        caseId,
        caseNumber
      };

      const dialogConfig = {
        id: caseId ? `PolicyLookup-${caseId}` : undefined,
        width: '1000px',
        data
      };

      return this.dialogHelperUtilService.open(PolicyViewDialogComponent, dialogConfig);
    }

    openMedicalReportSupplierPortalDialog(reportSupplierPortal: MedicalReportSupplierPortalDto): MatDialogRef < any > {
      const data: MedicalReportSupplierDialogData = {
        taskId: reportSupplierPortal.taskId
      };
      const dialogConfig = {
        id: reportSupplierPortal && reportSupplierPortal.taskId ? `MedicalReportSupplierPortal-${reportSupplierPortal.taskId}` : undefined,
        width: '750px',
        data
      };
      return this.dialogHelperUtilService.open(MedicalReportSupplierDialogComponent, dialogConfig);
    }

  openSupplierSearchDialog(data: SupplierDialogData): MatDialogRef<any> {
    const dialogConfig = {
      id: data.caseId ? 'supplierSearch' + data.caseId : undefined,
      width: '1700px',
      data
    };
    return this.dialogHelperUtilService.open(SearchServiceProviderComponent, dialogConfig);
  }

  openSupplierSearchDialogFromStakeholder(data: SupplierSearchData): MatDialogRef<any> {
    const dialogConfig = {
      id: data.supplierId ? 'supplierSearchFromStakeholder' + data.supplierId : undefined,
      width: '1700px',
      data
    };
    return this.dialogHelperUtilService.open(StakeholderSupplierSearchDialogComponent, dialogConfig);
  }

  openSupplierSearchDialogFromPlansAddServices(data: SupplierSearchData): MatDialogRef<any> {
    const dialogConfig = {
      id: data.supplierId ? 'supplierSearchFromPlansAddServices' + data.supplierId : undefined,
      width: '1700px',
      data
    };
    return this.dialogHelperUtilService.open(StakeholderSupplierSearchDialogComponent, dialogConfig);
  }

  openRecoveryPayment(data: CaseEconomyRecoveryPaymentDialogData): MatDialogRef<any> {
    const dialogConfig = {
      id: data.payment.recoveryId ? 'recoveryPayment' + data.payment : undefined,
      width: '1700px',
      data
    };
    return this.dialogHelperUtilService.open(CaseEconomyRecoveryPaymentDialogComponent, dialogConfig);

  }

  openCountryInfo(countryBasics: CaseCountryInfoDialogInitData): MatDialogRef<any> {
    const dialogConfig = {
      id: `countryInfo-${countryBasics.caseId}`,
      width: '1470px',
      data: countryBasics
    };
    return this.dialogHelperUtilService.open(CaseCountryInfoDialogComponent, dialogConfig);
  }

}
