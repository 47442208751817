import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';


@Directive({
  selector: "[appDialogModal]",
})
export class DialogModalDirective implements OnInit {

  constructor(private elmRef: ElementRef, 
              private renderer: Renderer2) {        
  }

  ngOnInit() {
    this.sendToFont();
  }

  private sendToFont() {
    const overlayElement = this.findParentElementByClass(this.elmRef.nativeElement, 'cdk-global-overlay-wrapper');
    if ( overlayElement ) {
      this.renderer.addClass(overlayElement, 'dialog-to-front');
    }
  }

  private findParentElementByClass(element, componentClass) {
    for (let i = 0; i < 10; i++) {
        if ( !element ) {
          break;
        }

        if (element?.classList?.contains(componentClass)) {
            return element;
        } 

        element = element.parentNode;
    }

    return null;
  }
}
