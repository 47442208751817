import { AutoUnsubscribe } from 'src/app/shared/decorators/auto-unsubscribe';
import { SubStakeholder } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholder';
import { CaseStakeholder } from './../../../../../../../../shared/models/caseStakeholder';
import { SubRemarksFromSupplier } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subRemarksFromSupplier';
import { SubAirwayBill } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subAirwayBill';
import { SubReference } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subReference';
import { SubCoffinUrn } from './../../models/subCoffinUrn';
import { SubRemarksToSupplier } from './../../models/subRemarksToSupplier';
import { SubStakeholdersConfig } from './../../models/subStakeholdersConfig';
import { SubStakeholders } from './../../models/subStakeholders';
import { GroundTranportServiceOrderExtension } from './../../../../../../../../shared/models/service-order/ground-transport-service-order-extension';
import { TransportTypeEnum, StakeholderTypeEnum } from './../../../../../../../../shared/models/enums';
import { SubTransportType } from './../../models/subTransportType';
import { ServiceTypeBase } from './../../models/serviceTypeBase';
import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { ServiceTypeComponent } from './../../models/interfaces';
import { SubTravellers } from '../../models/subTravellers';

@AutoUnsubscribe
@Component({
  selector: 'app-ground-transport',
  templateUrl: './ground-transport.component.html',
  styles: [' .commitment-container { padding-left: 40px }']
})
export class GroundTransportComponent extends ServiceTypeBase implements OnInit, ServiceTypeComponent {
  @Input()
  public get stakeholders(): CaseStakeholder[] {
    return this._stakeholders;
  }
  public set stakeholders(stakeholders: CaseStakeholder[]) {
    this._stakeholders = stakeholders;
  }
  private _stakeholders: CaseStakeholder[];
  @Input() caseId: string;
  subStakeholdersConfig: SubStakeholdersConfig;
  _groundTransport: GroundTranportServiceOrderExtension;
  @Input()
  set groundTransport(groundTransport: GroundTranportServiceOrderExtension) {
    if (groundTransport != null) {
      this._groundTransport = groundTransport;
      this.initializeSettings();
    }
  }
  groundTransportWasInitialized: boolean;

  get groundTransport() {
    return this._groundTransport;
  }

  $typeChangedSubscr;

  constructor() {
    super();
    this.initializeData();
  }

  ngOnInit() {
    this.init();
  }

  init() {
    if (this.groundTransport?.transportType?.transportType === TransportTypeEnum.FUNERAL_CAR) {
      this.subStakeholdersConfig.mandatoryAndSetStakeholderType = StakeholderTypeEnum.endUser;
      this.subStakeholdersConfig.hideAddButton = true;
    }
  }
    
  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }

  tryToTransportTypeChanged() {
    if (this.groundTransport?.transportType != null && this.stakeholders != null && this.groundTransport?.transportType?.transportType === TransportTypeEnum.FUNERAL_CAR) {
      this.transportTypeChanged(this.groundTransport.transportType.transportType);
    }
  }

  checkIfThereIsOnlyEndUser(subStakeholders: SubStakeholder[]): boolean {
    let isThereEndUser: boolean = false;
    let isThereOtherUsers: boolean = false;
    if (this.stakeholders == null || subStakeholders == null) {
      return false;
    }
    subStakeholders.forEach(subStakeholder => {
      let foundStakeholder = this.stakeholders.find(a => a.id === subStakeholder.stakeholderId);
      if (foundStakeholder != null) {
        if (foundStakeholder.hasRole(StakeholderTypeEnum.endUser)) {
          isThereEndUser = true;
        } else {
          isThereOtherUsers = true;
        }
      }
    });
    return isThereEndUser && !isThereOtherUsers;
  }

  isValid(): boolean {
    let result: boolean;
    if (!this.serviceOrderTypeState.showConfirmationTab) {
      result = this.groundTransport.isValidExpectation();
    }
    if (this.serviceOrderTypeState.showConfirmationTab) {
      result = this.groundTransport.isValid();
    }
    return result;
  }

  getModel() {
    return this.groundTransport;
  }

  get TransportTypeEnum() {
    return TransportTypeEnum;
  }

  initializeData() {
    this.groundTransport = new GroundTranportServiceOrderExtension();
    this.groundTransport.transportType = new SubTransportType(TransportTypeEnum.TAXI);
    this.groundTransport.reference = new SubReference();
    // TODO this.groundTransport.commitmentType = new SubCommitmentType(CommitmentTypes.BOOKING);
    this.groundTransport.airwayBill = new SubAirwayBill();
    this.groundTransport.coffinUrn = new SubCoffinUrn();
    this.groundTransport.remarksFromSupplier = new SubRemarksFromSupplier();
    this.groundTransport.remarksToSupplier = new SubRemarksToSupplier();
    this.groundTransport.travellers = new SubTravellers();
    this.groundTransport.travelInfo = this.groundTransport.travellers.flightLegs[0];
    this.groundTransport.subStakeholders = new SubStakeholders();
    this.groundTransport.stakeholders = this.groundTransport.subStakeholders.stakeholders;
  }

  initializeSettings() {
    this.subStakeholdersConfig = new SubStakeholdersConfig();
    this.subStakeholdersConfig.requireAtLeastOneStakeholder = true;
    this.subStakeholdersConfig.hideClass = true;
    this.subStakeholdersConfig.hideInclDateOfBirth = true;
    this.subStakeholdersConfig.hideInclPassportDetails = true;
    this.subStakeholdersConfig.onlyPersonTypeStakeholders = false;
    this.subStakeholdersConfig.availableStakeholderTypes = [
      StakeholderTypeEnum.person,
      StakeholderTypeEnum.endUser,
      StakeholderTypeEnum.policyHolder,
      StakeholderTypeEnum.reporter,
      StakeholderTypeEnum.coTraveller,
      StakeholderTypeEnum.escortDoctor,
      StakeholderTypeEnum.escortNurse
    ];
    this.subStakeholdersConfig.showNoNipAlways = true;
    this.isSupplierRequiredOnConfirmationTab = false;
    if (this.$typeChangedSubscr != null) {
      this.$typeChangedSubscr.unsubscribe();
      this.$typeChangedSubscr = null;
    }
  }

  get StakeholderTypeEnum() {
    return StakeholderTypeEnum;
  }

  addEndUserAsDefaultStakeholder() {
    if (
      this.groundTransport.subStakeholders.stakeholders.length === 0 ||
      (this.groundTransport.subStakeholders.stakeholders.length > 0 &&
        this.groundTransport.subStakeholders.stakeholders[0].stakeholderType !== StakeholderTypeEnum.endUser)
    ) {
      this.subStakeholdersConfig.requireSingleStakeholder = true;
      this.groundTransport.subStakeholders.stakeholders.unshift(
        new SubStakeholder({
          stakeholderId: this.stakeholders.find(a => a.hasRole(StakeholderTypeEnum.endUser)).id,
          stakeholderType: StakeholderTypeEnum.endUser,
        })
      );
    }
  }

  removeEndUserAsDefaultStakeholder() {
    if (
      this.groundTransport.stakeholders.length > 0 &&
      this.groundTransport.stakeholders[0].stakeholderType === StakeholderTypeEnum.endUser
    ) {
      this.groundTransport.stakeholders.shift();
    }
  }

  transportTypeChanged(transportTypeEnum: any) {
    if (transportTypeEnum === TransportTypeEnum.FUNERAL_CAR) {
      this.subStakeholdersConfig.mandatoryAndSetStakeholderType = StakeholderTypeEnum.endUser;
      this.subStakeholdersConfig.hideAddButton = true;
      this.groundTransport.subStakeholders.stakeholders = [];
      this.groundTransport.stakeholders = this.groundTransport.subStakeholders.stakeholders;
      this.addEndUserAsDefaultStakeholder();
    } else {
      this.subStakeholdersConfig.hideAddButton = false;
      this.subStakeholdersConfig.mandatoryAndSetStakeholderType = null;
      this.groundTransport.subStakeholders = new SubStakeholders();
      this.groundTransport.stakeholders = this.groundTransport.subStakeholders.stakeholders;
      this.removeEndUserAsDefaultStakeholder();
    }
  }
}
