<span (click)="hover=true && asOverlay" (mouseout)="hover=false">
  <div *ngIf="(isLockTaken || caseClosed || financialStatusClosedNoInvoice || permissionService.isReadOnly) && !hover" class="case-lock-overlay" [ngClass]="{'case-lock-box': !asOverlay}">
    <div class="overlay-background"></div>
    <div class="overlay-text">
      <div *ngIf="caseClosed && financialStatusClosedNoInvoice">{{"case-closed-case-closed-with-no-invoice" | translate}}</div>
      <div *ngIf="caseClosed && !financialStatusClosedNoInvoice">{{"case-closed" | translate}}</div>
      <div *ngIf="financialStatusClosedNoInvoice && !caseClosed">{{"case-closed-with-no-invoice" | translate}}</div>
      <div *ngIf="isLockTaken">
        <div *ngIf="lockContext === LockContextEnum.CASE_BASIC">{{"case-locked" | translate}} {{lockedBy}}</div>
        <div *ngIf="lockContext === LockContextEnum.SUPPLIER_INVOICE">{{"supplier-invoice-locked" | translate}} {{lockedBy}}</div>
      </div>
      <div *ngIf="permissionService.isReadOnly">
        <div>{{"case-readonly" | translate}}</div>
      </div>
    </div>
    <div class="overlay-actions" *ngIf="isLockTaken && !permissionService.isReadOnly">
      <button *ngIf="lockContext === LockContextEnum.CASE_BASIC" class="unlockcase-button" (click)="unlockCaseButtonClick();$event.stopPropagation();$event.preventDefault()">{{"case-unlock" | translate}}</button>
      <button *ngIf="lockContext === LockContextEnum.SUPPLIER_INVOICE" class="unlockcase-button" (click)="unlockCaseButtonClick();$event.stopPropagation();$event.preventDefault()">{{"supplier-invoice-unlock" | translate}}</button>
    </div>
  </div>
  <div *ngIf="hover" class="case-lock-overlay-hover"></div>
</span>
