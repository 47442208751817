import {Injectable} from '@angular/core';
import {BaseService} from '@secca/core/services/base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import {SettingsService} from '@secca/core/services/settings.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {PhoneCall, PhoneCallAdapter} from '@secca/shared/models/phoneCall';
import {CountryCode} from '@secca/shared/enums/countryCode';
import {PhoneDto} from '@secca/shared/models/phone-dto';

@Injectable({
  providedIn: 'root'
})
export class PhoneService extends BaseService {

  constructor(
    private settingsService: SettingsService,
    private http: HttpClient,
    private phoneCallAdaptor: PhoneCallAdapter
  ) {
    super(settingsService);
  }

  getLatestPhoneCall(activeOnly: boolean = false): Observable<PhoneCall> {
    let httpParams = new HttpParams();
    if (activeOnly) {
      httpParams = httpParams.set('active', 'true');
    }
    return this.http.get<PhoneCall>(this.baseURL + 'phone/last-call', {headers: this.jsonHeaders, params: httpParams})
      .pipe(map(item => this.phoneCallAdaptor.adapt(item)));
  }

  performCall(prefix, number): Observable<any> {
    return this.http.post(this.baseURL + 'phone/call/' + prefix + number, {headers: this.jsonHeaders});
  }

  extractPhoneDto(fullNumber: string): PhoneDto {
    if (!fullNumber) {
      return new PhoneDto();
    }

    if (fullNumber.startsWith('+45') || fullNumber.startsWith('0045')) {
      return new PhoneDto({countryCode: CountryCode.DK.toUpperCase(), phonePrefix: '+45', phoneNumber: fullNumber.replace('+45', '')});
    } else if (fullNumber.startsWith('+47')) {
      return new PhoneDto({countryCode: CountryCode.NO.toUpperCase(), phonePrefix: '+47', phoneNumber: fullNumber.replace('+47', '')});
    } else if (fullNumber.startsWith('+46')) {
      return new PhoneDto({countryCode: CountryCode.SE.toUpperCase(), phonePrefix: '+46', phoneNumber: fullNumber.replace('+46', '')});
    } else if (fullNumber.startsWith('+358')) {
      return new PhoneDto({countryCode: CountryCode.FI.toUpperCase(), phonePrefix: '+358', phoneNumber: fullNumber.replace('+358', '')});
    } else if (fullNumber.startsWith('+49')) {
      return new PhoneDto({countryCode: CountryCode.DE.toUpperCase(), phonePrefix: '+49', phoneNumber: fullNumber.replace('+49', '')});
    } else if (fullNumber.startsWith('+44')) {
      return new PhoneDto({countryCode: CountryCode.EN.toUpperCase(), phonePrefix: '+44', phoneNumber: fullNumber.replace('+44', '')});
    } else {
      return new PhoneDto({phoneNumber: fullNumber.replace('+', '')});
    }
  }
}
