import { Component, Inject, ViewChild } from '@angular/core';
import { CaseEconomyRecoveryPaymentAdditionalInfo, IAdditionalInfo } from '@secca/shared/interfaces/additional-info';
import { IMinimizable } from '@secca/shared/interfaces/minimizable';
import { IKeyboardEnabled } from '@secca/shared/interfaces/keyboard-enabled';
import { IFocusEnabled } from '@secca/shared/interfaces/focus-enabled';
import { DialogMinimizableDirective } from '@secca/shared/directives/dialog-minimizable.directive';
import { MinimizableDialogType } from '@secca/shared/enums/minimizable-dialog-type-enum';
import { DialogSelectableDirective } from '@secca/shared/directives/dialog-selectable.directive';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DialogBoundryService } from '@secca/core/services/dialog-boundry.service';
import { DialogHelperUtilService } from '@secca/core/services/dialog-helper-util.service';
import { RecoveryPayment } from '@secca/shared/models/recoveryPayment';
import {
  RecoveryPaymentComponent
} from '@secca/case/components/case-economy/case-economy-recovery-payment-dialog/recovery-payment/recovery-payment.component';
import {ServiceItemType} from "@secca/shared/models/service-item-type";
import {
  CaseEconomyRecoveryPaymentDialogData
} from "@secca/case/components/case-economy/case-economy-recovery-payment-dialog/case-economy-recovery-payment-dialog-data.component";
import {Recovery} from "@secca/shared/models/recovery";
import {DropdownDictionary} from "@secca/shared/models/dropdownDictionary";

@Component({
  selector: 'app-case-economy-recovery-payment-dialog',
  templateUrl: './case-economy-recovery-payment-dialog.component.html',
  styleUrls: ['./case-economy-recovery-payment-dialog.component.scss']
})
export class CaseEconomyRecoveryPaymentDialogComponent implements IAdditionalInfo, IMinimizable, IKeyboardEnabled, IFocusEnabled {
  @ViewChild(DialogMinimizableDirective) minimizedDirective: DialogMinimizableDirective;
  @ViewChild(RecoveryPaymentComponent) wrappedInstance: RecoveryPaymentComponent;
  @ViewChild(DialogSelectableDirective) selectableDirective: DialogSelectableDirective;

  constructor(private dialogRef: MatDialogRef<CaseEconomyRecoveryPaymentDialogComponent>,
              public dialogBoundryService: DialogBoundryService,
              private dialogHelperUtilService: DialogHelperUtilService,
              @Inject(MAT_DIALOG_DATA) data: CaseEconomyRecoveryPaymentDialogData,
              ) {
    this.data = data;
  }

  data: CaseEconomyRecoveryPaymentDialogData;

  getFocusElement() {
    return this.selectableDirective?.focusElement;
  }

  keyboardClose(): void {
    this.wrappedInstance.close();
  }

  minimize(): void {
    this.minimizedDirective.minimize();
  }

  getMinimizeType(): MinimizableDialogType {
    return this.minimizedDirective.appDialogMinimizable;
  }

  close() {
    this.data.paymentUpdated();
    this.dialogHelperUtilService.close(this.dialogRef, this.getProperties().caseId, true);
  }

  getProperties(): CaseEconomyRecoveryPaymentAdditionalInfo {
    return this.getAdditionalInfo();
  }

  get MinimizableDialogType() {
    return MinimizableDialogType;
  }

  getAdditionalInfo(): CaseEconomyRecoveryPaymentAdditionalInfo {
    const caseId = +this.wrappedInstance?.recoveryPayment.caseId;
    const recoveryId = +this.wrappedInstance?.recoveryPayment.recoveryId;
    return {
      caseId,
      recoveryId
    };
  }
}

