import { Injectable } from '@angular/core';
import { BoardSelectedMenuEnum } from '@secca/shared/enums/board-selected-menu-enum';
import { CaseSelectedMenuEnum } from '@secca/shared/enums/case-selected-menu-enum.component';

@Injectable()
export class MenuService {
  private casesMenu = new Map<number, number>();
  private boardMenu: BoardSelectedMenuEnum;

  send(caseId: number, menuId: number) {
    this.casesMenu.set(caseId, menuId);
  }

  getSelectedMenuId(caseId: number) {
    return this.casesMenu.get(caseId) ? this.casesMenu.get(caseId) : CaseSelectedMenuEnum.CaseBasics;
  }

  sendBoardMenuItem(menuId: BoardSelectedMenuEnum) {
    this.boardMenu = menuId;
  }

  getSelectedBoardMenuId(): BoardSelectedMenuEnum {
    return this.boardMenu;
  }
}
