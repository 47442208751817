import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LockContextEnum } from '@secca/shared/enums/lock-context.enum';
import { CaseLock } from '@secca/shared/models/case-lock.model';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { SettingsService } from './settings.service';

@Injectable({
    providedIn: 'root'
})
export class CaseLockService extends BaseService {

    constructor(private http: HttpClient,
                private settingsService: SettingsService) {
        super(settingsService);
    }

    public lockCase(caseId: string, lockContext: LockContextEnum, contextId?: number): Observable<CaseLock> {
      if ( contextId ) {
        return this.http.get<CaseLock>(`${this.baseURL}cases/${caseId}/lock/${lockContext}/${contextId}/take`);
      }
      else {
        return this.http.get<CaseLock>(`${this.baseURL}cases/${caseId}/lock/${lockContext}/take`);
      }
    }

    public releaseCaseLock(caseId: string, lockContext: LockContextEnum, contextId?: number): Observable<CaseLock> {
      if ( contextId ) {
        return this.http.get<CaseLock>(`${this.baseURL}cases/${caseId}/lock/${lockContext}/${contextId}/release`);
      }
      else {
        return this.http.get<CaseLock>(`${this.baseURL}cases/${caseId}/lock/${lockContext}/release`);
      }
    }

    public tryLockCase(caseId: string, lockContext: LockContextEnum, contextId?: number): Observable<CaseLock> {
      if ( contextId ) {
        return this.http.get<CaseLock>(`${this.baseURL}cases/${caseId}/lock/${lockContext}/${contextId}/try-take`);
      }
      else {
        return this.http.get<CaseLock>(`${this.baseURL}cases/${caseId}/lock/${lockContext}/try-take`);
      }
    }

    public tryReleaseCaseLock(caseId: string, lockContext: LockContextEnum, contextId?: number): Observable<CaseLock> {
      if ( contextId ) {
        return this.http.get<CaseLock>(`${this.baseURL}cases/${caseId}/lock/${lockContext}/${contextId}/try-release`);
      }
      else {
        return this.http.get<CaseLock>(`${this.baseURL}cases/${caseId}/lock/${lockContext}/try-release`);
      }
    }

    public tryReleaseCaseLockByName(caseId: string, lockContextName: string): Observable<CaseLock> {
      return this.http.get<CaseLock>(`${this.baseURL}cases/${caseId}/lock/${lockContextName}/try-release-byname`);
    }


    public forceReleaseCaseLock(caseId: string, lockContext: LockContextEnum, contextId?: number): Observable<CaseLock> {
      if ( contextId ) {
        return this.http.get<CaseLock>(`${this.baseURL}cases/${caseId}/lock/${lockContext}/${contextId}/force-release`);
      }
      else {
        return this.http.get<CaseLock>(`${this.baseURL}cases/${caseId}/lock/${lockContext}/force-release`);
      }        
    }

    public forceReleaseCaseLockByName(caseId: string, lockContextName: string): Observable<CaseLock> {
      return this.http.get<CaseLock>(`${this.baseURL}cases/${caseId}/lock/${lockContextName}/force-release-byname`);
    }

    public hasCaseLock(caseId: string, lockContext: LockContextEnum, contextId?: number): Observable<boolean> {
      if ( contextId ) {
        return this.http.get<boolean>(`${this.baseURL}cases/${caseId}/lock/${lockContext}/${contextId}/has-lock`);
      }
      else {
        return this.http.get<boolean>(`${this.baseURL}cases/${caseId}/lock/${lockContext}/has-lock`);
      }
    }

    public isCaseLocked(caseId: string, lockContext: LockContextEnum, contextId?: number): Observable<boolean> {
      if ( contextId ) {
        return this.http.get<boolean>(`${this.baseURL}cases/${caseId}/lock/${lockContext}/${contextId}/is-locked`);
      }
      else {
        return this.http.get<boolean>(`${this.baseURL}cases/${caseId}/lock/${lockContext}/is-locked`);
      }
    }
}
