import { Adapter } from '../interfaces/adapter';
import { SalesOrderItem, SalesOrderItemAdapter } from './salesOrderItem';
import { DineroObject } from 'dinero.js';
import { SalesOrderStatus } from '../enums/sales-order-status';
import { Injectable } from '@angular/core';
import { EconomyHistory } from './refund-history';
import moment from 'moment-timezone';
import { DateSerializer } from './date-serializer';

export class SalesOrder implements EconomyHistory{
  id: number;
  seccaCaseId: string;
  debtor: string;
  salesOrderNumber: string;
  erpClient: any;
  credited: boolean;
  creditNote: boolean;
  status: SalesOrderStatus;
  invoicePrice: DineroObject;
  customerInvoiceExclVat: DineroObject;
  customerInvoiceInclVat: DineroObject;
  erpSentDate: Date;
  invoiceNo: string;
  invoiceDate: Date;
  invoiceDocumentId: string;
  invoiceStatus: string;
  invoiceExecutionDate: moment.Moment;
  invoiceHandlingNote: string;
  customerProfileId: number;
  customerInvoiceNote: string;
  modifiedBy: string;
  modifiedOn: moment.Moment;
  deletedBy: string;
  deletedOn: moment.Moment;
  salesOrderItems: SalesOrderItem[];
  acknowledgeMessage: string;
  retryCount: number;
  quantity: number;

  identicalHistory(salesOrder: SalesOrder): boolean {
    return this.debtor == salesOrder.debtor &&
    this.salesOrderNumber == salesOrder.salesOrderNumber &&
    this.erpClient === salesOrder.erpClient &&
    this.status === salesOrder.status &&
    this.invoiceDate === salesOrder.invoiceDate &&
    this.erpSentDate === salesOrder.erpSentDate &&
    this.invoiceDate === salesOrder.invoiceDate &&
    this.invoiceHandlingNote === salesOrder.invoiceHandlingNote &&
    this.invoiceDocumentId === salesOrder.invoiceDocumentId &&
    this.customerInvoiceNote === salesOrder.customerInvoiceNote &&
    this.customerInvoiceExclVat === salesOrder.customerInvoiceExclVat

  }

  public constructor(init?: Partial<SalesOrder>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
export class SalesOrderAdapter implements Adapter<SalesOrder> {
  private salesOrderItemAdapter = new SalesOrderItemAdapter();
  adapt(item: any): SalesOrder {
    let invoicePrice = item.invoicePrice;
    if (item.invoicePrice == null) {
      invoicePrice = { amount: 0, currency: 'DKK' };
    }
    const statusString: string = item.status;
    const status: SalesOrderStatus = SalesOrderStatus[statusString];
    const salesOrder = new SalesOrder({
      id: item.id,
      seccaCaseId: item.seccaCaseId,
      debtor: item.debtor,
      salesOrderNumber: item.salesOrderNumber,
      erpClient: item.erpClient,
      credited: item.credited,
      creditNote: item.creditNote,
      status,
      invoicePrice,
      customerInvoiceExclVat: item.customerInvoiceExclVat,
      customerInvoiceInclVat: item.customerInvoiceInclVat,
      erpSentDate: item.erpSentDate,
      invoiceNo: item.invoiceNo,
      invoiceDate: item.invoiceDate,
      invoiceHandlingNote: item.invoiceHandlingNote,
      invoiceDocumentId: item.invoiceDocumentId,
      customerProfileId: item.customerProfileId,
      customerInvoiceNote: item.customerInvoiceNote,
      acknowledgeMessage: item.acknowledgeMessage,
      retryCount: item.retryCount,
      modifiedOn: item.modifiedOn ? DateSerializer.deserialize(item.modifiedOn) : null,
      deletedOn: item.deletedOn ? DateSerializer.deserialize(item.deletedOn) : null,
      deletedBy: item.deletedBy,
      salesOrderItems: [],
      invoiceExecutionDate: item.invoiceExecutionDate ? DateSerializer.deserialize(item.invoiceExecutionDate) : null,
      invoiceStatus: item.invoiceStatus
    });
    for (const si of item.salesOrderItems) {
      const salesOrderItem = this.salesOrderItemAdapter.adapt(si);
      if (!!si.sosServiceName) {
        salesOrderItem.sosServiceName = si.sosServiceName;
      }
      salesOrderItem.invoiced = status !== SalesOrderStatus.NOT_INVOICED;
      salesOrderItem.status = status;
      salesOrder.salesOrderItems.push(salesOrderItem);
    }
    return salesOrder;
  }
}
