import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserTeamDto } from '@secca/shared/models/user-team-dto';

@Injectable({
  providedIn: 'root'
})
export class BoardTeamCaringService {
  private changeShowBoardTeamCaring = new BehaviorSubject<boolean>(true);
  allSelected: boolean = false;
  teamSelected: boolean = false;
  userSelected: boolean = false;

  constructor() {}


  public changedShowBoardTeamCaring(show: boolean) {
    this.changeShowBoardTeamCaring.next(show);
  }
  public getShowBoardTeamCaring(): Observable<boolean> {
    return this.changeShowBoardTeamCaring.asObservable();
  }
}