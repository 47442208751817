<div class="consent-info">
  <div class="consent-main">
    <div *ngIf="selectedCaseConsentData?.includeConsent">
      <div [appDisableInput]="caseStateService.isCaseDisabled()">
        <app-drop-down-consent [loading]="consentRequestLoading" [items]="consentData" [(selectedItemId)]="selectedConsent"
        (selectedItemIdChange)="onSelectItem($event)" (open)="onOpen()" #dropDownConsentInstance>
        </app-drop-down-consent>
      </div>
    </div>
  </div>
</div>
