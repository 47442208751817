import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogHelperUtilService } from '@secca/core/services/dialog-helper-util.service';

@Component({
  selector: 'app-keyboard-shortcuts-modal',
  templateUrl: './keyboard-shortcuts-modal.component.html',
  styleUrls: ['./keyboard-shortcuts-modal.component.scss']
})
export class KeyboardShortcutsModalComponent implements OnInit {

  @Output() closed = new EventEmitter<number>();

  constructor(private modalService: NgbModal, private dialogHelperUtilService: DialogHelperUtilService) { }

  ngOnInit(): void {
  }

  close() {
    if (this.closed.observers) {
      this.closed.emit();
    }
    if (this.modalService.hasOpenModals()) {
      this.modalService.dismissAll();
    }
    this.dialogHelperUtilService.closeModal();
  }

}
