import { MedicationItemEnum } from './../enums';

import { Injectable } from '@angular/core';
import { Adapter } from '../../interfaces/adapter';

export class Medication {
    otherItemDescription: string;
    item: MedicationItemEnum;
    public constructor(init?: Partial<Medication>) {
        Object.assign(this, init);
    }

  setOtherItemDescription(value: string) {
    this.otherItemDescription = value;
  }
}

@Injectable({
    providedIn: 'root'
})
export class MedicationAdapter implements Adapter<Medication> {
    adapt(item: any): Medication {
        return new Medication({
            otherItemDescription: item.otherItemDescription,
            item: item.item
        });
    }
}
