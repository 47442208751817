import { StakeholderTypeEnum } from '@secca/shared/models/enums';
import { StakeholderClassTypeEnum } from '@secca/case/components/case-plans/case-plans/add-service-order/models/enums';

export class SubStakeholdersConfig {
  classLabelsVisible: Map<string, boolean> = new Map<string, boolean>([
    [StakeholderClassTypeEnum.ECONOMY, true],
    [StakeholderClassTypeEnum.ECONOMY_FLEX_PREMIUM_ECONOMY, true],
    [StakeholderClassTypeEnum.BUSINESS_CLASS, true],
    [StakeholderClassTypeEnum.FIRST_CLASS, true],
    [StakeholderClassTypeEnum.SAME_AS_END_USER, true],
  ]);
  hideClass = false;
  hideInclDateOfBirth = false;
  hideInclPassportDetails = false;
  requireSingleStakeholder = false;
  requireAtLeastOneStakeholder = false;
  requireStakeholderType = null;
  onlyPersonTypeStakeholders = false;
  availableStakeholderTypes: StakeholderTypeEnum[];
  mandatoryAndSetStakeholderType: StakeholderTypeEnum;
  hideAddButton = false;
  hideTrashIcon = false;
  readOnly = false;
  reporterAndPolicyHolderMustBePersons = false;
  endUserAlwaysSeeMedifClass = false;
  showNoNipAlways = false;
  lockFirstInclDateOfBirth = false;

  public constructor(init?: Partial<SubStakeholdersConfig>) {
    Object.assign(this, init);
  }
}
