<div class="card activity-card note">
  <div class="card-header activity-card-header">
    <table class="note-log-table">
      <thead></thead>
      <tbody class="table-body">
        <tr class="table-row">
          <td class="col">
            <div class="float-start text stakeholder">
              <div *ngIf="entry.caseObjectType === CaseObjectType.note">
                <span *ngIf="getNoteType(entry.entityId) === NoteTypeEnum.phone">
                  <img class="icon-phone" src="/assets/icons/Phone%20note%20focused.svg" alt="Phone Image" />
                  <img
                    *ngIf="getNoteCalltype(entry.entityId) === CallType.ingoing; else outgoing"
                    alt="Incoming Phone Icon"
                    class="icon-phone"
                    src="/assets/icons/Ingoing.svg"
                  />
                  <ng-template #outgoing>
                    <img class="icon-phone" src="/assets/icons/Outgoing.svg" alt="Outgoing Phone Image" />
                  </ng-template>
                  <img class="stakeholder-icon" [src]="getStakeholderIcon(entry.entityId)" />
                  <div *ngIf="getNoteStakeholderName(entry.entityId) !== '' && !longStakeholderName" (mouseenter)="showLongStakeholderName()"  class="stakeholder-name">{{ getNoteStakeholderName(entry.entityId) }} </div>
                  <div *ngIf="getNoteStakeholderName(entry.entityId) !== '' && longStakeholderName" (mouseleave)="showLongStakeholderName()" class="stakeholder-name-long">{{ getNoteStakeholderName(entry.entityId) }} </div>
                </span>
                <span *ngIf="getNoteType(entry.entityId) === NoteTypeEnum.case">
                  <img src="/assets/icons/Case note.svg" alt="Case note image" />
                </span>
                <span *ngIf="getNoteType(entry.entityId) === NoteTypeEnum.customer">
                  <img src="/assets/icons/Customer%20Note%20Medium%20Blue.svg" alt="Customer Note icon" />
                </span>
                <span *ngIf="getNoteType(entry.entityId) === NoteTypeEnum.supplier">
                  <img class="icon-phone" src="/assets/icons/Customer%20Note%20Medium%20Blue.svg" alt="Supplier Note icon" />
                  <img class="stakeholder-icon" [src]="getStakeholderIcon(entry.entityId)" />
                  <div *ngIf="getNoteStakeholderName(entry.entityId) !== '' && !longStakeholderName" (mouseenter)="showLongStakeholderName()" class="stakeholder-name">{{ getNoteStakeholderName(entry.entityId) }}</div>
                  <div *ngIf="getNoteStakeholderName(entry.entityId) !== '' && longStakeholderName" (mouseleave)="showLongStakeholderName()" class="stakeholder-name-long"> HELLO{{ getNoteStakeholderName(entry.entityId) }}</div>
                </span>

                <span *ngIf="getNoteType(entry.entityId) === NoteTypeEnum.customer && isNoteDeleted(entry.entityId)" class="ps-2">
                  Customer note
                </span>
                <span *ngIf="getNoteType(entry.entityId) === NoteTypeEnum.supplier && isNoteDeleted(entry.entityId)" class="ps-2">
                  Supplier note
                </span>
              </div>
            </div>
          </td>
          <td *ngIf="getNoteType(entry.entityId) === NoteTypeEnum.phone" class="col phone-middle-col">
              <span *ngIf="getPhoneStartTime(entry.entityId)"
              >{{ 'case-summary-list-note-phone-starttime' | translate }}:
                {{ getPhoneStartTime(entry.entityId) | momentFormat: 'HH:mm':'local' }}</span
              >
          </td>
          <td class="col last-column">
            <div
              *ngIf="
                !isNoteDeleted(entry.entityId) &&
                getNoteType(entry.entityId) !== NoteTypeEnum.customer &&
                getNoteType(entry.entityId) !== NoteTypeEnum.supplier
              "
              class="float-end"
            >
              <div [appDisableInput]="caseStateService.isCaseDisabled()">
                <div class="dropdown">
                  <span class="dot"></span>
                  <span class="dot"></span>
                  <span class="dot"></span>
                  <div class="dropdown-content">
                    <p (click)="edititem(entry.entityId)">Edit</p>
                    <div *ngIf="showCopyLink">
                      <p (click)="openCopyNoteDialog(entry.entityId)">Copy to linked case</p>
                    </div>
                    <p (click)="registerDeleteItem(entry.entityId)" style="color: red">Delete</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              *ngIf="
                !isNoteDeleted(entry.entityId) &&
                (getNoteType(entry.entityId) === NoteTypeEnum.customer ||
                getNoteType(entry.entityId) === NoteTypeEnum.supplier)
              "
              class="float-end"
            >

              <div [appDisableInput]="caseLockHelperService.isCaseBasicLockTaken || permissionService.isReadOnly" *permission='PermissionEnum.COMMUNICATION_CREATE; hideType:PermissionHideTypeEnum.REMOVE'>
                <div class="dropdown">
                  <span class="dot"></span>
                  <span class="dot"></span>
                  <span class="dot"></span>
                  <div class="dropdown-content">
                    <p (click)="openModal()"  style="color: red" *permission='PermissionEnum.COMMUNICATION_CREATE; hideType:PermissionHideTypeEnum.REMOVE'>Delete: Not GDPR compliant</p>
                    <p (click)="viewNoteDocuments(entry.entityId)" *ngIf="getNoteType(entry.entityId) === NoteTypeEnum.supplier && getNoteDocuments(entry.entityId).length > 0">View documents</p>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="!isNoteDeleted(entry.entityId)" class="float-end">
              <div *ngIf="(isEdited(entry) || wasNoteEdited) && !isCopied(entry.entityId)">Edited&nbsp;</div>
            </div>
            <div *ngIf="!isNoteDeleted(entry.entityId)" class="float-end">
              <div *ngIf="isCopied(entry.entityId) && !(isEdited(entry) || wasNoteEdited)">Copied&nbsp;</div>
            </div>
            <div *ngIf="!isNoteDeleted(entry.entityId)" class="float-end">
              <div *ngIf="(isEdited(entry) || wasNoteEdited) && isCopied(entry.entityId)">Edited/Copied&nbsp;</div>
            </div>
            <div *ngIf="isNoteDeleted(entry.entityId)" class="float-end">
              <span class="redText">Deleted &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
            </div>
          </td>
        </tr>
        <tr class="table-row phone-second-row" *ngIf="getNoteType(entry.entityId) === NoteTypeEnum.phone">
          <td class="col phone-number">
            <div class="float-start text">
              <span *ngIf="getPhoneNumber(entry.entityId)" class="phone-link me-2"
                ><a (click)="performCall(entry.entityId)">{{ getPhoneNumber(entry.entityId) }}</a></span
              >
            </div>
          </td>
          <td class="col phone-middle-col">
            <span *ngIf="getPhoneCallDuration(entry, entry.entityId)"
              >{{ 'case-summary-list-note-phone-duration' | translate }}: {{ getPhoneCallDuration(entry, entry.entityId) }}</span
            >
          </td>
          <td class="col last-column float-end"></td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="!isNoteDeleted(entry.entityId)" class="card-body activity-card-body borderBottomShadow">
    <div valign="top">
      <span *ngIf="getNoteType(entry.entityId) === NoteTypeEnum.phone">
        <span *ngFor="let descr of getNoteDescriptionSplited(entry.entityId)">
          <div>{{ descr }}</div>
        </span>
      </span>
      <span *ngIf="getNoteType(entry.entityId) === NoteTypeEnum.case">
        <div>{{ getNoteCalltype(entry.entityId) }}</div>
        <span *ngFor="let descr of getNoteDescriptionSplited(entry.entityId)">
          <div>{{ descr }}</div>
        </span>
      </span>
      <span *ngIf="getNoteType(entry.entityId) === NoteTypeEnum.customer || getNoteType(entry.entityId) === NoteTypeEnum.supplier">
        <div>{{ getNoteCalltype(entry.entityId) }}</div>
        <span *ngFor="let descr of getNoteDescriptionSplited(entry.entityId)">
          <div>{{ descr }}</div>
        </span>
      </span>
    </div>
  </div>
</div>
