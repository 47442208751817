import { Component, Inject, ViewChild } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { DialogBoundryService } from "@secca/core/services/dialog-boundry.service";
import { DialogHelperUtilService } from "@secca/core/services/dialog-helper-util.service";
import { DialogMinimizableDirective } from "@secca/shared/directives/dialog-minimizable.directive";
import { MinimizableDialogType } from "@secca/shared/enums/minimizable-dialog-type-enum";
import { CaseDocumentAdditionalInfo, IAdditionalInfo } from "@secca/shared/interfaces/additional-info";
import { IKeyboardEnabled } from "@secca/shared/interfaces/keyboard-enabled";
import { IMinimizable } from "@secca/shared/interfaces/minimizable";
import { CaseDocumentDialogData } from "./case-document-dialog-data.component";

@Component({
    selector: 'app-case-document-dialog',
    templateUrl: './case-document-dialog.component.html',
    styleUrls: ['./case-document-dialog.component.scss'],
  })
  export class CaseDocumentDialogComponent implements IAdditionalInfo, IMinimizable, IKeyboardEnabled {
    @ViewChild(DialogMinimizableDirective) minimizedDirective: DialogMinimizableDirective;

    data: CaseDocumentDialogData;

    constructor(private dialogRef: MatDialogRef<CaseDocumentDialogComponent>,
                @Inject(MAT_DIALOG_DATA) data: CaseDocumentDialogData,
                private dialogHelperUtilService: DialogHelperUtilService,
                public dialogBoundryService: DialogBoundryService) {

      this.data = data;
    }

    close() {
      this.dialogHelperUtilService.close(this.dialogRef, this.getAdditionalInfo()?.caseId);
    }

    keyboardClose(): void {
      this.close();
    }

    minimize() {
      this.minimizedDirective.minimize();
    }

    getMinimizeType(): MinimizableDialogType {
      return this.minimizedDirective.appDialogMinimizable;
    }

    getAdditionalInfo(): CaseDocumentAdditionalInfo {

      return {
        caseId: +this.data.caseId,
        hoverTitle: this.data.caseNumber,
        hoverText: this.data?.attachment?.documentName ? this.data.attachment?.documentName : '',
        isOutgoing: this.data?.selectedRow?.isOutgoing
      };
    }

    get MinimizableDialogType() {
      return MinimizableDialogType;
    }
}
