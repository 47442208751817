<div class="modal-content">
  <div class="modal-header">
    <div class="modal-header-title">
      {{ 'policy-lookup-lookup-title' | translate }}
    </div>
    <div class="modal-buttons me-3">
      <div (click)="close()">
        <img src="/assets/icons/Close White.svg"/>
      </div>
    </div>
  </div>

  <div class="policy-view-modal-body">
    <div class="policy-holder">
      <img class="clearfix" src="../../../../../../../assets/icons/Policy Holder.svg" alt="">
      <div class="title">{{ nameLine }}</div>
      <div class="ssn">{{ policyHolder?.person?.nationalId }}</div>
      <div class="address">{{ addressLine }}</div>
    </div>
    <div class="lookup row">
      <div class="details col-12">
        <div *ngIf="insurance.insuranceLookupPolicy" class="contact-info">
          <app-policy-lookup-contact [contactDetails]="insurance.insuranceLookupPolicy.customerContactInfo"></app-policy-lookup-contact>
        </div>
        <app-policy-lookup-details [policy]="insurance.insuranceLookupPolicy"></app-policy-lookup-details>
      </div>
    </div>
  </div>
</div>
