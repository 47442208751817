import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { SimpleCustomerProfile } from '@secca/shared/models/simpleCustomerProfile';
import { CauseItemType } from '@secca/shared/models/cause-item-type';
import { PurposeOfTravelItemType } from '@secca/shared/models/purpose-of-travel-item-type';
import { ServiceItemType } from '@secca/shared/models/service-item-type';
import { MasterListService } from '@secca/core/services/masterlist-service';
import { CaseService } from 'src/app/core/services/case.service';
import { ModalDialogComponent } from '@secca/shared/components/modal-dialog/modal-dialog.component';
import { ModalDialogConfiguration } from '@secca/shared/models/modal/modal-dialog-configuration';
import { MessageChannelType, MessageFrom, PermissionEnum, StakeholderTypeEnum, TaskStatus, TaskType } from '@secca/shared/models/enums';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import { PermissionService } from '@secca/core/services/permission.service';
import { TaskStateService } from '@secca/board/components/board-task/services/task-state.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Task } from '@secca/shared/models/task';
import { TaskService } from '@secca/core/services/task.service';
import { DialogHelperUtilService } from '@secca/core/services/dialog-helper-util.service';
import { IncomingCasesTaskViewModel } from '@secca/board/components/board-task/models/incoming-cases-task-view-model';
import { SimpleCaseStakeholder } from '@secca/shared/models/simpleCaseStakeholder';
import { CaseLockOverlayAction, LockContextEnum } from '@secca/shared/enums/lock-context.enum';
import { TaskViewModel } from '../../models/task-view.model';
import { TaskWebLeftPanelComponent } from './task-web-left-panel/task-web-left-panel.component';
import { TaskModalModeEnum } from '@secca/case/components/case-task/task-modal-static/task-modal-static-mode.enum';
import { DialogViewerServiceInterface } from '@secca/core/services/dialog-viewer-service-interface';
import { ChannelType } from '@secca/shared/enums/channelType';
import { DIALOG_VIEWER_TOKEN } from '@secca/core/services/token';
import { TaskWebPropertiesService } from '@secca/core/services/task-web-properties.service';
import { TaskWebModalMainPanelComponent } from './task-web-main-panel/task-web-modal-main-panel.component';
import { Message } from '@secca/case/components/case-output-management/models/message';
import { ServiceOrderMessageRequest } from '@secca/shared/models/service-order/service-order-message-request';
import { CaseMessageService } from '@secca/core/services/case-message.service';
import { OutputManagementService } from '@secca/case/components/case-output-management/services/output-management.service';
import { Case } from '@secca/shared/models/case';
import { DialogStateService } from '@secca/core/state-services/dialog-state.service';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { Person } from '@secca/shared/models/person';
import { PostHandlingsDto } from '@secca/shared/models/post-handlings-dto';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { Diagnosis } from '@secca/shared/models/dignosis';
import { CaseType } from '@secca/shared/models/case-type';
import { CaseTypesService } from '@secca/core/services/case-type.service';
import { TaskMessageModalMoveTaskComponent } from '../../board-task-table/task-message-modal/task-message-modal-move-task/task-message-modal-move-task.component';
import { Channel } from '@secca/shared/models/channel';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-task-web-modal',
  templateUrl: './task-web-modal.component.html',
  styleUrls: ['./task-web-modal.component.scss']
})
@AutoUnsubscribe
export class TaskWebModalComponent implements OnInit, OnDestroy {
  @ViewChild('taskModal') taskModal: TaskWebLeftPanelComponent;
  @ViewChild(TaskWebModalMainPanelComponent) taskModalMainPanelComponent;

  @Input() taskViewModel: TaskViewModel;
  @Input() taskStatusDisabled = false;
  @Input() hideSearchCreateButton = false;
  @Input() createCaseOnly = false;

  @Output()
  navigatedToCaseEvent: EventEmitter<void> = new EventEmitter();

  @Output() closed = new EventEmitter<void>();

  case: Case;
  endUser: SimpleCaseStakeholder;
  reporter: SimpleCaseStakeholder;
  oldTask: Task;
  taskDataViewModel: IncomingCasesTaskViewModel;

  moveModalRef: NgbModalRef;
  isMoveToCaseNumberValid = false;

  serviceItemList: ServiceItemType[];
  purposeOfTravelItemList: PurposeOfTravelItemType[];
  causes: Map<string, CauseItemType[]> = new Map<string, CauseItemType[]>();
  diagnoses: Diagnosis[];
  channels: Channel[];
  customerProfiles: SimpleCustomerProfile[];
  companyStakeholders: CaseStakeholder[];
  showSpinner = false;
  showOverlaySpinner = false;
  isSwitchReporterOnCaseAllowed = false;
  switchReporter = false;

  constructor(
    private taskStateService: TaskStateService,
    private taskService: TaskService,
    private modalService: NgbModal,
    private dialogHelperUtilService: DialogHelperUtilService,
    public taskWebPropertiesService: TaskWebPropertiesService,
    private caseService: CaseService,
    private masterListService: MasterListService,
    public caseLockHelperService: CaseLockHelperService,
    public permissionService: PermissionService,
    @Inject(DIALOG_VIEWER_TOKEN) private dialogViewerService: DialogViewerServiceInterface,
    private outputManagementService: OutputManagementService,
    private caseMessageService: CaseMessageService,
    private dialogStateService: DialogStateService,
    public caseStateService: CaseStateService,
    private caseTypesService: CaseTypesService,
    private translateService: TranslateService,
    private router: Router
  ) {
    this.masterListService.getServiceItemList().subscribe(result => this.serviceItemList = result);
    this.masterListService.getPurposeOfTravelList().subscribe(result => this.purposeOfTravelItemList = result);
    this.masterListService.getAllLevel1Causes().subscribe(result => this.causes.set('level1', result));
    this.masterListService.getAllLevel2Causes().subscribe(result => this.causes.set('level2', result));
    this.masterListService.getAllLevel3Causes().subscribe(result => this.causes.set('level3', result));
    this.masterListService.getAllDiagnoses().subscribe(result => this.diagnoses = result);
    this.masterListService.getAllChannelTypes().subscribe(result => this.channels = result);      
    this.caseService.getAllSimpleCustomerProfiles().subscribe(result => this.customerProfiles = result);    
  }

  ngOnInit(): void {}

  taskLoadedNotification(loaded: boolean): void {
    this.getTaskWithData(this.taskModal?.task);
  }

  ngOnDestroy(): void {
    this.taskStateService.modalTaskLoaded.next(null);
  }

  closeTaskWebDialog(force?: boolean) {
    if (!force && this.hasModelChanged()) {
      this.openLeaveWarningDialog();
    } else {
      this.close();
    }
  }

  close() {
    if (this.closed.observers) {
      this.closed.emit();
    }
    if (this.modalService.hasOpenModals()) {
      this.modalService.dismissAll();
    }
    this.dialogHelperUtilService.closeModal();
  }

  hasModelChanged(): boolean {
    if (!this.taskModal || !this.taskModal.task || !this.taskViewModel || !this.oldTask) {
      return false;
    }
    return this.taskChanged || this.reporterChanged();
  }

  saveTaskChanged() {
    if (this.reporterChanged()) {
      this.taskModal.task.internalSummary = this.taskModalMainPanelComponent.internalSummary;
      this.showSpinner = true;
      this.taskService
        .createMedicalReportFromTask(
          this.taskDataViewModel,
          this.taskModalMainPanelComponent.assignedReporter,
          this.taskModalMainPanelComponent.switchReporter
        )
        .subscribe(
          result => {
            if (this.taskChanged) {
              this.callUpdateTask();
            } else {
              this.finalizeTaskUpdate();
            }
          },
          error => {
            this.showSpinner = false;
            throw error;
          }
        );
    } else if (this.taskChanged) {
      this.callUpdateTask();
    }
  }

  createCase(): void {
    this.showOverlaySpinner = true;
    this.caseService.userCreateExternalCase(this.taskViewModel.caseId).pipe(
      tap(() => this.navigateToCase(this.taskViewModel.caseId.toString(), false)),
      finalize(() => this.showOverlaySpinner = false)
    )
    .subscribe();
  }

  private navigateToCase(caseId: string, newlyCreated: boolean) {
    this.router.navigate(['/case/', caseId], { state: { newCase: newlyCreated } });
    this.closed.emit();
    this.navigatedToCaseEvent.emit();
    this.dialogHelperUtilService.closeModal();
    this.modalService.dismissAll();
  }

  private finalizeTaskUpdate() {
    this.showSpinner = false;
    this.closeTaskWebDialog(true);
    this.taskStateService.refreshAllTasksAndPendingNumberOfTasks.next();
    this.taskStateService.refreshIncomingCasesAfterSaving.next();
  }

  private callUpdateTask() {
    this.showSpinner = true;
    this.taskModal.task.internalSummary = this.taskModalMainPanelComponent?.internalSummary;
    this.taskService.updateTask(this.taskModal.task).subscribe(
      result => this.finalizeTaskUpdate(),
      error => {
        this.showSpinner = false;
        throw error;
      }
    );
  }

  moveConfirmed(caseNumber: string) {
    this.caseService.getCase(caseNumber).pipe(
      tap(moveToCase => {
        this.caseTypesService.getCaseTypeByCaseId(''+this.taskModal?.caseId).subscribe(caseType => {
          if ( this.canMoveToCase(caseType, moveToCase.caseTypeCode) ) {
            this.moveTask(this.taskModal?.task?.id, caseNumber);
          }
          else {
            this.openWarningModal();
            this.isMoveToCaseNumberValid = false;
          }    
      })
    })).subscribe();      
  }

  openLeaveWarningDialog() {
    const modalRef = this.modalService.open(ModalDialogComponent);
    modalRef.componentInstance.items = [];
    modalRef.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'default-modal-header',
      title: 'case-tasks-leave-edited-task-or-go-back-and-save-pop-up',
      text: null,
      footer: 'case-validation-warning-modal-footer',
      yes: 'default-modal-dialog-yes',
      no: 'default-modal-dialog-no',
      isBody: true,
      isFooter: true,
    });
    modalRef.componentInstance.closeModalEvent.subscribe(
      closedClickingYes => {
        if (closedClickingYes) {
          this.close();
        }
        modalRef.close();
      },
      error => console.log(error)
    );
  }

  openMoveTaskModal() {
    this.isMoveToCaseNumberValid = true;
    this.dialogViewerService.openTaskMessageMoveDialog(false, this.isMoveToCaseNumberValid, this.taskModal?.caseId, evt => this.moveCaseNumberEntered(evt), evt => this.moveConfirmed(evt));
  }

  moveCaseNumberEntered(value: boolean) {
    this.isMoveToCaseNumberValid = value;
  }

  get title(): string {
    let channelTypeName = !!this.taskDataViewModel?.channelType ? this.getChannelTypeName(this.taskDataViewModel?.channelType) : '';
    return this.translateService.instant('task-web-modal-handle-task', {taskName: channelTypeName || ''});
  }

  get taskChanged(): boolean {
    return (
      this.oldTask.internalSummary !== this.taskModalMainPanelComponent?.internalSummary ||
      this.oldTask.title !== this.taskModal.task.title ||
      this.oldTask.status !== this.taskModal.task.status ||
      this.oldTask.userId !== this.taskModal.task.userId ||
      this.oldTask.adtCode !== this.taskModal.task.adtCode ||
      this.oldTask.priority !== this.taskModal.task.priority
    );
  }

  get TaskType() {
    return TaskType;
  }

  get TaskModalModeEnum() {
    return TaskModalModeEnum;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  get LockContextEnum(): any {
    return LockContextEnum;
  }

  get CaseLockOverlayAction(): any {
    return CaseLockOverlayAction;
  }

  get isDisabled(): boolean {
    return this.createCaseOnly || this.caseStateService.isCaseDisabled();
  }

  private getTaskWithData(task: Task) {
    this.oldTask = new Task();
    Object.assign(this.oldTask, task);

    this.taskService.getWebTaskById(task.id).subscribe(taskModel => {
      this.taskDataViewModel = taskModel;
      this.getRelevantInformation();
    });

    if (this.isSupplierChannel()) {
      this.getReporterInformation();
    }
  }

  private openWarningModal() {
    const modalRef = this.modalService.open(ModalDialogComponent);
    modalRef.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'task-message-modal-move-warning-header',
      text: 'task-message-modal-move-warning-text',
      no: 'task-message-modal-move-warning-ok',
      isBody: true,
      isFooter: true,
      showYesNoButton: true,
    });

    modalRef.componentInstance.saveandcloseEvent.subscribe(
      emittedEvent => {
        this.closeModal();
      }
    );

    modalRef.componentInstance.closeModalEvent.subscribe(
      emittedEvent => {
        modalRef.close();
      },
      err => console.log(err)
    );
  }

  private closeModal() {
    this.modalService.dismissAll();
  }

  private canMoveToCase(caseType: CaseType, caseTypeCode: string): boolean {
    return !!caseType?.allowableCaseTypes?.find(allowed => allowed.code === caseTypeCode) || caseType?.code === caseTypeCode;
  }

  private isSupplierChannel(): boolean {
    const channelType = ChannelType[this.taskViewModel?.channelType];
    return channelType === ChannelType.SUPPLIER_PORTAL || channelType === ChannelType.UNKNOWN_SUPPLIER;
  }

  private getRelevantInformation() {
    this.endUser = this.taskDataViewModel?.data?.caseStakeholders?.find(
      stakeHolder => stakeHolder.stakeholderType === StakeholderTypeEnum.endUser
    );
    this.reporter = this.taskDataViewModel?.data?.caseStakeholders?.find(
      stakeHolder => stakeHolder.stakeholderType === StakeholderTypeEnum.reporter
    );
    if (!this.endUser) {
      if (this.reporter?.properties.includes('END_USER')) {
        this.endUser = this.reporter;
      }
    }
    this.taskModalMainPanelComponent.internalSummary = this.taskDataViewModel.internalSummary;
    this.companyStakeholders = [];
    this.caseService.getStakeholdersOnCase(this.taskViewModel.caseId?.toString()).subscribe(stakeholders => {
      this.companyStakeholders = stakeholders.filter(
        stakeholder =>
          stakeholder.isCompany &&
          !stakeholder.hasRole(StakeholderTypeEnum.policyHolder) &&
          (!stakeholder.hasRole(StakeholderTypeEnum.reporter) ||
            stakeholder.properties?.some(prop => prop === StakeholderTypeEnum.reporter))
      );
    });
    if (this.isSupplierChannel() && this.taskDataViewModel?.customerProfileLegacy) {
      this.taskService.getCustomerProfiles('SUPPLIER_PORTAL').subscribe(result => (this.customerProfiles = result));
    }
  }

  private getReporterInformation() {
    this.caseService.getCase('' + this.taskViewModel.caseId).subscribe(theCase => {
      const caseChannelType = ChannelType[theCase.channelType];
      this.isSwitchReporterOnCaseAllowed =
        caseChannelType === ChannelType.SUPPLIER_PORTAL || caseChannelType === ChannelType.UNKNOWN_SUPPLIER;
      if (this.isSwitchReporterOnCaseAllowed) {
        this.taskService.getWebTaskByCaseId(this.taskViewModel.caseId).subscribe(tasks => {
          this.switchReporter = tasks?.length === 1;
          if (tasks?.length > 1) {
            const receivedFirst = tasks.sort((t1, t2) => t1.receivedDate.valueOf() - t2.receivedDate.valueOf())[0];
            this.switchReporter = receivedFirst.id === Number.parseInt(this.taskViewModel.id);
          }
        });
      }
    });
  }

  private moveTask(taskId: number, caseNumber: string) {
    this.taskService.moveTask(taskId, caseNumber).subscribe(
      () => {
        this.taskStateService.refreshAllTasksAndPendingNumberOfTasks.next();
        this.close();
      },
      () => (this.isMoveToCaseNumberValid = false)
    );
  }

  openMessageModalGDPR(doneTask: boolean, deleteTask: boolean) {
    this.caseService
      .getCaseStakeholdersOnCase('' + this.taskDataViewModel.caseId)
      .subscribe(stakeholders => {
        const reporters = stakeholders.filter(item => item.hasRole(StakeholderTypeEnum.reporter));
        this.openMessageModal(Number(reporters.pop().id), doneTask, deleteTask);
      });
  }

  openMessageModal(reporterStakeholderId: number = undefined, doneTask: boolean, deleteTask: boolean) {
    const message = new Message();
    message.caseId = '' + this.taskDataViewModel.caseId;
    message.messageChannelType = MessageChannelType.EMAIL;
    message.messageFrom = MessageFrom.EMAIL_SENDER_SOS;
    message.preSelectDocumentAttachments = this.taskDataViewModel.attachments;
    message.replyWebRequestData = this.taskDataViewModel.data;
    message.subject = 'RE: ' + this.taskDataViewModel.title;
    message.subjectLock = true;
    message.caseNumber = ' ';

    message.receiverIsNotChangable = true;
    if (reporterStakeholderId) {
      message.stakeholderReceiverId = reporterStakeholderId;
    } else {
      message.receiver = new CaseStakeholder({ person: new Person({ preferredLanguageCode: 'en' }) });
    }
    message.postHandlings = new PostHandlingsDto({
      webTaskToRemove: this.taskDataViewModel.id,
      removeSentMail: true,
      sendToReporter: !!reporterStakeholderId,
    });

    const srMessage = new ServiceOrderMessageRequest({
      messageService: this.caseMessageService,
      message,
    });

    const matDialogRef = this.dialogViewerService.openOutputManagementDialog(
      new Case({ id: '' + this.taskDataViewModel.caseId, caseNumber: this.taskDataViewModel.caseNumber }),
      MessageChannelType.EMAIL,
      srMessage,
      null,
      () => this.handleMessageSent(),
      true
    );
    setTimeout(() => this.dialogStateService.sendToFront(matDialogRef.id), 0);
  }

  private handleMessageSent(): void {
    setTimeout(() => {
      this.taskStateService.refreshIncomingCasesAfterSaving.next();
      this.close();
    }, 500);
  }

  private reporterChanged(): boolean {
    const originalAssigned = this.taskDataViewModel?.assignedReporterId;
    const currentAssigned = this.taskModalMainPanelComponent?.assignedReporter?.id;
    const originalNull = originalAssigned === null || originalAssigned === undefined;
    const currentNull = currentAssigned === null || currentAssigned === undefined;

    return (originalNull && !currentNull) || (!originalNull && !currentNull && originalAssigned !== currentAssigned);
  }

  taskStatusChanged(newTaskStatus: TaskStatus) {
    this.taskModalMainPanelComponent.updatedTaskStatus = newTaskStatus;
  }

  isValidated(): boolean {
    if (this.isSupplierChannel() && this.oldTask.taskType !== TaskType.processGopRequest) {
      return (
        (this.taskModal.task.status === TaskStatus.done && this.taskModalMainPanelComponent?.assignedReporter?.id) ||
        this.taskModal.task.status !== TaskStatus.done
      );
    }
    return true;
  }

  private getChannelTypeName(channelTypeEnumValue: string) {
    return this.channels?.find(c => c.channelTypeEnumValue === channelTypeEnumValue)?.channelTypeName;
  }
}
