import { NoteService } from '@secca/core/services/note.service';
import { NoteDtoWrapper } from '@secca/shared/models/note';
import { CallType, ShortcutEnum, StakeholderTypeEnum } from '@secca/shared/models/enums';
import { HelperService } from '@secca/core/services/helper.service';
import { Subject, Subscription, of } from 'rxjs';
import { CaseService } from 'src/app/core/services/case.service';
import { Component, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AutoUnsubscribe } from 'src/app/shared/decorators/auto-unsubscribe';
import { Note, NoteDto } from 'src/app/shared/models/note';
import { NoteTypeEnum } from 'src/app/shared/models/enums';
import { NgbModal, NgbNav, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { DictionaryService } from '@secca/core/services/dictionary.service';
import { StakeholderType } from '../case-stakeholders/stakeholderType';
import { PhoneService } from '@secca/core/services/phone.service';
import { PhoneCall } from '@secca/shared/models/phoneCall';
import { ProviderHelper } from '@secca/shared/helpers/provider-helper';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { Person } from '@secca/shared/models/person';
import { NoteView } from '@secca/shared/models/note-view';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ShortcutService } from '@secca/core/services/shortcut.service';
import { SettingsService } from '@secca/core/services/settings.service';
import moment from 'moment-timezone';
import { CaseTypeCodes } from '@secca/shared/models/case-type';

@Component({
  selector: 'app-case-notes',
  templateUrl: './case-notes.component.html',
  styleUrls: ['./case-notes.component.scss'],
})
@AutoUnsubscribe
export class CaseNotesComponent implements OnInit, OnDestroy {
  @ViewChild('nav') private tabSet: NgbNav;
  @ViewChild('noteTypeDialog') private noteTypeDialog: TemplateRef<any>;
  @ViewChild('caseNotesElement') private caseNotesElement: ElementRef;

  @Input() caseId: string;
  timerSubsc$;
  showNewNoteDialog: boolean;
  talkedToItems: CaseStakeholder[];
  talkedToItemsOutgoing: CaseStakeholder[];
  noteViews: NoteView[] = [];
  loggedCall: PhoneCall[];
  subscribe: Subscription;
  subscribe1: Subscription;
  noteDto: NoteDto;
  noteEditStakeholderId: string;
  stakeholderTypes: StakeholderType[];
  stakeholderType: string;
  active: string;
  $caseTypeSubscr: Subscription;
  isCoordinationCase = false;

  private modelChanged: Subject<string> = new Subject<string>();
  private providerHelper: ProviderHelper;

  private shortcutSubscription: Subscription;
  private $modelChangedSubscr: Subscription;
  private $editNoteSubsc: Subscription;
  private $stakeholderSubscr: Subscription;
  private $stakeholderTypesSubr: Subscription;
  private $providersUpdatedSubscr: Subscription;

  constructor(
    private localStorageService: LocalStorageService,
    private noteDtoWrapper: NoteDtoWrapper,
    private helperService: HelperService,
    private caseService: CaseService,
    private noteService: NoteService,
    private dictionaryService: DictionaryService,
    private phoneService: PhoneService,
    private shortcutService: ShortcutService,
    private modalService: NgbModal,
    private caseStateService: CaseStateService,
    public settingsService: SettingsService,
  ) {
    this.shortcutSubscription = this.shortcutService.addShortcut({ keys: ShortcutEnum.CaseNotes }).subscribe(a => {
      this.caseNotesElement.nativeElement.focus();
    });
    this.caseTypeSubscribe();
  }

  ngOnInit() {
    this.noteViews = this.localStorageService.getNotes(this.caseId);
    this.noteViews.forEach(a => (a.isFocused = false));
    if (this.noteViews.length === 0) {
      this.noteViews.unshift(new NoteView({ note: new Note({ type: NoteTypeEnum.phone, id: this.helperService.getRandomGuid() }), isFocused: true }));
    }
    this.noteViews[0].isFocused = true;
    this.active = this.noteViews[0].note?.id;
    this.$modelChangedSubscr = this.modelChanged.pipe(
      debounceTime(200), // wait 200ms after the last event before emitting last event
      distinctUntilChanged() // only emit if value is different from previous value
    )
    .subscribe(() =>  this.localStorageService.saveNotes(this.caseId, this.noteViews));

    this.$stakeholderSubscr = this.caseStateService.stakeholder.subscribe((stakeholder: CaseStakeholder) => {
      this.getCaseStakeholders();
    });

    this.$providersUpdatedSubscr = this.caseStateService.getProvidersUpdated().subscribe(() => {
      this.getCaseStakeholders();
    });


    this.$editNoteSubsc = this.noteService.editedNote.subscribe(result => {
      const noteEdit: Note = result;
      this.addEditedNote(noteEdit);
    });

    this.$stakeholderTypesSubr = this.dictionaryService.getStakeholdersTypes().subscribe(
      result => {
        this.stakeholderTypes = result;
      },
      error => console.log(error)
    );

    this.getCaseStakeholders();
    this.getLoggedCall();
  }

  ngOnDestroy(): void {
    this.shortcutSubscription.unsubscribe();
  }

  get freeTextFieldPlaceholder() {
    return this.helperService.freeTextFieldPlaceholder;
  }

  get noteTypeEnum() {
    return NoteTypeEnum;
  }

  submit(noteView: NoteView) {
    noteView.shouldBeValidated = true;
    if (noteView.note.type == NoteTypeEnum.phone) {
      if (noteView.note.description === '' || noteView.note.stakeholder == null || noteView.note.callType == null) {
        return;
      }
    }
    if (noteView.note.type == NoteTypeEnum.case) {
      if (noteView.note.description === '') {
        return;
      }
      noteView.note.stakeholder = new CaseStakeholder();
      noteView.note.callType = null;
    }

    this.localStorageService.saveNotes(this.caseId, this.noteViews);
    noteView.note.caseId = +this.caseId;
    this.noteDto = this.noteDtoWrapper.wrap(noteView.note, this.stakeholderTypes);
    if (!isNaN(+noteView.note.originalId)) {
      this.noteEditStakeholderId = null;
      this.noteDto.id = +noteView.note.originalId;
      this.subscribe1 = this.caseService.editNoteToCase(this.noteDto).subscribe(() => {
        this.noteService.editedNoteUpdatedSend({ id: this.noteDto.id, note: noteView.note });
        this.closeNoteTab(noteView);
      });
    } else {
      this.noteDto.id = null;
      this.subscribe1 = this.caseService.addNoteToCase(this.noteDto).subscribe(
        () => {
          this.closeNoteTab(noteView);
        },
        error => {
          this.openProviderModal(error);
        }
      );
    }
  }

  private closeNoteTab(noteView: NoteView) {
    if ( this.noteViews.length > 1 ) {
      const noteIndex = this.noteViews.indexOf(noteView);
      if (noteIndex === 0 ) {
        this.noteViews = this.noteViews.filter((noteView,index) => index != noteIndex);
        this.noteViews = [new NoteView({ note: new Note({ type: NoteTypeEnum.phone, id: this.helperService.getRandomGuid() }), isFocused: true }), ...this.noteViews];
        this.active = this.noteViews[0].note?.id;
      }
      else {
        this.active = this.findNextNoteId(noteIndex);
        this.noteViews = this.noteViews.filter((noteView,index) => index != noteIndex);
      }
    }
    else {
      this.noteViews = [];
      this.addPhoneNote();
    }

    this.localStorageService.saveNotes(this.caseId, this.noteViews);
  }

  private openProviderModal(error: any) {
    this.providerHelper = new ProviderHelper(this.modalService);
    this.providerHelper.messageError(error, 'note-error-modal-header', 'note-error-title', 'note-error-message');
  }

  caseStakeholderChanged(noteView: NoteView) {
     if(noteView.note.stakeholder?.person) {
      this.stakeholderType = noteView.note.stakeholder.person.firstName;
    } else if (noteView.note.stakeholder?.company) {
      this.stakeholderType = noteView.note.stakeholder.stakeholderType
    }

    this.localStorageService.saveNotes(this.caseId, this.noteViews);
  }

  addNewNote() {
    this.showNewNoteDialog = !this.showNewNoteDialog;
  }

  getCaseStakeholders() {
    this.subscribe = this.caseService.getStakeholdersOnCase(this.caseId).subscribe(
      result => {
        if (this.isCoordinationCase) {
          this.talkedToItems = result.filter(caseStakeholder => ![StakeholderTypeEnum.endUser, StakeholderTypeEnum.policyHolder, StakeholderTypeEnum.reporter].includes(caseStakeholder.stakeholderType));
        } else {
          this.talkedToItems = result;
        }
        this.talkedToItemsOutgoing = this.talkedToItems?.concat(new CaseStakeholder({ id: null, stakeholderType: null, person: new Person({ firstName: 'Other', surname: null }) }));
      },
      error => console.log(error)
    );
  }

  getLoggedCall() {
    this.phoneService.getLatestPhoneCall().subscribe(lastPhoneCall => {
      this.loggedCall = [lastPhoneCall];
    });
  }

  closeTab(noteView: NoteView, event: any) {
    event.preventDefault();
    event.stopPropagation();
    const noteIndex = this.noteViews.indexOf(noteView);
    this.active = this.findNextNoteId(noteIndex);
    this.noteViews.splice(noteIndex, 1);
    this.localStorageService.saveNotes(this.caseId, this.noteViews);
  }

  isDisabled(noteView: NoteView) {
    const disabled = (this.caseStateService.isCaseClosed || this.caseStateService.isInvoiceClosedWithNoInvoicingToCustomer) &&
                     (noteView.note && noteView.note.callType === CallType.outgoing);
    return disabled;
  }

  beforeChange($event: NgbNavChangeEvent) {
    const noteView: NoteView = this.noteViews.find(a => a.note.id === $event.nextId);
    this.setActive(noteView);
  }

  addEditedNote(noteEdit: Note) {
    const noteViewEdit = new NoteView();
    noteViewEdit.note = noteEdit;
    noteViewEdit.hasBeenSubmitted = true;
    noteViewEdit.isEdit = true;
    if (noteEdit.type === NoteTypeEnum.phone) {
      this.noteEditStakeholderId = noteEdit.stakeholder.id;
      const stakeholderType = noteEdit.stakeholder.stakeholderType;
      const isPerson = noteEdit.stakeholder.person != null;
      const talkedToItems = noteEdit.callType === CallType.outgoing ? this.talkedToItemsOutgoing : this.talkedToItems;
      if (isPerson) {
        const personFirstName = noteEdit.stakeholder.person ? noteEdit.stakeholder.person.firstName : null;
        const personSurName = noteEdit.stakeholder.person ? noteEdit.stakeholder.person.surname : null;
        noteViewEdit.note.stakeholder = talkedToItems.find(
          stakeholder =>
            stakeholder.hasRole(stakeholderType) &&
            stakeholder.person.firstName === personFirstName &&
            stakeholder.person.surname === personSurName
        );
      } else {
        const companyName = noteEdit.stakeholder.company.name;
        noteViewEdit.note.stakeholder = talkedToItems.find(
          stakeholder => stakeholder.hasRole(stakeholderType) && stakeholder.company.name === companyName
        );
      }
    }
    this.addNote(noteViewEdit);
    this.localStorageService.saveNotes(this.caseId, this.noteViews);
  }

  addPhoneNote() {
    let noteView = new NoteView();
    noteView.note.type = NoteTypeEnum.phone;
    this.addNote(noteView);
  }

  addCaseNote() {
    let noteView = new NoteView();
    noteView.note.type = NoteTypeEnum.case;
    this.addNote(noteView);
  }

  setActive(noteView: NoteView) {
    const focused = this.noteViews.find(a => a.isFocused === true);
    if ( focused ) {
      focused.isFocused = false;
    }
    this.noteViews.find(a => a.note.id === noteView.note.id).isFocused = true;
    this.active = noteView.note.id;
    if ( noteView.isEdit && noteView.note.type === NoteTypeEnum.phone && !!noteView.note.connectionCallId ) {
      this.loggedCall = [new PhoneCall({
        callType: noteView.note?.callType,
        phoneNumber: noteView.note?.callType === CallType.ingoing ? noteView.note?.callFrom : noteView.note?.callTo,
        connectionCallId: noteView.note?.connectionCallId
      })];
    }
  }

  addNote(noteView: NoteView) {
    if (this.noteViews.length < 6) {
      noteView.note.id = this.helperService.getRandomGuid();
      this.noteViews.push(noteView);
      this.setActive(noteView);
    }
  }

  textChanged(noteView: NoteView, text: string) {
    noteView.hasBeenSubmitted = false;
    this.modelChanged.next(text);
  }

  callTypeChangeOn(callType: CallType) {}

  onClickedOutside() {
    this.showNewNoteDialog = false;
  }

  onSelectedPhoneConversationChange(noteView: NoteView, lastPhoneCall: PhoneCall) {
    if ( !!lastPhoneCall ) {
      noteView.note.connectionCallId = lastPhoneCall.connectionCallId;
      noteView.note.callType = lastPhoneCall.callType;
      if (lastPhoneCall.callType === CallType.outgoing) {
        noteView.note.callTo = lastPhoneCall.phoneNumber;
      } else {
        noteView.note.callFrom = lastPhoneCall.phoneNumber;
      }
    }
    else {
      setTimeout(() => {
        Object.assign(noteView.note, { callType: null, callTo: null, callFrom: null, callLength: null, connectionCallId: null});
      }, 0);
    }

    this.localStorageService.saveNotes(this.caseId, this.noteViews);
  }

  onCallTypeChange(noteView: NoteView, callType: CallType) {
    Object.assign(noteView.note, { callType, callTo: null, callFrom: null, callLength: null, connectionCallId: null});
    this.localStorageService.saveNotes(this.caseId, this.noteViews);
  }

  canClose(noteView: NoteView) {
    return noteView.hasBeenSubmitted || (!noteView.hasBeenSubmitted && noteView.note.description === '');
  }

  get CallType() {
    return CallType;
  }

  private findNextNoteId(index: number): string {
    if ( index > 0 ) {
      return this.noteViews[index-1].note?.id;
    }
    else if ( index === 0 && this.noteViews.length > 1 ) {
      return this.noteViews[1].note?.id;
    }
    else if ( this.noteViews.length > 0 ) {
      return this.noteViews[0].note?.id;
    }
  }

  private caseTypeSubscribe() {
    this.$caseTypeSubscr = this.caseStateService.getCaseType().subscribe(caseType => {
      // When caseType changes: Possible change of customer profile on backend. Need to neglect cache and force backend call to fetch correct personInsurance
      this.isCoordinationCase = CaseTypeCodes.INTERNAL_COORDINATION === caseType.code;
    });
  }
}
