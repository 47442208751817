import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';

export class CaseHistoryDataFieldChangeDto {
  field: string;
  oldValue: string;
  newValue: string;

  public static readonly CALL_DURATION_TIME = 'Call duration time';

  public constructor(init?: Partial<CaseHistoryDataFieldChangeDto>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})

export class CaseHistoryDtoAdapter implements Adapter<CaseHistoryDataFieldChangeDto> {
  adapt(item: any): CaseHistoryDataFieldChangeDto {
    return new CaseHistoryDataFieldChangeDto({
      field: item.field,
      oldValue: item.oldValue,
      newValue: this.formatValue(item)
    });
  }

  private formatValue(item: any): string {
    if (item.field === CaseHistoryDataFieldChangeDto.CALL_DURATION_TIME && item.newValue != null) {
      return this.formatTimeDuration(item.newValue);
    }
    return item.newValue;
  }

  private formatTimeDuration(totalTimeInSecAsString: string): string {
    const totalTimeInSec = Number(totalTimeInSecAsString);
    const hours = Math.floor(totalTimeInSec / 3600);
    const minutes = Math.floor((totalTimeInSec - (hours * 3600)) / 60);
    const seconds = totalTimeInSec - (hours * 3600) - (minutes * 60);

    return hours.toString().padStart(2, '0') + ':' +
      minutes.toString().padStart(2, '0') + ':' +
      seconds.toString().padStart(2, '0');
  }
}
