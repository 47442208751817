import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-linked-modal-link-case',
  templateUrl: './linked-modal-link-case.component.html',
  styleUrls: ['./linked-modal-link-case.component.scss']
})
export class LinkedModalLinkCaseComponent {

  @Output() linkCaseEvent = new EventEmitter<string>();
  @Output() closeModal = new EventEmitter<string>();
  @Input() caseNumberOnCase: string;
  @Input() existingLinkedCaseNumbersOnCase: string[] = [];

  caseNumber: string = '';
  isCaseNumberValid = true;
  moveInProgress = false;
  private caseNumberRegex: RegExp = /[A-Z]{4}(-\d{3}){3}/;

  constructor() { }

  setDirty() {
    this.isCaseNumberValid = true;
    this.caseNumber = this.caseNumber?.toUpperCase();
  }

  linkCase() {
    this.linkCaseEvent.emit(this.caseNumber);
  }

  close() {
    this.closeModal.emit();
  }

  isValidCaseNumber(): boolean {
    return this.caseNumberRegex.test(this.caseNumber) && this.isCaseNumberValid && (!this.existingLinkedCaseNumbersOnCase.includes(this.caseNumber.trim())) && (this.caseNumber.trim() !== this.caseNumberOnCase) && this.caseNumber.length <= 16;
  }


}
