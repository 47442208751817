import { HelperService } from '@secca/core/services/helper.service';
import { PersonInsurance } from './../../../../shared/models/person-insurance';
import { Case } from 'src/app/shared/models/case';
import { Component, Input, OnInit } from '@angular/core';
import { OnRefresh } from 'src/app/shared/interfaces/on-refresh';
import { PlannedTravel } from 'src/app/shared/models/planned-travel';
import { CaseIncident } from 'src/app/shared/models/caseIncident';
import { IncidentService } from 'src/app/core/services/incident.service';
import { UpdateTimeLineService } from 'src/app/core/services/updateTimeLine.service';
import { InsuranceService } from '../../../../core/services/insurance.service';
import * as moment from 'moment';
import { MomentHelperService } from '@secca/core/services/moment-helper.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { PermissionService } from '@secca/core/services/permission.service';
import { PlannedTravelService } from '@secca/core/services/planned-travel.service';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { FinancialStatus } from '@secca/shared/models/financial-status';

@Component({
  selector: 'app-case-timeline',
  templateUrl: './case-timeline.component.html',
  styleUrls: ['./case-timeline.component.scss']
})
@AutoUnsubscribe
export class CaseTimelineComponent implements OnInit, OnRefresh {

  @Input()
  set case(newCase: Case) {
      this._case = newCase;
      this.onRefresh();
  }

  get case(): Case {
    return this._case;
  }

  @Input() caseClosed: false;
  @Input() financialStatus: FinancialStatus;
  
  private _case: Case;
  personInsurance: PersonInsurance;
  incident: CaseIncident;
  plannedTravel: PlannedTravel;
  daysSinceTravelStartToToday: number;
  daysSinceTravelStartToIncident: number;
  coverageStopIconColor: string;
  longPeriodInDays: number;
  shortPeriodInDays: number;
  travelLinePercentNumber: number;

  coverageStopDate: Date;
  today: moment.Moment;

  shortPeriodStopLinePercent: string;
  todayPercent: string;
  incidentLinePercent: string;
  coverageStopIconPercent: string;
  travelLinePercent: string;
  incidentIconPercent: string;

  displayTravelLine: boolean;
  displayCoverageStopIcon: boolean;
  displayIncident: boolean;
  displayTodayLine: boolean;

  confirmUnlockDialog: NgbModalRef;

  $insuranceSubscr: Subscription;
  $updateTravelSubscr: Subscription;
  $incidentSubscr: Subscription;
  $personInsuranceSubscr: Subscription;
  $plannedTravelSubscr: Subscription;
  $incidentServiceSubscr: Subscription;

  constructor(
    private incidentService: IncidentService,
    private insuranceService: InsuranceService,
    private updateTimeLineService: UpdateTimeLineService,
    private plannedTravelService: PlannedTravelService,
    public caseLockHelperService: CaseLockHelperService,
    public permissionService: PermissionService,
    private helperService: HelperService,
    public caseStateService: CaseStateService) {}

  ngOnInit() {
    let startUp = true;
    this.$insuranceSubscr = this.updateTimeLineService.updateInsurance.subscribe( value => {
      if (!startUp) {
        this.personInsurance = value;
        this.createTimeLine();
      }
    });
    this.$updateTravelSubscr = this.updateTimeLineService.updateTravel.subscribe(value => {
      if (!startUp) {
        this.plannedTravel = value;
        this.createTimeLine();
      }
    });
    this.$incidentSubscr = this.updateTimeLineService.updateIncidente.subscribe( value => {
      if (!startUp) {
        this.incident = value;
        this.createTimeLine();
      }
    });
    this.today = moment.utc();
    startUp = false;
    this.createTimeLine();
  }

  onRefresh() {
    if ( this.$personInsuranceSubscr ) {
      this.$personInsuranceSubscr.unsubscribe();
    }
    if ( this.$plannedTravelSubscr ) {
      this.$plannedTravelSubscr.unsubscribe();
    }
    if ( this.$incidentServiceSubscr ) {
      this.$incidentServiceSubscr.unsubscribe();
    }

    this.$personInsuranceSubscr = this.insuranceService.getPersonInsurance(this.case.id).
    subscribe(result1 => {
      this.personInsurance = result1;
      this.$plannedTravelSubscr = this.plannedTravelService.getPlannedTravel(this.case.id).
      subscribe(result2 => {
        this.plannedTravel = result2;
        this.$incidentServiceSubscr = this.incidentService.getCaseIncident(this.case.incident.id).
        subscribe(result3 => {
          this.incident = result3;
          this.createTimeLine();
        });
      });
    });
  }

  createTimeLine() {
    this.displayTodayLine = false;
    this.coverageStopIconColor = 'GREY';
    this.checkIfTravelLineShouldBeDisplayed();
    this.checkIfCoverageStopIconShouldBeDisplayed();
    this.checkIfIncidentShouldBeDisplayed();
    this.daysSinceTravelStartToToday = null;
    this.daysSinceTravelStartToIncident = null;
    if (this.plannedTravel  && this.plannedTravel.departureDate) {
      this.daysSinceTravelStartToToday = MomentHelperService.getDaysRangeBothIncl(this.plannedTravel.departureDate, this.today);
      this.setdaysSinceTravelStartToIncident();
      this.setcoverageStopDate();
    }
    this.calcTimelineSetup();
  }

  calcTimelineSetup() {

    if (!this.displayIncident && !this.displayCoverageStopIcon && !this.displayTravelLine) {
      this.displayTodayLine = false;
    }
    if (this.displayIncident && !this.displayTravelLine) {
      this.displayTodayLine = false;
      this.displayCoverageStopIcon = false;
      this.incidentLinePercent = '48%';
      this.incidentIconPercent = '49%';
    }
    if (!this.displayIncident && this.displayCoverageStopIcon && !this.displayTravelLine) {
      this.displayTravelLine = false;
      this.displayCoverageStopIcon = false;
      this.displayIncident = false;
      this.displayTodayLine = false;
    }
    if (!this.displayCoverageStopIcon && this.displayTravelLine) {
      this.longPeriodInDays = this.helperService.getNumberFromString(this.plannedTravel.durationDays);
      this.travelLinePercent = '96%';
      this.isTodayInsideTheLongestPeriod();
      this.isIncidentInsideTheLongestPeriod();
    }
    if (this.displayCoverageStopIcon && this.displayTravelLine) {
      this.setValuesIfcoveragePeriodIsLongerThanTravelPeriod();
      this.setValuesIfcoveragePeriodShorterOrEqualToTravelPeriod();
      this.shortPeriodStopLinePercent = (((this.shortPeriodInDays / this.longPeriodInDays) * 96) + 1.5) + '%';
      this.isIncidentInsideTheLongestPeriod();
      this.isTodayInsideTheLongestPeriod();

      if (this.travelLinePercentNumber  < 20) {
        this.minimumValueForTravelLinePercent();
      }
    }

    if (!this.incident || MomentHelperService.getDaysRangeBothIncl(this.incident.incidentDate, this.today) <= 0) {
      this.displayIncident = false;
    }
  }

  isTodayInsideTheLongestPeriod() {
    const durationDaysNumber = this.helperService.getNumberFromString(this.plannedTravel.durationDays);
    const coverageDaysNumber = this.helperService.getNumberFromString(this.personInsurance.coverageDays);
    if (0 < this.daysSinceTravelStartToToday && this.personInsurance &&
      ((this.daysSinceTravelStartToToday <= durationDaysNumber) ||
      this.daysSinceTravelStartToToday <= coverageDaysNumber)) {
      this.displayTodayLine = true;
      this.todayPercent = ((this.daysSinceTravelStartToToday / this.longPeriodInDays) * 96) + 1.5 + '%';
    }
    if (this.personInsurance && this.personInsurance.coverageDays) {
      this.setColorOfSoverageStopIconIfCoverageEndsIn3OrLessDays();
    }
  }

  isIncidentInsideTheLongestPeriod() {
    const durationDaysNumber = this.helperService.getNumberFromString(this.plannedTravel.durationDays);
    const coverageDaysNumber = this.helperService.getNumberFromString(this.personInsurance.coverageDays);
    if (0 < this.daysSinceTravelStartToIncident && this.displayIncident &&
      (this.daysSinceTravelStartToIncident <= durationDaysNumber ||
      this.daysSinceTravelStartToIncident <= coverageDaysNumber))
    {
      this.displayIncident = true;
      this.incidentLinePercent = (((this.daysSinceTravelStartToIncident / this.longPeriodInDays) * 96)) + '%';
      this.incidentIconPercent = (((this.daysSinceTravelStartToIncident / this.longPeriodInDays) * 96) + 0.7) + '%';
    } else {
      this.displayIncident = false;
    }
  }

  minimumValueForTravelLinePercent() {
    this.travelLinePercent = '20%';
    this.shortPeriodStopLinePercent = '21.5%';
    const travelDays = this.helperService.getNumberFromString(this.plannedTravel.durationDays);
    const coverageDays = this.helperService.getNumberFromString(this.personInsurance.coverageDays);
    if (this.daysSinceTravelStartToToday <= travelDays && this.displayTodayLine) {
      this.setTodayPercentIfTravelLinePercentIsLowerThan20Percent(travelDays);
    } else if (this.daysSinceTravelStartToToday < coverageDays && this.displayTodayLine) {
      this.todayPercent = (((this.daysSinceTravelStartToToday / coverageDays) * 78.5) + 21.5) + '%';
    }
    if (this.daysSinceTravelStartToIncident <= travelDays && this.displayIncident) {
      this.setIncidentPercentIfTravelLinePercentIsLowerThan20Percent(travelDays);
    } else if (this.daysSinceTravelStartToIncident < coverageDays && this.displayIncident) {
      this.incidentLinePercent = ((this.daysSinceTravelStartToIncident / coverageDays) * 78.5) + 19.6 + '%';
      this.incidentIconPercent = (((this.daysSinceTravelStartToIncident / coverageDays) * 78.5) + 20.8) + '%';
    }
  }

  private addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  private checkIfTravelLineShouldBeDisplayed() {
    if (this.plannedTravel && this.plannedTravel.departureDate && this.plannedTravel.arrivalDate) {
      this.displayTravelLine = true;
    } else { this.displayTravelLine = false; }
  }

  private checkIfCoverageStopIconShouldBeDisplayed() {
    if (
      this.personInsurance &&
      this.personInsurance.coverageDays &&
      this.helperService.getNumberFromString(this.personInsurance.coverageDays) > 0
    ) {
      this.displayCoverageStopIcon = true;
    } else {
      this.displayCoverageStopIcon = false;
    }
  }

  private checkIfIncidentShouldBeDisplayed() {
    if (this.incident && this.incident.incidentDate) {
      this.displayIncident = true;
    } else { this.displayIncident = false; }
  }

  private setdaysSinceTravelStartToIncident() {
    if (this.incident && this.incident.incidentDate) {
      this.daysSinceTravelStartToIncident = MomentHelperService.getDaysRangeBothIncl(
        this.plannedTravel.departureDate,
        this.incident.incidentDate
      );
    }
  }

  private setcoverageStopDate() {
    if (this.personInsurance && this.personInsurance.coverageDays) {
      const coveragedaysForDate = this.helperService.getNumberFromString(this.personInsurance.coverageDays) - 1;
      this.coverageStopDate = this.addDays(this.plannedTravel.departureDate, coveragedaysForDate);
    }
  }

  private setValuesIfcoveragePeriodIsLongerThanTravelPeriod() {
    const durationDaysNumber = this.helperService.getNumberFromString(this.plannedTravel.durationDays);
    const coverageDaysNumber = this.helperService.getNumberFromString(this.personInsurance.coverageDays);
    if (durationDaysNumber < coverageDaysNumber) {
      this.shortPeriodInDays = durationDaysNumber;
      this.longPeriodInDays = this.helperService.getNumberFromString(coverageDaysNumber);
      this.coverageStopIconPercent = '95.5%';
      this.travelLinePercentNumber = (((this.shortPeriodInDays / this.longPeriodInDays) * 96));
      this.travelLinePercent = this.travelLinePercentNumber + '%';
    }
  }

  private setValuesIfcoveragePeriodShorterOrEqualToTravelPeriod() {
    const durationDaysNumber = this.helperService.getNumberFromString(this.plannedTravel.durationDays);
    const coverageDaysNumber = this.helperService.getNumberFromString(this.personInsurance.coverageDays);
    if (durationDaysNumber >= coverageDaysNumber) {
      this.travelLinePercentNumber = 21;
      this.shortPeriodInDays = coverageDaysNumber;
      this.longPeriodInDays = durationDaysNumber;
      this.coverageStopIconPercent = (Math.round((this.shortPeriodInDays / this.longPeriodInDays) * 96)) + '%';
      this.travelLinePercent = '96%';
    }
  }

  private setColorOfSoverageStopIconIfCoverageEndsIn3OrLessDays() {
    const coveredDaysNumber = this.helperService.getNumberFromString(this.personInsurance.coverageDays);
    const daysTillCoverageEnds = (coveredDaysNumber - this.daysSinceTravelStartToToday);
    if (daysTillCoverageEnds < 2) {
      this.coverageStopIconColor = 'RED';
    }
    if (daysTillCoverageEnds === 2 || daysTillCoverageEnds === 3) {
      this.coverageStopIconColor = 'YELLOW';
    }
  }

  private setTodayPercentIfTravelLinePercentIsLowerThan20Percent(travelDays: number) {
    if (travelDays === 1) {
      this.todayPercent = '11.7%';
    } else {
      this.todayPercent = (((this.daysSinceTravelStartToToday / travelDays) * 21.5)) + '%';
    }
  }

  private setIncidentPercentIfTravelLinePercentIsLowerThan20Percent(travelDays: number) {
    if (travelDays === 1) {
      this.incidentLinePercent = '10%';
      this.incidentIconPercent = '11%';
    } else {
      let incidentLinePercentNumber = ((this.daysSinceTravelStartToIncident / travelDays) * 21.5) - 1.9;
      let incidentIconPercentNumber = (((this.daysSinceTravelStartToIncident / travelDays) * 21.5) - 0.7);
      if (incidentLinePercentNumber < 0) {
        incidentLinePercentNumber = 1;
        incidentIconPercentNumber = 1;
      }
      this.incidentLinePercent = incidentLinePercentNumber + '%';
      this.incidentIconPercent = incidentIconPercentNumber + '%';
    }
  }


}
