
import { StakeholderService } from 'src/app/core/services/stakeholder.service';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CaseService } from '@secca/core/services/case.service';
import { TranslateService } from '@ngx-translate/core';
import { ServiceTypeEnum, ServiceTypeEnumIcon, StakeholderTypeEnum, StatusTypes } from '@secca/shared/models/enums';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import { DropdownWitIconDictionary } from '@secca/shared/models/dropdownWithIconDictionary';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { SubStakeholdersConfig } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholdersConfig';
import * as _ from 'lodash';
import { ServiceOrderType } from '@secca/shared/models/service-order-type';
import { ICCStakeholder } from '@secca/case/components/case-plans/case-plans/add-service-order/models/icc-stakeholder';
import { ICCStakeholders } from '@secca/case/components/case-plans/case-plans/add-service-order/models/icc-stakeholders';
import { ServiceOrder } from '@secca/shared/models/service-order/service-order';
import { ServiceItemType } from '@secca/shared/models/service-item-type';
import { RefundItem } from '@secca/shared/models/refund-Item';
import { ServiceOrderDropDownItem } from '@secca/shared/models/service-order-drop-down-item';
import { MedicalAssessment } from '@secca/shared/models/medicalAssessment';
import { cloneDeep } from 'lodash-es';


@Component({
  selector: 'app-sub-icc-stakeholders',
  templateUrl: './sub-icc-stakeholders.component.html',
  styleUrls: ['./sub-icc-stakeholders.component.scss'],
})
@AutoUnsubscribe
export class SubIccStakeholdersComponent implements OnInit, OnChanges {
  @Input() caseId: string;
  @Input() stakeholders: CaseStakeholder[];
  @Input() incidentLocation: string;
  @Input() disabled: boolean;
  @Input() preselectEndUser = false;
  @Input() serviceOrderType: ServiceOrderType;
  @Input() flightStakeholders: ICCStakeholders;
  @Input() serviceOrdersList: ServiceOrder[];
  @Input() iccServiceOrders: ServiceOrder[];
  @Input() iccServiceOrderStatus: StatusTypes;
  @Input() showPassengerConfirmed: boolean;
  @Input() caseNumber: string;
  @Input() showDeleteButton: boolean = true;
  @Input() savedFlightStakeholders: ICCStakeholders;

  stakeholdersDropdown: DropdownWitIconDictionary[];
  serviceOrdersDropdown: ServiceOrderDropDownItem[];
  fullServiceOrdersDropdown: ServiceOrderDropDownItem[];
  classDropdown: DropdownDictionary[];
  classDropdownSeeMedifOnly: DropdownDictionary[];
  inclDOBDropdown: DropdownDictionary[];
  inclPassportDropdown: DropdownDictionary[];
  caseStakeholders: CaseStakeholder[];
  selectableServiceItemList: ServiceItemType[];
  allServiceItemList: ServiceItemType[];
  endUser: string;
  endUserCaseICCStakeholders: ICCStakeholder[];
  serviceOrdersCancelled: boolean;

  constructor(private stakeholderService: StakeholderService, private translate: TranslateService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.initServiceOrdersDropdown(this.serviceOrdersList, this.iccServiceOrderStatus);
    this.endUserCaseICCStakeholders = this.flightStakeholders.byCase(this.caseId);
  }

  ngOnInit() {
    this.serviceOrdersCancelled = this.endUserCaseICCStakeholders.map(stakeholder => stakeholder.serviceOrderStatus).indexOf(StatusTypes.CANCELLED) !== -1;
    this.initICCStakeholdersDropdown();
    this.initServiceOrdersDropdown(this.serviceOrdersList, this.iccServiceOrderStatus);
  }

  initICCStakeholdersDropdown() {
    if ( this.stakeholders ) {
      const tempStakeholders = _.clone(this.stakeholders);
      this.caseStakeholders = tempStakeholders;
      this.stakeholdersDropdown = [];
      tempStakeholders.forEach(stakeholder => {
        this.stakeholdersDropdown.push({
          id: stakeholder.id,
          name: stakeholder.person ? `${stakeholder.person.firstName} ${stakeholder.person.surname}` : `${stakeholder.company.name}`,
          iconPath: this.stakeholderService.getStakeholderIconFullPath(stakeholder.stakeholderType),
          hoverText: null
        });
        if(stakeholder.stakeholderType === StakeholderTypeEnum.endUser) {
          this.endUser = stakeholder.person.firstName + ' ' + stakeholder.person.surname + ', ' + stakeholder.person.gender + stakeholder.person.age;
        }
      });
    }
  }

  get StatusTypes() {
    return StatusTypes;
  }

  passengerConfirmedClicked(status, id) {
    this.endUserCaseICCStakeholders.find(stakeholder => stakeholder.stakeholderId === id).passengerConfirmed = status;
  }

  initServiceOrdersDropdown(serviceOrders: ServiceOrder[], status: StatusTypes) {
    this.serviceOrdersDropdown = [];
    this.fullServiceOrdersDropdown = [];
    if (serviceOrders) {
      for (const serviceOrder of serviceOrders) {
        const serviceOrderType = serviceOrder.type;
        const disableStatus  = status == StatusTypes.EXPECTED ? [StatusTypes.COMMITTED, StatusTypes.NOT_COVERED,StatusTypes.CANCELLED].includes(serviceOrder.status) :
        [StatusTypes.NOT_COVERED,StatusTypes.CANCELLED].includes(serviceOrder.status);
        const serviceTypeEnumIcon = ServiceTypeEnumIcon.getIcon(serviceOrderType);
        if (serviceOrderType && !disableStatus) {
          this.serviceOrdersDropdown.push(
            new ServiceOrderDropDownItem({
              id: Number(serviceOrder.id),
              serviceOrderId: serviceOrder.serviceOrderId,
              iconFileName: 'assets/icons/' + serviceTypeEnumIcon,
              serviceOrderType,
              providerName: '',
              disabled: false,
            })
          );
        }

        this.fullServiceOrdersDropdown.push(
          new ServiceOrderDropDownItem({
            id: Number(serviceOrder.id),
            serviceOrderId: serviceOrder.serviceOrderId,
            iconFileName: 'assets/icons/' + serviceTypeEnumIcon,
            serviceOrderType,
            providerName: '',
            disabled: false,
          })
        );

      }
  }}

  hasEnduserBeenSavedOnServiceorder(iccStakeholder: ICCStakeholder): boolean {
    return this.savedFlightStakeholders.stakeholders.some(a => a.stakeholderId === iccStakeholder.stakeholderId)
  }

  addStakeholder() {
    this.flightStakeholders.push(new ICCStakeholder({caseId: this.caseId, serviceOrderStatus: null}));
    this.endUserCaseICCStakeholders = this.flightStakeholders.byCase(this.caseId);
  }

  updateStakeHolder(stakeHolder) {
    if (this.flightStakeholders.allreadyChosen(this.caseId, stakeHolder.stakeholderId)) {
      this.deleteStakeholder(stakeHolder, true);
    }
  }

  deleteStakeholder(stakeholder, isEnduser: boolean) {
    if(this.endUserCaseICCStakeholders.length  > 1 && isEnduser ) {
      this.flightStakeholders.delete(stakeholder);
      this.endUserCaseICCStakeholders = this.flightStakeholders.byCase(this.caseId);
      if(this.savedFlightStakeholders) {
        this.savedFlightStakeholders.stakeholders =  cloneDeep(this.flightStakeholders.byCase(this.caseId));
      }
    }
  }
}
