import { Component, Input } from '@angular/core';
import { CauseItemType } from '@secca/shared/models/cause-item-type';
import { SimpleCustomerProfile } from '@secca/shared/models/simpleCustomerProfile';
import { PurposeOfTravelItemType } from '@secca/shared/models/purpose-of-travel-item-type';
import { ServiceItemType } from '@secca/shared/models/service-item-type';
import { IncomingCasesTaskViewModel } from '../../../models/incoming-cases-task-view-model';
import { Diagnosis } from '@secca/shared/models/dignosis';

@Component({
  selector: 'app-task-web-luggage-panel',
  templateUrl: './task-web-luggage-panel.component.html',
  styleUrls: ['./task-web-luggage-panel.component.scss'],
})
export class TaskWebLuggagePanelComponent {
  @Input() taskDataViewModel: IncomingCasesTaskViewModel;

  @Input() serviceItemList: ServiceItemType[];
  @Input() purposeOfTravelItemList: PurposeOfTravelItemType[];
  @Input() causes: Map<string, CauseItemType[]>;
  @Input() diagnoses: Diagnosis[];
  @Input() customerProfiles: SimpleCustomerProfile[];

  ignoredProperties = [];

  constructor() {}
}
