<div class="recovery-row" [appDisableInput]="caseLockHelperService.isCaseBasicLockTaken || caseStateService.isCaseClosed">
    <div class="caselock-no-events">
        <div>
            <table class="primary-table">
                <tr class="tr-recovery">
                  <td  
                      [ngClass]="showDeleteButton() ? 'delete-button' : 'no-delete-button'" 
                      (click)="deleteItem(recoveryItem.supplierInvoiceNumber)" 
                      [attr.rowspan]="getHeightOfDeletebutton()">
                  </td>
                  <td class="td-invoice-no">
                    <app-drop-down *ngIf="recoveryItem?.isInvoiceItemGroupStart"
                      [items]="invoiceNumbers"
                      (selectedItemIdChange)="onInvoiceNumberChange($event)"
                      [(selectedItemId)]="recoveryItem.supplierInvoiceNumber"
                      [disabled]="isRecoveryDisabled() || isinvoiceNumberDropDownDisabled()"
                      [recommended]="!recoveryItem.supplierInvoiceNumber"
                    ></app-drop-down>
                  </td>
                  <td class="td-remittance-advice">
                    <app-drop-down *ngIf="recoveryItem?.isInvoiceItemGroupStart"
                    [items]="remittanceAdvices"
                    (selectedItemIdChange)="onRemittanceAdviceChange($event)"
                    [(selectedItemId)]="recoveryItem.remittanceAdviceDocumentId"
                    [disabled]="isRecoveryDisabled() || isinvoiceNumberDropDownDisabled() || !recoveryItem.supplierInvoiceNumber"
                    [recommended]="!recoveryItem.remittanceAdviceDocumentId"
                    ></app-drop-down>
                  </td>
                  <td class="td-service-order">
                      <input class="input-height"
                        [value]="getServiceOrderName(recoveryItem?.serviceOrderId)" 
                        [ngClass]="{ 'recovery-item-included-background': recoveryItem.isInvoiceItemIncluded }"
                        [disabled]="true"
                      />
                  </td>
                  <td class="td-item">
                        <input class="input-height"
                          [(ngModel)]="recoveryItem.serviceItemName"
                          [ngClass]="{ 'recovery-item-included-background': recoveryItem.isInvoiceItemIncluded }"
                          [disabled]="true"
                        />
                        <div class="warning">
                          <div triggers="hover" placement="right" [ngbPopover]="'case-economy-recovery-item-not-found-warning' | translate">
                            <img *ngIf="recoveryItem.isNotFoundWarning" src="/assets/icons/Warning.svg"/>
                          </div>
                        </div>
                  </td>
                  <td class="td-supplier-paid-amount" placement="bottom" >
                      <div class="input-with-currency">
                        <div class="input-with-currency-currency">{{ recoveryItem.supplierLocalCurrency }}</div>
                        <input
                          class="input-height"
                          [(ngModel)]="recoveryItem.supplierPaidAmount"
                          [ngClass]="{ 'recovery-item-included-background': recoveryItem.isInvoiceItemIncluded }"
                          currencyMask
                          [options]="currencyMaskOptions"
                          (focus)="focusElement($event)"
                          [disabled]="true"
                          />
                      </div>
                  </td>
                  <td class="td-applied-amount" placement="bottom" >
                        <div class="input-with-currency">
                          <div class="input-with-currency-currency">{{ recoveryItem.recoveryCurrency }}</div>
                          <input
                            class="input-height"
                            [(ngModel)]="recoveryItem.appliedAmount"
                            [ngClass]="{ 'recovery-item-included-background': recoveryItem.isInvoiceItemIncluded }"
                            currencyMask
                            [options]="currencyMaskOptions"
                            (focus)="focusElement($event)"
                            [disabled]="true"
                            />
                        </div>
                    </td>
                    <td class="td-recovered-amount">
                        <div class="input-with-currency">
                          <div class="input-with-currency-currency">{{ recoveryItem.recoveryCurrency }}</div>
                            <input
                            class="input-height"
                            [ngModel]="recoveryItem.recoveredAmount"
                            [ngClass]="{ 'recovery-item-included-background': recoveryItem.isInvoiceItemIncluded }"
                            currencyMask
                            [options]="currencyMaskOptions"
                            (focus)="focusElement($event)"
                            [disabled]="true"
                            />
                        </div>
                    </td>
                    <td>
                      <div class="include-checkbox custom-checkbox" *ngIf="!!recoveryItem.supplierInvoiceNumber">
                        <label class="checkbox">
                          <input type="checkbox" [ngModel]="recoveryItem.isInvoiceItemIncluded" (ngModelChange)="includeInvoiceItemClick($event)" [disabled]="isRecoveryDisabled()" />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>
