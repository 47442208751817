<div class="historyContainer" [class.zoomed]="settingsService.isZoomed()">
  <div class="d-flex pt-4" *ngFor="let item of thisMedicalHistory; index as i">
    <div class="userInfo me-1">
      <div class="float-start text-end w-75">
        <div class="date"> {{ item.createdOn | momentFormat: 'D MMM YYYY HH:mm': 'local' }}</div>
        <div *ngIf="item.medicalHistoryType !== MedicalHistoryType.medicalReportSupplierPortal; then userInfoName else stakeholderName"></div>
        <ng-template #userInfoName>{{ item.userInfo.userFullName }}</ng-template>
        <ng-template #stakeholderName>{{ getCaseStakeholderName(item) }}</ng-template>
      </div>
      <div class="float-end w-25 text-center">
        <img [src]="getUserImage(item)" class="personPhoto"/>
      </div>
    </div>
    <div class="history ms-1">
      <div *ngIf="item.medicalHistoryType === MedicalHistoryType.regularMedicalAssessment" class="card me-1">
        <div class="card-header" [ngClass]="headerClass(item.medicalAssessmentType)">
          #{{item.idx}} - {{ resolveHeaderStringFromType(item)}}
          <div class="dropdown" *ngIf="medicalDeleteAllowed && item.deletedOn === null">
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <div class="dropdown-content">
              <div [disablePopover]="!item.connectedServiceOrderIds" placement="left" triggers="hover" popoverClass="custom-popover" [ngbPopover]="popOverText(item.id, item.connectedServiceOrderIds)" >
                <p
                  [ngClass]="{ 'delete-button': !item.connectedServiceOrderIds , 'non-active-delete-button':  !!item.connectedServiceOrderIds }"
                  (click)='deleteAssessment(item, !item.connectedServiceOrderIds, i);'>
                    {{ "medical-history-delete" | translate }}
                </p>

              </div>
            </div>
          </div>
          <div class="deleted" *ngIf="item.deletedOn !== null" >  {{ "medical-history-deleted-due-to-GDPR" | translate }}</div>
        </div>
        <div *ngIf="item.deletedOn === null" class="card-body" role="button" (click)="openAssessment(item.id, i)">
          <div class="itemName">{{ "medical-history-based-on" | translate }}</div>
          <div class="itemValue">{{ "medical-history-" + item.assessmentBaseUpon | translate }} {{ item.sourceText }}</div>

          <div class="itemName"> {{ "medical-history-main-diagnosis" | translate }} </div>
          <div class="itemValue">
            <div class="itemValue" *ngFor="let icd10Item of thisMedicalHistory[i].diagnosisItems;">
              {{ icd10Item.idc10Code }} - {{ icd10Item.idc10Description }}
            </div>
          </div>

          <div class="itemName">{{ "medical-history-evaluation" | translate }}</div>
          <div class="itemValue">{{ item.note.status }}</div>

          <ng-container *ngIf="item.nextAssessmentDate">
            <div class="itemName">{{ "medical-history-next-assessment-date" | translate }}</div>
            <div class="itemValue">{{ item.nextAssessmentDate | date: 'd MMM y' }}</div>
          </ng-container>
        </div>
      </div>
      <div *ngIf="item.medicalHistoryType === MedicalHistoryType.medicalNote" class="card note w-75">
        <div class="card-header">
          #{{item.idx}} - {{ resolveNoteHeaderStringFromType(item.medicalNoteType)}}
          <div class="dropdown" *ngIf="(medicalDeleteAllowed || medicalPreDepartureDeleteAllowed) && item.deletedOn === null">
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <div class="dropdown-content">
              <div [disablePopover]="!item.connectedServiceOrderIds" placement="left" triggers="hover" popoverClass="custom-popover" [ngbPopover]="popOverText(item.id, item.connectedServiceOrderIds)" >
                <p Class="delete-button" (click)='deleteNote(item, i);'>
                    {{ "medical-history-delete" | translate }}
                </p>
              </div>
            </div>
          </div>
          <div class="deleted" *ngIf="item.deletedOn !== null" >  {{ "medical-history-deleted-due-to-GDPR" | translate }}</div>
        </div>
        <div class="card-body" *ngIf="item.deletedOn === null">
          <span *ngFor="let descr of getMedicalNoteTextSplited(item.medicalNoteText)">
            <div> {{ descr }} </div>
          </span>
        </div>
      </div>
      <div *ngIf="item.medicalHistoryType === MedicalHistoryType.medicalReportSupplierPortal" class="card me-1">
        <div class="card-header medical-report-supplier-portal">
          #{{item.idx}} - {{ 'medical-history-report-supplier-portal-title' | translate}}
          <div class="dropdown" *ngIf="medicalDeleteAllowed && item.deletedOn === null">
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <div class="dropdown-content">
              <div [disablePopover]="!item.connectedServiceOrderIds" placement="left" triggers="hover" popoverClass="custom-popover" [ngbPopover]="popOverText(item.id, item.connectedServiceOrderIds)" >
                <p
                  Class="delete-button"
                  (click)='deleteMedicalReport(item, i);'>
                    {{ "medical-history-delete" | translate }}
                </p>
              </div>
            </div>
          </div>
          <div class="deleted" *ngIf="item.deletedOn !== null" >  {{ "medical-history-deleted-due-to-GDPR" | translate }}</div>
        </div>
        <div class="card-body" *ngIf="item.deletedOn === null" (click)="openRawView(item)">
          <div class="itemName">{{ "medical-history-diagnosis-and-symptoms" | translate }}</div>
          <div class="itemValue">{{ item.reportSupplierPortal.diagnosisAndSymptoms }}</div>

          <div class="itemName"> {{ "medical-history-main-diagnosis" | translate }} </div>
          <div class="itemValue">
            <div class="itemValue" *ngFor="let icd10Item of thisMedicalHistory[i].diagnosisItems;">
              {{ icd10Item.idc10Code }} - {{ icd10Item.idc10Description }}
            </div>
          </div>

          <div class="itemName">{{ "medical-history-treatment" | translate }}</div>
          <div class="itemValue">{{ item.reportSupplierPortal.treatment }}</div>

          <div class="itemName">{{ "medical-history-submitted-in-supplier-portal" | translate }}</div>
          <div class="itemValue">{{ item.reportSupplierPortal.submitted | momentFormat: 'D MMM YYYY HH:mm': 'local' }}</div>
        </div>
      </div>
      <div *ngIf="item.medicalHistoryType === MedicalHistoryType.medicalPreDeparture" class="card me-1">
        <div class="card-header" [ngClass]="headerClass(item.medicalAssessmentType)">
          #{{item.idx}} - {{ resolveHeaderStringFromType(item)}}
          <div class="dropdown" *ngIf="medicalPreDepartureDeleteAllowed && item.deletedOn === null">
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <div class="dropdown-content">
              <!-- UNDERSØG HOS LOTTE, OM MEDICAL MÅ SLETTES UDEN TJEK PÅ SERVICE ORDER -->
              <div [disablePopover]="!item.connectedServiceOrderIds" placement="left" triggers="hover" popoverClass="custom-popover" [ngbPopover]="popOverText(item.id, item.connectedServiceOrderIds)" >
                <p
                  [ngClass]="{ 'delete-button': !item.connectedServiceOrderIds , 'non-active-delete-button':  !!item.connectedServiceOrderIds }"
                  (click)='deletePreAssessment(item, !item.connectedServiceOrderIds, i);'>
                    {{ "medical-history-delete" | translate }}
                </p>
              </div>
            </div>
          </div>
          <div class="deleted" *ngIf="item.deletedOn !== null" >  {{ "medical-history-deleted-due-to-GDPR" | translate }}</div>
        </div>
        <div *ngIf="item.deletedOn === null" class="card-body" role="button" (click)="openPreAssessment(item.id, i)">
          <div class="itemName">{{ "medical-history-based-on" | translate }}</div>
          <div class="itemValue">{{ "medical-history-" + item.assessmentBaseUpon | translate }} {{ item.sourceText }}</div>
          <div class="itemName"> {{ "medical-history-main-diagnosis" | translate }} </div>
          <div class="itemValue">
            <div class="itemValue" *ngFor="let icd10Item of thisMedicalHistory[i].diagnosisItems;">
              {{ icd10Item.idc10Code }} - {{ icd10Item.idc10Description }}
            </div>
          </div>
          <ng-container *ngIf="item.evaluation">
            <div class="itemName">{{ "medical-history-evaluation" | translate }}</div>
            <div class="itemValue">{{ item.evaluation }}</div>
          </ng-container>
          <ng-container *ngIf="item.descriptionToEndUser">
            <div class="itemName">{{ "medical-history-descriptionToEndUser" | translate }}</div>
            <div class="itemValue">{{ item.descriptionToEndUser }}</div>
          </ng-container>
          <ng-container *ngIf="item.evaluationDecision">
            <div class="itemName">{{ "medical-history-evaluationDecision" | translate }}</div>
            <div class="itemValue">{{ item.evaluationDecision }}</div>
          </ng-container>
          <ng-container *ngIf="item.decision">
            <div class="itemName">{{ "medical-history-decision" | translate }}</div>
            <div class="itemValue">{{ "medical-pre-assessment-decison-" + item.decision | translate }}</div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
