<app-drop-down-customer-profile
  [name]="headline"
  [validationType]="validationTypeEnum"
  [(selectedItemId)]="groupProfile"
  (selectedItemIdChange)="updateInsuranceCustomerProfile()"
  (open)="onOpen()"
  [items]="customerProfiles"
  [useAsSearchParameter]="useAsSearchParameter"
  [disabled]="(consentHappened || serviceOrdersExist || lookupPolicy || hasMedicalPreAssessmentDialogOpen) && !!_customerProfileId">
</app-drop-down-customer-profile>
