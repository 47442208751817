import { Component, Inject, ViewChild } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { DialogBoundryService } from "@secca/core/services/dialog-boundry.service";
import { DialogHelperUtilService } from "@secca/core/services/dialog-helper-util.service";
import { DialogMinimizableDirective } from "@secca/shared/directives/dialog-minimizable.directive";
import { MinimizableDialogType } from "@secca/shared/enums/minimizable-dialog-type-enum";
import { CaseSummaryEmailFaxAdditionalInfo, IAdditionalInfo } from "@secca/shared/interfaces/additional-info";
import { IMinimizable } from "@secca/shared/interfaces/minimizable";
import { CaseSummaryNoteDialogData } from "./case-summary-note-dialog-data.component";
import { CaseSummaryNoteModalComponent } from "../case-summary-note-modal/case-summary-note-modal.component";
import { IKeyboardEnabled } from "@secca/shared/interfaces/keyboard-enabled";

@Component({
    selector: 'app-case-summary-note-dialog',
    templateUrl: './case-summary-note-dialog.component.html',
    styleUrls: ['./case-summary-note-dialog.component.scss'],
  })
  export class CaseSummaryNoteDialogComponent implements IAdditionalInfo, IMinimizable, IKeyboardEnabled {
    @ViewChild(DialogMinimizableDirective) minimizedDirective: DialogMinimizableDirective;
    @ViewChild(CaseSummaryNoteModalComponent) wrappedInstance: CaseSummaryNoteModalComponent;

    data: CaseSummaryNoteDialogData;

    constructor(private dialogRef: MatDialogRef<CaseSummaryNoteDialogComponent>,
                @Inject(MAT_DIALOG_DATA) data: CaseSummaryNoteDialogData,
                private dialogHelperUtilService: DialogHelperUtilService,
                public dialogBoundryService: DialogBoundryService) {
      this.data = data;
    }

    close() {
      this.dialogHelperUtilService.close(this.dialogRef, this.getAdditionalInfo()?.caseId);
    }

    keyboardClose(): void {
      this.wrappedInstance.close();
    }

    minimize() {
      this.minimizedDirective.minimize();
    }

    getMinimizeType(): MinimizableDialogType {
      return this.minimizedDirective.appDialogMinimizable;
    }

    getAdditionalInfo(): CaseSummaryEmailFaxAdditionalInfo {

      return {
        caseId: +this.data.entry.caseId,
        hoverTitle: this.data.caseNumber ? this.data.caseNumber : '',
        hoverText: 'View note documents',
        caseHistoryType: this.data?.entry?.caseHistoryType
      };
    }

    get MinimizableDialogType() {
      return MinimizableDialogType;
    }
}
