<div class="case-basics-card case-basic-info-lniked-cases" style="min-height: 442px;" >
    <div class="row">
        <div class="col button-box">
            <button [disabled]="!isCaseNumberAssigned || !areLinkedCasesLoaded" (click)="openModal()" [class.primary-button-inactive]="!isCaseNumberAssigned || !areLinkedCasesLoaded"  class="primary-button m-2">{{ 'case-basics-linked-cases-add-link-to-case' | translate }}</button>
        </div>
        <div class="col">
            <div class="headline-container">
                <div class="d-flex headline">
                    <div class="casenumber casenumber-headline">{{ 'case-basics-linked-cases-case-number' | translate }}</div>
                    <div class="name" >{{ 'case-basics-linked-cases-name' | translate }}</div>
                    <div class="age" >{{ 'case-basics-linked-cases-age' | translate }}</div>
                    <div class="primary">{{ 'case-basics-linked-cases-primary-case-handler' | translate }}</div>
                    <div class="status">{{ 'case-basics-linked-cases-status' | translate }}</div>
                    <div class="delete"></div>
                </div>
            </div>
            <div class="list-container">
                <div *ngIf="!areLinkedCasesLoaded || !areCoordinationCasesLoaded">
                    <img src="/assets/icons/waitForSearchresult.gif" class="spinner"  />
                </div>
                <div *ngIf="areLinkedCasesLoaded && areCoordinationCasesLoaded">
                    <!-- // TODO ICC SCW -->
                    <ng-container *ngFor="let coordinationCase of coordinationCasesList; index as i">
                        <div class="d-flex line"  *ngIf="coordinationCase.caseNumber !== caseNumber" >
                            <div class="casenumber" (click)="redirectToCase(coordinationCase.caseId)">
                                {{ coordinationCase.caseNumber }}
                            </div>
                            <div class="coordinationCase">
                                {{ coordinationCase.name }}
                            </div>
                            <div class="age">

                            </div>
                            <div class="primary">
                                {{ coordinationCase.primary }}
                            </div>
                            <div class="status">
                                <img src="/assets/icons/folder-outline.svg" *ngIf="coordinationCase.status === HandlingAreaStatusEnum.CLOSED"  />
                                <img src="/assets/icons/folder-open-outline.svg" *ngIf="coordinationCase.status === HandlingAreaStatusEnum.REOPENED"  />
                                <img src="/assets/icons/folder-more-open.svg" *ngIf="coordinationCase.status === HandlingAreaStatusEnum.OPEN"  />
                            </div>
                            <div class="delete"></div>
                        </div>
                    </ng-container>
                    <ng-container *ngFor="let linkedCase of linkedCasesList; index as i">
                        <div class="d-flex line"  *ngIf="linkedCase.caseNumber !== caseNumber" >
                            <div class="casenumber" (click)="redirectToCase(linkedCase.caseId)">
                                {{ linkedCase.caseNumber }}
                            </div>
                            <div class="name">
                                {{ linkedCase.name }}
                            </div>
                            <div class="age">
                                {{ linkedCase.age }}
                            </div>
                            <div class="primary">
                                {{ linkedCase.primary }}
                            </div>
                            <div class="status">
                                <img src="/assets/icons/folder-outline.svg" *ngIf="linkedCase.status === HandlingAreaStatusEnum.CLOSED"  />
                                <img src="/assets/icons/folder-open-outline.svg" *ngIf="linkedCase.status === HandlingAreaStatusEnum.REOPENED"  />
                                <img src="/assets/icons/folder-more-open.svg" *ngIf="linkedCase.status === HandlingAreaStatusEnum.OPEN"  />
                            </div>
                            <div class="delete">
                                <img src="/assets/icons/garbage.svg" height="16px" (click)="deleteLink(linkedCase.id)" />
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>