import {Component, Input, ViewChild} from '@angular/core';
import {Case} from '@secca/shared/models/case';
import {BalanceSheetEntryEnum, BalanceSheetEventEnum, PermissionEnum, RecoveryPaymentStatus} from '@secca/shared/models/enums';
import {SettingsService} from '@secca/core/services/settings.service';
import {PermissionService} from '@secca/core/services/permission.service';
import {RecoveryPayment} from '@secca/shared/models/recoveryPayment';
import {CaseDialogViewerService} from '@secca/case/case-dialog-viewer.service';
import {DialogStateService} from '@secca/core/state-services/dialog-state.service';
import {SortDirective} from '@secca/shared/directives/sort.directive';
import {RecoveryPaymentService} from '@secca/core/services/recovery/recovery-payment.service';
import DineroFactory from 'dinero.js';
import {timer} from 'rxjs';
import {
  CaseEconomyRecoveryPaymentDialogData
} from "@secca/case/components/case-economy/case-economy-recovery-payment-dialog/case-economy-recovery-payment-dialog-data.component";
import {RecoveryService} from "@secca/core/services/recovery.service";
import {DropdownDictionary} from "@secca/shared/models/dropdownDictionary";
import {DataService} from "@secca/core/services/data.service";


@Component({
  selector: 'app-case-economy-recovery-payment',
  templateUrl: './case-economy-recovery-payment.component.html',
  styleUrls: ['./case-economy-recovery-payment.component.scss']
})
export class CaseEconomyRecoveryPaymentComponent {
  @ViewChild(SortDirective) sortDirective;

  constructor(
    private dataService: DataService,
    public settingsService: SettingsService,
    private permissionService: PermissionService,
    private dialogViewerService: CaseDialogViewerService,
    private dialogStateService: DialogStateService,
    private recoveryPaymentService: RecoveryPaymentService,
    private recoveryService: RecoveryService) {
  }


  caseCurrency = 'DKK';
  expanded: boolean[];
  sortedOn: any = {};
  sortKey: string;
  totalAppliedAmount: DineroFactory.DineroObject = DineroFactory({ amount: 0, currency: 'DKK' }).toObject();
  totalRecoveredAmount: DineroFactory.DineroObject = DineroFactory({ amount: 0, currency: 'DKK' }).toObject();

  filteredRecoveryPaymentList: RecoveryPayment[] = [];
  serviceItemOptions: DropdownDictionary[] = [];
  recoveryOptions: DropdownDictionary[] = [];

  @Input()
  set case(newCase: Case) {
    if (newCase) {
      this.theCase = newCase;
      this.onRefresh();
    }
  }

  get case(): Case {
    return this.theCase;
  }

  private theCase: Case;


  onRefresh() {
    this.expanded = [];

    this.recoveryPaymentService.getServiceItemList().subscribe(serviceItemList => {
      this.serviceItemOptions = serviceItemList.filter(item => item.serviceItemType === 'RECOVERY').map(item => new DropdownDictionary(item.code, item.name));
    });

    this.recoveryService.getRecoveryByCaseId(+this.theCase.id).subscribe(recoveries => {
      this.recoveryOptions = recoveries.map(recovery => new DropdownDictionary(recovery.id, recovery.recoveryNumber));
    });

    this.reloadRecoveryPaymentTab();
  }


  openManageRecoveryPayment(recoveryPayment: RecoveryPayment) {
    if (this.permissionService.checkUserPermission(PermissionEnum.RECOVERY_READ)) {
      let data = new CaseEconomyRecoveryPaymentDialogData();
      data.payment = recoveryPayment;
      data.serviceItemOptions = this.serviceItemOptions;
      data.recoveryOptions = this.recoveryOptions;
      data.paymentUpdated = () => this.reloadRecoveryPaymentTab();
      const matDialogRef = this.dialogViewerService.openRecoveryPayment(data);
      if (matDialogRef) {
        setTimeout(() => {
          this.dialogStateService.sendToFront(matDialogRef.id);
        }, 0);
      }
    }
  }

  setSortKey(key: string) {
    this.sortKey = key;
  }

  protected readonly BalanceSheetEntryEnum = BalanceSheetEntryEnum;
  protected readonly String = String;

  expand(i: number) {
    this.expanded[i] = this.expanded[i] !== true;
  }

  getEventsToDisplay(): BalanceSheetEventEnum[] {
    return [BalanceSheetEventEnum.INITIAL, BalanceSheetEventEnum.AWAITING,
      BalanceSheetEventEnum.UPDATED, BalanceSheetEventEnum.ONGOING, BalanceSheetEventEnum.HANDLED, BalanceSheetEventEnum.CREDITOR_PAID];
  }

  getHiddenEvents() {
    return [];
  }

  private reloadRecoveryPaymentTab() {
    this.recoveryPaymentService.getRecoveryPaymentByCaseId(+this.theCase.id).subscribe(recoveryPaymentTab => {
      this.totalAppliedAmount = recoveryPaymentTab.totalAppliedAmount;
      this.totalRecoveredAmount = recoveryPaymentTab.totalRecoveredAmount;
      this.filteredRecoveryPaymentList = recoveryPaymentTab.caseRecoveryPayments;
      if (this.dataService.navigateRecoveryPaymentId) {
        const taskRecoveryId = this.dataService.navigateRecoveryPaymentId;
        const recoveryPaymentClicked = this.filteredRecoveryPaymentList.filter(entry => entry.id === taskRecoveryId)[0];
        this.dataService.navigateRecoveryPaymentId = null;
        if ( !!recoveryPaymentClicked ) {
          timer(300).subscribe(t => this.openManageRecoveryPayment(recoveryPaymentClicked));
        }
      }
    });
  }
}
