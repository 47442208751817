import { TaskFilterRequest } from "@secca/board/components/board-task/models/task-filter-request.model";

export class RecoveryPaymentTaskFilterRequest extends TaskFilterRequest {
  dateRange: moment.Moment[];
  statuses: string[];
  invoiceNumber: string;
  creditorId: string;
  caseNumber: string;

  public constructor(init?: Partial<RecoveryPaymentTaskFilterRequest>) {
    super();
    Object.assign(this, init);
  }
}
