<div class='chip-input-container' (click)='focusInput()'>

  <div *ngFor='let chip of chips' [ngClass]="{'chip-user-entered': chip.userEntered, 'chip': !chip.userEntered}">

    <div class='mx-1 headline' [ngbPopover]='chip.text' triggers='hover'
         (click)='removeChip(chip); $event.stopPropagation()'>
      <span class='chip-remove' (click)='removeChip(chip); $event.stopPropagation()'>
        <img ngSrc='/assets/icons/x-circle-white.svg' height='16' alt='Delete' class='pe-1' width='16' />
      </span>
      <span class='chip-remove'>
      {{ chip.text | slice:0:7 }} {{ chip.text.length > 7 ? '...' : '' }}
      </span>
    </div>
  </div>

  <div class='input-wrapper'>
    <input
      #chipInput
      (keydown)='handleKeydown($event)'
      class='form-control my-0 mx-0'
      type='text'
      [(ngModel)]='inputValue'
      (input)='filterList()'
      (focus)='showList = false'
      (keyup.enter)='addChip()'
      (blur)='hideList()'
      placeholder='Search...'
    />
    <ul *ngIf='showList' class='list-group'>
      <li class='list-group-item' *ngFor='let item of filteredList; let i = index' (click)='selectItem(item)'
          [ngClass]="{'focused-item-class': i === focusedItemIndex}">
        {{ item.text }} <span class='category-size'>({{ item.category | translate }}) </span>
      </li>
    </ul>
  </div>
</div>
