import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { ChannelType } from '@secca/shared/enums/channelType';
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import { PermissionService } from '@secca/core/services/permission.service';
import { TaskStateService } from '@secca/board/components/board-task/services/task-state.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogHelperUtilService } from '@secca/core/services/dialog-helper-util.service';
import { ServiceItemType } from '@secca/shared/models/service-item-type';
import { PurposeOfTravelItemType } from '@secca/shared/models/purpose-of-travel-item-type';
import { CauseItemType } from '@secca/shared/models/cause-item-type';
import { TaskWebPropertiesService } from '@secca/core/services/task-web-properties.service';
import { TaskViewModel } from '../../../models/task-view.model';
import { IncomingCasesTaskViewModel } from '../../../models/incoming-cases-task-view-model';
import { TaskService } from '@secca/core/services/task.service';
import { SimpleCustomerProfile } from '@secca/shared/models/simpleCustomerProfile';
import { CaseDocumentTagEnum, TaskStatus, TaskType } from '@secca/shared/models/enums';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { AttachmentDto } from '@secca/shared/models/attachment-dto';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { Diagnosis } from '@secca/shared/models/dignosis';
import { Channel } from '@secca/shared/models/channel';

@Component({
  selector: 'app-task-web-modal-main-panel',
  templateUrl: './task-web-modal-main-panel.component.html',
  styleUrls: ['./task-web-modal-main-panel.component.scss']
})
@AutoUnsubscribe
export class TaskWebModalMainPanelComponent implements OnDestroy {

  @Input() taskViewModel: TaskViewModel;
  @Input() set taskDataViewModel(taskDataViewModel: IncomingCasesTaskViewModel) {
    this._taskDataViewModel = taskDataViewModel;
    this.setTaskReporter();
    if ( taskDataViewModel ) {
      this.initAttachments();
      this.initMedicalAttachments();
    }
  }
  get taskDataViewModel(): IncomingCasesTaskViewModel { return this._taskDataViewModel; }

  @Input() hideSearchCreateButton = false;

  @Output() closed = new EventEmitter<number>();
  @Output() createCaseButtonPressed = new EventEmitter<void>();

  @Input() serviceItemList: ServiceItemType[];
  @Input() purposeOfTravelItemList: PurposeOfTravelItemType[];
  @Input() causes: Map<string, CauseItemType[]>;
  @Input() diagnoses: Diagnosis[];
  @Input() customerProfiles: SimpleCustomerProfile[];
  @Input() channels: Channel[];
  @Input()
  set companyStakeholders(companyStakeholders: CaseStakeholder[]) {
    this._companyStakeholders = companyStakeholders;
    this.setTaskReporter();
  }
  get companyStakeholders(): CaseStakeholder[] { return this._companyStakeholders; }
  @Input() isSwitchReporterOnCaseAllowed: boolean;
  @Input() switchReporter: boolean;
  @Input() createCaseOnly = false;

  internalSummary: string;
  assignedReporter: CaseStakeholder;
  private _taskDataViewModel: IncomingCasesTaskViewModel;
  private _companyStakeholders: CaseStakeholder[];
  updatedTaskStatus: TaskStatus;
  attachments: AttachmentDto[] = [];
  medicalAttachments: AttachmentDto[] = [];

  constructor(
    private taskStateService: TaskStateService,
    private modalService: NgbModal,
    private dialogHelperUtilService: DialogHelperUtilService,
    private taskService: TaskService,
    public taskWebPropertiesService: TaskWebPropertiesService,
    public caseLockHelperService: CaseLockHelperService,
    public permissionService: PermissionService,
    public caseStateService: CaseStateService
  ) {
  }

  ngOnDestroy(): void {
    this.taskStateService.modalTaskLoaded.next(null);
  }

  private setTaskReporter() {
    if (this.taskDataViewModel && this.companyStakeholders) {
      this.assignedReporter = this.companyStakeholders.find(stakeholder => Number.parseInt(stakeholder.id) === this.taskDataViewModel.assignedReporterId);
    }
  }

  save() {
    if (this.internalSummaryHasBeenUpdated) {
      this.taskService.getTask(this.taskDataViewModel.id).subscribe(task => {
        task.internalSummary = this.internalSummary;
        this.taskService.updateTask(task).subscribe(
          () => this.close()
        );
      });
    }
  }

  createCase(): void {
    this.createCaseButtonPressed.emit();
  }

  get internalSummaryHasBeenUpdated() {
    return this.taskDataViewModel && this.internalSummary !== this.taskDataViewModel.internalSummary;
  }

  get isDisabled(): boolean {
    return this.createCaseOnly || this.caseStateService.isCaseDisabled();
  }

  close() {
    if ( this.closed.observers ) {
      this.closed.emit();
    }
    if (this.modalService.hasOpenModals()) {
      this.modalService.dismissAll();
    }
    this.dialogHelperUtilService.closeModal();
  }

  isSupplierPortalChannel(): boolean {
    if (this.taskDataViewModel) {
      const channelType = ChannelType[this.taskDataViewModel.channelType];
      return (channelType === ChannelType.SUPPLIER_PORTAL || channelType === ChannelType.UNKNOWN_SUPPLIER);
    }
    return false;
  }

  isProcessGopRequest(): boolean {
    if (this.taskDataViewModel) {
      return this.taskDataViewModel.taskType === TaskType.processGopRequest;
    }
    return false;
  }

  isMpaRequest(): boolean {
    if (this.taskDataViewModel) {
      return this.taskDataViewModel?.channelType === ChannelType.MPA_TRAVEL;
    }
    return false;
  }

  isReportedByRequired(): boolean {
    return this.updatedTaskStatus === TaskStatus.done;
  }

  private initAttachments(): void {
    this.attachments = this.taskDataViewModel?.data?.treatment ?
                       this.taskDataViewModel?.attachments?.filter(a => a.documentTags && !a.documentTags.map(tagKey => CaseDocumentTagEnum[tagKey]).includes(CaseDocumentTagEnum.MEDICAL_INFO)) :
                       this.taskDataViewModel?.attachments;
  }

  private initMedicalAttachments(): void {
    this.medicalAttachments = this.taskDataViewModel?.attachments?.filter(a => a.documentTags && a.documentTags.map(tagKey => CaseDocumentTagEnum[tagKey]).includes(CaseDocumentTagEnum.MEDICAL_INFO));
  }
}
