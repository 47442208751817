import { TravelPurposeEnum, ValidationTypeEnum, MeansOfTransportationEnum, AccommodationEnum } from '@secca/shared/models/enums';
import { DateFormatter } from './../../../../../../shared/helpers/date-formatter';
import { PlannedTravel } from 'src/app/shared/models/planned-travel';
import { Component, Input, OnInit } from '@angular/core';
import { DictionaryService } from 'src/app/core/services/dictionary.service';
import { CaseService } from 'src/app/core/services/case.service';
import { DropdownDictionary } from './../../../../../../shared/models/dropdownDictionary';
import { UpdateTimeLineService } from 'src/app/core/services/updateTimeLine.service';
import { TranslateService } from '@ngx-translate/core';
import { MomentHelperService } from '@secca/core/services/moment-helper.service';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import * as moment from 'moment';
import { RangeSupport } from '@secca/shared/components/input-date-time/range-support';
import { PermissionService } from '@secca/core/services/permission.service';
import { PlannedTravelService } from '@secca/core/services/planned-travel.service';
import { InsuranceService } from '@secca/core/services/insurance.service';
import { ProductChangeService } from '@secca/case/components/case-basics/case-basics-insurance/product-change-service';
import { PersonInsurance } from '@secca/shared/models/person-insurance';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { Location } from '@secca/shared/models/location';
import { SwitchMultiCasePipe } from '@secca/shared/helpers/switch-multi-case.pipe';

@Component({
  selector: 'app-case-basics-planned-travel-details',
  templateUrl: './case-basics-planned-travel-details.component.html',
  styleUrls: ['./case-basics-planned-travel-details.component.scss'],
})
export class CaseBasicsPlannedTravelDetailsComponent implements OnInit {
  readonly MAX_NUMBER_OF_TRAVEL_DESTINATIONS = 8;

  accommodationList: DropdownDictionary[];
  meansOfTransportationList: DropdownDictionary[];
  purposeOfTravelList: DropdownDictionary[];
  selectedPurposesOfTravel: DropdownDictionary[] = new Array<DropdownDictionary>();
  savedValidDepartureDate: moment.Moment;
  savedValidArrivalDate: moment.Moment;
  rangeSupport: RangeSupport;
  rangeExpatriationSupport: RangeSupport;
  travelPurposeEnum: TravelPurposeEnum;
  _personInsurance: PersonInsurance;

  @Input()
  plannedTravelSubPlans: PlannedTravel[];

  @Input() set plannedTravel(value: PlannedTravel) {
    this.internalPlannedTravel = value;
    this.selectedPurposesOfTravel = this.internalPlannedTravel.purposesOfTravel
      ? this.plannedTravel.purposesOfTravel.map(item => this.adaptToTravelPurposeDictionary(item))
      : [];
  }
  get plannedTravel(): PlannedTravel {
    return this.internalPlannedTravel;
  }
  private internalPlannedTravel: PlannedTravel;

  @Input() set customerProduct(value: number) {
    this._customerProduct = value;
    if(value) {
      this.getCustomerProduct(value);
    }
  }
  get customerProduct(): number {
    return this._customerProduct;
  }
  private _customerProduct: number;
  insuranceProductSubscription: Subscription;

  constructor(
    private dictionaryService: DictionaryService,
    private caseService: CaseService,
    private updateTimeLineService: UpdateTimeLineService,
    private translateService: TranslateService,
    private plannedTravelService: PlannedTravelService,
    private insuranceService: InsuranceService,
    public caseLockHelperService: CaseLockHelperService,
    public permissionService: PermissionService,
    private productChangeService: ProductChangeService,
    public caseStateService: CaseStateService

  ) {}

  get ValidationTypeEnum() {
    return ValidationTypeEnum;
  }

  ngOnInit() {
    this.meansOfTransportationList = this.getDropdownListFromEnum(MeansOfTransportationEnum, 'planned-travel-means-of-transportation-');
    this.accommodationList = this.getDropdownListFromEnum(AccommodationEnum, 'planned-travel-accommodation-');
    this.purposeOfTravelList = this.getPurposeOfTravelList();

    this.rangeSupport = new RangeSupport(
      this.plannedTravel.departureDate,
      this.plannedTravel.arrivalDate,
      this,
      this.updateTravelPeriodDepartureDate,
      this.updateTravelPeriodArrivalDate,
      null
    );

    this.rangeExpatriationSupport = new RangeSupport(
      this.plannedTravel.expatriationDepartureDate,
      this.plannedTravel.expatriationArrivalDate,
      this,
      this.updateExpatriationPeriodDepartureDate,
      this.updateExpatriationPeriodArrivalDate,
      null
    );

    this.insuranceProductSubscription = this.insuranceService.getCustomerProductChanges(this.plannedTravel.seccaCaseId).subscribe(
      productId => {
        if(!!productId) {
          this.getCustomerProduct(productId);
        }
      }
    );
  }

  private getCustomerProduct (productId: number) {
    this.caseService.getCustomerProduct(productId).subscribe(product => {
      this.travelPurposeEnum = TravelPurposeEnum[product.primaryPurposeOfTravel];
    });
  }

  getTextForPlannedTravelDepartureName() {
    if(this.travelPurposeEnum !== TravelPurposeEnum.EXPATRIATION) {
      return 'case-basics-planned-travel-arrival-home-country';
    } else {
      return 'case-basics-planned-travel-arrival-home-country-expatriation';
    }
  }

  private adaptToTravelPurposeDictionary(purposeOfTravel: string): DropdownDictionary {
    return new DropdownDictionary(purposeOfTravel, TravelPurposeEnum[purposeOfTravel]);
  }

  get getTravelPurposeEnum() {
    return TravelPurposeEnum;
  }

  private getDropdownListFromEnum(enumItem: any, prefix: string) {
    return Object.keys(enumItem).map(
      key => new DropdownDictionary(enumItem[key], this.translateService.instant(prefix + key.toString().toLowerCase().replace(/_/g, '-')))
    );
  }

  getDurationDays(): string {
    if (
      this.plannedTravel.departureAndArrival == null ||
      this.plannedTravel.departureAndArrival[0] == null ||
      this.plannedTravel.departureAndArrival[1] == null
    ) {
      this.plannedTravel.durationDays = '';
      return '';
    }
    const rangeDays: number = this.getDurationDaysInNumber();
    if (rangeDays < 1) {
      return DateFormatter.formatToNumberOfDays(1);
    }
    return DateFormatter.formatToNumberOfDays(rangeDays);
  }

  getDurationDaysInNumber() {
    if (!!this.plannedTravel.departureAndArrival[0] && !!this.plannedTravel.departureAndArrival[1]) {
      return MomentHelperService.getDaysRangeBothIncl(this.plannedTravel.departureAndArrival[0], this.plannedTravel.departureAndArrival[1]);
    } else {
      return null;
    }
  }

  getDurationWeeks(): string {
    if (
      this.plannedTravel.departureAndArrival == null ||
      this.plannedTravel.departureAndArrival[0] == null ||
      this.plannedTravel.departureAndArrival[1] == null
    ) {
      this.plannedTravel.durationWeeks = '';
      return '';
    }
    const rangeDays: number = this.getDurationWeeksInDays();
    if (rangeDays < 1) {
      return '0 week(s) +1 day';
    }
    let rangeWeeks = Math.floor(rangeDays / 7);
    if (isNaN(rangeWeeks)) {
      rangeWeeks = 0;
    }
    const restDays = rangeDays - rangeWeeks * 7;

    return String(rangeWeeks) + ' week(s) + ' + restDays + ' day(s)';
  }

  getDurationWeeksInDays() {
    return MomentHelperService.getDaysRangeBothIncl(this.plannedTravel.departureAndArrival[0], this.plannedTravel.departureAndArrival[1]);
  }

  getPurposeOfTravelList(): DropdownDictionary[] {
    return Object.keys(TravelPurposeEnum).map(item => new DropdownDictionary(item, TravelPurposeEnum[item]));
  }

  concString(d1: Date, d2: Date) {
    let str = '';
    if (d1 === null || d2 === null) {
    } else {
      str = str.concat(d1.toString(), ' ~ ', d2.toString());
    }
    return str;
  }

  saveModel(plannedTravel: PlannedTravel) {
    if (plannedTravel.departureAndArrival == null) {
      plannedTravel.departureDate = null;
      plannedTravel.arrivalDate = null;
    } else {
      plannedTravel.departureDate = plannedTravel.departureAndArrival[0];
      plannedTravel.arrivalDate = plannedTravel.departureAndArrival[1];
    }

    if (plannedTravel.expatriationDepartureAndArrival == null) {
      plannedTravel.expatriationDepartureDate = null;
      plannedTravel.expatriationArrivalDate = null;
    } else {
      plannedTravel.expatriationDepartureDate = plannedTravel.expatriationDepartureAndArrival[0];
      plannedTravel.expatriationArrivalDate = plannedTravel.expatriationDepartureAndArrival[1];
    }

    const coTravellers: any = plannedTravel.coTravellers;
    if (coTravellers != null && coTravellers.$ngOptionValue === null) {
      plannedTravel.coTravellers = null;
    }

    this.plannedTravelService.updatePlannedTravelWithQueueService(plannedTravel);
  }

  addSubTravelDestination(): void {
    if ( this.plannedTravelSubPlans?.length >= this.MAX_NUMBER_OF_TRAVEL_DESTINATIONS-1 ) {
      return;
    }
    this.plannedTravelService.addPlannedTravel(new PlannedTravel({
      seccaCaseId: this.plannedTravel.seccaCaseId
    })).pipe(switchMap(() =>
      this.plannedTravelService.getPlannedTravelSubPlans(this.plannedTravel.seccaCaseId)
    ))
    .subscribe(subPlans => this.plannedTravelSubPlans = subPlans);
  }

  deleteMainTravelDestination(plannedTravel: PlannedTravel): void {
    if ( !this.plannedTravelSubPlans || this.plannedTravelSubPlans.length < 1 ) {
      return;
    }

    const firstSubTravel = this.plannedTravelSubPlans[0];
    plannedTravel.destinationLocation = firstSubTravel.destinationLocation;
    this.saveModel(plannedTravel);

    this.plannedTravelService.deletePlannedTravel(+firstSubTravel.id).pipe(switchMap(() =>
      this.plannedTravelService.getPlannedTravelSubPlans(plannedTravel.seccaCaseId)
    ))
    .subscribe(subPlans => this.plannedTravelSubPlans = subPlans);
  }

  deleteSubTravelDestination(plannedTravel: PlannedTravel): void {
    this.plannedTravelService.deletePlannedTravel(+plannedTravel.id).pipe(switchMap(() =>
      this.plannedTravelService.getPlannedTravelSubPlans(this.plannedTravel.seccaCaseId)
    ))
    .subscribe(subPlans => this.plannedTravelSubPlans = subPlans);
  }

  updateTravelPeriodDepartureDate(departureDate: moment.Moment, arrivalDate: moment.Moment) {
    this.plannedTravel.departureDate = departureDate;
    this.plannedTravel.arrivalDate = arrivalDate;
    this.updateTravelPeriod();
  }

  updateTravelPeriodArrivalDate(arrivalDate: moment.Moment, departureDate: moment.Moment) {
    this.plannedTravel.arrivalDate = arrivalDate;
    this.plannedTravel.departureDate = departureDate;
    this.updateTravelPeriod();
  }

  updateTravelPeriod() {
    this.plannedTravel.departureAndArrival = [this.plannedTravel.departureDate, this.plannedTravel.arrivalDate];
    this.plannedTravel.durationDays = !!this.getDurationDaysInNumber() ? this.getDurationDaysInNumber().toString() : null;
    this.plannedTravel.durationWeeks = this.getDurationWeeks() || null;
    this.plannedTravelService.updatePlannedTravelWithQueueService(this.plannedTravel);
    this.updateTimeLineService.changedTravel(this.plannedTravel);
  }

  updateExpatriationPeriodDepartureDate(departureDate: moment.Moment, arrivalDate: moment.Moment) {
    this.plannedTravel.expatriationDepartureDate = departureDate;
    this.plannedTravel.expatriationArrivalDate = arrivalDate;
    this.plannedTravel.expatriationDepartureAndArrival = [this.plannedTravel.expatriationDepartureDate, this.plannedTravel.expatriationArrivalDate];
    this.updateExpatriationPeriod();
    this.plannedTravelService.updatePlannedTravelWithQueueService(this.plannedTravel);
  }

  updateExpatriationPeriodArrivalDate(arrivalDate: moment.Moment, departureDate: moment.Moment) {
    this.plannedTravel.expatriationArrivalDate = arrivalDate;
    this.plannedTravel.expatriationDepartureDate = departureDate;
    this.plannedTravel.expatriationDepartureAndArrival = [this.plannedTravel.expatriationDepartureDate, this.plannedTravel.expatriationArrivalDate];
    this.updateExpatriationPeriod();
    this.plannedTravelService.updatePlannedTravelWithQueueService(this.plannedTravel);
  }

  updateExpatriationPeriod() {
    this.plannedTravelService.updatePlannedTravelWithQueueService(this.plannedTravel);
  }

  updateTravelPurposes(selectedPurposes: DropdownDictionary[]) {
    this.plannedTravel.purposesOfTravel = selectedPurposes.map(item => this.adaptPurposeOfTravelToDto(item));
    this.saveModel(this.plannedTravel);
  }

  isDepartureAndArrivalValidMessage(): string {
    if (this.plannedTravel.departureAndArrival == null) {
      return 'Please set departure and arrival date';
    }
    if (this.plannedTravel.departureAndArrival[0] == null) {
      return 'Please set the departure date also';
    }
    if (this.plannedTravel.departureAndArrival[1] == null) {
      return 'Please set the arrival date also';
    }
    if (this.plannedTravel.departureAndArrival[0] > this.plannedTravel.departureAndArrival[1]) {
      return 'Arrival (Home country) must be after Departure';
    }
    return '';
  }

  isDepartureAndArrivalValid(): boolean {
    if (this.plannedTravel.departureAndArrival == null) {
      return false;
    }
    if (this.plannedTravel.departureAndArrival[0] == null && this.plannedTravel.departureAndArrival[1] == null) {
      return false;
    }
    if (this.plannedTravel.departureAndArrival[0] != null && this.plannedTravel.departureAndArrival[1] == null) {
      return false;
    }
    if (this.plannedTravel.departureAndArrival[0] == null && this.plannedTravel.departureAndArrival[1] != null) {
      return false;
    }
    if (this.plannedTravel.departureAndArrival[0] > this.plannedTravel.departureAndArrival[1]) {
      return false;
    }

    return true;
  }

  hasSubTravelPlans(): boolean {
    return this.plannedTravelSubPlans?.length > 0;
  }

  isMaxDestinationsCreated(): boolean {
    return this.plannedTravelSubPlans?.length >= this.MAX_NUMBER_OF_TRAVEL_DESTINATIONS-1;
  }

  adaptPurposeOfTravelToDto(purposeOfTravel: DropdownDictionary): string {
    return purposeOfTravel.id;
  }

  updateTravelAgency(travelAgency: string) {
    this.plannedTravel.travelAgency = travelAgency;
    this.saveModel(this.plannedTravel);
  }

  updateCoTravellers(coTravellers: string) {
    this.plannedTravel.coTravellers = coTravellers;
    this.saveModel(this.plannedTravel);
  }
}
