import { SubStakeholder } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholder';

export class SubStakeholders {
  stakeholders: SubStakeholder[] = [new SubStakeholder()];

  constructor(stakeholders?: SubStakeholder[]) {
    this.stakeholders = !!stakeholders ? stakeholders : [new SubStakeholder()];
  }

  clearStakeholderTable() {
    this.stakeholders = [];
  }
}
