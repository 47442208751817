import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-small-yes-no-question',
  templateUrl: './small-yes-no-question.component.html',
  styleUrls: ['./small-yes-no-question.component.scss']
})
export class SmallYesNoQuestionComponent {

  @Input() header: string;
  @Input() disabled: boolean;
  @Output() valueChange = new EventEmitter();
  questionValue: boolean;

  constructor() { }

  answerYes() {
    if(!this.disabled) {
      if (this.value === true) {
        this.value = undefined;
      } else {
        this.value = true;
      }
    }
  }

  answerNo() {
    if (!this.disabled) {
      if (this.value === false) {
        this.value = undefined;
      } else {
        this.value = false;
      }
    }
  }

  @Input()
  get value() {
    return this.questionValue;
  }

  set value(question) {
    this.questionValue = question;
    this.valueChange.emit(this.questionValue);
  }
}
