import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-value',
  templateUrl: './new-value.component.html',
  styleUrls: ['./new-value.component.scss']
})
export class NewValueComponent implements OnInit {

  @Input() entry;
  @Input() attribute
  @Input() display = 'string';
  @Input() showAll = false;

  constructor(
    ) { }

  ngOnInit(): void {
  }
 
}

