import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { StakeholderService } from '@secca/core/services/stakeholder.service';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { MajorIncidentCaseDetail } from '@secca/shared/models/Major-incident-case-details';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { DropdownWitIconDictionary } from '@secca/shared/models/dropdownWithIconDictionary';
import { StakeholderTypeEnum } from '@secca/shared/models/enums';
import * as _ from 'lodash';
import { SubStakeholder } from '../../models/subStakeholder';
import { SubStakeholders } from '../../models/subStakeholders';
import { MajorIncidentStakeholders } from '../../models/majorIncidentStakeholders';
import { MajorIncidentStakeholder } from '../../models/majorIncidentStakeholder';
import { cloneDeep } from 'lodash-es';

@Component({
  selector: 'app-sub-major-incident-stakeholders',
  templateUrl: './sub-major-incident-stakeholders.component.html',
  styleUrls: ['./sub-major-incident-stakeholders.component.scss']
})


@AutoUnsubscribe
export class SubMajorIncidentStakeholdersComponent implements OnInit, OnChanges {
  @Input() majorIncidentCaseDetail: MajorIncidentCaseDetail;
  @Input() flightStakeholders: MajorIncidentStakeholders;
  stakeholdersDropdown: DropdownWitIconDictionary[];
  endUser: string;
  caseStakeholders: CaseStakeholder[];
  endUserCaseStakeholders: MajorIncidentStakeholder[];
  disabled: boolean;
  caseId: string;
  @Input() savedFlightStakeholders: MajorIncidentStakeholders;

  constructor(private stakeholderService: StakeholderService) {}

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit() {
    this.caseId = this.majorIncidentCaseDetail.caseId.toString();
    this.endUserCaseStakeholders = this.flightStakeholders.byCase(this.caseId);
    if(this.majorIncidentCaseDetail) {
      this.initICCStakeholdersDropdown();
    }
  }

  initICCStakeholdersDropdown() {
    if ( this.majorIncidentCaseDetail.stakeholders ) {
      const tempStakeholders = _.clone(this.majorIncidentCaseDetail.stakeholders);
      this.caseStakeholders = tempStakeholders;
      this.stakeholdersDropdown = [];
      tempStakeholders.forEach(stakeholder => {
        this.stakeholdersDropdown.push({
          id: stakeholder.id,
          name: stakeholder.person ? `${stakeholder.person.firstName} ${stakeholder.person.surname}` : `${stakeholder.company.name}`,
          iconPath: this.stakeholderService.getStakeholderIconFullPath(stakeholder.stakeholderType),
          hoverText: null
        });
        if(stakeholder.stakeholderType === StakeholderTypeEnum.endUser) {
          this.endUser = stakeholder.person.firstName + ' ' + stakeholder.person.surname + ', ' + stakeholder.person.gender + stakeholder.person.age;
        }
      });
    }
  }

  updateStakeHolder(stakeHolder) {
    if (this.flightStakeholders.allreadyChosen(this.caseId, stakeHolder.stakeholderId)) {
      this.deleteStakeholder(stakeHolder);
    }
  }

  deleteStakeholder(stakeholder) {
      this.flightStakeholders.delete(stakeholder);
      if(this.flightStakeholders.byCase(this.caseId).length === 0) {
        this.flightStakeholders.push(new MajorIncidentStakeholder({caseId: this.caseId }));
      }
      this.endUserCaseStakeholders = this.flightStakeholders.byCase(this.caseId);

      if(this.savedFlightStakeholders) {
        this.savedFlightStakeholders.stakeholders =  cloneDeep(this.flightStakeholders.byCase(this.caseId));
      }
  }

  getDayOfBirth(stakeholderId: string) {
    const stakeholder = stakeholderId !== '0' ? this.majorIncidentCaseDetail.stakeholders.find(stakeholder => stakeholder.id === stakeholderId) as CaseStakeholder : null;
    return stakeholder ? stakeholder.person?.dateOfBirth : null;
  }

  addStakeholder() {
    this.flightStakeholders.push(new MajorIncidentStakeholder({caseId: this.caseId }));
    this.endUserCaseStakeholders = this.flightStakeholders.byCase(this.caseId);
  }
}
