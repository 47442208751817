import {Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {DropdownDictionary} from '@secca/shared/models/dropdownDictionary';
import {
  CaseDocumentTagEnum,
  InputType,
  InputTypePattern,
  RecoveryStatus,
  RecoveryStatusIndex,
  SalesOrderItemTypeEnum,
  ValidationTypeEnum
} from '@secca/shared/models/enums';
import {ServiceOrder} from '@secca/shared/models/service-order/service-order';
import {PermissionService} from '@secca/core/services/permission.service';
import {TranslateService} from '@ngx-translate/core';
import {CaseService} from '@secca/core/services/case.service';
import {AutoCompleteTypeEnum} from '@secca/shared/components/drop-down-countries-auto-complete/auto-complete-type-enum';
import {DictionaryService} from '@secca/core/services/dictionary.service';
import * as moment from 'moment-timezone';
import * as _ from 'lodash';
import {CaseMessageService} from '@secca/core/services/case-message.service';
import {OutputManagementService} from '@secca/case/components/case-output-management/services/output-management.service';
import {ModalDialogComponent} from '@secca/shared/components/modal-dialog/modal-dialog.component';
import {ModalDialogConfiguration} from '@secca/shared/models/modal/modal-dialog-configuration';
import {DialogViewerServiceInterface} from '@secca/core/services/dialog-viewer-service-interface';
import {DIALOG_VIEWER_TOKEN} from '@secca/core/services/token';
import {CaseValidationService} from '@secca/core/services/case-validation.service';
import {EconomyStatusBarValues} from '@secca/shared/models/economyStatusBarValues';
import {CaseLockHelperService} from '@secca/core/services/case-lock-helper.service';
import {CaseLockOverlayAction, LockContextEnum} from '@secca/shared/enums/lock-context.enum';
import {finalize, switchMap, tap} from 'rxjs/operators';
import {CaseStateService} from '@secca/core/state-services/case-state.service';
import {MasterListService} from '@secca/core/services/masterlist-service';
import {SupplierInvoiceService} from '@secca/core/services/supplier-invoice.service';
import {Case} from '@secca/shared/models/case';
import {FinancialStatusService} from '@secca/core/services/financial-status.service';
import { Recovery } from '@secca/shared/models/recovery';
import { RecoveryService } from '@secca/core/services/recovery.service';
import { CurrencyMaskInputMode } from 'ngx-currency';
import { RecoveryDocumentComponent } from '../recovery-document/recovery-document.component';
import { SalesOrderService } from '@secca/core/services/sales-order.service';
import { SalesOrder } from '@secca/shared/models/salesOrder';
import { SupplierInvoice } from '@secca/shared/models/supplierInvoice';
import { RecoveryViewItem } from '@secca/shared/models/recovery-view-Item';
import { forkJoin, Observable, map } from 'rxjs';
import { InvoiceItemEvent, RemittanceItemEvent } from '../recovery-item/recovery-item.component';
import { PlanService } from '@secca/core/services/plan.service';
import { BillingCurrency } from '@secca/shared/models/billing-currency';
import { SalesOrderStatus } from '@secca/shared/enums/sales-order-status';
import { DocumentService } from '@secca/core/services/document.service';
import { CaseDocument } from '@secca/shared/models/caseDocument';
import { DateSerializer } from '@secca/shared/models/date-serializer';
import { ReasonForCancellation } from '@secca/shared/models/reason-for-cancellation';


@Component({
  selector: 'app-add-recovery',
  templateUrl: './add-recovery.component.html',
  styleUrls: ['./add-recovery.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddRecoveryComponent implements OnInit {
  @Input() readOnly: boolean;
  @Input() caseCreatedDate: Date;
  @Input() customerProfileId: string;
  @Input() caseId: string;
  @Input() recoveryId: string;
  @Output() closed = new EventEmitter<number>();
  @Output() update = new EventEmitter<number>();
  @Output() recoveryDeleted = new EventEmitter<number>();

  @ViewChild(RecoveryDocumentComponent) recoveryDocumentComponent: RecoveryDocumentComponent;

  currencyMaskOptions = {
    prefix: '',
    thousands: '.',
    decimal: ',',
    precision: 2,
    allowNegative: true,
    inputMode: CurrencyMaskInputMode.NATURAL,
  };

  recovery: Recovery;
  oldRecovery: Recovery;
  recoveryItems: RecoveryViewItem[];
  invoiceNumbers: DropdownDictionary[] = [];
  remittanceAdvices: DropdownDictionary[] = [];
  serviceOrdersForRecovery: ServiceOrder[];
  serviceItemsForRecovery: any[];
  salesOrders: SalesOrder[];
  supplierInvoices: SupplierInvoice[];
  remittanceDocuments: CaseDocument[];
  currentCase: Case;
  storingRecovery = false;
  nationalHealthAuthority: string;
  isCaseErpReady = false;
  groupMemberValid = true;
  calculateTotalRecoveryCurrency: number;
  recoveryState = new RecoveryState();
  waitBeforeInitTextbox = false;
  recoveryToday: Date;
  activeReasonsForCancellationOnCreationDate: DropdownDictionary[] = [];
  activeReasonsForCancellation: DropdownDictionary[] = [];
  reasonForCancellation: string;
  billingCurrency: BillingCurrency;
  selectableInvoiceNumbers = {};
  numberOfStatus = 6;
  showSpinner = false;

  constructor(private modalService: NgbModal,
              private recoveryService: RecoveryService,
              public permissionService: PermissionService,
              private masterListService: MasterListService,
              private translateService: TranslateService,
              private salesOrderService: SalesOrderService,
              private supplierInvoiceService: SupplierInvoiceService,
              private caseService: CaseService,
              private dictionaryService: DictionaryService,
              private caseMessageService: CaseMessageService,
              private outputManagementService: OutputManagementService,
              private caseValidationService: CaseValidationService,
              private planService: PlanService,
              private documentService: DocumentService,
              public caseLockHelperService: CaseLockHelperService,
              public caseStateService: CaseStateService,
              public financialStatusService: FinancialStatusService,
              @Inject(DIALOG_VIEWER_TOKEN) private dialogViewerService: DialogViewerServiceInterface) {
  }

  ngOnInit(): void {
    this.caseService.getCase('' + this.caseId).pipe(
      switchMap(resultCase => {
        this.currentCase = resultCase;
        return this.initialiseReasonForCancellation(resultCase.caseCreatedDateAsISOString);
      })
    )
    .subscribe();

    if (this.customerProfileId){
      this.validateCustomerProfile();
    }

    const [documentTagName] = Object.entries(CaseDocumentTagEnum).find(([key, value]) => value === CaseDocumentTagEnum.REMITTANCE_ADVICE);

    forkJoin({
      serviceOrdersForRecovery: this.recoveryService.getServiceOrdersForRecovery(+this.caseId),
      serviceItems: this.recoveryService.getServiceItemList(),
      salesOrders: this.salesOrderService.getSalesOrdersByCaseId(this.caseId),
      supplierInvoices: this.supplierInvoiceService.getSupplierInvoicesByCase(+this.caseId),
      remittanceDocuments: this.documentService.geDocumentsByDocumentTag(+this.caseId, documentTagName),
      recovery: this.getRecovery(this.recoveryId)
    }).subscribe(({serviceOrdersForRecovery, serviceItems, salesOrders, supplierInvoices, remittanceDocuments, recovery}) => {
      this.serviceOrdersForRecovery = serviceOrdersForRecovery;
      this.serviceItemsForRecovery = serviceItems?.filter(item => item.recovery);
      this.salesOrders = salesOrders;
      this.supplierInvoices = supplierInvoices;
      this.remittanceDocuments = remittanceDocuments;
      this.recovery = recovery;

      this.initialise();
    });
  }

  deleteRecoveryItem(event: InvoiceItemEvent) {
    if ( this.isRecoveryDisabled() || this.isApplicationSent() ) {
      return;
    }
    this.recoveryItems.splice(event.itemIndex, this.invoiceItemsCount(event.itemIndex));
    this.selectableInvoiceNumbers = {};
    this.onRecoveryItemChange();
  }

  close() {
    this.closed.emit();
    this.modalService.dismissAll();
  }

  addRecoveryItem() {
    if ( this.isRecoveryDisabled() || this.isApplicationSent() ) {
      return;
    }

    this.recoveryItems.push(this.createRecoveryItem());
    this.onRecoveryItemChange();
  }

  requestInformationActiveStatus() {
    return false;
  }

  showDeleteButton() {
    return true;
  }

  isCancelButtonDisabled() {
    return !this.recovery.reasonForCancellation || this.isRecoveryDisabled();
  }

  isCancelReasonDisabled() {
    return !this.recovery.id;
  }

  isCancelled() {
    return this.recovery?.recoveryStatus === RecoveryStatus.CANCELLED;
  }

  isRecoveryDisabled() {
    return this.isCancelled() || this.readOnly || this.storingRecovery ||
      this.caseLockHelperService.isCaseBasicLockTaken || this.caseStateService.isCaseClosed;
  }

  isSaveButtonDisabled() {
    if ( !this.recoveryState.recoveryContextValid ) {
      return true;
    }
    return !this.recoveryChanged();
  }

  isDeleteButtonDisabled() {
    return !this.recovery.id || this.isApplicationSent();
  }

  isPOAReceivedButtonDisabled() {
    return !this.recoveryState.validForReceived;
  }

  isPreviewApplicationButtonDisabled() {
    return !this.recoveryState.validForPreviewApplication || !this.isAllSelectedInvoicesCharged() || this.recoveryState.applicationHasBeenPreviewed;
  }

  isSendApplicationButtonDisabled() {
    return !this.recoveryState.validForSendApplication || !this.isAllSelectedInvoicesCharged() || this.missingMandatoryApplicationFields();
  }

  isSaveApplicationButtonDisabled() {
    return !this.recoveryState.validToSaveApplication || !this.isAllSelectedInvoicesCharged() || this.missingMandatoryApplicationFields();
  }

  isApplicationSent() {
    return RecoveryStatus.SEND_APPLICATION != this.recovery.recoveryStatus
      && this.getStatusIndexByStatus(this.recovery.recoveryStatus).statusIndex >= RecoveryStatusIndex.APPLICATION_SENT;
  }

  focusElement(event: any) {
    event.target.select();
  }

  onRecoveryItemChange() {
    this.recoveryState.applicationHasBeenPreviewed = false;
    this.recalculateRecoveryState();
  }

  onInvoiceNumberChange(event: InvoiceItemEvent) {
    const invoiceNo = event.invoiceNumber;
    const recoveryItems = this.getRecoveryItemsByInvoiceNumber(invoiceNo);

    if ( !!recoveryItems ) {
      this.recoveryItems.splice(event.itemIndex, this.invoiceItemsCount(event.itemIndex), ...recoveryItems);
    }

    this.recalculateRecoveryState();
    this.selectableInvoiceNumbers = {};
  }

  onRemittanceAdviceChange(event: RemittanceItemEvent) {
    this.recoveryItems
      .filter(ri => ri.supplierInvoiceNumber === event.invoiceNumber)
      .forEach(item => item.remittanceAdviceDocumentId = event.remittanceAdviceDocumentId);

    this.recalculateRecoveryState();
  }

  totalAppliedAmount() {
    let totalAmount = 0;
    this.recoveryItems.forEach((item: RecoveryViewItem) => {
      totalAmount += item.isInvoiceItemIncluded ? item.appliedAmount : 0;
    });
    return totalAmount;
  }

  totalRecoveredAmount() {
    let totalAmount = 0;
    this.recoveryItems.forEach((item: RecoveryViewItem) => {
      totalAmount += item.isInvoiceItemIncluded ? item.recoveredAmount : 0;
    });
    return totalAmount;
  }

  recoveredProcent() {
    const totalAppliedAmount = this.totalAppliedAmount();
    const totalRecoveredAmount = this.totalRecoveredAmount();
    return totalAppliedAmount !== 0 ? totalRecoveredAmount * 100 / totalAppliedAmount : 0;
  }

  recalculateRecoveryState(): RecoveryState {
    const recoveryStatusIndex = this.getStatusValues(this.recovery).statusIndex;
    this.recoveryState.recoveryContextValid = !!this.recovery.dueDate && !!this.recovery.applicationDeadline;
    this.recoveryState.recoveryItemsValid = this.recoveryItems.filter(item =>
      !item.supplierInvoiceNumber || !item.remittanceAdviceDocumentId
    )?.length === 0;
    this.recoveryState.recoveryValid = this.recoveryState.recoveryContextValid
      && this.recoveryState.recoveryItemsValid
      && this.totalAppliedAmount() > 0;
    this.recoveryState.validForReceived = this.recoveryState.recoveryContextValid
      && recoveryStatusIndex < RecoveryStatusIndex.POA_SIGNED;
    this.recoveryState.validForPreviewApplication = this.recoveryState.recoveryValid
      && this.recovery.description?.length > 0
      && !!this.recovery.templateId && !!this.recovery.templateLanguage
      && [RecoveryStatus.POA_SIGNED, RecoveryStatus.SEND_APPLICATION].includes(this.recovery.recoveryStatus);
    this.recoveryState.validForSendApplication = this.recoveryState.recoveryValid
      && this.recoveryState.validForPreviewApplication
      && this.recoveryState.applicationHasBeenPreviewed;
    this.recoveryState.validToSaveApplication = this.recoveryState.validForSendApplication;
    return this.recoveryState;
  }

  lockRecoveryOnApproved(): boolean {
    return [RecoveryStatus.CHARGED].includes(this.recovery?.recoveryStatus);
  }

  receivedPOA() {
    this.recovery.recoveryStatus = RecoveryStatus.POA_SIGNED;
    this.saveAndClose();
  }

  applicationSent() {
    this.recovery.recoveryStatus = RecoveryStatus.APPLICATION_SENT;
    this.saveAndClose();
  }

  previewApplication() {
    this.showSpinner = true;
    this.save().pipe(
      switchMap(() => this.recoveryDocumentComponent.preview()),
      finalize(() => this.showSpinner = false),
    ).subscribe();
  }

  applicationHasBeenPreviewed() {
    this.recoveryState.applicationHasBeenPreviewed = true;
    this.showSpinner = false;
    this.recalculateRecoveryState();
  }

  sendApplication() {
    if (this.recovery.recoveryStatus !== RecoveryStatus.APPLICATION_SENT) {
      this.recovery.recoveryStatus = RecoveryStatus.APPLICATION_SENT;
    }
    this.showSpinner = true;
    this.save().pipe(
      switchMap(() => this.recoveryDocumentComponent.send()),
      finalize(() => this.showSpinner = false)
    )
    .subscribe();
  }

  saveApplication(close: boolean = true) {
    if (this.recovery.recoveryStatus !== RecoveryStatus.SEND_APPLICATION) {
      this.recovery.recoveryStatus = RecoveryStatus.SEND_APPLICATION;
    }
    this.showSpinner = true;
    this.save().pipe(
      switchMap(() => this.recoveryDocumentComponent.save()),
      tap(() => {
        if (close) {
          this.close();
        }
      }),
      finalize(() => this.showSpinner = false)
    ).subscribe();
  }

  saveAndClose(close: boolean = true){
    if (this.storingRecovery) {
      return;
    }
    this.save().pipe(
      tap(() => {
        if (close) {
          this.close();
        }
      })
    )
    .subscribe();
  }

  deleteRecovery() {
    if ( this.isDeleteButtonDisabled() ) {
      return;
    }
    const modalRef = this.showDeleteRecoveryWarningMessageDialog();
    modalRef.componentInstance.closeModalEvent.subscribe(closedClickingYes => {
        if (closedClickingYes) {
          this.recoveryService.deleteRecoveryAndSetTaskToDone(this.recovery.id).subscribe(recovery => {
            this.recoveryDeleted.emit();
            this.close();
           });
        }
        modalRef.close();
    });
  }

  cancelRecovery() {
    const modalRef = this.showCancelRecoverylWarningDialog();
    modalRef.componentInstance.closeModalEvent.subscribe(closedClickingYes => {
        if (closedClickingYes) {
          this.recoveryService.cancelRecovery(this.recovery).subscribe(data => {
            this.close();
          });
        }
        modalRef.close();
      }
    );
  }

  paymentInAs400() {
    const modalRef = this.showCancelRecoverylWarningDialog();
    modalRef.componentInstance.closeModalEvent.subscribe(closedClickingYes => {
        if (closedClickingYes) {
          this.recoveryService.paymentInAs400(this.recovery.id).subscribe(data => {
            this.close();
          });
        }
        modalRef.close();
      }
    );
  }

  getStatusValues(recovery: Recovery): EconomyStatusBarValues {
    const statusValues = !!recovery ? this.getStatusIndexByStatus(recovery?.recoveryStatus) : new EconomyStatusBarValues();
    return statusValues;
  }

  getRedStatusText(recovery: Recovery) {
    if ([RecoveryStatus.CANCELLED].includes(recovery?.recoveryStatus)) {
      return recovery?.recoveryStatus;
    }
  }

  onKeyUp(editor) {
    if (this.recovery.description === '') {
      editor.editor.iframeElement.contentDocument.getElementsByTagName('body')[0].style = 'background-color: #fff9e7;';
    } else {
      editor.editor.iframeElement.contentDocument.getElementsByTagName('body')[0].style = 'background-color: #fff';
    }
    this.recalculateRecoveryState();
  }

  onFocus(e: any) {
    // Force a focus-in event, fix for missing focus-in event
    const event = new FocusEvent('focusin', {
      view: window,
      bubbles: true,
      cancelable: true
    });
    const editor = e?.editor?.iframeElement;
    if ( editor ) {
      editor.dispatchEvent(event);
    }
  }

  getSelectableInvoiceNumbers(item: RecoveryViewItem, index: number) {
    if ( !!this.selectableInvoiceNumbers[index] ) {
      return this.selectableInvoiceNumbers[index];
    }
    else {
      this.selectableInvoiceNumbers[index] = this.invoiceNumbers
          .filter(invoiceItem => item.supplierInvoiceNumber === invoiceItem.id ||
                                !this.recoveryItems.find(item => invoiceItem.id === item.supplierInvoiceNumber));
      return this.selectableInvoiceNumbers[index];
    }
  }

  get poaReceivedMissingHoverText() {
    if ( !this.recoveryState.validForReceived ) {
      if (!this.recoveryState.recoveryContextValid) {
        return this.translateService.instant('case-economy-recovery-missing-fields');
      }
      else if (this.getStatusValues(this.recovery).statusIndex >= RecoveryStatusIndex.POA_SIGNED) {
        return this.translateService.instant('case-economy-recovery-already-received');
      }
    }
  }

  get previewApplicationMissingHoverText() {
    if ( !this.recoveryState.recoveryValid ) {
      return this.getRecoveryMissingHoverText();
    } else if ( !this.recoveryState.validForPreviewApplication ) {
      if ( !this.recoveryState.recoveryValid || !this.recovery.description || !this.recovery.templateId || !this.recovery.templateLanguage ) {
        if ( !this.recovery.description ) {
          return this.translateService.instant('case-economy-recovery-missing-description');
        }
        else if ( !this.recovery.templateId || !this.recovery.templateLanguage ) {
          return this.translateService.instant('case-economy-recovery-missing-template');
        }
        else {
          return this.translateService.instant('case-economy-recovery-missing-fields');
        }
      }
      else if ( this.getStatusValues(this.recovery).statusIndex <= RecoveryStatusIndex.POA_SIGNED ) {
        return this.translateService.instant('case-economy-recovery-state-not-signed');
      }
    }
    else if ( !this.isAllSelectedInvoicesCharged() ) {
      return this.translateService.instant('case-economy-recovery-invoices-must-be-charged');
    }
  }

  get sendApplicationMissingHoverText() {
    return this.getApplicationMissingHoverText('send');
  }

  get saveApplicationMissingHoverText() {
    return this.getApplicationMissingHoverText('save');
  }

  get AutoCompleteTypeEnum() {
    return AutoCompleteTypeEnum;
  }

  get InputType() {
    return InputType;
  }

  get InputTypePattern() {
    return InputTypePattern;
  }

  get ValidationTypeEnum() {
    return ValidationTypeEnum;
  }

  get LockContextEnum(): any {
    return LockContextEnum;
  }

  get CaseLockOverlayAction(): any {
    return CaseLockOverlayAction;
  }

  get recoveryStatus(): typeof RecoveryStatus {
    return RecoveryStatus;
  }

  private initialise() {
    this.populateInvoiceDropDownValues();
    this.mergeInvoiceItemsWithLoadedRecoveryItems();
    this.populateRemittanceAdvices();
    this.recalculateRecoveryState();

    setTimeout(() => {
      this.waitBeforeInitTextbox = true;
    }, 500);

    this.createOldRecovery();
  }

  private initialiseReasonForCancellation(caseCreatedDate: string): Observable<ReasonForCancellation[]> {
    return this.masterListService.getReasonForCancellation().pipe(
      tap((result: ReasonForCancellation[]) => {
        const caseCreatedMoment: moment.Moment = !!caseCreatedDate ? DateSerializer.deserialize(caseCreatedDate) : moment();
        this.activeReasonsForCancellationOnCreationDate = result.filter(item => !item.activateOn.isAfter(caseCreatedMoment) && (item.deactivateOn === null || !caseCreatedMoment.isAfter(moment(item.deactivateOn)))).map(item => new DropdownDictionary(item.reasonForCancellationCode, item.reasonForCancellationName));
        this.activeReasonsForCancellation = result.filter(item => item.active).map(item => new DropdownDictionary(item.reasonForCancellationCode, item.reasonForCancellationName));
    }));
  }

  private validateCustomerProfile() {
    this.caseService.getCustomerProfile(this.customerProfileId.toString()).subscribe(customerProfile =>{
      if(customerProfile.isGroupProfile){
        this.groupMemberValid = false;
      }
    });
  }

  private save(): Observable<Recovery> {
    this.storingRecovery = true;
    this.recovery.items = this.recoveryItems.filter(item => !!item.isInvoiceItemIncluded);
    if (this.recovery.id === null) {
      return this.recoveryService.createRecovery(this.recovery).pipe(
        tap(() => {
            this.createOldRecovery();
            this.refreshHandlingAreas();
        }),
        finalize(() => this.storingRecovery = false)
      );
    } else {
      return this.recoveryService.updateRecovery(this.recovery).pipe(
        tap(() => {
          this.refreshHandlingAreas();
          this.update.emit();
        }),
        finalize(() => this.storingRecovery = false)
      );
    }
  }

  private refreshHandlingAreas() {
    setTimeout(() => {
      this.caseStateService.sendRefreshHandlingArea();
    }, 200);
  }

  private getRecoveryMissingHoverText() {
    if (!this.recoveryState.recoveryItemsValid) {
      return this.translateService.instant('case-economy-recovery-items-missing-fields');
    } else if (!this.recoveryState.recoveryContextValid) {
      return this.translateService.instant('case-economy-recovery-missing-fields');
    }
    else if (this.totalAppliedAmount() === 0) {
      return this.translateService.instant('case-economy-recovery-applied-amount-must-not-be-nul');
    }
  }

  private getApplicationMissingHoverText(application: string) {
    if ( !this.recoveryState.applicationHasBeenPreviewed ) {
      return this.translateService.instant('case-economy-recovery-application-must-be-previewed');
    }
    else if ( !this.isAllSelectedInvoicesCharged() ) {
      return this.translateService.instant('case-economy-recovery-invoices-must-be-charged');
    }
    else if ( this.missingMandatoryApplicationFields() ) {
      const missingFields = this.getSendApplicationMissingMandatoryApplicationFields();
      let text = '<span class="send-application-warn"><b>' +
                    this.translateService.instant('case-economy-recovery-missing-mandatory-fields-for-application', {application}) +
                 '</b></span><br>' +
                  Object.keys(missingFields)?.map(supplierInvoiceNumber =>
                    '' + this.translateService.instant('case-economy-recovery-supplier-invoice') + supplierInvoiceNumber +
                    '<ul>' + missingFields[supplierInvoiceNumber]?.map(field => '<li>' + field + '</li>') + '</ul>');
      return text;
    }
  }

  private getSendApplicationMissingMandatoryApplicationFields() {
    const missingFields: {[key: string]: []} = {};

    this.recovery.items.filter((item: RecoveryViewItem) => item.isInvoiceItemIncluded)
                       .map(item => {
      if ( !item.supplierInvoiceKreditor ) {
        this.addMissingField(missingFields, item.supplierInvoiceNumber, this.translateService.instant('case-economy-recovery-supplier-invoice-creditor-field'));
      }
      if ( !item.supplierPaidAmount ) {
        this.addMissingField(missingFields, item.supplierInvoiceNumber, this.translateService.instant('case-economy-recovery-supplier-paid-amount-field'));
      }
      if ( !item.supplierPaidAmountBeforeSavings ) {
        this.addMissingField(missingFields, item.supplierInvoiceNumber, this.translateService.instant('case-economy-recovery-supplier-paid-amount-before-savings-field'));
      }
      if ( !item.appliedAmount ) {
        this.addMissingField(missingFields, item.supplierInvoiceNumber, this.translateService.instant('case-economy-recovery-applied-amount-field'));
      }
      if ( !item.salesOrderInvoiceNo ) {
        this.addMissingField(missingFields, item.supplierInvoiceNumber, this.translateService.instant('case-economy-recovery-sales-order-invoice-no-field'));
      }
      if ( !item.serviceItemCode ) {
        this.addMissingField(missingFields, item.supplierInvoiceNumber, this.translateService.instant('case-economy-recovery-service-item-code-field'));
      }
      if ( !item.remittanceAdviceDocumentId ) {
        this.addMissingField(missingFields, item.supplierInvoiceNumber, this.translateService.instant('case-economy-recovery-remittance-advice-field'));
      }
    });

    return missingFields;
  }

  private addMissingField(missingFields, key, value) {
    missingFields[key] = (missingFields[key] || []);
    missingFields[key].push(value);
  }

  private missingMandatoryApplicationFields() {
    const includedItems = this.recovery.items.filter((item: RecoveryViewItem) => item.isInvoiceItemIncluded);
    const validItems = includedItems.filter(item => !!item.supplierInvoiceNumber && !!item.supplierInvoiceKreditor && !!item.supplierPaidAmount && !!item.supplierPaidAmountBeforeSavings && !!item.appliedAmount && !!item.salesOrderInvoiceNo && !!item.serviceItemCode && !!item.remittanceAdviceDocumentId);
    return validItems.length !== includedItems.length;
  }

  private createOldRecovery() {
    this.oldRecovery = this.getRecoveryCopy();
  }

  private recoveryChanged(): boolean {
    const recovery = this.getRecoveryCopy();
    return JSON.stringify(this.oldRecovery) !== JSON.stringify(recovery);
  }

  private getRecoveryCopy() {
    const clone = _.clone(this.recovery);
    clone.items = _.cloneDeep(this.recoveryItems);
    return clone;
  }

  private invoiceItemsCount(index: number): number {
    let count = 1;
    const firstItem = this.recoveryItems?.length > index ? this.recoveryItems[index] : null;
    if ( !!firstItem ) {
      for (let i = index + 1; i < this.recoveryItems.length; ++i) {
        const item = this.recoveryItems[i] as RecoveryViewItem;
        if ( item.isInvoiceItemGroupStart || item.supplierInvoiceNumber !== firstItem.supplierInvoiceNumber ) {
          break;
        }
        ++count;
      }
    }
    return count;
  }

  private mergeInvoiceItemsWithLoadedRecoveryItems() {
    this.recoveryItems = [this.createRecoveryItem()];
    if ( this.recovery.items?.length > 0 && !!this.recovery.items?.find(item =>!!item.supplierInvoiceNumber) ) {
      const invoiceNumbers = [...new Set(this.recovery.items?.map(item => item.supplierInvoiceNumber))];
      this.recoveryItems = invoiceNumbers.filter(invoiceNumber => !!invoiceNumber).flatMap(invoiceNumber => {
        let remittanceAdviceDocumentId;
        const recoveryItems = this.getRecoveryItemsByInvoiceNumber(invoiceNumber)
                                  .map(item => {
                                    const existingItem = this.recovery.items.find(existingItem => existingItem.supplierInvoiceItemId === item.supplierInvoiceItemId);
                                    if (!!existingItem) {
                                      item = new RecoveryViewItem(existingItem);
                                      remittanceAdviceDocumentId = item.remittanceAdviceDocumentId;
                                    }
                                    item.isInvoiceItemIncluded = !!existingItem;
                                    return item;
                                  });

        const existingNotFoundItems = this.recovery.items.filter(item => item.supplierInvoiceNumber === invoiceNumber)
                                                         .filter(existingItem => !recoveryItems.find(item => existingItem.supplierInvoiceItemId === item.supplierInvoiceItemId))
                                                         .map(existingItem => {
                                                            const item = new RecoveryViewItem(existingItem);
                                                            item.isNotFoundWarning = true;
                                                            item.isInvoiceItemIncluded = true;
                                                            return item;
                                                          });
        recoveryItems.push(...existingNotFoundItems);

        recoveryItems.sort((item1: RecoveryViewItem, item2: RecoveryViewItem) => {
          return item1.supplierInvoiceItemId - item2.supplierInvoiceItemId;
        });

        recoveryItems.forEach((item, index) => {
          item.isInvoiceItemGroupStart = index === 0;
          item.remittanceAdviceDocumentId = remittanceAdviceDocumentId;
        });

        return recoveryItems;
      });
    }
  }

  private getRecoveryItemsByInvoiceNumber(invoiceNo: string) {
    const salesOrderTypes = [SalesOrderItemTypeEnum.SUPPLIER_INVOICE];
    const supplierInvoice = this.supplierInvoices?.find(supplierInvoice => supplierInvoice.invoiceNumber === invoiceNo);
    return supplierInvoice.items
                          .filter(supplierInvoiceItem => this.serviceItemsForRecovery?.find(serviceItem => serviceItem.code === supplierInvoiceItem?.serviceItemCode))
                          .flatMap((supplierInvoiceItem, supplierInvoiceItemIndex) => {
      const salesOrderItems = this.salesOrders.flatMap(salesOrder => {salesOrder.salesOrderItems.forEach(item => item.salesOrderId = salesOrder.id); return salesOrder.salesOrderItems})
                                              .filter(salesOrderItem => this.serviceOrdersForRecovery.find(serviceOrder => serviceOrder.serviceOrderId === salesOrderItem.serviceOrderId))
                                              .filter(salesOrderItem => salesOrderTypes.includes(salesOrderItem.type as SalesOrderItemTypeEnum) && salesOrderItem.supplierInvoiceItemId === supplierInvoiceItem.id);

      return salesOrderItems.map((salesOrderItem, salesOrderItemIndex) =>
        new RecoveryViewItem({
          supplierInvoiceNumber: supplierInvoice.invoiceNumber,
          serviceItemCode: supplierInvoiceItem.serviceItemCode,
          serviceItemName: supplierInvoiceItem.serviceItemName,
          supplierPaidAmount: salesOrderItem.agreementPrice.amount,
          supplierPaidAmountBeforeSavings: supplierInvoiceItem.paidAmount,
          supplierLocalCurrency: salesOrderItem.agreementPrice.currency,
          supplierInvoiceKreditor: supplierInvoice.creditorName,
          appliedAmount: salesOrderItem.invoicePrice.amount,
          recoveryCurrency: salesOrderItem.invoicePrice.currency,
          salesOrderInvoiceNo: this.salesOrders.find(s => s.id === salesOrderItem.salesOrderId)?.invoiceNo,
          serviceOrderId: supplierInvoiceItem.serviceOrderId,
          salesOrderId: salesOrderItem.salesOrderId,
          salesOrderItemId: salesOrderItem.id,
          supplierInvoiceItemId: salesOrderItem.supplierInvoiceItemId,
          isInvoiceItemGroupStart: supplierInvoiceItemIndex === 0 && salesOrderItemIndex === 0,
          isInvoiceItemIncluded: true
         })
      );
    })
  }

  private isAllSelectedInvoicesCharged() {
    const chargedItems = this.recoveryItems
                          .filter(item => item.isInvoiceItemIncluded && ![SalesOrderStatus.PAID, SalesOrderStatus.INVOICED].includes(this.salesOrders?.find(salesOrder => salesOrder.id === item.salesOrderId)?.status));
    return chargedItems.length === 0;
  }

  private populateInvoiceDropDownValues() {
    const invoiceNumbers = this.findAllInvoiceNumbers();
    const existingInvoiceNumbers = this.recovery.items?.map(item => item.supplierInvoiceNumber);
    this.invoiceNumbers = [...new Set([...invoiceNumbers, ...existingInvoiceNumbers])].map(invoiceNumber =>
        new DropdownDictionary(invoiceNumber, invoiceNumber)
    );
  }

  private populateRemittanceAdvices() {
    this.remittanceAdvices = this.remittanceDocuments.map(document => new DropdownDictionary(document.id, document.comments));
  }

  private findAllInvoiceNumbers() {
    const salesOrderTypes = [SalesOrderItemTypeEnum.SUPPLIER_INVOICE];
    return this.serviceOrdersForRecovery?.flatMap(serviceOrder => {
      const salesOrderItems = this.salesOrders?.flatMap(salesOrder => salesOrder.salesOrderItems).filter(salesOrderItems => salesOrderTypes.includes(salesOrderItems.type as SalesOrderItemTypeEnum) && salesOrderItems.serviceOrderId === serviceOrder.serviceOrderId);
      return salesOrderItems.flatMap(salesOrderItem => {
        const supplierInvoices = this.supplierInvoices?.filter(supplierInvoice => {
          return supplierInvoice.items.find(supplierInvoiceItem => supplierInvoiceItem.id === salesOrderItem.supplierInvoiceItemId && this.serviceItemsForRecovery?.find(serviceItem => serviceItem.code === supplierInvoiceItem?.serviceItemCode));
        });
        return supplierInvoices.map(supplierInvoice => supplierInvoice.invoiceNumber);
      });
    });
  }

  private getRecovery(recoveryId: string): Observable<Recovery> {
    if ( !!recoveryId ) {
      return this.recoveryService.getRecoveryById(+recoveryId);
    }
    else {
      return this.planService.getBillingCurrencyForCase(+this.caseId).pipe(
        map(result => {
          this.billingCurrency = result;
          return this.createRecovery();
        })
      );
    }
  }

  private createRecovery(): Recovery {
    const recovery = new Recovery();
    recovery.caseId = + this.caseId;
    recovery.customerProfileId = + this.customerProfileId;
    recovery.recoveryStatus = RecoveryStatus.REQUEST_POA;
    recovery.recoveryCurrency = this.billingCurrency?.billingCurrency;
    recovery.internalRemark = '';
    recovery.description = null;
    recovery.id = null;
    recovery.templateName = null;
    recovery.items = [this.createRecoveryItem()];

    return recovery;
  }

  private createRecoveryItem() {
    return new RecoveryViewItem({
      supplierInvoiceNumber: null,
      serviceOrderId: null,
      serviceItemCode: null,
      supplierPaidAmount: null,
      appliedAmount: null,
      recoveredAmount: null,
      isInvoiceItemGroupStart: true,
      isInvoiceItemIncluded: true
    });
  }


  private getStatusIndexByStatus(status: RecoveryStatus): EconomyStatusBarValues {
    const statusValues = new EconomyStatusBarValues();
    statusValues.redIconIndex = null;
    statusValues.warnIconIndex = null;
    statusValues.statusIndex = -1;
    Recovery.setStatusIndex(statusValues, status);
    return statusValues;
  }

  private showDeleteRecoveryWarningMessageDialog(): NgbModalRef {
    const confirmCloseDialog = this.modalService.open(ModalDialogComponent);
    confirmCloseDialog.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'default-modal-header',
      title: 'case-economy-recovery-delete-warning-modal-title',
      text: '',
      footer: 'case-economy-recovery-delete-warning-modal-footer',
      yes: 'default-modal-dialog-yes',
      no: 'default-modal-dialog-no',
      isBody: false,
      isFooter: true,
    });
    return confirmCloseDialog;
  }

  private showCancelRecoverylWarningDialog(): NgbModalRef {
    const modalRef = this.modalService.open(ModalDialogComponent);
    modalRef.componentInstance.items = [];
    modalRef.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'default-modal-header',
      title: 'case-economy-recovery-cancel-warning-modal-title',
      text: '',
      footer: 'case-economy-recovery-cancel-warning-modal-footer',
      yes: 'default-modal-dialog-yes',
      no: 'default-modal-dialog-no',
      isBody: false,
      isFooter: true,
    });
    return modalRef;
  }

  get tinyMCEConfiguration() {
    let style = '';
    if (this.recovery.description === '' || this.recovery.description === null) {
      style = 'body { font-family: calibri; font-size: 11pt; background-color: #fff9e7;  }';
    } else {
      style = 'body { font-family: calibri; font-size: 11pt; }';
    }
    return {
      base_url: '/tinymce',
      suffix: '.min',
      menubar: false,
      height: '100%',
      body_class: 'mceBlackBody',
      paste_data_images: true,
      fontsize_formats: '8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt 24pt 28pt 32pt 36pt',
      content_style: style,

      font_formats:
        'Calibri=calibri; Arial=arial,helvetica,sans-serif;' +
        'Times New Roman=times new roman,times;' +
        'Helvetica=helvetica;' +
        'Courier New=courier_newregular,courier;' +
        'Verdana=verdana,geneva;',
      plugins: [
        'print preview searchreplace autolink ' +
          'directionality visualblocks visualchars fullscreen image link ' +
          'media template codesample table charmap hr pagebreak nonbreaking ' +
          'anchor toc insertdatetime advlist lists wordcount ' +
          'imagetools textpattern noneditable help ' +
          'charmap quickbars emoticons '
      ],
      toolbar:
        'bold italic underline strikethrough | fontselect fontsizeselect formatselect | \
        alignleft aligncenter alignright alignjustify | \
        bullist numlist checklist  | outdent indent | forecolor backcolor formatpainter'
      };
    }

  protected readonly RecoveryStatus = RecoveryStatus;
}

export class RecoveryState {
  recoveryValid = false;
  recoveryContextValid = false;
  recoveryItemsValid = false;
  validForReceived = false;
  validForPreviewApplication = false;
  applicationHasBeenPreviewed = false;
  validForSendApplication = false;
  validToSaveApplication = false;
}
