import { AssigneeEnum, CommitmentTypes, HandlingAreaStatusEnum, HandlingAreaType, StatusTypes } from './../../../../../../../../shared/models/enums';
import { ServiceTypeBase } from '../../models/serviceTypeBase';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ServiceTypeComponent } from './../../models/interfaces';
import { MedicalAssessmentServiceOrderExtension } from '@secca/shared/models/service-order/medical-assessment-service-order-extension';
import { AssessmentTypeEnum, ServiceTypeEnum } from '@secca/shared/models/enums';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import { User } from '@secca/shared/models/user';
import { CaseService } from '@secca/core/services/case.service';
import { ServiceOrderService } from '@secca/core/services/service-order.service';
import { ServiceOrder } from '@secca/shared/models/service-order/service-order';
import { HandlingAreaService } from '@secca/core/services/handling-area.service';
import { HandlingArea } from '@secca/shared/models/handlingArea';
import { MedicalService } from '@secca/core/services/medical.service';
import { ServiceOrderType } from '@secca/shared/models/service-order-type';
import { CommitmentType } from '@secca/shared/models/commitment-type';
import { CASE_TYPE_CODES, CaseType } from '@secca/shared/models/case-type';
import { timer } from 'rxjs';

@Component({
  selector: 'app-medical-assessment-type',
  templateUrl: './medical-assessment-type.component.html',
  styleUrls: ['medical-assessment-type.component.scss'],
})
export class MedicalAssessmentTypeComponent extends ServiceTypeBase implements OnInit, OnChanges, ServiceTypeComponent {

  @Input() caseId: string;
  @Input() serviceOrderType: ServiceOrderType;
  @Input() serviceOrder: ServiceOrder;
  @Input() caseType: CaseType;

  @Input()
  set medicalAssessment(medicalAssessment: MedicalAssessmentServiceOrderExtension) {
    this._medicalAssessment = medicalAssessment;
    this.isDescriptionValid = this.medicalAssessment.validateDescription();
  }
  get medicalAssessment() {
    return this._medicalAssessment;
  }
  _medicalAssessment: MedicalAssessmentServiceOrderExtension;

  assignee = AssigneeEnum;
  assessmentType = AssessmentTypeEnum;
  medicalStaff: DropdownDictionary[];
  serviceOrderList: ServiceOrder[] = [];
  isDescriptionValid: boolean;
  nightAssessmentExist: boolean;
  regularAssessmentExist: boolean;
  editServiceOrder: boolean;
  handlingArea: HandlingArea;
  claimsHandler: boolean = false;
  selectableCommitmentTypes: CommitmentType[];

  constructor(private caseService: CaseService,
              private serviceOrderService: ServiceOrderService,
              private handlingAreaService: HandlingAreaService,
              private medicalService: MedicalService) {
    super();
  }

  ngOnInit() {
    this.getMedicalStaffList();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.handlingAreaService.getHandlingsAreas(parseInt(this.caseId)).subscribe(handlingAreas => {
      this.handlingArea = handlingAreas.find(handlingArea => handlingArea.handlingAreaType ===  HandlingAreaType.CLAIMS);
      if (!!this.handlingArea) {
        this.claimsHandler = this.handlingArea.status === HandlingAreaStatusEnum.OPEN || this.handlingArea.status === HandlingAreaStatusEnum.REOPENED;
      }
    });
    if (this.medicalAssessment.getCommitmentType() && this.serviceOrder.status === StatusTypes.COMMITTED  &&
      !this.serviceOrderType.commitmentTypes.some((obj) => {
        return obj.businessKey === this.medicalAssessment.getCommitmentType().businessKey;
    })){
      this.selectableCommitmentTypes = [this.medicalAssessment.getCommitmentType()].concat(this.serviceOrderType.commitmentTypes);
    } else {
      this.selectableCommitmentTypes = this.serviceOrderType.commitmentTypes;
    }
    this.editServiceOrder = !!this.medicalAssessment.id && !!this.medicalAssessment.commitmentType;

    this.updateMedicalPreAssessmentState();
    this.updateAssessmentTypeRadioButtons();
  }

  isValid(): boolean {
    return this.medicalAssessment.isValid();
  }

  getModel() {
    return this.medicalAssessment;
  }

  getMedicalStaffList() {
    this.medicalService.getUsersForMedicalAssessment().subscribe(result => {
      this.medicalStaff = this.mapUsersToDictionary(result);
    });
  }

  updateAssessmentTypeRadioButtons() {
    if (this._medicalAssessment.assignee === AssigneeEnum.MEDICAL_STAFF) {
      this.serviceOrderService.getServiceOrdersForCaseByType(parseInt(this.caseId), ServiceTypeEnum.MEDICAL_ASSESSMENT).subscribe(
        result => {
          this.serviceOrderList = result;
          if (this.serviceOrderList.some(e => (e.extension as MedicalAssessmentServiceOrderExtension).commitmentType && (e.extension as MedicalAssessmentServiceOrderExtension).commitmentType.businessKey === CommitmentTypes.NIGHT_SHIFT_MEDICAL_ASSESSMENT &&
            (e.extension as MedicalAssessmentServiceOrderExtension).assignee !== this.assignee.MEDICAL_ADVICE)) {
            this.nightAssessmentExist = true;
          }
          if (this.serviceOrderList.some(e => (e.extension as MedicalAssessmentServiceOrderExtension).commitmentType && (e.extension as MedicalAssessmentServiceOrderExtension).commitmentType.businessKey === CommitmentTypes.REGULAR_MEDICAL_ASSESSMENT &&
            (e.extension as MedicalAssessmentServiceOrderExtension).assignee !== this.assignee.MEDICAL_ADVICE)) {
            this.regularAssessmentExist = true;
          }
        }, error => console.log(error)
      );
    }
  }

  mapUsersToDictionary(users: User[]): DropdownDictionary[] {
    return users.map(user => {
      return new DropdownDictionary(user.id, user.firstName + ' ' + user.surname);
    });
  }

  medicalChange() {
    this.isDescriptionValid = this.medicalAssessment.validateDescription();
  }

  changeAssignee(assignee: AssigneeEnum) {
    if (this.medicalAssessment.getCommitmentType() && this.serviceOrder.status === StatusTypes.EXPECTED &&
    !this.serviceOrderType.commitmentTypes.some((obj) => {
      return obj.businessKey === this.medicalAssessment.getCommitmentType().businessKey;
    })
  ) {
    this.medicalAssessment.setCommitmentType(null);
    this.editServiceOrder = !!this.medicalAssessment.id && !!this.medicalAssessment.commitmentType;
  }

    this.medicalAssessment.assignee = assignee;
    this.updateAssessmentTypeRadioButtons();
    if (this.medicalAssessment.assignee === AssigneeEnum.MEDICAL_ADVICE ||
        this.medicalAssessment.assignee === AssigneeEnum.PRE_DEPARTURE_MEDICAL_ADVICE) {
      this.medicalAssessment.assignedMedicalStaffId = null;
      //this.medicalAssessment.setCommitmentType(CommitmentTypes.EXPECTED); // TODO
    } else {
      //this.medicalAssessment.setCommitmentType(CommitmentTypes.COMMITTED);  // TODO
    }
    this.medicalChange();
  }

  changeAssessmentType(commitmentType: CommitmentType) {
    this.medicalAssessment.commitmentType = commitmentType;
    this.medicalChange();
  }

  commitmentTypeDisabled(commitmentType: CommitmentType){
    switch (commitmentType.businessKey) {
      case CommitmentTypes.REGULAR_MEDICAL_ASSESSMENT:
          return this.editServiceOrder;
      case CommitmentTypes.LIGHT_MEDICAL_REVIEW:
          return this.nightAssessmentExist || this.regularAssessmentExist || this.editServiceOrder;
      case CommitmentTypes.NIGHT_SHIFT_MEDICAL_ASSESSMENT:
        return this.nightAssessmentExist || this.editServiceOrder;
      case CommitmentTypes.CLAIMS_MEDICAL_REVIEW:
        return !this.claimsHandler || this.editServiceOrder;
      case CommitmentTypes.PRE_DEPARTURE_REVIEW:
          return true;
      default:
        return false;
    }
  }

  get isPreDeparture() {
    return (!!this.medicalAssessment?.id && this.medicalAssessment?.assignee === AssigneeEnum.PRE_DEPARTURE_MEDICAL_ADVICE) ||
           (!this.medicalAssessment?.id && this.caseType?.code === CASE_TYPE_CODES.MEDICAL_PRE_ASSESSMENT);
  }

  get CommitmentTypes() {
    return CommitmentTypes;
  }

  private updateMedicalPreAssessmentState(): void {
    timer(100).subscribe(() => { // prevent changed-after-checked
      this.medicalAssessment.isDescriptionRequired = !this.isPreDeparture;
      if ( !this.medicalAssessment?.id && this.isPreDeparture ) {
        this.medicalAssessment.assignee = AssigneeEnum.PRE_DEPARTURE_MEDICAL_ADVICE;
        this.changeAssignee(this.medicalAssessment.assignee);
      }
    });
  }
}
