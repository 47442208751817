import { ApplicationRef, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { TaskViewModel } from '@secca/board/components/board-task/models/task-view.model';
import { TaskStateService } from '@secca/board/components/board-task/services/task-state.service';
import { CasePlansService } from '@secca/case/components/case-plans/case-plans.service';
import { PendingTasksListUpdateService } from '@secca/case/components/case-task/services/pending-tasks-list-update.service';
import { TaskChangeStatusService } from '@secca/case/components/case-task/services/task-change-status.service';
import { TaskModalModeEnum } from '@secca/case/components/case-task/task-modal-static/task-modal-static-mode.enum';
import { CaseCommunicationService } from '@secca/core/services/case-communication.service';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import { CaseValidationService } from '@secca/core/services/case-validation.service';
import { CaseService } from '@secca/core/services/case.service';
import { DialogHelperUtilService } from '@secca/core/services/dialog-helper-util.service';
import { DialogViewerServiceInterface } from '@secca/core/services/dialog-viewer-service-interface';
import { DictionaryService } from '@secca/core/services/dictionary.service';
import { InsuranceService } from '@secca/core/services/insurance.service';
import { PermissionService } from '@secca/core/services/permission.service';
import { PlanService } from '@secca/core/services/plan.service';
import { ServiceOrderService } from '@secca/core/services/service-order.service';
import { TaskService } from '@secca/core/services/task.service';
import { DIALOG_VIEWER_TOKEN } from '@secca/core/services/token';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { Case } from '@secca/shared/models/case';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import {
  HandlingAreaType,
  PermissionEnum,
  PermissionHideTypeEnum,
  TaskStatus,
  TaskType,
  UserDepartmentEnum,
  ValidationTypeEnum
} from '@secca/shared/models/enums';
import { ModalDialogConfiguration } from '@secca/shared/models/modal/modal-dialog-configuration';
import { PersonInsurance } from '@secca/shared/models/person-insurance';
import { SearchUserAndTeam } from '@secca/shared/models/searchUserAndTeam';
import { MedicalAssessmentServiceOrderExtension } from '@secca/shared/models/service-order/medical-assessment-service-order-extension';
import { SimpleIncident } from '@secca/shared/models/SimpleIncident';
import { Task } from '@secca/shared/models/task';
import { TeamDto } from '@secca/shared/models/teamDto';
import { UserDepartmentAdapter } from '@secca/shared/models/userDepartment';
import { UserDto } from '@secca/shared/models/userDto';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AutoCompleteTypeEnum } from '../drop-down-auto-complete/auto-complete-type-enum';
import { ModalDialogComponent } from '../modal-dialog/modal-dialog.component';
import { ShortcutService } from '@secca/core/services/shortcut.service';
import { MedicalPreAssessmentService } from '@secca/core/services/medical-pre-assessment.service';
import { MedicalPreAssessment } from '@secca/shared/models/medicalPreAssessment';
import {BillingCurrency} from "@secca/shared/models/billing-currency";
import {CaseHistoryEntry} from "@secca/shared/models/caseHistoryEntry";
import {CaseSummaryService} from "@secca/core/services/caseSummary.service";
import {HandlingAreaService} from "@secca/core/services/handling-area.service";
import {UserService} from "@secca/core/services/user.service";
import { CaseTypeCodes } from '@secca/shared/models/case-type';
import { CoordinationDetailsService } from '@secca/core/services/coordination-details.service';

@Component({
  selector: 'app-task-modal-common',
  template: ''
})
export abstract class TaskModalCommon {
  @Input() case: Case;
  @Input() taskViewModel: TaskViewModel;

  @Output() openServiceEditDialog: EventEmitter<any> = new EventEmitter();
  @Output() closedDialog: EventEmitter<boolean> = new EventEmitter();
  @Output() closed: EventEmitter<boolean> = new EventEmitter();
  @Output() modalTaskLoadedEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() taskStatusChanged: EventEmitter<TaskStatus> = new EventEmitter();

  task: Task;
  $personInsuranceSubscr: Subscription;

  protected acHandler: SearchUserAndTeam;
  protected saveButtonHasBeenClicked: boolean;
  protected originalTaskState: Task = new Task();
  protected teams: DropdownDictionary[];
  protected medicalAssessmentAssignedMedicalStaffId = null;
  protected endUser: CaseStakeholder;
  protected actualTimeZoneId: string;
  protected location: string;
  protected incidentCause: string;
  protected personInsurance: PersonInsurance = new PersonInsurance();
  protected profileName: string;
  protected disableSaveButtonWhenNoChanges: boolean = true;
  protected isCaseNumberValidated: boolean;
  protected isTaskTypeHandelMedicalAssessment: boolean;
  protected serviceOrderHasBeenDeleted: boolean = false;
  protected taskIsLoaded: boolean;
  protected canLeaveTask = true;
  protected originatedFromWebtask: Task;
  protected medicalPreAssessment: MedicalPreAssessment;

  abstract _constructor(): void;

  constructor(protected caseService: CaseService,
              protected taskService: TaskService,
              protected dictionaryService: DictionaryService,
              protected planService: PlanService,
              protected insuranceService: InsuranceService,
              protected casePlansService: CasePlansService,
              protected caseStateService: CaseStateService,
              protected shortcutService: ShortcutService,
              protected modalService: NgbModal,
              protected dialogHelperUtilService: DialogHelperUtilService,
              protected applicationRef: ApplicationRef,
              protected taskStateService: TaskStateService,
              protected pendingTasksListUpdateService: PendingTasksListUpdateService,
              protected caseCommunicationService: CaseCommunicationService,
              protected taskChangeStatusService: TaskChangeStatusService,
              protected userDepartmentAdapter: UserDepartmentAdapter,
              protected caseValidationService: CaseValidationService,
              protected handlingAreaService: HandlingAreaService,
              protected userService: UserService,
              protected caseSummaryService: CaseSummaryService,
              @Inject(DIALOG_VIEWER_TOKEN) protected dialogViewerService: DialogViewerServiceInterface,
              public caseLockHelperService: CaseLockHelperService,
              public permissionService: PermissionService,
              public serviceOrderService: ServiceOrderService,
              public medicalPreAssessmentService: MedicalPreAssessmentService,
              public translate: TranslateService,
              protected coordinationDetailsService: CoordinationDetailsService
  ) {
    this._constructor();
  }

  createTask() {
    this.saveButtonHasBeenClicked = true;
    this.setCanLeave(true);
    this.task.caseId = +this.case.id;
    this.task.taskType = TaskType.manual;
    this.taskService.createTask(this.task).subscribe(
      () => {
        this.informTaskHasChanged();
        this.close();
      },
      error => console.log(error),
      () => (this.saveButtonHasBeenClicked = false)
    );
  }

  updateTask() {
    this.saveButtonHasBeenClicked = true;
    this.setCanLeave(true);
    this.taskService.updateTask(this.task).subscribe(
      task => {
        // in case when the status changed the task must be moved to the different tab
        if (this.originalTaskState.status !== task.status) {
          this.taskChangeStatusService.setTaskChangeStatus();
        }
        this.originalTaskState = task;
        this.task = task;
        this.informTaskHasChanged();
        this.close();
      },
      error => console.log(error),
      () => (this.saveButtonHasBeenClicked = false)
    );
    this.pendingTasksListUpdateService.setPendingTasksHasChange();
  }

  openLeaveWarningDialog() {
    const modalRef = this.modalService.open(ModalDialogComponent);
    modalRef.componentInstance.items = [];
    modalRef.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'default-modal-header',
      title: 'case-tasks-leave-edited-task-or-go-back-and-save-pop-up',
      text: null,
      footer: 'case-validation-warning-modal-footer',
      yes: 'default-modal-dialog-yes',
      no: 'default-modal-dialog-no',
      isBody: true,
      isFooter: true,
    });

    modalRef.componentInstance.closeModalEvent.subscribe(
      closedClickingYes => {
        if (closedClickingYes) {
          this.setCanLeave(true);
          this.close();
          this.closedDialog.emit(true);
        } else {
          this.setCanLeave(false);
          this.closedDialog.emit(false);
        }
        modalRef.close();
      },
      error => {
        console.log(error);
      }
    );
  }

  resetInputfields() {
    this.task.description = '';
    this.task.userId = null;
    this.task.adtCode = null;
    this.task.title = '';
    this.task.status = null;
    this.task.dueDate = null;
    this.task.receivedDate = null;
    this.acHandler = null;
  }

  close() {
    if ( !this.canLeaveTask ) {
      return this.onTryingToLeaveEditedNotSavedTask();
    }

    this.resetInputfields();
    if ( this.closed.observers ) {
      this.closed.emit();
    }
    this.dialogHelperUtilService.closeModal();
  }

  caseHandlerChanged(acHandler: SearchUserAndTeam): void {
    if (acHandler.isTeam) {
      this.acHandler = acHandler;
      this.task.adtCode = acHandler.adtCode;
      this.task.userId = null;
    } else {
      this.acHandler = acHandler;
      this.task.userId = acHandler.id;
      this.task.adtCode = null;
    }

    this.setTaskHasChanged();
  }

  dueDateChanged(newMoment) {
    this.task.dueDate = newMoment;

    this.setTaskHasChanged();
  }

  setTaskHasChanged() {
    if (this.task?.status && this.task?.status !== this.taskViewModel?.status) {
      this.taskStatusChanged.emit(this.task?.status);
    }
    this.disableSaveButtonWhenNoChanges = false;
    this.setCanLeave(false);
  }

  informTaskHasChanged() {
    this.taskStateService.refreshAllTasksAndPendingNumberOfTasks.next();
  }

  onTryingToLeaveEditedNotSavedTask() {
    if (this.hasTaskReallyChanged()) {
      this.setCanLeave(false);
    } else {
      this.setCanLeave(true);
    }
    if (!this.canLeaveTask) {
      this.openLeaveWarningDialog();
    }
  }

  openServiceOrderModal() {
    this.openServiceEditDialog.emit(this.task.serviceOrderId);
  }

  isSMSorMMS(): boolean {
    return this.task.taskType === TaskType.sms || this.task.taskType === TaskType.mms;
  }

  trimText(text, len=50): string {
    if ( text?.length > len ) {
      return text.substring(0, len) + '...';
    }
    return text;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  get PermissionHideTypeEnum() {
    return PermissionHideTypeEnum;
  }

  get ValidationTypeEnum() {
    return ValidationTypeEnum;
  }

  get AutoCompleteTypeEnum() {
    return AutoCompleteTypeEnum;
  }

  get TaskModalModeEnum() {
    return TaskModalModeEnum;
  }

  get isUnrelatedToCase(): boolean {
    return this.case.caseNumber == undefined;
  }

  protected getSubmitButtonTitle(taskModalModeEnum: TaskModalModeEnum) {
    switch (taskModalModeEnum) {
      case TaskModalModeEnum.viewOnly:
        return 'task-modal-submit-close';
      case TaskModalModeEnum.addNew:
        return 'task-modal-submit-save';
      case TaskModalModeEnum.assignableOnly:
        return 'task-modal-submit-save';
      default:
        return 'task-modal-submit-save';
    }
  }

  protected getModalTitle(taskModalModeEnum: TaskModalModeEnum) {
    switch (taskModalModeEnum) {
      case TaskModalModeEnum.viewOnly:
        return 'task-modal-task-view-task';
      case TaskModalModeEnum.edit:
        return 'task-modal-task-edit-task';
      case TaskModalModeEnum.assignableOnly:
        return 'task-modal-task-edit-task';
      default:
        return 'task-modal-task-new-task';
    }
  }

  protected isValidated(taskModalModeEnum: TaskModalModeEnum): boolean {
    if (taskModalModeEnum === TaskModalModeEnum.viewOnly) {
      return true;
    }
    return (
      this.task.title != null &&
      this.task.title !== '' &&
      this.task.description != null &&
      this.task.description !== '' &&
      this.task.dueDate != null &&
      this.task.status != null &&
      (this.task.taskType === 'MANUAL' || this.task.taskType === undefined ? !!this.task.priority : true ) &&
      (this.acHandler?.adtCode || this.acHandler?.team?.adtCode) &&
      this.isCaseNumberValidated &&
      this.disableSaveButtonWhenNoChanges === false
    );
  }

  protected setCanLeave(canLeave: boolean): void {
    this.canLeaveTask = canLeave;
  }

  protected getTaskInformation(taskId: number): Observable<Task> {
    return this.taskService.getTask(taskId).pipe(map(
      task => {
        this.task = task;
        this.modalTaskLoadedEvent.next(true);
        this.taskIsLoaded = true;
        if (this.task.userId != null) {
          this.dictionaryService.searchUserById(this.task.userId).subscribe(
            user => {
              this.acHandler = new SearchUserAndTeam();
              this.acHandler.initializeFromSearchUser(user);
            },
            error => console.log(error)
          );
        } else {
          if (this.teams != null && this.task.adtCode != null) {
            this.initializeTeamAcHandler();
          }
        }
        if (this.task.taskType === TaskType.medical && this.task.serviceOrderId) {
          this.serviceOrderService.getServiceOrder(this.task.serviceOrderId).subscribe(
            result => {
              this.medicalAssessmentAssignedMedicalStaffId =
                (result.extension as MedicalAssessmentServiceOrderExtension).assignedMedicalStaffId;
              this.isTaskTypeHandelMedicalAssessment = this.medicalAssessmentAssignedMedicalStaffId === null;
              this.serviceOrderHasBeenDeleted = !!result.deletedOn;
            }
          );
        }
        if (this.task.taskType === TaskType.medicalPreAssessment && this.task.medicalPreAssessmentId) {
          this.medicalPreAssessmentService.getMedicalPreAssessment(this.task.medicalPreAssessmentId).subscribe(result => this.medicalPreAssessment = result);
        }
        if (!!this.task.originatedFromWebtaskId) {
          this.taskService.getTask(task.originatedFromWebtaskId).subscribe(
            value => this.originatedFromWebtask = value);
        }

        Object.assign(this.originalTaskState, this.task);

        return task;
      }
    ));
  }

  protected getHeaderInformations(seccaCase: Case) {
    if (seccaCase.caseTypeCode === CaseTypeCodes.INTERNAL_COORDINATION) {
      this.coordinationDetailsService.getCoordinationDetails(seccaCase.id).subscribe(
        details => this.case.coordinationCaseName = details.name
      );

    } else {
      this.caseService.getStakeholderForCase(seccaCase.id, 'END_USER').subscribe(
        result => {
          this.endUser = result;
          this.case.endUserName = this.endUser.person.firstName + ' ' + this.endUser.person.surname;
        },
        error => console.log(error)
      );
    }
    let actualTimeZoneId = this.case.currentTimeZone != null ? this.case.currentTimeZone : this.case.incidentTimeZone;
    this.actualTimeZoneId = actualTimeZoneId ? actualTimeZoneId : 'Europe/Copenhagen';

    this.location = this.extractLocation(this.case.incident);
    this.incidentCause = this.case.incident.incidentCause;
    if ( this.$personInsuranceSubscr ) {
      this.$personInsuranceSubscr.unsubscribe();
    }
    this.$personInsuranceSubscr = this.insuranceService.getPersonInsurance(seccaCase.id).subscribe(
      result => {
        if (result != null) {
          this.personInsurance = result;
          if (this.personInsurance.customerProfileId != null) {
            this.caseService.getCustomerProfile(this.personInsurance.customerProfileId.toString()).subscribe(
              profile => {
                if (profile != null) {
                  this.profileName = profile.profileName;
                }
              },
              error => {
                console.log(error);
              }
            );
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  protected initializeTeamAcHandler() {
    this.acHandler = new SearchUserAndTeam();
    this.acHandler.isTeam = true;
    this.acHandler.adtCode = this.task.adtCode;
    this.acHandler.teamName = this.teams.find(a => a.id === this.task.adtCode).name;
  }

  protected getHandlerInformation(user: UserDto) {
    if ( !user?.userDepartment ) {
      return;
    }
    const department = this.userDepartmentAdapter.adapt(user.userDepartment);
    if (department != null && department.id && department.id === UserDepartmentEnum.OTHER) {
      return;
    }
    this.acHandler = new SearchUserAndTeam();
    this.acHandler.isTeam = false;
    this.acHandler.id = user.id;
    this.acHandler.picture = user.picture;
    this.acHandler.fullUserName = user.firstName + ' ' + user.surname;
    this.acHandler.team = new TeamDto();
    if (user.userTeam != null) {
      this.acHandler.team.adtCode = user.userTeam.adtCode;
      this.acHandler.team.teamName = user.userTeam.teamName;
    }
    this.acHandler.initials = user.initials;
    this.setTaskHandlerId();
  }

  protected getBillingCurrency(caseId: string, handler: (billingCurrency: BillingCurrency) => void): void {
    this.planService.getBillingCurrencyForCase(Number(caseId)).subscribe(billingCurrency => {
      handler(billingCurrency);
    }, error => console.log(error));
  }

  protected getLastActionInCaseSummary(caseId: string, handler: (caseHistoryEntry: CaseHistoryEntry) => void): void {
    this.caseSummaryService.getLastActionInCaseSummary(caseId).subscribe(caseHistoryEntry => {
      if ( !!caseHistoryEntry ) {
        handler(caseHistoryEntry)
      }
    }, error => console.log(error));
  }

  protected getUser(userId: string, handler: (user: UserDto) => void): void {
    this.userService.getUserByUserId(userId).subscribe(user => {
      handler(user);
    }, error => console.log(error));
  }


  protected getPrimaryCaseHandler(caseId: string, handler: (user: UserDto, team: TeamDto) => void): void {
    if (caseId) {
      this.handlingAreaService.getHandlingsAreas(Number(caseId)).subscribe( result => {
          const caseHandlers = result.filter(area => area.handlingAreaType == HandlingAreaType.CASE_HANDLER);
          if (caseHandlers.length > 0) {
            if ( !!caseHandlers[0].user ) {
              handler(caseHandlers[0].user, null);
            }
            else {
              handler(null, caseHandlers[0].team);
            }
          }
        },
        error => console.log(error)
      );
    }
  }

  protected setTaskHandlerId() {
    if (this.task != null && this.acHandler != null) {
      this.task.userId = this.acHandler.id;
    }
  }

  private extractLocation(incident: SimpleIncident) {
    if (incident.currentCountry != null && incident.currentCountry !== '') {
      return incident.currentCity != null && incident.currentCity !== ''
        ? incident.currentCity + ', ' + incident.currentCountry
        : incident.currentCountry;
    } else {
      return incident.incidentCity !== undefined && incident.incidentCity !== ''
        ? incident.incidentCity + ', ' + incident.incidentCountry
        : incident.incidentCountry;
    }
  }

  private hasTaskReallyChanged(): boolean {
    return (
      this.originalTaskState.title !== this.task.title ||
      this.originalTaskState.description !== this.task.description ||
      !this.originalTaskState.dueDate.isSame(this.task.dueDate) ||
      this.originalTaskState.status !== this.task.status ||
      this.originalTaskState.userId !== this.task.userId ||
      this.originalTaskState.adtCode !== this.task.adtCode ||
      this.originalTaskState.priority !== this.task.priority
    );
  }

  public get isTaskChanged() : boolean {
    return this.hasTaskReallyChanged();
  }

  protected isCoordinationCase(): boolean {
    return this.case.caseTypeCode === CaseTypeCodes.INTERNAL_COORDINATION;
  }
}
