import { Injectable } from '@angular/core';
import { Adapter } from '../../interfaces/adapter';

export class AssessmentUsedInServiceOrders {
    medicalAssessmentId: number;
    serviceOrderIds: string[];
    public constructor(init?: Partial<AssessmentUsedInServiceOrders>) {
        Object.assign(this, init);
    }
}

@Injectable({
    providedIn: 'root'
})
export class AssessmentUsedInServiceOrdersAdapter implements Adapter<AssessmentUsedInServiceOrders> {
    adapt(item: any): AssessmentUsedInServiceOrders {
        return new AssessmentUsedInServiceOrders({
            medicalAssessmentId: item.medicalAssessmentId,
            serviceOrderIds: item.serviceOrderIds,
        });
    }
}
