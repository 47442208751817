import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, ReplaySubject } from 'rxjs';
import { GlobalErrorhandlerHelper } from '@secca/shared/helpers/global-errorhandler-helper';
import { CaseNotLockedError } from '@secca/shared/models/case-not-locked.error';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {


  private errorMessages = new ReplaySubject<Error | HttpErrorResponse>(1);
  private globalErrorHandlerHelper: GlobalErrorhandlerHelper;

  constructor(private injector: Injector,
              private zone: NgZone) {

    if (!environment.disableGlobalErrorModal) {
      this.globalErrorHandlerHelper = new GlobalErrorhandlerHelper(this.getErrorMessage(), injector, zone);
    }

  }

  public getErrorMessage():Observable<Error | HttpErrorResponse> {
    return this.errorMessages.asObservable();
  }

  handleError(error: Error | HttpErrorResponse) {
    if ( !(error instanceof CaseNotLockedError) ) {
      this.errorMessages.next(error);
    }

    if ( environment.logErrorToConsole) {
      console.error(error);
    } else {
      console.log(error);
    }
  }
}
