import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SimpleCaseStakeholder } from '@secca/shared/models/simpleCaseStakeholder';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss']
})
export class EmployeesComponent  {

  @Input() selectedItemId: any;
  @Input() name: string;
  @Input() disabled: boolean;
  @Output() selectedItemIdChange = new EventEmitter();
  @Input() users: SimpleCaseStakeholder[];
  @Input() isRecommended: boolean;
  @Input() showOptionalText: boolean;


  constructor() { }

  onSelectedItemIdChange() {
    this.selectedItemIdChange.emit(this.selectedItemId);
  }

  getStakeholderIcon(type: string) {
    let imageUrl;
    switch(type) {
      case 'END_USER': {
        imageUrl = '/assets/icons/End-User.svg';
         break;
      }
      case 'POLICYHOLDER': {
        imageUrl = '/assets/icons/Policy Holder.svg';
         break;
      }
      case 'REPORTER': {
        imageUrl = '/assets/icons/Reporter.svg';
        break;
      }
      case 'COMPANY': {
        imageUrl = '/assets/icons/Other.svg';
        break;
      }
      default: {
        imageUrl = '/assets/icons/Person.svg';
         break;
      }
   }
   return imageUrl;
  }

  getStakeholderText(item: SimpleCaseStakeholder): string {
    if (item.isCompany) {
      return item.companyName;
    } else {
      return item.firstName + ' ' + item.surname;
    }
  }
}
