import { Component, EventEmitter, Input, Output, SecurityContext } from '@angular/core';
import { resumeAnimation } from '../animation';
import { ContactItem, DocListItem, SolrInput } from '@secca/shared/models/SupplierSearchResponse';
import { ProviderOwnershipEnum } from '@secca/shared/models/enums';
import {
  SupplierSearchHelper
} from '@secca/case/components/case-plans/case-plans/add-service-order/search-service-provider/search-service-provider-modal/supplier-search-helper.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-supplier-info',
  templateUrl: './supplier-info.component.html',
  styleUrls: ['./supplier-info.component.scss'],
  animations: resumeAnimation
})
export class SupplierInfoComponent {
  @Input()
  supplier: DocListItem = {} as DocListItem;
  @Input()
  solrInput: SolrInput;
  @Output()
  changeSupplierElect = new EventEmitter<string>();
  @Input()
  private openStuff: boolean = false;

  callFS: boolean;
  amountOfCountriesToShow: number = 15;

  @Input()
  set callFromStakeholder(value: boolean) {
    this.callFS = value || false;
    this.openStuff = value;
  }

  constructor(protected supplierSearchHelper: SupplierSearchHelper, private sanitizer: DomSanitizer) {
  }

  select(supplierInfoComponent: SupplierInfoComponent) {
    this.changeSupplierElect.emit(supplierInfoComponent.supplier.id);
  }

  public toggle(): void {
    this.openStuff = !this.openStuff;
  }

  public isOpen(): boolean {
    return this.openStuff;
  }

  public isOpenState(): string {
    return this.isOpen() ? 'open' : 'close';
  }

  private cleanHTML(input: string): string {
    if (!input) {
      return;
    }
    // remove non html tags
    let output = input.replace(/<\/?[^>]+(>|$)/g, (match) => {
      return match.trim() === '<p>' ||
      match.trim() === '</p>' ||
      match.trim() === '<br />' ||
      match.trim() === '<li>' ||
      match.trim() === '</li>' ||
      /<strong>.*<\/strong>/.test(match) ? match : '';
    });
    // remove empty p tags
    output = output.replace(/<p>\s*<\/p>/g, '');
    return output;
  }

  getDescriptionAsHtml(description: string): any {
    return this.sanitizer.sanitize(SecurityContext.HTML, this.cleanHTML(description));
  }

  getHospitalIconPrivate(privateCompany: string) {
    return privateCompany === (ProviderOwnershipEnum.Private || ProviderOwnershipEnum.Both) ? '/assets/icons/provider/hosp_private_true_24x24.svg' : '/assets/icons/provider/hosp_private_false_24x24.svg';
  }

  getHospitalIconPublic(publicCompany: string) {
    return publicCompany === (ProviderOwnershipEnum.Public || ProviderOwnershipEnum.Both) ? '/assets/icons/provider/hosp_public_true_24x24.svg' : '/assets/icons/provider/hosp_public_false_24x24.svg';
  }

  filterOnContact(contactList: ContactItem[], contactType: string) {
    return contactList.filter(contact => contact.contact_type === contactType);
  }

  distinctContactTypes(contactList: ContactItem[]): string[] {
    return contactList.map(contact => contact.contact_type).filter(this.uniqueFilter);
  }

  uniqueFilter(value, index, self) {
    return self.indexOf(value) === index;
  }

  getSupplierScore(recommendations: string[]): { iconPath: string, tooltip: string } {
    return this.supplierSearchHelper.setIconSupplierLevel(recommendations);
  }

  getRecommendationDecor(recommendation: string): string {
    return this.supplierSearchHelper.getRecommendationDecor(recommendation);
  }

  getBlacklistFinancialMedicalScore(recommendations: string[]): { iconPath: string, tooltip: string } {
    return this.supplierSearchHelper.setIconFinancialMedicalOrBlackListed(recommendations);
  }
}
