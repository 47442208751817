import { Adapter } from '../interfaces/adapter';
import { Injectable } from '@angular/core';

export class UserTeam {
  teamName: string;
  teamNameAlt: string;
  id: string;
  adtCode: string;
  caseHandler: boolean;
  defaultChannel: string;

  public constructor(init?: Partial<UserTeam>) {
    Object.assign(this, init);
  }
}
@Injectable({
  providedIn: 'root'
})
export class UserTeamAdapter implements Adapter<UserTeam> {
  public adapt(item: any): UserTeam {
    return new UserTeam({
      teamName: item.teamName,
      teamNameAlt: item.teamNameAlt,
      id: item.id,
      adtCode: item.adtCode,
      caseHandler: item.caseHandler,
      defaultChannel: item.defaultChannel
    });
  }
}
