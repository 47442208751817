import { Component, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { MediaMatcher } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { ChangeSelectedCaseData, DataService } from '@secca/core/services/data.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalCode } from './shared/enums/countryCode';
import { CaseValidationService } from './core/services/case-validation.service';
import { SettingsService } from './core/services/settings.service';
import { NgbDropdownConfig, NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnDestroy {
  title = 'Secca';
  currentLanguage: LocalCode;
  isNew = true;

  private mobileQuery: MediaQueryList;

  private subscription: Subscription;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private dataService: DataService,
              private caseValidationService: CaseValidationService,
              private translate: TranslateService,
              private media: MediaMatcher,
              private settingsService: SettingsService,
              private popOverConfig: NgbPopoverConfig,
              private dropdownConfig: NgbDropdownConfig
    ) {
    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.isNew ) {
          const openCases = this.dataService.fetchOpenCases();
          if (openCases.size) {
            for (const openCase of openCases) {
              this.dataService.changeSelectedCase.emit({caseId: openCase} as ChangeSelectedCaseData);
              this.caseValidationService.sendVerifyCaseNumberAssigned('' + openCase);
            }
          }
        }
      }

      this.isNew = false;
    });

    switch (this.translate.getBrowserLang() as LocalCode) {
      case LocalCode.DE: {
        this.currentLanguage = LocalCode.DE;
        break;
      }
      default: {
        this.currentLanguage = LocalCode.EN;
        break;
      }
    }
    this.translate.setDefaultLang(this.currentLanguage);
    this.translate.use(this.currentLanguage);

    this.mobileQuery = media.matchMedia('(min-width: 3000px)'); // 1920px
    localStorage.setItem('zoomed', 'false');
    if (this.mobileQuery.matches) {
      localStorage.setItem('zoomed', 'true');
      document.querySelector('body').style.setProperty('zoom', '110%');
      if (this.settingsService.isZoomed) {

        dropdownConfig.popperOptions = (options) => {
          for (const modifier of options.modifiers || []) {
            if (modifier.name === 'offset' && modifier.options) {
              modifier.options.offset = ({ placement, reference, popper }) => {
                if (placement === "top" || placement === "top-left" || placement === "top-start") {
                  return [0, -110];
                } else {

                  return [0, 0];
                }
              }
            }
        }
        return options;
        };

        popOverConfig.popperOptions = (options) => {
          for (const modifier of options.modifiers || []) {
            if (modifier.name === 'offset' && modifier.options) {
              modifier.options.offset = ({ placement, reference, popper }) => {
                if (placement === 'top') {
                  return [0, -110];
                } else if (placement === 'top-start') {
                  return [0, 10];
                } else if (placement === 'end' || placement === 'right') {
                  return [0, 0];
                } else {
                  return [0,0];
                }
              }
            }
          }
          return options;
        };
      }
    } else {
      localStorage.setItem('zoomed', 'false');
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}