import { Injectable } from '@angular/core';
import { Icd10Code } from './icd10Code';
import { Adapter } from '../interfaces/adapter';

export class Icd10CodeWithStyle extends Icd10Code {
  redBoxStyle: boolean;

  public constructor(init?: Partial<Icd10CodeWithStyle>) {
    super(init);
    Object.assign(this, init);
  }
}
