export enum CaseSelectedMenuEnum {
  CaseBasics = 1,
  CaseSummary,
  CaseTask,
  CasePlan,
  CaseMedical,
  CaseEconomy,
  Stakeholders,
  Documents,
  About
}
