<div class="footer-container" [ngStyle]="footerStyle" (click)="toggle()">
  <div class="footer-show" ngbTooltip="Show environment params" placement="top" *ngIf="!shown"><img src="../../../../assets/icons/chevron-up.svg"></div>
  <div class="footer-show" ngbTooltip="Hide environment params" placement="top" *ngIf="shown"><img src="../../../../assets/icons/chevron-down.svg"></div>
</div>
<div class="footer-container" *ngIf="connectedServiceInfo && shown">
  <div class="footer-content">
    <div class="info-group info-spacing">
      <div class="info-name">Case management system ui</div>
      <div class="info-value">{{appVersion}}</div>
    </div>
    <ng-container *ngFor="let serviceInfo of connectedServiceInfo">
      <div class="info-group info-spacing">
        <div class="info-name">{{serviceInfo.name}}</div>
        <div class="info-value">{{serviceInfo.version}}</div>
      </div>
    </ng-container>
  </div>
</div>
