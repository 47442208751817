<div class="match-overview">
  <div class="right-box" *ngIf="policy">
    <div class="match" *ngFor="let match of policy.possibleMatches">
      <div class="indicator clearfix" [ngClass]="match.quality === MatchQuality.SUCCESS ? 'success' : 'failure'"></div>
      <div class="match-parameter">{{ 'policy-lookup-' + match.parameter | translate }}</div>
    </div>
  </div>
  <div class="right-box" *ngIf="policy">
    <div class="match match-bold">{{ getIntegrationType(policy.lookupExecution.type) }}</div>
    <div class="match">{{ getIntegrationName(policy.lookupExecution) }}</div>
    <div *ngIf="policy.lookupExecution?.matchedBy" class="match">(Matched by: {{ policy.lookupExecution.matchedBy }})</div>
    <div class="match">{{ policy.lookupExecution.lookupTime | date: "dd MMM yyyy HH:mm" }}</div>
  </div>
</div>

<div class="row">
  <div class="result-field-list col-6" *ngIf="policy">
    <div class="result-fields" *ngIf="policy.product && policy.product.customerProductId">
      <div class="title">{{'policy-lookup-product-name' | translate}}</div>
      <div class="value"><span class="text">{{ policy.product.customerProductName }} ({{ policy.product.customerProductId }})</span></div>
    </div>
    <div class="result-fields" *ngIf="!policy.product || !policy.product.customerProductId">
      <div class="value"><span class="text">{{ 'policy-lookup-no-product-found' | translate }}</span></div>
    </div>
    <div class="result-fields" *ngIf="policy.insuranceLevel">
      <div class="title" *ngIf="policy.insuranceType == policyInsuranceType.REGULAR">{{'policy-lookup-insurance-level' | translate}}</div>
      <div class="title" *ngIf="policy.insuranceType == policyInsuranceType.CARD">{{'policy-lookup-card-level' | translate}}</div>
      <div class="value"><span class="text">{{ policy.insuranceLevel }}</span></div>
    </div>
    <div class="result-fields" *ngIf="policy.insuranceType && policy.insuranceType === policyInsuranceType.CARD">
      <div class="title">{{'policy-lookup-percentage-paid-with-card' | translate}}</div>
      <div class="value"><span class="text">{{ policy.percentagePaidWithCard }}</span></div>
    </div>
    <div class="result-fields" *ngIf="policy.product && policy.product.termsName">
      <div class="title">{{'policy-lookup-policy-terms' | translate}}</div>
      <div class="value"><span class="text">{{ policy.product.termsName }}</span></div>
    </div>
    <div class="result-fields" *ngIf="policy.product.version">
      <div class="title">{{'policy-lookup-policy-version' | translate}}</div>
      <div class="value"><span class="text">{{ policy.product.version }}</span></div>
    </div>
    <div class="result-fields" *ngIf="policy.insurancePeriodStart">
      <div class="title">{{'policy-lookup-insurance-period-start' | translate}}</div>
      <div class="value"><span class="text">{{ policy.insurancePeriodStart | date:'yyyy-MM-dd' }}</span></div>
    </div>
    <div class="result-fields" *ngIf="policy.insurancePeriodEnd">
      <div class="title">{{'policy-lookup-insurance-period-end' | translate}}</div>
      <div class="value"><span class="text">{{ policy.insurancePeriodEnd | date:'yyyy-MM-dd' }}</span></div>
    </div>
    <div class="result-fields">
      <div class="title">{{'policy-lookup-policy-number' | translate}}</div>
      <div class="value"><span class="text">{{ policy.policyNumber }}</span></div>
    </div>
    <div class="result-fields" *ngIf="policy.geographicalCoverageArea">
      <div class="title">{{'policy-lookup-geographical-coverage-area' | translate}}</div>
      <div class="value"><span class="text">{{ policy.geographicalCoverageArea }}</span></div>
    </div>
    <div class="result-fields" *ngIf="policy.product && policy.product.coveragePeriodWeeks">
      <div class="title">{{'policy-lookup-coverage-period-weeks' | translate}}</div>
      <div class="title" *ngIf="policy.product && !policy.product.coveragePeriodFromLookup">{{'policy-lookup-coverage-period-weeks' | translate}} {{'policy-lookup-coverage-period-from-onepoint' | translate}}</div>
      <div class="title" *ngIf="policy.product && policy.product.coveragePeriodFromLookup">{{'policy-lookup-coverage-period-weeks' | translate}} {{'policy-lookup-coverage-period-from-lookup' | translate}}</div>
      <div class="value"><span class="text">{{ policy.product.coveragePeriodWeeks }}</span></div>
    </div>
    <div class="result-fields" *ngIf="policy.product && policy.product.coveragePeriodDays">
      <div class="title" *ngIf="policy.product && !policy.product.coveragePeriodFromLookup">{{'policy-lookup-coverage-period-days' | translate}} {{'policy-lookup-coverage-period-from-onepoint' | translate}}</div>
      <div class="title" *ngIf="policy.product && policy.product.coveragePeriodFromLookup">{{'policy-lookup-coverage-period-days' | translate}} {{'policy-lookup-coverage-period-from-lookup' | translate}}</div>
      <div class="value"><span class="text">{{ policy.product.coveragePeriodDays }}</span></div>
    </div>
    <div class="result-fields" *ngIf="policy.product && policy.product.primaryPurposeOfTravel">
      <div class="title">{{'policy-lookup-travel-purpose' | translate}}</div>
      <div class="value"><span class="text">{{ policy.product.primaryPurposeOfTravel }}</span></div>
    </div>
    <ng-container *ngIf="policy.extensions">
      <div *ngFor="let extension of policy.extensions" class="result-fields">
        <div class="title">{{ 'policy-lookup-extension' | translate}}</div>
        <div *ngIf="extension.extensionPeriodStart" class="value"><span class="text">{{ extension.extensionPeriodStart | date:'yyyy-MM-dd' }}
          - {{ extension.extensionPeriodEnd | date:'yyyy-MM-dd' }}</span></div>
        <div *ngIf="extension.extensionPeriodInDays" class="value"><span
          class="text">{{ extension.extensionPeriodInDays }} {{ 'policy-lookup-days' | translate }}</span></div>
        <div *ngIf="extension.extensionPeriodInWeeks" class="value"><span
          class="text">{{ extension.extensionPeriodInWeeks }} {{ 'policy-lookup-weeks' | translate }}</span></div>
      </div>
    </ng-container>

    <div *ngIf="policy.excess && policy.excess.length" class="result-fields">
      <div class="title">{{ 'policy-lookup-excess' | translate }}</div>
      <ng-container *ngFor="let excess of policy.excess">
        <div class="value"><span class="text">{{ excess.coverageId }}: {{ excess.excessAmount | number }}</span></div>
      </ng-container>
    </div>
    <div *ngIf="policy.specialRemarks" class="result-fields">
      <div class="title">{{ 'policy-lookup-remarks' | translate }}</div>
      <ng-container *ngFor="let remark of policy.specialRemarks">
        <div class="value"><span class="text">{{ remark }}</span></div>
      </ng-container>
    </div>

  </div>
  <div class="result-field-list col-4" *ngIf="policy">
    <app-policy-lookup-person [person]="policy.policyHolder" [title]="'policy-lookup-policyholder'"></app-policy-lookup-person>

    <div class="result-fields" *ngIf="policy.coInsured">
      <div class="title">{{'policy-lookup-co-insured' | translate}}</div>
      <div class="value"><span class="text">{{ policy.coInsured }}</span></div>
    </div>

    <ng-container *ngFor="let person of policy.coveredPersons">
      <app-policy-lookup-person [person]="person" [title]="'policy-lookup-covered-person'"></app-policy-lookup-person>
    </ng-container>
    <div *ngIf="policy.supplements && policy.supplements.length" class="result-fields">
      <div class="title">{{ 'policy-lookup-supplements' | translate }}</div>
      <ng-container *ngFor="let supplement of policy.supplements">
        <div class="value"><span class="text">{{ supplement.supplementDescription }}</span></div>
        <div class="value"><span class="text">
          {{ supplement.supplementPeriodStart | date:'yyyy-MM-dd' }} - {{ supplement.supplementPeriodEnd | date:'yyyy-MM-dd' }}
        </span></div>
        <div class="value"><span
          class="text">{{ supplement.supplementInsuranceCurrency }} {{ supplement.supplementInsuranceSum | number }}</span></div>
      </ng-container>
    </div>
  </div>

  <div class="coverages" *ngIf="policy?.product?.coverages?.length">
    <div class="coverages-limits-title">{{'policy-lookup-coverages-limits' | translate}}</div>
    <app-coverage-view [coveragesBasic]="basicCoverages()" [coveragesSupplement]="supplementCoverages()" [personInsurance]="{}" [fixedHeight]="false"></app-coverage-view>
  </div>

</div>
