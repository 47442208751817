import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';

export class SupplierProfile {
  id: number;
  supplierProfileCode: string;
  supplierProfileName: string;
  providerId: number;
  creditorNumber: string;
  creditorName: string;
  creditorCurrency: string;
  networkManagerId: string;
  networkManagerFirstname: string;
  networkManagerSurname: string;
  networkManagerEmail: string;
  networkManagerPhone: string;
  startDate: Date;
  endDate: Date;
  type: number;
  typeName: string;
  partnerSupplierProfileId: number;

  public constructor(init?: Partial<SupplierProfile>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
export class SupplierProfileAdapter implements Adapter<SupplierProfile> {
  adapt(item: any): SupplierProfile {
    const endDate = item.endDate ? new Date(item.endDate) : null;
    return new SupplierProfile({
      id: item.id,
      supplierProfileCode: item.supplierProfileCode,
      supplierProfileName: item.supplierProfileName,
      providerId: item.providerId,
      creditorNumber: item.creditorNumber,
      creditorName: item.creditorName,
      creditorCurrency: item.creditorCurrency,
      networkManagerId: item.networkManagerId,
      networkManagerFirstname: item.networkManagerFirstname,
      networkManagerSurname: item.networkManagerSurname,
      networkManagerEmail: item.networkManagerEmail,
      networkManagerPhone: item.networkManagerPhone,
      startDate: new Date(item.startDate),
      endDate,
      type: item.type,
      partnerSupplierProfileId: item.partnerSupplierProfileId,
    });
  }
}
