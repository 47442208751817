  <div class="w-100 pt-3 ps-3 pe-3 header">
    <div class="float-start">{{(isMedicalPreAssessment ? "medical-comment-new" : "medical-comment-admission-new") | translate}}</div>
    <div class="float-end mt-2 close" role="button"><img (click)="close()" src="/assets/icons/Close Black.svg"></div>
  </div>
  <div class="w-100 pt-2 ps-3 pe-3 content">
    <textarea name="textarea" [placeholder]="(isMedicalPreAssessment ? 'medical-pre-assessment-comment-placeHolder' : 'medical-comment-placeHolder')| translate" class="note-text" maxlength="500" [(ngModel)]="medicalNote.noteText" (keyup)="textChanged()" ></textarea>
  </div>
  <div class="w-100 pt-2 ps-3 pe-3 bottom">
    <span *ngIf="!isMedicalPreAssessment"><input type="checkbox" (click)="changeNoteType()" /> {{"medical-admission-note" | translate}}</span>
    <button class="add-comment-button primary-button" [disabled]="noteTextValid" (click)="addComment()">{{"medical-comment-add" | translate}}</button>
  </div>

