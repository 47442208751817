import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class AuditlogService extends BaseService {

  constructor(private http: HttpClient,
              private settingsService: SettingsService) {
    super(settingsService);
  }

  public createAuditlog(auditlogText: string): Observable<string> {
    return this.http.post<string>(this.baseURL + 'auditlog/createAuditlog', auditlogText, {
      headers: this.jsonHeaders
    });
  }
}
