import { Subject, Subscription } from 'rxjs';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { cloneDeep } from 'lodash-es';
import { CustomerProfile } from '@secca/shared/models/customerProfile';
import { DictionaryService } from '@secca/core/services/dictionary.service';
import { CaseService } from '@secca/core/services/case.service';
import { CustomerProfileDropdown } from './customer-profile-search';
import { CustomerProfileIdsForSearch } from '@secca/board/components/board-task/models/task-filter-request.model';

@Component({
  selector: 'app-multiple-select-customer-profile',
  templateUrl: './multiple-select-customer-profile.component.html',
  styleUrls: ['./multiple-select-customer-profile.component.scss'],
  animations: [
    trigger('toggleChecked', [
      state('checked', style({
        opacity: 1
      })),
      state('unchecked', style({
        opacity: 0
      })),
      transition('unchecked => checked', [
        animate('400ms', keyframes([
          style({opacity: 0.5, boxShadow: '0 0 0 2px #23AFDC', offset: 0.5}),
          style({opacity: 1, boxShadow: '0 0 0 0px #23AFDC', offset: 1})
        ]))
      ]),
      transition('checked => unchecked', [
        animate('300ms', keyframes([
          style({opacity: 0.5, transform: 'scale(0)', offset: 0.5}),
          style({opacity: 0, offset: 1})
        ]))
      ]),
    ])
  ]
})

@AutoUnsubscribe
export class MultipleSelectCustomerProfileComponent implements OnInit {

  @ViewChildren('dropdownCheckbox') dropdownValue: QueryList<ElementRef>;
  @ViewChild('tableText') tableText: ElementRef;
  @Input() maxElements: number;
  @Input() name: string;
  @Input() selectedItemsCustomerProfiles: CustomerProfileIdsForSearch[] = [];
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() recommended: boolean;
  @Output() selectedItemsChange = new EventEmitter();

  selectedItems: CustomerProfileDropdown[] = [];
  isRequired: boolean;
  showDropdown: boolean;
  dropdownValueList: CustomerProfileDropdown[];
  private wasInside: boolean;
  filteringText = new Subject<string | null>();
  customerProfileDropdown: CustomerProfileDropdown[] = [];
  teams: any;
  debounceSelectedCustomerProfilesChanged: any;

  $filteringTextSubscr: Subscription;

  constructor(private caseService: CaseService,
              private dictionaryService: DictionaryService ) {

    this.$filteringTextSubscr = this.filteringText.subscribe(newTerm => {
      this.getDropdownValues(newTerm);
    });
  }

  ngOnInit() {
    this.dictionaryService.getAllTeamsList().subscribe(result => {
      if (result != null) {
        this.teams = result;
      }
    });
    this.caseService.getCustomerProfilesForDropDown().subscribe(result => {
      this.customerProfileDropdown = result;
      if(this.selectedItemsCustomerProfiles) {
        this.customerProfileDropdown.forEach(item => {
          item.customerProfileSelected = this.selectedItemsCustomerProfiles.find((_item) =>  _item.customerProfileId.toString() == item.profileId ) !== undefined;
          if(item.customerProfileSelected) {
            this.selectedItems.push(item);
          }
        });
      } else {
        this.selectedItemsCustomerProfiles = [];
      }
      this.getDropdownValues('');
    });
    this.filteringText = null;
  }

  clearSelectedCustomerProfiles() {
    this.selectedItems = [];
    this.selectedItemsCustomerProfiles = [];
    this.customerProfileDropdown.forEach(item => item.customerProfileSelected = false );
    this.dropdownValueList.forEach(item => item.customerProfileSelected = false );
    this.emitCustomerProfiles();
  }

  toggleShowDropdown(event: Event) {
    this.filteringText = null;
    event.preventDefault();
    if (!this.disabled) {
      this.showDropdown = !this.showDropdown;
      if (this.showDropdown) {
        setTimeout(() => {
            if(!!this.tableText) {
              this.tableText.nativeElement.focus();
            }
          },
          100);
        this.getDropdownValues('');
      } else {
        this.showDropdown = false;
      }
    }
  }

  getProfileImagesUrl(value: string) {
    return this.caseService.getBrandImageUrl(value);
  }

  getTeamName(value: string) {
    if (this.teams == null) {
      return '';
    }
    return this.teams.find(x => x.adtCode === value).teamName;
  }

  clickSelectItem(event: Event, row: CustomerProfileDropdown) {
    event.preventDefault();
    if (row.customerProfileSelected) {
      row.customerProfileSelected = false;
      const index = this.selectedItems.findIndex(e => { return e.profileId === row.profileId; })
      const indexA = this.selectedItemsCustomerProfiles.findIndex(e => { return e.customerProfileId === +row.profileId; })
      if (index !== -1) { this.selectedItems.splice(index, 1); }
      if (indexA !== -1) { this.selectedItemsCustomerProfiles.splice(indexA, 1); }
      this.emitCustomerProfiles();
    } else {
      row.customerProfileSelected = true;
      this.selectedItems.push(row);
      this.selectedItemsCustomerProfiles.push({ 'customerProfileId': +row.profileId, 'isGroupProfile': row.isGroupProfile });
      this.emitCustomerProfiles();
    }
  }

  emitCustomerProfiles() {
    clearTimeout(this.debounceSelectedCustomerProfilesChanged);
    this.debounceSelectedCustomerProfilesChanged = setTimeout(() => {
      this.selectedItemsChange.emit(this.selectedItemsCustomerProfiles);
    }, 500);
  }


  @HostListener('keydown.esc') onEsc() {
    this.showDropdown = false;
  }

  @HostListener('click')
  clickInside() {
    this.wasInside = true;
  }

  sortDropdownList() {
    this.dropdownValueList.sort((a,b) => a.customerProfileSelected === true ? -1 : (b.customerProfileSelected === true ? 1 : a.profileName.localeCompare(b.profileName)));
  }

  @HostListener('document:click')
  clickOut() {
    if (!this.wasInside) {
      this.showDropdown = false;
    }
    this.wasInside = false;
  }

  arrowDownSearchTextPressed(event: Event) {
    event.preventDefault();
    if (document.activeElement.getAttribute('class').includes('search-text')) {
      this.dropdownValue.first.nativeElement.focus();
    }
  }

  arrowItemPressed(event: Event, up: boolean) {
    const divElement = event.target as HTMLDivElement;
    if (up) {
      if (divElement.previousElementSibling) {
        (divElement.previousElementSibling as HTMLDivElement).focus();
      } else {
        this.tableText.nativeElement.focus();
      }
    } else {
      if (divElement.nextElementSibling) {
        (divElement.nextElementSibling as HTMLDivElement).focus();
      }
    }
  }

  focusCurrent(event: Event) {
    const divElement = event.target as HTMLDivElement;
    divElement.focus();
  }

  private getDropdownValues(term: string | null) {
    this.customerProfileDropdown.forEach(item => {
      if(this.selectedItemsCustomerProfiles) {
        item.customerProfileSelected = this.selectedItemsCustomerProfiles.find((_item) => _item.customerProfileId.toString() == item.profileId ) !== undefined;
      }
    });

    this.dropdownValueList = this.customerProfileDropdown;
    this.sortDropdownList();
  }

  searchDropdownValues(term: string | null) {
    this.dropdownValueList = this.customerProfileDropdown.filter(cu => cu.profileName.toLowerCase().indexOf(term.toLowerCase()) >= 0);
    this.sortDropdownList();
  }

  deselectAll() {
    this.selectedItems = [];
    this.emitCustomerProfiles();
  }
}
