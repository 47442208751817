import {SubAirwayBill} from '@secca/case/components/case-plans/case-plans/add-service-order/models/subAirwayBill';
import {CommitmentTypes, StatusTypes} from '@secca/shared/models/enums';
import {SubTravellers} from '@secca/case/components/case-plans/case-plans/add-service-order/models/subTravellers';
import {ServiceOrderExtension} from './service-order-extension';
import {SubCoffinUrn} from '@secca/case/components/case-plans/case-plans/add-service-order/models/subCoffinUrn';
import {SubFuneralHomes} from '@secca/case/components/case-plans/case-plans/add-service-order/models/subFuneralHomes';
import {SubRemarksToSupplier} from '@secca/case/components/case-plans/case-plans/add-service-order/models/subRemarksToSupplier';
import {SubTravelInfo, SubTravelInfoAdapter} from '@secca/case/components/case-plans/case-plans/add-service-order/models/subTravelInfo';
import {SubReference} from '@secca/case/components/case-plans/case-plans/add-service-order/models/subReference';
import {SubStakeholder, SubStakeholderAdapter} from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholder';
import {SubStakeholders} from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholders';
import {RegularFlightType} from '@secca/case/components/case-plans/case-plans/add-service-order/models/enums';
import {
  SubRemarksRulesAndFees,
  SubRemarksRulesAndFeesAdapter
} from '@secca/case/components/case-plans/case-plans/add-service-order/models/subRemarksRulesAndFees';
import {SubRemarksFromSupplier} from '@secca/case/components/case-plans/case-plans/add-service-order/models/subRemarksFromSupplier';
import {Location} from '@secca/shared/models/location';
import * as moment from 'moment-timezone';
import {MomentHelperService} from '@secca/core/services/moment-helper.service';
import { CommonServiceOrderExtension } from './CommonServiceOrderExtension.component';
import { CommitmentType, CommitmentTypeAdapter } from '../commitment-type';

export class RegularFlightNonMedicalServiceOrderExtension extends CommonServiceOrderExtension implements ServiceOrderExtension {
  id: number;
  type: RegularFlightType;
  reference: SubReference;
  coffinUrn: SubCoffinUrn;
  funeralHomes: SubFuneralHomes;
  remarksToSupplier: SubRemarksToSupplier;
  remarksFromSupplier: SubRemarksFromSupplier;
  commitmentType: CommitmentType;
  airwayBill: SubAirwayBill;


  expectedStakeholders: SubStakeholder[];
  expectedSubStakeholders: SubStakeholders;
  expectedTravelInfo: SubTravelInfo;
  expectedSubTravellers: SubTravellers;

  confirmationStakeholders: SubStakeholder[];
  confirmationSubStakeholders: SubStakeholders;
  confirmationTravelInfo: SubTravelInfo[];
  confirmationSubTravellers: SubTravellers;

  remarksRulesAndFees: SubRemarksRulesAndFees;

  // Only used for display purposes in plans
  displayFlightLegId = 0;
  displayDurationId: number;

  // TODO: Delete this?? Is used in CaseEconomyServiceOrdersComponent.getSupplierId(serviceOrder)??
  supplierId: number;
  // TODO: Delete this?? Is used in RegularFlightNonMedicalServiceOrderHoverComponent.onRefresh()??
  stakeholderIds: string[];

  public constructor(init?: Partial<RegularFlightNonMedicalServiceOrderExtension>) {
    super();
    Object.assign(this, init);
    if (!init) {
      this.init();
    }
  }

  isPreviewGopValid(): boolean {
     throw new Error("Gop preview not supported for this service order type");
  }

  isValidExpectation(): boolean {
    if (this.type === RegularFlightType.REGULAR_PASSENGERS) {
      return (
        this.expectedSubStakeholders.stakeholders.length > 0 &&
        this.expectedSubStakeholders.stakeholders[0].stakeholderId != null &&
        this.expectedSubTravellers.flightLegs.length > 0 &&
        this.expectedSubTravellers.flightLegs[0].isValidExpectation() &&
        this.remarksToSupplier.isValid()
      );
    } else {
      return (
        this.reference.isValid() &&
        this.expectedSubStakeholders.stakeholders.length > 0 &&
        this.expectedSubStakeholders.stakeholders[0].stakeholderId != null &&
        this.expectedSubTravellers.flightLegs.length > 0 &&
        this.expectedSubTravellers.flightLegs[0].isValidExpectation() &&
        this.coffinUrn.isValid() &&
        this.funeralHomes.isValid()
      );
    }
  }

  isValid(): boolean {
    if (this.type === RegularFlightType.REGULAR_PASSENGERS) {
      return (
        !!this.commitmentType &&
        this.confirmationSubStakeholders.stakeholders.length > 0 &&
        this.confirmationSubStakeholders.stakeholders[0].stakeholderId != null &&
        this.confirmationSubTravellers.flightLegs.length > 0 &&
        this.confirmationSubTravellers.flightLegs[0].isValid(true) &&
      this.remarksRulesAndFees.isValid() &&
        this.remarksToSupplier.isValid() &&
        this.confirmationSubTravellers.isValid()
      );
    } else {
      return (
        !!this.commitmentType &&
        this.reference.isValid() &&
        this.confirmationSubStakeholders.stakeholders.length > 0 &&
        this.confirmationSubStakeholders.stakeholders[0].stakeholderId != null &&
        this.confirmationSubTravellers.flightLegs.length > 0 &&
        this.confirmationSubTravellers.flightLegs[0].isValid(true) &&
        this.remarksRulesAndFees.isValid() &&
        this.coffinUrn.isValid() &&
        this.funeralHomes.isValid() &&
        this.remarksFromSupplier.isValid() &&
        this.confirmationSubTravellers.isValid()
      );
    }
  }

  init() {
    this.type = RegularFlightType.REGULAR_PASSENGERS;
    this.reference = new SubReference();
    this.expectedSubStakeholders = new SubStakeholders();
    this.expectedStakeholders = this.expectedSubStakeholders.stakeholders;
    this.expectedSubTravellers = new SubTravellers();
    this.expectedTravelInfo = this.expectedSubTravellers.flightLegs[0];
    this.confirmationSubStakeholders = new SubStakeholders();
    this.confirmationStakeholders = this.confirmationSubStakeholders.stakeholders;
    this.confirmationSubTravellers = new SubTravellers();
    this.confirmationTravelInfo = this.confirmationSubTravellers.flightLegs;
    this.coffinUrn = new SubCoffinUrn();
    this.funeralHomes = new SubFuneralHomes();
    this.remarksToSupplier = new SubRemarksToSupplier();
    this.commitmentType = null;
    this.airwayBill = new SubAirwayBill();
    this.remarksRulesAndFees = new SubRemarksRulesAndFees();
    this.remarksFromSupplier = new SubRemarksFromSupplier();
  }

  setCommitmentType(commitmentType: CommitmentType) {
    this.commitmentType = commitmentType;
  }

  getCommitmentType(status? : StatusTypes): CommitmentType {
    if (!status) {
      return this.commitmentType;
    }
    return status == StatusTypes.EXPECTED || status == StatusTypes.CANCELLED ? this.commitmentType : null;
  }

  getStartDate(status: StatusTypes): moment.Moment {
    return this.getStartTravelInfo(status).departureDate || this.getStartTravelInfo(StatusTypes.EXPECTED).departureDate;
  }

  getStartLocation(status: StatusTypes): Location {
    return this.getStartTravelInfo(status).departureFrom;
  }

  isMultipleDurations(): boolean {
    return this.expectedSubTravellers.flightLegs.length > 1;
  }

  getEndLocation(status: StatusTypes): Location {
    return this.getEndTravelInfo(status).arrivalAt;
  }

  hasMedicalEscort(): boolean {
    return false;
  }

  hasCoTravellers(status: StatusTypes): boolean {
    return this.getCoTravellers(status).length > 1;
  }

  private getCoTravellers(status: StatusTypes): SubStakeholder[] {
    if (status === StatusTypes.COMMITTED || status == StatusTypes.CANCELLED) {
      return this.confirmationStakeholders;
    } else {
      return this.expectedStakeholders;
    }
  }


  private getStartTravelInfo(status: StatusTypes) {
    if (status === StatusTypes.COMMITTED || status == StatusTypes.CANCELLED){
      return this.confirmationTravelInfo[0];
    } else {
      return this.expectedTravelInfo;
    }
  }

  private getEndTravelInfo(status: StatusTypes) {
    if (status === StatusTypes.COMMITTED || status == StatusTypes.CANCELLED){
      return this.confirmationTravelInfo[this.confirmationTravelInfo.length - 1];
    } else {
      return this.expectedTravelInfo;
    }
  }

  getEndDate(status: StatusTypes): moment.Moment {
    return this.getEndTravelInfo(status).arrivalDate ? this.getEndTravelInfo(status).arrivalDate :  MomentHelperService.endOfCaseWorkerDay(this.getStartDate(status));
  }

  getSelectedStakeholderIds(status?: StatusTypes): string[] {
    if (status === StatusTypes.COMMITTED || status == StatusTypes.CANCELLED) {
      return this.confirmationSubStakeholders.stakeholders.map(subStakeholder => subStakeholder.stakeholderId);
    } else {
      return this.expectedSubStakeholders.stakeholders.map(subStakeholder => subStakeholder.stakeholderId);
    }
  }
}

export class RegularFlightNonMedicalServiceOrderExtensionAdapter {
  adapt(item?: Partial<RegularFlightNonMedicalServiceOrderExtension>): RegularFlightNonMedicalServiceOrderExtension {
    const subStakeholderAdapter = new SubStakeholderAdapter();
    const subTravelInfoAdapter = new SubTravelInfoAdapter();
    const commitmentTypeAdapter: CommitmentTypeAdapter = new CommitmentTypeAdapter();
    const result = this.setStakeholdersConfigByType(new RegularFlightNonMedicalServiceOrderExtension({
      id: item.id,
      type: item.type ? item.type : RegularFlightType.REGULAR_PASSENGERS,
      reference: item.reference ? new SubReference(item.reference) : new SubReference(),
      expectedStakeholders: item.expectedStakeholders == null ? [] : item.expectedStakeholders.map(subStakeholderAdapter.adapt),
      confirmationStakeholders: item.confirmationStakeholders == null ? [] : item.confirmationStakeholders.map(subStakeholderAdapter.adapt),
      expectedTravelInfo: item.expectedTravelInfo == null ? new SubTravelInfo() : subTravelInfoAdapter.adapt(item.expectedTravelInfo),
      confirmationTravelInfo: item.confirmationTravelInfo == null ? [] : item.confirmationTravelInfo.map(subTravelInfoAdapter.adapt),
      coffinUrn: item.coffinUrn ? new SubCoffinUrn(item.coffinUrn) : new SubCoffinUrn(),
      funeralHomes: item.funeralHomes ? new SubFuneralHomes(item.funeralHomes) : new SubFuneralHomes(),
      remarksToSupplier: item.remarksToSupplier ? new SubRemarksToSupplier(item.remarksToSupplier) : new SubRemarksToSupplier(),
      remarksFromSupplier: item.remarksFromSupplier ? new SubRemarksFromSupplier(item.remarksFromSupplier) : new SubRemarksFromSupplier(),
      commitmentType:
        item.commitmentType != null && item.commitmentType.businessKey !== CommitmentTypes.EXPECTED ? item.commitmentType : null, // TODO new SubCommitmentType(CommitmentTypes.BOOKING),
      airwayBill: item.airwayBill == null ? new SubAirwayBill() : item.airwayBill,
      remarksRulesAndFees: item.remarksRulesAndFees == null ? new SubRemarksRulesAndFees() :
        new SubRemarksRulesAndFeesAdapter().adapt(item.remarksRulesAndFees)
    }));

    result.expectedSubStakeholders = new SubStakeholders();
    result.expectedSubStakeholders.stakeholders = result.expectedStakeholders;
    result.confirmationSubStakeholders = new SubStakeholders();
    result.confirmationSubStakeholders.stakeholders = result.confirmationStakeholders;
    result.expectedSubTravellers = new SubTravellers();
    result.expectedSubTravellers.flightLegs[0] = result.expectedTravelInfo;
    result.confirmationSubTravellers = new SubTravellers();
    result.confirmationSubTravellers.flightLegs = result.confirmationTravelInfo;

    return result;
  }

  setStakeholdersConfigByType(extension: RegularFlightNonMedicalServiceOrderExtension) {
    if (extension.type === RegularFlightType.REGULAR_PASSENGERS) {
      extension.reference = new SubReference();
      extension.coffinUrn = new SubCoffinUrn();
      extension.funeralHomes = new SubFuneralHomes();
    }

    return extension
  }
}


