import { Component, Inject, ViewChild } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { DialogBoundryService } from "@secca/core/services/dialog-boundry.service";
import { DialogHelperUtilService } from "@secca/core/services/dialog-helper-util.service";
import { DialogMinimizableDirective } from "@secca/shared/directives/dialog-minimizable.directive";
import { CaseHistoryType } from "@secca/shared/enums/case-history-type";
import { MinimizableDialogType } from "@secca/shared/enums/minimizable-dialog-type-enum";
import { CaseSummaryEmailFaxAdditionalInfo, IAdditionalInfo } from "@secca/shared/interfaces/additional-info";
import { IMinimizable } from "@secca/shared/interfaces/minimizable";
import { CaseSummaryEmailFaxDialogData } from "./case-summary-email-fax-dialog-data.component";
import { CaseSummaryEmailFaxModalComponent } from "../case-summary-email-fax-modal/case-summary-email-fax-modal.component";
import { IKeyboardEnabled } from "@secca/shared/interfaces/keyboard-enabled";

@Component({
    selector: 'app-case-summary-email-fax-dialog',
    templateUrl: './case-summary-email-fax-dialog.component.html',
    styleUrls: ['./case-summary-email-fax-dialog.component.scss'],
  })
  export class CaseSummaryEmailFaxDialogComponent implements IAdditionalInfo, IMinimizable, IKeyboardEnabled {
    @ViewChild(DialogMinimizableDirective) minimizedDirective: DialogMinimizableDirective;
    @ViewChild(CaseSummaryEmailFaxModalComponent) wrappedInstance: CaseSummaryEmailFaxModalComponent;

    data: CaseSummaryEmailFaxDialogData;

    constructor(private dialogRef: MatDialogRef<CaseSummaryEmailFaxDialogComponent>,
                @Inject(MAT_DIALOG_DATA) data: CaseSummaryEmailFaxDialogData,
                private dialogHelperUtilService: DialogHelperUtilService,
                public dialogBoundryService: DialogBoundryService) {
              
      this.data = data;
    }

    close() {
      this.dialogHelperUtilService.close(this.dialogRef, this.getAdditionalInfo()?.caseId);
    }

    keyboardClose(): void {
      this.wrappedInstance.close();
    }

    minimize() {
      this.minimizedDirective.minimize();
    }

    getMinimizeType(): MinimizableDialogType {
      return this.minimizedDirective.appDialogMinimizable;
    }

    getAdditionalInfo(): CaseSummaryEmailFaxAdditionalInfo {

      return {
        caseId: +this.data.entry.caseId,
        hoverTitle: this.data.caseNumber ? this.data.caseNumber : '',
        hoverText: this.isEmail() ? 'View Email' : 'View Fax',
        caseHistoryType: this.data?.entry?.caseHistoryType
      };
    }

    get MinimizableDialogType() {
      return MinimizableDialogType;
    }

    private isEmail(): boolean {      
      switch ( this.data?.entry?.caseHistoryType ) {
        case CaseHistoryType.EMAIL:
        case CaseHistoryType.EMAIL_RECEIVE:
        case CaseHistoryType.EMAIL_UPDATE:
          return true;

        default:
          return false;  
    }
  }
}
