import { UpdatePersonInsurance } from './../../shared/models/update-person-insurance';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { BehaviorSubject, Observable, ReplaySubject, Subject, throwError } from 'rxjs';
import { PersonInsurance, PersonInsuranceAdapter } from '../../shared/models/person-insurance';
import { CoverageAdapter } from '../../shared/models/onepoint-coverage';
import { SettingsService } from './settings.service';
import {catchError, map, tap} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { PolicyLookupResponseAdapter, PolicyProduct } from '../../shared/models/policy-lookup-response';
import { PolicyLookupSelect } from '@secca/shared/models/policy-lookup-select';
import { CaseLockHttpService } from './case-lock-http.service';
import { CustomerProfileInfo, CustomerProfileInfoAdapter } from '@secca/shared/models/customerProfileInfo';
import { QueueService } from './queue.service';
import { UpdateInsuranceQueueService } from './update-insurance-queue.service';
import { CustomerProductInfo, CustomerProductInfoAdapter } from '@secca/shared/models/customer-product-info';
import { PersonInsuranceStateService } from '../state-services/insurance-state-service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class InsuranceService extends BaseService {
  constructor(
    private http: HttpClient,
    private personInsuranceAdapter: PersonInsuranceAdapter,
    private coverageAdapter: CoverageAdapter,
    private settingsService: SettingsService,
    private policyLookupResponseAdapter: PolicyLookupResponseAdapter,
    private customerProfileInfoAdapter: CustomerProfileInfoAdapter,
    private customerProductInfoAdapter: CustomerProductInfoAdapter,
    private caseLockHttpService: CaseLockHttpService,
    private updateInsuranceQueueService: UpdateInsuranceQueueService,
    private queueService: QueueService,
    private insuranceStateService: PersonInsuranceStateService
  ) {
    super(settingsService);
  }

  private customerProductChanges = {};

  public getPersonInsurance(caseId: string, forceReload = false): Observable<PersonInsurance> {
    return this.getPersonInsuranceWithCache(caseId, forceReload);
  }

  public deletePersonInsurance(caseId: string): void {
    this.caseLockHttpService
      .delete<PersonInsurance>(this.baseURL + `insurances/case/${caseId}`)
      .pipe(map(item => this.personInsuranceAdapter.adapt(item)))
      .subscribe(
        result => {
          this.insuranceStateService.updateCacheAndEmitInsuranceUpdated(result);
        }
      );
  }

  public updatePersonInsurance(caseId: string, personInsurance: PersonInsurance): Observable<PersonInsurance> {
    return this.caseLockHttpService
      .put<PersonInsurance>(this.baseURL + 'insurances/' + personInsurance.id, personInsurance, {
        headers: this.jsonHeaders
      }).pipe( tap(() => {
        this.insuranceStateService.updateCacheAndEmitInsuranceUpdated(personInsurance);
      }));
  }

  public updateCustomerProfile(personInsurance: PersonInsurance): Observable<PersonInsurance> {
    return this.caseLockHttpService
      .put<PersonInsurance>(this.baseURL + 'insurances/updateCustomerProfile/' + personInsurance.id, personInsurance, {
        headers: this.jsonHeaders
      }).pipe(
        catchError(error => throwError(error)),
        tap(() => {
          this.insuranceStateService.updateCacheAndEmitInsuranceUpdated(personInsurance);
        }),
        map(item => this.personInsuranceAdapter.adapt(item))
      );
  }

  public profileSelectedByLookup(insuranceId: string, relationSearchResultRequest: string) {
    this.http.post(this.baseURL + 'insurances/' + insuranceId + '/profile-selected-by-lookup', relationSearchResultRequest, {
      headers: this.jsonHeaders
    }).subscribe(
      result => (result),
    );
  }

  public updatePersonInsuranceWithQueueService(caseId: string, personInsurance: PersonInsurance) {
    const updatePersonInsurance = personInsurance;
    this.queueService.pushInsurance(this.personInsuranceAdapter.adapt((updatePersonInsurance)));
    return this.updateInsuranceQueueService.actionSchedule();
  }

  public selectPolicy(data: PolicyLookupSelect): void {
    this.http
      .post(this.baseURL + 'insurances/select-policy', data, { headers: this.jsonHeaders })
      .pipe(map(item => this.personInsuranceAdapter.adapt(item)))
      .subscribe(
        result => {
          this.insuranceStateService.updateCacheAndEmitInsuranceUpdated(result);
        }
      );
  }

  public removePolicy(insuranceId: string): void {
    this.caseLockHttpService
      .post(this.baseURL + 'insurances/' + insuranceId + '/remove-policy', null, { headers: this.jsonHeaders })
      .pipe(map(item => this.personInsuranceAdapter.adapt(item)))
      .subscribe(
        result => {
          this.insuranceStateService.updateCacheAndEmitInsuranceUpdated(result);
        }
      );
  }

  public getProductVersion(profileId: number, productName: string, level: string, incidentDate: moment.Moment): Observable<PolicyProduct> {
    const formattedDate = incidentDate != null ? '&incidentDate=' + incidentDate.format('YYYY-MM-DD') : '';
    const levelParameter = level != null ? '&level=' + level : '';
    return this.http.get(this.baseURL + 'products/by-name/' + profileId + '?productName=' + encodeURIComponent(productName) + levelParameter + formattedDate)
      .pipe(map(item => this.policyLookupResponseAdapter.adaptProduct(item)));
  }

  public getProduct(productId: number): Observable<PolicyProduct> {
    return this.http.get(this.baseURL + 'products/' + productId)
      .pipe(map(item => this.policyLookupResponseAdapter.adaptProduct(item)));
  }

  public getCustomerProfileInfo(caseId: string): Observable<CustomerProfileInfo> {
    return this.http.get(this.baseURL + 'insurances/customer-profile-info-on-case/' + caseId)
      .pipe(map(item => this.customerProfileInfoAdapter.adapt(item)));
  }

  public getCustomerProductInfo(caseId: string): Observable<CustomerProductInfo> {
    return this.http.get(this.baseURL + 'insurances/customer-product-info-on-case/' + caseId)
      .pipe(map(item => this.customerProductInfoAdapter.adapt(item)));
  }

  public clearInsuranceCache(): void {
    this.insuranceStateService.clearCache();
  }

  public getCustomerProductChanges(caseId: string): Observable<number> {
    this.initCustomerProductChangeBehaviorSubject(caseId);
    return this.customerProductChanges[caseId].asObservable();
  }

  public customerProductChanged(caseId: string, customerProduct: number) {
    this.initCustomerProductChangeBehaviorSubject(caseId);
    this.customerProductChanges[caseId].next(customerProduct);
  }

  public checkDebtorChange(caseId: string, customerProduct: number, customerProfileId: number): Observable<boolean> {
    if (customerProduct) {
      return this.http.get<boolean>(this.baseURL + 'insurances/check-debtor-change-on-case/' + caseId + '/product/' + customerProduct + '/profile/' + customerProfileId);
    } else {
      return this.http.get<boolean>(this.baseURL + 'insurances/check-debtor-change-on-case/' + caseId + '/profile/' + customerProfileId);
    }
  }

  public checkCurrencyChange(caseId: string, customerProduct: number, customerProfileId: number): Observable<boolean> {
    if (customerProduct) {
      return this.http.get<boolean>(this.baseURL + 'insurances/check-currency-change-on-case/' + caseId + '/product/' + customerProduct + '/profile/' + customerProfileId);
    } else {
      return this.http.get<boolean>(this.baseURL + 'insurances/check-currency-change-on-case/' + caseId + '/profile/' + customerProfileId);
    }
  }

  public checkDebtorChangeFinancialStatus(caseId: string): Observable<boolean> {
    return this.http.get<boolean>(this.baseURL + 'insurances/check-debtor-change-financial-status/' + caseId);
  }

  private getPersonInsuranceWithCache(caseId: string, forceReload: boolean): Observable<PersonInsurance> {
    if (!this.insuranceStateService.isPersonInsuranceCached(caseId) || forceReload) {
      let insuranceSubject = this.insuranceStateService.getPersonInsurance();
      if (!forceReload) {
        insuranceSubject = this.insuranceStateService.initPersonInsuranceCache(caseId);
      }
      this.fetchPersonInsurance(caseId)
        .subscribe(result => {
            insuranceSubject.next(result);
        },
        err => {
          this.insuranceStateService.clearCache();
          throw err;
        }
      );
    }

    return this.insuranceStateService.getPersonInsurance();
  }

  private fetchPersonInsurance(caseId: string): Observable<PersonInsurance> {
    return this.http
      .get(this.baseURL + `insurances/case/${caseId}`)
      .pipe(map((data: any[]) => data.map(item => this.personInsuranceAdapter.adapt(item))[0]));
  }

  private initCustomerProductChangeBehaviorSubject(caseId: string) {
    if (this.customerProductChanges[caseId] == null) {
      this.customerProductChanges[caseId] = new BehaviorSubject(undefined);
    }
  }
}
