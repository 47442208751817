import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-case-document-table-delete-document',
  templateUrl: './case-document-table-delete-document.component.html',
  styleUrls: ['./case-document-table-delete-document.component.scss']
})
export class CaseDocumentTableDeleteDocumentComponent implements OnInit {

  @Output() closeModalEvent = new EventEmitter<boolean>();
  @Input() documentName: string;

  constructor() { }

  ngOnInit() {
  }

  close(close: boolean) {
    this.closeModalEvent.emit(close);
  }

}
