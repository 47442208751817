import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SendGopModel } from './send-gop.model';
import { UpdateServiceOrderGopModel } from './update-service-order-gop.model';

@Injectable({
  providedIn: 'root'
})
export class SendGopService {
  private sendGop = new Subject<SendGopModel>();
  constructor() {}

  private serviceOrderGopStatus = new Subject<UpdateServiceOrderGopModel>();

  public sendGopInfo(sendGopModel: SendGopModel) {
    this.sendGop.next(sendGopModel);
  }

  public getGopInfo(): Observable<SendGopModel> {
    return this.sendGop.asObservable();
  }

  public updateServiceOrderGopStatus(updateServiceOrderGop: UpdateServiceOrderGopModel) {
    return this.serviceOrderGopStatus.next(updateServiceOrderGop);
  }

  public getServiceOrderGopStatus(): Observable<UpdateServiceOrderGopModel> {
    return this.serviceOrderGopStatus.asObservable();
  }
}
