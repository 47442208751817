<div *ngIf="case && task">
  <div class="modal-content">
    <div class="modal-content-body">
      <div class="top-info top-info-message">
        <div class="row">
          <div class="col-6">
            <div class="text-label">{{ 'task-modal-case-id' | translate }}</div>
            <div class="text-content">{{ case.caseNumber }}</div>
          </div>
          <div class="col-6" *ngIf='!isCoordinationCase()'>
            <div class="text-label">{{ 'task-modal-end-user' | translate }}</div>
            <div class="text-content" *ngIf="case.caseNumber">{{ trimText(case.endUserName, 40) }}</div>
          </div>
          <div class="col-6" *ngIf='isCoordinationCase()'>
            <div class="text-label">{{ 'task-modal-coordination-name' | translate }}</div>
            <div class="text-content" *ngIf="case.caseNumber">{{ trimText(case.coordinationCaseName, 40) }}</div>
          </div>
        </div>
        <div class="row-separator"></div>
        <div class="row">
          <div class="col-6">
            <div class="text-label">{{ 'task-modal-customer' | translate }}</div>
            <div class="text-content">{{ profileName }}</div>
          </div>
          <div class="col-6">
            <div class="text-label">{{ 'task-modal-location' | translate }}</div>
            <div class="text-content">{{ trimText(location, 40) }}</div>
          </div>
        </div>
        <div class="row-separator"></div>
        <div class="row">
          <div class="col-6">
            <div class="text-label">{{ 'task-modal-reserve' | translate }}</div>
            <div class="text-content">{{ reserve | numberFormat }} {{ currency }}</div>
          </div>
          <div class="col-6">
            <div class="text-label">{{ 'task-modal-cause' | translate }}</div>
            <div class="text-content">{{ trimText(incidentCause, 40) }}</div>
          </div>
        </div>
        <div class="row-separator"></div>
        <div class="row">
          <div class="col-6">
            <div class="text-label">{{ 'task-modal-primary-case-handler' | translate }}</div>
            <div class="text-content">{{ trimText(primaryCaseHandler, 40) }}</div>
          </div>
          <div class="col-6">
            <div class="text-label">{{ 'task-modal-last-case-action-by' | translate }}</div>
            <div *ngIf="lastCaseActionBy != undefined">
              <div class="text-content">{{ trimText(lastCaseActionBy, 40) }}</div>
              <div class="text-content">on {{ lastCaseActionTime | date:'dd MMM yyyy HH:mm':'local' }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row-separator"></div>
      <div *permission="PermissionEnum.TASK_UPDATE; hideType: PermissionHideTypeEnum.DISABLE">
        <div class="modal-body p-0 mb-2 mt-2">
          <div class="container">
            <div>
              <div>
                <div class="row left-panel-message-case-edit">
                  <div class="modal-body-left-panel col">
                      <div>
                      <div class="field-title">{{ 'task-modal-title' | translate }}</div>
                      <div>
                        <input type="text" name="textinput" class="task-text"
                          maxlength="1000" [name]="'Title'" [required]="true" (input)="titleUpdated()"
                          [(ngModel)]="task.title" />
                      </div>
                      <div class="row-separator-small"></div>
                    </div>
                    <div>
                      <div class="field-title">{{ 'task-modal-received-date' | translate }}</div>
                      <div>
                        <app-input-date-time [disabled]="true" [date]="task.receivedDate" [withImage]="true" [ngClass]="validated ? '' : 'required-red'"
                                            [timeZone]="'local'">
                        </app-input-date-time>
                      </div>
                      <div class="row-separator-extra-small"></div>
                      <div class="field-title">{{ 'task-modal-due-date' | translate }}</div>
                      <div>
                        <app-input-date-time [date]="task.dueDate" [withImage]="true" [ngClass]="validated ? '' : 'required-red'"
                          (dateChange)="dueDateUpdated($event)" [timeZone]="'local'">
                        </app-input-date-time>
                      </div>
                      <div class="row-separator-extra-small"></div>
                      <div class="field-title" *ngIf="!task.originatedFromWebtaskId">{{ 'task-modal-priority' | translate }}</div>
                      <div>
                        <app-drop-down
                        [dropdownPosition]="'auto'"
                        [disabled]="isReadOnly || isPartialReadOnly || isSupplierInvoiceTask || isRefundTask || isAssignableOnly"
                        [items]="priorityDropdown"
                        [(selectedItemId)]="task.priority"
                        (selectedItemIdChange)="priorityUpdated()"
                      ></app-drop-down>
                      </div>
                      <div class="row-separator-extra-small"></div>
                      <app-case-handler-selector [acHandler]="acHandler" [caseId]="caseId" [required]="true"
                                                 (caseHandlerChange)="caseHandlerUpdated($event)">
                      </app-case-handler-selector>
                      <div class="row-separator-extra-small"></div>
                      <div class="field-title">{{ 'task-modal-status' | translate }}</div>
                      <div>
                        <app-drop-down
                          [dropdownPosition]="'auto'"
                          [items]="task.allStatuses" [(selectedItemId)]="task.status"
                          [requiredRedBackground]="true" (selectedItemIdChange)="statusUpdated()"
                          [disabled]='isUnrelatedToCase'>
                        </app-drop-down>
                      </div>
                      <div class="row-separator-extra-small"></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 task-id-col">
                    <div class="task-id">
                      <div>{{task.createdOn ? ('Created: ' + (task.createdOn | momentFormat:'DD MMM YYYY HH:mm':'local') + '&nbsp;&nbsp;&nbsp;ID: ' + task.id) : '&nbsp;' }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
