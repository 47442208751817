import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';

export class ProfileCountry {
    id: number;
    name: string;
    countryCodeAlpha2: string;
  public constructor(init?: Partial<ProfileCountry>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})

export class ProfileCountryAdapter implements Adapter<ProfileCountry> {
  adapt(item: any): ProfileCountry {
    return new ProfileCountry({
        id: item.id,
        name: item.name,
        countryCodeAlpha2: item.alpha2Code
    });
  }
}
