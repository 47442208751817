import { Component, Input } from '@angular/core';
import { PersonInsurance } from 'src/app/shared/models/person-insurance';
import { Coverage } from '@secca/shared/models/onepoint-coverage';
import { CaseService } from '@secca/core/services/case.service';
import { CustomerProfile } from '@secca/shared/models/customerProfile';
import { ValidationTypeEnum } from '@secca/shared/models/enums';
import { InsuranceService } from '@secca/core/services/insurance.service';
import { ModalDialogComponent } from '@secca/shared/components/modal-dialog/modal-dialog.component';
import { ModalDialogConfiguration } from '@secca/shared/models/modal/modal-dialog-configuration';
import { ServiceOrderService } from '@secca/core/services/service-order.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { PlanService } from '@secca/core/services/plan.service';

@Component({
  selector: 'app-coverage-view',
  templateUrl: './coverage-view.component.html',
  styleUrls: ['./coverage-view.component.scss']
})
export class CoverageViewComponent {
  @Input()
  set personInsurance(personInsurance: PersonInsurance) {
    this._personInsurance = personInsurance;
    if (this._personInsurance && this._personInsurance.customerProfileId) {
      this.caseService.getCustomerProfile(this._personInsurance.customerProfileId.toString()).subscribe(
        result => {
          if (result.isGroupProfile || !!result.groupProfile) {
            const cpId = result.isGroupProfile ? result.profileId : result.groupProfile;
            this.caseService.getMemberProfiles(cpId.toString()).subscribe(
              members => {
                this.memberProfiles = members;
                this.memberProfiles.splice(0, 0, new CustomerProfile({profileName: this.translate.instant('case-basics-insurance-coverage-view-no-insurance'), noInsuranceWithThisCustomerGroup: true, profileId: cpId}));
                this.memberProfiles.splice(0, 0, new CustomerProfile({profileName: this.translate.instant('case-basics-insurance-coverage-view-awaiting-confirmation'), noInsuranceWithThisCustomerGroup: false, profileId: ''}));
                if (!this._personInsurance.noInsuranceWithThisCustomerGroup && result.isGroupProfile) {
                  this.selectedMemberProfile = '';
                } else {
                  this.selectedMemberProfile = result.profileId;
                }
                this.oldSelectedMemberProfile = this.selectedMemberProfile;
              }
            );
          } else {
            this.memberProfiles = null;
          }
        }
      );
      this.checkFinancialStatusOpen();
    }
  }

  get personInsurance(): PersonInsurance {
    return this._personInsurance;
  }

  @Input() coveragesBasic: Coverage[];
  @Input() coveragesSupplement: Coverage[];
  @Input() fixedHeight: boolean;
  selectedBasicLimit: number;
  selectedSupplementLimit: number;
  displayGrayBackground = false;
  _personInsurance: PersonInsurance;
  memberProfiles: CustomerProfile[];
  selectedMemberProfile: string;
  oldSelectedMemberProfile: string;
  validationTypeEnum: ValidationTypeEnum = ValidationTypeEnum.empty;
  financialStatusOpen: boolean;

  constructor(private caseService: CaseService,
              private insuranceService: InsuranceService,
              private serviceOrderService: ServiceOrderService,
              private translate: TranslateService,
              private planService: PlanService,
              private modalService: NgbModal) {
  }

  close() {
    this.displayGrayBackground = false;
    this.selectedBasicLimit = null;
    this.selectedSupplementLimit = null;
  }

  setSelectedBasic(value: number) {
    this.displayGrayBackground = true;
    this.selectedBasicLimit = value;
  }

  setSelectedSupplement(value: number) {
    this.displayGrayBackground = true;
    this.selectedSupplementLimit = value;
  }

  getSelectedBasic() {
    return this.selectedBasicLimit;
  }

  getSelectedSupplement() {
    return this.selectedSupplementLimit;
  }

  onProfileChange() {
    this._personInsurance.insuranceLevelId = null;
    this._personInsurance.geographicalCoverageArea = null;
    this._personInsurance.customerProduct = null;
    this._personInsurance.coverageDays = null;
    this._personInsurance.coverageWeeks = null;
    this.personInsurance.coveragePeriodFromLookup = false;
  }

  updateMemberProfile() {
    this.oldSelectedMemberProfile = this.selectedMemberProfile;
    if (this.selectedMemberProfile === null || this.selectedMemberProfile === '') {
      this.caseService.getCustomerProfile(this._personInsurance.customerProfileId.toString()).subscribe(
        result => {
          if (!!result.groupProfile) {
            this._personInsurance.customerProfileId = +result.groupProfile;
          }
          this.personInsurance.noInsuranceWithThisCustomerGroup = false;
          this.updateCustomerProfile();
        }
      );
    } else {
      this._personInsurance.noInsuranceWithThisCustomerGroup = this.memberProfiles.find(value => value.profileId === this.selectedMemberProfile).noInsuranceWithThisCustomerGroup;
      this._personInsurance.customerProfileId = +this.selectedMemberProfile;
      this.updateCustomerProfile();
    }
  }

  private updateCustomerProfile() {
    this.onProfileChange();
    this.insuranceService.updateCustomerProfile(this._personInsurance).subscribe(
      result => {
        this._personInsurance = result;
      }
    );
  }

  isGroupProfile(): boolean {
    return this.memberProfiles ? this.memberProfiles.length > 1 : false;
  }

  checkFinancialStatusOpen() {
    this.insuranceService.checkDebtorChangeFinancialStatus(this.personInsurance.seccaCaseId).subscribe(financialStatusOpen => {
      this.financialStatusOpen = financialStatusOpen;
    });
  }

  checkIfMemberProfileChangeIsPossible() {
    if (this.selectedMemberProfile === null || this.selectedMemberProfile === '') {
      this.caseService.getCustomerProfile(this._personInsurance.customerProfileId.toString()).subscribe(result => {
        if (result.isGroupProfile) {
          this.performChecks(result.profileId);
        } else {
          this.performChecks(result.groupProfile);
        }
      });
    } else {
      this.performChecks(this.selectedMemberProfile);
    }
  }

  private performChecks(profileId: string) {
    this.insuranceService.checkDebtorChange(this.personInsurance.seccaCaseId, this.personInsurance.customerProduct, +profileId).subscribe(debtorChange => {
      if (!debtorChange) {
        this.checkCoveragesUsedOnExistingServiceOrders(this.personInsurance.seccaCaseId);
      } else {
        this.insuranceService.checkDebtorChangeFinancialStatus(this.personInsurance.seccaCaseId).subscribe(financialStatusOpen => {
          if (financialStatusOpen) {
            this.planService.existReserveOnCase(this.personInsurance.seccaCaseId).subscribe(reserveExist => {
              if (!reserveExist) {
                this.checkCoveragesUsedOnExistingServiceOrders(this.personInsurance.seccaCaseId);
              } else {
                this.insuranceService.checkCurrencyChange(this.personInsurance.seccaCaseId, this.personInsurance.customerProduct, +profileId).subscribe(currencyChange => {
                  if (!currencyChange) {
                    this.checkCoveragesUsedOnExistingServiceOrders(this.personInsurance.seccaCaseId);
                  } else {
                    this.showWarningIfProfileChangeNotPossible('check-debtors-same-currency-member-profile-title', 'check-debtors-same-currency-member-profile-text');
                  }
                });
              }
            });
          } else {
            this.showWarningIfProfileChangeNotPossible('check-debtors-financial-status-not-open-title', 'check-debtors-financial-status-not-open-text');
          }
        });
      }
    });
  }

  private showWarningIfProfileChangeNotPossible(title: string, text: string) {
    const modalRef = this.modalService.open(ModalDialogComponent);
    modalRef.componentInstance.items = [];
    modalRef.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'default-modal-header',
      title: title,
      text: text,
      no: 'dismiss-ok-modal',
      isBody: true,
      isFooter: true,
    });
    modalRef.componentInstance.closeModalEvent.subscribe(
      ok => {
        this.selectedMemberProfile = this.oldSelectedMemberProfile;
        this.modalService.dismissAll();
      }
    );
  }

  private checkCoveragesUsedOnExistingServiceOrders(caseId: string) {
    this.serviceOrderService.isCoveragesUsedOnExistingServiceOrders(+caseId).subscribe(coveragesExist => {
      if (coveragesExist) {
        const modalRef = this.modalService.open(ModalDialogComponent);
        modalRef.componentInstance.items = [];
        modalRef.componentInstance.configuration = new ModalDialogConfiguration({
          header: 'default-modal-header',
          title: 'changing-service-orders-with-coverages-member-profile-change',
          text: null,
          footer: 'case-validation-warning-modal-footer',
          yes: 'default-modal-dialog-yes',
          no: 'default-modal-dialog-no',
          isBody: true,
          isFooter: true,
        });
        modalRef.componentInstance.closeModalEvent.subscribe(
          closedClickingYes => {
            this.modalService.dismissAll();
            if (closedClickingYes) {
              this.updateMemberProfile();
            } else {
              this.selectedMemberProfile = this.oldSelectedMemberProfile;
            }
          }
        );
      } else {
        this.updateMemberProfile();
      }
    });
  }
}
