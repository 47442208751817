import { WorkInProgress } from './../../shared/models/work-in-progress';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WorkInProgressService {
  currentWork: WorkInProgress[] = [];

  public addWorkInProgress(work: WorkInProgress) {
    if (this.currentWork.filter(item => work.caseId === item.caseId && work.type === item.type).length === 0) {
      this.currentWork.push(work);
    }
  }

  public removeWorkInProgress(work: WorkInProgress) {
    this.currentWork.forEach((currentWorkItem, index) => {
      if (this.currentWork.filter(item => work.caseId === item.caseId && work.type === item.type).length === 0) {
        this.currentWork.splice(index, 1);
      }
    });
  }

  public clearWorkInProgress(caseId: string) {
    this.currentWork.forEach((workInProgress, index) => {
      if (caseId === workInProgress.caseId) {
        this.currentWork.splice(index, 1);
      }
    });
  }

  public getWorkInProgress(caseId: string): WorkInProgress[] {
    return this.currentWork.filter(item => item.caseId === caseId);
  }

  public getWorkInProgressMessageList(caseId: string): string[] {
    return this.getWorkInProgress(caseId).map(item => this.mapToMessageList(item));
  }

  private mapToMessageList(work: WorkInProgress): string {
    switch (work.type) {
      case WorkInProgressEnum.MESSAGE:
        return 'work-in-progress-modal-text-message';
    }
  }
}

export enum WorkInProgressEnum {
  MESSAGE = 'MESSAGE',
  NOTE = 'NOTE'
}
