import { Injectable } from '@angular/core';
import { NoteView, NoteViewAdapter } from '@secca/shared/models/note-view';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  constructor(private noteViewAdapter: NoteViewAdapter) {}
  private notesPrefix = 'notes-for-case';

  getNotes(caseId: string): NoteView[] {
    const untypedNotes = JSON.parse(localStorage.getItem(this.notesPrefix + caseId)) || [];
    return untypedNotes.map(un => this.noteViewAdapter.adapt(un));
  }
  saveNotes(caseId: string, notes: NoteView[]) {
    localStorage.setItem(this.notesPrefix + caseId, JSON.stringify(notes));
  }
}
