import { Component, Input } from '@angular/core';
import { CauseItemType } from '@secca/shared/models/cause-item-type';
import { SimpleCustomerProfile } from '@secca/shared/models/simpleCustomerProfile';
import { StakeholderTypeEnum } from '@secca/shared/models/enums';
import { PurposeOfTravelItemType } from '@secca/shared/models/purpose-of-travel-item-type';
import { ServiceItemType } from '@secca/shared/models/service-item-type';
import { IncomingCasesTaskViewModel } from '../../../models/incoming-cases-task-view-model';
import { Diagnosis } from '@secca/shared/models/dignosis';

@Component({
  selector: 'app-task-web-co-travellers-panel',
  templateUrl: './task-web-co-travellers-panel.component.html',
  styleUrls: ['./task-web-co-travellers-panel.component.scss'],
})
export class TaskWebCoTravellersPanelComponent {
  readonly numberOfColumns = 3;

  @Input() taskDataViewModel: IncomingCasesTaskViewModel;

  @Input() serviceItemList: ServiceItemType[];
  @Input() purposeOfTravelItemList: PurposeOfTravelItemType[];
  @Input() causes: Map<string, CauseItemType[]>;
  @Input() diagnoses: Diagnosis[];
  @Input() customerProfiles: SimpleCustomerProfile[];

  ignoredProperties = ['id', 'properties', 'stakeholderType', 'nationalIdValid', 'isCompany'];

  cotravellers = [];

  constructor() {}

  getCotravellerFirstCol(index: number): any {
    return this.getCotravellerFromIndex(index*this.numberOfColumns + 0);
  }

  getCotravellerSecondCol(index: number): any {
    return this.getCotravellerFromIndex(index*this.numberOfColumns + 1);
  }

  getCotravellerThirdCol(index: number): any {
    return this.getCotravellerFromIndex(index*this.numberOfColumns + 2);
  }

  getCotravellerFromIndex(index: number): any {
    if ( this.taskDataViewModel?.data?.coTravellerStakeholders && this.taskDataViewModel.data.coTravellerStakeholders.length > index ) {
      return this.taskDataViewModel?.data?.coTravellerStakeholders[index];
    }
    else {
      return null;
    }
  }

  isEnduser(cotraveller: any): boolean {
    return cotraveller?.properties?.includes(StakeholderTypeEnum.endUser);
  }

  isPolicyHolder(cotraveller: any): boolean {
    return cotraveller?.properties?.includes(StakeholderTypeEnum.policyHolder);
  }

  isReporter(cotraveller: any): boolean {
    return cotraveller?.properties?.includes(StakeholderTypeEnum.reporter);
  }
}
