import { BaseService } from '@secca/core/services/base.service';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '@secca/core/services/settings.service';
import { BalanceSheetProgress, BalanceSheetAdapter } from '@secca/shared/models/balance-sheet-event.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class BalanceSheetService extends BaseService {
  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private balanceSheetAdapter: BalanceSheetAdapter,
  ) {
    super(settingsService);
  }

  public getServiceOrderEvents(caseId: string, serviceOrderId: string): Observable<BalanceSheetProgress> {
    return this.http
      .get<BalanceSheetProgress>(this.baseURL + 'balance-sheet/' + caseId + '/' + serviceOrderId, { headers: this.jsonHeaders })
      .pipe(map((data: any) => this.balanceSheetAdapter.adapt(data)));
  }
}
