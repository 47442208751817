import {Component, EventEmitter, Output, TemplateRef, ViewChild} from '@angular/core';
import {AutoUnsubscribe} from '@secca/shared/decorators/auto-unsubscribe';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CaseLockHelperService} from '@secca/core/services/case-lock-helper.service';
import {PermissionService} from '@secca/core/services/permission.service';
import {CaseStateService} from '@secca/core/state-services/case-state.service';
import {RefundService} from '@secca/core/services/refund.service';

@Component({
  selector: 'app-edit-refund',
  templateUrl: './edit-refund.component.html',
  styleUrls: ['./edit-refund.component.scss'],
})
@AutoUnsubscribe
export class EditRefundComponent {
  @ViewChild('content') private template: TemplateRef<any>;
  @Output() refresh = new EventEmitter();
  actionComment: string;
  caseId: string;
  refundId: number;
  title: string;

  constructor(
    private modalService: NgbModal,
    public caseLockHelperService: CaseLockHelperService,
    public permissionService: PermissionService,
    public caseStateService: CaseStateService,
    public refundService: RefundService,
  ) {}

  show(caseId: string, refundId: number, title: string) {
    this.caseId = caseId;
    this.refundId = refundId;
    this.title = title;
    this.modalService.open(this.template, { size: 'lg' as any });
  }

  close() {
    this.modalService.dismissAll();
  }

  save() {
    this.refundService.deleteErrorRefund(this.refundId, this.actionComment).subscribe(
      ignore => this.refresh.emit()
    );
    this.close();
  }

}
