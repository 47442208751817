import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';

export class CaseValidation {
  caseBeenValidated: boolean;
  caseValidateList: string[];

  public constructor(init?: Partial<CaseValidation>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class CaseClosedValidationAdapter implements Adapter<CaseValidation> {

  constructor() {
  }

  adapt(item: any): CaseValidation {
    return new CaseValidation({
      caseBeenValidated: item.caseBeenValidated,
      caseValidateList: item.caseValidateList ? item.caseValidateList : []
    });
  }
}
