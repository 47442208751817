  <div class="modal-content">
    <div class="modal-header">
      <div class="modal-header-title">
        {{ 'profile-lookup-lookup-title' | translate }}
      </div>
      <div *ngIf="!selectionInProgress" class="modal-buttons">
        <div (click)="close()">
          <img src="/assets/icons/Close White.svg" />
        </div>
      </div>
    </div>
    <div class="profile-lookup-modal-body">
      <div class="policy-holder">
        <img class="clearfix" src="/assets/icons/Policy Holder.svg" alt="">
        <div class="title">{{ nameLine }}</div>
        <div *ngIf="insurance.creditCard" class="ssn">{{ policyHolder.person ? policyHolder.person.nationalId : '' }}</div>
        <div class="address">{{ addressLine }}</div>
        <div *ngIf="insurance.creditCard" class="ssn">{{ insurance.creditCardNo }}</div>
      </div>
      <div class="lookup row">
        <div class="overview col-4">
          <div class="profile-search">
            <app-input
              *ngIf="policyHolder.isCompany"
              [name]="'profile-lookup-policy-number' | translate"
              [withImage]="true"
              (imageClicked)="performLookup()"
              (keyup.enter)="performLookup()"
              [(value)]="policyNo">
            </app-input>
            <app-input
              *ngIf="policyHolder.isCompany"
              [name]="'profile-lookup-company-name' | translate"
              [withImage]="true"
              (imageClicked)="performLookup()"
              (keyup.enter)="performLookup()"
              [(value)]="companyName">
            </app-input>
            <app-input-credit-card
              *ngIf="insurance.creditCard"
              [name]="'profile-lookup-credit-card-no' | translate"
              [validate]="true"
              [withImage]="true"
              (imageClicked)="performLookup()"
              (keyup.enter)="performLookup()"
              [(value)]="creditCardNo">
            </app-input-credit-card>
            <app-drop-down
              *ngIf="insurance.creditCard"
              [name]="'profile-lookup-bank' | translate"
              [items]="banks"
              [selectedItemId]="bank"
              (selectedItemIdChange)="saveBankAndPerformLookup($event)">
            </app-drop-down>
            <app-drop-down
              *ngIf="insurance.creditCard"
              [name]="'profile-lookup-credit-card-type' | translate"
              [items]="cardTypes"
              [selectedItemId]="cardType"
              (selectedItemIdChange)="saveCardTypeAndPerformLookup($event)">
            </app-drop-down>
            <app-drop-down
              *ngIf="insurance.creditCard"
              [name]="'profile-lookup-credit-card-level' | translate"
              [items]="levels"
              [selectedItemId]="level"
              (selectedItemIdChange)="saveLevelAndPerformLookup($event)">
            </app-drop-down>
          </div>
          <div class="result-list">
            <div class="searching" *ngIf="!lookupResponse">
              <div class="searching-text">{{ 'profile-lookup-searching' | translate }}</div>
              <img src="/assets/icons/waitForSearchresult.gif" alt="Searching...">
            </div>
            <div class="results" *ngIf="lookupResponse">
              <div class="result" [ngClass]="!showSelectedProfile && chosen === idx ? 'chosen' : ''" *ngFor="let result of lookupResponse.results; let idx = index"
                   (click)="onShowLookupProfile(idx)">
                <span *ngIf="policyHolder.isCompany">
                  <div class="insurance-type">{{result.customerProductRelation.productName}} {{ result.customerProductRelation.productLevel }}</div>
                  <div class="reference-number">{{ result.customerProductRelation.policyNo }}</div>
                </span>
                <span *ngIf="insurance.creditCard">
                  <div class="insurance-type">{{ result.customerProductRelation.bank }} - {{result.customerProductRelation.productName}} {{result.customerProductRelation.productLevel}}</div>
                  <div class="reference-number">{{ 'profile-lookup-bin' | translate }}: {{ result.customerProductRelation.bin }}</div>
                </span>
                <div class="profile-name">{{ 'profile-lookup-customer-profile' | translate }}:
                  {{ result.customerProductRelation.profileName }} ({{result.customerProductRelation.validFrom | date:'dd MMM yyyy'}} -
                  <span *ngIf="result.customerProductRelation.validTo"> {{result.customerProductRelation.validTo | date:'dd MMM yyyy'}}</span>
                  <span *ngIf="!result.customerProductRelation.validTo"> {{'profile-lookup-ongoing' | translate}}</span>)
                </div>
              </div>
              <div *ngIf="!profileFound && sufficientSearchCriteria" class="no-matches-found">{{ 'profile-lookup-no-matches' | translate }}</div>
              <div *ngIf="!profileFound && !sufficientSearchCriteria" class="no-matches-found">{{ 'profile-lookup-insufficient-search-criteria' | translate }}</div>
            </div>
          </div>
        </div>
        <div class="details col-8">
          <div class="details-container" *ngIf="lookupResponse">
            <app-alert-box
              [title]="('policy-lookup-integration-status-successful' | translate)"
              [type]="'success'">
              <ng-container *ngTemplateOutlet="successful"></ng-container>
            </app-alert-box>
            <ng-container *ngIf="profileFound">
              <div class="button-container">
                <button *ngIf="relationSearchResult && !showSelectedProfile"
                        class="btn select"
                        [class.button-busy]="selectionInProgress"
                        [disabled]="selectionInProgress"
                        (click)="profileSelect(relationSearchResult)">
                  {{ 'profile-lookup-select-result' | translate }}
                  <img src="/assets/icons/waitForSearchresult.gif" class="spinner" [class.hide-spinner]="!selectionInProgress" />
                </button>
              </div>
              <app-profile-lookup-details [relationSearchResult]="relationSearchResult" [sourceInfo]="lookupResponse.sourceInfo" [matchedBy]="lookupResponse.matchedBy" [lookupTime]="lookupResponse.lookupTime"></app-profile-lookup-details>
            </ng-container>
          </div>
          <div class="filler" *ngIf="!lookupResponse && !profileFound"></div>
        </div>
      </div>
    </div>
  </div>

<ng-template #successful>
  <span>{{ 'profile-lookup-integration-successful-details' | translate }}:</span>
  <ul>
    <li>{{ lookupResponse.sourceInfo }}</li>
  </ul>
</ng-template>
