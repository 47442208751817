import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {SalesOrderItem} from '../../../../../shared/models/salesOrderItem';
import {SalesOrderStatus} from '../../../../../shared/enums/sales-order-status';
import {FeeTypeEnum, PermissionEnum, SalesOrderItemTypeEnum, SosServiceCategoriesEnum} from '@secca/shared/models/enums';
import {ModifyActionEnum, ModifySalesOrderComponent} from './modify-sales-order/modify-sales-order.component';
import {PermissionService} from '@secca/core/services/permission.service';
import {SalesOrderService} from '@secca/core/services/sales-order.service';
import {Case} from '@secca/shared/models/case';
import {CaseLockHelperService} from '@secca/core/services/case-lock-helper.service';
import {DineroObject} from 'dinero.js';
import {first, switchMap, takeUntil, takeWhile, tap} from 'rxjs/operators';
import {interval, Subscription, throwError, timer} from 'rxjs';
import {AutoUnsubscribe} from '@secca/shared/decorators/auto-unsubscribe';
import {FeePickerSecondaryComponent} from './fee-picker-secondary/fee-picker-secondary.component';
import {FeePickerStatus} from '@secca/shared/models/fee-picker-status';
import {CaseStateService} from '@secca/core/state-services/case-state.service';
import { SettingsService } from '@secca/core/services/settings.service';

@Component({
  selector: 'app-case-economy-sos-service',
  templateUrl: './case-economy-sos-service.component.html',
  styleUrls: ['./case-economy-sos-service.component.scss'],
})
@AutoUnsubscribe
export class CaseEconomySosServiceComponent implements OnInit {
  salesOrderItems: SalesOrderItem[];
  notInvoicedAmount: number;
  sentToERPAmount: number;
  invoicedAmount: number;
  caseCurrency = 'DKK';
  feePickerStatus: FeePickerStatus = new FeePickerStatus();
  feePickerSecondaryEnabled = false;
  disableRunFeepicker = false;
  private pollingInterval = 1000;
  private pollingTimeoutMillis = 5 * 60 * 1000;
  private $pollFeePickerSubscr: Subscription;
  private $salesOrderSubscription: Subscription;

  @ViewChild('modifySalesOrder') modifySalesOrder: ModifySalesOrderComponent;
  @ViewChild('feePickerSecondary') feePickerSecondary: FeePickerSecondaryComponent;

  @Input()
  set case(newCase: Case) {
    this.theCase = newCase;
    this.onRefresh();
  }
  get case() {
    return this.theCase;
  }
  private theCase: Case;
  private userHasUpdatePermission = false;
  private userHasDeletePermission = false;

  get PermissionEnum() {
    return PermissionEnum;
  }

  constructor(
    public permissionService: PermissionService,
    private salesOrderService: SalesOrderService,
    public caseLockHelperService: CaseLockHelperService,
    public caseStateService: CaseStateService,
    public settingsService: SettingsService,
  ) {}

  ngOnInit(): void {
    this.permissionService.permissionsReadyNotification.subscribe(result => {
      if (result) {
        this.userHasUpdatePermission = this.permissionService.checkUserPermission(PermissionEnum.SALES_ORDER_UPDATE);
        this.userHasDeletePermission = this.permissionService.checkUserPermission(PermissionEnum.SALES_ORDER_DELETE);
      }
    });
  }

  onRefresh() {
    this.$salesOrderSubscription = this.salesOrderService.getSalesOrders().subscribe(result => {
      this.notInvoicedAmount = 0;
      this.sentToERPAmount = 0;
      this.invoicedAmount = 0;
      this.salesOrderItems = [];

      for (const salesOrder of result) {
        this.caseCurrency = salesOrder.invoicePrice.currency;
        for (const si of salesOrder.salesOrderItems) {
          if (SalesOrderItemTypeEnum.FEE === si.type || SalesOrderItemTypeEnum.RECOVERY_FEE === si.type) {
            this.salesOrderItems.push(si);
            si.invoiced =
              salesOrder.status === SalesOrderStatus.SENT_TO_ERP ||
              salesOrder.status === SalesOrderStatus.INVOICED ||
              salesOrder.status === SalesOrderStatus.PAID;
            if (salesOrder.status === SalesOrderStatus.INVOICED || salesOrder.status === SalesOrderStatus.PAID) {
              this.invoicedAmount += si.invoicePrice.amount;
            } else if (salesOrder.status === SalesOrderStatus.SENT_TO_ERP) {
              this.sentToERPAmount += si.invoicePrice.amount;
            } else if (!si.suggestion) {
              this.notInvoicedAmount += si.invoicePrice.amount;
            }
          }
        }
      }
      // Default sort order is billable descending and then appliedDate descending
      this.salesOrderItems.sort((s1, s2) => {
        if (s1.billable === s2.billable) {
          return s1.appliedDate >= s2.appliedDate ? -1 : 1;
        } else if (s1.billable > s2.billable) {
          return -1;
        } else {
          return 1;
        }
      });
    });

    this.refreshFeePickerStatus();
    this.salesOrderService
      .isFeePickerRunning(this.case.id)
      .pipe(first())
      .subscribe(result => {
        this.disableRunFeepicker = result;
        if (result) {
          this.listenFeePickerDone();
        }
      });
  }

  getItemDescription(item: SalesOrderItem): string {
    return 'Quantity: ' + item.quantity + '<br/>Unit price: ' + (item.agreementPrice.amount/item.quantity);
  }

  getCurrencyDecimals(moneyAmount: DineroObject): string {
    return '.' + moneyAmount.precision + '-' + moneyAmount.precision;
  }

  addFee() {
    const primaryFeePresent =
      this.salesOrderItems.filter(si => si.invoicePrice.amount >= 0 && si.feeType == FeeTypeEnum.PRIMARY)?.length > 0;
    this.modifySalesOrder.show(this.case.id, null, ModifyActionEnum.ADD, primaryFeePresent,null);
  }

  replaceFee(salesOrderItem: SalesOrderItem) {
    if(salesOrderItem.agreementPrice?.amount < 0 || salesOrderItem.credited){
      return;
    }
    if (salesOrderItem.agreementPrice.amount >= 0) {
      this.modifySalesOrder.show(this.case.id, salesOrderItem, ModifyActionEnum.REPLACE, null,null);
    }
  }

  adjustAmount(salesOrderItem: SalesOrderItem) {
    if(salesOrderItem.creditNote || salesOrderItem.credited){
      return;
    }
    this.modifySalesOrder.show(null, salesOrderItem, ModifyActionEnum.ADJUST, null, salesOrderItem.agreementPrice.currency);
  }

  deleteSalesOrderItem(salesOrderItem: SalesOrderItem) {
    if(salesOrderItem.invoiced || salesOrderItem.credited){
      return;
    }
    if (salesOrderItem.suggestion) {
      this.salesOrderService.deleteSalesOrderItem(Number(this.case.id), salesOrderItem, null).subscribe(t => this.refreshFeePickerStatus());
    } else if (!salesOrderItem.invoiced) {
      this.modifySalesOrder.show(this.case.id, salesOrderItem, ModifyActionEnum.DELETE, null,null);
    }
  }

  creditSalesOrderItem(salesOrderItem: SalesOrderItem) {
    if(salesOrderItem.creditNote || salesOrderItem.credited || this.selectCreditAllowed(salesOrderItem)){
      return;
    }
    this.modifySalesOrder.show(this.case.id, salesOrderItem, ModifyActionEnum.CREDIT, null,null);
  }

  selectSuggestion(salesOrderItem: SalesOrderItem) {
    this.modifySalesOrder.show(this.case.id, salesOrderItem, ModifyActionEnum.SELECT_SUGGESTION, null,null);
  }

  enableFeePicker() {
    this.feePickerStatus.primaryDisabled = false;
    this.salesOrderService
      .enableFeePicker(this.feePickerStatus.caseId, this.feePickerStatus)
      .subscribe(result => (this.feePickerStatus = result));
  }

  reenableFeePickerDisabled(): boolean {
    return !this.feePickerStatus.primaryDisabled || !this.userHasUpdatePermission;
  }

  executeFeePicker() {
    this.disableRunFeepicker = true;
    this.salesOrderService.executeFeePicker(this.case.id).subscribe(() => this.handlePollResult(false));
  }

  listenFeePickerDone() {
    this.disableRunFeepicker = true;
    this.$pollFeePickerSubscr = interval(this.pollingInterval)
      .pipe(
        switchMap(() => this.salesOrderService.isFeePickerRunning(this.case.id)),
        tap(result => this.handlePollResult(result)),
        takeWhile(() => this.disableRunFeepicker),
        takeUntil(timer(this.pollingTimeoutMillis).pipe(switchMap(() => throwError('Fee picker status polling timeout!'))))
      )
      .subscribe(
        ignore => {},
        error => {
          console.log(error);
          this.disableRunFeepicker = false;
        }
      );
  }

  private handlePollResult(isFeePickerRunning: boolean): void {
    if (!isFeePickerRunning) {
      this.disableRunFeepicker = false;
      this.salesOrderService.getSalesOrdersByCaseId(this.case.id).subscribe(result => this.salesOrderService.sendSalesOrders(result));
    }
  }

  userUpdateDeletePermitted(): boolean {
    return this.userHasDeletePermission || this.userHasUpdatePermission;
  }
  salesOrderItemUpdatePermitted(salesOrderItem: SalesOrderItem): boolean {
    return this.userHasDeletePermission && !salesOrderItem.suggestion;
  }
  selectSuggestionPermitted(salesOrderItem: SalesOrderItem): boolean {
    return this.userHasDeletePermission && salesOrderItem.suggestion;
  }

  selectCreditAllowed(salesOrderItem: SalesOrderItem): boolean {
    return !(salesOrderItem.status == SalesOrderStatus.PAID || salesOrderItem.status == SalesOrderStatus.SENT_TO_ERP || salesOrderItem.status == SalesOrderStatus.INVOICED);
  }

  isFeePickerSecondaryActivePart(): boolean {
    return (
      !!this.feePickerStatus?.secondaryDisabledCategories &&
      this.feePickerStatus.secondaryDisabledCategories.length > 0 &&
      this.feePickerStatus.secondaryDisabledCategories.length < Object.keys(SosServiceCategoriesEnum).length
    );
  }
  openFeePickerSecondary() {
    this.feePickerSecondary.show(this.feePickerStatus);
  }
  feePickerSecondaryUpdated(updatedFeePickerStatus: FeePickerStatus) {
    this.feePickerStatus = updatedFeePickerStatus;
    this.feePickerSecondaryEnabled = updatedFeePickerStatus?.secondaryDisabledCategories?.length === 0;
  }
  refreshFeePickerStatus() {
    this.salesOrderService.getFeePickerStatus(this.case.id).subscribe(result => {
      this.feePickerStatus = result;
      this.feePickerSecondaryEnabled = this.feePickerStatus.secondaryDisabledCategories.length === 0;
    });
  }
}
