<div class="task-consent-dialog-container" (click)="checkIfThereWereChangesOnTask()">
  <div class="modal-header">
    <div class="modal-header-title">
      <span>{{ taskViewModel.title }}</span>
    </div>
    <div class="modal-buttons">
      <div (click)="closeTaskMessageDialog()">
        <img src="/assets/icons/Close White.svg" />
      </div>
    </div>
  </div>
  <app-case-lock-overlay [lockContext]="LockContextEnum.CASE_BASIC" [asOverlay]="false" [unlockPostAction]="CaseLockOverlayAction.TAKE_LOCK"></app-case-lock-overlay>
  <div class="modal-content-body">
  <div class="row m-0">
    <div class="col-3 task-details">
      <app-task-message-left-panel
        [taskCase]="taskViewModel.caseId"
        [taskId]="taskViewModel.id"
        (modalTaskLoadedEvent)="taskLoadedNotification($event)"
        #taskModal>
      </app-task-message-left-panel>
    </div>
    <div class="consent-message-details col-9">
      <div class="consent-message-header">
        <div class="consent-message-header-label">{{'task-message-modal-date' | translate}}</div>
        <div class="consent-message-header-text">{{consent ? (consent.consentDate | momentFormat : 'DD MM YYYY, HH:mm' : 'local') : '' }}</div>
        <button *ngIf="!isOnCasePage" class="primary-button goto-case-button" (click)="goToCase()">{{'task-message-modal-go-to-case' | translate }}</button>
        <div *ngIf="!isFailed">
          <span>
            <button class="primary-button-white consent-url-botton" (click)="goViewConsent()">{{'view-consent' | translate}}
            </button>
          </span>
          <span>
            <button class="primary-button-white consent-url-botton" (click)="goShareConsent()">{{'share-consent' | translate}}</button>
          </span>
        </div>
      </div>
      <div class="consent-message-stakeholder">
        <div class="consent-message-header-label">
          {{'consent-message-from' | translate}}
          <div class="border">
            <span class="m-1">
              <img class="stakeholder-icon" [src]="stakeholderIcon">
            </span>
            <span class="consent-message-stakeholder-text">
              <span>
                {{stakeholder ? stakeholder?.person?.firstName : ''}}
              </span>
              <span class="m-1">
                {{stakeholder ? stakeholder?.person?.surname : ''}}
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="horizontal-line"></div>
  <div class="row">
    <div class="col-12">
      <button class="primary-button save-button" [class.primary-button-inactive]="!changesOnTask" [disabled]="!changesOnTask" (click)="saveTaskChanges()">{{"task-message-modal-save" | translate}}</button>
    </div>
  </div>
  </div>
</div>
