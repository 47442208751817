<div class="input">
  <span [ngClass]="isValid ? 'input-name' : 'invalid-input-name'">{{ name }}</span>
  <img
    *ngIf="withImage"
    src="../../../../assets/icons/Calendar.svg"
    class="input-image-calender"
    [ngStyle]="name && { top: '28px' }"
    [owlDateTimeTrigger]="dt1"
    (click)="setOpenedFromCalendarIcon()"
  />
  <div *ngIf="startingPlaceholder" class="input-starting-placeholder">{{ startingPlaceholder }}</div>
  <input
    [owlDateTime]="dt1"
    [owlDateTimeTrigger]="dt1"
    (saveModel)="onSaveModel()"
    (change)="onChange(); onSaveModel()"
    title="{{ this.isValidMessage }}"
    spellcheck="false"
    (focus)="onSaveState()"
    [(ngModel)]="dateTimeRange"
    placeholder="{{ placeholder }}"
    [selectMode]="selectMode"
    class="input-component"
    (input)="onInputCheckEmpty($event)"
    (keydown.backspace)="onInputCheckEmpty($event)"
    [ngClass]="isValid ? 'input-component' : 'invalid-input-component'"
    [disabled]="!!disabled"
  />
  <owl-date-time
    [pickerType]="'both'"
    #dt1
    (afterPickerOpen)="afterPickerOpen()"
    [startAt]="startAt"
    (afterPickerClosed)="onChange()"
  ></owl-date-time>
</div>
