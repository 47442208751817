import { SubComponentModel } from '@secca/case/components/case-plans/case-plans/add-service-order/models/interfaces';

export class SubFuneralHomes implements SubComponentModel {
  localCaseStakeholderId: string;
  receivingCaseStakeholderId: string;

  public constructor(init?: Partial<SubFuneralHomes>) {
    Object.assign(this, init);
  }

  isValid(): boolean {
    return true;
  }

}
