<div class="recovery-payment-task-table" *ngIf="taskViewModels; else loadingTemplate">
  <div class="primary-table-container">
    <table class="primary-table fixed-header-table">
      <thead>
      <tr>
        <td class="checkbox-column-header">
          <div class="custom-checkbox checkbox-header-container">
            <label class="checkbox">
              <input type="checkbox" (click)="selectAllTasks()" [(ngModel)]="isAllSelected" />
              <span class="checkmark"></span>
            </label>
          </div>
        </td>
        <td (click)="sortBy('taskType')" [ngClass]="sortedBy('taskType')">{{ 'board-task-recovery-payment-table-type' | translate }}</td>
        <td (click)="sortBy('customerProfileId')" [ngClass]="sortedBy('customerProfileId')">{{ 'board-task-recovery-payment-table-cu' | translate }}</td>
        <td (click)="sortBy('invoiceDate')" [ngClass]="sortedBy('invoiceDate')">{{ 'board-task-recovery-payment-table-invoice-date' | translate }}</td>
        <td (click)="sortBy('receivedDate')" [ngClass]="sortedBy('receivedDate')">{{ 'board-task-recovery-payment-table-received-date' | translate }}</td>
        <td (click)="sortBy('dueDate')" [ngClass]="sortedBy('dueDate')">{{ 'board-task-recovery-payment-table-due-date' | translate }}</td>
        <td (click)="sortBy('remaining')" [ngClass]="sortedBy('remaining')">{{ 'board-task-recovery-payment-table-remaining' | translate }}</td>
        <td (click)="sortBy('caseNumber')" [ngClass]="sortedBy('caseNumber')">{{ 'board-task-recovery-payment-table-case-number' | translate }}</td>
        <td (click)="sortBy('recoveryId')" [ngClass]="sortedBy('recoveryId')">{{ 'board-task-recovery-payment-table-recovery-id' | translate }}</td>
        <td (click)="sortBy('creditorId')" [ngClass]="sortedBy('creditorId')">{{ 'board-task-recovery-payment-table-creditor' | translate }}</td>
        <td (click)="sortBy('invoiceNumber')" [ngClass]="sortedBy('invoiceNumber')">{{ 'board-task-recovery-payment-table-invoice-id' | translate }}</td>
        <td (click)="sortBy('localAmount')" [ngClass]="sortedBy('localAmount')">{{ 'board-task-recovery-payment-table-local-amount' | translate }}</td>
        <td (click)="sortBy('recoveryPaymentStatus')" [ngClass]="sortedBy('recoveryPaymentStatus')">{{ 'board-task-recovery-payment-table-status' | translate }}</td>
        <td (click)="sortBy('assignedTo')" [ngClass]="sortedBy('assignedTo')">{{ 'board-task-recovery-payment-table-assign' | translate }}</td>
      </tr>
      </thead>
      <tbody (scroll)="onCaseTableScroll()" id="taskRecoveryPaymentTable" [class.zoomed]="settingsService.isZoomed()">
      <tr *ngFor="let task of taskViewModels; let i = index" [class.task-check]="task.isSelected" [class.task-row-red]="!task.recoveryPaymentId">
        <td class="checkbox-column">
          <div class="custom-checkbox checkbox-content-container">
            <label class="checkbox">
              <input type="checkbox" [(ngModel)]="task.isSelected" />
              <span class="checkmark"></span>
            </label>
          </div>
        </td>
        <td class="type-column"><img src="/assets/icons/task/recovery-payment-todo.svg" (click)="viewTask(task)" /></td>
        <td class="customer-column">
          <img *ngIf="task.customerProfileId" [src]="getBrandImagesUrlForProfile(task.customerProfileId)" />
        </td>
        <td>{{ task.invoiceDate | date: 'dd MMM' | lowercase }}</td>
        <td>{{ task.receivedDate | momentFormat: 'DD MMM':'local' | lowercase }}</td>
        <td>{{ task.dueDate | momentFormat: 'DD MMM':'local' | lowercase }}</td>
        <td>{{ task.remaining }}</td>
        <td class="link-column" (click)="manageInvoiceAndOpenPDF(task)">
          <div *ngIf="task.caseNumber !== '-'">{{ task.caseNumber }}</div>
        </td>
        <td>{{ task.recoveryId }}</td>
        <td class="creditor-column">
          {{ task.creditorId }}&nbsp;
          <span class="creditor-column-label">{{ task.creditorName }}</span>
        </td>
        <td>{{ task.invoiceNumber }}</td>
        <td class="amount-column">
          <span class="currency-value">{{ task.localCurrency }}</span>{{ task.localAmount | number: '.2' }}
        </td>
        <td>
          <div *ngIf="task.recoveryPaymentId">{{ 'case-economy-recovery-payment-status-' + task.recoveryPaymentStatus | translate }}</div>
        </td>
        <td>{{ task.assignedTo }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
<ng-template #loadingTemplate>
  <div class="d-flex justify-content-center pt-3">
    <h4>{{ 'board-task-table-refreshing' | translate }}</h4>
  </div>
</ng-template>
