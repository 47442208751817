<div class="modal-dialog-wrapper" appDialogSelectable appDialogKeyboardEnabled [appDialogMinimizable]="MinimizableDialogType.CaseDocument">
  <mat-dialog-content (cdkDragStarted)="dialogBoundryService.handleDragStart($event)" (cdkDragEnded)="dialogBoundryService.handleDragEnd($event)" cdkDragRootElement=".cdk-overlay-pane" cdkDrag>
    <div appDialogDragArea cdkDragHandle></div>
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-header-title">
          {{ 'Preview document' | translate }}
        </div>
        <div class="modal-buttons">
          <div (click)="close()">
            <img src="/assets/icons/Close%20White.svg" />
          </div>
        </div>
      </div>
      <div class="modal-preview-body">
        <div class="row m-0">
          <pdf-viewer logLevel="0" [src]="data.arrayBufferFromBlob" [render-text]="true" style="width: 100%; height: 854px"></pdf-viewer>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
