import { Limit, LimitAdapter } from './onepoint-limit';
import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
import { CoverageTypeEnum } from './enums';

export class Coverage {
    id: number;
    coverageId: string;
    productId: number;
    coverageName: string;
    coverageNameAlias: string;
    customerCoverageId: string;
    included: string;
    limits: Limit[];
    coverageType: CoverageTypeEnum;

    public constructor(init?: Partial<Coverage>) {
        Object.assign(this, init);
    }
}

@Injectable({
    providedIn: 'root'
})
export class CoverageAdapter implements Adapter<Coverage> {
    adapt(item: any): Coverage {
        const limitAdapter = new LimitAdapter();
        return new Coverage({
            id: item.id || item.coverageId,
            coverageId: item.coverageId,
            productId: item.productId,
            coverageName: item.coverageName,
            coverageNameAlias: item.coverageNameAlias,
            customerCoverageId: item.customerCoverageId,
            limits: item.limits ? item.limits.map(limitAdapter.adapt) : [],
            included: item.included,
            coverageType: item.coverageType
        });
    }
}
