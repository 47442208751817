    <div class="d-flex" [class.even]="index % 2">
        <div class="history-table-cell align-top">
            <app-user-picture [imageURL]="entry.picture" [active]="'true'" [imageClass]="'caseSummery'">
            </app-user-picture>
            <span class="headline initials" *ngIf="!entry.previousValue">
                {{entry.initials}} {{ 'case-history-economy-refund-created' | translate }} {{entry.newValue.id}}
            </span>
            <span class="headline initials" *ngIf="entry.previousValue && !entry.newValue.deletedOn">
                {{entry.initials}} {{ 'case-history-economy-refund-updated' | translate }} {{entry.newValue.id}}
            </span>
            <span class="headline initials" *ngIf="entry.previousValue && entry.newValue.deletedOn">
                {{entry.initials}} {{ 'case-history-economy-refund-deleted' | translate }} {{entry.newValue.id}}
            </span>
             <div class="elementDateTime">{{ entry.newValue.modifiedOn | date: 'd MMM y - HH:mm' }}</div>
        </div>
        <div class="history-table-cell align-top">
            <div class="headline">
                <span *ngIf="entry.previousValue">
                    {{ 'case-history-list-original-value' | translate }}
                </span>
            </div>
            <app-previous-value [entry]="entry" [attribute]="'internalRemark'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'refundType'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'refundStatus'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'refundDate'" [display]="'date'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'recievedDate'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'refundCurrency'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'stakeHolderId'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'approvedDate'" [display]="'date'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'objection'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'dueDate'" [display]="'date'"></app-previous-value>
        </div>
        <div class="history-table-cell align-top" *ngIf="!entry.newValue.deletedOn">
            <div class="headline">
                <span>
                    {{ 'case-history-list-new-value' | translate }}
                </span>
            </div>
            <app-new-value [entry]="entry" [attribute]="'internalRemark'"></app-new-value>
            <app-new-value [entry]="entry" [attribute]="'refundType'"></app-new-value>
            <app-new-value [entry]="entry" [attribute]="'refundStatus'"></app-new-value>
            <app-new-value [entry]="entry" [attribute]="'refundDate'" [display]="'date'"></app-new-value>
            <app-new-value [entry]="entry" [attribute]="'recievedDate'" [display]="'date'"></app-new-value>
            <app-new-value [entry]="entry" [attribute]="'refundCurrency'"></app-new-value>
            <app-new-value [entry]="entry" [attribute]="'stakeHolderId'"></app-new-value>
            <app-new-value [entry]="entry" [attribute]="'approvedDate'" [display]="'date'"></app-new-value>
            <app-new-value [entry]="entry" [attribute]="'objection'"></app-new-value>
            <app-new-value [entry]="entry" [attribute]="'dueDate'" [display]="'date'"></app-new-value>
        </div>
        <div class="history-table-cell align-top" *ngIf="entry.newValue.deletedOn">
            <div class="headline">
                <span class="red-text">
                    {{ 'case-history-economy-deleted' | translate }}
                </span>
            </div>
        </div>
    </div>
