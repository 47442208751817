<div *ngIf="termsDocumentId || guidelineUri" class="case-terms-container">
  <header class="header">{{ 'case-terms-instructions' | translate }}</header>
  <div class="icon-container">

    <div *ngIf="guidelineUri" class="document-icon" (click)="openGuideDocument()">
      <div class="document-image-label label-guide">GUIDE</div>
      <img class="document-image"
           src="assets/icons/guide.svg" alt="Link to guide document">
    </div>

    <div *ngIf="termsDocumentId" class="document-icon" (click)="openTermsDocument()">
      <div class="document-image-label label-terms">TERMS</div>
      <img class="document-image"
           src="assets/icons/terms.svg" alt="Link to terms document">
    </div>

  </div>
  <div *ngIf="termsDocumentId" class="terms-info">{{ termsName + ', ' + productVersion }}</div>
</div>
