import { Component, Input, OnInit } from "@angular/core";
import {DigitalCaseflowLog} from "@secca/shared/models/digital-caseflow-log";
import {DigitalCaseflowActivityLog} from "@secca/shared/models/digital-caseflow-activity-log";
import {TranslateService} from "@ngx-translate/core";
import {DigitalCaseflowService} from "@secca/core/services/digital-caseflow.service";

@Component({
  selector: 'app-task-web-digital-caseflow-details',
  templateUrl: './task-web-digital-caseflow-details.component.html',
  styleUrls: ['./task-web-digital-caseflow-details.component.scss'],
})
export class TaskWebDigitalCaseflowDetailsComponent implements OnInit {
  @Input()
  digitalCaseflowLogId : number
  flowLog: DigitalCaseflowLog;

  translateService: TranslateService;
  digitalCaseflowService: DigitalCaseflowService;

  errorCodeTranslationPrefix: string = 'digital-caseflow-activity-log-error-';
  actionTranslationPrefix: string = 'digital-caseflow-activity-action-';
  resultTranslationPrefix: string = 'digital-caseflow-activity-result-';

  constructor(translateService: TranslateService, digitalCaseflowService: DigitalCaseflowService) {
    this.translateService = translateService;
    this.digitalCaseflowService = digitalCaseflowService;
  }

  ngOnInit() {
    this.digitalCaseflowService.getDigitalCaseflowLog(this.digitalCaseflowLogId).subscribe(
      result => {
        this.flowLog = result;
      },
      error => console.log(error)
    );
  }

  getTranslatedErrorCodes(activityLog: DigitalCaseflowActivityLog): string {
    return activityLog.activityErrorLogs.map(item => this.translateService.instant(this.errorCodeTranslationPrefix+item.errorCode)).join(',');
  }
  getTranslatedResult(activityLog: DigitalCaseflowActivityLog): string {
    return this.translateService.instant(this.resultTranslationPrefix+activityLog.result);
  }
}
