import { SimpleTaskViewModel } from './../../../../case/components/case-task/models/simple-task-view.model';
import { TaskService } from './../../../../../core/services/task.service';
import { DropDownAutoCompleteComponent } from './../../../../../shared/components/drop-down-auto-complete/drop-down-auto-complete.component';
import { DictionaryService } from 'src/app/core/services/dictionary.service';
import { SearchUserAndTeam } from './../../../../../shared/models/searchUserAndTeam';
import { TaskViewModel } from './../models/task-view.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input, ApplicationRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { AutoCompleteTypeEnum } from 'src/app/shared/components/drop-down-auto-complete/auto-complete-type-enum';
import { Subscription } from 'rxjs';
import { ShortcutService } from '@secca/core/services/shortcut.service';
import { ShortcutEnum } from '@secca/shared/models/enums';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';

@Component({
  selector: 'app-task-reassign',
  templateUrl: './task-reassign.component.html',
  styleUrls: ['./task-reassign.component.scss']
})
@AutoUnsubscribe
export class TaskReassignComponent implements OnInit {
  @ViewChild('caseHandlerDropDown') caseHandlerDropDown: DropDownAutoCompleteComponent;
  @Input() tasksForReassignTaskViewModel: TaskViewModel[];
  @Input() tasksForReassignSimpleTaskViewModel: SimpleTaskViewModel[];
  @Input() caseId: string;
  @Output() tasksReassigned = new EventEmitter();
  isCaseHandlerVisible: boolean;
  acHandler: SearchUserAndTeam;

  private shortcutSubscriptions: Subscription[] = [];

  constructor(
    private modalService: NgbModal,
    private dictionaryService: DictionaryService,
    private applicationRef: ApplicationRef,
    private taskService: TaskService,
    private shortcutService: ShortcutService,
  ) {
    this.shortcutSubscriptions.push(
      this.shortcutService.addShortcut({ keys: ShortcutEnum.BoardTaskReassignProcess }).subscribe(a => {
        if (this.acHandler) {
          this.reassignClick();
        }
      }),
    );
  }

  ngOnInit() {}

  close() {
    this.modalService.dismissAll();
  }

  get AutoCompleteTypeEnum() {
    return AutoCompleteTypeEnum;
  }

  makeCaseHandlerInVisible(e: Event) {
    e.stopPropagation();
    this.isCaseHandlerVisible = false;
  }

  caseHandlerChanged(userOrTeam: SearchUserAndTeam) {
    if (userOrTeam.isTeam) {
      this.acHandler = userOrTeam;
    } else {
      let id: number = userOrTeam.id;
      this.isCaseHandlerVisible = false;
      this.dictionaryService.searchUsersExact(id).subscribe(
        result => {
          if (result.length !== 1) {
            console.log('There is no user with id ' + id);
          } else if (result.length > 1) {
            console.log('There is more than one user with id ' + id);
          } else {
            this.acHandler = new SearchUserAndTeam();
            this.acHandler.initializeFromSearchUser(result[0]);
          }
        },
        error => console.log(error)
      );
    }
  }
  makeCaseHandlerVisible(e: Event) {
    e.stopPropagation();
    this.isCaseHandlerVisible = true;
    this.applicationRef.tick();
    this.caseHandlerDropDown.setFocus();
  }

  reassignClick() {
    let taskIds =
      this.tasksForReassignTaskViewModel != null
        ? this.tasksForReassignTaskViewModel.map(a => a.id)
        : this.tasksForReassignSimpleTaskViewModel.map(a => a.id);
    if (this.acHandler.isTeam) {
      this.taskService.reassignTasksToTeam(taskIds, this.acHandler.adtCode).subscribe(
        () => {
          this.updateTheList();
          this.close();
        },
        error => console.log(error)
      );
    } else {
      this.taskService.reassignTasksToUser(taskIds, this.acHandler.id).subscribe(
        () => {
          this.updateTheList();
          this.close();
        },
        error => console.log(error)
      );
    }
  }
  private updateTheList() {
    if (this.tasksForReassignTaskViewModel != null) {
      this.tasksForReassignTaskViewModel.forEach(task => {
        task.isCheck = false;
        task.assignInitials = this.acHandler.initials == null ? this.acHandler.teamName : this.acHandler.initials;
      });
    }
    if (this.tasksForReassignSimpleTaskViewModel != null) {
      this.tasksForReassignSimpleTaskViewModel.forEach(task => {
        task.isCheck = false;
        task.assignInitials = this.acHandler.initials == null ? this.acHandler.teamName : this.acHandler.initials;
      });
    }
    this.tasksReassigned.emit();
  }
}
