import {Component, Input, OnInit} from '@angular/core';
import {ServiceTypeBase} from '../../models/serviceTypeBase';
import {ServiceTypeComponent} from './../../models/interfaces';
import {SubStakeholdersConfig} from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholdersConfig';
import {ServiceTypeEnum} from '@secca/shared/models/enums';
import {SubStakeholders} from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholders';
import {ServiceOrderTypeState} from '@secca/case/components/case-plans/case-plans/add-service-order/models/serviceOrderTypeState';
import {CaseStakeholder} from '@secca/shared/models/caseStakeholder';
import {AssistanceOrContactServiceOrderExtension} from "@secca/shared/models/service-order/assistance-or-contact-service-order-extension";

@Component({
  selector: 'app-assistance-or-contact',
  templateUrl: './assistance-or-contact.component.html',
  styleUrls: ['./assistance-or-contact.component.scss'],
})
export class AssistanceOrContactComponent extends ServiceTypeBase implements OnInit, ServiceTypeComponent {
  @Input() caseId: string;
  @Input() serviceOrderTypeState: ServiceOrderTypeState;
  @Input() assistanceOrContact: AssistanceOrContactServiceOrderExtension;  
  @Input() stakeholders: CaseStakeholder[];
  @Input() leftSideValid: boolean;

  subStakeholders: SubStakeholders;
  //serviceOrderType = ServiceTypeEnum.ASSISTANCE_OR_CONTACT;

  private static subConfirmationStakeholderConfig = new SubStakeholdersConfig({
    hideClass: true,
    hideInclDateOfBirth: true,
    hideInclPassportDetails: true,
    requireAtLeastOneStakeholder: true
  });

  constructor() {
    super();
  }

  ngOnInit() {
    this.subStakeholders = new SubStakeholders(this.assistanceOrContact.stakeholders);
  }

  isValid(): boolean {
    let result: boolean;
    result = this.assistanceOrContact.isValid()
    return result;
  }

  getModel(): any {
    return this.assistanceOrContact;
  }

  disableSendRequest(): boolean {
    return !(this.isValid() && !!this.leftSideValid && this.serviceOrderTypeState.isSupplierChosen);
  }

  startDateChange(date) {
    this.assistanceOrContact.startDate = date;
  }

  sendConfirmation() {
     this.serviceOrderTypeState.sendRequestRequested = true;
     this.serviceOrderTypeState.sendConfirmationEvent.emit();
  }

  get subConfirmationStakeholderConfig(): SubStakeholdersConfig {
    return AssistanceOrContactComponent.subConfirmationStakeholderConfig;
  }
}
