import { SubRemarksToSupplier } from './../../../modules/case/components/case-plans/case-plans/add-service-order/models/subRemarksToSupplier';
import { SubCoffinUrn } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subCoffinUrn';
import { SubFuneralHomes } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subFuneralHomes';
import { SubGopDetails } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subGopDetails';
import { SubReference } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subReference';
import { SubRemarksFromSupplier } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subRemarksFromSupplier';
import { Duration, DurationAdapter } from '../duration';
import { MortalRemainsType } from '../enums';
import { ServiceOrderExtension } from './service-order-extension';
import { Location } from '@secca/shared/models/location';
import * as moment from 'moment-timezone';
import { CommonServiceOrderExtension } from './CommonServiceOrderExtension.component';
import { CommitmentType, CommitmentTypeAdapter } from '../commitment-type';

export class MortalRemainsServiceOrderExtension extends CommonServiceOrderExtension implements ServiceOrderExtension {
  id: number;
  type: MortalRemainsType;
  duration: Duration;
  coffinUrn: SubCoffinUrn;
  funeralHomes: SubFuneralHomes;
  commitmentType: CommitmentType;
  reference: SubReference;
  gopDetails: SubGopDetails;
  remarksToSupplier: SubRemarksToSupplier;
  remarksFromSupplier: SubRemarksFromSupplier;
  // Only used for display purposes in plans
  displayDurationId: number;

  public constructor(init?: Partial<MortalRemainsServiceOrderExtension>) {
    super();
    Object.assign(this, init);
    if (!init) {
      this.type = MortalRemainsType.NOT_DECIDED;
      this.coffinUrn = new SubCoffinUrn();
      this.commitmentType = null;// TODO new SubCommitmentType(CommitmentTypes.GUARANTEE_OF_PAYMENT);
      this.funeralHomes = new SubFuneralHomes();
      this.reference = new SubReference();
      this.duration = new Duration();
      this.remarksFromSupplier = new SubRemarksFromSupplier();
      this.remarksToSupplier = new SubRemarksToSupplier();
    }
  }
  init(defaultBillingCurrency) {}

  isPreviewGopValid(): boolean {
    throw new Error("Gop preview not supported for this service order type");
  }

  isValid() {
    return this.isDurationValid();
  }

  isValidExpectation(): boolean {
    return this.isDurationValid();
  }

  setCommitmentType(commitmentType: CommitmentType) {
    this.commitmentType = commitmentType;
  }

  getCommitmentType(): CommitmentType {
    return this.commitmentType;
  }

  getStartDate(): moment.Moment {
    return this.duration.startDate;
  }

  getStartLocation(): Location {
    return this.duration.location;
  }

  getEndLocation(): Location {
    return this.getStartLocation();
  }

  getEndDate(): moment.Moment {
    return this.duration.endDate;
  }

  isMultipleDurations(): boolean {
    return false;
  }

  hasMedicalEscort(): boolean {
    return false;
  }

  hasCoTravellers(): boolean {
    return false;
  }

  isDurationValid() {
    return this.duration.isStartDateValid() && this.duration.location.freeText !== '';
  }

  getSelectedStakeholderIds(): string[] {
    return null;
  }

}

export class MortalRemainsServiceOrderExtensionAdapter {
  adapt(item?: Partial<MortalRemainsServiceOrderExtension>): MortalRemainsServiceOrderExtension {
    const durationAdapter = new DurationAdapter();
    const commitmentTypeAdapter: CommitmentTypeAdapter = new CommitmentTypeAdapter();
    return new MortalRemainsServiceOrderExtension({
      id: item.id,
      type: item.type,
      funeralHomes: item.funeralHomes ? new SubFuneralHomes(item.funeralHomes) : new SubFuneralHomes(),
      duration: item.duration == null ? new Duration() : durationAdapter.adapt(item.duration),
      coffinUrn: item.coffinUrn == null ? new SubCoffinUrn() : new SubCoffinUrn(item.coffinUrn),
      commitmentType:
        item.commitmentType == null
          ? null/*new SubCommitmentType(CommitmentTypes.GUARANTEE_OF_PAYMENT)*/
          : commitmentTypeAdapter.adapt(item.commitmentType),
      reference: item.reference == null ? new SubReference() :  new SubReference(item.reference),
      remarksFromSupplier: item.remarksFromSupplier == null ? new SubRemarksFromSupplier() : item.remarksFromSupplier,
      remarksToSupplier: item.remarksToSupplier == null ? new SubRemarksToSupplier() : item.remarksToSupplier,
    });
  }
}
