
<div class="modal-dialog-wrapper" appDialogSelectable appDialogKeyboardEnabled [enabledKeys]="enabledKeys" [appDialogMinimizable]="getMinimizableDialogType()" [isMinimizeIconHidden]="data.isMinimizeIconHidden">
    <mat-dialog-content (cdkDragStarted)="dialogBoundryService.handleDragStart($event)" (cdkDragEnded)="dialogBoundryService.handleDragEnd($event)" cdkDragRootElement=".cdk-overlay-pane" cdkDrag>
        <div appDialogDragArea cdkDragHandle></div>
        <app-message-dialog [case]="data.seccaCase" 
                            [messageChannelType]="data.messageChannelType" 
                            [messageRequest]="data.messageRequest" 
                            [consent]="data.consent"
                            (messageSentEvent)="data.messageSentEvent ? data.messageSentEvent($event) : null"
                            (closed)="close()"></app-message-dialog>
    </mat-dialog-content>
</div>
