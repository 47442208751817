import { Component, Input } from '@angular/core';
import { ServiceTypeBase } from './../../models/serviceTypeBase';
import { ServiceTypeComponent } from './../../models/interfaces';
import { MedicalEscortServiceOrderExtension } from '@secca/shared/models/service-order/medical-escort-service-order-extension';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-medical-escort',
  templateUrl: './medical-escort.component.html',
})
export class MedicalEscortComponent extends ServiceTypeBase implements ServiceTypeComponent {
  @Input() medicalEscort: MedicalEscortServiceOrderExtension;
  @Input() caseId: string;

  constructor(private translate: TranslateService) {
    super();
  }

  isValid(): boolean {
    return this.medicalEscort.isValid();
  }

  getModel() {
    return this.medicalEscort;
  }

  initialize() {
  }

}
