<ng-template #content let-modal>
  <div class="edit-customer-invoice-note">
    <div class="edit-customer-invoice-note-header">
      <div class="edit-customer-invoice-note-header-close float-end clearfix">
        <img (click)="closeModal()" src="../../../../../../assets/icons/Close.svg" alt="Close" />
      </div>
      <div class="edit-customer-invoice-note-header-title">
        {{ 'case-economy-customer-invoices-note-modal' | translate }}
      </div>
    </div>
    <div class="edit-customer-invoice-note-body">
      <app-text-box [maxTextLength]="250" [(value)]="customerInvoiceNote"></app-text-box>
    </div>
    <div class="modal-footer">
      <div class="d-flex justify-content-end">
        <button (click)="clearCustomerInvoiceNote()" [disabled]="!salesOrder.customerInvoiceNote || saveInProgress">
          {{ 'case-economy-customer-invoice-clear-note' | translate }}
        </button>
        <button (click)="saveCustomerInvoiceNote()" [disabled]="saveInProgress">
          {{ 'case-economy-sos-services-modify-save' | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>
