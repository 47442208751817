import { DineroObject } from 'dinero.js';
import { Directive, ElementRef, EventEmitter, Injectable, Input, OnInit, Output, Renderer2 } from '@angular/core';

@Directive({ selector: '[sortColumn]' })
@Injectable({ providedIn: 'root'})
export class SortDirective implements OnInit {
  @Input() data: any[];
  @Input('sortKey') key: any;
  @Input('toggleSort') toggleSort: boolean = false;
  @Output('sortEvent') sortEvent = new EventEmitter<string>();

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.renderer.listen(this.el.nativeElement, 'click', event => {
    let parentNode = this.el.nativeElement.parentNode;
    let children = parentNode.children;
    if (this.data && this.key) {
        let sortedData: any = this.sortArray();
        this.sortEvent.emit(this.key);
    }
    this.toggleSort = !this.toggleSort;
});
  }

  sortArray(): Array<any> {
    let tempArray: Array<any> = this.data;

    let orderingAscDesc: number = this.toggleSort ? 1 : -1;

    tempArray.sort((a, b) => {
      let aVal = a[this.key];
      let bVal = b[this.key];

      if ((!aVal && aVal !== 0) || (!bVal && bVal !== 0)) {
        return !aVal && !bVal && aVal !== 0 && bVal !== 0 ? 0 : (!aVal && aVal !== 0 ? 1 : -1);
      }

      if (aVal instanceof Function) {
        if ( a[this.key]() ) {
          aVal = a[this.key]();
          bVal = b[this.key]();
        }
      }

      if (!isNaN(aVal.amount)) {
        aVal = aVal.amount;
        bVal = bVal.amount;
      }

      if (typeof aVal === 'string') {
        aVal = aVal != null ? aVal.toString().toLowerCase() : '';
        bVal = bVal != null ? bVal.toString().toLowerCase() : '';
      }

      return aVal === bVal ? 0 : (aVal < bVal ? -orderingAscDesc : orderingAscDesc);
    });

    return tempArray;
  }
}
