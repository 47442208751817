
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  public static NUMBER_OF_HOURS_FOR_PENDING_TASKS = 12;
  public static ONE_MINUTE_IN_MILLIS = 60000;
  public static HALF_MINUTE_IN_MILLIS = 30000;

  constructor() {}

  public compareValues(key, order = 'asc') {
    return function (a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }
      const varA = typeof a[key] === 'string' ? a[key].toLowerCase() : a[key];
      const varB = typeof b[key] === 'string' ? b[key].toLowerCase() : b[key];
      let comparison = 0;
      if (varA < varB) {
        comparison = 1;
      } else if (varA > varB) {
        comparison = -1;
      }
      return order == 'desc' ? comparison * -1 : comparison;
    };
  }

  public get freeTextFieldPlaceholder() {
    return 'No sensitive personal data, ex. health info not relevant for the case';
  }

  public getRandomGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  public toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  public translateErrorCode(code: string): string {
    switch (code) {
      case 'RESOURCE_NOT_FOUND':
        return 'Resource not found';
      case 'PAGE_NOT_FOUND':
        return 'Page not found';
      case 'CASE_NUMBER_GENERATION':
        return 'Case number generation';
      case 'NATIONAL_ID_PARSER_ERROR':
        return 'National Id parser error';
      case 'SECURITY_MATRIX_PARSE_ERROR':
        return 'Security matrix parse error';
      case 'PROPERTY_MAPPING_ERROR':
        return 'Property mapping error';
      case 'GENERAL_ERROR':
        return 'General error occured';
      case 'UNKNOWN_ERROR':
        return 'Unknown error occured';
      default:
        return code;
    }
  }

  public getEnumKeyByEnumValue(myEnum, enumValue): any {
    let keys = Object.keys(myEnum).filter(x => myEnum[x] === enumValue);
    return keys.length > 0 ? keys[0] : null;
  }

  public getBaseUrl(): string {
    const pathArray = window.location.href.split('/');
    const protocol = pathArray[0];
    const host = pathArray[2];
    return protocol + '//' + host;
  }

  public convertBase64ToArrayBuffer(base64: any): any {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    let bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  public getNumberFromString(input: any): number {
    if (isNaN(input)) {
      return !!input ? Number(input.replace(/\D/g, '')) : null;
    } else {
      return input;
    }
  }
}
