import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-text-box',
  templateUrl: './text-box.component.html',
  styleUrls: ['./text-box.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TextBoxComponent implements OnInit {
  @Input() name: string;
  @Input() value: string;
  @Input() maxTextLength: number;
  @Input() placeHolder = '';
  @Input() alternativeColor: string;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() mediumView: boolean;
  @Input() largeView: boolean;
  @Input() numberOfRows: number = 3;
  @Input() recommended: boolean;
  isRequired: boolean;

  @Output() valueChange = new EventEmitter();
  height;

  constructor() {}

  onChange() {
    this.valueChange.emit(this.value);
    this.isRequired = this.required && (this.value == null || this.value === '');
  }

  ngOnInit() {
    this.height = (23 * +this.numberOfRows) + (+this.numberOfRows === 1 ? 11 : 0);
    if (this.maxTextLength === undefined) {
      this.maxTextLength = Number.MAX_SAFE_INTEGER;
    }
    this.isRequired = this.required && (this.value == null || this.value === '');
  }
}
