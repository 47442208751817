import { Component, Output, OnInit, Input, ViewEncapsulation, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ToggleSwitchComponent implements OnInit {
  @Input() name: string;
  @Input() disabled: boolean;
  @Input() value: any;
  @Output() valueChange = new EventEmitter();
  @Output() saveModel = new EventEmitter();

  constructor() { }

  onChange() {
    this.valueChange.emit(this.value);
  }

  onSaveModel() {
    this.valueChange.emit(this.value);
    this.saveModel.emit();
  }

  ngOnInit() {
  }
}
