import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ServiceOrderHoverExtensionComponent } from '../service-order-hover-extension-component';
import { MajorIncidentServiceOrderExtension } from '@secca/shared/models/service-order/major-incident-service-order-extension';
import { ServiceOrder } from '@secca/shared/models/service-order/service-order';
import { TranslateService } from '@ngx-translate/core';
import { MasterListService } from '@secca/core/services/masterlist-service';
import { StatusTypes } from '@secca/shared/models/enums';
import { CommitmentType } from '@secca/shared/models/commitment-type';

@Component({
  selector: 'app-major-incident-service-order-hover',
  templateUrl: './major-incident-service-order-hover.component.html',
  styleUrls: ['./major-incident-service-order-hover.component.scss']
})
export class MajorIncidentServiceOrderHoverComponent implements ServiceOrderHoverExtensionComponent , OnChanges, OnInit {

  @Input() serviceOrder: ServiceOrder;
  majorIncidentServiceOrderExtension: MajorIncidentServiceOrderExtension;
  @Input() providerName: string;
  @Input() agentName: string;
  @Input() incidentEvent: string;
  incidentEventName: string;


  constructor(private translateService: TranslateService,
              private masterListService: MasterListService,
  ){
  }

  ngOnInit() {
    this.majorIncidentServiceOrderExtension = this.serviceOrder.extension as MajorIncidentServiceOrderExtension;

    this.masterListService.getIncidentEvents().subscribe(result => {
      result.forEach(event => {
        if(event.businessKey === this.incidentEvent) {
          this.incidentEventName = event.name;
        }
      })
    });

  }

  getCategory(): string {
    return this.translateService.instant('major-incident-service-order-category-' + this.majorIncidentServiceOrderExtension.majorIncidentCategory);
  }

  get statusType(): string {
    switch (this.serviceOrder.status) {
      case StatusTypes.CANCELLED:
        return 'service-order-cancelled';
      case StatusTypes.COMMITTED:
        return 'service-order-committed';
      case StatusTypes.EXPECTED:
        return 'service-order-expected';
      case StatusTypes.NOT_COVERED:
        return 'service-order-not-covered';
      case StatusTypes.PARTIALLY_COMMITTED:
        return '';
    }
  }

  get commitmentType(): CommitmentType {
    return this.serviceOrder.extension.getCommitmentType();
  }

  getStartTimeZone(): string {
    return this.serviceOrder.getStartLocation().timeZoneId ? this.serviceOrder.getStartLocation().timeZoneId : 'local';
  }

  ngOnChanges() { }

  onRefresh() {  }

}
