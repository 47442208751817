import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
import { DateSerializer } from '@secca/shared/models/date-serializer';
import { EconomyHistory } from './refund-history';
import * as moment from 'moment';


export class RecoveryItem implements EconomyHistory {
  id: number;
  serviceItemCode: string;
  serviceItemName: string;
  supplierInvoiceNumber: string;
  supplierPaidAmount: number;
  supplierPaidAmountBeforeSavings: number;
  supplierLocalCurrency: string;
  supplierInvoiceKreditor: string;
  appliedAmount: number;
  recoveredAmount: number;
  recoveryCurrency: string;
  salesOrderInvoiceNo: string;
  serviceOrderId: string;
  salesOrderId: number;
  salesOrderItemId: number;
  supplierInvoiceItemId: number;
  remittanceAdviceDocumentId: string;
  modifiedBy: string;
  modifiedOn: moment.Moment;
  modifiedUserName: string;
  deletedBy: string;
  deletedOn: moment.Moment;

  public constructor(init?: Partial<RecoveryItem>) {
    Object.assign(this, init);
  }

  identicalHistory(recoveryItem: RecoveryItem): boolean {
    return this.serviceOrderId === recoveryItem.serviceOrderId &&
           this.serviceItemCode === recoveryItem.serviceItemCode &&
           this.serviceItemName === recoveryItem.serviceItemName &&
           this.supplierPaidAmount === recoveryItem.supplierPaidAmount &&
           this.supplierPaidAmountBeforeSavings === recoveryItem.supplierPaidAmountBeforeSavings &&
           this.supplierLocalCurrency === recoveryItem.supplierLocalCurrency &&
           this.supplierInvoiceKreditor === recoveryItem.supplierInvoiceKreditor &&
           this.appliedAmount === recoveryItem.appliedAmount &&
           this.recoveredAmount === recoveryItem.recoveredAmount &&
           this.salesOrderInvoiceNo === recoveryItem.salesOrderInvoiceNo;
  }
}

@Injectable({
    providedIn: 'root'
  })
  export class RecoveryItemAdapter implements Adapter<RecoveryItem> {
    adapt(item: any): RecoveryItem {
      return new RecoveryItem({
        id: item.id,
        serviceItemCode: item.serviceItemCode,
        serviceItemName: item.serviceItemName,
        supplierInvoiceNumber: item.supplierInvoiceNumber,
        supplierPaidAmount: item.supplierPaidAmount,
        supplierPaidAmountBeforeSavings: item.supplierPaidAmountBeforeSavings,
        supplierLocalCurrency: item.supplierLocalCurrency,
        supplierInvoiceKreditor: item.supplierInvoiceKreditor,
        appliedAmount: item.appliedAmount,
        recoveredAmount: item.recoveredAmount,
        recoveryCurrency: item.recoveryCurrency,
        salesOrderInvoiceNo: item.salesOrderInvoiceNo,
        serviceOrderId: item.serviceOrderId,
        salesOrderId: item.salesOrderId,
        salesOrderItemId: item.salesOrderItemId,
        supplierInvoiceItemId: item.supplierInvoiceItemId,
        remittanceAdviceDocumentId: item.remittanceAdviceDocumentId,
        modifiedBy: item.modifiedBy,
        modifiedOn: item.modifiedOn ? DateSerializer.deserialize(item.modifiedOn) : null,
        modifiedUserName: item.modifiedUserName
      });
    }
  }

