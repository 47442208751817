import { RecoveryItem } from './recovery-Item';


export class RecoveryViewItem extends RecoveryItem {
  isInvoiceItemGroupStart: boolean;
  isInvoiceItemIncluded: boolean;
  isNotFoundWarning: boolean;

  public constructor(init?: Partial<RecoveryViewItem>) {
    super(init);
    Object.assign(this, init);
  }
}

