import * as moment from 'moment';

export class DaySerializer {
  static ISO_LOCAL_DATE = 'YYYY-MM-DD';
  static serialize(day: moment.Moment): string  {
    if (day) {
      return day.utc().format(this.ISO_LOCAL_DATE);
    } else {
      return null;
    }
  }

  static  deserialize(dayStr: string): moment.Moment {
    if (dayStr) {
      return moment.utc(dayStr, this.ISO_LOCAL_DATE);
    }
    return null;
  }
}
