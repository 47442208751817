import { TaskFilterRequest } from './task-filter-request.model';
import * as moment from 'moment';
import { MedicalPreAssessmentStatusEnum, SortOrder } from '@secca/shared/models/enums';

export class PreDepartureTaskFilterRequest extends TaskFilterRequest {
  createdOnFrom: moment.Moment;
  createdOnTo: moment.Moment;
  dateRange: moment.Moment[];
  sortBy: string;
  userIds: number[];
  adtCodes: string[];
  pageIndex: number;
  pageSize: number;
  sortOrder: SortOrder;
  includeOtherTeams: boolean;
  status: MedicalPreAssessmentStatusEnum[];
  customerProfileId: number;
  adtCodesForCount: string[];

    public constructor(init?: Partial<PreDepartureTaskFilterRequest>) {
        super();
        Object.assign(this, init);
      }
}