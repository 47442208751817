import { Injectable } from "@angular/core";
import { TabData } from "@secca/shared/models/tab-data";

@Injectable({
  providedIn: 'root',
})
export class TopTabsService {
  tabs: TabData[] = [];

  tabWithCaseIdExists(caseId: number): boolean {
    return !!this.getTabFromCaseId(caseId);
  }

  getTabFromCaseId(caseId: number) {
    return this.tabs.find(tab => +tab.caseId === +caseId );
  }
}