import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DictionaryService } from '@secca/core/services/dictionary.service';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { InsuranceService } from '@secca/core/services/insurance.service';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { Subscription } from 'rxjs';
import { ServiceOrderType } from '@secca/shared/models/service-order-type';
import { CommitmentType } from '@secca/shared/models/commitment-type';
import { DropdownWithHoverDictionary } from '@secca/shared/models/dropdownWithHoverDictionary';
import { CommitmentTypes, ServiceTypeEnum, StatusTypes } from '@secca/shared/models/enums';
import { Case } from '@secca/shared/models/case';

@Component({
  selector: 'app-sub-commitment-type',
  templateUrl: './sub-commitment-type.component.html',
  styleUrls: ['./sub-commitment-type.component.scss'],
})
@AutoUnsubscribe
export class SubCommitmentTypeComponent implements OnInit {

  @Input() readOnlyMode: false;

  @Input() commitmentType: CommitmentType;
  @Output() commitmentTypeChange = new EventEmitter<CommitmentType>();
  @Input() serviceOrderType: ServiceOrderType;
  @Input() status: StatusTypes;
  @Input() caseId: string;

  $caseSubscr: Subscription;

  commitmentTypes: DropdownWithHoverDictionary[] = [];
  private filteredCommitmentTypes: CommitmentType[];

  constructor(private dictionaryService: DictionaryService,
              private caseStateService: CaseStateService,
              private insuranceService: InsuranceService) {
  }

  ngOnInit() {
    this.filteredCommitmentTypes = [];
    this.$caseSubscr = this.caseStateService.getCase().subscribe(caseObject => {
      const caseId = caseObject.id;
      this.commitmentTypes = this.dictionaryService.getCommitmentTypeDictionary(this.serviceOrderType.commitmentTypes);
      if (this.caseId === caseId.toString()) {
        this.insuranceService.getCustomerProfileInfo(caseId).subscribe(info => {
          this.adjustCommitmentsTypes(info.claimsAgreement);
        });
      }
    });
  }

  private adjustCommitmentsTypes(claimsAgreement?: boolean) {
    this.filteredCommitmentTypes = !!claimsAgreement ?
    // claims agreement exclude !commit.sosClaims & commit.refund
    this.serviceOrderType.commitmentTypes.filter(type => ( (!type.refund)) || (type.refund && type.sosClaims)) :
      // ikke claims agreement exclude commit.sosClaim & commit.refund
    this.serviceOrderType.commitmentTypes.filter(type => (( !type.refund) ||  (type.refund && !type.sosClaims)) );

    this.commitmentTypes = this.dictionaryService.getCommitmentTypeDictionary(this.filteredCommitmentTypes);
    this.setFirstCommitType(this.commitmentTypes);
  }

  commitmentTypeChanged(commitmentType): void {
    this.commitmentType = commitmentType.value;
    this.commitmentTypeChange.emit(commitmentType.value);
  }

  setFirstCommitType(commitmentTypes: DropdownWithHoverDictionary[]): void {
    if (commitmentTypes.length === 1){
      this.commitmentType = commitmentTypes[0].value;
      this.commitmentTypeChange.emit(this.commitmentType);
    }
  }

  get ServiceTypeEnum() {
    return ServiceTypeEnum;
  }
}
