import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NationalIdService {
  public getMaskedId(country: string, nationalId: string) {
    if(nationalId !== '') {
      if (country === 'DK' || country === 'SE') {
        return nationalId.substr(0, 7) + 'XXXX';
      } else if (country === 'IS') {
        return nationalId.substr(0, 6) + 'XXXX';
      } else if (country === 'NO' || country === 'FI') {
        return nationalId.substr(0, 6) + 'XXXXX';
      } else if (country === 'FO') {
        return nationalId.substr(0, 6) + 'XXX';
      } else if (country === 'LV') {
        return nationalId.substr(0, 7) + 'XXXXX';
      } else if (country === 'EE' || country === 'LT') {
        return 'X' + nationalId.substr(1, 6) + 'XXXX';
      } else {
        return nationalId;
      }
    }
    else {
      return '';
    }
  }

  public getUnMaskedId(country: string, nationalId: string) {
    if (country === 'DK' || country === 'SE' || country === 'LT') {
      return nationalId.substr(0, 7) + '-' + nationalId.substr(7);
    } else if (country === 'IS' || country === 'NO' || country === 'FI' ||
               country === 'FO' || country === 'LV' || country === 'EE' ) {
      return nationalId.substr(0, 6) + '-' + nationalId.substr(6);
    } else {
      return nationalId;
    }
  }

  public isNationalIdMasked(country: string | null): boolean {
    return (
      country === 'DK' ||
      country === 'SE' ||
      country === 'IS' ||
      country === 'NO' ||
      country === 'FI' ||
      country === 'FO' ||
      country === 'LV' ||
      country === 'EE' ||
      country === 'LT'
    );
  }
}
