import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
import * as moment from 'moment';

export class Questionnaire {
  id: number;
  questionCode: string;
  questionName: string;
  flow: string;
  description: string;
  uiSortIndex: number;
  activateOn: moment.Moment;
  active: boolean;

  public constructor(init?: Partial<Questionnaire>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireAdapter implements Adapter<Questionnaire> {
  adapt(item: any): Questionnaire {
    return new Questionnaire({
      id: item.id,
      questionCode: item.questionCode,
      questionName: item.questionName,
      flow: item.flow,
      description: item.description,
      uiSortIndex: item.uiSortIndex,
      activateOn: item.activateOn,
      active: item.active
    });
  }
}
