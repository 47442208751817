<div class="card">

  <div class="sub-component-placeholder">
    <app-sub-reference [(subReference)]="assistanceOrContact.reference"></app-sub-reference>
  </div>

  <div class="sub-component-placeholder">
    <div class="sub-component-container">
      <div class="sub-title">{{ 'sub-stakeholders-title' | translate }}</div>
      <div class="sub-content-container">
        <app-sub-stakeholders
          [caseId]="caseId"
          [stakeholders]="stakeholders"
          [subStakeholders]="subStakeholders"
          [disabled]="false"
          [subStakeholdersConfig]="subConfirmationStakeholderConfig"
        >
        </app-sub-stakeholders>
      </div>
    </div>
  </div>
  <div class="sub-component-placeholder">
    <app-sub-remarks-to-supplier [readOnlyMode]="serviceOrderTypeState.requestSent"
                                 [(subRemarksToSupplier)]="assistanceOrContact.remarksToSupplier"></app-sub-remarks-to-supplier>
  </div>

  <div class="sub-component-placeholder">
    <div class="col-5">
      <app-input-date-time [name]="'money-transfer-start-date' | translate" [date]="assistanceOrContact.startDate"
                           (dateChange)="startDateChange($event)" [withImage]="true" [timeZone]="'secca'">
      </app-input-date-time>
    </div>
  </div>

  <div class="sub-component-placeholder bottom">
    <div class="col-12">
      <button class="primary-button float-end" (click)="sendConfirmation()"
              [disabled]="disableSendRequest()">{{'regular-flight-non-medical-send-request' | translate}}</button>
    </div>
  </div>

</div>
