<div *ngIf="entry" class="consent-management-summary">
  <div class="consent-management-summary-header">
    <span class="consent-status-box">
      <span [ngClass]="getBackgroundClass(newConsentType)" class="consent-text">
        {{newConsentType | translate}} </span>
      <span *ngIf="stakeholderIcon">
        <img class="stakeholder-icon" [src]="stakeholderIcon">
      </span>
      <span>
        {{stakeholderName}}
      </span>
    </span>
  </div>
  <div class="consent-management-summary-line"></div>
  <div *ngIf="getExplainText(newConsentType).length > 1">
    <span class="consent-management-summary-value"> {{getExplainText(newConsentType)}}
      <span class="values-blue-color" *ngIf="isConsentWeb">{{ channelType | translate }}</span>
    </span>
      <span class="float-end" *ngIf="showViewUrls">
      {{'view-consent' | translate}}
      <img class="stakeholder-icon" [src]="urlIcon" (click)="openACUrl()">
      {{'share-consent' | translate}}
      <img class="stakeholder-icon" [src]="urlIcon" (click)="openDoctorUrl()">
      </span>
    <div *ngIf="showReasonText">
      <div class="consent-management-summary-title">{{'consent-reason' | translate}}</div>
      <div class="consent-management-summary-value"> {{reasonText}} </div>
    </div>
  </div>
</div>
