import { AfterViewInit, Directive, ElementRef, Input, OnChanges, Renderer2, RendererStyleFlags2 } from '@angular/core';

const Constants = {
  DISABLED: 'disabled',
  DISABLED_COLOR: '#AAAAAA',
  DISABLED_BG_COLOR: '#BDC7D033',
  APP_DISABLED_MARKER: 'app-disabled-marker',
  ENABLED_COLOR: '',
  ENABLED_BG_COLOR: '',
  ENABLED_POINTER_EVENTS: 'all',
  DISABLED_POINTER_EVENTS: 'none',
  TAB_INDEX: 'tabindex',
  TAG_ANCHOR: 'a'
};

@Directive({
  selector: '[appDisableInput]'
})
export class DisableInputDirective implements OnChanges, AfterViewInit {

  // tslint:disable: no-bitwise
  POINTER_EVENTS = 0x1;
  FOREGROUND_COLOR = 0x2;
  BACKGROUND_COLOR = 0x4;

  disabledTags: string[] = ['input', 'textarea', 'button', 'img'];

  // tslint:disable: object-literal-key-quotes
  specialHandledElements = new Map([
    ['caselock-no-events', this.POINTER_EVENTS],
    ['input-component', this.POINTER_EVENTS | this.FOREGROUND_COLOR | this.BACKGROUND_COLOR],
    ['text-box-component', this.POINTER_EVENTS | this.FOREGROUND_COLOR | this.BACKGROUND_COLOR],
    ['switchBox', this.POINTER_EVENTS],
    ['checkbox', this.POINTER_EVENTS],
    ['input-date', this.POINTER_EVENTS | this.FOREGROUND_COLOR | this.BACKGROUND_COLOR],
    ['prefix-input-component', this.POINTER_EVENTS | this.FOREGROUND_COLOR | this.BACKGROUND_COLOR],
    ['ng-select-container', this.POINTER_EVENTS | this.FOREGROUND_COLOR | this.BACKGROUND_COLOR],
    ['selected-item-box', this.POINTER_EVENTS | this.FOREGROUND_COLOR | this.BACKGROUND_COLOR],
    ['multiselect-dropdown', this.POINTER_EVENTS | this.FOREGROUND_COLOR | this.BACKGROUND_COLOR],
    ['input-image-calender', this.POINTER_EVENTS | this.FOREGROUND_COLOR | this.BACKGROUND_COLOR],
    ['checkmark', this.POINTER_EVENTS | this.BACKGROUND_COLOR],
    ['iconBox', this.POINTER_EVENTS | this.BACKGROUND_COLOR],
    ['action-button-menu-button', this.POINTER_EVENTS | this.BACKGROUND_COLOR],
    ['action-button-menu', this.POINTER_EVENTS | this.BACKGROUND_COLOR],
    ['context-menu', this.POINTER_EVENTS | this.BACKGROUND_COLOR],
    ['primary-button', this.POINTER_EVENTS | this.BACKGROUND_COLOR],
    ['reset-button', this.POINTER_EVENTS | this.BACKGROUND_COLOR],
    ['submit-button', this.POINTER_EVENTS | this.BACKGROUND_COLOR],
    ['save-button', this.POINTER_EVENTS | this.BACKGROUND_COLOR],
    ['fax-button', this.POINTER_EVENTS | this.BACKGROUND_COLOR],
    ['email-button', this.POINTER_EVENTS | this.BACKGROUND_COLOR],
    ['sms-button', this.POINTER_EVENTS | this.BACKGROUND_COLOR],
    ['dropdown', this.POINTER_EVENTS | this.BACKGROUND_COLOR],
    ['case-handler', this.POINTER_EVENTS],
    ['no-col-on', this.POINTER_EVENTS],
    ['TBD-col-on', this.POINTER_EVENTS],
    ['yes-col-on', this.POINTER_EVENTS],
    ['no-col-off', this.POINTER_EVENTS],
    ['TBD-col-off', this.POINTER_EVENTS],
    ['yes-col-off', this.POINTER_EVENTS]
  ]);

  specialHandledElementsEntries = [...this.specialHandledElements.entries()];

  @Input() appDisableInput = false;

  constructor(private eleRef: ElementRef, private renderer: Renderer2) {
  }

  ngOnChanges() {
    this.disableElement(this.eleRef.nativeElement);
  }

  ngAfterViewInit() {
    this.disableElement(this.eleRef.nativeElement);
  }

  private disableElement(element: any) {
    if ( this.isHandledElement(element) ) {

      if (this.appDisableInput) {

          if (!element.hasAttribute(Constants.DISABLED)) {
              this.renderer.setAttribute(element, Constants.APP_DISABLED_MARKER, '');
              this.renderer.setAttribute(element, Constants.DISABLED, 'true');

              // disabling anchor tab keyboard event
              if (element.tagName.toLowerCase() === Constants.TAG_ANCHOR) {
                this.renderer.setAttribute(element, Constants.TAB_INDEX, '-1');
              }

              if ( element.classList ) {
                  this.specificElementsHandling(element,
                                                Constants.DISABLED_POINTER_EVENTS, Constants.DISABLED_COLOR, Constants.DISABLED_BG_COLOR);
              }
        }

      } else {

        if (element.hasAttribute(Constants.APP_DISABLED_MARKER)) {

          if (element.getAttribute('disabled') !== '') {
            element.removeAttribute(Constants.DISABLED);
          }

          element.removeAttribute(Constants.APP_DISABLED_MARKER);

          // enabling anchor tab keyboard event
          if (element.tagName.toLowerCase() === Constants.TAG_ANCHOR) {
            element.removeAttribute(Constants.TAB_INDEX);
          }

          if ( element.classList ) {
              this.specificElementsHandling(element,
                                            Constants.ENABLED_POINTER_EVENTS, Constants.ENABLED_COLOR, Constants.ENABLED_BG_COLOR);
          }
        }
      }
    }

    if (element.children) {
      for (const childElement of element.children) {
        this.disableElement(childElement);
      }
    }
  }

  private specificElementsHandling(element: any, pointerEvents: string, foregroundColor: string, backgroundColor: string): void {

    const item = this.specialHandledElementsEntries.find(entry => element.classList.contains(entry[0]));
    const flags = item ? item[1] : 0;

    if ( flags & this.POINTER_EVENTS ) {
      this.renderer.setStyle(element, 'pointer-events', pointerEvents);
    }

    if ( flags & this.FOREGROUND_COLOR ) {
      this.renderer.setStyle(element, 'color', foregroundColor, RendererStyleFlags2.Important + RendererStyleFlags2.DashCase);
    }

    if ( flags & this.BACKGROUND_COLOR ) {
      this.renderer.setStyle(element, 'background-color', backgroundColor, RendererStyleFlags2.Important + RendererStyleFlags2.DashCase);
    }
  }

  private isHandledElement(element: any): boolean {

    return this.disabledTags.includes(element.tagName.toLowerCase()) ||
            !!this.specialHandledElementsEntries.find(entry => element.classList.contains(entry[0]));
  }
}
