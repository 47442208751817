import { SortOrder } from '@secca/shared/models/enums';
import * as moment from 'moment';

export class CustomerInvoiceJobFilterRequest {
  salesOrderCreationRange: Date[];
  salesOrderCreationFrom: moment.Moment;
  salesOrderCreationTo: moment.Moment;
  invoiceDateRange: Date[];
  invoiceDateFrom: moment.Moment;
  invoiceDateTo: moment.Moment;
  invoiceJobDateRange: Date[];
  invoiceJobDateFrom: moment.Moment;
  invoiceJobDateTo: moment.Moment;
  salesOrderId: number;
  customerProfile: string[] = [];
  caseNumber: string;
  debtor: string[] = [];
  erpClient: string[] = [];
  invoiceNo: string;
  salesOrderStatus: string[] = [];
  caseFinancialStatus: string[] = [];
  invoiceJobStatus: string[] = [];
  sortBy: string;
  retryCount: number[] = [];
  pageIndex: number;
  pageSize: number;
  sortOrder: SortOrder;

  public constructor(init?: Partial<CustomerInvoiceJobFilterRequest>) {
    Object.assign(this, init);
  }
}
