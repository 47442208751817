import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { BaseService } from '@secca/core/services/base.service';
import { SettingsService } from '@secca/core/services/settings.service';
import { Observable } from 'rxjs';
import { RecoveryPaymentTab, RecoveryPaymentTabAdapter } from '@secca/shared/models/recoveryPaymentTab';
import { RecoveryPayment, RecoveryPaymentAdapter } from '@secca/shared/models/recoveryPayment';
import { ServiceItemType, ServiceItemTypeAdapter } from '@secca/shared/models/service-item-type';
import { ErpCreditor } from '@secca/shared/models/erp-creditor';
import { RecoveryPaymentItem, RecoveryPaymentItemAdapter } from '@secca/shared/models/recoveryPaymentItem';

@Injectable({
  providedIn: 'root'
})
export class RecoveryPaymentService extends BaseService {

  constructor(
    private recoveryPaymentTabAdapter: RecoveryPaymentTabAdapter,
    private recoveryPaymentAdapter: RecoveryPaymentAdapter,
    private recoveryPaymentItemAdapter: RecoveryPaymentItemAdapter,
    private serviceItemTypeAdapter: ServiceItemTypeAdapter,
    private settingsService: SettingsService,
    private http: HttpClient
  ) {
    super(settingsService);
  }

  public getServiceItemList(): Observable<ServiceItemType[]> {
    return this.http
      .get<ServiceItemType[]>(this.baseURL + 'masterlist/service-item-list')
      .pipe(map((data: any[]) => data.map(item => this.serviceItemTypeAdapter.adapt(item))));
  }

  public getRecoveryPaymentByCaseId(caseId: number): Observable<RecoveryPaymentTab> {
    return this.http
      .get<RecoveryPaymentTab>(this.baseURL + 'recovery/payment/tab/' + caseId, { headers: this.jsonHeaders })
      .pipe(map((data: any) => this.recoveryPaymentTabAdapter.adapt(data)
      ));
  }

  public updateRecoveryPayment(recoveryPayment: RecoveryPayment): Observable<RecoveryPayment> {
    recoveryPayment.progress = null;
    return this.http
      .put<RecoveryPayment>(this.baseURL + 'recovery/payment/' + recoveryPayment.id, recoveryPayment, { headers: this.jsonHeaders })
      .pipe(map((data: any) => this.recoveryPaymentAdapter.adapt(data)));
  }

  public getRecoveryPayment(id: number): Observable<RecoveryPayment> {
    return this.http.get(this.baseURL + 'recovery/payment/' + id, { headers: this.jsonHeaders })
      .pipe(map((data: any) => this.recoveryPaymentAdapter.adapt(data)));
  }

  public getErpCreditor(erpId: string): Observable<ErpCreditor> {
    return this.http
      .get<ErpCreditor>(this.baseURL + 'erp-data/creditor/' + erpId, { headers: this.jsonHeaders });
  }

  getRecoveryPaymentFullHistory(caseId: string): Observable<RecoveryPayment[]> {
    return this.http
      .get(this.baseURL + 'recovery/payment/full-history/' + caseId, { headers: this.jsonHeaders })
      .pipe(map((data: any[]) => data.map(item => this.recoveryPaymentAdapter.adapt(item))));
  }

  getRecoveryPaymentItemFullHistory(caseId: string): Observable<RecoveryPaymentItem[]> {
    return this.http
      .get(this.baseURL + 'recovery/payment/full-item-history/' + caseId, { headers: this.jsonHeaders })
      .pipe(map((data: any[]) => data.map(item => this.recoveryPaymentItemAdapter.adapt(item))));
  }
}
