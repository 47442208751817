<div class="screening-tool-container">
    <div class="table-container">
      <div [appDisableInput]="caseStateService.isCaseDisabled()" class="caselock-no-events">
        <table>
          <tbody>
              <tr>
                <td class="name-col-title">{{ 'screening-tool-insurance' | translate }}</td>
                <td class="no-col-title">{{ 'screening-tool-NO' | translate }}</td>
                <td class="tbd-col-title">{{ 'screening-tool-TBD' | translate }}</td>
                <td class="yes-col-title">{{ 'screening-tool-YES' | translate }}</td>
              </tr>
              <tr>
                <td class="name-col-valid-insurance row">{{ 'screening-tool-valid-insurance' | translate }}
                  <div class="question-mark-holder row" *ngIf="personInsurance">
                    <img class="question-mark" src="/assets/icons/Questionmark.png" ngbPopover="{{ 'screening-tool-valid-insurance-text-1' | translate }}"
                    [autoClose]="false" triggers="hover" openDelay="400" *ngIf="!this.personInsurance.creditCard" alt="question mark">
                    <img class="question-mark" src="/assets/icons/Questionmark.png" ngbPopover="{{ 'screening-tool-valid-insurance-text-2' | translate }}"
                    [autoClose]="false" triggers="hover" openDelay="400" *ngIf="this.personInsurance.creditCard" alt="question mark">
                  </div>
                </td>
                <td [ngClass]="{ 'no-col-off' : true, 'no-col-on' : screening.validInsuranceSetByCaseHandler && screening.validInsuranceStates === NO}"
                (click)="updateScreeningManual('validInsuranceStates', NO, 'validInsuranceSetByCaseHandler', true)"
                tabindex="0" (keyup.enter)="updateScreeningManual('validInsuranceStates', NO, 'validInsuranceSetByCaseHandler', true)">
                  <div *ngIf = '!screening.validInsuranceSetByCaseHandler && screening.validInsuranceStates === NO' class="no-dot"></div>
                </td>
                <td [ngClass]="{ 'tbd-col-off' : true, 'tbd-col-on' : screening.validInsuranceSetByCaseHandler && screening.validInsuranceStates === TBD}"
                (click)="updateScreeningManual('validInsuranceStates', TBD, 'validInsuranceSetByCaseHandler', true)"
                tabindex="0" (keyup.enter)="updateScreeningManual('validInsuranceStates', TBD, 'validInsuranceSetByCaseHandler', true)">
                  <div *ngIf = '!screening.validInsuranceSetByCaseHandler && screening.validInsuranceStates === TBD' class="tbd-dot"></div>
                </td>
                <td [ngClass]="{ 'yes-col-off' : true, 'yes-col-on' : screening.validInsuranceSetByCaseHandler && screening.validInsuranceStates === YES}"
                (click)="updateScreeningManual('validInsuranceStates', YES, 'validInsuranceSetByCaseHandler', true)"
                tabindex="0" (keyup.enter)="updateScreeningManual('validInsuranceStates', YES, 'validInsuranceSetByCaseHandler', true)">
                  <div *ngIf = '!screening.validInsuranceSetByCaseHandler && screening.validInsuranceStates === YES' class="yes-dot"></div>
                </td>
              </tr>
              <tr>
                <td class="name-col-title">{{ 'screening-tool-travel' | translate }}</td>
              </tr>
              <tr>
                <td class="name-col">{{ 'screening-tool-insurance-period' | translate }}
                    <img class="question-mark" src="/assets/icons/Questionmark.png" ngbPopover="{{ 'screening-tool-insurance-period-text' | translate }}"
                    [autoClose]="false" triggers="hover" openDelay="400" alt="question mark" placement="top">
                  </td>
                <td [ngClass]="{ 'no-col-off' : true, 'no-col-on' : screening.insurancePeriodSetByCaseHandler && screening.insurancePeriodState === NO }"
                (click)="updateScreeningManual('insurancePeriodState', NO, 'insurancePeriodSetByCaseHandler', true)"
                tabindex="0" (keyup.enter)="updateScreeningManual('insurancePeriodState', NO, 'insurancePeriodSetByCaseHandler', true)">
                  <div *ngIf = '!screening.insurancePeriodSetByCaseHandler && screening.insurancePeriodState === NO' class="no-dot"></div>
                </td>
                <td [ngClass]="{ 'tbd-col-off' : true, 'tbd-col-on' : screening.insurancePeriodSetByCaseHandler && screening.insurancePeriodState === TBD }"
                (click)="updateScreeningManual('insurancePeriodState', TBD, 'insurancePeriodSetByCaseHandler', true)"
                tabindex="0" (keyup.enter)="updateScreeningManual('insurancePeriodState', TBD, 'insurancePeriodSetByCaseHandler', true)">
                  <div *ngIf = '!screening.insurancePeriodSetByCaseHandler && screening.insurancePeriodState === TBD' class="tbd-dot"></div>
                </td>
                <td [ngClass]="{ 'yes-col-off' : true, 'yes-col-on' : screening.insurancePeriodSetByCaseHandler && screening.insurancePeriodState === YES }"
                (click)="updateScreeningManual('insurancePeriodState', YES, 'insurancePeriodSetByCaseHandler', true)"
                tabindex="0" (keyup.enter)="updateScreeningManual('insurancePeriodState', YES, 'insurancePeriodSetByCaseHandler', true)">
                  <div *ngIf = '!screening.insurancePeriodSetByCaseHandler && screening.insurancePeriodState === YES' class="yes-dot"></div>
                </td>
              </tr>
              <tr>
                <td class="name-col">{{ 'screening-tool-person-group-includes' | translate }}
                    <img class="question-mark" src="/assets/icons/Questionmark.png" ngbPopover="{{ 'screening-tool-person-group-includes-text' | translate }}"
                    [autoClose]="false" triggers="hover" openDelay="400" alt="question mark"  placement="top">
                    <img class="question-mark" triggers="hover" ngbPopover="{{ 'screening-tool-person-group-different-address' | translate }}" src="/assets/icons/Address not identical.svg" *ngIf='screening.caseAddressState === DIFFERENT' alt="address not identical"  placement="top">
                    <img class="question-mark" triggers="hover" ngbPopover="{{ 'screening-tool-person-group-same-address' | translate }}" src="/assets/icons/Address identical.svg" *ngIf='screening.caseAddressState === SAME ' alt="address identical"  placement="top">
                    <img class="question-mark" triggers="hover" ngbPopover="{{ 'screening-tool-person-group-partial-address' | translate }}" src="/assets/icons/Address partial.svg" *ngIf='screening.caseAddressState === PARTIAL' alt="address partial"  placement="top">
                    <img class="question-mark" triggers="hover" ngbPopover="{{ 'screening-tool-person-group-missing-address' | translate }}" src="/assets/icons/Address missing.svg" *ngIf='screening.caseAddressState === MISSING || screening.caseAddressState === null ' alt="address missing"  placement="top">
                  </td>
                <td [ngClass]="{ 'no-col-off' : true, 'no-col-on' : screening.personGroupIncludesSetByCaseHandler && screening.personGroupIncludesState === NO}"
                (click)="updateScreeningManual('personGroupIncludesState', NO, 'personGroupIncludesSetByCaseHandler', true)"
                tabindex="0" (keyup.enter)="updateScreeningManual('personGroupIncludesState', NO, 'personGroupIncludesSetByCaseHandler', true)">
                  <div *ngIf = '!screening.personGroupIncludesSetByCaseHandler && screening.personGroupIncludesState === NO' class="no-dot"></div>
                </td>
                <td [ngClass]="{ 'tbd-col-off' : true, 'tbd-col-on' : screening.personGroupIncludesSetByCaseHandler && screening.personGroupIncludesState === TBD}"
                (click)="updateScreeningManual('personGroupIncludesState', TBD, 'personGroupIncludesSetByCaseHandler', true)"
                tabindex="0" (keyup.enter)="updateScreeningManual('personGroupIncludesState', TBD, 'personGroupIncludesSetByCaseHandler', true)">
                  <div *ngIf = '!screening.personGroupIncludesSetByCaseHandler && screening.personGroupIncludesState === TBD' class="tbd-dot"></div>
                </td>
                <td [ngClass]="{ 'yes-col-off' : true, 'yes-col-on' : screening.personGroupIncludesSetByCaseHandler && screening.personGroupIncludesState === YES}"
                (click)="updateScreeningManual('personGroupIncludesState', YES, 'personGroupIncludesSetByCaseHandler', true)"
                tabindex="0" (keyup.enter)="updateScreeningManual('personGroupIncludesState', YES, 'personGroupIncludesSetByCaseHandler', true)">
                  <div *ngIf = '!screening.personGroupIncludesSetByCaseHandler && screening.personGroupIncludesState === YES' class="yes-dot"></div>
                </td>
              </tr>
              <tr>
                <td class="name-col">{{ 'screening-tool-geographical-area' | translate }}
                    <img class="question-mark" src="/assets/icons/Questionmark.png" ngbPopover="{{ 'screening-tool-geographical-area-text' | translate }}"
                    [autoClose]="false" triggers="hover" openDelay="400" alt="question mark" placement="top">
                  </td>
                <td [ngClass]="{ 'no-col-off' : true, 'no-col-on' : screening.geographicalAreaSetByCaseHandler && screening.geographicalAreaState === NO}"
                (click)="updateScreeningManual('geographicalAreaState', NO, 'geographicalAreaSetByCaseHandler', true)"
                tabindex="0" (keyup.enter)="updateScreeningManual('geographicalAreaState', NO, 'geographicalAreaSetByCaseHandler', true)">
                  <div *ngIf = '!screening.geographicalAreaSetByCaseHandler && screening.geographicalAreaState === NO' class="no-dot"></div>
                </td>
                <td [ngClass]="{ 'tbd-col-off' : true, 'tbd-col-on' : screening.geographicalAreaSetByCaseHandler && screening.geographicalAreaState === TBD}"
                (click)="updateScreeningManual('geographicalAreaState', TBD, 'geographicalAreaSetByCaseHandler', true)"
                tabindex="0" (keyup.enter)="updateScreeningManual('geographicalAreaState', TBD, 'geographicalAreaSetByCaseHandler', true)">
                  <div *ngIf = '!screening.geographicalAreaSetByCaseHandler && screening.geographicalAreaState === TBD' class="tbd-dot"></div>
                </td>
                <td [ngClass]="{ 'yes-col-off' : true, 'yes-col-on' : screening.geographicalAreaSetByCaseHandler && screening.geographicalAreaState === YES}"
                (click)="updateScreeningManual('geographicalAreaState', YES, 'geographicalAreaSetByCaseHandler', true)"
                tabindex="0" (keyup.enter)="updateScreeningManual('geographicalAreaState', YES, 'geographicalAreaSetByCaseHandler', true)">
                  <div *ngIf = '!screening.geographicalAreaSetByCaseHandler && screening.geographicalAreaState === YES' class="yes-dot"></div>
                </td>
              </tr>
              <tr>
                <td class="name-col">{{ 'screening-tool-coverage-period' | translate }}
                    <img class="question-mark" src="/assets/icons/Questionmark.png" ngbPopover="{{ 'screening-tool-coverage-period-text' | translate }}"
                    [autoClose]="false" triggers="hover" openDelay="400" alt="question mark">
                  </td>
                <td [ngClass]="{ 'no-col-off' : true, 'no-col-on' : screening.coveragePeriodSetByCaseHandler && screening.coveragePeriodState === NO}"
                (click)="updateScreeningManual('coveragePeriodState', NO, 'coveragePeriodSetByCaseHandler', true)"
                tabindex="0" (keyup.enter)="updateScreeningManual('coveragePeriodState', NO, 'coveragePeriodSetByCaseHandler', true)">
                  <div *ngIf = '!screening.coveragePeriodSetByCaseHandler && screening.coveragePeriodState === NO' class="no-dot"></div>
                </td>
                <td [ngClass]="{ 'tbd-col-off' : true, 'tbd-col-on' : screening.coveragePeriodSetByCaseHandler && screening.coveragePeriodState === TBD}"
                (click)="updateScreeningManual('coveragePeriodState', TBD, 'coveragePeriodSetByCaseHandler', true)"
                tabindex="0" (keyup.enter)="updateScreeningManual('coveragePeriodState', TBD, 'coveragePeriodSetByCaseHandler', true)">
                  <div *ngIf = '!screening.coveragePeriodSetByCaseHandler && screening.coveragePeriodState === TBD' class="tbd-dot"></div>
                </td>
                <td [ngClass]="{ 'yes-col-off' : true, 'yes-col-on' :  screening.coveragePeriodSetByCaseHandler && screening.coveragePeriodState === YES}"
                (click)="updateScreeningManual('coveragePeriodState', YES, 'coveragePeriodSetByCaseHandler', true)"
                tabindex="0" (keyup.enter)="updateScreeningManual('coveragePeriodState', YES, 'coveragePeriodSetByCaseHandler', true)">
                  <div *ngIf = '!screening.coveragePeriodSetByCaseHandler && screening.coveragePeriodState === YES' class="yes-dot"></div>
                </td>
              </tr>
              <tr>
                <td class="name-col">{{ 'screening-tool-purpose-of-travel' | translate }}
                    <img class="question-mark" src="/assets/icons/Questionmark.png" ngbPopover="{{ 'screening-tool-purpose-of-travel-text' | translate }}"
                    [autoClose]="false" triggers="hover" openDelay="400" alt="question mark">
                  </td>
                <td [ngClass]="{ 'no-col-off' : true, 'no-col-on' : screening.purposesOfTravelSetByCaseHandler && screening.purposesOfTravelState === NO}"
                (click)="updateScreeningManual('purposesOfTravelState', NO, 'purposesOfTravelSetByCaseHandler', true)"
                tabindex="0" (keyup.enter)="updateScreeningManual('purposesOfTravelState', NO, 'purposesOfTravelSetByCaseHandler', true)">
                  <div *ngIf = '!screening.purposesOfTravelSetByCaseHandler && screening.purposesOfTravelState === NO' class="no-dot"></div>
                </td>
                <td [ngClass]="{ 'tbd-col-off' : true, 'tbd-col-on' : screening.purposesOfTravelSetByCaseHandler && screening.purposesOfTravelState === TBD}"
                (click)="updateScreeningManual('purposesOfTravelState', TBD, 'purposesOfTravelSetByCaseHandler', true)"
                tabindex="0" (keyup.enter)="updateScreeningManual('purposeOfTravelState', TBD, 'purposeOfTravelSetByCaseHandler', true)">
                  <div *ngIf = '!screening.purposesOfTravelSetByCaseHandler && screening.purposesOfTravelState === TBD' class="tbd-dot"></div>
                </td>
                <td [ngClass]="{ 'yes-col-off' : true, 'yes-col-on' : screening.purposesOfTravelSetByCaseHandler && screening.purposesOfTravelState === YES}"
                (click)="updateScreeningManual('purposesOfTravelState', YES, 'purposesOfTravelSetByCaseHandler', true)"
                tabindex="0" (keyup.enter)="updateScreeningManual('purposeOfTravelState', YES, 'purposesOfTravelSetByCaseHandler', true)">
                  <div *ngIf = '!screening.purposesOfTravelSetByCaseHandler && screening.purposesOfTravelState === YES' class="yes-dot"></div>
                </td>
              </tr>

              <ng-container *ngIf="isCaseTypeMedicalPreAssesssment(case.caseTypeCode)">
                <tr>
                  <td class="name-col-title">{{ 'screening-tool-pre-assessment' | translate }}</td>
                  <td class="no-col-title"></td>
                  <td class="tbd-col-title">{{ 'medical-pre-assessment-partially' | translate }}</td>
                  <td class="yes-col-title">{{ 'medical-pre-assessment-fully' | translate }}</td>
                </tr>
                <tr>
                  <td class="name-col">{{ 'screening-tool-pre-departure-decision' | translate }}
                      <img class="question-mark" src="/assets/icons/Questionmark.png" ngbPopover="{{ 'screening-tool-pre-departure-decision-text' | translate }}"
                      [autoClose]="false" triggers="hover" openDelay="400" alt="question mark" placement="top">
                  </td>
                  <td class="no-col-off-no-hover" tabindex="0" ></td>
                  <td [ngClass]="{ 'tbd-col-off-no-hover' : true, 'tbd-col-on' : case.medicalPreAssessmentDecision === MedicalPreAssessmentDecisionEnum.PARTIALLY_COVERED}" tabindex="0" > </td>
                  <td [ngClass]="{ 'yes-col-off-no-hover' :  true, 'yes-col-on' : case.medicalPreAssessmentDecision === MedicalPreAssessmentDecisionEnum.FULLY_COVERED}" tabindex="0"> </td>
                </tr>
              </ng-container>

              <ng-container *ngIf="!isCaseTypeMedicalPreAssesssment(case.caseTypeCode)">
                <tr>
                  <td class="name-col-title">{{ 'screening-tool-incident' | translate }}</td>
                </tr>
                <tr>
                  <td class="name-col">{{ 'screening-tool-incident-covered' | translate }}
                      <img class="question-mark" src="/assets/icons/Questionmark.png" ngbPopover="{{ 'screening-tool-incident-covered-text' | translate }}"
                      [autoClose]="false" triggers="hover" openDelay="400" alt="question mark" placement="top">
                    </td>
                  <td [ngClass]="{ 'no-col-off' : true, 'no-col-on' : screening.incidentCoveredSetByCaseHandler && screening.incidentCoveredState === NO}"
                  (click)="updateScreeningManual('incidentCoveredState', NO, 'incidentCoveredSetByCaseHandler', true)"
                  tabindex="0" (keyup.enter)="updateScreeningManual('incidentCoveredState', NO, 'incidentCoveredSetByCaseHandler', true)">
                    <div *ngIf = '!screening.incidentCoveredSetByCaseHandler && screening.incidentCoveredState === NO' class="no-dot"></div>
                  </td>
                  <td [ngClass]="{ 'tbd-col-off' : true, 'tbd-col-on' : screening.incidentCoveredSetByCaseHandler && screening.incidentCoveredState === TBD}"
                  (click)="updateScreeningManual('incidentCoveredState', TBD, 'incidentCoveredSetByCaseHandler', true)"
                  tabindex="0" (keyup.enter)="updateScreeningManual('incidentCoveredState', TBD, 'incidentCoveredSetByCaseHandler', true)">
                    <div *ngIf = '!screening.incidentCoveredSetByCaseHandler && screening.incidentCoveredState === TBD' class="tbd-dot"></div>
                  </td>
                  <td [ngClass]="{ 'yes-col-off' :  true, 'yes-col-on' : screening.incidentCoveredSetByCaseHandler && screening.incidentCoveredState === YES}"
                  (click)="updateScreeningManual('incidentCoveredState', YES, 'incidentCoveredSetByCaseHandler', true)"
                  tabindex="0" (keyup.enter)="updateScreeningManual('incidentCoveredState', YES, 'incidentCoveredSetByCaseHandler', true)">
                    <div *ngIf = '!screening.incidentCoveredSetByCaseHandler && screening.incidentCoveredState === YES' class="yes-dot"></div>
                  </td>
                </tr>
              </ng-container>
          </tbody>
        </table>
      </div>
    </div>
</div>
