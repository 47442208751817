import { MedicalPreAssessmentService } from '@secca/core/services/medical-pre-assessment.service';
import { element } from 'protractor';
import { Observable, Subject, Subscription } from 'rxjs';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { IcdCodesService } from 'src/app/core/services/icd-codes.service';
import { debounceTime } from 'rxjs/operators';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Icd10Code } from '@secca/shared/models/icd10Code';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { cloneDeep } from 'lodash-es';
import * as _ from 'lodash';

@Component({
  selector: 'app-multiple-select',
  templateUrl: './multiple-select.component.html',
  styleUrls: ['./multiple-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('toggleChecked', [
      state('checked', style({
        opacity: 1
      })),
      state('unchecked', style({
        opacity: 0
      })),
      transition('unchecked => checked', [
        animate('400ms', keyframes([
          style({opacity: 0.5, boxShadow: '0 0 0 2px #23AFDC', offset: 0.5}),
          style({opacity: 1, boxShadow: '0 0 0 0px #23AFDC', offset: 1})
        ]))
      ]),
      transition('checked => unchecked', [
        animate('300ms', keyframes([
          style({opacity: 0.5, transform: 'scale(0)', offset: 0.5}),
          style({opacity: 0, offset: 1})
        ]))
      ]),
    ])
  ]
})

@AutoUnsubscribe
export class MultipleSelectComponent implements OnInit {
  showDropdown: boolean;
  dropdownValueList: Icd10Code[];
  @ViewChildren('dropdownCheckbox') dropdownValue: QueryList<ElementRef>;
  @ViewChild('tableText') tableText: ElementRef;
  @Input() maxElements: number;
  @Input() name: string;
  @Input() selectedItems: Icd10Code[] = [];
  @Input() singleValue: boolean;
  @Input() excludedElements: string;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() alternativeBoxStyle: boolean;
  @Input() recommended: boolean;
  isRequired: boolean;
  @Output() selectedItemsChange = new EventEmitter();
  selectedItemsToEmit: Icd10Code[] = [];
  private wasInside: boolean;

  subscription: Subscription;
  dropdownList: Observable<Icd10Code[]>;
  filteringText = new Subject<string | null>();

  $filteringTextSubscr: Subscription;

  constructor(private icdCodesService: IcdCodesService, private medicalPreAssessmentService: MedicalPreAssessmentService) {
    this.$filteringTextSubscr = this.filteringText.subscribe(newTerm => {
      this.getDropdownValues(newTerm);
    });
  }

  ngOnInit() {
    this.filteringText = null;
  }

  removeLabelClick(rowId: string) {
    this.selectedItems = this.selectedItems.filter(h => h.icd10Code !== rowId);
    if(!!this.dropdownValueList) {
      this.dropdownValueList.find((f)=>{
        if(f.icd10Code === rowId){
          f.selected = false;
        }
      });
    }
    this.selectedItemsChange.emit(this.selectedItems);
}

  toggleShowDropdown(event: Event) {
    this.filteringText = null;
    event.preventDefault();
    if (!this.disabled) {
      this.showDropdown = !this.showDropdown;
      if (this.showDropdown) {
        setTimeout(() => {
            if(!!this.tableText) {
              this.tableText.nativeElement.focus();
            }
          },
          100);
        this.getDropdownValues('');
      } else {
        this.showDropdown = false;
      }
    }
  }


  clickSelectItem(event: Event, row: Icd10Code) {
    event.preventDefault();
    if (row.selected) {
      this.selectedItems = this.selectedItems.filter(f => f.icd10Code !== row.icd10Code);
      row.selected = false;
      this.selectedItemsChange.emit(this.selectedItems);
    } else if (((this.singleValue && this.selectedItems.length < 1) || !this.singleValue) && this.selectedItems.length < this.maxElements) {
      this.selectedItems.push(row);
      row.selected = true;
      this.selectedItemsToEmit = cloneDeep(this.selectedItems);
      this.selectedItemsToEmit.forEach(element => {
        if(element.shortName.includes('-')) {
          element.shortName = element.shortName.split('-').pop();
        }
      });
      this.selectedItemsChange.emit(this.selectedItemsToEmit);
    }
  }

  @HostListener('keydown.esc') onEsc() {
    this.showDropdown = false;
  }

  @HostListener('click')
  clickInside() {
    this.wasInside = true;
  }

  @HostListener('document:click')
  clickOut() {
    if (!this.wasInside) {
      this.showDropdown = false;
    }
    this.wasInside = false;
  }

  arrowDownSearchTextPressed(event: Event) {
    event.preventDefault();
    if (document.activeElement.getAttribute('class').includes('search-text')) {
      this.dropdownValue.first.nativeElement.focus();
    }
  }

  arrowItemPressed(event: Event, up: boolean) {
    const divElement = event.target as HTMLDivElement;
    if (up) {
      if (divElement.previousElementSibling) {
        (divElement.previousElementSibling as HTMLDivElement).focus();
      } else {
        this.tableText.nativeElement.focus();
      }
    } else {
      if (divElement.nextElementSibling) {
        (divElement.nextElementSibling as HTMLDivElement).focus();
      }
    }

  }

  focusCurrent(event: Event) {
    const divElement = event.target as HTMLDivElement;
    divElement.focus();
  }

  private getDropdownValues(term: string | null) {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    return this.subscription = this.icdCodesService
      .getSymptoms(term.toLocaleLowerCase())
      .pipe(debounceTime(500))
      .pipe(result => (this.dropdownList = result))
      .subscribe(result => {
        if (!!this.medicalPreAssessmentService.componentText) {
          this.selectedItemsChange.emit(this.selectedItems);
          const properties = ['icd10Code', 'shortName', 'selected'];
          this.dropdownValueList = result;
          const removeMatchedElements: any[] = result
          .filter((item: any) => !this.medicalPreAssessmentService.excludedValues.some((exclude) => item.icd10Code === exclude))
          .map((item) =>
              properties.reduce((element, name) => {
                element[name] = item[name];
                  return element;
              }, {})
          );
          this.dropdownValueList = removeMatchedElements;
          result.forEach(item => this.selectedItems.forEach(selected => {
                  if (selected.icd10Code === item.icd10Code) {
                    item.selected = true;
                  }
                }));
              }
        else {
              this.dropdownValueList = result.filter((item: any) => item.icd10Code !== this.excludedElements);
              result.forEach(item => this.selectedItems.forEach(selected => {
                if (selected.icd10Code === item.icd10Code) {
                  item.selected = true;
                }
              }));
            }

  });
}
}
