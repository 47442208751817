<div class="output-management-entry action-button">
  <div class="output-management-entry-header">
    <div>
      <span class="blue">{{entry.flowName}}
        <span class="black"> {{"digital-caseflow" | translate}} </span>
        <span class="teal" *ngIf="entry.flowResult">{{"status-succeeded" | translate}}</span>
        <span class="red" *ngIf="!entry.flowResult">{{"status-failed" | translate}}</span>
      </span>
    </div>

  </div>
  <div>
    <div class="action-button-menu-button" (click)="showEntryMenu=!showEntryMenu" (clickOutside)="showEntryMenu=false">
      <div class="action-button-menu-button-dot"></div>
      <div class="action-button-menu-button-dot"></div>
      <div class="action-button-menu-button-dot"></div>
    </div>
    <div class="action-button-menu" *ngIf="showEntryMenu">
      <div class="action-button-menu-position" (click)="viewMenuClick()">{{"case-summary-external-view" | translate}}</div>
    </div>
  </div>
</div>
