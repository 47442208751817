import { IntensiveCareStepCategoryEnum } from '../enums';

import { Injectable } from '@angular/core';
import { Adapter } from '../../interfaces/adapter';
import * as moment from 'moment';

export class PatientState {
    breathingSupportDescription: string;
    medications: string;
    bloodPressureSystolic: number;
    bloodPressureDiastolic: number;
    hearthRate: number;
    respiratoryRate: number;
    oxygenSaturation: number;
    temperature: number;
    hemoglobin: number;
    cast: boolean;
    castRight: boolean;
    castLeft: boolean;
    castDescription: string;
    castReadyForFlight: boolean;
    surgery: boolean;
    surgeryDate: moment.Moment;
    surgeryDescription: string;
    intensiveCareStep: IntensiveCareStepCategoryEnum;
    intensiveCareStepText: string;
    medicalHistoryDescription: string;

    public constructor(init?: Partial<PatientState>) {
        Object.assign(this, init);
    }
}

@Injectable({
    providedIn: 'root'
})
export class PatientStateAdapter implements Adapter<PatientState> {



    adapt(item: any): PatientState {
        return new PatientState({
            breathingSupportDescription: item.breathingSupportDescription,
            medications: item.medications,
            bloodPressureSystolic: item.bloodPressureSystolic,
            bloodPressureDiastolic: item.bloodPressureDiastolic,
            hearthRate: item.hearthRate,
            respiratoryRate: item.respiratoryRate,
            oxygenSaturation: item.oxygenSaturation,
            temperature: item.temperature,
            hemoglobin: item.hemoglobin,
            cast: item.cast,
            castRight: item.castRight,
            castLeft: item.castLeft,
            castDescription: item.castDescription,
            castReadyForFlight: item.castReadyForFlight,
            surgery: item.surgery,
            surgeryDate: item.surgeryDate,
            surgeryDescription: item.surgeryDescription,
            intensiveCareStep: item.intensiveCareStep,
            intensiveCareStepText: item.intensiveCareStepText,
            medicalHistoryDescription: item.medicalHistoryDescription
        });
    }
}
