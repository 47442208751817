<div  class="timeline">
  <app-case-lock-overlay [caseClosed]="caseClosed" [financialStatus]="financialStatus"></app-case-lock-overlay>

  <div class="timeline-content" [ngClass]="{'dimmed': caseStateService.isCaseDisabled()}">
  <div class="start-line"></div>
  <div class="departures" *ngIf = "displayTravelLine">
    <img class="departures-img" src=".././../../../../assets/icons/departures.svg" />
    <div class="departures-date">
      {{plannedTravel.departureDate | momentFormat : "DD MMM YYYY" }}
    </div>
  </div>
  <div class="dottedLine-full"></div>
  <div class="travel-line" [ngStyle]="{'width' : travelLinePercent}" *ngIf = "displayTravelLine"></div>
  <div class="incident-line" [ngStyle]="{'width' : incidentLinePercent}" *ngIf = "displayIncident"></div>
    <div class="incident" *ngIf="displayIncident" [ngStyle]="{'left' : incidentIconPercent}"
         [ngbPopover]="incident.incidentDate | date : 'dd MMM yyyy'" [autoClose]="false" triggers="hover"
         #p="ngbPopover" (click)="p.open()" openDelay="400">
      <img class="incident-img" src="./assets/icons/lighting.svg"/>
  </div>
  <div class="short-stop-line" [ngStyle]="{'left' : shortPeriodStopLinePercent}" *ngIf= "displayTravelLine && displayCoverageStopIcon"> </div>
  <div class="arrivals" [ngStyle]="{'left' : travelLinePercent}" *ngIf = "displayTravelLine">
      <div class="arrivals-date">
        {{plannedTravel.arrivalDate | momentFormat : "DD MMM YYYY" }}
      </div>
      <img class="arrivals-img" src=".././../../../../assets/icons/arrivals.svg" />
  </div>

  <div class="coverage-stop" [ngStyle]="{'left' : coverageStopIconPercent, 'top' : '6px'}" *ngIf = "displayCoverageStopIcon">
      <div class="coverage-stop-date">
        {{coverageStopDate | date : "dd MMM yyyy" }}
      </div>
      <img class="coverage-stop" *ngIf = "coverageStopIconColor === 'RED'" src=".././../../../../assets/icons/StopRed.svg" />
      <img class="coverage-stop" *ngIf = "coverageStopIconColor === 'YELLOW'" src=".././../../../../assets/icons/StopYellow.svg" />
      <img class="coverage-stop" *ngIf = "coverageStopIconColor === 'GREY'" src=".././../../../../assets/icons/stop.svg" />
  </div>

  <div class="today-line" [ngStyle]="{'left' : todayPercent}" *ngIf = "displayTodayLine">
    <div class="today-text">
      Today
    </div>
  </div>
  <div class="stop-line"></div>
  </div>
</div>
