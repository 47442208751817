<div class="recovery-container" xmlns="http://www.w3.org/1999/html">
    <div class="modal-header">
      <div class="modal-header-title">
        {{'case-economy-recovery-manage-recovery-and-compensations' | translate }}
      </div>
      <div class="modal-buttons">
        <div (click)="close()">
          <img alt="close modal" src="/assets/icons/Close White.svg" />
        </div>
      </div>
    </div>
    <app-case-lock-overlay
      [lockContext]="LockContextEnum.CASE_BASIC"
      [asOverlay]="false"
      [unlockPostAction]="CaseLockOverlayAction.TAKE_LOCK"
      [caseClosed]="caseStateService.isCaseClosed"
    ></app-case-lock-overlay>
    <div class="recovery-modal-shadow" [appDisableInput]="caseLockHelperService.isCaseBasicLockTaken || caseStateService.isCaseClosed">
      <div class="recovery-modal-header">
        <div class="row">
          <div class="col-7 d-flex flex-row">
            <div class="economy-process-bar p-1">
              <app-economy-status-bar
                [numberOfStatus]="numberOfStatus"
                [maxStatus]="numberOfStatus"
                [cancelled]="isCancelled()"
                [statusText]="'case-economy-recovery-recovery-status-' + recovery?.recoveryStatus | translate"
                [statusIndex]="getStatusValues(recovery).statusIndex"
                [warnIconIndex]="getStatusValues(recovery).warnIconIndex"
                [redIconIndex]="getStatusValues(recovery).redIconIndex"
                [redIconText]="'case-economy-recovery-recovery-status-' + (getRedStatusText(recovery)) | translate"
              ></app-economy-status-bar>
            </div>
          </div>
          <div class="col-4"></div>
          <div class="col-1"><b>{{ 'case-economy-recovery-recovery-id' | translate }}</b><br/>{{ recovery?.recoveryNumber ? recovery.recoveryNumber : 'New'  }} </div>
        </div>
      </div>
      <div class="recovery-modal-body">
        <table class="primary-table">
            <thead>
              <tr>
                <td class="td-invoice-no">{{ 'case-economy-recovery-invoice-no' | translate }}
                  <span>
                    <div *ngIf="!lockRecoveryOnApproved() && !readOnly" class="add-invoice-button" (click)="addRecoveryItem()">
                      {{ 'case-economy-recovery-service-add-invoice' | translate }} <img src="../../../../../../assets/icons/add-new.svg" />
                    </div>
                  </span>
                </td>
                <td class="td-remittance-advice">{{ 'case-economy-recovery-remittance-advice' | translate }}</td>
                <td class="td-service">{{ 'case-economy-recovery-service' | translate }}</td>
                <td class="td-item">
                  <div
                    [appDisableInput]="permissionService.isReadOnly || caseStateService.isCaseClosed"
                    class="caselock-no-events">
                    {{ 'case-economy-recovery-item' | translate }}
                  </div>
                </td>
                <td class="td-supplier-paid-amount">{{ 'case-economy-recovery-supplier-amount' | translate }}</td>
                <td class="td-applied-amount">{{ 'case-economy-recovery-applied-amount' | translate }}</td>
                <td class="td-recovered-amount">{{ 'case-economy-recovery-recovered-amount' | translate }}</td>
              </tr>
            </thead>
        </table>
        <div class="recovery-details" >
            <div class="recovery-items">
              <app-recovery-item *ngFor="let item of recoveryItems; let i = index"
                [caseId]="recovery?.caseId"
                [invoiceNumbers]="getSelectableInvoiceNumbers(item, i)"
                [remittanceAdvices]="remittanceAdvices"
                [serviceOrdersForRecovery]="serviceOrdersForRecovery"
                [showDelete]="true"
                (deleteRecoveryItem)="deleteRecoveryItem($event)"
                (invoiceNumberChange)="onInvoiceNumberChange($event)"
                (remittanceAdviceChange)="onRemittanceAdviceChange($event)"
                (itemChange)="onRecoveryItemChange()"
                [recoveryItem]="item"
                [caseCreatedDate]="caseCreatedDate"
                [itemIndex]="i"
                [isDisabled]="isRecoveryDisabled() || isApplicationSent()"
              ></app-recovery-item>
            </div>
            <div class="row recovery-footer" *ngIf="recovery">
              <div class="col-9">
                <div class="recovery-footer-box mb-3" >
                  <div class="recovery-footer-box-header ps-1 pt-1">
                    {{ 'case-economy-recovery-cancellation' | translate }}
                  </div>
                  <div class="recovery-footer-box-content p-3 mb-1">
                    <div class="row">
                      <div class="col-2 d-flex">
                        <div class="pr-4 align-self-end">
                          <button
                            class="cancel-btn w-100 me-1"
                            [class.cancel-btn-inactive]="isCancelButtonDisabled() || isRecoveryDisabled()"
                            [disabled]="isCancelButtonDisabled() || isRecoveryDisabled()"
                            (click)="cancelRecovery()">
                            <div triggers="hover" [ngbPopover]="'case-economy-recovery-cancel-recovery-disabled-hover-text' | translate">
                              {{ 'case-economy-recovery-cancel-recovery' | translate }}
                            </div>
                          </button>
                        </div>
                      </div>
                      <div class="col-5 d-flex flex-column">
                          <div>{{ 'case-economy-recovery-reason-for-cancellation' | translate }}</div>
                          <app-drop-down
                          [items]="isCancelReasonDisabled() || isRecoveryDisabled() ? activeReasonsForCancellationOnCreationDate : activeReasonsForCancellation"
                          [(selectedItemId)]="recovery.reasonForCancellation"
                          [disabled]="isCancelReasonDisabled() || isRecoveryDisabled()"
                          [recommended]="!recovery.reasonForCancellation"
                          (selectedItemIdChange)="recalculateRecoveryState()"
                          ></app-drop-down>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="editor-small-height mb-3">
                  <editor *ngIf="waitBeforeInitTextbox"
                          apiKey=""
                          [init]="tinyMCEConfiguration"
                          [disabled]="isRecoveryDisabled() || isApplicationSent()"
                          [(ngModel)]="recovery.description"
                          class="h-100"
                          (onKeyUp)="onKeyUp($event)"
                          (onFocusIn)="onFocus($event)">
                  </editor>
                </div>
                <div class="mb-3">
                  <app-recovery-document [(recovery)]="recovery"
                                         [disabled]="isRecoveryDisabled() || isApplicationSent()"
                                         [recoveryState]="recoveryState"
                                         [recoveryItems]="recoveryItems"
                                         [currentCase]="currentCase"
                                         (applicationPreviewed)="applicationHasBeenPreviewed()"
                                         (closeRecoveryModal)='close()'
                                         (templateChange)="recalculateRecoveryState()">
                  </app-recovery-document>
                </div>
                <div class="w-100 d-flex mb-3 caselock-no-events" >
                    <div class="pe-1">
                      <button
                        class="primary-button w-100 me-1"
                        [class.primary-button-inactive]="isRecoveryDisabled() || isPOAReceivedButtonDisabled()"
                        [disabled]="isRecoveryDisabled() || isPOAReceivedButtonDisabled()"
                        (click)="receivedPOA()"
                        >
                        <div [disablePopover]="isRecoveryDisabled() || !isPOAReceivedButtonDisabled()" container="body" triggers="hover" placement="top" [ngbPopover]="poaReceivedMissingHoverText">
                          {{ 'case-economy-recovery-POA-received' | translate }}
                        </div>
                      </button>
                    </div>
                    <div class="pe-1">
                      <button
                        class="primary-button w-100 me-1"
                        [class.primary-button-inactive]="isRecoveryDisabled() || isPreviewApplicationButtonDisabled()"
                        [disabled]="isRecoveryDisabled() || isPreviewApplicationButtonDisabled()"
                        (click)="previewApplication()"
                        >
                        <div [disablePopover]="isRecoveryDisabled() || !isPreviewApplicationButtonDisabled()" container="body"  triggers="hover" placement="top" [ngbPopover]="previewApplicationMissingHoverText">
                          {{ 'case-economy-recovery-preview-application' | translate }}
                        </div>
                      </button>
                    </div>
                    <div class="pe-1">
                      <ng-template #sendApplicationPopContent><span [innerHTML]="sendApplicationMissingHoverText | safeHtml"></span></ng-template>
                      <button
                        class="primary-button w-100 me-1"
                        [class.primary-button-inactive]="isRecoveryDisabled() || isSendApplicationButtonDisabled()"
                        [disabled]="isRecoveryDisabled() || isSendApplicationButtonDisabled()"
                        (click)="sendApplication()"
                        >
                        <div [disablePopover]="isRecoveryDisabled() || isApplicationSent() || !isSendApplicationButtonDisabled()" container="body" triggers="hover" placement="top" [ngbPopover]="sendApplicationPopContent">
                          {{ 'case-economy-recovery-send-application' | translate }}
                        </div>
                      </button>
                    </div>
                    <div class="pe-1">
                      <ng-template #saveApplicationPopContent><span [innerHTML]="saveApplicationMissingHoverText | safeHtml"></span></ng-template>
                      <button
                        class="primary-button w-100 me-1"
                        [class.primary-button-inactive]="isRecoveryDisabled() || isSaveApplicationButtonDisabled()"
                        [disabled]="isRecoveryDisabled() || isSaveApplicationButtonDisabled()"
                        (click)="saveApplication()"
                        >
                        <div [disablePopover]="isRecoveryDisabled() || isApplicationSent() || !isSaveApplicationButtonDisabled()" container="body" triggers="hover" placement="top" [ngbPopover]="saveApplicationPopContent">
                          {{ 'case-economy-recovery-save-application' | translate }}
                        </div>
                      </button>
                    </div>
                    <div class="pe-1">
                      <button
                        class="primary-button w-100 me-1"
                        [class.primary-button-inactive]="isRecoveryDisabled() || recovery.recoveryStatus !== RecoveryStatus.SEND_APPLICATION"
                        [disabled]="isRecoveryDisabled() || recovery.recoveryStatus !== RecoveryStatus.SEND_APPLICATION"
                        (click)="applicationSent()"
                      >
                        <div>{{ 'case-economy-recovery-application-sent' | translate }}</div>
                      </button>
                    </div>
                  <div class="pe-1">
                    <button
                      class="primary-button w-100 me-1"
                      [class.primary-button-inactive]="isRecoveryDisabled() || recovery.recoveryStatus !== RecoveryStatus.PAYMENT_IN_AS400"
                      [disabled]="isRecoveryDisabled() || recovery.recoveryStatus !== RecoveryStatus.PAYMENT_IN_AS400"
                      (click)="paymentInAs400()"
                    >
                      <div>{{ 'case-economy-recovery-payment-in-as400' | translate }}</div>
                    </button>
                  </div>
                    <div class="flex-grow-1">
                      &nbsp;
                    </div>
                    <div>
                      <div *ngIf="showDeleteButton()" (click)="deleteRecovery()" class="delete-recovery d-flex align-items-end">
                        <div [ngClass]="isDeleteButtonDisabled() ? 'delete-recovery-image-grey' : 'delete-recovery-image-red'">
                          <div class="delete-recovery-text">{{ 'case-economy-recovery-delete-recovery' | translate }}</div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
              <div class="col-3" >
                <div class="recovery-footer-box">
                  <div class="recovery-footer-box-content p-0 font-weight-bold">
                    <div class="d-flex ps-2 pb-1 pe-2 pt-1">
                      <div class="d-flex flex-column w-50">
                        <div class="pt-2">{{ 'case-economy-due-date' | translate }}</div>
                      </div>
                      <div class="w-50 text-end">
                        <app-input-calendar
                          [withImage]="true"
                          [(date)]="recovery.dueDate"
                          [disabled]="isRecoveryDisabled()"
                          [required]="!recovery.dueDate"
                          (dateChange)="recalculateRecoveryState()">
                        </app-input-calendar>
                      </div>
                    </div>
                    <div class="d-flex ps-2 pb-1 pe-2 pt-1">
                      <div class="d-flex flex-column w-50">
                        <div class="pt-2">{{ 'case-economy-application-deadline' | translate }}</div>
                      </div>
                      <div class="w-50 text-end">
                        <app-input-calendar
                          [withImage]="true"
                          [(date)]="recovery.applicationDeadline"
                          [disabled]="isRecoveryDisabled() || isApplicationSent()"
                          [required]="!recovery.applicationDeadline"
                          (dateChange)="recalculateRecoveryState()">
                        </app-input-calendar>
                      </div>
                    </div>
                    <div class="d-flex ps-2 pb-1 pe-2 pt-1 " >
                      <div class="w-50 pt-1 font-weight-bold">{{ 'case-economy-recovery-applied-amount' | translate }}</div>
                      <div class="w-50 input-with-currency">
                        <div class="input-with-currency-currency">{{ recovery?.recoveryCurrency }}</div>
                        <input
                        class="input-height"
                        [ngModel]="totalAppliedAmount()"
                        [disabled]="true"
                        currencyMask
                        [options]="currencyMaskOptions"
                        (focus)="focusElement($event)"/>
                      </div>
                    </div>
                    <div class="d-flex ps-2 pb-1 pe-2 pt-1 " >
                      <div class="w-50 pt-1 font-weight-bold">{{ 'case-economy-recovery-recovered-amount' | translate }}</div>
                      <div class="w-50 input-with-currency">
                        <div class="input-with-currency-currency">{{ recovery?.recoveryCurrency }}</div>
                        <input
                        class="input-height"
                        [ngModel]="totalRecoveredAmount()"
                        [disabled]="true"
                        currencyMask
                        [options]="currencyMaskOptions"
                        (focus)="focusElement($event)"/>
                      </div>
                    </div>
                    <div class="d-flex ps-2 pb-1 pe-2 pt-1 " >
                      <div class="w-50 pt-1 font-weight-bold"></div>
                      <div class="w-50 input-with-currency">
                        <div class="input-with-currency-currency">%</div>
                        <input
                        class="input-height"
                        [ngModel]="recoveredProcent()"
                        [disabled]="true"
                        currencyMask
                        [options]="currencyMaskOptions"
                        (focus)="focusElement($event)"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-1">
                  {{ 'case-economy-recovery-internal-remark' | translate }}
                  <div>
                    <textarea
                      name="textarea"
                      class="internal-remark-text"
                      maxlength="1000"
                      [disabled]="readOnly|| isCancelled() "
                      [(ngModel)]="recovery.internalRemark"
                      (change)="recalculateRecoveryState()"
                      >
                    </textarea>
                  </div>
                </div>
                <div class="mt-2 d-flex justify-content-end">
                  <button
                      class="primary-button ml-1 button-width"
                      [class.primary-button-inactive]="isRecoveryDisabled() || isSaveButtonDisabled()"
                      [disabled]="isRecoveryDisabled() || isSaveButtonDisabled()"
                      (click)="saveAndClose()"
                      >
                      {{ 'case-economy-recovery-save-and-close' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    <div *ngIf="showSpinner || storingRecovery" class="overlay d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
</div>
