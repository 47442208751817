import { DialogBoundryService } from '@secca/core/services/dialog-boundry.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DialogHelperUtilService } from '@secca/core/services/dialog-helper-util.service';
import { MedicalPreAssessmentDialogData } from './../../medical-pre-assessment/medical-pre-assessment-dialog/medical-pre-assessment-data.component';
import { DialogSelectableDirective } from '@secca/shared/directives/dialog-selectable.directive';
import { MinimizableDialogType } from '@secca/shared/enums/minimizable-dialog-type-enum';
import { DialogMinimizableDirective } from '@secca/shared/directives/dialog-minimizable.directive';
import { MedicalPreAssessmentComponent } from './../medical-pre-assessment.component';
import { IFocusEnabled } from '@secca/shared/interfaces/focus-enabled';
import { IKeyboardEnabled } from '@secca/shared/interfaces/keyboard-enabled';
import { IMinimizable } from '@secca/shared/interfaces/minimizable';
import { AdditionalInfo, IAdditionalInfo } from '@secca/shared/interfaces/additional-info';
import { Component, ViewChild, Inject } from '@angular/core';

@Component({
  selector: 'app-medical-pre-assessment-dialog',
  templateUrl: './medical-pre-assessment-dialog.component.html',
  styleUrls: ['./medical-pre-assessment-dialog.component.scss']
})
export class MedicalPreAssessmentDialogComponent implements IAdditionalInfo, IMinimizable, IKeyboardEnabled, IFocusEnabled {
  @ViewChild(MedicalPreAssessmentComponent) wrappedInstance: MedicalPreAssessmentComponent;
  @ViewChild(DialogMinimizableDirective) minimizedDirective: DialogMinimizableDirective;
  @ViewChild(DialogSelectableDirective) selectableDirective: DialogSelectableDirective;

  data: MedicalPreAssessmentDialogData;

  constructor(
    private dialogRef: MatDialogRef<MedicalPreAssessmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: MedicalPreAssessmentDialogData,
    private dialogHelperUtilService: DialogHelperUtilService,
    public dialogBoundryService: DialogBoundryService
  ) {
    this.data = data;
  }

  close() {
    this.dialogHelperUtilService.close(this.dialogRef, this.getAdditionalInfo()?.caseId);
  }

  keyboardClose(): void {
    this.wrappedInstance.close();
  }

  minimize() {
    this.minimizedDirective.minimize();
  }

  getMinimizeType(): MinimizableDialogType {
    return this.minimizedDirective.appDialogMinimizable;
  }

  getAdditionalInfo(): AdditionalInfo {
    const caseId: number = +this.data.case.id;
    const caseNumber: string = this.data.case.caseNumber;

    return {
      caseId,
      hoverTitle: caseNumber || '',
      hoverText: '',
    };
  }


  getFocusElement(): any {
    return this.selectableDirective?.focusElement;
  }

  get MinimizableDialogType() {
    return MinimizableDialogType;
  }

}
