import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { CaseLockHttpService } from './case-lock-http.service';
import { QueueService } from './queue.service';
import { SettingsService } from './settings.service';
import { OtherInsurance } from './../../shared/models/otherInsurance';

@Injectable({
  providedIn: 'root'
})
export class UpdateOtherInsuranceService extends BaseService {

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private caseLockHttpService: CaseLockHttpService,
    private queueService: QueueService
  ) {
    super(settingsService);
  }

  actionSchedule() {
    if (!this.queueService.anyOtherInsuranceQueuedOperationsRunning()) {
      return this.runActions();
    }
  }

  runActions() {
    this.queueService.otherInsuranceQueuedOperationsRunning(true);

    const nextOtherInsurance = this.queueService.popOtherInsurance();
    if (nextOtherInsurance == null) {
      return this.queueService.otherInsuranceQueuedOperationsRunning(false);
    }

    return this.caseLockHttpService
      .put<OtherInsurance>(this.baseURL + 'otherinsurance/',  nextOtherInsurance, { headers: this.jsonHeaders })
      .subscribe(() => {
        this.runActions();
      });
  }
}