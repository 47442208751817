<div class="modal-content">
  <div class="modal-header">
    <div class="modal-header-title">
      {{ title }}
    </div>
    <div class="modal-buttons">
      <div (click)="closeTaskWebDialog()">
        <img src="/assets/icons/Close White.svg" />
      </div>
    </div>
  </div>
  <div class="modal-content-body">
    <div>
      <app-case-lock-overlay [lockContext]="LockContextEnum.CASE_BASIC" [asOverlay]="false"
        [unlockPostAction]="CaseLockOverlayAction.TAKE_LOCK"></app-case-lock-overlay>
      <div class="row m-0">
        <div class="col-3 task-details p-0">
          <app-task-web-left-panel [taskViewModel]="taskViewModel" [taskStatusDisabled]="taskStatusDisabled"
            (modalTaskLoadedEvent)="taskLoadedNotification($event)" (taskStatusChanged)="taskStatusChanged($event)"
            [disabled]="isDisabled"
            #taskModal>
          </app-task-web-left-panel>

          <div *ngIf="taskDataViewModel?.taskType !== TaskType.processGopRequest">
            <div [appDisableInput]="isDisabled" class="caselock-no-events">
              <div *permission="PermissionEnum.TASK_UPDATE">
                <div class="action-button">
                  <app-drop-menu [label]="'Actions'" [disabled]="isDisabled">
                    <ng-template #optionTemplate>
                      <div (click)="openMoveTaskModal()">{{'task-message-modal-dropdown-message-related-to-other-case' | translate}}</div>
                    </ng-template>
                    <ng-template #optionTemplate>
                      <div (click)="openMessageModal(null, false, true)">{{'task-message-modal-dropdown-forward-message-to-other-application' | translate}}</div>
                    </ng-template>
                    <ng-template #optionTemplate>
                      <div (click)="openMessageModalGDPR(false, true)">{{'task-message-modal-dropdown-message-is-not-gdpr-compliant' | translate}}</div>
                    </ng-template>
                    <ng-template #optionTemplate>
                      <div (click)="openMessageModalGDPR(true, false)">{{'task-message-modal-dropdown-reply-no-need-for-stakeholder' | translate}}</div>
                    </ng-template>
                  </app-drop-menu>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-9 message-details">
          <app-task-web-modal-main-panel [taskViewModel]="taskViewModel" [taskDataViewModel]="taskDataViewModel"
            [serviceItemList]="serviceItemList" [purposeOfTravelItemList]="purposeOfTravelItemList" [causes]="causes" [diagnoses]="diagnoses"
            [customerProfiles]="customerProfiles" [companyStakeholders]="companyStakeholders" [channels]="channels"
            [isSwitchReporterOnCaseAllowed]="isSwitchReporterOnCaseAllowed" [switchReporter]="switchReporter"
            [hideSearchCreateButton]="hideSearchCreateButton" [createCaseOnly]="createCaseOnly"
            (createCaseButtonPressed)="createCase()" (closed)="close()">
          </app-task-web-modal-main-panel>
        </div>
      </div>
    </div>
    <div class="horizontal-line"></div>
    <div class="row">
      <div class="col-12">
        <div [appDisableInput]="isDisabled">
          <button class="primary-button save-button" [disabled]="!hasModelChanged() || !isValidated()"
            (click)="saveTaskChanged()" [class.primary-button-inactive]="!hasModelChanged() || !isValidated()"
            [class.button-busy]="showSpinner">
            {{ 'task-message-modal-save' | translate }}
            <img src="/assets/icons/waitForSearchresult.gif" class="spinner" [class.hide-spinner]="!showSpinner" />
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="showOverlaySpinner" class="overlay d-flex justify-content-center">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
