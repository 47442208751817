import { CustomerInvoiceJobSalesOrder } from './customer-invoice-job-sales-order';

export class CustomerInvoiceJobFilterResponse {
  totalNumber: number;
  jobSalesOrders: CustomerInvoiceJobSalesOrder[];

  public constructor(init?: Partial<CustomerInvoiceJobFilterResponse>) {
    Object.assign(this, init);
  }
}
