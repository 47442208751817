<div class="card">
  <div class="sub-component-placeholder">
    <div class="sub-component-container">
      <div class="sub-title">{{ 'sub-stakeholders-title' | translate }}</div>
      <div class="sub-content-container d-flex">
        <div class="export-boks" (click)="onExportButtonClicked()">
          <img src="../assets/icons/export.svg"
            [ngbPopover]="'export' | translate"
            placement="auto"
            triggers="hover">
        </div>
      </div>
      <div class="sub-content-container d-flex">
        <div class="dropdown-width me-2">
          <app-drop-down
              [name]="'major-incident-service-order-category' | translate"
              [items]="majorIncidentCategoryList"
              [(selectedItemId)]="majorIncidentExtension.majorIncidentCategory"
              [disabled]="false"
              [requiredRedBackground]="true"
              [recommended]="false">
          </app-drop-down>
        </div>
        <div class="dropdown-width me-3">
          <app-input
            [name]="'major-incident-service-order-event' | translate"
            [disabled]="true"
            [value]="incidentEventName"
          ></app-input>
        </div>
        <div class="dropdown-width">
          <app-sub-commitment-type
            [serviceOrderType]="serviceOrderType"
            [(commitmentType)]="majorIncidentExtension.commitmentType"
            [readOnlyMode]="true">
          </app-sub-commitment-type>
        </div>
      </div>
      <div class="sub-content-container d-flex">
        <div class="w-50 me-1">
          <app-input-date-time
            [name]="'money-transfer-start-date' | translate"
            [date]="majorIncidentExtension.startDate"
            (dateChange)="startDateChange($event)"
            [withImage]="true"
            [timeZone]="locationTimeZone || 'local'"
          >
          </app-input-date-time>
        </div>
        <div class="w-50 ms-1">
          <app-input-location
              [withImage]="true"
              [(value)]="majorIncidentExtension.location"
              [allowEmpty]="false"
              [name]="'Location'"
              (saveModel)="locationUpdate()"
              [disabled]="false"
              placeholder=""
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
              type="text"
          ></app-input-location>
        </div>
      </div>
      <div *ngFor='let majorIncidentCaseDetail of majorIncidentCaseDetails'>
          <app-sub-major-incident-stakeholders
            [majorIncidentCaseDetail]="majorIncidentCaseDetail"
            [flightStakeholders]='majorIncidentExtension.subExpectedStakeholders'
          >
          </app-sub-major-incident-stakeholders>
      </div>
    </div>
  </div>
</div>
