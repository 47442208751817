<div class="coverage-info">
  <div class="coverage-info-column" *ngIf="reserve != null">
    <div class="coverage-info-column-title">Reserve</div>
    <div class="coverage-info-column-value">{{reserve | numberFormat}} {{billingCurrency}}</div>
  </div>
  <div class="coverage-info-column" *ngIf="plannedCost != null">
    <div class="coverage-info-column-title">Planned cost</div>
    <div class="coverage-info-column-value">{{plannedCost | numberFormat}} {{billingCurrency}}</div>
  </div>
  <div class="coverage-info-column coverage-info-last-column" *ngIf="remaining != null">
    <div class="coverage-info-column-title">Remaining</div>
    <div class="coverage-info-column-value" [ngClass]="remaining < 0 && 'coverage-info-column-value-below-zero'">{{remaining | numberFormat}} {{billingCurrency}}
    </div>
  </div>
</div>
