import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { SalesOrder } from '@secca/shared/models/salesOrder';
import { BaseRow } from '../base-row';

@Component({
  selector: 'app-customer-invoice-row',
  templateUrl: './customer-invoice-row.component.html',
  styleUrls: ['./../base-row.component.scss']
})
export class CustomerInvoiceRowComponent extends BaseRow implements OnChanges {
  statusChanged = false;

  ngOnChanges(changes: SimpleChanges): void {
    const previousValue = this.entry.previousValue as SalesOrder;
    const newValue = this.entry.newValue as SalesOrder;
    if (!previousValue || (newValue.status !== previousValue.status)) {
      this.statusChanged = true;
    }
  }

}
