import { HandlingAreaStatusEnum, HandlingAreaType } from './enums';
import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';

export class LinkedCasesList {
    caseNumber: string;
    caseId: number;
    name: string;
    id: string;
    primary: string;
    age: number;
    status: HandlingAreaStatusEnum;
    groupId: string;

    public constructor(init?: Partial<LinkedCasesList>) {
      Object.assign(this, init);
    }
}

@Injectable({
    providedIn: 'root'
})

export class LinkedCasesListAdapter implements Adapter<LinkedCasesList> {
    adapt(item: any): LinkedCasesList {
        return new LinkedCasesList({
            caseNumber: item.caseNumber,
            caseId: item.caseId,
            name: item.name,
            id: item.id,
            age: item.age,
            primary: item.primary,
            status: item.status,
            groupId: item.groupId,
        });
    }
}