<div class="questionContainer">
  <div class="head-text">{{ header }} </div>
  <label class="radio-button">
    <a class="blue-button" [ngClass]="{'blue-button-required' : questionValue === undefined, 'blue-button-on' : questionValue === true}" (click)="answerYes()" (keyup.enter)="answerYes()" tabindex="0" ngFocus></a>
    <a class="blue-button" [ngClass]="{'blue-button-required' : questionValue === undefined, 'blue-button-dot' : questionValue === true}" (click)="answerYes()"></a>
    <div class="text"> Yes </div>
  </label>
  <label class="radio-button">
    <a class="blue-button" [ngClass]="{'blue-button-required' : questionValue === undefined, 'blue-button-on' : questionValue === false}" (click)="answerNo()" (keyup.enter)="answerNo()" tabindex="0"></a>
    <a class="blue-button" [ngClass]="{'blue-button-required' : questionValue === undefined, 'blue-button-dot' : questionValue === false}" (click)="answerNo()"></a>
    <div class="text"> No </div>
  </label>
</div>