
import {
  SubTravelInfo,
  SubTravelInfoAdapter,
} from './../../../modules/case/components/case-plans/case-plans/add-service-order/models/subTravelInfo';
import { SubTravellers } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subTravellers';
import { SubStakeholders } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholders';
import { SubStakeholder } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholder';
import { SubRemarksToSupplier } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subRemarksToSupplier';
import { SubCoffinUrn } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subCoffinUrn';
import { SubRemarksFromSupplier } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subRemarksFromSupplier';
import { SubAirwayBill } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subAirwayBill';
import { TransportTypeEnum } from './../enums';
import { SubTransportType } from './../../../modules/case/components/case-plans/case-plans/add-service-order/models/subTransportType';
import { SubReference } from '../../../modules/case/components/case-plans/case-plans/add-service-order/models/subReference';
import { ServiceOrderExtension } from './service-order-extension';
import { Location } from '@secca/shared/models/location';
import * as moment from 'moment-timezone';
import { CommonServiceOrderExtension } from './CommonServiceOrderExtension.component';
import { CommitmentType, CommitmentTypeAdapter } from '../commitment-type';

export class GroundTranportServiceOrderExtension extends CommonServiceOrderExtension implements ServiceOrderExtension {
  id: number;
  transportType: SubTransportType;
  commitmentType: CommitmentType;
  reference: SubReference;
  displayDurationId: number;
  airwayBill: SubAirwayBill;
  remarksFromSupplier: SubRemarksFromSupplier;
  coffinUrn: SubCoffinUrn;
  remarksToSupplier: SubRemarksToSupplier;
  travellers: SubTravellers;
  stakeholders: SubStakeholder[];
  subStakeholders: SubStakeholders;
  travelInfo: SubTravelInfo;

  public constructor(init?: Partial<GroundTranportServiceOrderExtension>) {
    super();
    Object.assign(this, init);
    if (!init) {
      this.transportType = new SubTransportType(TransportTypeEnum.TAXI);
      this.reference = new SubReference();
      this.commitmentType = null;
      this.airwayBill = new SubAirwayBill();
      this.coffinUrn = new SubCoffinUrn();
      this.remarksFromSupplier = new SubRemarksFromSupplier();
      this.remarksToSupplier = new SubRemarksToSupplier();
      this.travellers = new SubTravellers();
      this.travelInfo = this.travellers.flightLegs[0];
      this.subStakeholders = new SubStakeholders();
      this.stakeholders = this.subStakeholders.stakeholders;
    }
  }

  isValidExpectation(): boolean {
    return (
      this.subStakeholders.stakeholders.length > 0 &&
      this.subStakeholders.stakeholders[0].stakeholderId != null &&
      this.travelInfo.departureDate != null &&
      this.travelInfo.arrivalDate != null &&
      !!this.travelInfo.arrivalAt.freeText &&
      !!this.travelInfo.departureFrom.freeText
    );
  }

  isValid(): boolean {
    return (
      !!this.commitmentType &&
      this.subStakeholders.stakeholders.length > 0 &&
      this.subStakeholders.stakeholders[0].stakeholderId != null &&
      !!this.travelInfo.departureFrom.freeText &&
      this.travelInfo.departureDate != null &&
      this.travelInfo.arrivalDate != null &&
      !!this.travelInfo.arrivalAt.freeText
    );
  }

  init() {
    this.transportType = new SubTransportType(TransportTypeEnum.TAXI);
  }

  isPreviewGopValid(): boolean {
    throw new Error("Gop preview not supported for this service order type");
  }

  getStartDate(): moment.Moment {
    return this.travelInfo.departureDate;
  }

  getStartLocation(): Location {
    return this.travelInfo.departureFrom;
  }

  getEndLocation(): Location {
    return this.travelInfo.arrivalAt;
  }


  getEndDate(): moment.Moment {
    return this.travelInfo.arrivalDate;
  }

  isMultipleDurations(): boolean {
    return false;
  }

  setCommitmentType(commitmentType: CommitmentType) {
    this.commitmentType = commitmentType;
  }

  getCommitmentType(): CommitmentType {
    return this.commitmentType;
  }

  hasMedicalEscort(): boolean {
    return false;
  }

  hasCoTravellers(): boolean {
    return false;
  }

  getSelectedStakeholderIds(): string[] {
    return this.subStakeholders.stakeholders.map(subStakeholder => subStakeholder.stakeholderId);
  }
}

export class GroundTransportServiceOrderExtensionAdapter {
  adapt(item?: Partial<GroundTranportServiceOrderExtension>): GroundTranportServiceOrderExtension {
    const travelInfoAdapter = new SubTravelInfoAdapter();
    const travelInfoAdapted = travelInfoAdapter.adapt(item.travelInfo);
    const travelInfoAddaptedArray: SubTravelInfo[] = [];
    const commitmentTypeAdapter: CommitmentTypeAdapter = new CommitmentTypeAdapter();
    travelInfoAddaptedArray.push(travelInfoAdapted);
    return new GroundTranportServiceOrderExtension({
      id: item.id,
      transportType: item.transportType,
      commitmentType:
        item.commitmentType != null ? commitmentTypeAdapter.adapt(item.commitmentType) : null, //TODO new SubCommitmentType(CommitmentTypes.BOOKING),
      reference: item.reference == null ? new SubReference() : item.reference,
      airwayBill: item.airwayBill == null ? new SubAirwayBill() : item.airwayBill,
      coffinUrn: item.coffinUrn == null ? new SubCoffinUrn() : item.coffinUrn,
      remarksFromSupplier: item.remarksFromSupplier == null ? new SubRemarksFromSupplier() : item.remarksFromSupplier,
      remarksToSupplier: item.remarksToSupplier == null ? new SubRemarksToSupplier() : item.remarksToSupplier,
      travelInfo: travelInfoAdapted,
      travellers: new SubTravellers(travelInfoAddaptedArray),
      stakeholders: item.stakeholders,
      subStakeholders: new SubStakeholders(item.stakeholders),
    });
  }
}
