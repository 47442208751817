import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Event, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AbstractGlobalBoardTableShortcutsComponent } from '@secca/shared/components/abstract-global-board-table-shortcuts.component';
import { TaskRedirectFromGlobalTaskBoard } from '@secca/shared/models/task-redirect-from-global-task-board';
import { TaskViewModel } from '../models/task-view.model';
import { TaskService } from '@secca/core/services/task.service';
import { CaseService } from 'src/app/core/services/case.service';
import { TableSort } from '@secca/shared/models/table-sort';
import { TaskStateService } from '../services/task-state.service';
import { DictionaryService } from '@secca/core/services/dictionary.service';
import { PlanService } from '@secca/core/services/plan.service';
import { CommitmentTypes, PatientType, ConsentStatusEnum, HandlingAreaStatusEnum, ShortcutEnum, SortOrder, TaskStatus, TaskType, TaskTypeEnumIcon } from '@secca/shared/models/enums';
import { TaskHoverModel } from './task-hover/task-hover-model';
import { UserDto } from '@secca/shared/models/userDto';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import { LockContextEnum } from '@secca/shared/enums/lock-context.enum';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DialogViewerService } from '@secca/core/services/dialog-viewer.service';
import { TaskModalModeEnum } from '@secca/case/components/case-task/task-modal-static/task-modal-static-mode.enum';
import { TranslateService } from '@ngx-translate/core';
import { ScreeningStates } from '@secca/shared/enums/screening-states';
import { SettingsService } from '@secca/core/services/settings.service';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { CASE_TYPE_CODES } from '@secca/shared/models/case-type';
import { BoardNavigationStateService } from '../services/board-navigation-state.service';

@Component({
  selector: 'app-board-task-table',
  templateUrl: './board-task-table.component.html',
  styleUrls: ['./board-task-table.component.scss'],
})
@AutoUnsubscribe
export class BoardTaskTableComponent extends AbstractGlobalBoardTableShortcutsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() taskStatus: TaskStatus;

  @Input() set taskViewModels(data: TaskViewModel[]) {
    this._taskViewModels = data;
    this.numberOfRows = data?.length;
    setTimeout(() => {
      if (this.taskStatus === TaskStatus.toDo) {
        this.selectedRowIndex = this.boardNavigationStateService.getState('board-case-filter-request-to-do-task-selected-row-index') ? parseInt(this.boardNavigationStateService.getState('board-case-filter-request-to-do-task-selected-row-index'), 10) : 0;
        this.inputScrollPosition = this.boardNavigationStateService.getState('board-case-filter-request-to-do-task-scroll-position') ? parseInt(this.boardNavigationStateService.getState('board-case-filter-request-to-do-task-scroll-position'), 10) : 0;
      }
      if (this.taskStatus === TaskStatus.done) {
        this.selectedRowIndex = this.boardNavigationStateService.getState('board-case-filter-request-done-task-selected-row-index') ? parseInt(this.boardNavigationStateService.getState('board-case-filter-request-done-task-selected-row-index'), 10) : 0;
        this.inputScrollPosition = this.boardNavigationStateService.getState('board-case-filter-request-done-task-scroll-position') ? parseInt(this.boardNavigationStateService.getState('board-case-filter-request-done-task-scroll-position'), 10) : 0;
      }
      this.navigateToTable();
    }, 50);
  }
  get taskViewModels(): TaskViewModel[] {
    return this._taskViewModels;
  }

  @Input() taskSorting: TableSort;
  @Output() sortingAction = new EventEmitter<TableSort>();
  @Output() updateTaskListAfterEdit = new EventEmitter();

  isCheckAll = false;
  sortedOn: any = { dueDate: true };
  sortOrder: any = {};
  to: string;
  from: string;
  selectedTaskViewModel: TaskViewModel;
  loggedInUser: UserDto;
  modalRef: MatDialogRef<any>;
  redirectedToUrl = '';
  loggedInUserSubscription: Subscription;
  routerSubscription: Subscription;
  NO = ScreeningStates.NO;
  YES = ScreeningStates.YES;
  TBD = ScreeningStates.TO_BE_DETERMINED;
  inputScrollPosition = 0;

  private _taskViewModels: TaskViewModel[];

  constructor(
    private planService: PlanService,
    private router: Router,
    private taskStateService: TaskStateService,
    private caseStateService: CaseStateService,
    private dialogViewerService: DialogViewerService,
    private caseService: CaseService,
    private taskService: TaskService,
    private dictionaryService: DictionaryService,
    private translateService: TranslateService,
    private caseLockHelperService: CaseLockHelperService,
    public settingsService: SettingsService,
    private boardNavigationStateService: BoardNavigationStateService
  ) {
    super('taskTabel');
    this.loggedInUserSubscription = dictionaryService.getLoggedInUser()
      .subscribe(user => {
          this.loggedInUser = user;
      });
    this.routerSubscription = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.redirectedToUrl = event.url;
      }
    });
    this.shortcutSubscriptions.push(
      this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateTableRowCtrlEnter }).subscribe(_ => {
        if (typeof this.selectedRowIndex === 'number') {
          const taskViewModel = this.taskViewModels[this.selectedRowIndex];
          if (taskViewModel.taskType === TaskType.medical) {
            this.redirectToTaskInCase(taskViewModel);
          } else {
            this.viewTask(taskViewModel);
          }
        }
      }),
      this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateTableRowAltEnter }).subscribe(_ => {
        if (typeof this.selectedRowIndex === 'number') {
          this.redirectToTaskInCase(this.taskViewModels[this.selectedRowIndex]);
        }
      }),
    );
  }

  assigneeShortName(assignInitials: string): string {
    return this.caseService.teamNameShortName(assignInitials);
  }

  ngOnInit() {
    if (this.taskSorting != null) {
      this.sortedOn = {};
      this.sortedOn[this.taskSorting.sortBy] = true;
      this.sortOrder[this.taskSorting.sortBy] = this.taskSorting.sortOrder;
    }
    this.taskStateService.sendHoveredTask(undefined);

    const scrollDoneTaskPositionJson = this.boardNavigationStateService.getState('board-case-filter-request-done-task-scroll-position') ? this.boardNavigationStateService.getState('board-case-filter-request-done-task-scroll-position') : '0';
    const scrollToDoTaskPositionJson = this.boardNavigationStateService.getState('board-case-filter-request-to-do-task-scroll-position') ? this.boardNavigationStateService.getState('board-case-filter-request-to-do-task-scroll-position') : '0';

    if (this.taskStatus === TaskStatus.toDo && !!scrollToDoTaskPositionJson) {
      this.inputScrollPosition = parseInt(scrollToDoTaskPositionJson, 10);
    }
    if (this.taskStatus === TaskStatus.done && !!scrollDoneTaskPositionJson) {
      this.inputScrollPosition = parseInt(scrollDoneTaskPositionJson, 10);
    }
  }

  ngOnChanges() {
    setTimeout(() => this.setScrollPosition(), 500);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.taskStatus === TaskStatus.toDo) {
      this.boardNavigationStateService.saveState('board-case-filter-request-to-do-task-scroll-position', JSON.stringify(this.inputScrollPosition));
      this.boardNavigationStateService.saveState('board-case-filter-request-to-do-task-selected-row-index', JSON.stringify(this.selectedRowIndex));
    }
    if (this.taskStatus === TaskStatus.done) {
      this.boardNavigationStateService.saveState('board-case-filter-request-done-task-scroll-position', JSON.stringify(this.inputScrollPosition));
      this.boardNavigationStateService.saveState('board-case-filter-request-done-task-selected-row-index', JSON.stringify(this.selectedRowIndex));
    }
  }

  setScrollPosition() {
    if (this.inputScrollPosition !== null && document.getElementById('taskTabel')) {
      document.getElementById('taskTabel').scrollTop = this.inputScrollPosition;
    }
  }

  onCaseTabelScroll() {
    this.inputScrollPosition = document.getElementById('taskTabel').scrollTop;
  }

  getTaskTypeImagePath(taskViewModel: TaskViewModel): string {
    return TaskTypeEnumIcon.getIcon(taskViewModel.taskType, taskViewModel.status);
  }

  redirectToCase(caseId: number) {
    this.router.navigate(['case', caseId]);
  }

  redirectToTaskInCase(taskViewModel: TaskViewModel) {
    if (taskViewModel.caseNumber) {
      this.taskService.taskRedirectFromGlobalTaskBoard = new TaskRedirectFromGlobalTaskBoard({
        caseId: taskViewModel.caseId,
        taskId: +taskViewModel.id,
        taskStatus: taskViewModel.status,
        directRedirection: true,
      });
      this.router.navigate(['case', taskViewModel.caseId]);
    } else {
      this.viewTask(taskViewModel);
    }
  }

  checkAllTasks() {
    this.isCheckAll = !this.isCheckAll;
    this.taskViewModels.forEach(a => (a.isCheck = this.isCheckAll));
  }

  mouseTitleEnter(event, taskViewModel: TaskViewModel) {
    const spanElement = event.srcElement;
    const tdElement = event.srcElement.parentNode;
    const tableElement = tdElement.parentNode.parentNode;
    const taskHoverModel: TaskHoverModel = new TaskHoverModel();
    taskHoverModel.title = taskViewModel.title;
    taskHoverModel.description = this.removeNewlines(taskViewModel.description);
    taskHoverModel.LeftHoverElemet = tdElement.offsetLeft - (taskHoverModel.width - tdElement.getBoundingClientRect().width);
    taskHoverModel.bottomHoverElement =
      tableElement.scrollTop +
      tableElement.getBoundingClientRect().height -
      (tdElement.offsetTop - tdElement.getBoundingClientRect().height) -
      15;
    taskHoverModel.leftTrianglePosition =
      taskHoverModel.width -
      tdElement.getBoundingClientRect().width +
      (spanElement.getBoundingClientRect().width > tdElement.getBoundingClientRect().width
        ? tdElement.getBoundingClientRect().width
        : spanElement.getBoundingClientRect().width) /
        2 -
      7;
    this.taskStateService.sendHoveredTask(taskHoverModel);
  }

  mouseTitleLeave() {
    this.taskStateService.sendHoveredTask(undefined);
  }

  sortBy(column: string) {
    this.sortedOn = {};
    this.sortedOn[column] = true;
    if (this.sortOrder[column] == null) {
      this.sortOrder[column] = SortOrder.asc;
    } else {
      if (this.sortOrder[column] === SortOrder.asc) {
        this.sortOrder[column] = SortOrder.desc;
      } else {
        this.sortOrder[column] = SortOrder.asc;
      }
    }
    this.sortingAction.emit(new TableSort({ sortBy: column, sortOrder: this.sortOrder[column] }));
  }

  sortedBy(column: string): string {
    return this.sortedOn[column] ? 'sorted-by' : '';
  }

  viewTask(taskViewModel: TaskViewModel) {
    this.redirectedToUrl = '';

    this.caseService.getCase(taskViewModel.caseId.toString()).subscribe(seccaCase => {
      this.caseStateService.sendCase(seccaCase);

      // Update caselocks to determine if loggedin user has already taken the lock
      this.caseLockHelperService.updateCaseLocks(seccaCase.id, seccaCase.caseLocks);

      const isCaseAlreadyLockedByTheLoggedInUser = this.caseLockHelperService.isCaseLockedByTheLoggedInUser(LockContextEnum.CASE_BASIC);
      if (!isCaseAlreadyLockedByTheLoggedInUser && !this.caseLockHelperService.isCaseBasicLockTaken) {
        // If not already locked by the loggedin user or others, we try to take the lock
        this.caseLockHelperService.lockCase(LockContextEnum.CASE_BASIC).subscribe();
      }

      this.openTaskModal(taskViewModel);
    });
  }

  getConsentImage(taskViewModel: TaskViewModel): string {
    let iconName = '';
    switch (taskViewModel.consentType) {
      case ConsentStatusEnum.CONSENT_ACCEPTED:
      case ConsentStatusEnum.CONSENT_ACCEPTED_MAN:
      case ConsentStatusEnum.CONSENT_ACCEPTED_WEB:
        iconName = 'Success Small.svg';
        break;

      case ConsentStatusEnum.ORAL:
        iconName = 'Approx Small.svg';
        break;

      case ConsentStatusEnum.CONSENT_REJECTED:
      case ConsentStatusEnum.CONSENT_NOT_POSSIBLE:
        iconName = 'Failure Small.svg';
        break;

      case ConsentStatusEnum.REQUESTED:
      case ConsentStatusEnum.REQUESTED_MANUALLY_AGAIN:
      case ConsentStatusEnum.REQUESTED_MANUALLY:
        iconName = 'Requested.svg';
        break;

      case ConsentStatusEnum.CONSENT_REQUEST_NOT_SENT:
      case ConsentStatusEnum.UNKNOWN:
          iconName = 'Not sent.svg';
    }

    return iconName ? `/assets/icons/consent/${iconName}` : '';
  }

  getConsentText(taskViewModel: TaskViewModel): string {
    switch (taskViewModel.consentType) {
      case ConsentStatusEnum.CONSENT_ACCEPTED:
      case ConsentStatusEnum.CONSENT_ACCEPTED_MAN:
      case ConsentStatusEnum.CONSENT_ACCEPTED_WEB:
        return this.translateService.instant('board-task-table-consent-accepted');

      case ConsentStatusEnum.ORAL:
        return this.translateService.instant('board-task-table-consent-temporary-oral-consent-given');

      case ConsentStatusEnum.CONSENT_REJECTED:
        return this.translateService.instant('board-task-table-consent-rejected');

      case ConsentStatusEnum.CONSENT_NOT_POSSIBLE:
        return this.translateService.instant('board-task-table-consent-not-possible');

      case ConsentStatusEnum.REQUESTED:
      case ConsentStatusEnum.REQUESTED_MANUALLY_AGAIN:
      case ConsentStatusEnum.REQUESTED_MANUALLY:
        return this.translateService.instant('board-task-table-consent-requested');

      case ConsentStatusEnum.CONSENT_REQUEST_NOT_SENT:
      case ConsentStatusEnum.UNKNOWN:
        return this.translateService.instant('board-task-table-consent-request-not-sent');
    }

    return '';
  }

  get TaskModalModeEnum() {
    return TaskModalModeEnum;
  }

  get TaskType() {
    return TaskType;
  }

  get TaskStatus() {
    return TaskStatus;
  }

  get caseTypeCodes() {
    return CASE_TYPE_CODES;
  }

  get HandlingAreaStatusEnum() {
    return HandlingAreaStatusEnum;
  }

  get CommitmentTypes() {
    return CommitmentTypes;
  }

  get PatientType() {
    return PatientType;
  }

  calculateTimeZoneDifference(timezone: number) {
    if (timezone == null) {
      return null;
    }
    return timezone - new Date().getTimezoneOffset() / -60;
  }

  getBrandImagesUrlForProfile(profileId: string) {
    return this.caseService.getBrandImagesUrlForProfile(profileId);
  }

  caseClosedFadedColour(taskViewModel: TaskViewModel): boolean {
    return taskViewModel.teamStatus === HandlingAreaStatusEnum.CLOSED &&
          ( taskViewModel.caseTypeCode === CASE_TYPE_CODES.MEDICAL_PRE_ASSESSMENT ||
            taskViewModel.caseTypeCode === CASE_TYPE_CODES.ASSISTANCE_AND_CLAIMS ||
            taskViewModel.caseTypeCode === CASE_TYPE_CODES.ASSISTANCE_ONLY);
  }

  private openTaskModal(taskViewModel: TaskViewModel): void {
    this.modalRef = null;
    if (
      taskViewModel.taskType === TaskType.email ||
      taskViewModel.taskType === TaskType.fax ||
      taskViewModel.taskType === TaskType.sms ||
      taskViewModel.taskType === TaskType.mms
    ) {
      this.dialogViewerService.openTaskMessageDialog(taskViewModel, false, null, () => this.taskUpdated());
    } else if (taskViewModel.taskType === TaskType.consent) {
      this.dialogViewerService.openTaskConsentDialog(taskViewModel, false, () => this.taskUpdated());
    } else if (taskViewModel.taskType === TaskType.external || taskViewModel.taskType === TaskType.processGopRequest) {
      this.dialogViewerService.openTaskExternalDialog(taskViewModel, false, true);
    } else if (taskViewModel.taskType === TaskType.process) {
      this.dialogViewerService.openTaskDialog(taskViewModel, TaskModalModeEnum.partialViewOnly);
    } else {
      this.dialogViewerService.openTaskDialog(taskViewModel, TaskModalModeEnum.assignableOnly, false);
    }
  }

  taskUpdated() {
    this.updateTaskListAfterEdit.emit();
  }

  caseDetailsEscort(taskViewModel: TaskViewModel): string {
    const mortalList: boolean[] = [taskViewModel.caseDetailsDOC, taskViewModel.caseDetailsNUR, taskViewModel.caseDetailsNME];
    let returnValue: string;
    const mortalListNumbers = mortalList.filter(m => m === true).length;
    if (mortalListNumbers === 1) {
      if (taskViewModel.caseDetailsDOC) { returnValue = this.translateService.instant('board-task-table-case-details-DOC'); }
      if (taskViewModel.caseDetailsNUR) { returnValue = this.translateService.instant('board-task-table-case-details-NUR'); }
      if (taskViewModel.caseDetailsNME) { returnValue = this.translateService.instant('board-task-table-case-details-NME'); }
    } else {
      returnValue = mortalListNumbers.toString();
    }
    return returnValue;
  }

  protected removeNewlines(description: string): string {
    if (description !== undefined && description !== null && description.includes('\n')) {
      return description.replace(/\n/g, '');
    } else {
      return description;
    }
  }

  showMedicalServiceOrderType(taskViewModel: TaskViewModel): boolean {
    return  !taskViewModel.caseDetailsAIR &&
            !taskViewModel.caseDetailsSTR &&
            !taskViewModel.caseDetailsEXT &&
            !taskViewModel.caseDetailsSIT &&
            !taskViewModel.caseDetailsREG;
  }
}
