import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { DropdownWitIconDictionary } from '@secca/shared/models/dropdownWithIconDictionary';
import { ICCStakeholder } from '@secca/case/components/case-plans/case-plans/add-service-order/models/icc-stakeholder';
import { ICCStakeholders } from '../../models/icc-stakeholders';


@Component({
  selector: 'app-sub-icc-stakeholder',
  templateUrl: './sub-icc-stakeholder.component.html',
  styleUrls: ['./sub-icc-stakeholder.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SubIccStakeholderComponent {

  @Input() stakeholdersDropdown: DropdownWitIconDictionary[];
  @Input() iccStakeholders: ICCStakeholders;
  @Input() disabled: boolean;
  @Input() iccStakeholder: ICCStakeholder;
  @Input() caseStakeholders: CaseStakeholder[];
  @Input() deletable: boolean;
  @Output() delete: EventEmitter<ICCStakeholder> = new EventEmitter();
  @Output() update: EventEmitter<ICCStakeholder> = new EventEmitter();

  setSelectedStakeholder(selectedStakeholder: CaseStakeholder) {
    this.iccStakeholder.stakeholderId = selectedStakeholder.id;
    this.update.emit(this.iccStakeholder);
  }

  getNameInPassport(stakeholderId: string): boolean {
    const selectedIccStakeholder = this.caseStakeholders.find(a => a.id === stakeholderId);
    return selectedIccStakeholder.person?.nameInPassport;
  }

  deleteStakeholder() {
    if(this.deletable) {
      this.delete.emit(this.iccStakeholder);
    }
  }
}
