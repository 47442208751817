import { Injectable } from '@angular/core';
import { Adapter } from '@secca/shared/interfaces/adapter';
import { HandlingAreaStatusEnum } from '@secca/shared/models/enums';

export class HandlerPicture {
    showUserImage: boolean;
    userId: number;
    activeUser: boolean;
    handlerStatus: HandlingAreaStatusEnum;
    haveUserImage: boolean;

    public constructor(init?: Partial<HandlerPicture>) {
      Object.assign(this, init);
    }
}

@Injectable({
    providedIn: 'root'
})
export class HandlerPictureAdapter implements Adapter<HandlerPicture> {

    constructor(private handlerPictureAdapter: HandlerPictureAdapter) {}
    adapt(item: any): HandlerPicture {
      return new HandlerPicture({
        showUserImage: item.showUserImage,
        userId: item.userId,
        activeUser: item.activeUser,
        handlerStatus: item.handlerStatus,
        haveUserImage: item.haveUserImage,
      });
    }
}
