<div class="single-choice">
  <span class="single-choice-title" *ngIf="!!title">{{title}}</span>
  <div class="single-choice-item" *ngFor="let item of items">
    <div class="single-choice-item-checkbox" (click)="select(item)" (keyup.enter)="select(item)">
    <a class="blue-button" [ngClass]="{'blue-button-required' : valid, 'blue-button-on' : item.selected === true}"  tabindex="0"></a>
    <a class="blue-button" [ngClass]="{'blue-button-required' : valid, 'blue-button-dot' : item.selected === true}"></a>
    </div>
    <span [ngClass]="{'single-choice-item-label' : alternativePadding === false, 'single-choice-item-labelPreAssessment': alternativePadding === true}"
          class="single-choice-item-label" [innerHTML]="item.label"></span>
  </div>
</div>
