import { Component, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SalesOrderService } from '@secca/core/services/sales-order.service';
import { SalesOrder } from '@secca/shared/models/salesOrder';

@Component({
  selector: 'app-customer-invoice-note',
  templateUrl: './customer-invoice-note.component.html',
  styleUrls: ['./customer-invoice-note.component.scss'],
})
export class CustomerInvoiceNoteComponent {
  @ViewChild('content') private template: TemplateRef<any>;
  customerInvoiceNote: string;
  saveInProgress = false;
  salesOrder: SalesOrder;

  constructor(private salesOrderService: SalesOrderService, private modalService: NgbModal) {}

  show(salesOrder: SalesOrder) {
    this.salesOrder = salesOrder;
    this.customerInvoiceNote = salesOrder.customerInvoiceNote;
    this.modalService.open(this.template, { size: 'lg' as any });
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  clearCustomerInvoiceNote() {
    this.salesOrder.customerInvoiceNote = null;
    this.saveSalesOrder();
  }

  saveCustomerInvoiceNote() {
    this.salesOrder.customerInvoiceNote = this.customerInvoiceNote;
    this.saveSalesOrder();
  }

  private saveSalesOrder() {
    this.saveInProgress = true;
    this.salesOrderService.saveCustomerInvoiceNote(this.salesOrder).subscribe(so => {
      this.saveInProgress = false;
      this.closeModal();
    });
  }
}
