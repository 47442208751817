import * as moment from "moment/moment";
import { DateFormatter } from "@secca/shared/helpers/date-formatter";
import { Injectable } from "@angular/core";
import { Adapter } from "@secca/shared/interfaces/adapter";
import { DateSerializer } from "@secca/shared/models/date-serializer";

export class RecoveryPaymentTaskViewModel {
  id: number;
  caseId: number;
  isSelected: boolean;
  type: string;
  customerProfileId: number;
  receivedDate: moment.Moment;
  invoiceDate: moment.Moment;
  dueDate: moment.Moment;
  caseNumber: string;
  recoveryId: string;
  creditorId: string;
  creditorName: string;
  recoveryPaymentId: number;
  invoiceNumber: string;
  localAmount: string;
  localCurrency: string;
  recoveryPaymentStatus: string;
  assignedTo: string;

  public constructor(init?: Partial<RecoveryPaymentTaskViewModel>) {
    Object.assign(this, init);
  }

  get remaining() {
    const remainingDays = this.dueDate.diff(moment().startOf('day'), 'days');
    return DateFormatter.formatToNumberOfDays(remainingDays);
  }
}

@Injectable({
  providedIn: 'root',
})
export class RecoveryPaymentTaskViewModelAdapter implements Adapter<RecoveryPaymentTaskViewModel> {
  adapt(item: any): RecoveryPaymentTaskViewModel {
    return new RecoveryPaymentTaskViewModel({
      id: item.id,
      caseId: item.caseId,
      type: item.type,
      customerProfileId: item.customerProfileId == null ? null : +item.customerProfileId,
      receivedDate: item.receivedDate === '' ? null : DateSerializer.deserialize(item.receivedDate),
      invoiceDate: item.invoiceDate === '' ? null : DateSerializer.deserialize(item.invoiceDate),
      dueDate: item.dueDate === '' ? null : DateSerializer.deserialize(item.dueDate),
      creditorId: item.creditorId,
      creditorName: item.creditorName,
      caseNumber: item.caseNumber,
      recoveryId: item.recoveryId,
      recoveryPaymentId: item.recoveryPaymentId,
      invoiceNumber: item.invoiceNumber,
      localAmount: item.localAmount,
      localCurrency: item.localCurrency,
      recoveryPaymentStatus: item.recoveryPaymentStatus,
      assignedTo: item.assignedTo,
    });
  }
}
