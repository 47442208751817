import { EndUserStateService } from '@secca/core/state-services/enduser-state-service';
import { DropDownCountriesAutoComplete } from '@secca/shared/components/drop-down-countries-auto-complete/drop-down-countries-auto-complete.component';
import { Case } from '@secca/shared/models/case';
import { AutoUnsubscribe } from 'src/app/shared/decorators/auto-unsubscribe';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import {
  InputTypePattern,
  PermissionEnum,
  PermissionHideTypeEnum,
  PhoneNumberType, ShortcutEnum, StakeholderTypeEnum,
  ValidationTypeEnum
} from '@secca/shared/models/enums';
import { AutoCompleteTypeEnum } from 'src/app/shared/components/drop-down-countries-auto-complete/auto-complete-type-enum';
import { Subscription } from 'rxjs';
import { CaseStakeholder, CaseStakeholderAdapter } from '@secca/shared/models/caseStakeholder';
import { CaseService } from 'src/app/core/services/case.service';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import { Country, CountryDetails, CountryDetailsAdapter } from '@secca/shared/models/country';
import { DictionaryService } from '@secca/core/services/dictionary.service';
import { Component, EventEmitter, Input, Output, ViewChild, OnInit, ViewEncapsulation, ElementRef, OnDestroy } from '@angular/core';
import { NationalIdService } from '@secca/core/services/national-id.service';
import { CasePrimaryContactService } from '../../../case-primary-contact/case-primary-contact.service';
import { StakeholderService } from '@secca/core/services/stakeholder.service';
import { PhoneNumber } from '@secca/shared/models/phoneNumber';
import { Person } from '@secca/shared/models/person';
import { Address } from '@secca/shared/models/address';
import { SimpleCaseStakeholder, SimpleCaseStakeholderAdapter } from '@secca/shared/models/simpleCaseStakeholder';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import { DropdownAdapter } from '@secca/shared/models/dictionary';
import { PermissionService } from '@secca/core/services/permission.service';
import { PersonInsurance } from '@secca/shared/models/person-insurance';
import { ContactPerson } from '@secca/shared/models/contactPerson';
import { ShortcutService } from '@secca/core/services/shortcut.service';
import { CaseSelectedMenuEnum } from '@secca/shared/enums/case-selected-menu-enum.component';
import { MenuService } from '@secca/core/services/menu.service';
import { TranslateService } from '@ngx-translate/core';

@AutoUnsubscribe
@Component({
  selector: 'app-case-basics-info-user',
  templateUrl: './case-basics-info-user.component.html',
  styleUrls: ['./case-basics-info-user.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CaseBasicsInfoUserComponent implements OnInit, OnDestroy {
  @ViewChild('ssnLookupButton') ssnLookupButton: ElementRef;
  @ViewChild('homeCountry') homeCountry: DropDownCountriesAutoComplete;
  @Input() type: string;

  seccaCase: Case;
  $subscrGetCase;
  $subscrSsnLookupPossible;

  @Input()
  set caseId(newCaseId: string) {
    if (newCaseId !== undefined) {
      this._caseId = newCaseId;
      this.reloadData();
    }
  }

  get caseId(): string {
    return this._caseId;
  }
  private _caseId: string;


  @Input() set personInsurance(personInsurance: PersonInsurance) {
    this._personInsurance = personInsurance;
    if (this.personInsurance?.creditCard) {
      this.companyDisabledPopUpText = 'case-basics-info-user-creditcard-info-popup';
    } else if (this.personInsurance?.customerProduct && !this.caseStakeholder?.isCompany) {
      this.companyDisabledPopUpText = 'case-basics-info-user-customerProduct-info-popup';
    } else {
      this.companyDisabledPopUpText = null;
    }
  }
  get personInsurance() {
    return this._personInsurance;
  }
  private _personInsurance: PersonInsurance;

  @Input() caseStakeholder: CaseStakeholder;
  @Output() refresh: EventEmitter<string> = new EventEmitter();

  @Input() newCase: boolean;
  nonShowNationalId: string;
  theShownNationalId: string;
  emailStatusType: DropdownDictionary[] = [
    { id: true, name: 'Email' },
    { id: false, name: 'No' },
  ];
  countriesWithName: Country[];
  showAddress: boolean;
  genders: DropdownDictionary[];
  blockEmail: boolean;
  nationalIdUpdate = false;
  theShownMail: string;
  private caseStakeholderForOldValues: CaseStakeholder;
  disableCompanyToggleButton: boolean;
  subscription: Subscription;
  subscription2: Subscription;
  newOpenCard: boolean;
  maxDateOfBirth: Date;
  ssnValidationStatus = 'not-looked-up';
  private insuranceSubscription: Subscription;
  newContactPerson: ContactPerson;
  employeesDropdown: DropdownDictionary[];
  employeesList: SimpleCaseStakeholder[];
  employee: number;
  updateNationalIdValue = true;

  get AutoCompleteTypeEnum() {
    return AutoCompleteTypeEnum;
  }

  get InputTypePattern() {
    return InputTypePattern;
  }

  get ValidationTypeEnum() {
    return ValidationTypeEnum;
  }

  public stakeholderFax: PhoneNumber;
  public stakeholderFixed: PhoneNumber;
  public stakeholderMobile: PhoneNumber;

  nationalIdCountry: CountryDetails;
  oldNationalIdCountry: CountryDetails;
  caseStakeholderCountry: CountryDetails;
  companyDisabledPopUpText: string;

  private selectedMenuId: number;
  private shortcutSubscription: Subscription;

  constructor(
    private dictionaryService: DictionaryService,
    private countryAdapter: CountryDetailsAdapter,
    private caseService: CaseService,
    private nationalIdService: NationalIdService,
    private primaryContactService: CasePrimaryContactService,
    public caseStateService: CaseStateService,
    private stakeholderService: StakeholderService,
    private menuService: MenuService,
    private shortcutService: ShortcutService,
    private simpleStakeholderAdapter: SimpleCaseStakeholderAdapter,
    private caseLockHelperService: CaseLockHelperService,
    private permissionService: PermissionService,
    private endUserStateService: EndUserStateService,
    private dropdownAdapter: DropdownAdapter,
    private caseStakeholderAdapter: CaseStakeholderAdapter,
    private translateService: TranslateService,
  ) {
    this.$subscrGetCase = this.caseStateService.getCase().subscribe(result => (this.seccaCase = result));
    this.shortcutSubscription = this.shortcutService.addShortcut({ keys: ShortcutEnum.CaseBasicsInfoUserLookup }).subscribe(a => {
      this.selectedMenuId = this.menuService.getSelectedMenuId(parseInt(this.caseId, 10));
      if (this.selectedMenuId === CaseSelectedMenuEnum.CaseBasics) {
        if (!this.caseStakeholder.isCompany) {
          this.ssnLookupButton.nativeElement.focus();
        }
      }
    });
  }

  ngOnInit(): void {
    this.$subscrSsnLookupPossible = this.caseStateService.ssnLookupPossible.subscribe(result => {
      if (this.caseStakeholder?.person && +this.caseStakeholder.id === result.caseStakeholderId) {
        this.caseStakeholder.person.ssnLookupPossible = result.ssnLookupPossible;
      }
    });
  }

  ngOnDestroy(): void {
    this.shortcutSubscription.unsubscribe();
  }

  reloadData() {
    this.maxDateOfBirth = new Date();
    this.maxDateOfBirth.setDate(this.maxDateOfBirth.getDate() + 1);

    this.stakeholderFax = new PhoneNumber();
    this.stakeholderFixed = new PhoneNumber();
    this.stakeholderMobile = new PhoneNumber();
    this.nonShowNationalId = '';
    this.genders = this.dictionaryService.getGenders();
    this.caseStakeholder = new CaseStakeholder();
    this.caseStakeholder.person = new Person();
    this.caseStakeholder.address = new Address();

    if (!this.caseStakeholder.isCompany) {
      this.caseStakeholder.person.dateOfBirth = null;
    }

    this.caseService.getStakeholderForCase(this.caseId, this.type).subscribe(
      result => {
        this.newOpenCard = true;
        this.caseStakeholderForOldValues = JSON.parse(JSON.stringify(result));
        this.proceedWithStakeholder(result);
      },
      error => console.log(error)
    );

    if (this.newCase === true) {
      this.showAddress = this.newCase && !this.seccaCase.caseNumber;
      this.caseStakeholder = new CaseStakeholder();
      this.theShownNationalId = '';
      this.newCase = false;
    }
  }

  updateSimpleStakeholdersOnCase() {
    this.caseService.getSimpleStakeholdersOnCase(this.caseId).subscribe(
      simpleStakeholders => {
        this.employeesList = [];
        simpleStakeholders.forEach((item: SimpleCaseStakeholder) => {
          if (
              (
                (item.hasRole(StakeholderTypeEnum.person) || item.hasRole(StakeholderTypeEnum.endUser) ||
                  (item.hasRole(StakeholderTypeEnum.policyHolder) && !this.caseStakeholder.hasRole(StakeholderTypeEnum.policyHolder))  ||
                  (item.hasRole(StakeholderTypeEnum.reporter)  && !this.caseStakeholder.hasRole(StakeholderTypeEnum.reporter))
                ) &&
              !item.isCompany)
            ) {
              this.employeesList.push(item);
          }
        });
        this.seccaCase.simpleCaseStakeholders = simpleStakeholders;
      }
    );
  }

  showAddressClick() {
    this.showAddress = !this.showAddress;
  }

  focusInFunction() {
    if (this.caseStakeholder.person.nationalId !== '') {
      this.theShownNationalId = this.caseStakeholder.person.nationalId;
    }
  }

  focusOutFunction() {
    let countryDetails: CountryDetails = null;
    if (this.caseStakeholder.person.nationalIdCountry) {
      countryDetails = new CountryDetails({
        id: this.caseStakeholder.person.nationalIdCountry.id,
        code: this.caseStakeholder.person.nationalIdCountry.code,
        countryName: this.caseStakeholder.person.nationalIdCountry.name,
      });
    }
    this.saveNationalId(this.theShownNationalId, countryDetails);
    this.proceedWithStakeholder(this.caseStakeholder);
  }

  nationalIdInputFocusOut() {
    this.focusOutFunction();
  }

  showNationalIdClick() {
    if (this.theShownNationalId === this.nonShowNationalId) {
      this.theShownNationalId = this.caseStakeholder.person.nationalId;
    } else {
      this.theShownNationalId = this.nonShowNationalId;
    }
  }

  homeCountryChanged(country: CountryDetails) {
    interface PhoneNumber {
      phoneCountryCode: string;
      phonePrefix: string;
    }

    this.caseStakeholder.address.country.id = country ? country.id : null;
    this.caseStakeholder.address.country.code = country ? country.code : null;
    this.caseStakeholder.address.country.name = country ? country.countryName : null;
    if (country && this.caseStakeholder.phoneNumbers.length === 0) {
      const phoneNumber: PhoneNumber = { phoneCountryCode: country.code, phonePrefix: country.phonePrefix };
      this.caseStakeholder.phoneNumbers = this.stakeholderService.setPhonenumber(
        PhoneNumberType.Mobile,
        phoneNumber,
        this.caseStakeholder.phoneNumbers
      );
      this.caseStakeholder.phoneNumbers = this.stakeholderService.setPhonenumber(
        PhoneNumberType.Fixed,
        phoneNumber,
        this.caseStakeholder.phoneNumbers
      );
    }
    this.updateStakeholder();
  }

  homeCountryRemoved() {
    this.stakeholderService.removeHomeCountry(this.caseStakeholder.id)
      .subscribe(
        result => this.handleUpdatedStakeholder(result)
      );
  }

  saveEmail(value: string): void {
    if (this.caseStakeholder.company) {
      this.caseStakeholder.company.email = this.theShownMail;
    } else {
      this.caseStakeholder.person.email = this.theShownMail;
    }

    this.saveModel();
  }

  saveName() {
    if (this.caseStakeholder.person.nationalIdCountry.code === 'NO') {
      this.caseStakeholder.person.ssnLookupPerformed = false;
      this.caseStakeholder.person.ssnValidated = false;
      this.caseStakeholder.person.ssnLookupError = null;
    }
    this.updateStakeholder();
  }

  saveModel() {
    this.updateStakeholder();
  }

  saveMobilePhone(event) {
    this.caseStakeholder.phoneNumbers = this.stakeholderService.setPhonenumber(
      PhoneNumberType.Mobile,
      event,
      this.caseStakeholder.phoneNumbers
    );
    this.updateStakeholder();
  }

  saveFixedPhone(event) {
    this.caseStakeholder.phoneNumbers = this.stakeholderService.setPhonenumber(
      PhoneNumberType.Fixed,
      event,
      this.caseStakeholder.phoneNumbers
    );
    this.updateStakeholder();
  }

  saveFax(event) {
    this.caseStakeholder.phoneNumbers = this.stakeholderService.setPhonenumber(
      PhoneNumberType.Fax,
      event,
      this.caseStakeholder.phoneNumbers
    );
    this.updateStakeholder();
  }

  canSwitchToCompany() {
    return !this.caseStakeholder.hasRole(StakeholderTypeEnum.endUser) &&
      this.canSwitchToCompanyOnPolicyHolderOrReporter();
  }

  canSwitchToCompanyOnPolicyHolderOrReporter(): boolean {
    return !(this.caseStakeholder.hasRole(StakeholderTypeEnum.policyHolder) && (this.personInsurance.creditCard || this.personInsurance.customerProduct));
  }

  saveNationalIdCountry(nationalIdValue: string, country: CountryDetails) {
    this.updateNationalIdValue = false;
    let countryDetails: CountryDetails = null;
    if (this.caseStakeholder.person.nationalIdCountry) {
        countryDetails = new CountryDetails({
        id: this.caseStakeholder.person.nationalIdCountry.id,
        code: this.caseStakeholder.person.nationalIdCountry.code,
        countryName: this.caseStakeholder.person.nationalIdCountry.name,
      });
    }
    this.saveNationalId(nationalIdValue, country);
  }

  saveNationalId(nationalIdValue: string, country: CountryDetails) {
    nationalIdValue = nationalIdValue?.toUpperCase();
    if (nationalIdValue !== this.nonShowNationalId && nationalIdValue !== this.caseStakeholder.person.nationalId) {
      if (nationalIdValue) {
        if (this.caseStakeholder.person && this.updateNationalIdValue) {
          if (this.caseStakeholder.person.nationalIdValid) {
            this.nonShowNationalId = this.nationalIdService.getMaskedId(
              this.caseStakeholder.person.nationalIdCountry.code,
              this.caseStakeholder.person.nationalId
            );
            this.setMaskId();
          } else {
            this.theShownNationalId = this.caseStakeholder.person.nationalId;
            this.nonShowNationalId = this.caseStakeholder.person.nationalId;
          }
        }
        this.caseStakeholder.person.ssnLookupPerformed = false;
        this.caseStakeholder.person.ssnValidated = false;
        this.caseStakeholder.person.gender = null;
        this.caseStakeholder.person.nationalId = nationalIdValue;
        this.caseStakeholder.person.ssnLookupError = null;
      }
    }

    if (nationalIdValue === '') {
      this.stakeholderService.removeDateOfBirth(this.caseStakeholder.id).subscribe(result => {
        this.caseStakeholder.person.dateOfBirth = result.person.dateOfBirth;
        this.caseStakeholder.person.nationalId = '';
        if (this.updateNationalIdValue) {
          this.theShownNationalId = '';
          this.nonShowNationalId = '';
        }
        this.caseStakeholder.person.ssnLookupPerformed = null;
        this.caseStakeholder.person.ssnValidated = null;
        this.updateSsnValidationStatus();
        this.updateDateOfBirthOrAgeOrGenderOrNationalId();
        this.proceedWithStakeholder(this.caseStakeholder);
      });
    }

    if (country) {
      if (this.caseStakeholder.person.nationalIdCountry.code !== country.code) {
        this.caseStakeholder.person.nationalIdCountry = country.toCountry();
      }
    } else if (!this.caseStakeholder.person.nationalIdCountry) {
      this.caseStakeholder.person.nationalIdCountry = new Country();
    }

    if (nationalIdValue !== '') {
      this.updateDateOfBirthOrAgeOrGenderOrNationalId();
      this.proceedWithStakeholder(this.caseStakeholder);
    }

  }

  private nationalIdCountryRemoved() {
    this.stakeholderService.removeNationalIdCountry(this.caseStakeholder.id)
      .subscribe(
        result => this.handleUpdatedStakeholder(result)
      );
  }

  allowEdit() {
    return this.type.toUpperCase() === this.caseStakeholder.stakeholderType &&
           !this.caseLockHelperService.isCaseBasicLockTaken &&
           !this.permissionService.isReadOnly;
  }
  disableIfEnduserIsPolicyholder() {
    return this.type === 'policyholder' && this.caseStakeholder.enduserIsPolicyholder === true;
  }

  disablePolicyholderReporterCheckbox() {
    return this.caseStakeholder.enduserIsReporter || (!this.caseStakeholder.policyholderIsReporter && !this.similarTo('REPORTER'));
  }

  disableEnduserCheckbox(stakeholderType: string) {
    return this.caseStakeholder.company || !this.similarTo(stakeholderType);
  }

  disableReporterCheckbox(stakeholderType: string) {
    return this.caseStakeholder.policyholderIsReporter || (!this.caseStakeholder.enduserIsReporter && !this.similarTo(stakeholderType));
  }

  changeCompanyPersonStatusOnStakeholder() {
    if (this.caseStakeholder.isCompany) {
      this.caseService.UpdateStakeholderToPerson(this.caseStakeholder.id).subscribe(result => {
        this.proceedWithStakeholder(result);
      });
    } else {
      this.caseService.UpdateStakeholderToCompany(this.caseStakeholder.id).subscribe(result => {
        this.proceedWithStakeholder(result);
      });
    }
  }

  updateStakeholder() {
    this.caseService.updateStakeholderForCase(this.caseStakeholder);
    this.handleUpdatedStakeholder(this.caseStakeholder);
  }

  private handleUpdatedStakeholder(result: CaseStakeholder) {
    this.caseStateService.sendCase(this.seccaCase);
    if (this.caseStakeholder.hasRole(StakeholderTypeEnum.endUser)) {
      this.endUserStateService.sendEndUser(this.caseStakeholder);
    }

    this.proceedWithStakeholder(result);
    this.primaryContactService.updatePrimary(this.type, this.caseStakeholder);
  }

  updateDateOfBirthOrAgeOrGenderOrNationalId() {
    this.caseService.updateDateOfBirthOrAgeOrGenderOrNationalId(this.caseStakeholder).subscribe(
            result => {
        this.caseStateService.updatedStakeholder(this.caseStakeholder);
        this.caseStakeholderAdapter.adaptToExisting(this.caseStakeholder, result, this.updateNationalIdValue);
        if (this.caseStakeholder.hasRole(StakeholderTypeEnum.endUser)) {
          this.endUserStateService.sendEndUser(this.caseStakeholder);
        }
      }
    );
  }

  updateDateOfBirth($event) {
    this.caseStakeholder.person.dateOfBirth = $event;
    if ($event) {
      this.updateDateOfBirthOrAgeOrGenderOrNationalId();
    } else {
      this.stakeholderService.removeDateOfBirth(this.caseStakeholder.id).subscribe(result => this.handleUpdatedStakeholder(result));
    }
  }

  updateDateOfDeath($event) {
    this.caseStakeholder.person.dateOfDeath = $event;
    if ($event) {
      this.saveModel();
    } else {
      this.stakeholderService.removeDateOfDeath(this.caseStakeholder.id).subscribe(result => this.handleUpdatedStakeholder(result));
    }
  }

  similarTo(stakeholderType: string): boolean {
    if (this.caseStakeholder.similarStakeholders == null) {
      return true;
    } else {
      return this.caseStakeholder.similarStakeholders.includes(stakeholderType);
    }
  }

  private setMaskId() {
    if (this.newOpenCard) {
      this.theShownNationalId = this.nonShowNationalId;
      this.newOpenCard = false;
    }
  }

  changeEmployee(event) {
    this.caseStakeholder.company.employee = +event;
    this.updateStakeholder();
  }

  private proceedWithStakeholder(caseStakeholder: CaseStakeholder) {
    this.caseStakeholder = caseStakeholder;
    this.updateSimpleStakeholdersOnCase();
    this.caseStakeholderForOldValues = JSON.parse(JSON.stringify(this.caseStakeholder));
    if (
      (this.type === 'POLICYHOLDER' && this.caseStakeholder.enduserIsPolicyholder) ||
      (this.type === 'REPORTER' && this.caseStakeholder.enduserIsReporter)
    ) {
      this.disableCompanyToggleButton = true;
    }

    if (this.caseStakeholder?.company?.employee) {
      this.employee = +this.caseStakeholder.company.employee;
    }

    if (this.caseStakeholder.person) {
      if (this.caseStakeholder.person.nationalIdCountry === null) {
        this.caseStakeholder.person.nationalIdCountry = new Country();
        this.caseStakeholder.person.nationalIdCountry.code = null;
        this.caseStakeholder.person.nationalIdCountry.name = '';
        this.nationalIdCountry = new CountryDetails();
      } else {
        this.nationalIdCountry = this.countryAdapter.adaptFromCountry(this.caseStakeholder.person.nationalIdCountry);
        this.oldNationalIdCountry = JSON.parse(JSON.stringify(this.nationalIdCountry));
      }
    } else {
      if (!this.caseStakeholder?.company?.contactPersons[0]) {
        this.caseStakeholder.company.contactPersons.push(new ContactPerson({caseId: this.caseStakeholder.caseId}));
      }
    }

    if (this.caseStakeholder.address.country === null) {
      this.caseStakeholder.address.country = new Country();
      this.caseStakeholder.address.country.code = null;
      this.caseStakeholder.address.country.name = '';
      this.caseStakeholderCountry = new CountryDetails();
    } else {
      this.caseStakeholderCountry = this.countryAdapter.adaptFromCountry(this.caseStakeholder.address.country);
    }

    this.updateSsnValidationStatus();
    this.caseService.publishStakeholder(caseStakeholder);
    this.stakeholderFixed = this.stakeholderService.getPhoneNumber(PhoneNumberType.Fixed, caseStakeholder.phoneNumbers);
    this.stakeholderMobile = this.stakeholderService.getPhoneNumber(PhoneNumberType.Mobile, caseStakeholder.phoneNumbers);
    this.stakeholderFax = this.stakeholderService.getPhoneNumber(PhoneNumberType.Fax, caseStakeholder.phoneNumbers);

    if (caseStakeholder.person) {
      if (this.caseStakeholder.person.nationalIdValid) {
        this.nonShowNationalId = this.nationalIdService.getMaskedId(
          this.caseStakeholder.person.nationalIdCountry.code,
          this.caseStakeholder.person.nationalId
          );
        if (this.updateNationalIdValue) {
          this.setMaskId();
        }
      } else {
        if (this.updateNationalIdValue) {
          this.theShownNationalId = this.caseStakeholder.person.nationalId;
          this.nonShowNationalId = this.caseStakeholder.person.nationalId;
        }
      }

      if (!this.caseStakeholder.person.hasEmail) {
        this.blockEmail = true;
        this.theShownMail = '';
      } else {
        this.blockEmail = false;
        this.theShownMail = this.caseStakeholder.person.email;
      }
    }

    if (caseStakeholder.company) {
        this.blockEmail = false;
        this.theShownMail = this.caseStakeholder.company.email;
    }
    if (this.caseStakeholder.address.country && this.homeCountry) {
      if (this.caseStakeholder.address.country.name) {
        this.homeCountry.countryName = this.caseStakeholder.address.country.name;
      } else {
        this.homeCountry.countryName = '';
      }
    }

    if (this.caseStakeholder.primaryContact) {
      this.primaryContactService.updatePrimary(caseStakeholder.stakeholderType, caseStakeholder);
    }
  }

  updateSsnValidationStatus() {
    if (!this.caseStakeholder.isCompany) {
      if (this.caseStakeholder.person.ssnValidated) {
        this.ssnValidationStatus = 'success';
      } else if (this.caseStakeholder.person.ssnLookupPerformed) {
        this.ssnValidationStatus = 'failure';
      } else {
        this.ssnValidationStatus = 'not-looked-up';
      }
    }
  }

  doSsnLookup() {
    this.ssnValidationStatus = 'searching';
    this.caseService.doSsnLookup(this.caseStakeholder.id).subscribe(
      result => {
        this.caseStakeholder = result;
        this.updateSsnValidationStatus();
        this.proceedWithStakeholder(this.caseStakeholder);
        this.updateStakeholder();
        this.caseService.publishStakeholder(result);
      },
      error => console.error(error)
    );
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  get PermissionHideTypeEnum() {
    return PermissionHideTypeEnum;
  }

  updateContactPerson() {
    this.caseStakeholder.company.contactPersons[0].caseId = +this.caseId;
    if (this.caseStakeholder.company.contactPersons[0]?.id) {
      this.stakeholderService.updateContactPersons(this.caseStakeholder.company.contactPersons[0].id, this.caseStakeholder.company.contactPersons[0]).subscribe(
        result => { }
      );
    } else {
      this.stakeholderService.addContactPerson(this.caseStakeholder.company.contactPersons[0], this.caseStakeholder.id).subscribe(
        result => {
          this.caseStakeholder.company.contactPersons[0] = result;
        }
      );
    }
  }

  saveModelContactPersonPhone(phonenumber) {
    this.caseStakeholder.company.contactPersons[0].phoneNumbers[0].number = phonenumber.phoneNumber;
    this.caseStakeholder.company.contactPersons[0].phoneNumbers[0].countryCode = phonenumber.phoneCountryCode;
    this.caseStakeholder.company.contactPersons[0].phoneNumbers[0].prefix = phonenumber.phonePrefix;
    this.updateContactPerson();
  }

  lookupNotPossibleText() {
    const countrySupported = ['DK', 'SE', 'NO', 'FI', 'IS'].some(value => this.caseStakeholder.person.nationalIdCountry.code === value);
    if (!countrySupported) {
      return 'case-basics-info-user-ssn-lookup-country-not-supported-popup';
    } else if (!this.caseStakeholder.person.nationalIdValid) {
      return 'case-basics-info-user-ssn-incorrect-popup';
    } else {
      return 'case-basics-info-user-ssn-lookup-not-possible-popup';
    }
  }

  getErrorText() {
    if (!!this.caseStakeholder.person.ssnLookupError) {
      return this.caseStakeholder.person.ssnLookupError;
    } else if (this.caseStakeholder.person.ssnLookupPerformed && !this.caseStakeholder.person.ssnValidated) {
      return this.translateService.instant('case-basics-info-user-ssn-lookup-no-match-found');
    } else {
      return undefined;
    }
  }
}

