import {Injectable} from '@angular/core';
import {Adapter} from '../interfaces/adapter';

export class CustomerNameHolder {

  profileName: string;

  public constructor(init?: Partial<CustomerNameHolder>) {
    Object.assign(this, init);
  }
}

export class CustomerProfileCodes {
  static readonly SOS_TRAVELCARE_DK_CUSTOMER_PROFILE_CODE = 'CP900';
  static readonly SOS_TRAVELCARE_SE_CUSTOMER_PROFILE_CODE = 'CP903';
  static readonly SOS_TRAVELCARE_NO_CUSTOMER_PROFILE_CODE = 'CP904';
  static readonly SOS_TRAVELCARE_FI_CUSTOMER_PROFILE_CODE = 'CP905';
}

export class CustomerProfile {
  commonCustomerName: string = null;
  brandName: string = null;
  brandId: string = null;
  profileCode: string = null;
  profileName: string = null;
  profileId: string = null;
  teamName: string = null;
  adtCode: string = null;
  groupProfile: string = null;
  isGroupProfile: boolean;
  noInsuranceWithThisCustomerGroup = false;

  public constructor(init?: Partial<CustomerProfile>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class CustomerNameAdapter implements Adapter<CustomerNameHolder> {
  adapt(item: any): CustomerNameHolder {
    return new CustomerNameHolder({
      profileName: item.name
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class CustomerProfilesAdapter implements Adapter<CustomerProfile> {
  adapt(item: any): CustomerProfile {
    return new CustomerProfile({
      commonCustomerName: item.commonCustomerName,
      brandName: item.brandName,
      brandId: item.brandId,
      profileName: item.profileName,
      profileId: item.profileId,
      profileCode: item.profileCode,
      teamName: item.teamName,
      adtCode: item.adtCode,
      groupProfile: item.groupProfile,
      isGroupProfile: item.isGroupProfile
    });
  }
}
