import { ProfileCountry, ProfileCountryAdapter } from './ProfileCountry';
import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';

export class ProfileCountryList {
  id: number;
  name: string;
  profileId: number;
  countries: ProfileCountry[];
  public constructor(init?: Partial<ProfileCountryList>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProfileCountryListAdapter implements Adapter<ProfileCountryList> {
  constructor(private profileCountryAdapter: ProfileCountryAdapter) {}

  adapt(item: any): ProfileCountryList {
    return new ProfileCountryList({
      id: item.id,
      name: item.name,
      profileId: item.profileId,
      countries: item.countries.map(a => this.profileCountryAdapter.adapt(a))
    });
  }
}
