import {CoordinationTypeEnum} from '@secca/shared/models/enums';
import { Location } from './location';
import moment from 'moment';
import {Injectable} from "@angular/core";
import {Adapter} from "@secca/shared/interfaces/adapter";
import {DaySerializer} from "@secca/shared/models/day-serializer";

export class CoordinationDetails {
  name: string;
  type: CoordinationTypeEnum;
  expectedDate: moment.Moment;
  destination: Location = new Location();
  remarks: string;

  public constructor(init?: Partial<CoordinationDetails>) {
    Object.assign(this, init);
  }

}

@Injectable({
  providedIn: 'root'
})
export class CoordinationDetailsAdapter implements Adapter<CoordinationDetails> {
  adapt(item: any): CoordinationDetails {
    return new CoordinationDetails({
      name: item.name,
      type: item.type,
      expectedDate: item.expectedDate ? DaySerializer.deserialize(item.expectedDate) : null,
      destination: item.destination || new Location(),
      remarks: item.remarks,
    });
  }
}
