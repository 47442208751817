<div class="modal-content">
  <div class="modal-header">
    <div class="modal-header-title">
      {{ taskViewModel.title }}
    </div>
    <div class="modal-buttons">
      <div (click)="closeTaskMessageDialog()">
        <img src="/assets/icons/Close White.svg" alt="Close icon"/>
      </div>
    </div>
  </div>
  <app-case-lock-overlay [lockContext]="LockContextEnum.CASE_BASIC" [asOverlay]="false"
    [unlockPostAction]="CaseLockOverlayAction.TAKE_LOCK"></app-case-lock-overlay>
  <div class="modal-content-body">
    <div class="row m-0">
      <div class="col-3 task-details p-0">
        <app-task-message-left-panel [taskCase]="taskViewModel.caseId" [taskId]="taskViewModel.id"
          (modalTaskLoadedEvent)="taskLoadedNotification($event)" (stateChanged)="checkIfThereWereChanges()" #taskModal
          *ngIf="taskViewModel.taskType !== TaskType.suplierInvoice">
        </app-task-message-left-panel>
        <app-task-supplier-invoice-left-panel [taskCase]="taskViewModel.caseId" [taskId]="taskViewModel.id"
                                              (stateChanged)="checkIfThereWereChanges()"
          (modalTaskLoadedEvent)="taskLoadedNotification($event)" #taskModal
          *ngIf="taskViewModel.taskType === TaskType.suplierInvoice">
        </app-task-supplier-invoice-left-panel>

        <div *ngIf="caseEmail && taskViewModel?.taskType !== TaskType.processGopRequest">
          <div *permission="PermissionEnum.TASK_UPDATE">
            <div class="action-button">
              <app-drop-menu [label]="'Actions'">
                <ng-template #optionTemplate>
                  <div (click)="openMoveTaskModal()">{{'task-message-modal-dropdown-message-related-to-other-case' | translate}}</div>
                </ng-template>
                <ng-template #optionTemplate>
                  <div (click)="openMessageModal(false, false, true)">{{'task-message-modal-dropdown-forward-message-to-other-application' | translate}}</div>
                </ng-template>
                <ng-template #optionTemplate>
                  <div (click)="openModal()">{{ 'task-message-modal-dropdown-message-is-not-gdpr-compliant' | translate }}</div>
                </ng-template>
                <ng-template #optionTemplate>
                  <div (click)="openMessageModal(true, true, false)">{{'task-message-modal-dropdown-reply-no-need-for-stakeholder' | translate}}</div>
                </ng-template>
              </app-drop-menu>
            </div>
          </div>
        </div>

        <div *ngIf="taskViewModel.taskType === TaskType.suplierInvoice" [appDisableInput]="isCaseLocked" >
            <div *permission="PermissionEnum.SUPPLIER_INVOICE_CREATE">
              <div class="action-button">
                <app-drop-menu [label]="'Actions'">
                  <ng-template #optionTemplate>
                    <div (click)="openMoveSupplierInvoiceModal()">{{ 'task-message-modal-dropdown-move-supplier-invoice-to-other-case' | translate }}</div>
                  </ng-template>
                </app-drop-menu>
            </div>
          </div>
        </div>
      </div>
      <div class="col-9 message-details" *ngIf="caseEmail">
<!-- The keyboard property on ngbNav does not work in Safari -->
        <ul ngbNav #nav="ngbNav" class="nav-tabs" [destroyOnHide]="false" [keyboard]="true">
           <li ngbNavItem="1">
            <a ngbNavLink>{{ 'task-message-modal-message' | translate }}</a>
            <ng-template ngbNavContent>
              <div class="message-header">
                <button *ngIf="!isOnCasePage && hasCaseNumber" class="primary-button placement-right" (click)="goToCase()">{{'task-message-modal-go-to-case' | translate }}</button>
                <button *ngIf="!isOnCasePage && !hasCaseNumber" class="primary-button placement-right" (click)="goToSearchCase()"><span class="shortcut-key">S</span>earch or create case<br><small>(Ctrl + Shift + S)</small></button>
                <div class="message-header-subject" *ngIf="getCurrentTaskOriginType() === TaskOriginType.EMAIL">
                  {{ caseEmail.subject }}
                </div>
                <div class="message-header-label">{{ 'task-message-modal-from' | translate }}</div>
                <div class="message-header-text">{{ caseEmail.from }}</div>
                <div class="message-header-label">{{ 'task-message-modal-to' | translate }}</div>
                <div class="message-header-text">{{ caseEmail.to }}</div>
                <div *ngIf="caseEmail.ccRecipientEmails">
                  <div class="message-header-label">{{ 'task-message-modal-cc' | translate }}</div>
                  <div class="message-header-text">{{ caseEmail.ccRecipientEmails }}</div>
                </div>
                <div class="message-header-label">{{ 'task-message-modal-date' | translate }}</div>
                <div class="message-header-text">{{ task.receivedDate | momentFormat: 'DD MMM YYYY, HH:mm':'local' }}
                </div>
              </div>
              <div *ngIf="emailBody !== null && isHtmlBody" class="message-content" [innerHTML]="emailBody | htmlAttributeModifier: 'a': 'tabindex': '-1' | safeHtml: null" tabindex="0"></div>
              <div *ngIf="emailBody !== null && !isHtmlBody" class="message-content message-content-not-html" [innerText]="emailBody" tabindex="0"></div>
              <div *ngIf="emailBody === null" class="message-content">
                {{ 'task-message-modal-loading' | translate }}
              </div>
              <div>
                <div class="message-stakeholder">
                  <app-message-form-receiver [showFullName]="true"
                    [name]="'task-message-modal-message-is-from' | translate" [stakeholders]="stakeholders"
                    [(selectedItemId)]="selectedStakeholder" (selectedItemIdChange)="checkIfThereWereChanges()"></app-message-form-receiver>
                </div>
                <div class="message-internal-summary">
                  <label>{{ 'task-message-modal-internal-summary' | translate }}</label>
                  <div class="position-relative">
                    <textarea maxlength="8000" [(ngModel)]="caseEmail.internalSummary" appCharCount='top-right' (input)="checkIfThereWereChanges()"></textarea>
                  </div>
                </div>
              </div>
            </ng-template>
           </li>
           <li ngbNavItem="2">
            <a ngbNavLink>{{ 'task-message-modal-attachments' | translate }} ({{ caseEmail?.attachments?.length }})</a>
            <ng-template ngbNavContent>
              <div class="message-attachments-container">
                <div *ngFor="let attachment of caseEmail.attachments">
                  <app-task-message-attachment [attachment]="attachment" [caseId]="taskViewModel.caseId">
                  </app-task-message-attachment>
                </div>
              </div>
            </ng-template>
           </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
      </div>
      <div class="col-9 message-details" *ngIf="taskViewModel.taskType === TaskType.suplierInvoice">
        <app-task-message-attachment [attachment]="supplierInvoiceInfo" [caseId]="taskViewModel.caseId">
        </app-task-message-attachment>
      </div>
    </div>
    <div class="horizontal-line"></div>
    <div class="row">
      <div class="col-12">
        <div>
          <button class="primary-button save-button" [disabled]="isDisabled()" (click)="saveTaskChanged()"
            [class.primary-button-inactive]="isDisabled()">
            {{ 'task-message-modal-save' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
