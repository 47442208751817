import {Adapter} from '@secca/shared/interfaces/adapter';
import {CaseDocument} from '@secca/shared/models/caseDocument';
import {Injectable} from '@angular/core';

export class FileScan {

  // id: number;
  hit: boolean;
  scanned: boolean
  result: string;

  public constructor(init?: Partial<FileScan>) {
    Object.assign(this, init);
  }
}
@Injectable({
  providedIn: 'root',
})
export class FileScanAdapter implements Adapter<FileScan> {
  adapt(item: any): FileScan {
    if (item == null) {
      return null;
    }
    return new FileScan({
      hit: item.hit,
      scanned: item.scanned,
      result: item.result
    });
  }
}
