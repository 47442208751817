export interface SeccaSearchParams {
  freeText?: string;
  location?: Map<string, number>;
  distance?: number;
  serviceTypes?: string[];
  supplierServices?: string[];
  competences?: string[];
  supplierType?: string[];
  recommendations?: string[];
  countries?: string[];
  distanceWeight?: number;
  rows?: number;
}

export enum SeccaSearchParamString {
  freeText = 'freeText',
  location = 'location',
  distance = 'distance',
  serviceTypes = 'serviceTypes',
  supplierServices = 'supplierServices',
  competences = 'competences',
  supplierType = 'supplierType',
  recommendations = 'recommendations',
  countries = 'countries',
  distanceWeight = 'distanceWeight'
}
