import { Injectable } from '@angular/core';
import { DateHelperService } from '@secca/core/services/date-helper.service';
import * as moment from 'moment';
import { Adapter } from '../interfaces/adapter';

export class RecentCases {
    caseId: number;
    caseNumber: string;
    logo: string;
    user: string;
    cause: string;
    incidentDate: string;
    country: string;
    exitTime: moment.Moment;

    public constructor(init?: Partial<RecentCases>) {
      Object.assign(this, init);
    }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class RecentCasesAdapter implements Adapter<RecentCases> {

    constructor(private dateHelperService: DateHelperService) {
    }

    adapt(item: any): RecentCases {
      return item ? new RecentCases({
        caseId: item.id,
        caseNumber: item.caseNumber,
        country: item.incidentCountry,
        cause: item.incidentCause,
        user: item.endUserFullName,
        incidentDate: item.incidentDate
      }) : new RecentCases();
    }
  }

