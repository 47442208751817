<div>
  <div class="pb-3" *ngFor="let iccStakeholder of endUserCaseICCStakeholders; let i = index">
    <div class="d-flex flex-container-margin">
      <div class="headline" [ngClass]="{'expected-stakeholder': !showPassengerConfirmed, 'confirmed-stakeholder' : showPassengerConfirmed}">Stakeholder</div>
      <div class="headline" [ngClass]="{'expected-service-order': !showPassengerConfirmed, 'confirmed-service-order' : showPassengerConfirmed}">Service<span *ngIf="iccStakeholder.serviceOrderStatus === StatusTypes.CANCELLED" class="service-order-cancelled"> - {{"coordination-linked-service-order-cancelled-by-icc-service-order" | translate}}</span></div>
      <div class="headline passenger-confirmed d-flex justify-content-center" *ngIf="showPassengerConfirmed">{{"case-basics-coordination-case-passenger-confirmed" | translate}}</div>
    </div>
    <div class="d-flex flex-container-margin" >
      <div [ngClass]="{'expected-stakeholder': !showPassengerConfirmed, 'confirmed-stakeholder' : showPassengerConfirmed}">
        <app-sub-icc-stakeholder
          [iccStakeholder]='iccStakeholder'
          [stakeholdersDropdown]="stakeholdersDropdown"
          [caseStakeholders]="caseStakeholders"
          [iccStakeholders]="flightStakeholders"
          (delete)="deleteStakeholder($event)"
          (update)="updateStakeHolder($event)"
          [disabled]="disabled || (savedFlightStakeholders ? hasEnduserBeenSavedOnServiceorder(iccStakeholder) : false) || i === 0"
          [deletable]="endUserCaseICCStakeholders.length > 1 && i !== 0"
          >
        </app-sub-icc-stakeholder>
      </div>
      <div [ngClass]="{'expected-service-order': !showPassengerConfirmed, 'confirmed-service-order' : showPassengerConfirmed}">
        <div class="d-flex">
          <div class="flex-fill">
            <app-sub-service-order
              [selectedItemId]='iccStakeholder.serviceOrderId'
              (selectedItemIdChange)="serviceOrderChange()"
              [shouldDisableSelectionOnInvalidServiceOrders]="false"
              [serviceOrders]="serviceOrdersList"
              [iccStakeholder]='iccStakeholder'
              [fullServiceOrderDropdown]="fullServiceOrdersDropdown"
              [serviceOrderDropDown]='serviceOrdersDropdown'
              [disabled]="disabled || (savedFlightStakeholders ? hasEnduserBeenSavedOnServiceorder(iccStakeholder) : false)">
            </app-sub-service-order>
          </div>
          <div class="delete-container w-100 ms-2" *ngIf="showDeleteButton" [ngClass]="{ 'delete-container-deletable': i !== 0 }" (click)="deleteStakeholder(iccStakeholder, i > 0)" >
            <div class="d-flex justify-content-center align-items-center h-100" >
              <img src="../../../../assets/icons/garbage.svg" />
            </div>
          </div>
        </div>
      </div>
      <div class="passenger-confirmed d-flex justify-content-center" *ngIf="showPassengerConfirmed">
        <div class="d-flex ps-2 pt-1">
          <label class="custom-radio-button text-center ms-1">
            <div class="single-choice-item-checkbox"  (click)="passengerConfirmedClicked(true, iccStakeholder.stakeholderId)">
              <a class="button" [ngClass]="{'button-required' : iccStakeholder.passengerConfirmed == undefined, 'button-on-green' : iccStakeholder.passengerConfirmed}"  tabindex="0"></a>
              <a class="button" [ngClass]="{'button-required' : iccStakeholder.passengerConfirmed == undefined, 'button-dot-green' : iccStakeholder.passengerConfirmed}"></a>
            </div>
            <div class="radio-button-text">{{ 'case-basics-coordination-case-relevant-for-case-yes' | translate }}</div>
          </label>
          <label class="custom-radio-button text-center ms-1">
            <div class="single-choice-item-checkbox"  (click)="passengerConfirmedClicked(false, iccStakeholder.stakeholderId)" >
              <a class="button" [ngClass]="{'button-on-red' : iccStakeholder.passengerConfirmed == false, 'button-required' : iccStakeholder.passengerConfirmed  == undefined }"  tabindex="0"></a>
              <a class="button" [ngClass]="{'button-dot-red' : iccStakeholder.passengerConfirmed  == false, 'button-required' : iccStakeholder.passengerConfirmed  == undefined }"></a>
            </div>
            <div class="radio-button-text">{{ 'case-basics-coordination-case-relevant-for-case-no' | translate }}</div>
          </label>
        </div>
      </div>
    </div>
  </div>
    <a class="add-stakeholder pt-3" (click)="addStakeholder()"
       *ngIf="!disabled && !serviceOrdersCancelled">{{"sub-stakeholders-add-icc-stakeholder" | translate}} {{caseNumber}}</a>
  </div>