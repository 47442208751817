import { CaseStakeholder } from '../../shared/models/caseStakeholder';
import { PersonInsurance } from '../../shared/models/person-insurance';
import { CaseIncident } from '../../shared/models/caseIncident';
import { PlannedTravel } from 'src/app/shared/models/planned-travel';
import { OtherInsurance } from 'src/app/shared/models/otherInsurance';
import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QueueService {
  _stakeholderStore: CaseStakeholder[] = [];
  areStakeholderQueuedOperationsRunning = false;
  private stakeholderQueueRunningSubject = new BehaviorSubject<boolean>(false);
  stakeholderQueueRunning: Observable<boolean> = this.stakeholderQueueRunningSubject.asObservable();

  _insuranceStore: PersonInsurance[] = [];
  areInsuranceQueuedOperationsRunning = false;
  private insuranceQueueRunningSubject = new BehaviorSubject<boolean>(false);
  insuranceQueueRunning: Observable<boolean> = this.insuranceQueueRunningSubject.asObservable();

  _incidentStore: CaseIncident[] = [];
  areIncidentQueuedOperationsRunning = false;
  private incidentQueueRunningSubject = new BehaviorSubject<boolean>(false);
  incidentQueueRunning: Observable<boolean> = this.incidentQueueRunningSubject.asObservable();

  _plannedTravelStore: PlannedTravel[] = [];
  arePlannedTravelQueuedOperationsRunning = false;

  _otherInsurance: OtherInsurance[] = [];
  areOtherInsuranceQueuedOperationsRunning = false;


  pushStakeholder(val: CaseStakeholder) {
    this._stakeholderStore.push(val);
  }
  popStakeholder(): CaseStakeholder | null {
    return this._stakeholderStore.shift();
  }
  stakeholderQueuedOperationsRunning(val: boolean) {
    this.areStakeholderQueuedOperationsRunning = val;
    if (this.stakeholderQueueRunningSubject.getValue() !== val) {
      this.stakeholderQueueRunningSubject.next(val);
    }
  }
  anyStakeholderQueuedOperationsRunning(): boolean {
    return this.areStakeholderQueuedOperationsRunning;
  }
  pushIncident(val: CaseIncident) {
    this._incidentStore.push(val);
  }
  popIncident(): CaseIncident | null {
    return this._incidentStore.shift();
  }
  incidentQueuedOperationsRunning(val: boolean) {
    this.areIncidentQueuedOperationsRunning = val;
    if (this.incidentQueueRunningSubject.getValue() !== val) {
      this.incidentQueueRunningSubject.next(val);
    }
  }
  anyIncidentQueuedOperationsRunning(): boolean {
    return this.areIncidentQueuedOperationsRunning;
  }
  pushInsurance(val: PersonInsurance) {
    this._insuranceStore.push(val);
  }
  popInsurance(): PersonInsurance | null {
    return this._insuranceStore.shift();
  }
  insuranceQueuedOperationsRunning(val: boolean) {
    this.areInsuranceQueuedOperationsRunning = val;
    if (this.insuranceQueueRunningSubject.getValue() !== val) {
      this.insuranceQueueRunningSubject.next(val);
    }
  }
  anyInsuranceQueuedOperationsRunning(): boolean {
    return this.areInsuranceQueuedOperationsRunning;
  }
  pushPlannedTravel(val: any) {
    this._plannedTravelStore.push(val);
  }
  popPlannedTravel(): PlannedTravel | null {
    return this._plannedTravelStore.shift();
  }
  plannedTravelQueuedOperationsRunning(val: boolean) {
    this.arePlannedTravelQueuedOperationsRunning = val;
  }
  anyPlannedTravelQueuedOperationsRunning(): boolean {
    return this.arePlannedTravelQueuedOperationsRunning;
  }
  pushOtherInsurance(val: any) {
    this._otherInsurance.push(val);
  }
  popOtherInsurance(): OtherInsurance | null {
    return this._otherInsurance.shift();
  }
  otherInsuranceQueuedOperationsRunning(val: boolean) {
    this.areOtherInsuranceQueuedOperationsRunning = val;
  }
  anyOtherInsuranceQueuedOperationsRunning(): boolean {
    return this.areOtherInsuranceQueuedOperationsRunning;
  }
}
