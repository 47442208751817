import { MedicalNoteTypeEnum } from '@secca/shared/models/enums';
import { Injectable } from '@angular/core';
import { Adapter } from '../../interfaces/adapter';

export class MedicalNote {
    id: number;
    seccaCaseId: number;
    noteType: MedicalNoteTypeEnum;
    noteText: string;

    public constructor(init?: Partial<MedicalNote>) {
        Object.assign(this, init);
    }
}

@Injectable({
    providedIn: 'root'
})
export class medicalNoteAdapter implements Adapter<MedicalNote> {
    adapt(item: any): MedicalNote {
        return new MedicalNote({
            id: item.id,
            seccaCaseId: item.seccaCaseId,
            noteType: item.noteType,
            noteText: item.noteText
        });
    }
}
