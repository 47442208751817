import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';

export class LinkedCaseCreate {
    seccaCaseId: string;
    linkedCaseNumber: number;
    groupId: string;

    public constructor(init?: Partial<LinkedCaseCreate>) {
      Object.assign(this, init);
    }
}

@Injectable({
    providedIn: 'root'
})

export class LinkedCaseCreateAdapter implements Adapter<LinkedCaseCreate> {
    adapt(item: any): LinkedCaseCreate {
        return new LinkedCaseCreate({
            seccaCaseId: item.seccaCaseId,
            linkedCaseNumber: item.linkedCaseNumber,
            groupId: item.groupId,
        });
    }
}