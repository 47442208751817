<div class="modal-content" *ngIf="stakeholder">
  <div class="modal-body">
    <div>
      <div> {{stakeholder.firstName}} {{stakeholder.surname}} </div>
      <div> {{shownNationalId}} <img (mouseenter)="unMaskNationalId()" (mouseleave)="maskNationalId()"
                                     class="showNationalId" src='./../../../../../../../assets/icons/eye.svg'></div>
    </div>
    <div *ngIf="isAddressFilled()">
      <hr>
      <div>
        <div>{{stakeholder.street}}</div>
        <div>{{stakeholder.postalCode}} {{stakeholder.city}}</div>
        <div>{{stakeholder.homeCountry}}</div>
      </div>
    </div>
    <div *ngIf="isEmailPhoneFilled()">
      <hr>
      <div>
        <div>{{stakeholder.email}}</div>
        <div *ngIf="phoneNumberFixed">{{phoneNumberFixed.prefix }} {{phoneNumberFixed.number | mask: '00 00 00 00 00 00 00 00 {9}' }}</div>

      </div>
    </div>
    <div class="divider"></div>
    <div *permission="PermissionEnum.CASE_BASIC_CREATE">
      <button (click)="createCaseButtonPressed()" class="primary-button">
        Create new case<br>with this profile
      </button>
    </div>
  </div>
</div>
<i class="arrow down"></i>
