<div *ngIf="case && task" class="modal-container">
  <div class="modal-content">
    <div class="modal-content-body">
      <div *permission="PermissionEnum.TASK_UPDATE; hideType: PermissionHideTypeEnum.DISABLE">
        <div class="modal-body modal-body-external p-0 mb-2">
          <div class="container">
            <div>
              <div class="row">
                <div class="left-panel-message-case-edit" class="col-12">
                  <div class="modal-body-left-panel">
                    <div [appDisableInput]="isDisabled">
                      <div class="field-title">{{ 'task-modal-title' | translate }}</div>
                      <div>
                        <input type="text" name="textinput" class="task-text"
                              maxlength="1000" [name]="'Title'" [required]="true" (input)="setTaskHasChanged()"
                              [(ngModel)]="task.title" />
                      </div>
                      <div class="row-separator-small"></div>
                    </div>
                  </div>
                  <div [appDisableInput]="isDisabled"
                      class="caselock-no-events">
                    <div class="field-title">{{ 'task-modal-received-date' | translate }}</div>
                    <div>
                      <app-input-date-time [disabled]="true" [date]="task.receivedDate" [withImage]="true" [ngClass]="validated ? '' : 'required-red'"
                                          [timeZone]="'local'">
                      </app-input-date-time>
                    </div>
                    <div class="row-separator"></div>
                    <app-case-handler-selector [acHandler]="acHandler" [caseId]="caseId" [required]="true"
                                              (caseHandlerChange)="caseHandlerChanged($event)">
                    </app-case-handler-selector>
                    <div class="row-separator"></div>
                    <div class="field-title">{{ 'task-modal-status' | translate }}</div>
                    <div>
                      <app-drop-down
                        [dropdownPosition]="'auto'"
                        [disabled]="taskStatusDisabled" [items]="task.allStatuses" [(selectedItemId)]="task.status"
                        [requiredRedBackground]="true" (selectedItemIdChange)="setTaskHasChanged()"></app-drop-down>
                    </div>
                    <div class="row-separator"></div>
                      <app-drop-down
                        [dropdownPosition]="'auto'"
                        [disabled]="taskStatusDisabled"
                        [items]="priorityDropdown"
                        [(selectedItemId)]="task.priority"
                        (selectedItemIdChange)="setTaskHasChanged()"
                      ></app-drop-down>
                    <div class="row-separator"></div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 task-id-col">
                  <div class="task-id">
                    <div>{{task.createdOn ? ('Created: ' + (task.createdOn | momentFormat:'DD MMM YYYY HH:mm':'local') + '&nbsp;&nbsp;&nbsp;ID: ' + task.id) : '&nbsp;' }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="task.digitalCaseflowLogId !== null" class="col-12 dcf-webtask">
            <div>
              <span class="field-title">{{ 'digital-caseflow-name' | translate }}</span>
            </div>
            <div class="dcf-details">
              <app-task-web-digital-caseflow-details [digitalCaseflowLogId]="task.digitalCaseflowLogId"></app-task-web-digital-caseflow-details>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
