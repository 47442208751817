export class ErpCreditor {
  id: number;
  erpId: string;
  erpActive: boolean;
  name: string;
  alias: string;
  vatRegNo: string;
  countryCode: string;
  emails: string;
  segmentDesc: string;
  paymentTermsDesc: string;
  bankAccount: string;
  currency: string;
  iban: string;
  swift: string;
}
