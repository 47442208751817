import { Injectable } from '@angular/core';
import { Adapter } from '@secca/shared/interfaces/adapter';
import * as moment from 'moment';

export class CustomerInvoiceLastJob {
  startedOn: moment.Moment;
  public constructor(init?: Partial<CustomerInvoiceLastJob>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
export class CustomerInvoiceLastJobAdapter implements Adapter<CustomerInvoiceLastJob> {
  adapt(item: any): CustomerInvoiceLastJob {
    const startedOn = !!item.startedOn ? moment.utc(item.startedOn) : null;
    return new CustomerInvoiceLastJob({
      startedOn: startedOn,
    });
  }
}
