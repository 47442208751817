import { RefundTaskViewModel } from '@secca/board/components/board-task/models/refund-task-view.model';
import { RecoveryTaskViewModel } from './recovery-task-view.model';

export class RecoveryTaskFilterResponse {
  totalNumber: number;
  taskViewModels: RecoveryTaskViewModel[];

  public constructor(init?: Partial<RecoveryTaskFilterResponse>) {
    Object.assign(this, init);
  }
}
