
<div class="modal-dialog-wrapper" appDialogSelectable appDialogKeyboardEnabled [enabledKeys]="enabledKeys">
    <mat-dialog-content (cdkDragStarted)="dialogBoundaryService.handleDragStart($event)" (cdkDragEnded)="dialogBoundaryService.handleDragEnd($event)" cdkDragRootElement=".cdk-overlay-pane" cdkDrag>
        <div appDialogDragArea cdkDragHandle></div>
        <app-case-summary-note-linked-case-modal
                      (closed)="close()"
        [caseId]="data.caseId"
        [note]="data.note" >

        </app-case-summary-note-linked-case-modal>
    </mat-dialog-content>
</div>
