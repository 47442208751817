<div class="case-coverage-container">
  <div class="case-coverage">
    <div class="case-coverage-row">
      <div class="id-header">
        ID
      </div>
      <div class="coverage-header">
        Coverage
      </div>
      <div class="limit-currency-header">
        Currency
      </div>
      <div class="limit-header">
        Limit
      </div>
      <div class="usage-currency-header">
        Currency
      </div>
      <div class="reserve-header">
        Reserve
      </div>
      <div class="planned-cost-header">
        Planned cost
      </div>
      <div class="remaining-header">
        Remaining
      </div>
    </div>
    <div class="case-coverage-row" *ngFor="let coverage of coveragesSummarized">
      <div class="small-item">
        <span>
          {{coverage.limitId}}
        </span>
      </div>
      <div class="coverage-item">
        <span [class.not-valid]="!isCoverageValid(coverage)">
          {{coverage.typeDescription}}
        </span>
      </div>
      <div class="small-item-limit">
        <span>
          {{coverage.limitCurrency}}
        </span>
      </div>

      <div class="limit-item">
        <div class="d-flex">
          <div class="limit">
            <ng-template [ngIf]="coverage.limit === -1">
              {{"case-coverage-unlimited" | translate}}
            </ng-template>
            <ng-template [ngIf]="coverage.limit !== -1">
              {{coverage.limit | numberFormat}}
            </ng-template>
          </div>
          <div class="warning">
            <div triggers="hover" placement="right" [ngbPopover]="'case-coverage-pop-limit-warning' | translate">
              <img *ngIf="coverage.nonAmountLimitsExist" src="/assets/icons/Warning.svg"/>
            </div>
          </div>
        </div>
      </div>
      <div class="small-item-left-border">
        <span>
          {{billingCurrency}}
        </span>
      </div>
      <div class="reserve-item">
        {{coverage.reserve | numberFormat}}
      </div>
      <div class="planned-cost-item">
        <ng-template [ngIf]="coverage.planned == null || coverage.planned === 0" [ngIfElse]="showPlannedValue">
          -
        </ng-template>
        <ng-template #showPlannedValue>{{coverage.planned | numberFormat}}</ng-template>
      </div>
      <div class="remaining-item">
        <ng-template [ngIf]="coverage.planned == null || coverage.planned === 0" [ngIfElse]="showCalculateddValue">
          {{coverage.reserve | numberFormat}}
        </ng-template>
        <ng-template #showCalculateddValue> {{coverage.reserve - coverage.planned | numberFormat}}</ng-template>
      </div>
    </div>
  </div>
</div>
