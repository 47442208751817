import {Observable} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {Injector, NgZone} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import {ErrorModalDialogConfiguration} from '@secca/shared/models/errormodal/modal-error-configuration';
import {ModalErrorDialogGlobalComponent} from '@secca/shared/components/modal-error-dialog-global/modal-error-dialog-global.component';
import {GlobalErrorModalSessionObject} from '@secca/shared/models/errormodal/global-error-modal-session-object';
import { SettingsService } from '@secca/core/services/settings.service';

export class GlobalErrorhandlerHelper {

  private settingsService: SettingsService;
  private globalErrorModal: NgbModal;
  private ngbModalRef: NgbModalRef;
  private modalConfiguration: ErrorModalDialogConfiguration;
  private isErrorModalOpen = false;

  constructor(errorMessages$: Observable<Error | HttpErrorResponse>,
              private injector: Injector,
              private zone: NgZone) {
    this.settingsService = injector.get(SettingsService);

    errorMessages$.subscribe(error => {
      if (!this.getGlobalErrorModel()) {
        return;
      }

      const globalErrorModelSession = sessionStorage.getItem(GlobalErrorModalSessionObject.key);
      if (globalErrorModelSession) {
        const globalErrorModelSessionParsed = JSON.parse(globalErrorModelSession);
        if (globalErrorModelSessionParsed.suppressErrors) {
          const expireDate = new Date(globalErrorModelSessionParsed.expiresAt);
          const diffMs = expireDate.getTime() - new Date().getTime();
          if (diffMs > 0) {
            return;
          }
        }
      }

      this.zone.runGuarded(() => {
        // Check if modal is opened already
        if (error instanceof HttpErrorResponse && error.status === 403) {
          return;
        }

        if ( !this.isErrorModalOpen ) {
          this.openGlobalErrorModal();
          this.modalConfiguration = new ErrorModalDialogConfiguration();
          this.modalConfiguration.header = 'global-error-modal-header';
          this.modalConfiguration.contactSupport = 'global-error-modal-contact-support';
          if (error instanceof HttpErrorResponse) {
            if (!this.ignore(error)) {
              this.modalConfiguration.httpErrors.push(error);
            }
          } else if (error instanceof Error) {
            this.modalConfiguration.errors.push(error);
          } 
          this.ngbModalRef.componentInstance.configuration = this.modalConfiguration;
          this.ngbModalRef.componentInstance.closeModalEvent.subscribe(
            emittedEvent => {
              this.ngbModalRef.close();
            }
          );
          this.ngbModalRef.componentInstance.selectedErrorEvent.subscribe(
            selectedError => {

            }
          );

        } else {
          if (error instanceof Error) {
            this.modalConfiguration.errors.push(error);
          } else if (error instanceof HttpErrorResponse) {
            this.modalConfiguration.httpErrors.push(error);
          }
        }
      });

    });
  }

  private ignore(error: HttpErrorResponse) {
    return error.statusText === 'Unknown Error' && error.status === 0;
  }

  getGlobalErrorModel(): NgbModal {
    if (!this.globalErrorModal) {
     this.globalErrorModal = this.injector.get(NgbModal);
    }
    return this.globalErrorModal;
  }

  private openGlobalErrorModal(): void {
    if (!this.settingsService.isZoomed()) {
      this.ngbModalRef = this.globalErrorModal.open(ModalErrorDialogGlobalComponent, { centered: true });
    } else {
      this.ngbModalRef = this.globalErrorModal.open(ModalErrorDialogGlobalComponent, { windowClass: 'top-most-window-class-zoomed', centered: true });
    }
    this.isErrorModalOpen = true;

    this.ngbModalRef.result.then(
      result => {
        this.isErrorModalOpen = false;
      },
      error => {
        this.isErrorModalOpen = false;
    });
  }
}
