import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CaseService} from '@secca/core/services/case.service';
import {InsuranceService} from '@secca/core/services/insurance.service';
import {CaseStakeholder} from '@secca/shared/models/caseStakeholder';
import {PersonInsurance} from '@secca/shared/models/person-insurance';
import {Subscription} from 'rxjs';
import {ProfileService} from '@secca/core/services/profile.service';
import {RelationSearchResult, ProfileLookupResponse, CustomerProductRelationType} from '@secca/shared/models/profile-lookup-response';
import {DropdownDictionary} from '@secca/shared/models/dropdownDictionary';
import {IncidentService} from '@secca/core/services/incident.service';
import {DaySerializer} from '@secca/shared/models/day-serializer';
import {ProfileChangeService} from '@secca/case/components/case-basics/case-basics-insurance/profile-change-service';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import * as moment from 'moment';

@Component({
  selector: 'app-profile-lookup',
  templateUrl: './profile-lookup.component.html',
  styleUrls: ['./profile-lookup.component.scss']
})
@AutoUnsubscribe
export class ProfileLookupComponent implements OnInit {
  @Input() set caseId(value: string) {
    this._caseId = value;
    if (value != null) {
      this.subscribeToPersonInsurance();
      this.subscribeToIncidentDate();
      this.getCaseReportDate();
    }
  }
  get caseId() {
    return this._caseId;
  }

  @Output()
  closed: EventEmitter<void> = new EventEmitter();

  private _caseId: string;
  lookupResponse: ProfileLookupResponse;
  profileFound: boolean;
  sufficientSearchCriteria: boolean;
  chosen = 0;

  private incidentDate: moment.Moment;
  private reportDate: moment.Moment;
  policyHolder = new CaseStakeholder();
  nameLine = '';
  companyName = '';
  addressLine = '';
  showSelectedProfile: boolean;
  relationSearchResult: RelationSearchResult;
  insurance = new PersonInsurance();
  $personInsuranceSubscr: Subscription;
  banks: DropdownDictionary[];
  cardTypes: DropdownDictionary[];
  levels: DropdownDictionary[];
  private _creditCardNo: string;
  get creditCardNo(): string {
    return this._creditCardNo;
  }
  set creditCardNo(value: string) {
    this._creditCardNo = value;
    let newBin = null;
    if (value != null && value.length >= 6) {
      newBin = value.substr(0, 6);
    }
    if (newBin !== this.bin) {
      this.bin = newBin;
      this.bank = null;
      this.cardType = null;
      this.level = null;
    }
    if (this.insurance.creditCard) {
      this.getBanks(this.bin);
    }
  }
  policyNo: string;
  bank: string;
  bin: string;
  cardType: string;
  level: string;
  selectionInProgress = false;

  constructor(
    private modalService: NgbModal,
    private caseService: CaseService,
    private incidentService: IncidentService,
    private profileService: ProfileService,
    private insuranceService: InsuranceService,
    private profileChangeService: ProfileChangeService) {
    this.caseService.observePolicyholder().subscribe(
      policyHolder => {
        if (policyHolder) {
          this.policyHolder = policyHolder;
          this.updatePolicyHolderData();
        }
      }
    );
  }

  ngOnInit(): void {
    this.init();
  }

  private subscribeToPersonInsurance() {
    if ( this.$personInsuranceSubscr ) {
      this.$personInsuranceSubscr.unsubscribe();
    }
    this.$personInsuranceSubscr = this.insuranceService.getPersonInsurance(this.caseId).subscribe(
      insurance => {
        if (insurance) {
          this.insurance = insurance;
          this.creditCardNo = insurance.creditCardNo;
          this.policyNo = insurance.policyReference;
        }
      }
    );
  }

  subscribeToIncidentDate() {
    this.incidentService.getIncidentDateChanges(this.caseId).subscribe(
      incidentDate => {
        this.incidentDate = incidentDate;
      }
    );
  }

  getCaseReportDate() {
    this.caseService.getCaseReportDate(this.caseId).subscribe(
      reportDate => {
        this.reportDate = reportDate;
      }
    );
  }

  getBanks(bin: string): void {
    this.profileService.getBanks(bin).subscribe(
      result => {
        this.banks = result;
      },
      error => {
        this.close();
        throw error;
      }
    );
  }

  getCardTypes(bin: string, bank: string): void {
    this.profileService.getCardTypes(bin, bank).subscribe(
      result => {
        this.cardTypes = result;
      },
      error => {
        this.close();
        throw error;
      }
    );
  }

  getLevels(bin: string, bank: string, cardType: string): void {
    this.profileService.getLevels(bin, bank, cardType).subscribe(
      result => {
        this.levels = result;
      },
      error => {
        this.close();
        throw error;
      }
    );
  }

  saveBankAndPerformLookup(bank: string): void {
    this.bank = bank;
    this.cardType = null;
    this.level = null;
    this.levels = null;
    this.getCardTypes(this.bin, this.bank);
    this.performLookup();
  }

  saveCardTypeAndPerformLookup(cardType: string): void {
    this.cardType = cardType;
    this.level = null;
    this.getLevels(this.bin, this.bank, this.cardType);
    this.performLookup();
  }

  saveLevelAndPerformLookup(level: string): void {
    this.level = level;
    this.performLookup();
  }

  updatePolicyHolderData() {
    if (this.policyHolder && this.policyHolder.isCompany) {
      this.nameLine = this.policyHolder.company.name;
      this.companyName = this.nameLine;
    } else if (this.insurance.creditCard) {
      this.nameLine = this.policyHolder.person.firstName + ' ' + this.policyHolder.person.surname;
      if (this.policyHolder.person.gender && this.policyHolder.person.age) {
        this.nameLine += ' (' + this.policyHolder.person.gender + this.policyHolder.person.age + ')';
      }
    }
    const country = this.policyHolder.address && this.policyHolder.address.country ? this.policyHolder.address.country.name : null;
    const postalCodeCity = [this.policyHolder.address.postalCode, this.policyHolder.address.city].filter(Boolean).join(' ');
    this.addressLine = [this.policyHolder.address.street, postalCodeCity, country].filter(Boolean).join(', ');
  }

  init(): void {
    this.lookupResponse = null;
    this.relationSearchResult = null;
    this.policyNo = this.insurance.policyReference;
    this.updatePolicyHolderData();
    this.creditCardNo = this.insurance.creditCardNo;
    this.bank = null;
    this.cardType = null;
    this.level = null;
    if (this.insurance.creditCard) {
      this.getBanks(this.bin);
    }
    this.performLookup();
  }

  performLookup() {
    this.lookupResponse = null;
    this.profileFound = false;
    this.sufficientSearchCriteria = false;
    this.relationSearchResult = null;
    this.onShowSelectedProfile();
    if (this.policyHolder.isCompany) {
      if (this.companyName || this.policyNo) {
        this.sufficientSearchCriteria = true;
        const customerProductRelationTypes = [CustomerProductRelationType.BUSINESS_TRAVEL, CustomerProductRelationType.EXPATRIATE_INSURANCE, CustomerProductRelationType.GROUP_INSURANCE];
        this.profileService.lookupProfile(
          this.caseId,
          customerProductRelationTypes,
          this.companyName,
          this.policyNo,
          this.policyHolder.address.country.code,
          null,
          null,
          null,
          null,
          DaySerializer.serialize(this.incidentDate),
          DaySerializer.serialize(this.reportDate))
          .subscribe(
            result => {
              this.showLookupResponse(result);
            },
            error => {
              this.close();
              throw error;
            }
          );
      } else {
        this.showLookupResponse(this.emptyProfileLookupResponse());
      }
    } else if (this.insurance.creditCard) {
      if (this.bin || this.bank) {
        this.sufficientSearchCriteria = true;
        const customerProductRelationTypes = [CustomerProductRelationType.CREDIT_CARD];
        this.profileService.lookupProfile(
          this.caseId,
          customerProductRelationTypes,
          null,
          null,
          null,
          this.bin,
          this.bank,
          this.cardType,
          this.level,
          DaySerializer.serialize(this.incidentDate),
          DaySerializer.serialize(this.reportDate))
          .subscribe(
            result => {
              this.showLookupResponse(result);
            },
            error => {
              this.close();
              throw error;
            }
          );
      } else {
        this.showLookupResponse(this.emptyProfileLookupResponse());
      }
    }
  }

  emptyProfileLookupResponse(): ProfileLookupResponse {
    return new ProfileLookupResponse({
        lookupTime: null,
        matchedBy: '',
        sourceInfo: '',
        results: null
      }
    );
  }

  showLookupResponse(profileLookupResponse: ProfileLookupResponse) {
    this.lookupResponse = profileLookupResponse;
    this.profileFound = !(profileLookupResponse.results === null || profileLookupResponse.results.length === 0);
    if (this.profileFound) {
      this.onShowLookupProfile(0);
    }
  }

  close() {
    this.closed.emit();
    this.modalService.dismissAll();
  }

  profileSelect(relationSearchResult: RelationSearchResult) {
    this.selectionInProgress = true;
    // This will only generate case history and add relation label to the customerProfileInfo
    this.insuranceService.profileSelectedByLookup(this.insurance.id, this.adaptRelationSearchResultForServiceCall(relationSearchResult));
    // This will update the customer profile on the UI and update the backend subsequently.
    this.insurance.customerProfileId = relationSearchResult.customerProductRelation.profileId;
    this.onProfileChange();
    this.insuranceService.updateCustomerProfile(this.insurance).subscribe(
      () => {
        this.profileChangeService.publish(this.caseId, this.insurance.customerProfileId);
        this.close();
      });

  }

  onProfileChange() {
    this.insurance.insuranceLevelId = null;
    this.insurance.geographicalCoverageArea = null;
    this.insurance.customerProduct = null;
    this.insurance.coverageDays = null;
    this.insurance.coverageWeeks = null;
    this.insurance.coveragePeriodFromLookup = false;
  }

  private adaptRelationSearchResultForServiceCall(relationSearchResult: RelationSearchResult) {
    const relationSearchResultRequest = JSON.parse(JSON.stringify(relationSearchResult));
    relationSearchResultRequest.customerProductRelation.validFrom = DaySerializer.serialize(relationSearchResult.customerProductRelation.validFrom);
    relationSearchResultRequest.customerProductRelation.validTo = DaySerializer.serialize(relationSearchResult.customerProductRelation.validTo);
    return relationSearchResultRequest;
  }

  onShowLookupProfile(idx: number) {
    this.relationSearchResult = this.lookupResponse.results[idx];
    this.chosen = idx;
    this.showSelectedProfile = false;
  }

  onShowSelectedProfile() {
    this.chosen = undefined;
    this.showSelectedProfile = true;
  }
}
