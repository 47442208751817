import {Pipe, PipeTransform, SecurityContext} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {NgDompurifySanitizer} from '@tinkoff/ng-dompurify';

@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer,
              private readonly dompurifySanitizer: NgDompurifySanitizer) {}

 public transform(value: any): any {
   const purifiedHtml = this.dompurifySanitizer.sanitize(SecurityContext.HTML, value, {FORBID_TAGS: ['style']});
   return this.sanitizer.bypassSecurityTrustHtml(purifiedHtml);
  }
}
