<ul ngbNav #nav="ngbNav" class="service-order-type-tabset nav-tabs" [(activeId)]="activeTab" >
  <li [id]="SelectedTab.ExpectationTab" [ngbNavItem]="SelectedTab.ExpectationTab">
    <a ngbNavLink>{{"service-type-expectation" | translate}}</a>
    <ng-template ngbNavContent>
      <div [appDisableInput]="caseLocked || serviceOrderTypeState.isLockedByCoordinationTransport" class="caselock-no-events">
        <div class="sub-component-placeholder row">
          <div class="col-3">
            <div class="common-drop-down-arrow drop-down">
              <span class="name">{{'regular-flight-non-medical-type' | translate}}</span>
              <ng-select class="custom" [items]="typesDropdown" bindValue="id" bindLabel="name" [(ngModel)]="regularFlightNonMedical.type"
                        (change)="selectType($event)" [clearable]="false" *ngIf="typesDropdown"></ng-select>
            </div>
          </div>
          <div class="col-6">
            <div class="d-flex float-end align-items-end h-100" *ngIf="regularFlightNonMedical.type === regularFlightType.REGULAR_PASSENGERS">
              <a class="btn primary-button" href="https://www.egencia.dk/" target="_blank">{{'regular-flight-non-medical-egencia-online' | translate}} <img class="ps-2" src="/assets/icons/new window white.svg"></a>
            </div>
          </div>
        </div>
        <div class="sub-component-placeholder" *ngIf="regularFlightNonMedical.type !== regularFlightType.REGULAR_PASSENGERS">
          <app-sub-reference [(subReference)]="regularFlightNonMedical.reference"></app-sub-reference>
        </div>
        <div class="sub-component-placeholder">
          <app-sub-travellers
              [caseId]="caseId"
              [travellers]="regularFlightNonMedical.expectedSubTravellers"
              [stakeholders]="stakeholders"
              [subStakeholders]="regularFlightNonMedical.expectedSubStakeholders"
              [subStakeholdersConfig]="subStakeholdersConfig"
              [hideCabinClass]="true"
              [hideFlightInRefs]="true"
              [departureDateRequired]="true"
              [departureFromRequired]="true"
              [arrivalDateRequired]="false"
              [arrivalAtRequired]="true"
              [hideAddFlightLeg]="true"
              [disableStakeholders]="regularFlightNonMedical.type !== regularFlightType.REGULAR_PASSENGERS"
              [serviceOrderType]="serviceOrderType"
            >
            </app-sub-travellers>
        </div>
        <div class="sub-component-placeholder" *ngIf="regularFlightNonMedical.type !== regularFlightType.REGULAR_PASSENGERS">
          <app-sub-coffin-urn [required]="false" [serviceOrderType]="serviceOrderType" [handlingDisabled]="true"
                              [subCoffinUrn]="regularFlightNonMedical.coffinUrn"></app-sub-coffin-urn>
        </div>
        <div class="sub-component-placeholder" *ngIf="regularFlightNonMedical.type !== regularFlightType.REGULAR_PASSENGERS">
          <app-sub-funeral-homes [caseId]="caseId" [subFuneralHomes]="regularFlightNonMedical.funeralHomes" [stakeholders]="stakeholders" ></app-sub-funeral-homes>
        </div>
        <div class="sub-component-placeholder">
          <app-sub-remarks-to-supplier [(subRemarksToSupplier)]="regularFlightNonMedical.remarksToSupplier"></app-sub-remarks-to-supplier>
        </div>
        <div class="sub-component-placeholder row">
          <div class="col-12">
            <button class="primary-button float-end" (click)="sendConfirmation()" [disabled]="disableSendRequest()">{{'regular-flight-non-medical-send-request' | translate}}</button>
          </div>
        </div>
      </div>
    </ng-template>
  </li>
  <ng-container *ngIf="iccServiceOrder">
    <li *ngFor='let coordinationTransportServiceOrderExtension of coordinationServiceOrderExtensions' [id]="coordinationTransportServiceOrderExtension.serviceOrderId" [ngbNavItem]="coordinationTransportServiceOrderExtension.serviceOrderId" >
      <a style="font-size: 12px; line-height: 12px; padding-top: 3px;" ngbNavLink>{{linkedEndUserCaseDetail?.caseNumber}}<br/>{{ linkedEndUser?.person.firstName }} {{ linkedEndUser?.person.surname }}</a>
      <ng-template ngbNavContent>
        <app-end-user-linked-case-component
          *ngIf="linkedEndUserCaseDetail"
          [endUserDisplayReadOnly]='true'
          [caseId]='caseId'
          [endUserCaseDetail]='linkedEndUserCaseDetail'
          [coordinationTransportServiceOrderExtension]='coordinationTransportServiceOrderExtension'
          [subStakeholdersConfig]="linkedEndUserSubStakeholdersConfig"
          [serviceOrderType]='serviceOrderType'
          [status]='status'
        >
        </app-end-user-linked-case-component>
      </ng-template>
    </li>
  </ng-container>
  <li [id]="SelectedTab.ConfirmationTab" *ngIf="serviceOrderTypeState.showConfirmationTab && !iccServiceOrder" [ngbNavItem]="SelectedTab.ConfirmationTab">
    <a ngbNavLink>{{"service-type-confirmation" | translate}}<div class="close-tab-x" (click)="closeConfirmationTab($event)"
        *ngIf="!serviceOrderTypeState.isConfirmationTabSaved"><img src="/assets/icons/Close Black.svg"></div>
    </a>
    <ng-template ngbNavContent>
      <div [appDisableInput]="caseLocked || serviceOrderTypeState.isLockedByCoordinationTransport" class="caselock-no-events">
      <div class="sub-component-placeholder row">
        <div class="col-3">
          <div class="common-drop-down-arrow drop-down">
            <span class="name">{{'regular-flight-non-medical-type' | translate}}</span>
            <ng-select class="custom" [items]="typesDropdown" bindValue="id" bindLabel="name" [(ngModel)]="regularFlightNonMedical.type"
                       (change)="selectType($event)" [clearable]="false" *ngIf="typesDropdown" [disabled]="true"></ng-select>
          </div>
        </div>
        <div class="col-7">
          <app-sub-commitment-type
            [(commitmentType)]="regularFlightNonMedical.commitmentType" [serviceOrderType]="serviceOrderType"  [status]="status" [caseId]="caseId">
          </app-sub-commitment-type>
        </div>
        <div class="col-2">
          <div class="d-flex float-end align-items-end h-100" *ngIf="regularFlightNonMedical.type === regularFlightType.REGULAR_PASSENGERS">
            <a class="btn primary-button no-hover" href="https://www.egencia.dk/" target="_blank">{{'regular-flight-non-medical-egencia-online' | translate}} <img class="ps-2" src="/assets/icons/new window white.svg"></a>
          </div>
        </div>
      </div>
      <div class="sub-component-placeholder" *ngIf="regularFlightNonMedical.type !== regularFlightType.REGULAR_PASSENGERS">
        <app-sub-reference [(subReference)]="regularFlightNonMedical.reference"></app-sub-reference>
      </div>
      <div class="sub-component-placeholder">
        <app-sub-travellers
                [caseId]="caseId"
                [travellers]="regularFlightNonMedical.confirmationSubTravellers"
                [stakeholders]="stakeholders"
                [subStakeholders]="regularFlightNonMedical.confirmationSubStakeholders"
                [subStakeholdersConfig]="subStakeholdersConfig"
                [hideCabinClass]="regularFlightNonMedical.type !== regularFlightType.REGULAR_PASSENGERS"
                [hideTicketDetails]="regularFlightNonMedical.type !== regularFlightType.REGULAR_PASSENGERS"
                [cabinClassRequired]="true"
                [departureDateRequired]="true"
                [departureFromRequired]="true"
                [arrivalDateRequired]="true"
                [arrivalAtRequired]="true"
                [flightNoRequired]="true"
                [agencyReferenceRequired]="false"
                [disableStakeholders]="regularFlightNonMedical.type !== regularFlightType.REGULAR_PASSENGERS"
                [serviceOrderType]="serviceOrderType"
        >
        </app-sub-travellers>
      </div>
      <div class="sub-component-placeholder" *ngIf="regularFlightNonMedical.type !== regularFlightType.REGULAR_PASSENGERS">
        <app-sub-coffin-urn [serviceOrderType]="serviceOrderType" [(subCoffinUrn)]="regularFlightNonMedical.coffinUrn" [readOnlyMode]="true"></app-sub-coffin-urn>
      </div>
      <div class="sub-component-placeholder" *ngIf="regularFlightNonMedical.type !== regularFlightType.REGULAR_PASSENGERS">
        <app-sub-funeral-homes [caseId]="caseId" [(subFuneralHomes)]="regularFlightNonMedical.funeralHomes" [stakeholders]="stakeholders" [readOnlyMode]="true">
        </app-sub-funeral-homes>
      </div>
        <div class="sub-component-placeholder">
          <app-sub-airway-bill [subAirwayBill]="regularFlightNonMedical.airwayBill"></app-sub-airway-bill>
        </div>
      <div class="sub-component-placeholder" *ngIf="regularFlightNonMedical.type === regularFlightType.REGULAR_PASSENGERS">
        <app-sub-remarks-rules-and-fees [subRemarksRulesAndFees]="regularFlightNonMedical.remarksRulesAndFees"></app-sub-remarks-rules-and-fees>
      </div>
      <div class="sub-component-placeholder" *ngIf="regularFlightNonMedical.type !== regularFlightType.REGULAR_PASSENGERS">
        <app-sub-remarks-from-supplier [subRemarksFromSupplier]="regularFlightNonMedical.remarksFromSupplier"></app-sub-remarks-from-supplier>
      </div>
      <div class="sub-component-placeholder row">
        <div class="col-12">
          <button class="primary-button float-end" [disabled]="disableSendRequest()" (click)="sendConfirmation()">
            {{'regular-flight-non-medical-send-itinerary' | translate}}
          </button>
        </div>
      </div>
      </div>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="nav"></div>

