import { ExtraSpaceEnum } from '../enums';

import { Injectable } from '@angular/core';
import { Adapter } from '../../interfaces/adapter';

export class RepatriationExtraSpace {
  type: ExtraSpaceEnum;
  otherItemDescription: string;

  public constructor(init?: Partial<RepatriationExtraSpace>) {
    Object.assign(this, init);
  }

  setOtherItemDescription(value: string) {
    this.otherItemDescription = value;
  }
}

@Injectable({
  providedIn: 'root'
})
export class RepatriationExtraSpaceAdapter implements Adapter<RepatriationExtraSpace> {
  adapt(item: any): RepatriationExtraSpace {
    return new RepatriationExtraSpace({
      type: item.type,
      otherItemDescription: item.otherItemDescription
    });
  }
}
