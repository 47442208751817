import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SettingsLoaderService } from '@secca/core/services/settings.loader.service';
import { ModalDialogConfiguration } from '@secca/shared/models/modal/modal-dialog-configuration';
import { interval, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { ModalDialogComponent } from '../modal-dialog/modal-dialog.component';
import { forEach } from 'lodash';


@Component({
  selector: 'app-reload-footer',
  templateUrl: './reload-footer.component.html',
  styleUrls: ['./reload-footer.component.scss']
})
export class ReloadFooterComponent implements OnInit {

  newVersion = false;
  private lastModified: String;
  private readonly CONFIG_URL = 'build.txt';
  private headers = new HttpHeaders({
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache'
  });

  private subscription;
  count = 0;

  constructor(private settingsLoaderService: SettingsLoaderService,
              private http: HttpClient,
              private modalService: NgbModal,
              private matDialog: MatDialog) { }

  ngOnInit(): void {
    this.subscription = interval(100 * 1000).pipe(
      mergeMap(() => this.http.get(this.CONFIG_URL, { responseType: 'text', headers: this.headers }).pipe(
        catchError(error => of("-404-")) 
      ))
    ).subscribe(data => {
        this.count++;
        this.checkforNewVersion(data);
    });
  }

  reloadAppClick(): void {
    if ( this.hasOpenDialogs() ) {
      this.showOpenDialogsWarning();
      return
    }

    this.reload();
  }

  private checkforNewVersion(newValue: string): void {
    if (newValue !== "-404-"){
      if (!this.lastModified) {
        this.lastModified = newValue;
      } else{
        if (newValue && this.lastModified !== newValue) {
          this.subscription.unsubscribe();
          this.newVersion = true;
        }
      }
    }
  }

  private showOpenDialogsWarning(): void {
      const modalRef = this.modalService.open(ModalDialogComponent);
      modalRef.componentInstance.configuration = new ModalDialogConfiguration({
        header: 'default-modal-header',
        title: 'reload-open-dialogs-warning-title',
        text: 'reload-open-dialogs-warning-text',
        yes: 'default-modal-dialog-yes',
        no: 'default-modal-dialog-no',
        isBody: true,
        isFooter: true,
      });
      modalRef.componentInstance.closeModalEvent.subscribe(
        emittedEvent => {
          modalRef.close();
          if (emittedEvent) {
            this.reload();
          }
        }
      );
  }

  private reload(): void {
    const whiteList = ['open-cases', 'case-tab-selected'];
    let keyList = Object.keys(sessionStorage);
    keyList.forEach(key => {
      if (!whiteList.includes(key)){
        sessionStorage.removeItem(key)
      }
    })
    window.location.reload();
  }

  private hasOpenDialogs(): boolean {
    return this.matDialog.openDialogs.length != 0;
  }
}
