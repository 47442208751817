<div class="modal-content">
  <div class="modal-header">
    <div class="modal-header-title">
      {{ "medical-assessment" | translate }}
    </div>
    <div class="modal-buttons">
      <div (click)="close()">
        <img src="/assets/icons/Close White.svg" />
      </div>
    </div>
  </div>
  <div class="d-flex no-gutters p-3 assessment">
    <div class="w-25">
      <app-medical-assessment-menu [state]="state" [formValid]="formValid"></app-medical-assessment-menu>
    </div>
    <div class="card-holder w-75">
      <div *ngIf="medicalAssessment && state" class="middle-box">
        <div class="assessment-card" id="basisAssessment">
          <app-medical-assessment-card-title [title]="'medical-regular-assessment-basic-for-assessment' | translate"
            [valid]="state.basisValid"></app-medical-assessment-card-title>
          <div class="assessment-card-content">
            <div *ngFor="let source of medicalAssessment.sourceList; let i = index" class="row mb-3">
              <div class="d-flex w-100 pt-2">
                <div class="w-50 pe-2">
                  <app-drop-down [disabled]="disabledViewOfMedicalAssessment"  [(selectedItemId)]="source.basedOn"
                  [items]="basisSources" [requiredRedBackground]=true
                  [name]="'medical-regular-assessment-based-on' | translate">
                  </app-drop-down>
                </div>
                <div class="w-50 ps-2">
                  <div class="deleteIcon" *ngIf="sourceDeletable() && !disabledViewOfMedicalAssessment" (click)="deleteSource(i)">
                    <span *ngIf="!disabledViewOfMedicalAssessment">Delete <img src="../../../../assets/icons/garbage.svg"></span>
                  </div>
                  <app-medical-assessment-source [disabled]="disabledViewOfMedicalAssessment"
                    [name]="'medical-regular-assessment-source' | translate"
                    [selectedItemId]="resolveSourceId(source)"
                    [required]=true
                    [users]="this.users"
                    (selectedItemIdChange)="updateSourceData($event, source)">
                  </app-medical-assessment-source>
                </div>
            </div>
            <div class="source-spacer"></div>
          </div>
            <button *ngIf="disabledViewOfMedicalAssessment !== true" class="primary-button-white mb-3"
              (click)="addNewSource()">{{'medical-regular-assessment-examination-treatments-add-more' | translate }}</button>
            <div class="small-spacer" *ngIf="state.basisDocuments"></div>
            <app-multiple-choice-component [important]="true" [disabled]="disabledViewOfMedicalAssessment" *ngIf="state.basisDocuments" [items]="documents"
              [title]="'medical-regular-assessment-based-on-medical-report' | translate " (valueChangeEvent)="updateDocument($event)">
            </app-multiple-choice-component>
            <div class="source-spacer" *ngIf="state.basisDocuments && documents?.length > 0 && supoMedReps?.length > 0"></div>
            <div class="small-spacer" *ngIf="state.basisDocuments && documents?.length > 0 && supoMedReps?.length > 0"></div>
            <app-multiple-choice-component [important]="true" [disabled]="disabledViewOfMedicalAssessment" *ngIf="state.basisDocuments" [items]="supoMedReps"
              (valueChangeEvent)="updateSupoMedRep($event)">
            </app-multiple-choice-component>
          </div>
        </div>
        <div class="assessment-card" id="assessmentNote">
          <app-medical-assessment-card-title [title]="'medical-regular-assessment-medical-notes' | translate" [valid]="state.noteValid">
          </app-medical-assessment-card-title>
          <div class="assessment-card-content">
            <div class="diagnosis-symptoms-picker">
              <app-multiple-select [disabled]="disabledViewOfMedicalAssessment"
                [name]="'medical-regular-assessment-main-diagnosis' | translate" [maxElements]="1" [singleValue]="true" [required]=true
                [(selectedItems)]="this.medicalAssessment.note.diagnosis">
              </app-multiple-select>
            </div>

            <div class="small-spacer"></div>

            <div class="diagnosis-symptoms-picker">
              <app-multiple-select [disabled]="disabledViewOfMedicalAssessment"
                [name]="'medical-regular-assessment-reported-symptoms' | translate" [maxElements]="10" [singleValue]="false"
                [(selectedItems)]="this.medicalAssessment.note.symptoms"
                [excludedElements]="findExcludedElement(this.medicalAssessment.note.diagnosis,0)">
              </app-multiple-select>
            </div>
            <div class="small-spacer"></div>

            <div class="assessment-card-content-date">
              <app-input-calendar [withImage]="true" [name]="'medical-regular-assessment-date-of-diagnosis' | translate" [maxDate]="today"
                [date]="this.medicalAssessment.note.date" [disabled]="disabledViewOfMedicalAssessment"
                [required]="!this.medicalAssessment.note.date" (dateChange)="updateDiagnosisDate($event)">
              </app-input-calendar>
            </div>
            <div class="spacer"></div>
            <app-text-box [disabled]="disabledViewOfMedicalAssessment" [maxTextLength]="3000" [largeView]=true [numberOfRows]="10"
              [name]="'medical-regular-assessment-evaluation' | translate" [(value)]="medicalAssessment.note.status" [required]=true
              [placeHolder]="'medical-regular-assessment-medical-status-placeHolder' | translate">
            </app-text-box>

            <div class="spacer"></div>

            <app-text-box [disabled]="disabledViewOfMedicalAssessment" [maxTextLength]="3000" [alternativeColor]="'red'" [mediumView]=true
              [name]="'medical-regular-assessment-internal-note' | translate" [(value)]="medicalAssessment.note.internalNote" [numberOfRows]="5"
              [placeHolder]="'medical-regular-assessment-medical-notes-placeHolder' | translate"></app-text-box>

            <div class="small-spacer"></div>
          </div>
        </div>

        <div class="assessment-card" id="treatmentPrognosis" *ngIf="state.regularAssessment">
          <app-medical-assessment-card-title [title]="'medical-regular-assessment-treatment-prognosis' | translate"
            [valid]="state.treatmentPrognosisValid">
          </app-medical-assessment-card-title>
          <div class="assessment-card-content">
            <app-total-visits [disabled]="disabledViewOfMedicalAssessment" [medicalAssessmentVisits]="medicalAssessment.visits"
              [required]="!state.treatmentPrognosisValid" [header]="'medical-regular-assessment-out-patient-visits-treatments' | translate">
            </app-total-visits>

            <div class="spacer"></div>

            <app-total-days [disabled]="disabledViewOfMedicalAssessment" [medicalAssessmentAdmission]="medicalAssessment.admissionList[0]"
              [header]="'medical-regular-assessment-regular-ward-admission' | translate" [required]="!state.treatmentPrognosisValid">
            </app-total-days>

            <div class="spacer"></div>

            <app-total-days [disabled]="disabledViewOfMedicalAssessment" [medicalAssessmentAdmission]="medicalAssessment.admissionList[1]"
              [header]="'medical-regular-assessment-intensive-care-unit-admission' | translate" [required]="!state.treatmentPrognosisValid">
            </app-total-days>

            <div class="spacer"></div>

            <app-small-yes-no-question [(value)]="medicalAssessment.admissionIsReadmission"
              [header]="'medical-regular-assessment-admission-is-readmission' | translate" [disabled]="disabledViewOfMedicalAssessment">
            </app-small-yes-no-question>

            <div class="small-spacer"></div>

          </div>
        </div>
        <div class="assessment-card" *ngIf="state.regularAssessment" id="examinationTreatments">
          <app-medical-assessment-card-title [title]="'medical-regular-assessment-examination-treatments' | translate">
          </app-medical-assessment-card-title>
          <div class="assessment-card-content">
            <div>
              <span
                class="assessment-card-content-small-header">{{'medical-regular-assessment-examination-acutely-indicated' | translate}}</span>
              <ng-container *ngFor="let medicalAction of medicalAssessment.actionList; let i = index">
                <ng-container *ngIf="medicalAction.examinationType === getMedicalExaminationsTypeEnum().ACUTELY">
                  <app-action-card [disabled]="disabledViewOfMedicalAssessment" [index]="i" [medicalAssessmentAction]="medicalAction"
                    [actionTypes]="medicalActionTypeList"
                    [actionDeleteble]="actionDeleteButtonActive(getMedicalExaminationsTypeEnum().ACUTELY)" (actionDelete)="deleteAction(i);">
                  </app-action-card>
                </ng-container>
              </ng-container>
              <button *ngIf="disabledViewOfMedicalAssessment !== true" class="primary-button-white mb-3"
                (click)="addNewAction(getMedicalExaminationsTypeEnum().ACUTELY)">{{'medical-regular-assessment-examination-treatments-add-more' | translate }}</button>
            </div>
            <div>
              <span
                class="assessment-card-content-small-header">{{'medical-regular-assessment-examination-not-acutely-indicated' | translate}}</span>
              <ng-container *ngFor="let medicalAction of medicalAssessment.actionList  let i = index">
                <ng-container *ngIf="medicalAction.examinationType === getMedicalExaminationsTypeEnum().NOT_ACUTELY">
                  <app-action-card [disabled]="disabledViewOfMedicalAssessment" [index]="i" [medicalAssessmentAction]="medicalAction"
                    [actionTypes]="medicalActionTypeList"
                    [actionDeleteble]="actionDeleteButtonActive(getMedicalExaminationsTypeEnum().NOT_ACUTELY)"
                    (actionDelete)="deleteAction(i);"></app-action-card>
                </ng-container>
              </ng-container>
              <button *ngIf="disabledViewOfMedicalAssessment !== true" class="primary-button-white mb-3"
                (click)="addNewAction(getMedicalExaminationsTypeEnum().NOT_ACUTELY)">{{'medical-regular-assessment-examination-treatments-add-more' | translate }}</button>
            </div>
          </div>
        </div>

        <div class="assessment-card" *ngIf="state.regularAssessment" id="medicalEvacuation">
          <app-medical-assessment-card-title [title]="'medical-regular-assessment-medical-evacuation' | translate"
            [valid]="state.medEvacValid">
          </app-medical-assessment-card-title>
          <div class="assessment-card-content">
            <app-yes-no-question [disabled]="disabledViewOfMedicalAssessment" [header]="'medical-assessment-medical-evacuation' | translate"
              [(value)]="medicalAssessment.medicalEvacuation">
            </app-yes-no-question>
            <div class="small-spacer"></div>
            <div class="assessment-card-content-date">
              <app-input-calendar *ngIf=state.medicalEvacuation [withImage]="true"
                [name]="'medical-assessment-medical-evacuation-arrival-deadline' | translate" [date]="medicalAssessment.medicalEvacuationDate"
                [disabled]="disabledViewOfMedicalAssessment" (dateChange)="updateMedEvacDate($event)"
                [required]="!medicalAssessment.medicalEvacuationDate">
              </app-input-calendar>
              <div class="small-spacer"></div>
            </div>
          </div>
        </div>

        <div class="assessment-card" *ngIf="state.regularAssessment" id="repatriationStatus">
          <app-medical-assessment-card-title [title]="'medical-regular-assessment-repatriation-status' | translate"
            [valid]="state.repatriationStatusValid">
          </app-medical-assessment-card-title>
          <div class="assessment-card-content">
            <app-yes-no-question [disabled]="disabledViewOfMedicalAssessment || state.medicalEvacuation"
              [header]="'medical-regular-assessment-travel-home-as-planned' | translate" [(value)]="medicalAssessment.travelHomeAsPlanned">
            </app-yes-no-question>
            <app-yes-no-question *ngIf="state.notTravelHomeAsPlanned" [(value)]="medicalAssessment.repatriationInstructionsAllowed"
              [disabled]="disabledViewOfMedicalAssessment || state.medicalEvacuation"
              [header]="'medical-regular-assessment-repatriation-instructions-allowed' | translate"></app-yes-no-question>

            <div class="small-spacer"></div>
          </div>
        </div>
        <app-repatriation-prognosis *ngIf="state.repatriationPrognosis" [state]="state"
          [repatriationPrognosis]="medicalAssessment.repatriationPrognosis"
          [disabledViewOfMedicalAssessment]="disabledViewOfMedicalAssessment"></app-repatriation-prognosis>
        <app-repatriation-instructions *ngIf="state.repatriationInstructionsAllowed" (formValidChanged)="updateFormValid($event)"
                                      [repatriationInstructions]="medicalAssessment.repatriationInstructionsBase"
                                      [state]="state" [caseID]="this.caseId" [dateOfDiagnosis]="medicalAssessment.note.date"
                                      [selectedSymptoms]="this.medicalAssessment.note.symptoms"
                                      [selectedDiagnosis]="this.medicalAssessment.note.diagnosis"
                                      [disabledViewOfMedicalAssessment]="disabledViewOfMedicalAssessment"
                                      [users]="users"
                                      [copyOfExistingAssessment]="copyOfExistingAssessment">
        </app-repatriation-instructions>

        <div class="assessment-card" *ngIf="state.regularAssessment" id="nextAssessment">
          <app-medical-assessment-card-title [title]="'medical-assessment-next-assessment' | translate" [valid]="state.nextAssessmentValid">
          </app-medical-assessment-card-title>
          <div class="assessment-card-content" id="nextAssessment">
            <app-yes-no-question [disabled]="disabledViewOfMedicalAssessment" [header]="'medical-assessment-followup-planned' | translate"
              [(value)]="medicalAssessment.followupPlanned"></app-yes-no-question>

            <div class="small-spacer"></div>
            <div *ngIf="state.followUp">
              <div class="assessment-card-content-date">
                <app-input-date-time [name]="'medical-assessment-date-of-followup' | translate" [date]="this.medicalAssessment.followupDate"
                [withImage]="true" [required]="!this.medicalAssessment.followupDate" [minDate]="now"
                [timeZone]="'local'" (dateChange)="updateFollowupDate($event)" [disabled]="disabledViewOfMedicalAssessment"
                ></app-input-date-time>
              </div>
              <div class="small-spacer"></div>
              <app-text-box [maxTextLength]="250" [name]="'medical-assessment-remarks-of-followup' | translate"
                [(value)]="medicalAssessment.followupDescription" [disabled]="disabledViewOfMedicalAssessment">
              </app-text-box>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row no-gutters">
    <div class="col pt-2 ps-3 pe-3 bottom">
      <button class="published primary-button" *ngIf="disabledViewOfMedicalAssessment !== true" [disabled]="!formValid || !state.assessmentValid || storingMedicalAssessment"
        (click)=" publishAssessment()">{{"medical-assessment-publish" | translate}}</button>
      <button class="save primary-button" *ngIf="disabledViewOfMedicalAssessment !== true"
        (click)="saveDraft()">{{"medical-assessment-save-and-close" | translate}}</button>
      <button class="save primary-button" *ngIf="disabledViewOfMedicalAssessment == true"
              (click)="close()">{{"medical-assessment-close" | translate}}</button>
    </div>
  </div>
</div>
