export class GlobalErrorModalSessionObject {

  constructor(init?: Partial<GlobalErrorModalSessionObject>) {
    Object.assign(this, init);
  }

  expiresAt: string;
  suppressErrors: boolean;
  static key: string = 'GLOBAL_ERROR_MODAL';
}
