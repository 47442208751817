<div class="common-drop-down-arrow drop-down">
  <span class="name">{{name}}</span>
  <ng-select 
    #recieverSelect
    (open)="openStakeholderDropdown()"
    [clearable]="false"
    [(ngModel)]="selectedItemId"
    class="custom"
    (change)="onSelectedItemIdChange()"
    [items]="stakeholders"
    [readonly]="message?.receiverIsNotChangable"
    (blur)="recieverSelect.close()"
    bindLabel="fullName">
    <ng-template ng-option-tmp let-item="item" let-index="index">
      <ng-container *ngIf="!item.deactivated">
      <img [src]="getStakeholderIconUrl(item.stakeholderType)">
      <span *ngIf="item.person">
        {{item.person.firstName}} {{item.person.surname}}
      </span>
      <ng-container *ngIf="item.company">
        <span> {{item.company.name}}</span>
        <ng-container *ngIf="item.company.gop">
          <span class="text" *ngIf="item.company.gop.preferredChannel || item.company.gop.preferredLanguage">(</span>
          <span class="text">{{item.company.gop.preferredChannel}} {{item.company.gop.preferredLanguage}}</span>
          <span class="text" *ngIf="item.company.gop.preferredChannel || item.company.gop.preferredLanguage">)</span>
        </ng-container>
      </ng-container>
    </ng-container>
    </ng-template>
    <ng-template ng-label-tmp let-item="item">
      <img [src]="getStakeholderIconUrl(item.stakeholderType)">
      <span *ngIf="!showFullName"> {{ item.stakeholderType | titlecase }}
        <span class="text" *ngIf="item.preferredChannel || item.preferredLanguage">(</span>
        <span class="text">{{item.preferredChannel}} {{item.preferredLanguage}}</span>
        <span class="text" *ngIf="item.preferredChannel || item.preferredLanguage">)</span>
      </span>
      <span *ngIf="showFullName">
        <span *ngIf="item.person">
          {{item.person.firstName}} {{item.person.surname}}
        </span>
        <span *ngIf="item.company"> {{item.company.name}}</span>
      </span>
    </ng-template>
  </ng-select>
</div>
