<div class="sub-component-container" >
  <div class="sub-title ">
      <div class="me-auto p-0 w-50 ">{{"sub-stakeholders-serviceorders-title" | translate}}</div>
  </div>
  <div class="sub-component-container m-3">
    <div class="sub-title sub-title-table">
      <div class="d-flex">
        <div class="me-auto p-0 w-50">{{coordinatedCaseDetail.caseNumber}} - {{ coordinatedCaseDetail.stakeholders[0].person.firstName }} {{ coordinatedCaseDetail.stakeholders[0].person.surname }}, {{ coordinatedCaseDetail.stakeholders[0].person.gender }} {{ coordinatedCaseDetail.stakeholders[0].person.age }}</div>
      </div>
    </div>

    <div class="sub-content-container ">
      <div class="row pb-3">
        <div class="col-6">
          <app-input [name]="'case-basics-incident-current-location' | translate" [(value)]="coordinatedCaseDetail.incidentLocation"
            [disabled]='true'>

          </app-input>
        </div>
        <div class="col-6">
          <ng-container *ngIf='coordinaitonMedicalAssessment'>
            <app-input [name]="'medical-regular-assessment-repatriation-menu-form' | translate"
              [value]="getRepatriationFormName(coordinaitonMedicalAssessment.repatriationInstructionsBase.repatriationForm.form)"
              [disabled]='true'>
            </app-input>
          </ng-container>
        </div>
      </div>
      <app-sub-icc-stakeholders [caseId]='coordinatedCaseDetail.caseId.toString()'
        [stakeholders]="coordinatedCaseDetail.stakeholders"
        [flightStakeholders]='flightStakeholders'
        [savedFlightStakeholders]="savedFlightStakeholders"
        [preselectEndUser]="false"
        [disabled]="false"
        [serviceOrderType]="serviceOrderType"
        [serviceOrdersList]='coordinatedCaseDetail.serviceOrders'
        [iccServiceOrderStatus]="iccServiceOrderStatus"
        [showPassengerConfirmed]="showPassengerConfirmed"
        [caseNumber]="coordinatedCaseDetail.caseNumber"
        >
      </app-sub-icc-stakeholders>
    </div>
  </div>
</div>