import { ServiceTypeBase } from './../../models/serviceTypeBase';
import { Component, Input } from '@angular/core';
import { ServiceTypeComponent } from './../../models/interfaces';
import { GeneralAdviceServiceOrderExtension } from '@secca/shared/models/service-order/general-advice-service-order-extension';

@Component({
  selector: 'app-general-advice',
  templateUrl: './general-advice.component.html',
})
export class GeneralAdviceComponent extends ServiceTypeBase implements ServiceTypeComponent {
  @Input() generalAdvice: GeneralAdviceServiceOrderExtension;
  @Input() leftSideValid = true;
  @Input() caseId: string;

  constructor() {
    super();
  }

  startDateChange(date): void {
    this.generalAdvice.startDate = date;
  }

  isValid(): boolean {
   return this.generalAdvice.isValid();
  }

  getModel(): GeneralAdviceServiceOrderExtension {
    return this.generalAdvice;
  }

}
