import { Injectable } from '@angular/core';
import { RecoveryPayment, RecoveryPaymentAdapter } from '@secca/shared/models/recoveryPayment';
import { DineroObject } from 'dinero.js';
import { Adapter } from '@secca/shared/interfaces/adapter';

export class RecoveryPaymentTab {
  caseRecoveryPayments: RecoveryPayment[];
  totalAppliedAmount: DineroObject;
  totalRecoveredAmount: DineroObject;

  public constructor(init?: Partial<RecoveryPaymentTab>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class RecoveryPaymentTabAdapter implements Adapter<RecoveryPaymentTab> {
  constructor(
    private recoveryPaymentAdapter: RecoveryPaymentAdapter) {
  }

  adapt(item: any): RecoveryPaymentTab {
    return new RecoveryPaymentTab({
      caseRecoveryPayments: !!item.caseRecoveryPayments ? item.caseRecoveryPayments
        .map((recoveryPaymentItem: any) => this.recoveryPaymentAdapter.adapt(recoveryPaymentItem)) : [],
      totalAppliedAmount: item.totalAppliedAmount,
      totalRecoveredAmount: item.totalRecoveredAmount
    });
  }
}
