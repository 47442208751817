import {CoordinationTypeEnum} from '@secca/shared/models/enums';
import { Location } from './location';
import moment from 'moment';
import {Injectable} from "@angular/core";
import {Adapter} from "@secca/shared/interfaces/adapter";
import {DaySerializer} from "@secca/shared/models/day-serializer";

export class MajorIncidentDetails {
  name: string;
  type: CoordinationTypeEnum;
  remarks: string;

  public constructor(init?: Partial<MajorIncidentDetails>) {
    Object.assign(this, init);
  }

}

@Injectable({
  providedIn: 'root'
})
export class MajorIncidentDetailsAdapter implements Adapter<MajorIncidentDetails> {
  adapt(item: any): MajorIncidentDetails {
    return new MajorIncidentDetails({
      name: item.name,
      type: item.type,
      remarks: item.remarks
    });
  }
}
