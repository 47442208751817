<ng-template #content let-modal>
  <div class="comment-container">
    <div class="comment-container-header">
      {{ 'comment-modal-header-title' | translate }}
    </div>
    <div class="input-title-name">{{ 'comment-modal-text-box-title' | translate }}</div>
    <div class="comment-container-comment">
      <textarea class="comment-container-comment-input"
                maxlength="255"
                [(ngModel)]="comment">
      </textarea>
    </div>
    <div class="comment-container-footer">
      <button class="comment-container-footer-button-return" (click)="returnToAccounting()"
              [class.comment-container-footer-button-return-disabled]="!comment"
              [disabled]="!comment">{{'comment-modal-save-btn-title' | translate}}</button>
      <button class="comment-container-footer-button"
              (click)="close()">{{'message-dialog-close-and-discard' | translate}}</button>
    </div>
  </div>
</ng-template>
