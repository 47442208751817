import { AfterViewInit, ContentChild, Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { DropDownCustomerProfileComponent } from '../components/drop-down-customer-profile/drop-down-customer-profile.component';
import { DropDownComponent } from '../components/drop-down/drop-down.component';
import { InputCalenderComponent } from '../components/input-calender/input-calender.component';
import { InputComponent } from '../components/input/input.component';

@Directive({
  selector: '[appInputFocus]'
})
export class InputFocusDirective implements AfterViewInit, OnChanges {
  @ContentChild(InputComponent)
  private inputComp: InputComponent;

  @ContentChild(DropDownComponent)
  private dropDownComp: DropDownComponent;

  @ContentChild(InputCalenderComponent)
  private calendarComp: InputCalenderComponent;

  @ContentChild(DropDownCustomerProfileComponent)
  private customerProfileComp: DropDownCustomerProfileComponent;

  @Input() appInputFocus = false;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setFocus();
  }

  ngAfterViewInit(): void {
    this.setFocus();
  }

  setFocus(): void {
    if ( !this.appInputFocus ) {
      return;
    }

    if ( this.inputComp ) {
      this.inputComp.setFocus();
    } else if ( this.dropDownComp ) {
        this.dropDownComp.setFocus();
    } else if ( this.calendarComp ) {
        this.calendarComp.setFocus();
    } else if ( this.customerProfileComp ) {
      this.customerProfileComp.setFocus();
    }
  }
}
