<div class="sub-component-container" *ngIf="subFuneralHomes">
  <div class="sub-title">{{"funeral-homes-title" | translate}}</div>
  <div class="sub-content-container">
    <div class="row">
      <app-drop-down-with-icon class="col-12" [items]="stakeholdersDropdown" [name]="'local-funeral-home' | translate"
                               (selectedItemIdChange)="setSelectedStakeholder($event, true)" [(selectedItemId)]="subFuneralHomes.localCaseStakeholderId"
                               [disabled]="readOnlyMode"></app-drop-down-with-icon>
    </div>
    <div class="row">
      <app-drop-down-with-icon class="col-12" [items]="stakeholdersDropdown" [name]="'receiving-funeral-home' | translate"
                               (selectedItemIdChange)="setSelectedStakeholder($event, false)" [(selectedItemId)]="subFuneralHomes.receivingCaseStakeholderId"
                               [disabled]="readOnlyMode"></app-drop-down-with-icon>
    </div>
  </div>
</div>
