import {ErrorModalDialogConfiguration} from '../../models/errormodal/modal-error-configuration';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-modal-error-dialog',
  templateUrl: './modal-error-dialog.component.html',
  styleUrls: ['./modal-error-dialog.component.scss']
})

export class ModalErrorDialogComponent {
  @Input() items: string[];
  @Input() configuration: ErrorModalDialogConfiguration;

  @Output() closeModalEvent = new EventEmitter<boolean>();

  showModal = false;

  constructor(public activeModal: NgbActiveModal) {}

  close(close: boolean) {
    this.closeModalEvent.emit(close);
  }
}
