import { Injectable } from '@angular/core';
import { Adapter } from './../interfaces/adapter';

export class Dictionary {
  id: string;
  name: string;

  public constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }
}

@Injectable({
  providedIn: 'root'
})
export class DropdownAdapter implements Adapter<Dictionary> {
  adapt(item: string): Dictionary {
    return new Dictionary(item, item);
  }
}

@Injectable({
  providedIn: 'root'
})

export class TeamDropdownAdapter implements Adapter<Dictionary> {
  adapt(item: any): Dictionary {
    return new Dictionary(item.adtCode, item.teamName);
  }
}
