import { Component, EventEmitter, OnInit, Output, Input, OnChanges, OnDestroy } from '@angular/core';
import { DataService } from '@secca/core/services/data.service';
import { AbstractGlobalBoardTableShortcutsComponent } from '@secca/shared/components/abstract-global-board-table-shortcuts.component';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { ShortcutEnum, SortOrder, HandlingAreaStatusEnum } from '@secca/shared/models/enums';
import { Router } from '@angular/router';
import { TableSort } from '@secca/shared/models/table-sort';
import { SettingsService } from '@secca/core/services/settings.service';
import { PreDepartureTaskViewModel } from '../models/pre-departure-task-view.model';
import { CaseService } from '@secca/core/services/case.service';
import { Subscription } from 'rxjs';
import { DictionaryService } from '@secca/core/services/dictionary.service';
import { UserDto } from '@secca/shared/models/userDto';
import { BoardNavigationStateService } from '../services/board-navigation-state.service';

@Component({
  selector: 'app-board-task-pre-departure-tabel',
  templateUrl: './board-task-pre-departure-tabel.component.html',
  styleUrls: ['./board-task-pre-departure-tabel.component.scss']
})

@AutoUnsubscribe
export class BoardTaskPreDepartureTabelComponent extends AbstractGlobalBoardTableShortcutsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() set taskViewModels(data: PreDepartureTaskViewModel[]) {
    this._taskViewModels = data;
    this.numberOfRows = data?.length;
    setTimeout(() => {
      this.selectedRowIndex = this.boardNavigationStateService.getState('board-case-filter-request-pre-departure-selected-row-index') ? parseInt(this.boardNavigationStateService.getState('board-case-filter-request-pre-departure-selected-row-index'), 10) : 0;
      this.inputScrollPosition = this.boardNavigationStateService.getState('board-case-filter-request-pre-departure-selected-row-index') ? parseInt(this.boardNavigationStateService.getState('board-case-filter-request-pre-departure-selected-row-index'), 10) : 0;
      this.navigateToTable();
    }, 50);
  }
  get taskViewModels(): PreDepartureTaskViewModel[] {
    return this._taskViewModels;
  }

  @Input() taskSorting: TableSort;
  get sortedOn(): any {
    const sortedOn = {};
    if (this.taskSorting != null) {
      sortedOn[this.taskSorting.sortBy] = true;
    }
    return sortedOn;
  }
  get sortOrder(): any {
    const sortOrder = {};
    if (this.taskSorting != null) {
      sortOrder[this.taskSorting.sortBy] = this.taskSorting.sortOrder;
    }
    return sortOrder;
  }

  private _taskViewModels: PreDepartureTaskViewModel[];
  isAllSelected = false;
  inputScrollPosition = 0;
  loggedInUser: UserDto;
  loggedInUserSubscription: Subscription;

  @Output() sortingAction = new EventEmitter<TableSort>();
  @Output() refundMovedEvent = new EventEmitter();

  constructor(private dataService: DataService,
              private router: Router,
              public settingsService: SettingsService,
              private caseService: CaseService,
              private dictionaryService: DictionaryService,
              private boardNavigationStateService: BoardNavigationStateService) {
    super('taskPreDepartureTabel');
    this.loggedInUserSubscription = dictionaryService.getLoggedInUser()
      .subscribe(user => {
          this.loggedInUser = user;
      });
    this.shortcutSubscriptions.push(
      this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateTableRowAltEnter }).subscribe(a => {
        if (Number.isFinite(this.selectedRowIndex)) {
          this.redirectToManageMedical(this.taskViewModels[this.selectedRowIndex].caseId);
        }
      }),
    );
   }

  ngOnInit(): void {
    this.selectedRowIndex = this.boardNavigationStateService.getState('board-case-filter-request-pre-departure-selected-row-index') ? parseInt(this.boardNavigationStateService.getState('board-case-filter-request-pre-departure-selected-row-index'), 10) : 0;
    this.inputScrollPosition = this.boardNavigationStateService.getState('board-case-filter-request-pre-departure-task-scroll-position') ? parseInt(this.boardNavigationStateService.getState('board-case-filter-request-pre-departure-task-scroll-position'), 10) : 0;
  }

  ngOnChanges() {
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.boardNavigationStateService.saveState('board-case-filter-request-pre-departure-task-scroll-position', JSON.stringify(this.inputScrollPosition));
    this.boardNavigationStateService.saveState('board-case-filter-request-pre-departure-selected-row-index', JSON.stringify(this.selectedRowIndex));
  }

  onCaseTabelScroll() {
    this.inputScrollPosition = document.getElementById('taskPreDepartureTabel').scrollTop;
  }

  getBrandImagesUrlForProfile(profileId: string) {
    return this.caseService.getBrandImagesUrlForProfile(profileId);
  }

  redirectToManageMedical(caseId: number) {
    this.dataService.navigateMedical = true;
    this.router.navigate(['case', caseId]);
  }

  selectAllTasks() {
    this.isAllSelected = !this.isAllSelected;
    this.taskViewModels.forEach(a => (a.isSelected = this.isAllSelected));
  }

  sortedBy(column: string): string {
    return this.sortedOn[column] ? 'sorted-by' : '';
  }

  get HandlingAreaStatusEnum() {
    return HandlingAreaStatusEnum;
  }

  sortBy(column: string) {
    const sortOrder = {};
    if (this.sortOrder[column] == null) {
      sortOrder[column] = SortOrder.asc;
    } else {
      if (this.sortOrder[column] === SortOrder.asc) {
        sortOrder[column] = SortOrder.desc;
      } else {
        sortOrder[column] = SortOrder.asc;
      }
    }
    this.sortingAction.emit(new TableSort({ sortBy: column, sortOrder: sortOrder[column] }));
  }

  assigneeShortName(assignInitials: string): string {
    return this.caseService.teamNameShortName(assignInitials);
  }
}
