import { Adapter } from '../interfaces/adapter';
import { Injectable } from '@angular/core';
import { MedicalAssessmentBasedOnEnum, MedicalHistoryType, AssessmentTypeEnum, MedicalNoteTypeEnum, MedicalPreAssessmentDecisionEnum } from './enums';
import * as moment from 'moment';
import { DateSerializer } from './date-serializer';

export class MedicalHistory {
  id: number;
  medicalHistoryType: MedicalHistoryType;
  assessmentBaseUpon: MedicalAssessmentBasedOnEnum;
  sourceText: string;
  diagnosis: string;
  diagnosisItems: MedicalHistoryIcd10Dto[];
  createdOn: moment.Moment;
  createdBy: string;
  deletedOn: moment.Moment;
  userInfo: MedicalHistoryUserDto;
  idx: number;
  medicalAssessmentType: AssessmentTypeEnum;
  medicalNoteText: string;
  medicalNoteType: MedicalNoteTypeEnum;
  repatriationInstructions: boolean;
  repatriationPrognosis: boolean;
  nextAssessmentDate: moment.Moment;
  note: MedicalAssessmentNoteDto;
  reportSupplierPortal: MedicalReportSupplierPortalDto;
  connectedServiceOrderIds: string[];
  evaluation: string;
  descriptionToEndUser: string;
  evaluationDecision: string;
  decision: MedicalPreAssessmentDecisionEnum;





  public constructor(init?: Partial<MedicalHistory>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
export class MedicalHistoryAdapter implements Adapter<MedicalHistory> {
  adapt(item: any): MedicalHistory {
    const medicalHistoryIcd10DtoAdapter = new MedicalHistoryIcd10DtoAdapter();
    const medicalAssessmentNoteDtoAdapter = new MedicalAssessmentNoteDtoAdapter();
    const medicalReportSupplierPortalDtoAdapter = new MedicalReportSupplierPortalDtoAdapter();

    return new MedicalHistory({
      id: item.id,
      medicalHistoryType: item.medicalHistoryType,
      assessmentBaseUpon: item.assessmentBaseUpon,
      sourceText: item.sourceText,
      diagnosis: item.diagnosis,
      diagnosisItems: item.diagnosisItems === null ? null : item.diagnosisItems.map(medicalHistoryIcd10DtoAdapter.adapt),
      createdOn: DateSerializer.deserialize(item.createdOn),
      createdBy: item.createdBy,
      userInfo: {
        userFullName: item.userInfo.userFullName,
        userId: item.userInfo.userId,
        userActive: item.userInfo.userActive,
        imageIsPresent: item.userInfo.imageIsPresent,
      },
      medicalAssessmentType: item.medicalAssessmentType,
      medicalNoteText: item.medicalNoteText,
      medicalNoteType: item.medicalNoteType,
      repatriationInstructions: item.repatriationInstructions,
      repatriationPrognosis: item.repatriationPrognosis,
      nextAssessmentDate: DateSerializer.deserialize(item.nextAssessmentDate),
      note: item.note === null ? null : medicalAssessmentNoteDtoAdapter.adapt(item.note),
      reportSupplierPortal: item.reportSupplierPortal == null ? null : medicalReportSupplierPortalDtoAdapter.adapt(item.reportSupplierPortal),
      deletedOn: item.deletedOn,
      evaluation: item.evaluation,
      descriptionToEndUser: item.descriptionToEndUser,
      evaluationDecision: item.evaluationDecision,
      decision: item.decision
    });
  }
}

export class MedicalHistoryIcd10Dto {
  idc10Code: string;
  idc10Description: string;
  public constructor(init?: Partial<MedicalHistoryIcd10Dto>) {
    Object.assign(this, init);
  }
}

export class MedicalHistoryIcd10DtoAdapter implements Adapter<MedicalHistoryIcd10Dto> {
  adapt(item: any): MedicalHistoryIcd10Dto {
    return new MedicalHistoryIcd10Dto({
      idc10Code: item.idc10Code,
      idc10Description: item.idc10Description,
    });
  }
}
export class MedicalHistoryUserDto {
  userFullName: string;
  userId: number;
  userActive: boolean;
  imageIsPresent: boolean;
  public constructor(init?: Partial<MedicalHistoryUserDto>) {
    Object.assign(this, init);
  }
}

export class MedicalAssessmentNoteDto {
  date: moment.Moment;
  status: string;
  internalNote: string;
  public constructor(init?: Partial<MedicalAssessmentNoteDto>) {
    Object.assign(this, init);
  }
}

export class MedicalAssessmentNoteDtoAdapter implements Adapter<MedicalAssessmentNoteDto> {
  adapt(item: any): MedicalAssessmentNoteDto {
    return new MedicalAssessmentNoteDto({
      date: DateSerializer.deserialize(item.date),
      status: item.status,
      internalNote: item.internalNote,
    });
  }
}

export class MedicalReportSupplierPortalDto {
  id: number;
  diagnosisAndSymptoms: string;
  mainDiagnosis: string;
  treatment: string;
  submitted: moment.Moment;
  caseStakeholderId: number;
  taskId: number;
  public constructor(init?: Partial<MedicalReportSupplierPortalDto>) {
    Object.assign(this, init);
  }
}
export class MedicalReportSupplierPortalDtoAdapter implements Adapter<MedicalReportSupplierPortalDto> {
  adapt(item: any): MedicalReportSupplierPortalDto {
    return new MedicalReportSupplierPortalDto({
      id: item.id,
      diagnosisAndSymptoms: item.diagnosisAndSymptoms,
      mainDiagnosis: item.mainDiagnosis,
      treatment: item.treatment,
      submitted: DateSerializer.deserialize(item.submitted),
      caseStakeholderId: item.caseStakeholderId,
      taskId: item.taskId,
    });
  }
}