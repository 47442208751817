import { InputTypePattern, ValidationTypeEnum, InputType } from './../../models/enums';
import { OnSaveData } from './../../interfaces/on-save-data';
import { Component, Output, OnInit, Input, ViewEncapsulation, EventEmitter, ApplicationRef, ViewChild, ElementRef } from '@angular/core';
import { InputValidationService } from './../../../core/services/input-validation.service';
import { SettingsService } from '@secca/core/services/settings.service';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InputComponent implements OnInit, OnSaveData {
  @ViewChild('input', { static: true })
  inputComp: ElementRef;

  @Input() required: boolean;
  @Input() requiredExternalTrigger: boolean;
  @Input() placeholder: string;
  @Input() name: string;
  @Input() withImage: boolean;
  @Input() value: any;
  @Input() disabled: boolean;
  @Input() validate: boolean;
  @Input() validationType: ValidationTypeEnum;
  @Input() isDate: boolean;
  @Input() isValid: boolean;
  @Input() maskPattern: string;
  @Input() maxTextLength: number = 100000;
  @Input() inputTypePattern: InputTypePattern = InputTypePattern.all;
  @Input() showEye: boolean;
  @Input() inputType: InputType = InputType.text;
  @Input() recommended: boolean;
  @Input() highlightBorderOnInputNonEmpty = false;

  @Output() valueChange = new EventEmitter();
  @Output() saveModel = new EventEmitter();
  @Output() imageClicked = new EventEmitter();

  isRequired: boolean;

  hideValue: boolean = true;
  oldValue: string;
  dateValue: Date;
  isProd: boolean;

  monthNames = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

  constructor(private inputValidationService: InputValidationService, private applicationRef: ApplicationRef,
              public settingsService: SettingsService) {
    this.isProd = settingsService.isProduction();
    if (this.placeholder === undefined) {
      this.placeholder = '';
    }
  }

  showHideValue() {
    this.hideValue = !this.hideValue;
  }

  onChange() {
    this.hideValue = false;
    this.emitChangeModel();
    this.isRequired = (this.required && (this.value === undefined || this.value === ''));
  }

  get InputTypePattern() {
    return InputTypePattern;
  }

  public formatDate(newValue: Date) {
    if (newValue === null) {
      this.value = null;
      this.dateValue = null;
      return;
    }
    this.value = newValue;
    this.dateValue = new Date(this.value);
    this.value = this.convertDateToString(this.dateValue);
  }

  public getDateAsString(): any {
    if (this.dateValue === null) {
      return null;
    }
    return (
      this.dateValue.getFullYear() +
      '-' +
      ('0' + (this.dateValue.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + this.dateValue.getDate()).slice(-2)
    );
  }

  public getDate(): any {
    return this.dateValue;
  }

  onSaveModel() {
    if (this.oldValue !== this.value || this.isDate) {
      if (this.isDate && this.dateValue !== this.value) {
        this.dateValue = this.convertStringToDate(this.value);
        this.emitSaveModel();
        this.hideValue = true;
        return;
      }
      this.hideValue = true;
      this.emitSaveModel();
    }
  }

  onSaveState() {
    this.oldValue = this.value;
  }

  convertDateToString(val: Date): string {
    if (val != null && typeof val.getMonth === 'function') {
      const result = val.getDate().toString() + ' ' + this.monthNames[val.getMonth()] + ' ' + val.getFullYear().toString();
      return result;
    } else {
      return '';
    }
  }

  convertDateToJSONString(val: Date): string {
    if (val != null) {
      const result = val.getFullYear().toString() + '-' + val.getMonth().toString() + '-' + val.getDate().toString();
      return result;
    } else {
      return '';
    }
  }

  convertStringToDate(val: string): Date {
    if (val != null) {
      try {
        // dd MM yyyy
        // dd MMM yyyy
        let data = val.toString().split(' ');
        if (val.length === 8) {
          // ddMMyyyy
          data = [val.substr(0, 2), val.substr(2, 2), val.substr(4, 4)];
        }
        return new Date(
          parseInt(data[2]),
          this.monthNames.indexOf(data[1].toLowerCase()) >= 0 ? this.monthNames.indexOf(data[1].toLowerCase()) : parseInt(data[1]) - 1,
          parseInt(data[0])
        );
      } catch (error) {
        console.log(error);
        return null;
      }
    } else {
      return null;
    }
  }

  isValidated(val: any) {
    return this.isValid === undefined ? this.inputValidationService.isValidated(val, this.validationType) : !this.isValid;
  }

  ngOnInit() {
    if (!this.value) {
      this.hideValue = false;
    }

    if (this.maxTextLength === undefined) {
      this.maxTextLength = 100000;
    }

    this.isRequired = this.required && (this.value === undefined || this.value === '');
  }

  setFocus(): void {
    this.inputComp.nativeElement.focus();
  }

  get validationClass() {
    if (this.isValidated(this.value)) {
      switch (this.validationType) {
        case ValidationTypeEnum.required:
          return 'required-input';
        case ValidationTypeEnum.empty:
          return 'invalid-input';
        case ValidationTypeEnum.email:
          return 'invalid-input';
        case ValidationTypeEnum.emailAndValid:
          return this.value ? 'invalid-email-input' : 'invalid-input';
        case ValidationTypeEnum.emailAndValidOrEmpty:
          return this.value ? 'invalid-email-input' : '';
        case ValidationTypeEnum.numbers:
          return 'invalid-input';
        default:
          return 'input';
      }
    }
  }

  private emitChangeModel() {
    this.valueChange.emit(this.value);
  }

  private emitSaveModel() {
    this.saveModel.emit(this.value);
  }
}
