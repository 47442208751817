<app-environment-ribbon></app-environment-ribbon>

<div fxLayout="row">
  <div fxFlex="100" style="background-color: #CDE6F5; height: 5px;"></div>
</div>
<div class="h-100" fxLayout="row">
  <div fxFlex="1920px">
    <div fxLayout="column">
      <div fxLayout="row">
        <div fxFlex="250px"><app-top-menu></app-top-menu></div>
        <div fxFlex="1595px">
<!--          <div *ngIf="showSnow()" class="snow-container">-->
<!--            <div class="inner-snow-container">-->
<!--              <div class="snow"></div>-->
<!--            </div>-->
<!--          </div>-->
          <app-top-tabs></app-top-tabs>
        </div>
        <div fxFlex="75px"><app-sign-out></app-sign-out></div>
      </div>
      <div style="background-color: inherit">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <div fxFlex="100" style="background-color: #CDE6F5;"></div>
</div>

<app-footer *ngIf="!isProduction"></app-footer>
<app-reload-footer></app-reload-footer>
