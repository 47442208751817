<div class="recovery-payment-container" [ngClass]="{'disabled': !hasAccess()}">
  <div class='d-flex justify-content-between modal-header background-colour'>
    <div class='modal-header-title'>
      {{ 'case-economy-manage-recovery-payment-title' | translate }}
    </div>
    <div class='modal-buttons'>
      <div (click)='close()'>
        <img ngSrc='/assets/icons/Close White.svg' alt='Close window' height="30" width="30" />
      </div>
    </div>
  </div>
  <div class="top-row">
    <div>
      <div>{{ 'manage-recovery-payment-invoice-number' | translate }}</div>
      <div>{{ recoveryPayment.invoiceNumber}}</div>
    </div>
    <div>
      <div>{{ 'manage-recovery-payment-creditor-id' | translate }}</div>
      <div>{{ recoveryPayment.creditorId}}</div>
    </div>
    <div>
      <div>{{ 'manage-recovery-payment-creditor-name' | translate }}</div>
      <div class="creditor-box">
        <div>{{ recoveryPayment.creditorName}}</div>
        <img ngSrc="/assets/icons/Info.svg"
             [ngbPopover]="creditorInfo"
             popoverClass="economy-creditor-info-popover"
             alt="creditor-info"
             height="25"
             width="25" />
      </div>
    </div>
  </div>
  <div class="recovery-payment-table-container">
    <table class="recovery-payment-table">
      <thead>
        <tr>
          <th class="col-recovery-id">{{ 'manage-recovery-payment-item-recovery-id' | translate }}</th>
          <th class="col-item">
            <div>
              {{ 'manage-recovery-payment-item-item' | translate }}
              <span >
                    <div class="add-item-button" *ngIf="!isApproved()" (click)="addItem()">
                      {{ 'economy-supplier-invoice-add-item' | translate }} <img
                      src="/assets/icons/add-new.svg" alt="Add supplier invoice item" />
                    </div>
                  </span>
            </div>
          </th>
          <th class="col-amount">{{ 'manage-recovery-payment-item-amount-vat' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let item of recoveryPayment.items">
          <app-recovery-payment-item class="recovery-payment-item"
                                     (removePaymentItem)='itemRemoved(item)'
                                     (changePaymentItem)='itemChanged()'
                                     [recoveryPaymentItem]='item'
                                     [paymentCurrency]='recoveryPayment.amountLocal.currency'
                                     [firstItem]='isFirstItem(item)' [approved]='isApproved()'
                                     [serviceItemOptions]='serviceItemOptions'
                                     [recoveryOptions]='recoveryOptions'
                                     [disabled]="!hasAccess()"/>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div class="bottom-row">
    <div class="bottom-grid">
      <div class="boxes">
        <div>
          <div>{{ 'manage-recovery-payment-invoice-date' | translate }}</div>
          <div>{{ recoveryPayment.receivedDate | date: 'dd LLL yyyy' }}</div>
        </div>
        <div>
          <div>{{ 'manage-recovery-payment-due-date' | translate }}</div>
          <div>{{ recoveryPayment.dueDate | date: 'dd LLL yyyy' }}</div>
        </div>
      </div>
      <div class="boxes-paid">
        <recovery-payment-paid-box
          currency="{{ recoveryPayment.amountLocal.currency}}"
          invoicedAmount="{{ recoveryPayment.amountLocal.amount | number: '.2-2' }}"
        />
        <recovery-payment-paid-box
          currency="{{ recoveryPayment.amountExchanged.currency}}"
          invoicedAmount="{{ recoveryPayment.amountExchanged.amount | number: '.2-2' }}"
        />
      </div>
    </div>
    <div class="box-internal-remarks">
      <div>{{ 'manage-recovery-payment-int-remark' | translate }}</div>
      <textarea cols="60" maxlength="1000"
                [(ngModel)]="recoveryPayment.returnRemark">
          </textarea>
    </div>
  </div>
  <div class="button-actions">
    <button class="default-button-white" *ngIf="false" [ngClass]="{ 'default-button-white-disabled': isApproved() }" [disabled]="isApproved() || !hasAccess()"(click)="rejectRecoveryPayment()">{{ 'manage-recovery-payment-reject' | translate }}</button>
    <button class="default-button-white" *ngIf="false" [ngClass]="{ 'default-button-white-disabled': isApproved() }" [disabled]="isApproved() || !hasAccess()" (click)="returnRecoveryPaymentToAccounting()">{{ 'manage-recovery-payment-return' | translate }}</button>
    <button class="default-button-white" (click)="saveRecoveryPayment()" [disabled]='!hasAccess()'>{{ 'manage-recovery-payment-save' | translate }}</button>
    <button class="default-button" [ngClass]="{ 'default-button-disabled': isApproved() || isInvalid() || !hasAccess()}" [disabled]="isApproved() || !hasAccess() || isInvalid()" (click)="approveRecoveryPayment()">{{ 'manage-recovery-payment-approve' | translate }}</button>
  </div>
</div>
<ng-template #creditorInfo>
  <div class="row">
    <div class="col-3"><b>{{ 'economy-creditor-popover-creditor-id' | translate }}:</b></div>
    <div class="col">{{ creditor.erpId }}</div>
  </div>
  <div class="row">
    <div class="col-3"><b>{{ 'economy-creditor-popover-name' | translate }}:</b></div>
    <div class="col">{{ creditor.name }}</div>
  </div>
  <div class="row">
    <div class="col-3"><b>{{ 'economy-creditor-popover-alias' | translate }}:</b></div>
    <div class="col">{{ creditor.alias }}</div>
  </div>
  <div class="row">
    <div class="col-3"><b>{{ 'economy-creditor-popover-iban' | translate }}:</b></div>
    <div class="col">{{ creditor.iban }}</div>
  </div>
  <div class="row">
    <div class="col-3"><b>{{ 'economy-creditor-popover-swift' | translate }}:</b></div>
    <div class="col">{{ creditor.swift }}</div>
  </div>
  <div class="row">
    <div class="col-3"><b>{{ 'economy-creditor-popover-account' | translate }}:</b></div>
    <div class="col">{{ creditor.bankAccount }}</div>
  </div>
  <div class="row">
    <div class="col-3"><b>{{ 'economy-creditor-popover-currency' | translate }}:</b></div>
    <div class="col">{{ creditor.currency }}</div>
  </div>
  <div class="row">
    <div class="col-3"><b>{{ 'economy-creditor-popover-country' | translate }}:</b></div>
    <div class="col">{{ creditor.countryCode }}</div>
  </div>
  <div class="row">
    <div class="col-3"><b>{{ 'economy-creditor-popover-terms' | translate }}:</b></div>
    <div class="col">{{ creditor.paymentTermsDesc }}</div>
  </div>
  <div class="row">
    <div class="col-3"><b>{{ 'economy-creditor-popover-active' | translate }}:</b></div>
    <div class="col">{{ creditor.erpActive }}</div>
  </div>
  <div class="row">
    <div class="col-3"><b>{{ 'economy-creditor-popover-vat-number' | translate }}:</b></div>
    <div class="col">{{ creditor.vatRegNo }}</div>
  </div>
  <div class="row">
    <div class="col-3"><b>{{ 'economy-creditor-popover-segment' | translate }}:</b></div>
    <div class="col">{{ creditor.segmentDesc }}</div>
  </div>
  <div class="row">
    <div class="col-3"><b>{{ 'economy-creditor-popover-emails' | translate }}:</b></div>
    <div class="col">{{ creditor.emails }}</div>
  </div>
</ng-template>
