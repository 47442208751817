import { DataService } from 'src/app/core/services/data.service';
import { TaskChangeStatusService } from './services/task-change-status.service';
import { PermissionEnum, ShortcutEnum } from './../../../../shared/models/enums';
import { CaseValidationService } from './../../../../core/services/case-validation.service';
import { TaskService } from './../../../../core/services/task.service';
import { Component, TemplateRef, ViewChild, OnInit, Input, OnDestroy } from '@angular/core';
import { AutoUnsubscribe } from '../../../../shared/decorators/auto-unsubscribe';
import { NgbModal, NgbNav, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { TaskStateService } from '../../../board/components/board-task/services/task-state.service';
import { BoardTeamCaringService } from '../../../board/components/board-team-caring/board-team-caring.service';
import { TaskStatus, SortOrder } from '../../../../shared/models/enums';
import { CaseStateService } from '../../../../core/state-services/case-state.service';
import { TaskBoardDatesRangeService } from './services/task-board-dates-range.service';
import { DatesRange } from './models/DatesRange';
import { TaskCaseViewOptions } from 'src/app/shared/models/task-case-view-options';
import { PendingTasksListUpdateService } from './services/pending-tasks-list-update.service';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import { PermissionService } from '@secca/core/services/permission.service';
import { DialogViewerService } from '@secca/core/services/dialog-viewer.service';
import { TaskViewModel } from '@secca/board/components/board-task/models/task-view.model';
import { SimpleTaskViewModel } from './models/simple-task-view.model';
import { TaskModalModeEnum } from './task-modal-static/task-modal-static-mode.enum';
import { CaseValidation } from '@secca/shared/models/caseValidation';
import { Subscription } from 'rxjs';
import { CaseSelectedMenuEnum } from '@secca/shared/enums/case-selected-menu-enum.component';
import { MenuService } from '@secca/core/services/menu.service';
import { ShortcutService } from '@secca/core/services/shortcut.service';

@Component({
  selector: 'app-case-task',
  templateUrl: './case-task.component.html',
  styleUrls: ['./case-task.component.scss'],
  providers: [TaskBoardDatesRangeService],
})
@AutoUnsubscribe
export class CaseTaskComponent implements OnInit, OnDestroy {
  @ViewChild('taskReassignModalContent') taskReassignModalContent: TemplateRef<any>;
  @ViewChild('nav') ngbTabset: NgbNav;
  @Input() set caseId(caseId: string) {
    if (caseId != null && caseId !== this.caseId) {
      this._caseId = caseId;
      this.initializeTaskCaseData();
    }
  }
  get caseId(): string {
    return this._caseId;
  }

  _caseId: string
  toDoSimpleTaskViewModelsForCase: SimpleTaskViewModel[];
  filteredToDoSimpleTaskViewModelsForCase: SimpleTaskViewModel[];
  doneSimpleTaskViewModelsForCase: SimpleTaskViewModel[];
  filteredSimpleDoneTaskViewModelsForCase: SimpleTaskViewModel[];
  isBoardTeamCaringShow: boolean;
  datesRange: DatesRange;
  activeTab: TabsEnum = TabsEnum.TO_DO;
  dateTimePlaceholder = 'dd mm yyyy - hh:mm';
  disableTaskButton = true;
  mouseOverIcon = false;
  caseValidation: CaseValidation;
  $subscr2;
  $subscr5;
  $subscr7;
  $refreshTasksSubscr: Subscription;

  private selectedMenuId: number;
  private shortcutSubscription: Subscription;

  constructor(
    private modalService: NgbModal,
    private taskStateService: TaskStateService,
    private boardTeamCaringService: BoardTeamCaringService,
    private caseStateService: CaseStateService,
    private taskBoardDatesService: TaskBoardDatesRangeService,
    private taskService: TaskService,
    private pendingTasksListUpdateService: PendingTasksListUpdateService,
    private caseValidationService: CaseValidationService,
    private taskChangeStatusService: TaskChangeStatusService,
    private dataService: DataService,
    public caseLockHelperService: CaseLockHelperService,
    public permissionService: PermissionService,
    private dialogViewerService: DialogViewerService,
    private menuService: MenuService,
    private shortcutService: ShortcutService
  ) {
    this.datesRange = new DatesRange();
    this.taskChangeStatusService.getTaskChangeStatus().subscribe(() => {
      this.refreshTasksClick(TaskStatus.toDo);
      this.refreshTasksClick(TaskStatus.done);
    });
    this.taskBoardDatesService.getDate().subscribe(datesRange => {
      if (datesRange != null) {
        this.datesRange = datesRange;
        if (this.toDoSimpleTaskViewModelsForCase != null) {
          this.loadToDoTasksOnDemand(this.toDoSimpleTaskViewModelsForCase);
        }
        if (this.doneSimpleTaskViewModelsForCase != null) {
          this.loadDoneTasksOnDemand(this.doneSimpleTaskViewModelsForCase);
        }
      }
    });
    this.$subscr2 = this.boardTeamCaringService.getShowBoardTeamCaring().subscribe(result => (this.isBoardTeamCaringShow = result));
    this.$subscr7 = this.taskStateService.refreshAllTasksAndPendingNumberOfTasks.subscribe(result => {
      this.taskStateService.getToDoTaskViewModelForCase(this.caseId).subscribe(tasks => {
        this.loadToDoTasksOnDemand(tasks);
      },
        error => console.log(error));
      this.pendingTasksListUpdateService.setPendingTasksHasChange();
      this.taskStateService.getDoneTaskViewModelForCase(this.caseId).subscribe(tasks => {
        this.loadDoneTasksOnDemand(tasks);
      }, error => console.log(error)
      );
    });
    this.$subscr5 = this.dataService.getIsCaseLoading().subscribe(result => {
      if (result) {
        this.disableTaskButton = true;
      }
    });

    this.$refreshTasksSubscr = this.taskStateService.getRefreshTasks().subscribe(res => {
      if (this.activeTab === TabsEnum.TO_DO) {
        this.refreshTasksClick(TaskStatus.toDo);
      } else if (this.activeTab === TabsEnum.DONE) {
        this.refreshTasksClick(TaskStatus.done);
      }
    });

    // this.shortcutSubscription = this.shortcutService.addShortcut({ keys: ShortcutEnum.AddTask }).subscribe(a => {
    //   this.selectedMenuId = this.menuService.getSelectedMenuId(parseInt(this.caseId, 10));
    //   if (this.selectedMenuId === CaseSelectedMenuEnum.CaseTask) {
    //     if (this.enableTaskButton) {
    //       this.openCreateTask();
    //     }
    //   }
    // });
  }

  ngOnInit() {
    if (
      this.taskService.taskRedirectFromGlobalTaskBoard != null &&
      this.taskService.taskRedirectFromGlobalTaskBoard.taskStatus === TaskStatus.done
    ) {
      this.activeTab = TabsEnum.DONE;
      this.triggerDoneTasksLoad();
    }
    this.isCaseValid();
  }

  ngOnDestroy(): void {
//    this.shortcutSubscription.unsubscribe();
  }

  initializeTaskCaseData(){
    this.isCaseValid();
    this.doneSimpleTaskViewModelsForCase = null;
    if (this.taskService.taskCaseViewOptions == null || this.taskService.taskCaseViewOptions.caseId !== + this.caseId) {
      this.taskService.taskCaseViewOptions = new TaskCaseViewOptions({
        caseId: + this.caseId,
        toDoSortColumnName: 'dueDate',
        toDoSortOrder: SortOrder.asc,
        doneSortColumnName: 'dueDate',
        doneSortOrder: SortOrder.asc,
      });
    } else {
      this.taskBoardDatesService.setDatesRange(
        new DatesRange({
          fromDate: this.taskService.taskCaseViewOptions.toDoDateFrom,
          toDate: this.taskService.taskCaseViewOptions.toDoDateTo,
        })
      );
    }
    this.taskStateService.getToDoTaskViewModelForCase(this.caseId).subscribe(tasks => {
      if (tasks != null) {
        this.loadToDoTasksOnDemand(tasks);
      }
    });
    this.taskStateService.getDoneTaskViewModelForCase(this.caseId).subscribe(tasks => {
      if (tasks != null) {
        this.loadDoneTasksOnDemand(tasks);
      }
    });
  }

  get activeTabId(): TabsEnum {
    return this.activeTab;
  }

  get TaskStatus() {
    return TaskStatus;
  }

  isCaseValid() {
    this.caseValidationService.isCaseValid(this.caseId).subscribe(result => {
      this.caseValidation = result;
    });
  }

  enableTaskButton() {
    if (!this.caseValidation){
      return false;
    }
    return this.caseValidation.caseBeenValidated && !(this.caseStateService.isCaseClosed || this.caseStateService.isInvoiceClosedWithNoInvoicingToCustomer) || (!this.caseValidation.caseBeenValidated && this.caseValidation.caseValidateList.length === 0);
  }

  openCreateTask() {
    if (!this.caseValidation.caseBeenValidated && this.caseValidation.caseValidateList.length !== 0) {
      return;
    }

    const taskViewModel = new TaskViewModel({caseId: +this.caseId});
    this.dialogViewerService.openTaskDialog(taskViewModel);
  }

  get TaskModalModeEnum() {
    return TaskModalModeEnum;
  }

  private loadToDoTasksOnDemand(simpleTaskViewModels: SimpleTaskViewModel[]) {
    this.toDoSimpleTaskViewModelsForCase = simpleTaskViewModels;
    this.filteredToDoSimpleTaskViewModelsForCase = this.filterTasksByDatesRange(simpleTaskViewModels);
    this.sortToDoTasks();
  }

  get tasksToReassign() {
    if (this.filteredToDoSimpleTaskViewModelsForCase != null) {
      return this.filteredToDoSimpleTaskViewModelsForCase.filter(a => a.isCheck);
    } else {
      return null;
    }
  }

  getCaseValidationText() {
    this.caseValidationService.isCaseValid(this.caseId).subscribe(result => {
      this.caseValidation = result;
    });
  }

  private sortToDoTasks() {
    this.sortTasks(
      this.filteredToDoSimpleTaskViewModelsForCase,
      this.taskService.taskCaseViewOptions.toDoSortColumnName,
      this.taskService.taskCaseViewOptions.toDoSortOrder
    );
  }

  private sortTasks(simpleTaskViewModels: SimpleTaskViewModel[], columnName: string, sortOrder: SortOrder) {
    let comparisonCondition: number = sortOrder === SortOrder.desc ? -1 : 1;
    if (columnName === 'dueDate') {
      simpleTaskViewModels.sort((a, b) =>
        new Date(a.dueDate.valueOf()) < new Date(b.dueDate.valueOf()) ? -comparisonCondition : comparisonCondition
      );
    } else if(columnName === 'prioritySort') {
      simpleTaskViewModels.sort((a, b) => (a.prioritySort > b.prioritySort ? 1 : -1))
    }
     else {
      simpleTaskViewModels.sort((a, b) => comparisonCondition * a[columnName].localeCompare(b[columnName]));
    }
  }

  private sortDoneTasks() {
    this.sortTasks(
      this.filteredSimpleDoneTaskViewModelsForCase,
      this.taskService.taskCaseViewOptions.doneSortColumnName,
      this.taskService.taskCaseViewOptions.doneSortOrder
    );
  }

  private loadDoneTasksOnDemand(simpleTaskViewModels: SimpleTaskViewModel[]) {
    this.doneSimpleTaskViewModelsForCase = simpleTaskViewModels;
    this.filteredSimpleDoneTaskViewModelsForCase = this.filterTasksByDatesRange(simpleTaskViewModels);
    this.sortDoneTasks();
  }

  private filterTasksByDatesRange(allTasksSimpleViewModel: SimpleTaskViewModel[]): SimpleTaskViewModel[] {
    if (this.datesRange != null) {
      if (this.datesRange.fromDate != null && this.datesRange.toDate == null) {
        return allTasksSimpleViewModel.filter(task => {
          return task.dueDate.valueOf() > new Date(this.datesRange.fromDate).getTime();
        });
      } else if (this.datesRange.fromDate == null && this.datesRange.toDate != null) {
        return allTasksSimpleViewModel.filter(task => task.valueOf() < new Date(this.datesRange.toDate).getTime());
      } else if (this.datesRange.fromDate != null && this.datesRange.toDate != null) {
        return allTasksSimpleViewModel.filter(
          task => task.dueDate.valueOf() > new Date(this.datesRange.fromDate).getTime() && task.dueDate.valueOf() < new Date(this.datesRange.toDate).getTime()
        );
      }
    }

    return allTasksSimpleViewModel;
  }

  get numberOfReassignedTasksToDo(): number {
    if (this.toDoSimpleTaskViewModelsForCase == null) {
      return 0;
    } else {
      return this.toDoSimpleTaskViewModelsForCase.filter(a => a.isCheck).length;
    }
  }

  get numberOfReassignedTasksDone(): number {
    if (this.doneSimpleTaskViewModelsForCase == null) {
      return 0;
    } else {
      return this.doneSimpleTaskViewModelsForCase.filter(a => a.isCheck).length;
    }
  }

  refreshTasksClick(taskStatus: TaskStatus) {
    if (this.caseId == null || taskStatus == null) {
      return;
    }
    if (TaskStatus.toDo === taskStatus) {
      this.filteredToDoSimpleTaskViewModelsForCase = null;
      if (this.$subscr5 != null) {
        this.$subscr5.unsubscribe();
      }
      this.$subscr5 = this.taskStateService.getToDoTaskViewModelForCase(this.caseId).subscribe(tasks => {
        if (tasks != null) {
          this.loadToDoTasksOnDemand(tasks);
          this.pendingTasksListUpdateService.setPendingTasksHasChange();
        }
      });
    }
    if (TaskStatus.done === taskStatus) {
      this.filteredSimpleDoneTaskViewModelsForCase = null;
      this.triggerDoneTasksLoad();
    }
  }

  onStartDateAndTimeChange(date: any, taskStatus: TaskStatus) {
    if (date == null) {
      return;
    }
    if (taskStatus === TaskStatus.toDo) {
      this.taskService.taskCaseViewOptions.toDoDateFrom = date;
    } else {
      this.taskService.taskCaseViewOptions.doneDateFrom = date;
    }
    this.datesRange.fromDate = date;
    this.taskBoardDatesService.setDatesRange(this.datesRange);
  }

  onEndDateAndTimeChange(date: any, taskStatus: TaskStatus) {
    if (date == null) {
      return;
    }
    if (taskStatus === TaskStatus.toDo) {
      this.taskService.taskCaseViewOptions.toDoDateTo = date;
    } else {
      this.taskService.taskCaseViewOptions.doneDateTo = date;
    }
    this.datesRange.toDate = date;
    this.taskBoardDatesService.setDatesRange(this.datesRange);
  }

  reassignTasks() {
    this.modalService.open(this.taskReassignModalContent, { size: 'xs' as any });
  }

  onTabChange(event: NgbNavChangeEvent) {
    if (event.nextId === TabsEnum.DONE.toString()) {
      this.taskBoardDatesService.setDatesRange(
        new DatesRange({
          fromDate: this.taskService.taskCaseViewOptions.doneDateFrom,
          toDate: this.taskService.taskCaseViewOptions.doneDateTo,
        })
      );
      if (this.doneSimpleTaskViewModelsForCase == null) {
        this.triggerDoneTasksLoad();
      }
    }
    if (event.nextId === TabsEnum.TO_DO.toString()) {
      this.taskBoardDatesService.setDatesRange(
        new DatesRange({
          fromDate: this.taskService.taskCaseViewOptions.toDoDateFrom,
          toDate: this.taskService.taskCaseViewOptions.toDoDateTo,
        })
      );
    }
  }

  private triggerDoneTasksLoad() {
    this.taskStateService.getDoneTaskViewModelForCase(this.caseId).subscribe(tasks => {
      if (tasks != null) {
        this.loadDoneTasksOnDemand(tasks);
      }
    });
  }
  disableNewTaskHover() {
    return this.caseStateService.isCaseClosed || this.caseStateService.isInvoiceClosedWithNoInvoicingToCustomer || (this.caseValidation && this.caseValidation.caseBeenValidated);
  }
  get TabsEnum() {
    return TabsEnum;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }
}

enum TabsEnum {
  TO_DO,
  DONE,
}
