import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogState as MatDialogState } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { DialogMinimizedStorageService } from '@secca/core/services/dialog-minimized-storage.service';
import { DialogStateAction, DialogStateService, MinimizableNotifData } from '@secca/core/state-services/dialog-state.service';
import { Subject, Subscription } from 'rxjs';
import { AutoUnsubscribe } from '../decorators/auto-unsubscribe';
import { MinimizableDialogType } from '../enums/minimizable-dialog-type-enum';
import { AdditionalInfo, IAdditionalInfo } from '../interfaces/additional-info';

@Directive({
  selector: '[appDialogMinimizable]'
})
@AutoUnsubscribe
export class DialogMinimizableDirective implements OnInit, AfterViewInit {
  readonly ADD_MINIMIZABLE_ICON_RETRY_CNT = 20;
  readonly MAX_PARENT_TRAVERSE_CNT = 10;
  readonly MINIMIZE_BUTTON_NAME = 'MinimizeButton';
  readonly RETRY_TIME = 200;

  @Input()
  appDialogMinimizable: MinimizableDialogType;

  @Input()
  isGlobalBoardDialog: boolean;

  @Input()
  isMinimizeIconHidden: boolean;

  dialogId: string;
  containerElement: any;
  isDialogHidden: boolean;
  subscr: Subscription;

  dialogStateChange = new Subject<MinimizableNotifData>();

  constructor(private elmRef: ElementRef, 
              private renderer: Renderer2,
              private dialogMinimizedStorageService: DialogMinimizedStorageService,
              private dialogStateService: DialogStateService,
              private matDialog: MatDialog,
              private router: Router) {
    this.subscr = dialogStateService.getMinimizable().subscribe((data: MinimizableNotifData) => {
      if ( data.dialogId === this.dialogId ) {
        if ( data.action === DialogStateAction.MAXIMIZE ) {
          this.maximizeDialog();
        }
        else if ( data.action === DialogStateAction.MINIMIZE ) {
          this.minimizeDialog();
        }
        
        this.dialogStateChange.next(data);
      }
    })            
  }

  ngOnInit() {
    this.findDialogId();
  }

  ngAfterViewInit() {
    if ( !this.isMinimizeIconHidden ) {
      this.callFunctionWithRetry(() => this.addDialogMinimizableIcon(), this.ADD_MINIMIZABLE_ICON_RETRY_CNT);
    }
  }

  minimize(): void {
    this.dialogStateService.minimize(this.dialogId);
  }

  maximize(): void {
    this.dialogStateService.maximize(this.dialogId);
  }

  private minimizeDialog(): void {
    const dialogRef = this.matDialog.getDialogById(this.dialogId);
    if ( dialogRef && dialogRef.getState() === MatDialogState.OPEN ) {
      const additionalInfo = this.getAdditionalInfo();
      this.dialogMinimizedStorageService.addMinimized(this.dialogId, this.appDialogMinimizable, this.boardActive, additionalInfo);
      this.hideDialog();
    }
  }

  private maximizeDialog(): void {
    this.showDialog();
    this.dialogStateService.sendToFront(this.dialogId);
    this.dialogMinimizedStorageService.removeMinimized(this.dialogId);
  }

  private addDialogMinimizableIcon(): boolean {
    const elem = this.elmRef.nativeElement.querySelector(".modal-buttons");
    if ( elem ) {
      elem.insertAdjacentHTML('afterbegin',`
          <div style="margin-left:40px; cursor:pointer;">
            <img name="${this.MINIMIZE_BUTTON_NAME}" src="/assets/icons/Minimize White.svg" style="height:30px; width:30px;" />
          </div>
      `);
      
      elem.querySelector("div").addEventListener('click', () => {
        this.minimize();
      })
    }
    return !!elem;
  }

  private showDialog() {
    const elementToShow = this.containerElement ? this.containerElement : this.elmRef.nativeElement;
    this.renderer.removeClass(elementToShow, 'mat-dialog-hide');
    this.isDialogHidden = false;
  }

  private hideDialog() {
    const elementToHide = this.containerElement ? this.containerElement : this.elmRef.nativeElement;
    this.renderer.addClass(elementToHide, 'mat-dialog-hide');
    this.isDialogHidden = true;
  }

  private findDialogId(): void {
    this.containerElement = this.findParentElementByClass(this.elmRef.nativeElement, 'mat-dialog-container');
    if ( this.containerElement ) {
      this.dialogId = this.containerElement.id;
    }
  }

  private findParentElementByClass(element, componentClass) {
    for (let i = 0; i < this.MAX_PARENT_TRAVERSE_CNT; i++) {
        if ( !element ) {
          break;
        }

        if (element?.classList?.contains(componentClass)) {
            return element;
        } 

        element = element.parentNode;
    }

    return null;
  }

  private getAdditionalInfo(): AdditionalInfo {
    const component: IAdditionalInfo = this.matDialog.getDialogById(this.dialogId)?.componentInstance;
    return component?.getAdditionalInfo();
  }

  private callFunctionWithRetry(func: any, retryCnt: number) {
    setTimeout(() => {
      if ( !func() && retryCnt > 0 ) {
        this.callFunctionWithRetry(func, retryCnt-1);
      }
    }, this.RETRY_TIME);
  }

  get boardActive(): boolean {
    return this.router.url === '/board';
  }
}
