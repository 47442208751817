import { EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

function unsubscribe(property: unknown): void {
  if (property instanceof Subscription) {
    property.unsubscribe();
  }
}

export function AutoUnsubscribe(constructor) {
  const original = constructor.prototype.ngOnDestroy;

  constructor.prototype.ngOnDestroy = function() {
    for (const prop in this) {
      const property = this[prop];

      const isEventEmitter = property && property instanceof EventEmitter;
      const hasUnsubscribe = property && typeof property.unsubscribe === 'function';

      if (!isEventEmitter && hasUnsubscribe) {
        property.unsubscribe();
      }

      if (Array.isArray(property)) {
        property.forEach(unsubscribe);
      }
    }
    original && typeof original === 'function' && original.apply(this, arguments);
  };
}
