<div *ngIf="person" class="result-fields">
  <div class="title">{{ title | translate }}</div>
  <div class="value">{{ 'policy-lookup-first-name' | translate }} <span class="text">{{ person.firstName }}</span></div>
  <div class="value">{{ 'policy-lookup-surname' | translate }} <span class="text">{{ person.lastName }}</span></div>
  <div *ngIf="person.ssn" class="value">{{ 'policy-lookup-ssn' | translate }} <span class="text">{{ person.ssn }}</span></div>
  <div *ngIf="person.gender" class="value">{{ 'policy-lookup-gender' | translate }} <span class="text">{{ person.gender }}</span></div>
  <div *ngIf="person.addressCareOf" class="value">{{ 'policy-lookup-address-care-of' | translate }} <span class="text">{{ person.addressCareOf }}</span></div>
  <div *ngIf="person.streetName" class="value">{{ 'policy-lookup-street' | translate }} <span class="text">{{ person.streetName }} {{ person.streetNumber }}{{ person.streetNumberLetter }}</span></div>
  <div *ngIf="person.postalCode" class="value">{{ 'policy-lookup-postal-code' | translate }} <span class="text">{{ person.postalCode }}</span></div>
  <div *ngIf="person.city" class="value">{{ 'policy-lookup-city' | translate }} <span class="text">{{ person.city }}</span></div>
  <div *ngIf="person.residentCountry" class="value">{{ 'policy-lookup-resident-country' | translate }} <span class="text">{{ person.residentCountry }}</span></div>
  <div *ngIf="person.citizenshipCountry" class="value">{{ 'policy-lookup-citizen-country' | translate }} <span class="text">{{ person.citizenshipCountry }}</span></div>
  <ng-container *ngIf="person.phoneNumbers">
    <div class="title">{{ 'policy-lookup-phone-numbers' | translate }}</div>
    <div *ngFor="let number of person.phoneNumbers" class="value">
      <span class="text">{{ number.type | titlecase }} {{ number.number }}</span>
    </div>
  </ng-container>
  <div *ngIf="person.email" class="value">{{ 'policy-lookup-email' | translate }} <span class="text">{{ person.email }}</span></div>
</div>
