import { Injectable } from "@angular/core";
import { Adapter } from "../interfaces/adapter";
import { CausesLevel2DD, CausesLevel2DDAdapter } from "./CausesLevel2DD";
import { DropdownDictionary } from "./dropdownDictionary";

export class CAUSES_LEVEL1_CODES {
  static readonly MEDICAL       = 'CL100';
  static readonly PRE_DEPARTURE = 'CL108';  
}

export class CausesLevel1DD extends DropdownDictionary {
    level2Causes: CausesLevel2DD[];

    public constructor(init?: Partial<CausesLevel1DD>) {
        super();
        Object.assign(this, init);
    }    
}

@Injectable({
    providedIn: 'root'
})  
export class CausesLevel1DDAdapter implements Adapter<CausesLevel1DD> {
    constructor(private causesLevel2DDAdapter: CausesLevel2DDAdapter) {
    }

    adapt(item: any): CausesLevel1DD {
        return new CausesLevel1DD({
        id: item.level1Code,
        name: item.level1,
        level2Causes: item.level2Causes ? item.level2Causes.map(causeLevel2 => this.causesLevel2DDAdapter.adapt(causeLevel2)) : []
      });
    }
}

