export class CaseCoverageDropDown {
  id: number;
  name: string;
  disabled: boolean;

  public constructor(id: number, name: string, disabled?: boolean) {
    this.id = id;
    this.name = name;
    this.disabled = disabled;
  }
}
