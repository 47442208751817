import { OpenInvoicePdfComponent } from '@secca/shared/components/open-invoice-pdf/open-invoice-pdf.component';
import { ServiceOrderService } from '@secca/core/services/service-order.service';
import { CaseService } from 'src/app/core/services/case.service';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { SupplierInvoiceTaskViewModel } from '../models/supplier-invoice-task-view.model';
import { ServiceTypeEnumIcon, ShortcutEnum, SortOrder, SupplierInvoiceStatus } from '@secca/shared/models/enums';
import { Router } from '@angular/router';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import { TaskStateService } from '../services/task-state.service';
import { TaskViewModel } from '../models/task-view.model';
import { DataService } from '@secca/core/services/data.service';
import { SupplierInvoiceService } from '@secca/core/services/supplier-invoice.service';
import { TableSort } from '@secca/shared/models/table-sort';
import { DropdownWithIconDisableDictionary } from '@secca/shared/models/dropdownWithIconDisableDictionary';
import { TaskModalModeEnum } from '@secca/case/components/case-task/task-modal-static/task-modal-static-mode.enum';
import { DialogViewerService } from '@secca/core/services/dialog-viewer.service';
import { LockContextEnum } from '@secca/shared/enums/lock-context.enum';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { AbstractGlobalBoardTableShortcutsComponent } from '@secca/shared/components/abstract-global-board-table-shortcuts.component';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { SettingsService } from '@secca/core/services/settings.service';
import { BoardNavigationStateService } from '../services/board-navigation-state.service';

@Component({
  selector: 'app-board-task-supplier-invoice-table',
  templateUrl: './board-task-supplier-invoice-table.component.html',
  styleUrls: ['./board-task-supplier-invoice-table.component.scss'],
  providers: [OpenInvoicePdfComponent],
})
@AutoUnsubscribe
export class BoardTaskSupplierInvoiceTableComponent extends AbstractGlobalBoardTableShortcutsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() set taskViewModels(data: SupplierInvoiceTaskViewModel[]) {
    this._taskViewModels = data;
    this.numberOfRows = data?.length;
    setTimeout(() => {
      this.selectedRowIndex = this.boardNavigationStateService.getState('board-case-filter-request-supplier-invoice-selected-row-index') ? parseInt(this.boardNavigationStateService.getState('board-case-filter-request-supplier-invoice-selected-row-index'), 10) : 0;
      this.inputScrollPosition = this.boardNavigationStateService.getState('board-case-filter-request-supplier-invoice-task-scroll-position') ? parseInt(this.boardNavigationStateService.getState('board-case-filter-request-supplier-invoice-task-scroll-position'), 10) : 0;
      this.navigateToTable();
    }, 50);
  }
  get taskViewModels(): SupplierInvoiceTaskViewModel[] {
    return this._taskViewModels;
  }

  @Input() taskSorting: TableSort;
  @Output() sortingAction = new EventEmitter<TableSort>();
  @Output() supplierInvoiceMovedEvent = new EventEmitter();
  @Output() taskUpdatedEvent = new EventEmitter();
  inputScrollPosition: number = 0;

  isAllSelected = false;
  get sortedOn(): any {
    const sortedOn = {};
    if (this.taskSorting != null) {
      sortedOn[this.taskSorting.sortBy] = true;
    }
    return sortedOn;
  }
  get sortOrder(): any {
    const sortOrder = {};
    if (this.taskSorting != null) {
      sortOrder[this.taskSorting.sortBy] = this.taskSorting.sortOrder;
    }
    return sortOrder;
  }
  supplierInvoiceId: number;
  selectedTaskViewModel: TaskViewModel;

  private _taskViewModels: SupplierInvoiceTaskViewModel[];
  private serviceOrderTypes: DropdownWithIconDisableDictionary[];

  get filteredTasks() {
    return this.taskViewModels;
  }

  constructor(
    private caseService: CaseService,
    private supplierInvoiceService: SupplierInvoiceService,
    private dataService: DataService,
    private serviceOrderService: ServiceOrderService,
    private openInvoicePdfComponent: OpenInvoicePdfComponent,
    private taskStateService: TaskStateService,
    private caseLockHelperService: CaseLockHelperService,
    private dialogViewerService: DialogViewerService,
    private caseStateService: CaseStateService,
    public settingsService: SettingsService,
    private router: Router,
    private boardNavigationStateService: BoardNavigationStateService
  ) {
    super('taskSupplierInvoiceTabel');
  }

  ngOnInit() {
    this.serviceOrderService.getServiceOrderTypes(false).subscribe(result => (this.serviceOrderTypes = result));
    this.selectedRowIndex = this.boardNavigationStateService.getState('board-case-filter-request-supplier-invoice-selected-row-index') ? parseInt(this.boardNavigationStateService.getState('board-case-filter-request-supplier-invoice-selected-row-index'), 10) : 0;
    this.inputScrollPosition = this.boardNavigationStateService.getState('board-case-filter-request-supplier-invoice-task-scroll-position') ? parseInt(this.boardNavigationStateService.getState('board-case-filter-request-supplier-invoice-task-scroll-position'), 10) : 0;

    this.shortcutSubscriptions.push(
      this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateTableRowCtrlEnter }).subscribe(a => {
        if (Number.isFinite(this.selectedRowIndex)) {
          this.viewTask(this.taskViewModels[this.selectedRowIndex] as any);
        }
      }),
      this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateTableRowAltEnter }).subscribe(a => {
        if (Number.isFinite(this.selectedRowIndex)) {
          this.manageInvoiceAndOpenPDF(this.taskViewModels[this.selectedRowIndex] as any);
        }
      }),
    );
  }

  ngOnChanges() {
    setTimeout(() => this.setScrollPosition(), 500);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.boardNavigationStateService.saveState('board-case-filter-request-supplier-invoice-task-scroll-position', JSON.stringify(this.inputScrollPosition));
    this.boardNavigationStateService.saveState('board-case-filter-request-supplier-invoice-selected-row-index', JSON.stringify(this.selectedRowIndex));
  }

  setScrollPosition() {
    if (this.inputScrollPosition !== null && document.getElementById('taskSupplierInvoiceTabel')) {
      document.getElementById('taskSupplierInvoiceTabel').scrollTop = this.inputScrollPosition;
    }
  }

  onCaseTabelScroll() {
    this.inputScrollPosition = document.getElementById('taskSupplierInvoiceTabel').scrollTop;
  }

  getTaskTypeImagePath(task: SupplierInvoiceTaskViewModel): string {
    return '/assets/icons/task/economy-task-todo.svg';
  }

  getServiceOderTypeIconStyle(task: SupplierInvoiceTaskViewModel): any {
    const imagePath = ServiceTypeEnumIcon.getIcon(task.serviceOrderType);
    return {
      backgroundColor: ServiceTypeEnumIcon.getBackGroundColor(task.serviceOrderType),
      backgroundImage: `url(\'../../../../../../assets/icons/${imagePath}\')`,
    };
  }

  sortBy(column: string) {
    const sortOrder = {};
    if (this.sortOrder[column] == null) {
      sortOrder[column] = SortOrder.asc;
    } else {
      if (this.sortOrder[column] === SortOrder.asc) {
        sortOrder[column] = SortOrder.desc;
      } else {
        sortOrder[column] = SortOrder.asc;
      }
    }
    this.sortingAction.emit(new TableSort({ sortBy: column, sortOrder: sortOrder[column] }));
  }

  sortedBy(column: string): string {
    return this.sortedOn[column] ? 'sorted-by' : '';
  }

  selectAllTasks() {
    this.isAllSelected = !this.isAllSelected;
    this.taskViewModels.forEach(a => (a.isSelected = this.isAllSelected));
  }

  getBrandImagesUrlForProfile(profileId: string) {
    return this.caseService.getBrandImagesUrlForProfile(profileId);
  }

  manageInvoiceAndOpenPDF(supplierInvoiceTaskViewModel: SupplierInvoiceTaskViewModel) {
    if (supplierInvoiceTaskViewModel.invoiceId) {
      this.redirectToManageSupplierInvoice(supplierInvoiceTaskViewModel.caseId, supplierInvoiceTaskViewModel.invoiceId);
      this.supplierInvoiceService
        .getSupplierInvoice(supplierInvoiceTaskViewModel.invoiceId)
        .subscribe(invoice => this.openInvoicePdfComponent.getAndOpenInvoicePDFNewWindow(invoice.invoiceDocumentId, invoice.description));
    }
  }

  private redirectToManageSupplierInvoice(caseId: number, invoiceId: number) {
    this.dataService.navigateSupplierInvoiceId = invoiceId;
    this.router.navigate(['case', caseId]);
  }

  getServiceOrderTypeName(task: SupplierInvoiceTaskViewModel): string {
    if (task.serviceOrderType && this.serviceOrderTypes) {
      const serviceOrderType = this.serviceOrderTypes.find(s => s.id === task.serviceOrderType);
      return serviceOrderType.name;
    }
    return null;
  }

  get TaskModalModeEnum() {
    return TaskModalModeEnum;
  }

  viewTask(supplierInvoiceTaskViewModel: SupplierInvoiceTaskViewModel) {
    const doneStatuses = [SupplierInvoiceStatus.rejected, SupplierInvoiceStatus.approved, SupplierInvoiceStatus.supplierPaid];
    let supplierInvoiceStatus: SupplierInvoiceStatus|null = null;
    for (const[key, value] of Object.entries(SupplierInvoiceStatus)) {
      if (value === supplierInvoiceTaskViewModel.invoiceStatus) { supplierInvoiceStatus = SupplierInvoiceStatus[key]; }
    }
    if (supplierInvoiceStatus && doneStatuses.find(done => done == supplierInvoiceStatus)) {
      return;
    }

    this.caseService.getCase(supplierInvoiceTaskViewModel.caseId.toString()).subscribe(seccaCase => {
      this.caseStateService.sendCase(seccaCase);

      // Update caselocks to determine if loggedin user has already taken the lock
      this.caseLockHelperService.updateCaseLocks(seccaCase.id, seccaCase.caseLocks);

      const isCaseAlreadyLockedByTheLoggedInUser = this.caseLockHelperService.isCaseLockedByTheLoggedInUser(LockContextEnum.CASE_BASIC);
      if (!isCaseAlreadyLockedByTheLoggedInUser && !this.caseLockHelperService.isCaseBasicLockTaken) {
        // If not already locked by the loggedin user or others, we try to take the lock
        this.caseLockHelperService.lockCase(LockContextEnum.CASE_BASIC).subscribe();
      }
    });

    this.taskStateService.getTaskById(supplierInvoiceTaskViewModel.id).subscribe(taskViewModel => {
      this.selectedTaskViewModel = taskViewModel.convertToTaskViewModel();
      this.dialogViewerService.openTaskMessageDialog(this.selectedTaskViewModel, false, () => this.supplierInvoiceMoved(), () => this.taskUpdated(), );
    });
  }

  supplierInvoiceMoved() {
    this.supplierInvoiceMovedEvent.emit();
  }

  taskUpdated() {
    this.taskUpdatedEvent.emit();
  }
}
