import {IncidentService} from '@secca/core/services/incident.service';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MomentHelperService, SECCA_TIMEZONES} from '@secca/core/services/moment-helper.service';
import {Duration} from '@secca/shared/models/duration';
import {ValidationTypeEnum} from '@secca/shared/models/enums';
import * as moment from 'moment';
import {ServiceOrderType} from '@secca/shared/models/service-order-type';

@Component({
  selector: 'app-duration',
  templateUrl: './duration.component.html',
  styleUrls: ['./duration.component.scss'],
})
export class DurationComponent implements OnInit {
  @Input() incidentId: string;
  @Input() deleteable: boolean;
  @Input() gopSemiRequired: false;
  @Input() duration: Duration;
  @Input() hideGOPDates: boolean;
  @Input() hideDaysHours = true;
  @Input() hideLocation: boolean;
  @Input() hideRoomAndRemark: boolean;
  @Input() disableStartDate: boolean;
  @Input() disableEndDate: boolean;
  @Input() disableLocation: boolean;
  @Input() calculateDefaultDates = true;
  @Input() seccaTimeZone: SECCA_TIMEZONES = 'utc';
  @Input() altGopPeriodTitle: string;
  @Input() defaultStartDate: moment.Moment;
  @Input() defaultEndDate: moment.Moment;
  @Input() serviceOrderType: ServiceOrderType;
  @Input() title = true;
  @Input() notNeeded = false;

  @Output() delete: EventEmitter<Duration> = new EventEmitter();

  startDateStartAt: moment.Moment;
  endDateStartAt: moment.Moment;
  gopPeriodTitle: string;
  timeZone = 'utc';
  minGopDate: moment.Moment;
  maxGopDate: moment.Moment;

  get ValidationTypeEnum() {
    return ValidationTypeEnum;
  }

  get startDateInvalid() {
    return !this.duration.isStartDateValid();
  }

  constructor(private incidentService: IncidentService) {}

  ngOnInit() {
    this.calcOnBasisOfLocation();
    this.calcMaxGopDate();
    this.calcMinGopDate();
    // Use enum
  /*  if(this.serviceOrderType && this.serviceOrderType.serviceTypeCode === 'ST01' ){
      this.cloneGopEndDate();
      this.cloneGopStartDate();
    } */
    this.gopPeriodTitle = this.altGopPeriodTitle ? this.altGopPeriodTitle : 'sub-duration-gop-period';

    if (this.incidentId != null) {
      this.incidentService.getCaseIncident(+this.incidentId).subscribe(caseIncident => {
        if (caseIncident && this.duration && !this.duration.location.freeText) {
          if (!!caseIncident.currentLocation.freeText) {
            this.duration.location = caseIncident.currentLocation;
          } else {
            this.duration.location = caseIncident.incidentLocation;
          }
          this.calcOnBasisOfLocation();
        }
      });
    }
  }
  private calcOnBasisOfLocation() {
    this.calcUtcOffset();
    this.calcStartDates();
    if (!this.duration.startDate && this.startDateStartAt) {
      this.duration.startDate = this.startDateStartAt;
    }
    if (!this.duration.endDate && this.endDateStartAt) {
      this.duration.endDate = this.endDateStartAt;
    }
  }

  private calcStartDates() {
    if (this.calculateDefaultDates) {
      this.startDateStartAt = this.defaultStartDate
        ? this.defaultStartDate.clone().tz(this.timeZone, true).utc()
        : moment().tz(this.timeZone).startOf('day').utc();
      this.endDateStartAt = this.defaultEndDate
        ? this.defaultEndDate.clone().tz(this.timeZone, true).utc()
        : moment().tz(this.timeZone).endOf('day').utc();
    }
  }

  deleteClicked() {
    this.delete.emit(this.duration);
  }

  endDateChange(date) {
    this.duration.endDate = date;
    if (date && date.isValid()) {
      this.calcMaxGopDate();
    }
  }

  startDateChange(date) {
    this.duration.startDate = date;
    if (date && date.isValid()) {
      this.calcMinGopDate();
    }
  }

  gopStartChange(date) {
    this.duration.gopPeriodStartDate = date;
  }

  calcMinGopDate() {
    if (this.duration.startDate) {
      this.minGopDate = this.duration.startDate.clone().tz(this.timeZone).startOf('day');
    }
  }

  calcMaxGopDate() {
    if (this.duration.endDate){
      this.maxGopDate = this.duration.endDate.clone().tz(this.timeZone).endOf('day');
    }
  }
  cloneGopStartDate() {
    if (this.duration.startDate){
      this.duration.gopPeriodStartDate = this.duration.startDate.clone().tz(this.timeZone).startOf('day').utc();
    }
  }
  cloneGopEndDate() {
    if (this.duration.endDate){
      this.duration.gopPeriodEndDate = this.duration.endDate.clone().tz(this.timeZone).startOf('day').utc();
    }
  }

  gopEndChange(date) {
    this.duration.gopPeriodEndDate = date;
  }

  onLocationUpdate(event) {
    this.calcUtcOffset();
  }

  calculateDaysHours(): string {
    let readableDuration = '';
    if (this.duration.startDate && this.duration.endDate) {
      const minutes: number = this.duration.endDate.diff(this.duration.startDate, 'minutes');

      const startedHours = Math.ceil(minutes / 60);
      const days = Math.floor(startedHours / 24);
      const remainingHours = startedHours % 24;
      readableDuration = `${days} day(s) ${remainingHours} hour(s)`;
    }
    return readableDuration;
  }

  private calcUtcOffset() {
    if (this.hideLocation) {
      this.timeZone = MomentHelperService.resolveTimeZone(this.seccaTimeZone);
    } else {
      this.timeZone = this.duration.location.timeZoneId ? this.duration.location.timeZoneId : 'utc';
    }
  }
}
