import { CaseBasicsInfoUserTypeEnum, CoordinationTypeEnum } from '@secca/shared/models/enums';
import { Case } from '@secca/shared/models/case';
import { Component, OnInit, Input } from '@angular/core';
import { PersonInsurance } from '@secca/shared/models/person-insurance';
import { CaseTypeCodes, isCoordinationCase } from '@secca/shared/models/case-type';
import { CoordinationCaseService } from '@secca/core/services/coordination-case.service';
import { CoordinationList } from '@secca/shared/models/coordination-link';
@Component({
  selector: 'app-case-basics-info',
  templateUrl: './case-basics-info.component.html',
  styleUrls: ['./case-basics-info.component.scss'],
})
export class CaseBasicsInfoComponent implements OnInit {

  @Input()
  set case(newCase: Case) {
    if (newCase) {

      this._case = newCase;
      this.getCoordinationCases();
    }
  }
  get case(): Case {
    return this._case;
  }

  @Input()
  set caseType(caseType: string) {
    if (caseType) {
      this.active = !isCoordinationCase(this.case.caseTypeCode) ? 1 : 5;
      if(this.case.caseTypeCode === CaseTypeCodes.MAJOR_INCIDENT) {
        this.numberOfCoordinationCases = 0;
      }
    }
  }

  @Input() newCase: boolean;
  get CaseBasicsInfoUserTypeEnum() {
    return CaseBasicsInfoUserTypeEnum;
  }

  isCoordinationCase(value: string ) {
    return isCoordinationCase(value);
  }

  active = 1;
  private _case: Case;
  private coordinationTypeEnum: CoordinationTypeEnum = CoordinationTypeEnum.AMBULANCE_FLIGHT_PER_QUOTE;
  @Input() set personInsurance(value: PersonInsurance) {
    this._personInsurance = value;
  }
  @Input() numberOfLinkedCases: 0;
  @Input() numberOfCoordinationCases: 0;
  get personInsurance() {
    return this._personInsurance;
  }
  private _personInsurance: PersonInsurance;
  coordinationList: CoordinationList[] = [];

  constructor( private coordinationCaseService: CoordinationCaseService,) {}

  ngOnInit() {
    this.active = this.case.caseTypeCode !== CaseTypeCodes.INTERNAL_COORDINATION && this.case.caseTypeCode !== CaseTypeCodes.MAJOR_INCIDENT ? 1 : 5;
  }

  getCoordinationCases() {
    this.coordinationCaseService.getLinkedCasesForCoordinationCase(this.case.id).subscribe(
      result => {
        this.coordinationList = result;
      }
    );
  }

  get caseTypeCodes(){
    return CaseTypeCodes;
  }

  get CoordinationTypeEnum() {
    return CoordinationTypeEnum;
  }
}
