import { Component, Input, OnInit } from "@angular/core";
import {DigitalCaseflowLog} from "@secca/shared/models/digital-caseflow-log";
import {DigitalCaseflowActivityLog} from "@secca/shared/models/digital-caseflow-activity-log";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-case-summary-digital-caseflow-details',
  templateUrl: './case-summary-digital-caseflow-details.component.html',
  styleUrls: ['./case-summary-digital-caseflow-details.component.scss'],
})
export class CaseSummaryDigitalCaseflowDetailsComponent implements OnInit {
  @Input()
  flowLog: DigitalCaseflowLog;
  translateService: TranslateService;

  errorCodeTranslationPrefix: string = 'digital-caseflow-activity-log-error-';
  actionTranslationPrefix: string = 'digital-caseflow-activity-action-';
  resultTranslationPrefix: string = 'digital-caseflow-activity-result-';
  constructor(translateService: TranslateService) {
    this.translateService = translateService;
  }

  ngOnInit() {
  }

  getTranslatedErrorCodes(activityLog: DigitalCaseflowActivityLog): string {
    return activityLog.activityErrorLogs.map(item => this.translateService.instant(this.errorCodeTranslationPrefix+item.errorCode)).join(',');
  }
  getTranslatedResult(activityLog: DigitalCaseflowActivityLog): string {
    return this.translateService.instant(this.resultTranslationPrefix+activityLog.result);
  }
}
