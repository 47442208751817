import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
import { AttachmentTypeEnum } from './enums';
import {FileScan, FileScanAdapter} from '@secca/shared/models/fileScan';

export class AttachmentDto {
  documentId: string;
  documentName: string;
  documentExtension: string;
  documentNote: string;
  attachmentType: AttachmentTypeEnum = AttachmentTypeEnum.FROM_USER;
  documentTags: string[];
  fileScan: FileScan;

  get documentFilename() {
    return this.documentName + '.' + this.documentExtension;
  }

  public constructor(init?: Partial<AttachmentDto>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
export class AttachmentAdapter implements Adapter<AttachmentDto> {
  constructor(private fileScanAdapter: FileScanAdapter) {}

  adapt(item: any): AttachmentDto {
    const documentFileName = item.documentName.split('.');
    let extension = documentFileName.length > 1 ? documentFileName[documentFileName.length - 1] : '';
    if (extension.length > 5) {
      extension = '';
    }
    const extensionIndex = extension === '' ? item.documentName.length : item.documentName.lastIndexOf(extension) - 1;
    return new AttachmentDto({
      documentName: item.documentName.substring(0, extensionIndex),
      documentExtension: extension,
      documentId: item.documentId,
      documentNote: item.documentNote,
      documentTags: item.documentTags,
      fileScan: this.fileScanAdapter.adapt(item.fileScan)
    });
  }
}
