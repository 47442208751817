<div [appDisableInput]="caseStateService.isCaseDisabled()">
  <div class="case-stakeholder" [class.show-top-buttons]="showSsnLookup || caseStakeholder.company != null && getStakeholderType(caseStakeholder) != null">
    <div class="d-flex" *ngIf="caseStakeholder.company != null && getStakeholderType(caseStakeholder) != null">
      <div class="w-50"></div>
      <div class="w-50">
        <button *ngIf='isNewServiceProviderEnabled' class="primary-button float-end" (click)="supplierInformation()">{{ 'view-supplier' | translate }}</button>
        <button *ngIf='!isNewServiceProviderEnabled'
          class="primary-button float-end"
          (click)="searchSupplier()"
          [class.is-disabled]="!!caseStakeholder.company.providerId || disable"
        >
          {{ 'search-supplier' | translate }}
        </button>
        <button class="primary-button float-end" (click)="refreshSupplier()" [disabled]="disable">
          {{ 'refresh-supplier' | translate }}
        </button>
      </div>
    </div>
    <div class="row" *ngIf="showSsnLookup">
      <div class="col-6">
      <span *permission="PermissionEnum.STAKEHOLDER_UPDATE">
        <button class="ssn-lookup-button"
          *ngIf="!caseStakeholder.isCompany"
          [class.lookup-performed]="caseStakeholder.person.ssnLookupPerformed"
          [disabled]="!caseStakeholder.person.ssnLookupPossible || this.caseStateService.isCaseDisabled()"
          (click)="doSsnLookup()"
        >
          {{ 'ssn-lookup' | translate }}
        </button>
      </span>
      </div>
    </div>
    <div class="d-flex" >
      <div class="w-50 stakeholder-column">
        <div class="w-100">
            <h1 class="heading-1"><span>Stakeholder</span></h1>
        </div>
        <div class="w-100">
          <div class="ssn-validation-status" *ngIf="!caseStakeholder.isCompany">
            <div [className]="ssnValidationStatus"></div>
          </div>
        </div>
        <div class="d-flex w-100" *ngIf="caseStakeholder.person != null">
          <div class="w-50 pe-2">
            <app-drop-down-countries-auto-complete
              [disabled]="disable"
              [name]="'National ID'"
              [withInput]="true"
              [showCode]="true"
              [(selectedItemId)]="nationalIdCountry"
              [showEye]="true"
              (showNationalId)="showNationalIdClick()"
              [autoCompleteTypeEnum]="AutoCompleteTypeEnum.Countries"
              (selectedItemIdChange)="saveNationalId(theShownNationalId, $event)"
              [(value)]="theShownNationalId"
              (focusin)="focusInFunction()"
              (focusout)="focusOutFunction()"
              [validate]="!caseStakeholder.person.nationalIdValid"
              [isValid] = "caseStakeholder.person.nationalIdValid"
              [validationTypeToInput]="ValidationTypeEnum.nationalID"
              [placeholder]="caseStakeholder.stakeholderType === 'REPORTER' || caseStakeholder.stakeholderType === 'PERSON' ? ('case-stakeholder-optional-placeholder' | translate) : ''"
              [recommended]="caseStakeholder.stakeholderType !== 'REPORTER' && caseStakeholder.stakeholderType !== 'PERSON'"
            >
            </app-drop-down-countries-auto-complete>
          </div>
          <div class="w-50 ps-2">
            <div class="d-flex">
              <div class="date-of-birth-column">
                <app-input-calendar
                  [withImage]="true"
                  [name]="'Date of birth'"
                  [required]="false"
                  [date]="caseStakeholder.person.dateOfBirth"
                  [disabled]="caseStakeholder.person.dateOfBirthCalculated || disable"
                  (dateChange)="updateDateOfBirth($event)"
                  [placeholder]="caseStakeholder.stakeholderType === 'REPORTER' || caseStakeholder.stakeholderType === 'PERSON' ? ('case-stakeholder-optional-placeholder' | translate) : ''"
                  [recommended]="!caseStakeholder.person.dateOfBirth && caseStakeholder.stakeholderType !== 'REPORTER' && caseStakeholder.stakeholderType !== 'PERSON'"
                >
                </app-input-calendar>
              </div>
              <div class="age-column">
                <app-input
                  [name]="'Age'"
                  [(value)]="caseStakeholder.person.age"
                  [disabled]="true"
                  (saveModel)="saveModel('age', caseStakeholder.person.age, false)"
                  [validate]="true"
                >
                </app-input>
              </div>
              <div class="gender-column">
                <app-drop-down
                  [name]="'Gender'"
                  [items]="genders"
                  (saveModel)="saveModel('gender', caseStakeholder.person.gender, false)"
                  [(selectedItemId)]="caseStakeholder.person.gender"
                  [disabled]="caseStakeholder.person.dateOfBirthCalculated || disable"
                >
                </app-drop-down>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="caseStakeholder.company != null">
          <div class="d-flex">
            <div class="w-100">
              <app-input
                [name]="'Company name'"
                [(value)]="caseStakeholder.company.name"
                [maxTextLength]="250"
                (saveModel)="saveModel('companyName', caseStakeholder.company.name, false)"
                [disabled]="!!caseStakeholder.company.providerId || disable"
              >
              </app-input>
            </div>
          </div>
          <div class="d-flex pt-2">
            <div class="w-50 pe-2">
              <app-phone-drop-down
                *ngIf="displayMobilePhoneNumber()"
                [disabled]="disable"
                [name]="'case-basics-info-mobile-phone' | translate"
                [withPrefixInput]="true"
                [withInput]="true"
                [showCode]="true"
                (saveModel)="saveMobilePhone($event)"
                [(selectedItemId)]="stakeholderMobile.countryCode"
                (selectedItemIdChange)="saveMobilePhone($event)"
                [placeholder]="stakeholderMobile.prefix"
                [(prefixValue)]="stakeholderMobile.prefix"
                [(value)]="stakeholderMobile.number"
              >
              </app-phone-drop-down>
              <app-phone-drop-down
                *ngIf="!displayMobilePhoneNumber()"
                [disabled]="disable"
                [name]="'Phone'"
                [withPrefixInput]="true"
                [withInput]="true"
                [showCode]="true"
                (saveModel)="saveFixedPhone($event)"
                [(selectedItemId)]="stakeholderFixed.countryCode"
                (selectedItemIdChange)="saveFixedPhone($event)"
                [placeholder]="stakeholderFixed.prefix"
                [(prefixValue)]="stakeholderFixed.prefix"
                [(value)]="stakeholderFixed.number"
              >
              </app-phone-drop-down>
            </div>
            <div class="w-50 ps-2 icons-column" *ngIf="viewProviderRating()">
              <app-supplier-icon
                class="float-start"
                [supplierRating]="'A'"
                [isActive]="caseStakeholder.company.quality === ProviderRatingEnum.A"
              ></app-supplier-icon>
              <app-supplier-icon
                class="float-start"
                [supplierRating]="'B'"
                [isActive]="caseStakeholder.company.quality === ProviderRatingEnum.B"
              ></app-supplier-icon>
              <app-supplier-icon
                class="float-start"
                [supplierRating]="'C'"
                [isActive]="caseStakeholder.company.quality === ProviderRatingEnum.C"
              ></app-supplier-icon>
              <app-supplier-icon
                class="float-start"
                [supplierRating]="'D'"
                [isActive]="caseStakeholder.company.quality === ProviderRatingEnum.D"
              ></app-supplier-icon>
              <app-supplier-icon
                class="float-start"
                [supplierRating]="'NONE'"
                [isActive]="caseStakeholder.company.quality === '' ? true : false"
              ></app-supplier-icon>
            </div>
          </div>
          <div class="d-flex pt-2">
            <div class="w-50 pe-2">
              <app-phone-drop-down
                [disabled]="disable"
                [name]="'Fax'"
                [withPrefixInput]="true"
                [withInput]="true"
                [showCode]="true"
                (saveModel)="saveFax($event)"
                [(selectedItemId)]="stakeholderFax.countryCode"
                (selectedItemIdChange)="saveFax($event)"
                [placeholder]="stakeholderFax.prefix"
                [(prefixValue)]="stakeholderFax.prefix"
                [(value)]="stakeholderFax.number"
              >
              </app-phone-drop-down>
            </div>
            <div class="w-50 icons-column" *ngIf="viewProviderRating()">
              <app-blacklisted-icon class="float-start" [isBlacklisted]="caseStakeholder.company.attention"></app-blacklisted-icon> &nbsp;
              <div class="text-blacklisted" *ngIf="caseStakeholder.company.attention">Attention! Read instruction in Providers</div>
            </div>
          </div>
          <div class="field-row pt-2">
            <div class="w-50 ">
              <app-input
                [name]="'Email'"
                [maxTextLength]="250"
                [(value)]="caseStakeholder.company.email"
                [validationType]="ValidationTypeEnum.emailAndValidOrEmpty"
                [validate]="true"
                (saveModel)="saveCompanyEmail()"
                [disabled]="disable"
              >
              </app-input>
            </div>
            <div class="w-50"></div>
          </div>
          <div class="d-flex pt-2">
            <div class="w-100 me-2">
              <app-text-box
                [maxTextLength]="10000"
                [name]="'Remarks'"
                [(value)]="caseStakeholder.company.remarks"
                [disabled]="disable"
                (focusout)="saveModel('remarks', caseStakeholder.company.remarks, false)"
                [placeHolder]="remarksPlaceholder"
                numberOfRows="1"
              ></app-text-box>
            </div>
          </div>
          <div class="d-flex">
            <div class="w-100 network-address-field">
              <app-text-box
                [maxTextLength]="10000"
                [name]="'Network remarks'"
                [(value)]="caseStakeholder.company.networkRemarks"
                [disabled]="true"
                (focusout)="saveModel('networkRemarks', caseStakeholder.company.networkRemarks, false)"
              ></app-text-box>
            </div>
          </div>
          <div class="d-flex pt-2" *ngIf="!!caseStakeholder.suppliersReference">
            <div class="w-100">
              <app-input
                [name]="'sub-reference-number' | translate"
                [disabled]="true"
                [value]="caseStakeholder.suppliersReference"
              ></app-input>
            </div>
          </div>
        </div>
        <div class="d-flex pt-2" *ngIf="caseStakeholder.person != null">
          <div class="w-50 pe-2">
            <app-input
              [name]="'First name'"
              [maxTextLength]="250"
              [(value)]="caseStakeholder.person.firstName"
              (saveModel)="saveModel('firstName', caseStakeholder.person.firstName, false)"
              [disabled]="caseStakeholder.person.nameInPassport || disable"
            >
            </app-input>
          </div>
          <div class="w-50 ps-2">
            <div class="surname-column">
              <div class="custom-checkbox nip-checkbox mb-5">
                <label class="checkbox">
                  <span class="checkbox label">NIP</span>
                  <input
                    type="checkbox"
                    [(ngModel)]="caseStakeholder.person.nameInPassport"
                    (change)="saveModel('nameInPassport', caseStakeholder.person.nameInPassport, false)"
                    [disabled]="disable"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
              <app-input
                [name]="'Surname'"
                [(value)]="caseStakeholder.person.surname"
                (saveModel)="saveModel('surname', caseStakeholder.person.surname, false)"
                [disabled]="caseStakeholder.person.nameInPassport || disable"
                [maxTextLength]="250"
              >
              </app-input>
            </div>
          </div>
        </div>
        <div class="remarks pt-2">
          <div [ngClass]="{ 'small': caseStakeholder.company != null, 'big': caseStakeholder.person != null }" class="pe-2">
            <app-input
              *ngIf="caseStakeholder.person != null"
              [name]="'Remarks'"
              [(value)]="caseStakeholder.person.remarks"
              (saveModel)="saveModel('remarks', caseStakeholder.person.remarks, false)"
              [disabled]="disable"
              [placeholder]="remarksPlaceholder"
              [maxTextLength]="250"
            >
            </app-input>
          </div>
          <div class="small ps-2" style="margin-top: 1px;" *ngIf="caseStakeholder.person != null">
            <app-input-calendar
              [withImage]="true"
              [name]="'case-basics-date-of-death' | translate"
              [date]="caseStakeholder.person.dateOfDeath"
              [disabled]="disable"
              (dateChange)="updateDateOfDeath($event)"
            >
            </app-input-calendar>
          </div>
        </div>
      </div>
      <div class="w-50 contact-column border-between">
        <div class="d-flex">
          <div class="w-100">
            <h1 class="heading-1" *ngIf="caseStakeholder.person != null"><span>Contact details</span></h1>
            <h1 class="heading-1" *ngIf="caseStakeholder.company != null"><span>Insurance / GOP</span></h1>
          </div>
        </div>

        <div *ngIf="caseStakeholder.company != null">
          <div class="d-flex ">
            <div class="w-100">
              <app-input
                [name]="'Contact'"
                [(value)]="caseStakeholder.company.contactPerson"
                [maxTextLength]="250"
                [disabled]="nonEmptyProviderTransferredField(caseStakeholder.company.contactPerson) || disable"
                (saveModel)="saveModel('gopContact', caseStakeholder.company.contactPerson, false)"
              >
              </app-input>
            </div>
          </div>
          <div class="d-flex pt-2">
            <div class="w-50">
              <app-phone-drop-down
                [disabled]="disable"
                [name]="'Phone'"
                [withPrefixInput]="true"
                [withInput]="true"
                [showCode]="true"
                (saveModel)="saveGopPhone($event)"
                [(selectedItemId)]="gopFixed.countryCode"
                (selectedItemIdChange)="saveGopPhone($event)"
                [placeholder]="gopFixed.countryCode"
                [(prefixValue)]="gopFixed.prefix"
                [(value)]="gopFixed.number"
              >
              </app-phone-drop-down>
            </div>
            <div class="w-50">
              <app-drop-down
                [name]="'Preferred channel'"
                (saveModel)="saveModel('gopPreferredChannel', caseStakeholder.company.gop.preferredChannelCode)"
                [items]="preferredChannels"
                [(selectedItemId)]="caseStakeholder.company.gop.preferredChannelCode"
                [disabled]="disable"
              >
              </app-drop-down>
            </div>
          </div>
          <div class="d-flex pt-2">
            <div class="w-50 pe-2">
              <app-phone-drop-down
                [disabled]="disable"
                [name]="'Fax'"
                [withPrefixInput]="true"
                [withInput]="true"
                [showCode]="true"
                (saveModel)="saveGopFax($event)"
                [(selectedItemId)]="gopFax.countryCode"
                (selectedItemIdChange)="saveGopFax($event)"
                [placeholder]="gopFax.prefix"
                [(prefixValue)]="gopFax.prefix"
                [(value)]="gopFax.number"
              >
              </app-phone-drop-down>
            </div>
            <div class="w-50 ps-2">
              <app-drop-down
                [name]="'Preferred language'"
                (saveModel)="updatePreferredLanguage(caseStakeholder.company.gop.preferredLanguageCode)"
                [items]="preferredLanguages"
                [(selectedItemId)]="caseStakeholder.company.gop.preferredLanguageCode"
                [disabled]="disable"
              >
              </app-drop-down>
            </div>
          </div>
          <div class="d-flex pt-2">
            <div class="w-50 pe-2">
              <app-input
                [name]="'Email'"
                [maxTextLength]="250"
                [(value)]="caseStakeholder.company.gop.email"
                [validationType]="ValidationTypeEnum.emailAndValidOrEmpty"
                [validate]="true"
                (saveModel)="saveCompanyGopEmail()"
                [disabled]="disable"
              ></app-input>
            </div>
            <div class="w-50 ps-2">
              <app-drop-down
                [name]="'case-stakeholders-info-gop-method' | translate"
                [items]="gopMethods"
                [disabled]="nonEmptyProviderTransferredField(caseStakeholder.company?.gop?.method, caseStakeholder.company?.gop?.providerMethod) || disable"
                [(selectedItemId)]="caseStakeholder.company.gop.method"
                (saveModel)="saveGopMethod()"
              ></app-drop-down>
            </div>
          </div>
          <div class="d-flex pt-2">
            <div class="w-100">
              <app-text-box
                [maxTextLength]="10000"
                [name]="'Remarks'"
                [(value)]="caseStakeholder.company.gop.remarks"
                [disabled]="disable"
                (focusout)="saveModel('gopRemarks', caseStakeholder.company.gop.remarks, false)"
                [placeHolder]="remarksPlaceholder"
              >
              </app-text-box>
            </div>
          </div>
          <div class="d-flex agent-field">
            <div class="w-100">
              <app-drop-down-stakeholder-with-icon
                [name]="'Agent'"
                [items]="agentStakeholders"
                [disabled]="!agentStakeholderRequired() || nonEmptyProviderTransferredField(caseStakeholder.company?.gop?.agentId, caseStakeholder.company?.gop?.providerAgentId) || disable"
                [(selectedItemId)]="agentForStakeholder"
                (focusout)="updateAgentAndSave()"
                [required]="true"
              >
              </app-drop-down-stakeholder-with-icon>
            </div>
          </div>
        </div>
        <div *ngIf="caseStakeholder.person != null">
          <div class="d-flex">
            <div class="w-50 pe-2">
              <app-phone-drop-down
                [disabled]="disable"
                [name]="'Mobile phone'"
                [withPrefixInput]="true"
                [withInput]="true"
                [showCode]="true"
                (saveModel)="saveMobilePhone($event)"
                [(selectedItemId)]="stakeholderMobile.countryCode"
                (selectedItemIdChange)="saveMobilePhone($event)"
                [placeholder]="stakeholderMobile.prefix"
                [(prefixValue)]="stakeholderMobile.prefix"
                [(value)]="stakeholderMobile.number"
                [recommended]="true"
              >
              </app-phone-drop-down>
            </div>
            <div class="w-25 ps-2 pe-2">
              <app-input
                [name]="'Passport number'"
                [maxTextLength]="250"
                [(value)]="caseStakeholder.person.passportNumber"
                [showEye]="true"
                [disabled]="disable"
                (saveModel)="saveModel('passportNumber', caseStakeholder.person.passportNumber)"
              ></app-input>
            </div>
            <div class="w-25 ps-2 pe-2">
              <app-input-calendar
                [withImage]="true"
                [name]="'Passport expiry date'"
                [required]="false"
                [date]="caseStakeholder.person.passportExpiryDate"
                [minDate]="passportExpiryMinDate"
                [disabled]="disable"
                (dateChange)="updatePassportExpiryDate($event)"
              >
              </app-input-calendar>
            </div>
          </div>
          <div class="d-flex pt-2">
            <div class="w-50 pe-2">
              <app-phone-drop-down
                [disabled]="disable"
                [name]="'Fixed phone'"
                [withPrefixInput]="true"
                [withInput]="true"
                [showCode]="true"
                (saveModel)="saveFixedPhone($event)"
                [(selectedItemId)]="stakeholderFixed.countryCode"
                (selectedItemIdChange)="saveFixedPhone($event)"
                [placeholder]="stakeholderFixed.prefix"
                [(prefixValue)]="stakeholderFixed.prefix"
                [(value)]="stakeholderFixed.number"
              >
              </app-phone-drop-down>
            </div>
            <div class="w-50 pe-2 ps-2">
              <app-drop-down
                [name]="'Preferred channel'"
                (saveModel)="saveModel('preferredChannel', caseStakeholder.person.preferredChannelCode)"
                [items]="preferredChannels"
                [(selectedItemId)]="caseStakeholder.person.preferredChannelCode"
                [disabled]="disable"
              >
              </app-drop-down>
            </div>
          </div>
          <div class="d-flex pt-2">
            <div class="w-50 pe-2">
              <app-drop-down-input
                [(selectedItemId)]="caseStakeholder.person.hasEmail"
                [(value)]="personEmail"
                [name]="'Email'"
                [disabled]="disable"
                (saveModel)="saveModel('emailStatus', caseStakeholder.person.hasEmail)"
                (change)="savePersonEmail()"
                [validationType]="ValidationTypeEnum.default"
                [validationTypeToInput]="ValidationTypeEnum.emailAndValidOrEmpty"
                [validate]="caseStakeholder.person.hasEmail"
                [items]="emailStatusType"
                [blockEmail]="blockEmail"
              >
              </app-drop-down-input>
            </div>
            <div class="w-50 pe-2 ps-2">
              <app-drop-down
                [name]="'Preferred language'"
                (saveModel)="saveModel('preferredLanguage', caseStakeholder.person.preferredLanguageCode)"
                [items]="preferredLanguages"
                [(selectedItemId)]="caseStakeholder.person.preferredLanguageCode"
                [disabled]="disable"
              >
              </app-drop-down>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex stakeholder-column pt-2">
      <div class="w-100 pe-2">
        <h1 class="heading-1"><span>Address</span></h1>
      </div>
    </div>
    <div class="d-flex stakeholder-column pt-2">
      <div class="w-50 pe-2">
        <span class="input-name">Address</span>
        <input
          placeholder=""
          autocorrect="off"
          autocapitalize="off"
          spellcheck="off"
          type="text"
          #addressInput
          class="input-component"
          [(ngModel)]="caseStakeholder.address.street"
          [disabled]="disable"
          (change)="saveModel('address', caseStakeholder.address.street)"
          [placeholder]="caseStakeholder.stakeholderType === 'REPORTER' ? ('case-stakeholder-optional-placeholder' | translate) : ''"
          [ngClass]="getAddressClass(caseStakeholder, disable)"
        />
      </div>
      <div class="w-25 ps-2 pe-2">
        <app-input
          [name]="'Postal code'"
          [(value)]="caseStakeholder.address.postalCode"
          [inputTypePattern]="InputTypePattern.all"
          (saveModel)="saveModel('postalCode', caseStakeholder.address.postalCode)"
          [maxTextLength]="250"
          [disabled]="disable"
          [placeholder]="caseStakeholder.stakeholderType === 'REPORTER' ? ('case-stakeholder-optional-placeholder' | translate) : ''"
          [recommended]="caseStakeholder.stakeholderType !== 'REPORTER' && !caseStakeholder.address.postalCode"
        >
        </app-input>
      </div>
      <div class="w-25 ps-2">
        <app-input
          [name]="'City'"
          [maxTextLength]="250"
          [(value)]="caseStakeholder.address.city"
          (saveModel)="saveModel('city', caseStakeholder.address.city)"
          [disabled]="disable"
          [placeholder]="caseStakeholder.stakeholderType === 'REPORTER' ? ('case-stakeholder-optional-placeholder' | translate) : ''"
          [recommended]="caseStakeholder.stakeholderType !== 'REPORTER' && !caseStakeholder.address.city"
        >
        </app-input>
      </div>
    </div>
    <div class="d-flex stakeholder-column pt-2">
      <div class="w-25 pe-2"></div>
      <div class="w-25 ps-2 pe-2">
        <div class="local-time-container">
          <div class="local-time-text">{{ 'case-stakeholders-info-local-time' | translate }}</div>
          <div class="local-time">
            {{ now | date: 'HH:mm:ss' }}
          </div>
        </div>
      </div>
      <div class="w-25 ps-2 pe-2">
        <app-input
          [name]="'Region/State'"
          [maxTextLength]="250"
          [disabled]="disable"
          [(value)]="caseStakeholder.address.region"
          (saveModel)="saveModel('region', caseStakeholder.address.region)"
        >
        </app-input>
      </div>
      <div class="w-25 ps-2">
        <app-drop-down-countries-auto-complete
          [name]="'case-stakeholders-info-country' | translate"
          #homeCountry
          [disabled]="disable"
          [autoCompleteTypeEnum]="AutoCompleteTypeEnum.Countries"
          [withInput]="false"
          [(selectedItemId)]="caseStakeholderCountry"
          (selectedItemIdChange)="homeCountryChanged($event)"
          [showCode]="false"
          [recommended]="caseStakeholder.stakeholderType !== 'REPORTER'"
        >
        </app-drop-down-countries-auto-complete>
      </div>
    </div>


    <div class="stakeholder-column" *ngIf="caseStakeholder.company != null">
      <div *ngFor="let contactPerson of caseStakeholder.company.contactPersons; index as i">
        <div class="d-flex">
          <div class="w-100">
            <h1 class="heading-1"><span>{{ 'company-contact-person' | translate }}</span></h1>
          </div>
        </div>
        <div class="d-flex">
          <div class="w-25 pe-2">
            <app-input
              [name]="'company-contact-firstname' | translate"
              [maxTextLength]="250"
              [(value)]="contactPerson.firstName"
              (saveModel)="saveModelContactPerson(contactPerson.id, contactPerson)"
              [disabled]="disable"
              [recommended]="!contactPerson.firstName"
            >
            </app-input>
          </div>
          <div class="w-25 ps-2 pe-2">
            <app-input
              [name]="'company-contact-surname' | translate"
              [maxTextLength]="250"
              [(value)]="contactPerson.surname"
              (saveModel)="saveModelContactPerson(contactPerson.id, contactPerson)"
              [disabled]="disable"
              [recommended]="!contactPerson.surname"
            >
            </app-input>
          </div>
          <div class="w-25  ps-2 pe-2">
            <app-phone-drop-down
              [disabled]="disable"
              [name]="'company-contact-telephone' | translate"
              [withPrefixInput]="true"
              [withInput]="true"
              [showCode]="true"
              (saveModel)="saveModelContactPersonPhone($event, i, PhoneNumberType.Fixed)"
              [(selectedItemId)]="getPhoneNumber(i).countryCode"
              (selectedItemIdChange)="saveModelContactPersonPhone($event, i, PhoneNumberType.Fixed)"
              [placeholder]="getPhoneNumber(i).number"
              [(prefixValue)]="getPhoneNumber(i).prefix"
              [(value)]="getPhoneNumber(i).number"
              [recommended]="true"
            >
            </app-phone-drop-down>
          </div>
          <div class="w-25  ps-2">
            <app-drop-down
              [name]="'company-contact-preferred-channel' | translate"
              (saveModel)="saveModelContactPerson(contactPerson.id, contactPerson)"
              [items]="preferredChannels"
              [(selectedItemId)]="contactPerson.preferredChannelCode"
              [disabled]="disable"
            >
            </app-drop-down>
            <div
              class="delete-contact-button"
              (click)="deleteContactPerson(contactPerson.id)"
              *ngIf="caseStakeholder.company.contactPersons.length > 1 && i !== 0"
            >
              <img src="/assets/icons/garbage.svg" height="16px" />
            </div>
          </div>
        </div>
        <div class="d-flex mt-2">
          <div class="w-25 pe-2">
          </div>
          <div class="w-25 ps-2 pe-2">
            <app-input
              [name]="'company-contact-email' | translate"
              [maxTextLength]="250"
              [(value)]="contactPerson.email"
              [validationType]="ValidationTypeEnum.emailAndValidOrEmpty"
              [validate]="true"
              (saveModel)="saveEmailContactPerson(contactPerson.id, contactPerson)"
              [disabled]="disable"
            ></app-input>
          </div>
          <div class="w-25 ps-2 pe-2">
            <app-phone-drop-down
              [disabled]="disable"
              [name]="'company-contact-fax' | translate"
              [withPrefixInput]="true"
              [withInput]="true"
              [showCode]="true"
              (saveModel)="saveModelContactPersonPhone($event, i, PhoneNumberType.Fax)"
              [(selectedItemId)]="getFaxNumber(i).countryCode"
              (selectedItemIdChange)="saveModelContactPersonPhone($event, i, PhoneNumberType.Fax)"
              [placeholder]="getFaxNumber(i).number"
              [(prefixValue)]="getFaxNumber(i).prefix"
              [(value)]="getFaxNumber(i).number"
              [recommended]="true"
            ></app-phone-drop-down>
          </div>
          <div class="w-25 ps-2">
            <app-drop-down
              [name]="'company-contact-preferred-language' | translate"
              (saveModel)="saveModelContactPerson(contactPerson.id, contactPerson)"
              [items]="preferredLanguages"
              [(selectedItemId)]="contactPerson.preferredLanguageCode"
              [disabled]="disable"
            >
            </app-drop-down>
          </div>
        </div>
        <div class="d-flex">
          <div class="w-100 text-start">
            <app-multiple-select-dropdown [name]="'company-contact-person-roles' | translate" [dropdownList]="contactPersonRoles"
                                          (selectedItemsChange)="updateContactRoles($event, contactPerson.id, contactPerson) "
                                          [(selectedItems)]="contactPerson.propertyTypesSelected"
                                          [recommended]="contactPerson.propertyTypes?.length === 0">

            </app-multiple-select-dropdown>
          </div>
        </div>
        <div class="d-flex">
          <div class="w-50 pe-2 mt-2">
            <app-input
              [name]= "'Remarks' | translate"
              [maxTextLength]="250"
              [(value)]="contactPerson.remarks"
              [placeholder]="remarksPlaceholder"
              (saveModel)="saveModelContactPerson(contactPerson.id, contactPerson)"
              [disabled]="disable"
            >
            </app-input>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <div class="w-25 mt-3">
          <button class="add-contact-button" (click)="addContactPerson()" [disabled]="disable">{{'company-contact-add-person' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
