import { Component, Inject, ViewChild } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { DialogBoundryService } from "@secca/core/services/dialog-boundry.service";
import { DialogHelperUtilService } from "@secca/core/services/dialog-helper-util.service";
import { DialogMinimizableDirective } from "@secca/shared/directives/dialog-minimizable.directive";
import { MinimizableDialogType } from "@secca/shared/enums/minimizable-dialog-type-enum";
import { AdditionalInfo, IAdditionalInfo } from "@secca/shared/interfaces/additional-info";
import { IKeyboardEnabled } from "@secca/shared/interfaces/keyboard-enabled";
import { IMinimizable } from "@secca/shared/interfaces/minimizable";
import { ProfileLookupComponent } from "../profile-lookup/profile-lookup.component";
import { ProfileLookupDialogData } from "./profile-lookup-dialog-data.component";

@Component({
    selector: 'app-profile-lookup-dialog',
    templateUrl: './profile-lookup-dialog.component.html',
    styleUrls: ['./profile-lookup-dialog.component.scss'],
  })
  export class ProfileLookupDialogComponent implements IAdditionalInfo, IMinimizable, IKeyboardEnabled {
    @ViewChild(ProfileLookupComponent) wrappedInstance: ProfileLookupComponent;
    @ViewChild(DialogMinimizableDirective) minimizedDirective: DialogMinimizableDirective;

    data: ProfileLookupDialogData;

    constructor(private dialogRef: MatDialogRef<ProfileLookupDialogComponent>,
                @Inject(MAT_DIALOG_DATA) data: ProfileLookupDialogData,
                private dialogHelperUtilService: DialogHelperUtilService,
                public dialogBoundryService: DialogBoundryService) {
              
      this.data = data;
    }

    close() {
      this.dialogHelperUtilService.close(this.dialogRef, this.getAdditionalInfo()?.caseId);
    }

    keyboardClose(): void {
      this.wrappedInstance.close();
    }
    
    minimize() {
      this.minimizedDirective.minimize();
    }

    getMinimizeType(): MinimizableDialogType {
      return this.minimizedDirective.appDialogMinimizable;
    }

    getAdditionalInfo(): AdditionalInfo {

      return {
        caseId: +this.data.caseId,
        hoverTitle: this.data.caseNumber ? this.data.caseNumber : `CASE ID: ${this.data.caseId}`,
        hoverText: 'Profile lookup'
      };
    }

    get MinimizableDialogType() {
      return MinimizableDialogType;
    }
}
