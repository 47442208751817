<div class="d-flex justify-content-end header-amounts">
  <div class="col-8">
    <div class="d-flex">
      <div *permission="PermissionEnum.RECOVERY_CREATE; hideType: PermissionHideTypeEnum.REMOVE">
        <button (click)="addNewRecovery()" class="primary-button ml-3" [class.primary-button-inactive]="disableAddRecovery()" [disabled]="disableAddRecovery()">
          <ng-template #popContent><span [innerHTML]="'case-economy-recovery-add-no-regres-configuration' | translate | safeHtml"></span></ng-template>
          <div [disablePopover]="disableAddRecoveryHover()" container="body" triggers="hover" placement="top" [ngbPopover]="popContent">
            {{ 'case-economy-recovery-add-recovery' | translate }}
          </div>
        </button>
      </div>
    </div>
  </div>
  <div class="col-2">
    <div class="row">
      <div class="col text-end amount-name">{{ 'case-economy-recovery-list-total-applied-amount' | translate }}</div>
    </div>
    <div class="row">
      <div class="col">
        <div class="d-flex">
          <div class="flex-fill text-end amount-value">{{ totalAppliedAmount | number: '.2-2' }}</div>
          <div class="amount-currency">{{ caseCurrency }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-2">
    <div class="row">
      <div class="col text-end amount-name">{{ 'case-economy-recovery-list-total-recovered-amount' | translate }}</div>
    </div>
    <div class="row">
      <div class="col">
        <div class="d-flex">
          <div class="flex-fill text-end amount-value">{{ totalRecoveredAmount | number: '.2-2' }}</div>
          <div class="amount-currency">{{ caseCurrency }}</div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="col-12 table-container" [class.zoomed]="settingsService.isZoomed()">
  <table class="primary-table fixed-header-table">
    <thead>
      <tr>
        <td class="invoice-id">
          <div style="float: left;">
            {{ 'case-economy-recovery-list-invoice-id' | translate }}
          </div>
        </td>
        <td
          class="thead dates"
          sortColumn
          [sortKey]="'createdOn'"
          (sortEvent)="setSortKey($event)"
          [data]="filteredRecoveryList"
          [ngClass]="{ 'sort-selected': sortKey === 'createdOn' }"
        >
          {{ 'case-economy-recovery-list-created-date' | translate }}
        </td>
        <td
          class="thead dates"
          sortColumn
          [sortKey]="'dueDate'"
          (sortEvent)="setSortKey($event)"
          [data]="filteredRecoveryList"
          [ngClass]="{ 'sort-selected': sortKey === 'dueDate' }"
        >
          {{ 'case-economy-recovery-list-due-date' | translate }}
        </td>
        <td
          class="thead dates"
          sortColumn
          [sortKey]="'applicationDeadline'"
          (sortEvent)="setSortKey($event)"
          [data]="filteredRecoveryList"
          [ngClass]="{ 'sort-selected': sortKey === 'applicationDeadline' }"
        >
          {{ 'case-economy-recovery-list-deadline-date' | translate }}
        </td>
        <td
          class="thead processStatus"
          sortColumn
          [sortKey]="'recoveryStatus'"
          (sortEvent)="setSortKey($event)"
          [data]="filteredRecoveryList"
          [ngClass]="{ 'sort-selected': sortKey === 'recoveryStatus' }"
        >
          {{ 'case-economy-recovery-list-progress' | translate }}
        </td>
        <td
          class="thead"
          sortColumn
          [sortKey]="'totalAppliedAmount'"
          (sortEvent)="setSortKey($event)"
          [data]="filteredRecoveryList"
          [ngClass]="{ 'sort-selected': sortKey === 'totalAppliedAmount' }"
        >
          {{ 'case-economy-recovery-list-applied-amount' | translate }}
        </td>
        <td
          class="thead"
          sortColumn
          [sortKey]="'totalRecoveredAmount'"
          (sortEvent)="setSortKey($event)"
          [data]="filteredRecoveryList"
          [ngClass]="{ 'sort-selected': sortKey === 'totalRecoveredAmount' }"
        >
          {{ 'case-economy-recovery-list-recovered-amount' | translate }}
        </td>
      </tr>
    </thead>
    <tbody class="theBody">
      <ng-container *ngFor="let entry of filteredRecoveryList; index as i">
        <tr class="odd">
          <td class="invoice-id">
            <div class="invoice-id-row">
              <div class="invoice-number">
                {{ entry.recoveryNumber }}
              </div>
            </div>
            <div class="invoice-id-row">
              <div class="manage" (click)="openManageRecovery(entry)">
                {{ 'case-economy-recovery-manage' | translate }}
              </div>
            </div>
            <div *ngIf="entry.internalRemark">
              <span class="label-name">{{ 'case-economy-recovery-internal-remark' | translate }}:</span> {{entry.internalRemark}}
            </div>
          </td>
          <td>
            <div>
            {{ entry.createdOn | date: 'dd LLL yyyy' }}
            </div>
          </td>
          <td>
            {{ entry.dueDate | date: 'dd LLL yyyy' }}
          </td>
          <td>
            {{ entry.applicationDeadline | date: 'dd LLL yyyy' }}
          </td>
          <td class="pt-2 process-bar-column">
            <app-economy-status-bar
              [numberOfStatus]="numberOfStatus"
              [maxStatus]="numberOfStatus"
              [cancelled]="entry.recoveryStatus === RecoveryStatus.CANCELLED || entry.recoveryStatus === RecoveryStatus.DELETED"
              [statusText]="'case-economy-recovery-recovery-status-' + entry.recoveryStatus | translate"
              [statusIndex]="getStatusValues(entry).statusIndex"
              [redIconIndex]="getStatusValues(entry).redIconIndex"
              [warnIconIndex]="getStatusValues(entry).warnIconIndex"
              [histotyType]="entry.recoveryType"
              [redIconText]="'case-economy-recovery-recovery-status-' + (entry.getRedStatusText()) | translate"
              [invoiceNumber]="entry.recoveryNumber"
              [greenIndexHistoryMap]="getGreenIndexHistoryMap(entry)"
              [redIndexHistoryMap]="getRedIndexHistoryMap(entry)"
            ></app-economy-status-bar>
          </td>
          <td>
            {{ entry.totalAppliedAmount | number: '.2-2' }}
          </td>
          <td>
            {{ entry.totalRecoveredAmount | number: '.2-2' }}
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
