import { GlobalSearchPageComponent } from '@secca/global-search/pages/global-search-page/global-search-page.component';
import { BoardPageComponent } from '@secca/board/pages/board-page/board-page.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OpenCaseLimitService } from '@secca/core/services/open-case-limit.service';
import { DialogsActiveGuardService } from '@secca/core/services/dialogs-active-guard.service';
import { CasePageComponent } from '@secca/case/pages/case-page/case-page.component';

const routes: Routes = [
  { path: 'case/:id', component: CasePageComponent, canActivate: [OpenCaseLimitService, DialogsActiveGuardService] },
  { path: 'board', component: BoardPageComponent, canActivate: [DialogsActiveGuardService] },
  { path: 'global-search', component: GlobalSearchPageComponent },
  { path: '**', redirectTo: '/board', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {}),
    RouterModule.forRoot(routes,
            {
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
