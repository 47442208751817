import { Injectable } from "@angular/core";
import { MinimizableDialogType } from "@secca/shared/enums/minimizable-dialog-type-enum";
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { AutoUnsubscribe } from "@secca/shared/decorators/auto-unsubscribe";
import { AdditionalInfo } from "@secca/shared/interfaces/additional-info";

@Injectable({
    providedIn: 'root'
})
@AutoUnsubscribe
export class DialogMinimizedStorageService {
    private minimizedDialogs: Map<string, DialogMinimizedData> = new Map();

    constructor(private caseStateService: CaseStateService) {
    }

    public getMinimized(): Map<string, DialogMinimizedData> {
      return this.minimizedDialogs;
    }

    public getMinimizedList(): DialogMinimizedData[] {
      return Array.from(this.minimizedDialogs.values());
    }

    public getMinimizedFromDialogId(dialogId: string): DialogMinimizedData {
      return this.minimizedDialogs.get(dialogId);
    }

    public addMinimized(dialogId:string, dialogType: MinimizableDialogType, isGlobalBoardDialog: boolean, additionalInfo: AdditionalInfo) {
      this.minimizedDialogs.set(dialogId, {caseId: additionalInfo?.caseId, dialogId, dialogType, isGlobalBoardDialog, additionalInfo});
    }

    public removeMinimized(dialogId: string) {
      this.minimizedDialogs.delete(dialogId);
    }

    public get count(): number {
      return this.minimizedDialogs.size;
    }
}

export interface DialogMinimizedData {
  dialogId: string;
  caseId: number;
  dialogType: MinimizableDialogType;
  isGlobalBoardDialog?: boolean;
  additionalInfo: AdditionalInfo;
}
