
import { MomentHelperService } from '@secca/core/services/moment-helper.service';
import * as moment from 'moment';
import { Location } from '@secca/shared/models/location';
import { ServiceOrderExtension } from './service-order-extension';
import { CommitmentType, CommitmentTypeAdapter } from '../commitment-type';
import { StatusTypes, TransportTypeEnum } from '@secca/shared/models/enums';
import { ICCStakeholder, ICCStakeHolderAdapter } from '@secca/case/components/case-plans/case-plans/add-service-order/models/icc-stakeholder';
import { SubRemarksToSupplier } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subRemarksToSupplier';
import { SubTravelInfo, SubTravelInfoAdapter } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subTravelInfo';
import { ICCStakeholders } from '@secca/case/components/case-plans/case-plans/add-service-order/models/icc-stakeholders';
import { ICCFlightLegRelevantForCaseAdapter } from '@secca/case/components/case-plans/case-plans/add-service-order/models/icc-flightLegsRelevantForCase';
import { ICCAmbulanceFlight, ICCAmbulanceFlightAdapter } from '@secca/case/components/case-plans/case-plans/add-service-order/models/icc-ambulanceFlight';
import { SubTransportType } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subTransportType';
import { IccRepatriationInfo, IccRepatriationInfoAdapter, IccRepatriationInfos } from './icc-repatriation-info';
import { SubRemarksFromSupplier } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subRemarksFromSupplier';
import { SubStakeholder } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholder';
import { SubStakeholders } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholders';
import { ICCLinkedCaseRemark, ICCLinkedCaseRemarkAdapter, ICCLinkedCaseRemarks } from '@secca/case/components/case-plans/case-plans/add-service-order/models/icc-linkedCaseRemark';


export class CoordinationTransportServiceOrderExtension implements ServiceOrderExtension {
  displayDurationId: number;
  id: number;
  startDate: moment.Moment = MomentHelperService.getSeccaTime().startOf('day').utc();
  adviceGiven: string;
  commitmentType: CommitmentType;
  transportType: SubTransportType;
  iccExpectedFlightStakeholders: ICCStakeholders;
  expectedFlightStakeholders: ICCStakeholder[];
  iccConfirmationFlightStakeholders: ICCStakeholders;
  confirmationFlightStakeholders: ICCStakeholder[];
  remarksToSupplier: SubRemarksToSupplier;
  confirmationFlight: SubTravelInfo[] = [];
  expectedFlight: SubTravelInfo[] = [];
  iccExpectedAmbulanceFlights: ICCAmbulanceFlight[] = [];
  iccConfirmationAmbulanceFlights: ICCAmbulanceFlight[] = [];
  iccRepatriationInfos: IccRepatriationInfos;
  repatriationInfos: IccRepatriationInfo[];
  remarksFromSupplier: SubRemarksFromSupplier;
  icclinkedCasesRemarks: ICCLinkedCaseRemarks;
  linkedCasesRemarks: ICCLinkedCaseRemark[];


    // Only used for display purposes in plans
  displayFlightLegId = 0;
  serviceOrderId: number;
  private caseDetailsCaseNumbersForValidation: Map<number, string> = new Map<number, string>();


  public constructor(init?: Partial<CoordinationTransportServiceOrderExtension>) {
    if (init) {
      Object.assign(this, init);
    }
    if (!init) {
      this.init();
    }
    this.expectedFlightStakeholders = this.iccExpectedFlightStakeholders.stakeholders;
    this.repatriationInfos = this.iccRepatriationInfos.repatriationInfos;
    this.linkedCasesRemarks = this.icclinkedCasesRemarks.iccLinkedCaseRemarks;
  }

  isPreviewGopValid(): boolean {
    throw new Error('Gop preview not supported for this service order type');
  }

  getMissingFields(committed: boolean, caseDetailtCaseNumbersForValidation: Map<number, string>): string[] {
    this.caseDetailsCaseNumbersForValidation = caseDetailtCaseNumbersForValidation;
    const missingFields = this.getMissingFieldsExpectation();
    if (committed) {
      return this.getMissingFieldsCommitted();
    } else {
      return missingFields;
    }
  }

  getMissingFieldsExpectation() {
    const fields: string[] = [];
    if (this.expectedFlight.find(f => f.departureDate == null || !f.departureDate.isValid())) {
      fields.push('departuredate');
    }
    if (this.expectedFlight.find(f => !f.departureFrom.freeText)) {
      fields.push('departurefrom');
    }
    if (this.expectedFlight.find(f => !f.arrivalAt.freeText)) {
      fields.push('arrivalat');
    }

    if (this.iccExpectedFlightStakeholders.stakeholders.find(sh => !sh.stakeholderId)) {
      fields.push('stakeholder');
    }
    if (this.iccExpectedFlightStakeholders.stakeholders.find(sh => !sh.serviceOrderId)) {
      fields.push('stakeholderserviceorder');
    }

    const caseList = Array.from(new Set(this.expectedFlightStakeholders?.map(fsh => fsh.caseId)));

    this.expectedFlight.forEach(
        flight => {
          let rfcs: any[];
          rfcs = flight.relevantForCaseIds.map(rfc => rfc.linkedCaseId);
          const missingCases = caseList.filter(caseId => rfcs.indexOf(+caseId) < 0 );
          if (missingCases.length > 0 && fields.indexOf('relevantforcase') < 0) {
            missingCases.forEach(missingCase => {
              fields.push('relevantforcase ' + this.caseDetailsCaseNumbersForValidation.get(+missingCase));
            })
          }
        }
    );
    return fields;
  }

  getMissingFieldsCommitted() {
    const fields: string[] = [];
    if (this.confirmationFlight.find(f => f.departureDate == null || !f.departureDate.isValid())) {
      fields.push('departuredate');
    }

    if (this.confirmationFlight.find(f => f.arrivalDate == null || !f.arrivalDate.isValid())) {
      fields.push('arrivaldate');
    }

    if (this.confirmationFlight.find(f => !f.departureFrom.freeText)) {
      fields.push('departurefrom');
    }
    if (this.confirmationFlight.find(f => !f.arrivalAt.freeText)) {
      fields.push('arrivalat');
    }
    if (this.iccConfirmationFlightStakeholders.stakeholders.find(sh => !sh.stakeholderId)) {
      fields.push('stakeholder');
    }

    if (this.iccConfirmationFlightStakeholders.stakeholders.find(sh => !sh.serviceOrderId)) {
      fields.push('stakeholderserviceorder');
    }

    this.iccConfirmationFlightStakeholders.stakeholders.forEach(sh => {
      if(sh.passengerConfirmed === undefined) {
        fields.push('passengerConfirmed ' + this.caseDetailsCaseNumbersForValidation.get(+sh.caseId));
      }
    })

    const caseList = Array.from(new Set(this.confirmationFlightStakeholders?.map(fsh => fsh.caseId)));
    this.confirmationFlight.forEach(
        flight => {
          let rfcs: any[];
          rfcs = flight.relevantForCaseIds.map(rfc => rfc.linkedCaseId);
          const missingCases = caseList.filter(caseId => rfcs.indexOf(+caseId) < 0);
          if (missingCases.length > 0 && fields.indexOf('relevantforcase') < 0) {
            missingCases.forEach(missingCase => {
              fields.push('relevantforcase ' + this.caseDetailsCaseNumbersForValidation.get(+missingCase));
            })
          }
        }
    );

    if (this.confirmationFlight.find(f => !f.flightNo)) {
      fields.push('flightno');
    }
    if (this.confirmationFlight.find(f => !f.agencyReference)) {
      fields.push('flightregno');
    }
    return fields;
  }

  isValid(): boolean {
    let confirmationValid = true;
    const flightStakeholdersValid = this.iccConfirmationFlightStakeholders?.isValid();
    this.confirmationFlight?.forEach(flightLeg => {
      if (!flightLeg.isValid()) {
        confirmationValid = false;
      }
    });
    return confirmationValid && flightStakeholdersValid;
  }

  isValidExpectation(): boolean {
    let expectedFlightValid = true;
    const flightStakeholdersValid = this.iccExpectedFlightStakeholders.isValid();
    this.expectedFlight.forEach(flightLeg => {
      if (!flightLeg.isValidExpectation()) {
        expectedFlightValid = false;
      }
    });
    return expectedFlightValid && flightStakeholdersValid;
  }

  init() {
    this.transportType = new SubTransportType(TransportTypeEnum.GROUND_AMBULANCE);
    this.remarksToSupplier = new SubRemarksToSupplier();
    this.iccExpectedFlightStakeholders = new ICCStakeholders();

    this.expectedFlight.push(new SubTravelInfo());
    this.iccRepatriationInfos = new IccRepatriationInfos();
    this.icclinkedCasesRemarks = new ICCLinkedCaseRemarks();
  }

  getStartDate(status: StatusTypes): moment.Moment {
    return this.getFlight(status)[this.displayFlightLegId].departureDate;
  }

  getEndDate(status: StatusTypes): moment.Moment {
    let arrivalDate = null;

    if (status === StatusTypes.COMMITTED || status === StatusTypes.CANCELLED) {
      arrivalDate = this.getFlight(status)[this.confirmationFlight.length - 1].arrivalDate;
    }
    else {
      arrivalDate = this.getFlight(status)[this.expectedFlight.length - 1].arrivalDate;
    }

    return arrivalDate ? arrivalDate : this.getStartDate(status);
  }

  getTravelInfoForCase(linkedCaseId) : SubTravelInfo[] {
    return this.getTravelInfoRelevantForCase(linkedCaseId);
  }

  private getTravelInfoRelevantForCase(linkedCaseId): SubTravelInfo[] {
    return this.confirmationFlight.filter(ti => ti.relevantForCaseIds.find(rfc => rfc.linkedCaseId === linkedCaseId ))
  }

  getStakeHoldersForEndUserServiceOrder(serviceOrderId: string, statusType: StatusTypes): string[] {
    if (StatusTypes.EXPECTED == statusType){
      return this.iccExpectedFlightStakeholders.byServiceOrder(serviceOrderId).map(iccSH =>  iccSH.stakeholderId);
    } else {
      return this.iccConfirmationFlightStakeholders.byServiceOrder(serviceOrderId).map(iccSH =>  iccSH.stakeholderId);
    }
  }

  getFlightStakeHolders(status: StatusTypes): ICCStakeholders {
    if (StatusTypes.COMMITTED == status) {
      return this.iccConfirmationFlightStakeholders;
    } else {
      return this.iccExpectedFlightStakeholders
    }
  }
  getEndLocation(status: StatusTypes): Location {
    return this.getEndTravelInfo(status);
  }

  private getStartTravelInfo() {
      return this.expectedFlight[0];
  }

  isMultipleDurations(): boolean {
    return false;
  }

  setCommitmentType(commitmentType: CommitmentType) {
    this.commitmentType = commitmentType;
  }

  getCommitmentType(): CommitmentType {
    return this.commitmentType;
  }

  getSelectedStakeholderIds(): string[] {
    return null;
  }

  setRemarksToSupplier(subRemarksToSupplier: SubRemarksToSupplier) {
    this.remarksToSupplier = subRemarksToSupplier;
  }


  getRemarksToSupplier(): SubRemarksToSupplier {
    return this.remarksToSupplier;
  }

  setFlight(expectedFlight: SubTravelInfo[]) {
    this.expectedFlight = expectedFlight;
  }


  getStartLocation(status: StatusTypes): Location {
    return this.getFlight(status)[this.displayFlightLegId].departureFrom;
  }

  private getEndTravelInfo(status: StatusTypes):Location {
    if (status === StatusTypes.COMMITTED || status === StatusTypes.CANCELLED) {
      return this.getFlight(status)[this.confirmationFlight.length - 1].arrivalAt;
    }
    else {
      return this.getFlight(status)[this.expectedFlight.length - 1].arrivalAt;
    }
  }

  getStartDisplayDate(status: StatusTypes): moment.Moment {
    return this.getFlight(status)[this.displayFlightLegId].departureDisplayDate;
  }

  getConfirmationAmbulanceFlightforCase(caseId: any) : ICCAmbulanceFlight{
    return this.iccConfirmationAmbulanceFlights.find(cf => ""+cf.linkedCaseId === ""+caseId)
  }

  getEndDisplayDate(status: StatusTypes): moment.Moment {
    if (status === StatusTypes.COMMITTED || status === StatusTypes.CANCELLED) {
      return this.getFlight(status)[this.confirmationFlight.length - 1].arrivalDisplayDate;
    }
    else {
      return this.getFlight(status)[this.expectedFlight.length - 1].arrivalDisplayDate;
    }
  }

  getFlight(status: StatusTypes): SubTravelInfo[] {
    if (status === StatusTypes.COMMITTED || status === StatusTypes.CANCELLED) {
      return this.confirmationFlight;
    }
    else {
      return this.expectedFlight;
    }
  }

  hasMedicalEscort(): boolean {
    return false;
  }

  hasCoTravellers(status: StatusTypes): boolean {
    return null;
  }
}

export class CoordinationTransportServiceOrderExtensionAdapter {
  commitmentTypeAdapter: CommitmentTypeAdapter = new CommitmentTypeAdapter();
  iccStakeholderAdapter: ICCStakeHolderAdapter = new ICCStakeHolderAdapter();
  iccRepatriationInfoAdapter: IccRepatriationInfoAdapter = new IccRepatriationInfoAdapter();
  iccLinkedCaseRemarkAdapter: ICCLinkedCaseRemarkAdapter = new ICCLinkedCaseRemarkAdapter();
  adapt(item?: any): CoordinationTransportServiceOrderExtension {
    const commitmentTypeAdapter: CommitmentTypeAdapter = new CommitmentTypeAdapter();
    const iccStakeholderAdapter: ICCStakeHolderAdapter = new ICCStakeHolderAdapter();
    const iccAmbulanceFlightAdapter: ICCAmbulanceFlightAdapter = new ICCAmbulanceFlightAdapter();
    const subTravelInfoAdapter = new SubTravelInfoAdapter();
    const iccFlightLegsRelevantForCaseAdapter = new ICCFlightLegRelevantForCaseAdapter();
    const confirmationFlightAdapted = item.confirmationFlight ? item.confirmationFlight?.map(flightLeg => subTravelInfoAdapter.adapt(flightLeg)) : [];
    const expectedFlightAdapted = item.expectedFlight?.map(flightLeg => subTravelInfoAdapter.adapt(flightLeg));
    return new CoordinationTransportServiceOrderExtension({
      id: item.id,
      commitmentType: item.commitmentType != null ?  commitmentTypeAdapter.adapt(item.commitmentType) : null,//TODO DEFAULTnew SubCommitmentType(CommitmentTypes.EXPECTED),
      transportType: item.transportType,
      iccExpectedFlightStakeholders: item.expectedFlightStakeholders ? new ICCStakeholders(item.expectedFlightStakeholders.map(stakeholder => iccStakeholderAdapter.adapt(stakeholder))) : new ICCStakeholders(),
      iccConfirmationFlightStakeholders: item.confirmationFlightStakeholders ? new ICCStakeholders(item.confirmationFlightStakeholders.map(stakeholder => iccStakeholderAdapter.adapt(stakeholder))) : new ICCStakeholders(),
      remarksToSupplier: item.remarksToSupplier ? new SubRemarksToSupplier(item.remarksToSupplier) : new SubRemarksToSupplier(),
      confirmationFlight: confirmationFlightAdapted,
      expectedFlight: expectedFlightAdapted,
      iccExpectedAmbulanceFlights: item.iccExpectedAmbulanceFlights ? item.iccExpectedAmbulanceFlights?.map(ambulanceFlight => iccAmbulanceFlightAdapter.adapt(ambulanceFlight)): [],
      iccConfirmationAmbulanceFlights: item.iccConfirmationAmbulanceFlights ? item.iccConfirmationAmbulanceFlights?.map(ambulanceFlight => iccAmbulanceFlightAdapter.adapt(ambulanceFlight)) : [],
      iccRepatriationInfos: item.repatriationInfos ?  new IccRepatriationInfos(item.repatriationInfos.map(info => this.iccRepatriationInfoAdapter.adapt(info))) : new IccRepatriationInfos(),
      remarksFromSupplier: item.remarksFromSupplier ? new SubRemarksFromSupplier(item.remarksFromSupplier) : new SubRemarksFromSupplier(),
      confirmationFlightStakeholders: item.confirmationFlightStakeholders ? item.confirmationFlightStakeholders.map(stakeholder => iccStakeholderAdapter.adapt(stakeholder)) : [],
      icclinkedCasesRemarks: item.linkedCasesRemarks ? new ICCLinkedCaseRemarks(item.linkedCasesRemarks.map(remark => this.iccLinkedCaseRemarkAdapter.adapt(remark))) : new ICCLinkedCaseRemarks(),
    });
  }
}
