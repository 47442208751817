import { Adapter } from 'src/app/shared/interfaces/adapter';
import { Injectable } from '@angular/core';
import { userDepartment, UserDepartmentAdapter  } from './userDepartment';
import { UserTeam, UserTeamAdapter  } from './userTeam';

export class UserDto {
  active: boolean;
  firstName: string;
  fullName: string;
  fullNameAndInitialt: string;
  id: number;
  initials: string;
  picture: string;
  surname: string;
  userDepartment: userDepartment;
  userRole: string;
  userTeam: UserTeam;
  userTitle: string;
  userId: string;

  public constructor(init?: Partial<UserDto>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class UserDtoAdapter implements Adapter<UserDto> {
  adapt(item: any): UserDto {
    return new UserDto({
    active: item.active,
    firstName: item.firstName,
    fullName: item.firstName + ' ' + item.surname,
    fullNameAndInitialt: item.firstName + ' ' + item.surname + ', ' + item.initials,
    id: item.id,
    initials: item.initials,
    picture: item.picture,
    surname: item.surname,
    userDepartment: !!item.userDepartment ? new UserDepartmentAdapter().adapt(item.userDepartment) : null,
    userRole: item.userRole,
    userTeam: !!item.userTeam ? new UserTeamAdapter().adapt(item.userTeam) : null,
    userTitle: item.userTitle,
    userId: item.userId
    });
  }
}
