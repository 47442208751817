import * as moment from "moment-timezone";
import { StatusTypes } from "../enums";

export abstract class CommonServiceOrderExtension {

  abstract getStartDate(status?: StatusTypes): moment.Moment;
  abstract getEndDate(status?: StatusTypes): moment.Moment;

  getStartDisplayDate(status?: StatusTypes): moment.Moment {
    return this.getStartDate(status);
  }

  getEndDisplayDate(status?: StatusTypes): moment.Moment {
    return this.getEndDate(status);
  }
}