import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { Adapter } from '../interfaces/adapter';
import { CaseDocumentTag, CaseDocumentTagAdapter } from './case-document-tag';
import { DateSerializer } from './date-serializer';
import {DocumentGroupChannel} from '@secca/shared/models/enums';
import {FileScan, FileScanAdapter} from '@secca/shared/models/fileScan';

export class CaseDocument {
  id: number;
  documentName: string;
  documentExtension: string;
  documentId: string;
  documentType: string;
  documentDate: moment.Moment;
  isOutgoing: boolean;
  sender: string;
  recipient: string;
  caseDocumentTags: CaseDocumentTag[];
  comments: string;
  status: string;
  unhandled: boolean;
  deleted: boolean;
  deletedBy: string;
  stakeholderSenderId: number;
  stakeholderReceiverId: number;
  documentGroupChannel: DocumentGroupChannel;
  fileScan: FileScan;

  public constructor(init?: Partial<CaseDocument>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
export class CaseDocumentAdapter implements Adapter<CaseDocument> {
  constructor(private caseDocumentTagAdapter: CaseDocumentTagAdapter, private fileScanAdapter: FileScanAdapter) {}

  adapt(item: any): CaseDocument {
    const documentFileName = item.documentName.split('.');
    let extension = documentFileName.length > 1 ? documentFileName[documentFileName.length - 1] : '';
    if (extension.length > 5) {
      extension = '';
    }
    const extensionIndex = extension === '' ? item.documentName.length : item.documentName.lastIndexOf(extension) - 1;
    return new CaseDocument({
      id: item.id,
      documentName: item.documentName.substring(0, extensionIndex),
      documentExtension: extension,
      documentId: item.documentId,
      documentDate: DateSerializer.deserialize(item.creationDate),
      isOutgoing: item.outgoing,
      documentType: item.documentType,
      sender: item.sender,
      recipient: item.recipient,
      caseDocumentTags: item.caseDocumentTags ? item.caseDocumentTags.map(tag => this.caseDocumentTagAdapter.adapt(tag)) : undefined,
      status: item.documentStatus,
      comments: item.comments,
      unhandled: item.unhandled,
      deleted: item.deleted,
      deletedBy: item.deletedBy,
      stakeholderSenderId: item.stakeholderSenderId,
      stakeholderReceiverId: item.stakeholderReceiverId,
      documentGroupChannel: item.documentGroupChannel,
      fileScan: this.fileScanAdapter.adapt(item.fileScan)
    });
  }
}
