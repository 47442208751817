import { Component } from "@angular/core";

@Component({
  selector: 'app-task-modal-common-header',
  template: ''
})
export class TaskModalCommonHeaderComponent {
  
  trimText(text, len=50): string {
    if ( text?.length > len ) {
      return text.substring(0, len) + '...';
    }
    return text;
  }
}