  <ul
    ngbNav
    class="service-order-type-tabset nav-tabs"
    #nav="ngbNav"
    [(activeId)]="activeTab"
    (navChange)="tabChange($event)">
    <li ngbNavItem [id]="SelectedTab.ExpectationTab" [ngbNavItem]="SelectedTab.ExpectationTab">
      <a ngbNavLink>{{"service-type-expectation" | translate}}</a>

      <ng-template ngbNavContent>
        <div [appDisableInput]="caseLocked" class="caselock-no-events">
        <div class="sub-component-placeholder row">
            <div class="col-3" >
              <app-sub-transport-type [(subTranportType)]="groundAmbulance.transportType" [hideInnerBorder]="true"
                [availableTransportTypes]="availableTransportTypes">
              </app-sub-transport-type>
            </div>
            <div class="col-3 ">
              <app-sub-commitment-type
                [serviceOrderType]="serviceOrderType"
                [(commitmentType)]="groundAmbulance.commitmentType"
                [caseId]="caseId"
              ></app-sub-commitment-type>
            </div>
          </div>
        <div class="sub-component-placeholder">
          <app-sub-travellers
            [hideCabinClass]="true"
            [hideFlightInRefs]="true"
            [hideAddFlightLeg]="true"
            [caseId]="caseId"
            [(travellers)]="groundAmbulance.travellers"
            [stakeholders]="stakeholders"
            [(subStakeholders)]="groundAmbulance.subStakeholders"
            [subStakeholdersConfig]="subStakeholdersConfig"
            [arrivalDateRequired]="true"
            [departureDateRequired]="true"
            [arrivalAtRequired]="true"
            [departureFromRequired]="true"
            [serviceOrderType]="serviceOrderType">
          </app-sub-travellers>
        </div>
        <div class="sub-component-placeholder">
          <app-sub-remarks-to-supplier [(subRemarksToSupplier)]="groundAmbulance.remarksToSupplier">
          </app-sub-remarks-to-supplier>
        </div>
        <div>
          <app-gop-send-request [serviceOrderExtension]="groundAmbulance" [serviceOrderTypeState]="serviceOrderTypeState">
          </app-gop-send-request>
        </div>
      </div>
      </ng-template>
    </li>
    <li *ngIf="serviceOrderTypeState.showConfirmationTab" [id]="SelectedTab.ConfirmationTab" [ngbNavItem]="SelectedTab.ConfirmationTab">
      <a ngbNavLink>{{"service-type-confirmation" | translate}}<div class="close-tab-x" (click)="closeConfirmationTab($event)"
          *ngIf="!serviceOrderTypeState.isConfirmationTabSaved"><img src="/assets/icons/Close Black.svg"></div>
      </a>
      <ng-template ngbNavContent>
        <div [appDisableInput]="caseLocked" class="caselock-no-events">
          <div class="sub-component-placeholder">
            <app-sub-transport-type [(subTranportType)]="groundAmbulance.transportType" [availableTransportTypes]="availableTransportTypes">
            </app-sub-transport-type>
          </div>
          <div class="sub-component-placeholder">
            <app-sub-travellers
              [hideFlightInRefs]="true"
              [hideAddFlightLeg]="true"
              [caseId]="caseId"
              [stakeholderType]="StakeholderTypeEnum.person"
              [hideCabinClass]="true"
              [(travellers)]="groundAmbulance.travellers"
              [stakeholders]="stakeholders"
              [(subStakeholders)]="groundAmbulance.subStakeholders"
              [subStakeholdersConfig]="subStakeholdersConfig"
              [arrivalDateRequired]="true"
              [departureDateRequired]="true"
              [arrivalAtRequired]="true"
              [departureFromRequired]="true">
            </app-sub-travellers>
          </div>
          <div class="sub-component-placeholder">
            <app-sub-remarks-from-supplier [subRemarksFromSupplier]="groundAmbulance.remarksFromSupplier"></app-sub-remarks-from-supplier>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
