import { Injectable } from '@angular/core';
import { MedicalHistory, MedicalHistoryAdapter } from '../../shared/models/medicalHistory';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { BaseService } from './base.service';
import { SettingsService } from './settings.service';
import { map } from 'rxjs/operators';
import { MedicalAssessment, MedicalAssessmentAdapter } from '@secca/shared/models/medicalAssessment';
import { MedicalNote } from '@secca/shared/models/medical-models/medical-note';
import { SessionStorageService } from 'ngx-webstorage';
import { CaseDocument, CaseDocumentAdapter } from '@secca/shared/models/caseDocument';
import { MedicalAssessmentStateService } from '@secca/core/services/medical-assessment-state.service';
import { RepatriationInstructionsBase } from '@secca/shared/models/medical-models/repatriation-instructions-base';
import { MedicalAssessmentState } from '@secca/case/components/case-medical/medical-components/medical-models';
import { AssessmentTypeEnum, RepatriationMediumEnum } from '@secca/shared/models/enums';
import * as moment from 'moment';
import { User, UserAdapter } from '@secca/shared/models/user';
import { MedicalPreAssessment } from '@secca/shared/models/medicalPreAssessment';

@Injectable({
  providedIn: 'root',
})
export class MedicalService extends BaseService {
  static genderTypes = new Map<string, string>([
    ['F', 'Female'],
    ['M', 'Male'],
    ['O', 'Other'],
  ]);

  private regularMedicalAssessmentDraftPrefix = 'medical-assessment-regular-';

  private claimsReviewDraftPrefix = 'claims-review-';

  publishMedicalAssessmentHaveFollowUpPlaned = new Subject<boolean>();

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private sessionStorageService: SessionStorageService,
    private medicalAssessmentAdapter: MedicalAssessmentAdapter,
    private medicalHistoryAdapter: MedicalHistoryAdapter,
    private caseDocumentAdapter: CaseDocumentAdapter,
    private medicalAssessmentStateService: MedicalAssessmentStateService,
    private userAdapter: UserAdapter
  ) {
    super(settingsService);
  }

  public saveRegularAssessmentDraft(medicalAssessment: MedicalAssessment, caseId: number) {
    sessionStorage.setItem(this.regularMedicalAssessmentDraftPrefix + caseId, JSON.stringify(medicalAssessment));
  }

  public saveClaimsReviewToDraft(medicalAssessment: MedicalAssessment, caseId: number) {
    sessionStorage.setItem(this.claimsReviewDraftPrefix + caseId , JSON.stringify(medicalAssessment));
  }

  public getRegularAssessmentInDraft(caseId: number): MedicalAssessment {
    const draft = sessionStorage.getItem(this.regularMedicalAssessmentDraftPrefix + caseId);
    if (!!draft) {
      return this.medicalAssessmentAdapter.adapt(JSON.parse(draft));
    }
    return null;
  }

  public getClaimsReviewInDraft(caseId: number): MedicalAssessment {
    const draft = sessionStorage.getItem(this.claimsReviewDraftPrefix + caseId);
    if (!!draft) {
      return this.medicalAssessmentAdapter.adapt(JSON.parse(draft));
    }
    return null;
  }

  public removeRegularAssessmentDraft(caseId: number) {
    sessionStorage.removeItem(this.regularMedicalAssessmentDraftPrefix + caseId);
  }

  public removeClaimsReviewDraft(caseId: number) {
    sessionStorage.removeItem(this.claimsReviewDraftPrefix + caseId);
  }

  public publishMedicalAssessment(medicalAssessment: MedicalAssessment): Observable<any> {
    if(medicalAssessment.medicalAssessmentType !== AssessmentTypeEnum.CLAIMS) {
      this.publishNewMedicalAssessmentEvent(medicalAssessment);
    }
    return this.http.post<any>(this.baseURL + 'medical-assessment', this.nullifyInvalidAssessmentValues(medicalAssessment), {
      headers: this.jsonHeaders,
    });
  }

  public publishMedicalNote(medicalNote: MedicalNote): Observable<MedicalNote> {
    return this.http.post<MedicalNote>(this.baseURL + 'medical-note', medicalNote, {
      headers: this.jsonHeaders,
    });
  }

  public getMedicalDocuments(caseId: number): Observable<CaseDocument[]> {
    return this.http
      .get<any>(this.baseURL + `documents/${caseId}/` + 'MEDICAL_INFO', {
        headers: this.jsonHeaders,
      })
      .pipe(map((data: any[]) => data.map(item => this.caseDocumentAdapter.adapt(item))));
  }

  public getUsersForMedicalAssessment(): Observable<User[]> {
    return this.http
      .get(this.baseURL + 'users/user-for-medical-assessment')
      .pipe(map((data: any[]) => data.map(item => this.userAdapter.adapt(item))));
  }

  public getMedicalHistory(caseId: string): Observable<MedicalHistory[]> {
    return this.http
      .get(this.baseURL + `medical-history/case/${caseId}`)
      .pipe(map((data: any[]) => data.map(item => this.medicalHistoryAdapter.adapt(item))));
  }

  public getMedicalAssessment(id: number): Observable<MedicalAssessment> {
    return this.http.get(this.baseURL + 'medical-assessment/' + id).pipe(map(item => this.medicalAssessmentAdapter.adapt(item)));
  }

  public deleteMedicalAssessment(medicalAssessmentId: number, medicalNumber: number, text?: string): Observable<any> {
    return this.http.put<any>(this.baseURL + 'medical-assessment/delete/' + medicalAssessmentId + '/' + medicalNumber, text);
  }

  public deletePreAssessment(preAssessmentId: number, medicalNumber: number, text?: string): Observable<MedicalPreAssessment> {
    return this.http.put<MedicalPreAssessment>(this.baseURL + 'medical-pre-assessment/delete/' + preAssessmentId + '/' + medicalNumber, text);
  }

  public deleteMedicalNote(medicalNoteId: number, medicalNumber: number, text?: string ): Observable<any> {
    return this.http.put<any>(this.baseURL + 'medical-note/delete/' + medicalNoteId + '/' + medicalNumber, text);
  }

  public deleteMedicalReport(medicalReportId: number, medicalNumber: number, text?: string): Observable<any> {
    return this.http.put<any>(this.baseURL + 'medical-history/delete-medical-report/' + medicalReportId + '/' + medicalNumber, text);
  }

  public getNewestMedicalAssessment(caseId: number): Observable<MedicalAssessment> {
    return this.http
      .get(this.baseURL + 'medical-assessment/case/newest/' + caseId)
      .pipe(map(item => this.medicalAssessmentAdapter.adapt(item)));
  }

  public getMedicalAssessments(caseId: number): Observable<MedicalAssessment[]> {
    return this.http
      .get(this.baseURL + 'medical-assessment/case/' + caseId)
      .pipe(map((data: any[]) => data.map(item => this.medicalAssessmentAdapter.adapt(item))));
  }

  public medicalAssessmentsUsedOnServiceOrder(medicalAssessmentId: number): Observable<any> {
    return this.http.get(this.baseURL + 'service-orders/medical-assessment-used-on-service-order/' + medicalAssessmentId);
  }

  publishNewMedicalAssessmentEvent(medicalAssessment: MedicalAssessment) {
    const followUpPlaned = medicalAssessment.followupPlanned ? medicalAssessment.followupPlanned : false;
    this.publishMedicalAssessmentHaveFollowUpPlaned.next(followUpPlaned);
  }

  private nullifyInvalidAssessmentValues(assessment: MedicalAssessment): MedicalAssessment {
    const result = this.medicalAssessmentAdapter.adapt(assessment);
    const state = this.medicalAssessmentStateService.calculateAssessmentState(result);

    if (state.assessmentValid) {
      result.documentList = state.basisDocuments ? result.documentList : null;
      result.medicalEvacuationDate = state.medicalEvacuation ? result.medicalEvacuationDate : null;
      result.repatriationInstructionsAllowed = state.notTravelHomeAsPlanned ? result.repatriationInstructionsAllowed : null;
      result.repatriationPrognosis = state.repatriationPrognosis ? result.repatriationPrognosis : null;
      result.repatriationInstructionsBase = state.repatriationInstructionsAllowed
        ? this.nullifyInvalidRepatriationAssessmentValues(result.repatriationInstructionsBase, state)
        : null;
      result.followupDate = state.followUp ? result.followupDate : null;
      result.followupDescription = state.followUp ? result.followupDescription : null;
      return result;
    }
    return null;
  }

  private nullifyInvalidRepatriationAssessmentValues(
    instructions: RepatriationInstructionsBase,
    state: MedicalAssessmentState
  ): RepatriationInstructionsBase {
    const result = new RepatriationInstructionsBase(instructions);

    const patientState = result.patientState;
    const patientNeeds = result.patientNeeds;
    const form = result.repatriationForm;

    if (!state.surgery) {
      patientState.surgeryDate = null;
      patientState.surgeryDescription = null;
    }

    form.extraSpaces = state.extraSpace ? form.extraSpaces : null;
    form.arrivalDescription = state.repatriationArrivalDeadline ? form.arrivalDescription : null;
    form.seaLevelFlight = state.intensiveCarePatient ? form.seaLevelFlight : null;

    if (!state.intensiveCarePatient && !state.stretcher) {
      form.patientWeight = null;
      form.patientHeight = null;
      form.patientCarriableOnBoard = null;
    }

    if (state.altFromShownIndex > 0 || result.alternativeFormStretcher.formSelected !== true) {
      result.alternativeFormStretcher.formSelected = undefined;
      result.alternativeFormStretcher.date = undefined;
    }

    if (state.altFromShownIndex > 1 || result.alternativeFormExtraSpace.formSelected !== true) {
      result.alternativeFormExtraSpace.formSelected = undefined;
      result.alternativeFormExtraSpace.date = undefined;
      result.alternativeFormExtraSpace.extraSpaces = undefined;
    }

    if (state.altFromShownIndex > 2 || result.alternativeFormEconomyClass.formSelected !== true) {
      result.alternativeFormEconomyClass.formSelected = undefined;
      result.alternativeFormEconomyClass.date = undefined;
    }

    if (state.altFromShownIndex > 3 || result.alternativeFormRegularPassenger.formSelected !== true) {
      result.alternativeFormRegularPassenger.formSelected = undefined;
      result.alternativeFormRegularPassenger.date = undefined;
    }

    form.coTransportDescription = state.coTransportDesc ? form.coTransportDescription : null;

    if (!state.intensiveCarePatient) {
      patientState.breathingSupportDescription = null;
      patientState.medications = null;
      patientState.intensiveCareStep = null;
    }

    patientNeeds.medicalCareInTransitDescription = state.medicalCareInTransit ? patientNeeds.medicalCareInTransitDescription : null;
    if (!state.admissionOnArrival) {
      patientNeeds.admissionUponArrivalDescription = null;
      patientNeeds.ward = null;
    }

    if (!state.cast) {
      patientState.castLeft = null;
      patientState.castRight = null;
      patientState.castReadyForFlight = null;
      patientState.castDescription = null;
    }

    if (!!patientNeeds.oxygen && patientNeeds.oxygen.length > 0) {
      if (!patientNeeds.onGroundOxygenNeeded && !!patientNeeds.getOxygen(RepatriationMediumEnum.ON_GROUND)) {
        patientNeeds.oxygen = patientNeeds.oxygen.filter(item => item !== patientNeeds.getOxygen(RepatriationMediumEnum.ON_GROUND));
      }
      if (!patientNeeds.inFlightOxygenNeeded && !!patientNeeds.getOxygen(RepatriationMediumEnum.IN_FLIGHT)) {
        patientNeeds.oxygen = patientNeeds.oxygen.filter(item => item !== patientNeeds.getOxygen(RepatriationMediumEnum.IN_FLIGHT));
      }
    }

    if (!patientNeeds.escortNeeded) {
      patientNeeds.escort = null;
      patientNeeds.equipmentNeeded = null;
      patientNeeds.equipmentTransportMediumType = null;
      patientNeeds.equipment = null;
      patientNeeds.medicationNeeded = null;
      patientNeeds.medication = null;
    } else {
      if (!state.equipmentMedication) {
        patientNeeds.equipmentNeeded = null;
        patientNeeds.equipment = null;
        patientNeeds.equipmentTransportMediumType = null;
        patientNeeds.medicationNeeded = null;
        patientNeeds.medication = null;
      } else {
        patientNeeds.equipment = state.equipment ? patientNeeds.equipment : null;
        patientNeeds.equipmentTransportMediumType = state.equipment ? patientNeeds.equipmentTransportMediumType : null;
        patientNeeds.medication = state.medication ? patientNeeds.medication : null;
      }
    }
    return result;
  }
}
