<div class="sub-component-container" [appDisableInput]="caseStateService.isCaseDisabled()">
  <div class="sub-title">{{"sub-repatriation-form-ambulance-flight-title" | translate}}</div>
<!--  <div class="sub-content-container">-->
  <div>
    <div class="d-flex horizontal-line">
      <div class="ms-auto p-3">
        <div class="horizontal-line"></div>
        <button class="primary-button repatriation-button float-end" [disabled]="!isThereRepatriationInstruction || readOnlyMode"
                [class.primary-button-inactive]="!isThereRepatriationInstruction || readOnlyMode"
                (click)="setLatestRepatriation()">{{"sub-repatriation-form-ambulance-flight-add-data" | translate}}</button>
      </div>
    </div>

    <div *ngIf="noRepatriation" >
      <div class="flex-fill p-3">
        <div class="fixed-text">
          <span style="font-style:italic;">{{"no-repatriation-available" | translate}}</span>
        </div>
      </div>
    </div>

    <div *ngIf="medicalAssessment?.repatriationInstructionsBase" class="d-flex repatriation-instruction-container">
      <div class="flex-fill p-3 vertical-line">
        <app-drop-down [items]="repatriationForms"
                       [name]="'sub-repatriation-form-medical-repatriation-form' | translate"
                       [(selectedItemId)]="medicalAssessment.repatriationInstructionsBase.repatriationForm.form" [disabled]="true">
        </app-drop-down>

        <div *ngIf="medicalAssessment?.repatriationInstructionsBase?.repatriationForm?.form == RepatriationFormEnum.EXTRA_SPACE">
          <div class="fixed-text-holder">
            <app-multiple-choice-component [items]="extraSpaceList"
                                           [title]="'repatriation-extra-space-type' | translate" [disabled]="true">
            </app-multiple-choice-component>
            <div *ngIf="isOtherSelected">
              {{otherExtraSpaceText}}
            </div>
          </div>
        </div>
        <div class="py-3">
          <app-yes-no-question [header]="'sub-repatriation-form-ambulance-flight-sea-level-required' | translate"
                              [value]="medicalAssessment?.repatriationInstructionsBase?.repatriationForm?.seaLevelFlight">
          </app-yes-no-question>
          <app-yes-no-question [header]="'sub-repatriation-form-ambulance-flight-oxygen-needed-in-flight' | translate"
                              [value]="medicalAssessment?.repatriationInstructionsBase?.patientNeeds?.inFlightOxygenNeeded">
          </app-yes-no-question>
          <app-yes-no-question [header]="'sub-repatriation-form-ambulance-flight-oxygen-needed-on-ground' | translate"
                              [value]="medicalAssessment?.repatriationInstructionsBase?.patientNeeds?.onGroundOxygenNeeded">
          </app-yes-no-question>
          <app-yes-no-question [header]="'sub-repatriation-form-ambulance-flight-escort-needed' | translate"
                              [value]="medicalAssessment?.repatriationInstructionsBase?.patientNeeds?.escortNeeded">
          </app-yes-no-question>
          <app-yes-no-question [header]="'sub-repatriation-form-ambulance-flight-patient-carriable-on-board-sitting' | translate"
                              [value]="medicalAssessment?.repatriationInstructionsBase?.repatriationForm?.patientCarriableOnBoard">
          </app-yes-no-question>
        </div>
      </div>
      <div class="flex-fill py-3 pe-3 editable">
        <div class="d-flex pb-3">
          <div class="flex-fill d-flex ps-3 flex-nowrap">
            <app-input [disabled]="isHeightFromRepatriationInstructions()" [name]="'sub-repatriation-form-ambulance-flight-patient-height' | translate"
                       [(value)]="height" [inputType]="numberInputType" [inputTypePattern]="digitsTypePattern">
            </app-input>
            <div class="pt-4 ps-2">
              {{'sub-repatriation-form-ambulance-flight-patient-height-cm' | translate}}
            </div>
          </div>
          <div class="flex-fill ps-3 d-flex flex-nowrap">
            <app-input [disabled]="isWeightFromRepatriationInstructions()" [name]="'sub-repatriation-form-ambulance-flight-patient-weight' | translate"
                       [(value)]="weight" [inputType]="numberInputType" [inputTypePattern]="digitsTypePattern">
            </app-input>
            <div class="pt-4 ps-2">
            {{'sub-repatriation-form-ambulance-flight-patient-weight-kg' | translate}}
            </div>
          </div>
        </div>
        <div class="d-flex">
          <div class="flex-fill ps-3">
            <app-text-box [name]="'sub-repatriation-form-ambulance-flight-describe-luggage' | translate" [maxTextLength]="1000"
                          [(value)]="iccRepatriationInfo.remarks"
                          [placeHolder]="'sub-repatriation-form-ambulance-flight-describe-luggage-place-holder' | translate"></app-text-box>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
