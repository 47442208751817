import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Subscription } from 'rxjs';
import { TaskModalCommon } from '@secca/shared/components/task-modal-common/task-modal-common.component';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { TaskType } from '@secca/shared/models/enums';
import { Task } from '@secca/shared/models/task';
import { Coverage } from '@secca/shared/models/coverage';
import { Case } from '@secca/shared/models/case';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import { CaseEmail } from '@secca/shared/models/case-email';
import {Moment} from "moment-timezone";
import moment from "moment";
import {SearchUserAndTeam} from "@secca/shared/models/searchUserAndTeam";
import { UserDto } from '@secca/shared/models/userDto';
import { TeamDto } from '@secca/shared/models/teamDto';

@Component({
  selector: 'app-task-message-left-panel',
  templateUrl: './task-message-left-panel.component.html',
  styleUrls: ['./task-message-left-panel.component.scss']
})
@AutoUnsubscribe
export class TaskMessageLeftPanelComponent extends TaskModalCommon implements OnInit {
  @Input() taskCase: string;
  @Input() taskId: string;

  @Output() stateChanged = new EventEmitter<void>();

  coverages: Coverage[];
  reserve: number;
  currency: string;
  caseEmail: CaseEmail;
  primaryCaseHandler: string;
  lastCaseActionBy: string;
  lastCaseActionTime: Date;

  $subscr2: Subscription;
  $teamsSubscr: Subscription;
  priorityDropdown: DropdownDictionary[] = [];

  _constructor() {}

  ngOnInit() {
    this.initialiseTaskInformation(this.taskId || this.taskViewModel?.id, this.taskViewModel?.currency);
    this.initialiseCaseInformation(this.case, this.taskCase || this.taskViewModel?.caseId?.toString());
    this.initialiseTeamHandler();
    this.initialiseCaseNumberValidated(this.case?.id || this.taskCase || this.taskViewModel?.caseId?.toString());
    this.initializePrimaryCaseHandler(this.case?.id || this.taskCase || this.taskViewModel?.caseId?.toString());
    this.initPriorityDropdown();
  }

  get caseId() {
    return this.case?.id || this.taskCase || this.taskViewModel?.caseId?.toString();
  }

  get validated(): boolean {
    return (
      this.task.title &&
      this.task.dueDate != null &&
      this.task.status != null &&
      (this.acHandler?.adtCode || this.acHandler?.team?.adtCode) &&
      this.isCaseNumberValidated
    );
  }

  private initPriorityDropdown() {
    this.priorityDropdown.push(new DropdownDictionary(1, this.translate.instant('task-priority-type-1')));
    this.priorityDropdown.push(new DropdownDictionary(2, this.translate.instant('task-priority-type-2')));
    this.priorityDropdown.push(new DropdownDictionary(3, this.translate.instant('task-priority-type-3')));
    this.priorityDropdown.push(new DropdownDictionary(4, this.translate.instant('task-priority-type-4')));
    this.priorityDropdown.push(new DropdownDictionary(5, this.translate.instant('task-priority-type-5')));
}

  descriptionChange($event) {
    if (this.task.description !== $event) {
      this.setTaskHasChanged();
    }
    this.disableSaveButtonWhenNoChanges = false;
    this.task.description = $event;
  }

  titleUpdated() {
    this.setTaskHasChanged();
    this.stateChanged.emit();
  }

  dueDateUpdated(newMoment: Moment) {
    this.dueDateChanged(newMoment);
    this.stateChanged.emit();
  }

  priorityUpdated() {
    this.setTaskHasChanged();
    this.stateChanged.emit();
  }

  caseHandlerUpdated(acHandler: SearchUserAndTeam) {
    this.caseHandlerChanged(acHandler);
    this.stateChanged.emit();
  }

  statusUpdated() {
    this.setTaskHasChanged();
    this.stateChanged.emit();
  }

  private initialiseCaseInformation(seccaCase: Case, caseId: string) {
    if ( caseId ) {
      this.getCaseInformations(caseId);
    }
    else if ( seccaCase ) {
      this.getHeaderInformations(seccaCase);
      this.getCoverages(seccaCase.id);
    }
  }

  private initialiseTaskInformation(taskId: string, currency: string) {
    if ( taskId ) {
      this.getTaskInformation(+taskId).subscribe(() => {
          this.loadEmail();
        });
    }
    else {
      this.task = new Task();
    }

    this.currency = currency;
  }

  private initializePrimaryCaseHandler(caseId: string) {
    this.getPrimaryCaseHandler(caseId, (user: UserDto, team: TeamDto) => {
      if (user) {
        this.primaryCaseHandler = user.fullNameAndInitialt;
      } else if (team) {
        this.primaryCaseHandler = team.teamName;
      } else {
        this.primaryCaseHandler = undefined;
      }

    });
  }

  private loadEmail() {
    if (this.task.taskType === TaskType.email) {
      this.caseCommunicationService.getCaseEmail(this.task.emailId).subscribe(
        result => {
          this.caseEmail = result;
        },
        error => console.log(error)
      );
    }
    if (this.task.taskType === TaskType.fax) {
      this.caseCommunicationService.getCaseFax(this.task.faxId).subscribe(
        result => {
          this.caseEmail = result;
        },
        error => console.log(error)
      );
    }
    if (this.task.taskType === TaskType.sms || this.task.taskType === TaskType.mms) {
      this.caseCommunicationService.getCaseMessage(this.task.messageId).subscribe(
        result => {
          this.caseEmail = result;
        },
        error => console.log(error)
      );
    }
  }

  private getCoverages(caseId: string) {
    this.planService.getCoveragesForCase(caseId).subscribe(
      coverages => {
        this.coverages = coverages;
        this.calculateCaseReserve();
      },
      error => console.log(error)
    );
  }

  private calculateCaseReserve() {
    if (this.coverages != null) {
      this.reserve = this.coverages.map(a => a.reserve).reduce((a, b) => a + b, 0);
    }
  }

  private initialiseCaseNumberValidated(caseId: string) {
    if ( caseId ) {
      this.$subscr2 = this.caseValidationService.isCaseNumberAssigned(caseId).subscribe(validated => {
        this.isCaseNumberValidated = validated;
      });
    }
  }

  private initialiseTeamHandler() {
    this.$teamsSubscr = this.dictionaryService.getAllActiveTeamsList().subscribe(result => {
      if (result != null) {
        this.teams = result.map(a => new DropdownDictionary(a.adtCode, a.teamName));
        if (this.task != null && this.task.adtCode != null) {
          this.initializeTeamAcHandler();
        }
      }
    });
  }

  private getCaseInformations(caseId: string) {
    this.caseService.getCase(caseId).subscribe(
      seccaCase => {
        this.case = seccaCase;
        this.getHeaderInformations(seccaCase);
        this.getCoverages(caseId);
        this.getPrimaryCaseHandler(caseId, (user: UserDto, team: TeamDto) => {
          if (user && user.firstName && user.surname && user.initials) {
            this.primaryCaseHandler = user.firstName + ' ' + user.surname + ', ' + user.initials;
          } else if (team) {
            this.primaryCaseHandler = team.teamName;
          } else {
            this.primaryCaseHandler = undefined;
          }
        });

        this.getLastActionInCaseSummary(caseId, caseHistoryEntry => {
          this.lastCaseActionTime = moment(caseHistoryEntry.actionTime).utc(false).toDate();
          this.getUser(caseHistoryEntry.userId, user => {
            if (user != undefined && user.firstName != undefined && user.surname != undefined && user.initials != undefined) {
              this.lastCaseActionBy = user.firstName + ' ' + user.surname + ', ' + user.initials;
            } else {
              this.lastCaseActionBy = undefined
            }
          });
        });

        this.getBillingCurrency(caseId, billingCurrency => {
          this.currency = billingCurrency.billingCurrency;
        });
      },
      error => console.log(error)
    );
  }
}
