import { Component, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { RefundService } from '@secca/core/services/refund.service';
import { SalesOrderService } from '@secca/core/services/sales-order.service';
import { SupplierInvoiceService } from '@secca/core/services/supplier-invoice.service';
import { CaseSummarySortOrderType } from '@secca/shared/enums/caseSummarySortOrderType';
import { Case } from '@secca/shared/models/case';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import { EconomyHistoryFilterType } from '@secca/shared/models/enums';
import { Refund } from '@secca/shared/models/refund';
import { RefundItem } from '@secca/shared/models/refund-Item';
import { SalesOrder } from '@secca/shared/models/salesOrder';
import { SalesOrderItem } from '@secca/shared/models/salesOrderItem';
import { SupplierInvoice } from '@secca/shared/models/supplierInvoice';
import { SupplierInvoiceItem } from '@secca/shared/models/supplierInvoiceItem';
import { UserDto } from '@secca/shared/models/userDto';
import { forkJoin } from 'rxjs';
import { EconomyHistoryComparison, EconomyHistoryService } from '../economy-history.service';
import { SettingsService } from '@secca/core/services/settings.service';
import { RecoveryPaymentService } from '@secca/core/services/recovery/recovery-payment.service';
import { RecoveryPayment } from '@secca/shared/models/recoveryPayment';
import { RecoveryPaymentItem } from '@secca/shared/models/recoveryPaymentItem';

@Component({
  selector: 'app-case-economy-history-list',
  templateUrl: './case-economy-history-list.component.html',
  styleUrls: ['./case-economy-history-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CaseEconomyHistoryListComponent implements OnInit, OnChanges {

  @Input() case: Case;
  @Input() users: UserDto[] = [];
  fullSupplierInvoiceHistory: SupplierInvoice[];
  fullSupplierInvoiceItemHistory: SupplierInvoiceItem[];
  fullRefundHistory: Refund[];
  fullSalesOrderHistory: SalesOrder[];
  sosServicesHistory: SalesOrderItem[];
  customerServicesItemHistory: SalesOrderItem[];
  fullSalesOrderItemHistory: SalesOrderItem[];
  fullEconomyHistoryList: EconomyHistoryComparison[] = [];
  fullRefundItemHistory: RefundItem[];
  fullRecoveryPaymentHistory: RecoveryPayment[];
  fullRecoveryPaymentItemHistory: RecoveryPaymentItem[];
  @Input() dateRange: Date[];
  @Input() userCriteria: UserDto[];
  @Input() objectItem: string;
  @Input() subObjectItems;
  @Input() idSearch: string;

  filteredEconomyHistoryList: EconomyHistoryComparison[];

  sortItems: DropdownDictionary[] = [
    { id: 'ASC', name: CaseSummarySortOrderType.ASC },
    { id: 'DESC', name: CaseSummarySortOrderType.DESC }
  ];
  sortItem = 'DESC';


  constructor(
    private supplierInvoiceService: SupplierInvoiceService,
    private settingsService: SettingsService,
    private refundService: RefundService,
    private salesOrderService: SalesOrderService,
    private recoveryPaymentService: RecoveryPaymentService,
    private economyHistoryService: EconomyHistoryService
  ) {
  }

  ngOnInit(): void {
    const supplierInvoiceHist = this.supplierInvoiceService.getSupplierInvoiceFullHistory(this.case.id);
    const supplierInvoiceItemHist = this.supplierInvoiceService.getSupplierInvoiceFullItemHistory(this.case.id);
    const refundItemHist = this.refundService.getFullRefundItemHistory(this.case.id);
    const refundHist = this.refundService.getFullRefundHistory(this.case.id);
    const salesorderHist = this.salesOrderService.getFullHistory(this.case.id);
    const salesOrderItemHist = this.salesOrderService.getFullItemHistory(this.case.id);
    const recoveryPaymentHist = this.recoveryPaymentService.getRecoveryPaymentFullHistory(this.case.id);
    const recoveryPaymentItemHist = this.recoveryPaymentService.getRecoveryPaymentItemFullHistory(this.case.id);

    forkJoin([refundHist, refundItemHist, salesorderHist, salesOrderItemHist, supplierInvoiceHist, supplierInvoiceItemHist, recoveryPaymentHist, recoveryPaymentItemHist])
      .subscribe(results => {
        this.fullRefundHistory = results[0];
        this.fullRefundItemHistory = results[1];
        this.fullSalesOrderHistory = results[2];
        this.fullSalesOrderItemHistory = results[3];
        this.fullSupplierInvoiceHistory = results[4];
        this.fullSupplierInvoiceItemHistory = results[5];
        this.fullRecoveryPaymentHistory = results[6];
        this.fullRecoveryPaymentItemHistory = results[7];
        this.sosServicesHistory = this.fullSalesOrderItemHistory.filter((salesOrder) => salesOrder.feeType);
        this.customerServicesItemHistory = this.fullSalesOrderItemHistory.filter((salesOrder) => !salesOrder.feeType);
        this.fullEconomyHistoryList = this.economyHistoryService.generateVersionComparison(this.fullRefundHistory, this.users, EconomyHistoryFilterType.REFUND);
        this.fullEconomyHistoryList.push(...this.economyHistoryService.generateVersionComparison(this.sosServicesHistory, this.users, EconomyHistoryFilterType.SOS_SERVICE));
        this.fullEconomyHistoryList.push(...this.economyHistoryService.generateVersionComparison(this.fullRefundItemHistory, this.users, EconomyHistoryFilterType.REFUND_ITEM));
        this.fullEconomyHistoryList.push(...this.economyHistoryService.generateVersionComparison(this.fullSalesOrderHistory, this.users, EconomyHistoryFilterType.CUSTOMER_INVOICE));
        this.fullEconomyHistoryList.push(...this.economyHistoryService.generateVersionComparison(this.customerServicesItemHistory, this.users, EconomyHistoryFilterType.CUSTOMER_INVOICE_ITEM));
        this.fullEconomyHistoryList.push(...this.economyHistoryService.generateVersionComparison(this.fullSupplierInvoiceHistory, this.users, EconomyHistoryFilterType.SUPPLIER_INVOICE));
        this.fullEconomyHistoryList.push(...this.economyHistoryService.generateVersionComparison(this.fullSupplierInvoiceItemHistory, this.users, EconomyHistoryFilterType.SUPPLIER_INVOICE_ITEM));
        this.fullEconomyHistoryList.push(...this.economyHistoryService.generateVersionComparison(this.fullRecoveryPaymentHistory, this.users, EconomyHistoryFilterType.RECOVERY_PAYMENT));
        this.fullEconomyHistoryList.push(...this.economyHistoryService.generateVersionComparison(this.fullRecoveryPaymentItemHistory, this.users, EconomyHistoryFilterType.RECOVERY_PAYMENT_ITEM));


        this.applyAllFilter(this.fullEconomyHistoryList);
      });
  }

  ngOnChanges() {
    this.applyAllFilter(this.fullEconomyHistoryList);
  }

  matchUsers(hist: EconomyHistoryComparison, users: UserDto[]): unknown {
    if (!users || users.length === 0) {
      return true;
    }
    return users.includes(hist.user);
  }

  private filterbyId(history: EconomyHistoryComparison, id: string): boolean {
    if (id) {
      return id === history.newValue.id.toString(10);
    }
    return true;
  }

  private filterCaseObjects(list: EconomyHistoryComparison[]) {
    let filterTypeList: string[] = [];
    const allObjects = new Map<string, Array<string>>([
      [EconomyHistoryFilterType.RECOVERY_PAYMENT, [EconomyHistoryFilterType.RECOVERY_PAYMENT, EconomyHistoryFilterType.RECOVERY_PAYMENT_ITEM]],
      [EconomyHistoryFilterType.REFUND, [EconomyHistoryFilterType.REFUND, EconomyHistoryFilterType.REFUND_ITEM]],
      [EconomyHistoryFilterType.SOS_SERVICE, [EconomyHistoryFilterType.SOS_SERVICE]],
      [EconomyHistoryFilterType.CUSTOMER_INVOICE, [EconomyHistoryFilterType.CUSTOMER_INVOICE, EconomyHistoryFilterType.CUSTOMER_INVOICE_ITEM]],
      [EconomyHistoryFilterType.SUPPLIER_INVOICE, [EconomyHistoryFilterType.SUPPLIER_INVOICE, EconomyHistoryFilterType.SUPPLIER_INVOICE_ITEM]]

    ]);

    if (this.objectItem) {
      filterTypeList = allObjects.get(EconomyHistoryFilterType[this.objectItem]);
    }
    if (this.subObjectItems && this.subObjectItems.length > 0) {
      filterTypeList = this.subObjectItems;
    }
    if (this.objectItem) {
      return list.filter((hist) => filterTypeList.includes(hist.type));
    }
    return list;
  }

  private applyAllFilter(list: EconomyHistoryComparison[]) {
    this.filteredEconomyHistoryList = this.filterCaseObjects(
      this.fullEconomyHistoryList
        .filter(hist => this.matchDates(hist, this.dateRange))
        .filter(entry => this.filterbyId(entry, this.idSearch))
        .sort((obj1, obj2) => this.economyHistoryService.sortDatesByTime(obj1.date(), obj2.date(), this.sortItem)));
  }

  private matchDates(history: EconomyHistoryComparison, dateRange: Date[]): boolean {
    if (!dateRange || dateRange.length === 0) {
      return true;
    }
    return (history.date().isAfter(dateRange[0])) && (history.date().isBefore(dateRange[1]));
  }

  sortChanged(value: string): void {
    this.sortItem = value;
    this.filteredEconomyHistoryList.sort(
      (obj1, obj2) => this.economyHistoryService.sortDatesByTime(obj1.date(), obj2.date(), this.sortItem)
    );
  }

  getUserImage(userId: string) {
    return '';
  }

  get EconomyHistoryFilterType() {
    return EconomyHistoryFilterType;
  }
}

