import { Injectable } from '@angular/core';

export class ProductPurposesOfTravel {
  productId: number;
  primaryPurpose: string;
  secondaryPurposes: string[];

  constructor(init?: Partial<ProductPurposesOfTravel>) {
    Object.assign(this, init);
  }
}

@Injectable({ providedIn: 'root' })
export class ProductPurposesOfTravelAdapter {
  public adapt(item: any): ProductPurposesOfTravel {
    return new ProductPurposesOfTravel({
      productId: item.productId,
      primaryPurpose: item.primaryPurpose,
      secondaryPurposes: !!item.secondaryPurposes ? item.secondaryPurposes : null
    });
  }
}