import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CauseItemType,
  CauseLevel1ItemTypeAdapter,
  CauseLevel2ItemTypeAdapter,
  CauseLevel3ItemTypeAdapter,
} from '@secca/shared/models/cause-item-type';
import { PurposeOfTravelItemType, PurposeOfTravelItemTypeAdapter } from '@secca/shared/models/purpose-of-travel-item-type';
import { ReductionType, ReductionTypeAdapter } from '@secca/shared/models/reduction-type';
import { ServiceItemType, ServiceItemTypeAdapter } from '@secca/shared/models/service-item-type';
import { map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { SettingsService } from './settings.service';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import { ServiceTypeEnum } from '@secca/shared/models/enums';
import { IncidentEventAdapter, IncidentEvent } from '@secca/shared/models/incidentEvent';
import { Questionnaire, QuestionnaireAdapter } from '@secca/shared/models/questionnaire';
import { Diagnosis, DiagnosisAdapter } from '@secca/shared/models/dignosis';
import { Observable } from 'rxjs';
import { ContactPerson } from '@secca/shared/models/contactPerson';
import { PropertyType, PropertyTypeDb } from '@secca/shared/models/PropertyType';
import { ChannelType } from '@secca/shared/enums/channelType';
import { Channel, ChannelAdapter } from '@secca/shared/models/channel';
import { ReasonForCancellation, ReasonForCancellationAdapter } from '@secca/shared/models/reason-for-cancellation';

@Injectable({
  providedIn: 'root',
})
export class MasterListService extends BaseService {
  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private serviceItemTypeAdapter: ServiceItemTypeAdapter,
    private purposeOfTravelItemTypeAdapter: PurposeOfTravelItemTypeAdapter,
    private causeLevel1ItemTypeAdapter: CauseLevel1ItemTypeAdapter,
    private causeLevel2ItemTypeAdapter: CauseLevel2ItemTypeAdapter,
    private causeLevel3ItemTypeAdapter: CauseLevel3ItemTypeAdapter,
    private refundReductionTypeAdapter: ReductionTypeAdapter,
    private incidentEventAdapter: IncidentEventAdapter,
    private questionnaireTypeAdapter: QuestionnaireAdapter,
    private diagnosisAdapter: DiagnosisAdapter,
    private reasonForCancellationAdapter: ReasonForCancellationAdapter,
    private channelAdapter: ChannelAdapter
  ) {
    super(settingsService);
  }

  public getServiceItemList(): Observable<ServiceItemType[]> {
    return this.http
      .get<ServiceItemType[]>(this.baseURL + 'masterlist/service-item-list')
      .pipe(map((data: any[]) => data.map(item => this.serviceItemTypeAdapter.adapt(item))));
  }

  public getPurposeOfTravelList(): Observable<PurposeOfTravelItemType[]> {
    return this.http
      .get<PurposeOfTravelItemType[]>(this.baseURL + 'masterlist/purpose-of-travel-list')
      .pipe(map((data: any[]) => data.map(item => this.purposeOfTravelItemTypeAdapter.adapt(item))));
  }

  public getAllLevel1Causes(): Observable<CauseItemType[]> {
    return this.http
      .get<CauseItemType[]>(this.baseURL + 'masterlist/causes/level1/all')
      .pipe(map((data: any[]) => data.map(item => this.causeLevel1ItemTypeAdapter.adapt(item))));
  }

  public getAllLevel2Causes(): Observable<CauseItemType[]> {
    return this.http
      .get<CauseItemType[]>(this.baseURL + 'masterlist/causes/level2/all')
      .pipe(map((data: any[]) => data.map(item => this.causeLevel2ItemTypeAdapter.adapt(item))));
  }

  public getAllLevel3Causes(): Observable<CauseItemType[]> {
    return this.http
      .get<CauseItemType[]>(this.baseURL + 'masterlist/causes/level3/all')
      .pipe(map((data: any[]) => data.map(item => this.causeLevel3ItemTypeAdapter.adapt(item))));
  }

  public getRefundReductionTypes(): Observable<ReductionType[]> {
    return this.http
      .get<ReductionType[]>(this.baseURL + 'masterlist/reductions')
      .pipe(map((data: any[]) => data.filter(item => item.usedInRefund).map(item => this.refundReductionTypeAdapter.adapt(item))));
  }

  public getSupplierInvoiceReductionTypes(): Observable<ReductionType[]> {
    return this.http
      .get<ReductionType[]>(this.baseURL + 'masterlist/reductions')
      .pipe(map((data: any[]) => data.filter(item => item.usedInSupplierInvoice).map(item => this.refundReductionTypeAdapter.adapt(item))));
  }

  public getRefundTypes(): Observable<DropdownDictionary[]> {
    return this.http
      .get(this.baseURL + 'masterlist/refund-type-list')
      .pipe(map((data: any[]) => data.map(item => new DropdownDictionary(item.businessKey, item.refundTypeName))));
  }

  public getCommitmentBusinessKeyValuesByServiceTypeEnum(serviceTypeEnum: ServiceTypeEnum): Observable<DropdownDictionary[]> {
    return this.http
      .get(this.baseURL + 'masterlist/commitmenttypes/businessKeyValuesByServiceTypeEnum/' + serviceTypeEnum)
      .pipe(map((data: any[]) => data.map(item => new DropdownDictionary(item.businessKey, item.name))));
  }

  public getIncidentEvents(): Observable<IncidentEvent[]> {
    return this.http
      .get(this.baseURL + 'masterlist/incident-events')
      .pipe(map((data: any[]) => data.filter(item => item).map(item => this.incidentEventAdapter.adapt(item))));
  }

  public getQuestionnaire(): Observable<Questionnaire[]> {
    return this.http
      .get(this.baseURL + 'masterlist/questionnaire')
      .pipe(map((data: any[]) => data.filter(item => item).map(item => this.questionnaireTypeAdapter.adapt(item))));
  }

  public getQuestionnaireByFlow(flow: string): Observable<Questionnaire[]> {
    return this.http
      .get(this.baseURL + `masterlist/questionnaire/flow/${flow}`)
      .pipe(map((data: any[]) => data.filter(item => item).map(item => this.questionnaireTypeAdapter.adapt(item))));
  }

  public getQuestionnaireByFlowAndLanguage(flow: string, lang: string): Observable<Questionnaire[]> {
    return this.http
      .get(this.baseURL + `masterlist/questionnaire/flow/${flow}/lang/${lang}`)
      .pipe(map((data: any[]) => data.filter(item => item).map(item => this.questionnaireTypeAdapter.adapt(item))));
  }

  public getAllDiagnoses(): Observable<Diagnosis[]> {
    return this.http
      .get(this.baseURL + 'masterlist/diagnosis')
      .pipe(map((data: any[]) => data.filter(item => item).map(item => this.diagnosisAdapter.adapt(item))));
  }

  public getReasonForCancellation(): Observable<ReasonForCancellation[]> {
    return this.http
      .get<ReasonForCancellation[]>(this.baseURL + 'masterlist/reason-for-cancellation')
      .pipe(map((data: any[]) => data.map(item => this.reasonForCancellationAdapter.adapt(item))));
  }

  public getContactRoles(): Observable<PropertyTypeDb[]> {
    return this.http
      .get(this.baseURL + 'masterlist/contact-role-list')
      .pipe(map((data: PropertyTypeDb[]) => data.filter(item => item).map(item => item)));
  }

  public getContactRolesWithApplication(): Observable<PropertyType[]> {
    return this.http
      .get(this.baseURL + 'masterlist/contact-role-list/with-application')
      .pipe(map((data: PropertyType[]) => data.filter(item => item).map(item => item)));
  }

  public getAllChannelTypes(): Observable<Channel[]> {
    return this.http
      .get(this.baseURL + `channel-types/all`)
      .pipe(map((data: any[]) => data.filter(item => item).map(item => this.channelAdapter.adapt(item))));
  }
}
