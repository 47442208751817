import { DictionaryService } from '@secca/core/services/dictionary.service';
import { ProfileCountryList } from '@secca/shared/models/ProfileCountryList';
import { ProfileCountry } from '@secca/shared/models/ProfileCountry';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { PlannedTravel } from '@secca/shared/models/planned-travel';
import { UpdateScreeningToolService } from '@secca/core/services/update-screening-tool.service';
import { IncidentService } from '@secca/core/services/incident.service';
import { CaseIncident } from '@secca/shared/models/caseIncident';
import { PersonInsurance } from '@secca/shared/models/person-insurance';
import { CaseService } from '@secca/core/services/case.service';
import { ScreeningStates } from '@secca/shared/enums/screening-states';
import { CaseScreening, CaseScreeningAdapter } from '@secca/shared/models/caseScreening';
import { Component, Input, OnInit } from '@angular/core';
import { Case } from '@secca/shared/models/case';
import { CaseScreeningService } from '@secca/core/services/case-screening.service';
import { InsuranceService } from '@secca/core/services/insurance.service';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import { MedicalPreAssessmentDecisionEnum, StakeholderTypeEnum, TravelPurposeEnum } from '@secca/shared/models/enums';
import * as moment from 'moment';
import { ProductPurposesOfTravel } from '@secca/shared/models/productPurposesOfTravel';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import { PermissionService } from '@secca/core/services/permission.service';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { map, tap } from 'rxjs/operators';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { PlannedTravelService } from '@secca/core/services/planned-travel.service';
import { IncidentStateService } from '@secca/core/state-services/incident-state-service';
import { cloneDeep } from 'lodash-es';
import { MedicalPreAssessmentService } from '@secca/core/services/medical-pre-assessment.service';
import { CASE_TYPE_CODES } from '@secca/shared/models/case-type';
import { CaseAddressState } from '@secca/shared/enums/case-address-states';


@Component({
  selector: 'app-case-screening-tool',
  templateUrl: './case-screening-tool.component.html',
  styleUrls: ['./case-screening-tool.component.scss']
})
@AutoUnsubscribe
export class CaseScreeningToolComponent implements OnInit {

  @Input()
  set case(newCase: Case) {
    if (!this._case || (this._case && this._case.id !== newCase.id)) {
      this.screening = newCase.caseScreening;
      this.oldScreening = this.screening != null ? this.caseScreeningAdapter.adapt(this.screening) : new CaseScreening();
      // TODO Comment this in to enable suggestions based on changing case data
      // this.loadInitialData(newCase).subscribe( ignore =>
      //   this.initializeInsurance(newCase.id)
      // );
    }
    this._case = newCase;
  }

  get case(): Case {
    return this._case;
  }

  private _case: Case;

  initialized = false;
  purposeOfTravelList: DropdownDictionary[];
  screening: CaseScreening;
  oldScreening: CaseScreening;
  NO = ScreeningStates.NO;
  YES = ScreeningStates.YES;
  TBD = ScreeningStates.TO_BE_DETERMINED;
  personInsurance: PersonInsurance;
  incident: CaseIncident;
  plannedTravel: PlannedTravel;
  endUser: CaseStakeholder;
  policyHolder: CaseStakeholder;
  coverageCountries: ProfileCountry[];
  profileCountryList: ProfileCountryList;
  productPurposesOfTravel: ProductPurposesOfTravel;
  $personInsuranceSubscription: Subscription;
  MISSING = CaseAddressState.MISSING;
  PARTIAL = CaseAddressState.PARTIAL;
  DIFFERENT = CaseAddressState.DIFFERENT;
  SAME = CaseAddressState.SAME;

  constructor(
    private caseScreeningService: CaseScreeningService,
    private caseService: CaseService,
    private incidentService: IncidentService,
    private insuranceService: InsuranceService,
    private updateScreeningToolService: UpdateScreeningToolService,
    private dictionaryService: DictionaryService,
    private caseScreeningAdapter: CaseScreeningAdapter,
    public caseStateService: CaseStateService,
    private plannedTravelService: PlannedTravelService,
    private incidentStateService: IncidentStateService,
    public caseLockHelperService: CaseLockHelperService,
    public permissionService: PermissionService,
    private medicalPreAssessmentService: MedicalPreAssessmentService
  ) {
  }

  ngOnInit() {
    this.purposeOfTravelList = Object.keys(TravelPurposeEnum).map(item => new DropdownDictionary(item, TravelPurposeEnum[item]));
    this.subscribeToChanges();
    this.caseService.getStakeholdersOnCase(this._case.id).subscribe(stakeholders => {
      stakeholders.forEach(stakeholder => {
        if (stakeholder.stakeholderType === StakeholderTypeEnum.endUser) {
          this.endUser = stakeholder;
          if (stakeholder.hasRole(StakeholderTypeEnum.policyHolder)) {
            this.policyHolder = stakeholder;
          }
        }
        if (stakeholder.stakeholderType === StakeholderTypeEnum.policyHolder) {
          this.policyHolder = stakeholder;
          if (stakeholder.hasRole(StakeholderTypeEnum.endUser)) {
            this.endUser = stakeholder;
          }
        }
        if (stakeholder.stakeholderType === StakeholderTypeEnum.reporter) {
          if (stakeholder.hasRole(StakeholderTypeEnum.endUser)) {
            this.endUser = stakeholder;
          }
          if (stakeholder.hasRole(StakeholderTypeEnum.policyHolder)) {
            this.policyHolder = stakeholder;
          }
        }
      });
      this.resolveCaseAddressState(this.endUser, this.policyHolder);
    });
  }

  loadInitialData(newCase: Case): Observable<boolean[]> {

    const incident = this.incidentService.getCaseIncident(Number(newCase.incident.id)).pipe(
      tap(result => this.incident = result),
      map(ignore => true));
    const plannedTravel = this.plannedTravelService.getPlannedTravel(newCase.id).pipe(
      tap(result => this.plannedTravel = result),
      map(ignore => true));
    const endUser = this.caseService.getStakeholderForCase(newCase.id, 'END_USER').pipe(
      tap(result => this.endUser = result),
      map(ignore => true));
    const policyholder = this.caseService.getStakeholderForCase(newCase.id, 'POLICYHOLDER').pipe(
      tap(result => this.policyHolder = result),
      map(ignore => true));
    return forkJoin([incident, plannedTravel, endUser, policyholder]);
  }

  subscribeToChanges() {
    this.caseStateService.stakeholder.subscribe(
      updatedStakeholder => {
        if (updatedStakeholder.caseId === parseInt(this._case.id, 10)) {
          if ((updatedStakeholder.stakeholderType === StakeholderTypeEnum.endUser && updatedStakeholder.hasRole(StakeholderTypeEnum.policyHolder)) ||
            (updatedStakeholder.stakeholderType === StakeholderTypeEnum.policyHolder && updatedStakeholder.hasRole(StakeholderTypeEnum.endUser))) {
            // EndUser and policyHolder is the same person
            this.updatePersonGroupIncludes(updatedStakeholder, updatedStakeholder);
            this.endUser = cloneDeep(updatedStakeholder);
            this.policyHolder = cloneDeep(updatedStakeholder);
          } else {
            if (updatedStakeholder.stakeholderType === StakeholderTypeEnum.endUser) {
              this.updatePersonGroupIncludes(updatedStakeholder, this.policyHolder);
              this.endUser = cloneDeep(updatedStakeholder);
            } else if (updatedStakeholder.stakeholderType === StakeholderTypeEnum.policyHolder) {
              this.updatePersonGroupIncludes(this.endUser, updatedStakeholder);
              this.policyHolder = cloneDeep(updatedStakeholder);
            }
          }
        }
      }
    );

    this.incidentStateService.getIncident().subscribe(
      result => {
        if (result && result.caseId === this._case.id) {
          if (this.personInsurance) {
            this.updateInsurancePeriod(this.personInsurance, this.plannedTravel, result.incident);
            this.updateValidInsurance(this.personInsurance, result.incident);
            this.updateGeographicalArea(result.incident, this.personInsurance);
            this.incident = cloneDeep(result.incident);
          }
        }
      }
    );

    this.plannedTravelService.plannedTravelChanges(this._case.id).subscribe(
      result => {
        if (result) {
          if (this.personInsurance) {
            this.updateInsurancePeriod(this.personInsurance, result, this.incident);
            this.updateCoveragePeriod(this.personInsurance, result);
            this.updatePurposesOfTravel(this.personInsurance, result);
            this.plannedTravel = cloneDeep(result);
          }

        }
      }
    );
  }

  updateScreeningManual(stateProperty: string, stateValue: ScreeningStates, handlerProperty: string, handlerValue: boolean) {
    if (true) {
      this.screening[stateProperty] = stateValue;
      this.screening[handlerProperty] = handlerValue;
      this.updateCall();
    }
  }

  updateScreeningAuto(personInsurance: PersonInsurance, plannedTravel: PlannedTravel, incident: CaseIncident,
                      endUser: CaseStakeholder, policyholder: CaseStakeholder) {
    this.updateValidInsurance(personInsurance, incident);
    this.updateInsurancePeriod(personInsurance, plannedTravel, incident);
    this.updatePersonGroupIncludes(endUser, policyholder);
    this.updateGeographicalArea(incident, personInsurance);
    this.updateCoveragePeriod(personInsurance, plannedTravel);
    this.updatePurposesOfTravel(personInsurance, plannedTravel);
  }

  isCaseTypeMedicalPreAssesssment(caseType): boolean {
    return CASE_TYPE_CODES.MEDICAL_PRE_ASSESSMENT ===  caseType;
  }

  updatePurposesOfTravel(personInsurance: PersonInsurance, plannedTravel: PlannedTravel) {
    if(this.personInsurance){
      if (personInsurance.customerProduct === this.personInsurance?.customerProduct
        && plannedTravel.purposesOfTravel === this.plannedTravel.purposesOfTravel
      ) {
        return;
      }
      if (!personInsurance?.customerProduct || !plannedTravel?.purposesOfTravel || !this.productPurposesOfTravel) {
        this.screening.purposesOfTravelState = ScreeningStates.TO_BE_DETERMINED;
        this.screening.purposesOfTravelSetByCaseHandler = false;
        this.updateIncidentCovered();
        return;
      }

      this.screening.purposesOfTravelState =
        this.setPurposeOfTravelState(this.productPurposesOfTravel.primaryPurpose,
          this.productPurposesOfTravel.secondaryPurposes,
          JSON.parse(JSON.stringify(plannedTravel.purposesOfTravel)));

      this.screening.purposesOfTravelSetByCaseHandler = false;
      this.updateIncidentCovered();
    }
  }

  setPurposeOfTravelState(primaryPurposeOfTravel: string,
                          secondaryPurposesOfTravel: string[],
                          incidentPurposesOfTravel: string[]): ScreeningStates {

    if (incidentPurposesOfTravel.length === 0) {
      return ScreeningStates.TO_BE_DETERMINED;
    }

    if (primaryPurposeOfTravel === 'ALL_PURPOSES') {
      return ScreeningStates.YES;
    }

    const foundPrimaryPurpose = incidentPurposesOfTravel.filter(item => item === primaryPurposeOfTravel);

    if (foundPrimaryPurpose.length === 1 && foundPrimaryPurpose[0] !== null) {

      const index = incidentPurposesOfTravel.indexOf(foundPrimaryPurpose[0]);
      if (index > -1) {
        incidentPurposesOfTravel.splice(index, 1);
      }

      if (incidentPurposesOfTravel.length === 0) {
        return ScreeningStates.YES;
      }

      let allPurposesOfTravelMatchASecondaryPurposesOfTravel = true;
      incidentPurposesOfTravel.forEach( purpose => {
        if (allPurposesOfTravelMatchASecondaryPurposesOfTravel) {
          allPurposesOfTravelMatchASecondaryPurposesOfTravel = secondaryPurposesOfTravel.includes(purpose);
        }
      });

      if (allPurposesOfTravelMatchASecondaryPurposesOfTravel) {
        return ScreeningStates.TO_BE_DETERMINED;
      }
    }
    return ScreeningStates.NO;
  }

  updateGeographicalArea(incident: CaseIncident, personInsurance: PersonInsurance) {
    if (this.incident){
      if (this.incident.incidentLocation?.countryCodeAlpha2 === incident.incidentLocation?.countryCodeAlpha2
        && this.personInsurance.geographicalCoverageArea === personInsurance.geographicalCoverageArea
      ) {
        return;
      }
      if (!incident.incidentLocation.countryCodeAlpha2 || !personInsurance.geographicalCoverageArea) {
        this.screening.geographicalAreaSetByCaseHandler = false;
        this.screening.geographicalAreaState = ScreeningStates.TO_BE_DETERMINED;
        this.updateIncidentCovered();
        return;
      }
      this.updateScreeningToolService.getCountryList(personInsurance.geographicalCoverageArea.toString()).subscribe(result => {
        this.profileCountryList = result;
        if (this.profileCountryList.countries.length === 0) {
          this.screening.geographicalAreaState = ScreeningStates.TO_BE_DETERMINED;
        }
        const incidentCountryIsCovered = this.profileCountryList.countries.filter(c =>
          c.countryCodeAlpha2 === incident.incidentLocation.countryCodeAlpha2).length !== 0;
        if (incidentCountryIsCovered) {
          this.screening.geographicalAreaState = ScreeningStates.YES;
        } else {
          this.screening.geographicalAreaState = ScreeningStates.NO;
        }
        this.screening.geographicalAreaSetByCaseHandler = false;
        this.updateIncidentCovered();
      });
    }
  }

  updateValidInsurance(personInsurance: PersonInsurance, incident: CaseIncident) {
    if(this.personInsurance){
      if (personInsurance.creditCard === this.personInsurance.creditCard
        && personInsurance.insurancePeriodFrom === this.personInsurance.insurancePeriodFrom
        && personInsurance.insurancePeriodTo === this.personInsurance.insurancePeriodTo
        && incident.incidentDate === this.incident.incidentDate
      ) {
        return;
      }
      if (personInsurance.creditCard) {
        this.screening.validInsuranceStates = ScreeningStates.TO_BE_DETERMINED;
      } else {
        this.screening.validInsuranceStates = ScreeningStates.YES;
      }
      this.screening.validInsuranceSetByCaseHandler = false;
      this.screening.validInsuranceStates = this.momentBetweenCheck(
        personInsurance.insurancePeriodFrom,
        personInsurance.insurancePeriodTo,
        incident.incidentDate,
        this.screening.validInsuranceStates
      );
      this.updateIncidentCovered();
    }

  }

  updateInsurancePeriod(personInsurance: PersonInsurance, plannedTravel: PlannedTravel, incident: CaseIncident) {
    if(this.personInsurance){
      if (personInsurance.insurancePeriodFrom === this.personInsurance.insurancePeriodFrom
        && personInsurance.insurancePeriodTo === this.personInsurance.insurancePeriodTo
        && personInsurance.coverageDays === this.personInsurance.coverageDays
        && plannedTravel.departureDate === this.plannedTravel.departureDate
        && plannedTravel.arrivalDate === this.plannedTravel.arrivalDate
        && incident.incidentDate === this.incident.incidentDate) {
        return;
      }
      const planTravelInInsuranceCheck = this.updateInsurancePeriodPlannedTravelCheck(personInsurance, plannedTravel);
      const coverageInInsuranceCheck = this.updateInsuranceCoverageCheck(personInsurance, plannedTravel, incident);

      if (planTravelInInsuranceCheck === coverageInInsuranceCheck) {
        this.screening.insurancePeriodState = coverageInInsuranceCheck;
      } else if (planTravelInInsuranceCheck === ScreeningStates.NO || coverageInInsuranceCheck === ScreeningStates.NO) {
        this.screening.insurancePeriodState = ScreeningStates.NO;
      } else {
        this.screening.insurancePeriodState = ScreeningStates.TO_BE_DETERMINED;
      }
      this.screening.insurancePeriodSetByCaseHandler = false;
      this.updateIncidentCovered();
    }

  }

  updateInsurancePeriodPlannedTravelCheck(personInsurance: PersonInsurance, plannedTravel: PlannedTravel): ScreeningStates {
    const departureDateCheck = this.momentBetweenCheck(personInsurance.insurancePeriodFrom,
    personInsurance.insurancePeriodTo, plannedTravel.departureDate, ScreeningStates.YES);
    const arrivalDateCheck = this.momentBetweenCheck(personInsurance.insurancePeriodFrom,
    personInsurance.insurancePeriodTo, plannedTravel.arrivalDate, ScreeningStates.YES);
    if (departureDateCheck === arrivalDateCheck) {
      return arrivalDateCheck;
    } else if (departureDateCheck === ScreeningStates.NO || arrivalDateCheck === ScreeningStates.NO) {
      return ScreeningStates.NO;
    } else {
      return ScreeningStates.TO_BE_DETERMINED;
    }
  }

  updateInsuranceCoverageCheck(personInsurance: PersonInsurance, plannedTravel: PlannedTravel, incident: CaseIncident): ScreeningStates {
    if (!personInsurance.coverageDays?.length) {
      return ScreeningStates.TO_BE_DETERMINED;
    }
    const coverageDaysForComparison = Number(personInsurance.coverageDays.replace(/[^0-9]/g, '')) - 1;
    const coverageStopDate = plannedTravel.departureDate?.clone()?.add(coverageDaysForComparison, 'days');
    return this.momentBetweenCheck(plannedTravel.departureDate,
      coverageStopDate, incident.incidentDate, ScreeningStates.YES);
  }

  isSameAddress(endUser: CaseStakeholder, policyHolder: CaseStakeholder): boolean {
    return (this.isSameStreet(endUser, policyHolder) &&
      this.isSameCity(endUser, policyHolder) &&
      this.isSamePostal(endUser, policyHolder));
  }

  isMissingAddress(endUser: CaseStakeholder, policyHolder: CaseStakeholder): boolean {
    return (!endUser.address.street && !endUser.address.city && !endUser.address.postalCode &&
      !policyHolder.address.street && !policyHolder.address.city && !policyHolder.address.postalCode);
  }

  isSameStreet(endUser: CaseStakeholder, policyHolder: CaseStakeholder): boolean {
    return (this.isExistingStreet(endUser) && this.isExistingStreet(policyHolder) &&
      endUser.address?.street?.trim().toLowerCase() === policyHolder.address?.street?.trim().toLowerCase());
  }

  isSameCity(endUser: CaseStakeholder, policyHolder: CaseStakeholder): boolean {
    return (this.isExistingCity(endUser) && this.isExistingCity(policyHolder) &&
      endUser.address?.city?.trim().toLowerCase() === policyHolder.address?.city?.trim().toLowerCase());
  }

  isSamePostal(endUser: CaseStakeholder, policyHolder: CaseStakeholder): boolean {
    return (this.isExistingPostal(endUser) && this.isExistingPostal(policyHolder) &&
      endUser.address?.postalCode?.trim().toLowerCase() === policyHolder.address?.postalCode?.trim().toLowerCase());
  }

  isExistingStreet(user: CaseStakeholder): boolean {
    return (user.address?.street && user.address?.street.trim().length !== 0 );
  }

  isExistingCity(user: CaseStakeholder): boolean {
    return (user.address?.city && user.address?.city.trim().length !== 0 );
  }

  isExistingPostal(user: CaseStakeholder): boolean {
    return (user.address?.postalCode && user.address?.postalCode.trim().length !== 0);
  }

  isDifferentAddress(endUser: CaseStakeholder, policyHolder: CaseStakeholder): boolean {
    return !this.isSameAddress(endUser, policyHolder) && !this.isMissingAddress(endUser, policyHolder) &&
      ((this.isExistingPostal(endUser) && this.isExistingPostal(policyHolder) && !this.isSamePostal(endUser, policyHolder)) ||
        (this.isExistingCity(endUser) && this.isExistingCity(policyHolder) && !this.isSameCity(endUser, policyHolder)) ||
        (this.isExistingStreet(endUser) && this.isExistingStreet(policyHolder) && !this.isSameStreet(endUser, policyHolder)));
  }

  private resolveCaseAddressState(endUser: CaseStakeholder, policyHolder: CaseStakeholder) {
    if (this.isMissingAddress(endUser, policyHolder)) {
      this.screening.caseAddressState = CaseAddressState.MISSING;
    } else if (this.isSameAddress(endUser, policyHolder)) {
      this.screening.caseAddressState = CaseAddressState.SAME;
    } else if (this.isDifferentAddress(endUser, policyHolder)) {
      this.screening.caseAddressState = CaseAddressState.DIFFERENT;
    } else {
      this.screening.caseAddressState = CaseAddressState.PARTIAL;
    }
  }

  updatePersonGroupIncludes(endUser: CaseStakeholder, policyHolder: CaseStakeholder) {
    if (endUser && policyHolder) {
      this.resolveCaseAddressState(endUser, policyHolder);
      if (this.screening.caseAddressState !== CaseAddressState.SAME &&
        (this.endUser?.address?.street?.trim().toLowerCase() !== endUser.address?.street?.trim().toLowerCase()
          || this.endUser?.address?.city?.trim().toLowerCase() !== endUser.address?.city?.trim().toLowerCase()
          || this.endUser?.address?.postalCode?.trim().toLowerCase() !== endUser.address?.postalCode?.trim().toLowerCase()
          || this.policyHolder?.address?.street?.trim().toLowerCase() !== policyHolder?.address?.street?.trim().toLowerCase()
          || this.policyHolder?.address?.city?.trim().toLowerCase() !== policyHolder?.address?.city?.trim().toLowerCase()
          || this.policyHolder?.address?.postalCode?.trim().toLowerCase() !== policyHolder?.address?.postalCode?.trim().toLowerCase())) {
          // Enduser or policyHolder address has changed, and they have different addresses: User needs to reconsider personGroupIncludesState
          this.screening.personGroupIncludesState = ScreeningStates.TO_BE_DETERMINED;
          this.screening.personGroupIncludesSetByCaseHandler = false;
          this.updateIncidentCovered();
      } else if (this.screening.caseAddressState !== this.oldScreening.caseAddressState) {
        this.updateCall();
      }
    }
  }

  updateCoveragePeriod(personInsurance: PersonInsurance, plannedTravel: PlannedTravel) {
    if (this.personInsurance) {
      if (this.personInsurance.coverageDays === personInsurance.coverageDays
        && this.plannedTravel.durationDays === plannedTravel.durationDays
      ) {
        return;
      }
      const plannedTravelDuration = plannedTravel.durationDays ? Number(plannedTravel.durationDays) : null;
      const coverageDays = personInsurance.coverageDays ? Number(personInsurance.coverageDays.replace(/[^0-9]/g, '')) : null;
      if (plannedTravelDuration === null || coverageDays === null) {
        this.screening.coveragePeriodState = ScreeningStates.TO_BE_DETERMINED;
      } else if (plannedTravelDuration <= coverageDays) {
        this.screening.coveragePeriodState = ScreeningStates.YES;
      } else {
        this.screening.coveragePeriodState = ScreeningStates.NO;
      }
      this.screening.coveragePeriodSetByCaseHandler = false;
      this.updateIncidentCovered();
    }
  }

  updateIncidentCovered() {
    if (this.screening.validInsuranceStates === ScreeningStates.NO ||
      this.screening.insurancePeriodState === ScreeningStates.NO ||
      this.screening.personGroupIncludesState === ScreeningStates.NO ||
      this.screening.geographicalAreaState === ScreeningStates.NO ||
      this.screening.purposesOfTravelState === ScreeningStates.NO ||
      this.screening.coveragePeriodState === ScreeningStates.NO) {
      this.screening.incidentCoveredState = ScreeningStates.NO;
    } else {
      this.screening.incidentCoveredState = ScreeningStates.TO_BE_DETERMINED;
    }
    this.screening.incidentCoveredSetByCaseHandler = false;
    if (JSON.stringify(this.screening) !== JSON.stringify(this.oldScreening)) {
      this.updateCall();
    }
  }

  get MedicalPreAssessmentDecisionEnum() {
    return MedicalPreAssessmentDecisionEnum;
  }

  updateCall() {
    this.caseScreeningService.updateCaseScreening(this.screening).subscribe(result => {
      this.screening = result;
      this.oldScreening = this.caseScreeningAdapter.adapt(this.screening);
    });
  }

  momentBetweenCheck(from: moment.Moment, to: moment.Moment, theDate: moment.Moment, presetState: ScreeningStates): ScreeningStates {
    if (!from || !to || !theDate) {
      return ScreeningStates.TO_BE_DETERMINED;
    }
    if (from.isAfter(theDate, 'day') || theDate.isAfter(to, 'day')) {
      return ScreeningStates.NO;
    } else {
      return presetState;
    }
  }
}
