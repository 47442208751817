<div class="common-drop-down-arrow"
  [className]="validate && isValidated(selectedItemId) ? 'invalid-drop-down-talked-to' : 'common-drop-down-arrow'">
  <span class="name">{{name}}</span>
  <ng-select
    (blur)="onSaveModel()"
    (focus)="onSaveState()"
    [clearable]="false"
    [(ngModel)]="selectedItemId"
    class="custom"
    [disabled]="!!disabled"
    (change)="onChange()"
    (focus)="onOpen()"
    [items]="items"
    [compareWith]="compareFunction"
    dropdownPosition="top"
    bindLabel="nameForSearch"
    [class.recommended-yellow-background]="isRequired() && !requiredRedBackground"
    [class.required-red-background]="isRequired() && requiredRedBackground"
    >
    <ng-template ng-label-tmp let-item="item" >
      {{ item.nameForSearch }}
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
        <ng-container [ngSwitch]="item.stakeholderType">
          <img [src]="getStakeholderIconType(item)" class="stakeholder-icon">
            {{ item.nameForSearch }}
        </ng-container>
    </ng-template>
  </ng-select>
</div>
