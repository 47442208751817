import { AssessmentTypeEnum, HandlingAreaStatusEnum, SortOrder } from '@secca/shared/models/enums';
import * as moment from 'moment';
import { CustomerProfileIdsForSearch } from '../../board-task/models/task-filter-request.model';

export class CaseMedicalFilterRequest {
  toDate: moment.Moment;
  fromDate: moment.Moment;
  statuses: HandlingAreaStatusEnum[];
  sortBy: string;
  userIds: number[];
  adtCodes: string[];
  pageIndex: number;
  pageSize: number;
  sortOrder: SortOrder;
  includeOtherTeams: boolean;
  medicalStatus: string[];
  assessmentType: AssessmentTypeEnum[];
  customerProfileId: number;
  customerProfileIds: CustomerProfileIdsForSearch[];
  adtCodesForCount: string[];

  public constructor(init?: Partial<CaseMedicalFilterRequest>) {
    Object.assign(this, init);
  }
}
