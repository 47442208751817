import {Component, Input} from '@angular/core';
import {AutoUnsubscribe} from '@secca/shared/decorators/auto-unsubscribe';
import {FileScan} from '@secca/shared/models/fileScan';

@Component({
  selector: 'app-hover-file-scan',
  templateUrl: './hover-file-scan.component.html',
  styleUrls: ['./hover-file-scan.component.scss']
})
@AutoUnsubscribe
export class HoverFileScanComponent {

  _fileScan: FileScan;

  @Input()
  set fileScan(fileScan: FileScan) {
    this._fileScan = fileScan;
  }

  get fileScan() :FileScan {
    return this._fileScan;
  }
}
