import {Injectable} from '@angular/core';
import {Adapter} from '@secca/shared/interfaces/adapter';
import {TaskStatus, TaskType} from '@secca/shared/models/enums';
import * as moment from 'moment';
import {DateSerializer} from '@secca/shared/models/date-serializer';
import { AttachmentAdapter, AttachmentDto } from '@secca/shared/models/attachment-dto';
import {TaskCountType} from '@secca/board/components/board-task/models/task-count-type.model';

export class TaskCountTeam {

  adtCode: string;
  taskTypeCountList: TaskCountType[];

  public constructor(init?: Partial<TaskCountTeam>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
export class TaskCountTeamAdapter implements Adapter<TaskCountTeam> {

  adapt(item: any): TaskCountTeam {
    return new TaskCountTeam({
      adtCode: item.adtCode,
      taskTypeCountList: item.taskTypeCountList
    });
  }
}
