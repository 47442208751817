import {Injectable} from '@angular/core';
import {Adapter} from '../interfaces/adapter';

export class Location {
  id: number;
  freeText: string;
  streetNumber: string;
  streetName: string;
  locality: string;
  administrativeAreaLevel1: string;
  administrativeAreaLevel2: string;
  country: string;
  postalCode: string;
  latitude: number;
  longitude: number;
  countryCodeAlpha2: string;
  utcOffsetInHours: number;
  timeZoneId: string;

  public constructor(init?: Partial<Location>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})

export class LocationAdapter implements Adapter<Location> {
  adapt(item: any): Location {
    return new Location({
      id: item.id,
      freeText: item.freeText,
      streetNumber: item.streetNumber,
      streetName: item.streetName,
      locality: item.locality,
      administrativeAreaLevel1: item.administrativeAreaLevel1,
      administrativeAreaLevel2: item.administrativeAreaLevel2,
      country: item.country,
      postalCode: item.postalCode,
      latitude: item.latitude,
      longitude: item.longitude,
      countryCodeAlpha2: item.countryCodeAlpha2,
      utcOffsetInHours: item.utcOffsetInHours,
      timeZoneId: item.timeZoneId
    });
  }
}

