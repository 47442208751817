import { FinanceBridgeStatusService } from '@secca/core/services/finance-bridge-status.service';
import { registerLocaleData } from '@angular/common';
import { trigger } from '@angular/animations';
import { SelectedTab } from './../../../case/components/case-plans/case-plans/add-service-order/models/enums';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AutoCustomerInvoiceJobService } from '@secca/board/components/board-sales-order/services/auto-customer-invoice-job.service';
import { CaseService } from '@secca/core/services/case.service';
import { CommonCustomersService } from '@secca/core/services/common-customers.service';
import { DataService } from '@secca/core/services/data.service';
import { SettingsService } from '@secca/core/services/settings.service';
import { CustomerProfile } from '@secca/shared/models/customerProfile';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import {
  CustomerInvoiceJobStatusEnum,
  FinancialStatusEnum,
  InputType,
  InputTypePattern,
  SalesOrderStatusEnum, ShortcutEnum,
  SortOrder, UserTeam
} from '@secca/shared/models/enums';
import { TableSort } from '@secca/shared/models/table-sort';
import * as moment from 'moment';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CustomerInvoiceJobFilterRequest } from './models/customer-invoice-job-filter-request';
import { CustomerInvoiceJobFilterResponse } from './models/customer-invoice-job-filter-response';
import { ShortcutService } from '@secca/core/services/shortcut.service';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { ResetFinanceBridgeStatustDto } from '@secca/shared/models/ResetFinanceBridgeStatustDto';

@Component({
  selector: 'app-board-sales-order',
  templateUrl: './board-sales-order.component.html',
  styleUrls: ['./board-sales-order.component.scss'],
})
@AutoUnsubscribe
export class BoardSalesOrderComponent implements OnInit {
  datePlaceholder = 'dd mm yyyy';
  filterRequest: CustomerInvoiceJobFilterRequest = new CustomerInvoiceJobFilterRequest({
    sortBy: 'salesOrderCreatedOn',
    sortOrder: SortOrder.asc,
    pageSize: SettingsService.pageSizeForPagination,
    pageIndex: 0,
  });
  filterResponse: CustomerInvoiceJobFilterResponse;

  allCustomerProfiles: DropdownDictionary[];
  allRetryCount: DropdownDictionary[];
  allDebtors: DropdownDictionary[];
  allErpClients: DropdownDictionary[];
  allSalesOrderStatus = this.getSalesOrderStatusList();
  allCaseFinancialStatus = this.getFinancialStatusList();
  allInvoiceJobStatus = this.getInvoiceJobStatusList();
  private lastCustomerInvoiceJobDate: moment.Moment;


  private shortcutSubscriptions: Subscription[] = [];

  constructor(
    private caseService: CaseService,
    private dataService: DataService,
    private commonCustomersService: CommonCustomersService,
    private autoCustomerInvoiceJobService: AutoCustomerInvoiceJobService,
    private translateService: TranslateService,
    private shortcutService: ShortcutService,
    private router: Router,
    private financeBridgeStatusService: FinanceBridgeStatusService
  ) {
    this.shortcutSubscriptions.push(
    );
  }

  ngOnInit(): void {
    this.initializeCustomerProfiles();
    this.initializeDebtors();
    this.initializeRetryCount();
    const filterJSON = sessionStorage.getItem('customer-invoice-filter-request');
    if (!!filterJSON) {
      const restoredFilter = JSON.parse(filterJSON);
      this.filterRequest = new CustomerInvoiceJobFilterRequest(JSON.parse(filterJSON));
      this.filterRequest.salesOrderCreationRange = this.parseDateRange(restoredFilter.salesOrderCreationRange);
      this.filterRequest.invoiceDateRange = this.parseDateRange(restoredFilter.invoiceDateRange);
      this.filterRequest.invoiceJobDateRange = this.parseDateRange(restoredFilter.invoiceJobDateRange);
      this.loadSalesOrderInvoiceJobs(true);
    } else {
      this.loadLastCustomerInvoiceJob();
    }
  }

  private parseDateRange(stringArray: string[]): Date[] {
    const dateRange = [null, null];
    if (stringArray && stringArray.length > 0) {
      dateRange[0] = !stringArray[0] || stringArray[0] === 'null' ? null : new Date(stringArray[0]);
    }
    if (stringArray && stringArray.length > 1) {
      dateRange[1] = !stringArray[1] || stringArray[1] === 'null' ? null : new Date(stringArray[1]);
    }
    return dateRange;
  }

  public get InputType() {
    return InputType;
  }
  public get InputTypePattern() {
    return InputTypePattern;
  }

  private getSalesOrderStatusList(): DropdownDictionary[] {
    const list: DropdownDictionary[] = [];
    Object.keys(SalesOrderStatusEnum).forEach(key => {
      list.push(new DropdownDictionary(key, this.translateService.instant('sales-order-status-' + key)));
    });
    return list;
  }
  private getFinancialStatusList(): DropdownDictionary[] {
    const list: DropdownDictionary[] = [];
    Object.keys(FinancialStatusEnum).forEach(key =>
      list.push(new DropdownDictionary(key, this.translateService.instant('case-economy-financial-status-' + key)))
    );
    return list;
  }
  private getInvoiceJobStatusList(): DropdownDictionary[] {
    const list: DropdownDictionary[] = [];
    Object.keys(CustomerInvoiceJobStatusEnum).forEach(key =>
      list.push(new DropdownDictionary(key, this.translateService.instant('customer-invoice-sales-order-status-' + key)))
    );
    return list;
  }
  private initializeCustomerProfiles() {
    this.caseService.getSimpleCustomerProfiles().subscribe(result => {
      this.allCustomerProfiles = [];
      result.forEach(cp => this.allCustomerProfiles.push(new DropdownDictionary(cp.profileId, cp.profileName)));
    });
  }

  private initializeRetryCount() {
    this.allRetryCount = [];
    this.allRetryCount.push(new DropdownDictionary(0, 0));
    this.allRetryCount.push(new DropdownDictionary(1, 1));
    this.allRetryCount.push(new DropdownDictionary(2, 2));
    this.allRetryCount.push(new DropdownDictionary(3, 3));
    this.allRetryCount.push(new DropdownDictionary(4, 4));
    this.allRetryCount.push(new DropdownDictionary(5, 5));

  }
  private initializeDebtors() {
    this.commonCustomersService.getAllDebtors().subscribe(result => {
      this.allDebtors = result;
      const erpClients = [];
      result.forEach(debtor => {
        if (!erpClients.find(erpClient => erpClient.id === debtor.erpClient.id)) {
          erpClients.push(new DropdownDictionary(debtor.erpClient.id, debtor.erpClient.name));
        }
      });
      this.allErpClients = erpClients;
    });
  }

  private loadLastCustomerInvoiceJob() {
    this.autoCustomerInvoiceJobService.getLastCustomerInvoiceJobDate().subscribe(result => {
      this.lastCustomerInvoiceJobDate = result.startedOn;
      this.resetFilter(this.lastCustomerInvoiceJobDate);
      this.loadSalesOrderInvoiceJobs(true);
    });
  }
  loadSalesOrderInvoiceJobs(setPageIndexToZero: boolean) {
    if (setPageIndexToZero) {
      this.filterRequest.pageIndex = 0;
    }
    this.autoCustomerInvoiceJobService.getFilterResponse(this.filterRequest).subscribe(response => (this.filterResponse = response));
  }

  loadSalesOrderWithSalesOrderId() {
    this.filterRequest.salesOrderCreationRange = [null, null];
    this.filterRequest.invoiceDateRange = [null, null];
    this.filterRequest.salesOrderCreationRange = [null, null];
    this.autoCustomerInvoiceJobService.getFilterResponse(this.filterRequest).subscribe(response => (this.filterResponse = response));
  }

  clearFilter() {
    if (!!this.lastCustomerInvoiceJobDate) {
      this.resetFilter(this.lastCustomerInvoiceJobDate);
      this.loadSalesOrderInvoiceJobs(true);
    } else {
      this.loadLastCustomerInvoiceJob();
    }
  }

  private resetFilter(lastCustomerInvoiceJobDate: moment.Moment) {
    this.filterRequest.salesOrderCreationRange = [null, null];
    this.filterRequest.invoiceDateRange = [null, null];
    this.filterRequest.invoiceJobDateRange = [
      !!lastCustomerInvoiceJobDate ? lastCustomerInvoiceJobDate.toDate() : null,
      moment.utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
    ];
    this.filterRequest.salesOrderId = null;
    this.filterRequest.customerProfile = [];
    this.filterRequest.caseNumber = null;
    this.filterRequest.debtor = [];
    this.filterRequest.erpClient = [];
    this.filterRequest.invoiceNo = null;
    this.filterRequest.salesOrderStatus = [];
    this.filterRequest.caseFinancialStatus = [];
    this.filterRequest.invoiceJobStatus = [];
    this.filterRequest.pageIndex = 0;
  }

  refresh() {
    this.loadSalesOrderInvoiceJobs(true);
  }

  sortingAction(sorting: TableSort) {
    this.filterRequest.sortBy = sorting.sortBy;
    this.filterRequest.sortOrder = sorting.sortOrder;
    this.loadSalesOrderInvoiceJobs(false);
  }

  paginationChanged(index: number): void {
    this.filterRequest.pageIndex = index;
    this.loadSalesOrderInvoiceJobs(false);
  }

  ngOnDestroy(): void {
    sessionStorage.setItem('customer-invoice-filter-request', JSON.stringify(this.filterRequest));
  }

  navigateToCase(salesOrderId: number) {
    const navigateTo = this.filterResponse.jobSalesOrders.find(r => r.salesOrderId === salesOrderId);
    if (navigateTo) {
      this.dataService.navigateSalesOrderId = salesOrderId;
      this.router.navigate(['case', navigateTo.caseId]);
    }
  }

  resetRetryCount() : void{
    console.log('resetRetryCount');

    let selectedSalesOrder = this.createSelectedSalesOrderIds();
    if(selectedSalesOrder !== undefined && selectedSalesOrder.salesOrderId !== undefined && selectedSalesOrder.salesOrderId.length > 0 ) {
      let response =  this.financeBridgeStatusService.postResetRetryCount(selectedSalesOrder).subscribe(result => {
        this.refresh();
      });
    }
  }

  resetAckMessage() : void{
    console.log('resetAckMessage');
    let selectedSalesOrder = this.createSelectedSalesOrderIds();
    if(selectedSalesOrder !== undefined && selectedSalesOrder.salesOrderId !== undefined && selectedSalesOrder.salesOrderId.length > 0 ) {
      this.financeBridgeStatusService.postResetAckMessage(selectedSalesOrder).subscribe(result => {
        this.refresh();
      });
    }
  }

  resetPaymentStatus() : void{
    if(!this.hasOnlyOneSalesOrderSelected()){
      return;
    }
    let selectedSalesOrder = this.createSelectedSalesOrderIds();
    if(selectedSalesOrder !== undefined && selectedSalesOrder.salesOrderId !== undefined && selectedSalesOrder.salesOrderId.length > 0 ) {
      let response =  this.financeBridgeStatusService.postResetPaymentStatus(selectedSalesOrder).subscribe(result => {
        this.refresh();
      });
    }
  }

  resetInvoiceDocument() : void{
    if(!this.hasOnlyOneSalesOrderSelected()){
      return;
    }
    let selectedSalesOrder = this.createSelectedSalesOrderIds();
    if(selectedSalesOrder !== undefined && selectedSalesOrder.salesOrderId !== undefined && selectedSalesOrder.salesOrderId.length > 0 ) {
      this.financeBridgeStatusService.postResetInvoiceDocument(selectedSalesOrder).subscribe(result => {
        this.refresh();
      });
    }
  }

  hasOnlyOneSalesOrderSelected() : boolean{
    return this.filterResponse?.jobSalesOrders?.filter(salesOrder => salesOrder.isCheck).length == 1;
  }

  createSelectedSalesOrderIds(): ResetFinanceBridgeStatustDto {
    let selectedSalesOrders = new Array;
    this.filterResponse.jobSalesOrders?.forEach(salesOrder => {
      console.log('createSelectedSalesOrderIds');
      console.log(salesOrder.isCheck);
      if(salesOrder.isCheck){
        selectedSalesOrders.push(salesOrder.salesOrderId);
      }
    });
    console.log('createSelectedSalesOrderIds-count');
    console.log(selectedSalesOrders);
    return  new ResetFinanceBridgeStatustDto({
      salesOrderId: selectedSalesOrders,
    });
  }

}
