<div [appDisableInput]="caseStateService.isCaseDisabled()"
class="caselock-no-events">
  <div class="message-header">
    <div class="d-flex" style="gap: 10px">
      <div>
        <div class="message-header-label">{{ 'task-web-modal-channel' | translate }}</div>
        <input type="text" name="textinput" class="task-text"
          value="{{ getChannelTypeName(taskDataViewModel?.channelType) }}" [disabled]="true"
          [readonly]="true" />
      </div>
      <div>
        <div class="message-header-label">{{ 'task-web-modal-cause' | translate }}</div>
        <input type="text" name="textinput" class="cause-text"
          value="{{cause}}" [disabled]="true"
          [readonly]="true">
      </div>
      <div *ngIf="taskDataViewModel?.data?.origin">
        <div class="message-header-label">{{ 'task-web-modal-origin' | translate }}</div>
        <input type="text" name="textinput" class="origin-text"
          value="{{taskDataViewModel?.data?.origin}}" [disabled]="true"
          [readonly]="true">
      </div>
      <div class="mt-auto mb-auto" *ngIf="taskDataViewModel?.customerProfileLegacy">
                        <span class="message-content-header-red">
                          {{'customer-not-in-secca' | translate }}
                        </span>
      </div>
      <div class="mt-auto mb-auto" *ngIf="!hasValidCustomerTeamRelation">
                        <span class="message-content-header-red">
                          {{'customer-no-team-relation' | translate }}
                        </span>
      </div>
      <div class="mt-auto mb-auto" *ngIf="!hasValidCustomerContractRelation">
                        <span class="message-content-header-red">
                          {{'customer-no-contract-relation' | translate }}
                        </span>
      </div>
      <div class="ms-auto align-self-end" *ngIf="!hideSearchCreateButton && !isCaseBasicLockTaken">
        <button class="primary-button" *ngIf="taskDataViewModel && !taskDataViewModel.caseValidated && !taskDataViewModel.customerProfileLegacy && createCaseOnly"
                (click)="createCase()">{{ 'task-web-modal-create-case' | translate }}</button>
        <button class="primary-button" *ngIf="taskDataViewModel && !taskDataViewModel.caseValidated && !taskDataViewModel.customerProfileLegacy && !createCaseOnly"
                (click)="goToSearchCase()"><span class="shortcut-key">S</span>earch or create case<br><small>(Ctrl + Shift + S)</small></button>
      </div>
    </div>
  </div>
</div>
