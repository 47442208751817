import { Injectable } from '@angular/core';
import { Adapter } from './../interfaces/adapter';

export class OtherInsurance {
    id: string;
    seccaCaseId: string;
    hasOtherInsurance: string;
    creditCardBank: string;
    creditCardNo: string;
    creditCardType: string;
    creditCardRemarks: string;
    insuranceCompany: string;
    insurancePolicyNo: string;
    insuranceProduct: string;
    insuranceRemarks: string;

    public constructor(init?: Partial<OtherInsurance>) {
        Object.assign(this, init);
    }
}

@Injectable({
    providedIn: 'root'
})
export class OtherInsuranceAdapter implements Adapter<OtherInsurance> {
    adapt(item: any): OtherInsurance {

        return new OtherInsurance({
            id: item.id,
            seccaCaseId: item.seccaCaseId,
            hasOtherInsurance: item.hasOtherInsurance,
            creditCardBank: item.creditCardBank,
            creditCardNo: item.creditCardNo,
            creditCardType: item.creditCardType,
            creditCardRemarks: item.creditCardRemarks,
            insuranceCompany: item.insuranceCompany,
            insurancePolicyNo: item.insurancePolicyNo,
            insuranceProduct: item.insuranceProduct,
            insuranceRemarks: item.insuranceRemarks
        });
    }
}
