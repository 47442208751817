import { MedicalAssessment } from './../../../../../../../../shared/models/medicalAssessment';
import { StakeholderService } from 'src/app/core/services/stakeholder.service';
import { PhoneNumber } from '@secca/shared/models/phoneNumber';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { DropdownWitIconDictionary } from '@secca/shared/models/dropdownWithIconDictionary';
import { SelectItem } from '@secca/case/components/case-medical/medical-components/medical-models';
import { ExtraSpaceEnum, TransitEnum } from './../../../../../../../../shared/models/enums';
import { RepatriationFormEnum, PhoneNumberType } from '@secca/shared/models/enums';
import { TranslateService } from '@ngx-translate/core';
import { DropdownDictionary } from 'src/app/shared/models/dropdownDictionary';
import { MedicalService } from '@secca/core/services/medical.service';
import {Component, Input, Output, EventEmitter} from '@angular/core';
import { SubRepatriationFormMedical } from '../../models/subRepatriationFormMedical';
import { SubRepatriationDetails } from '../../models/subRepatriationDetails';
import { ServiceOrderDetailsHelper } from '@secca/case/components/case-plans/plan/service-order/service-order-details-helper';
import { IccRepatriationInfo } from '@secca/shared/models/service-order/icc-repatriation-info';

@Component({
  selector: 'app-sub-repatriation-form-medical',
  templateUrl: './sub-repatriation-form-medical.component.html',
  styleUrls: ['./sub-repatriation-form-medical.component.scss'],
})
export class SubRepatriationFormMedicalComponent {
  @Input() subRepatriationDetails: SubRepatriationDetails;

  @Input()
  public get subRepatriationFormMedical(): SubRepatriationFormMedical {
    return this._subRepatriationFormMedical;
  }
  public set subRepatriationFormMedical(subRepatriationFormMedical: SubRepatriationFormMedical) {
    this._subRepatriationFormMedical = subRepatriationFormMedical;
    this.tryToGetRepatriation();
    this.tryToSetStakeholderOrganizer();
  }

  private _subRepatriationFormMedical: SubRepatriationFormMedical;
  @Input()
  public get caseId(): string {
    return this._caseId;
  }
  public set caseId(caseId: string) {
    this._caseId = caseId;
    this.tryToGetRepatriation();
  }
  @Input()
  public get stakeholders(): CaseStakeholder[] {
    return this._stakeholders;
  }

  public set stakeholders(stakeholders: CaseStakeholder[]) {
    this._stakeholders = stakeholders;
    if (stakeholders != null) {
      this.stakeholdersDropdown = [];
      stakeholders.forEach(stakeholder => {
        this.stakeholdersDropdown.push({
          id: stakeholder.id,
          name: stakeholder.company.name,
          iconPath: this.stakeholderService.getStakeholderIconFullPath(stakeholder.stakeholderType),
          hoverText: null
        });
      });
      this.tryToSetStakeholderOrganizer();
    }
  }

  @Input() addRepatriationDisabled = false;

  private _stakeholders: CaseStakeholder[];
  stakeholdersDropdown: DropdownWitIconDictionary[];
  private _caseId: string;
  medicalAssessments: MedicalAssessment[] = [];
  awaitingMedicalAssessment: MedicalAssessment;
  medicalAssessment: MedicalAssessment;
  repatriationForms: DropdownDictionary[];
  extraSpaceList: SelectItem<ExtraSpaceEnum>[];
  isIntensiveRepatriation = false;

  @Output() intensivePatient = new EventEmitter<boolean>();

  phoneToAirport: PhoneNumber = new PhoneNumber();
  phoneFromAirport: PhoneNumber = new PhoneNumber();

  constructor(
    private medicalService: MedicalService,
    private translateService: TranslateService,
    private stakeholderService: StakeholderService,
    private serviceOrderDetailsHelper: ServiceOrderDetailsHelper
  ) {
    this.initializeData();
  }

  initializeData() {
    this.repatriationForms = this.getDropdownListFromEnum(RepatriationFormEnum, 'medical-assessment-repatriation-form-');
  }

  setIntensivePatient() {
    this.isIntensiveRepatriation = true;
    this.intensivePatient.emit(this.isIntensiveRepatriation);
  }

  setSelectedStakeholderToAirport(event: any) {
    let foundPhoneNumber = this.stakeholderService.getPhoneNumber(
      PhoneNumberType.Fixed,
      this.stakeholders.find(a => a.id === event.id).phoneNumbers
    );
    if (this.isValidPhoneNumber(foundPhoneNumber)) {
      this.subRepatriationFormMedical.isPhoneNumberToAirportOrganizer = true;
      this.phoneToAirport = foundPhoneNumber;
    } else {
      this.subRepatriationFormMedical.isPhoneNumberToAirportOrganizer = false;
      this.phoneToAirport = new PhoneNumber();
    }
  }

  setSelectedStakeholderFromAirport(event: any) {
    let foundPhoneNumber = this.stakeholderService.getPhoneNumber(
      PhoneNumberType.Fixed,
      this.stakeholders.find(a => a.id === event.id).phoneNumbers
    );
    if (this.isValidPhoneNumber(foundPhoneNumber)) {
      this.subRepatriationFormMedical.isPhoneNumberFromAirportOrganizer = true;
      this.phoneFromAirport = foundPhoneNumber;
    } else {
      this.subRepatriationFormMedical.isPhoneNumberFromAirportOrganizer = false;
      this.phoneFromAirport = new PhoneNumber();
    }
  }
  private getDropdownListFromEnum(enumItem: any, prefix: string) {
    return Object.keys(enumItem).map(
      key => new DropdownDictionary(enumItem[key], this.translateService.instant(prefix + key.toString().toLowerCase().replace(/_/g, '-')))
    );
  }

  private isValidPhoneNumber(phoneNumber: PhoneNumber): boolean {
    return !!phoneNumber && !!phoneNumber.countryCode && !!phoneNumber.prefix && !!phoneNumber.number;
  }

  tryToSetStakeholderOrganizer() {
    if (this.stakeholders != null && this.subRepatriationFormMedical != null) {
      if (this.subRepatriationFormMedical.fromAirportOrganizerStakeholderId != null) {
        this.setSelectedStakeholderFromAirport({ id: this.subRepatriationFormMedical.fromAirportOrganizerStakeholderId });
      }
      if (this.subRepatriationFormMedical.toAirportOrganizerStakeholderId != null) {
        this.setSelectedStakeholderToAirport({ id: this.subRepatriationFormMedical.toAirportOrganizerStakeholderId });
      }
    }
  }

  tryToGetRepatriation() {
    if (this.caseId != null && this.subRepatriationFormMedical != null && this.awaitingMedicalAssessment == null) {
      this.medicalService.getMedicalAssessments(+this.caseId).subscribe(
        result => {
          this.medicalAssessments = result.sort((a, b) => b.id - a.id);
          for (let medicalAssessment of this.medicalAssessments) {
            if (
              medicalAssessment.repatriationInstructionsBase != null &&
              medicalAssessment.repatriationInstructionsBase.repatriationForm != null &&
              medicalAssessment.repatriationInstructionsBase.repatriationInstructionId != null
            ) {
              if(medicalAssessment.id == this.medicalAssessments[0].id && medicalAssessment.repatriationInstructionsBase.repatriationForm.form == 'INTENSIVE_CARE_PATIENT'){
                this.setIntensivePatient();
              }
              if (this.awaitingMedicalAssessment == null) {
                this.awaitingMedicalAssessment = medicalAssessment;
              }
              if (
                medicalAssessment.repatriationInstructionsBase.repatriationInstructionId ===
                this.subRepatriationFormMedical.repatriationInstructionId
              ) {
                this.setRepatriation(medicalAssessment);
                break;
              }
            }
          }
        }
      );
    }
  }

  setLatestRepatriation() {
    const currentRepatriationForm = this.serviceOrderDetailsHelper.getRepatriationFormRequested(this.medicalAssessment);
    const awaitingRepatriationForm = this.serviceOrderDetailsHelper.getRepatriationFormRequested(this.awaitingMedicalAssessment);
    if ( currentRepatriationForm !== awaitingRepatriationForm ) {
      this.subRepatriationDetails.repatriationForm = "";
    }

    this.setRepatriation(this.awaitingMedicalAssessment);
    this.subRepatriationFormMedical.fromAirportOrganizerStakeholderId = null;
    this.subRepatriationFormMedical.toAirportOrganizerStakeholderId = null;
    this.subRepatriationFormMedical.isPhoneNumberFromAirportOrganizer = false;
    this.subRepatriationFormMedical.isPhoneNumberToAirportOrganizer = false;
    this.phoneToAirport = new PhoneNumber();
    this.phoneFromAirport = new PhoneNumber();
  }

  setRepatriation(medicalAssessment: MedicalAssessment) {
    this.medicalAssessment = medicalAssessment;
    this.extraSpaceList = this.getMultiSelectItemFromEnum(
      ExtraSpaceEnum,
      this.medicalAssessment.repatriationInstructionsBase.repatriationForm.extraSpaces,
      'repatriation-extra-space-type-'
    );
    this.subRepatriationFormMedical.repatriationInstructionId = medicalAssessment.repatriationInstructionsBase.repatriationInstructionId;
    this.subRepatriationFormMedical.medicalAssessmentId = medicalAssessment.id;
    this.subRepatriationFormMedical.isFromAirportOrganizerVisible = this.isTransportFromAirportAmbulanceOrLiftvan;
    this.subRepatriationFormMedical.isToAirportOrganizerVisible = this.isTransportToAirportAmbulanceOrLiftvan;
  }

  private getMultiSelectItemFromEnum(enumItem: any, selectedPath: any[], prefix: string) {
    return Object.keys(enumItem).map(key => {
      return new SelectItem({
        label: this.translateService.instant(prefix + key.toString().toLowerCase().replace(/_/g, '-')),
        value: enumItem[key],
        selected: !!selectedPath.find(saved => saved.type === key || saved.item === key),
      });
    });
  }
  get isThereRepatriationInstruction() {
    return (
      this.awaitingMedicalAssessment != null &&
      this.awaitingMedicalAssessment.repatriationInstructionsBase != null &&
      this.awaitingMedicalAssessment.repatriationInstructionsBase.repatriationForm != null
    );
  }

  get isTransportFromAirportAmbulanceOrLiftvan(): boolean {
    return this.medicalAssessment?.repatriationInstructionsBase?.repatriationForm?.transportFromAirport === TransitEnum.AMBULANCE ||
           this.medicalAssessment?.repatriationInstructionsBase?.repatriationForm?.transportFromAirport === TransitEnum.LIFT_VAN;
  }

  get isTransportToAirportAmbulanceOrLiftvan(): boolean {
    return this.medicalAssessment?.repatriationInstructionsBase?.repatriationForm?.transportToAirport === TransitEnum.AMBULANCE ||
           this.medicalAssessment?.repatriationInstructionsBase?.repatriationForm?.transportToAirport === TransitEnum.LIFT_VAN;
  }

  get RepatriationFormEnum() {
    return RepatriationFormEnum;
  }

  get TransitEnum() {
    return TransitEnum;
  }

  get isOtherSelected(): boolean {
    if (this.extraSpaceList == null) {
      return false;
    }
    return this.extraSpaceList.some(a => a.value === ExtraSpaceEnum.OTHER && a.selected);
  }

  get otherExtraSpaceText(): string {
    if (this.isOtherSelected) {
      return this.medicalAssessment.repatriationInstructionsBase.repatriationForm.extraSpaces.find(a => a.type === ExtraSpaceEnum.OTHER)
        .otherItemDescription;
    } else {
      return null;
    }
  }
}
