import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
import { Location } from './location';
import { MeansOfTransportationEnum, AccommodationEnum } from '@secca/shared/models/enums';
import * as moment from 'moment';
import { DaySerializer } from './day-serializer';

export class PlannedTravel {
  id: string;
  seccaCaseId: string;
  destinationLocation: Location;
  meansOfTransportation: MeansOfTransportationEnum;
  accommodation: AccommodationEnum;
  departureDate: moment.Moment;
  arrivalDate: moment.Moment;
  coTravellers: string;
  departureAndArrival: moment.Moment[];
  purposesOfTravel: string[];
  durationDays: string;
  durationWeeks: string;
  travelAgency: string;
  expatriationLocation: Location;
  expatriationDepartureDate: moment.Moment;
  expatriationArrivalDate: moment.Moment;
  expatriationDepartureAndArrival: moment.Moment[];
  parentId: number;

  public constructor(init?: Partial<PlannedTravel>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class PlannedTravelAdapter implements Adapter<PlannedTravel> {
  adapt(item: any): PlannedTravel {
    var itemDestinationLocation = null;
    if (item.destinationLocation == null) {
      itemDestinationLocation = new Location();
    } else {
      itemDestinationLocation = new Location({
        id: item.destinationLocation.id,
        freeText: item.destinationLocation.freeText,
        streetNumber: item.destinationLocation.streetNumber,
        streetName: item.destinationLocation.streetName,
        locality: item.destinationLocation.locality,
        administrativeAreaLevel1: item.destinationLocation.administrativeAreaLevel1,
        administrativeAreaLevel2: item.destinationLocation.administrativeAreaLevel2,
        country: item.destinationLocation.country,
        postalCode: item.destinationLocation.postalCode,
        latitude: item.destinationLocation.latitude,
        longitude: item.destinationLocation.longitude
      });
    }

    var itemExpatriationLocation = null;
    if (item.expatriationLocation == null) {
      itemExpatriationLocation = new Location();
    } else {
      itemExpatriationLocation = new Location({
        id: item.expatriationLocation.id,
        freeText: item.expatriationLocation.freeText,
        streetNumber: item.expatriationLocation.streetNumber,
        streetName: item.expatriationLocation.streetName,
        locality: item.expatriationLocation.locality,
        administrativeAreaLevel1: item.expatriationLocation.administrativeAreaLevel1,
        administrativeAreaLevel2: item.expatriationLocation.administrativeAreaLevel2,
        country: item.expatriationLocation.country,
        postalCode: item.expatriationLocation.postalCode,
        latitude: item.expatriationLocation.latitude,
        longitude: item.expatriationLocation.longitude
      });
    }


    const departureDate = DaySerializer.deserialize(item.departureDate);
    const arrivalDate = DaySerializer.deserialize(item.arrivalDate);
    const expatriationDepartureDate = DaySerializer.deserialize(item.expatriationDepartureDate);
    const expatriationArrivalDate = DaySerializer.deserialize(item.expatriationArrivalDate);

    return new PlannedTravel({
      id: item.id,
      seccaCaseId: item.seccaCaseId,
      destinationLocation: itemDestinationLocation,
      departureDate,
      arrivalDate,
      departureAndArrival: [departureDate, arrivalDate],
      meansOfTransportation: item.meansOfTransportation,
      accommodation: item.accommodation,
      coTravellers:  item.coTravellers,
      durationDays: item.durationDays,
      durationWeeks: item.durationWeeks,
      purposesOfTravel: item.purposesOfTravel ? item.purposesOfTravel : [],
      travelAgency: item.travelAgency,
      expatriationLocation: itemExpatriationLocation,
      expatriationDepartureDate,
      expatriationArrivalDate,
      expatriationDepartureAndArrival: [expatriationDepartureDate, expatriationArrivalDate],
      parentId: item.parentId
    });
  }
}
