import { Injectable } from '@angular/core';
import { DocListItem, PartnerItem } from '@secca/shared/models/SupplierSearchResponse';
import { CaseService } from '@secca/core/services/case.service';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  constructor(private caseService: CaseService) { }

  public setAssistantPartnerOnSupplier(suppliers: DocListItem[], caseId: number): void {
    this.caseService.getCase('' + caseId).subscribe((caseData) => {
      const caseValidatedDate = !caseData.validatedOn ? new Date() : caseData.validatedOn;
      suppliers.forEach(supplier => {
        if (supplier.partner_list) {
          supplier.partnerSupplier = this.findActivePartnerOnDate(supplier.partner_list, caseValidatedDate);
        }
      });
    });
  }

  findActivePartnerOnDate(partners: PartnerItem[], validateDate: Date): PartnerItem | undefined {
    const stripTime = (date: Date): Date => {
      const strippedDate = new Date(date);
      strippedDate.setHours(0, 0, 0, 0);
      return strippedDate;
    };

    const strippedValidatedDate: Date = stripTime(validateDate);

    return partners
      .filter(partner => partner.partner_startDate) // Remove partners without start date
      .filter(partner => {
        const startDate = stripTime(new Date(partner.partner_startDate));
        const endDate = partner.partner_endDate ? stripTime(new Date(partner.partner_endDate)) : null;
        return startDate <= strippedValidatedDate && (!endDate || endDate >= strippedValidatedDate);
      })
      .sort((a, b) => {
        // sorts the partners by start date in descending order
        const startDateA: Date = stripTime(new Date(a.partner_startDate));
        const startDateB: Date = stripTime(new Date(b.partner_startDate));
        return startDateB.getTime() - startDateA.getTime();
      })[0]; //returns the first partner in the sorted list
  }

}
