import { ScreeningStates } from './../enums/screening-states';
import { Injectable } from '@angular/core';
import { Adapter } from './../interfaces/adapter';
import { CaseAddressState } from '@secca/shared/enums/case-address-states';

export class CaseScreening {
    id: string;
    validInsuranceStates: ScreeningStates;
    validInsuranceSetByCaseHandler: boolean;
    insurancePeriodState: ScreeningStates;
    insurancePeriodSetByCaseHandler: boolean;
    personGroupIncludesState: ScreeningStates;
    personGroupIncludesSetByCaseHandler: boolean;
    geographicalAreaState: ScreeningStates;
    geographicalAreaSetByCaseHandler: boolean;
    coveragePeriodState: ScreeningStates;
    coveragePeriodSetByCaseHandler: boolean;
    purposesOfTravelState: ScreeningStates;
    purposesOfTravelSetByCaseHandler: boolean;
    incidentCoveredState: ScreeningStates;
    incidentCoveredSetByCaseHandler: boolean;
    caseAddressState: CaseAddressState;

  public constructor(init?: Partial<CaseScreening>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class CaseScreeningAdapter implements Adapter<CaseScreening> {
  adapt(item: any): CaseScreening {
    return new CaseScreening({
        id: item.id,
        validInsuranceStates: item.validInsuranceStates,
        validInsuranceSetByCaseHandler: item.validInsuranceSetByCaseHandler,
        insurancePeriodState: item.insurancePeriodState,
        insurancePeriodSetByCaseHandler: item.insurancePeriodSetByCaseHandler,
        personGroupIncludesState: item.personGroupIncludesState,
        personGroupIncludesSetByCaseHandler: item.personGroupIncludesSetByCaseHandler,
        geographicalAreaState: item.geographicalAreaState,
        geographicalAreaSetByCaseHandler: item.geographicalAreaSetByCaseHandler,
        coveragePeriodState: item.coveragePeriodState,
        coveragePeriodSetByCaseHandler: item.coveragePeriodSetByCaseHandler,
        purposesOfTravelState: item.purposesOfTravelState,
        purposesOfTravelSetByCaseHandler: item.purposesOfTravelSetByCaseHandler,
        incidentCoveredState: item.incidentCoveredState,
        incidentCoveredSetByCaseHandler: item.incidentCoveredSetByCaseHandler,
        caseAddressState: item.caseAddressState
    });
  }
}
