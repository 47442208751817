import { Injectable } from '@angular/core';
import { Adapter } from './../interfaces/adapter';

export class TeamCaring {
  firstName: string;
  surname: string;
  initials: string;
  photoUrl: string;
  loggedIn: boolean;
  shortcut: string;
  active: boolean;
  public constructor(init?: Partial<TeamCaring>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class TeamCaringAdapter implements Adapter<TeamCaring> {
  adapt(item: any): TeamCaring {
    return new TeamCaring({
      firstName: item.firstName,
      surname: item.surname,
      initials: item.initials,
      photoUrl: item.photoUrl,
      loggedIn: item.loggedIn,
      shortcut: item.shortcut,
      active: item.active
    });
  }
}
