import { DropDownCustomerProfileComponent } from '@secca/shared/components/drop-down-customer-profile/drop-down-customer-profile.component';
import { ConsentStatusEnum, ValidationTypeEnum } from '@secca/shared/models/enums';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CustomerProfileCodes, CustomerProfile } from '@secca/shared/models/customerProfile';
import { ServiceOrderService } from '@secca/core/services/service-order.service';
import { PersonInsurance } from '@secca/shared/models/person-insurance';
import { CaseService } from '@secca/core/services/case.service';
import { Subscription } from 'rxjs';
import { ProfileChangeService } from '@secca/case/components/case-basics/case-basics-insurance/profile-change-service';
import { DigitalConsent } from '@secca/shared/models/digital-consent';
import { TranslateService } from '@ngx-translate/core';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { DialogHelperUtilService, DialogStateEvent } from '@secca/core/services/dialog-helper-util.service';
import { MinimizableDialogType } from '@secca/shared/enums/minimizable-dialog-type-enum';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { CaseTypesService } from '@secca/core/services/case-type.service';
import { CaseTypeCodes } from '@secca/shared/models/case-type';

@Component({
  selector: 'app-case-basics-insurance-profile',
  templateUrl: './case-basics-insurance-profile.component.html',
  styleUrls: ['./case-basics-insurance-profile.component.scss']
})
@AutoUnsubscribe
export class CaseBasicsInsuranceProfileComponent implements OnChanges {
  @ViewChild(DropDownCustomerProfileComponent) customerProfileDropDownComponent: DropDownCustomerProfileComponent;

  @Output() profileUpdate = new EventEmitter();
  @Output() open = new EventEmitter<void>();
  @Input() set customerProfileId(value: string) {
    this.existingProfileId = value;
    this._customerProfileId = value;
    this.groupProfile = value;
    if (!!this.groupProfile) {
      this.caseService.getCustomerProfile(this._customerProfileId).subscribe(result => {
          if (!!result.groupProfile) {
            this.groupProfile = result.groupProfile;
          } else {
            this.groupProfile = result.profileId;
          }
          this.setHeadline(result.isGroupProfile || !!result.groupProfile);
        }
      );
    } else {
      this.setHeadline(false);
    }
  }
  existingProfileId: string;
  _customerProfileId: string = null;
  groupProfile: string = null;
  public lastAcknowledged: Date | null;
  public lastAcknowledgedBy: string;
  private $caseSubscr: Subscription;

  _caseId: string;
  @Input() set caseId(value: string) {
    this._caseId = value;
    this.resetCustomerProfile();
    this.getDigitalConsentStatus();
    this.disableCustomerProfileIfNecessary();
    this.isMedicalPreAssessmentDialogOpen();
    this.$caseSubscr = this.caseStateService.getCase().subscribe(
        seccaCase => { if (this._caseId == seccaCase.id) {
                        this.lastAcknowledged = seccaCase.lastAcknowledged;
                        this.lastAcknowledgedBy = seccaCase.lastAcknowledgedBy;
                       }
    });
    if (this.customerProfileUpdateSubscription) {
      this.customerProfileUpdateSubscription.unsubscribe();
    }
    this.customerProfileUpdateSubscription = this.profileChangeService.observe(value)
      .subscribe(profileId => {
        this._customerProfileId = profileId;
        if (profileId) {
          this.caseService.getCustomerProfile(profileId).subscribe(
            result => {
              if (!!result.groupProfile) {
                this.groupProfile = result.groupProfile;
              } else {
                this.groupProfile = result.profileId;
              }
            }
          );
        }
      });
  }
  get caseId() {
    return this._caseId;
  }

  @Input() set personInsurance(value: PersonInsurance) {
    this.caseTypeService.getCaseTypeByCaseId(value.seccaCaseId).subscribe(caseType => {
      this.isInternalCoordinationCase = CaseTypeCodes.INTERNAL_COORDINATION === caseType.code || CaseTypeCodes.MAJOR_INCIDENT === caseType.code;
      this.initializeCustomerProfiles();
    });
    this.lookupPolicy = !!value.insuranceLookupPolicy;
  }

  @Input() consent: DigitalConsent;
  @Input() validationTypeEnum: ValidationTypeEnum = ValidationTypeEnum.required;
  @Input() useAsSearchParameter = false;

  public serviceOrdersExist: boolean;
  public lookupPolicy: boolean;
  public consentHappened: boolean;
  public hasMedicalPreAssessmentDialogOpen: boolean;
  public customerProfiles: CustomerProfile[];
  private customerProfileUpdateSubscription: Subscription;
  private dialogOpenedClosedEventSubscr: Subscription;
  public isInternalCoordinationCase = false;

  headline = '';

  constructor(
    private serviceOrderService: ServiceOrderService,
    private caseService: CaseService,
    private caseStateService: CaseStateService,
    private profileChangeService: ProfileChangeService,
    private translate: TranslateService,
    private dialogHelperUtilService: DialogHelperUtilService,
    private caseTypeService: CaseTypesService
  ) {
    this.dialogOpenedClosedEventSubscr = this.dialogHelperUtilService.dialogStateEvent.subscribe((evnt:DialogStateEvent) => {
        this.isMedicalPreAssessmentDialogOpen();
        this.disableCustomerProfileIfNecessary();
    });

    this.initializeCustomerProfiles();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getDigitalConsentStatus();
  }

  onOpen() {
    this.open.next();
  }

  close() {
    this.customerProfileDropDownComponent?.close();
  }

  resetCustomerProfile() {
    this._customerProfileId = null;
    this.initializeCustomerProfiles();
  }

  private initializeCustomerProfiles() {
    let profilesByCase;
    if (this._caseId) {
      profilesByCase = this.caseService.getCustomerProfilesByCase(this._caseId);
    } else {
      profilesByCase = this.caseService.getCustomerProfiles();
    }
    profilesByCase.subscribe(result => {
      this.customerProfiles =
        result.sort(
          (a, b) => ((a.profileName.replace('(', 'Ü') > b.profileName.replace('(', 'Ü')) ? 1 : -1));
      if (!this.isInternalCoordinationCase) {
        this.customerProfiles = this.customerProfiles.filter(profile =>
          profile.profileCode !== CustomerProfileCodes.SOS_TRAVELCARE_DK_CUSTOMER_PROFILE_CODE &&
          profile.profileCode !== CustomerProfileCodes.SOS_TRAVELCARE_SE_CUSTOMER_PROFILE_CODE &&
          profile.profileCode !== CustomerProfileCodes.SOS_TRAVELCARE_NO_CUSTOMER_PROFILE_CODE &&
          profile.profileCode !== CustomerProfileCodes.SOS_TRAVELCARE_FI_CUSTOMER_PROFILE_CODE);
      } else {
        this.customerProfiles = this.customerProfiles.filter(profile =>
          profile.profileCode === CustomerProfileCodes.SOS_TRAVELCARE_DK_CUSTOMER_PROFILE_CODE ||
          profile.profileCode === CustomerProfileCodes.SOS_TRAVELCARE_SE_CUSTOMER_PROFILE_CODE ||
          profile.profileCode === CustomerProfileCodes.SOS_TRAVELCARE_NO_CUSTOMER_PROFILE_CODE ||
          profile.profileCode === CustomerProfileCodes.SOS_TRAVELCARE_FI_CUSTOMER_PROFILE_CODE);
      }
      if (!this.useAsSearchParameter && !this.isInternalCoordinationCase) {
        this.customerProfiles.push(new CustomerProfile({profileName: 'Blank'}));
      }
      if (this._customerProfileId === null && this.useAsSearchParameter) {
        this.customerProfiles.splice(0, 0, new CustomerProfile({profileName: 'All', profileId: ''}));
        this._customerProfileId = '';
        this.groupProfile = '';
      }
    });
  }

  private getDigitalConsentStatus() {
      if (this.consent && this.consent.consentType !== ConsentStatusEnum.UNKNOWN &&
        this.consent.consentType !== ConsentStatusEnum.CONSENT_ACCEPTED_WEB && this.consent.consentType !== null){
        this.consentHappened = true;
      }
  }

  public updateInsuranceCustomerProfile() {
    if (this.groupProfile !== this.existingProfileId) {
      this.existingProfileId = this.groupProfile;
      if (this.groupProfile) {
        this.caseService.getCustomerProfile(this.groupProfile).subscribe(
          result => {
            if (!!result.groupProfile) {
              this.groupProfile = result.groupProfile;
            } else {
              this.groupProfile = result.profileId;
            }
            this.setHeadline(result.isGroupProfile || !!result.groupProfile);
            this.profileUpdate.emit(this.groupProfile);
          }
        );
      } else {
        this.setHeadline(false);
        this.profileUpdate.emit(this.groupProfile);
      }
    } else if (this._customerProfileId !== this.existingProfileId) {
      this.existingProfileId = this._customerProfileId;
      this.profileUpdate.emit(this._customerProfileId);
    }
  }

  private setHeadline(isGroupProfile: boolean) {
    if (isGroupProfile) {
      this.headline = this.translate.instant('case-basics-insurance-customer-group-profile');
    } else {
      this.headline = this.translate.instant('case-basics-insurance-customer-profile');
    }
  }

  private disableCustomerProfileIfNecessary() {
    this.serviceOrderService.findServiceOrderCountOnCase(+this._caseId).subscribe(result => {
      this.serviceOrdersExist = result > 0;
    });
  }

  private isMedicalPreAssessmentDialogOpen() {
    if ( !this.serviceOrdersExist ) {
      const dialogRef : any = this.dialogHelperUtilService.getOpenDialogWithCaseIdAndType(this.caseId, MinimizableDialogType.MedicalPreAssessment);
      this.hasMedicalPreAssessmentDialogOpen = !!dialogRef;
    }
  }

  get ValidationTypeEnum() {
    return ValidationTypeEnum;
  }
}
