import { ICCStakeholder } from '@secca/case/components/case-plans/case-plans/add-service-order/models/icc-stakeholder';
import { StatusTypes } from '@secca/shared/models/enums';

export class ICCStakeholders {
  stakeholders: ICCStakeholder[] = [];

  constructor(stakeholders?: ICCStakeholder[]) {
    this.stakeholders = !!stakeholders ? stakeholders : [];
  }

  push(stakeHolder: ICCStakeholder) {
    this.stakeholders.push(stakeHolder);
  }

  delete(stakeHolder: ICCStakeholder) {
    const index = this.stakeholders.findIndex(d => d === stakeHolder);
    this.stakeholders.splice(index, 1);
  }

  changeStatusOnServiceOrders(caseId: string) {
    this.stakeholders.forEach(stakeholder => {
      if(stakeholder.caseId.toString() === caseId.toString()) {
        stakeholder.serviceOrderStatus = stakeholder.serviceOrderStatus === StatusTypes.COMMITTED || stakeholder.serviceOrderStatus === null ? StatusTypes.CANCELLED : StatusTypes.COMMITTED;
      }
    });
  }

  byCase(caseId: string) {
    return this.stakeholders.filter(stakeholder => stakeholder.caseId == caseId);
  }

  byServiceOrder(serviceOrderId: string) {
    return this.stakeholders.filter(stakeholder => stakeholder.serviceOrderId == serviceOrderId);
  }

  isFilledForCase(caseId: string) {    
    return this.stakeholders.filter(function(stakeHolder: ICCStakeholder){
      return stakeHolder.caseId == caseId && stakeHolder.isFilled();
    }).length > 0;
  }

  isValid() {
    let empty = this.stakeholders.length == 0 ? true : false;
    if (empty) {
      return false;
    } else {
      let allValid = true;
      for (let i = 0; i< this.stakeholders.length; i++){
        const stakeholder = this.stakeholders[i];
        if(!stakeholder.stakeholderId || !stakeholder.serviceOrderId) {
          allValid = false;
         break;
        }
      }
      return allValid;
    }
  }

  allreadyChosen(caseId: string,stakeholderId: string ) :boolean {
    const stakeholdersByCase = this.byCase(caseId);
    return stakeholdersByCase.slice(0, stakeholdersByCase.length-1)
    .some(a => a.stakeholderId === stakeholderId)
  }

  hasEndUserServiceOrder(serviceOrderId: string) {
    return this.stakeholders.filter((iccStakeHolder) => ""+iccStakeHolder.serviceOrderId == serviceOrderId).length > 0;
  }
}
