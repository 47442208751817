import { Injectable } from '@angular/core';
import { LegacyDialogPosition as DialogPosition, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { NavigationStart, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ModalDialogComponent } from '@secca/shared/components/modal-dialog/modal-dialog.component';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { LockContextEnum } from '@secca/shared/enums/lock-context.enum';
import { MinimizableDialogType } from '@secca/shared/enums/minimizable-dialog-type-enum';
import { AdditionalInfo } from '@secca/shared/interfaces/additional-info';
import { ModalDialogConfiguration } from '@secca/shared/models/modal/modal-dialog-configuration';
import { Subscription } from 'rxjs';
import { DialogStateService } from '../state-services/dialog-state.service';
import { CaseLockService } from './case-lock.service';
import { DialogFocusService } from './dialog-focus.service';
import { DialogSequenceOrderService } from './dialog-sequence-order.service';
import { TopTabsService } from './top-tabs.service';
import { SettingsService } from '@secca/core/services/settings.service';
import { Subject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

export enum DIALOG_STATE {
  OPENED,
  CLOSED
}

export class DialogStateEvent {
  dialogId: string;
  state: DIALOG_STATE;

  public constructor(init?: Partial<DialogStateEvent>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
@AutoUnsubscribe
export class DialogHelperUtilService {
  readonly MAX_OPEN_DIALOGS = 8;
  readonly MODAL_NAME = 'therecanbeonlyone';

  readonly defaultProps: any = {
    disableClose: true,
    autoFocus: 'first-tabbable',
    hasBackdrop: false,
    closeOnNavigation: false,
  };

  readonly modalProps = {
    id: this.MODAL_NAME,
    disableClose: true,
    autoFocus: 'first-tabbable',
    hasBackdrop: true,
    closeOnNavigation: true
  };

  private isNavigating = false;
  private $routerEventsSubscr: Subscription;

  private _dialogStateEvent: Subject<DialogStateEvent> = new Subject();

  constructor(private matDialog: MatDialog,
              private dialogStateService: DialogStateService,
              private modalService: NgbModal,
              private translateService: TranslateService,
              private topTabsService: TopTabsService,
              private caseLockService: CaseLockService,
              private dialogSequenceOrderService: DialogSequenceOrderService,
              private dialogFocusService: DialogFocusService,
              private settingsService: SettingsService,
              private router: Router) {
    this.$routerEventsSubscr = this.router.events.subscribe(event => this.isNavigating = event instanceof NavigationStart);
  }

  public open(component: any, config?: MatDialogConfig, emitStateEvent?: boolean): MatDialogRef<any> {
    if (this.settingsService.isZoomed()) {
      if (!config || !['SearchCase', 'OpenRecentCases', 'KeyboardShortcuts'].includes(config.id)) {
        const topMarginValue = 15 + (40 * this.matDialog.openDialogs.length);
        const rightMarginValue = 15 + (40 * this.matDialog.openDialogs.length);
        this.defaultProps.position = {
          top: `${topMarginValue}px`,
          right: `${rightMarginValue}px`
        };
      } else {
        delete this.defaultProps.position;
      }
    }

    const dialogConfig = Object.assign(new MatDialogConfig(), this.defaultProps, config);

    if ( dialogConfig.id ) {
      const dialogRef = this.matDialog.getDialogById(dialogConfig.id);
      if ( dialogRef ) {
        this.dialogStateService.maximize(dialogConfig.id);
        return dialogRef;
      }
    }

    if ( this.matDialog.openDialogs.length >= this.MAX_OPEN_DIALOGS && !config?.hasBackdrop) {
        this.showMaxDialogsReachedWarningModal();
        return null;
    }

    const dialogRef: MatDialogRef<any> = this.matDialog.open(component, dialogConfig);

    this.dialogStateService.sendToFront(dialogRef.id);

    if ( emitStateEvent ) {
      dialogRef.afterOpened().pipe(take(1)).subscribe(() =>
        this.dialogStateEvent.next(new DialogStateEvent({dialogId: dialogRef.id, state: DIALOG_STATE.OPENED}))
      );
    }

    return dialogRef;
  }

  public close(dialogRef: MatDialogRef<any>, caseId: number, emitStateEvent?: boolean) {
    this.doClose(dialogRef, emitStateEvent);
    this.checkReleaseCaseLock(dialogRef.id, caseId);
  }

  public openModal(component: any, config?: MatDialogConfig): MatDialogRef<any> {
    const dialogConfig = Object.assign(new MatDialogConfig(), this.modalProps, config);

    if ( dialogConfig.id ) {
      const dialogRef: MatDialogRef<any> = this.matDialog.getDialogById(dialogConfig.id);
      if ( dialogRef ) {
        return dialogRef;
      }
    }

    const dialogRef = this.matDialog.open(component, dialogConfig);

    this.dialogStateService.sendToFront(dialogRef.id);

    return dialogRef;
  }

  public closeModal() {
    const dialogRef: MatDialogRef<any> = this.matDialog.getDialogById(this.MODAL_NAME);
    this.doClose(dialogRef);
  }

  public getOpenDialogWithCaseIdAndType(caseId: string, type: MinimizableDialogType): MatDialogRef<any> {
    const dialogs: MatDialogRef<any>[] = this.matDialog.openDialogs;

    const dialogRef: MatDialogRef<any> =  dialogs.find(ref => {
      const component = ref.componentInstance;
      const additionalInfo: AdditionalInfo = this.isFunction(component.getAdditionalInfo) && component.getAdditionalInfo();
      const minimizeType: MinimizableDialogType = this.isFunction(component.getMinimizeType) && component.getMinimizeType();
      return minimizeType === type && +additionalInfo?.caseId === +caseId;
    });

    return dialogRef;
  }

  public getOpenDialogByAdditionaInfoAndType(func: (additionalInfo) => boolean, type: MinimizableDialogType): MatDialogRef<any> {
    const dialogs: MatDialogRef<any>[] = this.matDialog.openDialogs;

    const dialogRef: MatDialogRef<any> =  dialogs.find(ref => {
      const component = ref.componentInstance;
      const additionalInfo: AdditionalInfo = this.isFunction(component.getAdditionalInfo) && component.getAdditionalInfo();
      const minimizeType: MinimizableDialogType = this.isFunction(component.getMinimizeType) && component.getMinimizeType();
      return minimizeType === type && func(additionalInfo);
    });

    return dialogRef;
  }

  get dialogStateEvent() {
    return this._dialogStateEvent;
  }

  private doClose(dialogRef: MatDialogRef<any>, emitStateEvent?: boolean): void {
    if ( dialogRef ) {
      if ( emitStateEvent ) {
        dialogRef.afterClosed().pipe(take(1)).subscribe(() =>
          this.dialogStateEvent.next(new DialogStateEvent({dialogId: dialogRef.id, state: DIALOG_STATE.CLOSED}))
        );
      }
      dialogRef.close();
      this.sendNextOpenModelToFront(dialogRef.id);
    }
  }

  private sendNextOpenModelToFront(dialogId: string): void {
    dialogId = this.dialogSequenceOrderService.remove(dialogId);

    if ( dialogId ) {
      const dialogRef: MatDialogRef<any> = this.matDialog.getDialogById(dialogId);
      if ( dialogRef ) {
        this.dialogStateService.sendToFront(dialogRef.id);
      }
    }
  }

  private checkReleaseCaseLock(dialogId: string, caseId: number): void {
    const isBoardActive = this.router.url === '/board'
    if ( isBoardActive && caseId && !this.isNavigating) {
      const hasOpenCaseTab = this.topTabsService.tabWithCaseIdExists(caseId);
      const hasOpenCaseDialogs = this.hasOpenDialogWithCaseId(caseId, dialogId, [MinimizableDialogType.SupplierInvoice]);

      if ( !hasOpenCaseTab && !hasOpenCaseDialogs ) {
        this.caseLockService.tryReleaseCaseLock(''+caseId, LockContextEnum.CASE_BASIC).subscribe();
      }
    }
  }

  private hasOpenDialogWithCaseId(caseId: number, excludeId?: string, excludeTypes: MinimizableDialogType[] = []): boolean {
    const dialogs: MatDialogRef<any>[] = this.matDialog.openDialogs;

    const dialogRef: MatDialogRef<any> =  dialogs.filter(ref => ref.id !== excludeId).find(ref => {
      const component = ref.componentInstance;
      const additionalInfo: AdditionalInfo = this.isFunction(component.getAdditionalInfo) && component.getAdditionalInfo();
      const minimizeType: MinimizableDialogType = this.isFunction(component.getMinimizeType) && component.getMinimizeType();
      return +additionalInfo?.caseId === +caseId && !excludeTypes.includes(minimizeType);
    });

    return !!dialogRef;
  }

  private isFunction(method: any): boolean {
    return !!method && typeof method === 'function'
  }

  private showMaxDialogsReachedWarningModal() {
    const params = {
      limit: this.MAX_OPEN_DIALOGS
    };

    const modalRef = this.modalService.open(ModalDialogComponent, { backdrop: 'static', windowClass: 'modal-ontop' });
    modalRef.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'default-modal-header',
      title: 'max-open-dialogs-warning-title',
      text: this.translateService.instant('max-open-dialogs-warning-text', params),
      no: 'dismiss-modal',
      isBody: true,
      isFooter: true,
    });
    modalRef.componentInstance.closeModalEvent.subscribe(
      () => {
        modalRef.close();
      }
    );
  }
}
