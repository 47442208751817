import { AttachmentDto } from '@secca/shared/models/attachment-dto';
import { InputValidationService } from '@secca/core/services/input-validation.service';
import { ModalDialogComponent } from '@secca/shared/components/modal-dialog/modal-dialog.component';
import { OutputManagementService } from './../../../services/output-management.service';
import { CaseStakeholder } from 'src/app/shared/models/caseStakeholder';
import { Message } from './../../../models/message';
import { ValidationTypeEnum, MessageChannelType } from './../../../../../../../shared/models/enums';
import { Component, OnInit, Input, ViewChild, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-message-subject',
  templateUrl: './message-subject.component.html',
  styleUrls: ['./message-subject.component.scss'],
})
export class MessageSubjectComponent implements OnInit {
  @ViewChild(ModalDialogComponent) modalDialogComponent: ModalDialogComponent;
  @Input() stakeholders: CaseStakeholder[];
  @Input() attachments: AttachmentDto[];
  @Input() attachmentsFromTemplate: AttachmentDto[];
  @Input() message: Message;
  @Output() messageUpdated: EventEmitter<Message> = new EventEmitter<Message>();

  get ValidationTypeEnum() {
    return ValidationTypeEnum;
  }

  constructor(
    private outputManagementService: OutputManagementService,
    private inputValidationService: InputValidationService) {}

  ngOnInit() {}

  get MessageChannelType() {
    return MessageChannelType;
  }

  private updateMessage() {
    this.messageUpdated.emit(this.message);
  }

 }
