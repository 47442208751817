import { CaseHistoryEntry } from '../../shared/models/caseHistoryEntry';
import { UserDto } from './../../shared/models/userDto';
import { CaseHistoryType, CaseSummaryWarning } from './../../shared/enums/case-history-type';
import { CallType, NoteTypeEnum } from 'src/app/shared/models/enums';
import { Note } from 'src/app/shared/models/note';
import { SettingsService } from './settings.service';
import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { CaseHistoryDataFieldChangeDto } from '@secca/shared/models/CaseHistoryDataFieldChangeDto';
import * as moment from 'moment';
import { Person } from '@secca/shared/models/person';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { Company } from '@secca/shared/models/company';
import { CaseDocument } from '@secca/shared/models/caseDocument';

@Injectable({
  providedIn: 'root',
})
export class CaseSummaryHelperService extends BaseService {
  constructor(private settingsService: SettingsService) {
    super(settingsService);
  }

  get NoteTypeEnum() {
    return NoteTypeEnum;
  }

  get CaseHistoryType() {
    return CaseHistoryType;
  }

  get errorMessage() {
    return CaseSummaryWarning.warning;
  }

  public insertLineBetweenCaseSummaryItems(caseSummaryList: CaseHistoryEntry[], index: number) {
    return index + 1 === caseSummaryList.length ? '' : 'line';
  }

  public updateNote(caseSummaryEntities: any, id: number, note: Note) {
    if (caseSummaryEntities.NOTE !== undefined && caseSummaryEntities.NOTE[id] != null) {
      caseSummaryEntities.NOTE[id].description = note.description;
      caseSummaryEntities.NOTE[id].deletedBy = note.deletedBy;
      if (note.type === NoteTypeEnum.phone) {
        caseSummaryEntities.NOTE[id].callType = note.callType;
        caseSummaryEntities.NOTE[id].stakeholderType = note.stakeholder.stakeholderType;
        if (note.stakeholder.company) {
          caseSummaryEntities.NOTE[id].stakeholderCompanyName = note.stakeholder.company.name;
          caseSummaryEntities.NOTE[id].stakeholderFirstName = null;
          caseSummaryEntities.NOTE[id].stakeholderSurname = null;
        } else {
          caseSummaryEntities.NOTE[id].stakeholderCompanyName = null;
          caseSummaryEntities.NOTE[id].stakeholderFirstName = note.stakeholder.person.firstName;
          caseSummaryEntities.NOTE[id].stakeholderSurname = note.stakeholder.person.surname;
        }
      }
    }
  }

  public getNoteDescriptionSplited(caseSummaryEntities: any, noteId: number) {
    if (caseSummaryEntities.NOTE !== undefined) {
      if (caseSummaryEntities.NOTE[noteId].description == null) {
        return null;
      } else {
        return caseSummaryEntities.NOTE[noteId].description.split('\n');
      }
    } else {
      return this.errorMessage;
    }
  }

  public getNoteDeletedBy(caseSummaryEntities: any, noteId: number) {
    return caseSummaryEntities.NOTE !== undefined ? caseSummaryEntities.NOTE[noteId].deletedBy : this.errorMessage;
  }

  public getEmailDeletedBy(caseSummaryEntities: any, emailId: number) {
    return caseSummaryEntities.EMAIL !== undefined ? caseSummaryEntities.EMAIL[emailId].deletedBy : this.errorMessage;
  }

  public isCopied(caseSummaryEntities: any, noteId: number) {
    return caseSummaryEntities.NOTE !== undefined ? caseSummaryEntities.NOTE[noteId].copiedFromCaseId : this.errorMessage;
  }

  public getNoteStakeholderType(caseSummaryEntities: any, noteId: number) {
    return caseSummaryEntities.NOTE !== undefined ? caseSummaryEntities.NOTE[noteId].stakeholderType : this.errorMessage;
  }

  public getNoteType(caseSummaryEntities: any, noteId: number) {
    return caseSummaryEntities.NOTE !== undefined ? caseSummaryEntities.NOTE[noteId].noteType : '';
  }

  public getMedicalAssessmentDeleted(caseSummaryEntities: any, medicalAssessmentId: number) {
    return caseSummaryEntities.MEDICAL_ASSESSMENT !== undefined ? caseSummaryEntities.MEDICAL_ASSESSMENT[medicalAssessmentId]?.deletedOn : null;
  }

  public getMedicalNoteDeleted(caseSummaryEntities: any, medicalNoteId: number) {
    return caseSummaryEntities.MEDICAL_NOTE !== undefined ? caseSummaryEntities.MEDICAL_NOTE[medicalNoteId]?.deletedOn : null;
  }

  public getMedicalreportDeleted(caseSummaryEntities: any, medicaleportId: number) {
    return caseSummaryEntities.MEDICAL_REPORT_SUPPLIER_PORTAL !== undefined ? caseSummaryEntities.MEDICAL_REPORT_SUPPLIER_PORTAL[medicaleportId]?.deletedOn : null;
  }

  public getMedicalPreAssessmentDeleted(caseSummaryEntities: any, medicalAssessmentId: number) {
    return caseSummaryEntities.MEDICAL_PRE_ASSESSMENT !== undefined ? caseSummaryEntities.MEDICAL_PRE_ASSESSMENT[medicalAssessmentId]?.deletedOn : null;
  }

  public getMedicalPreAssessmentDecision(caseSummaryEntities: any, preAssessmentId: number) {
    return caseSummaryEntities.MEDICAL_PRE_ASSESSMENT !== undefined ? caseSummaryEntities.MEDICAL_PRE_ASSESSMENT[preAssessmentId].decision : '';
  }

  public getDeleteEdits(caseSummaryEntities: any, deleteId: number) {
    return caseSummaryEntities.GDPR_NOTE !== undefined ? (Object.values(caseSummaryEntities.GDPR_NOTE) as CaseHistoryEntry[])
      .filter(edit => edit.entityId === deleteId)
      .sort((a, b) => {
        return moment(b.actionTime).diff(a.actionTime);
      }) : [];
  }

  public getNoteDocuments(caseSummaryEntities: any, noteId: number): CaseDocument[] {
    return caseSummaryEntities.NOTE !== undefined ? caseSummaryEntities.NOTE[noteId].documents : [];
  }

  public getNoteCallType(caseSummaryEntities: any, noteId: number) {
    return caseSummaryEntities.NOTE !== undefined ? caseSummaryEntities.NOTE[noteId].callType : '';
  }

  public getNoteStakeholderName(caseSummaryEntities: any, noteId: number) {
    if (caseSummaryEntities.NOTE !== undefined) {
      const item = caseSummaryEntities.NOTE[noteId];
      if (item.stakeholderCompanyName) {
        return item.stakeholderCompanyName;
      } else {
        return (item.stakeholderFirstName || '') + (item.stakeholderSurname ? ' ' : '') + (item.stakeholderSurname || '');
      }
    } else {
      return this.errorMessage;
    }
  }

  public getPhoneNumber(caseSummaryEntities: any, noteId: number): string {
    if (caseSummaryEntities.NOTE !== undefined) {
      const item = caseSummaryEntities.NOTE[noteId];
      return item.phoneNumber;
    }
  }

  public getPhoneStartTime(caseSummaryEntities: any, noteId: number): moment.Moment {
    if (caseSummaryEntities.NOTE !== undefined) {
      const item = caseSummaryEntities.NOTE[noteId];
      if (item.phoneStartTime) {
        return moment.utc(item.phoneStartTime);
      }
    }
  }

  public getPhoneCallDuration(caseHistory: CaseHistoryEntry, caseSummaryEntities: any, noteId: number): string {
    if (caseSummaryEntities.NOTE !== undefined) {
      const item = caseSummaryEntities.NOTE[noteId];
      if (item.phoneCallDuration) {
        return item.phoneCallDuration;
      }
    }

    if (caseHistory !== undefined) {
      const dataFieldChanges = caseHistory.dataFieldChanges;
      if (dataFieldChanges !== undefined) {
        return dataFieldChanges
          .filter(historyDataField => historyDataField.field === CaseHistoryDataFieldChangeDto.CALL_DURATION_TIME)
          .find(historyDataField => historyDataField.newValue !== undefined).newValue;
      }
    }
  }

  public isEdited(caseSummaryEntities: any, entry: CaseHistoryEntry) {
    // @ts-ignore
    const noteId = entry.entityId;
    const note = caseSummaryEntities.NOTE[noteId];
    if (+note.id === note.originalId) {
      return false;
    }
    return true;
  }

  public getUserImage(userList: UserDto[], userId: string) {
    if (userList.length !== 0 && userId !== null) {
      const user = userList.find(x => userId.localeCompare(x.userId, undefined, { sensitivity: 'accent' }) === 0);
      return user != null ? user.picture : '';
    } else {
      return '';
    }
  }

  public getUserInitials(userList: UserDto[], userId: string) {
    if (userList.length !== 0 && userId !== null) {
      const user = userList.find(x => userId.localeCompare(x.userId, undefined, { sensitivity: 'accent' }) === 0);
      return user != null ? user.initials : '';
    } else {
      return '';
    }
  }

  public showNoteEntity(caseSummaryType: CaseHistoryType) {
    return (
      caseSummaryType === CaseHistoryType.PHONE_NOTE_CREATE ||
      caseSummaryType === CaseHistoryType.CASE_NOTE_CREATE ||
      caseSummaryType === CaseHistoryType.PHONE_NOTE_EDIT ||
      caseSummaryType === CaseHistoryType.CASE_NOTE_EDIT ||
      caseSummaryType === CaseHistoryType.CUSTOMER_NOTE_CREATE ||
      caseSummaryType === CaseHistoryType.SUPPLIER_NOTE_CREATE ||
      caseSummaryType === CaseHistoryType.PHONE_NOTE_COPY ||
      caseSummaryType === CaseHistoryType.CASE_NOTE_COPY);
  }

  public entityToNote(note: any): Note {
    const editedNote = new Note();
    if (note.noteType === NoteTypeEnum.phone) {
      editedNote.stakeholder = new CaseStakeholder({ id: null, stakeholderType: note.stakeholderType });

      if (note.stakeholderCompanyName != null) {
        editedNote.stakeholder.company = new Company({ name: note.stakeholderCompanyName });
      } else {
        editedNote.stakeholder.person = new Person({ firstName: note.stakeholderFirstName, surname: note.stakeholderSurname });
      }

      editedNote.callType = note.callType;
    }
    editedNote.type = note.noteType;
    editedNote.description = note.description;
    editedNote.id = note.id;
    editedNote.originalId = note.originalId;
    editedNote.connectionCallId = note.connectionCallId;
    editedNote.callLength = note.phoneCallDuration;
    editedNote.phoneStartTime = note.phoneStartTime;
    editedNote.callFrom = editedNote.callType === CallType.ingoing ? note.phoneNumber : undefined;
    editedNote.callTo = editedNote.callType === CallType.outgoing ? note.phoneNumber : undefined;
    editedNote.copiedFromCaseId = note.copiedFromCaseId;
    return editedNote;
  }
}
