import * as moment from 'moment';
import { Location } from '@secca/shared/models/location';
import { SubComponentModel } from '@secca/case/components/case-plans/case-plans/add-service-order/models/interfaces';
import { DateSerializer } from '@secca/shared/models/date-serializer';
import { ICCFlightLegRelevantForCase, ICCFlightLegRelevantForCaseAdapter } from './icc-flightLegsRelevantForCase';

export class SubTravelInfo implements SubComponentModel {
  id: number;
  departureDate: moment.Moment;
  departureDisplayDate: moment.Moment;
  arrivalDate: moment.Moment;
  arrivalDisplayDate: moment.Moment;
  departureFrom: Location = new Location();
  arrivalAt: Location = new Location();
  flightNo: string;
  agencyReference: string;
  airlineReference: string;
  ticketNumbers: string;
  cabinClass: string;
  remarks: string;
  roomNumber: string;
  relevantForCaseIds: ICCFlightLegRelevantForCase[] = [];

  public constructor(init?: Partial<SubTravelInfo>) {
    Object.assign(this, init);
  }

  isDepartureDateValid(): boolean {
    return this.departureDate && this.departureDate.isValid();
  }

  isArrivalDateValid(): boolean {
    return this.arrivalDate && this.arrivalDate.isValid();
  }

  isArrivalDatePresentAndValid(): boolean {
    return this.arrivalDate && this.arrivalDate.isValid();
  }

  isDepartureFromValid(): boolean {
    return !!this.departureFrom.freeText;
  }

  isArrivalAtValid(): boolean {
    return !!this.arrivalAt.freeText;
  }

  isValid(skipAgencyReference?: boolean): boolean {
    return (
      this.isDepartureDateValid() && this.isDepartureFromValid() && this.isArrivalDateValid() && this.isArrivalAtValid() && !!this.flightNo && (skipAgencyReference || !!this.agencyReference)
    );
  }

  isValidExpectation() {
    return this.isDepartureDateValid() && this.isDepartureFromValid() && this.isArrivalAtValid();
  }
}

export class SubTravelInfoAdapter {
  iccFlightLegsRelevantForCaseAdapter: ICCFlightLegRelevantForCaseAdapter = new ICCFlightLegRelevantForCaseAdapter();
  adapt(item: any): SubTravelInfo {
    const iccFlightLegsRelevantForCaseAdapter = new ICCFlightLegRelevantForCaseAdapter();
    const relevantForCaseIds = item.relevantForCaseIds?.map(relevantForCase => iccFlightLegsRelevantForCaseAdapter.adapt(relevantForCase));
    return new SubTravelInfo({
      id: item.id,
      departureDate: DateSerializer.deserialize(item.departureDate),
      departureDisplayDate: DateSerializer.deserialize(item.departureDate),
      arrivalDate: DateSerializer.deserialize(item.arrivalDate),
      arrivalDisplayDate: DateSerializer.deserialize(item.arrivalDate),
      departureFrom: item.departureFrom,
      arrivalAt: item.arrivalAt,
      flightNo: item.flightNo,
      agencyReference: item.agencyReference,
      airlineReference: item.airlineReference,
      ticketNumbers: item.ticketNumbers,
      cabinClass: item.cabinClass,
      remarks: item.remarks,
      roomNumber: item.roomNumber,
      relevantForCaseIds: relevantForCaseIds ? relevantForCaseIds : []
    });
  }
}
