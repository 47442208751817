import { Injectable } from '@angular/core';
import { Adapter } from '../../../../../shared/interfaces/adapter';
import * as moment from 'moment';
import { DateSerializer } from '@secca/shared/models/date-serializer';

export class RefundTaskViewModel {
    id: number;
    isSelected: boolean;
    receivedDate: moment.Moment;
    dueDate: moment.Moment;
    remainingDays: number;
    refundDate: moment.Moment;
    receiver: string;
    receiverName: string;
    caseId: number;
    caseNumber: string;
    serviceOrderTypes: string[];
    refundId: number;
    refundAmount: string;
    refundStatus: string;
    refundAwaiting: string;
    assignedTo: string;
    refundCurrency: string;
    customerProfileId: string;
    incidentEventId: string;
    incidentEventName: string;
    winterSport: boolean;
    causeLevel1Code: String;

    public constructor(init?: Partial<RefundTaskViewModel>) {
        Object.assign(this, init);
    }
}

@Injectable({
    providedIn: 'root',
  })
  export class RefundTaskViewModelAdapter implements Adapter<RefundTaskViewModel> {
    adapt(item: any): RefundTaskViewModel {
        return new RefundTaskViewModel({
            id: item.id,
            receivedDate: item.receivedDate === '' ? null : DateSerializer.deserialize(item.receivedDate),
            dueDate: item.dueDate === '' ? null : DateSerializer.deserialize(item.dueDate),
            remainingDays: item.remainingDays,
            refundDate: item.refundDate === '' ? null : DateSerializer.deserialize(item.refundDate),
            receiver: item.receiver,
            receiverName: item.receiverName,
            caseId: item.caseId,
            caseNumber: item.caseNumber,
            serviceOrderTypes: item.serviceOrderTypes,
            refundId: item.refundId,
            refundAmount: item.refundAmount,
            refundStatus: item.refundStatus,
            refundAwaiting: item.refundAwaiting,
            assignedTo: item.assignedTo,
            refundCurrency: item.refundCurrency,
            customerProfileId: item.customerProfileId,
            incidentEventId: item.incidentEventId,
            incidentEventName: item.incidentEventName,
            winterSport: item.winterSport,
            causeLevel1Code: item.causeLevel1Code,
        });
    }
}



