  <div class="field-title asignee-label" (click)="makeCaseHandlerVisible($event)">
    {{ 'task-modal-assignee' | translate }}
  </div>
  <div *ngIf="acHandler != undefined && !isCaseHandlerVisible" (click)="makeCaseHandlerVisible($event)"
    [ngStyle]="!isCaseHandlerVisible && { cursor: 'pointer' }">
    <div *ngIf="acHandler.isTeam && acHandler.adtCode != null" class="container-fluid">
      <div class="row">
        <div class="col fixed-width-40">
          <app-team-picture> </app-team-picture>
        </div>
        <div class="col column-content">
          <div class="team-name">{{ acHandler.teamName }}</div>
        </div>
      </div>
    </div>
    <div *ngIf="!acHandler.isTeam" class="container-fluid">
      <div class="row" (click)="makeCaseHandlerVisible($event)">
        <div class="photo-column col fixed-width-40">
          <app-user-picture [imageURL]="acHandler.picture" [active]="acHandler.active"></app-user-picture>
        </div>
        <div class="col">
          <div>{{ acHandler.fullUserName }} ({{ acHandler.initials }})</div>
          <div class="person-team">{{ acHandler?.team?.teamName | titlecase }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="handlers-dropdown-list" [ngStyle]="(acHandler == undefined || isCaseHandlerVisible) && { overflow: 'visible', height: 'auto' }"
    (clickOutside)="makeCaseHandlerInVisible($event)" (focusin)="makeCaseHandlerVisible($event)" (focusout)="makeCaseHandlerInVisible($event)">
    <app-drop-down-auto-complete #caseHandlerDropDown
      [caseId]="caseId"
      [autoCompleteTypeEnum]="AutoCompleteTypeEnum.UsersAndTeamsExceptFromOtherDepartment"
      [required]="required && !acHandlerSelected"
      (selectedItemIdChange)="onCaseHandlerChanged($event)">
    </app-drop-down-auto-complete>
  </div>
