import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DialogHelperUtilService } from '@secca/core/services/dialog-helper-util.service';
import { DialogBoundryService } from '@secca/core/services/dialog-boundry.service';
import { DialogMinimizableDirective } from '@secca/shared/directives/dialog-minimizable.directive';
import { ShortcutService } from '@secca/core/services/shortcut.service';
import { PreviewDocumentDialogData } from '@secca/shared/components/preview-document-dialog/preview-document-dialog-data';
import {IKeyboardEnabled} from '@secca/shared/interfaces/keyboard-enabled';
import { MinimizableDialogType } from '@secca/shared/enums/minimizable-dialog-type-enum';
import { AdditionalInfo } from '@secca/shared/interfaces/additional-info';

@Component({
  selector: 'app-preview-document-dialog',
  templateUrl: './preview-document-dialog.component.html',
  styleUrls: ['./preview-document-dialog.component.scss']
})
export class PreviewDocumentDialogComponent implements IKeyboardEnabled{
  @ViewChild(DialogMinimizableDirective) minimizedDirective: DialogMinimizableDirective;

  data: PreviewDocumentDialogData;

  constructor(private dialogRef: MatDialogRef<PreviewDocumentDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data: PreviewDocumentDialogData,
              private dialogHelperUtilService: DialogHelperUtilService,
              private shortcutService: ShortcutService,
              public dialogBoundryService: DialogBoundryService) {
    this.data = data;
    if (this.data.disableShortcuts ) {
      this.shortcutService.disableShortCuts();
    }
  }

  keyboardClose(): void {
    this.close();
  }

  minimize() {
    this.minimizedDirective.minimize();
  }

  getMinimizeType(): MinimizableDialogType {
    return this.minimizedDirective.appDialogMinimizable;
  }

  close() {
    if ( this.data.disableShortcuts ) {
      this.shortcutService.enableShortCuts();
    }
    this.dialogHelperUtilService.close(this.dialogRef, this.data.id);
  }

  getAdditionalInfo(): AdditionalInfo {
    return {
      caseId: +this.data.caseId,
      hoverTitle: this.data.caseNumber ? this.data.caseNumber : `CASE ID: ${this.data.caseId}`,
      hoverText: 'Preview document'
    };
  }

  get MinimizableDialogType() {
    return MinimizableDialogType;
  }

}
