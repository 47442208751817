import { TaskFilterRequest } from './task-filter-request.model';
import * as moment from 'moment';

export class RecoveryTaskFilterRequest extends TaskFilterRequest {
    dateRange: moment.Moment[];
    applicationDeadlineRange: moment.Moment[];
    applicationDeadlineFrom: moment.Moment;
    applicationDeadlineTo: moment.Moment;  
    recoveryID: number;
    recoveryStatus: string[];

    public constructor(init?: Partial<RecoveryTaskFilterRequest>) {
        super();
        Object.assign(this, init);
      }

}