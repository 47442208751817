<div class="case-basics-card case-basic-info-user-container" *ngIf="caseStakeholder?.person || caseStakeholder?.company">
  <div [appDisableInput]="caseStateService.isCaseDisabled()">
  <div class="row">
    <div class="button-container col-12">
      <span *permission="PermissionEnum.STAKEHOLDER_UPDATE">
        <button class="info-user-button"
          *ngIf="!caseStakeholder.isCompany"
          [class.lookup-performed]="caseStakeholder.person.ssnLookupPerformed"
          [disabled]="!caseStakeholder.person.ssnLookupPossible || (!caseStakeholder.person.ssnLookupError && caseStakeholder.person.ssnLookupPerformed && !caseStakeholder.person.ssnValidated) || this.caseStateService.isCaseDisabled()"
          (click)="doSsnLookup()" #ssnLookupButton>
          <div [disablePopover]="caseStakeholder.person.ssnLookupPossible && caseStakeholder.person.nationalIdValid"
               triggers="hover" placement="bottom-right" popoverClass="custom-popover"
               [ngbPopover]="lookupNotPossibleText() | translate">
            <span class="shortcut-key">L</span>ookup
          </div>
        </button>
      </span>
    </div>
  </div>
  <div class="d-flex position-relative company-width" *ngIf="type !== 'END_USER'">
    <div class="company-switch-button">
      <app-toggle-switch
        [value]="caseStakeholder.isCompany"
        [name]="'Company'"
        (change)="changeCompanyPersonStatusOnStakeholder()"
        [disabled]="!allowEdit() || !canSwitchToCompany()"
        [ngbPopover]="companyDisabledPopUpText | translate" [disablePopover]="canSwitchToCompany()"
        triggers="hover"
        placement="bottom-start"
        class="company-switch-button"
      >
      </app-toggle-switch>
    </div>
  </div>
  <div class="row">
    <div class="ssn-validation-status" *ngIf="!caseStakeholder.isCompany">
      <div [className]="ssnValidationStatus"></div>
    </div>
  </div>
  <div class="field-row" *ngIf="caseStakeholder.isCompany">
    <div class="col-12">
      <app-input
        [name]="'Company name'"
        [(value)]="caseStakeholder.company.name"
        (saveModel)="saveModel()"
        [disabled]="!allowEdit()"
        [maxTextLength]="250"
        [validationType]="ValidationTypeEnum.required"
      >
      </app-input>
    </div>
  </div>
  <div class="field-row" *ngIf="!caseStakeholder.isCompany">
    <div class="col-6" *ngIf="caseStakeholder">
      <app-drop-down-countries-auto-complete
        class="small-placeholder"
        [name]="'National ID'"
        [errorText]="getErrorText()"
        [withInput]="true"
        [showCode]="true"
        (inputFocusOutEmit)="focusOutFunction()"
        [(selectedItemId)]="nationalIdCountry"
        [showEye]="true"
        (showNationalId)="showNationalIdClick()"
        [autoCompleteTypeEnum]="AutoCompleteTypeEnum.Countries"
        (selectedItemIdChange)="saveNationalIdCountry(theShownNationalId, $event)"
        (selectedItemRemoved)="nationalIdCountryRemoved()"
        [(value)]="theShownNationalId"
        [disabled]="!allowEdit()"
        (focusin)="focusInFunction()"

        [validate]="!caseStakeholder.person.nationalIdValid"
        [isValid] = "caseStakeholder.person.nationalIdValid"
        [validationTypeToInput]="ValidationTypeEnum.nationalID"
        [placeholder]="type === 'REPORTER' ? ('case-stakeholder-optional-placeholder' | translate) : ''"
        [removableCountry]="true"
        [recommended]="type !== 'REPORTER'"
      >
      </app-drop-down-countries-auto-complete>
    </div>
    <div class="col-6">
      <div class="d-flex">
        <div class="date-of-birth-column">
          <app-input-calendar
            [withImage]="true"
            [name]="'Date of birth'"
            [date]="caseStakeholder.person.dateOfBirth"
            [disabled]="caseStakeholder.person.dateOfBirthCalculated || !allowEdit()"
            (dateChange)="updateDateOfBirth($event)"
            [placeholder]="type === 'REPORTER' ? ('case-stakeholder-optional-placeholder' | translate) : ''"
            [recommended]="!caseStakeholder.person.dateOfBirth && type !== 'REPORTER'"
          >
          </app-input-calendar>
        </div>
        <div class="age-column">
          <div class="age-incorrect-message-icon" *ngIf="caseStakeholder.person.age < 0"
               [disablePopover]="caseStakeholder.person.age >= 0" triggers="hover" placement="bottom-right"
               [ngbPopover]="'case-basics-info-user-incorrect-age' | translate" popoverClass="custom-popover">
            !
          </div>
          <app-input [name]="'Age'" [(value)]="caseStakeholder.person.age" [disabled]="true"> </app-input>
        </div>
        <div class="gender-column">
          <app-drop-down
            [name]="'Gender'"
            [items]="genders"
            (saveModel)="updateDateOfBirthOrAgeOrGenderOrNationalId()"
            [(selectedItemId)]="caseStakeholder.person.gender"
            [disabled]="caseStakeholder.person.genderCalculated || !allowEdit()"
          >
          </app-drop-down>
        </div>
      </div>
    </div>
  </div>
  <div class="field-row" *ngIf="!caseStakeholder.isCompany">
    <div class="col-6">
      <app-input
        [name]="'First name'"
        [(value)]="caseStakeholder.person.firstName"
        (saveModel)="saveName()"
        [disabled]="caseStakeholder.person.nameInPassport || !allowEdit()"
        *ngIf="!caseStakeholder.isCompany"
        [maxTextLength]="250"
        [validate]="true"
        [validationType]="ValidationTypeEnum.required"
      >
      </app-input>
    </div>
    <div class="surname-column col-6">
      <div class="nip-checkbox custom-checkbox">
        <label class="checkbox">
          <span class="nip-checkbox-label">NIP</span>
          <input type="checkbox" [(ngModel)]="caseStakeholder.person.nameInPassport" (change)="saveModel()" [disabled]="!allowEdit()" />
          <span class="checkmark nip-checkbox-checkmark"></span>
        </label>
      </div>
      <app-input
        [name]="'Surname'"
        [(value)]="caseStakeholder.person.surname"
        (saveModel)="saveName()"
        [disabled]="caseStakeholder.person.nameInPassport || !allowEdit()"
        [maxTextLength]="250"
        [validationType]="ValidationTypeEnum.required"
      >
      </app-input>
    </div>
  </div>
  <div class="field-row">
    <div class="col-6">
      <app-drop-down-countries-auto-complete
        [name]="'Home country'"
        #homeCountry
        [autoCompleteTypeEnum]="AutoCompleteTypeEnum.Countries"
        [withInput]="false"
        [disabled]="!allowEdit()"
        [countryName]="caseStakeholderCountry?.countryName"
        [(selectedItemId)]="caseStakeholderCountry"
        (selectedItemIdChange)="homeCountryChanged($event)"
        (selectedItemRemoved)="homeCountryRemoved()"
        [showCode]="false"
        [removableCountry]="true"
        [recommended]="!caseStakeholderCountry?.id && type !== 'REPORTER' || allowEdit()"
      >
      </app-drop-down-countries-auto-complete>
    </div>
    <div class="col-6">
      <app-phone-drop-down
        [name]="'Mobile phone'"
        [withPrefixInput]="true"
        [withInput]="true"
        [showCode]="true"
        *ngIf="!caseStakeholder.isCompany"
        (saveModel)="saveMobilePhone($event)"
        [disabled]="!allowEdit()"
        [(selectedItemId)]="stakeholderMobile.countryCode"
        (selectedItemIdChange)="saveMobilePhone($event)"
        [placeholder]="stakeholderMobile.prefix"
        [(prefixValue)]="stakeholderMobile.prefix"
        [(value)]="stakeholderMobile.number"
        [removableCountry]="true"
        [recommended]="true"
      >
      </app-phone-drop-down>

      <app-phone-drop-down
        [name]="'Phone'"
        [withPrefixInput]="true"
        [withInput]="true"
        [showCode]="true"
        *ngIf="caseStakeholder.isCompany"
        (saveModel)="saveFixedPhone($event)"
        [disabled]="!allowEdit()"
        [(selectedItemId)]="stakeholderFixed.countryCode"
        (selectedItemIdChange)="saveFixedPhone($event)"
        [placeholder]="stakeholderFixed.prefix"
        [(prefixValue)]="stakeholderFixed.prefix"
        [(value)]="stakeholderFixed.number"
        [removableCountry]="true"
      >
      </app-phone-drop-down>
    </div>
  </div>
  <div class="field-row">
    <div class="col-6">
      <app-drop-down-input
        *ngIf="caseStakeholder.person"
        [(selectedItemId)]="caseStakeholder.person.hasEmail"
        [(value)]="theShownMail"
        [name]="'Email'"
        (saveModel)="saveEmail($event)"
        [disabled]="!allowEdit()"
        [validate]="caseStakeholder.person.hasEmail"
        [validationType]="ValidationTypeEnum.default"
        [items]="emailStatusType"
        [validationTypeToInput]="caseStakeholder.isCompany ? ValidationTypeEnum.emailAndValidOrEmpty : ValidationTypeEnum.emailAndValid"
        [blockEmail]="blockEmail"
      ></app-drop-down-input>

      <app-input
          *ngIf="caseStakeholder.company"
          [name]="'Email'"
          [maxTextLength]="250"
          [(value)]="theShownMail"
          [validationType]="ValidationTypeEnum.emailAndValid"
          [validate]="true"
          (saveModel)="saveEmail($event)"
          [disabled]="!allowEdit()"
        >
      </app-input>

    </div>
    <div class="col-6">
      <app-phone-drop-down
        [name]="'Phone'"
        *ngIf="!caseStakeholder.isCompany"
        [withPrefixInput]="true"
        [withInput]="true"
        [showCode]="true"
        (saveModel)="saveFixedPhone($event)"
        [(selectedItemId)]="stakeholderFixed.countryCode"
        (selectedItemIdChange)="saveFixedPhone($event)"
        [placeholder]="stakeholderFixed.prefix"
        [(prefixValue)]="stakeholderFixed.prefix"
        [(value)]="stakeholderFixed.number"
        [disabled]="!allowEdit()"
        [removableCountry]="true"
      >
      </app-phone-drop-down>
      <app-phone-drop-down
        [name]="'Fax'"
        *ngIf="caseStakeholder.isCompany"
        [withPrefixInput]="true"
        [withInput]="true"
        [showCode]="true"
        (saveModel)="saveFax($event)"
        [(selectedItemId)]="stakeholderFax.countryCode"
        (selectedItemIdChange)="saveFax($event)"
        [placeholder]="stakeholderFax.prefix"
        [(prefixValue)]="stakeholderFax.prefix"
        [(value)]="stakeholderFax.number"
        [disabled]="!allowEdit()"
        [removableCountry]="true"
      >
      </app-phone-drop-down>
    </div>
  </div>

  <div class="field-row" *ngIf="caseStakeholder.isCompany">
    <div class="col-6">
      <app-input
        [name]="'case-basics-info-contact-person-firstname' | translate"
        [(value)]="caseStakeholder.company.contactPersons[0].firstName"
        (saveModel)="updateContactPerson()"
        [disabled]="!allowEdit()"
        [maxTextLength]="250"
        [recommended]="!caseStakeholder.company.contactPersons[0].firstName"
      >
      </app-input>
    </div>
    <div class="col-6">
      <app-input
      [name]="'case-basics-info-contact-person-surname' | translate"
        [(value)]="caseStakeholder.company.contactPersons[0].surname"
        (saveModel)="updateContactPerson()"
        [disabled]="!allowEdit()"
        [maxTextLength]="250"
        [recommended]="!caseStakeholder.company.contactPersons[0].surname">
      </app-input>
    </div>
  </div>
  <div class="field-row" *ngIf="caseStakeholder.isCompany">
    <div class="col-6">
      <app-input
      [name]="'case-basics-info-contact-person-email' | translate"
      [(value)]="caseStakeholder.company.contactPersons[0].email"
      (saveModel)="updateContactPerson()"
      [disabled]="!allowEdit()"
      [maxTextLength]="250"
      [recommended]="!caseStakeholder.company.contactPersons[0].email"
      >
      </app-input>
    </div>
    <div class="col-6">
        <app-phone-drop-down
        [disabled]="!allowEdit()"
        [name]="'case-basics-info-contact-person-phone' | translate"
        [withPrefixInput]="true"
        [withInput]="true"
        [showCode]="true"
        (saveModel)="saveModelContactPersonPhone($event)"
        [(selectedItemId)]="caseStakeholder.company.contactPersons[0].phoneNumbers[0].countryCode"
        (selectedItemIdChange)="saveModelContactPersonPhone($event)"
        [placeholder]="caseStakeholder.company.contactPersons[0].phoneNumbers[0].number"
        [(prefixValue)]="caseStakeholder.company.contactPersons[0].phoneNumbers[0].prefix"
        [(value)]="caseStakeholder.company.contactPersons[0].phoneNumbers[0].number"
        [recommended]="true"
      >
      </app-phone-drop-down>
    </div>
  </div>
  <div class="field-row">
    <div [ngClass]="{ 'col-6': caseStakeholder.isCompany, 'col-8': !caseStakeholder.isCompany }">
      <app-input
        [name]="'Remarks'"
        *ngIf="caseStakeholder.isCompany"
        [(value)]="caseStakeholder.company.remarks"
        (saveModel)="saveModel()"
        [disabled]="!allowEdit()"
        [maxTextLength]="250"
        [placeholder]="'case-stakeholder-optional-placeholder' | translate"
      ></app-input>

      <app-input
        [name]="'Remarks'"
        *ngIf="!caseStakeholder.isCompany"
        [(value)]="caseStakeholder.person.remarks"
        (saveModel)="saveModel()"
        [disabled]="!allowEdit()"
        [maxTextLength]="250"
        [placeholder]="'case-stakeholder-optional-placeholder' | translate"
      ></app-input>
    </div>
    <div [ngClass]="{ 'col-6': caseStakeholder.isCompany, 'col-4': !caseStakeholder.isCompany }">
      <app-input-calendar
        *ngIf="!caseStakeholder.isCompany"
        [withImage]="true"
        [name]="'case-basics-date-of-death' | translate"
        [date]="caseStakeholder.person.dateOfDeath"
        [disabled]="!allowEdit()"
        (dateChange)="updateDateOfDeath($event)"
      >
      </app-input-calendar>
      <app-employees
        [users]="employeesList"
        [disabled]="!allowEdit()"
        [name]="'case-basics-info-employee' | translate"
        [selectedItemId]="+caseStakeholder.company.employee"
        *ngIf="caseStakeholder.company"
        (selectedItemIdChange)="changeEmployee($event)"
        [isRecommended]="caseStakeholder.stakeholderType === 'POLICYHOLDER'"
        [showOptionalText]="caseStakeholder.stakeholderType === 'REPORTER'">
      </app-employees>
    </div>
  </div>
  <app-icon-chekbox-row
    [caseId]="caseId"
    [type]="type"
    [caseStakeholder]="caseStakeholder"
    (refresh)="reloadData()"
    *permission="PermissionEnum.STAKEHOLDER_UPDATE; hideType: PermissionHideTypeEnum.DISABLE"
  >
  </app-icon-chekbox-row>
  </div>

  <div class="separator"></div>
  <div class="row">
    <div class="col-12">
      <div class="show-more" (click)="showAddressClick()">
        <span *ngIf="!showAddress">Show address</span>
        <span *ngIf="showAddress">Hide address</span>
        <img src="../../../../../../../assets/icons/ArrowDownBlue.svg" />
      </div>
    </div>
  </div>
  <div *ngIf="showAddress">
  <div [appDisableInput]="caseStateService.isCaseDisabled()">
  <div class="field-row">
    <div class="col-12">
      <app-input
        [name]="'Address'"
        [(value)]="caseStakeholder.address.street"
        (saveModel)="saveModel()"
        [disabled]="!allowEdit()"
        [maxTextLength]="250"
        [placeholder]="type === 'REPORTER' ? ('case-stakeholder-optional-placeholder' | translate) : ''"
        [recommended]="!caseStakeholder.address.street && type !== 'REPORTER'"
      ></app-input>
    </div>
  </div>
  <div class="field-row">
    <div class="col-6">
      <app-input
        [name]="'Postal code'"
        [(value)]="caseStakeholder.address.postalCode"
        (saveModel)="saveModel()"
        [disabled]="!allowEdit()"
        [maxTextLength]="250"
        [inputTypePattern]="InputTypePattern.all"
        [placeholder]="type === 'REPORTER' ? ('case-stakeholder-optional-placeholder' | translate) : ''"
        [recommended]="!caseStakeholder.address.postalCode && type !== 'REPORTER'"
      ></app-input>
    </div>
    <div class="col-6">
      <app-input
        [name]="'City'"
        [(value)]="caseStakeholder.address.city"
        (saveModel)="saveModel()"
        [disabled]="!allowEdit()"
        [maxTextLength]="250"
        [inputTypePattern]="InputTypePattern.lettersOnly"
        [placeholder]="type === 'REPORTER' ? ('case-stakeholder-optional-placeholder' | translate) : ''"
        [recommended]="!caseStakeholder.address.city && type !== 'REPORTER'"
      >
      </app-input>
    </div>
  </div>
  </div>
  </div>
</div>
