import * as moment from 'moment';
import { DateSerializer } from './date-serializer';
import { DaySerializer } from './day-serializer';
import { Location } from './location';

export class Duration {
  id: number;
  roomNumber: string;
  internalRemarks: string;
  location: Location = new Location();
  startDate: moment.Moment;
  endDate: moment.Moment;
  gopPeriodStartDate: moment.Moment;
  gopPeriodEndDate: moment.Moment;
  submittedInSupo: boolean;

  public constructor(init?: Partial<Duration>) {
    Object.assign(this, init);
  }

  isValid(): boolean {
    return this.isStartEndDatesValid() && this.isGopPeriodsValid() && this.isLocationValid();
  }

  isValidExpectation() {
    return this.isStartEndDatesValid() && this.isLocationValid();
  }

  isStartDateValid(): boolean {
    return this.startDate && this.startDate.isValid();
  }

  isEndDateValid(): boolean {
    return this.endDate && this.endDate.isValid();

  }
  isStartEndDatesValid(): boolean {
    return this.isStartDateValid() && this.isEndDateValid() && this.startDate.isSameOrBefore(this.endDate);
  }

  private isLocationValid(): boolean {
    return !!this.location.freeText;
  }

  isGopPeriodStartValid(): boolean {
    return this.gopPeriodStartDate && this.gopPeriodStartDate.isValid();
  }

  isGopPeriodEndValid(): boolean {
    return this.gopPeriodEndDate && this.gopPeriodEndDate.isValid();
  }

  isGopPeriodsValid(): boolean {
    return this.isGopPeriodStartValid() && this.isGopPeriodEndValid() && this.gopPeriodStartDate.isSameOrBefore(this.gopPeriodEndDate);
  }

}


export class DurationAdapter {
  adapt(item: any): Duration {
    return new Duration({
      id: item.id,
      roomNumber: item.roomNumber,
      internalRemarks: item.internalRemarks,
      location: item.location,
      startDate: DateSerializer.deserialize(item.startDate),
      endDate: DateSerializer.deserialize(item.endDate),
      gopPeriodStartDate: DaySerializer.deserialize(item.gopPeriodStartDate),
      gopPeriodEndDate: DaySerializer.deserialize(item.gopPeriodEndDate),
      submittedInSupo: item.submittedInSupo
    });
  }
}
