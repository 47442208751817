import * as moment from 'moment';

export class RangeSupport {

  constructor(
    private startDate: moment.Moment,
    private endDate: moment.Moment,
    private context, // We need the reference to the calling context to use 'apply' below.
    private validStartDateCallback,
    private validEndDateCallback,
    private invalidDateRangeCallback) {
  }

  updateStartDate(startDate: moment.Moment) {
    this.startDate = startDate;
    if (!this.isStartDateInValidInRange()) {
      this.validStartDateCallback.apply(this.context, [this.startDate, this.endDate]);
    } else {
      if (this.invalidDateRangeCallback) {
        this.invalidDateRangeCallback.apply(this.context, [this.endDate, this.startDate]);
      }
    }
  }

  updateEndDate(endDate: moment.Moment) {
    this.endDate = endDate;
    if (!this.isEndDateInvalidInRange()) {
      this.validEndDateCallback.apply(this.context, [this.endDate, this.startDate]);
    } else {
      if (this.invalidDateRangeCallback) {
        this.invalidDateRangeCallback.apply(this.context, [this.endDate, this.startDate]);
      }
    }
  }

  isStartDateInValidInRange() {
    if (this.startDate) {
      return this.endDate ? !this.startDate.isSameOrBefore(this.endDate) : false;
    }
    return false;
  }

  isEndDateInvalidInRange() {
    if (this.endDate) {
      return this.startDate ? !this.endDate.isSameOrAfter(this.startDate) : false;
    }
    return false;
  }

}
