<div class="modal-dialog-wrapper" appDialogSelectable appDialogKeyboardEnabled
     [appDialogMinimizable]='MinimizableDialogType.CaseEconomyRecoveryPayment'>

  <mat-dialog-content (cdkDragStarted)='dialogBoundryService.handleDragStart($event)'
                      (cdkDragEnded)='dialogBoundryService.handleDragEnd($event)'
                      cdkDragRootElement='.cdk-overlay-pane' cdkDrag>
    <div appDialogDragArea cdkDragHandle></div>

    <app-recovery-payment (closeRecoveryPayment)='close()' [recoveryPayment]='data.payment' [serviceItemOptions]="data.serviceItemOptions" [recoveryOptions]="data.recoveryOptions"/>

  </mat-dialog-content>
</div>
