import { MedicalEscortExpenseItemEnum, SalesOrderItemTypeEnum, ServiceTypeEnum } from './../../../../../shared/models/enums';
import { SupplierInvoiceItem } from 'src/app/shared/models/supplierInvoiceItem';
import { SalesOrderItem } from '@secca/shared/models/salesOrderItem';
import { SupplierInvoice } from '@secca/shared/models/supplierInvoice';
import { Component, Input } from '@angular/core';
import { SalesOrderStatus } from '@secca/shared/enums/sales-order-status';
import { SalesOrder } from '@secca/shared/models/salesOrder';
import { SalesOrderService } from '@secca/core/services/sales-order.service';
import { Case } from '@secca/shared/models/case';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import { SupplierInvoiceService } from '@secca/core/services/supplier-invoice.service';
import { PurchaseAndSalesItem } from '@secca/shared/models/PurchaseAndSalesItem';
import { TranslateService } from '@ngx-translate/core';
import { PermissionService } from '@secca/core/services/permission.service';
import { ServiceOrderService } from '@secca/core/services/service-order.service';
import { ServiceOrder } from '@secca/shared/models/service-order/service-order';
import { forkJoin } from 'rxjs';
import { CaseStateService } from '@secca/core/state-services/case-state.service';

@Component({
  selector: 'app-case-economy-purchase-and-sales',
  templateUrl: './case-economy-purchase-and-sales.component.html',
  styleUrls: ['./case-economy-purchase-and-sales.component.scss'],
})
export class CaseEconomyPurchaseAndSalesComponent {
  salesOrderItems: SalesOrderItem[] = [];
  purchaseAndSalesItems: PurchaseAndSalesItem[] = [];
  combinedPurchaseAndSalesItems: PurchaseAndSalesItem[] = [];
  caseCurrency = 'DKK';
  totalPurchaseAmount = 0;
  totalSalesAmount = 0;
  totalNetAmount = 0;
  totalNetPercent = 0;

  @Input()
  set case(newCase: Case) {
    this.theCase = newCase;
    this.onRefresh();
  }
  get case() {
    return this.theCase;
  }
  private theCase: Case;

  constructor(
    private salesOrderService: SalesOrderService,
    private serviceOrderService: ServiceOrderService,
    public caseLockHelperService: CaseLockHelperService,
    public permissionService: PermissionService,
    public supplierInvoiceService: SupplierInvoiceService,
    private translateService: TranslateService,
    public caseStateService: CaseStateService,
  ) {}

  onRefresh() {
    if (this.theCase) {
      this.salesOrderService.getSalesOrders().subscribe(salesOrders => {
        this.createSalesOrderItems(salesOrders);
        if (this.salesOrderItems.length > 0) {
          this.buildTable(this.salesOrderItems);
        }
      });
    }
  }

  createSalesOrderItems(salesOrders: SalesOrder[]) {
    this.salesOrderItems = [];
    for (const salesOrder of salesOrders) {
      this.caseCurrency = salesOrder.invoicePrice.currency;
      if (!!salesOrder.salesOrderItems) {
        for (const salesOrderItem of salesOrder.salesOrderItems) {
          salesOrderItem.invoiced =
            salesOrder.status === SalesOrderStatus.SENT_TO_ERP ||
            salesOrder.status === SalesOrderStatus.INVOICED ||
            salesOrder.status === SalesOrderStatus.PAID;
          if (salesOrderItem.invoiced) {
            this.salesOrderItems.push(salesOrderItem);
          }
        }
      }
    }
  }

  buildTable(salesOrderItems: SalesOrderItem[]) {
    forkJoin([
      this.supplierInvoiceService.getSupplierInvoicesByCase(Number(this.theCase.id)),
      this.serviceOrderService.getServiceOrdersForCase(Number(this.theCase.id)),
    ]).subscribe(results => {
      const supplierInvoices = results[0];
      const orders = results[1];
      this.buildPurchaseAndSalesItems(salesOrderItems, supplierInvoices, orders);
      this.combineSupplierInvoiceItems(this.purchaseAndSalesItems);
      this.calculateTotals(this.purchaseAndSalesItems);
    });
  }

  buildPurchaseAndSalesItems(salesOrderItems: SalesOrderItem[], supplierInvoices: SupplierInvoice[], serviceOrders: ServiceOrder[]) {
    this.purchaseAndSalesItems = [];
    let id = 1;
    salesOrderItems.forEach(salesOrderItem => {
      const supplierInvoiceItem = this.getSupplierInvoiceItem(salesOrderItem, supplierInvoices);
      const purchaseAndSalesItem = new PurchaseAndSalesItem();
      let purchaseAmount = 0;
      const salesAmount = salesOrderItem.invoicePrice.amount;

      if (salesOrderItem.type === SalesOrderItemTypeEnum.SUPPLIER_INVOICE && !!supplierInvoices && !!supplierInvoiceItem) {
        purchaseAmount = this.calculatePurchaseAmount(supplierInvoiceItem);
        purchaseAndSalesItem.purchaseAmount = purchaseAmount;
        purchaseAndSalesItem.supplierName = this.getSupplierName(supplierInvoiceItem.supplierId);
        purchaseAndSalesItem.supplierInvoiceItem = supplierInvoiceItem;
        purchaseAndSalesItem.creditorId = supplierInvoiceItem.creditorId;
        purchaseAndSalesItem.creditorName = supplierInvoiceItem.creditorName;
      } else {
        this.setPurchaseAmountMedicalEscortFee(salesOrderItem, salesOrderItems, supplierInvoices, serviceOrders, purchaseAndSalesItem);
      }

      purchaseAndSalesItem.id = id;
      purchaseAndSalesItem.serviceItem = salesOrderItem.sosServiceName;
      purchaseAndSalesItem.type = this.getType(salesOrderItem.type);
      purchaseAndSalesItem.salesAmount = salesAmount;
      purchaseAndSalesItem.netAmount = this.calculateNetAmount(purchaseAndSalesItem.purchaseAmount, purchaseAndSalesItem.salesAmount);
      this.purchaseAndSalesItems.push(purchaseAndSalesItem);

      id++;
    });
  }

  setPurchaseAmountMedicalEscortFee(
    salesOrderItem: SalesOrderItem,
    allSalesOrderItems: SalesOrderItem[],
    supplierInvoices: SupplierInvoice[],
    serviceOrders: ServiceOrder[],
    purchaseAndSalesItem: PurchaseAndSalesItem
  ) {
    const additionalMedicalServicesEscort = 'ADDITIONAL_MEDICAL_SERVICES_ESCORT';
    if (
      salesOrderItem.type == SalesOrderItemTypeEnum.FEE &&
      salesOrderItem.sosServicesCategory === additionalMedicalServicesEscort &&
      serviceOrders.find(so => so.serviceOrderId === salesOrderItem.serviceOrderId)?.type === ServiceTypeEnum.MEDICAL_ESCORT
    ) {
      const invoiceItems: SupplierInvoiceItem[] = [];
      const medicalEscortExpenseItemType = Object.keys(MedicalEscortExpenseItemEnum);
      supplierInvoices.forEach(supplierInvoice => {
        supplierInvoice.items
          .filter(
            invoiceItem =>
              invoiceItem.serviceOrderId === salesOrderItem.serviceOrderId &&
              medicalEscortExpenseItemType.indexOf(invoiceItem.serviceItemCode) === -1
          )
          .forEach(invoiceItem => {
            invoiceItem.exchangeRate = supplierInvoice.exchangeRate;
            invoiceItems.push(invoiceItem);
          });
      });
      const purchaseAmountTotal = invoiceItems.map(item => this.calculatePurchaseAmount(item)).reduce((prev, curr) => prev + curr, 0);
      const allMedicalEscortSalesAmount = allSalesOrderItems
        .filter(
          item =>
            item.serviceOrderId === salesOrderItem.serviceOrderId &&
            item.type == SalesOrderItemTypeEnum.FEE &&
            item.sosServicesCategory === additionalMedicalServicesEscort
        )
        .map(item => item.invoicePrice.amount)
        .reduce((prev, curr) => prev + curr, 0);
      purchaseAndSalesItem.purchaseAmount = (purchaseAmountTotal * salesOrderItem.invoicePrice.amount) / allMedicalEscortSalesAmount;
    }
  }

  combineSupplierInvoiceItems(purchaseAndSalesItems: PurchaseAndSalesItem[]) {
    this.combinedPurchaseAndSalesItems = [];
    let uniquePurchaseAndSalesItems: PurchaseAndSalesItem[] = purchaseAndSalesItems;
    uniquePurchaseAndSalesItems = uniquePurchaseAndSalesItems.filter(
      (value, index, array) =>
        array.findIndex(item => item.supplierName === value.supplierName && item.serviceItem === value.serviceItem) === index
    );
    uniquePurchaseAndSalesItems.forEach(uniqueItem => {
      const filteredList = purchaseAndSalesItems.filter(
        originalItem => originalItem.supplierName === uniqueItem.supplierName && originalItem.serviceItem === uniqueItem.serviceItem
      );
      const resultItem = new PurchaseAndSalesItem();
      const purchaseAmountTotal = filteredList.map(item => item.purchaseAmount).reduce((prev, curr) => prev + curr, 0);
      const salesAmountTotal = filteredList.map(item => item.salesAmount).reduce((prev, curr) => prev + curr, 0);
      const netAmountTotal = filteredList.map(item => item.netAmount).reduce((prev, curr) => prev + curr, 0);

      resultItem.serviceItem = filteredList[0].serviceItem;
      resultItem.supplierName = filteredList[0].supplierName;
      resultItem.creditorId = filteredList[0].creditorId;
      resultItem.creditorName = filteredList[0].creditorName;
      resultItem.type = filteredList[0].type;
      resultItem.purchaseAmount = purchaseAmountTotal;
      resultItem.salesAmount = salesAmountTotal;
      resultItem.netAmount = netAmountTotal;
      this.combinedPurchaseAndSalesItems.push(resultItem);
    });
  }

  calculateTotals(purchaseAndSalesItems: PurchaseAndSalesItem[]) {
    this.totalPurchaseAmount = 0;
    this.totalSalesAmount = 0;
    this.totalNetAmount = 0;
    this.totalNetPercent = 0;

    for (const purchaseAndSalesItem of purchaseAndSalesItems) {
      if (purchaseAndSalesItem.purchaseAmount) {
        this.totalPurchaseAmount += purchaseAndSalesItem.purchaseAmount;
      }
      this.totalSalesAmount += purchaseAndSalesItem.salesAmount;
      this.totalNetAmount += purchaseAndSalesItem.netAmount;
    }

    this.totalNetAmount = this.totalSalesAmount - this.totalPurchaseAmount;
    if (this.totalSalesAmount !== 0 && this.totalPurchaseAmount !== 0) {
      this.totalNetPercent = (this.totalSalesAmount / this.totalPurchaseAmount - 1) * 100;
    } else {
      this.totalNetPercent = 0;
    }
  }

  getSupplierInvoiceItem(salesOrderItem: SalesOrderItem, supplierInvoices: SupplierInvoice[]) {
    if (salesOrderItem.type === SalesOrderItemTypeEnum.SUPPLIER_INVOICE && !!supplierInvoices) {
      for (const supplierInvoice of supplierInvoices) {
        for (const supplierInvoiceItem of supplierInvoice.items) {
          if (supplierInvoiceItem.id === salesOrderItem.supplierInvoiceItemId) {
            supplierInvoiceItem.exchangeRate = supplierInvoice.exchangeRate;
            supplierInvoiceItem.creditorId = supplierInvoice.creditorId;
            supplierInvoiceItem.creditorName = supplierInvoice.creditorName;
            return supplierInvoiceItem;
          }
        }
      }
    }
    return;
  }

  getSupplierName(supplierId: number) {
    if (this.theCase?.simpleCaseStakeholders) {
      return this.theCase.simpleCaseStakeholders
        .filter(stakeholder => stakeholder.id === supplierId)
        .map(supplier => supplier.companyName)[0];
    }
    return null;
  }

  getType(type: string) {
    const prefix = 'case-economy-purchase-and-sales-';
    return this.translateService.instant(prefix + type.toLowerCase().replace('_', '-'));
  }

  calculatePurchaseAmount(supplierInvoiceItem: SupplierInvoiceItem) {
    let totalItemSavings = 0;
    supplierInvoiceItem.savings.filter(savings => !savings.gross).map(savings => (totalItemSavings += savings.amount));
    return (supplierInvoiceItem.paidAmount - totalItemSavings) * supplierInvoiceItem.exchangeRate;
  }

  calculateNetAmount(purchaseAmount: number, salesAmount: number) {
    if (!!purchaseAmount && !!salesAmount) {
      return salesAmount - purchaseAmount;
    }
    return salesAmount;
  }
}
