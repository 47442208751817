import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { PersonInsurance } from '@secca/shared/models/person-insurance';

@Injectable({
  providedIn: 'root'
})
export class PersonInsuranceStateService {
  private insuranceSubject = new ReplaySubject<PersonInsurance>(1);
  private insuranceCachedCaseId: number = null;

  constructor() {}

  public getPersonInsurance(): ReplaySubject<PersonInsurance> {
    return this.insuranceSubject;
  }

  public updateCacheAndEmitInsuranceUpdated(personInsurance: PersonInsurance): void {
    if ( this.insuranceCachedCaseId === +personInsurance.seccaCaseId ) {
      this.insuranceSubject.next(personInsurance);
    }
  }

  public isPersonInsuranceCached(caseId: string): boolean {
    return this.insuranceCachedCaseId === +caseId;
  }

  public initPersonInsuranceCache(caseId: string): ReplaySubject<PersonInsurance> {
    this.insuranceCachedCaseId = +caseId;
    this.insuranceSubject = new ReplaySubject<PersonInsurance>(1);
    return this.insuranceSubject;
  }

  public clearCache(): void {
    this.insuranceCachedCaseId = null;
  }
}
