import { Component, Input } from '@angular/core';
import { LookupExecution, LookupExecutionErrorType } from '@secca/shared/models/policy-lookup-response';

@Component({
  selector: 'app-policy-lookup-integration-status',
  templateUrl: './policy-lookup-integration-status.component.html',
  styleUrls: ['./policy-lookup-integration-status.component.scss']
})
export class PolicyLookupIntegrationStatusComponent {
  _executions: LookupExecution[];
  failed: LookupExecution[] = [];
  failedProducts = [];
  succeeded: LookupExecution[] = [];

  @Input() set executions(executions: LookupExecution[]) {
    this._executions = executions;

    if (executions) {
      this.succeeded = executions.filter(execution => !execution.error);
      this.failed = executions.filter(execution => !!execution.error);
      this.failedProducts = this.getFailedProducts(this.failed);
    }
  }

  getFailedProducts(list: LookupExecution[]) {
    const products = [];
    list.forEach(execution =>
      execution.error.products.forEach(p => products.push(p.productName))
    );
    return products;
  }

  get ErrorType() {
    return LookupExecutionErrorType;
  }

  stringify(params: any) {
    return JSON.stringify(params);
  }
}
