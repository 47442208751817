<div class="common-drop-down-arrow drop-down">
  <span class="name">{{ name }}</span>
  <span *ngIf="required" class="required-red">*</span>
  <ng-select
    #selectCause
    [dropdownPosition]="dropdownPosition"
    (blur)="onSaveModel()"
    (focus)="onSaveState()"
    (close)="onClose()"
    [clearable]="false"
    [(ngModel)]="selectedItemId"
    [ngClass]="{ noborder: noBorderAndArrow, custom: !noBorderAndArrow, 'recommended-yellow-background': recommended }"
    [disabled]="!!disabled"
    [multiple]="multiple"
    [closeOnSelect]="closeOnSelect"
    [placeholder]="placeholder"
    *ngIf="translatePipePath !== undefined"
    (change)="onChange()"
  >
    <ng-option *ngFor="let item of items" [value]="item" [disabled]="item.disabled">
      {{item.text }}
    </ng-option>
    <ng-template ng-multi-label-tmp *ngIf="multiSelectedLabel !== undefined">
      <div class="multi-selected-label">{{ translatePipePath + multiSelectedLabel | translate: { number: selectedItemId.length } }}</div>
    </ng-template>
    <ng-template ng-label-tmp let-item="item" *ngIf="displayMissingLabel()">
      {{ missingItemLabel }}
    </ng-template>
  </ng-select>

  <ng-select
    #selectCause
    (blur)="onSaveModel()"
    (focus)="onSaveState()"
    (close)="onClose()"
    [clearable]="false"
    [dropdownPosition]="dropdownPosition"
    [(ngModel)]="selectedItem"
    [ngClass]="{ noborder: noBorderAndArrow, custom: !noBorderAndArrow }"
    [disabled]="!!disabled"
    [multiple]="multiple"
    [closeOnSelect]="closeOnSelect"
    [placeholder]="placeholder"
    [items]="items"
    [trackByFn]="trackBy"
    
    *ngIf="translatePipePath === undefined"
    (change)="onChange()"
    [class.required-red-background]="requiredRedBackground && !this.selectedItem"
    [class.required-red-label]="requiredRedLabel"
    [class.recommended-yellow-background]="recommended"
  >
    <ng-template ng-option-tmp let-item="item">
      <span
        [ngbPopover]="item.hoverText | translate"
        triggers="hover"
        placement="right"
        popoverClass="commit-popover"
        container="body">
        {{ item.text() }}
      </span>
    </ng-template>
    <ng-template ng-label-tmp let-item="item">
      {{ item.name }}
    </ng-template>
    <ng-template ng-multi-label-tmp *ngIf="multiSelectedLabel !== undefined">
      <div class="multi-selected-label">{{ multiSelectedLabel }}</div>
    </ng-template>
    <ng-template ng-label-tmp let-item="item" *ngIf="displayMissingLabel()">
      {{ missingItemLabel }}
    </ng-template>
  </ng-select>
</div>
