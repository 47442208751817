

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MedicalAssessmentCardTitleComponent } from "@secca/shared/components/medical-assessment-card/medical-assessment-card-title/medical-assessment-card-title.component";
import { SharedModule } from "@secca/shared/shared.module";
import { UtilsModule } from "src/app/utils/utils.module";
import { CaseMedicalHistoryComponent } from "./case-medical-history/case-medical-history.component";
import { CaseMedicalInfocardsComponent } from "./case-medical-infocards/case-medical-infocards.component";
import { CaseMedicalComponent } from "./case-medical.component";
import { MedicalAssessmentDialogComponent } from "./medical-assessment/medical-assessment-dialog/medical-assessment-dialog.component";
import { MedicalPreAssessmentDialogComponent } from '@secca/case/components/case-medical/medical-pre-assessment/medical-pre-assessment-dialog/medical-pre-assessment-dialog.component';
import { MedicalAssessmentSourceComponent } from "./medical-assessment/medical-assessment-source/medical-assessment-source.component";
import { MedicalAssessmentComponent } from "./medical-assessment/medical-assessment.component";
import { MedicalPreAssessmentComponent } from './medical-pre-assessment/medical-pre-assessment.component';
import { ActionCardComponent } from "./medical-components/action-card/action-card.component";
import { MedicalAssessmentMenuComponent } from "./medical-components/medical-assessment-menu/medical-assessment-menu.component";
import { SmallYesNoQuestionComponent } from "./medical-components/small-yes-no-question/small-yes-no-question.component";
import { TotalDaysComponent } from "./medical-components/total-days/total-days.component";
import { TotalVisitsComponent } from "./medical-components/total-visits/total-visits.component";
import { MedicalNoteComponent } from "./medical-note/medical-note.component";
import { RepatriationInstructionsComponent } from "./repatriation-instructions/repatriation-instructions.component";
import { RepatriationPrognosisComponent } from "./repatriation-prognosis/repatriation-prognosis.component";
import { MedicalReportSupplierModalComponent } from './medical-report-supplier-modal/medical-report-supplier-modal.component';
import { MedicalReportSupplierDialogComponent } from './medical-report-supplier-dialog/medical-report-supplier-dialog.component';
import { MedicalPreAssessmentMenuComponent } from './medical-components/medical-pre-assessment-menu/medical-pre-assessment-menu.component';
import { MediPreTestComponent } from './medical-pre-assessment/medi-pre-test/medi-pre-test.component';
import { MedicalPreAssessmentDecisionComponent } from "./medical-pre-assessment/medical-pre-assessment-decision/medical-pre-assessment-decision.component";

@NgModule({
  declarations: [
    CaseMedicalComponent,
    CaseMedicalHistoryComponent,
    CaseMedicalInfocardsComponent,
    MedicalAssessmentDialogComponent,
    MedicalPreAssessmentDialogComponent,
    ActionCardComponent,
    MedicalAssessmentMenuComponent,
    SmallYesNoQuestionComponent,
    TotalDaysComponent,
    TotalVisitsComponent,
    MedicalNoteComponent,
    RepatriationInstructionsComponent,
    RepatriationPrognosisComponent,
    MedicalAssessmentComponent,
    MedicalPreAssessmentComponent,
    MedicalAssessmentCardTitleComponent,
    MedicalAssessmentSourceComponent,
    MedicalReportSupplierModalComponent,
    MedicalReportSupplierDialogComponent,
    MedicalPreAssessmentMenuComponent,
    MediPreTestComponent,
    MedicalPreAssessmentDecisionComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UtilsModule,
    SharedModule
  ],
  exports: [
    CaseMedicalComponent
  ]
})
export class CaseMedicalModule { }