import { HandlingAreaStatusEnum, PatientType, SortOrder, TransportAssistanceType } from '@secca/shared/models/enums';
import * as moment from 'moment';
import { CustomerProfileIdsForSearch } from '../../board-task/models/task-filter-request.model';

export class CaseIncidentEventsRequest {
  fromDate: moment.Moment;
  toDate: moment.Moment;
  incidentFromDate: moment.Moment;
  incidentToDate: moment.Moment;
  customerProfileId: number;
  customerProfileIds: CustomerProfileIdsForSearch[];
  causeLevel1Code: string;
  causeLevel2Code: string;
  causeLevel3Code: string;
  incidentCountry: String[];
  eventKeys: string[];
  userIds: number[];
  adtCodes: string[];
  adtCodesForCount: string[];
  includeOtherTeams: boolean;
  statuses: HandlingAreaStatusEnum[];
  sortBy: string;
  pageIndex: number;
  pageSize: number;
  sortOrder: SortOrder;
  noIncidentDate: boolean;
  selectedTeamNames: string[];
  caseTypeCodes: string[];
  inOutPatient: PatientType[];
  transportAssistanceType: TransportAssistanceType[];

  public constructor(init?: Partial<CaseIncidentEventsRequest>) {
    Object.assign(this, init);
  }
}
