import { SubComponentModel } from '@secca/case/components/case-plans/case-plans/add-service-order/models/interfaces';

export class SubRemarksRulesAndFees implements SubComponentModel {
  remarks: string;
  rulesAndFees: string;

  public constructor(init?: Partial<SubRemarksRulesAndFees>) {
    Object.assign(this, init);
  }

  isValid(): boolean {
    return true;
  }

}

export class SubRemarksRulesAndFeesAdapter {
  public adapt(item: any): SubRemarksRulesAndFees {
    return new SubRemarksRulesAndFees({
      remarks: item.remarks,
      rulesAndFees: item.rulesAndFees
    });
  }
}
