<div class="sub-component-container" *ngIf="subRemarksFromSupplier">
  <div class="sub-title">{{'sub-remarks-from-supplier-title' | translate}}</div>
  <div class="sub-content-container">
    <div class="row">
      <div class="col-12">
        <app-text-box [name]="'sub-remarks-from-supplier' | translate" [maxTextLength]="1000"
                      [(value)]="subRemarksFromSupplier.remarks"></app-text-box>
      </div>
    </div>
  </div>
</div>
