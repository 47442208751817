import { Component, EventEmitter, Input, OnInit, Output, ViewChild, TemplateRef, ViewEncapsulation, ContentChildren } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-drop-menu',
  templateUrl: './drop-menu.component.html',
  styleUrls: ['./drop-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DropMenuComponent {
  @ViewChild('dropmenu') dropMenu: NgSelectComponent;
  @ContentChildren('optionTemplate') items: TemplateRef<any>[];

  @Input() dropdownPosition: string = 'top';
  @Input() appendTo: string = 'body';
  @Input() disabled: boolean;
  @Input() label: string;

  @Output() selectedItemChange = new EventEmitter();

  selectedItem;

  constructor() {}

  open() {
    this.dropMenu.open();
  }

  onChange(template: string) {
    this.selectedItemChange.emit(template)
    setTimeout(() => {
      this.selectedItem = '';
    }, 0)
  }
}
