import { Component, OnInit, Input } from '@angular/core';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { PlannedTravel } from '../../../../../shared/models/planned-travel';
import { CaseIncident } from '../../../../../shared/models/caseIncident';
import { NationalIdService } from '../../../../../core/services/national-id.service';
import { MedicalService } from '../../../../../core/services/medical.service';
import { DictionaryService } from 'src/app/core/services/dictionary.service';
import { AuditlogService } from 'src/app/core/services/auditlog.service';
import { UserDto } from '../../../../../shared/models/userDto';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { IncidentService } from '@secca/core/services/incident.service';
import { CausesLevel1DD } from '@secca/shared/models/CausesLevel1DD';

@Component({
  selector: 'app-case-medical-infocards',
  templateUrl: './case-medical-infocards.component.html',
  styleUrls: ['./case-medical-infocards.component.scss']
})
export class CaseMedicalInfocardsComponent implements OnInit {

  @Input() caseID: string;

  @Input()
  set endUser(theEndUser: CaseStakeholder) {
    if (theEndUser !== undefined) {
      this.thisEndUser = theEndUser;
      this.onStakeholderLoad();
    }
  }
  get endUser(): CaseStakeholder {
    return this.thisEndUser;
  }

  @Input()
  set plannedTravel(thePlannedTravel: PlannedTravel) {
    if (thePlannedTravel !== undefined) {
      this.thisPlannedTravel = thePlannedTravel;
      this.onPlannedTravelLoad();
    }
  }
  get plannedTravel(): PlannedTravel {
    return this.thisPlannedTravel;
  }

  @Input()
  set caseIncident(theincident: CaseIncident) {
    if (theincident !== undefined) {
      this.thisIncident = theincident;
      this.initIncidentLevel();
    }
  }
  get caseIncident(): CaseIncident {
    return this.thisIncident;
  }

  private thisPlannedTravel: PlannedTravel;
  private thisEndUser: CaseStakeholder;
  private thisIncident: CaseIncident;
  private user: UserDto;
  nonShowNationalId: string;
  theShownNationalId: string;
  showEye: boolean;
  calculatedRemainingDays: any;
  accomodationList: Map<number, string>;
  meansOfTransportation: Map<number, string>;
  incidentCausesLevel1: CausesLevel1DD[];
  incidentLevel: string;
  $loggedUserSubscr: Subscription;

  constructor(private nationalIdService: NationalIdService,
              private dictionaryService: DictionaryService,
              private auditlogService: AuditlogService,
              private incidentService: IncidentService) { }

  ngOnInit() {
    this.$loggedUserSubscr = this.dictionaryService.getLoggedInUser().subscribe(user => {
      if (user != null) {
        this.user = user;
      }
    });

    this.incidentService.getIncidentCauses().subscribe(
      result => {
        this.incidentCausesLevel1 = result;
        this.initIncidentLevel();
      }
    );
  }

  public onStakeholderLoad() {
    this.nonShowNationalId = '';
    this.theShownNationalId = '';
    this.showEye = false;
    if (this.endUser.person.nationalIdValid) {
      this.showEye = true;
      this.nonShowNationalId = this.nationalIdService.getMaskedId(this.endUser.person.nationalIdCountry.code, this.endUser.person.nationalId);
      this.theShownNationalId = this.nonShowNationalId;
    } else {
      this.showEye = false;
      this.theShownNationalId = this.endUser.person.nationalId;
      this.nonShowNationalId = this.endUser.person.nationalId;
    }
  }

  public onPlannedTravelLoad() {
    if (this.plannedTravel.arrivalDate !== null) {
      this.calculatedRemainingDays = Math.floor(moment.duration(moment(this.plannedTravel.arrivalDate).diff(moment())).asDays());
    } else {
      this.calculatedRemainingDays = '';
    }
  }

  showNationalIdClick() {
    if (this.theShownNationalId === this.nonShowNationalId) {
      this.theShownNationalId = this.endUser.person.nationalId;
      const logText = 'Case: ' + this.caseID + '. ' + this.user.initials.toUpperCase() + ' has viewed end-user NATIONAL ID in medical page.';
      this.auditlogService.createAuditlog(logText).subscribe(
        result => (result), error => console.log(error));
    } else {
      this.theShownNationalId = this.nonShowNationalId;
    }
  }

  getGenderTypes(value: string) {
    return MedicalService.genderTypes.get(value);
  }

  
  private initIncidentLevel(): void {
    if ( this.incidentCausesLevel1 && this.thisIncident ) {
      const causeLevel1 = this.incidentCausesLevel1.find(level1 => level1.id === this.thisIncident.causeLevel1Code);
      const causeLevel2 = causeLevel1?.level2Causes?.find(level2 => level2.id === this.thisIncident.causeLevel2Code);
      const causeLevel3 = causeLevel2?.level3Causes?.find(level3 => level3.id === this.thisIncident.causeLevel3Code);

      this.incidentLevel = (causeLevel1 ? causeLevel1.name : '') +
                           (causeLevel2 ? ('/' + causeLevel2.name) : '') +
                           (causeLevel3 ? ('/' + causeLevel3.name) : '');
    }
  }
}
