import { Component, OnInit, Input } from '@angular/core';
import { Duration } from '@secca/shared/models/duration';
import { Location } from '@secca/shared/models/location';
import { IncidentService } from '@secca/core/services/incident.service';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import * as _ from 'lodash-es';
import * as moment from 'moment-timezone';
import { ServiceOrderType } from '@secca/shared/models/service-order-type';
@Component({
  selector: 'app-durations',
  templateUrl: './durations.component.html',
  styleUrls: ['./durations.component.scss'],
})
@AutoUnsubscribe
export class DurationsComponent implements OnInit {

  @Input() incidentId: string;

  @Input() hideGOPDates: boolean;
  @Input() hideAdd: boolean;
  @Input() disableDeletion: false;
  @Input() gopDatesSemiRequired: false;

  @Input() disableStartDate: boolean;
  @Input() disableEndDate: boolean;
  @Input() disableLocation: boolean;
  @Input() altGopPeriodTitle: string;
  @Input() defaultStartDate: moment.Moment;
  @Input() defaultEndDate: moment.Moment;
  @Input() serviceOrderType: ServiceOrderType;

  @Input()
  public get durations(): Duration[] {
    return this._durations;
  }
  public set durations(durations: Duration[]) {
    this._durations2 = durations;
  }
  private _durations: Duration[];
  private _durations2: Duration[];
  private clonedLocation: Location;

  constructor(private incidentService: IncidentService) {}

  ngOnInit() {
    this.incidentService.getCaseIncident(+this.incidentId).subscribe(caseIncident => {
      if (caseIncident && this._durations2 && this._durations2.length && !this._durations2[0].location.freeText) {
        if (!!caseIncident.currentLocation.freeText) {
          this.clonedLocation = this.cloneLocation(caseIncident.currentLocation);
        } else {
          this.clonedLocation = this.cloneLocation(caseIncident.incidentLocation);
        }
        this._durations2[0].location = this.clonedLocation;
      }
      this._durations = this._durations2;

    });
  }

  addMore() {
    const newDuration = new Duration();

    let locationCloned: Location;
    if (this._durations[0] && this._durations[0].location) {
      locationCloned = this.cloneLocation(this._durations[0].location);
    } else {
      locationCloned = this.clonedLocation;
    }
    newDuration.location = locationCloned;
    if (newDuration.location && newDuration.location.timeZoneId) {
      newDuration.startDate =  this.defaultStartDate ?  this.defaultStartDate.clone().tz(newDuration.location.timeZoneId, true).utc() : moment.tz(newDuration.location.timeZoneId).startOf('day').utc();
      newDuration.endDate = this.defaultEndDate ? this.defaultEndDate.clone().tz(newDuration.location.timeZoneId, true).utc() : moment.tz(newDuration.location.timeZoneId).endOf('day').utc();
    } else {
      newDuration.startDate =  this.defaultStartDate ? this.defaultStartDate : moment.utc().startOf('day').utc();
      newDuration.endDate = this.defaultEndDate ? this.defaultEndDate : moment.utc().endOf('day').utc();
    }

    this._durations.push(newDuration);
  }

  deleteable(index): boolean {
    return !this.disableDeletion && index !== 0;
  }

  gopSemiRequired(index): boolean {
    return this.gopDatesSemiRequired && index !== 0;
  }

  deleteDuration(duration: Duration) {
    const index = this.durations.findIndex(d => d === duration);
    this.durations.splice(index, 1);
  }

  private cloneLocation(location: Location): Location{
    const newLocation: Location = _.clone(location);
    newLocation.id = null;
    return newLocation;
  }
}
