<div class="card">
  <div class="sub-component-placeholder">
    <div class="sub-component-container col-12">
      <div class="sub-title">{{ 'luggage-advice-given-title' | translate }}</div>
      <app-text-box
              [name]="'luggage-advice-given-label' | translate"
              [maxTextLength]="1000"
              [required]="false"
              [(value)]="luggage.adviceGiven">
      </app-text-box>
    </div>
  </div>
  <div class="sub-component-placeholder">
    <div class="col-5 p-0">
      <app-sub-commitment-type
        [(commitmentType)]="luggage.commitmentType"
        [serviceOrderType]="serviceOrderType" [status]="status"
        [caseId]="caseId"
        ></app-sub-commitment-type>
      </div>
  </div>
  <div class="sub-component-placeholder">
    <app-sub-luggage-reference [(subLuggageReference)]="luggage.luggageReference"></app-sub-luggage-reference>
  </div>
  <div class="sub-component-placeholder">
    <div class="col-5">
      <app-input-date-time
              [name]="'luggage-start-date' | translate"
              [date]="luggage.startDate" (dateChange)="startDateChange($event)"
              [required]="true"
              [withImage]="true"
              [timeZone]="'secca'">
      </app-input-date-time>
    </div>
  </div>
</div>
