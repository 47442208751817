import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RangeSupport } from '@secca/shared/components/input-date-time/range-support';
import { PersonInsurance } from '@secca/shared/models/person-insurance';
import { CoverageChangeService } from '@secca/case/components/case-basics/case-basics-insurance/coverage-change-service';
import { InsuranceService } from '@secca/core/services/insurance.service';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import * as moment from 'moment';
import * as _ from 'lodash-es';

@Component({
  selector: 'app-case-basics-insurance-dates',
  templateUrl: './case-basics-insurance-dates.component.html',
  styleUrls: ['./case-basics-insurance-dates.component.scss']
})
@AutoUnsubscribe
export class CaseBasicsInsuranceDatesComponent implements OnInit {
  @Input() set personInsurance(value: PersonInsurance) {
    this._personInsurance = value;
    if (value?.seccaCaseId) {
      this.getCustomerProductInfo();
    }
    this.initializeRangeSupport();
    this.setCalculatedWeeks();
  }
  get personInsurance() {
    return this._personInsurance;
  }
  private _personInsurance;
  @Output() updateInsurance = new EventEmitter();
  insurancePeriodRangeSupport: RangeSupport;
  public coveragePeriodType: string;
  private productIdForPeriodType: number;

  constructor(private coverageChangeService: CoverageChangeService,
              private insuranceService: InsuranceService) { }

  ngOnInit(): void {
    this.initializeRangeSupport();
    this.coverageChangeService.change.subscribe(
      result => this.setCoveragePeriod(result)
    );
  }

  private initializeRangeSupport() {
    if (this._personInsurance && !this.insurancePeriodRangeSupport) {
      this.insurancePeriodRangeSupport = new RangeSupport(
        this._personInsurance.insurancePeriodFrom,
        this._personInsurance.insurancePeriodTo,
        this,
        this.updateInsuranceFromDate,
        this.updateInsuranceToDate,
        null
      );
    }
  }

  updateInsuranceFromDate(insuranceFromDate: moment.Moment, insuranceToDate: moment.Moment) {
    this._personInsurance.insurancePeriodFrom = insuranceFromDate;
    this._personInsurance.insurancePeriodTo = insuranceToDate;
    this.updateInsurance.emit();
  }

  updateInsuranceToDate(insuranceToDate: moment.Moment, insuranceFromDate: moment.Moment) {
    this._personInsurance.insurancePeriodTo = insuranceToDate;
    this._personInsurance.insurancePeriodFrom = insuranceFromDate;
    this.updateInsurance.emit();
  }

  calculateWeeks() {
    this.setCalculatedWeeks();
    this.updateInsurance.emit('Coverage');
  }

  isDaysDisabled() {
    if (this.isDaysAndWeeksEnabled()) {
      return false;
    }
    return !this._personInsurance.coverageWeeksCalculated;
  }

  isWeeksDisabled() {
    if (this.isDaysAndWeeksEnabled()) {
      return false;
    }
    return !!this._personInsurance.coverageWeeksCalculated;
  }

  isDaysAndWeeksEnabled() {
    if (_.isEmpty(this._personInsurance.coverageDays) && _.isEmpty(this._personInsurance.coverageWeeks)) {
      return true;
    }
    if (this._personInsurance.coverageWeeksCalculated && _.isEmpty(this._personInsurance.coverageDays)) {
      return true;
    }
    if (!this._personInsurance.coverageWeeksCalculated && _.isEmpty(this._personInsurance.coverageWeeks)) {
      return true;
    }
    return false;
  }

  setCalculatedWeeks() {
    this._personInsurance.coverageWeeksCalculated = true;
    if (this._personInsurance.coverageDays == null || _.isEmpty(this._personInsurance.coverageDays)) {
      this._personInsurance.coverageWeeks = '';
      return;
    }
    const days = parseInt(this._personInsurance.coverageDays, 10);
    const weeks = Math.floor(days / 7);
    const rest = days - weeks * 7;
    this._personInsurance.coverageDays = String(days) + ' day(s)';
    this._personInsurance.coverageWeeks = weeks + ' week(s) + ' + rest + ' day(s)';
  }

  setCoveragePeriod(product: any) {
    if (!!product.coveragePeriodValue && (this._personInsurance.insuranceLookupPolicy == null || !this._personInsurance.insuranceLookupPolicy.product.coveragePeriodFromLookup)) {
      if (product.coveragePeriodUnit === 'MONTHS') {
        this._personInsurance.coverageDays = (product.coveragePeriodValue * 30).toString();
      } else if (product.coveragePeriodUnit === 'WEEKS') {
        this._personInsurance.coverageDays = (product.coveragePeriodValue * 7).toString();
      } else {
        this._personInsurance.coverageDays = product.coveragePeriodValue.toString();
      }
      this._personInsurance.coveragePeriodFromLookup = false;
      this.setCalculatedWeeks();
    }
  }

  calculateDays() {
    this.personInsurance.coverageWeeksCalculated = false;
    if (this.personInsurance.coverageWeeks == null || _.isEmpty(this.personInsurance.coverageWeeks)) {
      this.personInsurance.coverageDays = 'Coverage';
      return;
    }
    const weeks = parseInt(this.personInsurance.coverageWeeks, 10);
    let days = 0;
    if (this.personInsurance.coverageWeeks.indexOf('+') > 0) {
      days = parseInt(this.personInsurance.coverageWeeks.replace(/.*[+]/, ''), 10);
    }
    this.personInsurance.coverageDays = String(weeks * 7 + days) + ' day(s)';
    this.personInsurance.coverageWeeks = weeks + ' week(s) + ' + days + ' day(s)';
    this.updateInsurance.emit('Coverage');
  }

  private getCustomerProductInfo() {
    if (this.personInsurance?.customerProduct && this.productIdForPeriodType !== this.personInsurance.customerProduct) {
      this.productIdForPeriodType = this.personInsurance.customerProduct;
      this.insuranceService.getCustomerProductInfo(this._personInsurance.seccaCaseId).subscribe(
        result => {
          this.coveragePeriodType = result.productCoveragePeriodType;
        }
      );
    }
  }
}
