<div class="d-flex" [class.even]="index % 2">
    <div class="history-table-cell align-top">
            <app-user-picture [imageURL]="entry.picture" [active]="'true'" [imageClass]="'caseSummery'">
            </app-user-picture>
            <span class="headline initials" *ngIf="!entry.previousValue">{{entry.initials}} {{ 'case-history-economy-customer-invoice-created' | translate }}
                {{entry.newValue.id
                }}</span>
            <span class="headline initials" *ngIf="entry.previousValue  && !entry.newValue.deletedOn">{{entry.initials}} {{ 'case-history-economy-customer-invoice-updated' | translate }} {{entry.newValue.id
                }}</span>
                <span class="headline initials" *ngIf="entry.previousValue && entry.newValue.deletedOn">
                    {{entry.user?.initials}} {{ 'case-history-economy-customer-invoice-deleted' | translate }} {{entry.newValue.id}}
                </span>

            <div class="elementDateTime">{{ entry.newValue.modifiedOn | date: 'd MMM y - HH:mm' }}</div>
        </div>
        <div class="history-table-cell align-top">
            <div class="headline">
                <span *ngIf="entry.previousValue">
                    {{ 'case-history-list-original-value' | translate }}
                </span>
            </div>
            <app-previous-value [entry]="entry" [attribute]="'debtor'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'salesOrderNumber'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'erpClient'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'status'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'invoiceDate'" [display]="'date'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'erpSentDate'" [display]="'date'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'invoicehandlingNote'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'invoiceDocumentId'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'customerInvoiceNote'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'customerInvoiceExclVat'" [display]="'currency'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'customerInvoiceInclVat'" [display]="'currency'"></app-previous-value>

        </div>
        <div class="history-table-cell align-top">
            <div class="headline">
                <span *ngIf="true">
                    {{ 'case-history-list-new-value' | translate }}
                </span>
            </div>
            <app-new-value [entry]="entry" [showAll]="statusChanged" [attribute]="'debtor'"></app-new-value>
            <app-new-value [entry]="entry" [showAll]="statusChanged" [attribute]="'salesOrderNumber'"></app-new-value>
            <app-new-value [entry]="entry" [showAll]="statusChanged" [attribute]="'erpClient'"></app-new-value>
            <app-new-value [entry]="entry" [showAll]="statusChanged" [attribute]="'status'"></app-new-value>
            <app-new-value [entry]="entry" [showAll]="statusChanged" [attribute]="'invoiceDate'" [display]="'date'"></app-new-value>
            <app-new-value [entry]="entry" [showAll]="statusChanged" [attribute]="'erpSentDate'" [display]="'date'"></app-new-value>
            <app-new-value [entry]="entry" [showAll]="statusChanged" [attribute]="'invoicehandlingNote'"></app-new-value>
            <app-new-value [entry]="entry" [showAll]="statusChanged" [attribute]="'invoiceDocumentId'"></app-new-value>
            <app-new-value [entry]="entry" [showAll]="statusChanged" [attribute]="'customerInvoiceNote'"></app-new-value>
            <app-new-value [entry]="entry" [showAll]="statusChanged" [attribute]="'customerInvoiceExclVat'" [display]="'currency'"></app-new-value>
            <app-new-value [entry]="entry" [showAll]="statusChanged" [attribute]="'customerInvoiceInclVat'" [display]="'currency'"></app-new-value>

        </div>
    </div>
