import { Component, Inject, ViewChild } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { DialogBoundryService } from "@secca/core/services/dialog-boundry.service";
import { DialogHelperUtilService } from "@secca/core/services/dialog-helper-util.service";
import { DialogStateAction, MinimizableNotifData } from "@secca/core/state-services/dialog-state.service";
import { DialogMinimizableDirective } from "@secca/shared/directives/dialog-minimizable.directive";
import { DialogSelectableDirective } from "@secca/shared/directives/dialog-selectable.directive";
import { MinimizableDialogType } from "@secca/shared/enums/minimizable-dialog-type-enum";
import { IAdditionalInfo, ServicePlanAdditionalInfo } from "@secca/shared/interfaces/additional-info";
import { IFocusEnabled } from "@secca/shared/interfaces/focus-enabled";
import { IKeyboardEnabled } from "@secca/shared/interfaces/keyboard-enabled";
import { IMinimizable } from "@secca/shared/interfaces/minimizable";
import { ServiceTypeEnum } from "@secca/shared/models/enums";
import { Subscription } from "rxjs";
import { AddServiceOrderComponent } from "../add-service-order.component";
import { AddServiceOrderDialogData } from "./add-service-order-dialog-data.component";

@Component({
    selector: 'app-add-service-order-dialog',
    templateUrl: './add-service-order-dialog.component.html',
    styleUrls: ['./add-service-order-dialog.component.scss']
  })
  export class AddServiceOrderDialogComponent implements IAdditionalInfo, IMinimizable, IKeyboardEnabled, IFocusEnabled {
    @ViewChild(AddServiceOrderComponent) wrappedInstance: AddServiceOrderComponent;
    @ViewChild(DialogMinimizableDirective) minimizedDirective: DialogMinimizableDirective;
    @ViewChild(DialogSelectableDirective) selectableDirective: DialogSelectableDirective;

    data: AddServiceOrderDialogData;
    dialogStateChangeSubscr: Subscription;

    constructor(private dialogRef: MatDialogRef<AddServiceOrderDialogComponent>,
                @Inject(MAT_DIALOG_DATA) data: AddServiceOrderDialogData,
                private dialogHelperUtilService: DialogHelperUtilService,
                public dialogBoundryService: DialogBoundryService) {

      this.data = data;
    }

    ngAfterViewInit() {
      this.dialogStateChangeSubscr = this.minimizedDirective.dialogStateChange.subscribe((data: MinimizableNotifData) => {
        if ( data.action === DialogStateAction.MAXIMIZE ) {
          this.wrappedInstance.loadStakeholders();
        }
      });
      this.dialogStateChangeSubscr = this.selectableDirective.dialogStateChange.subscribe(() => {
          this.wrappedInstance.loadStakeholders();
      });

    }

    close(): void {
      this.dialogHelperUtilService.close(this.dialogRef, this.getAdditionalInfo()?.caseId, true);
    }

    keyboardClose(): void {
      this.wrappedInstance.close();
    }

    minimize(): void {
      this.minimizedDirective.minimize();
    }

    getMinimizeType(): MinimizableDialogType {
      return this.minimizedDirective.appDialogMinimizable;
    }

    getAdditionalInfo(): ServicePlanAdditionalInfo {
      const caseId: number = this.wrappedInstance?.selectedServiceOrder?.caseId;
      const caseNumber: string = this.data?.caseNumber;
      const servicePlanType: ServiceTypeEnum = this.wrappedInstance?.selectedServiceOrder?.type;
      const serviceOrderId: string = this.wrappedInstance?.selectedServiceOrder?.serviceOrderId;

      return {
        caseId,
        hoverTitle: caseNumber || '',
        hoverText: serviceOrderId || '',
        servicePlanType,
        serviceOrderId: this.wrappedInstance?.serviceOrderId
      };
    }

    getFocusElement(): any {
      return this.selectableDirective?.focusElement;
    }

    get MinimizableDialogType() {
      return MinimizableDialogType;
    }
}
