import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import { MedicalAssessmentStateService } from '@secca/core/services/medical-assessment-state.service';
import { TranslateService } from '@ngx-translate/core';
import { RepatriationFormEnum } from '@secca/shared/models/enums';
import { Component, Input, OnInit } from '@angular/core';
import { MedicalAssessmentRepatriationPrognosis } from '@secca/shared/models/medical-models/repatriation-prognosis';
import { MedicalAssessmentState } from '@secca/case/components/case-medical/medical-components/medical-models';
import * as moment from 'moment';
import { DateHelperService } from '@secca/core/services/date-helper.service';

@Component({
  selector: 'app-repatriation-prognosis',
  templateUrl: './repatriation-prognosis.component.html',
  styleUrls: ['./repatriation-prognosis.component.scss']
})
export class RepatriationPrognosisComponent implements OnInit {
  @Input() repatriationPrognosis: MedicalAssessmentRepatriationPrognosis;
  @Input() state: MedicalAssessmentState;
  @Input() disabledViewOfMedicalAssessment: boolean;
  repatriationForms: DropdownDictionary[];

  expectedEndPeriod: moment.Moment;
  today: moment.Moment = moment.utc();

  constructor(
    private assessmentSateService: MedicalAssessmentStateService,
    private translateService: TranslateService,
    private dateHelperService: DateHelperService) {
  }

  ngOnInit() {
    this.repatriationForms = this.getDropdownListFromEnum(RepatriationFormEnum, 'medical-assessment-repatriation-form-');
    if ( this.repatriationPrognosis.date) {
      this.repatriationPrognosis.date = moment.utc( this.repatriationPrognosis.date, this.dateHelperService.getDateFormat());
    }
  }

  private getDropdownListFromEnum(enumItem: any, prefix: string) {
    return Object.keys(enumItem)
      .map(key => new DropdownDictionary(enumItem[key],
        this.translateService.instant(prefix + key.toString().toLowerCase().replace(/_/g, '-'))));
  }

  updateDate($event) {
    this.repatriationPrognosis.date = $event;
  }

  get repatriationFormEnum() {
    return RepatriationFormEnum;
  }
}
