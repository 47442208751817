import { SearchUser } from './searchUser';
export class SearchUserAndTeam extends SearchUser {
  isTeam: boolean;
  adtCode: string;
  teamName: string;
  teamNameAlt: string;

  public constructor(init?: Partial<SearchUserAndTeam>) {
    super(init);
    Object.assign(this, init);
  }
  public initializeFromSearchUser(searchUser: SearchUser) {
    this.department = searchUser.department;
    this.initials = searchUser.initials;
    this.picture = searchUser.picture;
    this.team = searchUser.team;
    this.fullUserName = searchUser.fullUserName;
    this.id = searchUser.id;
    this.isTeam = false;
  }
}
