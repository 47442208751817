import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-case-basics-incident',
  templateUrl: './case-basics-incident.component.html',
  styleUrls: ['./case-basics-incident.component.scss']
})

export class CaseBasicsIncidentComponent implements OnInit {

  @Input() caseId = '';
  eventName: String = '';

  constructor() { }

  ngOnInit() { }

  updateEventName(value: string) {
    this.eventName = value;
  }
}