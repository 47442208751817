import { Injectable } from '@angular/core';
import { Adapter } from '@secca/shared/interfaces/adapter';
import { TaskStatus, TaskType } from '@secca/shared/models/enums';
import { DateSerializer } from '@secca/shared/models/date-serializer';
import { AttachmentAdapter, AttachmentDto } from '@secca/shared/models/attachment-dto';
import * as moment from 'moment';
import {QuestionnaireDto, QuestionnaireDtoAdapter} from '@secca/shared/models/questionnaire-dto';

export class IncomingCasesTaskViewModel {
  id: number;
  caseId: number;
  caseNumber: string;
  isCheck: boolean;
  taskType: TaskType;
  status: TaskStatus;
  channelType: string;
  cause: string;
  dueDate: moment.Moment;
  doneDate: moment.Moment;
  receivedDate: moment.Moment;
  reporterFullName: string;
  timeZoneId: string;
  timeOffsetInHours: number;
  title: string;
  internalSummary: string;
  customerProfileId: number;
  customerProfileLegacy: boolean;
  caseLockedBy: string;
  assignInitials: string;
  location: string;
  data: any;
  caseValidated: boolean;
  attachments: AttachmentDto[];
  assignedReporterId: number;
  incidentDate: moment.Moment;
  customerProfileTeamName;
  customerProfileInfoId;
  medicalPreAssessmentId: number;
  questionnaire: QuestionnaireDto;
  departureDate: moment.Moment;

  public constructor(init?: Partial<IncomingCasesTaskViewModel>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
export class IncomingCasesTaskViewModelAdapter implements Adapter<IncomingCasesTaskViewModel> {

  constructor(private attachmentAdapter: AttachmentAdapter, private questionnaireDtoAdapter: QuestionnaireDtoAdapter) {
  }

  adapt(item: any): IncomingCasesTaskViewModel {
    return new IncomingCasesTaskViewModel({
      id: item.id,
      caseId: item.caseId,
      caseNumber: item.caseNumber,
      taskType: item.type,
      status: item.status,
      channelType: item.channelType,
      cause: item.cause,
      dueDate: item.dueDate ? DateSerializer.deserialize(item.dueDate) : null,
      doneDate: item.doneDate ? DateSerializer.deserialize(item.doneDate) : null,
      receivedDate: item.receivedDate === '' ? null : DateSerializer.deserialize(item.receivedDate),
      departureDate: item.departureDate ? DateSerializer.deserialize(item.departureDate) : null,
      reporterFullName: item.reporter,
      title: item.title,
      internalSummary: item.internalSummary,
      customerProfileId: item.customerProfileId == null ? null : +item.customerProfileId,
      caseLockedBy: item.caseLockedBy,
      assignInitials: item.assignInitials,
      location: item.location,
      timeOffsetInHours: item.timeOffsetInHours,
      timeZoneId: item.timeZoneId,
      data: JSON.parse(item.data),
      caseValidated: item.caseValidated,
      attachments: item.attachments ? item.attachments.map(attachment =>
        this.attachmentAdapter.adapt({documentId: attachment.id,
                                      documentName: attachment.attachmentName,
                                      documentTags: attachment.documentTags,
                                      fileScan: attachment.fileScan
        })) : [],
      customerProfileLegacy: item.customerProfileLegacy,
      assignedReporterId: item.assignedReporterId,
      incidentDate: item.origIncidentDate ? DateSerializer.deserialize(item.origIncidentDate) : (item.incidentDate ? DateSerializer.deserialize(item.incidentDate) : null),
      customerProfileTeamName: item.customerProfileTeamName,
      customerProfileInfoId: item.customerProfileInfoId,
      medicalPreAssessmentId: item.medicalPreAssessmentId,
      questionnaire: item.questionnaire ? item.questionnaire.map(questionnaire =>
        this.questionnaireDtoAdapter.adapt({diagnosisKey: questionnaire.diagnosisKey,
                                              diagnosis: questionnaire.diagnosis,
                                              questions: questionnaire.questions
        })) : []
    });
  }
}
