import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from '../../../../../core/services/base.service';
import { SettingsService } from '../../../../../core/services/settings.service';
import { CustomerInvoiceJobFilterRequest } from '../models/customer-invoice-job-filter-request';
import { CustomerInvoiceJobFilterResponse } from '../models/customer-invoice-job-filter-response';
import { CustomerInvoiceJobSalesOrderAdapter } from '../models/customer-invoice-job-sales-order';
import { CustomerInvoiceLastJob, CustomerInvoiceLastJobAdapter } from '../models/customer-invoice-last-job';

@Injectable({
  providedIn: 'root',
})
export class AutoCustomerInvoiceJobService extends BaseService {
  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private customerInvoiceLastJobAdapter: CustomerInvoiceLastJobAdapter,
    private customerInvoiceJobSalesOrderAdapter: CustomerInvoiceJobSalesOrderAdapter
  ) {
    super(settingsService);
  }

  getLastCustomerInvoiceJobDate(): Observable<CustomerInvoiceLastJob> {
    return this.http
      .get<CustomerInvoiceLastJob>(this.baseURL + 'auto-invoice/last-execution-date', {
        headers: this.jsonHeaders,
      })
      .pipe(map(data => this.customerInvoiceLastJobAdapter.adapt(data)));
  }

  getFilterResponse(filterRequest: CustomerInvoiceJobFilterRequest): Observable<CustomerInvoiceJobFilterResponse> {
    filterRequest.salesOrderCreationFrom = !!filterRequest.salesOrderCreationRange[0]
      ? moment.utc(filterRequest.salesOrderCreationRange[0])
      : null;
    filterRequest.salesOrderCreationTo = !!filterRequest.salesOrderCreationRange[1]
      ? moment.utc(filterRequest.salesOrderCreationRange[1])
      : null;
    filterRequest.invoiceDateFrom = !!filterRequest.invoiceDateRange[0] ? moment.utc(filterRequest.invoiceDateRange[0]) : null;
    filterRequest.invoiceDateTo = !!filterRequest.invoiceDateRange[1] ? moment.utc(filterRequest.invoiceDateRange[1]) : null;
    filterRequest.invoiceJobDateFrom = !!filterRequest.invoiceJobDateRange[0] ? moment.utc(filterRequest.invoiceJobDateRange[0]) : null;
    filterRequest.invoiceJobDateTo = !!filterRequest.invoiceJobDateRange[1] ? moment.utc(filterRequest.invoiceJobDateRange[1]) : null;
    return this.http
      .post<CustomerInvoiceJobFilterResponse>(this.baseURL + 'auto-invoice/sales-orders', filterRequest, {
        headers: this.jsonHeaders,
      })
      .pipe(
        map(
          (data: CustomerInvoiceJobFilterResponse) =>
            new CustomerInvoiceJobFilterResponse({
              jobSalesOrders: data.jobSalesOrders.map(taskViewModel => this.customerInvoiceJobSalesOrderAdapter.adapt(taskViewModel)),
              totalNumber: data.totalNumber,
            })
        )
      );
  }

  getDetailsResponse(filterRequest: CustomerInvoiceJobFilterRequest): Observable<CustomerInvoiceJobFilterResponse> {
    return this.http
      .post<CustomerInvoiceJobFilterResponse>(this.baseURL + 'auto-invoice/sales-orders/details', filterRequest, {
        headers: this.jsonHeaders,
      })
      .pipe(
        map(
          (data: CustomerInvoiceJobFilterResponse) =>
            new CustomerInvoiceJobFilterResponse({
              jobSalesOrders: data.jobSalesOrders.map(taskViewModel => this.customerInvoiceJobSalesOrderAdapter.adapt(taskViewModel)),
              totalNumber: data.totalNumber,
            })
        )
      );
  }
}
