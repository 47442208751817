import { Component, Inject, ViewChild } from '@angular/core';
import { IMinimizable } from '@secca/shared/interfaces/minimizable';
import { IKeyboardEnabled } from '@secca/shared/interfaces/keyboard-enabled';
import { IFocusEnabled } from '@secca/shared/interfaces/focus-enabled';

import { DialogMinimizableDirective } from '@secca/shared/directives/dialog-minimizable.directive';
import { MinimizableDialogType } from '@secca/shared/enums/minimizable-dialog-type-enum';
import { DialogSelectableDirective } from '@secca/shared/directives/dialog-selectable.directive';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DialogBoundryService } from '@secca/core/services/dialog-boundry.service';
import { DialogHelperUtilService } from '@secca/core/services/dialog-helper-util.service';
import {
  CountryInfoComponent
} from '@secca/case/components/case-country-info/case-country-info-dialog/country-info/country-info.component';
import {
  CaseCountryInfoDialogInitData
} from '@secca/case/components/case-country-info/case-country-info-dialog/case-country-info-dialog-init-data';
import { AdditionalInfo, IAdditionalInfo } from '@secca/shared/interfaces/additional-info';

@Component({
  selector: 'app-case-country-info-dialog',
  templateUrl: './case-country-info-dialog.component.html',
  styleUrls: ['./case-country-info-dialog.component.scss']
})
export class CaseCountryInfoDialogComponent implements IAdditionalInfo, IMinimizable, IKeyboardEnabled, IFocusEnabled {

  constructor(private dialogRef: MatDialogRef<CaseCountryInfoDialogComponent>,
              public dialogBoundryService: DialogBoundryService,
              private dialogHelperUtilService: DialogHelperUtilService,
              @Inject(MAT_DIALOG_DATA) data: CaseCountryInfoDialogInitData) {
    this.data = data;
  }

  get countryCode() {
    if (this.data?.countryCode) {
      return this.data.countryCode;
    } else {
      console.error('countryCode is not defined!');
      return 'ERROR';
    }
  }
  get country() {
    if (this.data?.country) {
      return this.data.country;
    } else {
      console.error('country is not defined!');
      return 'ERROR';
    }
  }
  @ViewChild(DialogMinimizableDirective) minimizedDirective: DialogMinimizableDirective;
  @ViewChild(DialogSelectableDirective) selectableDirective: DialogSelectableDirective;
  @ViewChild(CountryInfoComponent) wrappedInstance: CountryInfoComponent;

  data: CaseCountryInfoDialogInitData;

  protected readonly MinimizableDialogType = MinimizableDialogType;

  getFocusElement() {
    return this.selectableDirective?.focusElement;
  }

  keyboardClose(): void {
    this.wrappedInstance.close();
  }

  minimize(): void {
    this.minimizedDirective.minimize();
  }

  getMinimizeType(): MinimizableDialogType {
    return this.minimizedDirective.appDialogMinimizable;
  }

  getAdditionalInfo(): AdditionalInfo {
    return {
      caseId: this.data.caseId,
      hoverText: this.data.country,
      hoverTitle: this.data.caseNumber || ''
    };
  }

  close() {
    this.dialogHelperUtilService.close(this.dialogRef, this.data.caseId );
  }
}
