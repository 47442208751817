<ng-template #content let-modal>
  <div class="edit-customer-invoice-delete">
    <div class="edit-customer-invoice-delete-header">
      <div class="edit-customer-invoice-delete-header-close float-end clearfix">
        <img (click)="closeModal()" src="../../../../../../assets/icons/Close.svg" alt="Close" />
      </div>
      <div class="edit-customer-invoice-delete-header-title">
        {{ 'case-economy-customer-invoices-delete' | translate }}
      </div>
    </div>
    <div class="edit-customer-invoice-delete-body">
      <app-text-box
        [maxTextLength]="250"
        [name]="'case-economy-sos-services-modify-comment' | translate"
        [(value)]="actionComment"
      ></app-text-box>
    </div>
    <div class="modal-footer">
      <div class="d-flex justify-content-end">
        <button (click)="deleteSalesOrder()" [disabled]="saveInProgress">
          {{ 'case-economy-sos-services-modify-save' | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>
