import { ServiceOrder } from '@secca/shared/models/service-order/service-order';
import { Message } from '@secca/case/components/case-output-management/models/message';
import {MessageService} from '@secca/core/services/message.service';

export class ServiceOrderMessageRequest {
  serviceOrder: ServiceOrder;
  message: Message;
  messageService: MessageService;
  isConsent: boolean;

  constructor(init?: Partial<ServiceOrderMessageRequest>) {
    Object.assign(this, init);
  }
}
