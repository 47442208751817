import {CaseHistoryEntry} from '../../../../../../shared/models/caseHistoryEntry';
import {Component, Input, OnInit} from '@angular/core';
import {CaseLockHelperService} from '@secca/core/services/case-lock-helper.service';
import {PermissionService} from '@secca/core/services/permission.service';
import {CaseDialogViewerService} from '@secca/case/case-dialog-viewer.service';
import {DigitalCaseflowService} from "@secca/core/services/digital-caseflow.service";

@Component({
  selector: 'app-case-summary-digital-caseflow',
  templateUrl: './case-summary-digital-caseflow.component.html',
  styleUrls: ['./case-summary-digital-caseflow.component.scss'],
})
export class CaseSummaryDigitalCaseflowComponent implements OnInit {
  @Input() entry: CaseHistoryEntry;
  showEntryMenu: boolean;

  constructor(
    public caseLockHelperService: CaseLockHelperService,
    public permissionService: PermissionService,
    public digitalCaseflowService: DigitalCaseflowService,
    private dialogViewerService: CaseDialogViewerService
  ) {
  }

  ngOnInit() {

  }

  viewMenuClick() {
    this.digitalCaseflowService.getDigitalCaseflowLog(this.entry.digitalCaseflowLogId).subscribe(
      result => {
        console.log(result);
        this.dialogViewerService.openCaseSummaryDigitalCaseflowDialog(result, this.entry.caseId);
      },
      error => console.log(error)
    );

  }




}
