import { ValidationTypeEnum } from './../../models/enums';
import { Component, OnInit, Input, EventEmitter, Output, ViewEncapsulation, ViewChild } from '@angular/core';
import { CustomerProfile } from '../../models/customerProfile';
import { CaseService } from 'src/app/core/services/case.service';
import { DictionaryService } from './../../../core/services/dictionary.service';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-drop-down-customer-profile',
  templateUrl: './drop-down-customer-profile.component.html',
  styleUrls: ['./drop-down-customer-profile.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DropDownCustomerProfileComponent implements OnInit {
  @ViewChild('ngselectcomp') ngSelectComponent: NgSelectComponent;
  @Input() name: string;
  @Input() disabled: boolean;
  @Input() selectedItemId: any;
  @Input() value: string;
  @Input() items: CustomerProfile[];
  @Input() validationType: ValidationTypeEnum;
  @Input() useAsSearchParameter: boolean;
  @Input() blankTitle: string;
  @Input() caseId: string;
  @Input() highlightBorderOnInputNonEmpty = false;
  @Output() saveModel = new EventEmitter();
  @Output() selectedItemIdChange = new EventEmitter();
  @Output() open = new EventEmitter();  

  oldSelectedItemId: string;
  oldValue: string;
  profileImageUrl: string;
  teams: any;

  constructor(private caseService: CaseService, private dictionaryService: DictionaryService) {}

  ngOnInit() {
    this.dictionaryService.getAllTeamsList().subscribe(result => {
      if (result != null) {
        this.teams = result;
      }
    });
  }

  onSaveModel() {
    this.saveModel.emit();
  }

  onSaveModelDropDown() {
    if (this.oldSelectedItemId !== this.selectedItemId) {
      this.onSaveModel();
    }
  }

  onSaveModelInput() {
    if (this.oldValue !== this.value) {
      this.onSaveModel();
    }
  }

  onSaveStateDropDown() {
    this.oldSelectedItemId = this.selectedItemId;
  }

  onSelectedItemIdChange() {
    this.selectedItemIdChange.emit(this.selectedItemId);
  }

  onSaveStateInput() {
    this.oldValue = this.value;
  }

  onOpen() {
    this.open.emit();
  }

  getProfileImagesUrl(value: string) {
    return this.caseService.getBrandImageUrl(value);
  }

  getTeamName(value: string) {
    if (this.teams == null) {
      return '';
    }

    return this.teams.find(x => x.adtCode === value).teamName;
  }

  setFocus() {
    this.ngSelectComponent.focus();
  }

  close() {
    this.ngSelectComponent.close();
  }

  get validationClass() {
    switch (this.validationType) {
      case ValidationTypeEnum.required:
        if (this.selectedItemId == null || this.selectedItemId === '') {
          return 'required-drop-down-customer-profile';
        }
        break;
      case ValidationTypeEnum.empty:
        if (this.selectedItemId == null || this.selectedItemId === '') {
          return 'empty-drop-down-customer-profile';
        }
        break;
      default:
        return '';
    }
  }
}
