export enum MinimizableDialogType {
  Task = 'Task',
  ServicePlan = 'ServicePlan',
  SupplierInvoice = "SupplierInvoice",
  Claims = "Claims",
  CaseNew = "CaseNew",
  MedicalAssessment = "MedicalAssessment",
  MedicalPreAssessment = "MedicalPreAssessment",
  ProfileLookup = "ProfileLookup",
  PolicyLookup = "PolicyLookup",
  PolicyView = "PolicyView",
  CaseDocument = "CaseDocument",
  CaseSummaryEmailFax = "CaseSummaryEmailFax",
  CaseSummaryExternal = "CaseSummaryExternal",
  CaseSummarySms = "CaseSummarySms",
  CaseSummaryNote = "CaseSummaryNote",
  CaseFax = "CaseFax",
  CaseEmail = "CaseEmail",
  CaseSms = "CaseSms",
  CaseSummaryNoteLinkedCase = "CaseSummaryNoteLinkedCase",
  SearchProvider = "SearchProvider",
  StakeholderSupplierSearch = "StakeholderSupplierSearch",
  CaseEconomyRecoveryPayment = "CaseEconomyRecoveryPayment",
  CaseCountryInfo = "CaseCountryInfo"
}
