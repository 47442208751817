export class PhoneDto {
  countryCode: string;
  phonePrefix: string;
  phoneNumber: string;
  connectionCallId: string;

  public constructor(init?: Partial<PhoneDto>) {
    Object.assign(this, init);
  }
}
