<div class='d-flex'>
  <div [ngbPopover]='suggester' triggers='hover' placement='top-start'>
    <div *ngIf='filterList.size > 0' class='badge text-bg-primary m-2'>
      {{ Array.from(filterList)[0] | slice:0:10 }}{{ Array.from(filterList)[0].length > 10 ? '...' : '' }}
    </div>
  </div>
  <div *ngIf='filterList.size > 1' class='m-2'>
    {{ additionalCount }}
  </div>
</div>

<ng-template #suggester>
  {{ Array.from(filterList)[0]}}
</ng-template>



