import { Component, Inject, Input, LOCALE_ID, OnChanges, SimpleChanges } from '@angular/core';
import { ServiceTypeBase } from '../../models/serviceTypeBase';
import { ServiceTypeComponent } from '../../models/interfaces';
import { MajorIncidentServiceOrderExtension } from '@secca/shared/models/service-order/major-incident-service-order-extension';
import { ServiceOrderTypeState } from '../../models/serviceOrderTypeState';
import { MajorIncidentCategory, StatusTypes } from '@secca/shared/models/enums';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import { TranslateService } from '@ngx-translate/core';
import { MajorIncidentDetailsService } from '@secca/core/services/major-incident-details.service';
import { MajorIncidentCaseDetail } from '@secca/shared/models/Major-incident-case-details';
import { MajorIncidentStakeholder } from '../../models/majorIncidentStakeholder';
import { MasterListService } from '@secca/core/services/masterlist-service';
import { IncidentService } from '@secca/core/services/incident.service';
import { CaseIncident } from '@secca/shared/models/caseIncident';
import { MomentHelperService, SECCA_TIMEZONES } from '@secca/core/services/moment-helper.service';
import { formatDate } from '@angular/common';
import { saveAs } from 'file-saver-es';

@Component({
  selector: 'app-major-incident',
  templateUrl: './major-incident.component.html',
  styleUrls: ['./major-incident.component.scss']
})
export class MajorIncidentComponent extends ServiceTypeBase implements OnChanges, ServiceTypeComponent {

  exportRunning: boolean = false;
  @Input() caseId: string;
  @Input() serviceOrderTypeState: ServiceOrderTypeState;
  @Input() majorIncidentExtension: MajorIncidentServiceOrderExtension;
  @Input() leftSideValid: boolean;
  @Input() status: StatusTypes;
  @Input() incidentId: number;
  majorIncidentCategory: MajorIncidentCategory;
  majorIncidentCategoryList: DropdownDictionary[];
  majorIncidentCaseDetails: MajorIncidentCaseDetail[];
  incidentEventName: string;
  incident: CaseIncident;
  locationTimeZone: SECCA_TIMEZONES;
  @Inject(LOCALE_ID) private locale: string = 'en-DK';

  exportConfig = [
    { key: 'eventName', value: 'Event' },
    { key: 'category', value: 'Category' },
    { key: 'caseNumber', value: 'Case number' },
    { key: 'stakeholdeFirstname', value: 'Firstname' },
    { key: 'stakeholdeSurname', value: 'Lastname' },
    { key: 'dateOfBirth', value: 'Date of birth', formatter: (val: any) => this.dateTimeFormatter(val) },
    { key: 'location', value: 'Current location' },
  ];


  constructor(private translateService: TranslateService,
              private majorIncidentDetailsService: MajorIncidentDetailsService,
              private masterListService: MasterListService,
              private incidentService: IncidentService
  ) {
    super();
  }

  ngOnInit() {
    this.getCasesFromCaseWithEvent(this.caseId);
    this.majorIncidentCategoryList = this.getDropdownListFromEnum(MajorIncidentCategory, 'major-incident-service-order-category-');

    if(this.incidentId) {
      this.incidentService.getCaseIncident(this.incidentId).subscribe(result => {
        this.incident = result;
        this.masterListService.getIncidentEvents().subscribe(result => {
          result.forEach(event => {
            if(event.businessKey === this.incident.incidentEvent) {
              this.incidentEventName = event.name;
            }
          })
      });
    });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.status) {
      if ((changes.status.previousValue === StatusTypes.EXPECTED || changes.status.previousValue === StatusTypes.NOT_COVERED) && changes.status.currentValue === StatusTypes.COMMITTED) {
        this.status = StatusTypes.COMMITTED;
      }
    }
  }

  locationUpdate() {
    this.calcDepartureTimeZone();
  }

  private calcDepartureTimeZone() {
    this.locationTimeZone =  MomentHelperService.resolveTimeZone(this.majorIncidentExtension.location.timeZoneId);
  }

  private translate(str: string): string {
    return this.translateService.instant(str);
  }

  private dateTimeFormatter(date: Date): string | null {
    return date ? formatDate(date, 'dd MMM yyyy', this.locale, 'GMT+1') : '';
  }

  onExportButtonClicked() {
    if (this.exportRunning) {
      return;
    }

    if(this.majorIncidentCaseDetails?.length){
      this.exportRunning = true;
      this.exportCases();
    }
  }

  exportCases() {
    var excelInfo: ExcelInfo[] = [];
    this.majorIncidentExtension.expectedStakeholders.forEach(expectedStakeholder => {
      const stakeholderCase = this.majorIncidentCaseDetails.find(__case => __case.caseId.toString() == expectedStakeholder.caseId);
      const stakeholder = stakeholderCase.stakeholders.find(__stakeholder => __stakeholder.id === expectedStakeholder.stakeholderId);
      if(stakeholder) {
        excelInfo.push(new ExcelInfo({
            caseNumber: stakeholderCase.caseNumber,
            category: this.translateService.instant('major-incident-service-order-category-' + this.majorIncidentExtension.majorIncidentCategory),
            eventName: this.incidentEventName,
            location: stakeholderCase.destination.freeText,
            dateOfBirth: stakeholder.person && stakeholder.person.dateOfBirth ? stakeholder.person.dateOfBirth.toDate() : null,
            stakeholdeFirstname: stakeholder.person ? stakeholder.person.firstName : stakeholder.company.name,
            stakeholdeSurname: stakeholder.person ? stakeholder.person.surname : null
          }
        ));
      }
    })

    const replacer = (key: string, value: string) => (value === null ? '' : value);
    const header = this.exportConfig.map(item => this.translate(item.value));
    const csv = (excelInfo)
      .map(row =>
        this.exportConfig.map(item => JSON.stringify(item.formatter ? item.formatter(row[item.key]) : row[item.key], replacer)).join(';')
      );

    csv.unshift(header.join(';'));
    var csvArray = csv.join('\r\n');
    var BOM = "\uFEFF";
    csvArray = BOM + csvArray;
    const blob = new Blob([csvArray], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, 'all-major-incident-service-order-stkeholders.csv');
    this.exportRunning = false;
    console.log('done');
  }

  private getDropdownListFromEnum(enumItem: any, prefix: string) {
    return Object.keys(enumItem).map(
      key => new DropdownDictionary(enumItem[key], this.translateService.instant(prefix + key.toString()))
    );
  }

  isValidForChangingTheStatusToConfirmation(): boolean {
    return this.isValid();
  }

  isValid(): boolean {
    return !!this.majorIncidentExtension.majorIncidentCategory;
  }

  generateMissingFieldsText(): string[] {
    const fields: string[] = [];
    return fields;
  }

  startDateChange(date) {
    this.majorIncidentExtension.startDate = date;
  }

  getModel(): MajorIncidentServiceOrderExtension {
    return this.majorIncidentExtension;
  }

  getCasesFromCaseWithEvent(caseId: string) {
      this.majorIncidentDetailsService.getIncidentEventCasesDetails(caseId)
        .subscribe(eventCaseDetails => {
          this.majorIncidentCaseDetails = eventCaseDetails;
          this.majorIncidentCaseDetails.forEach(details => {
            if (this.majorIncidentExtension.subExpectedStakeholders.byCase(details.caseId.toString()).length == 0) {
              this.majorIncidentExtension.expectedStakeholders.push(new MajorIncidentStakeholder({caseId: details.caseId.toString(), stakeholderId: '0'}));
            }
          })
    });
  }

  isCommitted(): boolean {
    return this.status === StatusTypes.COMMITTED;
  }
}
export class ExcelInfo {
  caseNumber: string;
  category: string;
  eventName: string;
  dateOfBirth: Date;
  stakeholdeFirstname: string;
  stakeholdeSurname: string;
  // DOB: string;
  location: string;

  public constructor(init?: Partial<ExcelInfo>) {
    Object.assign(this, init);
  }
}
