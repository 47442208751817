import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ProviderService } from '@secca/core/services/providerService';
import { DocListItem } from '@secca/shared/models/SupplierSearchResponse';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { DictionaryService } from '@secca/core/services/dictionary.service';
import { combineLatest } from 'rxjs';
import {
  SupplierSearchData
} from '@secca/case/components/case-plans/case-plans/add-service-order/search-service-provider/SupplierDialogData';
import { StakeholderType } from '@secca/case/components/case-stakeholders/stakeholderType';
import { StakeholderService } from '@secca/core/services/stakeholder.service';
import {
  SupplierSearchHelper
} from '@secca/case/components/case-plans/case-plans/add-service-order/search-service-provider/search-service-provider-modal/supplier-search-helper.service';
import {
  PartnerService
} from '@secca/case/components/case-plans/case-plans/add-service-order/search-service-provider/search-service-provider-modal/partner.service';


@Component({
  selector: 'app-supplier-search-dialog',
  templateUrl: './supplier-search-dialog.component.html',
  styleUrls: ['./supplier-search-dialog.component.scss']
})
@AutoUnsubscribe
export class SupplierSearchDialogComponent implements OnChanges {

  constructor(
    private supplierService: ProviderService,
    private stakeholderService: StakeholderService,
    private dictionaryService: DictionaryService,
    private supplierSearchHelper: SupplierSearchHelper,
    private partnerService: PartnerService
  ) {
  }

  @Output()
  closeSearchSupplierModal = new EventEmitter<string>();
  @Input()
  supplierSearchData: SupplierSearchData;

  suppliers: DocListItem[] = [] as DocListItem[];
  isErrors: boolean = false;
  message: string = '';
  foundAnySuppliers: boolean = true;
  noDataFound: boolean = false;
  isLoading: boolean = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.supplierSearchData) {
      this.isLoading = true;
      this.findSupplier(this.supplierSearchData);
    }
  }

  close() {
    this.closeSearchSupplierModal.emit('close');
  }

  findSupplier(supplierSearchData: SupplierSearchData) {
    const supplierSearch: string = `freeText=supplierId:${supplierSearchData?.supplierId}`;
    const suppliersObservable = this.supplierService.searchSuppliers(supplierSearch);
    const stakeholderTypesObservable = this.dictionaryService.getStakeholdersTypes();

    combineLatest([suppliersObservable, stakeholderTypesObservable]).subscribe({
      next: ([res, stakeholderTypes]) => {
        if (res.numFound === 0) {
          this.isLoading = false;
          this.foundAnySuppliers = false;
          this.noDataFound = true;
          this.message = new Date().toLocaleDateString() + ', ' + new Date().toLocaleTimeString() + ', ' + supplierSearch;
          console.error('Error on fetching data', supplierSearch);
          return;
        }
        this.suppliers = res.docList;
        this.supplierSearchHelper.markSuppliersAsBlacklisted(this.suppliers);
        this.partnerService.setAssistantPartnerOnSupplier(this.suppliers, this.supplierSearchData.caseId);
        this.suppliers.forEach(supplier => {
          supplier.iconPath = this.findIconPathForStakeholder(stakeholderTypes, +supplier.supplierTypeCode)
          ;
        });
      },
      error: (error) => {
        this.isLoading = false;
        this.foundAnySuppliers = false;
        this.isErrors = true;
        this.message = new Date().toLocaleDateString() + ', ' + new Date().toLocaleTimeString() + ' ' + error.message;
        console.error('Error on fetching data', error);
      }
    });
  }

  private findIconPathForStakeholder(stakeholdersTypes: StakeholderType[], supplierTypeCode: number): string {
    const typeCode = supplierTypeCode;
    const stakeholderType = stakeholdersTypes.find(st => st.providerTypeId === typeCode);
    return this.stakeholderService.getStakeholderIconFullPath(stakeholderType?.stakeholderTypeEnum) || '/assets/icons/service-order-type/other.svg';
  }

}
