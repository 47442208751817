export enum CaseHistoryType {
  PHONE_NOTE_CREATE = 'PHONE_NOTE_CREATE',
  PHONE_NOTE_EDIT = 'PHONE_NOTE_EDIT',
  PHONE_NOTE_COPY = 'PHONE_NOTE_COPY',
  DOCUMENT_CREATE = 'DOCUMENT_CREATE',
  SMS = 'SMS',
  CASE_NOTE_CREATE = 'CASE_NOTE_CREATE',
  CASE_NOTE_EDIT = 'CASE_NOTE_EDIT',
  CASE_NOTE_COPY = 'CASE_NOTE_COPY',
  POLICY_LOOKUP = 'POLICY_LOOKUP',
  DATA_FIELD_INPUT = 'DATA_FIELD_INPUT',
  DATA_FIELD_UPDATE = 'DATA_FIELD_UPDATE',
  ITEM_DELETION = 'ITEM_DELETION',
  GDPR_ITEM_DELETION = 'GDPR_ITEM_DELETION',
  GDPR_ITEM_EDIT = 'GDPR_ITEM_EDIT',
  NATIONAL_ID_CHECK = 'NATIONAL_ID_CHECK',
  CASE_CREATE = 'CASE_CREATE',
  SERVICE_ORDER_CREATE = 'SERVICE_ORDER_CREATE',
  SCREENING_TOOL_CHANGE = 'SCREENING_TOOL_CHANGE',
  CHAT = 'CHAT',
  EMAIL = 'EMAIL',
  CUSTOMER_NOTE_CREATE = 'CUSTOMER_NOTE_CREATE',
  CONSENT = 'CONSENT',
  POLICY_SELECTED = 'POLICY_SELECTED',
  POLICY_REMOVED = 'POLICY_REMOVED',
  CUSTOMER_PRODUCT_ADDED = 'CUSTOMER_PRODUCT_ADDED',
  CUSTOMER_PRODUCT_UPDATED = 'CUSTOMER_PRODUCT_UPDATED',
  CASE_NUMBER_CREATE = 'CASE_NUMBER_CREATE',
  DOCUMENT_MOVE = 'DOCUMENT_MOVE',
  SMS_UPDATE = 'SMS_UPDATE',
  MEDICAL_ASSESSMENT_CREATE = 'MEDICAL_ASSESSMENT_CREATE',
  MEDICAL_ASSESSMENT_MOVE = 'MEDICAL_ASSESSMENT_MOVE',
  MEDICAL_PRE_ASSESSMENT_CREATE = 'MEDICAL_PRE_ASSESSMENT_CREATE',
  SERVICE_ORDER_EDIT = 'SERVICE_ORDER_EDIT',
  SALES_ORDER_FINALIZED = 'SALES_ORDER_FINALIZED',
  TASK_CREATE = 'TASK_CREATE',
  TASK_UPDATE = 'TASK_UPDATE',
  TASK_ADD = 'TASK_ADD',
  EMAIL_RECEIVE = 'EMAIL_RECEIVE',
  EMAIL_UPDATE = 'EMAIL_UPDATE',
  FAX = 'FAX',
  FAX_RECEIVE = 'FAX_RECEIVE',
  FAX_UPDATE = 'FAX_UPDATE',
  RULE_ENGINE = 'RULE_ENGINE',
  STAKEHOLDER_MERGE = 'STAKEHOLDER_MERGE',
  STAKEHOLDER_UNMERGE = 'STAKEHOLDER_UNMERGE',
  HANDLER_CHANGE = 'HANDLER_CHANGE',
  STATUS_CHANGE = 'STATUS_CHANGE',
  CUSTOMER_LOOKUP = 'CUSTOMER_LOOKUP',
  CUSTOMER_SELECTED = 'CUSTOMER_SELECTED',
  SUPPLIER_NOTE_CREATE = 'SUPPLIER_NOTE_CREATE',
  END_USER_SATISFACTION_SURVEY = 'END_USER_SATISFACTION_SURVEY',
  CLAIM_END_USER_SATISFACTION_SURVEY = 'CLAIM_END_USER_SATISFACTION_SURVEY',
  MPA_END_USER_SATISFACTION_SURVEY = 'MPA_END_USER_SATISFACTION_SURVEY',
  END_USER_SATISFACTION_SURVEY_DUPLICATE = 'END_USER_SATISFACTION_SURVEY_DUPLICATE',
  CLAIM_END_USER_SATISFACTION_SURVEY_DUPLICATE = 'CLAIM_END_USER_SATISFACTION_SURVEY_DUPLICATE',
  MPA_END_USER_SATISFACTION_SURVEY_DUPLICATE = 'MPA_END_USER_SATISFACTION_SURVEY_DUPLICATE',
  REGRES = 'REGRES',
  SLA_EVENT = 'SLA_EVENT',
  CASE_EVENT = 'CASE_EVENT',
  SUPPLIER_LOOKUP = 'SUPPLIER_LOOKUP',
  CASE_LINK = 'CASE_LINK',
  SENSITIVE = 'SENSITIVE',
  END_USER_LOOKUP = 'END_USER_LOOKUP',
  EXTERNAL_AUTO_CREATE_CASE = 'EXTERNAL_AUTO_CREATE_CASE',
  MEDICAL_PRE_ASSESSMENT_LOOKUP = 'MEDICAL_PRE_ASSESSMENT_LOOKUP',
  MEDICAL_PRE_ASSESSMENT_LOOKUP_SECCA = 'MEDICAL_PRE_ASSESSMENT_LOOKUP_SECCA',
  PROCESS_GOP_REQUEST_CREATE = 'PROCESS_GOP_REQUEST_CREATE',
  DIGITAL_CASEFLOW = 'DIGITAL_CASEFLOW',
  COORDINATION_CASE_LINK = 'COORDINATION_CASE_LINK',
}

export enum CaseObjectType {
  note = 'NOTE',
  insurance = 'INSURANCE',
  incident = 'INCIDENT',
  planned_travel = 'PLANNED_TRAVEL',
  document = 'DOCUMENT',
  stakeholder = 'STAKEHOLDER',
  communication = 'COMMUNICATION',
  gdpr_communication = 'GDPR_ITEM_DELETION',
  task = 'TASK',
  service_order = 'SERVICE_ORDER',
  sales_order = 'SALES_ORDER',
  purchase_order = 'PURCHASE_ORDER',
  medical_assessment = 'MEDICAL_ASSESSMENT',
  status = 'STATUS',
  coverage = 'COVERAGE',
  customer = 'CUSTOMER',
  screening_tool = 'SCREENING_TOOL',
  CONSENT = 'CONSENT',
  handler = 'HANDLER',
  COORDINATION_CASE = 'COORDINATION_CASE',
}

export enum CaseSubObjectType {
  phone_note = 'PHONE_NOTE',
  case_note = 'CASE_NOTE',
  CUSTOMER_NOTE = 'CUSTOMER_NOTE',
  insurance_note = 'INSURANCE_NOTE',
  insurance = 'INSURANCE',
  incident = 'INCIDENT',
  planned_travel = 'PLANNED_TRAVEL',
  medical_information = 'MEDICAL_INFORMATION',
  case_creation_form = 'CASE_CREATION_FORM',
  gop = 'GOP',
  supplier_proposal = 'SUPPLIER_PROPOSAL',
  supplier_confirmation = 'SUPPLIER_CONFIRMATION',
  supplier_invoice = 'SUPPLIER_INVOICE',
  customer_invoice = 'CUSTOMER_INVOICE',
  passport = 'PASSPORT',
  medical_escort_invoice = 'MEDICAL_ESCORT_INVOICE',
  medif = 'MEDIF',
  end_user = 'END_USER',
  policy_holder = 'POLICY_HOLDER',
  reporter = 'REPORTER',
  person = 'PERSON',
  medical = 'MEDICAL',
  transport = 'TRANSPORT',
  medical_escort = 'MEDICAL_ESCORT',
  agent = 'AGENT',
  other = 'OTHER',
  email = 'EMAIL',
  sms = 'SMS',
  mms = 'MMS',
  chat = 'CHAT',
  fax = 'FAX',
  task = 'TASK',
  email_task = 'EMAIL_TASK',
  sms_task = 'SMS_TASK',
  mms_task = 'MMS_TASK',
  fax_task = 'FAX_TASK',
  manual = 'MANUAL',
  treatment = 'TREATMENT',
  medical_assessment = 'MEDICAL_ASSESSMENT',
  accommodation = 'ACCOMMODATION',
  commercial_flight = 'COMMERCIAL_FLIGHT',
  ambulance_flight = 'AMBULANCE_FLIGHT',
  ground_ambulance = 'GROUND_AMBULANCE',
  taxi = 'TAXI',
  undertaker = 'UNDERTAKER',
  referral_to_customer = 'REFERRAL_TO_CUSTOMER',
  advise_on_insurance_terms = 'ADVISE_ON_INSURANCE_TERMS',
  other_refund_request = 'OTHER_REFUND_REQUEST',
  sales_order = 'SALES_ORDER',
  purchase_order = 'PURCHASE_ORDER',
  light_assessment = 'LIGHT_ASSESSMENT',
  transport_ordination = 'TRANSPORT_ORDINATION',
  ac = 'AC',
  claims = 'CLAIMS',
  cost_control = 'COST_CONTROL',
  financial = 'FINANCIAL',
  case = 'CASE',
  coverage = 'COVERAGE',
  service_order = 'SERVICE_ORDER',
  other_insurance = 'OTHER_INSURANCE',
  customer_profile = 'CUSTOMER_PROFILE',
  customer_reference = 'CUSTOMER_REFERENCE',
  screening_tool = 'SCREENING_TOOL',
  consent = 'CONSENT',
  customer_product_and_policy = 'CUSTOMER_PRODUCT_AND_POLICY',
  primary_case_handler_status = 'PRIMARY_CASE_HANDLER_STATUS',
  medical_status = 'MEDICAL_STATUS',
  team_transport_status = 'TEAM_TRANSPORT_STATUS',
  cost_control_status = 'COST_CONTROL_STATUS',
  claims_status = 'CLAIMS_STATUS',
  primary_case_handler = 'PRIMARY_CASE_HANDLER',
  medical_handler = 'MEDICAL_HANDLER',
  team_transport_handler = 'TEAM_TRANSPORT_HANDLER',
  cost_control_handler = 'COST_CONTROL_HANDLER',
  claims_handler = 'CLAIMS_HANDLER',
  contact_person = 'CONTACT_PERSON',
  external = 'EXTERNAL',
  process = 'PROCESS',
  supplier_note = 'SUPPLIER_NOTE',
  END_USER_SATISFACTION_SURVEY = 'END_USER_SATISFACTION_SURVEY',
  SLA_EVENT = 'SLA_EVENT',
  SUPPLIER_LOOKUP = 'SUPPLIER_LOOKUP',
  SENSITIVE = 'SENSITIVE',
  COORDINATION_CASE_LINK = 'COORDINATION_CASE_LINK',
}

export enum CaseSummaryWarning {
  warning = 'Note not available',
}
