export const DAY_FORMATS = {
  parseInput: 'DDMMYYYY',
  fullPickerInput: 'DD MMM YYYY',
  datePickerInput: 'DD MMM YYYY',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
  serverSideDateFormat: 'YYYY-MM-DD',
  serverSideInput: 'YYYY-MM-DD',
};

export const DATE_FORMATS = {
  parseInput: 'DDMMYYYY HHmm',
  fullPickerInput: 'DD MMM YYYY HH:mm',
  datePickerInput: 'DD MMM YYYY HH:mm',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
  serverSideDateFormat: 'YYYY-MM-DD',
  serverSideInput: 'YYYY-MM-DD',
};