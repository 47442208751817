import { Injectable } from '@angular/core';
import { Adapter } from '@secca/shared/interfaces/adapter';
import { RepatriationFormEnum } from '@secca/shared/models/enums';
import * as moment from 'moment';

export class MedicalAssessmentRepatriationPrognosis {
    date: moment.Moment;
    repatriationForm: RepatriationFormEnum;
    oxygen: boolean;

    public constructor(init?: Partial<MedicalAssessmentRepatriationPrognosis>) {
        Object.assign(this, init);
      }
}


@Injectable({
    providedIn: 'root'
  })
  export class MedicalAssessmentRepatriationPrognosisAdapter implements Adapter<MedicalAssessmentRepatriationPrognosis> {
    adapt(item: any): MedicalAssessmentRepatriationPrognosis {
      return new MedicalAssessmentRepatriationPrognosis({
        date: item.date,
        repatriationForm: item.repatriationForm,
        oxygen: item.oxygen
      });
    }
  }