import { PermissionEnum, PhoneNumberType } from './../../../../../shared/models/enums';
import { Component, Input, OnInit } from '@angular/core';
import { NationalIdService } from '../../../../../core/services/national-id.service';
import { SimpleCaseStakeholder } from '@secca/shared/models/simpleCaseStakeholder';
import { PhoneNumber } from '@secca/shared/models/phoneNumber';
import { StakeholderTooltipService } from './stakeholder-tooltip.service';
import { StakeholderService } from '@secca/core/services/stakeholder.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-stakeholder-tooltip',
  templateUrl: './stakeholder-tooltip.component.html',
  styleUrls: ['./stakeholder-tooltip.scss'],
})
export class StakeholderTooltipComponent implements OnInit {
  @Input() stakeholderId: string;

  stakeholder: SimpleCaseStakeholder;
  shownNationalId: string;
  phoneNumberFixed: PhoneNumber;
  showSpinner = false;

  constructor(
    private stakeholderService: StakeholderService,
    private stakeholderTooltipService: StakeholderTooltipService,
    private nationalIdService: NationalIdService,
  ) { }

  ngOnInit(): void {
    this.stakeholder = null;
    this.stakeholderService.getSimpleStakeholder(+this.stakeholderId).pipe(
      tap(result => {
        this.stakeholder = result;
        this.shownNationalId = this.nationalIdService.getMaskedId(this.stakeholder.nationalIdCountry, this.stakeholder.nationalId);
        this.phoneNumberFixed = this.getPhoneNumber(PhoneNumberType.Fixed);
      })
    )
    .subscribe();
  }

  unMaskNationalId(): void {
    this.shownNationalId = this.nationalIdService.getUnMaskedId(this.stakeholder.nationalIdCountry, this.stakeholder.nationalId);
  }

  maskNationalId(): void {
    this.shownNationalId = this.nationalIdService.getMaskedId(this.stakeholder.nationalIdCountry, this.stakeholder.nationalId);
  }

  createCaseButtonPressed(): void {
    this.stakeholderTooltipService.send(this.stakeholder.id.toString());
  }

  isAddressFilled() {
    return this.stakeholder && 
           this.stakeholder.street || this.stakeholder.postalCode ||
           this.stakeholder.city || this.stakeholder.homeCountry;
  }

  isEmailPhoneFilled() {
    return this.stakeholder.email || this.phoneNumberFixed;
  }

  private getPhoneNumber(phoneNumberType: PhoneNumberType): PhoneNumber {
    return this.stakeholder.phoneNumbers.find(p => p.type === phoneNumberType);
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  get PhoneNumberType() {
    return PhoneNumberType;
  }
}
