<div class="message-dialog-container">

  <div class="modal-header">
    <div class="modal-header-title">
      {{"message-dialog-new-message" | translate}}
    </div>
    <div class="modal-buttons">
      <div (click)="closeMessageDialog()">
        <img src="/assets/icons/Close White.svg" />
      </div>
    </div>
  </div>
  <div class="horizontal-line"></div>
  <div class="modal-content-body">
<!--  <div class="case-summary-container">-->
<!--    <div class="case-summary-info">-->
<!--      {{"message-dialog-case-summary-info" | translate}}-->
<!--    </div>-->
<!--    <app-case-summary-view [case]="case"></app-case-summary-view>-->
<!--  </div>-->
  <div class="message-form">
    <app-message-form
      [takeStakeholderFromGopContact]="takeStakeholderFromGopContact"
      [message]="messageRequest.message"
      [stakeholders]="stakeholders"
      [hideConsentTemplates]="!messageRequest.isConsent"
      [messageTemplates]="filteredMessageTemplates"
      (messageLanguageHasChanged)="messageLanguageHasChanged($event)"
      (messageTemplateHasChanged)="setTemplateRichTextAndSubjectFields($event)"
      (messageChannelTypeHasChanged)="messageChannelTypeHasChanged($event)"
      (messageUpdated)="messageUpdatedEvent($event)">
      </app-message-form>
  </div>
  <div class="horizontal-line"></div>
  <div [ngClass]="messageSubjectClass">
    <app-message-subject [message]="messageRequest.message" [attachments]="attachments" [stakeholders]="stakeholders" (messageUpdated)="messageUpdatedEvent($event)"></app-message-subject>
  </div>
  <div class="message-text">
    <div *ngIf="messageRequest.message.messageChannelType === MessageChannelType.FAX || messageRequest.message.messageChannelType === MessageChannelType.EMAIL">
      <app-rich-text-editor tabindex="5" [showEditor]="true" [message]="messageRequest.message" [caseId]="case.id" (messageUpdated)="messageUpdatedEvent($event)"></app-rich-text-editor>
    </div>
    <div *ngIf="messageRequest.message.messageChannelType === MessageChannelType.SMS">
      <textarea maxlength="1000" class="sms-text" [placeholder]="smsPlaceholder" [(ngModel)]="messageRequest.message.text"></textarea>
    </div>
  </div>
  <div *ngIf="messageRequest.message.messageChannelType === MessageChannelType.FAX || messageRequest.message.messageChannelType === MessageChannelType.EMAIL">
    <div class="footer">
      <div class="message-footer">
        <div class="text">{{ 'message-dialog-internal-summary' | translate}}</div>
        <div class="position-relative">
          <textarea appCharCount="top-right" maxlength="8000" class="internal-summary" [(ngModel)]="messageRequest.message.internalSummary"></textarea>
        </div>
        <button class="send-button float-end" (click)="sendClicked()" [disabled]="!isSendMessageValid" [class.primary-button-inactive]="!isSendMessageValid">
          Send
        </button>

      </div>
    </div>
  </div>
  <div *ngIf="messageRequest.message.messageChannelType === MessageChannelType.SMS" >
    <div class="footer">
      <div class="message-footer">
        <button class="send-button float-end" (click)="sendClicked()" [disabled]="!isSendSmsValid" [class.primary-button-inactive]="!isSendSmsValid">
          Send
        </button>

      </div>
    </div>
  </div>

  </div>
</div>
