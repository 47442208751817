
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  whiteListExtensions = ['pdf', 'jpg', 'jpeg', 'gif', 'png', 'mp4', 'mov', 'ogg', 'heif', 'heic', 'docx'];

  constructor(private http: HttpClient) { }

  downloadFile(url): Observable<any> {
    return this.http.get(url, { responseType: 'blob' });
  }

  isExtensionWhiteListed(extension: string): boolean {
    extension = extension.toLowerCase();
    return this.whiteListExtensions.indexOf(extension) >= 0;

  }
}
