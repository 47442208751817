import { MedicalAssessmentServiceOrderExtension } from '@secca/shared/models/service-order/medical-assessment-service-order-extension';
import { DateHelper } from './../../../../../../shared/helpers/date-helper';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { DropdownDictionary } from './../../../../../../shared/models/dropdownDictionary';
import { CasePlansService } from './../../case-plans.service';
import { ServiceOrderHeightEnum } from './../ServiceOrderHeightEnum';
import { PlanDisplayHelper } from '../plan-display-helper';
import { ServiceOrder } from '@secca/shared/models/service-order/service-order';
import { Timeline } from '../../../../../../shared/models/timeline';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AssigneeEnum, ServiceTypeEnum, ServiceTypeEnumIcon, StatusTypes } from 'src/app/shared/models/enums';
import { ServiceOrderHelper } from './service-order-helper';
import { ServiceOrderHoverModel } from '../service-order-hover/service-order-hover-model';
import { ColorGroupHtml } from './color-group-enum';
import { DocumentService } from '../../../../../../core/services/document.service';
import { SendGopService } from '../../case-coverage/send-gop/send-gop.service';
import { SendGopModel } from '../../case-coverage/send-gop/send-gop.model';
import { CaseService } from '../../../../../../core/services/case.service';
import { StakeholderService } from '../../../../../../core/services/stakeholder.service';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';

@Component({
  selector: 'app-service-order',
  templateUrl: './service-order.component.html',
  styleUrls: ['./service-order.component.scss'],
})
@AutoUnsubscribe
export class ServiceOrderComponent implements OnInit {
  readonly MAX_LANE_NUMBER = 3;

  @ViewChild('serviceContainer') serviceContainer: ElementRef;
  @Input() serviceOrders: ServiceOrder[];
  @Input() serviceOrder: ServiceOrder;
  @Input() remain: ServiceOrder;
  @Input() timeline: Timeline;
  @Input() stakeholders: CaseStakeholder[];
  @Input() daysInTimeline: Date[];
  @Input() planHelper: PlanDisplayHelper;
  @Input() numberOfLanes = 0;
  @Input() caseStakeholder: CaseStakeholder[];
  @Input() providersNames: DropdownDictionary[];
  @Input() providersIcons: DropdownDictionary[];
  @Input() incidentEvent: string;

  @Output() openServiceEditDialog: EventEmitter<any> = new EventEmitter();
  serviceStyle: any;
  serviceLeftLineStyle: any;
  serviceImageStyle: any;
  serviceOrderHelper: ServiceOrderHelper;
  isMouseOverTheService = false;
  caseId: number;
  serviceOrderId: number;
  latestGopId: string;
  gopStatusImageStyle: any;
  gopStatus: string;
  $subscrUpdateGopStatus;
  debugInfo: any = {};
  private supplier: CaseStakeholder;
  serviceOrderGroup = 0;
  durationsWithLocationServiceOrders = [ServiceTypeEnum.TREATMENT, ServiceTypeEnum.ACCOMMODATION];
  durationsWithoutLocationServiceOrders = [ServiceTypeEnum.MEDICAL_ESCORT];
  transportServiceOrder = [ ServiceTypeEnum.AMBULANCE_FLIGHT,
                            ServiceTypeEnum.REGULAR_FLIGHT_MEDICAL,
                            ServiceTypeEnum.REGULAR_FLIGHT_NON_MEDICAL,
                            ServiceTypeEnum.GROUND_AMBULANCE,
                            ServiceTypeEnum.GROUND_TRANSPORT,
                            ServiceTypeEnum.COORDINATION_TRANSPORT
                          ];
                          serviceOrderHoverModel: ServiceOrderHoverModel;

  constructor(
    private casePlansService: CasePlansService,
    private sendGopService: SendGopService,
    private caseService: CaseService,
    private documentService: DocumentService,
    private stakeholderService: StakeholderService,
    public caseLockHelperService: CaseLockHelperService
  ) {}

  ngOnInit() {
    this.draw();
    this.serviceOrderId = this.serviceOrder.id;
    this.caseId = this.serviceOrder.caseId;
    if (this.durationsWithLocationServiceOrders.includes(this.serviceOrder.type)){
      this.serviceOrderGroup = 1;
    } else if(this.durationsWithoutLocationServiceOrders.includes(this.serviceOrder.type)) {
      this.serviceOrderGroup = 2;
    } else if(this.transportServiceOrder.includes(this.serviceOrder.type)) {
      this.serviceOrderGroup = 3;
    }
    if (this.serviceOrder.lastGop) {
      this.documentService.getCaseDocument(this.serviceOrder.lastGop).subscribe(response => {
        this.latestGopId = response.documentId;
        this.gopStatus = response.status;
        this.draw();
      });
    }
    this.$subscrUpdateGopStatus = this.sendGopService.getServiceOrderGopStatus().subscribe(result => {
      if (this.serviceOrderId === result.serviceOrderId) {
        this.gopStatus = result.status;
        this.latestGopId = result.documentId;
        this.draw();
      }
    });
  }

  public draw() {
    this.serviceOrderHelper = new ServiceOrderHelper(this.serviceOrder, this.serviceOrders);
    this.serviceStyle = {
      backgroundColor: this.backgroundColor,
      left: this.leftPosition,
      width: this.width,
      top: this.topPosition + 'px',
      height: this.getHeightWitPixel(),
      borderColor: this.borderColor,
    };

    this.serviceLeftLineStyle = {
      backgroundColor: this.borderColor,
    };
    this.serviceImageStyle = {
      backgroundColor: this.serviceOrderIconBackgroundColor,
      backgroundImage: "url('../../../../../../assets/icons/" + this.serviceImageIcon + "')",
    };
    if (this.latestGopId) {
      if (this.gopStatus === 'Created') {
        this.gopStatusImageStyle = {
          backgroundImage: "url('../../../../../../assets/icons/GopCreated.svg')",
        };
      }
      if (this.gopStatus === 'Sent') {
        // TODO: during implementation of Send Gop add color for sent icon
        return null;
      }
      if (this.gopStatus === 'Cancelled') {
        // TODO: during implementation of Cancel Gop add color for cancelled icon
        return null;
      }
    }
  }

  get serviceDiagonalBackgroundStyle() {
    return {
      background:
        'repeating-linear-gradient(45deg,' +
        this.backgroundColor +
        ',' +
        this.backgroundColor +
        ' 6px,' +
        this.diagonalColor +
        ' 6px,' +
        this.diagonalColor +
        ' 12px)',
      borderTopColor: this.getServiceOrderStatusType() === StatusTypes.EXPECTED ? this.backgroundColor : this.borderColor,
      display:
        this.getServiceOrderStatusType() === StatusTypes.COMMITTED || this.getServiceOrderStatusType() === StatusTypes.CANCELLED || this.getMedicalAssesmentAssignToMedicalStaffDirectly()
          ? 'none'
          : 'block',
      borderTopRightRadius: this.getServiceOrderStatusType() === StatusTypes.PARTIALLY_COMMITTED ? '0px' : '5px',
      top: this.calculateTopPositionForDiagonal
    };
  }

  get isVisible(): boolean {
    return this.planHelper && !this.serviceOrder.hidden && this.serviceOrder.laneNumber < this.MAX_LANE_NUMBER;
  }

  private getMedicalAssesmentAssignToMedicalStaffDirectly(): boolean {
    if (
      this.serviceOrder.type === ServiceTypeEnum.MEDICAL_ASSESSMENT &&
      (this.serviceOrder.extension as MedicalAssessmentServiceOrderExtension).assignee === AssigneeEnum.MEDICAL_STAFF
    ) {
      return true;
    } else {
      return false;
    }
  }

  private getServiceOrderStatusType(): StatusTypes {
    return this.serviceOrder.status;
  }

  private getServiceOrderCommitmentDate(): Date {
    if (this.serviceOrder.type === ServiceTypeEnum.MEDICAL_ASSESSMENT) {
      return null;
    } else if (this.serviceOrder.type === ServiceTypeEnum.REGULAR_FLIGHT_NON_MEDICAL) {
      return null;
    } else {
      return new Date();
    }
  }

  openServiceForEdit() {
    this.openServiceEditDialog.emit(this.serviceOrder.id);
  }

  mouseEnterServiceOrder() {
    this.isMouseOverTheService = true;
    setTimeout(() => {
      if (this.isMouseOverTheService) {
        const serviceOrderHoverModel = this.initializeHoverModel();
        this.casePlansService.sendHoverServiceOrder(this.serviceOrderHoverModel);

      }
    }, 1000);
  }

  mouseLeaveServiceOrder() {
    this.isMouseOverTheService = false;
    this.casePlansService.sendHoverServiceOrder(undefined);
  }

  get calculateTopPositionForDiagonal(): string {
    if (
      this.getServiceOrderStatusType() === StatusTypes.COMMITTED ||
      this.getServiceOrderStatusType() === StatusTypes.EXPECTED ||
      this.getServiceOrderStatusType() === StatusTypes.CANCELLED ||
      this.getServiceOrderStatusType() === StatusTypes.NOT_COVERED ||
      this.getServiceOrderCommitmentDate() == null
    ) {
      return '0px';
    } else {
      return (
        DateHelper.findDateIndexInDates(this.daysInTimeline, this.getServiceOrderCommitmentDate()) * PlanDisplayHelper.defaultCellHeight +
        this.planHelper.topOffsetFromPreviousExtendedDates(this.getServiceOrderCommitmentDate()) -
        (this.topPosition + 2) +
        'px'
      );
    }
  }

  get width(): string {
    let dividor = 2;
    if (this.numberOfLanes === 3 && this.serviceOrder.laneNumber > 0) {
      dividor = 4;
    }

    return 'calc((100% / ' + dividor + ') / ' + (this.serviceOrder.numberOfOverlappingServiceOrders + 1) + ' - 3px)';
  }

  get leftPosition(): string {
    const overridesServices = this.serviceOrder.numberOfOverlappingServiceOrders + 1;
    let laneAdjust = '0px';
    let overlappedAdjust = '(100% / 2)';
    if (this.serviceOrder.laneNumber === 1 && this.numberOfLanes === 3) {
      laneAdjust = '(100% / 2)';
      overlappedAdjust = '(100% / 4)';
    }
    if (this.serviceOrder.laneNumber > 0 && this.numberOfLanes === 2) {
      laneAdjust = '(100% / 2)';
      overlappedAdjust = '(100% / 2)';
    }
    if (this.serviceOrder.laneNumber === 2 && this.numberOfLanes === 3) {
      laneAdjust = '3 * (100% / 4)';
      overlappedAdjust = '(100% / 4)';
    }
    return (
      'calc(' +
      laneAdjust +
      ' + (' +
      overlappedAdjust +
      ' / ' +
      overridesServices +
      ') * ' +
      this.serviceOrder.overlappingPosition +
      ' + 2px)'
    );
  }

  get topPosition(): number {
    if (this.planHelper == null) {
      return 0;
    }

    // this.debugInfo.dateIndex = DateHelper.findDateIndexInDates(this.daysInTimeline, this.serviceOrder.getStartDateLocal());
    // this.debugInfo.positionInCellHeightNumberFromTop = this.serviceOrder.cellPosOffset;
    // this.debugInfo.topOffsetFromPreviousExtendedDates = this.planHelper.topOffsetFromPreviousExtendedDates(this.serviceOrder.getStartDateLocal());
    const topPosition = (
      DateHelper.findDateIndexInDates(this.daysInTimeline, this.serviceOrder.getStartDateLocal()) * PlanDisplayHelper.defaultCellHeight +
      (this.serviceOrder.cellPosOffset * PlanDisplayHelper.defaultCellHeight) / 2.0 +
      this.planHelper.topOffsetFromPreviousExtendedDates(this.serviceOrder.getStartDateLocal())
    );

    return topPosition;
  }

  get height(): number {
    const cellHeightInPx =  this.serviceOrder.cellHeight ? this.serviceOrder.cellHeight * (PlanDisplayHelper.defaultCellHeight / 2) : PlanDisplayHelper.defaultCellHeight / 2;

    switch (this.serviceOrderHelper.serviceOrderHeightEnum) {
      case ServiceOrderHeightEnum.HalfCellHeight:
        return cellHeightInPx;
      case ServiceOrderHeightEnum.FullCellHeight:
        return cellHeightInPx;
      case ServiceOrderHeightEnum.MultipleDaysLessThan12h:
      case ServiceOrderHeightEnum.MultipleDaysMoreThan12h:
        let fullDays = this.serviceOrderHelper.daysLast;
        let fullDaysInPx = PlanDisplayHelper.defaultCellHeight * fullDays;

        const positionInCellHeight = (this.serviceOrder.cellPosOffset * PlanDisplayHelper.defaultCellHeight) / 2;

        return this.serviceOrder.extendedHeight + fullDaysInPx + cellHeightInPx - positionInCellHeight;
      }
  }

  getHeightWitPixel(): string {
    return this.height - 1 + 'px';
  }

  get backgroundColor(): string {
    return ColorGroupHtml.getBackgroundColor(this.serviceOrderHelper.colorGroupForType());
  }

  get borderColor(): string {
    return ColorGroupHtml.getBorderColor(this.serviceOrderHelper.colorGroupForType());
  }

  get serviceOrderIconBackgroundColor(): string {
    return ServiceTypeEnumIcon.getBackGroundColor(this.serviceOrder.type);
  }

  get diagonalColor(): string {
    return ColorGroupHtml.getDiagonalColor(this.serviceOrderHelper.colorGroupForType());
  }

  get serviceImageIcon(): string {
    return ServiceTypeEnumIcon.getIcon(this.serviceOrder.type);
  }

  get displayFullInfo(): boolean {
    return this.serviceOrderHelper.serviceOrderHeightEnum !== ServiceOrderHeightEnum.HalfCellHeight;
  }

  get numberOfOverlappingServiceOrders(): number {
    return this.serviceOrderHelper.numberOfOverlappingServiceOrders();
  }

  get supplierName(): string {
    const supplierId = this.getSupplierId();
    if (supplierId == null) {
      return '';
    }
    if (this.providersNames != null && this.providersNames.find(a => a.id === supplierId.toString()) != null) {
      return this.providersNames.find(a => a.id === supplierId.toString()).name;
    } else {
      return '';
    }
  }

  get agentName(): string {
    const supplierId = this.getSupplierId();
    const supplier = this.caseStakeholder.find(s => +s.id === supplierId);

    if (supplier && supplier.company.gop?.agentId) {
      const agent = this.caseStakeholder.find(s => +s.id === supplier.company.gop.agentId);
      return agent?.company?.name || null;
    }
    return null;
  }

  get ServiceTypeEnum() {
    return ServiceTypeEnum;
  }

  get StatusTypes() {
    return StatusTypes;
  }

  initializeHoverModel(): ServiceOrderHoverModel {
    this.serviceOrderHoverModel = new ServiceOrderHoverModel();
    this.serviceOrderHoverModel.serviceOrder = this.serviceOrder;
    this.serviceOrderHoverModel.serviceLeftPosition = this.serviceContainer.nativeElement.offsetLeft;
    this.serviceOrderHoverModel.serviceTopPosition = this.topPosition;
    this.serviceOrderHoverModel.serviceTypeIcon = this.serviceImageIcon;
    this.serviceOrderHoverModel.serviceWidth = this.serviceContainer.nativeElement.offsetWidth;
    this.serviceOrderHoverModel.providerName = this.supplierName;
    this.serviceOrderHoverModel.agentName = this.agentName;
    this.serviceOrderHoverModel.incidentEvent = this.incidentEvent;

    return this.serviceOrderHoverModel;
  }

  showServiceOrderHover(event: any) {
    const serviceOrderHoverModel = this.initializeHoverModel();
    this.casePlansService.sendHoverServiceOrder(serviceOrderHoverModel);
    event.stopPropagation();
  }

  openDialog() {
    const message = new SendGopModel();
    const supplierStakeholder = this.getSupplierId();
    if (supplierStakeholder) {
      this.stakeholderService.getStakeholder(supplierStakeholder).subscribe(result => {
        message.preferredLanguage =
          result.company.gop != null && result.company.gop.preferredLanguageCode != null
            ? result.company.gop.preferredLanguageCode || 'en'
            : 'en';
        message.caseId = this.caseId;
        message.serviceOrderId = this.serviceOrderId;
        this.sendGopService.sendGopInfo(message);
      });
    }
  }


  private getSupplierId(): number {
    if (this.serviceOrder.type === ServiceTypeEnum.MEDICAL_ASSESSMENT) {
      return null;
    } else {
      return this.serviceOrder.supplierId;
    }
  }

  openGop() {
    if (this.latestGopId) {
      this.documentService.getDocument(this.latestGopId).subscribe(
        result => {
          const url = window.URL.createObjectURL(result);
          window.open(url);
        },
        error => console.log(error)
      );
    }
  }
}
