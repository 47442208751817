import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { MomentHelperService, SECCA_TIMEZONES } from '@secca/core/services/moment-helper.service';

@Pipe({ name: 'momentFormat' })
export class MomentPipe implements PipeTransform {
  constructor() {}
  transform(value: moment.Moment, dateFormat: string, timeZone: SECCA_TIMEZONES): any {
        timeZone = MomentHelperService.resolveTimeZone(timeZone);
        return value ? value.clone().tz(timeZone).format(dateFormat) : '';
    }
}
