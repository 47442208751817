<div class="customer-invoices-container">
  <div class="row d-flex">
    <div class="col-6 d-flex">
      <div [appDisableInput]="caseLockHelperService.isCaseBasicLockTaken || permissionService.isReadOnly">
        <button
          [ngClass]="{
            'invoice-manually-button-disabled': !readyToInvoice || !!emptySalesOrder,
            'invoice-manually-button': !!readyToInvoice || !emptySalesOrder
          }"
          (click)="invoice()"
          [ngbPopover]="iconTextPopOver"
          [autoClose]="false"
          triggers="hover"
          #p="ngbPopover"
          placement="bottom-left"
          [disablePopover]="readyToInvoice"
        >
          {{ 'case-economy-customer-invoices-invoice-manually-button' | translate }}
        </button>
      </div>
      <ng-template #iconTextPopOver>
        {{ 'case-economy-customer-invoice-validation' | translate }}
        <ul>
          <li *ngFor="let item of missingFields">
            <div>{{ item }}</div>
          </li>
        </ul>
      </ng-template>
      <div [appDisableInput]="caseLockHelperService.isCaseBasicLockTaken || permissionService.isReadOnly" class="flex-fill">
        <app-case-economy-customer-invoices-financial-status [caseId]="case.id"></app-case-economy-customer-invoices-financial-status>
      </div>
    </div>
    <div class="col-6">
      <div class="row header-amounts">
        <div class="col">
          <div class="row">
            <div class="col text-end amount-name">{{ 'case-economy-sos-services-not-invoiced-amount' | translate }}</div>
          </div>
          <div class="row">
            <div class="col">
              <div class="d-flex">
                <div class="flex-fill text-end amount-value">{{ notInvoicedAmount | number: '.2' }}</div>
                <div class="amount-currency">{{ caseCurrency }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="row">
            <div class="col text-end amount-name">{{ 'case-economy-sos-services-sent-to-finance' | translate }}</div>
          </div>
          <div class="row">
            <div class="col">
              <div class="d-flex">
                <div class="flex-fill text-end amount-value">{{ sentToERPAmount | number: '.2' }}</div>
                <div class="amount-currency">{{ caseCurrency }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="row">
            <div class="col text-end amount-name">{{ 'case-economy-sos-services-invoiced-amount-excl-vat' | translate }}</div>
          </div>
          <div class="row">
            <div class="col">
              <div class="d-flex">
                <div class="flex-fill text-end amount-value">{{ invoicedAmountExclVat | number: '.2' }}</div>
                <div class="amount-currency">{{ caseCurrency }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="row">
            <div class="col text-end amount-name">{{ 'case-economy-sos-services-invoiced-amount-incl-vat' | translate }}</div>
          </div>
          <div class="row">
            <div class="col">
              <div class="d-flex">
                <div class="flex-fill text-end amount-value" *ngIf="customerInvoiceExists">
                  {{ invoicedAmountInclVat | number: '.2' }}
                </div>
                <div class="flex-fill text-end amount-value" *ngIf="!customerInvoiceExists"></div>
                <div class="amount-currency">{{ caseCurrency }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 table-container" [class.zoomed]="settingsService.isZoomed()">
    <table class="primary-table">
      <thead>
        <tr>
          <td class="thead" (click)="collapseAll()" sortColumn [sortKey]="'id'" [data]="salesOrders">
            {{ 'case-economy-customer-invoices-sales-order-id' | translate }}
          </td>
          <td class="thead" (click)="collapseAll()" sortColumn [sortKey]="'debtorNo'" [data]="salesOrders">
            {{ 'case-economy-customer-invoices-debtorNo' | translate }}
          </td>
          <td class="thead" (click)="collapseAll()" sortColumn [sortKey]="'invoiceNo'" [data]="salesOrders">
            {{ 'case-economy-customer-invoices-invoiceNo' | translate }}
          </td>
          <td class="thead" (click)="collapseAll()" sortColumn [sortKey]="'invoiceDate'" [data]="salesOrders">
            {{ 'case-economy-customer-invoices-invoiceDate' | translate }}
          </td>
          <td class="thead case-amount" (click)="collapseAll()" sortColumn [sortKey]="'invoicePrice'" [data]="salesOrders">
            {{ 'case-economy-customer-invoices-invoicePrice-excl-vat' | translate }}
          </td>
          <td class="thead case-amount" (click)="collapseAll()" sortColumn [sortKey]="'customerInvoiceInclVat'" [data]="salesOrders">
            {{ 'case-economy-customer-invoices-invoicePrice-incl-vat' | translate }}
          </td>
          <td class="thead" (click)="collapseAll()" sortColumn [sortKey]="'status'" [data]="salesOrders">
            {{ 'case-economy-customer-invoices-status' | translate }}
          </td>
          <td class="thead" (click)="collapseAll()" sortColumn [sortKey]="'retryCount'" [data]="salesOrders">
            {{ 'case-economy-customer-invoices-retry-count' | translate }}
          </td>
        </tr>
      </thead>
      <tbody class="theBody">
        <ng-container *ngFor="let salesOrder of salesOrders; index as i">
          <tr class="spacer">
            <td colspan="5"></td>
          </tr>
          <tr
            [ngbPopover]="salesOrderPopOver"
            [autoClose]="false"
            triggers="hover"
            placement="bottom-left"
            #p="ngbPopover">
            <td [ngClass]="{ even: i % 2 != 0 }" [class.note-present-indicator]="!!salesOrder.customerInvoiceNote">
              <div class="d-flex" (click)="expandSalesOrder(i)">
                <div class="flex-fill" [ngbPopover]="salesOrder.customerInvoiceNote" triggers="hover" placement="bottom-left">
                  {{ salesOrder.id }}
                </div>
                <div>
                  <img *ngIf="!expanded[i]" class="expand-icon" src="/assets/icons/ArrowDown.svg" />
                  <img *ngIf="expanded[i]" class="expand-icon" src="/assets/icons/ArrowDownBlue.svg" />
                </div>
              </div>
            </td>
            <td [ngClass]="{ even: i % 2 != 0 }">
              <div class="d-flex">
                <div class="flex-fill">
                  {{salesOrder.debtor}}
                </div>
              </div>
            </td>
            <td [ngClass]="{ even: i % 2 != 0 }">
              <div class="d-flex" *ngIf="salesOrder.status == SalesOrderStatus.INVOICED || salesOrder.status == SalesOrderStatus.PAID">
                <div class="flex-fill">Invoice {{ salesOrder.invoiceNo }}</div>
                <app-open-invoice-pdf [invoiceDocumentId]="salesOrder.invoiceDocumentId"></app-open-invoice-pdf>
              </div>
              <div *ngIf="salesOrder.status == SalesOrderStatus.SENT_TO_ERP" class="pale-text">Pending ERP</div>
            </td>
            <td [ngClass]="{ even: i % 2 != 0 }">
              <div *ngIf="salesOrder.status === SalesOrderStatus.SENT_TO_ERP">{{ salesOrder.erpSentDate | date: 'dd LLL yyyy' }}</div>
              <div *ngIf="salesOrder.status === SalesOrderStatus.INVOICED || salesOrder.status === SalesOrderStatus.PAID">
                {{ salesOrder.invoiceDate | date: 'dd LLL yyyy' }}
              </div>
            </td>
            <td class="case-amount" [ngClass]="{ even: i % 2 != 0 }">
              <div class="d-flex">
                <div class="flex-fill pale-text">{{ salesOrder.invoicePrice.currency }}</div>
                <div class="amount-positive">{{ salesOrder.invoicePrice.amount | number: '.2' }}</div>
              </div>
            </td>
            <td class="case-amount" [ngClass]="{ even: i % 2 != 0 }">
              <div class="d-flex">
                <div class="flex-fill pale-text">{{ salesOrder.invoicePrice.currency }}</div>
                <div class="amount-positive">{{ salesOrder.customerInvoiceInclVat?.amount | number: '.2' }}</div>
              </div>
            </td>
            <td [ngClass]="{ even: i % 2 != 0 }">
                <div class="d-flex">
                  <div class="flex-fill" [ngbPopover]="salesOrder.acknowledgeMessage" triggers="hover" placement="bottom">{{ 'sales-order-status-' + salesOrder.status | translate }}</div>
              </div>
            </td>
            <td [ngClass]="{ even: i % 2 != 0 }">
              <div>
                <div class="d-flex">
                  <div class="flex-fill">
                    {{salesOrder.retryCount}}
                  </div>
                  <div class="flex-fill" *permission="PermissionEnum.SALES_ORDER_UPDATE">
                    <div class="context-menu">
                      <div class="context-menu-dot"></div>
                      <div class="context-menu-dot"></div>
                      <div class="context-menu-dot"></div>
                      <div class="context-menu-hook">
                        <div class="context-menu-content" style="text-align:left">
                          <p (click)="editNote(salesOrder)">{{ 'case-economy-customer-invoices-edit-note' | translate }}</p>
                          <p (click)="deleteSalesOrder(salesOrder)" [ngClass]="{ 'context-menu-content-disabled': salesOrder.salesOrderItems.length > 0}">{{ 'case-economy-customer-invoices-delete' | translate }}</p>
                          <p (click)="resetRetryCount(salesOrder)">{{ 'case-economy-customer-invoices-reset-retry-count' | translate }}</p>
                          <p (click)="resetAckMessage(salesOrder)">{{ 'case-economy-customer-invoices-reset-ack-msg' | translate }}</p>
                          <p (click)="creditDebtor(salesOrder)" [ngClass]="{ 'context-menu-content-disabled': salesOrder.invoiceDate === null || salesOrder.credited || salesOrder.creditNote}">{{ 'case-economy-customer-invoices-reinvoice' | translate }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr *ngIf="expanded[i]">
            <td [ngClass]="{ even: i % 2 != 0, 'expanded-row': true }" colspan="5">
              <div class="expanded-container">
                <table>
                  <tr *ngFor="let salesOrderItem of salesOrder.salesOrderItems; index as i">
                    <td class="service-name">
                      <div>{{ salesOrderItem.sosServiceName }}</div>
                      <div class="pale-text">{{ 'economy-cust-inv-sales-order-item-' + salesOrderItem.type | translate }}</div>
                    </td>
                    <td class="local-amount">
                      <ng-template #popContent><b>SOS Service ID: </b>{{salesOrderItem.sosServiceId}}<br/><b>Quantity: </b>{{salesOrderItem.quantity}}<br/><b>Unit price: </b>{{(salesOrderItem.agreementPrice.amount/salesOrderItem.quantity) | number: '.2'}} {{salesOrderItem.agreementPrice.currency}} </ng-template>
                      <div class="d-flex" [disablePopover]="salesOrderItem.quantity == null"
                           triggers="hover"
                           placement="right"
                           [ngbPopover]="popContent">
                        <div class="flex-fill pale-text">{{ salesOrderItem.agreementPrice?.currency }}</div>
                        <div class="amount-positive">{{ salesOrderItem.agreementPrice?.amount | number: '.2' }}</div>
                      </div>
                    </td>
                    <td class="case-amount">
                      <div class="d-flex">
                        <div class="flex-fill pale-text">{{ salesOrderItem.invoicePrice.currency }}</div>
                        <div class="amount-positive">{{ salesOrderItem.invoicePrice.amount | number: '.2' }}</div>
                      </div>
                    </td>
                    <td class="case-amount">
                      <div class="d-flex">
                        <div class="flex-fill pale-text">{{ salesOrderItem.invoicePrice.currency }}</div>
                        <div class="amount-positive">{{ getPriceVatIncluded(salesOrderItem) | number: '.2' }}</div>
                      </div>
                    </td>
                    <td>
                      <div class="pale-text" *ngIf="salesOrderItem.vat">{{ 'case-economy-customer-invoices-vat' | translate }}</div>
                    </td>
                  </tr>
                </table>
              </div>
            </td>
          </tr>
          <ng-template #salesOrderPopOver>
            <b>{{ 'board-sales-order-invoice-job-status' | translate }} : </b>{{'customer-invoice-sales-order-status-' + salesOrder.invoiceStatus | translate}}
            <b>{{ 'board-sales-order-last-job-date' | translate }} : </b>{{ salesOrder.invoiceExecutionDate | momentFormat: 'D MMM YYYY, HH:mm':'local' }}
          </ng-template>
        </ng-container>
        <div class="white-space"></div>
      </tbody>
    </table>
  </div>
  <app-customer-invoice-note #editNoteModal></app-customer-invoice-note>
  <app-customer-invoice-delete-modal #deleteModal></app-customer-invoice-delete-modal>
  <app-customer-invoice-credit-modal #creditModal></app-customer-invoice-credit-modal>
</div>
