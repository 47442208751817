import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
export class DigitalCaseflowActivityErrorLog {
  errorCode: string;

  public constructor(init?: Partial<DigitalCaseflowActivityErrorLog>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class DigitalCaseflowActivityErrorLogAdapter implements Adapter<DigitalCaseflowActivityErrorLog> {

  adapt(item: any): DigitalCaseflowActivityErrorLog {
    return new DigitalCaseflowActivityErrorLog({
      errorCode: item.errorCode
    });
  }
}
