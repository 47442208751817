<div class="common-drop-down-arrow common-drop-down">
  <div [ngClass]="getBackgroundClass(selectedItemId.consentStatus)">
    <ng-select [clearable]="false" [items]="items" (blur)="onSaveModelDropDown()" (change)="onSelectedItemIdChange()"
               [(ngModel)]="selectedItemId" class="custom"
               [loading]="loading"
               [searchable]="false" (open)="onOpen()" #concentSelect>
      <ng-template ng-label-tmp let-item="item">
        <div>
          {{ item.name }}
        </div>
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
        <div id="wrapper">
          <div id="left">
            <div>{{ item.name }}</div>
          </div>
          <div id="right">
            <div *ngIf="item.consentRequest === ConsentRequestEnum.AUTO">
                <span>
                  <img *ngIf="item.showLegalGuardian; else disableLegalGuardian" width='16'
                       height='16' src="/assets/icons/consent/Is%20Legal%20ON.svg">
                  <ng-template #disableLegalGuardian>
                    <img class="icon-phone" width='16' height='16' src="/assets/icons/consent/Is%20Legal%20Disabled.svg">
                  </ng-template>
                </span>
                <span>
                  <img *ngIf="item.showSMS; else disableSMS" width='16'
                       height='16' src="/assets/icons/consent/SMS%20Blue.svg">
                  <ng-template #disableSMS>
                    <img class="icon-phone" width='16' height='16' src="/assets/icons/consent/SMS%20Light%20Grey.svg">
                  </ng-template>
                </span>
            </div>
            <div *ngIf="item.consentRequest === ConsentRequestEnum.ORAL">
                  <img width='16' height='16' src="/assets/icons/consent/Approx%20Small.svg">
            </div>
            <div *ngIf="item.consentRequest === ConsentRequestEnum.CONSENT_NOT_POSSIBLE">
              <img width='16' height='16' src="/assets/icons/consent/Failure%20Small.svg">
            </div>
            <div *ngIf="item.consentRequest === ConsentRequestEnum.CONSENT_ACCEPTED || item.consentRequest === ConsentRequestEnum.CONSENT_ACCEPTED_MAN">
              <img width='16' height='16' src="/assets/icons/consent/Success%20Small.svg">
            </div>
            <div *ngIf="item.consentRequest === ConsentRequestEnum.RESEND_MANUALLY">
              <img width='16' height='16' src="/assets/icons/consent/Refresh%20Medium%20Grey.svg">
            </div>
            <div *ngIf="item.consentRequest === ConsentRequestEnum.CONSENT_REJECTED">
              <img width='16' height='16' src="/assets/icons/consent/Failure%20Small.svg">
            </div>
          </div>
        </div>
      </ng-template>
    </ng-select>
  </div>
</div>

