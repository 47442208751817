import {StakeholderHelperService} from '@secca/core/services/stakeholder-helper.service';
import {CaseService} from '@secca/core/services/case.service';
import {FileService} from '@secca/core/services/file.service';
import {AttachmentDto} from '@secca/shared/models/attachment-dto';
import {DropdownDictionary} from '@secca/shared/models/dropdownDictionary';
import {ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {CaseDocumentTagEnum, DocumentGroupChannel, DocumentTypeEnum, PermissionEnum, StakeholderTypeEnum} from '@secca/shared/models/enums';
import {CaseDocumentTableMoveDocumentComponent} from './case-document-table-move-document/case-document-table-move-document.component';
import {CaseDocumentTableDeleteDocumentComponent} from './case-document-table-delete-document/case-document-table-delete-document.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {DocumentService} from 'src/app/core/services/document.service';
import {CaseDocumentTableRow} from './case-document-table-row';
import {saveAs} from 'file-saver-es';
import {CaseStakeholder} from '@secca/shared/models/caseStakeholder';
import {StakeholderService} from 'src/app/core/services/stakeholder.service';
import {CaseLockHelperService} from '@secca/core/services/case-lock-helper.service';
import {PermissionService} from '@secca/core/services/permission.service';
import {CaseStateService} from '@secca/core/state-services/case-state.service';
import {FileScan} from '@secca/shared/models/fileScan';
import {HoverFileScanComponent} from '@secca/case/components/case-documents/case-document-table/case-document-table-hover-filescan/hover-file-scan.component';
import {CaseDialogViewerService} from '@secca/case/case-dialog-viewer.service';
import { SettingsService } from '@secca/core/services/settings.service';

@Component({
  selector: 'app-case-document-table',
  templateUrl: './case-document-table.component.html',
  styleUrls: ['./case-document-table.component.scss'],
})
export class CaseDocumentTableComponent implements OnInit {
  caseStakeholder: CaseStakeholder[];
  documentsTagsList: DropdownDictionary[];
  rows: CaseDocumentTableRow[];
  filteredRows: CaseDocumentTableRow[];
  filteringText = '';
  filteringTags = [];
  sortItems: DropdownDictionary[] = [
    { id: 'ondocumentDate', name: 'Recent' },
    { id: 'ondocumentType', name: 'Document Type' },
    { id: 'ondocumentFrom', name: 'Sender' },
    { id: 'ondocumentTo', name: 'Receiver' },
    { id: 'ondocumentName', name: 'Document Name' },
  ];
  sortItem = 'select sorting';
  sortingOrder = 'ondocumentDate';

  @ViewChild('hoverFileScanComponent') hoverFileScanComponent: HoverFileScanComponent;

  @Input()
  caseNumber: string;

  @Input() set caseId(newCaseId: number) {
    this._caseId = newCaseId;
    this.getDocuments();
    this.getStakeholders();
  }
  get caseId(): number {
    return this._caseId;
  }
  attachment: AttachmentDto;
  selectedRow: CaseDocumentTableRow;
  showEntryMenu: any = {};

  constructor(
    private fileService: FileService,
    private documentService: DocumentService,
    private modalService: NgbModal,
    private changeDetectorRef: ChangeDetectorRef,
    private caseService: CaseService,
    private stakeholderHelperService: StakeholderHelperService,
    private stakeholderService: StakeholderService,
    private dialogViewerService: CaseDialogViewerService,
    public caseLockHelperService: CaseLockHelperService,
    public permissionService: PermissionService,
    public caseStateService: CaseStateService,
    public settingsService: SettingsService,
  ) {}
  private _caseId: number;

  get PermissionEnum() {
    return PermissionEnum;
  }

  selectedDocumentId: string;
  moveModalRef: NgbModalRef;

  ngOnInit() {
    this.getTagList();
    this.filterList();
  }

  setShowEntryMenu(i: number) {
    if (this.showEntryMenu[i]) {
      this.showEntryMenu[i] = !this.showEntryMenu[i];
    } else {
      this.showEntryMenu[i] = true;
    }
  }

  getShowEntryMenu(i: number): boolean {
    if (this.showEntryMenu[i]) {
      return this.showEntryMenu[i];
    } else {
      return false;
    }
  }

  clearShowEntryMenu(i: number) {
    this.showEntryMenu[i] = false;
  }

  private getStakeholders() {
    this.caseService.getCaseStakeholdersOnCase(this.caseId.toString()).subscribe(
      stakeholders => {
        this.caseStakeholder = stakeholders;
        if (this.rows != null) {
          this.rows.forEach(row => this.findStakeholderSenderOrRecipient(row));
        }
      },
      error => console.log(error)
    );
  }

  private getDocuments() {
    this.documentService.getCaseDocumentList(this.caseId).subscribe(
      result => {
        this.rows = result.map(doc => new CaseDocumentTableRow(doc));
        this.filterList();
        this.updateDropdownLists();
        if (this.caseStakeholder != null) {
          this.rows.forEach(row => this.findStakeholderSenderOrRecipient(row));
        }
      },
      error => console.log(error)
    );
  }
  
  private getTagList() {
    this.documentService.getUserAllowedDocumentTags().subscribe(
      items => {
        this.documentsTagsList = items.map(item => new DropdownDictionary(item, CaseDocumentTagEnum[item]));
      }
    );
  }

  updateDropdownLists(){
    this.rows.forEach(row => {
      row.caseDocumentTags.forEach(tag => {
        if(tag.preTagged) {
          if(row.documentWithPreTags === undefined){
            row.documentWithPreTags = [];
            row.documentWithPreTags.push(row.documentTags[row.documentTags.findIndex(e => e.id === tag.name)])
            row.documentTags.splice(row.documentTags.findIndex(e => e.id === tag.name), 1);
          }
        }
      });
    });
  }

  private findStakeholderSenderOrRecipient(selectedRow: CaseDocumentTableRow) {
    if (selectedRow.documentType != DocumentTypeEnum.ATTACHMENT &&
      selectedRow.documentType != DocumentTypeEnum.DOCUMENT &&
    selectedRow.documentType !== DocumentTypeEnum.MEDIF) {
      return;
    }
    let foundStakeholder: CaseStakeholder;
    if (selectedRow.isOutgoing === false) {
      foundStakeholder = this.caseStakeholder.find(a => +a.id === selectedRow.stakeholderSenderId);
    } else {
      foundStakeholder = this.caseStakeholder.find(a => +a.id === selectedRow.stakeholderReceiverId);
    }
    if (foundStakeholder != null) {
      selectedRow.stakeholderFullName = foundStakeholder.isCompany
        ? foundStakeholder.company.name
        : foundStakeholder.person.firstName + ' ' + foundStakeholder.person.surname;
      selectedRow.stakeholderIcon = this.stakeholderService.getStakeholderIconFullPath(
        foundStakeholder.stakeholderType as StakeholderTypeEnum
      );
    } else {
      selectedRow.stakeholderFullName = null;
      selectedRow.stakeholderIcon = null;
    }
  }

  containsDocumentGroupChannel(selectedRow: CaseDocumentTableRow): boolean {
    return !!selectedRow.documentGroupChannel;
  }

  containsMatchedStakeholder(selectedRow: CaseDocumentTableRow): boolean {
    return (
      (selectedRow.isOutgoing && selectedRow.stakeholderReceiverId != null) ||
      (!selectedRow.isOutgoing && selectedRow.stakeholderSenderId != null)
    );
  }

  private filterList() {
    if (this.rows === undefined) {
      return;
    }
    this.filteredRows = this.rows.filter(
      caseDocument =>
        (caseDocument.documentName && caseDocument.documentName.toLowerCase().includes(this.filteringText)) ||
        (caseDocument.comments && caseDocument.comments.toLowerCase().includes(this.filteringText)) ||
        (caseDocument.documentTags.length > 0 &&
          caseDocument.documentTags.some(tag => tag.name.toString().toLowerCase().includes(this.filteringText)))
    );
    if (this.filteringTags.length > 0 && this.filteringTags.indexOf('ALL') < 0) {
      this.filteredRows = this.filteredRows.filter(
        caseDocument => caseDocument.documentTags.filter(tag => this.filteringTags.indexOf(tag.id) >= 0).length > 0 ||
                        caseDocument.documentWithPreTags?.filter(tag => this.filteringTags.indexOf(tag.id) >= 0).length > 0
      );
    }
    if (this.sortingOrder !== '') {
      if (this.sortingOrder === 'ondocumentDate') {
        this.filteredRows.sort(this.compareValues('documentDate', 'desc'));
      }
      if (this.sortingOrder === 'ondocumentName') {
        this.filteredRows.sort(this.compareValues('documentName', 'asc'));
      }
      if (this.sortingOrder === 'ondocumentTo') {
        this.filteredRows.sort(this.compareValues('recipient', 'asc'));
      }
      if (this.sortingOrder === 'ondocumentFrom') {
        this.filteredRows.sort(this.compareValues('sender', 'asc'));
      }
      if (this.sortingOrder === 'ondocumentType') {
        this.filteredRows.sort(this.compareValues('documentType', 'asc'));
      }
    }
  }

  filterByTag(value: string[]) {
    this.filteringTags = value;
    this.filterList();
  }

  searchFilterTextChanged(value: string) {
    this.filteringText = value.toLowerCase();
    this.filterList();
  }

  onSortItem(value: string) {
    this.sortingOrder = value;
    this.filterList();
  }

  compareValues(key, order = 'asc') {
    return (a, b) => {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = typeof a[key] === 'string' ? a[key].toLowerCase() : a[key];
      const varB = typeof b[key] === 'string' ? b[key].toLowerCase() : b[key];

      let comparison = 0;
      if (varA < varB) {
        comparison = 1;
      } else if (varA > varB) {
        comparison = -1;
      }
      return order === 'desc' ? comparison : comparison * -1;
    };
  }

  openDocumentInEmbeddedView(row: CaseDocumentTableRow) {
    if (this.containsFileScanInformation(row)) {
      return;
    }
    if (this.fileService.isExtensionWhiteListed(row.documentExtension)) {
      this.attachment = new AttachmentDto({
        documentId: row.documentId,
        documentName: row.documentName,
        documentExtension: row.documentExtension,
      });
      this.selectedRow = row;

      const matDialogRef = this.dialogViewerService.openCaseDocumentDialog(this.caseId, this.caseNumber, this.attachment, this.selectedRow);
      matDialogRef.afterClosed().subscribe(result =>  {
        this.getDocuments();
        }
      );
    } else {
      this.downloadDocument(row);
    }
  }

  downloadDocument(row: CaseDocumentTableRow) {
    if (this.containsFileScanInformation(row)){
      return;
    }

    this.documentService.getDocument(row.documentId).subscribe(
      result => {
        const url = window.URL.createObjectURL(result);
        if (row.documentExtension.toLowerCase() === 'pdf') {
          window.open(url);
        } else {
          this.fileService.downloadFile(url).subscribe(blob => {
            saveAs(blob, row.documentName + '.' + row.documentExtension);
          });
        }
      },
      error => console.log(error)
    );
  }

  updateDocument(row: CaseDocumentTableRow) {
    if(row.documentWithPreTags !== undefined) {
      row.documentTags = row.documentTags.concat(row.documentWithPreTags);
      row.documentWithPreTags = undefined;
    }
    this.documentService.updateCaseDocument(row.getCaseDocument()).subscribe(
      response => {
        this.caseStateService.updatedDocumentTags();
      },
      error => {
        this.getDocuments();
        console.log(error);
      }
    );
    this.updateDropdownLists();
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  deleteDocument(documentId: string) {
    this.documentService.deleteCaseDocument(documentId, this.caseId).subscribe(
      deletedCaseDocument => {
        this.removeCaseDocumentsFromFilteredList();
      },
      error => console.log(error)
    );
  }

  moveDocument(documentId: string, caseNumber: string) {
    this.documentService.moveCaseDocument(documentId, caseNumber).subscribe(
      movedCaseDocument => {
        this.removeCaseDocumentsFromFilteredList();
        this.closeModal();
      },
      error => {
        this.moveModalRef.componentInstance.isCaseNumberValid = false;
        this.moveModalRef.componentInstance.moveInProgress = false;
      }
    );
  }

  isCaseDocumentRowDeleted(row: CaseDocumentTableRow): boolean {
    return row.deletedBy !== null;
  }

  removeCaseDocumentsFromFilteredList() {
    const idx = this.rows.findIndex(doc => doc.documentId === this.selectedDocumentId);
    this.rows.splice(idx, 1);
    this.filterList();
    this.selectedDocumentId = '';
    this.changeDetectorRef.detectChanges();
  }

  openMoveCaseDocumentModal(row: CaseDocumentTableRow) {
    this.selectedDocumentId = row.documentId;
    this.moveModalRef = this.modalService.open(CaseDocumentTableMoveDocumentComponent);
    this.moveModalRef.componentInstance.documentName = row.documentName;
    this.moveModalRef.componentInstance.moveCaseDocumentEvent.subscribe(
      caseNumberNewCase => {
        this.moveDocument(this.selectedDocumentId, caseNumberNewCase);
      },
      error => console.log(error)
    );
    this.moveModalRef.componentInstance.closeModal.subscribe(
      closeEvent => {
        this.closeModal();
      },
      error => console.log(error)
    );
  }

  openDeleteCaseDocumentModal(row: CaseDocumentTableRow) {
    this.selectedDocumentId = row.documentId;
    const modalRef = this.modalService.open(CaseDocumentTableDeleteDocumentComponent);
    modalRef.componentInstance.documentName = row.documentName;
    modalRef.componentInstance.closeModalEvent.subscribe(
      closeEvent => {
        if (closeEvent === true) {
          this.deleteDocument(row.documentId);
        }
        this.closeModal();
      },
      error => console.log(error)
    );
  }

  closeTaskMessageAttachmentDialog() {
    this.modalService.dismissAll();
  }

  getDocumentGroupChannelIcon(documentGroupChannel: DocumentGroupChannel) {
    if (documentGroupChannel == DocumentGroupChannel.EMAIL) {
      return '/assets/icons/output-management/Email Black.svg';
    } else if (documentGroupChannel == DocumentGroupChannel.FAX) {
      return '/assets/icons/output-management/Fax Black.svg';
    } else if (documentGroupChannel == DocumentGroupChannel.MESSAGE) {
      return '/assets/icons/output-management/SMS Black.svg';
    } else if (documentGroupChannel == DocumentGroupChannel.WEB) {
      return '/assets/icons/output-management/Web Black.svg';
    }
  }

  containsFileScanInformation(row: CaseDocumentTableRow) : boolean {
    return !!row.fileScan && (!row.fileScan?.scanned || row.fileScan?.hit);
  }

  getFileScanIcon(fileScan: FileScan) {
    if (!fileScan.scanned) {
      return '/assets/icons/question-mark-red.svg';
    } else if (fileScan?.hit) {
      return '/assets/icons/Warning-filescan.svg';
    }
  }
}

