import { Component, Inject, ViewChild } from '@angular/core';
import { IKeyboardEnabled } from '@secca/shared/interfaces/keyboard-enabled';
import { MinimizableDialogType } from '@secca/shared/enums/minimizable-dialog-type-enum';
import { DialogMinimizableDirective } from '@secca/shared/directives/dialog-minimizable.directive';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { DialogBoundryService } from '@secca/core/services/dialog-boundry.service';
import { DialogHelperUtilService } from '@secca/core/services/dialog-helper-util.service';
import {
  SearchServiceProviderModalComponent
} from '@secca/case/components/case-plans/case-plans/add-service-order/search-service-provider/search-service-provider-modal/search-service-provider-modal.component';
import { IMinimizable } from '@secca/shared/interfaces/minimizable';
import { IAdditionalInfo, SupplierAdditionalInfo } from '@secca/shared/interfaces/additional-info';
import {
  SupplierDialogData
} from '@secca/case/components/case-plans/case-plans/add-service-order/search-service-provider/SupplierDialogData';
import { IFocusEnabled } from '@secca/shared/interfaces/focus-enabled';
import { DialogSelectableDirective } from '@secca/shared/directives/dialog-selectable.directive';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';

@Component({
  selector: 'app-search-service-provider',
  templateUrl: './search-service-provider.component.html'
})
export class SearchServiceProviderComponent implements IAdditionalInfo, IMinimizable, IKeyboardEnabled, IFocusEnabled {
  @ViewChild(DialogMinimizableDirective) minimizedDirective: DialogMinimizableDirective;
  @ViewChild(SearchServiceProviderModalComponent) wrappedInstance: SearchServiceProviderModalComponent;
  @ViewChild(DialogSelectableDirective) selectableDirective: DialogSelectableDirective;

  data: SupplierDialogData;

  constructor(private dialogRef: MatDialogRef<SearchServiceProviderComponent>,
              public dialogBoundryService: DialogBoundryService,
              private dialogHelperUtilService: DialogHelperUtilService,
              @Inject(MAT_DIALOG_DATA) data: SupplierDialogData) {
    this.data = data;
  }

  getAdditionalInfo(): SupplierAdditionalInfo {
    const caseId = this.wrappedInstance?.supplierDialogData?.caseId;
    const serviceOrder = this.wrappedInstance?.supplierDialogData.serviceOrder;
    const suppliers: CaseStakeholder[] = this.wrappedInstance?.supplierDialogData.suppliers;
    return {
      caseId,
      serviceOrder,
      suppliers
    };
  }

  keyboardClose(): void {
    this.wrappedInstance.closeModalWindow();
  }

  close() {
    this.dialogHelperUtilService.close(this.dialogRef, this.getProperties()?.caseId, false);
  }

  get MinimizableDialogType() {
    return MinimizableDialogType;
  }

  getMinimizeType(): MinimizableDialogType {
    return this.minimizedDirective.appDialogMinimizable;
  }

  minimize(): void {
    this.minimizedDirective.minimize();
  }

  getProperties(): SupplierAdditionalInfo {
    const caseId = this.wrappedInstance?.supplierDialogData.caseId;
    return {
      caseId
    };
  }

  getFocusElement(): any {
    return this.selectableDirective?.focusElement;
  }
}
