import { CaseScreening, CaseScreeningAdapter } from './../../shared/models/caseScreening';
import {Injectable} from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from './settings.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CaseLockHttpService } from './case-lock-http.service';


@Injectable({
  providedIn: 'root'
})
export class CaseScreeningService extends BaseService {
    constructor(
      private http: HttpClient,
      private caseScreeningAdapter: CaseScreeningAdapter,
      private settingsService: SettingsService,
      private caseLockHttpService: CaseLockHttpService
    ) {
      super(settingsService);
    }

  updateCaseScreening(caseScreening: CaseScreening): Observable<CaseScreening> {
    return this.caseLockHttpService.put(this.baseURL + 'cases/case-screening', caseScreening, {
        headers: this.jsonHeaders
      }).pipe(map(item => this.caseScreeningAdapter.adapt(item)));
  }
}
