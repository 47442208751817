import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectItem } from '@secca/case/components/case-medical/medical-components/medical-models';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-single-choice-component',
  templateUrl: 'single-choice.component.html',
  styleUrls: ['single-choice.component.scss'],
  animations: [
    trigger('toggleChecked', [
      state('checked', style({
        opacity: 1
      })),
      state('unchecked', style({
        opacity: 0
      })),
      transition('unchecked => checked', [
        animate('400ms', keyframes([
          style({opacity: 0.5, boxShadow: '0 0 0 2px #23AFDC', offset: 0.5}),
          style({opacity: 1, boxShadow: '0 0 0 0px #23AFDC', offset: 1})
        ]))
      ]),
      transition('checked => unchecked', [
        animate('300ms', keyframes([
          style({opacity: 0.5, transform: 'scale(0)', offset: 0.5}),
          style({opacity: 0, offset: 1})
        ]))
      ]),
    ])
  ]
})

export class SingleChoiceComponent<T> implements OnInit {

  @Input() items: SelectItem<T>[];
  @Input() title: string;
  @Input() disabled: boolean;
  @Input() alternativePadding = false;
  valid: boolean;
  selectedValue: T;

  @Output() valueChangeEvent = new EventEmitter<SelectItem<T>>();
  @Output() valueChange = new EventEmitter<T>();

  constructor() {
  }

  ngOnInit(): void {
    this.checkIfValid();
  }

  public select(item: SelectItem<T>) {
    if (this.disabled !== true) {
      const clickedValue = item.selected;
      this.items.forEach(option => option.selected = false);
      item.selected = !clickedValue;
      this.value = item.selected ? item.value : null;
      this.valueChangeEvent.emit(item);
      this.checkIfValid();
    }
  }

  public checkIfValid() {
    this.valid = !
    this.items.some(item => item.selected === true);
  }

  @Input()
  get value(): T {
    return this.selectedValue;
  }

  set value(value: T) {
    this.selectedValue = value;
    this.valueChange.emit(this.selectedValue);
  }
}
