import { Component, Input, OnInit } from '@angular/core';
import { MedicalAssessmentVisits } from '@secca/shared/models/medicalAssessment';


@Component({
  selector: 'app-total-visits',
  templateUrl: './total-visits.component.html',
  styleUrls: ['./total-visits.component.scss']
})
export class TotalVisitsComponent implements OnInit {

  @Input() medicalAssessmentVisits: MedicalAssessmentVisits;
  @Input() header: string;
  @Input() disabled: boolean;
  @Input() required: boolean;
  totalVisits: number;

  constructor() { }

  ngOnInit() {
  }

  setVisits(value: number) {
    this.medicalAssessmentVisits.numberOfVisits = Number(value);
  }

  setAditionalVisits(value: number) {
    this.medicalAssessmentVisits.numberOfAdditionalVisits = Number(value);
  }
}
