import {
  SubStakeholder,
  SubStakeholderAdapter
} from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholder';
import { SubTravelInfo, SubTravelInfoAdapter } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subTravelInfo';
import { SubStakeholders } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholders';
import { SubTravellers } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subTravellers';
import { Duration, DurationAdapter } from '@secca/shared/models/duration';

export class SubMedicalEscort {
  id: number;
  stakeholder: SubStakeholder; // = new SubStakeholder();
  outboundTripNotNeeded: boolean;
  outboundTrips: SubTravelInfo[];
  returnTripNotNeeded: boolean;
  returnTrips: SubTravelInfo[];
  minimumRest: string;
  escortIndex: number;

  subStakeholders: SubStakeholders = new SubStakeholders();
  subOutboundTrips: SubTravellers = new SubTravellers();
  subReturnTrips: SubTravellers = new SubTravellers();

  accommodation: Duration = new Duration();
  accommodationNotNeeded = false;

  public constructor(init?: Partial<SubMedicalEscort>) {
    Object.assign(this, init);
    if (!init) {
      this.init();
    }
  }

  init() {
    this.stakeholder = this.subStakeholders.stakeholders[0];
    this.outboundTrips = this.subOutboundTrips.flightLegs;
    this.returnTrips = this.subReturnTrips.flightLegs;
  }
}

export class MedicalEscortAdapter {
  adapt(item: any): SubMedicalEscort {
    const stakeHolderAdapter = new SubStakeholderAdapter();
    const stakeHolderAdapted = stakeHolderAdapter.adapt(item.stakeholder);
    const travelInfoAdapter = new SubTravelInfoAdapter();
    const outboundTripsAdapted = item.outboundTrips.map(outboundTrip => travelInfoAdapter.adapt(outboundTrip));
    const returnTripsAdapted = item.returnTrips.map(returnTrip => travelInfoAdapter.adapt(returnTrip));
    const adaptedAccommodation = item.accommodation ? new DurationAdapter().adapt(item.accommodation) : new Duration();

    return new SubMedicalEscort({
      id: item.id,
      stakeholder: stakeHolderAdapted,
      subStakeholders: new SubStakeholders([stakeHolderAdapted]),
      outboundTrips: outboundTripsAdapted,
      subOutboundTrips: new SubTravellers(outboundTripsAdapted),
      returnTrips: returnTripsAdapted,
      subReturnTrips: new SubTravellers(returnTripsAdapted),
      minimumRest: item.minimumRest,
      outboundTripNotNeeded: item.outboundTripNotNeeded,
      returnTripNotNeeded: item.returnTripNotNeeded,
      // default to accommodation 'not needed' with existing medical escorts
      accommodationNotNeeded: item.accommodation ? item.accommodationNotNeeded : true,
      accommodation: adaptedAccommodation,
      escortIndex: item.escortIndex,
    });
  }
}
