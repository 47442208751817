<div class="pre-departure-task-table" *ngIf="taskViewModels; else loadingTemplate">
    <div class="primary-table-container">
      <table class="primary-table fixed-header-table">
            <thead>
                <tr>
                    <td class="checkbox-column-header">
                        <div class="custom-checkbox checkbox-header-container">
                            <label class="checkbox">
                                <input type="checkbox" (click)="selectAllTasks()" [(ngModel)]="isAllSelected" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </td>

                    <td (click)="sortBy('customerProfileId')" [ngClass]="sortedBy('customerProfileId')">
                        {{ 'board-task-pre-departure-customer-profile' | translate }}
                    </td>

                    <td (click)="sortBy('caseCreatedOn')" [ngClass]="sortedBy('caseCreatedOn')">
                        {{ 'board-task-pre-departure-received-date' | translate }}
                    </td>

                    <td (click)="sortBy('departureDate')" [ngClass]="sortedBy('departureDate')">
                        {{ 'board-task-pre-departure-departure-date' | translate }}
                    </td>

                    <td (click)="sortBy('caseNumber')" [ngClass]="sortedBy('caseNumber')">
                        {{ 'board-task-pre-departure-case-number' | translate }}
                    </td>

                    <td (click)="sortBy('endUserFullName')" [ngClass]="sortedBy('endUserFullName')">
                        {{ 'board-task-pre-departure-enduser' | translate }}
                    </td>

                    <td (click)="sortBy('location')" [ngClass]="sortedBy('location')">
                        {{ 'board-task-pre-departure-destination' | translate }}
                    </td>

                    <td (click)="sortBy('status')" [ngClass]="sortedBy('status')">
                        {{ 'board-task-pre-departure-status' | translate }}
                    </td>

                    <td (click)="sortBy('assignInitials')" [ngClass]="sortedBy('assignInitials')">
                        {{ 'board-task-pre-departure-assignee' | translate }}
                    </td>

                    <td (click)="sortBy('medicalPreAssessmentDecision')" [ngClass]="sortedBy('medicalPreAssessmentDecision')">
                        {{ 'board-task-pre-departure-decision' | translate }}
                    </td>

                    <td (click)="sortBy('title')" [ngClass]="sortedBy('title')">
                        {{ 'board-task-pre-departure-subject' | translate }}
                    </td>
                </tr>
            </thead>
            <tbody (scroll)="onCaseTabelScroll()" id="taskPreDepartureTabel" [class.zoomed]="settingsService.isZoomed()">
                <tr *ngFor="let task of taskViewModels; let i = index" [class.task-check]="task.isSelected" [class.closed-case]="task.teamStatus === HandlingAreaStatusEnum.CLOSED">
                    <td class="checkbox-column">
                        <div class="custom-checkbox checkbox-content-container">
                            <label class="checkbox">
                            <input type="checkbox" [(ngModel)]="task.isSelected" />
                            <span class="checkmark"></span>
                            </label>
                        </div>
                    </td>
                    <td class="customer-column">
                        <img *ngIf="task.customerProfileId" [src]="getBrandImagesUrlForProfile(task.customerProfileId)" />
                    </td>
                    <td>{{ task.caseCreatedOn | date: 'dd MMM YYYY' | lowercase }}</td>
                    <td>{{ task.departureDate | date: 'dd MMM YYYY' | lowercase }}</td>
                    <td class="link-column" (click)="redirectToManageMedical(task.caseId)">
                        <span [ngClass]="{'case-locked': task.caseLockedBy && task.caseLockedBy != loggedInUser.id}">{{task.caseNumber}}</span>
                    </td>
                    <td>{{ task.endUserFullName }}</td>
                    <td>{{ task.location }}</td>
                    <td>{{ 'medical-pre-assessment-status-' + task.status | translate }}  </td>
                    <td>{{ assigneeShortName(task.assignInitials) }}</td>
                    <td>{{ 'medical-pre-assessment-decison-' + task.medicalPreAssessmentDecision | translate }}</td>
                    <td class="long-text-field-task-title" [ngbPopover]="task.title" placement="auto" triggers="hover">
                        {{ task.title }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<ng-template #loadingTemplate>
    <div class="d-flex justify-content-center pt-3">
      <h4>{{ 'board-task-table-refreshing' | translate }}</h4>
    </div>
</ng-template>
