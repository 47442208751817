import {Component, Input, OnInit} from '@angular/core';
import {CustomerProductRelationType, MatchQuality, RelationSearchResult} from '@secca/shared/models/profile-lookup-response';
import * as moment from 'moment';

@Component({
  selector: 'app-profile-lookup-details',
  templateUrl: './profile-lookup-details.component.html',
  styleUrls: ['./profile-lookup-details.component.scss']
})
export class ProfileLookupDetailsComponent implements OnInit {
  @Input() relationSearchResult: RelationSearchResult;
  @Input() sourceInfo: string;
  @Input() matchedBy: string;
  @Input() lookupTime: moment.Moment;
  MatchQuality = MatchQuality;

  constructor() { }

  ngOnInit(): void {
  }

  public isCreditCard(): boolean {
    return this.relationSearchResult.customerProductRelation.customerProductRelationType === CustomerProductRelationType.CREDIT_CARD;
  }
}
