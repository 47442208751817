import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
import { SosServiceCategoriesEnum } from './enums';

export class FeePickerStatus {
  caseId: number;
  primaryDisabled: boolean;
  secondaryDisabledCategories: SosServiceCategoriesEnum[];

  public constructor(init?: Partial<FeePickerStatus>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
export class FeePickerStatusAdapter implements Adapter<FeePickerStatus> {
  adapt(item: any): FeePickerStatus {
    const secondaryDisabledCategories: SosServiceCategoriesEnum[] = [];
    if (!!item.secondaryDisabledCategories) {
      item.secondaryDisabledCategories.forEach(element => {
        secondaryDisabledCategories.push(SosServiceCategoriesEnum[element]);
      });
    }
    return new FeePickerStatus({ caseId: item.caseId, primaryDisabled: item.primaryDisabled, secondaryDisabledCategories });
  }
}
