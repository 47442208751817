import { SubComponentModel } from './interfaces';

export class SubReference implements SubComponentModel {
  supplierReferenceNumber: string;
  id: number;

  public constructor(init?: Partial<SubReference>) {
    Object.assign(this, init);
  }

  isValid(): boolean {
    return true;
  }
}
