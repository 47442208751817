<div class="message-content">
  <div class="row">
    <div class="col-5">
      <div class="message-content-header">
        <div class="heading">
          <span><img src="/assets/icons/service-order-type/Treatment.svg"></span>
          <div class="text">
            <span>{{'task-web-modal-medical-report' | translate}}</span>
          </div>
        </div>
      </div>
      <div class="message-content-box">
        <div class="properties" *ngIf="isProcessGopRequest()">
          <div class="property-container">
            <div class="property-name">Supplier:</div>
            <div class="property-value-container">
              <span class="property-value">{{supplierName}}</span>
            </div>
          </div>
          <div class="property-container">
            <div class="property-name">Service order:</div>
            <div class="property-value-container">
              <span class="property-value">{{serviceOrderId}}</span>
            </div>
          </div>
        </div>
        <app-task-web-properties [properties]="taskDataViewModel?.data?.treatment || taskDataViewModel?.data?.processGopRequestTask" [showEmptyProperties]="true"
                                 [ignoredProperties]="isProcessGopRequest() ? ['icd10'] : []"
                                 [serviceItemList]="serviceItemList" [purposeOfTravelItemList]="purposeOfTravelItemList"
                                 [causes]="causes" [diagnoses]="diagnoses" [customerProfiles]="customerProfiles">
        </app-task-web-properties>
      </div>
    </div>
  </div>
</div>
