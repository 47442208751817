import { PermissionHideTypeEnum, ShortcutEnum } from '@secca/shared/models/enums';
import { PermissionEnum } from 'src/app/shared/models/enums';
import { PersonInsurance } from 'src/app/shared/models/person-insurance';
import { OtherInsurance } from 'src/app/shared/models/otherInsurance';
import { Component, ElementRef, Inject, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { InsuranceService } from '@secca/core/services/insurance.service';
import { CaseService } from '@secca/core/services/case.service';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { Subscription } from 'rxjs';
import { Coverage } from 'src/app/shared/models/onepoint-coverage';
import { CoverageTypeEnum } from 'src/app/shared/models/enums';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import { PlanService } from '@secca/core/services/plan.service';
import { PermissionService } from '@secca/core/services/permission.service';
import { CaseValidationService } from '@secca/core/services/case-validation.service';
import { DigitalConsent } from '@secca/shared/models/digital-consent';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { CaseDialogViewerService } from '@secca/case/case-dialog-viewer.service';
import { ShortcutService } from '@secca/core/services/shortcut.service';
import { MenuService } from '@secca/core/services/menu.service';
import { CaseSelectedMenuEnum } from '@secca/shared/enums/case-selected-menu-enum.component';
import { ProfileUpdateInProcessService } from '@secca/case/components/case-basics/case-basics-insurance/profile-update-in-process';
import { CaseTypeCodes } from '@secca/shared/models/case-type';
import { CaseTypesService } from '@secca/core/services/case-type.service';

@Component({
  selector: 'app-case-basics-insurance',
  templateUrl: './case-basics-insurance.component.html',
  styleUrls: ['./case-basics-insurance.component.scss']
})
@AutoUnsubscribe
export class CaseBasicsInsuranceComponent implements OnDestroy {
  @ViewChild('insuranceLookupButton') insuranceLookupButton: ElementRef;

  @Input() caseNumber: string;

  @Input() set caseId(caseId: string) {
    this._caseId = caseId;
    this.caseValidationService.isCaseNumberAssigned(this._caseId).subscribe(
      result => {
        this.isCaseNumberAssigned = result;
      }
    );
    if (this.profileUpdateInProcessSubscription) {
      this.profileUpdateInProcessSubscription.unsubscribe();
    }
    this.profileUpdateInProcessSubscription = this.profileUpdateInProcessService.observe(caseId)
      .subscribe(inProcess => {
        this.profileUpdateInProcess = inProcess;
        this.recalculateLookupEnabled();
      });
  }

  get caseId(): string {
    return this._caseId;
  }

  active = 1;

  @Input() consent: DigitalConsent;

  @Input() otherInsurance: OtherInsurance;

  @Input() set personInsurance(value: PersonInsurance) {
    this._personInsurance = value;
    this.handlePersonInsurance();
  }

  get personInsurance() {
    return this._personInsurance;
  }

  private _caseId: string;
  private _personInsurance: PersonInsurance;
  policyHolder: CaseStakeholder;
  lookupEnabled = false;
  policyLookupEnabled = false;
  profileLookupEnabled = false;
  public isInternalCoordinationCase = false;
  lookupTitle = 'policy-lookup-lookup';
  coveragesBasic: Coverage[] = [];
  coveragesSupplement: Coverage[] = [];
  private policyholderSubscription: Subscription;
  private isCaseNumberAssigned = false;

  private selectedMenuId: number;
  private shortcutSubscription: Subscription;
  private profileUpdateInProcessSubscription: Subscription;
  private profileUpdateInProcess = false;

  constructor(private insuranceService: InsuranceService,
              private caseService: CaseService,
              public caseLockHelperService: CaseLockHelperService,
              public permissionService: PermissionService,
              private planService: PlanService,
              private menuService: MenuService,
              private shortcutService: ShortcutService,
              private caseValidationService: CaseValidationService,
              public caseStateService: CaseStateService,
              private dialogViewerService: CaseDialogViewerService,
              private profileUpdateInProcessService: ProfileUpdateInProcessService,
              private caseTypeService: CaseTypesService) {
    this.policyholderSubscription = this.caseService.observePolicyholder().subscribe(policyHolder => {
      this.policyHolder = policyHolder;
      this.recalculateLookupEnabled();
    });
    this.shortcutSubscription = this.shortcutService.addShortcut({ keys: ShortcutEnum.CaseBasicsInsuranceLookup }).subscribe(a => {
      this.selectedMenuId = this.menuService.getSelectedMenuId(parseInt(this.caseId, 10));
      if (this.selectedMenuId === CaseSelectedMenuEnum.CaseBasics) {
        if (this.lookupEnabled) {
          this.insuranceLookupButton.nativeElement.focus();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.shortcutSubscription.unsubscribe();
  }

  private handlePersonInsurance() {
    this.recalculateLookupEnabled();
    if (this._personInsurance != null) {
      this.caseTypeService.getCaseTypeByCaseId(this._personInsurance.seccaCaseId).subscribe(caseType => {
        this.isInternalCoordinationCase = CaseTypeCodes.INTERNAL_COORDINATION === caseType.code;
        if (this.caseId && this._personInsurance.customerProduct != null) {
          this.getCoveragesForInsuranceProduct();
        } else {
          this.coveragesBasic = [];
          this.coveragesSupplement = [];
        }
      });
    }
  }

  getCoveragesForInsuranceProduct() {
    this.planService.getValidCaseCoverages(Number(this.caseId)).subscribe(
      result => {
        this.coveragesBasic = result.filter(x => x.coverageType === CoverageTypeEnum.BASIC);
        this.coveragesSupplement = result.filter(x => x.coverageType === CoverageTypeEnum.SUPPLEMENT);
      },
      error => console.log(error)
    );
  }

  recalculateLookupEnabled() {
    this.policyLookupEnabled = this.isPolicyLookupEnabled();
    this.profileLookupEnabled = this.isProfileLookupEnabled();
    this.lookupEnabled = (this.policyLookupEnabled || this.profileLookupEnabled) && !this.caseLockHelperService.isCaseBasicLockTaken;
    if (!this.lookupEnabled) {
      this.lookupTitle = 'policy-lookup-lookup'; // Lookup
    } else {
      this.lookupTitle = this.policyLookupEnabled ? 'policy-lookup-lookup-title' : 'profile-lookup-lookup-title'; // Policy lookup : Profile lookup
    }
  }

  policyLookupOpen(): void {
    this.dialogViewerService.openPolicyLookupDialog(this.caseId, this.caseNumber);
  }

  profileLookupOpen(): void {
    if (!this.personInsurance.customerProfileId) {
      this.dialogViewerService.openProfileLookupDialog(this.caseId, this.caseNumber);
    }
  }

  private isPolicyLookupEnabled(): boolean {
    return this.personInsurance != null &&
      !this.profileUpdateInProcess &&
      this.personInsurance.customerProfileId != null && (
        (!this.personInsurance.creditCard && Boolean(this.personInsurance.policyReference)) ||
        (this.personInsurance.creditCard && Boolean(this.personInsurance.creditCardNo)) ||
        this.enoughPolicyHolderDataForPolicyLookup()
      );
  }

  private enoughPolicyHolderDataForPolicyLookup(): boolean {
    return (
      this.policyHolder &&
      ( (this.policyHolder.isCompany &&
          Boolean(this.policyHolder.company.name)) ||
        (!this.policyHolder.isCompany &&
          Boolean(this.policyHolder.person.firstName) &&
          Boolean(this.policyHolder.person.surname) &&
          ( (Boolean(this.policyHolder.person.nationalId) && Boolean(this.policyHolder.person.nationalIdCountry.code)) ||
            Boolean(this.policyHolder.address.street) ||
            Boolean(this.policyHolder.person.dateOfBirth))
        )
      )
    );
  }

  private isProfileLookupEnabled(): boolean {
    return !this.isCaseNumberAssigned &&
      (this.personInsurance?.customerProfileId == null || this.personInsurance?.customerProfileId === 0) &&
      (this.isPolicyHolderCompanyProfileLookupEnable() || this.isCreditCardProfileLookupEnabled());
  }

  private isPolicyHolderCompanyProfileLookupEnable(): boolean {
    return (
      this.policyHolder?.isCompany &&
      Boolean(this.policyHolder?.address?.country?.code) &&
      (Boolean(this.policyHolder?.company?.name) || Boolean(this.personInsurance?.policyReference))
    );
  }

  private isCreditCardProfileLookupEnabled(): boolean {
    return (
      this.personInsurance?.creditCard &&
      this.enoughPolicyHolderDataForProfileLookup()
    );
  }

  private enoughPolicyHolderDataForProfileLookup(): boolean {
    return (
      this.policyHolder &&
      !this.policyHolder.isCompany &&
      Boolean(this.policyHolder.person.firstName) &&
      Boolean(this.policyHolder.person.surname) &&
      ((Boolean(this.policyHolder.person.nationalId) && Boolean(this.policyHolder.person.nationalIdCountry.code)) ||
        Boolean(this.policyHolder.address.street)
      )
    );
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  get PermissionHideTypeEnum() {
    return PermissionHideTypeEnum;
  }
}
