import { Component, OnChanges } from '@angular/core';
import { OWL_DATE_TIME_FORMATS } from '@danielmoncada/angular-datetime-picker';
import { PickerBaseComponent } from './picker-base.component';
import { DATE_FORMATS } from './date-formats';


@Component({
  selector: 'app-input-date-time',
  templateUrl: './input-date-time.component.html',
  styleUrls: ['./input-date-time.component.scss'],
  providers: [
    { provide: OWL_DATE_TIME_FORMATS, useValue: DATE_FORMATS },
  ],
})
export class InputDateTimeComponent extends PickerBaseComponent implements OnChanges {
  constructor() {

    super();
    this.formats = DATE_FORMATS;
  }
}
