import {TaskCountTeam, TaskCountTeamAdapter} from '@secca/board/components/board-task/models/task-count-team.model';
import {Injectable} from '@angular/core';
import {Adapter} from '@secca/shared/interfaces/adapter';

export class TaskCountResponse {

  taskCountTeamDtoList: TaskCountTeam[];
  unhandledTasks: number;

  constructor(init ?: Partial<TaskCountResponse>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
export class TaskCountResponseAdapter implements Adapter<TaskCountResponse> {

  constructor(private taskCountTeamAdapter: TaskCountTeamAdapter) {}

  adapt(item: any): TaskCountResponse {
    return new TaskCountResponse({
      taskCountTeamDtoList: item.taskCountTeamDtoList.map((taskCountTeam: TaskCountTeam) => this.taskCountTeamAdapter.adapt(taskCountTeam)),
      unhandledTasks: item.unhandledTasks
    });
  }
}
