import {Component, Input, ViewEncapsulation} from '@angular/core';
import {IncomingCasesTaskViewModel} from '@secca/board/components/board-task/models/incoming-cases-task-view-model';
import {Questions} from '@secca/shared/models/questions';
import {ServiceItemType} from '@secca/shared/models/service-item-type';
import {PurposeOfTravelItemType} from '@secca/shared/models/purpose-of-travel-item-type';
import {CauseItemType} from '@secca/shared/models/cause-item-type';
import {SimpleCustomerProfile} from '@secca/shared/models/simpleCustomerProfile';
import { Diagnosis } from '@secca/shared/models/dignosis';

@Component({
  selector: 'app-task-web-questionnaire',
  templateUrl: './task-web-questionnaire.component.html',
  styleUrls: ['./task-web-questionnaire.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TaskWebQuestionnaireComponent {

  @Input() taskDataViewModel: IncomingCasesTaskViewModel;

  @Input() diagnosis: string;
  @Input() diagnosisKey: string;
  @Input() answerKey: string;
  @Input() questionKey: string;
  @Input() questions: Questions;

  @Input() serviceItemList: ServiceItemType[];
  @Input() purposeOfTravelItemList: PurposeOfTravelItemType[];
  @Input() causes: Map<string, CauseItemType[]>;
  @Input() diagnoses: Diagnosis[];
  @Input() customerProfiles: SimpleCustomerProfile[];

  ignoredProperties = ['diagnosisKey', 'answerKey', 'questionKey'];

  constructor() { }

  getDiagnosisTextIgnoredProperties(questionnaire: any) {
    if ( questionnaire.diagnosisText ) {
      return [...this.ignoredProperties, 'questions', 'diagnosis'];
    }
    else {
      return [...this.ignoredProperties, 'questions', 'diagnosisText'];
    }
  }

  get diagnosisCodeIgnoredProperties() {
    return ['type'];
  }

  get questionnaireIgnoredProperties() {
    return this.ignoredProperties;
  }
}
