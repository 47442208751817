    <div class="d-flex" [class.even]="index % 2">
        <div class="history-table-cell align-top">
            <app-user-picture [imageURL]="entry.picture" [active]="'true'" [imageClass]="'caseSummery'">
            </app-user-picture>
            <span class="headline initials" *ngIf="!entry.previousValue">{{entry.initials}} {{'case-history-economy-supplier-invoice-created' | translate }} {{entry.newValue.id}}</span>
            <span class="headline initials" *ngIf="entry.previousValue && !entry.newValue.deletedOn">{{entry.user?.initials}} {{'case-history-economy-supplier-invoice-updated' | translate }}  {{entry.newValue.id}}</span>
            <span class="headline initials" *ngIf="entry.previousValue && entry.newValue.deletedOn">{{entry.user?.initials}} {{'case-history-economy-supplier-invoice-deleted' | translate }} {{entry.newValue.id}}
            </span>

            <div class="elementDateTime">{{ entry.newValue.modifiedOn | date: 'd MMM y - HH:mm' }}</div>
        </div>
        <div class="history-table-cell align-top">
            <div class="headline">
                <span *ngIf="entry.previousValue">
                    {{ 'case-history-list-original-value' | translate }}
                </span>
            </div>
            <app-previous-value [entry]="entry" [attribute]="'invoiceNumber'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'recievedDate'" [display]="'date'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'invoiceDate'" [display]="'date'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'dueDate'" [display]="'date'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'invoiceAmountLocal'" [display]="'currency'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'invoiceAmountExchanged'" [display]="'currency'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'exchangeDate'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'internalRemark'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'reductionRemark'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'invoiceStatus'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'creditorId'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'serviceOrderId'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'invoiceDocumentId'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'creditorName'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'returnRemark'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'supplierInvoiceIdent'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'approvalDate'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'medicalEscort'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'creditorSupplierGroup'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'submittedByInSupo'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'invoiceGroup'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'submittedInSupo'"></app-previous-value>
            <app-previous-value [entry]="entry" [attribute]="'supoRequestedTime'"></app-previous-value>

        </div>
        <div class="history-table-cell align-top" *ngIf="!entry.newValue.deletedOn">
            <div class="headline">
                <span *ngIf="true">
                    {{ 'case-history-list-new-value' | translate }}
                </span>
            </div>
            <app-new-value [entry]="entry" [attribute]="'invoiceNumber'"></app-new-value>
            <app-new-value [entry]="entry" [attribute]="'recievedDate'" [display]="'date'"></app-new-value>
            <app-new-value [entry]="entry" [attribute]="'invoiceDate'" [display]="'date'"></app-new-value>
            <app-new-value [entry]="entry" [attribute]="'dueDate'" [display]="'date'"></app-new-value>
            <app-new-value [entry]="entry" [attribute]="'invoiceAmountLocal'" [display]="'currency'"></app-new-value>
            <app-new-value [entry]="entry" [attribute]="'invoiceAmountExchanged'" [display]="'currency'"></app-new-value>
            <app-new-value [entry]="entry" [attribute]="'exchangeDate'"></app-new-value>
            <app-new-value [entry]="entry" [attribute]="'internalRemark'"></app-new-value>
            <app-new-value [entry]="entry" [attribute]="'reductionRemark'"></app-new-value>
            <app-new-value [entry]="entry" [attribute]="'invoiceStatus'"></app-new-value>
            <app-new-value [entry]="entry" [attribute]="'creditorId'"></app-new-value>
            <app-new-value [entry]="entry" [attribute]="'serviceOrderId'"></app-new-value>
            <app-new-value [entry]="entry" [attribute]="'invoiceDocumentId'"></app-new-value>
            <app-new-value [entry]="entry" [attribute]="'creditorName'"></app-new-value>
            <app-new-value [entry]="entry" [attribute]="'returnRemark'"></app-new-value>
            <app-new-value [entry]="entry" [attribute]="'supplierInvoiceIdent'"></app-new-value>
            <app-new-value [entry]="entry" [attribute]="'approvalDate'"></app-new-value>
            <app-new-value [entry]="entry" [attribute]="'medicalEscort'"></app-new-value>
            <app-new-value [entry]="entry" [attribute]="'creditorSupplierGroup'"></app-new-value>
            <app-new-value [entry]="entry" [attribute]="'submittedByInSupo'"></app-new-value>
            <app-new-value [entry]="entry" [attribute]="'invoiceGroup'"></app-new-value>
            <app-new-value [entry]="entry" [attribute]="'submittedInSupo'"></app-new-value>
            <app-new-value [entry]="entry" [attribute]="'supoRequestedTime'"></app-new-value>
        </div>
        <div class="history-table-cell align-top" *ngIf="entry.newValue.deletedOn">
            <div class="headline">
                <span class="red-text">
                    {{ 'case-history-economy-deleted' | translate }}
                </span>
            </div>
        </div>

    </div>
