<div class='d-flex justify-content-end header-amounts'>
  <div class='col-8'>

  </div>
  <div class='col-2'>
    <div class='row'>
      <div class='col text-end amount-name'>{{ 'case-economy-recovery-list-total-applied-amount' | translate }}</div>
    </div>
    <div class='row'>
      <div class='col'>
        <div class='d-flex'>
          <div class='flex-fill text-end amount-value'>{{ totalAppliedAmount.amount | number: '.2-2' }}</div>
          <div class='amount-currency'>{{ totalAppliedAmount.currency }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class='col-2'>
    <div class='row'>
      <div class='col text-end amount-name'>{{ 'case-economy-recovery-list-total-recovered-amount' | translate }}</div>
    </div>
    <div class='row'>
      <div class='col'>
        <div class='d-flex'>
          <div class='flex-fill text-end amount-value'>{{ totalRecoveredAmount?.amount | number: '.2-2' }}</div>
          <div class='amount-currency'>{{ totalRecoveredAmount?.currency }}</div>
        </div>
      </div>
    </div>
  </div>

</div>
<div class='col-12 table-container' [class.zoomed]='settingsService.isZoomed()'>
  <table class='primary-table fixed-header-table-rec'>
    <thead>
    <tr>
      <th scope='col' class='recovery-id-width'
          sortColumn
          [sortKey]="'recoveryId'"
          (sortEvent)='setSortKey($event)'
          [data]='filteredRecoveryPaymentList'
          [ngClass]="{'sort-selected': sortKey === 'recoveryId'}">
        <span>
          {{ 'case-economy-recovery-payment-recovery-id' | translate }}
        </span>
      </th>
      <th scope='col' class='creditor-name'
          sortColumn
          [sortKey]="'creditorName'"
          (sortEvent)='setSortKey($event)'
          [data]='filteredRecoveryPaymentList'
          [ngClass]="{'sort-selected': sortKey === 'creditorName'}">
        <span>
          {{ 'case-economy-recovery-payment-creditor' | translate }}
        </span>
      </th>
      <th scope='col' class='date-width'
          sortColumn
          [sortKey]="'receivedDate'"
          (sortEvent)='setSortKey($event)'
          [data]='filteredRecoveryPaymentList'
          [ngClass]="{'sort-selected': sortKey === 'receivedDate'}">
        <span>
          {{ 'case-economy-recovery-payment-created' | translate }}
        </span>
      </th>
      <th scope='col' class='date-width'
          sortColumn
          [sortKey]="'dueDate'"
          (sortEvent)='setSortKey($event)'
          [data]='filteredRecoveryPaymentList'
          [ngClass]="{'sort-selected': sortKey === 'dueDate'}">
        <span>
          {{ 'case-economy-recovery-payment-due' | translate }}
        </span>
      </th>
      <th scope='col' class='progress-bar-col'>
        <span>
          {{ 'case-economy-recovery-payment-progress' | translate }}
        </span>
      </th>
      <th scope='col' class='amount'
          sortColumn
          [sortKey]="'recoveredAmount.amount'"
          (sortEvent)='setSortKey($event)'
          [data]='filteredRecoveryPaymentList'
          [ngClass]="{'sort-selected': sortKey === 'recoveredAmount.amount'}">
        <span>
          {{ 'case-economy-recovery-list-recovered-amount' | translate }}
        </span>
      </th>
      <th scope='col' class='amount'
          sortColumn
          [sortKey]="'totalAppliedAmount.amount'"
          (sortEvent)='setSortKey($event)'
          [data]='filteredRecoveryPaymentList'
          [ngClass]="{'sort-selected': sortKey === 'totalAppliedAmount.amount'}">
        <span>
          {{ 'case-economy-recovery-list-applied-share' | translate }}
        </span>
      </th>
    </tr>
    </thead>
    <tbody class='theBody'>
    <ng-container *ngFor='let recoveryPayment of filteredRecoveryPaymentList; index as i'>
      <tr class='odd'>
        <td class='recovery-id-width'>
          <div class='recovery-id-row'>
            <div class='recovery-number'>
              {{ recoveryPayment.invoiceNumber }}
            </div>
            <app-open-recovery-payment-document
              [recoveryPaymentDocumentId]='recoveryPayment.documentId'>
            </app-open-recovery-payment-document>
          </div>
          <div class='recovery-id-row'>
            <div class='manage' (click)='openManageRecoveryPayment(recoveryPayment)'>
              {{ 'case-economy-recovery-payment-manage' | translate }}
            </div>
            <div (click)='expand(i)'>
              <img class='icon' src='/assets/icons/ArrowDown.svg' alt='arrow' [ngClass]="{ 'icon-rotated': expanded[i] }" />
            </div>
          </div>
        </td>
        <td class='creditor-name'>
          <div>
            {{ recoveryPayment.creditorId }}
          </div>
          <div class='supplier-name'>
            {{ recoveryPayment.creditorName}}
          </div>
        </td>
        <td class='date-width'>
          <div>
            {{ recoveryPayment.receivedDate | date: 'dd LLL yyyy' }}
          </div>
        </td>
        <td class='date-width'>
          <div>
            {{ recoveryPayment.dueDate | date: 'dd LLL yyyy' }}
          </div>
        </td>
        <td class='progress-bar-col'>
          <app-economy-process-bar
            [hidden]='getHiddenEvents()'
            [eventsToDisplay]='getEventsToDisplay()'
            [identifier]='recoveryPayment.recoveryId != null ? recoveryPayment.recoveryId.toString() : ""'
            [type]='recoveryPayment.getType()'
            [progress]='recoveryPayment.getProgress()'>
          </app-economy-process-bar>
        </td>
        <td class='amount-cell'>
          <div class='d-flex'>
            <div class='amount-currency flex-fill'>{{ recoveryPayment.amountExchanged.currency }}</div>
            <div class='text-end'>{{ recoveryPayment.amountExchanged.amount | number: '.2-2' }}</div>
          </div>
        </td>
        <td class='amount-cell'>
          <div class='d-flex flex-row-reverse'>
            {{ recoveryPayment.shareOfTotalApplied }} %
          </div>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</div>
