<div class="task-table" *ngIf="taskViewModels; else loadingTemplate">
  <div class="primary-table-container">
    <table class="primary-table fixed-header-table">
      <thead>
        <tr>
          <td class="checkbox-column-header">
            <div class="custom-checkbox checkbox-header-container">
              <label class="checkbox">
                <input type="checkbox" (click)="checkAllTasks()" [(ngModel)]="isCheckAll">
                <span class="checkmark"></span>
              </label>
            </div>
          </td>
          <td (click)="sortBy('type')" [ngClass]="sortedBy('type')">
            {{"board-task-table-type" | translate}}
          </td>
          <td (click)="sortBy('dueDate')" [ngClass]="sortedBy('dueDate')" *ngIf="taskStatus === 'TODO'">
            {{"board-task-table-due" | translate}}
          </td>
          <td (click)="sortBy('doneDate')" [ngClass]="sortedBy('doneDate')" *ngIf="taskStatus === 'DONE'">
            {{"board-task-table-done" | translate}}
          </td>
          <td (click)="sortBy('prioritySort')" [ngClass]="sortedBy('prioritySort')">
            {{"board-task-table-priority" | translate}}
          </td>
          <td (click)="sortBy('customerProfileName')" [ngClass]="sortedBy('customerProfileName')">
            {{"board-task-table-customer" | translate}}
          </td>
          <td (click)="sortBy('title')" [ngClass]="sortedBy('title')">
            {{"board-task-table-task-title" | translate}}
          </td>
          <td>
            {{"board-task-table-task-consent" | translate}}
          </td>
          <td (click)="sortBy('cause')" [ngClass]="sortedBy('cause')">
            {{"board-task-table-cause" | translate}}
          </td>
          <td>
            {{"board-task-table-covered" | translate}}
          </td>
          <td (click)="sortBy('caseNumber')" [ngClass]="sortedBy('caseNumber')">
            {{"board-task-table-case-id" | translate}}
          </td>
          <td (click)="sortBy('endUserFullName')" [ngClass]="sortedBy('endUserFullName')">
            {{"board-task-table-end-user" | translate}}
          </td>
          <td (click)="sortBy('location')" [ngClass]="sortedBy('location')">
            {{"board-task-table-location" | translate}}
          </td>
          <td (click)="sortBy('arrivalDate')" [ngClass]="sortedBy('arrivalDate')">
            {{"board-task-table-days-left" | translate}}
          </td>
          <td (click)="sortBy('reserve')" [ngClass]="sortedBy('reserve')">
            {{"board-task-table-reserve" | translate}}
          </td>
          <td (click)="sortBy('assignInitials')" [ngClass]="sortedBy('assignInitials')">
            {{"board-task-table-assign" | translate}}
          </td>
          <td (click)="sortBy('caseHandler')" [ngClass]="sortedBy('caseHandler')">
            {{"board-task-table-primary" | translate}}
          </td>
          <td class="ps-0" (click)="sortBy('winterSport')" [ngClass]="sortedBy('winterSport')">
            {{"board-task-table-att" | translate}}
          </td>
          <td>
            {{"board-task-table-case-details" | translate}}
          </td>
          <td (click)="sortBy('readyFrom')" [ngClass]="sortedBy('arrivalDeadline')">
            {{"board-task-table-ready-from" | translate}}
          </td>
        </tr>
      </thead>
      <tbody (scroll)="onCaseTabelScroll()" id="taskTabel" [class.zoomed]="settingsService.isZoomed()">
        <app-task-hover></app-task-hover>
        <tr *ngFor="let taskViewModel of taskViewModels" [class.task-check]="taskViewModel.isCheck" [class.task-without-case-row]="!taskViewModel.caseNumber" [class.closed-case]="caseClosedFadedColour(taskViewModel)">
          <td class="checkbox-column">
            <div class="custom-checkbox checkbox-content-container">
              <label class="checkbox">
                <input type="checkbox" [(ngModel)]="taskViewModel.isCheck">
                <span class="checkmark"></span>
              </label>
            </div>
          </td>
          <td class="type-column">
            <img *ngIf="taskViewModel.taskType === TaskType.medical" [src]="getTaskTypeImagePath(taskViewModel)" (click)="redirectToTaskInCase(taskViewModel)">
            <img *ngIf="taskViewModel.taskType !== TaskType.medical" [src]="getTaskTypeImagePath(taskViewModel)" (click)="viewTask(taskViewModel)">
          </td>
          <td class="date-column" [class.task-without-case-text]="!taskViewModel.caseNumber" *ngIf="taskStatus === TaskStatus.toDo">
            {{taskViewModel.dueDate | momentFormat :'DD MMM HH:mm' : 'local' }}</td>
          <td class="date-column" [class.task-without-case-text]="!taskViewModel.caseNumber" *ngIf="taskStatus === TaskStatus.done">
            {{taskViewModel.doneDate | momentFormat :'DD MMM HH:mm' : 'local' }}</td>
            <td class="text-center">{{ taskViewModel.priority }}</td>
          <td class="customer-column">
            <img *ngIf="taskViewModel.customerProfileId"
                 [src]="getBrandImagesUrlForProfile(taskViewModel.customerProfileId)"
                 [ngbPopover]="taskViewModel.customerProfileName" placement="auto" triggers="hover">
            <img *ngIf="!taskViewModel.customerProfileId" src="assets/icons/1x1.png">
          </td>
          <td class="long-text-field-task-title" id="{{taskViewModel.id}}">
            <span (click)="redirectToTaskInCase(taskViewModel)" (mouseenter)="mouseTitleEnter($event,
              taskViewModel)" (mouseleave)="mouseTitleLeave()">{{taskViewModel.title}}</span>
          </td>

          <td class="consent-column"><span *ngIf="taskViewModel.cause === 'Medical'"><img [src]="getConsentImage(taskViewModel)" [title]="getConsentText(taskViewModel)"></span></td>
          <td class="long-text-field">
            <div class="d-flex justify-content-between">
              <div [class]="taskViewModel.incidentEvent != null && taskViewModel.incidentEvent != 0 ? 'cause-short' : 'cause' "> {{taskViewModel.cause}}</div>
              <div>
                <div
                  *ngIf="taskViewModel.incidentEvent != null && taskViewModel.incidentEvent != 0"
                  class="case-status case-details-repatriation-form case-details-event"
                  [ngbPopover]="taskViewModel.incidentEventName"
                  placement="auto"
                  triggers="hover">
                      {{ 'task-board-incident-event' | translate }}
                </div>
              </div>
            </div>
          </td>
          <td class="long-text-field">
            <div
              [ngClass]="{ 'yes-col-off' :  true,
                           'yes-col-on' : taskViewModel.incidentCoveredSetByCaseHandler && taskViewModel.incidentCoveredState === YES,
                           'tbd-col-on' : taskViewModel.incidentCoveredSetByCaseHandler && taskViewModel.incidentCoveredState === TBD,
                           'no-col-on' : taskViewModel.incidentCoveredSetByCaseHandler && taskViewModel.incidentCoveredState === NO}">
              <div *ngIf = '!taskViewModel.incidentCoveredSetByCaseHandler && taskViewModel.incidentCoveredState === YES' class="yes-dot"></div>
              <div *ngIf = '!taskViewModel.incidentCoveredSetByCaseHandler && taskViewModel.incidentCoveredState === TBD' class="tbd-dot"></div>
              <div *ngIf = '!taskViewModel.incidentCoveredSetByCaseHandler && taskViewModel.incidentCoveredState === NO' class="no-dot"></div>
            </div>
          </td>
          <td class="case-number-column">
            <div
                [ngClass]="{'case-locked': taskViewModel.caseLockedBy && taskViewModel.caseLockedBy != loggedInUser.id}"
                (click)="redirectToCase(taskViewModel.caseId)"
                [ngbPopover]="taskViewModel.incidentCause"
                  placement="auto"
                  triggers="hover"
                >{{taskViewModel.caseNumber}}</div>
          </td>

          <td class="end-user-column">{{taskViewModel.endUserFullName}}</td>
          <td class="long-text-field">{{taskViewModel.location}} ({{taskViewModel.timeZoneId | timeDiff : true}}) </td>
          <td class="time-column" [class.days-left-question-mark]="taskViewModel.daysToArrivalDate === '?' || taskViewModel.daysToArrivalDate < 0" >
            {{taskViewModel.daysToArrivalDate}}
          </td>
          <td class="reserve-column"><span
              *ngIf="taskViewModel.caseNumber">{{taskViewModel.reserve == null ?  0 : taskViewModel.reserve | numberFormat }}
              {{taskViewModel.currency}}</span>
          </td>
          <td class="assing-column">{{ assigneeShortName(taskViewModel.assignInitials) }}</td>
          <td class="long-text-field">{{ assigneeShortName(taskViewModel.caseHandler) }}</td>
          <td>
            <img *ngIf="taskViewModel.winterSport" src="/assets/icons/snowflake.svg" />
          </td>
          <td>
            <div *ngIf="taskViewModel.caseDetailsTBD === true" class="case-status case-details-mortal"> {{"board-task-table-case-details-TBD" | translate}} </div>
            <div *ngIf="taskViewModel.caseDetailsURN === true" class="case-status case-details-mortal"> {{"board-task-table-case-details-URN" | translate}} </div>
            <div *ngIf="taskViewModel.caseDetailsCOF === true" class="case-status case-details-mortal"> {{"board-task-table-case-details-COF" | translate}} </div>
            <div *ngIf="taskViewModel.caseDetailsTBD !== true && taskViewModel.caseDetailsURN !== true && taskViewModel.caseDetailsCOF !== true ">
              <span *ngIf="taskViewModel.inpatientOutpatientOnCase !== null && taskViewModel.inpatientOutpatientActive !== null && showMedicalServiceOrderType(taskViewModel)">

                <div *ngIf="taskViewModel.inpatientOutpatientOnCase === PatientType.INPATIENT" class="case-status"
                  [ngClass]="{ 'case-details-treatment-service-order-inpatient' : taskViewModel.inpatientOutpatientActive === true,
                               'case-details-treatment-service-order-inactive' :  taskViewModel.inpatientOutpatientActive === false } ">
                    {{"board-task-table-case-details-INPATIENT" | translate}}
                </div>
                <div *ngIf="taskViewModel.inpatientOutpatientOnCase === PatientType.OUTPATIENT" class="case-status"
                  [ngClass]="{ 'case-details-treatment-service-order-outpatient' : taskViewModel.inpatientOutpatientActive === true,
                               'case-details-treatment-service-order-inactive' :  taskViewModel.inpatientOutpatientActive === false } ">
                    {{ "board-task-table-case-details-OUTPATIENT" | translate }}
                </div>

              </span>
              <span *ngIf="showMedicalServiceOrderType(taskViewModel)">
                <div *ngIf="taskViewModel.medicalServiceorderType === CommitmentTypes.REGULAR_MEDICAL_ASSESSMENT" class="case-status case-details-medical-service-order"> {{"board-task-table-case-details-REGULAR" | translate}} </div>
                <div *ngIf="taskViewModel.medicalServiceorderType === CommitmentTypes.LIGHT_MEDICAL_REVIEW" class="case-status case-details-medical-service-order"> {{"board-task-table-case-details-LIGHT" | translate}} </div>
                <div *ngIf="taskViewModel.medicalServiceorderType === CommitmentTypes.NIGHT_SHIFT_MEDICAL_ASSESSMENT" class="case-status case-details-medical-service-order"> {{"board-task-table-case-details-NIGHT" | translate}} </div>
                <div *ngIf="taskViewModel.medicalServiceorderType === CommitmentTypes.CLAIMS_MEDICAL_REVIEW" class="case-status case-details-claims-service-order"> {{"board-task-table-case-details-CLAIMS" | translate}} </div>
              </span>
              <div *ngIf="taskViewModel.caseDetailsAIR === true" class="case-status case-details-repatriation-form"> {{"board-task-table-case-details-AIR" | translate}} </div>
              <div *ngIf="taskViewModel.caseDetailsSTR === true" class="case-status case-details-repatriation-form"> {{"board-task-table-case-details-STR" | translate}} </div>
              <div *ngIf="taskViewModel.caseDetailsEXT === true" class="case-status case-details-repatriation-form"> {{"board-task-table-case-details-EXT" | translate}} </div>
              <div *ngIf="taskViewModel.caseDetailsSIT === true" class="case-status case-details-repatriation-form"> {{"board-task-table-case-details-SIT" | translate}} </div>
              <div *ngIf="taskViewModel.caseDetailsREG === true" class="case-status case-details-repatriation-form"> {{"board-task-table-case-details-REG" | translate}} </div>
              <div *ngIf="taskViewModel.caseDetailsDOC === true || taskViewModel.caseDetailsNUR === true || taskViewModel.caseDetailsNME === true" class="case-status case-details-escort"> {{ caseDetailsEscort(taskViewModel) }} </div>
              <div *ngIf="taskViewModel.caseDetailsOXY === true" class="case-status case-details-oxygen"> {{"board-task-table-case-details-OXY" | translate}} </div>
              <div *ngIf="taskViewModel.caseDetailsEQU === true" class="case-status case-details-equipment"> {{"board-task-table-case-details-EQU" | translate}} </div>
            </div>
          </td>
          <td>
            <div *ngIf="taskViewModel.caseDetailsTBD === false && taskViewModel.caseDetailsURN === false && taskViewModel.caseDetailsCOF === false ">
              {{taskViewModel.readyFrom | date: 'dd MMM YYYY' }}
              <div *ngIf="taskViewModel.arrivalDeadline" [ngbPopover]="'board-task-table-arrival-deadline-popover-text' | translate: {arrivalDeadline: taskViewModel.arrivalDeadline | date | lowercase}" placement="auto" triggers="hover" class="aarrival-deadline">
                {{taskViewModel.arrivalDeadline | date: 'dd MMM YYYY' }}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<ng-template #loadingTemplate>
  <div class="d-flex justify-content-center">
    <h4>{{"board-task-table-refreshing" | translate}}</h4>
  </div>
</ng-template>
