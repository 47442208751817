import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductChangeService {
  private products = {};

  observe(caseId: string) {
    this.init(caseId);
    return this.products[caseId].asObservable();
  }

  publish(caseId: string, product: string) {
    this.init(caseId);
    this.products[caseId].next(product);
  }

  init(caseId: string) {
    if (this.products[caseId] == null) {
      this.products[caseId] = new Subject();
    }
  }
}
