<div class="entry-text-container">
    <div *ngIf="entry.previousValue !== null  && entry.previousValue[attribute] !== entry.newValue[attribute]">
        <span class="historyTabelCell-label" >{{ 'case-economy-history-' + entry.type + '-' + attribute | translate }} : </span>
        <span class="entry-text-container historyTabelCell-value">
            <span *ngIf="display =='string'">{{  entry.previousValue[attribute] }}</span>
            <span *ngIf="display =='date'">{{  entry.previousValue[attribute] | date  :'dd MMM YYYY HH:mm'}}</span>
            <span *ngIf="display =='currency' && entry.previousValue[attribute]">{{entry.previousValue[attribute].amount | numberFormat}} {{entry.previousValue[attribute]?.currency}} </span>
            <span *ngIf="display =='number' && entry.previousValue[attribute]">{{entry.previousValue[attribute] | numberFormat}} </span>
        </span>
    </div>
</div>
