<div class="multiple-choice">
  <span class="multiple-choice-title" [ngClass]="{'important': important}" *ngIf="!!title">{{title}}</span>
  <div class="multiple-choice-item" *ngFor="let item of items">
    <div class="multiple-choice-item-checkbox" *ngIf="extraSpace || (!disabled) || (item.selected && disabled)" 
    [ngClass]="{'multiple-choice-item-checkbox-required' : required, 'multiple-choice-item-checkbox-disabled-view' : disabled }"
    (click)="select(item)" (keyup.enter)="select(item) "tabindex="0">
      <img [@toggleChecked]="item.selected ? 'checked' : 'unchecked'"
         class="multiple-choice-item-checkbox-checked" src="../../../../assets/icons/Checked.svg">
    </div>
    <span class="multiple-choice-item-label" [innerHTML]="item.label" *ngIf="extraSpace || (!disabled) || (item.selected && disabled)"></span>
  </div>
</div>
