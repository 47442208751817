import { Component, Input } from '@angular/core';
import {
  MatchQuality,
  PolicyLookup,
  PolicyLookupMethodType,
  PolicyInsuranceType, LookupExecution
} from '../../../../../../shared/models/policy-lookup-response';
import { TranslateService } from '@ngx-translate/core';
import { CoverageTypeEnum } from '@secca/shared/models/enums';

@Component({
  selector: 'app-policy-lookup-details',
  templateUrl: './policy-lookup-details.component.html',
  styleUrls: ['./policy-lookup-details.component.scss']
})
export class PolicyLookupDetailsComponent {
  @Input() policy: PolicyLookup;
  MatchQuality = MatchQuality;
  policyInsuranceType = PolicyInsuranceType;

  constructor(private translate: TranslateService) {
  }

  public getIntegrationType(type): string {
    return this.translate.instant('policy-lookup-method-type-' + type);
  }

  public getIntegrationName(execution: LookupExecution): string {
    if (execution.type === PolicyLookupMethodType.SECCA_POLICY_LOOKUP) {
      return execution.sourceInfo ? execution.sourceInfo : 'Register Server integration';
    }
    return 'Unknown type: ' + execution.type;
  }

  basicCoverages() {
    return this.policy.product.coverages.filter(x => x.coverageType === CoverageTypeEnum.BASIC);
  }

  supplementCoverages() {
    return this.policy.product.coverages.filter(x => x.coverageType === CoverageTypeEnum.SUPPLEMENT);
  }

}
