import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { Icd10Code, Icd10CodeAdapter } from '@secca/shared/models/icd10Code';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IcdCodesService extends BaseService {
  symptoms: Icd10Code[];

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private icdDropdownAdapter: Icd10CodeAdapter
  ) {
    super(settingsService);
  }

  getSymptoms(search: string): Observable<Icd10Code[]> {
    let icdServiceTarget;
    if (search) {
      icdServiceTarget = this.baseURL + 'icd10/search/' + search;
    } else {
      icdServiceTarget = this.baseURL + 'icd10';
    }
    return this.http
      .get<any[]>(icdServiceTarget, {
        headers: this.jsonHeaders
      })
      .pipe(map((data: any[]) => data.map(item => this.icdDropdownAdapter.adapt(item))));
  }
}
