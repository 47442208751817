
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';

@Component({
  selector: 'app-case-documents',
  templateUrl: './case-documents.component.html',
  styleUrls: ['./case-documents.component.scss']
})
export class CaseDocumentsComponent implements OnInit {
  @ViewChild('caseDocumentsTable') caseDocumentsTable;

  @Input()
  caseId: number;

  @Input()
  caseNumber: string;

  constructor(public caseLockHelperService: CaseLockHelperService) {}

  ngOnInit() {
  }

  searchFilterTextChanged(value: string) {
    this.caseDocumentsTable.searchFilterTextChanged(value);
  }
  filterByTag(value: string[]) {
    this.caseDocumentsTable.filterByTag(value);
  }
}
