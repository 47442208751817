import { CoffinUrnHandlingTypeEnum } from './../../models/enums';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ServiceTypeBase } from './../../models/serviceTypeBase';
import { ServiceTypeComponent } from './../../models/interfaces';
import { MortalRemainsServiceOrderExtension } from '@secca/shared/models/service-order/mortal-remains-service-order-extension';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import { MortalRemainsType, CommitmentTypes, StatusTypes } from '@secca/shared/models/enums';
import { TranslateService } from '@ngx-translate/core';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';

@Component({
  selector: 'app-handling-of-mortal-remains',
  templateUrl: './handling-of-mortal-remains.component.html',
  styles: ['.light-gray-option { color: lightGray; }'],
})
export class HandlingOfMortalRemainsComponent extends ServiceTypeBase implements OnInit, OnChanges, ServiceTypeComponent {
  @Input() incidentId: string;
  @Input()
  public get handlingOfMortalRemains(): MortalRemainsServiceOrderExtension {
    return this._handlingOfMortalRemains;
  }
  public set handlingOfMortalRemains(handlingOfMortalRemains: MortalRemainsServiceOrderExtension) {
    this._handlingOfMortalRemains = handlingOfMortalRemains;
    if (this.handlingOfMortalRemains != null) {
      this.mortalRemainsTypeChanged(this.handlingOfMortalRemains.type, false);
    }
  }

  @Input() status: StatusTypes;

  private _handlingOfMortalRemains: MortalRemainsServiceOrderExtension;

  @Input() caseId: string;
  
  @Input() stakeholders: CaseStakeholder[];


  mortalRemainsTypes: DropdownDictionary[];
  availableCoffinUrn: CoffinUrnHandlingTypeEnum[];

  constructor(private translateService: TranslateService) {
    super();
    this.initializeSettings();
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }

  isValid(): boolean {
    let result: boolean;
    if (!this.serviceOrderTypeState.showConfirmationTab) {
      result = this.handlingOfMortalRemains.isValidExpectation();
    } else {
      result = this.handlingOfMortalRemains.isValid() && this.serviceOrderTypeState.isSupplierChosen;
    }
    return result;
  }

  activateSendRequestCreateButton(): boolean {
    return (
      this.serviceOrderTypeState.isLeftSideValid &&
      this.serviceOrderTypeState.isSupplierChosen &&
      this.handlingOfMortalRemains.duration.location.freeText !== '' &&
      this.handlingOfMortalRemains.isDurationValid() &&
      this.handlingOfMortalRemains.type !== MortalRemainsType.NOT_DECIDED &&
      !!this.handlingOfMortalRemains.coffinUrn.handling &&
      this.handlingOfMortalRemains.commitmentType.businessKey === CommitmentTypes.GUARANTEE_OF_PAYMENT
    );
  }

  getModel() {
    return this.handlingOfMortalRemains;
  }

  mortalRemainsTypeChanged(mortalRemainType: MortalRemainsType, resetPreviousValue: boolean = true) {
    if (resetPreviousValue) {
      this.handlingOfMortalRemains.coffinUrn.handling = null;
    }
    if (mortalRemainType === MortalRemainsType.CREMATION) {
      this.availableCoffinUrn = [
        CoffinUrnHandlingTypeEnum.URN_SENT_HOME_BY_GROUND_TRANSPORT,
        CoffinUrnHandlingTypeEnum.URN_SENT_HOME_BY_REGULAR_FLIGHT,
        CoffinUrnHandlingTypeEnum.URN_BROUGHT_HOME_BY_CO_TRAVELLER,
        CoffinUrnHandlingTypeEnum.URN_BROUGHT_HOME_BY_ESCORT,
        CoffinUrnHandlingTypeEnum.LOCAL_BURIAL,
      ];
    }
    if (mortalRemainType === MortalRemainsType.EMBALMING) {
      this.availableCoffinUrn = [
        CoffinUrnHandlingTypeEnum.COFFIN_SENT_HOME_BY_GROUND_TRANSPORT,
        CoffinUrnHandlingTypeEnum.COFFIN_SENT_HOME_BY_REGULAR_FLIGHT,
        CoffinUrnHandlingTypeEnum.LOCAL_BURIAL,
      ];
    }
    if (mortalRemainType === MortalRemainsType.NOT_DECIDED) {
      this.availableCoffinUrn = null;
    }
  }

  initializeSettings() {
    this.mortalRemainsTypes = [
      new DropdownDictionary(
        MortalRemainsType.NOT_DECIDED.toString(),
        this.translateService.instant('sub-handling-mortal-remains-type-' + MortalRemainsType[MortalRemainsType.NOT_DECIDED])
      ),
      new DropdownDictionary(
        MortalRemainsType.CREMATION.toString(),
        this.translateService.instant('sub-handling-mortal-remains-type-' + MortalRemainsType[MortalRemainsType.CREMATION])
      ),
      new DropdownDictionary(
        MortalRemainsType.EMBALMING.toString(),
        this.translateService.instant('sub-handling-mortal-remains-type-' + MortalRemainsType[MortalRemainsType.EMBALMING])
      ),
    ];
  }

  sendRequest() {
    this.serviceOrderTypeState.sendConfirmationEvent.emit();
  }
}
