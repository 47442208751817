import {Adapter} from '../interfaces/adapter';
import {Injectable} from '@angular/core';
export class userDepartment {
  name: string;
  id: string;
  public constructor(init?: Partial<userDepartment>) {
    Object.assign(this, init);
  }
}
@Injectable({
  providedIn: 'root'
})
export class UserDepartmentAdapter implements Adapter<userDepartment> {
  public adapt(item: any): userDepartment {
    return new userDepartment({
      id: item.departmentId,
      name: item.departmentName
    });
  }
}
