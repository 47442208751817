<div class="sub-component-container" *ngIf="subStakeholders">
  <div class="sub-title sub-title-table">
    <div class="d-flex">
      <div class="me-auto p-0 w-100">{{"sub-stakeholders-title" | translate}}</div>
      <div class="col-class p-0 w-100 ms-2" *ngIf="!subStakeholdersConfig.hideClass">{{"sub-stakeholders-class-title" | translate}}</div>
      <div class="col-yes-no-missing p-0 w-100 ms-2" *ngIf="!subStakeholdersConfig.hideInclDateOfBirth">{{"sub-stakeholders-incl-date-of-birth-title" | translate}}</div>
      <div class="col-yes-no-missing p-0 w-100 ms-2" *ngIf="!subStakeholdersConfig.hideInclPassportDetails">{{"sub-stakeholders-incl-passport-details-title" | translate}}</div>
      <div class="delete-container-title p-0 ms-2" *ngIf="!subStakeholdersConfig.requireSingleStakeholder"></div>
    </div>
  </div>
  <div class="sub-content-container">
    <div class="pb-3" *ngFor="let subStakeholder of subStakeholders.stakeholders; let i = index">
      <app-sub-stakeholder [isFirstItem]="i === 0"
                           [subStakeholder]="subStakeholders.stakeholders[i]"
                           [(subStakeholdersConfig)]="subStakeholdersConfig"
                           [stakeholdersDropdown]="stakeholdersDropdown"
                           [classDropdown]="classDropdown"
                           [classDropdownSeeMedifOnly]="classDropdownSeeMedifOnly"
                           [inclDOBDropdown]="inclDOBDropdown"
                           [inclPassportDropdown]="inclPassportDropdown"
                           [caseStakeholders]="caseStakeholders"
                           [subStakeholders]="subStakeholders"
                           [showInvalidBackground]="showInvalidBackground"
                           (delete)="deleteStakeholder($event)"
                           [disabled]="disabled"
                           [hideNip]="hideNip"></app-sub-stakeholder>
    </div>
    <a class="add-stakeholder pt-3" (click)="addStakeholder()"
       *ngIf="(!subStakeholdersConfig.requireSingleStakeholder || !disabled) &&
       !subStakeholdersConfig.hideAddButton">{{"sub-stakeholders-add-stakeholder" | translate}}</a>
  </div>
</div>
