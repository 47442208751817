import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ServiceItemType } from '@secca/shared/models/service-item-type';
import { PurposeOfTravelItemType } from '@secca/shared/models/purpose-of-travel-item-type';
import { CauseItemType } from '@secca/shared/models/cause-item-type';
import { SimpleCustomerProfile } from '@secca/shared/models/simpleCustomerProfile';
import { TaskWebPropertiesService } from '@secca/core/services/task-web-properties.service';
import { Diagnosis } from '@secca/shared/models/dignosis';

@Component({
  selector: 'app-task-web-property',
  templateUrl: './task-web-property.component.html',
  styleUrls: ['./task-web-property.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TaskWebPropertyComponent {
  @Input() properties = {};
  @Input() key: string;
  @Input() type: string;

  @Input() serviceItemList: ServiceItemType[];
  @Input() purposeOfTravelItemList: PurposeOfTravelItemType[];
  @Input() causes: Map<string, CauseItemType[]>;
  @Input() diagnoses: Diagnosis[];
  @Input() customerProfiles: SimpleCustomerProfile[];

  constructor(public taskWebPropertiesService: TaskWebPropertiesService) {
  }

  getPropertyValue(properties: any, key: string, serviceItemList: ServiceItemType[], purposeOfTravelItemList: PurposeOfTravelItemType[], causes: Map<string, CauseItemType[]>, diagnoses: Diagnosis[], customerProfiles: SimpleCustomerProfile[]): string {
    return this.taskWebPropertiesService.getPropertyValue(properties, key, serviceItemList, purposeOfTravelItemList, causes, diagnoses, customerProfiles);
  }

  getPropertyValuebyType(properties: any, key: string, type: string, serviceItemList: ServiceItemType[], purposeOfTravelItemList: PurposeOfTravelItemType[], causes: Map<string, CauseItemType[]>, diagnoses: Diagnosis[], customerProfiles: SimpleCustomerProfile[]): string {
    if (properties) {
      return this.taskWebPropertiesService.getValueFromType(properties[key], type, serviceItemList, purposeOfTravelItemList, causes, diagnoses, customerProfiles);
    }
    return '';
  }
}
