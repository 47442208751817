import { Component } from '@angular/core';
import { BaseRow } from '../base-row';

@Component({
  selector: 'app-customer-invoice-item-row',
  templateUrl: './customer-invoice-item-row.component.html',
  styleUrls: ['./../base-row.component.scss']
})
export class CustomerInvoiceItemRowComponent extends BaseRow{
}
