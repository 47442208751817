<div class=" mb-3 actionCard"
  [ngClass]="{ 'red' : (medicalAssessmentAction.examinationType === getMedicalExaminationsTypeEnum().NOT_ACUTELY)}">
  <span class="deleteIcon" *ngIf="actionDeleteble" (click)="deleteAction()">
    <span *ngIf="!disabled">Delete <img src="../../../../assets/icons/garbage.svg"></span>
  </span>
  <div class="actionCardContainer ms-2 me-2">
    <div class="mt-2 me-1 left">
      <app-drop-down [disabled]="disabled" [name]="'medical-action-card-expected-action' | translate" [items]="actionTypes"
        [(selectedItemId)]="medicalAssessmentAction.actionType">
      </app-drop-down>
    </div>
    <div class="mt-2 right">
      <ng-container *ngIf="medicalAssessmentAction.examinationType !== getMedicalExaminationsTypeEnum().NOT_ACUTELY">
        <app-input-calendar [withImage]="true" [name]="'medical-action-card-not-relevant-after' | translate"
          [date]="this.medicalAssessmentAction.date"
          [disabled]="disabled" (dateChange)="setDate($event)">
        </app-input-calendar>
      </ng-container>
    </div>
  </div>
  <div class="mt-2 ms-2 me-2">
    <app-text-box [disabled]="disabled" [maxTextLength]="250" [name]="'medical-action-card-not-description' | translate" [numberOfRows]="5"
      [(value)]="medicalAssessmentAction.description" [placeHolder]="''"></app-text-box>
  </div>
</div>
