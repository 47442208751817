<ul ngbNav #nav="ngbNav" class="service-order-type-tabset nav-tabs" [(activeId)]="activeTab" (navChange)="tabChange($event)">
  <li id="{{SelectedTab.ExpectationTab}}" [ngbNavItem]="SelectedTab.ExpectationTab">
    <a ngbNavLink>{{"service-type-expectation" | translate}}</a>
    <ng-template ngbNavContent>
      <div [appDisableInput]="caseLocked" class="caselock-no-events">
      <div class="sub-component-placeholder">
        <app-drop-down [requiredRedBackground]="true" [name]="'sub-handling-mortal-remains-type-name' | translate"
          [items]="mortalRemainsTypes" [(selectedItemId)]="handlingOfMortalRemains.type"
          (selectedItemIdChange)="mortalRemainsTypeChanged($event)"></app-drop-down>

      </div>

      <div class="sub-component-placeholder">
        <app-sub-commitment-type [(commitmentType)]="handlingOfMortalRemains.commitmentType"
                                 [caseId]="caseId"
                                 [serviceOrderType]="serviceOrderType" [status]="status">
        </app-sub-commitment-type>
      </div>

      <div class="sub-component-placeholder">
        <app-sub-reference [(subReference)]="handlingOfMortalRemains.reference"></app-sub-reference>
      </div>

      <div class="sub-component-placeholder">
        <app-sub-coffin-urn [required]="false" *ngIf="availableCoffinUrn" [availableCoffinUrn]="availableCoffinUrn"
          [(subCoffinUrn)]="handlingOfMortalRemains.coffinUrn">
        </app-sub-coffin-urn>
        <div class="sub-component-container" *ngIf="!availableCoffinUrn">
          <div class="sub-title">{{"coffin-urn-title" | translate}}</div>
          <div class="sub-content-container light-gray-option">
            {{"handling-of-mortal-remains-select-type" | translate}}
          </div>
        </div>
      </div>

      <div class="sub-component-placeholder">
        <app-duration [incidentId]="incidentId" [(duration)]="handlingOfMortalRemains.duration" [hideGOPDates]="true"
          [hideRoomAndRemark]="true" [isFirstItem]="true">
        </app-duration>
      </div>

      <div class="sub-component-placeholder">
        <app-sub-funeral-homes [(caseId)]="caseId" [(subFuneralHomes)]="handlingOfMortalRemains.funeralHomes" [stakeholders]="stakeholders"></app-sub-funeral-homes>
      </div>

      <div class="sub-component-placeholder">
        <app-sub-remarks-to-supplier [(subRemarksToSupplier)]="handlingOfMortalRemains.remarksToSupplier"></app-sub-remarks-to-supplier>
      </div>

      <div class="sub-component-placeholder row">
        <div class="col-12">
          <button class="primary-button float-end" (click)="sendRequest()" [disabled]="!activateSendRequestCreateButton()">{{'handling-of-mortal-remains-send-request' | translate}}</button>
        </div>
      </div>
      </div>
    </ng-template>
  </li>
  <li [id]="SelectedTab.ConfirmationTab" *ngIf="serviceOrderTypeState.showConfirmationTab" [ngbNavItem]="SelectedTab.ConfirmationTab">
    <a ngbNavLink>{{"service-type-confirmation" | translate}}<div class="close-tab-x" (click)="closeConfirmationTab($event)"
        *ngIf="!serviceOrderTypeState.isConfirmationTabSaved"><img src="/assets/icons/Close Black.svg"></div>
    </a>
    <ng-template ngbNavContent>
      <div [appDisableInput]="caseLocked" class="caselock-no-events">
      <div class="sub-component-placeholder">
        <app-drop-down [requiredRedBackground]="true" [name]="'sub-handling-mortal-remains-type-name' | translate"
          [items]="mortalRemainsTypes" [(selectedItemId)]="handlingOfMortalRemains.type" [disabled]="true"></app-drop-down>
      </div>

      <div class="sub-component-placeholder">
        <app-sub-commitment-type
          [(commitmentType)]="handlingOfMortalRemains.commitmentType"
          [status]="status"
          [serviceOrderType]="serviceOrderType"
          [caseId]="caseId"
        ></app-sub-commitment-type>
      </div>

      <div class="sub-component-placeholder">
        <app-sub-reference [(subReference)]="handlingOfMortalRemains.reference"></app-sub-reference>
      </div>

      <div class="sub-component-placeholder">
        <app-sub-coffin-urn [required]="false" *ngIf="availableCoffinUrn" [availableCoffinUrn]="availableCoffinUrn"
          [(subCoffinUrn)]="handlingOfMortalRemains.coffinUrn" [readOnlyMode]="true">
        </app-sub-coffin-urn>
        <div class="sub-component-container" *ngIf="!availableCoffinUrn">
          <div class="sub-title">{{"coffin-urn-title" | translate}}</div>
          <div class="sub-content-container light-gray-option">
            {{"handling-of-mortal-remains-select-type" | translate}}
          </div>
        </div>
      </div>

      <div class="sub-component-placeholder">
        <app-duration [(duration)]="handlingOfMortalRemains.duration" [hideGOPDates]="true" [hideRoomAndRemark]="true" [isFirstItem]="true"
          [incidentId]="incidentId">
        </app-duration>
      </div>

      <div class="sub-component-placeholder">
        <app-sub-funeral-homes [(caseId)]="caseId" [(subFuneralHomes)]="handlingOfMortalRemains.funeralHomes" [readOnlyMode]="true" [stakeholders]="stakeholders" >
        </app-sub-funeral-homes>
      </div>

      <div class="sub-component-placeholder">
        <app-sub-remarks-from-supplier [(subRemarksFromSupplier)]="handlingOfMortalRemains.remarksFromSupplier">
        </app-sub-remarks-from-supplier>
      </div>
    </div>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="nav"></div>

