import { Component, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-comment-modal',
  templateUrl: 'comment-modal.component.html',
  styleUrls: ['comment-modal.component.scss']
})
export class CommentModalComponent {
  @ViewChild('content') private template: TemplateRef<any>;

  comment: string;
  @Output() commentEvent = new EventEmitter<string>();

  private modalRef: NgbModalRef;

  constructor(public modalService: NgbModal) {
  }

  open(comment: string) {
    this.comment = comment;
    this.modalRef = this.modalService.open(this.template, { backdrop: 'static', keyboard: false });
  }

  close() {
    this.modalRef.close();
  }

  returnToAccounting() {
    this.commentEvent.emit(this.comment);
    close();
  }
}
