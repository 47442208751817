import { DropdownDictionary } from './../../models/dropdownDictionary';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { OnSaveData } from '../../interfaces/on-save-data';
import { DropdownPosition, NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-drop-down',
  templateUrl: './drop-down.component.html',
})
export class DropDownComponent implements OnInit, OnSaveData {
  @Input() dropdownPosition: DropdownPosition = 'auto';
  @Input() required: boolean;
  @Input() recommended: boolean;
  @Input() requiredRedBackground: boolean;
  @Input() requiredRedLabel: boolean;
  @Input() name: string;
  @Input() items: DropdownDictionary[];
  @Input() selectedItemId: string = '';
  @Input() disabled: boolean;
  @Input() noBorderAndArrow: boolean;
  @Input() translatePipePath: string;
  @Input() multiple = false;
  @Input() multiSelectedLabel: string;
  @Input() closeOnSelect = true;
  @Input() placeholder = '';
  @Input() missingItemLabel: string;
  @Input() appendTo: string = null;
  @Input() highlightBorderOnInputNonEmpty = false;
  @Output() selectedItemIdChange = new EventEmitter();
  @Output() saveModel = new EventEmitter();
  @Output() open = new EventEmitter();
  @Output() close = new EventEmitter();
  @Output() focus = new EventEmitter();
  oldSelectedItemId: string;
  @ViewChild('selectCause') selectCause: NgSelectComponent;

  constructor() {}

  onChange() {
    this.selectedItemIdChange.emit(this.selectedItemId);
  }

  onOpen() {
    this.open.emit();
  }

  onClose() {
    this.close.emit();
  }

  ngOnInit() {}

  onSaveModel() {
    if (this.oldSelectedItemId !== this.selectedItemId) {
      this.saveModel.emit();
    }
  }

  onSaveState() {
    this.oldSelectedItemId = this.selectedItemId;
  }

  setFocus({ selectCause = false }: { selectCause?: boolean } = {}) {
    this.disabled = false;
    if ( this.selectCause ) {
      this.selectCause.focus();
      if (selectCause) {
        this.selectCause.open();
      }
    }
  }

  displayMissingLabel(): boolean {
    if (!!this.items) {
      return !this.items.find(item => item.id === this.selectedItemId);
    }
    return true;
  }
}
