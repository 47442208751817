import { DocumentService } from 'src/app/core/services/document.service';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-open-invoice-pdf',
  templateUrl: './open-invoice-pdf.component.html',
  styleUrls: ['./open-invoice-pdf.component.scss'],
})
export class OpenInvoicePdfComponent {
  @Input() invoiceDocumentId: string;

  constructor(private documentService: DocumentService) {}

  getAndOpenInvoicePDF(invoiceDocumentId: string) {
    this.documentService.getDocument(invoiceDocumentId).subscribe(result => {
      const url = window.URL.createObjectURL(result);
      window.open(url);
    });
  }

  getAndOpenInvoicePDFNewWindow(invoiceDocumentId: string, windowName: string) {
    this.documentService.getDocument(invoiceDocumentId).subscribe(result => {
      const url = window.URL.createObjectURL(result);
      window.open(url, windowName, 'height=' + screen.availHeight + ',width=' + screen.availWidth);
    });
  }
}
