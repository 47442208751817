import { Injectable } from '@angular/core';
import { EconomyHistoryFilterType } from '@secca/shared/models/enums';
import { EconomyHistory } from '@secca/shared/models/refund-history';
import { UserDto } from '@secca/shared/models/userDto';
import * as _ from 'lodash-es';

export class EconomyHistoryComparison {

  public picture: string;
  public initials: string;

  constructor(
    public previousValue: EconomyHistory,
    public newValue: EconomyHistory,
    public user: UserDto,
    public type: EconomyHistoryFilterType
  ) {
    this.initials = user ? user.initials : null;
    this.picture = this.initials ? this.user.picture : 'system';
  }

  date(): moment.Moment {
    return this.newValue.modifiedOn;
  }

}

@Injectable({
  providedIn: 'root'
})
export class EconomyHistoryService {
  constructor() {
  }

  sortDatesByTime(date1, date2, order) {
    if ('DESC' === order) {
      return date2.toDate().getTime() - date1.toDate().getTime();
    }
    return date1.toDate().getTime() - date2.toDate().getTime();
  }

  generateVersionComparison(historyList: EconomyHistory[], users: UserDto[], historyType: EconomyHistoryFilterType) {

    const economComparisonList: EconomyHistoryComparison[] = [];
    const historyListByGroup = _.groupBy(historyList, 'id');
    const keys = Object.keys(historyListByGroup);
    for (const item of keys) {
      const list = historyListByGroup[item].sort((obj1, obj2) => this.sortDatesByTime(obj1.modifiedOn, obj2.modifiedOn, 'DESC'));
      if (list.length > 0) {

        for (let i = 0; i < list.length - 1; i++) {
          if (!list[i].identicalHistory(list[i + 1])) {
            economComparisonList.push(new EconomyHistoryComparison(list[i + 1], list[i], this.resolveUser(list[i], users), historyType));
          }
        }
        economComparisonList.push(new EconomyHistoryComparison(null, list[list.length - 1], this.resolveUser(list[list.length - 1], users), historyType));
      }
    }
    return economComparisonList;
  }

  private resolveUser(economyHistory: EconomyHistory, users: UserDto[]): UserDto {
    return users.find(user => user.userId === economyHistory.modifiedBy);
  }
}
