import { Component, Input } from '@angular/core';
import { ServiceItemType } from '@secca/shared/models/service-item-type';
import { PurposeOfTravelItemType } from '@secca/shared/models/purpose-of-travel-item-type';
import { CauseItemType } from '@secca/shared/models/cause-item-type';
import { TaskWebPropertiesService } from '@secca/core/services/task-web-properties.service';
import { SimpleCustomerProfile } from '@secca/shared/models/simpleCustomerProfile';
import { Diagnosis } from '@secca/shared/models/dignosis';

export enum DisplayTypeEnum {
  FORMATTED_TEXT
}
@Component({
  selector: 'app-task-web-properties',
  templateUrl: './task-web-properties.component.html',
  styleUrls: ['./task-web-properties.component.scss']
})
export class TaskWebPropertiesComponent {

  @Input() properties = {};
  @Input() ignoredProperties: string[] = [];
  @Input() showEmptyProperties = false;
  @Input() showNestedProperties = true;
  @Input() propertiesDisplayTypes: Record<string, DisplayTypeEnum> = {};

  @Input() serviceItemList: ServiceItemType[];
  @Input() purposeOfTravelItemList: PurposeOfTravelItemType[];
  @Input() causes: Map<string, CauseItemType[]>;
  @Input() diagnoses: Diagnosis[];
  @Input() customerProfiles: SimpleCustomerProfile[];

  constructor(public taskWebPropertiesService: TaskWebPropertiesService) {
  }

  getPropertyDisplayType(key: string): DisplayTypeEnum {
    return key && this.propertiesDisplayTypes && this.propertiesDisplayTypes[key];
  }

  get DisplayTypeEnum() {
    return DisplayTypeEnum;
  }
}
