import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
import { CaseHistoryEntry, CaseHistoryEntryAdapter } from './caseHistoryEntry';
import { UserDto } from './userDto';

export class CaseSummary {
  caseHistoryList: CaseHistoryEntry[];
  users: UserDto[];
  entities: any;
  totalNumber: number;

  public constructor(init?: Partial<CaseSummary>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class CaseSummaryAdapter implements Adapter<CaseSummary> {
  constructor(private caseHistoryEntryAdapter: CaseHistoryEntryAdapter) {}

  adapt(item: any): CaseSummary {
    return new CaseSummary({
      caseHistoryList: item.caseHistoryList.map(caseHistoryEntry => this.caseHistoryEntryAdapter.adapt(caseHistoryEntry)),
      users: item.users,
      entities: item.entities,
      totalNumber: item.totalNumber
    });
  }
}
