import { SettingsService } from './settings.service';
import { TeamCaring, TeamCaringAdapter } from './../../shared/models/teamCaring';
import { Case, CaseAdapter } from './../../shared/models/case';
import { SearchCaseResult, SearhCaseResultAdapter } from './../../shared/models/searchCaseResult';
import { SearchCase } from './../../shared/models/searchCase';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class BoardService extends BaseService {
  constructor(
    private http: HttpClient,
    private caseAdapter: CaseAdapter,
    private teamCaringAdapter: TeamCaringAdapter,
    private searhCaseResultAdapter: SearhCaseResultAdapter,
    private settingsService: SettingsService) {
      super(settingsService);
    }

  public getTeamCaring(): Observable<TeamCaring[]> {
    return this.http.get(this.baseURL + 'teamcaring')
    .pipe(map((data: any[]) => data.map(item => this.teamCaringAdapter.adapt(item))));
  }

  public getCases(): Observable<Case[]> {
    return this.http.get(this.baseURL + 'cases')
    .pipe(map((data: any[]) => data.map(item => this.caseAdapter.adapt(item))));
  }

  public getSearchCase(searchCase: SearchCase): Observable<SearchCaseResult[]> {
    return this.http.post<SearchCaseResult[]>(this.baseURL + 'searches/cases', searchCase, {headers: this.jsonHeaders})
      .pipe(map((data: any[]) => data.map(item => this.searhCaseResultAdapter.adapt(item))));
  }
}
