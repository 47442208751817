import { StakeholderService } from 'src/app/core/services/stakeholder.service';
import { Component, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { SubFuneralHomes } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subFuneralHomes';
import { CaseService } from '@secca/core/services/case.service';
import { StakeholderTypeEnum } from 'src/app/shared/models/enums';
import { DropdownWitIconDictionary } from '@secca/shared/models/dropdownWithIconDictionary';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';

@Component({
  selector: 'app-sub-funeral-homes',
  templateUrl: './sub-funeral-homes.component.html',
  styleUrls: ['./sub-funeral-homes.component.scss']
})
export class SubFuneralHomesComponent implements OnChanges {
  @Input() caseId: string;
  @Input() subFuneralHomes: SubFuneralHomes;
  @Input() readOnlyMode: boolean;
  @Input() stakeholders: CaseStakeholder[];

  stakeholdersDropdown: DropdownWitIconDictionary[];

  constructor(private caseService: CaseService, private stakeholderService: StakeholderService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['stakeholders'] && this.stakeholders ) {
      const funeralCompanies = this.stakeholders.filter(item => item.hasRole(StakeholderTypeEnum.funeralCompany));
      this.stakeholdersDropdown = [];
      funeralCompanies.forEach(stakeholder => {
        this.stakeholdersDropdown.push({
          id: stakeholder.id,
          name: stakeholder.company.name,
          iconPath: this.stakeholderService.getStakeholderIconFullPath(stakeholder.stakeholderType),
          hoverText: null
        });
      });
    }
  }

  setSelectedStakeholder(selectedId: any, isLocal: boolean) {
    if (isLocal) {
      this.subFuneralHomes.localCaseStakeholderId = selectedId;
    } else {
      this.subFuneralHomes.receivingCaseStakeholderId = selectedId;
    }
  }
}
