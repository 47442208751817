import { BalanceSheetEntry, BalanceSheetEntryItem } from '@secca/case/components/case-plans/case-plans/add-service-order/models/interfaces';
import { EconomyHistory } from '@secca/shared/models/refund-history';
import moment from 'moment';
import DineroFactory, { DineroObject } from 'dinero.js';
import { BalanceSheetProgress, BalanceSheetAdapter } from '@secca/shared/models/balance-sheet-event.model';
import { BalanceSheetEntryEnum, RecoveryPaymentStatus } from '@secca/shared/models/enums';
import { RecoveryPaymentItem, RecoveryPaymentItemAdapter } from '@secca/shared/models/recoveryPaymentItem';
import { Adapter } from '@secca/shared/interfaces/adapter';
import { Injectable } from '@angular/core';
import { DateSerializer } from '@secca/shared/models/date-serializer';

export class RecoveryPayment implements BalanceSheetEntry, EconomyHistory {
  id: number;
  erpIdent: number;
  erpClient: string;
  receivedDate: Date;
  invoiceDate: Date;
  dueDate: Date;
  approvalDate: Date;
  invoiceNumber: string;
  creditorId: number;
  creditorName: string;
  caseId: number;
  recoveryId: string;
  amountLocal: DineroObject;
  amountExchanged: DineroObject;
  exchangeRate: number;
  internalRemark: string;
  returnRemark: string;
  status: RecoveryPaymentStatus;
  documentId: string;
  items: RecoveryPaymentItem[] = [];
  deletedBy: string;
  deletedOn: moment.Moment;
  modifiedBy: string;
  modifiedOn: moment.Moment;
  progress: BalanceSheetProgress;
  shareOfTotalApplied: number;
  revision: number;

  public constructor(init?: Partial<RecoveryPayment>) {
    Object.assign(this, init);
  }


  getCreditorId(): string {
    return "" + this.creditorId;
  }

  getCreditorName(): string {
    return this.creditorName;
  }

  getDueDate(): Date {
    return this.dueDate;
  }

  getExchangeRate(): number {
    return this.exchangeRate;
  }

  getId(): number {
    return this.id;
  }

  getInvoiceAmount(): DineroFactory.DineroObject {
    return this.amountLocal;
  }

  getInvoiceAmountExchanged(): DineroFactory.DineroObject {
    return this.amountExchanged;
  }

  getInvoiceDate(): Date {
    return this.invoiceDate;
  }

  getInvoiceDocumentId(): string {
    return this.documentId;
  }

  getInvoiceNumber(): string {
    return this.invoiceNumber;
  }

  getItems(): BalanceSheetEntryItem[] {
    return [];
  }

  getMedicalEscort(): boolean {
    return false;
  }

  getProgress(): BalanceSheetProgress {
    return this.progress;
  }

  getReceivedDate(): Date {
    return this.receivedDate;
  }

  getRedStatusText(): string {
    return 'APPROVED';
  }

  getReductionAmount(): number {
    return 0;
  }

  getSettledAmount(): number {
    return 0;
  }

  getStakeholderId(): number {
    return 0;
  }

  getStatus(): string {
    return this.status;
  }

  getType(): BalanceSheetEntryEnum {
    return BalanceSheetEntryEnum.RECOVERY_PAYMENT;
  }

  identicalHistory(histObject: EconomyHistory): boolean {
    return false;
  }

  isCancelled(): boolean {
    return false;
  }

  isDeleted(): boolean {
    return false;
  }

  isObjection(): boolean {
    return false;
  }

  setReductionAmount(amount: number) {
  }

  setSettledAmount(amount: number) {
  }
}

@Injectable({
  providedIn: 'root'
})
export class RecoveryPaymentAdapter implements Adapter<RecoveryPayment> {
  constructor(private recoveryPaymentItemAdapter: RecoveryPaymentItemAdapter, private balanceSheetAdapter: BalanceSheetAdapter) {
  }

  adapt(item: any): RecoveryPayment {
    return new RecoveryPayment({
      id: item.id,
      caseId: item.caseId,
      recoveryId: item.recoveryId,
      erpIdent: item.erpIdent,
      erpClient: item.erpClient,
      receivedDate: item.receivedDate,
      invoiceDate: item.invoiceDate,
      dueDate: item.dueDate,
      approvalDate: item.approvalDate,
      invoiceNumber: item.invoiceNumber,
      creditorId: item.creditorId,
      creditorName: item.creditorName,
      amountLocal: item.amountLocal,
      amountExchanged: item.amountExchanged,
      exchangeRate: item.exchangeRate,
      internalRemark: item.internalRemark,
      returnRemark: item.returnRemark,
      status: item.status,
      documentId: item.documentId,
      items: item.items.map((paymentItem: any) => this.recoveryPaymentItemAdapter.adapt(paymentItem)),
      progress: item.progress ? this.balanceSheetAdapter.adapt(item.progress) : null,
      shareOfTotalApplied: item.shareOfTotalApplied,
      modifiedBy: item.modifiedBy,
      modifiedOn: item.modifiedOn ? DateSerializer.deserialize(item.modifiedOn) : null,
      deletedOn: item.deletedOn ? DateSerializer.deserialize(item.deletedOn) : null,
      deletedBy: item.deletedBy,
      revision: item.revision
    });
  }
}
