import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {OnSaveData} from '../../interfaces/on-save-data';
import {UserDto} from '../../models/userDto';

@Component({
  selector: 'app-drop-down-users-static',
  templateUrl: './drop-down-users-static.component.html',
  styleUrls: ['./drop-down-users-static.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DropDownUsersStaticComponent implements OnInit, OnSaveData {
  @Input() required = false;
  @Input() name: string;
  @Input() standardDropdownIcon: boolean;
  @Input() sortingStyle: boolean;
  @Input() items: UserDto[];
  @Input() selectedUserDtos: UserDto[] = [];
  @Input() multiple = false;
  @Input() multiSelectedLabel: string;
  @Input() closeOnSelect = true;
  @Input() placeholder = '';
  @Input() disabled = false;
  @Output() selectedUserDtosChange = new EventEmitter();
  @Output() saveModel = new EventEmitter();
  @Output() open = new EventEmitter();
  @Output() close = new EventEmitter<UserDto[]>();

  oldSelectedUserDtos: UserDto[];

  constructor() {
  }

  onChange() {
    this.selectedUserDtosChange.emit(this.selectedUserDtos);
  }

  onOpen() {
    this.open.emit();
  }

  onClose() {
    this.close.emit(this.selectedUserDtos);
  }

  ngOnInit() { }

  onSaveModel() {
    if (this.oldSelectedUserDtos !== this.selectedUserDtos) {
      this.saveModel.emit();
    }
  }

  onSaveState() {
    this.oldSelectedUserDtos = this.selectedUserDtos;
  }
}
