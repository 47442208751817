import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractGlobalBoardTableShortcutsComponent } from '@secca/shared/components/abstract-global-board-table-shortcuts.component';
import { CaseService } from '@secca/core/services/case.service';
import { ShortcutEnum, SortOrder } from '@secca/shared/models/enums';
import { TableSort } from '@secca/shared/models/table-sort';
import { CustomerInvoiceJobSalesOrder } from '../models/customer-invoice-job-sales-order';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { AutoCustomerInvoiceJobService } from '@secca/board/components/board-sales-order/services/auto-customer-invoice-job.service';
import { CustomerInvoiceJobFilterRequest } from '@secca/board/components/board-sales-order/models/customer-invoice-job-filter-request';
import { SettingsService } from '@secca/core/services/settings.service';
import { BoardNavigationStateService } from '@secca/board/components/board-task/services/board-navigation-state.service';

@Component({
  selector: 'app-board-sales-order-table',
  templateUrl: './board-sales-order-table.component.html',
  styleUrls: ['./board-sales-order-table.component.scss'],
})
@AutoUnsubscribe
export class BoardSalesOrderTableComponent extends AbstractGlobalBoardTableShortcutsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() set customerInvoiceJobs(data: CustomerInvoiceJobSalesOrder[]) {
    this._customerInvoiceJobs = data;
    this.numberOfRows = data?.length;
    this.isCheckAll = false;
    if (this._customerInvoiceJobs){
      this.expanded = new Array(this._customerInvoiceJobs.length);
      this._customerInvoiceJobs.forEach(salesOrder => {
        this.expanded.push(false);
      });
    }
    setTimeout(() => {
      this.selectedRowIndex = this.boardNavigationStateService.getState('board-sales-orders-cases-selected-row-index') ? parseInt(this.boardNavigationStateService.getState('board-sales-orders-cases-selected-row-index'), 10) : 0;
      this.scrollSalesOrdersPosition = this.boardNavigationStateService.getState('board-sales-orders-scroll-position') ? parseInt(this.boardNavigationStateService.getState('board-sales-orders-scroll-position'), 10) : 0;
      this.navigateToTable();
    }, 50);
  }
  get customerInvoiceJobs(): CustomerInvoiceJobSalesOrder[] {
    return this._customerInvoiceJobs;
  }
  @Output() sortingAction = new EventEmitter<TableSort>();
  @Output() navigateToCaseEvent: EventEmitter<number> = new EventEmitter();

  private _customerInvoiceJobs: CustomerInvoiceJobSalesOrder[];
  sortedOn: any = { salesOrderCreatedOn: true };
  sortOrder: any = {};
  expanded: boolean[];
  scrollSalesOrdersPosition = 0;
  isCheckAll = false;

  constructor(private caseService: CaseService,
              private autoCustomerInvoiceJobService: AutoCustomerInvoiceJobService,
              public settingsService: SettingsService,
              private boardNavigationStateService: BoardNavigationStateService) {
    super('tabelSalesOrders');
    this.shortcutSubscriptions.push(
      this.shortcutService.addShortcut({ keys: ShortcutEnum.NavigateTableRowAltEnter }).subscribe(a => {
        if (Number.isFinite(this.selectedRowIndex)) {
          this.navigateToCase(this.customerInvoiceJobs[this.selectedRowIndex].salesOrderId);
        }
      }),
    );
  }

  ngOnInit() {
    this.selectedRowIndex = this.boardNavigationStateService.getState('board-sales-orders-cases-selected-row-index') ? parseInt(this.boardNavigationStateService.getState('board-sales-orders-cases-selected-row-index'), 10) : 0;
    this.scrollSalesOrdersPosition = this.boardNavigationStateService.getState('board-sales-orders-scroll-position') ? parseInt(this.boardNavigationStateService.getState('board-sales-orders-scroll-position'), 10) : 0;
  }

  ngOnChanges() {
    setTimeout(() => this.setScrollPosition(), 500);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.boardNavigationStateService.saveState('board-sales-orders-scroll-position', JSON.stringify(this.scrollSalesOrdersPosition));
    this.boardNavigationStateService.saveState('board-sales-orders-cases-selected-row-index', JSON.stringify(this.selectedRowIndex));
  }

  expandSalesOrder(i: number, salesOrder: CustomerInvoiceJobSalesOrder) {
    const request = new CustomerInvoiceJobFilterRequest();
    request.caseNumber = salesOrder.caseNumber;
    request.salesOrderId = salesOrder.salesOrderId;
    this.expanded[i] = this.expanded[i] !== true;
    if (this.expanded[i] && salesOrder.details === null) {
      this.autoCustomerInvoiceJobService.getDetailsResponse(request).subscribe(res => {
        salesOrder.details = res.jobSalesOrders;
      });
    }
  }

  setScrollPosition() {
    if (this.scrollSalesOrdersPosition !== null && document.getElementById('tabelSalesOrders') && this.customerInvoiceJobs) {
      document.getElementById('tabelSalesOrders').scrollTop = this.scrollSalesOrdersPosition;
    }
  }

  onCaseTabelScroll() {
    this.scrollSalesOrdersPosition = document.getElementById('tabelSalesOrders').scrollTop;
  }

  sortedBy(column: string): string {
    return this.sortedOn[column] ? 'sorted-by' : '';
  }

  sortBy(column: string){
    this.sortedOn = {};
    this.sortedOn[column] = true;
    if (this.sortOrder[column] == null) {
      this.sortOrder[column] = SortOrder.asc;
    } else {
      if (this.sortOrder[column] === SortOrder.asc) {
        this.sortOrder[column] = SortOrder.desc;
      } else {
        this.sortOrder[column] = SortOrder.asc;
      }
    }
    this.sortingAction.emit(new TableSort({ sortBy: column, sortOrder: this.sortOrder[column] }));
  }

  getBrandImagesUrlForProfile(profileId: number) {
    return this.caseService.getBrandImagesUrlForProfile(profileId.toString());
  }

  navigateToCase(salesOrderId: number) {
    this.navigateToCaseEvent.emit(salesOrderId);
  }

  checkAllTasks() {
    this.isCheckAll = !this.isCheckAll;
    this._customerInvoiceJobs.forEach(salesOrder => {
      salesOrder.isCheck = this.isCheckAll;
    });
  }
}
