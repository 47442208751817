import { StakeholderTypeEnum } from '@secca/shared/models/enums';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { SubStakeholder } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholder';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { SubStakeholdersConfig } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholdersConfig';
import { StakeholderClassTypeEnum } from '@secca/case/components/case-plans/case-plans/add-service-order/models/enums';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import { DropdownWitIconDictionary } from '@secca/shared/models/dropdownWithIconDictionary';
import { SubStakeholders } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholders';

@Component({
  selector: 'app-sub-stakeholder',
  templateUrl: './sub-stakeholder.component.html',
  styleUrls: ['./sub-stakeholder.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SubStakeholderComponent implements OnChanges {
  @Input() isFirstItem: boolean;
  @Input() subStakeholdersConfig: SubStakeholdersConfig;
  @Input() stakeholdersDropdown: DropdownWitIconDictionary[];
  @Input() classDropdown: DropdownDictionary[];
  @Input() classDropdownSeeMedifOnly: DropdownDictionary[];
  @Input() inclDOBDropdown: DropdownDictionary[];
  @Input() inclPassportDropdown: DropdownDictionary[];
  @Output() delete: EventEmitter<SubStakeholder> = new EventEmitter();
  @Input() subStakeholders: SubStakeholders;
  @Input() disabled: boolean;
  @Input() showInvalidBackground: boolean;
  @Input() hideNip: boolean;

  nameInPassport = true;
  missingPassportDetails = false;

  _subStakeholder: SubStakeholder;
  _caseStakeholders: CaseStakeholder[];

  @Input() set subStakeholder(subStakeholder) {
    this._subStakeholder = subStakeholder;
    this.stakeholderChangeEvent();
  }

  get subStakeholder() {
    return this._subStakeholder;
  }

  @Input() set caseStakeholders(caseStakeholders: CaseStakeholder[]) {
    this._caseStakeholders = caseStakeholders;
    this.stakeholderChangeEvent();
  }

  get caseStakeholders(): CaseStakeholder[] {
    return this._caseStakeholders;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.stakeholdersDropdown || changes.caseStakeholders) {
      if (this.subStakeholder && this.subStakeholder.stakeholderId && this.caseStakeholders) {
        const selectedStakeholder = this.caseStakeholders.find(a => a.id === this.subStakeholder.stakeholderId);
        if (selectedStakeholder.person != null) {
          this.nameInPassport = selectedStakeholder.person.nameInPassport;
        }
      }
    }
  }

  setSelectedStakeholder(selectedStakeholder: CaseStakeholder) {
    if (
      this.subStakeholders.stakeholders
        .slice(0, this.subStakeholders.stakeholders.length - 1)
        .some(a => a.stakeholderId === selectedStakeholder.id)
    ) {
      this.delete.emit(this.subStakeholder);
      return;
    }
    // Reset
    this.nameInPassport = false;
    this.missingPassportDetails = false;
    // Set stakeholder values
    this.subStakeholder.stakeholderId = selectedStakeholder.id;
    this.subStakeholder.cabinClass = StakeholderClassTypeEnum.ECONOMY;
    this.subStakeholder.includeDateOfBirth = 'YES';
    this.subStakeholder.includePassportDetails = 'NO';
    this.stakeholderChangeEvent();
  }

  stakeholderChangeEvent() {
    if (!!this.subStakeholder.stakeholderId && !!this.caseStakeholders) {
      const realSelectedStakeholder = this.caseStakeholders.find(a => a.id === this.subStakeholder.stakeholderId);
      this.subStakeholder.stakeholderType = realSelectedStakeholder.stakeholderType;
      if (realSelectedStakeholder.person) {
        this.nameInPassport = realSelectedStakeholder.person.nameInPassport;
        if (!realSelectedStakeholder.person.passportNumber || !realSelectedStakeholder.person.passportExpiryDate) {
          this.missingPassportDetails = true;
          this.subStakeholder.includePassportDetails = 'MISSING';
        }
      }
      else {
        this.missingPassportDetails = true;
        this.subStakeholder.includePassportDetails = 'MISSING';
      }
    }
  }

  deleteStakeholder() {
    this.delete.emit(this.subStakeholder);
  }

  get isMandatoryAndSetStakeholderType(): boolean {
    this.tryToSetStakeholderType();
    if (this.subStakeholdersConfig.mandatoryAndSetStakeholderType != null) {
      return this.subStakeholdersConfig.mandatoryAndSetStakeholderType === this.subStakeholder.stakeholderType && this.isFirstItem;
    }
    return false;
  }

  showSeeMedifOnlyForEndUser(): boolean {
    if (this.subStakeholdersConfig.endUserAlwaysSeeMedifClass) {
      if (this.subStakeholder != null && this.subStakeholder.stakeholderType === StakeholderTypeEnum.endUser) {
        this.subStakeholder.cabinClass = StakeholderClassTypeEnum.SEE_MEDIF;
        return true;
      }
    }
    return false;
  }

  tryToSetStakeholderType() {
    if (this.caseStakeholders != null && this.subStakeholder != null && this.subStakeholder.stakeholderType == null) {
      const subStakeholder = this.caseStakeholders.find(a => a.id === this.subStakeholder.stakeholderId);
      if (subStakeholder != null) {
        this.subStakeholder.stakeholderType = subStakeholder.stakeholderType;
        if (subStakeholder.person && subStakeholder.person.nameInPassport) {
          this.nameInPassport = subStakeholder.person.nameInPassport;
        }
      }
    }
  }

  calculateStakeholderDropdownWidth() {
    if (!this.subStakeholder || !this.subStakeholder.stakeholderId) {
      return 100;
    } else {
      let width = 100;
      if (!this.subStakeholdersConfig.hideTrashIcon) {
        width = width - 4;
      }
      if (!this.subStakeholdersConfig.hideClass) {
        width = width - 29;
      }
      if (!this.subStakeholdersConfig.hideInclDateOfBirth) {
        width = width - 12;
      }
      if (!this.subStakeholdersConfig.hideInclPassportDetails) {
        width = width - 12;
      }
      return width;
    }
  }
}
