<div class="row justify-content-end header-amounts">
  <div class="col-8">
    <div class="d-flex">
      <div *permission="PermissionEnum.REFUND_CREATE; hideType: PermissionHideTypeEnum.REMOVE">
        <button (click)="addNewClaim()"
                class="primary-button ms-3"
                [class.primary-button-inactive]="disableAddRefund()"
                [disabled]="disableAddRefund()">
          <div [disablePopover]="disableAddRefundHover()" triggers="hover" placement="right" [ngbPopover]="refundPopOverText()">
            {{'case-economy-refund-add-refund' | translate}}
          </div>
        </button>
        <button (click)="addNewObjection()"
                class="add-objection-button ms-2"
                [class.primary-button-inactive]="disableAddObjection()"
                [disabled]="disableAddObjection()">
          <div [disablePopover]="disableAddObjectionHover()" triggers="hover" placement="right" [ngbPopover]="objectionPopOverText()">
            {{'case-economy-refund-add-objection' | translate}}
          </div>
        </button>
      </div>
      <div class="w-50 ms-2">
        <app-drop-down
          [placeholder]="'board-task-placeholder-all' | translate"
          [items]="filterableBalanceSheetType"
          [(selectedItemId)]="filteredType"
          (selectedItemIdChange)="updateFilter()"
        ></app-drop-down>
      </div>
    </div>
  </div>
  <div class="col-2">
    <div class="row">
      <div class="col text-end amount-name">{{ 'economy-supplier-invoice-list-total-saving' | translate }}</div>
    </div>
    <div class="row">
      <div class="col">
        <div class="d-flex">
          <div class="flex-fill text-end amount-value">{{ savingsAmount | number: '.2-2' }}</div>
          <div class="amount-currency">{{ caseCurrency }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-2">
    <div class="row">
      <div class="col text-end amount-name">{{ 'economy-supplier-invoice-list-total-settled-amount' | translate }}</div>
    </div>
    <div class="row">
      <div class="col">
        <div class="d-flex">
          <div class="flex-fill text-end amount-value">{{ settledAmount | number: '.2-2' }}</div>
          <div class="amount-currency">{{ caseCurrency }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-12 table-container" [class.zoomed]="settingsService.isZoomed()">
  <table class="primary-table fixed-header-table">
    <thead>
      <tr>
        <td class="invoice-id">
          <div class="expand-collapse-link" style="float: left" (click)="$event.stopPropagation()">
            <span (click)="expandAll();$event.stopPropagation()">
              <img src="/assets/icons/plus_square_icon.svg" width='16' height='16' [title]="'expand-all' | translate"/>
            </span> /
            <span (click)="collapseAll();$event.stopPropagation()">
              <img src="/assets/icons/minus_square_icon.svg" width='16' height='16' [title]="'collapse-all' | translate"/>
            </span>
          </div>
          <div style="float: left;">
            {{ 'case-economy-supplier-invoice-invoice-id' | translate }}
          </div>
        </td>
        <td
          class="thead"
          sortColumn
          [sortKey]="'creditorId'"
          (sortEvent)="setSortKey($event)"
          [data]="filteredBalanceSheetEntries"
          [ngClass]="{ 'sort-selected': sortKey === 'creditorId' }"
        >
          {{ 'case-economy-supplier-invoice-creditor' | translate }}
        </td>
        <td
          class="thead dates"
          sortColumn
          [sortKey]="'invoiceDate'"
          (sortEvent)="setSortKey($event)"
          [data]="filteredBalanceSheetEntries"
          [ngClass]="{ 'sort-selected': sortKey === 'invoiceDate' }"
        >
          {{ 'case-economy-supplier-invoice-invoice-date' | translate }}
        </td>
        <td
          class="thead dates"
          sortColumn
          [sortKey]="'receivedDate'"
          (sortEvent)="setSortKey($event)"
          [data]="filteredBalanceSheetEntries"
          [ngClass]="{ 'sort-selected': sortKey === 'receivedDate' }"
        >
          {{ 'case-economy-supplier-invoice-received-date' | translate }}
        </td>
        <td
          class="thead dates"
          sortColumn
          [sortKey]="'dueDate'"
          (sortEvent)="setSortKey($event)"
          [data]="filteredBalanceSheetEntries"
          [ngClass]="{ 'sort-selected': sortKey === 'dueDate' }"
        >
          {{ 'case-economy-supplier-invoice-due-date' | translate }}
        </td>
        <td
          class="thead processStatus"
          sortColumn
          [sortKey]="'getStatus'"
          (sortEvent)="setSortKey($event)"
          [data]="filteredBalanceSheetEntries"
          [ngClass]="{ 'sort-selected': sortKey === 'getStatus' }"
        >
          {{ 'case-economy-supplier-invoice-progress' | translate }}
        </td>
        <td
          class="thead"
          sortColumn
          [sortKey]="'getInvoiceAmountExchanged'"
          (sortEvent)="setSortKey($event)"
          [data]="filteredBalanceSheetEntries"
          [ngClass]="{ 'sort-selected': sortKey === 'getInvoiceAmountExchanged' }"
        >
          {{ 'case-economy-supplier-invoice-invoice-amount' | translate }}
        </td>
        <td
          class="thead"
          sortColumn
          [sortKey]="'saved'"
          (sortEvent)="setSortKey($event)"
          [data]="filteredBalanceSheetEntries"
          [ngClass]="{ 'sort-selected': sortKey === 'saved' }"
        >
          {{ 'case-economy-supplier-invoice-invoice-saving' | translate }}
        </td>
        <td
          class="thead"
          sortColumn
          [sortKey]="'settled'"
          (sortEvent)="setSortKey($event)"
          [data]="filteredBalanceSheetEntries"
          [ngClass]="{ 'sort-selected': sortKey === 'settled' }"
        >
          {{ 'case-economy-supplier-invoice-invoice-settled' | translate }}
        </td>
        <td class="thead"></td>
      </tr>
    </thead>
    <tbody class="theBody">
      <ng-container *ngFor="let entry of filteredBalanceSheetEntries; index as i">
        <tr class="odd">
          <td class="invoice-id">
            <div class="invoice-id-row">
              <div class="invoice-number">
                {{ entry.getInvoiceNumber() }} {{ entry.getType()===BalanceSheetEntryEnum.REFUND ? ((entry.isObjection() ? 'case-economy-objection' : 'case-economy-refund') | translate) : '' }}
              </div>
              <app-open-invoice-pdf
                *ngIf="medicalEscortAuthorized(entry) && isSupplierInvoice(entry)"
                [invoiceDocumentId]="entry.getInvoiceDocumentId()"
              ></app-open-invoice-pdf>
            </div>
            <div class="invoice-id-row">
              <div *ngIf="medicalEscortAuthorized(entry)" class="manage" (click)="isSupplierInvoice(entry) ? openManageInvoice(entry.getId()) : openManageRefund(entry)">
                {{ 'case-economy-supplier-invoice-manage' | translate }}
              </div>
              <div *ngIf="!!entry.getItems() && entry.getItems().length > 0" (click)="expand(i)">
                <img class="icon" src="/assets/icons/ArrowDown.svg" [ngClass]="{ 'icon-rotated': expanded[i] }" />
              </div>
            </div>
            <div *ngIf="entry.internalRemark">
              <span class="label-name">{{ 'case-economy-supplier-invoice-internal-remark' | translate }}:</span> {{entry.internalRemark}}
            </div>
          </td>
          <td>
            <div *ngIf="isSupplierInvoice(entry)">
            {{ entry.getCreditorId() }}
            <div class="creditor-column-label">{{ entry.getCreditorName() }}</div>
            </div>
            <div *ngIf="!isSupplierInvoice(entry) && entry.getStakeholderId() != null">
                <img [src]="getStakeholderIconUrl(entry.getStakeholderId())">
                <span>
                  <span>
                    {{getStakeholderName(entry.getStakeholderId())}}
                  </span>
                </span>
            </div>
          </td>
          <td>
            <div *ngIf="isSupplierInvoice(entry)">
            {{ entry.getInvoiceDate() | date: 'dd LLL yyyy' }}
            </div>
          </td>
          <td>
            {{ entry.getReceivedDate() | date: 'dd LLL yyyy' }}
          </td>
          <td>
            {{ entry.getDueDate() | date: 'dd LLL yyyy' }}
          </td>
          <td class="pt-2 process-bar-column">
            <app-economy-process-bar
              [eventsToDisplay]="getEventsToDisplay()" [hidden]="[]" [progress]="entry.getProgress()"
              [identifier]="entry.getInvoiceNumber()" [type]="entry.getType()"
            ></app-economy-process-bar>
          </td>
          <td>
            <div class="d-flex" *ngIf="medicalEscortAuthorized(entry)">
              <div class="flex-fill currency-case">{{ entry.getInvoiceAmountExchanged()?.currency }}</div>
              <div class="amount-case">{{ entry.getInvoiceAmountExchanged()?.amount | number: '.2-2' }}</div>
            </div>
            <div class="d-flex" *ngIf="medicalEscortAuthorized(entry)">
              <div class="flex-fill currency-refund">{{ entry.getInvoiceAmount()?.currency }}</div>
              <div class="amount-refund">{{ entry.getInvoiceAmount()?.amount | number: '.2-2' }}</div>
            </div>
          </td>
          <td>
            <div class="d-flex" *ngIf="medicalEscortAuthorized(entry) && isBalanceSheetApproved(entry)">
              <div class="flex-fill currency">{{ entry.getInvoiceAmountExchanged()?.currency }}</div>
              <div class="amount-positive">{{ entry.getReductionAmount() | number: '.2-2' }}</div>
            </div>
          </td>
          <td>
            <div class="d-flex" *ngIf="medicalEscortAuthorized(entry) && isBalanceSheetApproved(entry)">
              <div class="flex-fill currency">{{ entry.getInvoiceAmountExchanged()?.currency }}</div>
              <div class="amount">{{ entry.getSettledAmount() | number: '.2-2' }}</div>
            </div>
          </td>
          <td>
            <div class="d-flex" *ngIf="entry.getType() === BalanceSheetEntryEnum.SUPPLIER_INVOICE && SupplierInvoiceStatus.error === entry.getStatus()">
              <div *permission="PermissionEnum.SUPPLIER_INVOICE_UPDATE">
                  <div class="context-menu" (click)="setShowEntryMenu(i)" (clickOutside)="clearShowEntryMenu(i)">
                    <div class="context-menu-dot"></div>
                    <div class="context-menu-dot"></div>
                    <div class="context-menu-dot"></div>
                  </div>
                  <div class="pop-over" *ngIf="getShowEntryMenu(i)">
                    <div class="pop-over-triangle"></div>
                      <p (click)="supplierInvoiceResetRetry(entry)" class="context-menu-item">{{ 'case-economy-supplier-invoice-reset-retry' | translate }}</p>
                  </div>
              </div>
            </div>
            <div class="d-flex"
                 *ngIf="entry.getType()===BalanceSheetEntryEnum.REFUND
                 && [RefundStatus.APPROVED, RefundStatus.PAID, RefundStatus.ERROR].includes(RefundStatus[entry.getStatus()])">
              <div *permission="PermissionEnum.REFUND_DELETE">
                <div class="context-menu" (click)="setShowEntryMenu(i)" (clickOutside)="clearShowEntryMenu(i)">
                  <div class="context-menu-dot"></div>
                  <div class="context-menu-dot"></div>
                  <div class="context-menu-dot"></div>
                </div>
                <div class="pop-over" *ngIf="getShowEntryMenu(i)">
                  <div class="pop-over-triangle"></div>
                  <p (click)="deleteRefund(entry)" class="context-menu-item"
                       [ngClass]="{ 'context-menu-item-disabled': RefundStatus.ERROR !== RefundStatus[entry.getStatus()] }">
                      {{ 'case-economy-refund-delete' | translate }}
                    </p>
                    <p class="context-menu-item context-menu-item-disabled">{{ 'case-economy-refund-credit' | translate }}</p>
                    <p class="context-menu-item context-menu-item-disabled">{{ 'case-economy-refund-credit-move' | translate }}</p>
                    <p class="context-menu-item context-menu-item-disabled">{{ 'case-economy-refund-credit-move-malpractice' | translate }}</p>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <ng-container *ngFor="let entryItem of entry.getItems()">
          <table class="invoice-item-table">
            <tr *ngIf="expanded[i] && entryItem.getServiceItemCode() !== null" class="even">
              <td colspan="3" class="service-order">
                <app-drop-down-service-order
                  [selectedItemId]="entryItem.getServiceOrderId()"
                  [displayOnly]="true"
                  [serviceOrders]="caseServiceOrders"
                ></app-drop-down-service-order>
              </td>
              <td class="service-item-name">{{ getServiceItemName(entryItem.getServiceItemCode()) }}</td>
              <td class="invoice-item-amount">
                <ng-template #popContent><b>Quantity: </b>{{entryItem.getQuantity() + ' ' + entryItem.getQuantityUnit()}}<br/><b>Unit price: </b>{{(entryItem.getOriginalAmount()/entryItem.getQuantity()) | number: '.2'}} {{entry.getInvoiceAmount().currency}} </ng-template>
                <div class="d-flex" [disablePopover]="entryItem.getQuantity() == null"
                     triggers="hover"
                     placement="right"
                     [ngbPopover]="popContent">
                  <div class="flex-fill currency-case">{{ entry.getInvoiceAmountExchanged()?.currency }}</div>
                  <div class="amount-case">{{ entryItem.getOriginalAmount() * entry.getExchangeRate() | number: '.2-2' }}</div>
                </div>
                <div class="d-flex">
                  <div class="flex-fill currency-refund">{{ entry.getInvoiceAmount()?.currency }}</div>
                  <div class="amount-refund">{{ entryItem.getOriginalAmount() | number: '.2-2' }}</div>
                </div>
              </td>
              <td class="invoice-item-amount">
                <div class="d-flex" *ngIf="isBalanceSheetApproved(entry)">
                  <div class="flex-fill currency">{{ entry.getInvoiceAmountExchanged()?.currency }}</div>
                  <div class="amount">{{ calculateItemTotalSavings(entry, entryItem) | number: '.2-2' }}</div>
                </div>
              </td>
              <td class="invoice-item-amount">
                <div class="d-flex" *ngIf="isBalanceSheetApproved(entry)">
                  <div class="flex-fill currency">{{ entry.getInvoiceAmountExchanged()?.currency }}</div>
                  <div class="amount">{{ calculateItemSettled(entry, entryItem) | number: '.2-2' }}</div>
                </div>
              </td>
            </tr>
          </table>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
</div>
<app-edit-refund #editRefund (refresh)="onRefresh()"></app-edit-refund>
