import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class CaseManagementSystemService extends BaseService {
  constructor(private http: HttpClient, private settingsService: SettingsService) {
    super(settingsService);
  }

  public getConnectedServicesInfo(): Observable<any> {
    return this.http.get(this.settingsService.getBffEndpoint() + 'services-info');
  }
}
