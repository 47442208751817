import { RefundTaskViewModel } from '@secca/board/components/board-task/models/refund-task-view.model';

export class RefundTaskFilterResponse {
  totalNumber: number;
  taskViewModels: RefundTaskViewModel[];

  public constructor(init?: Partial<RefundTaskFilterResponse>) {
    Object.assign(this, init);
  }
}
