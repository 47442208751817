<div class="stakeholder-name">
  <img class="stakeholder-icon" [src]="getIconUrl(stakeholder)" />
  <span *ngIf="stakeholder.person">
    {{ stakeholder.person.firstName }}
    {{ stakeholder.person.surname }}
    <span *ngIf="stakeholder.person.gender && stakeholder.person.age">, {{ stakeholder.person.gender }}{{ stakeholder.person.age }} </span>
  </span>
  <span *ngIf="stakeholder.company">
    {{ stakeholder.company.name  }}
  </span>
</div>
