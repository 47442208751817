import { AssigneeEnum, ServiceTypeEnum, StakeholderTypeEnum, StatusTypes } from '@secca/shared/models/enums';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ServiceTypeBase } from '@secca/case/components/case-plans/case-plans/add-service-order/models/serviceTypeBase';
import { ServiceOrder } from '@secca/shared/models/service-order/service-order';
import { InvestigationServiceOrderExtension } from '@secca/shared/models/service-order/investigation-service-order-extension';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { SelectionAwareStakeholder } from '@secca/case/components/case-plans/case-plans/add-service-order/models/SelectionAwareStakeholder';

@Component({
  selector: 'app-investigation-left-side',
  templateUrl: './investigation-type-left-side.component.html',
  styleUrls: ['./investigation-type-left-side.component.scss']
})
export class InvestigationTypeLeftSideComponent extends ServiceTypeBase implements OnInit, OnChanges {
  disableEditingDescription = false;
  _investigation: InvestigationServiceOrderExtension;
  private caseStakeholders: CaseStakeholder[];
  selectionAwareStakeholders: SelectionAwareStakeholder[];
  isCancelAllowed = false;
  @Input() caseId: number;
  @Input() serviceOrder: ServiceOrder;
  @Input() assignee: AssigneeEnum;
  @Input() leftSideValid = false;
  @Input()
  set investigation(investigation: InvestigationServiceOrderExtension) {
    this._investigation = investigation;
    this.isValid();
  }
  get investigation() {
    return this._investigation;
  }
  @Input() set stakeholders(stakeholders: CaseStakeholder[]) {
    this.caseStakeholders = stakeholders;
    if (stakeholders) {
      this.selectionAwareStakeholders = stakeholders.map(stakeholder => new SelectionAwareStakeholder(stakeholder, false));
      this.selectStakeholders();
    }
  }

  private selectStakeholders() {
    if (this.selectionAwareStakeholders !== null && this.selectionAwareStakeholders !== undefined) {
      this.selectionAwareStakeholders.forEach(s => {
        s.isSelected = false;
      });
      this.serviceOrder.caseStakeholderIds = this.selectionAwareStakeholders.map(a => +a.stakeholder.id);
    }
  }

  constructor() {

    super();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.serviceOrder.status !== StatusTypes.CANCELLED) {

    }
  }

  ngOnInit() {
    if(this.serviceOrder.status === StatusTypes.EXPECTED){
      this.serviceOrder.status = StatusTypes.COMMITTED;
      return;
    }
    if(this.serviceOrder.status === StatusTypes.COMMITTED){
      this.isCancelAllowed = true;
    }
  }

  isValid() {
    this.serviceOrderTypeState.isLeftSideValid = true;
  }

  changeStatus(val: StatusTypes, event: any = null) {
    if (val === this.serviceOrder.status && event != null) {
      return false;
    }
    if (val !== this.serviceOrder.status) {
      this.serviceOrder.status = val;
    }
  }

  expectedDisabled() {
    return true;
  }

  cancelledDisabled() {
    return !(this.isCancelAllowed && (this.serviceOrder.status === StatusTypes.COMMITTED));
  }

  committedDisabled() {
    return this.serviceOrder.status !== StatusTypes.CANCELLED;
  }

  get StatusTypes() {
    return StatusTypes;
  }
  dataChanged() {
    this.checkIfLeftSideIsValid();
  }
  checkIfLeftSideIsValid() {
    if (this.serviceOrder.coverageTypeId != null) {
      this.serviceOrderTypeState.isLeftSideValid = true;
    }
    else {
      this.serviceOrderTypeState.isLeftSideValid = true;
    }
  }
  get isNotMedicalEscortServiceOrderType(): boolean {
    return this.serviceOrder.type !== ServiceTypeEnum.MEDICAL_ESCORT && this.serviceOrder.type !== ServiceTypeEnum.OTHER_REFUND_REQUEST;
  }
  get selectedStakeholders(): CaseStakeholder[] {
    if (this.caseStakeholders == null) {
      return null;
    } else {
      const selectedStakeholders = this.serviceOrder.getSelectedStakeholderIds();
      if (selectedStakeholders) {
        return this.caseStakeholders.filter(stakeholder =>
          selectedStakeholders.includes(stakeholder.id)
        );
      }
      return this.caseStakeholders.filter(stakeholder => stakeholder.hasRole(StakeholderTypeEnum.endUser));
    }
  }
  isOddNumber(index: number) {
    if (index % 2 === 0) {
      return true;
    }
    return false;
  }

}
