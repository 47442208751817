<div class="board-content">
  <ul ngbNav #nav="ngbNav" class="nav-tabs" [(activeId)]="active" (navChange)="onTabChange($event.nextId)">
     <li [ngbNavItem]="TabsEnum.GENERAL" [id]=" TabsEnum.GENERAL ">
      <a ngbNavLink>{{  'board-case-general' | translate }} <ng-container *ngIf="caseFilterResponse">({{caseFilterResponse.totalNumber}})</ng-container></a>
      <ng-template ngbNavContent>
        <div class="top-panel">
          <div class="d-flex">
            <div class="px-2">
              <button class="icon-button" (click)="refreshBoardCases()">
                <img src="../../../../assets/icons/refresh.svg" />
              </button>
            </div>
            <div class="px-2 ms-3">
              <div class="calendar">
                <app-input-date-time
                  name="{{'board-case-from' | translate}}"
                  [placeholder]="dateTimePlaceholder"
                  [(date)]="caseFilterRequest.fromDate"
                  [withImage]="true"
                  [timeZone]="'local'"
                  (dateChange)="loadCaseViewModels(caseFilterRequest, true)"
                ></app-input-date-time>
              </div>
            </div>
            <div class="px-2">
              <div class="calendar">
                <app-input-date-time
                  name="{{'board-case-to' | translate}}"
                  [placeholder]="dateTimePlaceholder"
                  [(date)]="caseFilterRequest.toDate"
                  [withImage]="true"
                  [timeZone]="'local'"
                  (dateChange)="loadCaseViewModels(caseFilterRequest, true)"
                ></app-input-date-time>
              </div>
            </div>
            <div class="px-2">
              <div class="status">
                <app-drop-down
                [name]="'board-task-incident-covered-status' | translate"
                [placeholder]="'board-case-placeholder-all' | translate"
                [items]="caseIncidentCoveredStatus"
                [(selectedItemId)]="caseFilterRequest.incidentCoveredStates"
                [multiple]="true"
                [multiSelectedLabel]="
                  'board-task-filter-some-selected' | translate: { number: caseFilterRequest.incidentCoveredStates.length }
                "
                (selectedItemIdChange)="loadCaseViewModels(caseFilterRequest, true)"
              ></app-drop-down>
              </div>
            </div>
            <div class="px-2 customerProfileId">
              <app-multiple-select-customer-profile
                [name]="'board-task-table-customer-profile-multible' | translate"
                [maxElements]="10"
                [selectedItemsCustomerProfiles]="caseFilterRequest.customerProfileIds"
                (selectedItemsChange)="selectedCustomerProfilesChange($event)"
                #multipleSelectCustomerProfileComponent
              ></app-multiple-select-customer-profile>
            </div>
            <div class="px-2">
              <div class="status">
                <div class="common-drop-down-arrow drop-down">
                  <app-drop-down
                  [name]="'board-case-table-team-status' | translate"
                  [placeholder]="'board-case-placeholder-all' | translate"
                  [items]="status"
                  [(selectedItemId)]="caseFilterRequest.statuses"
                  [multiple]="true"
                  [multiSelectedLabel]="
                    'board-task-filter-some-selected' | translate: { number: caseFilterRequest.statuses.length }
                  "
                  (selectedItemIdChange)="loadCaseViewModels(caseFilterRequest, true)"
                ></app-drop-down>
                </div>
              </div>
            </div>

            <div class="px-2">
              <div class="status">
                <div class="common-drop-down-arrow drop-down">
                  <app-drop-down
                    [name]="'board-case-table-claim-status' | translate"
                    [placeholder]="'board-case-placeholder-not-relevant' | translate"
                    [items]="claimsDropDownStatus"
                    [(selectedItemId)]="claimStatus"
                    [multiple]="true"
                    [multiSelectedLabel]="claimsTypeSelectText()"
                    (selectedItemIdChange)="claimsTypeSelectChange()"
                ></app-drop-down>
                </div>
              </div>
            </div>
            <div class="px-2">
              <button class="icon-button">
                <img src="../../../../assets/icons/clear-filter.svg" (click)="clearBoardCaseFilter()" />
              </button>
            </div>
          </div>
        </div>
        <ng-container *ngIf="!boardCasesRefreshing">
          <app-board-cases-table
            [casesSorting]="casesSorting"
            (sortingAction)="sortingAction($event)"
            [caseSelect]="'open'"
            [boardCases]="boardCases"
            >
          </app-board-cases-table>
          <app-pagination [currentPageIndex]="caseFilterRequest?.pageIndex" (pagingChangedEvent)="paginationChanged($event)"
                          [totalNumber]="caseFilterResponse?.totalNumber" [pageSize]="caseFilterRequest?.pageSize"></app-pagination>
        </ng-container>
        <ng-container *ngIf="boardCasesRefreshing">
          <div class="d-flex justify-content-center">
            <h4>{{"board-task-table-refreshing" | translate}}</h4>
          </div>
        </ng-container>
      </ng-template>
    </li>


    <li [ngbNavItem]="TabsEnum.MEDICAL" [id]=" TabsEnum.MEDICAL ">
      <a ngbNavLink>{{  'board-case-medical' | translate }}<ng-container *ngIf="caseMedicalFilterRequest">
           {{ getCaseCount(TabsEnum.MEDICAL) }}
          </ng-container></a>
      <ng-template ngbNavContent>
        <div class="top-panel">
          <div class="d-flex">
            <div class="px-2">
              <button class="icon-button" (click)="refreshBoardCases()">
                <img src="../../../../assets/icons/refresh.svg" />
              </button>
            </div>
            <div class="ms-3 px-2">
              <div class="calendar">
                <app-input-date-time
                  name="{{'board-case-medical-start' | translate}}"
                  [placeholder]="dateTimePlaceholder"
                  [(date)]="caseMedicalFilterRequest.fromDate"
                  [withImage]="true"
                  [timeZone]="'local'"
                  [minDate]="minMoment"
                  [maxDate]="maxMoment"
                  (dateChange)="loadCaseViewModels(caseMedicalFilterRequest, true)"
                ></app-input-date-time>
              </div>
            </div>
            <div class="px-2">
              <div class="calendar">
                <app-input-date-time
                  name=" "
                  [placeholder]="dateTimePlaceholder"
                  [(date)]="caseMedicalFilterRequest.toDate"
                  [withImage]="true"
                  [timeZone]="'local'"
                  [minDate]="minMoment"
                  [maxDate]="maxMoment"
                  (dateChange)="loadCaseViewModels(caseMedicalFilterRequest, true)"
                ></app-input-date-time>
              </div>
            </div>
            <div class="px-2 customerProfileId">
                <app-multiple-select-customer-profile
                  [name]="'board-task-table-customer-profile-multible' | translate"
                  [maxElements]="10"
                  [selectedItemsCustomerProfiles]="caseMedicalFilterRequest.customerProfileIds"
                  (selectedItemsChange)="selectedMedicalCustomerProfilesChange($event)"
                  #multipleSelectCustomerProfileMedicalComponent
                ></app-multiple-select-customer-profile>
            </div>
            <div class="px-2">
              <div class="status">
                <div class="common-drop-down-arrow drop-down">
                  <app-drop-down
                  [name]="'board-case-table-assessment-type' | translate"
                  [placeholder]="'board-case-placeholder-all' | translate"
                  [items]="assessmentTypes"
                  [(selectedItemId)]="caseMedicalFilterRequest.assessmentType"
                  [multiple]="true"
                  [multiSelectedLabel]="
                    'board-task-filter-some-selected' | translate: { number: caseMedicalFilterRequest.assessmentType.length }
                  "
                  (selectedItemIdChange)="loadCaseViewModels(caseMedicalFilterRequest, true)"
                ></app-drop-down>
                </div>
              </div>
            </div>
            <div class="px-2">
              <div class="status">
                <div class="common-drop-down-arrow drop-down">
                  <app-drop-down
                    [name]="'board-case-table-medical-status' | translate"
                    [placeholder]="'board-case-placeholder-all' | translate"
                    [items]="status"
                    [(selectedItemId)]="caseMedicalFilterRequest.medicalStatus"
                    [multiple]="true"
                    [multiSelectedLabel]="'board-task-filter-some-selected' | translate: { number: caseMedicalFilterRequest.medicalStatus.length }"
                    (selectedItemIdChange)="loadCaseViewModels(caseMedicalFilterRequest, true)"
                ></app-drop-down>
                </div>
              </div>
            </div>
            <div class="px-2">
                <button class="icon-button" (click)="clearBoardMedicalCaseFilter()">
                  <img src="../../../../assets/icons/clear-filter.svg"  />
                </button>
            </div>
          </div>
        </div>
        <ng-container *ngIf="!boardCasesRefreshing">
          <app-board-medical-cases-table
            [casesSorting]="medicalCasesSorting"
            (sortingAction)="sortingActionMedical($event)"
            [caseSelect]="'open'"
            [boardMedicalCases]="boardMedicalCase"
            >
        </app-board-medical-cases-table>
          <app-pagination [currentPageIndex]="caseMedicalFilterRequest?.pageIndex" (pagingChangedEvent)="paginationChanged($event)"
                          [totalNumber]="caseMedicalFilterResponse?.totalNumber" [pageSize]="caseMedicalFilterRequest?.pageSize"></app-pagination>
        </ng-container>

        <ng-container *ngIf="boardCasesRefreshing">
          <div class="d-flex justify-content-center">
            <h4>{{"board-task-table-refreshing" | translate}}</h4>
          </div>
        </ng-container>
      </ng-template>
    </li>
    <li [ngbNavItem]="TabsEnum.TRANSPORT" [id]=" TabsEnum.TRANSPORT ">
      <a ngbNavLink>{{  'board-case-transport' | translate }} <ng-container *ngIf="caseTransportRequest">{{ getCaseCount(TabsEnum.TRANSPORT) }}</ng-container></a>
      <ng-template ngbNavContent>
        <div class="top-panel">
          <div class="d-flex">
            <div class="ms-auto px-2">
              <app-input-date-time
                name="{{'board-case-event-incident-start' | translate}}"
                [placeholder]="dateTimePlaceholderIncidentEvent"
                [(date)]="caseTransportRequest.incidentFromDate"
                [withImage]="true"
                [timeZone]="'local'"
                (dateChange)="loadCaseViewModels(caseTransportRequest, true)"
            ></app-input-date-time>
            </div>
            <div class="px-2">
              <app-input-date-time
                name="{{'board-case-event-incident-end' | translate}}"
                [placeholder]="dateTimePlaceholderIncidentEvent"
                [(date)]="caseTransportRequest.incidentToDate"
                [withImage]="true"
                [timeZone]="'local'"
                (dateChange)="loadCaseViewModels(caseTransportRequest, true)"
              ></app-input-date-time>
            </div>
            <div class="px-2 repatriation-margin">
              <div class="status">
                <div class="common-drop-down-arrow drop-down">
                  <app-drop-down
                    [name]="'board-case-repatriation-form-expected' | translate"
                    [placeholder]="'board-task-placeholder-all' | translate"
                    [items]="repatriationForm"
                    [selectedItemId]="caseTransportRequest.repatriationForm"
                    [multiple]="true"
                    (selectedItemIdChange)="selectedRepatriationFormChanged($event)"
                    [multiSelectedLabel]="
                      'board-task-filter-some-selected' | translate: { number: caseTransportRequest.repatriationForm.length }
                    " >
                  </app-drop-down>
                </div>
              </div>
            </div>

            <div class="px-2 cauntry-margin">
              <div class="status">
                <div class="common-drop-down-arrow drop-down" *ngIf="caseDetails.length != 0">
                  <app-drop-down
                    [name]="'board-case-details' | translate"
                    [placeholder]="'board-case-placeholder-all' | translate"
                    [items]="caseDetails"
                    [(selectedItemId)]="caseTransportRequest.caseDetails"
                    [multiple]="true"
                    [multiSelectedLabel]="
                      'board-task-filter-some-selected' | translate: { number: caseTransportRequest.caseDetails.length }
                    "
                    (selectedItemIdChange)="loadCaseViewModels(caseTransportRequest, true)"
                  ></app-drop-down>
                </div>
              </div>
            </div>
            <div class="button-container">
              <button class="icon-button ms-3 mt-0" (click)="refreshBoardCases()">
                <img src="../../../../assets/icons/refresh.svg" />
              </button>
              <button class="icon-button mt-0" (click)="clearBoardTransportCaseFilter()">
                <img src="../../../../assets/icons/clear-filter.svg"  />
              </button>
              <div class="export-boks" (click)="onExportTransportButtonClicked()">
                <img src="../assets/icons/export.svg"
                  [ngbPopover]="'export' | translate"
                  placement="auto"
                  triggers="hover" >
              </div>
            </div>
          </div>
          <div class="d-flex mt-1" style="margin-right: 141px;">
            <div class="ms-auto px-2">

              <app-input-date-time
                name="{{'board-case-transport-start' | translate}}"
                [placeholder]="dateTimePlaceholderIncidentEvent"
                [(date)]="caseTransportRequest.fromDate"
                [withImage]="true"
                [timeZone]="'local'"
                (dateChange)="loadCaseViewModels(caseTransportRequest, true)"
              ></app-input-date-time>
            </div>
            <div class="px-2">
                <app-input-date-time
                  name="{{'board-case-transport-end' | translate}}"
                  [placeholder]="dateTimePlaceholderIncidentEvent"
                  [(date)]="caseTransportRequest.toDate"
                  [withImage]="true"
                  [timeZone]="'local'"
                  (dateChange)="loadCaseViewModels(caseTransportRequest, true)"
                ></app-input-date-time>
            </div>

            <div class="px-2 customerProfileId">
                <app-multiple-select-customer-profile
                  [name]="'board-task-table-customer-profile-multible' | translate"
                  [maxElements]="12"
                  [selectedItemsCustomerProfiles]="caseTransportRequest.customerProfileIds"
                  (selectedItemsChange)="selectedTranspoprtCustomerProfilesChange($event)"
                  #multipleSelectCustomerProfileTransportComponent
                ></app-multiple-select-customer-profile>
            </div>

            <div class="px-2 ">
              <div class="status">
                <div class="common-drop-down-arrow drop-down">
                  <app-drop-down
                  [name]="'search-case-case-type' | translate"
                  [placeholder]="'board-case-placeholder-all' | translate"
                  [items]="caseTypes"
                  [(selectedItemId)]="caseTransportRequest.caseTypeCodes"
                  [multiple]="true"
                  [multiSelectedLabel]="
                    'board-task-filter-some-selected' | translate: { number: caseTransportRequest.caseTypeCodes.length }
                  "
                  (selectedItemIdChange)="loadCaseViewModels(caseTransportRequest, true)"
                ></app-drop-down>
                </div>
              </div>
            </div>

            <div class="px-2 cauntry-margin">
              <div class="status">
                <div class="common-drop-down-arrow drop-down" *ngIf="countryListDropdown.length != 0">
                  <app-drop-down [name]="'board-case-current-country' | translate"
                    [placeholder]="'board-task-placeholder-all' | translate"
                    [items]="countryListDropdown"
                    [selectedItemId]="caseTransportRequest.currentCountry"
                    [multiple]="true"
                    (selectedItemIdChange)="selectedCurrentCountryChanged($event)"
                    [multiSelectedLabel]="
                      'board-task-filter-some-selected' | translate: { number: caseTransportRequest.currentCountry.length }
                    " >
                  </app-drop-down>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="!boardCasesRefreshing">
          <app-board-transport-cases-table
            [casesSorting]="transportCasesSorting"
            (sortingAction)="sortingActionTransport($event)"
            [caseSelect]="'open'"
            [boardTransportCase]="boardTransportCase"
            [caseTypes]="caseTypes"
          >
          </app-board-transport-cases-table>
          <app-pagination [currentPageIndex]="caseTransportRequest?.pageIndex" (pagingChangedEvent)="paginationChanged($event)"
                          [totalNumber]="CaseTransportFilterResponse?.totalNumber" [pageSize]="caseTransportRequest?.pageSize"></app-pagination>
        </ng-container>
        <ng-container *ngIf="boardCasesRefreshing">
          <div class="d-flex justify-content-center">
            <h4>{{"board-task-table-refreshing" | translate}}</h4>
          </div>
        </ng-container>
      </ng-template>
    </li>
    <li [ngbNavItem]="TabsEnum.EVENTS" [id]=" TabsEnum.EVENTS ">
      <a ngbNavLink>{{  'board-case-events' | translate }}<ng-container *ngIf="caseIncidentEventsRequest">
        {{ getCaseCount(TabsEnum.EVENTS) }}
          </ng-container></a>
      <ng-template ngbNavContent>
        <div class="top-panel">
          <div class="d-flex">
            <div class="ms-auto px-2">
              <div class="events">
                <div class="common-drop-down-arrow drop-down">
                  <app-drop-down [name]="'board-task-incident-event' | translate"
                    [placeholder]="'board-task-placeholder-all' | translate"
                    [items]="incidentEventsDropdown"
                    [selectedItemId]="caseIncidentEventsRequest.eventKeys"
                    [multiple]="true"
                    (selectedItemIdChange)="selectedEventChanged($event)"
                    [multiSelectedLabel]="
                      'board-task-filter-some-selected' | translate: { number: caseIncidentEventsRequest.eventKeys.length }"
                    >
                  </app-drop-down>
                </div>
              </div>
            </div>

            <div class="px-2">
              <app-input-date-time
                name="{{'board-case-event-incident-start' | translate}}"
                [placeholder]="dateTimePlaceholderIncidentEvent"
                [(date)]="caseIncidentEventsRequest.incidentFromDate"
                [withImage]="true"
                [timeZone]="'local'"
                (dateChange)="loadCaseViewModels(caseIncidentEventsRequest, true)"
            ></app-input-date-time>
            </div>
            <div class="px-2">
              <app-input-date-time
                name="{{'board-case-event-incident-end' | translate}}"
                [placeholder]="dateTimePlaceholderIncidentEvent"
                [(date)]="caseIncidentEventsRequest.incidentToDate"
                [withImage]="true"
                [timeZone]="'local'"
                (dateChange)="loadCaseViewModels(caseIncidentEventsRequest, true)"
              ></app-input-date-time>
            </div>
            <div class="px-2">
              <span class="input-name">Cause</span>
              <div class="incident-container">
                <div class="incident-dropdown">
                  <app-drop-down
                    [placeholder]="'board-task-placeholder-all' | translate"
                    #caseCauseLevel1Component
                    [items]="incidentCausesLevel1"
                    [(selectedItemId)]="caseIncidentEventsRequest.causeLevel1Code"
                    [noBorderAndArrow]="true"
                    (focusin)="openCauseLevel1Dropdown()"
                    (selectedItemIdChange)="incidentCauseLevel1Changed()">
                  </app-drop-down>
                </div>
                <div class="incident-dropdown-separator">/</div>
                <div class="incident-dropdown">
                  <app-drop-down
                    [placeholder]="'board-task-placeholder-all' | translate"
                    #caseCauseLevel2Component
                    [items]="incidentCausesLevel2"
                    [(selectedItemId)]="caseIncidentEventsRequest.causeLevel2Code"
                    (focusin)="openCauseLevel2Dropdown()"
                    [disabled]="!caseIncidentEventsRequest.causeLevel1Code"
                    (selectedItemIdChange)="incidentCauseLevel2Changed()"
                    [noBorderAndArrow]="true"
                    >
                  </app-drop-down>
                </div>
                <div class="incident-dropdown-separator">/</div>
                <div class="incident-dropdown">
                  <app-drop-down
                    #caseCauseLevel3Component
                    [placeholder]="'board-task-placeholder-all' | translate"
                    [items]="incidentCausesLevel3"
                    [(selectedItemId)]="caseIncidentEventsRequest.causeLevel3Code"
                    (focusin)="openCauseLevel3Dropdown()"
                    [disabled]="incidentCausesLevel3?.length === 0 || !caseIncidentEventsRequest.causeLevel2Code || caseIncidentEventsRequest.causeLevel2Code == ''"
                    [noBorderAndArrow]="true"
                    (selectedItemIdChange)="loadIncidentEventCaseViewModels(caseIncidentEventsRequest, true)"
                    >
                  </app-drop-down>
                </div>
              </div>
            </div>
            <div class="px-2 cauntry-margin">
              <div class="status">
                <div class="common-drop-down-arrow drop-down" *ngIf="countryListDropdown.length != 0">
                  <app-drop-down [name]="'board-case-event-country' | translate"
                    [placeholder]="'board-task-placeholder-all' | translate"
                    [items]="countryListDropdown"
                    [selectedItemId]="caseIncidentEventsRequest.incidentCountry"
                    [multiple]="true"
                    (selectedItemIdChange)="selectedCountryChanged($event)"
                    [multiSelectedLabel]="
                      'board-task-filter-some-selected' | translate: { number: caseIncidentEventsRequest.incidentCountry.length }
                    " >
                  </app-drop-down>
                </div>
              </div>
            </div>

            <div class="px-2 cauntry-margin">
              <div class="status">
                <div class="common-drop-down-arrow drop-down">
                  <app-drop-down
                    [name]="'board-case-table-team-patienttype' | translate"
                    [placeholder]="'board-case-placeholder-all' | translate"
                    [items]="patientType"
                    [(selectedItemId)]="caseIncidentEventsRequest.inOutPatient"
                    [multiple]="true"
                    [multiSelectedLabel]="
                      'board-task-filter-some-selected' | translate: { number: caseIncidentEventsRequest.inOutPatient.length }
                    "
                    (selectedItemIdChange)="loadCaseViewModels(caseIncidentEventsRequest, true)"
                  ></app-drop-down>
                </div>
              </div>
            </div>
            <div class="button-container">
              <button class="icon-button ms-3 mt-0" (click)="refreshBoardCases()">
                <img src="../../../../assets/icons/refresh.svg" />
              </button>
              <button class="icon-button mt-0" (click)="clearBoardIncidentEventCaseFilter()">
                <img src="../../../../assets/icons/clear-filter.svg"  />
              </button>
              <div class="export-boks" (click)="onExportButtonClicked()">
                <img src="../assets/icons/export.svg"
                  [ngbPopover]="'export' | translate"
                  placement="auto"
                  triggers="hover" >
              </div>
            </div>
          </div>
          <div class="d-flex mt-1" style="margin-right: 141px;">
            <div class="ms-auto px-2">
              <div class="nip-checkbox custom-checkbox">
                <label class="checkbox">
                  <span class="nip-checkbox-label">{{ 'include-cases-with-no-incident-date' | translate }}</span>
                  <input type="checkbox"
                  [(ngModel)]="caseIncidentEventsRequest.noIncidentDate"
                  (change)="loadCaseViewModels(caseIncidentEventsRequest, true)" [disabled]="!caseIncidentEventsRequest.incidentFromDate && !caseIncidentEventsRequest.incidentToDate"  />
                  <span class="checkmark nip-checkbox-checkmark"></span>
                </label>
              </div>
            </div>

            <div class=" px-2">
              <app-input-date-time
                name="{{'board-case-event-start' | translate}}"
                [placeholder]="dateTimePlaceholderIncidentEvent"
                [(date)]="caseIncidentEventsRequest.fromDate"
                [withImage]="true"
                [timeZone]="'local'"
                (dateChange)="loadCaseViewModels(caseIncidentEventsRequest, true)"
              ></app-input-date-time>
            </div>
            <div class="px-2">
                <app-input-date-time
                  name="{{'board-case-event-end' | translate}}"
                  [placeholder]="dateTimePlaceholderIncidentEvent"
                  [(date)]="caseIncidentEventsRequest.toDate"
                  [withImage]="true"
                  [timeZone]="'local'"
                  (dateChange)="loadCaseViewModels(caseIncidentEventsRequest, true)"
                ></app-input-date-time>
            </div>

            <div class="px-2 customerProfileId">
                <app-multiple-select-customer-profile
                  [name]="'board-task-table-customer-profile-multible' | translate"
                  [maxElements]="12"
                  [selectedItemsCustomerProfiles]="caseIncidentEventsRequest.customerProfileIds"
                  (selectedItemsChange)="selectedEventsCustomerProfilesChange($event)"
                  #multipleSelectCustomerProfileEventsComponent
                ></app-multiple-select-customer-profile>
            </div>

            <div class="px-2 ">
              <div class="status">
                <div class="common-drop-down-arrow drop-down">
                  <app-drop-down
                  [name]="'search-case-case-type' | translate"
                  [placeholder]="'board-case-placeholder-all' | translate"
                  [items]="caseTypes"
                  [(selectedItemId)]="caseIncidentEventsRequest.caseTypeCodes"
                  [multiple]="true"
                  [multiSelectedLabel]="
                    'board-task-filter-some-selected' | translate: { number: caseIncidentEventsRequest.caseTypeCodes.length }
                  "
                  (selectedItemIdChange)="loadCaseViewModels(caseIncidentEventsRequest, true)"
                ></app-drop-down>
                </div>
              </div>
            </div>

            <div class="px-2 cauntry-margin">
              <div class="status">
                <div class="common-drop-down-arrow drop-down">
                  <app-drop-down
                  [name]="'board-case-table-team-status' | translate"
                  [placeholder]="'board-case-placeholder-all' | translate"
                  [items]="status"
                  [(selectedItemId)]="caseIncidentEventsRequest.statuses"
                  [multiple]="true"
                  [multiSelectedLabel]="
                    'board-task-filter-some-selected' | translate: { number: caseIncidentEventsRequest.statuses.length }
                  "
                  (selectedItemIdChange)="loadCaseViewModels(caseIncidentEventsRequest, true)"
                ></app-drop-down>
                </div>
              </div>
            </div>

            <div class="px-2">
              <div class="status">
                <div class="common-drop-down-arrow drop-down">
                  <app-drop-down
                    [name]="'incident-event-transport-arranged-by-sos-global-board' | translate"
                    [placeholder]="'board-case-placeholder-all' | translate"
                    [items]="transportArranged"
                    [(selectedItemId)]="caseIncidentEventsRequest.transportAssistanceType"
                    [multiple]="true"
                    [multiSelectedLabel]="
                      'board-task-filter-some-selected' | translate: { number: caseIncidentEventsRequest.transportAssistanceType.length }
                    "
                    (selectedItemIdChange)="loadCaseViewModels(caseIncidentEventsRequest, true)"
                  ></app-drop-down>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="!boardCasesRefreshing">
          <app-board-incident-event-cases-table
            [casesSorting]="incidentEventCasesSorting"
            (sortingAction)="sortingActionIncidentEvent($event)"
            [caseSelect]="'open'"
            [boardIncidentEventCases]="boardIncidentEventCase"
            [caseTypes]="caseTypes"
          >
          </app-board-incident-event-cases-table>
          <app-pagination [currentPageIndex]="caseIncidentEventsRequest?.pageIndex" (pagingChangedEvent)="paginationChanged($event)"
                          [totalNumber]="caseIncidentEventFilterResponse?.totalNumber" [pageSize]="caseIncidentEventsRequest?.pageSize"></app-pagination>
        </ng-container>
        <ng-container *ngIf="boardCasesRefreshing">
          <div class="d-flex justify-content-center">
            <h4>{{"board-task-table-refreshing" | translate}}</h4>
          </div>
        </ng-container>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</div>
