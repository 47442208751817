import { RecoveryPaymentTaskViewModel } from "@secca/board/components/board-task/models/recovery-payment-task-view.model";

export class RecoveryPaymentTaskFilterResponse {
  totalNumber: number;
  taskViewModels: RecoveryPaymentTaskViewModel[];

  public constructor(init?: Partial<RecoveryPaymentTaskFilterResponse>) {
    Object.assign(this, init);
  }
}
