
<div class="modal-dialog-wrapper" appDialogSelectable appDialogKeyboardEnabled [enabledKeys]="enabledKeys" [appDialogMinimizable]="MinimizableDialogType.CaseNew" [isMinimizeIconHidden]="data.isMinimizeIconHidden">
    <mat-dialog-content (cdkDragStarted)="dialogBoundryService.handleDragStart($event)" (cdkDragEnded)="dialogBoundryService.handleDragEnd($event)" cdkDragRootElement=".cdk-overlay-pane" cdkDrag>
        <div appDialogDragArea cdkDragHandle></div>
        <app-case-new [searchCase]="data.searchCase"
                      [taskViewModel]="data.taskViewModel"
                      [showUpdateExistingCaseButton]="data.showUpdateExistingCaseButton"
                      [minimizeOnSelectCase]="data.minimizeOnSelectCase"
                      [isExternalTaskSearch]="data.isExternalTaskSearch"
                      [isUnrelatedTaskSearch]="data.isUnrelatedTaskSearch"
                      (navigatedToCaseEvent)="data.navigatedToCaseEvent ? data.navigatedToCaseEvent($event) : null"                      
                      (closed)="close()"></app-case-new>
    </mat-dialog-content>
</div>
