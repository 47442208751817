<div class="refund-container" xmlns="http://www.w3.org/1999/html">
    <div class="modal-header">
      <div class="modal-header-title">
        {{ (objection ? 'case-economy-refund-manage-objection' : 'case-economy-refund-manage-refund-and-compensations') | translate }}
      </div>
      <div class="modal-buttons float-end">
        <div (click)="close()">
          <img src="/assets/icons/Close White.svg" />
        </div>
      </div>
    </div>
    <app-case-lock-overlay
      [lockContext]="LockContextEnum.CASE_BASIC"
      [asOverlay]="false"
      [unlockPostAction]="CaseLockOverlayAction.TAKE_LOCK"
      [caseClosed]="caseStateService.isCaseClosed"
  ></app-case-lock-overlay>
  <div class="refund-modal-shadow" [appDisableInput]="caseLockHelperService.isCaseBasicLockTaken || caseStateService.isCaseClosed">
      <div class="refund-modal-header">
        <div class="row">
          <div class="col-3 d-flex flex-row">
            <div class="economy-process-bar p-1">
              <app-economy-process-bar
                [eventsToDisplay]="getEventsToDisplay()"
                [type]="refund.getType()"
                [progress]="refund.getProgress()"
              ></app-economy-process-bar>
            </div>
            <div class="p-2">
              <button
                class="cancel-btn"
                [hidden]="!showCancelButton()"
                [class.cancel-btn-inactive]="disableCancelButton() || isCancelled() || readOnly || storingRefund || caseLockHelperService.isCaseBasicLockTaken || caseStateService.isCaseClosed"
                [disabled]="disableCancelButton() || isCancelled() || readOnly || storingRefund || caseLockHelperService.isCaseBasicLockTaken || caseStateService.isCaseClosed"
                (click)="openCancelWarningDialog()">
                <div [disablePopover]="approvedToday()" triggers="hover" [ngbPopover]="'cancel-refund-disabled-hover-text' | translate">
                  {{ 'case-economy-refund-cancel' | translate }}
                </div>
              </button>
            </div>
          </div>
          <div *ngIf="!isModifiedAfterApproved()" class="col-2"></div>
          <div class="col-1"><b>{{ 'case-economy-refund-refund-id' | translate }}</b><br/>{{ refund.id ? refund.id : 'New'  }} </div>
          <div class="col-2"><b>{{ 'case-economy-refund-receiver-of-refund' | translate }}</b><br/> {{ recieverOfRefund }} </div>
          <div class="col-2"><b>{{ 'case-economy-refund-first-approver' | translate }}</b>
            <div *ngIf="refund.firstApprover">{{ refund.firstApproverName }}</div>
            <div *ngIf="refund.firstApprover">{{ refund.approvedDate | momentFormat: 'DD MMM YYYY HH:mm':'local' }}</div>
          </div>
          <div *ngIf="isModifiedAfterApproved()" class="col-2"><b>{{ 'case-economy-refund-second-modified' | translate }}</b>
            <div class="awaiting-text">{{ maxModifiedUser }}</div>
            <div>{{ maxModifiedOn }}</div>
          </div>
          <div *ngIf="refund.approverAwaiting" class="col-2"><b>{{ 'case-economy-refund-second-approver-awaiting' | translate }}</b><br/><span class="awaiting-text">{{ getSecondApprover() }}</span></div>
          <div *ngIf="!refund.approverAwaiting" class="col-2"><b>{{ 'case-economy-refund-second-approver' | translate }}</b>
            <div>{{ getSecondApprover() }}</div>
            <div *ngIf="refund.secondApprover">{{ refund.secondApprovedDate | momentFormat: 'DD MMM YYYY HH:mm':'local' }}</div>
          </div>
        </div>
        <ng-container *ngIf="getRefundStatus.ERROR === refund.refundStatus">
          <div class="row error-container">
            <div class="col-9 error-messages">
              <p class="error">{{ 'case-economy-refund-error-instructions-intro' | translate }}</p>
              <p class="error" *ngIf="!showContactMessage()">{{ 'case-economy-refund-error-instructions-delete' | translate }}</p>
              <p class="error" *ngIf="showContactMessage()">{{ 'case-economy-refund-error-instructions-contact' | translate }}</p>
              <p *ngFor="let error of refund.errors" class="error">Error: {{ error.cause }}</p>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="refund-modal-body">
        <table class="primary-table">
            <thead>
              <tr>
                <td class="td-service">{{ 'case-economy-refund-service' | translate }}</td>
                <td class="td-item">
                  <div
                    [appDisableInput]="permissionService.isReadOnly || caseStateService.isCaseClosed"
                    class="caselock-no-events">
                    {{ 'case-economy-refund-item' | translate }}
                    <span>
                      <div *ngIf="!lockRefundOnApproved() && !readOnly" class="add-item-button" (click)="addItem()">
                        {{ 'economy-supplier-invoice-add-item' | translate }} <img src="../../../../../../assets/icons/add-new.svg" />
                      </div>
                    </span>
                  </div>
                </td>
                <td class="td-quantity">{{ 'case-economy-refund-quantity' | translate }}</td>
                <td class="td-unit">{{ 'case-economy-refund-unit' | translate }}</td>
                <td class="td-reduction-amount">{{ 'case-economy-refund-reduction-amount' | translate }}</td>
                <td class="td-reduction-pct">{{ 'case-economy-refund-reduction-pct' | translate }}</td>
                <td class="td-expense-date">{{ 'case-economy-refund-expense-date' | translate }}</td>
                <td class="td-expense-currency">{{ 'case-economy-refund-expense-currency' | translate }}</td>
                <td class="td-expense-amount">{{ 'case-economy-refund-expense-amount' | translate }}</td>
                <td class="td-refund-amount">{{ 'case-economy-refund-refund-amount' | translate }}</td>
              </tr>
            </thead>
        </table>
          <div class="refund-details" >
            <app-refund-item
              [caseId]="refund.caseId"
              *ngFor="let item of refund.items; let i = index"
              [showDelete]="i != 0"
              (deleteRefundItem)="deleteRefundItem($event)"
              [baseCurrenciesSupported]="baseCurrenciesSupported"
              (updateRefundAmounts)="updateRefundAmounts()"
              [serviceOrders]="selectableServiceOrders"
              [savingTypes]="savingTypeList"
              [serviceItems]="serviceItemList"
              [item]="item"
              [caseCreatedDate]="caseCreatedDate"
              [refundCurrency]="localCurrency"
              [lockRefundOnApproved]="lockRefundOnApproved() || readOnly || isCancelled()"
            ></app-refund-item>
            <div class="row refund-footer " *ngIf="refund">
              <div class="col-9 ps-3 pe-3">
                <div class="refund-footer-box refund-type-box">
                  <div class="refund-type common-drop-down-arrow">
                    <span class="name select-title">{{'refund-type' | translate}}</span>
                    <ng-select [class.recommended-yellow-background]="!refund.refundType" dropdownPosition="bottom" [clearable]="false" [items]="refundTypes"
                               [(ngModel)]="refund.refundType" [disabled]="refund.getStatus() === getRefundStatus.DELETED" bindLabel="name" bindValue="id" class="custom">
                    </ng-select>
                  </div>
                </div>
                <div class="refund-footer-box" >
                  <div class="refund-footer-box-header ps-1 pt-1">
                    {{ 'case-economy-refund-receiver-of-refund-and-bank-details' | translate }}
                  </div>
                  <ng-container *ngIf="hideBankInfo">
                    <div class="refund-footer-box-content p-1 mb-1 d-flex">
                      <div class="account-info">
                        <app-single-choice-component
                          [disabled]="lockRefundOnApproved() || isCancelled() || readOnly || caseLockHelperService.isCaseBasicLockTaken || caseStateService.isCaseClosed"
                          [items]="refundAccountType"
                          (valueChangeEvent)="changeAccountType($event);"
                          [(value)]="refund.accountType"
                          [title]="'case-economy-refund-refund-to' | translate" >
                        </app-single-choice-component>
                      <div>
                      <app-employees
                        [users]="refund.accountType === RefundAccountType.NEMKONTO ? stakeholderForNemIdList : stakeholderList"
                        [disabled]="lockRefundOnApproved() || isCancelled() || readOnly || caseLockHelperService.isCaseBasicLockTaken || caseStateService.isCaseClosed"
                        [name]="'case-economy-refund-receiver-of-refund' | translate"
                        [(selectedItemId)]="refund.stakeHolderId"
                        (selectedItemIdChange)="getStakeholder($event)"
                        [isRecommended]="true"
                        >
                      </app-employees>
                    </div>
                    <div *ngIf="(selectedRefundStakeholder)">
                      <div class="mt-1" >
                        <app-input
                          [name]="'case-economy-refund-address' | translate"
                          [value]="selectedRefundStakeholder.address.street"
                          [disabled]="true"
                        ></app-input>
                      </div>
                      <div class="mt-1" >
                        <div class="float-start small-field">
                          <app-input
                          [name]="'case-economy-refund-postal-code' | translate"
                          [value]="selectedRefundStakeholder.address.postalCode"
                          [disabled]="true"
                        ></app-input>
                        </div>
                        <div class="float-start big-field">
                          <app-input
                          [name]="'case-economy-refund-city' | translate"
                          [value]="selectedRefundStakeholder.address.city"
                          [disabled]="true"
                          ></app-input>
                        </div>
                      </div>
                      <div class="bank-country" >
                        <app-drop-down-countries-auto-complete
                          [name]="'case-economy-refund-home-country' | translate"
                          #homeCountry
                          [autoCompleteTypeEnum]="AutoCompleteTypeEnum.Countries"
                          [withInput]="false"
                          [disabled]="true"
                          [countryName]="caseStakeholderCountry?.countryName"
                          [(selectedItemId)]="caseStakeholderCountry"
                          [showCode]="false"
                          [removableCountry]="true"
                        >
                        </app-drop-down-countries-auto-complete>
                      </div>
                    </div>
                      </div>
                      <div class="account-info ms-4 top-margin">
                        <div class="d-flex mb-2" *ngIf="(refund.accountType === RefundAccountType.OTHER)">
                          <div class="small-field">
                            <app-input
                              [name]="'case-economy-refund-registration-no' | translate"
                              [(value)]="refund.otherAccountRegNo"
                              [disabled]="lockRefundOnApproved() || readOnly || isCancelled() "
                              [inputTypePattern]="InputTypePattern.digitsOnly"
                              [maxTextLength]="4"
                              [validationType]="'empty'"
                              [validate]="true"
                              >
                            </app-input>
                          </div>
                          <div class="big-field">
                            <app-input
                              [name]="'case-economy-refund-account-no' | translate"
                              (saveModel)="padLeadingZeros($event, '10')"
                              [value]="refund.accountNo"
                              [disabled]="lockRefundOnApproved() || readOnly || isCancelled() "
                              [inputTypePattern]="InputTypePattern.digitsOnly"
                              [maxTextLength]="10"
                              [validationType]="'empty'"
                              >
                            </app-input>
                          </div>
                        </div>

                        <div *ngIf="(!!selectedRefundStakeholder?.person) && (refund.accountType === RefundAccountType.NEMKONTO)" class="position-relative">
                          <div class="ssn-validation-status" >
                            <div [className]="ssnValidationStatus"></div>
                          </div>
                          <app-drop-down-countries-auto-complete class="small-placeholder"
                            [name]="'case-economy-refund-national-id' | translate"
                            [withInput]="true"
                            [showCode]="true"
                            [(selectedItemId)]="nationalIdCountry"
                            [showEye]="true"
                            (showNationalId)="showNationalIdClick()"
                            [autoCompleteTypeEnum]="AutoCompleteTypeEnum.Countries"
                            [(value)]="theShownNationalId"
                            [disabled]="true"
                            [isValid]="false"
                            [validate]="!selectedRefundStakeholder.person.nationalIdValid"
                            [validationTypeToInput]="'nationalID'"
                            [removableCountry]="true"
                            [recommended]="!nationalIdCountry"
                          >
                          </app-drop-down-countries-auto-complete>
                        </div>

                        <div *ngIf="(selectedRefundStakeholder) && (refund.accountType === RefundAccountType.FOREIGN)">
                          <div class="bank-account-currency" >
                            <app-input
                              [name]="'case-economy-refund-name-of-bank' | translate"
                              [(value)]="refund.bankName"
                              [disabled]="lockRefundOnApproved() || readOnly || isCancelled() "
                              [maxTextLength]="250"
                              [validationType]="'empty'"
                              >
                            </app-input>
                          </div>
                          <div  class="mt-1">
                            <app-drop-down-countries-auto-complete
                              [name]="'case-economy-refund-country-of-bank' | translate"
                              #bankHomeCountry
                              [autoCompleteTypeEnum]="AutoCompleteTypeEnum.Countries"
                              [withInput]="false"
                              [countryName]="bankCountry?.countryName"
                              [(selectedItemId)]="bankCountry"
                              (selectedItemIdChange)="bankCountryChanged($event)"
                              [showCode]="false"
                              [removableCountry]="true"
                              [disabled]="lockRefundOnApproved() || readOnly|| isCancelled() "
                              [validationTypeToInput]="'empty'"
                              [recommended]="!bankCountry?.countryName"
                            >
                            </app-drop-down-countries-auto-complete>
                          </div>
                          <div class="mt-1" >
                            <app-input
                              [name]="'case-economy-refund-account-no-and-iban' | translate"
                              [(value)]="refund.foreignAccountNo"
                              [maxTextLength]="40"
                              (valueChange)="saveIBANInfo($event)"
                              [disabled]="lockRefundOnApproved() || readOnly|| isCancelled() "
                              [validationType]="'empty'"
                              [inputTypePattern]="InputTypePattern.noSpecialCharacters"
                              >
                            </app-input>
                          </div>
                          <div class="mt-1" >
                            <app-input
                              [name]="'case-economy-refund-swift-bic' | translate"
                              [value]="refund.swiftBIC"
                              (valueChange)="toUpperCase($event)"
                              [maxTextLength]="11"
                              [disabled]="lockRefundOnApproved() || readOnly || isCancelled() "
                              [validationType]="'empty'"
                              [inputTypePattern]="InputTypePattern.noSpecialCharacters"
                              >
                            </app-input>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div class="hide-show-bank-info" (click)="hideBankInfoClick()">
                  <span *ngIf="hideBankInfo">{{ 'case-economy-refund-hide-bank-details' | translate }}</span>
                  <span *ngIf="!hideBankInfo">{{ 'case-economy-refund-show-bank-details' | translate }}</span>
                  <img *ngIf="!hideBankInfo" class="ms-1" src="../../../../../../../assets/icons/ArrowDownBlue.svg" />
                  <img *ngIf="hideBankInfo" class="ms-1 rotate" src="../../../../../../../assets/icons/ArrowDownBlue.svg" />
                </div>
                <div [ngClass]="{'editor-big-height' : !hideBankInfo, 'editor-small-height' : hideBankInfo }">
                  <editor apiKey="" [init]="tinyMCEConfiguration" [disabled]="readOnly || isCancelled() || caseLockHelperService.isCaseBasicLockTaken || caseStateService.isCaseClosed"  *ngIf="waitBeforeInitTextbox" [(ngModel)]="refund.refundDescription" class="h-100" (onKeyUp)="onKeyUp($event)" (onFocusIn)="onFocus($event)"></editor>
                </div>
                <div>
                  <app-refund-document [(refund)]="refund" [disabled]="readOnly || isCancelled() || isInErrorStatus() " [refundState]="getRefundState()" (refundChange)="documentHasBeenPreviewed($event)" (closeRefundModal)='close()' [currentCase]="currentCase"></app-refund-document>
                </div>
                <div class="w-100 position-relative mb-3 caselock-no-events" >
                    <div class="float-start pe-1 button-area-small approve-button-position">
                      <button
                        class="primary-button w-100 me-1"
                        [class.primary-button-inactive]="!refundState.validForApproveAndReject || isCancelled() || readOnly || !groupMemberValid || !isCaseErpReady || (calculateTotalRefundCurrency <= 0) || storingRefund || caseLockHelperService.isCaseBasicLockTaken"
                        [disabled]="!refundState.validForApproveAndReject || isCancelled() || readOnly || !groupMemberValid || !isCaseErpReady  || (calculateTotalRefundCurrency <= 0) || storingRefund || caseLockHelperService.isCaseBasicLockTaken"
                        (click)="approve()"
                        >
                        <div [disablePopover]="isCaseErpReady && groupMemberValid" triggers="hover" placement="top" [ngbPopover]="(groupMemberValid ? 'case-economy-refund-approve-disabled' : 'case-economy-missing-group-member') | translate">
                          {{ 'case-economy-refund-approve' | translate }}
                        </div>
                      </button>
                    </div>
                    <div class="float-start pe-1 button-area-small reject-all-button-position">
                      <button
                        class="save-btn w-100 me-1"
                        [class.save-btn-inactive]="!refundState.validForApproveAndReject || (calculateTotalRefundCurrency > 0) || isCancelled() || storingRefund || caseLockHelperService.isCaseBasicLockTaken"
                        [disabled]="!refundState.validForApproveAndReject || readOnly || isCancelled() || storingRefund || (calculateTotalRefundCurrency > 0) || caseLockHelperService.isCaseBasicLockTaken"
                        (click)="rejectAll()"
                        >
                        {{ 'case-economy-refund-reject-all' | translate }}
                      </button>
                    </div>
                    <div class="float-start pe-1 button-area-small delete-refund-button-position">
                      <button
                        class="delete-button me-1"
                        [class.hidden]="!showDeleteButton()"
                        (click)="deleteRefund()"
                        >
                        {{ 'case-economy-refund-delete-refund' | translate }}
                      </button>
                    </div>
                </div>
              </div>
              <div class="col-3 ps-2 pe-3" >
                <div class="refund-footer-box">
                  <div class="refund-footer-box-content p-0 font-weight-bold">
                    <div class="d-flex ps-2 pb-1 pe-2 pt-1">
                      <div class="d-flex flex-column w-50">
                        <div class="pt-2">{{ 'case-economy-refund-received-date' | translate }}</div>
                        <div class="help-label" *ngIf="refund.objection">{{ 'case-economy-refund-objection-received-date' | translate }}</div>
                      </div>
                      <div class="w-50 text-end">
                        <app-input-calendar
                          [withImage]="true"
                          [maxDate]="today"
                          [(date)]="refund.receivedDate"
                          [disabled]="lockRefundOnApproved() || readOnly|| isCancelled() || claimsOnlineCase"
                          [required]="!refund.receivedDate"
                          (dateChange)="updateRefundAmounts()">
                        </app-input-calendar>
                      </div>
                    </div>
                    <div class="d-flex p-2">
                      <div class="w-50 pt-1">{{ 'case-economy-refund-due-date' | translate }}</div>
                      <div class="w-50 text-end">
                        <app-input-calendar
                          [withImage]="true"
                          [maxDate]="today"
                          [disabled]="true"
                          [date]="refund.dueDate"
                          [required]="!refund.dueDate"
                          (dateChange)="updateRefundAmounts()">
                        </app-input-calendar>
                      </div>
                    </div>
                    <div class="d-flex p-2">
                      <div class="w-50 pt-1">{{ 'case-economy-refund-refund-date' | translate }}</div>
                      <div class="w-50 text-end">
                        <app-input-calendar
                          [withImage]="true"
                          [minDate]="today"
                          [disabled]="lockRefundOnApproved() || readOnly|| isCancelled() "
                          [(date)]="refund.refundDate"
                          [invalid]="!refund.refundDate || refund.refundDate < today"
                          >
                        </app-input-calendar>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="refund-footer-box mt-1">
                  <div class="refund-footer-box-content p-0 ">
                    <div class="d-flex ps-2 pb-1 pe-2 pt-1 " >
                      <div class="w-50 pt-1 font-weight-bold">{{ 'case-economy-refund-refund-currency' | translate }}</div>
                      <div class="w-50">
                        <app-drop-down
                          [items]="exchangeCurrenciesSupported"
                          [(selectedItemId)]="refund.refundCurrency"
                          (selectedItemIdChange)="updateRefundAmounts()"
                          [recommended]="refund.refundCurrency === ''"
                          [disabled]="lockRefundCurrency || lockRefundOnApproved() || isCancelled()"
                      ></app-drop-down>
                      </div>
                    </div>
                    <div class="d-flex ps-2 pb-1 pe-2 pt-1 font-weight-bold" >
                      <div class="w-50 pt-1">{{ 'case-economy-refund-total-refund-currency' | translate }}</div>
                      <div class="w-50 text-end">
                        <div class="output-with-currency" >
                          <div class="output-with-currency-currency" >{{ localCurrency.currency }}</div>
                          {{ calculateTotalRefundCurrency | number: '.' + localCurrency.precision }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-1">
                  {{ 'case-economy-refund-internal-remark' | translate }}
                  <div>
                    <textarea
                      name="textarea"
                      class="internal-remark-text"
                      maxlength="1000"
                      [disabled]="readOnly|| isCancelled() "
                      [(ngModel)]="refund.internalRemark"
                      >
                    </textarea>
                  </div>
                </div>
                <div class="mt-2">
                  <button
                      class="save-btn me-1 button-width"
                      [class.save-btn-inactive]="disabledRequestInformation() || isCancelled() || readOnly || storingRefund || caseLockHelperService.isCaseBasicLockTaken || caseStateService.isCaseClosed"
                      [disabled]="disabledRequestInformation() || isCancelled() || readOnly || storingRefund || caseLockHelperService.isCaseBasicLockTaken || caseStateService.isCaseClosed"
                      (click)="requestInformation()"
                      >
                      {{ 'case-economy-refund-request-information' | translate }}
                  </button>
                  <button
                      class="primary-button ms-1 button-width"
                      [class.primary-button-inactive]="disableSavedButton() || readOnly || storingRefund || caseLockHelperService.isCaseBasicLockTaken || caseStateService.isCaseClosed"
                      [disabled]="disableSavedButton() || readOnly || storingRefund || caseLockHelperService.isCaseBasicLockTaken || caseStateService.isCaseClosed"
                      (click)="changeRefundStatusAndSave()"
                      >
                      {{ 'case-economy-refund-save-and-close' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</div>
