import { CaseStakeholder } from './../../shared/models/caseStakeholder';
import { TranslateService } from '@ngx-translate/core';
import { PhoneNumberType, StakeholderTypeEnum } from 'src/app/shared/models/enums';
import { Injectable } from '@angular/core';
import { StakeholderService } from './stakeholder.service';

@Injectable({
  providedIn: 'root',
})
export class StakeholderHelperService {
  constructor(private translate: TranslateService, private stakeholderService: StakeholderService) { }

  public getStakeholderNameForType(stakeholderType: StakeholderTypeEnum): string {
    switch (stakeholderType) {
      case StakeholderTypeEnum.person: {
        return this.translate.instant('stakeholder-helper-person');
      }
      case StakeholderTypeEnum.escortNurse: {
        return this.translate.instant('stakeholder-helper-escort-nurse');
      }
      case StakeholderTypeEnum.escortDoctor: {
        return this.translate.instant('stakeholder-helper-escort-doctor');
      }
      case StakeholderTypeEnum.coTraveller: {
        return this.translate.instant('stakeholder-helper-co-traveller');
      }
      case StakeholderTypeEnum.endUser: {
        return this.translate.instant('stakeholder-helper-end-user');
      }
      case StakeholderTypeEnum.policyHolder: {
        return this.translate.instant('stakeholder-helper-policy-holder');
      }
      case StakeholderTypeEnum.reporter: {
        return this.translate.instant('stakeholder-helper-reporter');
      }
      case StakeholderTypeEnum.hotel: {
        return this.translate.instant('stakeholder-helper-hotel');
      }
      case StakeholderTypeEnum.hospital: {
        return this.translate.instant('stakeholder-helper-hospital');
      }
      default:
        return this.translate.instant('stakeholder-helper-other');
    }
  }

  public matchStakeholderByEmail(stakeholder: CaseStakeholder, email: string): CaseStakeholder {
    if (stakeholder.email && email && email === stakeholder.email) {
      return stakeholder;
    } else {
      return null;
    }
  }

  public matchStakeholderByMobilePhoneNumber(stakeholder: CaseStakeholder, mobilePhoneNumber: string): CaseStakeholder {
    let exist = false;
    stakeholder.phoneNumbers.forEach(value => {
      if (value.type === PhoneNumberType.Mobile) {
        if (value.number && value.prefix && mobilePhoneNumber && mobilePhoneNumber === value.prefix + value.number) {
          exist = true;
        }
      }
    });

    if (exist) {
      return stakeholder;
    } else {
      return null;
    }
  }
}
