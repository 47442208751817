export class ICCLinkedCaseRemarks{
    iccLinkedCaseRemarks: ICCLinkedCaseRemark[] = [];

    constructor(iccinkedCaseRemarks?: ICCLinkedCaseRemark[]) {
      this.iccLinkedCaseRemarks = !!iccinkedCaseRemarks ? iccinkedCaseRemarks : [];
    }


    getByCase(caseId: number) : string{
        let foundRemark = this.iccLinkedCaseRemarks.find(inkedCaseRemark => inkedCaseRemark.caseId == caseId);
        if (!foundRemark) {
            foundRemark = new ICCLinkedCaseRemark({caseId : caseId});
          this.iccLinkedCaseRemarks.push(foundRemark);

        }
        return foundRemark.remark;
    }

    setByCase(caseId: number, remark: string) {
        this.iccLinkedCaseRemarks.find(inkedCaseRemark => inkedCaseRemark.caseId == caseId).remark = remark;
    }
}

export class ICCLinkedCaseRemark {
    id: number;
    caseId: number;
    remark: string;

    public constructor(init?: Partial<ICCLinkedCaseRemark>) {
      Object.assign(this, init);
    }
}

export class ICCLinkedCaseRemarkAdapter {
    adapt(item: any): ICCLinkedCaseRemark {
    const iccLinkedCaseRemark = new ICCLinkedCaseRemark({
        caseId: item.caseId,
        remark: item.remark,
    });
    return iccLinkedCaseRemark;
    }
}