<div class="case-local-time-container">
  <header class="header-local-time">
     Local Time
  </header>
  <div class="line-local-time"></div>
  <div *ngIf="country">
    <p class="local-time-time">{{ dateVal | TimezoneOffset : timeZoneId}}
      <flag-icon *ngIf="countryCodeAlpha2 && showFlag" [country]="countryCodeAlpha2.toLowerCase()" (click)="openCountryInfo()"></flag-icon>
    </p>
    <div class="local-time-place">
      {{city}} {{country}} ({{ timeZoneId | timeDiff : true }})
    </div>
  </div>
</div>
