<div class="modal-content">
  <div class="modal-header">
    <div class="modal-header-title">
      <span *ngIf="isEmail()">{{"case-summary-email-view-email" | translate}}</span>
      <span *ngIf="!isEmail()">{{"case-summary-email-view-fax" | translate}}</span>
    </div>
    <div class="modal-buttons">
      <div (click)="close()">
        <img src="/assets/icons/Close White.svg" />
      </div>
    </div>
  </div>
  <div class="wrapper">
    <ul ngbNav #nav="ngbNav" class="nav-tabs" [destroyOnHide]="false">
      <li ngbNavItem>
        <a ngbNavLink>
          <div> {{"task-message-modal-message" | translate}}</div>
        </a>
        <ng-template ngbNavContent>
          <div class="view-container">
            <div class="view-container-subject">{{entry.subject}}</div>
            <div class="view-container-message-header">
              <span class="view-container-message-header-title">{{"case-summary-email-from" | translate}}: </span>
              <span class="view-container-message-header-value">{{entry.from}}</span>
            </div>
            <div class="view-container-message-header">
              <span class="view-container-message-header-title">{{"case-summary-email-to" | translate}}: </span>
              <span class="view-container-message-header-value">{{entry.to}}</span>
            </div>
            <div class="view-container-message-header">
              <span class="view-container-message-header-title">{{"case-summary-email-cc" | translate}}: </span>
              <span class="view-container-message-header-value">{{entry.cc}}</span>
            </div>
            <div class="view-container-message-header">
              <span class="view-container-message-header-title">{{"case-summary-email-date" | translate}}: </span>
              <span class="view-container-message-header-value">{{mailDate | date:'dd. MMM yyyy, HH:mm'}}</span>
            </div>
            <div *ngIf="!entry.content" class="view-container-content-loading">{{"case-summary-email-loading" | translate}}</div>
            <div *ngIf="entry.content" class="view-container-content" [innerHTML]="entry.content | safeHtml"></div>
            <div class="view-container-message-header">
              <div class="view-container-message-header-title">{{"case-summary-email-internal-summary" | translate}} </div>
              <div class="position-relative">
                <textarea appCharCount="top-right" maxlength="8000" class="internal-summary" [(ngModel)]="entry.updatedInternalSummary"></textarea>
              </div>
              <div>
                <button class="primary-button internal-summary-button" [disabled]="!internalSummaryHasBeenUpdated"
                  [class.primary-button-inactive]="!internalSummaryHasBeenUpdated"
                  (click)="saveCaseSummary()">{{"case-summary-email-internal-summary-save" | translate}}</button>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
      <li ngbNavItem *ngIf="caseEmail?.attachments?.length > 0">
        <a ngbNavLink>
          <div> {{"task-message-modal-attachments" | translate}} ({{caseEmail?.attachments?.length}}) </div>
        </a>
        <ng-template ngbNavContent>
          <div class="attachments-container">
            <div *ngFor="let attachment of caseEmail.attachments">
              <app-task-message-attachment [attachment]="attachment" [caseId]="entry.caseId"></app-task-message-attachment>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
  </div>
</div>
